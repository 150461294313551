<template>
  <v-row justify="center">
    <v-dialog
      v-model="sheet"
      class="bottom-sheet"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="primary" class="px-6">
          <v-toolbar-title>
            <span class="text-white">{{
              showAdminMenu ? 'Admin Menu' : 'User Menu'
            }}</span>
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn icon @click="sheet = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!--Parents-->
        <v-expansion-panels variant="accordion">
          <v-expansion-panel
            v-for="item in allowedItems"
            :key="item.title"
            @click="item.action ? item.action() : handleClick(item)"
            class="px-3"
            hide-actions
            elevation="0"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <div>
                  <v-icon color="primary" class="mr-3">
                    {{ item.icon }}
                  </v-icon>
                  <span class="text-primary">{{ item.title }}</span>
                  <v-icon color="primary" class="ml-5" v-if="hasChildren(item)">
                    {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </div>
              </template>
            </v-expansion-panel-title>
            <!--Children-->
            <v-expansion-panel-text>
              <v-expansion-panels
                variant="accordion"
                v-if="hasChildren(item)"
                class="px-5"
              >
                <v-expansion-panel
                  v-for="child in item.items"
                  :key="child.title"
                  @click="handleClick(child)"
                  hide-actions
                  elevation="0"
                >
                  <v-expansion-panel-title>
                    <template v-slot:default="{ expanded }">
                      <div>
                        <v-icon color="primary" class="mr-3">
                          {{ child.icon }}
                        </v-icon>
                        <span class="text-primary">{{ child.title }}</span>
                        <v-icon
                          color="primary"
                          class="ml-5"
                          v-if="hasChildren(child)"
                        >
                          {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                      </div>
                    </template>
                  </v-expansion-panel-title>
                  <!--Grandchildren-->
                  <v-expansion-panel-text>
                    <v-expansion-panels
                      variant="accordion"
                      v-if="hasChildren(child)"
                      class="px-7"
                    >
                      <v-expansion-panel
                        v-for="grandChild in child.items"
                        :key="grandChild.title"
                        @click="handleClick(grandChild)"
                        hide-actions
                        elevation="0"
                      >
                        <v-expansion-panel-title>
                          <template v-slot:default="{ expanded }">
                            <div>
                              <v-icon
                                color="primary"
                                size="small"
                                class="mr-3"
                                v-if="grandChild.icon"
                              >
                                {{ grandChild.icon }}
                              </v-icon>
                              <span
                                class="text-primary"
                                :class="{
                                  'font-weight-bold': hasChildren(grandChild),
                                }"
                              >
                                {{ grandChild.title }}
                              </span>
                              <v-icon
                                color="primary"
                                class="ml-5"
                                v-if="hasChildren(grandChild)"
                              >
                                {{
                                  expanded
                                    ? 'mdi-chevron-up'
                                    : 'mdi-chevron-down'
                                }}
                              </v-icon>
                            </div>
                          </template>
                        </v-expansion-panel-title>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
    <NewTermsOfService
      :show="showTermsOfService"
      @closeDialog="(value) => (showTermsOfService = value)"
    />
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// This import will be replaced by the new codebase when it's ready
import NewTermsOfService from '@/Core.Service.Domain/TermsOfService/Components/TermsOfService.vue';

export default {
  name: 'AdminMenu',

  components: {
    NewTermsOfService,
  },

  props: {
    adminData: {
      type: Object,
      default: () => ({}),
    },
    onClickParent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('session', ['hasNewTermsOfServices']),

    showAdminMenu() {
      return this.$can('administer', 'control');
    },

    allowedItems() {
      return this.items.filter((item) => item.active());
    },
  },

  methods: {
    ...mapActions('session', ['logout']),
    hasChildren(item) {
      return item.items && item.items.length;
    },

    handleClick(item) {
      if (item.to) {
        this.sheet = false;
        this.$router.push(item.to);
      }
    },

    openTermsOfService() {
      this.showTermsOfService = true;
    },
  },

  watch: {
    onClickParent(val) {
      this.sheet = val;
    },
    sheet(val) {
      this.$emit('update:onClickParent', val);
    },
  },

  data: (instance) => ({
    showTermsOfService: false,
    sheet: false,
    items: [
      {
        icon: 'mdi-account-cog',
        to: '',
        active: () => instance.showAdminMenu,
        items: [
          {
            icon: 'mdi-account-box-multiple',
            title: 'Users',
            to: { name: 'UserIndex' },
          },
          {
            icon: 'mdi-office-building-marker',
            title: 'Sites',
            to: '',
            items: [
              {
                icon: 'mdi-map-marker-multiple',
                title: 'All Sites',
                to: { name: 'SitesRoute' },
              },
              {
                icon: 'mdi-hand-coin-outline',
                title: 'ROI Predictions',
                to: { name: 'ROIPredictions' },
              },
              {
                icon: 'mdi-home-lightning-bolt',
                title: 'Utility Companies',
                to: { name: 'UtilityCompanies' },
              },
            ],
          },
          {
            icon: 'mdi-chart-box',
            title: 'Reports',
            to: { name: 'AdminReportIndex' },
          },
          {
            icon: 'mdi-sync-circle',
            title: 'ADF-Sync',
            to: { name: 'ADFSyncIndex' },
          },
          {
            icon: 'mdi-account-sync',
            title: 'Refresh Auth0 Role Cache',
            to: { name: 'UsersRolesCache' },
          },
          {
            icon: 'mdi-sprout',
            title: 'Demand Response',
            to: { name: 'DemandResponse' },
          },
        ],
        title: 'Administration',
      },
      {
        title: 'Message Center',
        to: { name: 'MessageCenter' },
        icon: 'mdi-message-text-fast-outline',
        active: () => instance.showAdminMenu,
      },
      {
        icon: 'mdi-shield-lock',
        to: '',
        active: () => instance.showAdminMenu,
        items: [
          {
            icon: 'mdi-shield-home',
            title: 'Permissions - Core',
            to: { name: 'Permissions' },
          },
        ],
        title: 'Security',
      },
      {
        icon: 'mdi-chart-timeline-variant-shimmer',
        to: '',
        active: () => instance.showAdminMenu,
        items: [
          {
            icon: 'mdi-gauge',
            to: '',
            title: 'Egauges',
            items: [
              {
                icon: 'mdi-gauge',
                title: 'My Egauges',
                to: { name: 'SubMeterEgaugeIndex' },
              },
              {
                icon: 'mdi-database-sync',
                title: 'Egauge Data Recovery',
                to: { name: 'SubMeterEgaugeDataRecovery' },
              },
            ],
          },
          {
            icon: 'mdi-monitor-shimmer',
            to: '',
            title: 'Manual Monitors',
            items: [
              {
                icon: 'mdi-monitor-shimmer',
                title: 'My Manual Monitors',
                to: { name: 'SubMeterManualMonitorIndex' },
              },
            ],
          },
          {
            icon: 'mdi-monitor-account',
            title: 'Virtual Monitors',
            to: { name: 'VirtualMonitorIndex' },
          },
          {
            icon: 'mdi-alpha-a-box',
            to: '',
            title: 'Aginova',
            items: [
              {
                icon: 'mdi-alpha-a-box',
                title: 'My Aginova Monitors',
                to: { name: 'AginovaMonitorsList' },
              },
              {
                icon: 'mdi-alpha-a-box-outline',
                title: 'Aginova Login',
                to: { name: 'AginovaLoginIndex' },
              },
              {
                icon: 'mdi-database-sync',
                title: 'Aginova Data Recovery',
                to: { name: 'AginovaDataRecovery' },
              },
            ],
          },          
          {
            icon: 'mdi-ray-end',
            title: 'End Uses',
            to: { name: 'EgaugeEndUseIndex' },
          },
          {
            icon: 'mdi-leak',
            title: 'Sensors',
            to: { name: 'EgaugeSensorIndex' },
          },
          {
            icon: 'mdi-speedometer',
            to: { name: 'UtilityMeterIndex' },
            title: 'Utility Meters',
          },
          {
            icon: 'mdi-smoke-detector-variant',
            to: { name: 'EquipmentIndex' },
            title: 'Equipment',
          },
          {
            icon: 'mdi-map-marker-radius',
            to: { name: 'SiteLocationIndex' },
            title: 'Site Location',
          },
        ],
        title: 'Monitors',
      },
      {
        icon: 'mdi-timer-cog-outline',
        to: '',
        active: () => instance.showAdminMenu,
        items: [
          {
            icon: 'mdi-shield-account',
            title: 'Pelican Permissions',
            to: { name: 'ControlPermissionsIndex' },
          },
        ],
        title: 'Controls',
      },
      {
        icon: 'mdi-finance',
        items: [
          {
            icon: 'mdi-collage',
            title: 'Models',
            to: { name: 'Models' },
            active: () => instance.$can('read', 'models'),
          },
          {
            icon: 'mdi-chart-gantt',
            title: 'Projects',
            to: { name: 'AnalyticsProjects' },
            active: () => instance.$can('read', 'projects'),
          },
          {
            icon: 'mdi-cog-off',
            title: 'Non-Routine Events',
            to: { name: 'NonRoutineEvents' },
            active: () => instance.$can('read', 'non-routine-events'),
          },
          {
            icon: 'mdi-gauge',
            title: 'Measures/Oportunities',
            to: { name: 'AnalyticsMeasures' },
            active: () => instance.$can('read', 'measures'),
          },
        ],
        title: 'Analytics',
        to: '',
        active: () => instance.$can('read', 'analytics'),
      },
      {
        icon: 'mdi-text-box-check-outline',
        to: '',
        active: () => true,
        title: 'Terms of Services',
        action: () => instance.openTermsOfService(),
      },
      {
        icon: 'mdi-logout',
        to: '',
        active: () => true,
        title: 'Logout',
        action: () => instance.logout(),
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
.cut-name {
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  width: calc(80%);
}

.v-list {
  height: 100%;
}

.v-expansion-panel-title {
  color: $primary !important;
}
</style>
