import embed from '../embedReports/defaultEmbed.js';
import slicer from '../slicerReports/defaultSlicer.js';

const embedAndSlice = async function (container, embedConfiguration) {
    const pbiEmbed = await embed.getEmbed(container, embedConfiguration);
    try {
      const visuals = await getVisuals(pbiEmbed);
      // Retrieve the target visuals.
      await slicer.setSlicers(embedConfiguration.reportParameters, visuals);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getVisuals = async function (pbiEmbed) {
    const pages = await pbiEmbed.getPages();

    // Retrieve the active page.
    const page = pages.filter(function (page) {
      return page.isActive;
    })[0];

    return await page.getVisuals();
  };

export default {
  embedAndSlice,
};
