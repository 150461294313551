<template>
  <v-card class="mt-n2">
    <v-toolbar color="primary">
      <v-tabs
        v-model="currentTab"
        align-tabs="start"
        slider-color="#378CDA"
        bg-color="primary"
      >
        <v-tab
          v-for="(floor, n) in floors"
          :key="n"
          :value="n"
          :disabled="floor.isDisabled ? floor.isDisabled : false"
        >
          <span
            class="text-subtitle-1 text-white"
            :class="currentTab === n ? 'font-weight-bold' : ''"
          >
            {{ floor.name }}
          </span>
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <v-tabs-window v-model="currentTab">
      <v-tabs-window-item v-for="(floor, n) in floors" :key="n" :value="n">
        <v-container fluid>
          <ImageViewer
            class="image-view"
            :showAHUButton="floor.showAHUButton"
            :isAHULoading="isAHULoading"
            @update:ahu-settings="handleAHUDialog(floor.ahuId)"
            @get:ahu-id="() => (currentAHUId = floor.ahuId)"
          >
            <template v-slot:Hotspot v-if="floor.hotspotsList">
              <div v-for="(hotspot, i) in floor.hotspotsList" :key="i">
                <component
                  v-if="hotspot.component && isFloorLoadedMap[floor.loadedName]"
                  :is="hotspot.component"
                  :color="hotspot.color"
                  :style="{ ...hotspot.style }"
                  @mouseenter="showTooltip(hotspot.id, floor)"
                  @mouseleave="hideTooltip(floor)"
                  @mousemove="updateTooltipPosition"
                  @click="!hotspot.isFloorVAV && hotspot.hotSpotAction(i)"
                />
                <v-chip
                  v-if="
                    !hotspot.isFloorVAV && isFloorLoadedMap[floor.loadedName]
                  "
                  variant="elevated"
                  :prepend-icon="hotspot.techInfo.icon"
                  :color="hotspot.color"
                  :style="hotspot.chipStyle as CSSProperties"
                  @mouseenter="showTooltip(hotspot.id, floor)"
                  @mouseleave="hideTooltip(floor)"
                  @mousemove="updateTooltipPosition"
                  @click="hotspot.hotSpotAction(i)"
                >
                  <span class="text-subtitle-1 font-weight-bold">
                    {{ hotspot.name }}
                  </span>
                </v-chip>
                <VAVChipComponent
                  v-if="
                    hotspot.isFloorVAV && isFloorLoadedMap[floor.loadedName]
                  "
                  :hotspot="hotspot"
                  :suite="floor"
                  :selectedVAVId="selectedVAVId"
                />
              </div>
            </template>
            <!-- Image Component -->
            <template v-slot:Image>
              <component :is="floor.component" />
            </template>
          </ImageViewer>

          <!-- Tooltip -->
          <div
            v-if="
              floor.activeTooltip !== null &&
              !floor.hotspotsList[floor.activeTooltip]?.isFloorVAV
            "
            :style="{
              top: tooltipPosition.top,
              left: tooltipPosition.left,
              zIndex: 101,
            }"
            class="tooltip"
          >
            <!-- Tooltip content -->
            <DigitalTwinTooltip
              :color="floor.hotspotsList[floor.activeTooltip]?.color"
              :tooltip-info="{
                techInfo:
                  floor.hotspotsList[floor.activeTooltip]?.techInfo['location'],
              }"
            >
              <table>
                <tbody>
                  <tr
                    v-for="(value, key) in floor.hotspotsList[
                      floor.activeTooltip
                    ]?.techInfo"
                    :key="key"
                  >
                    <td
                      cols="6"
                      class="text-primary text-subtitle-2 text-left pr-7"
                    >
                      {{
                        value && key !== 'location'
                          ? splitCamelCase(capitalizeFirstLetter(`${key}`))
                          : ''
                      }}
                    </td>
                    <td
                      cols="6"
                      class="text-primary text-subtitle-2 text-left pl-7 font-weight-bold"
                    >
                      <ul
                        v-if="Array.isArray(value)"
                        class="columns-wrapper mt-n5"
                      >
                        <div
                          v-for="(chunk, chunkIndex) in chunkedItems(value)"
                          :key="chunkIndex"
                          class="column"
                        >
                          <li
                            v-for="(item, index) in chunk"
                            :key="index"
                            class="text-primary text-subtitle-2 text-left pr-7"
                          >
                            {{ item }}
                          </li>
                        </div>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </DigitalTwinTooltip>
          </div>
        </v-container>
      </v-tabs-window-item>
    </v-tabs-window>

    <!-- Dialog -->
    <DigitalTwinDialog
      :dialog="dialog"
      :currentTab="currentTab"
      :childTab="childTab"
      :floors="floors"
      :vav="vav"
      :selectedVAVId="selectedVAVId"
      @update:dialog="handleDialog"
      @update:childTab="handleChildTab"
    />

    <!-- AHU Dialog -->
    <DigitalTwinAHUDialog
      :dialog="ahuDialog"
      :ahuDevice="ahu"
      @update:dialog="($event: boolean) => (ahuDialog = $event)"
    />

    <!-- VAV Card -->
    <VAVCard
      class="vav-card"
      v-if="
        Object.keys(vav).length &&
        selectedVAVId !== 0 &&
        vav.controlName !== '' &&
        selectedVAVId === vav.controlId &&
        isClickFromCommonArea
      "
      :VAVInfo="vav"
    />
  </v-card>
</template>

<script setup lang="ts">
import {
  ref,
  CSSProperties,
  onMounted,
  watch,
  reactive,
  provide,
  readonly,
} from 'vue';
import { useStore } from 'vuex';
import * as allFloors from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';
import ImageViewer from '@/Core.Service.Domain/Controls/DigitalTwin/Components/ImageViewer.vue';

import DigitalTwinDialog from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DigitalTwinDialog.vue';

import DigitalTwinAHUDialog from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DigitalTwinAHUDialog.vue';

import DigitalTwinTooltip from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DigitalTwinTooltip.vue';

import VAVChipComponent from '@/Core.Service.Domain/Controls/DigitalTwin/Components/VAVChipComponent.vue';

import VAVCard from '@/Core.Service.Domain/Controls/DigitalTwin/Components/VAVCard.vue';

import { capitalizeFirstLetter } from '@/Core.Patterns/Adapter/index.ts';
import { splitCamelCase } from '@/Core.Patterns/Factory/index.ts';

import API from '@/Core.Service.Domain/Controls/DigitalTwin/API/index.ts';
import { IAHU } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

const store = useStore();
onMounted(() => {
  eventBus.$on('update:selectedVAVId', (vavId: number) => {
    store.dispatch('DigitalTwinStore/removeDeviceFromCache', vavId);
    selectedVAVId.value = 0;
  });
});

const floorsLoadedNamesList = ref<string[]>(['6822-01', '6822-02', '6822-03']);

const isFloorLoadedMap = reactive<Record<string, boolean>>({});

floorsLoadedNamesList.value.forEach((floorId) => {
  isFloorLoadedMap[floorId] = false;
});

floorsLoadedNamesList.value.forEach((floorId) => {
  watch(
    () => store.getters['DigitalTwinStore/isComponentCached'](floorId),
    (value) => {
      isFloorLoadedMap[floorId] = value;
    },
    { immediate: true }
  );
});

const floors = ref(allFloors.floors);

const currentAHUId = ref(0);
provide('currentAHUId', readonly(currentAHUId));

const ahuDialog = ref(false);

const isAHULoading = ref(false);
provide('isAHULoading', readonly(isAHULoading));

const dialog = ref(allFloors.dialog);
const tooltipPosition = ref({
  top: '0',
  left: '0',
});
const currentTab = ref(0);
const childTab = ref(allFloors.childTab);
const selectedVAVId = ref(allFloors.selectedVAVId);
const isClickFromCommonArea = ref(allFloors.isClickFromCommonArea);

const vav = ref(allFloors.vav);

const ahu = ref<IAHU>({} as IAHU);

const showTooltip = (
  hotspotId: number,
  floor: { activeTooltip: number | null }
) => {
  floor.activeTooltip = hotspotId;
};

const hideTooltip = (floor: { activeTooltip: number | null }) => {
  floor.activeTooltip = null;
};

const updateTooltipPosition = (event: { clientY: number; clientX: number }) => {
  tooltipPosition.value = {
    top: `${event.clientY + 275}px`,
    left: `${event.clientX - 120}px`,
  };
};

const handleDialog = (value: boolean) => {
  if (!value) selectedVAVId.value = 0;
  dialog.value = value;
};

const handleChildTab = (value: number) => {
  childTab.value = value;
  selectedVAVId.value = 0;
};

const chunkedItems = (value: Array<string>) => {
  const chunkSize = 6;
  const result = [];
  for (let i = 0; i < value.length; i += chunkSize) {
    result.push(value.slice(i, i + chunkSize));
  }
  return result;
};

const handleAHUDialog = async (ahuId: number) => {
  try {
    isAHULoading.value = true;
    ahu.value = await API.getAHUById(ahuId);
    ahuDialog.value = true;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    isAHULoading.value = false;
  }
};
</script>

<style lang="scss" scoped>
.image-view {
  width: auto;
  height: 80vh;
}

.tooltip {
  position: absolute;
  min-width: 250px;
  min-height: 500px;
  color: white;
  border-radius: 5px;
  white-space: nowrap;
  pointer-events: none;
  transform: translate(-50%, -100%);
}

.v-alert__border {
  opacity: 1 !important;
}

ul {
  list-style-type: none;
  padding-top: 15px;
}

.columns-wrapper {
  display: flex;
  gap: 0;
}

.column {
  list-style: none;
  padding: 0;
  margin: 0;
}

:deep(.v-tab__slider) {
  height: 6px !important;
}
</style>
