import DigitalTwinRoutes from '@/Core.Service.Domain/Controls/DigitalTwin/Views/DigitalTwin.vue';

export const DigitalTwinRoute = {
  path: '/digital-twin/:siteId',
  name: 'DigitalTwin',
  component: DigitalTwinRoutes,
  meta: {
    placeholder: 'Search for a Digital Twin',
    controlTypeName: 'Digital Twin',
    label: 'Digital Twin',
    toolTip: 'Digital Twin',
    permission: 'controls:read',
  },
};
