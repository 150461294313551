<template>
  <v-overlay
    class="message-overlay"
    :model-value="showOverlay"
    @click="close()"
  >
    <v-card class="p-2" width="800" height="500" color="primary">
      <v-card-title class="py-5">
        {{ stepperTitle }}
      </v-card-title>
      <v-card-text>
        <div class="container">
          <StepperIndex :replayMessages="replayMessages" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn class="close-button" :disabled="isLoading" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script setup lang="ts">
import StepperIndex from '@/Core.Service.Domain/Strategies/Strategies/Components/StepperIndex.vue';

import { IProps } from '@/Core.Service.Domain/Strategies/Strategies/types/replayStrategy.ts';

const {
  replayMessages,
  showOverlay = false,
  isLoading = false,
  stepperTitle = 'Replaying the strategy',
} = defineProps<IProps>();

const emits = defineEmits(['close']);

const close = () => {
  if (isLoading) return;
  emits('close');
};
</script>
<style lang="scss" scoped>
.message-overlay {
  z-index: 9999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: absolute;
  width: 96%;
  height: 350px;
  background-color: white;
  overflow-y: scroll;
  border-radius: 2px;
}

.close-button {
  position: absolute !important;
  bottom: 10px;
  right: 15px;
}
</style>
