<template>
  <div>
    <!--Settings change Warning-->
    <v-alert
      border="start"
      border-color="accentOrange"
      class="text-primary"
      color="white"
      elevation="1"
      v-if="isThermostatDirty && !isDirty.isOutOfRange"
    >
      <strong class="text-h6">Notice</strong><br />
      You've changed
      {{
        `${getThermostatNewSettings().length} ${
          getThermostatNewSettings().length > 1 ? 'settings' : 'setting'
        }`
      }}.
      <v-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="text-primary text-subtitle-2 text-left font-weight-bold"
              >
                Setting:
              </th>
              <th
                class="text-primary text-subtitle-2 text-left font-weight-bold"
              >
                Current Value:
              </th>
              <th
                class="text-primary text-subtitle-2 text-left font-weight-bold"
              >
                New Value:
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="setting in getThermostatNewSettings()"
              :key="setting.name"
            >
              <td class="text-primary text-subtitle-2 text-left">
                {{ setting.name }}
              </td>
              <td class="text-primary text-subtitle-2 text-left">
                {{ setting.currentSetting }}
              </td>
              <td class="text-primary text-subtitle-2 text-left">
                {{ setting.newSetting }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </v-alert>
    <!--Out of Range Warning-->
    <v-alert
      border="bottom"
      border-color="warning"
      icon="mdi-alert"
      class="text-primary"
      color="white"
      v-if="isDirty.isOutOfRange"
    >
      <strong>WARNING - Control's settings are Locked</strong><br />
      This control's settings are <strong>locked</strong> because its
      {{
        getTemperaturesOutOfRange().length > 1
          ? 'configurations are'
          : 'configuration is'
      }}
      <strong> out of range. </strong><br />Please, fix control's
      {{
        getTemperaturesOutOfRange().length > 1
          ? 'configurations'
          : 'configuration'
      }}
      before setting new values.
      <v-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="text-primary text-subtitle-2 text-left font-weight-bold"
              >
                Setting:
              </th>
              <th
                class="text-primary text-subtitle-2 text-left font-weight-bold"
              >
                Expected:
              </th>
              <th
                class="text-primary text-subtitle-2 text-left font-weight-bold"
              >
                Current:
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="setting in getTemperaturesOutOfRange()"
              :key="setting.name"
            >
              <td class="text-primary text-subtitle-2 text-left">
                {{ setting.name }}
              </td>
              <td class="text-primary text-subtitle-2 text-left">
                {{ setting.expected }}
              </td>
              <td class="text-primary text-subtitle-2 text-left">
                {{ setting.current }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'ThermostatAlert',
  props: {
    isThermostatDirty: {
      type: Boolean,
      required: true,
    },
    isDirty: {
      type: Object,
      required: true,
    },
    getThermostatNewSettings: {
      type: Function,
      required: true,
    },
    getTemperaturesOutOfRange: {
      type: Function,
      required: true,
    },
  },
};
</script>
