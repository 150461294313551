<template>
  <v-sheet flat border class="pa-4 col-sm-12">
    <p class="text-subtitle-1 pt-3 text-primary">
      <span class="font-weight-bold">Thermostat</span>
      - Input values at execution time
    </p>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="InputData.ControlId"
          :items="inputList"
          :item-title="(item) => item.Name || ''"
          :item-value="(item) => item.Id"
          label="Control Name"
          variant="outlined"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="InputData.CurrentDateTime"
          :rules="[isDate]"
          variant="outlined"
          label="Current Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="InputData.ReadingDateTime"
          :rules="[isDate]"
          variant="outlined"
          label="Reading Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="InputData.DelayInMinutes"
          :rules="[isNumberGreaterThanZero]"
          variant="outlined"
          label="Delay in Minutes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="InputData.HeatSetting"
          :rules="[isNumber]"
          variant="outlined"
          label="Heat Setting"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="InputData.MinHeatSetting"
          :rules="[isNumber]"
          variant="outlined"
          label="Min Heat Setting"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="InputData.MaxHeatSetting"
          :rules="[isNumber]"
          variant="outlined"
          label="Max Heat Setting"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="InputData.CoolSetting"
          :rules="[isNumber]"
          variant="outlined"
          label="Cool Setting"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="InputData.MinCoolSetting"
          :rules="[isNumber]"
          variant="outlined"
          label="Min Cool Setting"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="InputData.MaxCoolSetting"
          :rules="[isNumber]"
          variant="outlined"
          label="Max Cool Setting"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="InputData.Co2Setting"
          :rules="[isNumber]"
          variant="outlined"
          label="CO2 Setting (max)"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="InputData.Co2Level"
          :rules="[isNumber]"
          variant="outlined"
          label="CO2 Level (current)"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="InputData.FanStatus"
          :rules="[(v) => /^(on|off)$/i.test(v)]"
          variant="outlined"
          label="Fan On/Auto Status"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="InputData.FrontKeyPad"
          :rules="[(v) => /^(on|off)$/i.test(v)]"
          variant="outlined"
          label="Font Key Pad On/Off Value"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <ValidatedSelectField
          :items="systemEnum"
          :item-title="(item) => item.text || ''"
          :item-value="(item) => item.id"
          v-model="InputData.System"
          label="The Current System Value of the A/C Unit"
          rules="required"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="InputData.SystemDurationInMinutes"
          :rules="[isNumberGreaterThanZero]"
          variant="outlined"
          label="System Status Duration (minutes)"
        />
      </v-col>
      <v-col cols="3">
        <ValidatedSelectField
          :items="runStatuses"
          :item-title="(item) => item.text || ''"
          :item-value="(item) => item.id"
          v-model="InputData.RunStatus"
          label="Run Status"
          rules="required"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="InputData.RunStatusDurationInMinutes"
          :rules="[isNumberGreaterThanZero]"
          variant="outlined"
          label="Run Status Duration In Minutes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="InputData.Temperature"
          :rules="[isNumber]"
          variant="outlined"
          label="Current Space Temperature"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="InputData.SupplyAirTemperature"
          :rules="[isNumber]"
          variant="outlined"
          label="Supply Air Temperature"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="InputData.ReturnAirTemperature"
          :rules="[isNumber]"
          variant="outlined"
          label="Return Air Temperature"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import {
  isNumber,
  isNumberGreaterThanZero,
  hasValue,
  isDate,
} from '@/Core.Patterns/Factory/validationFactory.ts';

export default {
  name: 'ThermostatsInput',
  data() {
    return {
      devices: [],
      // line un with the RunStatus enum
      runStatuses: [
        { id: 0, shortName: 'CoolStage1', text: 'Cool Stage 1' },
        { id: 1, shortName: 'CoolStage2', text: 'Cool Stage 2' },
        { id: 2, shortName: 'Fan', text: 'Fan' },
        { id: 3, shortName: 'Fan2', text: 'Fan2' },
        { id: 4, shortName: 'HeatStage1', text: 'Heat Stage 1' },
        { id: 5, shortName: 'HeatStage2', text: 'Heat Stage 2' },
        { id: 6, shortName: 'Off', text: 'Off' },
        { id: 7, shortName: 'On', text: 'On' },
      ],
      // line up with the system enum
      systemEnum: [
        { id: 0, text: 'Auto' },
        { id: 1, text: 'Off' },
        { id: 2, text: 'Cool' },
        { id: 3, text: 'Heat' },
      ],
    };
  },

  computed: {
    inputList() {
      // see if we have a list of datasources of this very object in datasources
      const inputs = this.DataSources.find(
        (ds) => ds.Key === '_selfList'
      )?.Value;
      if (inputs) return inputs;
      // if not, we'll create one based on InputData.ControlId/Name/FriendlyName values
      return [
        {
          Id: this.InputData.ControlId,
          DeviceName: this.InputData.ControlName,
          FriendlyName: this.InputData.ControlFriendlyName,
          Name: `${this.InputData.ControlFriendlyName} ( ${this.InputData.ControlName} )`,
        },
      ];
    },
  },

  props: {
    InputData: {
      type: Object,
      required: true,
    },
    DataSources: {
      type: Array,
      required: true,
    },
  },

  watch: {
    InputData: {
      handler() {
        // this handles the case where a user changes an entry in the Controls v-select
        // since we only get the ControlId (v-model), we need to set the rest
        // console.debug(`watch.InputData - selected item: ${this.InputData.ControlId}, name=${this.InputData.ControlName}, friendly=${this.InputData.ControlFriendlyName}`);
        const selected = this.inputList.find(
          (i) => i.Id == this.InputData.ControlId
        );
        if (selected) {
          this.InputData.ControlName = selected.Name;
          this.InputData.ControlFriendlyName = selected.FriendlyName;
        } else {
          this.InputData.ControlName = null;
          this.InputData.ControlFriendlyName = null;
        }
        console.debug(
          `watch.InputData - updated: ${this.InputData.ControlId}, name=${this.InputData.ControlName}, friendly=${this.InputData.ControlFriendlyName}`
        );
        this.$emit('update:model', this.InputData);
      },
      deep: true,
    },
  },

  methods: {
    isNumber,
    isNumberGreaterThanZero,
    hasValue,
    isDate,
  },
};
</script>
