<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 278 131"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      fill-opacity="0.3"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="M20 130H1V26V21.5H9.5H45V17L57 13L67.5 9.5L78.5 7.5V4.5L81.5 4V2.5L87.5 1.5V7H126V1.5L132.5 2.5V4L135.5 4.5V7L146 9.5L157.5 13L169 17V21.5H261.5V18H277V20.5H272.5V24H271.5V130H233V120.5H213.5V130H125V127.5H93V130H47.5V121.5H28.5L20 130Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="handleClick"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
defineProps<{
  color?: string;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};

const handleClick = (event: MouseEvent) => {
  emits('click', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
