import actions from '@/Core.Service.Domain/Controls/VAVs/Store/actions.ts';
import getters from '@/Core.Service.Domain/Controls/VAVs/Store/getters.ts';
import mutations from '@/Core.Service.Domain/Controls/VAVs/Store/mutations.ts';

import { IVAV } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const state = {
  editVAV: {} as IVAV,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
