import { markRaw, defineAsyncComponent } from 'vue';
import { store } from '@/store';

import LoadingComponent from '@/Core.Service.Domain/Controls/DigitalTwin/Components/ImageLoader.vue';

const SecondFloor = defineAsyncComponent({
  loader: () =>
    import(
      '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/6822-02.vue'
    ).then((module) => {
      store.dispatch('DigitalTwinStore/addComponentToCachedList', '6822-02');
      return module.default;
    }),

  loadingComponent: LoadingComponent,

  delay: 0,
});

// Suites
import Suite211 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesSchematics/6822-02-suite-211.vue';
import Suite213 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesSchematics/6822-02-suite-213.vue';
import Suite221 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesSchematics/6822-02-suite-221.vue';
import Suite2fpch from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesSchematics/6822-02-suite-2fpch.vue';
import Suite2fciW from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesSchematics/6822-02-suite-2fciW.vue';
import Suite2fciE from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesSchematics/6822-02-suite-2fciE.vue';

// Hotspots
import Suite2fpchHotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesHotspots/suite2fpchDrawing.vue';
import Suite2fciWHotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesHotspots/suite2fciWDrawing.vue';
import Suite2fciEHotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesHotspots/suite2fciEDrawing.vue';
import Suite211Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesHotspots/suite211Drawing.vue';
import Suite213Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesHotspots/suite213Drawing.vue';
import Suite221Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesHotspots/suite221Drawing.vue';

// VAVs in the Common area
import VAV28 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/CommonArea/VAV28Drawing.vue';
import VAV29 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/CommonArea/VAV29Drawing.vue';
import VAV210 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/CommonArea/VAV210Drawing.vue';
import VAV211 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/CommonArea/VAV211Drawing.vue';

// VAVs in suites
import Suite211ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_2nd_Floor/suite211ControlsList.ts';
import Suite213ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_2nd_Floor/suite213ControlsList.ts';
import Suite221ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_2nd_Floor/suite221ControlsList';
import Suite2fpchControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_2nd_Floor/suite2fpchControlsList';
import Suite2fciEControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_2nd_Floor/suite2fciEControlsList.ts';
import Suite2fciWControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_2nd_Floor/suite2fciWControlsList.ts';

import {
  dialog,
  childTab,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors';

import { IFloor } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import eventBus from '@/Core.Service.Domain/Shared/eventBus';

const handleAction = (tab: MouseEvent | KeyboardEvent | number) => {
  if (typeof tab === 'number') {
    dialog.value = true;
    childTab.value = tab;
    eventBus.$emit('update:selectedVAVId', 0);
  }
};

const handleVAVAction = async (
  controlId: MouseEvent | KeyboardEvent | number
) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = true;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const secondFloor: IFloor<Record<string, unknown>> = {
  id: 0,
  name: 'MGMOB - 2nd Floor',
  ahuId: 2092,
  loadedName: '6822-02',
  component: markRaw(SecondFloor),
  showAHUButton: true,
  hotspotsList: [
    // Suite 221
    {
      id: 0,
      name: 'Suite 221',
      component: markRaw(Suite221Hotspot),
      techInfo: {
        location: 'Suite 221',
        icon: '',
        devices: ['VAV 2-59', 'VAV 2-60', 'VAV 2-61'],
      },
      style: {
        position: 'absolute',
        top: '3.75%',
        left: '2.7%',
        width: '28%',
        height: '28%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '3.625%',
        left: '3%',
        width: '27.2%',
        height: '27.2%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '15%',
        left: '15%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 213
    {
      id: 1,
      name: 'Suite 213',
      component: markRaw(Suite213Hotspot),
      techInfo: {
        location: 'Suite 213',
        icon: '',
        devices: ['VAV 2-62'],
      },
      style: {
        position: 'absolute',
        top: '3.75%',
        left: '19.6%',
        width: '27.3%',
        height: '27.3%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '3.3%',
        left: '19.8%',
        width: '26.8%',
        height: '26.8%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '15%',
        left: '31%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 211
    {
      id: 2,
      name: 'Suite 211',
      component: markRaw(Suite211Hotspot),
      techInfo: {
        location: 'Suite 211',
        icon: '',
        devices: ['VAV 2-12', 'VAV 2-77', 'VAV 2-78', 'VAV 2-79'],
      },
      style: {
        position: 'absolute',
        top: '-2.2%',
        right: '28.48%',
        width: '35%',
        height: '35%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '-2.66%',
        left: '36.5%',
        width: '35%',
        height: '35%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '15%',
        right: '45%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 2fpch
    {
      id: 3,
      name: 'Suite 205',
      component: markRaw(Suite2fpchHotspot),
      techInfo: {
        location: 'Suite 205',
        icon: '',
        devices: [
          'VAV 2-13',
          'VAV 2-14',
          'VAV 2-15',
          'VAV 2-16',
          'VAV 2-17',
          'VAV 2-19',
          'VAV 2-20',
          'VAV 2-21',
        ],
      },
      style: {
        position: 'absolute',
        top: '3.72%',
        right: '2.42%',
        width: '28%',
        height: '28%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '3.625%',
        right: '2.8%',
        width: '27.3%',
        height: '27.3%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '15%',
        right: '15%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 2fciE
    {
      id: 4,
      name: 'Suite 200B',
      component: markRaw(Suite2fciEHotspot),
      techInfo: {
        location: 'Suite 200B',
        icon: '',
        devices: [
          'VAV 2-63',
          'VAV 2-70',
          'VAV 2-71',
          'VAV 2-72',
          'VAV 2-73',
          'VAV 2-74',
          'VAV 2-75',
          'VAV 2-76',
        ],
      },
      style: {
        position: 'absolute',
        top: '35.8%',
        right: '3.45%',
        width: '46.4%',
        height: '46.4%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '34.1%',
        right: '3.5%',
        width: '46.5%',
        height: '46.5%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        bottom: '42%',
        right: '20%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 2fciW
    {
      id: 5,
      name: 'Suite 200A',
      component: markRaw(Suite2fciWHotspot),
      techInfo: {
        location: 'Suite 200A',
        icon: '',
        devices: [
          'VAV 2-63',
          'VAV 2-64',
          'VAV 2-65',
          'VAV 2-66',
          'VAV 2-67',
          'VAV 2-68',
          'VAV 2-69',
        ],
      },
      style: {
        position: 'absolute',
        top: '35.6%',
        left: '3.5%',
        width: '46.9%',
        height: '46.9%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '34%',
        right: '4.8%',
        width: '47%',
        height: '47%',
      },
      hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
        handleAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        bottom: '42%',
        left: '20%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 2-8
    {
      id: 6,
      name: 'VAV 2-8',
      controlId: 2216,
      isFloorVAV: true,
      component: markRaw(VAV28),
      techInfo: {
        location: 'VAV 2-8',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '11.25%',
        left: '3.5%',
        width: '45.07%',
        height: '45.07%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        bottom: '3.2%',
        right: '4.8%',
        width: '54.3%',
        height: '54.3%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '32.5%',
        left: '20%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 2-9
    {
      id: 7,
      name: 'VAV 2-9',
      controlId: 2217,
      isFloorVAV: true,
      component: markRaw(VAV29),
      techInfo: {
        location: 'VAV 2-9',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '11.27%',
        right: '3.5%',
        width: '44.5%',
        height: '44.5%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        bottom: '3.2%',
        right: '4.8%',
        width: '54.3%',
        height: '54.3%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '32.5%',
        right: '20%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 2-10
    {
      id: 8,
      name: 'VAV 2-10',
      controlId: 2218,
      isFloorVAV: true,
      component: markRaw(VAV210),
      techInfo: {
        location: 'VAV 2-10',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '30%',
        right: '38.05%',
        width: '27.3%',
        height: '27.3%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        bottom: '3.2%',
        right: '4.8%',
        width: '54.3%',
        height: '54.3%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '45%',
        right: '49%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 2-11
    {
      id: 9,
      name: 'VAV 2-11',
      controlId: 2219,
      isFloorVAV: true,
      component: markRaw(VAV211),
      techInfo: {
        location: 'VAV 2-11',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '33.7%',
        right: '51.6%',
        width: '10.85%',
        height: '10.85%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        bottom: '3.2%',
        right: '4.8%',
        width: '54.3%',
        height: '54.3%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '38%',
        right: '57%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
  ],
  childrenSuites: [
    // Suite 221
    {
      id: 0,
      name: 'Suite 221',
      disabled: false,
      styles: {
        width: '85%',
        transform: 'translate(25%, 5%)',
        height: 'auto',
        top: '0',
      },
      component: markRaw(Suite221),
      controlsList: [...Suite221ControlsList],
      activeTooltip: null,
    },
    // Suite 213
    {
      id: 1,
      name: 'Suite 213',
      disabled: false,
      styles: {
        width: '25%',
        height: 'auto',
        transform: 'translate(200%, 5%)',
        top: '0',
      },
      component: markRaw(Suite213),
      controlsList: [...Suite213ControlsList],
      activeTooltip: null,
    },
    // Suite 211
    {
      id: 2,
      name: 'Suite 211',
      disabled: false,
      styles: {
        width: '90%',
        height: 'auto',
        transform: 'translate(25%, 5%)',
        top: '0',
      },
      component: markRaw(Suite211),
      controlsList: [...Suite211ControlsList],
      activeTooltip: null,
    },
    // Suite 2fpch
    {
      id: 3,
      name: 'Suite 205',
      disabled: false,
      styles: {
        width: '90%',
        height: 'auto',
        transform: 'translate(25%, 5%)',
        top: '0',
      },
      component: markRaw(Suite2fpch),
      controlsList: [...Suite2fpchControlsList],
      activeTooltip: null,
    },
    // Suite 2fciE
    {
      id: 4,
      name: 'Suite 200B',
      disabled: false,
      styles: {
        width: '90%',
        height: 'auto',
        transform: 'translate(25%, 5%)',
        top: '0',
      },
      component: markRaw(Suite2fciE),
      controlsList: [...Suite2fciEControlsList],
      activeTooltip: null,
    },
    // Suite 2fciW
    {
      id: 5,
      name: 'Suite 200A',
      disabled: false,
      styles: {
        width: '90%',
        height: 'auto',
        transform: 'translate(25%, 5%)',
        top: '0',
      },
      component: markRaw(Suite2fciW),
      controlsList: [...Suite2fciWControlsList],
      activeTooltip: null,
    },
  ],
  activeTooltip: null,
};

export default secondFloor;
