<template>
  <v-dialog
    v-model="dialogValue"
    width="80%"
    transition="slide-x-reverse-transition"
    content-class="map-dialog"
  >
    <v-card class="rounded-2 px-10 py-9 map-dialog__card">
      <v-toolbar color="white" class="mb-5">
        <v-toolbar-title class="text-primary font-weight-bold px-0 text-h5">
          {{ `${controlDetails.name} - Settings Changes' History` }}
          <p class="text-primary text-subtitle-2 mt-2">
            {{ controlDetails.siteLocationName }}
          </p>
        </v-toolbar-title>
        <div>
          <v-btn
            variant="plain"
            size="small"
            class="mr-n3"
            @click="dialogValue = false"
          >
            <v-icon color="primary"> mdi-close-circle </v-icon>
          </v-btn>
        </div>
      </v-toolbar>
      <!--Settings History List-->
      <ThermostatSettingsHistoryList
        :SettingsHistoryList="SettingsHistoryList"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/Core.Service.Domain/Controls/Thermostats/API';
import { mapActions } from 'vuex';

import ThermostatSettingsHistoryList from '@/Core.Service.Domain/Controls/Thermostats/Components/ThermostatSettingsHistoryList.vue';

export default {
  name: 'ThermostatHistoryDialog',

  props: {
    controlDetails: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ThermostatSettingsHistoryList,
  },

  beforeUnmount() {
    this.setControlsSettingsHistoryMessagesTimerOn(false);
    this.setLateraMenuOpen(false);
  },

  mounted() {
    this.handleSettingsHistory();
    this.setControlsSettingsHistoryMessagesTimerOn(true);
    this.setLateraMenuOpen(true);
  },

  computed: {
    dialogValue: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },

  data() {
    return {
      SettingsHistoryList: [],
    };
  },

  methods: {
    ...mapActions({
      setControlsSettingsHistoryMessagesTimerOn:
        'ControlsStore/setControlsSettingsHistoryMessagesTimerOn',
      setLateraMenuOpen: 'UIStore/setLateraMenuOpen',
    }),

    async handleSettingsHistory() {
      try {
        this.SettingsHistoryList = await API.GetThermostatSettingsMessages(
          this.controlDetails.controlId
        );
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.$emit('update:settingsHistoryList');
      }
    },
  },
};
</script>
