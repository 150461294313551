<template>
  <v-card class="rounded-2 pa-3">
    <v-row>
      <!-- Left panel and messages list -->
      <v-col cols="3">
        <!-- Tabs -->
        <v-row>
          <v-col>
            <v-tabs
              :model-value="selectedTab"
              @update:modelValue="handleChangeTab"
            >
              <v-tab>
                <v-badge
                  v-if="messagesCount > 0"
                  :content="messagesCount"
                  location="right"
                  color="accentOrange"
                  offset-x="60"
                >
                  <span class="text-primary">Unread</span>
                </v-badge>
                <span class="text-primary" v-else> Unread </span>
              </v-tab>
              <v-tab>
                <span class="text-primary">Read</span>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <!-- Messages list -->
        <v-row>
          <v-col>
            <v-list>
              <v-list-item
                v-for="(item, i) in getUnreadMessagesList"
                :key="i"
                @click="selectedMessage = item"
              >
                <v-list-item-title>
                  <span class="text-primary text-subtitle-2">
                    {{ item.messageCenterMessageLabel }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list>
              <v-list-item
                v-for="(item, i) in getReadMessagesList"
                :key="i"
                @click="selectedMessage = item"
              >
                <v-list-item-title>
                  <span class="text-primary text-subtitle-2">
                    {{ item.messageCenterMessageLabel }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
      <!-- Right panel and message panel -->
      <v-col cols="9">
        <v-alert
          icon="mdi-email-open-outline"
          prominent
          type="info"
          color="primary"
          class="ma-3"
          v-if="
            isReadMessagesListEmptyAndTabIsRead ||
            isUnreadMessagesListEmptyAndTabIsUnread
          "
        >
          {{
            `You have no ${
              isUnreadMessagesListEmptyAndTabIsUnread ? 'Unread' : 'Read'
            } messages.`
          }}
        </v-alert>
        <MessageCenterMessagePanel
          v-else
          @toggle="toggleMessage"
          :selectedMessage="selectedMessage"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import MessageCenterMessagePanel from '@/Core.Service.Domain/MessageCenter/Users/Components/MessageCenterMessagePanel.vue';

import { IUserMessage } from '@/Core.Service.Domain/MessageCenter/Users/types';

// Reactive variables<Refs>
const selectedMessage = ref<IUserMessage>({
  isExpired: false,
  isRead: false,
  messageCenterMessageCreateDateTimeOffset: '',
  messageCenterMessageLabel: '',
  messageCenterMessageTypeId: 0,
  messagingCenterMessageValue: '',
  userMessageCenterMessageId: 0,
});
const selectedTab = ref(0);

// Store
const store = useStore();
const messagesCount = computed(
  () => store.getters['MessageCenterUsersStore/getUnreadMessagesCount']
);
const messagesByUserList = computed(
  () => store.getters['MessageCenterUsersStore/getMessagesByUser']
);

// Store actions
const setUnreadMessagesCount = () =>
  store.dispatch('MessageCenterUsersStore/setUnreadMessagesCount');
const getMessagesByUser = () =>
  store.dispatch('MessageCenterUsersStore/setMessagesByUser');

// Computed
const getReadMessagesList = computed(() =>
  messagesByUserList.value.filter((item: { isRead: boolean }) => item.isRead)
);
const getUnreadMessagesList = computed(() =>
  messagesByUserList.value.filter((item: { isRead: boolean }) => !item.isRead)
);
const isReadMessagesListEmptyAndTabIsRead = computed(
  () => getReadMessagesList.value.length === 0 && selectedTab.value === 1
);
const isUnreadMessagesListEmptyAndTabIsUnread = computed(
  () => getUnreadMessagesList.value.length === 0 && selectedTab.value === 0
);

// Lifecycle Hooks
const router = useRouter();
watch(router.currentRoute, (to) => {
  if (to.name === 'MessageCenterMyMessages') {
    handleLoadMessages();
  }
});

onMounted(async () => {
  await handleLoadMessages();
  if (isUnreadMessagesListEmptyAndTabIsUnread.value) {
    selectedTab.value = 1;
    selectedMessage.value = getReadMessagesList.value[0];
  } else {
    selectedMessage.value = getUnreadMessagesList.value[0];
  }
});

// Methods
const handleLoadMessages = async () => {
  try {
    await getMessagesByUser();
  } catch (error) {
    return Promise.reject(error);
  }
};

const toggleMessage = async () => {
  await handleLoadMessages();
  setUnreadMessagesCount();
};

const handleChangeTab = (value: unknown) => {
  selectedTab.value = value as number;
};
</script>
<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
}
.v-badge :deep(.v-badge__badge) {
  width: auto;
  letter-spacing: -1px;
  top: -7px !important;
}
</style>
