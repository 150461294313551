<template>
  <v-card class="px-5 py-3 rounded-2">
    <v-container fluid>
      <TitleBar :label="`Edit Non-Routine Event`" />
      <v-form @submit.prevent="submit" validate-on="input">
        <v-row>
          <v-col>
            <v-text-field
              v-model="nonRoutineEvent.name"
              :model-value="nonRoutineEvent.name"
              label="Non-Routine Event Name"
              hint="Name for this Non-Routine Event"
              persistent-hint
              :rules="[(v) => !!v || 'Name is required']"
              variant="outlined"
              density="compact"
              color="primary"
            />
          </v-col>
          <v-col>
            <v-select
              :items="nonRoutineEventTypes"
              :rules="[(v) => !!v || 'Type is required']"
              v-model="nonRoutineEvent.nreTypeId"
              :model-value="nonRoutineEvent.nreTypeId"
              label="Non-Routine Event Type"
              hint="What type of Non-Routine Event is this?"
              persistent-hint
              variant="outlined"
              density="compact"
              color="primary"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <start-end-date-picker
              name="date range"
              rules="required|valid_date_range"
              v-model="dateRange"
              @change="handleSetDateRange"
              date-format="yyyy-MM-dd"
              ref="startEndDatePicker"
              disabled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-textarea
            rows="2"
            label="Comments"
            clearable
            clear-icon="mdi-close-circle"
            variant="outlined"
            auto-grow
            v-model="nonRoutineEvent.comment"
            class="pr-3 pl-3"
          />
        </v-row>

        <v-row v-if="!loading">
          <v-col>
            <v-btn
              type="submit"
              color="primary"
              :disabled="!isValidNonRoutineEvent"
              :loading="isLoading"
            >
              Save
            </v-btn>
            <v-btn
              v-if="!hasAvailableVersions"
              class="ml-5"
              color="primary"
              title="Download new energy adjustment spreadsheet"
              @click="handleDownloadNewSheet()"
            >
              Download New Spreadsheet
            </v-btn>
            <v-btn
              v-if="hasAvailableVersions"
              location="left"
              class="ml-5"
              color="secondary"
              title="Download existing energy adjustment spreadsheet"
              @click="openDownloadExistingSheetModal()"
            >
              <v-icon>mdi-cloud-download-outline</v-icon>
            </v-btn>
            <v-btn
              class="ml-5"
              color="secondary"
              title="Upload energy adjustment spreadsheet"
              @click="openUploadSheetModal()"
            >
              <v-icon>mdi-cloud-upload-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="hasAvailableVersions"
              class="ml-5"
              color="secondary"
              title="View Energy Adjustment Chart"
              @click="openViewChartModal()"
            >
              <v-icon>mdi-chart-bar</v-icon>
            </v-btn>
            <v-btn @click="handleCancelCrud" class="ml-5"> Cancel </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-dialog width="800" v-model="showUploadSheetModal">
        <v-card>
          <v-card-title class="text-h5">Upload spreadsheet</v-card-title>
          <v-file-input
            small-chips
            label="Select Energy Adjustment Data"
            v-model="energyAdjustmentFile"
            class="pr-4"
          />
          <v-card-actions>
            <v-btn @click="handleUploadSheet()" color="primary">Upload</v-btn>
            <v-btn @click="closeUploadSheetModal()" class="ml-5">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="800" v-model="showDownloadExistingSheetModal">
        <v-card>
          <v-card-title class="text-h5">
            Download existing spreadsheet
          </v-card-title>
          <v-card-text>
            <v-select v-model="selectedVersion" :items="availableVersions" />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="handleDownloadExistingSheet()" color="primary">
              Download
            </v-btn>
            <v-btn @click="closeDownloadExistingSheetModal()" class="ml-5">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="800" v-model="showViewChartModal">
        <v-card>
          <v-card-title class="text-h5">
            View Energy Adjustment Chart
          </v-card-title>
          <v-card-text>
            <v-select v-model="selectedVersion" :items="availableVersions" />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="handleViewChart()" color="primary">View Chart</v-btn>
            <v-btn @click="closeViewChartModal()" class="ml-5">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker';
import api from '../_api';

import TitleBar from '@/Core.Service.Domain/Monitors/Monitors.Common/Components/TitleBar.vue';

export default {
  name: 'EditNonRoutineEvent',
  components: {
    'start-end-date-picker': StartEndDatePicker,
    TitleBar,
  },

  data() {
    return {
      valid: true,
      nonRoutineEventId: null,
      nonRoutineEvent: {
        name: null,
        nonRoutineEventTypeId: null,
        startDateTime: null,
        endDateTime: null,
        comment: null,
      },
      nonRoutineEventTypes: [],
      selectedNonRoutineEventType: null,
      dateRange: {},
      isValidNonRoutineEvent: true,

      showUploadSheetModal: false,
      energyAdjustmentFile: null,

      showDownloadExistingSheetModal: false,
      showViewChartModal: false,
      availableVersions: [],
      selectedVersion: null,
      loading: true,
      isLoading: false,
    };
  },

  async created() {
    try {
      this.nonRoutineEventId = this.$route.params.nonRoutineEventId;
      const response = await api.getNonRoutineEventById(this.nonRoutineEventId);
      this.nonRoutineEvent = response;
      await this.getNonRoutineEventTypes();
      this.availableVersions = this.nonRoutineEvent.energyAdjustmentVersions;
      this.selectedVersion = Math.max(this.availableVersions);
      this.dateRange = {
        startDateTime: this.nonRoutineEvent.startDateTime.substring(0, 10),
        endDateTime: this.nonRoutineEvent.endDateTime.substring(0, 10),
      };
    } catch (error) {
      return Promise.reject(error);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    hasAvailableVersions() {
      return this.availableVersions.length;
    },
  },

  methods: {
    async submit(event) {
      const { valid } = await event;
      if (!valid) {
        return Promise.reject({
          Error: 'Please fill in all required fields',
        });
      }

      this.isLoading = true;

      try {
        this.nonRoutineEvent.analyticModels = this.selectedAnalyticModels;
        await api.updateNonRoutineEvent(this.nonRoutineEvent);

        const options = {
          title: 'Success',
          message: 'Non-routine Event updated',
          status: 'success',
        };
        this.$toast.show(options);
        this.$router.push({ name: 'NonRoutineEvents' });
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleSetDateRange(dateRange) {
      this.nonRoutineEvent.startDateTime = dateRange.startDateTime;
      this.nonRoutineEvent.endDateTime = dateRange.endDateTime;

      this.dateRange = {
        startDateTime: dateRange.startDateTime,
        endDateTime: dateRange.endDateTime,
      };
    },

    async getNonRoutineEventTypes() {
      try {
        const response = await api.getNonRoutineEventTypes();
        this.nonRoutineEventTypes = response;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    //Download New Sheet
    async handleDownloadNewSheet() {
      try {
        this.loading = true;
        const zipStream = await api.downloadNewEnergyAdjustmentSpreadsheet(
          this.nonRoutineEventId
        );
        const fileURL = window.URL.createObjectURL(
          new Blob([zipStream], { type: 'application/vnd.ms-excel' })
        );
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        const fileName = `${this.nonRoutineEvent.year} Energy Adjustment for ${this.nonRoutineEvent.name}`;
        fileLink.setAttribute('download', fileName + '.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.loading = false;
      }
    },

    //Download Existing Sheet
    openDownloadExistingSheetModal() {
      this.showDownloadExistingSheetModal = true;
    },

    closeDownloadExistingSheetModal() {
      this.showDownloadExistingSheetModal = false;
    },

    async handleDownloadExistingSheet() {
      try {
        const zipStream = await api.downloadExistingEnergyAdjustmentSpreadsheet(
          this.nonRoutineEventId,
          this.selectedVersion
        );
        const fileURL = window.URL.createObjectURL(
          new Blob([zipStream], { type: 'application/vnd.ms-excel' })
        );
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        const fileName = `${this.nonRoutineEvent.year} Energy Adjustment for ${this.nonRoutineEvent.name} v${this.selectedVersion}`;
        fileLink.setAttribute('download', fileName + '.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.loading = false;
      }
    },

    //Upload Sheet
    openUploadSheetModal() {
      this.showUploadSheetModal = true;
    },

    closeUploadSheetModal() {
      this.showUploadSheetModal = false;
    },

    async handleUploadSheet() {
      try {
        this.loading = true;
        await api.uploadEnergyAdjustmentSpreadsheet(
          this.nonRoutineEventId,
          this.energyAdjustmentFile
        );
        this.$router.go(0);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.loading = false;
      }
    },

    //View Energy Adjustment Chart
    openViewChartModal() {
      this.showViewChartModal = true;
    },

    closeViewChartModal() {
      this.showViewChartModal = false;
    },

    handleViewChart() {
      this.$router.push({
        name: 'NREEnergyAdjustmentChart',
        params: {
          nonRoutineEventId: this.nonRoutineEventId,
          version: this.selectedVersion,
        },
      });
    },

    handleCancelCrud() {
      this.$router.push({ name: 'NonRoutineEvents' });
    },
  },
};
</script>
