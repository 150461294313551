import VAVsPortfolio from '@/Core.Service.Domain/Controls/VAVs/Views/IndexView.vue';

import EditVAV from '@/Core.Service.Domain/Controls/VAVs/Views/EditVAV.vue';

export const VAVsRoute = {
  path: 'vavs',
  name: 'VAVsPortfolio',
  component: VAVsPortfolio,
  children: [
    {
      path: 'history/:id',
      name: 'VAVHistory',
    },
  ],
  meta: {
    placeholder: 'Search for a VAV',
    controlTypeName: 'VAV',
    label: 'VAVs',
    toolTip: 'VAV',
    permission: 'controls:read',
  },
};

export const EditVAVRoute = {
  path: 'edit-vav/:controlId',
  name: 'EditVAV',
  props: true,
  component: EditVAV,
  meta: {
    label: 'Edit VAV',
    toolTip: 'Edit VAV',
    permission: 'controls:update',
  },
};
