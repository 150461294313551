import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
const apiVersion = 'v1';

const getAnalyticsNonRoutineEventsBySiteId = async (site) => {
  const { id } = RemapObjectKeysAdapter(site, ['siteId'], ['id']);

  try {
    const nresResponse = await window.axios.get(
      `/${apiVersion}/NonRoutineEvent/site/${id}`
    );
    if (nresResponse && nresResponse.data) {
      return nresResponse.data.data;
    } else {
      return null;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSitesWithAnalyticsNonRoutineEvents = async () => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/NonRoutineEvent/site/sites`
    );

    const { data } = result.data;
    data.forEach((site) => {
      RemapObjectKeysAdapter(site, ['siteId'], ['id']);
    });
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteAnalyticsNonRoutineEvent = async (id) => {
  try {
    const result = await window.axios.delete(
      `/${apiVersion}/nonroutineevent/delete/${id}`
    );
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getSitesWithAnalyticsNonRoutineEvents,
  getAnalyticsNonRoutineEventsBySiteId,
  deleteAnalyticsNonRoutineEvent,
};
