import { IVAV } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

type VAVState = {
  editVAV: IVAV;
};

const getEditVAV = (state: VAVState) => {
  return state.editVAV;
};

export default {
  getEditVAV,
};
