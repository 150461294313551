<template>
  <v-container fluid>
    <PortfolioBar />
    <PortfolioList :portfolioList="portfolioList" />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </v-container>
</template>

<script>
import PortfolioList from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/PortfolioList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

import PortfolioBar from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/PortfolioBar.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PortfolioView',

  inject: ['isSSOUser'],

  components: { PortfolioList, ProgressLinearLoaderVue, PortfolioBar },

  computed: {
    ...mapGetters({
      defaultSite: 'session/defaultSite',
      portfolioList: 'ViewSupportStore/PortfolioStore/getPortfolioSitesList',
    }),
  },

  data() {
    return {
      isLoading: true,
      ssoAttempts: 0,
    };
  },

  async created() {
    try {
      await this.getPortfolioSites();

      // if this is an SSO user, and on their first visit,
      // we need to wait for their profile to be created
      const doesThisUserUseSso = this.isSSOUser();
      if (doesThisUserUseSso) {
        while (!this.portfolioList.length && this.ssoAttempts < 10) {
          this.ssoAttempts++;
          await this.getPortfolioSites();
          // TODO: debounce seconds * attempts ?
        }
        if (this.ssoAttempts >= 10) {
          console.error('Could not retrieve portfolio sites after 10 attempts');
        }
      }
      if (!this.defaultSite) {
        this.setDefaultSite(this.portfolioList[0]);
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      setDefaultSite: 'session/setDefaultSite',
      getPortfolioSites: 'ViewSupportStore/PortfolioStore/getPortfolioSites',
    }),
  },
};
</script>
