import { RemapObjectKeysAdapter } from '@/Core.Patterns/Adapter';
const apiVersion = 'v1';

const getControlsBySite = async (site: { [x: string]: string }) => {
  const { id } = RemapObjectKeysAdapter(site, ['siteId'], ['id']);

  try {
    const controlsResponse = await window.axios.get(
      `/${apiVersion}/controls/site`,
      { params: { siteId: id } }
    );
    if (controlsResponse && controlsResponse.data) {
      return controlsResponse.data.data;
    } else {
      return null;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSitesWithControls = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/controls/sites`);

    const { data } = result.data;
    data.forEach((site: { [x: string]: string }) => {
      RemapObjectKeysAdapter(site, ['siteId'], ['id']);
    });
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getControlConfig = async (siteId: number) => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/controlpermissions/site/${siteId}/scheduler/login`
    );
    const { data } = result.data;
    return {
      url: `https://${data.account}.officeclimatecontrol.net/login.cgi`,
      account: data.account,
      user: data.userName,
      pass: data.password,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getSitesWithControls,
  getControlsBySite,
  getControlConfig,
};
