const apiVersion = 'v1';

const getAnalyticsMeasures = async () => {
  try {
    const analyticsMeasuresResponse = await window.axios.get(
      `/${apiVersion}/Measure`
    );
    if (analyticsMeasuresResponse && analyticsMeasuresResponse.data) {
      return analyticsMeasuresResponse.data.data;
    } else {
      return null;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteAnalyticsMeasure = async (measureId) => {
  const data = {
    measureId,
  };
  try {
    const result = await window.axios.delete(`/${apiVersion}/measure/delete/`, {
      data,
    });
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getAnalyticsMeasures,
  deleteAnalyticsMeasure,
};
