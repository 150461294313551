<template>
  <div>
    <StrategiesBar
      v-if="
        !isRouteStrategiesExecutionHistory && !isRouteReplayStrategyExecution
      "
    />
    <router-view />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import StrategiesBar from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategiesBar.vue';

import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

const store = useStore();
const route = useRoute();

const isLoading = computed(() => {
  return store.getters[
    'StrategiesStore/StrategiesStore/selectedSiteStrategiesIsLoading'
  ];
});

const isRouteStrategiesExecutionHistory = computed(() => {
  return route.name === 'StrategyExecutionHistory';
});

const isRouteReplayStrategyExecution = computed(() => {
  return route.name === 'StrategyExecutionHistoryReplay';
});
</script>
