const apiVersion = 'v1';
const rootPath = `${apiVersion}/AginovaMonitor`;

import {
  IAginovaMonitor,
  IAginovaMonitorData,
} from '@/Core.Service.Domain/Monitors/Aginova/types';

const getSitesForAginova = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/sites/portfolio`);
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAginovaMonitorsBySiteId = async (id: number) => {
  try {
    const result = await window.axios.get(`${rootPath}/monitors/site/${id}`);
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAginovaMonitorByDeviceId = async (deviceId: number) => {
  try {
    const result = await window.axios.get(`${rootPath}/${deviceId}`);
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSiteLocationsForAginovaMonitor = async (siteId: number) => {
  try {
    const result = await window.axios.get(
      `${apiVersion}/SiteLocation/SiteLocations/${siteId}`
    );
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateAginovaMonitor = async (AginovaMonitor: IAginovaMonitor) => {
  try {
    const result = await window.axios.put(`${rootPath}/update`, AginovaMonitor);
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteAginovaMonitor = async (AginovaMonitor: IAginovaMonitor) => {
  const { siteId, deviceId } = AginovaMonitor;
  try {
    const result = await window.axios.delete(`${rootPath}/delete`, {
      data: {
        siteId,
        deviceId,
      },
    });
    const { data } = result.data;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const recoverAginovaData = async (AginovaMonitorData: IAginovaMonitorData) => {
  try {
    const result = await window.axios.post(
      `/${apiVersion}/aginovadatarecovery/request`,
      AginovaMonitorData
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getAginovaMonitorsBySiteId,
  getSitesForAginova,
  deleteAginovaMonitor,
  getAginovaMonitorByDeviceId,
  getSiteLocationsForAginovaMonitor,
  updateAginovaMonitor,
  recoverAginovaData,
};
