<template>
  <svg
    width="213"
    height="169"
    viewBox="0 0 213 169"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="213" height="169" fill="white" />
    <path d="M-8.23999 2.74001H8.84001" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 8.35001H6.44002" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 7.37H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 6.39H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 5.39999H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 4.42H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 3.44H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M-9 1.41H10.17" stroke="black" stroke-width="0.5" />
    <path d="M8.84 2.74001V12.81" stroke="black" stroke-width="0.5" />
    <path d="M6.70001 2.74001V7.37" stroke="black" stroke-width="0.5" />
    <path d="M6.44 7.37V8.35001" stroke="black" stroke-width="0.5" />
    <path d="M10.17 1.41V11.48" stroke="black" stroke-width="0.5" />
    <path d="M8.84 12.81H11.61" stroke="black" stroke-width="0.5" />
    <path d="M10.17 11.48H11.61" stroke="black" stroke-width="0.5" />
    <path d="M11.08 11.48V12.81" stroke="black" stroke-width="0.5" />
    <path d="M11.61 11.48V12.81" stroke="black" stroke-width="0.5" />
    <path d="M21.22 11.48H28.69" stroke="black" stroke-width="0.5" />
    <path d="M21.22 12.81H28.69" stroke="black" stroke-width="0.5" />
    <path d="M28.69 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M28.15 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M21.75 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M21.22 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M21.75 12.15H28.15" stroke="black" stroke-width="0.5" />
    <path d="M28.99 11.48L39.06 1.41" stroke="black" stroke-width="0.5" />
    <path d="M29.24 12.81L39.31 2.74001" stroke="black" stroke-width="0.5" />
    <path d="M39.31 2.74001V1.41" stroke="black" stroke-width="0.5" />
    <path d="M39.31 2.74001H64.18" stroke="black" stroke-width="0.5" />
    <path d="M64.18 2.74001V1.41" stroke="black" stroke-width="0.5" />
    <path d="M74.25 11.48L64.18 1.41" stroke="black" stroke-width="0.5" />
    <path d="M74.25 12.81L64.18 2.74001" stroke="black" stroke-width="0.5" />
    <path d="M74.25 11.48H81.72" stroke="black" stroke-width="0.5" />
    <path d="M74.25 12.81H81.72" stroke="black" stroke-width="0.5" />
    <path d="M81.72 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M91.32 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M91.86 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M81.18 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M74.78 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M74.25 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M74.78 12.15H81.18" stroke="black" stroke-width="0.5" />
    <path d="M91.32 12.81H99.9" stroke="black" stroke-width="0.5" />
    <path d="M91.32 11.48H93.02" stroke="black" stroke-width="0.5" />
    <path d="M94.35 11.48H99.9" stroke="black" stroke-width="0.5" />
    <path d="M39.06 1.41H64.18" stroke="black" stroke-width="0.5" />
    <path d="M28.69 11.48H28.99" stroke="black" stroke-width="0.5" />
    <path d="M93.02 1.41V11.48" stroke="black" stroke-width="0.5" />
    <path d="M94.35 2.74001V11.48" stroke="black" stroke-width="0.5" />
    <path d="M93.02 1.41H99.58" stroke="black" stroke-width="0.5" />
    <path d="M105.98 1.41H166.17" stroke="black" stroke-width="0.5" />
    <path d="M93.02 2.74001H123.38" stroke="black" stroke-width="0.5" />
    <path d="M99.58 0.0800018V1.41" stroke="black" stroke-width="0.5" />
    <path d="M99.9 2.74001V11.48" stroke="black" stroke-width="0.5" />
    <path d="M99.9 12.81V29.01" stroke="black" stroke-width="0.5" />
    <path d="M99.9 41.82V44.71" stroke="black" stroke-width="0.5" />
    <path d="M101.23 2.74001V29.01" stroke="black" stroke-width="0.5" />
    <path d="M99.58 0.0800018H105.98" stroke="black" stroke-width="0.5" />
    <path d="M105.98 0.0800018V1.41" stroke="black" stroke-width="0.5" />
    <path d="M99.9 41.82H101.23" stroke="black" stroke-width="0.5" />
    <path d="M99.9 29.01H101.23" stroke="black" stroke-width="0.5" />
    <path d="M101.23 41.82V43.38" stroke="black" stroke-width="0.5" />
    <path d="M99.9 44.71H126.67" stroke="black" stroke-width="0.5" />
    <path d="M101.23 43.38H123.38" stroke="black" stroke-width="0.5" />
    <path d="M123.38 2.74001V43.38" stroke="black" stroke-width="0.5" />
    <path d="M124.71 2.74001V43.38" stroke="black" stroke-width="0.5" />
    <path d="M124.71 43.38H126.67" stroke="black" stroke-width="0.5" />
    <path d="M126.67 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M140.99 2.74001V43.38" stroke="black" stroke-width="0.5" />
    <path d="M140.99 44.71V50.09" stroke="black" stroke-width="0.5" />
    <path d="M140.99 59.69V60.82" stroke="black" stroke-width="0.5" />
    <path d="M140.99 62.16V66.5" stroke="black" stroke-width="0.5" />
    <path d="M140.99 67.83V71.65" stroke="black" stroke-width="0.5" />
    <path d="M142.33 44.71V50.09" stroke="black" stroke-width="0.5" />
    <path d="M142.33 59.69V60.82" stroke="black" stroke-width="0.5" />
    <path d="M142.33 62.16V70.31" stroke="black" stroke-width="0.5" />
    <path d="M139.48 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M139.48 43.38H140.99" stroke="black" stroke-width="0.5" />
    <path d="M139.48 44.71H140.99" stroke="black" stroke-width="0.5" />
    <path d="M142.33 44.71H152.36" stroke="black" stroke-width="0.5" />
    <path d="M161.97 44.71H201.9" stroke="black" stroke-width="0.5" />
    <path d="M211.51 44.71H212.82" stroke="black" stroke-width="0.5" />
    <path d="M142.33 43.38H152.36" stroke="black" stroke-width="0.5" />
    <path d="M161.97 43.38H163.29" stroke="black" stroke-width="0.5" />
    <path d="M164.62 43.38H201.9" stroke="black" stroke-width="0.5" />
    <path d="M211.51 43.38H212.82" stroke="black" stroke-width="0.5" />
    <path d="M142.33 43.38V22.23" stroke="black" stroke-width="0.5" />
    <path d="M148.73 36.69V20.89" stroke="black" stroke-width="0.5" />
    <path d="M142.33 20.89V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M142.33 12.35H154.2" stroke="black" stroke-width="0.5" />
    <path d="M162.2 12.35H163.29" stroke="black" stroke-width="0.5" />
    <path d="M142.33 12.88H154.2" stroke="black" stroke-width="0.5" />
    <path d="M142.33 20.89H148.73" stroke="black" stroke-width="0.5" />
    <path d="M142.33 22.23H148.73" stroke="black" stroke-width="0.5" />
    <path d="M162.2 12.88H163.29" stroke="black" stroke-width="0.5" />
    <path d="M162.2 12.35V12.88" stroke="black" stroke-width="0.5" />
    <path d="M154.2 12.35V12.88" stroke="black" stroke-width="0.5" />
    <path d="M156.43 6.58L162.2 12.35" stroke="black" stroke-width="0.5" />
    <path d="M156.32 6.85001L162.2 12.73" stroke="black" stroke-width="0.5" />
    <path
      d="M156.43 6.58C155.003 8.16381 154.209 10.2179 154.2 12.35"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M162.2 12.73V12.35" stroke="black" stroke-width="0.5" />
    <path d="M148.08 2.74001H152.79" stroke="black" stroke-width="0.5" />
    <path d="M152.79 2.74001V4.55" stroke="black" stroke-width="0.5" />
    <path d="M152.79 4.55H148.08" stroke="black" stroke-width="0.5" />
    <path d="M148.08 4.55V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M145.7 43.38V41.2" stroke="black" stroke-width="0.5" />
    <path d="M145.7 41.2L147.34 39.02" stroke="black" stroke-width="0.5" />
    <path d="M147.34 39.02L148.73 36.69" stroke="black" stroke-width="0.5" />
    <path d="M144.53 6.02L145.09 5.95" stroke="black" stroke-width="0.5" />
    <path d="M145.09 5.95L145.51 5.89999" stroke="black" stroke-width="0.5" />
    <path d="M145.51 5.89999L145.87 5.84" stroke="black" stroke-width="0.5" />
    <path d="M145.87 5.84L146.17 5.77" stroke="black" stroke-width="0.5" />
    <path d="M146.17 5.77L146.41 5.72" stroke="black" stroke-width="0.5" />
    <path d="M146.41 5.72L146.6 5.67" stroke="black" stroke-width="0.5" />
    <path d="M146.6 5.67L146.72 5.64" stroke="black" stroke-width="0.5" />
    <path d="M146.72 5.64L146.82 5.61" stroke="black" stroke-width="0.5" />
    <path d="M146.82 5.61L146.93 5.60001" stroke="black" stroke-width="0.5" />
    <path d="M146.93 5.60001L147.07 5.59" stroke="black" stroke-width="0.5" />
    <path d="M147.07 5.59L147.27 5.58" stroke="black" stroke-width="0.5" />
    <path d="M147.27 5.58H147.51" stroke="black" stroke-width="0.5" />
    <path d="M147.51 5.58L147.78 5.59" stroke="black" stroke-width="0.5" />
    <path d="M147.78 5.59L148.08 5.63" stroke="black" stroke-width="0.5" />
    <path d="M148.08 5.63L148.39 5.7" stroke="black" stroke-width="0.5" />
    <path d="M148.39 5.7L148.7 5.8" stroke="black" stroke-width="0.5" />
    <path d="M148.7 5.8L149 5.92" stroke="black" stroke-width="0.5" />
    <path d="M149 5.92L149.28 6.08" stroke="black" stroke-width="0.5" />
    <path d="M149.28 6.08L149.54 6.25999" stroke="black" stroke-width="0.5" />
    <path d="M149.54 6.25999L149.75 6.47" stroke="black" stroke-width="0.5" />
    <path d="M149.75 6.47L149.93 6.69" stroke="black" stroke-width="0.5" />
    <path d="M149.93 6.69L150.06 6.91" stroke="black" stroke-width="0.5" />
    <path d="M150.06 6.91L150.14 7.14" stroke="black" stroke-width="0.5" />
    <path d="M150.14 7.14L150.17 7.36" stroke="black" stroke-width="0.5" />
    <path d="M150.17 7.36L150.14 7.58" stroke="black" stroke-width="0.5" />
    <path d="M150.14 7.58L150.06 7.8" stroke="black" stroke-width="0.5" />
    <path d="M150.06 7.8L149.93 8.02" stroke="black" stroke-width="0.5" />
    <path d="M149.93 8.02L149.75 8.24001" stroke="black" stroke-width="0.5" />
    <path d="M149.75 8.24001L149.54 8.45" stroke="black" stroke-width="0.5" />
    <path d="M149.54 8.45L149.28 8.63" stroke="black" stroke-width="0.5" />
    <path d="M149.28 8.63L149 8.78999" stroke="black" stroke-width="0.5" />
    <path d="M149 8.78999L148.7 8.91" stroke="black" stroke-width="0.5" />
    <path d="M148.7 8.91L148.39 9.00999" stroke="black" stroke-width="0.5" />
    <path d="M148.39 9.00999L148.08 9.08" stroke="black" stroke-width="0.5" />
    <path d="M148.08 9.08L147.78 9.12" stroke="black" stroke-width="0.5" />
    <path d="M147.78 9.12L147.5 9.13" stroke="black" stroke-width="0.5" />
    <path d="M147.5 9.13H147.26" stroke="black" stroke-width="0.5" />
    <path d="M147.26 9.13L147.06 9.12" stroke="black" stroke-width="0.5" />
    <path d="M147.06 9.12L146.93 9.11" stroke="black" stroke-width="0.5" />
    <path d="M146.93 9.11L146.83 9.10001" stroke="black" stroke-width="0.5" />
    <path
      d="M146.83 9.10001L146.72 9.07001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M146.72 9.07001L146.59 9.03999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M146.59 9.03999L146.41 8.99001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M146.41 8.99001L146.17 8.94" stroke="black" stroke-width="0.5" />
    <path d="M146.17 8.94L145.87 8.87" stroke="black" stroke-width="0.5" />
    <path d="M145.87 8.87L145.51 8.81" stroke="black" stroke-width="0.5" />
    <path d="M145.51 8.81L145.1 8.75999" stroke="black" stroke-width="0.5" />
    <path d="M145.1 8.75999L144.65 8.71001" stroke="black" stroke-width="0.5" />
    <path d="M144.65 8.71001H144.54" stroke="black" stroke-width="0.5" />
    <path d="M144.39 9.17999L144.37 9.22" stroke="black" stroke-width="0.5" />
    <path d="M144.37 9.22L144.36 9.25" stroke="black" stroke-width="0.5" />
    <path d="M144.36 9.25L144.35 9.28999" stroke="black" stroke-width="0.5" />
    <path
      d="M144.35 9.28999L144.33 9.32001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M144.33 9.32001L144.31 9.35001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M144.31 9.35001L144.29 9.38" stroke="black" stroke-width="0.5" />
    <path d="M144.29 9.38L144.26 9.41" stroke="black" stroke-width="0.5" />
    <path d="M144.26 9.41L144.23 9.45" stroke="black" stroke-width="0.5" />
    <path d="M144.23 9.45L144.19 9.5" stroke="black" stroke-width="0.5" />
    <path d="M144.19 9.5L144.15 9.53999" stroke="black" stroke-width="0.5" />
    <path d="M144.15 9.53999L144.09 9.59" stroke="black" stroke-width="0.5" />
    <path d="M144.09 9.59L144.04 9.64" stroke="black" stroke-width="0.5" />
    <path d="M144.04 9.64L143.97 9.69" stroke="black" stroke-width="0.5" />
    <path d="M143.97 9.69L143.91 9.73" stroke="black" stroke-width="0.5" />
    <path d="M143.91 9.73L143.84 9.77" stroke="black" stroke-width="0.5" />
    <path d="M143.84 9.77L143.77 9.81" stroke="black" stroke-width="0.5" />
    <path d="M143.77 9.81L143.69 9.83" stroke="black" stroke-width="0.5" />
    <path d="M143.69 9.83L143.62 9.85001" stroke="black" stroke-width="0.5" />
    <path d="M143.62 9.85001L143.54 9.87" stroke="black" stroke-width="0.5" />
    <path d="M143.54 9.87L143.46 9.88" stroke="black" stroke-width="0.5" />
    <path d="M143.46 9.88H143.39" stroke="black" stroke-width="0.5" />
    <path d="M143.39 9.88H143.31" stroke="black" stroke-width="0.5" />
    <path d="M143.31 9.88H143.24" stroke="black" stroke-width="0.5" />
    <path d="M143.24 9.88H143.17" stroke="black" stroke-width="0.5" />
    <path d="M143.17 9.88H143.1" stroke="black" stroke-width="0.5" />
    <path d="M143.1 9.88H143.03" stroke="black" stroke-width="0.5" />
    <path d="M143.03 9.88H142.97" stroke="black" stroke-width="0.5" />
    <path d="M142.97 9.88L142.91 9.87" stroke="black" stroke-width="0.5" />
    <path d="M142.91 9.87H142.85" stroke="black" stroke-width="0.5" />
    <path d="M142.85 9.87H142.8" stroke="black" stroke-width="0.5" />
    <path d="M142.8 9.87L142.75 9.86" stroke="black" stroke-width="0.5" />
    <path d="M142.75 9.86L142.71 9.85001" stroke="black" stroke-width="0.5" />
    <path d="M142.71 9.85001H142.66" stroke="black" stroke-width="0.5" />
    <path d="M142.66 9.85001L142.62 9.84" stroke="black" stroke-width="0.5" />
    <path d="M142.62 9.84L142.59 9.82001" stroke="black" stroke-width="0.5" />
    <path d="M142.59 9.82001L142.55 9.81" stroke="black" stroke-width="0.5" />
    <path d="M142.55 9.81L142.52 9.78" stroke="black" stroke-width="0.5" />
    <path d="M142.52 9.78L142.5 9.75999" stroke="black" stroke-width="0.5" />
    <path d="M142.5 9.75999L142.47 9.72" stroke="black" stroke-width="0.5" />
    <path d="M142.47 9.72L142.45 9.67999" stroke="black" stroke-width="0.5" />
    <path d="M142.45 9.67999L142.43 9.63" stroke="black" stroke-width="0.5" />
    <path d="M142.43 9.63L142.42 9.56" stroke="black" stroke-width="0.5" />
    <path d="M142.42 9.56L142.4 9.49001" stroke="black" stroke-width="0.5" />
    <path d="M142.4 9.49001L142.39 9.39999" stroke="black" stroke-width="0.5" />
    <path d="M142.39 9.39999L142.38 9.31" stroke="black" stroke-width="0.5" />
    <path d="M142.38 9.31L142.37 9.19" stroke="black" stroke-width="0.5" />
    <path d="M142.37 9.19L142.36 9.07001" stroke="black" stroke-width="0.5" />
    <path d="M142.36 9.07001V8.92" stroke="black" stroke-width="0.5" />
    <path d="M142.36 8.92L142.35 8.75999" stroke="black" stroke-width="0.5" />
    <path d="M142.35 8.75999L142.34 8.59" stroke="black" stroke-width="0.5" />
    <path d="M142.34 8.59V8.39999" stroke="black" stroke-width="0.5" />
    <path
      d="M142.34 8.39999L142.33 8.21001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M142.33 8.21001V8" stroke="black" stroke-width="0.5" />
    <path d="M142.33 8V7.78999" stroke="black" stroke-width="0.5" />
    <path d="M142.33 7.78999V7.57001" stroke="black" stroke-width="0.5" />
    <path d="M142.33 7.57001V7.35001" stroke="black" stroke-width="0.5" />
    <path d="M142.33 7.35001V7.14" stroke="black" stroke-width="0.5" />
    <path d="M142.33 7.14V6.92" stroke="black" stroke-width="0.5" />
    <path d="M142.33 6.92V6.71001" stroke="black" stroke-width="0.5" />
    <path d="M142.33 6.71001V6.5" stroke="black" stroke-width="0.5" />
    <path d="M142.33 6.5L142.34 6.3" stroke="black" stroke-width="0.5" />
    <path d="M142.34 6.3V6.12" stroke="black" stroke-width="0.5" />
    <path d="M142.34 6.12L142.35 5.94" stroke="black" stroke-width="0.5" />
    <path d="M142.35 5.94L142.36 5.78" stroke="black" stroke-width="0.5" />
    <path d="M142.36 5.78L142.37 5.64" stroke="black" stroke-width="0.5" />
    <path d="M142.37 5.64V5.50999" stroke="black" stroke-width="0.5" />
    <path
      d="M142.37 5.50999L142.38 5.39999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M142.38 5.39999L142.4 5.3" stroke="black" stroke-width="0.5" />
    <path d="M142.4 5.3L142.41 5.22" stroke="black" stroke-width="0.5" />
    <path d="M142.41 5.22L142.42 5.14999" stroke="black" stroke-width="0.5" />
    <path d="M142.42 5.14999L142.44 5.08" stroke="black" stroke-width="0.5" />
    <path d="M142.44 5.08L142.46 5.03" stroke="black" stroke-width="0.5" />
    <path d="M142.46 5.03L142.48 4.99001" stroke="black" stroke-width="0.5" />
    <path d="M142.48 4.99001L142.5 4.96001" stroke="black" stroke-width="0.5" />
    <path d="M142.5 4.96001L142.53 4.92999" stroke="black" stroke-width="0.5" />
    <path d="M142.53 4.92999L142.56 4.91" stroke="black" stroke-width="0.5" />
    <path d="M142.56 4.91L142.59 4.89" stroke="black" stroke-width="0.5" />
    <path d="M142.59 4.89L142.63 4.88" stroke="black" stroke-width="0.5" />
    <path d="M142.63 4.88L142.67 4.87" stroke="black" stroke-width="0.5" />
    <path d="M142.67 4.87L142.71 4.86" stroke="black" stroke-width="0.5" />
    <path d="M142.71 4.86L142.76 4.85001" stroke="black" stroke-width="0.5" />
    <path d="M142.76 4.85001H142.81" stroke="black" stroke-width="0.5" />
    <path d="M142.81 4.85001L142.86 4.84" stroke="black" stroke-width="0.5" />
    <path d="M142.86 4.84H142.92" stroke="black" stroke-width="0.5" />
    <path d="M142.92 4.84H142.98" stroke="black" stroke-width="0.5" />
    <path d="M142.98 4.84L143.04 4.83" stroke="black" stroke-width="0.5" />
    <path d="M143.04 4.83H143.1" stroke="black" stroke-width="0.5" />
    <path d="M143.1 4.83H143.17" stroke="black" stroke-width="0.5" />
    <path d="M143.17 4.83H143.24" stroke="black" stroke-width="0.5" />
    <path d="M143.24 4.83H143.31" stroke="black" stroke-width="0.5" />
    <path d="M143.31 4.83H143.39" stroke="black" stroke-width="0.5" />
    <path d="M143.39 4.83L143.46 4.84" stroke="black" stroke-width="0.5" />
    <path d="M143.46 4.84L143.54 4.85001" stroke="black" stroke-width="0.5" />
    <path d="M143.54 4.85001L143.61 4.86" stroke="black" stroke-width="0.5" />
    <path d="M143.61 4.86L143.69 4.89" stroke="black" stroke-width="0.5" />
    <path d="M143.69 4.89L143.76 4.91" stroke="black" stroke-width="0.5" />
    <path d="M143.76 4.91L143.83 4.95" stroke="black" stroke-width="0.5" />
    <path d="M143.83 4.95L143.9 4.99001" stroke="black" stroke-width="0.5" />
    <path d="M143.9 4.99001L143.97 5.03" stroke="black" stroke-width="0.5" />
    <path d="M143.97 5.03L144.03 5.09" stroke="black" stroke-width="0.5" />
    <path d="M144.03 5.09L144.09 5.14" stroke="black" stroke-width="0.5" />
    <path d="M144.09 5.14L144.15 5.2" stroke="black" stroke-width="0.5" />
    <path d="M144.15 5.2L144.2 5.27" stroke="black" stroke-width="0.5" />
    <path d="M144.2 5.27L144.25 5.33" stroke="black" stroke-width="0.5" />
    <path d="M144.25 5.33L144.3 5.39999" stroke="black" stroke-width="0.5" />
    <path d="M144.3 5.39999L144.33 5.47" stroke="black" stroke-width="0.5" />
    <path d="M144.33 5.47L144.37 5.53999" stroke="black" stroke-width="0.5" />
    <path d="M144.37 5.53999L144.4 5.61" stroke="black" stroke-width="0.5" />
    <path d="M144.4 5.61L144.43 5.67999" stroke="black" stroke-width="0.5" />
    <path
      d="M144.43 5.67999L144.45 5.75999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M144.45 5.75999L144.48 5.83" stroke="black" stroke-width="0.5" />
    <path d="M144.48 5.83L144.5 5.91" stroke="black" stroke-width="0.5" />
    <path d="M144.5 5.91L144.52 5.98" stroke="black" stroke-width="0.5" />
    <path d="M144.52 5.98L144.54 6.06" stroke="black" stroke-width="0.5" />
    <path d="M144.54 6.06L144.56 6.14" stroke="black" stroke-width="0.5" />
    <path d="M144.56 6.14L144.58 6.22" stroke="black" stroke-width="0.5" />
    <path d="M144.58 6.22L144.6 6.3" stroke="black" stroke-width="0.5" />
    <path d="M144.6 6.3L144.61 6.39" stroke="black" stroke-width="0.5" />
    <path d="M144.61 6.39L144.63 6.47" stroke="black" stroke-width="0.5" />
    <path d="M144.63 6.47L144.64 6.56" stroke="black" stroke-width="0.5" />
    <path d="M144.64 6.56L144.65 6.64999" stroke="black" stroke-width="0.5" />
    <path d="M144.65 6.64999L144.66 6.75" stroke="black" stroke-width="0.5" />
    <path d="M144.66 6.75L144.67 6.85001" stroke="black" stroke-width="0.5" />
    <path d="M144.67 6.85001L144.68 6.95" stroke="black" stroke-width="0.5" />
    <path d="M144.68 6.95L144.69 7.05" stroke="black" stroke-width="0.5" />
    <path d="M144.69 7.05V7.14999" stroke="black" stroke-width="0.5" />
    <path d="M144.69 7.14999V7.25999" stroke="black" stroke-width="0.5" />
    <path d="M144.69 7.25999V7.36" stroke="black" stroke-width="0.5" />
    <path d="M144.69 7.36V7.46001" stroke="black" stroke-width="0.5" />
    <path d="M144.69 7.46001V7.57001" stroke="black" stroke-width="0.5" />
    <path d="M144.69 7.57001V7.67" stroke="black" stroke-width="0.5" />
    <path d="M144.69 7.67L144.68 7.77" stroke="black" stroke-width="0.5" />
    <path d="M144.68 7.77V7.87" stroke="black" stroke-width="0.5" />
    <path d="M144.68 7.87L144.67 7.96001" stroke="black" stroke-width="0.5" />
    <path d="M144.67 7.96001L144.66 8.05" stroke="black" stroke-width="0.5" />
    <path d="M144.66 8.05L144.65 8.14" stroke="black" stroke-width="0.5" />
    <path d="M144.65 8.14L144.64 8.22" stroke="black" stroke-width="0.5" />
    <path d="M144.64 8.22L144.63 8.3" stroke="black" stroke-width="0.5" />
    <path d="M144.63 8.3L144.62 8.37" stroke="black" stroke-width="0.5" />
    <path d="M144.62 8.37L144.6 8.44" stroke="black" stroke-width="0.5" />
    <path d="M144.6 8.44L144.59 8.50999" stroke="black" stroke-width="0.5" />
    <path d="M144.59 8.50999L144.57 8.58" stroke="black" stroke-width="0.5" />
    <path d="M144.57 8.58L144.56 8.64" stroke="black" stroke-width="0.5" />
    <path d="M144.56 8.64L144.54 8.71001" stroke="black" stroke-width="0.5" />
    <path d="M144.54 8.71001L144.52 8.77" stroke="black" stroke-width="0.5" />
    <path d="M144.52 8.77L144.5 8.84" stroke="black" stroke-width="0.5" />
    <path d="M144.5 8.84L144.48 8.89999" stroke="black" stroke-width="0.5" />
    <path
      d="M144.48 8.89999L144.46 8.96001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M144.46 8.96001L144.44 9.02" stroke="black" stroke-width="0.5" />
    <path d="M144.44 9.02L144.42 9.08" stroke="black" stroke-width="0.5" />
    <path d="M144.42 9.08L144.4 9.13" stroke="black" stroke-width="0.5" />
    <path d="M144.4 9.13L144.39 9.17999" stroke="black" stroke-width="0.5" />
    <path d="M144.5 17.94V19.03" stroke="black" stroke-width="0.5" />
    <path d="M144.5 19.03H142.33" stroke="black" stroke-width="0.5" />
    <path d="M142.33 19.03V14.3" stroke="black" stroke-width="0.5" />
    <path d="M142.33 14.3H144.5" stroke="black" stroke-width="0.5" />
    <path d="M144.5 14.3V15.39" stroke="black" stroke-width="0.5" />
    <path d="M144.5 15.39L145.31 16.27" stroke="black" stroke-width="0.5" />
    <path d="M145.31 16.67V16.27" stroke="black" stroke-width="0.5" />
    <path d="M144.5 17.94L145.31 17.06" stroke="black" stroke-width="0.5" />
    <path d="M145.31 16.67V17.06" stroke="black" stroke-width="0.5" />
    <path d="M146.04 36.58L146.79 36.41" stroke="black" stroke-width="0.5" />
    <path d="M146.79 36.41L147.4 35.91" stroke="black" stroke-width="0.5" />
    <path d="M147.4 35.91L147.79 35.17" stroke="black" stroke-width="0.5" />
    <path d="M147.79 35.17L147.87 34.32" stroke="black" stroke-width="0.5" />
    <path d="M147.87 34.32L147.63 33.51" stroke="black" stroke-width="0.5" />
    <path d="M147.63 33.51L147.12 32.88" stroke="black" stroke-width="0.5" />
    <path d="M147.12 32.88L146.42 32.53" stroke="black" stroke-width="0.5" />
    <path d="M146.42 32.53H145.66" stroke="black" stroke-width="0.5" />
    <path d="M145.66 32.53L144.96 32.88" stroke="black" stroke-width="0.5" />
    <path d="M144.96 32.88L144.45 33.51" stroke="black" stroke-width="0.5" />
    <path d="M144.45 33.51L144.21 34.32" stroke="black" stroke-width="0.5" />
    <path d="M144.21 34.32L144.29 35.17" stroke="black" stroke-width="0.5" />
    <path d="M144.29 35.17L144.68 35.91" stroke="black" stroke-width="0.5" />
    <path d="M144.68 35.91L145.29 36.41" stroke="black" stroke-width="0.5" />
    <path d="M145.29 36.41L146.04 36.58" stroke="black" stroke-width="0.5" />
    <path d="M146.04 36.2L146.07 36.19" stroke="black" stroke-width="0.5" />
    <path d="M146.07 36.19H146.11" stroke="black" stroke-width="0.5" />
    <path d="M146.11 36.19H146.14" stroke="black" stroke-width="0.5" />
    <path d="M146.14 36.19H146.18" stroke="black" stroke-width="0.5" />
    <path d="M146.18 36.19L146.21 36.18" stroke="black" stroke-width="0.5" />
    <path d="M146.21 36.18H146.25" stroke="black" stroke-width="0.5" />
    <path d="M146.25 36.18L146.28 36.17" stroke="black" stroke-width="0.5" />
    <path d="M146.28 36.17L146.31 36.16" stroke="black" stroke-width="0.5" />
    <path d="M146.31 36.16H146.35" stroke="black" stroke-width="0.5" />
    <path d="M146.35 36.16L146.38 36.15" stroke="black" stroke-width="0.5" />
    <path d="M146.38 36.15L146.42 36.14" stroke="black" stroke-width="0.5" />
    <path d="M146.42 36.14L146.45 36.13" stroke="black" stroke-width="0.5" />
    <path d="M146.45 36.13L146.48 36.12" stroke="black" stroke-width="0.5" />
    <path d="M146.48 36.12L146.51 36.1" stroke="black" stroke-width="0.5" />
    <path d="M146.51 36.1L146.55 36.09" stroke="black" stroke-width="0.5" />
    <path d="M146.55 36.09L146.58 36.08" stroke="black" stroke-width="0.5" />
    <path d="M146.58 36.08L146.61 36.06" stroke="black" stroke-width="0.5" />
    <path d="M146.61 36.06L146.64 36.04" stroke="black" stroke-width="0.5" />
    <path d="M146.64 36.04L146.68 36.03" stroke="black" stroke-width="0.5" />
    <path d="M146.68 36.03L146.71 36.01" stroke="black" stroke-width="0.5" />
    <path d="M146.71 36.01L146.74 35.99" stroke="black" stroke-width="0.5" />
    <path d="M146.74 35.99L146.77 35.97" stroke="black" stroke-width="0.5" />
    <path d="M146.77 35.97L146.8 35.95" stroke="black" stroke-width="0.5" />
    <path d="M146.8 35.95L146.83 35.93" stroke="black" stroke-width="0.5" />
    <path d="M146.83 35.93L146.86 35.9" stroke="black" stroke-width="0.5" />
    <path d="M146.86 35.9L146.89 35.88" stroke="black" stroke-width="0.5" />
    <path d="M146.89 35.88L146.91 35.86" stroke="black" stroke-width="0.5" />
    <path d="M146.91 35.86L146.94 35.83" stroke="black" stroke-width="0.5" />
    <path d="M146.94 35.83L146.97 35.81" stroke="black" stroke-width="0.5" />
    <path d="M146.97 35.81L147 35.78" stroke="black" stroke-width="0.5" />
    <path d="M147 35.78L147.02 35.75" stroke="black" stroke-width="0.5" />
    <path d="M147.02 35.75L147.05 35.72" stroke="black" stroke-width="0.5" />
    <path d="M147.05 35.72L147.07 35.7" stroke="black" stroke-width="0.5" />
    <path d="M147.07 35.7L147.1 35.67" stroke="black" stroke-width="0.5" />
    <path d="M147.1 35.67L147.12 35.64" stroke="black" stroke-width="0.5" />
    <path d="M147.12 35.64L147.15 35.6" stroke="black" stroke-width="0.5" />
    <path d="M147.15 35.6L147.17 35.57" stroke="black" stroke-width="0.5" />
    <path d="M147.17 35.57L147.19 35.54" stroke="black" stroke-width="0.5" />
    <path d="M147.19 35.54L147.21 35.51" stroke="black" stroke-width="0.5" />
    <path d="M147.21 35.51L147.23 35.47" stroke="black" stroke-width="0.5" />
    <path d="M147.23 35.47L147.25 35.44" stroke="black" stroke-width="0.5" />
    <path d="M147.25 35.44L147.27 35.41" stroke="black" stroke-width="0.5" />
    <path d="M147.27 35.41L147.29 35.37" stroke="black" stroke-width="0.5" />
    <path d="M147.29 35.37L147.31 35.33" stroke="black" stroke-width="0.5" />
    <path d="M147.31 35.33L147.33 35.3" stroke="black" stroke-width="0.5" />
    <path d="M147.33 35.3L147.34 35.26" stroke="black" stroke-width="0.5" />
    <path d="M147.34 35.26L147.36 35.22" stroke="black" stroke-width="0.5" />
    <path d="M147.36 35.22L147.37 35.18" stroke="black" stroke-width="0.5" />
    <path d="M147.37 35.18L147.39 35.15" stroke="black" stroke-width="0.5" />
    <path d="M147.39 35.15L147.4 35.11" stroke="black" stroke-width="0.5" />
    <path d="M147.4 35.11L147.41 35.07" stroke="black" stroke-width="0.5" />
    <path d="M147.41 35.07L147.42 35.03" stroke="black" stroke-width="0.5" />
    <path d="M147.42 35.03L147.43 34.99" stroke="black" stroke-width="0.5" />
    <path d="M147.43 34.99L147.44 34.95" stroke="black" stroke-width="0.5" />
    <path d="M147.44 34.95L147.45 34.91" stroke="black" stroke-width="0.5" />
    <path d="M147.45 34.91L147.46 34.87" stroke="black" stroke-width="0.5" />
    <path d="M147.46 34.87V34.83" stroke="black" stroke-width="0.5" />
    <path d="M147.46 34.83L147.47 34.79" stroke="black" stroke-width="0.5" />
    <path d="M147.47 34.79L147.48 34.74" stroke="black" stroke-width="0.5" />
    <path d="M147.48 34.74V34.7" stroke="black" stroke-width="0.5" />
    <path d="M147.48 34.7V34.66" stroke="black" stroke-width="0.5" />
    <path d="M147.48 34.66L147.49 34.62" stroke="black" stroke-width="0.5" />
    <path d="M147.49 34.62V34.58" stroke="black" stroke-width="0.5" />
    <path d="M147.49 34.58V34.54" stroke="black" stroke-width="0.5" />
    <path d="M147.49 34.54V34.5" stroke="black" stroke-width="0.5" />
    <path d="M147.49 34.5V34.45" stroke="black" stroke-width="0.5" />
    <path d="M147.49 34.45L147.48 34.41" stroke="black" stroke-width="0.5" />
    <path d="M147.48 34.41V34.37" stroke="black" stroke-width="0.5" />
    <path d="M147.48 34.37V34.33" stroke="black" stroke-width="0.5" />
    <path d="M147.48 34.33L147.47 34.29" stroke="black" stroke-width="0.5" />
    <path d="M147.47 34.29L147.46 34.25" stroke="black" stroke-width="0.5" />
    <path d="M147.46 34.25V34.21" stroke="black" stroke-width="0.5" />
    <path d="M147.46 34.21L147.45 34.17" stroke="black" stroke-width="0.5" />
    <path d="M147.45 34.17L147.44 34.12" stroke="black" stroke-width="0.5" />
    <path d="M147.44 34.12L147.43 34.08" stroke="black" stroke-width="0.5" />
    <path d="M147.43 34.08L147.42 34.04" stroke="black" stroke-width="0.5" />
    <path d="M147.42 34.04L147.41 34.01" stroke="black" stroke-width="0.5" />
    <path d="M147.41 34.01L147.4 33.97" stroke="black" stroke-width="0.5" />
    <path d="M147.4 33.97L147.39 33.93" stroke="black" stroke-width="0.5" />
    <path d="M147.39 33.93L147.37 33.89" stroke="black" stroke-width="0.5" />
    <path d="M147.37 33.89L147.36 33.85" stroke="black" stroke-width="0.5" />
    <path d="M147.36 33.85L147.34 33.81" stroke="black" stroke-width="0.5" />
    <path d="M147.34 33.81L147.33 33.78" stroke="black" stroke-width="0.5" />
    <path d="M147.33 33.78L147.31 33.74" stroke="black" stroke-width="0.5" />
    <path d="M147.31 33.74L147.29 33.7" stroke="black" stroke-width="0.5" />
    <path d="M147.29 33.7L147.27 33.67" stroke="black" stroke-width="0.5" />
    <path d="M147.27 33.67L147.25 33.63" stroke="black" stroke-width="0.5" />
    <path d="M147.25 33.63L147.23 33.6" stroke="black" stroke-width="0.5" />
    <path d="M147.23 33.6L147.21 33.57" stroke="black" stroke-width="0.5" />
    <path d="M147.21 33.57L147.19 33.53" stroke="black" stroke-width="0.5" />
    <path d="M147.19 33.53L147.17 33.5" stroke="black" stroke-width="0.5" />
    <path d="M147.17 33.5L147.15 33.47" stroke="black" stroke-width="0.5" />
    <path d="M147.15 33.47L147.12 33.44" stroke="black" stroke-width="0.5" />
    <path d="M147.12 33.44L147.1 33.41" stroke="black" stroke-width="0.5" />
    <path d="M147.1 33.41L147.07 33.38" stroke="black" stroke-width="0.5" />
    <path d="M147.07 33.38L147.05 33.35" stroke="black" stroke-width="0.5" />
    <path d="M147.05 33.35L147.02 33.32" stroke="black" stroke-width="0.5" />
    <path d="M147.02 33.32L147 33.29" stroke="black" stroke-width="0.5" />
    <path d="M147 33.29L146.97 33.27" stroke="black" stroke-width="0.5" />
    <path d="M146.97 33.27L146.94 33.24" stroke="black" stroke-width="0.5" />
    <path d="M146.94 33.24L146.91 33.22" stroke="black" stroke-width="0.5" />
    <path d="M146.91 33.22L146.89 33.19" stroke="black" stroke-width="0.5" />
    <path d="M146.89 33.19L146.86 33.17" stroke="black" stroke-width="0.5" />
    <path d="M146.86 33.17L146.83 33.15" stroke="black" stroke-width="0.5" />
    <path d="M146.83 33.15L146.8 33.13" stroke="black" stroke-width="0.5" />
    <path d="M146.8 33.13L146.77 33.1" stroke="black" stroke-width="0.5" />
    <path d="M146.77 33.1L146.74 33.08" stroke="black" stroke-width="0.5" />
    <path d="M146.74 33.08L146.71 33.07" stroke="black" stroke-width="0.5" />
    <path d="M146.71 33.07L146.68 33.05" stroke="black" stroke-width="0.5" />
    <path d="M146.68 33.05L146.64 33.03" stroke="black" stroke-width="0.5" />
    <path d="M146.64 33.03L146.61 33.01" stroke="black" stroke-width="0.5" />
    <path d="M146.61 33.01L146.58 33" stroke="black" stroke-width="0.5" />
    <path d="M146.58 33L146.55 32.98" stroke="black" stroke-width="0.5" />
    <path d="M146.55 32.98L146.51 32.97" stroke="black" stroke-width="0.5" />
    <path d="M146.51 32.97L146.48 32.96" stroke="black" stroke-width="0.5" />
    <path d="M146.48 32.96L146.45 32.95" stroke="black" stroke-width="0.5" />
    <path d="M146.45 32.95L146.42 32.94" stroke="black" stroke-width="0.5" />
    <path d="M146.42 32.94L146.38 32.93" stroke="black" stroke-width="0.5" />
    <path d="M146.38 32.93L146.35 32.92" stroke="black" stroke-width="0.5" />
    <path d="M146.35 32.92L146.31 32.91" stroke="black" stroke-width="0.5" />
    <path d="M146.31 32.91L146.28 32.9" stroke="black" stroke-width="0.5" />
    <path d="M146.28 32.9H146.25" stroke="black" stroke-width="0.5" />
    <path d="M146.25 32.9L146.21 32.89" stroke="black" stroke-width="0.5" />
    <path d="M146.21 32.89H146.18" stroke="black" stroke-width="0.5" />
    <path d="M146.18 32.89L146.14 32.88" stroke="black" stroke-width="0.5" />
    <path d="M146.14 32.88H146.11" stroke="black" stroke-width="0.5" />
    <path d="M146.11 32.88H146.07" stroke="black" stroke-width="0.5" />
    <path d="M146.07 32.88H146.04" stroke="black" stroke-width="0.5" />
    <path d="M144.23 34.23H145.64" stroke="black" stroke-width="0.5" />
    <path d="M144.36 33.71H145.64" stroke="black" stroke-width="0.5" />
    <path
      d="M145.64 34.23C145.709 34.23 145.775 34.2026 145.824 34.1538C145.873 34.1051 145.9 34.039 145.9 33.97C145.9 33.901 145.873 33.8349 145.824 33.7862C145.775 33.7374 145.709 33.71 145.64 33.71"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M146.04 32.88C145.673 33.0549 145.374 33.3469 145.19 33.71"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M145.19 35.36C145.372 35.7268 145.671 36.0225 146.04 36.2"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M144.23 34.85H145.41" stroke="black" stroke-width="0.5" />
    <path d="M144.36 35.36H145.41" stroke="black" stroke-width="0.5" />
    <path
      d="M145.41 35.36C145.472 35.3522 145.529 35.322 145.571 35.275C145.612 35.2281 145.635 35.1676 145.635 35.105C145.635 35.0424 145.612 34.9819 145.571 34.935C145.529 34.888 145.472 34.8578 145.41 34.85"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M145.16 34.85V34.23" stroke="black" stroke-width="0.5" />
    <path d="M146.04 29.59L146.79 29.41" stroke="black" stroke-width="0.5" />
    <path d="M146.79 29.41L147.4 28.91" stroke="black" stroke-width="0.5" />
    <path d="M147.4 28.91L147.79 28.17" stroke="black" stroke-width="0.5" />
    <path d="M147.79 28.17L147.87 27.33" stroke="black" stroke-width="0.5" />
    <path d="M147.87 27.33L147.63 26.52" stroke="black" stroke-width="0.5" />
    <path d="M147.63 26.52L147.12 25.89" stroke="black" stroke-width="0.5" />
    <path d="M147.12 25.89L146.42 25.54" stroke="black" stroke-width="0.5" />
    <path d="M146.42 25.54H145.66" stroke="black" stroke-width="0.5" />
    <path d="M145.66 25.54L144.96 25.89" stroke="black" stroke-width="0.5" />
    <path d="M144.96 25.89L144.45 26.52" stroke="black" stroke-width="0.5" />
    <path d="M144.45 26.52L144.21 27.33" stroke="black" stroke-width="0.5" />
    <path d="M144.21 27.33L144.29 28.17" stroke="black" stroke-width="0.5" />
    <path d="M144.29 28.17L144.68 28.91" stroke="black" stroke-width="0.5" />
    <path d="M144.68 28.91L145.29 29.41" stroke="black" stroke-width="0.5" />
    <path d="M145.29 29.41L146.04 29.59" stroke="black" stroke-width="0.5" />
    <path d="M146.04 29.2H146.07" stroke="black" stroke-width="0.5" />
    <path d="M146.07 29.2H146.11" stroke="black" stroke-width="0.5" />
    <path d="M146.11 29.2H146.14" stroke="black" stroke-width="0.5" />
    <path d="M146.14 29.2L146.18 29.19" stroke="black" stroke-width="0.5" />
    <path d="M146.18 29.19H146.21" stroke="black" stroke-width="0.5" />
    <path d="M146.21 29.19L146.25 29.18" stroke="black" stroke-width="0.5" />
    <path d="M146.25 29.18H146.28" stroke="black" stroke-width="0.5" />
    <path d="M146.28 29.18L146.31 29.17" stroke="black" stroke-width="0.5" />
    <path d="M146.31 29.17L146.35 29.16" stroke="black" stroke-width="0.5" />
    <path d="M146.35 29.16L146.38 29.15" stroke="black" stroke-width="0.5" />
    <path d="M146.38 29.15L146.42 29.14" stroke="black" stroke-width="0.5" />
    <path d="M146.42 29.14L146.45 29.13" stroke="black" stroke-width="0.5" />
    <path d="M146.45 29.13L146.48 29.12" stroke="black" stroke-width="0.5" />
    <path d="M146.48 29.12L146.51 29.11" stroke="black" stroke-width="0.5" />
    <path d="M146.51 29.11L146.55 29.09" stroke="black" stroke-width="0.5" />
    <path d="M146.55 29.09L146.58 29.08" stroke="black" stroke-width="0.5" />
    <path d="M146.58 29.08L146.61 29.06" stroke="black" stroke-width="0.5" />
    <path d="M146.61 29.06L146.64 29.05" stroke="black" stroke-width="0.5" />
    <path d="M146.64 29.05L146.68 29.03" stroke="black" stroke-width="0.5" />
    <path d="M146.68 29.03L146.71 29.01" stroke="black" stroke-width="0.5" />
    <path d="M146.71 29.01L146.74 28.99" stroke="black" stroke-width="0.5" />
    <path d="M146.74 28.99L146.77 28.97" stroke="black" stroke-width="0.5" />
    <path d="M146.77 28.97L146.8 28.95" stroke="black" stroke-width="0.5" />
    <path d="M146.8 28.95L146.83 28.93" stroke="black" stroke-width="0.5" />
    <path d="M146.83 28.93L146.86 28.91" stroke="black" stroke-width="0.5" />
    <path d="M146.86 28.91L146.89 28.89" stroke="black" stroke-width="0.5" />
    <path d="M146.89 28.89L146.91 28.86" stroke="black" stroke-width="0.5" />
    <path d="M146.91 28.86L146.94 28.84" stroke="black" stroke-width="0.5" />
    <path d="M146.94 28.84L146.97 28.81" stroke="black" stroke-width="0.5" />
    <path d="M146.97 28.81L147 28.78" stroke="black" stroke-width="0.5" />
    <path d="M147 28.78L147.02 28.76" stroke="black" stroke-width="0.5" />
    <path d="M147.02 28.76L147.05 28.73" stroke="black" stroke-width="0.5" />
    <path d="M147.05 28.73L147.07 28.7" stroke="black" stroke-width="0.5" />
    <path d="M147.07 28.7L147.1 28.67" stroke="black" stroke-width="0.5" />
    <path d="M147.1 28.67L147.12 28.64" stroke="black" stroke-width="0.5" />
    <path d="M147.12 28.64L147.15 28.61" stroke="black" stroke-width="0.5" />
    <path d="M147.15 28.61L147.17 28.58" stroke="black" stroke-width="0.5" />
    <path d="M147.17 28.58L147.19 28.55" stroke="black" stroke-width="0.5" />
    <path d="M147.19 28.55L147.21 28.51" stroke="black" stroke-width="0.5" />
    <path d="M147.21 28.51L147.23 28.48" stroke="black" stroke-width="0.5" />
    <path d="M147.23 28.48L147.25 28.44" stroke="black" stroke-width="0.5" />
    <path d="M147.25 28.44L147.27 28.41" stroke="black" stroke-width="0.5" />
    <path d="M147.27 28.41L147.29 28.37" stroke="black" stroke-width="0.5" />
    <path d="M147.29 28.37L147.31 28.34" stroke="black" stroke-width="0.5" />
    <path d="M147.31 28.34L147.33 28.3" stroke="black" stroke-width="0.5" />
    <path d="M147.33 28.3L147.34 28.26" stroke="black" stroke-width="0.5" />
    <path d="M147.34 28.26L147.36 28.23" stroke="black" stroke-width="0.5" />
    <path d="M147.36 28.23L147.37 28.19" stroke="black" stroke-width="0.5" />
    <path d="M147.37 28.19L147.39 28.15" stroke="black" stroke-width="0.5" />
    <path d="M147.39 28.15L147.4 28.11" stroke="black" stroke-width="0.5" />
    <path d="M147.4 28.11L147.41 28.07" stroke="black" stroke-width="0.5" />
    <path d="M147.41 28.07L147.42 28.03" stroke="black" stroke-width="0.5" />
    <path d="M147.42 28.03L147.43 27.99" stroke="black" stroke-width="0.5" />
    <path d="M147.43 27.99L147.44 27.95" stroke="black" stroke-width="0.5" />
    <path d="M147.44 27.95L147.45 27.91" stroke="black" stroke-width="0.5" />
    <path d="M147.45 27.91L147.46 27.87" stroke="black" stroke-width="0.5" />
    <path d="M147.46 27.87V27.83" stroke="black" stroke-width="0.5" />
    <path d="M147.46 27.83L147.47 27.79" stroke="black" stroke-width="0.5" />
    <path d="M147.47 27.79L147.48 27.75" stroke="black" stroke-width="0.5" />
    <path d="M147.48 27.75V27.71" stroke="black" stroke-width="0.5" />
    <path d="M147.48 27.71V27.67" stroke="black" stroke-width="0.5" />
    <path d="M147.48 27.67L147.49 27.62" stroke="black" stroke-width="0.5" />
    <path d="M147.49 27.62V27.58" stroke="black" stroke-width="0.5" />
    <path d="M147.49 27.58V27.54" stroke="black" stroke-width="0.5" />
    <path d="M147.49 27.54V27.5" stroke="black" stroke-width="0.5" />
    <path d="M147.49 27.5V27.46" stroke="black" stroke-width="0.5" />
    <path d="M147.49 27.46L147.48 27.42" stroke="black" stroke-width="0.5" />
    <path d="M147.48 27.42V27.38" stroke="black" stroke-width="0.5" />
    <path d="M147.48 27.38V27.33" stroke="black" stroke-width="0.5" />
    <path d="M147.48 27.33L147.47 27.29" stroke="black" stroke-width="0.5" />
    <path d="M147.47 27.29L147.46 27.25" stroke="black" stroke-width="0.5" />
    <path d="M147.46 27.25V27.21" stroke="black" stroke-width="0.5" />
    <path d="M147.46 27.21L147.45 27.17" stroke="black" stroke-width="0.5" />
    <path d="M147.45 27.17L147.44 27.13" stroke="black" stroke-width="0.5" />
    <path d="M147.44 27.13L147.43 27.09" stroke="black" stroke-width="0.5" />
    <path d="M147.43 27.09L147.42 27.05" stroke="black" stroke-width="0.5" />
    <path d="M147.42 27.05L147.41 27.01" stroke="black" stroke-width="0.5" />
    <path d="M147.41 27.01L147.4 26.97" stroke="black" stroke-width="0.5" />
    <path d="M147.4 26.97L147.39 26.93" stroke="black" stroke-width="0.5" />
    <path d="M147.39 26.93L147.37 26.89" stroke="black" stroke-width="0.5" />
    <path d="M147.37 26.89L147.36 26.86" stroke="black" stroke-width="0.5" />
    <path d="M147.36 26.86L147.34 26.82" stroke="black" stroke-width="0.5" />
    <path d="M147.34 26.82L147.33 26.78" stroke="black" stroke-width="0.5" />
    <path d="M147.33 26.78L147.31 26.74" stroke="black" stroke-width="0.5" />
    <path d="M147.31 26.74L147.29 26.71" stroke="black" stroke-width="0.5" />
    <path d="M147.29 26.71L147.27 26.67" stroke="black" stroke-width="0.5" />
    <path d="M147.27 26.67L147.25 26.64" stroke="black" stroke-width="0.5" />
    <path d="M147.25 26.64L147.23 26.6" stroke="black" stroke-width="0.5" />
    <path d="M147.23 26.6L147.21 26.57" stroke="black" stroke-width="0.5" />
    <path d="M147.21 26.57L147.19 26.54" stroke="black" stroke-width="0.5" />
    <path d="M147.19 26.54L147.17 26.51" stroke="black" stroke-width="0.5" />
    <path d="M147.17 26.51L147.15 26.47" stroke="black" stroke-width="0.5" />
    <path d="M147.15 26.47L147.12 26.44" stroke="black" stroke-width="0.5" />
    <path d="M147.12 26.44L147.1 26.41" stroke="black" stroke-width="0.5" />
    <path d="M147.1 26.41L147.07 26.38" stroke="black" stroke-width="0.5" />
    <path d="M147.07 26.38L147.05 26.35" stroke="black" stroke-width="0.5" />
    <path d="M147.05 26.35L147.02 26.33" stroke="black" stroke-width="0.5" />
    <path d="M147.02 26.33L147 26.3" stroke="black" stroke-width="0.5" />
    <path d="M147 26.3L146.97 26.27" stroke="black" stroke-width="0.5" />
    <path d="M146.97 26.27L146.94 26.25" stroke="black" stroke-width="0.5" />
    <path d="M146.94 26.25L146.91 26.22" stroke="black" stroke-width="0.5" />
    <path d="M146.91 26.22L146.89 26.2" stroke="black" stroke-width="0.5" />
    <path d="M146.89 26.2L146.86 26.17" stroke="black" stroke-width="0.5" />
    <path d="M146.86 26.17L146.83 26.15" stroke="black" stroke-width="0.5" />
    <path d="M146.83 26.15L146.8 26.13" stroke="black" stroke-width="0.5" />
    <path d="M146.8 26.13L146.77 26.11" stroke="black" stroke-width="0.5" />
    <path d="M146.77 26.11L146.74 26.09" stroke="black" stroke-width="0.5" />
    <path d="M146.74 26.09L146.71 26.07" stroke="black" stroke-width="0.5" />
    <path d="M146.71 26.07L146.68 26.05" stroke="black" stroke-width="0.5" />
    <path d="M146.68 26.05L146.64 26.03" stroke="black" stroke-width="0.5" />
    <path d="M146.64 26.03L146.61 26.02" stroke="black" stroke-width="0.5" />
    <path d="M146.61 26.02L146.58 26" stroke="black" stroke-width="0.5" />
    <path d="M146.58 26L146.55 25.99" stroke="black" stroke-width="0.5" />
    <path d="M146.55 25.99L146.51 25.98" stroke="black" stroke-width="0.5" />
    <path d="M146.51 25.98L146.48 25.96" stroke="black" stroke-width="0.5" />
    <path d="M146.48 25.96L146.45 25.95" stroke="black" stroke-width="0.5" />
    <path d="M146.45 25.95L146.42 25.94" stroke="black" stroke-width="0.5" />
    <path d="M146.42 25.94L146.38 25.93" stroke="black" stroke-width="0.5" />
    <path d="M146.38 25.93L146.35 25.92" stroke="black" stroke-width="0.5" />
    <path d="M146.35 25.92L146.31 25.91" stroke="black" stroke-width="0.5" />
    <path d="M146.31 25.91H146.28" stroke="black" stroke-width="0.5" />
    <path d="M146.28 25.91L146.25 25.9" stroke="black" stroke-width="0.5" />
    <path d="M146.25 25.9H146.21" stroke="black" stroke-width="0.5" />
    <path d="M146.21 25.9L146.18 25.89" stroke="black" stroke-width="0.5" />
    <path d="M146.18 25.89H146.14" stroke="black" stroke-width="0.5" />
    <path d="M146.14 25.89H146.11" stroke="black" stroke-width="0.5" />
    <path d="M146.11 25.89L146.07 25.88" stroke="black" stroke-width="0.5" />
    <path d="M146.07 25.88H146.04" stroke="black" stroke-width="0.5" />
    <path d="M144.23 27.23H145.64" stroke="black" stroke-width="0.5" />
    <path d="M144.36 26.72H145.64" stroke="black" stroke-width="0.5" />
    <path
      d="M145.64 27.23C145.702 27.2222 145.759 27.192 145.801 27.1451C145.842 27.0981 145.865 27.0376 145.865 26.975C145.865 26.9124 145.842 26.8519 145.801 26.805C145.759 26.758 145.702 26.7278 145.64 26.72"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M146.04 25.88C145.671 26.0575 145.372 26.3531 145.19 26.72"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M145.19 28.36C145.372 28.7268 145.671 29.0225 146.04 29.2"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M144.23 27.85H145.41" stroke="black" stroke-width="0.5" />
    <path d="M144.36 28.36H145.41" stroke="black" stroke-width="0.5" />
    <path
      d="M145.41 28.36C145.472 28.3522 145.529 28.322 145.571 28.275C145.612 28.2281 145.635 28.1676 145.635 28.105C145.635 28.0424 145.612 27.9819 145.571 27.935C145.529 27.888 145.472 27.8578 145.41 27.85"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M145.16 27.85V27.23" stroke="black" stroke-width="0.5" />
    <path d="M161.97 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M162.5 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M151.83 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M152.36 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M124.71 2.74001H163.29" stroke="black" stroke-width="0.5" />
    <path d="M163.29 2.74001V20.62" stroke="black" stroke-width="0.5" />
    <path d="M164.62 2.74001V19.29" stroke="black" stroke-width="0.5" />
    <path d="M163.29 20.62H212.82" stroke="black" stroke-width="0.5" />
    <path d="M212.82 20.62V89" stroke="black" stroke-width="0.5" />
    <path d="M164.61 90.01H212.81" stroke="black" stroke-width="0.5" />
    <path d="M182.39 88.67H212.81" stroke="black" stroke-width="0.5" />
    <path d="M181.06 81.73H212.81" stroke="black" stroke-width="0.5" />
    <path d="M182.22 73.73H182.75" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 164.76V162.68" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 161.84V160.18" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 159.34V157.67" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 156.84V155.17" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 154.34V152.67" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 151.84V150.17" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 149.33V147.67" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 146.83V145.17" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 144.33V142.66" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 141.83V140.16" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 139.33V137.66" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 136.83V135.16" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 134.32V132.66" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 131.82V130.15" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 129.32V127.65" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 126.82V124.73" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 115.13V114.16" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 113.32V111.65" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 110.82V109.15" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 108.32V106.65" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 105.82V104.15" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 103.31V101.65" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 100.81V99.15" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 98.31V96.64" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 95.81V94.14" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 93.31V91.64" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 90.81V89.14" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 88.3V86.64" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 85.8V84.13" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 83.3V81.63" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 80.8V79.13" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 78.3V76.63" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 75.79V74.13" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 73.29V72.32" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 66.57V64.88" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 64.04V62.38" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 61.54V59.87" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 59.04V57.37" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 56.54V54.87" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 54.04V52.37" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 51.53V49.87" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 49.03V47.36" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 46.53V44.86" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 44.03V42.36" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 41.53V39.83" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 30.22V28.16" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 27.33V25.66" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 24.82V23.16" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 22.32V20.65" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 19.82V18.15" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 17.32V15.65" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 14.82V13.15" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 12.31V10.65" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 9.81V8.14999" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 7.31V5.64" stroke="black" stroke-width="0.5" />
    <path d="M0.649994 4.81V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 164.76V162.68" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 161.84V160.18" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 159.34V157.67" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 156.84V155.17" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 154.34V152.67" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 151.84V150.17" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 149.33V147.67" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 146.83V145.17" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 144.33V142.66" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 141.83V140.16" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 139.33V137.66" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 136.83V135.16" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 134.32V132.66" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 131.82V130.15" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 129.32V127.65" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 126.82V124.73" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 115.13V114.16" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 113.32V111.65" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 110.82V109.15" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 108.32V106.65" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 105.82V104.15" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 103.31V101.65" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 100.81V99.15" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 98.31V96.64" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 95.81V94.14" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 93.31V91.64" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 90.81V89.14" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 88.3V86.64" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 85.8V84.13" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 83.3V81.63" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 80.8V79.13" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 78.3V76.63" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 75.79V74.13" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 73.29V72.32" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 66.57V64.88" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 64.04V62.38" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 61.54V59.87" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 59.04V57.37" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 56.54V54.87" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 54.04V52.37" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 51.53V49.87" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 49.03V47.36" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 46.53V44.86" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 44.03V42.36" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 41.53V39.83" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 30.22V28.16" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 27.33V25.66" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 24.82V23.16" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 22.32V20.65" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 19.82V18.15" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 17.32V15.65" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 14.82V13.15" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 12.31V10.65" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 9.81V8.14999" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 7.31V5.64" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 4.81V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 15.67H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 28.48H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 41.32H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 54.03H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 75.11H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M164.62 2.74001H166.17" stroke="black" stroke-width="0.5" />
    <path d="M166.17 2.74001V1.41" stroke="black" stroke-width="0.5" />
    <path d="M105.91 62.16H152.09" stroke="black" stroke-width="0.5" />
    <path d="M161.69 62.16H163.27" stroke="black" stroke-width="0.5" />
    <path d="M164.61 62.16H192.82" stroke="black" stroke-width="0.5" />
    <path d="M165.94 72.3H182.22" stroke="black" stroke-width="0.5" />
    <path d="M165.94 72.83H182.22" stroke="black" stroke-width="0.5" />
    <path d="M202.43 62.16H203.51" stroke="black" stroke-width="0.5" />
    <path d="M204.84 62.16H212.82" stroke="black" stroke-width="0.5" />
    <path d="M99.77 60.82H140.99" stroke="black" stroke-width="0.5" />
    <path d="M142.33 60.82H152.09" stroke="black" stroke-width="0.5" />
    <path d="M161.69 60.82H192.82" stroke="black" stroke-width="0.5" />
    <path d="M202.43 60.82H212.82" stroke="black" stroke-width="0.5" />
    <path d="M211.51 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M212.04 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M201.9 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M201.36 43.38V44.71" stroke="black" stroke-width="0.5" />
    <path d="M202.43 62.16V60.82" stroke="black" stroke-width="0.5" />
    <path d="M202.96 62.16V60.82" stroke="black" stroke-width="0.5" />
    <path d="M192.29 62.16V60.82" stroke="black" stroke-width="0.5" />
    <path d="M192.82 62.16V60.82" stroke="black" stroke-width="0.5" />
    <path d="M203.51 62.16V71.24" stroke="black" stroke-width="0.5" />
    <path d="M204.84 62.16V69.9" stroke="black" stroke-width="0.5" />
    <path d="M203.51 71.24H212.82" stroke="black" stroke-width="0.5" />
    <path d="M204.84 69.9H212.82" stroke="black" stroke-width="0.5" />
    <path d="M203.72 69.43H206.94" stroke="black" stroke-width="0.5" />
    <path d="M206.94 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M203.72 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M182.39 81.73V88.67" stroke="black" stroke-width="0.5" />
    <path d="M181.06 81.73V88.67" stroke="black" stroke-width="0.5" />
    <path d="M181.06 88.67H165.94" stroke="black" stroke-width="0.5" />
    <path d="M163.27 72V62.16" stroke="black" stroke-width="0.5" />
    <path d="M164.61 90.01V62.16" stroke="black" stroke-width="0.5" />
    <path d="M165.94 88.67V72.83" stroke="black" stroke-width="0.5" />
    <path d="M165.94 72.3V62.16" stroke="black" stroke-width="0.5" />
    <path d="M182.75 73.73V71.21" stroke="black" stroke-width="0.5" />
    <path d="M182.75 63.21V62.16" stroke="black" stroke-width="0.5" />
    <path d="M182.22 73.73V72.83" stroke="black" stroke-width="0.5" />
    <path d="M182.22 72.3V71.21" stroke="black" stroke-width="0.5" />
    <path d="M182.22 63.21V62.16" stroke="black" stroke-width="0.5" />
    <path d="M182.22 71.21H182.75" stroke="black" stroke-width="0.5" />
    <path d="M182.22 63.21H182.75" stroke="black" stroke-width="0.5" />
    <path d="M173.37 62.16H177.8" stroke="black" stroke-width="0.5" />
    <path d="M177.8 62.16V63.18" stroke="black" stroke-width="0.5" />
    <path d="M177.8 63.18H173.37" stroke="black" stroke-width="0.5" />
    <path d="M173.37 63.18V62.16" stroke="black" stroke-width="0.5" />
    <path d="M168.97 70.48H173.37" stroke="black" stroke-width="0.5" />
    <path d="M173.37 70.48V74.63" stroke="black" stroke-width="0.5" />
    <path d="M173.37 74.63H168.97" stroke="black" stroke-width="0.5" />
    <path d="M168.97 74.63V70.48" stroke="black" stroke-width="0.5" />
    <path d="M173.37 72.83H177.8" stroke="black" stroke-width="0.5" />
    <path d="M177.8 72.83V73.85" stroke="black" stroke-width="0.5" />
    <path d="M177.8 73.85H173.37" stroke="black" stroke-width="0.5" />
    <path d="M173.37 73.85V72.83" stroke="black" stroke-width="0.5" />
    <path d="M168.15 79.22L168.7 79.29" stroke="black" stroke-width="0.5" />
    <path d="M168.7 79.29L169.12 79.35" stroke="black" stroke-width="0.5" />
    <path d="M169.12 79.35L169.48 79.41" stroke="black" stroke-width="0.5" />
    <path d="M169.48 79.41L169.78 79.47" stroke="black" stroke-width="0.5" />
    <path d="M169.78 79.47L170.03 79.53" stroke="black" stroke-width="0.5" />
    <path d="M170.03 79.53L170.21 79.57" stroke="black" stroke-width="0.5" />
    <path d="M170.21 79.57L170.34 79.61" stroke="black" stroke-width="0.5" />
    <path d="M170.34 79.61L170.44 79.63" stroke="black" stroke-width="0.5" />
    <path d="M170.44 79.63L170.55 79.65" stroke="black" stroke-width="0.5" />
    <path d="M170.55 79.65L170.68 79.66" stroke="black" stroke-width="0.5" />
    <path d="M170.68 79.66L170.88 79.67" stroke="black" stroke-width="0.5" />
    <path d="M170.88 79.67H171.12" stroke="black" stroke-width="0.5" />
    <path d="M171.12 79.67L171.39 79.65" stroke="black" stroke-width="0.5" />
    <path d="M171.39 79.65L171.69 79.61" stroke="black" stroke-width="0.5" />
    <path d="M171.69 79.61L172 79.54" stroke="black" stroke-width="0.5" />
    <path d="M172 79.54L172.31 79.45" stroke="black" stroke-width="0.5" />
    <path d="M172.31 79.45L172.62 79.32" stroke="black" stroke-width="0.5" />
    <path d="M172.62 79.32L172.9 79.16" stroke="black" stroke-width="0.5" />
    <path d="M172.9 79.16L173.15 78.98" stroke="black" stroke-width="0.5" />
    <path d="M173.15 78.98L173.37 78.77" stroke="black" stroke-width="0.5" />
    <path d="M173.37 78.77L173.54 78.55" stroke="black" stroke-width="0.5" />
    <path d="M173.54 78.55L173.67 78.33" stroke="black" stroke-width="0.5" />
    <path d="M173.67 78.33L173.75 78.11" stroke="black" stroke-width="0.5" />
    <path d="M173.75 78.11L173.78 77.89" stroke="black" stroke-width="0.5" />
    <path d="M173.78 77.89L173.75 77.67" stroke="black" stroke-width="0.5" />
    <path d="M173.75 77.67L173.68 77.45" stroke="black" stroke-width="0.5" />
    <path d="M173.68 77.45L173.54 77.22" stroke="black" stroke-width="0.5" />
    <path d="M173.54 77.22L173.37 77" stroke="black" stroke-width="0.5" />
    <path d="M173.37 77L173.15 76.8" stroke="black" stroke-width="0.5" />
    <path d="M173.15 76.8L172.9 76.61" stroke="black" stroke-width="0.5" />
    <path d="M172.9 76.61L172.61 76.46" stroke="black" stroke-width="0.5" />
    <path d="M172.61 76.46L172.31 76.33" stroke="black" stroke-width="0.5" />
    <path d="M172.31 76.33L172 76.23" stroke="black" stroke-width="0.5" />
    <path d="M172 76.23L171.69 76.16" stroke="black" stroke-width="0.5" />
    <path d="M171.69 76.16L171.39 76.13" stroke="black" stroke-width="0.5" />
    <path d="M171.39 76.13L171.11 76.11" stroke="black" stroke-width="0.5" />
    <path d="M171.11 76.11H170.87" stroke="black" stroke-width="0.5" />
    <path d="M170.87 76.11L170.68 76.12" stroke="black" stroke-width="0.5" />
    <path d="M170.68 76.12L170.55 76.13" stroke="black" stroke-width="0.5" />
    <path d="M170.55 76.13L170.44 76.15" stroke="black" stroke-width="0.5" />
    <path d="M170.44 76.15L170.34 76.17" stroke="black" stroke-width="0.5" />
    <path d="M170.34 76.17L170.21 76.2" stroke="black" stroke-width="0.5" />
    <path d="M170.21 76.2L170.02 76.25" stroke="black" stroke-width="0.5" />
    <path d="M170.02 76.25L169.78 76.31" stroke="black" stroke-width="0.5" />
    <path d="M169.78 76.31L169.48 76.37" stroke="black" stroke-width="0.5" />
    <path d="M169.48 76.37L169.12 76.43" stroke="black" stroke-width="0.5" />
    <path d="M169.12 76.43L168.71 76.48" stroke="black" stroke-width="0.5" />
    <path d="M168.71 76.48L168.27 76.54" stroke="black" stroke-width="0.5" />
    <path d="M168.27 76.54H168.15" stroke="black" stroke-width="0.5" />
    <path d="M168 76.07L167.99 76.03" stroke="black" stroke-width="0.5" />
    <path d="M167.99 76.03L167.97 75.99" stroke="black" stroke-width="0.5" />
    <path d="M167.97 75.99L167.96 75.96" stroke="black" stroke-width="0.5" />
    <path d="M167.96 75.96L167.94 75.93" stroke="black" stroke-width="0.5" />
    <path d="M167.94 75.93L167.93 75.9" stroke="black" stroke-width="0.5" />
    <path d="M167.93 75.9L167.9 75.86" stroke="black" stroke-width="0.5" />
    <path d="M167.9 75.86L167.88 75.83" stroke="black" stroke-width="0.5" />
    <path d="M167.88 75.83L167.85 75.79" stroke="black" stroke-width="0.5" />
    <path d="M167.85 75.79L167.81 75.75" stroke="black" stroke-width="0.5" />
    <path d="M167.81 75.75L167.76 75.7" stroke="black" stroke-width="0.5" />
    <path d="M167.76 75.7L167.71 75.65" stroke="black" stroke-width="0.5" />
    <path d="M167.71 75.65L167.65 75.6" stroke="black" stroke-width="0.5" />
    <path d="M167.65 75.6L167.59 75.56" stroke="black" stroke-width="0.5" />
    <path d="M167.59 75.56L167.52 75.51" stroke="black" stroke-width="0.5" />
    <path d="M167.52 75.51L167.45 75.47" stroke="black" stroke-width="0.5" />
    <path d="M167.45 75.47L167.38 75.44" stroke="black" stroke-width="0.5" />
    <path d="M167.38 75.44L167.31 75.41" stroke="black" stroke-width="0.5" />
    <path d="M167.31 75.41L167.23 75.39" stroke="black" stroke-width="0.5" />
    <path d="M167.23 75.39L167.15 75.38" stroke="black" stroke-width="0.5" />
    <path d="M167.15 75.38L167.08 75.37" stroke="black" stroke-width="0.5" />
    <path d="M167.08 75.37L167 75.36" stroke="black" stroke-width="0.5" />
    <path d="M167 75.36H166.93" stroke="black" stroke-width="0.5" />
    <path d="M166.93 75.36H166.85" stroke="black" stroke-width="0.5" />
    <path d="M166.85 75.36H166.78" stroke="black" stroke-width="0.5" />
    <path d="M166.78 75.36H166.71" stroke="black" stroke-width="0.5" />
    <path d="M166.71 75.36H166.65" stroke="black" stroke-width="0.5" />
    <path d="M166.65 75.36L166.58 75.37" stroke="black" stroke-width="0.5" />
    <path d="M166.58 75.37H166.52" stroke="black" stroke-width="0.5" />
    <path d="M166.52 75.37H166.47" stroke="black" stroke-width="0.5" />
    <path d="M166.47 75.37L166.42 75.38" stroke="black" stroke-width="0.5" />
    <path d="M166.42 75.38H166.37" stroke="black" stroke-width="0.5" />
    <path d="M166.37 75.38L166.32 75.39" stroke="black" stroke-width="0.5" />
    <path d="M166.32 75.39L166.28 75.4" stroke="black" stroke-width="0.5" />
    <path d="M166.28 75.4L166.24 75.41" stroke="black" stroke-width="0.5" />
    <path d="M166.24 75.41L166.2 75.42" stroke="black" stroke-width="0.5" />
    <path d="M166.2 75.42L166.17 75.44" stroke="black" stroke-width="0.5" />
    <path d="M166.17 75.44L166.14 75.46" stroke="black" stroke-width="0.5" />
    <path d="M166.14 75.46L166.11 75.49" stroke="black" stroke-width="0.5" />
    <path d="M166.11 75.49L166.09 75.52" stroke="black" stroke-width="0.5" />
    <path d="M166.09 75.52L166.07 75.57" stroke="black" stroke-width="0.5" />
    <path d="M166.07 75.57L166.05 75.62" stroke="black" stroke-width="0.5" />
    <path d="M166.05 75.62L166.03 75.68" stroke="black" stroke-width="0.5" />
    <path d="M166.03 75.68L166.02 75.75" stroke="black" stroke-width="0.5" />
    <path d="M166.02 75.75L166 75.84" stroke="black" stroke-width="0.5" />
    <path d="M166 75.84L165.99 75.94" stroke="black" stroke-width="0.5" />
    <path d="M165.99 75.94L165.98 76.05" stroke="black" stroke-width="0.5" />
    <path d="M165.98 76.05V76.18" stroke="black" stroke-width="0.5" />
    <path d="M165.98 76.18L165.97 76.32" stroke="black" stroke-width="0.5" />
    <path d="M165.97 76.32L165.96 76.48" stroke="black" stroke-width="0.5" />
    <path d="M165.96 76.48V76.65" stroke="black" stroke-width="0.5" />
    <path d="M165.96 76.65L165.95 76.84" stroke="black" stroke-width="0.5" />
    <path d="M165.95 76.84V77.04" stroke="black" stroke-width="0.5" />
    <path d="M165.95 77.04L165.94 77.24" stroke="black" stroke-width="0.5" />
    <path d="M165.94 77.24V77.46" stroke="black" stroke-width="0.5" />
    <path d="M165.94 77.46V77.67" stroke="black" stroke-width="0.5" />
    <path d="M165.94 77.67V77.89" stroke="black" stroke-width="0.5" />
    <path d="M165.94 77.89V78.11" stroke="black" stroke-width="0.5" />
    <path d="M165.94 78.11V78.32" stroke="black" stroke-width="0.5" />
    <path d="M165.94 78.32V78.54" stroke="black" stroke-width="0.5" />
    <path d="M165.94 78.54L165.95 78.74" stroke="black" stroke-width="0.5" />
    <path d="M165.95 78.74V78.94" stroke="black" stroke-width="0.5" />
    <path d="M165.95 78.94L165.96 79.13" stroke="black" stroke-width="0.5" />
    <path d="M165.96 79.13V79.3" stroke="black" stroke-width="0.5" />
    <path d="M165.96 79.3L165.97 79.46" stroke="black" stroke-width="0.5" />
    <path d="M165.97 79.46L165.98 79.6" stroke="black" stroke-width="0.5" />
    <path d="M165.98 79.6L165.99 79.73" stroke="black" stroke-width="0.5" />
    <path d="M165.99 79.73L166 79.84" stroke="black" stroke-width="0.5" />
    <path d="M166 79.84L166.01 79.94" stroke="black" stroke-width="0.5" />
    <path d="M166.01 79.94L166.02 80.02" stroke="black" stroke-width="0.5" />
    <path d="M166.02 80.02L166.04 80.1" stroke="black" stroke-width="0.5" />
    <path d="M166.04 80.1L166.05 80.16" stroke="black" stroke-width="0.5" />
    <path d="M166.05 80.16L166.07 80.21" stroke="black" stroke-width="0.5" />
    <path d="M166.07 80.21L166.09 80.25" stroke="black" stroke-width="0.5" />
    <path d="M166.09 80.25L166.12 80.29" stroke="black" stroke-width="0.5" />
    <path d="M166.12 80.29L166.14 80.32" stroke="black" stroke-width="0.5" />
    <path d="M166.14 80.32L166.17 80.34" stroke="black" stroke-width="0.5" />
    <path d="M166.17 80.34L166.21 80.35" stroke="black" stroke-width="0.5" />
    <path d="M166.21 80.35L166.24 80.37" stroke="black" stroke-width="0.5" />
    <path d="M166.24 80.37L166.28 80.38" stroke="black" stroke-width="0.5" />
    <path d="M166.28 80.38H166.32" stroke="black" stroke-width="0.5" />
    <path d="M166.32 80.38L166.37 80.39" stroke="black" stroke-width="0.5" />
    <path d="M166.37 80.39L166.42 80.4" stroke="black" stroke-width="0.5" />
    <path d="M166.42 80.4H166.47" stroke="black" stroke-width="0.5" />
    <path d="M166.47 80.4H166.53" stroke="black" stroke-width="0.5" />
    <path d="M166.53 80.4L166.59 80.41" stroke="black" stroke-width="0.5" />
    <path d="M166.59 80.41H166.65" stroke="black" stroke-width="0.5" />
    <path d="M166.65 80.41H166.72" stroke="black" stroke-width="0.5" />
    <path d="M166.72 80.41H166.79" stroke="black" stroke-width="0.5" />
    <path d="M166.79 80.41H166.86" stroke="black" stroke-width="0.5" />
    <path d="M166.86 80.41H166.93" stroke="black" stroke-width="0.5" />
    <path d="M166.93 80.41H167" stroke="black" stroke-width="0.5" />
    <path d="M167 80.41L167.08 80.4" stroke="black" stroke-width="0.5" />
    <path d="M167.08 80.4L167.15 80.39" stroke="black" stroke-width="0.5" />
    <path d="M167.15 80.39L167.23 80.38" stroke="black" stroke-width="0.5" />
    <path d="M167.23 80.38L167.3 80.36" stroke="black" stroke-width="0.5" />
    <path d="M167.3 80.36L167.37 80.33" stroke="black" stroke-width="0.5" />
    <path d="M167.37 80.33L167.45 80.3" stroke="black" stroke-width="0.5" />
    <path d="M167.45 80.3L167.52 80.26" stroke="black" stroke-width="0.5" />
    <path d="M167.52 80.26L167.58 80.21" stroke="black" stroke-width="0.5" />
    <path d="M167.58 80.21L167.65 80.16" stroke="black" stroke-width="0.5" />
    <path d="M167.65 80.16L167.71 80.1" stroke="black" stroke-width="0.5" />
    <path d="M167.71 80.1L167.76 80.04" stroke="black" stroke-width="0.5" />
    <path d="M167.76 80.04L167.82 79.98" stroke="black" stroke-width="0.5" />
    <path d="M167.82 79.98L167.87 79.91" stroke="black" stroke-width="0.5" />
    <path d="M167.87 79.91L167.91 79.84" stroke="black" stroke-width="0.5" />
    <path d="M167.91 79.84L167.95 79.78" stroke="black" stroke-width="0.5" />
    <path d="M167.95 79.78L167.98 79.71" stroke="black" stroke-width="0.5" />
    <path d="M167.98 79.71L168.01 79.63" stroke="black" stroke-width="0.5" />
    <path d="M168.01 79.63L168.04 79.56" stroke="black" stroke-width="0.5" />
    <path d="M168.04 79.56L168.07 79.49" stroke="black" stroke-width="0.5" />
    <path d="M168.07 79.49L168.09 79.41" stroke="black" stroke-width="0.5" />
    <path d="M168.09 79.41L168.11 79.34" stroke="black" stroke-width="0.5" />
    <path d="M168.11 79.34L168.14 79.26" stroke="black" stroke-width="0.5" />
    <path d="M168.14 79.26L168.16 79.18" stroke="black" stroke-width="0.5" />
    <path d="M168.16 79.18L168.18 79.1" stroke="black" stroke-width="0.5" />
    <path d="M168.18 79.1L168.19 79.02" stroke="black" stroke-width="0.5" />
    <path d="M168.19 79.02L168.21 78.94" stroke="black" stroke-width="0.5" />
    <path d="M168.21 78.94L168.23 78.86" stroke="black" stroke-width="0.5" />
    <path d="M168.23 78.86L168.24 78.77" stroke="black" stroke-width="0.5" />
    <path d="M168.24 78.77L168.25 78.68" stroke="black" stroke-width="0.5" />
    <path d="M168.25 78.68L168.27 78.59" stroke="black" stroke-width="0.5" />
    <path d="M168.27 78.59L168.28 78.49" stroke="black" stroke-width="0.5" />
    <path d="M168.28 78.49L168.29 78.4" stroke="black" stroke-width="0.5" />
    <path d="M168.29 78.4V78.3" stroke="black" stroke-width="0.5" />
    <path d="M168.29 78.3L168.3 78.19" stroke="black" stroke-width="0.5" />
    <path d="M168.3 78.19V78.09" stroke="black" stroke-width="0.5" />
    <path d="M168.3 78.09L168.31 77.99" stroke="black" stroke-width="0.5" />
    <path d="M168.31 77.99V77.88" stroke="black" stroke-width="0.5" />
    <path d="M168.31 77.88V77.78" stroke="black" stroke-width="0.5" />
    <path d="M168.31 77.78V77.68" stroke="black" stroke-width="0.5" />
    <path d="M168.31 77.68L168.3 77.57" stroke="black" stroke-width="0.5" />
    <path d="M168.3 77.57V77.47" stroke="black" stroke-width="0.5" />
    <path d="M168.3 77.47L168.29 77.38" stroke="black" stroke-width="0.5" />
    <path d="M168.29 77.38L168.28 77.28" stroke="black" stroke-width="0.5" />
    <path d="M168.28 77.28V77.19" stroke="black" stroke-width="0.5" />
    <path d="M168.28 77.19L168.27 77.11" stroke="black" stroke-width="0.5" />
    <path d="M168.27 77.11L168.26 77.02" stroke="black" stroke-width="0.5" />
    <path d="M168.26 77.02L168.24 76.95" stroke="black" stroke-width="0.5" />
    <path d="M168.24 76.95L168.23 76.87" stroke="black" stroke-width="0.5" />
    <path d="M168.23 76.87L168.22 76.8" stroke="black" stroke-width="0.5" />
    <path d="M168.22 76.8L168.2 76.74" stroke="black" stroke-width="0.5" />
    <path d="M168.2 76.74L168.19 76.67" stroke="black" stroke-width="0.5" />
    <path d="M168.19 76.67L168.17 76.6" stroke="black" stroke-width="0.5" />
    <path d="M168.17 76.6L168.15 76.54" stroke="black" stroke-width="0.5" />
    <path d="M168.15 76.54L168.13 76.47" stroke="black" stroke-width="0.5" />
    <path d="M168.13 76.47L168.11 76.41" stroke="black" stroke-width="0.5" />
    <path d="M168.11 76.41L168.09 76.34" stroke="black" stroke-width="0.5" />
    <path d="M168.09 76.34L168.07 76.28" stroke="black" stroke-width="0.5" />
    <path d="M168.07 76.28L168.05 76.22" stroke="black" stroke-width="0.5" />
    <path d="M168.05 76.22L168.03 76.17" stroke="black" stroke-width="0.5" />
    <path d="M168.03 76.17L168.02 76.11" stroke="black" stroke-width="0.5" />
    <path d="M168.02 76.11L168 76.07" stroke="black" stroke-width="0.5" />
    <path d="M168.15 68.56L168.7 68.63" stroke="black" stroke-width="0.5" />
    <path d="M168.7 68.63L169.12 68.68" stroke="black" stroke-width="0.5" />
    <path d="M169.12 68.68L169.48 68.75" stroke="black" stroke-width="0.5" />
    <path d="M169.48 68.75L169.78 68.81" stroke="black" stroke-width="0.5" />
    <path d="M169.78 68.81L170.03 68.86" stroke="black" stroke-width="0.5" />
    <path d="M170.03 68.86L170.21 68.91" stroke="black" stroke-width="0.5" />
    <path d="M170.21 68.91L170.34 68.94" stroke="black" stroke-width="0.5" />
    <path d="M170.34 68.94L170.44 68.97" stroke="black" stroke-width="0.5" />
    <path d="M170.44 68.97L170.55 68.98" stroke="black" stroke-width="0.5" />
    <path d="M170.55 68.98L170.68 68.99" stroke="black" stroke-width="0.5" />
    <path d="M170.68 68.99L170.88 69" stroke="black" stroke-width="0.5" />
    <path d="M170.88 69H171.12" stroke="black" stroke-width="0.5" />
    <path d="M171.12 69L171.39 68.99" stroke="black" stroke-width="0.5" />
    <path d="M171.39 68.99L171.69 68.95" stroke="black" stroke-width="0.5" />
    <path d="M171.69 68.95L172 68.88" stroke="black" stroke-width="0.5" />
    <path d="M172 68.88L172.31 68.79" stroke="black" stroke-width="0.5" />
    <path d="M172.31 68.79L172.62 68.66" stroke="black" stroke-width="0.5" />
    <path d="M172.62 68.66L172.9 68.5" stroke="black" stroke-width="0.5" />
    <path d="M172.9 68.5L173.15 68.32" stroke="black" stroke-width="0.5" />
    <path d="M173.15 68.32L173.37 68.11" stroke="black" stroke-width="0.5" />
    <path d="M173.37 68.11L173.54 67.89" stroke="black" stroke-width="0.5" />
    <path d="M173.54 67.89L173.67 67.67" stroke="black" stroke-width="0.5" />
    <path d="M173.67 67.67L173.75 67.45" stroke="black" stroke-width="0.5" />
    <path d="M173.75 67.45L173.78 67.23" stroke="black" stroke-width="0.5" />
    <path d="M173.78 67.23L173.75 67.01" stroke="black" stroke-width="0.5" />
    <path d="M173.75 67.01L173.68 66.78" stroke="black" stroke-width="0.5" />
    <path d="M173.68 66.78L173.54 66.56" stroke="black" stroke-width="0.5" />
    <path d="M173.54 66.56L173.37 66.34" stroke="black" stroke-width="0.5" />
    <path d="M173.37 66.34L173.15 66.13" stroke="black" stroke-width="0.5" />
    <path d="M173.15 66.13L172.9 65.95" stroke="black" stroke-width="0.5" />
    <path d="M172.9 65.95L172.61 65.79" stroke="black" stroke-width="0.5" />
    <path d="M172.61 65.79L172.31 65.67" stroke="black" stroke-width="0.5" />
    <path d="M172.31 65.67L172 65.57" stroke="black" stroke-width="0.5" />
    <path d="M172 65.57L171.69 65.5" stroke="black" stroke-width="0.5" />
    <path d="M171.69 65.5L171.39 65.46" stroke="black" stroke-width="0.5" />
    <path d="M171.39 65.46L171.11 65.45" stroke="black" stroke-width="0.5" />
    <path d="M171.11 65.45H170.87" stroke="black" stroke-width="0.5" />
    <path d="M170.87 65.45L170.68 65.46" stroke="black" stroke-width="0.5" />
    <path d="M170.68 65.46L170.55 65.47" stroke="black" stroke-width="0.5" />
    <path d="M170.55 65.47L170.44 65.48" stroke="black" stroke-width="0.5" />
    <path d="M170.44 65.48L170.34 65.51" stroke="black" stroke-width="0.5" />
    <path d="M170.34 65.51L170.21 65.54" stroke="black" stroke-width="0.5" />
    <path d="M170.21 65.54L170.02 65.59" stroke="black" stroke-width="0.5" />
    <path d="M170.02 65.59L169.78 65.65" stroke="black" stroke-width="0.5" />
    <path d="M169.78 65.65L169.48 65.71" stroke="black" stroke-width="0.5" />
    <path d="M169.48 65.71L169.12 65.77" stroke="black" stroke-width="0.5" />
    <path d="M169.12 65.77L168.71 65.82" stroke="black" stroke-width="0.5" />
    <path d="M168.71 65.82L168.27 65.87" stroke="black" stroke-width="0.5" />
    <path d="M168.27 65.87L168.15 65.88" stroke="black" stroke-width="0.5" />
    <path d="M168 65.41L167.99 65.36" stroke="black" stroke-width="0.5" />
    <path d="M167.99 65.36L167.97 65.33" stroke="black" stroke-width="0.5" />
    <path d="M167.97 65.33L167.96 65.3" stroke="black" stroke-width="0.5" />
    <path d="M167.96 65.3L167.94 65.26" stroke="black" stroke-width="0.5" />
    <path d="M167.94 65.26L167.93 65.23" stroke="black" stroke-width="0.5" />
    <path d="M167.93 65.23L167.9 65.2" stroke="black" stroke-width="0.5" />
    <path d="M167.9 65.2L167.88 65.17" stroke="black" stroke-width="0.5" />
    <path d="M167.88 65.17L167.85 65.13" stroke="black" stroke-width="0.5" />
    <path d="M167.85 65.13L167.81 65.09" stroke="black" stroke-width="0.5" />
    <path d="M167.81 65.09L167.76 65.04" stroke="black" stroke-width="0.5" />
    <path d="M167.76 65.04L167.71 64.99" stroke="black" stroke-width="0.5" />
    <path d="M167.71 64.99L167.65 64.94" stroke="black" stroke-width="0.5" />
    <path d="M167.65 64.94L167.59 64.89" stroke="black" stroke-width="0.5" />
    <path d="M167.59 64.89L167.52 64.85" stroke="black" stroke-width="0.5" />
    <path d="M167.52 64.85L167.45 64.81" stroke="black" stroke-width="0.5" />
    <path d="M167.45 64.81L167.38 64.78" stroke="black" stroke-width="0.5" />
    <path d="M167.38 64.78L167.31 64.75" stroke="black" stroke-width="0.5" />
    <path d="M167.31 64.75L167.23 64.73" stroke="black" stroke-width="0.5" />
    <path d="M167.23 64.73L167.15 64.71" stroke="black" stroke-width="0.5" />
    <path d="M167.15 64.71L167.08 64.7" stroke="black" stroke-width="0.5" />
    <path d="M167.08 64.7H167" stroke="black" stroke-width="0.5" />
    <path d="M167 64.7H166.93" stroke="black" stroke-width="0.5" />
    <path d="M166.93 64.7H166.85" stroke="black" stroke-width="0.5" />
    <path d="M166.85 64.7H166.78" stroke="black" stroke-width="0.5" />
    <path d="M166.78 64.7H166.71" stroke="black" stroke-width="0.5" />
    <path d="M166.71 64.7H166.65" stroke="black" stroke-width="0.5" />
    <path d="M166.65 64.7H166.58" stroke="black" stroke-width="0.5" />
    <path d="M166.58 64.7L166.52 64.71" stroke="black" stroke-width="0.5" />
    <path d="M166.52 64.71H166.47" stroke="black" stroke-width="0.5" />
    <path d="M166.47 64.71L166.42 64.72" stroke="black" stroke-width="0.5" />
    <path d="M166.42 64.72H166.37" stroke="black" stroke-width="0.5" />
    <path d="M166.37 64.72L166.32 64.73" stroke="black" stroke-width="0.5" />
    <path d="M166.32 64.73L166.28 64.74" stroke="black" stroke-width="0.5" />
    <path d="M166.28 64.74L166.24 64.75" stroke="black" stroke-width="0.5" />
    <path d="M166.24 64.75L166.2 64.76" stroke="black" stroke-width="0.5" />
    <path d="M166.2 64.76L166.17 64.78" stroke="black" stroke-width="0.5" />
    <path d="M166.17 64.78L166.14 64.8" stroke="black" stroke-width="0.5" />
    <path d="M166.14 64.8L166.11 64.83" stroke="black" stroke-width="0.5" />
    <path d="M166.11 64.83L166.09 64.86" stroke="black" stroke-width="0.5" />
    <path d="M166.09 64.86L166.07 64.9" stroke="black" stroke-width="0.5" />
    <path d="M166.07 64.9L166.05 64.96" stroke="black" stroke-width="0.5" />
    <path d="M166.05 64.96L166.03 65.02" stroke="black" stroke-width="0.5" />
    <path d="M166.03 65.02L166.02 65.09" stroke="black" stroke-width="0.5" />
    <path d="M166.02 65.09L166 65.18" stroke="black" stroke-width="0.5" />
    <path d="M166 65.18L165.99 65.28" stroke="black" stroke-width="0.5" />
    <path d="M165.99 65.28L165.98 65.39" stroke="black" stroke-width="0.5" />
    <path d="M165.98 65.39V65.52" stroke="black" stroke-width="0.5" />
    <path d="M165.98 65.52L165.97 65.66" stroke="black" stroke-width="0.5" />
    <path d="M165.97 65.66L165.96 65.82" stroke="black" stroke-width="0.5" />
    <path d="M165.96 65.82V65.99" stroke="black" stroke-width="0.5" />
    <path d="M165.96 65.99L165.95 66.18" stroke="black" stroke-width="0.5" />
    <path d="M165.95 66.18V66.38" stroke="black" stroke-width="0.5" />
    <path d="M165.95 66.38L165.94 66.58" stroke="black" stroke-width="0.5" />
    <path d="M165.94 66.58V66.79" stroke="black" stroke-width="0.5" />
    <path d="M165.94 66.79V67.01" stroke="black" stroke-width="0.5" />
    <path d="M165.94 67.01V67.23" stroke="black" stroke-width="0.5" />
    <path d="M165.94 67.23V67.45" stroke="black" stroke-width="0.5" />
    <path d="M165.94 67.45V67.66" stroke="black" stroke-width="0.5" />
    <path d="M165.94 67.66V67.87" stroke="black" stroke-width="0.5" />
    <path d="M165.94 67.87L165.95 68.08" stroke="black" stroke-width="0.5" />
    <path d="M165.95 68.08V68.28" stroke="black" stroke-width="0.5" />
    <path d="M165.95 68.28L165.96 68.46" stroke="black" stroke-width="0.5" />
    <path d="M165.96 68.46V68.64" stroke="black" stroke-width="0.5" />
    <path d="M165.96 68.64L165.97 68.8" stroke="black" stroke-width="0.5" />
    <path d="M165.97 68.8L165.98 68.94" stroke="black" stroke-width="0.5" />
    <path d="M165.98 68.94L165.99 69.07" stroke="black" stroke-width="0.5" />
    <path d="M165.99 69.07L166 69.18" stroke="black" stroke-width="0.5" />
    <path d="M166 69.18L166.01 69.28" stroke="black" stroke-width="0.5" />
    <path d="M166.01 69.28L166.02 69.36" stroke="black" stroke-width="0.5" />
    <path d="M166.02 69.36L166.04 69.43" stroke="black" stroke-width="0.5" />
    <path d="M166.04 69.43L166.05 69.5" stroke="black" stroke-width="0.5" />
    <path d="M166.05 69.5L166.07 69.55" stroke="black" stroke-width="0.5" />
    <path d="M166.07 69.55L166.09 69.59" stroke="black" stroke-width="0.5" />
    <path d="M166.09 69.59L166.12 69.63" stroke="black" stroke-width="0.5" />
    <path d="M166.12 69.63L166.14 69.65" stroke="black" stroke-width="0.5" />
    <path d="M166.14 69.65L166.17 69.67" stroke="black" stroke-width="0.5" />
    <path d="M166.17 69.67L166.21 69.69" stroke="black" stroke-width="0.5" />
    <path d="M166.21 69.69L166.24 69.7" stroke="black" stroke-width="0.5" />
    <path d="M166.24 69.7L166.28 69.71" stroke="black" stroke-width="0.5" />
    <path d="M166.28 69.71L166.32 69.72" stroke="black" stroke-width="0.5" />
    <path d="M166.32 69.72L166.37 69.73" stroke="black" stroke-width="0.5" />
    <path d="M166.37 69.73H166.42" stroke="black" stroke-width="0.5" />
    <path d="M166.42 69.73L166.47 69.74" stroke="black" stroke-width="0.5" />
    <path d="M166.47 69.74H166.53" stroke="black" stroke-width="0.5" />
    <path d="M166.53 69.74L166.59 69.75" stroke="black" stroke-width="0.5" />
    <path d="M166.59 69.75H166.65" stroke="black" stroke-width="0.5" />
    <path d="M166.65 69.75H166.72" stroke="black" stroke-width="0.5" />
    <path d="M166.72 69.75H166.79" stroke="black" stroke-width="0.5" />
    <path d="M166.79 69.75H166.86" stroke="black" stroke-width="0.5" />
    <path d="M166.86 69.75H166.93" stroke="black" stroke-width="0.5" />
    <path d="M166.93 69.75H167" stroke="black" stroke-width="0.5" />
    <path d="M167 69.75L167.08 69.74" stroke="black" stroke-width="0.5" />
    <path d="M167.08 69.74L167.15 69.73" stroke="black" stroke-width="0.5" />
    <path d="M167.15 69.73L167.23 69.72" stroke="black" stroke-width="0.5" />
    <path d="M167.23 69.72L167.3 69.7" stroke="black" stroke-width="0.5" />
    <path d="M167.3 69.7L167.37 69.67" stroke="black" stroke-width="0.5" />
    <path d="M167.37 69.67L167.45 69.63" stroke="black" stroke-width="0.5" />
    <path d="M167.45 69.63L167.52 69.59" stroke="black" stroke-width="0.5" />
    <path d="M167.52 69.59L167.58 69.55" stroke="black" stroke-width="0.5" />
    <path d="M167.58 69.55L167.65 69.5" stroke="black" stroke-width="0.5" />
    <path d="M167.65 69.5L167.71 69.44" stroke="black" stroke-width="0.5" />
    <path d="M167.71 69.44L167.76 69.38" stroke="black" stroke-width="0.5" />
    <path d="M167.76 69.38L167.82 69.32" stroke="black" stroke-width="0.5" />
    <path d="M167.82 69.32L167.87 69.25" stroke="black" stroke-width="0.5" />
    <path d="M167.87 69.25L167.91 69.18" stroke="black" stroke-width="0.5" />
    <path d="M167.91 69.18L167.95 69.11" stroke="black" stroke-width="0.5" />
    <path d="M167.95 69.11L167.98 69.04" stroke="black" stroke-width="0.5" />
    <path d="M167.98 69.04L168.01 68.97" stroke="black" stroke-width="0.5" />
    <path d="M168.01 68.97L168.04 68.9" stroke="black" stroke-width="0.5" />
    <path d="M168.04 68.9L168.07 68.82" stroke="black" stroke-width="0.5" />
    <path d="M168.07 68.82L168.09 68.75" stroke="black" stroke-width="0.5" />
    <path d="M168.09 68.75L168.11 68.67" stroke="black" stroke-width="0.5" />
    <path d="M168.11 68.67L168.14 68.6" stroke="black" stroke-width="0.5" />
    <path d="M168.14 68.6L168.16 68.52" stroke="black" stroke-width="0.5" />
    <path d="M168.16 68.52L168.18 68.44" stroke="black" stroke-width="0.5" />
    <path d="M168.18 68.44L168.19 68.36" stroke="black" stroke-width="0.5" />
    <path d="M168.19 68.36L168.21 68.28" stroke="black" stroke-width="0.5" />
    <path d="M168.21 68.28L168.23 68.2" stroke="black" stroke-width="0.5" />
    <path d="M168.23 68.2L168.24 68.11" stroke="black" stroke-width="0.5" />
    <path d="M168.24 68.11L168.25 68.02" stroke="black" stroke-width="0.5" />
    <path d="M168.25 68.02L168.27 67.93" stroke="black" stroke-width="0.5" />
    <path d="M168.27 67.93L168.28 67.83" stroke="black" stroke-width="0.5" />
    <path d="M168.28 67.83L168.29 67.73" stroke="black" stroke-width="0.5" />
    <path d="M168.29 67.73V67.63" stroke="black" stroke-width="0.5" />
    <path d="M168.29 67.63L168.3 67.53" stroke="black" stroke-width="0.5" />
    <path d="M168.3 67.53V67.43" stroke="black" stroke-width="0.5" />
    <path d="M168.3 67.43L168.31 67.33" stroke="black" stroke-width="0.5" />
    <path d="M168.31 67.33V67.22" stroke="black" stroke-width="0.5" />
    <path d="M168.31 67.22V67.12" stroke="black" stroke-width="0.5" />
    <path d="M168.31 67.12V67.01" stroke="black" stroke-width="0.5" />
    <path d="M168.31 67.01L168.3 66.91" stroke="black" stroke-width="0.5" />
    <path d="M168.3 66.91V66.81" stroke="black" stroke-width="0.5" />
    <path d="M168.3 66.81L168.29 66.71" stroke="black" stroke-width="0.5" />
    <path d="M168.29 66.71L168.28 66.62" stroke="black" stroke-width="0.5" />
    <path d="M168.28 66.62V66.53" stroke="black" stroke-width="0.5" />
    <path d="M168.28 66.53L168.27 66.44" stroke="black" stroke-width="0.5" />
    <path d="M168.27 66.44L168.26 66.36" stroke="black" stroke-width="0.5" />
    <path d="M168.26 66.36L168.24 66.28" stroke="black" stroke-width="0.5" />
    <path d="M168.24 66.28L168.23 66.21" stroke="black" stroke-width="0.5" />
    <path d="M168.23 66.21L168.22 66.14" stroke="black" stroke-width="0.5" />
    <path d="M168.22 66.14L168.2 66.07" stroke="black" stroke-width="0.5" />
    <path d="M168.2 66.07L168.19 66.01" stroke="black" stroke-width="0.5" />
    <path d="M168.19 66.01L168.17 65.94" stroke="black" stroke-width="0.5" />
    <path d="M168.17 65.94L168.15 65.88" stroke="black" stroke-width="0.5" />
    <path d="M168.15 65.88L168.13 65.81" stroke="black" stroke-width="0.5" />
    <path d="M168.13 65.81L168.11 65.74" stroke="black" stroke-width="0.5" />
    <path d="M168.11 65.74L168.09 65.68" stroke="black" stroke-width="0.5" />
    <path d="M168.09 65.68L168.07 65.62" stroke="black" stroke-width="0.5" />
    <path d="M168.07 65.62L168.05 65.56" stroke="black" stroke-width="0.5" />
    <path d="M168.05 65.56L168.03 65.5" stroke="black" stroke-width="0.5" />
    <path d="M168.03 65.5L168.02 65.45" stroke="black" stroke-width="0.5" />
    <path d="M168.02 65.45L168 65.41" stroke="black" stroke-width="0.5" />
    <path d="M198.17 84.77L198 84.02" stroke="black" stroke-width="0.5" />
    <path d="M198 84.02L197.5 83.4" stroke="black" stroke-width="0.5" />
    <path d="M197.5 83.4L196.76 83.02" stroke="black" stroke-width="0.5" />
    <path d="M196.76 83.02L195.91 82.94" stroke="black" stroke-width="0.5" />
    <path d="M195.91 82.94L195.1 83.18" stroke="black" stroke-width="0.5" />
    <path d="M195.1 83.18L194.47 83.69" stroke="black" stroke-width="0.5" />
    <path d="M194.47 83.69L194.12 84.38" stroke="black" stroke-width="0.5" />
    <path d="M194.12 84.38V85.15" stroke="black" stroke-width="0.5" />
    <path d="M194.12 85.15L194.47 85.84" stroke="black" stroke-width="0.5" />
    <path d="M194.47 85.84L195.1 86.36" stroke="black" stroke-width="0.5" />
    <path d="M195.1 86.36L195.91 86.59" stroke="black" stroke-width="0.5" />
    <path d="M195.91 86.59L196.76 86.51" stroke="black" stroke-width="0.5" />
    <path d="M196.76 86.51L197.5 86.13" stroke="black" stroke-width="0.5" />
    <path d="M197.5 86.13L198 85.51" stroke="black" stroke-width="0.5" />
    <path d="M198 85.51L198.17 84.77" stroke="black" stroke-width="0.5" />
    <path d="M197.78 84.77V84.73" stroke="black" stroke-width="0.5" />
    <path d="M197.78 84.73V84.7" stroke="black" stroke-width="0.5" />
    <path d="M197.78 84.7V84.66" stroke="black" stroke-width="0.5" />
    <path d="M197.78 84.66V84.63" stroke="black" stroke-width="0.5" />
    <path d="M197.78 84.63L197.77 84.59" stroke="black" stroke-width="0.5" />
    <path d="M197.77 84.59V84.56" stroke="black" stroke-width="0.5" />
    <path d="M197.77 84.56L197.76 84.53" stroke="black" stroke-width="0.5" />
    <path d="M197.76 84.53L197.75 84.49" stroke="black" stroke-width="0.5" />
    <path d="M197.75 84.49V84.46" stroke="black" stroke-width="0.5" />
    <path d="M197.75 84.46L197.74 84.42" stroke="black" stroke-width="0.5" />
    <path d="M197.74 84.42L197.73 84.39" stroke="black" stroke-width="0.5" />
    <path d="M197.73 84.39L197.72 84.36" stroke="black" stroke-width="0.5" />
    <path d="M197.72 84.36L197.7 84.32" stroke="black" stroke-width="0.5" />
    <path d="M197.7 84.32L197.69 84.29" stroke="black" stroke-width="0.5" />
    <path d="M197.69 84.29L197.68 84.26" stroke="black" stroke-width="0.5" />
    <path d="M197.68 84.26L197.66 84.23" stroke="black" stroke-width="0.5" />
    <path d="M197.66 84.23L197.65 84.19" stroke="black" stroke-width="0.5" />
    <path d="M197.65 84.19L197.63 84.16" stroke="black" stroke-width="0.5" />
    <path d="M197.63 84.16L197.62 84.13" stroke="black" stroke-width="0.5" />
    <path d="M197.62 84.13L197.6 84.1" stroke="black" stroke-width="0.5" />
    <path d="M197.6 84.1L197.58 84.07" stroke="black" stroke-width="0.5" />
    <path d="M197.58 84.07L197.56 84.04" stroke="black" stroke-width="0.5" />
    <path d="M197.56 84.04L197.54 84.01" stroke="black" stroke-width="0.5" />
    <path d="M197.54 84.01L197.52 83.98" stroke="black" stroke-width="0.5" />
    <path d="M197.52 83.98L197.49 83.95" stroke="black" stroke-width="0.5" />
    <path d="M197.49 83.95L197.47 83.92" stroke="black" stroke-width="0.5" />
    <path d="M197.47 83.92L197.45 83.89" stroke="black" stroke-width="0.5" />
    <path d="M197.45 83.89L197.42 83.86" stroke="black" stroke-width="0.5" />
    <path d="M197.42 83.86L197.4 83.84" stroke="black" stroke-width="0.5" />
    <path d="M197.4 83.84L197.37 83.81" stroke="black" stroke-width="0.5" />
    <path d="M197.37 83.81L197.34 83.78" stroke="black" stroke-width="0.5" />
    <path d="M197.34 83.78L197.31 83.76" stroke="black" stroke-width="0.5" />
    <path d="M197.31 83.76L197.29 83.73" stroke="black" stroke-width="0.5" />
    <path d="M197.29 83.73L197.26 83.71" stroke="black" stroke-width="0.5" />
    <path d="M197.26 83.71L197.23 83.68" stroke="black" stroke-width="0.5" />
    <path d="M197.23 83.68L197.19 83.66" stroke="black" stroke-width="0.5" />
    <path d="M197.19 83.66L197.16 83.64" stroke="black" stroke-width="0.5" />
    <path d="M197.16 83.64L197.13 83.61" stroke="black" stroke-width="0.5" />
    <path d="M197.13 83.61L197.1 83.59" stroke="black" stroke-width="0.5" />
    <path d="M197.1 83.59L197.06 83.57" stroke="black" stroke-width="0.5" />
    <path d="M197.06 83.57L197.03 83.55" stroke="black" stroke-width="0.5" />
    <path d="M197.03 83.55L196.99 83.53" stroke="black" stroke-width="0.5" />
    <path d="M196.99 83.53L196.96 83.51" stroke="black" stroke-width="0.5" />
    <path d="M196.96 83.51L196.92 83.5" stroke="black" stroke-width="0.5" />
    <path d="M196.92 83.5L196.89 83.48" stroke="black" stroke-width="0.5" />
    <path d="M196.89 83.48L196.85 83.46" stroke="black" stroke-width="0.5" />
    <path d="M196.85 83.46L196.81 83.45" stroke="black" stroke-width="0.5" />
    <path d="M196.81 83.45L196.77 83.43" stroke="black" stroke-width="0.5" />
    <path d="M196.77 83.43L196.74 83.42" stroke="black" stroke-width="0.5" />
    <path d="M196.74 83.42L196.7 83.41" stroke="black" stroke-width="0.5" />
    <path d="M196.7 83.41L196.66 83.4" stroke="black" stroke-width="0.5" />
    <path d="M196.66 83.4L196.62 83.38" stroke="black" stroke-width="0.5" />
    <path d="M196.62 83.38L196.58 83.37" stroke="black" stroke-width="0.5" />
    <path d="M196.58 83.37L196.54 83.36" stroke="black" stroke-width="0.5" />
    <path d="M196.54 83.36H196.5" stroke="black" stroke-width="0.5" />
    <path d="M196.5 83.36L196.46 83.35" stroke="black" stroke-width="0.5" />
    <path d="M196.46 83.35L196.42 83.34" stroke="black" stroke-width="0.5" />
    <path d="M196.42 83.34H196.38" stroke="black" stroke-width="0.5" />
    <path d="M196.38 83.34L196.33 83.33" stroke="black" stroke-width="0.5" />
    <path d="M196.33 83.33H196.29" stroke="black" stroke-width="0.5" />
    <path d="M196.29 83.33L196.25 83.32" stroke="black" stroke-width="0.5" />
    <path d="M196.25 83.32H196.21" stroke="black" stroke-width="0.5" />
    <path d="M196.21 83.32H196.17" stroke="black" stroke-width="0.5" />
    <path d="M196.17 83.32H196.13" stroke="black" stroke-width="0.5" />
    <path d="M196.13 83.32H196.08" stroke="black" stroke-width="0.5" />
    <path d="M196.08 83.32H196.04" stroke="black" stroke-width="0.5" />
    <path d="M196.04 83.32H196" stroke="black" stroke-width="0.5" />
    <path d="M196 83.32L195.96 83.33" stroke="black" stroke-width="0.5" />
    <path d="M195.96 83.33H195.92" stroke="black" stroke-width="0.5" />
    <path d="M195.92 83.33L195.88 83.34" stroke="black" stroke-width="0.5" />
    <path d="M195.88 83.34H195.84" stroke="black" stroke-width="0.5" />
    <path d="M195.84 83.34L195.8 83.35" stroke="black" stroke-width="0.5" />
    <path d="M195.8 83.35L195.75 83.36" stroke="black" stroke-width="0.5" />
    <path d="M195.75 83.36H195.71" stroke="black" stroke-width="0.5" />
    <path d="M195.71 83.36L195.67 83.37" stroke="black" stroke-width="0.5" />
    <path d="M195.67 83.37L195.63 83.38" stroke="black" stroke-width="0.5" />
    <path d="M195.63 83.38L195.59 83.4" stroke="black" stroke-width="0.5" />
    <path d="M195.59 83.4L195.56 83.41" stroke="black" stroke-width="0.5" />
    <path d="M195.56 83.41L195.52 83.42" stroke="black" stroke-width="0.5" />
    <path d="M195.52 83.42L195.48 83.43" stroke="black" stroke-width="0.5" />
    <path d="M195.48 83.43L195.44 83.45" stroke="black" stroke-width="0.5" />
    <path d="M195.44 83.45L195.4 83.46" stroke="black" stroke-width="0.5" />
    <path d="M195.4 83.46L195.37 83.48" stroke="black" stroke-width="0.5" />
    <path d="M195.37 83.48L195.33 83.5" stroke="black" stroke-width="0.5" />
    <path d="M195.33 83.5L195.29 83.52" stroke="black" stroke-width="0.5" />
    <path d="M195.29 83.52L195.26 83.53" stroke="black" stroke-width="0.5" />
    <path d="M195.26 83.53L195.22 83.55" stroke="black" stroke-width="0.5" />
    <path d="M195.22 83.55L195.19 83.57" stroke="black" stroke-width="0.5" />
    <path d="M195.19 83.57L195.16 83.59" stroke="black" stroke-width="0.5" />
    <path d="M195.16 83.59L195.12 83.61" stroke="black" stroke-width="0.5" />
    <path d="M195.12 83.61L195.09 83.64" stroke="black" stroke-width="0.5" />
    <path d="M195.09 83.64L195.06 83.66" stroke="black" stroke-width="0.5" />
    <path d="M195.06 83.66L195.03 83.68" stroke="black" stroke-width="0.5" />
    <path d="M195.03 83.68L195 83.71" stroke="black" stroke-width="0.5" />
    <path d="M195 83.71L194.97 83.73" stroke="black" stroke-width="0.5" />
    <path d="M194.97 83.73L194.94 83.76" stroke="black" stroke-width="0.5" />
    <path d="M194.94 83.76L194.91 83.78" stroke="black" stroke-width="0.5" />
    <path d="M194.91 83.78L194.88 83.81" stroke="black" stroke-width="0.5" />
    <path d="M194.88 83.81L194.86 83.84" stroke="black" stroke-width="0.5" />
    <path d="M194.86 83.84L194.83 83.86" stroke="black" stroke-width="0.5" />
    <path d="M194.83 83.86L194.81 83.89" stroke="black" stroke-width="0.5" />
    <path d="M194.81 83.89L194.78 83.92" stroke="black" stroke-width="0.5" />
    <path d="M194.78 83.92L194.76 83.95" stroke="black" stroke-width="0.5" />
    <path d="M194.76 83.95L194.74 83.98" stroke="black" stroke-width="0.5" />
    <path d="M194.74 83.98L194.71 84.01" stroke="black" stroke-width="0.5" />
    <path d="M194.71 84.01L194.69 84.04" stroke="black" stroke-width="0.5" />
    <path d="M194.69 84.04L194.67 84.07" stroke="black" stroke-width="0.5" />
    <path d="M194.67 84.07L194.65 84.1" stroke="black" stroke-width="0.5" />
    <path d="M194.65 84.1L194.64 84.13" stroke="black" stroke-width="0.5" />
    <path d="M194.64 84.13L194.62 84.16" stroke="black" stroke-width="0.5" />
    <path d="M194.62 84.16L194.6 84.19" stroke="black" stroke-width="0.5" />
    <path d="M194.6 84.19L194.59 84.23" stroke="black" stroke-width="0.5" />
    <path d="M194.59 84.23L194.57 84.26" stroke="black" stroke-width="0.5" />
    <path d="M194.57 84.26L194.56 84.29" stroke="black" stroke-width="0.5" />
    <path d="M194.56 84.29L194.55 84.32" stroke="black" stroke-width="0.5" />
    <path d="M194.55 84.32L194.54 84.36" stroke="black" stroke-width="0.5" />
    <path d="M194.54 84.36L194.52 84.39" stroke="black" stroke-width="0.5" />
    <path d="M194.52 84.39V84.42" stroke="black" stroke-width="0.5" />
    <path d="M194.52 84.42L194.51 84.46" stroke="black" stroke-width="0.5" />
    <path d="M194.51 84.46L194.5 84.49" stroke="black" stroke-width="0.5" />
    <path d="M194.5 84.49L194.49 84.53" stroke="black" stroke-width="0.5" />
    <path d="M194.49 84.53L194.48 84.56" stroke="black" stroke-width="0.5" />
    <path d="M194.48 84.56V84.59" stroke="black" stroke-width="0.5" />
    <path d="M194.48 84.59V84.63" stroke="black" stroke-width="0.5" />
    <path d="M194.48 84.63L194.47 84.66" stroke="black" stroke-width="0.5" />
    <path d="M194.47 84.66V84.7" stroke="black" stroke-width="0.5" />
    <path d="M194.47 84.7V84.73" stroke="black" stroke-width="0.5" />
    <path d="M194.47 84.73V84.77" stroke="black" stroke-width="0.5" />
    <path d="M195.82 86.58V85.16" stroke="black" stroke-width="0.5" />
    <path d="M195.3 86.45V85.16" stroke="black" stroke-width="0.5" />
    <path
      d="M195.82 85.16C195.82 85.091 195.793 85.0249 195.744 84.9762C195.695 84.9274 195.629 84.9 195.56 84.9C195.491 84.9 195.425 84.9274 195.376 84.9762C195.327 85.0249 195.3 85.091 195.3 85.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.47 84.77C194.645 85.1374 194.937 85.4364 195.3 85.62"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.95 85.62C197.313 85.4364 197.605 85.1374 197.78 84.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M196.43 86.58V85.39" stroke="black" stroke-width="0.5" />
    <path d="M196.95 86.45V85.39" stroke="black" stroke-width="0.5" />
    <path
      d="M196.95 85.39C196.95 85.3211 196.923 85.2549 196.874 85.2061C196.825 85.1574 196.759 85.13 196.69 85.13C196.621 85.13 196.555 85.1574 196.506 85.2061C196.457 85.2549 196.43 85.3211 196.43 85.39"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M196.43 85.65H195.82" stroke="black" stroke-width="0.5" />
    <path d="M209.33 84.77L209.16 84.02" stroke="black" stroke-width="0.5" />
    <path d="M209.16 84.02L208.65 83.4" stroke="black" stroke-width="0.5" />
    <path d="M208.65 83.4L207.92 83.02" stroke="black" stroke-width="0.5" />
    <path d="M207.92 83.02L207.07 82.94" stroke="black" stroke-width="0.5" />
    <path d="M207.07 82.94L206.26 83.18" stroke="black" stroke-width="0.5" />
    <path d="M206.26 83.18L205.63 83.69" stroke="black" stroke-width="0.5" />
    <path d="M205.63 83.69L205.28 84.38" stroke="black" stroke-width="0.5" />
    <path d="M205.28 84.38V85.15" stroke="black" stroke-width="0.5" />
    <path d="M205.28 85.15L205.63 85.84" stroke="black" stroke-width="0.5" />
    <path d="M205.63 85.84L206.26 86.36" stroke="black" stroke-width="0.5" />
    <path d="M206.26 86.36L207.07 86.59" stroke="black" stroke-width="0.5" />
    <path d="M207.07 86.59L207.92 86.51" stroke="black" stroke-width="0.5" />
    <path d="M207.92 86.51L208.65 86.13" stroke="black" stroke-width="0.5" />
    <path d="M208.65 86.13L209.16 85.51" stroke="black" stroke-width="0.5" />
    <path d="M209.16 85.51L209.33 84.77" stroke="black" stroke-width="0.5" />
    <path d="M208.94 84.77V84.73" stroke="black" stroke-width="0.5" />
    <path d="M208.94 84.73V84.7" stroke="black" stroke-width="0.5" />
    <path d="M208.94 84.7V84.66" stroke="black" stroke-width="0.5" />
    <path d="M208.94 84.66V84.63" stroke="black" stroke-width="0.5" />
    <path d="M208.94 84.63L208.93 84.59" stroke="black" stroke-width="0.5" />
    <path d="M208.93 84.59V84.56" stroke="black" stroke-width="0.5" />
    <path d="M208.93 84.56L208.92 84.53" stroke="black" stroke-width="0.5" />
    <path d="M208.92 84.53L208.91 84.49" stroke="black" stroke-width="0.5" />
    <path d="M208.91 84.49L208.9 84.46" stroke="black" stroke-width="0.5" />
    <path d="M208.9 84.46V84.42" stroke="black" stroke-width="0.5" />
    <path d="M208.9 84.42L208.89 84.39" stroke="black" stroke-width="0.5" />
    <path d="M208.89 84.39L208.88 84.36" stroke="black" stroke-width="0.5" />
    <path d="M208.88 84.36L208.86 84.32" stroke="black" stroke-width="0.5" />
    <path d="M208.86 84.32L208.85 84.29" stroke="black" stroke-width="0.5" />
    <path d="M208.85 84.29L208.84 84.26" stroke="black" stroke-width="0.5" />
    <path d="M208.84 84.26L208.82 84.23" stroke="black" stroke-width="0.5" />
    <path d="M208.82 84.23L208.81 84.19" stroke="black" stroke-width="0.5" />
    <path d="M208.81 84.19L208.79 84.16" stroke="black" stroke-width="0.5" />
    <path d="M208.79 84.16L208.77 84.13" stroke="black" stroke-width="0.5" />
    <path d="M208.77 84.13L208.76 84.1" stroke="black" stroke-width="0.5" />
    <path d="M208.76 84.1L208.74 84.07" stroke="black" stroke-width="0.5" />
    <path d="M208.74 84.07L208.72 84.04" stroke="black" stroke-width="0.5" />
    <path d="M208.72 84.04L208.7 84.01" stroke="black" stroke-width="0.5" />
    <path d="M208.7 84.01L208.67 83.98" stroke="black" stroke-width="0.5" />
    <path d="M208.67 83.98L208.65 83.95" stroke="black" stroke-width="0.5" />
    <path d="M208.65 83.95L208.63 83.92" stroke="black" stroke-width="0.5" />
    <path d="M208.63 83.92L208.61 83.89" stroke="black" stroke-width="0.5" />
    <path d="M208.61 83.89L208.58 83.86" stroke="black" stroke-width="0.5" />
    <path d="M208.58 83.86L208.55 83.84" stroke="black" stroke-width="0.5" />
    <path d="M208.55 83.84L208.53 83.81" stroke="black" stroke-width="0.5" />
    <path d="M208.53 83.81L208.5 83.78" stroke="black" stroke-width="0.5" />
    <path d="M208.5 83.78L208.47 83.76" stroke="black" stroke-width="0.5" />
    <path d="M208.47 83.76L208.44 83.73" stroke="black" stroke-width="0.5" />
    <path d="M208.44 83.73L208.41 83.71" stroke="black" stroke-width="0.5" />
    <path d="M208.41 83.71L208.38 83.68" stroke="black" stroke-width="0.5" />
    <path d="M208.38 83.68L208.35 83.66" stroke="black" stroke-width="0.5" />
    <path d="M208.35 83.66L208.32 83.64" stroke="black" stroke-width="0.5" />
    <path d="M208.32 83.64L208.29 83.61" stroke="black" stroke-width="0.5" />
    <path d="M208.29 83.61L208.26 83.59" stroke="black" stroke-width="0.5" />
    <path d="M208.26 83.59L208.22 83.57" stroke="black" stroke-width="0.5" />
    <path d="M208.22 83.57L208.19 83.55" stroke="black" stroke-width="0.5" />
    <path d="M208.19 83.55L208.15 83.53" stroke="black" stroke-width="0.5" />
    <path d="M208.15 83.53L208.12 83.51" stroke="black" stroke-width="0.5" />
    <path d="M208.12 83.51L208.08 83.5" stroke="black" stroke-width="0.5" />
    <path d="M208.08 83.5L208.05 83.48" stroke="black" stroke-width="0.5" />
    <path d="M208.05 83.48L208.01 83.46" stroke="black" stroke-width="0.5" />
    <path d="M208.01 83.46L207.97 83.45" stroke="black" stroke-width="0.5" />
    <path d="M207.97 83.45L207.93 83.43" stroke="black" stroke-width="0.5" />
    <path d="M207.93 83.43L207.89 83.42" stroke="black" stroke-width="0.5" />
    <path d="M207.89 83.42L207.86 83.41" stroke="black" stroke-width="0.5" />
    <path d="M207.86 83.41L207.82 83.4" stroke="black" stroke-width="0.5" />
    <path d="M207.82 83.4L207.78 83.38" stroke="black" stroke-width="0.5" />
    <path d="M207.78 83.38L207.74 83.37" stroke="black" stroke-width="0.5" />
    <path d="M207.74 83.37L207.7 83.36" stroke="black" stroke-width="0.5" />
    <path d="M207.7 83.36H207.66" stroke="black" stroke-width="0.5" />
    <path d="M207.66 83.36L207.62 83.35" stroke="black" stroke-width="0.5" />
    <path d="M207.62 83.35L207.57 83.34" stroke="black" stroke-width="0.5" />
    <path d="M207.57 83.34H207.53" stroke="black" stroke-width="0.5" />
    <path d="M207.53 83.34L207.49 83.33" stroke="black" stroke-width="0.5" />
    <path d="M207.49 83.33H207.45" stroke="black" stroke-width="0.5" />
    <path d="M207.45 83.33L207.41 83.32" stroke="black" stroke-width="0.5" />
    <path d="M207.41 83.32H207.37" stroke="black" stroke-width="0.5" />
    <path d="M207.37 83.32H207.33" stroke="black" stroke-width="0.5" />
    <path d="M207.33 83.32H207.28" stroke="black" stroke-width="0.5" />
    <path d="M207.28 83.32H207.24" stroke="black" stroke-width="0.5" />
    <path d="M207.24 83.32H207.2" stroke="black" stroke-width="0.5" />
    <path d="M207.2 83.32H207.16" stroke="black" stroke-width="0.5" />
    <path d="M207.16 83.32L207.12 83.33" stroke="black" stroke-width="0.5" />
    <path d="M207.12 83.33H207.08" stroke="black" stroke-width="0.5" />
    <path d="M207.08 83.33L207.04 83.34" stroke="black" stroke-width="0.5" />
    <path d="M207.04 83.34H206.99" stroke="black" stroke-width="0.5" />
    <path d="M206.99 83.34L206.95 83.35" stroke="black" stroke-width="0.5" />
    <path d="M206.95 83.35L206.91 83.36" stroke="black" stroke-width="0.5" />
    <path d="M206.91 83.36H206.87" stroke="black" stroke-width="0.5" />
    <path d="M206.87 83.36L206.83 83.37" stroke="black" stroke-width="0.5" />
    <path d="M206.83 83.37L206.79 83.38" stroke="black" stroke-width="0.5" />
    <path d="M206.79 83.38L206.75 83.4" stroke="black" stroke-width="0.5" />
    <path d="M206.75 83.4L206.71 83.41" stroke="black" stroke-width="0.5" />
    <path d="M206.71 83.41L206.68 83.42" stroke="black" stroke-width="0.5" />
    <path d="M206.68 83.42L206.64 83.43" stroke="black" stroke-width="0.5" />
    <path d="M206.64 83.43L206.6 83.45" stroke="black" stroke-width="0.5" />
    <path d="M206.6 83.45L206.56 83.46" stroke="black" stroke-width="0.5" />
    <path d="M206.56 83.46L206.52 83.48" stroke="black" stroke-width="0.5" />
    <path d="M206.52 83.48L206.49 83.5" stroke="black" stroke-width="0.5" />
    <path d="M206.49 83.5L206.45 83.52" stroke="black" stroke-width="0.5" />
    <path d="M206.45 83.52L206.42 83.53" stroke="black" stroke-width="0.5" />
    <path d="M206.42 83.53L206.38 83.55" stroke="black" stroke-width="0.5" />
    <path d="M206.38 83.55L206.35 83.57" stroke="black" stroke-width="0.5" />
    <path d="M206.35 83.57L206.31 83.59" stroke="black" stroke-width="0.5" />
    <path d="M206.31 83.59L206.28 83.61" stroke="black" stroke-width="0.5" />
    <path d="M206.28 83.61L206.25 83.64" stroke="black" stroke-width="0.5" />
    <path d="M206.25 83.64L206.22 83.66" stroke="black" stroke-width="0.5" />
    <path d="M206.22 83.66L206.19 83.68" stroke="black" stroke-width="0.5" />
    <path d="M206.19 83.68L206.16 83.71" stroke="black" stroke-width="0.5" />
    <path d="M206.16 83.71L206.13 83.73" stroke="black" stroke-width="0.5" />
    <path d="M206.13 83.73L206.1 83.76" stroke="black" stroke-width="0.5" />
    <path d="M206.1 83.76L206.07 83.78" stroke="black" stroke-width="0.5" />
    <path d="M206.07 83.78L206.04 83.81" stroke="black" stroke-width="0.5" />
    <path d="M206.04 83.81L206.02 83.84" stroke="black" stroke-width="0.5" />
    <path d="M206.02 83.84L205.99 83.86" stroke="black" stroke-width="0.5" />
    <path d="M205.99 83.86L205.96 83.89" stroke="black" stroke-width="0.5" />
    <path d="M205.96 83.89L205.94 83.92" stroke="black" stroke-width="0.5" />
    <path d="M205.94 83.92L205.92 83.95" stroke="black" stroke-width="0.5" />
    <path d="M205.92 83.95L205.89 83.98" stroke="black" stroke-width="0.5" />
    <path d="M205.89 83.98L205.87 84.01" stroke="black" stroke-width="0.5" />
    <path d="M205.87 84.01L205.85 84.04" stroke="black" stroke-width="0.5" />
    <path d="M205.85 84.04L205.83 84.07" stroke="black" stroke-width="0.5" />
    <path d="M205.83 84.07L205.81 84.1" stroke="black" stroke-width="0.5" />
    <path d="M205.81 84.1L205.8 84.13" stroke="black" stroke-width="0.5" />
    <path d="M205.8 84.13L205.78 84.16" stroke="black" stroke-width="0.5" />
    <path d="M205.78 84.16L205.76 84.19" stroke="black" stroke-width="0.5" />
    <path d="M205.76 84.19L205.75 84.23" stroke="black" stroke-width="0.5" />
    <path d="M205.75 84.23L205.73 84.26" stroke="black" stroke-width="0.5" />
    <path d="M205.73 84.26L205.72 84.29" stroke="black" stroke-width="0.5" />
    <path d="M205.72 84.29L205.71 84.32" stroke="black" stroke-width="0.5" />
    <path d="M205.71 84.32L205.69 84.36" stroke="black" stroke-width="0.5" />
    <path d="M205.69 84.36L205.68 84.39" stroke="black" stroke-width="0.5" />
    <path d="M205.68 84.39L205.67 84.42" stroke="black" stroke-width="0.5" />
    <path d="M205.67 84.42L205.66 84.46" stroke="black" stroke-width="0.5" />
    <path d="M205.66 84.46V84.49" stroke="black" stroke-width="0.5" />
    <path d="M205.66 84.49L205.65 84.53" stroke="black" stroke-width="0.5" />
    <path d="M205.65 84.53L205.64 84.56" stroke="black" stroke-width="0.5" />
    <path d="M205.64 84.56V84.59" stroke="black" stroke-width="0.5" />
    <path d="M205.64 84.59L205.63 84.63" stroke="black" stroke-width="0.5" />
    <path d="M205.63 84.63V84.66" stroke="black" stroke-width="0.5" />
    <path d="M205.63 84.66V84.7" stroke="black" stroke-width="0.5" />
    <path d="M205.63 84.7V84.73" stroke="black" stroke-width="0.5" />
    <path d="M205.63 84.73V84.77" stroke="black" stroke-width="0.5" />
    <path d="M206.98 86.58V85.16" stroke="black" stroke-width="0.5" />
    <path d="M206.46 86.45V85.16" stroke="black" stroke-width="0.5" />
    <path
      d="M206.98 85.16C206.98 85.091 206.953 85.0249 206.904 84.9762C206.855 84.9274 206.789 84.9 206.72 84.9C206.651 84.9 206.585 84.9274 206.536 84.9762C206.487 85.0249 206.46 85.091 206.46 85.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M205.63 84.77C205.805 85.1374 206.097 85.4364 206.46 85.62"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M208.11 85.62C208.473 85.4364 208.765 85.1374 208.94 84.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M207.59 86.58V85.39" stroke="black" stroke-width="0.5" />
    <path d="M208.11 86.45V85.39" stroke="black" stroke-width="0.5" />
    <path
      d="M208.11 85.39C208.11 85.3211 208.083 85.2549 208.034 85.2061C207.985 85.1574 207.919 85.13 207.85 85.13C207.781 85.13 207.715 85.1574 207.666 85.2061C207.617 85.2549 207.59 85.3211 207.59 85.39"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M207.59 85.65H206.98" stroke="black" stroke-width="0.5" />
    <path d="M161.69 62.16V60.82" stroke="black" stroke-width="0.5" />
    <path d="M162.23 62.16V60.82" stroke="black" stroke-width="0.5" />
    <path d="M151.55 62.16V60.82" stroke="black" stroke-width="0.5" />
    <path d="M152.09 62.16V60.82" stroke="black" stroke-width="0.5" />
    <path d="M161.69 71.65V70.31" stroke="black" stroke-width="0.5" />
    <path d="M162.23 71.65V70.31" stroke="black" stroke-width="0.5" />
    <path d="M161.69 70.31H163.27" stroke="black" stroke-width="0.5" />
    <path d="M161.69 71.65H163.27" stroke="black" stroke-width="0.5" />
    <path d="M151.55 71.65V70.31" stroke="black" stroke-width="0.5" />
    <path d="M152.09 71.65V70.31" stroke="black" stroke-width="0.5" />
    <path d="M152.09 70.31H142.33" stroke="black" stroke-width="0.5" />
    <path d="M152.09 71.65H147.3" stroke="black" stroke-width="0.5" />
    <path d="M145.97 71.65H140.99" stroke="black" stroke-width="0.5" />
    <path d="M140.99 60.23H142.33" stroke="black" stroke-width="0.5" />
    <path d="M140.99 59.69H142.33" stroke="black" stroke-width="0.5" />
    <path d="M140.99 50.09H142.33" stroke="black" stroke-width="0.5" />
    <path d="M140.99 49.55H142.33" stroke="black" stroke-width="0.5" />
    <path d="M145.97 71.65V72.91" stroke="black" stroke-width="0.5" />
    <path d="M147.3 71.65V72.91" stroke="black" stroke-width="0.5" />
    <path d="M145.97 72.37H147.3" stroke="black" stroke-width="0.5" />
    <path d="M145.97 72.91H147.3" stroke="black" stroke-width="0.5" />
    <path d="M140.99 67.83H105.91" stroke="black" stroke-width="0.5" />
    <path d="M140.99 66.5H138.1" stroke="black" stroke-width="0.5" />
    <path d="M108.17 66.5H105.91" stroke="black" stroke-width="0.5" />
    <path d="M105.91 62.16V161.56" stroke="black" stroke-width="0.5" />
    <path d="M104.57 72.32V161.56" stroke="black" stroke-width="0.5" />
    <path d="M108.17 62.16H111.45" stroke="black" stroke-width="0.5" />
    <path d="M111.45 62.16V67.83" stroke="black" stroke-width="0.5" />
    <path d="M111.45 67.83H108.17" stroke="black" stroke-width="0.5" />
    <path d="M108.17 67.83V62.16" stroke="black" stroke-width="0.5" />
    <path d="M108.17 67.83L111.45 62.16" stroke="black" stroke-width="0.5" />
    <path d="M108.17 62.16L111.45 67.83" stroke="black" stroke-width="0.5" />
    <path d="M114.74 62.16H111.45" stroke="black" stroke-width="0.5" />
    <path d="M111.45 62.16V67.83" stroke="black" stroke-width="0.5" />
    <path d="M111.45 67.83H114.74" stroke="black" stroke-width="0.5" />
    <path d="M114.74 67.83V62.16" stroke="black" stroke-width="0.5" />
    <path d="M114.74 67.83L111.45 62.16" stroke="black" stroke-width="0.5" />
    <path d="M114.74 62.16L111.45 67.83" stroke="black" stroke-width="0.5" />
    <path d="M114.74 62.16H118.02" stroke="black" stroke-width="0.5" />
    <path d="M118.02 62.16V67.83" stroke="black" stroke-width="0.5" />
    <path d="M118.02 67.83H114.74" stroke="black" stroke-width="0.5" />
    <path d="M114.74 67.83V62.16" stroke="black" stroke-width="0.5" />
    <path d="M114.74 67.83L118.02 62.16" stroke="black" stroke-width="0.5" />
    <path d="M114.74 62.16L118.02 67.83" stroke="black" stroke-width="0.5" />
    <path d="M121.3 62.16H118.02" stroke="black" stroke-width="0.5" />
    <path d="M118.02 62.16V67.83" stroke="black" stroke-width="0.5" />
    <path d="M118.02 67.83H121.3" stroke="black" stroke-width="0.5" />
    <path d="M121.3 67.83V62.16" stroke="black" stroke-width="0.5" />
    <path d="M121.3 67.83L118.02 62.16" stroke="black" stroke-width="0.5" />
    <path d="M121.3 62.16L118.02 67.83" stroke="black" stroke-width="0.5" />
    <path d="M121.3 62.16H124.58" stroke="black" stroke-width="0.5" />
    <path d="M124.58 62.16V67.83" stroke="black" stroke-width="0.5" />
    <path d="M124.58 67.83H121.3" stroke="black" stroke-width="0.5" />
    <path d="M121.3 67.83V62.16" stroke="black" stroke-width="0.5" />
    <path d="M121.3 67.83L124.58 62.16" stroke="black" stroke-width="0.5" />
    <path d="M121.3 62.16L124.58 67.83" stroke="black" stroke-width="0.5" />
    <path d="M127.86 62.16H124.58" stroke="black" stroke-width="0.5" />
    <path d="M124.58 62.16V67.83" stroke="black" stroke-width="0.5" />
    <path d="M124.58 67.83H127.86" stroke="black" stroke-width="0.5" />
    <path d="M127.86 67.83V62.16" stroke="black" stroke-width="0.5" />
    <path d="M127.86 67.83L124.58 62.16" stroke="black" stroke-width="0.5" />
    <path d="M127.86 62.16L124.58 67.83" stroke="black" stroke-width="0.5" />
    <path d="M129 62.16H133.55" stroke="black" stroke-width="0.5" />
    <path d="M133.55 62.16V67.83" stroke="black" stroke-width="0.5" />
    <path d="M133.55 67.83H129" stroke="black" stroke-width="0.5" />
    <path d="M129 67.83V62.16" stroke="black" stroke-width="0.5" />
    <path d="M129 67.83L133.55 62.16" stroke="black" stroke-width="0.5" />
    <path d="M129 62.16L133.55 67.83" stroke="black" stroke-width="0.5" />
    <path d="M138.1 62.16H133.55" stroke="black" stroke-width="0.5" />
    <path d="M133.55 62.16V67.83" stroke="black" stroke-width="0.5" />
    <path d="M133.55 67.83H138.1" stroke="black" stroke-width="0.5" />
    <path d="M138.1 67.83V62.16" stroke="black" stroke-width="0.5" />
    <path d="M138.1 67.83L133.55 62.16" stroke="black" stroke-width="0.5" />
    <path d="M138.1 62.16L133.55 67.83" stroke="black" stroke-width="0.5" />
    <path d="M105.91 72.32H99.77" stroke="black" stroke-width="0.5" />
    <path d="M99.77 72.32V60.82" stroke="black" stroke-width="0.5" />
    <path d="M99.77 66.57H105.24" stroke="black" stroke-width="0.5" />
    <path d="M101.14 69.43H104.36" stroke="black" stroke-width="0.5" />
    <path d="M104.36 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M101.14 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M101.14 70.76H104.36" stroke="black" stroke-width="0.5" />
    <path d="M104.36 68.09H101.14" stroke="black" stroke-width="0.5" />
    <path d="M3.42 72.32H-2.72" stroke="black" stroke-width="0.5" />
    <path d="M-2.72 66.57H3.42" stroke="black" stroke-width="0.5" />
    <path d="M-1.35001 69.43H1.87" stroke="black" stroke-width="0.5" />
    <path d="M1.87 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M-1.35001 70.76H1.87" stroke="black" stroke-width="0.5" />
    <path d="M1.87 68.09H-1.35001" stroke="black" stroke-width="0.5" />
    <path d="M3.41998 72.32V66.57" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 115.13H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 113.57H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 124.73H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 126.41H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 139.17H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 152.13H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M13.9 145.59H20.9" stroke="black" stroke-width="0.5" />
    <path d="M20.9 145.59V152.35" stroke="black" stroke-width="0.5" />
    <path d="M20.9 152.35H13.9" stroke="black" stroke-width="0.5" />
    <path d="M13.9 152.35V145.59" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 39.83H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 30.22H0.650009" stroke="black" stroke-width="0.5" />
    <path d="M-104.54 166.9H96.28" stroke="black" stroke-width="0.5" />
    <path d="M-106.58 168.94H92" stroke="black" stroke-width="0.5" />
    <path d="M40.67 168.11H40.14" stroke="black" stroke-width="0.5" />
    <path d="M40.14 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M40.14 166.9H40.67" stroke="black" stroke-width="0.5" />
    <path d="M40.67 166.9V168.11" stroke="black" stroke-width="0.5" />
    <path d="M11.32 166.9H11.85" stroke="black" stroke-width="0.5" />
    <path d="M11.85 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M16.16 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M16.72 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M11.85 168.11H11.32" stroke="black" stroke-width="0.5" />
    <path d="M11.32 168.11V166.9" stroke="black" stroke-width="0.5" />
    <path d="M35.29 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M35.29 166.9H35.83" stroke="black" stroke-width="0.5" />
    <path d="M35.83 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M35.83 168.11H35.29" stroke="black" stroke-width="0.5" />
    <path d="M40.14 166.9H35.83" stroke="black" stroke-width="0.5" />
    <path d="M35.29 166.9H16.72" stroke="black" stroke-width="0.5" />
    <path d="M16.16 166.9H11.85" stroke="black" stroke-width="0.5" />
    <path d="M35.29 168.11H16.72" stroke="black" stroke-width="0.5" />
    <path d="M16.16 168.11H11.85" stroke="black" stroke-width="0.5" />
    <path d="M40.14 168.11H35.83" stroke="black" stroke-width="0.5" />
    <path d="M11.85 167.79H40.14" stroke="black" stroke-width="0.5" />
    <path d="M40.14 167.52H11.85" stroke="black" stroke-width="0.5" />
    <path d="M40.14 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path
      d="M11.85 167.52V167.79L40.14 167.52H11.85Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M40.14 167.52L11.85 167.79H40.14V167.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M11.85 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path d="M16.16 168.11H16.72" stroke="black" stroke-width="0.5" />
    <path d="M16.16 166.9H16.72" stroke="black" stroke-width="0.5" />
    <path d="M91.91 168.11H91.37" stroke="black" stroke-width="0.5" />
    <path d="M91.37 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M91.37 166.9H91.91" stroke="black" stroke-width="0.5" />
    <path d="M91.91 166.9V168.11" stroke="black" stroke-width="0.5" />
    <path d="M62.55 166.9H63.09" stroke="black" stroke-width="0.5" />
    <path d="M63.09 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M67.4 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M67.95 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M63.09 168.11H62.55" stroke="black" stroke-width="0.5" />
    <path d="M62.55 168.11V166.9" stroke="black" stroke-width="0.5" />
    <path d="M86.53 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M86.53 166.9H87.06" stroke="black" stroke-width="0.5" />
    <path d="M87.06 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M87.06 168.11H86.53" stroke="black" stroke-width="0.5" />
    <path d="M91.37 166.9H87.06" stroke="black" stroke-width="0.5" />
    <path d="M86.53 166.9H67.95" stroke="black" stroke-width="0.5" />
    <path d="M67.4 166.9H63.09" stroke="black" stroke-width="0.5" />
    <path d="M86.53 168.11H67.95" stroke="black" stroke-width="0.5" />
    <path d="M67.4 168.11H63.09" stroke="black" stroke-width="0.5" />
    <path d="M91.37 168.11H87.06" stroke="black" stroke-width="0.5" />
    <path d="M63.09 167.79H91.37" stroke="black" stroke-width="0.5" />
    <path d="M91.37 167.52H63.09" stroke="black" stroke-width="0.5" />
    <path d="M91.37 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path
      d="M63.09 167.52V167.79L91.37 167.52H63.09Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M91.37 167.52L63.09 167.79H91.37V167.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M63.09 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path d="M67.4 168.11H67.95" stroke="black" stroke-width="0.5" />
    <path d="M67.4 166.9H67.95" stroke="black" stroke-width="0.5" />
    <path d="M11.32 166.9H40.67" stroke="black" stroke-width="0.5" />
    <path d="M40.67 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M40.67 164.76H11.32" stroke="black" stroke-width="0.5" />
    <path d="M11.32 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M26.68 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M25.43 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M11.32 165.83H15.79" stroke="black" stroke-width="0.5" />
    <path d="M17.13 165.83H19.77" stroke="black" stroke-width="0.5" />
    <path d="M21.62 165.83H24.44" stroke="black" stroke-width="0.5" />
    <path d="M26.53 165.83H28.85" stroke="black" stroke-width="0.5" />
    <path d="M31.15 165.83H33.46" stroke="black" stroke-width="0.5" />
    <path d="M35.91 165.83H38.07" stroke="black" stroke-width="0.5" />
    <path d="M39.46 165.83H40.67" stroke="black" stroke-width="0.5" />
    <path d="M62.55 166.9H91.91" stroke="black" stroke-width="0.5" />
    <path d="M91.91 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M91.91 164.76H62.55" stroke="black" stroke-width="0.5" />
    <path d="M62.55 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M62.55 165.83H67.03" stroke="black" stroke-width="0.5" />
    <path d="M68.37 165.83H71" stroke="black" stroke-width="0.5" />
    <path d="M72.86 165.83H75.67" stroke="black" stroke-width="0.5" />
    <path d="M77.77 165.83H80.09" stroke="black" stroke-width="0.5" />
    <path d="M82.38 165.83H84.69" stroke="black" stroke-width="0.5" />
    <path d="M87.15 165.83H89.3" stroke="black" stroke-width="0.5" />
    <path d="M90.7 165.83H91.91" stroke="black" stroke-width="0.5" />
    <path d="M11.32 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M40.67 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M62.55 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M91.91 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M96.28 161.5V166.9" stroke="black" stroke-width="0.5" />
    <path d="M-0.5 164.76H11.32" stroke="black" stroke-width="0.5" />
    <path d="M40.67 164.76H62.55" stroke="black" stroke-width="0.5" />
    <path d="M91.91 164.76H94.68" stroke="black" stroke-width="0.5" />
    <path d="M94.68 164.76V161.56" stroke="black" stroke-width="0.5" />
    <path d="M94.68 161.56H106" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 159.16H6.44002" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 160.14H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 161.12H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 162.1H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 163.08H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M-6.10999 164.07H6.70001" stroke="black" stroke-width="0.5" />
    <path d="M6.70001 164.76V160.14" stroke="black" stroke-width="0.5" />
    <path d="M6.70001 160.14V156.94" stroke="black" stroke-width="0.5" />
    <path d="M6.7 156.94H3.5" stroke="black" stroke-width="0.5" />
    <path d="M3.5 156.94V158.01" stroke="black" stroke-width="0.5" />
    <path d="M3.50002 158.01H-6.10999" stroke="black" stroke-width="0.5" />
    <path d="M3.5 158.01H6.7" stroke="black" stroke-width="0.5" />
    <path d="M-0.0499878 124.73V124.27" stroke="black" stroke-width="0.5" />
    <path d="M164.62 20.62V43.38" stroke="black" stroke-width="0.5" />
    <path d="M163.29 19.29V43.38" stroke="black" stroke-width="0.5" />
    <path d="M151.93 59.69H142.33" stroke="black" stroke-width="0.5" />
    <path d="M142.33 59.23H151.92" stroke="black" stroke-width="0.5" />
    <path d="M142.33 59.69V59.23" stroke="black" stroke-width="0.5" />
    <path d="M142.33 50.09L144.2 50.27" stroke="black" stroke-width="0.5" />
    <path d="M144.2 50.27L146 50.82" stroke="black" stroke-width="0.5" />
    <path d="M146 50.82L147.66 51.71" stroke="black" stroke-width="0.5" />
    <path d="M147.66 51.71L149.12 52.9" stroke="black" stroke-width="0.5" />
    <path d="M149.12 52.9L150.31 54.36" stroke="black" stroke-width="0.5" />
    <path d="M150.31 54.36L151.2 56.02" stroke="black" stroke-width="0.5" />
    <path d="M151.2 56.02L151.75 57.82" stroke="black" stroke-width="0.5" />
    <path d="M151.75 57.82L151.93 59.69" stroke="black" stroke-width="0.5" />
    <path d="M161.69 60.71V70.31" stroke="black" stroke-width="0.5" />
    <path d="M161.23 70.31V60.72" stroke="black" stroke-width="0.5" />
    <path d="M161.69 70.31H161.23" stroke="black" stroke-width="0.5" />
    <path d="M152.09 70.31L152.27 68.44" stroke="black" stroke-width="0.5" />
    <path d="M152.27 68.44L152.82 66.64" stroke="black" stroke-width="0.5" />
    <path d="M152.82 66.64L153.71 64.98" stroke="black" stroke-width="0.5" />
    <path d="M153.71 64.98L154.9 63.52" stroke="black" stroke-width="0.5" />
    <path d="M154.9 63.52L156.36 62.32" stroke="black" stroke-width="0.5" />
    <path d="M156.36 62.32L158.02 61.44" stroke="black" stroke-width="0.5" />
    <path d="M158.02 61.44L159.82 60.89" stroke="black" stroke-width="0.5" />
    <path d="M159.82 60.89L161.69 60.71" stroke="black" stroke-width="0.5" />
    <path d="M161.69 71.76V62.16" stroke="black" stroke-width="0.5" />
    <path d="M161.23 62.16V71.75" stroke="black" stroke-width="0.5" />
    <path d="M161.69 62.16H161.23" stroke="black" stroke-width="0.5" />
    <path d="M152.09 62.16L152.27 64.03" stroke="black" stroke-width="0.5" />
    <path d="M152.27 64.03L152.82 65.83" stroke="black" stroke-width="0.5" />
    <path d="M152.82 65.83L153.71 67.49" stroke="black" stroke-width="0.5" />
    <path d="M153.71 67.49L154.9 68.95" stroke="black" stroke-width="0.5" />
    <path d="M154.9 68.95L156.36 70.15" stroke="black" stroke-width="0.5" />
    <path d="M156.36 70.15L158.02 71.03" stroke="black" stroke-width="0.5" />
    <path d="M158.02 71.03L159.82 71.58" stroke="black" stroke-width="0.5" />
    <path d="M159.82 71.58L161.69 71.76" stroke="black" stroke-width="0.5" />
    <path d="M192.82 62.16L193.01 64.03" stroke="black" stroke-width="0.5" />
    <path d="M193.01 64.03L193.55 65.83" stroke="black" stroke-width="0.5" />
    <path d="M193.55 65.83L194.44 67.49" stroke="black" stroke-width="0.5" />
    <path d="M194.44 67.49L195.64 68.95" stroke="black" stroke-width="0.5" />
    <path d="M195.64 68.95L197.09 70.15" stroke="black" stroke-width="0.5" />
    <path d="M197.09 70.15L198.75 71.03" stroke="black" stroke-width="0.5" />
    <path d="M198.75 71.03L200.56 71.58" stroke="black" stroke-width="0.5" />
    <path d="M200.56 71.58L202.43 71.76" stroke="black" stroke-width="0.5" />
    <path d="M202.43 62.16H201.96" stroke="black" stroke-width="0.5" />
    <path d="M201.96 62.16V71.75" stroke="black" stroke-width="0.5" />
    <path d="M202.43 71.76V62.16" stroke="black" stroke-width="0.5" />
    <path d="M119.02 0.529999H123.97" stroke="black" stroke-width="0.5" />
    <path d="M123.97 0.529999V1.41" stroke="black" stroke-width="0.5" />
    <path d="M123.97 1.41H119.02" stroke="black" stroke-width="0.5" />
    <path d="M119.02 1.41V0.529999" stroke="black" stroke-width="0.5" />
    <path d="M119.45 1.41H123.42" stroke="black" stroke-width="0.5" />
    <path d="M123.42 1.41V2.17999" stroke="black" stroke-width="0.5" />
    <path d="M123.42 2.17999H119.45" stroke="black" stroke-width="0.5" />
    <path d="M119.45 2.17999V1.41" stroke="black" stroke-width="0.5" />
    <path d="M176.45 68.98L182.22 63.21" stroke="black" stroke-width="0.5" />
    <path d="M176.72 69.08L182.6 63.21" stroke="black" stroke-width="0.5" />
    <path
      d="M176.45 68.98C178.034 70.4074 180.088 71.2012 182.22 71.21"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M182.6 63.21H182.22" stroke="black" stroke-width="0.5" />
    <path d="M176.45 75.96L182.22 81.73" stroke="black" stroke-width="0.5" />
    <path d="M176.72 75.86L182.6 81.73" stroke="black" stroke-width="0.5" />
    <path
      d="M182.22 73.73C180.088 73.7388 178.034 74.5326 176.45 75.96"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M182.6 81.73H182.22" stroke="black" stroke-width="0.5" />
    <path d="M166.72 14.51L166.55 14.92" stroke="black" stroke-width="0.5" />
    <path d="M166.55 14.92L166.14 15.09" stroke="black" stroke-width="0.5" />
    <path d="M166.14 15.09L165.73 14.92" stroke="black" stroke-width="0.5" />
    <path d="M165.73 14.92L165.56 14.51" stroke="black" stroke-width="0.5" />
    <path d="M165.56 14.51L165.73 14.1" stroke="black" stroke-width="0.5" />
    <path d="M165.73 14.1L166.14 13.93" stroke="black" stroke-width="0.5" />
    <path d="M166.14 13.93L166.55 14.1" stroke="black" stroke-width="0.5" />
    <path d="M166.55 14.1L166.72 14.51" stroke="black" stroke-width="0.5" />
    <path d="M166.72 15.81L166.55 16.22" stroke="black" stroke-width="0.5" />
    <path d="M166.55 16.22L166.14 16.39" stroke="black" stroke-width="0.5" />
    <path d="M166.14 16.39L165.73 16.22" stroke="black" stroke-width="0.5" />
    <path d="M165.73 16.22L165.56 15.81" stroke="black" stroke-width="0.5" />
    <path d="M165.56 15.81L165.73 15.4" stroke="black" stroke-width="0.5" />
    <path d="M165.73 15.4L166.14 15.23" stroke="black" stroke-width="0.5" />
    <path d="M166.14 15.23L166.55 15.4" stroke="black" stroke-width="0.5" />
    <path d="M166.55 15.4L166.72 15.81" stroke="black" stroke-width="0.5" />
    <path d="M166.72 17.12L166.55 17.53" stroke="black" stroke-width="0.5" />
    <path d="M166.55 17.53L166.14 17.7" stroke="black" stroke-width="0.5" />
    <path d="M166.14 17.7L165.73 17.53" stroke="black" stroke-width="0.5" />
    <path d="M165.73 17.53L165.56 17.12" stroke="black" stroke-width="0.5" />
    <path d="M165.56 17.12L165.73 16.71" stroke="black" stroke-width="0.5" />
    <path d="M165.73 16.71L166.14 16.54" stroke="black" stroke-width="0.5" />
    <path d="M166.14 16.54L166.55 16.71" stroke="black" stroke-width="0.5" />
    <path d="M166.55 16.71L166.72 17.12" stroke="black" stroke-width="0.5" />
    <path d="M162.3 23.91H163.29" stroke="black" stroke-width="0.5" />
    <path d="M163.29 23.91V28.19" stroke="black" stroke-width="0.5" />
    <path d="M163.29 28.19H162.3" stroke="black" stroke-width="0.5" />
    <path d="M162.3 28.19V23.91" stroke="black" stroke-width="0.5" />
    <path d="M21.22 11.48L21.03 9.60001" stroke="black" stroke-width="0.5" />
    <path d="M21.03 9.60001L20.48 7.8" stroke="black" stroke-width="0.5" />
    <path d="M20.48 7.8L19.59 6.14" stroke="black" stroke-width="0.5" />
    <path d="M19.59 6.14L18.4 4.67999" stroke="black" stroke-width="0.5" />
    <path d="M18.4 4.67999L16.94 3.49001" stroke="black" stroke-width="0.5" />
    <path d="M16.94 3.49001L15.28 2.60001" stroke="black" stroke-width="0.5" />
    <path d="M15.28 2.60001L13.48 2.06" stroke="black" stroke-width="0.5" />
    <path d="M13.48 2.06L11.61 1.87" stroke="black" stroke-width="0.5" />
    <path d="M11.61 11.48H12.08" stroke="black" stroke-width="0.5" />
    <path d="M12.08 11.48L12.07 1.88" stroke="black" stroke-width="0.5" />
    <path d="M11.61 1.87V11.48" stroke="black" stroke-width="0.5" />
    <path d="M81.72 11.48L81.9 9.60001" stroke="black" stroke-width="0.5" />
    <path d="M81.9 9.60001L82.45 7.8" stroke="black" stroke-width="0.5" />
    <path d="M82.45 7.8L83.34 6.14" stroke="black" stroke-width="0.5" />
    <path d="M83.34 6.14L84.53 4.67999" stroke="black" stroke-width="0.5" />
    <path d="M84.53 4.67999L85.99 3.49001" stroke="black" stroke-width="0.5" />
    <path d="M85.99 3.49001L87.65 2.60001" stroke="black" stroke-width="0.5" />
    <path d="M87.65 2.60001L89.45 2.06" stroke="black" stroke-width="0.5" />
    <path d="M89.45 2.06L91.33 1.87" stroke="black" stroke-width="0.5" />
    <path d="M91.32 11.48H90.86" stroke="black" stroke-width="0.5" />
    <path d="M90.86 11.48V1.88" stroke="black" stroke-width="0.5" />
    <path d="M91.33 1.87L91.32 11.48" stroke="black" stroke-width="0.5" />
    <path d="M99.89 29.01L97.67 29.2" stroke="black" stroke-width="0.5" />
    <path d="M97.67 29.2L95.51 29.78" stroke="black" stroke-width="0.5" />
    <path d="M95.51 29.78L93.49 30.73" stroke="black" stroke-width="0.5" />
    <path d="M93.49 30.73L91.66 32.01" stroke="black" stroke-width="0.5" />
    <path d="M91.66 32.01L90.08 33.59" stroke="black" stroke-width="0.5" />
    <path d="M90.08 33.59L88.8 35.42" stroke="black" stroke-width="0.5" />
    <path d="M88.8 35.42L87.86 37.44" stroke="black" stroke-width="0.5" />
    <path d="M87.86 37.44L87.28 39.6" stroke="black" stroke-width="0.5" />
    <path d="M87.28 39.6L87.09 41.82" stroke="black" stroke-width="0.5" />
    <path d="M99.9 41.82L99.89 41.19" stroke="black" stroke-width="0.5" />
    <path d="M99.89 41.19L87.1 41.2" stroke="black" stroke-width="0.5" />
    <path d="M87.09 41.82H99.9" stroke="black" stroke-width="0.5" />
    <path d="M126.67 43.37L126.87 41.15" stroke="black" stroke-width="0.5" />
    <path d="M126.87 41.15L127.45 38.99" stroke="black" stroke-width="0.5" />
    <path d="M127.45 38.99L128.39 36.97" stroke="black" stroke-width="0.5" />
    <path d="M128.39 36.97L129.67 35.14" stroke="black" stroke-width="0.5" />
    <path d="M129.67 35.14L131.25 33.56" stroke="black" stroke-width="0.5" />
    <path d="M131.25 33.56L133.08 32.28" stroke="black" stroke-width="0.5" />
    <path d="M133.08 32.28L135.1 31.34" stroke="black" stroke-width="0.5" />
    <path d="M135.1 31.34L137.26 30.76" stroke="black" stroke-width="0.5" />
    <path d="M137.26 30.76L139.49 30.57" stroke="black" stroke-width="0.5" />
    <path d="M139.48 43.38H138.86" stroke="black" stroke-width="0.5" />
    <path d="M138.86 43.38V30.58" stroke="black" stroke-width="0.5" />
    <path d="M139.49 30.57L139.48 43.38" stroke="black" stroke-width="0.5" />
    <path d="M152.36 43.37L152.55 41.5" stroke="black" stroke-width="0.5" />
    <path d="M152.55 41.5L153.1 39.7" stroke="black" stroke-width="0.5" />
    <path d="M153.1 39.7L153.98 38.04" stroke="black" stroke-width="0.5" />
    <path d="M153.98 38.04L155.18 36.58" stroke="black" stroke-width="0.5" />
    <path d="M155.18 36.58L156.64 35.39" stroke="black" stroke-width="0.5" />
    <path d="M156.64 35.39L158.3 34.5" stroke="black" stroke-width="0.5" />
    <path d="M158.3 34.5L160.1 33.95" stroke="black" stroke-width="0.5" />
    <path d="M160.1 33.95L161.97 33.77" stroke="black" stroke-width="0.5" />
    <path d="M161.97 43.38H161.5" stroke="black" stroke-width="0.5" />
    <path d="M161.5 43.38L161.51 33.78" stroke="black" stroke-width="0.5" />
    <path d="M161.97 33.77V43.38" stroke="black" stroke-width="0.5" />
    <path d="M166.17 1.41H166.64" stroke="black" stroke-width="0.5" />
    <path d="M201.9 43.37L202.08 41.5" stroke="black" stroke-width="0.5" />
    <path d="M202.08 41.5L202.63 39.7" stroke="black" stroke-width="0.5" />
    <path d="M202.63 39.7L203.52 38.04" stroke="black" stroke-width="0.5" />
    <path d="M203.52 38.04L204.72 36.58" stroke="black" stroke-width="0.5" />
    <path d="M204.72 36.58L206.17 35.39" stroke="black" stroke-width="0.5" />
    <path d="M206.17 35.39L207.83 34.5" stroke="black" stroke-width="0.5" />
    <path d="M207.83 34.5L209.64 33.95" stroke="black" stroke-width="0.5" />
    <path d="M209.64 33.95L211.51 33.77" stroke="black" stroke-width="0.5" />
    <path d="M211.51 43.38H211.04" stroke="black" stroke-width="0.5" />
    <path d="M211.04 43.38V33.78" stroke="black" stroke-width="0.5" />
    <path d="M211.51 33.77V43.38" stroke="black" stroke-width="0.5" />
  </svg>
</template>
