<template>
  <svg
    width="287"
    height="117"
    viewBox="0 0 287 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.46002 2.26999V110.1" stroke="black" stroke-width="0.5" />
    <path d="M0.419983 0.23999V111.16" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 2.26999H203.28" stroke="black" stroke-width="0.5" />
    <path d="M0.419983 0.23999H214.49" stroke="black" stroke-width="0.5" />
    <path d="M15.84 2.26999V0.23999" stroke="black" stroke-width="0.5" />
    <path d="M45.19 1.07001H44.66" stroke="black" stroke-width="0.5" />
    <path d="M44.66 1.66V2.27" stroke="black" stroke-width="0.5" />
    <path d="M44.66 2.26999H45.19" stroke="black" stroke-width="0.5" />
    <path d="M45.19 2.27V1.07001" stroke="black" stroke-width="0.5" />
    <path d="M15.84 2.26999H16.37" stroke="black" stroke-width="0.5" />
    <path d="M16.37 2.27V1.66" stroke="black" stroke-width="0.5" />
    <path d="M20.69 2.27001V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M21.24 2.27001V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M16.37 1.07001H15.84" stroke="black" stroke-width="0.5" />
    <path d="M15.84 1.07001V2.27" stroke="black" stroke-width="0.5" />
    <path d="M39.82 1.66V2.27" stroke="black" stroke-width="0.5" />
    <path d="M39.82 2.26999H40.35" stroke="black" stroke-width="0.5" />
    <path d="M40.35 2.27V1.66" stroke="black" stroke-width="0.5" />
    <path d="M40.35 1.07001H39.82" stroke="black" stroke-width="0.5" />
    <path d="M44.66 2.26999H40.35" stroke="black" stroke-width="0.5" />
    <path d="M39.82 2.26999H21.24" stroke="black" stroke-width="0.5" />
    <path d="M20.69 2.26999H16.37" stroke="black" stroke-width="0.5" />
    <path d="M39.82 1.07001H21.24" stroke="black" stroke-width="0.5" />
    <path d="M20.69 1.07001H16.37" stroke="black" stroke-width="0.5" />
    <path d="M44.66 1.07001H40.35" stroke="black" stroke-width="0.5" />
    <path d="M16.37 1.39001H44.66" stroke="black" stroke-width="0.5" />
    <path d="M44.66 1.66H16.37" stroke="black" stroke-width="0.5" />
    <path d="M44.66 1.66002V1.39001" stroke="black" stroke-width="0.5" />
    <path
      d="M16.37 1.66002H44.66L16.37 1.39001V1.66002Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M44.66 1.66002V1.39001H16.37L44.66 1.66002Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M16.37 1.66002V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M20.69 1.07001H21.24" stroke="black" stroke-width="0.5" />
    <path d="M20.69 2.26999H21.24" stroke="black" stroke-width="0.5" />
    <path d="M96.43 1.07001H95.9" stroke="black" stroke-width="0.5" />
    <path d="M95.9 1.66V2.27" stroke="black" stroke-width="0.5" />
    <path d="M95.9 2.26999H96.43" stroke="black" stroke-width="0.5" />
    <path d="M96.43 2.27V1.07001" stroke="black" stroke-width="0.5" />
    <path d="M67.08 2.26999H67.61" stroke="black" stroke-width="0.5" />
    <path d="M67.61 2.27V1.66" stroke="black" stroke-width="0.5" />
    <path d="M71.92 2.27001V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M72.48 2.27001V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M67.61 1.07001H67.08" stroke="black" stroke-width="0.5" />
    <path d="M67.08 1.07001V2.27" stroke="black" stroke-width="0.5" />
    <path d="M91.05 1.66V2.27" stroke="black" stroke-width="0.5" />
    <path d="M91.05 2.26999H91.59" stroke="black" stroke-width="0.5" />
    <path d="M91.59 2.27V1.66" stroke="black" stroke-width="0.5" />
    <path d="M91.59 1.07001H91.05" stroke="black" stroke-width="0.5" />
    <path d="M95.9 2.26999H91.59" stroke="black" stroke-width="0.5" />
    <path d="M91.05 2.26999H72.48" stroke="black" stroke-width="0.5" />
    <path d="M71.92 2.26999H67.61" stroke="black" stroke-width="0.5" />
    <path d="M91.05 1.07001H72.48" stroke="black" stroke-width="0.5" />
    <path d="M71.92 1.07001H67.61" stroke="black" stroke-width="0.5" />
    <path d="M95.9 1.07001H91.59" stroke="black" stroke-width="0.5" />
    <path d="M67.61 1.39001H95.9" stroke="black" stroke-width="0.5" />
    <path d="M95.9 1.66H67.61" stroke="black" stroke-width="0.5" />
    <path d="M95.9 1.66002V1.39001" stroke="black" stroke-width="0.5" />
    <path
      d="M67.61 1.66002H95.9L67.61 1.39001V1.66002Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M95.9 1.66002V1.39001H67.61L95.9 1.66002Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M67.61 1.66002V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M71.92 1.07001H72.48" stroke="black" stroke-width="0.5" />
    <path d="M71.92 2.26999H72.48" stroke="black" stroke-width="0.5" />
    <path d="M147.67 1.07001H147.14" stroke="black" stroke-width="0.5" />
    <path d="M147.14 1.66V2.27" stroke="black" stroke-width="0.5" />
    <path d="M147.14 2.26999H147.67" stroke="black" stroke-width="0.5" />
    <path d="M147.67 2.27V1.07001" stroke="black" stroke-width="0.5" />
    <path d="M118.32 2.26999H118.85" stroke="black" stroke-width="0.5" />
    <path d="M118.85 2.27V1.66" stroke="black" stroke-width="0.5" />
    <path d="M123.16 2.27001V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M123.72 2.27001V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M118.85 1.07001H118.32" stroke="black" stroke-width="0.5" />
    <path d="M118.32 1.07001V2.27" stroke="black" stroke-width="0.5" />
    <path d="M142.29 1.66V2.27" stroke="black" stroke-width="0.5" />
    <path d="M142.29 2.26999H142.83" stroke="black" stroke-width="0.5" />
    <path d="M142.83 2.27V1.66" stroke="black" stroke-width="0.5" />
    <path d="M142.83 1.07001H142.29" stroke="black" stroke-width="0.5" />
    <path d="M147.14 2.26999H142.83" stroke="black" stroke-width="0.5" />
    <path d="M142.29 2.26999H123.72" stroke="black" stroke-width="0.5" />
    <path d="M123.16 2.26999H118.85" stroke="black" stroke-width="0.5" />
    <path d="M142.29 1.07001H123.72" stroke="black" stroke-width="0.5" />
    <path d="M123.16 1.07001H118.85" stroke="black" stroke-width="0.5" />
    <path d="M147.14 1.07001H142.83" stroke="black" stroke-width="0.5" />
    <path d="M118.85 1.39001H147.14" stroke="black" stroke-width="0.5" />
    <path d="M147.14 1.66H118.85" stroke="black" stroke-width="0.5" />
    <path d="M147.14 1.66002V1.39001" stroke="black" stroke-width="0.5" />
    <path
      d="M118.85 1.66002H147.14L118.85 1.39001V1.66002Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M147.14 1.66002V1.39001H118.85L147.14 1.66002Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M118.85 1.66002V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M123.16 1.07001H123.72" stroke="black" stroke-width="0.5" />
    <path d="M123.16 2.26999H123.72" stroke="black" stroke-width="0.5" />
    <path d="M198.91 1.07001H198.37" stroke="black" stroke-width="0.5" />
    <path d="M198.37 1.66V2.27" stroke="black" stroke-width="0.5" />
    <path d="M198.37 2.26999H198.91" stroke="black" stroke-width="0.5" />
    <path d="M198.91 2.27V1.07001" stroke="black" stroke-width="0.5" />
    <path d="M169.55 2.26999H170.09" stroke="black" stroke-width="0.5" />
    <path d="M170.09 2.27V1.66" stroke="black" stroke-width="0.5" />
    <path d="M174.4 2.27001V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M174.95 2.27001V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M170.09 1.07001H169.55" stroke="black" stroke-width="0.5" />
    <path d="M169.55 1.07001V2.27" stroke="black" stroke-width="0.5" />
    <path d="M193.53 1.66V2.27" stroke="black" stroke-width="0.5" />
    <path d="M193.53 2.26999H194.06" stroke="black" stroke-width="0.5" />
    <path d="M194.06 2.27V1.66" stroke="black" stroke-width="0.5" />
    <path d="M194.06 1.07001H193.53" stroke="black" stroke-width="0.5" />
    <path d="M198.37 2.26999H194.06" stroke="black" stroke-width="0.5" />
    <path d="M193.53 2.26999H174.95" stroke="black" stroke-width="0.5" />
    <path d="M174.4 2.26999H170.09" stroke="black" stroke-width="0.5" />
    <path d="M193.53 1.07001H174.95" stroke="black" stroke-width="0.5" />
    <path d="M174.4 1.07001H170.09" stroke="black" stroke-width="0.5" />
    <path d="M198.37 1.07001H194.06" stroke="black" stroke-width="0.5" />
    <path d="M170.09 1.39001H198.37" stroke="black" stroke-width="0.5" />
    <path d="M198.37 1.66H170.09" stroke="black" stroke-width="0.5" />
    <path d="M198.37 1.66002V1.39001" stroke="black" stroke-width="0.5" />
    <path
      d="M170.09 1.66002H198.37L170.09 1.39001V1.66002Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M198.37 1.66002V1.39001H170.09L198.37 1.66002Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M170.09 1.66002V1.39001" stroke="black" stroke-width="0.5" />
    <path d="M174.4 1.07001H174.95" stroke="black" stroke-width="0.5" />
    <path d="M174.4 2.26999H174.95" stroke="black" stroke-width="0.5" />
    <path d="M250.14 4.26999H249.61" stroke="black" stroke-width="0.5" />
    <path d="M249.61 4.85999V5.47999" stroke="black" stroke-width="0.5" />
    <path d="M249.61 5.48001H250.14" stroke="black" stroke-width="0.5" />
    <path d="M250.14 5.48V4.26999" stroke="black" stroke-width="0.5" />
    <path d="M220.79 5.48001H221.32" stroke="black" stroke-width="0.5" />
    <path d="M221.32 5.47999V4.85999" stroke="black" stroke-width="0.5" />
    <path d="M225.63 5.48V4.59" stroke="black" stroke-width="0.5" />
    <path d="M226.19 5.48V4.59" stroke="black" stroke-width="0.5" />
    <path d="M221.32 4.26999H220.79" stroke="black" stroke-width="0.5" />
    <path d="M220.79 4.26999V5.48" stroke="black" stroke-width="0.5" />
    <path d="M244.77 4.85999V5.47999" stroke="black" stroke-width="0.5" />
    <path d="M244.77 5.48001H245.3" stroke="black" stroke-width="0.5" />
    <path d="M245.3 5.47999V4.85999" stroke="black" stroke-width="0.5" />
    <path d="M245.3 4.26999H244.77" stroke="black" stroke-width="0.5" />
    <path d="M249.61 5.48001H245.3" stroke="black" stroke-width="0.5" />
    <path d="M244.77 5.48001H226.19" stroke="black" stroke-width="0.5" />
    <path d="M225.63 5.48001H221.32" stroke="black" stroke-width="0.5" />
    <path d="M244.77 4.26999H226.19" stroke="black" stroke-width="0.5" />
    <path d="M225.63 4.26999H221.32" stroke="black" stroke-width="0.5" />
    <path d="M249.61 4.26999H245.3" stroke="black" stroke-width="0.5" />
    <path d="M221.32 4.59H249.61" stroke="black" stroke-width="0.5" />
    <path d="M249.61 4.85999H221.32" stroke="black" stroke-width="0.5" />
    <path d="M249.61 4.86V4.59" stroke="black" stroke-width="0.5" />
    <path
      d="M221.32 4.86H249.61L221.32 4.59V4.86Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M249.61 4.86V4.59H221.32L249.61 4.86Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M221.32 4.86V4.59" stroke="black" stroke-width="0.5" />
    <path d="M225.63 4.26999H226.19" stroke="black" stroke-width="0.5" />
    <path d="M225.63 5.48001H226.19" stroke="black" stroke-width="0.5" />
    <path d="M272.03 5.48001H272.56" stroke="black" stroke-width="0.5" />
    <path d="M272.56 5.47999V4.85999" stroke="black" stroke-width="0.5" />
    <path d="M276.87 5.48V4.59" stroke="black" stroke-width="0.5" />
    <path d="M277.43 5.48V4.59" stroke="black" stroke-width="0.5" />
    <path d="M272.56 4.26999H272.03" stroke="black" stroke-width="0.5" />
    <path d="M272.03 4.26999V5.48" stroke="black" stroke-width="0.5" />
    <path d="M276.87 5.48001H272.56" stroke="black" stroke-width="0.5" />
    <path d="M276.87 4.26999H272.56" stroke="black" stroke-width="0.5" />
    <path d="M272.56 4.86V4.59" stroke="black" stroke-width="0.5" />
    <path d="M276.87 4.26999H277.43" stroke="black" stroke-width="0.5" />
    <path d="M276.87 5.48001H277.43" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 44.14V43.61" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 43.61H2.46003" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 43.61V44.14" stroke="black" stroke-width="0.5" />
    <path d="M2.46001 44.14H1.26001" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 14.79V15.32" stroke="black" stroke-width="0.5" />
    <path d="M2.46003 15.32H1.84003" stroke="black" stroke-width="0.5" />
    <path d="M2.46001 19.63H1.57001" stroke="black" stroke-width="0.5" />
    <path d="M2.46001 20.19H1.57001" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 15.32V14.79" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 14.79H2.46001" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 38.77H2.46003" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 38.77V39.3" stroke="black" stroke-width="0.5" />
    <path d="M2.46003 39.3H1.84003" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 39.3V38.77" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 43.61V39.3" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 38.77V20.19" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 19.63V15.32" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 38.77V20.19" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 19.63V15.32" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 43.61V39.3" stroke="black" stroke-width="0.5" />
    <path d="M1.57001 15.32V43.61" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 43.61V15.32" stroke="black" stroke-width="0.5" />
    <path d="M1.84001 43.61H1.57001" stroke="black" stroke-width="0.5" />
    <path
      d="M1.84001 15.32H1.57001L1.84001 43.61V15.32Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M1.84001 43.61L1.57001 15.32V43.61H1.84001Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M1.84001 15.32H1.57001" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 19.63V20.19" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 19.63V20.19" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 95.38V94.85" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 94.85H2.46003" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 94.85V95.38" stroke="black" stroke-width="0.5" />
    <path d="M2.46001 95.38H1.26001" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 66.03V66.56" stroke="black" stroke-width="0.5" />
    <path d="M2.46003 66.56H1.84003" stroke="black" stroke-width="0.5" />
    <path d="M2.46001 70.87H1.57001" stroke="black" stroke-width="0.5" />
    <path d="M2.46001 71.43H1.57001" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 66.56V66.03" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 66.03H2.46001" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 90H2.46003" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 90V90.54" stroke="black" stroke-width="0.5" />
    <path d="M2.46003 90.54H1.84003" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 90.54V90" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 94.85V90.54" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 90V71.43" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 70.87V66.56" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 90V71.43" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 70.87V66.56" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 94.85V90.54" stroke="black" stroke-width="0.5" />
    <path d="M1.57001 66.56V94.85" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 94.85V66.56" stroke="black" stroke-width="0.5" />
    <path d="M1.84001 94.85H1.57001" stroke="black" stroke-width="0.5" />
    <path
      d="M1.84001 66.56H1.57001L1.84001 94.85V66.56Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M1.84001 94.85L1.57001 66.56V94.85H1.84001Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M1.84001 66.56H1.57001" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 70.87V71.43" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 70.87V71.43" stroke="black" stroke-width="0.5" />
    <path d="M15.84 2.26999H45.19" stroke="black" stroke-width="0.5" />
    <path d="M45.19 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M45.19 4.41H15.84" stroke="black" stroke-width="0.5" />
    <path d="M15.84 4.41V2.26999" stroke="black" stroke-width="0.5" />
    <path d="M39.38 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M40.62 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M67.08 2.26999H96.43" stroke="black" stroke-width="0.5" />
    <path d="M96.43 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M96.43 4.41H67.08" stroke="black" stroke-width="0.5" />
    <path d="M67.08 4.41V2.26999" stroke="black" stroke-width="0.5" />
    <path d="M72.38 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M71.05 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M67.08 3.34H71.05" stroke="black" stroke-width="0.5" />
    <path d="M72.38 3.34H75.53" stroke="black" stroke-width="0.5" />
    <path d="M77.38 3.34H80.2" stroke="black" stroke-width="0.5" />
    <path d="M82.29 3.34H84.62" stroke="black" stroke-width="0.5" />
    <path d="M86.91 3.34H89.22" stroke="black" stroke-width="0.5" />
    <path d="M91.67 3.34H93.83" stroke="black" stroke-width="0.5" />
    <path d="M95.22 3.34H96.43" stroke="black" stroke-width="0.5" />
    <path d="M15.84 3.34H20.32" stroke="black" stroke-width="0.5" />
    <path d="M21.65 3.34H24.29" stroke="black" stroke-width="0.5" />
    <path d="M26.14 3.34H28.96" stroke="black" stroke-width="0.5" />
    <path d="M31.06 3.34H33.38" stroke="black" stroke-width="0.5" />
    <path d="M35.67 3.34H37.98" stroke="black" stroke-width="0.5" />
    <path d="M40.62 3.34H42.59" stroke="black" stroke-width="0.5" />
    <path d="M43.99 3.34H45.19" stroke="black" stroke-width="0.5" />
    <path d="M118.32 2.26999H147.67" stroke="black" stroke-width="0.5" />
    <path d="M147.67 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M147.67 4.41H118.32" stroke="black" stroke-width="0.5" />
    <path d="M118.32 4.41V2.26999" stroke="black" stroke-width="0.5" />
    <path d="M134.83 46.55V5.75" stroke="black" stroke-width="0.5" />
    <path d="M134.83 5.75H132.43" stroke="black" stroke-width="0.5" />
    <path d="M132.43 5.75V4.41" stroke="black" stroke-width="0.5" />
    <path d="M133.68 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M132.43 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M118.32 3.34H122.79" stroke="black" stroke-width="0.5" />
    <path d="M124.13 3.34H126.77" stroke="black" stroke-width="0.5" />
    <path d="M128.62 3.34H131.44" stroke="black" stroke-width="0.5" />
    <path d="M133.53 3.34H135.85" stroke="black" stroke-width="0.5" />
    <path d="M138.15 3.34H140.46" stroke="black" stroke-width="0.5" />
    <path d="M142.91 3.34H145.07" stroke="black" stroke-width="0.5" />
    <path d="M146.46 3.34H147.67" stroke="black" stroke-width="0.5" />
    <path d="M279 7.62H272.03" stroke="black" stroke-width="0.5" />
    <path d="M272.03 7.62001V5.48001" stroke="black" stroke-width="0.5" />
    <path d="M277.84 5.48001V7.62001" stroke="black" stroke-width="0.5" />
    <path d="M276.51 5.48001V7.62001" stroke="black" stroke-width="0.5" />
    <path d="M272.03 6.54999H276.51" stroke="black" stroke-width="0.5" />
    <path d="M220.79 5.48001H250.14" stroke="black" stroke-width="0.5" />
    <path d="M250.14 5.48001V7.62001" stroke="black" stroke-width="0.5" />
    <path d="M250.14 7.62H220.79" stroke="black" stroke-width="0.5" />
    <path d="M220.79 7.62001V5.48001" stroke="black" stroke-width="0.5" />
    <path d="M244.33 5.48001V7.62001" stroke="black" stroke-width="0.5" />
    <path d="M245.67 5.48001V7.62001" stroke="black" stroke-width="0.5" />
    <path d="M220.79 6.54999H225.27" stroke="black" stroke-width="0.5" />
    <path d="M226.6 6.54999H229.24" stroke="black" stroke-width="0.5" />
    <path d="M231.09 6.54999H233.91" stroke="black" stroke-width="0.5" />
    <path d="M236 6.54999H238.33" stroke="black" stroke-width="0.5" />
    <path d="M240.62 6.54999H242.93" stroke="black" stroke-width="0.5" />
    <path d="M245.67 6.54999H247.54" stroke="black" stroke-width="0.5" />
    <path d="M248.94 6.54999H250.14" stroke="black" stroke-width="0.5" />
    <path d="M169.55 2.26999H198.91" stroke="black" stroke-width="0.5" />
    <path d="M198.91 2.26999V4.41" stroke="black" stroke-width="0.5" />
    <path d="M198.91 4.41H169.55" stroke="black" stroke-width="0.5" />
    <path d="M169.55 4.41V2.26999" stroke="black" stroke-width="0.5" />
    <path d="M169.55 3.34H174.03" stroke="black" stroke-width="0.5" />
    <path d="M175.37 3.34H178" stroke="black" stroke-width="0.5" />
    <path d="M179.86 3.34H182.67" stroke="black" stroke-width="0.5" />
    <path d="M184.77 3.34H187.09" stroke="black" stroke-width="0.5" />
    <path d="M189.38 3.34H191.69" stroke="black" stroke-width="0.5" />
    <path d="M194.15 3.34H196.3" stroke="black" stroke-width="0.5" />
    <path d="M197.7 3.34H198.91" stroke="black" stroke-width="0.5" />
    <path d="M45.19 2.26999V0.23999" stroke="black" stroke-width="0.5" />
    <path d="M67.08 2.26999V0.23999" stroke="black" stroke-width="0.5" />
    <path d="M96.43 2.26999V0.23999" stroke="black" stroke-width="0.5" />
    <path d="M118.32 2.26999V0.23999" stroke="black" stroke-width="0.5" />
    <path d="M147.67 2.26999V0.23999" stroke="black" stroke-width="0.5" />
    <path d="M169.55 2.26999V0.23999" stroke="black" stroke-width="0.5" />
    <path d="M198.91 2.26999V0.23999" stroke="black" stroke-width="0.5" />
    <path d="M220.79 5.48V3.44" stroke="black" stroke-width="0.5" />
    <path d="M203.28 5.48001H277.77" stroke="black" stroke-width="0.5" />
    <path d="M214.49 3.44L272 3.5" stroke="black" stroke-width="0.5" />
    <path d="M203.28 5.48V2.26999" stroke="black" stroke-width="0.5" />
    <path d="M214.49 3.43999V0.23999" stroke="black" stroke-width="0.5" />
    <path d="M250.14 5.48V3.44" stroke="black" stroke-width="0.5" />
    <path d="M272.03 5.48V3.44" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 42.81V14.79" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 14.79H4.60002" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 14.79V42.81" stroke="black" stroke-width="0.5" />
    <path d="M4.60002 42.81H2.46002" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 42.81V39.66" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 38.33V35.69" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 33.84V31.02" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 28.93V26.6" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 24.31V22" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 19.55V17.39" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 16V14.79" stroke="black" stroke-width="0.5" />
    <path d="M37.96 44.14H36.55" stroke="black" stroke-width="0.5" />
    <path d="M26.94 44.14H23.55" stroke="black" stroke-width="0.5" />
    <path d="M22.21 44.14H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M22.21 70.3H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M20.61 55.35H19.28" stroke="black" stroke-width="0.5" />
    <path d="M18.44 55.35H16.77" stroke="black" stroke-width="0.5" />
    <path d="M15.94 55.35H14.27" stroke="black" stroke-width="0.5" />
    <path d="M13.44 55.35H11.77" stroke="black" stroke-width="0.5" />
    <path d="M10.94 55.35H9.27002" stroke="black" stroke-width="0.5" />
    <path d="M8.43002 55.35H6.77002" stroke="black" stroke-width="0.5" />
    <path d="M5.92998 55.35H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M37.49 71.63H36.42" stroke="black" stroke-width="0.5" />
    <path d="M26.81 71.63H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M37.96 42.81H36.55" stroke="black" stroke-width="0.5" />
    <path d="M26.94 42.81H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 95.38V66.03" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 66.03H4.60002" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 66.03V95.38" stroke="black" stroke-width="0.5" />
    <path d="M4.60002 95.38H2.46002" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 71.84H4.60002" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 70.51H4.60002" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 95.38V90.9" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 89.57V86.93" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 85.08V82.26" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 80.17V77.84" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 75.55V73.24" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 70.51V68.63" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 67.23V66.03" stroke="black" stroke-width="0.5" />
    <path d="M37.96 4.41V42.81" stroke="black" stroke-width="0.5" />
    <path d="M37.96 44.14V47.88" stroke="black" stroke-width="0.5" />
    <path d="M39.29 5.75V46.55" stroke="black" stroke-width="0.5" />
    <path d="M40.62 4.41V5.75" stroke="black" stroke-width="0.5" />
    <path d="M40.62 5.75H39.29" stroke="black" stroke-width="0.5" />
    <path d="M37.96 47.88H53.8" stroke="black" stroke-width="0.5" />
    <path d="M39.29 46.55H53.8" stroke="black" stroke-width="0.5" />
    <path d="M69.81 46.55H71.05" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 44.14V42.81" stroke="black" stroke-width="0.5" />
    <path d="M36.55 42.81V44.14" stroke="black" stroke-width="0.5" />
    <path d="M37.08 42.81V44.14" stroke="black" stroke-width="0.5" />
    <path d="M26.94 42.81V44.14" stroke="black" stroke-width="0.5" />
    <path d="M26.41 42.81V44.14" stroke="black" stroke-width="0.5" />
    <path d="M71.05 4.41V46.55" stroke="black" stroke-width="0.5" />
    <path d="M72.38 4.41V46.55" stroke="black" stroke-width="0.5" />
    <path d="M69.81 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M70.35 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M53.8 47.88H60.21" stroke="black" stroke-width="0.5" />
    <path d="M53.8 46.55H60.21" stroke="black" stroke-width="0.5" />
    <path d="M60.21 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M59.67 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M54.34 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M53.8 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M54.34 47.21H59.67" stroke="black" stroke-width="0.5" />
    <path d="M69.81 47.88H86.09" stroke="black" stroke-width="0.5" />
    <path d="M72.38 46.55H86.09" stroke="black" stroke-width="0.5" />
    <path d="M102.1 46.55H103.34" stroke="black" stroke-width="0.5" />
    <path d="M103.34 4.41V46.55" stroke="black" stroke-width="0.5" />
    <path d="M104.67 4.41V46.55" stroke="black" stroke-width="0.5" />
    <path d="M102.1 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M102.64 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M86.09 47.88H92.5" stroke="black" stroke-width="0.5" />
    <path d="M86.09 46.55H92.5" stroke="black" stroke-width="0.5" />
    <path d="M92.5 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M91.96 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M86.63 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M86.09 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M86.63 47.21H91.96" stroke="black" stroke-width="0.5" />
    <path d="M136.86 47.88H121.92" stroke="black" stroke-width="0.5" />
    <path d="M134.83 46.55H121.92" stroke="black" stroke-width="0.5" />
    <path d="M105.9 46.55H104.67" stroke="black" stroke-width="0.5" />
    <path d="M105.9 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M105.37 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M121.92 47.88H115.51" stroke="black" stroke-width="0.5" />
    <path d="M121.92 46.55H115.51" stroke="black" stroke-width="0.5" />
    <path d="M115.51 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M116.05 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M121.38 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M121.92 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M121.38 47.21H116.05" stroke="black" stroke-width="0.5" />
    <path d="M102.1 47.88H105.9" stroke="black" stroke-width="0.5" />
    <path d="M136.16 4.41V46.55" stroke="black" stroke-width="0.5" />
    <path d="M185.94 47.88H153.41" stroke="black" stroke-width="0.5" />
    <path d="M182.58 46.55H169.55" stroke="black" stroke-width="0.5" />
    <path d="M168.22 46.55H153.41" stroke="black" stroke-width="0.5" />
    <path d="M137.39 46.55H136.16" stroke="black" stroke-width="0.5" />
    <path d="M137.39 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M136.86 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M153.41 47.88H147" stroke="black" stroke-width="0.5" />
    <path d="M153.41 46.55H147" stroke="black" stroke-width="0.5" />
    <path d="M147 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M147.53 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M152.87 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M153.41 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M152.87 47.21H147.53" stroke="black" stroke-width="0.5" />
    <path d="M133.59 47.88H137.39" stroke="black" stroke-width="0.5" />
    <path d="M168.22 47.88V4.41" stroke="black" stroke-width="0.5" />
    <path d="M169.55 4.41V47.88" stroke="black" stroke-width="0.5" />
    <path d="M205.01 46.55V7.62" stroke="black" stroke-width="0.5" />
    <path d="M206.35 7.62V46.55" stroke="black" stroke-width="0.5" />
    <path d="M241.31 46.55V7.62" stroke="black" stroke-width="0.5" />
    <path d="M242.64 8.95001V46.55" stroke="black" stroke-width="0.5" />
    <path d="M277.6 46.55V7.62" stroke="black" stroke-width="0.5" />
    <path d="M278.93 7.62V46.55" stroke="black" stroke-width="0.5" />
    <path d="M171.49 47.88H187.77" stroke="black" stroke-width="0.5" />
    <path d="M174.06 46.55H187.77" stroke="black" stroke-width="0.5" />
    <path d="M203.78 46.55H205.01" stroke="black" stroke-width="0.5" />
    <path d="M203.78 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M204.31 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M187.77 47.88H194.17" stroke="black" stroke-width="0.5" />
    <path d="M187.77 46.55H194.17" stroke="black" stroke-width="0.5" />
    <path d="M194.17 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M193.64 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M188.3 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M187.77 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M188.3 47.21H193.64" stroke="black" stroke-width="0.5" />
    <path d="M203.78 47.88H207.58" stroke="black" stroke-width="0.5" />
    <path d="M207.78 47.88H224.06" stroke="black" stroke-width="0.5" />
    <path d="M206.35 46.55H224.59" stroke="black" stroke-width="0.5" />
    <path d="M240.07 46.55H241.31" stroke="black" stroke-width="0.5" />
    <path d="M240.07 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M240.61 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M224.06 47.88H230.47" stroke="black" stroke-width="0.5" />
    <path d="M224.06 46.55H230.47" stroke="black" stroke-width="0.5" />
    <path d="M230.47 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M229.93 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M224.59 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M224.06 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M224.59 47.21H229.93" stroke="black" stroke-width="0.5" />
    <path d="M240.07 47.88H243.87" stroke="black" stroke-width="0.5" />
    <path d="M280.17 47.88H259.88" stroke="black" stroke-width="0.5" />
    <path d="M277.6 46.55H259.35" stroke="black" stroke-width="0.5" />
    <path d="M243.87 46.55H242.64" stroke="black" stroke-width="0.5" />
    <path d="M243.87 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M243.34 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M259.88 47.88H253.48" stroke="black" stroke-width="0.5" />
    <path d="M259.88 46.55H253.48" stroke="black" stroke-width="0.5" />
    <path d="M253.48 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M254.01 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M259.35 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M259.88 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M259.35 47.21H254.01" stroke="black" stroke-width="0.5" />
    <path d="M243.87 47.88H240.07" stroke="black" stroke-width="0.5" />
    <path d="M280.17 46.55H278.93" stroke="black" stroke-width="0.5" />
    <path d="M280.17 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M279.63 46.55V47.88" stroke="black" stroke-width="0.5" />
    <path d="M207.95 65.23V110.39" stroke="black" stroke-width="0.5" />
    <path d="M209.28 110.39V65.23" stroke="black" stroke-width="0.5" />
    <path d="M247.71 65.23V110.39" stroke="black" stroke-width="0.5" />
    <path d="M249.04 111.99V73.23" stroke="black" stroke-width="0.5" />
    <path d="M249.04 71.9V63.89" stroke="black" stroke-width="0.5" />
    <path d="M280.8 63.89V71.9" stroke="black" stroke-width="0.5" />
    <path d="M206.71 65.23H207.95" stroke="black" stroke-width="0.5" />
    <path d="M206.71 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M207.25 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M190.7 63.89H197.11" stroke="black" stroke-width="0.5" />
    <path d="M190.7 65.23H197.11" stroke="black" stroke-width="0.5" />
    <path d="M197.11 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M196.57 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M191.24 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M190.7 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M191.24 64.56H196.57" stroke="black" stroke-width="0.5" />
    <path d="M206.71 63.89H230.47" stroke="black" stroke-width="0.5" />
    <path d="M246.48 65.23H247.71" stroke="black" stroke-width="0.5" />
    <path d="M246.48 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M247.01 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M230.47 63.89H236.87" stroke="black" stroke-width="0.5" />
    <path d="M230.47 65.23H236.87" stroke="black" stroke-width="0.5" />
    <path d="M236.87 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M236.34 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M231 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M230.47 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M231 64.56H236.34" stroke="black" stroke-width="0.5" />
    <path d="M250.11 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M249.58 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M266.12 73.23H259.72" stroke="black" stroke-width="0.5" />
    <path d="M266.12 71.9H259.72" stroke="black" stroke-width="0.5" />
    <path d="M259.72 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M260.25 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M265.59 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M266.12 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M265.59 72.56H260.25" stroke="black" stroke-width="0.5" />
    <path d="M249.04 63.89H246.48" stroke="black" stroke-width="0.5" />
    <path d="M283.37 65.23H282.13" stroke="black" stroke-width="0.5" />
    <path d="M283.37 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M282.83 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M266.12 71.9H280.8" stroke="black" stroke-width="0.5" />
    <path d="M266.12 73.23H280.8" stroke="black" stroke-width="0.5" />
    <path d="M280.8 74.03H281.47" stroke="black" stroke-width="0.5" />
    <path d="M209.28 65.23H230.47" stroke="black" stroke-width="0.5" />
    <path d="M192.57 63.89H155.27" stroke="black" stroke-width="0.5" />
    <path d="M149.54 65.23H168.22" stroke="black" stroke-width="0.5" />
    <path d="M169.55 65.23H190.7" stroke="black" stroke-width="0.5" />
    <path d="M168.22 47.88V49.21" stroke="black" stroke-width="0.5" />
    <path d="M168.22 50.05V51.72" stroke="black" stroke-width="0.5" />
    <path d="M168.22 52.55V54.22" stroke="black" stroke-width="0.5" />
    <path d="M168.22 55.05V56.72" stroke="black" stroke-width="0.5" />
    <path d="M168.22 57.55V59.22" stroke="black" stroke-width="0.5" />
    <path d="M168.22 60.06V61.72" stroke="black" stroke-width="0.5" />
    <path d="M168.22 62.56V63.89" stroke="black" stroke-width="0.5" />
    <path d="M169.55 47.88V49.21" stroke="black" stroke-width="0.5" />
    <path d="M169.55 50.05V51.72" stroke="black" stroke-width="0.5" />
    <path d="M169.55 52.55V54.22" stroke="black" stroke-width="0.5" />
    <path d="M169.55 55.05V56.72" stroke="black" stroke-width="0.5" />
    <path d="M169.55 57.55V59.22" stroke="black" stroke-width="0.5" />
    <path d="M169.55 60.06V61.72" stroke="black" stroke-width="0.5" />
    <path d="M169.55 62.56V63.89" stroke="black" stroke-width="0.5" />
    <path d="M168.22 63.89V111.99" stroke="black" stroke-width="0.5" />
    <path d="M169.55 63.89V111.99" stroke="black" stroke-width="0.5" />
    <path d="M283.37 63.89H280.8" stroke="black" stroke-width="0.5" />
    <path d="M250.11 71.9H249.04" stroke="black" stroke-width="0.5" />
    <path d="M250.11 73.23H249.04" stroke="black" stroke-width="0.5" />
    <path d="M280.8 73.23H282.13" stroke="black" stroke-width="0.5" />
    <path d="M282.13 73.23V103.65" stroke="black" stroke-width="0.5" />
    <path d="M282.13 103.65H280.8" stroke="black" stroke-width="0.5" />
    <path d="M280.8 103.65V73.23" stroke="black" stroke-width="0.5" />
    <path d="M281.47 73.23V103.65" stroke="black" stroke-width="0.5" />
    <path d="M280.8 82.83H281.47" stroke="black" stroke-width="0.5" />
    <path d="M280.8 83.63H281.47" stroke="black" stroke-width="0.5" />
    <path d="M280.8 93.15H281.47" stroke="black" stroke-width="0.5" />
    <path d="M280.8 93.95H281.47" stroke="black" stroke-width="0.5" />
    <path d="M280.8 102.85H281.47" stroke="black" stroke-width="0.5" />
    <path d="M282.13 73.23V65.23" stroke="black" stroke-width="0.5" />
    <path d="M282.13 103.65H286" stroke="black" stroke-width="0.5" />
    <path d="M281.65 104.99L286 105" stroke="black" stroke-width="0.5" />
    <path d="M281.65 104.99L274.64 111.99" stroke="black" stroke-width="0.5" />
    <path d="M280.8 103.95L272.76 111.99" stroke="black" stroke-width="0.5" />
    <path d="M280.8 103.65V103.95" stroke="black" stroke-width="0.5" />
    <path d="M285.34 103.65V104.99" stroke="black" stroke-width="0.5" />
    <path d="M285.87 103.65V104.99" stroke="black" stroke-width="0.5" />
    <path d="M154.04 111.99V104.99" stroke="black" stroke-width="0.5" />
    <path d="M155.38 111.99V103.65" stroke="black" stroke-width="0.5" />
    <path d="M70.58 65.23V111.99" stroke="black" stroke-width="0.5" />
    <path d="M71.91 111.99V65.23" stroke="black" stroke-width="0.5" />
    <path d="M101.8 65.23V111.99" stroke="black" stroke-width="0.5" />
    <path d="M103.14 111.99V73.23" stroke="black" stroke-width="0.5" />
    <path d="M103.14 71.9V63.89" stroke="black" stroke-width="0.5" />
    <path d="M134.89 63.89V71.9" stroke="black" stroke-width="0.5" />
    <path d="M69.35 65.23H70.58" stroke="black" stroke-width="0.5" />
    <path d="M69.35 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M69.88 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M53.34 63.89H59.74" stroke="black" stroke-width="0.5" />
    <path d="M53.34 65.23H59.74" stroke="black" stroke-width="0.5" />
    <path d="M59.74 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M59.21 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M53.87 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M53.34 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M53.87 64.56H59.21" stroke="black" stroke-width="0.5" />
    <path d="M69.35 63.89H84.56" stroke="black" stroke-width="0.5" />
    <path d="M100.57 65.23H101.8" stroke="black" stroke-width="0.5" />
    <path d="M100.57 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M101.1 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M84.56 63.89H90.96" stroke="black" stroke-width="0.5" />
    <path d="M84.56 65.23H90.96" stroke="black" stroke-width="0.5" />
    <path d="M90.96 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M90.43 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M85.09 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M84.56 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M85.09 64.56H90.43" stroke="black" stroke-width="0.5" />
    <path d="M104.2 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M103.67 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M120.22 73.23H113.81" stroke="black" stroke-width="0.5" />
    <path d="M120.22 71.9H113.81" stroke="black" stroke-width="0.5" />
    <path d="M113.81 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M114.35 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M119.68 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M120.22 71.9V73.23" stroke="black" stroke-width="0.5" />
    <path d="M119.68 72.56H114.35" stroke="black" stroke-width="0.5" />
    <path d="M103.14 63.89H100.57" stroke="black" stroke-width="0.5" />
    <path d="M137.46 65.23H136.23" stroke="black" stroke-width="0.5" />
    <path d="M137.46 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M136.93 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M147.07 65.23H168.22" stroke="black" stroke-width="0.5" />
    <path d="M147.07 63.89H170.05" stroke="black" stroke-width="0.5" />
    <path d="M120.22 71.9H134.89" stroke="black" stroke-width="0.5" />
    <path d="M120.22 73.23H134.89" stroke="black" stroke-width="0.5" />
    <path d="M134.89 74.03H135.56" stroke="black" stroke-width="0.5" />
    <path d="M69.88 65.23H84.56" stroke="black" stroke-width="0.5" />
    <path d="M53.34 63.89H37.49" stroke="black" stroke-width="0.5" />
    <path d="M38.82 65.23H53.34" stroke="black" stroke-width="0.5" />
    <path d="M137.46 63.89H134.89" stroke="black" stroke-width="0.5" />
    <path d="M147.07 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M147.6 65.23V63.89" stroke="black" stroke-width="0.5" />
    <path d="M104.2 71.9H103.14" stroke="black" stroke-width="0.5" />
    <path d="M104.2 73.23H103.14" stroke="black" stroke-width="0.5" />
    <path d="M134.89 73.23H136.23" stroke="black" stroke-width="0.5" />
    <path d="M136.23 73.23V103.65" stroke="black" stroke-width="0.5" />
    <path d="M136.23 103.65H134.89" stroke="black" stroke-width="0.5" />
    <path d="M134.89 103.65V73.23" stroke="black" stroke-width="0.5" />
    <path d="M135.56 73.23V103.65" stroke="black" stroke-width="0.5" />
    <path d="M134.89 82.83H135.56" stroke="black" stroke-width="0.5" />
    <path d="M134.89 83.63H135.56" stroke="black" stroke-width="0.5" />
    <path d="M134.89 93.15H135.56" stroke="black" stroke-width="0.5" />
    <path d="M134.89 93.95H135.56" stroke="black" stroke-width="0.5" />
    <path d="M134.89 102.85H135.56" stroke="black" stroke-width="0.5" />
    <path d="M136.23 73.23V65.23" stroke="black" stroke-width="0.5" />
    <path d="M134.89 103.65H136.76" stroke="black" stroke-width="0.5" />
    <path d="M152.77 103.65H155.38" stroke="black" stroke-width="0.5" />
    <path d="M135.74 104.99H136.76" stroke="black" stroke-width="0.5" />
    <path d="M152.31 104.99H154.04" stroke="black" stroke-width="0.5" />
    <path d="M135.74 104.99L128.74 111.99" stroke="black" stroke-width="0.5" />
    <path d="M134.89 103.95L126.85 111.99" stroke="black" stroke-width="0.5" />
    <path d="M134.89 103.65V103.95" stroke="black" stroke-width="0.5" />
    <path d="M143.17 104.99H136.76" stroke="black" stroke-width="0.5" />
    <path d="M143.17 103.65H136.76" stroke="black" stroke-width="0.5" />
    <path d="M136.76 103.65V104.99" stroke="black" stroke-width="0.5" />
    <path d="M137.29 103.65V104.99" stroke="black" stroke-width="0.5" />
    <path d="M142.63 103.65V104.99" stroke="black" stroke-width="0.5" />
    <path d="M143.17 103.65V104.99" stroke="black" stroke-width="0.5" />
    <path d="M142.63 104.32H137.29" stroke="black" stroke-width="0.5" />
    <path d="M152.77 104.98V103.65" stroke="black" stroke-width="0.5" />
    <path d="M153.31 104.98V103.65" stroke="black" stroke-width="0.5" />
    <path d="M37.49 63.89V113.33" stroke="black" stroke-width="0.5" />
    <path d="M22.81 106.86V113.33" stroke="black" stroke-width="0.5" />
    <path d="M21.48 105.52V111.99" stroke="black" stroke-width="0.5" />
    <path d="M36.96 70.3V71.63" stroke="black" stroke-width="0.5" />
    <path d="M36.42 70.3V71.63" stroke="black" stroke-width="0.5" />
    <path d="M38.82 111.99V65.23" stroke="black" stroke-width="0.5" />
    <path d="M22.21 44.14V52.54" stroke="black" stroke-width="0.5" />
    <path d="M22.21 62.15V70.3" stroke="black" stroke-width="0.5" />
    <path d="M20.61 55.35V57.4" stroke="black" stroke-width="0.5" />
    <path d="M20.61 58.24V59.91" stroke="black" stroke-width="0.5" />
    <path d="M20.61 60.74V62.41" stroke="black" stroke-width="0.5" />
    <path d="M20.61 63.24V64.91" stroke="black" stroke-width="0.5" />
    <path d="M20.61 65.74V67.41" stroke="black" stroke-width="0.5" />
    <path d="M20.61 68.24V70.3" stroke="black" stroke-width="0.5" />
    <path d="M23.55 44.14V52.54" stroke="black" stroke-width="0.5" />
    <path d="M23.55 62.15V70.3" stroke="black" stroke-width="0.5" />
    <path d="M23.55 70.3H26.81" stroke="black" stroke-width="0.5" />
    <path d="M36.42 70.3H37.49" stroke="black" stroke-width="0.5" />
    <path d="M26.81 70.3V71.63" stroke="black" stroke-width="0.5" />
    <path d="M26.28 70.3V71.63" stroke="black" stroke-width="0.5" />
    <path d="M22.21 62.68H23.55" stroke="black" stroke-width="0.5" />
    <path d="M22.21 62.15H23.55" stroke="black" stroke-width="0.5" />
    <path d="M22.21 52.54H23.55" stroke="black" stroke-width="0.5" />
    <path d="M22.21 52.01H23.55" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 46.28H6.48002" stroke="black" stroke-width="0.5" />
    <path d="M7.32001 46.28H8.99001" stroke="black" stroke-width="0.5" />
    <path d="M9.82001 46.28H11.49" stroke="black" stroke-width="0.5" />
    <path d="M12.32 46.28H13.41" stroke="black" stroke-width="0.5" />
    <path d="M13.41 46.28V47.26" stroke="black" stroke-width="0.5" />
    <path d="M13.41 48.1V49.76" stroke="black" stroke-width="0.5" />
    <path d="M13.41 50.6V52.27" stroke="black" stroke-width="0.5" />
    <path d="M13.41 53.1V54.77" stroke="black" stroke-width="0.5" />
    <path d="M13.41 55.6V57.27" stroke="black" stroke-width="0.5" />
    <path d="M13.41 58.1V59.09" stroke="black" stroke-width="0.5" />
    <path d="M13.41 59.09H12.32" stroke="black" stroke-width="0.5" />
    <path d="M11.49 59.09H9.82001" stroke="black" stroke-width="0.5" />
    <path d="M8.99001 59.09H7.32001" stroke="black" stroke-width="0.5" />
    <path d="M6.48002 59.09H5.40002" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 59.09V58.1" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 57.27V55.6" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 54.77V53.1" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 52.27V50.6" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 49.76V48.1" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 47.26V46.28" stroke="black" stroke-width="0.5" />
    <path d="M6.76001 70.51H5.64001" stroke="black" stroke-width="0.5" />
    <path d="M5.64001 70.51V70.33" stroke="black" stroke-width="0.5" />
    <path d="M5.64001 70.33H6.76001" stroke="black" stroke-width="0.5" />
    <path d="M6.76001 70.33V70.51" stroke="black" stroke-width="0.5" />
    <path d="M15.25 70.51H16.36" stroke="black" stroke-width="0.5" />
    <path d="M16.36 70.51V70.33" stroke="black" stroke-width="0.5" />
    <path d="M16.36 70.33H15.25" stroke="black" stroke-width="0.5" />
    <path d="M15.25 70.33V70.51" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 56.65V55.54" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 55.54H4.76998" stroke="black" stroke-width="0.5" />
    <path d="M4.77002 55.54V56.65" stroke="black" stroke-width="0.5" />
    <path d="M4.76998 56.65H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 66.75V67.86" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 67.86H4.76998" stroke="black" stroke-width="0.5" />
    <path d="M4.77002 67.86V66.75" stroke="black" stroke-width="0.5" />
    <path d="M4.76998 66.75H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M10.83 69.92H6.44" stroke="black" stroke-width="0.5" />
    <path d="M6.43997 69.92L6.40997 69.93" stroke="black" stroke-width="0.5" />
    <path d="M6.41 69.93L6.38 69.95" stroke="black" stroke-width="0.5" />
    <path d="M6.38 69.95L6.37 69.99" stroke="black" stroke-width="0.5" />
    <path d="M6.37 69.99V70.33" stroke="black" stroke-width="0.5" />
    <path d="M6.37003 70.33H6.03003" stroke="black" stroke-width="0.5" />
    <path d="M6.03003 70.33V69.92" stroke="black" stroke-width="0.5" />
    <path d="M6.03003 69.92L6.07003 69.74" stroke="black" stroke-width="0.5" />
    <path d="M6.07001 69.74L6.20001 69.62" stroke="black" stroke-width="0.5" />
    <path d="M6.20001 69.62L6.37001 69.57" stroke="black" stroke-width="0.5" />
    <path d="M6.37 69.57H10.83" stroke="black" stroke-width="0.5" />
    <path d="M10.83 69.57H15.63" stroke="black" stroke-width="0.5" />
    <path d="M15.63 69.57L15.81 69.62" stroke="black" stroke-width="0.5" />
    <path d="M15.81 69.62L15.93 69.74" stroke="black" stroke-width="0.5" />
    <path d="M15.93 69.74L15.98 69.92" stroke="black" stroke-width="0.5" />
    <path d="M15.98 69.92V70.33" stroke="black" stroke-width="0.5" />
    <path d="M15.98 70.33H15.63" stroke="black" stroke-width="0.5" />
    <path d="M15.63 70.33V69.99" stroke="black" stroke-width="0.5" />
    <path d="M15.63 69.99L15.62 69.95" stroke="black" stroke-width="0.5" />
    <path d="M15.62 69.95L15.6 69.93" stroke="black" stroke-width="0.5" />
    <path d="M15.6 69.93L15.56 69.92" stroke="black" stroke-width="0.5" />
    <path d="M15.56 69.92H10.83" stroke="black" stroke-width="0.5" />
    <path d="M5.18999 56.34L5.17999 56.3" stroke="black" stroke-width="0.5" />
    <path d="M5.18002 56.3L5.15002 56.28" stroke="black" stroke-width="0.5" />
    <path d="M5.15 56.28L5.12 56.27" stroke="black" stroke-width="0.5" />
    <path d="M5.12002 56.27H4.77002" stroke="black" stroke-width="0.5" />
    <path d="M4.77002 56.27V55.92" stroke="black" stroke-width="0.5" />
    <path d="M4.77002 55.92H5.19002" stroke="black" stroke-width="0.5" />
    <path d="M5.19 55.92L5.36 55.97" stroke="black" stroke-width="0.5" />
    <path d="M5.35999 55.97L5.48999 56.1" stroke="black" stroke-width="0.5" />
    <path d="M5.48999 56.1L5.53999 56.27" stroke="black" stroke-width="0.5" />
    <path d="M5.53998 56.27V67.13" stroke="black" stroke-width="0.5" />
    <path d="M5.53999 67.13L5.48999 67.3" stroke="black" stroke-width="0.5" />
    <path d="M5.48999 67.3L5.35999 67.43" stroke="black" stroke-width="0.5" />
    <path d="M5.36 67.43L5.19 67.48" stroke="black" stroke-width="0.5" />
    <path d="M5.19002 67.48H4.77002" stroke="black" stroke-width="0.5" />
    <path d="M4.77002 67.48V67.13" stroke="black" stroke-width="0.5" />
    <path d="M4.77002 67.13H5.12002" stroke="black" stroke-width="0.5" />
    <path d="M5.12 67.13L5.15 67.12" stroke="black" stroke-width="0.5" />
    <path d="M5.15002 67.12L5.18002 67.1" stroke="black" stroke-width="0.5" />
    <path d="M5.17999 67.1L5.18999 67.06" stroke="black" stroke-width="0.5" />
    <path d="M5.19 67.06V56.34" stroke="black" stroke-width="0.5" />
    <path d="M10.73 67.95L10.8 67.39" stroke="black" stroke-width="0.5" />
    <path d="M10.8 67.39L10.86 66.98" stroke="black" stroke-width="0.5" />
    <path d="M10.86 66.98L10.92 66.62" stroke="black" stroke-width="0.5" />
    <path d="M10.92 66.62L10.98 66.32" stroke="black" stroke-width="0.5" />
    <path d="M10.98 66.32L11.04 66.07" stroke="black" stroke-width="0.5" />
    <path d="M11.04 66.07L11.09 65.89" stroke="black" stroke-width="0.5" />
    <path d="M11.09 65.89L11.12 65.76" stroke="black" stroke-width="0.5" />
    <path d="M11.12 65.76L11.14 65.66" stroke="black" stroke-width="0.5" />
    <path d="M11.14 65.66L11.16 65.55" stroke="black" stroke-width="0.5" />
    <path d="M11.16 65.55L11.17 65.42" stroke="black" stroke-width="0.5" />
    <path d="M11.17 65.42L11.18 65.22" stroke="black" stroke-width="0.5" />
    <path d="M11.18 65.22V64.98" stroke="black" stroke-width="0.5" />
    <path d="M11.18 64.98L11.16 64.71" stroke="black" stroke-width="0.5" />
    <path d="M11.16 64.71L11.12 64.41" stroke="black" stroke-width="0.5" />
    <path d="M11.12 64.41L11.06 64.1" stroke="black" stroke-width="0.5" />
    <path d="M11.06 64.1L10.96 63.79" stroke="black" stroke-width="0.5" />
    <path d="M10.96 63.79L10.83 63.48" stroke="black" stroke-width="0.5" />
    <path d="M10.83 63.48L10.68 63.2" stroke="black" stroke-width="0.5" />
    <path d="M10.68 63.2L10.49 62.95" stroke="black" stroke-width="0.5" />
    <path d="M10.49 62.95L10.29 62.73" stroke="black" stroke-width="0.5" />
    <path d="M10.29 62.73L10.07 62.56" stroke="black" stroke-width="0.5" />
    <path d="M10.07 62.56L9.84003 62.43" stroke="black" stroke-width="0.5" />
    <path d="M9.84 62.43L9.62 62.35" stroke="black" stroke-width="0.5" />
    <path d="M9.62002 62.35L9.40002 62.32" stroke="black" stroke-width="0.5" />
    <path d="M9.39999 62.32L9.17999 62.35" stroke="black" stroke-width="0.5" />
    <path d="M9.18002 62.35L8.96002 62.42" stroke="black" stroke-width="0.5" />
    <path d="M8.95998 62.42L8.72998 62.56" stroke="black" stroke-width="0.5" />
    <path d="M8.73001 62.56L8.51001 62.73" stroke="black" stroke-width="0.5" />
    <path d="M8.50999 62.73L8.31 62.95" stroke="black" stroke-width="0.5" />
    <path d="M8.31 62.95L8.12 63.2" stroke="black" stroke-width="0.5" />
    <path d="M8.11997 63.2L7.96997 63.49" stroke="black" stroke-width="0.5" />
    <path d="M7.97003 63.49L7.84003 63.79" stroke="black" stroke-width="0.5" />
    <path d="M7.83999 63.79L7.73999 64.1" stroke="black" stroke-width="0.5" />
    <path d="M7.73999 64.1L7.66998 64.41" stroke="black" stroke-width="0.5" />
    <path d="M7.67001 64.41L7.64001 64.71" stroke="black" stroke-width="0.5" />
    <path d="M7.64 64.71L7.62 64.99" stroke="black" stroke-width="0.5" />
    <path d="M7.62 64.99V65.23" stroke="black" stroke-width="0.5" />
    <path d="M7.62 65.23L7.63 65.42" stroke="black" stroke-width="0.5" />
    <path d="M7.63 65.42L7.64 65.55" stroke="black" stroke-width="0.5" />
    <path d="M7.64001 65.55L7.66002 65.66" stroke="black" stroke-width="0.5" />
    <path d="M7.65997 65.66L7.67997 65.76" stroke="black" stroke-width="0.5" />
    <path d="M7.67999 65.76L7.70999 65.89" stroke="black" stroke-width="0.5" />
    <path d="M7.71002 65.89L7.76002 66.08" stroke="black" stroke-width="0.5" />
    <path d="M7.76001 66.08L7.82001 66.32" stroke="black" stroke-width="0.5" />
    <path d="M7.82001 66.32L7.88001 66.62" stroke="black" stroke-width="0.5" />
    <path d="M7.88 66.62L7.94 66.98" stroke="black" stroke-width="0.5" />
    <path d="M7.94 66.98L8 67.39" stroke="black" stroke-width="0.5" />
    <path d="M8 67.39L8.05 67.83" stroke="black" stroke-width="0.5" />
    <path d="M8.04999 67.83V67.95" stroke="black" stroke-width="0.5" />
    <path d="M7.57998 68.1L7.53998 68.11" stroke="black" stroke-width="0.5" />
    <path d="M7.54 68.11L7.5 68.13" stroke="black" stroke-width="0.5" />
    <path d="M7.49997 68.13L7.46997 68.14" stroke="black" stroke-width="0.5" />
    <path d="M7.47001 68.14L7.44 68.16" stroke="black" stroke-width="0.5" />
    <path d="M7.43997 68.16L7.40997 68.17" stroke="black" stroke-width="0.5" />
    <path d="M7.41 68.17L7.38 68.2" stroke="black" stroke-width="0.5" />
    <path d="M7.38003 68.2L7.34003 68.22" stroke="black" stroke-width="0.5" />
    <path d="M7.33999 68.22L7.29999 68.25" stroke="black" stroke-width="0.5" />
    <path d="M7.30001 68.25L7.26001 68.29" stroke="black" stroke-width="0.5" />
    <path d="M7.26002 68.29L7.21002 68.34" stroke="black" stroke-width="0.5" />
    <path d="M7.20997 68.34L7.15997 68.39" stroke="black" stroke-width="0.5" />
    <path d="M7.16 68.39L7.12 68.45" stroke="black" stroke-width="0.5" />
    <path d="M7.12001 68.45L7.07001 68.51" stroke="black" stroke-width="0.5" />
    <path d="M7.07002 68.51L7.02002 68.58" stroke="black" stroke-width="0.5" />
    <path d="M7.01998 68.58L6.97998 68.65" stroke="black" stroke-width="0.5" />
    <path d="M6.98001 68.65L6.95001 68.72" stroke="black" stroke-width="0.5" />
    <path d="M6.94999 68.72L6.91998 68.79" stroke="black" stroke-width="0.5" />
    <path d="M6.92002 68.79L6.90002 68.87" stroke="black" stroke-width="0.5" />
    <path d="M6.90002 68.87L6.89001 68.95" stroke="black" stroke-width="0.5" />
    <path d="M6.89 68.95L6.88 69.02" stroke="black" stroke-width="0.5" />
    <path d="M6.88 69.02L6.87 69.1" stroke="black" stroke-width="0.5" />
    <path d="M6.87 69.1V69.17" stroke="black" stroke-width="0.5" />
    <path d="M6.87 69.17V69.25" stroke="black" stroke-width="0.5" />
    <path d="M6.87 69.25V69.32" stroke="black" stroke-width="0.5" />
    <path d="M6.87 69.32V69.39" stroke="black" stroke-width="0.5" />
    <path d="M6.87 69.39L6.88 69.45" stroke="black" stroke-width="0.5" />
    <path d="M6.88 69.45V69.52" stroke="black" stroke-width="0.5" />
    <path d="M6.88 69.52V69.58" stroke="black" stroke-width="0.5" />
    <path d="M6.88 69.58V69.63" stroke="black" stroke-width="0.5" />
    <path d="M6.88 69.63L6.89 69.68" stroke="black" stroke-width="0.5" />
    <path d="M6.89001 69.68L6.90002 69.73" stroke="black" stroke-width="0.5" />
    <path d="M6.90002 69.73V69.78" stroke="black" stroke-width="0.5" />
    <path d="M6.90002 69.78L6.91002 69.82" stroke="black" stroke-width="0.5" />
    <path d="M6.90997 69.82L6.91997 69.86" stroke="black" stroke-width="0.5" />
    <path d="M6.91998 69.86L6.92999 69.9" stroke="black" stroke-width="0.5" />
    <path d="M6.92999 69.9L6.94999 69.93" stroke="black" stroke-width="0.5" />
    <path d="M6.95001 69.93L6.97001 69.96" stroke="black" stroke-width="0.5" />
    <path d="M6.96997 69.96L6.99997 69.99" stroke="black" stroke-width="0.5" />
    <path d="M7 69.99L7.03 70.01" stroke="black" stroke-width="0.5" />
    <path d="M7.03003 70.01L7.08003 70.03" stroke="black" stroke-width="0.5" />
    <path d="M7.08002 70.03L7.13002 70.05" stroke="black" stroke-width="0.5" />
    <path d="M7.13 70.05L7.19 70.07" stroke="black" stroke-width="0.5" />
    <path d="M7.19 70.07L7.27 70.08" stroke="black" stroke-width="0.5" />
    <path d="M7.27002 70.08L7.35002 70.1" stroke="black" stroke-width="0.5" />
    <path d="M7.34998 70.1L7.44998 70.11" stroke="black" stroke-width="0.5" />
    <path d="M7.45001 70.11L7.56001 70.12" stroke="black" stroke-width="0.5" />
    <path d="M7.56 70.12H7.68999" stroke="black" stroke-width="0.5" />
    <path d="M7.69 70.12L7.83001 70.13" stroke="black" stroke-width="0.5" />
    <path d="M7.83002 70.13L7.99002 70.14" stroke="black" stroke-width="0.5" />
    <path d="M7.98999 70.14H8.16999" stroke="black" stroke-width="0.5" />
    <path d="M8.16998 70.14L8.34998 70.15" stroke="black" stroke-width="0.5" />
    <path d="M8.34998 70.15H8.54998" stroke="black" stroke-width="0.5" />
    <path d="M8.54999 70.15L8.74999 70.16" stroke="black" stroke-width="0.5" />
    <path d="M8.75 70.16H8.97" stroke="black" stroke-width="0.5" />
    <path d="M8.96997 70.16H9.17997" stroke="black" stroke-width="0.5" />
    <path d="M9.17999 70.16H9.39999" stroke="black" stroke-width="0.5" />
    <path d="M9.40002 70.16H9.62002" stroke="black" stroke-width="0.5" />
    <path d="M9.62 70.16H9.84" stroke="black" stroke-width="0.5" />
    <path d="M9.84003 70.16H10.05" stroke="black" stroke-width="0.5" />
    <path d="M10.05 70.16L10.25 70.15" stroke="black" stroke-width="0.5" />
    <path d="M10.25 70.15H10.45" stroke="black" stroke-width="0.5" />
    <path d="M10.45 70.15L10.64 70.14" stroke="black" stroke-width="0.5" />
    <path d="M10.64 70.14H10.81" stroke="black" stroke-width="0.5" />
    <path d="M10.81 70.14L10.97 70.13" stroke="black" stroke-width="0.5" />
    <path d="M10.97 70.13L11.11 70.12" stroke="black" stroke-width="0.5" />
    <path d="M11.11 70.12L11.24 70.11" stroke="black" stroke-width="0.5" />
    <path d="M11.24 70.11L11.35 70.1" stroke="black" stroke-width="0.5" />
    <path d="M11.35 70.1L11.45 70.09" stroke="black" stroke-width="0.5" />
    <path d="M11.45 70.09L11.54 70.08" stroke="black" stroke-width="0.5" />
    <path d="M11.54 70.08L11.61 70.06" stroke="black" stroke-width="0.5" />
    <path d="M11.61 70.06L11.67 70.05" stroke="black" stroke-width="0.5" />
    <path d="M11.67 70.05L11.72 70.03" stroke="black" stroke-width="0.5" />
    <path d="M11.72 70.03L11.76 70.01" stroke="black" stroke-width="0.5" />
    <path d="M11.76 70.01L11.8 69.98" stroke="black" stroke-width="0.5" />
    <path d="M11.8 69.98L11.83 69.96" stroke="black" stroke-width="0.5" />
    <path d="M11.83 69.96L11.85 69.93" stroke="black" stroke-width="0.5" />
    <path d="M11.85 69.93L11.86 69.89" stroke="black" stroke-width="0.5" />
    <path d="M11.86 69.89L11.88 69.86" stroke="black" stroke-width="0.5" />
    <path d="M11.88 69.86L11.89 69.82" stroke="black" stroke-width="0.5" />
    <path d="M11.89 69.82V69.78" stroke="black" stroke-width="0.5" />
    <path d="M11.89 69.78L11.9 69.73" stroke="black" stroke-width="0.5" />
    <path d="M11.9 69.73L11.91 69.68" stroke="black" stroke-width="0.5" />
    <path d="M11.91 69.68V69.63" stroke="black" stroke-width="0.5" />
    <path d="M11.91 69.63L11.92 69.57" stroke="black" stroke-width="0.5" />
    <path d="M11.92 69.57V69.51" stroke="black" stroke-width="0.5" />
    <path d="M11.92 69.51V69.45" stroke="black" stroke-width="0.5" />
    <path d="M11.92 69.45V69.38" stroke="black" stroke-width="0.5" />
    <path d="M11.92 69.38L11.93 69.31" stroke="black" stroke-width="0.5" />
    <path d="M11.93 69.31V69.24" stroke="black" stroke-width="0.5" />
    <path d="M11.93 69.24V69.17" stroke="black" stroke-width="0.5" />
    <path d="M11.93 69.17L11.92 69.1" stroke="black" stroke-width="0.5" />
    <path d="M11.92 69.1V69.02" stroke="black" stroke-width="0.5" />
    <path d="M11.92 69.02L11.91 68.95" stroke="black" stroke-width="0.5" />
    <path d="M11.91 68.95L11.89 68.87" stroke="black" stroke-width="0.5" />
    <path d="M11.89 68.87L11.87 68.8" stroke="black" stroke-width="0.5" />
    <path d="M11.87 68.8L11.84 68.73" stroke="black" stroke-width="0.5" />
    <path d="M11.84 68.73L11.81 68.65" stroke="black" stroke-width="0.5" />
    <path d="M11.81 68.65L11.77 68.58" stroke="black" stroke-width="0.5" />
    <path d="M11.77 68.58L11.72 68.52" stroke="black" stroke-width="0.5" />
    <path d="M11.72 68.52L11.67 68.45" stroke="black" stroke-width="0.5" />
    <path d="M11.67 68.45L11.61 68.39" stroke="black" stroke-width="0.5" />
    <path d="M11.61 68.39L11.55 68.34" stroke="black" stroke-width="0.5" />
    <path d="M11.55 68.34L11.49 68.28" stroke="black" stroke-width="0.5" />
    <path d="M11.49 68.28L11.42 68.23" stroke="black" stroke-width="0.5" />
    <path d="M11.42 68.23L11.36 68.19" stroke="black" stroke-width="0.5" />
    <path d="M11.36 68.19L11.29 68.15" stroke="black" stroke-width="0.5" />
    <path d="M11.29 68.15L11.22 68.12" stroke="black" stroke-width="0.5" />
    <path d="M11.22 68.12L11.15 68.09" stroke="black" stroke-width="0.5" />
    <path d="M11.15 68.09L11.07 68.06" stroke="black" stroke-width="0.5" />
    <path d="M11.07 68.06L11 68.03" stroke="black" stroke-width="0.5" />
    <path d="M11 68.03L10.92 68.01" stroke="black" stroke-width="0.5" />
    <path d="M10.92 68.01L10.85 67.99" stroke="black" stroke-width="0.5" />
    <path d="M10.85 67.99L10.77 67.96" stroke="black" stroke-width="0.5" />
    <path d="M10.77 67.96L10.69 67.94" stroke="black" stroke-width="0.5" />
    <path d="M10.69 67.94L10.62 67.92" stroke="black" stroke-width="0.5" />
    <path d="M10.62 67.92L10.54 67.91" stroke="black" stroke-width="0.5" />
    <path d="M10.54 67.91L10.45 67.89" stroke="black" stroke-width="0.5" />
    <path d="M10.45 67.89L10.37 67.87" stroke="black" stroke-width="0.5" />
    <path d="M10.37 67.87L10.28 67.86" stroke="black" stroke-width="0.5" />
    <path d="M10.28 67.86L10.19 67.85" stroke="black" stroke-width="0.5" />
    <path d="M10.19 67.85L10.1 67.83" stroke="black" stroke-width="0.5" />
    <path d="M10.1 67.83L10.01 67.82" stroke="black" stroke-width="0.5" />
    <path d="M10.01 67.82L9.90997 67.81" stroke="black" stroke-width="0.5" />
    <path d="M9.91 67.81H9.81" stroke="black" stroke-width="0.5" />
    <path d="M9.81002 67.81L9.71002 67.8" stroke="black" stroke-width="0.5" />
    <path d="M9.70998 67.8H9.59998" stroke="black" stroke-width="0.5" />
    <path d="M9.6 67.8L9.5 67.79" stroke="black" stroke-width="0.5" />
    <path d="M9.50002 67.79H9.40002" stroke="black" stroke-width="0.5" />
    <path d="M9.39998 67.79H9.28998" stroke="black" stroke-width="0.5" />
    <path d="M9.29 67.79H9.19" stroke="black" stroke-width="0.5" />
    <path d="M9.19003 67.79L9.09003 67.8" stroke="black" stroke-width="0.5" />
    <path d="M9.08999 67.8H8.98999" stroke="black" stroke-width="0.5" />
    <path d="M8.99002 67.8L8.89001 67.81" stroke="black" stroke-width="0.5" />
    <path d="M8.88998 67.81H8.78998" stroke="black" stroke-width="0.5" />
    <path d="M8.79001 67.81L8.70001 67.82" stroke="black" stroke-width="0.5" />
    <path d="M8.7 67.82L8.62 67.83" stroke="black" stroke-width="0.5" />
    <path d="M8.61998 67.83L8.53998 67.84" stroke="black" stroke-width="0.5" />
    <path d="M8.54002 67.84L8.46002 67.86" stroke="black" stroke-width="0.5" />
    <path d="M8.46001 67.86L8.39001 67.87" stroke="black" stroke-width="0.5" />
    <path d="M8.39 67.87L8.31 67.88" stroke="black" stroke-width="0.5" />
    <path d="M8.31 67.88L8.25 67.9" stroke="black" stroke-width="0.5" />
    <path d="M8.24999 67.9L8.17999 67.91" stroke="black" stroke-width="0.5" />
    <path d="M8.17999 67.91L8.10999 67.93" stroke="black" stroke-width="0.5" />
    <path d="M8.10999 67.93L8.04999 67.95" stroke="black" stroke-width="0.5" />
    <path d="M8.04998 67.95L7.97998 67.97" stroke="black" stroke-width="0.5" />
    <path d="M7.97998 67.97L7.91998 67.99" stroke="black" stroke-width="0.5" />
    <path d="M7.91998 67.99L7.84998 68.01" stroke="black" stroke-width="0.5" />
    <path d="M7.84998 68.01L7.78998 68.03" stroke="black" stroke-width="0.5" />
    <path d="M7.78998 68.03L7.72998 68.05" stroke="black" stroke-width="0.5" />
    <path d="M7.72999 68.05L7.67999 68.07" stroke="black" stroke-width="0.5" />
    <path d="M7.68 68.07L7.63 68.08" stroke="black" stroke-width="0.5" />
    <path d="M7.63002 68.08L7.58002 68.1" stroke="black" stroke-width="0.5" />
    <path d="M21.41 55.35V55.23" stroke="black" stroke-width="0.5" />
    <path d="M21.41 55.23V55.1" stroke="black" stroke-width="0.5" />
    <path d="M21.41 55.1L21.4 54.97" stroke="black" stroke-width="0.5" />
    <path d="M21.4 54.97V54.93" stroke="black" stroke-width="0.5" />
    <path d="M21.39 54.72L21.38 54.59" stroke="black" stroke-width="0.5" />
    <path d="M21.38 54.59L21.36 54.47" stroke="black" stroke-width="0.5" />
    <path d="M21.36 54.47L21.35 54.34" stroke="black" stroke-width="0.5" />
    <path d="M21.35 54.34L21.34 54.31" stroke="black" stroke-width="0.5" />
    <path d="M21.31 54.1V54.09" stroke="black" stroke-width="0.5" />
    <path d="M21.31 54.09L21.29 53.97" stroke="black" stroke-width="0.5" />
    <path d="M21.29 53.97L21.27 53.84" stroke="black" stroke-width="0.5" />
    <path d="M21.27 53.84L21.24 53.72" stroke="black" stroke-width="0.5" />
    <path d="M21.24 53.72V53.69" stroke="black" stroke-width="0.5" />
    <path d="M21.19 53.48V53.47" stroke="black" stroke-width="0.5" />
    <path d="M21.19 53.47L21.16 53.35" stroke="black" stroke-width="0.5" />
    <path d="M21.16 53.35L21.12 53.23" stroke="black" stroke-width="0.5" />
    <path d="M21.12 53.23L21.09 53.11" stroke="black" stroke-width="0.5" />
    <path d="M21.09 53.11L21.08 53.08" stroke="black" stroke-width="0.5" />
    <path d="M21.02 52.88V52.87" stroke="black" stroke-width="0.5" />
    <path d="M21.02 52.87L20.97 52.75" stroke="black" stroke-width="0.5" />
    <path d="M20.97 52.75L20.93 52.63" stroke="black" stroke-width="0.5" />
    <path d="M20.93 52.63L20.89 52.51" stroke="black" stroke-width="0.5" />
    <path d="M20.89 52.51L20.88 52.48" stroke="black" stroke-width="0.5" />
    <path d="M20.8 52.29V52.27" stroke="black" stroke-width="0.5" />
    <path d="M20.8 52.27L20.75 52.16" stroke="black" stroke-width="0.5" />
    <path d="M20.75 52.16L20.69 52.04" stroke="black" stroke-width="0.5" />
    <path d="M20.69 52.04L20.64 51.93" stroke="black" stroke-width="0.5" />
    <path d="M20.64 51.93L20.63 51.91" stroke="black" stroke-width="0.5" />
    <path d="M20.54 51.72L20.53 51.7" stroke="black" stroke-width="0.5" />
    <path d="M20.53 51.7L20.47 51.59" stroke="black" stroke-width="0.5" />
    <path d="M20.47 51.59L20.41 51.48" stroke="black" stroke-width="0.5" />
    <path d="M20.41 51.48L20.35 51.37" stroke="black" stroke-width="0.5" />
    <path d="M20.35 51.37L20.34 51.35" stroke="black" stroke-width="0.5" />
    <path d="M20.23 51.17L20.22 51.15" stroke="black" stroke-width="0.5" />
    <path d="M20.22 51.15L20.15 51.04" stroke="black" stroke-width="0.5" />
    <path d="M20.15 51.04L20.08 50.94" stroke="black" stroke-width="0.5" />
    <path d="M20.08 50.94L20.01 50.83" stroke="black" stroke-width="0.5" />
    <path d="M20.01 50.83L20 50.82" stroke="black" stroke-width="0.5" />
    <path d="M19.88 50.65L19.87 50.63" stroke="black" stroke-width="0.5" />
    <path d="M19.87 50.63L19.79 50.52" stroke="black" stroke-width="0.5" />
    <path d="M19.79 50.52L19.71 50.42" stroke="black" stroke-width="0.5" />
    <path d="M19.71 50.42L19.64 50.33" stroke="black" stroke-width="0.5" />
    <path d="M19.64 50.33L19.63 50.31" stroke="black" stroke-width="0.5" />
    <path d="M19.49 50.15L19.47 50.13" stroke="black" stroke-width="0.5" />
    <path d="M19.47 50.13L19.39 50.04" stroke="black" stroke-width="0.5" />
    <path d="M19.39 50.04L19.31 49.94" stroke="black" stroke-width="0.5" />
    <path d="M19.31 49.94L19.22 49.85" stroke="black" stroke-width="0.5" />
    <path d="M19.22 49.85L19.21 49.84" stroke="black" stroke-width="0.5" />
    <path d="M19.07 49.69L19.04 49.67" stroke="black" stroke-width="0.5" />
    <path d="M19.04 49.67L18.95 49.58" stroke="black" stroke-width="0.5" />
    <path d="M18.95 49.58L18.86 49.49" stroke="black" stroke-width="0.5" />
    <path d="M18.86 49.49L18.77 49.41" stroke="black" stroke-width="0.5" />
    <path d="M18.77 49.41L18.76 49.4" stroke="black" stroke-width="0.5" />
    <path d="M18.6 49.26L18.58 49.24" stroke="black" stroke-width="0.5" />
    <path d="M18.58 49.24L18.48 49.16" stroke="black" stroke-width="0.5" />
    <path d="M18.48 49.16L18.38 49.08" stroke="black" stroke-width="0.5" />
    <path d="M18.38 49.08L18.28 49" stroke="black" stroke-width="0.5" />
    <path d="M18.11 48.88L18.08 48.85" stroke="black" stroke-width="0.5" />
    <path d="M18.08 48.85L17.98 48.78" stroke="black" stroke-width="0.5" />
    <path d="M17.98 48.78L17.87 48.71" stroke="black" stroke-width="0.5" />
    <path d="M17.87 48.71L17.77 48.64" stroke="black" stroke-width="0.5" />
    <path d="M17.59 48.53L17.55 48.5" stroke="black" stroke-width="0.5" />
    <path d="M17.55 48.5L17.44 48.44" stroke="black" stroke-width="0.5" />
    <path d="M17.44 48.44L17.33 48.38" stroke="black" stroke-width="0.5" />
    <path d="M17.33 48.38L17.23 48.32" stroke="black" stroke-width="0.5" />
    <path d="M17.04 48.22L17 48.2" stroke="black" stroke-width="0.5" />
    <path d="M17 48.2L16.89 48.14" stroke="black" stroke-width="0.5" />
    <path d="M16.89 48.14L16.77 48.09" stroke="black" stroke-width="0.5" />
    <path d="M16.77 48.09L16.66 48.04" stroke="black" stroke-width="0.5" />
    <path d="M16.47 47.96L16.42 47.94" stroke="black" stroke-width="0.5" />
    <path d="M16.42 47.94L16.31 47.89" stroke="black" stroke-width="0.5" />
    <path d="M16.31 47.89L16.19 47.85" stroke="black" stroke-width="0.5" />
    <path d="M16.19 47.85L16.08 47.81" stroke="black" stroke-width="0.5" />
    <path d="M15.88 47.74L15.83 47.72" stroke="black" stroke-width="0.5" />
    <path d="M15.83 47.72L15.71 47.69" stroke="black" stroke-width="0.5" />
    <path d="M15.71 47.69L15.59 47.65" stroke="black" stroke-width="0.5" />
    <path d="M15.59 47.65L15.48 47.62" stroke="black" stroke-width="0.5" />
    <path d="M15.27 47.57L15.22 47.56" stroke="black" stroke-width="0.5" />
    <path d="M15.22 47.56L15.1 47.53" stroke="black" stroke-width="0.5" />
    <path d="M15.1 47.53L14.97 47.5" stroke="black" stroke-width="0.5" />
    <path d="M14.97 47.5L14.86 47.48" stroke="black" stroke-width="0.5" />
    <path d="M14.66 47.44H14.6" stroke="black" stroke-width="0.5" />
    <path d="M14.6 47.44L14.48 47.42" stroke="black" stroke-width="0.5" />
    <path d="M14.48 47.42L14.35 47.4" stroke="black" stroke-width="0.5" />
    <path d="M14.35 47.4L14.24 47.39" stroke="black" stroke-width="0.5" />
    <path d="M14.03 47.37H13.97" stroke="black" stroke-width="0.5" />
    <path d="M13.97 47.37L13.85 47.36" stroke="black" stroke-width="0.5" />
    <path d="M13.85 47.36L13.72 47.35" stroke="black" stroke-width="0.5" />
    <path d="M13.72 47.35H13.61" stroke="black" stroke-width="0.5" />
    <path d="M13.41 47.35H13.34" stroke="black" stroke-width="0.5" />
    <path d="M13.34 47.35H13.22" stroke="black" stroke-width="0.5" />
    <path d="M13.22 47.35H13.09" stroke="black" stroke-width="0.5" />
    <path d="M13.09 47.35L12.99 47.36" stroke="black" stroke-width="0.5" />
    <path d="M12.78 47.37L12.71 47.38" stroke="black" stroke-width="0.5" />
    <path d="M12.71 47.38L12.58 47.39" stroke="black" stroke-width="0.5" />
    <path d="M12.58 47.39L12.46 47.4" stroke="black" stroke-width="0.5" />
    <path d="M12.46 47.4L12.36 47.41" stroke="black" stroke-width="0.5" />
    <path d="M12.15 47.44L12.08 47.46" stroke="black" stroke-width="0.5" />
    <path d="M12.08 47.46L11.96 47.48" stroke="black" stroke-width="0.5" />
    <path d="M11.96 47.48L11.84 47.5" stroke="black" stroke-width="0.5" />
    <path d="M11.84 47.5L11.74 47.52" stroke="black" stroke-width="0.5" />
    <path d="M11.54 47.57L11.47 47.58" stroke="black" stroke-width="0.5" />
    <path d="M11.47 47.58L11.34 47.62" stroke="black" stroke-width="0.5" />
    <path d="M11.34 47.62L11.22 47.65" stroke="black" stroke-width="0.5" />
    <path d="M11.22 47.65L11.13 47.68" stroke="black" stroke-width="0.5" />
    <path d="M10.93 47.74L10.86 47.76" stroke="black" stroke-width="0.5" />
    <path d="M10.86 47.76L10.74 47.8" stroke="black" stroke-width="0.5" />
    <path d="M10.74 47.8L10.62 47.85" stroke="black" stroke-width="0.5" />
    <path d="M10.62 47.85L10.54 47.88" stroke="black" stroke-width="0.5" />
    <path d="M10.34 47.96L10.27 47.99" stroke="black" stroke-width="0.5" />
    <path d="M10.27 47.99L10.15 48.04" stroke="black" stroke-width="0.5" />
    <path d="M10.15 48.04L10.04 48.09" stroke="black" stroke-width="0.5" />
    <path d="M10.04 48.09L9.96002 48.13" stroke="black" stroke-width="0.5" />
    <path d="M9.77001 48.22L9.70001 48.26" stroke="black" stroke-width="0.5" />
    <path d="M9.70003 48.26L9.59003 48.32" stroke="black" stroke-width="0.5" />
    <path d="M9.58998 48.32L9.47998 48.38" stroke="black" stroke-width="0.5" />
    <path d="M9.48002 48.38L9.40002 48.42" stroke="black" stroke-width="0.5" />
    <path d="M9.22002 48.53L9.15002 48.57" stroke="black" stroke-width="0.5" />
    <path d="M9.14998 48.57L9.03998 48.64" stroke="black" stroke-width="0.5" />
    <path d="M9.04 48.64L8.94 48.71" stroke="black" stroke-width="0.5" />
    <path d="M8.93999 48.71L8.87 48.75" stroke="black" stroke-width="0.5" />
    <path d="M8.7 48.88L8.63 48.93" stroke="black" stroke-width="0.5" />
    <path d="M8.63003 48.93L8.53003 49" stroke="black" stroke-width="0.5" />
    <path d="M8.52999 49L8.42999 49.08" stroke="black" stroke-width="0.5" />
    <path d="M8.43 49.08L8.37 49.13" stroke="black" stroke-width="0.5" />
    <path d="M8.21001 49.26L8.14001 49.32" stroke="black" stroke-width="0.5" />
    <path d="M8.13998 49.32L8.03998 49.41" stroke="black" stroke-width="0.5" />
    <path d="M8.04001 49.41L7.95001 49.49" stroke="black" stroke-width="0.5" />
    <path d="M7.95002 49.49L7.89001 49.54" stroke="black" stroke-width="0.5" />
    <path d="M7.73999 49.69L7.67999 49.76" stroke="black" stroke-width="0.5" />
    <path d="M7.68003 49.76L7.59003 49.85" stroke="black" stroke-width="0.5" />
    <path d="M7.59 49.85L7.5 49.94" stroke="black" stroke-width="0.5" />
    <path d="M7.50002 49.94L7.46002 50" stroke="black" stroke-width="0.5" />
    <path d="M7.32 50.15L7.25 50.23" stroke="black" stroke-width="0.5" />
    <path d="M7.24998 50.23L7.16998 50.33" stroke="black" stroke-width="0.5" />
    <path d="M7.16998 50.33L7.09998 50.42" stroke="black" stroke-width="0.5" />
    <path d="M7.09999 50.42L7.04999 50.48" stroke="black" stroke-width="0.5" />
    <path d="M6.93 50.65L6.87 50.73" stroke="black" stroke-width="0.5" />
    <path d="M6.86999 50.73L6.79999 50.83" stroke="black" stroke-width="0.5" />
    <path d="M6.79998 50.83L6.72998 50.94" stroke="black" stroke-width="0.5" />
    <path d="M6.73 50.94L6.69 50.99" stroke="black" stroke-width="0.5" />
    <path d="M6.58003 51.17L6.53003 51.26" stroke="black" stroke-width="0.5" />
    <path d="M6.53002 51.26L6.46002 51.37" stroke="black" stroke-width="0.5" />
    <path d="M6.46002 51.37L6.40002 51.48" stroke="black" stroke-width="0.5" />
    <path d="M6.4 51.48L6.37 51.53" stroke="black" stroke-width="0.5" />
    <path d="M6.26997 51.72L6.21997 51.81" stroke="black" stroke-width="0.5" />
    <path d="M6.21999 51.81L6.16998 51.93" stroke="black" stroke-width="0.5" />
    <path d="M6.16999 51.93L6.12 52.04" stroke="black" stroke-width="0.5" />
    <path d="M6.12003 52.04L6.09003 52.1" stroke="black" stroke-width="0.5" />
    <path d="M6.00997 52.29L5.96997 52.39" stroke="black" stroke-width="0.5" />
    <path d="M5.96999 52.39L5.91998 52.51" stroke="black" stroke-width="0.5" />
    <path d="M5.92 52.51L5.88 52.63" stroke="black" stroke-width="0.5" />
    <path d="M5.87999 52.63L5.85999 52.68" stroke="black" stroke-width="0.5" />
    <path d="M5.79001 52.88L5.76001 52.99" stroke="black" stroke-width="0.5" />
    <path d="M5.75997 52.99L5.71997 53.11" stroke="black" stroke-width="0.5" />
    <path d="M5.72001 53.11L5.69 53.23" stroke="black" stroke-width="0.5" />
    <path d="M5.68998 53.23L5.66998 53.28" stroke="black" stroke-width="0.5" />
    <path d="M5.62003 53.48L5.59003 53.6" stroke="black" stroke-width="0.5" />
    <path d="M5.59001 53.6L5.57001 53.72" stroke="black" stroke-width="0.5" />
    <path d="M5.56998 53.72L5.53998 53.84" stroke="black" stroke-width="0.5" />
    <path d="M5.54003 53.84L5.53003 53.89" stroke="black" stroke-width="0.5" />
    <path d="M5.49998 54.1L5.47998 54.22" stroke="black" stroke-width="0.5" />
    <path d="M5.48002 54.22L5.46002 54.34" stroke="black" stroke-width="0.5" />
    <path d="M5.46001 54.34L5.45001 54.47" stroke="black" stroke-width="0.5" />
    <path d="M5.45 54.47L5.44 54.52" stroke="black" stroke-width="0.5" />
    <path d="M5.41998 54.72V54.85" stroke="black" stroke-width="0.5" />
    <path d="M5.41997 54.85L5.40997 54.97" stroke="black" stroke-width="0.5" />
    <path d="M5.41002 54.97L5.40002 55.1" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 55.1V55.14" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 55.35V55.48" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 55.48V55.6" stroke="black" stroke-width="0.5" />
    <path d="M5.40002 55.6L5.41002 55.73" stroke="black" stroke-width="0.5" />
    <path d="M5.40997 55.73V55.77" stroke="black" stroke-width="0.5" />
    <path d="M5.41998 55.98L5.43998 56.11" stroke="black" stroke-width="0.5" />
    <path d="M5.44 56.11L5.45 56.23" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 56.23L5.46001 56.36" stroke="black" stroke-width="0.5" />
    <path d="M5.46002 56.36L5.47002 56.4" stroke="black" stroke-width="0.5" />
    <path d="M5.5 56.6V56.61" stroke="black" stroke-width="0.5" />
    <path d="M5.5 56.61L5.52 56.74" stroke="black" stroke-width="0.5" />
    <path d="M5.52002 56.74L5.54002 56.86" stroke="black" stroke-width="0.5" />
    <path d="M5.53998 56.86L5.56998 56.98" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 56.98V57.02" stroke="black" stroke-width="0.5" />
    <path d="M5.62 57.22V57.23" stroke="black" stroke-width="0.5" />
    <path d="M5.62 57.23L5.65 57.35" stroke="black" stroke-width="0.5" />
    <path d="M5.65002 57.35L5.69002 57.47" stroke="black" stroke-width="0.5" />
    <path d="M5.69 57.47L5.72001 57.6" stroke="black" stroke-width="0.5" />
    <path d="M5.71997 57.6L5.72997 57.63" stroke="black" stroke-width="0.5" />
    <path d="M5.78998 57.83V57.84" stroke="black" stroke-width="0.5" />
    <path d="M5.78998 57.84L5.83998 57.96" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 57.96L5.88003 58.08" stroke="black" stroke-width="0.5" />
    <path d="M5.88 58.08L5.92 58.2" stroke="black" stroke-width="0.5" />
    <path d="M5.91998 58.2L5.92999 58.22" stroke="black" stroke-width="0.5" />
    <path d="M6.01001 58.42V58.43" stroke="black" stroke-width="0.5" />
    <path d="M6.01001 58.43L6.06001 58.55" stroke="black" stroke-width="0.5" />
    <path d="M6.06 58.55L6.12 58.66" stroke="black" stroke-width="0.5" />
    <path d="M6.12 58.66L6.16999 58.78" stroke="black" stroke-width="0.5" />
    <path d="M6.16998 58.78L6.17999 58.8" stroke="black" stroke-width="0.5" />
    <path d="M6.27002 58.99L6.28002 59" stroke="black" stroke-width="0.5" />
    <path d="M6.28003 59L6.34003 59.12" stroke="black" stroke-width="0.5" />
    <path d="M6.34003 59.12L6.40003 59.23" stroke="black" stroke-width="0.5" />
    <path d="M6.40002 59.23L6.46002 59.34" stroke="black" stroke-width="0.5" />
    <path d="M6.46002 59.34L6.47002 59.35" stroke="black" stroke-width="0.5" />
    <path d="M6.58002 59.53L6.59002 59.55" stroke="black" stroke-width="0.5" />
    <path d="M6.59003 59.55L6.66003 59.66" stroke="black" stroke-width="0.5" />
    <path d="M6.65997 59.66L6.72997 59.77" stroke="black" stroke-width="0.5" />
    <path d="M6.72998 59.77L6.79998 59.87" stroke="black" stroke-width="0.5" />
    <path d="M6.79999 59.87L6.80999 59.89" stroke="black" stroke-width="0.5" />
    <path d="M6.92999 60.06L6.93999 60.08" stroke="black" stroke-width="0.5" />
    <path d="M6.94 60.08L7.02 60.18" stroke="black" stroke-width="0.5" />
    <path d="M7.02002 60.18L7.10002 60.28" stroke="black" stroke-width="0.5" />
    <path d="M7.09998 60.28L7.16998 60.38" stroke="black" stroke-width="0.5" />
    <path d="M7.16998 60.38L7.17999 60.39" stroke="black" stroke-width="0.5" />
    <path d="M7.32001 60.55L7.34001 60.57" stroke="black" stroke-width="0.5" />
    <path d="M7.34003 60.57L7.42002 60.67" stroke="black" stroke-width="0.5" />
    <path d="M7.41998 60.67L7.49998 60.76" stroke="black" stroke-width="0.5" />
    <path d="M7.5 60.76L7.59 60.85" stroke="black" stroke-width="0.5" />
    <path d="M7.59003 60.85L7.60003 60.86" stroke="black" stroke-width="0.5" />
    <path d="M7.73999 61.01L7.76999 61.04" stroke="black" stroke-width="0.5" />
    <path d="M7.77002 61.04L7.86002 61.12" stroke="black" stroke-width="0.5" />
    <path d="M7.85999 61.12L7.94999 61.21" stroke="black" stroke-width="0.5" />
    <path d="M7.95001 61.21L8.04001 61.3" stroke="black" stroke-width="0.5" />
    <path d="M8.03998 61.3H8.04998" stroke="black" stroke-width="0.5" />
    <path d="M8.21002 61.44L8.23002 61.46" stroke="black" stroke-width="0.5" />
    <path d="M8.22998 61.46L8.32998 61.54" stroke="black" stroke-width="0.5" />
    <path d="M8.33002 61.54L8.43002 61.62" stroke="black" stroke-width="0.5" />
    <path d="M8.42999 61.62L8.52999 61.7" stroke="black" stroke-width="0.5" />
    <path d="M8.70001 61.83L8.73001 61.85" stroke="black" stroke-width="0.5" />
    <path d="M8.72998 61.85L8.82998 61.92" stroke="black" stroke-width="0.5" />
    <path d="M8.83002 61.92L8.94001 62" stroke="black" stroke-width="0.5" />
    <path d="M8.94 62L9.04 62.07" stroke="black" stroke-width="0.5" />
    <path d="M9.21997 62.18L9.25997 62.2" stroke="black" stroke-width="0.5" />
    <path d="M9.26001 62.2L9.37001 62.26" stroke="black" stroke-width="0.5" />
    <path d="M9.37 62.26L9.48 62.33" stroke="black" stroke-width="0.5" />
    <path d="M9.47998 62.33L9.57998 62.39" stroke="black" stroke-width="0.5" />
    <path d="M9.77002 62.49L9.81002 62.51" stroke="black" stroke-width="0.5" />
    <path d="M9.81 62.51L9.91999 62.56" stroke="black" stroke-width="0.5" />
    <path d="M9.91998 62.56L10.04 62.62" stroke="black" stroke-width="0.5" />
    <path d="M10.04 62.62L10.15 62.67" stroke="black" stroke-width="0.5" />
    <path d="M10.34 62.75L10.39 62.77" stroke="black" stroke-width="0.5" />
    <path d="M10.39 62.77L10.5 62.81" stroke="black" stroke-width="0.5" />
    <path d="M10.5 62.81L10.62 62.86" stroke="black" stroke-width="0.5" />
    <path d="M10.62 62.86L10.73 62.9" stroke="black" stroke-width="0.5" />
    <path d="M10.93 62.97L10.98 62.98" stroke="black" stroke-width="0.5" />
    <path d="M10.98 62.98L11.1 63.02" stroke="black" stroke-width="0.5" />
    <path d="M11.1 63.02L11.22 63.05" stroke="black" stroke-width="0.5" />
    <path d="M11.22 63.05L11.33 63.09" stroke="black" stroke-width="0.5" />
    <path d="M11.54 63.14L11.59 63.15" stroke="black" stroke-width="0.5" />
    <path d="M11.59 63.15L11.71 63.18" stroke="black" stroke-width="0.5" />
    <path d="M11.71 63.18L11.84 63.2" stroke="black" stroke-width="0.5" />
    <path d="M11.84 63.2L11.95 63.22" stroke="black" stroke-width="0.5" />
    <path d="M12.15 63.26L12.21 63.27" stroke="black" stroke-width="0.5" />
    <path d="M12.21 63.27L12.33 63.29" stroke="black" stroke-width="0.5" />
    <path d="M12.33 63.29L12.46 63.3" stroke="black" stroke-width="0.5" />
    <path d="M12.46 63.3L12.57 63.31" stroke="black" stroke-width="0.5" />
    <path d="M12.78 63.33L12.84 63.34" stroke="black" stroke-width="0.5" />
    <path d="M12.84 63.34L12.96 63.35" stroke="black" stroke-width="0.5" />
    <path d="M12.96 63.35H13.09" stroke="black" stroke-width="0.5" />
    <path d="M13.09 63.35L13.2 63.36" stroke="black" stroke-width="0.5" />
    <path d="M13.41 63.36H13.47" stroke="black" stroke-width="0.5" />
    <path d="M13.47 63.36H13.59" stroke="black" stroke-width="0.5" />
    <path d="M13.59 63.36L13.72 63.35" stroke="black" stroke-width="0.5" />
    <path d="M13.72 63.35H13.82" stroke="black" stroke-width="0.5" />
    <path d="M14.03 63.33H14.1" stroke="black" stroke-width="0.5" />
    <path d="M14.1 63.33L14.23 63.32" stroke="black" stroke-width="0.5" />
    <path d="M14.23 63.32L14.35 63.3" stroke="black" stroke-width="0.5" />
    <path d="M14.35 63.3L14.45 63.29" stroke="black" stroke-width="0.5" />
    <path d="M14.66 63.26L14.73 63.25" stroke="black" stroke-width="0.5" />
    <path d="M14.73 63.25L14.85 63.23" stroke="black" stroke-width="0.5" />
    <path d="M14.85 63.23L14.97 63.2" stroke="black" stroke-width="0.5" />
    <path d="M14.97 63.2L15.07 63.18" stroke="black" stroke-width="0.5" />
    <path d="M15.27 63.14L15.34 63.12" stroke="black" stroke-width="0.5" />
    <path d="M15.34 63.12L15.47 63.09" stroke="black" stroke-width="0.5" />
    <path d="M15.47 63.09L15.59 63.05" stroke="black" stroke-width="0.5" />
    <path d="M15.59 63.05L15.68 63.03" stroke="black" stroke-width="0.5" />
    <path d="M15.88 62.97L15.95 62.94" stroke="black" stroke-width="0.5" />
    <path d="M15.95 62.94L16.07 62.9" stroke="black" stroke-width="0.5" />
    <path d="M16.07 62.9L16.19 62.86" stroke="black" stroke-width="0.5" />
    <path d="M16.19 62.86L16.27 62.83" stroke="black" stroke-width="0.5" />
    <path d="M16.47 62.75L16.54 62.72" stroke="black" stroke-width="0.5" />
    <path d="M16.54 62.72L16.66 62.67" stroke="black" stroke-width="0.5" />
    <path d="M16.66 62.67L16.77 62.62" stroke="black" stroke-width="0.5" />
    <path d="M16.77 62.62L16.85 62.58" stroke="black" stroke-width="0.5" />
    <path d="M17.04 62.49L17.11 62.45" stroke="black" stroke-width="0.5" />
    <path d="M17.11 62.45L17.22 62.39" stroke="black" stroke-width="0.5" />
    <path d="M17.22 62.39L17.33 62.33" stroke="black" stroke-width="0.5" />
    <path d="M17.33 62.33L17.41 62.29" stroke="black" stroke-width="0.5" />
    <path d="M17.59 62.18L17.66 62.13" stroke="black" stroke-width="0.5" />
    <path d="M17.66 62.13L17.77 62.07" stroke="black" stroke-width="0.5" />
    <path d="M17.77 62.07L17.87 62" stroke="black" stroke-width="0.5" />
    <path d="M17.87 62L17.94 61.95" stroke="black" stroke-width="0.5" />
    <path d="M18.11 61.83L18.18 61.78" stroke="black" stroke-width="0.5" />
    <path d="M18.18 61.78L18.28 61.7" stroke="black" stroke-width="0.5" />
    <path d="M18.28 61.7L18.38 61.62" stroke="black" stroke-width="0.5" />
    <path d="M18.38 61.62L18.44 61.57" stroke="black" stroke-width="0.5" />
    <path d="M18.6 61.44L18.67 61.38" stroke="black" stroke-width="0.5" />
    <path d="M18.67 61.38L18.77 61.3" stroke="black" stroke-width="0.5" />
    <path d="M18.77 61.3L18.86 61.21" stroke="black" stroke-width="0.5" />
    <path d="M18.86 61.21L18.92 61.16" stroke="black" stroke-width="0.5" />
    <path d="M19.07 61.01L19.13 60.95" stroke="black" stroke-width="0.5" />
    <path d="M19.13 60.95L19.22 60.85" stroke="black" stroke-width="0.5" />
    <path d="M19.22 60.85L19.31 60.76" stroke="black" stroke-width="0.5" />
    <path d="M19.31 60.76L19.35 60.71" stroke="black" stroke-width="0.5" />
    <path d="M19.49 60.55L19.56 60.48" stroke="black" stroke-width="0.5" />
    <path d="M19.56 60.48L19.64 60.38" stroke="black" stroke-width="0.5" />
    <path d="M19.64 60.38L19.71 60.28" stroke="black" stroke-width="0.5" />
    <path d="M19.71 60.28L19.76 60.23" stroke="black" stroke-width="0.5" />
    <path d="M19.88 60.06L19.94 59.98" stroke="black" stroke-width="0.5" />
    <path d="M19.94 59.98L20.01 59.87" stroke="black" stroke-width="0.5" />
    <path d="M20.01 59.87L20.08 59.77" stroke="black" stroke-width="0.5" />
    <path d="M20.08 59.77L20.12 59.71" stroke="black" stroke-width="0.5" />
    <path d="M20.23 59.53L20.29 59.45" stroke="black" stroke-width="0.5" />
    <path d="M20.29 59.45L20.35 59.34" stroke="black" stroke-width="0.5" />
    <path d="M20.35 59.34L20.41 59.23" stroke="black" stroke-width="0.5" />
    <path d="M20.41 59.23L20.44 59.17" stroke="black" stroke-width="0.5" />
    <path d="M20.54 58.99L20.59 58.89" stroke="black" stroke-width="0.5" />
    <path d="M20.59 58.89L20.64 58.78" stroke="black" stroke-width="0.5" />
    <path d="M20.64 58.78L20.69 58.66" stroke="black" stroke-width="0.5" />
    <path d="M20.69 58.66L20.72 58.61" stroke="black" stroke-width="0.5" />
    <path d="M20.8 58.42L20.84 58.31" stroke="black" stroke-width="0.5" />
    <path d="M20.84 58.31L20.89 58.2" stroke="black" stroke-width="0.5" />
    <path d="M20.89 58.2L20.93 58.08" stroke="black" stroke-width="0.5" />
    <path d="M20.93 58.08L20.95 58.02" stroke="black" stroke-width="0.5" />
    <path d="M21.02 57.83L21.05 57.72" stroke="black" stroke-width="0.5" />
    <path d="M21.05 57.72L21.09 57.6" stroke="black" stroke-width="0.5" />
    <path d="M21.09 57.6L21.12 57.47" stroke="black" stroke-width="0.5" />
    <path d="M21.12 57.47L21.14 57.42" stroke="black" stroke-width="0.5" />
    <path d="M21.19 57.22L21.22 57.11" stroke="black" stroke-width="0.5" />
    <path d="M21.22 57.11L21.24 56.98" stroke="black" stroke-width="0.5" />
    <path d="M21.24 56.98L21.27 56.86" stroke="black" stroke-width="0.5" />
    <path d="M21.27 56.86L21.28 56.81" stroke="black" stroke-width="0.5" />
    <path d="M21.31 56.6L21.33 56.49" stroke="black" stroke-width="0.5" />
    <path d="M21.33 56.49L21.35 56.36" stroke="black" stroke-width="0.5" />
    <path d="M21.35 56.36L21.36 56.23" stroke="black" stroke-width="0.5" />
    <path d="M21.36 56.23L21.37 56.19" stroke="black" stroke-width="0.5" />
    <path d="M21.39 55.98V55.86" stroke="black" stroke-width="0.5" />
    <path d="M21.39 55.86L21.4 55.73" stroke="black" stroke-width="0.5" />
    <path d="M21.4 55.73L21.41 55.6" stroke="black" stroke-width="0.5" />
    <path d="M21.41 55.6V55.56" stroke="black" stroke-width="0.5" />
    <path d="M10.1 45.08V44.63" stroke="black" stroke-width="0.5" />
    <path d="M8.78003 45.08H10.1" stroke="black" stroke-width="0.5" />
    <path d="M8.78003 44.63V45.08" stroke="black" stroke-width="0.5" />
    <path d="M10.1 44.63H8.78003" stroke="black" stroke-width="0.5" />
    <path d="M11.68 48.28L11.59 48.66" stroke="black" stroke-width="0.5" />
    <path d="M11.59 48.66L11.36 48.95" stroke="black" stroke-width="0.5" />
    <path d="M11.36 48.95L11.04 49.05" stroke="black" stroke-width="0.5" />
    <path d="M7.84002 49.05L7.52002 48.95" stroke="black" stroke-width="0.5" />
    <path d="M7.51998 48.95L7.28998 48.66" stroke="black" stroke-width="0.5" />
    <path d="M7.29001 48.66L7.20001 48.28" stroke="black" stroke-width="0.5" />
    <path d="M7.20001 46.37L7.29001 45.99" stroke="black" stroke-width="0.5" />
    <path d="M7.28998 45.99L7.51998 45.7" stroke="black" stroke-width="0.5" />
    <path d="M7.52002 45.7L7.84002 45.6" stroke="black" stroke-width="0.5" />
    <path d="M11.04 45.6L11.36 45.7" stroke="black" stroke-width="0.5" />
    <path d="M11.36 45.7L11.59 45.99" stroke="black" stroke-width="0.5" />
    <path d="M11.59 45.99L11.68 46.37" stroke="black" stroke-width="0.5" />
    <path d="M11.68 48.28V46.37" stroke="black" stroke-width="0.5" />
    <path d="M7.84003 49.05H11.04" stroke="black" stroke-width="0.5" />
    <path d="M7.20001 46.37V48.28" stroke="black" stroke-width="0.5" />
    <path d="M11.04 45.6H7.84003" stroke="black" stroke-width="0.5" />
    <path d="M12.12 49.5V44.14" stroke="black" stroke-width="0.5" />
    <path d="M6.76001 49.5H12.12" stroke="black" stroke-width="0.5" />
    <path d="M6.76001 44.14V49.5" stroke="black" stroke-width="0.5" />
    <path d="M12.12 44.14H6.76001" stroke="black" stroke-width="0.5" />
    <path d="M15.39 44.14H18.15" stroke="black" stroke-width="0.5" />
    <path d="M18.15 44.14V45.38" stroke="black" stroke-width="0.5" />
    <path d="M18.15 45.38H15.39" stroke="black" stroke-width="0.5" />
    <path d="M15.39 45.38V44.14" stroke="black" stroke-width="0.5" />
    <path d="M22.21 45.38H19.89" stroke="black" stroke-width="0.5" />
    <path d="M19.89 45.38V49.42" stroke="black" stroke-width="0.5" />
    <path d="M19.89 49.42H22.21" stroke="black" stroke-width="0.5" />
    <path d="M22.21 49.42V45.38" stroke="black" stroke-width="0.5" />
    <path d="M37.49 106.86H36.42" stroke="black" stroke-width="0.5" />
    <path d="M26.81 106.86H22.81" stroke="black" stroke-width="0.5" />
    <path d="M36.96 105.52V106.86" stroke="black" stroke-width="0.5" />
    <path d="M36.42 105.52V106.86" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 105.52H26.81" stroke="black" stroke-width="0.5" />
    <path d="M36.42 105.52H37.49" stroke="black" stroke-width="0.5" />
    <path d="M26.81 105.52V106.86" stroke="black" stroke-width="0.5" />
    <path d="M26.28 105.52V106.86" stroke="black" stroke-width="0.5" />
    <path d="M16.76 109.83V110.24" stroke="black" stroke-width="0.5" />
    <path d="M17.97 109.83H16.76" stroke="black" stroke-width="0.5" />
    <path d="M17.97 110.24V109.83" stroke="black" stroke-width="0.5" />
    <path d="M16.76 110.24H17.97" stroke="black" stroke-width="0.5" />
    <path d="M15.3 106.87L15.38 106.52" stroke="black" stroke-width="0.5" />
    <path d="M15.38 106.52L15.6 106.26" stroke="black" stroke-width="0.5" />
    <path d="M15.6 106.26L15.89 106.16" stroke="black" stroke-width="0.5" />
    <path d="M18.84 106.16L19.13 106.26" stroke="black" stroke-width="0.5" />
    <path d="M19.13 106.26L19.35 106.52" stroke="black" stroke-width="0.5" />
    <path d="M19.35 106.52L19.43 106.87" stroke="black" stroke-width="0.5" />
    <path d="M19.43 108.63L19.35 108.99" stroke="black" stroke-width="0.5" />
    <path d="M19.35 108.99L19.13 109.25" stroke="black" stroke-width="0.5" />
    <path d="M19.13 109.25L18.84 109.34" stroke="black" stroke-width="0.5" />
    <path d="M15.89 109.34L15.6 109.25" stroke="black" stroke-width="0.5" />
    <path d="M15.6 109.25L15.38 108.99" stroke="black" stroke-width="0.5" />
    <path d="M15.38 108.99L15.3 108.63" stroke="black" stroke-width="0.5" />
    <path d="M15.3 106.87V108.63" stroke="black" stroke-width="0.5" />
    <path d="M18.84 106.16H15.89" stroke="black" stroke-width="0.5" />
    <path d="M19.43 108.63V106.87" stroke="black" stroke-width="0.5" />
    <path d="M15.89 109.34H18.84" stroke="black" stroke-width="0.5" />
    <path d="M14.9 105.75V110.68" stroke="black" stroke-width="0.5" />
    <path d="M19.83 105.75H14.9" stroke="black" stroke-width="0.5" />
    <path d="M19.83 110.68V105.75" stroke="black" stroke-width="0.5" />
    <path d="M14.9 110.68H19.83" stroke="black" stroke-width="0.5" />
    <path d="M8.35999 113.33H22.81" stroke="black" stroke-width="0.5" />
    <path d="M8.35999 113.33V115.46" stroke="black" stroke-width="0.5" />
    <path d="M8.35998 115.46H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 114.66V115.46" stroke="black" stroke-width="0.5" />
    <path d="M37.49 113.33H104.2" stroke="black" stroke-width="0.5" />
    <path d="M104.2 114.66H110.61" stroke="black" stroke-width="0.5" />
    <path d="M110.61 113.33H129.29" stroke="black" stroke-width="0.5" />
    <path d="M152.78 113.33H206.58" stroke="black" stroke-width="0.5" />
    <path d="M212.98 113.33H274.64" stroke="black" stroke-width="0.5" />
    <path d="M91.4 111.99V113.33" stroke="black" stroke-width="0.5" />
    <path d="M101 111.99V113.33" stroke="black" stroke-width="0.5" />
    <path d="M104.2 113.33V114.66" stroke="black" stroke-width="0.5" />
    <path d="M110.61 113.33V114.66" stroke="black" stroke-width="0.5" />
    <path d="M129.29 111.99V113.33" stroke="black" stroke-width="0.5" />
    <path d="M206.58 114.4H212.98" stroke="black" stroke-width="0.5" />
    <path d="M206.58 113.33V114.4" stroke="black" stroke-width="0.5" />
    <path d="M212.98 113.33V114.4" stroke="black" stroke-width="0.5" />
    <path d="M274.64 111.99V113.33" stroke="black" stroke-width="0.5" />
    <path d="M274.64 111.99H169.55" stroke="black" stroke-width="0.5" />
    <path d="M168.22 111.99H155.38" stroke="black" stroke-width="0.5" />
    <path d="M154.04 111.99H152.78" stroke="black" stroke-width="0.5" />
    <path d="M247.71 110.39H209.28" stroke="black" stroke-width="0.5" />
    <path d="M207.95 110.39H169.55" stroke="black" stroke-width="0.5" />
    <path d="M129.29 111.99H103.14" stroke="black" stroke-width="0.5" />
    <path d="M101.8 111.99H71.91" stroke="black" stroke-width="0.5" />
    <path d="M70.58 111.99H38.82" stroke="black" stroke-width="0.5" />
    <path d="M152.78 111.99V113.33" stroke="black" stroke-width="0.5" />
    <path d="M6.75999 113.33H4.42999" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 4.41V114.66" stroke="black" stroke-width="0.5" />
    <path d="M15.84 4.41H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M45.19 4.41H67.08" stroke="black" stroke-width="0.5" />
    <path d="M147.67 4.41H169.55" stroke="black" stroke-width="0.5" />
    <path d="M198.91 4.41H201.68" stroke="black" stroke-width="0.5" />
    <path d="M201.68 4.41V7.62" stroke="black" stroke-width="0.5" />
    <path d="M201.68 7.62H220.79" stroke="black" stroke-width="0.5" />
    <path d="M250.14 7.62H272.03" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 111.99H22.81" stroke="black" stroke-width="0.5" />
    <path d="M6.76001 114.66V111.99" stroke="black" stroke-width="0.5" />
    <path d="M36.87 86.13H37.49" stroke="black" stroke-width="0.5" />
    <path d="M37.49 86.13V94.95" stroke="black" stroke-width="0.5" />
    <path d="M37.49 94.95H36.87" stroke="black" stroke-width="0.5" />
    <path d="M36.87 94.95V86.13" stroke="black" stroke-width="0.5" />
    <path d="M245.67 7.62V8.94999" stroke="black" stroke-width="0.5" />
    <path d="M245.67 8.95001H242.64" stroke="black" stroke-width="0.5" />
    <path d="M75.61 63.01H80.56" stroke="black" stroke-width="0.5" />
    <path d="M80.56 63.01V63.89" stroke="black" stroke-width="0.5" />
    <path d="M80.56 63.89H75.61" stroke="black" stroke-width="0.5" />
    <path d="M75.61 63.89V63.01" stroke="black" stroke-width="0.5" />
    <path d="M76.04 63.89H80.01" stroke="black" stroke-width="0.5" />
    <path d="M80.01 63.89V64.66" stroke="black" stroke-width="0.5" />
    <path d="M80.01 64.66H76.04" stroke="black" stroke-width="0.5" />
    <path d="M76.04 64.66V63.89" stroke="black" stroke-width="0.5" />
    <path d="M219.94 7.62V8.41999" stroke="black" stroke-width="0.5" />
    <path d="M219.94 8.41998H221.45" stroke="black" stroke-width="0.5" />
    <path d="M222.28 8.41998H223.95" stroke="black" stroke-width="0.5" />
    <path d="M224.79 8.41998H226.45" stroke="black" stroke-width="0.5" />
    <path d="M227.29 8.41998H228.96" stroke="black" stroke-width="0.5" />
    <path d="M229.79 8.41998H231.46" stroke="black" stroke-width="0.5" />
    <path d="M232.29 8.41998H233.96" stroke="black" stroke-width="0.5" />
    <path d="M234.79 8.41998H236.46" stroke="black" stroke-width="0.5" />
    <path d="M237.29 8.41998H238.96" stroke="black" stroke-width="0.5" />
    <path d="M239.8 8.41998H241.31" stroke="black" stroke-width="0.5" />
    <path d="M245.67 8.41998H246.66" stroke="black" stroke-width="0.5" />
    <path d="M247.5 8.41998H249.16" stroke="black" stroke-width="0.5" />
    <path d="M250 8.41998H250.99" stroke="black" stroke-width="0.5" />
    <path d="M250.99 7.62V8.41999" stroke="black" stroke-width="0.5" />
    <path d="M220.16 63.01H225.12" stroke="black" stroke-width="0.5" />
    <path d="M225.12 63.01V63.89" stroke="black" stroke-width="0.5" />
    <path d="M225.12 63.89H220.16" stroke="black" stroke-width="0.5" />
    <path d="M220.16 63.89V63.01" stroke="black" stroke-width="0.5" />
    <path d="M220.59 63.89H224.57" stroke="black" stroke-width="0.5" />
    <path d="M224.57 63.89V64.66" stroke="black" stroke-width="0.5" />
    <path d="M224.57 64.66H220.59" stroke="black" stroke-width="0.5" />
    <path d="M220.59 64.66V63.89" stroke="black" stroke-width="0.5" />
    <path d="M169.55 5.22H170.48" stroke="black" stroke-width="0.5" />
    <path d="M171.31 5.22H172.98" stroke="black" stroke-width="0.5" />
    <path d="M173.81 5.22H175.48" stroke="black" stroke-width="0.5" />
    <path d="M176.31 5.22H177.98" stroke="black" stroke-width="0.5" />
    <path d="M178.82 5.22H180.48" stroke="black" stroke-width="0.5" />
    <path d="M181.32 5.22H182.99" stroke="black" stroke-width="0.5" />
    <path d="M183.82 5.22H185.49" stroke="black" stroke-width="0.5" />
    <path d="M186.32 5.22H187.99" stroke="black" stroke-width="0.5" />
    <path d="M188.82 5.22H190.49" stroke="black" stroke-width="0.5" />
    <path d="M191.33 5.22H192.99" stroke="black" stroke-width="0.5" />
    <path d="M193.83 5.22H195.5" stroke="black" stroke-width="0.5" />
    <path d="M196.33 5.22H198" stroke="black" stroke-width="0.5" />
    <path d="M198.83 5.22H199.76" stroke="black" stroke-width="0.5" />
    <path d="M199.76 4.41V5.22" stroke="black" stroke-width="0.5" />
    <path d="M0.429993 111.16V115.46" stroke="black" stroke-width="0.5" />
    <path d="M4.59999 115.46H0.429993" stroke="black" stroke-width="0.5" />
    <path d="M4.60002 110.1H2.46002" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 4.41H15.84" stroke="black" stroke-width="0.5" />
    <path d="M115.51 46.54L115.33 44.67" stroke="black" stroke-width="0.5" />
    <path d="M115.33 44.67L114.78 42.87" stroke="black" stroke-width="0.5" />
    <path d="M114.78 42.87L113.89 41.2" stroke="black" stroke-width="0.5" />
    <path d="M113.89 41.2L112.69 39.75" stroke="black" stroke-width="0.5" />
    <path d="M112.69 39.75L111.24 38.56" stroke="black" stroke-width="0.5" />
    <path d="M111.24 38.56L109.58 37.67" stroke="black" stroke-width="0.5" />
    <path d="M109.58 37.67L107.77 37.12" stroke="black" stroke-width="0.5" />
    <path d="M107.77 37.12L105.9 36.94" stroke="black" stroke-width="0.5" />
    <path d="M105.9 46.55H106.37" stroke="black" stroke-width="0.5" />
    <path d="M106.37 46.55V36.95" stroke="black" stroke-width="0.5" />
    <path d="M105.9 36.94V46.55" stroke="black" stroke-width="0.5" />
    <path d="M60.21 46.54L60.39 44.67" stroke="black" stroke-width="0.5" />
    <path d="M60.39 44.67L60.94 42.87" stroke="black" stroke-width="0.5" />
    <path d="M60.94 42.87L61.83 41.2" stroke="black" stroke-width="0.5" />
    <path d="M61.83 41.2L63.02 39.75" stroke="black" stroke-width="0.5" />
    <path d="M63.02 39.75L64.48 38.56" stroke="black" stroke-width="0.5" />
    <path d="M64.48 38.56L66.14 37.67" stroke="black" stroke-width="0.5" />
    <path d="M66.14 37.67L67.94 37.12" stroke="black" stroke-width="0.5" />
    <path d="M67.94 37.12L69.82 36.94" stroke="black" stroke-width="0.5" />
    <path d="M69.81 46.55H69.35" stroke="black" stroke-width="0.5" />
    <path d="M69.35 46.55V36.95" stroke="black" stroke-width="0.5" />
    <path d="M69.82 36.94L69.81 46.55" stroke="black" stroke-width="0.5" />
    <path d="M26.94 42.81L27.13 40.94" stroke="black" stroke-width="0.5" />
    <path d="M27.13 40.94L27.68 39.13" stroke="black" stroke-width="0.5" />
    <path d="M27.68 39.13L28.56 37.47" stroke="black" stroke-width="0.5" />
    <path d="M28.56 37.47L29.76 36.02" stroke="black" stroke-width="0.5" />
    <path d="M29.76 36.02L31.22 34.82" stroke="black" stroke-width="0.5" />
    <path d="M31.22 34.82L32.88 33.94" stroke="black" stroke-width="0.5" />
    <path d="M32.88 33.94L34.68 33.39" stroke="black" stroke-width="0.5" />
    <path d="M34.68 33.39L36.55 33.21" stroke="black" stroke-width="0.5" />
    <path d="M36.55 42.81H36.08" stroke="black" stroke-width="0.5" />
    <path d="M36.08 42.81L36.09 33.22" stroke="black" stroke-width="0.5" />
    <path d="M36.55 33.21V42.81" stroke="black" stroke-width="0.5" />
    <path d="M92.5 46.54L92.68 44.67" stroke="black" stroke-width="0.5" />
    <path d="M92.68 44.67L93.23 42.87" stroke="black" stroke-width="0.5" />
    <path d="M93.23 42.87L94.12 41.2" stroke="black" stroke-width="0.5" />
    <path d="M94.12 41.2L95.31 39.75" stroke="black" stroke-width="0.5" />
    <path d="M95.31 39.75L96.77 38.56" stroke="black" stroke-width="0.5" />
    <path d="M96.77 38.56L98.43 37.67" stroke="black" stroke-width="0.5" />
    <path d="M98.43 37.67L100.23 37.12" stroke="black" stroke-width="0.5" />
    <path d="M100.23 37.12L102.11 36.94" stroke="black" stroke-width="0.5" />
    <path d="M102.1 46.55H101.64" stroke="black" stroke-width="0.5" />
    <path d="M101.64 46.55V36.95" stroke="black" stroke-width="0.5" />
    <path d="M102.11 36.94L102.1 46.55" stroke="black" stroke-width="0.5" />
    <path d="M147 46.54L146.82 44.67" stroke="black" stroke-width="0.5" />
    <path d="M146.82 44.67L146.27 42.87" stroke="black" stroke-width="0.5" />
    <path d="M146.27 42.87L145.38 41.2" stroke="black" stroke-width="0.5" />
    <path d="M145.38 41.2L144.18 39.75" stroke="black" stroke-width="0.5" />
    <path d="M144.18 39.75L142.73 38.56" stroke="black" stroke-width="0.5" />
    <path d="M142.73 38.56L141.07 37.67" stroke="black" stroke-width="0.5" />
    <path d="M141.07 37.67L139.26 37.12" stroke="black" stroke-width="0.5" />
    <path d="M139.26 37.12L137.39 36.94" stroke="black" stroke-width="0.5" />
    <path d="M137.39 46.55H137.86" stroke="black" stroke-width="0.5" />
    <path d="M137.86 46.55V36.95" stroke="black" stroke-width="0.5" />
    <path d="M137.39 36.94V46.55" stroke="black" stroke-width="0.5" />
    <path d="M194.17 46.54L194.36 44.67" stroke="black" stroke-width="0.5" />
    <path d="M194.36 44.67L194.91 42.87" stroke="black" stroke-width="0.5" />
    <path d="M194.91 42.87L195.79 41.2" stroke="black" stroke-width="0.5" />
    <path d="M195.79 41.2L196.99 39.75" stroke="black" stroke-width="0.5" />
    <path d="M196.99 39.75L198.45 38.56" stroke="black" stroke-width="0.5" />
    <path d="M198.45 38.56L200.11 37.67" stroke="black" stroke-width="0.5" />
    <path d="M200.11 37.67L201.91 37.12" stroke="black" stroke-width="0.5" />
    <path d="M201.91 37.12L203.78 36.94" stroke="black" stroke-width="0.5" />
    <path d="M203.78 46.55H203.31" stroke="black" stroke-width="0.5" />
    <path d="M203.31 46.55L203.32 36.95" stroke="black" stroke-width="0.5" />
    <path d="M203.78 36.94V46.55" stroke="black" stroke-width="0.5" />
    <path d="M230.47 46.54L230.65 44.67" stroke="black" stroke-width="0.5" />
    <path d="M230.65 44.67L231.2 42.87" stroke="black" stroke-width="0.5" />
    <path d="M231.2 42.87L232.09 41.2" stroke="black" stroke-width="0.5" />
    <path d="M232.09 41.2L233.28 39.75" stroke="black" stroke-width="0.5" />
    <path d="M233.28 39.75L234.74 38.56" stroke="black" stroke-width="0.5" />
    <path d="M234.74 38.56L236.4 37.67" stroke="black" stroke-width="0.5" />
    <path d="M236.4 37.67L238.2 37.12" stroke="black" stroke-width="0.5" />
    <path d="M238.2 37.12L240.08 36.94" stroke="black" stroke-width="0.5" />
    <path d="M240.07 46.55H239.61" stroke="black" stroke-width="0.5" />
    <path d="M239.61 46.55V36.95" stroke="black" stroke-width="0.5" />
    <path d="M240.08 36.94L240.07 46.55" stroke="black" stroke-width="0.5" />
    <path d="M253.48 46.54L253.29 44.67" stroke="black" stroke-width="0.5" />
    <path d="M253.29 44.67L252.75 42.87" stroke="black" stroke-width="0.5" />
    <path d="M252.75 42.87L251.86 41.2" stroke="black" stroke-width="0.5" />
    <path d="M251.86 41.2L250.66 39.75" stroke="black" stroke-width="0.5" />
    <path d="M250.66 39.75L249.21 38.56" stroke="black" stroke-width="0.5" />
    <path d="M249.21 38.56L247.54 37.67" stroke="black" stroke-width="0.5" />
    <path d="M247.54 37.67L245.74 37.12" stroke="black" stroke-width="0.5" />
    <path d="M245.74 37.12L243.87 36.94" stroke="black" stroke-width="0.5" />
    <path d="M243.87 46.55H244.34" stroke="black" stroke-width="0.5" />
    <path d="M244.34 46.55L244.33 36.95" stroke="black" stroke-width="0.5" />
    <path d="M243.87 36.94V46.55" stroke="black" stroke-width="0.5" />
    <path d="M280.17 46.55H280.63" stroke="black" stroke-width="0.5" />
    <path d="M197.11 65.23L197.29 67.1" stroke="black" stroke-width="0.5" />
    <path d="M197.29 67.1L197.84 68.91" stroke="black" stroke-width="0.5" />
    <path d="M197.84 68.91L198.73 70.57" stroke="black" stroke-width="0.5" />
    <path d="M198.73 70.57L199.92 72.02" stroke="black" stroke-width="0.5" />
    <path d="M199.92 72.02L201.38 73.22" stroke="black" stroke-width="0.5" />
    <path d="M201.38 73.22L203.04 74.1" stroke="black" stroke-width="0.5" />
    <path d="M203.04 74.1L204.84 74.65" stroke="black" stroke-width="0.5" />
    <path d="M204.84 74.65L206.72 74.83" stroke="black" stroke-width="0.5" />
    <path d="M206.71 65.23H206.25" stroke="black" stroke-width="0.5" />
    <path d="M206.25 65.23V74.82" stroke="black" stroke-width="0.5" />
    <path d="M206.72 74.83L206.71 65.23" stroke="black" stroke-width="0.5" />
    <path d="M236.87 65.23L237.06 67.1" stroke="black" stroke-width="0.5" />
    <path d="M237.06 67.1L237.6 68.91" stroke="black" stroke-width="0.5" />
    <path d="M237.6 68.91L238.49 70.57" stroke="black" stroke-width="0.5" />
    <path d="M238.49 70.57L239.69 72.02" stroke="black" stroke-width="0.5" />
    <path d="M239.69 72.02L241.14 73.22" stroke="black" stroke-width="0.5" />
    <path d="M241.14 73.22L242.8 74.1" stroke="black" stroke-width="0.5" />
    <path d="M242.8 74.1L244.61 74.65" stroke="black" stroke-width="0.5" />
    <path d="M244.61 74.65L246.48 74.83" stroke="black" stroke-width="0.5" />
    <path d="M246.48 65.23H246.01" stroke="black" stroke-width="0.5" />
    <path d="M246.01 65.23V74.82" stroke="black" stroke-width="0.5" />
    <path d="M246.48 74.83V65.23" stroke="black" stroke-width="0.5" />
    <path d="M259.72 71.89L259.53 70.02" stroke="black" stroke-width="0.5" />
    <path d="M259.53 70.02L258.99 68.22" stroke="black" stroke-width="0.5" />
    <path d="M258.99 68.22L258.1 66.56" stroke="black" stroke-width="0.5" />
    <path d="M258.1 66.56L256.9 65.1" stroke="black" stroke-width="0.5" />
    <path d="M256.9 65.1L255.45 63.91" stroke="black" stroke-width="0.5" />
    <path d="M255.45 63.91L253.78 63.02" stroke="black" stroke-width="0.5" />
    <path d="M253.78 63.02L251.98 62.47" stroke="black" stroke-width="0.5" />
    <path d="M251.98 62.47L250.11 62.29" stroke="black" stroke-width="0.5" />
    <path d="M250.11 71.9H250.58" stroke="black" stroke-width="0.5" />
    <path d="M250.58 71.9L250.57 62.3" stroke="black" stroke-width="0.5" />
    <path d="M250.11 62.29V71.9" stroke="black" stroke-width="0.5" />
    <path d="M283.37 65.23H283.83" stroke="black" stroke-width="0.5" />
    <path d="M59.74 65.23L59.93 67.1" stroke="black" stroke-width="0.5" />
    <path d="M59.93 67.1L60.47 68.91" stroke="black" stroke-width="0.5" />
    <path d="M60.47 68.91L61.36 70.57" stroke="black" stroke-width="0.5" />
    <path d="M61.36 70.57L62.56 72.02" stroke="black" stroke-width="0.5" />
    <path d="M62.56 72.02L64.01 73.22" stroke="black" stroke-width="0.5" />
    <path d="M64.01 73.22L65.68 74.1" stroke="black" stroke-width="0.5" />
    <path d="M65.68 74.1L67.48 74.65" stroke="black" stroke-width="0.5" />
    <path d="M67.48 74.65L69.35 74.83" stroke="black" stroke-width="0.5" />
    <path d="M69.35 65.23H68.88" stroke="black" stroke-width="0.5" />
    <path d="M68.88 65.23V74.82" stroke="black" stroke-width="0.5" />
    <path d="M69.35 74.83V65.23" stroke="black" stroke-width="0.5" />
    <path d="M90.96 65.23L91.15 67.1" stroke="black" stroke-width="0.5" />
    <path d="M91.15 67.1L91.7 68.91" stroke="black" stroke-width="0.5" />
    <path d="M91.7 68.91L92.58 70.57" stroke="black" stroke-width="0.5" />
    <path d="M92.58 70.57L93.78 72.02" stroke="black" stroke-width="0.5" />
    <path d="M93.78 72.02L95.24 73.22" stroke="black" stroke-width="0.5" />
    <path d="M95.24 73.22L96.9 74.1" stroke="black" stroke-width="0.5" />
    <path d="M96.9 74.1L98.7 74.65" stroke="black" stroke-width="0.5" />
    <path d="M98.7 74.65L100.57 74.83" stroke="black" stroke-width="0.5" />
    <path d="M100.57 65.23H100.1" stroke="black" stroke-width="0.5" />
    <path d="M100.1 65.23L100.11 74.82" stroke="black" stroke-width="0.5" />
    <path d="M100.57 74.83V65.23" stroke="black" stroke-width="0.5" />
    <path d="M113.81 71.89L113.63 70.02" stroke="black" stroke-width="0.5" />
    <path d="M113.63 70.02L113.08 68.22" stroke="black" stroke-width="0.5" />
    <path d="M113.08 68.22L112.19 66.56" stroke="black" stroke-width="0.5" />
    <path d="M112.19 66.56L111 65.1" stroke="black" stroke-width="0.5" />
    <path d="M111 65.1L109.54 63.91" stroke="black" stroke-width="0.5" />
    <path d="M109.54 63.91L107.88 63.02" stroke="black" stroke-width="0.5" />
    <path d="M107.88 63.02L106.07 62.47" stroke="black" stroke-width="0.5" />
    <path d="M106.07 62.47L104.2 62.29" stroke="black" stroke-width="0.5" />
    <path d="M104.2 71.9H104.67" stroke="black" stroke-width="0.5" />
    <path d="M104.67 71.9V62.3" stroke="black" stroke-width="0.5" />
    <path d="M104.2 62.29V71.9" stroke="black" stroke-width="0.5" />
    <path d="M147.07 65.23L146.88 67.1" stroke="black" stroke-width="0.5" />
    <path d="M146.88 67.1L146.33 68.91" stroke="black" stroke-width="0.5" />
    <path d="M146.33 68.91L145.45 70.57" stroke="black" stroke-width="0.5" />
    <path d="M145.45 70.57L144.25 72.02" stroke="black" stroke-width="0.5" />
    <path d="M144.25 72.02L142.79 73.22" stroke="black" stroke-width="0.5" />
    <path d="M142.79 73.22L141.13 74.1" stroke="black" stroke-width="0.5" />
    <path d="M141.13 74.1L139.33 74.65" stroke="black" stroke-width="0.5" />
    <path d="M139.33 74.65L137.46 74.83" stroke="black" stroke-width="0.5" />
    <path d="M137.46 65.23H137.93" stroke="black" stroke-width="0.5" />
    <path d="M137.93 65.23L137.92 74.82" stroke="black" stroke-width="0.5" />
    <path d="M137.46 74.83V65.23" stroke="black" stroke-width="0.5" />
    <path d="M143.17 104.99L143.35 106.86" stroke="black" stroke-width="0.5" />
    <path d="M143.35 106.86L143.9 108.66" stroke="black" stroke-width="0.5" />
    <path d="M143.9 108.66L144.79 110.32" stroke="black" stroke-width="0.5" />
    <path d="M144.79 110.32L145.98 111.78" stroke="black" stroke-width="0.5" />
    <path d="M145.98 111.78L147.44 112.97" stroke="black" stroke-width="0.5" />
    <path d="M147.44 112.97L149.1 113.86" stroke="black" stroke-width="0.5" />
    <path d="M149.1 113.86L150.9 114.41" stroke="black" stroke-width="0.5" />
    <path d="M150.9 114.41L152.78 114.59" stroke="black" stroke-width="0.5" />
    <path d="M152.77 104.98H152.31" stroke="black" stroke-width="0.5" />
    <path d="M152.31 104.98V114.58" stroke="black" stroke-width="0.5" />
    <path d="M152.78 114.59L152.77 104.98" stroke="black" stroke-width="0.5" />
    <path d="M26.81 71.63L27 73.5" stroke="black" stroke-width="0.5" />
    <path d="M27 73.5L27.55 75.31" stroke="black" stroke-width="0.5" />
    <path d="M27.55 75.31L28.44 76.97" stroke="black" stroke-width="0.5" />
    <path d="M28.44 76.97L29.63 78.42" stroke="black" stroke-width="0.5" />
    <path d="M29.63 78.42L31.09 79.62" stroke="black" stroke-width="0.5" />
    <path d="M31.09 79.62L32.75 80.5" stroke="black" stroke-width="0.5" />
    <path d="M32.75 80.5L34.55 81.05" stroke="black" stroke-width="0.5" />
    <path d="M34.55 81.05L36.43 81.23" stroke="black" stroke-width="0.5" />
    <path d="M36.42 71.63H35.95" stroke="black" stroke-width="0.5" />
    <path d="M35.95 71.63L35.96 81.22" stroke="black" stroke-width="0.5" />
    <path d="M36.43 81.23L36.42 71.63" stroke="black" stroke-width="0.5" />
    <path d="M23.55 52.54L25.42 52.73" stroke="black" stroke-width="0.5" />
    <path d="M25.42 52.73L27.22 53.28" stroke="black" stroke-width="0.5" />
    <path d="M27.22 53.28L28.88 54.16" stroke="black" stroke-width="0.5" />
    <path d="M28.88 54.16L30.34 55.36" stroke="black" stroke-width="0.5" />
    <path d="M30.34 55.36L31.53 56.82" stroke="black" stroke-width="0.5" />
    <path d="M31.53 56.82L32.42 58.48" stroke="black" stroke-width="0.5" />
    <path d="M32.42 58.48L32.96 60.28" stroke="black" stroke-width="0.5" />
    <path d="M32.96 60.28L33.15 62.15" stroke="black" stroke-width="0.5" />
    <path d="M23.54 62.15V61.68" stroke="black" stroke-width="0.5" />
    <path d="M23.54 61.68L33.14 61.69" stroke="black" stroke-width="0.5" />
    <path d="M33.15 62.15H23.54" stroke="black" stroke-width="0.5" />
    <path d="M26.81 106.86L27 108.73" stroke="black" stroke-width="0.5" />
    <path d="M27 108.73L27.55 110.53" stroke="black" stroke-width="0.5" />
    <path d="M27.55 110.53L28.44 112.19" stroke="black" stroke-width="0.5" />
    <path d="M28.44 112.19L29.63 113.65" stroke="black" stroke-width="0.5" />
    <path d="M29.63 113.65L31.09 114.84" stroke="black" stroke-width="0.5" />
    <path d="M31.09 114.84L32.75 115.73" stroke="black" stroke-width="0.5" />
    <path d="M32.75 115.73L34.55 116.28" stroke="black" stroke-width="0.5" />
    <path d="M34.55 116.28L36.43 116.46" stroke="black" stroke-width="0.5" />
    <path d="M36.42 106.85H35.95" stroke="black" stroke-width="0.5" />
    <path d="M35.95 106.85L35.96 116.45" stroke="black" stroke-width="0.5" />
    <path d="M36.43 116.46L36.42 106.85" stroke="black" stroke-width="0.5" />
  </svg>
</template>
