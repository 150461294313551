import { IAHU } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

interface IContext {
  commit: (mutation: string, payload: IAHU) => void;
}

const setAHUEdit = (context: IContext, ahu: IAHU) => {
  context.commit('SET_AHU_EDIT', ahu);
};

export default {
  setAHUEdit,
};
