<template>
  <svg
    width="369"
    height="192"
    viewBox="0 0 369 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M366.96 169.02V2" stroke="black" stroke-width="0.5" />
    <path d="M20.71 90.73H15.165" stroke="black" stroke-width="0.5" />
    <path d="M20.71 90.73V65.86" stroke="black" stroke-width="0.5" />
    <path d="M20.71 65.86H54.76" stroke="black" stroke-width="0.5" />
    <path d="M20.71 64.53H53.43" stroke="black" stroke-width="0.5" />
    <path d="M95.58 162.85H66.33" stroke="black" stroke-width="0.5" />
    <path d="M66.33 162.85V160.71" stroke="black" stroke-width="0.5" />
    <path d="M66.33 160.71H50.47" stroke="black" stroke-width="0.5" />
    <path d="M50.47 160.71V171.59" stroke="black" stroke-width="0.5" />
    <path d="M50.47 171.59L46.69 173.61" stroke="black" stroke-width="0.5" />
    <path d="M54.76 65.86V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M46.83 173.94L36.19 178.64" stroke="black" stroke-width="0.5" />
    <path
      d="M24.28 182C28.2675 180.993 32.194 179.757 36.04 178.3"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M53.43 64.53V10.5" stroke="black" stroke-width="0.5" />
    <path d="M24.28 182H17.19" stroke="black" stroke-width="0.5" />
    <path d="M17.19 182V185.01" stroke="black" stroke-width="0.5" />
    <path d="M365.27 2.42999V65.93" stroke="black" stroke-width="0.5" />
    <path d="M362.17 109.02H364.31" stroke="black" stroke-width="0.5" />
    <path d="M364.31 109.02V138.27" stroke="black" stroke-width="0.5" />
    <path d="M364.31 138.27H363.94" stroke="black" stroke-width="0.5" />
    <path d="M363.94 138.27H362.17" stroke="black" stroke-width="0.5" />
    <path d="M364.3 138.27V166.03" stroke="black" stroke-width="0.5" />
    <path d="M362.17 163.9H350.9" stroke="black" stroke-width="0.5" />
    <path d="M364.3 166.03H350.9" stroke="black" stroke-width="0.5" />
    <path d="M350.9 163.9V166.04" stroke="black" stroke-width="0.5" />
    <path d="M350.9 166.04H321.65" stroke="black" stroke-width="0.5" />
    <path d="M321.65 166.04V163.9" stroke="black" stroke-width="0.5" />
    <path d="M321.65 163.9H299.84" stroke="black" stroke-width="0.5" />
    <path d="M321.65 166.03H299.84" stroke="black" stroke-width="0.5" />
    <path d="M299.84 163.9V166.04" stroke="black" stroke-width="0.5" />
    <path d="M299.84 166.04H270.58" stroke="black" stroke-width="0.5" />
    <path d="M270.58 166.04V163.9" stroke="black" stroke-width="0.5" />
    <path d="M270.58 163.9H248.78" stroke="black" stroke-width="0.5" />
    <path d="M270.58 166.03H248.78" stroke="black" stroke-width="0.5" />
    <path d="M248.78 163.9V166.04" stroke="black" stroke-width="0.5" />
    <path d="M248.78 166.04H219.52" stroke="black" stroke-width="0.5" />
    <path d="M219.52 166.04V163.9" stroke="black" stroke-width="0.5" />
    <path d="M219.52 163.9H197.71" stroke="black" stroke-width="0.5" />
    <path d="M219.52 166.03H197.71" stroke="black" stroke-width="0.5" />
    <path d="M197.71 163.9V166.04" stroke="black" stroke-width="0.5" />
    <path d="M197.71 166.04H168.45" stroke="black" stroke-width="0.5" />
    <path d="M168.45 166.04V163.9" stroke="black" stroke-width="0.5" />
    <path d="M168.45 163.9H165.7" stroke="black" stroke-width="0.5" />
    <path d="M168.46 166.03H164.1" stroke="black" stroke-width="0.5" />
    <path d="M165.7 163.9V160.71" stroke="black" stroke-width="0.5" />
    <path d="M164.1 166.03V162.84" stroke="black" stroke-width="0.5" />
    <path d="M165.7 160.71H146.65" stroke="black" stroke-width="0.5" />
    <path d="M164.1 162.84H146.65" stroke="black" stroke-width="0.5" />
    <path d="M146.65 160.71V162.85" stroke="black" stroke-width="0.5" />
    <path d="M146.65 162.85H117.39" stroke="black" stroke-width="0.5" />
    <path d="M117.39 162.85V160.71" stroke="black" stroke-width="0.5" />
    <path d="M117.39 160.71H95.58" stroke="black" stroke-width="0.5" />
    <path d="M117.39 162.84H95.58" stroke="black" stroke-width="0.5" />
    <path d="M95.58 160.71V162.85" stroke="black" stroke-width="0.5" />
    <path d="M46.69 173.61L46.83 173.94" stroke="black" stroke-width="0.5" />
    <path d="M36.19 178.64L36.04 178.3" stroke="black" stroke-width="0.5" />
    <path d="M18.78 183.33V188.38" stroke="black" stroke-width="0.5" />
    <path d="M52.06 162.84V171.73" stroke="black" stroke-width="0.5" />
    <path d="M47.35 175.16L36.73 179.85" stroke="black" stroke-width="0.5" />
    <path d="M47.87 176.39L37.27 181.07" stroke="black" stroke-width="0.5" />
    <path d="M59.51 170.16L47.87 176.39" stroke="black" stroke-width="0.5" />
    <path d="M58.18 168.46L47.03 174.42" stroke="black" stroke-width="0.5" />
    <path d="M24.44 183.33H18.78" stroke="black" stroke-width="0.5" />
    <path
      d="M24.44 183.33C28.5575 182.298 32.6111 181.026 36.58 179.52"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M29.49 189.98H17.19" stroke="black" stroke-width="0.5" />
    <path d="M27.89 188.38H18.78" stroke="black" stroke-width="0.5" />
    <path d="M66.33 162.84H52.06" stroke="black" stroke-width="0.5" />
    <path d="M321.65 163.9V168.69" stroke="black" stroke-width="0.5" />
    <path d="M321.65 168.69H350.9" stroke="black" stroke-width="0.5" />
    <path d="M350.9 168.69V163.9" stroke="black" stroke-width="0.5" />
    <path d="M350.9 163.9H321.65" stroke="black" stroke-width="0.5" />
    <path d="M321.65 166.04H350.9" stroke="black" stroke-width="0.5" />
    <path d="M321.65 165.77H350.9" stroke="black" stroke-width="0.5" />
    <path d="M321.65 166.84H350.9" stroke="black" stroke-width="0.5" />
    <path d="M322.18 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M326.17 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M326.7 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M346.38 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M345.85 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M350.37 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M336.43 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M335.9 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M168.45 163.9V168.69" stroke="black" stroke-width="0.5" />
    <path d="M168.45 168.69H197.71" stroke="black" stroke-width="0.5" />
    <path d="M197.71 168.69V163.9" stroke="black" stroke-width="0.5" />
    <path d="M197.71 163.9H168.45" stroke="black" stroke-width="0.5" />
    <path d="M168.45 166.04H197.71" stroke="black" stroke-width="0.5" />
    <path d="M168.45 165.77H197.71" stroke="black" stroke-width="0.5" />
    <path d="M168.45 166.84H197.71" stroke="black" stroke-width="0.5" />
    <path d="M168.99 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M172.98 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M173.51 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M193.19 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M192.66 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M197.18 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M183.24 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M182.71 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M219.52 163.9V168.69" stroke="black" stroke-width="0.5" />
    <path d="M219.52 168.69H248.78" stroke="black" stroke-width="0.5" />
    <path d="M248.78 168.69V163.9" stroke="black" stroke-width="0.5" />
    <path d="M248.78 163.9H219.52" stroke="black" stroke-width="0.5" />
    <path d="M219.52 166.04H248.78" stroke="black" stroke-width="0.5" />
    <path d="M219.52 165.77H248.78" stroke="black" stroke-width="0.5" />
    <path d="M219.52 166.84H248.78" stroke="black" stroke-width="0.5" />
    <path d="M220.05 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M224.04 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M224.57 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M244.25 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M243.72 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M248.24 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M234.3 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M233.77 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M66.33 160.71V165.5" stroke="black" stroke-width="0.5" />
    <path d="M66.33 165.5H95.58" stroke="black" stroke-width="0.5" />
    <path d="M95.58 165.5V160.71" stroke="black" stroke-width="0.5" />
    <path d="M95.58 160.71H66.33" stroke="black" stroke-width="0.5" />
    <path d="M66.33 162.85H95.58" stroke="black" stroke-width="0.5" />
    <path d="M66.33 162.58H95.58" stroke="black" stroke-width="0.5" />
    <path d="M66.33 163.64H95.58" stroke="black" stroke-width="0.5" />
    <path d="M66.86 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M70.85 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M71.38 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M91.06 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M90.53 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M95.05 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M81.11 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M80.58 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M117.39 160.71V165.5" stroke="black" stroke-width="0.5" />
    <path d="M117.39 165.5H146.65" stroke="black" stroke-width="0.5" />
    <path d="M146.65 165.5V160.71" stroke="black" stroke-width="0.5" />
    <path d="M146.65 160.71H117.39" stroke="black" stroke-width="0.5" />
    <path d="M117.39 162.85H146.65" stroke="black" stroke-width="0.5" />
    <path d="M117.39 162.58H146.65" stroke="black" stroke-width="0.5" />
    <path d="M117.39 163.64H146.65" stroke="black" stroke-width="0.5" />
    <path d="M117.92 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M121.91 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M122.44 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M142.12 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M141.59 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M146.11 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M132.17 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M131.64 162.85V163.64" stroke="black" stroke-width="0.5" />
    <path d="M270.58 163.9V168.69" stroke="black" stroke-width="0.5" />
    <path d="M270.58 168.69H299.84" stroke="black" stroke-width="0.5" />
    <path d="M299.84 168.69V163.9" stroke="black" stroke-width="0.5" />
    <path d="M299.84 163.9H270.58" stroke="black" stroke-width="0.5" />
    <path d="M270.58 166.04H299.84" stroke="black" stroke-width="0.5" />
    <path d="M270.58 165.77H299.84" stroke="black" stroke-width="0.5" />
    <path d="M270.58 166.84H299.84" stroke="black" stroke-width="0.5" />
    <path d="M271.12 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M275.11 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M275.64 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M295.32 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M294.79 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M299.31 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M285.37 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M284.84 166.04V166.84" stroke="black" stroke-width="0.5" />
    <path d="M362.17 109.02H366.96" stroke="black" stroke-width="0.5" />
    <path d="M366.96 109.02V138.27" stroke="black" stroke-width="0.5" />
    <path d="M366.96 138.27H362.17" stroke="black" stroke-width="0.5" />
    <path d="M362.17 138.27V109.02" stroke="black" stroke-width="0.5" />
    <path d="M364.31 109.02V138.27" stroke="black" stroke-width="0.5" />
    <path d="M364.04 109.02V138.27" stroke="black" stroke-width="0.5" />
    <path d="M365.11 109.02V138.27" stroke="black" stroke-width="0.5" />
    <path d="M364.31 109.55H365.11" stroke="black" stroke-width="0.5" />
    <path d="M364.31 113.54H365.11" stroke="black" stroke-width="0.5" />
    <path d="M364.31 114.07H365.11" stroke="black" stroke-width="0.5" />
    <path d="M364.31 133.75H365.11" stroke="black" stroke-width="0.5" />
    <path d="M364.31 133.22H365.11" stroke="black" stroke-width="0.5" />
    <path d="M364.31 137.74H365.11" stroke="black" stroke-width="0.5" />
    <path d="M364.31 123.8H365.11" stroke="black" stroke-width="0.5" />
    <path d="M364.31 123.27H365.11" stroke="black" stroke-width="0.5" />
    <path d="M52.06 165.5H152.5" stroke="black" stroke-width="0.5" />
    <path d="M152.5 165.5V168.69" stroke="black" stroke-width="0.5" />
    <path d="M152.5 168.69H168.45" stroke="black" stroke-width="0.5" />
    <path d="M168.45 168.69H366.96" stroke="black" stroke-width="0.5" />
    <path d="M58.18 165.5V168.46" stroke="black" stroke-width="0.5" />
    <path d="M59.51 165.5V170.16" stroke="black" stroke-width="0.5" />
    <path d="M46.83 173.94L47.87 176.39" stroke="black" stroke-width="0.5" />
    <path d="M37.27 181.07L36.19 178.64" stroke="black" stroke-width="0.5" />
    <path d="M29.49 183.68V189.98" stroke="black" stroke-width="0.5" />
    <path d="M27.89 182.4V188.38" stroke="black" stroke-width="0.5" />
    <path d="M17.19 185.01V189.98" stroke="black" stroke-width="0.5" />
    <path d="M27.47 185.79L27.29 186.36" stroke="black" stroke-width="0.5" />
    <path d="M27.29 186.36L26.8 186.71" stroke="black" stroke-width="0.5" />
    <path d="M26.8 186.71H26.21" stroke="black" stroke-width="0.5" />
    <path d="M26.21 186.71L25.72 186.36" stroke="black" stroke-width="0.5" />
    <path d="M25.72 186.36L25.54 185.79" stroke="black" stroke-width="0.5" />
    <path d="M25.54 185.79L25.72 185.22" stroke="black" stroke-width="0.5" />
    <path d="M25.72 185.22L26.21 184.87" stroke="black" stroke-width="0.5" />
    <path d="M26.21 184.87H26.8" stroke="black" stroke-width="0.5" />
    <path d="M26.8 184.87L27.29 185.22" stroke="black" stroke-width="0.5" />
    <path d="M27.29 185.22L27.47 185.79" stroke="black" stroke-width="0.5" />
    <path d="M24.74 185.79L24.55 186.36" stroke="black" stroke-width="0.5" />
    <path d="M24.55 186.36L24.07 186.71" stroke="black" stroke-width="0.5" />
    <path d="M24.07 186.71H23.47" stroke="black" stroke-width="0.5" />
    <path d="M23.47 186.71L22.98 186.36" stroke="black" stroke-width="0.5" />
    <path d="M22.98 186.36L22.8 185.79" stroke="black" stroke-width="0.5" />
    <path d="M22.8 185.79L22.98 185.22" stroke="black" stroke-width="0.5" />
    <path d="M22.98 185.22L23.47 184.87" stroke="black" stroke-width="0.5" />
    <path d="M23.47 184.87H24.07" stroke="black" stroke-width="0.5" />
    <path d="M24.07 184.87L24.55 185.22" stroke="black" stroke-width="0.5" />
    <path d="M24.55 185.22L24.74 185.79" stroke="black" stroke-width="0.5" />
    <path
      d="M29.49 183.68C32.1152 182.908 34.7104 182.037 37.27 181.07"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M31.52 180.27H33.29" stroke="black" stroke-width="0.5" />
    <path d="M33.29 180.27V181.9" stroke="black" stroke-width="0.5" />
    <path d="M33.29 181.9H31.52" stroke="black" stroke-width="0.5" />
    <path d="M31.52 181.9V180.27" stroke="black" stroke-width="0.5" />
    <path d="M17.19 189.98V190.98" stroke="black" stroke-width="0.5" />
    <path d="M17.19 190.98H20.48" stroke="black" stroke-width="0.5" />
    <path d="M20.48 190.98V189.98" stroke="black" stroke-width="0.5" />
    <path d="M0.5 183.95H17.19" stroke="black" stroke-width="0.5" />
    <path d="M0.5 185.01H17.19" stroke="black" stroke-width="0.5" />
    <path d="M16.66 183.95V185.01" stroke="black" stroke-width="0.5" />
    <path d="M7.67001 185.01V183.95" stroke="black" stroke-width="0.5" />
    <path d="M8.20001 185.01V183.95" stroke="black" stroke-width="0.5" />
    <path d="M17.19 183.95V185.01" stroke="black" stroke-width="0.5" />
    <path d="M53.43 10.41H20.71" stroke="black" stroke-width="0.5" />
    <path d="M20.71 60.54V10.41" stroke="black" stroke-width="0.5" />
    <path d="M20.71 60.54V64.53" stroke="black" stroke-width="0.5" />
    <path d="M81.56 160.71V162.58" stroke="black" stroke-width="0.5" />
    <path d="M81.56 160.71H87.36" stroke="black" stroke-width="0.5" />
    <path d="M327.23 163.9H328.56" stroke="black" stroke-width="0.5" />
    <path d="M365.27 65.93V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M367.03 63.17L367.12 63.39" stroke="black" stroke-width="0.5" />
    <path d="M367.12 63.39L367.22 63.61" stroke="black" stroke-width="0.5" />
    <path d="M367.22 63.61L367.33 63.82" stroke="black" stroke-width="0.5" />
    <path d="M367.43 62.63V25.64" stroke="black" stroke-width="0.5" />
    <path
      d="M367.03 63.15C367.14 63.1116 367.236 63.042 367.307 62.9496C367.379 62.8573 367.421 62.7462 367.43 62.63"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M367.43 25.64C367.442 25.5118 367.408 25.3835 367.335 25.2776C367.262 25.1716 367.154 25.0946 367.03 25.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M367.82 25.56C367.824 25.3332 367.747 25.1124 367.602 24.938C367.457 24.7636 367.254 24.6472 367.03 24.61"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M367.22 24.42L367.12 24.64" stroke="black" stroke-width="0.5" />
    <path d="M367.12 24.64L367.03 24.86" stroke="black" stroke-width="0.5" />
    <path d="M367.83 62.66L367.82 25.56" stroke="black" stroke-width="0.5" />
    <path
      d="M367.03 63.56C367.243 63.5176 367.437 63.4067 367.581 63.2443C367.725 63.0818 367.813 62.8767 367.83 62.66"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M19.92 65.33H20.78" stroke="black" stroke-width="0.5" />
    <path d="M21.45 65.33H22.77" stroke="black" stroke-width="0.5" />
    <path d="M23.44 65.33H24.77" stroke="black" stroke-width="0.5" />
    <path d="M25.43 65.33H26.76" stroke="black" stroke-width="0.5" />
    <path d="M27.43 65.33H28.76" stroke="black" stroke-width="0.5" />
    <path d="M29.42 65.33H30.75" stroke="black" stroke-width="0.5" />
    <path d="M31.42 65.33H32.75" stroke="black" stroke-width="0.5" />
    <path d="M33.41 65.33H34.74" stroke="black" stroke-width="0.5" />
    <path d="M35.41 65.33H36.74" stroke="black" stroke-width="0.5" />
    <path d="M37.4 65.33H38.73" stroke="black" stroke-width="0.5" />
    <path d="M39.4 65.33H40.73" stroke="black" stroke-width="0.5" />
    <path d="M41.39 65.33H42.72" stroke="black" stroke-width="0.5" />
    <path d="M43.39 65.33H44.72" stroke="black" stroke-width="0.5" />
    <path d="M45.38 65.33H46.71" stroke="black" stroke-width="0.5" />
    <path d="M47.38 65.33H48.71" stroke="black" stroke-width="0.5" />
    <path d="M49.37 65.33H50.7" stroke="black" stroke-width="0.5" />
    <path d="M51.37 65.33H52.7" stroke="black" stroke-width="0.5" />
    <path d="M53.36 65.33H54.22" stroke="black" stroke-width="0.5" />
    <path d="M54.22 65.33V63.74" stroke="black" stroke-width="0.5" />
    <path d="M54.22 63.07V61.74" stroke="black" stroke-width="0.5" />
    <path d="M54.22 61.08V59.75" stroke="black" stroke-width="0.5" />
    <path d="M54.22 59.08V57.75" stroke="black" stroke-width="0.5" />
    <path d="M54.22 57.09V55.76" stroke="black" stroke-width="0.5" />
    <path d="M54.22 55.09V53.76" stroke="black" stroke-width="0.5" />
    <path d="M54.22 53.1V51.77" stroke="black" stroke-width="0.5" />
    <path d="M54.22 51.1V49.77" stroke="black" stroke-width="0.5" />
    <path d="M54.22 49.11V47.78" stroke="black" stroke-width="0.5" />
    <path d="M54.22 47.11V45.78" stroke="black" stroke-width="0.5" />
    <path d="M54.22 45.12V43.79" stroke="black" stroke-width="0.5" />
    <path d="M54.22 43.12V41.79" stroke="black" stroke-width="0.5" />
    <path d="M54.22 41.13V39.8" stroke="black" stroke-width="0.5" />
    <path d="M54.22 39.13V37.8" stroke="black" stroke-width="0.5" />
    <path d="M54.22 37.14V35.81" stroke="black" stroke-width="0.5" />
    <path d="M54.22 35.14V33.81" stroke="black" stroke-width="0.5" />
    <path d="M54.22 33.15V31.82" stroke="black" stroke-width="0.5" />
    <path d="M54.22 31.16V29.83" stroke="black" stroke-width="0.5" />
    <path d="M54.22 29.16V27.83" stroke="black" stroke-width="0.5" />
    <path d="M54.22 27.17V25.84" stroke="black" stroke-width="0.5" />
    <path d="M54.22 25.17V23.84" stroke="black" stroke-width="0.5" />
    <path d="M54.22 23.18V21.85" stroke="black" stroke-width="0.5" />
    <path d="M54.22 21.18V19.85" stroke="black" stroke-width="0.5" />
    <path d="M54.22 19.19V17.86" stroke="black" stroke-width="0.5" />
    <path d="M54.22 17.19V15.86" stroke="black" stroke-width="0.5" />
    <path d="M54.22 15.2V13.87" stroke="black" stroke-width="0.5" />
    <path d="M54.22 13.2V11.87" stroke="black" stroke-width="0.5" />
    <path d="M54.22 11.21V9.60999" stroke="black" stroke-width="0.5" />
    <path d="M54.22 9.60999H53.36" stroke="black" stroke-width="0.5" />
    <path d="M52.7 9.60999H51.37" stroke="black" stroke-width="0.5" />
    <path d="M50.7 9.60999H49.37" stroke="black" stroke-width="0.5" />
    <path d="M48.71 9.60999H47.38" stroke="black" stroke-width="0.5" />
    <path d="M46.71 9.60999H45.38" stroke="black" stroke-width="0.5" />
    <path d="M44.72 9.60999H43.39" stroke="black" stroke-width="0.5" />
    <path d="M42.72 9.60999H41.39" stroke="black" stroke-width="0.5" />
    <path d="M40.73 9.60999H39.4" stroke="black" stroke-width="0.5" />
    <path d="M38.73 9.60999H37.4" stroke="black" stroke-width="0.5" />
    <path d="M36.74 9.60999H35.41" stroke="black" stroke-width="0.5" />
    <path d="M34.74 9.60999H33.41" stroke="black" stroke-width="0.5" />
    <path d="M32.75 9.60999H31.42" stroke="black" stroke-width="0.5" />
    <path d="M30.75 9.60999H29.42" stroke="black" stroke-width="0.5" />
    <path d="M28.76 9.60999H27.43" stroke="black" stroke-width="0.5" />
    <path d="M26.76 9.60999H25.43" stroke="black" stroke-width="0.5" />
    <path d="M24.77 9.60999H23.44" stroke="black" stroke-width="0.5" />
    <path d="M22.77 9.60999H21.45" stroke="black" stroke-width="0.5" />
    <path d="M20.78 9.60999H19.92" stroke="black" stroke-width="0.5" />
    <path d="M19.92 9.60999V11.21" stroke="black" stroke-width="0.5" />
    <path d="M19.92 11.87V13.2" stroke="black" stroke-width="0.5" />
    <path d="M19.92 13.87V15.2" stroke="black" stroke-width="0.5" />
    <path d="M19.92 15.86V17.19" stroke="black" stroke-width="0.5" />
    <path d="M19.92 17.86V19.19" stroke="black" stroke-width="0.5" />
    <path d="M19.92 19.85V21.18" stroke="black" stroke-width="0.5" />
    <path d="M19.92 21.85V23.18" stroke="black" stroke-width="0.5" />
    <path d="M19.92 23.84V25.17" stroke="black" stroke-width="0.5" />
    <path d="M19.92 25.84V27.17" stroke="black" stroke-width="0.5" />
    <path d="M19.92 27.83V29.16" stroke="black" stroke-width="0.5" />
    <path d="M19.92 29.83V31.16" stroke="black" stroke-width="0.5" />
    <path d="M19.92 31.82V33.15" stroke="black" stroke-width="0.5" />
    <path d="M19.92 33.81V35.14" stroke="black" stroke-width="0.5" />
    <path d="M19.92 35.81V37.14" stroke="black" stroke-width="0.5" />
    <path d="M19.92 37.8V39.13" stroke="black" stroke-width="0.5" />
    <path d="M19.92 39.8V41.13" stroke="black" stroke-width="0.5" />
    <path d="M19.92 41.79V43.12" stroke="black" stroke-width="0.5" />
    <path d="M19.92 43.79V45.12" stroke="black" stroke-width="0.5" />
    <path d="M19.92 45.78V47.11" stroke="black" stroke-width="0.5" />
    <path d="M19.92 47.78V49.11" stroke="black" stroke-width="0.5" />
    <path d="M19.92 49.77V51.1" stroke="black" stroke-width="0.5" />
    <path d="M19.92 51.77V53.1" stroke="black" stroke-width="0.5" />
    <path d="M19.92 53.76V55.09" stroke="black" stroke-width="0.5" />
    <path d="M19.92 55.76V57.09" stroke="black" stroke-width="0.5" />
    <path d="M19.92 57.75V59.08" stroke="black" stroke-width="0.5" />
    <path d="M19.92 59.75V61.08" stroke="black" stroke-width="0.5" />
    <path d="M19.92 61.74V63.07" stroke="black" stroke-width="0.5" />
    <path d="M19.92 63.74V65.33" stroke="black" stroke-width="0.5" />
    <path d="M20.71 19.45V11.04" stroke="black" stroke-width="0.5" />
    <path d="M20.71 11.04H22.04" stroke="black" stroke-width="0.5" />
    <path d="M22.04 11.04V19.45" stroke="black" stroke-width="0.5" />
    <path d="M22.04 19.45H20.71" stroke="black" stroke-width="0.5" />
    <path d="M21.38 18.92V11.57" stroke="black" stroke-width="0.5" />
    <path d="M20.71 11.57H22.04" stroke="black" stroke-width="0.5" />
    <path d="M22.04 18.92H20.71" stroke="black" stroke-width="0.5" />
    <path d="M20.71 46.29V37.87" stroke="black" stroke-width="0.5" />
    <path d="M20.71 37.87H22.04" stroke="black" stroke-width="0.5" />
    <path d="M22.04 37.87V46.29" stroke="black" stroke-width="0.5" />
    <path d="M22.04 46.29H20.71" stroke="black" stroke-width="0.5" />
    <path d="M21.38 45.76V38.4" stroke="black" stroke-width="0.5" />
    <path d="M20.71 38.4H22.04" stroke="black" stroke-width="0.5" />
    <path d="M22.04 45.76H20.71" stroke="black" stroke-width="0.5" />
    <path d="M33.64 146.23H34.71" stroke="black" stroke-width="0.5" />
    <path d="M35.37 146.23H36.7" stroke="black" stroke-width="0.5" />
    <path d="M37.36 146.23H38.69" stroke="black" stroke-width="0.5" />
    <path d="M39.36 146.23H40.69" stroke="black" stroke-width="0.5" />
    <path d="M41.35 146.23H42.68" stroke="black" stroke-width="0.5" />
    <path d="M43.35 146.23H44.68" stroke="black" stroke-width="0.5" />
    <path d="M45.34 146.23H46.41" stroke="black" stroke-width="0.5" />
    <path d="M46.41 146.23V147.79" stroke="black" stroke-width="0.5" />
    <path d="M46.41 148.45V149.78" stroke="black" stroke-width="0.5" />
    <path d="M46.41 150.45V151.78" stroke="black" stroke-width="0.5" />
    <path d="M46.41 152.44V154" stroke="black" stroke-width="0.5" />
    <path d="M46.41 154H45.34" stroke="black" stroke-width="0.5" />
    <path d="M44.68 154H43.35" stroke="black" stroke-width="0.5" />
    <path d="M42.68 154H41.35" stroke="black" stroke-width="0.5" />
    <path d="M40.69 154H39.36" stroke="black" stroke-width="0.5" />
    <path d="M38.69 154H37.36" stroke="black" stroke-width="0.5" />
    <path d="M36.7 154H35.37" stroke="black" stroke-width="0.5" />
    <path d="M34.71 154H33.64" stroke="black" stroke-width="0.5" />
    <path d="M33.64 154V152.44" stroke="black" stroke-width="0.5" />
    <path d="M33.64 151.78V150.45" stroke="black" stroke-width="0.5" />
    <path d="M33.64 149.78V148.45" stroke="black" stroke-width="0.5" />
    <path d="M33.64 147.79V146.23" stroke="black" stroke-width="0.5" />
    <path d="M365.8 65.93V65.1" stroke="black" stroke-width="0.5" />
    <path d="M365.8 64.43V63.1" stroke="black" stroke-width="0.5" />
    <path d="M365.8 62.44V61.11" stroke="black" stroke-width="0.5" />
    <path d="M365.8 60.44V59.11" stroke="black" stroke-width="0.5" />
    <path d="M365.8 58.45V57.12" stroke="black" stroke-width="0.5" />
    <path d="M365.8 56.45V55.13" stroke="black" stroke-width="0.5" />
    <path d="M365.8 54.46V53.13" stroke="black" stroke-width="0.5" />
    <path d="M365.8 52.47V51.14" stroke="black" stroke-width="0.5" />
    <path d="M365.8 50.47V49.14" stroke="black" stroke-width="0.5" />
    <path d="M365.8 48.48V47.15" stroke="black" stroke-width="0.5" />
    <path d="M365.8 46.48V45.15" stroke="black" stroke-width="0.5" />
    <path d="M365.8 44.49V43.16" stroke="black" stroke-width="0.5" />
    <path d="M365.8 42.49V41.16" stroke="black" stroke-width="0.5" />
    <path d="M365.8 40.5V39.17" stroke="black" stroke-width="0.5" />
    <path d="M365.8 38.5V37.17" stroke="black" stroke-width="0.5" />
    <path d="M365.8 36.51V35.18" stroke="black" stroke-width="0.5" />
    <path d="M365.8 34.51V33.18" stroke="black" stroke-width="0.5" />
    <path d="M365.8 32.52V31.19" stroke="black" stroke-width="0.5" />
    <path d="M365.8 30.52V29.19" stroke="black" stroke-width="0.5" />
    <path d="M365.8 28.53V27.2" stroke="black" stroke-width="0.5" />
    <path d="M365.8 26.53V25.2" stroke="black" stroke-width="0.5" />
    <path d="M365.8 24.54V23.21" stroke="black" stroke-width="0.5" />
    <path d="M365.8 22.54V21.22" stroke="black" stroke-width="0.5" />
    <path d="M365.8 20.55V19.22" stroke="black" stroke-width="0.5" />
    <path d="M365.8 18.56V17.23" stroke="black" stroke-width="0.5" />
    <path d="M365.8 16.56V15.23" stroke="black" stroke-width="0.5" />
    <path d="M365.8 14.57V13.24" stroke="black" stroke-width="0.5" />
    <path d="M365.8 12.57V11.24" stroke="black" stroke-width="0.5" />
    <path d="M365.8 10.58V9.25" stroke="black" stroke-width="0.5" />
    <path d="M365.8 8.58V7.25" stroke="black" stroke-width="0.5" />
    <path d="M365.8 6.59001V5.26001" stroke="black" stroke-width="0.5" />
    <path d="M365.8 4.59001V3.26001" stroke="black" stroke-width="0.5" />
    <path d="M365.8 2.60002V1.77002" stroke="black" stroke-width="0.5" />
    <path d="M367.56 1.77002H365.8" stroke="black" stroke-width="0.5" />
    <path d="M23.17 14.48H52.18" stroke="black" stroke-width="0.5" />
    <path d="M52.18 14.48V34.44" stroke="black" stroke-width="0.5" />
    <path d="M52.18 34.44H22.58" stroke="black" stroke-width="0.5" />
    <path d="M22.04 34.44V19.43" stroke="black" stroke-width="0.5" />
    <path d="M24.62 15.02H51.64" stroke="black" stroke-width="0.5" />
    <path d="M51.64 15.02V33.91" stroke="black" stroke-width="0.5" />
    <path d="M51.64 33.91H22.58" stroke="black" stroke-width="0.5" />
    <path d="M22.58 33.91V9.60999" stroke="black" stroke-width="0.5" />
    <path d="M22.04 19.43H24.62" stroke="black" stroke-width="0.5" />
    <path d="M24.62 19.43V15.02" stroke="black" stroke-width="0.5" />
    <path d="M22.58 19.43H23.17" stroke="black" stroke-width="0.5" />
    <path d="M23.17 19.43V11.76" stroke="black" stroke-width="0.5" />
    <path d="M23.17 11.76H22.58" stroke="black" stroke-width="0.5" />
    <path d="M22.58 11.76V19.43" stroke="black" stroke-width="0.5" />
    <path d="M22.87 11.76L22.89 19.43" stroke="black" stroke-width="0.5" />
    <path d="M19.92 35.78H22.04" stroke="black" stroke-width="0.5" />
    <path d="M22.04 34.44V35.78" stroke="black" stroke-width="0.5" />
    <path d="M23.17 41.32H52.18" stroke="black" stroke-width="0.5" />
    <path d="M52.18 41.32V61.28" stroke="black" stroke-width="0.5" />
    <path d="M52.18 61.28H22.58" stroke="black" stroke-width="0.5" />
    <path d="M22.04 61.28V46.26" stroke="black" stroke-width="0.5" />
    <path d="M24.62 41.85H51.64" stroke="black" stroke-width="0.5" />
    <path d="M51.64 41.85V60.74" stroke="black" stroke-width="0.5" />
    <path d="M51.64 60.74H24.64" stroke="black" stroke-width="0.5" />
    <path d="M23.04 60.74H22.58" stroke="black" stroke-width="0.5" />
    <path d="M22.58 60.74V46.26" stroke="black" stroke-width="0.5" />
    <path d="M22.58 46.26H23.17" stroke="black" stroke-width="0.5" />
    <path d="M23.17 46.26V38.59" stroke="black" stroke-width="0.5" />
    <path d="M23.17 38.59H22.58" stroke="black" stroke-width="0.5" />
    <path d="M22.58 38.59V46.26" stroke="black" stroke-width="0.5" />
    <path d="M22.87 38.59L22.89 46.26" stroke="black" stroke-width="0.5" />
    <path d="M20.71 62.54H22.04" stroke="black" stroke-width="0.5" />
    <path d="M22.04 61.28V62.54" stroke="black" stroke-width="0.5" />
    <path d="M23.04 60.74V60.47" stroke="black" stroke-width="0.5" />
    <path d="M23.04 60.47H24.64" stroke="black" stroke-width="0.5" />
    <path d="M24.64 60.47V60.74" stroke="black" stroke-width="0.5" />
    <path d="M22.58 60.74V61.28" stroke="black" stroke-width="0.5" />
    <path d="M22.58 33.91V34.44" stroke="black" stroke-width="0.5" />
    <path d="M24.62 41.85V46.26" stroke="black" stroke-width="0.5" />
    <path d="M22.58 46.26H24.62" stroke="black" stroke-width="0.5" />
    <path d="M37.8 61.58V62.46" stroke="black" stroke-width="0.5" />
    <path d="M37.08 62.46H38.52" stroke="black" stroke-width="0.5" />
    <path d="M37.8 14.01V13.14" stroke="black" stroke-width="0.5" />
    <path d="M37.08 13.14H38.52" stroke="black" stroke-width="0.5" />
    <path d="M37.8 40.98V40.1" stroke="black" stroke-width="0.5" />
    <path d="M37.08 40.1H38.52" stroke="black" stroke-width="0.5" />
    <path d="M37.8 34.69V35.56" stroke="black" stroke-width="0.5" />
    <path d="M37.08 35.56H38.52" stroke="black" stroke-width="0.5" />
    <path d="M98.54 2.15001L98.55 2.14001" stroke="black" stroke-width="0.5" />
    <path
      d="M343.34 2.15001L343.35 2.14001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M53.9 70.38L53.81 70.29" stroke="black" stroke-width="0.5" />
    <path d="M54.14 70.27L53.81 69.94" stroke="black" stroke-width="0.5" />
    <path d="M54.14 69.92L53.81 69.59" stroke="black" stroke-width="0.5" />
    <path d="M54.14 69.57L53.81 69.24" stroke="black" stroke-width="0.5" />
    <path d="M54.14 69.22L53.81 68.88" stroke="black" stroke-width="0.5" />
    <path d="M54.2 68.92L54.07 68.79" stroke="black" stroke-width="0.5" />
    <path d="M54.07 68.79L53.81 68.53" stroke="black" stroke-width="0.5" />
    <path d="M54.55 68.92L54.42 68.79" stroke="black" stroke-width="0.5" />
    <path d="M54.9 68.92L54.77 68.79" stroke="black" stroke-width="0.5" />
    <path d="M55.26 68.92L55.12 68.79" stroke="black" stroke-width="0.5" />
    <path d="M54.42 68.79L54.29 68.66" stroke="black" stroke-width="0.5" />
    <path d="M54.77 68.79L54.64 68.66" stroke="black" stroke-width="0.5" />
    <path d="M55.12 68.79L54.99 68.66" stroke="black" stroke-width="0.5" />
    <path d="M54.14 68.51L53.81 68.18" stroke="black" stroke-width="0.5" />
    <path d="M54.14 68.16L53.81 67.83" stroke="black" stroke-width="0.5" />
    <path d="M54.14 67.81L53.81 67.47" stroke="black" stroke-width="0.5" />
    <path d="M57.03 70.35L56.7 70.01" stroke="black" stroke-width="0.5" />
    <path d="M55.61 68.92L55.48 68.79" stroke="black" stroke-width="0.5" />
    <path d="M55.48 68.79L55.42 68.73" stroke="black" stroke-width="0.5" />
    <path d="M55.42 68.73L55.34 68.66" stroke="black" stroke-width="0.5" />
    <path d="M54.14 67.45L53.88 67.19" stroke="black" stroke-width="0.5" />
    <path d="M57.03 69.99L56.7 69.66" stroke="black" stroke-width="0.5" />
    <path d="M57.03 69.64L56.7 69.31" stroke="black" stroke-width="0.5" />
    <path d="M55.96 68.92L55.83 68.79" stroke="black" stroke-width="0.5" />
    <path d="M56.31 68.92L56.18 68.79" stroke="black" stroke-width="0.5" />
    <path d="M55.83 68.79L55.7 68.66" stroke="black" stroke-width="0.5" />
    <path d="M56.18 68.79L56.05 68.66" stroke="black" stroke-width="0.5" />
    <path d="M57.03 69.29L56.53 68.79" stroke="black" stroke-width="0.5" />
    <path d="M56.53 68.79L56.4 68.66" stroke="black" stroke-width="0.5" />
    <path d="M57.03 68.94L56.89 68.79" stroke="black" stroke-width="0.5" />
    <path d="M56.89 68.79L56.7 68.6" stroke="black" stroke-width="0.5" />
    <path d="M57.03 68.58L56.7 68.25" stroke="black" stroke-width="0.5" />
    <path d="M57.03 68.23L56.7 67.9" stroke="black" stroke-width="0.5" />
    <path d="M57.03 67.88L56.7 67.55" stroke="black" stroke-width="0.5" />
    <path d="M57.03 67.53L56.72 67.21" stroke="black" stroke-width="0.5" />
    <path d="M156.03 70.38L155.94 70.29" stroke="black" stroke-width="0.5" />
    <path d="M156.27 70.27L155.94 69.94" stroke="black" stroke-width="0.5" />
    <path d="M156.27 69.92L155.94 69.59" stroke="black" stroke-width="0.5" />
    <path d="M156.27 69.57L155.94 69.24" stroke="black" stroke-width="0.5" />
    <path d="M156.27 69.22L155.94 68.88" stroke="black" stroke-width="0.5" />
    <path d="M156.33 68.92L156.2 68.79" stroke="black" stroke-width="0.5" />
    <path d="M156.2 68.79L155.94 68.53" stroke="black" stroke-width="0.5" />
    <path d="M156.68 68.92L156.55 68.79" stroke="black" stroke-width="0.5" />
    <path d="M157.03 68.92L156.9 68.79" stroke="black" stroke-width="0.5" />
    <path d="M157.39 68.92L157.25 68.79" stroke="black" stroke-width="0.5" />
    <path d="M156.55 68.79L156.41 68.66" stroke="black" stroke-width="0.5" />
    <path d="M156.9 68.79L156.77 68.66" stroke="black" stroke-width="0.5" />
    <path d="M157.25 68.79L157.12 68.66" stroke="black" stroke-width="0.5" />
    <path d="M156.27 68.51L155.94 68.18" stroke="black" stroke-width="0.5" />
    <path d="M156.27 68.16L155.94 67.83" stroke="black" stroke-width="0.5" />
    <path d="M156.27 67.81L155.94 67.47" stroke="black" stroke-width="0.5" />
    <path d="M159.16 70.35L158.83 70.01" stroke="black" stroke-width="0.5" />
    <path d="M157.74 68.92L157.61 68.79" stroke="black" stroke-width="0.5" />
    <path d="M157.61 68.79L157.55 68.73" stroke="black" stroke-width="0.5" />
    <path d="M157.55 68.73L157.47 68.66" stroke="black" stroke-width="0.5" />
    <path d="M156.27 67.45L156.01 67.19" stroke="black" stroke-width="0.5" />
    <path d="M159.16 69.99L158.83 69.66" stroke="black" stroke-width="0.5" />
    <path d="M159.16 69.64L158.83 69.31" stroke="black" stroke-width="0.5" />
    <path d="M158.09 68.92L157.96 68.79" stroke="black" stroke-width="0.5" />
    <path d="M158.44 68.92L158.31 68.79" stroke="black" stroke-width="0.5" />
    <path d="M157.96 68.79L157.83 68.66" stroke="black" stroke-width="0.5" />
    <path d="M158.31 68.79L158.18 68.66" stroke="black" stroke-width="0.5" />
    <path d="M159.16 69.29L158.66 68.79" stroke="black" stroke-width="0.5" />
    <path d="M158.66 68.79L158.53 68.66" stroke="black" stroke-width="0.5" />
    <path d="M159.16 68.94L159.02 68.79" stroke="black" stroke-width="0.5" />
    <path d="M159.02 68.79L158.83 68.6" stroke="black" stroke-width="0.5" />
    <path d="M159.16 68.58L158.83 68.25" stroke="black" stroke-width="0.5" />
    <path d="M159.16 68.23L158.83 67.9" stroke="black" stroke-width="0.5" />
    <path d="M159.16 67.88L158.83 67.55" stroke="black" stroke-width="0.5" />
    <path d="M159.16 67.53L158.85 67.21" stroke="black" stroke-width="0.5" />
    <path d="M258.16 70.38L258.07 70.29" stroke="black" stroke-width="0.5" />
    <path d="M258.4 70.27L258.07 69.94" stroke="black" stroke-width="0.5" />
    <path d="M258.4 69.92L258.07 69.59" stroke="black" stroke-width="0.5" />
    <path d="M258.4 69.57L258.07 69.24" stroke="black" stroke-width="0.5" />
    <path d="M258.4 69.22L258.07 68.88" stroke="black" stroke-width="0.5" />
    <path d="M258.46 68.92L258.32 68.79" stroke="black" stroke-width="0.5" />
    <path d="M258.32 68.79L258.07 68.53" stroke="black" stroke-width="0.5" />
    <path d="M258.81 68.92L258.68 68.79" stroke="black" stroke-width="0.5" />
    <path d="M259.16 68.92L259.03 68.79" stroke="black" stroke-width="0.5" />
    <path d="M259.51 68.92L259.38 68.79" stroke="black" stroke-width="0.5" />
    <path d="M258.68 68.79L258.54 68.66" stroke="black" stroke-width="0.5" />
    <path d="M259.03 68.79L258.9 68.66" stroke="black" stroke-width="0.5" />
    <path d="M259.38 68.79L259.25 68.66" stroke="black" stroke-width="0.5" />
    <path d="M258.4 68.51L258.07 68.18" stroke="black" stroke-width="0.5" />
    <path d="M258.4 68.16L258.07 67.83" stroke="black" stroke-width="0.5" />
    <path d="M258.4 67.81L258.07 67.47" stroke="black" stroke-width="0.5" />
    <path d="M261.29 70.35L260.96 70.01" stroke="black" stroke-width="0.5" />
    <path d="M259.87 68.92L259.73 68.79" stroke="black" stroke-width="0.5" />
    <path d="M259.73 68.79L259.68 68.73" stroke="black" stroke-width="0.5" />
    <path d="M259.68 68.73L259.6 68.66" stroke="black" stroke-width="0.5" />
    <path d="M258.4 67.45L258.14 67.19" stroke="black" stroke-width="0.5" />
    <path d="M261.29 69.99L260.96 69.66" stroke="black" stroke-width="0.5" />
    <path d="M261.29 69.64L260.96 69.31" stroke="black" stroke-width="0.5" />
    <path d="M260.22 68.92L260.09 68.79" stroke="black" stroke-width="0.5" />
    <path d="M260.57 68.92L260.44 68.79" stroke="black" stroke-width="0.5" />
    <path d="M260.09 68.79L259.95 68.66" stroke="black" stroke-width="0.5" />
    <path d="M260.44 68.79L260.31 68.66" stroke="black" stroke-width="0.5" />
    <path d="M261.29 69.29L260.79 68.79" stroke="black" stroke-width="0.5" />
    <path d="M260.79 68.79L260.66 68.66" stroke="black" stroke-width="0.5" />
    <path d="M261.29 68.94L261.14 68.79" stroke="black" stroke-width="0.5" />
    <path d="M261.14 68.79L260.96 68.6" stroke="black" stroke-width="0.5" />
    <path d="M261.29 68.58L260.96 68.25" stroke="black" stroke-width="0.5" />
    <path d="M261.29 68.23L260.96 67.9" stroke="black" stroke-width="0.5" />
    <path d="M261.29 67.88L260.96 67.55" stroke="black" stroke-width="0.5" />
    <path d="M261.29 67.53L260.98 67.21" stroke="black" stroke-width="0.5" />
    <path d="M363.94 109.02H362.17" stroke="black" stroke-width="0.5" />
    <path d="M362.17 109.02V71.45" stroke="black" stroke-width="0.5" />
    <path d="M362.17 71.45H363.94" stroke="black" stroke-width="0.5" />
    <path d="M363.94 71.45V109.02" stroke="black" stroke-width="0.5" />
    <path d="M253.16 2.42999H294.79" stroke="black" stroke-width="0.5" />
    <path d="M327.17 2.42999H365.27" stroke="black" stroke-width="0.5" />
    <path d="M54.76 2.42999H107.68" stroke="black" stroke-width="0.5" />
    <path d="M338.07 56.95L337.89 55.09" stroke="black" stroke-width="0.5" />
    <path d="M337.89 55.09L337.34 53.29" stroke="black" stroke-width="0.5" />
    <path d="M337.34 53.29L336.46 51.63" stroke="black" stroke-width="0.5" />
    <path d="M336.46 51.63L335.27 50.18" stroke="black" stroke-width="0.5" />
    <path d="M335.27 50.18L333.82 48.99" stroke="black" stroke-width="0.5" />
    <path d="M333.82 48.99L332.16 48.11" stroke="black" stroke-width="0.5" />
    <path d="M332.16 48.11L330.37 47.56" stroke="black" stroke-width="0.5" />
    <path d="M330.37 47.56L328.5 47.38" stroke="black" stroke-width="0.5" />
    <path d="M328.5 56.49V56.95" stroke="black" stroke-width="0.5" />
    <path d="M328.5 56.95H338.07" stroke="black" stroke-width="0.5" />
    <path d="M338.07 56.95V56.49" stroke="black" stroke-width="0.5" />
    <path d="M338.07 56.49H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 56.95V57.49" stroke="black" stroke-width="0.5" />
    <path d="M328.5 57.49H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 57.49V56.95" stroke="black" stroke-width="0.5" />
    <path d="M327.17 56.95H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 46.85V47.38" stroke="black" stroke-width="0.5" />
    <path d="M328.5 47.38H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 47.38V46.85" stroke="black" stroke-width="0.5" />
    <path d="M327.17 46.85H328.5" stroke="black" stroke-width="0.5" />
    <path d="M338.07 110.25L337.89 112.11" stroke="black" stroke-width="0.5" />
    <path d="M337.89 112.11L337.34 113.91" stroke="black" stroke-width="0.5" />
    <path d="M337.34 113.91L336.46 115.56" stroke="black" stroke-width="0.5" />
    <path d="M336.46 115.56L335.27 117.02" stroke="black" stroke-width="0.5" />
    <path d="M335.27 117.02L333.82 118.21" stroke="black" stroke-width="0.5" />
    <path d="M333.82 118.21L332.16 119.09" stroke="black" stroke-width="0.5" />
    <path d="M332.16 119.09L330.37 119.64" stroke="black" stroke-width="0.5" />
    <path d="M330.37 119.64L328.5 119.82" stroke="black" stroke-width="0.5" />
    <path d="M328.5 110.71V110.25" stroke="black" stroke-width="0.5" />
    <path d="M328.5 110.25H338.07" stroke="black" stroke-width="0.5" />
    <path d="M338.07 110.25V110.71" stroke="black" stroke-width="0.5" />
    <path d="M338.07 110.71H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 110.25V109.71" stroke="black" stroke-width="0.5" />
    <path d="M328.5 109.71H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 109.71V110.25" stroke="black" stroke-width="0.5" />
    <path d="M327.17 110.25H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 120.35V119.82" stroke="black" stroke-width="0.5" />
    <path d="M328.5 119.82H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 119.82V120.35" stroke="black" stroke-width="0.5" />
    <path d="M327.17 120.35H328.5" stroke="black" stroke-width="0.5" />
    <path d="M51.56 79.83L49.7 80.01" stroke="black" stroke-width="0.5" />
    <path d="M49.7 80.01L47.9 80.56" stroke="black" stroke-width="0.5" />
    <path d="M47.9 80.56L46.25 81.44" stroke="black" stroke-width="0.5" />
    <path d="M46.25 81.44L44.79 82.63" stroke="black" stroke-width="0.5" />
    <path d="M44.79 82.63L43.6 84.08" stroke="black" stroke-width="0.5" />
    <path d="M43.6 84.08L42.72 85.74" stroke="black" stroke-width="0.5" />
    <path d="M42.72 85.74L42.17 87.53" stroke="black" stroke-width="0.5" />
    <path d="M42.17 87.53L41.99 89.4" stroke="black" stroke-width="0.5" />
    <path d="M51.1 89.4H51.56" stroke="black" stroke-width="0.5" />
    <path d="M51.56 89.4V79.83" stroke="black" stroke-width="0.5" />
    <path d="M51.56 79.83H51.1" stroke="black" stroke-width="0.5" />
    <path d="M51.1 79.83V89.4" stroke="black" stroke-width="0.5" />
    <path d="M51.56 89.4H52.1" stroke="black" stroke-width="0.5" />
    <path d="M52.1 89.4V90.73" stroke="black" stroke-width="0.5" />
    <path d="M52.1 90.73H51.56" stroke="black" stroke-width="0.5" />
    <path d="M51.56 90.73V89.4" stroke="black" stroke-width="0.5" />
    <path d="M41.46 89.4H41.99" stroke="black" stroke-width="0.5" />
    <path d="M41.99 89.4V90.73" stroke="black" stroke-width="0.5" />
    <path d="M41.99 90.73H41.46" stroke="black" stroke-width="0.5" />
    <path d="M41.46 90.73V89.4" stroke="black" stroke-width="0.5" />
    <path d="M362.01 163.74V138.11" stroke="black" stroke-width="0.5" />
    <path d="M362.01 163.74H350.74" stroke="black" stroke-width="0.5" />
    <path d="M328.5 163.9V158.12" stroke="black" stroke-width="0.5" />
    <path d="M327.17 162.57V158.12" stroke="black" stroke-width="0.5" />
    <path d="M276.37 95.12H247.31" stroke="black" stroke-width="0.5" />
    <path d="M276.37 78.49V81.68" stroke="black" stroke-width="0.5" />
    <path d="M274.44 93.79H248.64" stroke="black" stroke-width="0.5" />
    <path d="M274.44 78.49V81.68" stroke="black" stroke-width="0.5" />
    <path d="M204.56 163.74V120.65" stroke="black" stroke-width="0.5" />
    <path d="M276.37 2.42999H309.88" stroke="black" stroke-width="0.5" />
    <path d="M275.04 42.46V40.86" stroke="black" stroke-width="0.5" />
    <path d="M275.04 31.29V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M50.3 160.55V142.13" stroke="black" stroke-width="0.5" />
    <path d="M51.63 160.55V129.81" stroke="black" stroke-width="0.5" />
    <path d="M274.44 93.79V70.92" stroke="black" stroke-width="0.5" />
    <path d="M248.64 70.92H274.44" stroke="black" stroke-width="0.5" />
    <path d="M248.64 66.66H274.44" stroke="black" stroke-width="0.5" />
    <path d="M248.64 43.79V55.49" stroke="black" stroke-width="0.5" />
    <path d="M247.31 42.46V55.49" stroke="black" stroke-width="0.5" />
    <path d="M274.44 66.66V43.79" stroke="black" stroke-width="0.5" />
    <path d="M248.64 70.92V71.45" stroke="black" stroke-width="0.5" />
    <path d="M247.31 66.13H248.64" stroke="black" stroke-width="0.5" />
    <path d="M247.31 71.45H248.64" stroke="black" stroke-width="0.5" />
    <path d="M247.31 55.49H248.64" stroke="black" stroke-width="0.5" />
    <path d="M247.31 82.09H248.64" stroke="black" stroke-width="0.5" />
    <path d="M248.64 66.13V66.66" stroke="black" stroke-width="0.5" />
    <path d="M248.64 82.09V93.79" stroke="black" stroke-width="0.5" />
    <path d="M53.96 71.45H58.21" stroke="black" stroke-width="0.5" />
    <path d="M196.25 2.42999V32.68" stroke="black" stroke-width="0.5" />
    <path d="M194.92 2.42999V66.26" stroke="black" stroke-width="0.5" />
    <path d="M196.25 34.01V66.26" stroke="black" stroke-width="0.5" />
    <path d="M52.63 67.19H20.71" stroke="black" stroke-width="0.5" />
    <path d="M52.63 67.19V71.45" stroke="black" stroke-width="0.5" />
    <path d="M58.21 67.59V71.45" stroke="black" stroke-width="0.5" />
    <path d="M52.63 71.45V89.4" stroke="black" stroke-width="0.5" />
    <path d="M110.87 103.36H109.54" stroke="black" stroke-width="0.5" />
    <path d="M53.96 71.45V84.15" stroke="black" stroke-width="0.5" />
    <path d="M74.7 67.59V72.98" stroke="black" stroke-width="0.5" />
    <path d="M76.03 67.59V72.98" stroke="black" stroke-width="0.5" />
    <path d="M74.7 84.15H53.96" stroke="black" stroke-width="0.5" />
    <path d="M74.7 85.48H53.96" stroke="black" stroke-width="0.5" />
    <path d="M53.96 85.48V102.03" stroke="black" stroke-width="0.5" />
    <path d="M74.7 85.48V90.86" stroke="black" stroke-width="0.5" />
    <path d="M76.03 101.5H74.7" stroke="black" stroke-width="0.5" />
    <path d="M76.03 90.86H74.7" stroke="black" stroke-width="0.5" />
    <path d="M76.03 72.98H74.7" stroke="black" stroke-width="0.5" />
    <path d="M74.7 101.5V102.03" stroke="black" stroke-width="0.5" />
    <path d="M76.03 101.5V103.36" stroke="black" stroke-width="0.5" />
    <path d="M76.03 83.62V90.86" stroke="black" stroke-width="0.5" />
    <path d="M74.7 102.03H53.96" stroke="black" stroke-width="0.5" />
    <path d="M55.55 66.26H85.87" stroke="black" stroke-width="0.5" />
    <path d="M58.21 67.59H74.7" stroke="black" stroke-width="0.5" />
    <path d="M95.17 24.77H130.83" stroke="black" stroke-width="0.5" />
    <path d="M94.62 26.1H112.34" stroke="black" stroke-width="0.5" />
    <path d="M113.67 40.93V26.1" stroke="black" stroke-width="0.5" />
    <path d="M112.34 40.93V26.1" stroke="black" stroke-width="0.5" />
    <path d="M113.67 26.1H131.38" stroke="black" stroke-width="0.5" />
    <path d="M94.62 26.1L92.76 24.23" stroke="black" stroke-width="0.5" />
    <path d="M95.17 24.77L93.7 23.29" stroke="black" stroke-width="0.5" />
    <path d="M247.31 82.09V95.12" stroke="black" stroke-width="0.5" />
    <path d="M328.5 59.35H362.01" stroke="black" stroke-width="0.5" />
    <path d="M328.5 58.02H365.1" stroke="black" stroke-width="0.5" />
    <path d="M328.5 58.02V57.49" stroke="black" stroke-width="0.5" />
    <path d="M276.37 93.79V40.86" stroke="black" stroke-width="0.5" />
    <path d="M276.37 31.29V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M282.75 93.79V55.49" stroke="black" stroke-width="0.5" />
    <path d="M328.5 107.85H362.01" stroke="black" stroke-width="0.5" />
    <path d="M328.5 109.18H363.94" stroke="black" stroke-width="0.5" />
    <path d="M328.5 107.85V107.32" stroke="black" stroke-width="0.5" />
    <path d="M327.17 57.49H328.5" stroke="black" stroke-width="0.5" />
    <path d="M327.17 107.32H328.5" stroke="black" stroke-width="0.5" />
    <path d="M327.17 109.71H328.5" stroke="black" stroke-width="0.5" />
    <path d="M327.17 96.68H328.5" stroke="black" stroke-width="0.5" />
    <path d="M327.17 46.85H328.5" stroke="black" stroke-width="0.5" />
    <path d="M327.17 120.35H328.5" stroke="black" stroke-width="0.5" />
    <path d="M327.17 96.68V57.49" stroke="black" stroke-width="0.5" />
    <path d="M328.5 96.68V59.35" stroke="black" stroke-width="0.5" />
    <path d="M328.5 109.71V109.18" stroke="black" stroke-width="0.5" />
    <path d="M327.17 109.71V107.32" stroke="black" stroke-width="0.5" />
    <path d="M328.5 46.85V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M327.17 46.85V12.01" stroke="black" stroke-width="0.5" />
    <path d="M62.39 120.65H60.79" stroke="black" stroke-width="0.5" />
    <path d="M62.39 119.32H60.24" stroke="black" stroke-width="0.5" />
    <path d="M87.37 119.32H86.04" stroke="black" stroke-width="0.5" />
    <path d="M159.16 67.59H172.31" stroke="black" stroke-width="0.5" />
    <path d="M140.13 66.26H170.45" stroke="black" stroke-width="0.5" />
    <path d="M20.71 90.73H26.03" stroke="black" stroke-width="0.5" />
    <path d="M20.71 89.4H26.03" stroke="black" stroke-width="0.5" />
    <path d="M52.1 89.4V90.73" stroke="black" stroke-width="0.5" />
    <path d="M52.1 90.73H52.63" stroke="black" stroke-width="0.5" />
    <path d="M52.1 89.4H52.63" stroke="black" stroke-width="0.5" />
    <path d="M52.63 90.73V103.36" stroke="black" stroke-width="0.5" />
    <path d="M22.99 114.82L29.76 121.59" stroke="black" stroke-width="0.5" />
    <path d="M14.86 97.11V109.1" stroke="black" stroke-width="0.5" />
    <path d="M50.3 140.25V131.14" stroke="black" stroke-width="0.5" />
    <path d="M29.76 121.59L30.7 122.53" stroke="black" stroke-width="0.5" />
    <path d="M338.07 106.79L337.89 104.92" stroke="black" stroke-width="0.5" />
    <path d="M337.89 104.92L337.34 103.12" stroke="black" stroke-width="0.5" />
    <path d="M337.34 103.12L336.46 101.47" stroke="black" stroke-width="0.5" />
    <path d="M336.46 101.47L335.27 100.02" stroke="black" stroke-width="0.5" />
    <path d="M335.27 100.02L333.82 98.83" stroke="black" stroke-width="0.5" />
    <path d="M333.82 98.83L332.16 97.94" stroke="black" stroke-width="0.5" />
    <path d="M332.16 97.94L330.37 97.4" stroke="black" stroke-width="0.5" />
    <path d="M330.37 97.4L328.5 97.21" stroke="black" stroke-width="0.5" />
    <path d="M328.5 106.32V106.79" stroke="black" stroke-width="0.5" />
    <path d="M328.5 106.79H338.07" stroke="black" stroke-width="0.5" />
    <path d="M338.07 106.79V106.32" stroke="black" stroke-width="0.5" />
    <path d="M338.07 106.32H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 106.79V107.32" stroke="black" stroke-width="0.5" />
    <path d="M328.5 107.32H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 107.32V106.79" stroke="black" stroke-width="0.5" />
    <path d="M327.17 106.79H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 96.68V97.21" stroke="black" stroke-width="0.5" />
    <path d="M328.5 97.21H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 97.21V96.68" stroke="black" stroke-width="0.5" />
    <path d="M327.17 96.68H328.5" stroke="black" stroke-width="0.5" />
    <path d="M85.61 100.97L85.42 99.1" stroke="black" stroke-width="0.5" />
    <path d="M85.42 99.1L84.88 97.31" stroke="black" stroke-width="0.5" />
    <path d="M84.88 97.31L83.99 95.65" stroke="black" stroke-width="0.5" />
    <path d="M83.99 95.65L82.8 94.2" stroke="black" stroke-width="0.5" />
    <path d="M82.8 94.2L81.35 93.01" stroke="black" stroke-width="0.5" />
    <path d="M81.35 93.01L79.7 92.12" stroke="black" stroke-width="0.5" />
    <path d="M79.7 92.12L77.9 91.58" stroke="black" stroke-width="0.5" />
    <path d="M77.9 91.58L76.03 91.4" stroke="black" stroke-width="0.5" />
    <path d="M76.03 100.5V100.97" stroke="black" stroke-width="0.5" />
    <path d="M76.03 100.97H85.61" stroke="black" stroke-width="0.5" />
    <path d="M85.61 100.97V100.5" stroke="black" stroke-width="0.5" />
    <path d="M85.61 100.5H76.03" stroke="black" stroke-width="0.5" />
    <path d="M76.03 100.97V101.5" stroke="black" stroke-width="0.5" />
    <path d="M76.03 101.5H74.7" stroke="black" stroke-width="0.5" />
    <path d="M74.7 101.5V100.97" stroke="black" stroke-width="0.5" />
    <path d="M74.7 100.97H76.03" stroke="black" stroke-width="0.5" />
    <path d="M76.03 90.86V91.4" stroke="black" stroke-width="0.5" />
    <path d="M76.03 91.4H74.7" stroke="black" stroke-width="0.5" />
    <path d="M74.7 91.4V90.86" stroke="black" stroke-width="0.5" />
    <path d="M74.7 90.86H76.03" stroke="black" stroke-width="0.5" />
    <path d="M85.61 83.08L85.42 81.22" stroke="black" stroke-width="0.5" />
    <path d="M85.42 81.22L84.88 79.42" stroke="black" stroke-width="0.5" />
    <path d="M84.88 79.42L83.99 77.76" stroke="black" stroke-width="0.5" />
    <path d="M83.99 77.76L82.8 76.31" stroke="black" stroke-width="0.5" />
    <path d="M82.8 76.31L81.35 75.12" stroke="black" stroke-width="0.5" />
    <path d="M81.35 75.12L79.7 74.24" stroke="black" stroke-width="0.5" />
    <path d="M79.7 74.24L77.9 73.69" stroke="black" stroke-width="0.5" />
    <path d="M77.9 73.69L76.03 73.51" stroke="black" stroke-width="0.5" />
    <path d="M76.03 82.62V83.08" stroke="black" stroke-width="0.5" />
    <path d="M76.03 83.08H85.61" stroke="black" stroke-width="0.5" />
    <path d="M85.61 83.08V82.62" stroke="black" stroke-width="0.5" />
    <path d="M85.61 82.62H76.03" stroke="black" stroke-width="0.5" />
    <path d="M76.03 83.08V83.62" stroke="black" stroke-width="0.5" />
    <path d="M76.03 83.62H74.7" stroke="black" stroke-width="0.5" />
    <path d="M74.7 83.62V83.08" stroke="black" stroke-width="0.5" />
    <path d="M74.7 83.08H76.03" stroke="black" stroke-width="0.5" />
    <path d="M76.03 72.98V73.51" stroke="black" stroke-width="0.5" />
    <path d="M76.03 73.51H74.7" stroke="black" stroke-width="0.5" />
    <path d="M74.7 73.51V72.98" stroke="black" stroke-width="0.5" />
    <path d="M74.7 72.98H76.03" stroke="black" stroke-width="0.5" />
    <path d="M258.22 65.6L258.03 63.73" stroke="black" stroke-width="0.5" />
    <path d="M258.03 63.73L257.49 61.93" stroke="black" stroke-width="0.5" />
    <path d="M257.49 61.93L256.6 60.28" stroke="black" stroke-width="0.5" />
    <path d="M256.6 60.28L255.41 58.83" stroke="black" stroke-width="0.5" />
    <path d="M255.41 58.83L253.96 57.64" stroke="black" stroke-width="0.5" />
    <path d="M253.96 57.64L252.3 56.75" stroke="black" stroke-width="0.5" />
    <path d="M252.3 56.75L250.51 56.21" stroke="black" stroke-width="0.5" />
    <path d="M250.51 56.21L248.64 56.02" stroke="black" stroke-width="0.5" />
    <path d="M248.64 65.13V65.6" stroke="black" stroke-width="0.5" />
    <path d="M248.64 65.6H258.22" stroke="black" stroke-width="0.5" />
    <path d="M258.22 65.6V65.13" stroke="black" stroke-width="0.5" />
    <path d="M258.22 65.13H248.64" stroke="black" stroke-width="0.5" />
    <path d="M248.64 65.6V66.13" stroke="black" stroke-width="0.5" />
    <path d="M248.64 66.13H247.31" stroke="black" stroke-width="0.5" />
    <path d="M247.31 66.13V65.6" stroke="black" stroke-width="0.5" />
    <path d="M247.31 65.6H248.64" stroke="black" stroke-width="0.5" />
    <path d="M248.64 55.49V56.02" stroke="black" stroke-width="0.5" />
    <path d="M248.64 56.02H247.31" stroke="black" stroke-width="0.5" />
    <path d="M247.31 56.02V55.49" stroke="black" stroke-width="0.5" />
    <path d="M247.31 55.49H248.64" stroke="black" stroke-width="0.5" />
    <path d="M258.22 71.98L258.03 73.85" stroke="black" stroke-width="0.5" />
    <path d="M258.03 73.85L257.49 75.64" stroke="black" stroke-width="0.5" />
    <path d="M257.49 75.64L256.6 77.3" stroke="black" stroke-width="0.5" />
    <path d="M256.6 77.3L255.41 78.75" stroke="black" stroke-width="0.5" />
    <path d="M255.41 78.75L253.96 79.94" stroke="black" stroke-width="0.5" />
    <path d="M253.96 79.94L252.3 80.83" stroke="black" stroke-width="0.5" />
    <path d="M252.3 80.83L250.51 81.37" stroke="black" stroke-width="0.5" />
    <path d="M250.51 81.37L248.64 81.55" stroke="black" stroke-width="0.5" />
    <path d="M248.64 72.45V71.98" stroke="black" stroke-width="0.5" />
    <path d="M248.64 71.98H258.22" stroke="black" stroke-width="0.5" />
    <path d="M258.22 71.98V72.45" stroke="black" stroke-width="0.5" />
    <path d="M258.22 72.45H248.64" stroke="black" stroke-width="0.5" />
    <path d="M248.64 71.98V71.45" stroke="black" stroke-width="0.5" />
    <path d="M248.64 71.45H247.31" stroke="black" stroke-width="0.5" />
    <path d="M247.31 71.45V71.98" stroke="black" stroke-width="0.5" />
    <path d="M247.31 71.98H248.64" stroke="black" stroke-width="0.5" />
    <path d="M248.64 82.09V81.55" stroke="black" stroke-width="0.5" />
    <path d="M248.64 81.55H247.31" stroke="black" stroke-width="0.5" />
    <path d="M247.31 81.55V82.09" stroke="black" stroke-width="0.5" />
    <path d="M247.31 82.09H248.64" stroke="black" stroke-width="0.5" />
    <path d="M22.57 90.73V103.49" stroke="black" stroke-width="0.5" />
    <path d="M22.57 103.49L35.22 116.14" stroke="black" stroke-width="0.5" />
    <path d="M109.54 89.27V103.36" stroke="black" stroke-width="0.5" />
    <path d="M110.87 89.27V103.36" stroke="black" stroke-width="0.5" />
    <path d="M110.87 89.27H109.54" stroke="black" stroke-width="0.5" />
    <path d="M248.64 42.46H275.04" stroke="black" stroke-width="0.5" />
    <path d="M275.04 40.86H276.37" stroke="black" stroke-width="0.5" />
    <path d="M275.04 31.29H276.37" stroke="black" stroke-width="0.5" />
    <path d="M227.63 10.68H228.16" stroke="black" stroke-width="0.5" />
    <path d="M226.3 12.01H228.16" stroke="black" stroke-width="0.5" />
    <path d="M227.63 10.68V1.10001" stroke="black" stroke-width="0.5" />
    <path d="M226.3 12.01V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M228.16 12.01V10.68" stroke="black" stroke-width="0.5" />
    <path
      d="M228.69 1.10001L230.56 1.28999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M230.56 1.28998L232.36 1.82999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M232.36 1.83002L234.01 2.72002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M234.01 2.72L235.46 3.91" stroke="black" stroke-width="0.5" />
    <path d="M235.46 3.91L236.65 5.36" stroke="black" stroke-width="0.5" />
    <path
      d="M236.65 5.35999L237.54 7.00998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M237.54 7.01001L238.08 8.81001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M238.08 8.81L238.27 10.68" stroke="black" stroke-width="0.5" />
    <path d="M229.16 10.68H228.69" stroke="black" stroke-width="0.5" />
    <path d="M228.69 10.68V1.10001" stroke="black" stroke-width="0.5" />
    <path d="M228.69 1.10001H229.16" stroke="black" stroke-width="0.5" />
    <path d="M229.16 1.10001V10.68" stroke="black" stroke-width="0.5" />
    <path d="M228.69 10.68H228.16" stroke="black" stroke-width="0.5" />
    <path d="M228.16 10.68V12.01" stroke="black" stroke-width="0.5" />
    <path d="M228.16 12.01H228.69" stroke="black" stroke-width="0.5" />
    <path d="M228.69 12.01V10.68" stroke="black" stroke-width="0.5" />
    <path d="M238.8 10.68H238.27" stroke="black" stroke-width="0.5" />
    <path d="M238.27 10.68V12.01" stroke="black" stroke-width="0.5" />
    <path d="M238.27 12.01H238.8" stroke="black" stroke-width="0.5" />
    <path d="M238.8 12.01V10.68" stroke="black" stroke-width="0.5" />
    <path d="M244.14 12.01L253.71 2.42999" stroke="black" stroke-width="0.5" />
    <path d="M243.59 12.01H244.14" stroke="black" stroke-width="0.5" />
    <path d="M363.94 138.11H362.01" stroke="black" stroke-width="0.5" />
    <path d="M362.01 107.85V59.35" stroke="black" stroke-width="0.5" />
    <path d="M363.34 107.85V71.45" stroke="black" stroke-width="0.5" />
    <path d="M363.34 65.93V59.35" stroke="black" stroke-width="0.5" />
    <path d="M363.34 59.35H365.27" stroke="black" stroke-width="0.5" />
    <path d="M309.88 12.01H316" stroke="black" stroke-width="0.5" />
    <path d="M326.64 12.01V10.68" stroke="black" stroke-width="0.5" />
    <path d="M316 12.01V10.68" stroke="black" stroke-width="0.5" />
    <path d="M326.64 10.68H327.17" stroke="black" stroke-width="0.5" />
    <path d="M326.64 12.01H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 10.68V1.10001" stroke="black" stroke-width="0.5" />
    <path d="M326.1 1.10001L324.24 1.28999" stroke="black" stroke-width="0.5" />
    <path
      d="M324.24 1.28998L322.44 1.82999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M322.44 1.83002L320.78 2.72002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M320.78 2.72L319.33 3.91" stroke="black" stroke-width="0.5" />
    <path d="M319.33 3.91L318.14 5.36" stroke="black" stroke-width="0.5" />
    <path
      d="M318.14 5.35999L317.26 7.00998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M317.26 7.01001L316.71 8.81001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M316.71 8.81L316.53 10.68" stroke="black" stroke-width="0.5" />
    <path d="M325.64 10.68H326.1" stroke="black" stroke-width="0.5" />
    <path d="M326.1 10.68V1.10001" stroke="black" stroke-width="0.5" />
    <path d="M326.1 1.10001H325.64" stroke="black" stroke-width="0.5" />
    <path d="M325.64 1.10001V10.68" stroke="black" stroke-width="0.5" />
    <path d="M326.1 10.68H326.64" stroke="black" stroke-width="0.5" />
    <path d="M326.64 10.68V12.01" stroke="black" stroke-width="0.5" />
    <path d="M326.64 12.01H326.1" stroke="black" stroke-width="0.5" />
    <path d="M326.1 12.01V10.68" stroke="black" stroke-width="0.5" />
    <path d="M316 10.68H316.53" stroke="black" stroke-width="0.5" />
    <path d="M316.53 10.68V12.01" stroke="black" stroke-width="0.5" />
    <path d="M316.53 12.01H316" stroke="black" stroke-width="0.5" />
    <path d="M316 12.01V10.68" stroke="black" stroke-width="0.5" />
    <path d="M98.71 66.26H99.17" stroke="black" stroke-width="0.5" />
    <path d="M99.17 66.26V53.5" stroke="black" stroke-width="0.5" />
    <path d="M99.17 53.5H98.71" stroke="black" stroke-width="0.5" />
    <path d="M98.71 53.5V66.26" stroke="black" stroke-width="0.5" />
    <path d="M99.17 66.26H99.7" stroke="black" stroke-width="0.5" />
    <path d="M99.7 66.26V67.59" stroke="black" stroke-width="0.5" />
    <path d="M99.7 67.59H99.17" stroke="black" stroke-width="0.5" />
    <path d="M99.17 67.59V66.26" stroke="black" stroke-width="0.5" />
    <path d="M85.87 66.26H86.41" stroke="black" stroke-width="0.5" />
    <path d="M86.41 66.26V67.59" stroke="black" stroke-width="0.5" />
    <path d="M86.41 67.59H85.87" stroke="black" stroke-width="0.5" />
    <path d="M85.87 67.59V66.26" stroke="black" stroke-width="0.5" />
    <path d="M127.3 66.26H126.83" stroke="black" stroke-width="0.5" />
    <path d="M126.83 66.26V53.5" stroke="black" stroke-width="0.5" />
    <path d="M126.83 53.5H127.3" stroke="black" stroke-width="0.5" />
    <path d="M127.3 53.5V66.26" stroke="black" stroke-width="0.5" />
    <path d="M126.83 66.26H126.3" stroke="black" stroke-width="0.5" />
    <path d="M126.3 66.26V67.59" stroke="black" stroke-width="0.5" />
    <path d="M126.3 67.59H126.83" stroke="black" stroke-width="0.5" />
    <path d="M126.83 67.59V66.26" stroke="black" stroke-width="0.5" />
    <path d="M140.13 66.26H139.6" stroke="black" stroke-width="0.5" />
    <path d="M139.6 66.26V67.59" stroke="black" stroke-width="0.5" />
    <path d="M139.6 67.59H140.13" stroke="black" stroke-width="0.5" />
    <path d="M140.13 67.59V66.26" stroke="black" stroke-width="0.5" />
    <path d="M18.58 97.11V107.78" stroke="black" stroke-width="0.5" />
    <path d="M16.19 90.73V97.11" stroke="black" stroke-width="0.5" />
    <path d="M14.86 97.11V90.73" stroke="black" stroke-width="0.5" />
    <path d="M14.86 97.11H16.19" stroke="black" stroke-width="0.5" />
    <path d="M76.03 103.36H52.63" stroke="black" stroke-width="0.5" />
    <path d="M327.17 129.39H328.5" stroke="black" stroke-width="0.5" />
    <path d="M327.17 158.12H328.5" stroke="black" stroke-width="0.5" />
    <path d="M327.17 129.39V120.35" stroke="black" stroke-width="0.5" />
    <path d="M328.5 129.39V120.35" stroke="black" stroke-width="0.5" />
    <path d="M327.17 129.39V135.25" stroke="black" stroke-width="0.5" />
    <path d="M328.5 129.39V135.25" stroke="black" stroke-width="0.5" />
    <path d="M327.83 129.93V135.25" stroke="black" stroke-width="0.5" />
    <path d="M327.17 129.93V129.39" stroke="black" stroke-width="0.5" />
    <path d="M327.17 129.39H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 129.39V129.93" stroke="black" stroke-width="0.5" />
    <path d="M328.5 129.93H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 135.78V135.25" stroke="black" stroke-width="0.5" />
    <path d="M327.17 135.25H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 135.25V135.78" stroke="black" stroke-width="0.5" />
    <path d="M328.5 135.78H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 152.27V135.78" stroke="black" stroke-width="0.5" />
    <path d="M328.5 152.27V135.78" stroke="black" stroke-width="0.5" />
    <path d="M327.83 151.74V135.78" stroke="black" stroke-width="0.5" />
    <path d="M327.17 151.74V152.27" stroke="black" stroke-width="0.5" />
    <path d="M327.17 152.27H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 152.27V151.74" stroke="black" stroke-width="0.5" />
    <path d="M328.5 151.74H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 158.12V152.27" stroke="black" stroke-width="0.5" />
    <path d="M328.5 158.12V152.27" stroke="black" stroke-width="0.5" />
    <path d="M327.83 157.59V152.27" stroke="black" stroke-width="0.5" />
    <path d="M327.17 157.59V158.12" stroke="black" stroke-width="0.5" />
    <path d="M327.17 158.12H328.5" stroke="black" stroke-width="0.5" />
    <path d="M328.5 158.12V157.59" stroke="black" stroke-width="0.5" />
    <path d="M328.5 157.59H327.17" stroke="black" stroke-width="0.5" />
    <path d="M327.17 46.85H328.5" stroke="black" stroke-width="0.5" />
    <path d="M248.64 43.79H274.44" stroke="black" stroke-width="0.5" />
    <path d="M93.7 23.29L92.76 24.23" stroke="black" stroke-width="0.5" />
    <path d="M326.04 165.67V162.57" stroke="black" stroke-width="0.5" />
    <path d="M326.04 162.57H327.17" stroke="black" stroke-width="0.5" />
    <path d="M334.88 163.9V125.41" stroke="black" stroke-width="0.5" />
    <path d="M355.63 163.74V109.18" stroke="black" stroke-width="0.5" />
    <path d="M328.5 124.87H334.35" stroke="black" stroke-width="0.5" />
    <path d="M355.63 107.85V59.35" stroke="black" stroke-width="0.5" />
    <path d="M334.88 89.66V76.21" stroke="black" stroke-width="0.5" />
    <path d="M329.03 90.19H334.35" stroke="black" stroke-width="0.5" />
    <path d="M329.03 76.21H334.88" stroke="black" stroke-width="0.5" />
    <path d="M247.31 66.13V71.45" stroke="black" stroke-width="0.5" />
    <path d="M55.55 2.42999V66.26" stroke="black" stroke-width="0.5" />
    <path d="M125.77 40.93H113.67" stroke="black" stroke-width="0.5" />
    <path d="M124.44 42.86H101.57" stroke="black" stroke-width="0.5" />
    <path d="M124.44 68.66V42.86" stroke="black" stroke-width="0.5" />
    <path d="M125.77 69.99V67.59" stroke="black" stroke-width="0.5" />
    <path d="M101.57 42.86V68.66" stroke="black" stroke-width="0.5" />
    <path d="M100.24 40.93V66.26" stroke="black" stroke-width="0.5" />
    <path d="M170.45 2.42999V66.26" stroke="black" stroke-width="0.5" />
    <path d="M171.78 2.42999V66.26" stroke="black" stroke-width="0.5" />
    <path d="M101.57 68.66H113.27" stroke="black" stroke-width="0.5" />
    <path d="M125.77 66.26H126.3" stroke="black" stroke-width="0.5" />
    <path d="M123.91 69.99V68.66" stroke="black" stroke-width="0.5" />
    <path d="M113.27 69.99V68.66" stroke="black" stroke-width="0.5" />
    <path d="M123.38 59.08L121.51 59.27" stroke="black" stroke-width="0.5" />
    <path d="M121.51 59.27L119.71 59.81" stroke="black" stroke-width="0.5" />
    <path d="M119.71 59.81L118.06 60.69" stroke="black" stroke-width="0.5" />
    <path d="M118.06 60.69L116.6 61.89" stroke="black" stroke-width="0.5" />
    <path d="M116.6 61.89L115.41 63.34" stroke="black" stroke-width="0.5" />
    <path d="M115.41 63.34L114.53 64.99" stroke="black" stroke-width="0.5" />
    <path d="M114.53 64.99L113.98 66.79" stroke="black" stroke-width="0.5" />
    <path d="M113.98 66.79L113.8 68.66" stroke="black" stroke-width="0.5" />
    <path d="M122.91 68.66H123.38" stroke="black" stroke-width="0.5" />
    <path d="M123.38 68.66V59.08" stroke="black" stroke-width="0.5" />
    <path d="M123.38 59.08H122.91" stroke="black" stroke-width="0.5" />
    <path d="M122.91 59.08V68.66" stroke="black" stroke-width="0.5" />
    <path d="M123.38 68.66H123.91" stroke="black" stroke-width="0.5" />
    <path d="M123.91 68.66V69.99" stroke="black" stroke-width="0.5" />
    <path d="M123.91 69.99H123.38" stroke="black" stroke-width="0.5" />
    <path d="M123.38 69.99V68.66" stroke="black" stroke-width="0.5" />
    <path d="M113.27 68.66H113.8" stroke="black" stroke-width="0.5" />
    <path d="M113.8 68.66V69.99" stroke="black" stroke-width="0.5" />
    <path d="M113.8 69.99H113.27" stroke="black" stroke-width="0.5" />
    <path d="M113.27 69.99V68.66" stroke="black" stroke-width="0.5" />
    <path d="M123.91 69.99H125.77" stroke="black" stroke-width="0.5" />
    <path d="M123.91 68.66H124.44" stroke="black" stroke-width="0.5" />
    <path d="M126.3 67.59V66.26" stroke="black" stroke-width="0.5" />
    <path d="M99.7 66.26V67.59" stroke="black" stroke-width="0.5" />
    <path d="M99.7 66.26H100.24" stroke="black" stroke-width="0.5" />
    <path d="M171.78 66.26H172.31" stroke="black" stroke-width="0.5" />
    <path d="M182.95 67.59H194.92" stroke="black" stroke-width="0.5" />
    <path d="M182.95 66.26H194.92" stroke="black" stroke-width="0.5" />
    <path d="M172.84 56.69L174.71 56.87" stroke="black" stroke-width="0.5" />
    <path d="M174.71 56.87L176.51 57.42" stroke="black" stroke-width="0.5" />
    <path d="M176.51 57.42L178.16 58.3" stroke="black" stroke-width="0.5" />
    <path d="M178.16 58.3L179.61 59.49" stroke="black" stroke-width="0.5" />
    <path d="M179.61 59.49L180.8 60.94" stroke="black" stroke-width="0.5" />
    <path d="M180.8 60.94L181.69 62.6" stroke="black" stroke-width="0.5" />
    <path d="M181.69 62.6L182.23 64.39" stroke="black" stroke-width="0.5" />
    <path d="M182.23 64.39L182.42 66.26" stroke="black" stroke-width="0.5" />
    <path d="M173.31 66.26H172.84" stroke="black" stroke-width="0.5" />
    <path d="M172.84 66.26V56.69" stroke="black" stroke-width="0.5" />
    <path d="M172.84 56.69H173.31" stroke="black" stroke-width="0.5" />
    <path d="M173.31 56.69V66.26" stroke="black" stroke-width="0.5" />
    <path d="M172.84 66.26H172.31" stroke="black" stroke-width="0.5" />
    <path d="M172.31 66.26V67.59" stroke="black" stroke-width="0.5" />
    <path d="M172.31 67.59H172.84" stroke="black" stroke-width="0.5" />
    <path d="M172.84 67.59V66.26" stroke="black" stroke-width="0.5" />
    <path d="M182.95 66.26H182.42" stroke="black" stroke-width="0.5" />
    <path d="M182.42 66.26V67.59" stroke="black" stroke-width="0.5" />
    <path d="M182.42 67.59H182.95" stroke="black" stroke-width="0.5" />
    <path d="M182.95 67.59V66.26" stroke="black" stroke-width="0.5" />
    <path d="M76.03 67.59H85.87" stroke="black" stroke-width="0.5" />
    <path d="M27.1 67.19V89.3" stroke="black" stroke-width="0.5" />
    <path d="M20.71 89.3H27.1" stroke="black" stroke-width="0.5" />
    <path d="M205.89 150.97V163.74" stroke="black" stroke-width="0.5" />
    <path d="M211.21 163.74V151.5" stroke="black" stroke-width="0.5" />
    <path d="M205.89 150.97H210.68" stroke="black" stroke-width="0.5" />
    <path
      d="M211.21 151.5C211.209 151.36 211.153 151.225 211.054 151.126C210.955 151.027 210.82 150.971 210.68 150.97"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M194.92 67.59H226.3" stroke="black" stroke-width="0.5" />
    <path d="M196.25 66.26H226.3" stroke="black" stroke-width="0.5" />
    <path d="M112.34 40.93H100.24" stroke="black" stroke-width="0.5" />
    <path d="M60.79 120.65L51.63 129.81" stroke="black" stroke-width="0.5" />
    <path d="M60.24 119.32L53.84 125.73" stroke="black" stroke-width="0.5" />
    <path d="M52.9 126.67L52.52 126.29" stroke="black" stroke-width="0.5" />
    <path d="M53.84 125.73L53.46 125.35" stroke="black" stroke-width="0.5" />
    <path d="M40.68 112.55L30.7 122.53" stroke="black" stroke-width="0.5" />
    <path d="M39.74 111.61L29.76 121.59" stroke="black" stroke-width="0.5" />
    <path d="M53.46 125.35L52.52 126.29" stroke="black" stroke-width="0.5" />
    <path d="M23.93 113.88L22.05 115.76" stroke="black" stroke-width="0.5" />
    <path d="M39.74 111.61L40.68 112.55" stroke="black" stroke-width="0.5" />
    <path d="M52.9 126.67L49.36 130.2" stroke="black" stroke-width="0.5" />
    <path d="M100.24 69.99H113.27" stroke="black" stroke-width="0.5" />
    <path d="M99.7 67.59H100.24" stroke="black" stroke-width="0.5" />
    <path d="M125.77 67.59H126.3" stroke="black" stroke-width="0.5" />
    <path d="M85.87 66.26V67.59" stroke="black" stroke-width="0.5" />
    <path d="M100.24 67.59V69.99" stroke="black" stroke-width="0.5" />
    <path d="M140.13 67.59V66.26" stroke="black" stroke-width="0.5" />
    <path d="M125.77 66.26V40.93" stroke="black" stroke-width="0.5" />
    <path d="M117.79 12.01L115.92 11.82" stroke="black" stroke-width="0.5" />
    <path d="M115.92 11.82L114.12 11.28" stroke="black" stroke-width="0.5" />
    <path d="M114.12 11.28L112.47 10.39" stroke="black" stroke-width="0.5" />
    <path d="M112.47 10.39L111.02 9.20001" stroke="black" stroke-width="0.5" />
    <path d="M111.02 9.2L109.83 7.75" stroke="black" stroke-width="0.5" />
    <path d="M109.83 7.75L108.94 6.10001" stroke="black" stroke-width="0.5" />
    <path d="M108.94 6.09999L108.4 4.29999" stroke="black" stroke-width="0.5" />
    <path d="M108.4 4.3L108.21 2.42999" stroke="black" stroke-width="0.5" />
    <path d="M117.32 2.42999H117.79" stroke="black" stroke-width="0.5" />
    <path d="M117.79 2.42999V12.01" stroke="black" stroke-width="0.5" />
    <path d="M117.79 12.01H117.32" stroke="black" stroke-width="0.5" />
    <path d="M117.32 12.01V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M117.79 2.42999H118.32" stroke="black" stroke-width="0.5" />
    <path d="M118.32 2.42999V1.10001" stroke="black" stroke-width="0.5" />
    <path d="M118.32 1.10001H117.79" stroke="black" stroke-width="0.5" />
    <path d="M117.79 1.10001V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M107.68 2.42999H108.21" stroke="black" stroke-width="0.5" />
    <path d="M108.21 2.42999V1.10001" stroke="black" stroke-width="0.5" />
    <path d="M108.21 1.10001H107.68" stroke="black" stroke-width="0.5" />
    <path d="M107.68 1.10001V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M86.93 16.52L85.98 17.46" stroke="black" stroke-width="0.5" />
    <path d="M92.76 24.23L85.98 17.46" stroke="black" stroke-width="0.5" />
    <path d="M93.7 23.29L86.93 16.52" stroke="black" stroke-width="0.5" />
    <path d="M93.32 22.92L92.38 23.86" stroke="black" stroke-width="0.5" />
    <path d="M87.3 16.9L86.36 17.84" stroke="black" stroke-width="0.5" />
    <path d="M86.83 17.37L92.85 23.39" stroke="black" stroke-width="0.5" />
    <path d="M86.93 16.52L83.54 13.14" stroke="black" stroke-width="0.5" />
    <path d="M86.93 16.52L85.98 17.46" stroke="black" stroke-width="0.5" />
    <path d="M85.98 17.46L82.6 14.08" stroke="black" stroke-width="0.5" />
    <path d="M84.39 13.94H93.41" stroke="black" stroke-width="0.5" />
    <path d="M97.87 18.39L93.41 13.94" stroke="black" stroke-width="0.5" />
    <path d="M97.87 18.39H128.18" stroke="black" stroke-width="0.5" />
    <path d="M128.18 18.39L132.63 13.94" stroke="black" stroke-width="0.5" />
    <path d="M132.63 13.94H141.66" stroke="black" stroke-width="0.5" />
    <path d="M86.93 16.52L85.98 17.46" stroke="black" stroke-width="0.5" />
    <path d="M171.78 50.3H176.57" stroke="black" stroke-width="0.5" />
    <path d="M176.57 7.22V50.3" stroke="black" stroke-width="0.5" />
    <path d="M190.13 7.22V50.3" stroke="black" stroke-width="0.5" />
    <path d="M176.57 7.22H190.13" stroke="black" stroke-width="0.5" />
    <path d="M190.13 50.3H194.92" stroke="black" stroke-width="0.5" />
    <path d="M205.89 119.32H197.11" stroke="black" stroke-width="0.5" />
    <path d="M204.56 120.65H197.11" stroke="black" stroke-width="0.5" />
    <path d="M86.04 159.38V120.65" stroke="black" stroke-width="0.5" />
    <path d="M87.37 160.71V120.65" stroke="black" stroke-width="0.5" />
    <path d="M80.29 162.47V159.38" stroke="black" stroke-width="0.5" />
    <path d="M80.29 159.38H86.04" stroke="black" stroke-width="0.5" />
    <path d="M82.6 14.08L83.54 13.14" stroke="black" stroke-width="0.5" />
    <path d="M112.34 26.1H113.67" stroke="black" stroke-width="0.5" />
    <path d="M131.38 26.1L133.25 24.23" stroke="black" stroke-width="0.5" />
    <path d="M130.83 24.77L132.31 23.29" stroke="black" stroke-width="0.5" />
    <path d="M132.31 23.29L133.25 24.23" stroke="black" stroke-width="0.5" />
    <path d="M139.08 16.52L140.02 17.46" stroke="black" stroke-width="0.5" />
    <path d="M133.25 24.23L140.02 17.46" stroke="black" stroke-width="0.5" />
    <path d="M132.31 23.29L139.08 16.52" stroke="black" stroke-width="0.5" />
    <path d="M132.68 22.92L133.62 23.86" stroke="black" stroke-width="0.5" />
    <path d="M138.7 16.9L139.64 17.84" stroke="black" stroke-width="0.5" />
    <path d="M139.17 17.37L133.15 23.39" stroke="black" stroke-width="0.5" />
    <path d="M139.08 16.52L142.46 13.14" stroke="black" stroke-width="0.5" />
    <path d="M139.08 16.52L140.02 17.46" stroke="black" stroke-width="0.5" />
    <path d="M140.02 17.46L143.4 14.08" stroke="black" stroke-width="0.5" />
    <path d="M139.08 16.52L140.02 17.46" stroke="black" stroke-width="0.5" />
    <path d="M143.4 14.08L142.46 13.14" stroke="black" stroke-width="0.5" />
    <path d="M112.34 40.93H113.67" stroke="black" stroke-width="0.5" />
    <path
      d="M334.35 90.19C334.49 90.1895 334.625 90.1335 334.724 90.0342C334.823 89.9349 334.879 89.8004 334.88 89.66"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M334.88 125.41C334.881 125.34 334.868 125.27 334.842 125.204C334.816 125.139 334.777 125.08 334.728 125.029C334.678 124.979 334.62 124.939 334.555 124.912C334.49 124.884 334.42 124.87 334.35 124.87"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M329.03 76.21V90.19" stroke="black" stroke-width="0.5" />
    <path d="M50.3 131.14L49.36 130.2" stroke="black" stroke-width="0.5" />
    <path d="M50.3 140.25L49.36 141.19" stroke="black" stroke-width="0.5" />
    <path d="M15.02 109.48L21.3 115.76" stroke="black" stroke-width="0.5" />
    <path d="M18.58 107.78L23.93 113.13" stroke="black" stroke-width="0.5" />
    <path
      d="M23.93 113.88C24.029 113.78 24.0847 113.646 24.0847 113.505C24.0847 113.364 24.029 113.23 23.93 113.13"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M21.3 115.76C21.3997 115.859 21.5345 115.915 21.675 115.915C21.8155 115.915 21.9503 115.859 22.05 115.76"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M14.86 109.1C14.86 109.171 14.8742 109.241 14.9017 109.306C14.9292 109.371 14.9694 109.431 15.02 109.48"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M51.81 125.59L52.14 125.92" stroke="black" stroke-width="0.5" />
    <path d="M52.14 125.92L43.12 134.94" stroke="black" stroke-width="0.5" />
    <path d="M43.12 134.94L42.79 134.61" stroke="black" stroke-width="0.5" />
    <path d="M42.79 134.61L51.81 125.59" stroke="black" stroke-width="0.5" />
    <path d="M52.14 125.92L52.52 126.29" stroke="black" stroke-width="0.5" />
    <path d="M52.52 126.29L53.46 125.35" stroke="black" stroke-width="0.5" />
    <path d="M53.46 125.35L53.08 124.98" stroke="black" stroke-width="0.5" />
    <path d="M53.08 124.98L52.14 125.92" stroke="black" stroke-width="0.5" />
    <path d="M42.74 116.51L43.12 116.89" stroke="black" stroke-width="0.5" />
    <path d="M43.12 116.89L44.06 115.95" stroke="black" stroke-width="0.5" />
    <path d="M44.06 115.95L43.68 115.57" stroke="black" stroke-width="0.5" />
    <path d="M43.68 115.57L42.74 116.51" stroke="black" stroke-width="0.5" />
    <path d="M43.12 134.94L41.69 133.24" stroke="black" stroke-width="0.5" />
    <path d="M41.69 133.24L40.57 131.31" stroke="black" stroke-width="0.5" />
    <path d="M40.57 131.31L39.81 129.22" stroke="black" stroke-width="0.5" />
    <path d="M39.81 129.22L39.43 127.03" stroke="black" stroke-width="0.5" />
    <path d="M39.43 127.03V124.8" stroke="black" stroke-width="0.5" />
    <path d="M39.43 124.8L39.81 122.61" stroke="black" stroke-width="0.5" />
    <path d="M39.81 122.61L40.57 120.52" stroke="black" stroke-width="0.5" />
    <path d="M40.57 120.52L41.69 118.59" stroke="black" stroke-width="0.5" />
    <path d="M41.69 118.59L43.12 116.89" stroke="black" stroke-width="0.5" />
    <path d="M118.98 40.93V26.1" stroke="black" stroke-width="0.5" />
    <path d="M107.02 40.93V26.1" stroke="black" stroke-width="0.5" />
    <path d="M58.21 85.48V102.03" stroke="black" stroke-width="0.5" />
    <path d="M58.21 71.45V84.15" stroke="black" stroke-width="0.5" />
    <path d="M49.36 141.19L50.3 142.13" stroke="black" stroke-width="0.5" />
    <path d="M44.01 135.7L44.1 135.79" stroke="black" stroke-width="0.5" />
    <path d="M44.27 135.77L49.46 140.95" stroke="black" stroke-width="0.5" />
    <path d="M49.46 140.95L49.36 141.05" stroke="black" stroke-width="0.5" />
    <path d="M49.36 130.34L44.01 135.7" stroke="black" stroke-width="0.5" />
    <path d="M49.46 130.44L44.1 135.79" stroke="black" stroke-width="0.5" />
    <path d="M49.46 130.44L49.36 130.34" stroke="black" stroke-width="0.5" />
    <path d="M44.17 135.86L49.36 141.05" stroke="black" stroke-width="0.5" />
    <path d="M44.27 135.77L44.17 135.86" stroke="black" stroke-width="0.5" />
    <path d="M19 104.58V104.52" stroke="black" stroke-width="0.5" />
    <path d="M19 104.52L18.99 104.46" stroke="black" stroke-width="0.5" />
    <path d="M18.99 104.46L18.98 104.41" stroke="black" stroke-width="0.5" />
    <path d="M18.98 104.41L18.97 104.35" stroke="black" stroke-width="0.5" />
    <path d="M18.97 104.35L18.95 104.3" stroke="black" stroke-width="0.5" />
    <path d="M18.95 104.3L18.93 104.25" stroke="black" stroke-width="0.5" />
    <path d="M18.93 104.25L18.91 104.2" stroke="black" stroke-width="0.5" />
    <path d="M18.91 104.2L18.88 104.15" stroke="black" stroke-width="0.5" />
    <path d="M18.88 104.15L18.84 104.1" stroke="black" stroke-width="0.5" />
    <path d="M18.84 104.1L18.81 104.06" stroke="black" stroke-width="0.5" />
    <path d="M18.81 104.06L18.77 104.02" stroke="black" stroke-width="0.5" />
    <path d="M18.77 104.02L18.74 103.98" stroke="black" stroke-width="0.5" />
    <path d="M18.45 103.82L18.44 103.81" stroke="black" stroke-width="0.5" />
    <path d="M18.44 103.81L18.38 103.8" stroke="black" stroke-width="0.5" />
    <path d="M18.38 103.8L18.33 103.79" stroke="black" stroke-width="0.5" />
    <path d="M18.33 103.79L18.27 103.78" stroke="black" stroke-width="0.5" />
    <path d="M18.27 103.78H18.22" stroke="black" stroke-width="0.5" />
    <path d="M18.22 103.78H18.16" stroke="black" stroke-width="0.5" />
    <path d="M18.16 103.78H18.1" stroke="black" stroke-width="0.5" />
    <path d="M18.1 103.78L18.05 103.79" stroke="black" stroke-width="0.5" />
    <path d="M18.05 103.79L17.99 103.81" stroke="black" stroke-width="0.5" />
    <path d="M17.99 103.81L17.94 103.82" stroke="black" stroke-width="0.5" />
    <path d="M17.94 103.82L17.89 103.84" stroke="black" stroke-width="0.5" />
    <path d="M17.89 103.84L17.84 103.87" stroke="black" stroke-width="0.5" />
    <path d="M17.84 103.87L17.8 103.89" stroke="black" stroke-width="0.5" />
    <path d="M17.56 104.11L17.54 104.13" stroke="black" stroke-width="0.5" />
    <path d="M17.54 104.13L17.51 104.17" stroke="black" stroke-width="0.5" />
    <path d="M17.51 104.17L17.49 104.22" stroke="black" stroke-width="0.5" />
    <path d="M17.49 104.22L17.46 104.27" stroke="black" stroke-width="0.5" />
    <path d="M17.46 104.27L17.44 104.33" stroke="black" stroke-width="0.5" />
    <path d="M17.44 104.33L17.43 104.38" stroke="black" stroke-width="0.5" />
    <path d="M17.43 104.38L17.42 104.44" stroke="black" stroke-width="0.5" />
    <path d="M17.42 104.44L17.41 104.49" stroke="black" stroke-width="0.5" />
    <path d="M17.41 104.49V104.55" stroke="black" stroke-width="0.5" />
    <path d="M17.41 104.55V104.6" stroke="black" stroke-width="0.5" />
    <path d="M17.41 104.6V104.66" stroke="black" stroke-width="0.5" />
    <path d="M17.41 104.66L17.42 104.72" stroke="black" stroke-width="0.5" />
    <path d="M17.42 104.72V104.74" stroke="black" stroke-width="0.5" />
    <path d="M17.56 105.04L17.58 105.07" stroke="black" stroke-width="0.5" />
    <path d="M17.58 105.07L17.61 105.11" stroke="black" stroke-width="0.5" />
    <path d="M17.61 105.11L17.65 105.16" stroke="black" stroke-width="0.5" />
    <path d="M17.65 105.16L17.7 105.19" stroke="black" stroke-width="0.5" />
    <path d="M17.7 105.19L17.74 105.23" stroke="black" stroke-width="0.5" />
    <path d="M17.74 105.23L17.79 105.26" stroke="black" stroke-width="0.5" />
    <path d="M17.79 105.26L17.84 105.29" stroke="black" stroke-width="0.5" />
    <path d="M17.84 105.29L17.89 105.31" stroke="black" stroke-width="0.5" />
    <path d="M17.89 105.31L17.94 105.33" stroke="black" stroke-width="0.5" />
    <path d="M17.94 105.33L17.99 105.35" stroke="black" stroke-width="0.5" />
    <path d="M17.99 105.35L18.05 105.36" stroke="black" stroke-width="0.5" />
    <path d="M18.05 105.36L18.1 105.37" stroke="black" stroke-width="0.5" />
    <path d="M18.1 105.37H18.12" stroke="black" stroke-width="0.5" />
    <path d="M18.45 105.33L18.49 105.32" stroke="black" stroke-width="0.5" />
    <path d="M18.49 105.32L18.54 105.3" stroke="black" stroke-width="0.5" />
    <path d="M18.54 105.3L18.59 105.27" stroke="black" stroke-width="0.5" />
    <path d="M18.59 105.27L18.64 105.24" stroke="black" stroke-width="0.5" />
    <path d="M18.64 105.24L18.69 105.21" stroke="black" stroke-width="0.5" />
    <path d="M18.69 105.21L18.73 105.17" stroke="black" stroke-width="0.5" />
    <path d="M18.73 105.17L18.77 105.14" stroke="black" stroke-width="0.5" />
    <path d="M18.77 105.14L18.81 105.09" stroke="black" stroke-width="0.5" />
    <path d="M18.81 105.09L18.84 105.05" stroke="black" stroke-width="0.5" />
    <path d="M18.84 105.05L18.88 105" stroke="black" stroke-width="0.5" />
    <path d="M18.88 105L18.91 104.95" stroke="black" stroke-width="0.5" />
    <path d="M18.91 104.95L18.93 104.9" stroke="black" stroke-width="0.5" />
    <path d="M24.64 110.22V110.16" stroke="black" stroke-width="0.5" />
    <path d="M24.64 110.16V110.11" stroke="black" stroke-width="0.5" />
    <path d="M24.64 110.11L24.63 110.05" stroke="black" stroke-width="0.5" />
    <path d="M24.63 110.05L24.61 110" stroke="black" stroke-width="0.5" />
    <path d="M24.61 110L24.59 109.94" stroke="black" stroke-width="0.5" />
    <path d="M24.59 109.94L24.57 109.89" stroke="black" stroke-width="0.5" />
    <path d="M24.57 109.89L24.55 109.84" stroke="black" stroke-width="0.5" />
    <path d="M24.55 109.84L24.52 109.79" stroke="black" stroke-width="0.5" />
    <path d="M24.52 109.79L24.49 109.75" stroke="black" stroke-width="0.5" />
    <path d="M24.49 109.75L24.45 109.7" stroke="black" stroke-width="0.5" />
    <path d="M24.45 109.7L24.41 109.66" stroke="black" stroke-width="0.5" />
    <path d="M24.41 109.66L24.38 109.63" stroke="black" stroke-width="0.5" />
    <path d="M24.09 109.46H24.08" stroke="black" stroke-width="0.5" />
    <path d="M24.08 109.46L24.03 109.44" stroke="black" stroke-width="0.5" />
    <path d="M24.03 109.44L23.97 109.43" stroke="black" stroke-width="0.5" />
    <path d="M23.97 109.43L23.92 109.42" stroke="black" stroke-width="0.5" />
    <path d="M23.92 109.42H23.86" stroke="black" stroke-width="0.5" />
    <path d="M23.86 109.42H23.8" stroke="black" stroke-width="0.5" />
    <path d="M23.8 109.42L23.75 109.43" stroke="black" stroke-width="0.5" />
    <path d="M23.75 109.43L23.69 109.44" stroke="black" stroke-width="0.5" />
    <path d="M23.69 109.44L23.64 109.45" stroke="black" stroke-width="0.5" />
    <path d="M23.64 109.45L23.58 109.47" stroke="black" stroke-width="0.5" />
    <path d="M23.58 109.47L23.53 109.49" stroke="black" stroke-width="0.5" />
    <path d="M23.53 109.49L23.48 109.51" stroke="black" stroke-width="0.5" />
    <path d="M23.48 109.51L23.45 109.53" stroke="black" stroke-width="0.5" />
    <path d="M23.2 109.75L23.19 109.77" stroke="black" stroke-width="0.5" />
    <path d="M23.19 109.77L23.16 109.82" stroke="black" stroke-width="0.5" />
    <path d="M23.16 109.82L23.13 109.87" stroke="black" stroke-width="0.5" />
    <path d="M23.13 109.87L23.11 109.92" stroke="black" stroke-width="0.5" />
    <path d="M23.11 109.92L23.09 109.97" stroke="black" stroke-width="0.5" />
    <path d="M23.09 109.97L23.07 110.02" stroke="black" stroke-width="0.5" />
    <path d="M23.07 110.02L23.06 110.08" stroke="black" stroke-width="0.5" />
    <path d="M23.06 110.08L23.05 110.13" stroke="black" stroke-width="0.5" />
    <path d="M23.05 110.13V110.19" stroke="black" stroke-width="0.5" />
    <path d="M23.05 110.19V110.25" stroke="black" stroke-width="0.5" />
    <path d="M23.05 110.25V110.3" stroke="black" stroke-width="0.5" />
    <path d="M23.05 110.3L23.06 110.36" stroke="black" stroke-width="0.5" />
    <path d="M23.06 110.36L23.07 110.38" stroke="black" stroke-width="0.5" />
    <path d="M23.2 110.69L23.22 110.71" stroke="black" stroke-width="0.5" />
    <path d="M23.22 110.71L23.26 110.76" stroke="black" stroke-width="0.5" />
    <path d="M23.26 110.76L23.3 110.8" stroke="black" stroke-width="0.5" />
    <path d="M23.3 110.8L23.34 110.83" stroke="black" stroke-width="0.5" />
    <path d="M23.34 110.83L23.38 110.87" stroke="black" stroke-width="0.5" />
    <path d="M23.38 110.87L23.43 110.9" stroke="black" stroke-width="0.5" />
    <path d="M23.43 110.9L23.48 110.93" stroke="black" stroke-width="0.5" />
    <path d="M23.48 110.93L23.53 110.95" stroke="black" stroke-width="0.5" />
    <path d="M23.53 110.95L23.58 110.97" stroke="black" stroke-width="0.5" />
    <path d="M23.58 110.97L23.64 110.99" stroke="black" stroke-width="0.5" />
    <path d="M23.64 110.99L23.69 111" stroke="black" stroke-width="0.5" />
    <path d="M23.69 111L23.75 111.01" stroke="black" stroke-width="0.5" />
    <path d="M23.75 111.01H23.76" stroke="black" stroke-width="0.5" />
    <path d="M24.09 110.98L24.13 110.96" stroke="black" stroke-width="0.5" />
    <path d="M24.13 110.96L24.19 110.94" stroke="black" stroke-width="0.5" />
    <path d="M24.19 110.94L24.24 110.91" stroke="black" stroke-width="0.5" />
    <path d="M24.24 110.91L24.28 110.88" stroke="black" stroke-width="0.5" />
    <path d="M24.28 110.88L24.33 110.85" stroke="black" stroke-width="0.5" />
    <path d="M24.33 110.85L24.37 110.82" stroke="black" stroke-width="0.5" />
    <path d="M24.37 110.82L24.41 110.78" stroke="black" stroke-width="0.5" />
    <path d="M24.41 110.78L24.45 110.74" stroke="black" stroke-width="0.5" />
    <path d="M24.45 110.74L24.49 110.69" stroke="black" stroke-width="0.5" />
    <path d="M24.49 110.69L24.52 110.65" stroke="black" stroke-width="0.5" />
    <path d="M24.52 110.65L24.55 110.6" stroke="black" stroke-width="0.5" />
    <path d="M24.55 110.6L24.57 110.55" stroke="black" stroke-width="0.5" />
    <path d="M24.57 110.55V110.54" stroke="black" stroke-width="0.5" />
    <path d="M30.35 115.93V115.87" stroke="black" stroke-width="0.5" />
    <path d="M30.35 115.87L30.34 115.81" stroke="black" stroke-width="0.5" />
    <path d="M30.34 115.81L30.33 115.76" stroke="black" stroke-width="0.5" />
    <path d="M30.33 115.76L30.32 115.7" stroke="black" stroke-width="0.5" />
    <path d="M30.32 115.7L30.3 115.65" stroke="black" stroke-width="0.5" />
    <path d="M30.3 115.65L30.28 115.6" stroke="black" stroke-width="0.5" />
    <path d="M30.28 115.6L30.25 115.55" stroke="black" stroke-width="0.5" />
    <path d="M30.25 115.55L30.23 115.5" stroke="black" stroke-width="0.5" />
    <path d="M30.23 115.5L30.19 115.45" stroke="black" stroke-width="0.5" />
    <path d="M30.19 115.45L30.16 115.41" stroke="black" stroke-width="0.5" />
    <path d="M30.16 115.41L30.12 115.37" stroke="black" stroke-width="0.5" />
    <path d="M30.12 115.37L30.09 115.33" stroke="black" stroke-width="0.5" />
    <path d="M29.8 115.17L29.79 115.16" stroke="black" stroke-width="0.5" />
    <path d="M29.79 115.16L29.73 115.15" stroke="black" stroke-width="0.5" />
    <path d="M29.73 115.15L29.68 115.14" stroke="black" stroke-width="0.5" />
    <path d="M29.68 115.14L29.62 115.13" stroke="black" stroke-width="0.5" />
    <path d="M29.62 115.13H29.57" stroke="black" stroke-width="0.5" />
    <path d="M29.57 115.13H29.51" stroke="black" stroke-width="0.5" />
    <path d="M29.51 115.13H29.45" stroke="black" stroke-width="0.5" />
    <path d="M29.45 115.13L29.4 115.14" stroke="black" stroke-width="0.5" />
    <path d="M29.4 115.14L29.34 115.16" stroke="black" stroke-width="0.5" />
    <path d="M29.34 115.16L29.29 115.17" stroke="black" stroke-width="0.5" />
    <path d="M29.29 115.17L29.24 115.19" stroke="black" stroke-width="0.5" />
    <path d="M29.24 115.19L29.19 115.22" stroke="black" stroke-width="0.5" />
    <path d="M29.19 115.22L29.15 115.24" stroke="black" stroke-width="0.5" />
    <path d="M28.91 115.46L28.89 115.48" stroke="black" stroke-width="0.5" />
    <path d="M28.89 115.48L28.86 115.52" stroke="black" stroke-width="0.5" />
    <path d="M28.86 115.52L28.84 115.57" stroke="black" stroke-width="0.5" />
    <path d="M28.84 115.57L28.81 115.62" stroke="black" stroke-width="0.5" />
    <path d="M28.81 115.62L28.79 115.68" stroke="black" stroke-width="0.5" />
    <path d="M28.79 115.68L28.78 115.73" stroke="black" stroke-width="0.5" />
    <path d="M28.78 115.73L28.77 115.79" stroke="black" stroke-width="0.5" />
    <path d="M28.77 115.79L28.76 115.84" stroke="black" stroke-width="0.5" />
    <path d="M28.76 115.84V115.9" stroke="black" stroke-width="0.5" />
    <path d="M28.76 115.9V115.95" stroke="black" stroke-width="0.5" />
    <path d="M28.76 115.95V116.01" stroke="black" stroke-width="0.5" />
    <path d="M28.76 116.01L28.77 116.07" stroke="black" stroke-width="0.5" />
    <path d="M28.77 116.07V116.09" stroke="black" stroke-width="0.5" />
    <path d="M28.91 116.39L28.93 116.42" stroke="black" stroke-width="0.5" />
    <path d="M28.93 116.42L28.96 116.46" stroke="black" stroke-width="0.5" />
    <path d="M28.96 116.46L29 116.5" stroke="black" stroke-width="0.5" />
    <path d="M29 116.5L29.05 116.54" stroke="black" stroke-width="0.5" />
    <path d="M29.05 116.54L29.09 116.58" stroke="black" stroke-width="0.5" />
    <path d="M29.09 116.58L29.14 116.61" stroke="black" stroke-width="0.5" />
    <path d="M29.14 116.61L29.19 116.63" stroke="black" stroke-width="0.5" />
    <path d="M29.19 116.63L29.24 116.66" stroke="black" stroke-width="0.5" />
    <path d="M29.24 116.66L29.29 116.68" stroke="black" stroke-width="0.5" />
    <path d="M29.29 116.68L29.34 116.7" stroke="black" stroke-width="0.5" />
    <path d="M29.34 116.7L29.4 116.71" stroke="black" stroke-width="0.5" />
    <path d="M29.4 116.71L29.45 116.72" stroke="black" stroke-width="0.5" />
    <path d="M29.45 116.72H29.47" stroke="black" stroke-width="0.5" />
    <path d="M29.8 116.68L29.84 116.67" stroke="black" stroke-width="0.5" />
    <path d="M29.84 116.67L29.89 116.65" stroke="black" stroke-width="0.5" />
    <path d="M29.89 116.65L29.94 116.62" stroke="black" stroke-width="0.5" />
    <path d="M29.94 116.62L29.99 116.59" stroke="black" stroke-width="0.5" />
    <path d="M29.99 116.59L30.04 116.56" stroke="black" stroke-width="0.5" />
    <path d="M30.04 116.56L30.08 116.52" stroke="black" stroke-width="0.5" />
    <path d="M30.08 116.52L30.12 116.49" stroke="black" stroke-width="0.5" />
    <path d="M30.12 116.49L30.16 116.44" stroke="black" stroke-width="0.5" />
    <path d="M30.16 116.44L30.19 116.4" stroke="black" stroke-width="0.5" />
    <path d="M30.19 116.4L30.23 116.35" stroke="black" stroke-width="0.5" />
    <path d="M30.23 116.35L30.25 116.3" stroke="black" stroke-width="0.5" />
    <path d="M30.25 116.3L30.28 116.25" stroke="black" stroke-width="0.5" />
    <path d="M48.28 134.7V133.91" stroke="black" stroke-width="0.5" />
    <path d="M48.28 133.91H48.77" stroke="black" stroke-width="0.5" />
    <path d="M48.28 134.29H48.58" stroke="black" stroke-width="0.5" />
    <path d="M309.88 12.01V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M299.77 8.81V2.42999" stroke="black" stroke-width="0.5" />
    <path d="M139.03 120.65H146.48" stroke="black" stroke-width="0.5" />
    <path d="M147.81 120.65H155.26" stroke="black" stroke-width="0.5" />
    <path d="M176.87 120.65H186.47" stroke="black" stroke-width="0.5" />
    <path d="M139.03 119.32H155.26" stroke="black" stroke-width="0.5" />
    <path d="M165.9 119.32H186.47" stroke="black" stroke-width="0.5" />
    <path d="M205.89 124.61H213.34" stroke="black" stroke-width="0.5" />
    <path d="M223.97 124.61H271.25" stroke="black" stroke-width="0.5" />
    <path d="M281.89 124.61H298.11" stroke="black" stroke-width="0.5" />
    <path d="M308.75 124.61H327.17" stroke="black" stroke-width="0.5" />
    <path d="M247.31 108.65H261.67" stroke="black" stroke-width="0.5" />
    <path d="M282.22 108.65H285.15" stroke="black" stroke-width="0.5" />
    <path d="M247.31 107.32H260.08" stroke="black" stroke-width="0.5" />
    <path d="M283.55 107.32H285.15" stroke="black" stroke-width="0.5" />
    <path d="M205.89 125.94H213.34" stroke="black" stroke-width="0.5" />
    <path d="M223.97 125.94H251.3" stroke="black" stroke-width="0.5" />
    <path d="M252.63 125.94H271.25" stroke="black" stroke-width="0.5" />
    <path d="M281.89 125.94H289.33" stroke="black" stroke-width="0.5" />
    <path d="M290.66 125.94H298.11" stroke="black" stroke-width="0.5" />
    <path d="M308.75 125.94H327.17" stroke="black" stroke-width="0.5" />
    <path d="M247.31 107.32V108.65" stroke="black" stroke-width="0.5" />
    <path d="M260.08 95.12V107.32" stroke="black" stroke-width="0.5" />
    <path d="M261.67 95.12V108.65" stroke="black" stroke-width="0.5" />
    <path d="M283.55 95.12V107.32" stroke="black" stroke-width="0.5" />
    <path d="M282.22 95.12V108.65" stroke="black" stroke-width="0.5" />
    <path d="M226.3 12.01V21.51" stroke="black" stroke-width="0.5" />
    <path d="M226.3 31.09V32.68" stroke="black" stroke-width="0.5" />
    <path d="M226.3 34.01V55.09" stroke="black" stroke-width="0.5" />
    <path d="M227.63 55.09V31.09" stroke="black" stroke-width="0.5" />
    <path d="M227.63 21.51V12.01" stroke="black" stroke-width="0.5" />
    <path d="M176.87 120.65V163.9" stroke="black" stroke-width="0.5" />
    <path d="M175.54 120.65V163.9" stroke="black" stroke-width="0.5" />
    <path d="M147.81 120.65V160.55" stroke="black" stroke-width="0.5" />
    <path d="M146.48 120.65V160.55" stroke="black" stroke-width="0.5" />
    <path d="M117.56 120.65V160.55" stroke="black" stroke-width="0.5" />
    <path d="M116.23 120.65V160.55" stroke="black" stroke-width="0.5" />
    <path d="M144.45 102.03V67.59" stroke="black" stroke-width="0.5" />
    <path d="M145.78 103.36V67.59" stroke="black" stroke-width="0.5" />
    <path d="M226.3 64.67V67.59" stroke="black" stroke-width="0.5" />
    <path d="M247.31 42.46V40.86" stroke="black" stroke-width="0.5" />
    <path d="M247.31 31.29V15.18" stroke="black" stroke-width="0.5" />
    <path d="M248.64 42.46V40.86" stroke="black" stroke-width="0.5" />
    <path d="M248.64 31.29V7.5" stroke="black" stroke-width="0.5" />
    <path d="M247.31 15.18L244.14 12.01" stroke="black" stroke-width="0.5" />
    <path d="M62.39 119.32H67.95" stroke="black" stroke-width="0.5" />
    <path d="M78.59 119.32H86.04" stroke="black" stroke-width="0.5" />
    <path d="M62.39 120.65H67.95" stroke="black" stroke-width="0.5" />
    <path d="M78.59 120.65H86.04" stroke="black" stroke-width="0.5" />
    <path d="M87.37 119.32H94.82" stroke="black" stroke-width="0.5" />
    <path d="M105.46 119.32H121.78" stroke="black" stroke-width="0.5" />
    <path d="M87.37 120.65H94.82" stroke="black" stroke-width="0.5" />
    <path d="M105.46 120.65H116.23" stroke="black" stroke-width="0.5" />
    <path d="M117.56 120.65H123.11" stroke="black" stroke-width="0.5" />
    <path d="M78.59 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M67.95 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M251.3 163.74V125.94" stroke="black" stroke-width="0.5" />
    <path d="M252.63 163.74V125.94" stroke="black" stroke-width="0.5" />
    <path d="M289.33 125.94V163.9" stroke="black" stroke-width="0.5" />
    <path d="M290.66 125.94V163.9" stroke="black" stroke-width="0.5" />
    <path d="M248.64 40.86H247.31" stroke="black" stroke-width="0.5" />
    <path d="M248.64 31.29H247.31" stroke="black" stroke-width="0.5" />
    <path d="M258.22 40.86L258.03 38.99" stroke="black" stroke-width="0.5" />
    <path d="M258.03 38.99L257.49 37.2" stroke="black" stroke-width="0.5" />
    <path d="M257.49 37.2L256.6 35.54" stroke="black" stroke-width="0.5" />
    <path d="M256.6 35.54L255.41 34.09" stroke="black" stroke-width="0.5" />
    <path d="M255.41 34.09L253.96 32.9" stroke="black" stroke-width="0.5" />
    <path d="M253.96 32.9L252.3 32.02" stroke="black" stroke-width="0.5" />
    <path d="M252.3 32.02L250.51 31.47" stroke="black" stroke-width="0.5" />
    <path d="M250.51 31.47L248.64 31.29" stroke="black" stroke-width="0.5" />
    <path d="M248.64 40.4V40.86" stroke="black" stroke-width="0.5" />
    <path d="M248.64 40.86H258.22" stroke="black" stroke-width="0.5" />
    <path d="M258.22 40.86V40.4" stroke="black" stroke-width="0.5" />
    <path d="M258.22 40.4H248.64" stroke="black" stroke-width="0.5" />
    <path d="M248.64 40.86V41.39" stroke="black" stroke-width="0.5" />
    <path d="M248.64 41.39H247.31" stroke="black" stroke-width="0.5" />
    <path d="M247.31 41.39V40.86" stroke="black" stroke-width="0.5" />
    <path d="M247.31 40.86H248.64" stroke="black" stroke-width="0.5" />
    <path d="M248.64 30.76V31.29" stroke="black" stroke-width="0.5" />
    <path d="M248.64 31.29H247.31" stroke="black" stroke-width="0.5" />
    <path d="M247.31 31.29V30.76" stroke="black" stroke-width="0.5" />
    <path d="M247.31 30.76H248.64" stroke="black" stroke-width="0.5" />
    <path d="M79.66 159.38H86.04" stroke="black" stroke-width="0.5" />
    <path d="M82.71 154.59L82.66 154.71" stroke="black" stroke-width="0.5" />
    <path d="M82.66 154.71L82.55 154.75" stroke="black" stroke-width="0.5" />
    <path d="M82.55 154.75L82.43 154.71" stroke="black" stroke-width="0.5" />
    <path d="M82.43 154.71L82.39 154.59" stroke="black" stroke-width="0.5" />
    <path d="M82.39 154.59L82.43 154.48" stroke="black" stroke-width="0.5" />
    <path d="M82.43 154.48L82.55 154.43" stroke="black" stroke-width="0.5" />
    <path d="M82.55 154.43L82.66 154.48" stroke="black" stroke-width="0.5" />
    <path d="M82.66 154.48L82.71 154.59" stroke="black" stroke-width="0.5" />
    <path d="M82.91 155.17L82.89 155.24" stroke="black" stroke-width="0.5" />
    <path d="M82.89 155.24L82.88 155.31" stroke="black" stroke-width="0.5" />
    <path d="M82.88 155.31L82.86 155.37" stroke="black" stroke-width="0.5" />
    <path d="M82.86 155.37L82.84 155.43" stroke="black" stroke-width="0.5" />
    <path d="M82.84 155.43L82.82 155.49" stroke="black" stroke-width="0.5" />
    <path d="M82.82 155.49L82.8 155.55" stroke="black" stroke-width="0.5" />
    <path d="M82.8 155.55L82.78 155.6" stroke="black" stroke-width="0.5" />
    <path d="M82.78 155.6L82.76 155.65" stroke="black" stroke-width="0.5" />
    <path d="M82.76 155.65L82.73 155.71" stroke="black" stroke-width="0.5" />
    <path d="M82.73 155.71L82.71 155.75" stroke="black" stroke-width="0.5" />
    <path d="M82.71 155.75L82.69 155.8" stroke="black" stroke-width="0.5" />
    <path d="M82.69 155.8L82.66 155.84" stroke="black" stroke-width="0.5" />
    <path d="M82.66 155.84L82.63 155.88" stroke="black" stroke-width="0.5" />
    <path d="M82.63 155.88L82.61 155.92" stroke="black" stroke-width="0.5" />
    <path d="M82.61 155.92L82.58 155.96" stroke="black" stroke-width="0.5" />
    <path d="M82.58 155.96L82.55 156" stroke="black" stroke-width="0.5" />
    <path d="M82.55 156L82.52 156.03" stroke="black" stroke-width="0.5" />
    <path d="M82.52 156.03L82.49 156.06" stroke="black" stroke-width="0.5" />
    <path d="M82.49 156.06L82.45 156.09" stroke="black" stroke-width="0.5" />
    <path d="M82.45 156.09L82.42 156.11" stroke="black" stroke-width="0.5" />
    <path d="M82.42 156.11L82.39 156.14" stroke="black" stroke-width="0.5" />
    <path d="M82.39 156.14L82.35 156.16" stroke="black" stroke-width="0.5" />
    <path d="M82.35 156.16L82.32 156.18" stroke="black" stroke-width="0.5" />
    <path d="M82.32 156.18L82.28 156.2" stroke="black" stroke-width="0.5" />
    <path d="M82.28 156.2L82.24 156.21" stroke="black" stroke-width="0.5" />
    <path d="M82.24 156.21L82.2 156.23" stroke="black" stroke-width="0.5" />
    <path d="M82.2 156.23L82.16 156.24" stroke="black" stroke-width="0.5" />
    <path d="M82.16 156.24L82.12 156.25" stroke="black" stroke-width="0.5" />
    <path d="M82.12 156.25H82.08" stroke="black" stroke-width="0.5" />
    <path d="M82.08 156.25L82.04 156.26" stroke="black" stroke-width="0.5" />
    <path d="M82.04 156.26H82" stroke="black" stroke-width="0.5" />
    <path d="M82 156.26H81.95" stroke="black" stroke-width="0.5" />
    <path d="M81.95 156.26H81.92" stroke="black" stroke-width="0.5" />
    <path d="M81.92 156.26H81.88" stroke="black" stroke-width="0.5" />
    <path d="M81.88 156.26H81.84" stroke="black" stroke-width="0.5" />
    <path d="M81.84 156.26L81.81 156.25" stroke="black" stroke-width="0.5" />
    <path d="M81.81 156.25H81.77" stroke="black" stroke-width="0.5" />
    <path d="M81.77 156.25L81.74 156.24" stroke="black" stroke-width="0.5" />
    <path d="M81.74 156.24L81.7 156.23" stroke="black" stroke-width="0.5" />
    <path d="M81.7 156.23L81.67 156.22" stroke="black" stroke-width="0.5" />
    <path d="M81.67 156.22L81.63 156.21" stroke="black" stroke-width="0.5" />
    <path d="M81.63 156.21L81.6 156.2" stroke="black" stroke-width="0.5" />
    <path d="M81.6 156.2L81.57 156.19" stroke="black" stroke-width="0.5" />
    <path d="M81.57 156.19L81.54 156.18" stroke="black" stroke-width="0.5" />
    <path d="M81.54 156.18L81.5 156.16" stroke="black" stroke-width="0.5" />
    <path d="M81.5 156.16L81.47 156.15" stroke="black" stroke-width="0.5" />
    <path d="M81.47 156.15L81.44 156.13" stroke="black" stroke-width="0.5" />
    <path d="M81.44 156.13L81.41 156.11" stroke="black" stroke-width="0.5" />
    <path d="M81.41 156.11L81.38 156.09" stroke="black" stroke-width="0.5" />
    <path d="M81.38 156.09L81.35 156.08" stroke="black" stroke-width="0.5" />
    <path d="M81.35 156.08L81.33 156.05" stroke="black" stroke-width="0.5" />
    <path d="M81.33 156.05L81.3 156.03" stroke="black" stroke-width="0.5" />
    <path d="M81.3 156.03L81.27 156.01" stroke="black" stroke-width="0.5" />
    <path d="M81.27 156.01L81.24 155.99" stroke="black" stroke-width="0.5" />
    <path d="M81.24 155.99L81.22 155.97" stroke="black" stroke-width="0.5" />
    <path d="M81.22 155.97L81.19 155.94" stroke="black" stroke-width="0.5" />
    <path d="M81.19 155.94L81.17 155.92" stroke="black" stroke-width="0.5" />
    <path d="M81.17 155.92L81.14 155.89" stroke="black" stroke-width="0.5" />
    <path d="M81.14 155.89L81.12 155.86" stroke="black" stroke-width="0.5" />
    <path d="M81.12 155.86L81.1 155.84" stroke="black" stroke-width="0.5" />
    <path d="M81.1 155.84L81.07 155.81" stroke="black" stroke-width="0.5" />
    <path d="M81.07 155.81L81.05 155.78" stroke="black" stroke-width="0.5" />
    <path d="M81.05 155.78L81.03 155.75" stroke="black" stroke-width="0.5" />
    <path d="M81.03 155.75L81.01 155.72" stroke="black" stroke-width="0.5" />
    <path d="M81.01 155.72L80.99 155.69" stroke="black" stroke-width="0.5" />
    <path d="M80.99 155.69L80.97 155.66" stroke="black" stroke-width="0.5" />
    <path d="M80.97 155.66L80.95 155.63" stroke="black" stroke-width="0.5" />
    <path d="M80.95 155.63L80.93 155.59" stroke="black" stroke-width="0.5" />
    <path d="M80.93 155.59L80.91 155.56" stroke="black" stroke-width="0.5" />
    <path d="M80.91 155.56L80.89 155.53" stroke="black" stroke-width="0.5" />
    <path d="M80.89 155.53L80.88 155.49" stroke="black" stroke-width="0.5" />
    <path d="M80.88 155.49L80.86 155.46" stroke="black" stroke-width="0.5" />
    <path d="M80.86 155.46L80.84 155.43" stroke="black" stroke-width="0.5" />
    <path d="M80.84 155.43L80.83 155.39" stroke="black" stroke-width="0.5" />
    <path d="M80.83 155.39L80.81 155.36" stroke="black" stroke-width="0.5" />
    <path d="M80.81 155.36L80.8 155.32" stroke="black" stroke-width="0.5" />
    <path d="M80.8 155.32L80.79 155.28" stroke="black" stroke-width="0.5" />
    <path d="M80.79 155.28L80.78 155.25" stroke="black" stroke-width="0.5" />
    <path d="M80.78 155.25L80.75 155.18" stroke="black" stroke-width="0.5" />
    <path d="M80.75 155.18L80.73 155.1" stroke="black" stroke-width="0.5" />
    <path d="M80.73 155.1L80.71 155.03" stroke="black" stroke-width="0.5" />
    <path d="M80.71 155.03L80.7 154.95" stroke="black" stroke-width="0.5" />
    <path d="M80.7 154.95L80.69 154.87" stroke="black" stroke-width="0.5" />
    <path d="M80.69 154.87L80.68 154.8" stroke="black" stroke-width="0.5" />
    <path d="M80.68 154.8L80.67 154.72" stroke="black" stroke-width="0.5" />
    <path d="M80.67 154.72V154.68" stroke="black" stroke-width="0.5" />
    <path d="M80.67 154.68L80.66 154.65" stroke="black" stroke-width="0.5" />
    <path d="M80.66 154.65V154.61" stroke="black" stroke-width="0.5" />
    <path d="M80.66 154.61V154.57" stroke="black" stroke-width="0.5" />
    <path d="M80.66 154.57V154.53" stroke="black" stroke-width="0.5" />
    <path d="M80.66 154.53V154.49" stroke="black" stroke-width="0.5" />
    <path d="M80.66 154.49L80.67 154.45" stroke="black" stroke-width="0.5" />
    <path d="M80.67 154.45V154.42" stroke="black" stroke-width="0.5" />
    <path d="M80.67 154.42L80.68 154.34" stroke="black" stroke-width="0.5" />
    <path d="M80.68 154.34L80.69 154.26" stroke="black" stroke-width="0.5" />
    <path d="M80.69 154.26L80.7 154.19" stroke="black" stroke-width="0.5" />
    <path d="M80.7 154.19L80.71 154.11" stroke="black" stroke-width="0.5" />
    <path d="M80.71 154.11L80.73 154.04" stroke="black" stroke-width="0.5" />
    <path d="M80.73 154.04L80.75 153.96" stroke="black" stroke-width="0.5" />
    <path d="M80.75 153.96L80.78 153.89" stroke="black" stroke-width="0.5" />
    <path d="M80.78 153.89L80.79 153.85" stroke="black" stroke-width="0.5" />
    <path d="M80.79 153.85L80.8 153.82" stroke="black" stroke-width="0.5" />
    <path d="M80.8 153.82L80.81 153.78" stroke="black" stroke-width="0.5" />
    <path d="M80.81 153.78L80.83 153.75" stroke="black" stroke-width="0.5" />
    <path d="M80.83 153.75L80.84 153.71" stroke="black" stroke-width="0.5" />
    <path d="M80.84 153.71L80.86 153.68" stroke="black" stroke-width="0.5" />
    <path d="M80.86 153.68L80.88 153.64" stroke="black" stroke-width="0.5" />
    <path d="M80.88 153.64L80.89 153.61" stroke="black" stroke-width="0.5" />
    <path d="M80.89 153.61L80.91 153.58" stroke="black" stroke-width="0.5" />
    <path d="M80.91 153.58L80.93 153.54" stroke="black" stroke-width="0.5" />
    <path d="M80.93 153.54L80.95 153.51" stroke="black" stroke-width="0.5" />
    <path d="M80.95 153.51L80.97 153.48" stroke="black" stroke-width="0.5" />
    <path d="M80.97 153.48L80.99 153.45" stroke="black" stroke-width="0.5" />
    <path d="M80.99 153.45L81.01 153.42" stroke="black" stroke-width="0.5" />
    <path d="M81.01 153.42L81.03 153.39" stroke="black" stroke-width="0.5" />
    <path d="M81.03 153.39L81.05 153.36" stroke="black" stroke-width="0.5" />
    <path d="M81.05 153.36L81.07 153.33" stroke="black" stroke-width="0.5" />
    <path d="M81.07 153.33L81.1 153.3" stroke="black" stroke-width="0.5" />
    <path d="M81.1 153.3L81.12 153.27" stroke="black" stroke-width="0.5" />
    <path d="M81.12 153.27L81.14 153.25" stroke="black" stroke-width="0.5" />
    <path d="M81.14 153.25L81.17 153.22" stroke="black" stroke-width="0.5" />
    <path d="M81.17 153.22L81.19 153.2" stroke="black" stroke-width="0.5" />
    <path d="M81.19 153.2L81.22 153.17" stroke="black" stroke-width="0.5" />
    <path d="M81.22 153.17L81.24 153.15" stroke="black" stroke-width="0.5" />
    <path d="M81.24 153.15L81.27 153.13" stroke="black" stroke-width="0.5" />
    <path d="M81.27 153.13L81.3 153.1" stroke="black" stroke-width="0.5" />
    <path d="M81.3 153.1L81.33 153.08" stroke="black" stroke-width="0.5" />
    <path d="M81.33 153.08L81.35 153.06" stroke="black" stroke-width="0.5" />
    <path d="M81.35 153.06L81.38 153.04" stroke="black" stroke-width="0.5" />
    <path d="M81.38 153.04L81.41 153.03" stroke="black" stroke-width="0.5" />
    <path d="M81.41 153.03L81.44 153.01" stroke="black" stroke-width="0.5" />
    <path d="M81.44 153.01L81.47 152.99" stroke="black" stroke-width="0.5" />
    <path d="M81.47 152.99L81.5 152.98" stroke="black" stroke-width="0.5" />
    <path d="M81.5 152.98L81.54 152.96" stroke="black" stroke-width="0.5" />
    <path d="M81.54 152.96L81.57 152.95" stroke="black" stroke-width="0.5" />
    <path d="M81.57 152.95L81.6 152.94" stroke="black" stroke-width="0.5" />
    <path d="M81.6 152.94L81.63 152.92" stroke="black" stroke-width="0.5" />
    <path d="M81.63 152.92L81.67 152.91" stroke="black" stroke-width="0.5" />
    <path d="M81.67 152.91L81.7 152.9" stroke="black" stroke-width="0.5" />
    <path d="M81.7 152.9H81.74" stroke="black" stroke-width="0.5" />
    <path d="M81.74 152.9L81.77 152.89" stroke="black" stroke-width="0.5" />
    <path d="M81.77 152.89L81.81 152.88" stroke="black" stroke-width="0.5" />
    <path d="M81.81 152.88H81.84" stroke="black" stroke-width="0.5" />
    <path d="M81.84 152.88H81.88" stroke="black" stroke-width="0.5" />
    <path d="M81.88 152.88L81.92 152.87" stroke="black" stroke-width="0.5" />
    <path d="M81.92 152.87H81.95" stroke="black" stroke-width="0.5" />
    <path d="M81.95 152.87L82 152.88" stroke="black" stroke-width="0.5" />
    <path d="M82 152.88H82.04" stroke="black" stroke-width="0.5" />
    <path d="M82.04 152.88H82.08" stroke="black" stroke-width="0.5" />
    <path d="M82.08 152.88L82.12 152.89" stroke="black" stroke-width="0.5" />
    <path d="M82.12 152.89L82.16 152.9" stroke="black" stroke-width="0.5" />
    <path d="M82.16 152.9L82.2 152.91" stroke="black" stroke-width="0.5" />
    <path d="M82.2 152.91L82.24 152.92" stroke="black" stroke-width="0.5" />
    <path d="M82.24 152.92L82.27 152.94" stroke="black" stroke-width="0.5" />
    <path d="M82.27 152.94L82.31 152.96" stroke="black" stroke-width="0.5" />
    <path d="M82.31 152.96L82.35 152.97" stroke="black" stroke-width="0.5" />
    <path d="M82.35 152.97L82.38 153" stroke="black" stroke-width="0.5" />
    <path d="M82.38 153L82.41 153.02" stroke="black" stroke-width="0.5" />
    <path d="M82.41 153.02L82.45 153.04" stroke="black" stroke-width="0.5" />
    <path d="M82.45 153.04L82.48 153.07" stroke="black" stroke-width="0.5" />
    <path d="M82.48 153.07L82.51 153.1" stroke="black" stroke-width="0.5" />
    <path d="M82.51 153.1L82.54 153.13" stroke="black" stroke-width="0.5" />
    <path d="M82.54 153.13L82.57 153.17" stroke="black" stroke-width="0.5" />
    <path d="M82.57 153.17L82.6 153.2" stroke="black" stroke-width="0.5" />
    <path d="M82.6 153.2L82.62 153.24" stroke="black" stroke-width="0.5" />
    <path d="M82.62 153.24L82.65 153.28" stroke="black" stroke-width="0.5" />
    <path d="M82.65 153.28L82.68 153.32" stroke="black" stroke-width="0.5" />
    <path d="M82.68 153.32L82.7 153.37" stroke="black" stroke-width="0.5" />
    <path d="M82.7 153.37L82.73 153.41" stroke="black" stroke-width="0.5" />
    <path d="M82.73 153.41L82.75 153.46" stroke="black" stroke-width="0.5" />
    <path d="M82.75 153.46L82.77 153.51" stroke="black" stroke-width="0.5" />
    <path d="M82.77 153.51L82.79 153.57" stroke="black" stroke-width="0.5" />
    <path d="M82.79 153.57L82.81 153.62" stroke="black" stroke-width="0.5" />
    <path d="M82.81 153.62L82.83 153.68" stroke="black" stroke-width="0.5" />
    <path d="M82.83 153.68L82.85 153.74" stroke="black" stroke-width="0.5" />
    <path d="M82.85 153.74L82.87 153.8" stroke="black" stroke-width="0.5" />
    <path d="M82.87 153.8L82.88 153.86" stroke="black" stroke-width="0.5" />
    <path d="M82.88 153.86L82.9 153.93" stroke="black" stroke-width="0.5" />
    <path d="M81.98 152.51L81.25 152.69" stroke="black" stroke-width="0.5" />
    <path d="M81.25 152.69L80.65 153.19" stroke="black" stroke-width="0.5" />
    <path d="M80.65 153.19L80.28 153.93" stroke="black" stroke-width="0.5" />
    <path d="M80.28 153.93L80.2 154.78" stroke="black" stroke-width="0.5" />
    <path d="M80.2 154.78L80.43 155.6" stroke="black" stroke-width="0.5" />
    <path d="M80.43 155.6L80.93 156.23" stroke="black" stroke-width="0.5" />
    <path d="M80.93 156.23L81.61 156.58" stroke="black" stroke-width="0.5" />
    <path d="M81.61 156.58H82.36" stroke="black" stroke-width="0.5" />
    <path d="M82.36 156.58L83.04 156.23" stroke="black" stroke-width="0.5" />
    <path d="M83.04 156.23L83.54 155.6" stroke="black" stroke-width="0.5" />
    <path d="M83.54 155.6L83.77 154.78" stroke="black" stroke-width="0.5" />
    <path d="M83.77 154.78L83.69 153.93" stroke="black" stroke-width="0.5" />
    <path d="M83.69 153.93L83.32 153.19" stroke="black" stroke-width="0.5" />
    <path d="M83.32 153.19L82.71 152.69" stroke="black" stroke-width="0.5" />
    <path d="M82.71 152.69L81.98 152.51" stroke="black" stroke-width="0.5" />
    <path d="M82.91 155.17L82.9 153.93" stroke="black" stroke-width="0.5" />
    <path d="M86.04 145.02V159.38" stroke="black" stroke-width="0.5" />
    <path d="M91.02 155.76L90.98 155.87" stroke="black" stroke-width="0.5" />
    <path d="M90.98 155.87L90.86 155.92" stroke="black" stroke-width="0.5" />
    <path d="M90.86 155.92L90.75 155.87" stroke="black" stroke-width="0.5" />
    <path d="M90.75 155.87L90.7 155.76" stroke="black" stroke-width="0.5" />
    <path d="M90.7 155.76L90.75 155.64" stroke="black" stroke-width="0.5" />
    <path d="M90.75 155.64L90.86 155.59" stroke="black" stroke-width="0.5" />
    <path d="M90.86 155.59L90.98 155.64" stroke="black" stroke-width="0.5" />
    <path d="M90.98 155.64L91.02 155.76" stroke="black" stroke-width="0.5" />
    <path d="M90.5 156.33L90.52 156.4" stroke="black" stroke-width="0.5" />
    <path d="M90.52 156.4L90.53 156.47" stroke="black" stroke-width="0.5" />
    <path d="M90.53 156.47L90.55 156.53" stroke="black" stroke-width="0.5" />
    <path d="M90.55 156.53L90.57 156.59" stroke="black" stroke-width="0.5" />
    <path d="M90.57 156.59L90.59 156.65" stroke="black" stroke-width="0.5" />
    <path d="M90.59 156.65L90.61 156.71" stroke="black" stroke-width="0.5" />
    <path d="M90.61 156.71L90.63 156.77" stroke="black" stroke-width="0.5" />
    <path d="M90.63 156.77L90.65 156.82" stroke="black" stroke-width="0.5" />
    <path d="M90.65 156.82L90.68 156.87" stroke="black" stroke-width="0.5" />
    <path d="M90.68 156.87L90.7 156.92" stroke="black" stroke-width="0.5" />
    <path d="M90.7 156.92L90.72 156.96" stroke="black" stroke-width="0.5" />
    <path d="M90.72 156.96L90.75 157.01" stroke="black" stroke-width="0.5" />
    <path d="M90.75 157.01L90.78 157.05" stroke="black" stroke-width="0.5" />
    <path d="M90.78 157.05L90.8 157.09" stroke="black" stroke-width="0.5" />
    <path d="M90.8 157.09L90.83 157.12" stroke="black" stroke-width="0.5" />
    <path d="M90.83 157.12L90.86 157.16" stroke="black" stroke-width="0.5" />
    <path d="M90.86 157.16L90.89 157.19" stroke="black" stroke-width="0.5" />
    <path d="M90.89 157.19L90.92 157.22" stroke="black" stroke-width="0.5" />
    <path d="M90.92 157.22L90.96 157.25" stroke="black" stroke-width="0.5" />
    <path d="M90.96 157.25L90.99 157.28" stroke="black" stroke-width="0.5" />
    <path d="M90.99 157.28L91.02 157.3" stroke="black" stroke-width="0.5" />
    <path d="M91.02 157.3L91.06 157.32" stroke="black" stroke-width="0.5" />
    <path d="M91.06 157.32L91.09 157.34" stroke="black" stroke-width="0.5" />
    <path d="M91.09 157.34L91.13 157.36" stroke="black" stroke-width="0.5" />
    <path d="M91.13 157.36L91.17 157.38" stroke="black" stroke-width="0.5" />
    <path d="M91.17 157.38L91.21 157.39" stroke="black" stroke-width="0.5" />
    <path d="M91.21 157.39L91.25 157.4" stroke="black" stroke-width="0.5" />
    <path d="M91.25 157.4L91.29 157.41" stroke="black" stroke-width="0.5" />
    <path d="M91.29 157.41L91.33 157.42" stroke="black" stroke-width="0.5" />
    <path d="M91.33 157.42H91.37" stroke="black" stroke-width="0.5" />
    <path d="M91.37 157.42L91.41 157.43" stroke="black" stroke-width="0.5" />
    <path d="M91.41 157.43H91.46" stroke="black" stroke-width="0.5" />
    <path d="M91.46 157.43H91.49" stroke="black" stroke-width="0.5" />
    <path d="M91.49 157.43L91.53 157.42" stroke="black" stroke-width="0.5" />
    <path d="M91.53 157.42H91.57" stroke="black" stroke-width="0.5" />
    <path d="M91.57 157.42H91.6" stroke="black" stroke-width="0.5" />
    <path d="M91.6 157.42L91.64 157.41" stroke="black" stroke-width="0.5" />
    <path d="M91.64 157.41L91.67 157.4" stroke="black" stroke-width="0.5" />
    <path d="M91.67 157.4H91.71" stroke="black" stroke-width="0.5" />
    <path d="M91.71 157.4L91.74 157.39" stroke="black" stroke-width="0.5" />
    <path d="M91.74 157.39L91.78 157.38" stroke="black" stroke-width="0.5" />
    <path d="M91.78 157.38L91.81 157.37" stroke="black" stroke-width="0.5" />
    <path d="M91.81 157.37L91.84 157.35" stroke="black" stroke-width="0.5" />
    <path d="M91.84 157.35L91.87 157.34" stroke="black" stroke-width="0.5" />
    <path d="M91.87 157.34L91.91 157.33" stroke="black" stroke-width="0.5" />
    <path d="M91.91 157.33L91.94 157.31" stroke="black" stroke-width="0.5" />
    <path d="M91.94 157.31L91.97 157.29" stroke="black" stroke-width="0.5" />
    <path d="M91.97 157.29L92 157.28" stroke="black" stroke-width="0.5" />
    <path d="M92 157.28L92.03 157.26" stroke="black" stroke-width="0.5" />
    <path d="M92.03 157.26L92.05 157.24" stroke="black" stroke-width="0.5" />
    <path d="M92.05 157.24L92.08 157.22" stroke="black" stroke-width="0.5" />
    <path d="M92.08 157.22L92.11 157.2" stroke="black" stroke-width="0.5" />
    <path d="M92.11 157.2L92.14 157.18" stroke="black" stroke-width="0.5" />
    <path d="M92.14 157.18L92.17 157.15" stroke="black" stroke-width="0.5" />
    <path d="M92.17 157.15L92.19 157.13" stroke="black" stroke-width="0.5" />
    <path d="M92.19 157.13L92.22 157.1" stroke="black" stroke-width="0.5" />
    <path d="M92.22 157.1L92.24 157.08" stroke="black" stroke-width="0.5" />
    <path d="M92.24 157.08L92.27 157.05" stroke="black" stroke-width="0.5" />
    <path d="M92.27 157.05L92.29 157.03" stroke="black" stroke-width="0.5" />
    <path d="M92.29 157.03L92.31 157" stroke="black" stroke-width="0.5" />
    <path d="M92.31 157L92.34 156.97" stroke="black" stroke-width="0.5" />
    <path d="M92.34 156.97L92.36 156.94" stroke="black" stroke-width="0.5" />
    <path d="M92.36 156.94L92.38 156.91" stroke="black" stroke-width="0.5" />
    <path d="M92.38 156.91L92.4 156.88" stroke="black" stroke-width="0.5" />
    <path d="M92.4 156.88L92.42 156.85" stroke="black" stroke-width="0.5" />
    <path d="M92.42 156.85L92.44 156.82" stroke="black" stroke-width="0.5" />
    <path d="M92.44 156.82L92.46 156.79" stroke="black" stroke-width="0.5" />
    <path d="M92.46 156.79L92.48 156.76" stroke="black" stroke-width="0.5" />
    <path d="M92.48 156.76L92.5 156.73" stroke="black" stroke-width="0.5" />
    <path d="M92.5 156.73L92.52 156.69" stroke="black" stroke-width="0.5" />
    <path d="M92.52 156.69L92.53 156.66" stroke="black" stroke-width="0.5" />
    <path d="M92.53 156.66L92.55 156.62" stroke="black" stroke-width="0.5" />
    <path d="M92.55 156.62L92.57 156.59" stroke="black" stroke-width="0.5" />
    <path d="M92.57 156.59L92.58 156.55" stroke="black" stroke-width="0.5" />
    <path d="M92.58 156.55L92.59 156.52" stroke="black" stroke-width="0.5" />
    <path d="M92.59 156.52L92.61 156.48" stroke="black" stroke-width="0.5" />
    <path d="M92.61 156.48L92.62 156.45" stroke="black" stroke-width="0.5" />
    <path d="M92.62 156.45L92.63 156.41" stroke="black" stroke-width="0.5" />
    <path d="M92.63 156.41L92.66 156.34" stroke="black" stroke-width="0.5" />
    <path d="M92.66 156.34L92.68 156.26" stroke="black" stroke-width="0.5" />
    <path d="M92.68 156.26L92.7 156.19" stroke="black" stroke-width="0.5" />
    <path d="M92.7 156.19L92.71 156.11" stroke="black" stroke-width="0.5" />
    <path d="M92.71 156.11L92.72 156.04" stroke="black" stroke-width="0.5" />
    <path d="M92.72 156.04L92.73 155.96" stroke="black" stroke-width="0.5" />
    <path d="M92.73 155.96L92.74 155.88" stroke="black" stroke-width="0.5" />
    <path d="M92.74 155.88V155.85" stroke="black" stroke-width="0.5" />
    <path d="M92.74 155.85L92.75 155.81" stroke="black" stroke-width="0.5" />
    <path d="M92.75 155.81V155.77" stroke="black" stroke-width="0.5" />
    <path d="M92.75 155.77V155.73" stroke="black" stroke-width="0.5" />
    <path d="M92.75 155.73V155.69" stroke="black" stroke-width="0.5" />
    <path d="M92.75 155.69V155.66" stroke="black" stroke-width="0.5" />
    <path d="M92.75 155.66L92.74 155.62" stroke="black" stroke-width="0.5" />
    <path d="M92.74 155.62V155.58" stroke="black" stroke-width="0.5" />
    <path d="M92.74 155.58L92.73 155.5" stroke="black" stroke-width="0.5" />
    <path d="M92.73 155.5L92.72 155.43" stroke="black" stroke-width="0.5" />
    <path d="M92.72 155.43L92.71 155.35" stroke="black" stroke-width="0.5" />
    <path d="M92.71 155.35L92.7 155.28" stroke="black" stroke-width="0.5" />
    <path d="M92.7 155.28L92.68 155.2" stroke="black" stroke-width="0.5" />
    <path d="M92.68 155.2L92.66 155.13" stroke="black" stroke-width="0.5" />
    <path d="M92.66 155.13L92.63 155.05" stroke="black" stroke-width="0.5" />
    <path d="M92.63 155.05L92.62 155.02" stroke="black" stroke-width="0.5" />
    <path d="M92.62 155.02L92.61 154.98" stroke="black" stroke-width="0.5" />
    <path d="M92.61 154.98L92.59 154.95" stroke="black" stroke-width="0.5" />
    <path d="M92.59 154.95L92.58 154.91" stroke="black" stroke-width="0.5" />
    <path d="M92.58 154.91L92.57 154.88" stroke="black" stroke-width="0.5" />
    <path d="M92.57 154.88L92.55 154.84" stroke="black" stroke-width="0.5" />
    <path d="M92.55 154.84L92.53 154.81" stroke="black" stroke-width="0.5" />
    <path d="M92.53 154.81L92.52 154.77" stroke="black" stroke-width="0.5" />
    <path d="M92.52 154.77L92.5 154.74" stroke="black" stroke-width="0.5" />
    <path d="M92.5 154.74L92.48 154.71" stroke="black" stroke-width="0.5" />
    <path d="M92.48 154.71L92.46 154.68" stroke="black" stroke-width="0.5" />
    <path d="M92.46 154.68L92.44 154.64" stroke="black" stroke-width="0.5" />
    <path d="M92.44 154.64L92.42 154.61" stroke="black" stroke-width="0.5" />
    <path d="M92.42 154.61L92.4 154.58" stroke="black" stroke-width="0.5" />
    <path d="M92.4 154.58L92.38 154.55" stroke="black" stroke-width="0.5" />
    <path d="M92.38 154.55L92.36 154.52" stroke="black" stroke-width="0.5" />
    <path d="M92.36 154.52L92.34 154.49" stroke="black" stroke-width="0.5" />
    <path d="M92.34 154.49L92.31 154.47" stroke="black" stroke-width="0.5" />
    <path d="M92.31 154.47L92.29 154.44" stroke="black" stroke-width="0.5" />
    <path d="M92.29 154.44L92.27 154.41" stroke="black" stroke-width="0.5" />
    <path d="M92.27 154.41L92.24 154.39" stroke="black" stroke-width="0.5" />
    <path d="M92.24 154.39L92.22 154.36" stroke="black" stroke-width="0.5" />
    <path d="M92.22 154.36L92.19 154.34" stroke="black" stroke-width="0.5" />
    <path d="M92.19 154.34L92.17 154.31" stroke="black" stroke-width="0.5" />
    <path d="M92.17 154.31L92.14 154.29" stroke="black" stroke-width="0.5" />
    <path d="M92.14 154.29L92.11 154.27" stroke="black" stroke-width="0.5" />
    <path d="M92.11 154.27L92.08 154.25" stroke="black" stroke-width="0.5" />
    <path d="M92.08 154.25L92.05 154.23" stroke="black" stroke-width="0.5" />
    <path d="M92.05 154.23L92.03 154.21" stroke="black" stroke-width="0.5" />
    <path d="M92.03 154.21L92 154.19" stroke="black" stroke-width="0.5" />
    <path d="M92 154.19L91.97 154.17" stroke="black" stroke-width="0.5" />
    <path d="M91.97 154.17L91.94 154.15" stroke="black" stroke-width="0.5" />
    <path d="M91.94 154.15L91.91 154.14" stroke="black" stroke-width="0.5" />
    <path d="M91.91 154.14L91.87 154.12" stroke="black" stroke-width="0.5" />
    <path d="M91.87 154.12L91.84 154.11" stroke="black" stroke-width="0.5" />
    <path d="M91.84 154.11L91.81 154.1" stroke="black" stroke-width="0.5" />
    <path d="M91.81 154.1L91.78 154.09" stroke="black" stroke-width="0.5" />
    <path d="M91.78 154.09L91.74 154.08" stroke="black" stroke-width="0.5" />
    <path d="M91.74 154.08L91.71 154.07" stroke="black" stroke-width="0.5" />
    <path d="M91.71 154.07L91.67 154.06" stroke="black" stroke-width="0.5" />
    <path d="M91.67 154.06L91.64 154.05" stroke="black" stroke-width="0.5" />
    <path d="M91.64 154.05H91.6" stroke="black" stroke-width="0.5" />
    <path d="M91.6 154.05L91.57 154.04" stroke="black" stroke-width="0.5" />
    <path d="M91.57 154.04H91.53" stroke="black" stroke-width="0.5" />
    <path d="M91.53 154.04H91.49" stroke="black" stroke-width="0.5" />
    <path d="M91.49 154.04H91.46" stroke="black" stroke-width="0.5" />
    <path d="M91.46 154.04H91.41" stroke="black" stroke-width="0.5" />
    <path d="M91.41 154.04H91.37" stroke="black" stroke-width="0.5" />
    <path d="M91.37 154.04L91.33 154.05" stroke="black" stroke-width="0.5" />
    <path d="M91.33 154.05H91.29" stroke="black" stroke-width="0.5" />
    <path d="M91.29 154.05L91.25 154.06" stroke="black" stroke-width="0.5" />
    <path d="M91.25 154.06L91.21 154.07" stroke="black" stroke-width="0.5" />
    <path d="M91.21 154.07L91.17 154.09" stroke="black" stroke-width="0.5" />
    <path d="M91.17 154.09L91.13 154.1" stroke="black" stroke-width="0.5" />
    <path d="M91.13 154.1L91.1 154.12" stroke="black" stroke-width="0.5" />
    <path d="M91.1 154.12L91.06 154.14" stroke="black" stroke-width="0.5" />
    <path d="M91.06 154.14L91.03 154.16" stroke="black" stroke-width="0.5" />
    <path d="M91.03 154.16L91 154.18" stroke="black" stroke-width="0.5" />
    <path d="M91 154.18L90.96 154.21" stroke="black" stroke-width="0.5" />
    <path d="M90.96 154.21L90.93 154.24" stroke="black" stroke-width="0.5" />
    <path d="M90.93 154.24L90.9 154.27" stroke="black" stroke-width="0.5" />
    <path d="M90.9 154.27L90.87 154.3" stroke="black" stroke-width="0.5" />
    <path d="M90.87 154.3L90.84 154.33" stroke="black" stroke-width="0.5" />
    <path d="M90.84 154.33L90.81 154.37" stroke="black" stroke-width="0.5" />
    <path d="M90.81 154.37L90.79 154.4" stroke="black" stroke-width="0.5" />
    <path d="M90.79 154.4L90.76 154.44" stroke="black" stroke-width="0.5" />
    <path d="M90.76 154.44L90.73 154.49" stroke="black" stroke-width="0.5" />
    <path d="M90.73 154.49L90.71 154.53" stroke="black" stroke-width="0.5" />
    <path d="M90.71 154.53L90.68 154.58" stroke="black" stroke-width="0.5" />
    <path d="M90.68 154.58L90.66 154.63" stroke="black" stroke-width="0.5" />
    <path d="M90.66 154.63L90.64 154.68" stroke="black" stroke-width="0.5" />
    <path d="M90.64 154.68L90.62 154.73" stroke="black" stroke-width="0.5" />
    <path d="M90.62 154.73L90.6 154.79" stroke="black" stroke-width="0.5" />
    <path d="M90.6 154.79L90.58 154.84" stroke="black" stroke-width="0.5" />
    <path d="M90.58 154.84L90.56 154.9" stroke="black" stroke-width="0.5" />
    <path d="M90.56 154.9L90.54 154.96" stroke="black" stroke-width="0.5" />
    <path d="M90.54 154.96L90.53 155.03" stroke="black" stroke-width="0.5" />
    <path d="M90.53 155.03L90.51 155.09" stroke="black" stroke-width="0.5" />
    <path d="M91.43 153.68L92.16 153.85" stroke="black" stroke-width="0.5" />
    <path d="M92.16 153.85L92.76 154.36" stroke="black" stroke-width="0.5" />
    <path d="M92.76 154.36L93.13 155.1" stroke="black" stroke-width="0.5" />
    <path d="M93.13 155.1L93.21 155.95" stroke="black" stroke-width="0.5" />
    <path d="M93.21 155.95L92.98 156.76" stroke="black" stroke-width="0.5" />
    <path d="M92.98 156.76L92.48 157.4" stroke="black" stroke-width="0.5" />
    <path d="M92.48 157.4L91.8 157.74" stroke="black" stroke-width="0.5" />
    <path d="M91.8 157.74H91.05" stroke="black" stroke-width="0.5" />
    <path d="M91.05 157.74L90.37 157.4" stroke="black" stroke-width="0.5" />
    <path d="M90.37 157.4L89.87 156.76" stroke="black" stroke-width="0.5" />
    <path d="M89.87 156.76L89.64 155.95" stroke="black" stroke-width="0.5" />
    <path d="M89.64 155.95L89.72 155.1" stroke="black" stroke-width="0.5" />
    <path d="M89.72 155.1L90.09 154.36" stroke="black" stroke-width="0.5" />
    <path d="M90.09 154.36L90.7 153.85" stroke="black" stroke-width="0.5" />
    <path d="M90.7 153.85L91.43 153.68" stroke="black" stroke-width="0.5" />
    <path d="M90.5 156.33L90.51 155.09" stroke="black" stroke-width="0.5" />
    <path d="M87.37 146.19V160.55" stroke="black" stroke-width="0.5" />
    <path d="M143.15 155.76L143.1 155.87" stroke="black" stroke-width="0.5" />
    <path d="M143.1 155.87L142.99 155.92" stroke="black" stroke-width="0.5" />
    <path d="M142.99 155.92L142.87 155.87" stroke="black" stroke-width="0.5" />
    <path d="M142.87 155.87L142.83 155.76" stroke="black" stroke-width="0.5" />
    <path d="M142.83 155.76L142.87 155.64" stroke="black" stroke-width="0.5" />
    <path d="M142.87 155.64L142.99 155.59" stroke="black" stroke-width="0.5" />
    <path d="M142.99 155.59L143.1 155.64" stroke="black" stroke-width="0.5" />
    <path d="M143.1 155.64L143.15 155.76" stroke="black" stroke-width="0.5" />
    <path d="M143.35 156.33L143.33 156.4" stroke="black" stroke-width="0.5" />
    <path d="M143.33 156.4L143.32 156.47" stroke="black" stroke-width="0.5" />
    <path d="M143.32 156.47L143.3 156.53" stroke="black" stroke-width="0.5" />
    <path d="M143.3 156.53L143.28 156.59" stroke="black" stroke-width="0.5" />
    <path d="M143.28 156.59L143.26 156.65" stroke="black" stroke-width="0.5" />
    <path d="M143.26 156.65L143.24 156.71" stroke="black" stroke-width="0.5" />
    <path d="M143.24 156.71L143.22 156.77" stroke="black" stroke-width="0.5" />
    <path d="M143.22 156.77L143.2 156.82" stroke="black" stroke-width="0.5" />
    <path d="M143.2 156.82L143.17 156.87" stroke="black" stroke-width="0.5" />
    <path d="M143.17 156.87L143.15 156.92" stroke="black" stroke-width="0.5" />
    <path d="M143.15 156.92L143.12 156.96" stroke="black" stroke-width="0.5" />
    <path d="M143.12 156.96L143.1 157.01" stroke="black" stroke-width="0.5" />
    <path d="M143.1 157.01L143.07 157.05" stroke="black" stroke-width="0.5" />
    <path d="M143.07 157.05L143.04 157.09" stroke="black" stroke-width="0.5" />
    <path d="M143.04 157.09L143.02 157.12" stroke="black" stroke-width="0.5" />
    <path d="M143.02 157.12L142.99 157.16" stroke="black" stroke-width="0.5" />
    <path d="M142.99 157.16L142.96 157.19" stroke="black" stroke-width="0.5" />
    <path d="M142.96 157.19L142.93 157.22" stroke="black" stroke-width="0.5" />
    <path d="M142.93 157.22L142.89 157.25" stroke="black" stroke-width="0.5" />
    <path d="M142.89 157.25L142.86 157.28" stroke="black" stroke-width="0.5" />
    <path d="M142.86 157.28L142.83 157.3" stroke="black" stroke-width="0.5" />
    <path d="M142.83 157.3L142.79 157.32" stroke="black" stroke-width="0.5" />
    <path d="M142.79 157.32L142.76 157.34" stroke="black" stroke-width="0.5" />
    <path d="M142.76 157.34L142.72 157.36" stroke="black" stroke-width="0.5" />
    <path d="M142.72 157.36L142.68 157.38" stroke="black" stroke-width="0.5" />
    <path d="M142.68 157.38L142.64 157.39" stroke="black" stroke-width="0.5" />
    <path d="M142.64 157.39L142.6 157.4" stroke="black" stroke-width="0.5" />
    <path d="M142.6 157.4L142.56 157.41" stroke="black" stroke-width="0.5" />
    <path d="M142.56 157.41L142.52 157.42" stroke="black" stroke-width="0.5" />
    <path d="M142.52 157.42H142.48" stroke="black" stroke-width="0.5" />
    <path d="M142.48 157.42L142.44 157.43" stroke="black" stroke-width="0.5" />
    <path d="M142.44 157.43H142.39" stroke="black" stroke-width="0.5" />
    <path d="M142.39 157.43H142.36" stroke="black" stroke-width="0.5" />
    <path d="M142.36 157.43L142.32 157.42" stroke="black" stroke-width="0.5" />
    <path d="M142.32 157.42H142.28" stroke="black" stroke-width="0.5" />
    <path d="M142.28 157.42H142.25" stroke="black" stroke-width="0.5" />
    <path d="M142.25 157.42L142.21 157.41" stroke="black" stroke-width="0.5" />
    <path d="M142.21 157.41L142.18 157.4" stroke="black" stroke-width="0.5" />
    <path d="M142.18 157.4H142.14" stroke="black" stroke-width="0.5" />
    <path d="M142.14 157.4L142.11 157.39" stroke="black" stroke-width="0.5" />
    <path d="M142.11 157.39L142.07 157.38" stroke="black" stroke-width="0.5" />
    <path d="M142.07 157.38L142.04 157.37" stroke="black" stroke-width="0.5" />
    <path d="M142.04 157.37L142.01 157.35" stroke="black" stroke-width="0.5" />
    <path d="M142.01 157.35L141.98 157.34" stroke="black" stroke-width="0.5" />
    <path d="M141.98 157.34L141.94 157.33" stroke="black" stroke-width="0.5" />
    <path d="M141.94 157.33L141.91 157.31" stroke="black" stroke-width="0.5" />
    <path d="M141.91 157.31L141.88 157.29" stroke="black" stroke-width="0.5" />
    <path d="M141.88 157.29L141.85 157.28" stroke="black" stroke-width="0.5" />
    <path d="M141.85 157.28L141.82 157.26" stroke="black" stroke-width="0.5" />
    <path d="M141.82 157.26L141.79 157.24" stroke="black" stroke-width="0.5" />
    <path d="M141.79 157.24L141.77 157.22" stroke="black" stroke-width="0.5" />
    <path d="M141.77 157.22L141.74 157.2" stroke="black" stroke-width="0.5" />
    <path d="M141.74 157.2L141.71 157.18" stroke="black" stroke-width="0.5" />
    <path d="M141.71 157.18L141.68 157.15" stroke="black" stroke-width="0.5" />
    <path d="M141.68 157.15L141.66 157.13" stroke="black" stroke-width="0.5" />
    <path d="M141.66 157.13L141.63 157.1" stroke="black" stroke-width="0.5" />
    <path d="M141.63 157.1L141.61 157.08" stroke="black" stroke-width="0.5" />
    <path d="M141.61 157.08L141.58 157.05" stroke="black" stroke-width="0.5" />
    <path d="M141.58 157.05L141.56 157.03" stroke="black" stroke-width="0.5" />
    <path d="M141.56 157.03L141.54 157" stroke="black" stroke-width="0.5" />
    <path d="M141.54 157L141.51 156.97" stroke="black" stroke-width="0.5" />
    <path d="M141.51 156.97L141.49 156.94" stroke="black" stroke-width="0.5" />
    <path d="M141.49 156.94L141.47 156.91" stroke="black" stroke-width="0.5" />
    <path d="M141.47 156.91L141.45 156.88" stroke="black" stroke-width="0.5" />
    <path d="M141.45 156.88L141.43 156.85" stroke="black" stroke-width="0.5" />
    <path d="M141.43 156.85L141.41 156.82" stroke="black" stroke-width="0.5" />
    <path d="M141.41 156.82L141.39 156.79" stroke="black" stroke-width="0.5" />
    <path d="M141.39 156.79L141.37 156.76" stroke="black" stroke-width="0.5" />
    <path d="M141.37 156.76L141.35 156.73" stroke="black" stroke-width="0.5" />
    <path d="M141.35 156.73L141.33 156.69" stroke="black" stroke-width="0.5" />
    <path d="M141.33 156.69L141.32 156.66" stroke="black" stroke-width="0.5" />
    <path d="M141.32 156.66L141.3 156.62" stroke="black" stroke-width="0.5" />
    <path d="M141.3 156.62L141.28 156.59" stroke="black" stroke-width="0.5" />
    <path d="M141.28 156.59L141.27 156.55" stroke="black" stroke-width="0.5" />
    <path d="M141.27 156.55L141.25 156.52" stroke="black" stroke-width="0.5" />
    <path d="M141.25 156.52L141.24 156.48" stroke="black" stroke-width="0.5" />
    <path d="M141.24 156.48L141.23 156.45" stroke="black" stroke-width="0.5" />
    <path d="M141.23 156.45L141.22 156.41" stroke="black" stroke-width="0.5" />
    <path d="M141.22 156.41L141.19 156.34" stroke="black" stroke-width="0.5" />
    <path d="M141.19 156.34L141.17 156.26" stroke="black" stroke-width="0.5" />
    <path d="M141.17 156.26L141.15 156.19" stroke="black" stroke-width="0.5" />
    <path d="M141.15 156.19L141.14 156.11" stroke="black" stroke-width="0.5" />
    <path d="M141.14 156.11L141.13 156.04" stroke="black" stroke-width="0.5" />
    <path d="M141.13 156.04L141.12 155.96" stroke="black" stroke-width="0.5" />
    <path d="M141.12 155.96L141.11 155.88" stroke="black" stroke-width="0.5" />
    <path d="M141.11 155.88V155.85" stroke="black" stroke-width="0.5" />
    <path d="M141.11 155.85L141.1 155.81" stroke="black" stroke-width="0.5" />
    <path d="M141.1 155.81V155.77" stroke="black" stroke-width="0.5" />
    <path d="M141.1 155.77V155.73" stroke="black" stroke-width="0.5" />
    <path d="M141.1 155.73V155.69" stroke="black" stroke-width="0.5" />
    <path d="M141.1 155.69V155.66" stroke="black" stroke-width="0.5" />
    <path d="M141.1 155.66L141.11 155.62" stroke="black" stroke-width="0.5" />
    <path d="M141.11 155.62V155.58" stroke="black" stroke-width="0.5" />
    <path d="M141.11 155.58L141.12 155.5" stroke="black" stroke-width="0.5" />
    <path d="M141.12 155.5L141.13 155.43" stroke="black" stroke-width="0.5" />
    <path d="M141.13 155.43L141.14 155.35" stroke="black" stroke-width="0.5" />
    <path d="M141.14 155.35L141.15 155.28" stroke="black" stroke-width="0.5" />
    <path d="M141.15 155.28L141.17 155.2" stroke="black" stroke-width="0.5" />
    <path d="M141.17 155.2L141.19 155.13" stroke="black" stroke-width="0.5" />
    <path d="M141.19 155.13L141.22 155.05" stroke="black" stroke-width="0.5" />
    <path d="M141.22 155.05L141.23 155.02" stroke="black" stroke-width="0.5" />
    <path d="M141.23 155.02L141.24 154.98" stroke="black" stroke-width="0.5" />
    <path d="M141.24 154.98L141.25 154.95" stroke="black" stroke-width="0.5" />
    <path d="M141.25 154.95L141.27 154.91" stroke="black" stroke-width="0.5" />
    <path d="M141.27 154.91L141.28 154.88" stroke="black" stroke-width="0.5" />
    <path d="M141.28 154.88L141.3 154.84" stroke="black" stroke-width="0.5" />
    <path d="M141.3 154.84L141.32 154.81" stroke="black" stroke-width="0.5" />
    <path d="M141.32 154.81L141.33 154.77" stroke="black" stroke-width="0.5" />
    <path d="M141.33 154.77L141.35 154.74" stroke="black" stroke-width="0.5" />
    <path d="M141.35 154.74L141.37 154.71" stroke="black" stroke-width="0.5" />
    <path d="M141.37 154.71L141.39 154.68" stroke="black" stroke-width="0.5" />
    <path d="M141.39 154.68L141.41 154.64" stroke="black" stroke-width="0.5" />
    <path d="M141.41 154.64L141.43 154.61" stroke="black" stroke-width="0.5" />
    <path d="M141.43 154.61L141.45 154.58" stroke="black" stroke-width="0.5" />
    <path d="M141.45 154.58L141.47 154.55" stroke="black" stroke-width="0.5" />
    <path d="M141.47 154.55L141.49 154.52" stroke="black" stroke-width="0.5" />
    <path d="M141.49 154.52L141.51 154.49" stroke="black" stroke-width="0.5" />
    <path d="M141.51 154.49L141.54 154.47" stroke="black" stroke-width="0.5" />
    <path d="M141.54 154.47L141.56 154.44" stroke="black" stroke-width="0.5" />
    <path d="M141.56 154.44L141.58 154.41" stroke="black" stroke-width="0.5" />
    <path d="M141.58 154.41L141.61 154.39" stroke="black" stroke-width="0.5" />
    <path d="M141.61 154.39L141.63 154.36" stroke="black" stroke-width="0.5" />
    <path d="M141.63 154.36L141.66 154.34" stroke="black" stroke-width="0.5" />
    <path d="M141.66 154.34L141.68 154.31" stroke="black" stroke-width="0.5" />
    <path d="M141.68 154.31L141.71 154.29" stroke="black" stroke-width="0.5" />
    <path d="M141.71 154.29L141.74 154.27" stroke="black" stroke-width="0.5" />
    <path d="M141.74 154.27L141.77 154.25" stroke="black" stroke-width="0.5" />
    <path d="M141.77 154.25L141.79 154.23" stroke="black" stroke-width="0.5" />
    <path d="M141.79 154.23L141.82 154.21" stroke="black" stroke-width="0.5" />
    <path d="M141.82 154.21L141.85 154.19" stroke="black" stroke-width="0.5" />
    <path d="M141.85 154.19L141.88 154.17" stroke="black" stroke-width="0.5" />
    <path d="M141.88 154.17L141.91 154.15" stroke="black" stroke-width="0.5" />
    <path d="M141.91 154.15L141.94 154.14" stroke="black" stroke-width="0.5" />
    <path d="M141.94 154.14L141.98 154.12" stroke="black" stroke-width="0.5" />
    <path d="M141.98 154.12L142.01 154.11" stroke="black" stroke-width="0.5" />
    <path d="M142.01 154.11L142.04 154.1" stroke="black" stroke-width="0.5" />
    <path d="M142.04 154.1L142.07 154.09" stroke="black" stroke-width="0.5" />
    <path d="M142.07 154.09L142.11 154.08" stroke="black" stroke-width="0.5" />
    <path d="M142.11 154.08L142.14 154.07" stroke="black" stroke-width="0.5" />
    <path d="M142.14 154.07L142.18 154.06" stroke="black" stroke-width="0.5" />
    <path d="M142.18 154.06L142.21 154.05" stroke="black" stroke-width="0.5" />
    <path d="M142.21 154.05H142.25" stroke="black" stroke-width="0.5" />
    <path d="M142.25 154.05L142.28 154.04" stroke="black" stroke-width="0.5" />
    <path d="M142.28 154.04H142.32" stroke="black" stroke-width="0.5" />
    <path d="M142.32 154.04H142.36" stroke="black" stroke-width="0.5" />
    <path d="M142.36 154.04H142.39" stroke="black" stroke-width="0.5" />
    <path d="M142.39 154.04H142.44" stroke="black" stroke-width="0.5" />
    <path d="M142.44 154.04H142.48" stroke="black" stroke-width="0.5" />
    <path d="M142.48 154.04L142.52 154.05" stroke="black" stroke-width="0.5" />
    <path d="M142.52 154.05H142.56" stroke="black" stroke-width="0.5" />
    <path d="M142.56 154.05L142.6 154.06" stroke="black" stroke-width="0.5" />
    <path d="M142.6 154.06L142.64 154.07" stroke="black" stroke-width="0.5" />
    <path d="M142.64 154.07L142.68 154.09" stroke="black" stroke-width="0.5" />
    <path d="M142.68 154.09L142.71 154.1" stroke="black" stroke-width="0.5" />
    <path d="M142.71 154.1L142.75 154.12" stroke="black" stroke-width="0.5" />
    <path d="M142.75 154.12L142.79 154.14" stroke="black" stroke-width="0.5" />
    <path d="M142.79 154.14L142.82 154.16" stroke="black" stroke-width="0.5" />
    <path d="M142.82 154.16L142.85 154.18" stroke="black" stroke-width="0.5" />
    <path d="M142.85 154.18L142.89 154.21" stroke="black" stroke-width="0.5" />
    <path d="M142.89 154.21L142.92 154.24" stroke="black" stroke-width="0.5" />
    <path d="M142.92 154.24L142.95 154.27" stroke="black" stroke-width="0.5" />
    <path d="M142.95 154.27L142.98 154.3" stroke="black" stroke-width="0.5" />
    <path d="M142.98 154.3L143.01 154.33" stroke="black" stroke-width="0.5" />
    <path d="M143.01 154.33L143.04 154.37" stroke="black" stroke-width="0.5" />
    <path d="M143.04 154.37L143.06 154.4" stroke="black" stroke-width="0.5" />
    <path d="M143.06 154.4L143.09 154.44" stroke="black" stroke-width="0.5" />
    <path d="M143.09 154.44L143.12 154.49" stroke="black" stroke-width="0.5" />
    <path d="M143.12 154.49L143.14 154.53" stroke="black" stroke-width="0.5" />
    <path d="M143.14 154.53L143.16 154.58" stroke="black" stroke-width="0.5" />
    <path d="M143.16 154.58L143.19 154.63" stroke="black" stroke-width="0.5" />
    <path d="M143.19 154.63L143.21 154.68" stroke="black" stroke-width="0.5" />
    <path d="M143.21 154.68L143.23 154.73" stroke="black" stroke-width="0.5" />
    <path d="M143.23 154.73L143.25 154.79" stroke="black" stroke-width="0.5" />
    <path d="M143.25 154.79L143.27 154.84" stroke="black" stroke-width="0.5" />
    <path d="M143.27 154.84L143.29 154.9" stroke="black" stroke-width="0.5" />
    <path d="M143.29 154.9L143.31 154.96" stroke="black" stroke-width="0.5" />
    <path d="M143.31 154.96L143.32 155.03" stroke="black" stroke-width="0.5" />
    <path d="M143.32 155.03L143.34 155.09" stroke="black" stroke-width="0.5" />
    <path d="M142.42 153.68L141.69 153.85" stroke="black" stroke-width="0.5" />
    <path d="M141.69 153.85L141.09 154.36" stroke="black" stroke-width="0.5" />
    <path d="M141.09 154.36L140.72 155.1" stroke="black" stroke-width="0.5" />
    <path d="M140.72 155.1L140.64 155.95" stroke="black" stroke-width="0.5" />
    <path d="M140.64 155.95L140.87 156.76" stroke="black" stroke-width="0.5" />
    <path d="M140.87 156.76L141.37 157.4" stroke="black" stroke-width="0.5" />
    <path d="M141.37 157.4L142.05 157.74" stroke="black" stroke-width="0.5" />
    <path d="M142.05 157.74H142.8" stroke="black" stroke-width="0.5" />
    <path d="M142.8 157.74L143.48 157.4" stroke="black" stroke-width="0.5" />
    <path d="M143.48 157.4L143.98 156.76" stroke="black" stroke-width="0.5" />
    <path d="M143.98 156.76L144.21 155.95" stroke="black" stroke-width="0.5" />
    <path d="M144.21 155.95L144.13 155.1" stroke="black" stroke-width="0.5" />
    <path d="M144.13 155.1L143.76 154.36" stroke="black" stroke-width="0.5" />
    <path d="M143.76 154.36L143.15 153.85" stroke="black" stroke-width="0.5" />
    <path d="M143.15 153.85L142.42 153.68" stroke="black" stroke-width="0.5" />
    <path d="M143.35 156.33L143.34 155.09" stroke="black" stroke-width="0.5" />
    <path d="M151.46 155.76L151.42 155.87" stroke="black" stroke-width="0.5" />
    <path d="M151.42 155.87L151.3 155.92" stroke="black" stroke-width="0.5" />
    <path d="M151.3 155.92L151.19 155.87" stroke="black" stroke-width="0.5" />
    <path d="M151.19 155.87L151.14 155.76" stroke="black" stroke-width="0.5" />
    <path d="M151.14 155.76L151.19 155.64" stroke="black" stroke-width="0.5" />
    <path d="M151.19 155.64L151.3 155.59" stroke="black" stroke-width="0.5" />
    <path d="M151.3 155.59L151.42 155.64" stroke="black" stroke-width="0.5" />
    <path d="M151.42 155.64L151.46 155.76" stroke="black" stroke-width="0.5" />
    <path d="M150.94 156.33L150.96 156.4" stroke="black" stroke-width="0.5" />
    <path d="M150.96 156.4L150.97 156.47" stroke="black" stroke-width="0.5" />
    <path d="M150.97 156.47L150.99 156.53" stroke="black" stroke-width="0.5" />
    <path d="M150.99 156.53L151.01 156.59" stroke="black" stroke-width="0.5" />
    <path d="M151.01 156.59L151.03 156.65" stroke="black" stroke-width="0.5" />
    <path d="M151.03 156.65L151.05 156.71" stroke="black" stroke-width="0.5" />
    <path d="M151.05 156.71L151.07 156.77" stroke="black" stroke-width="0.5" />
    <path d="M151.07 156.77L151.09 156.82" stroke="black" stroke-width="0.5" />
    <path d="M151.09 156.82L151.12 156.87" stroke="black" stroke-width="0.5" />
    <path d="M151.12 156.87L151.14 156.92" stroke="black" stroke-width="0.5" />
    <path d="M151.14 156.92L151.16 156.96" stroke="black" stroke-width="0.5" />
    <path d="M151.16 156.96L151.19 157.01" stroke="black" stroke-width="0.5" />
    <path d="M151.19 157.01L151.22 157.05" stroke="black" stroke-width="0.5" />
    <path d="M151.22 157.05L151.24 157.09" stroke="black" stroke-width="0.5" />
    <path d="M151.24 157.09L151.27 157.12" stroke="black" stroke-width="0.5" />
    <path d="M151.27 157.12L151.3 157.16" stroke="black" stroke-width="0.5" />
    <path d="M151.3 157.16L151.33 157.19" stroke="black" stroke-width="0.5" />
    <path d="M151.33 157.19L151.36 157.22" stroke="black" stroke-width="0.5" />
    <path d="M151.36 157.22L151.4 157.25" stroke="black" stroke-width="0.5" />
    <path d="M151.4 157.25L151.43 157.28" stroke="black" stroke-width="0.5" />
    <path d="M151.43 157.28L151.46 157.3" stroke="black" stroke-width="0.5" />
    <path d="M151.46 157.3L151.5 157.32" stroke="black" stroke-width="0.5" />
    <path d="M151.5 157.32L151.53 157.34" stroke="black" stroke-width="0.5" />
    <path d="M151.53 157.34L151.57 157.36" stroke="black" stroke-width="0.5" />
    <path d="M151.57 157.36L151.61 157.38" stroke="black" stroke-width="0.5" />
    <path d="M151.61 157.38L151.65 157.39" stroke="black" stroke-width="0.5" />
    <path d="M151.65 157.39L151.69 157.4" stroke="black" stroke-width="0.5" />
    <path d="M151.69 157.4L151.73 157.41" stroke="black" stroke-width="0.5" />
    <path d="M151.73 157.41L151.77 157.42" stroke="black" stroke-width="0.5" />
    <path d="M151.77 157.42H151.81" stroke="black" stroke-width="0.5" />
    <path d="M151.81 157.42L151.85 157.43" stroke="black" stroke-width="0.5" />
    <path d="M151.85 157.43H151.9" stroke="black" stroke-width="0.5" />
    <path d="M151.9 157.43H151.93" stroke="black" stroke-width="0.5" />
    <path d="M151.93 157.43L151.97 157.42" stroke="black" stroke-width="0.5" />
    <path d="M151.97 157.42H152.01" stroke="black" stroke-width="0.5" />
    <path d="M152.01 157.42H152.04" stroke="black" stroke-width="0.5" />
    <path d="M152.04 157.42L152.08 157.41" stroke="black" stroke-width="0.5" />
    <path d="M152.08 157.41L152.11 157.4" stroke="black" stroke-width="0.5" />
    <path d="M152.11 157.4H152.15" stroke="black" stroke-width="0.5" />
    <path d="M152.15 157.4L152.18 157.39" stroke="black" stroke-width="0.5" />
    <path d="M152.18 157.39L152.22 157.38" stroke="black" stroke-width="0.5" />
    <path d="M152.22 157.38L152.25 157.37" stroke="black" stroke-width="0.5" />
    <path d="M152.25 157.37L152.28 157.35" stroke="black" stroke-width="0.5" />
    <path d="M152.28 157.35L152.31 157.34" stroke="black" stroke-width="0.5" />
    <path d="M152.31 157.34L152.34 157.33" stroke="black" stroke-width="0.5" />
    <path d="M152.34 157.33L152.38 157.31" stroke="black" stroke-width="0.5" />
    <path d="M152.38 157.31L152.41 157.29" stroke="black" stroke-width="0.5" />
    <path d="M152.41 157.29L152.44 157.28" stroke="black" stroke-width="0.5" />
    <path d="M152.44 157.28L152.47 157.26" stroke="black" stroke-width="0.5" />
    <path d="M152.47 157.26L152.49 157.24" stroke="black" stroke-width="0.5" />
    <path d="M152.49 157.24L152.52 157.22" stroke="black" stroke-width="0.5" />
    <path d="M152.52 157.22L152.55 157.2" stroke="black" stroke-width="0.5" />
    <path d="M152.55 157.2L152.58 157.18" stroke="black" stroke-width="0.5" />
    <path d="M152.58 157.18L152.61 157.15" stroke="black" stroke-width="0.5" />
    <path d="M152.61 157.15L152.63 157.13" stroke="black" stroke-width="0.5" />
    <path d="M152.63 157.13L152.66 157.1" stroke="black" stroke-width="0.5" />
    <path d="M152.66 157.1L152.68 157.08" stroke="black" stroke-width="0.5" />
    <path d="M152.68 157.08L152.71 157.05" stroke="black" stroke-width="0.5" />
    <path d="M152.71 157.05L152.73 157.03" stroke="black" stroke-width="0.5" />
    <path d="M152.73 157.03L152.75 157" stroke="black" stroke-width="0.5" />
    <path d="M152.75 157L152.78 156.97" stroke="black" stroke-width="0.5" />
    <path d="M152.78 156.97L152.8 156.94" stroke="black" stroke-width="0.5" />
    <path d="M152.8 156.94L152.82 156.91" stroke="black" stroke-width="0.5" />
    <path d="M152.82 156.91L152.84 156.88" stroke="black" stroke-width="0.5" />
    <path d="M152.84 156.88L152.86 156.85" stroke="black" stroke-width="0.5" />
    <path d="M152.86 156.85L152.88 156.82" stroke="black" stroke-width="0.5" />
    <path d="M152.88 156.82L152.9 156.79" stroke="black" stroke-width="0.5" />
    <path d="M152.9 156.79L152.92 156.76" stroke="black" stroke-width="0.5" />
    <path d="M152.92 156.76L152.94 156.73" stroke="black" stroke-width="0.5" />
    <path d="M152.94 156.73L152.96 156.69" stroke="black" stroke-width="0.5" />
    <path d="M152.96 156.69L152.97 156.66" stroke="black" stroke-width="0.5" />
    <path d="M152.97 156.66L152.99 156.62" stroke="black" stroke-width="0.5" />
    <path d="M152.99 156.62L153.01 156.59" stroke="black" stroke-width="0.5" />
    <path d="M153.01 156.59L153.02 156.55" stroke="black" stroke-width="0.5" />
    <path d="M153.02 156.55L153.03 156.52" stroke="black" stroke-width="0.5" />
    <path d="M153.03 156.52L153.05 156.48" stroke="black" stroke-width="0.5" />
    <path d="M153.05 156.48L153.06 156.45" stroke="black" stroke-width="0.5" />
    <path d="M153.06 156.45L153.07 156.41" stroke="black" stroke-width="0.5" />
    <path d="M153.07 156.41L153.1 156.34" stroke="black" stroke-width="0.5" />
    <path d="M153.1 156.34L153.12 156.26" stroke="black" stroke-width="0.5" />
    <path d="M153.12 156.26L153.14 156.19" stroke="black" stroke-width="0.5" />
    <path d="M153.14 156.19L153.15 156.11" stroke="black" stroke-width="0.5" />
    <path d="M153.15 156.11L153.16 156.04" stroke="black" stroke-width="0.5" />
    <path d="M153.16 156.04L153.17 155.96" stroke="black" stroke-width="0.5" />
    <path d="M153.17 155.96L153.18 155.88" stroke="black" stroke-width="0.5" />
    <path d="M153.18 155.88V155.85" stroke="black" stroke-width="0.5" />
    <path d="M153.18 155.85V155.81" stroke="black" stroke-width="0.5" />
    <path d="M153.18 155.81L153.19 155.77" stroke="black" stroke-width="0.5" />
    <path d="M153.19 155.77V155.73" stroke="black" stroke-width="0.5" />
    <path d="M153.19 155.73V155.69" stroke="black" stroke-width="0.5" />
    <path d="M153.19 155.69L153.18 155.66" stroke="black" stroke-width="0.5" />
    <path d="M153.18 155.66V155.62" stroke="black" stroke-width="0.5" />
    <path d="M153.18 155.62V155.58" stroke="black" stroke-width="0.5" />
    <path d="M153.18 155.58L153.17 155.5" stroke="black" stroke-width="0.5" />
    <path d="M153.17 155.5L153.16 155.43" stroke="black" stroke-width="0.5" />
    <path d="M153.16 155.43L153.15 155.35" stroke="black" stroke-width="0.5" />
    <path d="M153.15 155.35L153.14 155.28" stroke="black" stroke-width="0.5" />
    <path d="M153.14 155.28L153.12 155.2" stroke="black" stroke-width="0.5" />
    <path d="M153.12 155.2L153.1 155.13" stroke="black" stroke-width="0.5" />
    <path d="M153.1 155.13L153.07 155.05" stroke="black" stroke-width="0.5" />
    <path d="M153.07 155.05L153.06 155.02" stroke="black" stroke-width="0.5" />
    <path d="M153.06 155.02L153.05 154.98" stroke="black" stroke-width="0.5" />
    <path d="M153.05 154.98L153.03 154.95" stroke="black" stroke-width="0.5" />
    <path d="M153.03 154.95L153.02 154.91" stroke="black" stroke-width="0.5" />
    <path d="M153.02 154.91L153.01 154.88" stroke="black" stroke-width="0.5" />
    <path d="M153.01 154.88L152.99 154.84" stroke="black" stroke-width="0.5" />
    <path d="M152.99 154.84L152.97 154.81" stroke="black" stroke-width="0.5" />
    <path d="M152.97 154.81L152.96 154.77" stroke="black" stroke-width="0.5" />
    <path d="M152.96 154.77L152.94 154.74" stroke="black" stroke-width="0.5" />
    <path d="M152.94 154.74L152.92 154.71" stroke="black" stroke-width="0.5" />
    <path d="M152.92 154.71L152.9 154.68" stroke="black" stroke-width="0.5" />
    <path d="M152.9 154.68L152.88 154.64" stroke="black" stroke-width="0.5" />
    <path d="M152.88 154.64L152.86 154.61" stroke="black" stroke-width="0.5" />
    <path d="M152.86 154.61L152.84 154.58" stroke="black" stroke-width="0.5" />
    <path d="M152.84 154.58L152.82 154.55" stroke="black" stroke-width="0.5" />
    <path d="M152.82 154.55L152.8 154.52" stroke="black" stroke-width="0.5" />
    <path d="M152.8 154.52L152.78 154.49" stroke="black" stroke-width="0.5" />
    <path d="M152.78 154.49L152.75 154.47" stroke="black" stroke-width="0.5" />
    <path d="M152.75 154.47L152.73 154.44" stroke="black" stroke-width="0.5" />
    <path d="M152.73 154.44L152.71 154.41" stroke="black" stroke-width="0.5" />
    <path d="M152.71 154.41L152.68 154.39" stroke="black" stroke-width="0.5" />
    <path d="M152.68 154.39L152.66 154.36" stroke="black" stroke-width="0.5" />
    <path d="M152.66 154.36L152.63 154.34" stroke="black" stroke-width="0.5" />
    <path d="M152.63 154.34L152.61 154.31" stroke="black" stroke-width="0.5" />
    <path d="M152.61 154.31L152.58 154.29" stroke="black" stroke-width="0.5" />
    <path d="M152.58 154.29L152.55 154.27" stroke="black" stroke-width="0.5" />
    <path d="M152.55 154.27L152.52 154.25" stroke="black" stroke-width="0.5" />
    <path d="M152.52 154.25L152.49 154.23" stroke="black" stroke-width="0.5" />
    <path d="M152.49 154.23L152.47 154.21" stroke="black" stroke-width="0.5" />
    <path d="M152.47 154.21L152.44 154.19" stroke="black" stroke-width="0.5" />
    <path d="M152.44 154.19L152.41 154.17" stroke="black" stroke-width="0.5" />
    <path d="M152.41 154.17L152.38 154.15" stroke="black" stroke-width="0.5" />
    <path d="M152.38 154.15L152.34 154.14" stroke="black" stroke-width="0.5" />
    <path d="M152.34 154.14L152.31 154.12" stroke="black" stroke-width="0.5" />
    <path d="M152.31 154.12L152.28 154.11" stroke="black" stroke-width="0.5" />
    <path d="M152.28 154.11L152.25 154.1" stroke="black" stroke-width="0.5" />
    <path d="M152.25 154.1L152.22 154.09" stroke="black" stroke-width="0.5" />
    <path d="M152.22 154.09L152.18 154.08" stroke="black" stroke-width="0.5" />
    <path d="M152.18 154.08L152.15 154.07" stroke="black" stroke-width="0.5" />
    <path d="M152.15 154.07L152.11 154.06" stroke="black" stroke-width="0.5" />
    <path d="M152.11 154.06L152.08 154.05" stroke="black" stroke-width="0.5" />
    <path d="M152.08 154.05H152.04" stroke="black" stroke-width="0.5" />
    <path d="M152.04 154.05L152.01 154.04" stroke="black" stroke-width="0.5" />
    <path d="M152.01 154.04H151.97" stroke="black" stroke-width="0.5" />
    <path d="M151.97 154.04H151.93" stroke="black" stroke-width="0.5" />
    <path d="M151.93 154.04H151.9" stroke="black" stroke-width="0.5" />
    <path d="M151.9 154.04H151.85" stroke="black" stroke-width="0.5" />
    <path d="M151.85 154.04H151.81" stroke="black" stroke-width="0.5" />
    <path d="M151.81 154.04L151.77 154.05" stroke="black" stroke-width="0.5" />
    <path d="M151.77 154.05H151.73" stroke="black" stroke-width="0.5" />
    <path d="M151.73 154.05L151.69 154.06" stroke="black" stroke-width="0.5" />
    <path d="M151.69 154.06L151.65 154.07" stroke="black" stroke-width="0.5" />
    <path d="M151.65 154.07L151.61 154.09" stroke="black" stroke-width="0.5" />
    <path d="M151.61 154.09L151.57 154.1" stroke="black" stroke-width="0.5" />
    <path d="M151.57 154.1L151.54 154.12" stroke="black" stroke-width="0.5" />
    <path d="M151.54 154.12L151.5 154.14" stroke="black" stroke-width="0.5" />
    <path d="M151.5 154.14L151.47 154.16" stroke="black" stroke-width="0.5" />
    <path d="M151.47 154.16L151.44 154.18" stroke="black" stroke-width="0.5" />
    <path d="M151.44 154.18L151.4 154.21" stroke="black" stroke-width="0.5" />
    <path d="M151.4 154.21L151.37 154.24" stroke="black" stroke-width="0.5" />
    <path d="M151.37 154.24L151.34 154.27" stroke="black" stroke-width="0.5" />
    <path d="M151.34 154.27L151.31 154.3" stroke="black" stroke-width="0.5" />
    <path d="M151.31 154.3L151.28 154.33" stroke="black" stroke-width="0.5" />
    <path d="M151.28 154.33L151.25 154.37" stroke="black" stroke-width="0.5" />
    <path d="M151.25 154.37L151.23 154.4" stroke="black" stroke-width="0.5" />
    <path d="M151.23 154.4L151.2 154.44" stroke="black" stroke-width="0.5" />
    <path d="M151.2 154.44L151.17 154.49" stroke="black" stroke-width="0.5" />
    <path d="M151.17 154.49L151.15 154.53" stroke="black" stroke-width="0.5" />
    <path d="M151.15 154.53L151.12 154.58" stroke="black" stroke-width="0.5" />
    <path d="M151.12 154.58L151.1 154.63" stroke="black" stroke-width="0.5" />
    <path d="M151.1 154.63L151.08 154.68" stroke="black" stroke-width="0.5" />
    <path d="M151.08 154.68L151.06 154.73" stroke="black" stroke-width="0.5" />
    <path d="M151.06 154.73L151.04 154.79" stroke="black" stroke-width="0.5" />
    <path d="M151.04 154.79L151.02 154.84" stroke="black" stroke-width="0.5" />
    <path d="M151.02 154.84L151 154.9" stroke="black" stroke-width="0.5" />
    <path d="M151 154.9L150.98 154.96" stroke="black" stroke-width="0.5" />
    <path d="M150.98 154.96L150.97 155.03" stroke="black" stroke-width="0.5" />
    <path d="M150.97 155.03L150.95 155.09" stroke="black" stroke-width="0.5" />
    <path d="M151.87 153.68L152.6 153.85" stroke="black" stroke-width="0.5" />
    <path d="M152.6 153.85L153.2 154.36" stroke="black" stroke-width="0.5" />
    <path d="M153.2 154.36L153.57 155.1" stroke="black" stroke-width="0.5" />
    <path d="M153.57 155.1L153.65 155.95" stroke="black" stroke-width="0.5" />
    <path d="M153.65 155.95L153.42 156.76" stroke="black" stroke-width="0.5" />
    <path d="M153.42 156.76L152.92 157.4" stroke="black" stroke-width="0.5" />
    <path d="M152.92 157.4L152.24 157.74" stroke="black" stroke-width="0.5" />
    <path d="M152.24 157.74H151.49" stroke="black" stroke-width="0.5" />
    <path d="M151.49 157.74L150.81 157.4" stroke="black" stroke-width="0.5" />
    <path d="M150.81 157.4L150.31 156.76" stroke="black" stroke-width="0.5" />
    <path d="M150.31 156.76L150.08 155.95" stroke="black" stroke-width="0.5" />
    <path d="M150.08 155.95L150.16 155.1" stroke="black" stroke-width="0.5" />
    <path d="M150.16 155.1L150.53 154.36" stroke="black" stroke-width="0.5" />
    <path d="M150.53 154.36L151.14 153.85" stroke="black" stroke-width="0.5" />
    <path d="M151.14 153.85L151.87 153.68" stroke="black" stroke-width="0.5" />
    <path d="M150.94 156.33L150.95 155.09" stroke="black" stroke-width="0.5" />
    <path d="M201.23 158.95L201.18 159.06" stroke="black" stroke-width="0.5" />
    <path d="M201.18 159.06L201.07 159.11" stroke="black" stroke-width="0.5" />
    <path d="M201.07 159.11L200.95 159.06" stroke="black" stroke-width="0.5" />
    <path d="M200.95 159.06L200.91 158.95" stroke="black" stroke-width="0.5" />
    <path d="M200.91 158.95L200.95 158.83" stroke="black" stroke-width="0.5" />
    <path d="M200.95 158.83L201.07 158.79" stroke="black" stroke-width="0.5" />
    <path d="M201.07 158.79L201.18 158.83" stroke="black" stroke-width="0.5" />
    <path d="M201.18 158.83L201.23 158.95" stroke="black" stroke-width="0.5" />
    <path d="M201.43 159.53L201.41 159.59" stroke="black" stroke-width="0.5" />
    <path d="M201.41 159.59L201.4 159.66" stroke="black" stroke-width="0.5" />
    <path d="M201.4 159.66L201.38 159.72" stroke="black" stroke-width="0.5" />
    <path d="M201.38 159.72L201.36 159.79" stroke="black" stroke-width="0.5" />
    <path d="M201.36 159.79L201.34 159.85" stroke="black" stroke-width="0.5" />
    <path d="M201.34 159.85L201.32 159.9" stroke="black" stroke-width="0.5" />
    <path d="M201.32 159.9L201.3 159.96" stroke="black" stroke-width="0.5" />
    <path d="M201.3 159.96L201.28 160.01" stroke="black" stroke-width="0.5" />
    <path d="M201.28 160.01L201.25 160.06" stroke="black" stroke-width="0.5" />
    <path d="M201.25 160.06L201.23 160.11" stroke="black" stroke-width="0.5" />
    <path d="M201.23 160.11L201.2 160.15" stroke="black" stroke-width="0.5" />
    <path d="M201.2 160.15L201.18 160.2" stroke="black" stroke-width="0.5" />
    <path d="M201.18 160.2L201.15 160.24" stroke="black" stroke-width="0.5" />
    <path d="M201.15 160.24L201.12 160.28" stroke="black" stroke-width="0.5" />
    <path d="M201.12 160.28L201.1 160.32" stroke="black" stroke-width="0.5" />
    <path d="M201.1 160.32L201.07 160.35" stroke="black" stroke-width="0.5" />
    <path d="M201.07 160.35L201.04 160.38" stroke="black" stroke-width="0.5" />
    <path d="M201.04 160.38L201 160.41" stroke="black" stroke-width="0.5" />
    <path d="M201 160.41L200.97 160.44" stroke="black" stroke-width="0.5" />
    <path d="M200.97 160.44L200.94 160.47" stroke="black" stroke-width="0.5" />
    <path d="M200.94 160.47L200.91 160.49" stroke="black" stroke-width="0.5" />
    <path d="M200.91 160.49L200.87 160.51" stroke="black" stroke-width="0.5" />
    <path d="M200.87 160.51L200.84 160.53" stroke="black" stroke-width="0.5" />
    <path d="M200.84 160.53L200.8 160.55" stroke="black" stroke-width="0.5" />
    <path d="M200.8 160.55L200.76 160.57" stroke="black" stroke-width="0.5" />
    <path d="M200.76 160.57L200.72 160.58" stroke="black" stroke-width="0.5" />
    <path d="M200.72 160.58L200.68 160.59" stroke="black" stroke-width="0.5" />
    <path d="M200.68 160.59L200.64 160.6" stroke="black" stroke-width="0.5" />
    <path d="M200.64 160.6L200.6 160.61" stroke="black" stroke-width="0.5" />
    <path d="M200.6 160.61H200.56" stroke="black" stroke-width="0.5" />
    <path d="M200.56 160.61L200.52 160.62" stroke="black" stroke-width="0.5" />
    <path d="M200.52 160.62H200.47" stroke="black" stroke-width="0.5" />
    <path d="M200.47 160.62H200.43" stroke="black" stroke-width="0.5" />
    <path d="M200.43 160.62H200.4" stroke="black" stroke-width="0.5" />
    <path d="M200.4 160.62L200.36 160.61" stroke="black" stroke-width="0.5" />
    <path d="M200.36 160.61H200.33" stroke="black" stroke-width="0.5" />
    <path d="M200.33 160.61L200.29 160.6" stroke="black" stroke-width="0.5" />
    <path d="M200.29 160.6H200.26" stroke="black" stroke-width="0.5" />
    <path d="M200.26 160.6L200.22 160.59" stroke="black" stroke-width="0.5" />
    <path d="M200.22 160.59L200.19 160.58" stroke="black" stroke-width="0.5" />
    <path d="M200.19 160.58L200.15 160.57" stroke="black" stroke-width="0.5" />
    <path d="M200.15 160.57L200.12 160.56" stroke="black" stroke-width="0.5" />
    <path d="M200.12 160.56L200.09 160.55" stroke="black" stroke-width="0.5" />
    <path d="M200.09 160.55L200.06 160.53" stroke="black" stroke-width="0.5" />
    <path d="M200.06 160.53L200.02 160.52" stroke="black" stroke-width="0.5" />
    <path d="M200.02 160.52L199.99 160.5" stroke="black" stroke-width="0.5" />
    <path d="M199.99 160.5L199.96 160.49" stroke="black" stroke-width="0.5" />
    <path d="M199.96 160.49L199.93 160.47" stroke="black" stroke-width="0.5" />
    <path d="M199.93 160.47L199.9 160.45" stroke="black" stroke-width="0.5" />
    <path d="M199.9 160.45L199.87 160.43" stroke="black" stroke-width="0.5" />
    <path d="M199.87 160.43L199.85 160.41" stroke="black" stroke-width="0.5" />
    <path d="M199.85 160.41L199.82 160.39" stroke="black" stroke-width="0.5" />
    <path d="M199.82 160.39L199.79 160.37" stroke="black" stroke-width="0.5" />
    <path d="M199.79 160.37L199.76 160.34" stroke="black" stroke-width="0.5" />
    <path d="M199.76 160.34L199.74 160.32" stroke="black" stroke-width="0.5" />
    <path d="M199.74 160.32L199.71 160.3" stroke="black" stroke-width="0.5" />
    <path d="M199.71 160.3L199.69 160.27" stroke="black" stroke-width="0.5" />
    <path d="M199.69 160.27L199.66 160.24" stroke="black" stroke-width="0.5" />
    <path d="M199.66 160.24L199.64 160.22" stroke="black" stroke-width="0.5" />
    <path d="M199.64 160.22L199.61 160.19" stroke="black" stroke-width="0.5" />
    <path d="M199.61 160.19L199.59 160.16" stroke="black" stroke-width="0.5" />
    <path d="M199.59 160.16L199.57 160.13" stroke="black" stroke-width="0.5" />
    <path d="M199.57 160.13L199.55 160.1" stroke="black" stroke-width="0.5" />
    <path d="M199.55 160.1L199.53 160.07" stroke="black" stroke-width="0.5" />
    <path d="M199.53 160.07L199.51 160.04" stroke="black" stroke-width="0.5" />
    <path d="M199.51 160.04L199.49 160.01" stroke="black" stroke-width="0.5" />
    <path d="M199.49 160.01L199.47 159.98" stroke="black" stroke-width="0.5" />
    <path d="M199.47 159.98L199.45 159.95" stroke="black" stroke-width="0.5" />
    <path d="M199.45 159.95L199.43 159.92" stroke="black" stroke-width="0.5" />
    <path d="M199.43 159.92L199.41 159.88" stroke="black" stroke-width="0.5" />
    <path d="M199.41 159.88L199.4 159.85" stroke="black" stroke-width="0.5" />
    <path d="M199.4 159.85L199.38 159.82" stroke="black" stroke-width="0.5" />
    <path d="M199.38 159.82L199.36 159.78" stroke="black" stroke-width="0.5" />
    <path d="M199.36 159.78L199.35 159.75" stroke="black" stroke-width="0.5" />
    <path d="M199.35 159.75L199.33 159.71" stroke="black" stroke-width="0.5" />
    <path d="M199.33 159.71L199.32 159.68" stroke="black" stroke-width="0.5" />
    <path d="M199.32 159.68L199.31 159.64" stroke="black" stroke-width="0.5" />
    <path d="M199.31 159.64L199.29 159.6" stroke="black" stroke-width="0.5" />
    <path d="M199.29 159.6L199.27 159.53" stroke="black" stroke-width="0.5" />
    <path d="M199.27 159.53L199.25 159.46" stroke="black" stroke-width="0.5" />
    <path d="M199.25 159.46L199.23 159.38" stroke="black" stroke-width="0.5" />
    <path d="M199.23 159.38L199.22 159.31" stroke="black" stroke-width="0.5" />
    <path d="M199.22 159.31L199.21 159.23" stroke="black" stroke-width="0.5" />
    <path d="M199.21 159.23L199.2 159.15" stroke="black" stroke-width="0.5" />
    <path d="M199.2 159.15L199.19 159.08" stroke="black" stroke-width="0.5" />
    <path d="M199.19 159.08V159.04" stroke="black" stroke-width="0.5" />
    <path d="M199.19 159.04L199.18 159" stroke="black" stroke-width="0.5" />
    <path d="M199.18 159V158.96" stroke="black" stroke-width="0.5" />
    <path d="M199.18 158.96V158.92" stroke="black" stroke-width="0.5" />
    <path d="M199.18 158.92V158.89" stroke="black" stroke-width="0.5" />
    <path d="M199.18 158.89V158.85" stroke="black" stroke-width="0.5" />
    <path d="M199.18 158.85L199.19 158.81" stroke="black" stroke-width="0.5" />
    <path d="M199.19 158.81V158.77" stroke="black" stroke-width="0.5" />
    <path d="M199.19 158.77L199.2 158.7" stroke="black" stroke-width="0.5" />
    <path d="M199.2 158.7L199.21 158.62" stroke="black" stroke-width="0.5" />
    <path d="M199.21 158.62L199.22 158.54" stroke="black" stroke-width="0.5" />
    <path d="M199.22 158.54L199.23 158.47" stroke="black" stroke-width="0.5" />
    <path d="M199.23 158.47L199.25 158.39" stroke="black" stroke-width="0.5" />
    <path d="M199.25 158.39L199.27 158.32" stroke="black" stroke-width="0.5" />
    <path d="M199.27 158.32L199.29 158.24" stroke="black" stroke-width="0.5" />
    <path d="M199.29 158.24L199.31 158.21" stroke="black" stroke-width="0.5" />
    <path d="M199.31 158.21L199.32 158.17" stroke="black" stroke-width="0.5" />
    <path d="M199.32 158.17L199.33 158.14" stroke="black" stroke-width="0.5" />
    <path d="M199.33 158.14L199.35 158.1" stroke="black" stroke-width="0.5" />
    <path d="M199.35 158.1L199.36 158.07" stroke="black" stroke-width="0.5" />
    <path d="M199.36 158.07L199.38 158.03" stroke="black" stroke-width="0.5" />
    <path d="M199.38 158.03L199.4 158" stroke="black" stroke-width="0.5" />
    <path d="M199.4 158L199.41 157.96" stroke="black" stroke-width="0.5" />
    <path d="M199.41 157.96L199.43 157.93" stroke="black" stroke-width="0.5" />
    <path d="M199.43 157.93L199.45 157.9" stroke="black" stroke-width="0.5" />
    <path d="M199.45 157.9L199.47 157.87" stroke="black" stroke-width="0.5" />
    <path d="M199.47 157.87L199.49 157.84" stroke="black" stroke-width="0.5" />
    <path d="M199.49 157.84L199.51 157.8" stroke="black" stroke-width="0.5" />
    <path d="M199.51 157.8L199.53 157.77" stroke="black" stroke-width="0.5" />
    <path d="M199.53 157.77L199.55 157.74" stroke="black" stroke-width="0.5" />
    <path d="M199.55 157.74L199.57 157.71" stroke="black" stroke-width="0.5" />
    <path d="M199.57 157.71L199.59 157.69" stroke="black" stroke-width="0.5" />
    <path d="M199.59 157.69L199.61 157.66" stroke="black" stroke-width="0.5" />
    <path d="M199.61 157.66L199.64 157.63" stroke="black" stroke-width="0.5" />
    <path d="M199.64 157.63L199.66 157.6" stroke="black" stroke-width="0.5" />
    <path d="M199.66 157.6L199.69 157.58" stroke="black" stroke-width="0.5" />
    <path d="M199.69 157.58L199.71 157.55" stroke="black" stroke-width="0.5" />
    <path d="M199.71 157.55L199.74 157.53" stroke="black" stroke-width="0.5" />
    <path d="M199.74 157.53L199.76 157.5" stroke="black" stroke-width="0.5" />
    <path d="M199.76 157.5L199.79 157.48" stroke="black" stroke-width="0.5" />
    <path d="M199.79 157.48L199.82 157.46" stroke="black" stroke-width="0.5" />
    <path d="M199.82 157.46L199.85 157.44" stroke="black" stroke-width="0.5" />
    <path d="M199.85 157.44L199.87 157.42" stroke="black" stroke-width="0.5" />
    <path d="M199.87 157.42L199.9 157.4" stroke="black" stroke-width="0.5" />
    <path d="M199.9 157.4L199.93 157.38" stroke="black" stroke-width="0.5" />
    <path d="M199.93 157.38L199.96 157.36" stroke="black" stroke-width="0.5" />
    <path d="M199.96 157.36L199.99 157.35" stroke="black" stroke-width="0.5" />
    <path d="M199.99 157.35L200.02 157.33" stroke="black" stroke-width="0.5" />
    <path d="M200.02 157.33L200.06 157.32" stroke="black" stroke-width="0.5" />
    <path d="M200.06 157.32L200.09 157.3" stroke="black" stroke-width="0.5" />
    <path d="M200.09 157.3L200.12 157.29" stroke="black" stroke-width="0.5" />
    <path d="M200.12 157.29L200.15 157.28" stroke="black" stroke-width="0.5" />
    <path d="M200.15 157.28L200.19 157.27" stroke="black" stroke-width="0.5" />
    <path d="M200.19 157.27L200.22 157.26" stroke="black" stroke-width="0.5" />
    <path d="M200.22 157.26L200.26 157.25" stroke="black" stroke-width="0.5" />
    <path d="M200.26 157.25L200.29 157.24" stroke="black" stroke-width="0.5" />
    <path d="M200.29 157.24H200.33" stroke="black" stroke-width="0.5" />
    <path d="M200.33 157.24L200.36 157.23" stroke="black" stroke-width="0.5" />
    <path d="M200.36 157.23H200.4" stroke="black" stroke-width="0.5" />
    <path d="M200.4 157.23H200.43" stroke="black" stroke-width="0.5" />
    <path d="M200.43 157.23H200.47" stroke="black" stroke-width="0.5" />
    <path d="M200.47 157.23H200.52" stroke="black" stroke-width="0.5" />
    <path d="M200.52 157.23H200.56" stroke="black" stroke-width="0.5" />
    <path d="M200.56 157.23L200.6 157.24" stroke="black" stroke-width="0.5" />
    <path d="M200.6 157.24L200.64 157.25" stroke="black" stroke-width="0.5" />
    <path d="M200.64 157.25H200.68" stroke="black" stroke-width="0.5" />
    <path d="M200.68 157.25L200.72 157.27" stroke="black" stroke-width="0.5" />
    <path d="M200.72 157.27L200.76 157.28" stroke="black" stroke-width="0.5" />
    <path d="M200.76 157.28L200.79 157.29" stroke="black" stroke-width="0.5" />
    <path d="M200.79 157.29L200.83 157.31" stroke="black" stroke-width="0.5" />
    <path d="M200.83 157.31L200.87 157.33" stroke="black" stroke-width="0.5" />
    <path d="M200.87 157.33L200.9 157.35" stroke="black" stroke-width="0.5" />
    <path d="M200.9 157.35L200.93 157.37" stroke="black" stroke-width="0.5" />
    <path d="M200.93 157.37L200.97 157.4" stroke="black" stroke-width="0.5" />
    <path d="M200.97 157.4L201 157.43" stroke="black" stroke-width="0.5" />
    <path d="M201 157.43L201.03 157.46" stroke="black" stroke-width="0.5" />
    <path d="M201.03 157.46L201.06 157.49" stroke="black" stroke-width="0.5" />
    <path d="M201.06 157.49L201.09 157.52" stroke="black" stroke-width="0.5" />
    <path d="M201.09 157.52L201.12 157.56" stroke="black" stroke-width="0.5" />
    <path d="M201.12 157.56L201.14 157.6" stroke="black" stroke-width="0.5" />
    <path d="M201.14 157.6L201.17 157.64" stroke="black" stroke-width="0.5" />
    <path d="M201.17 157.64L201.2 157.68" stroke="black" stroke-width="0.5" />
    <path d="M201.2 157.68L201.22 157.72" stroke="black" stroke-width="0.5" />
    <path d="M201.22 157.72L201.24 157.77" stroke="black" stroke-width="0.5" />
    <path d="M201.24 157.77L201.27 157.82" stroke="black" stroke-width="0.5" />
    <path d="M201.27 157.82L201.29 157.87" stroke="black" stroke-width="0.5" />
    <path d="M201.29 157.87L201.31 157.92" stroke="black" stroke-width="0.5" />
    <path d="M201.31 157.92L201.33 157.98" stroke="black" stroke-width="0.5" />
    <path d="M201.33 157.98L201.35 158.03" stroke="black" stroke-width="0.5" />
    <path d="M201.35 158.03L201.37 158.09" stroke="black" stroke-width="0.5" />
    <path d="M201.37 158.09L201.39 158.15" stroke="black" stroke-width="0.5" />
    <path d="M201.39 158.15L201.4 158.22" stroke="black" stroke-width="0.5" />
    <path d="M201.4 158.22L201.42 158.28" stroke="black" stroke-width="0.5" />
    <path d="M200.5 156.87L199.77 157.05" stroke="black" stroke-width="0.5" />
    <path d="M199.77 157.05L199.17 157.55" stroke="black" stroke-width="0.5" />
    <path d="M199.17 157.55L198.8 158.29" stroke="black" stroke-width="0.5" />
    <path d="M198.8 158.29L198.72 159.14" stroke="black" stroke-width="0.5" />
    <path d="M198.72 159.14L198.95 159.95" stroke="black" stroke-width="0.5" />
    <path d="M198.95 159.95L199.45 160.59" stroke="black" stroke-width="0.5" />
    <path d="M199.45 160.59L200.13 160.94" stroke="black" stroke-width="0.5" />
    <path d="M200.13 160.94H200.88" stroke="black" stroke-width="0.5" />
    <path d="M200.88 160.94L201.56 160.59" stroke="black" stroke-width="0.5" />
    <path d="M201.56 160.59L202.06 159.95" stroke="black" stroke-width="0.5" />
    <path d="M202.06 159.95L202.29 159.14" stroke="black" stroke-width="0.5" />
    <path d="M202.29 159.14L202.21 158.29" stroke="black" stroke-width="0.5" />
    <path d="M202.21 158.29L201.84 157.55" stroke="black" stroke-width="0.5" />
    <path d="M201.84 157.55L201.23 157.05" stroke="black" stroke-width="0.5" />
    <path d="M201.23 157.05L200.5 156.87" stroke="black" stroke-width="0.5" />
    <path d="M201.43 159.53L201.42 158.28" stroke="black" stroke-width="0.5" />
    <path d="M165.9 120.65H175.54" stroke="black" stroke-width="0.5" />
    <path d="M274.44 81.68H276.37" stroke="black" stroke-width="0.5" />
    <path d="M274.44 78.49H276.37" stroke="black" stroke-width="0.5" />
    <path d="M275.31 81.68V78.49" stroke="black" stroke-width="0.5" />
    <path d="M275.5 81.68V78.49" stroke="black" stroke-width="0.5" />
    <path d="M196.25 32.68H226.3" stroke="black" stroke-width="0.5" />
    <path d="M226.3 31.09H227.63" stroke="black" stroke-width="0.5" />
    <path d="M226.3 21.51H227.63" stroke="black" stroke-width="0.5" />
    <path d="M196.25 34.01H226.3" stroke="black" stroke-width="0.5" />
    <path d="M216.73 31.09L216.91 29.22" stroke="black" stroke-width="0.5" />
    <path d="M216.91 29.22L217.45 27.42" stroke="black" stroke-width="0.5" />
    <path d="M217.45 27.42L218.34 25.77" stroke="black" stroke-width="0.5" />
    <path d="M218.34 25.77L219.53 24.32" stroke="black" stroke-width="0.5" />
    <path d="M219.53 24.32L220.98 23.13" stroke="black" stroke-width="0.5" />
    <path d="M220.98 23.13L222.64 22.24" stroke="black" stroke-width="0.5" />
    <path d="M222.64 22.24L224.43 21.7" stroke="black" stroke-width="0.5" />
    <path d="M224.43 21.7L226.3 21.51" stroke="black" stroke-width="0.5" />
    <path d="M226.3 30.62V31.09" stroke="black" stroke-width="0.5" />
    <path d="M226.3 31.09H216.73" stroke="black" stroke-width="0.5" />
    <path d="M216.73 31.09V30.62" stroke="black" stroke-width="0.5" />
    <path d="M216.73 30.62H226.3" stroke="black" stroke-width="0.5" />
    <path d="M226.3 31.09V31.62" stroke="black" stroke-width="0.5" />
    <path d="M226.3 31.62H227.63" stroke="black" stroke-width="0.5" />
    <path d="M227.63 31.62V31.09" stroke="black" stroke-width="0.5" />
    <path d="M227.63 31.09H226.3" stroke="black" stroke-width="0.5" />
    <path d="M226.3 20.98V21.51" stroke="black" stroke-width="0.5" />
    <path d="M226.3 21.51H227.63" stroke="black" stroke-width="0.5" />
    <path d="M227.63 21.51V20.98" stroke="black" stroke-width="0.5" />
    <path d="M227.63 20.98H226.3" stroke="black" stroke-width="0.5" />
    <path d="M216.73 64.67L216.91 62.8" stroke="black" stroke-width="0.5" />
    <path d="M216.91 62.8L217.45 61" stroke="black" stroke-width="0.5" />
    <path d="M217.45 61L218.34 59.35" stroke="black" stroke-width="0.5" />
    <path d="M218.34 59.35L219.53 57.9" stroke="black" stroke-width="0.5" />
    <path d="M219.53 57.9L220.98 56.71" stroke="black" stroke-width="0.5" />
    <path d="M220.98 56.71L222.64 55.82" stroke="black" stroke-width="0.5" />
    <path d="M222.64 55.82L224.43 55.28" stroke="black" stroke-width="0.5" />
    <path d="M224.43 55.28L226.3 55.09" stroke="black" stroke-width="0.5" />
    <path d="M226.3 64.2V64.67" stroke="black" stroke-width="0.5" />
    <path d="M226.3 64.67H216.73" stroke="black" stroke-width="0.5" />
    <path d="M216.73 64.67V64.2" stroke="black" stroke-width="0.5" />
    <path d="M216.73 64.2H226.3" stroke="black" stroke-width="0.5" />
    <path d="M226.3 64.67V65.2" stroke="black" stroke-width="0.5" />
    <path d="M226.3 65.2H227.63" stroke="black" stroke-width="0.5" />
    <path d="M227.63 65.2V64.67" stroke="black" stroke-width="0.5" />
    <path d="M227.63 64.67H226.3" stroke="black" stroke-width="0.5" />
    <path d="M226.3 54.56V55.09" stroke="black" stroke-width="0.5" />
    <path d="M226.3 55.09H227.63" stroke="black" stroke-width="0.5" />
    <path d="M227.63 55.09V54.56" stroke="black" stroke-width="0.5" />
    <path d="M227.63 54.56H226.3" stroke="black" stroke-width="0.5" />
    <path d="M276.37 95.12H282.22" stroke="black" stroke-width="0.5" />
    <path d="M261.67 107.32H262.37" stroke="black" stroke-width="0.5" />
    <path d="M281.52 107.32H282.22" stroke="black" stroke-width="0.5" />
    <path d="M261.67 107.59H262.37" stroke="black" stroke-width="0.5" />
    <path d="M281.52 107.59H282.22" stroke="black" stroke-width="0.5" />
    <path d="M283.55 95.12H309.88" stroke="black" stroke-width="0.5" />
    <path d="M276.37 93.79H311.21" stroke="black" stroke-width="0.5" />
    <path d="M286 159.11L285.96 159.23" stroke="black" stroke-width="0.5" />
    <path d="M285.96 159.23L285.84 159.28" stroke="black" stroke-width="0.5" />
    <path d="M285.84 159.28L285.73 159.23" stroke="black" stroke-width="0.5" />
    <path d="M285.73 159.23L285.68 159.11" stroke="black" stroke-width="0.5" />
    <path d="M285.68 159.11L285.73 159" stroke="black" stroke-width="0.5" />
    <path d="M285.73 159L285.84 158.95" stroke="black" stroke-width="0.5" />
    <path d="M285.84 158.95L285.96 159" stroke="black" stroke-width="0.5" />
    <path d="M285.96 159L286 159.11" stroke="black" stroke-width="0.5" />
    <path d="M286.2 159.69L286.19 159.76" stroke="black" stroke-width="0.5" />
    <path d="M286.19 159.76L286.17 159.83" stroke="black" stroke-width="0.5" />
    <path d="M286.17 159.83L286.15 159.89" stroke="black" stroke-width="0.5" />
    <path d="M286.15 159.89L286.13 159.95" stroke="black" stroke-width="0.5" />
    <path d="M286.13 159.95L286.12 160.01" stroke="black" stroke-width="0.5" />
    <path d="M286.12 160.01L286.1 160.07" stroke="black" stroke-width="0.5" />
    <path d="M286.1 160.07L286.07 160.12" stroke="black" stroke-width="0.5" />
    <path d="M286.07 160.12L286.05 160.18" stroke="black" stroke-width="0.5" />
    <path d="M286.05 160.18L286.03 160.23" stroke="black" stroke-width="0.5" />
    <path d="M286.03 160.23L286 160.27" stroke="black" stroke-width="0.5" />
    <path d="M286 160.27L285.98 160.32" stroke="black" stroke-width="0.5" />
    <path d="M285.98 160.32L285.95 160.36" stroke="black" stroke-width="0.5" />
    <path d="M285.95 160.36L285.93 160.41" stroke="black" stroke-width="0.5" />
    <path d="M285.93 160.41L285.9 160.44" stroke="black" stroke-width="0.5" />
    <path d="M285.9 160.44L285.87 160.48" stroke="black" stroke-width="0.5" />
    <path d="M285.87 160.48L285.84 160.52" stroke="black" stroke-width="0.5" />
    <path d="M285.84 160.52L285.81 160.55" stroke="black" stroke-width="0.5" />
    <path d="M285.81 160.55L285.78 160.58" stroke="black" stroke-width="0.5" />
    <path d="M285.78 160.58L285.75 160.61" stroke="black" stroke-width="0.5" />
    <path d="M285.75 160.61L285.72 160.64" stroke="black" stroke-width="0.5" />
    <path d="M285.72 160.64L285.68 160.66" stroke="black" stroke-width="0.5" />
    <path d="M285.68 160.66L285.65 160.68" stroke="black" stroke-width="0.5" />
    <path d="M285.65 160.68L285.61 160.7" stroke="black" stroke-width="0.5" />
    <path d="M285.61 160.7L285.57 160.72" stroke="black" stroke-width="0.5" />
    <path d="M285.57 160.72L285.54 160.73" stroke="black" stroke-width="0.5" />
    <path d="M285.54 160.73L285.5 160.75" stroke="black" stroke-width="0.5" />
    <path d="M285.5 160.75L285.46 160.76" stroke="black" stroke-width="0.5" />
    <path d="M285.46 160.76L285.42 160.77" stroke="black" stroke-width="0.5" />
    <path d="M285.42 160.77L285.38 160.78" stroke="black" stroke-width="0.5" />
    <path d="M285.38 160.78H285.33" stroke="black" stroke-width="0.5" />
    <path d="M285.33 160.78H285.29" stroke="black" stroke-width="0.5" />
    <path d="M285.29 160.78L285.25 160.79" stroke="black" stroke-width="0.5" />
    <path d="M285.25 160.79L285.21 160.78" stroke="black" stroke-width="0.5" />
    <path d="M285.21 160.78H285.17" stroke="black" stroke-width="0.5" />
    <path d="M285.17 160.78H285.14" stroke="black" stroke-width="0.5" />
    <path d="M285.14 160.78H285.1" stroke="black" stroke-width="0.5" />
    <path d="M285.1 160.78L285.07 160.77" stroke="black" stroke-width="0.5" />
    <path d="M285.07 160.77L285.03 160.76" stroke="black" stroke-width="0.5" />
    <path d="M285.03 160.76L285 160.75" stroke="black" stroke-width="0.5" />
    <path d="M285 160.75H284.96" stroke="black" stroke-width="0.5" />
    <path d="M284.96 160.75L284.93 160.74" stroke="black" stroke-width="0.5" />
    <path d="M284.93 160.74L284.9 160.72" stroke="black" stroke-width="0.5" />
    <path d="M284.9 160.72L284.86 160.71" stroke="black" stroke-width="0.5" />
    <path d="M284.86 160.71L284.83 160.7" stroke="black" stroke-width="0.5" />
    <path d="M284.83 160.7L284.8 160.68" stroke="black" stroke-width="0.5" />
    <path d="M284.8 160.68L284.77 160.67" stroke="black" stroke-width="0.5" />
    <path d="M284.77 160.67L284.74 160.65" stroke="black" stroke-width="0.5" />
    <path d="M284.74 160.65L284.71 160.63" stroke="black" stroke-width="0.5" />
    <path d="M284.71 160.63L284.68 160.62" stroke="black" stroke-width="0.5" />
    <path d="M284.68 160.62L284.65 160.6" stroke="black" stroke-width="0.5" />
    <path d="M284.65 160.6L284.62 160.58" stroke="black" stroke-width="0.5" />
    <path d="M284.62 160.58L284.59 160.55" stroke="black" stroke-width="0.5" />
    <path d="M284.59 160.55L284.57 160.53" stroke="black" stroke-width="0.5" />
    <path d="M284.57 160.53L284.54 160.51" stroke="black" stroke-width="0.5" />
    <path d="M284.54 160.51L284.51 160.49" stroke="black" stroke-width="0.5" />
    <path d="M284.51 160.49L284.49 160.46" stroke="black" stroke-width="0.5" />
    <path d="M284.49 160.46L284.46 160.44" stroke="black" stroke-width="0.5" />
    <path d="M284.46 160.44L284.44 160.41" stroke="black" stroke-width="0.5" />
    <path d="M284.44 160.41L284.41 160.38" stroke="black" stroke-width="0.5" />
    <path d="M284.41 160.38L284.39 160.36" stroke="black" stroke-width="0.5" />
    <path d="M284.39 160.36L284.37 160.33" stroke="black" stroke-width="0.5" />
    <path d="M284.37 160.33L284.34 160.3" stroke="black" stroke-width="0.5" />
    <path d="M284.34 160.3L284.32 160.27" stroke="black" stroke-width="0.5" />
    <path d="M284.32 160.27L284.3 160.24" stroke="black" stroke-width="0.5" />
    <path d="M284.3 160.24L284.28 160.21" stroke="black" stroke-width="0.5" />
    <path d="M284.28 160.21L284.26 160.18" stroke="black" stroke-width="0.5" />
    <path d="M284.26 160.18L284.24 160.15" stroke="black" stroke-width="0.5" />
    <path d="M284.24 160.15L284.22 160.12" stroke="black" stroke-width="0.5" />
    <path d="M284.22 160.12L284.21 160.08" stroke="black" stroke-width="0.5" />
    <path d="M284.21 160.08L284.19 160.05" stroke="black" stroke-width="0.5" />
    <path d="M284.19 160.05L284.17 160.02" stroke="black" stroke-width="0.5" />
    <path d="M284.17 160.02L284.15 159.98" stroke="black" stroke-width="0.5" />
    <path d="M284.15 159.98L284.14 159.95" stroke="black" stroke-width="0.5" />
    <path d="M284.14 159.95L284.12 159.91" stroke="black" stroke-width="0.5" />
    <path d="M284.12 159.91L284.11 159.88" stroke="black" stroke-width="0.5" />
    <path d="M284.11 159.88L284.1 159.84" stroke="black" stroke-width="0.5" />
    <path d="M284.1 159.84L284.08 159.81" stroke="black" stroke-width="0.5" />
    <path d="M284.08 159.81L284.07 159.77" stroke="black" stroke-width="0.5" />
    <path d="M284.07 159.77L284.05 159.7" stroke="black" stroke-width="0.5" />
    <path d="M284.05 159.7L284.03 159.62" stroke="black" stroke-width="0.5" />
    <path d="M284.03 159.62L284.01 159.55" stroke="black" stroke-width="0.5" />
    <path d="M284.01 159.55L283.99 159.47" stroke="black" stroke-width="0.5" />
    <path d="M283.99 159.47L283.98 159.4" stroke="black" stroke-width="0.5" />
    <path d="M283.98 159.4L283.97 159.32" stroke="black" stroke-width="0.5" />
    <path d="M283.97 159.32L283.96 159.24" stroke="black" stroke-width="0.5" />
    <path d="M283.96 159.24V159.2" stroke="black" stroke-width="0.5" />
    <path d="M283.96 159.2V159.17" stroke="black" stroke-width="0.5" />
    <path d="M283.96 159.17V159.13" stroke="black" stroke-width="0.5" />
    <path d="M283.96 159.13V159.09" stroke="black" stroke-width="0.5" />
    <path d="M283.96 159.09V159.05" stroke="black" stroke-width="0.5" />
    <path d="M283.96 159.05V159.01" stroke="black" stroke-width="0.5" />
    <path d="M283.96 159.01V158.98" stroke="black" stroke-width="0.5" />
    <path d="M283.96 158.98V158.94" stroke="black" stroke-width="0.5" />
    <path d="M283.96 158.94L283.97 158.86" stroke="black" stroke-width="0.5" />
    <path d="M283.97 158.86L283.98 158.79" stroke="black" stroke-width="0.5" />
    <path d="M283.98 158.79L283.99 158.71" stroke="black" stroke-width="0.5" />
    <path d="M283.99 158.71L284.01 158.63" stroke="black" stroke-width="0.5" />
    <path d="M284.01 158.63L284.03 158.56" stroke="black" stroke-width="0.5" />
    <path d="M284.03 158.56L284.05 158.48" stroke="black" stroke-width="0.5" />
    <path d="M284.05 158.48L284.07 158.41" stroke="black" stroke-width="0.5" />
    <path d="M284.07 158.41L284.08 158.37" stroke="black" stroke-width="0.5" />
    <path d="M284.08 158.37L284.1 158.34" stroke="black" stroke-width="0.5" />
    <path d="M284.1 158.34L284.11 158.3" stroke="black" stroke-width="0.5" />
    <path d="M284.11 158.3L284.12 158.27" stroke="black" stroke-width="0.5" />
    <path d="M284.12 158.27L284.14 158.23" stroke="black" stroke-width="0.5" />
    <path d="M284.14 158.23L284.15 158.2" stroke="black" stroke-width="0.5" />
    <path d="M284.15 158.2L284.17 158.16" stroke="black" stroke-width="0.5" />
    <path d="M284.17 158.16L284.19 158.13" stroke="black" stroke-width="0.5" />
    <path d="M284.19 158.13L284.21 158.1" stroke="black" stroke-width="0.5" />
    <path d="M284.21 158.1L284.22 158.07" stroke="black" stroke-width="0.5" />
    <path d="M284.22 158.07L284.24 158.03" stroke="black" stroke-width="0.5" />
    <path d="M284.24 158.03L284.26 158" stroke="black" stroke-width="0.5" />
    <path d="M284.26 158L284.28 157.97" stroke="black" stroke-width="0.5" />
    <path d="M284.28 157.97L284.3 157.94" stroke="black" stroke-width="0.5" />
    <path d="M284.3 157.94L284.32 157.91" stroke="black" stroke-width="0.5" />
    <path d="M284.32 157.91L284.34 157.88" stroke="black" stroke-width="0.5" />
    <path d="M284.34 157.88L284.37 157.85" stroke="black" stroke-width="0.5" />
    <path d="M284.37 157.85L284.39 157.82" stroke="black" stroke-width="0.5" />
    <path d="M284.39 157.82L284.41 157.8" stroke="black" stroke-width="0.5" />
    <path d="M284.41 157.8L284.44 157.77" stroke="black" stroke-width="0.5" />
    <path d="M284.44 157.77L284.46 157.74" stroke="black" stroke-width="0.5" />
    <path d="M284.46 157.74L284.49 157.72" stroke="black" stroke-width="0.5" />
    <path d="M284.49 157.72L284.51 157.69" stroke="black" stroke-width="0.5" />
    <path d="M284.51 157.69L284.54 157.67" stroke="black" stroke-width="0.5" />
    <path d="M284.54 157.67L284.57 157.65" stroke="black" stroke-width="0.5" />
    <path d="M284.57 157.65L284.59 157.63" stroke="black" stroke-width="0.5" />
    <path d="M284.59 157.63L284.62 157.6" stroke="black" stroke-width="0.5" />
    <path d="M284.62 157.6L284.65 157.58" stroke="black" stroke-width="0.5" />
    <path d="M284.65 157.58L284.68 157.56" stroke="black" stroke-width="0.5" />
    <path d="M284.68 157.56L284.71 157.55" stroke="black" stroke-width="0.5" />
    <path d="M284.71 157.55L284.74 157.53" stroke="black" stroke-width="0.5" />
    <path d="M284.74 157.53L284.77 157.51" stroke="black" stroke-width="0.5" />
    <path d="M284.77 157.51L284.8 157.5" stroke="black" stroke-width="0.5" />
    <path d="M284.8 157.5L284.83 157.48" stroke="black" stroke-width="0.5" />
    <path d="M284.83 157.48L284.86 157.47" stroke="black" stroke-width="0.5" />
    <path d="M284.86 157.47L284.9 157.46" stroke="black" stroke-width="0.5" />
    <path d="M284.9 157.46L284.93 157.45" stroke="black" stroke-width="0.5" />
    <path d="M284.93 157.45L284.96 157.44" stroke="black" stroke-width="0.5" />
    <path d="M284.96 157.44L285 157.43" stroke="black" stroke-width="0.5" />
    <path d="M285 157.43L285.03 157.42" stroke="black" stroke-width="0.5" />
    <path d="M285.03 157.42L285.07 157.41" stroke="black" stroke-width="0.5" />
    <path d="M285.07 157.41H285.1" stroke="black" stroke-width="0.5" />
    <path d="M285.1 157.41L285.14 157.4" stroke="black" stroke-width="0.5" />
    <path d="M285.14 157.4H285.17" stroke="black" stroke-width="0.5" />
    <path d="M285.17 157.4H285.21" stroke="black" stroke-width="0.5" />
    <path d="M285.21 157.4H285.25" stroke="black" stroke-width="0.5" />
    <path d="M285.25 157.4H285.29" stroke="black" stroke-width="0.5" />
    <path d="M285.29 157.4H285.33" stroke="black" stroke-width="0.5" />
    <path d="M285.33 157.4H285.38" stroke="black" stroke-width="0.5" />
    <path d="M285.38 157.4L285.42 157.41" stroke="black" stroke-width="0.5" />
    <path d="M285.42 157.41L285.46 157.42" stroke="black" stroke-width="0.5" />
    <path d="M285.46 157.42L285.49 157.43" stroke="black" stroke-width="0.5" />
    <path d="M285.49 157.43L285.53 157.44" stroke="black" stroke-width="0.5" />
    <path d="M285.53 157.44L285.57 157.46" stroke="black" stroke-width="0.5" />
    <path d="M285.57 157.46L285.61 157.48" stroke="black" stroke-width="0.5" />
    <path d="M285.61 157.48L285.64 157.5" stroke="black" stroke-width="0.5" />
    <path d="M285.64 157.5L285.68 157.52" stroke="black" stroke-width="0.5" />
    <path d="M285.68 157.52L285.71 157.54" stroke="black" stroke-width="0.5" />
    <path d="M285.71 157.54L285.74 157.57" stroke="black" stroke-width="0.5" />
    <path d="M285.74 157.57L285.77 157.59" stroke="black" stroke-width="0.5" />
    <path d="M285.77 157.59L285.8 157.62" stroke="black" stroke-width="0.5" />
    <path d="M285.8 157.62L285.83 157.65" stroke="black" stroke-width="0.5" />
    <path d="M285.83 157.65L285.86 157.69" stroke="black" stroke-width="0.5" />
    <path d="M285.86 157.69L285.89 157.72" stroke="black" stroke-width="0.5" />
    <path d="M285.89 157.72L285.92 157.76" stroke="black" stroke-width="0.5" />
    <path d="M285.92 157.76L285.95 157.8" stroke="black" stroke-width="0.5" />
    <path d="M285.95 157.8L285.97 157.84" stroke="black" stroke-width="0.5" />
    <path d="M285.97 157.84L286 157.89" stroke="black" stroke-width="0.5" />
    <path d="M286 157.89L286.02 157.94" stroke="black" stroke-width="0.5" />
    <path d="M286.02 157.94L286.04 157.98" stroke="black" stroke-width="0.5" />
    <path d="M286.04 157.98L286.06 158.03" stroke="black" stroke-width="0.5" />
    <path d="M286.06 158.03L286.09 158.09" stroke="black" stroke-width="0.5" />
    <path d="M286.09 158.09L286.11 158.14" stroke="black" stroke-width="0.5" />
    <path d="M286.11 158.14L286.13 158.2" stroke="black" stroke-width="0.5" />
    <path d="M286.13 158.2L286.14 158.26" stroke="black" stroke-width="0.5" />
    <path d="M286.14 158.26L286.16 158.32" stroke="black" stroke-width="0.5" />
    <path d="M286.16 158.32L286.18 158.38" stroke="black" stroke-width="0.5" />
    <path d="M286.18 158.38L286.19 158.45" stroke="black" stroke-width="0.5" />
    <path d="M285.28 157.04L284.55 157.21" stroke="black" stroke-width="0.5" />
    <path d="M284.55 157.21L283.94 157.72" stroke="black" stroke-width="0.5" />
    <path d="M283.94 157.72L283.57 158.46" stroke="black" stroke-width="0.5" />
    <path d="M283.57 158.46L283.49 159.31" stroke="black" stroke-width="0.5" />
    <path d="M283.49 159.31L283.72 160.12" stroke="black" stroke-width="0.5" />
    <path d="M283.72 160.12L284.22 160.75" stroke="black" stroke-width="0.5" />
    <path d="M284.22 160.75L284.91 161.1" stroke="black" stroke-width="0.5" />
    <path d="M284.91 161.1H285.65" stroke="black" stroke-width="0.5" />
    <path d="M285.65 161.1L286.33 160.75" stroke="black" stroke-width="0.5" />
    <path d="M286.33 160.75L286.83 160.12" stroke="black" stroke-width="0.5" />
    <path d="M286.83 160.12L287.06 159.31" stroke="black" stroke-width="0.5" />
    <path d="M287.06 159.31L286.99 158.46" stroke="black" stroke-width="0.5" />
    <path d="M286.99 158.46L286.61 157.72" stroke="black" stroke-width="0.5" />
    <path d="M286.61 157.72L286.01 157.21" stroke="black" stroke-width="0.5" />
    <path d="M286.01 157.21L285.28 157.04" stroke="black" stroke-width="0.5" />
    <path d="M286.2 159.69L286.19 158.45" stroke="black" stroke-width="0.5" />
    <path d="M294.32 159.11L294.27 159.23" stroke="black" stroke-width="0.5" />
    <path d="M294.27 159.23L294.16 159.28" stroke="black" stroke-width="0.5" />
    <path d="M294.16 159.28L294.04 159.23" stroke="black" stroke-width="0.5" />
    <path d="M294.04 159.23L293.99 159.11" stroke="black" stroke-width="0.5" />
    <path d="M293.99 159.11L294.04 159" stroke="black" stroke-width="0.5" />
    <path d="M294.04 159L294.16 158.95" stroke="black" stroke-width="0.5" />
    <path d="M294.16 158.95L294.27 159" stroke="black" stroke-width="0.5" />
    <path d="M294.27 159L294.32 159.11" stroke="black" stroke-width="0.5" />
    <path d="M293.79 159.69L293.81 159.76" stroke="black" stroke-width="0.5" />
    <path d="M293.81 159.76L293.83 159.83" stroke="black" stroke-width="0.5" />
    <path d="M293.83 159.83L293.85 159.89" stroke="black" stroke-width="0.5" />
    <path d="M293.85 159.89L293.86 159.95" stroke="black" stroke-width="0.5" />
    <path d="M293.86 159.95L293.88 160.01" stroke="black" stroke-width="0.5" />
    <path d="M293.88 160.01L293.9 160.07" stroke="black" stroke-width="0.5" />
    <path d="M293.9 160.07L293.93 160.12" stroke="black" stroke-width="0.5" />
    <path d="M293.93 160.12L293.95 160.18" stroke="black" stroke-width="0.5" />
    <path d="M293.95 160.18L293.97 160.23" stroke="black" stroke-width="0.5" />
    <path d="M293.97 160.23L293.99 160.27" stroke="black" stroke-width="0.5" />
    <path d="M293.99 160.27L294.02 160.32" stroke="black" stroke-width="0.5" />
    <path d="M294.02 160.32L294.04 160.36" stroke="black" stroke-width="0.5" />
    <path d="M294.04 160.36L294.07 160.41" stroke="black" stroke-width="0.5" />
    <path d="M294.07 160.41L294.1 160.44" stroke="black" stroke-width="0.5" />
    <path d="M294.1 160.44L294.13 160.48" stroke="black" stroke-width="0.5" />
    <path d="M294.13 160.48L294.16 160.52" stroke="black" stroke-width="0.5" />
    <path d="M294.16 160.52L294.19 160.55" stroke="black" stroke-width="0.5" />
    <path d="M294.19 160.55L294.22 160.58" stroke="black" stroke-width="0.5" />
    <path d="M294.22 160.58L294.25 160.61" stroke="black" stroke-width="0.5" />
    <path d="M294.25 160.61L294.28 160.64" stroke="black" stroke-width="0.5" />
    <path d="M294.28 160.64L294.32 160.66" stroke="black" stroke-width="0.5" />
    <path d="M294.32 160.66L294.35 160.68" stroke="black" stroke-width="0.5" />
    <path d="M294.35 160.68L294.39 160.7" stroke="black" stroke-width="0.5" />
    <path d="M294.39 160.7L294.42 160.72" stroke="black" stroke-width="0.5" />
    <path d="M294.42 160.72L294.46 160.73" stroke="black" stroke-width="0.5" />
    <path d="M294.46 160.73L294.5 160.75" stroke="black" stroke-width="0.5" />
    <path d="M294.5 160.75L294.54 160.76" stroke="black" stroke-width="0.5" />
    <path d="M294.54 160.76L294.58 160.77" stroke="black" stroke-width="0.5" />
    <path d="M294.58 160.77L294.62 160.78" stroke="black" stroke-width="0.5" />
    <path d="M294.62 160.78H294.66" stroke="black" stroke-width="0.5" />
    <path d="M294.66 160.78H294.71" stroke="black" stroke-width="0.5" />
    <path d="M294.71 160.78L294.75 160.79" stroke="black" stroke-width="0.5" />
    <path d="M294.75 160.79L294.79 160.78" stroke="black" stroke-width="0.5" />
    <path d="M294.79 160.78H294.83" stroke="black" stroke-width="0.5" />
    <path d="M294.83 160.78H294.86" stroke="black" stroke-width="0.5" />
    <path d="M294.86 160.78H294.9" stroke="black" stroke-width="0.5" />
    <path d="M294.9 160.78L294.93 160.77" stroke="black" stroke-width="0.5" />
    <path d="M294.93 160.77L294.97 160.76" stroke="black" stroke-width="0.5" />
    <path d="M294.97 160.76L295 160.75" stroke="black" stroke-width="0.5" />
    <path d="M295 160.75H295.04" stroke="black" stroke-width="0.5" />
    <path d="M295.04 160.75L295.07 160.74" stroke="black" stroke-width="0.5" />
    <path d="M295.07 160.74L295.1 160.72" stroke="black" stroke-width="0.5" />
    <path d="M295.1 160.72L295.14 160.71" stroke="black" stroke-width="0.5" />
    <path d="M295.14 160.71L295.17 160.7" stroke="black" stroke-width="0.5" />
    <path d="M295.17 160.7L295.2 160.68" stroke="black" stroke-width="0.5" />
    <path d="M295.2 160.68L295.23 160.67" stroke="black" stroke-width="0.5" />
    <path d="M295.23 160.67L295.26 160.65" stroke="black" stroke-width="0.5" />
    <path d="M295.26 160.65L295.29 160.63" stroke="black" stroke-width="0.5" />
    <path d="M295.29 160.63L295.32 160.62" stroke="black" stroke-width="0.5" />
    <path d="M295.32 160.62L295.35 160.6" stroke="black" stroke-width="0.5" />
    <path d="M295.35 160.6L295.38 160.58" stroke="black" stroke-width="0.5" />
    <path d="M295.38 160.58L295.41 160.55" stroke="black" stroke-width="0.5" />
    <path d="M295.41 160.55L295.43 160.53" stroke="black" stroke-width="0.5" />
    <path d="M295.43 160.53L295.46 160.51" stroke="black" stroke-width="0.5" />
    <path d="M295.46 160.51L295.49 160.49" stroke="black" stroke-width="0.5" />
    <path d="M295.49 160.49L295.51 160.46" stroke="black" stroke-width="0.5" />
    <path d="M295.51 160.46L295.54 160.44" stroke="black" stroke-width="0.5" />
    <path d="M295.54 160.44L295.56 160.41" stroke="black" stroke-width="0.5" />
    <path d="M295.56 160.41L295.59 160.38" stroke="black" stroke-width="0.5" />
    <path d="M295.59 160.38L295.61 160.36" stroke="black" stroke-width="0.5" />
    <path d="M295.61 160.36L295.63 160.33" stroke="black" stroke-width="0.5" />
    <path d="M295.63 160.33L295.65 160.3" stroke="black" stroke-width="0.5" />
    <path d="M295.65 160.3L295.68 160.27" stroke="black" stroke-width="0.5" />
    <path d="M295.68 160.27L295.7 160.24" stroke="black" stroke-width="0.5" />
    <path d="M295.7 160.24L295.72 160.21" stroke="black" stroke-width="0.5" />
    <path d="M295.72 160.21L295.74 160.18" stroke="black" stroke-width="0.5" />
    <path d="M295.74 160.18L295.76 160.15" stroke="black" stroke-width="0.5" />
    <path d="M295.76 160.15L295.78 160.12" stroke="black" stroke-width="0.5" />
    <path d="M295.78 160.12L295.79 160.08" stroke="black" stroke-width="0.5" />
    <path d="M295.79 160.08L295.81 160.05" stroke="black" stroke-width="0.5" />
    <path d="M295.81 160.05L295.83 160.02" stroke="black" stroke-width="0.5" />
    <path d="M295.83 160.02L295.84 159.98" stroke="black" stroke-width="0.5" />
    <path d="M295.84 159.98L295.86 159.95" stroke="black" stroke-width="0.5" />
    <path d="M295.86 159.95L295.88 159.91" stroke="black" stroke-width="0.5" />
    <path d="M295.88 159.91L295.89 159.88" stroke="black" stroke-width="0.5" />
    <path d="M295.89 159.88L295.9 159.84" stroke="black" stroke-width="0.5" />
    <path d="M295.9 159.84L295.92 159.81" stroke="black" stroke-width="0.5" />
    <path d="M295.92 159.81L295.93 159.77" stroke="black" stroke-width="0.5" />
    <path d="M295.93 159.77L295.95 159.7" stroke="black" stroke-width="0.5" />
    <path d="M295.95 159.7L295.97 159.62" stroke="black" stroke-width="0.5" />
    <path d="M295.97 159.62L295.99 159.55" stroke="black" stroke-width="0.5" />
    <path d="M295.99 159.55L296.01 159.47" stroke="black" stroke-width="0.5" />
    <path d="M296.01 159.47L296.02 159.4" stroke="black" stroke-width="0.5" />
    <path d="M296.02 159.4L296.03 159.32" stroke="black" stroke-width="0.5" />
    <path d="M296.03 159.32L296.04 159.24" stroke="black" stroke-width="0.5" />
    <path d="M296.04 159.24V159.2" stroke="black" stroke-width="0.5" />
    <path d="M296.04 159.2V159.17" stroke="black" stroke-width="0.5" />
    <path d="M296.04 159.17V159.13" stroke="black" stroke-width="0.5" />
    <path d="M296.04 159.13V159.09" stroke="black" stroke-width="0.5" />
    <path d="M296.04 159.09V159.05" stroke="black" stroke-width="0.5" />
    <path d="M296.04 159.05V159.01" stroke="black" stroke-width="0.5" />
    <path d="M296.04 159.01V158.98" stroke="black" stroke-width="0.5" />
    <path d="M296.04 158.98V158.94" stroke="black" stroke-width="0.5" />
    <path d="M296.04 158.94L296.03 158.86" stroke="black" stroke-width="0.5" />
    <path d="M296.03 158.86L296.02 158.79" stroke="black" stroke-width="0.5" />
    <path d="M296.02 158.79L296.01 158.71" stroke="black" stroke-width="0.5" />
    <path d="M296.01 158.71L295.99 158.63" stroke="black" stroke-width="0.5" />
    <path d="M295.99 158.63L295.97 158.56" stroke="black" stroke-width="0.5" />
    <path d="M295.97 158.56L295.95 158.48" stroke="black" stroke-width="0.5" />
    <path d="M295.95 158.48L295.93 158.41" stroke="black" stroke-width="0.5" />
    <path d="M295.93 158.41L295.92 158.37" stroke="black" stroke-width="0.5" />
    <path d="M295.92 158.37L295.9 158.34" stroke="black" stroke-width="0.5" />
    <path d="M295.9 158.34L295.89 158.3" stroke="black" stroke-width="0.5" />
    <path d="M295.89 158.3L295.88 158.27" stroke="black" stroke-width="0.5" />
    <path d="M295.88 158.27L295.86 158.23" stroke="black" stroke-width="0.5" />
    <path d="M295.86 158.23L295.84 158.2" stroke="black" stroke-width="0.5" />
    <path d="M295.84 158.2L295.83 158.16" stroke="black" stroke-width="0.5" />
    <path d="M295.83 158.16L295.81 158.13" stroke="black" stroke-width="0.5" />
    <path d="M295.81 158.13L295.79 158.1" stroke="black" stroke-width="0.5" />
    <path d="M295.79 158.1L295.78 158.07" stroke="black" stroke-width="0.5" />
    <path d="M295.78 158.07L295.76 158.03" stroke="black" stroke-width="0.5" />
    <path d="M295.76 158.03L295.74 158" stroke="black" stroke-width="0.5" />
    <path d="M295.74 158L295.72 157.97" stroke="black" stroke-width="0.5" />
    <path d="M295.72 157.97L295.7 157.94" stroke="black" stroke-width="0.5" />
    <path d="M295.7 157.94L295.68 157.91" stroke="black" stroke-width="0.5" />
    <path d="M295.68 157.91L295.65 157.88" stroke="black" stroke-width="0.5" />
    <path d="M295.65 157.88L295.63 157.85" stroke="black" stroke-width="0.5" />
    <path d="M295.63 157.85L295.61 157.82" stroke="black" stroke-width="0.5" />
    <path d="M295.61 157.82L295.59 157.8" stroke="black" stroke-width="0.5" />
    <path d="M295.59 157.8L295.56 157.77" stroke="black" stroke-width="0.5" />
    <path d="M295.56 157.77L295.54 157.74" stroke="black" stroke-width="0.5" />
    <path d="M295.54 157.74L295.51 157.72" stroke="black" stroke-width="0.5" />
    <path d="M295.51 157.72L295.49 157.69" stroke="black" stroke-width="0.5" />
    <path d="M295.49 157.69L295.46 157.67" stroke="black" stroke-width="0.5" />
    <path d="M295.46 157.67L295.43 157.65" stroke="black" stroke-width="0.5" />
    <path d="M295.43 157.65L295.41 157.63" stroke="black" stroke-width="0.5" />
    <path d="M295.41 157.63L295.38 157.6" stroke="black" stroke-width="0.5" />
    <path d="M295.38 157.6L295.35 157.58" stroke="black" stroke-width="0.5" />
    <path d="M295.35 157.58L295.32 157.56" stroke="black" stroke-width="0.5" />
    <path d="M295.32 157.56L295.29 157.55" stroke="black" stroke-width="0.5" />
    <path d="M295.29 157.55L295.26 157.53" stroke="black" stroke-width="0.5" />
    <path d="M295.26 157.53L295.23 157.51" stroke="black" stroke-width="0.5" />
    <path d="M295.23 157.51L295.2 157.5" stroke="black" stroke-width="0.5" />
    <path d="M295.2 157.5L295.17 157.48" stroke="black" stroke-width="0.5" />
    <path d="M295.17 157.48L295.14 157.47" stroke="black" stroke-width="0.5" />
    <path d="M295.14 157.47L295.1 157.46" stroke="black" stroke-width="0.5" />
    <path d="M295.1 157.46L295.07 157.45" stroke="black" stroke-width="0.5" />
    <path d="M295.07 157.45L295.04 157.44" stroke="black" stroke-width="0.5" />
    <path d="M295.04 157.44L295 157.43" stroke="black" stroke-width="0.5" />
    <path d="M295 157.43L294.97 157.42" stroke="black" stroke-width="0.5" />
    <path d="M294.97 157.42L294.93 157.41" stroke="black" stroke-width="0.5" />
    <path d="M294.93 157.41H294.9" stroke="black" stroke-width="0.5" />
    <path d="M294.9 157.41L294.86 157.4" stroke="black" stroke-width="0.5" />
    <path d="M294.86 157.4H294.83" stroke="black" stroke-width="0.5" />
    <path d="M294.83 157.4H294.79" stroke="black" stroke-width="0.5" />
    <path d="M294.79 157.4H294.75" stroke="black" stroke-width="0.5" />
    <path d="M294.75 157.4H294.71" stroke="black" stroke-width="0.5" />
    <path d="M294.71 157.4H294.67" stroke="black" stroke-width="0.5" />
    <path d="M294.67 157.4H294.62" stroke="black" stroke-width="0.5" />
    <path d="M294.62 157.4L294.58 157.41" stroke="black" stroke-width="0.5" />
    <path d="M294.58 157.41L294.54 157.42" stroke="black" stroke-width="0.5" />
    <path d="M294.54 157.42L294.5 157.43" stroke="black" stroke-width="0.5" />
    <path d="M294.5 157.43L294.47 157.44" stroke="black" stroke-width="0.5" />
    <path d="M294.47 157.44L294.43 157.46" stroke="black" stroke-width="0.5" />
    <path d="M294.43 157.46L294.39 157.48" stroke="black" stroke-width="0.5" />
    <path d="M294.39 157.48L294.36 157.5" stroke="black" stroke-width="0.5" />
    <path d="M294.36 157.5L294.32 157.52" stroke="black" stroke-width="0.5" />
    <path d="M294.32 157.52L294.29 157.54" stroke="black" stroke-width="0.5" />
    <path d="M294.29 157.54L294.26 157.57" stroke="black" stroke-width="0.5" />
    <path d="M294.26 157.57L294.23 157.59" stroke="black" stroke-width="0.5" />
    <path d="M294.23 157.59L294.19 157.62" stroke="black" stroke-width="0.5" />
    <path d="M294.19 157.62L294.16 157.65" stroke="black" stroke-width="0.5" />
    <path d="M294.16 157.65L294.14 157.69" stroke="black" stroke-width="0.5" />
    <path d="M294.14 157.69L294.11 157.72" stroke="black" stroke-width="0.5" />
    <path d="M294.11 157.72L294.08 157.76" stroke="black" stroke-width="0.5" />
    <path d="M294.08 157.76L294.05 157.8" stroke="black" stroke-width="0.5" />
    <path d="M294.05 157.8L294.03 157.84" stroke="black" stroke-width="0.5" />
    <path d="M294.03 157.84L294 157.89" stroke="black" stroke-width="0.5" />
    <path d="M294 157.89L293.98 157.94" stroke="black" stroke-width="0.5" />
    <path d="M293.98 157.94L293.96 157.98" stroke="black" stroke-width="0.5" />
    <path d="M293.96 157.98L293.93 158.03" stroke="black" stroke-width="0.5" />
    <path d="M293.93 158.03L293.91 158.09" stroke="black" stroke-width="0.5" />
    <path d="M293.91 158.09L293.89 158.14" stroke="black" stroke-width="0.5" />
    <path d="M293.89 158.14L293.87 158.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 158.2L293.85 158.26" stroke="black" stroke-width="0.5" />
    <path d="M293.85 158.26L293.84 158.32" stroke="black" stroke-width="0.5" />
    <path d="M293.84 158.32L293.82 158.38" stroke="black" stroke-width="0.5" />
    <path d="M293.82 158.38L293.8 158.45" stroke="black" stroke-width="0.5" />
    <path d="M294.72 157.04L295.45 157.21" stroke="black" stroke-width="0.5" />
    <path d="M295.45 157.21L296.05 157.72" stroke="black" stroke-width="0.5" />
    <path d="M296.05 157.72L296.43 158.46" stroke="black" stroke-width="0.5" />
    <path d="M296.43 158.46L296.51 159.31" stroke="black" stroke-width="0.5" />
    <path d="M296.51 159.31L296.27 160.12" stroke="black" stroke-width="0.5" />
    <path d="M296.27 160.12L295.78 160.75" stroke="black" stroke-width="0.5" />
    <path d="M295.78 160.75L295.09 161.1" stroke="black" stroke-width="0.5" />
    <path d="M295.09 161.1H294.35" stroke="black" stroke-width="0.5" />
    <path d="M294.35 161.1L293.66 160.75" stroke="black" stroke-width="0.5" />
    <path d="M293.66 160.75L293.17 160.12" stroke="black" stroke-width="0.5" />
    <path d="M293.17 160.12L292.93 159.31" stroke="black" stroke-width="0.5" />
    <path d="M292.93 159.31L293.01 158.46" stroke="black" stroke-width="0.5" />
    <path d="M293.01 158.46L293.39 157.72" stroke="black" stroke-width="0.5" />
    <path d="M293.39 157.72L293.99 157.21" stroke="black" stroke-width="0.5" />
    <path d="M293.99 157.21L294.72 157.04" stroke="black" stroke-width="0.5" />
    <path d="M293.79 159.69L293.8 158.45" stroke="black" stroke-width="0.5" />
    <path d="M265.46 40.86L265.65 38.99" stroke="black" stroke-width="0.5" />
    <path d="M265.65 38.99L266.19 37.2" stroke="black" stroke-width="0.5" />
    <path d="M266.19 37.2L267.08 35.54" stroke="black" stroke-width="0.5" />
    <path d="M267.08 35.54L268.27 34.09" stroke="black" stroke-width="0.5" />
    <path d="M268.27 34.09L269.72 32.9" stroke="black" stroke-width="0.5" />
    <path d="M269.72 32.9L271.38 32.02" stroke="black" stroke-width="0.5" />
    <path d="M271.38 32.02L273.17 31.47" stroke="black" stroke-width="0.5" />
    <path d="M273.17 31.47L275.04 31.29" stroke="black" stroke-width="0.5" />
    <path d="M275.04 40.4V40.86" stroke="black" stroke-width="0.5" />
    <path d="M275.04 40.86H265.46" stroke="black" stroke-width="0.5" />
    <path d="M265.46 40.86V40.4" stroke="black" stroke-width="0.5" />
    <path d="M265.46 40.4H275.04" stroke="black" stroke-width="0.5" />
    <path d="M275.04 40.86V41.39" stroke="black" stroke-width="0.5" />
    <path d="M275.04 41.39H276.37" stroke="black" stroke-width="0.5" />
    <path d="M276.37 41.39V40.86" stroke="black" stroke-width="0.5" />
    <path d="M276.37 40.86H275.04" stroke="black" stroke-width="0.5" />
    <path d="M275.04 30.76V31.29" stroke="black" stroke-width="0.5" />
    <path d="M275.04 31.29H276.37" stroke="black" stroke-width="0.5" />
    <path d="M276.37 31.29V30.76" stroke="black" stroke-width="0.5" />
    <path d="M276.37 30.76H275.04" stroke="black" stroke-width="0.5" />
    <path d="M227.63 55.09H226.3" stroke="black" stroke-width="0.5" />
    <path d="M227.63 64.67H226.3" stroke="black" stroke-width="0.5" />
    <path d="M205.89 163.74V125.94" stroke="black" stroke-width="0.5" />
    <path d="M205.89 124.61V119.32" stroke="black" stroke-width="0.5" />
    <path d="M118.32 2.42999H227.63" stroke="black" stroke-width="0.5" />
    <path d="M282.75 21.58V8.81" stroke="black" stroke-width="0.5" />
    <path d="M248.64 7.5L244.14 12.01" stroke="black" stroke-width="0.5" />
    <path d="M327.17 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M276.37 21.58H282.75" stroke="black" stroke-width="0.5" />
    <path d="M282.75 55.49H276.37" stroke="black" stroke-width="0.5" />
    <path d="M198.18 149.38H204.56" stroke="black" stroke-width="0.5" />
    <path d="M154.19 146.18H147.81" stroke="black" stroke-width="0.5" />
    <path d="M140.1 146.18H146.48" stroke="black" stroke-width="0.5" />
    <path d="M93.75 146.19H87.37" stroke="black" stroke-width="0.5" />
    <path d="M79.66 145.02H86.04" stroke="black" stroke-width="0.5" />
    <path d="M280.02 89L279.98 89.11" stroke="black" stroke-width="0.5" />
    <path d="M279.98 89.11L279.86 89.16" stroke="black" stroke-width="0.5" />
    <path d="M279.86 89.16L279.75 89.11" stroke="black" stroke-width="0.5" />
    <path d="M279.75 89.11L279.7 89" stroke="black" stroke-width="0.5" />
    <path d="M279.7 89L279.75 88.89" stroke="black" stroke-width="0.5" />
    <path d="M279.75 88.89L279.86 88.84" stroke="black" stroke-width="0.5" />
    <path d="M279.86 88.84L279.98 88.89" stroke="black" stroke-width="0.5" />
    <path d="M279.98 88.89L280.02 89" stroke="black" stroke-width="0.5" />
    <path d="M279.5 89.58L279.52 89.65" stroke="black" stroke-width="0.5" />
    <path d="M279.52 89.65L279.53 89.71" stroke="black" stroke-width="0.5" />
    <path d="M279.53 89.71L279.55 89.78" stroke="black" stroke-width="0.5" />
    <path d="M279.55 89.78L279.57 89.84" stroke="black" stroke-width="0.5" />
    <path d="M279.57 89.84L279.59 89.9" stroke="black" stroke-width="0.5" />
    <path d="M279.59 89.9L279.61 89.95" stroke="black" stroke-width="0.5" />
    <path d="M279.61 89.95L279.63 90.01" stroke="black" stroke-width="0.5" />
    <path d="M279.63 90.01L279.65 90.06" stroke="black" stroke-width="0.5" />
    <path d="M279.65 90.06L279.68 90.11" stroke="black" stroke-width="0.5" />
    <path d="M279.68 90.11L279.7 90.16" stroke="black" stroke-width="0.5" />
    <path d="M279.7 90.16L279.72 90.21" stroke="black" stroke-width="0.5" />
    <path d="M279.72 90.21L279.75 90.25" stroke="black" stroke-width="0.5" />
    <path d="M279.75 90.25L279.78 90.29" stroke="black" stroke-width="0.5" />
    <path d="M279.78 90.29L279.8 90.33" stroke="black" stroke-width="0.5" />
    <path d="M279.8 90.33L279.83 90.37" stroke="black" stroke-width="0.5" />
    <path d="M279.83 90.37L279.86 90.4" stroke="black" stroke-width="0.5" />
    <path d="M279.86 90.4L279.89 90.44" stroke="black" stroke-width="0.5" />
    <path d="M279.89 90.44L279.92 90.47" stroke="black" stroke-width="0.5" />
    <path d="M279.92 90.47L279.96 90.49" stroke="black" stroke-width="0.5" />
    <path d="M279.96 90.49L279.99 90.52" stroke="black" stroke-width="0.5" />
    <path d="M279.99 90.52L280.02 90.55" stroke="black" stroke-width="0.5" />
    <path d="M280.02 90.55L280.06 90.57" stroke="black" stroke-width="0.5" />
    <path d="M280.06 90.57L280.09 90.59" stroke="black" stroke-width="0.5" />
    <path d="M280.09 90.59L280.13 90.6" stroke="black" stroke-width="0.5" />
    <path d="M280.13 90.6L280.17 90.62" stroke="black" stroke-width="0.5" />
    <path d="M280.17 90.62L280.21 90.63" stroke="black" stroke-width="0.5" />
    <path d="M280.21 90.63L280.25 90.65" stroke="black" stroke-width="0.5" />
    <path d="M280.25 90.65H280.29" stroke="black" stroke-width="0.5" />
    <path d="M280.29 90.65L280.33 90.66" stroke="black" stroke-width="0.5" />
    <path d="M280.33 90.66L280.37 90.67" stroke="black" stroke-width="0.5" />
    <path d="M280.37 90.67H280.41" stroke="black" stroke-width="0.5" />
    <path d="M280.41 90.67H280.46" stroke="black" stroke-width="0.5" />
    <path d="M280.46 90.67H280.49" stroke="black" stroke-width="0.5" />
    <path d="M280.49 90.67H280.53" stroke="black" stroke-width="0.5" />
    <path d="M280.53 90.67H280.57" stroke="black" stroke-width="0.5" />
    <path d="M280.57 90.67L280.6 90.66" stroke="black" stroke-width="0.5" />
    <path d="M280.6 90.66H280.64" stroke="black" stroke-width="0.5" />
    <path d="M280.64 90.66L280.67 90.65" stroke="black" stroke-width="0.5" />
    <path d="M280.67 90.65L280.71 90.64" stroke="black" stroke-width="0.5" />
    <path d="M280.71 90.64L280.74 90.63" stroke="black" stroke-width="0.5" />
    <path d="M280.74 90.63L280.78 90.62" stroke="black" stroke-width="0.5" />
    <path d="M280.78 90.62L280.81 90.61" stroke="black" stroke-width="0.5" />
    <path d="M280.81 90.61L280.84 90.6" stroke="black" stroke-width="0.5" />
    <path d="M280.84 90.6L280.87 90.58" stroke="black" stroke-width="0.5" />
    <path d="M280.87 90.58L280.9 90.57" stroke="black" stroke-width="0.5" />
    <path d="M280.9 90.57L280.94 90.55" stroke="black" stroke-width="0.5" />
    <path d="M280.94 90.55L280.97 90.54" stroke="black" stroke-width="0.5" />
    <path d="M280.97 90.54L281 90.52" stroke="black" stroke-width="0.5" />
    <path d="M281 90.52L281.03 90.5" stroke="black" stroke-width="0.5" />
    <path d="M281.03 90.5L281.05 90.48" stroke="black" stroke-width="0.5" />
    <path d="M281.05 90.48L281.08 90.46" stroke="black" stroke-width="0.5" />
    <path d="M281.08 90.46L281.11 90.44" stroke="black" stroke-width="0.5" />
    <path d="M281.11 90.44L281.14 90.42" stroke="black" stroke-width="0.5" />
    <path d="M281.14 90.42L281.16 90.4" stroke="black" stroke-width="0.5" />
    <path d="M281.16 90.4L281.19 90.37" stroke="black" stroke-width="0.5" />
    <path d="M281.19 90.37L281.22 90.35" stroke="black" stroke-width="0.5" />
    <path d="M281.22 90.35L281.24 90.32" stroke="black" stroke-width="0.5" />
    <path d="M281.24 90.32L281.27 90.3" stroke="black" stroke-width="0.5" />
    <path d="M281.27 90.3L281.29 90.27" stroke="black" stroke-width="0.5" />
    <path d="M281.29 90.27L281.31 90.24" stroke="black" stroke-width="0.5" />
    <path d="M281.31 90.24L281.34 90.21" stroke="black" stroke-width="0.5" />
    <path d="M281.34 90.21L281.36 90.19" stroke="black" stroke-width="0.5" />
    <path d="M281.36 90.19L281.38 90.16" stroke="black" stroke-width="0.5" />
    <path d="M281.38 90.16L281.4 90.13" stroke="black" stroke-width="0.5" />
    <path d="M281.4 90.13L281.42 90.1" stroke="black" stroke-width="0.5" />
    <path d="M281.42 90.1L281.44 90.07" stroke="black" stroke-width="0.5" />
    <path d="M281.44 90.07L281.46 90.03" stroke="black" stroke-width="0.5" />
    <path d="M281.46 90.03L281.48 90" stroke="black" stroke-width="0.5" />
    <path d="M281.48 90L281.5 89.97" stroke="black" stroke-width="0.5" />
    <path d="M281.5 89.97L281.52 89.94" stroke="black" stroke-width="0.5" />
    <path d="M281.52 89.94L281.53 89.9" stroke="black" stroke-width="0.5" />
    <path d="M281.53 89.9L281.55 89.87" stroke="black" stroke-width="0.5" />
    <path d="M281.55 89.87L281.57 89.83" stroke="black" stroke-width="0.5" />
    <path d="M281.57 89.83L281.58 89.8" stroke="black" stroke-width="0.5" />
    <path d="M281.58 89.8L281.59 89.76" stroke="black" stroke-width="0.5" />
    <path d="M281.59 89.76L281.61 89.73" stroke="black" stroke-width="0.5" />
    <path d="M281.61 89.73L281.62 89.69" stroke="black" stroke-width="0.5" />
    <path d="M281.62 89.69L281.63 89.66" stroke="black" stroke-width="0.5" />
    <path d="M281.63 89.66L281.66 89.58" stroke="black" stroke-width="0.5" />
    <path d="M281.66 89.58L281.68 89.51" stroke="black" stroke-width="0.5" />
    <path d="M281.68 89.51L281.7 89.43" stroke="black" stroke-width="0.5" />
    <path d="M281.7 89.43L281.71 89.36" stroke="black" stroke-width="0.5" />
    <path d="M281.71 89.36L281.72 89.28" stroke="black" stroke-width="0.5" />
    <path d="M281.72 89.28L281.73 89.2" stroke="black" stroke-width="0.5" />
    <path d="M281.73 89.2L281.74 89.13" stroke="black" stroke-width="0.5" />
    <path d="M281.74 89.13V89.09" stroke="black" stroke-width="0.5" />
    <path d="M281.74 89.09V89.05" stroke="black" stroke-width="0.5" />
    <path d="M281.74 89.05L281.75 89.01" stroke="black" stroke-width="0.5" />
    <path d="M281.75 89.01V88.98" stroke="black" stroke-width="0.5" />
    <path d="M281.75 88.98V88.94" stroke="black" stroke-width="0.5" />
    <path d="M281.75 88.94L281.74 88.9" stroke="black" stroke-width="0.5" />
    <path d="M281.74 88.9V88.86" stroke="black" stroke-width="0.5" />
    <path d="M281.74 88.86V88.82" stroke="black" stroke-width="0.5" />
    <path d="M281.74 88.82L281.73 88.75" stroke="black" stroke-width="0.5" />
    <path d="M281.73 88.75L281.72 88.67" stroke="black" stroke-width="0.5" />
    <path d="M281.72 88.67L281.71 88.6" stroke="black" stroke-width="0.5" />
    <path d="M281.71 88.6L281.7 88.52" stroke="black" stroke-width="0.5" />
    <path d="M281.7 88.52L281.68 88.44" stroke="black" stroke-width="0.5" />
    <path d="M281.68 88.44L281.66 88.37" stroke="black" stroke-width="0.5" />
    <path d="M281.66 88.37L281.63 88.3" stroke="black" stroke-width="0.5" />
    <path d="M281.63 88.3L281.62 88.26" stroke="black" stroke-width="0.5" />
    <path d="M281.62 88.26L281.61 88.22" stroke="black" stroke-width="0.5" />
    <path d="M281.61 88.22L281.59 88.19" stroke="black" stroke-width="0.5" />
    <path d="M281.59 88.19L281.58 88.15" stroke="black" stroke-width="0.5" />
    <path d="M281.58 88.15L281.57 88.12" stroke="black" stroke-width="0.5" />
    <path d="M281.57 88.12L281.55 88.08" stroke="black" stroke-width="0.5" />
    <path d="M281.55 88.08L281.53 88.05" stroke="black" stroke-width="0.5" />
    <path d="M281.53 88.05L281.52 88.02" stroke="black" stroke-width="0.5" />
    <path d="M281.52 88.02L281.5 87.98" stroke="black" stroke-width="0.5" />
    <path d="M281.5 87.98L281.48 87.95" stroke="black" stroke-width="0.5" />
    <path d="M281.48 87.95L281.46 87.92" stroke="black" stroke-width="0.5" />
    <path d="M281.46 87.92L281.44 87.89" stroke="black" stroke-width="0.5" />
    <path d="M281.44 87.89L281.42 87.86" stroke="black" stroke-width="0.5" />
    <path d="M281.42 87.86L281.4 87.83" stroke="black" stroke-width="0.5" />
    <path d="M281.4 87.83L281.38 87.8" stroke="black" stroke-width="0.5" />
    <path d="M281.38 87.8L281.36 87.77" stroke="black" stroke-width="0.5" />
    <path d="M281.36 87.77L281.34 87.74" stroke="black" stroke-width="0.5" />
    <path d="M281.34 87.74L281.31 87.71" stroke="black" stroke-width="0.5" />
    <path d="M281.31 87.71L281.29 87.68" stroke="black" stroke-width="0.5" />
    <path d="M281.29 87.68L281.27 87.66" stroke="black" stroke-width="0.5" />
    <path d="M281.27 87.66L281.24 87.63" stroke="black" stroke-width="0.5" />
    <path d="M281.24 87.63L281.22 87.6" stroke="black" stroke-width="0.5" />
    <path d="M281.22 87.6L281.19 87.58" stroke="black" stroke-width="0.5" />
    <path d="M281.19 87.58L281.16 87.56" stroke="black" stroke-width="0.5" />
    <path d="M281.16 87.56L281.14 87.53" stroke="black" stroke-width="0.5" />
    <path d="M281.14 87.53L281.11 87.51" stroke="black" stroke-width="0.5" />
    <path d="M281.11 87.51L281.08 87.49" stroke="black" stroke-width="0.5" />
    <path d="M281.08 87.49L281.05 87.47" stroke="black" stroke-width="0.5" />
    <path d="M281.05 87.47L281.03 87.45" stroke="black" stroke-width="0.5" />
    <path d="M281.03 87.45L281 87.43" stroke="black" stroke-width="0.5" />
    <path d="M281 87.43L280.97 87.41" stroke="black" stroke-width="0.5" />
    <path d="M280.97 87.41L280.94 87.4" stroke="black" stroke-width="0.5" />
    <path d="M280.94 87.4L280.9 87.38" stroke="black" stroke-width="0.5" />
    <path d="M280.9 87.38L280.87 87.37" stroke="black" stroke-width="0.5" />
    <path d="M280.87 87.37L280.84 87.35" stroke="black" stroke-width="0.5" />
    <path d="M280.84 87.35L280.81 87.34" stroke="black" stroke-width="0.5" />
    <path d="M280.81 87.34L280.78 87.33" stroke="black" stroke-width="0.5" />
    <path d="M280.78 87.33L280.74 87.32" stroke="black" stroke-width="0.5" />
    <path d="M280.74 87.32L280.71 87.31" stroke="black" stroke-width="0.5" />
    <path d="M280.71 87.31L280.67 87.3" stroke="black" stroke-width="0.5" />
    <path d="M280.67 87.3H280.64" stroke="black" stroke-width="0.5" />
    <path d="M280.64 87.3L280.6 87.29" stroke="black" stroke-width="0.5" />
    <path d="M280.6 87.29H280.57" stroke="black" stroke-width="0.5" />
    <path d="M280.57 87.29L280.53 87.28" stroke="black" stroke-width="0.5" />
    <path d="M280.53 87.28H280.49" stroke="black" stroke-width="0.5" />
    <path d="M280.49 87.28H280.46" stroke="black" stroke-width="0.5" />
    <path d="M280.46 87.28H280.41" stroke="black" stroke-width="0.5" />
    <path d="M280.41 87.28L280.37 87.29" stroke="black" stroke-width="0.5" />
    <path d="M280.37 87.29H280.33" stroke="black" stroke-width="0.5" />
    <path d="M280.33 87.29L280.29 87.3" stroke="black" stroke-width="0.5" />
    <path d="M280.29 87.3L280.25 87.31" stroke="black" stroke-width="0.5" />
    <path d="M280.25 87.31L280.21 87.32" stroke="black" stroke-width="0.5" />
    <path d="M280.21 87.32L280.17 87.33" stroke="black" stroke-width="0.5" />
    <path d="M280.17 87.33L280.13 87.35" stroke="black" stroke-width="0.5" />
    <path d="M280.13 87.35L280.1 87.36" stroke="black" stroke-width="0.5" />
    <path d="M280.1 87.36L280.06 87.38" stroke="black" stroke-width="0.5" />
    <path d="M280.06 87.38L280.03 87.4" stroke="black" stroke-width="0.5" />
    <path d="M280.03 87.4L280 87.43" stroke="black" stroke-width="0.5" />
    <path d="M280 87.43L279.96 87.45" stroke="black" stroke-width="0.5" />
    <path d="M279.96 87.45L279.93 87.48" stroke="black" stroke-width="0.5" />
    <path d="M279.93 87.48L279.9 87.51" stroke="black" stroke-width="0.5" />
    <path d="M279.9 87.51L279.87 87.54" stroke="black" stroke-width="0.5" />
    <path d="M279.87 87.54L279.84 87.57" stroke="black" stroke-width="0.5" />
    <path d="M279.84 87.57L279.81 87.61" stroke="black" stroke-width="0.5" />
    <path d="M279.81 87.61L279.79 87.65" stroke="black" stroke-width="0.5" />
    <path d="M279.79 87.65L279.76 87.69" stroke="black" stroke-width="0.5" />
    <path d="M279.76 87.69L279.73 87.73" stroke="black" stroke-width="0.5" />
    <path d="M279.73 87.73L279.71 87.77" stroke="black" stroke-width="0.5" />
    <path d="M279.71 87.77L279.68 87.82" stroke="black" stroke-width="0.5" />
    <path d="M279.68 87.82L279.66 87.87" stroke="black" stroke-width="0.5" />
    <path d="M279.66 87.87L279.64 87.92" stroke="black" stroke-width="0.5" />
    <path d="M279.64 87.92L279.62 87.97" stroke="black" stroke-width="0.5" />
    <path d="M279.62 87.97L279.6 88.03" stroke="black" stroke-width="0.5" />
    <path d="M279.6 88.03L279.58 88.09" stroke="black" stroke-width="0.5" />
    <path d="M279.58 88.09L279.56 88.15" stroke="black" stroke-width="0.5" />
    <path d="M279.56 88.15L279.54 88.21" stroke="black" stroke-width="0.5" />
    <path d="M279.54 88.21L279.53 88.27" stroke="black" stroke-width="0.5" />
    <path d="M279.53 88.27L279.51 88.34" stroke="black" stroke-width="0.5" />
    <path d="M280.43 86.92L281.16 87.1" stroke="black" stroke-width="0.5" />
    <path d="M281.16 87.1L281.76 87.6" stroke="black" stroke-width="0.5" />
    <path d="M281.76 87.6L282.13 88.34" stroke="black" stroke-width="0.5" />
    <path d="M282.13 88.34L282.21 89.19" stroke="black" stroke-width="0.5" />
    <path d="M282.21 89.19L281.98 90" stroke="black" stroke-width="0.5" />
    <path d="M281.98 90L281.48 90.64" stroke="black" stroke-width="0.5" />
    <path d="M281.48 90.64L280.8 90.99" stroke="black" stroke-width="0.5" />
    <path d="M280.8 90.99H280.05" stroke="black" stroke-width="0.5" />
    <path d="M280.05 90.99L279.37 90.64" stroke="black" stroke-width="0.5" />
    <path d="M279.37 90.64L278.87 90" stroke="black" stroke-width="0.5" />
    <path d="M278.87 90L278.64 89.19" stroke="black" stroke-width="0.5" />
    <path d="M278.64 89.19L278.72 88.34" stroke="black" stroke-width="0.5" />
    <path d="M278.72 88.34L279.09 87.6" stroke="black" stroke-width="0.5" />
    <path d="M279.09 87.6L279.7 87.1" stroke="black" stroke-width="0.5" />
    <path d="M279.7 87.1L280.43 86.92" stroke="black" stroke-width="0.5" />
    <path d="M279.5 89.58L279.51 88.34" stroke="black" stroke-width="0.5" />
    <path d="M280.02 16.79L279.98 16.91" stroke="black" stroke-width="0.5" />
    <path d="M279.98 16.91L279.86 16.95" stroke="black" stroke-width="0.5" />
    <path d="M279.86 16.95L279.75 16.91" stroke="black" stroke-width="0.5" />
    <path d="M279.75 16.91L279.7 16.79" stroke="black" stroke-width="0.5" />
    <path d="M279.7 16.79L279.75 16.68" stroke="black" stroke-width="0.5" />
    <path d="M279.75 16.68L279.86 16.63" stroke="black" stroke-width="0.5" />
    <path d="M279.86 16.63L279.98 16.68" stroke="black" stroke-width="0.5" />
    <path d="M279.98 16.68L280.02 16.79" stroke="black" stroke-width="0.5" />
    <path d="M279.5 17.37L279.52 17.44" stroke="black" stroke-width="0.5" />
    <path d="M279.52 17.44L279.53 17.5" stroke="black" stroke-width="0.5" />
    <path d="M279.53 17.5L279.55 17.57" stroke="black" stroke-width="0.5" />
    <path d="M279.55 17.57L279.57 17.63" stroke="black" stroke-width="0.5" />
    <path d="M279.57 17.63L279.59 17.69" stroke="black" stroke-width="0.5" />
    <path d="M279.59 17.69L279.61 17.75" stroke="black" stroke-width="0.5" />
    <path d="M279.61 17.75L279.63 17.8" stroke="black" stroke-width="0.5" />
    <path d="M279.63 17.8L279.65 17.85" stroke="black" stroke-width="0.5" />
    <path d="M279.65 17.85L279.68 17.9" stroke="black" stroke-width="0.5" />
    <path d="M279.68 17.9L279.7 17.95" stroke="black" stroke-width="0.5" />
    <path d="M279.7 17.95L279.72 18" stroke="black" stroke-width="0.5" />
    <path d="M279.72 18L279.75 18.04" stroke="black" stroke-width="0.5" />
    <path d="M279.75 18.04L279.78 18.08" stroke="black" stroke-width="0.5" />
    <path d="M279.78 18.08L279.8 18.12" stroke="black" stroke-width="0.5" />
    <path d="M279.8 18.12L279.83 18.16" stroke="black" stroke-width="0.5" />
    <path d="M279.83 18.16L279.86 18.19" stroke="black" stroke-width="0.5" />
    <path d="M279.86 18.19L279.89 18.23" stroke="black" stroke-width="0.5" />
    <path d="M279.89 18.23L279.92 18.26" stroke="black" stroke-width="0.5" />
    <path d="M279.92 18.26L279.96 18.29" stroke="black" stroke-width="0.5" />
    <path d="M279.96 18.29L279.99 18.31" stroke="black" stroke-width="0.5" />
    <path d="M279.99 18.31L280.02 18.34" stroke="black" stroke-width="0.5" />
    <path d="M280.02 18.34L280.06 18.36" stroke="black" stroke-width="0.5" />
    <path d="M280.06 18.36L280.09 18.38" stroke="black" stroke-width="0.5" />
    <path d="M280.09 18.38L280.13 18.4" stroke="black" stroke-width="0.5" />
    <path d="M280.13 18.4L280.17 18.41" stroke="black" stroke-width="0.5" />
    <path d="M280.17 18.41L280.21 18.43" stroke="black" stroke-width="0.5" />
    <path d="M280.21 18.43L280.25 18.44" stroke="black" stroke-width="0.5" />
    <path d="M280.25 18.44L280.29 18.45" stroke="black" stroke-width="0.5" />
    <path d="M280.29 18.45H280.33" stroke="black" stroke-width="0.5" />
    <path d="M280.33 18.45L280.37 18.46" stroke="black" stroke-width="0.5" />
    <path d="M280.37 18.46H280.41" stroke="black" stroke-width="0.5" />
    <path d="M280.41 18.46H280.46" stroke="black" stroke-width="0.5" />
    <path d="M280.46 18.46H280.49" stroke="black" stroke-width="0.5" />
    <path d="M280.49 18.46H280.53" stroke="black" stroke-width="0.5" />
    <path d="M280.53 18.46H280.57" stroke="black" stroke-width="0.5" />
    <path d="M280.57 18.46L280.6 18.45" stroke="black" stroke-width="0.5" />
    <path d="M280.6 18.45H280.64" stroke="black" stroke-width="0.5" />
    <path d="M280.64 18.45L280.67 18.44" stroke="black" stroke-width="0.5" />
    <path d="M280.67 18.44L280.71 18.43" stroke="black" stroke-width="0.5" />
    <path d="M280.71 18.43L280.74 18.42" stroke="black" stroke-width="0.5" />
    <path d="M280.74 18.42L280.78 18.41" stroke="black" stroke-width="0.5" />
    <path d="M280.78 18.41L280.81 18.4" stroke="black" stroke-width="0.5" />
    <path d="M280.81 18.4L280.84 18.39" stroke="black" stroke-width="0.5" />
    <path d="M280.84 18.39L280.87 18.38" stroke="black" stroke-width="0.5" />
    <path d="M280.87 18.38L280.9 18.36" stroke="black" stroke-width="0.5" />
    <path d="M280.9 18.36L280.94 18.35" stroke="black" stroke-width="0.5" />
    <path d="M280.94 18.35L280.97 18.33" stroke="black" stroke-width="0.5" />
    <path d="M280.97 18.33L281 18.31" stroke="black" stroke-width="0.5" />
    <path d="M281 18.31L281.03 18.29" stroke="black" stroke-width="0.5" />
    <path d="M281.03 18.29L281.05 18.27" stroke="black" stroke-width="0.5" />
    <path d="M281.05 18.27L281.08 18.25" stroke="black" stroke-width="0.5" />
    <path d="M281.08 18.25L281.11 18.23" stroke="black" stroke-width="0.5" />
    <path d="M281.11 18.23L281.14 18.21" stroke="black" stroke-width="0.5" />
    <path d="M281.14 18.21L281.16 18.19" stroke="black" stroke-width="0.5" />
    <path d="M281.16 18.19L281.19 18.16" stroke="black" stroke-width="0.5" />
    <path d="M281.19 18.16L281.22 18.14" stroke="black" stroke-width="0.5" />
    <path d="M281.22 18.14L281.24 18.11" stroke="black" stroke-width="0.5" />
    <path d="M281.24 18.11L281.27 18.09" stroke="black" stroke-width="0.5" />
    <path d="M281.27 18.09L281.29 18.06" stroke="black" stroke-width="0.5" />
    <path d="M281.29 18.06L281.31 18.03" stroke="black" stroke-width="0.5" />
    <path d="M281.31 18.03L281.34 18.01" stroke="black" stroke-width="0.5" />
    <path d="M281.34 18.01L281.36 17.98" stroke="black" stroke-width="0.5" />
    <path d="M281.36 17.98L281.38 17.95" stroke="black" stroke-width="0.5" />
    <path d="M281.38 17.95L281.4 17.92" stroke="black" stroke-width="0.5" />
    <path d="M281.4 17.92L281.42 17.89" stroke="black" stroke-width="0.5" />
    <path d="M281.42 17.89L281.44 17.86" stroke="black" stroke-width="0.5" />
    <path d="M281.44 17.86L281.46 17.82" stroke="black" stroke-width="0.5" />
    <path d="M281.46 17.82L281.48 17.79" stroke="black" stroke-width="0.5" />
    <path d="M281.48 17.79L281.5 17.76" stroke="black" stroke-width="0.5" />
    <path d="M281.5 17.76L281.52 17.73" stroke="black" stroke-width="0.5" />
    <path d="M281.52 17.73L281.53 17.69" stroke="black" stroke-width="0.5" />
    <path d="M281.53 17.69L281.55 17.66" stroke="black" stroke-width="0.5" />
    <path d="M281.55 17.66L281.57 17.62" stroke="black" stroke-width="0.5" />
    <path d="M281.57 17.62L281.58 17.59" stroke="black" stroke-width="0.5" />
    <path d="M281.58 17.59L281.59 17.55" stroke="black" stroke-width="0.5" />
    <path d="M281.59 17.55L281.61 17.52" stroke="black" stroke-width="0.5" />
    <path d="M281.61 17.52L281.62 17.48" stroke="black" stroke-width="0.5" />
    <path d="M281.62 17.48L281.63 17.45" stroke="black" stroke-width="0.5" />
    <path d="M281.63 17.45L281.66 17.37" stroke="black" stroke-width="0.5" />
    <path d="M281.66 17.37L281.68 17.3" stroke="black" stroke-width="0.5" />
    <path d="M281.68 17.3L281.7 17.22" stroke="black" stroke-width="0.5" />
    <path d="M281.7 17.22L281.71 17.15" stroke="black" stroke-width="0.5" />
    <path d="M281.71 17.15L281.72 17.07" stroke="black" stroke-width="0.5" />
    <path d="M281.72 17.07L281.73 17" stroke="black" stroke-width="0.5" />
    <path d="M281.73 17L281.74 16.92" stroke="black" stroke-width="0.5" />
    <path d="M281.74 16.92V16.88" stroke="black" stroke-width="0.5" />
    <path d="M281.74 16.88V16.84" stroke="black" stroke-width="0.5" />
    <path d="M281.74 16.84L281.75 16.81" stroke="black" stroke-width="0.5" />
    <path d="M281.75 16.81V16.77" stroke="black" stroke-width="0.5" />
    <path d="M281.75 16.77V16.73" stroke="black" stroke-width="0.5" />
    <path d="M281.75 16.73L281.74 16.69" stroke="black" stroke-width="0.5" />
    <path d="M281.74 16.69V16.65" stroke="black" stroke-width="0.5" />
    <path d="M281.74 16.65V16.62" stroke="black" stroke-width="0.5" />
    <path d="M281.74 16.62L281.73 16.54" stroke="black" stroke-width="0.5" />
    <path d="M281.73 16.54L281.72 16.46" stroke="black" stroke-width="0.5" />
    <path d="M281.72 16.46L281.71 16.39" stroke="black" stroke-width="0.5" />
    <path d="M281.71 16.39L281.7 16.31" stroke="black" stroke-width="0.5" />
    <path d="M281.7 16.31L281.68 16.24" stroke="black" stroke-width="0.5" />
    <path d="M281.68 16.24L281.66 16.16" stroke="black" stroke-width="0.5" />
    <path d="M281.66 16.16L281.63 16.09" stroke="black" stroke-width="0.5" />
    <path d="M281.63 16.09L281.62 16.05" stroke="black" stroke-width="0.5" />
    <path d="M281.62 16.05L281.61 16.02" stroke="black" stroke-width="0.5" />
    <path d="M281.61 16.02L281.59 15.98" stroke="black" stroke-width="0.5" />
    <path d="M281.59 15.98L281.58 15.95" stroke="black" stroke-width="0.5" />
    <path d="M281.58 15.95L281.57 15.91" stroke="black" stroke-width="0.5" />
    <path d="M281.57 15.91L281.55 15.88" stroke="black" stroke-width="0.5" />
    <path d="M281.55 15.88L281.53 15.84" stroke="black" stroke-width="0.5" />
    <path d="M281.53 15.84L281.52 15.81" stroke="black" stroke-width="0.5" />
    <path d="M281.52 15.81L281.5 15.77" stroke="black" stroke-width="0.5" />
    <path d="M281.5 15.77L281.48 15.74" stroke="black" stroke-width="0.5" />
    <path d="M281.48 15.74L281.46 15.71" stroke="black" stroke-width="0.5" />
    <path d="M281.46 15.71L281.44 15.68" stroke="black" stroke-width="0.5" />
    <path d="M281.44 15.68L281.42 15.65" stroke="black" stroke-width="0.5" />
    <path d="M281.42 15.65L281.4 15.62" stroke="black" stroke-width="0.5" />
    <path d="M281.4 15.62L281.38 15.59" stroke="black" stroke-width="0.5" />
    <path d="M281.38 15.59L281.36 15.56" stroke="black" stroke-width="0.5" />
    <path d="M281.36 15.56L281.34 15.53" stroke="black" stroke-width="0.5" />
    <path d="M281.34 15.53L281.31 15.5" stroke="black" stroke-width="0.5" />
    <path d="M281.31 15.5L281.29 15.47" stroke="black" stroke-width="0.5" />
    <path d="M281.29 15.47L281.27 15.45" stroke="black" stroke-width="0.5" />
    <path d="M281.27 15.45L281.24 15.42" stroke="black" stroke-width="0.5" />
    <path d="M281.24 15.42L281.22 15.4" stroke="black" stroke-width="0.5" />
    <path d="M281.22 15.4L281.19 15.37" stroke="black" stroke-width="0.5" />
    <path d="M281.19 15.37L281.16 15.35" stroke="black" stroke-width="0.5" />
    <path d="M281.16 15.35L281.14 15.32" stroke="black" stroke-width="0.5" />
    <path d="M281.14 15.32L281.11 15.3" stroke="black" stroke-width="0.5" />
    <path d="M281.11 15.3L281.08 15.28" stroke="black" stroke-width="0.5" />
    <path d="M281.08 15.28L281.05 15.26" stroke="black" stroke-width="0.5" />
    <path d="M281.05 15.26L281.03 15.24" stroke="black" stroke-width="0.5" />
    <path d="M281.03 15.24L281 15.22" stroke="black" stroke-width="0.5" />
    <path d="M281 15.22L280.97 15.21" stroke="black" stroke-width="0.5" />
    <path d="M280.97 15.21L280.94 15.19" stroke="black" stroke-width="0.5" />
    <path d="M280.94 15.19L280.9 15.17" stroke="black" stroke-width="0.5" />
    <path d="M280.9 15.17L280.87 15.16" stroke="black" stroke-width="0.5" />
    <path d="M280.87 15.16L280.84 15.15" stroke="black" stroke-width="0.5" />
    <path d="M280.84 15.15L280.81 15.13" stroke="black" stroke-width="0.5" />
    <path d="M280.81 15.13L280.78 15.12" stroke="black" stroke-width="0.5" />
    <path d="M280.78 15.12L280.74 15.11" stroke="black" stroke-width="0.5" />
    <path d="M280.74 15.11L280.71 15.1" stroke="black" stroke-width="0.5" />
    <path d="M280.71 15.1H280.67" stroke="black" stroke-width="0.5" />
    <path d="M280.67 15.1L280.64 15.09" stroke="black" stroke-width="0.5" />
    <path d="M280.64 15.09L280.6 15.08" stroke="black" stroke-width="0.5" />
    <path d="M280.6 15.08H280.57" stroke="black" stroke-width="0.5" />
    <path d="M280.57 15.08H280.53" stroke="black" stroke-width="0.5" />
    <path d="M280.53 15.08L280.49 15.07" stroke="black" stroke-width="0.5" />
    <path d="M280.49 15.07H280.46" stroke="black" stroke-width="0.5" />
    <path d="M280.46 15.07H280.41" stroke="black" stroke-width="0.5" />
    <path d="M280.41 15.07L280.37 15.08" stroke="black" stroke-width="0.5" />
    <path d="M280.37 15.08H280.33" stroke="black" stroke-width="0.5" />
    <path d="M280.33 15.08L280.29 15.09" stroke="black" stroke-width="0.5" />
    <path d="M280.29 15.09L280.25 15.1" stroke="black" stroke-width="0.5" />
    <path d="M280.25 15.1L280.21 15.11" stroke="black" stroke-width="0.5" />
    <path d="M280.21 15.11L280.17 15.12" stroke="black" stroke-width="0.5" />
    <path d="M280.17 15.12L280.13 15.14" stroke="black" stroke-width="0.5" />
    <path d="M280.13 15.14L280.1 15.15" stroke="black" stroke-width="0.5" />
    <path d="M280.1 15.15L280.06 15.17" stroke="black" stroke-width="0.5" />
    <path d="M280.06 15.17L280.03 15.19" stroke="black" stroke-width="0.5" />
    <path d="M280.03 15.19L280 15.22" stroke="black" stroke-width="0.5" />
    <path d="M280 15.22L279.96 15.24" stroke="black" stroke-width="0.5" />
    <path d="M279.96 15.24L279.93 15.27" stroke="black" stroke-width="0.5" />
    <path d="M279.93 15.27L279.9 15.3" stroke="black" stroke-width="0.5" />
    <path d="M279.9 15.3L279.87 15.33" stroke="black" stroke-width="0.5" />
    <path d="M279.87 15.33L279.84 15.37" stroke="black" stroke-width="0.5" />
    <path d="M279.84 15.37L279.81 15.4" stroke="black" stroke-width="0.5" />
    <path d="M279.81 15.4L279.79 15.44" stroke="black" stroke-width="0.5" />
    <path d="M279.79 15.44L279.76 15.48" stroke="black" stroke-width="0.5" />
    <path d="M279.76 15.48L279.73 15.52" stroke="black" stroke-width="0.5" />
    <path d="M279.73 15.52L279.71 15.57" stroke="black" stroke-width="0.5" />
    <path d="M279.71 15.57L279.68 15.61" stroke="black" stroke-width="0.5" />
    <path d="M279.68 15.61L279.66 15.66" stroke="black" stroke-width="0.5" />
    <path d="M279.66 15.66L279.64 15.71" stroke="black" stroke-width="0.5" />
    <path d="M279.64 15.71L279.62 15.76" stroke="black" stroke-width="0.5" />
    <path d="M279.62 15.76L279.6 15.82" stroke="black" stroke-width="0.5" />
    <path d="M279.6 15.82L279.58 15.88" stroke="black" stroke-width="0.5" />
    <path d="M279.58 15.88L279.56 15.94" stroke="black" stroke-width="0.5" />
    <path d="M279.56 15.94L279.54 16" stroke="black" stroke-width="0.5" />
    <path d="M279.54 16L279.53 16.06" stroke="black" stroke-width="0.5" />
    <path d="M279.53 16.06L279.51 16.13" stroke="black" stroke-width="0.5" />
    <path d="M280.43 14.71L281.16 14.89" stroke="black" stroke-width="0.5" />
    <path d="M281.16 14.89L281.76 15.39" stroke="black" stroke-width="0.5" />
    <path d="M281.76 15.39L282.13 16.13" stroke="black" stroke-width="0.5" />
    <path d="M282.13 16.13L282.21 16.98" stroke="black" stroke-width="0.5" />
    <path d="M282.21 16.98L281.98 17.8" stroke="black" stroke-width="0.5" />
    <path d="M281.98 17.8L281.48 18.43" stroke="black" stroke-width="0.5" />
    <path d="M281.48 18.43L280.8 18.78" stroke="black" stroke-width="0.5" />
    <path d="M280.8 18.78H280.05" stroke="black" stroke-width="0.5" />
    <path d="M280.05 18.78L279.37 18.43" stroke="black" stroke-width="0.5" />
    <path d="M279.37 18.43L278.87 17.8" stroke="black" stroke-width="0.5" />
    <path d="M278.87 17.8L278.64 16.98" stroke="black" stroke-width="0.5" />
    <path d="M278.64 16.98L278.72 16.13" stroke="black" stroke-width="0.5" />
    <path d="M278.72 16.13L279.09 15.39" stroke="black" stroke-width="0.5" />
    <path d="M279.09 15.39L279.7 14.89" stroke="black" stroke-width="0.5" />
    <path d="M279.7 14.89L280.43 14.71" stroke="black" stroke-width="0.5" />
    <path d="M279.5 17.37L279.51 16.13" stroke="black" stroke-width="0.5" />
    <path d="M282.95 149.54H289.33" stroke="black" stroke-width="0.5" />
    <path d="M297.05 149.54H290.66" stroke="black" stroke-width="0.5" />
    <path d="M282.75 8.81H299.77" stroke="black" stroke-width="0.5" />
    <path d="M94.82 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M105.46 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M139.03 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M128.39 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M128.39 120.65H123.11" stroke="black" stroke-width="0.5" />
    <path d="M128.39 119.32H121.78" stroke="black" stroke-width="0.5" />
    <path d="M155.26 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M165.9 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M213.34 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M223.97 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M281.89 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M271.25 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M298.11 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M308.75 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M226.3 67.59H227.63" stroke="black" stroke-width="0.5" />
    <path d="M227.63 67.59V64.67" stroke="black" stroke-width="0.5" />
    <path d="M275.5 81.68H275.31" stroke="black" stroke-width="0.5" />
    <path d="M276.37 59.09V55.9" stroke="black" stroke-width="0.5" />
    <path d="M274.44 59.09V55.9" stroke="black" stroke-width="0.5" />
    <path d="M274.44 55.9H276.37" stroke="black" stroke-width="0.5" />
    <path d="M274.44 59.09H276.37" stroke="black" stroke-width="0.5" />
    <path d="M275.31 55.9V59.09" stroke="black" stroke-width="0.5" />
    <path d="M275.5 55.9V59.09" stroke="black" stroke-width="0.5" />
    <path d="M275.5 55.9H275.31" stroke="black" stroke-width="0.5" />
    <path d="M155.94 67.59H139.6" stroke="black" stroke-width="0.5" />
    <path d="M78.03 130.73L76.07 130.54" stroke="black" stroke-width="0.5" />
    <path d="M76.07 130.54L74.18 129.96" stroke="black" stroke-width="0.5" />
    <path d="M74.18 129.96L72.43 129.03" stroke="black" stroke-width="0.5" />
    <path d="M72.43 129.03L70.91 127.78" stroke="black" stroke-width="0.5" />
    <path d="M70.91 127.78L69.65 126.25" stroke="black" stroke-width="0.5" />
    <path d="M69.65 126.25L68.72 124.51" stroke="black" stroke-width="0.5" />
    <path d="M68.72 124.51L68.15 122.62" stroke="black" stroke-width="0.5" />
    <path d="M68.15 122.62L67.95 120.65" stroke="black" stroke-width="0.5" />
    <path d="M78.1 120.65H78.59" stroke="black" stroke-width="0.5" />
    <path d="M78.59 120.65V130.73" stroke="black" stroke-width="0.5" />
    <path d="M78.59 130.73H78.1" stroke="black" stroke-width="0.5" />
    <path d="M78.1 130.73V120.65" stroke="black" stroke-width="0.5" />
    <path d="M78.59 120.65H79.13" stroke="black" stroke-width="0.5" />
    <path d="M79.13 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M79.13 119.32H78.59" stroke="black" stroke-width="0.5" />
    <path d="M78.59 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M67.42 120.65H67.95" stroke="black" stroke-width="0.5" />
    <path d="M67.95 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M67.95 119.32H67.42" stroke="black" stroke-width="0.5" />
    <path d="M67.42 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M94.82 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M105.46 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M95.38 130.73L97.34 130.54" stroke="black" stroke-width="0.5" />
    <path d="M97.34 130.54L99.23 129.96" stroke="black" stroke-width="0.5" />
    <path d="M99.23 129.96L100.98 129.03" stroke="black" stroke-width="0.5" />
    <path d="M100.98 129.03L102.5 127.78" stroke="black" stroke-width="0.5" />
    <path d="M102.5 127.78L103.76 126.25" stroke="black" stroke-width="0.5" />
    <path d="M103.76 126.25L104.69 124.51" stroke="black" stroke-width="0.5" />
    <path d="M104.69 124.51L105.26 122.62" stroke="black" stroke-width="0.5" />
    <path d="M105.26 122.62L105.46 120.65" stroke="black" stroke-width="0.5" />
    <path d="M95.31 120.65H94.82" stroke="black" stroke-width="0.5" />
    <path d="M94.82 120.65V130.73" stroke="black" stroke-width="0.5" />
    <path d="M94.82 130.73H95.31" stroke="black" stroke-width="0.5" />
    <path d="M95.31 130.73V120.65" stroke="black" stroke-width="0.5" />
    <path d="M94.82 120.65H94.28" stroke="black" stroke-width="0.5" />
    <path d="M94.28 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M94.28 119.32H94.82" stroke="black" stroke-width="0.5" />
    <path d="M94.82 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M105.99 120.65H105.46" stroke="black" stroke-width="0.5" />
    <path d="M105.46 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M105.46 119.32H105.99" stroke="black" stroke-width="0.5" />
    <path d="M105.99 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M139.03 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M128.39 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M138.47 130.73L136.51 130.54" stroke="black" stroke-width="0.5" />
    <path d="M136.51 130.54L134.62 129.96" stroke="black" stroke-width="0.5" />
    <path d="M134.62 129.96L132.87 129.03" stroke="black" stroke-width="0.5" />
    <path d="M132.87 129.03L131.35 127.78" stroke="black" stroke-width="0.5" />
    <path d="M131.35 127.78L130.09 126.25" stroke="black" stroke-width="0.5" />
    <path d="M130.09 126.25L129.16 124.51" stroke="black" stroke-width="0.5" />
    <path d="M129.16 124.51L128.59 122.62" stroke="black" stroke-width="0.5" />
    <path d="M128.59 122.62L128.39 120.65" stroke="black" stroke-width="0.5" />
    <path d="M138.54 120.65H139.03" stroke="black" stroke-width="0.5" />
    <path d="M139.03 120.65V130.73" stroke="black" stroke-width="0.5" />
    <path d="M139.03 130.73H138.54" stroke="black" stroke-width="0.5" />
    <path d="M138.54 130.73V120.65" stroke="black" stroke-width="0.5" />
    <path d="M139.03 120.65H139.56" stroke="black" stroke-width="0.5" />
    <path d="M139.56 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M139.56 119.32H139.03" stroke="black" stroke-width="0.5" />
    <path d="M139.03 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M127.86 120.65H128.39" stroke="black" stroke-width="0.5" />
    <path d="M128.39 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M128.39 119.32H127.86" stroke="black" stroke-width="0.5" />
    <path d="M127.86 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M155.26 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M165.9 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M155.26 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M165.9 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M155.82 130.73L157.78 130.54" stroke="black" stroke-width="0.5" />
    <path d="M157.78 130.54L159.67 129.96" stroke="black" stroke-width="0.5" />
    <path d="M159.67 129.96L161.42 129.03" stroke="black" stroke-width="0.5" />
    <path d="M161.42 129.03L162.94 127.78" stroke="black" stroke-width="0.5" />
    <path d="M162.94 127.78L164.2 126.25" stroke="black" stroke-width="0.5" />
    <path d="M164.2 126.25L165.13 124.51" stroke="black" stroke-width="0.5" />
    <path d="M165.13 124.51L165.7 122.62" stroke="black" stroke-width="0.5" />
    <path d="M165.7 122.62L165.9 120.65" stroke="black" stroke-width="0.5" />
    <path d="M155.75 120.65H155.26" stroke="black" stroke-width="0.5" />
    <path d="M155.26 120.65V130.73" stroke="black" stroke-width="0.5" />
    <path d="M155.26 130.73H155.75" stroke="black" stroke-width="0.5" />
    <path d="M155.75 130.73V120.65" stroke="black" stroke-width="0.5" />
    <path d="M155.26 120.65H154.72" stroke="black" stroke-width="0.5" />
    <path d="M154.72 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M154.72 119.32H155.26" stroke="black" stroke-width="0.5" />
    <path d="M155.26 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M166.43 120.65H165.9" stroke="black" stroke-width="0.5" />
    <path d="M165.9 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M165.9 119.32H166.43" stroke="black" stroke-width="0.5" />
    <path d="M166.43 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M196.55 130.73L194.59 130.54" stroke="black" stroke-width="0.5" />
    <path d="M194.59 130.54L192.7 129.96" stroke="black" stroke-width="0.5" />
    <path d="M192.7 129.96L190.95 129.03" stroke="black" stroke-width="0.5" />
    <path d="M190.95 129.03L189.43 127.78" stroke="black" stroke-width="0.5" />
    <path d="M189.43 127.78L188.17 126.25" stroke="black" stroke-width="0.5" />
    <path d="M188.17 126.25L187.24 124.51" stroke="black" stroke-width="0.5" />
    <path d="M187.24 124.51L186.67 122.62" stroke="black" stroke-width="0.5" />
    <path d="M186.67 122.62L186.47 120.65" stroke="black" stroke-width="0.5" />
    <path d="M196.62 120.65H197.11" stroke="black" stroke-width="0.5" />
    <path d="M197.11 120.65V130.73" stroke="black" stroke-width="0.5" />
    <path d="M197.11 130.73H196.62" stroke="black" stroke-width="0.5" />
    <path d="M196.62 130.73V120.65" stroke="black" stroke-width="0.5" />
    <path d="M197.11 120.65H197.64" stroke="black" stroke-width="0.5" />
    <path d="M197.64 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M197.64 119.32H197.11" stroke="black" stroke-width="0.5" />
    <path d="M197.11 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M185.94 120.65H186.47" stroke="black" stroke-width="0.5" />
    <path d="M186.47 120.65V119.32" stroke="black" stroke-width="0.5" />
    <path d="M186.47 119.32H185.94" stroke="black" stroke-width="0.5" />
    <path d="M185.94 119.32V120.65" stroke="black" stroke-width="0.5" />
    <path d="M213.9 136.02L215.86 135.82" stroke="black" stroke-width="0.5" />
    <path d="M215.86 135.82L217.75 135.25" stroke="black" stroke-width="0.5" />
    <path d="M217.75 135.25L219.5 134.32" stroke="black" stroke-width="0.5" />
    <path d="M219.5 134.32L221.02 133.06" stroke="black" stroke-width="0.5" />
    <path d="M221.02 133.06L222.28 131.54" stroke="black" stroke-width="0.5" />
    <path d="M222.28 131.54L223.21 129.79" stroke="black" stroke-width="0.5" />
    <path d="M223.21 129.79L223.78 127.9" stroke="black" stroke-width="0.5" />
    <path d="M223.78 127.9L223.97 125.94" stroke="black" stroke-width="0.5" />
    <path d="M213.83 125.94H213.34" stroke="black" stroke-width="0.5" />
    <path d="M213.34 125.94V136.02" stroke="black" stroke-width="0.5" />
    <path d="M213.34 136.02H213.83" stroke="black" stroke-width="0.5" />
    <path d="M213.83 136.02V125.94" stroke="black" stroke-width="0.5" />
    <path d="M213.34 125.94H212.8" stroke="black" stroke-width="0.5" />
    <path d="M212.8 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M212.8 124.61H213.34" stroke="black" stroke-width="0.5" />
    <path d="M213.34 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M224.51 125.94H223.97" stroke="black" stroke-width="0.5" />
    <path d="M223.97 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M223.97 124.61H224.51" stroke="black" stroke-width="0.5" />
    <path d="M224.51 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M281.33 136.02L279.36 135.82" stroke="black" stroke-width="0.5" />
    <path d="M279.36 135.82L277.47 135.25" stroke="black" stroke-width="0.5" />
    <path d="M277.47 135.25L275.73 134.32" stroke="black" stroke-width="0.5" />
    <path d="M275.73 134.32L274.2 133.06" stroke="black" stroke-width="0.5" />
    <path d="M274.2 133.06L272.95 131.54" stroke="black" stroke-width="0.5" />
    <path d="M272.95 131.54L272.02 129.79" stroke="black" stroke-width="0.5" />
    <path d="M272.02 129.79L271.44 127.9" stroke="black" stroke-width="0.5" />
    <path d="M271.44 127.9L271.25 125.94" stroke="black" stroke-width="0.5" />
    <path d="M281.4 125.94H281.89" stroke="black" stroke-width="0.5" />
    <path d="M281.89 125.94V136.02" stroke="black" stroke-width="0.5" />
    <path d="M281.89 136.02H281.4" stroke="black" stroke-width="0.5" />
    <path d="M281.4 136.02V125.94" stroke="black" stroke-width="0.5" />
    <path d="M281.89 125.94H282.42" stroke="black" stroke-width="0.5" />
    <path d="M282.42 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M282.42 124.61H281.89" stroke="black" stroke-width="0.5" />
    <path d="M281.89 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M270.72 125.94H271.25" stroke="black" stroke-width="0.5" />
    <path d="M271.25 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M271.25 124.61H270.72" stroke="black" stroke-width="0.5" />
    <path d="M270.72 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M298.11 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M308.75 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M298.67 136.02L300.64 135.82" stroke="black" stroke-width="0.5" />
    <path d="M300.64 135.82L302.53 135.25" stroke="black" stroke-width="0.5" />
    <path d="M302.53 135.25L304.27 134.32" stroke="black" stroke-width="0.5" />
    <path d="M304.27 134.32L305.8 133.06" stroke="black" stroke-width="0.5" />
    <path d="M305.8 133.06L307.05 131.54" stroke="black" stroke-width="0.5" />
    <path d="M307.05 131.54L307.98 129.79" stroke="black" stroke-width="0.5" />
    <path d="M307.98 129.79L308.56 127.9" stroke="black" stroke-width="0.5" />
    <path d="M308.56 127.9L308.75 125.94" stroke="black" stroke-width="0.5" />
    <path d="M298.6 125.94H298.11" stroke="black" stroke-width="0.5" />
    <path d="M298.11 125.94V136.02" stroke="black" stroke-width="0.5" />
    <path d="M298.11 136.02H298.6" stroke="black" stroke-width="0.5" />
    <path d="M298.6 136.02V125.94" stroke="black" stroke-width="0.5" />
    <path d="M298.11 125.94H297.58" stroke="black" stroke-width="0.5" />
    <path d="M297.58 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M297.58 124.61H298.11" stroke="black" stroke-width="0.5" />
    <path d="M298.11 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M309.28 125.94H308.75" stroke="black" stroke-width="0.5" />
    <path d="M308.75 125.94V124.61" stroke="black" stroke-width="0.5" />
    <path d="M308.75 124.61H309.28" stroke="black" stroke-width="0.5" />
    <path d="M309.28 124.61V125.94" stroke="black" stroke-width="0.5" />
    <path d="M79.66 159.38V145.02" stroke="black" stroke-width="0.5" />
    <path d="M93.75 146.19V160.55" stroke="black" stroke-width="0.5" />
    <path d="M140.1 146.18V160.55" stroke="black" stroke-width="0.5" />
    <path d="M154.19 146.18V160.55" stroke="black" stroke-width="0.5" />
    <path d="M198.18 163.74V149.38" stroke="black" stroke-width="0.5" />
    <path d="M282.95 149.54V163.9" stroke="black" stroke-width="0.5" />
    <path d="M297.05 149.54V163.9" stroke="black" stroke-width="0.5" />
    <path d="M281.52 107.32V107.59" stroke="black" stroke-width="0.5" />
    <path d="M262.37 107.32V107.59" stroke="black" stroke-width="0.5" />
    <path d="M262.37 107.59L269.14 114.36" stroke="black" stroke-width="0.5" />
    <path d="M269.14 114.36L269.33 114.17" stroke="black" stroke-width="0.5" />
    <path d="M269.33 114.17L262.56 107.4" stroke="black" stroke-width="0.5" />
    <path d="M262.56 107.4L262.37 107.59" stroke="black" stroke-width="0.5" />
    <path
      d="M269.14 114.36C270.03 113.471 270.736 112.416 271.219 111.255C271.701 110.093 271.949 108.848 271.95 107.59"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M281.52 107.59L274.75 114.36" stroke="black" stroke-width="0.5" />
    <path d="M274.75 114.36L274.56 114.17" stroke="black" stroke-width="0.5" />
    <path d="M274.56 114.17L281.33 107.4" stroke="black" stroke-width="0.5" />
    <path d="M281.33 107.4L281.52 107.59" stroke="black" stroke-width="0.5" />
    <path
      d="M271.95 107.59C271.949 110.129 272.956 112.564 274.75 114.36"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M301.1 108.65H311.21" stroke="black" stroke-width="0.5" />
    <path d="M301.1 107.32H309.88" stroke="black" stroke-width="0.5" />
    <path d="M311.21 93.79V108.65" stroke="black" stroke-width="0.5" />
    <path d="M309.88 95.12V107.32" stroke="black" stroke-width="0.5" />
    <path d="M301.1 107.32V108.65" stroke="black" stroke-width="0.5" />
    <path d="M123.64 102.03H144.45" stroke="black" stroke-width="0.5" />
    <path d="M123.64 103.36H145.78" stroke="black" stroke-width="0.5" />
    <path d="M123.64 102.03V103.36" stroke="black" stroke-width="0.5" />
  </svg>
</template>
