const getNotificationsListBySite = (state) => state.notificationsListBySite

const getNotificationsCounterBySite = (state) =>
  state.notificationsCounterBySite

const getNotificationsSearchTerm = (state) => state.notificationsSearchTerm

const getNotificationsIsLoading = (state) => state.notificationsIsLoading

const getSitesList = (state) => state.sitesList

export default {
  getNotificationsListBySite,
  getNotificationsCounterBySite,
  getNotificationsSearchTerm,
  getNotificationsIsLoading,
  getSitesList
}
