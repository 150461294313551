<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg1"
    viewBox="0 0 152 98"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="M1 97V54H10.5C11.4275 59.8948 15.5 63.5 20.5 63.5V54V0.5H71V28H84V40.5H71V42H85V59H95C96 65 99.5 69 105.5 69V59H122.5V69C126.5 69 132 65.5 133 59H151V95.5H150V97H1Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
