const apiVersion = 'v1';

const getPowerControlLatestUsage = async (site) => {
  try {
    const { id } = site;
    const response = await window.axios.get(
      `/${apiVersion}/PowerControls/latest-readings`,
      { params: { siteId: id } }
    );
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deletePowerControl = async (powerControlId) => {
  try {
    await window.axios.delete(
      `/${apiVersion}/PowerControls/${powerControlId}`
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

const setPowerControlData = async (powerControl) => {
  try {
    const response = await window.axios.put(
      `/${apiVersion}/PowerControls/${powerControl.powerControlId}/settings`,
      powerControl
    );
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetPowerControlSettingsMessages = async (powerControlId) => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/PowerControls/${powerControlId}/history`
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getPowerControlLatestUsage,
  deletePowerControl,
  setPowerControlData,
  GetPowerControlSettingsMessages,
};
