import API from '@/Core.Service.Domain/ViewSupport/Notifications/API';

const getNotificationsBySiteId = async (context, siteId) => {
  const notificationsListBySite = await API.getNotificationsBySiteId(siteId)
  context.commit('NOTIFICATIONS_LIST_BY_SITE', notificationsListBySite)

  context.commit('NOTIFICATIONS_IS_LOADING', false)
}

const updateNotification = async (context, notification) => {
  const { siteId } = notification
  let notificationsCounter = 0

  try {
    //we expect back a counter of unread notifications
    notificationsCounter = await API.updateNotification(notification)
    context.commit('NOTIFICATIONS_COUNTER_BY_SITE', {
      siteId,
      notificationsCounter,
    })
  } catch (error) {
    Promise.reject(error)
  }
}

const setSitesList = async (context) => {

  try {
    //we expect back a counter of unread notifications
    const sitesList = await API.getSitesList()
    context.commit('SET_SITES_LIST', 
      sitesList
    )
  } catch (error) {
    Promise.reject(error)
  }
}
export default {
  getNotificationsBySiteId,
  updateNotification,
  setSitesList
}
