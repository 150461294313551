<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <component :is="currentComponent" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AginovaMonitorBar from '@/Core.Service.Domain/Monitors/Aginova/Components/AginovaMonitorBar.vue';
import TitleBar from '@/Core.Service.Domain/Monitors/Monitors.Common/Components/TitleBar.vue';

export default {
  name: 'MonitorsView',

  components: {
    AginovaMonitors: AginovaMonitorBar,
    TitleBar,
  },

  data(instance) {
    return {
      currentComponent: instance.$route.meta.monitorsTypeName,
    };
  },

  watch: {
    $route() {
      this.currentComponent = this.$route.meta.monitorsTypeName;
    },
  },
};
</script>
