import { ref } from 'vue';

import {
  IFloor,
  IVAV,
} from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

import FirstFloor from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_1st_Floor/floor.ts';
import SecondFloor from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_2nd_Floor/floor.ts';
import ThirdFloor from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_3rd_Floor/floor.ts';

export const dialog = ref<boolean>(false);
export const childTab = ref<number>(0);
export const selectedVAVId = ref<number>();

export const isClickFromCommonArea = ref<boolean>(false);

export const vav = ref<IVAV>({} as IVAV);

export const floors = ref<IFloor<Record<string, unknown>>[]>([
  { ...FirstFloor },
  { ...SecondFloor },
  { ...ThirdFloor },
]);
