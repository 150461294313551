<template>
  <v-dialog v-model="dialogValue" persistent max-width="500">
    <AdminAction
      :controlId="powerControlInfo.controlId"
      :controlType="'power-controls'"
      :siteId="powerControlInfo.controlInfo.site.id"
      @ok="dialogValue = false"
      @cancel="dialogValue = false"
    />
  </v-dialog>
</template>

<script>
// AdminAction is not the final component. It should be refactored to the new domain's structure.
import AdminAction from '@/modules/controls/_components/AdminAction.vue';

import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

export default {
  name: 'ConfirmDialogAdminPowerControl',

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    powerControlInfo: {
      type: Object,
      required: true,
    },
  },

  created() {
    eventBus.$on('ok', () => {
      this.handleOk();
    });

    eventBus.$on('cancel', () => {
      this.handleCancel();
    });
  },

  components: {
    AdminAction,
  },

  data() {
    return {
      loadingDelete: false,
    };
  },

  methods: {
    handleOk() {
      this.dialogValue = false;
    },

    handleCancel() {
      this.dialogValue = false;
    },
  },

  computed: {
    dialogValue: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
};
</script>
