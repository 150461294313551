//Power Details
//Power Heatmap
//Site Slicer - vwSite - SiteName
//Electricity Date Slicer - vwReportingDateTime - Date
//Billing

import constant from '../constants';

const setSlicers = async function (reportParameters, visuals) {
  try {
    await setSiteSlicerVisual(reportParameters, visuals);
    await setDateSlicerVisual(reportParameters, visuals);
  } catch (error) {
    return Promise.reject(error);
  }
};

const setSiteSlicerVisual = async function (reportParameters, visuals) {
  const siteSlicerFilter = {
    $schema: constant.ADVANCED_FILTER,
    target: {
      table: 'vwSite',
      column: 'SiteName',
    },
    operator: 'In',
    values: [reportParameters.SiteName],
  };
  const siteSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState({
      filters: [siteSlicerFilter],
    });
  }
};

const setDateSlicerVisual = async function (reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: constant.ADVANCED_FILTER,
    target: {
      table: 'vwReportingDateTime',
      column: 'Date',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.ElectricityStartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.ElectricityEndDate,
      },
    ],
  };

  const regex = /Date\s+Slicer/gi;
  const dateSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && regex.test(visual.title);
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
  }
};

export default {
  setSlicers,
};
