import { markRaw } from 'vue';

import VAV259Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite221/VAV259Drawing.vue';
import VAV260Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite221/VAV260Drawing.vue';
import VAV261Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite221/VAV261Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

import { IHotspot } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList: IHotspot<Record<string, unknown>>[] = [
  {
    id: 0,
    name: 'VAV 2-59',
    controlId: 2230,
    component: markRaw(VAV259Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-59',
      },
    },
    style: {
      position: 'absolute',
      top: '36.5%',
      left: '33.9%',
      width: '71.5%',
      height: '71.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '70%',
      left: '75%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 2-60',
    controlId: 2231,
    component: markRaw(VAV260Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-60',
      },
    },
    style: {
      position: 'absolute',
      top: '9.1%',
      left: '-3.2%',
      width: '94%',
      height: '94%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '70%',
      left: '35%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 2-61',
    controlId: 2232,
    component: markRaw(VAV261Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-61',
      },
    },
    style: {
      position: 'absolute',
      top: '9.1%',
      left: '56.5%',
      width: '56.8%',
      height: '56.8%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '23%',
      left: '82%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
