import { AginovaMonitorsRoute } from '@/Core.Service.Domain/Monitors/Aginova/Routes';
import MonitorsView from '@/Core.Service.Domain/Monitors/Monitors.Common/Views';

const MonitorsRoute = {
  path: 'monitors',
  name: 'Monitors',
  children: [AginovaMonitorsRoute],
  component: MonitorsView,
  meta: { permission: 'meters:read' },
};

export { MonitorsRoute };

