import { defineAbility } from '@casl/ability';

const ability = defineAbility((can) => {
  can('read', 'unconfigured');
});

const ACTIONS = ['READ', 'CREATE', 'UPDATE', 'DELETE'];

/**
 * @param {String} permission a string in format "action:model" or "model:action"
 * @returns a Rule object, with action and subject semantically set in either case.
 */
interface Rule {
  action: string;
  subject: string;
}

export function mapAuth0PermissionToRule(permission: string): Rule {
  const [action, subject] = permission.split(':');
  // the rule from auth0 are both read:Sites and Sites:read
  if (ACTIONS.some((x) => x === action.toUpperCase())) {
    // it's read:sites
    return { action: action, subject: subject };
  } else {
    // it's sites:read
    return { action: subject, subject: action };
  }
}

/**
 * Maps an array of Auth0 permissions into ability Rules.
 * @param {[String]} permissions array of Auth0 permissions
 * @see{@link mapAuth0PermissionToRule}
 *   for further parameters format info.
 */
export function mapAuth0PermissionsToAbilityRules(
  permissions: string[]
): Rule[] {
  return permissions.map((perm) => mapAuth0PermissionToRule(perm));
}

/**
 * Updates the abilities with a list Auth0 permissions.
 * @param {[string]} permissions array of Auth0 permissions in either
 * "action:model" or "model:action" format
 * (where action is one of create, read, update, delete).
 */
interface UpdateAbilitiesFromAuth0Permissions {
  (permissions: string[]): Promise<void>;
}

export const updateAbilitiesFromAuth0PermissionsComposable: UpdateAbilitiesFromAuth0Permissions =
  async (permissions) => {
    const updatedPermissions = mapAuth0PermissionsToAbilityRules(permissions);
    ability.update(updatedPermissions);
  };

export function useAbility() {
  return ability;
}
