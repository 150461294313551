<template>
  <Form ref="obs" v-slot="{ invalid }">
    <v-container grid-list-xl>
      <h1>Project Detail</h1>
      <form @submit.prevent="submit">
        <v-sheet elevation="2" class="pa-4">
          <v-text-field
            :rules="[(v) => !!v || 'Name is required']"
            v-model="project.projectName"
            label="Name"
          ></v-text-field>

          <v-text-field
            :rules="[(v) => !!v || 'Description is required']"
            v-model="project.description"
            label="Description"
          ></v-text-field>
          <v-select
            :rules="[(v) => !!v || 'Model is required']"
            :items="analyticModels"
            item-title="modelName"
            v-model="selectedModel"
            label="Associated Model"
            hint="select an analysis model for this project"
            persistent-hint
            return-object
            @change-value="setDefaultDateRange"
          ></v-select>
          <v-row>
            <v-col cols="auto" md="6" sm="12">
              <v-autocomplete
                :items="measureOptions"
                item-title="name"
                item-value="id"
                v-model="project.measures"
                label="Select Measure(s)"
                multiple
                persistent-hint
                hint="Select Measure(s) for project"
                ref="measureSelect"
                return-object
                @update:modelValue="handleSetMeasures"
                variant="solo"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <start-end-date-picker
            name="date range"
            v-model="dateRange"
            date-format="yyyy-MM-dd"
            :rules="[(v) => !!v || 'Start Date is required']"
          ></start-end-date-picker>
          <v-row>
            <label class="pa-3">Comments</label>
            <v-textarea
              rows="2"
              clearable
              clear-icon="mdi-close-circle"
              variant="outlined"
              auto-grow
              v-model="project.comment"
              class="pr-3"
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="invalid"
                >Save</v-btn
              >
              <v-btn
                @click="handleMeasureAssociations"
                color="secondary"
                class="ml-5"
                >Manage Measure Associations</v-btn
              >
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </form>
    </v-container>
    <span class="px-4 text-caption text-primary">
      {{ $appOldVersion }}
    </span>
  </Form>
</template>

<script>
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker';
import { Form, defineRule } from 'vee-validate';
import api from '../../projects/_api';

export default {
  name: 'EditProject',
  components: {
    'start-end-date-picker': StartEndDatePicker,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
  },

  data() {
    return {
      currentMode: {},
      dateRange: {},
      project: {
        projectName: '',
        description: '',
        analyticModel: {},
        startDateTime: '',
        endDateTime: '',
        site: {},
        measures: [],
      },
      projectName: '',
      analyticModels: [],
      measureOptions: [],
      selectedModel: {},
      siteOptions: [],
      siteId: null,
      loading: false,
    };
  },

  beforeCreate() {},

  created() {
    //this.getProjects();
    this.registerCustomValidators();
  },

  async mounted() {
    this.getProject();
  },

  computed: {},

  methods: {
    //PROJECT - START
    getProject() {
      this.loading = true;
      api
        .getAnalyticProject(this.$route.params.id)
        .then(async (response) => {
          this.project = this.mapProject(response);
          this.projectName = response.projectName;
          this.siteId = response.siteId;
          await this.getModels(response.modelId);
          await this.getMeasures();
          await this.getProjects(response.siteId);
          this.selectedMeasures = this.currentMeasure?.projectMeasures;
          this.dateRange = {
            startDateTime: response.projectStartDateTime.substring(0, 10),
            endDateTime: response.projectEndDateTime.substring(0, 10),
          };
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => (this.loading = false));
    },

    async getProjects(siteId) {
      this.projects = await api.getAnalyticProjects(siteId);
    },

    mapProject(proj) {
      return {
        id: proj.id,
        projectName: proj.projectName,
        description: proj.description,
        analyticModel: { name: proj.modelName, id: proj.modelId },
        startDateTime: new Date(proj.projectStartDateTime)
          .toISOString()
          .substring(0, 10),
        endDateTime: new Date(proj.projectEndDateTime)
          .toISOString()
          .substring(0, 10),
        comment: proj.comment,
        site: proj.site,
        measures: proj.measures,
        nonRoutineEvents: proj.nonRoutineEvents,
      };
    },
    //PROJECT - END

    //MODEL - START
    async getModels(analyticModelId) {
      let siteId = this.siteId;
      api
        .getAnalyticModels(siteId)
        .then((response) => {
          this.analyticModels = response;
          this.setSelectedModel(analyticModelId);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    mapModelLookup(models) {
      const makeOptionItems = (item) => {
        return {
          value: item.id,
          text: item.name,
        };
      };
      let ml = models.map(makeOptionItems);
      return ml;
    },

    setSelectedModel(id) {
      let cm = {};
      try {
        cm = this.analyticModels.find((m) => {
          return m.id === id;
        });
      } catch (error) {
        return Promise.reject(error);
      }
      if (cm) {
        this.selectedModel = cm;
      }
    },
    //MODEL - END

    //MEASURES - START
    async getMeasures() {
      api
        .getMeasures()
        .then((response) => {
          this.measureOptions = this.mapMeasures(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    mapMeasures(measures) {
      return measures.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },

    handleSetMeasures(selectedMeasures) {
      this.project.measures = selectedMeasures.map((x) => {
        return { ...x, selected: true };
      });
    },
    //MEASURES - END

    async submit() {
      this.project.projectStartDateTime = this.dateRange.startDateTime;
      this.project.projectEndDateTime = this.dateRange.endDateTime;
      this.project.site = {
        id: this.siteId,
      };

      this.project.modelName = this.selectedModel.modelName;
      this.project.modelId = this.selectedModel.id;

      this.update();
    },

    async update() {
      let valid = await this.$refs.obs.validate();

      if (valid) {
        await api.updateAnalyticProject(this.project);

        this.$toast.show('Project updated.', null, 'success');
        this.$router.push({ name: 'AnalyticsMandVProjectIndex' });
      }
    },

    registerCustomValidators() {
      defineRule('duplicate_name', (value, [field]) => {
        let valid = true;
        if (this.projects) {
          let existingNames = this.projects.map((a) => a.projectName);
          //filter project name out of list if in edit

          existingNames = existingNames.filter((n) => {
            return n !== this.projectName;
          });

          valid = existingNames.indexOf(value) === -1;
        }

        if (!valid) {
          return `${field} is already used by another project.  Enter a different name.`;
        }

        return valid;
      });
    },

    setDefaultDateRange(id) {
      if (this.loading !== true) {
        this.setSelectedModel(id);
      }
    },

    handleMeasureAssociations() {
      this.$router.push({
        name: 'AnalyticsMandVProjectMeasureIndex',
        id: this.$route.params.id,
      });
    },

    handleCancelCrud() {
      this.$router.push({ name: 'AnalyticsMandVProjectIndex' });
    },
  },
};
</script>
