import { markRaw } from 'vue';

import VAV324Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite310/VAV324Drawing.vue';
import VAV325Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite310/VAV325Drawing.vue';
import VAV326Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite310/VAV326Drawing.vue';
import VAV327Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite310/VAV327Drawing.vue';
import VAV328Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite310/VAV328Drawing.vue';
import VAV329Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite310/VAV329Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

import { IHotspot } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList: IHotspot<Record<string, unknown>>[] = [
  {
    id: 0,
    name: 'VAV 3-24',
    controlId: 2343,
    component: markRaw(VAV324Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-24',
      },
    },
    style: {
      position: 'absolute',
      top: '23.3%',
      left: '14.2%',
      width: '30%',
      height: '30%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '35%',
      left: '26%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 3-25',
    controlId: 2344,
    component: markRaw(VAV325Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-25',
      },
    },
    style: {
      position: 'absolute',
      top: '66.5%',
      left: '12.5%',
      width: '34.5%',
      height: '34.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '80%',
      left: '27%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 3-26',
    controlId: 2345,
    component: markRaw(VAV326Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-26',
      },
    },
    style: {
      position: 'absolute',
      top: '-1%',
      left: '36.8%',
      width: '66%',
      height: '66%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '20%',
      left: '54%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 3-27',
    controlId: 2346,
    component: markRaw(VAV327Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-27',
      },
    },
    style: {
      position: 'absolute',
      top: '69.75%',
      left: '75.5%',
      width: '32.7%',
      height: '32.7%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '85%',
      left: '88%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 4,
    name: 'VAV 3-28',
    controlId: 2347,
    component: markRaw(VAV328Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-28',
      },
    },
    style: {
      position: 'absolute',
      top: '23.4%',
      left: '36.8%',
      width: '65.85%',
      height: '65.85%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '50%',
      left: '60%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 5,
    name: 'VAV 3-29',
    controlId: 2348,
    component: markRaw(VAV329Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-29',
      },
    },
    style: {
      position: 'absolute',
      top: '53.2%',
      left: '36.5%',
      width: '42%',
      height: '42%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '75%',
      left: '50%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
