<template>
  <svg
    width="53"
    height="112"
    viewBox="0 0 53 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.3 5.07V2.94" stroke="black" stroke-width="0.5" />
    <path d="M40.56 2.94V5.07" stroke="black" stroke-width="0.5" />
    <path d="M40.56 5.07001H52.5" stroke="black" stroke-width="0.5" />
    <path d="M1.98999 109.09V111.75" stroke="black" stroke-width="0.5" />
    <path d="M1.98999 109.09V107.76" stroke="black" stroke-width="0.5" />
    <path d="M52.66 100.27V35.16" stroke="black" stroke-width="0.5" />
    <path d="M52.66 33.83V5.07001" stroke="black" stroke-width="0.5" />
    <path d="M36.7 70V67.7" stroke="black" stroke-width="0.5" />
    <path d="M36.7 58.16V35.16" stroke="black" stroke-width="0.5" />
    <path d="M35.37 68.67V67.7" stroke="black" stroke-width="0.5" />
    <path d="M35.37 58.18V35.16" stroke="black" stroke-width="0.5" />
    <path d="M26.03 109.09H0.400024" stroke="black" stroke-width="0.5" />
    <path d="M35.37 68.67H1.06" stroke="black" stroke-width="0.5" />
    <path d="M36.7 70H1.06" stroke="black" stroke-width="0.5" />
    <path d="M52.66 33.83H51.56" stroke="black" stroke-width="0.5" />
    <path d="M42.04 33.83H1.06" stroke="black" stroke-width="0.5" />
    <path d="M52.66 35.16H51.56" stroke="black" stroke-width="0.5" />
    <path d="M42.02 35.16H36.7" stroke="black" stroke-width="0.5" />
    <path d="M35.37 35.16H1.06" stroke="black" stroke-width="0.5" />
    <path d="M26.58 110.42H2" stroke="black" stroke-width="0.5" />
    <path d="M1.06 5.07001H11.3" stroke="black" stroke-width="0.5" />
    <path d="M3.58997 109.09V107.76" stroke="black" stroke-width="0.5" />
    <path d="M1.06 5.07001V33.83" stroke="black" stroke-width="0.5" />
    <path d="M1.06 35.16V68.67" stroke="black" stroke-width="0.5" />
    <path d="M1.06 70V109.09" stroke="black" stroke-width="0.5" />
    <path d="M40.56 2.94003V0.280029" stroke="black" stroke-width="0.5" />
    <path d="M11.3 2.94003V0.280029" stroke="black" stroke-width="0.5" />
    <path d="M11.3 2.94003V0.280029" stroke="black" stroke-width="0.5" />
    <path d="M11.3 5.07003V0.280029" stroke="black" stroke-width="0.5" />
    <path d="M11.3 0.280029H40.56" stroke="black" stroke-width="0.5" />
    <path d="M40.56 0.280029V5.07003" stroke="black" stroke-width="0.5" />
    <path d="M40.56 5.07001H11.3" stroke="black" stroke-width="0.5" />
    <path d="M11.3 2.94H40.56" stroke="black" stroke-width="0.5" />
    <path d="M11.3 3.20001H40.56" stroke="black" stroke-width="0.5" />
    <path d="M11.3 2.14001H40.56" stroke="black" stroke-width="0.5" />
    <path d="M11.83 2.94002V2.14001" stroke="black" stroke-width="0.5" />
    <path d="M15.82 2.94002V2.14001" stroke="black" stroke-width="0.5" />
    <path d="M16.35 2.94002V2.14001" stroke="black" stroke-width="0.5" />
    <path d="M36.04 2.94002V2.14001" stroke="black" stroke-width="0.5" />
    <path d="M35.5 2.94002V2.14001" stroke="black" stroke-width="0.5" />
    <path d="M40.02 2.94002V2.14001" stroke="black" stroke-width="0.5" />
    <path d="M26.08 2.94002V2.14001" stroke="black" stroke-width="0.5" />
    <path d="M25.55 2.94002V2.14001" stroke="black" stroke-width="0.5" />
    <path d="M51.33 100.27H52.66" stroke="black" stroke-width="0.5" />
    <path d="M41.22 101.6V100.27" stroke="black" stroke-width="0.5" />
    <path d="M35.9 101.6V100.27" stroke="black" stroke-width="0.5" />
    <path d="M51.86 101.6V100.27" stroke="black" stroke-width="0.5" />
    <path d="M51.33 101.6V100.27" stroke="black" stroke-width="0.5" />
    <path d="M41.75 101.6V100.27" stroke="black" stroke-width="0.5" />
    <path d="M51.33 101.6H52.66" stroke="black" stroke-width="0.5" />
    <path d="M35.37 101.6H41.75" stroke="black" stroke-width="0.5" />
    <path d="M35.37 101.6V100.27" stroke="black" stroke-width="0.5" />
    <path d="M41.75 100.27H34.82" stroke="black" stroke-width="0.5" />
    <path d="M41.75 101.6L41.94 103.46" stroke="black" stroke-width="0.5" />
    <path d="M41.94 103.46L42.48 105.26" stroke="black" stroke-width="0.5" />
    <path d="M42.48 105.26L43.37 106.91" stroke="black" stroke-width="0.5" />
    <path d="M43.37 106.91L44.56 108.37" stroke="black" stroke-width="0.5" />
    <path d="M44.56 108.37L46.01 109.56" stroke="black" stroke-width="0.5" />
    <path d="M46.01 109.56L47.66 110.44" stroke="black" stroke-width="0.5" />
    <path d="M47.66 110.44L49.46 110.99" stroke="black" stroke-width="0.5" />
    <path d="M49.46 110.99L51.33 111.17" stroke="black" stroke-width="0.5" />
    <path d="M51.33 101.6H50.86" stroke="black" stroke-width="0.5" />
    <path d="M50.86 101.6V111.16" stroke="black" stroke-width="0.5" />
    <path d="M51.33 111.17V101.6" stroke="black" stroke-width="0.5" />
    <path d="M35.9 100.93H41.22" stroke="black" stroke-width="0.5" />
    <path d="M34.8 100.32L26.03 109.09" stroke="black" stroke-width="0.5" />
    <path d="M35.35 101.65V100.32" stroke="black" stroke-width="0.5" />
    <path d="M35.35 101.65L26.58 110.42" stroke="black" stroke-width="0.5" />
    <path d="M52.22 33.68V35.27" stroke="black" stroke-width="0.5" />
    <path d="M52.22 35.27H51.56" stroke="black" stroke-width="0.5" />
    <path d="M51.56 35.27V34.79" stroke="black" stroke-width="0.5" />
    <path d="M51.56 34.79H51.36" stroke="black" stroke-width="0.5" />
    <path d="M51.36 34.79V34.17" stroke="black" stroke-width="0.5" />
    <path d="M51.36 34.17H51.56" stroke="black" stroke-width="0.5" />
    <path d="M51.56 34.17V33.72" stroke="black" stroke-width="0.5" />
    <path d="M51.56 33.72V33.68" stroke="black" stroke-width="0.5" />
    <path d="M51.56 33.68H52.22" stroke="black" stroke-width="0.5" />
    <path d="M51.56 33.68V24.11" stroke="black" stroke-width="0.5" />
    <path d="M51.56 24.11H51.19" stroke="black" stroke-width="0.5" />
    <path d="M51.19 24.11V33.68" stroke="black" stroke-width="0.5" />
    <path d="M51.19 33.68H51.56" stroke="black" stroke-width="0.5" />
    <path d="M41.51 33.68V35.31" stroke="black" stroke-width="0.5" />
    <path d="M41.51 35.31H42.02" stroke="black" stroke-width="0.5" />
    <path d="M42.02 35.31V34.85" stroke="black" stroke-width="0.5" />
    <path d="M42.02 34.85H42.27" stroke="black" stroke-width="0.5" />
    <path d="M42.27 34.85V34.2" stroke="black" stroke-width="0.5" />
    <path d="M42.27 34.2H42.04" stroke="black" stroke-width="0.5" />
    <path d="M42.04 34.2V33.69" stroke="black" stroke-width="0.5" />
    <path d="M42.04 33.69L41.51 33.68" stroke="black" stroke-width="0.5" />
    <path
      d="M51.19 24.11C48.7291 24.234 46.4093 25.2961 44.7074 27.078C43.0055 28.8598 42.051 31.226 42.04 33.69"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M41.51 35.16V33.68" stroke="black" stroke-width="0.5" />
    <path d="M41.11 35.16V33.83" stroke="black" stroke-width="0.5" />
    <path d="M37.25 35.16V33.83" stroke="black" stroke-width="0.5" />
    <path d="M37.65 35.16V33.83" stroke="black" stroke-width="0.5" />
    <path d="M37.65 34.49H41.11" stroke="black" stroke-width="0.5" />
    <path d="M35.25 68.35H36.83" stroke="black" stroke-width="0.5" />
    <path d="M36.83 68.35V67.7" stroke="black" stroke-width="0.5" />
    <path d="M36.83 67.7H36.35" stroke="black" stroke-width="0.5" />
    <path d="M36.35 67.7V67.49" stroke="black" stroke-width="0.5" />
    <path d="M36.35 67.49H35.74" stroke="black" stroke-width="0.5" />
    <path d="M35.74 67.49V67.7" stroke="black" stroke-width="0.5" />
    <path d="M35.74 67.7H35.29" stroke="black" stroke-width="0.5" />
    <path d="M35.29 67.7H35.25" stroke="black" stroke-width="0.5" />
    <path d="M35.25 67.7V68.35" stroke="black" stroke-width="0.5" />
    <path d="M35.25 67.7H25.68" stroke="black" stroke-width="0.5" />
    <path d="M25.68 67.7V67.32" stroke="black" stroke-width="0.5" />
    <path d="M25.68 67.32H35.25" stroke="black" stroke-width="0.5" />
    <path d="M35.25 67.32V67.7" stroke="black" stroke-width="0.5" />
    <path d="M35.25 57.64H36.88" stroke="black" stroke-width="0.5" />
    <path d="M36.88 57.64V58.16" stroke="black" stroke-width="0.5" />
    <path d="M36.88 58.16H36.42" stroke="black" stroke-width="0.5" />
    <path d="M36.42 58.16V58.4" stroke="black" stroke-width="0.5" />
    <path d="M36.42 58.4H35.77" stroke="black" stroke-width="0.5" />
    <path d="M35.77 58.4V58.18" stroke="black" stroke-width="0.5" />
    <path d="M35.77 58.18H35.26" stroke="black" stroke-width="0.5" />
    <path d="M35.26 58.18L35.25 57.64" stroke="black" stroke-width="0.5" />
    <path
      d="M35.26 58.18C32.7977 58.1908 30.4329 59.144 28.6513 60.8437C26.8697 62.5435 25.8065 64.8609 25.68 67.32"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M35.37 57.64H36.88" stroke="black" stroke-width="0.5" />
    <path d="M35.37 57.24H36.7" stroke="black" stroke-width="0.5" />
    <path d="M35.37 53.39H36.7" stroke="black" stroke-width="0.5" />
    <path d="M35.37 53.78H36.7" stroke="black" stroke-width="0.5" />
    <path d="M36.03 57.24V53.78" stroke="black" stroke-width="0.5" />
  </svg>
</template>
