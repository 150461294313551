import AHUsPortfolio from '@/Core.Service.Domain/Controls/AHUs/Views/IndexView.vue';

import EditAHU from '@/Core.Service.Domain/Controls/AHUs/Views/EditAHU.vue';

export const AHUsRoute = {
  path: 'ahus',
  name: 'AHUsPortfolio',
  component: AHUsPortfolio,
  children: [
    {
      path: 'history/:id',
      name: 'AHUHistory',
    },
  ],
  meta: {
    placeholder: 'Search for a AHU',
    controlTypeName: 'AHU',
    label: 'AHUs',
    toolTip: 'AHU',
    permission: 'controls:read',
  },
};

export const EditAHURoute = {
  path: 'edit-ahu/:controlId',
  name: 'EditAHU',
  props: true,
  component: EditAHU,
  meta: {
    label: 'Edit AHU',
    toolTip: 'Edit AHU',
    permission: 'controls:update',
  },
};
