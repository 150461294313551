<template>
  <v-container fluid>
    <TitleBar :label="`Edit ${control.friendlyName}`" />
    <v-form @submit.prevent="submit" validate-on="input">
      <v-sheet elevation="2" class="pa-4">
        <v-row>
          <v-col sm="6" class="mt-3">
            <site-location-field
              v-if="finishedLoading"
              :siteId="siteId.toString()"
              :selectedSiteLocationId="control.siteLocationId"
              @sitelocationselected="siteLocationSelected"
              ref="siteLocation"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <v-autocomplete
              :items="equipment"
              item-value="equipmentId"
              item-title="equipmentName"
              clearable
              v-model="selectedEquipment"
              label="Equipment"
              ref="equipment"
              persistent-hint
              variant="outlined"
            />
          </v-col>
        </v-row>
        <confirm-dialog ref="confirmDelete" title="Delete Control?" />
        <v-row>
          <v-col>
            <v-btn type="submit" color="primary" :disabled="!isValidControl">
              Save
            </v-btn>
            <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-sheet>

      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="600">
          <v-card>
            <v-card-title class="text-h5">Validating {{ type }}</v-card-title>
            <v-card-text>
              <v-stepper v-model="vStep">
                <v-stepper-item
                  :complete="vStep > 1 && isValidSiteLocation"
                  step="1"
                  color="green"
                >
                  Validating Site Location
                  <small v-if="vStep > 1 && isValidSiteLocation === true">
                    Site Location is valid
                  </small>
                  <v-row
                    style="width: 500px"
                    v-if="vStep > 1 && isValidSiteLocation === false"
                  >
                    <v-col class="pt-0" cols="2" md="9" sm="9">
                      <small v-if="vStep > 2 && isValidSiteLocation === false">
                        Must select site location
                      </small>
                    </v-col>
                    <v-col cols="auto" md="3" sm="3">
                      <v-btn
                        class="mt-n7"
                        color="primary"
                        @click="fixControlErrors(0)"
                      >
                        Fix
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-item>

                <v-stepper-item
                  :complete="vStep > 2 && isValidEquipment === true"
                  step="2"
                  color="green"
                >
                  Validating Equipment
                  <small v-if="vStep > 2 && isValidEquipment === true">{{
                    'Equipment is valid'
                  }}</small>
                  <v-row
                    style="width: 500px"
                    v-if="vStep > 2 && isValidEquipment === false"
                  >
                    <v-col class="pt-0" cols="2" md="9" sm="9">
                      <small v-if="vStep > 2"> Select an equipment </small>
                    </v-col>
                    <v-col cols="auto" md="3" sm="3">
                      <v-btn
                        class="mt-n7"
                        color="primary"
                        @click="fixControlErrors(1)"
                      >
                        Fix
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-item>
              </v-stepper>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green-darken-1"
                variant="text"
                @click="cancelDialog"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green-darken-1"
                :disabled="!isValidControl || !valid"
                variant="text"
                @click="dismissDialog"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import SiteLocationField from '@/modules/site/site_location/_components/SiteLocation';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import api from '../_api';
import siteLocationApi from '../../site/site_location/_api';
import equipmentApi from '../../site/equipment/_api';

import TitleBar from '@/Core.Service.Domain/Monitors/Monitors.Common/Components/TitleBar.vue';

export default {
  name: 'EditControl',
  components: {
    'site-location-field': SiteLocationField,
    'confirm-dialog': ConfirmDialog,
    TitleBar,
  },

  data() {
    return {
      finishedLoading: false,
      valid: true,
      vStep: 0,
      control: {
        siteId: null,
        friendlyName: null,
        siteLocationId: null,
        equipmentId: null,
      },
      deviceName: null,
      friendlyName: null,
      siteLocations: [],
      selectedSiteLocation: null,
      equipment: [],
      selectedEquipment: null,

      isValidSiteLocation: true,
      isValidEquipment: true,
      isValidControl: true,
      dialog: false,

      controlId: this.$route.params.controlId,
      type: this.$route.params.type,
      interval: null,
      isValidating: false,
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    try {
      this.control = await api.getControlById(this.type, this.controlId);
      this.siteId = this.control.siteId;
      this.selectedSiteLocation = this.control.siteLocationId;
      this.siteLocations = await siteLocationApi.getSiteLocationsBySiteId(
        this.siteId
      );
      this.selectedEquipment = this.control.equipmentId;
      this.equipment = await equipmentApi.getEquipmentForSite(this.siteId);
      this.finishedLoading = true;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  methods: {
    async submit(event) {
      const { valid } = await event;
      if (!valid) {
        return Promise.reject({
          Error: 'Please fill in all required fields',
        });
      }

      this.isValidating = false;
      this.validateControl();
    },

    siteLocationSelected(selectedSiteLocation) {
      this.selectedSiteLocation = selectedSiteLocation;
    },

    validateControl() {
      this.isValidating = true;
      this.isValidSiteLocation = false;
      this.isValidEquipment = false;
      this.dialog = true;
      this.startValidator();
    },

    fixControlErrors(item) {
      this.cancelDialog();
      switch (item) {
        case 0:
          this.$nextTick().then(() => {
            this.$refs.siteLocation.$refs.siteLocation.focus();
          });
          break;
        case 1:
          this.$nextTick().then(() => {
            this.$refs.equipment.focus();
          });
          break;
        default:
          break;
      }
    },

    cancelDialog() {
      this.isValidating = false;
      this.isValidControl = true;
      this.dialog = false;
      this.stopValidator();
      this.vStep = 0;
    },

    async dismissDialog() {
      this.cancelDialog();
      if (this.isValidControl) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        this.loading = true;
        try {
          var updatedControl = {
            deviceId: this.control.deviceId,
            siteLocationId: this.selectedSiteLocation.id,
            equipmentId: this.selectedEquipment,
          };
          await api.updateControl(this.type, this.control.deviceId, updatedControl);
          const controlTypes = {
            powercontrol: 'PowerControls',
            thermostat: 'Thermostats',
          };
          this.$router.push({ name: controlTypes[this.type] });
        } catch (error) {
          return Promise.reject(error);
        }
        this.loading = false;
      }
    },

    startValidator() {
      this.interval = setInterval(() => {
        this.vStep++;

        if (this.vStep === 1) {
          this.isValidSiteLocation = this.selectedSiteLocation !== null;
        }
        if (this.vStep === 2) {
          this.isValidEquipment = true;
        }

        if (this.vStep > 2) {
          clearInterval(this.interval);
          this.isValidating = false;
        }

        this.isValidControl = this.isValidSiteLocation && this.isValidEquipment;
      }, 1000);
    },

    stopValidator() {
      clearInterval(this.interval);
    },

    handleCancelCrud() {
      const controlTypes = {
        powercontrol: 'PowerControls',
        thermostat: 'Thermostats',
      };
      this.$router.push({ name: controlTypes[this.type] });
    },
  },
};
</script>
