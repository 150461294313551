<template>
  <v-card min-height="185px" flat>
    <v-list>
      <v-list-item
        v-for="(item, i) in utilityCompaniesItemsList.types"
        :key="i"
      >
        <template v-slot:prepend>
          <v-icon :color="utilityCompanyIconByType[item].color">
            {{ utilityCompanyIconByType[item].icon }}
          </v-icon>
        </template>

        <span class="text-primary font-weight-bold">
          {{ item }}
        </span>
        <v-divider v-if="i !== utilityCompaniesItemsList.types.length - 1" />
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'UtilityCompaniesItemsList',

  props: {
    utilityCompaniesItemsList: {
      type: Object,
      required: true,
    },
  },

  computed: {
    utilityCompanyIconByType() {
      return {
        Water: {
          icon: 'mdi-water',
          color: 'intechBlue',
        },
        Gas: {
          icon: 'mdi-gas-burner',
          color: 'heatColor',
        },
        Electricity: {
          icon: 'mdi-flash',
          color: 'warning',
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel:first-child {
  border-top: none;
}

.v-list-item {
  ::v-deep(.v-list-item__spacer) {
    width: 17px !important;
  }
}
</style>
