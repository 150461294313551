<template>
  <v-container fluid>
    <v-form @submit.prevent="submit" validate-on="input">
      <v-card elevation="1" class="pa-4 pt-6 mt-n4 ml-n4">
        <v-row>
          <v-col cols="6">
            <v-text-field
              autofocus
              v-model="getVAVState.controlFriendlyName"
              label="VAV Friendly Name"
              :rules="[(v) => !!v || 'Name is required']"
              required
              variant="outlined"
              density="compact"
            />
          </v-col>
        </v-row>
        <v-row class="mt-n4 ml-n5">
          <v-col cols="6">
            <v-col>
              <v-btn type="submit" color="primary" :loading="isLoading">
                Save
              </v-btn>
              <v-btn
                class="ml-5"
                @click="router.push({ name: 'VAVsPortfolio' })"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-container>
</template>

<script setup lang="ts">
import { computed, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { ToastService } from '@/plugins/toasts';
import { ToastOptions } from '@/Core.UI.Domain/types/Toast';

import API from '@/Core.Service.Domain/Controls/VAVs/API/index.ts';

import { IVAV } from '@/Core.Service.Domain/Controls/VAVs/types/VAV.ts';

const router = useRouter();

const store = useStore();
const getVAVState = computed<IVAV>(
  () => store.getters['ControlsStore/VAVsStore/getEditVAV']
);

const toast = inject<ToastService>('toast')!;
const isLoading = ref(false);
const submit = async (
  event: PromiseLike<{ valid: boolean }> | { valid: boolean }
) => {
  const { valid } = await event;
  if (!valid) {
    return Promise.reject({
      Error: 'Please fill in all required fields',
    });
  }

  isLoading.value = true;
  try {
    await API.updateVAVFriendlyName(getVAVState.value);
    const options: ToastOptions = {
      title: 'Success',
      message: `VAV friendly name has been updated successfully.<br /> Values can take up to 2 minutes to reflect.`,
      status: 'success',
    };
    toast.show(options);
    router.push({ name: 'VAVsPortfolio' });
  } catch (error) {
    return Promise.reject(error);
  } finally {
    isLoading.value = false;
  }
};
</script>
