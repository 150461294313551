<template>
  <svg
    width="323"
    height="193"
    viewBox="0 0 323 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M36.99 67.83V71.65" stroke="black" stroke-width="0.5" />
    <path d="M59.27 91.34H151.38" stroke="black" stroke-width="0.5" />
    <path d="M170.6 91.34H181.78" stroke="black" stroke-width="0.5" />
    <path d="M181.78 91.34V66.39" stroke="black" stroke-width="0.5" />
    <path d="M181.78 90.01V66.39" stroke="black" stroke-width="0.5" />
    <path d="M181.78 66.39H215.94" stroke="black" stroke-width="0.5" />
    <path d="M181.78 67.73H202.06" stroke="black" stroke-width="0.5" />
    <path d="M203.4 67.73H214.61" stroke="black" stroke-width="0.5" />
    <path d="M214.61 72.53H218.61" stroke="black" stroke-width="0.5" />
    <path d="M215.94 66.39V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M214.61 72.53V67.73" stroke="black" stroke-width="0.5" />
    <path d="M214.61 65.06V10.48" stroke="black" stroke-width="0.5" />
    <path d="M218.61 72.53V63.05" stroke="black" stroke-width="0.5" />
    <path d="M217.28 61.72V32.9" stroke="black" stroke-width="0.5" />
    <path d="M257.84 101.75V100.14" stroke="black" stroke-width="0.5" />
    <path d="M257.84 90.54V63.05" stroke="black" stroke-width="0.5" />
    <path d="M257.84 61.72V60.65" stroke="black" stroke-width="0.5" />
    <path d="M257.84 51.05V32.9" stroke="black" stroke-width="0.5" />
    <path d="M257.84 31.57V30.23" stroke="black" stroke-width="0.5" />
    <path d="M257.84 20.62V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M259.17 103.08V100.15" stroke="black" stroke-width="0.5" />
    <path d="M259.17 90.54V60.65" stroke="black" stroke-width="0.5" />
    <path d="M259.17 51.05V30.23" stroke="black" stroke-width="0.5" />
    <path
      d="M259.17 11V2.5M259.17 20.62V12.08"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M217.28 31.57V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M215.94 2.74001H257.84" stroke="black" stroke-width="0.5" />
    <path d="M277.85 2.74001H318.42" stroke="black" stroke-width="0.5" />
    <path d="M308.14 161.56V163.7" stroke="black" stroke-width="0.5" />
    <path d="M308.14 163.7H278.79" stroke="black" stroke-width="0.5" />
    <path d="M278.79 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M278.79 161.56H256.9" stroke="black" stroke-width="0.5" />
    <path d="M256.9 161.56V163.7" stroke="black" stroke-width="0.5" />
    <path d="M256.9 163.7H227.55" stroke="black" stroke-width="0.5" />
    <path d="M227.55 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M227.55 161.56H211.64" stroke="black" stroke-width="0.5" />
    <path d="M211.64 161.56V172.47" stroke="black" stroke-width="0.5" />
    <path d="M140.27 91.34V92.34" stroke="black" stroke-width="0.5" />
    <path d="M140.27 106.22V107.58" stroke="black" stroke-width="0.5" />
    <path d="M141.6 91.34V92.34" stroke="black" stroke-width="0.5" />
    <path d="M43.3 71.65H59.5V91.5" stroke="black" stroke-width="0.5" />
    <path d="M41.97 71.65H36.99" stroke="black" stroke-width="0.5" />
    <path d="M41.97 71.65V72.91" stroke="black" stroke-width="0.5" />
    <path d="M41.97 82.51V107.58" stroke="black" stroke-width="0.5" />
    <path d="M40.9 100.64V107.58" stroke="black" stroke-width="0.5" />
    <path d="M32.89 100.64V107.58" stroke="black" stroke-width="0.5" />
    <path d="M32.36 101.18V107.58" stroke="black" stroke-width="0.5" />
    <path d="M43.3 71.65V72.91" stroke="black" stroke-width="0.5" />
    <path d="M43.3 82.51V108.92" stroke="black" stroke-width="0.5" />
    <path d="M41.97 72.37H43.3" stroke="black" stroke-width="0.5" />
    <path d="M41.97 72.91H43.3" stroke="black" stroke-width="0.5" />
    <path d="M36.99 67.83H0.5" stroke="black" stroke-width="0.5" />
    <path d="M0.570007 67.5V161.56" stroke="black" stroke-width="0.5" />
    <path d="M7.44998 67.83H4.16998" stroke="black" stroke-width="0.5" />
    <path d="M7.45001 67.83H10.74" stroke="black" stroke-width="0.5" />
    <path d="M14.02 67.83H10.74" stroke="black" stroke-width="0.5" />
    <path d="M14.02 67.83H17.3" stroke="black" stroke-width="0.5" />
    <path d="M20.58 67.83H17.3" stroke="black" stroke-width="0.5" />
    <path d="M20.58 67.83H23.86" stroke="black" stroke-width="0.5" />
    <path d="M29.55 67.83H25" stroke="black" stroke-width="0.5" />
    <path d="M29.55 67.83H34.1" stroke="black" stroke-width="0.5" />
    <path d="M43.3 108.92H39.71" stroke="black" stroke-width="0.5" />
    <path d="M38.37 108.92H1.91" stroke="black" stroke-width="0.5" />
    <path d="M41.97 107.58H1.91" stroke="black" stroke-width="0.5" />
    <path d="M32.36 101.18H1.91" stroke="black" stroke-width="0.5" />
    <path d="M40.9 100.64H32.89" stroke="black" stroke-width="0.5" />
    <path d="M41.97 104.11H41.12" stroke="black" stroke-width="0.5" />
    <path d="M40.28 104.11H38.62" stroke="black" stroke-width="0.5" />
    <path d="M37.78 104.11H36.11" stroke="black" stroke-width="0.5" />
    <path d="M35.28 104.11H33.61" stroke="black" stroke-width="0.5" />
    <path d="M32.78 104.11H31.11" stroke="black" stroke-width="0.5" />
    <path d="M30.28 104.11H28.61" stroke="black" stroke-width="0.5" />
    <path d="M27.77 104.11H26.11" stroke="black" stroke-width="0.5" />
    <path d="M25.27 104.11H23.61" stroke="black" stroke-width="0.5" />
    <path d="M22.77 104.11H21.1" stroke="black" stroke-width="0.5" />
    <path d="M20.27 104.11H18.6" stroke="black" stroke-width="0.5" />
    <path d="M17.77 104.11H16.1" stroke="black" stroke-width="0.5" />
    <path d="M15.27 104.11H13.6" stroke="black" stroke-width="0.5" />
    <path d="M12.76 104.11H11.1" stroke="black" stroke-width="0.5" />
    <path d="M10.26 104.11H8.59" stroke="black" stroke-width="0.5" />
    <path d="M7.75999 104.11H6.09" stroke="black" stroke-width="0.5" />
    <path d="M5.25999 104.11H3.59" stroke="black" stroke-width="0.5" />
    <path d="M2.75999 104.11H1.91" stroke="black" stroke-width="0.5" />
    <path d="M25.7 105.79V106.23" stroke="black" stroke-width="0.5" />
    <path d="M27.01 105.79H25.7" stroke="black" stroke-width="0.5" />
    <path d="M27.01 106.23V105.79" stroke="black" stroke-width="0.5" />
    <path d="M25.7 106.23H27.01" stroke="black" stroke-width="0.5" />
    <path d="M24.11 102.58L24.2 102.2" stroke="black" stroke-width="0.5" />
    <path d="M24.2 102.2L24.43 101.91" stroke="black" stroke-width="0.5" />
    <path d="M24.43 101.91L24.76 101.81" stroke="black" stroke-width="0.5" />
    <path d="M27.95 101.81L28.27 101.91" stroke="black" stroke-width="0.5" />
    <path d="M28.27 101.91L28.51 102.2" stroke="black" stroke-width="0.5" />
    <path d="M28.51 102.2L28.59 102.58" stroke="black" stroke-width="0.5" />
    <path d="M28.59 104.49L28.51 104.88" stroke="black" stroke-width="0.5" />
    <path d="M28.51 104.88L28.27 105.16" stroke="black" stroke-width="0.5" />
    <path d="M28.27 105.16L27.95 105.26" stroke="black" stroke-width="0.5" />
    <path d="M24.76 105.26L24.43 105.16" stroke="black" stroke-width="0.5" />
    <path d="M24.43 105.16L24.2 104.88" stroke="black" stroke-width="0.5" />
    <path d="M24.2 104.88L24.11 104.49" stroke="black" stroke-width="0.5" />
    <path d="M24.11 102.58V104.49" stroke="black" stroke-width="0.5" />
    <path d="M27.95 101.81H24.76" stroke="black" stroke-width="0.5" />
    <path d="M28.59 104.49V102.58" stroke="black" stroke-width="0.5" />
    <path d="M24.76 105.26H27.95" stroke="black" stroke-width="0.5" />
    <path d="M23.68 101.36V106.72" stroke="black" stroke-width="0.5" />
    <path d="M29.03 101.36H23.68" stroke="black" stroke-width="0.5" />
    <path d="M29.03 106.72V101.36" stroke="black" stroke-width="0.5" />
    <path d="M23.68 106.72H29.03" stroke="black" stroke-width="0.5" />
    <path d="M38.37 108.92V109.95" stroke="black" stroke-width="0.5" />
    <path d="M38.37 119.55V161.56" stroke="black" stroke-width="0.5" />
    <path d="M39.71 108.92V109.95" stroke="black" stroke-width="0.5" />
    <path d="M39.71 119.55V123" stroke="black" stroke-width="0.5" />
    <path d="M39.71 124.34V147.58" stroke="black" stroke-width="0.5" />
    <path d="M39.71 148.91V158.38" stroke="black" stroke-width="0.5" />
    <path d="M38.37 109.41H39.71" stroke="black" stroke-width="0.5" />
    <path d="M38.37 109.95H39.71" stroke="black" stroke-width="0.5" />
    <path d="M38.37 119.55H39.71" stroke="black" stroke-width="0.5" />
    <path d="M38.37 120.09H39.71" stroke="black" stroke-width="0.5" />
    <path d="M39.71 123H56.23" stroke="black" stroke-width="0.5" />
    <path d="M65.84 123H67.02" stroke="black" stroke-width="0.5" />
    <path d="M39.71 124.34H56.23" stroke="black" stroke-width="0.5" />
    <path d="M65.84 124.34H67.02" stroke="black" stroke-width="0.5" />
    <path d="M67.02 107.58V110.06" stroke="black" stroke-width="0.5" />
    <path d="M67.02 119.67V123" stroke="black" stroke-width="0.5" />
    <path d="M67.02 124.34V161.56" stroke="black" stroke-width="0.5" />
    <path d="M68.35 108.92V110.06" stroke="black" stroke-width="0.5" />
    <path d="M68.35 119.67V161.56" stroke="black" stroke-width="0.5" />
    <path d="M68.35 108.92H104.96" stroke="black" stroke-width="0.5" />
    <path d="M106.29 108.92H107.5" stroke="black" stroke-width="0.5" />
    <path d="M121.38 108.92H140.27" stroke="black" stroke-width="0.5" />
    <path d="M67.02 107.58H107.5" stroke="black" stroke-width="0.5" />
    <path d="M121.38 107.58H140.27" stroke="black" stroke-width="0.5" />
    <path d="M66.37 123V124.34" stroke="black" stroke-width="0.5" />
    <path d="M65.84 123V124.34" stroke="black" stroke-width="0.5" />
    <path d="M56.23 123V124.34" stroke="black" stroke-width="0.5" />
    <path d="M55.7 123V124.34" stroke="black" stroke-width="0.5" />
    <path d="M43.3 82.51V86.78" stroke="black" stroke-width="0.5" />
    <path d="M41.97 82.51V86.78" stroke="black" stroke-width="0.5" />
    <path d="M41.97 86.78H43.3" stroke="black" stroke-width="0.5" />
    <path d="M41.97 86.25H43.3" stroke="black" stroke-width="0.5" />
    <path d="M41.97 83.05H43.3" stroke="black" stroke-width="0.5" />
    <path d="M41.97 82.51H43.3" stroke="black" stroke-width="0.5" />
    <path d="M42.63 83.05V86.25" stroke="black" stroke-width="0.5" />
    <path d="M67.02 109.53H68.35" stroke="black" stroke-width="0.5" />
    <path d="M67.02 110.06H68.35" stroke="black" stroke-width="0.5" />
    <path d="M67.02 119.67H68.35" stroke="black" stroke-width="0.5" />
    <path d="M67.02 120.2H68.35" stroke="black" stroke-width="0.5" />
    <path d="M104.96 108.92V161.56" stroke="black" stroke-width="0.5" />
    <path d="M106.29 108.92V148.41" stroke="black" stroke-width="0.5" />
    <path d="M106.29 149.75V161.56" stroke="black" stroke-width="0.5" />
    <path d="M106.97 108.92V107.58" stroke="black" stroke-width="0.5" />
    <path d="M107.5 108.92V107.58" stroke="black" stroke-width="0.5" />
    <path d="M121.38 108.92H117.11" stroke="black" stroke-width="0.5" />
    <path d="M121.38 107.58H117.11" stroke="black" stroke-width="0.5" />
    <path d="M117.11 107.58V108.92" stroke="black" stroke-width="0.5" />
    <path d="M117.64 107.58V108.92" stroke="black" stroke-width="0.5" />
    <path d="M120.84 107.58V108.92" stroke="black" stroke-width="0.5" />
    <path d="M121.38 107.58V108.92" stroke="black" stroke-width="0.5" />
    <path d="M120.84 108.25H117.64" stroke="black" stroke-width="0.5" />
    <path d="M140.27 108.92V139.15" stroke="black" stroke-width="0.5" />
    <path d="M141.6 106.22V139.91" stroke="black" stroke-width="0.5" />
    <path
      d="M106.29 148.41C118.221 148.274 129.919 145.086 140.27 139.15"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.95 149.75C104.953 149.75 104.957 149.75 104.96 149.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M106.29 149.75C118.717 149.606 130.89 146.214 141.6 139.91"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M140.27 91.81H141.6" stroke="black" stroke-width="0.5" />
    <path d="M140.27 92.34H141.6" stroke="black" stroke-width="0.5" />
    <path d="M140.27 106.22V101.95" stroke="black" stroke-width="0.5" />
    <path d="M141.6 106.22V101.95" stroke="black" stroke-width="0.5" />
    <path d="M141.6 101.95H140.27" stroke="black" stroke-width="0.5" />
    <path d="M141.6 102.48H140.27" stroke="black" stroke-width="0.5" />
    <path d="M141.6 105.69H140.27" stroke="black" stroke-width="0.5" />
    <path d="M141.6 106.22H140.27" stroke="black" stroke-width="0.5" />
    <path d="M140.93 105.69V102.48" stroke="black" stroke-width="0.5" />
    <path d="M150.85 91.34V90.01" stroke="black" stroke-width="0.5" />
    <path d="M151.38 91.34V90.01" stroke="black" stroke-width="0.5" />
    <path d="M170.6 91.34V90.01" stroke="black" stroke-width="0.5" />
    <path d="M171.13 91.34V90.01" stroke="black" stroke-width="0.5" />
    <path d="M181.78 91.34V101.75" stroke="black" stroke-width="0.5" />
    <path d="M181.78 103.08V104.24" stroke="black" stroke-width="0.5" />
    <path d="M181.78 118.12V127.04" stroke="black" stroke-width="0.5" />
    <path d="M180.45 91.34V104.24" stroke="black" stroke-width="0.5" />
    <path d="M180.44 118.12L180.45 127.59" stroke="black" stroke-width="0.5" />
    <path d="M180.45 103.71H181.78" stroke="black" stroke-width="0.5" />
    <path d="M180.45 104.24H181.78" stroke="black" stroke-width="0.5" />
    <path d="M181.78 127.04L185.43 130.69" stroke="black" stroke-width="0.5" />
    <path d="M180.45 127.59L184.35 131.5" stroke="black" stroke-width="0.5" />
    <path d="M203.4 67.73V101.75" stroke="black" stroke-width="0.5" />
    <path d="M202.06 67.73V101.75" stroke="black" stroke-width="0.5" />
    <path d="M217.28 32.9H257.84" stroke="black" stroke-width="0.5" />
    <path d="M277.85 32.9H318.42" stroke="black" stroke-width="0.5" />
    <path d="M257.84 50.51H259.17" stroke="black" stroke-width="0.5" />
    <path d="M276.52 50.51H277.85" stroke="black" stroke-width="0.5" />
    <path d="M217.28 61.72H257.84" stroke="black" stroke-width="0.5" />
    <path d="M277.85 61.72H318.42" stroke="black" stroke-width="0.5" />
    <path d="M218.61 63.05H257.84" stroke="black" stroke-width="0.5" />
    <path d="M277.85 63.05H317.08" stroke="black" stroke-width="0.5" />
    <path d="M217.28 31.57H257.84" stroke="black" stroke-width="0.5" />
    <path d="M257.84 30.76H259.17" stroke="black" stroke-width="0.5" />
    <path d="M257.84 30.23H259.17" stroke="black" stroke-width="0.5" />
    <path d="M277.85 31.57H318.42" stroke="black" stroke-width="0.5" />
    <path d="M276.52 93.21V90.81" stroke="black" stroke-width="0.5" />
    <path d="M276.52 81.2V60.65" stroke="black" stroke-width="0.5" />
    <path d="M276.52 51.05V30.23" stroke="black" stroke-width="0.5" />
    <path d="M276.52 20.62V12.08" stroke="black" stroke-width="0.5" />
    <path d="M276.52 10.75V2.5" stroke="black" stroke-width="0.5" />
    <path d="M277.85 81.2V63.05" stroke="black" stroke-width="0.5" />
    <path d="M277.85 61.72V60.65" stroke="black" stroke-width="0.5" />
    <path d="M277.85 50.51V32.9" stroke="black" stroke-width="0.5" />
    <path d="M277.85 31.57V30.23" stroke="black" stroke-width="0.5" />
    <path d="M277.85 20.62V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M257.83 20.09H259.17" stroke="black" stroke-width="0.5" />
    <path d="M257.83 20.62H259.17" stroke="black" stroke-width="0.5" />
    <path d="M277.85 30.76H276.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 30.23H276.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 20.09H276.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 20.62H276.52" stroke="black" stroke-width="0.5" />
    <path d="M259.17 12.08H265.58" stroke="black" stroke-width="0.5" />
    <path d="M275.18 12.08H276.52" stroke="black" stroke-width="0.5" />
    <path d="M259.17 10.75H265.58" stroke="black" stroke-width="0.5" />
    <path d="M275.18 10.75H276.52" stroke="black" stroke-width="0.5" />
    <path d="M275.72 10.75V12.08" stroke="black" stroke-width="0.5" />
    <path d="M275.18 10.75V12.08" stroke="black" stroke-width="0.5" />
    <path d="M265.04 10.75V12.08" stroke="black" stroke-width="0.5" />
    <path d="M265.58 10.75V12.08" stroke="black" stroke-width="0.5" />
    <path d="M257.84 61.19H259.17" stroke="black" stroke-width="0.5" />
    <path d="M257.84 60.65H259.17" stroke="black" stroke-width="0.5" />
    <path d="M257.83 50.51H259.17" stroke="black" stroke-width="0.5" />
    <path d="M257.83 51.05H259.17" stroke="black" stroke-width="0.5" />
    <path d="M277.85 61.19H276.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 60.65H276.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 50.51H276.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 51.05H276.52" stroke="black" stroke-width="0.5" />
    <path d="M276.52 80.67H277.85" stroke="black" stroke-width="0.5" />
    <path d="M277.85 91.88H318.42" stroke="black" stroke-width="0.5" />
    <path d="M276.52 93.21H297.03" stroke="black" stroke-width="0.5" />
    <path d="M298.36 93.21H318.42" stroke="black" stroke-width="0.5" />
    <path d="M277.85 91.88V90.81" stroke="black" stroke-width="0.5" />
    <path d="M277.85 91.34H276.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 90.81H276.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 80.67H276.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 81.2H276.52" stroke="black" stroke-width="0.5" />
    <path d="M252.84 4.7V4.25" stroke="black" stroke-width="0.5" />
    <path d="M254.15 4.7H252.84" stroke="black" stroke-width="0.5" />
    <path d="M254.15 4.25V4.7" stroke="black" stroke-width="0.5" />
    <path d="M252.84 4.25H254.15" stroke="black" stroke-width="0.5" />
    <path d="M251.25 7.91L251.34 8.28999" stroke="black" stroke-width="0.5" />
    <path
      d="M251.34 8.28999L251.57 8.57001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M251.57 8.57001L251.9 8.67999" stroke="black" stroke-width="0.5" />
    <path
      d="M255.09 8.67999L255.41 8.57001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M255.41 8.57001L255.65 8.28999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M255.65 8.28999L255.73 7.91" stroke="black" stroke-width="0.5" />
    <path d="M255.73 6L255.65 5.61" stroke="black" stroke-width="0.5" />
    <path d="M255.65 5.61L255.41 5.33" stroke="black" stroke-width="0.5" />
    <path d="M255.41 5.33L255.09 5.23" stroke="black" stroke-width="0.5" />
    <path d="M251.9 5.23L251.57 5.33" stroke="black" stroke-width="0.5" />
    <path d="M251.57 5.33L251.34 5.61" stroke="black" stroke-width="0.5" />
    <path d="M251.34 5.61L251.25 6" stroke="black" stroke-width="0.5" />
    <path d="M251.25 7.91V6" stroke="black" stroke-width="0.5" />
    <path d="M255.09 8.67999H251.9" stroke="black" stroke-width="0.5" />
    <path d="M255.73 6V7.91" stroke="black" stroke-width="0.5" />
    <path d="M251.9 5.23H255.09" stroke="black" stroke-width="0.5" />
    <path d="M250.82 9.13V3.77" stroke="black" stroke-width="0.5" />
    <path d="M256.17 9.13H250.82" stroke="black" stroke-width="0.5" />
    <path d="M256.17 3.77V9.13" stroke="black" stroke-width="0.5" />
    <path d="M250.82 3.77H256.17" stroke="black" stroke-width="0.5" />
    <path d="M257.84 9.42H257.82" stroke="black" stroke-width="0.5" />
    <path d="M257.81 9.42H257.77" stroke="black" stroke-width="0.5" />
    <path d="M257.76 9.42H257.72" stroke="black" stroke-width="0.5" />
    <path d="M257.71 9.42H257.67" stroke="black" stroke-width="0.5" />
    <path d="M257.66 9.42H257.62" stroke="black" stroke-width="0.5" />
    <path d="M257.61 9.42H257.57" stroke="black" stroke-width="0.5" />
    <path d="M257.56 9.42H257.52" stroke="black" stroke-width="0.5" />
    <path d="M257.51 9.42H257.47" stroke="black" stroke-width="0.5" />
    <path d="M257.46 9.42H257.42" stroke="black" stroke-width="0.5" />
    <path d="M257.41 9.42H257.37" stroke="black" stroke-width="0.5" />
    <path d="M257.36 9.42H257.32" stroke="black" stroke-width="0.5" />
    <path d="M257.31 9.42H257.27" stroke="black" stroke-width="0.5" />
    <path d="M257.26 9.42H257.22" stroke="black" stroke-width="0.5" />
    <path d="M257.21 9.42H257.17" stroke="black" stroke-width="0.5" />
    <path d="M257.16 9.42H257.12" stroke="black" stroke-width="0.5" />
    <path d="M257.11 9.42H257.07" stroke="black" stroke-width="0.5" />
    <path d="M257.05 9.42H257.02" stroke="black" stroke-width="0.5" />
    <path d="M257 9.42H256.97" stroke="black" stroke-width="0.5" />
    <path d="M256.95 9.42H256.92" stroke="black" stroke-width="0.5" />
    <path d="M256.9 9.42H256.87" stroke="black" stroke-width="0.5" />
    <path d="M256.85 9.42H256.82" stroke="black" stroke-width="0.5" />
    <path d="M256.8 9.42H256.77" stroke="black" stroke-width="0.5" />
    <path d="M256.75 9.42H256.72" stroke="black" stroke-width="0.5" />
    <path d="M256.7 9.42H256.67" stroke="black" stroke-width="0.5" />
    <path d="M256.65 9.42H256.62" stroke="black" stroke-width="0.5" />
    <path d="M256.6 9.42H256.57" stroke="black" stroke-width="0.5" />
    <path d="M256.55 9.42H256.52" stroke="black" stroke-width="0.5" />
    <path d="M256.5 9.42H256.47" stroke="black" stroke-width="0.5" />
    <path d="M256.45 9.42H256.42" stroke="black" stroke-width="0.5" />
    <path d="M256.4 9.42H256.37" stroke="black" stroke-width="0.5" />
    <path d="M256.35 9.42H256.32" stroke="black" stroke-width="0.5" />
    <path d="M256.3 9.42H256.27" stroke="black" stroke-width="0.5" />
    <path d="M256.25 9.42H256.22" stroke="black" stroke-width="0.5" />
    <path d="M256.2 9.42H256.17" stroke="black" stroke-width="0.5" />
    <path d="M256.15 9.42H256.12" stroke="black" stroke-width="0.5" />
    <path d="M256.1 9.42H256.07" stroke="black" stroke-width="0.5" />
    <path d="M256.05 9.42H256.02" stroke="black" stroke-width="0.5" />
    <path d="M256 9.42H255.97" stroke="black" stroke-width="0.5" />
    <path d="M255.95 9.42H255.92" stroke="black" stroke-width="0.5" />
    <path d="M255.9 9.42H255.87" stroke="black" stroke-width="0.5" />
    <path d="M255.85 9.42H255.82" stroke="black" stroke-width="0.5" />
    <path d="M255.8 9.42H255.77" stroke="black" stroke-width="0.5" />
    <path d="M255.75 9.42H255.72" stroke="black" stroke-width="0.5" />
    <path d="M255.7 9.42H255.67" stroke="black" stroke-width="0.5" />
    <path d="M255.65 9.42H255.62" stroke="black" stroke-width="0.5" />
    <path d="M255.6 9.42H255.57" stroke="black" stroke-width="0.5" />
    <path d="M255.55 9.42H255.52" stroke="black" stroke-width="0.5" />
    <path d="M255.5 9.42H255.47" stroke="black" stroke-width="0.5" />
    <path d="M255.45 9.42H255.42" stroke="black" stroke-width="0.5" />
    <path d="M255.4 9.42H255.37" stroke="black" stroke-width="0.5" />
    <path d="M255.35 9.42H255.32" stroke="black" stroke-width="0.5" />
    <path d="M255.3 9.42H255.27" stroke="black" stroke-width="0.5" />
    <path d="M255.25 9.42H255.22" stroke="black" stroke-width="0.5" />
    <path d="M255.2 9.42H255.17" stroke="black" stroke-width="0.5" />
    <path d="M255.15 9.42H255.12" stroke="black" stroke-width="0.5" />
    <path d="M255.1 9.42H255.07" stroke="black" stroke-width="0.5" />
    <path d="M255.05 9.42H255.02" stroke="black" stroke-width="0.5" />
    <path d="M255 9.42H254.97" stroke="black" stroke-width="0.5" />
    <path d="M254.95 9.42H254.92" stroke="black" stroke-width="0.5" />
    <path d="M254.9 9.42H254.87" stroke="black" stroke-width="0.5" />
    <path d="M254.85 9.42H254.82" stroke="black" stroke-width="0.5" />
    <path d="M254.8 9.42H254.77" stroke="black" stroke-width="0.5" />
    <path d="M254.75 9.42H254.72" stroke="black" stroke-width="0.5" />
    <path d="M254.7 9.42H254.67" stroke="black" stroke-width="0.5" />
    <path d="M254.65 9.42H254.62" stroke="black" stroke-width="0.5" />
    <path d="M254.6 9.42H254.57" stroke="black" stroke-width="0.5" />
    <path d="M254.55 9.42H254.52" stroke="black" stroke-width="0.5" />
    <path d="M254.5 9.42H254.47" stroke="black" stroke-width="0.5" />
    <path d="M254.45 9.42H254.42" stroke="black" stroke-width="0.5" />
    <path d="M254.4 9.42H254.37" stroke="black" stroke-width="0.5" />
    <path d="M254.35 9.42H254.32" stroke="black" stroke-width="0.5" />
    <path d="M254.3 9.42H254.27" stroke="black" stroke-width="0.5" />
    <path d="M254.25 9.42H254.22" stroke="black" stroke-width="0.5" />
    <path d="M254.2 9.42H254.17" stroke="black" stroke-width="0.5" />
    <path d="M254.15 9.42H254.12" stroke="black" stroke-width="0.5" />
    <path d="M254.1 9.42H254.07" stroke="black" stroke-width="0.5" />
    <path d="M254.05 9.42H254.02" stroke="black" stroke-width="0.5" />
    <path d="M254 9.42H253.97" stroke="black" stroke-width="0.5" />
    <path d="M253.95 9.42H253.92" stroke="black" stroke-width="0.5" />
    <path d="M253.9 9.42H253.87" stroke="black" stroke-width="0.5" />
    <path d="M253.85 9.42H253.82" stroke="black" stroke-width="0.5" />
    <path d="M253.8 9.42H253.77" stroke="black" stroke-width="0.5" />
    <path d="M253.75 9.42H253.72" stroke="black" stroke-width="0.5" />
    <path d="M253.7 9.42H253.67" stroke="black" stroke-width="0.5" />
    <path d="M253.65 9.42H253.62" stroke="black" stroke-width="0.5" />
    <path d="M253.6 9.42H253.57" stroke="black" stroke-width="0.5" />
    <path d="M253.55 9.42H253.52" stroke="black" stroke-width="0.5" />
    <path d="M253.5 9.42H253.47" stroke="black" stroke-width="0.5" />
    <path d="M253.45 9.42H253.42" stroke="black" stroke-width="0.5" />
    <path d="M253.4 9.42H253.37" stroke="black" stroke-width="0.5" />
    <path d="M253.35 9.42H253.32" stroke="black" stroke-width="0.5" />
    <path d="M253.3 9.42H253.27" stroke="black" stroke-width="0.5" />
    <path d="M253.25 9.42H253.22" stroke="black" stroke-width="0.5" />
    <path d="M253.2 9.42H253.17" stroke="black" stroke-width="0.5" />
    <path d="M253.15 9.42H253.12" stroke="black" stroke-width="0.5" />
    <path d="M253.1 9.42H253.07" stroke="black" stroke-width="0.5" />
    <path d="M253.05 9.42H253.02" stroke="black" stroke-width="0.5" />
    <path d="M253 9.42H252.97" stroke="black" stroke-width="0.5" />
    <path d="M252.95 9.42H252.92" stroke="black" stroke-width="0.5" />
    <path d="M252.9 9.42H252.87" stroke="black" stroke-width="0.5" />
    <path d="M252.85 9.42H252.82" stroke="black" stroke-width="0.5" />
    <path d="M252.8 9.42H252.77" stroke="black" stroke-width="0.5" />
    <path d="M252.75 9.42H252.72" stroke="black" stroke-width="0.5" />
    <path d="M252.7 9.42H252.67" stroke="black" stroke-width="0.5" />
    <path d="M252.65 9.42H252.62" stroke="black" stroke-width="0.5" />
    <path d="M252.6 9.42H252.57" stroke="black" stroke-width="0.5" />
    <path d="M252.55 9.42H252.52" stroke="black" stroke-width="0.5" />
    <path d="M252.5 9.42H252.47" stroke="black" stroke-width="0.5" />
    <path d="M252.45 9.42H252.42" stroke="black" stroke-width="0.5" />
    <path d="M252.4 9.42H252.37" stroke="black" stroke-width="0.5" />
    <path d="M252.35 9.42H252.32" stroke="black" stroke-width="0.5" />
    <path d="M252.3 9.42H252.27" stroke="black" stroke-width="0.5" />
    <path d="M252.25 9.42H252.22" stroke="black" stroke-width="0.5" />
    <path d="M252.2 9.42H252.17" stroke="black" stroke-width="0.5" />
    <path d="M252.15 9.42H252.12" stroke="black" stroke-width="0.5" />
    <path d="M252.1 9.42H252.07" stroke="black" stroke-width="0.5" />
    <path d="M252.05 9.42H252.02" stroke="black" stroke-width="0.5" />
    <path d="M252 9.42H251.97" stroke="black" stroke-width="0.5" />
    <path d="M251.95 9.42H251.92" stroke="black" stroke-width="0.5" />
    <path d="M251.9 9.42H251.87" stroke="black" stroke-width="0.5" />
    <path d="M251.85 9.42H251.82" stroke="black" stroke-width="0.5" />
    <path d="M251.8 9.42H251.77" stroke="black" stroke-width="0.5" />
    <path d="M251.75 9.42H251.72" stroke="black" stroke-width="0.5" />
    <path d="M251.7 9.42H251.67" stroke="black" stroke-width="0.5" />
    <path d="M251.65 9.42H251.62" stroke="black" stroke-width="0.5" />
    <path d="M251.6 9.42H251.57" stroke="black" stroke-width="0.5" />
    <path d="M251.55 9.42H251.52" stroke="black" stroke-width="0.5" />
    <path d="M251.5 9.42H251.47" stroke="black" stroke-width="0.5" />
    <path d="M251.45 9.42H251.42" stroke="black" stroke-width="0.5" />
    <path d="M251.4 9.42H251.37" stroke="black" stroke-width="0.5" />
    <path d="M251.35 9.42H251.32" stroke="black" stroke-width="0.5" />
    <path d="M251.3 9.42H251.27" stroke="black" stroke-width="0.5" />
    <path d="M251.25 9.42H251.22" stroke="black" stroke-width="0.5" />
    <path d="M251.2 9.42H251.17" stroke="black" stroke-width="0.5" />
    <path d="M251.15 9.42H251.12" stroke="black" stroke-width="0.5" />
    <path d="M251.1 9.42H251.07" stroke="black" stroke-width="0.5" />
    <path d="M251.05 9.42H251.02" stroke="black" stroke-width="0.5" />
    <path d="M251 9.42H250.97" stroke="black" stroke-width="0.5" />
    <path d="M250.95 9.42H250.92" stroke="black" stroke-width="0.5" />
    <path d="M250.9 9.42H250.87" stroke="black" stroke-width="0.5" />
    <path d="M250.85 9.42H250.82" stroke="black" stroke-width="0.5" />
    <path d="M250.8 9.42H250.77" stroke="black" stroke-width="0.5" />
    <path d="M250.75 9.42H250.72" stroke="black" stroke-width="0.5" />
    <path d="M250.7 9.42H250.67" stroke="black" stroke-width="0.5" />
    <path d="M250.65 9.42H250.62" stroke="black" stroke-width="0.5" />
    <path d="M250.6 9.42H250.57" stroke="black" stroke-width="0.5" />
    <path d="M250.55 9.42H250.52" stroke="black" stroke-width="0.5" />
    <path d="M250.5 9.42H250.47" stroke="black" stroke-width="0.5" />
    <path d="M250.45 9.42H250.42" stroke="black" stroke-width="0.5" />
    <path d="M250.4 9.42H250.37" stroke="black" stroke-width="0.5" />
    <path d="M250.35 9.42H250.32" stroke="black" stroke-width="0.5" />
    <path d="M250.3 9.42H250.27" stroke="black" stroke-width="0.5" />
    <path d="M250.25 9.42H250.22" stroke="black" stroke-width="0.5" />
    <path d="M250.2 9.42H250.17" stroke="black" stroke-width="0.5" />
    <path d="M250.15 9.42H250.12" stroke="black" stroke-width="0.5" />
    <path d="M250.1 9.42H250.07" stroke="black" stroke-width="0.5" />
    <path d="M250.05 9.42H250.02" stroke="black" stroke-width="0.5" />
    <path d="M250 9.42H249.97" stroke="black" stroke-width="0.5" />
    <path d="M249.95 9.42H249.92" stroke="black" stroke-width="0.5" />
    <path d="M249.9 9.42H249.87" stroke="black" stroke-width="0.5" />
    <path d="M249.85 9.42H249.82" stroke="black" stroke-width="0.5" />
    <path d="M249.8 9.42H249.77" stroke="black" stroke-width="0.5" />
    <path d="M249.75 9.42H249.72" stroke="black" stroke-width="0.5" />
    <path d="M249.7 9.42H249.67" stroke="black" stroke-width="0.5" />
    <path d="M249.65 9.42H249.62" stroke="black" stroke-width="0.5" />
    <path d="M249.6 9.42H249.57" stroke="black" stroke-width="0.5" />
    <path d="M249.55 9.42H249.52" stroke="black" stroke-width="0.5" />
    <path d="M249.5 9.42H249.47" stroke="black" stroke-width="0.5" />
    <path d="M249.45 9.42H249.42" stroke="black" stroke-width="0.5" />
    <path d="M249.4 9.42H249.37" stroke="black" stroke-width="0.5" />
    <path d="M249.35 9.42H249.32" stroke="black" stroke-width="0.5" />
    <path d="M249.3 9.42H249.27" stroke="black" stroke-width="0.5" />
    <path d="M249.25 9.42H249.22" stroke="black" stroke-width="0.5" />
    <path d="M249.2 9.42H249.17" stroke="black" stroke-width="0.5" />
    <path d="M249.15 9.42H249.12" stroke="black" stroke-width="0.5" />
    <path d="M249.1 9.42H249.07" stroke="black" stroke-width="0.5" />
    <path d="M249.05 9.42H249.02" stroke="black" stroke-width="0.5" />
    <path d="M249 9.42H248.97" stroke="black" stroke-width="0.5" />
    <path d="M248.95 9.42H248.92" stroke="black" stroke-width="0.5" />
    <path d="M248.9 9.42H248.87" stroke="black" stroke-width="0.5" />
    <path d="M248.85 9.42H248.82" stroke="black" stroke-width="0.5" />
    <path d="M248.8 9.42H248.77" stroke="black" stroke-width="0.5" />
    <path d="M248.75 9.42H248.72" stroke="black" stroke-width="0.5" />
    <path d="M248.7 9.42H248.67" stroke="black" stroke-width="0.5" />
    <path d="M248.65 9.42H248.62" stroke="black" stroke-width="0.5" />
    <path d="M248.6 9.42H248.57" stroke="black" stroke-width="0.5" />
    <path d="M248.55 9.42H248.52" stroke="black" stroke-width="0.5" />
    <path d="M248.5 9.42H248.47" stroke="black" stroke-width="0.5" />
    <path d="M248.45 9.42H248.42" stroke="black" stroke-width="0.5" />
    <path d="M248.4 9.42H248.37" stroke="black" stroke-width="0.5" />
    <path d="M248.35 9.42H248.32" stroke="black" stroke-width="0.5" />
    <path d="M248.3 9.42H248.27" stroke="black" stroke-width="0.5" />
    <path d="M248.25 9.42H248.22" stroke="black" stroke-width="0.5" />
    <path d="M248.2 9.42H248.17" stroke="black" stroke-width="0.5" />
    <path d="M248.15 9.42H248.12" stroke="black" stroke-width="0.5" />
    <path d="M248.1 9.42H248.07" stroke="black" stroke-width="0.5" />
    <path d="M248.05 9.42H248.02" stroke="black" stroke-width="0.5" />
    <path d="M248 9.42H247.96" stroke="black" stroke-width="0.5" />
    <path d="M247.95 9.42H247.91" stroke="black" stroke-width="0.5" />
    <path d="M247.9 9.42H247.86" stroke="black" stroke-width="0.5" />
    <path d="M247.85 9.42H247.81" stroke="black" stroke-width="0.5" />
    <path d="M247.8 9.42H247.76" stroke="black" stroke-width="0.5" />
    <path d="M247.75 9.42H247.71" stroke="black" stroke-width="0.5" />
    <path d="M247.7 9.42H247.66" stroke="black" stroke-width="0.5" />
    <path d="M247.65 9.42H247.61" stroke="black" stroke-width="0.5" />
    <path d="M247.6 9.42H247.56" stroke="black" stroke-width="0.5" />
    <path d="M247.55 9.42H247.51" stroke="black" stroke-width="0.5" />
    <path d="M247.5 9.42H247.46" stroke="black" stroke-width="0.5" />
    <path d="M247.45 9.42H247.41" stroke="black" stroke-width="0.5" />
    <path d="M247.4 9.42H247.36" stroke="black" stroke-width="0.5" />
    <path d="M247.35 9.42H247.31" stroke="black" stroke-width="0.5" />
    <path d="M247.3 9.42H247.26" stroke="black" stroke-width="0.5" />
    <path d="M247.25 9.42H247.21" stroke="black" stroke-width="0.5" />
    <path d="M247.2 9.42H247.16" stroke="black" stroke-width="0.5" />
    <path d="M247.15 9.42H247.11" stroke="black" stroke-width="0.5" />
    <path d="M247.1 9.42H247.06" stroke="black" stroke-width="0.5" />
    <path d="M247.05 9.42H247.01" stroke="black" stroke-width="0.5" />
    <path d="M247 9.42H246.96" stroke="black" stroke-width="0.5" />
    <path d="M246.95 9.42H246.91" stroke="black" stroke-width="0.5" />
    <path d="M246.9 9.42H246.86" stroke="black" stroke-width="0.5" />
    <path d="M246.85 9.42H246.81" stroke="black" stroke-width="0.5" />
    <path d="M246.8 9.42H246.76" stroke="black" stroke-width="0.5" />
    <path d="M246.75 9.42H246.71" stroke="black" stroke-width="0.5" />
    <path d="M246.7 9.42H246.66" stroke="black" stroke-width="0.5" />
    <path d="M246.65 9.42H246.61" stroke="black" stroke-width="0.5" />
    <path d="M246.6 9.42H246.56" stroke="black" stroke-width="0.5" />
    <path d="M246.55 9.42H246.51" stroke="black" stroke-width="0.5" />
    <path d="M246.5 9.42H246.46" stroke="black" stroke-width="0.5" />
    <path d="M246.45 9.42H246.41" stroke="black" stroke-width="0.5" />
    <path d="M246.4 9.42H246.36" stroke="black" stroke-width="0.5" />
    <path d="M246.35 9.42H246.31" stroke="black" stroke-width="0.5" />
    <path d="M246.3 9.42H246.26" stroke="black" stroke-width="0.5" />
    <path d="M246.25 9.42H246.21" stroke="black" stroke-width="0.5" />
    <path d="M246.2 9.42H246.16" stroke="black" stroke-width="0.5" />
    <path d="M246.15 9.42H246.11" stroke="black" stroke-width="0.5" />
    <path d="M246.1 9.42H246.06" stroke="black" stroke-width="0.5" />
    <path d="M246.05 9.42H246.01" stroke="black" stroke-width="0.5" />
    <path d="M246 9.42H245.96" stroke="black" stroke-width="0.5" />
    <path d="M245.95 9.42H245.91" stroke="black" stroke-width="0.5" />
    <path d="M245.9 9.42H245.86" stroke="black" stroke-width="0.5" />
    <path d="M245.85 9.42H245.81" stroke="black" stroke-width="0.5" />
    <path d="M245.8 9.42H245.76" stroke="black" stroke-width="0.5" />
    <path d="M245.75 9.42H245.71" stroke="black" stroke-width="0.5" />
    <path d="M245.7 9.42H245.66" stroke="black" stroke-width="0.5" />
    <path d="M245.65 9.42H245.61" stroke="black" stroke-width="0.5" />
    <path d="M245.6 9.42H245.56" stroke="black" stroke-width="0.5" />
    <path d="M245.55 9.42H245.51" stroke="black" stroke-width="0.5" />
    <path d="M245.5 9.42H245.46" stroke="black" stroke-width="0.5" />
    <path d="M245.45 9.42H245.41" stroke="black" stroke-width="0.5" />
    <path d="M245.4 9.42H245.36" stroke="black" stroke-width="0.5" />
    <path d="M245.35 9.42H245.31" stroke="black" stroke-width="0.5" />
    <path d="M245.3 9.42H245.26" stroke="black" stroke-width="0.5" />
    <path d="M245.25 9.42H245.21" stroke="black" stroke-width="0.5" />
    <path d="M245.2 9.42H245.16" stroke="black" stroke-width="0.5" />
    <path d="M245.15 9.42H245.11" stroke="black" stroke-width="0.5" />
    <path d="M245.1 9.42H245.06" stroke="black" stroke-width="0.5" />
    <path d="M245.05 9.42H245.01" stroke="black" stroke-width="0.5" />
    <path d="M245 9.42H244.96" stroke="black" stroke-width="0.5" />
    <path d="M244.95 9.42H244.91" stroke="black" stroke-width="0.5" />
    <path d="M244.9 9.42H244.86" stroke="black" stroke-width="0.5" />
    <path d="M244.85 9.42H244.81" stroke="black" stroke-width="0.5" />
    <path d="M244.8 9.42H244.76" stroke="black" stroke-width="0.5" />
    <path d="M244.75 9.42H244.71" stroke="black" stroke-width="0.5" />
    <path d="M244.7 9.42H244.66" stroke="black" stroke-width="0.5" />
    <path d="M244.65 9.42H244.61" stroke="black" stroke-width="0.5" />
    <path d="M244.6 9.42H244.56" stroke="black" stroke-width="0.5" />
    <path d="M244.55 9.42H244.51" stroke="black" stroke-width="0.5" />
    <path d="M244.5 9.42H244.46" stroke="black" stroke-width="0.5" />
    <path d="M244.45 9.42H244.41" stroke="black" stroke-width="0.5" />
    <path d="M244.4 9.42H244.36" stroke="black" stroke-width="0.5" />
    <path d="M244.35 9.42H244.31" stroke="black" stroke-width="0.5" />
    <path d="M244.3 9.42H244.26" stroke="black" stroke-width="0.5" />
    <path d="M244.25 9.42H244.21" stroke="black" stroke-width="0.5" />
    <path d="M244.2 9.42H244.16" stroke="black" stroke-width="0.5" />
    <path d="M244.15 9.42H244.11" stroke="black" stroke-width="0.5" />
    <path d="M244.1 9.42H244.06" stroke="black" stroke-width="0.5" />
    <path d="M244.05 9.42H244.01" stroke="black" stroke-width="0.5" />
    <path d="M244 9.42H243.96" stroke="black" stroke-width="0.5" />
    <path d="M243.95 9.42H243.91" stroke="black" stroke-width="0.5" />
    <path d="M243.9 9.42H243.86" stroke="black" stroke-width="0.5" />
    <path d="M243.85 9.42H243.81" stroke="black" stroke-width="0.5" />
    <path d="M243.8 9.42H243.76" stroke="black" stroke-width="0.5" />
    <path d="M243.75 9.42H243.71" stroke="black" stroke-width="0.5" />
    <path d="M243.7 9.42H243.66" stroke="black" stroke-width="0.5" />
    <path d="M243.65 9.42H243.61" stroke="black" stroke-width="0.5" />
    <path d="M243.6 9.42H243.56" stroke="black" stroke-width="0.5" />
    <path d="M243.55 9.42H243.51" stroke="black" stroke-width="0.5" />
    <path d="M243.5 9.42H243.46" stroke="black" stroke-width="0.5" />
    <path d="M243.45 9.42H243.41" stroke="black" stroke-width="0.5" />
    <path d="M243.39 9.42H243.36" stroke="black" stroke-width="0.5" />
    <path d="M243.34 9.42H243.31" stroke="black" stroke-width="0.5" />
    <path d="M243.29 9.42H243.26" stroke="black" stroke-width="0.5" />
    <path d="M243.24 9.42H243.21" stroke="black" stroke-width="0.5" />
    <path d="M243.19 9.42H243.16" stroke="black" stroke-width="0.5" />
    <path d="M243.14 9.42H243.11" stroke="black" stroke-width="0.5" />
    <path d="M243.09 9.42H243.06" stroke="black" stroke-width="0.5" />
    <path d="M243.04 9.42H243.01" stroke="black" stroke-width="0.5" />
    <path d="M242.99 9.42H242.96" stroke="black" stroke-width="0.5" />
    <path d="M242.94 9.42H242.91" stroke="black" stroke-width="0.5" />
    <path d="M242.89 9.42H242.86" stroke="black" stroke-width="0.5" />
    <path d="M242.84 9.42H242.81" stroke="black" stroke-width="0.5" />
    <path d="M242.79 9.42H242.76" stroke="black" stroke-width="0.5" />
    <path d="M242.74 9.42H242.71" stroke="black" stroke-width="0.5" />
    <path d="M242.69 9.42H242.66" stroke="black" stroke-width="0.5" />
    <path d="M242.64 9.42H242.61" stroke="black" stroke-width="0.5" />
    <path d="M242.59 9.42H242.56" stroke="black" stroke-width="0.5" />
    <path d="M242.54 9.42H242.51" stroke="black" stroke-width="0.5" />
    <path d="M242.49 9.42H242.46" stroke="black" stroke-width="0.5" />
    <path d="M242.44 9.42H242.41" stroke="black" stroke-width="0.5" />
    <path d="M242.39 9.42H242.36" stroke="black" stroke-width="0.5" />
    <path d="M242.34 9.42H242.31" stroke="black" stroke-width="0.5" />
    <path d="M242.29 9.42H242.26" stroke="black" stroke-width="0.5" />
    <path d="M242.24 9.42H242.21" stroke="black" stroke-width="0.5" />
    <path d="M242.19 9.42H242.16" stroke="black" stroke-width="0.5" />
    <path d="M242.14 9.42H242.11" stroke="black" stroke-width="0.5" />
    <path d="M242.09 9.42H242.06" stroke="black" stroke-width="0.5" />
    <path d="M242.04 9.42H242.01" stroke="black" stroke-width="0.5" />
    <path d="M241.99 9.42H241.96" stroke="black" stroke-width="0.5" />
    <path d="M241.94 9.42H241.91" stroke="black" stroke-width="0.5" />
    <path d="M241.89 9.42H241.86" stroke="black" stroke-width="0.5" />
    <path d="M241.84 9.42H241.83" stroke="black" stroke-width="0.5" />
    <path d="M241.83 9.42V9.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 9.37V9.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 9.32001V9.28999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 9.27V9.24001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 9.22V9.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 9.17V9.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 9.12V9.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 9.07001V9.03999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 9.02V8.99001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.97V8.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.92V8.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.87V8.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.82001V8.78999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.77V8.74001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.72V8.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.67V8.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.62V8.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.57001V8.53999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.52V8.49001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.47V8.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.42V8.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.37V8.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.32001V8.28999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.27V8.24001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.22V8.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.17V8.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.12V8.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.07001V8.03999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 8.02V7.99001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.97V7.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.92V7.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.87V7.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.82001V7.78999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.77V7.74001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.72V7.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.67V7.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.62V7.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.57001V7.53999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.52V7.49001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.47V7.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.42V7.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.37V7.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.32001V7.28999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.27V7.24001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.22V7.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.17V7.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.12V7.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.07001V7.03999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 7.02V6.99001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.97V6.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.92V6.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.87V6.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.82001V6.78999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.77V6.74001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.72V6.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.67V6.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.62V6.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.57001V6.53999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.52V6.49001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.47V6.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.42V6.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.37V6.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.32001V6.28999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.27V6.24001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.22V6.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.17V6.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.12V6.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.07001V6.03999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.02V5.99001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.97V5.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.92V5.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.87V5.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.82001V5.78999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.77V5.74001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.72V5.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.67V5.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.62V5.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.57001V5.53999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.52V5.49001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.47V5.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.42V5.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.37V5.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.32001V5.28999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.27V5.24001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.22V5.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.17V5.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.12V5.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.07001V5.03999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 5.02V4.99001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.97V4.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.92V4.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.87V4.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.82001V4.78999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.77V4.74001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.72V4.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.67V4.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.62V4.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.57001V4.53999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.52V4.49001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.47V4.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.42V4.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.37V4.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.32001V4.28999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.27V4.24001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.22V4.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.17V4.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.12V4.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.07001V4.03999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 4.02V3.99001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.97V3.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.92V3.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.87V3.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.82001V3.78999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.77V3.74001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.72V3.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.67V3.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.62V3.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.57001V3.53999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.52V3.49001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.47V3.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.42V3.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.37V3.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.32001V3.28999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.27V3.24001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.22V3.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.17V3.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.12V3.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.07001V3.03999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 3.02V2.99001" stroke="black" stroke-width="0.5" />
    <path d="M241.83 2.97V2.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 2.92V2.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 2.87V2.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 2.82001V2.78999" stroke="black" stroke-width="0.5" />
    <path d="M241.83 2.77V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M282.86 4.7V4.25" stroke="black" stroke-width="0.5" />
    <path d="M281.54 4.7H282.86" stroke="black" stroke-width="0.5" />
    <path d="M281.54 4.25V4.7" stroke="black" stroke-width="0.5" />
    <path d="M282.86 4.25H281.54" stroke="black" stroke-width="0.5" />
    <path d="M284.44 7.91L284.35 8.28999" stroke="black" stroke-width="0.5" />
    <path
      d="M284.35 8.28999L284.12 8.57001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M284.12 8.57001L283.8 8.67999" stroke="black" stroke-width="0.5" />
    <path d="M280.6 8.67999L280.28 8.57001" stroke="black" stroke-width="0.5" />
    <path
      d="M280.28 8.57001L280.05 8.28999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M280.05 8.28999L279.96 7.91" stroke="black" stroke-width="0.5" />
    <path d="M279.96 6L280.05 5.61" stroke="black" stroke-width="0.5" />
    <path d="M280.05 5.61L280.28 5.33" stroke="black" stroke-width="0.5" />
    <path d="M280.28 5.33L280.6 5.23" stroke="black" stroke-width="0.5" />
    <path d="M283.8 5.23L284.12 5.33" stroke="black" stroke-width="0.5" />
    <path d="M284.12 5.33L284.35 5.61" stroke="black" stroke-width="0.5" />
    <path d="M284.35 5.61L284.44 6" stroke="black" stroke-width="0.5" />
    <path d="M284.44 7.91V6" stroke="black" stroke-width="0.5" />
    <path d="M280.6 8.67999H283.8" stroke="black" stroke-width="0.5" />
    <path d="M279.96 6V7.91" stroke="black" stroke-width="0.5" />
    <path d="M283.8 5.23H280.6" stroke="black" stroke-width="0.5" />
    <path d="M284.88 9.13V3.77" stroke="black" stroke-width="0.5" />
    <path d="M279.52 9.13H284.88" stroke="black" stroke-width="0.5" />
    <path d="M279.52 3.77V9.13" stroke="black" stroke-width="0.5" />
    <path d="M284.88 3.77H279.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 9.42H277.87" stroke="black" stroke-width="0.5" />
    <path d="M277.89 9.42H277.92" stroke="black" stroke-width="0.5" />
    <path d="M277.94 9.42H277.97" stroke="black" stroke-width="0.5" />
    <path d="M277.99 9.42H278.02" stroke="black" stroke-width="0.5" />
    <path d="M278.04 9.42H278.07" stroke="black" stroke-width="0.5" />
    <path d="M278.09 9.42H278.12" stroke="black" stroke-width="0.5" />
    <path d="M278.14 9.42H278.17" stroke="black" stroke-width="0.5" />
    <path d="M278.19 9.42H278.22" stroke="black" stroke-width="0.5" />
    <path d="M278.24 9.42H278.27" stroke="black" stroke-width="0.5" />
    <path d="M278.29 9.42H278.32" stroke="black" stroke-width="0.5" />
    <path d="M278.34 9.42H278.37" stroke="black" stroke-width="0.5" />
    <path d="M278.39 9.42H278.42" stroke="black" stroke-width="0.5" />
    <path d="M278.44 9.42H278.47" stroke="black" stroke-width="0.5" />
    <path d="M278.49 9.42H278.52" stroke="black" stroke-width="0.5" />
    <path d="M278.54 9.42H278.57" stroke="black" stroke-width="0.5" />
    <path d="M278.59 9.42H278.62" stroke="black" stroke-width="0.5" />
    <path d="M278.64 9.42H278.67" stroke="black" stroke-width="0.5" />
    <path d="M278.69 9.42H278.72" stroke="black" stroke-width="0.5" />
    <path d="M278.74 9.42H278.77" stroke="black" stroke-width="0.5" />
    <path d="M278.79 9.42H278.82" stroke="black" stroke-width="0.5" />
    <path d="M278.84 9.42H278.87" stroke="black" stroke-width="0.5" />
    <path d="M278.89 9.42H278.92" stroke="black" stroke-width="0.5" />
    <path d="M278.94 9.42H278.97" stroke="black" stroke-width="0.5" />
    <path d="M278.99 9.42H279.02" stroke="black" stroke-width="0.5" />
    <path d="M279.04 9.42H279.07" stroke="black" stroke-width="0.5" />
    <path d="M279.09 9.42H279.12" stroke="black" stroke-width="0.5" />
    <path d="M279.14 9.42H279.17" stroke="black" stroke-width="0.5" />
    <path d="M279.19 9.42H279.22" stroke="black" stroke-width="0.5" />
    <path d="M279.24 9.42H279.27" stroke="black" stroke-width="0.5" />
    <path d="M279.29 9.42H279.32" stroke="black" stroke-width="0.5" />
    <path d="M279.34 9.42H279.37" stroke="black" stroke-width="0.5" />
    <path d="M279.39 9.42H279.42" stroke="black" stroke-width="0.5" />
    <path d="M279.44 9.42H279.47" stroke="black" stroke-width="0.5" />
    <path d="M279.49 9.42H279.52" stroke="black" stroke-width="0.5" />
    <path d="M279.54 9.42H279.57" stroke="black" stroke-width="0.5" />
    <path d="M279.59 9.42H279.62" stroke="black" stroke-width="0.5" />
    <path d="M279.64 9.42H279.67" stroke="black" stroke-width="0.5" />
    <path d="M279.69 9.42H279.72" stroke="black" stroke-width="0.5" />
    <path d="M279.74 9.42H279.77" stroke="black" stroke-width="0.5" />
    <path d="M279.79 9.42H279.82" stroke="black" stroke-width="0.5" />
    <path d="M279.84 9.42H279.87" stroke="black" stroke-width="0.5" />
    <path d="M279.89 9.42H279.92" stroke="black" stroke-width="0.5" />
    <path d="M279.94 9.42H279.97" stroke="black" stroke-width="0.5" />
    <path d="M279.99 9.42H280.02" stroke="black" stroke-width="0.5" />
    <path d="M280.04 9.42H280.07" stroke="black" stroke-width="0.5" />
    <path d="M280.09 9.42H280.12" stroke="black" stroke-width="0.5" />
    <path d="M280.14 9.42H280.17" stroke="black" stroke-width="0.5" />
    <path d="M280.19 9.42H280.22" stroke="black" stroke-width="0.5" />
    <path d="M280.24 9.42H280.27" stroke="black" stroke-width="0.5" />
    <path d="M280.29 9.42H280.32" stroke="black" stroke-width="0.5" />
    <path d="M280.34 9.42H280.37" stroke="black" stroke-width="0.5" />
    <path d="M280.39 9.42H280.42" stroke="black" stroke-width="0.5" />
    <path d="M280.44 9.42H280.47" stroke="black" stroke-width="0.5" />
    <path d="M280.49 9.42H280.52" stroke="black" stroke-width="0.5" />
    <path d="M280.54 9.42H280.57" stroke="black" stroke-width="0.5" />
    <path d="M280.59 9.42H280.62" stroke="black" stroke-width="0.5" />
    <path d="M280.64 9.42H280.67" stroke="black" stroke-width="0.5" />
    <path d="M280.69 9.42H280.72" stroke="black" stroke-width="0.5" />
    <path d="M280.74 9.42H280.77" stroke="black" stroke-width="0.5" />
    <path d="M280.79 9.42H280.82" stroke="black" stroke-width="0.5" />
    <path d="M280.84 9.42H280.87" stroke="black" stroke-width="0.5" />
    <path d="M280.89 9.42H280.92" stroke="black" stroke-width="0.5" />
    <path d="M280.94 9.42H280.97" stroke="black" stroke-width="0.5" />
    <path d="M280.99 9.42H281.02" stroke="black" stroke-width="0.5" />
    <path d="M281.04 9.42H281.07" stroke="black" stroke-width="0.5" />
    <path d="M281.09 9.42H281.12" stroke="black" stroke-width="0.5" />
    <path d="M281.14 9.42H281.17" stroke="black" stroke-width="0.5" />
    <path d="M281.19 9.42H281.22" stroke="black" stroke-width="0.5" />
    <path d="M281.24 9.42H281.27" stroke="black" stroke-width="0.5" />
    <path d="M281.29 9.42H281.32" stroke="black" stroke-width="0.5" />
    <path d="M281.34 9.42H281.37" stroke="black" stroke-width="0.5" />
    <path d="M281.39 9.42H281.42" stroke="black" stroke-width="0.5" />
    <path d="M281.44 9.42H281.47" stroke="black" stroke-width="0.5" />
    <path d="M281.49 9.42H281.52" stroke="black" stroke-width="0.5" />
    <path d="M281.54 9.42H281.57" stroke="black" stroke-width="0.5" />
    <path d="M281.59 9.42H281.62" stroke="black" stroke-width="0.5" />
    <path d="M281.64 9.42H281.67" stroke="black" stroke-width="0.5" />
    <path d="M281.69 9.42H281.72" stroke="black" stroke-width="0.5" />
    <path d="M281.74 9.42H281.77" stroke="black" stroke-width="0.5" />
    <path d="M281.79 9.42H281.82" stroke="black" stroke-width="0.5" />
    <path d="M281.84 9.42H281.87" stroke="black" stroke-width="0.5" />
    <path d="M281.89 9.42H281.92" stroke="black" stroke-width="0.5" />
    <path d="M281.94 9.42H281.97" stroke="black" stroke-width="0.5" />
    <path d="M281.99 9.42H282.02" stroke="black" stroke-width="0.5" />
    <path d="M282.04 9.42H282.07" stroke="black" stroke-width="0.5" />
    <path d="M282.09 9.42H282.12" stroke="black" stroke-width="0.5" />
    <path d="M282.14 9.42H282.17" stroke="black" stroke-width="0.5" />
    <path d="M282.19 9.42H282.22" stroke="black" stroke-width="0.5" />
    <path d="M282.24 9.42H282.27" stroke="black" stroke-width="0.5" />
    <path d="M282.29 9.42H282.32" stroke="black" stroke-width="0.5" />
    <path d="M282.34 9.42H282.37" stroke="black" stroke-width="0.5" />
    <path d="M282.39 9.42H282.42" stroke="black" stroke-width="0.5" />
    <path d="M282.44 9.42H282.47" stroke="black" stroke-width="0.5" />
    <path d="M282.49 9.42H282.52" stroke="black" stroke-width="0.5" />
    <path d="M282.54 9.42H282.57" stroke="black" stroke-width="0.5" />
    <path d="M282.59 9.42H282.62" stroke="black" stroke-width="0.5" />
    <path d="M282.64 9.42H282.67" stroke="black" stroke-width="0.5" />
    <path d="M282.69 9.42H282.72" stroke="black" stroke-width="0.5" />
    <path d="M282.74 9.42H282.77" stroke="black" stroke-width="0.5" />
    <path d="M282.79 9.42H282.82" stroke="black" stroke-width="0.5" />
    <path d="M282.84 9.42H282.87" stroke="black" stroke-width="0.5" />
    <path d="M282.89 9.42H282.92" stroke="black" stroke-width="0.5" />
    <path d="M282.94 9.42H282.97" stroke="black" stroke-width="0.5" />
    <path d="M282.99 9.42H283.02" stroke="black" stroke-width="0.5" />
    <path d="M283.04 9.42H283.07" stroke="black" stroke-width="0.5" />
    <path d="M283.09 9.42H283.12" stroke="black" stroke-width="0.5" />
    <path d="M283.14 9.42H283.17" stroke="black" stroke-width="0.5" />
    <path d="M283.19 9.42H283.22" stroke="black" stroke-width="0.5" />
    <path d="M283.24 9.42H283.27" stroke="black" stroke-width="0.5" />
    <path d="M283.29 9.42H283.32" stroke="black" stroke-width="0.5" />
    <path d="M283.34 9.42H283.37" stroke="black" stroke-width="0.5" />
    <path d="M283.39 9.42H283.42" stroke="black" stroke-width="0.5" />
    <path d="M283.44 9.42H283.47" stroke="black" stroke-width="0.5" />
    <path d="M283.49 9.42H283.52" stroke="black" stroke-width="0.5" />
    <path d="M283.54 9.42H283.57" stroke="black" stroke-width="0.5" />
    <path d="M283.59 9.42H283.62" stroke="black" stroke-width="0.5" />
    <path d="M283.64 9.42H283.67" stroke="black" stroke-width="0.5" />
    <path d="M283.69 9.42H283.72" stroke="black" stroke-width="0.5" />
    <path d="M283.74 9.42H283.77" stroke="black" stroke-width="0.5" />
    <path d="M283.79 9.42H283.82" stroke="black" stroke-width="0.5" />
    <path d="M283.84 9.42H283.87" stroke="black" stroke-width="0.5" />
    <path d="M283.89 9.42H283.92" stroke="black" stroke-width="0.5" />
    <path d="M283.94 9.42H283.97" stroke="black" stroke-width="0.5" />
    <path d="M283.99 9.42H284.02" stroke="black" stroke-width="0.5" />
    <path d="M284.04 9.42H284.07" stroke="black" stroke-width="0.5" />
    <path d="M284.09 9.42H284.12" stroke="black" stroke-width="0.5" />
    <path d="M284.14 9.42H284.17" stroke="black" stroke-width="0.5" />
    <path d="M284.19 9.42H284.22" stroke="black" stroke-width="0.5" />
    <path d="M284.24 9.42H284.27" stroke="black" stroke-width="0.5" />
    <path d="M284.29 9.42H284.32" stroke="black" stroke-width="0.5" />
    <path d="M284.34 9.42H284.37" stroke="black" stroke-width="0.5" />
    <path d="M284.39 9.42H284.42" stroke="black" stroke-width="0.5" />
    <path d="M284.44 9.42H284.48" stroke="black" stroke-width="0.5" />
    <path d="M284.49 9.42H284.53" stroke="black" stroke-width="0.5" />
    <path d="M284.54 9.42H284.58" stroke="black" stroke-width="0.5" />
    <path d="M284.59 9.42H284.63" stroke="black" stroke-width="0.5" />
    <path d="M284.64 9.42H284.68" stroke="black" stroke-width="0.5" />
    <path d="M284.69 9.42H284.73" stroke="black" stroke-width="0.5" />
    <path d="M284.74 9.42H284.78" stroke="black" stroke-width="0.5" />
    <path d="M284.79 9.42H284.83" stroke="black" stroke-width="0.5" />
    <path d="M284.84 9.42H284.88" stroke="black" stroke-width="0.5" />
    <path d="M284.89 9.42H284.93" stroke="black" stroke-width="0.5" />
    <path d="M284.94 9.42H284.98" stroke="black" stroke-width="0.5" />
    <path d="M284.99 9.42H285.03" stroke="black" stroke-width="0.5" />
    <path d="M285.04 9.42H285.08" stroke="black" stroke-width="0.5" />
    <path d="M285.09 9.42H285.13" stroke="black" stroke-width="0.5" />
    <path d="M285.14 9.42H285.18" stroke="black" stroke-width="0.5" />
    <path d="M285.19 9.42H285.23" stroke="black" stroke-width="0.5" />
    <path d="M285.24 9.42H285.28" stroke="black" stroke-width="0.5" />
    <path d="M285.29 9.42H285.33" stroke="black" stroke-width="0.5" />
    <path d="M285.34 9.42H285.38" stroke="black" stroke-width="0.5" />
    <path d="M285.39 9.42H285.43" stroke="black" stroke-width="0.5" />
    <path d="M285.44 9.42H285.48" stroke="black" stroke-width="0.5" />
    <path d="M285.49 9.42H285.53" stroke="black" stroke-width="0.5" />
    <path d="M285.54 9.42H285.58" stroke="black" stroke-width="0.5" />
    <path d="M285.59 9.42H285.63" stroke="black" stroke-width="0.5" />
    <path d="M285.64 9.42H285.68" stroke="black" stroke-width="0.5" />
    <path d="M285.69 9.42H285.73" stroke="black" stroke-width="0.5" />
    <path d="M285.74 9.42H285.78" stroke="black" stroke-width="0.5" />
    <path d="M285.79 9.42H285.83" stroke="black" stroke-width="0.5" />
    <path d="M285.84 9.42H285.88" stroke="black" stroke-width="0.5" />
    <path d="M285.89 9.42H285.93" stroke="black" stroke-width="0.5" />
    <path d="M285.94 9.42H285.98" stroke="black" stroke-width="0.5" />
    <path d="M285.99 9.42H286.03" stroke="black" stroke-width="0.5" />
    <path d="M286.04 9.42H286.08" stroke="black" stroke-width="0.5" />
    <path d="M286.09 9.42H286.13" stroke="black" stroke-width="0.5" />
    <path d="M286.14 9.42H286.18" stroke="black" stroke-width="0.5" />
    <path d="M286.19 9.42H286.23" stroke="black" stroke-width="0.5" />
    <path d="M286.24 9.42H286.28" stroke="black" stroke-width="0.5" />
    <path d="M286.29 9.42H286.33" stroke="black" stroke-width="0.5" />
    <path d="M286.34 9.42H286.38" stroke="black" stroke-width="0.5" />
    <path d="M286.39 9.42H286.43" stroke="black" stroke-width="0.5" />
    <path d="M286.44 9.42H286.48" stroke="black" stroke-width="0.5" />
    <path d="M286.49 9.42H286.53" stroke="black" stroke-width="0.5" />
    <path d="M286.54 9.42H286.58" stroke="black" stroke-width="0.5" />
    <path d="M286.59 9.42H286.63" stroke="black" stroke-width="0.5" />
    <path d="M286.64 9.42H286.68" stroke="black" stroke-width="0.5" />
    <path d="M286.69 9.42H286.73" stroke="black" stroke-width="0.5" />
    <path d="M286.74 9.42H286.78" stroke="black" stroke-width="0.5" />
    <path d="M286.79 9.42H286.83" stroke="black" stroke-width="0.5" />
    <path d="M286.84 9.42H286.88" stroke="black" stroke-width="0.5" />
    <path d="M286.89 9.42H286.93" stroke="black" stroke-width="0.5" />
    <path d="M286.94 9.42H286.98" stroke="black" stroke-width="0.5" />
    <path d="M286.99 9.42H287.03" stroke="black" stroke-width="0.5" />
    <path d="M287.04 9.42H287.08" stroke="black" stroke-width="0.5" />
    <path d="M287.09 9.42H287.13" stroke="black" stroke-width="0.5" />
    <path d="M287.14 9.42H287.18" stroke="black" stroke-width="0.5" />
    <path d="M287.19 9.42H287.23" stroke="black" stroke-width="0.5" />
    <path d="M287.24 9.42H287.28" stroke="black" stroke-width="0.5" />
    <path d="M287.29 9.42H287.33" stroke="black" stroke-width="0.5" />
    <path d="M287.34 9.42H287.38" stroke="black" stroke-width="0.5" />
    <path d="M287.39 9.42H287.43" stroke="black" stroke-width="0.5" />
    <path d="M287.44 9.42H287.48" stroke="black" stroke-width="0.5" />
    <path d="M287.49 9.42H287.53" stroke="black" stroke-width="0.5" />
    <path d="M287.54 9.42H287.58" stroke="black" stroke-width="0.5" />
    <path d="M287.59 9.42H287.63" stroke="black" stroke-width="0.5" />
    <path d="M287.64 9.42H287.68" stroke="black" stroke-width="0.5" />
    <path d="M287.69 9.42H287.73" stroke="black" stroke-width="0.5" />
    <path d="M287.74 9.42H287.78" stroke="black" stroke-width="0.5" />
    <path d="M287.79 9.42H287.83" stroke="black" stroke-width="0.5" />
    <path d="M287.84 9.42H287.88" stroke="black" stroke-width="0.5" />
    <path d="M287.89 9.42H287.93" stroke="black" stroke-width="0.5" />
    <path d="M287.94 9.42H287.98" stroke="black" stroke-width="0.5" />
    <path d="M287.99 9.42H288.03" stroke="black" stroke-width="0.5" />
    <path d="M288.04 9.42H288.08" stroke="black" stroke-width="0.5" />
    <path d="M288.09 9.42H288.13" stroke="black" stroke-width="0.5" />
    <path d="M288.14 9.42H288.18" stroke="black" stroke-width="0.5" />
    <path d="M288.19 9.42H288.23" stroke="black" stroke-width="0.5" />
    <path d="M288.24 9.42H288.28" stroke="black" stroke-width="0.5" />
    <path d="M288.29 9.42H288.33" stroke="black" stroke-width="0.5" />
    <path d="M288.34 9.42H288.38" stroke="black" stroke-width="0.5" />
    <path d="M288.39 9.42H288.43" stroke="black" stroke-width="0.5" />
    <path d="M288.44 9.42H288.48" stroke="black" stroke-width="0.5" />
    <path d="M288.49 9.42H288.53" stroke="black" stroke-width="0.5" />
    <path d="M288.54 9.42H288.58" stroke="black" stroke-width="0.5" />
    <path d="M288.59 9.42H288.63" stroke="black" stroke-width="0.5" />
    <path d="M288.64 9.42H288.68" stroke="black" stroke-width="0.5" />
    <path d="M288.69 9.42H288.73" stroke="black" stroke-width="0.5" />
    <path d="M288.74 9.42H288.78" stroke="black" stroke-width="0.5" />
    <path d="M288.79 9.42H288.83" stroke="black" stroke-width="0.5" />
    <path d="M288.84 9.42H288.88" stroke="black" stroke-width="0.5" />
    <path d="M288.89 9.42H288.93" stroke="black" stroke-width="0.5" />
    <path d="M288.94 9.42H288.98" stroke="black" stroke-width="0.5" />
    <path d="M288.99 9.42H289.03" stroke="black" stroke-width="0.5" />
    <path d="M289.05 9.42H289.08" stroke="black" stroke-width="0.5" />
    <path d="M289.1 9.42H289.13" stroke="black" stroke-width="0.5" />
    <path d="M289.15 9.42H289.18" stroke="black" stroke-width="0.5" />
    <path d="M289.2 9.42H289.23" stroke="black" stroke-width="0.5" />
    <path d="M289.25 9.42H289.28" stroke="black" stroke-width="0.5" />
    <path d="M289.3 9.42H289.33" stroke="black" stroke-width="0.5" />
    <path d="M289.35 9.42H289.38" stroke="black" stroke-width="0.5" />
    <path d="M289.4 9.42H289.43" stroke="black" stroke-width="0.5" />
    <path d="M289.45 9.42H289.48" stroke="black" stroke-width="0.5" />
    <path d="M289.5 9.42H289.53" stroke="black" stroke-width="0.5" />
    <path d="M289.55 9.42H289.58" stroke="black" stroke-width="0.5" />
    <path d="M289.6 9.42H289.63" stroke="black" stroke-width="0.5" />
    <path d="M289.65 9.42H289.68" stroke="black" stroke-width="0.5" />
    <path d="M289.7 9.42H289.73" stroke="black" stroke-width="0.5" />
    <path d="M289.75 9.42H289.78" stroke="black" stroke-width="0.5" />
    <path d="M289.8 9.42H289.83" stroke="black" stroke-width="0.5" />
    <path d="M289.85 9.42H289.88" stroke="black" stroke-width="0.5" />
    <path d="M289.9 9.42H289.93" stroke="black" stroke-width="0.5" />
    <path d="M289.95 9.42H289.98" stroke="black" stroke-width="0.5" />
    <path d="M290 9.42H290.03" stroke="black" stroke-width="0.5" />
    <path d="M290.05 9.42H290.08" stroke="black" stroke-width="0.5" />
    <path d="M290.1 9.42H290.13" stroke="black" stroke-width="0.5" />
    <path d="M290.15 9.42H290.18" stroke="black" stroke-width="0.5" />
    <path d="M290.2 9.42H290.23" stroke="black" stroke-width="0.5" />
    <path d="M290.25 9.42H290.28" stroke="black" stroke-width="0.5" />
    <path d="M290.3 9.42H290.33" stroke="black" stroke-width="0.5" />
    <path d="M290.35 9.42H290.38" stroke="black" stroke-width="0.5" />
    <path d="M290.4 9.42H290.43" stroke="black" stroke-width="0.5" />
    <path d="M290.45 9.42H290.48" stroke="black" stroke-width="0.5" />
    <path d="M290.5 9.42H290.53" stroke="black" stroke-width="0.5" />
    <path d="M290.55 9.42H290.58" stroke="black" stroke-width="0.5" />
    <path d="M290.6 9.42H290.63" stroke="black" stroke-width="0.5" />
    <path d="M290.65 9.42H290.68" stroke="black" stroke-width="0.5" />
    <path d="M290.7 9.42H290.73" stroke="black" stroke-width="0.5" />
    <path d="M290.75 9.42H290.78" stroke="black" stroke-width="0.5" />
    <path d="M290.8 9.42H290.83" stroke="black" stroke-width="0.5" />
    <path d="M290.85 9.42H290.88" stroke="black" stroke-width="0.5" />
    <path d="M290.9 9.42H290.93" stroke="black" stroke-width="0.5" />
    <path d="M290.95 9.42H290.98" stroke="black" stroke-width="0.5" />
    <path d="M291 9.42H291.03" stroke="black" stroke-width="0.5" />
    <path d="M291.05 9.42H291.08" stroke="black" stroke-width="0.5" />
    <path d="M291.1 9.42H291.13" stroke="black" stroke-width="0.5" />
    <path d="M291.15 9.42H291.18" stroke="black" stroke-width="0.5" />
    <path d="M291.2 9.42H291.23" stroke="black" stroke-width="0.5" />
    <path d="M291.25 9.42H291.28" stroke="black" stroke-width="0.5" />
    <path d="M291.3 9.42H291.33" stroke="black" stroke-width="0.5" />
    <path d="M291.35 9.42H291.38" stroke="black" stroke-width="0.5" />
    <path d="M291.4 9.42H291.43" stroke="black" stroke-width="0.5" />
    <path d="M291.45 9.42H291.48" stroke="black" stroke-width="0.5" />
    <path d="M291.5 9.42H291.53" stroke="black" stroke-width="0.5" />
    <path d="M291.55 9.42H291.58" stroke="black" stroke-width="0.5" />
    <path d="M291.6 9.42H291.63" stroke="black" stroke-width="0.5" />
    <path d="M291.65 9.42H291.68" stroke="black" stroke-width="0.5" />
    <path d="M291.7 9.42H291.73" stroke="black" stroke-width="0.5" />
    <path d="M291.75 9.42H291.78" stroke="black" stroke-width="0.5" />
    <path d="M291.8 9.42H291.83" stroke="black" stroke-width="0.5" />
    <path d="M291.85 9.42H291.88" stroke="black" stroke-width="0.5" />
    <path d="M291.9 9.42H291.93" stroke="black" stroke-width="0.5" />
    <path d="M291.95 9.42H291.98" stroke="black" stroke-width="0.5" />
    <path d="M292 9.42H292.03" stroke="black" stroke-width="0.5" />
    <path d="M292.05 9.42H292.08" stroke="black" stroke-width="0.5" />
    <path d="M292.1 9.42H292.13" stroke="black" stroke-width="0.5" />
    <path d="M292.15 9.42H292.18" stroke="black" stroke-width="0.5" />
    <path d="M292.2 9.42H292.23" stroke="black" stroke-width="0.5" />
    <path d="M292.25 9.42H292.28" stroke="black" stroke-width="0.5" />
    <path d="M292.3 9.42H292.33" stroke="black" stroke-width="0.5" />
    <path d="M292.35 9.42H292.38" stroke="black" stroke-width="0.5" />
    <path d="M292.4 9.42H292.43" stroke="black" stroke-width="0.5" />
    <path d="M292.45 9.42H292.48" stroke="black" stroke-width="0.5" />
    <path d="M292.5 9.42H292.53" stroke="black" stroke-width="0.5" />
    <path d="M292.55 9.42H292.58" stroke="black" stroke-width="0.5" />
    <path d="M292.6 9.42H292.63" stroke="black" stroke-width="0.5" />
    <path d="M292.65 9.42H292.68" stroke="black" stroke-width="0.5" />
    <path d="M292.7 9.42H292.73" stroke="black" stroke-width="0.5" />
    <path d="M292.75 9.42H292.78" stroke="black" stroke-width="0.5" />
    <path d="M292.8 9.42H292.83" stroke="black" stroke-width="0.5" />
    <path d="M292.85 9.42H292.88" stroke="black" stroke-width="0.5" />
    <path d="M292.9 9.42H292.93" stroke="black" stroke-width="0.5" />
    <path d="M292.95 9.42H292.98" stroke="black" stroke-width="0.5" />
    <path d="M293 9.42H293.03" stroke="black" stroke-width="0.5" />
    <path d="M293.05 9.42H293.08" stroke="black" stroke-width="0.5" />
    <path d="M293.1 9.42H293.13" stroke="black" stroke-width="0.5" />
    <path d="M293.15 9.42H293.18" stroke="black" stroke-width="0.5" />
    <path d="M293.2 9.42H293.23" stroke="black" stroke-width="0.5" />
    <path d="M293.25 9.42H293.28" stroke="black" stroke-width="0.5" />
    <path d="M293.3 9.42H293.33" stroke="black" stroke-width="0.5" />
    <path d="M293.35 9.42H293.38" stroke="black" stroke-width="0.5" />
    <path d="M293.4 9.42H293.43" stroke="black" stroke-width="0.5" />
    <path d="M293.45 9.42H293.48" stroke="black" stroke-width="0.5" />
    <path d="M293.5 9.42H293.53" stroke="black" stroke-width="0.5" />
    <path d="M293.55 9.42H293.58" stroke="black" stroke-width="0.5" />
    <path d="M293.6 9.42H293.63" stroke="black" stroke-width="0.5" />
    <path d="M293.65 9.42H293.68" stroke="black" stroke-width="0.5" />
    <path d="M293.7 9.42H293.73" stroke="black" stroke-width="0.5" />
    <path d="M293.75 9.42H293.78" stroke="black" stroke-width="0.5" />
    <path d="M293.8 9.42H293.83" stroke="black" stroke-width="0.5" />
    <path d="M293.85 9.42H293.87" stroke="black" stroke-width="0.5" />
    <path d="M293.87 9.42V9.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 9.37V9.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 9.32001V9.28999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 9.27V9.24001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 9.22V9.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 9.17V9.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 9.12V9.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 9.07001V9.03999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 9.02V8.99001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.97V8.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.92V8.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.87V8.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.82001V8.78999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.77V8.74001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.72V8.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.67V8.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.62V8.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.57001V8.53999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.52V8.49001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.47V8.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.42V8.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.37V8.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.32001V8.28999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.27V8.24001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.22V8.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.17V8.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.12V8.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.07001V8.03999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 8.02V7.99001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.97V7.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.92V7.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.87V7.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.82001V7.78999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.77V7.74001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.72V7.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.67V7.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.62V7.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.57001V7.53999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.52V7.49001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.47V7.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.42V7.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.37V7.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.32001V7.28999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.27V7.24001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.22V7.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.17V7.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.12V7.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.07001V7.03999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 7.02V6.99001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.97V6.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.92V6.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.87V6.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.82001V6.78999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.77V6.74001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.72V6.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.67V6.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.62V6.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.57001V6.53999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.52V6.49001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.47V6.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.42V6.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.37V6.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.32001V6.28999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.27V6.24001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.22V6.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.17V6.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.12V6.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.07001V6.03999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 6.02V5.99001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.97V5.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.92V5.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.87V5.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.82001V5.78999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.77V5.74001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.72V5.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.67V5.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.62V5.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.57001V5.53999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.52V5.49001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.47V5.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.42V5.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.37V5.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.32001V5.28999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.27V5.24001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.22V5.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.17V5.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.12V5.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.07001V5.03999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 5.02V4.99001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.97V4.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.92V4.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.87V4.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.82001V4.78999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.77V4.74001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.72V4.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.67V4.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.62V4.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.57001V4.53999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.52V4.49001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.47V4.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.42V4.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.37V4.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.32001V4.28999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.27V4.24001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.22V4.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.17V4.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.12V4.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.07001V4.03999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 4.02V3.99001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.97V3.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.92V3.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.87V3.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.82001V3.78999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.77V3.74001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.72V3.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.67V3.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.62V3.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.57001V3.53999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.52V3.49001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.47V3.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.42V3.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.37V3.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.32001V3.28999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.27V3.24001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.22V3.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.17V3.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.12V3.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.07001V3.03999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 3.02V2.99001" stroke="black" stroke-width="0.5" />
    <path d="M293.87 2.97V2.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 2.92V2.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 2.87V2.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 2.82001V2.78999" stroke="black" stroke-width="0.5" />
    <path d="M293.87 2.77V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M252.84 34.86V34.41" stroke="black" stroke-width="0.5" />
    <path d="M254.15 34.86H252.84" stroke="black" stroke-width="0.5" />
    <path d="M254.15 34.41V34.86" stroke="black" stroke-width="0.5" />
    <path d="M252.84 34.41H254.15" stroke="black" stroke-width="0.5" />
    <path d="M251.25 38.06L251.34 38.45" stroke="black" stroke-width="0.5" />
    <path d="M251.34 38.45L251.57 38.73" stroke="black" stroke-width="0.5" />
    <path d="M251.57 38.73L251.9 38.83" stroke="black" stroke-width="0.5" />
    <path d="M255.09 38.83L255.41 38.73" stroke="black" stroke-width="0.5" />
    <path d="M255.41 38.73L255.65 38.45" stroke="black" stroke-width="0.5" />
    <path d="M255.65 38.45L255.73 38.06" stroke="black" stroke-width="0.5" />
    <path d="M255.73 36.15L255.65 35.77" stroke="black" stroke-width="0.5" />
    <path d="M255.65 35.77L255.41 35.49" stroke="black" stroke-width="0.5" />
    <path d="M255.41 35.49L255.09 35.38" stroke="black" stroke-width="0.5" />
    <path d="M251.9 35.38L251.57 35.49" stroke="black" stroke-width="0.5" />
    <path d="M251.57 35.49L251.34 35.77" stroke="black" stroke-width="0.5" />
    <path d="M251.34 35.77L251.25 36.15" stroke="black" stroke-width="0.5" />
    <path d="M251.25 38.06V36.15" stroke="black" stroke-width="0.5" />
    <path d="M255.09 38.83H251.9" stroke="black" stroke-width="0.5" />
    <path d="M255.73 36.15V38.06" stroke="black" stroke-width="0.5" />
    <path d="M251.9 35.38H255.09" stroke="black" stroke-width="0.5" />
    <path d="M250.82 39.28V33.93" stroke="black" stroke-width="0.5" />
    <path d="M256.17 39.28H250.82" stroke="black" stroke-width="0.5" />
    <path d="M256.17 33.93V39.28" stroke="black" stroke-width="0.5" />
    <path d="M250.82 33.93H256.17" stroke="black" stroke-width="0.5" />
    <path d="M257.84 39.57H257.82" stroke="black" stroke-width="0.5" />
    <path d="M257.81 39.57H257.77" stroke="black" stroke-width="0.5" />
    <path d="M257.76 39.57H257.72" stroke="black" stroke-width="0.5" />
    <path d="M257.71 39.57H257.67" stroke="black" stroke-width="0.5" />
    <path d="M257.66 39.57H257.62" stroke="black" stroke-width="0.5" />
    <path d="M257.61 39.57H257.57" stroke="black" stroke-width="0.5" />
    <path d="M257.56 39.57H257.52" stroke="black" stroke-width="0.5" />
    <path d="M257.51 39.57H257.47" stroke="black" stroke-width="0.5" />
    <path d="M257.46 39.57H257.42" stroke="black" stroke-width="0.5" />
    <path d="M257.41 39.57H257.37" stroke="black" stroke-width="0.5" />
    <path d="M257.36 39.57H257.32" stroke="black" stroke-width="0.5" />
    <path d="M257.31 39.57H257.27" stroke="black" stroke-width="0.5" />
    <path d="M257.26 39.57H257.22" stroke="black" stroke-width="0.5" />
    <path d="M257.21 39.57H257.17" stroke="black" stroke-width="0.5" />
    <path d="M257.16 39.57H257.12" stroke="black" stroke-width="0.5" />
    <path d="M257.11 39.57H257.07" stroke="black" stroke-width="0.5" />
    <path d="M257.05 39.57H257.02" stroke="black" stroke-width="0.5" />
    <path d="M257 39.57H256.97" stroke="black" stroke-width="0.5" />
    <path d="M256.95 39.57H256.92" stroke="black" stroke-width="0.5" />
    <path d="M256.9 39.57H256.87" stroke="black" stroke-width="0.5" />
    <path d="M256.85 39.57H256.82" stroke="black" stroke-width="0.5" />
    <path d="M256.8 39.57H256.77" stroke="black" stroke-width="0.5" />
    <path d="M256.75 39.57H256.72" stroke="black" stroke-width="0.5" />
    <path d="M256.7 39.57H256.67" stroke="black" stroke-width="0.5" />
    <path d="M256.65 39.57H256.62" stroke="black" stroke-width="0.5" />
    <path d="M256.6 39.57H256.57" stroke="black" stroke-width="0.5" />
    <path d="M256.55 39.57H256.52" stroke="black" stroke-width="0.5" />
    <path d="M256.5 39.57H256.47" stroke="black" stroke-width="0.5" />
    <path d="M256.45 39.57H256.42" stroke="black" stroke-width="0.5" />
    <path d="M256.4 39.57H256.37" stroke="black" stroke-width="0.5" />
    <path d="M256.35 39.57H256.32" stroke="black" stroke-width="0.5" />
    <path d="M256.3 39.57H256.27" stroke="black" stroke-width="0.5" />
    <path d="M256.25 39.57H256.22" stroke="black" stroke-width="0.5" />
    <path d="M256.2 39.57H256.17" stroke="black" stroke-width="0.5" />
    <path d="M256.15 39.57H256.12" stroke="black" stroke-width="0.5" />
    <path d="M256.1 39.57H256.07" stroke="black" stroke-width="0.5" />
    <path d="M256.05 39.57H256.02" stroke="black" stroke-width="0.5" />
    <path d="M256 39.57H255.97" stroke="black" stroke-width="0.5" />
    <path d="M255.95 39.57H255.92" stroke="black" stroke-width="0.5" />
    <path d="M255.9 39.57H255.87" stroke="black" stroke-width="0.5" />
    <path d="M255.85 39.57H255.82" stroke="black" stroke-width="0.5" />
    <path d="M255.8 39.57H255.77" stroke="black" stroke-width="0.5" />
    <path d="M255.75 39.57H255.72" stroke="black" stroke-width="0.5" />
    <path d="M255.7 39.57H255.67" stroke="black" stroke-width="0.5" />
    <path d="M255.65 39.57H255.62" stroke="black" stroke-width="0.5" />
    <path d="M255.6 39.57H255.57" stroke="black" stroke-width="0.5" />
    <path d="M255.55 39.57H255.52" stroke="black" stroke-width="0.5" />
    <path d="M255.5 39.57H255.47" stroke="black" stroke-width="0.5" />
    <path d="M255.45 39.57H255.42" stroke="black" stroke-width="0.5" />
    <path d="M255.4 39.57H255.37" stroke="black" stroke-width="0.5" />
    <path d="M255.35 39.57H255.32" stroke="black" stroke-width="0.5" />
    <path d="M255.3 39.57H255.27" stroke="black" stroke-width="0.5" />
    <path d="M255.25 39.57H255.22" stroke="black" stroke-width="0.5" />
    <path d="M255.2 39.57H255.17" stroke="black" stroke-width="0.5" />
    <path d="M255.15 39.57H255.12" stroke="black" stroke-width="0.5" />
    <path d="M255.1 39.57H255.07" stroke="black" stroke-width="0.5" />
    <path d="M255.05 39.57H255.02" stroke="black" stroke-width="0.5" />
    <path d="M255 39.57H254.97" stroke="black" stroke-width="0.5" />
    <path d="M254.95 39.57H254.92" stroke="black" stroke-width="0.5" />
    <path d="M254.9 39.57H254.87" stroke="black" stroke-width="0.5" />
    <path d="M254.85 39.57H254.82" stroke="black" stroke-width="0.5" />
    <path d="M254.8 39.57H254.77" stroke="black" stroke-width="0.5" />
    <path d="M254.75 39.57H254.72" stroke="black" stroke-width="0.5" />
    <path d="M254.7 39.57H254.67" stroke="black" stroke-width="0.5" />
    <path d="M254.65 39.57H254.62" stroke="black" stroke-width="0.5" />
    <path d="M254.6 39.57H254.57" stroke="black" stroke-width="0.5" />
    <path d="M254.55 39.57H254.52" stroke="black" stroke-width="0.5" />
    <path d="M254.5 39.57H254.47" stroke="black" stroke-width="0.5" />
    <path d="M254.45 39.57H254.42" stroke="black" stroke-width="0.5" />
    <path d="M254.4 39.57H254.37" stroke="black" stroke-width="0.5" />
    <path d="M254.35 39.57H254.32" stroke="black" stroke-width="0.5" />
    <path d="M254.3 39.57H254.27" stroke="black" stroke-width="0.5" />
    <path d="M254.25 39.57H254.22" stroke="black" stroke-width="0.5" />
    <path d="M254.2 39.57H254.17" stroke="black" stroke-width="0.5" />
    <path d="M254.15 39.57H254.12" stroke="black" stroke-width="0.5" />
    <path d="M254.1 39.57H254.07" stroke="black" stroke-width="0.5" />
    <path d="M254.05 39.57H254.02" stroke="black" stroke-width="0.5" />
    <path d="M254 39.57H253.97" stroke="black" stroke-width="0.5" />
    <path d="M253.95 39.57H253.92" stroke="black" stroke-width="0.5" />
    <path d="M253.9 39.57H253.87" stroke="black" stroke-width="0.5" />
    <path d="M253.85 39.57H253.82" stroke="black" stroke-width="0.5" />
    <path d="M253.8 39.57H253.77" stroke="black" stroke-width="0.5" />
    <path d="M253.75 39.57H253.72" stroke="black" stroke-width="0.5" />
    <path d="M253.7 39.57H253.67" stroke="black" stroke-width="0.5" />
    <path d="M253.65 39.57H253.62" stroke="black" stroke-width="0.5" />
    <path d="M253.6 39.57H253.57" stroke="black" stroke-width="0.5" />
    <path d="M253.55 39.57H253.52" stroke="black" stroke-width="0.5" />
    <path d="M253.5 39.57H253.47" stroke="black" stroke-width="0.5" />
    <path d="M253.45 39.57H253.42" stroke="black" stroke-width="0.5" />
    <path d="M253.4 39.57H253.37" stroke="black" stroke-width="0.5" />
    <path d="M253.35 39.57H253.32" stroke="black" stroke-width="0.5" />
    <path d="M253.3 39.57H253.27" stroke="black" stroke-width="0.5" />
    <path d="M253.25 39.57H253.22" stroke="black" stroke-width="0.5" />
    <path d="M253.2 39.57H253.17" stroke="black" stroke-width="0.5" />
    <path d="M253.15 39.57H253.12" stroke="black" stroke-width="0.5" />
    <path d="M253.1 39.57H253.07" stroke="black" stroke-width="0.5" />
    <path d="M253.05 39.57H253.02" stroke="black" stroke-width="0.5" />
    <path d="M253 39.57H252.97" stroke="black" stroke-width="0.5" />
    <path d="M252.95 39.57H252.92" stroke="black" stroke-width="0.5" />
    <path d="M252.9 39.57H252.87" stroke="black" stroke-width="0.5" />
    <path d="M252.85 39.57H252.82" stroke="black" stroke-width="0.5" />
    <path d="M252.8 39.57H252.77" stroke="black" stroke-width="0.5" />
    <path d="M252.75 39.57H252.72" stroke="black" stroke-width="0.5" />
    <path d="M252.7 39.57H252.67" stroke="black" stroke-width="0.5" />
    <path d="M252.65 39.57H252.62" stroke="black" stroke-width="0.5" />
    <path d="M252.6 39.57H252.57" stroke="black" stroke-width="0.5" />
    <path d="M252.55 39.57H252.52" stroke="black" stroke-width="0.5" />
    <path d="M252.5 39.57H252.47" stroke="black" stroke-width="0.5" />
    <path d="M252.45 39.57H252.42" stroke="black" stroke-width="0.5" />
    <path d="M252.4 39.57H252.37" stroke="black" stroke-width="0.5" />
    <path d="M252.35 39.57H252.32" stroke="black" stroke-width="0.5" />
    <path d="M252.3 39.57H252.27" stroke="black" stroke-width="0.5" />
    <path d="M252.25 39.57H252.22" stroke="black" stroke-width="0.5" />
    <path d="M252.2 39.57H252.17" stroke="black" stroke-width="0.5" />
    <path d="M252.15 39.57H252.12" stroke="black" stroke-width="0.5" />
    <path d="M252.1 39.57H252.07" stroke="black" stroke-width="0.5" />
    <path d="M252.05 39.57H252.02" stroke="black" stroke-width="0.5" />
    <path d="M252 39.57H251.97" stroke="black" stroke-width="0.5" />
    <path d="M251.95 39.57H251.92" stroke="black" stroke-width="0.5" />
    <path d="M251.9 39.57H251.87" stroke="black" stroke-width="0.5" />
    <path d="M251.85 39.57H251.82" stroke="black" stroke-width="0.5" />
    <path d="M251.8 39.57H251.77" stroke="black" stroke-width="0.5" />
    <path d="M251.75 39.57H251.72" stroke="black" stroke-width="0.5" />
    <path d="M251.7 39.57H251.67" stroke="black" stroke-width="0.5" />
    <path d="M251.65 39.57H251.62" stroke="black" stroke-width="0.5" />
    <path d="M251.6 39.57H251.57" stroke="black" stroke-width="0.5" />
    <path d="M251.55 39.57H251.52" stroke="black" stroke-width="0.5" />
    <path d="M251.5 39.57H251.47" stroke="black" stroke-width="0.5" />
    <path d="M251.45 39.57H251.42" stroke="black" stroke-width="0.5" />
    <path d="M251.4 39.57H251.37" stroke="black" stroke-width="0.5" />
    <path d="M251.35 39.57H251.32" stroke="black" stroke-width="0.5" />
    <path d="M251.3 39.57H251.27" stroke="black" stroke-width="0.5" />
    <path d="M251.25 39.57H251.22" stroke="black" stroke-width="0.5" />
    <path d="M251.2 39.57H251.17" stroke="black" stroke-width="0.5" />
    <path d="M251.15 39.57H251.12" stroke="black" stroke-width="0.5" />
    <path d="M251.1 39.57H251.07" stroke="black" stroke-width="0.5" />
    <path d="M251.05 39.57H251.02" stroke="black" stroke-width="0.5" />
    <path d="M251 39.57H250.97" stroke="black" stroke-width="0.5" />
    <path d="M250.95 39.57H250.92" stroke="black" stroke-width="0.5" />
    <path d="M250.9 39.57H250.87" stroke="black" stroke-width="0.5" />
    <path d="M250.85 39.57H250.82" stroke="black" stroke-width="0.5" />
    <path d="M250.8 39.57H250.77" stroke="black" stroke-width="0.5" />
    <path d="M250.75 39.57H250.72" stroke="black" stroke-width="0.5" />
    <path d="M250.7 39.57H250.67" stroke="black" stroke-width="0.5" />
    <path d="M250.65 39.57H250.62" stroke="black" stroke-width="0.5" />
    <path d="M250.6 39.57H250.57" stroke="black" stroke-width="0.5" />
    <path d="M250.55 39.57H250.52" stroke="black" stroke-width="0.5" />
    <path d="M250.5 39.57H250.47" stroke="black" stroke-width="0.5" />
    <path d="M250.45 39.57H250.42" stroke="black" stroke-width="0.5" />
    <path d="M250.4 39.57H250.37" stroke="black" stroke-width="0.5" />
    <path d="M250.35 39.57H250.32" stroke="black" stroke-width="0.5" />
    <path d="M250.3 39.57H250.27" stroke="black" stroke-width="0.5" />
    <path d="M250.25 39.57H250.22" stroke="black" stroke-width="0.5" />
    <path d="M250.2 39.57H250.17" stroke="black" stroke-width="0.5" />
    <path d="M250.15 39.57H250.12" stroke="black" stroke-width="0.5" />
    <path d="M250.1 39.57H250.07" stroke="black" stroke-width="0.5" />
    <path d="M250.05 39.57H250.02" stroke="black" stroke-width="0.5" />
    <path d="M250 39.57H249.97" stroke="black" stroke-width="0.5" />
    <path d="M249.95 39.57H249.92" stroke="black" stroke-width="0.5" />
    <path d="M249.9 39.57H249.87" stroke="black" stroke-width="0.5" />
    <path d="M249.85 39.57H249.82" stroke="black" stroke-width="0.5" />
    <path d="M249.8 39.57H249.77" stroke="black" stroke-width="0.5" />
    <path d="M249.75 39.57H249.72" stroke="black" stroke-width="0.5" />
    <path d="M249.7 39.57H249.67" stroke="black" stroke-width="0.5" />
    <path d="M249.65 39.57H249.62" stroke="black" stroke-width="0.5" />
    <path d="M249.6 39.57H249.57" stroke="black" stroke-width="0.5" />
    <path d="M249.55 39.57H249.52" stroke="black" stroke-width="0.5" />
    <path d="M249.5 39.57H249.47" stroke="black" stroke-width="0.5" />
    <path d="M249.45 39.57H249.42" stroke="black" stroke-width="0.5" />
    <path d="M249.4 39.57H249.37" stroke="black" stroke-width="0.5" />
    <path d="M249.35 39.57H249.32" stroke="black" stroke-width="0.5" />
    <path d="M249.3 39.57H249.27" stroke="black" stroke-width="0.5" />
    <path d="M249.25 39.57H249.22" stroke="black" stroke-width="0.5" />
    <path d="M249.2 39.57H249.17" stroke="black" stroke-width="0.5" />
    <path d="M249.15 39.57H249.12" stroke="black" stroke-width="0.5" />
    <path d="M249.1 39.57H249.07" stroke="black" stroke-width="0.5" />
    <path d="M249.05 39.57H249.02" stroke="black" stroke-width="0.5" />
    <path d="M249 39.57H248.97" stroke="black" stroke-width="0.5" />
    <path d="M248.95 39.57H248.92" stroke="black" stroke-width="0.5" />
    <path d="M248.9 39.57H248.87" stroke="black" stroke-width="0.5" />
    <path d="M248.85 39.57H248.82" stroke="black" stroke-width="0.5" />
    <path d="M248.8 39.57H248.77" stroke="black" stroke-width="0.5" />
    <path d="M248.75 39.57H248.72" stroke="black" stroke-width="0.5" />
    <path d="M248.7 39.57H248.67" stroke="black" stroke-width="0.5" />
    <path d="M248.65 39.57H248.62" stroke="black" stroke-width="0.5" />
    <path d="M248.6 39.57H248.57" stroke="black" stroke-width="0.5" />
    <path d="M248.55 39.57H248.52" stroke="black" stroke-width="0.5" />
    <path d="M248.5 39.57H248.47" stroke="black" stroke-width="0.5" />
    <path d="M248.45 39.57H248.42" stroke="black" stroke-width="0.5" />
    <path d="M248.4 39.57H248.37" stroke="black" stroke-width="0.5" />
    <path d="M248.35 39.57H248.32" stroke="black" stroke-width="0.5" />
    <path d="M248.3 39.57H248.27" stroke="black" stroke-width="0.5" />
    <path d="M248.25 39.57H248.22" stroke="black" stroke-width="0.5" />
    <path d="M248.2 39.57H248.17" stroke="black" stroke-width="0.5" />
    <path d="M248.15 39.57H248.12" stroke="black" stroke-width="0.5" />
    <path d="M248.1 39.57H248.07" stroke="black" stroke-width="0.5" />
    <path d="M248.05 39.57H248.02" stroke="black" stroke-width="0.5" />
    <path d="M248 39.57H247.96" stroke="black" stroke-width="0.5" />
    <path d="M247.95 39.57H247.91" stroke="black" stroke-width="0.5" />
    <path d="M247.9 39.57H247.86" stroke="black" stroke-width="0.5" />
    <path d="M247.85 39.57H247.81" stroke="black" stroke-width="0.5" />
    <path d="M247.8 39.57H247.76" stroke="black" stroke-width="0.5" />
    <path d="M247.75 39.57H247.71" stroke="black" stroke-width="0.5" />
    <path d="M247.7 39.57H247.66" stroke="black" stroke-width="0.5" />
    <path d="M247.65 39.57H247.61" stroke="black" stroke-width="0.5" />
    <path d="M247.6 39.57H247.56" stroke="black" stroke-width="0.5" />
    <path d="M247.55 39.57H247.51" stroke="black" stroke-width="0.5" />
    <path d="M247.5 39.57H247.46" stroke="black" stroke-width="0.5" />
    <path d="M247.45 39.57H247.41" stroke="black" stroke-width="0.5" />
    <path d="M247.4 39.57H247.36" stroke="black" stroke-width="0.5" />
    <path d="M247.35 39.57H247.31" stroke="black" stroke-width="0.5" />
    <path d="M247.3 39.57H247.26" stroke="black" stroke-width="0.5" />
    <path d="M247.25 39.57H247.21" stroke="black" stroke-width="0.5" />
    <path d="M247.2 39.57H247.16" stroke="black" stroke-width="0.5" />
    <path d="M247.15 39.57H247.11" stroke="black" stroke-width="0.5" />
    <path d="M247.1 39.57H247.06" stroke="black" stroke-width="0.5" />
    <path d="M247.05 39.57H247.01" stroke="black" stroke-width="0.5" />
    <path d="M247 39.57H246.96" stroke="black" stroke-width="0.5" />
    <path d="M246.95 39.57H246.91" stroke="black" stroke-width="0.5" />
    <path d="M246.9 39.57H246.86" stroke="black" stroke-width="0.5" />
    <path d="M246.85 39.57H246.81" stroke="black" stroke-width="0.5" />
    <path d="M246.8 39.57H246.76" stroke="black" stroke-width="0.5" />
    <path d="M246.75 39.57H246.71" stroke="black" stroke-width="0.5" />
    <path d="M246.7 39.57H246.66" stroke="black" stroke-width="0.5" />
    <path d="M246.65 39.57H246.61" stroke="black" stroke-width="0.5" />
    <path d="M246.6 39.57H246.56" stroke="black" stroke-width="0.5" />
    <path d="M246.55 39.57H246.51" stroke="black" stroke-width="0.5" />
    <path d="M246.5 39.57H246.46" stroke="black" stroke-width="0.5" />
    <path d="M246.45 39.57H246.41" stroke="black" stroke-width="0.5" />
    <path d="M246.4 39.57H246.36" stroke="black" stroke-width="0.5" />
    <path d="M246.35 39.57H246.31" stroke="black" stroke-width="0.5" />
    <path d="M246.3 39.57H246.26" stroke="black" stroke-width="0.5" />
    <path d="M246.25 39.57H246.21" stroke="black" stroke-width="0.5" />
    <path d="M246.2 39.57H246.16" stroke="black" stroke-width="0.5" />
    <path d="M246.15 39.57H246.11" stroke="black" stroke-width="0.5" />
    <path d="M246.1 39.57H246.06" stroke="black" stroke-width="0.5" />
    <path d="M246.05 39.57H246.01" stroke="black" stroke-width="0.5" />
    <path d="M246 39.57H245.96" stroke="black" stroke-width="0.5" />
    <path d="M245.95 39.57H245.91" stroke="black" stroke-width="0.5" />
    <path d="M245.9 39.57H245.86" stroke="black" stroke-width="0.5" />
    <path d="M245.85 39.57H245.81" stroke="black" stroke-width="0.5" />
    <path d="M245.8 39.57H245.76" stroke="black" stroke-width="0.5" />
    <path d="M245.75 39.57H245.71" stroke="black" stroke-width="0.5" />
    <path d="M245.7 39.57H245.66" stroke="black" stroke-width="0.5" />
    <path d="M245.65 39.57H245.61" stroke="black" stroke-width="0.5" />
    <path d="M245.6 39.57H245.56" stroke="black" stroke-width="0.5" />
    <path d="M245.55 39.57H245.51" stroke="black" stroke-width="0.5" />
    <path d="M245.5 39.57H245.46" stroke="black" stroke-width="0.5" />
    <path d="M245.45 39.57H245.41" stroke="black" stroke-width="0.5" />
    <path d="M245.4 39.57H245.36" stroke="black" stroke-width="0.5" />
    <path d="M245.35 39.57H245.31" stroke="black" stroke-width="0.5" />
    <path d="M245.3 39.57H245.26" stroke="black" stroke-width="0.5" />
    <path d="M245.25 39.57H245.21" stroke="black" stroke-width="0.5" />
    <path d="M245.2 39.57H245.16" stroke="black" stroke-width="0.5" />
    <path d="M245.15 39.57H245.11" stroke="black" stroke-width="0.5" />
    <path d="M245.1 39.57H245.06" stroke="black" stroke-width="0.5" />
    <path d="M245.05 39.57H245.01" stroke="black" stroke-width="0.5" />
    <path d="M245 39.57H244.96" stroke="black" stroke-width="0.5" />
    <path d="M244.95 39.57H244.91" stroke="black" stroke-width="0.5" />
    <path d="M244.9 39.57H244.86" stroke="black" stroke-width="0.5" />
    <path d="M244.85 39.57H244.81" stroke="black" stroke-width="0.5" />
    <path d="M244.8 39.57H244.76" stroke="black" stroke-width="0.5" />
    <path d="M244.75 39.57H244.71" stroke="black" stroke-width="0.5" />
    <path d="M244.7 39.57H244.66" stroke="black" stroke-width="0.5" />
    <path d="M244.65 39.57H244.61" stroke="black" stroke-width="0.5" />
    <path d="M244.6 39.57H244.56" stroke="black" stroke-width="0.5" />
    <path d="M244.55 39.57H244.51" stroke="black" stroke-width="0.5" />
    <path d="M244.5 39.57H244.46" stroke="black" stroke-width="0.5" />
    <path d="M244.45 39.57H244.41" stroke="black" stroke-width="0.5" />
    <path d="M244.4 39.57H244.36" stroke="black" stroke-width="0.5" />
    <path d="M244.35 39.57H244.31" stroke="black" stroke-width="0.5" />
    <path d="M244.3 39.57H244.26" stroke="black" stroke-width="0.5" />
    <path d="M244.25 39.57H244.21" stroke="black" stroke-width="0.5" />
    <path d="M244.2 39.57H244.16" stroke="black" stroke-width="0.5" />
    <path d="M244.15 39.57H244.11" stroke="black" stroke-width="0.5" />
    <path d="M244.1 39.57H244.06" stroke="black" stroke-width="0.5" />
    <path d="M244.05 39.57H244.01" stroke="black" stroke-width="0.5" />
    <path d="M244 39.57H243.96" stroke="black" stroke-width="0.5" />
    <path d="M243.95 39.57H243.91" stroke="black" stroke-width="0.5" />
    <path d="M243.9 39.57H243.86" stroke="black" stroke-width="0.5" />
    <path d="M243.85 39.57H243.81" stroke="black" stroke-width="0.5" />
    <path d="M243.8 39.57H243.76" stroke="black" stroke-width="0.5" />
    <path d="M243.75 39.57H243.71" stroke="black" stroke-width="0.5" />
    <path d="M243.7 39.57H243.66" stroke="black" stroke-width="0.5" />
    <path d="M243.65 39.57H243.61" stroke="black" stroke-width="0.5" />
    <path d="M243.6 39.57H243.56" stroke="black" stroke-width="0.5" />
    <path d="M243.55 39.57H243.51" stroke="black" stroke-width="0.5" />
    <path d="M243.5 39.57H243.46" stroke="black" stroke-width="0.5" />
    <path d="M243.45 39.57H243.41" stroke="black" stroke-width="0.5" />
    <path d="M243.39 39.57H243.36" stroke="black" stroke-width="0.5" />
    <path d="M243.34 39.57H243.31" stroke="black" stroke-width="0.5" />
    <path d="M243.29 39.57H243.26" stroke="black" stroke-width="0.5" />
    <path d="M243.24 39.57H243.21" stroke="black" stroke-width="0.5" />
    <path d="M243.19 39.57H243.16" stroke="black" stroke-width="0.5" />
    <path d="M243.14 39.57H243.11" stroke="black" stroke-width="0.5" />
    <path d="M243.09 39.57H243.06" stroke="black" stroke-width="0.5" />
    <path d="M243.04 39.57H243.01" stroke="black" stroke-width="0.5" />
    <path d="M242.99 39.57H242.96" stroke="black" stroke-width="0.5" />
    <path d="M242.94 39.57H242.91" stroke="black" stroke-width="0.5" />
    <path d="M242.89 39.57H242.86" stroke="black" stroke-width="0.5" />
    <path d="M242.84 39.57H242.81" stroke="black" stroke-width="0.5" />
    <path d="M242.79 39.57H242.76" stroke="black" stroke-width="0.5" />
    <path d="M242.74 39.57H242.71" stroke="black" stroke-width="0.5" />
    <path d="M242.69 39.57H242.66" stroke="black" stroke-width="0.5" />
    <path d="M242.64 39.57H242.61" stroke="black" stroke-width="0.5" />
    <path d="M242.59 39.57H242.56" stroke="black" stroke-width="0.5" />
    <path d="M242.54 39.57H242.51" stroke="black" stroke-width="0.5" />
    <path d="M242.49 39.57H242.46" stroke="black" stroke-width="0.5" />
    <path d="M242.44 39.57H242.41" stroke="black" stroke-width="0.5" />
    <path d="M242.39 39.57H242.36" stroke="black" stroke-width="0.5" />
    <path d="M242.34 39.57H242.31" stroke="black" stroke-width="0.5" />
    <path d="M242.29 39.57H242.26" stroke="black" stroke-width="0.5" />
    <path d="M242.24 39.57H242.21" stroke="black" stroke-width="0.5" />
    <path d="M242.19 39.57H242.16" stroke="black" stroke-width="0.5" />
    <path d="M242.14 39.57H242.11" stroke="black" stroke-width="0.5" />
    <path d="M242.09 39.57H242.06" stroke="black" stroke-width="0.5" />
    <path d="M242.04 39.57H242.01" stroke="black" stroke-width="0.5" />
    <path d="M241.99 39.57H241.96" stroke="black" stroke-width="0.5" />
    <path d="M241.94 39.57H241.91" stroke="black" stroke-width="0.5" />
    <path d="M241.89 39.57H241.86" stroke="black" stroke-width="0.5" />
    <path d="M241.84 39.57H241.83" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.57V39.55" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.53V39.5" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.48V39.45" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.43V39.4" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.38V39.35" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.33V39.3" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.28V39.25" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.23V39.2" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.18V39.15" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.13V39.1" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.08V39.05" stroke="black" stroke-width="0.5" />
    <path d="M241.83 39.03V39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.98V38.95" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.93V38.9" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.88V38.85" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.83V38.8" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.78V38.75" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.73V38.7" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.68V38.65" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.63V38.6" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.58V38.55" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.53V38.5" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.48V38.45" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.43V38.4" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.38V38.35" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.33V38.3" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.28V38.25" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.23V38.2" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.18V38.15" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.13V38.1" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.08V38.04" stroke="black" stroke-width="0.5" />
    <path d="M241.83 38.03V37.99" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.98V37.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.93V37.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.88V37.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.83V37.79" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.78V37.74" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.73V37.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.68V37.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.63V37.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.58V37.54" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.53V37.49" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.48V37.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.43V37.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.38V37.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.33V37.29" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.28V37.24" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.23V37.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.18V37.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.13V37.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.08V37.04" stroke="black" stroke-width="0.5" />
    <path d="M241.83 37.03V36.99" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.98V36.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.93V36.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.88V36.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.83V36.79" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.78V36.74" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.73V36.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.68V36.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.63V36.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.58V36.54" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.53V36.49" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.48V36.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.43V36.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.38V36.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.33V36.29" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.28V36.24" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.23V36.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.18V36.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.13V36.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.08V36.04" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.03V35.99" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.98V35.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.93V35.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.88V35.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.83V35.79" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.78V35.74" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.73V35.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.68V35.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.63V35.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.58V35.54" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.53V35.49" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.48V35.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.43V35.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.38V35.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.33V35.29" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.28V35.24" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.23V35.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.18V35.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.13V35.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.08V35.04" stroke="black" stroke-width="0.5" />
    <path d="M241.83 35.03V34.99" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.98V34.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.93V34.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.88V34.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.83V34.79" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.78V34.74" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.73V34.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.68V34.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.63V34.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.58V34.54" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.53V34.49" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.48V34.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.43V34.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.38V34.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.33V34.29" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.28V34.24" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.23V34.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.18V34.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.13V34.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.08V34.04" stroke="black" stroke-width="0.5" />
    <path d="M241.83 34.03V33.99" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.98V33.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.93V33.89" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.88V33.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.83V33.79" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.78V33.74" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.73V33.69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.68V33.64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.63V33.59" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.58V33.54" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.53V33.49" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.47V33.44" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.42V33.39" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.37V33.34" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.32V33.29" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.27V33.24" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.22V33.19" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.17V33.14" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.12V33.09" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.07V33.04" stroke="black" stroke-width="0.5" />
    <path d="M241.83 33.02V32.99" stroke="black" stroke-width="0.5" />
    <path d="M241.83 32.97V32.94" stroke="black" stroke-width="0.5" />
    <path d="M241.83 32.92V32.9" stroke="black" stroke-width="0.5" />
    <path d="M282.86 34.86V34.41" stroke="black" stroke-width="0.5" />
    <path d="M281.54 34.86H282.86" stroke="black" stroke-width="0.5" />
    <path d="M281.54 34.41V34.86" stroke="black" stroke-width="0.5" />
    <path d="M282.86 34.41H281.54" stroke="black" stroke-width="0.5" />
    <path d="M284.44 38.06L284.35 38.45" stroke="black" stroke-width="0.5" />
    <path d="M284.35 38.45L284.12 38.73" stroke="black" stroke-width="0.5" />
    <path d="M284.12 38.73L283.8 38.83" stroke="black" stroke-width="0.5" />
    <path d="M280.6 38.83L280.28 38.73" stroke="black" stroke-width="0.5" />
    <path d="M280.28 38.73L280.05 38.45" stroke="black" stroke-width="0.5" />
    <path d="M280.05 38.45L279.96 38.06" stroke="black" stroke-width="0.5" />
    <path d="M279.96 36.15L280.05 35.77" stroke="black" stroke-width="0.5" />
    <path d="M280.05 35.77L280.28 35.49" stroke="black" stroke-width="0.5" />
    <path d="M280.28 35.49L280.6 35.38" stroke="black" stroke-width="0.5" />
    <path d="M283.8 35.38L284.12 35.49" stroke="black" stroke-width="0.5" />
    <path d="M284.12 35.49L284.35 35.77" stroke="black" stroke-width="0.5" />
    <path d="M284.35 35.77L284.44 36.15" stroke="black" stroke-width="0.5" />
    <path d="M284.44 38.06V36.15" stroke="black" stroke-width="0.5" />
    <path d="M280.6 38.83H283.8" stroke="black" stroke-width="0.5" />
    <path d="M279.96 36.15V38.06" stroke="black" stroke-width="0.5" />
    <path d="M283.8 35.38H280.6" stroke="black" stroke-width="0.5" />
    <path d="M284.88 39.28V33.93" stroke="black" stroke-width="0.5" />
    <path d="M279.52 39.28H284.88" stroke="black" stroke-width="0.5" />
    <path d="M279.52 33.93V39.28" stroke="black" stroke-width="0.5" />
    <path d="M284.88 33.93H279.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 39.57H277.87" stroke="black" stroke-width="0.5" />
    <path d="M277.89 39.57H277.92" stroke="black" stroke-width="0.5" />
    <path d="M277.94 39.57H277.97" stroke="black" stroke-width="0.5" />
    <path d="M277.99 39.57H278.02" stroke="black" stroke-width="0.5" />
    <path d="M278.04 39.57H278.07" stroke="black" stroke-width="0.5" />
    <path d="M278.09 39.57H278.12" stroke="black" stroke-width="0.5" />
    <path d="M278.14 39.57H278.17" stroke="black" stroke-width="0.5" />
    <path d="M278.19 39.57H278.22" stroke="black" stroke-width="0.5" />
    <path d="M278.24 39.57H278.27" stroke="black" stroke-width="0.5" />
    <path d="M278.29 39.57H278.32" stroke="black" stroke-width="0.5" />
    <path d="M278.34 39.57H278.37" stroke="black" stroke-width="0.5" />
    <path d="M278.39 39.57H278.42" stroke="black" stroke-width="0.5" />
    <path d="M278.44 39.57H278.47" stroke="black" stroke-width="0.5" />
    <path d="M278.49 39.57H278.52" stroke="black" stroke-width="0.5" />
    <path d="M278.54 39.57H278.57" stroke="black" stroke-width="0.5" />
    <path d="M278.59 39.57H278.62" stroke="black" stroke-width="0.5" />
    <path d="M278.64 39.57H278.67" stroke="black" stroke-width="0.5" />
    <path d="M278.69 39.57H278.72" stroke="black" stroke-width="0.5" />
    <path d="M278.74 39.57H278.77" stroke="black" stroke-width="0.5" />
    <path d="M278.79 39.57H278.82" stroke="black" stroke-width="0.5" />
    <path d="M278.84 39.57H278.87" stroke="black" stroke-width="0.5" />
    <path d="M278.89 39.57H278.92" stroke="black" stroke-width="0.5" />
    <path d="M278.94 39.57H278.97" stroke="black" stroke-width="0.5" />
    <path d="M278.99 39.57H279.02" stroke="black" stroke-width="0.5" />
    <path d="M279.04 39.57H279.07" stroke="black" stroke-width="0.5" />
    <path d="M279.09 39.57H279.12" stroke="black" stroke-width="0.5" />
    <path d="M279.14 39.57H279.17" stroke="black" stroke-width="0.5" />
    <path d="M279.19 39.57H279.22" stroke="black" stroke-width="0.5" />
    <path d="M279.24 39.57H279.27" stroke="black" stroke-width="0.5" />
    <path d="M279.29 39.57H279.32" stroke="black" stroke-width="0.5" />
    <path d="M279.34 39.57H279.37" stroke="black" stroke-width="0.5" />
    <path d="M279.39 39.57H279.42" stroke="black" stroke-width="0.5" />
    <path d="M279.44 39.57H279.47" stroke="black" stroke-width="0.5" />
    <path d="M279.49 39.57H279.52" stroke="black" stroke-width="0.5" />
    <path d="M279.54 39.57H279.57" stroke="black" stroke-width="0.5" />
    <path d="M279.59 39.57H279.62" stroke="black" stroke-width="0.5" />
    <path d="M279.64 39.57H279.67" stroke="black" stroke-width="0.5" />
    <path d="M279.69 39.57H279.72" stroke="black" stroke-width="0.5" />
    <path d="M279.74 39.57H279.77" stroke="black" stroke-width="0.5" />
    <path d="M279.79 39.57H279.82" stroke="black" stroke-width="0.5" />
    <path d="M279.84 39.57H279.87" stroke="black" stroke-width="0.5" />
    <path d="M279.89 39.57H279.92" stroke="black" stroke-width="0.5" />
    <path d="M279.94 39.57H279.97" stroke="black" stroke-width="0.5" />
    <path d="M279.99 39.57H280.02" stroke="black" stroke-width="0.5" />
    <path d="M280.04 39.57H280.07" stroke="black" stroke-width="0.5" />
    <path d="M280.09 39.57H280.12" stroke="black" stroke-width="0.5" />
    <path d="M280.14 39.57H280.17" stroke="black" stroke-width="0.5" />
    <path d="M280.19 39.57H280.22" stroke="black" stroke-width="0.5" />
    <path d="M280.24 39.57H280.27" stroke="black" stroke-width="0.5" />
    <path d="M280.29 39.57H280.32" stroke="black" stroke-width="0.5" />
    <path d="M280.34 39.57H280.37" stroke="black" stroke-width="0.5" />
    <path d="M280.39 39.57H280.42" stroke="black" stroke-width="0.5" />
    <path d="M280.44 39.57H280.47" stroke="black" stroke-width="0.5" />
    <path d="M280.49 39.57H280.52" stroke="black" stroke-width="0.5" />
    <path d="M280.54 39.57H280.57" stroke="black" stroke-width="0.5" />
    <path d="M280.59 39.57H280.62" stroke="black" stroke-width="0.5" />
    <path d="M280.64 39.57H280.67" stroke="black" stroke-width="0.5" />
    <path d="M280.69 39.57H280.72" stroke="black" stroke-width="0.5" />
    <path d="M280.74 39.57H280.77" stroke="black" stroke-width="0.5" />
    <path d="M280.79 39.57H280.82" stroke="black" stroke-width="0.5" />
    <path d="M280.84 39.57H280.87" stroke="black" stroke-width="0.5" />
    <path d="M280.89 39.57H280.92" stroke="black" stroke-width="0.5" />
    <path d="M280.94 39.57H280.97" stroke="black" stroke-width="0.5" />
    <path d="M280.99 39.57H281.02" stroke="black" stroke-width="0.5" />
    <path d="M281.04 39.57H281.07" stroke="black" stroke-width="0.5" />
    <path d="M281.09 39.57H281.12" stroke="black" stroke-width="0.5" />
    <path d="M281.14 39.57H281.17" stroke="black" stroke-width="0.5" />
    <path d="M281.19 39.57H281.22" stroke="black" stroke-width="0.5" />
    <path d="M281.24 39.57H281.27" stroke="black" stroke-width="0.5" />
    <path d="M281.29 39.57H281.32" stroke="black" stroke-width="0.5" />
    <path d="M281.34 39.57H281.37" stroke="black" stroke-width="0.5" />
    <path d="M281.39 39.57H281.42" stroke="black" stroke-width="0.5" />
    <path d="M281.44 39.57H281.47" stroke="black" stroke-width="0.5" />
    <path d="M281.49 39.57H281.52" stroke="black" stroke-width="0.5" />
    <path d="M281.54 39.57H281.57" stroke="black" stroke-width="0.5" />
    <path d="M281.59 39.57H281.62" stroke="black" stroke-width="0.5" />
    <path d="M281.64 39.57H281.67" stroke="black" stroke-width="0.5" />
    <path d="M281.69 39.57H281.72" stroke="black" stroke-width="0.5" />
    <path d="M281.74 39.57H281.77" stroke="black" stroke-width="0.5" />
    <path d="M281.79 39.57H281.82" stroke="black" stroke-width="0.5" />
    <path d="M281.84 39.57H281.87" stroke="black" stroke-width="0.5" />
    <path d="M281.89 39.57H281.92" stroke="black" stroke-width="0.5" />
    <path d="M281.94 39.57H281.97" stroke="black" stroke-width="0.5" />
    <path d="M281.99 39.57H282.02" stroke="black" stroke-width="0.5" />
    <path d="M282.04 39.57H282.07" stroke="black" stroke-width="0.5" />
    <path d="M282.09 39.57H282.12" stroke="black" stroke-width="0.5" />
    <path d="M282.14 39.57H282.17" stroke="black" stroke-width="0.5" />
    <path d="M282.19 39.57H282.22" stroke="black" stroke-width="0.5" />
    <path d="M282.24 39.57H282.27" stroke="black" stroke-width="0.5" />
    <path d="M282.29 39.57H282.32" stroke="black" stroke-width="0.5" />
    <path d="M282.34 39.57H282.37" stroke="black" stroke-width="0.5" />
    <path d="M282.39 39.57H282.42" stroke="black" stroke-width="0.5" />
    <path d="M282.44 39.57H282.47" stroke="black" stroke-width="0.5" />
    <path d="M282.49 39.57H282.52" stroke="black" stroke-width="0.5" />
    <path d="M282.54 39.57H282.57" stroke="black" stroke-width="0.5" />
    <path d="M282.59 39.57H282.62" stroke="black" stroke-width="0.5" />
    <path d="M282.64 39.57H282.67" stroke="black" stroke-width="0.5" />
    <path d="M282.69 39.57H282.72" stroke="black" stroke-width="0.5" />
    <path d="M282.74 39.57H282.77" stroke="black" stroke-width="0.5" />
    <path d="M282.79 39.57H282.82" stroke="black" stroke-width="0.5" />
    <path d="M282.84 39.57H282.87" stroke="black" stroke-width="0.5" />
    <path d="M282.89 39.57H282.92" stroke="black" stroke-width="0.5" />
    <path d="M282.94 39.57H282.97" stroke="black" stroke-width="0.5" />
    <path d="M282.99 39.57H283.02" stroke="black" stroke-width="0.5" />
    <path d="M283.04 39.57H283.07" stroke="black" stroke-width="0.5" />
    <path d="M283.09 39.57H283.12" stroke="black" stroke-width="0.5" />
    <path d="M283.14 39.57H283.17" stroke="black" stroke-width="0.5" />
    <path d="M283.19 39.57H283.22" stroke="black" stroke-width="0.5" />
    <path d="M283.24 39.57H283.27" stroke="black" stroke-width="0.5" />
    <path d="M283.29 39.57H283.32" stroke="black" stroke-width="0.5" />
    <path d="M283.34 39.57H283.37" stroke="black" stroke-width="0.5" />
    <path d="M283.39 39.57H283.42" stroke="black" stroke-width="0.5" />
    <path d="M283.44 39.57H283.47" stroke="black" stroke-width="0.5" />
    <path d="M283.49 39.57H283.52" stroke="black" stroke-width="0.5" />
    <path d="M283.54 39.57H283.57" stroke="black" stroke-width="0.5" />
    <path d="M283.59 39.57H283.62" stroke="black" stroke-width="0.5" />
    <path d="M283.64 39.57H283.67" stroke="black" stroke-width="0.5" />
    <path d="M283.69 39.57H283.72" stroke="black" stroke-width="0.5" />
    <path d="M283.74 39.57H283.77" stroke="black" stroke-width="0.5" />
    <path d="M283.79 39.57H283.82" stroke="black" stroke-width="0.5" />
    <path d="M283.84 39.57H283.87" stroke="black" stroke-width="0.5" />
    <path d="M283.89 39.57H283.92" stroke="black" stroke-width="0.5" />
    <path d="M283.94 39.57H283.97" stroke="black" stroke-width="0.5" />
    <path d="M283.99 39.57H284.02" stroke="black" stroke-width="0.5" />
    <path d="M284.04 39.57H284.07" stroke="black" stroke-width="0.5" />
    <path d="M284.09 39.57H284.12" stroke="black" stroke-width="0.5" />
    <path d="M284.14 39.57H284.17" stroke="black" stroke-width="0.5" />
    <path d="M284.19 39.57H284.22" stroke="black" stroke-width="0.5" />
    <path d="M284.24 39.57H284.27" stroke="black" stroke-width="0.5" />
    <path d="M284.29 39.57H284.32" stroke="black" stroke-width="0.5" />
    <path d="M284.34 39.57H284.37" stroke="black" stroke-width="0.5" />
    <path d="M284.39 39.57H284.42" stroke="black" stroke-width="0.5" />
    <path d="M284.44 39.57H284.48" stroke="black" stroke-width="0.5" />
    <path d="M284.49 39.57H284.53" stroke="black" stroke-width="0.5" />
    <path d="M284.54 39.57H284.58" stroke="black" stroke-width="0.5" />
    <path d="M284.59 39.57H284.63" stroke="black" stroke-width="0.5" />
    <path d="M284.64 39.57H284.68" stroke="black" stroke-width="0.5" />
    <path d="M284.69 39.57H284.73" stroke="black" stroke-width="0.5" />
    <path d="M284.74 39.57H284.78" stroke="black" stroke-width="0.5" />
    <path d="M284.79 39.57H284.83" stroke="black" stroke-width="0.5" />
    <path d="M284.84 39.57H284.88" stroke="black" stroke-width="0.5" />
    <path d="M284.89 39.57H284.93" stroke="black" stroke-width="0.5" />
    <path d="M284.94 39.57H284.98" stroke="black" stroke-width="0.5" />
    <path d="M284.99 39.57H285.03" stroke="black" stroke-width="0.5" />
    <path d="M285.04 39.57H285.08" stroke="black" stroke-width="0.5" />
    <path d="M285.09 39.57H285.13" stroke="black" stroke-width="0.5" />
    <path d="M285.14 39.57H285.18" stroke="black" stroke-width="0.5" />
    <path d="M285.19 39.57H285.23" stroke="black" stroke-width="0.5" />
    <path d="M285.24 39.57H285.28" stroke="black" stroke-width="0.5" />
    <path d="M285.29 39.57H285.33" stroke="black" stroke-width="0.5" />
    <path d="M285.34 39.57H285.38" stroke="black" stroke-width="0.5" />
    <path d="M285.39 39.57H285.43" stroke="black" stroke-width="0.5" />
    <path d="M285.44 39.57H285.48" stroke="black" stroke-width="0.5" />
    <path d="M285.49 39.57H285.53" stroke="black" stroke-width="0.5" />
    <path d="M285.54 39.57H285.58" stroke="black" stroke-width="0.5" />
    <path d="M285.59 39.57H285.63" stroke="black" stroke-width="0.5" />
    <path d="M285.64 39.57H285.68" stroke="black" stroke-width="0.5" />
    <path d="M285.69 39.57H285.73" stroke="black" stroke-width="0.5" />
    <path d="M285.74 39.57H285.78" stroke="black" stroke-width="0.5" />
    <path d="M285.79 39.57H285.83" stroke="black" stroke-width="0.5" />
    <path d="M285.84 39.57H285.88" stroke="black" stroke-width="0.5" />
    <path d="M285.89 39.57H285.93" stroke="black" stroke-width="0.5" />
    <path d="M285.94 39.57H285.98" stroke="black" stroke-width="0.5" />
    <path d="M285.99 39.57H286.03" stroke="black" stroke-width="0.5" />
    <path d="M286.04 39.57H286.08" stroke="black" stroke-width="0.5" />
    <path d="M286.09 39.57H286.13" stroke="black" stroke-width="0.5" />
    <path d="M286.14 39.57H286.18" stroke="black" stroke-width="0.5" />
    <path d="M286.19 39.57H286.23" stroke="black" stroke-width="0.5" />
    <path d="M286.24 39.57H286.28" stroke="black" stroke-width="0.5" />
    <path d="M286.29 39.57H286.33" stroke="black" stroke-width="0.5" />
    <path d="M286.34 39.57H286.38" stroke="black" stroke-width="0.5" />
    <path d="M286.39 39.57H286.43" stroke="black" stroke-width="0.5" />
    <path d="M286.44 39.57H286.48" stroke="black" stroke-width="0.5" />
    <path d="M286.49 39.57H286.53" stroke="black" stroke-width="0.5" />
    <path d="M286.54 39.57H286.58" stroke="black" stroke-width="0.5" />
    <path d="M286.59 39.57H286.63" stroke="black" stroke-width="0.5" />
    <path d="M286.64 39.57H286.68" stroke="black" stroke-width="0.5" />
    <path d="M286.69 39.57H286.73" stroke="black" stroke-width="0.5" />
    <path d="M286.74 39.57H286.78" stroke="black" stroke-width="0.5" />
    <path d="M286.79 39.57H286.83" stroke="black" stroke-width="0.5" />
    <path d="M286.84 39.57H286.88" stroke="black" stroke-width="0.5" />
    <path d="M286.89 39.57H286.93" stroke="black" stroke-width="0.5" />
    <path d="M286.94 39.57H286.98" stroke="black" stroke-width="0.5" />
    <path d="M286.99 39.57H287.03" stroke="black" stroke-width="0.5" />
    <path d="M287.04 39.57H287.08" stroke="black" stroke-width="0.5" />
    <path d="M287.09 39.57H287.13" stroke="black" stroke-width="0.5" />
    <path d="M287.14 39.57H287.18" stroke="black" stroke-width="0.5" />
    <path d="M287.19 39.57H287.23" stroke="black" stroke-width="0.5" />
    <path d="M287.24 39.57H287.28" stroke="black" stroke-width="0.5" />
    <path d="M287.29 39.57H287.33" stroke="black" stroke-width="0.5" />
    <path d="M287.34 39.57H287.38" stroke="black" stroke-width="0.5" />
    <path d="M287.39 39.57H287.43" stroke="black" stroke-width="0.5" />
    <path d="M287.44 39.57H287.48" stroke="black" stroke-width="0.5" />
    <path d="M287.49 39.57H287.53" stroke="black" stroke-width="0.5" />
    <path d="M287.54 39.57H287.58" stroke="black" stroke-width="0.5" />
    <path d="M287.59 39.57H287.63" stroke="black" stroke-width="0.5" />
    <path d="M287.64 39.57H287.68" stroke="black" stroke-width="0.5" />
    <path d="M287.69 39.57H287.73" stroke="black" stroke-width="0.5" />
    <path d="M287.74 39.57H287.78" stroke="black" stroke-width="0.5" />
    <path d="M287.79 39.57H287.83" stroke="black" stroke-width="0.5" />
    <path d="M287.84 39.57H287.88" stroke="black" stroke-width="0.5" />
    <path d="M287.89 39.57H287.93" stroke="black" stroke-width="0.5" />
    <path d="M287.94 39.57H287.98" stroke="black" stroke-width="0.5" />
    <path d="M287.99 39.57H288.03" stroke="black" stroke-width="0.5" />
    <path d="M288.04 39.57H288.08" stroke="black" stroke-width="0.5" />
    <path d="M288.09 39.57H288.13" stroke="black" stroke-width="0.5" />
    <path d="M288.14 39.57H288.18" stroke="black" stroke-width="0.5" />
    <path d="M288.19 39.57H288.23" stroke="black" stroke-width="0.5" />
    <path d="M288.24 39.57H288.28" stroke="black" stroke-width="0.5" />
    <path d="M288.29 39.57H288.33" stroke="black" stroke-width="0.5" />
    <path d="M288.34 39.57H288.38" stroke="black" stroke-width="0.5" />
    <path d="M288.39 39.57H288.43" stroke="black" stroke-width="0.5" />
    <path d="M288.44 39.57H288.48" stroke="black" stroke-width="0.5" />
    <path d="M288.49 39.57H288.53" stroke="black" stroke-width="0.5" />
    <path d="M288.54 39.57H288.58" stroke="black" stroke-width="0.5" />
    <path d="M288.59 39.57H288.63" stroke="black" stroke-width="0.5" />
    <path d="M288.64 39.57H288.68" stroke="black" stroke-width="0.5" />
    <path d="M288.69 39.57H288.73" stroke="black" stroke-width="0.5" />
    <path d="M288.74 39.57H288.78" stroke="black" stroke-width="0.5" />
    <path d="M288.79 39.57H288.83" stroke="black" stroke-width="0.5" />
    <path d="M288.84 39.57H288.88" stroke="black" stroke-width="0.5" />
    <path d="M288.89 39.57H288.93" stroke="black" stroke-width="0.5" />
    <path d="M288.94 39.57H288.98" stroke="black" stroke-width="0.5" />
    <path d="M288.99 39.57H289.03" stroke="black" stroke-width="0.5" />
    <path d="M289.05 39.57H289.08" stroke="black" stroke-width="0.5" />
    <path d="M289.1 39.57H289.13" stroke="black" stroke-width="0.5" />
    <path d="M289.15 39.57H289.18" stroke="black" stroke-width="0.5" />
    <path d="M289.2 39.57H289.23" stroke="black" stroke-width="0.5" />
    <path d="M289.25 39.57H289.28" stroke="black" stroke-width="0.5" />
    <path d="M289.3 39.57H289.33" stroke="black" stroke-width="0.5" />
    <path d="M289.35 39.57H289.38" stroke="black" stroke-width="0.5" />
    <path d="M289.4 39.57H289.43" stroke="black" stroke-width="0.5" />
    <path d="M289.45 39.57H289.48" stroke="black" stroke-width="0.5" />
    <path d="M289.5 39.57H289.53" stroke="black" stroke-width="0.5" />
    <path d="M289.55 39.57H289.58" stroke="black" stroke-width="0.5" />
    <path d="M289.6 39.57H289.63" stroke="black" stroke-width="0.5" />
    <path d="M289.65 39.57H289.68" stroke="black" stroke-width="0.5" />
    <path d="M289.7 39.57H289.73" stroke="black" stroke-width="0.5" />
    <path d="M289.75 39.57H289.78" stroke="black" stroke-width="0.5" />
    <path d="M289.8 39.57H289.83" stroke="black" stroke-width="0.5" />
    <path d="M289.85 39.57H289.88" stroke="black" stroke-width="0.5" />
    <path d="M289.9 39.57H289.93" stroke="black" stroke-width="0.5" />
    <path d="M289.95 39.57H289.98" stroke="black" stroke-width="0.5" />
    <path d="M290 39.57H290.03" stroke="black" stroke-width="0.5" />
    <path d="M290.05 39.57H290.08" stroke="black" stroke-width="0.5" />
    <path d="M290.1 39.57H290.13" stroke="black" stroke-width="0.5" />
    <path d="M290.15 39.57H290.18" stroke="black" stroke-width="0.5" />
    <path d="M290.2 39.57H290.23" stroke="black" stroke-width="0.5" />
    <path d="M290.25 39.57H290.28" stroke="black" stroke-width="0.5" />
    <path d="M290.3 39.57H290.33" stroke="black" stroke-width="0.5" />
    <path d="M290.35 39.57H290.38" stroke="black" stroke-width="0.5" />
    <path d="M290.4 39.57H290.43" stroke="black" stroke-width="0.5" />
    <path d="M290.45 39.57H290.48" stroke="black" stroke-width="0.5" />
    <path d="M290.5 39.57H290.53" stroke="black" stroke-width="0.5" />
    <path d="M290.55 39.57H290.58" stroke="black" stroke-width="0.5" />
    <path d="M290.6 39.57H290.63" stroke="black" stroke-width="0.5" />
    <path d="M290.65 39.57H290.68" stroke="black" stroke-width="0.5" />
    <path d="M290.7 39.57H290.73" stroke="black" stroke-width="0.5" />
    <path d="M290.75 39.57H290.78" stroke="black" stroke-width="0.5" />
    <path d="M290.8 39.57H290.83" stroke="black" stroke-width="0.5" />
    <path d="M290.85 39.57H290.88" stroke="black" stroke-width="0.5" />
    <path d="M290.9 39.57H290.93" stroke="black" stroke-width="0.5" />
    <path d="M290.95 39.57H290.98" stroke="black" stroke-width="0.5" />
    <path d="M291 39.57H291.03" stroke="black" stroke-width="0.5" />
    <path d="M291.05 39.57H291.08" stroke="black" stroke-width="0.5" />
    <path d="M291.1 39.57H291.13" stroke="black" stroke-width="0.5" />
    <path d="M291.15 39.57H291.18" stroke="black" stroke-width="0.5" />
    <path d="M291.2 39.57H291.23" stroke="black" stroke-width="0.5" />
    <path d="M291.25 39.57H291.28" stroke="black" stroke-width="0.5" />
    <path d="M291.3 39.57H291.33" stroke="black" stroke-width="0.5" />
    <path d="M291.35 39.57H291.38" stroke="black" stroke-width="0.5" />
    <path d="M291.4 39.57H291.43" stroke="black" stroke-width="0.5" />
    <path d="M291.45 39.57H291.48" stroke="black" stroke-width="0.5" />
    <path d="M291.5 39.57H291.53" stroke="black" stroke-width="0.5" />
    <path d="M291.55 39.57H291.58" stroke="black" stroke-width="0.5" />
    <path d="M291.6 39.57H291.63" stroke="black" stroke-width="0.5" />
    <path d="M291.65 39.57H291.68" stroke="black" stroke-width="0.5" />
    <path d="M291.7 39.57H291.73" stroke="black" stroke-width="0.5" />
    <path d="M291.75 39.57H291.78" stroke="black" stroke-width="0.5" />
    <path d="M291.8 39.57H291.83" stroke="black" stroke-width="0.5" />
    <path d="M291.85 39.57H291.88" stroke="black" stroke-width="0.5" />
    <path d="M291.9 39.57H291.93" stroke="black" stroke-width="0.5" />
    <path d="M291.95 39.57H291.98" stroke="black" stroke-width="0.5" />
    <path d="M292 39.57H292.03" stroke="black" stroke-width="0.5" />
    <path d="M292.05 39.57H292.08" stroke="black" stroke-width="0.5" />
    <path d="M292.1 39.57H292.13" stroke="black" stroke-width="0.5" />
    <path d="M292.15 39.57H292.18" stroke="black" stroke-width="0.5" />
    <path d="M292.2 39.57H292.23" stroke="black" stroke-width="0.5" />
    <path d="M292.25 39.57H292.28" stroke="black" stroke-width="0.5" />
    <path d="M292.3 39.57H292.33" stroke="black" stroke-width="0.5" />
    <path d="M292.35 39.57H292.38" stroke="black" stroke-width="0.5" />
    <path d="M292.4 39.57H292.43" stroke="black" stroke-width="0.5" />
    <path d="M292.45 39.57H292.48" stroke="black" stroke-width="0.5" />
    <path d="M292.5 39.57H292.53" stroke="black" stroke-width="0.5" />
    <path d="M292.55 39.57H292.58" stroke="black" stroke-width="0.5" />
    <path d="M292.6 39.57H292.63" stroke="black" stroke-width="0.5" />
    <path d="M292.65 39.57H292.68" stroke="black" stroke-width="0.5" />
    <path d="M292.7 39.57H292.73" stroke="black" stroke-width="0.5" />
    <path d="M292.75 39.57H292.78" stroke="black" stroke-width="0.5" />
    <path d="M292.8 39.57H292.83" stroke="black" stroke-width="0.5" />
    <path d="M292.85 39.57H292.88" stroke="black" stroke-width="0.5" />
    <path d="M292.9 39.57H292.93" stroke="black" stroke-width="0.5" />
    <path d="M292.95 39.57H292.98" stroke="black" stroke-width="0.5" />
    <path d="M293 39.57H293.03" stroke="black" stroke-width="0.5" />
    <path d="M293.05 39.57H293.08" stroke="black" stroke-width="0.5" />
    <path d="M293.1 39.57H293.13" stroke="black" stroke-width="0.5" />
    <path d="M293.15 39.57H293.18" stroke="black" stroke-width="0.5" />
    <path d="M293.2 39.57H293.23" stroke="black" stroke-width="0.5" />
    <path d="M293.25 39.57H293.28" stroke="black" stroke-width="0.5" />
    <path d="M293.3 39.57H293.33" stroke="black" stroke-width="0.5" />
    <path d="M293.35 39.57H293.38" stroke="black" stroke-width="0.5" />
    <path d="M293.4 39.57H293.43" stroke="black" stroke-width="0.5" />
    <path d="M293.45 39.57H293.48" stroke="black" stroke-width="0.5" />
    <path d="M293.5 39.57H293.53" stroke="black" stroke-width="0.5" />
    <path d="M293.55 39.57H293.58" stroke="black" stroke-width="0.5" />
    <path d="M293.6 39.57H293.63" stroke="black" stroke-width="0.5" />
    <path d="M293.65 39.57H293.68" stroke="black" stroke-width="0.5" />
    <path d="M293.7 39.57H293.73" stroke="black" stroke-width="0.5" />
    <path d="M293.75 39.57H293.78" stroke="black" stroke-width="0.5" />
    <path d="M293.8 39.57H293.83" stroke="black" stroke-width="0.5" />
    <path d="M293.85 39.57H293.87" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.57V39.55" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.53V39.5" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.48V39.45" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.43V39.4" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.38V39.35" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.33V39.3" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.28V39.25" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.23V39.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.18V39.15" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.13V39.1" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.08V39.05" stroke="black" stroke-width="0.5" />
    <path d="M293.87 39.03V39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.98V38.95" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.93V38.9" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.88V38.85" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.83V38.8" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.78V38.75" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.73V38.7" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.68V38.65" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.63V38.6" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.58V38.55" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.53V38.5" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.48V38.45" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.43V38.4" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.38V38.35" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.33V38.3" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.28V38.25" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.23V38.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.18V38.15" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.13V38.1" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.08V38.04" stroke="black" stroke-width="0.5" />
    <path d="M293.87 38.03V37.99" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.98V37.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.93V37.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.88V37.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.83V37.79" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.78V37.74" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.73V37.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.68V37.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.63V37.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.58V37.54" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.53V37.49" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.48V37.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.43V37.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.38V37.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.33V37.29" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.28V37.24" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.23V37.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.18V37.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.13V37.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.08V37.04" stroke="black" stroke-width="0.5" />
    <path d="M293.87 37.03V36.99" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.98V36.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.93V36.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.88V36.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.83V36.79" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.78V36.74" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.73V36.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.68V36.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.63V36.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.58V36.54" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.53V36.49" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.48V36.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.43V36.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.38V36.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.33V36.29" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.28V36.24" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.23V36.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.18V36.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.13V36.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.08V36.04" stroke="black" stroke-width="0.5" />
    <path d="M293.87 36.03V35.99" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.98V35.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.93V35.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.88V35.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.83V35.79" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.78V35.74" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.73V35.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.68V35.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.63V35.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.58V35.54" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.53V35.49" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.48V35.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.43V35.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.38V35.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.33V35.29" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.28V35.24" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.23V35.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.18V35.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.13V35.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.08V35.04" stroke="black" stroke-width="0.5" />
    <path d="M293.87 35.03V34.99" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.98V34.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.93V34.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.88V34.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.83V34.79" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.78V34.74" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.73V34.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.68V34.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.63V34.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.58V34.54" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.53V34.49" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.48V34.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.43V34.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.38V34.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.33V34.29" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.28V34.24" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.23V34.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.18V34.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.13V34.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.08V34.04" stroke="black" stroke-width="0.5" />
    <path d="M293.87 34.03V33.99" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.98V33.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.93V33.89" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.88V33.84" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.83V33.79" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.78V33.74" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.73V33.69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.68V33.64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.63V33.59" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.58V33.54" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.53V33.49" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.47V33.44" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.42V33.39" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.37V33.34" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.32V33.29" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.27V33.24" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.22V33.19" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.17V33.14" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.12V33.09" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.07V33.04" stroke="black" stroke-width="0.5" />
    <path d="M293.87 33.02V32.99" stroke="black" stroke-width="0.5" />
    <path d="M293.87 32.97V32.94" stroke="black" stroke-width="0.5" />
    <path d="M293.87 32.92V32.9" stroke="black" stroke-width="0.5" />
    <path d="M252.84 65.01V64.57" stroke="black" stroke-width="0.5" />
    <path d="M254.15 65.01H252.84" stroke="black" stroke-width="0.5" />
    <path d="M254.15 64.57V65.01" stroke="black" stroke-width="0.5" />
    <path d="M252.84 64.57H254.15" stroke="black" stroke-width="0.5" />
    <path d="M251.25 68.22L251.34 68.6" stroke="black" stroke-width="0.5" />
    <path d="M251.34 68.6L251.57 68.88" stroke="black" stroke-width="0.5" />
    <path d="M251.57 68.88L251.9 68.99" stroke="black" stroke-width="0.5" />
    <path d="M255.09 68.99L255.41 68.88" stroke="black" stroke-width="0.5" />
    <path d="M255.41 68.88L255.65 68.6" stroke="black" stroke-width="0.5" />
    <path d="M255.65 68.6L255.73 68.22" stroke="black" stroke-width="0.5" />
    <path d="M255.73 66.31L255.65 65.92" stroke="black" stroke-width="0.5" />
    <path d="M255.65 65.92L255.41 65.64" stroke="black" stroke-width="0.5" />
    <path d="M255.41 65.64L255.09 65.54" stroke="black" stroke-width="0.5" />
    <path d="M251.9 65.54L251.57 65.64" stroke="black" stroke-width="0.5" />
    <path d="M251.57 65.64L251.34 65.92" stroke="black" stroke-width="0.5" />
    <path d="M251.34 65.92L251.25 66.31" stroke="black" stroke-width="0.5" />
    <path d="M251.25 68.22V66.31" stroke="black" stroke-width="0.5" />
    <path d="M255.09 68.99H251.9" stroke="black" stroke-width="0.5" />
    <path d="M255.73 66.31V68.22" stroke="black" stroke-width="0.5" />
    <path d="M251.9 65.54H255.09" stroke="black" stroke-width="0.5" />
    <path d="M250.82 69.44V64.08" stroke="black" stroke-width="0.5" />
    <path d="M256.17 69.44H250.82" stroke="black" stroke-width="0.5" />
    <path d="M256.17 64.08V69.44" stroke="black" stroke-width="0.5" />
    <path d="M250.82 64.08H256.17" stroke="black" stroke-width="0.5" />
    <path d="M257.84 69.73H257.82" stroke="black" stroke-width="0.5" />
    <path d="M257.81 69.73H257.77" stroke="black" stroke-width="0.5" />
    <path d="M257.76 69.73H257.72" stroke="black" stroke-width="0.5" />
    <path d="M257.71 69.73H257.67" stroke="black" stroke-width="0.5" />
    <path d="M257.66 69.73H257.62" stroke="black" stroke-width="0.5" />
    <path d="M257.61 69.73H257.57" stroke="black" stroke-width="0.5" />
    <path d="M257.56 69.73H257.52" stroke="black" stroke-width="0.5" />
    <path d="M257.51 69.73H257.47" stroke="black" stroke-width="0.5" />
    <path d="M257.46 69.73H257.42" stroke="black" stroke-width="0.5" />
    <path d="M257.41 69.73H257.37" stroke="black" stroke-width="0.5" />
    <path d="M257.36 69.73H257.32" stroke="black" stroke-width="0.5" />
    <path d="M257.31 69.73H257.27" stroke="black" stroke-width="0.5" />
    <path d="M257.26 69.73H257.22" stroke="black" stroke-width="0.5" />
    <path d="M257.21 69.73H257.17" stroke="black" stroke-width="0.5" />
    <path d="M257.16 69.73H257.12" stroke="black" stroke-width="0.5" />
    <path d="M257.1 69.73H257.07" stroke="black" stroke-width="0.5" />
    <path d="M257.05 69.73H257.02" stroke="black" stroke-width="0.5" />
    <path d="M257 69.73H256.97" stroke="black" stroke-width="0.5" />
    <path d="M256.95 69.73H256.92" stroke="black" stroke-width="0.5" />
    <path d="M256.9 69.73H256.87" stroke="black" stroke-width="0.5" />
    <path d="M256.85 69.73H256.82" stroke="black" stroke-width="0.5" />
    <path d="M256.8 69.73H256.77" stroke="black" stroke-width="0.5" />
    <path d="M256.75 69.73H256.72" stroke="black" stroke-width="0.5" />
    <path d="M256.7 69.73H256.67" stroke="black" stroke-width="0.5" />
    <path d="M256.65 69.73H256.62" stroke="black" stroke-width="0.5" />
    <path d="M256.6 69.73H256.57" stroke="black" stroke-width="0.5" />
    <path d="M256.55 69.73H256.52" stroke="black" stroke-width="0.5" />
    <path d="M256.5 69.73H256.47" stroke="black" stroke-width="0.5" />
    <path d="M256.45 69.73H256.42" stroke="black" stroke-width="0.5" />
    <path d="M256.4 69.73H256.37" stroke="black" stroke-width="0.5" />
    <path d="M256.35 69.73H256.32" stroke="black" stroke-width="0.5" />
    <path d="M256.3 69.73H256.27" stroke="black" stroke-width="0.5" />
    <path d="M256.25 69.73H256.22" stroke="black" stroke-width="0.5" />
    <path d="M256.2 69.73H256.17" stroke="black" stroke-width="0.5" />
    <path d="M256.15 69.73H256.12" stroke="black" stroke-width="0.5" />
    <path d="M256.1 69.73H256.07" stroke="black" stroke-width="0.5" />
    <path d="M256.05 69.73H256.02" stroke="black" stroke-width="0.5" />
    <path d="M256 69.73H255.97" stroke="black" stroke-width="0.5" />
    <path d="M255.95 69.73H255.92" stroke="black" stroke-width="0.5" />
    <path d="M255.9 69.73H255.87" stroke="black" stroke-width="0.5" />
    <path d="M255.85 69.73H255.82" stroke="black" stroke-width="0.5" />
    <path d="M255.8 69.73H255.77" stroke="black" stroke-width="0.5" />
    <path d="M255.75 69.73H255.72" stroke="black" stroke-width="0.5" />
    <path d="M255.7 69.73H255.67" stroke="black" stroke-width="0.5" />
    <path d="M255.65 69.73H255.62" stroke="black" stroke-width="0.5" />
    <path d="M255.6 69.73H255.57" stroke="black" stroke-width="0.5" />
    <path d="M255.55 69.73H255.52" stroke="black" stroke-width="0.5" />
    <path d="M255.5 69.73H255.47" stroke="black" stroke-width="0.5" />
    <path d="M255.45 69.73H255.42" stroke="black" stroke-width="0.5" />
    <path d="M255.4 69.73H255.37" stroke="black" stroke-width="0.5" />
    <path d="M255.35 69.73H255.32" stroke="black" stroke-width="0.5" />
    <path d="M255.3 69.73H255.27" stroke="black" stroke-width="0.5" />
    <path d="M255.25 69.73H255.22" stroke="black" stroke-width="0.5" />
    <path d="M255.2 69.73H255.17" stroke="black" stroke-width="0.5" />
    <path d="M255.15 69.73H255.12" stroke="black" stroke-width="0.5" />
    <path d="M255.1 69.73H255.07" stroke="black" stroke-width="0.5" />
    <path d="M255.05 69.73H255.02" stroke="black" stroke-width="0.5" />
    <path d="M255 69.73H254.97" stroke="black" stroke-width="0.5" />
    <path d="M254.95 69.73H254.92" stroke="black" stroke-width="0.5" />
    <path d="M254.9 69.73H254.87" stroke="black" stroke-width="0.5" />
    <path d="M254.85 69.73H254.82" stroke="black" stroke-width="0.5" />
    <path d="M254.8 69.73H254.77" stroke="black" stroke-width="0.5" />
    <path d="M254.75 69.73H254.72" stroke="black" stroke-width="0.5" />
    <path d="M254.7 69.73H254.67" stroke="black" stroke-width="0.5" />
    <path d="M254.65 69.73H254.62" stroke="black" stroke-width="0.5" />
    <path d="M254.6 69.73H254.57" stroke="black" stroke-width="0.5" />
    <path d="M254.55 69.73H254.52" stroke="black" stroke-width="0.5" />
    <path d="M254.5 69.73H254.47" stroke="black" stroke-width="0.5" />
    <path d="M254.45 69.73H254.42" stroke="black" stroke-width="0.5" />
    <path d="M254.4 69.73H254.37" stroke="black" stroke-width="0.5" />
    <path d="M254.35 69.73H254.32" stroke="black" stroke-width="0.5" />
    <path d="M254.3 69.73H254.27" stroke="black" stroke-width="0.5" />
    <path d="M254.25 69.73H254.22" stroke="black" stroke-width="0.5" />
    <path d="M254.2 69.73H254.17" stroke="black" stroke-width="0.5" />
    <path d="M254.15 69.73H254.12" stroke="black" stroke-width="0.5" />
    <path d="M254.1 69.73H254.07" stroke="black" stroke-width="0.5" />
    <path d="M254.05 69.73H254.02" stroke="black" stroke-width="0.5" />
    <path d="M254 69.73H253.97" stroke="black" stroke-width="0.5" />
    <path d="M253.95 69.73H253.92" stroke="black" stroke-width="0.5" />
    <path d="M253.9 69.73H253.87" stroke="black" stroke-width="0.5" />
    <path d="M253.85 69.73H253.82" stroke="black" stroke-width="0.5" />
    <path d="M253.8 69.73H253.77" stroke="black" stroke-width="0.5" />
    <path d="M253.75 69.73H253.72" stroke="black" stroke-width="0.5" />
    <path d="M253.7 69.73H253.67" stroke="black" stroke-width="0.5" />
    <path d="M253.65 69.73H253.62" stroke="black" stroke-width="0.5" />
    <path d="M253.6 69.73H253.57" stroke="black" stroke-width="0.5" />
    <path d="M253.55 69.73H253.52" stroke="black" stroke-width="0.5" />
    <path d="M253.5 69.73H253.47" stroke="black" stroke-width="0.5" />
    <path d="M253.45 69.73H253.42" stroke="black" stroke-width="0.5" />
    <path d="M253.4 69.73H253.37" stroke="black" stroke-width="0.5" />
    <path d="M253.35 69.73H253.32" stroke="black" stroke-width="0.5" />
    <path d="M253.3 69.73H253.27" stroke="black" stroke-width="0.5" />
    <path d="M253.25 69.73H253.22" stroke="black" stroke-width="0.5" />
    <path d="M253.2 69.73H253.17" stroke="black" stroke-width="0.5" />
    <path d="M253.15 69.73H253.12" stroke="black" stroke-width="0.5" />
    <path d="M253.1 69.73H253.07" stroke="black" stroke-width="0.5" />
    <path d="M253.05 69.73H253.02" stroke="black" stroke-width="0.5" />
    <path d="M253 69.73H252.97" stroke="black" stroke-width="0.5" />
    <path d="M252.95 69.73H252.92" stroke="black" stroke-width="0.5" />
    <path d="M252.9 69.73H252.87" stroke="black" stroke-width="0.5" />
    <path d="M252.85 69.73H252.82" stroke="black" stroke-width="0.5" />
    <path d="M252.8 69.73H252.77" stroke="black" stroke-width="0.5" />
    <path d="M252.75 69.73H252.72" stroke="black" stroke-width="0.5" />
    <path d="M252.7 69.73H252.67" stroke="black" stroke-width="0.5" />
    <path d="M252.65 69.73H252.62" stroke="black" stroke-width="0.5" />
    <path d="M252.6 69.73H252.57" stroke="black" stroke-width="0.5" />
    <path d="M252.55 69.73H252.52" stroke="black" stroke-width="0.5" />
    <path d="M252.5 69.73H252.47" stroke="black" stroke-width="0.5" />
    <path d="M252.45 69.73H252.42" stroke="black" stroke-width="0.5" />
    <path d="M252.4 69.73H252.37" stroke="black" stroke-width="0.5" />
    <path d="M252.35 69.73H252.32" stroke="black" stroke-width="0.5" />
    <path d="M252.3 69.73H252.27" stroke="black" stroke-width="0.5" />
    <path d="M252.25 69.73H252.22" stroke="black" stroke-width="0.5" />
    <path d="M252.2 69.73H252.17" stroke="black" stroke-width="0.5" />
    <path d="M252.15 69.73H252.12" stroke="black" stroke-width="0.5" />
    <path d="M252.1 69.73H252.07" stroke="black" stroke-width="0.5" />
    <path d="M252.05 69.73H252.02" stroke="black" stroke-width="0.5" />
    <path d="M252 69.73H251.97" stroke="black" stroke-width="0.5" />
    <path d="M251.95 69.73H251.92" stroke="black" stroke-width="0.5" />
    <path d="M251.9 69.73H251.87" stroke="black" stroke-width="0.5" />
    <path d="M251.85 69.73H251.82" stroke="black" stroke-width="0.5" />
    <path d="M251.8 69.73H251.77" stroke="black" stroke-width="0.5" />
    <path d="M251.75 69.73H251.72" stroke="black" stroke-width="0.5" />
    <path d="M251.7 69.73H251.67" stroke="black" stroke-width="0.5" />
    <path d="M251.65 69.73H251.62" stroke="black" stroke-width="0.5" />
    <path d="M251.6 69.73H251.57" stroke="black" stroke-width="0.5" />
    <path d="M251.55 69.73H251.52" stroke="black" stroke-width="0.5" />
    <path d="M251.5 69.73H251.47" stroke="black" stroke-width="0.5" />
    <path d="M251.45 69.73H251.42" stroke="black" stroke-width="0.5" />
    <path d="M251.4 69.73H251.37" stroke="black" stroke-width="0.5" />
    <path d="M251.35 69.73H251.32" stroke="black" stroke-width="0.5" />
    <path d="M251.3 69.73H251.27" stroke="black" stroke-width="0.5" />
    <path d="M251.25 69.73H251.22" stroke="black" stroke-width="0.5" />
    <path d="M251.2 69.73H251.17" stroke="black" stroke-width="0.5" />
    <path d="M251.15 69.73H251.12" stroke="black" stroke-width="0.5" />
    <path d="M251.1 69.73H251.07" stroke="black" stroke-width="0.5" />
    <path d="M251.05 69.73H251.02" stroke="black" stroke-width="0.5" />
    <path d="M251 69.73H250.97" stroke="black" stroke-width="0.5" />
    <path d="M250.95 69.73H250.92" stroke="black" stroke-width="0.5" />
    <path d="M250.9 69.73H250.87" stroke="black" stroke-width="0.5" />
    <path d="M250.85 69.73H250.82" stroke="black" stroke-width="0.5" />
    <path d="M250.8 69.73H250.77" stroke="black" stroke-width="0.5" />
    <path d="M250.75 69.73H250.72" stroke="black" stroke-width="0.5" />
    <path d="M250.7 69.73H250.67" stroke="black" stroke-width="0.5" />
    <path d="M250.65 69.73H250.62" stroke="black" stroke-width="0.5" />
    <path d="M250.6 69.73H250.57" stroke="black" stroke-width="0.5" />
    <path d="M250.55 69.73H250.52" stroke="black" stroke-width="0.5" />
    <path d="M250.5 69.73H250.47" stroke="black" stroke-width="0.5" />
    <path d="M250.45 69.73H250.42" stroke="black" stroke-width="0.5" />
    <path d="M250.4 69.73H250.37" stroke="black" stroke-width="0.5" />
    <path d="M250.35 69.73H250.32" stroke="black" stroke-width="0.5" />
    <path d="M250.3 69.73H250.27" stroke="black" stroke-width="0.5" />
    <path d="M250.25 69.73H250.22" stroke="black" stroke-width="0.5" />
    <path d="M250.2 69.73H250.17" stroke="black" stroke-width="0.5" />
    <path d="M250.15 69.73H250.12" stroke="black" stroke-width="0.5" />
    <path d="M250.1 69.73H250.07" stroke="black" stroke-width="0.5" />
    <path d="M250.05 69.73H250.02" stroke="black" stroke-width="0.5" />
    <path d="M250 69.73H249.97" stroke="black" stroke-width="0.5" />
    <path d="M249.95 69.73H249.92" stroke="black" stroke-width="0.5" />
    <path d="M249.9 69.73H249.87" stroke="black" stroke-width="0.5" />
    <path d="M249.85 69.73H249.82" stroke="black" stroke-width="0.5" />
    <path d="M249.8 69.73H249.77" stroke="black" stroke-width="0.5" />
    <path d="M249.75 69.73H249.72" stroke="black" stroke-width="0.5" />
    <path d="M249.7 69.73H249.67" stroke="black" stroke-width="0.5" />
    <path d="M249.65 69.73H249.62" stroke="black" stroke-width="0.5" />
    <path d="M249.6 69.73H249.57" stroke="black" stroke-width="0.5" />
    <path d="M249.55 69.73H249.52" stroke="black" stroke-width="0.5" />
    <path d="M249.5 69.73H249.47" stroke="black" stroke-width="0.5" />
    <path d="M249.45 69.73H249.42" stroke="black" stroke-width="0.5" />
    <path d="M249.4 69.73H249.37" stroke="black" stroke-width="0.5" />
    <path d="M249.35 69.73H249.32" stroke="black" stroke-width="0.5" />
    <path d="M249.3 69.73H249.27" stroke="black" stroke-width="0.5" />
    <path d="M249.25 69.73H249.22" stroke="black" stroke-width="0.5" />
    <path d="M249.2 69.73H249.17" stroke="black" stroke-width="0.5" />
    <path d="M249.15 69.73H249.12" stroke="black" stroke-width="0.5" />
    <path d="M249.1 69.73H249.07" stroke="black" stroke-width="0.5" />
    <path d="M249.05 69.73H249.02" stroke="black" stroke-width="0.5" />
    <path d="M249 69.73H248.97" stroke="black" stroke-width="0.5" />
    <path d="M248.95 69.73H248.92" stroke="black" stroke-width="0.5" />
    <path d="M248.9 69.73H248.87" stroke="black" stroke-width="0.5" />
    <path d="M248.85 69.73H248.82" stroke="black" stroke-width="0.5" />
    <path d="M248.8 69.73H248.77" stroke="black" stroke-width="0.5" />
    <path d="M248.75 69.73H248.72" stroke="black" stroke-width="0.5" />
    <path d="M248.7 69.73H248.67" stroke="black" stroke-width="0.5" />
    <path d="M248.65 69.73H248.62" stroke="black" stroke-width="0.5" />
    <path d="M248.6 69.73H248.57" stroke="black" stroke-width="0.5" />
    <path d="M248.55 69.73H248.52" stroke="black" stroke-width="0.5" />
    <path d="M248.5 69.73H248.47" stroke="black" stroke-width="0.5" />
    <path d="M248.45 69.73H248.42" stroke="black" stroke-width="0.5" />
    <path d="M248.4 69.73H248.37" stroke="black" stroke-width="0.5" />
    <path d="M248.35 69.73H248.32" stroke="black" stroke-width="0.5" />
    <path d="M248.3 69.73H248.27" stroke="black" stroke-width="0.5" />
    <path d="M248.25 69.73H248.22" stroke="black" stroke-width="0.5" />
    <path d="M248.2 69.73H248.17" stroke="black" stroke-width="0.5" />
    <path d="M248.15 69.73H248.12" stroke="black" stroke-width="0.5" />
    <path d="M248.1 69.73H248.07" stroke="black" stroke-width="0.5" />
    <path d="M248.05 69.73H248.02" stroke="black" stroke-width="0.5" />
    <path d="M248 69.73H247.96" stroke="black" stroke-width="0.5" />
    <path d="M247.95 69.73H247.91" stroke="black" stroke-width="0.5" />
    <path d="M247.9 69.73H247.86" stroke="black" stroke-width="0.5" />
    <path d="M247.85 69.73H247.81" stroke="black" stroke-width="0.5" />
    <path d="M247.8 69.73H247.76" stroke="black" stroke-width="0.5" />
    <path d="M247.75 69.73H247.71" stroke="black" stroke-width="0.5" />
    <path d="M247.7 69.73H247.66" stroke="black" stroke-width="0.5" />
    <path d="M247.65 69.73H247.61" stroke="black" stroke-width="0.5" />
    <path d="M247.6 69.73H247.56" stroke="black" stroke-width="0.5" />
    <path d="M247.55 69.73H247.51" stroke="black" stroke-width="0.5" />
    <path d="M247.5 69.73H247.46" stroke="black" stroke-width="0.5" />
    <path d="M247.45 69.73H247.41" stroke="black" stroke-width="0.5" />
    <path d="M247.4 69.73H247.36" stroke="black" stroke-width="0.5" />
    <path d="M247.35 69.73H247.31" stroke="black" stroke-width="0.5" />
    <path d="M247.3 69.73H247.26" stroke="black" stroke-width="0.5" />
    <path d="M247.25 69.73H247.21" stroke="black" stroke-width="0.5" />
    <path d="M247.2 69.73H247.16" stroke="black" stroke-width="0.5" />
    <path d="M247.15 69.73H247.11" stroke="black" stroke-width="0.5" />
    <path d="M247.1 69.73H247.06" stroke="black" stroke-width="0.5" />
    <path d="M247.05 69.73H247.01" stroke="black" stroke-width="0.5" />
    <path d="M247 69.73H246.96" stroke="black" stroke-width="0.5" />
    <path d="M246.95 69.73H246.91" stroke="black" stroke-width="0.5" />
    <path d="M246.9 69.73H246.86" stroke="black" stroke-width="0.5" />
    <path d="M246.85 69.73H246.81" stroke="black" stroke-width="0.5" />
    <path d="M246.8 69.73H246.76" stroke="black" stroke-width="0.5" />
    <path d="M246.75 69.73H246.71" stroke="black" stroke-width="0.5" />
    <path d="M246.7 69.73H246.66" stroke="black" stroke-width="0.5" />
    <path d="M246.65 69.73H246.61" stroke="black" stroke-width="0.5" />
    <path d="M246.6 69.73H246.56" stroke="black" stroke-width="0.5" />
    <path d="M246.55 69.73H246.51" stroke="black" stroke-width="0.5" />
    <path d="M246.5 69.73H246.46" stroke="black" stroke-width="0.5" />
    <path d="M246.45 69.73H246.41" stroke="black" stroke-width="0.5" />
    <path d="M246.4 69.73H246.36" stroke="black" stroke-width="0.5" />
    <path d="M246.35 69.73H246.31" stroke="black" stroke-width="0.5" />
    <path d="M246.3 69.73H246.26" stroke="black" stroke-width="0.5" />
    <path d="M246.25 69.73H246.21" stroke="black" stroke-width="0.5" />
    <path d="M246.2 69.73H246.16" stroke="black" stroke-width="0.5" />
    <path d="M246.15 69.73H246.11" stroke="black" stroke-width="0.5" />
    <path d="M246.1 69.73H246.06" stroke="black" stroke-width="0.5" />
    <path d="M246.05 69.73H246.01" stroke="black" stroke-width="0.5" />
    <path d="M246 69.73H245.96" stroke="black" stroke-width="0.5" />
    <path d="M245.95 69.73H245.91" stroke="black" stroke-width="0.5" />
    <path d="M245.9 69.73H245.86" stroke="black" stroke-width="0.5" />
    <path d="M245.85 69.73H245.81" stroke="black" stroke-width="0.5" />
    <path d="M245.8 69.73H245.76" stroke="black" stroke-width="0.5" />
    <path d="M245.75 69.73H245.71" stroke="black" stroke-width="0.5" />
    <path d="M245.7 69.73H245.66" stroke="black" stroke-width="0.5" />
    <path d="M245.65 69.73H245.61" stroke="black" stroke-width="0.5" />
    <path d="M245.6 69.73H245.56" stroke="black" stroke-width="0.5" />
    <path d="M245.55 69.73H245.51" stroke="black" stroke-width="0.5" />
    <path d="M245.5 69.73H245.46" stroke="black" stroke-width="0.5" />
    <path d="M245.45 69.73H245.41" stroke="black" stroke-width="0.5" />
    <path d="M245.4 69.73H245.36" stroke="black" stroke-width="0.5" />
    <path d="M245.35 69.73H245.31" stroke="black" stroke-width="0.5" />
    <path d="M245.3 69.73H245.26" stroke="black" stroke-width="0.5" />
    <path d="M245.25 69.73H245.21" stroke="black" stroke-width="0.5" />
    <path d="M245.2 69.73H245.16" stroke="black" stroke-width="0.5" />
    <path d="M245.15 69.73H245.11" stroke="black" stroke-width="0.5" />
    <path d="M245.1 69.73H245.06" stroke="black" stroke-width="0.5" />
    <path d="M245.05 69.73H245.01" stroke="black" stroke-width="0.5" />
    <path d="M245 69.73H244.96" stroke="black" stroke-width="0.5" />
    <path d="M244.95 69.73H244.91" stroke="black" stroke-width="0.5" />
    <path d="M244.9 69.73H244.86" stroke="black" stroke-width="0.5" />
    <path d="M244.85 69.73H244.81" stroke="black" stroke-width="0.5" />
    <path d="M244.8 69.73H244.76" stroke="black" stroke-width="0.5" />
    <path d="M244.75 69.73H244.71" stroke="black" stroke-width="0.5" />
    <path d="M244.7 69.73H244.66" stroke="black" stroke-width="0.5" />
    <path d="M244.65 69.73H244.61" stroke="black" stroke-width="0.5" />
    <path d="M244.6 69.73H244.56" stroke="black" stroke-width="0.5" />
    <path d="M244.55 69.73H244.51" stroke="black" stroke-width="0.5" />
    <path d="M244.5 69.73H244.46" stroke="black" stroke-width="0.5" />
    <path d="M244.45 69.73H244.41" stroke="black" stroke-width="0.5" />
    <path d="M244.4 69.73H244.36" stroke="black" stroke-width="0.5" />
    <path d="M244.35 69.73H244.31" stroke="black" stroke-width="0.5" />
    <path d="M244.3 69.73H244.26" stroke="black" stroke-width="0.5" />
    <path d="M244.25 69.73H244.21" stroke="black" stroke-width="0.5" />
    <path d="M244.2 69.73H244.16" stroke="black" stroke-width="0.5" />
    <path d="M244.15 69.73H244.11" stroke="black" stroke-width="0.5" />
    <path d="M244.1 69.73H244.06" stroke="black" stroke-width="0.5" />
    <path d="M244.05 69.73H244.01" stroke="black" stroke-width="0.5" />
    <path d="M244 69.73H243.96" stroke="black" stroke-width="0.5" />
    <path d="M243.95 69.73H243.91" stroke="black" stroke-width="0.5" />
    <path d="M243.9 69.73H243.86" stroke="black" stroke-width="0.5" />
    <path d="M243.85 69.73H243.81" stroke="black" stroke-width="0.5" />
    <path d="M243.8 69.73H243.76" stroke="black" stroke-width="0.5" />
    <path d="M243.75 69.73H243.71" stroke="black" stroke-width="0.5" />
    <path d="M243.7 69.73H243.66" stroke="black" stroke-width="0.5" />
    <path d="M243.65 69.73H243.61" stroke="black" stroke-width="0.5" />
    <path d="M243.6 69.73H243.56" stroke="black" stroke-width="0.5" />
    <path d="M243.55 69.73H243.51" stroke="black" stroke-width="0.5" />
    <path d="M243.5 69.73H243.46" stroke="black" stroke-width="0.5" />
    <path d="M243.45 69.73H243.41" stroke="black" stroke-width="0.5" />
    <path d="M243.39 69.73H243.36" stroke="black" stroke-width="0.5" />
    <path d="M243.34 69.73H243.31" stroke="black" stroke-width="0.5" />
    <path d="M243.29 69.73H243.26" stroke="black" stroke-width="0.5" />
    <path d="M243.24 69.73H243.21" stroke="black" stroke-width="0.5" />
    <path d="M243.19 69.73H243.16" stroke="black" stroke-width="0.5" />
    <path d="M243.14 69.73H243.11" stroke="black" stroke-width="0.5" />
    <path d="M243.09 69.73H243.06" stroke="black" stroke-width="0.5" />
    <path d="M243.04 69.73H243.01" stroke="black" stroke-width="0.5" />
    <path d="M242.99 69.73H242.96" stroke="black" stroke-width="0.5" />
    <path d="M242.94 69.73H242.91" stroke="black" stroke-width="0.5" />
    <path d="M242.89 69.73H242.86" stroke="black" stroke-width="0.5" />
    <path d="M242.84 69.73H242.81" stroke="black" stroke-width="0.5" />
    <path d="M242.79 69.73H242.76" stroke="black" stroke-width="0.5" />
    <path d="M242.74 69.73H242.71" stroke="black" stroke-width="0.5" />
    <path d="M242.69 69.73H242.66" stroke="black" stroke-width="0.5" />
    <path d="M242.64 69.73H242.61" stroke="black" stroke-width="0.5" />
    <path d="M242.59 69.73H242.56" stroke="black" stroke-width="0.5" />
    <path d="M242.54 69.73H242.51" stroke="black" stroke-width="0.5" />
    <path d="M242.49 69.73H242.46" stroke="black" stroke-width="0.5" />
    <path d="M242.44 69.73H242.41" stroke="black" stroke-width="0.5" />
    <path d="M242.39 69.73H242.36" stroke="black" stroke-width="0.5" />
    <path d="M242.34 69.73H242.31" stroke="black" stroke-width="0.5" />
    <path d="M242.29 69.73H242.26" stroke="black" stroke-width="0.5" />
    <path d="M242.24 69.73H242.21" stroke="black" stroke-width="0.5" />
    <path d="M242.19 69.73H242.16" stroke="black" stroke-width="0.5" />
    <path d="M242.14 69.73H242.11" stroke="black" stroke-width="0.5" />
    <path d="M242.09 69.73H242.06" stroke="black" stroke-width="0.5" />
    <path d="M242.04 69.73H242.01" stroke="black" stroke-width="0.5" />
    <path d="M241.99 69.73H241.96" stroke="black" stroke-width="0.5" />
    <path d="M241.94 69.73H241.91" stroke="black" stroke-width="0.5" />
    <path d="M241.89 69.73H241.86" stroke="black" stroke-width="0.5" />
    <path d="M241.84 69.73H241.83" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.73V69.7" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.68V69.65" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.63V69.6" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.58V69.55" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.53V69.5" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.48V69.45" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.43V69.4" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.38V69.35" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.33V69.3" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.28V69.25" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.23V69.2" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.18V69.15" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.13V69.1" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.08V69.05" stroke="black" stroke-width="0.5" />
    <path d="M241.83 69.03V69" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.98V68.95" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.93V68.9" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.88V68.85" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.83V68.8" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.78V68.75" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.73V68.7" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.68V68.65" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.63V68.6" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.58V68.55" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.53V68.5" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.48V68.45" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.43V68.4" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.38V68.35" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.33V68.3" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.28V68.25" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.23V68.2" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.18V68.15" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.13V68.1" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.08V68.05" stroke="black" stroke-width="0.5" />
    <path d="M241.83 68.03V68" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.98V67.95" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.93V67.9" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.88V67.85" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.83V67.8" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.78V67.75" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.73V67.7" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.68V67.65" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.63V67.6" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.58V67.55" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.53V67.5" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.48V67.45" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.43V67.4" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.38V67.35" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.33V67.3" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.28V67.25" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.23V67.2" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.18V67.15" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.13V67.1" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.08V67.05" stroke="black" stroke-width="0.5" />
    <path d="M241.83 67.03V67" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.98V66.95" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.93V66.9" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.88V66.85" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.83V66.8" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.78V66.75" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.73V66.7" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.68V66.65" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.63V66.6" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.58V66.55" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.53V66.5" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.48V66.45" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.43V66.4" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.38V66.35" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.33V66.3" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.28V66.25" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.23V66.2" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.18V66.15" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.13V66.1" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.08V66.05" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.03V66" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.98V65.95" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.93V65.9" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.88V65.85" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.83V65.8" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.78V65.75" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.73V65.7" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.68V65.65" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.63V65.6" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.58V65.55" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.53V65.5" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.48V65.45" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.43V65.4" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.38V65.35" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.33V65.3" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.28V65.25" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.23V65.2" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.18V65.15" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.13V65.1" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.08V65.05" stroke="black" stroke-width="0.5" />
    <path d="M241.83 65.03V65" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.98V64.95" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.93V64.9" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.88V64.85" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.83V64.8" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.78V64.75" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.73V64.7" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.68V64.65" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.63V64.6" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.58V64.55" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.53V64.5" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.48V64.45" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.43V64.4" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.38V64.35" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.33V64.3" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.28V64.25" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.23V64.2" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.18V64.15" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.13V64.1" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.08V64.05" stroke="black" stroke-width="0.5" />
    <path d="M241.83 64.03V64" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.98V63.95" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.93V63.9" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.88V63.85" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.83V63.8" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.78V63.75" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.73V63.7" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.68V63.65" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.63V63.6" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.58V63.55" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.53V63.5" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.48V63.45" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.43V63.4" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.38V63.35" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.33V63.3" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.28V63.25" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.23V63.2" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.18V63.15" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.13V63.1" stroke="black" stroke-width="0.5" />
    <path d="M241.83 63.08V63.05" stroke="black" stroke-width="0.5" />
    <path d="M282.86 65.01V64.57" stroke="black" stroke-width="0.5" />
    <path d="M281.54 65.01H282.86" stroke="black" stroke-width="0.5" />
    <path d="M281.54 64.57V65.01" stroke="black" stroke-width="0.5" />
    <path d="M282.86 64.57H281.54" stroke="black" stroke-width="0.5" />
    <path d="M284.44 68.22L284.35 68.6" stroke="black" stroke-width="0.5" />
    <path d="M284.35 68.6L284.12 68.88" stroke="black" stroke-width="0.5" />
    <path d="M284.12 68.88L283.8 68.99" stroke="black" stroke-width="0.5" />
    <path d="M280.6 68.99L280.28 68.88" stroke="black" stroke-width="0.5" />
    <path d="M280.28 68.88L280.05 68.6" stroke="black" stroke-width="0.5" />
    <path d="M280.05 68.6L279.96 68.22" stroke="black" stroke-width="0.5" />
    <path d="M279.96 66.31L280.05 65.92" stroke="black" stroke-width="0.5" />
    <path d="M280.05 65.92L280.28 65.64" stroke="black" stroke-width="0.5" />
    <path d="M280.28 65.64L280.6 65.54" stroke="black" stroke-width="0.5" />
    <path d="M283.8 65.54L284.12 65.64" stroke="black" stroke-width="0.5" />
    <path d="M284.12 65.64L284.35 65.92" stroke="black" stroke-width="0.5" />
    <path d="M284.35 65.92L284.44 66.31" stroke="black" stroke-width="0.5" />
    <path d="M284.44 68.22V66.31" stroke="black" stroke-width="0.5" />
    <path d="M280.6 68.99H283.8" stroke="black" stroke-width="0.5" />
    <path d="M279.96 66.31V68.22" stroke="black" stroke-width="0.5" />
    <path d="M283.8 65.54H280.6" stroke="black" stroke-width="0.5" />
    <path d="M284.88 69.44V64.08" stroke="black" stroke-width="0.5" />
    <path d="M279.52 69.44H284.88" stroke="black" stroke-width="0.5" />
    <path d="M279.52 64.08V69.44" stroke="black" stroke-width="0.5" />
    <path d="M284.88 64.08H279.52" stroke="black" stroke-width="0.5" />
    <path d="M277.85 69.73H277.87" stroke="black" stroke-width="0.5" />
    <path d="M277.89 69.73H277.92" stroke="black" stroke-width="0.5" />
    <path d="M277.94 69.73H277.97" stroke="black" stroke-width="0.5" />
    <path d="M277.99 69.73H278.02" stroke="black" stroke-width="0.5" />
    <path d="M278.04 69.73H278.07" stroke="black" stroke-width="0.5" />
    <path d="M278.09 69.73H278.12" stroke="black" stroke-width="0.5" />
    <path d="M278.14 69.73H278.17" stroke="black" stroke-width="0.5" />
    <path d="M278.19 69.73H278.22" stroke="black" stroke-width="0.5" />
    <path d="M278.24 69.73H278.27" stroke="black" stroke-width="0.5" />
    <path d="M278.29 69.73H278.32" stroke="black" stroke-width="0.5" />
    <path d="M278.34 69.73H278.37" stroke="black" stroke-width="0.5" />
    <path d="M278.39 69.73H278.42" stroke="black" stroke-width="0.5" />
    <path d="M278.44 69.73H278.47" stroke="black" stroke-width="0.5" />
    <path d="M278.49 69.73H278.52" stroke="black" stroke-width="0.5" />
    <path d="M278.54 69.73H278.57" stroke="black" stroke-width="0.5" />
    <path d="M278.59 69.73H278.62" stroke="black" stroke-width="0.5" />
    <path d="M278.64 69.73H278.67" stroke="black" stroke-width="0.5" />
    <path d="M278.69 69.73H278.72" stroke="black" stroke-width="0.5" />
    <path d="M278.74 69.73H278.77" stroke="black" stroke-width="0.5" />
    <path d="M278.79 69.73H278.82" stroke="black" stroke-width="0.5" />
    <path d="M278.84 69.73H278.87" stroke="black" stroke-width="0.5" />
    <path d="M278.89 69.73H278.92" stroke="black" stroke-width="0.5" />
    <path d="M278.94 69.73H278.97" stroke="black" stroke-width="0.5" />
    <path d="M278.99 69.73H279.02" stroke="black" stroke-width="0.5" />
    <path d="M279.04 69.73H279.07" stroke="black" stroke-width="0.5" />
    <path d="M279.09 69.73H279.12" stroke="black" stroke-width="0.5" />
    <path d="M279.14 69.73H279.17" stroke="black" stroke-width="0.5" />
    <path d="M279.19 69.73H279.22" stroke="black" stroke-width="0.5" />
    <path d="M279.24 69.73H279.27" stroke="black" stroke-width="0.5" />
    <path d="M279.29 69.73H279.32" stroke="black" stroke-width="0.5" />
    <path d="M279.34 69.73H279.37" stroke="black" stroke-width="0.5" />
    <path d="M279.39 69.73H279.42" stroke="black" stroke-width="0.5" />
    <path d="M279.44 69.73H279.47" stroke="black" stroke-width="0.5" />
    <path d="M279.49 69.73H279.52" stroke="black" stroke-width="0.5" />
    <path d="M279.54 69.73H279.57" stroke="black" stroke-width="0.5" />
    <path d="M279.59 69.73H279.62" stroke="black" stroke-width="0.5" />
    <path d="M279.64 69.73H279.67" stroke="black" stroke-width="0.5" />
    <path d="M279.69 69.73H279.72" stroke="black" stroke-width="0.5" />
    <path d="M279.74 69.73H279.77" stroke="black" stroke-width="0.5" />
    <path d="M279.79 69.73H279.82" stroke="black" stroke-width="0.5" />
    <path d="M279.84 69.73H279.87" stroke="black" stroke-width="0.5" />
    <path d="M279.89 69.73H279.92" stroke="black" stroke-width="0.5" />
    <path d="M279.94 69.73H279.97" stroke="black" stroke-width="0.5" />
    <path d="M279.99 69.73H280.02" stroke="black" stroke-width="0.5" />
    <path d="M280.04 69.73H280.07" stroke="black" stroke-width="0.5" />
    <path d="M280.09 69.73H280.12" stroke="black" stroke-width="0.5" />
    <path d="M280.14 69.73H280.17" stroke="black" stroke-width="0.5" />
    <path d="M280.19 69.73H280.22" stroke="black" stroke-width="0.5" />
    <path d="M280.24 69.73H280.27" stroke="black" stroke-width="0.5" />
    <path d="M280.29 69.73H280.32" stroke="black" stroke-width="0.5" />
    <path d="M280.34 69.73H280.37" stroke="black" stroke-width="0.5" />
    <path d="M280.39 69.73H280.42" stroke="black" stroke-width="0.5" />
    <path d="M280.44 69.73H280.47" stroke="black" stroke-width="0.5" />
    <path d="M280.49 69.73H280.52" stroke="black" stroke-width="0.5" />
    <path d="M280.54 69.73H280.57" stroke="black" stroke-width="0.5" />
    <path d="M280.59 69.73H280.62" stroke="black" stroke-width="0.5" />
    <path d="M280.64 69.73H280.67" stroke="black" stroke-width="0.5" />
    <path d="M280.69 69.73H280.72" stroke="black" stroke-width="0.5" />
    <path d="M280.74 69.73H280.77" stroke="black" stroke-width="0.5" />
    <path d="M280.79 69.73H280.82" stroke="black" stroke-width="0.5" />
    <path d="M280.84 69.73H280.87" stroke="black" stroke-width="0.5" />
    <path d="M280.89 69.73H280.92" stroke="black" stroke-width="0.5" />
    <path d="M280.94 69.73H280.97" stroke="black" stroke-width="0.5" />
    <path d="M280.99 69.73H281.02" stroke="black" stroke-width="0.5" />
    <path d="M281.04 69.73H281.07" stroke="black" stroke-width="0.5" />
    <path d="M281.09 69.73H281.12" stroke="black" stroke-width="0.5" />
    <path d="M281.14 69.73H281.17" stroke="black" stroke-width="0.5" />
    <path d="M281.19 69.73H281.22" stroke="black" stroke-width="0.5" />
    <path d="M281.24 69.73H281.27" stroke="black" stroke-width="0.5" />
    <path d="M281.29 69.73H281.32" stroke="black" stroke-width="0.5" />
    <path d="M281.34 69.73H281.37" stroke="black" stroke-width="0.5" />
    <path d="M281.39 69.73H281.42" stroke="black" stroke-width="0.5" />
    <path d="M281.44 69.73H281.47" stroke="black" stroke-width="0.5" />
    <path d="M281.49 69.73H281.52" stroke="black" stroke-width="0.5" />
    <path d="M281.54 69.73H281.57" stroke="black" stroke-width="0.5" />
    <path d="M281.59 69.73H281.62" stroke="black" stroke-width="0.5" />
    <path d="M281.64 69.73H281.67" stroke="black" stroke-width="0.5" />
    <path d="M281.69 69.73H281.72" stroke="black" stroke-width="0.5" />
    <path d="M281.74 69.73H281.77" stroke="black" stroke-width="0.5" />
    <path d="M281.79 69.73H281.82" stroke="black" stroke-width="0.5" />
    <path d="M281.84 69.73H281.87" stroke="black" stroke-width="0.5" />
    <path d="M281.89 69.73H281.92" stroke="black" stroke-width="0.5" />
    <path d="M281.94 69.73H281.97" stroke="black" stroke-width="0.5" />
    <path d="M281.99 69.73H282.02" stroke="black" stroke-width="0.5" />
    <path d="M282.04 69.73H282.07" stroke="black" stroke-width="0.5" />
    <path d="M282.09 69.73H282.12" stroke="black" stroke-width="0.5" />
    <path d="M282.14 69.73H282.17" stroke="black" stroke-width="0.5" />
    <path d="M282.19 69.73H282.22" stroke="black" stroke-width="0.5" />
    <path d="M282.24 69.73H282.27" stroke="black" stroke-width="0.5" />
    <path d="M282.29 69.73H282.32" stroke="black" stroke-width="0.5" />
    <path d="M282.34 69.73H282.37" stroke="black" stroke-width="0.5" />
    <path d="M282.39 69.73H282.42" stroke="black" stroke-width="0.5" />
    <path d="M282.44 69.73H282.47" stroke="black" stroke-width="0.5" />
    <path d="M282.49 69.73H282.52" stroke="black" stroke-width="0.5" />
    <path d="M282.54 69.73H282.57" stroke="black" stroke-width="0.5" />
    <path d="M282.59 69.73H282.62" stroke="black" stroke-width="0.5" />
    <path d="M282.64 69.73H282.67" stroke="black" stroke-width="0.5" />
    <path d="M282.69 69.73H282.72" stroke="black" stroke-width="0.5" />
    <path d="M282.74 69.73H282.77" stroke="black" stroke-width="0.5" />
    <path d="M282.79 69.73H282.82" stroke="black" stroke-width="0.5" />
    <path d="M282.84 69.73H282.87" stroke="black" stroke-width="0.5" />
    <path d="M282.89 69.73H282.92" stroke="black" stroke-width="0.5" />
    <path d="M282.94 69.73H282.97" stroke="black" stroke-width="0.5" />
    <path d="M282.99 69.73H283.02" stroke="black" stroke-width="0.5" />
    <path d="M283.04 69.73H283.07" stroke="black" stroke-width="0.5" />
    <path d="M283.09 69.73H283.12" stroke="black" stroke-width="0.5" />
    <path d="M283.14 69.73H283.17" stroke="black" stroke-width="0.5" />
    <path d="M283.19 69.73H283.22" stroke="black" stroke-width="0.5" />
    <path d="M283.24 69.73H283.27" stroke="black" stroke-width="0.5" />
    <path d="M283.29 69.73H283.32" stroke="black" stroke-width="0.5" />
    <path d="M283.34 69.73H283.37" stroke="black" stroke-width="0.5" />
    <path d="M283.39 69.73H283.42" stroke="black" stroke-width="0.5" />
    <path d="M283.44 69.73H283.47" stroke="black" stroke-width="0.5" />
    <path d="M283.49 69.73H283.52" stroke="black" stroke-width="0.5" />
    <path d="M283.54 69.73H283.57" stroke="black" stroke-width="0.5" />
    <path d="M283.59 69.73H283.62" stroke="black" stroke-width="0.5" />
    <path d="M283.64 69.73H283.67" stroke="black" stroke-width="0.5" />
    <path d="M283.69 69.73H283.72" stroke="black" stroke-width="0.5" />
    <path d="M283.74 69.73H283.77" stroke="black" stroke-width="0.5" />
    <path d="M283.79 69.73H283.82" stroke="black" stroke-width="0.5" />
    <path d="M283.84 69.73H283.87" stroke="black" stroke-width="0.5" />
    <path d="M283.89 69.73H283.92" stroke="black" stroke-width="0.5" />
    <path d="M283.94 69.73H283.97" stroke="black" stroke-width="0.5" />
    <path d="M283.99 69.73H284.02" stroke="black" stroke-width="0.5" />
    <path d="M284.04 69.73H284.07" stroke="black" stroke-width="0.5" />
    <path d="M284.09 69.73H284.12" stroke="black" stroke-width="0.5" />
    <path d="M284.14 69.73H284.17" stroke="black" stroke-width="0.5" />
    <path d="M284.19 69.73H284.22" stroke="black" stroke-width="0.5" />
    <path d="M284.24 69.73H284.27" stroke="black" stroke-width="0.5" />
    <path d="M284.29 69.73H284.32" stroke="black" stroke-width="0.5" />
    <path d="M284.34 69.73H284.37" stroke="black" stroke-width="0.5" />
    <path d="M284.39 69.73H284.42" stroke="black" stroke-width="0.5" />
    <path d="M284.44 69.73H284.48" stroke="black" stroke-width="0.5" />
    <path d="M284.49 69.73H284.53" stroke="black" stroke-width="0.5" />
    <path d="M284.54 69.73H284.58" stroke="black" stroke-width="0.5" />
    <path d="M284.59 69.73H284.63" stroke="black" stroke-width="0.5" />
    <path d="M284.64 69.73H284.68" stroke="black" stroke-width="0.5" />
    <path d="M284.69 69.73H284.73" stroke="black" stroke-width="0.5" />
    <path d="M284.74 69.73H284.78" stroke="black" stroke-width="0.5" />
    <path d="M284.79 69.73H284.83" stroke="black" stroke-width="0.5" />
    <path d="M284.84 69.73H284.88" stroke="black" stroke-width="0.5" />
    <path d="M284.89 69.73H284.93" stroke="black" stroke-width="0.5" />
    <path d="M284.94 69.73H284.98" stroke="black" stroke-width="0.5" />
    <path d="M284.99 69.73H285.03" stroke="black" stroke-width="0.5" />
    <path d="M285.04 69.73H285.08" stroke="black" stroke-width="0.5" />
    <path d="M285.09 69.73H285.13" stroke="black" stroke-width="0.5" />
    <path d="M285.14 69.73H285.18" stroke="black" stroke-width="0.5" />
    <path d="M285.19 69.73H285.23" stroke="black" stroke-width="0.5" />
    <path d="M285.24 69.73H285.28" stroke="black" stroke-width="0.5" />
    <path d="M285.29 69.73H285.33" stroke="black" stroke-width="0.5" />
    <path d="M285.34 69.73H285.38" stroke="black" stroke-width="0.5" />
    <path d="M285.39 69.73H285.43" stroke="black" stroke-width="0.5" />
    <path d="M285.44 69.73H285.48" stroke="black" stroke-width="0.5" />
    <path d="M285.49 69.73H285.53" stroke="black" stroke-width="0.5" />
    <path d="M285.54 69.73H285.58" stroke="black" stroke-width="0.5" />
    <path d="M285.59 69.73H285.63" stroke="black" stroke-width="0.5" />
    <path d="M285.64 69.73H285.68" stroke="black" stroke-width="0.5" />
    <path d="M285.69 69.73H285.73" stroke="black" stroke-width="0.5" />
    <path d="M285.74 69.73H285.78" stroke="black" stroke-width="0.5" />
    <path d="M285.79 69.73H285.83" stroke="black" stroke-width="0.5" />
    <path d="M285.84 69.73H285.88" stroke="black" stroke-width="0.5" />
    <path d="M285.89 69.73H285.93" stroke="black" stroke-width="0.5" />
    <path d="M285.94 69.73H285.98" stroke="black" stroke-width="0.5" />
    <path d="M285.99 69.73H286.03" stroke="black" stroke-width="0.5" />
    <path d="M286.04 69.73H286.08" stroke="black" stroke-width="0.5" />
    <path d="M286.09 69.73H286.13" stroke="black" stroke-width="0.5" />
    <path d="M286.14 69.73H286.18" stroke="black" stroke-width="0.5" />
    <path d="M286.19 69.73H286.23" stroke="black" stroke-width="0.5" />
    <path d="M286.24 69.73H286.28" stroke="black" stroke-width="0.5" />
    <path d="M286.29 69.73H286.33" stroke="black" stroke-width="0.5" />
    <path d="M286.34 69.73H286.38" stroke="black" stroke-width="0.5" />
    <path d="M286.39 69.73H286.43" stroke="black" stroke-width="0.5" />
    <path d="M286.44 69.73H286.48" stroke="black" stroke-width="0.5" />
    <path d="M286.49 69.73H286.53" stroke="black" stroke-width="0.5" />
    <path d="M286.54 69.73H286.58" stroke="black" stroke-width="0.5" />
    <path d="M286.59 69.73H286.63" stroke="black" stroke-width="0.5" />
    <path d="M286.64 69.73H286.68" stroke="black" stroke-width="0.5" />
    <path d="M286.69 69.73H286.73" stroke="black" stroke-width="0.5" />
    <path d="M286.74 69.73H286.78" stroke="black" stroke-width="0.5" />
    <path d="M286.79 69.73H286.83" stroke="black" stroke-width="0.5" />
    <path d="M286.84 69.73H286.88" stroke="black" stroke-width="0.5" />
    <path d="M286.89 69.73H286.93" stroke="black" stroke-width="0.5" />
    <path d="M286.94 69.73H286.98" stroke="black" stroke-width="0.5" />
    <path d="M286.99 69.73H287.03" stroke="black" stroke-width="0.5" />
    <path d="M287.04 69.73H287.08" stroke="black" stroke-width="0.5" />
    <path d="M287.09 69.73H287.13" stroke="black" stroke-width="0.5" />
    <path d="M287.14 69.73H287.18" stroke="black" stroke-width="0.5" />
    <path d="M287.19 69.73H287.23" stroke="black" stroke-width="0.5" />
    <path d="M287.24 69.73H287.28" stroke="black" stroke-width="0.5" />
    <path d="M287.29 69.73H287.33" stroke="black" stroke-width="0.5" />
    <path d="M287.34 69.73H287.38" stroke="black" stroke-width="0.5" />
    <path d="M287.39 69.73H287.43" stroke="black" stroke-width="0.5" />
    <path d="M287.44 69.73H287.48" stroke="black" stroke-width="0.5" />
    <path d="M287.49 69.73H287.53" stroke="black" stroke-width="0.5" />
    <path d="M287.54 69.73H287.58" stroke="black" stroke-width="0.5" />
    <path d="M287.59 69.73H287.63" stroke="black" stroke-width="0.5" />
    <path d="M287.64 69.73H287.68" stroke="black" stroke-width="0.5" />
    <path d="M287.69 69.73H287.73" stroke="black" stroke-width="0.5" />
    <path d="M287.74 69.73H287.78" stroke="black" stroke-width="0.5" />
    <path d="M287.79 69.73H287.83" stroke="black" stroke-width="0.5" />
    <path d="M287.84 69.73H287.88" stroke="black" stroke-width="0.5" />
    <path d="M287.89 69.73H287.93" stroke="black" stroke-width="0.5" />
    <path d="M287.94 69.73H287.98" stroke="black" stroke-width="0.5" />
    <path d="M287.99 69.73H288.03" stroke="black" stroke-width="0.5" />
    <path d="M288.04 69.73H288.08" stroke="black" stroke-width="0.5" />
    <path d="M288.09 69.73H288.13" stroke="black" stroke-width="0.5" />
    <path d="M288.14 69.73H288.18" stroke="black" stroke-width="0.5" />
    <path d="M288.19 69.73H288.23" stroke="black" stroke-width="0.5" />
    <path d="M288.24 69.73H288.28" stroke="black" stroke-width="0.5" />
    <path d="M288.29 69.73H288.33" stroke="black" stroke-width="0.5" />
    <path d="M288.34 69.73H288.38" stroke="black" stroke-width="0.5" />
    <path d="M288.39 69.73H288.43" stroke="black" stroke-width="0.5" />
    <path d="M288.44 69.73H288.48" stroke="black" stroke-width="0.5" />
    <path d="M288.49 69.73H288.53" stroke="black" stroke-width="0.5" />
    <path d="M288.54 69.73H288.58" stroke="black" stroke-width="0.5" />
    <path d="M288.59 69.73H288.63" stroke="black" stroke-width="0.5" />
    <path d="M288.64 69.73H288.68" stroke="black" stroke-width="0.5" />
    <path d="M288.69 69.73H288.73" stroke="black" stroke-width="0.5" />
    <path d="M288.74 69.73H288.78" stroke="black" stroke-width="0.5" />
    <path d="M288.79 69.73H288.83" stroke="black" stroke-width="0.5" />
    <path d="M288.84 69.73H288.88" stroke="black" stroke-width="0.5" />
    <path d="M288.89 69.73H288.93" stroke="black" stroke-width="0.5" />
    <path d="M288.94 69.73H288.98" stroke="black" stroke-width="0.5" />
    <path d="M288.99 69.73H289.03" stroke="black" stroke-width="0.5" />
    <path d="M289.05 69.73H289.08" stroke="black" stroke-width="0.5" />
    <path d="M289.1 69.73H289.13" stroke="black" stroke-width="0.5" />
    <path d="M289.15 69.73H289.18" stroke="black" stroke-width="0.5" />
    <path d="M289.2 69.73H289.23" stroke="black" stroke-width="0.5" />
    <path d="M289.25 69.73H289.28" stroke="black" stroke-width="0.5" />
    <path d="M289.3 69.73H289.33" stroke="black" stroke-width="0.5" />
    <path d="M289.35 69.73H289.38" stroke="black" stroke-width="0.5" />
    <path d="M289.4 69.73H289.43" stroke="black" stroke-width="0.5" />
    <path d="M289.45 69.73H289.48" stroke="black" stroke-width="0.5" />
    <path d="M289.5 69.73H289.53" stroke="black" stroke-width="0.5" />
    <path d="M289.55 69.73H289.58" stroke="black" stroke-width="0.5" />
    <path d="M289.6 69.73H289.63" stroke="black" stroke-width="0.5" />
    <path d="M289.65 69.73H289.68" stroke="black" stroke-width="0.5" />
    <path d="M289.7 69.73H289.73" stroke="black" stroke-width="0.5" />
    <path d="M289.75 69.73H289.78" stroke="black" stroke-width="0.5" />
    <path d="M289.8 69.73H289.83" stroke="black" stroke-width="0.5" />
    <path d="M289.85 69.73H289.88" stroke="black" stroke-width="0.5" />
    <path d="M289.9 69.73H289.93" stroke="black" stroke-width="0.5" />
    <path d="M289.95 69.73H289.98" stroke="black" stroke-width="0.5" />
    <path d="M290 69.73H290.03" stroke="black" stroke-width="0.5" />
    <path d="M290.05 69.73H290.08" stroke="black" stroke-width="0.5" />
    <path d="M290.1 69.73H290.13" stroke="black" stroke-width="0.5" />
    <path d="M290.15 69.73H290.18" stroke="black" stroke-width="0.5" />
    <path d="M290.2 69.73H290.23" stroke="black" stroke-width="0.5" />
    <path d="M290.25 69.73H290.28" stroke="black" stroke-width="0.5" />
    <path d="M290.3 69.73H290.33" stroke="black" stroke-width="0.5" />
    <path d="M290.35 69.73H290.38" stroke="black" stroke-width="0.5" />
    <path d="M290.4 69.73H290.43" stroke="black" stroke-width="0.5" />
    <path d="M290.45 69.73H290.48" stroke="black" stroke-width="0.5" />
    <path d="M290.5 69.73H290.53" stroke="black" stroke-width="0.5" />
    <path d="M290.55 69.73H290.58" stroke="black" stroke-width="0.5" />
    <path d="M290.6 69.73H290.63" stroke="black" stroke-width="0.5" />
    <path d="M290.65 69.73H290.68" stroke="black" stroke-width="0.5" />
    <path d="M290.7 69.73H290.73" stroke="black" stroke-width="0.5" />
    <path d="M290.75 69.73H290.78" stroke="black" stroke-width="0.5" />
    <path d="M290.8 69.73H290.83" stroke="black" stroke-width="0.5" />
    <path d="M290.85 69.73H290.88" stroke="black" stroke-width="0.5" />
    <path d="M290.9 69.73H290.93" stroke="black" stroke-width="0.5" />
    <path d="M290.95 69.73H290.98" stroke="black" stroke-width="0.5" />
    <path d="M291 69.73H291.03" stroke="black" stroke-width="0.5" />
    <path d="M291.05 69.73H291.08" stroke="black" stroke-width="0.5" />
    <path d="M291.1 69.73H291.13" stroke="black" stroke-width="0.5" />
    <path d="M291.15 69.73H291.18" stroke="black" stroke-width="0.5" />
    <path d="M291.2 69.73H291.23" stroke="black" stroke-width="0.5" />
    <path d="M291.25 69.73H291.28" stroke="black" stroke-width="0.5" />
    <path d="M291.3 69.73H291.33" stroke="black" stroke-width="0.5" />
    <path d="M291.35 69.73H291.38" stroke="black" stroke-width="0.5" />
    <path d="M291.4 69.73H291.43" stroke="black" stroke-width="0.5" />
    <path d="M291.45 69.73H291.48" stroke="black" stroke-width="0.5" />
    <path d="M291.5 69.73H291.53" stroke="black" stroke-width="0.5" />
    <path d="M291.55 69.73H291.58" stroke="black" stroke-width="0.5" />
    <path d="M291.6 69.73H291.63" stroke="black" stroke-width="0.5" />
    <path d="M291.65 69.73H291.68" stroke="black" stroke-width="0.5" />
    <path d="M291.7 69.73H291.73" stroke="black" stroke-width="0.5" />
    <path d="M291.75 69.73H291.78" stroke="black" stroke-width="0.5" />
    <path d="M291.8 69.73H291.83" stroke="black" stroke-width="0.5" />
    <path d="M291.85 69.73H291.88" stroke="black" stroke-width="0.5" />
    <path d="M291.9 69.73H291.93" stroke="black" stroke-width="0.5" />
    <path d="M291.95 69.73H291.98" stroke="black" stroke-width="0.5" />
    <path d="M292 69.73H292.03" stroke="black" stroke-width="0.5" />
    <path d="M292.05 69.73H292.08" stroke="black" stroke-width="0.5" />
    <path d="M292.1 69.73H292.13" stroke="black" stroke-width="0.5" />
    <path d="M292.15 69.73H292.18" stroke="black" stroke-width="0.5" />
    <path d="M292.2 69.73H292.23" stroke="black" stroke-width="0.5" />
    <path d="M292.25 69.73H292.28" stroke="black" stroke-width="0.5" />
    <path d="M292.3 69.73H292.33" stroke="black" stroke-width="0.5" />
    <path d="M292.35 69.73H292.38" stroke="black" stroke-width="0.5" />
    <path d="M292.4 69.73H292.43" stroke="black" stroke-width="0.5" />
    <path d="M292.45 69.73H292.48" stroke="black" stroke-width="0.5" />
    <path d="M292.5 69.73H292.53" stroke="black" stroke-width="0.5" />
    <path d="M292.55 69.73H292.58" stroke="black" stroke-width="0.5" />
    <path d="M292.6 69.73H292.63" stroke="black" stroke-width="0.5" />
    <path d="M292.65 69.73H292.68" stroke="black" stroke-width="0.5" />
    <path d="M292.7 69.73H292.73" stroke="black" stroke-width="0.5" />
    <path d="M292.75 69.73H292.78" stroke="black" stroke-width="0.5" />
    <path d="M292.8 69.73H292.83" stroke="black" stroke-width="0.5" />
    <path d="M292.85 69.73H292.88" stroke="black" stroke-width="0.5" />
    <path d="M292.9 69.73H292.93" stroke="black" stroke-width="0.5" />
    <path d="M292.95 69.73H292.98" stroke="black" stroke-width="0.5" />
    <path d="M293 69.73H293.03" stroke="black" stroke-width="0.5" />
    <path d="M293.05 69.73H293.08" stroke="black" stroke-width="0.5" />
    <path d="M293.1 69.73H293.13" stroke="black" stroke-width="0.5" />
    <path d="M293.15 69.73H293.18" stroke="black" stroke-width="0.5" />
    <path d="M293.2 69.73H293.23" stroke="black" stroke-width="0.5" />
    <path d="M293.25 69.73H293.28" stroke="black" stroke-width="0.5" />
    <path d="M293.3 69.73H293.33" stroke="black" stroke-width="0.5" />
    <path d="M293.35 69.73H293.38" stroke="black" stroke-width="0.5" />
    <path d="M293.4 69.73H293.43" stroke="black" stroke-width="0.5" />
    <path d="M293.45 69.73H293.48" stroke="black" stroke-width="0.5" />
    <path d="M293.5 69.73H293.53" stroke="black" stroke-width="0.5" />
    <path d="M293.55 69.73H293.58" stroke="black" stroke-width="0.5" />
    <path d="M293.6 69.73H293.63" stroke="black" stroke-width="0.5" />
    <path d="M293.65 69.73H293.68" stroke="black" stroke-width="0.5" />
    <path d="M293.7 69.73H293.73" stroke="black" stroke-width="0.5" />
    <path d="M293.75 69.73H293.78" stroke="black" stroke-width="0.5" />
    <path d="M293.8 69.73H293.83" stroke="black" stroke-width="0.5" />
    <path d="M293.85 69.73H293.87" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.73V69.7" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.68V69.65" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.63V69.6" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.58V69.55" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.53V69.5" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.48V69.45" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.43V69.4" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.38V69.35" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.33V69.3" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.28V69.25" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.23V69.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.18V69.15" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.13V69.1" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.08V69.05" stroke="black" stroke-width="0.5" />
    <path d="M293.87 69.03V69" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.98V68.95" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.93V68.9" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.88V68.85" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.83V68.8" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.78V68.75" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.73V68.7" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.68V68.65" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.63V68.6" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.58V68.55" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.53V68.5" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.48V68.45" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.43V68.4" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.38V68.35" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.33V68.3" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.28V68.25" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.23V68.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.18V68.15" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.13V68.1" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.08V68.05" stroke="black" stroke-width="0.5" />
    <path d="M293.87 68.03V68" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.98V67.95" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.93V67.9" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.88V67.85" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.83V67.8" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.78V67.75" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.73V67.7" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.68V67.65" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.63V67.6" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.58V67.55" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.53V67.5" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.48V67.45" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.43V67.4" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.38V67.35" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.33V67.3" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.28V67.25" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.23V67.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.18V67.15" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.13V67.1" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.08V67.05" stroke="black" stroke-width="0.5" />
    <path d="M293.87 67.03V67" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.98V66.95" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.93V66.9" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.88V66.85" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.83V66.8" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.78V66.75" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.73V66.7" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.68V66.65" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.63V66.6" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.58V66.55" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.53V66.5" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.48V66.45" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.43V66.4" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.38V66.35" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.33V66.3" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.28V66.25" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.23V66.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.18V66.15" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.13V66.1" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.08V66.05" stroke="black" stroke-width="0.5" />
    <path d="M293.87 66.03V66" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.98V65.95" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.93V65.9" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.88V65.85" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.83V65.8" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.78V65.75" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.73V65.7" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.68V65.65" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.63V65.6" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.58V65.55" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.53V65.5" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.48V65.45" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.43V65.4" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.38V65.35" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.33V65.3" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.28V65.25" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.23V65.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.18V65.15" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.13V65.1" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.08V65.05" stroke="black" stroke-width="0.5" />
    <path d="M293.87 65.03V65" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.98V64.95" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.93V64.9" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.88V64.85" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.83V64.8" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.78V64.75" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.73V64.7" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.68V64.65" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.63V64.6" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.58V64.55" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.53V64.5" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.48V64.45" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.43V64.4" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.38V64.35" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.33V64.3" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.28V64.25" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.23V64.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.18V64.15" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.13V64.1" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.08V64.05" stroke="black" stroke-width="0.5" />
    <path d="M293.87 64.03V64" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.98V63.95" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.93V63.9" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.88V63.85" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.83V63.8" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.78V63.75" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.73V63.7" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.68V63.65" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.63V63.6" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.58V63.55" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.53V63.5" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.48V63.45" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.43V63.4" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.38V63.35" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.33V63.3" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.28V63.25" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.23V63.2" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.18V63.15" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.13V63.1" stroke="black" stroke-width="0.5" />
    <path d="M293.87 63.08V63.05" stroke="black" stroke-width="0.5" />
    <path d="M181.78 101.75H191.12" stroke="black" stroke-width="0.5" />
    <path d="M200.73 101.75H202.06" stroke="black" stroke-width="0.5" />
    <path d="M203.4 101.75H257.84" stroke="black" stroke-width="0.5" />
    <path d="M181.78 103.08H191.12" stroke="black" stroke-width="0.5" />
    <path d="M200.73 103.08H259.17" stroke="black" stroke-width="0.5" />
    <path d="M257.84 100.68H259.17" stroke="black" stroke-width="0.5" />
    <path d="M257.84 100.15H259.17" stroke="black" stroke-width="0.5" />
    <path d="M257.83 90.01H259.17" stroke="black" stroke-width="0.5" />
    <path d="M257.83 90.54H259.17" stroke="black" stroke-width="0.5" />
    <path d="M191.12 101.75V103.08" stroke="black" stroke-width="0.5" />
    <path d="M190.59 101.75V103.08" stroke="black" stroke-width="0.5" />
    <path d="M200.73 101.75V103.08" stroke="black" stroke-width="0.5" />
    <path d="M201.26 101.75V103.08" stroke="black" stroke-width="0.5" />
    <path d="M215 69.43H218.22" stroke="black" stroke-width="0.5" />
    <path d="M218.22 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M215 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M318.42 161.56V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M317.08 72.33V63.05" stroke="black" stroke-width="0.5" />
    <path d="M319.75 161.56V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M308.14 164.9H307.61" stroke="black" stroke-width="0.5" />
    <path d="M307.61 164.32V163.7" stroke="black" stroke-width="0.5" />
    <path d="M307.61 163.7H308.14" stroke="black" stroke-width="0.5" />
    <path d="M308.14 163.7V164.9" stroke="black" stroke-width="0.5" />
    <path d="M278.79 163.7H279.32" stroke="black" stroke-width="0.5" />
    <path d="M279.32 163.7V164.32" stroke="black" stroke-width="0.5" />
    <path d="M283.63 163.7V164.59" stroke="black" stroke-width="0.5" />
    <path d="M284.19 163.7V164.59" stroke="black" stroke-width="0.5" />
    <path d="M279.32 164.9H278.79" stroke="black" stroke-width="0.5" />
    <path d="M278.79 164.9V163.7" stroke="black" stroke-width="0.5" />
    <path d="M302.76 164.32V163.7" stroke="black" stroke-width="0.5" />
    <path d="M302.76 163.7H303.3" stroke="black" stroke-width="0.5" />
    <path d="M303.3 163.7V164.32" stroke="black" stroke-width="0.5" />
    <path d="M303.3 164.9H302.76" stroke="black" stroke-width="0.5" />
    <path d="M307.61 163.7H303.3" stroke="black" stroke-width="0.5" />
    <path d="M302.76 163.7H284.19" stroke="black" stroke-width="0.5" />
    <path d="M283.63 163.7H279.32" stroke="black" stroke-width="0.5" />
    <path d="M302.76 164.9H284.19" stroke="black" stroke-width="0.5" />
    <path d="M283.63 164.9H279.32" stroke="black" stroke-width="0.5" />
    <path d="M307.61 164.9H303.3" stroke="black" stroke-width="0.5" />
    <path d="M279.32 164.59H307.61" stroke="black" stroke-width="0.5" />
    <path d="M307.61 164.32H279.32" stroke="black" stroke-width="0.5" />
    <path d="M307.61 164.32V164.59" stroke="black" stroke-width="0.5" />
    <path
      d="M279.32 164.32V164.59L307.61 164.32H279.32Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M307.61 164.32L279.32 164.59H307.61V164.32Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M279.32 164.32V164.59" stroke="black" stroke-width="0.5" />
    <path d="M283.63 164.9H284.19" stroke="black" stroke-width="0.5" />
    <path d="M283.63 163.7H284.19" stroke="black" stroke-width="0.5" />
    <path d="M278.79 163.7H308.14" stroke="black" stroke-width="0.5" />
    <path d="M308.14 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M308.14 161.56H278.79" stroke="black" stroke-width="0.5" />
    <path d="M278.79 161.56V163.7" stroke="black" stroke-width="0.5" />
    <path d="M284.6 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M283.27 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M278.79 162.63H283.27" stroke="black" stroke-width="0.5" />
    <path d="M284.6 162.63H287.24" stroke="black" stroke-width="0.5" />
    <path d="M289.09 162.63H291.91" stroke="black" stroke-width="0.5" />
    <path d="M294 162.63H296.33" stroke="black" stroke-width="0.5" />
    <path d="M298.62 162.63H300.93" stroke="black" stroke-width="0.5" />
    <path d="M303.38 162.63H305.54" stroke="black" stroke-width="0.5" />
    <path d="M306.93 162.63H308.14" stroke="black" stroke-width="0.5" />
    <path d="M278.79 163.7V165.74" stroke="black" stroke-width="0.5" />
    <path d="M308.14 163.7V165.74" stroke="black" stroke-width="0.5" />
    <path d="M308.14 161.56H319.5" stroke="black" stroke-width="0.5" />
    <path d="M39.14 164.9H38.61" stroke="black" stroke-width="0.5" />
    <path d="M38.61 164.32V163.7" stroke="black" stroke-width="0.5" />
    <path d="M38.61 163.7H39.14" stroke="black" stroke-width="0.5" />
    <path d="M39.14 163.7V164.9" stroke="black" stroke-width="0.5" />
    <path d="M9.78998 163.7H10.32" stroke="black" stroke-width="0.5" />
    <path d="M10.32 163.7V164.32" stroke="black" stroke-width="0.5" />
    <path d="M14.63 163.7V164.59" stroke="black" stroke-width="0.5" />
    <path d="M15.19 163.7V164.59" stroke="black" stroke-width="0.5" />
    <path d="M10.32 164.9H9.78998" stroke="black" stroke-width="0.5" />
    <path d="M9.78998 164.9V163.7" stroke="black" stroke-width="0.5" />
    <path d="M33.77 164.32V163.7" stroke="black" stroke-width="0.5" />
    <path d="M33.77 163.7H34.3" stroke="black" stroke-width="0.5" />
    <path d="M34.3 163.7V164.32" stroke="black" stroke-width="0.5" />
    <path d="M34.3 164.9H33.77" stroke="black" stroke-width="0.5" />
    <path d="M38.61 163.7H34.3" stroke="black" stroke-width="0.5" />
    <path d="M33.77 163.7H15.19" stroke="black" stroke-width="0.5" />
    <path d="M14.63 163.7H10.32" stroke="black" stroke-width="0.5" />
    <path d="M33.77 164.9H15.19" stroke="black" stroke-width="0.5" />
    <path d="M14.63 164.9H10.32" stroke="black" stroke-width="0.5" />
    <path d="M38.61 164.9H34.3" stroke="black" stroke-width="0.5" />
    <path d="M10.32 164.59H38.61" stroke="black" stroke-width="0.5" />
    <path d="M38.61 164.32H10.32" stroke="black" stroke-width="0.5" />
    <path d="M38.61 164.32V164.59" stroke="black" stroke-width="0.5" />
    <path
      d="M10.32 164.32V164.59L38.61 164.32H10.32Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M38.61 164.32L10.32 164.59H38.61V164.32Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M10.32 164.32V164.59" stroke="black" stroke-width="0.5" />
    <path d="M14.63 164.9H15.19" stroke="black" stroke-width="0.5" />
    <path d="M14.63 163.7H15.19" stroke="black" stroke-width="0.5" />
    <path d="M90.38 164.9H89.85" stroke="black" stroke-width="0.5" />
    <path d="M89.85 164.32V163.7" stroke="black" stroke-width="0.5" />
    <path d="M89.85 163.7H90.38" stroke="black" stroke-width="0.5" />
    <path d="M90.38 163.7V164.9" stroke="black" stroke-width="0.5" />
    <path d="M61.03 163.7H61.56" stroke="black" stroke-width="0.5" />
    <path d="M61.56 163.7V164.32" stroke="black" stroke-width="0.5" />
    <path d="M65.87 163.7V164.59" stroke="black" stroke-width="0.5" />
    <path d="M66.43 163.7V164.59" stroke="black" stroke-width="0.5" />
    <path d="M61.56 164.9H61.03" stroke="black" stroke-width="0.5" />
    <path d="M61.03 164.9V163.7" stroke="black" stroke-width="0.5" />
    <path d="M85 164.32V163.7" stroke="black" stroke-width="0.5" />
    <path d="M85 163.7H85.54" stroke="black" stroke-width="0.5" />
    <path d="M85.54 163.7V164.32" stroke="black" stroke-width="0.5" />
    <path d="M85.54 164.9H85" stroke="black" stroke-width="0.5" />
    <path d="M89.85 163.7H85.54" stroke="black" stroke-width="0.5" />
    <path d="M85 163.7H66.43" stroke="black" stroke-width="0.5" />
    <path d="M65.87 163.7H61.56" stroke="black" stroke-width="0.5" />
    <path d="M85 164.9H66.43" stroke="black" stroke-width="0.5" />
    <path d="M65.87 164.9H61.56" stroke="black" stroke-width="0.5" />
    <path d="M89.85 164.9H85.54" stroke="black" stroke-width="0.5" />
    <path d="M61.56 164.59H89.85" stroke="black" stroke-width="0.5" />
    <path d="M89.85 164.32H61.56" stroke="black" stroke-width="0.5" />
    <path d="M89.85 164.32V164.59" stroke="black" stroke-width="0.5" />
    <path
      d="M61.56 164.32V164.59L89.85 164.32H61.56Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M89.85 164.32L61.56 164.59H89.85V164.32Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M61.56 164.32V164.59" stroke="black" stroke-width="0.5" />
    <path d="M65.87 164.9H66.43" stroke="black" stroke-width="0.5" />
    <path d="M65.87 163.7H66.43" stroke="black" stroke-width="0.5" />
    <path d="M256.9 164.9H256.37" stroke="black" stroke-width="0.5" />
    <path d="M256.37 164.32V163.7" stroke="black" stroke-width="0.5" />
    <path d="M256.37 163.7H256.9" stroke="black" stroke-width="0.5" />
    <path d="M256.9 163.7V164.9" stroke="black" stroke-width="0.5" />
    <path d="M227.55 163.7H228.08" stroke="black" stroke-width="0.5" />
    <path d="M228.08 163.7V164.32" stroke="black" stroke-width="0.5" />
    <path d="M232.39 163.7V164.59" stroke="black" stroke-width="0.5" />
    <path d="M232.95 163.7V164.59" stroke="black" stroke-width="0.5" />
    <path d="M228.08 164.9H227.55" stroke="black" stroke-width="0.5" />
    <path d="M227.55 164.9V163.7" stroke="black" stroke-width="0.5" />
    <path d="M251.53 164.32V163.7" stroke="black" stroke-width="0.5" />
    <path d="M251.53 163.7H252.06" stroke="black" stroke-width="0.5" />
    <path d="M252.06 163.7V164.32" stroke="black" stroke-width="0.5" />
    <path d="M252.06 164.9H251.53" stroke="black" stroke-width="0.5" />
    <path d="M256.37 163.7H252.06" stroke="black" stroke-width="0.5" />
    <path d="M251.53 163.7H232.95" stroke="black" stroke-width="0.5" />
    <path d="M232.39 163.7H228.08" stroke="black" stroke-width="0.5" />
    <path d="M251.53 164.9H232.95" stroke="black" stroke-width="0.5" />
    <path d="M232.39 164.9H228.08" stroke="black" stroke-width="0.5" />
    <path d="M256.37 164.9H252.06" stroke="black" stroke-width="0.5" />
    <path d="M228.08 164.59H256.37" stroke="black" stroke-width="0.5" />
    <path d="M256.37 164.32H228.08" stroke="black" stroke-width="0.5" />
    <path d="M256.37 164.32V164.59" stroke="black" stroke-width="0.5" />
    <path
      d="M228.08 164.32V164.59L256.37 164.32H228.08Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M256.37 164.32L228.08 164.59H256.37V164.32Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M228.08 164.32V164.59" stroke="black" stroke-width="0.5" />
    <path d="M232.39 164.9H232.95" stroke="black" stroke-width="0.5" />
    <path d="M232.39 163.7H232.95" stroke="black" stroke-width="0.5" />
    <path d="M61.03 163.7H90.38" stroke="black" stroke-width="0.5" />
    <path d="M90.38 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M90.38 161.56H61.03" stroke="black" stroke-width="0.5" />
    <path d="M61.03 161.56V163.7" stroke="black" stroke-width="0.5" />
    <path d="M71.33 162.63H74.15" stroke="black" stroke-width="0.5" />
    <path d="M76.24 162.63H78.57" stroke="black" stroke-width="0.5" />
    <path d="M80.86 162.63H83.17" stroke="black" stroke-width="0.5" />
    <path d="M85.62 162.63H87.78" stroke="black" stroke-width="0.5" />
    <path d="M89.17 162.63H90.38" stroke="black" stroke-width="0.5" />
    <path d="M9.78998 163.7H39.14" stroke="black" stroke-width="0.5" />
    <path d="M39.14 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M39.14 161.56H9.78998" stroke="black" stroke-width="0.5" />
    <path d="M9.78998 161.56V163.7" stroke="black" stroke-width="0.5" />
    <path d="M33.33 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M34.67 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M9.78998 162.63H14.27" stroke="black" stroke-width="0.5" />
    <path d="M15.6 162.63H18.24" stroke="black" stroke-width="0.5" />
    <path d="M20.09 162.63H22.91" stroke="black" stroke-width="0.5" />
    <path d="M25 162.63H27.33" stroke="black" stroke-width="0.5" />
    <path d="M29.62 162.63H31.93" stroke="black" stroke-width="0.5" />
    <path d="M34.67 162.63H36.54" stroke="black" stroke-width="0.5" />
    <path d="M37.94 162.63H39.14" stroke="black" stroke-width="0.5" />
    <path d="M227.55 163.7H256.9" stroke="black" stroke-width="0.5" />
    <path d="M256.9 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M256.9 161.56H227.55" stroke="black" stroke-width="0.5" />
    <path d="M227.55 161.56V163.7" stroke="black" stroke-width="0.5" />
    <path d="M251.09 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M252.42 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M284.6 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M283.27 163.7V161.56" stroke="black" stroke-width="0.5" />
    <path d="M278.79 162.63H283.27" stroke="black" stroke-width="0.5" />
    <path d="M284.6 162.63H287.24" stroke="black" stroke-width="0.5" />
    <path d="M289.09 162.63H291.91" stroke="black" stroke-width="0.5" />
    <path d="M294 162.63H296.33" stroke="black" stroke-width="0.5" />
    <path d="M227.55 162.63H232.03" stroke="black" stroke-width="0.5" />
    <path d="M233.36 162.63H236" stroke="black" stroke-width="0.5" />
    <path d="M237.85 162.63H240.67" stroke="black" stroke-width="0.5" />
    <path d="M242.76 162.63H245.09" stroke="black" stroke-width="0.5" />
    <path d="M247.38 162.63H249.69" stroke="black" stroke-width="0.5" />
    <path d="M252.42 162.63H254.3" stroke="black" stroke-width="0.5" />
    <path d="M255.7 162.63H256.9" stroke="black" stroke-width="0.5" />
    <path d="M9.78998 163.7V165.74" stroke="black" stroke-width="0.5" />
    <path d="M10 163.7H104.43" stroke="black" stroke-width="0.5" />
    <path d="M9.73282 165.74H104.43" stroke="black" stroke-width="0.5" />
    <path d="M213.51 165.74H308.5" stroke="black" stroke-width="0.5" />
    <path d="M39.14 163.7V165.74" stroke="black" stroke-width="0.5" />
    <path d="M61.03 163.7V165.74" stroke="black" stroke-width="0.5" />
    <path d="M90.38 163.7V165.74" stroke="black" stroke-width="0.5" />
    <path d="M227.55 163.7V165.74" stroke="black" stroke-width="0.5" />
    <path d="M256.9 163.7V165.74" stroke="black" stroke-width="0.5" />
    <path d="M278.79 163.7V165.74" stroke="black" stroke-width="0.5" />
    <path d="M96.46 165.74V170.39" stroke="black" stroke-width="0.5" />
    <path d="M96.99 165.74V170.11" stroke="black" stroke-width="0.5" />
    <path d="M98.59 165.74V168.71" stroke="black" stroke-width="0.5" />
    <path d="M221.48 165.74V170.39" stroke="black" stroke-width="0.5" />
    <path d="M220.94 165.74V169.84" stroke="black" stroke-width="0.5" />
    <path d="M219.34 165.74V168.71" stroke="black" stroke-width="0.5" />
    <path d="M104.43 163.7V170.84" stroke="black" stroke-width="0.5" />
    <path d="M213.51 163.7V171.03" stroke="black" stroke-width="0.5" />
    <path d="M0.5 161.56H9.78999" stroke="black" stroke-width="0.5" />
    <path d="M39.14 161.56H61.03" stroke="black" stroke-width="0.5" />
    <path d="M90.38 161.56H105.64" stroke="black" stroke-width="0.5" />
    <path d="M106.29 161.56V170.61" stroke="black" stroke-width="0.5" />
    <path d="M211.64 170.61L211.67 161.56" stroke="black" stroke-width="0.5" />
    <path d="M211.67 161.56H227.55" stroke="black" stroke-width="0.5" />
    <path d="M256.9 161.56H278.79" stroke="black" stroke-width="0.5" />
    <path d="M68.35 161.56V163.7" stroke="black" stroke-width="0.5" />
    <path d="M307.76 2.74001H276.52" stroke="black" stroke-width="0.5" />
    <path d="M259.17 2.74001H215.94" stroke="black" stroke-width="0.5" />
    <path d="M215.94 2.74001V66.39" stroke="black" stroke-width="0.5" />
    <path d="M215.94 66.39H181.78" stroke="black" stroke-width="0.5" />
    <path d="M181.78 66.39V91.34" stroke="black" stroke-width="0.5" />
    <path d="M181.78 91.34H171.13" stroke="black" stroke-width="0.5" />
    <path d="M141.6 91.34H108.82" stroke="black" stroke-width="0.5" />
    <path d="M298.36 93.21V94.43" stroke="black" stroke-width="0.5" />
    <path d="M298.36 104.04V113.99" stroke="black" stroke-width="0.5" />
    <path d="M297.03 93.21V94.9" stroke="black" stroke-width="0.5" />
    <path d="M297.03 104.04V115.32" stroke="black" stroke-width="0.5" />
    <path d="M298.36 113.99H302.55" stroke="black" stroke-width="0.5" />
    <path d="M294.76 115.32H301.22" stroke="black" stroke-width="0.5" />
    <path d="M302.55 113.99V120.29" stroke="black" stroke-width="0.5" />
    <path d="M301.22 115.32V120.29" stroke="black" stroke-width="0.5" />
    <path d="M302.55 120.29H318.42" stroke="black" stroke-width="0.5" />
    <path d="M301.22 121.63H318.42" stroke="black" stroke-width="0.5" />
    <path d="M287.42 94.43H297.03" stroke="black" stroke-width="0.5" />
    <path d="M297.03 94.9H287.43" stroke="black" stroke-width="0.5" />
    <path d="M297.03 94.43V94.9" stroke="black" stroke-width="0.5" />
    <path d="M297.03 104.04L295.15 103.86" stroke="black" stroke-width="0.5" />
    <path d="M295.15 103.86L293.35 103.31" stroke="black" stroke-width="0.5" />
    <path d="M293.35 103.31L291.69 102.42" stroke="black" stroke-width="0.5" />
    <path d="M291.69 102.42L290.23 101.23" stroke="black" stroke-width="0.5" />
    <path d="M290.23 101.23L289.04 99.77" stroke="black" stroke-width="0.5" />
    <path d="M289.04 99.77L288.15 98.11" stroke="black" stroke-width="0.5" />
    <path d="M288.15 98.11L287.6 96.31" stroke="black" stroke-width="0.5" />
    <path d="M287.6 96.31L287.42 94.43" stroke="black" stroke-width="0.5" />
    <path d="M297.03 94.43H298.36" stroke="black" stroke-width="0.5" />
    <path d="M297.03 93.9H298.36" stroke="black" stroke-width="0.5" />
    <path d="M297.03 104.04H298.36" stroke="black" stroke-width="0.5" />
    <path d="M297.03 104.57H298.36" stroke="black" stroke-width="0.5" />
    <path d="M311.71 119.87H307.32" stroke="black" stroke-width="0.5" />
    <path d="M307.32 119.87L307.28 119.88" stroke="black" stroke-width="0.5" />
    <path d="M307.28 119.88L307.26 119.91" stroke="black" stroke-width="0.5" />
    <path d="M307.26 119.91L307.25 119.94" stroke="black" stroke-width="0.5" />
    <path d="M307.25 119.94V120.29" stroke="black" stroke-width="0.5" />
    <path d="M307.25 120.29H306.9" stroke="black" stroke-width="0.5" />
    <path d="M306.9 120.29V119.87" stroke="black" stroke-width="0.5" />
    <path d="M306.9 119.87L306.95 119.7" stroke="black" stroke-width="0.5" />
    <path d="M306.95 119.7L307.08 119.57" stroke="black" stroke-width="0.5" />
    <path d="M307.08 119.57L307.25 119.53" stroke="black" stroke-width="0.5" />
    <path d="M307.25 119.53H311.71" stroke="black" stroke-width="0.5" />
    <path d="M311.71 119.53H316.51" stroke="black" stroke-width="0.5" />
    <path d="M316.51 119.53L316.68 119.57" stroke="black" stroke-width="0.5" />
    <path d="M316.68 119.57L316.81 119.7" stroke="black" stroke-width="0.5" />
    <path d="M316.81 119.7L316.86 119.87" stroke="black" stroke-width="0.5" />
    <path d="M316.86 119.87V120.29" stroke="black" stroke-width="0.5" />
    <path d="M316.86 120.29H316.51" stroke="black" stroke-width="0.5" />
    <path d="M316.51 120.29V119.94" stroke="black" stroke-width="0.5" />
    <path d="M316.51 119.94L316.5 119.91" stroke="black" stroke-width="0.5" />
    <path d="M316.5 119.91L316.47 119.88" stroke="black" stroke-width="0.5" />
    <path d="M316.47 119.88L316.44 119.87" stroke="black" stroke-width="0.5" />
    <path d="M316.44 119.87H311.71" stroke="black" stroke-width="0.5" />
    <path d="M318 107.62L318.01 107.58" stroke="black" stroke-width="0.5" />
    <path d="M318.01 107.58L318.03 107.56" stroke="black" stroke-width="0.5" />
    <path d="M318.03 107.56L318.07 107.55" stroke="black" stroke-width="0.5" />
    <path d="M318.07 107.55H318.42" stroke="black" stroke-width="0.5" />
    <path d="M318.42 107.55V107.2" stroke="black" stroke-width="0.5" />
    <path d="M318.42 107.2H318" stroke="black" stroke-width="0.5" />
    <path d="M318 107.2L317.83 107.25" stroke="black" stroke-width="0.5" />
    <path d="M317.83 107.25L317.7 107.37" stroke="black" stroke-width="0.5" />
    <path d="M317.7 107.37L317.65 107.55" stroke="black" stroke-width="0.5" />
    <path d="M317.65 107.55V118.41" stroke="black" stroke-width="0.5" />
    <path d="M317.65 118.41L317.7 118.58" stroke="black" stroke-width="0.5" />
    <path d="M317.7 118.58L317.83 118.71" stroke="black" stroke-width="0.5" />
    <path d="M317.83 118.71L318 118.75" stroke="black" stroke-width="0.5" />
    <path d="M318 118.75H318.42" stroke="black" stroke-width="0.5" />
    <path d="M318.42 118.75V118.41" stroke="black" stroke-width="0.5" />
    <path d="M318.42 118.41H318.07" stroke="black" stroke-width="0.5" />
    <path d="M318.07 118.41L318.03 118.4" stroke="black" stroke-width="0.5" />
    <path d="M318.03 118.4L318.01 118.37" stroke="black" stroke-width="0.5" />
    <path d="M318.01 118.37L318 118.34" stroke="black" stroke-width="0.5" />
    <path d="M318 118.34V107.62" stroke="black" stroke-width="0.5" />
    <path d="M315 118.02L315.07 117.46" stroke="black" stroke-width="0.5" />
    <path d="M315.07 117.46L315.13 117.04" stroke="black" stroke-width="0.5" />
    <path d="M315.13 117.04L315.19 116.68" stroke="black" stroke-width="0.5" />
    <path d="M315.19 116.68L315.25 116.38" stroke="black" stroke-width="0.5" />
    <path d="M315.25 116.38L315.31 116.14" stroke="black" stroke-width="0.5" />
    <path d="M315.31 116.14L315.35 115.95" stroke="black" stroke-width="0.5" />
    <path d="M315.35 115.95L315.39 115.83" stroke="black" stroke-width="0.5" />
    <path d="M315.39 115.83L315.41 115.73" stroke="black" stroke-width="0.5" />
    <path d="M315.41 115.73L315.43 115.62" stroke="black" stroke-width="0.5" />
    <path d="M315.43 115.62L315.44 115.48" stroke="black" stroke-width="0.5" />
    <path d="M315.44 115.48L315.45 115.28" stroke="black" stroke-width="0.5" />
    <path d="M315.45 115.28V115.05" stroke="black" stroke-width="0.5" />
    <path d="M315.45 115.05L315.43 114.77" stroke="black" stroke-width="0.5" />
    <path d="M315.43 114.77L315.39 114.48" stroke="black" stroke-width="0.5" />
    <path d="M315.39 114.48L315.32 114.16" stroke="black" stroke-width="0.5" />
    <path d="M315.32 114.16L315.23 113.86" stroke="black" stroke-width="0.5" />
    <path d="M315.23 113.86L315.1 113.55" stroke="black" stroke-width="0.5" />
    <path d="M315.1 113.55L314.94 113.27" stroke="black" stroke-width="0.5" />
    <path d="M314.94 113.27L314.76 113.01" stroke="black" stroke-width="0.5" />
    <path d="M314.76 113.01L314.55 112.8" stroke="black" stroke-width="0.5" />
    <path d="M314.55 112.8L314.33 112.62" stroke="black" stroke-width="0.5" />
    <path d="M314.33 112.62L314.11 112.49" stroke="black" stroke-width="0.5" />
    <path d="M314.11 112.49L313.89 112.41" stroke="black" stroke-width="0.5" />
    <path d="M313.89 112.41L313.67 112.39" stroke="black" stroke-width="0.5" />
    <path d="M313.67 112.39L313.45 112.41" stroke="black" stroke-width="0.5" />
    <path d="M313.45 112.41L313.23 112.49" stroke="black" stroke-width="0.5" />
    <path d="M313.23 112.49L313 112.62" stroke="black" stroke-width="0.5" />
    <path d="M313 112.62L312.78 112.8" stroke="black" stroke-width="0.5" />
    <path d="M312.78 112.8L312.58 113.01" stroke="black" stroke-width="0.5" />
    <path d="M312.58 113.01L312.39 113.27" stroke="black" stroke-width="0.5" />
    <path d="M312.39 113.27L312.23 113.55" stroke="black" stroke-width="0.5" />
    <path d="M312.23 113.55L312.11 113.85" stroke="black" stroke-width="0.5" />
    <path d="M312.11 113.85L312.01 114.16" stroke="black" stroke-width="0.5" />
    <path d="M312.01 114.16L311.94 114.48" stroke="black" stroke-width="0.5" />
    <path d="M311.94 114.48L311.9 114.77" stroke="black" stroke-width="0.5" />
    <path d="M311.9 114.77L311.89 115.05" stroke="black" stroke-width="0.5" />
    <path d="M311.89 115.05V115.29" stroke="black" stroke-width="0.5" />
    <path d="M311.89 115.29L311.9 115.49" stroke="black" stroke-width="0.5" />
    <path d="M311.9 115.49L311.91 115.62" stroke="black" stroke-width="0.5" />
    <path d="M311.91 115.62L311.93 115.72" stroke="black" stroke-width="0.5" />
    <path d="M311.93 115.72L311.95 115.83" stroke="black" stroke-width="0.5" />
    <path d="M311.95 115.83L311.98 115.96" stroke="black" stroke-width="0.5" />
    <path d="M311.98 115.96L312.03 116.14" stroke="black" stroke-width="0.5" />
    <path d="M312.03 116.14L312.09 116.39" stroke="black" stroke-width="0.5" />
    <path d="M312.09 116.39L312.15 116.68" stroke="black" stroke-width="0.5" />
    <path d="M312.15 116.68L312.21 117.04" stroke="black" stroke-width="0.5" />
    <path d="M312.21 117.04L312.26 117.46" stroke="black" stroke-width="0.5" />
    <path d="M312.26 117.46L312.32 117.9" stroke="black" stroke-width="0.5" />
    <path d="M312.32 117.9V118.01" stroke="black" stroke-width="0.5" />
    <path d="M311.85 118.16L311.81 118.18" stroke="black" stroke-width="0.5" />
    <path d="M311.81 118.18L311.77 118.19" stroke="black" stroke-width="0.5" />
    <path d="M311.77 118.19L311.74 118.21" stroke="black" stroke-width="0.5" />
    <path d="M311.74 118.21L311.71 118.22" stroke="black" stroke-width="0.5" />
    <path d="M311.71 118.22L311.68 118.24" stroke="black" stroke-width="0.5" />
    <path d="M311.68 118.24L311.64 118.26" stroke="black" stroke-width="0.5" />
    <path d="M311.64 118.26L311.61 118.29" stroke="black" stroke-width="0.5" />
    <path d="M311.61 118.29L311.57 118.32" stroke="black" stroke-width="0.5" />
    <path d="M311.57 118.32L311.53 118.36" stroke="black" stroke-width="0.5" />
    <path d="M311.53 118.36L311.48 118.41" stroke="black" stroke-width="0.5" />
    <path d="M311.48 118.41L311.43 118.46" stroke="black" stroke-width="0.5" />
    <path d="M311.43 118.46L311.38 118.52" stroke="black" stroke-width="0.5" />
    <path d="M311.38 118.52L311.34 118.58" stroke="black" stroke-width="0.5" />
    <path d="M311.34 118.58L311.29 118.64" stroke="black" stroke-width="0.5" />
    <path d="M311.29 118.64L311.25 118.71" stroke="black" stroke-width="0.5" />
    <path d="M311.25 118.71L311.22 118.78" stroke="black" stroke-width="0.5" />
    <path d="M311.22 118.78L311.19 118.86" stroke="black" stroke-width="0.5" />
    <path d="M311.19 118.86L311.17 118.93" stroke="black" stroke-width="0.5" />
    <path d="M311.17 118.93L311.16 119.01" stroke="black" stroke-width="0.5" />
    <path d="M311.16 119.01L311.15 119.09" stroke="black" stroke-width="0.5" />
    <path d="M311.15 119.09L311.14 119.16" stroke="black" stroke-width="0.5" />
    <path d="M311.14 119.16V119.24" stroke="black" stroke-width="0.5" />
    <path d="M311.14 119.24V119.31" stroke="black" stroke-width="0.5" />
    <path d="M311.14 119.31V119.39" stroke="black" stroke-width="0.5" />
    <path d="M311.14 119.39V119.45" stroke="black" stroke-width="0.5" />
    <path d="M311.14 119.45V119.52" stroke="black" stroke-width="0.5" />
    <path d="M311.14 119.52L311.15 119.58" stroke="black" stroke-width="0.5" />
    <path d="M311.15 119.58V119.64" stroke="black" stroke-width="0.5" />
    <path d="M311.15 119.64V119.7" stroke="black" stroke-width="0.5" />
    <path d="M311.15 119.7L311.16 119.75" stroke="black" stroke-width="0.5" />
    <path d="M311.16 119.75V119.8" stroke="black" stroke-width="0.5" />
    <path d="M311.16 119.8L311.17 119.85" stroke="black" stroke-width="0.5" />
    <path d="M311.17 119.85L311.18 119.89" stroke="black" stroke-width="0.5" />
    <path d="M311.18 119.89L311.19 119.93" stroke="black" stroke-width="0.5" />
    <path d="M311.19 119.93L311.2 119.96" stroke="black" stroke-width="0.5" />
    <path d="M311.2 119.96L311.22 120" stroke="black" stroke-width="0.5" />
    <path d="M311.22 120L311.24 120.03" stroke="black" stroke-width="0.5" />
    <path d="M311.24 120.03L311.27 120.05" stroke="black" stroke-width="0.5" />
    <path d="M311.27 120.05L311.3 120.08" stroke="black" stroke-width="0.5" />
    <path d="M311.3 120.08L311.35 120.1" stroke="black" stroke-width="0.5" />
    <path d="M311.35 120.1L311.4 120.12" stroke="black" stroke-width="0.5" />
    <path d="M311.4 120.12L311.46 120.14" stroke="black" stroke-width="0.5" />
    <path d="M311.46 120.14L311.53 120.15" stroke="black" stroke-width="0.5" />
    <path d="M311.53 120.15L311.62 120.16" stroke="black" stroke-width="0.5" />
    <path d="M311.62 120.16L311.72 120.17" stroke="black" stroke-width="0.5" />
    <path d="M311.72 120.17L311.83 120.18" stroke="black" stroke-width="0.5" />
    <path d="M311.83 120.18L311.96 120.19" stroke="black" stroke-width="0.5" />
    <path d="M311.96 120.19L312.1 120.2" stroke="black" stroke-width="0.5" />
    <path d="M312.1 120.2H312.26" stroke="black" stroke-width="0.5" />
    <path d="M312.26 120.2L312.43 120.21" stroke="black" stroke-width="0.5" />
    <path d="M312.43 120.21H312.62" stroke="black" stroke-width="0.5" />
    <path d="M312.62 120.21L312.82 120.22" stroke="black" stroke-width="0.5" />
    <path d="M312.82 120.22H313.02" stroke="black" stroke-width="0.5" />
    <path d="M313.02 120.22H313.23" stroke="black" stroke-width="0.5" />
    <path d="M313.23 120.22L313.45 120.23" stroke="black" stroke-width="0.5" />
    <path d="M313.45 120.23H313.67" stroke="black" stroke-width="0.5" />
    <path d="M313.67 120.23H313.89" stroke="black" stroke-width="0.5" />
    <path d="M313.89 120.23L314.1 120.22" stroke="black" stroke-width="0.5" />
    <path d="M314.1 120.22H314.32" stroke="black" stroke-width="0.5" />
    <path d="M314.32 120.22H314.52" stroke="black" stroke-width="0.5" />
    <path d="M314.52 120.22L314.72 120.21" stroke="black" stroke-width="0.5" />
    <path d="M314.72 120.21H314.91" stroke="black" stroke-width="0.5" />
    <path d="M314.91 120.21L315.08 120.2" stroke="black" stroke-width="0.5" />
    <path d="M315.08 120.2L315.24 120.19" stroke="black" stroke-width="0.5" />
    <path d="M315.24 120.19H315.38" stroke="black" stroke-width="0.5" />
    <path d="M315.38 120.19L315.51 120.18" stroke="black" stroke-width="0.5" />
    <path d="M315.51 120.18L315.62 120.17" stroke="black" stroke-width="0.5" />
    <path d="M315.62 120.17L315.72 120.16" stroke="black" stroke-width="0.5" />
    <path d="M315.72 120.16L315.8 120.14" stroke="black" stroke-width="0.5" />
    <path d="M315.8 120.14L315.88 120.13" stroke="black" stroke-width="0.5" />
    <path d="M315.88 120.13L315.94 120.11" stroke="black" stroke-width="0.5" />
    <path d="M315.94 120.11L315.99 120.09" stroke="black" stroke-width="0.5" />
    <path d="M315.99 120.09L316.03 120.07" stroke="black" stroke-width="0.5" />
    <path d="M316.03 120.07L316.07 120.05" stroke="black" stroke-width="0.5" />
    <path d="M316.07 120.05L316.09 120.02" stroke="black" stroke-width="0.5" />
    <path d="M316.09 120.02L316.12 119.99" stroke="black" stroke-width="0.5" />
    <path d="M316.12 119.99L316.13 119.96" stroke="black" stroke-width="0.5" />
    <path d="M316.13 119.96L316.15 119.92" stroke="black" stroke-width="0.5" />
    <path d="M316.15 119.92L316.16 119.88" stroke="black" stroke-width="0.5" />
    <path d="M316.16 119.88V119.84" stroke="black" stroke-width="0.5" />
    <path d="M316.16 119.84L316.17 119.8" stroke="black" stroke-width="0.5" />
    <path d="M316.17 119.8L316.18 119.75" stroke="black" stroke-width="0.5" />
    <path d="M316.18 119.75V119.69" stroke="black" stroke-width="0.5" />
    <path d="M316.18 119.69V119.64" stroke="black" stroke-width="0.5" />
    <path d="M316.18 119.64L316.19 119.58" stroke="black" stroke-width="0.5" />
    <path d="M316.19 119.58V119.51" stroke="black" stroke-width="0.5" />
    <path d="M316.19 119.51V119.45" stroke="black" stroke-width="0.5" />
    <path d="M316.19 119.45V119.38" stroke="black" stroke-width="0.5" />
    <path d="M316.19 119.38V119.31" stroke="black" stroke-width="0.5" />
    <path d="M316.19 119.31V119.24" stroke="black" stroke-width="0.5" />
    <path d="M316.19 119.24V119.16" stroke="black" stroke-width="0.5" />
    <path d="M316.19 119.16L316.18 119.09" stroke="black" stroke-width="0.5" />
    <path d="M316.18 119.09L316.17 119.01" stroke="black" stroke-width="0.5" />
    <path d="M316.17 119.01L316.16 118.94" stroke="black" stroke-width="0.5" />
    <path d="M316.16 118.94L316.14 118.86" stroke="black" stroke-width="0.5" />
    <path d="M316.14 118.86L316.11 118.79" stroke="black" stroke-width="0.5" />
    <path d="M316.11 118.79L316.08 118.72" stroke="black" stroke-width="0.5" />
    <path d="M316.08 118.72L316.04 118.65" stroke="black" stroke-width="0.5" />
    <path d="M316.04 118.65L315.99 118.58" stroke="black" stroke-width="0.5" />
    <path d="M315.99 118.58L315.94 118.52" stroke="black" stroke-width="0.5" />
    <path d="M315.94 118.52L315.88 118.46" stroke="black" stroke-width="0.5" />
    <path d="M315.88 118.46L315.82 118.4" stroke="black" stroke-width="0.5" />
    <path d="M315.82 118.4L315.76 118.35" stroke="black" stroke-width="0.5" />
    <path d="M315.76 118.35L315.69 118.3" stroke="black" stroke-width="0.5" />
    <path d="M315.69 118.3L315.62 118.26" stroke="black" stroke-width="0.5" />
    <path d="M315.62 118.26L315.56 118.22" stroke="black" stroke-width="0.5" />
    <path d="M315.56 118.22L315.48 118.18" stroke="black" stroke-width="0.5" />
    <path d="M315.48 118.18L315.41 118.15" stroke="black" stroke-width="0.5" />
    <path d="M315.41 118.15L315.34 118.12" stroke="black" stroke-width="0.5" />
    <path d="M315.34 118.12L315.27 118.1" stroke="black" stroke-width="0.5" />
    <path d="M315.27 118.1L315.19 118.07" stroke="black" stroke-width="0.5" />
    <path d="M315.19 118.07L315.12 118.05" stroke="black" stroke-width="0.5" />
    <path d="M315.12 118.05L315.04 118.03" stroke="black" stroke-width="0.5" />
    <path d="M315.04 118.03L314.96 118.01" stroke="black" stroke-width="0.5" />
    <path d="M314.96 118.01L314.88 117.99" stroke="black" stroke-width="0.5" />
    <path d="M314.88 117.99L314.8 117.97" stroke="black" stroke-width="0.5" />
    <path d="M314.8 117.97L314.72 117.95" stroke="black" stroke-width="0.5" />
    <path d="M314.72 117.95L314.64 117.94" stroke="black" stroke-width="0.5" />
    <path d="M314.64 117.94L314.55 117.92" stroke="black" stroke-width="0.5" />
    <path d="M314.55 117.92L314.46 117.91" stroke="black" stroke-width="0.5" />
    <path d="M314.46 117.91L314.37 117.9" stroke="black" stroke-width="0.5" />
    <path d="M314.37 117.9L314.27 117.89" stroke="black" stroke-width="0.5" />
    <path d="M314.27 117.89L314.18 117.88" stroke="black" stroke-width="0.5" />
    <path d="M314.18 117.88L314.08 117.87" stroke="black" stroke-width="0.5" />
    <path d="M314.08 117.87H313.97" stroke="black" stroke-width="0.5" />
    <path d="M313.97 117.87L313.87 117.86" stroke="black" stroke-width="0.5" />
    <path d="M313.87 117.86H313.77" stroke="black" stroke-width="0.5" />
    <path d="M313.77 117.86H313.66" stroke="black" stroke-width="0.5" />
    <path d="M313.66 117.86H313.56" stroke="black" stroke-width="0.5" />
    <path d="M313.56 117.86H313.46" stroke="black" stroke-width="0.5" />
    <path d="M313.46 117.86H313.35" stroke="black" stroke-width="0.5" />
    <path d="M313.35 117.86L313.25 117.87" stroke="black" stroke-width="0.5" />
    <path d="M313.25 117.87H313.16" stroke="black" stroke-width="0.5" />
    <path d="M313.16 117.87L313.06 117.88" stroke="black" stroke-width="0.5" />
    <path d="M313.06 117.88L312.97 117.89" stroke="black" stroke-width="0.5" />
    <path d="M312.97 117.89L312.88 117.9" stroke="black" stroke-width="0.5" />
    <path d="M312.88 117.9L312.8 117.91" stroke="black" stroke-width="0.5" />
    <path d="M312.8 117.91L312.73 117.92" stroke="black" stroke-width="0.5" />
    <path d="M312.73 117.92L312.65 117.93" stroke="black" stroke-width="0.5" />
    <path d="M312.65 117.93L312.58 117.95" stroke="black" stroke-width="0.5" />
    <path d="M312.58 117.95L312.51 117.96" stroke="black" stroke-width="0.5" />
    <path d="M312.51 117.96L312.45 117.98" stroke="black" stroke-width="0.5" />
    <path d="M312.45 117.98L312.38 118" stroke="black" stroke-width="0.5" />
    <path d="M312.38 118L312.32 118.01" stroke="black" stroke-width="0.5" />
    <path d="M312.32 118.01L312.25 118.03" stroke="black" stroke-width="0.5" />
    <path d="M312.25 118.03L312.19 118.05" stroke="black" stroke-width="0.5" />
    <path d="M312.19 118.05L312.12 118.07" stroke="black" stroke-width="0.5" />
    <path d="M312.12 118.07L312.06 118.09" stroke="black" stroke-width="0.5" />
    <path d="M312.06 118.09L312 118.11" stroke="black" stroke-width="0.5" />
    <path d="M312 118.11L311.95 118.13" stroke="black" stroke-width="0.5" />
    <path d="M311.95 118.13L311.89 118.15" stroke="black" stroke-width="0.5" />
    <path d="M311.89 118.15L311.85 118.16" stroke="black" stroke-width="0.5" />
    <path d="M301.22 121.63H296.09" stroke="black" stroke-width="0.5" />
    <path d="M296.09 121.63V131.44" stroke="black" stroke-width="0.5" />
    <path d="M296.09 151.68V161.56" stroke="black" stroke-width="0.5" />
    <path d="M294.76 115.32V131.44" stroke="black" stroke-width="0.5" />
    <path d="M294.76 151.68V160.23" stroke="black" stroke-width="0.5" />
    <path d="M301.22 120.29H294.76" stroke="black" stroke-width="0.5" />
    <path d="M296.09 161.56H294.14" stroke="black" stroke-width="0.5" />
    <path d="M294.76 160.23H292.81" stroke="black" stroke-width="0.5" />
    <path d="M294.14 161.56V163.7" stroke="black" stroke-width="0.5" />
    <path d="M292.81 160.23V163.7" stroke="black" stroke-width="0.5" />
    <path d="M294.76 131.44H296.09" stroke="black" stroke-width="0.5" />
    <path d="M294.76 151.68H296.09" stroke="black" stroke-width="0.5" />
    <path d="M294.76 131.44V131.97" stroke="black" stroke-width="0.5" />
    <path d="M294.76 131.97H296.09" stroke="black" stroke-width="0.5" />
    <path d="M296.09 131.97V131.44" stroke="black" stroke-width="0.5" />
    <path d="M294.76 151.68V151.15" stroke="black" stroke-width="0.5" />
    <path d="M294.76 151.15H296.09" stroke="black" stroke-width="0.5" />
    <path d="M296.09 151.15V151.68" stroke="black" stroke-width="0.5" />
    <path d="M296.09 151.15V141.54" stroke="black" stroke-width="0.5" />
    <path d="M296.09 141.54H295.56" stroke="black" stroke-width="0.5" />
    <path d="M295.56 141.54V151.15" stroke="black" stroke-width="0.5" />
    <path d="M295.29 146.22V136.61" stroke="black" stroke-width="0.5" />
    <path d="M295.29 136.61H294.76" stroke="black" stroke-width="0.5" />
    <path d="M294.76 136.61V146.22" stroke="black" stroke-width="0.5" />
    <path d="M295.29 146.22H294.76" stroke="black" stroke-width="0.5" />
    <path d="M258.37 161.56V160.23" stroke="black" stroke-width="0.5" />
    <path d="M258.37 160.23H256.22" stroke="black" stroke-width="0.5" />
    <path d="M256.22 160.23V124.95" stroke="black" stroke-width="0.5" />
    <path d="M262.63 155.08V134.33" stroke="black" stroke-width="0.5" />
    <path d="M254.89 161.56V126.29" stroke="black" stroke-width="0.5" />
    <path d="M256.22 124.95H224.66" stroke="black" stroke-width="0.5" />
    <path d="M254.89 126.29H226" stroke="black" stroke-width="0.5" />
    <path d="M226 161.56V153.38" stroke="black" stroke-width="0.5" />
    <path d="M226 144.24V126.29" stroke="black" stroke-width="0.5" />
    <path d="M224.66 161.56V153.85" stroke="black" stroke-width="0.5" />
    <path d="M224.66 144.24V124.95" stroke="black" stroke-width="0.5" />
    <path d="M235.6 153.85H226" stroke="black" stroke-width="0.5" />
    <path d="M226 153.38H235.59" stroke="black" stroke-width="0.5" />
    <path d="M226 153.85V153.38" stroke="black" stroke-width="0.5" />
    <path d="M226 144.24L227.87 144.42" stroke="black" stroke-width="0.5" />
    <path d="M227.87 144.42L229.67 144.97" stroke="black" stroke-width="0.5" />
    <path d="M229.67 144.97L231.33 145.86" stroke="black" stroke-width="0.5" />
    <path d="M231.33 145.86L232.79 147.05" stroke="black" stroke-width="0.5" />
    <path d="M232.79 147.05L233.98 148.51" stroke="black" stroke-width="0.5" />
    <path d="M233.98 148.51L234.87 150.17" stroke="black" stroke-width="0.5" />
    <path d="M234.87 150.17L235.42 151.97" stroke="black" stroke-width="0.5" />
    <path d="M235.42 151.97L235.6 153.85" stroke="black" stroke-width="0.5" />
    <path d="M226 153.85H224.66" stroke="black" stroke-width="0.5" />
    <path d="M226 154.38H224.66" stroke="black" stroke-width="0.5" />
    <path d="M226 144.24H224.66" stroke="black" stroke-width="0.5" />
    <path d="M226 143.71H224.66" stroke="black" stroke-width="0.5" />
    <path d="M224.66 130.92H226" stroke="black" stroke-width="0.5" />
    <path d="M224.66 130.39H226" stroke="black" stroke-width="0.5" />
    <path d="M225.33 130.92V143.71" stroke="black" stroke-width="0.5" />
    <path
      d="M256.22 124.95C259.658 124.922 263.045 124.108 266.12 122.57"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M256.24 127.09C259.98 127.057 263.663 126.175 267.011 124.51C270.36 122.846 273.287 120.442 275.57 117.48"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M262.63 134.33C270.29 132.747 277.126 128.463 281.89 122.26"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M256.26 129.22C260.348 129.186 264.374 128.218 268.03 126.39"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M266.12 122.57L268.03 126.39" stroke="black" stroke-width="0.5" />
    <path d="M275.57 117.48L281.89 122.26" stroke="black" stroke-width="0.5" />
    <path d="M262.63 155.08H312.11" stroke="black" stroke-width="0.5" />
    <path d="M312.11 155.08V128.15" stroke="black" stroke-width="0.5" />
    <path d="M312.11 128.15H296.09" stroke="black" stroke-width="0.5" />
    <path d="M256.22 150.11H262.63" stroke="black" stroke-width="0.5" />
    <path d="M259.42 150.11V158.48" stroke="black" stroke-width="0.5" />
    <path d="M259.42 158.48H278.79" stroke="black" stroke-width="0.5" />
    <path d="M278.79 161.56V158.48" stroke="black" stroke-width="0.5" />
    <path d="M256.22 142.59H262.63" stroke="black" stroke-width="0.5" />
    <path d="M259.42 142.59V132.06" stroke="black" stroke-width="0.5" />
    <path d="M259.42 132.06H256.22" stroke="black" stroke-width="0.5" />
    <path d="M227.55 161.56H211.67" stroke="black" stroke-width="0.5" />
    <path d="M211.67 161.56L211.64 172.47" stroke="black" stroke-width="0.5" />
    <path d="M90.38 161.56H106.29" stroke="black" stroke-width="0.5" />
    <path d="M106.29 161.56V172.48" stroke="black" stroke-width="0.5" />
    <path d="M106.29 172.48L110.09 174.51" stroke="black" stroke-width="0.5" />
    <path d="M110.09 174.51L108.71 177.52" stroke="black" stroke-width="0.5" />
    <path d="M109.94 174.83L120.7 179.58" stroke="black" stroke-width="0.5" />
    <path d="M132.59 182.92H139.69" stroke="black" stroke-width="0.5" />
    <path d="M132.59 183.99H137.55" stroke="black" stroke-width="0.5" />
    <path d="M139.69 182.92V185.93" stroke="black" stroke-width="0.5" />
    <path d="M139.69 185.93H178.25" stroke="black" stroke-width="0.5" />
    <path d="M139.69 184.86H178.25" stroke="black" stroke-width="0.5" />
    <path d="M119.56 182.2L120.85 179.24" stroke="black" stroke-width="0.5" />
    <path
      d="M120.85 179.24C124.708 180.636 128.625 181.864 132.59 182.92"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M98.59 165.74L106.29 172.48" stroke="black" stroke-width="0.5" />
    <path d="M106.29 166.08L98.59 168.71" stroke="black" stroke-width="0.5" />
    <path d="M139.69 185.93V191.27" stroke="black" stroke-width="0.5" />
    <path d="M140.75 184.86V185.93" stroke="black" stroke-width="0.5" />
    <path d="M137.55 183.99V188.87" stroke="black" stroke-width="0.5" />
    <path d="M139.15 182.92V191.54" stroke="black" stroke-width="0.5" />
    <path d="M139.69 191.27V192.07" stroke="black" stroke-width="0.5" />
    <path d="M139.69 192.07H136.48" stroke="black" stroke-width="0.5" />
    <path d="M139.15 191.54H137.02" stroke="black" stroke-width="0.5" />
    <path d="M136.48 192.07V191" stroke="black" stroke-width="0.5" />
    <path d="M137.02 191.54V190.47" stroke="black" stroke-width="0.5" />
    <path d="M136.48 191H126.88" stroke="black" stroke-width="0.5" />
    <path d="M137.55 188.87H129.01" stroke="black" stroke-width="0.5" />
    <path d="M137.02 190.47H127.41" stroke="black" stroke-width="0.5" />
    <path d="M126.88 191V184.6" stroke="black" stroke-width="0.5" />
    <path d="M129.01 188.87V182.87" stroke="black" stroke-width="0.5" />
    <path d="M127.41 190.47V184.2" stroke="black" stroke-width="0.5" />
    <path
      d="M96.46 170.39C105.8 176.678 116.063 181.472 126.88 184.6"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M96.99 170.11C100.818 172.673 104.806 174.987 108.93 177.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M119.77 181.71C122.285 182.635 124.833 183.465 127.41 184.2"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M98.59 168.71L104.51 172.77" stroke="black" stroke-width="0.5" />
    <path d="M104.51 172.77L109.6 175.57" stroke="black" stroke-width="0.5" />
    <path d="M104.43 170.84V172.72" stroke="black" stroke-width="0.5" />
    <path d="M109.4 176.01L120.19 180.75" stroke="black" stroke-width="0.5" />
    <path d="M119.99 179.27L119.47 180.44" stroke="black" stroke-width="0.5" />
    <path d="M110.57 175.11L110.05 176.3" stroke="black" stroke-width="0.5" />
    <path d="M120.43 180.2L132.59 183.99" stroke="black" stroke-width="0.5" />
    <path d="M221.48 165.74V170.39" stroke="black" stroke-width="0.5" />
    <path d="M220.94 165.74V170.11" stroke="black" stroke-width="0.5" />
    <path d="M219.34 165.74V168.71" stroke="black" stroke-width="0.5" />
    <path d="M213.51 163.7V170.84" stroke="black" stroke-width="0.5" />
    <path d="M211.64 161.56V170.61" stroke="black" stroke-width="0.5" />
    <path d="M211.64 161.56V172.48" stroke="black" stroke-width="0.5" />
    <path d="M211.64 172.48L207.84 174.51" stroke="black" stroke-width="0.5" />
    <path d="M207.84 174.51L209.22 177.52" stroke="black" stroke-width="0.5" />
    <path d="M207.99 174.83L197.23 179.58" stroke="black" stroke-width="0.5" />
    <path d="M185.34 182.92H178.25" stroke="black" stroke-width="0.5" />
    <path d="M185.34 183.99H180.38" stroke="black" stroke-width="0.5" />
    <path d="M178.25 182.92V185.93" stroke="black" stroke-width="0.5" />
    <path d="M177.18 184.86V185.93" stroke="black" stroke-width="0.5" />
    <path d="M198.38 182.2L197.08 179.24" stroke="black" stroke-width="0.5" />
    <path
      d="M185.34 182.92C189.305 181.864 193.222 180.636 197.08 179.24"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M178.25 185.93V191.27" stroke="black" stroke-width="0.5" />
    <path d="M180.38 183.99V188.87" stroke="black" stroke-width="0.5" />
    <path d="M178.78 182.92V191.54" stroke="black" stroke-width="0.5" />
    <path d="M178.25 191.27V192.07" stroke="black" stroke-width="0.5" />
    <path d="M178.25 192.07H181.45" stroke="black" stroke-width="0.5" />
    <path d="M178.78 191.54H180.92" stroke="black" stroke-width="0.5" />
    <path d="M181.45 192.07V191" stroke="black" stroke-width="0.5" />
    <path d="M180.92 191.54V190.47" stroke="black" stroke-width="0.5" />
    <path d="M181.45 191H191.06" stroke="black" stroke-width="0.5" />
    <path d="M180.38 188.87H188.92" stroke="black" stroke-width="0.5" />
    <path d="M180.92 190.47H190.52" stroke="black" stroke-width="0.5" />
    <path d="M191.06 191V184.6" stroke="black" stroke-width="0.5" />
    <path d="M188.92 188.87V182.87" stroke="black" stroke-width="0.5" />
    <path d="M190.52 190.47V184.2" stroke="black" stroke-width="0.5" />
    <path
      d="M191.06 184.6C201.877 181.472 212.14 176.678 221.48 170.39"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M209 177.04C213.124 174.987 217.112 172.673 220.94 170.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M190.52 184.2C193.097 183.465 195.645 182.635 198.16 181.71"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M219.34 168.71L213.43 172.77" stroke="black" stroke-width="0.5" />
    <path d="M213.43 172.77L208.33 175.57" stroke="black" stroke-width="0.5" />
    <path d="M213.51 170.84V172.72" stroke="black" stroke-width="0.5" />
    <path d="M208.53 176.01L197.74 180.75" stroke="black" stroke-width="0.5" />
    <path d="M197.94 179.27L198.46 180.44" stroke="black" stroke-width="0.5" />
    <path d="M207.36 175.11L207.89 176.3" stroke="black" stroke-width="0.5" />
    <path d="M197.5 180.2L185.34 183.99" stroke="black" stroke-width="0.5" />
    <path d="M131.41 186.78L131.24 187.37" stroke="black" stroke-width="0.5" />
    <path d="M131.24 187.37L130.77 187.78" stroke="black" stroke-width="0.5" />
    <path d="M130.77 187.78L130.16 187.87" stroke="black" stroke-width="0.5" />
    <path d="M130.16 187.87L129.6 187.61" stroke="black" stroke-width="0.5" />
    <path d="M129.6 187.61L129.27 187.09" stroke="black" stroke-width="0.5" />
    <path d="M129.27 187.09V186.47" stroke="black" stroke-width="0.5" />
    <path d="M129.27 186.47L129.6 185.96" stroke="black" stroke-width="0.5" />
    <path d="M129.6 185.96L130.16 185.7" stroke="black" stroke-width="0.5" />
    <path d="M130.16 185.7L130.77 185.79" stroke="black" stroke-width="0.5" />
    <path d="M130.77 185.79L131.24 186.19" stroke="black" stroke-width="0.5" />
    <path d="M131.24 186.19L131.41 186.78" stroke="black" stroke-width="0.5" />
    <path d="M134.04 186.78L133.87 187.37" stroke="black" stroke-width="0.5" />
    <path d="M133.87 187.37L133.4 187.78" stroke="black" stroke-width="0.5" />
    <path d="M133.4 187.78L132.79 187.87" stroke="black" stroke-width="0.5" />
    <path d="M132.79 187.87L132.23 187.61" stroke="black" stroke-width="0.5" />
    <path d="M132.23 187.61L131.9 187.09" stroke="black" stroke-width="0.5" />
    <path d="M131.9 187.09V186.47" stroke="black" stroke-width="0.5" />
    <path d="M131.9 186.47L132.23 185.96" stroke="black" stroke-width="0.5" />
    <path d="M132.23 185.96L132.79 185.7" stroke="black" stroke-width="0.5" />
    <path d="M132.79 185.7L133.4 185.79" stroke="black" stroke-width="0.5" />
    <path d="M133.4 185.79L133.87 186.19" stroke="black" stroke-width="0.5" />
    <path d="M133.87 186.19L134.04 186.78" stroke="black" stroke-width="0.5" />
    <path d="M188.71 186.78L188.54 187.37" stroke="black" stroke-width="0.5" />
    <path d="M188.54 187.37L188.07 187.78" stroke="black" stroke-width="0.5" />
    <path d="M188.07 187.78L187.46 187.87" stroke="black" stroke-width="0.5" />
    <path d="M187.46 187.87L186.9 187.61" stroke="black" stroke-width="0.5" />
    <path d="M186.9 187.61L186.56 187.09" stroke="black" stroke-width="0.5" />
    <path d="M186.56 187.09V186.47" stroke="black" stroke-width="0.5" />
    <path d="M186.56 186.47L186.9 185.96" stroke="black" stroke-width="0.5" />
    <path d="M186.9 185.96L187.46 185.7" stroke="black" stroke-width="0.5" />
    <path d="M187.46 185.7L188.07 185.79" stroke="black" stroke-width="0.5" />
    <path d="M188.07 185.79L188.54 186.19" stroke="black" stroke-width="0.5" />
    <path d="M188.54 186.19L188.71 186.78" stroke="black" stroke-width="0.5" />
    <path d="M186.08 186.78L185.91 187.37" stroke="black" stroke-width="0.5" />
    <path d="M185.91 187.37L185.44 187.78" stroke="black" stroke-width="0.5" />
    <path d="M185.44 187.78L184.83 187.87" stroke="black" stroke-width="0.5" />
    <path d="M184.83 187.87L184.27 187.61" stroke="black" stroke-width="0.5" />
    <path d="M184.27 187.61L183.93 187.09" stroke="black" stroke-width="0.5" />
    <path d="M183.93 187.09V186.47" stroke="black" stroke-width="0.5" />
    <path d="M183.93 186.47L184.27 185.96" stroke="black" stroke-width="0.5" />
    <path d="M184.27 185.96L184.83 185.7" stroke="black" stroke-width="0.5" />
    <path d="M184.83 185.7L185.44 185.79" stroke="black" stroke-width="0.5" />
    <path d="M185.44 185.79L185.91 186.19" stroke="black" stroke-width="0.5" />
    <path d="M185.91 186.19L186.08 186.78" stroke="black" stroke-width="0.5" />
    <path d="M192.63 180.81H194.35" stroke="black" stroke-width="0.5" />
    <path d="M194.35 180.81V183.03" stroke="black" stroke-width="0.5" />
    <path d="M194.35 183.03H192.63" stroke="black" stroke-width="0.5" />
    <path d="M192.63 183.03V180.81" stroke="black" stroke-width="0.5" />
    <path d="M125.3 180.81H123.58" stroke="black" stroke-width="0.5" />
    <path d="M123.58 180.81V183.03" stroke="black" stroke-width="0.5" />
    <path d="M123.58 183.03H125.3" stroke="black" stroke-width="0.5" />
    <path d="M125.3 183.03V180.81" stroke="black" stroke-width="0.5" />
    <path d="M148.58 184.86V185.93" stroke="black" stroke-width="0.5" />
    <path d="M149.44 184.86V185.93" stroke="black" stroke-width="0.5" />
    <path d="M169.35 184.86V185.93" stroke="black" stroke-width="0.5" />
    <path d="M168.5 184.86V185.93" stroke="black" stroke-width="0.5" />
    <path d="M178.25 185.93H139.69" stroke="black" stroke-width="0.5" />
    <path d="M178.25 182.92V176.09" stroke="black" stroke-width="0.5" />
    <path d="M178.25 166.49V139.94" stroke="black" stroke-width="0.5" />
    <path d="M176.65 143.97V139.87" stroke="black" stroke-width="0.5" />
    <path d="M179.58 182.92V175.63" stroke="black" stroke-width="0.5" />
    <path d="M179.58 166.49V153.61" stroke="black" stroke-width="0.5" />
    <path d="M189.19 176.09H179.58" stroke="black" stroke-width="0.5" />
    <path d="M179.58 175.63H189.18" stroke="black" stroke-width="0.5" />
    <path d="M179.58 176.09V175.63" stroke="black" stroke-width="0.5" />
    <path d="M179.58 166.49L181.46 166.67" stroke="black" stroke-width="0.5" />
    <path d="M181.46 166.67L183.26 167.22" stroke="black" stroke-width="0.5" />
    <path d="M183.26 167.22L184.92 168.11" stroke="black" stroke-width="0.5" />
    <path d="M184.92 168.11L186.37 169.3" stroke="black" stroke-width="0.5" />
    <path d="M186.37 169.3L187.57 170.76" stroke="black" stroke-width="0.5" />
    <path d="M187.57 170.76L188.46 172.42" stroke="black" stroke-width="0.5" />
    <path d="M188.46 172.42L189 174.22" stroke="black" stroke-width="0.5" />
    <path d="M189 174.22L189.19 176.09" stroke="black" stroke-width="0.5" />
    <path d="M179.58 176.09H178.25" stroke="black" stroke-width="0.5" />
    <path d="M179.58 176.63H178.25" stroke="black" stroke-width="0.5" />
    <path d="M179.58 166.49H178.25" stroke="black" stroke-width="0.5" />
    <path d="M179.58 165.95H178.25" stroke="black" stroke-width="0.5" />
    <path d="M178.25 153.61H179.58" stroke="black" stroke-width="0.5" />
    <path
      d="M193.89 126.96C190.096 126.615 186.321 127.788 183.39 130.22C180.459 132.653 178.61 136.148 178.25 139.94"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.04 125.36C194.013 125.36 193.987 125.36 193.96 125.36"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M191.21 125.36C187.466 125.684 183.955 127.315 181.292 129.968C178.63 132.621 176.987 136.127 176.65 139.87"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M176.65 142.73C176.65 142.757 176.65 142.783 176.65 142.81"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M191.35 128.56C188.295 128.856 185.448 130.24 183.328 132.459C181.208 134.678 179.956 137.585 179.8 140.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M193.89 126.96H214.93" stroke="black" stroke-width="0.5" />
    <path d="M191.21 125.36H203.16" stroke="black" stroke-width="0.5" />
    <path d="M191.35 128.56H203.16" stroke="black" stroke-width="0.5" />
    <path d="M214.93 126.96V135.2" stroke="black" stroke-width="0.5" />
    <path d="M214.93 135.2H191.73" stroke="black" stroke-width="0.5" />
    <path d="M179.58 160.05H186.43" stroke="black" stroke-width="0.5" />
    <path d="M186.43 160.05V140.4" stroke="black" stroke-width="0.5" />
    <path
      d="M191.73 135.2C190.42 135.419 189.209 136.036 188.261 136.966C187.313 137.896 186.674 139.095 186.43 140.4"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M176.65 143.97H179.8" stroke="black" stroke-width="0.5" />
    <path d="M179.8 140.65V143.97" stroke="black" stroke-width="0.5" />
    <path d="M203.16 125.36V128.56" stroke="black" stroke-width="0.5" />
    <path d="M32.36 72.91H41.97" stroke="black" stroke-width="0.5" />
    <path d="M41.97 73.37H32.37" stroke="black" stroke-width="0.5" />
    <path d="M41.97 72.91V73.37" stroke="black" stroke-width="0.5" />
    <path d="M41.97 82.51L40.09 82.33" stroke="black" stroke-width="0.5" />
    <path d="M40.09 82.33L38.29 81.78" stroke="black" stroke-width="0.5" />
    <path d="M38.29 81.78L36.63 80.89" stroke="black" stroke-width="0.5" />
    <path d="M36.63 80.89L35.17 79.7" stroke="black" stroke-width="0.5" />
    <path d="M35.17 79.7L33.98 78.24" stroke="black" stroke-width="0.5" />
    <path d="M33.98 78.24L33.09 76.58" stroke="black" stroke-width="0.5" />
    <path d="M33.09 76.58L32.54 74.78" stroke="black" stroke-width="0.5" />
    <path d="M32.54 74.78L32.36 72.91" stroke="black" stroke-width="0.5" />
    <path d="M40.9 92.64V100.64" stroke="black" stroke-width="0.5" />
    <path d="M40.51 100.64V92.65" stroke="black" stroke-width="0.5" />
    <path d="M40.9 100.64H40.51" stroke="black" stroke-width="0.5" />
    <path d="M32.89 100.64L33.05 99.08" stroke="black" stroke-width="0.5" />
    <path d="M33.05 99.08L33.5 97.58" stroke="black" stroke-width="0.5" />
    <path d="M33.5 97.58L34.24 96.2" stroke="black" stroke-width="0.5" />
    <path d="M34.24 96.2L35.24 94.98" stroke="black" stroke-width="0.5" />
    <path d="M35.24 94.98L36.45 93.99" stroke="black" stroke-width="0.5" />
    <path d="M36.45 93.99L37.83 93.25" stroke="black" stroke-width="0.5" />
    <path d="M37.83 93.25L39.34 92.79" stroke="black" stroke-width="0.5" />
    <path d="M39.34 92.79L40.9 92.64" stroke="black" stroke-width="0.5" />
    <path d="M77.96 110.06H68.35" stroke="black" stroke-width="0.5" />
    <path d="M68.35 110.53H77.95" stroke="black" stroke-width="0.5" />
    <path d="M68.35 110.06V110.53" stroke="black" stroke-width="0.5" />
    <path d="M68.35 119.67L70.22 119.48" stroke="black" stroke-width="0.5" />
    <path d="M70.22 119.48L72.03 118.94" stroke="black" stroke-width="0.5" />
    <path d="M72.03 118.94L73.69 118.05" stroke="black" stroke-width="0.5" />
    <path d="M73.69 118.05L75.14 116.85" stroke="black" stroke-width="0.5" />
    <path d="M75.14 116.85L76.34 115.4" stroke="black" stroke-width="0.5" />
    <path d="M76.34 115.4L77.23 113.74" stroke="black" stroke-width="0.5" />
    <path d="M77.23 113.74L77.77 111.94" stroke="black" stroke-width="0.5" />
    <path d="M77.77 111.94L77.96 110.06" stroke="black" stroke-width="0.5" />
    <path d="M28.76 109.95H38.37" stroke="black" stroke-width="0.5" />
    <path d="M38.37 110.41H28.78" stroke="black" stroke-width="0.5" />
    <path d="M38.37 109.95V110.41" stroke="black" stroke-width="0.5" />
    <path d="M38.37 119.55L36.5 119.37" stroke="black" stroke-width="0.5" />
    <path d="M36.5 119.37L34.7 118.82" stroke="black" stroke-width="0.5" />
    <path d="M34.7 118.82L33.03 117.93" stroke="black" stroke-width="0.5" />
    <path d="M33.03 117.93L31.58 116.74" stroke="black" stroke-width="0.5" />
    <path d="M31.58 116.74L30.38 115.28" stroke="black" stroke-width="0.5" />
    <path d="M30.38 115.28L29.5 113.62" stroke="black" stroke-width="0.5" />
    <path d="M29.5 113.62L28.95 111.82" stroke="black" stroke-width="0.5" />
    <path d="M28.95 111.82L28.76 109.95" stroke="black" stroke-width="0.5" />
    <path d="M65.84 133.94V124.34" stroke="black" stroke-width="0.5" />
    <path d="M65.37 124.34V133.93" stroke="black" stroke-width="0.5" />
    <path d="M65.84 124.34H65.37" stroke="black" stroke-width="0.5" />
    <path d="M56.23 124.34L56.41 126.21" stroke="black" stroke-width="0.5" />
    <path d="M56.41 126.21L56.96 128.01" stroke="black" stroke-width="0.5" />
    <path d="M56.96 128.01L57.85 129.68" stroke="black" stroke-width="0.5" />
    <path d="M57.85 129.68L59.04 131.13" stroke="black" stroke-width="0.5" />
    <path d="M59.04 131.13L60.5 132.33" stroke="black" stroke-width="0.5" />
    <path d="M60.5 132.33L62.16 133.21" stroke="black" stroke-width="0.5" />
    <path d="M62.16 133.21L63.96 133.76" stroke="black" stroke-width="0.5" />
    <path d="M63.96 133.76L65.84 133.94" stroke="black" stroke-width="0.5" />
    <path d="M39.71 148.91H55.82" stroke="black" stroke-width="0.5" />
    <path d="M55.82 148.91V147.58" stroke="black" stroke-width="0.5" />
    <path d="M55.82 147.58H39.71" stroke="black" stroke-width="0.5" />
    <path d="M39.71 145.42V146.53" stroke="black" stroke-width="0.5" />
    <path d="M39.71 146.53H39.88" stroke="black" stroke-width="0.5" />
    <path d="M39.88 146.53V145.42" stroke="black" stroke-width="0.5" />
    <path d="M39.88 145.42H39.71" stroke="black" stroke-width="0.5" />
    <path d="M39.71 136.93V135.82" stroke="black" stroke-width="0.5" />
    <path d="M39.71 135.82H39.88" stroke="black" stroke-width="0.5" />
    <path d="M39.88 135.82V136.93" stroke="black" stroke-width="0.5" />
    <path d="M39.88 136.93H39.71" stroke="black" stroke-width="0.5" />
    <path d="M53.56 147.58H54.67" stroke="black" stroke-width="0.5" />
    <path d="M54.67 147.58V147.41" stroke="black" stroke-width="0.5" />
    <path d="M54.67 147.41H53.56" stroke="black" stroke-width="0.5" />
    <path d="M53.56 147.41V147.58" stroke="black" stroke-width="0.5" />
    <path d="M43.46 147.58H42.35" stroke="black" stroke-width="0.5" />
    <path d="M42.35 147.58V147.41" stroke="black" stroke-width="0.5" />
    <path d="M42.35 147.41H43.46" stroke="black" stroke-width="0.5" />
    <path d="M43.46 147.41V147.58" stroke="black" stroke-width="0.5" />
    <path d="M40.3 141.35V145.74" stroke="black" stroke-width="0.5" />
    <path d="M40.3 145.74L40.29 145.77" stroke="black" stroke-width="0.5" />
    <path d="M40.29 145.77L40.26 145.8" stroke="black" stroke-width="0.5" />
    <path d="M40.26 145.8H40.23" stroke="black" stroke-width="0.5" />
    <path d="M40.23 145.8H39.88" stroke="black" stroke-width="0.5" />
    <path d="M39.88 145.8V146.15" stroke="black" stroke-width="0.5" />
    <path d="M39.88 146.15H40.3" stroke="black" stroke-width="0.5" />
    <path d="M40.3 146.15L40.47 146.11" stroke="black" stroke-width="0.5" />
    <path d="M40.47 146.11L40.6 145.98" stroke="black" stroke-width="0.5" />
    <path d="M40.6 145.98L40.64 145.8" stroke="black" stroke-width="0.5" />
    <path d="M40.64 145.8V141.35" stroke="black" stroke-width="0.5" />
    <path d="M40.64 141.35V136.55" stroke="black" stroke-width="0.5" />
    <path d="M40.64 136.55L40.6 136.37" stroke="black" stroke-width="0.5" />
    <path d="M40.6 136.37L40.47 136.24" stroke="black" stroke-width="0.5" />
    <path d="M40.47 136.24L40.3 136.2" stroke="black" stroke-width="0.5" />
    <path d="M40.3 136.2H39.88" stroke="black" stroke-width="0.5" />
    <path d="M39.88 136.2V136.55" stroke="black" stroke-width="0.5" />
    <path d="M39.88 136.55H40.23" stroke="black" stroke-width="0.5" />
    <path d="M40.23 136.55H40.26" stroke="black" stroke-width="0.5" />
    <path d="M40.26 136.55L40.29 136.58" stroke="black" stroke-width="0.5" />
    <path d="M40.29 136.58L40.3 136.61" stroke="black" stroke-width="0.5" />
    <path d="M40.3 136.61V141.35" stroke="black" stroke-width="0.5" />
    <path d="M53.87 146.99L53.91 147" stroke="black" stroke-width="0.5" />
    <path d="M53.91 147L53.93 147.02" stroke="black" stroke-width="0.5" />
    <path d="M53.93 147.02L53.94 147.06" stroke="black" stroke-width="0.5" />
    <path d="M53.94 147.06V147.41" stroke="black" stroke-width="0.5" />
    <path d="M53.94 147.41H54.29" stroke="black" stroke-width="0.5" />
    <path d="M54.29 147.41V146.99" stroke="black" stroke-width="0.5" />
    <path d="M54.29 146.99L54.24 146.82" stroke="black" stroke-width="0.5" />
    <path d="M54.24 146.82L54.12 146.69" stroke="black" stroke-width="0.5" />
    <path d="M54.12 146.69L53.94 146.64" stroke="black" stroke-width="0.5" />
    <path d="M53.94 146.64H43.08" stroke="black" stroke-width="0.5" />
    <path d="M43.08 146.64L42.91 146.69" stroke="black" stroke-width="0.5" />
    <path d="M42.91 146.69L42.78 146.82" stroke="black" stroke-width="0.5" />
    <path d="M42.78 146.82L42.73 146.99" stroke="black" stroke-width="0.5" />
    <path d="M42.73 146.99V147.41" stroke="black" stroke-width="0.5" />
    <path d="M42.73 147.41H43.08" stroke="black" stroke-width="0.5" />
    <path d="M43.08 147.41V147.06" stroke="black" stroke-width="0.5" />
    <path d="M43.08 147.06L43.09 147.02" stroke="black" stroke-width="0.5" />
    <path d="M43.09 147.02L43.12 147" stroke="black" stroke-width="0.5" />
    <path d="M43.12 147L43.15 146.99" stroke="black" stroke-width="0.5" />
    <path d="M43.15 146.99H53.87" stroke="black" stroke-width="0.5" />
    <path d="M42.26 141.45L42.82 141.38" stroke="black" stroke-width="0.5" />
    <path d="M42.82 141.38L43.23 141.32" stroke="black" stroke-width="0.5" />
    <path d="M43.23 141.32L43.59 141.26" stroke="black" stroke-width="0.5" />
    <path d="M43.59 141.26L43.9 141.2" stroke="black" stroke-width="0.5" />
    <path d="M43.9 141.2L44.14 141.14" stroke="black" stroke-width="0.5" />
    <path d="M44.14 141.14L44.32 141.09" stroke="black" stroke-width="0.5" />
    <path d="M44.32 141.09L44.45 141.06" stroke="black" stroke-width="0.5" />
    <path d="M44.45 141.06L44.55 141.04" stroke="black" stroke-width="0.5" />
    <path d="M44.55 141.04L44.66 141.02" stroke="black" stroke-width="0.5" />
    <path d="M44.66 141.02L44.79 141.01" stroke="black" stroke-width="0.5" />
    <path d="M44.79 141.01L45 141" stroke="black" stroke-width="0.5" />
    <path d="M45 141H45.23" stroke="black" stroke-width="0.5" />
    <path d="M45.23 141L45.5 141.02" stroke="black" stroke-width="0.5" />
    <path d="M45.5 141.02L45.8 141.05" stroke="black" stroke-width="0.5" />
    <path d="M45.8 141.05L46.11 141.12" stroke="black" stroke-width="0.5" />
    <path d="M46.11 141.12L46.42 141.22" stroke="black" stroke-width="0.5" />
    <path d="M46.42 141.22L46.73 141.35" stroke="black" stroke-width="0.5" />
    <path d="M46.73 141.35L47.01 141.5" stroke="black" stroke-width="0.5" />
    <path d="M47.01 141.5L47.26 141.69" stroke="black" stroke-width="0.5" />
    <path d="M47.26 141.69L47.48 141.89" stroke="black" stroke-width="0.5" />
    <path d="M47.48 141.89L47.66 142.11" stroke="black" stroke-width="0.5" />
    <path d="M47.66 142.11L47.79 142.34" stroke="black" stroke-width="0.5" />
    <path d="M47.79 142.34L47.87 142.56" stroke="black" stroke-width="0.5" />
    <path d="M47.87 142.56L47.89 142.78" stroke="black" stroke-width="0.5" />
    <path d="M47.89 142.78L47.86 143" stroke="black" stroke-width="0.5" />
    <path d="M47.86 143L47.79 143.22" stroke="black" stroke-width="0.5" />
    <path d="M47.79 143.22L47.66 143.44" stroke="black" stroke-width="0.5" />
    <path d="M47.66 143.44L47.48 143.66" stroke="black" stroke-width="0.5" />
    <path d="M47.48 143.66L47.26 143.87" stroke="black" stroke-width="0.5" />
    <path d="M47.26 143.87L47.01 144.05" stroke="black" stroke-width="0.5" />
    <path d="M47.01 144.05L46.73 144.21" stroke="black" stroke-width="0.5" />
    <path d="M46.73 144.21L46.43 144.34" stroke="black" stroke-width="0.5" />
    <path d="M46.43 144.34L46.11 144.43" stroke="black" stroke-width="0.5" />
    <path d="M46.11 144.43L45.8 144.5" stroke="black" stroke-width="0.5" />
    <path d="M45.8 144.5L45.5 144.54" stroke="black" stroke-width="0.5" />
    <path d="M45.5 144.54L45.23 144.56" stroke="black" stroke-width="0.5" />
    <path d="M45.23 144.56H44.99" stroke="black" stroke-width="0.5" />
    <path d="M44.99 144.56L44.79 144.55" stroke="black" stroke-width="0.5" />
    <path d="M44.79 144.55L44.66 144.54" stroke="black" stroke-width="0.5" />
    <path d="M44.66 144.54L44.56 144.52" stroke="black" stroke-width="0.5" />
    <path d="M44.56 144.52L44.45 144.5" stroke="black" stroke-width="0.5" />
    <path d="M44.45 144.5L44.32 144.46" stroke="black" stroke-width="0.5" />
    <path d="M44.32 144.46L44.13 144.42" stroke="black" stroke-width="0.5" />
    <path d="M44.13 144.42L43.89 144.36" stroke="black" stroke-width="0.5" />
    <path d="M43.89 144.36L43.59 144.3" stroke="black" stroke-width="0.5" />
    <path d="M43.59 144.3L43.23 144.24" stroke="black" stroke-width="0.5" />
    <path d="M43.23 144.24L42.82 144.18" stroke="black" stroke-width="0.5" />
    <path d="M42.82 144.18L42.38 144.13" stroke="black" stroke-width="0.5" />
    <path d="M42.38 144.13H42.26" stroke="black" stroke-width="0.5" />
    <path d="M42.11 144.6L42.1 144.64" stroke="black" stroke-width="0.5" />
    <path d="M42.1 144.64L42.09 144.68" stroke="black" stroke-width="0.5" />
    <path d="M42.09 144.68L42.07 144.71" stroke="black" stroke-width="0.5" />
    <path d="M42.07 144.71L42.06 144.74" stroke="black" stroke-width="0.5" />
    <path d="M42.06 144.74L42.04 144.77" stroke="black" stroke-width="0.5" />
    <path d="M42.04 144.77L42.02 144.8" stroke="black" stroke-width="0.5" />
    <path d="M42.02 144.8L41.99 144.84" stroke="black" stroke-width="0.5" />
    <path d="M41.99 144.84L41.96 144.88" stroke="black" stroke-width="0.5" />
    <path d="M41.96 144.88L41.92 144.92" stroke="black" stroke-width="0.5" />
    <path d="M41.92 144.92L41.87 144.97" stroke="black" stroke-width="0.5" />
    <path d="M41.87 144.97L41.82 145.01" stroke="black" stroke-width="0.5" />
    <path d="M41.82 145.01L41.76 145.06" stroke="black" stroke-width="0.5" />
    <path d="M41.76 145.06L41.7 145.11" stroke="black" stroke-width="0.5" />
    <path d="M41.7 145.11L41.64 145.15" stroke="black" stroke-width="0.5" />
    <path d="M41.64 145.15L41.57 145.2" stroke="black" stroke-width="0.5" />
    <path d="M41.57 145.2L41.49 145.23" stroke="black" stroke-width="0.5" />
    <path d="M41.49 145.23L41.42 145.26" stroke="black" stroke-width="0.5" />
    <path d="M41.42 145.26L41.34 145.28" stroke="black" stroke-width="0.5" />
    <path d="M41.34 145.28L41.27 145.29" stroke="black" stroke-width="0.5" />
    <path d="M41.27 145.29L41.19 145.3" stroke="black" stroke-width="0.5" />
    <path d="M41.19 145.3L41.11 145.31" stroke="black" stroke-width="0.5" />
    <path d="M41.11 145.31H41.04" stroke="black" stroke-width="0.5" />
    <path d="M41.04 145.31H40.96" stroke="black" stroke-width="0.5" />
    <path d="M40.96 145.31H40.89" stroke="black" stroke-width="0.5" />
    <path d="M40.89 145.31H40.82" stroke="black" stroke-width="0.5" />
    <path d="M40.82 145.31L40.76 145.3" stroke="black" stroke-width="0.5" />
    <path d="M40.76 145.3H40.7" stroke="black" stroke-width="0.5" />
    <path d="M40.7 145.3H40.64" stroke="black" stroke-width="0.5" />
    <path d="M40.64 145.3L40.58 145.29" stroke="black" stroke-width="0.5" />
    <path d="M40.58 145.29H40.53" stroke="black" stroke-width="0.5" />
    <path d="M40.53 145.29L40.48 145.28" stroke="black" stroke-width="0.5" />
    <path d="M40.48 145.28H40.43" stroke="black" stroke-width="0.5" />
    <path d="M40.43 145.28L40.39 145.27" stroke="black" stroke-width="0.5" />
    <path d="M40.39 145.27L40.35 145.26" stroke="black" stroke-width="0.5" />
    <path d="M40.35 145.26L40.31 145.25" stroke="black" stroke-width="0.5" />
    <path d="M40.31 145.25L40.28 145.23" stroke="black" stroke-width="0.5" />
    <path d="M40.28 145.23L40.25 145.21" stroke="black" stroke-width="0.5" />
    <path d="M40.25 145.21L40.22 145.18" stroke="black" stroke-width="0.5" />
    <path d="M40.22 145.18L40.2 145.14" stroke="black" stroke-width="0.5" />
    <path d="M40.2 145.14L40.18 145.1" stroke="black" stroke-width="0.5" />
    <path d="M40.18 145.1L40.16 145.05" stroke="black" stroke-width="0.5" />
    <path d="M40.16 145.05L40.14 144.99" stroke="black" stroke-width="0.5" />
    <path d="M40.14 144.99L40.13 144.91" stroke="black" stroke-width="0.5" />
    <path d="M40.13 144.91L40.12 144.83" stroke="black" stroke-width="0.5" />
    <path d="M40.12 144.83L40.11 144.73" stroke="black" stroke-width="0.5" />
    <path d="M40.11 144.73L40.1 144.62" stroke="black" stroke-width="0.5" />
    <path d="M40.1 144.62L40.09 144.49" stroke="black" stroke-width="0.5" />
    <path d="M40.09 144.49L40.08 144.35" stroke="black" stroke-width="0.5" />
    <path d="M40.08 144.35V144.19" stroke="black" stroke-width="0.5" />
    <path d="M40.08 144.19L40.07 144.01" stroke="black" stroke-width="0.5" />
    <path d="M40.07 144.01L40.06 143.83" stroke="black" stroke-width="0.5" />
    <path d="M40.06 143.83V143.63" stroke="black" stroke-width="0.5" />
    <path d="M40.06 143.63V143.42" stroke="black" stroke-width="0.5" />
    <path d="M40.06 143.42L40.05 143.21" stroke="black" stroke-width="0.5" />
    <path d="M40.05 143.21V143" stroke="black" stroke-width="0.5" />
    <path d="M40.05 143V142.78" stroke="black" stroke-width="0.5" />
    <path d="M40.05 142.78V142.56" stroke="black" stroke-width="0.5" />
    <path d="M40.05 142.56V142.34" stroke="black" stroke-width="0.5" />
    <path d="M40.05 142.34L40.06 142.13" stroke="black" stroke-width="0.5" />
    <path d="M40.06 142.13V141.92" stroke="black" stroke-width="0.5" />
    <path d="M40.06 141.92V141.73" stroke="black" stroke-width="0.5" />
    <path d="M40.06 141.73L40.07 141.54" stroke="black" stroke-width="0.5" />
    <path d="M40.07 141.54L40.08 141.37" stroke="black" stroke-width="0.5" />
    <path d="M40.08 141.37V141.21" stroke="black" stroke-width="0.5" />
    <path d="M40.08 141.21L40.09 141.06" stroke="black" stroke-width="0.5" />
    <path d="M40.09 141.06L40.1 140.94" stroke="black" stroke-width="0.5" />
    <path d="M40.1 140.94L40.11 140.83" stroke="black" stroke-width="0.5" />
    <path d="M40.11 140.83L40.12 140.73" stroke="black" stroke-width="0.5" />
    <path d="M40.12 140.73L40.13 140.64" stroke="black" stroke-width="0.5" />
    <path d="M40.13 140.64L40.15 140.57" stroke="black" stroke-width="0.5" />
    <path d="M40.15 140.57L40.17 140.51" stroke="black" stroke-width="0.5" />
    <path d="M40.17 140.51L40.19 140.46" stroke="black" stroke-width="0.5" />
    <path d="M40.19 140.46L40.21 140.41" stroke="black" stroke-width="0.5" />
    <path d="M40.21 140.41L40.23 140.38" stroke="black" stroke-width="0.5" />
    <path d="M40.23 140.38L40.26 140.35" stroke="black" stroke-width="0.5" />
    <path d="M40.26 140.35L40.29 140.33" stroke="black" stroke-width="0.5" />
    <path d="M40.29 140.33L40.32 140.31" stroke="black" stroke-width="0.5" />
    <path d="M40.32 140.31L40.35 140.3" stroke="black" stroke-width="0.5" />
    <path d="M40.35 140.3L40.39 140.29" stroke="black" stroke-width="0.5" />
    <path d="M40.39 140.29L40.44 140.28" stroke="black" stroke-width="0.5" />
    <path d="M40.44 140.28H40.48" stroke="black" stroke-width="0.5" />
    <path d="M40.48 140.28L40.53 140.27" stroke="black" stroke-width="0.5" />
    <path d="M40.53 140.27H40.59" stroke="black" stroke-width="0.5" />
    <path d="M40.59 140.27L40.64 140.26" stroke="black" stroke-width="0.5" />
    <path d="M40.64 140.26H40.7" stroke="black" stroke-width="0.5" />
    <path d="M40.7 140.26H40.76" stroke="black" stroke-width="0.5" />
    <path d="M40.76 140.26L40.83 140.25" stroke="black" stroke-width="0.5" />
    <path d="M40.83 140.25H40.9" stroke="black" stroke-width="0.5" />
    <path d="M40.9 140.25H40.97" stroke="black" stroke-width="0.5" />
    <path d="M40.97 140.25H41.04" stroke="black" stroke-width="0.5" />
    <path d="M41.04 140.25L41.11 140.26" stroke="black" stroke-width="0.5" />
    <path d="M41.11 140.26H41.19" stroke="black" stroke-width="0.5" />
    <path d="M41.19 140.26L41.26 140.27" stroke="black" stroke-width="0.5" />
    <path d="M41.26 140.27L41.34 140.29" stroke="black" stroke-width="0.5" />
    <path d="M41.34 140.29L41.41 140.31" stroke="black" stroke-width="0.5" />
    <path d="M41.41 140.31L41.49 140.34" stroke="black" stroke-width="0.5" />
    <path d="M41.49 140.34L41.56 140.37" stroke="black" stroke-width="0.5" />
    <path d="M41.56 140.37L41.63 140.41" stroke="black" stroke-width="0.5" />
    <path d="M41.63 140.41L41.69 140.46" stroke="black" stroke-width="0.5" />
    <path d="M41.69 140.46L41.76 140.51" stroke="black" stroke-width="0.5" />
    <path d="M41.76 140.51L41.82 140.57" stroke="black" stroke-width="0.5" />
    <path d="M41.82 140.57L41.88 140.63" stroke="black" stroke-width="0.5" />
    <path d="M41.88 140.63L41.93 140.69" stroke="black" stroke-width="0.5" />
    <path d="M41.93 140.69L41.98 140.75" stroke="black" stroke-width="0.5" />
    <path d="M41.98 140.75L42.02 140.82" stroke="black" stroke-width="0.5" />
    <path d="M42.02 140.82L42.06 140.89" stroke="black" stroke-width="0.5" />
    <path d="M42.06 140.89L42.09 140.96" stroke="black" stroke-width="0.5" />
    <path d="M42.09 140.96L42.13 141.03" stroke="black" stroke-width="0.5" />
    <path d="M42.13 141.03L42.15 141.11" stroke="black" stroke-width="0.5" />
    <path d="M42.15 141.11L42.18 141.18" stroke="black" stroke-width="0.5" />
    <path d="M42.18 141.18L42.2 141.25" stroke="black" stroke-width="0.5" />
    <path d="M42.2 141.25L42.23 141.33" stroke="black" stroke-width="0.5" />
    <path d="M42.23 141.33L42.25 141.41" stroke="black" stroke-width="0.5" />
    <path d="M42.25 141.41L42.27 141.48" stroke="black" stroke-width="0.5" />
    <path d="M42.27 141.48L42.29 141.56" stroke="black" stroke-width="0.5" />
    <path d="M42.29 141.56L42.31 141.64" stroke="black" stroke-width="0.5" />
    <path d="M42.31 141.64L42.32 141.72" stroke="black" stroke-width="0.5" />
    <path d="M42.32 141.72L42.34 141.81" stroke="black" stroke-width="0.5" />
    <path d="M42.34 141.81L42.35 141.9" stroke="black" stroke-width="0.5" />
    <path d="M42.35 141.9L42.37 141.98" stroke="black" stroke-width="0.5" />
    <path d="M42.37 141.98L42.38 142.08" stroke="black" stroke-width="0.5" />
    <path d="M42.38 142.08L42.39 142.17" stroke="black" stroke-width="0.5" />
    <path d="M42.39 142.17L42.4 142.27" stroke="black" stroke-width="0.5" />
    <path d="M42.4 142.27L42.41 142.37" stroke="black" stroke-width="0.5" />
    <path d="M42.41 142.37V142.47" stroke="black" stroke-width="0.5" />
    <path d="M42.41 142.47L42.42 142.58" stroke="black" stroke-width="0.5" />
    <path d="M42.42 142.58V142.68" stroke="black" stroke-width="0.5" />
    <path d="M42.42 142.68V142.78" stroke="black" stroke-width="0.5" />
    <path d="M42.42 142.78V142.89" stroke="black" stroke-width="0.5" />
    <path d="M42.42 142.89V142.99" stroke="black" stroke-width="0.5" />
    <path d="M42.42 142.99V143.09" stroke="black" stroke-width="0.5" />
    <path d="M42.42 143.09L42.41 143.19" stroke="black" stroke-width="0.5" />
    <path d="M42.41 143.19L42.4 143.29" stroke="black" stroke-width="0.5" />
    <path d="M42.4 143.29V143.38" stroke="black" stroke-width="0.5" />
    <path d="M42.4 143.38L42.39 143.48" stroke="black" stroke-width="0.5" />
    <path d="M42.39 143.48L42.38 143.56" stroke="black" stroke-width="0.5" />
    <path d="M42.38 143.56L42.37 143.64" stroke="black" stroke-width="0.5" />
    <path d="M42.37 143.64L42.36 143.72" stroke="black" stroke-width="0.5" />
    <path d="M42.36 143.72L42.34 143.79" stroke="black" stroke-width="0.5" />
    <path d="M42.34 143.79L42.33 143.86" stroke="black" stroke-width="0.5" />
    <path d="M42.33 143.86L42.31 143.93" stroke="black" stroke-width="0.5" />
    <path d="M42.31 143.93L42.3 144" stroke="black" stroke-width="0.5" />
    <path d="M42.3 144L42.28 144.06" stroke="black" stroke-width="0.5" />
    <path d="M42.28 144.06L42.26 144.13" stroke="black" stroke-width="0.5" />
    <path d="M42.26 144.13L42.24 144.19" stroke="black" stroke-width="0.5" />
    <path d="M42.24 144.19L42.22 144.26" stroke="black" stroke-width="0.5" />
    <path d="M42.22 144.26L42.2 144.32" stroke="black" stroke-width="0.5" />
    <path d="M42.2 144.32L42.18 144.39" stroke="black" stroke-width="0.5" />
    <path d="M42.18 144.39L42.17 144.45" stroke="black" stroke-width="0.5" />
    <path d="M42.17 144.45L42.15 144.5" stroke="black" stroke-width="0.5" />
    <path d="M42.15 144.5L42.13 144.55" stroke="black" stroke-width="0.5" />
    <path d="M42.13 144.55L42.11 144.6" stroke="black" stroke-width="0.5" />
    <path d="M224.66 155.06H208.99" stroke="black" stroke-width="0.5" />
    <path d="M224.66 158.26H212.21" stroke="black" stroke-width="0.5" />
    <path
      d="M208.99 155.06C208.029 155.059 207.106 155.44 206.426 156.119C205.745 156.797 205.362 157.719 205.36 158.68"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M205.36 175.99V158.68" stroke="black" stroke-width="0.5" />
    <path d="M208.57 174.12V160.56" stroke="black" stroke-width="0.5" />
    <path d="M208.57 160.56L209.46 159.52" stroke="black" stroke-width="0.5" />
    <path
      d="M212.21 158.26C211.688 158.26 211.172 158.372 210.697 158.59C210.223 158.807 209.801 159.124 209.46 159.52"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M117.11 108.92L116.92 110.79" stroke="black" stroke-width="0.5" />
    <path d="M116.92 110.79L116.38 112.59" stroke="black" stroke-width="0.5" />
    <path d="M116.38 112.59L115.49 114.25" stroke="black" stroke-width="0.5" />
    <path d="M115.49 114.25L114.29 115.71" stroke="black" stroke-width="0.5" />
    <path d="M114.29 115.71L112.84 116.9" stroke="black" stroke-width="0.5" />
    <path d="M112.84 116.9L111.18 117.79" stroke="black" stroke-width="0.5" />
    <path d="M111.18 117.79L109.37 118.34" stroke="black" stroke-width="0.5" />
    <path d="M109.37 118.34L107.5 118.52" stroke="black" stroke-width="0.5" />
    <path d="M107.5 108.92H107.97" stroke="black" stroke-width="0.5" />
    <path d="M107.97 108.92V118.51" stroke="black" stroke-width="0.5" />
    <path d="M107.5 118.52V108.92" stroke="black" stroke-width="0.5" />
    <path d="M39.71 148.91H49.45" stroke="black" stroke-width="0.5" />
    <path d="M49.45 148.91V158.38" stroke="black" stroke-width="0.5" />
    <path d="M49.45 158.38H39.71" stroke="black" stroke-width="0.5" />
    <path d="M39.71 158.38V148.91" stroke="black" stroke-width="0.5" />
    <path d="M48.92 151.25V157.47" stroke="black" stroke-width="0.5" />
    <path d="M48.92 157.47L48.87 157.66" stroke="black" stroke-width="0.5" />
    <path d="M48.87 157.66L48.74 157.79" stroke="black" stroke-width="0.5" />
    <path d="M48.74 157.79L48.55 157.84" stroke="black" stroke-width="0.5" />
    <path d="M48.55 157.84H40.61" stroke="black" stroke-width="0.5" />
    <path d="M40.61 157.84L40.42 157.79" stroke="black" stroke-width="0.5" />
    <path d="M40.42 157.79L40.29 157.66" stroke="black" stroke-width="0.5" />
    <path d="M40.29 157.66L40.24 157.47" stroke="black" stroke-width="0.5" />
    <path d="M40.24 157.47V149.82" stroke="black" stroke-width="0.5" />
    <path d="M40.24 149.82L40.29 149.63" stroke="black" stroke-width="0.5" />
    <path d="M40.29 149.63L40.42 149.5" stroke="black" stroke-width="0.5" />
    <path d="M40.42 149.5L40.61 149.45" stroke="black" stroke-width="0.5" />
    <path d="M40.61 149.45H44.21" stroke="black" stroke-width="0.5" />
    <path d="M44.95 149.45H48.55" stroke="black" stroke-width="0.5" />
    <path d="M48.92 151.25L47.86 151.74" stroke="black" stroke-width="0.5" />
    <path d="M48.92 151.25L49.98 151.74" stroke="black" stroke-width="0.5" />
    <path d="M40.51 152.46V149.46" stroke="black" stroke-width="0.5" />
    <path d="M40.51 152.46H44.12" stroke="black" stroke-width="0.5" />
    <path d="M48.92 154.36H45.15" stroke="black" stroke-width="0.5" />
    <path d="M44.53 152.92L44.12 152.46" stroke="black" stroke-width="0.5" />
    <path d="M45.5 150.26H43.66" stroke="black" stroke-width="0.5" />
    <path d="M43.66 150.26L44.58 148.91" stroke="black" stroke-width="0.5" />
    <path d="M45.5 150.26L44.58 148.91" stroke="black" stroke-width="0.5" />
    <path d="M48.55 149.45L49.06 149.28" stroke="black" stroke-width="0.5" />
    <path d="M49.06 149.28L49.45 148.91" stroke="black" stroke-width="0.5" />
    <path d="M48.92 153.91L48.79 154.23" stroke="black" stroke-width="0.5" />
    <path d="M48.79 154.23L48.47 154.36" stroke="black" stroke-width="0.5" />
    <path d="M48.47 154.36L48.14 154.23" stroke="black" stroke-width="0.5" />
    <path d="M48.14 154.23L48.01 153.91" stroke="black" stroke-width="0.5" />
    <path d="M48.01 153.91L48.14 153.58" stroke="black" stroke-width="0.5" />
    <path d="M48.14 153.58L48.47 153.45" stroke="black" stroke-width="0.5" />
    <path d="M48.47 153.45L48.79 153.58" stroke="black" stroke-width="0.5" />
    <path d="M48.79 153.58L48.92 153.91" stroke="black" stroke-width="0.5" />
    <path d="M48.92 154.82L48.79 155.14" stroke="black" stroke-width="0.5" />
    <path d="M48.79 155.14L48.47 155.27" stroke="black" stroke-width="0.5" />
    <path d="M48.47 155.27L48.14 155.14" stroke="black" stroke-width="0.5" />
    <path d="M48.14 155.14L48.01 154.82" stroke="black" stroke-width="0.5" />
    <path d="M48.01 154.82L48.14 154.49" stroke="black" stroke-width="0.5" />
    <path d="M48.14 154.49L48.47 154.36" stroke="black" stroke-width="0.5" />
    <path d="M48.47 154.36L48.79 154.49" stroke="black" stroke-width="0.5" />
    <path d="M48.79 154.49L48.92 154.82" stroke="black" stroke-width="0.5" />
    <path d="M48.92 155.73L48.79 156.05" stroke="black" stroke-width="0.5" />
    <path d="M48.79 156.05L48.47 156.18" stroke="black" stroke-width="0.5" />
    <path d="M48.47 156.18L48.14 156.05" stroke="black" stroke-width="0.5" />
    <path d="M48.14 156.05L48.01 155.73" stroke="black" stroke-width="0.5" />
    <path d="M48.01 155.73L48.14 155.41" stroke="black" stroke-width="0.5" />
    <path d="M48.14 155.41L48.47 155.27" stroke="black" stroke-width="0.5" />
    <path d="M48.47 155.27L48.79 155.41" stroke="black" stroke-width="0.5" />
    <path d="M48.79 155.41L48.92 155.73" stroke="black" stroke-width="0.5" />
    <path d="M49.45 158.38V161.56" stroke="black" stroke-width="0.5" />
    <path d="M45.15 154.36L44.68 154.23" stroke="black" stroke-width="0.5" />
    <path d="M44.68 154.23L44.36 153.85" stroke="black" stroke-width="0.5" />
    <path d="M44.36 153.85L44.3 153.36" stroke="black" stroke-width="0.5" />
    <path d="M44.3 153.36L44.53 152.92" stroke="black" stroke-width="0.5" />
    <path d="M45.15 154.36L45.28 153.77" stroke="black" stroke-width="0.5" />
    <path d="M45.28 153.77L45.04 153.22" stroke="black" stroke-width="0.5" />
    <path d="M45.04 153.22L44.53 152.92" stroke="black" stroke-width="0.5" />
    <path d="M46.34 124.34H49.2" stroke="black" stroke-width="0.5" />
    <path d="M49.2 124.34V125.35" stroke="black" stroke-width="0.5" />
    <path d="M49.2 125.35H46.34" stroke="black" stroke-width="0.5" />
    <path d="M46.34 125.35V124.34" stroke="black" stroke-width="0.5" />
    <path d="M44.18 93.34V98.29" stroke="black" stroke-width="0.5" />
    <path d="M44.18 98.29H43.3" stroke="black" stroke-width="0.5" />
    <path d="M43.3 98.29V93.34" stroke="black" stroke-width="0.5" />
    <path d="M43.3 93.34H44.18" stroke="black" stroke-width="0.5" />
    <path d="M43.3 93.77V97.74" stroke="black" stroke-width="0.5" />
    <path d="M43.3 97.74H42.53" stroke="black" stroke-width="0.5" />
    <path d="M42.53 97.74V93.77" stroke="black" stroke-width="0.5" />
    <path d="M42.53 93.77H43.3" stroke="black" stroke-width="0.5" />
    <path d="M214.91 66.39V64.79H215.64V66.39H214.91Z" fill="black" />
    <path d="M214.91 63.96V62.29H215.64V63.96H214.91Z" fill="black" />
    <path d="M214.91 61.45V59.79H215.64V61.45H214.91Z" fill="black" />
    <path d="M214.91 58.95V57.28H215.64V58.95H214.91Z" fill="black" />
    <path d="M214.91 56.45V54.78H215.64V56.45H214.91Z" fill="black" />
    <path d="M214.91 53.95V52.28H215.64V53.95H214.91Z" fill="black" />
    <path d="M214.91 51.45V49.78H215.64V51.45H214.91Z" fill="black" />
    <path d="M214.91 48.95V47.28H215.64V48.95H214.91Z" fill="black" />
    <path d="M214.91 46.44V44.78H215.64V46.44H214.91Z" fill="black" />
    <path d="M214.91 43.94V42.27H215.64V43.94H214.91Z" fill="black" />
    <path d="M214.91 41.44V39.77H215.64V41.44H214.91Z" fill="black" />
    <path d="M214.91 38.94V37.27H215.64V38.94H214.91Z" fill="black" />
    <path d="M214.91 36.44V34.77H215.64V36.44H214.91Z" fill="black" />
    <path d="M214.91 33.93V32.27H215.64V33.93H214.91Z" fill="black" />
    <path d="M214.91 31.43V29.76H215.64V31.43H214.91Z" fill="black" />
    <path d="M214.91 28.93V27.26H215.64V28.93H214.91Z" fill="black" />
    <path d="M214.91 26.43V24.76H215.64V26.43H214.91Z" fill="black" />
    <path d="M214.91 23.93V22.26H215.64V23.93H214.91Z" fill="black" />
    <path d="M214.91 21.43V19.76H215.64V21.43H214.91Z" fill="black" />
    <path d="M214.91 18.92V17.26H215.64V18.92H214.91Z" fill="black" />
    <path d="M214.91 16.42V14.75H215.64V16.42H214.91Z" fill="black" />
    <path d="M214.91 13.92V12.25H215.64V13.92H214.91Z" fill="black" />
    <path d="M214.91 11.42V9.82001H215.64V11.42H214.91Z" fill="black" />
    <path d="M215.27 10.18H213.62V9.45H215.27V10.18Z" fill="black" />
    <path d="M180.44 118.12V113.85" stroke="black" stroke-width="0.5" />
    <path d="M181.78 118.12V113.85" stroke="black" stroke-width="0.5" />
    <path d="M181.78 113.85H180.44" stroke="black" stroke-width="0.5" />
    <path d="M181.78 114.38H180.44" stroke="black" stroke-width="0.5" />
    <path d="M181.78 117.58H180.44" stroke="black" stroke-width="0.5" />
    <path d="M181.78 118.12H180.44" stroke="black" stroke-width="0.5" />
    <path d="M181.11 117.58V114.38" stroke="black" stroke-width="0.5" />
    <path d="M241.83 66.39H243.16" stroke="black" stroke-width="0.5" />
    <path d="M244 66.39H245.66" stroke="black" stroke-width="0.5" />
    <path d="M246.5 66.39H248.17" stroke="black" stroke-width="0.5" />
    <path d="M249 66.39H250.67" stroke="black" stroke-width="0.5" />
    <path d="M251.5 66.39H253.17" stroke="black" stroke-width="0.5" />
    <path d="M254 66.39H255.67" stroke="black" stroke-width="0.5" />
    <path d="M256.5 66.39H257.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 36.24H243.16" stroke="black" stroke-width="0.5" />
    <path d="M244 36.24H245.66" stroke="black" stroke-width="0.5" />
    <path d="M246.5 36.24H248.17" stroke="black" stroke-width="0.5" />
    <path d="M249 36.24H250.67" stroke="black" stroke-width="0.5" />
    <path d="M251.5 36.24H253.17" stroke="black" stroke-width="0.5" />
    <path d="M254 36.24H255.67" stroke="black" stroke-width="0.5" />
    <path d="M256.5 36.24H257.84" stroke="black" stroke-width="0.5" />
    <path d="M241.83 6.08H243.16" stroke="black" stroke-width="0.5" />
    <path d="M244 6.08H245.66" stroke="black" stroke-width="0.5" />
    <path d="M246.5 6.08H248.17" stroke="black" stroke-width="0.5" />
    <path d="M249 6.08H250.67" stroke="black" stroke-width="0.5" />
    <path d="M251.5 6.08H253.17" stroke="black" stroke-width="0.5" />
    <path d="M254 6.08H255.67" stroke="black" stroke-width="0.5" />
    <path d="M256.5 6.08H257.84" stroke="black" stroke-width="0.5" />
    <path d="M277.85 66.39H279.19" stroke="black" stroke-width="0.5" />
    <path d="M280.02 66.39H281.69" stroke="black" stroke-width="0.5" />
    <path d="M282.52 66.39H284.19" stroke="black" stroke-width="0.5" />
    <path d="M285.03 66.39H286.69" stroke="black" stroke-width="0.5" />
    <path d="M287.53 66.39H289.2" stroke="black" stroke-width="0.5" />
    <path d="M290.03 66.39H291.7" stroke="black" stroke-width="0.5" />
    <path d="M292.53 66.39H293.87" stroke="black" stroke-width="0.5" />
    <path d="M277.85 36.24H279.19" stroke="black" stroke-width="0.5" />
    <path d="M280.02 36.24H281.69" stroke="black" stroke-width="0.5" />
    <path d="M282.52 36.24H284.19" stroke="black" stroke-width="0.5" />
    <path d="M285.03 36.24H286.69" stroke="black" stroke-width="0.5" />
    <path d="M287.53 36.24H289.2" stroke="black" stroke-width="0.5" />
    <path d="M290.03 36.24H291.7" stroke="black" stroke-width="0.5" />
    <path d="M292.53 36.24H293.87" stroke="black" stroke-width="0.5" />
    <path d="M277.85 6.08H279.19" stroke="black" stroke-width="0.5" />
    <path d="M280.02 6.08H281.69" stroke="black" stroke-width="0.5" />
    <path d="M282.52 6.08H284.19" stroke="black" stroke-width="0.5" />
    <path d="M285.03 6.08H286.69" stroke="black" stroke-width="0.5" />
    <path d="M287.53 6.08H289.2" stroke="black" stroke-width="0.5" />
    <path d="M290.03 6.08H291.7" stroke="black" stroke-width="0.5" />
    <path d="M292.53 6.08H293.87" stroke="black" stroke-width="0.5" />
    <path d="M251.1 2.17999H247.13" stroke="black" stroke-width="0.5" />
    <path d="M252.81 103.96H247.86" stroke="black" stroke-width="0.5" />
    <path d="M247.86 103.96V103.08" stroke="black" stroke-width="0.5" />
    <path d="M247.86 103.08H252.81" stroke="black" stroke-width="0.5" />
    <path d="M252.81 103.08V103.96" stroke="black" stroke-width="0.5" />
    <path d="M252.38 103.08H248.41" stroke="black" stroke-width="0.5" />
    <path d="M248.41 103.08V102.31" stroke="black" stroke-width="0.5" />
    <path d="M248.41 102.31H252.38" stroke="black" stroke-width="0.5" />
    <path d="M252.38 102.31V103.08" stroke="black" stroke-width="0.5" />
    <path d="M317.65 116.15H316.86" stroke="black" stroke-width="0.5" />
    <path d="M316.86 116.15V113.27" stroke="black" stroke-width="0.5" />
    <path d="M316.86 113.27H317.65" stroke="black" stroke-width="0.5" />
    <path d="M317.65 113.27V116.15" stroke="black" stroke-width="0.5" />
    <path d="M317.65 112.02H316.68" stroke="black" stroke-width="0.5" />
    <path d="M316.68 112.02V107.73" stroke="black" stroke-width="0.5" />
    <path d="M316.68 107.73H317.65" stroke="black" stroke-width="0.5" />
    <path d="M317.65 107.73V112.02" stroke="black" stroke-width="0.5" />
    <path d="M297.99 120.29V118.22" stroke="black" stroke-width="0.5" />
    <path d="M297.99 117.39V115.32" stroke="black" stroke-width="0.5" />
    <path d="M318.42 102.6H317.63" stroke="black" stroke-width="0.5" />
    <path d="M317.63 102.6V99.71" stroke="black" stroke-width="0.5" />
    <path d="M317.63 99.71H318.42" stroke="black" stroke-width="0.5" />
    <path d="M318.42 99.71V102.6" stroke="black" stroke-width="0.5" />
    <path d="M309.78 123.51V123.06" stroke="black" stroke-width="0.5" />
    <path d="M308.47 123.51H309.78" stroke="black" stroke-width="0.5" />
    <path d="M308.47 123.06V123.51" stroke="black" stroke-width="0.5" />
    <path d="M309.78 123.06H308.47" stroke="black" stroke-width="0.5" />
    <path d="M311.37 126.72L311.28 127.1" stroke="black" stroke-width="0.5" />
    <path d="M311.28 127.1L311.05 127.38" stroke="black" stroke-width="0.5" />
    <path d="M311.05 127.38L310.72 127.49" stroke="black" stroke-width="0.5" />
    <path d="M307.53 127.49L307.21 127.38" stroke="black" stroke-width="0.5" />
    <path d="M307.21 127.38L306.97 127.1" stroke="black" stroke-width="0.5" />
    <path d="M306.97 127.1L306.89 126.72" stroke="black" stroke-width="0.5" />
    <path d="M306.89 124.81L306.97 124.42" stroke="black" stroke-width="0.5" />
    <path d="M306.97 124.42L307.21 124.14" stroke="black" stroke-width="0.5" />
    <path d="M307.21 124.14L307.53 124.04" stroke="black" stroke-width="0.5" />
    <path d="M310.72 124.04L311.05 124.14" stroke="black" stroke-width="0.5" />
    <path d="M311.05 124.14L311.28 124.42" stroke="black" stroke-width="0.5" />
    <path d="M311.28 124.42L311.37 124.81" stroke="black" stroke-width="0.5" />
    <path d="M311.37 126.72V124.81" stroke="black" stroke-width="0.5" />
    <path d="M307.53 127.49H310.72" stroke="black" stroke-width="0.5" />
    <path d="M306.89 124.81V126.72" stroke="black" stroke-width="0.5" />
    <path d="M310.72 124.04H307.53" stroke="black" stroke-width="0.5" />
    <path d="M311.8 127.93V122.58" stroke="black" stroke-width="0.5" />
    <path d="M306.45 127.93H311.8" stroke="black" stroke-width="0.5" />
    <path d="M306.45 122.58V127.93" stroke="black" stroke-width="0.5" />
    <path d="M311.8 122.58H306.45" stroke="black" stroke-width="0.5" />
    <path d="M296.09 124.97H297.71" stroke="black" stroke-width="0.5" />
    <path d="M298.55 124.97H300.22" stroke="black" stroke-width="0.5" />
    <path d="M301.05 124.97H302.72" stroke="black" stroke-width="0.5" />
    <path d="M303.55 124.97H305.22" stroke="black" stroke-width="0.5" />
    <path d="M306.05 124.97H307.72" stroke="black" stroke-width="0.5" />
    <path d="M308.56 124.97H310.22" stroke="black" stroke-width="0.5" />
    <path d="M311.06 124.97H312.72" stroke="black" stroke-width="0.5" />
    <path d="M313.56 124.97H315.18" stroke="black" stroke-width="0.5" />
    <path d="M315.18 124.97V126.59" stroke="black" stroke-width="0.5" />
    <path d="M315.18 127.42V129.09" stroke="black" stroke-width="0.5" />
    <path d="M315.18 129.92V131.59" stroke="black" stroke-width="0.5" />
    <path d="M315.18 132.42V134.09" stroke="black" stroke-width="0.5" />
    <path d="M315.18 134.93V136.59" stroke="black" stroke-width="0.5" />
    <path d="M315.18 137.43V139.1" stroke="black" stroke-width="0.5" />
    <path d="M315.18 139.93V141.6" stroke="black" stroke-width="0.5" />
    <path d="M315.18 142.43V144.1" stroke="black" stroke-width="0.5" />
    <path d="M315.18 144.93V146.6" stroke="black" stroke-width="0.5" />
    <path d="M315.18 147.44V149.1" stroke="black" stroke-width="0.5" />
    <path d="M315.18 149.94V151.6" stroke="black" stroke-width="0.5" />
    <path d="M315.18 152.44V154.11" stroke="black" stroke-width="0.5" />
    <path d="M315.18 154.94V156.61" stroke="black" stroke-width="0.5" />
    <path d="M315.18 157.44V159.11" stroke="black" stroke-width="0.5" />
    <path d="M315.18 159.94V161.56" stroke="black" stroke-width="0.5" />
    <path d="M317.08 72.33H322.03" stroke="black" stroke-width="0.5" />
    <path d="M322.03 72.33V65.93" stroke="black" stroke-width="0.5" />
    <path d="M322.03 65.93H319.75" stroke="black" stroke-width="0.5" />
    <path d="M304.55 124.97V117.58" stroke="black" stroke-width="0.5" />
    <path d="M304.55 117.58L303.36 118.53" stroke="black" stroke-width="0.5" />
    <path d="M304.55 117.58L305.74 118.53" stroke="black" stroke-width="0.5" />
    <path d="M304.55 124.97L303.36 124.02" stroke="black" stroke-width="0.5" />
    <path d="M304.55 124.97L305.74 124.02" stroke="black" stroke-width="0.5" />
    <path d="M306.19 121.28L306 122.04" stroke="black" stroke-width="0.5" />
    <path d="M306 122.04L305.48 122.63" stroke="black" stroke-width="0.5" />
    <path d="M305.48 122.63L304.75 122.9" stroke="black" stroke-width="0.5" />
    <path d="M304.75 122.9L303.97 122.81" stroke="black" stroke-width="0.5" />
    <path d="M303.97 122.81L303.32 122.36" stroke="black" stroke-width="0.5" />
    <path d="M303.32 122.36L302.96 121.67" stroke="black" stroke-width="0.5" />
    <path d="M302.96 121.67V120.88" stroke="black" stroke-width="0.5" />
    <path d="M302.96 120.88L303.32 120.19" stroke="black" stroke-width="0.5" />
    <path d="M303.32 120.19L303.97 119.74" stroke="black" stroke-width="0.5" />
    <path d="M303.97 119.74L304.75 119.65" stroke="black" stroke-width="0.5" />
    <path d="M304.75 119.65L305.48 119.93" stroke="black" stroke-width="0.5" />
    <path d="M305.48 119.93L306 120.51" stroke="black" stroke-width="0.5" />
    <path d="M306 120.51L306.19 121.28" stroke="black" stroke-width="0.5" />
    <path d="M317.47 69.43H320.69" stroke="black" stroke-width="0.5" />
    <path d="M320.69 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M317.47 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M160.99 90L161.18 88.13" stroke="black" stroke-width="0.5" />
    <path d="M161.18 88.13L161.72 86.33" stroke="black" stroke-width="0.5" />
    <path d="M161.72 86.33L162.61 84.67" stroke="black" stroke-width="0.5" />
    <path d="M162.61 84.67L163.81 83.21" stroke="black" stroke-width="0.5" />
    <path d="M163.81 83.21L165.26 82.02" stroke="black" stroke-width="0.5" />
    <path d="M165.26 82.02L166.92 81.13" stroke="black" stroke-width="0.5" />
    <path d="M166.92 81.13L168.73 80.58" stroke="black" stroke-width="0.5" />
    <path d="M168.73 80.58L170.6 80.4" stroke="black" stroke-width="0.5" />
    <path d="M170.6 90.01H170.13" stroke="black" stroke-width="0.5" />
    <path d="M170.13 90.01V80.41" stroke="black" stroke-width="0.5" />
    <path d="M170.6 80.4V90.01" stroke="black" stroke-width="0.5" />
    <path d="M160.99 90L160.81 88.13" stroke="black" stroke-width="0.5" />
    <path d="M160.81 88.13L160.26 86.33" stroke="black" stroke-width="0.5" />
    <path d="M160.26 86.33L159.37 84.67" stroke="black" stroke-width="0.5" />
    <path d="M159.37 84.67L158.17 83.21" stroke="black" stroke-width="0.5" />
    <path d="M158.17 83.21L156.72 82.02" stroke="black" stroke-width="0.5" />
    <path d="M156.72 82.02L155.06 81.13" stroke="black" stroke-width="0.5" />
    <path d="M155.06 81.13L153.25 80.58" stroke="black" stroke-width="0.5" />
    <path d="M153.25 80.58L151.38 80.4" stroke="black" stroke-width="0.5" />
    <path d="M151.38 90.01H151.85" stroke="black" stroke-width="0.5" />
    <path d="M151.85 90.01V80.41" stroke="black" stroke-width="0.5" />
    <path d="M151.38 80.4V90.01" stroke="black" stroke-width="0.5" />
    <path d="M257.83 20.62L255.96 20.81" stroke="black" stroke-width="0.5" />
    <path d="M255.96 20.81L254.16 21.36" stroke="black" stroke-width="0.5" />
    <path d="M254.16 21.36L252.5 22.25" stroke="black" stroke-width="0.5" />
    <path d="M252.5 22.25L251.04 23.44" stroke="black" stroke-width="0.5" />
    <path d="M251.04 23.44L249.85 24.9" stroke="black" stroke-width="0.5" />
    <path d="M249.85 24.9L248.96 26.56" stroke="black" stroke-width="0.5" />
    <path d="M248.96 26.56L248.42 28.36" stroke="black" stroke-width="0.5" />
    <path d="M248.42 28.36L248.23 30.24" stroke="black" stroke-width="0.5" />
    <path d="M257.84 30.23V29.76" stroke="black" stroke-width="0.5" />
    <path d="M257.84 29.76L248.24 29.77" stroke="black" stroke-width="0.5" />
    <path d="M248.23 30.24L257.84 30.23" stroke="black" stroke-width="0.5" />
    <path d="M277.86 20.62L279.73 20.81" stroke="black" stroke-width="0.5" />
    <path d="M279.73 20.81L281.53 21.36" stroke="black" stroke-width="0.5" />
    <path d="M281.53 21.36L283.19 22.25" stroke="black" stroke-width="0.5" />
    <path d="M283.19 22.25L284.65 23.44" stroke="black" stroke-width="0.5" />
    <path d="M284.65 23.44L285.84 24.9" stroke="black" stroke-width="0.5" />
    <path d="M285.84 24.9L286.73 26.56" stroke="black" stroke-width="0.5" />
    <path d="M286.73 26.56L287.28 28.36" stroke="black" stroke-width="0.5" />
    <path d="M287.28 28.36L287.46 30.24" stroke="black" stroke-width="0.5" />
    <path d="M277.85 30.23V29.76" stroke="black" stroke-width="0.5" />
    <path d="M277.85 29.76L287.45 29.77" stroke="black" stroke-width="0.5" />
    <path d="M287.46 30.24L277.85 30.23" stroke="black" stroke-width="0.5" />
    <path d="M265.58 10.75L265.76 8.87" stroke="black" stroke-width="0.5" />
    <path d="M265.76 8.87L266.31 7.07001" stroke="black" stroke-width="0.5" />
    <path d="M266.31 7.07001L267.2 5.41" stroke="black" stroke-width="0.5" />
    <path d="M267.2 5.41L268.39 3.95" stroke="black" stroke-width="0.5" />
    <path d="M268.39 3.95L269.85 2.75999" stroke="black" stroke-width="0.5" />
    <path d="M269.85 2.75999L271.51 1.87" stroke="black" stroke-width="0.5" />
    <path d="M271.51 1.87L273.31 1.33" stroke="black" stroke-width="0.5" />
    <path d="M273.31 1.33L275.19 1.14" stroke="black" stroke-width="0.5" />
    <path d="M275.18 10.75H274.72" stroke="black" stroke-width="0.5" />
    <path d="M274.72 10.75V1.14999" stroke="black" stroke-width="0.5" />
    <path d="M275.19 1.14L275.18 10.75" stroke="black" stroke-width="0.5" />
    <path d="M257.83 51.05L255.96 51.23" stroke="black" stroke-width="0.5" />
    <path d="M255.96 51.23L254.16 51.78" stroke="black" stroke-width="0.5" />
    <path d="M254.16 51.78L252.5 52.67" stroke="black" stroke-width="0.5" />
    <path d="M252.5 52.67L251.04 53.86" stroke="black" stroke-width="0.5" />
    <path d="M251.04 53.86L249.85 55.32" stroke="black" stroke-width="0.5" />
    <path d="M249.85 55.32L248.96 56.98" stroke="black" stroke-width="0.5" />
    <path d="M248.96 56.98L248.42 58.78" stroke="black" stroke-width="0.5" />
    <path d="M248.42 58.78L248.23 60.66" stroke="black" stroke-width="0.5" />
    <path d="M257.84 60.65V60.19" stroke="black" stroke-width="0.5" />
    <path d="M257.84 60.19H248.24" stroke="black" stroke-width="0.5" />
    <path d="M248.23 60.66L257.84 60.65" stroke="black" stroke-width="0.5" />
    <path d="M277.86 51.05L279.73 51.23" stroke="black" stroke-width="0.5" />
    <path d="M279.73 51.23L281.53 51.78" stroke="black" stroke-width="0.5" />
    <path d="M281.53 51.78L283.19 52.67" stroke="black" stroke-width="0.5" />
    <path d="M283.19 52.67L284.65 53.86" stroke="black" stroke-width="0.5" />
    <path d="M284.65 53.86L285.84 55.32" stroke="black" stroke-width="0.5" />
    <path d="M285.84 55.32L286.73 56.98" stroke="black" stroke-width="0.5" />
    <path d="M286.73 56.98L287.28 58.78" stroke="black" stroke-width="0.5" />
    <path d="M287.28 58.78L287.46 60.66" stroke="black" stroke-width="0.5" />
    <path d="M277.85 60.65V60.19" stroke="black" stroke-width="0.5" />
    <path d="M277.85 60.19H287.45" stroke="black" stroke-width="0.5" />
    <path d="M287.46 60.66L277.85 60.65" stroke="black" stroke-width="0.5" />
    <path d="M277.86 81.2L279.73 81.39" stroke="black" stroke-width="0.5" />
    <path d="M279.73 81.39L281.53 81.93" stroke="black" stroke-width="0.5" />
    <path d="M281.53 81.93L283.19 82.82" stroke="black" stroke-width="0.5" />
    <path d="M283.19 82.82L284.65 84.02" stroke="black" stroke-width="0.5" />
    <path d="M284.65 84.02L285.84 85.47" stroke="black" stroke-width="0.5" />
    <path d="M285.84 85.47L286.73 87.14" stroke="black" stroke-width="0.5" />
    <path d="M286.73 87.14L287.28 88.94" stroke="black" stroke-width="0.5" />
    <path d="M287.28 88.94L287.46 90.81" stroke="black" stroke-width="0.5" />
    <path d="M277.85 90.81V90.34" stroke="black" stroke-width="0.5" />
    <path d="M277.85 90.34L287.45 90.35" stroke="black" stroke-width="0.5" />
    <path d="M287.46 90.81H277.85" stroke="black" stroke-width="0.5" />
    <path d="M257.83 90.54L255.96 90.73" stroke="black" stroke-width="0.5" />
    <path d="M255.96 90.73L254.16 91.27" stroke="black" stroke-width="0.5" />
    <path d="M254.16 91.27L252.5 92.16" stroke="black" stroke-width="0.5" />
    <path d="M252.5 92.16L251.04 93.36" stroke="black" stroke-width="0.5" />
    <path d="M251.04 93.36L249.85 94.81" stroke="black" stroke-width="0.5" />
    <path d="M249.85 94.81L248.96 96.48" stroke="black" stroke-width="0.5" />
    <path d="M248.96 96.48L248.42 98.28" stroke="black" stroke-width="0.5" />
    <path d="M248.42 98.28L248.23 100.15" stroke="black" stroke-width="0.5" />
    <path d="M257.84 100.15V99.68" stroke="black" stroke-width="0.5" />
    <path d="M257.84 99.68L248.24 99.69" stroke="black" stroke-width="0.5" />
    <path d="M248.23 100.15H257.84" stroke="black" stroke-width="0.5" />
    <path d="M191.12 101.75L191.31 99.87" stroke="black" stroke-width="0.5" />
    <path d="M191.31 99.87L191.86 98.07" stroke="black" stroke-width="0.5" />
    <path d="M191.86 98.07L192.74 96.41" stroke="black" stroke-width="0.5" />
    <path d="M192.74 96.41L193.94 94.95" stroke="black" stroke-width="0.5" />
    <path d="M193.94 94.95L195.4 93.76" stroke="black" stroke-width="0.5" />
    <path d="M195.4 93.76L197.06 92.87" stroke="black" stroke-width="0.5" />
    <path d="M197.06 92.87L198.86 92.33" stroke="black" stroke-width="0.5" />
    <path d="M198.86 92.33L200.73 92.14" stroke="black" stroke-width="0.5" />
    <path d="M200.73 101.75H200.26" stroke="black" stroke-width="0.5" />
    <path d="M200.26 101.75L200.27 92.15" stroke="black" stroke-width="0.5" />
    <path d="M200.73 92.14V101.75" stroke="black" stroke-width="0.5" />
    <path d="M180.44 113.85L178.57 113.66" stroke="black" stroke-width="0.5" />
    <path d="M178.57 113.66L176.77 113.11" stroke="black" stroke-width="0.5" />
    <path d="M176.77 113.11L175.11 112.23" stroke="black" stroke-width="0.5" />
    <path d="M175.11 112.23L173.65 111.03" stroke="black" stroke-width="0.5" />
    <path d="M173.65 111.03L172.46 109.57" stroke="black" stroke-width="0.5" />
    <path d="M172.46 109.57L171.57 107.91" stroke="black" stroke-width="0.5" />
    <path d="M171.57 107.91L171.03 106.11" stroke="black" stroke-width="0.5" />
    <path d="M171.03 106.11L170.84 104.24" stroke="black" stroke-width="0.5" />
    <path d="M180.45 104.24V104.71" stroke="black" stroke-width="0.5" />
    <path d="M180.45 104.71L170.85 104.7" stroke="black" stroke-width="0.5" />
    <path d="M170.84 104.24H180.45" stroke="black" stroke-width="0.5" />
    <path d="M141.61 101.95L143.48 101.76" stroke="black" stroke-width="0.5" />
    <path d="M143.48 101.76L145.28 101.22" stroke="black" stroke-width="0.5" />
    <path d="M145.28 101.22L146.94 100.33" stroke="black" stroke-width="0.5" />
    <path d="M146.94 100.33L148.4 99.13" stroke="black" stroke-width="0.5" />
    <path d="M148.4 99.13L149.59 97.68" stroke="black" stroke-width="0.5" />
    <path d="M149.59 97.68L150.48 96.01" stroke="black" stroke-width="0.5" />
    <path d="M150.48 96.01L151.02 94.21" stroke="black" stroke-width="0.5" />
    <path d="M151.02 94.21L151.21 92.34" stroke="black" stroke-width="0.5" />
    <path d="M141.6 92.34V92.81" stroke="black" stroke-width="0.5" />
    <path d="M141.6 92.81L151.2 92.8" stroke="black" stroke-width="0.5" />
    <path d="M151.21 92.34H141.6" stroke="black" stroke-width="0.5" />
  </svg>
</template>
