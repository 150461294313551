<template>
  <v-card class="utility-companies-bar">
    <v-app-bar elevation="1" class="px-13">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        Utility Companies
      </v-app-bar-title>
      <v-text-field
        class="mt-1 mr-5"
        v-model="searchTerm"
        hide-details
        prepend-inner-icon="mdi-magnify"
        bg-color="white"
        single-line
        variant="outlined"
        density="compact"
        :placeholder="placeholder"
        :clearable="true"
        @click:clear="handleSearchInput"
        @update:modelValue="handleSearchInput"
      />
      <!-- Add Button -->
      <v-btn
        v-if="userCanUpdate"
        class="rounded-2 mr-3"
        color="primary"
        variant="flat"
        size="large"
        @click="handleAddUtilityCompany"
      >
        <div class="text-subtitle-2 text-white text-truncate font-weight-bold">
          Add Utility Company
        </div>
      </v-btn>
      <v-spacer />
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'UtilityCompaniesBar',

  data() {
    return {
      searchTerm: '',
      toolBarHeight: 50,
      toolBarWidth: 995,
      selectedSite: null,
    };
  },

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Company';
    },

    userCanCreate() {
      return this.$can('create', 'analytics');
    },
    userCanDelete() {
      return this.$can('delete', 'analytics');
    },
    userCanUpdate() {
      return this.$can('update', 'analytics');
    },
  },

  watch: {
    $route() {
      this.clearSearchTerm();
    },
  },

  methods: {
    ...mapMutations({
      setUtilityCompaniesSearchTerm:
        'SitesStore/UtilityCompaniesStore/SET_UTILITY_COMPANY_SEARCH_TERM',
    }),

    handleAddUtilityCompany() {
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({
        name: 'UtilityCompaniesCreate',
      });
    },

    handleSearchInput(value) {
      this.setUtilityCompaniesSearchTerm(value);
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setUtilityCompaniesSearchTerm('');
    },
  },
};
</script>
<style lang="scss" scoped>
.utility-companies-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 700px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
