const NOTIFICATIONS_LIST_BY_SITE = (state, notificationsListBySite) => {
  state.notificationsListBySite = notificationsListBySite;
};

const NOTIFICATIONS_COUNTER_BY_SITE = (state, site) => {
  const index = state.notificationsCounterBySite.findIndex(
    (item) => item.siteId === site.siteId
  );
  if (index === -1) {
    state.notificationsCounterBySite.push(site);
  } else {
    state.notificationsCounterBySite.splice(index, 1, site);
  }
};

const NOTIFICATIONS_SEARCH_TERM = (state, searchTerm) => {
  state.notificationsSearchTerm = searchTerm;
};

const NOTIFICATIONS_IS_LOADING = (state, isLoading) => {
  state.notificationsIsLoading = isLoading;
};

const SET_SITES_LIST = (state, sitesList) => {  
  state.sitesList = sitesList;
};

export default {
  NOTIFICATIONS_LIST_BY_SITE,
  NOTIFICATIONS_COUNTER_BY_SITE,
  NOTIFICATIONS_SEARCH_TERM,
  NOTIFICATIONS_IS_LOADING,
  SET_SITES_LIST
};
