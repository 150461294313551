<template>
  <svg
    width="205"
    height="117"
    viewBox="0 0 205 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.53003 9.07001V5.88" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 5.87999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M2.53003 5.88H5.45001" stroke="black" stroke-width="0.5" />
    <path d="M34.71 5.88H56.52" stroke="black" stroke-width="0.5" />
    <path d="M85.78 3.73999V5.87999" stroke="black" stroke-width="0.5" />
    <path d="M136.84 5.88H158.65" stroke="black" stroke-width="0.5" />
    <path d="M187.9 5.88H199.17" stroke="black" stroke-width="0.5" />
    <path d="M201.31 45.47H199.17" stroke="black" stroke-width="0.5" />
    <path d="M201.31 96.54H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 96.54V111.1" stroke="black" stroke-width="0.5" />
    <path d="M196.95 115.72V113.09" stroke="black" stroke-width="0.5" />
    <path d="M195.62 116.52V114.42" stroke="black" stroke-width="0.5" />
    <path d="M203.96 116.52H195.62" stroke="black" stroke-width="0.5" />
    <path d="M0.929993 9V7.20001" stroke="black" stroke-width="0.5" />
    <path d="M195.62 114.42H160.59" stroke="black" stroke-width="0.5" />
    <path d="M144.72 114.42H99.96" stroke="black" stroke-width="0.5" />
    <path
      d="M66.91 114.42H0.698151L0.698154 113"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M34.71 3.73999V5.87999" stroke="black" stroke-width="0.5" />
    <path d="M0.929993 7.19999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M85.78 5.88H107.58" stroke="black" stroke-width="0.5" />
    <path d="M56.52 5.87999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M107.58 5.87999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M136.84 3.73999V5.87999" stroke="black" stroke-width="0.5" />
    <path d="M187.9 3.73999V5.87999" stroke="black" stroke-width="0.5" />
    <path d="M85.78 1.08997H107.58" stroke="black" stroke-width="0.5" />
    <path d="M158.65 5.87999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M187.9 1.08997H203.96" stroke="black" stroke-width="0.5" />
    <path d="M34.71 1.08997H56.52" stroke="black" stroke-width="0.5" />
    <path d="M199.17 5.88V16.22" stroke="black" stroke-width="0.5" />
    <path d="M203.96 1.08997V16.22" stroke="black" stroke-width="0.5" />
    <path d="M199.17 16.22H201.31" stroke="black" stroke-width="0.5" />
    <path d="M203.96 96.54V116.52" stroke="black" stroke-width="0.5" />
    <path d="M199.17 67.28H201.31" stroke="black" stroke-width="0.5" />
    <path d="M199.17 45.47V67.28" stroke="black" stroke-width="0.5" />
    <path d="M199.34 115.72H196.95" stroke="black" stroke-width="0.5" />
    <path d="M199.34 111.1V115.72" stroke="black" stroke-width="0.5" />
    <path d="M199.17 111.1H199.34" stroke="black" stroke-width="0.5" />
    <path d="M143.43 113.09H100.43" stroke="black" stroke-width="0.5" />
    <path d="M196.95 113.09H161.88" stroke="black" stroke-width="0.5" />
    <path d="M65.62 113.09H0.5" stroke="black" stroke-width="0.5" />
    <path d="M199.34 111.1H201.31" stroke="black" stroke-width="0.5" />
    <path d="M201.31 96.54H203.96" stroke="black" stroke-width="0.5" />
    <path d="M201.31 67.28H203.96" stroke="black" stroke-width="0.5" />
    <path d="M201.31 45.47H203.96" stroke="black" stroke-width="0.5" />
    <path d="M187.9 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M158.65 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M136.84 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M107.58 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M85.78 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M56.52 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M34.71 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 3.73999H0.929993" stroke="black" stroke-width="0.5" />
    <path d="M85.78 3.73999H107.58" stroke="black" stroke-width="0.5" />
    <path d="M201.31 45.47V67.28" stroke="black" stroke-width="0.5" />
    <path d="M136.84 3.73999H158.65" stroke="black" stroke-width="0.5" />
    <path d="M187.9 3.73999H201.31" stroke="black" stroke-width="0.5" />
    <path d="M201.31 16.22V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 5.88H34.71" stroke="black" stroke-width="0.5" />
    <path d="M201.31 96.54V111.1" stroke="black" stroke-width="0.5" />
    <path d="M199.34 115.72V116.52" stroke="black" stroke-width="0.5" />
    <path d="M0.580017 113.09V9.07001" stroke="black" stroke-width="0.5" />
    <path d="M0.580017 9.07001H4.39001" stroke="black" stroke-width="0.5" />
    <path d="M4.39001 9.07001V5.88" stroke="black" stroke-width="0.5" />
    <path d="M4.39001 5.88H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 5.88V111.1" stroke="black" stroke-width="0.5" />
    <path d="M203.96 45.47V67.28" stroke="black" stroke-width="0.5" />
    <path d="M136.84 1.08997H158.65" stroke="black" stroke-width="0.5" />
    <path d="M0.5 9.85999H1.67999" stroke="black" stroke-width="0.5" />
    <path d="M1.67999 9.86001V9.07001" stroke="black" stroke-width="0.5" />
    <path d="M201.31 16.22H203.96" stroke="black" stroke-width="0.5" />
    <path d="M199.17 16.22H203.96" stroke="black" stroke-width="0.5" />
    <path d="M203.96 16.22V45.47" stroke="black" stroke-width="0.5" />
    <path d="M203.96 45.47H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 45.47V16.22" stroke="black" stroke-width="0.5" />
    <path d="M201.31 16.22V45.47" stroke="black" stroke-width="0.5" />
    <path d="M201.04 16.22V45.47" stroke="black" stroke-width="0.5" />
    <path d="M202.11 16.22V45.47" stroke="black" stroke-width="0.5" />
    <path d="M201.31 16.75H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 20.74H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 21.27H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 40.95H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 40.42H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 44.94H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 31H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 30.47H202.11" stroke="black" stroke-width="0.5" />
    <path d="M199.17 67.28H203.96" stroke="black" stroke-width="0.5" />
    <path d="M203.96 67.28V96.54" stroke="black" stroke-width="0.5" />
    <path d="M203.96 96.54H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 96.54V67.28" stroke="black" stroke-width="0.5" />
    <path d="M201.31 67.28V96.54" stroke="black" stroke-width="0.5" />
    <path d="M201.04 67.28V96.54" stroke="black" stroke-width="0.5" />
    <path d="M202.11 67.28V96.54" stroke="black" stroke-width="0.5" />
    <path d="M201.31 67.81H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 71.8H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 72.34H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 92.02H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 91.48H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 96.01H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 82.07H202.11" stroke="black" stroke-width="0.5" />
    <path d="M201.31 81.53H202.11" stroke="black" stroke-width="0.5" />
    <path d="M158.65 5.87997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M158.65 1.08997H187.9" stroke="black" stroke-width="0.5" />
    <path d="M187.9 1.08997V5.87997" stroke="black" stroke-width="0.5" />
    <path d="M187.9 5.88H158.65" stroke="black" stroke-width="0.5" />
    <path d="M158.65 3.73999H187.9" stroke="black" stroke-width="0.5" />
    <path d="M158.65 4.01001H187.9" stroke="black" stroke-width="0.5" />
    <path d="M158.65 2.95001H187.9" stroke="black" stroke-width="0.5" />
    <path d="M159.18 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M163.17 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M163.7 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M183.38 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M182.85 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M187.37 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M173.43 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M172.9 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M107.58 5.87997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M107.58 1.08997H136.84" stroke="black" stroke-width="0.5" />
    <path d="M136.84 1.08997V5.87997" stroke="black" stroke-width="0.5" />
    <path d="M136.84 5.88H107.58" stroke="black" stroke-width="0.5" />
    <path d="M107.58 3.73999H136.84" stroke="black" stroke-width="0.5" />
    <path d="M107.58 4.01001H136.84" stroke="black" stroke-width="0.5" />
    <path d="M107.58 2.95001H136.84" stroke="black" stroke-width="0.5" />
    <path d="M108.12 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M112.11 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M112.64 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M132.32 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M131.79 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M136.31 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M122.37 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M121.84 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M56.52 5.87997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M56.52 1.08997H85.78" stroke="black" stroke-width="0.5" />
    <path d="M85.78 1.08997V5.87997" stroke="black" stroke-width="0.5" />
    <path d="M85.78 5.88H56.52" stroke="black" stroke-width="0.5" />
    <path d="M56.52 3.73999H85.78" stroke="black" stroke-width="0.5" />
    <path d="M56.52 4.01001H85.78" stroke="black" stroke-width="0.5" />
    <path d="M56.52 2.95001H85.78" stroke="black" stroke-width="0.5" />
    <path d="M57.05 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M61.04 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M61.57 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M81.25 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M80.72 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M85.24 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M71.3 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M70.77 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 5.87997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 1.08997H34.71" stroke="black" stroke-width="0.5" />
    <path d="M34.71 1.08997V5.87997" stroke="black" stroke-width="0.5" />
    <path d="M34.71 5.88H5.45001" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 3.73999H34.71" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 4.01001H34.71" stroke="black" stroke-width="0.5" />
    <path d="M5.45001 2.95001H34.71" stroke="black" stroke-width="0.5" />
    <path d="M5.98999 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M9.97998 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M10.51 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M30.19 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M29.66 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M34.18 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M20.24 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M19.71 3.74001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M56.52 3.73999H34.71" stroke="black" stroke-width="0.5" />
    <path d="M93.58 115.75V113.84" stroke="black" stroke-width="0.5" />
    <path d="M99.96 115.75V114.42" stroke="black" stroke-width="0.5" />
    <path d="M93.58 115.75H99.96" stroke="black" stroke-width="0.5" />
    <path d="M95.17 113.09H98.36" stroke="black" stroke-width="0.5" />
    <path d="M98.36 113.09V115.75" stroke="black" stroke-width="0.5" />
    <path d="M98.36 115.75H95.17" stroke="black" stroke-width="0.5" />
    <path d="M95.17 115.75V113.09" stroke="black" stroke-width="0.5" />
    <path d="M95.7 113.63H97.83" stroke="black" stroke-width="0.5" />
    <path d="M97.83 113.63V115.22" stroke="black" stroke-width="0.5" />
    <path d="M97.83 115.22H95.7" stroke="black" stroke-width="0.5" />
    <path d="M95.7 115.22V113.63" stroke="black" stroke-width="0.5" />
    <path d="M197.01 115.72H199.34" stroke="black" stroke-width="0.5" />
    <path d="M199.34 115.72V111.1" stroke="black" stroke-width="0.5" />
    <path d="M95.47 75.25V65.63" stroke="black" stroke-width="0.5" />
    <path d="M95.47 65.63L98.33 65.62" stroke="black" stroke-width="0.5" />
    <path d="M98.33 65.62V77.7" stroke="black" stroke-width="0.5" />
    <path d="M98.33 77.7V53.38" stroke="black" stroke-width="0.5" />
    <path d="M98.33 53.38L80.21 53.35" stroke="black" stroke-width="0.5" />
    <path d="M80.21 53.35V59.82" stroke="black" stroke-width="0.5" />
    <path d="M80.21 59.82H90.23" stroke="black" stroke-width="0.5" />
    <path d="M90.23 59.82V71.51" stroke="black" stroke-width="0.5" />
    <path d="M90.23 71.51L86.68 72.86" stroke="black" stroke-width="0.5" />
    <path d="M86.68 72.86L83 73.84" stroke="black" stroke-width="0.5" />
    <path d="M83 73.84L79.25 74.43" stroke="black" stroke-width="0.5" />
    <path d="M79.25 74.43L75.45 74.62" stroke="black" stroke-width="0.5" />
    <path d="M75.45 74.62L71.65 74.43" stroke="black" stroke-width="0.5" />
    <path d="M71.65 74.43L67.89 73.84" stroke="black" stroke-width="0.5" />
    <path d="M67.89 73.84L64.22 72.86" stroke="black" stroke-width="0.5" />
    <path d="M64.22 72.86L60.67 71.51" stroke="black" stroke-width="0.5" />
    <path d="M60.67 71.51V53.38" stroke="black" stroke-width="0.5" />
    <path d="M60.67 53.38H52.73" stroke="black" stroke-width="0.5" />
    <path d="M52.73 53.38V77.7" stroke="black" stroke-width="0.5" />
    <path d="M52.73 77.7V65.61" stroke="black" stroke-width="0.5" />
    <path d="M52.73 65.61H55.45" stroke="black" stroke-width="0.5" />
    <path d="M55.45 65.61V75.25" stroke="black" stroke-width="0.5" />
    <path d="M55.45 75.25L59.24 76.94" stroke="black" stroke-width="0.5" />
    <path d="M59.24 76.94L63.17 78.28" stroke="black" stroke-width="0.5" />
    <path d="M63.17 78.28L67.21 79.25" stroke="black" stroke-width="0.5" />
    <path d="M67.21 79.25L71.32 79.83" stroke="black" stroke-width="0.5" />
    <path d="M71.32 79.83L75.46 80.02" stroke="black" stroke-width="0.5" />
    <path d="M75.46 80.02L79.61 79.83" stroke="black" stroke-width="0.5" />
    <path d="M79.61 79.83L83.72 79.25" stroke="black" stroke-width="0.5" />
    <path d="M83.72 79.25L87.75 78.28" stroke="black" stroke-width="0.5" />
    <path d="M87.75 78.28L91.68 76.94" stroke="black" stroke-width="0.5" />
    <path d="M91.68 76.94L95.47 75.25" stroke="black" stroke-width="0.5" />
    <path d="M55.59 64.32V53.38" stroke="black" stroke-width="0.5" />
    <path d="M55.59 53.38H52.73" stroke="black" stroke-width="0.5" />
    <path d="M52.73 53.38V64.32" stroke="black" stroke-width="0.5" />
    <path d="M52.73 64.32H55.59" stroke="black" stroke-width="0.5" />
    <path d="M98.33 64.34V53.38" stroke="black" stroke-width="0.5" />
    <path d="M98.33 53.38H95.47" stroke="black" stroke-width="0.5" />
    <path d="M95.47 53.38V64.34" stroke="black" stroke-width="0.5" />
    <path d="M95.47 64.34H98.33" stroke="black" stroke-width="0.5" />
    <path d="M82.39 79.48L82.83 82.14" stroke="black" stroke-width="0.5" />
    <path d="M82.83 82.14L78.16 82.53" stroke="black" stroke-width="0.5" />
    <path d="M78.16 82.53L73.47 82.56" stroke="black" stroke-width="0.5" />
    <path d="M73.47 82.56L68.8 82.22" stroke="black" stroke-width="0.5" />
    <path d="M68.8 82.22L69.21 79.58" stroke="black" stroke-width="0.5" />
    <path d="M69.21 79.58L72.5 79.9" stroke="black" stroke-width="0.5" />
    <path d="M72.5 79.9L75.8 79.99" stroke="black" stroke-width="0.5" />
    <path d="M75.8 79.99L79.1 79.84" stroke="black" stroke-width="0.5" />
    <path d="M79.1 79.84L82.39 79.48" stroke="black" stroke-width="0.5" />
    <path d="M99.97 5.88V38.07" stroke="black" stroke-width="0.5" />
    <path d="M99.97 38.07H93.28" stroke="black" stroke-width="0.5" />
    <path d="M93.28 38.07V12.33" stroke="black" stroke-width="0.5" />
    <path d="M93.28 12.33H50.67" stroke="black" stroke-width="0.5" />
    <path d="M50.67 12.33V31.94" stroke="black" stroke-width="0.5" />
    <path d="M50.67 31.94H65.98" stroke="black" stroke-width="0.5" />
    <path d="M65.98 31.94V39.31" stroke="black" stroke-width="0.5" />
    <path d="M65.98 39.31H43.99" stroke="black" stroke-width="0.5" />
    <path d="M43.99 39.31V5.88" stroke="black" stroke-width="0.5" />
    <path d="M43.99 5.88H99.97" stroke="black" stroke-width="0.5" />
    <path d="M65.98 31.02V40.13" stroke="black" stroke-width="0.5" />
    <path d="M65.98 40.13H77.39" stroke="black" stroke-width="0.5" />
    <path d="M77.39 40.13V31.02" stroke="black" stroke-width="0.5" />
    <path d="M77.39 31.02H65.98" stroke="black" stroke-width="0.5" />
    <path d="M65.98 39.31H43.99" stroke="black" stroke-width="0.5" />
    <path d="M43.99 39.31V35.93" stroke="black" stroke-width="0.5" />
    <path d="M43.99 35.93H65.98" stroke="black" stroke-width="0.5" />
    <path d="M65.98 35.93V39.31" stroke="black" stroke-width="0.5" />
    <path d="M121.71 38.02H121.31" stroke="black" stroke-width="0.5" />
    <path d="M121.31 38.02H121.71" stroke="black" stroke-width="0.5" />
    <path d="M121.71 38.02V38.18" stroke="black" stroke-width="0.5" />
    <path d="M121.71 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M121.71 38.02H101.26" stroke="black" stroke-width="0.5" />
    <path d="M101.26 38.02V5.88" stroke="black" stroke-width="0.5" />
    <path d="M101.26 5.88H101.1" stroke="black" stroke-width="0.5" />
    <path d="M100.14 5.88H99.97" stroke="black" stroke-width="0.5" />
    <path d="M99.97 5.88V38.02" stroke="black" stroke-width="0.5" />
    <path d="M99.97 38.02H93.28" stroke="black" stroke-width="0.5" />
    <path d="M93.28 38.02V38.18" stroke="black" stroke-width="0.5" />
    <path d="M155.28 38.02H154.88" stroke="black" stroke-width="0.5" />
    <path d="M154.88 38.02H155.28" stroke="black" stroke-width="0.5" />
    <path d="M155.28 38.02V38.18" stroke="black" stroke-width="0.5" />
    <path d="M155.28 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M155.28 38.02H134.3" stroke="black" stroke-width="0.5" />
    <path d="M134.3 38.02V5.88" stroke="black" stroke-width="0.5" />
    <path d="M134.3 5.88H134.13" stroke="black" stroke-width="0.5" />
    <path d="M133.17 5.88H133.01" stroke="black" stroke-width="0.5" />
    <path d="M133.01 5.88V38.02" stroke="black" stroke-width="0.5" />
    <path d="M133.01 38.02H131.76" stroke="black" stroke-width="0.5" />
    <path d="M131.76 38.02V38.18" stroke="black" stroke-width="0.5" />
    <path d="M131.76 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M131.76 38.02H132.16" stroke="black" stroke-width="0.5" />
    <path d="M132.16 38.02H131.76" stroke="black" stroke-width="0.5" />
    <path d="M166.21 5.88H166.04" stroke="black" stroke-width="0.5" />
    <path d="M166.04 5.88V38.02" stroke="black" stroke-width="0.5" />
    <path d="M166.04 38.02H165.33" stroke="black" stroke-width="0.5" />
    <path d="M165.33 38.02V38.18" stroke="black" stroke-width="0.5" />
    <path d="M165.33 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M165.33 38.02H165.73" stroke="black" stroke-width="0.5" />
    <path d="M165.73 38.02H165.33" stroke="black" stroke-width="0.5" />
    <path d="M168.18 38.02H167.79" stroke="black" stroke-width="0.5" />
    <path d="M167.79 38.02H168.18" stroke="black" stroke-width="0.5" />
    <path d="M168.18 38.02V38.18" stroke="black" stroke-width="0.5" />
    <path d="M168.18 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M168.18 38.02H167.33" stroke="black" stroke-width="0.5" />
    <path d="M167.33 38.02V5.88" stroke="black" stroke-width="0.5" />
    <path d="M167.33 5.88H167.17" stroke="black" stroke-width="0.5" />
    <path d="M161.88 56.94V56.55" stroke="black" stroke-width="0.5" />
    <path d="M161.88 56.55V56.94" stroke="black" stroke-width="0.5" />
    <path d="M161.88 56.94H161.72" stroke="black" stroke-width="0.5" />
    <path d="M161.72 56.94H161.88" stroke="black" stroke-width="0.5" />
    <path d="M161.88 56.94V56.44" stroke="black" stroke-width="0.5" />
    <path d="M161.88 56.44H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 56.44V56.27" stroke="black" stroke-width="0.5" />
    <path d="M199.17 55.31V55.15" stroke="black" stroke-width="0.5" />
    <path d="M199.17 55.15H180.64" stroke="black" stroke-width="0.5" />
    <path d="M180.64 55.15V54.37" stroke="black" stroke-width="0.5" />
    <path d="M180.64 54.37H180.47" stroke="black" stroke-width="0.5" />
    <path d="M180.47 54.37H180.64" stroke="black" stroke-width="0.5" />
    <path d="M180.64 54.37V54.77" stroke="black" stroke-width="0.5" />
    <path d="M180.64 54.77V54.37" stroke="black" stroke-width="0.5" />
    <path d="M179.35 54.77V54.37" stroke="black" stroke-width="0.5" />
    <path d="M179.35 54.37V54.77" stroke="black" stroke-width="0.5" />
    <path d="M179.35 54.77V54.37" stroke="black" stroke-width="0.5" />
    <path d="M179.35 54.37V55.15" stroke="black" stroke-width="0.5" />
    <path d="M179.35 55.15H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 55.15V56.94" stroke="black" stroke-width="0.5" />
    <path d="M160.59 56.94H160.76" stroke="black" stroke-width="0.5" />
    <path d="M160.76 56.94H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 56.94V56.55" stroke="black" stroke-width="0.5" />
    <path d="M160.59 56.55V56.94" stroke="black" stroke-width="0.5" />
    <path d="M132.16 39.31H131.76" stroke="black" stroke-width="0.5" />
    <path d="M131.76 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M131.76 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M131.76 39.31H132.16" stroke="black" stroke-width="0.5" />
    <path d="M131.76 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M131.76 38.02H132.16" stroke="black" stroke-width="0.5" />
    <path d="M121.71 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M121.71 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M121.71 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M121.71 39.31H121.31" stroke="black" stroke-width="0.5" />
    <path d="M121.31 39.31H121.71" stroke="black" stroke-width="0.5" />
    <path d="M121.71 39.31H121.31" stroke="black" stroke-width="0.5" />
    <path d="M121.71 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M121.71 38.02H121.31" stroke="black" stroke-width="0.5" />
    <path d="M165.73 39.31H165.33" stroke="black" stroke-width="0.5" />
    <path d="M165.33 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M165.33 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M165.33 39.31H165.73" stroke="black" stroke-width="0.5" />
    <path d="M165.33 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M165.33 38.02H165.73" stroke="black" stroke-width="0.5" />
    <path d="M155.28 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M155.28 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M155.28 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M155.28 39.31H154.88" stroke="black" stroke-width="0.5" />
    <path d="M154.88 39.31H155.28" stroke="black" stroke-width="0.5" />
    <path d="M155.28 39.31H154.88" stroke="black" stroke-width="0.5" />
    <path d="M155.28 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M155.28 38.02H154.88" stroke="black" stroke-width="0.5" />
    <path d="M168.18 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M168.18 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M168.18 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M168.18 39.31H167.79" stroke="black" stroke-width="0.5" />
    <path d="M167.79 39.31H168.18" stroke="black" stroke-width="0.5" />
    <path d="M168.18 39.31H167.79" stroke="black" stroke-width="0.5" />
    <path d="M168.18 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M168.18 38.02H167.79" stroke="black" stroke-width="0.5" />
    <path d="M178.63 39.31H178.24" stroke="black" stroke-width="0.5" />
    <path d="M178.24 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M178.24 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M178.24 39.31H178.63" stroke="black" stroke-width="0.5" />
    <path d="M178.24 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M178.24 38.02V38.18" stroke="black" stroke-width="0.5" />
    <path d="M178.24 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M178.24 38.02H178.63" stroke="black" stroke-width="0.5" />
    <path d="M178.63 38.02H178.24" stroke="black" stroke-width="0.5" />
    <path d="M178.24 38.02H178.63" stroke="black" stroke-width="0.5" />
    <path d="M199.17 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M199.17 38.02H178.24" stroke="black" stroke-width="0.5" />
    <path d="M93.28 38.18V39.31" stroke="black" stroke-width="0.5" />
    <path d="M93.28 39.31H121.71" stroke="black" stroke-width="0.5" />
    <path d="M131.76 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M131.76 39.31H155.28" stroke="black" stroke-width="0.5" />
    <path d="M165.33 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M165.33 39.31H168.18" stroke="black" stroke-width="0.5" />
    <path d="M178.24 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M178.24 39.31H179.35" stroke="black" stroke-width="0.5" />
    <path d="M179.35 39.31V44.32" stroke="black" stroke-width="0.5" />
    <path d="M179.35 44.32H179.51" stroke="black" stroke-width="0.5" />
    <path d="M179.51 44.32H179.35" stroke="black" stroke-width="0.5" />
    <path d="M179.35 44.32V43.92" stroke="black" stroke-width="0.5" />
    <path d="M179.35 43.92V44.32" stroke="black" stroke-width="0.5" />
    <path d="M180.64 44.32V43.92" stroke="black" stroke-width="0.5" />
    <path d="M180.64 43.92V44.32" stroke="black" stroke-width="0.5" />
    <path d="M180.64 44.32H180.47" stroke="black" stroke-width="0.5" />
    <path d="M180.47 44.32H180.64" stroke="black" stroke-width="0.5" />
    <path d="M180.64 44.32V39.31" stroke="black" stroke-width="0.5" />
    <path d="M180.64 39.31H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M121.77 38.02H121.71" stroke="black" stroke-width="0.5" />
    <path d="M121.71 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M121.71 39.31H121.77" stroke="black" stroke-width="0.5" />
    <path d="M121.77 39.31H121.97" stroke="black" stroke-width="0.5" />
    <path d="M121.97 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M121.97 38.02H121.77" stroke="black" stroke-width="0.5" />
    <path d="M131.69 39.31H131.49" stroke="black" stroke-width="0.5" />
    <path d="M131.49 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M131.49 38.02H131.69" stroke="black" stroke-width="0.5" />
    <path d="M131.69 38.02H131.76" stroke="black" stroke-width="0.5" />
    <path d="M131.76 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M131.76 39.31H131.69" stroke="black" stroke-width="0.5" />
    <path d="M121.77 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M131.69 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M131.49 28.5V38.02" stroke="black" stroke-width="0.5" />
    <path
      d="M131.49 28.5C128.965 28.5008 126.544 29.504 124.759 31.2892C122.974 33.0744 121.971 35.4954 121.97 38.02"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M131.76 39.31H132.16" stroke="black" stroke-width="0.5" />
    <path d="M155.35 38.02H155.28" stroke="black" stroke-width="0.5" />
    <path d="M155.28 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M155.28 39.31H155.35" stroke="black" stroke-width="0.5" />
    <path d="M155.35 39.31H155.55" stroke="black" stroke-width="0.5" />
    <path d="M155.55 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M155.55 38.02H155.35" stroke="black" stroke-width="0.5" />
    <path d="M165.27 39.31H165.07" stroke="black" stroke-width="0.5" />
    <path d="M165.07 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M165.07 38.02H165.27" stroke="black" stroke-width="0.5" />
    <path d="M165.27 38.02H165.33" stroke="black" stroke-width="0.5" />
    <path d="M165.33 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M165.33 39.31H165.27" stroke="black" stroke-width="0.5" />
    <path d="M155.35 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M165.27 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M165.07 28.5V38.02" stroke="black" stroke-width="0.5" />
    <path
      d="M165.07 28.5C162.545 28.5008 160.124 29.504 158.339 31.2892C156.554 33.0744 155.551 35.4954 155.55 38.02"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M165.33 39.31H165.73" stroke="black" stroke-width="0.5" />
    <path d="M178.17 38.02H178.24" stroke="black" stroke-width="0.5" />
    <path d="M178.24 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M178.24 39.31H178.17" stroke="black" stroke-width="0.5" />
    <path d="M178.17 39.31H177.97" stroke="black" stroke-width="0.5" />
    <path d="M177.97 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M177.97 38.02H178.17" stroke="black" stroke-width="0.5" />
    <path d="M168.25 39.31H168.45" stroke="black" stroke-width="0.5" />
    <path d="M168.45 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M168.45 38.02H168.25" stroke="black" stroke-width="0.5" />
    <path d="M168.25 38.02H168.18" stroke="black" stroke-width="0.5" />
    <path d="M168.18 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M168.18 39.31H168.25" stroke="black" stroke-width="0.5" />
    <path d="M178.17 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M168.25 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M168.45 28.5V38.02" stroke="black" stroke-width="0.5" />
    <path
      d="M177.97 38.02C177.969 35.4954 176.966 33.0744 175.181 31.2892C173.396 29.504 170.975 28.5008 168.45 28.5"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M178.24 39.31H178.63" stroke="black" stroke-width="0.5" />
    <path d="M41.76 39.31H42.16" stroke="black" stroke-width="0.5" />
    <path d="M42.16 39.31H41.76" stroke="black" stroke-width="0.5" />
    <path d="M41.76 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M41.76 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M41.76 39.31H43.99" stroke="black" stroke-width="0.5" />
    <path d="M43.99 39.31V5.88" stroke="black" stroke-width="0.5" />
    <path d="M43.99 5.88H43.83" stroke="black" stroke-width="0.5" />
    <path d="M42.87 5.88H42.7" stroke="black" stroke-width="0.5" />
    <path d="M42.7 5.88V38.02" stroke="black" stroke-width="0.5" />
    <path d="M42.7 38.02H41.76" stroke="black" stroke-width="0.5" />
    <path d="M41.76 38.02V38.18" stroke="black" stroke-width="0.5" />
    <path d="M41.76 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M41.76 38.02H42.16" stroke="black" stroke-width="0.5" />
    <path d="M42.16 38.02H41.76" stroke="black" stroke-width="0.5" />
    <path d="M60.67 64.49H52.26" stroke="black" stroke-width="0.5" />
    <path d="M52.26 64.49V65.45" stroke="black" stroke-width="0.5" />
    <path d="M52.26 65.45H60.67" stroke="black" stroke-width="0.5" />
    <path d="M60.67 65.45V64.49" stroke="black" stroke-width="0.5" />
    <path d="M36.72 64.32H37.12" stroke="black" stroke-width="0.5" />
    <path d="M37.12 64.32V64.49" stroke="black" stroke-width="0.5" />
    <path d="M37.12 64.49V64.32" stroke="black" stroke-width="0.5" />
    <path d="M37.12 64.32H36.72" stroke="black" stroke-width="0.5" />
    <path d="M36.72 65.61H37.12" stroke="black" stroke-width="0.5" />
    <path d="M37.12 65.61V65.45" stroke="black" stroke-width="0.5" />
    <path d="M37.12 65.45V65.61" stroke="black" stroke-width="0.5" />
    <path d="M37.12 65.61H36.72" stroke="black" stroke-width="0.5" />
    <path d="M52.26 64.49V64.32" stroke="black" stroke-width="0.5" />
    <path d="M52.26 64.32V64.49" stroke="black" stroke-width="0.5" />
    <path d="M52.26 64.49V64.32" stroke="black" stroke-width="0.5" />
    <path d="M52.26 64.32H52.66" stroke="black" stroke-width="0.5" />
    <path d="M52.66 64.32H52.26" stroke="black" stroke-width="0.5" />
    <path d="M52.26 64.32H52.66" stroke="black" stroke-width="0.5" />
    <path d="M52.26 65.45V65.61" stroke="black" stroke-width="0.5" />
    <path d="M52.26 65.61V65.45" stroke="black" stroke-width="0.5" />
    <path d="M52.26 65.45V65.61" stroke="black" stroke-width="0.5" />
    <path d="M52.26 65.61H52.66" stroke="black" stroke-width="0.5" />
    <path d="M52.66 65.61H52.26" stroke="black" stroke-width="0.5" />
    <path d="M52.26 65.61H52.66" stroke="black" stroke-width="0.5" />
    <path d="M36.16 113.09V112.52" stroke="black" stroke-width="0.5" />
    <path d="M36.16 112.52V65.61" stroke="black" stroke-width="0.5" />
    <path d="M36.16 65.61H37.12" stroke="black" stroke-width="0.5" />
    <path d="M37.12 65.61V65.45" stroke="black" stroke-width="0.5" />
    <path d="M52.26 65.61H60.67" stroke="black" stroke-width="0.5" />
    <path d="M60.67 65.61V65.45" stroke="black" stroke-width="0.5" />
    <path d="M60.67 64.49V64.32" stroke="black" stroke-width="0.5" />
    <path d="M60.67 64.32H52.26" stroke="black" stroke-width="0.5" />
    <path d="M37.12 64.49V64.32" stroke="black" stroke-width="0.5" />
    <path d="M37.12 64.32H34.87" stroke="black" stroke-width="0.5" />
    <path d="M34.87 64.32V73.14" stroke="black" stroke-width="0.5" />
    <path d="M34.87 73.14H34.52" stroke="black" stroke-width="0.5" />
    <path d="M34.52 73.14V73.3" stroke="black" stroke-width="0.5" />
    <path d="M34.52 73.3V73.14" stroke="black" stroke-width="0.5" />
    <path d="M34.52 73.14H34.91" stroke="black" stroke-width="0.5" />
    <path d="M34.91 73.14H34.52" stroke="black" stroke-width="0.5" />
    <path d="M52.2 65.61H52.26" stroke="black" stroke-width="0.5" />
    <path d="M52.26 65.61V64.32" stroke="black" stroke-width="0.5" />
    <path d="M52.26 64.32H52.2" stroke="black" stroke-width="0.5" />
    <path d="M52.2 64.32H52" stroke="black" stroke-width="0.5" />
    <path d="M52 64.32V65.61" stroke="black" stroke-width="0.5" />
    <path d="M52 65.61H52.2" stroke="black" stroke-width="0.5" />
    <path d="M37.18 64.32H37.38" stroke="black" stroke-width="0.5" />
    <path d="M37.38 64.32V65.61" stroke="black" stroke-width="0.5" />
    <path d="M37.38 65.61H37.18" stroke="black" stroke-width="0.5" />
    <path d="M37.18 65.61H37.12" stroke="black" stroke-width="0.5" />
    <path d="M37.12 65.61V64.32" stroke="black" stroke-width="0.5" />
    <path d="M37.12 64.32H37.18" stroke="black" stroke-width="0.5" />
    <path d="M52.2 65.61V64.32" stroke="black" stroke-width="0.5" />
    <path d="M48.69 64.32H48.49" stroke="black" stroke-width="0.5" />
    <path d="M48.49 64.32V65.61" stroke="black" stroke-width="0.5" />
    <path d="M48.49 65.61H48.69" stroke="black" stroke-width="0.5" />
    <path d="M48.69 65.61V64.32" stroke="black" stroke-width="0.5" />
    <path d="M37.18 64.32V65.61" stroke="black" stroke-width="0.5" />
    <path d="M37.38 76.72V65.61" stroke="black" stroke-width="0.5" />
    <path d="M52 65.61H48.69" stroke="black" stroke-width="0.5" />
    <path
      d="M37.38 76.72C40.3266 76.72 43.1524 75.5495 45.236 73.4659C47.3195 71.3824 48.49 68.5565 48.49 65.61"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M52.26 64.49V65.45" stroke="black" stroke-width="0.5" />
    <path d="M52.26 65.45V64.49" stroke="black" stroke-width="0.5" />
    <path d="M37.12 64.49V65.45" stroke="black" stroke-width="0.5" />
    <path d="M37.12 65.45V64.49" stroke="black" stroke-width="0.5" />
    <path d="M37.12 65.61H36.72" stroke="black" stroke-width="0.5" />
    <path d="M37.12 64.32H36.72" stroke="black" stroke-width="0.5" />
    <path d="M34.52 74.43H34.91" stroke="black" stroke-width="0.5" />
    <path d="M34.91 74.43H34.52" stroke="black" stroke-width="0.5" />
    <path d="M34.52 74.43V74.26" stroke="black" stroke-width="0.5" />
    <path d="M34.52 74.26V74.43" stroke="black" stroke-width="0.5" />
    <path d="M34.52 74.43H34.87" stroke="black" stroke-width="0.5" />
    <path d="M34.87 74.43V112.52" stroke="black" stroke-width="0.5" />
    <path d="M34.87 112.52V113.09" stroke="black" stroke-width="0.5" />
    <path d="M143.6 75.55H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 75.55V86.34" stroke="black" stroke-width="0.5" />
    <path d="M143.43 86.34H117.7" stroke="black" stroke-width="0.5" />
    <path d="M117.7 86.34V64.74" stroke="black" stroke-width="0.5" />
    <path d="M117.7 64.74H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 64.74V65.5" stroke="black" stroke-width="0.5" />
    <path d="M143.43 65.5V65.1" stroke="black" stroke-width="0.5" />
    <path d="M143.43 65.1V65.5" stroke="black" stroke-width="0.5" />
    <path d="M143.43 65.5V65.1" stroke="black" stroke-width="0.5" />
    <path d="M144.56 55.2H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 55.2V63.45" stroke="black" stroke-width="0.5" />
    <path d="M143.43 63.45H117.7" stroke="black" stroke-width="0.5" />
    <path d="M117.7 63.45V55.47" stroke="black" stroke-width="0.5" />
    <path d="M117.7 55.47H117.53" stroke="black" stroke-width="0.5" />
    <path d="M144.72 88.45V88.84" stroke="black" stroke-width="0.5" />
    <path d="M144.72 88.84H144.56" stroke="black" stroke-width="0.5" />
    <path d="M144.56 88.84H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 88.84V88.45" stroke="black" stroke-width="0.5" />
    <path d="M143.43 88.45V88.84" stroke="black" stroke-width="0.5" />
    <path d="M143.43 88.84H143.6" stroke="black" stroke-width="0.5" />
    <path d="M143.6 88.84H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 88.84V88.45" stroke="black" stroke-width="0.5" />
    <path d="M143.6 98.9H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 98.9H143.6" stroke="black" stroke-width="0.5" />
    <path d="M143.6 98.9H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 98.9V99.29" stroke="black" stroke-width="0.5" />
    <path d="M143.43 99.29V98.9" stroke="black" stroke-width="0.5" />
    <path d="M143.43 98.9V99.29" stroke="black" stroke-width="0.5" />
    <path d="M144.56 65.5H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 65.5H144.56" stroke="black" stroke-width="0.5" />
    <path d="M144.56 65.5H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 65.5V65.1" stroke="black" stroke-width="0.5" />
    <path d="M144.72 65.1V65.5" stroke="black" stroke-width="0.5" />
    <path d="M144.72 65.5V65.1" stroke="black" stroke-width="0.5" />
    <path d="M143.6 65.5H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 65.5H143.6" stroke="black" stroke-width="0.5" />
    <path d="M144.56 75.55H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 75.55H144.56" stroke="black" stroke-width="0.5" />
    <path d="M144.56 75.55H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 75.55V75.95" stroke="black" stroke-width="0.5" />
    <path d="M144.72 75.95V75.55" stroke="black" stroke-width="0.5" />
    <path d="M144.72 75.55V75.95" stroke="black" stroke-width="0.5" />
    <path d="M143.43 75.95V75.55" stroke="black" stroke-width="0.5" />
    <path d="M143.43 75.55H143.6" stroke="black" stroke-width="0.5" />
    <path d="M143.6 75.55H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 75.55V75.95" stroke="black" stroke-width="0.5" />
    <path d="M115.67 65.45L115.68 65.62" stroke="black" stroke-width="0.5" />
    <path d="M115.68 65.62H116.41" stroke="black" stroke-width="0.5" />
    <path d="M116.41 65.62V88.09" stroke="black" stroke-width="0.5" />
    <path d="M116.41 88.09V88.25" stroke="black" stroke-width="0.5" />
    <path d="M118.33 88.25H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 88.25V88.84" stroke="black" stroke-width="0.5" />
    <path d="M143.43 88.84H143.6" stroke="black" stroke-width="0.5" />
    <path d="M143.43 98.9V112.52" stroke="black" stroke-width="0.5" />
    <path d="M143.43 112.52V113.09" stroke="black" stroke-width="0.5" />
    <path d="M144.72 114.42V112.52" stroke="black" stroke-width="0.5" />
    <path d="M144.72 112.52V104.32" stroke="black" stroke-width="0.5" />
    <path d="M144.72 104.32H149.2" stroke="black" stroke-width="0.5" />
    <path d="M149.2 104.32V104.15" stroke="black" stroke-width="0.5" />
    <path d="M149.2 104.15V104.32" stroke="black" stroke-width="0.5" />
    <path d="M149.2 104.32H148.8" stroke="black" stroke-width="0.5" />
    <path d="M148.8 104.32H149.2" stroke="black" stroke-width="0.5" />
    <path d="M149.2 103.03H148.8" stroke="black" stroke-width="0.5" />
    <path d="M148.8 103.03H149.2" stroke="black" stroke-width="0.5" />
    <path d="M149.2 103.03V103.19" stroke="black" stroke-width="0.5" />
    <path d="M149.2 103.19V103.03" stroke="black" stroke-width="0.5" />
    <path d="M149.2 103.03H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 103.03V98.9" stroke="black" stroke-width="0.5" />
    <path d="M144.72 98.9H144.56" stroke="black" stroke-width="0.5" />
    <path d="M144.56 88.84H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 88.84V75.55" stroke="black" stroke-width="0.5" />
    <path d="M144.72 65.5V55.2" stroke="black" stroke-width="0.5" />
    <path d="M144.72 55.2H144.56" stroke="black" stroke-width="0.5" />
    <path d="M143.43 98.83V98.9" stroke="black" stroke-width="0.5" />
    <path d="M143.43 98.9H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 98.9V98.83" stroke="black" stroke-width="0.5" />
    <path d="M144.72 98.83V98.63" stroke="black" stroke-width="0.5" />
    <path d="M144.72 98.63H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 98.63V98.83" stroke="black" stroke-width="0.5" />
    <path d="M144.72 88.91V89.11" stroke="black" stroke-width="0.5" />
    <path d="M144.72 89.11H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 89.11V88.91" stroke="black" stroke-width="0.5" />
    <path d="M143.43 88.91V88.84" stroke="black" stroke-width="0.5" />
    <path d="M143.43 88.84H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 88.84V88.91" stroke="black" stroke-width="0.5" />
    <path d="M143.43 98.83H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 88.91H143.43" stroke="black" stroke-width="0.5" />
    <path d="M133.91 89.11H143.43" stroke="black" stroke-width="0.5" />
    <path
      d="M133.91 89.11C133.911 91.6346 134.914 94.0556 136.699 95.8408C138.484 97.6259 140.905 98.6292 143.43 98.63"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M143.43 88.84V88.45" stroke="black" stroke-width="0.5" />
    <path d="M144.72 88.84V88.45" stroke="black" stroke-width="0.5" />
    <path d="M143.43 75.48V75.55" stroke="black" stroke-width="0.5" />
    <path d="M143.43 75.55H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 75.55V75.48" stroke="black" stroke-width="0.5" />
    <path d="M144.72 75.48V75.28" stroke="black" stroke-width="0.5" />
    <path d="M144.72 75.28H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 75.28V75.48" stroke="black" stroke-width="0.5" />
    <path d="M144.72 65.56V65.76" stroke="black" stroke-width="0.5" />
    <path d="M144.72 65.76H143.43" stroke="black" stroke-width="0.5" />
    <path d="M143.43 65.76V65.56" stroke="black" stroke-width="0.5" />
    <path d="M143.43 65.56V65.5" stroke="black" stroke-width="0.5" />
    <path d="M143.43 65.5H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 65.5V65.56" stroke="black" stroke-width="0.5" />
    <path d="M143.43 75.48H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.72 65.56H143.43" stroke="black" stroke-width="0.5" />
    <path d="M133.91 65.76H143.43" stroke="black" stroke-width="0.5" />
    <path
      d="M133.91 65.76C133.911 68.2846 134.914 70.7056 136.699 72.4908C138.484 74.276 140.905 75.2792 143.43 75.28"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M143.43 75.55V75.95" stroke="black" stroke-width="0.5" />
    <path d="M143.43 65.5H143.6" stroke="black" stroke-width="0.5" />
    <path d="M41.76 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M41.76 39.31H42.16" stroke="black" stroke-width="0.5" />
    <path d="M41.76 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M41.76 38.02H42.16" stroke="black" stroke-width="0.5" />
    <path d="M31.31 39.31H31.71" stroke="black" stroke-width="0.5" />
    <path d="M31.71 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M31.71 39.14V39.31" stroke="black" stroke-width="0.5" />
    <path d="M31.71 39.31H31.31" stroke="black" stroke-width="0.5" />
    <path d="M31.71 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M31.71 38.02V38.18" stroke="black" stroke-width="0.5" />
    <path d="M31.71 38.18V38.02" stroke="black" stroke-width="0.5" />
    <path d="M31.71 38.02H31.31" stroke="black" stroke-width="0.5" />
    <path d="M31.31 38.02H31.71" stroke="black" stroke-width="0.5" />
    <path d="M31.71 38.02H31.31" stroke="black" stroke-width="0.5" />
    <path d="M31.71 38.02H1.5" stroke="black" stroke-width="0.5" />
    <path d="M1.5 38.02H0.580017" stroke="black" stroke-width="0.5" />
    <path d="M0.580017 39.31H1.5" stroke="black" stroke-width="0.5" />
    <path d="M1.5 39.31H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 39.31V42.82" stroke="black" stroke-width="0.5" />
    <path d="M21.42 42.82H21.59" stroke="black" stroke-width="0.5" />
    <path d="M21.59 42.82H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 42.82V42.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 42.42V42.82" stroke="black" stroke-width="0.5" />
    <path d="M22.71 42.82V42.42" stroke="black" stroke-width="0.5" />
    <path d="M22.71 42.42V42.82" stroke="black" stroke-width="0.5" />
    <path d="M22.71 42.82H22.55" stroke="black" stroke-width="0.5" />
    <path d="M22.55 42.82H22.71" stroke="black" stroke-width="0.5" />
    <path d="M22.71 42.82V39.31" stroke="black" stroke-width="0.5" />
    <path d="M22.71 39.31H31.71" stroke="black" stroke-width="0.5" />
    <path d="M31.71 39.31V39.14" stroke="black" stroke-width="0.5" />
    <path d="M31.77 38.02H31.71" stroke="black" stroke-width="0.5" />
    <path d="M31.71 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M31.71 39.31H31.77" stroke="black" stroke-width="0.5" />
    <path d="M31.77 39.31H31.97" stroke="black" stroke-width="0.5" />
    <path d="M31.97 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M31.97 38.02H31.77" stroke="black" stroke-width="0.5" />
    <path d="M41.69 39.31H41.5" stroke="black" stroke-width="0.5" />
    <path d="M41.5 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M41.5 38.02H41.69" stroke="black" stroke-width="0.5" />
    <path d="M41.69 38.02H41.76" stroke="black" stroke-width="0.5" />
    <path d="M41.76 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M41.76 39.31H41.69" stroke="black" stroke-width="0.5" />
    <path d="M31.77 38.02V39.31" stroke="black" stroke-width="0.5" />
    <path d="M41.69 39.31V38.02" stroke="black" stroke-width="0.5" />
    <path d="M41.5 28.5V38.02" stroke="black" stroke-width="0.5" />
    <path
      d="M41.5 28.5C40.2491 28.4991 39.0102 28.7446 37.8542 29.2226C36.6983 29.7006 35.6478 30.4016 34.7628 31.2857C33.8778 32.1698 33.1756 33.2195 32.6964 34.375C32.2172 35.5305 31.9704 36.7691 31.97 38.02"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M31.71 39.31H31.31" stroke="black" stroke-width="0.5" />
    <path d="M41.76 39.14V38.18" stroke="black" stroke-width="0.5" />
    <path d="M41.76 38.18V39.14" stroke="black" stroke-width="0.5" />
    <path d="M34.52 73.3V73.14" stroke="black" stroke-width="0.5" />
    <path d="M34.52 73.14H34.91" stroke="black" stroke-width="0.5" />
    <path d="M34.52 74.26V74.43" stroke="black" stroke-width="0.5" />
    <path d="M34.52 74.43H34.91" stroke="black" stroke-width="0.5" />
    <path d="M24.07 73.14H24.46" stroke="black" stroke-width="0.5" />
    <path d="M24.46 73.14V73.3" stroke="black" stroke-width="0.5" />
    <path d="M24.46 73.3V73.14" stroke="black" stroke-width="0.5" />
    <path d="M24.46 73.14H24.07" stroke="black" stroke-width="0.5" />
    <path d="M24.46 74.26V74.43" stroke="black" stroke-width="0.5" />
    <path d="M24.46 74.43V74.26" stroke="black" stroke-width="0.5" />
    <path d="M24.46 74.26V74.43" stroke="black" stroke-width="0.5" />
    <path d="M24.46 74.43H24.07" stroke="black" stroke-width="0.5" />
    <path d="M24.07 74.43H24.46" stroke="black" stroke-width="0.5" />
    <path d="M24.46 74.43H24.07" stroke="black" stroke-width="0.5" />
    <path d="M0.580017 74.43H1.5" stroke="black" stroke-width="0.5" />
    <path d="M1.5 74.43H24.46" stroke="black" stroke-width="0.5" />
    <path d="M24.46 73.3V73.14" stroke="black" stroke-width="0.5" />
    <path d="M24.46 73.14H22.71" stroke="black" stroke-width="0.5" />
    <path d="M22.71 73.14V52.87" stroke="black" stroke-width="0.5" />
    <path d="M22.71 52.87H22.55" stroke="black" stroke-width="0.5" />
    <path d="M22.55 52.87H22.71" stroke="black" stroke-width="0.5" />
    <path d="M22.71 52.87V53.27" stroke="black" stroke-width="0.5" />
    <path d="M22.71 53.27V52.87" stroke="black" stroke-width="0.5" />
    <path d="M21.42 52.87V53.27" stroke="black" stroke-width="0.5" />
    <path d="M21.42 53.27V52.87" stroke="black" stroke-width="0.5" />
    <path d="M21.42 52.87H21.59" stroke="black" stroke-width="0.5" />
    <path d="M21.59 52.87H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 52.87V73.14" stroke="black" stroke-width="0.5" />
    <path d="M21.42 73.14H1.5" stroke="black" stroke-width="0.5" />
    <path d="M1.5 73.14H0.580017" stroke="black" stroke-width="0.5" />
    <path d="M24.53 74.43H24.46" stroke="black" stroke-width="0.5" />
    <path d="M24.46 74.43V73.14" stroke="black" stroke-width="0.5" />
    <path d="M24.46 73.14H24.53" stroke="black" stroke-width="0.5" />
    <path d="M24.53 73.14H24.73" stroke="black" stroke-width="0.5" />
    <path d="M24.73 73.14V74.43" stroke="black" stroke-width="0.5" />
    <path d="M24.73 74.43H24.53" stroke="black" stroke-width="0.5" />
    <path d="M34.45 73.14H34.25" stroke="black" stroke-width="0.5" />
    <path d="M34.25 73.14V74.43" stroke="black" stroke-width="0.5" />
    <path d="M34.25 74.43H34.45" stroke="black" stroke-width="0.5" />
    <path d="M34.45 74.43H34.52" stroke="black" stroke-width="0.5" />
    <path d="M34.52 74.43V73.14" stroke="black" stroke-width="0.5" />
    <path d="M34.52 73.14H34.45" stroke="black" stroke-width="0.5" />
    <path d="M24.53 74.43V73.14" stroke="black" stroke-width="0.5" />
    <path d="M34.45 73.14V74.43" stroke="black" stroke-width="0.5" />
    <path d="M34.25 83.95V74.43" stroke="black" stroke-width="0.5" />
    <path
      d="M24.73 74.43C24.7308 76.9546 25.7341 79.3756 27.5192 81.1608C29.3044 82.946 31.7254 83.9492 34.25 83.95"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M24.46 73.14H24.07" stroke="black" stroke-width="0.5" />
    <path d="M21.59 42.82H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 42.82V42.42" stroke="black" stroke-width="0.5" />
    <path d="M22.55 42.82H22.71" stroke="black" stroke-width="0.5" />
    <path d="M22.71 42.82V42.42" stroke="black" stroke-width="0.5" />
    <path d="M21.59 52.87H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 52.87V53.27" stroke="black" stroke-width="0.5" />
    <path d="M22.55 52.87H22.71" stroke="black" stroke-width="0.5" />
    <path d="M22.71 52.87V53.27" stroke="black" stroke-width="0.5" />
    <path d="M22.71 52.81V52.87" stroke="black" stroke-width="0.5" />
    <path d="M22.71 52.87H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 52.87V52.81" stroke="black" stroke-width="0.5" />
    <path d="M21.42 52.81V52.61" stroke="black" stroke-width="0.5" />
    <path d="M21.42 52.61H22.71" stroke="black" stroke-width="0.5" />
    <path d="M22.71 52.61V52.81" stroke="black" stroke-width="0.5" />
    <path d="M21.42 42.89V43.08" stroke="black" stroke-width="0.5" />
    <path d="M21.42 43.08H22.71" stroke="black" stroke-width="0.5" />
    <path d="M22.71 43.08V42.89" stroke="black" stroke-width="0.5" />
    <path d="M22.71 42.89V42.82" stroke="black" stroke-width="0.5" />
    <path d="M22.71 42.82H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 42.82V42.89" stroke="black" stroke-width="0.5" />
    <path d="M22.71 52.81H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 42.89H22.71" stroke="black" stroke-width="0.5" />
    <path d="M32.23 43.08H22.71" stroke="black" stroke-width="0.5" />
    <path
      d="M22.71 52.61C23.9609 52.6096 25.1995 52.3628 26.355 51.8836C27.5105 51.4044 28.5603 50.7023 29.4443 49.8173C30.3284 48.9323 31.0294 47.8818 31.5074 46.7258C31.9854 45.5698 32.2309 44.3309 32.23 43.08"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M180.47 54.37H180.64" stroke="black" stroke-width="0.5" />
    <path d="M180.64 54.37V54.77" stroke="black" stroke-width="0.5" />
    <path d="M179.35 54.37H179.51" stroke="black" stroke-width="0.5" />
    <path d="M179.51 54.37H179.35" stroke="black" stroke-width="0.5" />
    <path d="M179.51 44.32H179.35" stroke="black" stroke-width="0.5" />
    <path d="M179.35 44.32V43.92" stroke="black" stroke-width="0.5" />
    <path d="M180.47 44.32H180.64" stroke="black" stroke-width="0.5" />
    <path d="M180.64 44.32V43.92" stroke="black" stroke-width="0.5" />
    <path d="M179.35 44.38V44.32" stroke="black" stroke-width="0.5" />
    <path d="M179.35 44.32H180.64" stroke="black" stroke-width="0.5" />
    <path d="M180.64 44.32V44.38" stroke="black" stroke-width="0.5" />
    <path d="M180.64 44.38V44.58" stroke="black" stroke-width="0.5" />
    <path d="M180.64 44.58H179.35" stroke="black" stroke-width="0.5" />
    <path d="M179.35 44.58V44.38" stroke="black" stroke-width="0.5" />
    <path d="M180.64 54.3V54.11" stroke="black" stroke-width="0.5" />
    <path d="M180.64 54.11H179.35" stroke="black" stroke-width="0.5" />
    <path d="M179.35 54.11V54.3" stroke="black" stroke-width="0.5" />
    <path d="M179.35 54.3V54.37" stroke="black" stroke-width="0.5" />
    <path d="M179.35 54.37H180.64" stroke="black" stroke-width="0.5" />
    <path d="M180.64 54.37V54.3" stroke="black" stroke-width="0.5" />
    <path d="M179.35 44.38H180.64" stroke="black" stroke-width="0.5" />
    <path d="M180.64 54.3H179.35" stroke="black" stroke-width="0.5" />
    <path d="M169.82 54.11H179.35" stroke="black" stroke-width="0.5" />
    <path
      d="M179.35 44.58C178.098 44.5791 176.859 44.825 175.702 45.3036C174.545 45.7822 173.494 46.4841 172.609 47.3692C171.724 48.2544 171.022 49.3053 170.544 50.462C170.065 51.6186 169.819 52.8583 169.82 54.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M179.51 54.37H179.35" stroke="black" stroke-width="0.5" />
    <path d="M160.76 56.94H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 56.94V56.55" stroke="black" stroke-width="0.5" />
    <path d="M161.72 56.94H161.88" stroke="black" stroke-width="0.5" />
    <path d="M161.88 56.94V56.55" stroke="black" stroke-width="0.5" />
    <path d="M160.59 67.39V66.99" stroke="black" stroke-width="0.5" />
    <path d="M160.59 66.99H160.76" stroke="black" stroke-width="0.5" />
    <path d="M160.76 66.99H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 66.99V67.39" stroke="black" stroke-width="0.5" />
    <path d="M161.88 67.39V66.99" stroke="black" stroke-width="0.5" />
    <path d="M161.88 66.99H161.72" stroke="black" stroke-width="0.5" />
    <path d="M161.72 66.99H161.88" stroke="black" stroke-width="0.5" />
    <path d="M161.88 66.99V67.39" stroke="black" stroke-width="0.5" />
    <path d="M160.76 66.99H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 66.99V86.8" stroke="black" stroke-width="0.5" />
    <path d="M160.76 96.85H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 96.85V103.03" stroke="black" stroke-width="0.5" />
    <path d="M160.59 103.03H159.25" stroke="black" stroke-width="0.5" />
    <path d="M159.25 103.03V103.19" stroke="black" stroke-width="0.5" />
    <path d="M159.25 103.19V103.03" stroke="black" stroke-width="0.5" />
    <path d="M159.25 103.03H159.65" stroke="black" stroke-width="0.5" />
    <path d="M159.65 103.03H159.25" stroke="black" stroke-width="0.5" />
    <path d="M159.25 104.32H159.65" stroke="black" stroke-width="0.5" />
    <path d="M159.65 104.32H159.25" stroke="black" stroke-width="0.5" />
    <path d="M159.25 104.32V104.15" stroke="black" stroke-width="0.5" />
    <path d="M159.25 104.15V104.32" stroke="black" stroke-width="0.5" />
    <path d="M159.25 104.32H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 104.32V112.52" stroke="black" stroke-width="0.5" />
    <path d="M160.59 112.52V114.42" stroke="black" stroke-width="0.5" />
    <path d="M161.88 113.09V96.85" stroke="black" stroke-width="0.5" />
    <path d="M161.88 86.8V66.99" stroke="black" stroke-width="0.5" />
    <path d="M161.88 66.99H161.72" stroke="black" stroke-width="0.5" />
    <path d="M161.88 66.93V66.99" stroke="black" stroke-width="0.5" />
    <path d="M161.88 66.99H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 66.99V66.93" stroke="black" stroke-width="0.5" />
    <path d="M160.59 66.93V66.73" stroke="black" stroke-width="0.5" />
    <path d="M160.59 66.73H161.88" stroke="black" stroke-width="0.5" />
    <path d="M161.88 66.73V66.93" stroke="black" stroke-width="0.5" />
    <path d="M160.59 57.01V57.21" stroke="black" stroke-width="0.5" />
    <path d="M160.59 57.21H161.88" stroke="black" stroke-width="0.5" />
    <path d="M161.88 57.21V57.01" stroke="black" stroke-width="0.5" />
    <path d="M161.88 57.01V56.94" stroke="black" stroke-width="0.5" />
    <path d="M161.88 56.94H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 56.94V57.01" stroke="black" stroke-width="0.5" />
    <path d="M161.88 66.93H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 57.01H161.88" stroke="black" stroke-width="0.5" />
    <path d="M171.41 57.21H161.88" stroke="black" stroke-width="0.5" />
    <path
      d="M161.88 66.73C163.131 66.7309 164.37 66.4854 165.526 66.0074C166.682 65.5294 167.732 64.8284 168.617 63.9443C169.502 63.0603 170.204 62.0105 170.684 60.855C171.163 59.6995 171.41 58.4609 171.41 57.21"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M160.76 66.99H161.72" stroke="black" stroke-width="0.5" />
    <path d="M161.72 66.99H160.76" stroke="black" stroke-width="0.5" />
    <path d="M161.88 66.99V67.39" stroke="black" stroke-width="0.5" />
    <path d="M160.59 66.99V67.39" stroke="black" stroke-width="0.5" />
    <path d="M161.88 96.79V96.85" stroke="black" stroke-width="0.5" />
    <path d="M161.88 96.85H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 96.85V96.79" stroke="black" stroke-width="0.5" />
    <path d="M160.59 96.79V96.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 96.59H161.88" stroke="black" stroke-width="0.5" />
    <path d="M161.88 96.59V96.79" stroke="black" stroke-width="0.5" />
    <path d="M160.59 86.87V87.07" stroke="black" stroke-width="0.5" />
    <path d="M160.59 87.07H161.88" stroke="black" stroke-width="0.5" />
    <path d="M161.88 87.07V86.87" stroke="black" stroke-width="0.5" />
    <path d="M161.88 86.87V86.8" stroke="black" stroke-width="0.5" />
    <path d="M161.88 86.8H160.59" stroke="black" stroke-width="0.5" />
    <path d="M160.59 86.8V86.87" stroke="black" stroke-width="0.5" />
    <path d="M171.41 87.07H161.88" stroke="black" stroke-width="0.5" />
    <path
      d="M161.88 96.59C163.131 96.5909 164.37 96.3454 165.526 95.8674C166.682 95.3894 167.732 94.6884 168.617 93.8043C169.502 92.9203 170.204 91.8705 170.684 90.715C171.163 89.5595 171.41 88.3209 171.41 87.07"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M161.88 84.42V84.59" stroke="black" stroke-width="0.5" />
    <path d="M161.88 84.59H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 84.59V84.42" stroke="black" stroke-width="0.5" />
    <path d="M199.17 83.46V83.3" stroke="black" stroke-width="0.5" />
    <path d="M199.17 83.3H161.88" stroke="black" stroke-width="0.5" />
    <path d="M161.88 83.3V83.46" stroke="black" stroke-width="0.5" />
    <path d="M149.2 103.19V103.03" stroke="black" stroke-width="0.5" />
    <path d="M149.2 103.03H148.8" stroke="black" stroke-width="0.5" />
    <path d="M149.2 104.15V104.32" stroke="black" stroke-width="0.5" />
    <path d="M149.2 104.32H148.8" stroke="black" stroke-width="0.5" />
    <path d="M149.26 104.32H149.2" stroke="black" stroke-width="0.5" />
    <path d="M149.2 104.32V103.03" stroke="black" stroke-width="0.5" />
    <path d="M149.2 103.03H149.26" stroke="black" stroke-width="0.5" />
    <path d="M149.26 103.03H149.46" stroke="black" stroke-width="0.5" />
    <path d="M149.46 103.03V104.32" stroke="black" stroke-width="0.5" />
    <path d="M149.46 104.32H149.26" stroke="black" stroke-width="0.5" />
    <path d="M159.18 103.03H158.98" stroke="black" stroke-width="0.5" />
    <path d="M158.98 103.03V104.32" stroke="black" stroke-width="0.5" />
    <path d="M158.98 104.32H159.18" stroke="black" stroke-width="0.5" />
    <path d="M159.18 104.32H159.25" stroke="black" stroke-width="0.5" />
    <path d="M159.25 104.32V103.03" stroke="black" stroke-width="0.5" />
    <path d="M159.25 103.03H159.18" stroke="black" stroke-width="0.5" />
    <path d="M149.26 104.32V103.03" stroke="black" stroke-width="0.5" />
    <path d="M159.18 103.03V104.32" stroke="black" stroke-width="0.5" />
    <path d="M158.98 113.84V104.32" stroke="black" stroke-width="0.5" />
    <path
      d="M149.46 104.32C149.461 106.845 150.464 109.266 152.249 111.051C154.034 112.836 156.455 113.839 158.98 113.84"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M116.07 64.33H115.67" stroke="black" stroke-width="0.5" />
    <path d="M115.67 64.33V64.49" stroke="black" stroke-width="0.5" />
    <path d="M115.67 64.49V64.33" stroke="black" stroke-width="0.5" />
    <path d="M115.67 64.33H116.07" stroke="black" stroke-width="0.5" />
    <path d="M116.07 65.62H115.68" stroke="black" stroke-width="0.5" />
    <path d="M115.68 65.62L115.67 65.45" stroke="black" stroke-width="0.5" />
    <path d="M115.67 65.45L115.68 65.62" stroke="black" stroke-width="0.5" />
    <path d="M115.68 65.62H116.07" stroke="black" stroke-width="0.5" />
    <path d="M100.53 64.5V64.33" stroke="black" stroke-width="0.5" />
    <path d="M100.53 64.33V64.5" stroke="black" stroke-width="0.5" />
    <path d="M100.53 64.5V64.33" stroke="black" stroke-width="0.5" />
    <path d="M100.53 64.33H100.13" stroke="black" stroke-width="0.5" />
    <path d="M100.13 64.33H100.53" stroke="black" stroke-width="0.5" />
    <path d="M100.53 64.33H100.13" stroke="black" stroke-width="0.5" />
    <path d="M100.53 65.46V65.62" stroke="black" stroke-width="0.5" />
    <path d="M100.53 65.62V65.46" stroke="black" stroke-width="0.5" />
    <path d="M100.53 65.46V65.62" stroke="black" stroke-width="0.5" />
    <path d="M100.53 65.62H100.13" stroke="black" stroke-width="0.5" />
    <path d="M100.13 65.62H100.53" stroke="black" stroke-width="0.5" />
    <path d="M100.53 65.62H100.13" stroke="black" stroke-width="0.5" />
    <path d="M90.23 65.46V65.63" stroke="black" stroke-width="0.5" />
    <path d="M90.23 65.63L100.53 65.62" stroke="black" stroke-width="0.5" />
    <path d="M117.53 55.47H116.41" stroke="black" stroke-width="0.5" />
    <path d="M116.41 55.47V64.33" stroke="black" stroke-width="0.5" />
    <path d="M116.41 64.33H115.67" stroke="black" stroke-width="0.5" />
    <path d="M115.67 64.33V64.49" stroke="black" stroke-width="0.5" />
    <path d="M100.53 64.33L90.23 64.34" stroke="black" stroke-width="0.5" />
    <path d="M90.23 64.34V64.5" stroke="black" stroke-width="0.5" />
    <path d="M100.6 65.62H100.53" stroke="black" stroke-width="0.5" />
    <path d="M100.53 65.62V64.33" stroke="black" stroke-width="0.5" />
    <path d="M100.53 64.33H100.6" stroke="black" stroke-width="0.5" />
    <path d="M100.6 64.33H100.79" stroke="black" stroke-width="0.5" />
    <path d="M100.79 64.33L100.8 65.62" stroke="black" stroke-width="0.5" />
    <path d="M100.8 65.62H100.6" stroke="black" stroke-width="0.5" />
    <path d="M115.61 64.33H115.41" stroke="black" stroke-width="0.5" />
    <path d="M115.41 64.33V65.62" stroke="black" stroke-width="0.5" />
    <path d="M115.41 65.62H115.61" stroke="black" stroke-width="0.5" />
    <path d="M115.61 65.62H115.68" stroke="black" stroke-width="0.5" />
    <path d="M115.68 65.62L115.67 64.33" stroke="black" stroke-width="0.5" />
    <path d="M115.67 64.33H115.61" stroke="black" stroke-width="0.5" />
    <path d="M100.6 65.62V64.33" stroke="black" stroke-width="0.5" />
    <path d="M104.1 64.33H104.3" stroke="black" stroke-width="0.5" />
    <path d="M104.3 64.33V65.62" stroke="black" stroke-width="0.5" />
    <path d="M104.3 65.62H104.1" stroke="black" stroke-width="0.5" />
    <path d="M104.1 65.62V64.33" stroke="black" stroke-width="0.5" />
    <path d="M115.61 64.33V65.62" stroke="black" stroke-width="0.5" />
    <path d="M115.42 76.73L115.41 65.62" stroke="black" stroke-width="0.5" />
    <path d="M100.8 65.62H104.1" stroke="black" stroke-width="0.5" />
    <path
      d="M104.3 65.62C104.3 67.0798 104.588 68.5253 105.147 69.8739C105.706 71.2225 106.525 72.4477 107.558 73.4795C108.59 74.5113 109.816 75.3294 111.165 75.8872C112.514 76.4449 113.96 76.7313 115.42 76.73"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M100.53 64.5V65.46" stroke="black" stroke-width="0.5" />
    <path d="M100.53 65.46V64.5" stroke="black" stroke-width="0.5" />
    <path d="M115.67 64.49V65.45" stroke="black" stroke-width="0.5" />
    <path d="M115.67 65.45V64.49" stroke="black" stroke-width="0.5" />
    <path d="M115.68 65.62H116.07" stroke="black" stroke-width="0.5" />
    <path d="M115.67 64.33H116.07" stroke="black" stroke-width="0.5" />
    <path d="M116.41 88.25V113.09" stroke="black" stroke-width="0.5" />
    <path d="M116.41 113.09H117.7" stroke="black" stroke-width="0.5" />
    <path d="M117.7 113.09V88.25" stroke="black" stroke-width="0.5" />
    <path d="M117.7 88.25H118.33" stroke="black" stroke-width="0.5" />
    <path d="M102.69 8.04001V8.09" stroke="black" stroke-width="0.5" />
    <path d="M102.69 8.09L102.73 8.12" stroke="black" stroke-width="0.5" />
    <path d="M102.73 8.12H102.78" stroke="black" stroke-width="0.5" />
    <path
      d="M102.78 8.12002L102.81 8.08002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.81 8.08V8.03" stroke="black" stroke-width="0.5" />
    <path d="M102.81 8.03L102.78 8" stroke="black" stroke-width="0.5" />
    <path d="M102.78 8H102.77" stroke="black" stroke-width="0.5" />
    <path d="M102.77 8V6.97" stroke="black" stroke-width="0.5" />
    <path d="M102.77 6.97H102.73" stroke="black" stroke-width="0.5" />
    <path d="M102.73 6.97V8" stroke="black" stroke-width="0.5" />
    <path d="M102.73 8L102.7 8.01" stroke="black" stroke-width="0.5" />
    <path d="M102.7 8.01001L102.69 8.04001" stroke="black" stroke-width="0.5" />
    <path d="M102.78 10.11H102.77" stroke="black" stroke-width="0.5" />
    <path d="M102.77 10.11V11.14" stroke="black" stroke-width="0.5" />
    <path d="M102.77 11.14H102.73" stroke="black" stroke-width="0.5" />
    <path d="M102.73 11.14V10.11" stroke="black" stroke-width="0.5" />
    <path d="M102.73 10.11L102.7 10.09" stroke="black" stroke-width="0.5" />
    <path d="M102.7 10.09L102.69 10.06" stroke="black" stroke-width="0.5" />
    <path d="M102.69 10.06V10.02" stroke="black" stroke-width="0.5" />
    <path d="M102.69 10.02L102.71 9.98999" stroke="black" stroke-width="0.5" />
    <path
      d="M102.71 9.99001L102.74 9.98001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.74 9.98001H102.77" stroke="black" stroke-width="0.5" />
    <path d="M102.77 9.98001L102.8 10" stroke="black" stroke-width="0.5" />
    <path d="M102.8 10L102.82 10.03" stroke="black" stroke-width="0.5" />
    <path d="M102.82 10.03V10.07" stroke="black" stroke-width="0.5" />
    <path d="M102.82 10.07L102.8 10.09" stroke="black" stroke-width="0.5" />
    <path d="M102.8 10.09L102.78 10.11" stroke="black" stroke-width="0.5" />
    <path d="M107.88 5.88V20.69" stroke="black" stroke-width="0.5" />
    <path d="M107.88 20.69H101.26" stroke="black" stroke-width="0.5" />
    <path d="M101.26 20.69V5.88" stroke="black" stroke-width="0.5" />
    <path d="M101.26 5.88H107.88" stroke="black" stroke-width="0.5" />
    <path d="M103.12 10.97L103.18 11.2" stroke="black" stroke-width="0.5" />
    <path d="M103.18 11.2L103.35 11.37" stroke="black" stroke-width="0.5" />
    <path d="M103.35 11.37L103.58 11.43" stroke="black" stroke-width="0.5" />
    <path d="M103.58 11.43H106.62" stroke="black" stroke-width="0.5" />
    <path d="M106.62 11.43L106.85 11.37" stroke="black" stroke-width="0.5" />
    <path d="M106.85 11.37L107.02 11.2" stroke="black" stroke-width="0.5" />
    <path d="M107.02 11.2L107.08 10.97" stroke="black" stroke-width="0.5" />
    <path d="M107.08 10.97V7.13" stroke="black" stroke-width="0.5" />
    <path
      d="M107.08 7.12999L107.02 6.89999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M107.02 6.90001L106.85 6.73001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M106.85 6.72999L106.62 6.66998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M106.62 6.66998H103.58" stroke="black" stroke-width="0.5" />
    <path
      d="M103.58 6.66998L103.35 6.72999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M103.35 6.73001L103.18 6.90001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M103.18 6.89999L103.12 7.12999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M103.12 7.13V10.97" stroke="black" stroke-width="0.5" />
    <path d="M107.02 7.19999L106.88 6.87" stroke="black" stroke-width="0.5" />
    <path d="M106.88 6.87L106.55 6.73999" stroke="black" stroke-width="0.5" />
    <path d="M106.55 6.73999H103.64" stroke="black" stroke-width="0.5" />
    <path d="M103.64 6.73999L103.32 6.87" stroke="black" stroke-width="0.5" />
    <path d="M103.32 6.87L103.18 7.19999" stroke="black" stroke-width="0.5" />
    <path d="M103.18 7.20001V10.9" stroke="black" stroke-width="0.5" />
    <path d="M103.18 10.9L103.32 11.23" stroke="black" stroke-width="0.5" />
    <path d="M103.32 11.23L103.64 11.37" stroke="black" stroke-width="0.5" />
    <path d="M103.64 11.37H106.55" stroke="black" stroke-width="0.5" />
    <path d="M106.55 11.37L106.88 11.23" stroke="black" stroke-width="0.5" />
    <path d="M106.88 11.23L107.02 10.9" stroke="black" stroke-width="0.5" />
    <path d="M107.02 10.9V7.20001" stroke="black" stroke-width="0.5" />
    <path
      d="M104.38 9.14001L104.42 9.10001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.42 9.10001L104.43 9.04001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.43 9.03999L104.41 8.98999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M104.41 8.99L104.34 8.94" stroke="black" stroke-width="0.5" />
    <path d="M104.34 8.94L104.25 8.96" stroke="black" stroke-width="0.5" />
    <path d="M104.25 8.95999H102.74" stroke="black" stroke-width="0.5" />
    <path d="M102.74 8.95999L102.7 8.96999" stroke="black" stroke-width="0.5" />
    <path d="M102.7 8.97L102.68 8.99" stroke="black" stroke-width="0.5" />
    <path
      d="M102.68 8.98999L102.66 9.01999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.66 9.01999V9.05" stroke="black" stroke-width="0.5" />
    <path d="M102.66 9.04999V9.07999" stroke="black" stroke-width="0.5" />
    <path
      d="M102.66 9.08002L102.68 9.11002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.68 9.10999L102.7 9.12998" stroke="black" stroke-width="0.5" />
    <path d="M102.7 9.13L102.74 9.14001" stroke="black" stroke-width="0.5" />
    <path d="M102.74 9.14001H104.25" stroke="black" stroke-width="0.5" />
    <path
      d="M104.25 9.14001L104.31 9.16002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.31 9.16002L104.38 9.14001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M104.97 17.87V20.69" stroke="black" stroke-width="0.5" />
    <path d="M104.97 20.69H101.26" stroke="black" stroke-width="0.5" />
    <path d="M101.26 20.69V17.87" stroke="black" stroke-width="0.5" />
    <path d="M101.26 15.05V12.23" stroke="black" stroke-width="0.5" />
    <path d="M101.26 9.41002V8.70001" stroke="black" stroke-width="0.5" />
    <path d="M101.26 8.7V5.88" stroke="black" stroke-width="0.5" />
    <path d="M101.26 5.88H104.97" stroke="black" stroke-width="0.5" />
    <path d="M104.97 5.88V8.7" stroke="black" stroke-width="0.5" />
    <path d="M104.97 8.70001V9.41002" stroke="black" stroke-width="0.5" />
    <path d="M104.97 15.05V12.23" stroke="black" stroke-width="0.5" />
    <path d="M135.72 8.04001V8.09" stroke="black" stroke-width="0.5" />
    <path d="M135.72 8.09L135.76 8.12" stroke="black" stroke-width="0.5" />
    <path d="M135.76 8.12L135.8 8.12999" stroke="black" stroke-width="0.5" />
    <path d="M135.8 8.12998L135.83 8.10999" stroke="black" stroke-width="0.5" />
    <path
      d="M135.83 8.11002L135.85 8.08002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.85 8.08V8.03" stroke="black" stroke-width="0.5" />
    <path d="M135.85 8.03L135.81 8" stroke="black" stroke-width="0.5" />
    <path d="M135.81 8V6.97" stroke="black" stroke-width="0.5" />
    <path d="M135.81 6.97H135.76" stroke="black" stroke-width="0.5" />
    <path d="M135.76 6.97V8" stroke="black" stroke-width="0.5" />
    <path d="M135.76 8L135.74 8.01" stroke="black" stroke-width="0.5" />
    <path
      d="M135.74 8.01001L135.72 8.04001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.81 10.11V11.14" stroke="black" stroke-width="0.5" />
    <path d="M135.81 11.14H135.76" stroke="black" stroke-width="0.5" />
    <path d="M135.76 11.14V10.11" stroke="black" stroke-width="0.5" />
    <path d="M135.76 10.11L135.72 10.07" stroke="black" stroke-width="0.5" />
    <path d="M135.72 10.07V10.02" stroke="black" stroke-width="0.5" />
    <path d="M135.72 10.02L135.76 9.98001" stroke="black" stroke-width="0.5" />
    <path d="M135.76 9.98001H135.81" stroke="black" stroke-width="0.5" />
    <path d="M135.81 9.98001L135.85 10.01" stroke="black" stroke-width="0.5" />
    <path d="M135.85 10.01V10.07" stroke="black" stroke-width="0.5" />
    <path d="M135.85 10.07L135.83 10.09" stroke="black" stroke-width="0.5" />
    <path d="M135.83 10.09L135.81 10.11" stroke="black" stroke-width="0.5" />
    <path d="M140.91 5.88V20.69" stroke="black" stroke-width="0.5" />
    <path d="M140.91 20.69H134.3" stroke="black" stroke-width="0.5" />
    <path d="M134.3 20.69V5.88" stroke="black" stroke-width="0.5" />
    <path d="M134.3 5.88H140.91" stroke="black" stroke-width="0.5" />
    <path d="M136.15 10.97L136.21 11.2" stroke="black" stroke-width="0.5" />
    <path d="M136.21 11.2L136.38 11.37" stroke="black" stroke-width="0.5" />
    <path d="M136.38 11.37L136.61 11.43" stroke="black" stroke-width="0.5" />
    <path d="M136.61 11.43H139.65" stroke="black" stroke-width="0.5" />
    <path d="M139.65 11.43L139.89 11.37" stroke="black" stroke-width="0.5" />
    <path d="M139.89 11.37L140.05 11.2" stroke="black" stroke-width="0.5" />
    <path d="M140.05 11.2L140.12 10.97" stroke="black" stroke-width="0.5" />
    <path d="M140.12 10.97V7.13" stroke="black" stroke-width="0.5" />
    <path
      d="M140.12 7.12999L140.05 6.89999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M140.05 6.90001L139.89 6.73001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M139.89 6.72999L139.65 6.66998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M139.65 6.66998H136.61" stroke="black" stroke-width="0.5" />
    <path
      d="M136.61 6.66998L136.38 6.72999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M136.38 6.73001L136.21 6.90001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M136.21 6.89999L136.15 7.12999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M136.15 7.13V10.97" stroke="black" stroke-width="0.5" />
    <path d="M140.05 7.19999L139.92 6.87" stroke="black" stroke-width="0.5" />
    <path d="M139.92 6.87L139.59 6.73999" stroke="black" stroke-width="0.5" />
    <path d="M139.59 6.73999H136.68" stroke="black" stroke-width="0.5" />
    <path d="M136.68 6.73999L136.35 6.87" stroke="black" stroke-width="0.5" />
    <path d="M136.35 6.87L136.21 7.19999" stroke="black" stroke-width="0.5" />
    <path d="M136.21 7.20001V10.9" stroke="black" stroke-width="0.5" />
    <path d="M136.21 10.9L136.35 11.23" stroke="black" stroke-width="0.5" />
    <path d="M136.35 11.23L136.68 11.37" stroke="black" stroke-width="0.5" />
    <path d="M136.68 11.37H139.59" stroke="black" stroke-width="0.5" />
    <path d="M139.59 11.37L139.92 11.23" stroke="black" stroke-width="0.5" />
    <path d="M139.92 11.23L140.05 10.9" stroke="black" stroke-width="0.5" />
    <path d="M140.05 10.9V7.20001" stroke="black" stroke-width="0.5" />
    <path
      d="M137.41 9.14001L137.45 9.10001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.45 9.10001L137.46 9.04001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.46 9.03999L137.44 8.98999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M137.44 8.99L137.37 8.94" stroke="black" stroke-width="0.5" />
    <path d="M137.37 8.94L137.28 8.96" stroke="black" stroke-width="0.5" />
    <path d="M137.28 8.95999H135.77" stroke="black" stroke-width="0.5" />
    <path
      d="M135.77 8.95999L135.74 8.96999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.74 8.97L135.71 8.99" stroke="black" stroke-width="0.5" />
    <path d="M135.71 8.98999L135.7 9.01999" stroke="black" stroke-width="0.5" />
    <path d="M135.7 9.01999L135.69 9.05" stroke="black" stroke-width="0.5" />
    <path d="M135.69 9.04999L135.7 9.07999" stroke="black" stroke-width="0.5" />
    <path d="M135.7 9.08002L135.71 9.11002" stroke="black" stroke-width="0.5" />
    <path
      d="M135.71 9.10999L135.74 9.12998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.74 9.13L135.77 9.14001" stroke="black" stroke-width="0.5" />
    <path d="M135.77 9.14001H137.28" stroke="black" stroke-width="0.5" />
    <path
      d="M137.28 9.14001L137.35 9.16002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.35 9.16002L137.41 9.14001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M138 17.87V20.69" stroke="black" stroke-width="0.5" />
    <path d="M138 20.69H134.3" stroke="black" stroke-width="0.5" />
    <path d="M134.3 20.69V17.87" stroke="black" stroke-width="0.5" />
    <path d="M134.3 9.41002V8.70001" stroke="black" stroke-width="0.5" />
    <path d="M134.3 8.7V5.88" stroke="black" stroke-width="0.5" />
    <path d="M134.3 5.88H138" stroke="black" stroke-width="0.5" />
    <path d="M138 5.88V8.7" stroke="black" stroke-width="0.5" />
    <path d="M138 8.70001V9.41002" stroke="black" stroke-width="0.5" />
    <path d="M138 15.05V12.23" stroke="black" stroke-width="0.5" />
    <path d="M197.06 36.51H198.08" stroke="black" stroke-width="0.5" />
    <path d="M198.08 36.51V36.55" stroke="black" stroke-width="0.5" />
    <path d="M198.08 36.55H197.06" stroke="black" stroke-width="0.5" />
    <path d="M197.06 36.55L197.04 36.58" stroke="black" stroke-width="0.5" />
    <path d="M197.04 36.58L197.01 36.6" stroke="black" stroke-width="0.5" />
    <path d="M197.01 36.6L196.97 36.59" stroke="black" stroke-width="0.5" />
    <path d="M196.97 36.59L196.94 36.58" stroke="black" stroke-width="0.5" />
    <path d="M196.94 36.58L196.93 36.54" stroke="black" stroke-width="0.5" />
    <path d="M196.93 36.54V36.51" stroke="black" stroke-width="0.5" />
    <path d="M196.93 36.51L196.95 36.48" stroke="black" stroke-width="0.5" />
    <path d="M196.95 36.48L196.98 36.46" stroke="black" stroke-width="0.5" />
    <path d="M196.98 36.46L197.01 36.47" stroke="black" stroke-width="0.5" />
    <path d="M197.01 36.47L197.04 36.48" stroke="black" stroke-width="0.5" />
    <path d="M197.04 36.48L197.06 36.51" stroke="black" stroke-width="0.5" />
    <path d="M194.98 36.59L195.02 36.6" stroke="black" stroke-width="0.5" />
    <path d="M195.02 36.6L195.05 36.58" stroke="black" stroke-width="0.5" />
    <path d="M195.05 36.58L195.07 36.56" stroke="black" stroke-width="0.5" />
    <path d="M195.07 36.56V36.5" stroke="black" stroke-width="0.5" />
    <path d="M195.07 36.5L195.03 36.47" stroke="black" stroke-width="0.5" />
    <path d="M195.03 36.47L195 36.46" stroke="black" stroke-width="0.5" />
    <path d="M195 36.46L194.97 36.48" stroke="black" stroke-width="0.5" />
    <path d="M194.97 36.48L194.95 36.51" stroke="black" stroke-width="0.5" />
    <path d="M194.95 36.51H194.94" stroke="black" stroke-width="0.5" />
    <path d="M194.94 36.51H193.92" stroke="black" stroke-width="0.5" />
    <path d="M193.92 36.51V36.55" stroke="black" stroke-width="0.5" />
    <path d="M193.92 36.55H194.94" stroke="black" stroke-width="0.5" />
    <path d="M194.94 36.55L194.96 36.58" stroke="black" stroke-width="0.5" />
    <path d="M194.96 36.58L194.98 36.59" stroke="black" stroke-width="0.5" />
    <path d="M199.17 31.41H184.36" stroke="black" stroke-width="0.5" />
    <path d="M184.36 31.41V38.02" stroke="black" stroke-width="0.5" />
    <path d="M184.36 38.02H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 38.02V31.41" stroke="black" stroke-width="0.5" />
    <path d="M194.08 36.17L193.76 36.03" stroke="black" stroke-width="0.5" />
    <path d="M193.76 36.03L193.62 35.7" stroke="black" stroke-width="0.5" />
    <path d="M193.62 35.7V32.66" stroke="black" stroke-width="0.5" />
    <path d="M193.62 32.66L193.76 32.33" stroke="black" stroke-width="0.5" />
    <path d="M193.76 32.33L194.08 32.2" stroke="black" stroke-width="0.5" />
    <path d="M194.08 32.2H197.92" stroke="black" stroke-width="0.5" />
    <path d="M197.92 32.2L198.25 32.33" stroke="black" stroke-width="0.5" />
    <path d="M198.25 32.33L198.38 32.66" stroke="black" stroke-width="0.5" />
    <path d="M198.38 32.66V35.7" stroke="black" stroke-width="0.5" />
    <path d="M198.38 35.7L198.25 36.03" stroke="black" stroke-width="0.5" />
    <path d="M198.25 36.03L197.92 36.17" stroke="black" stroke-width="0.5" />
    <path d="M197.92 36.17H194.08" stroke="black" stroke-width="0.5" />
    <path d="M197.85 32.27L198.18 32.4" stroke="black" stroke-width="0.5" />
    <path d="M198.18 32.4L198.32 32.73" stroke="black" stroke-width="0.5" />
    <path d="M198.32 32.73V35.64" stroke="black" stroke-width="0.5" />
    <path d="M198.32 35.64L198.25 35.87" stroke="black" stroke-width="0.5" />
    <path d="M198.25 35.87L198.08 36.04" stroke="black" stroke-width="0.5" />
    <path d="M198.08 36.04L197.85 36.1" stroke="black" stroke-width="0.5" />
    <path d="M197.85 36.1H194.15" stroke="black" stroke-width="0.5" />
    <path d="M194.15 36.1L193.92 36.04" stroke="black" stroke-width="0.5" />
    <path d="M193.92 36.04L193.75 35.87" stroke="black" stroke-width="0.5" />
    <path d="M193.75 35.87L193.69 35.64" stroke="black" stroke-width="0.5" />
    <path d="M193.69 35.64V32.73" stroke="black" stroke-width="0.5" />
    <path d="M193.69 32.73L193.82 32.4" stroke="black" stroke-width="0.5" />
    <path d="M193.82 32.4L194.15 32.27" stroke="black" stroke-width="0.5" />
    <path d="M194.15 32.27H197.85" stroke="black" stroke-width="0.5" />
    <path d="M196.09 35.03V36.55" stroke="black" stroke-width="0.5" />
    <path d="M196.09 36.55L196.08 36.58" stroke="black" stroke-width="0.5" />
    <path d="M196.08 36.58L196.06 36.6" stroke="black" stroke-width="0.5" />
    <path d="M196.06 36.6L196.03 36.62" stroke="black" stroke-width="0.5" />
    <path d="M196.03 36.62H196" stroke="black" stroke-width="0.5" />
    <path d="M196 36.62H195.97" stroke="black" stroke-width="0.5" />
    <path d="M195.97 36.62L195.94 36.6" stroke="black" stroke-width="0.5" />
    <path d="M195.94 36.6L195.92 36.58" stroke="black" stroke-width="0.5" />
    <path d="M195.92 36.58L195.91 36.55" stroke="black" stroke-width="0.5" />
    <path d="M195.91 36.55V35.03" stroke="black" stroke-width="0.5" />
    <path d="M195.91 35.03L195.89 34.98" stroke="black" stroke-width="0.5" />
    <path d="M195.89 34.98L195.9 34.92" stroke="black" stroke-width="0.5" />
    <path d="M195.9 34.92L195.94 34.88" stroke="black" stroke-width="0.5" />
    <path d="M195.94 34.88L196.02 34.86" stroke="black" stroke-width="0.5" />
    <path d="M196.02 34.86L196.09 34.9" stroke="black" stroke-width="0.5" />
    <path d="M196.09 34.9L196.11 34.97" stroke="black" stroke-width="0.5" />
    <path d="M196.11 34.97L196.09 35.03" stroke="black" stroke-width="0.5" />
    <path d="M187.18 34.32H184.36" stroke="black" stroke-width="0.5" />
    <path d="M184.36 34.32V38.02" stroke="black" stroke-width="0.5" />
    <path d="M184.36 38.02H187.18" stroke="black" stroke-width="0.5" />
    <path d="M190 38.02H192.83" stroke="black" stroke-width="0.5" />
    <path d="M195.65 38.02H196.35" stroke="black" stroke-width="0.5" />
    <path d="M196.35 38.02H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 38.02V34.32" stroke="black" stroke-width="0.5" />
    <path d="M199.17 34.32H196.35" stroke="black" stroke-width="0.5" />
    <path d="M196.35 34.32H195.65" stroke="black" stroke-width="0.5" />
    <path d="M190 34.32H192.83" stroke="black" stroke-width="0.5" />
    <path d="M41.19 8V6.97" stroke="black" stroke-width="0.5" />
    <path d="M41.19 6.97H41.24" stroke="black" stroke-width="0.5" />
    <path d="M41.24 6.97V8" stroke="black" stroke-width="0.5" />
    <path d="M41.24 8L41.27 8.02" stroke="black" stroke-width="0.5" />
    <path d="M41.27 8.01999L41.28 8.05" stroke="black" stroke-width="0.5" />
    <path d="M41.28 8.04999V8.07999" stroke="black" stroke-width="0.5" />
    <path d="M41.28 8.08002L41.26 8.11002" stroke="black" stroke-width="0.5" />
    <path d="M41.26 8.10999L41.23 8.12998" stroke="black" stroke-width="0.5" />
    <path d="M41.23 8.12999L41.19 8.12" stroke="black" stroke-width="0.5" />
    <path d="M41.19 8.11999L41.16 8.10999" stroke="black" stroke-width="0.5" />
    <path d="M41.16 8.11001L41.15 8.07001" stroke="black" stroke-width="0.5" />
    <path d="M41.15 8.07001V8.04001" stroke="black" stroke-width="0.5" />
    <path d="M41.15 8.04001L41.17 8.01001" stroke="black" stroke-width="0.5" />
    <path d="M41.17 8.01L41.19 8" stroke="black" stroke-width="0.5" />
    <path d="M41.28 10.07V10.03" stroke="black" stroke-width="0.5" />
    <path d="M41.28 10.03L41.27 10" stroke="black" stroke-width="0.5" />
    <path d="M41.27 10L41.24 9.98001" stroke="black" stroke-width="0.5" />
    <path d="M41.24 9.98001H41.19" stroke="black" stroke-width="0.5" />
    <path d="M41.19 9.98001L41.15 10.02" stroke="black" stroke-width="0.5" />
    <path d="M41.15 10.02V10.07" stroke="black" stroke-width="0.5" />
    <path d="M41.15 10.07L41.19 10.11" stroke="black" stroke-width="0.5" />
    <path d="M41.19 10.11V11.14" stroke="black" stroke-width="0.5" />
    <path d="M41.19 11.14H41.24" stroke="black" stroke-width="0.5" />
    <path d="M41.24 11.14V10.11" stroke="black" stroke-width="0.5" />
    <path d="M41.24 10.11L41.26 10.09" stroke="black" stroke-width="0.5" />
    <path d="M41.26 10.09L41.28 10.07" stroke="black" stroke-width="0.5" />
    <path d="M36.09 5.88V20.69" stroke="black" stroke-width="0.5" />
    <path d="M36.09 20.69H42.7" stroke="black" stroke-width="0.5" />
    <path d="M42.7 20.69V5.88" stroke="black" stroke-width="0.5" />
    <path d="M42.7 5.88H36.09" stroke="black" stroke-width="0.5" />
    <path d="M40.85 10.97L40.72 11.3" stroke="black" stroke-width="0.5" />
    <path d="M40.72 11.3L40.39 11.43" stroke="black" stroke-width="0.5" />
    <path d="M40.39 11.43H37.35" stroke="black" stroke-width="0.5" />
    <path d="M37.35 11.43L37.11 11.37" stroke="black" stroke-width="0.5" />
    <path d="M37.11 11.37L36.95 11.2" stroke="black" stroke-width="0.5" />
    <path d="M36.95 11.2L36.88 10.97" stroke="black" stroke-width="0.5" />
    <path d="M36.88 10.97V7.13" stroke="black" stroke-width="0.5" />
    <path d="M36.88 7.12999L36.95 6.89999" stroke="black" stroke-width="0.5" />
    <path d="M36.95 6.90001L37.11 6.73001" stroke="black" stroke-width="0.5" />
    <path d="M37.11 6.72999L37.35 6.66998" stroke="black" stroke-width="0.5" />
    <path d="M37.35 6.66998H40.39" stroke="black" stroke-width="0.5" />
    <path d="M40.39 6.66998L40.72 6.80998" stroke="black" stroke-width="0.5" />
    <path d="M40.72 6.81L40.85 7.13" stroke="black" stroke-width="0.5" />
    <path d="M40.85 7.13V10.97" stroke="black" stroke-width="0.5" />
    <path d="M36.95 7.19999L37.08 6.87" stroke="black" stroke-width="0.5" />
    <path d="M37.08 6.87L37.41 6.73999" stroke="black" stroke-width="0.5" />
    <path d="M37.41 6.73999H40.32" stroke="black" stroke-width="0.5" />
    <path d="M40.32 6.73999L40.65 6.87" stroke="black" stroke-width="0.5" />
    <path d="M40.65 6.87L40.78 7.19999" stroke="black" stroke-width="0.5" />
    <path d="M40.78 7.20001V10.9" stroke="black" stroke-width="0.5" />
    <path d="M40.78 10.9L40.65 11.23" stroke="black" stroke-width="0.5" />
    <path d="M40.65 11.23L40.32 11.37" stroke="black" stroke-width="0.5" />
    <path d="M40.32 11.37H37.41" stroke="black" stroke-width="0.5" />
    <path d="M37.41 11.37L37.08 11.23" stroke="black" stroke-width="0.5" />
    <path d="M37.08 11.23L36.95 10.9" stroke="black" stroke-width="0.5" />
    <path d="M36.95 10.9V7.20001" stroke="black" stroke-width="0.5" />
    <path d="M39.71 8.95999H41.23" stroke="black" stroke-width="0.5" />
    <path d="M41.23 8.95999L41.26 8.96999" stroke="black" stroke-width="0.5" />
    <path d="M41.26 8.97L41.29 8.99" stroke="black" stroke-width="0.5" />
    <path d="M41.29 8.98999L41.3 9.01999" stroke="black" stroke-width="0.5" />
    <path d="M41.3 9.01999L41.31 9.05" stroke="black" stroke-width="0.5" />
    <path d="M41.31 9.04999L41.3 9.07999" stroke="black" stroke-width="0.5" />
    <path d="M41.3 9.08002L41.29 9.11002" stroke="black" stroke-width="0.5" />
    <path d="M41.29 9.10999L41.26 9.12998" stroke="black" stroke-width="0.5" />
    <path d="M41.26 9.13L41.23 9.14001" stroke="black" stroke-width="0.5" />
    <path d="M41.23 9.14001H39.71" stroke="black" stroke-width="0.5" />
    <path d="M39.71 9.14001L39.63 9.16002" stroke="black" stroke-width="0.5" />
    <path d="M39.63 9.16L39.56 9.12" stroke="black" stroke-width="0.5" />
    <path d="M39.56 9.12L39.54 9.06" stroke="black" stroke-width="0.5" />
    <path d="M39.54 9.06L39.55 9" stroke="black" stroke-width="0.5" />
    <path d="M39.55 8.99999L39.59 8.95999" stroke="black" stroke-width="0.5" />
    <path d="M39.59 8.96L39.65 8.94" stroke="black" stroke-width="0.5" />
    <path d="M39.65 8.94L39.71 8.96" stroke="black" stroke-width="0.5" />
    <path d="M39 17.87V20.69" stroke="black" stroke-width="0.5" />
    <path d="M39 20.69H42.7" stroke="black" stroke-width="0.5" />
    <path d="M42.7 20.69V17.87" stroke="black" stroke-width="0.5" />
    <path d="M42.7 15.05V12.23" stroke="black" stroke-width="0.5" />
    <path d="M42.7 9.41002V8.70001" stroke="black" stroke-width="0.5" />
    <path d="M42.7 8.7V5.88" stroke="black" stroke-width="0.5" />
    <path d="M42.7 5.88H39" stroke="black" stroke-width="0.5" />
    <path d="M39 5.88V8.7" stroke="black" stroke-width="0.5" />
    <path d="M39 8.70001V9.41002" stroke="black" stroke-width="0.5" />
    <path d="M39 15.05V12.23" stroke="black" stroke-width="0.5" />
    <path d="M2.70001 75.94V75.93" stroke="black" stroke-width="0.5" />
    <path d="M2.70001 75.93H1.66998" stroke="black" stroke-width="0.5" />
    <path d="M1.66998 75.93V75.89" stroke="black" stroke-width="0.5" />
    <path d="M1.66998 75.89H2.70001" stroke="black" stroke-width="0.5" />
    <path d="M2.70001 75.89L2.72998 75.85" stroke="black" stroke-width="0.5" />
    <path d="M2.72998 75.85H2.78998" stroke="black" stroke-width="0.5" />
    <path d="M2.78998 75.85L2.82001 75.88" stroke="black" stroke-width="0.5" />
    <path d="M2.82001 75.88L2.83002 75.93" stroke="black" stroke-width="0.5" />
    <path d="M2.83002 75.93L2.81 75.96" stroke="black" stroke-width="0.5" />
    <path d="M2.81 75.96L2.78003 75.98" stroke="black" stroke-width="0.5" />
    <path d="M2.78003 75.98H2.73999" stroke="black" stroke-width="0.5" />
    <path d="M2.73999 75.98L2.71997 75.96" stroke="black" stroke-width="0.5" />
    <path d="M2.71997 75.96L2.70001 75.94" stroke="black" stroke-width="0.5" />
    <path d="M4.77002 75.85H4.73999" stroke="black" stroke-width="0.5" />
    <path d="M4.73999 75.85L4.71002 75.86" stroke="black" stroke-width="0.5" />
    <path d="M4.71002 75.86L4.67999 75.89" stroke="black" stroke-width="0.5" />
    <path d="M4.67999 75.89V75.92" stroke="black" stroke-width="0.5" />
    <path d="M4.67999 75.92L4.69 75.96" stroke="black" stroke-width="0.5" />
    <path d="M4.69 75.96L4.71997 75.98" stroke="black" stroke-width="0.5" />
    <path d="M4.71997 75.98H4.76001" stroke="black" stroke-width="0.5" />
    <path d="M4.76001 75.98L4.78998 75.97" stroke="black" stroke-width="0.5" />
    <path d="M4.78998 75.97L4.81 75.94" stroke="black" stroke-width="0.5" />
    <path d="M4.81 75.94V75.93" stroke="black" stroke-width="0.5" />
    <path d="M4.81 75.93H5.84003" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 75.93V75.89" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 75.89H4.81" stroke="black" stroke-width="0.5" />
    <path d="M4.81 75.89L4.79999 75.87" stroke="black" stroke-width="0.5" />
    <path d="M4.79999 75.87L4.77002 75.85" stroke="black" stroke-width="0.5" />
    <path d="M0.580017 81.04H15.39" stroke="black" stroke-width="0.5" />
    <path d="M15.39 81.04V74.43" stroke="black" stroke-width="0.5" />
    <path d="M15.39 74.43H0.580017" stroke="black" stroke-width="0.5" />
    <path d="M0.580017 74.43V81.04" stroke="black" stroke-width="0.5" />
    <path d="M5.66998 76.28L5.90002 76.34" stroke="black" stroke-width="0.5" />
    <path d="M5.90002 76.34L6.07001 76.51" stroke="black" stroke-width="0.5" />
    <path d="M6.07001 76.51L6.14001 76.74" stroke="black" stroke-width="0.5" />
    <path d="M6.14001 76.74V79.78" stroke="black" stroke-width="0.5" />
    <path d="M6.14001 79.78L6.07001 80.01" stroke="black" stroke-width="0.5" />
    <path d="M6.07001 80.01L5.90002 80.18" stroke="black" stroke-width="0.5" />
    <path d="M5.90002 80.18L5.66998 80.24" stroke="black" stroke-width="0.5" />
    <path d="M5.66998 80.24H1.84003" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 80.24L1.60999 80.18" stroke="black" stroke-width="0.5" />
    <path d="M1.60999 80.18L1.44 80.01" stroke="black" stroke-width="0.5" />
    <path d="M1.44 80.01L1.37 79.78" stroke="black" stroke-width="0.5" />
    <path d="M1.37 79.78V76.74" stroke="black" stroke-width="0.5" />
    <path d="M1.37 76.74L1.44 76.51" stroke="black" stroke-width="0.5" />
    <path d="M1.44 76.51L1.60999 76.34" stroke="black" stroke-width="0.5" />
    <path d="M1.60999 76.34L1.84003 76.28" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 76.28H5.66998" stroke="black" stroke-width="0.5" />
    <path d="M1.90002 80.18L1.58002 80.04" stroke="black" stroke-width="0.5" />
    <path d="M1.58002 80.04L1.44 79.72" stroke="black" stroke-width="0.5" />
    <path d="M1.44 79.72V76.81" stroke="black" stroke-width="0.5" />
    <path d="M1.44 76.81L1.58002 76.48" stroke="black" stroke-width="0.5" />
    <path d="M1.58002 76.48L1.90002 76.34" stroke="black" stroke-width="0.5" />
    <path d="M1.90002 76.34H5.60999" stroke="black" stroke-width="0.5" />
    <path d="M5.60999 76.34L5.84003 76.4" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 76.4L6.01001 76.57" stroke="black" stroke-width="0.5" />
    <path d="M6.01001 76.57L6.07001 76.81" stroke="black" stroke-width="0.5" />
    <path d="M6.07001 76.81V79.72" stroke="black" stroke-width="0.5" />
    <path d="M6.07001 79.72L6.01001 79.95" stroke="black" stroke-width="0.5" />
    <path d="M6.01001 79.95L5.84003 80.12" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 80.12L5.60999 80.18" stroke="black" stroke-width="0.5" />
    <path d="M5.60999 80.18H1.90002" stroke="black" stroke-width="0.5" />
    <path d="M3.65997 77.41V75.9" stroke="black" stroke-width="0.5" />
    <path d="M3.65997 75.9L3.66998 75.87" stroke="black" stroke-width="0.5" />
    <path d="M3.66998 75.87L3.70001 75.84" stroke="black" stroke-width="0.5" />
    <path d="M3.70001 75.84L3.71997 75.82" stroke="black" stroke-width="0.5" />
    <path d="M3.71997 75.82H3.76001" stroke="black" stroke-width="0.5" />
    <path d="M3.76001 75.82H3.78998" stroke="black" stroke-width="0.5" />
    <path d="M3.78998 75.82L3.82001 75.84" stroke="black" stroke-width="0.5" />
    <path d="M3.82001 75.84L3.84003 75.87" stroke="black" stroke-width="0.5" />
    <path d="M3.84003 75.87L3.84998 75.9" stroke="black" stroke-width="0.5" />
    <path d="M3.84998 75.9V77.41" stroke="black" stroke-width="0.5" />
    <path d="M3.84998 77.41L3.87 77.5" stroke="black" stroke-width="0.5" />
    <path d="M3.87 77.5L3.82001 77.57" stroke="black" stroke-width="0.5" />
    <path d="M3.82001 77.57L3.72998 77.59" stroke="black" stroke-width="0.5" />
    <path d="M3.72998 77.59L3.65997 77.54" stroke="black" stroke-width="0.5" />
    <path d="M3.65997 77.54L3.64001 77.48" stroke="black" stroke-width="0.5" />
    <path d="M3.64001 77.48L3.65997 77.41" stroke="black" stroke-width="0.5" />
    <path d="M12.57 78.13H15.39" stroke="black" stroke-width="0.5" />
    <path d="M15.39 78.13V74.43" stroke="black" stroke-width="0.5" />
    <path d="M15.39 74.43H12.57" stroke="black" stroke-width="0.5" />
    <path d="M4.10999 74.43H3.40002" stroke="black" stroke-width="0.5" />
    <path d="M3.40002 74.43H0.580017" stroke="black" stroke-width="0.5" />
    <path d="M0.580017 74.43V78.13" stroke="black" stroke-width="0.5" />
    <path d="M0.580017 78.13H3.40002" stroke="black" stroke-width="0.5" />
    <path d="M3.40002 78.13H4.10999" stroke="black" stroke-width="0.5" />
    <path d="M9.75 78.13H6.92999" stroke="black" stroke-width="0.5" />
    <path d="M197.02 57.86H196.97" stroke="black" stroke-width="0.5" />
    <path d="M196.97 57.86L196.93 57.9" stroke="black" stroke-width="0.5" />
    <path d="M196.93 57.9V57.94" stroke="black" stroke-width="0.5" />
    <path d="M196.93 57.94L196.94 57.97" stroke="black" stroke-width="0.5" />
    <path d="M196.94 57.97L196.97 57.99" stroke="black" stroke-width="0.5" />
    <path d="M196.97 57.99H197.02" stroke="black" stroke-width="0.5" />
    <path d="M197.02 57.99L197.06 57.95" stroke="black" stroke-width="0.5" />
    <path d="M197.06 57.95H198.08" stroke="black" stroke-width="0.5" />
    <path d="M198.08 57.95V57.9" stroke="black" stroke-width="0.5" />
    <path d="M198.08 57.9H197.06" stroke="black" stroke-width="0.5" />
    <path d="M197.06 57.9L197.04 57.88" stroke="black" stroke-width="0.5" />
    <path d="M197.04 57.88L197.02 57.86" stroke="black" stroke-width="0.5" />
    <path d="M194.95 57.95H194.94" stroke="black" stroke-width="0.5" />
    <path d="M194.94 57.95H193.92" stroke="black" stroke-width="0.5" />
    <path d="M193.92 57.95V57.9" stroke="black" stroke-width="0.5" />
    <path d="M193.92 57.9H194.94" stroke="black" stroke-width="0.5" />
    <path d="M194.94 57.9L194.98 57.86" stroke="black" stroke-width="0.5" />
    <path d="M194.98 57.86H195.03" stroke="black" stroke-width="0.5" />
    <path d="M195.03 57.86L195.07 57.89" stroke="black" stroke-width="0.5" />
    <path d="M195.07 57.89V57.95" stroke="black" stroke-width="0.5" />
    <path d="M195.07 57.95L195.04 57.99" stroke="black" stroke-width="0.5" />
    <path d="M195.04 57.99H194.99" stroke="black" stroke-width="0.5" />
    <path d="M194.99 57.99L194.96 57.97" stroke="black" stroke-width="0.5" />
    <path d="M194.96 57.97L194.95 57.95" stroke="black" stroke-width="0.5" />
    <path d="M199.17 63.05H184.36" stroke="black" stroke-width="0.5" />
    <path d="M184.36 63.05V56.44" stroke="black" stroke-width="0.5" />
    <path d="M184.36 56.44H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 56.44V63.05" stroke="black" stroke-width="0.5" />
    <path d="M194.08 58.29L193.85 58.35" stroke="black" stroke-width="0.5" />
    <path d="M193.85 58.35L193.68 58.52" stroke="black" stroke-width="0.5" />
    <path d="M193.68 58.52L193.62 58.75" stroke="black" stroke-width="0.5" />
    <path d="M193.62 58.75V61.79" stroke="black" stroke-width="0.5" />
    <path d="M193.62 61.79L193.68 62.03" stroke="black" stroke-width="0.5" />
    <path d="M193.68 62.03L193.85 62.19" stroke="black" stroke-width="0.5" />
    <path d="M193.85 62.19L194.08 62.26" stroke="black" stroke-width="0.5" />
    <path d="M194.08 62.26H197.92" stroke="black" stroke-width="0.5" />
    <path d="M197.92 62.26L198.15 62.19" stroke="black" stroke-width="0.5" />
    <path d="M198.15 62.19L198.32 62.03" stroke="black" stroke-width="0.5" />
    <path d="M198.32 62.03L198.38 61.79" stroke="black" stroke-width="0.5" />
    <path d="M198.38 61.79V58.75" stroke="black" stroke-width="0.5" />
    <path d="M198.38 58.75L198.32 58.52" stroke="black" stroke-width="0.5" />
    <path d="M198.32 58.52L198.15 58.35" stroke="black" stroke-width="0.5" />
    <path d="M198.15 58.35L197.92 58.29" stroke="black" stroke-width="0.5" />
    <path d="M197.92 58.29H194.08" stroke="black" stroke-width="0.5" />
    <path d="M197.85 62.19L198.08 62.13" stroke="black" stroke-width="0.5" />
    <path d="M198.08 62.13L198.25 61.96" stroke="black" stroke-width="0.5" />
    <path d="M198.25 61.96L198.32 61.73" stroke="black" stroke-width="0.5" />
    <path d="M198.32 61.73V58.82" stroke="black" stroke-width="0.5" />
    <path d="M198.32 58.82L198.25 58.59" stroke="black" stroke-width="0.5" />
    <path d="M198.25 58.59L198.08 58.42" stroke="black" stroke-width="0.5" />
    <path d="M198.08 58.42L197.85 58.35" stroke="black" stroke-width="0.5" />
    <path d="M197.85 58.35H194.15" stroke="black" stroke-width="0.5" />
    <path d="M194.15 58.35L193.92 58.42" stroke="black" stroke-width="0.5" />
    <path d="M193.92 58.42L193.75 58.59" stroke="black" stroke-width="0.5" />
    <path d="M193.75 58.59L193.69 58.82" stroke="black" stroke-width="0.5" />
    <path d="M193.69 58.82V61.73" stroke="black" stroke-width="0.5" />
    <path d="M193.69 61.73L193.75 61.96" stroke="black" stroke-width="0.5" />
    <path d="M193.75 61.96L193.92 62.13" stroke="black" stroke-width="0.5" />
    <path d="M193.92 62.13L194.15 62.19" stroke="black" stroke-width="0.5" />
    <path d="M194.15 62.19H197.85" stroke="black" stroke-width="0.5" />
    <path d="M195.91 59.55L195.95 59.59" stroke="black" stroke-width="0.5" />
    <path d="M195.95 59.59L196.01 59.6" stroke="black" stroke-width="0.5" />
    <path d="M196.01 59.6L196.06 59.58" stroke="black" stroke-width="0.5" />
    <path d="M196.06 59.58L196.1 59.54" stroke="black" stroke-width="0.5" />
    <path d="M196.1 59.54L196.11 59.48" stroke="black" stroke-width="0.5" />
    <path d="M196.11 59.48L196.09 59.42" stroke="black" stroke-width="0.5" />
    <path d="M196.09 59.42V57.91" stroke="black" stroke-width="0.5" />
    <path d="M196.09 57.91L196.08 57.88" stroke="black" stroke-width="0.5" />
    <path d="M196.08 57.88L196.06 57.85" stroke="black" stroke-width="0.5" />
    <path d="M196.06 57.85L196.03 57.84" stroke="black" stroke-width="0.5" />
    <path d="M196.03 57.84L196 57.83" stroke="black" stroke-width="0.5" />
    <path d="M196 57.83L195.97 57.84" stroke="black" stroke-width="0.5" />
    <path d="M195.97 57.84L195.94 57.85" stroke="black" stroke-width="0.5" />
    <path d="M195.94 57.85L195.92 57.88" stroke="black" stroke-width="0.5" />
    <path d="M195.92 57.88L195.91 57.91" stroke="black" stroke-width="0.5" />
    <path d="M195.91 57.91V59.42" stroke="black" stroke-width="0.5" />
    <path d="M195.91 59.42L195.89 59.49" stroke="black" stroke-width="0.5" />
    <path d="M195.89 59.49L195.91 59.55" stroke="black" stroke-width="0.5" />
    <path d="M187.18 60.14H184.36" stroke="black" stroke-width="0.5" />
    <path d="M184.36 60.14V56.44" stroke="black" stroke-width="0.5" />
    <path d="M184.36 56.44H187.18" stroke="black" stroke-width="0.5" />
    <path d="M190 56.44H192.83" stroke="black" stroke-width="0.5" />
    <path d="M195.65 56.44H196.35" stroke="black" stroke-width="0.5" />
    <path d="M196.35 56.44H199.17" stroke="black" stroke-width="0.5" />
    <path d="M199.17 56.44V60.14" stroke="black" stroke-width="0.5" />
    <path d="M199.17 60.14H196.35" stroke="black" stroke-width="0.5" />
    <path d="M196.35 60.14H195.65" stroke="black" stroke-width="0.5" />
    <path d="M190 60.14H192.83" stroke="black" stroke-width="0.5" />
    <path d="M57.29 5.88H86.38" stroke="black" stroke-width="0.5" />
    <path d="M86.38 5.88H57.29" stroke="black" stroke-width="0.5" />
    <path d="M78.29 104.42H83.58" stroke="black" stroke-width="0.5" />
    <path d="M83.58 104.42H78.29" stroke="black" stroke-width="0.5" />
    <path d="M78.29 104.42H84.11" stroke="black" stroke-width="0.5" />
    <path d="M84.11 104.42L93.53 113.84" stroke="black" stroke-width="0.5" />
    <path d="M93.53 113.84L84.11 104.42" stroke="black" stroke-width="0.5" />
    <path d="M84.11 104.42H78.29" stroke="black" stroke-width="0.5" />
    <path d="M78.29 103.09H83.58" stroke="black" stroke-width="0.5" />
    <path d="M83.58 103.09H78.29" stroke="black" stroke-width="0.5" />
    <path d="M78.29 103.76H83.58" stroke="black" stroke-width="0.5" />
    <path d="M83.58 103.76H78.29" stroke="black" stroke-width="0.5" />
    <path d="M67.7 103.09V103.13" stroke="black" stroke-width="0.5" />
    <path d="M67.7 103.13V103.09" stroke="black" stroke-width="0.5" />
    <path d="M67.7 103.09H65.62" stroke="black" stroke-width="0.5" />
    <path d="M65.62 103.09H67.7" stroke="black" stroke-width="0.5" />
    <path d="M65.62 103.13V113.09" stroke="black" stroke-width="0.5" />
    <path d="M65.62 113.09V103.13" stroke="black" stroke-width="0.5" />
    <path d="M93.03 111.1L85.03 103.1" stroke="black" stroke-width="0.5" />
    <path d="M85.03 103.1H84.11" stroke="black" stroke-width="0.5" />
    <path d="M93.03 111.1H100.43" stroke="black" stroke-width="0.5" />
    <path d="M100.43 111.1V113.09" stroke="black" stroke-width="0.5" />
    <path d="M49.99 9.06H47.17" stroke="black" stroke-width="0.5" />
    <path d="M47.17 9.06V11.88" stroke="black" stroke-width="0.5" />
    <path d="M47.17 12.97V15.79" stroke="black" stroke-width="0.5" />
    <path d="M47.17 16.88V19.7" stroke="black" stroke-width="0.5" />
    <path d="M47.17 20.79V23.61" stroke="black" stroke-width="0.5" />
    <path d="M47.17 24.7V27.52" stroke="black" stroke-width="0.5" />
    <path d="M47.17 28.61V31.44" stroke="black" stroke-width="0.5" />
    <path d="M47.17 32.53V33.11" stroke="black" stroke-width="0.5" />
    <path d="M47.17 33.11V35.93" stroke="black" stroke-width="0.5" />
    <path d="M93.98 9.06H96.8" stroke="black" stroke-width="0.5" />
    <path d="M96.8 9.06V11.88" stroke="black" stroke-width="0.5" />
    <path d="M96.8 12.97V15.79" stroke="black" stroke-width="0.5" />
    <path d="M96.8 16.88V19.7" stroke="black" stroke-width="0.5" />
    <path d="M96.8 20.79V23.61" stroke="black" stroke-width="0.5" />
    <path d="M96.8 24.7V27.52" stroke="black" stroke-width="0.5" />
    <path d="M96.8 28.61V31.44" stroke="black" stroke-width="0.5" />
    <path d="M96.8 32.53V35.2" stroke="black" stroke-width="0.5" />
    <path d="M96.8 35.2V38.02" stroke="black" stroke-width="0.5" />
    <path d="M86.38 5.88V9.06001" stroke="black" stroke-width="0.5" />
    <path d="M86.38 9.06H89.21" stroke="black" stroke-width="0.5" />
    <path d="M90.3 9.06H93.12" stroke="black" stroke-width="0.5" />
    <path d="M57.29 5.88V9.06001" stroke="black" stroke-width="0.5" />
    <path d="M57.29 9.06H54.46" stroke="black" stroke-width="0.5" />
    <path d="M53.37 9.06H50.55" stroke="black" stroke-width="0.5" />
    <path
      d="M68.23 113.94C69.4809 113.941 70.7197 113.695 71.8757 113.217C73.0317 112.739 74.0822 112.038 74.9672 111.154C75.8522 110.27 76.5544 109.22 77.0336 108.065C77.5128 106.909 77.7596 105.671 77.76 104.42"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M68.23 113.94C69.4809 113.941 70.7197 113.695 71.8757 113.217C73.0317 112.739 74.0822 112.038 74.9672 111.154C75.8522 110.27 76.5544 109.22 77.0336 108.065C77.5128 106.909 77.7596 105.671 77.76 104.42"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M83.58 104.42H84.11" stroke="black" stroke-width="0.5" />
    <path d="M84.11 104.42V103.09" stroke="black" stroke-width="0.5" />
    <path d="M84.11 103.09H83.58" stroke="black" stroke-width="0.5" />
    <path d="M83.58 103.09V104.42" stroke="black" stroke-width="0.5" />
    <path d="M68.23 104.42V114.04" stroke="black" stroke-width="0.5" />
    <path d="M68.23 114.04H68.71" stroke="black" stroke-width="0.5" />
    <path d="M68.71 114.04V104.42" stroke="black" stroke-width="0.5" />
    <path d="M68.71 104.42V114.04" stroke="black" stroke-width="0.5" />
    <path d="M68.71 114.04H68.23" stroke="black" stroke-width="0.5" />
    <path d="M68.23 114.04V104.42" stroke="black" stroke-width="0.5" />
    <path d="M68.23 104.42H67.7" stroke="black" stroke-width="0.5" />
    <path d="M67.7 104.42V103.13" stroke="black" stroke-width="0.5" />
    <path d="M67.7 103.13H68.23" stroke="black" stroke-width="0.5" />
    <path d="M68.23 103.13V104.42" stroke="black" stroke-width="0.5" />
    <path d="M68.23 104.42H68.71" stroke="black" stroke-width="0.5" />
    <path d="M68.71 104.42H68.23" stroke="black" stroke-width="0.5" />
    <path d="M68.23 104.42H67.7" stroke="black" stroke-width="0.5" />
    <path d="M67.7 104.42H66.91" stroke="black" stroke-width="0.5" />
    <path d="M66.91 104.42V113.84" stroke="black" stroke-width="0.5" />
    <path d="M66.91 113.84V114.42" stroke="black" stroke-width="0.5" />
    <path d="M66.91 114.42V113.84" stroke="black" stroke-width="0.5" />
    <path d="M66.91 113.84V104.42" stroke="black" stroke-width="0.5" />
    <path d="M66.91 104.42H67.7" stroke="black" stroke-width="0.5" />
    <path d="M67.7 104.42V103.13" stroke="black" stroke-width="0.5" />
    <path d="M67.7 103.13H68.23" stroke="black" stroke-width="0.5" />
    <path d="M68.23 103.13V104.42" stroke="black" stroke-width="0.5" />
    <path d="M78.29 103.13H77.76" stroke="black" stroke-width="0.5" />
    <path d="M77.76 103.13V104.42" stroke="black" stroke-width="0.5" />
    <path d="M77.76 104.42H78.29" stroke="black" stroke-width="0.5" />
    <path d="M78.29 104.42V103.13" stroke="black" stroke-width="0.5" />
    <path d="M83.58 104.42H84.11" stroke="black" stroke-width="0.5" />
    <path d="M84.11 104.42V103.09" stroke="black" stroke-width="0.5" />
    <path d="M84.11 103.09H83.58" stroke="black" stroke-width="0.5" />
    <path d="M83.58 103.09V104.42" stroke="black" stroke-width="0.5" />
    <path d="M78.29 103.13H77.76" stroke="black" stroke-width="0.5" />
    <path d="M77.76 103.13V104.42" stroke="black" stroke-width="0.5" />
    <path d="M77.76 104.42H78.29" stroke="black" stroke-width="0.5" />
    <path d="M78.29 104.42V103.13" stroke="black" stroke-width="0.5" />
    <path d="M101.34 7.34V6.28" stroke="black" stroke-width="0.5" />
    <path d="M101.34 6.28H101.26" stroke="black" stroke-width="0.5" />
    <path d="M101.26 6.28V7.34" stroke="black" stroke-width="0.5" />
    <path d="M101.26 7.34H101.34" stroke="black" stroke-width="0.5" />
    <path d="M102.06 7.33001V6.29001" stroke="black" stroke-width="0.5" />
    <path d="M102.06 6.29001H101.34" stroke="black" stroke-width="0.5" />
    <path d="M101.34 6.29001V7.33001" stroke="black" stroke-width="0.5" />
    <path d="M101.34 7.33002H102.06" stroke="black" stroke-width="0.5" />
    <path d="M102.02 7.31V6.31" stroke="black" stroke-width="0.5" />
    <path d="M102.02 6.31H101.38" stroke="black" stroke-width="0.5" />
    <path d="M101.38 6.31V7.31" stroke="black" stroke-width="0.5" />
    <path d="M101.38 7.31H102.02" stroke="black" stroke-width="0.5" />
    <path d="M101.26 6.28V7.34" stroke="black" stroke-width="0.5" />
    <path d="M101.26 7.34H101.34" stroke="black" stroke-width="0.5" />
    <path d="M101.34 7.34V6.28" stroke="black" stroke-width="0.5" />
    <path d="M101.34 6.28H101.26" stroke="black" stroke-width="0.5" />
    <path d="M101.34 7.33002H102.06" stroke="black" stroke-width="0.5" />
    <path d="M102.06 7.33001V6.29001" stroke="black" stroke-width="0.5" />
    <path d="M102.06 6.29001H101.34" stroke="black" stroke-width="0.5" />
    <path d="M101.38 6.31V7.31" stroke="black" stroke-width="0.5" />
    <path d="M101.38 7.31H102.02" stroke="black" stroke-width="0.5" />
    <path d="M102.02 7.31V6.31" stroke="black" stroke-width="0.5" />
    <path d="M102.02 6.31H101.38" stroke="black" stroke-width="0.5" />
    <path d="M102.32 11.12H102.39" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.12V11.13" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.13V11.15" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.15V11.16" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.16V11.18" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.18V11.2" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.2V11.22" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.22V11.25" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.25V11.27" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.27V11.29" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.29V11.31" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.31V11.33" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.33V11.35" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.35V11.36" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.36V11.37" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.37V11.38" stroke="black" stroke-width="0.5" />
    <path d="M102.39 11.38H102.32" stroke="black" stroke-width="0.5" />
    <path d="M101.26 12.67H102.32" stroke="black" stroke-width="0.5" />
    <path d="M102.32 12.67V9.83002" stroke="black" stroke-width="0.5" />
    <path d="M102.32 9.83002H101.26" stroke="black" stroke-width="0.5" />
    <path d="M101.26 9.83002V12.67" stroke="black" stroke-width="0.5" />
    <path d="M134.38 7.34V6.28" stroke="black" stroke-width="0.5" />
    <path d="M134.38 6.28H134.3" stroke="black" stroke-width="0.5" />
    <path d="M134.3 6.28V7.34" stroke="black" stroke-width="0.5" />
    <path d="M134.3 7.34H134.38" stroke="black" stroke-width="0.5" />
    <path d="M135.09 7.33001V6.29001" stroke="black" stroke-width="0.5" />
    <path d="M135.09 6.29001H134.38" stroke="black" stroke-width="0.5" />
    <path d="M134.38 6.29001V7.33001" stroke="black" stroke-width="0.5" />
    <path d="M134.38 7.33002H135.09" stroke="black" stroke-width="0.5" />
    <path d="M135.05 7.31V6.31" stroke="black" stroke-width="0.5" />
    <path d="M135.05 6.31H134.42" stroke="black" stroke-width="0.5" />
    <path d="M134.42 6.31V7.31" stroke="black" stroke-width="0.5" />
    <path d="M134.42 7.31H135.05" stroke="black" stroke-width="0.5" />
    <path d="M134.3 6.28V7.34" stroke="black" stroke-width="0.5" />
    <path d="M134.3 7.34H134.38" stroke="black" stroke-width="0.5" />
    <path d="M134.38 7.34V6.28" stroke="black" stroke-width="0.5" />
    <path d="M134.38 6.28H134.3" stroke="black" stroke-width="0.5" />
    <path d="M134.38 7.33002H135.09" stroke="black" stroke-width="0.5" />
    <path d="M135.09 7.33001V6.29001" stroke="black" stroke-width="0.5" />
    <path d="M135.09 6.29001H134.38" stroke="black" stroke-width="0.5" />
    <path d="M134.42 6.31V7.31" stroke="black" stroke-width="0.5" />
    <path d="M134.42 7.31H135.05" stroke="black" stroke-width="0.5" />
    <path d="M135.05 7.31V6.31" stroke="black" stroke-width="0.5" />
    <path d="M135.05 6.31H134.42" stroke="black" stroke-width="0.5" />
    <path d="M135.36 11.12H135.42" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.12V11.13" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.13V11.15" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.15V11.16" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.16V11.18" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.18V11.2" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.2V11.22" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.22V11.25" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.25V11.27" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.27V11.29" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.29V11.31" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.31V11.33" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.33V11.35" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.35V11.36" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.36V11.37" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.37V11.38" stroke="black" stroke-width="0.5" />
    <path d="M135.42 11.38H135.36" stroke="black" stroke-width="0.5" />
    <path d="M134.3 12.67H135.36" stroke="black" stroke-width="0.5" />
    <path d="M135.36 12.67V9.83002" stroke="black" stroke-width="0.5" />
    <path d="M135.36 9.83002H134.3" stroke="black" stroke-width="0.5" />
    <path d="M134.3 9.83002V12.67" stroke="black" stroke-width="0.5" />
    <path d="M197.71 38.02V37.94" stroke="black" stroke-width="0.5" />
    <path d="M197.71 37.94H198.77" stroke="black" stroke-width="0.5" />
    <path d="M198.77 37.94V38.02" stroke="black" stroke-width="0.5" />
    <path d="M198.77 38.02H197.71" stroke="black" stroke-width="0.5" />
    <path d="M197.72 37.94V37.23" stroke="black" stroke-width="0.5" />
    <path d="M197.72 37.23H198.76" stroke="black" stroke-width="0.5" />
    <path d="M198.76 37.23V37.94" stroke="black" stroke-width="0.5" />
    <path d="M198.76 37.94H197.72" stroke="black" stroke-width="0.5" />
    <path d="M197.75 37.9V37.26" stroke="black" stroke-width="0.5" />
    <path d="M197.75 37.26H198.74" stroke="black" stroke-width="0.5" />
    <path d="M198.74 37.26V37.9" stroke="black" stroke-width="0.5" />
    <path d="M198.74 37.9H197.75" stroke="black" stroke-width="0.5" />
    <path d="M198.77 38.02H197.71" stroke="black" stroke-width="0.5" />
    <path d="M197.71 38.02V37.94" stroke="black" stroke-width="0.5" />
    <path d="M197.71 37.94H198.77" stroke="black" stroke-width="0.5" />
    <path d="M198.77 37.94V38.02" stroke="black" stroke-width="0.5" />
    <path d="M197.72 37.94V37.23" stroke="black" stroke-width="0.5" />
    <path d="M197.72 37.23H198.76" stroke="black" stroke-width="0.5" />
    <path d="M198.76 37.23V37.94" stroke="black" stroke-width="0.5" />
    <path d="M198.74 37.9H197.75" stroke="black" stroke-width="0.5" />
    <path d="M197.75 37.9V37.26" stroke="black" stroke-width="0.5" />
    <path d="M197.75 37.26H198.74" stroke="black" stroke-width="0.5" />
    <path d="M198.74 37.26V37.9" stroke="black" stroke-width="0.5" />
    <path d="M193.94 36.96V36.89" stroke="black" stroke-width="0.5" />
    <path d="M193.94 36.89H193.93" stroke="black" stroke-width="0.5" />
    <path d="M193.93 36.89H193.92" stroke="black" stroke-width="0.5" />
    <path d="M193.92 36.89H193.91" stroke="black" stroke-width="0.5" />
    <path d="M193.91 36.89H193.89" stroke="black" stroke-width="0.5" />
    <path d="M193.89 36.89H193.87" stroke="black" stroke-width="0.5" />
    <path d="M193.87 36.89H193.85" stroke="black" stroke-width="0.5" />
    <path d="M193.85 36.89H193.83" stroke="black" stroke-width="0.5" />
    <path d="M193.83 36.89H193.81" stroke="black" stroke-width="0.5" />
    <path d="M193.81 36.89H193.78" stroke="black" stroke-width="0.5" />
    <path d="M193.78 36.89H193.76" stroke="black" stroke-width="0.5" />
    <path d="M193.76 36.89H193.74" stroke="black" stroke-width="0.5" />
    <path d="M193.74 36.89H193.72" stroke="black" stroke-width="0.5" />
    <path d="M193.72 36.89H193.7" stroke="black" stroke-width="0.5" />
    <path d="M193.7 36.89H193.69" stroke="black" stroke-width="0.5" />
    <path d="M193.69 36.89H193.68" stroke="black" stroke-width="0.5" />
    <path d="M193.68 36.89H193.67" stroke="black" stroke-width="0.5" />
    <path d="M193.67 36.89V36.96" stroke="black" stroke-width="0.5" />
    <path d="M192.38 38.02V36.96" stroke="black" stroke-width="0.5" />
    <path d="M192.38 36.96H195.23" stroke="black" stroke-width="0.5" />
    <path d="M195.23 36.96V38.02" stroke="black" stroke-width="0.5" />
    <path d="M195.23 38.02H192.38" stroke="black" stroke-width="0.5" />
    <path d="M42.7 7.34H42.62" stroke="black" stroke-width="0.5" />
    <path d="M42.62 7.34V6.28" stroke="black" stroke-width="0.5" />
    <path d="M42.62 6.28H42.7" stroke="black" stroke-width="0.5" />
    <path d="M42.7 6.28V7.34" stroke="black" stroke-width="0.5" />
    <path d="M42.62 7.33002H41.91" stroke="black" stroke-width="0.5" />
    <path d="M41.91 7.33001V6.29001" stroke="black" stroke-width="0.5" />
    <path d="M41.91 6.29001H42.62" stroke="black" stroke-width="0.5" />
    <path d="M42.62 6.29001V7.33001" stroke="black" stroke-width="0.5" />
    <path d="M42.58 7.31H41.95" stroke="black" stroke-width="0.5" />
    <path d="M41.95 7.31V6.31" stroke="black" stroke-width="0.5" />
    <path d="M41.95 6.31H42.58" stroke="black" stroke-width="0.5" />
    <path d="M42.58 6.31V7.31" stroke="black" stroke-width="0.5" />
    <path d="M42.7 6.28V7.34" stroke="black" stroke-width="0.5" />
    <path d="M42.7 7.34H42.62" stroke="black" stroke-width="0.5" />
    <path d="M42.62 7.34V6.28" stroke="black" stroke-width="0.5" />
    <path d="M42.62 6.28H42.7" stroke="black" stroke-width="0.5" />
    <path d="M42.62 7.33002H41.91" stroke="black" stroke-width="0.5" />
    <path d="M41.91 7.33001V6.29001" stroke="black" stroke-width="0.5" />
    <path d="M41.91 6.29001H42.62" stroke="black" stroke-width="0.5" />
    <path d="M42.58 6.31V7.31" stroke="black" stroke-width="0.5" />
    <path d="M42.58 7.31H41.95" stroke="black" stroke-width="0.5" />
    <path d="M41.95 7.31V6.31" stroke="black" stroke-width="0.5" />
    <path d="M41.95 6.31H42.58" stroke="black" stroke-width="0.5" />
    <path d="M41.64 11.12H41.58" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.12V11.13" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.13V11.15" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.15V11.16" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.16V11.18" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.18V11.2" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.2V11.22" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.22V11.25" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.25V11.27" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.27V11.29" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.29V11.31" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.31V11.33" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.33V11.35" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.35V11.36" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.36V11.37" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.37V11.38" stroke="black" stroke-width="0.5" />
    <path d="M41.58 11.38H41.64" stroke="black" stroke-width="0.5" />
    <path d="M42.7 12.67H41.64" stroke="black" stroke-width="0.5" />
    <path d="M41.64 12.67V9.83002" stroke="black" stroke-width="0.5" />
    <path d="M41.64 9.83002H42.7" stroke="black" stroke-width="0.5" />
    <path d="M42.7 9.83002V12.67" stroke="black" stroke-width="0.5" />
    <path d="M2.03003 74.5V75.22" stroke="black" stroke-width="0.5" />
    <path d="M2.03003 75.22H0.98999" stroke="black" stroke-width="0.5" />
    <path d="M0.98999 75.22V74.5" stroke="black" stroke-width="0.5" />
    <path d="M0.98999 74.5H2.03003" stroke="black" stroke-width="0.5" />
    <path d="M2.01001 74.54V75.18" stroke="black" stroke-width="0.5" />
    <path d="M2.01001 75.18H1.02002" stroke="black" stroke-width="0.5" />
    <path d="M1.02002 75.18V74.54" stroke="black" stroke-width="0.5" />
    <path d="M1.02002 74.54H2.01001" stroke="black" stroke-width="0.5" />
    <path d="M2.03003 74.5V75.22" stroke="black" stroke-width="0.5" />
    <path d="M2.03003 75.22H0.98999" stroke="black" stroke-width="0.5" />
    <path d="M0.98999 75.22V74.5" stroke="black" stroke-width="0.5" />
    <path d="M1.02002 74.54H2.01001" stroke="black" stroke-width="0.5" />
    <path d="M2.01001 74.54V75.18" stroke="black" stroke-width="0.5" />
    <path d="M2.01001 75.18H1.02002" stroke="black" stroke-width="0.5" />
    <path d="M1.02002 75.18V74.54" stroke="black" stroke-width="0.5" />
    <path d="M5.82001 75.48V75.55" stroke="black" stroke-width="0.5" />
    <path d="M5.82001 75.55H5.83002" stroke="black" stroke-width="0.5" />
    <path d="M5.83002 75.55H5.84003" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 75.55H5.84998" stroke="black" stroke-width="0.5" />
    <path d="M5.84998 75.55H5.87" stroke="black" stroke-width="0.5" />
    <path d="M5.87 75.55H5.88" stroke="black" stroke-width="0.5" />
    <path d="M5.88 75.55H5.90997" stroke="black" stroke-width="0.5" />
    <path d="M5.90997 75.55H5.92999" stroke="black" stroke-width="0.5" />
    <path d="M5.92999 75.55H5.95001" stroke="black" stroke-width="0.5" />
    <path d="M5.95001 75.55H5.96997" stroke="black" stroke-width="0.5" />
    <path d="M5.96997 75.55H6" stroke="black" stroke-width="0.5" />
    <path d="M6 75.55H6.02002" stroke="black" stroke-width="0.5" />
    <path d="M6.02002 75.55H6.03998" stroke="black" stroke-width="0.5" />
    <path d="M6.03998 75.55H6.04999" stroke="black" stroke-width="0.5" />
    <path d="M6.04999 75.55H6.07001" stroke="black" stroke-width="0.5" />
    <path d="M6.07001 75.55H6.08002" stroke="black" stroke-width="0.5" />
    <path d="M6.08002 75.55V75.48" stroke="black" stroke-width="0.5" />
    <path d="M7.37 74.43V75.48" stroke="black" stroke-width="0.5" />
    <path d="M7.37 75.48H4.53003" stroke="black" stroke-width="0.5" />
    <path d="M4.53003 75.48V74.43" stroke="black" stroke-width="0.5" />
    <path d="M4.53003 74.43H7.37" stroke="black" stroke-width="0.5" />
    <path d="M197.71 56.52H198.77" stroke="black" stroke-width="0.5" />
    <path d="M198.77 56.52V56.44" stroke="black" stroke-width="0.5" />
    <path d="M198.77 56.44H197.71" stroke="black" stroke-width="0.5" />
    <path d="M197.71 56.44V56.52" stroke="black" stroke-width="0.5" />
    <path d="M197.72 57.23H198.76" stroke="black" stroke-width="0.5" />
    <path d="M198.76 57.23V56.52" stroke="black" stroke-width="0.5" />
    <path d="M198.76 56.52H197.72" stroke="black" stroke-width="0.5" />
    <path d="M197.72 56.52V57.23" stroke="black" stroke-width="0.5" />
    <path d="M197.75 57.19H198.74" stroke="black" stroke-width="0.5" />
    <path d="M198.74 57.19V56.56" stroke="black" stroke-width="0.5" />
    <path d="M198.74 56.56H197.75" stroke="black" stroke-width="0.5" />
    <path d="M197.75 56.56V57.19" stroke="black" stroke-width="0.5" />
    <path d="M198.77 56.44H197.71" stroke="black" stroke-width="0.5" />
    <path d="M197.71 56.44V56.52" stroke="black" stroke-width="0.5" />
    <path d="M197.71 56.52H198.77" stroke="black" stroke-width="0.5" />
    <path d="M198.77 56.52V56.44" stroke="black" stroke-width="0.5" />
    <path d="M197.72 56.52V57.23" stroke="black" stroke-width="0.5" />
    <path d="M197.72 57.23H198.76" stroke="black" stroke-width="0.5" />
    <path d="M198.76 57.23V56.52" stroke="black" stroke-width="0.5" />
    <path d="M198.74 56.56H197.75" stroke="black" stroke-width="0.5" />
    <path d="M197.75 56.56V57.19" stroke="black" stroke-width="0.5" />
    <path d="M197.75 57.19H198.74" stroke="black" stroke-width="0.5" />
    <path d="M198.74 57.19V56.56" stroke="black" stroke-width="0.5" />
    <path d="M193.94 57.49V57.56" stroke="black" stroke-width="0.5" />
    <path d="M193.94 57.56H193.93" stroke="black" stroke-width="0.5" />
    <path d="M193.93 57.56H193.92" stroke="black" stroke-width="0.5" />
    <path d="M193.92 57.56H193.91" stroke="black" stroke-width="0.5" />
    <path d="M193.91 57.56H193.89" stroke="black" stroke-width="0.5" />
    <path d="M193.89 57.56H193.87" stroke="black" stroke-width="0.5" />
    <path d="M193.87 57.56H193.85" stroke="black" stroke-width="0.5" />
    <path d="M193.85 57.56H193.83" stroke="black" stroke-width="0.5" />
    <path d="M193.83 57.56H193.81" stroke="black" stroke-width="0.5" />
    <path d="M193.81 57.56H193.78" stroke="black" stroke-width="0.5" />
    <path d="M193.78 57.56H193.76" stroke="black" stroke-width="0.5" />
    <path d="M193.76 57.56H193.74" stroke="black" stroke-width="0.5" />
    <path d="M193.74 57.56H193.72" stroke="black" stroke-width="0.5" />
    <path d="M193.72 57.56H193.7" stroke="black" stroke-width="0.5" />
    <path d="M193.7 57.56H193.69" stroke="black" stroke-width="0.5" />
    <path d="M193.69 57.56H193.68" stroke="black" stroke-width="0.5" />
    <path d="M193.68 57.56H193.67" stroke="black" stroke-width="0.5" />
    <path d="M193.67 57.56V57.49" stroke="black" stroke-width="0.5" />
    <path d="M192.38 56.44V57.49" stroke="black" stroke-width="0.5" />
    <path d="M192.38 57.49H195.23" stroke="black" stroke-width="0.5" />
    <path d="M195.23 57.49V56.44" stroke="black" stroke-width="0.5" />
    <path d="M195.23 56.44H192.38" stroke="black" stroke-width="0.5" />
    <path d="M167.33 14.18V7.29001" stroke="black" stroke-width="0.5" />
    <path d="M161.88 83.18V78.73" stroke="black" stroke-width="0.5" />
    <path d="M20.01 73.14H14.21" stroke="black" stroke-width="0.5" />
    <path d="M34.87 111.13V107.29" stroke="black" stroke-width="0.5" />
    <path d="M98.56 5.88H87.14" stroke="black" stroke-width="0.5" />
    <path d="M101.36 15.23H103.22" stroke="black" stroke-width="0.5" />
    <path d="M103.22 15.23V13.64" stroke="black" stroke-width="0.5" />
    <path d="M103.22 13.64H101.36" stroke="black" stroke-width="0.5" />
    <path d="M101.36 13.64V15.23" stroke="black" stroke-width="0.5" />
    <path d="M134.4 15.23H136.25" stroke="black" stroke-width="0.5" />
    <path d="M136.25 15.23V13.64" stroke="black" stroke-width="0.5" />
    <path d="M136.25 13.64H134.4" stroke="black" stroke-width="0.5" />
    <path d="M134.4 13.64V15.23" stroke="black" stroke-width="0.5" />
    <path d="M189.82 37.92V36.07" stroke="black" stroke-width="0.5" />
    <path d="M189.82 36.07H191.41" stroke="black" stroke-width="0.5" />
    <path d="M191.41 36.07V37.92" stroke="black" stroke-width="0.5" />
    <path d="M191.41 37.92H189.82" stroke="black" stroke-width="0.5" />
    <path d="M42.6 15.23H40.75" stroke="black" stroke-width="0.5" />
    <path d="M40.75 15.23V13.64" stroke="black" stroke-width="0.5" />
    <path d="M40.75 13.64H42.6" stroke="black" stroke-width="0.5" />
    <path d="M42.6 13.64V15.23" stroke="black" stroke-width="0.5" />
    <path d="M9.92999 74.52V76.38" stroke="black" stroke-width="0.5" />
    <path d="M9.92999 76.38H8.34998" stroke="black" stroke-width="0.5" />
    <path d="M8.34998 76.38V74.52" stroke="black" stroke-width="0.5" />
    <path d="M8.34998 74.52H9.92999" stroke="black" stroke-width="0.5" />
    <path d="M189.82 56.54V58.39" stroke="black" stroke-width="0.5" />
    <path d="M189.82 58.39H191.41" stroke="black" stroke-width="0.5" />
    <path d="M191.41 58.39V56.54" stroke="black" stroke-width="0.5" />
    <path d="M191.41 56.54H189.82" stroke="black" stroke-width="0.5" />
    <path d="M52.73 77.7L56.69 79.31" stroke="black" stroke-width="0.5" />
    <path d="M56.69 79.31L60.76 80.62" stroke="black" stroke-width="0.5" />
    <path d="M60.76 80.62L64.91 81.6" stroke="black" stroke-width="0.5" />
    <path d="M64.91 81.6L69.13 82.26" stroke="black" stroke-width="0.5" />
    <path d="M69.13 82.26L73.39 82.59" stroke="black" stroke-width="0.5" />
    <path d="M73.39 82.59H77.67" stroke="black" stroke-width="0.5" />
    <path d="M77.67 82.59L81.93 82.26" stroke="black" stroke-width="0.5" />
    <path d="M81.93 82.26L86.15 81.6" stroke="black" stroke-width="0.5" />
    <path d="M86.15 81.6L90.3 80.62" stroke="black" stroke-width="0.5" />
    <path d="M90.3 80.62L94.37 79.31" stroke="black" stroke-width="0.5" />
    <path d="M94.37 79.31L98.33 77.7" stroke="black" stroke-width="0.5" />
    <path d="M98.33 77.7L94.37 79.31" stroke="black" stroke-width="0.5" />
    <path d="M94.37 79.31L90.3 80.62" stroke="black" stroke-width="0.5" />
    <path d="M90.3 80.62L86.15 81.6" stroke="black" stroke-width="0.5" />
    <path d="M86.15 81.6L81.93 82.26" stroke="black" stroke-width="0.5" />
    <path d="M81.93 82.26L77.67 82.59" stroke="black" stroke-width="0.5" />
    <path d="M77.67 82.59H73.39" stroke="black" stroke-width="0.5" />
    <path d="M73.39 82.59L69.13 82.26" stroke="black" stroke-width="0.5" />
    <path d="M69.13 82.26L64.91 81.6" stroke="black" stroke-width="0.5" />
    <path d="M64.91 81.6L60.76 80.62" stroke="black" stroke-width="0.5" />
    <path d="M60.76 80.62L56.69 79.31" stroke="black" stroke-width="0.5" />
    <path d="M56.69 79.31L52.73 77.7" stroke="black" stroke-width="0.5" />
    <path d="M102.59 8.06L102.61 8.14" stroke="black" stroke-width="0.5" />
    <path
      d="M102.61 8.14001L102.67 8.20001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.67 8.20001L102.75 8.23002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.75 8.23002L102.83 8.20001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.83 8.20001L102.89 8.14001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.89 8.14L102.92 8.06" stroke="black" stroke-width="0.5" />
    <path
      d="M102.92 8.06001L102.89 7.98001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.89 7.97999L102.83 7.91998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.83 7.91999L102.75 7.89999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.75 7.89999L102.67 7.91999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.67 7.91998L102.61 7.97999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.61 7.98001L102.59 8.06001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.92 10.04L102.89 9.95999" stroke="black" stroke-width="0.5" />
    <path
      d="M102.89 9.95999L102.83 9.89999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.83 9.90001L102.75 9.88" stroke="black" stroke-width="0.5" />
    <path d="M102.75 9.88L102.67 9.90001" stroke="black" stroke-width="0.5" />
    <path
      d="M102.67 9.89999L102.61 9.95999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.61 9.95999L102.59 10.04" stroke="black" stroke-width="0.5" />
    <path d="M102.59 10.04L102.61 10.13" stroke="black" stroke-width="0.5" />
    <path d="M102.61 10.13L102.67 10.19" stroke="black" stroke-width="0.5" />
    <path d="M102.67 10.19L102.75 10.21" stroke="black" stroke-width="0.5" />
    <path d="M102.75 10.21L102.83 10.19" stroke="black" stroke-width="0.5" />
    <path d="M102.83 10.19L102.89 10.13" stroke="black" stroke-width="0.5" />
    <path d="M102.89 10.13L102.92 10.04" stroke="black" stroke-width="0.5" />
    <path d="M105.1 8.79001L104.97 8.82001" stroke="black" stroke-width="0.5" />
    <path
      d="M104.97 8.82001L104.87 8.92001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.87 8.91998L104.83 9.04999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.83 9.04999L104.87 9.17999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.87 9.17999L104.97 9.27999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M104.97 9.28L105.1 9.32" stroke="black" stroke-width="0.5" />
    <path d="M105.1 9.31999L105.29 9.23999" stroke="black" stroke-width="0.5" />
    <path
      d="M105.29 9.23998L105.36 9.04999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M105.36 9.05L105.29 8.87" stroke="black" stroke-width="0.5" />
    <path d="M105.29 8.87001L105.1 8.79001" stroke="black" stroke-width="0.5" />
    <path d="M102.98 8.96001L102.93 8.88" stroke="black" stroke-width="0.5" />
    <path d="M102.93 8.88L102.84 8.82001" stroke="black" stroke-width="0.5" />
    <path d="M102.84 8.82L102.72 8.81" stroke="black" stroke-width="0.5" />
    <path d="M102.72 8.81L102.6 8.86" stroke="black" stroke-width="0.5" />
    <path d="M102.6 8.85999L102.52 8.95998" stroke="black" stroke-width="0.5" />
    <path d="M102.52 8.95999V9.14999" stroke="black" stroke-width="0.5" />
    <path
      d="M102.52 9.14999L102.66 9.27999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.66 9.28L102.79 9.3" stroke="black" stroke-width="0.5" />
    <path d="M102.79 9.3L102.91 9.25" stroke="black" stroke-width="0.5" />
    <path
      d="M102.91 9.25002L102.98 9.14001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.62 8.06L135.67 8.18" stroke="black" stroke-width="0.5" />
    <path d="M135.67 8.17999L135.79 8.23" stroke="black" stroke-width="0.5" />
    <path
      d="M135.79 8.23002L135.87 8.20001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M135.87 8.20001L135.93 8.14001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.93 8.14L135.95 8.06" stroke="black" stroke-width="0.5" />
    <path
      d="M135.95 8.06001L135.93 7.98001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M135.93 7.97999L135.87 7.91998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M135.87 7.91999L135.79 7.89999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M135.79 7.89999L135.67 7.93999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.67 7.94L135.62 8.06" stroke="black" stroke-width="0.5" />
    <path d="M135.95 10.04L135.93 9.95999" stroke="black" stroke-width="0.5" />
    <path
      d="M135.93 9.95999L135.87 9.89999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.87 9.90001L135.79 9.88" stroke="black" stroke-width="0.5" />
    <path d="M135.79 9.88L135.67 9.93001" stroke="black" stroke-width="0.5" />
    <path d="M135.67 9.92999L135.62 10.04" stroke="black" stroke-width="0.5" />
    <path d="M135.62 10.04L135.67 10.16" stroke="black" stroke-width="0.5" />
    <path d="M135.67 10.16L135.79 10.21" stroke="black" stroke-width="0.5" />
    <path d="M135.79 10.21L135.87 10.19" stroke="black" stroke-width="0.5" />
    <path d="M135.87 10.19L135.93 10.13" stroke="black" stroke-width="0.5" />
    <path d="M135.93 10.13L135.95 10.04" stroke="black" stroke-width="0.5" />
    <path
      d="M138.13 8.79001L137.95 8.87001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M137.95 8.87L137.87 9.05" stroke="black" stroke-width="0.5" />
    <path
      d="M137.87 9.04999L137.95 9.23998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.95 9.23999L138.13 9.31999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M138.13 9.32L138.27 9.28" stroke="black" stroke-width="0.5" />
    <path
      d="M138.27 9.27999L138.36 9.17999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M138.36 9.17999L138.4 9.04999" stroke="black" stroke-width="0.5" />
    <path d="M138.4 9.04999L138.36 8.91998" stroke="black" stroke-width="0.5" />
    <path
      d="M138.36 8.92001L138.27 8.82001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M138.27 8.82001L138.13 8.79001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M136.02 8.96001L135.96 8.88" stroke="black" stroke-width="0.5" />
    <path d="M135.96 8.88L135.88 8.82001" stroke="black" stroke-width="0.5" />
    <path d="M135.88 8.82001H135.69" stroke="black" stroke-width="0.5" />
    <path
      d="M135.69 8.82001L135.55 8.96001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M135.55 8.95999L135.54 9.08999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.54 9.09L135.59 9.21" stroke="black" stroke-width="0.5" />
    <path
      d="M135.59 9.20999L135.69 9.27999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M135.69 9.28H135.88" stroke="black" stroke-width="0.5" />
    <path
      d="M135.88 9.28001L136.02 9.14001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M196.99 36.37L196.91 36.39" stroke="black" stroke-width="0.5" />
    <path d="M196.91 36.39L196.85 36.45" stroke="black" stroke-width="0.5" />
    <path d="M196.85 36.45L196.83 36.53" stroke="black" stroke-width="0.5" />
    <path d="M196.83 36.53L196.85 36.61" stroke="black" stroke-width="0.5" />
    <path d="M196.85 36.61L196.91 36.67" stroke="black" stroke-width="0.5" />
    <path d="M196.91 36.67L196.99 36.7" stroke="black" stroke-width="0.5" />
    <path d="M196.99 36.7L197.08 36.67" stroke="black" stroke-width="0.5" />
    <path d="M197.08 36.67L197.14 36.61" stroke="black" stroke-width="0.5" />
    <path d="M197.14 36.61L197.16 36.53" stroke="black" stroke-width="0.5" />
    <path d="M197.16 36.53L197.14 36.45" stroke="black" stroke-width="0.5" />
    <path d="M197.14 36.45L197.08 36.39" stroke="black" stroke-width="0.5" />
    <path d="M197.08 36.39L196.99 36.37" stroke="black" stroke-width="0.5" />
    <path d="M195.01 36.7L195.13 36.65" stroke="black" stroke-width="0.5" />
    <path d="M195.13 36.65L195.17 36.53" stroke="black" stroke-width="0.5" />
    <path d="M195.17 36.53L195.13 36.41" stroke="black" stroke-width="0.5" />
    <path d="M195.13 36.41L195.01 36.37" stroke="black" stroke-width="0.5" />
    <path d="M195.01 36.37L194.93 36.39" stroke="black" stroke-width="0.5" />
    <path d="M194.93 36.39L194.87 36.45" stroke="black" stroke-width="0.5" />
    <path d="M194.87 36.45L194.84 36.53" stroke="black" stroke-width="0.5" />
    <path d="M194.84 36.53L194.87 36.61" stroke="black" stroke-width="0.5" />
    <path d="M194.87 36.61L194.93 36.67" stroke="black" stroke-width="0.5" />
    <path d="M194.93 36.67L195.01 36.7" stroke="black" stroke-width="0.5" />
    <path d="M196 33.92L195.87 33.95" stroke="black" stroke-width="0.5" />
    <path d="M195.87 33.95L195.77 34.05" stroke="black" stroke-width="0.5" />
    <path d="M195.77 34.05L195.74 34.18" stroke="black" stroke-width="0.5" />
    <path d="M195.74 34.18L195.77 34.32" stroke="black" stroke-width="0.5" />
    <path d="M195.77 34.32L195.87 34.41" stroke="black" stroke-width="0.5" />
    <path d="M195.87 34.41L196 34.45" stroke="black" stroke-width="0.5" />
    <path d="M196 34.45L196.19 34.37" stroke="black" stroke-width="0.5" />
    <path d="M196.19 34.37L196.26 34.18" stroke="black" stroke-width="0.5" />
    <path d="M196.26 34.18L196.19 34" stroke="black" stroke-width="0.5" />
    <path d="M196.19 34L196 33.92" stroke="black" stroke-width="0.5" />
    <path d="M195.91 36.3L195.82 36.35" stroke="black" stroke-width="0.5" />
    <path d="M195.82 36.35L195.77 36.44" stroke="black" stroke-width="0.5" />
    <path d="M195.77 36.44L195.75 36.57" stroke="black" stroke-width="0.5" />
    <path d="M195.75 36.57L195.8 36.69" stroke="black" stroke-width="0.5" />
    <path d="M195.8 36.69L195.91 36.76" stroke="black" stroke-width="0.5" />
    <path d="M195.91 36.76L196.04 36.78" stroke="black" stroke-width="0.5" />
    <path d="M196.04 36.78L196.16 36.73" stroke="black" stroke-width="0.5" />
    <path d="M196.16 36.73L196.23 36.62" stroke="black" stroke-width="0.5" />
    <path d="M196.23 36.62V36.43" stroke="black" stroke-width="0.5" />
    <path d="M196.23 36.43L196.09 36.3" stroke="black" stroke-width="0.5" />
    <path d="M41.05 8.06L41.07 8.14" stroke="black" stroke-width="0.5" />
    <path d="M41.07 8.14001L41.13 8.20001" stroke="black" stroke-width="0.5" />
    <path d="M41.13 8.20001L41.21 8.23002" stroke="black" stroke-width="0.5" />
    <path d="M41.21 8.23002L41.3 8.20001" stroke="black" stroke-width="0.5" />
    <path d="M41.3 8.20001L41.36 8.14001" stroke="black" stroke-width="0.5" />
    <path d="M41.36 8.14L41.38 8.06" stroke="black" stroke-width="0.5" />
    <path d="M41.38 8.06001L41.36 7.98001" stroke="black" stroke-width="0.5" />
    <path d="M41.36 7.97999L41.3 7.91998" stroke="black" stroke-width="0.5" />
    <path d="M41.3 7.91999L41.21 7.89999" stroke="black" stroke-width="0.5" />
    <path d="M41.21 7.89999L41.13 7.91999" stroke="black" stroke-width="0.5" />
    <path d="M41.13 7.91998L41.07 7.97999" stroke="black" stroke-width="0.5" />
    <path d="M41.07 7.98001L41.05 8.06001" stroke="black" stroke-width="0.5" />
    <path d="M41.38 10.04L41.36 9.95999" stroke="black" stroke-width="0.5" />
    <path d="M41.36 9.95999L41.3 9.89999" stroke="black" stroke-width="0.5" />
    <path d="M41.3 9.90001L41.21 9.88" stroke="black" stroke-width="0.5" />
    <path d="M41.21 9.88L41.13 9.90001" stroke="black" stroke-width="0.5" />
    <path d="M41.13 9.89999L41.07 9.95999" stroke="black" stroke-width="0.5" />
    <path d="M41.07 9.95999L41.05 10.04" stroke="black" stroke-width="0.5" />
    <path d="M41.05 10.04L41.07 10.13" stroke="black" stroke-width="0.5" />
    <path d="M41.07 10.13L41.13 10.19" stroke="black" stroke-width="0.5" />
    <path d="M41.13 10.19L41.21 10.21" stroke="black" stroke-width="0.5" />
    <path d="M41.21 10.21L41.3 10.19" stroke="black" stroke-width="0.5" />
    <path d="M41.3 10.19L41.36 10.13" stroke="black" stroke-width="0.5" />
    <path d="M41.36 10.13L41.38 10.04" stroke="black" stroke-width="0.5" />
    <path d="M38.87 8.79001L38.73 8.82001" stroke="black" stroke-width="0.5" />
    <path d="M38.73 8.82001L38.64 8.92001" stroke="black" stroke-width="0.5" />
    <path d="M38.64 8.91998L38.6 9.04999" stroke="black" stroke-width="0.5" />
    <path d="M38.6 9.04999L38.64 9.17999" stroke="black" stroke-width="0.5" />
    <path d="M38.64 9.17999L38.73 9.27999" stroke="black" stroke-width="0.5" />
    <path d="M38.73 9.28L38.87 9.32" stroke="black" stroke-width="0.5" />
    <path d="M38.87 9.32L39 9.28" stroke="black" stroke-width="0.5" />
    <path d="M39 9.27999L39.1 9.17999" stroke="black" stroke-width="0.5" />
    <path d="M39.1 9.17999L39.13 9.04999" stroke="black" stroke-width="0.5" />
    <path d="M39.13 9.04999L39.1 8.91998" stroke="black" stroke-width="0.5" />
    <path d="M39.1 8.92001L39 8.82001" stroke="black" stroke-width="0.5" />
    <path d="M39 8.82001L38.87 8.79001" stroke="black" stroke-width="0.5" />
    <path d="M40.98 9.14001L41.04 9.23002" stroke="black" stroke-width="0.5" />
    <path d="M41.04 9.23001L41.12 9.28001" stroke="black" stroke-width="0.5" />
    <path d="M41.12 9.28L41.25 9.3" stroke="black" stroke-width="0.5" />
    <path d="M41.25 9.3L41.37 9.25" stroke="black" stroke-width="0.5" />
    <path d="M41.37 9.25002L41.45 9.14001" stroke="black" stroke-width="0.5" />
    <path d="M41.45 9.14001L41.44 8.95001" stroke="black" stroke-width="0.5" />
    <path d="M41.44 8.95L41.31 8.82001" stroke="black" stroke-width="0.5" />
    <path d="M41.31 8.82L41.18 8.81" stroke="black" stroke-width="0.5" />
    <path d="M41.18 8.81L41.06 8.86" stroke="black" stroke-width="0.5" />
    <path d="M41.06 8.85999L40.98 8.95998" stroke="black" stroke-width="0.5" />
    <path d="M2.76001 76.08L2.84998 76.06" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 76.06L2.90997 76" stroke="black" stroke-width="0.5" />
    <path d="M2.90997 76L2.92999 75.91" stroke="black" stroke-width="0.5" />
    <path d="M2.92999 75.91L2.90997 75.83" stroke="black" stroke-width="0.5" />
    <path d="M2.90997 75.83L2.84998 75.77" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 75.77L2.76001 75.75" stroke="black" stroke-width="0.5" />
    <path d="M2.76001 75.75L2.65002 75.8" stroke="black" stroke-width="0.5" />
    <path d="M2.65002 75.8L2.59998 75.91" stroke="black" stroke-width="0.5" />
    <path d="M2.59998 75.91L2.65002 76.03" stroke="black" stroke-width="0.5" />
    <path d="M2.65002 76.03L2.76001 76.08" stroke="black" stroke-width="0.5" />
    <path d="M4.75 75.75L4.65997 75.77" stroke="black" stroke-width="0.5" />
    <path d="M4.65997 75.77L4.59998 75.83" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 75.83L4.58002 75.91" stroke="black" stroke-width="0.5" />
    <path d="M4.58002 75.91L4.59998 76" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 76L4.65997 76.06" stroke="black" stroke-width="0.5" />
    <path d="M4.65997 76.06L4.75 76.08" stroke="black" stroke-width="0.5" />
    <path d="M4.75 76.08L4.83002 76.06" stroke="black" stroke-width="0.5" />
    <path d="M4.83002 76.06L4.89001 76" stroke="black" stroke-width="0.5" />
    <path d="M4.89001 76L4.90997 75.91" stroke="black" stroke-width="0.5" />
    <path d="M4.90997 75.91L4.89001 75.83" stroke="black" stroke-width="0.5" />
    <path d="M4.89001 75.83L4.83002 75.77" stroke="black" stroke-width="0.5" />
    <path d="M4.83002 75.77L4.75 75.75" stroke="black" stroke-width="0.5" />
    <path d="M3.76001 78L3.62 78.03" stroke="black" stroke-width="0.5" />
    <path d="M3.62 78.03L3.53003 78.13" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 78.13L3.48999 78.26" stroke="black" stroke-width="0.5" />
    <path d="M3.48999 78.26L3.57001 78.45" stroke="black" stroke-width="0.5" />
    <path d="M3.57001 78.45L3.76001 78.53" stroke="black" stroke-width="0.5" />
    <path d="M3.76001 78.53L3.94 78.45" stroke="black" stroke-width="0.5" />
    <path d="M3.94 78.45L4.02002 78.26" stroke="black" stroke-width="0.5" />
    <path d="M4.02002 78.26L3.94 78.07" stroke="black" stroke-width="0.5" />
    <path d="M3.94 78.07L3.76001 78" stroke="black" stroke-width="0.5" />
    <path d="M3.84998 76.15L3.92999 76.09" stroke="black" stroke-width="0.5" />
    <path d="M3.92999 76.09L3.98999 76.01" stroke="black" stroke-width="0.5" />
    <path d="M3.98999 76.01V75.81" stroke="black" stroke-width="0.5" />
    <path d="M3.98999 75.81L3.84998 75.68" stroke="black" stroke-width="0.5" />
    <path d="M3.84998 75.68L3.71997 75.67" stroke="black" stroke-width="0.5" />
    <path d="M3.71997 75.67L3.59998 75.72" stroke="black" stroke-width="0.5" />
    <path d="M3.59998 75.72L3.52002 75.82" stroke="black" stroke-width="0.5" />
    <path d="M3.52002 75.82L3.51001 75.95" stroke="black" stroke-width="0.5" />
    <path d="M3.51001 75.95L3.56 76.07" stroke="black" stroke-width="0.5" />
    <path d="M3.56 76.07L3.65997 76.15" stroke="black" stroke-width="0.5" />
    <path d="M196.99 57.76L196.88 57.81" stroke="black" stroke-width="0.5" />
    <path d="M196.88 57.81L196.83 57.92" stroke="black" stroke-width="0.5" />
    <path d="M196.83 57.92L196.85 58.01" stroke="black" stroke-width="0.5" />
    <path d="M196.85 58.01L196.91 58.07" stroke="black" stroke-width="0.5" />
    <path d="M196.91 58.07L196.99 58.09" stroke="black" stroke-width="0.5" />
    <path d="M196.99 58.09L197.08 58.07" stroke="black" stroke-width="0.5" />
    <path d="M197.08 58.07L197.14 58.01" stroke="black" stroke-width="0.5" />
    <path d="M197.14 58.01L197.16 57.92" stroke="black" stroke-width="0.5" />
    <path d="M197.16 57.92L197.11 57.81" stroke="black" stroke-width="0.5" />
    <path d="M197.11 57.81L196.99 57.76" stroke="black" stroke-width="0.5" />
    <path d="M195.01 58.09L195.13 58.04" stroke="black" stroke-width="0.5" />
    <path d="M195.13 58.04L195.17 57.92" stroke="black" stroke-width="0.5" />
    <path d="M195.17 57.92L195.13 57.81" stroke="black" stroke-width="0.5" />
    <path d="M195.13 57.81L195.01 57.76" stroke="black" stroke-width="0.5" />
    <path d="M195.01 57.76L194.89 57.81" stroke="black" stroke-width="0.5" />
    <path d="M194.89 57.81L194.84 57.92" stroke="black" stroke-width="0.5" />
    <path d="M194.84 57.92L194.87 58.01" stroke="black" stroke-width="0.5" />
    <path d="M194.87 58.01L194.93 58.07" stroke="black" stroke-width="0.5" />
    <path d="M194.93 58.07L195.01 58.09" stroke="black" stroke-width="0.5" />
    <path d="M196 60.01L195.81 60.09" stroke="black" stroke-width="0.5" />
    <path d="M195.81 60.09L195.74 60.27" stroke="black" stroke-width="0.5" />
    <path d="M195.74 60.27L195.77 60.4" stroke="black" stroke-width="0.5" />
    <path d="M195.77 60.4L195.87 60.5" stroke="black" stroke-width="0.5" />
    <path d="M195.87 60.5L196 60.54" stroke="black" stroke-width="0.5" />
    <path d="M196 60.54L196.19 60.46" stroke="black" stroke-width="0.5" />
    <path d="M196.19 60.46L196.26 60.27" stroke="black" stroke-width="0.5" />
    <path d="M196.26 60.27L196.19 60.09" stroke="black" stroke-width="0.5" />
    <path d="M196.19 60.09L196 60.01" stroke="black" stroke-width="0.5" />
    <path d="M196.09 58.16L196.18 58.1" stroke="black" stroke-width="0.5" />
    <path d="M196.18 58.1L196.23 58.02" stroke="black" stroke-width="0.5" />
    <path d="M196.23 58.02V57.83" stroke="black" stroke-width="0.5" />
    <path d="M196.23 57.83L196.09 57.69" stroke="black" stroke-width="0.5" />
    <path d="M196.09 57.69L195.96 57.68" stroke="black" stroke-width="0.5" />
    <path d="M195.96 57.68L195.85 57.73" stroke="black" stroke-width="0.5" />
    <path d="M195.85 57.73L195.77 57.83" stroke="black" stroke-width="0.5" />
    <path d="M195.77 57.83L195.75 57.96" stroke="black" stroke-width="0.5" />
    <path d="M195.75 57.96L195.8 58.08" stroke="black" stroke-width="0.5" />
    <path d="M195.8 58.08L195.91 58.16" stroke="black" stroke-width="0.5" />
    <path d="M100.79 64.33H104.1" stroke="black" stroke-width="0.5" />
    <path d="M100.79 64.98H104.1" stroke="black" stroke-width="0.5" />
    <path d="M52 64.32H48.69" stroke="black" stroke-width="0.5" />
    <path d="M52 64.97H48.69" stroke="black" stroke-width="0.5" />
  </svg>
</template>
