<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 53 114"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      fill-opacity="0.3"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="M1 113V9V4.5V1H5.5V4.5H11.5H40.5H52.5V9V104H35L26 113H1Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="handleClick"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
defineProps<{
  color?: string;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};

const handleClick = (event: MouseEvent) => {
  emits('click', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
