<template>
  <svg
    width="108"
    height="169"
    viewBox="0 0 108 169"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="108" height="169" fill="white" />
    <path d="M6.44 33.79V31.38" stroke="black" stroke-width="0.5" />
    <path d="M6.44 31.38H7.64" stroke="black" stroke-width="0.5" />
    <path d="M7.64001 31.38L8.04002 31.78" stroke="black" stroke-width="0.5" />
    <path d="M8.03998 31.78V32.18" stroke="black" stroke-width="0.5" />
    <path d="M8.04002 32.18L7.64001 32.58" stroke="black" stroke-width="0.5" />
    <path d="M7.64 32.58H6.44" stroke="black" stroke-width="0.5" />
    <path d="M6.84003 32.58L8.04003 33.79" stroke="black" stroke-width="0.5" />
    <path d="M8.84003 33.79V31.38" stroke="black" stroke-width="0.5" />
    <path d="M8.84003 31.38H10.44" stroke="black" stroke-width="0.5" />
    <path d="M10.44 31.38V33.79" stroke="black" stroke-width="0.5" />
    <path d="M10.44 33.79H8.84003" stroke="black" stroke-width="0.5" />
    <path d="M11.24 33.79V31.38" stroke="black" stroke-width="0.5" />
    <path d="M11.24 31.38H12.85" stroke="black" stroke-width="0.5" />
    <path d="M12.85 31.38V33.79" stroke="black" stroke-width="0.5" />
    <path d="M12.85 33.79H11.24" stroke="black" stroke-width="0.5" />
    <path d="M13.65 33.79V31.38" stroke="black" stroke-width="0.5" />
    <path d="M13.65 31.38H15.25" stroke="black" stroke-width="0.5" />
    <path d="M13.65 32.58H14.45" stroke="black" stroke-width="0.5" />
    <path d="M6.44 37.79V35.39" stroke="black" stroke-width="0.5" />
    <path d="M6.44 36.59H8.04" stroke="black" stroke-width="0.5" />
    <path d="M8.03998 35.39V37.79" stroke="black" stroke-width="0.5" />
    <path d="M8.84003 37.79V36.99" stroke="black" stroke-width="0.5" />
    <path d="M8.84003 36.99L9.64003 35.39" stroke="black" stroke-width="0.5" />
    <path d="M9.64001 35.39L10.44 36.99" stroke="black" stroke-width="0.5" />
    <path d="M10.44 36.99V37.79" stroke="black" stroke-width="0.5" />
    <path d="M8.84003 36.99H10.44" stroke="black" stroke-width="0.5" />
    <path d="M11.24 35.39H12.85" stroke="black" stroke-width="0.5" />
    <path d="M12.05 35.39V37.79" stroke="black" stroke-width="0.5" />
    <path d="M15.25 37.39L14.85 37.79" stroke="black" stroke-width="0.5" />
    <path d="M14.85 37.79H14.05" stroke="black" stroke-width="0.5" />
    <path d="M14.05 37.79L13.65 37.39" stroke="black" stroke-width="0.5" />
    <path d="M13.65 37.39V35.79" stroke="black" stroke-width="0.5" />
    <path d="M13.65 35.79L14.05 35.39" stroke="black" stroke-width="0.5" />
    <path d="M14.05 35.39H14.85" stroke="black" stroke-width="0.5" />
    <path d="M14.85 35.39L15.25 35.79" stroke="black" stroke-width="0.5" />
    <path d="M16.05 37.79V35.39" stroke="black" stroke-width="0.5" />
    <path d="M16.05 36.59H17.65" stroke="black" stroke-width="0.5" />
    <path d="M17.65 35.39V37.79" stroke="black" stroke-width="0.5" />
    <path d="M18.45 37.79V37.39" stroke="black" stroke-width="0.5" />
    <path d="M-0.909973 1.41H1.50003" stroke="black" stroke-width="0.5" />
    <path d="M1.5 0.339996H7.9" stroke="black" stroke-width="0.5" />
    <path d="M-0.909973 2.74001H0.430027" stroke="black" stroke-width="0.5" />
    <path d="M-28.39 78.47H0.419985" stroke="black" stroke-width="0.5" />
    <path d="M1.5 1.41V0.339996" stroke="black" stroke-width="0.5" />
    <path d="M7.90002 2.74001V0.339996" stroke="black" stroke-width="0.5" />
    <path d="M7.90002 2.74001V11.48" stroke="black" stroke-width="0.5" />
    <path d="M9.5 11.48V12.81" stroke="black" stroke-width="0.5" />
    <path d="M8.96997 11.48V12.81" stroke="black" stroke-width="0.5" />
    <path d="M6.57001 2.74001V11.48" stroke="black" stroke-width="0.5" />
    <path d="M9.5 12.81H1.5" stroke="black" stroke-width="0.5" />
    <path d="M9.50002 11.48H7.90002" stroke="black" stroke-width="0.5" />
    <path d="M6.57 11.48H1.5" stroke="black" stroke-width="0.5" />
    <path d="M19.11 11.48H26.58" stroke="black" stroke-width="0.5" />
    <path d="M19.11 12.81H26.58" stroke="black" stroke-width="0.5" />
    <path d="M26.58 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M26.05 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M19.64 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M19.11 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M19.64 12.15H26.05" stroke="black" stroke-width="0.5" />
    <path d="M26.58 11.48L35.32 2.74001" stroke="black" stroke-width="0.5" />
    <path d="M27.13 12.81L37.2 2.74001" stroke="black" stroke-width="0.5" />
    <path d="M26.58 12.81H27.13" stroke="black" stroke-width="0.5" />
    <path d="M35.32 2.74001V1.41" stroke="black" stroke-width="0.5" />
    <path d="M35.32 2.74001H68.94" stroke="black" stroke-width="0.5" />
    <path d="M62.54 2.74001V1.41" stroke="black" stroke-width="0.5" />
    <path d="M68.94 2.74001V1.41" stroke="black" stroke-width="0.5" />
    <path d="M79.01 11.48L68.94 1.41" stroke="black" stroke-width="0.5" />
    <path d="M79.01 12.81L68.94 2.74001" stroke="black" stroke-width="0.5" />
    <path d="M79.01 11.48H86.48" stroke="black" stroke-width="0.5" />
    <path d="M79.01 12.81H86.48" stroke="black" stroke-width="0.5" />
    <path d="M86.48 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M96.09 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M96.62 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M85.95 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M79.54 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M79.01 12.81V11.48" stroke="black" stroke-width="0.5" />
    <path d="M79.54 12.15H85.95" stroke="black" stroke-width="0.5" />
    <path d="M96.09 12.81H98.76" stroke="black" stroke-width="0.5" />
    <path d="M96.09 11.48H97.42" stroke="black" stroke-width="0.5" />
    <path d="M98.76 12.81V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M100.89 8.35001V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M97.42 11.48V1.41" stroke="black" stroke-width="0.5" />
    <path d="M98.76 2.74001H115.84" stroke="black" stroke-width="0.5" />
    <path d="M100.89 8.35001H113.44" stroke="black" stroke-width="0.5" />
    <path d="M100.89 7.37H113.7" stroke="black" stroke-width="0.5" />
    <path d="M100.89 6.39H113.7" stroke="black" stroke-width="0.5" />
    <path d="M100.89 5.39999H113.7" stroke="black" stroke-width="0.5" />
    <path d="M100.89 4.42H113.7" stroke="black" stroke-width="0.5" />
    <path d="M100.89 3.44H113.7" stroke="black" stroke-width="0.5" />
    <path d="M97.42 1.41H104.02" stroke="black" stroke-width="0.5" />
    <path d="M104.02 0.0800018H110.63" stroke="black" stroke-width="0.5" />
    <path d="M104.02 1.41V0.0800018" stroke="black" stroke-width="0.5" />
    <path d="M35.32 1.41H68.94" stroke="black" stroke-width="0.5" />
    <path d="M1.5 2.74001H7.9" stroke="black" stroke-width="0.5" />
    <path d="M107.65 164.76V162.68" stroke="black" stroke-width="0.5" />
    <path d="M107.65 161.84V160.18" stroke="black" stroke-width="0.5" />
    <path d="M107.65 159.34V157.67" stroke="black" stroke-width="0.5" />
    <path d="M107.65 156.84V155.17" stroke="black" stroke-width="0.5" />
    <path d="M107.65 154.34V152.67" stroke="black" stroke-width="0.5" />
    <path d="M107.65 151.84V150.17" stroke="black" stroke-width="0.5" />
    <path d="M107.65 149.33V147.67" stroke="black" stroke-width="0.5" />
    <path d="M107.65 146.83V145.17" stroke="black" stroke-width="0.5" />
    <path d="M107.65 144.33V142.66" stroke="black" stroke-width="0.5" />
    <path d="M107.65 141.83V140.16" stroke="black" stroke-width="0.5" />
    <path d="M107.65 139.33V137.66" stroke="black" stroke-width="0.5" />
    <path d="M107.65 136.83V135.16" stroke="black" stroke-width="0.5" />
    <path d="M107.65 134.32V132.66" stroke="black" stroke-width="0.5" />
    <path d="M107.65 131.82V130.15" stroke="black" stroke-width="0.5" />
    <path d="M107.65 129.32V127.65" stroke="black" stroke-width="0.5" />
    <path d="M107.65 126.82V124.73" stroke="black" stroke-width="0.5" />
    <path d="M107.65 115.13V114.16" stroke="black" stroke-width="0.5" />
    <path d="M107.65 113.32V111.65" stroke="black" stroke-width="0.5" />
    <path d="M107.65 110.82V109.15" stroke="black" stroke-width="0.5" />
    <path d="M107.65 108.32V106.65" stroke="black" stroke-width="0.5" />
    <path d="M107.65 105.82V104.15" stroke="black" stroke-width="0.5" />
    <path d="M107.65 103.31V101.65" stroke="black" stroke-width="0.5" />
    <path d="M107.65 100.81V99.15" stroke="black" stroke-width="0.5" />
    <path d="M107.65 98.31V96.64" stroke="black" stroke-width="0.5" />
    <path d="M107.65 95.81V94.14" stroke="black" stroke-width="0.5" />
    <path d="M107.65 93.31V91.64" stroke="black" stroke-width="0.5" />
    <path d="M107.65 90.81V89.14" stroke="black" stroke-width="0.5" />
    <path d="M107.65 88.3V86.64" stroke="black" stroke-width="0.5" />
    <path d="M107.65 85.8V84.13" stroke="black" stroke-width="0.5" />
    <path d="M107.65 83.3V81.63" stroke="black" stroke-width="0.5" />
    <path d="M107.65 80.8V79.13" stroke="black" stroke-width="0.5" />
    <path d="M107.65 78.3V76.63" stroke="black" stroke-width="0.5" />
    <path d="M107.65 75.79V74.13" stroke="black" stroke-width="0.5" />
    <path d="M107.65 73.29V72.32" stroke="black" stroke-width="0.5" />
    <path d="M107.65 66.57V64.88" stroke="black" stroke-width="0.5" />
    <path d="M107.65 64.04V62.38" stroke="black" stroke-width="0.5" />
    <path d="M107.65 61.54V59.87" stroke="black" stroke-width="0.5" />
    <path d="M107.65 59.04V57.37" stroke="black" stroke-width="0.5" />
    <path d="M107.65 56.54V54.87" stroke="black" stroke-width="0.5" />
    <path d="M107.65 54.04V52.37" stroke="black" stroke-width="0.5" />
    <path d="M107.65 51.53V49.87" stroke="black" stroke-width="0.5" />
    <path d="M107.65 49.03V47.36" stroke="black" stroke-width="0.5" />
    <path d="M107.65 46.53V44.86" stroke="black" stroke-width="0.5" />
    <path d="M107.65 44.03V42.36" stroke="black" stroke-width="0.5" />
    <path d="M107.65 41.53V39.83" stroke="black" stroke-width="0.5" />
    <path d="M107.65 30.22V28.16" stroke="black" stroke-width="0.5" />
    <path d="M107.65 27.33V25.66" stroke="black" stroke-width="0.5" />
    <path d="M107.65 24.82V23.16" stroke="black" stroke-width="0.5" />
    <path d="M107.65 22.32V20.65" stroke="black" stroke-width="0.5" />
    <path d="M107.65 19.82V18.15" stroke="black" stroke-width="0.5" />
    <path d="M107.65 17.32V15.65" stroke="black" stroke-width="0.5" />
    <path d="M107.65 14.82V13.15" stroke="black" stroke-width="0.5" />
    <path d="M107.65 12.31V10.65" stroke="black" stroke-width="0.5" />
    <path d="M107.65 9.81V8.14999" stroke="black" stroke-width="0.5" />
    <path d="M107.65 7.31V5.64" stroke="black" stroke-width="0.5" />
    <path d="M107.65 4.81V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M106.95 164.76V162.68" stroke="black" stroke-width="0.5" />
    <path d="M106.95 161.84V160.18" stroke="black" stroke-width="0.5" />
    <path d="M106.95 159.34V157.67" stroke="black" stroke-width="0.5" />
    <path d="M106.95 156.84V155.17" stroke="black" stroke-width="0.5" />
    <path d="M106.95 154.34V152.67" stroke="black" stroke-width="0.5" />
    <path d="M106.95 151.84V150.17" stroke="black" stroke-width="0.5" />
    <path d="M106.95 149.33V147.67" stroke="black" stroke-width="0.5" />
    <path d="M106.95 146.83V145.17" stroke="black" stroke-width="0.5" />
    <path d="M106.95 144.33V142.66" stroke="black" stroke-width="0.5" />
    <path d="M106.95 141.83V140.16" stroke="black" stroke-width="0.5" />
    <path d="M106.95 139.33V137.66" stroke="black" stroke-width="0.5" />
    <path d="M106.95 136.83V135.16" stroke="black" stroke-width="0.5" />
    <path d="M106.95 134.32V132.66" stroke="black" stroke-width="0.5" />
    <path d="M106.95 131.82V130.15" stroke="black" stroke-width="0.5" />
    <path d="M106.95 129.32V127.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 126.82V124.73" stroke="black" stroke-width="0.5" />
    <path d="M106.95 115.13V114.16" stroke="black" stroke-width="0.5" />
    <path d="M106.95 113.32V111.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 110.82V109.15" stroke="black" stroke-width="0.5" />
    <path d="M106.95 108.32V106.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 105.82V104.15" stroke="black" stroke-width="0.5" />
    <path d="M106.95 103.31V101.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 100.81V99.15" stroke="black" stroke-width="0.5" />
    <path d="M106.95 98.31V96.64" stroke="black" stroke-width="0.5" />
    <path d="M106.95 95.81V94.14" stroke="black" stroke-width="0.5" />
    <path d="M106.95 93.31V91.64" stroke="black" stroke-width="0.5" />
    <path d="M106.95 90.81V89.14" stroke="black" stroke-width="0.5" />
    <path d="M106.95 88.3V86.64" stroke="black" stroke-width="0.5" />
    <path d="M106.95 85.8V84.13" stroke="black" stroke-width="0.5" />
    <path d="M106.95 83.3V81.63" stroke="black" stroke-width="0.5" />
    <path d="M106.95 80.8V79.13" stroke="black" stroke-width="0.5" />
    <path d="M106.95 78.3V76.63" stroke="black" stroke-width="0.5" />
    <path d="M106.95 75.79V74.13" stroke="black" stroke-width="0.5" />
    <path d="M106.95 73.29V72.32" stroke="black" stroke-width="0.5" />
    <path d="M106.95 66.57V64.88" stroke="black" stroke-width="0.5" />
    <path d="M106.95 64.04V62.38" stroke="black" stroke-width="0.5" />
    <path d="M106.95 61.54V59.87" stroke="black" stroke-width="0.5" />
    <path d="M106.95 59.04V57.37" stroke="black" stroke-width="0.5" />
    <path d="M106.95 56.54V54.87" stroke="black" stroke-width="0.5" />
    <path d="M106.95 54.04V52.37" stroke="black" stroke-width="0.5" />
    <path d="M106.95 51.53V49.87" stroke="black" stroke-width="0.5" />
    <path d="M106.95 49.03V47.36" stroke="black" stroke-width="0.5" />
    <path d="M106.95 46.53V44.86" stroke="black" stroke-width="0.5" />
    <path d="M106.95 44.03V42.36" stroke="black" stroke-width="0.5" />
    <path d="M106.95 41.53V39.83" stroke="black" stroke-width="0.5" />
    <path d="M106.95 30.22V28.16" stroke="black" stroke-width="0.5" />
    <path d="M106.95 27.33V25.66" stroke="black" stroke-width="0.5" />
    <path d="M106.95 24.82V23.16" stroke="black" stroke-width="0.5" />
    <path d="M106.95 22.32V20.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 19.82V18.15" stroke="black" stroke-width="0.5" />
    <path d="M106.95 17.32V15.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 14.82V13.15" stroke="black" stroke-width="0.5" />
    <path d="M106.95 12.31V10.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 9.81V8.14999" stroke="black" stroke-width="0.5" />
    <path d="M106.95 7.31V5.64" stroke="black" stroke-width="0.5" />
    <path d="M106.95 4.81V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M106.95 15.67H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 28.48H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 41.32H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 54.03H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 75.11H107.65" stroke="black" stroke-width="0.5" />
    <path d="M110.42 72.32H104.28" stroke="black" stroke-width="0.5" />
    <path d="M104.28 66.57H110.42" stroke="black" stroke-width="0.5" />
    <path d="M105.65 69.43H108.87" stroke="black" stroke-width="0.5" />
    <path d="M105.65 70.76V68.09" stroke="black" stroke-width="0.5" />
    <path d="M105.65 70.76H108.87" stroke="black" stroke-width="0.5" />
    <path d="M108.87 68.09H105.65" stroke="black" stroke-width="0.5" />
    <path d="M104.28 66.57V72.32" stroke="black" stroke-width="0.5" />
    <path d="M106.95 115.13H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 113.57H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 124.73H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 126.41H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 139.17H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 152.13H107.65" stroke="black" stroke-width="0.5" />
    <path d="M86.52 145.59H93.53" stroke="black" stroke-width="0.5" />
    <path d="M93.53 145.59V152.35" stroke="black" stroke-width="0.5" />
    <path d="M93.53 152.35H86.52" stroke="black" stroke-width="0.5" />
    <path d="M86.52 152.35V145.59" stroke="black" stroke-width="0.5" />
    <path d="M106.95 39.83H107.65" stroke="black" stroke-width="0.5" />
    <path d="M106.95 30.22H107.65" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 166.9V66.46" stroke="black" stroke-width="0.5" />
    <path d="M0.419983 168.94V78.47" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 166.9H203.28" stroke="black" stroke-width="0.5" />
    <path d="M0.419983 168.94H214.49" stroke="black" stroke-width="0.5" />
    <path d="M15.84 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M45.19 168.11H44.66" stroke="black" stroke-width="0.5" />
    <path d="M44.66 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M44.66 166.9H45.19" stroke="black" stroke-width="0.5" />
    <path d="M45.19 166.9V168.11" stroke="black" stroke-width="0.5" />
    <path d="M15.84 166.9H16.37" stroke="black" stroke-width="0.5" />
    <path d="M16.37 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M20.69 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M21.24 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M16.37 168.11H15.84" stroke="black" stroke-width="0.5" />
    <path d="M15.84 168.11V166.9" stroke="black" stroke-width="0.5" />
    <path d="M39.82 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M39.82 166.9H40.35" stroke="black" stroke-width="0.5" />
    <path d="M40.35 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M40.35 168.11H39.82" stroke="black" stroke-width="0.5" />
    <path d="M44.66 166.9H40.35" stroke="black" stroke-width="0.5" />
    <path d="M39.82 166.9H21.24" stroke="black" stroke-width="0.5" />
    <path d="M20.69 166.9H16.37" stroke="black" stroke-width="0.5" />
    <path d="M39.82 168.11H21.24" stroke="black" stroke-width="0.5" />
    <path d="M20.69 168.11H16.37" stroke="black" stroke-width="0.5" />
    <path d="M44.66 168.11H40.35" stroke="black" stroke-width="0.5" />
    <path d="M16.37 167.79H44.66" stroke="black" stroke-width="0.5" />
    <path d="M44.66 167.52H16.37" stroke="black" stroke-width="0.5" />
    <path d="M44.66 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path
      d="M16.37 167.52V167.79L44.66 167.52H16.37Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M44.66 167.52L16.37 167.79H44.66V167.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M16.37 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path d="M20.69 168.11H21.24" stroke="black" stroke-width="0.5" />
    <path d="M20.69 166.9H21.24" stroke="black" stroke-width="0.5" />
    <path d="M96.43 168.11H95.9" stroke="black" stroke-width="0.5" />
    <path d="M95.9 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M95.9 166.9H96.43" stroke="black" stroke-width="0.5" />
    <path d="M96.43 166.9V168.11" stroke="black" stroke-width="0.5" />
    <path d="M67.08 166.9H67.61" stroke="black" stroke-width="0.5" />
    <path d="M67.61 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M71.92 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M72.48 166.9V167.79" stroke="black" stroke-width="0.5" />
    <path d="M67.61 168.11H67.08" stroke="black" stroke-width="0.5" />
    <path d="M67.08 168.11V166.9" stroke="black" stroke-width="0.5" />
    <path d="M91.05 167.52V166.9" stroke="black" stroke-width="0.5" />
    <path d="M91.05 166.9H91.59" stroke="black" stroke-width="0.5" />
    <path d="M91.59 166.9V167.52" stroke="black" stroke-width="0.5" />
    <path d="M91.59 168.11H91.05" stroke="black" stroke-width="0.5" />
    <path d="M95.9 166.9H91.59" stroke="black" stroke-width="0.5" />
    <path d="M91.05 166.9H72.48" stroke="black" stroke-width="0.5" />
    <path d="M71.92 166.9H67.61" stroke="black" stroke-width="0.5" />
    <path d="M91.05 168.11H72.48" stroke="black" stroke-width="0.5" />
    <path d="M71.92 168.11H67.61" stroke="black" stroke-width="0.5" />
    <path d="M95.9 168.11H91.59" stroke="black" stroke-width="0.5" />
    <path d="M67.61 167.79H95.9" stroke="black" stroke-width="0.5" />
    <path d="M95.9 167.52H67.61" stroke="black" stroke-width="0.5" />
    <path d="M95.9 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path
      d="M67.61 167.52V167.79L95.9 167.52H67.61Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M95.9 167.52L67.61 167.79H95.9V167.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M67.61 167.52V167.79" stroke="black" stroke-width="0.5" />
    <path d="M71.92 168.11H72.48" stroke="black" stroke-width="0.5" />
    <path d="M71.92 166.9H72.48" stroke="black" stroke-width="0.5" />
    <path d="M15.84 166.9H45.19" stroke="black" stroke-width="0.5" />
    <path d="M45.19 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M45.19 164.76H15.84" stroke="black" stroke-width="0.5" />
    <path d="M15.84 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M39.38 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M40.62 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M67.08 166.9H96.43" stroke="black" stroke-width="0.5" />
    <path d="M96.43 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M96.43 164.76H67.08" stroke="black" stroke-width="0.5" />
    <path d="M67.08 164.76V166.9" stroke="black" stroke-width="0.5" />
    <path d="M72.38 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M71.05 166.9V164.76" stroke="black" stroke-width="0.5" />
    <path d="M67.08 165.83H71.05" stroke="black" stroke-width="0.5" />
    <path d="M72.38 165.83H75.53" stroke="black" stroke-width="0.5" />
    <path d="M77.38 165.83H80.2" stroke="black" stroke-width="0.5" />
    <path d="M82.29 165.83H84.62" stroke="black" stroke-width="0.5" />
    <path d="M86.91 165.83H89.22" stroke="black" stroke-width="0.5" />
    <path d="M91.67 165.83H93.83" stroke="black" stroke-width="0.5" />
    <path d="M95.22 165.83H96.43" stroke="black" stroke-width="0.5" />
    <path d="M15.84 165.83H20.32" stroke="black" stroke-width="0.5" />
    <path d="M21.65 165.83H24.29" stroke="black" stroke-width="0.5" />
    <path d="M26.14 165.83H28.96" stroke="black" stroke-width="0.5" />
    <path d="M31.06 165.83H33.38" stroke="black" stroke-width="0.5" />
    <path d="M35.67 165.83H37.98" stroke="black" stroke-width="0.5" />
    <path d="M40.62 165.83H42.59" stroke="black" stroke-width="0.5" />
    <path d="M43.99 165.83H45.19" stroke="black" stroke-width="0.5" />
    <path d="M45.19 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M67.08 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M96.43 166.9V168.94" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 109.83V137.85" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 137.85H4.60002" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 137.85V109.83" stroke="black" stroke-width="0.5" />
    <path d="M4.60002 109.83H2.46002" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 109.83V112.97" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 114.3V116.94" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 118.79V121.61" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 123.71V126.03" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 128.32V130.63" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 133.08V135.24" stroke="black" stroke-width="0.5" />
    <path d="M3.53003 136.64V137.85" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 109.69V111.02" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 164.76V71.98" stroke="black" stroke-width="0.5" />
    <path d="M15.84 164.76H4.59998" stroke="black" stroke-width="0.5" />
    <path d="M45.19 164.76H67.08" stroke="black" stroke-width="0.5" />
    <path d="M96.43 164.76H118.32" stroke="black" stroke-width="0.5" />
    <path d="M0.429985 78.64H-28.39" stroke="black" stroke-width="0.5" />
    <path d="M0.429991 76.51H-26.26" stroke="black" stroke-width="0.5" />
    <path d="M-0.25 64.86L-0.23 64.81" stroke="black" stroke-width="0.5" />
    <path
      d="M-0.219971 64.79L-0.189972 64.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.179993 64.72L-0.159992 64.68"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.150024 64.66L-0.120026 64.61"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.109985 64.59L-0.0899849 64.55"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.0800171 64.52L-0.0700169 64.51"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.0700073 64.51L-0.0600071 64.48"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.039978 64.46L-0.0199776 64.41"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.0100098 64.39L-9.53674e-06 64.36"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M0 64.36L0.0100002 64.35" stroke="black" stroke-width="0.5" />
    <path
      d="M0.0200195 64.32L0.0500183 64.28"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.0599976 64.26L0.079998 64.21"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.0900269 64.19L0.110027 64.14"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.119995 64.12L0.139994 64.08"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.150024 64.05L0.180025 64.01"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.190002 63.99L0.210001 63.94"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M0.219971 63.92V63.9" stroke="black" stroke-width="0.5" />
    <path d="M0.219971 63.9L0.239971 63.87" stroke="black" stroke-width="0.5" />
    <path d="M0.25 63.85L0.27 63.8" stroke="black" stroke-width="0.5" />
    <path d="M0.280029 63.78L0.29003 63.75" stroke="black" stroke-width="0.5" />
    <path
      d="M0.289978 63.75L0.299976 63.73"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.309998 63.71L0.329998 63.67"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.340027 63.64L0.360027 63.59"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M-3.75 38.91L0.360001 36.55" stroke="black" stroke-width="0.5" />
    <path d="M-3.75 41.85L0.360001 39.5" stroke="black" stroke-width="0.5" />
    <path d="M-0.039978 63.04V25.93" stroke="black" stroke-width="0.5" />
    <path
      d="M-0.039978 63.04C-0.0308613 63.1561 0.011798 63.2671 0.0828114 63.3594C0.153825 63.4517 0.250134 63.5214 0.360023 63.56"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.359982 25.35C0.236172 25.3849 0.128446 25.462 0.0554127 25.5679C-0.0176205 25.6738 -0.0513752 25.8019 -0.0400196 25.93"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.359977 24.89C0.134733 24.928 -0.0692217 25.046 -0.214369 25.2224C-0.359517 25.3988 -0.436096 25.6217 -0.430024 25.85"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.359978 25.15L0.289978 24.99"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.289972 24.99L0.219971 24.84"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.220024 24.84L0.150024 24.68"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M0.150017 24.68L0.0800171 24.53"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M0.0799999 24.53L0 24.38" stroke="black" stroke-width="0.5" />
    <path
      d="M-7.62939e-06 24.38L-0.0700073 24.22"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.0700245 24.22L-0.150024 24.07"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.149981 24.07L-0.22998 23.93"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M-0.440002 63.08C-0.422204 63.2964 -0.334563 63.5013 -0.190277 63.6636C-0.045991 63.8259 0.147129 63.937 0.359999 63.98"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M4.43 66.46H1.5" stroke="black" stroke-width="0.5" />
    <path d="M1.5 66.46V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M4.59999 71.98H4.42999" stroke="black" stroke-width="0.5" />
    <path d="M4.42999 71.98V66.46" stroke="black" stroke-width="0.5" />
    <path d="M0.429993 76.51V2.74001" stroke="black" stroke-width="0.5" />
    <path d="M100.89 158.01V164.76" stroke="black" stroke-width="0.5" />
    <path d="M100.89 159.16H113.44" stroke="black" stroke-width="0.5" />
    <path d="M100.89 160.14H113.7" stroke="black" stroke-width="0.5" />
    <path d="M100.89 161.12H113.7" stroke="black" stroke-width="0.5" />
    <path d="M100.89 162.1H113.7" stroke="black" stroke-width="0.5" />
    <path d="M100.89 163.08H113.7" stroke="black" stroke-width="0.5" />
    <path d="M100.89 164.07H113.7" stroke="black" stroke-width="0.5" />
    <path d="M110.5 158.01H100.89" stroke="black" stroke-width="0.5" />
    <path d="M97.34 124.73H99.23" stroke="black" stroke-width="0.5" />
    <path d="M100.06 124.73H101.73" stroke="black" stroke-width="0.5" />
    <path d="M102.56 124.73H104.23" stroke="black" stroke-width="0.5" />
    <path d="M105.06 124.73H106.95" stroke="black" stroke-width="0.5" />
    <path d="M106.95 124.27H105.07" stroke="black" stroke-width="0.5" />
    <path d="M104.24 124.27H102.57" stroke="black" stroke-width="0.5" />
    <path d="M101.73 124.27H100.07" stroke="black" stroke-width="0.5" />
    <path d="M99.23 124.27H97.35" stroke="black" stroke-width="0.5" />
    <path d="M106.95 124.73V124.27" stroke="black" stroke-width="0.5" />
    <path d="M106.95 115.13L106.09 115.21" stroke="black" stroke-width="0.5" />
    <path d="M105.26 115.29L105.07 115.31" stroke="black" stroke-width="0.5" />
    <path d="M105.07 115.31L103.66 115.74" stroke="black" stroke-width="0.5" />
    <path d="M102.89 116.06L101.61 116.75" stroke="black" stroke-width="0.5" />
    <path d="M101.61 116.75L101.45 116.88" stroke="black" stroke-width="0.5" />
    <path d="M100.8 117.41L100.16 117.94" stroke="black" stroke-width="0.5" />
    <path d="M100.16 117.94L99.63 118.59" stroke="black" stroke-width="0.5" />
    <path d="M99.1 119.23L98.96 119.4" stroke="black" stroke-width="0.5" />
    <path d="M98.96 119.4L98.28 120.68" stroke="black" stroke-width="0.5" />
    <path d="M97.96 121.44L97.53 122.86" stroke="black" stroke-width="0.5" />
    <path d="M97.53 122.86L97.51 123.05" stroke="black" stroke-width="0.5" />
    <path d="M97.43 123.88L97.34 124.73" stroke="black" stroke-width="0.5" />
    <path d="M97.34 39.83H99.23" stroke="black" stroke-width="0.5" />
    <path d="M100.06 39.83H101.73" stroke="black" stroke-width="0.5" />
    <path d="M102.56 39.83H104.23" stroke="black" stroke-width="0.5" />
    <path d="M105.06 39.83H106.95" stroke="black" stroke-width="0.5" />
    <path d="M106.95 39.36H105.07" stroke="black" stroke-width="0.5" />
    <path d="M104.24 39.36H102.57" stroke="black" stroke-width="0.5" />
    <path d="M101.73 39.36H100.07" stroke="black" stroke-width="0.5" />
    <path d="M99.23 39.36H97.35" stroke="black" stroke-width="0.5" />
    <path d="M106.95 30.22L106.09 30.31" stroke="black" stroke-width="0.5" />
    <path d="M105.26 30.39L105.07 30.41" stroke="black" stroke-width="0.5" />
    <path d="M105.07 30.41L103.66 30.84" stroke="black" stroke-width="0.5" />
    <path d="M102.89 31.16L101.61 31.84" stroke="black" stroke-width="0.5" />
    <path d="M101.61 31.84L101.45 31.98" stroke="black" stroke-width="0.5" />
    <path d="M100.8 32.51L100.16 33.04" stroke="black" stroke-width="0.5" />
    <path d="M100.16 33.04L99.63 33.68" stroke="black" stroke-width="0.5" />
    <path d="M99.1 34.33L98.96 34.49" stroke="black" stroke-width="0.5" />
    <path d="M98.96 34.49L98.28 35.78" stroke="black" stroke-width="0.5" />
    <path d="M97.96 36.54L97.53 37.96" stroke="black" stroke-width="0.5" />
    <path d="M97.53 37.96L97.51 38.14" stroke="black" stroke-width="0.5" />
    <path d="M97.43 38.97L97.34 39.83" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 139.18V138.65" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 138.65H2.46003" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 138.65V139.18" stroke="black" stroke-width="0.5" />
    <path d="M2.46001 139.18H1.26001" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 109.83V110.36" stroke="black" stroke-width="0.5" />
    <path d="M2.46003 110.36H1.84003" stroke="black" stroke-width="0.5" />
    <path d="M2.46001 114.67H1.57001" stroke="black" stroke-width="0.5" />
    <path d="M2.46001 115.23H1.57001" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 110.36V109.83" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 109.83H2.46001" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 133.8H2.46003" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 133.8V134.34" stroke="black" stroke-width="0.5" />
    <path d="M2.46003 134.34H1.84003" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 134.34V133.8" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 138.65V134.34" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 133.8V115.23" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 114.67V110.36" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 133.8V115.23" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 114.67V110.36" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 138.65V134.34" stroke="black" stroke-width="0.5" />
    <path d="M1.57001 110.36V138.65" stroke="black" stroke-width="0.5" />
    <path d="M1.84003 138.65V110.36" stroke="black" stroke-width="0.5" />
    <path d="M1.84001 138.65H1.57001" stroke="black" stroke-width="0.5" />
    <path
      d="M1.84001 110.36H1.57001L1.84001 138.65V110.36Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M1.84001 138.65L1.57001 110.36V138.65H1.84001Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M1.84001 110.36H1.57001" stroke="black" stroke-width="0.5" />
    <path d="M1.26001 114.67V115.23" stroke="black" stroke-width="0.5" />
    <path d="M2.46002 114.67V115.23" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 139.18H0.419983" stroke="black" stroke-width="0.5" />
    <path d="M4.59998 109.83H0.419983" stroke="black" stroke-width="0.5" />
    <path d="M19.11 11.48L18.92 9.60001" stroke="black" stroke-width="0.5" />
    <path d="M18.92 9.60001L18.38 7.8" stroke="black" stroke-width="0.5" />
    <path d="M18.38 7.8L17.49 6.14" stroke="black" stroke-width="0.5" />
    <path d="M17.49 6.14L16.29 4.67999" stroke="black" stroke-width="0.5" />
    <path d="M16.29 4.67999L14.84 3.49001" stroke="black" stroke-width="0.5" />
    <path d="M14.84 3.49001L13.17 2.60001" stroke="black" stroke-width="0.5" />
    <path d="M13.17 2.60001L11.37 2.06" stroke="black" stroke-width="0.5" />
    <path d="M11.37 2.06L9.5 1.87" stroke="black" stroke-width="0.5" />
    <path d="M9.5 11.48H9.97" stroke="black" stroke-width="0.5" />
    <path d="M9.96997 11.48V1.88" stroke="black" stroke-width="0.5" />
    <path d="M9.5 1.87V11.48" stroke="black" stroke-width="0.5" />
    <path d="M86.48 11.48L86.67 9.60001" stroke="black" stroke-width="0.5" />
    <path d="M86.67 9.60001L87.22 7.8" stroke="black" stroke-width="0.5" />
    <path d="M87.22 7.8L88.1 6.14" stroke="black" stroke-width="0.5" />
    <path d="M88.1 6.14L89.3 4.67999" stroke="black" stroke-width="0.5" />
    <path d="M89.3 4.67999L90.76 3.49001" stroke="black" stroke-width="0.5" />
    <path d="M90.76 3.49001L92.42 2.60001" stroke="black" stroke-width="0.5" />
    <path d="M92.42 2.60001L94.22 2.06" stroke="black" stroke-width="0.5" />
    <path d="M94.22 2.06L96.09 1.87" stroke="black" stroke-width="0.5" />
    <path d="M96.09 11.48H95.62" stroke="black" stroke-width="0.5" />
    <path d="M95.62 11.48L95.63 1.88" stroke="black" stroke-width="0.5" />
    <path d="M96.09 1.87V11.48" stroke="black" stroke-width="0.5" />
  </svg>
</template>
