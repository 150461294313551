<template>
  <v-container fluid>
    <v-form @submit.prevent="submit" validate-on="input">
      <v-card elevation="1" class="pa-4 pt-6 mt-n4 ml-n4">
        <v-row>
          <v-col cols="6">
            <v-text-field
              autofocus
              v-model="getAHUState.controlFriendlyName"
              label="AHU Friendly Name"
              :rules="[(v) => !!v || 'Name is required']"
              required
              variant="outlined"
              density="compact"
            />
          </v-col>
        </v-row>
        <v-row class="mt-n4 ml-n5">
          <v-col cols="6">
            <v-col>
              <v-btn type="submit" color="primary"> Save </v-btn>
              <v-btn
                class="ml-5"
                @click="router.push({ name: 'AHUsPortfolio' })"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();

const store = useStore();
const getAHUState = computed(
  () => store.getters['ControlsStore/AHUsStore/getEditAHU']
);

const submit = async (
  event: PromiseLike<{ valid: boolean }> | { valid: boolean }
) => {
  const { valid } = await event;
  if (!valid) {
    return Promise.reject({
      Error: 'Please fill in all required fields',
    });
  }
  router.push({ name: 'AHUsPortfolio' });
};
</script>
