<template>
  <v-dialog v-model="dialogValue" persistent max-width="290">
    <v-card>
      <v-card-title class="text-primary text-h5">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text class="text-primary">
        {{ dialogConfirm }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-2"
          color="primary"
          variant="flat"
          @click="dialogValue = false"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
          >
            Cancel
          </div>
        </v-btn>
        <v-btn
          class="rounded-2"
          color="warning"
          variant="flat"
          :loading="loadingDelete"
          :disabled="loadingDelete"
          @click="handleDelete"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
          >
            Delete
          </div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/Core.Service.Domain/Analytics/Measures/API';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ConfirmDialogDeleteMeasure',

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    measureInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadingDelete: false,
    };
  },

  computed: {
    ...mapGetters({
      defaultSite: 'session/defaultSite',
    }),

    dialogValue: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },

    dialogTitle() {
      return `Delete this ${this.$route.meta.toolTip}?`;
    },

    dialogConfirm() {
      return `Are you sure you want to delete ${this.measureInfo.measureName}? Deleting a ${this.$route.meta.toolTip} cannot be undone.`;
    },
  },

  methods: {
    ...mapActions({
      getAnalyticsMeasures:
        'AnalyticsStore/AnalyticsMeasuresStore/getAnalyticsMeasures',
    }),

    async handleDelete() {
      const { id } = this.measureInfo;
      try {
        this.loadingDelete = true;
        await API.deleteAnalyticsMeasure(id);
        await this.getAnalyticsMeasures();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.dialogValue = false;
        this.loadingDelete = false;
      }
    },
  },
};
</script>
