<template>
  <div ref="containerRef" class="scroll-container" @scroll="handleScroll">
    <!-- Initial Loader (when first fetching data) -->
    <v-row v-if="isLoading">
      <ProgressLinearLoaderVue :isLoading="isLoading" />
    </v-row>

    <!-- List of VAV Cards -->
    <v-row>
      <v-col v-for="vav in visibleItems" :key="vav.controlId">
        <VAVCard :VAVInfo="vav" />
      </v-col>
    </v-row>

    <!-- Spinner when loading more items -->
    <v-row v-if="isFetchingMore" class="loader-container">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  watch,
  onMounted,
  defineAsyncComponent,
  nextTick,
} from 'vue';
import { useStore } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

const VAVCard = defineAsyncComponent(
  () => import('@/Core.Service.Domain/Controls/VAVs/Components/VAVCard.vue')
);

import { IVAV } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';
import API from '@/Core.Service.Domain/Controls/VAVs/API/index.ts';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

const VAVsList = ref<IVAV[]>([]);
const isLoading = ref(true);
const isFetchingMore = ref(false);

const emits = defineEmits(['update:VAVsList', 'update:isLoading']);
const store = useStore();
const defaultSite = computed(() => store.getters['session/defaultSite']);

const searchTerm = computed(
  () => store.getters['ControlsStore/getControlSearchTerm']
);

const filteredItems = ref<IVAV[]>([]);
const visibleItems = ref<IVAV[]>([]);
const containerRef = ref<HTMLElement | null>(null);

const chunkSize = 20;

const updateVisibleItems = async () => {
  if (visibleItems.value.length >= filteredItems.value.length) return;

  isFetchingMore.value = true;
  await new Promise((resolve) => setTimeout(resolve, 500));

  const nextBatch = filteredItems.value.slice(
    visibleItems.value.length,
    visibleItems.value.length + chunkSize
  );

  visibleItems.value = [...visibleItems.value, ...nextBatch];
  isFetchingMore.value = false;
};

const handleScroll = () => {
  if (!containerRef.value || isFetchingMore.value) return;

  const { scrollTop, clientHeight, scrollHeight } = containerRef.value;

  if (scrollTop + clientHeight >= scrollHeight - 150) {
    updateVisibleItems();
  }
};

onMounted(async () => {
  await loadVavs();
  updateVisibleItems();
  await nextTick();
  if (containerRef.value) {
    containerRef.value.addEventListener('scroll', handleScroll);
  }
});

// Load VAVs
const loadVavs = async () => {
  isLoading.value = true;
  emits('update:isLoading', isLoading.value);
  try {
    VAVsList.value = await API.getVAVsBySiteId(defaultSite.value.id);
    emits('update:VAVsList', VAVsList.value.length);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    isLoading.value = false;
    emits('update:isLoading', isLoading.value);
    filteredItems.value = [...VAVsList.value];
    visibleItems.value = [];
    updateVisibleItems();
  }
};

watch(searchTerm, () => {
  filteredItems.value = searchByTermFactory(
    searchTerm.value,
    ['controlName', 'controlFriendlyName', 'controlId', 'siteLocationName'],
    VAVsList.value
  );
  visibleItems.value = [];
  updateVisibleItems();
});

watch(VAVsList, () => {
  filteredItems.value = sortAlphabetically(
    VAVsList.value,
    'controlFriendlyName'
  );
  visibleItems.value = [];
  updateVisibleItems();
});

watch(defaultSite, () => {
  loadVavs();
});
</script>

<style lang="scss" scoped>
.scroll-container {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  padding: 102px 50px;
  margin-left: -50px;
  margin-top: -100px;
  scrollbar-width: none;
}

.loader-container {
  display: flex;
  justify-content: center;
  margin-top: -20%;
}

.v-col {
  flex-grow: 0;
}
</style>
