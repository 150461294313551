<template>
  <div>
    <v-row v-if="isLoading">
      <ProgressLinearLoaderVue :isLoading="isLoading" />
    </v-row>
    <v-row>
      <v-col
        v-for="ahu in filteredItems.length ? filteredItems : AHUsList"
        :key="ahu.controlId"
      >
        <!-- AHU Card -->
        <AHUCard :AHUDevice="ahu" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

const AHUCard = defineAsyncComponent(
  () => import('@/Core.Service.Domain/Controls/AHUs/Components/AHUCard.vue')
);

import { IAHU } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

import API from '@/Core.Service.Domain/Controls/AHUs/API/index.ts';

import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

const AHUsList = ref<IAHU[]>([]);
const isLoading = ref(true);

const emits = defineEmits(['update:AHUsList', 'update:isLoading']);

const store = useStore();
const defaultSite = computed(() => store.getters['session/defaultSite']);

onMounted(async () => {
  await loadAHUs();
});

const searchTerm = computed(
  () => store.getters['ControlsStore/getControlSearchTerm']
);

const filteredItems = ref<IAHU[]>([]);

const loadAHUs = async () => {
  isLoading.value = true;
  emits('update:isLoading', isLoading.value);
  try {
    AHUsList.value = await API.getAHUsBySiteId(defaultSite.value.id);
    emits('update:AHUsList', AHUsList.value.length);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    isLoading.value = false;
    emits('update:isLoading', isLoading.value);
  }
};

watch(searchTerm, () => {
  filteredItems.value = searchByTermFactory(
    searchTerm.value,
    ['controlName', 'controlFriendlyName', 'controlId', 'siteLocationName'],
    AHUsList.value
  );
});

watch(AHUsList, () => {
  filteredItems.value = sortAlphabetically(
    AHUsList.value,
    'controlFriendlyName'
  );
});

watch(defaultSite, () => {
  loadAHUs();
});
</script>

<style lang="scss" scoped>
.v-col {
  flex-grow: 0;
}
</style>
