import actions from '@/Core.Service.Domain/Controls/Controls.Common/Store/actions.js';
import getters from '@/Core.Service.Domain/Controls/Controls.Common/Store/getters.js';
import mutations from '@/Core.Service.Domain/Controls/Controls.Common/Store/mutations.js';

import ThermostatsStore from '@/Core.Service.Domain/Controls/Thermostats/Store';
import PowerControlsStore from '@/Core.Service.Domain/Controls/PowerControls/Store';

import DaikinThermostatsStore from '@/Core.Service.Domain/Controls/DaikinThermostats/Store';

import VAVsStore from '@/Core.Service.Domain/Controls/VAVs/Store/index.ts';
import AHUsStore from '@/Core.Service.Domain/Controls/AHUs/Store/index.ts';

const state = {
  controlCurrentRoute: '',
  sitesWithControls: [],
  selectedSiteControls: [],
  controlSearchTerm: '',
  controlSettingsHistoryMessagesTimerOn: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    ThermostatsStore,
    PowerControlsStore,
    DaikinThermostatsStore,
    VAVsStore,
    AHUsStore,
  },
};
