import constants from '../constants';

let firstSiteName = '';

const setSlicers = async function (reportParameters, visuals) {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setDateSlicerVisual(reportParameters, visuals);
  await setEndUseSlicerVisual(reportParameters, visuals);
};
const setSiteSlicerVisual = async function (reportParameters, visuals) {
  const siteSlicerFilter = {
    $schema: constants.ADVANCED_FILTER,
    target: {
      table: 'vwSite',
      column: 'SiteName',
    },
    operator: 'In',
    values: [reportParameters.SiteName],
  };
  const siteSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState({
      filters: [siteSlicerFilter],
    });
  }
};

const setDateSlicerVisual = async function (reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: constants.ADVANCED_FILTER,
    target: {
      table: 'vwEndUseOneMinDirectQuery',
      column: 'SiteDateTimeOffset',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };

  const dateSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Slicer';
  })[0];

  if (dateSlicerVisual) {
    setEndUseSlicerVisual(reportParameters, visuals);
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
  }
};

const setEndUseSlicerVisual = async function (reportParameters, visuals) {
  try {
    const siteData = getSiteDataBySiteName(reportParameters);
    const { SiteName } = reportParameters;

    const endUseSlicerVisual = visuals.filter(function (visual) {
      return visual.type === 'slicer' && visual.title === 'End Use Slicer';
    })[0];

    if (!endUseSlicerVisual) {
      return Promise.reject(new Error('End Use Slicer visual not found'));
    }

    const values = siteData ? [siteData.EndUseName] : ['No Data to show'];

    if (!SiteName) {
      try {
        const endUseSlicerState = await endUseSlicerVisual.getSlicerState();
        await endUseSlicerVisual.setSlicerState(endUseSlicerState);
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      await endUseSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: constants.BASIC_FILTER,
            target: {
              table: 'vwEndUseNames',
              column: 'EndUseName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Retrieves the first site data based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @param {String} uomName - The UOM name.
 * @returns {Array} - The first site data.
 */
const getSiteDataBySiteName = (reportParameters) => {
  try {
    const { SiteName, SiteEndUses } = reportParameters;
    if (SiteEndUses) {
      const parsedData = JSON.parse(SiteEndUses);

      const trimmedSiteName = firstSiteName.length
        ? firstSiteName
        : SiteName.trim().toLowerCase();

      const response = parsedData.find((data) => {
        return data.SiteName.trim().toLowerCase() === trimmedSiteName;
      });

      return !response ? parsedData[0] : response;
    }

    return null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  setSlicers,
  setDateSlicerVisual,
  setEndUseSlicerVisual,
};
