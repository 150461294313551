<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <UsersBar v-model="userSearch" @add-user="addNewUser" />
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirmDelete" title="Delete User?" />

    <div class="mt-8">
      <v-data-table
        :headers="headers"
        :items="filteredUsers"
        :items-per-page="10"
        v-model:selected="selectedUsers"
        class="elevation-1"
        :loading="loading"
        :loading-text="`Loading... One moment`"
      >
        <template #[`item.emailVerified`]="{ item }">
          <v-icon v-if="!item.isSSO" color="green" size="small">
            mdi-check
          </v-icon>
          <v-tooltip location="right" v-if='item.isSSO'>
            <template #activator="{ props }">
              <v-icon v-bind="props" color="info" size="small"
              >
                mdi-account-check
                </v-icon
              >
            </template>
            <span>
              Email confirmation does not apply to SSO users
              coming from external systems, <br />
              <em>{{ item.connection }}</em> in this case.
            </span>
          </v-tooltip>
        </template>

        <template #[`item.lastLogin`]="{ item }">
          {{ item.lastLogin ? fromNow(item.lastLogin) : 'Never' }}
        </template>

        <template #[`item.createdAt`]="{ item }">
          {{ fromNow(item.createdAt) }}
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn
              v-if="!showOrphans && !item.emailVerified"
              icon="mdi-email-fast-outline"
              size="medium"
              variant="text"
              density="comfortable"
              title="Resend invitation to this user"
              @click="handleReinviteUser(item)"
            />

            <v-btn
              v-if="!showOrphans"
              class="mx-2"
              icon="mdi-pencil"
              size="medium"
              variant="text"
              density="comfortable"
              @click="editItem(item)"
            />

            <v-btn
              icon="mdi-delete-outline"
              size="medium"
              variant="text"
              density="comfortable"
              @click="handleUserDelete(item.userId)"
            />
          </div>
        </template>
      </v-data-table>

      <v-row>
        <v-col sm="2">
          <v-checkbox
            v-model="showOrphans"
            class="pl-3"
            label="Show orphans"
            @update:modelValue="loadOrphans"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-tooltip location="right">
            <template #activator="{ props }">
              <v-icon v-bind="props" class="mt-5 ml-n8"
                >mdi-help-circle-outline</v-icon
              >
            </template>
            <span>
              Orphan users are configured in Auth0 but have no corresponding
              entry in {{ appName }} User table
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script setup>
import { ref, computed, onMounted, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';
import api from '../API';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog.vue';
import UsersBar from '@/Core.Service.Domain/Users/Components/UsersBar.vue';

const router = useRouter();
const store = useStore();
const isSSOUser = inject('isSSOUser');

const headers = [
  { title: 'First Name', key: 'firstName' },
  { title: 'Last Name', key: 'lastName' },
  { title: 'Email', key: 'email' },
  { title: 'Email Verified', key: 'emailVerified' },
  { title: 'Login Count', key: 'loginsCount' },
  { title: 'Last Logged In At', key: 'lastLogin' },
  { title: 'Created At', key: 'createdAt' },
  { title: 'Actions', key: 'actions', sortable: false },
];

const users = ref([]);
const userSearch = ref('');
const selectedUsers = ref([]);
const loading = ref(true);
const showOrphans = ref(false);
const appName = import.meta.env.VITE_APP_BRANDING;
const confirmDelete = ref(null);

const filteredUsers = computed(() => {
  if (!userSearch.value) return users.value;
  const searchTerm = userSearch.value.toLowerCase();
  return users.value.filter(
    (user) =>
      user.firstName.toLowerCase().includes(searchTerm) ||
      user.lastName.toLowerCase().includes(searchTerm) ||
      user.email.toLowerCase().includes(searchTerm)
  );
});

onMounted(() => {
  getUsers();
});

const fromNow = (date) => {
  return moment(date).fromNow();
};

const editItem = async (item) => {
  try {
    await store.dispatch('AdminStore/setEditUser', item);
    await router.push({
      name: 'UserEdit',
      params: { userId: item.userId },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUsers = async () => {
  const apiUsers = await api.getAllUsers();
  for (const user of apiUsers) {
    // users with emailVerified === null are external (i.e. SSO) users
    // so let's mark them as verified
    if (user.emailVerified === null) {
      user.emailVerified = true;
      user.isSSO = isSSOUser(user.userId);
    }
  }
  users.value = apiUsers;
  loading.value = false;
};

const loadOrphans = async (state) => {
  loading.value = true;
  if (state === true) {
    users.value = await api.getOrphanAuth0Users();
  } else {
    await getUsers();
  }
  loading.value = false;
};

const handleUserDelete = async (userId) => {
  const msg = 'Are you sure you want to delete this user? Deleting user cannot be undone.';
  const confirmed = await confirmDelete.value.confirm(msg);

  if (!confirmed) return;

  try {
    await deleteUser(userId);
    await loadOrphans(showOrphans.value);
  } catch (error) {
    return Promise.reject(error);
  }
};

const handleReinviteUser = async (item) => {
  await api.reInviteUser(item.userId);
  console.log(`${item.email} has been reinvited.`);
};

const deleteUser = async (userId) => {
  if (showOrphans.value) {
    await api.deleteOrphanedAuth0User(userId);
  } else {
    await api.deleteUser(userId);
  }
};

const addNewUser = () => {
  router.push({ name: 'UserCreate' });
};
</script>
