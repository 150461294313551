<template>
  <div ref="containerRef" class="scroll-container" @scroll="handleScroll">
    <v-row>
      <v-col v-for="site in visibleItems" :key="site.siteId">
        <SiteCardVue :siteInfo="site" />
      </v-col>
    </v-row>

    <v-row v-if="isFetchingMore" class="loader-container">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  watch,
  defineAsyncComponent,
  nextTick,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

const SiteCardVue = defineAsyncComponent({
  loader: () =>
    import(
      '@/Core.Service.Domain/ViewSupport/Portfolio/Components/SiteCard.vue'
    ),
});

import { ISite } from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/types/Site.ts';

const props = defineProps<{
  portfolioList: Array<ISite>;
}>();

const store = useStore();
const searchTerm = computed(() => {
  return store.getters['ViewSupportStore/PortfolioStore/getSiteSearchTerm'];
});

const filteredItems = ref<Array<ISite>>([]);
const visibleItems = ref<Array<ISite>>([]);
const containerRef = ref<HTMLElement | null>(null);
const isFetchingMore = ref(false);
const chunkSize = 20;

const getSites = computed(() => {
  return filteredItems.value.length
    ? sortAlphabetically(filteredItems.value)
    : sortAlphabetically(props.portfolioList);
});

const updateVisibleItems = async () => {
  if (visibleItems.value.length >= getSites.value.length) return;

  isFetchingMore.value = true;
  await new Promise((resolve) => setTimeout(resolve, 500));

  const nextBatch = getSites.value.slice(
    visibleItems.value.length,
    visibleItems.value.length + chunkSize
  );

  visibleItems.value = [...visibleItems.value, ...nextBatch];
  isFetchingMore.value = false;
};

const handleScroll = () => {
  if (!containerRef.value || isFetchingMore.value) return;

  const { scrollTop, clientHeight, scrollHeight } = containerRef.value;

  if (scrollTop + clientHeight >= scrollHeight - 50) {
    updateVisibleItems();
  }
};

watch(searchTerm, () => {
  filteredItems.value = searchByTermFactory<ISite>(
    searchTerm.value,
    ['streetAddress', 'name'],
    props.portfolioList
  );
  visibleItems.value = [];
  updateVisibleItems();
});

watch(
  () => props.portfolioList,
  () => {
    filteredItems.value = [];
    visibleItems.value = [];
    updateVisibleItems();
  },
  { immediate: true }
);

onMounted(async () => {
  updateVisibleItems();
  await nextTick();
  if (containerRef.value) {
    containerRef.value.addEventListener('scroll', handleScroll);
  }
});
</script>

<style lang="scss" scoped>
.scroll-container {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  padding: 134px 50px;
  margin-left: -50px;
  margin-top: -100px;
  scrollbar-width: none;
}

.loader-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.v-col {
  flex-grow: 0;
}
</style>
