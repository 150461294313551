import AdminView from '@/Core.Service.Domain/Admin/Views';

// Monitors Domain
import { MonitorsRoute } from '@/Core.Service.Domain/Monitors/Monitors.Common/Routes';

// These views should be refactored to be part of the new Aginova's Domains and should be under Monitors, as its children.
import {
  AginovaDataRecoveryRoute,
  AginovaLoginCreateRoute,
  AginovaLoginEditRoute,
  AginovaLoginIndexRoute,
} from '@/Core.Service.Domain/Monitors/Aginova/Routes';
// End of Aginova Login routes.

import MessagesCenterRoutes from '@/Core.Service.Domain/MessageCenter/MessageCenter.Common/Routes';
import UsersRoutes from '@/Core.Service.Domain/MessageCenter/Users/Routes';

import DemandResponseRoutes from '@/Core.Service.Domain/Strategies/DemandResponse/Routes';

const AdminRoutes = {
  path: '/admin',
  name: 'Admin',
  children: [
    MonitorsRoute,    
    AginovaLoginIndexRoute,
    AginovaLoginCreateRoute,
    AginovaLoginEditRoute,
    AginovaDataRecoveryRoute,
    ...MessagesCenterRoutes,
    ...UsersRoutes,
    DemandResponseRoutes,
  ],
  component: AdminView,
};

export default [AdminRoutes];
