<template>
  <div
    class="image-container"
    :style="{ padding: isMiniMap ? '0' : '50px 0' }"
    :id="elementUUID"
    @wheel="onWheel"
    @mousedown="startPan"
    @mousemove="onPan"
    @mouseup="endPan"
    @mouseleave="endPan"
    @touchstart="startPan"
    @touchmove="onPan"
    @touchend="endPan"
  >
    <!-- Zoomable Content -->
    <div
      class="zoomable-content"
      :id="zoomableContentUUID"
      :style="{
        width: !isMiniMap ? `${zoomableContentWith}%` : '100%',
        height: !isMiniMap ? 'auto' : '100%',
        ...imageStyle,
      }"
    >
      <!-- Base Image Slot -->
      <slot name="Image"></slot>
      <!-- Hotspot Slot Positioned Above the Image -->
      <slot name="Hotspot"></slot>
    </div>

    <!-- Buttons for Zoom Controls -->
    <div class="controls" v-if="!isMiniMap">
      <v-btn :disabled="scale === 5" @click="zoomIn">
        <v-icon color="primary">mdi-plus-thick</v-icon>
      </v-btn>
      <v-btn :disabled="scale === 1" @click="zoomOut">
        <v-icon color="primary">mdi-minus-thick</v-icon>
      </v-btn>
      <v-btn :disabled="!displayResetButton" @click="resetPosition">
        <v-icon color="primary">mdi-magnify-remove-cursor</v-icon>
      </v-btn>
    </div>

    <!-- AHU Button -->
    <AHUButtonComponent
      v-if="showAHUButton && !isMiniMap && !isDialog"
      :isAHULoading="isAHULoading"
      @update:ahu-settings="$emit('update:ahu-settings')"
      @get:ahu-id="$emit('get:ahu-id')"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useDisplay } from 'vuetify';
import { generateUUID } from '@/Core.Patterns/Factory';

import AHUButtonComponent from '@/Core.Service.Domain/Controls/DigitalTwin/Components/AHUButtonComponent.vue';

const elementUUID = generateUUID();
const zoomableContentUUID = generateUUID();

const { name } = useDisplay();

const zoomableContentWith = computed(() => {
  if (name.value === 'lg') return 60;
  if (name.value === 'xl') return 70;
  if (name.value === 'xxl') return 75;
  return 100;
});

const props = defineProps<{
  showAHUButton?: boolean;
  isMiniMap?: boolean;
  isDialog?: boolean;
  isAHULoading?: boolean;
}>();

const imageContainerWidth = ref(0);
const imageWidth = ref(0);
const imageContainer = ref<HTMLElement | null>(null);
const imageElement = ref<HTMLElement | null>(null);

let observer: ResizeObserver | null = null;
onMounted(() => {
  imageContainer.value = document.getElementById(elementUUID);
  imageElement.value = document.getElementById(zoomableContentUUID);

  if (
    imageContainer.value &&
    imageElement.value &&
    !props.isMiniMap &&
    !props.isDialog
  ) {
    observer = new ResizeObserver(() => {
      imageContainerWidth.value = imageContainer.value!.clientWidth;
      imageWidth.value = imageElement.value!.clientWidth;
      position.value.x = (imageContainerWidth.value - imageWidth.value) / 2;
    });

    observer.observe(imageContainer.value);
    observer.observe(imageElement.value);
  }
});

onBeforeUnmount(() => {
  if (observer) observer.disconnect();
});

type Position = { x: number; y: number };

const scale = ref(1);
const position = ref<Position>({ x: 0, y: 0 });

const panStart = ref<Position | null>(null);

const imageStyle = computed(() => ({
  transform: `scale(${scale.value}) translate(${position.value.x}px, ${position.value.y}px)`,
  transformOrigin: 'center center',
}));

const displayResetButton = computed(
  () =>
    scale.value !== 1 ||
    position.value.x !== (imageContainerWidth.value - imageWidth.value) / 2 ||
    position.value.y !== 0
);

const onWheel = (event: WheelEvent) => {
  event.preventDefault();
  const zoomIntensity = 0.1;
  const direction = event.deltaY > 0 ? -1 : 1;
  scale.value = Math.min(
    Math.max(scale.value + direction * zoomIntensity, 1),
    5
  );
};

const startPan = (event: MouseEvent | TouchEvent) => {
  event.preventDefault();
  const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
  const clientY = 'touches' in event ? event.touches[0].clientY : event.clientY;
  panStart.value = {
    x: clientX - position.value.x,
    y: clientY - position.value.y,
  };
};

const onPan = (event: MouseEvent | TouchEvent) => {
  if (!panStart.value) return;

  const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
  const clientY = 'touches' in event ? event.touches[0].clientY : event.clientY;

  position.value.x = clientX - panStart.value.x;
  position.value.y = clientY - panStart.value.y;
};

const endPan = () => {
  panStart.value = null;
};

const zoomIn = () => {
  scale.value = Math.min(scale.value + 0.2, 5); // Limit max zoom to 5x
};

const zoomOut = () => {
  scale.value = Math.max(scale.value - 0.2, 1); // Limit min zoom to 1x
};

const resetPosition = () => {
  scale.value = 1;
  position.value = {
    x: (imageContainerWidth.value - imageWidth.value) / 2,
    y: 0,
  }; // Reset to initial position
};
</script>

<style lang="scss" scoped>
.image-container {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: grab;
}

.image-container:active {
  cursor: grabbing;
}

.zoomable-content {
  position: relative;
  transition: transform 0.2s ease;
}

.zoomable-content slot[name='Image'] {
  position: relative;
}

.hotspot-layer slot[name='Hotspot'] {
  pointer-events: auto;
}

/* Buttons for zoom controls */
.controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.controls button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  text-transform: none;
  color: #2e3c54;
}

.ahu-button button {
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  text-transform: none;
}

.controls button:hover,
.ahu-button button:hover {
  background-color: #f0f0f0;
}

.ahu-button {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;
}
</style>
