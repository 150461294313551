<template>
  <v-card class="strategies-bar">
    <v-app-bar elevation="1" class="px-11">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        {{ getTitle }}
      </v-app-bar-title>
      <v-row>
        <v-col>
          <v-autocomplete
            v-if="!isCreateRoute && !isEditRoute"
            class="mt-6 mr-5"
            :items="sitesWithStrategies"
            :item-title="(item) => item.name || ''"
            v-model="selectedSite"
            bg-color="white"
            color="primary"
            variant="outlined"
            density="compact"
            return-object
            @update:modelValue="setDefaultSiteFromStrategiesDomain"
          >
            <template v-slot:label>
              <span class="text-primary text-caption font-weight-bold">
                Site
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            v-if="!isCreateRoute && !isEditRoute"
            class="mt-6 mr-5"
            v-model="searchTerm"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            bg-color="white"
            variant="outlined"
            density="compact"
            :placeholder="placeholder"
            :clearable="true"
            @click:clear="handleSearchInput"
            @update:modelValue="handleSearchInput"
          />
        </v-col>
      </v-row>

      <v-btn
        v-if="userCanUpdate && !isCreateRoute && !isEditRoute"
        class="rounded-2 mr-3"
        color="primary"
        variant="flat"
        size="large"
        @click="handleAddStrategy"
      >
        <div class="text-subtitle-2 text-white text-truncate font-weight-bold">
          New Strategy
        </div>
      </v-btn>
      <v-spacer />
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'StrategiesBar',

  data() {
    return {
      searchTerm: '',
      selectedSite: null,
    };
  },

  async created() {
    await this.getSitesWithStrategies();
    this.setDefaultSiteFromStrategiesDomain(this.defaultSite);
  },

  beforeUnmount() {
    this.setStrategyCurrentRoute('');
  },

  computed: {
    ...mapGetters({
      sitesWithStrategies:
        'StrategiesStore/StrategiesStore/sitesWithStrategies',
      defaultSite: 'session/defaultSite',
    }),

    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Strategy';
    },

    userCanUpdate() {
      return this.$can('update', 'controls');
    },

    isCreateRoute() {
      return this.$route.name === 'StrategiesCreate';
    },

    isEditRoute() {
      return this.$route.name === 'StrategiesEdit';
    },

    getTitle() {
      return this.isCreateRoute
        ? 'New Strategy'
        : this.isEditRoute
        ? 'Edit Strategy'
        : 'My Strategies';
    },
  },

  watch: {
    $route() {
      this.setDefaultSiteFromStrategiesDomain(this.defaultSite);
      this.clearSearchTerm();
    },
  },

  methods: {
    ...mapActions({
      getSitesWithStrategies:
        'StrategiesStore/StrategiesStore/getSitesWithStrategies',
      getStrategiesBySiteId:
        'StrategiesStore/StrategiesStore/getStrategiesBySiteId',
      setStrategyCurrentRoute:
        'StrategiesStore/StrategiesStore/setStrategyCurrentRoute',
      setDefaultSite: 'session/setDefaultSite',
    }),

    ...mapMutations({
      setStrategySearchTerm:
        'StrategiesStore/StrategiesStore/STRATEGY_SEARCH_TERM',
    }),

    setDefaultSiteFromStrategiesDomain(selectedSite) {
      this.selectedSite = selectedSite || this.sitesWithStrategies[0];

      this.getStrategiesBySiteId({
        selectedSite: this.selectedSite,
      }).then(() => {
        this.setStrategyCurrentRoute(this.$route.name);
        this.setDefaultSite(this.selectedSite);
        this.clearSearchTerm();
      });
    },

    handleAddStrategy() {
      if (!this.userCanUpdate) return;
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({
        name: 'StrategiesCreate',
        params: { siteId: this.selectedSite.siteId || this.selectedSite.id },
      });
    },

    handleSearchInput(value) {
      if (typeof value === 'object') {
        this.setStrategySearchTerm('');
      } else {
        this.setStrategySearchTerm(value);
      }
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setStrategySearchTerm('');
    },
  },
};
</script>
<style lang="scss" scoped>
.strategies-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 700px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
