//Sensor Readings
//Site Slicer - vwSite - SiteName
//Date Slicer - vwReportingDateTime - Date
//UOM Slicer - UnitOfMeasureSelection - UnitOfMeasureName
//Sensor Slicer - vwSensorNames - SensorName

import constants from '../constants';

let firstSiteName = '';
let firstLoad = true;

/**
 * Sets the slicer visuals for the report.
 * @param {Object} reportParameters - The report parameters.
 * @param {Array} visuals - The visuals array.
 * @returns {Promise<void>}
 */
const setSlicers = async (reportParameters, visuals) => {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setDateSlicerVisual(reportParameters, visuals);
  await setUOMSlicerVisual(reportParameters, visuals);
  await setSensorSlicerVisual(reportParameters, visuals);
};

/**
 * Sets the site slicer visual based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @param {Array} visuals - The visuals array.
 * @returns {Promise<void>}
 */
const setSiteSlicerVisual = async (reportParameters, visuals) => {
  try {
    const siteData = getSiteDataBySiteName(reportParameters);
    const { SiteName } = reportParameters;

    const siteSlicerVisual = visuals.filter(function (visual) {
      return visual.type === 'slicer' && visual.title === 'Site Slicer';
    })[0];

    const values = siteData ? [siteData.SiteName] : [SiteName];

    if (!SiteName && !siteData) {
      const siteSlicerState = await siteSlicerVisual.getSlicerState();

      if (siteSlicerVisual) {
        await siteSlicerVisual.setSlicerState(siteSlicerState);
      }
    } else {
      await siteSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: constants.BASIC_FILTER,
            target: {
              table: 'vwSite',
              column: 'SiteName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Sets the date slicer visual based on the StartDate and EndDate parameters.
 * @param {Object} reportParameters - The report parameters.
 * @param {Array} visuals - The visuals array.
 * @returns {Promise<void>}
 */
const setDateSlicerVisual = async (reportParameters, visuals) => {
  const dateSlicerFilter = {
    $schema: constants.ADVANCED_FILTER,
    target: {
      table: 'vwReportingDateTime',
      column: 'Date',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };

  const dateSlicerVisual = visuals.filter((visual) => {
    return visual.type === 'slicer' && visual.title === 'Date Slicer';
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
  }
};

/**
 * Sets the UOM slicer visual based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @param {Array} visuals - The visuals array.
 * @param {Boolean} unsetFirstLoad - Whether to unset the first load.
 * @returns {Promise<void>}
 */
const setUOMSlicerVisual = async (
  reportParameters,
  visuals,
  unsetFirstLoad = false
) => {
  try {
    if (unsetFirstLoad) {
      firstLoad = false;
      firstSiteName = '';
    }
    const siteData = getSiteDataBySiteName(reportParameters);
    const { SiteName } = reportParameters;

    const uomSlicerVisual = visuals.filter(function (visual) {
      return visual.type === 'slicer' && visual.title === 'UOM Slicer';
    })[0];

    if (!SiteName && !siteData) {
      const uomSlicerState = await uomSlicerVisual.getSlicerState();

      if (uomSlicerVisual) {
        await uomSlicerVisual.setSlicerState(uomSlicerState);
      }
    } else {
      await uomSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: constants.BASIC_FILTER,
            target: {
              table: 'UnitOfMeasureSelection',
              column: 'UnitOfMeasureName',
            },
            operator: 'In',
            values: [siteData.UnitOfMeasureName],
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Sets the Sensor slicer visual based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @param {Array} visuals - The visuals array.
 * @param {String} uomName - The UOM name.
 * @param {Boolean} unsetFirstLoad - Whether to unset the first load.
 * @returns {Promise<void>}
 */
const setSensorSlicerVisual = async (
  reportParameters,
  visuals,
  uomName = null,
  unsetFirstLoad = false
) => {
  try {
    if (unsetFirstLoad) {
      firstLoad = false;
      firstSiteName = '';
    }
    const siteData = getSiteDataBySiteName(reportParameters, uomName);
    const { SiteName } = reportParameters;

    const sensorSlicerVisual = visuals.find(
      (visual) => visual.type === 'slicer' && visual.title === 'Sensor Slicer'
    );

    if (!sensorSlicerVisual) {
      return Promise.reject(new Error('Sensor Slicer visual not found'));
    }
    const values = siteData ? [siteData.SensorName] : ['No Data to show'];

    if (!SiteName) {
      try {
        const sensorSlicerState = await sensorSlicerVisual.getSlicerState();
        await sensorSlicerVisual.setSlicerState(sensorSlicerState);
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      await sensorSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: constants.BASIC_FILTER,
            target: {
              table: 'vwSensorNames',
              column: 'SensorName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Retrieves the first site data based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @param {String} uomName - The UOM name.
 * @returns {Array} - The first site data.
 */
const getSiteDataBySiteName = (reportParameters, uomName) => {
  try {
    const { SiteName, SiteUnitOfMeasureSensors } = reportParameters;

    if (SiteUnitOfMeasureSensors) {
      const parsedData = JSON.parse(SiteUnitOfMeasureSensors);
      if (firstLoad) {
        firstSiteName = parsedData[0].SiteName.trim().toLowerCase();
      }
      const trimmedSiteName = firstSiteName.length
        ? firstSiteName
        : SiteName.trim().toLowerCase();

      const response = parsedData.find((data) => {
        if (uomName) {
          return (
            data.SiteName.trim().toLowerCase() === trimmedSiteName &&
            data.UnitOfMeasureName.trim().toLowerCase() ===
              uomName.trim().toLowerCase()
          );
        }
        return data.SiteName.trim().toLowerCase() === trimmedSiteName;
      });

      if (firstLoad && uomName) {
        firstLoad = false;
      }

      return !response && firstLoad ? parsedData[0] : response;
    }

    return null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  setSlicers,
  setUOMSlicerVisual,
  setSensorSlicerVisual,
};
