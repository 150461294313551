<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="!hasLenght && !isLoading">
        <BannerAlertVue :message="bannerMessage" />
      </v-col>
      <v-col>
        <VAVsListVue
          @update:VAVsList="(value) => (hasLenght = value)"
          @update:isLoading="(value) => (isLoading = value)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';

import VAVsListVue from '@/Core.Service.Domain/Controls/VAVs/Components/VAVsList.vue';

const hasLenght = ref(false);
const isLoading = ref(true);
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

const router = useRouter();
const bannerMessage = computed(
  () => `No ${router.currentRoute.value.meta.label} configured for this site`
);
</script>
