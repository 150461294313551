<template>
  <svg
    width="716"
    height="554"
    viewBox="0 0 716 554"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M374.03 160.34H377.22L374.03 4.64001V160.34Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M377.22 160.34V4.64001H374.03L377.22 160.34Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M314.77 381.9H345L314.77 378.71V381.9Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M345 381.9V378.71H314.77L345 381.9Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M504.77 240.6H507.96L504.77 237.28V240.6Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M507.96 240.6V237.28H504.77L507.96 240.6Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M504.77 268.95H507.96L504.77 240.6V268.95Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M507.96 268.95V240.6H504.77L507.96 268.95Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M686.09 532.65H694.89L686.09 530.45V532.65Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M694.89 532.65V530.45H686.09L694.89 532.65Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M369.63 163.52H452.72L369.63 160.34V163.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M452.72 163.52V160.34H369.63L452.72 163.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M452.72 163.52H462.57L452.72 160.34V163.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M462.57 163.52V160.34H452.72L462.57 163.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M462.57 163.52H463.12L462.57 160.34V163.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M463.12 163.52V160.34H462.57L463.12 163.52Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M0.27002 553.1H55.82" stroke="black" stroke-width="0.5" />
    <path d="M55.82 553.1V542.55" stroke="black" stroke-width="0.5" />
    <path d="M41.52 542.55H0.27002" stroke="black" stroke-width="0.5" />
    <path d="M0.27002 542.55V553.1" stroke="black" stroke-width="0.5" />
    <path d="M150.82 553.1H225.15" stroke="black" stroke-width="0.5" />
    <path d="M225.15 553.1V542.55" stroke="black" stroke-width="0.5" />
    <path d="M150.82 542.55V553.1" stroke="black" stroke-width="0.5" />
    <path d="M314.88 553.1H396.79" stroke="black" stroke-width="0.5" />
    <path d="M396.79 553.1V542.55" stroke="black" stroke-width="0.5" />
    <path d="M314.88 542.55V553.1" stroke="black" stroke-width="0.5" />
    <path d="M486.52 553.1H578.55" stroke="black" stroke-width="0.5" />
    <path d="M578.55 553.1V542.55" stroke="black" stroke-width="0.5" />
    <path d="M486.52 542.55V553.1" stroke="black" stroke-width="0.5" />
    <path d="M652.44 553.1H705.44" stroke="black" stroke-width="0.5" />
    <path d="M705.44 553.1V542.55" stroke="black" stroke-width="0.5" />
    <path d="M652.44 542.55V553.1" stroke="black" stroke-width="0.5" />
    <path d="M652.44 553.1H578.55" stroke="black" stroke-width="0.5" />
    <path d="M486.52 553.1H396.79" stroke="black" stroke-width="0.5" />
    <path d="M314.88 553.1H225.15" stroke="black" stroke-width="0.5" />
    <path d="M150.82 553.1H55.82" stroke="black" stroke-width="0.5" />
    <path d="M55.82 553.1V542.55" stroke="black" stroke-width="0.5" />
    <path d="M150.82 542.55V553.1" stroke="black" stroke-width="0.5" />
    <path d="M225.15 553.1V542.55" stroke="black" stroke-width="0.5" />
    <path d="M314.88 542.55V553.1" stroke="black" stroke-width="0.5" />
    <path d="M396.79 553.1V542.55" stroke="black" stroke-width="0.5" />
    <path d="M486.52 542.55V553.1" stroke="black" stroke-width="0.5" />
    <path d="M578.55 553.1V542.55" stroke="black" stroke-width="0.5" />
    <path d="M652.44 542.55V553.1" stroke="black" stroke-width="0.5" />
    <path d="M705.44 542.55V452.93" stroke="black" stroke-width="0.5" />
    <path d="M705.44 452.93H694.89" stroke="black" stroke-width="0.5" />
    <path d="M694.89 452.93V529.9" stroke="black" stroke-width="0.5" />
    <path d="M694.89 532.65V538.26" stroke="black" stroke-width="0.5" />
    <path d="M705.44 359.69V215.62" stroke="black" stroke-width="0.5" />
    <path d="M694.89 215.62V217.82" stroke="black" stroke-width="0.5" />
    <path d="M694.89 233.54V326.9" stroke="black" stroke-width="0.5" />
    <path d="M694.89 331.19V359.69" stroke="black" stroke-width="0.5" />
    <path d="M694.89 359.69H705.44" stroke="black" stroke-width="0.5" />
    <path d="M694.89 359.69H705.44" stroke="black" stroke-width="0.5" />
    <path d="M705.44 452.93H694.89" stroke="black" stroke-width="0.5" />
    <path d="M694.89 452.93V359.69" stroke="black" stroke-width="0.5" />
    <path d="M17.44 1.20001V531.33" stroke="black" stroke-width="0.5" />
    <path d="M17.44 531.33H21.73" stroke="black" stroke-width="0.5" />
    <path d="M21.73 523.3V446.86" stroke="black" stroke-width="0.5" />
    <path d="M21.73 442.57V352.85" stroke="black" stroke-width="0.5" />
    <path d="M21.73 348.56V258.83" stroke="black" stroke-width="0.5" />
    <path d="M21.73 254.55V234.75" stroke="black" stroke-width="0.5" />
    <path d="M21.73 232V218.81" stroke="black" stroke-width="0.5" />
    <path d="M21.73 216.06V164.07" stroke="black" stroke-width="0.5" />
    <path d="M21.73 159.79V5.48999" stroke="black" stroke-width="0.5" />
    <path d="M21.73 1.20001H17.44" stroke="black" stroke-width="0.5" />
    <path d="M122.84 1.20001H21.73" stroke="black" stroke-width="0.5" />
    <path d="M21.73 5.48999H120.5L153 37.5" stroke="black" stroke-width="0.5" />
    <path d="M154.5 33L122.84 1.20001" stroke="black" stroke-width="0.5" />
    <path d="M215.36 33H211.74" stroke="black" stroke-width="0.5" />
    <path d="M211.74 33V37.29" stroke="black" stroke-width="0.5" />
    <path d="M211.74 37.29H219.65" stroke="black" stroke-width="0.5" />
    <path d="M219.65 37.29V33" stroke="black" stroke-width="0.5" />
    <path d="M164.23 33H154.64" stroke="black" stroke-width="0.5" />
    <path d="M152.87 37.29H164.23" stroke="black" stroke-width="0.5" />
    <path d="M164.23 37.29V33" stroke="black" stroke-width="0.5" />
    <path d="M215.36 0.900024V33" stroke="black" stroke-width="0.5" />
    <path d="M219.65 33V5.19" stroke="black" stroke-width="0.5" />
    <path d="M219.65 0.900024H215.36" stroke="black" stroke-width="0.5" />
    <path d="M510.27 0.900024H219.65" stroke="black" stroke-width="0.5" />
    <path d="M219.65 5.19H283.98" stroke="black" stroke-width="0.5" />
    <path d="M288.27 5.19H294.64" stroke="black" stroke-width="0.5" />
    <path d="M298.93 5.19H373.48" stroke="black" stroke-width="0.5" />
    <path d="M377.77 5.19H505.98" stroke="black" stroke-width="0.5" />
    <path d="M510.27 5.19003V0.900024" stroke="black" stroke-width="0.5" />
    <path d="M373.48 5.19H374.03" stroke="black" stroke-width="0.5" />
    <path d="M374.03 5.19H377.22" stroke="black" stroke-width="0.5" />
    <path d="M377.22 5.19H377.77" stroke="black" stroke-width="0.5" />
    <path d="M510.27 108.55V90.05" stroke="black" stroke-width="0.5" />
    <path d="M510.27 74.22V65.42" stroke="black" stroke-width="0.5" />
    <path d="M510.27 49.59V37.27" stroke="black" stroke-width="0.5" />
    <path d="M510.27 32.98V5.19" stroke="black" stroke-width="0.5" />
    <path d="M505.98 5.19V108.55" stroke="black" stroke-width="0.5" />
    <path d="M41.52 527.04H35.36" stroke="black" stroke-width="0.5" />
    <path d="M31.07 527.04H21.73" stroke="black" stroke-width="0.5" />
    <path d="M21.73 527.04V527.59" stroke="black" stroke-width="0.5" />
    <path d="M21.73 531.33H37.23" stroke="black" stroke-width="0.5" />
    <path d="M41.52 531.33V527.04" stroke="black" stroke-width="0.5" />
    <path d="M41.52 538.26V531.33" stroke="black" stroke-width="0.5" />
    <path d="M37.23 531.33V542.55" stroke="black" stroke-width="0.5" />
    <path d="M37.23 542.55H41.52" stroke="black" stroke-width="0.5" />
    <path d="M55.82 542.55V538.26" stroke="black" stroke-width="0.5" />
    <path d="M55.82 538.26H41.52" stroke="black" stroke-width="0.5" />
    <path d="M225.15 542.55V538.26" stroke="black" stroke-width="0.5" />
    <path d="M220.86 538.26H171.6" stroke="black" stroke-width="0.5" />
    <path d="M167.31 538.26H150.82" stroke="black" stroke-width="0.5" />
    <path d="M150.82 538.26V542.55" stroke="black" stroke-width="0.5" />
    <path d="M396.79 542.55V538.26" stroke="black" stroke-width="0.5" />
    <path d="M396.79 538.26H389.76" stroke="black" stroke-width="0.5" />
    <path d="M387.01 538.26H375.68" stroke="black" stroke-width="0.5" />
    <path d="M371.39 538.26H314.88" stroke="black" stroke-width="0.5" />
    <path d="M314.88 538.26V542.55" stroke="black" stroke-width="0.5" />
    <path d="M578.55 542.55V538.26" stroke="black" stroke-width="0.5" />
    <path d="M578.55 538.26H523.35" stroke="black" stroke-width="0.5" />
    <path d="M519.07 538.26H486.52" stroke="black" stroke-width="0.5" />
    <path d="M486.52 538.26V542.55" stroke="black" stroke-width="0.5" />
    <path d="M694.89 538.26H686.09" stroke="black" stroke-width="0.5" />
    <path d="M683.34 538.26H669.27" stroke="black" stroke-width="0.5" />
    <path d="M666.52 538.26H652.44" stroke="black" stroke-width="0.5" />
    <path d="M652.44 538.26V542.55" stroke="black" stroke-width="0.5" />
    <path d="M55.82 542.55V538.26" stroke="black" stroke-width="0.5" />
    <path d="M150.82 538.26V542.55" stroke="black" stroke-width="0.5" />
    <path d="M225.15 542.55V538.26" stroke="black" stroke-width="0.5" />
    <path d="M314.88 538.26V542.55" stroke="black" stroke-width="0.5" />
    <path d="M396.79 542.55V538.26" stroke="black" stroke-width="0.5" />
    <path d="M486.52 538.26V542.55" stroke="black" stroke-width="0.5" />
    <path d="M578.55 542.55V538.26" stroke="black" stroke-width="0.5" />
    <path d="M652.44 538.26V542.55" stroke="black" stroke-width="0.5" />
    <path d="M55.82 538.26H150.82" stroke="black" stroke-width="0.5" />
    <path d="M225.15 538.26H314.88" stroke="black" stroke-width="0.5" />
    <path d="M396.79 538.26H486.52" stroke="black" stroke-width="0.5" />
    <path d="M578.55 538.26H652.44" stroke="black" stroke-width="0.5" />
    <path d="M693.57 222.11V233.54" stroke="black" stroke-width="0.5" />
    <path d="M693.57 233.54H694.89" stroke="black" stroke-width="0.5" />
    <path d="M561.29 1.20001V74.34" stroke="black" stroke-width="0.5" />
    <path d="M561.29 74.34H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 74.34V5.48999" stroke="black" stroke-width="0.5" />
    <path d="M565.58 1.20001H561.29" stroke="black" stroke-width="0.5" />
    <path d="M561.29 106.01V182.65" stroke="black" stroke-width="0.5" />
    <path d="M561.29 182.65H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 182.65V113.14" stroke="black" stroke-width="0.5" />
    <path d="M565.58 108.85V106.01" stroke="black" stroke-width="0.5" />
    <path d="M565.58 106.01H561.29" stroke="black" stroke-width="0.5" />
    <path d="M561.29 214.32V290.85" stroke="black" stroke-width="0.5" />
    <path d="M561.29 290.85H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 290.85V222.11" stroke="black" stroke-width="0.5" />
    <path d="M565.58 217.82V214.32" stroke="black" stroke-width="0.5" />
    <path d="M565.58 214.32H561.29" stroke="black" stroke-width="0.5" />
    <path d="M561.29 322.52V337.36" stroke="black" stroke-width="0.5" />
    <path d="M561.29 337.36H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 337.36V331.19" stroke="black" stroke-width="0.5" />
    <path d="M565.58 326.9V322.52" stroke="black" stroke-width="0.5" />
    <path d="M565.58 322.52H561.29" stroke="black" stroke-width="0.5" />
    <path d="M561.29 369.03V435.23" stroke="black" stroke-width="0.5" />
    <path d="M565.58 435.23V369.03" stroke="black" stroke-width="0.5" />
    <path d="M565.58 369.03H561.29" stroke="black" stroke-width="0.5" />
    <path d="M694.89 217.82H694.56" stroke="black" stroke-width="0.5" />
    <path d="M693.24 217.82H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 222.11H693.57" stroke="black" stroke-width="0.5" />
    <path d="M694.89 326.9H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 331.19H694.89" stroke="black" stroke-width="0.5" />
    <path d="M694.89 435.23H565.58" stroke="black" stroke-width="0.5" />
    <path d="M561.29 435.23H560.27" stroke="black" stroke-width="0.5" />
    <path d="M560.27 435.23V439.52" stroke="black" stroke-width="0.5" />
    <path d="M560.27 439.52H694.89" stroke="black" stroke-width="0.5" />
    <path d="M694.89 439.52V435.23" stroke="black" stroke-width="0.5" />
    <path d="M528.6 435.23H411.55" stroke="black" stroke-width="0.5" />
    <path d="M411.55 435.23V439.52" stroke="black" stroke-width="0.5" />
    <path d="M411.55 439.52H519.07" stroke="black" stroke-width="0.5" />
    <path d="M523.35 439.52H528.6" stroke="black" stroke-width="0.5" />
    <path d="M528.6 439.52V435.23" stroke="black" stroke-width="0.5" />
    <path d="M379.88 435.23H368.56" stroke="black" stroke-width="0.5" />
    <path d="M368.56 435.23V439.52" stroke="black" stroke-width="0.5" />
    <path d="M368.56 439.52H371.39" stroke="black" stroke-width="0.5" />
    <path d="M375.68 439.52H379.88" stroke="black" stroke-width="0.5" />
    <path d="M379.88 439.52V435.23" stroke="black" stroke-width="0.5" />
    <path d="M336.89 435.23H220.86" stroke="black" stroke-width="0.5" />
    <path d="M220.86 435.23V439.52" stroke="black" stroke-width="0.5" />
    <path d="M225.15 439.52H336.89" stroke="black" stroke-width="0.5" />
    <path d="M336.89 439.52V435.23" stroke="black" stroke-width="0.5" />
    <path d="M220.86 439.52V502.3" stroke="black" stroke-width="0.5" />
    <path d="M220.86 506.59V538.26" stroke="black" stroke-width="0.5" />
    <path d="M225.15 538.26V439.52" stroke="black" stroke-width="0.5" />
    <path d="M371.39 439.52V538.26" stroke="black" stroke-width="0.5" />
    <path d="M375.68 538.26V537.49" stroke="black" stroke-width="0.5" />
    <path d="M375.68 534.74V439.52" stroke="black" stroke-width="0.5" />
    <path d="M519.07 439.52V538.26" stroke="black" stroke-width="0.5" />
    <path d="M523.35 538.26V439.52" stroke="black" stroke-width="0.5" />
    <path d="M220.86 502.3H216.46" stroke="black" stroke-width="0.5" />
    <path d="M216.46 502.3V506.59" stroke="black" stroke-width="0.5" />
    <path d="M216.46 506.59H220.86" stroke="black" stroke-width="0.5" />
    <path d="M184.8 502.3H171.6" stroke="black" stroke-width="0.5" />
    <path d="M171.6 506.59H184.8" stroke="black" stroke-width="0.5" />
    <path d="M184.8 506.59V502.3" stroke="black" stroke-width="0.5" />
    <path d="M171.6 538.26V506.59" stroke="black" stroke-width="0.5" />
    <path d="M171.6 502.3V480.75" stroke="black" stroke-width="0.5" />
    <path d="M171.6 480.75H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 480.75V538.26" stroke="black" stroke-width="0.5" />
    <path d="M171.6 449.08V388.38" stroke="black" stroke-width="0.5" />
    <path d="M171.6 388.38H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 388.38V442.57" stroke="black" stroke-width="0.5" />
    <path d="M167.31 446.86V449.08" stroke="black" stroke-width="0.5" />
    <path d="M167.31 449.08H171.6" stroke="black" stroke-width="0.5" />
    <path d="M171.6 356.71V339.23" stroke="black" stroke-width="0.5" />
    <path d="M171.6 339.23H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 339.23V348.56" stroke="black" stroke-width="0.5" />
    <path d="M167.31 352.85V356.71" stroke="black" stroke-width="0.5" />
    <path d="M167.31 356.71H171.6" stroke="black" stroke-width="0.5" />
    <path d="M171.6 307.56V244.67" stroke="black" stroke-width="0.5" />
    <path d="M171.6 244.67H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 244.67V254.55" stroke="black" stroke-width="0.5" />
    <path d="M167.31 258.83V307.56" stroke="black" stroke-width="0.5" />
    <path d="M167.31 307.56H171.6" stroke="black" stroke-width="0.5" />
    <path d="M171.6 213V164.07" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.07V213" stroke="black" stroke-width="0.5" />
    <path d="M167.31 213H171.6" stroke="black" stroke-width="0.5" />
    <path d="M21.73 164.07H167.31" stroke="black" stroke-width="0.5" />
    <path d="M171.6 164.07H247.91" stroke="black" stroke-width="0.5" />
    <path d="M247.91 164.07V159.79" stroke="black" stroke-width="0.5" />
    <path d="M247.91 159.79H21.73" stroke="black" stroke-width="0.5" />
    <path d="M279.58 164.07H299.48" stroke="black" stroke-width="0.5" />
    <path d="M295.19 159.79H288.27" stroke="black" stroke-width="0.5" />
    <path d="M283.98 159.79H279.58" stroke="black" stroke-width="0.5" />
    <path d="M279.58 159.79V164.07" stroke="black" stroke-width="0.5" />
    <path d="M299.48 164.07V163.52" stroke="black" stroke-width="0.5" />
    <path d="M299.48 163.52V161.93" stroke="black" stroke-width="0.5" />
    <path d="M299.48 161.93V160.34" stroke="black" stroke-width="0.5" />
    <path d="M299.48 160.34V159.79" stroke="black" stroke-width="0.5" />
    <path d="M466.86 112.83H470.49" stroke="black" stroke-width="0.5" />
    <path d="M470.49 112.83V108.55" stroke="black" stroke-width="0.5" />
    <path d="M470.49 108.55H466.86" stroke="black" stroke-width="0.5" />
    <path d="M502.15 112.83H510.27" stroke="black" stroke-width="0.5" />
    <path d="M510.27 112.83V108.55" stroke="black" stroke-width="0.5" />
    <path d="M505.98 108.55H502.15" stroke="black" stroke-width="0.5" />
    <path d="M502.15 108.55V112.83" stroke="black" stroke-width="0.5" />
    <path d="M565.58 113.14H705.44" stroke="black" stroke-width="0.5" />
    <path d="M705.44 108.85H565.58" stroke="black" stroke-width="0.5" />
    <path d="M705.44 5.48999V108.85" stroke="black" stroke-width="0.5" />
    <path d="M705.44 113.14V359.69" stroke="black" stroke-width="0.5" />
    <path d="M705.44 359.69H716" stroke="black" stroke-width="0.5" />
    <path d="M716 359.69V1.20001" stroke="black" stroke-width="0.5" />
    <path d="M716 1.20001H705.44" stroke="black" stroke-width="0.5" />
    <path d="M705.44 1.20001H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 5.48999H705.44" stroke="black" stroke-width="0.5" />
    <path d="M510.95 32.98H510.27" stroke="black" stroke-width="0.5" />
    <path d="M510.27 37.27H510.95" stroke="black" stroke-width="0.5" />
    <path d="M510.95 37.27V32.98" stroke="black" stroke-width="0.5" />
    <path d="M283.98 128.12V159.79" stroke="black" stroke-width="0.5" />
    <path d="M288.27 159.79V132.41" stroke="black" stroke-width="0.5" />
    <path d="M288.27 128.12H283.98" stroke="black" stroke-width="0.5" />
    <path d="M299.48 159.79V132.41" stroke="black" stroke-width="0.5" />
    <path d="M295.19 132.41V159.79" stroke="black" stroke-width="0.5" />
    <path d="M299.48 128.12H288.27" stroke="black" stroke-width="0.5" />
    <path d="M288.27 132.41H295.19" stroke="black" stroke-width="0.5" />
    <path d="M299.48 132.41V128.12" stroke="black" stroke-width="0.5" />
    <path d="M283.98 36.85H298.93" stroke="black" stroke-width="0.5" />
    <path d="M298.93 36.85V32.56" stroke="black" stroke-width="0.5" />
    <path d="M294.64 32.56H288.27" stroke="black" stroke-width="0.5" />
    <path d="M283.98 32.56V36.85" stroke="black" stroke-width="0.5" />
    <path d="M298.93 32.56V5.19" stroke="black" stroke-width="0.5" />
    <path d="M294.64 5.19V32.56" stroke="black" stroke-width="0.5" />
    <path d="M283.98 5.19V32.56" stroke="black" stroke-width="0.5" />
    <path d="M288.27 32.56V5.19" stroke="black" stroke-width="0.5" />
    <path d="M167.31 254.55H21.73" stroke="black" stroke-width="0.5" />
    <path d="M21.73 258.83H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 348.56H21.73" stroke="black" stroke-width="0.5" />
    <path d="M21.73 352.85H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 442.57H21.73" stroke="black" stroke-width="0.5" />
    <path d="M21.73 446.86H167.31" stroke="black" stroke-width="0.5" />
    <path d="M224.38 209.82V239.72" stroke="black" stroke-width="0.5" />
    <path d="M224.38 239.72H228.67" stroke="black" stroke-width="0.5" />
    <path d="M228.67 239.72V232.58" stroke="black" stroke-width="0.5" />
    <path d="M228.67 228.29V209.82" stroke="black" stroke-width="0.5" />
    <path d="M228.67 209.82H224.38" stroke="black" stroke-width="0.5" />
    <path d="M224.38 271.39V378.16" stroke="black" stroke-width="0.5" />
    <path d="M228.67 378.16V310.2" stroke="black" stroke-width="0.5" />
    <path d="M228.67 303.82V271.39" stroke="black" stroke-width="0.5" />
    <path d="M228.67 271.39H224.38" stroke="black" stroke-width="0.5" />
    <path d="M504.22 228.29H366.12" stroke="black" stroke-width="0.5" />
    <path d="M361.83 228.29H351.71" stroke="black" stroke-width="0.5" />
    <path d="M347.42 228.29H228.67" stroke="black" stroke-width="0.5" />
    <path d="M228.67 232.58H306.08" stroke="black" stroke-width="0.5" />
    <path d="M310.37 232.58H504.22" stroke="black" stroke-width="0.5" />
    <path d="M306.08 232.58V303.82" stroke="black" stroke-width="0.5" />
    <path d="M306.08 310.2V378.16" stroke="black" stroke-width="0.5" />
    <path d="M306.08 382.45H310.37" stroke="black" stroke-width="0.5" />
    <path d="M310.37 378.16V232.58" stroke="black" stroke-width="0.5" />
    <path d="M504.22 378.16H503.23" stroke="black" stroke-width="0.5" />
    <path d="M503.23 378.16V382.45" stroke="black" stroke-width="0.5" />
    <path d="M503.23 382.45H508.51" stroke="black" stroke-width="0.5" />
    <path d="M508.51 382.45V378.16" stroke="black" stroke-width="0.5" />
    <path d="M382.5 378.16H377.22" stroke="black" stroke-width="0.5" />
    <path d="M377.22 378.16V382.45" stroke="black" stroke-width="0.5" />
    <path d="M377.22 382.45H382.5" stroke="black" stroke-width="0.5" />
    <path d="M382.5 382.45V378.16" stroke="black" stroke-width="0.5" />
    <path d="M313.91 378.16H310.37" stroke="black" stroke-width="0.5" />
    <path d="M310.37 382.45H313.91" stroke="black" stroke-width="0.5" />
    <path d="M382.5 378.16H503.23" stroke="black" stroke-width="0.5" />
    <path d="M382.5 378.16V382.45" stroke="black" stroke-width="0.5" />
    <path d="M503.23 382.45V378.16" stroke="black" stroke-width="0.5" />
    <path d="M503.23 382.45H382.5" stroke="black" stroke-width="0.5" />
    <path d="M306.08 303.82H228.67" stroke="black" stroke-width="0.5" />
    <path d="M228.67 310.2H306.08" stroke="black" stroke-width="0.5" />
    <path d="M347.42 214.1V228.29" stroke="black" stroke-width="0.5" />
    <path d="M351.71 228.29V214.1" stroke="black" stroke-width="0.5" />
    <path d="M366.12 228.29V209.82" stroke="black" stroke-width="0.5" />
    <path d="M366.12 209.82H361.83" stroke="black" stroke-width="0.5" />
    <path d="M361.83 214.1V228.29" stroke="black" stroke-width="0.5" />
    <path d="M361.83 209.82H347.42" stroke="black" stroke-width="0.5" />
    <path d="M347.42 209.82V214.1" stroke="black" stroke-width="0.5" />
    <path d="M351.71 214.1H361.83" stroke="black" stroke-width="0.5" />
    <path d="M368.56 439.52L346.16 461.91" stroke="black" stroke-width="0.5" />
    <path d="M367.47 438.43L368.56 439.52" stroke="black" stroke-width="0.5" />
    <path d="M345.08 460.82L367.47 438.43" stroke="black" stroke-width="0.5" />
    <path d="M346.16 461.91L345.08 460.82" stroke="black" stroke-width="0.5" />
    <path
      d="M336.89 439.52C336.889 447.917 340.224 455.971 346.16 461.91"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M402.27 461.91L403.36 460.82" stroke="black" stroke-width="0.5" />
    <path d="M403.36 460.82L380.97 438.43" stroke="black" stroke-width="0.5" />
    <path d="M380.97 438.43L379.88 439.52" stroke="black" stroke-width="0.5" />
    <path d="M379.88 439.52L402.27 461.91" stroke="black" stroke-width="0.5" />
    <path
      d="M402.27 461.91C408.21 455.973 411.548 447.919 411.55 439.52"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M550.99 461.91L552.08 460.82" stroke="black" stroke-width="0.5" />
    <path d="M552.08 460.82L529.69 438.43" stroke="black" stroke-width="0.5" />
    <path d="M529.69 438.43L528.6 439.52" stroke="black" stroke-width="0.5" />
    <path d="M528.6 439.52L550.99 461.91" stroke="black" stroke-width="0.5" />
    <path
      d="M550.99 461.91C556.93 455.973 560.268 447.919 560.27 439.52"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M565.58 337.36L587.97 359.75" stroke="black" stroke-width="0.5" />
    <path d="M564.49 338.45L565.58 337.36" stroke="black" stroke-width="0.5" />
    <path d="M586.88 360.84L564.49 338.45" stroke="black" stroke-width="0.5" />
    <path d="M587.97 359.75L586.88 360.84" stroke="black" stroke-width="0.5" />
    <path
      d="M565.58 369.03C573.979 369.028 582.032 365.69 587.97 359.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M587.97 300.12L586.88 299.04" stroke="black" stroke-width="0.5" />
    <path d="M586.88 299.04L564.49 321.43" stroke="black" stroke-width="0.5" />
    <path d="M564.49 321.43L565.58 322.52" stroke="black" stroke-width="0.5" />
    <path d="M565.58 322.52L587.97 300.12" stroke="black" stroke-width="0.5" />
    <path
      d="M587.97 300.12C582.031 294.184 573.977 290.849 565.58 290.85"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M587.97 191.93L586.88 190.84" stroke="black" stroke-width="0.5" />
    <path d="M586.88 190.84L564.49 213.23" stroke="black" stroke-width="0.5" />
    <path d="M564.49 213.23L565.58 214.32" stroke="black" stroke-width="0.5" />
    <path d="M565.58 214.32L587.97 191.93" stroke="black" stroke-width="0.5" />
    <path
      d="M587.97 191.93C582.032 185.99 573.979 182.652 565.58 182.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M587.97 83.62L586.88 82.53" stroke="black" stroke-width="0.5" />
    <path d="M586.88 82.53L564.49 104.92" stroke="black" stroke-width="0.5" />
    <path d="M564.49 104.92L565.58 106.01" stroke="black" stroke-width="0.5" />
    <path d="M565.58 106.01L587.97 83.62" stroke="black" stroke-width="0.5" />
    <path
      d="M587.97 83.62C582.032 77.68 573.979 74.3419 565.58 74.34"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M167.31 244.67L144.92 222.27" stroke="black" stroke-width="0.5" />
    <path d="M168.4 243.58L167.31 244.67" stroke="black" stroke-width="0.5" />
    <path d="M146.01 221.19L168.4 243.58" stroke="black" stroke-width="0.5" />
    <path d="M144.92 222.27L146.01 221.19" stroke="black" stroke-width="0.5" />
    <path
      d="M167.31 213C158.913 212.999 150.859 216.334 144.92 222.27"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M167.31 339.23L144.92 316.84" stroke="black" stroke-width="0.5" />
    <path d="M168.4 338.14L167.31 339.23" stroke="black" stroke-width="0.5" />
    <path d="M146.01 315.75L168.4 338.14" stroke="black" stroke-width="0.5" />
    <path d="M144.92 316.84L146.01 315.75" stroke="black" stroke-width="0.5" />
    <path
      d="M167.31 307.56C158.911 307.562 150.857 310.9 144.92 316.84"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M144.92 379.11L146.01 380.19" stroke="black" stroke-width="0.5" />
    <path d="M146.01 380.19L168.4 357.8" stroke="black" stroke-width="0.5" />
    <path d="M168.4 357.8L167.31 356.71" stroke="black" stroke-width="0.5" />
    <path d="M167.31 356.71L144.92 379.11" stroke="black" stroke-width="0.5" />
    <path
      d="M144.92 379.11C150.859 385.046 158.913 388.381 167.31 388.38"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M144.92 471.47L146.01 472.56" stroke="black" stroke-width="0.5" />
    <path d="M146.01 472.56L168.4 450.17" stroke="black" stroke-width="0.5" />
    <path d="M168.4 450.17L167.31 449.08" stroke="black" stroke-width="0.5" />
    <path d="M167.31 449.08L144.92 471.47" stroke="black" stroke-width="0.5" />
    <path
      d="M144.92 471.47C150.857 477.41 158.911 480.748 167.31 480.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M194.07 479.91L192.98 481" stroke="black" stroke-width="0.5" />
    <path d="M192.98 481L215.37 503.39" stroke="black" stroke-width="0.5" />
    <path d="M215.37 503.39L216.46 502.3" stroke="black" stroke-width="0.5" />
    <path d="M216.46 502.3L194.07 479.91" stroke="black" stroke-width="0.5" />
    <path
      d="M194.07 479.91C188.134 485.849 184.799 493.903 184.8 502.3"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M228.67 239.72L251.06 262.11" stroke="black" stroke-width="0.5" />
    <path d="M227.58 240.81L228.67 239.72" stroke="black" stroke-width="0.5" />
    <path d="M249.97 263.2L227.58 240.81" stroke="black" stroke-width="0.5" />
    <path d="M251.06 262.11L249.97 263.2" stroke="black" stroke-width="0.5" />
    <path
      d="M228.67 271.39C237.069 271.388 245.123 268.05 251.06 262.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M705.88 361.45V375.52" stroke="black" stroke-width="0.5" />
    <path d="M705.88 375.52H706.76" stroke="black" stroke-width="0.5" />
    <path d="M706.76 375.52V361.45" stroke="black" stroke-width="0.5" />
    <path d="M706.76 361.45H705.88" stroke="black" stroke-width="0.5" />
    <path d="M705.88 438.86V451.17" stroke="black" stroke-width="0.5" />
    <path d="M705.88 451.17H706.76" stroke="black" stroke-width="0.5" />
    <path d="M706.76 451.17V438.86" stroke="black" stroke-width="0.5" />
    <path d="M706.76 438.86H705.88" stroke="black" stroke-width="0.5" />
    <path d="M705.88 377.28V437.1" stroke="black" stroke-width="0.5" />
    <path d="M705.88 437.1H706.76" stroke="black" stroke-width="0.5" />
    <path d="M706.76 437.1V377.28" stroke="black" stroke-width="0.5" />
    <path d="M706.76 377.28H705.88" stroke="black" stroke-width="0.5" />
    <path d="M703.68 359.69V361.45" stroke="black" stroke-width="0.5" />
    <path d="M703.68 361.45H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 361.45V359.69" stroke="black" stroke-width="0.5" />
    <path d="M708.96 359.69H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 451.17V452.93" stroke="black" stroke-width="0.5" />
    <path d="M703.68 452.93H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 452.93V451.17" stroke="black" stroke-width="0.5" />
    <path d="M708.96 451.17H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 361.45H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 361.45H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 375.52H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 375.52H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 361.45V375.52" stroke="black" stroke-width="0.5" />
    <path d="M703.68 375.52V361.45" stroke="black" stroke-width="0.5" />
    <path d="M708.96 375.52V361.45" stroke="black" stroke-width="0.5" />
    <path d="M708.96 375.52V361.45" stroke="black" stroke-width="0.5" />
    <path d="M703.68 437.1V438.86" stroke="black" stroke-width="0.5" />
    <path d="M703.68 438.86H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 438.86V437.1" stroke="black" stroke-width="0.5" />
    <path d="M708.96 437.1H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 375.52V377.28" stroke="black" stroke-width="0.5" />
    <path d="M703.68 377.28H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 377.28V375.52" stroke="black" stroke-width="0.5" />
    <path d="M708.96 375.52H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 438.86H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 438.86H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 451.17H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 451.17H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 438.86V451.17" stroke="black" stroke-width="0.5" />
    <path d="M703.68 451.17V438.86" stroke="black" stroke-width="0.5" />
    <path d="M708.96 451.17V438.86" stroke="black" stroke-width="0.5" />
    <path d="M708.96 451.17V438.86" stroke="black" stroke-width="0.5" />
    <path d="M703.68 377.28H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 377.28H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 437.1H708.96" stroke="black" stroke-width="0.5" />
    <path d="M708.96 437.1H703.68" stroke="black" stroke-width="0.5" />
    <path d="M703.68 377.28V437.1" stroke="black" stroke-width="0.5" />
    <path d="M703.68 437.1V377.28" stroke="black" stroke-width="0.5" />
    <path d="M708.96 437.1V377.28" stroke="black" stroke-width="0.5" />
    <path d="M708.96 437.1V377.28" stroke="black" stroke-width="0.5" />
    <path d="M512.71 35.57H524.15" stroke="black" stroke-width="0.5" />
    <path d="M524.15 35.57V34.69" stroke="black" stroke-width="0.5" />
    <path d="M524.15 34.69H512.71" stroke="black" stroke-width="0.5" />
    <path d="M512.71 34.69V35.57" stroke="black" stroke-width="0.5" />
    <path
      d="M535.1 11.81C529.198 18.0532 525.91 26.3187 525.91 34.91"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M535.1 11.81L536.65 10.27" stroke="black" stroke-width="0.5" />
    <path d="M557.98 34.69L559.53 33.15" stroke="black" stroke-width="0.5" />
    <path d="M557.98 34.69L535.1 11.81" stroke="black" stroke-width="0.5" />
    <path d="M559.53 33.15L536.65 10.27" stroke="black" stroke-width="0.5" />
    <path d="M165.99 35.59H176.55" stroke="black" stroke-width="0.5" />
    <path d="M176.55 35.59V34.71" stroke="black" stroke-width="0.5" />
    <path d="M176.55 34.71H165.99" stroke="black" stroke-width="0.5" />
    <path d="M165.99 34.71V35.59" stroke="black" stroke-width="0.5" />
    <path
      d="M186.93 13.21C181.393 19.0864 178.309 26.8559 178.31 34.93"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M186.93 13.21L188.48 11.67" stroke="black" stroke-width="0.5" />
    <path d="M208.43 34.72L209.98 33.17" stroke="black" stroke-width="0.5" />
    <path d="M208.43 34.72L186.93 13.21" stroke="black" stroke-width="0.5" />
    <path d="M209.98 33.17L188.48 11.67" stroke="black" stroke-width="0.5" />
    <path d="M257.19 137.39L256.1 138.48" stroke="black" stroke-width="0.5" />
    <path d="M256.1 138.48L278.49 160.87" stroke="black" stroke-width="0.5" />
    <path d="M278.49 160.87L279.58 159.79" stroke="black" stroke-width="0.5" />
    <path d="M279.58 159.79L257.19 137.39" stroke="black" stroke-width="0.5" />
    <path
      d="M257.19 137.39C254.248 140.331 251.914 143.823 250.321 147.667C248.729 151.51 247.91 155.63 247.91 159.79"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M492.88 135.23L493.97 134.14" stroke="black" stroke-width="0.5" />
    <path d="M493.97 134.14L471.58 111.74" stroke="black" stroke-width="0.5" />
    <path d="M471.58 111.74L470.49 112.83" stroke="black" stroke-width="0.5" />
    <path d="M470.49 112.83L492.88 135.23" stroke="black" stroke-width="0.5" />
    <path
      d="M492.88 135.23C495.821 132.288 498.153 128.796 499.743 124.952C501.334 121.109 502.152 116.99 502.15 112.83"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M57.57 545.74H71.65" stroke="black" stroke-width="0.5" />
    <path d="M71.65 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M71.65 544.86H57.57" stroke="black" stroke-width="0.5" />
    <path d="M57.57 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M73.41 545.74H133.23" stroke="black" stroke-width="0.5" />
    <path d="M133.23 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M133.23 544.86H73.41" stroke="black" stroke-width="0.5" />
    <path d="M73.41 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M134.98 545.74H149.06" stroke="black" stroke-width="0.5" />
    <path d="M149.06 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M149.06 544.86H134.98" stroke="black" stroke-width="0.5" />
    <path d="M134.98 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M55.82 547.94H57.57" stroke="black" stroke-width="0.5" />
    <path d="M57.57 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M57.57 542.66H55.82" stroke="black" stroke-width="0.5" />
    <path d="M55.82 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M149.06 547.94H150.82" stroke="black" stroke-width="0.5" />
    <path d="M150.82 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M150.82 542.66H149.06" stroke="black" stroke-width="0.5" />
    <path d="M149.06 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M57.57 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M57.57 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M71.65 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M71.65 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M57.57 547.94H71.65" stroke="black" stroke-width="0.5" />
    <path d="M71.65 547.94H57.57" stroke="black" stroke-width="0.5" />
    <path d="M71.65 542.66H57.57" stroke="black" stroke-width="0.5" />
    <path d="M71.65 542.66H57.57" stroke="black" stroke-width="0.5" />
    <path d="M71.65 547.94H73.41" stroke="black" stroke-width="0.5" />
    <path d="M73.41 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M73.41 542.66H71.65" stroke="black" stroke-width="0.5" />
    <path d="M71.65 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M133.23 547.94H134.98" stroke="black" stroke-width="0.5" />
    <path d="M134.98 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M134.98 542.66H133.23" stroke="black" stroke-width="0.5" />
    <path d="M133.23 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M73.41 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M73.41 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M133.23 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M133.23 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M73.41 547.94H133.23" stroke="black" stroke-width="0.5" />
    <path d="M133.23 547.94H73.41" stroke="black" stroke-width="0.5" />
    <path d="M133.23 542.66H73.41" stroke="black" stroke-width="0.5" />
    <path d="M133.23 542.66H73.41" stroke="black" stroke-width="0.5" />
    <path d="M134.98 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M134.98 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M149.06 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M149.06 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M134.98 547.94H149.06" stroke="black" stroke-width="0.5" />
    <path d="M149.06 547.94H134.98" stroke="black" stroke-width="0.5" />
    <path d="M149.06 542.66H134.98" stroke="black" stroke-width="0.5" />
    <path d="M149.06 542.66H134.98" stroke="black" stroke-width="0.5" />
    <path d="M226.91 545.74H240.98" stroke="black" stroke-width="0.5" />
    <path d="M240.98 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M240.98 544.86H226.91" stroke="black" stroke-width="0.5" />
    <path d="M226.91 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M242.74 545.74H302.56" stroke="black" stroke-width="0.5" />
    <path d="M302.56 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M302.56 544.86H242.74" stroke="black" stroke-width="0.5" />
    <path d="M242.74 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M304.32 545.74H313.12" stroke="black" stroke-width="0.5" />
    <path d="M313.12 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M313.12 544.86H304.32" stroke="black" stroke-width="0.5" />
    <path d="M304.32 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M225.15 547.94H226.91" stroke="black" stroke-width="0.5" />
    <path d="M226.91 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M226.91 542.66H225.15" stroke="black" stroke-width="0.5" />
    <path d="M225.15 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M313.12 547.94H314.88" stroke="black" stroke-width="0.5" />
    <path d="M314.88 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M314.88 542.66H313.12" stroke="black" stroke-width="0.5" />
    <path d="M313.12 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M226.91 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M226.91 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M240.98 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M240.98 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M226.91 547.94H240.98" stroke="black" stroke-width="0.5" />
    <path d="M240.98 547.94H226.91" stroke="black" stroke-width="0.5" />
    <path d="M240.98 542.66H226.91" stroke="black" stroke-width="0.5" />
    <path d="M240.98 542.66H226.91" stroke="black" stroke-width="0.5" />
    <path d="M240.98 547.94H242.74" stroke="black" stroke-width="0.5" />
    <path d="M242.74 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M242.74 542.66H240.98" stroke="black" stroke-width="0.5" />
    <path d="M240.98 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M302.56 547.94H304.32" stroke="black" stroke-width="0.5" />
    <path d="M304.32 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M304.32 542.66H302.56" stroke="black" stroke-width="0.5" />
    <path d="M302.56 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M242.74 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M242.74 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M302.56 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M302.56 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M242.74 547.94H302.56" stroke="black" stroke-width="0.5" />
    <path d="M302.56 547.94H242.74" stroke="black" stroke-width="0.5" />
    <path d="M302.56 542.66H242.74" stroke="black" stroke-width="0.5" />
    <path d="M302.56 542.66H242.74" stroke="black" stroke-width="0.5" />
    <path d="M304.32 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M304.32 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M313.12 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M313.12 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M304.32 547.94H313.12" stroke="black" stroke-width="0.5" />
    <path d="M313.12 547.94H304.32" stroke="black" stroke-width="0.5" />
    <path d="M313.12 542.66H304.32" stroke="black" stroke-width="0.5" />
    <path d="M313.12 542.66H304.32" stroke="black" stroke-width="0.5" />
    <path d="M398.55 545.74H409.99" stroke="black" stroke-width="0.5" />
    <path d="M409.99 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M409.99 544.86H398.55" stroke="black" stroke-width="0.5" />
    <path d="M398.55 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M411.75 545.74H471.56" stroke="black" stroke-width="0.5" />
    <path d="M471.56 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M471.56 544.86H411.75" stroke="black" stroke-width="0.5" />
    <path d="M411.75 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M473.32 545.74H484.76" stroke="black" stroke-width="0.5" />
    <path d="M484.76 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M484.76 544.86H473.32" stroke="black" stroke-width="0.5" />
    <path d="M473.32 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M396.79 547.94H398.55" stroke="black" stroke-width="0.5" />
    <path d="M398.55 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M398.55 542.66H396.79" stroke="black" stroke-width="0.5" />
    <path d="M396.79 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M484.76 547.94H486.52" stroke="black" stroke-width="0.5" />
    <path d="M486.52 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M486.52 542.66H484.76" stroke="black" stroke-width="0.5" />
    <path d="M484.76 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M398.55 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M398.55 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M409.99 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M409.99 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M398.55 547.94H409.99" stroke="black" stroke-width="0.5" />
    <path d="M409.99 547.94H398.55" stroke="black" stroke-width="0.5" />
    <path d="M409.99 542.66H398.55" stroke="black" stroke-width="0.5" />
    <path d="M409.99 542.66H398.55" stroke="black" stroke-width="0.5" />
    <path d="M409.99 547.94H411.75" stroke="black" stroke-width="0.5" />
    <path d="M411.75 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M411.75 542.66H409.99" stroke="black" stroke-width="0.5" />
    <path d="M409.99 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M471.56 547.94H473.32" stroke="black" stroke-width="0.5" />
    <path d="M473.32 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M473.32 542.66H471.56" stroke="black" stroke-width="0.5" />
    <path d="M471.56 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M411.75 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M411.75 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M471.56 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M471.56 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M411.75 547.94H471.56" stroke="black" stroke-width="0.5" />
    <path d="M471.56 547.94H411.75" stroke="black" stroke-width="0.5" />
    <path d="M471.56 542.66H411.75" stroke="black" stroke-width="0.5" />
    <path d="M471.56 542.66H411.75" stroke="black" stroke-width="0.5" />
    <path d="M473.32 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M473.32 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M484.76 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M484.76 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M473.32 547.94H484.76" stroke="black" stroke-width="0.5" />
    <path d="M484.76 547.94H473.32" stroke="black" stroke-width="0.5" />
    <path d="M484.76 542.66H473.32" stroke="black" stroke-width="0.5" />
    <path d="M484.76 542.66H473.32" stroke="black" stroke-width="0.5" />
    <path d="M580.31 545.74H583.83" stroke="black" stroke-width="0.5" />
    <path d="M583.83 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M583.83 544.86H580.31" stroke="black" stroke-width="0.5" />
    <path d="M580.31 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M585.59 545.74H645.41" stroke="black" stroke-width="0.5" />
    <path d="M645.41 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M645.41 544.86H585.59" stroke="black" stroke-width="0.5" />
    <path d="M585.59 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M647.17 545.74H650.69" stroke="black" stroke-width="0.5" />
    <path d="M650.69 545.74V544.86" stroke="black" stroke-width="0.5" />
    <path d="M650.69 544.86H647.17" stroke="black" stroke-width="0.5" />
    <path d="M647.17 544.86V545.74" stroke="black" stroke-width="0.5" />
    <path d="M578.55 547.94H580.31" stroke="black" stroke-width="0.5" />
    <path d="M580.31 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M580.31 542.66H578.55" stroke="black" stroke-width="0.5" />
    <path d="M578.55 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M650.69 547.94H652.44" stroke="black" stroke-width="0.5" />
    <path d="M652.44 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M652.44 542.66H650.69" stroke="black" stroke-width="0.5" />
    <path d="M650.69 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M580.31 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M580.31 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M583.83 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M583.83 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M580.31 547.94H583.83" stroke="black" stroke-width="0.5" />
    <path d="M583.83 547.94H580.31" stroke="black" stroke-width="0.5" />
    <path d="M583.83 542.66H580.31" stroke="black" stroke-width="0.5" />
    <path d="M583.83 542.66H580.31" stroke="black" stroke-width="0.5" />
    <path d="M583.83 547.94H585.59" stroke="black" stroke-width="0.5" />
    <path d="M585.59 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M585.59 542.66H583.83" stroke="black" stroke-width="0.5" />
    <path d="M583.83 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M645.41 547.94H647.17" stroke="black" stroke-width="0.5" />
    <path d="M647.17 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M647.17 542.66H645.41" stroke="black" stroke-width="0.5" />
    <path d="M645.41 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M585.59 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M585.59 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M645.41 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M645.41 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M585.59 547.94H645.41" stroke="black" stroke-width="0.5" />
    <path d="M645.41 547.94H585.59" stroke="black" stroke-width="0.5" />
    <path d="M645.41 542.66H585.59" stroke="black" stroke-width="0.5" />
    <path d="M645.41 542.66H585.59" stroke="black" stroke-width="0.5" />
    <path d="M647.17 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M647.17 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M650.69 547.94V542.66" stroke="black" stroke-width="0.5" />
    <path d="M650.69 542.66V547.94" stroke="black" stroke-width="0.5" />
    <path d="M647.17 547.94H650.69" stroke="black" stroke-width="0.5" />
    <path d="M650.69 547.94H647.17" stroke="black" stroke-width="0.5" />
    <path d="M650.69 542.66H647.17" stroke="black" stroke-width="0.5" />
    <path d="M650.69 542.66H647.17" stroke="black" stroke-width="0.5" />
    <path d="M304.34 254.56V254.62" stroke="black" stroke-width="0.5" />
    <path d="M304.34 254.73V254.84" stroke="black" stroke-width="0.5" />
    <path d="M304.34 254.95V255.06" stroke="black" stroke-width="0.5" />
    <path d="M304.34 255.17V255.28" stroke="black" stroke-width="0.5" />
    <path d="M304.34 255.39V255.5" stroke="black" stroke-width="0.5" />
    <path d="M304.34 255.61V255.72" stroke="black" stroke-width="0.5" />
    <path d="M304.34 255.83V255.94" stroke="black" stroke-width="0.5" />
    <path d="M304.34 256.05V256.16" stroke="black" stroke-width="0.5" />
    <path d="M304.34 256.27V256.38" stroke="black" stroke-width="0.5" />
    <path d="M304.34 256.49V256.6" stroke="black" stroke-width="0.5" />
    <path d="M304.34 256.71V256.82" stroke="black" stroke-width="0.5" />
    <path d="M304.34 256.93V257.04" stroke="black" stroke-width="0.5" />
    <path d="M304.34 257.15V257.26" stroke="black" stroke-width="0.5" />
    <path d="M304.34 257.37V257.48" stroke="black" stroke-width="0.5" />
    <path d="M304.34 257.59V257.7" stroke="black" stroke-width="0.5" />
    <path d="M304.34 257.81V257.92" stroke="black" stroke-width="0.5" />
    <path d="M304.34 258.03V258.14" stroke="black" stroke-width="0.5" />
    <path d="M304.34 258.25V258.36" stroke="black" stroke-width="0.5" />
    <path d="M304.34 258.47V258.58" stroke="black" stroke-width="0.5" />
    <path d="M304.34 258.69V258.8" stroke="black" stroke-width="0.5" />
    <path d="M304.34 258.91V259.02" stroke="black" stroke-width="0.5" />
    <path d="M304.34 259.13V259.24" stroke="black" stroke-width="0.5" />
    <path d="M304.34 259.35V259.46" stroke="black" stroke-width="0.5" />
    <path d="M304.34 259.57V259.68" stroke="black" stroke-width="0.5" />
    <path d="M304.34 259.79V259.9" stroke="black" stroke-width="0.5" />
    <path d="M304.34 260.01V260.12" stroke="black" stroke-width="0.5" />
    <path d="M304.34 260.23V260.34" stroke="black" stroke-width="0.5" />
    <path d="M304.34 260.45V260.56" stroke="black" stroke-width="0.5" />
    <path d="M304.34 260.67V260.78" stroke="black" stroke-width="0.5" />
    <path d="M304.34 260.89V261" stroke="black" stroke-width="0.5" />
    <path d="M304.34 261.11V261.22" stroke="black" stroke-width="0.5" />
    <path d="M304.34 261.33V261.44" stroke="black" stroke-width="0.5" />
    <path d="M304.34 261.55V261.66" stroke="black" stroke-width="0.5" />
    <path d="M304.34 261.77V261.88" stroke="black" stroke-width="0.5" />
    <path d="M304.34 261.99V262.1" stroke="black" stroke-width="0.5" />
    <path d="M304.34 262.21V262.32" stroke="black" stroke-width="0.5" />
    <path d="M304.34 262.43V262.54" stroke="black" stroke-width="0.5" />
    <path d="M304.34 262.65V262.76" stroke="black" stroke-width="0.5" />
    <path d="M304.34 262.87V262.98" stroke="black" stroke-width="0.5" />
    <path d="M304.34 263.09V263.19" stroke="black" stroke-width="0.5" />
    <path d="M304.34 263.3V263.41" stroke="black" stroke-width="0.5" />
    <path d="M304.34 263.52V263.63" stroke="black" stroke-width="0.5" />
    <path d="M304.34 263.74V263.85" stroke="black" stroke-width="0.5" />
    <path d="M304.34 263.96V264.07" stroke="black" stroke-width="0.5" />
    <path d="M304.34 264.18V264.29" stroke="black" stroke-width="0.5" />
    <path d="M304.34 264.4V264.51" stroke="black" stroke-width="0.5" />
    <path d="M304.34 264.62V264.73" stroke="black" stroke-width="0.5" />
    <path d="M304.34 264.84V264.95" stroke="black" stroke-width="0.5" />
    <path d="M304.34 265.06V265.17" stroke="black" stroke-width="0.5" />
    <path d="M304.34 265.28V265.39" stroke="black" stroke-width="0.5" />
    <path d="M304.34 265.5V265.61" stroke="black" stroke-width="0.5" />
    <path d="M304.34 265.72V265.83" stroke="black" stroke-width="0.5" />
    <path d="M304.34 265.94V266.05" stroke="black" stroke-width="0.5" />
    <path d="M304.34 266.16V266.27" stroke="black" stroke-width="0.5" />
    <path d="M304.34 266.38V266.49" stroke="black" stroke-width="0.5" />
    <path d="M304.34 266.6V266.71" stroke="black" stroke-width="0.5" />
    <path d="M304.34 266.82V266.93" stroke="black" stroke-width="0.5" />
    <path d="M304.34 267.04V267.15" stroke="black" stroke-width="0.5" />
    <path d="M304.34 267.26V267.37" stroke="black" stroke-width="0.5" />
    <path d="M304.34 267.48V267.59" stroke="black" stroke-width="0.5" />
    <path d="M304.34 267.7V267.81" stroke="black" stroke-width="0.5" />
    <path d="M304.34 267.92V268.03" stroke="black" stroke-width="0.5" />
    <path d="M304.34 268.14V268.25" stroke="black" stroke-width="0.5" />
    <path d="M304.34 268.36V268.47" stroke="black" stroke-width="0.5" />
    <path d="M304.34 268.58V268.69" stroke="black" stroke-width="0.5" />
    <path d="M304.34 268.8V268.91" stroke="black" stroke-width="0.5" />
    <path d="M304.34 269.02V269.13" stroke="black" stroke-width="0.5" />
    <path d="M304.34 269.24V269.35" stroke="black" stroke-width="0.5" />
    <path d="M304.34 269.46V269.57" stroke="black" stroke-width="0.5" />
    <path d="M304.34 269.68V269.79" stroke="black" stroke-width="0.5" />
    <path d="M304.34 269.9V270.01" stroke="black" stroke-width="0.5" />
    <path d="M304.34 270.12V270.23" stroke="black" stroke-width="0.5" />
    <path d="M304.34 270.34V270.45" stroke="black" stroke-width="0.5" />
    <path d="M304.34 270.56V270.67" stroke="black" stroke-width="0.5" />
    <path d="M304.34 270.78V270.89" stroke="black" stroke-width="0.5" />
    <path d="M304.34 271V271.11" stroke="black" stroke-width="0.5" />
    <path d="M304.34 271.22V271.33" stroke="black" stroke-width="0.5" />
    <path d="M304.34 271.44V271.55" stroke="black" stroke-width="0.5" />
    <path d="M304.34 271.66V271.77" stroke="black" stroke-width="0.5" />
    <path d="M304.34 271.88V271.99" stroke="black" stroke-width="0.5" />
    <path d="M304.34 272.1V272.21" stroke="black" stroke-width="0.5" />
    <path d="M304.34 272.32V272.43" stroke="black" stroke-width="0.5" />
    <path d="M304.34 272.54V272.65" stroke="black" stroke-width="0.5" />
    <path d="M304.34 272.76V272.87" stroke="black" stroke-width="0.5" />
    <path d="M304.34 272.98V273.09" stroke="black" stroke-width="0.5" />
    <path d="M304.34 273.2V273.31" stroke="black" stroke-width="0.5" />
    <path d="M304.34 273.42V273.53" stroke="black" stroke-width="0.5" />
    <path d="M304.34 273.64V273.75" stroke="black" stroke-width="0.5" />
    <path d="M304.34 273.86V273.97" stroke="black" stroke-width="0.5" />
    <path d="M304.34 274.08V274.19" stroke="black" stroke-width="0.5" />
    <path d="M304.34 274.3V274.41" stroke="black" stroke-width="0.5" />
    <path d="M304.34 274.52V274.63" stroke="black" stroke-width="0.5" />
    <path d="M304.34 274.74V274.85" stroke="black" stroke-width="0.5" />
    <path d="M304.34 274.96V275.07" stroke="black" stroke-width="0.5" />
    <path d="M304.34 275.18V275.29" stroke="black" stroke-width="0.5" />
    <path d="M304.34 275.4V275.51" stroke="black" stroke-width="0.5" />
    <path d="M304.34 275.62V275.73" stroke="black" stroke-width="0.5" />
    <path d="M304.34 275.84V275.95" stroke="black" stroke-width="0.5" />
    <path d="M304.34 276.06V276.17" stroke="black" stroke-width="0.5" />
    <path d="M304.34 276.28V276.39" stroke="black" stroke-width="0.5" />
    <path d="M304.34 276.5V276.61" stroke="black" stroke-width="0.5" />
    <path d="M304.34 276.72V276.83" stroke="black" stroke-width="0.5" />
    <path d="M304.34 276.94V277.05" stroke="black" stroke-width="0.5" />
    <path d="M304.34 277.16V277.27" stroke="black" stroke-width="0.5" />
    <path d="M304.34 277.38V277.49" stroke="black" stroke-width="0.5" />
    <path d="M304.34 277.6V277.71" stroke="black" stroke-width="0.5" />
    <path d="M304.34 277.82V277.93" stroke="black" stroke-width="0.5" />
    <path d="M304.34 278.04V278.15" stroke="black" stroke-width="0.5" />
    <path d="M304.34 278.26V278.37" stroke="black" stroke-width="0.5" />
    <path d="M304.34 278.48V278.59" stroke="black" stroke-width="0.5" />
    <path d="M304.34 278.7V278.81" stroke="black" stroke-width="0.5" />
    <path d="M304.34 278.92V279.03" stroke="black" stroke-width="0.5" />
    <path d="M304.34 279.14V279.25" stroke="black" stroke-width="0.5" />
    <path d="M304.34 279.36V279.47" stroke="black" stroke-width="0.5" />
    <path d="M304.34 279.58V279.69" stroke="black" stroke-width="0.5" />
    <path d="M304.34 279.8V279.91" stroke="black" stroke-width="0.5" />
    <path d="M304.34 280.02V280.13" stroke="black" stroke-width="0.5" />
    <path d="M304.34 280.24V280.35" stroke="black" stroke-width="0.5" />
    <path d="M304.34 280.46V280.57" stroke="black" stroke-width="0.5" />
    <path d="M304.34 280.68V280.79" stroke="black" stroke-width="0.5" />
    <path d="M304.34 280.9V281.01" stroke="black" stroke-width="0.5" />
    <path d="M304.34 281.12V281.23" stroke="black" stroke-width="0.5" />
    <path d="M304.34 281.34V281.45" stroke="black" stroke-width="0.5" />
    <path d="M304.34 281.56V281.67" stroke="black" stroke-width="0.5" />
    <path d="M304.34 281.78V281.89" stroke="black" stroke-width="0.5" />
    <path d="M304.34 282V282.11" stroke="black" stroke-width="0.5" />
    <path d="M304.34 282.22V282.33" stroke="black" stroke-width="0.5" />
    <path d="M304.34 282.44V282.55" stroke="black" stroke-width="0.5" />
    <path d="M304.34 282.66V282.77" stroke="black" stroke-width="0.5" />
    <path d="M304.34 282.88V282.99" stroke="black" stroke-width="0.5" />
    <path d="M304.34 283.1V283.21" stroke="black" stroke-width="0.5" />
    <path d="M304.34 283.32V283.43" stroke="black" stroke-width="0.5" />
    <path d="M304.34 283.54V283.65" stroke="black" stroke-width="0.5" />
    <path d="M304.34 283.76V283.87" stroke="black" stroke-width="0.5" />
    <path d="M304.34 283.98V284.09" stroke="black" stroke-width="0.5" />
    <path d="M304.34 284.2V284.31" stroke="black" stroke-width="0.5" />
    <path d="M304.34 284.42V284.53" stroke="black" stroke-width="0.5" />
    <path d="M304.34 284.64V284.75" stroke="black" stroke-width="0.5" />
    <path d="M304.34 284.86V284.97" stroke="black" stroke-width="0.5" />
    <path d="M304.34 285.08V285.19" stroke="black" stroke-width="0.5" />
    <path d="M304.34 285.3V285.41" stroke="black" stroke-width="0.5" />
    <path d="M304.34 285.52V285.63" stroke="black" stroke-width="0.5" />
    <path d="M304.34 285.74V285.85" stroke="black" stroke-width="0.5" />
    <path d="M304.34 285.96V286.07" stroke="black" stroke-width="0.5" />
    <path d="M304.34 286.18V286.29" stroke="black" stroke-width="0.5" />
    <path d="M304.34 286.4V286.51" stroke="black" stroke-width="0.5" />
    <path d="M304.34 286.62V286.73" stroke="black" stroke-width="0.5" />
    <path d="M304.34 286.84V286.95" stroke="black" stroke-width="0.5" />
    <path d="M304.34 287.06V287.17" stroke="black" stroke-width="0.5" />
    <path d="M304.34 287.28V287.39" stroke="black" stroke-width="0.5" />
    <path d="M304.34 287.5V287.61" stroke="black" stroke-width="0.5" />
    <path d="M304.34 287.72V287.83" stroke="black" stroke-width="0.5" />
    <path d="M304.34 287.94V288.05" stroke="black" stroke-width="0.5" />
    <path d="M304.34 288.16V288.27" stroke="black" stroke-width="0.5" />
    <path d="M304.34 288.38V288.49" stroke="black" stroke-width="0.5" />
    <path d="M304.34 288.6V288.71" stroke="black" stroke-width="0.5" />
    <path d="M304.34 288.82V288.93" stroke="black" stroke-width="0.5" />
    <path d="M304.34 289.03V289.14" stroke="black" stroke-width="0.5" />
    <path d="M304.34 289.25V289.36" stroke="black" stroke-width="0.5" />
    <path d="M304.34 289.47V289.58" stroke="black" stroke-width="0.5" />
    <path d="M304.34 289.69V289.8" stroke="black" stroke-width="0.5" />
    <path d="M304.34 289.91V290.02" stroke="black" stroke-width="0.5" />
    <path d="M304.34 290.13V290.24" stroke="black" stroke-width="0.5" />
    <path d="M304.34 290.35V290.46" stroke="black" stroke-width="0.5" />
    <path d="M304.34 290.57V290.68" stroke="black" stroke-width="0.5" />
    <path d="M304.34 290.79V290.9" stroke="black" stroke-width="0.5" />
    <path d="M304.34 291.01V291.12" stroke="black" stroke-width="0.5" />
    <path d="M304.34 291.23V291.34" stroke="black" stroke-width="0.5" />
    <path d="M304.34 291.45V291.56" stroke="black" stroke-width="0.5" />
    <path d="M304.34 291.67V291.78" stroke="black" stroke-width="0.5" />
    <path d="M304.34 291.89V292" stroke="black" stroke-width="0.5" />
    <path d="M304.34 292.11V292.22" stroke="black" stroke-width="0.5" />
    <path d="M304.34 292.33V292.44" stroke="black" stroke-width="0.5" />
    <path d="M304.34 292.55V292.66" stroke="black" stroke-width="0.5" />
    <path d="M304.34 292.77V292.88" stroke="black" stroke-width="0.5" />
    <path d="M304.34 292.99V293.1" stroke="black" stroke-width="0.5" />
    <path d="M304.34 293.21V293.32" stroke="black" stroke-width="0.5" />
    <path d="M304.34 293.43V293.54" stroke="black" stroke-width="0.5" />
    <path d="M304.34 293.65V293.76" stroke="black" stroke-width="0.5" />
    <path d="M304.34 293.87V293.98" stroke="black" stroke-width="0.5" />
    <path d="M304.34 294.09V294.2" stroke="black" stroke-width="0.5" />
    <path d="M304.34 294.31V294.42" stroke="black" stroke-width="0.5" />
    <path d="M304.34 294.53V294.64" stroke="black" stroke-width="0.5" />
    <path d="M304.34 294.75V294.86" stroke="black" stroke-width="0.5" />
    <path d="M304.34 294.97V295.08" stroke="black" stroke-width="0.5" />
    <path d="M304.34 295.19V295.3" stroke="black" stroke-width="0.5" />
    <path d="M304.34 295.41V295.52" stroke="black" stroke-width="0.5" />
    <path d="M304.34 295.63V295.74" stroke="black" stroke-width="0.5" />
    <path d="M304.34 295.85V295.96" stroke="black" stroke-width="0.5" />
    <path d="M304.34 296.07V296.18" stroke="black" stroke-width="0.5" />
    <path d="M304.34 296.29V296.4" stroke="black" stroke-width="0.5" />
    <path d="M304.34 296.51V296.62" stroke="black" stroke-width="0.5" />
    <path d="M304.34 296.73V296.84" stroke="black" stroke-width="0.5" />
    <path d="M304.34 296.95V297.06" stroke="black" stroke-width="0.5" />
    <path d="M304.34 297.17V297.28" stroke="black" stroke-width="0.5" />
    <path d="M304.34 297.39V297.5" stroke="black" stroke-width="0.5" />
    <path d="M304.34 297.61V297.72" stroke="black" stroke-width="0.5" />
    <path d="M304.34 297.83V297.94" stroke="black" stroke-width="0.5" />
    <path d="M304.34 298.05V298.16" stroke="black" stroke-width="0.5" />
    <path d="M304.34 298.27V298.38" stroke="black" stroke-width="0.5" />
    <path d="M304.34 298.49V298.6" stroke="black" stroke-width="0.5" />
    <path d="M304.34 298.71V298.82" stroke="black" stroke-width="0.5" />
    <path d="M304.34 298.93V299.04" stroke="black" stroke-width="0.5" />
    <path d="M304.34 299.15V299.26" stroke="black" stroke-width="0.5" />
    <path d="M304.34 299.37V299.48" stroke="black" stroke-width="0.5" />
    <path d="M304.34 299.59V299.7" stroke="black" stroke-width="0.5" />
    <path d="M304.34 299.81V299.92" stroke="black" stroke-width="0.5" />
    <path d="M304.34 300.03V300.14" stroke="black" stroke-width="0.5" />
    <path d="M304.34 300.25V300.36" stroke="black" stroke-width="0.5" />
    <path d="M304.34 300.47V300.58" stroke="black" stroke-width="0.5" />
    <path d="M304.34 300.69V300.8" stroke="black" stroke-width="0.5" />
    <path d="M304.34 300.91V301.02" stroke="black" stroke-width="0.5" />
    <path d="M304.34 301.13V301.24" stroke="black" stroke-width="0.5" />
    <path d="M304.34 301.35V301.46" stroke="black" stroke-width="0.5" />
    <path d="M304.34 301.57V301.68" stroke="black" stroke-width="0.5" />
    <path d="M304.34 301.79V301.9" stroke="black" stroke-width="0.5" />
    <path d="M304.34 302.01V302.12" stroke="black" stroke-width="0.5" />
    <path d="M304.34 302.23V302.34" stroke="black" stroke-width="0.5" />
    <path d="M304.34 302.45V302.56" stroke="black" stroke-width="0.5" />
    <path d="M304.34 302.67V302.78" stroke="black" stroke-width="0.5" />
    <path d="M304.34 302.89V303" stroke="black" stroke-width="0.5" />
    <path d="M304.34 303.11V303.22" stroke="black" stroke-width="0.5" />
    <path d="M304.34 303.33V303.44" stroke="black" stroke-width="0.5" />
    <path d="M304.34 303.55V303.66" stroke="black" stroke-width="0.5" />
    <path d="M304.34 303.77V303.82" stroke="black" stroke-width="0.5" />
    <path d="M251.56 254.56V254.62" stroke="black" stroke-width="0.5" />
    <path d="M251.56 254.73V254.84" stroke="black" stroke-width="0.5" />
    <path d="M251.56 254.95V255.06" stroke="black" stroke-width="0.5" />
    <path d="M251.56 255.17V255.28" stroke="black" stroke-width="0.5" />
    <path d="M251.56 255.39V255.5" stroke="black" stroke-width="0.5" />
    <path d="M251.56 255.61V255.72" stroke="black" stroke-width="0.5" />
    <path d="M251.56 255.83V255.94" stroke="black" stroke-width="0.5" />
    <path d="M251.56 256.05V256.16" stroke="black" stroke-width="0.5" />
    <path d="M251.56 256.27V256.38" stroke="black" stroke-width="0.5" />
    <path d="M251.56 256.49V256.6" stroke="black" stroke-width="0.5" />
    <path d="M251.56 256.71V256.82" stroke="black" stroke-width="0.5" />
    <path d="M251.56 256.93V257.04" stroke="black" stroke-width="0.5" />
    <path d="M251.56 257.15V257.26" stroke="black" stroke-width="0.5" />
    <path d="M251.56 257.37V257.48" stroke="black" stroke-width="0.5" />
    <path d="M251.56 257.59V257.7" stroke="black" stroke-width="0.5" />
    <path d="M251.56 257.81V257.92" stroke="black" stroke-width="0.5" />
    <path d="M251.56 258.03V258.14" stroke="black" stroke-width="0.5" />
    <path d="M251.56 258.25V258.36" stroke="black" stroke-width="0.5" />
    <path d="M251.56 258.47V258.58" stroke="black" stroke-width="0.5" />
    <path d="M251.56 258.69V258.8" stroke="black" stroke-width="0.5" />
    <path d="M251.56 258.91V259.02" stroke="black" stroke-width="0.5" />
    <path d="M251.56 259.13V259.24" stroke="black" stroke-width="0.5" />
    <path d="M251.56 259.35V259.46" stroke="black" stroke-width="0.5" />
    <path d="M251.56 259.57V259.68" stroke="black" stroke-width="0.5" />
    <path d="M251.56 259.79V259.9" stroke="black" stroke-width="0.5" />
    <path d="M251.56 260.01V260.12" stroke="black" stroke-width="0.5" />
    <path d="M251.56 260.23V260.34" stroke="black" stroke-width="0.5" />
    <path d="M251.56 260.45V260.56" stroke="black" stroke-width="0.5" />
    <path d="M251.56 260.67V260.78" stroke="black" stroke-width="0.5" />
    <path d="M251.56 260.89V261" stroke="black" stroke-width="0.5" />
    <path d="M251.56 261.11V261.22" stroke="black" stroke-width="0.5" />
    <path d="M251.56 261.33V261.44" stroke="black" stroke-width="0.5" />
    <path d="M251.56 261.55V261.66" stroke="black" stroke-width="0.5" />
    <path d="M251.56 261.77V261.88" stroke="black" stroke-width="0.5" />
    <path d="M251.56 261.99V262.1" stroke="black" stroke-width="0.5" />
    <path d="M251.56 262.21V262.32" stroke="black" stroke-width="0.5" />
    <path d="M251.56 262.43V262.54" stroke="black" stroke-width="0.5" />
    <path d="M251.56 262.65V262.76" stroke="black" stroke-width="0.5" />
    <path d="M251.56 262.87V262.98" stroke="black" stroke-width="0.5" />
    <path d="M251.56 263.09V263.19" stroke="black" stroke-width="0.5" />
    <path d="M251.56 263.3V263.41" stroke="black" stroke-width="0.5" />
    <path d="M251.56 263.52V263.63" stroke="black" stroke-width="0.5" />
    <path d="M251.56 263.74V263.85" stroke="black" stroke-width="0.5" />
    <path d="M251.56 263.96V264.07" stroke="black" stroke-width="0.5" />
    <path d="M251.56 264.18V264.29" stroke="black" stroke-width="0.5" />
    <path d="M251.56 264.4V264.51" stroke="black" stroke-width="0.5" />
    <path d="M251.56 264.62V264.73" stroke="black" stroke-width="0.5" />
    <path d="M251.56 264.84V264.95" stroke="black" stroke-width="0.5" />
    <path d="M251.56 265.06V265.17" stroke="black" stroke-width="0.5" />
    <path d="M251.56 265.28V265.39" stroke="black" stroke-width="0.5" />
    <path d="M251.56 265.5V265.61" stroke="black" stroke-width="0.5" />
    <path d="M251.56 265.72V265.83" stroke="black" stroke-width="0.5" />
    <path d="M251.56 265.94V266.05" stroke="black" stroke-width="0.5" />
    <path d="M251.56 266.16V266.27" stroke="black" stroke-width="0.5" />
    <path d="M251.56 266.38V266.49" stroke="black" stroke-width="0.5" />
    <path d="M251.56 266.6V266.71" stroke="black" stroke-width="0.5" />
    <path d="M251.56 266.82V266.93" stroke="black" stroke-width="0.5" />
    <path d="M251.56 267.04V267.15" stroke="black" stroke-width="0.5" />
    <path d="M251.56 267.26V267.37" stroke="black" stroke-width="0.5" />
    <path d="M251.56 267.48V267.59" stroke="black" stroke-width="0.5" />
    <path d="M251.56 267.7V267.81" stroke="black" stroke-width="0.5" />
    <path d="M251.56 267.92V268.03" stroke="black" stroke-width="0.5" />
    <path d="M251.56 268.14V268.25" stroke="black" stroke-width="0.5" />
    <path d="M251.56 268.36V268.47" stroke="black" stroke-width="0.5" />
    <path d="M251.56 268.58V268.69" stroke="black" stroke-width="0.5" />
    <path d="M251.56 268.8V268.91" stroke="black" stroke-width="0.5" />
    <path d="M251.56 269.02V269.13" stroke="black" stroke-width="0.5" />
    <path d="M251.56 269.24V269.35" stroke="black" stroke-width="0.5" />
    <path d="M251.56 269.46V269.57" stroke="black" stroke-width="0.5" />
    <path d="M251.56 269.68V269.79" stroke="black" stroke-width="0.5" />
    <path d="M251.56 269.9V270.01" stroke="black" stroke-width="0.5" />
    <path d="M251.56 270.12V270.23" stroke="black" stroke-width="0.5" />
    <path d="M251.56 270.34V270.45" stroke="black" stroke-width="0.5" />
    <path d="M251.56 270.56V270.67" stroke="black" stroke-width="0.5" />
    <path d="M251.56 270.78V270.89" stroke="black" stroke-width="0.5" />
    <path d="M251.56 271V271.11" stroke="black" stroke-width="0.5" />
    <path d="M251.56 271.22V271.33" stroke="black" stroke-width="0.5" />
    <path d="M251.56 271.44V271.55" stroke="black" stroke-width="0.5" />
    <path d="M251.56 271.66V271.77" stroke="black" stroke-width="0.5" />
    <path d="M251.56 271.88V271.99" stroke="black" stroke-width="0.5" />
    <path d="M251.56 272.1V272.21" stroke="black" stroke-width="0.5" />
    <path d="M251.56 272.32V272.43" stroke="black" stroke-width="0.5" />
    <path d="M251.56 272.54V272.65" stroke="black" stroke-width="0.5" />
    <path d="M251.56 272.76V272.87" stroke="black" stroke-width="0.5" />
    <path d="M251.56 272.98V273.09" stroke="black" stroke-width="0.5" />
    <path d="M251.56 273.2V273.31" stroke="black" stroke-width="0.5" />
    <path d="M251.56 273.42V273.53" stroke="black" stroke-width="0.5" />
    <path d="M251.56 273.64V273.75" stroke="black" stroke-width="0.5" />
    <path d="M251.56 273.86V273.97" stroke="black" stroke-width="0.5" />
    <path d="M251.56 274.08V274.19" stroke="black" stroke-width="0.5" />
    <path d="M251.56 274.3V274.41" stroke="black" stroke-width="0.5" />
    <path d="M251.56 274.52V274.63" stroke="black" stroke-width="0.5" />
    <path d="M251.56 274.74V274.85" stroke="black" stroke-width="0.5" />
    <path d="M251.56 274.96V275.07" stroke="black" stroke-width="0.5" />
    <path d="M251.56 275.18V275.29" stroke="black" stroke-width="0.5" />
    <path d="M251.56 275.4V275.51" stroke="black" stroke-width="0.5" />
    <path d="M251.56 275.62V275.73" stroke="black" stroke-width="0.5" />
    <path d="M251.56 275.84V275.95" stroke="black" stroke-width="0.5" />
    <path d="M251.56 276.06V276.17" stroke="black" stroke-width="0.5" />
    <path d="M251.56 276.28V276.39" stroke="black" stroke-width="0.5" />
    <path d="M251.56 276.5V276.61" stroke="black" stroke-width="0.5" />
    <path d="M251.56 276.72V276.83" stroke="black" stroke-width="0.5" />
    <path d="M251.56 276.94V277.05" stroke="black" stroke-width="0.5" />
    <path d="M251.56 277.16V277.27" stroke="black" stroke-width="0.5" />
    <path d="M251.56 277.38V277.49" stroke="black" stroke-width="0.5" />
    <path d="M251.56 277.6V277.71" stroke="black" stroke-width="0.5" />
    <path d="M251.56 277.82V277.93" stroke="black" stroke-width="0.5" />
    <path d="M251.56 278.04V278.15" stroke="black" stroke-width="0.5" />
    <path d="M251.56 278.26V278.37" stroke="black" stroke-width="0.5" />
    <path d="M251.56 278.48V278.59" stroke="black" stroke-width="0.5" />
    <path d="M251.56 278.7V278.81" stroke="black" stroke-width="0.5" />
    <path d="M251.56 278.92V279.03" stroke="black" stroke-width="0.5" />
    <path d="M251.56 279.14V279.25" stroke="black" stroke-width="0.5" />
    <path d="M251.56 279.36V279.47" stroke="black" stroke-width="0.5" />
    <path d="M251.56 279.58V279.69" stroke="black" stroke-width="0.5" />
    <path d="M251.56 279.8V279.91" stroke="black" stroke-width="0.5" />
    <path d="M251.56 280.02V280.13" stroke="black" stroke-width="0.5" />
    <path d="M251.56 280.24V280.35" stroke="black" stroke-width="0.5" />
    <path d="M251.56 280.46V280.57" stroke="black" stroke-width="0.5" />
    <path d="M251.56 280.68V280.79" stroke="black" stroke-width="0.5" />
    <path d="M251.56 280.9V281.01" stroke="black" stroke-width="0.5" />
    <path d="M251.56 281.12V281.23" stroke="black" stroke-width="0.5" />
    <path d="M251.56 281.34V281.45" stroke="black" stroke-width="0.5" />
    <path d="M251.56 281.56V281.67" stroke="black" stroke-width="0.5" />
    <path d="M251.56 281.78V281.89" stroke="black" stroke-width="0.5" />
    <path d="M251.56 282V282.11" stroke="black" stroke-width="0.5" />
    <path d="M251.56 282.22V282.33" stroke="black" stroke-width="0.5" />
    <path d="M251.56 282.44V282.55" stroke="black" stroke-width="0.5" />
    <path d="M251.56 282.66V282.77" stroke="black" stroke-width="0.5" />
    <path d="M251.56 282.88V282.99" stroke="black" stroke-width="0.5" />
    <path d="M251.56 283.1V283.21" stroke="black" stroke-width="0.5" />
    <path d="M251.56 283.32V283.43" stroke="black" stroke-width="0.5" />
    <path d="M251.56 283.54V283.65" stroke="black" stroke-width="0.5" />
    <path d="M251.56 283.76V283.87" stroke="black" stroke-width="0.5" />
    <path d="M251.56 283.98V284.09" stroke="black" stroke-width="0.5" />
    <path d="M251.56 284.2V284.31" stroke="black" stroke-width="0.5" />
    <path d="M251.56 284.42V284.53" stroke="black" stroke-width="0.5" />
    <path d="M251.56 284.64V284.75" stroke="black" stroke-width="0.5" />
    <path d="M251.56 284.86V284.97" stroke="black" stroke-width="0.5" />
    <path d="M251.56 285.08V285.19" stroke="black" stroke-width="0.5" />
    <path d="M251.56 285.3V285.41" stroke="black" stroke-width="0.5" />
    <path d="M251.56 285.52V285.63" stroke="black" stroke-width="0.5" />
    <path d="M251.56 285.74V285.85" stroke="black" stroke-width="0.5" />
    <path d="M251.56 285.96V286.07" stroke="black" stroke-width="0.5" />
    <path d="M251.56 286.18V286.29" stroke="black" stroke-width="0.5" />
    <path d="M251.56 286.4V286.51" stroke="black" stroke-width="0.5" />
    <path d="M251.56 286.62V286.73" stroke="black" stroke-width="0.5" />
    <path d="M251.56 286.84V286.95" stroke="black" stroke-width="0.5" />
    <path d="M251.56 287.06V287.17" stroke="black" stroke-width="0.5" />
    <path d="M251.56 287.28V287.39" stroke="black" stroke-width="0.5" />
    <path d="M251.56 287.5V287.61" stroke="black" stroke-width="0.5" />
    <path d="M251.56 287.72V287.83" stroke="black" stroke-width="0.5" />
    <path d="M251.56 287.94V288.05" stroke="black" stroke-width="0.5" />
    <path d="M251.56 288.16V288.27" stroke="black" stroke-width="0.5" />
    <path d="M251.56 288.38V288.49" stroke="black" stroke-width="0.5" />
    <path d="M251.56 288.6V288.71" stroke="black" stroke-width="0.5" />
    <path d="M251.56 288.82V288.93" stroke="black" stroke-width="0.5" />
    <path d="M251.56 289.03V289.14" stroke="black" stroke-width="0.5" />
    <path d="M251.56 289.25V289.36" stroke="black" stroke-width="0.5" />
    <path d="M251.56 289.47V289.58" stroke="black" stroke-width="0.5" />
    <path d="M251.56 289.69V289.8" stroke="black" stroke-width="0.5" />
    <path d="M251.56 289.91V290.02" stroke="black" stroke-width="0.5" />
    <path d="M251.56 290.13V290.24" stroke="black" stroke-width="0.5" />
    <path d="M251.56 290.35V290.46" stroke="black" stroke-width="0.5" />
    <path d="M251.56 290.57V290.68" stroke="black" stroke-width="0.5" />
    <path d="M251.56 290.79V290.9" stroke="black" stroke-width="0.5" />
    <path d="M251.56 291.01V291.12" stroke="black" stroke-width="0.5" />
    <path d="M251.56 291.23V291.34" stroke="black" stroke-width="0.5" />
    <path d="M251.56 291.45V291.56" stroke="black" stroke-width="0.5" />
    <path d="M251.56 291.67V291.78" stroke="black" stroke-width="0.5" />
    <path d="M251.56 291.89V292" stroke="black" stroke-width="0.5" />
    <path d="M251.56 292.11V292.22" stroke="black" stroke-width="0.5" />
    <path d="M251.56 292.33V292.44" stroke="black" stroke-width="0.5" />
    <path d="M251.56 292.55V292.66" stroke="black" stroke-width="0.5" />
    <path d="M251.56 292.77V292.88" stroke="black" stroke-width="0.5" />
    <path d="M251.56 292.99V293.1" stroke="black" stroke-width="0.5" />
    <path d="M251.56 293.21V293.32" stroke="black" stroke-width="0.5" />
    <path d="M251.56 293.43V293.54" stroke="black" stroke-width="0.5" />
    <path d="M251.56 293.65V293.76" stroke="black" stroke-width="0.5" />
    <path d="M251.56 293.87V293.98" stroke="black" stroke-width="0.5" />
    <path d="M251.56 294.09V294.2" stroke="black" stroke-width="0.5" />
    <path d="M251.56 294.31V294.42" stroke="black" stroke-width="0.5" />
    <path d="M251.56 294.53V294.64" stroke="black" stroke-width="0.5" />
    <path d="M251.56 294.75V294.86" stroke="black" stroke-width="0.5" />
    <path d="M251.56 294.97V295.08" stroke="black" stroke-width="0.5" />
    <path d="M251.56 295.19V295.3" stroke="black" stroke-width="0.5" />
    <path d="M251.56 295.41V295.52" stroke="black" stroke-width="0.5" />
    <path d="M251.56 295.63V295.74" stroke="black" stroke-width="0.5" />
    <path d="M251.56 295.85V295.96" stroke="black" stroke-width="0.5" />
    <path d="M251.56 296.07V296.18" stroke="black" stroke-width="0.5" />
    <path d="M251.56 296.29V296.4" stroke="black" stroke-width="0.5" />
    <path d="M251.56 296.51V296.62" stroke="black" stroke-width="0.5" />
    <path d="M251.56 296.73V296.84" stroke="black" stroke-width="0.5" />
    <path d="M251.56 296.95V297.06" stroke="black" stroke-width="0.5" />
    <path d="M251.56 297.17V297.28" stroke="black" stroke-width="0.5" />
    <path d="M251.56 297.39V297.5" stroke="black" stroke-width="0.5" />
    <path d="M251.56 297.61V297.72" stroke="black" stroke-width="0.5" />
    <path d="M251.56 297.83V297.94" stroke="black" stroke-width="0.5" />
    <path d="M251.56 298.05V298.16" stroke="black" stroke-width="0.5" />
    <path d="M251.56 298.27V298.38" stroke="black" stroke-width="0.5" />
    <path d="M251.56 298.49V298.6" stroke="black" stroke-width="0.5" />
    <path d="M251.56 298.71V298.82" stroke="black" stroke-width="0.5" />
    <path d="M251.56 298.93V299.04" stroke="black" stroke-width="0.5" />
    <path d="M251.56 299.15V299.26" stroke="black" stroke-width="0.5" />
    <path d="M251.56 299.37V299.48" stroke="black" stroke-width="0.5" />
    <path d="M251.56 299.59V299.7" stroke="black" stroke-width="0.5" />
    <path d="M251.56 299.81V299.92" stroke="black" stroke-width="0.5" />
    <path d="M251.56 300.03V300.14" stroke="black" stroke-width="0.5" />
    <path d="M251.56 300.25V300.36" stroke="black" stroke-width="0.5" />
    <path d="M251.56 300.47V300.58" stroke="black" stroke-width="0.5" />
    <path d="M251.56 300.69V300.8" stroke="black" stroke-width="0.5" />
    <path d="M251.56 300.91V301.02" stroke="black" stroke-width="0.5" />
    <path d="M251.56 301.13V301.24" stroke="black" stroke-width="0.5" />
    <path d="M251.56 301.35V301.46" stroke="black" stroke-width="0.5" />
    <path d="M251.56 301.57V301.68" stroke="black" stroke-width="0.5" />
    <path d="M251.56 301.79V301.9" stroke="black" stroke-width="0.5" />
    <path d="M251.56 302.01V302.12" stroke="black" stroke-width="0.5" />
    <path d="M251.56 302.23V302.34" stroke="black" stroke-width="0.5" />
    <path d="M251.56 302.45V302.56" stroke="black" stroke-width="0.5" />
    <path d="M251.56 302.67V302.78" stroke="black" stroke-width="0.5" />
    <path d="M251.56 302.89V303" stroke="black" stroke-width="0.5" />
    <path d="M251.56 303.11V303.22" stroke="black" stroke-width="0.5" />
    <path d="M251.56 303.33V303.44" stroke="black" stroke-width="0.5" />
    <path d="M251.56 303.55V303.66" stroke="black" stroke-width="0.5" />
    <path d="M251.56 303.77V303.82" stroke="black" stroke-width="0.5" />
    <path d="M304.34 254.56H304.29" stroke="black" stroke-width="0.5" />
    <path d="M304.18 254.56H304.07" stroke="black" stroke-width="0.5" />
    <path d="M303.96 254.56H303.85" stroke="black" stroke-width="0.5" />
    <path d="M303.74 254.56H303.63" stroke="black" stroke-width="0.5" />
    <path d="M303.52 254.56H303.41" stroke="black" stroke-width="0.5" />
    <path d="M303.3 254.56H303.19" stroke="black" stroke-width="0.5" />
    <path d="M303.08 254.56H302.97" stroke="black" stroke-width="0.5" />
    <path d="M302.86 254.56H302.75" stroke="black" stroke-width="0.5" />
    <path d="M302.64 254.56H302.53" stroke="black" stroke-width="0.5" />
    <path d="M302.42 254.56H302.31" stroke="black" stroke-width="0.5" />
    <path d="M302.2 254.56H302.09" stroke="black" stroke-width="0.5" />
    <path d="M301.98 254.56H301.87" stroke="black" stroke-width="0.5" />
    <path d="M301.76 254.56H301.65" stroke="black" stroke-width="0.5" />
    <path d="M301.54 254.56H301.43" stroke="black" stroke-width="0.5" />
    <path d="M301.32 254.56H301.21" stroke="black" stroke-width="0.5" />
    <path d="M301.1 254.56H300.99" stroke="black" stroke-width="0.5" />
    <path d="M300.88 254.56H300.77" stroke="black" stroke-width="0.5" />
    <path d="M300.66 254.56H300.55" stroke="black" stroke-width="0.5" />
    <path d="M300.44 254.56H300.33" stroke="black" stroke-width="0.5" />
    <path d="M300.22 254.56H300.11" stroke="black" stroke-width="0.5" />
    <path d="M300 254.56H299.89" stroke="black" stroke-width="0.5" />
    <path d="M299.78 254.56H299.67" stroke="black" stroke-width="0.5" />
    <path d="M299.56 254.56H299.45" stroke="black" stroke-width="0.5" />
    <path d="M299.34 254.56H299.23" stroke="black" stroke-width="0.5" />
    <path d="M299.12 254.56H299.01" stroke="black" stroke-width="0.5" />
    <path d="M298.9 254.56H298.79" stroke="black" stroke-width="0.5" />
    <path d="M298.68 254.56H298.57" stroke="black" stroke-width="0.5" />
    <path d="M298.46 254.56H298.35" stroke="black" stroke-width="0.5" />
    <path d="M298.24 254.56H298.13" stroke="black" stroke-width="0.5" />
    <path d="M298.02 254.56H297.91" stroke="black" stroke-width="0.5" />
    <path d="M297.8 254.56H297.69" stroke="black" stroke-width="0.5" />
    <path d="M297.58 254.56H297.47" stroke="black" stroke-width="0.5" />
    <path d="M297.36 254.56H297.25" stroke="black" stroke-width="0.5" />
    <path d="M297.14 254.56H297.03" stroke="black" stroke-width="0.5" />
    <path d="M296.92 254.56H296.81" stroke="black" stroke-width="0.5" />
    <path d="M296.7 254.56H296.59" stroke="black" stroke-width="0.5" />
    <path d="M296.48 254.56H296.37" stroke="black" stroke-width="0.5" />
    <path d="M296.26 254.56H296.15" stroke="black" stroke-width="0.5" />
    <path d="M296.04 254.56H295.93" stroke="black" stroke-width="0.5" />
    <path d="M295.82 254.56H295.71" stroke="black" stroke-width="0.5" />
    <path d="M295.6 254.56H295.49" stroke="black" stroke-width="0.5" />
    <path d="M295.38 254.56H295.27" stroke="black" stroke-width="0.5" />
    <path d="M295.16 254.56H295.05" stroke="black" stroke-width="0.5" />
    <path d="M294.94 254.56H294.83" stroke="black" stroke-width="0.5" />
    <path d="M294.72 254.56H294.61" stroke="black" stroke-width="0.5" />
    <path d="M294.5 254.56H294.39" stroke="black" stroke-width="0.5" />
    <path d="M294.28 254.56H294.17" stroke="black" stroke-width="0.5" />
    <path d="M294.06 254.56H293.95" stroke="black" stroke-width="0.5" />
    <path d="M293.84 254.56H293.73" stroke="black" stroke-width="0.5" />
    <path d="M293.62 254.56H293.51" stroke="black" stroke-width="0.5" />
    <path d="M293.4 254.56H293.29" stroke="black" stroke-width="0.5" />
    <path d="M293.18 254.56H293.07" stroke="black" stroke-width="0.5" />
    <path d="M292.96 254.56H292.85" stroke="black" stroke-width="0.5" />
    <path d="M292.74 254.56H292.63" stroke="black" stroke-width="0.5" />
    <path d="M292.52 254.56H292.41" stroke="black" stroke-width="0.5" />
    <path d="M292.3 254.56H292.19" stroke="black" stroke-width="0.5" />
    <path d="M292.08 254.56H291.97" stroke="black" stroke-width="0.5" />
    <path d="M291.86 254.56H291.75" stroke="black" stroke-width="0.5" />
    <path d="M291.64 254.56H291.53" stroke="black" stroke-width="0.5" />
    <path d="M291.42 254.56H291.31" stroke="black" stroke-width="0.5" />
    <path d="M291.2 254.56H291.09" stroke="black" stroke-width="0.5" />
    <path d="M290.98 254.56H290.87" stroke="black" stroke-width="0.5" />
    <path d="M290.76 254.56H290.65" stroke="black" stroke-width="0.5" />
    <path d="M290.54 254.56H290.43" stroke="black" stroke-width="0.5" />
    <path d="M290.32 254.56H290.21" stroke="black" stroke-width="0.5" />
    <path d="M290.1 254.56H289.99" stroke="black" stroke-width="0.5" />
    <path d="M289.88 254.56H289.77" stroke="black" stroke-width="0.5" />
    <path d="M289.66 254.56H289.55" stroke="black" stroke-width="0.5" />
    <path d="M289.44 254.56H289.33" stroke="black" stroke-width="0.5" />
    <path d="M289.22 254.56H289.11" stroke="black" stroke-width="0.5" />
    <path d="M289 254.56H288.89" stroke="black" stroke-width="0.5" />
    <path d="M288.78 254.56H288.67" stroke="black" stroke-width="0.5" />
    <path d="M288.56 254.56H288.45" stroke="black" stroke-width="0.5" />
    <path d="M288.34 254.56H288.23" stroke="black" stroke-width="0.5" />
    <path d="M288.12 254.56H288.01" stroke="black" stroke-width="0.5" />
    <path d="M287.9 254.56H287.79" stroke="black" stroke-width="0.5" />
    <path d="M287.68 254.56H287.57" stroke="black" stroke-width="0.5" />
    <path d="M287.46 254.56H287.35" stroke="black" stroke-width="0.5" />
    <path d="M287.24 254.56H287.13" stroke="black" stroke-width="0.5" />
    <path d="M287.02 254.56H286.91" stroke="black" stroke-width="0.5" />
    <path d="M286.8 254.56H286.69" stroke="black" stroke-width="0.5" />
    <path d="M286.58 254.56H286.47" stroke="black" stroke-width="0.5" />
    <path d="M286.36 254.56H286.25" stroke="black" stroke-width="0.5" />
    <path d="M286.14 254.56H286.03" stroke="black" stroke-width="0.5" />
    <path d="M285.92 254.56H285.81" stroke="black" stroke-width="0.5" />
    <path d="M285.7 254.56H285.59" stroke="black" stroke-width="0.5" />
    <path d="M285.48 254.56H285.37" stroke="black" stroke-width="0.5" />
    <path d="M285.26 254.56H285.15" stroke="black" stroke-width="0.5" />
    <path d="M285.04 254.56H284.93" stroke="black" stroke-width="0.5" />
    <path d="M284.82 254.56H284.71" stroke="black" stroke-width="0.5" />
    <path d="M284.6 254.56H284.49" stroke="black" stroke-width="0.5" />
    <path d="M284.38 254.56H284.27" stroke="black" stroke-width="0.5" />
    <path d="M284.16 254.56H284.05" stroke="black" stroke-width="0.5" />
    <path d="M283.94 254.56H283.83" stroke="black" stroke-width="0.5" />
    <path d="M283.72 254.56H283.61" stroke="black" stroke-width="0.5" />
    <path d="M283.5 254.56H283.39" stroke="black" stroke-width="0.5" />
    <path d="M283.28 254.56H283.17" stroke="black" stroke-width="0.5" />
    <path d="M283.06 254.56H282.95" stroke="black" stroke-width="0.5" />
    <path d="M282.84 254.56H282.73" stroke="black" stroke-width="0.5" />
    <path d="M282.62 254.56H282.51" stroke="black" stroke-width="0.5" />
    <path d="M282.4 254.56H282.29" stroke="black" stroke-width="0.5" />
    <path d="M282.18 254.56H282.07" stroke="black" stroke-width="0.5" />
    <path d="M281.96 254.56H281.86" stroke="black" stroke-width="0.5" />
    <path d="M281.75 254.56H281.64" stroke="black" stroke-width="0.5" />
    <path d="M281.53 254.56H281.42" stroke="black" stroke-width="0.5" />
    <path d="M281.31 254.56H281.2" stroke="black" stroke-width="0.5" />
    <path d="M281.09 254.56H280.98" stroke="black" stroke-width="0.5" />
    <path d="M280.87 254.56H280.76" stroke="black" stroke-width="0.5" />
    <path d="M280.65 254.56H280.54" stroke="black" stroke-width="0.5" />
    <path d="M280.43 254.56H280.32" stroke="black" stroke-width="0.5" />
    <path d="M280.21 254.56H280.1" stroke="black" stroke-width="0.5" />
    <path d="M279.99 254.56H279.88" stroke="black" stroke-width="0.5" />
    <path d="M279.77 254.56H279.66" stroke="black" stroke-width="0.5" />
    <path d="M279.55 254.56H279.44" stroke="black" stroke-width="0.5" />
    <path d="M279.33 254.56H279.22" stroke="black" stroke-width="0.5" />
    <path d="M279.11 254.56H279" stroke="black" stroke-width="0.5" />
    <path d="M278.89 254.56H278.78" stroke="black" stroke-width="0.5" />
    <path d="M278.67 254.56H278.56" stroke="black" stroke-width="0.5" />
    <path d="M278.45 254.56H278.34" stroke="black" stroke-width="0.5" />
    <path d="M278.23 254.56H278.12" stroke="black" stroke-width="0.5" />
    <path d="M278.01 254.56H277.9" stroke="black" stroke-width="0.5" />
    <path d="M277.79 254.56H277.68" stroke="black" stroke-width="0.5" />
    <path d="M277.57 254.56H277.46" stroke="black" stroke-width="0.5" />
    <path d="M277.35 254.56H277.24" stroke="black" stroke-width="0.5" />
    <path d="M277.13 254.56H277.02" stroke="black" stroke-width="0.5" />
    <path d="M276.91 254.56H276.8" stroke="black" stroke-width="0.5" />
    <path d="M276.69 254.56H276.58" stroke="black" stroke-width="0.5" />
    <path d="M276.47 254.56H276.36" stroke="black" stroke-width="0.5" />
    <path d="M276.25 254.56H276.14" stroke="black" stroke-width="0.5" />
    <path d="M276.03 254.56H275.92" stroke="black" stroke-width="0.5" />
    <path d="M275.81 254.56H275.7" stroke="black" stroke-width="0.5" />
    <path d="M275.59 254.56H275.48" stroke="black" stroke-width="0.5" />
    <path d="M275.37 254.56H275.26" stroke="black" stroke-width="0.5" />
    <path d="M275.15 254.56H275.04" stroke="black" stroke-width="0.5" />
    <path d="M274.93 254.56H274.82" stroke="black" stroke-width="0.5" />
    <path d="M274.71 254.56H274.6" stroke="black" stroke-width="0.5" />
    <path d="M274.49 254.56H274.38" stroke="black" stroke-width="0.5" />
    <path d="M274.27 254.56H274.16" stroke="black" stroke-width="0.5" />
    <path d="M274.05 254.56H273.94" stroke="black" stroke-width="0.5" />
    <path d="M273.83 254.56H273.72" stroke="black" stroke-width="0.5" />
    <path d="M273.61 254.56H273.5" stroke="black" stroke-width="0.5" />
    <path d="M273.39 254.56H273.28" stroke="black" stroke-width="0.5" />
    <path d="M273.17 254.56H273.06" stroke="black" stroke-width="0.5" />
    <path d="M272.95 254.56H272.84" stroke="black" stroke-width="0.5" />
    <path d="M272.73 254.56H272.62" stroke="black" stroke-width="0.5" />
    <path d="M272.51 254.56H272.4" stroke="black" stroke-width="0.5" />
    <path d="M272.29 254.56H272.18" stroke="black" stroke-width="0.5" />
    <path d="M272.07 254.56H271.96" stroke="black" stroke-width="0.5" />
    <path d="M271.85 254.56H271.74" stroke="black" stroke-width="0.5" />
    <path d="M271.63 254.56H271.52" stroke="black" stroke-width="0.5" />
    <path d="M271.41 254.56H271.3" stroke="black" stroke-width="0.5" />
    <path d="M271.19 254.56H271.08" stroke="black" stroke-width="0.5" />
    <path d="M270.97 254.56H270.86" stroke="black" stroke-width="0.5" />
    <path d="M270.75 254.56H270.64" stroke="black" stroke-width="0.5" />
    <path d="M270.53 254.56H270.42" stroke="black" stroke-width="0.5" />
    <path d="M270.31 254.56H270.2" stroke="black" stroke-width="0.5" />
    <path d="M270.09 254.56H269.98" stroke="black" stroke-width="0.5" />
    <path d="M269.87 254.56H269.76" stroke="black" stroke-width="0.5" />
    <path d="M269.65 254.56H269.54" stroke="black" stroke-width="0.5" />
    <path d="M269.43 254.56H269.32" stroke="black" stroke-width="0.5" />
    <path d="M269.21 254.56H269.1" stroke="black" stroke-width="0.5" />
    <path d="M268.99 254.56H268.88" stroke="black" stroke-width="0.5" />
    <path d="M268.77 254.56H268.66" stroke="black" stroke-width="0.5" />
    <path d="M268.55 254.56H268.44" stroke="black" stroke-width="0.5" />
    <path d="M268.33 254.56H268.22" stroke="black" stroke-width="0.5" />
    <path d="M268.11 254.56H268" stroke="black" stroke-width="0.5" />
    <path d="M267.89 254.56H267.78" stroke="black" stroke-width="0.5" />
    <path d="M267.67 254.56H267.56" stroke="black" stroke-width="0.5" />
    <path d="M267.45 254.56H267.34" stroke="black" stroke-width="0.5" />
    <path d="M267.23 254.56H267.12" stroke="black" stroke-width="0.5" />
    <path d="M267.01 254.56H266.9" stroke="black" stroke-width="0.5" />
    <path d="M266.79 254.56H266.68" stroke="black" stroke-width="0.5" />
    <path d="M266.57 254.56H266.46" stroke="black" stroke-width="0.5" />
    <path d="M266.35 254.56H266.24" stroke="black" stroke-width="0.5" />
    <path d="M266.13 254.56H266.02" stroke="black" stroke-width="0.5" />
    <path d="M265.91 254.56H265.8" stroke="black" stroke-width="0.5" />
    <path d="M265.69 254.56H265.58" stroke="black" stroke-width="0.5" />
    <path d="M265.47 254.56H265.36" stroke="black" stroke-width="0.5" />
    <path d="M265.25 254.56H265.14" stroke="black" stroke-width="0.5" />
    <path d="M265.03 254.56H264.92" stroke="black" stroke-width="0.5" />
    <path d="M264.81 254.56H264.7" stroke="black" stroke-width="0.5" />
    <path d="M264.59 254.56H264.48" stroke="black" stroke-width="0.5" />
    <path d="M264.37 254.56H264.26" stroke="black" stroke-width="0.5" />
    <path d="M264.15 254.56H264.04" stroke="black" stroke-width="0.5" />
    <path d="M263.93 254.56H263.82" stroke="black" stroke-width="0.5" />
    <path d="M263.71 254.56H263.6" stroke="black" stroke-width="0.5" />
    <path d="M263.49 254.56H263.38" stroke="black" stroke-width="0.5" />
    <path d="M263.27 254.56H263.16" stroke="black" stroke-width="0.5" />
    <path d="M263.05 254.56H262.94" stroke="black" stroke-width="0.5" />
    <path d="M262.83 254.56H262.72" stroke="black" stroke-width="0.5" />
    <path d="M262.61 254.56H262.5" stroke="black" stroke-width="0.5" />
    <path d="M262.39 254.56H262.28" stroke="black" stroke-width="0.5" />
    <path d="M262.17 254.56H262.06" stroke="black" stroke-width="0.5" />
    <path d="M261.95 254.56H261.84" stroke="black" stroke-width="0.5" />
    <path d="M261.73 254.56H261.62" stroke="black" stroke-width="0.5" />
    <path d="M261.51 254.56H261.4" stroke="black" stroke-width="0.5" />
    <path d="M261.29 254.56H261.18" stroke="black" stroke-width="0.5" />
    <path d="M261.07 254.56H260.96" stroke="black" stroke-width="0.5" />
    <path d="M260.85 254.56H260.74" stroke="black" stroke-width="0.5" />
    <path d="M260.63 254.56H260.52" stroke="black" stroke-width="0.5" />
    <path d="M260.41 254.56H260.3" stroke="black" stroke-width="0.5" />
    <path d="M260.19 254.56H260.08" stroke="black" stroke-width="0.5" />
    <path d="M259.97 254.56H259.86" stroke="black" stroke-width="0.5" />
    <path d="M259.75 254.56H259.64" stroke="black" stroke-width="0.5" />
    <path d="M259.53 254.56H259.42" stroke="black" stroke-width="0.5" />
    <path d="M259.31 254.56H259.2" stroke="black" stroke-width="0.5" />
    <path d="M259.09 254.56H258.98" stroke="black" stroke-width="0.5" />
    <path d="M258.87 254.56H258.76" stroke="black" stroke-width="0.5" />
    <path d="M258.65 254.56H258.54" stroke="black" stroke-width="0.5" />
    <path d="M258.43 254.56H258.32" stroke="black" stroke-width="0.5" />
    <path d="M258.21 254.56H258.1" stroke="black" stroke-width="0.5" />
    <path d="M257.99 254.56H257.88" stroke="black" stroke-width="0.5" />
    <path d="M257.77 254.56H257.66" stroke="black" stroke-width="0.5" />
    <path d="M257.55 254.56H257.44" stroke="black" stroke-width="0.5" />
    <path d="M257.33 254.56H257.22" stroke="black" stroke-width="0.5" />
    <path d="M257.11 254.56H257" stroke="black" stroke-width="0.5" />
    <path d="M256.89 254.56H256.78" stroke="black" stroke-width="0.5" />
    <path d="M256.67 254.56H256.56" stroke="black" stroke-width="0.5" />
    <path d="M256.45 254.56H256.34" stroke="black" stroke-width="0.5" />
    <path d="M256.23 254.56H256.12" stroke="black" stroke-width="0.5" />
    <path d="M256.02 254.56H255.91" stroke="black" stroke-width="0.5" />
    <path d="M255.8 254.56H255.69" stroke="black" stroke-width="0.5" />
    <path d="M255.58 254.56H255.47" stroke="black" stroke-width="0.5" />
    <path d="M255.36 254.56H255.25" stroke="black" stroke-width="0.5" />
    <path d="M255.14 254.56H255.03" stroke="black" stroke-width="0.5" />
    <path d="M254.92 254.56H254.81" stroke="black" stroke-width="0.5" />
    <path d="M254.7 254.56H254.59" stroke="black" stroke-width="0.5" />
    <path d="M254.48 254.56H254.37" stroke="black" stroke-width="0.5" />
    <path d="M254.26 254.56H254.15" stroke="black" stroke-width="0.5" />
    <path d="M254.04 254.56H253.93" stroke="black" stroke-width="0.5" />
    <path d="M253.82 254.56H253.71" stroke="black" stroke-width="0.5" />
    <path d="M253.6 254.56H253.49" stroke="black" stroke-width="0.5" />
    <path d="M253.38 254.56H253.27" stroke="black" stroke-width="0.5" />
    <path d="M253.16 254.56H253.05" stroke="black" stroke-width="0.5" />
    <path d="M252.94 254.56H252.83" stroke="black" stroke-width="0.5" />
    <path d="M252.72 254.56H252.61" stroke="black" stroke-width="0.5" />
    <path d="M252.5 254.56H252.39" stroke="black" stroke-width="0.5" />
    <path d="M252.28 254.56H252.17" stroke="black" stroke-width="0.5" />
    <path d="M252.06 254.56H251.95" stroke="black" stroke-width="0.5" />
    <path d="M251.84 254.56H251.73" stroke="black" stroke-width="0.5" />
    <path d="M251.62 254.56H251.56" stroke="black" stroke-width="0.5" />
    <path d="M286.75 298.53H290.27" stroke="black" stroke-width="0.5" />
    <path
      d="M289.86 280.2C288.965 280.024 288.045 280.024 287.15 280.2"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M292.33 292.98C293.563 291.271 294.228 289.217 294.23 287.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M282.79 287.11C282.789 289.216 283.45 291.27 284.68 292.98"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M284.68 292.98C285.752 293.879 287.106 294.372 288.505 294.372C289.904 294.372 291.258 293.879 292.33 292.98"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M286.52 281.75C286.802 281.545 287.009 281.253 287.11 280.919C287.211 280.585 287.2 280.227 287.079 279.9C286.958 279.573 286.733 279.294 286.439 279.107C286.145 278.919 285.798 278.832 285.45 278.86"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M291.56 278.86C291.212 278.832 290.865 278.919 290.571 279.107C290.277 279.294 290.052 279.573 289.931 279.9C289.81 280.227 289.799 280.585 289.9 280.919C290.001 281.253 290.208 281.545 290.49 281.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M294.23 287.11C294.229 285.941 293.87 284.801 293.201 283.843C292.533 282.885 291.586 282.154 290.49 281.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M286.52 281.75C285.425 282.156 284.481 282.887 283.815 283.845C283.148 284.803 282.79 285.943 282.79 287.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 278.31C287.354 278.31 286.21 278.537 285.142 278.979C284.074 279.421 283.104 280.069 282.287 280.887C281.469 281.704 280.821 282.674 280.379 283.742C279.937 284.81 279.71 285.954 279.71 287.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M279.71 287.11C279.71 289.642 280.331 292.135 281.52 294.37"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M281.52 294.37C282.279 295.797 283.357 297.029 284.67 297.97"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M284.67 297.97C285.08 298.261 285.553 298.453 286.05 298.53"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M286.05 298.53H286.75" stroke="black" stroke-width="0.5" />
    <path d="M286.75 298.53V301.08" stroke="black" stroke-width="0.5" />
    <path
      d="M287.06 302.18C287.205 302.396 287.396 302.577 287.62 302.71"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.39 302.71C289.617 302.578 289.812 302.397 289.96 302.18"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M290.27 301.08V298.53" stroke="black" stroke-width="0.5" />
    <path d="M290.27 298.53H290.97" stroke="black" stroke-width="0.5" />
    <path
      d="M290.97 298.53C291.464 298.452 291.933 298.26 292.34 297.97"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M292.34 297.97C293.657 297.03 294.739 295.798 295.5 294.37"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M295.5 294.37C296.685 292.134 297.304 289.641 297.3 287.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M297.3 287.11C297.301 284.778 296.375 282.54 294.727 280.89C293.079 279.24 290.842 278.312 288.51 278.31"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M289.38 301.08V301.05" stroke="black" stroke-width="0.5" />
    <path d="M289.38 301.05L289.37 301.02" stroke="black" stroke-width="0.5" />
    <path d="M289.37 301.02L289.36 300.99" stroke="black" stroke-width="0.5" />
    <path d="M289.36 300.99V300.95" stroke="black" stroke-width="0.5" />
    <path d="M289.36 300.95L289.35 300.92" stroke="black" stroke-width="0.5" />
    <path d="M289.35 300.92L289.33 300.88" stroke="black" stroke-width="0.5" />
    <path d="M289.33 300.88L289.32 300.85" stroke="black" stroke-width="0.5" />
    <path d="M289.32 300.85L289.31 300.82" stroke="black" stroke-width="0.5" />
    <path d="M289.31 300.82L289.29 300.79" stroke="black" stroke-width="0.5" />
    <path d="M289.29 300.79L289.28 300.76" stroke="black" stroke-width="0.5" />
    <path d="M289.28 300.76L289.26 300.73" stroke="black" stroke-width="0.5" />
    <path d="M289.26 300.73L289.24 300.7" stroke="black" stroke-width="0.5" />
    <path d="M289.24 300.7L289.22 300.67" stroke="black" stroke-width="0.5" />
    <path d="M289.22 300.67L289.2 300.64" stroke="black" stroke-width="0.5" />
    <path d="M289.2 300.64L289.18 300.61" stroke="black" stroke-width="0.5" />
    <path d="M289.18 300.61L289.15 300.59" stroke="black" stroke-width="0.5" />
    <path d="M289.15 300.59L289.13 300.56" stroke="black" stroke-width="0.5" />
    <path d="M289.13 300.56L289.1 300.54" stroke="black" stroke-width="0.5" />
    <path d="M289.1 300.54L289.08 300.51" stroke="black" stroke-width="0.5" />
    <path d="M289.08 300.51L289.05 300.49" stroke="black" stroke-width="0.5" />
    <path d="M289.05 300.49L289.02 300.47" stroke="black" stroke-width="0.5" />
    <path d="M289.02 300.47L288.99 300.45" stroke="black" stroke-width="0.5" />
    <path d="M288.99 300.45L288.96 300.43" stroke="black" stroke-width="0.5" />
    <path d="M288.96 300.43L288.93 300.41" stroke="black" stroke-width="0.5" />
    <path d="M288.93 300.41L288.9 300.4" stroke="black" stroke-width="0.5" />
    <path d="M288.9 300.4L288.87 300.38" stroke="black" stroke-width="0.5" />
    <path d="M288.87 300.38L288.84 300.37" stroke="black" stroke-width="0.5" />
    <path d="M288.84 300.37L288.8 300.36" stroke="black" stroke-width="0.5" />
    <path d="M288.8 300.36L288.77 300.35" stroke="black" stroke-width="0.5" />
    <path d="M288.77 300.35L288.73 300.34" stroke="black" stroke-width="0.5" />
    <path d="M288.73 300.34L288.7 300.33" stroke="black" stroke-width="0.5" />
    <path d="M288.7 300.33L288.67 300.32" stroke="black" stroke-width="0.5" />
    <path d="M288.67 300.32L288.63 300.31" stroke="black" stroke-width="0.5" />
    <path d="M288.63 300.31H288.6" stroke="black" stroke-width="0.5" />
    <path d="M288.6 300.31H288.56" stroke="black" stroke-width="0.5" />
    <path d="M288.56 300.31H288.53" stroke="black" stroke-width="0.5" />
    <path d="M288.53 300.31H288.51" stroke="black" stroke-width="0.5" />
    <path d="M288.51 300.31H288.47" stroke="black" stroke-width="0.5" />
    <path d="M288.47 300.31H288.44" stroke="black" stroke-width="0.5" />
    <path d="M288.44 300.31H288.4" stroke="black" stroke-width="0.5" />
    <path d="M288.4 300.31L288.37 300.32" stroke="black" stroke-width="0.5" />
    <path d="M288.37 300.32H288.33" stroke="black" stroke-width="0.5" />
    <path d="M288.33 300.32L288.3 300.33" stroke="black" stroke-width="0.5" />
    <path d="M288.3 300.33L288.26 300.34" stroke="black" stroke-width="0.5" />
    <path d="M288.26 300.34L288.23 300.35" stroke="black" stroke-width="0.5" />
    <path d="M288.23 300.35L288.2 300.36" stroke="black" stroke-width="0.5" />
    <path d="M288.2 300.36L288.16 300.38" stroke="black" stroke-width="0.5" />
    <path d="M288.16 300.38L288.13 300.39" stroke="black" stroke-width="0.5" />
    <path d="M288.13 300.39L288.1 300.41" stroke="black" stroke-width="0.5" />
    <path d="M288.1 300.41L288.07 300.42" stroke="black" stroke-width="0.5" />
    <path d="M288.07 300.42L288.04 300.44" stroke="black" stroke-width="0.5" />
    <path d="M288.04 300.44L288.01 300.46" stroke="black" stroke-width="0.5" />
    <path d="M288.01 300.46L287.98 300.48" stroke="black" stroke-width="0.5" />
    <path d="M287.98 300.48L287.95 300.5" stroke="black" stroke-width="0.5" />
    <path d="M287.95 300.5L287.93 300.52" stroke="black" stroke-width="0.5" />
    <path d="M287.93 300.52L287.9 300.55" stroke="black" stroke-width="0.5" />
    <path d="M287.9 300.55L287.88 300.57" stroke="black" stroke-width="0.5" />
    <path d="M287.88 300.57L287.85 300.6" stroke="black" stroke-width="0.5" />
    <path d="M287.85 300.6L287.83 300.62" stroke="black" stroke-width="0.5" />
    <path d="M287.83 300.62L287.81 300.65" stroke="black" stroke-width="0.5" />
    <path d="M287.81 300.65L287.79 300.68" stroke="black" stroke-width="0.5" />
    <path d="M287.79 300.68L287.77 300.71" stroke="black" stroke-width="0.5" />
    <path d="M287.77 300.71L287.75 300.74" stroke="black" stroke-width="0.5" />
    <path d="M287.75 300.74L287.73 300.77" stroke="black" stroke-width="0.5" />
    <path d="M287.73 300.77L287.72 300.8" stroke="black" stroke-width="0.5" />
    <path d="M287.72 300.8L287.7 300.83" stroke="black" stroke-width="0.5" />
    <path d="M287.7 300.83L287.69 300.87" stroke="black" stroke-width="0.5" />
    <path d="M287.69 300.87L287.68 300.9" stroke="black" stroke-width="0.5" />
    <path d="M287.68 300.9L287.66 300.93" stroke="black" stroke-width="0.5" />
    <path d="M287.66 300.93V300.97" stroke="black" stroke-width="0.5" />
    <path d="M287.66 300.97L287.65 301" stroke="black" stroke-width="0.5" />
    <path d="M287.65 301L287.64 301.04" stroke="black" stroke-width="0.5" />
    <path d="M287.64 301.04V301.07" stroke="black" stroke-width="0.5" />
    <path
      d="M289.34 300.91C289.311 300.817 289.267 300.729 289.21 300.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.11 300.4C288.068 300.42 288.028 300.444 287.99 300.47"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.34 300.91C289.311 300.817 289.267 300.729 289.21 300.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.11 300.4C288.068 300.42 288.028 300.444 287.99 300.47"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 302.5C288.816 302.501 289.113 302.395 289.35 302.2"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.82 301.07C289.79 300.743 289.64 300.438 289.397 300.216C289.155 299.994 288.839 299.871 288.51 299.87"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 299.87C288.18 299.868 287.861 299.991 287.616 300.213C287.372 300.435 287.22 300.741 287.19 301.07"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M287.67 302.2C287.907 302.395 288.204 302.501 288.51 302.5"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 302.5C288.816 302.501 289.113 302.395 289.35 302.2"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.82 301.07C289.79 300.743 289.64 300.438 289.397 300.216C289.155 299.994 288.839 299.871 288.51 299.87"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 299.87C288.18 299.868 287.861 299.991 287.616 300.213C287.372 300.435 287.22 300.741 287.19 301.07"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M287.67 302.2C287.907 302.395 288.204 302.501 288.51 302.5"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.34 300.91C289.311 300.817 289.267 300.729 289.21 300.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.11 300.4C288.068 300.42 288.028 300.444 287.99 300.47"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M287.63 302.17V303.82" stroke="black" stroke-width="0.5" />
    <path d="M289.39 303.82V302.17" stroke="black" stroke-width="0.5" />
    <path d="M287.63 303.82H289.39" stroke="black" stroke-width="0.5" />
    <path d="M287.63 303.82H289.39" stroke="black" stroke-width="0.5" />
    <path d="M303.68 294.15H304.34" stroke="black" stroke-width="0.5" />
    <path d="M304.34 292.39H303.68" stroke="black" stroke-width="0.5" />
    <path d="M303.68 257.2H304.34" stroke="black" stroke-width="0.5" />
    <path d="M304.34 255.44H303.68" stroke="black" stroke-width="0.5" />
    <path d="M304.34 294.15V292.39" stroke="black" stroke-width="0.5" />
    <path d="M304.34 294.15V292.39" stroke="black" stroke-width="0.5" />
    <path d="M304.34 257.2V255.44" stroke="black" stroke-width="0.5" />
    <path d="M304.34 257.2V255.44" stroke="black" stroke-width="0.5" />
    <path d="M303.68 292.39V294.15" stroke="black" stroke-width="0.5" />
    <path d="M303.68 292.39V293.81" stroke="black" stroke-width="0.5" />
    <path d="M303.68 293.82V294.15" stroke="black" stroke-width="0.5" />
    <path d="M303.68 255.44V257.2" stroke="black" stroke-width="0.5" />
    <path d="M303.68 255.44V257.2" stroke="black" stroke-width="0.5" />
    <path d="M298.18 303.16V303.82" stroke="black" stroke-width="0.5" />
    <path d="M299.94 303.82V303.16" stroke="black" stroke-width="0.5" />
    <path d="M266.52 303.16V303.82" stroke="black" stroke-width="0.5" />
    <path d="M268.28 303.82V303.16" stroke="black" stroke-width="0.5" />
    <path d="M298.18 303.82H299.94" stroke="black" stroke-width="0.5" />
    <path d="M298.18 303.82H299.94" stroke="black" stroke-width="0.5" />
    <path d="M266.52 303.82H268.28" stroke="black" stroke-width="0.5" />
    <path d="M266.52 303.82H268.28" stroke="black" stroke-width="0.5" />
    <path d="M299.94 303.16H298.18" stroke="black" stroke-width="0.5" />
    <path d="M299.94 303.16H298.18" stroke="black" stroke-width="0.5" />
    <path d="M268.28 303.16H266.52" stroke="black" stroke-width="0.5" />
    <path d="M268.28 303.16H266.52" stroke="black" stroke-width="0.5" />
    <path d="M303.02 293.82H303.68" stroke="black" stroke-width="0.5" />
    <path d="M303.68 292.72H303.02" stroke="black" stroke-width="0.5" />
    <path d="M301.59 292.39V292.45" stroke="black" stroke-width="0.5" />
    <path d="M301.59 292.45L301.6 292.5" stroke="black" stroke-width="0.5" />
    <path d="M301.6 292.5V292.56" stroke="black" stroke-width="0.5" />
    <path d="M301.6 292.56L301.61 292.62" stroke="black" stroke-width="0.5" />
    <path d="M301.61 292.62L301.62 292.67" stroke="black" stroke-width="0.5" />
    <path d="M301.62 292.67L301.63 292.73" stroke="black" stroke-width="0.5" />
    <path d="M301.63 292.73L301.65 292.78" stroke="black" stroke-width="0.5" />
    <path d="M301.65 292.78L301.66 292.84" stroke="black" stroke-width="0.5" />
    <path d="M301.66 292.84L301.68 292.89" stroke="black" stroke-width="0.5" />
    <path d="M301.68 292.89L301.71 292.95" stroke="black" stroke-width="0.5" />
    <path d="M301.71 292.95L301.73 293" stroke="black" stroke-width="0.5" />
    <path d="M301.73 293L301.75 293.05" stroke="black" stroke-width="0.5" />
    <path d="M301.75 293.05L301.78 293.1" stroke="black" stroke-width="0.5" />
    <path d="M301.78 293.1L301.81 293.15" stroke="black" stroke-width="0.5" />
    <path d="M301.81 293.15L301.84 293.2" stroke="black" stroke-width="0.5" />
    <path d="M301.84 293.2L301.88 293.24" stroke="black" stroke-width="0.5" />
    <path d="M301.88 293.24L301.91 293.29" stroke="black" stroke-width="0.5" />
    <path d="M301.91 293.29L301.95 293.33" stroke="black" stroke-width="0.5" />
    <path d="M301.95 293.33L301.99 293.37" stroke="black" stroke-width="0.5" />
    <path d="M301.99 293.37L302.03 293.41" stroke="black" stroke-width="0.5" />
    <path d="M302.03 293.41L302.07 293.45" stroke="black" stroke-width="0.5" />
    <path d="M302.07 293.45L302.11 293.49" stroke="black" stroke-width="0.5" />
    <path d="M302.11 293.49L302.16 293.53" stroke="black" stroke-width="0.5" />
    <path d="M302.16 293.53L302.2 293.56" stroke="black" stroke-width="0.5" />
    <path d="M302.2 293.56L302.25 293.59" stroke="black" stroke-width="0.5" />
    <path d="M302.25 293.59L302.3 293.62" stroke="black" stroke-width="0.5" />
    <path d="M302.3 293.62L302.35 293.65" stroke="black" stroke-width="0.5" />
    <path d="M302.35 293.65L302.4 293.68" stroke="black" stroke-width="0.5" />
    <path d="M302.4 293.68L302.45 293.7" stroke="black" stroke-width="0.5" />
    <path d="M302.45 293.7L302.5 293.72" stroke="black" stroke-width="0.5" />
    <path d="M302.5 293.72L302.56 293.74" stroke="black" stroke-width="0.5" />
    <path d="M302.56 293.74L302.61 293.76" stroke="black" stroke-width="0.5" />
    <path d="M302.61 293.76L302.67 293.77" stroke="black" stroke-width="0.5" />
    <path d="M302.67 293.77L302.72 293.79" stroke="black" stroke-width="0.5" />
    <path d="M302.72 293.79L302.78 293.8" stroke="black" stroke-width="0.5" />
    <path d="M302.78 293.8L302.84 293.81" stroke="black" stroke-width="0.5" />
    <path d="M302.84 293.81H302.89" stroke="black" stroke-width="0.5" />
    <path d="M302.89 293.81L302.95 293.82" stroke="black" stroke-width="0.5" />
    <path d="M302.95 293.82H303.01" stroke="black" stroke-width="0.5" />
    <path d="M303.01 293.82H303.02" stroke="black" stroke-width="0.5" />
    <path d="M303.02 292.72H303.01" stroke="black" stroke-width="0.5" />
    <path d="M303.01 292.72H303" stroke="black" stroke-width="0.5" />
    <path d="M303 292.72H302.98" stroke="black" stroke-width="0.5" />
    <path d="M302.98 292.72L302.97 292.71" stroke="black" stroke-width="0.5" />
    <path d="M302.97 292.71H302.96" stroke="black" stroke-width="0.5" />
    <path d="M302.96 292.71H302.94" stroke="black" stroke-width="0.5" />
    <path d="M302.94 292.71H302.93" stroke="black" stroke-width="0.5" />
    <path d="M302.93 292.71L302.92 292.7" stroke="black" stroke-width="0.5" />
    <path d="M302.92 292.7H302.91" stroke="black" stroke-width="0.5" />
    <path d="M302.91 292.7L302.89 292.69" stroke="black" stroke-width="0.5" />
    <path d="M302.89 292.69H302.88" stroke="black" stroke-width="0.5" />
    <path d="M302.88 292.69L302.87 292.68" stroke="black" stroke-width="0.5" />
    <path d="M302.87 292.68L302.86 292.67" stroke="black" stroke-width="0.5" />
    <path d="M302.86 292.67H302.85" stroke="black" stroke-width="0.5" />
    <path d="M302.85 292.67L302.84 292.66" stroke="black" stroke-width="0.5" />
    <path d="M302.84 292.66L302.82 292.65" stroke="black" stroke-width="0.5" />
    <path d="M302.82 292.65H302.81" stroke="black" stroke-width="0.5" />
    <path d="M302.81 292.65L302.8 292.64" stroke="black" stroke-width="0.5" />
    <path d="M302.8 292.64L302.79 292.63" stroke="black" stroke-width="0.5" />
    <path d="M302.79 292.63V292.62" stroke="black" stroke-width="0.5" />
    <path d="M302.79 292.62L302.78 292.61" stroke="black" stroke-width="0.5" />
    <path d="M302.78 292.61L302.77 292.6" stroke="black" stroke-width="0.5" />
    <path d="M302.77 292.6L302.76 292.59" stroke="black" stroke-width="0.5" />
    <path d="M302.76 292.59L302.75 292.58" stroke="black" stroke-width="0.5" />
    <path d="M302.75 292.58L302.74 292.57" stroke="black" stroke-width="0.5" />
    <path d="M302.74 292.57V292.56" stroke="black" stroke-width="0.5" />
    <path d="M302.74 292.56L302.73 292.54" stroke="black" stroke-width="0.5" />
    <path d="M302.73 292.54L302.72 292.53" stroke="black" stroke-width="0.5" />
    <path d="M302.72 292.53V292.52" stroke="black" stroke-width="0.5" />
    <path d="M302.72 292.52L302.71 292.51" stroke="black" stroke-width="0.5" />
    <path d="M302.71 292.51V292.5" stroke="black" stroke-width="0.5" />
    <path d="M302.71 292.5V292.48" stroke="black" stroke-width="0.5" />
    <path d="M302.71 292.48L302.7 292.47" stroke="black" stroke-width="0.5" />
    <path d="M302.7 292.47V292.46" stroke="black" stroke-width="0.5" />
    <path d="M302.7 292.46V292.44" stroke="black" stroke-width="0.5" />
    <path d="M302.7 292.44L302.69 292.43" stroke="black" stroke-width="0.5" />
    <path d="M302.69 292.43V292.42" stroke="black" stroke-width="0.5" />
    <path d="M302.69 292.42V292.41" stroke="black" stroke-width="0.5" />
    <path d="M302.69 292.41V292.39" stroke="black" stroke-width="0.5" />
    <path d="M301.59 257.2V292.39" stroke="black" stroke-width="0.5" />
    <path d="M302.69 292.39V257.2" stroke="black" stroke-width="0.5" />
    <path d="M303.02 255.77H302.96" stroke="black" stroke-width="0.5" />
    <path d="M302.96 255.77L302.91 255.78" stroke="black" stroke-width="0.5" />
    <path d="M302.91 255.78H302.85" stroke="black" stroke-width="0.5" />
    <path d="M302.85 255.78L302.79 255.79" stroke="black" stroke-width="0.5" />
    <path d="M302.79 255.79L302.74 255.8" stroke="black" stroke-width="0.5" />
    <path d="M302.74 255.8L302.68 255.81" stroke="black" stroke-width="0.5" />
    <path d="M302.68 255.81L302.63 255.83" stroke="black" stroke-width="0.5" />
    <path d="M302.63 255.83L302.57 255.85" stroke="black" stroke-width="0.5" />
    <path d="M302.57 255.85L302.52 255.86" stroke="black" stroke-width="0.5" />
    <path d="M302.52 255.86L302.47 255.89" stroke="black" stroke-width="0.5" />
    <path d="M302.47 255.89L302.41 255.91" stroke="black" stroke-width="0.5" />
    <path d="M302.41 255.91L302.36 255.93" stroke="black" stroke-width="0.5" />
    <path d="M302.36 255.93L302.31 255.96" stroke="black" stroke-width="0.5" />
    <path d="M302.31 255.96L302.26 255.99" stroke="black" stroke-width="0.5" />
    <path d="M302.26 255.99L302.21 256.02" stroke="black" stroke-width="0.5" />
    <path d="M302.21 256.02L302.17 256.06" stroke="black" stroke-width="0.5" />
    <path d="M302.17 256.06L302.12 256.09" stroke="black" stroke-width="0.5" />
    <path d="M302.12 256.09L302.08 256.13" stroke="black" stroke-width="0.5" />
    <path d="M302.08 256.13L302.04 256.17" stroke="black" stroke-width="0.5" />
    <path d="M302.04 256.17L302 256.21" stroke="black" stroke-width="0.5" />
    <path d="M302 256.21L301.96 256.25" stroke="black" stroke-width="0.5" />
    <path d="M301.96 256.25L301.92 256.29" stroke="black" stroke-width="0.5" />
    <path d="M301.92 256.29L301.88 256.34" stroke="black" stroke-width="0.5" />
    <path d="M301.88 256.34L301.85 256.38" stroke="black" stroke-width="0.5" />
    <path d="M301.85 256.38L301.82 256.43" stroke="black" stroke-width="0.5" />
    <path d="M301.82 256.43L301.79 256.48" stroke="black" stroke-width="0.5" />
    <path d="M301.79 256.48L301.76 256.53" stroke="black" stroke-width="0.5" />
    <path d="M301.76 256.53L301.74 256.58" stroke="black" stroke-width="0.5" />
    <path d="M301.74 256.58L301.71 256.63" stroke="black" stroke-width="0.5" />
    <path d="M301.71 256.63L301.69 256.68" stroke="black" stroke-width="0.5" />
    <path d="M301.69 256.68L301.67 256.74" stroke="black" stroke-width="0.5" />
    <path d="M301.67 256.74L301.65 256.79" stroke="black" stroke-width="0.5" />
    <path d="M301.65 256.79L301.64 256.85" stroke="black" stroke-width="0.5" />
    <path d="M301.64 256.85L301.62 256.9" stroke="black" stroke-width="0.5" />
    <path d="M301.62 256.9L301.61 256.96" stroke="black" stroke-width="0.5" />
    <path d="M301.61 256.96L301.6 257.02" stroke="black" stroke-width="0.5" />
    <path d="M301.6 257.02V257.07" stroke="black" stroke-width="0.5" />
    <path d="M301.6 257.07L301.59 257.13" stroke="black" stroke-width="0.5" />
    <path d="M301.59 257.13V257.19" stroke="black" stroke-width="0.5" />
    <path d="M301.59 257.19V257.2" stroke="black" stroke-width="0.5" />
    <path d="M302.69 257.2V257.19" stroke="black" stroke-width="0.5" />
    <path d="M302.69 257.19V257.18" stroke="black" stroke-width="0.5" />
    <path d="M302.69 257.18V257.16" stroke="black" stroke-width="0.5" />
    <path d="M302.69 257.16L302.7 257.15" stroke="black" stroke-width="0.5" />
    <path d="M302.7 257.15V257.14" stroke="black" stroke-width="0.5" />
    <path d="M302.7 257.14V257.12" stroke="black" stroke-width="0.5" />
    <path d="M302.7 257.12V257.11" stroke="black" stroke-width="0.5" />
    <path d="M302.7 257.11L302.71 257.1" stroke="black" stroke-width="0.5" />
    <path d="M302.71 257.1V257.09" stroke="black" stroke-width="0.5" />
    <path d="M302.71 257.09L302.72 257.07" stroke="black" stroke-width="0.5" />
    <path d="M302.72 257.07V257.06" stroke="black" stroke-width="0.5" />
    <path d="M302.72 257.06L302.73 257.05" stroke="black" stroke-width="0.5" />
    <path d="M302.73 257.05L302.74 257.04" stroke="black" stroke-width="0.5" />
    <path d="M302.74 257.04V257.03" stroke="black" stroke-width="0.5" />
    <path d="M302.74 257.03L302.75 257.02" stroke="black" stroke-width="0.5" />
    <path d="M302.75 257.02L302.76 257.01" stroke="black" stroke-width="0.5" />
    <path d="M302.76 257.01L302.77 256.99" stroke="black" stroke-width="0.5" />
    <path d="M302.77 256.99V256.98" stroke="black" stroke-width="0.5" />
    <path d="M302.77 256.98H302.78" stroke="black" stroke-width="0.5" />
    <path d="M302.78 256.98L302.79 256.97" stroke="black" stroke-width="0.5" />
    <path d="M302.79 256.97L302.8 256.96" stroke="black" stroke-width="0.5" />
    <path d="M302.8 256.96L302.81 256.95" stroke="black" stroke-width="0.5" />
    <path d="M302.81 256.95L302.82 256.94" stroke="black" stroke-width="0.5" />
    <path d="M302.82 256.94L302.83 256.93" stroke="black" stroke-width="0.5" />
    <path d="M302.83 256.93L302.84 256.92" stroke="black" stroke-width="0.5" />
    <path d="M302.84 256.92H302.85" stroke="black" stroke-width="0.5" />
    <path d="M302.85 256.92L302.87 256.91" stroke="black" stroke-width="0.5" />
    <path d="M302.87 256.91H302.88" stroke="black" stroke-width="0.5" />
    <path d="M302.88 256.91L302.89 256.9" stroke="black" stroke-width="0.5" />
    <path d="M302.89 256.9L302.9 256.89" stroke="black" stroke-width="0.5" />
    <path d="M302.9 256.89H302.91" stroke="black" stroke-width="0.5" />
    <path d="M302.91 256.89H302.93" stroke="black" stroke-width="0.5" />
    <path d="M302.93 256.89L302.94 256.88" stroke="black" stroke-width="0.5" />
    <path d="M302.94 256.88H302.95" stroke="black" stroke-width="0.5" />
    <path d="M302.95 256.88H302.97" stroke="black" stroke-width="0.5" />
    <path d="M302.97 256.88H302.98" stroke="black" stroke-width="0.5" />
    <path d="M302.98 256.88L302.99 256.87" stroke="black" stroke-width="0.5" />
    <path d="M302.99 256.87H303.01" stroke="black" stroke-width="0.5" />
    <path d="M303.01 256.87H303.02" stroke="black" stroke-width="0.5" />
    <path d="M303.68 255.77H303.02" stroke="black" stroke-width="0.5" />
    <path d="M303.02 256.87H303.68" stroke="black" stroke-width="0.5" />
    <path d="M303.68 293.82V292.72" stroke="black" stroke-width="0.5" />
    <path d="M303.68 293.82V292.72" stroke="black" stroke-width="0.5" />
    <path d="M303.68 256.87V255.77" stroke="black" stroke-width="0.5" />
    <path d="M303.68 256.87V255.77" stroke="black" stroke-width="0.5" />
    <path d="M266.85 302.5V303.16" stroke="black" stroke-width="0.5" />
    <path d="M267.95 303.16V302.5" stroke="black" stroke-width="0.5" />
    <path d="M268.28 301.08H268.22" stroke="black" stroke-width="0.5" />
    <path d="M268.22 301.08H268.16" stroke="black" stroke-width="0.5" />
    <path d="M268.16 301.08L268.1 301.09" stroke="black" stroke-width="0.5" />
    <path d="M268.1 301.09H268.05" stroke="black" stroke-width="0.5" />
    <path d="M268.05 301.09L267.99 301.1" stroke="black" stroke-width="0.5" />
    <path d="M267.99 301.1L267.94 301.12" stroke="black" stroke-width="0.5" />
    <path d="M267.94 301.12L267.88 301.13" stroke="black" stroke-width="0.5" />
    <path d="M267.88 301.13L267.83 301.15" stroke="black" stroke-width="0.5" />
    <path d="M267.83 301.15L267.77 301.17" stroke="black" stroke-width="0.5" />
    <path d="M267.77 301.17L267.72 301.19" stroke="black" stroke-width="0.5" />
    <path d="M267.72 301.19L267.67 301.21" stroke="black" stroke-width="0.5" />
    <path d="M267.67 301.21L267.62 301.24" stroke="black" stroke-width="0.5" />
    <path d="M267.62 301.24L267.57 301.26" stroke="black" stroke-width="0.5" />
    <path d="M267.57 301.26L267.52 301.29" stroke="black" stroke-width="0.5" />
    <path d="M267.52 301.29L267.47 301.32" stroke="black" stroke-width="0.5" />
    <path d="M267.47 301.32L267.42 301.36" stroke="black" stroke-width="0.5" />
    <path d="M267.42 301.36L267.38 301.39" stroke="black" stroke-width="0.5" />
    <path d="M267.38 301.39L267.33 301.43" stroke="black" stroke-width="0.5" />
    <path d="M267.33 301.43L267.29 301.47" stroke="black" stroke-width="0.5" />
    <path d="M267.29 301.47L267.25 301.51" stroke="black" stroke-width="0.5" />
    <path d="M267.25 301.51L267.21 301.55" stroke="black" stroke-width="0.5" />
    <path d="M267.21 301.55L267.17 301.59" stroke="black" stroke-width="0.5" />
    <path d="M267.17 301.59L267.14 301.64" stroke="black" stroke-width="0.5" />
    <path d="M267.14 301.64L267.1 301.68" stroke="black" stroke-width="0.5" />
    <path d="M267.1 301.68L267.07 301.73" stroke="black" stroke-width="0.5" />
    <path d="M267.07 301.73L267.04 301.78" stroke="black" stroke-width="0.5" />
    <path d="M267.04 301.78L267.01 301.83" stroke="black" stroke-width="0.5" />
    <path d="M267.01 301.83L266.99 301.88" stroke="black" stroke-width="0.5" />
    <path d="M266.99 301.88L266.96 301.93" stroke="black" stroke-width="0.5" />
    <path d="M266.96 301.93L266.94 301.99" stroke="black" stroke-width="0.5" />
    <path d="M266.94 301.99L266.92 302.04" stroke="black" stroke-width="0.5" />
    <path d="M266.92 302.04L266.91 302.09" stroke="black" stroke-width="0.5" />
    <path d="M266.91 302.09L266.89 302.15" stroke="black" stroke-width="0.5" />
    <path d="M266.89 302.15L266.88 302.21" stroke="black" stroke-width="0.5" />
    <path d="M266.88 302.21L266.87 302.26" stroke="black" stroke-width="0.5" />
    <path d="M266.87 302.26L266.86 302.32" stroke="black" stroke-width="0.5" />
    <path d="M266.86 302.32L266.85 302.38" stroke="black" stroke-width="0.5" />
    <path d="M266.85 302.38V302.43" stroke="black" stroke-width="0.5" />
    <path d="M266.85 302.43V302.49" stroke="black" stroke-width="0.5" />
    <path d="M266.85 302.49V302.5" stroke="black" stroke-width="0.5" />
    <path d="M267.95 302.5V302.49" stroke="black" stroke-width="0.5" />
    <path d="M267.95 302.49V302.48" stroke="black" stroke-width="0.5" />
    <path d="M267.95 302.48V302.47" stroke="black" stroke-width="0.5" />
    <path d="M267.95 302.47V302.45" stroke="black" stroke-width="0.5" />
    <path d="M267.95 302.45V302.44" stroke="black" stroke-width="0.5" />
    <path d="M267.95 302.44V302.43" stroke="black" stroke-width="0.5" />
    <path d="M267.95 302.43L267.96 302.41" stroke="black" stroke-width="0.5" />
    <path d="M267.96 302.41V302.4" stroke="black" stroke-width="0.5" />
    <path d="M267.96 302.4L267.97 302.39" stroke="black" stroke-width="0.5" />
    <path d="M267.97 302.39V302.38" stroke="black" stroke-width="0.5" />
    <path d="M267.97 302.38L267.98 302.36" stroke="black" stroke-width="0.5" />
    <path d="M267.98 302.36V302.35" stroke="black" stroke-width="0.5" />
    <path d="M267.98 302.35L267.99 302.34" stroke="black" stroke-width="0.5" />
    <path d="M267.99 302.34L268 302.33" stroke="black" stroke-width="0.5" />
    <path d="M268 302.33V302.32" stroke="black" stroke-width="0.5" />
    <path d="M268 302.32L268.01 302.31" stroke="black" stroke-width="0.5" />
    <path d="M268.01 302.31L268.02 302.3" stroke="black" stroke-width="0.5" />
    <path d="M268.02 302.3L268.03 302.29" stroke="black" stroke-width="0.5" />
    <path d="M268.03 302.29L268.04 302.28" stroke="black" stroke-width="0.5" />
    <path d="M268.04 302.28L268.05 302.27" stroke="black" stroke-width="0.5" />
    <path d="M268.05 302.27L268.06 302.26" stroke="black" stroke-width="0.5" />
    <path d="M268.06 302.26L268.07 302.25" stroke="black" stroke-width="0.5" />
    <path d="M268.07 302.25L268.08 302.24" stroke="black" stroke-width="0.5" />
    <path d="M268.08 302.24L268.09 302.23" stroke="black" stroke-width="0.5" />
    <path d="M268.09 302.23H268.1" stroke="black" stroke-width="0.5" />
    <path d="M268.1 302.23L268.11 302.22" stroke="black" stroke-width="0.5" />
    <path d="M268.11 302.22L268.12 302.21" stroke="black" stroke-width="0.5" />
    <path d="M268.12 302.21H268.13" stroke="black" stroke-width="0.5" />
    <path d="M268.13 302.21L268.14 302.2" stroke="black" stroke-width="0.5" />
    <path d="M268.14 302.2H268.16" stroke="black" stroke-width="0.5" />
    <path d="M268.16 302.2L268.17 302.19" stroke="black" stroke-width="0.5" />
    <path d="M268.17 302.19H268.18" stroke="black" stroke-width="0.5" />
    <path d="M268.18 302.19H268.19" stroke="black" stroke-width="0.5" />
    <path d="M268.19 302.19L268.21 302.18" stroke="black" stroke-width="0.5" />
    <path d="M268.21 302.18H268.22" stroke="black" stroke-width="0.5" />
    <path d="M268.22 302.18H268.23" stroke="black" stroke-width="0.5" />
    <path d="M268.23 302.18H268.25" stroke="black" stroke-width="0.5" />
    <path d="M268.25 302.18H268.26" stroke="black" stroke-width="0.5" />
    <path d="M268.26 302.18L268.27 302.17" stroke="black" stroke-width="0.5" />
    <path d="M268.27 302.17H268.28" stroke="black" stroke-width="0.5" />
    <path d="M298.18 301.08H268.28" stroke="black" stroke-width="0.5" />
    <path d="M268.28 302.17H298.18" stroke="black" stroke-width="0.5" />
    <path d="M299.61 302.5V302.45" stroke="black" stroke-width="0.5" />
    <path d="M299.61 302.45V302.39" stroke="black" stroke-width="0.5" />
    <path d="M299.61 302.39L299.6 302.33" stroke="black" stroke-width="0.5" />
    <path d="M299.6 302.33L299.59 302.28" stroke="black" stroke-width="0.5" />
    <path d="M299.59 302.28L299.58 302.22" stroke="black" stroke-width="0.5" />
    <path d="M299.58 302.22L299.57 302.16" stroke="black" stroke-width="0.5" />
    <path d="M299.57 302.16L299.56 302.11" stroke="black" stroke-width="0.5" />
    <path d="M299.56 302.11L299.54 302.06" stroke="black" stroke-width="0.5" />
    <path d="M299.54 302.06L299.52 302" stroke="black" stroke-width="0.5" />
    <path d="M299.52 302L299.5 301.95" stroke="black" stroke-width="0.5" />
    <path d="M299.5 301.95L299.48 301.9" stroke="black" stroke-width="0.5" />
    <path d="M299.48 301.9L299.45 301.84" stroke="black" stroke-width="0.5" />
    <path d="M299.45 301.84L299.42 301.79" stroke="black" stroke-width="0.5" />
    <path d="M299.42 301.79L299.39 301.75" stroke="black" stroke-width="0.5" />
    <path d="M299.39 301.75L299.36 301.7" stroke="black" stroke-width="0.5" />
    <path d="M299.36 301.7L299.33 301.65" stroke="black" stroke-width="0.5" />
    <path d="M299.33 301.65L299.3 301.61" stroke="black" stroke-width="0.5" />
    <path d="M299.3 301.61L299.26 301.56" stroke="black" stroke-width="0.5" />
    <path d="M299.26 301.56L299.22 301.52" stroke="black" stroke-width="0.5" />
    <path d="M299.22 301.52L299.18 301.48" stroke="black" stroke-width="0.5" />
    <path d="M299.18 301.48L299.14 301.44" stroke="black" stroke-width="0.5" />
    <path d="M299.14 301.44L299.09 301.4" stroke="black" stroke-width="0.5" />
    <path d="M299.09 301.4L299.05 301.37" stroke="black" stroke-width="0.5" />
    <path d="M299.05 301.37L299 301.33" stroke="black" stroke-width="0.5" />
    <path d="M299 301.33L298.96 301.3" stroke="black" stroke-width="0.5" />
    <path d="M298.96 301.3L298.91 301.27" stroke="black" stroke-width="0.5" />
    <path d="M298.91 301.27L298.86 301.24" stroke="black" stroke-width="0.5" />
    <path d="M298.86 301.24L298.81 301.22" stroke="black" stroke-width="0.5" />
    <path d="M298.81 301.22L298.75 301.19" stroke="black" stroke-width="0.5" />
    <path d="M298.75 301.19L298.7 301.17" stroke="black" stroke-width="0.5" />
    <path d="M298.7 301.17L298.65 301.15" stroke="black" stroke-width="0.5" />
    <path d="M298.65 301.15L298.59 301.14" stroke="black" stroke-width="0.5" />
    <path d="M298.59 301.14L298.54 301.12" stroke="black" stroke-width="0.5" />
    <path d="M298.54 301.12L298.48 301.11" stroke="black" stroke-width="0.5" />
    <path d="M298.48 301.11L298.43 301.1" stroke="black" stroke-width="0.5" />
    <path d="M298.43 301.1L298.37 301.09" stroke="black" stroke-width="0.5" />
    <path d="M298.37 301.09L298.31 301.08" stroke="black" stroke-width="0.5" />
    <path d="M298.31 301.08H298.26" stroke="black" stroke-width="0.5" />
    <path d="M298.26 301.08H298.2" stroke="black" stroke-width="0.5" />
    <path d="M298.2 301.08H298.18" stroke="black" stroke-width="0.5" />
    <path d="M298.18 302.17L298.2 302.18" stroke="black" stroke-width="0.5" />
    <path d="M298.2 302.18H298.21" stroke="black" stroke-width="0.5" />
    <path d="M298.21 302.18H298.22" stroke="black" stroke-width="0.5" />
    <path d="M298.22 302.18H298.24" stroke="black" stroke-width="0.5" />
    <path d="M298.24 302.18H298.25" stroke="black" stroke-width="0.5" />
    <path d="M298.25 302.18H298.26" stroke="black" stroke-width="0.5" />
    <path d="M298.26 302.18L298.27 302.19" stroke="black" stroke-width="0.5" />
    <path d="M298.27 302.19H298.29" stroke="black" stroke-width="0.5" />
    <path d="M298.29 302.19L298.3 302.2" stroke="black" stroke-width="0.5" />
    <path d="M298.3 302.2H298.31" stroke="black" stroke-width="0.5" />
    <path d="M298.31 302.2L298.32 302.21" stroke="black" stroke-width="0.5" />
    <path d="M298.32 302.21H298.34" stroke="black" stroke-width="0.5" />
    <path d="M298.34 302.21L298.35 302.22" stroke="black" stroke-width="0.5" />
    <path d="M298.35 302.22L298.36 302.23" stroke="black" stroke-width="0.5" />
    <path d="M298.36 302.23H298.37" stroke="black" stroke-width="0.5" />
    <path d="M298.37 302.23L298.38 302.24" stroke="black" stroke-width="0.5" />
    <path d="M298.38 302.24L298.39 302.25" stroke="black" stroke-width="0.5" />
    <path d="M298.39 302.25L298.4 302.26" stroke="black" stroke-width="0.5" />
    <path d="M298.4 302.26L298.41 302.27" stroke="black" stroke-width="0.5" />
    <path d="M298.41 302.27H298.42" stroke="black" stroke-width="0.5" />
    <path d="M298.42 302.27L298.43 302.28" stroke="black" stroke-width="0.5" />
    <path d="M298.43 302.28L298.44 302.29" stroke="black" stroke-width="0.5" />
    <path d="M298.44 302.29L298.45 302.3" stroke="black" stroke-width="0.5" />
    <path d="M298.45 302.3V302.32" stroke="black" stroke-width="0.5" />
    <path d="M298.45 302.32L298.46 302.33" stroke="black" stroke-width="0.5" />
    <path d="M298.46 302.33L298.47 302.34" stroke="black" stroke-width="0.5" />
    <path d="M298.47 302.34V302.35" stroke="black" stroke-width="0.5" />
    <path d="M298.47 302.35L298.48 302.36" stroke="black" stroke-width="0.5" />
    <path d="M298.48 302.36L298.49 302.37" stroke="black" stroke-width="0.5" />
    <path d="M298.49 302.37V302.39" stroke="black" stroke-width="0.5" />
    <path d="M298.49 302.39L298.5 302.4" stroke="black" stroke-width="0.5" />
    <path d="M298.5 302.4V302.41" stroke="black" stroke-width="0.5" />
    <path d="M298.5 302.41V302.42" stroke="black" stroke-width="0.5" />
    <path d="M298.5 302.42L298.51 302.44" stroke="black" stroke-width="0.5" />
    <path d="M298.51 302.44V302.45" stroke="black" stroke-width="0.5" />
    <path d="M298.51 302.45V302.46" stroke="black" stroke-width="0.5" />
    <path d="M298.51 302.46V302.47" stroke="black" stroke-width="0.5" />
    <path d="M298.51 302.47V302.49" stroke="black" stroke-width="0.5" />
    <path d="M298.51 302.49V302.5" stroke="black" stroke-width="0.5" />
    <path d="M299.61 303.16V302.5" stroke="black" stroke-width="0.5" />
    <path d="M298.51 302.5V303.16" stroke="black" stroke-width="0.5" />
    <path d="M266.85 303.16H267.95" stroke="black" stroke-width="0.5" />
    <path d="M266.85 303.16H267.95" stroke="black" stroke-width="0.5" />
    <path d="M298.51 303.16H299.61" stroke="black" stroke-width="0.5" />
    <path d="M298.51 303.16H299.61" stroke="black" stroke-width="0.5" />
    <path d="M248.2 288.87H234.13" stroke="black" stroke-width="0.5" />
    <path
      d="M234.13 288.87C233.897 288.87 233.673 288.963 233.508 289.128C233.343 289.293 233.25 289.517 233.25 289.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M233.25 289.75V303.82" stroke="black" stroke-width="0.5" />
    <path d="M233.25 303.82H249.08" stroke="black" stroke-width="0.5" />
    <path d="M249.08 303.82V289.75" stroke="black" stroke-width="0.5" />
    <path
      d="M249.08 289.75C249.08 289.517 248.987 289.293 248.822 289.128C248.657 288.963 248.433 288.87 248.2 288.87"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M253.92 261.6V261.66" stroke="black" stroke-width="0.5" />
    <path d="M253.92 261.77V261.88" stroke="black" stroke-width="0.5" />
    <path d="M253.92 261.99V262.1" stroke="black" stroke-width="0.5" />
    <path d="M253.92 262.21V262.32" stroke="black" stroke-width="0.5" />
    <path d="M253.92 262.43V262.54" stroke="black" stroke-width="0.5" />
    <path d="M253.92 262.65V262.76" stroke="black" stroke-width="0.5" />
    <path d="M253.92 262.87V262.98" stroke="black" stroke-width="0.5" />
    <path d="M253.92 263.09V263.19" stroke="black" stroke-width="0.5" />
    <path d="M253.92 263.3V263.41" stroke="black" stroke-width="0.5" />
    <path d="M253.92 263.52V263.63" stroke="black" stroke-width="0.5" />
    <path d="M253.92 263.74V263.85" stroke="black" stroke-width="0.5" />
    <path d="M253.92 263.96V264.07" stroke="black" stroke-width="0.5" />
    <path d="M253.92 264.18V264.29" stroke="black" stroke-width="0.5" />
    <path d="M253.92 264.4V264.51" stroke="black" stroke-width="0.5" />
    <path d="M253.92 264.62V264.73" stroke="black" stroke-width="0.5" />
    <path d="M253.92 264.84V264.95" stroke="black" stroke-width="0.5" />
    <path d="M253.92 265.06V265.17" stroke="black" stroke-width="0.5" />
    <path d="M253.92 265.28V265.39" stroke="black" stroke-width="0.5" />
    <path d="M253.92 265.5V265.61" stroke="black" stroke-width="0.5" />
    <path d="M253.92 265.72V265.83" stroke="black" stroke-width="0.5" />
    <path d="M253.92 265.94V266.05" stroke="black" stroke-width="0.5" />
    <path d="M253.92 266.16V266.27" stroke="black" stroke-width="0.5" />
    <path d="M253.92 266.38V266.49" stroke="black" stroke-width="0.5" />
    <path d="M253.92 266.6V266.71" stroke="black" stroke-width="0.5" />
    <path d="M253.92 266.82V266.93" stroke="black" stroke-width="0.5" />
    <path d="M253.92 267.04V267.15" stroke="black" stroke-width="0.5" />
    <path d="M253.92 267.26V267.37" stroke="black" stroke-width="0.5" />
    <path d="M253.92 267.48V267.59" stroke="black" stroke-width="0.5" />
    <path d="M253.92 267.7V267.81" stroke="black" stroke-width="0.5" />
    <path d="M253.92 267.92V268.03" stroke="black" stroke-width="0.5" />
    <path d="M253.92 268.14V268.25" stroke="black" stroke-width="0.5" />
    <path d="M253.92 268.36V268.47" stroke="black" stroke-width="0.5" />
    <path d="M253.92 268.58V268.69" stroke="black" stroke-width="0.5" />
    <path d="M253.92 268.8V268.91" stroke="black" stroke-width="0.5" />
    <path d="M253.92 269.02V269.13" stroke="black" stroke-width="0.5" />
    <path d="M253.92 269.24V269.35" stroke="black" stroke-width="0.5" />
    <path d="M253.92 269.46V269.57" stroke="black" stroke-width="0.5" />
    <path d="M253.92 269.68V269.79" stroke="black" stroke-width="0.5" />
    <path d="M253.92 269.9V270.01" stroke="black" stroke-width="0.5" />
    <path d="M253.92 270.12V270.23" stroke="black" stroke-width="0.5" />
    <path d="M253.92 270.34V270.45" stroke="black" stroke-width="0.5" />
    <path d="M253.92 270.56V270.67" stroke="black" stroke-width="0.5" />
    <path d="M253.92 270.78V270.89" stroke="black" stroke-width="0.5" />
    <path d="M253.92 271V271.11" stroke="black" stroke-width="0.5" />
    <path d="M253.92 271.22V271.33" stroke="black" stroke-width="0.5" />
    <path d="M253.92 271.44V271.55" stroke="black" stroke-width="0.5" />
    <path d="M253.92 271.66V271.77" stroke="black" stroke-width="0.5" />
    <path d="M253.92 271.88V271.99" stroke="black" stroke-width="0.5" />
    <path d="M253.92 272.1V272.21" stroke="black" stroke-width="0.5" />
    <path d="M253.92 272.32V272.43" stroke="black" stroke-width="0.5" />
    <path d="M253.92 272.54V272.65" stroke="black" stroke-width="0.5" />
    <path d="M253.92 272.76V272.87" stroke="black" stroke-width="0.5" />
    <path d="M253.92 272.98V273.09" stroke="black" stroke-width="0.5" />
    <path d="M253.92 273.2V273.31" stroke="black" stroke-width="0.5" />
    <path d="M253.92 273.42V273.53" stroke="black" stroke-width="0.5" />
    <path d="M253.92 273.64V273.75" stroke="black" stroke-width="0.5" />
    <path d="M253.92 273.86V273.97" stroke="black" stroke-width="0.5" />
    <path d="M253.92 274.08V274.19" stroke="black" stroke-width="0.5" />
    <path d="M253.92 274.3V274.41" stroke="black" stroke-width="0.5" />
    <path d="M253.92 274.52V274.63" stroke="black" stroke-width="0.5" />
    <path d="M253.92 274.74V274.85" stroke="black" stroke-width="0.5" />
    <path d="M253.92 274.96V275.07" stroke="black" stroke-width="0.5" />
    <path d="M253.92 275.18V275.29" stroke="black" stroke-width="0.5" />
    <path d="M253.92 275.4V275.51" stroke="black" stroke-width="0.5" />
    <path d="M253.92 275.62V275.73" stroke="black" stroke-width="0.5" />
    <path d="M253.92 275.84V275.95" stroke="black" stroke-width="0.5" />
    <path d="M253.92 276.06V276.17" stroke="black" stroke-width="0.5" />
    <path d="M253.92 276.28V276.39" stroke="black" stroke-width="0.5" />
    <path d="M253.92 276.5V276.61" stroke="black" stroke-width="0.5" />
    <path d="M253.92 276.72V276.83" stroke="black" stroke-width="0.5" />
    <path d="M253.92 276.94V277.05" stroke="black" stroke-width="0.5" />
    <path d="M253.92 277.16V277.27" stroke="black" stroke-width="0.5" />
    <path d="M253.92 277.38V277.49" stroke="black" stroke-width="0.5" />
    <path d="M253.92 277.6V277.71" stroke="black" stroke-width="0.5" />
    <path d="M253.92 277.82V277.93" stroke="black" stroke-width="0.5" />
    <path d="M253.92 278.04V278.15" stroke="black" stroke-width="0.5" />
    <path d="M253.92 278.26V278.37" stroke="black" stroke-width="0.5" />
    <path d="M253.92 278.48V278.59" stroke="black" stroke-width="0.5" />
    <path d="M253.92 278.7V278.81" stroke="black" stroke-width="0.5" />
    <path d="M253.92 278.92V279.03" stroke="black" stroke-width="0.5" />
    <path d="M253.92 279.14V279.25" stroke="black" stroke-width="0.5" />
    <path d="M253.92 279.36V279.47" stroke="black" stroke-width="0.5" />
    <path d="M253.92 279.58V279.69" stroke="black" stroke-width="0.5" />
    <path d="M253.92 279.8V279.91" stroke="black" stroke-width="0.5" />
    <path d="M253.92 280.02V280.13" stroke="black" stroke-width="0.5" />
    <path d="M253.92 280.24V280.35" stroke="black" stroke-width="0.5" />
    <path d="M253.92 280.46V280.57" stroke="black" stroke-width="0.5" />
    <path d="M253.92 280.68V280.79" stroke="black" stroke-width="0.5" />
    <path d="M253.92 280.9V281.01" stroke="black" stroke-width="0.5" />
    <path d="M253.92 281.12V281.23" stroke="black" stroke-width="0.5" />
    <path d="M253.92 281.34V281.45" stroke="black" stroke-width="0.5" />
    <path d="M253.92 281.56V281.67" stroke="black" stroke-width="0.5" />
    <path d="M253.92 281.78V281.89" stroke="black" stroke-width="0.5" />
    <path d="M253.92 282V282.11" stroke="black" stroke-width="0.5" />
    <path d="M253.92 282.22V282.33" stroke="black" stroke-width="0.5" />
    <path d="M253.92 282.44V282.55" stroke="black" stroke-width="0.5" />
    <path d="M253.92 282.66V282.77" stroke="black" stroke-width="0.5" />
    <path d="M253.92 282.88V282.99" stroke="black" stroke-width="0.5" />
    <path d="M253.92 283.1V283.21" stroke="black" stroke-width="0.5" />
    <path d="M253.92 283.32V283.43" stroke="black" stroke-width="0.5" />
    <path d="M253.92 283.54V283.65" stroke="black" stroke-width="0.5" />
    <path d="M253.92 283.76V283.87" stroke="black" stroke-width="0.5" />
    <path d="M253.92 283.98V284.09" stroke="black" stroke-width="0.5" />
    <path d="M253.92 284.2V284.31" stroke="black" stroke-width="0.5" />
    <path d="M253.92 284.42V284.53" stroke="black" stroke-width="0.5" />
    <path d="M253.92 284.64V284.75" stroke="black" stroke-width="0.5" />
    <path d="M253.92 284.86V284.97" stroke="black" stroke-width="0.5" />
    <path d="M253.92 285.08V285.19" stroke="black" stroke-width="0.5" />
    <path d="M253.92 285.3V285.41" stroke="black" stroke-width="0.5" />
    <path d="M253.92 285.52V285.63" stroke="black" stroke-width="0.5" />
    <path d="M253.92 285.74V285.85" stroke="black" stroke-width="0.5" />
    <path d="M253.92 285.96V286.07" stroke="black" stroke-width="0.5" />
    <path d="M253.92 286.18V286.29" stroke="black" stroke-width="0.5" />
    <path d="M253.92 286.4V286.51" stroke="black" stroke-width="0.5" />
    <path d="M253.92 286.62V286.73" stroke="black" stroke-width="0.5" />
    <path d="M253.92 286.84V286.95" stroke="black" stroke-width="0.5" />
    <path d="M253.92 287.06V287.17" stroke="black" stroke-width="0.5" />
    <path d="M253.92 287.28V287.39" stroke="black" stroke-width="0.5" />
    <path d="M253.92 287.5V287.61" stroke="black" stroke-width="0.5" />
    <path d="M253.92 287.72V287.83" stroke="black" stroke-width="0.5" />
    <path d="M253.92 287.94V288.05" stroke="black" stroke-width="0.5" />
    <path d="M253.92 288.16V288.27" stroke="black" stroke-width="0.5" />
    <path d="M253.92 288.38V288.49" stroke="black" stroke-width="0.5" />
    <path d="M253.92 288.6V288.71" stroke="black" stroke-width="0.5" />
    <path d="M253.92 288.82V288.93" stroke="black" stroke-width="0.5" />
    <path d="M253.92 289.03V289.14" stroke="black" stroke-width="0.5" />
    <path d="M253.92 289.25V289.36" stroke="black" stroke-width="0.5" />
    <path d="M253.92 289.47V289.58" stroke="black" stroke-width="0.5" />
    <path d="M253.92 289.69V289.8" stroke="black" stroke-width="0.5" />
    <path d="M253.92 289.91V290.02" stroke="black" stroke-width="0.5" />
    <path d="M253.92 290.13V290.24" stroke="black" stroke-width="0.5" />
    <path d="M253.92 290.35V290.46" stroke="black" stroke-width="0.5" />
    <path d="M253.92 290.57V290.68" stroke="black" stroke-width="0.5" />
    <path d="M253.92 290.79V290.9" stroke="black" stroke-width="0.5" />
    <path d="M253.92 291.01V291.12" stroke="black" stroke-width="0.5" />
    <path d="M253.92 291.23V291.34" stroke="black" stroke-width="0.5" />
    <path d="M253.92 291.45V291.56" stroke="black" stroke-width="0.5" />
    <path d="M253.92 291.67V291.78" stroke="black" stroke-width="0.5" />
    <path d="M253.92 291.89V292" stroke="black" stroke-width="0.5" />
    <path d="M253.92 292.11V292.22" stroke="black" stroke-width="0.5" />
    <path d="M253.92 292.33V292.44" stroke="black" stroke-width="0.5" />
    <path d="M253.92 292.55V292.66" stroke="black" stroke-width="0.5" />
    <path d="M253.92 292.77V292.88" stroke="black" stroke-width="0.5" />
    <path d="M253.92 292.99V293.1" stroke="black" stroke-width="0.5" />
    <path d="M253.92 293.21V293.32" stroke="black" stroke-width="0.5" />
    <path d="M253.92 293.43V293.54" stroke="black" stroke-width="0.5" />
    <path d="M253.92 293.65V293.76" stroke="black" stroke-width="0.5" />
    <path d="M253.92 293.87V293.98" stroke="black" stroke-width="0.5" />
    <path d="M253.92 294.09V294.2" stroke="black" stroke-width="0.5" />
    <path d="M253.92 294.31V294.42" stroke="black" stroke-width="0.5" />
    <path d="M253.92 294.53V294.64" stroke="black" stroke-width="0.5" />
    <path d="M253.92 294.75V294.86" stroke="black" stroke-width="0.5" />
    <path d="M253.92 294.97V295.08" stroke="black" stroke-width="0.5" />
    <path d="M253.92 295.19V295.3" stroke="black" stroke-width="0.5" />
    <path d="M253.92 295.41V295.52" stroke="black" stroke-width="0.5" />
    <path d="M253.92 295.63V295.74" stroke="black" stroke-width="0.5" />
    <path d="M253.92 295.85V295.96" stroke="black" stroke-width="0.5" />
    <path d="M253.92 296.07V296.18" stroke="black" stroke-width="0.5" />
    <path d="M253.92 296.29V296.4" stroke="black" stroke-width="0.5" />
    <path d="M253.92 296.51V296.62" stroke="black" stroke-width="0.5" />
    <path d="M253.92 296.73V296.84" stroke="black" stroke-width="0.5" />
    <path d="M253.92 296.95V297.06" stroke="black" stroke-width="0.5" />
    <path d="M253.92 297.17V297.28" stroke="black" stroke-width="0.5" />
    <path d="M253.92 297.39V297.5" stroke="black" stroke-width="0.5" />
    <path d="M253.92 297.61V297.72" stroke="black" stroke-width="0.5" />
    <path d="M253.92 297.83V297.94" stroke="black" stroke-width="0.5" />
    <path d="M253.92 298.05V298.16" stroke="black" stroke-width="0.5" />
    <path d="M253.92 298.27V298.38" stroke="black" stroke-width="0.5" />
    <path d="M253.92 298.49V298.6" stroke="black" stroke-width="0.5" />
    <path d="M253.92 298.71V298.82" stroke="black" stroke-width="0.5" />
    <path d="M253.92 298.93V299.04" stroke="black" stroke-width="0.5" />
    <path d="M253.92 299.15V299.26" stroke="black" stroke-width="0.5" />
    <path d="M253.92 299.37V299.48" stroke="black" stroke-width="0.5" />
    <path d="M253.92 299.59V299.7" stroke="black" stroke-width="0.5" />
    <path d="M253.92 299.81V299.92" stroke="black" stroke-width="0.5" />
    <path d="M253.92 300.03V300.14" stroke="black" stroke-width="0.5" />
    <path d="M253.92 300.25V300.36" stroke="black" stroke-width="0.5" />
    <path d="M253.92 300.47V300.58" stroke="black" stroke-width="0.5" />
    <path d="M253.92 300.69V300.8" stroke="black" stroke-width="0.5" />
    <path d="M253.92 300.91V301.02" stroke="black" stroke-width="0.5" />
    <path d="M253.92 301.13V301.24" stroke="black" stroke-width="0.5" />
    <path d="M253.92 301.35V301.46" stroke="black" stroke-width="0.5" />
    <path d="M253.92 301.57V301.68" stroke="black" stroke-width="0.5" />
    <path d="M253.92 301.79V301.9" stroke="black" stroke-width="0.5" />
    <path d="M253.92 302.01V302.12" stroke="black" stroke-width="0.5" />
    <path d="M253.92 302.23V302.34" stroke="black" stroke-width="0.5" />
    <path d="M253.92 302.45V302.56" stroke="black" stroke-width="0.5" />
    <path d="M253.92 302.67V302.78" stroke="black" stroke-width="0.5" />
    <path d="M253.92 302.89V303" stroke="black" stroke-width="0.5" />
    <path d="M253.92 303.11V303.22" stroke="black" stroke-width="0.5" />
    <path d="M253.92 303.33V303.44" stroke="black" stroke-width="0.5" />
    <path d="M253.92 303.55V303.66" stroke="black" stroke-width="0.5" />
    <path d="M253.92 303.77V303.82" stroke="black" stroke-width="0.5" />
    <path d="M227.53 261.6H227.59" stroke="black" stroke-width="0.5" />
    <path d="M227.7 261.6H227.81" stroke="black" stroke-width="0.5" />
    <path d="M227.92 261.6H228.03" stroke="black" stroke-width="0.5" />
    <path d="M228.14 261.6H228.25" stroke="black" stroke-width="0.5" />
    <path d="M228.36 261.6H228.47" stroke="black" stroke-width="0.5" />
    <path d="M228.58 261.6H228.69" stroke="black" stroke-width="0.5" />
    <path d="M228.8 261.6H228.91" stroke="black" stroke-width="0.5" />
    <path d="M229.02 261.6H229.13" stroke="black" stroke-width="0.5" />
    <path d="M229.24 261.6H229.35" stroke="black" stroke-width="0.5" />
    <path d="M229.46 261.6H229.57" stroke="black" stroke-width="0.5" />
    <path d="M229.68 261.6H229.79" stroke="black" stroke-width="0.5" />
    <path d="M229.9 261.6H230.01" stroke="black" stroke-width="0.5" />
    <path d="M230.12 261.6H230.23" stroke="black" stroke-width="0.5" />
    <path d="M230.34 261.6H230.45" stroke="black" stroke-width="0.5" />
    <path d="M230.56 261.6H230.67" stroke="black" stroke-width="0.5" />
    <path d="M230.78 261.6H230.89" stroke="black" stroke-width="0.5" />
    <path d="M231 261.6H231.11" stroke="black" stroke-width="0.5" />
    <path d="M231.22 261.6H231.33" stroke="black" stroke-width="0.5" />
    <path d="M231.44 261.6H231.55" stroke="black" stroke-width="0.5" />
    <path d="M231.66 261.6H231.77" stroke="black" stroke-width="0.5" />
    <path d="M231.88 261.6H231.99" stroke="black" stroke-width="0.5" />
    <path d="M232.1 261.6H232.21" stroke="black" stroke-width="0.5" />
    <path d="M232.32 261.6H232.43" stroke="black" stroke-width="0.5" />
    <path d="M232.54 261.6H232.65" stroke="black" stroke-width="0.5" />
    <path d="M232.76 261.6H232.87" stroke="black" stroke-width="0.5" />
    <path d="M232.98 261.6H233.09" stroke="black" stroke-width="0.5" />
    <path d="M233.2 261.6H233.31" stroke="black" stroke-width="0.5" />
    <path d="M233.42 261.6H233.53" stroke="black" stroke-width="0.5" />
    <path d="M233.64 261.6H233.75" stroke="black" stroke-width="0.5" />
    <path d="M233.86 261.6H233.97" stroke="black" stroke-width="0.5" />
    <path d="M234.08 261.6H234.19" stroke="black" stroke-width="0.5" />
    <path d="M234.3 261.6H234.41" stroke="black" stroke-width="0.5" />
    <path d="M234.51 261.6H234.62" stroke="black" stroke-width="0.5" />
    <path d="M234.73 261.6H234.84" stroke="black" stroke-width="0.5" />
    <path d="M234.95 261.6H235.06" stroke="black" stroke-width="0.5" />
    <path d="M235.17 261.6H235.28" stroke="black" stroke-width="0.5" />
    <path d="M235.39 261.6H235.5" stroke="black" stroke-width="0.5" />
    <path d="M235.61 261.6H235.72" stroke="black" stroke-width="0.5" />
    <path d="M235.83 261.6H235.94" stroke="black" stroke-width="0.5" />
    <path d="M236.05 261.6H236.16" stroke="black" stroke-width="0.5" />
    <path d="M236.27 261.6H236.38" stroke="black" stroke-width="0.5" />
    <path d="M236.49 261.6H236.6" stroke="black" stroke-width="0.5" />
    <path d="M236.71 261.6H236.82" stroke="black" stroke-width="0.5" />
    <path d="M236.93 261.6H237.04" stroke="black" stroke-width="0.5" />
    <path d="M237.15 261.6H237.26" stroke="black" stroke-width="0.5" />
    <path d="M237.37 261.6H237.48" stroke="black" stroke-width="0.5" />
    <path d="M237.59 261.6H237.7" stroke="black" stroke-width="0.5" />
    <path d="M237.81 261.6H237.92" stroke="black" stroke-width="0.5" />
    <path d="M238.03 261.6H238.14" stroke="black" stroke-width="0.5" />
    <path d="M238.25 261.6H238.36" stroke="black" stroke-width="0.5" />
    <path d="M238.47 261.6H238.58" stroke="black" stroke-width="0.5" />
    <path d="M238.69 261.6H238.8" stroke="black" stroke-width="0.5" />
    <path d="M238.91 261.6H239.02" stroke="black" stroke-width="0.5" />
    <path d="M239.13 261.6H239.24" stroke="black" stroke-width="0.5" />
    <path d="M239.35 261.6H239.46" stroke="black" stroke-width="0.5" />
    <path d="M239.57 261.6H239.68" stroke="black" stroke-width="0.5" />
    <path d="M239.79 261.6H239.9" stroke="black" stroke-width="0.5" />
    <path d="M240.01 261.6H240.12" stroke="black" stroke-width="0.5" />
    <path d="M240.23 261.6H240.34" stroke="black" stroke-width="0.5" />
    <path d="M240.45 261.6H240.56" stroke="black" stroke-width="0.5" />
    <path d="M240.67 261.6H240.78" stroke="black" stroke-width="0.5" />
    <path d="M240.89 261.6H241" stroke="black" stroke-width="0.5" />
    <path d="M241.11 261.6H241.22" stroke="black" stroke-width="0.5" />
    <path d="M241.33 261.6H241.44" stroke="black" stroke-width="0.5" />
    <path d="M241.55 261.6H241.66" stroke="black" stroke-width="0.5" />
    <path d="M241.77 261.6H241.88" stroke="black" stroke-width="0.5" />
    <path d="M241.99 261.6H242.1" stroke="black" stroke-width="0.5" />
    <path d="M242.21 261.6H242.32" stroke="black" stroke-width="0.5" />
    <path d="M242.43 261.6H242.54" stroke="black" stroke-width="0.5" />
    <path d="M242.65 261.6H242.76" stroke="black" stroke-width="0.5" />
    <path d="M242.87 261.6H242.98" stroke="black" stroke-width="0.5" />
    <path d="M243.09 261.6H243.2" stroke="black" stroke-width="0.5" />
    <path d="M243.31 261.6H243.42" stroke="black" stroke-width="0.5" />
    <path d="M243.53 261.6H243.64" stroke="black" stroke-width="0.5" />
    <path d="M243.75 261.6H243.86" stroke="black" stroke-width="0.5" />
    <path d="M243.97 261.6H244.08" stroke="black" stroke-width="0.5" />
    <path d="M244.19 261.6H244.3" stroke="black" stroke-width="0.5" />
    <path d="M244.41 261.6H244.52" stroke="black" stroke-width="0.5" />
    <path d="M244.63 261.6H244.74" stroke="black" stroke-width="0.5" />
    <path d="M244.85 261.6H244.96" stroke="black" stroke-width="0.5" />
    <path d="M245.07 261.6H245.18" stroke="black" stroke-width="0.5" />
    <path d="M245.29 261.6H245.4" stroke="black" stroke-width="0.5" />
    <path d="M245.51 261.6H245.62" stroke="black" stroke-width="0.5" />
    <path d="M245.73 261.6H245.84" stroke="black" stroke-width="0.5" />
    <path d="M245.95 261.6H246.06" stroke="black" stroke-width="0.5" />
    <path d="M246.17 261.6H246.28" stroke="black" stroke-width="0.5" />
    <path d="M246.39 261.6H246.5" stroke="black" stroke-width="0.5" />
    <path d="M246.61 261.6H246.72" stroke="black" stroke-width="0.5" />
    <path d="M246.83 261.6H246.94" stroke="black" stroke-width="0.5" />
    <path d="M247.05 261.6H247.16" stroke="black" stroke-width="0.5" />
    <path d="M247.27 261.6H247.38" stroke="black" stroke-width="0.5" />
    <path d="M247.49 261.6H247.6" stroke="black" stroke-width="0.5" />
    <path d="M247.71 261.6H247.82" stroke="black" stroke-width="0.5" />
    <path d="M247.93 261.6H248.04" stroke="black" stroke-width="0.5" />
    <path d="M248.15 261.6H248.26" stroke="black" stroke-width="0.5" />
    <path d="M248.37 261.6H248.48" stroke="black" stroke-width="0.5" />
    <path d="M248.59 261.6H248.7" stroke="black" stroke-width="0.5" />
    <path d="M248.81 261.6H248.92" stroke="black" stroke-width="0.5" />
    <path d="M249.03 261.6H249.14" stroke="black" stroke-width="0.5" />
    <path d="M249.25 261.6H249.36" stroke="black" stroke-width="0.5" />
    <path d="M249.47 261.6H249.58" stroke="black" stroke-width="0.5" />
    <path d="M249.69 261.6H249.8" stroke="black" stroke-width="0.5" />
    <path d="M249.91 261.6H250.02" stroke="black" stroke-width="0.5" />
    <path d="M250.13 261.6H250.24" stroke="black" stroke-width="0.5" />
    <path d="M250.35 261.6H250.46" stroke="black" stroke-width="0.5" />
    <path d="M250.57 261.6H250.68" stroke="black" stroke-width="0.5" />
    <path d="M250.79 261.6H250.9" stroke="black" stroke-width="0.5" />
    <path d="M251.01 261.6H251.12" stroke="black" stroke-width="0.5" />
    <path d="M251.23 261.6H251.34" stroke="black" stroke-width="0.5" />
    <path d="M251.45 261.6H251.56" stroke="black" stroke-width="0.5" />
    <path d="M251.67 261.6H251.78" stroke="black" stroke-width="0.5" />
    <path d="M251.89 261.6H252" stroke="black" stroke-width="0.5" />
    <path d="M252.11 261.6H252.22" stroke="black" stroke-width="0.5" />
    <path d="M252.33 261.6H252.44" stroke="black" stroke-width="0.5" />
    <path d="M252.55 261.6H252.66" stroke="black" stroke-width="0.5" />
    <path d="M252.77 261.6H252.88" stroke="black" stroke-width="0.5" />
    <path d="M252.99 261.6H253.1" stroke="black" stroke-width="0.5" />
    <path d="M253.21 261.6H253.32" stroke="black" stroke-width="0.5" />
    <path d="M253.43 261.6H253.54" stroke="black" stroke-width="0.5" />
    <path d="M253.65 261.6H253.76" stroke="black" stroke-width="0.5" />
    <path d="M253.87 261.6H253.92" stroke="black" stroke-width="0.5" />
    <path d="M227.53 271.39V271.28" stroke="black" stroke-width="0.5" />
    <path d="M227.53 271.17V271.06" stroke="black" stroke-width="0.5" />
    <path d="M227.53 270.95V270.84" stroke="black" stroke-width="0.5" />
    <path d="M227.53 270.73V270.62" stroke="black" stroke-width="0.5" />
    <path d="M227.53 270.51V270.4" stroke="black" stroke-width="0.5" />
    <path d="M227.53 270.29V270.18" stroke="black" stroke-width="0.5" />
    <path d="M227.53 270.07V269.96" stroke="black" stroke-width="0.5" />
    <path d="M227.53 269.85V269.74" stroke="black" stroke-width="0.5" />
    <path d="M227.53 269.63V269.52" stroke="black" stroke-width="0.5" />
    <path d="M227.53 269.41V269.3" stroke="black" stroke-width="0.5" />
    <path d="M227.53 269.19V269.08" stroke="black" stroke-width="0.5" />
    <path d="M227.53 268.97V268.86" stroke="black" stroke-width="0.5" />
    <path d="M227.53 268.75V268.64" stroke="black" stroke-width="0.5" />
    <path d="M227.53 268.53V268.42" stroke="black" stroke-width="0.5" />
    <path d="M227.53 268.31V268.2" stroke="black" stroke-width="0.5" />
    <path d="M227.53 268.09V267.98" stroke="black" stroke-width="0.5" />
    <path d="M227.53 267.87V267.76" stroke="black" stroke-width="0.5" />
    <path d="M227.53 267.65V267.54" stroke="black" stroke-width="0.5" />
    <path d="M227.53 267.43V267.32" stroke="black" stroke-width="0.5" />
    <path d="M227.53 267.21V267.1" stroke="black" stroke-width="0.5" />
    <path d="M227.53 266.99V266.88" stroke="black" stroke-width="0.5" />
    <path d="M227.53 266.77V266.66" stroke="black" stroke-width="0.5" />
    <path d="M227.53 266.55V266.44" stroke="black" stroke-width="0.5" />
    <path d="M227.53 266.33V266.22" stroke="black" stroke-width="0.5" />
    <path d="M227.53 266.11V266" stroke="black" stroke-width="0.5" />
    <path d="M227.53 265.89V265.78" stroke="black" stroke-width="0.5" />
    <path d="M227.53 265.67V265.56" stroke="black" stroke-width="0.5" />
    <path d="M227.53 265.45V265.34" stroke="black" stroke-width="0.5" />
    <path d="M227.53 265.23V265.12" stroke="black" stroke-width="0.5" />
    <path d="M227.53 265.01V264.9" stroke="black" stroke-width="0.5" />
    <path d="M227.53 264.79V264.68" stroke="black" stroke-width="0.5" />
    <path d="M227.53 264.57V264.46" stroke="black" stroke-width="0.5" />
    <path d="M227.53 264.35V264.24" stroke="black" stroke-width="0.5" />
    <path d="M227.53 264.13V264.02" stroke="black" stroke-width="0.5" />
    <path d="M227.53 263.91V263.8" stroke="black" stroke-width="0.5" />
    <path d="M227.53 263.69V263.58" stroke="black" stroke-width="0.5" />
    <path d="M227.53 263.47V263.36" stroke="black" stroke-width="0.5" />
    <path d="M227.53 263.25V263.14" stroke="black" stroke-width="0.5" />
    <path d="M227.53 263.03V262.92" stroke="black" stroke-width="0.5" />
    <path d="M227.53 262.81V262.7" stroke="black" stroke-width="0.5" />
    <path d="M227.53 262.59V262.48" stroke="black" stroke-width="0.5" />
    <path d="M227.53 262.37V262.26" stroke="black" stroke-width="0.5" />
    <path d="M227.53 262.15V262.04" stroke="black" stroke-width="0.5" />
    <path d="M227.53 261.93V261.82" stroke="black" stroke-width="0.5" />
    <path d="M227.53 261.71V261.6" stroke="black" stroke-width="0.5" />
    <path d="M236.77 300.31H240.62" stroke="black" stroke-width="0.5" />
    <path
      d="M244.57 302.06C244.57 301.885 244.5 301.717 244.377 301.593C244.253 301.47 244.085 301.4 243.91 301.4C243.735 301.4 243.567 301.47 243.443 301.593C243.32 301.717 243.25 301.885 243.25 302.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M243.25 302.06C243.25 302.235 243.32 302.403 243.443 302.527C243.567 302.65 243.735 302.72 243.91 302.72C244.085 302.72 244.253 302.65 244.377 302.527C244.5 302.403 244.57 302.235 244.57 302.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M237.76 302.06C237.76 302.235 237.83 302.403 237.953 302.527C238.077 302.65 238.245 302.72 238.42 302.72C238.595 302.72 238.763 302.65 238.887 302.527C239.01 302.403 239.08 302.235 239.08 302.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M239.08 302.06C239.08 301.885 239.01 301.717 238.887 301.593C238.763 301.47 238.595 301.4 238.42 301.4C238.245 301.4 238.077 301.47 237.953 301.593C237.83 301.717 237.76 301.885 237.76 302.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M249.08 303.82V289.75" stroke="black" stroke-width="0.5" />
    <path
      d="M249.08 289.75C249.08 289.517 248.987 289.293 248.822 289.128C248.657 288.963 248.433 288.87 248.2 288.87"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M248.2 288.87H234.13" stroke="black" stroke-width="0.5" />
    <path d="M233.25 289.75V303.82" stroke="black" stroke-width="0.5" />
    <path
      d="M234.13 288.87C233.897 288.87 233.673 288.963 233.508 289.128C233.343 289.293 233.25 289.517 233.25 289.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M241.71 297.95V302.54" stroke="black" stroke-width="0.5" />
    <path d="M241.71 302.54H240.62" stroke="black" stroke-width="0.5" />
    <path d="M240.62 302.54V297.95" stroke="black" stroke-width="0.5" />
    <path d="M240.62 297.95H241.71" stroke="black" stroke-width="0.5" />
    <path
      d="M245.57 300.31C245.803 300.31 246.027 300.217 246.192 300.052C246.357 299.887 246.45 299.663 246.45 299.43"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M241.71 300.31H245.57" stroke="black" stroke-width="0.5" />
    <path
      d="M235.89 299.43C235.89 299.663 235.983 299.887 236.148 300.052C236.313 300.217 236.537 300.31 236.77 300.31"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M246.45 299.43V292.39" stroke="black" stroke-width="0.5" />
    <path
      d="M246.45 292.39C246.45 292.157 246.357 291.933 246.192 291.768C246.027 291.603 245.803 291.51 245.57 291.51"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M245.57 291.51H236.77" stroke="black" stroke-width="0.5" />
    <path
      d="M236.77 291.51C236.537 291.51 236.313 291.603 236.148 291.768C235.983 291.933 235.89 292.157 235.89 292.39"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M235.89 292.39V299.43" stroke="black" stroke-width="0.5" />
    <path
      d="M248.64 290.19C248.64 289.957 248.547 289.733 248.382 289.568C248.217 289.403 247.993 289.31 247.76 289.31"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M234.57 289.31C234.337 289.31 234.113 289.403 233.948 289.568C233.783 289.733 233.69 289.957 233.69 290.19"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M248.64 302.94V290.19" stroke="black" stroke-width="0.5" />
    <path d="M247.76 289.31H234.57" stroke="black" stroke-width="0.5" />
    <path d="M233.69 290.19V302.94" stroke="black" stroke-width="0.5" />
    <path d="M233.69 302.94H248.64" stroke="black" stroke-width="0.5" />
    <path d="M228.67 303.82H253.87" stroke="black" stroke-width="0.5" />
    <path d="M304.34 359.46V359.41" stroke="black" stroke-width="0.5" />
    <path d="M304.34 359.3V359.19" stroke="black" stroke-width="0.5" />
    <path d="M304.34 359.08V358.97" stroke="black" stroke-width="0.5" />
    <path d="M304.34 358.86V358.75" stroke="black" stroke-width="0.5" />
    <path d="M304.34 358.64V358.53" stroke="black" stroke-width="0.5" />
    <path d="M304.34 358.42V358.31" stroke="black" stroke-width="0.5" />
    <path d="M304.34 358.2V358.09" stroke="black" stroke-width="0.5" />
    <path d="M304.34 357.98V357.87" stroke="black" stroke-width="0.5" />
    <path d="M304.34 357.76V357.65" stroke="black" stroke-width="0.5" />
    <path d="M304.34 357.54V357.43" stroke="black" stroke-width="0.5" />
    <path d="M304.34 357.32V357.21" stroke="black" stroke-width="0.5" />
    <path d="M304.34 357.1V356.99" stroke="black" stroke-width="0.5" />
    <path d="M304.34 356.88V356.77" stroke="black" stroke-width="0.5" />
    <path d="M304.34 356.66V356.55" stroke="black" stroke-width="0.5" />
    <path d="M304.34 356.44V356.33" stroke="black" stroke-width="0.5" />
    <path d="M304.34 356.22V356.11" stroke="black" stroke-width="0.5" />
    <path d="M304.34 356V355.89" stroke="black" stroke-width="0.5" />
    <path d="M304.34 355.78V355.67" stroke="black" stroke-width="0.5" />
    <path d="M304.34 355.56V355.45" stroke="black" stroke-width="0.5" />
    <path d="M304.34 355.34V355.23" stroke="black" stroke-width="0.5" />
    <path d="M304.34 355.12V355.01" stroke="black" stroke-width="0.5" />
    <path d="M304.34 354.9V354.79" stroke="black" stroke-width="0.5" />
    <path d="M304.34 354.68V354.57" stroke="black" stroke-width="0.5" />
    <path d="M304.34 354.46V354.35" stroke="black" stroke-width="0.5" />
    <path d="M304.34 354.24V354.13" stroke="black" stroke-width="0.5" />
    <path d="M304.34 354.02V353.91" stroke="black" stroke-width="0.5" />
    <path d="M304.34 353.8V353.69" stroke="black" stroke-width="0.5" />
    <path d="M304.34 353.58V353.47" stroke="black" stroke-width="0.5" />
    <path d="M304.34 353.36V353.25" stroke="black" stroke-width="0.5" />
    <path d="M304.34 353.14V353.03" stroke="black" stroke-width="0.5" />
    <path d="M304.34 352.92V352.81" stroke="black" stroke-width="0.5" />
    <path d="M304.34 352.7V352.59" stroke="black" stroke-width="0.5" />
    <path d="M304.34 352.48V352.37" stroke="black" stroke-width="0.5" />
    <path d="M304.34 352.26V352.15" stroke="black" stroke-width="0.5" />
    <path d="M304.34 352.04V351.93" stroke="black" stroke-width="0.5" />
    <path d="M304.34 351.82V351.71" stroke="black" stroke-width="0.5" />
    <path d="M304.34 351.6V351.49" stroke="black" stroke-width="0.5" />
    <path d="M304.34 351.38V351.27" stroke="black" stroke-width="0.5" />
    <path d="M304.34 351.16V351.05" stroke="black" stroke-width="0.5" />
    <path d="M304.34 350.94V350.83" stroke="black" stroke-width="0.5" />
    <path d="M304.34 350.72V350.61" stroke="black" stroke-width="0.5" />
    <path d="M304.34 350.5V350.39" stroke="black" stroke-width="0.5" />
    <path d="M304.34 350.28V350.17" stroke="black" stroke-width="0.5" />
    <path d="M304.34 350.06V349.95" stroke="black" stroke-width="0.5" />
    <path d="M304.34 349.84V349.73" stroke="black" stroke-width="0.5" />
    <path d="M304.34 349.62V349.51" stroke="black" stroke-width="0.5" />
    <path d="M304.34 349.4V349.29" stroke="black" stroke-width="0.5" />
    <path d="M304.34 349.18V349.07" stroke="black" stroke-width="0.5" />
    <path d="M304.34 348.96V348.85" stroke="black" stroke-width="0.5" />
    <path d="M304.34 348.74V348.63" stroke="black" stroke-width="0.5" />
    <path d="M304.34 348.52V348.41" stroke="black" stroke-width="0.5" />
    <path d="M304.34 348.3V348.19" stroke="black" stroke-width="0.5" />
    <path d="M304.34 348.08V347.97" stroke="black" stroke-width="0.5" />
    <path d="M304.34 347.86V347.75" stroke="black" stroke-width="0.5" />
    <path d="M304.34 347.64V347.53" stroke="black" stroke-width="0.5" />
    <path d="M304.34 347.42V347.31" stroke="black" stroke-width="0.5" />
    <path d="M304.34 347.2V347.09" stroke="black" stroke-width="0.5" />
    <path d="M304.34 346.98V346.87" stroke="black" stroke-width="0.5" />
    <path d="M304.34 346.76V346.65" stroke="black" stroke-width="0.5" />
    <path d="M304.34 346.54V346.43" stroke="black" stroke-width="0.5" />
    <path d="M304.34 346.32V346.21" stroke="black" stroke-width="0.5" />
    <path d="M304.34 346.1V345.99" stroke="black" stroke-width="0.5" />
    <path d="M304.34 345.88V345.77" stroke="black" stroke-width="0.5" />
    <path d="M304.34 345.66V345.55" stroke="black" stroke-width="0.5" />
    <path d="M304.34 345.44V345.33" stroke="black" stroke-width="0.5" />
    <path d="M304.34 345.22V345.11" stroke="black" stroke-width="0.5" />
    <path d="M304.34 345V344.89" stroke="black" stroke-width="0.5" />
    <path d="M304.34 344.78V344.67" stroke="black" stroke-width="0.5" />
    <path d="M304.34 344.56V344.45" stroke="black" stroke-width="0.5" />
    <path d="M304.34 344.34V344.23" stroke="black" stroke-width="0.5" />
    <path d="M304.34 344.12V344.01" stroke="black" stroke-width="0.5" />
    <path d="M304.34 343.9V343.79" stroke="black" stroke-width="0.5" />
    <path d="M304.34 343.68V343.57" stroke="black" stroke-width="0.5" />
    <path d="M304.34 343.46V343.35" stroke="black" stroke-width="0.5" />
    <path d="M304.34 343.24V343.13" stroke="black" stroke-width="0.5" />
    <path d="M304.34 343.02V342.91" stroke="black" stroke-width="0.5" />
    <path d="M304.34 342.8V342.69" stroke="black" stroke-width="0.5" />
    <path d="M304.34 342.58V342.47" stroke="black" stroke-width="0.5" />
    <path d="M304.34 342.36V342.25" stroke="black" stroke-width="0.5" />
    <path d="M304.34 342.14V342.03" stroke="black" stroke-width="0.5" />
    <path d="M304.34 341.92V341.81" stroke="black" stroke-width="0.5" />
    <path d="M304.34 341.7V341.59" stroke="black" stroke-width="0.5" />
    <path d="M304.34 341.48V341.37" stroke="black" stroke-width="0.5" />
    <path d="M304.34 341.26V341.15" stroke="black" stroke-width="0.5" />
    <path d="M304.34 341.04V340.93" stroke="black" stroke-width="0.5" />
    <path d="M304.34 340.82V340.71" stroke="black" stroke-width="0.5" />
    <path d="M304.34 340.61V340.5" stroke="black" stroke-width="0.5" />
    <path d="M304.34 340.39V340.28" stroke="black" stroke-width="0.5" />
    <path d="M304.34 340.17V340.06" stroke="black" stroke-width="0.5" />
    <path d="M304.34 339.95V339.84" stroke="black" stroke-width="0.5" />
    <path d="M304.34 339.73V339.62" stroke="black" stroke-width="0.5" />
    <path d="M304.34 339.51V339.4" stroke="black" stroke-width="0.5" />
    <path d="M304.34 339.29V339.18" stroke="black" stroke-width="0.5" />
    <path d="M304.34 339.07V338.96" stroke="black" stroke-width="0.5" />
    <path d="M304.34 338.85V338.74" stroke="black" stroke-width="0.5" />
    <path d="M304.34 338.63V338.52" stroke="black" stroke-width="0.5" />
    <path d="M304.34 338.41V338.3" stroke="black" stroke-width="0.5" />
    <path d="M304.34 338.19V338.08" stroke="black" stroke-width="0.5" />
    <path d="M304.34 337.97V337.86" stroke="black" stroke-width="0.5" />
    <path d="M304.34 337.75V337.64" stroke="black" stroke-width="0.5" />
    <path d="M304.34 337.53V337.42" stroke="black" stroke-width="0.5" />
    <path d="M304.34 337.31V337.2" stroke="black" stroke-width="0.5" />
    <path d="M304.34 337.09V336.98" stroke="black" stroke-width="0.5" />
    <path d="M304.34 336.87V336.76" stroke="black" stroke-width="0.5" />
    <path d="M304.34 336.65V336.54" stroke="black" stroke-width="0.5" />
    <path d="M304.34 336.43V336.32" stroke="black" stroke-width="0.5" />
    <path d="M304.34 336.21V336.1" stroke="black" stroke-width="0.5" />
    <path d="M304.34 335.99V335.88" stroke="black" stroke-width="0.5" />
    <path d="M304.34 335.77V335.66" stroke="black" stroke-width="0.5" />
    <path d="M304.34 335.55V335.44" stroke="black" stroke-width="0.5" />
    <path d="M304.34 335.33V335.22" stroke="black" stroke-width="0.5" />
    <path d="M304.34 335.11V335" stroke="black" stroke-width="0.5" />
    <path d="M304.34 334.89V334.78" stroke="black" stroke-width="0.5" />
    <path d="M304.34 334.67V334.56" stroke="black" stroke-width="0.5" />
    <path d="M304.34 334.45V334.34" stroke="black" stroke-width="0.5" />
    <path d="M304.34 334.23V334.12" stroke="black" stroke-width="0.5" />
    <path d="M304.34 334.01V333.9" stroke="black" stroke-width="0.5" />
    <path d="M304.34 333.79V333.68" stroke="black" stroke-width="0.5" />
    <path d="M304.34 333.57V333.46" stroke="black" stroke-width="0.5" />
    <path d="M304.34 333.35V333.24" stroke="black" stroke-width="0.5" />
    <path d="M304.34 333.13V333.02" stroke="black" stroke-width="0.5" />
    <path d="M304.34 332.91V332.8" stroke="black" stroke-width="0.5" />
    <path d="M304.34 332.69V332.58" stroke="black" stroke-width="0.5" />
    <path d="M304.34 332.47V332.36" stroke="black" stroke-width="0.5" />
    <path d="M304.34 332.25V332.14" stroke="black" stroke-width="0.5" />
    <path d="M304.34 332.03V331.92" stroke="black" stroke-width="0.5" />
    <path d="M304.34 331.81V331.7" stroke="black" stroke-width="0.5" />
    <path d="M304.34 331.59V331.48" stroke="black" stroke-width="0.5" />
    <path d="M304.34 331.37V331.26" stroke="black" stroke-width="0.5" />
    <path d="M304.34 331.15V331.04" stroke="black" stroke-width="0.5" />
    <path d="M304.34 330.93V330.82" stroke="black" stroke-width="0.5" />
    <path d="M304.34 330.71V330.6" stroke="black" stroke-width="0.5" />
    <path d="M304.34 330.49V330.38" stroke="black" stroke-width="0.5" />
    <path d="M304.34 330.27V330.16" stroke="black" stroke-width="0.5" />
    <path d="M304.34 330.05V329.94" stroke="black" stroke-width="0.5" />
    <path d="M304.34 329.83V329.72" stroke="black" stroke-width="0.5" />
    <path d="M304.34 329.61V329.5" stroke="black" stroke-width="0.5" />
    <path d="M304.34 329.39V329.28" stroke="black" stroke-width="0.5" />
    <path d="M304.34 329.17V329.06" stroke="black" stroke-width="0.5" />
    <path d="M304.34 328.95V328.84" stroke="black" stroke-width="0.5" />
    <path d="M304.34 328.73V328.62" stroke="black" stroke-width="0.5" />
    <path d="M304.34 328.51V328.4" stroke="black" stroke-width="0.5" />
    <path d="M304.34 328.29V328.18" stroke="black" stroke-width="0.5" />
    <path d="M304.34 328.07V327.96" stroke="black" stroke-width="0.5" />
    <path d="M304.34 327.85V327.74" stroke="black" stroke-width="0.5" />
    <path d="M304.34 327.63V327.52" stroke="black" stroke-width="0.5" />
    <path d="M304.34 327.41V327.3" stroke="black" stroke-width="0.5" />
    <path d="M304.34 327.19V327.08" stroke="black" stroke-width="0.5" />
    <path d="M304.34 326.97V326.86" stroke="black" stroke-width="0.5" />
    <path d="M304.34 326.75V326.64" stroke="black" stroke-width="0.5" />
    <path d="M304.34 326.53V326.42" stroke="black" stroke-width="0.5" />
    <path d="M304.34 326.31V326.2" stroke="black" stroke-width="0.5" />
    <path d="M304.34 326.09V325.98" stroke="black" stroke-width="0.5" />
    <path d="M304.34 325.87V325.76" stroke="black" stroke-width="0.5" />
    <path d="M304.34 325.65V325.54" stroke="black" stroke-width="0.5" />
    <path d="M304.34 325.43V325.32" stroke="black" stroke-width="0.5" />
    <path d="M304.34 325.21V325.1" stroke="black" stroke-width="0.5" />
    <path d="M304.34 324.99V324.88" stroke="black" stroke-width="0.5" />
    <path d="M304.34 324.77V324.66" stroke="black" stroke-width="0.5" />
    <path d="M304.34 324.55V324.44" stroke="black" stroke-width="0.5" />
    <path d="M304.34 324.33V324.22" stroke="black" stroke-width="0.5" />
    <path d="M304.34 324.11V324" stroke="black" stroke-width="0.5" />
    <path d="M304.34 323.89V323.78" stroke="black" stroke-width="0.5" />
    <path d="M304.34 323.67V323.56" stroke="black" stroke-width="0.5" />
    <path d="M304.34 323.45V323.34" stroke="black" stroke-width="0.5" />
    <path d="M304.34 323.23V323.12" stroke="black" stroke-width="0.5" />
    <path d="M304.34 323.01V322.9" stroke="black" stroke-width="0.5" />
    <path d="M304.34 322.79V322.68" stroke="black" stroke-width="0.5" />
    <path d="M304.34 322.57V322.46" stroke="black" stroke-width="0.5" />
    <path d="M304.34 322.35V322.24" stroke="black" stroke-width="0.5" />
    <path d="M304.34 322.13V322.02" stroke="black" stroke-width="0.5" />
    <path d="M304.34 321.91V321.8" stroke="black" stroke-width="0.5" />
    <path d="M304.34 321.69V321.58" stroke="black" stroke-width="0.5" />
    <path d="M304.34 321.47V321.36" stroke="black" stroke-width="0.5" />
    <path d="M304.34 321.25V321.14" stroke="black" stroke-width="0.5" />
    <path d="M304.34 321.03V320.92" stroke="black" stroke-width="0.5" />
    <path d="M304.34 320.81V320.7" stroke="black" stroke-width="0.5" />
    <path d="M304.34 320.59V320.48" stroke="black" stroke-width="0.5" />
    <path d="M304.34 320.37V320.26" stroke="black" stroke-width="0.5" />
    <path d="M304.34 320.15V320.04" stroke="black" stroke-width="0.5" />
    <path d="M304.34 319.93V319.82" stroke="black" stroke-width="0.5" />
    <path d="M304.34 319.71V319.6" stroke="black" stroke-width="0.5" />
    <path d="M304.34 319.49V319.38" stroke="black" stroke-width="0.5" />
    <path d="M304.34 319.27V319.16" stroke="black" stroke-width="0.5" />
    <path d="M304.34 319.05V318.94" stroke="black" stroke-width="0.5" />
    <path d="M304.34 318.83V318.72" stroke="black" stroke-width="0.5" />
    <path d="M304.34 318.61V318.5" stroke="black" stroke-width="0.5" />
    <path d="M304.34 318.39V318.28" stroke="black" stroke-width="0.5" />
    <path d="M304.34 318.17V318.06" stroke="black" stroke-width="0.5" />
    <path d="M304.34 317.95V317.84" stroke="black" stroke-width="0.5" />
    <path d="M304.34 317.73V317.62" stroke="black" stroke-width="0.5" />
    <path d="M304.34 317.51V317.4" stroke="black" stroke-width="0.5" />
    <path d="M304.34 317.29V317.18" stroke="black" stroke-width="0.5" />
    <path d="M304.34 317.07V316.96" stroke="black" stroke-width="0.5" />
    <path d="M304.34 316.85V316.74" stroke="black" stroke-width="0.5" />
    <path d="M304.34 316.63V316.52" stroke="black" stroke-width="0.5" />
    <path d="M304.34 316.41V316.3" stroke="black" stroke-width="0.5" />
    <path d="M304.34 316.19V316.08" stroke="black" stroke-width="0.5" />
    <path d="M304.34 315.97V315.86" stroke="black" stroke-width="0.5" />
    <path d="M304.34 315.75V315.64" stroke="black" stroke-width="0.5" />
    <path d="M304.34 315.53V315.42" stroke="black" stroke-width="0.5" />
    <path d="M304.34 315.31V315.2" stroke="black" stroke-width="0.5" />
    <path d="M304.34 315.09V314.98" stroke="black" stroke-width="0.5" />
    <path d="M304.34 314.87V314.77" stroke="black" stroke-width="0.5" />
    <path d="M304.34 314.66V314.55" stroke="black" stroke-width="0.5" />
    <path d="M304.34 314.44V314.33" stroke="black" stroke-width="0.5" />
    <path d="M304.34 314.22V314.11" stroke="black" stroke-width="0.5" />
    <path d="M304.34 314V313.89" stroke="black" stroke-width="0.5" />
    <path d="M304.34 313.78V313.67" stroke="black" stroke-width="0.5" />
    <path d="M304.34 313.56V313.45" stroke="black" stroke-width="0.5" />
    <path d="M304.34 313.34V313.23" stroke="black" stroke-width="0.5" />
    <path d="M304.34 313.12V313.01" stroke="black" stroke-width="0.5" />
    <path d="M304.34 312.9V312.79" stroke="black" stroke-width="0.5" />
    <path d="M304.34 312.68V312.57" stroke="black" stroke-width="0.5" />
    <path d="M304.34 312.46V312.35" stroke="black" stroke-width="0.5" />
    <path d="M304.34 312.24V312.13" stroke="black" stroke-width="0.5" />
    <path d="M304.34 312.02V311.91" stroke="black" stroke-width="0.5" />
    <path d="M304.34 311.8V311.69" stroke="black" stroke-width="0.5" />
    <path d="M304.34 311.58V311.47" stroke="black" stroke-width="0.5" />
    <path d="M304.34 311.36V311.25" stroke="black" stroke-width="0.5" />
    <path d="M304.34 311.14V311.03" stroke="black" stroke-width="0.5" />
    <path d="M304.34 310.92V310.81" stroke="black" stroke-width="0.5" />
    <path d="M304.34 310.7V310.59" stroke="black" stroke-width="0.5" />
    <path d="M304.34 310.48V310.37" stroke="black" stroke-width="0.5" />
    <path d="M304.34 310.26V310.2" stroke="black" stroke-width="0.5" />
    <path d="M251.56 359.46V359.41" stroke="black" stroke-width="0.5" />
    <path d="M251.56 359.3V359.19" stroke="black" stroke-width="0.5" />
    <path d="M251.56 359.08V358.97" stroke="black" stroke-width="0.5" />
    <path d="M251.56 358.86V358.75" stroke="black" stroke-width="0.5" />
    <path d="M251.56 358.64V358.53" stroke="black" stroke-width="0.5" />
    <path d="M251.56 358.42V358.31" stroke="black" stroke-width="0.5" />
    <path d="M251.56 358.2V358.09" stroke="black" stroke-width="0.5" />
    <path d="M251.56 357.98V357.87" stroke="black" stroke-width="0.5" />
    <path d="M251.56 357.76V357.65" stroke="black" stroke-width="0.5" />
    <path d="M251.56 357.54V357.43" stroke="black" stroke-width="0.5" />
    <path d="M251.56 357.32V357.21" stroke="black" stroke-width="0.5" />
    <path d="M251.56 357.1V356.99" stroke="black" stroke-width="0.5" />
    <path d="M251.56 356.88V356.77" stroke="black" stroke-width="0.5" />
    <path d="M251.56 356.66V356.55" stroke="black" stroke-width="0.5" />
    <path d="M251.56 356.44V356.33" stroke="black" stroke-width="0.5" />
    <path d="M251.56 356.22V356.11" stroke="black" stroke-width="0.5" />
    <path d="M251.56 356V355.89" stroke="black" stroke-width="0.5" />
    <path d="M251.56 355.78V355.67" stroke="black" stroke-width="0.5" />
    <path d="M251.56 355.56V355.45" stroke="black" stroke-width="0.5" />
    <path d="M251.56 355.34V355.23" stroke="black" stroke-width="0.5" />
    <path d="M251.56 355.12V355.01" stroke="black" stroke-width="0.5" />
    <path d="M251.56 354.9V354.79" stroke="black" stroke-width="0.5" />
    <path d="M251.56 354.68V354.57" stroke="black" stroke-width="0.5" />
    <path d="M251.56 354.46V354.35" stroke="black" stroke-width="0.5" />
    <path d="M251.56 354.24V354.13" stroke="black" stroke-width="0.5" />
    <path d="M251.56 354.02V353.91" stroke="black" stroke-width="0.5" />
    <path d="M251.56 353.8V353.69" stroke="black" stroke-width="0.5" />
    <path d="M251.56 353.58V353.47" stroke="black" stroke-width="0.5" />
    <path d="M251.56 353.36V353.25" stroke="black" stroke-width="0.5" />
    <path d="M251.56 353.14V353.03" stroke="black" stroke-width="0.5" />
    <path d="M251.56 352.92V352.81" stroke="black" stroke-width="0.5" />
    <path d="M251.56 352.7V352.59" stroke="black" stroke-width="0.5" />
    <path d="M251.56 352.48V352.37" stroke="black" stroke-width="0.5" />
    <path d="M251.56 352.26V352.15" stroke="black" stroke-width="0.5" />
    <path d="M251.56 352.04V351.93" stroke="black" stroke-width="0.5" />
    <path d="M251.56 351.82V351.71" stroke="black" stroke-width="0.5" />
    <path d="M251.56 351.6V351.49" stroke="black" stroke-width="0.5" />
    <path d="M251.56 351.38V351.27" stroke="black" stroke-width="0.5" />
    <path d="M251.56 351.16V351.05" stroke="black" stroke-width="0.5" />
    <path d="M251.56 350.94V350.83" stroke="black" stroke-width="0.5" />
    <path d="M251.56 350.72V350.61" stroke="black" stroke-width="0.5" />
    <path d="M251.56 350.5V350.39" stroke="black" stroke-width="0.5" />
    <path d="M251.56 350.28V350.17" stroke="black" stroke-width="0.5" />
    <path d="M251.56 350.06V349.95" stroke="black" stroke-width="0.5" />
    <path d="M251.56 349.84V349.73" stroke="black" stroke-width="0.5" />
    <path d="M251.56 349.62V349.51" stroke="black" stroke-width="0.5" />
    <path d="M251.56 349.4V349.29" stroke="black" stroke-width="0.5" />
    <path d="M251.56 349.18V349.07" stroke="black" stroke-width="0.5" />
    <path d="M251.56 348.96V348.85" stroke="black" stroke-width="0.5" />
    <path d="M251.56 348.74V348.63" stroke="black" stroke-width="0.5" />
    <path d="M251.56 348.52V348.41" stroke="black" stroke-width="0.5" />
    <path d="M251.56 348.3V348.19" stroke="black" stroke-width="0.5" />
    <path d="M251.56 348.08V347.97" stroke="black" stroke-width="0.5" />
    <path d="M251.56 347.86V347.75" stroke="black" stroke-width="0.5" />
    <path d="M251.56 347.64V347.53" stroke="black" stroke-width="0.5" />
    <path d="M251.56 347.42V347.31" stroke="black" stroke-width="0.5" />
    <path d="M251.56 347.2V347.09" stroke="black" stroke-width="0.5" />
    <path d="M251.56 346.98V346.87" stroke="black" stroke-width="0.5" />
    <path d="M251.56 346.76V346.65" stroke="black" stroke-width="0.5" />
    <path d="M251.56 346.54V346.43" stroke="black" stroke-width="0.5" />
    <path d="M251.56 346.32V346.21" stroke="black" stroke-width="0.5" />
    <path d="M251.56 346.1V345.99" stroke="black" stroke-width="0.5" />
    <path d="M251.56 345.88V345.77" stroke="black" stroke-width="0.5" />
    <path d="M251.56 345.66V345.55" stroke="black" stroke-width="0.5" />
    <path d="M251.56 345.44V345.33" stroke="black" stroke-width="0.5" />
    <path d="M251.56 345.22V345.11" stroke="black" stroke-width="0.5" />
    <path d="M251.56 345V344.89" stroke="black" stroke-width="0.5" />
    <path d="M251.56 344.78V344.67" stroke="black" stroke-width="0.5" />
    <path d="M251.56 344.56V344.45" stroke="black" stroke-width="0.5" />
    <path d="M251.56 344.34V344.23" stroke="black" stroke-width="0.5" />
    <path d="M251.56 344.12V344.01" stroke="black" stroke-width="0.5" />
    <path d="M251.56 343.9V343.79" stroke="black" stroke-width="0.5" />
    <path d="M251.56 343.68V343.57" stroke="black" stroke-width="0.5" />
    <path d="M251.56 343.46V343.35" stroke="black" stroke-width="0.5" />
    <path d="M251.56 343.24V343.13" stroke="black" stroke-width="0.5" />
    <path d="M251.56 343.02V342.91" stroke="black" stroke-width="0.5" />
    <path d="M251.56 342.8V342.69" stroke="black" stroke-width="0.5" />
    <path d="M251.56 342.58V342.47" stroke="black" stroke-width="0.5" />
    <path d="M251.56 342.36V342.25" stroke="black" stroke-width="0.5" />
    <path d="M251.56 342.14V342.03" stroke="black" stroke-width="0.5" />
    <path d="M251.56 341.92V341.81" stroke="black" stroke-width="0.5" />
    <path d="M251.56 341.7V341.59" stroke="black" stroke-width="0.5" />
    <path d="M251.56 341.48V341.37" stroke="black" stroke-width="0.5" />
    <path d="M251.56 341.26V341.15" stroke="black" stroke-width="0.5" />
    <path d="M251.56 341.04V340.93" stroke="black" stroke-width="0.5" />
    <path d="M251.56 340.82V340.71" stroke="black" stroke-width="0.5" />
    <path d="M251.56 340.61V340.5" stroke="black" stroke-width="0.5" />
    <path d="M251.56 340.39V340.28" stroke="black" stroke-width="0.5" />
    <path d="M251.56 340.17V340.06" stroke="black" stroke-width="0.5" />
    <path d="M251.56 339.95V339.84" stroke="black" stroke-width="0.5" />
    <path d="M251.56 339.73V339.62" stroke="black" stroke-width="0.5" />
    <path d="M251.56 339.51V339.4" stroke="black" stroke-width="0.5" />
    <path d="M251.56 339.29V339.18" stroke="black" stroke-width="0.5" />
    <path d="M251.56 339.07V338.96" stroke="black" stroke-width="0.5" />
    <path d="M251.56 338.85V338.74" stroke="black" stroke-width="0.5" />
    <path d="M251.56 338.63V338.52" stroke="black" stroke-width="0.5" />
    <path d="M251.56 338.41V338.3" stroke="black" stroke-width="0.5" />
    <path d="M251.56 338.19V338.08" stroke="black" stroke-width="0.5" />
    <path d="M251.56 337.97V337.86" stroke="black" stroke-width="0.5" />
    <path d="M251.56 337.75V337.64" stroke="black" stroke-width="0.5" />
    <path d="M251.56 337.53V337.42" stroke="black" stroke-width="0.5" />
    <path d="M251.56 337.31V337.2" stroke="black" stroke-width="0.5" />
    <path d="M251.56 337.09V336.98" stroke="black" stroke-width="0.5" />
    <path d="M251.56 336.87V336.76" stroke="black" stroke-width="0.5" />
    <path d="M251.56 336.65V336.54" stroke="black" stroke-width="0.5" />
    <path d="M251.56 336.43V336.32" stroke="black" stroke-width="0.5" />
    <path d="M251.56 336.21V336.1" stroke="black" stroke-width="0.5" />
    <path d="M251.56 335.99V335.88" stroke="black" stroke-width="0.5" />
    <path d="M251.56 335.77V335.66" stroke="black" stroke-width="0.5" />
    <path d="M251.56 335.55V335.44" stroke="black" stroke-width="0.5" />
    <path d="M251.56 335.33V335.22" stroke="black" stroke-width="0.5" />
    <path d="M251.56 335.11V335" stroke="black" stroke-width="0.5" />
    <path d="M251.56 334.89V334.78" stroke="black" stroke-width="0.5" />
    <path d="M251.56 334.67V334.56" stroke="black" stroke-width="0.5" />
    <path d="M251.56 334.45V334.34" stroke="black" stroke-width="0.5" />
    <path d="M251.56 334.23V334.12" stroke="black" stroke-width="0.5" />
    <path d="M251.56 334.01V333.9" stroke="black" stroke-width="0.5" />
    <path d="M251.56 333.79V333.68" stroke="black" stroke-width="0.5" />
    <path d="M251.56 333.57V333.46" stroke="black" stroke-width="0.5" />
    <path d="M251.56 333.35V333.24" stroke="black" stroke-width="0.5" />
    <path d="M251.56 333.13V333.02" stroke="black" stroke-width="0.5" />
    <path d="M251.56 332.91V332.8" stroke="black" stroke-width="0.5" />
    <path d="M251.56 332.69V332.58" stroke="black" stroke-width="0.5" />
    <path d="M251.56 332.47V332.36" stroke="black" stroke-width="0.5" />
    <path d="M251.56 332.25V332.14" stroke="black" stroke-width="0.5" />
    <path d="M251.56 332.03V331.92" stroke="black" stroke-width="0.5" />
    <path d="M251.56 331.81V331.7" stroke="black" stroke-width="0.5" />
    <path d="M251.56 331.59V331.48" stroke="black" stroke-width="0.5" />
    <path d="M251.56 331.37V331.26" stroke="black" stroke-width="0.5" />
    <path d="M251.56 331.15V331.04" stroke="black" stroke-width="0.5" />
    <path d="M251.56 330.93V330.82" stroke="black" stroke-width="0.5" />
    <path d="M251.56 330.71V330.6" stroke="black" stroke-width="0.5" />
    <path d="M251.56 330.49V330.38" stroke="black" stroke-width="0.5" />
    <path d="M251.56 330.27V330.16" stroke="black" stroke-width="0.5" />
    <path d="M251.56 330.05V329.94" stroke="black" stroke-width="0.5" />
    <path d="M251.56 329.83V329.72" stroke="black" stroke-width="0.5" />
    <path d="M251.56 329.61V329.5" stroke="black" stroke-width="0.5" />
    <path d="M251.56 329.39V329.28" stroke="black" stroke-width="0.5" />
    <path d="M251.56 329.17V329.06" stroke="black" stroke-width="0.5" />
    <path d="M251.56 328.95V328.84" stroke="black" stroke-width="0.5" />
    <path d="M251.56 328.73V328.62" stroke="black" stroke-width="0.5" />
    <path d="M251.56 328.51V328.4" stroke="black" stroke-width="0.5" />
    <path d="M251.56 328.29V328.18" stroke="black" stroke-width="0.5" />
    <path d="M251.56 328.07V327.96" stroke="black" stroke-width="0.5" />
    <path d="M251.56 327.85V327.74" stroke="black" stroke-width="0.5" />
    <path d="M251.56 327.63V327.52" stroke="black" stroke-width="0.5" />
    <path d="M251.56 327.41V327.3" stroke="black" stroke-width="0.5" />
    <path d="M251.56 327.19V327.08" stroke="black" stroke-width="0.5" />
    <path d="M251.56 326.97V326.86" stroke="black" stroke-width="0.5" />
    <path d="M251.56 326.75V326.64" stroke="black" stroke-width="0.5" />
    <path d="M251.56 326.53V326.42" stroke="black" stroke-width="0.5" />
    <path d="M251.56 326.31V326.2" stroke="black" stroke-width="0.5" />
    <path d="M251.56 326.09V325.98" stroke="black" stroke-width="0.5" />
    <path d="M251.56 325.87V325.76" stroke="black" stroke-width="0.5" />
    <path d="M251.56 325.65V325.54" stroke="black" stroke-width="0.5" />
    <path d="M251.56 325.43V325.32" stroke="black" stroke-width="0.5" />
    <path d="M251.56 325.21V325.1" stroke="black" stroke-width="0.5" />
    <path d="M251.56 324.99V324.88" stroke="black" stroke-width="0.5" />
    <path d="M251.56 324.77V324.66" stroke="black" stroke-width="0.5" />
    <path d="M251.56 324.55V324.44" stroke="black" stroke-width="0.5" />
    <path d="M251.56 324.33V324.22" stroke="black" stroke-width="0.5" />
    <path d="M251.56 324.11V324" stroke="black" stroke-width="0.5" />
    <path d="M251.56 323.89V323.78" stroke="black" stroke-width="0.5" />
    <path d="M251.56 323.67V323.56" stroke="black" stroke-width="0.5" />
    <path d="M251.56 323.45V323.34" stroke="black" stroke-width="0.5" />
    <path d="M251.56 323.23V323.12" stroke="black" stroke-width="0.5" />
    <path d="M251.56 323.01V322.9" stroke="black" stroke-width="0.5" />
    <path d="M251.56 322.79V322.68" stroke="black" stroke-width="0.5" />
    <path d="M251.56 322.57V322.46" stroke="black" stroke-width="0.5" />
    <path d="M251.56 322.35V322.24" stroke="black" stroke-width="0.5" />
    <path d="M251.56 322.13V322.02" stroke="black" stroke-width="0.5" />
    <path d="M251.56 321.91V321.8" stroke="black" stroke-width="0.5" />
    <path d="M251.56 321.69V321.58" stroke="black" stroke-width="0.5" />
    <path d="M251.56 321.47V321.36" stroke="black" stroke-width="0.5" />
    <path d="M251.56 321.25V321.14" stroke="black" stroke-width="0.5" />
    <path d="M251.56 321.03V320.92" stroke="black" stroke-width="0.5" />
    <path d="M251.56 320.81V320.7" stroke="black" stroke-width="0.5" />
    <path d="M251.56 320.59V320.48" stroke="black" stroke-width="0.5" />
    <path d="M251.56 320.37V320.26" stroke="black" stroke-width="0.5" />
    <path d="M251.56 320.15V320.04" stroke="black" stroke-width="0.5" />
    <path d="M251.56 319.93V319.82" stroke="black" stroke-width="0.5" />
    <path d="M251.56 319.71V319.6" stroke="black" stroke-width="0.5" />
    <path d="M251.56 319.49V319.38" stroke="black" stroke-width="0.5" />
    <path d="M251.56 319.27V319.16" stroke="black" stroke-width="0.5" />
    <path d="M251.56 319.05V318.94" stroke="black" stroke-width="0.5" />
    <path d="M251.56 318.83V318.72" stroke="black" stroke-width="0.5" />
    <path d="M251.56 318.61V318.5" stroke="black" stroke-width="0.5" />
    <path d="M251.56 318.39V318.28" stroke="black" stroke-width="0.5" />
    <path d="M251.56 318.17V318.06" stroke="black" stroke-width="0.5" />
    <path d="M251.56 317.95V317.84" stroke="black" stroke-width="0.5" />
    <path d="M251.56 317.73V317.62" stroke="black" stroke-width="0.5" />
    <path d="M251.56 317.51V317.4" stroke="black" stroke-width="0.5" />
    <path d="M251.56 317.29V317.18" stroke="black" stroke-width="0.5" />
    <path d="M251.56 317.07V316.96" stroke="black" stroke-width="0.5" />
    <path d="M251.56 316.85V316.74" stroke="black" stroke-width="0.5" />
    <path d="M251.56 316.63V316.52" stroke="black" stroke-width="0.5" />
    <path d="M251.56 316.41V316.3" stroke="black" stroke-width="0.5" />
    <path d="M251.56 316.19V316.08" stroke="black" stroke-width="0.5" />
    <path d="M251.56 315.97V315.86" stroke="black" stroke-width="0.5" />
    <path d="M251.56 315.75V315.64" stroke="black" stroke-width="0.5" />
    <path d="M251.56 315.53V315.42" stroke="black" stroke-width="0.5" />
    <path d="M251.56 315.31V315.2" stroke="black" stroke-width="0.5" />
    <path d="M251.56 315.09V314.98" stroke="black" stroke-width="0.5" />
    <path d="M251.56 314.87V314.77" stroke="black" stroke-width="0.5" />
    <path d="M251.56 314.66V314.55" stroke="black" stroke-width="0.5" />
    <path d="M251.56 314.44V314.33" stroke="black" stroke-width="0.5" />
    <path d="M251.56 314.22V314.11" stroke="black" stroke-width="0.5" />
    <path d="M251.56 314V313.89" stroke="black" stroke-width="0.5" />
    <path d="M251.56 313.78V313.67" stroke="black" stroke-width="0.5" />
    <path d="M251.56 313.56V313.45" stroke="black" stroke-width="0.5" />
    <path d="M251.56 313.34V313.23" stroke="black" stroke-width="0.5" />
    <path d="M251.56 313.12V313.01" stroke="black" stroke-width="0.5" />
    <path d="M251.56 312.9V312.79" stroke="black" stroke-width="0.5" />
    <path d="M251.56 312.68V312.57" stroke="black" stroke-width="0.5" />
    <path d="M251.56 312.46V312.35" stroke="black" stroke-width="0.5" />
    <path d="M251.56 312.24V312.13" stroke="black" stroke-width="0.5" />
    <path d="M251.56 312.02V311.91" stroke="black" stroke-width="0.5" />
    <path d="M251.56 311.8V311.69" stroke="black" stroke-width="0.5" />
    <path d="M251.56 311.58V311.47" stroke="black" stroke-width="0.5" />
    <path d="M251.56 311.36V311.25" stroke="black" stroke-width="0.5" />
    <path d="M251.56 311.14V311.03" stroke="black" stroke-width="0.5" />
    <path d="M251.56 310.92V310.81" stroke="black" stroke-width="0.5" />
    <path d="M251.56 310.7V310.59" stroke="black" stroke-width="0.5" />
    <path d="M251.56 310.48V310.37" stroke="black" stroke-width="0.5" />
    <path d="M251.56 310.26V310.2" stroke="black" stroke-width="0.5" />
    <path d="M304.34 359.46H304.29" stroke="black" stroke-width="0.5" />
    <path d="M304.18 359.46H304.07" stroke="black" stroke-width="0.5" />
    <path d="M303.96 359.46H303.85" stroke="black" stroke-width="0.5" />
    <path d="M303.74 359.46H303.63" stroke="black" stroke-width="0.5" />
    <path d="M303.52 359.46H303.41" stroke="black" stroke-width="0.5" />
    <path d="M303.3 359.46H303.19" stroke="black" stroke-width="0.5" />
    <path d="M303.08 359.46H302.97" stroke="black" stroke-width="0.5" />
    <path d="M302.86 359.46H302.75" stroke="black" stroke-width="0.5" />
    <path d="M302.64 359.46H302.53" stroke="black" stroke-width="0.5" />
    <path d="M302.42 359.46H302.31" stroke="black" stroke-width="0.5" />
    <path d="M302.2 359.46H302.09" stroke="black" stroke-width="0.5" />
    <path d="M301.98 359.46H301.87" stroke="black" stroke-width="0.5" />
    <path d="M301.76 359.46H301.65" stroke="black" stroke-width="0.5" />
    <path d="M301.54 359.46H301.43" stroke="black" stroke-width="0.5" />
    <path d="M301.32 359.46H301.21" stroke="black" stroke-width="0.5" />
    <path d="M301.1 359.46H300.99" stroke="black" stroke-width="0.5" />
    <path d="M300.88 359.46H300.77" stroke="black" stroke-width="0.5" />
    <path d="M300.66 359.46H300.55" stroke="black" stroke-width="0.5" />
    <path d="M300.44 359.46H300.33" stroke="black" stroke-width="0.5" />
    <path d="M300.22 359.46H300.11" stroke="black" stroke-width="0.5" />
    <path d="M300 359.46H299.89" stroke="black" stroke-width="0.5" />
    <path d="M299.78 359.46H299.67" stroke="black" stroke-width="0.5" />
    <path d="M299.56 359.46H299.45" stroke="black" stroke-width="0.5" />
    <path d="M299.34 359.46H299.23" stroke="black" stroke-width="0.5" />
    <path d="M299.12 359.46H299.01" stroke="black" stroke-width="0.5" />
    <path d="M298.9 359.46H298.79" stroke="black" stroke-width="0.5" />
    <path d="M298.68 359.46H298.57" stroke="black" stroke-width="0.5" />
    <path d="M298.46 359.46H298.35" stroke="black" stroke-width="0.5" />
    <path d="M298.24 359.46H298.13" stroke="black" stroke-width="0.5" />
    <path d="M298.02 359.46H297.91" stroke="black" stroke-width="0.5" />
    <path d="M297.8 359.46H297.69" stroke="black" stroke-width="0.5" />
    <path d="M297.58 359.46H297.47" stroke="black" stroke-width="0.5" />
    <path d="M297.36 359.46H297.25" stroke="black" stroke-width="0.5" />
    <path d="M297.14 359.46H297.03" stroke="black" stroke-width="0.5" />
    <path d="M296.92 359.46H296.81" stroke="black" stroke-width="0.5" />
    <path d="M296.7 359.46H296.59" stroke="black" stroke-width="0.5" />
    <path d="M296.48 359.46H296.37" stroke="black" stroke-width="0.5" />
    <path d="M296.26 359.46H296.15" stroke="black" stroke-width="0.5" />
    <path d="M296.04 359.46H295.93" stroke="black" stroke-width="0.5" />
    <path d="M295.82 359.46H295.71" stroke="black" stroke-width="0.5" />
    <path d="M295.6 359.46H295.49" stroke="black" stroke-width="0.5" />
    <path d="M295.38 359.46H295.27" stroke="black" stroke-width="0.5" />
    <path d="M295.16 359.46H295.05" stroke="black" stroke-width="0.5" />
    <path d="M294.94 359.46H294.83" stroke="black" stroke-width="0.5" />
    <path d="M294.72 359.46H294.61" stroke="black" stroke-width="0.5" />
    <path d="M294.5 359.46H294.39" stroke="black" stroke-width="0.5" />
    <path d="M294.28 359.46H294.17" stroke="black" stroke-width="0.5" />
    <path d="M294.06 359.46H293.95" stroke="black" stroke-width="0.5" />
    <path d="M293.84 359.46H293.73" stroke="black" stroke-width="0.5" />
    <path d="M293.62 359.46H293.51" stroke="black" stroke-width="0.5" />
    <path d="M293.4 359.46H293.29" stroke="black" stroke-width="0.5" />
    <path d="M293.18 359.46H293.07" stroke="black" stroke-width="0.5" />
    <path d="M292.96 359.46H292.85" stroke="black" stroke-width="0.5" />
    <path d="M292.74 359.46H292.63" stroke="black" stroke-width="0.5" />
    <path d="M292.52 359.46H292.41" stroke="black" stroke-width="0.5" />
    <path d="M292.3 359.46H292.19" stroke="black" stroke-width="0.5" />
    <path d="M292.08 359.46H291.97" stroke="black" stroke-width="0.5" />
    <path d="M291.86 359.46H291.75" stroke="black" stroke-width="0.5" />
    <path d="M291.64 359.46H291.53" stroke="black" stroke-width="0.5" />
    <path d="M291.42 359.46H291.31" stroke="black" stroke-width="0.5" />
    <path d="M291.2 359.46H291.09" stroke="black" stroke-width="0.5" />
    <path d="M290.98 359.46H290.87" stroke="black" stroke-width="0.5" />
    <path d="M290.76 359.46H290.65" stroke="black" stroke-width="0.5" />
    <path d="M290.54 359.46H290.43" stroke="black" stroke-width="0.5" />
    <path d="M290.32 359.46H290.21" stroke="black" stroke-width="0.5" />
    <path d="M290.1 359.46H289.99" stroke="black" stroke-width="0.5" />
    <path d="M289.88 359.46H289.77" stroke="black" stroke-width="0.5" />
    <path d="M289.66 359.46H289.55" stroke="black" stroke-width="0.5" />
    <path d="M289.44 359.46H289.33" stroke="black" stroke-width="0.5" />
    <path d="M289.22 359.46H289.11" stroke="black" stroke-width="0.5" />
    <path d="M289 359.46H288.89" stroke="black" stroke-width="0.5" />
    <path d="M288.78 359.46H288.67" stroke="black" stroke-width="0.5" />
    <path d="M288.56 359.46H288.45" stroke="black" stroke-width="0.5" />
    <path d="M288.34 359.46H288.23" stroke="black" stroke-width="0.5" />
    <path d="M288.12 359.46H288.01" stroke="black" stroke-width="0.5" />
    <path d="M287.9 359.46H287.79" stroke="black" stroke-width="0.5" />
    <path d="M287.68 359.46H287.57" stroke="black" stroke-width="0.5" />
    <path d="M287.46 359.46H287.35" stroke="black" stroke-width="0.5" />
    <path d="M287.24 359.46H287.13" stroke="black" stroke-width="0.5" />
    <path d="M287.02 359.46H286.91" stroke="black" stroke-width="0.5" />
    <path d="M286.8 359.46H286.69" stroke="black" stroke-width="0.5" />
    <path d="M286.58 359.46H286.47" stroke="black" stroke-width="0.5" />
    <path d="M286.36 359.46H286.25" stroke="black" stroke-width="0.5" />
    <path d="M286.14 359.46H286.03" stroke="black" stroke-width="0.5" />
    <path d="M285.92 359.46H285.81" stroke="black" stroke-width="0.5" />
    <path d="M285.7 359.46H285.59" stroke="black" stroke-width="0.5" />
    <path d="M285.48 359.46H285.37" stroke="black" stroke-width="0.5" />
    <path d="M285.26 359.46H285.15" stroke="black" stroke-width="0.5" />
    <path d="M285.04 359.46H284.93" stroke="black" stroke-width="0.5" />
    <path d="M284.82 359.46H284.71" stroke="black" stroke-width="0.5" />
    <path d="M284.6 359.46H284.49" stroke="black" stroke-width="0.5" />
    <path d="M284.38 359.46H284.27" stroke="black" stroke-width="0.5" />
    <path d="M284.16 359.46H284.05" stroke="black" stroke-width="0.5" />
    <path d="M283.94 359.46H283.83" stroke="black" stroke-width="0.5" />
    <path d="M283.72 359.46H283.61" stroke="black" stroke-width="0.5" />
    <path d="M283.5 359.46H283.39" stroke="black" stroke-width="0.5" />
    <path d="M283.28 359.46H283.17" stroke="black" stroke-width="0.5" />
    <path d="M283.06 359.46H282.95" stroke="black" stroke-width="0.5" />
    <path d="M282.84 359.46H282.73" stroke="black" stroke-width="0.5" />
    <path d="M282.62 359.46H282.51" stroke="black" stroke-width="0.5" />
    <path d="M282.4 359.46H282.29" stroke="black" stroke-width="0.5" />
    <path d="M282.18 359.46H282.07" stroke="black" stroke-width="0.5" />
    <path d="M281.96 359.46H281.86" stroke="black" stroke-width="0.5" />
    <path d="M281.75 359.46H281.64" stroke="black" stroke-width="0.5" />
    <path d="M281.53 359.46H281.42" stroke="black" stroke-width="0.5" />
    <path d="M281.31 359.46H281.2" stroke="black" stroke-width="0.5" />
    <path d="M281.09 359.46H280.98" stroke="black" stroke-width="0.5" />
    <path d="M280.87 359.46H280.76" stroke="black" stroke-width="0.5" />
    <path d="M280.65 359.46H280.54" stroke="black" stroke-width="0.5" />
    <path d="M280.43 359.46H280.32" stroke="black" stroke-width="0.5" />
    <path d="M280.21 359.46H280.1" stroke="black" stroke-width="0.5" />
    <path d="M279.99 359.46H279.88" stroke="black" stroke-width="0.5" />
    <path d="M279.77 359.46H279.66" stroke="black" stroke-width="0.5" />
    <path d="M279.55 359.46H279.44" stroke="black" stroke-width="0.5" />
    <path d="M279.33 359.46H279.22" stroke="black" stroke-width="0.5" />
    <path d="M279.11 359.46H279" stroke="black" stroke-width="0.5" />
    <path d="M278.89 359.46H278.78" stroke="black" stroke-width="0.5" />
    <path d="M278.67 359.46H278.56" stroke="black" stroke-width="0.5" />
    <path d="M278.45 359.46H278.34" stroke="black" stroke-width="0.5" />
    <path d="M278.23 359.46H278.12" stroke="black" stroke-width="0.5" />
    <path d="M278.01 359.46H277.9" stroke="black" stroke-width="0.5" />
    <path d="M277.79 359.46H277.68" stroke="black" stroke-width="0.5" />
    <path d="M277.57 359.46H277.46" stroke="black" stroke-width="0.5" />
    <path d="M277.35 359.46H277.24" stroke="black" stroke-width="0.5" />
    <path d="M277.13 359.46H277.02" stroke="black" stroke-width="0.5" />
    <path d="M276.91 359.46H276.8" stroke="black" stroke-width="0.5" />
    <path d="M276.69 359.46H276.58" stroke="black" stroke-width="0.5" />
    <path d="M276.47 359.46H276.36" stroke="black" stroke-width="0.5" />
    <path d="M276.25 359.46H276.14" stroke="black" stroke-width="0.5" />
    <path d="M276.03 359.46H275.92" stroke="black" stroke-width="0.5" />
    <path d="M275.81 359.46H275.7" stroke="black" stroke-width="0.5" />
    <path d="M275.59 359.46H275.48" stroke="black" stroke-width="0.5" />
    <path d="M275.37 359.46H275.26" stroke="black" stroke-width="0.5" />
    <path d="M275.15 359.46H275.04" stroke="black" stroke-width="0.5" />
    <path d="M274.93 359.46H274.82" stroke="black" stroke-width="0.5" />
    <path d="M274.71 359.46H274.6" stroke="black" stroke-width="0.5" />
    <path d="M274.49 359.46H274.38" stroke="black" stroke-width="0.5" />
    <path d="M274.27 359.46H274.16" stroke="black" stroke-width="0.5" />
    <path d="M274.05 359.46H273.94" stroke="black" stroke-width="0.5" />
    <path d="M273.83 359.46H273.72" stroke="black" stroke-width="0.5" />
    <path d="M273.61 359.46H273.5" stroke="black" stroke-width="0.5" />
    <path d="M273.39 359.46H273.28" stroke="black" stroke-width="0.5" />
    <path d="M273.17 359.46H273.06" stroke="black" stroke-width="0.5" />
    <path d="M272.95 359.46H272.84" stroke="black" stroke-width="0.5" />
    <path d="M272.73 359.46H272.62" stroke="black" stroke-width="0.5" />
    <path d="M272.51 359.46H272.4" stroke="black" stroke-width="0.5" />
    <path d="M272.29 359.46H272.18" stroke="black" stroke-width="0.5" />
    <path d="M272.07 359.46H271.96" stroke="black" stroke-width="0.5" />
    <path d="M271.85 359.46H271.74" stroke="black" stroke-width="0.5" />
    <path d="M271.63 359.46H271.52" stroke="black" stroke-width="0.5" />
    <path d="M271.41 359.46H271.3" stroke="black" stroke-width="0.5" />
    <path d="M271.19 359.46H271.08" stroke="black" stroke-width="0.5" />
    <path d="M270.97 359.46H270.86" stroke="black" stroke-width="0.5" />
    <path d="M270.75 359.46H270.64" stroke="black" stroke-width="0.5" />
    <path d="M270.53 359.46H270.42" stroke="black" stroke-width="0.5" />
    <path d="M270.31 359.46H270.2" stroke="black" stroke-width="0.5" />
    <path d="M270.09 359.46H269.98" stroke="black" stroke-width="0.5" />
    <path d="M269.87 359.46H269.76" stroke="black" stroke-width="0.5" />
    <path d="M269.65 359.46H269.54" stroke="black" stroke-width="0.5" />
    <path d="M269.43 359.46H269.32" stroke="black" stroke-width="0.5" />
    <path d="M269.21 359.46H269.1" stroke="black" stroke-width="0.5" />
    <path d="M268.99 359.46H268.88" stroke="black" stroke-width="0.5" />
    <path d="M268.77 359.46H268.66" stroke="black" stroke-width="0.5" />
    <path d="M268.55 359.46H268.44" stroke="black" stroke-width="0.5" />
    <path d="M268.33 359.46H268.22" stroke="black" stroke-width="0.5" />
    <path d="M268.11 359.46H268" stroke="black" stroke-width="0.5" />
    <path d="M267.89 359.46H267.78" stroke="black" stroke-width="0.5" />
    <path d="M267.67 359.46H267.56" stroke="black" stroke-width="0.5" />
    <path d="M267.45 359.46H267.34" stroke="black" stroke-width="0.5" />
    <path d="M267.23 359.46H267.12" stroke="black" stroke-width="0.5" />
    <path d="M267.01 359.46H266.9" stroke="black" stroke-width="0.5" />
    <path d="M266.79 359.46H266.68" stroke="black" stroke-width="0.5" />
    <path d="M266.57 359.46H266.46" stroke="black" stroke-width="0.5" />
    <path d="M266.35 359.46H266.24" stroke="black" stroke-width="0.5" />
    <path d="M266.13 359.46H266.02" stroke="black" stroke-width="0.5" />
    <path d="M265.91 359.46H265.8" stroke="black" stroke-width="0.5" />
    <path d="M265.69 359.46H265.58" stroke="black" stroke-width="0.5" />
    <path d="M265.47 359.46H265.36" stroke="black" stroke-width="0.5" />
    <path d="M265.25 359.46H265.14" stroke="black" stroke-width="0.5" />
    <path d="M265.03 359.46H264.92" stroke="black" stroke-width="0.5" />
    <path d="M264.81 359.46H264.7" stroke="black" stroke-width="0.5" />
    <path d="M264.59 359.46H264.48" stroke="black" stroke-width="0.5" />
    <path d="M264.37 359.46H264.26" stroke="black" stroke-width="0.5" />
    <path d="M264.15 359.46H264.04" stroke="black" stroke-width="0.5" />
    <path d="M263.93 359.46H263.82" stroke="black" stroke-width="0.5" />
    <path d="M263.71 359.46H263.6" stroke="black" stroke-width="0.5" />
    <path d="M263.49 359.46H263.38" stroke="black" stroke-width="0.5" />
    <path d="M263.27 359.46H263.16" stroke="black" stroke-width="0.5" />
    <path d="M263.05 359.46H262.94" stroke="black" stroke-width="0.5" />
    <path d="M262.83 359.46H262.72" stroke="black" stroke-width="0.5" />
    <path d="M262.61 359.46H262.5" stroke="black" stroke-width="0.5" />
    <path d="M262.39 359.46H262.28" stroke="black" stroke-width="0.5" />
    <path d="M262.17 359.46H262.06" stroke="black" stroke-width="0.5" />
    <path d="M261.95 359.46H261.84" stroke="black" stroke-width="0.5" />
    <path d="M261.73 359.46H261.62" stroke="black" stroke-width="0.5" />
    <path d="M261.51 359.46H261.4" stroke="black" stroke-width="0.5" />
    <path d="M261.29 359.46H261.18" stroke="black" stroke-width="0.5" />
    <path d="M261.07 359.46H260.96" stroke="black" stroke-width="0.5" />
    <path d="M260.85 359.46H260.74" stroke="black" stroke-width="0.5" />
    <path d="M260.63 359.46H260.52" stroke="black" stroke-width="0.5" />
    <path d="M260.41 359.46H260.3" stroke="black" stroke-width="0.5" />
    <path d="M260.19 359.46H260.08" stroke="black" stroke-width="0.5" />
    <path d="M259.97 359.46H259.86" stroke="black" stroke-width="0.5" />
    <path d="M259.75 359.46H259.64" stroke="black" stroke-width="0.5" />
    <path d="M259.53 359.46H259.42" stroke="black" stroke-width="0.5" />
    <path d="M259.31 359.46H259.2" stroke="black" stroke-width="0.5" />
    <path d="M259.09 359.46H258.98" stroke="black" stroke-width="0.5" />
    <path d="M258.87 359.46H258.76" stroke="black" stroke-width="0.5" />
    <path d="M258.65 359.46H258.54" stroke="black" stroke-width="0.5" />
    <path d="M258.43 359.46H258.32" stroke="black" stroke-width="0.5" />
    <path d="M258.21 359.46H258.1" stroke="black" stroke-width="0.5" />
    <path d="M257.99 359.46H257.88" stroke="black" stroke-width="0.5" />
    <path d="M257.77 359.46H257.66" stroke="black" stroke-width="0.5" />
    <path d="M257.55 359.46H257.44" stroke="black" stroke-width="0.5" />
    <path d="M257.33 359.46H257.22" stroke="black" stroke-width="0.5" />
    <path d="M257.11 359.46H257" stroke="black" stroke-width="0.5" />
    <path d="M256.89 359.46H256.78" stroke="black" stroke-width="0.5" />
    <path d="M256.67 359.46H256.56" stroke="black" stroke-width="0.5" />
    <path d="M256.45 359.46H256.34" stroke="black" stroke-width="0.5" />
    <path d="M256.23 359.46H256.12" stroke="black" stroke-width="0.5" />
    <path d="M256.02 359.46H255.91" stroke="black" stroke-width="0.5" />
    <path d="M255.8 359.46H255.69" stroke="black" stroke-width="0.5" />
    <path d="M255.58 359.46H255.47" stroke="black" stroke-width="0.5" />
    <path d="M255.36 359.46H255.25" stroke="black" stroke-width="0.5" />
    <path d="M255.14 359.46H255.03" stroke="black" stroke-width="0.5" />
    <path d="M254.92 359.46H254.81" stroke="black" stroke-width="0.5" />
    <path d="M254.7 359.46H254.59" stroke="black" stroke-width="0.5" />
    <path d="M254.48 359.46H254.37" stroke="black" stroke-width="0.5" />
    <path d="M254.26 359.46H254.15" stroke="black" stroke-width="0.5" />
    <path d="M254.04 359.46H253.93" stroke="black" stroke-width="0.5" />
    <path d="M253.82 359.46H253.71" stroke="black" stroke-width="0.5" />
    <path d="M253.6 359.46H253.49" stroke="black" stroke-width="0.5" />
    <path d="M253.38 359.46H253.27" stroke="black" stroke-width="0.5" />
    <path d="M253.16 359.46H253.05" stroke="black" stroke-width="0.5" />
    <path d="M252.94 359.46H252.83" stroke="black" stroke-width="0.5" />
    <path d="M252.72 359.46H252.61" stroke="black" stroke-width="0.5" />
    <path d="M252.5 359.46H252.39" stroke="black" stroke-width="0.5" />
    <path d="M252.28 359.46H252.17" stroke="black" stroke-width="0.5" />
    <path d="M252.06 359.46H251.95" stroke="black" stroke-width="0.5" />
    <path d="M251.84 359.46H251.73" stroke="black" stroke-width="0.5" />
    <path d="M251.62 359.46H251.56" stroke="black" stroke-width="0.5" />
    <path d="M286.75 315.49H290.27" stroke="black" stroke-width="0.5" />
    <path
      d="M287.15 333.82C288.045 333.996 288.965 333.996 289.86 333.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M294.23 326.92C294.228 324.813 293.563 322.759 292.33 321.05"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M284.68 321.05C283.45 322.76 282.789 324.814 282.79 326.92"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M292.33 321.05C291.258 320.151 289.904 319.658 288.505 319.658C287.106 319.658 285.752 320.151 284.68 321.05"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M285.45 335.16C285.795 335.184 286.139 335.096 286.43 334.909C286.72 334.721 286.943 334.445 287.063 334.121C287.183 333.796 287.196 333.442 287.098 333.11C287 332.778 286.797 332.487 286.52 332.28"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M290.49 332.28C290.213 332.487 290.01 332.778 289.912 333.11C289.814 333.442 289.826 333.796 289.947 334.121C290.067 334.445 290.29 334.721 290.581 334.909C290.871 335.096 291.215 335.184 291.56 335.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M290.49 332.28C291.586 331.876 292.533 331.145 293.201 330.187C293.87 329.229 294.229 328.089 294.23 326.92"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M282.79 326.92C282.79 328.087 283.148 329.227 283.815 330.185C284.481 331.143 285.425 331.874 286.52 332.28"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 335.71C290.841 335.708 293.075 334.781 294.723 333.133C296.371 331.485 297.298 329.251 297.3 326.92"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M297.3 326.92C297.304 324.389 296.685 321.896 295.5 319.66"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M295.5 319.66C294.739 318.232 293.657 317 292.34 316.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M292.34 316.06C291.934 315.766 291.465 315.571 290.97 315.49"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M290.27 315.49H290.97" stroke="black" stroke-width="0.5" />
    <path d="M290.27 312.95V315.49" stroke="black" stroke-width="0.5" />
    <path
      d="M287.62 311.32C287.397 311.45 287.206 311.628 287.06 311.84"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.96 311.84C289.811 311.627 289.616 311.449 289.39 311.32"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M286.75 315.49V312.95" stroke="black" stroke-width="0.5" />
    <path d="M286.05 315.49H286.75" stroke="black" stroke-width="0.5" />
    <path
      d="M286.05 315.49C285.552 315.57 285.079 315.765 284.67 316.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M284.67 316.06C283.357 317.001 282.279 318.233 281.52 319.66"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M281.52 319.66C280.331 321.895 279.71 324.388 279.71 326.92"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M279.71 326.92C279.712 329.252 280.64 331.489 282.29 333.137C283.94 334.785 286.178 335.711 288.51 335.71"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M288.51 313.72H288.54" stroke="black" stroke-width="0.5" />
    <path d="M288.54 313.72H288.58" stroke="black" stroke-width="0.5" />
    <path d="M288.58 313.72L288.61 313.71" stroke="black" stroke-width="0.5" />
    <path d="M288.61 313.71H288.65" stroke="black" stroke-width="0.5" />
    <path d="M288.65 313.71L288.68 313.7" stroke="black" stroke-width="0.5" />
    <path d="M288.68 313.7H288.72" stroke="black" stroke-width="0.5" />
    <path d="M288.72 313.7L288.75 313.69" stroke="black" stroke-width="0.5" />
    <path d="M288.75 313.69L288.78 313.68" stroke="black" stroke-width="0.5" />
    <path d="M288.78 313.68L288.82 313.66" stroke="black" stroke-width="0.5" />
    <path d="M288.82 313.66L288.85 313.65" stroke="black" stroke-width="0.5" />
    <path d="M288.85 313.65L288.88 313.64" stroke="black" stroke-width="0.5" />
    <path d="M288.88 313.64L288.91 313.62" stroke="black" stroke-width="0.5" />
    <path d="M288.91 313.62L288.94 313.6" stroke="black" stroke-width="0.5" />
    <path d="M288.94 313.6L288.97 313.59" stroke="black" stroke-width="0.5" />
    <path d="M288.97 313.59L289 313.57" stroke="black" stroke-width="0.5" />
    <path d="M289 313.57L289.03 313.55" stroke="black" stroke-width="0.5" />
    <path d="M289.03 313.55L289.06 313.52" stroke="black" stroke-width="0.5" />
    <path d="M289.06 313.52L289.09 313.5" stroke="black" stroke-width="0.5" />
    <path d="M289.09 313.5L289.11 313.48" stroke="black" stroke-width="0.5" />
    <path d="M289.11 313.48L289.14 313.45" stroke="black" stroke-width="0.5" />
    <path d="M289.14 313.45L289.16 313.43" stroke="black" stroke-width="0.5" />
    <path d="M289.16 313.43L289.19 313.4" stroke="black" stroke-width="0.5" />
    <path d="M289.19 313.4L289.21 313.37" stroke="black" stroke-width="0.5" />
    <path d="M289.21 313.37L289.23 313.35" stroke="black" stroke-width="0.5" />
    <path d="M289.23 313.35L289.25 313.32" stroke="black" stroke-width="0.5" />
    <path d="M289.25 313.32L289.27 313.29" stroke="black" stroke-width="0.5" />
    <path d="M289.27 313.29L289.28 313.26" stroke="black" stroke-width="0.5" />
    <path d="M289.28 313.26L289.3 313.22" stroke="black" stroke-width="0.5" />
    <path d="M289.3 313.22L289.31 313.19" stroke="black" stroke-width="0.5" />
    <path d="M289.31 313.19L289.33 313.16" stroke="black" stroke-width="0.5" />
    <path d="M289.33 313.16L289.34 313.13" stroke="black" stroke-width="0.5" />
    <path d="M289.34 313.13L289.35 313.09" stroke="black" stroke-width="0.5" />
    <path d="M289.35 313.09L289.36 313.06" stroke="black" stroke-width="0.5" />
    <path d="M289.36 313.06L289.37 313.02" stroke="black" stroke-width="0.5" />
    <path d="M289.37 313.02V312.99" stroke="black" stroke-width="0.5" />
    <path d="M289.37 312.99L289.38 312.96" stroke="black" stroke-width="0.5" />
    <path d="M287.63 312.95L287.64 312.97" stroke="black" stroke-width="0.5" />
    <path d="M287.64 312.97V313.01" stroke="black" stroke-width="0.5" />
    <path d="M287.64 313.01L287.65 313.04" stroke="black" stroke-width="0.5" />
    <path d="M287.65 313.04L287.66 313.07" stroke="black" stroke-width="0.5" />
    <path d="M287.66 313.07L287.67 313.11" stroke="black" stroke-width="0.5" />
    <path d="M287.67 313.11L287.68 313.14" stroke="black" stroke-width="0.5" />
    <path d="M287.68 313.14L287.69 313.17" stroke="black" stroke-width="0.5" />
    <path d="M287.69 313.17L287.71 313.21" stroke="black" stroke-width="0.5" />
    <path d="M287.71 313.21L287.72 313.24" stroke="black" stroke-width="0.5" />
    <path d="M287.72 313.24L287.74 313.27" stroke="black" stroke-width="0.5" />
    <path d="M287.74 313.27L287.76 313.3" stroke="black" stroke-width="0.5" />
    <path d="M287.76 313.3L287.78 313.33" stroke="black" stroke-width="0.5" />
    <path d="M287.78 313.33L287.8 313.36" stroke="black" stroke-width="0.5" />
    <path d="M287.8 313.36L287.82 313.39" stroke="black" stroke-width="0.5" />
    <path d="M287.82 313.39L287.84 313.41" stroke="black" stroke-width="0.5" />
    <path d="M287.84 313.41L287.86 313.44" stroke="black" stroke-width="0.5" />
    <path d="M287.86 313.44L287.89 313.47" stroke="black" stroke-width="0.5" />
    <path d="M287.89 313.47L287.91 313.49" stroke="black" stroke-width="0.5" />
    <path d="M287.91 313.49L287.94 313.51" stroke="black" stroke-width="0.5" />
    <path d="M287.94 313.51L287.97 313.53" stroke="black" stroke-width="0.5" />
    <path d="M287.97 313.53L288 313.56" stroke="black" stroke-width="0.5" />
    <path d="M288 313.56L288.02 313.58" stroke="black" stroke-width="0.5" />
    <path d="M288.02 313.58L288.05 313.59" stroke="black" stroke-width="0.5" />
    <path d="M288.05 313.59L288.08 313.61" stroke="black" stroke-width="0.5" />
    <path d="M288.08 313.61L288.12 313.63" stroke="black" stroke-width="0.5" />
    <path d="M288.12 313.63L288.15 313.64" stroke="black" stroke-width="0.5" />
    <path d="M288.15 313.64L288.18 313.66" stroke="black" stroke-width="0.5" />
    <path d="M288.18 313.66L288.21 313.67" stroke="black" stroke-width="0.5" />
    <path d="M288.21 313.67L288.25 313.68" stroke="black" stroke-width="0.5" />
    <path d="M288.25 313.68L288.28 313.69" stroke="black" stroke-width="0.5" />
    <path d="M288.28 313.69L288.31 313.7" stroke="black" stroke-width="0.5" />
    <path d="M288.31 313.7L288.35 313.71" stroke="black" stroke-width="0.5" />
    <path d="M288.35 313.71H288.38" stroke="black" stroke-width="0.5" />
    <path d="M288.38 313.71L288.42 313.72" stroke="black" stroke-width="0.5" />
    <path d="M288.42 313.72H288.45" stroke="black" stroke-width="0.5" />
    <path d="M288.45 313.72H288.49" stroke="black" stroke-width="0.5" />
    <path d="M288.49 313.72H288.51" stroke="black" stroke-width="0.5" />
    <path
      d="M289.21 313.37C289.267 313.291 289.311 313.203 289.34 313.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M287.99 313.55C288.028 313.58 288.068 313.607 288.11 313.63"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.21 313.37C289.267 313.291 289.311 313.203 289.34 313.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M287.99 313.55C288.028 313.58 288.068 313.607 288.11 313.63"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 314.16C288.839 314.159 289.155 314.036 289.397 313.814C289.64 313.592 289.79 313.287 289.82 312.96"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.35 311.82C289.113 311.625 288.816 311.519 288.51 311.52"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 311.52C288.204 311.519 287.907 311.625 287.67 311.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M287.19 312.96C287.22 313.289 287.372 313.595 287.616 313.817C287.861 314.039 288.18 314.162 288.51 314.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 314.16C288.741 314.16 288.968 314.098 289.168 313.983C289.368 313.867 289.534 313.7 289.65 313.5"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.65 313.5C289.745 313.334 289.803 313.15 289.82 312.96"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.35 311.82C289.113 311.625 288.816 311.519 288.51 311.52"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M288.51 311.52C288.204 311.519 287.907 311.625 287.67 311.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M287.19 312.96C287.207 313.15 287.265 313.334 287.36 313.5"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M287.36 313.5C287.476 313.702 287.644 313.869 287.846 313.985C288.048 314.101 288.277 314.161 288.51 314.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M289.21 313.37C289.267 313.291 289.311 313.203 289.34 313.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M287.99 313.55C288.028 313.58 288.068 313.607 288.11 313.63"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M287.63 310.2V311.85" stroke="black" stroke-width="0.5" />
    <path d="M289.39 311.85V310.2" stroke="black" stroke-width="0.5" />
    <path d="M289.39 310.2H287.63" stroke="black" stroke-width="0.5" />
    <path d="M289.39 310.2H287.63" stroke="black" stroke-width="0.5" />
    <path d="M304.34 319.88H303.68" stroke="black" stroke-width="0.5" />
    <path d="M303.68 321.64H304.34" stroke="black" stroke-width="0.5" />
    <path d="M304.34 356.82H303.68" stroke="black" stroke-width="0.5" />
    <path d="M303.68 358.58H304.34" stroke="black" stroke-width="0.5" />
    <path d="M304.34 321.64V319.88" stroke="black" stroke-width="0.5" />
    <path d="M304.34 321.64V319.88" stroke="black" stroke-width="0.5" />
    <path d="M304.34 358.58V356.82" stroke="black" stroke-width="0.5" />
    <path d="M304.34 358.58V356.82" stroke="black" stroke-width="0.5" />
    <path d="M303.68 319.88V321.64" stroke="black" stroke-width="0.5" />
    <path d="M303.68 319.88V321.64" stroke="black" stroke-width="0.5" />
    <path d="M303.68 356.82V358.58" stroke="black" stroke-width="0.5" />
    <path d="M303.68 356.82V358.58" stroke="black" stroke-width="0.5" />
    <path d="M298.18 310.2V310.86" stroke="black" stroke-width="0.5" />
    <path d="M299.94 310.86V310.2" stroke="black" stroke-width="0.5" />
    <path d="M266.52 310.2V310.86" stroke="black" stroke-width="0.5" />
    <path d="M268.28 310.86V310.2" stroke="black" stroke-width="0.5" />
    <path d="M299.94 310.2H298.18" stroke="black" stroke-width="0.5" />
    <path d="M299.94 310.2H298.18" stroke="black" stroke-width="0.5" />
    <path d="M268.28 310.2H266.52" stroke="black" stroke-width="0.5" />
    <path d="M268.28 310.2H266.52" stroke="black" stroke-width="0.5" />
    <path d="M298.18 310.86H299.94" stroke="black" stroke-width="0.5" />
    <path d="M298.18 310.86H299.94" stroke="black" stroke-width="0.5" />
    <path d="M266.52 310.86H268.28" stroke="black" stroke-width="0.5" />
    <path d="M266.52 310.86H268.28" stroke="black" stroke-width="0.5" />
    <path d="M303.68 320.21H303.02" stroke="black" stroke-width="0.5" />
    <path d="M303.02 321.31H303.68" stroke="black" stroke-width="0.5" />
    <path d="M303.02 320.21H302.96" stroke="black" stroke-width="0.5" />
    <path d="M302.96 320.21H302.91" stroke="black" stroke-width="0.5" />
    <path d="M302.91 320.21L302.85 320.22" stroke="black" stroke-width="0.5" />
    <path d="M302.85 320.22L302.79 320.23" stroke="black" stroke-width="0.5" />
    <path d="M302.79 320.23L302.74 320.24" stroke="black" stroke-width="0.5" />
    <path d="M302.74 320.24L302.68 320.25" stroke="black" stroke-width="0.5" />
    <path d="M302.68 320.25L302.63 320.26" stroke="black" stroke-width="0.5" />
    <path d="M302.63 320.26L302.57 320.28" stroke="black" stroke-width="0.5" />
    <path d="M302.57 320.28L302.52 320.3" stroke="black" stroke-width="0.5" />
    <path d="M302.52 320.3L302.47 320.32" stroke="black" stroke-width="0.5" />
    <path d="M302.47 320.32L302.41 320.34" stroke="black" stroke-width="0.5" />
    <path d="M302.41 320.34L302.36 320.37" stroke="black" stroke-width="0.5" />
    <path d="M302.36 320.37L302.31 320.4" stroke="black" stroke-width="0.5" />
    <path d="M302.31 320.4L302.26 320.43" stroke="black" stroke-width="0.5" />
    <path d="M302.26 320.43L302.21 320.46" stroke="black" stroke-width="0.5" />
    <path d="M302.21 320.46L302.17 320.49" stroke="black" stroke-width="0.5" />
    <path d="M302.17 320.49L302.12 320.53" stroke="black" stroke-width="0.5" />
    <path d="M302.12 320.53L302.08 320.56" stroke="black" stroke-width="0.5" />
    <path d="M302.08 320.56L302.04 320.6" stroke="black" stroke-width="0.5" />
    <path d="M302.04 320.6L302 320.64" stroke="black" stroke-width="0.5" />
    <path d="M302 320.64L301.96 320.68" stroke="black" stroke-width="0.5" />
    <path d="M301.96 320.68L301.92 320.73" stroke="black" stroke-width="0.5" />
    <path d="M301.92 320.73L301.88 320.77" stroke="black" stroke-width="0.5" />
    <path d="M301.88 320.77L301.85 320.82" stroke="black" stroke-width="0.5" />
    <path d="M301.85 320.82L301.82 320.87" stroke="black" stroke-width="0.5" />
    <path d="M301.82 320.87L301.79 320.91" stroke="black" stroke-width="0.5" />
    <path d="M301.79 320.91L301.76 320.96" stroke="black" stroke-width="0.5" />
    <path d="M301.76 320.96L301.74 321.01" stroke="black" stroke-width="0.5" />
    <path d="M301.74 321.01L301.71 321.07" stroke="black" stroke-width="0.5" />
    <path d="M301.71 321.07L301.69 321.12" stroke="black" stroke-width="0.5" />
    <path d="M301.69 321.12L301.67 321.17" stroke="black" stroke-width="0.5" />
    <path d="M301.67 321.17L301.65 321.23" stroke="black" stroke-width="0.5" />
    <path d="M301.65 321.23L301.64 321.28" stroke="black" stroke-width="0.5" />
    <path d="M301.64 321.28L301.62 321.34" stroke="black" stroke-width="0.5" />
    <path d="M301.62 321.34L301.61 321.39" stroke="black" stroke-width="0.5" />
    <path d="M301.61 321.39L301.6 321.45" stroke="black" stroke-width="0.5" />
    <path d="M301.6 321.45V321.51" stroke="black" stroke-width="0.5" />
    <path d="M301.6 321.51L301.59 321.56" stroke="black" stroke-width="0.5" />
    <path d="M301.59 321.56V321.62" stroke="black" stroke-width="0.5" />
    <path d="M301.59 321.62V321.64" stroke="black" stroke-width="0.5" />
    <path d="M302.69 321.64V321.62" stroke="black" stroke-width="0.5" />
    <path d="M302.69 321.62V321.61" stroke="black" stroke-width="0.5" />
    <path d="M302.69 321.61V321.6" stroke="black" stroke-width="0.5" />
    <path d="M302.69 321.6L302.7 321.58" stroke="black" stroke-width="0.5" />
    <path d="M302.7 321.58V321.57" stroke="black" stroke-width="0.5" />
    <path d="M302.7 321.57V321.56" stroke="black" stroke-width="0.5" />
    <path d="M302.7 321.56V321.55" stroke="black" stroke-width="0.5" />
    <path d="M302.7 321.55L302.71 321.53" stroke="black" stroke-width="0.5" />
    <path d="M302.71 321.53V321.52" stroke="black" stroke-width="0.5" />
    <path d="M302.71 321.52L302.72 321.51" stroke="black" stroke-width="0.5" />
    <path d="M302.72 321.51V321.5" stroke="black" stroke-width="0.5" />
    <path d="M302.72 321.5L302.73 321.49" stroke="black" stroke-width="0.5" />
    <path d="M302.73 321.49L302.74 321.47" stroke="black" stroke-width="0.5" />
    <path d="M302.74 321.47V321.46" stroke="black" stroke-width="0.5" />
    <path d="M302.74 321.46L302.75 321.45" stroke="black" stroke-width="0.5" />
    <path d="M302.75 321.45L302.76 321.44" stroke="black" stroke-width="0.5" />
    <path d="M302.76 321.44L302.77 321.43" stroke="black" stroke-width="0.5" />
    <path d="M302.77 321.43V321.42" stroke="black" stroke-width="0.5" />
    <path d="M302.77 321.42L302.78 321.41" stroke="black" stroke-width="0.5" />
    <path d="M302.78 321.41L302.79 321.4" stroke="black" stroke-width="0.5" />
    <path d="M302.79 321.4L302.8 321.39" stroke="black" stroke-width="0.5" />
    <path d="M302.8 321.39L302.81 321.38" stroke="black" stroke-width="0.5" />
    <path d="M302.81 321.38L302.82 321.37" stroke="black" stroke-width="0.5" />
    <path d="M302.82 321.37H302.83" stroke="black" stroke-width="0.5" />
    <path d="M302.83 321.37L302.84 321.36" stroke="black" stroke-width="0.5" />
    <path d="M302.84 321.36L302.85 321.35" stroke="black" stroke-width="0.5" />
    <path d="M302.85 321.35H302.87" stroke="black" stroke-width="0.5" />
    <path d="M302.87 321.35L302.88 321.34" stroke="black" stroke-width="0.5" />
    <path d="M302.88 321.34L302.89 321.33" stroke="black" stroke-width="0.5" />
    <path d="M302.89 321.33H302.9" stroke="black" stroke-width="0.5" />
    <path d="M302.9 321.33H302.91" stroke="black" stroke-width="0.5" />
    <path d="M302.91 321.33L302.93 321.32" stroke="black" stroke-width="0.5" />
    <path d="M302.93 321.32H302.94" stroke="black" stroke-width="0.5" />
    <path d="M302.94 321.32L302.95 321.31" stroke="black" stroke-width="0.5" />
    <path d="M302.95 321.31H302.97" stroke="black" stroke-width="0.5" />
    <path d="M302.97 321.31H302.98" stroke="black" stroke-width="0.5" />
    <path d="M302.98 321.31H302.99" stroke="black" stroke-width="0.5" />
    <path d="M302.99 321.31H303.01" stroke="black" stroke-width="0.5" />
    <path d="M303.01 321.31H303.02" stroke="black" stroke-width="0.5" />
    <path d="M301.59 321.64V356.82" stroke="black" stroke-width="0.5" />
    <path d="M302.69 356.82V321.64" stroke="black" stroke-width="0.5" />
    <path d="M301.59 356.82V356.88" stroke="black" stroke-width="0.5" />
    <path d="M301.59 356.88L301.6 356.94" stroke="black" stroke-width="0.5" />
    <path d="M301.6 356.94V356.99" stroke="black" stroke-width="0.5" />
    <path d="M301.6 356.99L301.61 357.05" stroke="black" stroke-width="0.5" />
    <path d="M301.61 357.05L301.62 357.11" stroke="black" stroke-width="0.5" />
    <path d="M301.62 357.11L301.63 357.16" stroke="black" stroke-width="0.5" />
    <path d="M301.63 357.16L301.65 357.22" stroke="black" stroke-width="0.5" />
    <path d="M301.65 357.22L301.66 357.27" stroke="black" stroke-width="0.5" />
    <path d="M301.66 357.27L301.68 357.33" stroke="black" stroke-width="0.5" />
    <path d="M301.68 357.33L301.71 357.38" stroke="black" stroke-width="0.5" />
    <path d="M301.71 357.38L301.73 357.43" stroke="black" stroke-width="0.5" />
    <path d="M301.73 357.43L301.75 357.48" stroke="black" stroke-width="0.5" />
    <path d="M301.75 357.48L301.78 357.53" stroke="black" stroke-width="0.5" />
    <path d="M301.78 357.53L301.81 357.58" stroke="black" stroke-width="0.5" />
    <path d="M301.81 357.58L301.84 357.63" stroke="black" stroke-width="0.5" />
    <path d="M301.84 357.63L301.88 357.68" stroke="black" stroke-width="0.5" />
    <path d="M301.88 357.68L301.91 357.72" stroke="black" stroke-width="0.5" />
    <path d="M301.91 357.72L301.95 357.77" stroke="black" stroke-width="0.5" />
    <path d="M301.95 357.77L301.99 357.81" stroke="black" stroke-width="0.5" />
    <path d="M301.99 357.81L302.03 357.85" stroke="black" stroke-width="0.5" />
    <path d="M302.03 357.85L302.07 357.89" stroke="black" stroke-width="0.5" />
    <path d="M302.07 357.89L302.11 357.93" stroke="black" stroke-width="0.5" />
    <path d="M302.11 357.93L302.16 357.96" stroke="black" stroke-width="0.5" />
    <path d="M302.16 357.96L302.2 357.99" stroke="black" stroke-width="0.5" />
    <path d="M302.2 357.99L302.25 358.03" stroke="black" stroke-width="0.5" />
    <path d="M302.25 358.03L302.3 358.06" stroke="black" stroke-width="0.5" />
    <path d="M302.3 358.06L302.35 358.08" stroke="black" stroke-width="0.5" />
    <path d="M302.35 358.08L302.4 358.11" stroke="black" stroke-width="0.5" />
    <path d="M302.4 358.11L302.45 358.13" stroke="black" stroke-width="0.5" />
    <path d="M302.45 358.13L302.5 358.16" stroke="black" stroke-width="0.5" />
    <path d="M302.5 358.16L302.56 358.18" stroke="black" stroke-width="0.5" />
    <path d="M302.56 358.18L302.61 358.19" stroke="black" stroke-width="0.5" />
    <path d="M302.61 358.19L302.67 358.21" stroke="black" stroke-width="0.5" />
    <path d="M302.67 358.21L302.72 358.22" stroke="black" stroke-width="0.5" />
    <path d="M302.72 358.22L302.78 358.23" stroke="black" stroke-width="0.5" />
    <path d="M302.78 358.23L302.84 358.24" stroke="black" stroke-width="0.5" />
    <path d="M302.84 358.24L302.89 358.25" stroke="black" stroke-width="0.5" />
    <path d="M302.89 358.25H302.95" stroke="black" stroke-width="0.5" />
    <path d="M302.95 358.25H303.01" stroke="black" stroke-width="0.5" />
    <path d="M303.01 358.25H303.02" stroke="black" stroke-width="0.5" />
    <path d="M303.02 357.15H303.01" stroke="black" stroke-width="0.5" />
    <path d="M303.01 357.15H303" stroke="black" stroke-width="0.5" />
    <path d="M303 357.15H302.98" stroke="black" stroke-width="0.5" />
    <path d="M302.98 357.15H302.97" stroke="black" stroke-width="0.5" />
    <path d="M302.97 357.15H302.96" stroke="black" stroke-width="0.5" />
    <path d="M302.96 357.15L302.94 357.14" stroke="black" stroke-width="0.5" />
    <path d="M302.94 357.14H302.93" stroke="black" stroke-width="0.5" />
    <path d="M302.93 357.14H302.92" stroke="black" stroke-width="0.5" />
    <path d="M302.92 357.14L302.91 357.13" stroke="black" stroke-width="0.5" />
    <path d="M302.91 357.13H302.89" stroke="black" stroke-width="0.5" />
    <path d="M302.89 357.13L302.88 357.12" stroke="black" stroke-width="0.5" />
    <path d="M302.88 357.12H302.87" stroke="black" stroke-width="0.5" />
    <path d="M302.87 357.12L302.86 357.11" stroke="black" stroke-width="0.5" />
    <path d="M302.86 357.11L302.85 357.1" stroke="black" stroke-width="0.5" />
    <path d="M302.85 357.1H302.84" stroke="black" stroke-width="0.5" />
    <path d="M302.84 357.1L302.82 357.09" stroke="black" stroke-width="0.5" />
    <path d="M302.82 357.09L302.81 357.08" stroke="black" stroke-width="0.5" />
    <path d="M302.81 357.08L302.8 357.07" stroke="black" stroke-width="0.5" />
    <path d="M302.8 357.07L302.79 357.06" stroke="black" stroke-width="0.5" />
    <path d="M302.79 357.06V357.05" stroke="black" stroke-width="0.5" />
    <path d="M302.79 357.05L302.78 357.04" stroke="black" stroke-width="0.5" />
    <path d="M302.78 357.04L302.77 357.03" stroke="black" stroke-width="0.5" />
    <path d="M302.77 357.03L302.76 357.02" stroke="black" stroke-width="0.5" />
    <path d="M302.76 357.02L302.75 357.01" stroke="black" stroke-width="0.5" />
    <path d="M302.75 357.01L302.74 357" stroke="black" stroke-width="0.5" />
    <path d="M302.74 357V356.99" stroke="black" stroke-width="0.5" />
    <path d="M302.74 356.99L302.73 356.98" stroke="black" stroke-width="0.5" />
    <path d="M302.73 356.98L302.72 356.97" stroke="black" stroke-width="0.5" />
    <path d="M302.72 356.97V356.96" stroke="black" stroke-width="0.5" />
    <path d="M302.72 356.96L302.71 356.94" stroke="black" stroke-width="0.5" />
    <path d="M302.71 356.94V356.93" stroke="black" stroke-width="0.5" />
    <path d="M302.71 356.93V356.92" stroke="black" stroke-width="0.5" />
    <path d="M302.71 356.92L302.7 356.91" stroke="black" stroke-width="0.5" />
    <path d="M302.7 356.91V356.89" stroke="black" stroke-width="0.5" />
    <path d="M302.7 356.89V356.88" stroke="black" stroke-width="0.5" />
    <path d="M302.7 356.88L302.69 356.87" stroke="black" stroke-width="0.5" />
    <path d="M302.69 356.87V356.85" stroke="black" stroke-width="0.5" />
    <path d="M302.69 356.85V356.84" stroke="black" stroke-width="0.5" />
    <path d="M302.69 356.84V356.83" stroke="black" stroke-width="0.5" />
    <path d="M302.69 356.83V356.82" stroke="black" stroke-width="0.5" />
    <path d="M303.02 358.25H303.68" stroke="black" stroke-width="0.5" />
    <path d="M303.68 357.15H303.02" stroke="black" stroke-width="0.5" />
    <path d="M303.68 321.31V320.88" stroke="black" stroke-width="0.5" />
    <path d="M303.68 320.88V320.21" stroke="black" stroke-width="0.5" />
    <path d="M303.68 320.88V320.21" stroke="black" stroke-width="0.5" />
    <path d="M303.68 321.31V320.88" stroke="black" stroke-width="0.5" />
    <path d="M303.68 358.25V357.15" stroke="black" stroke-width="0.5" />
    <path d="M303.68 358.25V357.15" stroke="black" stroke-width="0.5" />
    <path d="M266.85 310.86V311.52" stroke="black" stroke-width="0.5" />
    <path d="M267.95 311.52V310.86" stroke="black" stroke-width="0.5" />
    <path d="M266.85 311.52V311.58" stroke="black" stroke-width="0.5" />
    <path d="M266.85 311.58V311.64" stroke="black" stroke-width="0.5" />
    <path d="M266.85 311.64L266.86 311.69" stroke="black" stroke-width="0.5" />
    <path d="M266.86 311.69V311.75" stroke="black" stroke-width="0.5" />
    <path d="M266.86 311.75L266.87 311.81" stroke="black" stroke-width="0.5" />
    <path d="M266.87 311.81L266.89 311.86" stroke="black" stroke-width="0.5" />
    <path d="M266.89 311.86L266.9 311.92" stroke="black" stroke-width="0.5" />
    <path d="M266.9 311.92L266.92 311.97" stroke="black" stroke-width="0.5" />
    <path d="M266.92 311.97L266.94 312.02" stroke="black" stroke-width="0.5" />
    <path d="M266.94 312.02L266.96 312.08" stroke="black" stroke-width="0.5" />
    <path d="M266.96 312.08L266.98 312.13" stroke="black" stroke-width="0.5" />
    <path d="M266.98 312.13L267.01 312.18" stroke="black" stroke-width="0.5" />
    <path d="M267.01 312.18L267.03 312.23" stroke="black" stroke-width="0.5" />
    <path d="M267.03 312.23L267.06 312.28" stroke="black" stroke-width="0.5" />
    <path d="M267.06 312.28L267.1 312.33" stroke="black" stroke-width="0.5" />
    <path d="M267.1 312.33L267.13 312.37" stroke="black" stroke-width="0.5" />
    <path d="M267.13 312.37L267.16 312.42" stroke="black" stroke-width="0.5" />
    <path d="M267.16 312.42L267.2 312.46" stroke="black" stroke-width="0.5" />
    <path d="M267.2 312.46L267.24 312.51" stroke="black" stroke-width="0.5" />
    <path d="M267.24 312.51L267.28 312.55" stroke="black" stroke-width="0.5" />
    <path d="M267.28 312.55L267.32 312.59" stroke="black" stroke-width="0.5" />
    <path d="M267.32 312.59L267.36 312.62" stroke="black" stroke-width="0.5" />
    <path d="M267.36 312.62L267.41 312.66" stroke="black" stroke-width="0.5" />
    <path d="M267.41 312.66L267.46 312.69" stroke="black" stroke-width="0.5" />
    <path d="M267.46 312.69L267.5 312.72" stroke="black" stroke-width="0.5" />
    <path d="M267.5 312.72L267.55 312.75" stroke="black" stroke-width="0.5" />
    <path d="M267.55 312.75L267.6 312.78" stroke="black" stroke-width="0.5" />
    <path d="M267.6 312.78L267.65 312.81" stroke="black" stroke-width="0.5" />
    <path d="M267.65 312.81L267.7 312.83" stroke="black" stroke-width="0.5" />
    <path d="M267.7 312.83L267.76 312.85" stroke="black" stroke-width="0.5" />
    <path d="M267.76 312.85L267.81 312.87" stroke="black" stroke-width="0.5" />
    <path d="M267.81 312.87L267.87 312.89" stroke="black" stroke-width="0.5" />
    <path d="M267.87 312.89L267.92 312.91" stroke="black" stroke-width="0.5" />
    <path d="M267.92 312.91L267.98 312.92" stroke="black" stroke-width="0.5" />
    <path d="M267.98 312.92L268.03 312.93" stroke="black" stroke-width="0.5" />
    <path d="M268.03 312.93L268.09 312.94" stroke="black" stroke-width="0.5" />
    <path d="M268.09 312.94H268.15" stroke="black" stroke-width="0.5" />
    <path d="M268.15 312.94L268.2 312.95" stroke="black" stroke-width="0.5" />
    <path d="M268.2 312.95H268.26" stroke="black" stroke-width="0.5" />
    <path d="M268.26 312.95H268.28" stroke="black" stroke-width="0.5" />
    <path d="M268.28 311.85H268.26" stroke="black" stroke-width="0.5" />
    <path d="M268.26 311.85H268.25" stroke="black" stroke-width="0.5" />
    <path d="M268.25 311.85H268.24" stroke="black" stroke-width="0.5" />
    <path d="M268.24 311.85H268.22" stroke="black" stroke-width="0.5" />
    <path d="M268.22 311.85L268.21 311.84" stroke="black" stroke-width="0.5" />
    <path d="M268.21 311.84H268.2" stroke="black" stroke-width="0.5" />
    <path d="M268.2 311.84H268.18" stroke="black" stroke-width="0.5" />
    <path d="M268.18 311.84L268.17 311.83" stroke="black" stroke-width="0.5" />
    <path d="M268.17 311.83H268.16" stroke="black" stroke-width="0.5" />
    <path d="M268.16 311.83H268.15" stroke="black" stroke-width="0.5" />
    <path d="M268.15 311.83L268.13 311.82" stroke="black" stroke-width="0.5" />
    <path d="M268.13 311.82L268.12 311.81" stroke="black" stroke-width="0.5" />
    <path d="M268.12 311.81H268.11" stroke="black" stroke-width="0.5" />
    <path d="M268.11 311.81L268.1 311.8" stroke="black" stroke-width="0.5" />
    <path d="M268.1 311.8L268.09 311.79" stroke="black" stroke-width="0.5" />
    <path d="M268.09 311.79H268.08" stroke="black" stroke-width="0.5" />
    <path d="M268.08 311.79L268.07 311.78" stroke="black" stroke-width="0.5" />
    <path d="M268.07 311.78L268.06 311.77" stroke="black" stroke-width="0.5" />
    <path d="M268.06 311.77L268.05 311.76" stroke="black" stroke-width="0.5" />
    <path d="M268.05 311.76L268.04 311.75" stroke="black" stroke-width="0.5" />
    <path d="M268.04 311.75L268.03 311.74" stroke="black" stroke-width="0.5" />
    <path d="M268.03 311.74L268.02 311.73" stroke="black" stroke-width="0.5" />
    <path d="M268.02 311.73L268.01 311.72" stroke="black" stroke-width="0.5" />
    <path d="M268.01 311.72V311.71" stroke="black" stroke-width="0.5" />
    <path d="M268.01 311.71L268 311.7" stroke="black" stroke-width="0.5" />
    <path d="M268 311.7L267.99 311.69" stroke="black" stroke-width="0.5" />
    <path d="M267.99 311.69L267.98 311.68" stroke="black" stroke-width="0.5" />
    <path d="M267.98 311.68V311.66" stroke="black" stroke-width="0.5" />
    <path d="M267.98 311.66L267.97 311.65" stroke="black" stroke-width="0.5" />
    <path d="M267.97 311.65V311.64" stroke="black" stroke-width="0.5" />
    <path d="M267.97 311.64L267.96 311.63" stroke="black" stroke-width="0.5" />
    <path d="M267.96 311.63V311.62" stroke="black" stroke-width="0.5" />
    <path d="M267.96 311.62V311.6" stroke="black" stroke-width="0.5" />
    <path d="M267.96 311.6L267.95 311.59" stroke="black" stroke-width="0.5" />
    <path d="M267.95 311.59V311.58" stroke="black" stroke-width="0.5" />
    <path d="M267.95 311.58V311.56" stroke="black" stroke-width="0.5" />
    <path d="M267.95 311.56V311.55" stroke="black" stroke-width="0.5" />
    <path d="M267.95 311.55V311.54" stroke="black" stroke-width="0.5" />
    <path d="M267.95 311.54V311.52" stroke="black" stroke-width="0.5" />
    <path d="M268.28 312.95H298.18" stroke="black" stroke-width="0.5" />
    <path d="M298.18 311.85H268.28" stroke="black" stroke-width="0.5" />
    <path d="M298.18 312.95H298.24" stroke="black" stroke-width="0.5" />
    <path d="M298.24 312.95H298.3" stroke="black" stroke-width="0.5" />
    <path d="M298.3 312.95L298.35 312.94" stroke="black" stroke-width="0.5" />
    <path d="M298.35 312.94L298.41 312.93" stroke="black" stroke-width="0.5" />
    <path d="M298.41 312.93L298.47 312.92" stroke="black" stroke-width="0.5" />
    <path d="M298.47 312.92L298.52 312.91" stroke="black" stroke-width="0.5" />
    <path d="M298.52 312.91L298.58 312.9" stroke="black" stroke-width="0.5" />
    <path d="M298.58 312.9L298.63 312.88" stroke="black" stroke-width="0.5" />
    <path d="M298.63 312.88L298.69 312.86" stroke="black" stroke-width="0.5" />
    <path d="M298.69 312.86L298.74 312.84" stroke="black" stroke-width="0.5" />
    <path d="M298.74 312.84L298.79 312.81" stroke="black" stroke-width="0.5" />
    <path d="M298.79 312.81L298.84 312.79" stroke="black" stroke-width="0.5" />
    <path d="M298.84 312.79L298.89 312.76" stroke="black" stroke-width="0.5" />
    <path d="M298.89 312.76L298.94 312.73" stroke="black" stroke-width="0.5" />
    <path d="M298.94 312.73L298.99 312.7" stroke="black" stroke-width="0.5" />
    <path d="M298.99 312.7L299.04 312.67" stroke="black" stroke-width="0.5" />
    <path d="M299.04 312.67L299.08 312.63" stroke="black" stroke-width="0.5" />
    <path d="M299.08 312.63L299.13 312.6" stroke="black" stroke-width="0.5" />
    <path d="M299.13 312.6L299.17 312.56" stroke="black" stroke-width="0.5" />
    <path d="M299.17 312.56L299.21 312.52" stroke="black" stroke-width="0.5" />
    <path d="M299.21 312.52L299.25 312.48" stroke="black" stroke-width="0.5" />
    <path d="M299.25 312.48L299.29 312.43" stroke="black" stroke-width="0.5" />
    <path d="M299.29 312.43L299.32 312.39" stroke="black" stroke-width="0.5" />
    <path d="M299.32 312.39L299.35 312.34" stroke="black" stroke-width="0.5" />
    <path d="M299.35 312.34L299.39 312.29" stroke="black" stroke-width="0.5" />
    <path d="M299.39 312.29L299.42 312.24" stroke="black" stroke-width="0.5" />
    <path d="M299.42 312.24L299.44 312.2" stroke="black" stroke-width="0.5" />
    <path d="M299.44 312.2L299.47 312.14" stroke="black" stroke-width="0.5" />
    <path d="M299.47 312.14L299.49 312.09" stroke="black" stroke-width="0.5" />
    <path d="M299.49 312.09L299.52 312.04" stroke="black" stroke-width="0.5" />
    <path d="M299.52 312.04L299.54 311.99" stroke="black" stroke-width="0.5" />
    <path d="M299.54 311.99L299.55 311.93" stroke="black" stroke-width="0.5" />
    <path d="M299.55 311.93L299.57 311.88" stroke="black" stroke-width="0.5" />
    <path d="M299.57 311.88L299.58 311.82" stroke="black" stroke-width="0.5" />
    <path d="M299.58 311.82L299.59 311.76" stroke="black" stroke-width="0.5" />
    <path d="M299.59 311.76L299.6 311.71" stroke="black" stroke-width="0.5" />
    <path d="M299.6 311.71L299.61 311.65" stroke="black" stroke-width="0.5" />
    <path d="M299.61 311.65V311.59" stroke="black" stroke-width="0.5" />
    <path d="M299.61 311.59V311.54" stroke="black" stroke-width="0.5" />
    <path d="M299.61 311.54V311.52" stroke="black" stroke-width="0.5" />
    <path d="M298.51 311.52V311.53" stroke="black" stroke-width="0.5" />
    <path d="M298.51 311.53V311.55" stroke="black" stroke-width="0.5" />
    <path d="M298.51 311.55V311.56" stroke="black" stroke-width="0.5" />
    <path d="M298.51 311.56V311.57" stroke="black" stroke-width="0.5" />
    <path d="M298.51 311.57V311.59" stroke="black" stroke-width="0.5" />
    <path d="M298.51 311.59L298.5 311.6" stroke="black" stroke-width="0.5" />
    <path d="M298.5 311.6V311.61" stroke="black" stroke-width="0.5" />
    <path d="M298.5 311.61V311.63" stroke="black" stroke-width="0.5" />
    <path d="M298.5 311.63L298.49 311.64" stroke="black" stroke-width="0.5" />
    <path d="M298.49 311.64V311.65" stroke="black" stroke-width="0.5" />
    <path d="M298.49 311.65L298.48 311.66" stroke="black" stroke-width="0.5" />
    <path d="M298.48 311.66V311.67" stroke="black" stroke-width="0.5" />
    <path d="M298.48 311.67L298.47 311.69" stroke="black" stroke-width="0.5" />
    <path d="M298.47 311.69L298.46 311.7" stroke="black" stroke-width="0.5" />
    <path d="M298.46 311.7V311.71" stroke="black" stroke-width="0.5" />
    <path d="M298.46 311.71L298.45 311.72" stroke="black" stroke-width="0.5" />
    <path d="M298.45 311.72L298.44 311.73" stroke="black" stroke-width="0.5" />
    <path d="M298.44 311.73L298.43 311.74" stroke="black" stroke-width="0.5" />
    <path d="M298.43 311.74L298.42 311.75" stroke="black" stroke-width="0.5" />
    <path d="M298.42 311.75L298.41 311.76" stroke="black" stroke-width="0.5" />
    <path d="M298.41 311.76L298.4 311.77" stroke="black" stroke-width="0.5" />
    <path d="M298.4 311.77L298.39 311.78" stroke="black" stroke-width="0.5" />
    <path d="M298.39 311.78H298.38" stroke="black" stroke-width="0.5" />
    <path d="M298.38 311.78L298.37 311.79" stroke="black" stroke-width="0.5" />
    <path d="M298.37 311.79L298.36 311.8" stroke="black" stroke-width="0.5" />
    <path d="M298.36 311.8L298.35 311.81" stroke="black" stroke-width="0.5" />
    <path d="M298.35 311.81H298.34" stroke="black" stroke-width="0.5" />
    <path d="M298.34 311.81L298.33 311.82" stroke="black" stroke-width="0.5" />
    <path d="M298.33 311.82H298.32" stroke="black" stroke-width="0.5" />
    <path d="M298.32 311.82L298.3 311.83" stroke="black" stroke-width="0.5" />
    <path d="M298.3 311.83H298.29" stroke="black" stroke-width="0.5" />
    <path d="M298.29 311.83L298.28 311.84" stroke="black" stroke-width="0.5" />
    <path d="M298.28 311.84H298.27" stroke="black" stroke-width="0.5" />
    <path d="M298.27 311.84H298.25" stroke="black" stroke-width="0.5" />
    <path d="M298.25 311.84L298.24 311.85" stroke="black" stroke-width="0.5" />
    <path d="M298.24 311.85H298.23" stroke="black" stroke-width="0.5" />
    <path d="M298.23 311.85H298.21" stroke="black" stroke-width="0.5" />
    <path d="M298.21 311.85H298.2" stroke="black" stroke-width="0.5" />
    <path d="M298.2 311.85H298.19" stroke="black" stroke-width="0.5" />
    <path d="M298.19 311.85H298.18" stroke="black" stroke-width="0.5" />
    <path d="M299.61 311.52V310.86" stroke="black" stroke-width="0.5" />
    <path d="M298.51 310.86V311.52" stroke="black" stroke-width="0.5" />
    <path d="M267.95 310.86H266.85" stroke="black" stroke-width="0.5" />
    <path d="M267.95 310.86H266.85" stroke="black" stroke-width="0.5" />
    <path d="M299.61 310.86H298.51" stroke="black" stroke-width="0.5" />
    <path d="M299.61 310.86H298.51" stroke="black" stroke-width="0.5" />
    <path
      d="M248.2 325.16C248.433 325.16 248.657 325.067 248.822 324.902C248.987 324.737 249.08 324.513 249.08 324.28"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M249.08 310.2V324.28" stroke="black" stroke-width="0.5" />
    <path d="M233.25 310.2H249.08" stroke="black" stroke-width="0.5" />
    <path d="M233.25 324.28V310.2" stroke="black" stroke-width="0.5" />
    <path
      d="M233.25 324.28C233.25 324.513 233.343 324.737 233.508 324.902C233.673 325.067 233.897 325.16 234.13 325.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M248.2 325.16H234.13" stroke="black" stroke-width="0.5" />
    <path d="M253.92 352.43V352.37" stroke="black" stroke-width="0.5" />
    <path d="M253.92 352.26V352.15" stroke="black" stroke-width="0.5" />
    <path d="M253.92 352.04V351.93" stroke="black" stroke-width="0.5" />
    <path d="M253.92 351.82V351.71" stroke="black" stroke-width="0.5" />
    <path d="M253.92 351.6V351.49" stroke="black" stroke-width="0.5" />
    <path d="M253.92 351.38V351.27" stroke="black" stroke-width="0.5" />
    <path d="M253.92 351.16V351.05" stroke="black" stroke-width="0.5" />
    <path d="M253.92 350.94V350.83" stroke="black" stroke-width="0.5" />
    <path d="M253.92 350.72V350.61" stroke="black" stroke-width="0.5" />
    <path d="M253.92 350.5V350.39" stroke="black" stroke-width="0.5" />
    <path d="M253.92 350.28V350.17" stroke="black" stroke-width="0.5" />
    <path d="M253.92 350.06V349.95" stroke="black" stroke-width="0.5" />
    <path d="M253.92 349.84V349.73" stroke="black" stroke-width="0.5" />
    <path d="M253.92 349.62V349.51" stroke="black" stroke-width="0.5" />
    <path d="M253.92 349.4V349.29" stroke="black" stroke-width="0.5" />
    <path d="M253.92 349.18V349.07" stroke="black" stroke-width="0.5" />
    <path d="M253.92 348.96V348.85" stroke="black" stroke-width="0.5" />
    <path d="M253.92 348.74V348.63" stroke="black" stroke-width="0.5" />
    <path d="M253.92 348.52V348.41" stroke="black" stroke-width="0.5" />
    <path d="M253.92 348.3V348.19" stroke="black" stroke-width="0.5" />
    <path d="M253.92 348.08V347.97" stroke="black" stroke-width="0.5" />
    <path d="M253.92 347.86V347.75" stroke="black" stroke-width="0.5" />
    <path d="M253.92 347.64V347.53" stroke="black" stroke-width="0.5" />
    <path d="M253.92 347.42V347.31" stroke="black" stroke-width="0.5" />
    <path d="M253.92 347.2V347.09" stroke="black" stroke-width="0.5" />
    <path d="M253.92 346.98V346.87" stroke="black" stroke-width="0.5" />
    <path d="M253.92 346.76V346.65" stroke="black" stroke-width="0.5" />
    <path d="M253.92 346.54V346.43" stroke="black" stroke-width="0.5" />
    <path d="M253.92 346.32V346.21" stroke="black" stroke-width="0.5" />
    <path d="M253.92 346.1V345.99" stroke="black" stroke-width="0.5" />
    <path d="M253.92 345.88V345.77" stroke="black" stroke-width="0.5" />
    <path d="M253.92 345.66V345.55" stroke="black" stroke-width="0.5" />
    <path d="M253.92 345.44V345.33" stroke="black" stroke-width="0.5" />
    <path d="M253.92 345.22V345.11" stroke="black" stroke-width="0.5" />
    <path d="M253.92 345V344.89" stroke="black" stroke-width="0.5" />
    <path d="M253.92 344.78V344.67" stroke="black" stroke-width="0.5" />
    <path d="M253.92 344.56V344.45" stroke="black" stroke-width="0.5" />
    <path d="M253.92 344.34V344.23" stroke="black" stroke-width="0.5" />
    <path d="M253.92 344.12V344.01" stroke="black" stroke-width="0.5" />
    <path d="M253.92 343.9V343.79" stroke="black" stroke-width="0.5" />
    <path d="M253.92 343.68V343.57" stroke="black" stroke-width="0.5" />
    <path d="M253.92 343.46V343.35" stroke="black" stroke-width="0.5" />
    <path d="M253.92 343.24V343.13" stroke="black" stroke-width="0.5" />
    <path d="M253.92 343.02V342.91" stroke="black" stroke-width="0.5" />
    <path d="M253.92 342.8V342.69" stroke="black" stroke-width="0.5" />
    <path d="M253.92 342.58V342.47" stroke="black" stroke-width="0.5" />
    <path d="M253.92 342.36V342.25" stroke="black" stroke-width="0.5" />
    <path d="M253.92 342.14V342.03" stroke="black" stroke-width="0.5" />
    <path d="M253.92 341.92V341.81" stroke="black" stroke-width="0.5" />
    <path d="M253.92 341.7V341.59" stroke="black" stroke-width="0.5" />
    <path d="M253.92 341.48V341.37" stroke="black" stroke-width="0.5" />
    <path d="M253.92 341.26V341.15" stroke="black" stroke-width="0.5" />
    <path d="M253.92 341.04V340.93" stroke="black" stroke-width="0.5" />
    <path d="M253.92 340.82V340.71" stroke="black" stroke-width="0.5" />
    <path d="M253.92 340.61V340.5" stroke="black" stroke-width="0.5" />
    <path d="M253.92 340.39V340.28" stroke="black" stroke-width="0.5" />
    <path d="M253.92 340.17V340.06" stroke="black" stroke-width="0.5" />
    <path d="M253.92 339.95V339.84" stroke="black" stroke-width="0.5" />
    <path d="M253.92 339.73V339.62" stroke="black" stroke-width="0.5" />
    <path d="M253.92 339.51V339.4" stroke="black" stroke-width="0.5" />
    <path d="M253.92 339.29V339.18" stroke="black" stroke-width="0.5" />
    <path d="M253.92 339.07V338.96" stroke="black" stroke-width="0.5" />
    <path d="M253.92 338.85V338.74" stroke="black" stroke-width="0.5" />
    <path d="M253.92 338.63V338.52" stroke="black" stroke-width="0.5" />
    <path d="M253.92 338.41V338.3" stroke="black" stroke-width="0.5" />
    <path d="M253.92 338.19V338.08" stroke="black" stroke-width="0.5" />
    <path d="M253.92 337.97V337.86" stroke="black" stroke-width="0.5" />
    <path d="M253.92 337.75V337.64" stroke="black" stroke-width="0.5" />
    <path d="M253.92 337.53V337.42" stroke="black" stroke-width="0.5" />
    <path d="M253.92 337.31V337.2" stroke="black" stroke-width="0.5" />
    <path d="M253.92 337.09V336.98" stroke="black" stroke-width="0.5" />
    <path d="M253.92 336.87V336.76" stroke="black" stroke-width="0.5" />
    <path d="M253.92 336.65V336.54" stroke="black" stroke-width="0.5" />
    <path d="M253.92 336.43V336.32" stroke="black" stroke-width="0.5" />
    <path d="M253.92 336.21V336.1" stroke="black" stroke-width="0.5" />
    <path d="M253.92 335.99V335.88" stroke="black" stroke-width="0.5" />
    <path d="M253.92 335.77V335.66" stroke="black" stroke-width="0.5" />
    <path d="M253.92 335.55V335.44" stroke="black" stroke-width="0.5" />
    <path d="M253.92 335.33V335.22" stroke="black" stroke-width="0.5" />
    <path d="M253.92 335.11V335" stroke="black" stroke-width="0.5" />
    <path d="M253.92 334.89V334.78" stroke="black" stroke-width="0.5" />
    <path d="M253.92 334.67V334.56" stroke="black" stroke-width="0.5" />
    <path d="M253.92 334.45V334.34" stroke="black" stroke-width="0.5" />
    <path d="M253.92 334.23V334.12" stroke="black" stroke-width="0.5" />
    <path d="M253.92 334.01V333.9" stroke="black" stroke-width="0.5" />
    <path d="M253.92 333.79V333.68" stroke="black" stroke-width="0.5" />
    <path d="M253.92 333.57V333.46" stroke="black" stroke-width="0.5" />
    <path d="M253.92 333.35V333.24" stroke="black" stroke-width="0.5" />
    <path d="M253.92 333.13V333.02" stroke="black" stroke-width="0.5" />
    <path d="M253.92 332.91V332.8" stroke="black" stroke-width="0.5" />
    <path d="M253.92 332.69V332.58" stroke="black" stroke-width="0.5" />
    <path d="M253.92 332.47V332.36" stroke="black" stroke-width="0.5" />
    <path d="M253.92 332.25V332.14" stroke="black" stroke-width="0.5" />
    <path d="M253.92 332.03V331.92" stroke="black" stroke-width="0.5" />
    <path d="M253.92 331.81V331.7" stroke="black" stroke-width="0.5" />
    <path d="M253.92 331.59V331.48" stroke="black" stroke-width="0.5" />
    <path d="M253.92 331.37V331.26" stroke="black" stroke-width="0.5" />
    <path d="M253.92 331.15V331.04" stroke="black" stroke-width="0.5" />
    <path d="M253.92 330.93V330.82" stroke="black" stroke-width="0.5" />
    <path d="M253.92 330.71V330.6" stroke="black" stroke-width="0.5" />
    <path d="M253.92 330.49V330.38" stroke="black" stroke-width="0.5" />
    <path d="M253.92 330.27V330.16" stroke="black" stroke-width="0.5" />
    <path d="M253.92 330.05V329.94" stroke="black" stroke-width="0.5" />
    <path d="M253.92 329.83V329.72" stroke="black" stroke-width="0.5" />
    <path d="M253.92 329.61V329.5" stroke="black" stroke-width="0.5" />
    <path d="M253.92 329.39V329.28" stroke="black" stroke-width="0.5" />
    <path d="M253.92 329.17V329.06" stroke="black" stroke-width="0.5" />
    <path d="M253.92 328.95V328.84" stroke="black" stroke-width="0.5" />
    <path d="M253.92 328.73V328.62" stroke="black" stroke-width="0.5" />
    <path d="M253.92 328.51V328.4" stroke="black" stroke-width="0.5" />
    <path d="M253.92 328.29V328.18" stroke="black" stroke-width="0.5" />
    <path d="M253.92 328.07V327.96" stroke="black" stroke-width="0.5" />
    <path d="M253.92 327.85V327.74" stroke="black" stroke-width="0.5" />
    <path d="M253.92 327.63V327.52" stroke="black" stroke-width="0.5" />
    <path d="M253.92 327.41V327.3" stroke="black" stroke-width="0.5" />
    <path d="M253.92 327.19V327.08" stroke="black" stroke-width="0.5" />
    <path d="M253.92 326.97V326.86" stroke="black" stroke-width="0.5" />
    <path d="M253.92 326.75V326.64" stroke="black" stroke-width="0.5" />
    <path d="M253.92 326.53V326.42" stroke="black" stroke-width="0.5" />
    <path d="M253.92 326.31V326.2" stroke="black" stroke-width="0.5" />
    <path d="M253.92 326.09V325.98" stroke="black" stroke-width="0.5" />
    <path d="M253.92 325.87V325.76" stroke="black" stroke-width="0.5" />
    <path d="M253.92 325.65V325.54" stroke="black" stroke-width="0.5" />
    <path d="M253.92 325.43V325.32" stroke="black" stroke-width="0.5" />
    <path d="M253.92 325.21V325.1" stroke="black" stroke-width="0.5" />
    <path d="M253.92 324.99V324.88" stroke="black" stroke-width="0.5" />
    <path d="M253.92 324.77V324.66" stroke="black" stroke-width="0.5" />
    <path d="M253.92 324.55V324.44" stroke="black" stroke-width="0.5" />
    <path d="M253.92 324.33V324.22" stroke="black" stroke-width="0.5" />
    <path d="M253.92 324.11V324" stroke="black" stroke-width="0.5" />
    <path d="M253.92 323.89V323.78" stroke="black" stroke-width="0.5" />
    <path d="M253.92 323.67V323.56" stroke="black" stroke-width="0.5" />
    <path d="M253.92 323.45V323.34" stroke="black" stroke-width="0.5" />
    <path d="M253.92 323.23V323.12" stroke="black" stroke-width="0.5" />
    <path d="M253.92 323.01V322.9" stroke="black" stroke-width="0.5" />
    <path d="M253.92 322.79V322.68" stroke="black" stroke-width="0.5" />
    <path d="M253.92 322.57V322.46" stroke="black" stroke-width="0.5" />
    <path d="M253.92 322.35V322.24" stroke="black" stroke-width="0.5" />
    <path d="M253.92 322.13V322.02" stroke="black" stroke-width="0.5" />
    <path d="M253.92 321.91V321.8" stroke="black" stroke-width="0.5" />
    <path d="M253.92 321.69V321.58" stroke="black" stroke-width="0.5" />
    <path d="M253.92 321.47V321.36" stroke="black" stroke-width="0.5" />
    <path d="M253.92 321.25V321.14" stroke="black" stroke-width="0.5" />
    <path d="M253.92 321.03V320.92" stroke="black" stroke-width="0.5" />
    <path d="M253.92 320.81V320.7" stroke="black" stroke-width="0.5" />
    <path d="M253.92 320.59V320.48" stroke="black" stroke-width="0.5" />
    <path d="M253.92 320.37V320.26" stroke="black" stroke-width="0.5" />
    <path d="M253.92 320.15V320.04" stroke="black" stroke-width="0.5" />
    <path d="M253.92 319.93V319.82" stroke="black" stroke-width="0.5" />
    <path d="M253.92 319.71V319.6" stroke="black" stroke-width="0.5" />
    <path d="M253.92 319.49V319.38" stroke="black" stroke-width="0.5" />
    <path d="M253.92 319.27V319.16" stroke="black" stroke-width="0.5" />
    <path d="M253.92 319.05V318.94" stroke="black" stroke-width="0.5" />
    <path d="M253.92 318.83V318.72" stroke="black" stroke-width="0.5" />
    <path d="M253.92 318.61V318.5" stroke="black" stroke-width="0.5" />
    <path d="M253.92 318.39V318.28" stroke="black" stroke-width="0.5" />
    <path d="M253.92 318.17V318.06" stroke="black" stroke-width="0.5" />
    <path d="M253.92 317.95V317.84" stroke="black" stroke-width="0.5" />
    <path d="M253.92 317.73V317.62" stroke="black" stroke-width="0.5" />
    <path d="M253.92 317.51V317.4" stroke="black" stroke-width="0.5" />
    <path d="M253.92 317.29V317.18" stroke="black" stroke-width="0.5" />
    <path d="M253.92 317.07V316.96" stroke="black" stroke-width="0.5" />
    <path d="M253.92 316.85V316.74" stroke="black" stroke-width="0.5" />
    <path d="M253.92 316.63V316.52" stroke="black" stroke-width="0.5" />
    <path d="M253.92 316.41V316.3" stroke="black" stroke-width="0.5" />
    <path d="M253.92 316.19V316.08" stroke="black" stroke-width="0.5" />
    <path d="M253.92 315.97V315.86" stroke="black" stroke-width="0.5" />
    <path d="M253.92 315.75V315.64" stroke="black" stroke-width="0.5" />
    <path d="M253.92 315.53V315.42" stroke="black" stroke-width="0.5" />
    <path d="M253.92 315.31V315.2" stroke="black" stroke-width="0.5" />
    <path d="M253.92 315.09V314.98" stroke="black" stroke-width="0.5" />
    <path d="M253.92 314.87V314.77" stroke="black" stroke-width="0.5" />
    <path d="M253.92 314.66V314.55" stroke="black" stroke-width="0.5" />
    <path d="M253.92 314.44V314.33" stroke="black" stroke-width="0.5" />
    <path d="M253.92 314.22V314.11" stroke="black" stroke-width="0.5" />
    <path d="M253.92 314V313.89" stroke="black" stroke-width="0.5" />
    <path d="M253.92 313.78V313.67" stroke="black" stroke-width="0.5" />
    <path d="M253.92 313.56V313.45" stroke="black" stroke-width="0.5" />
    <path d="M253.92 313.34V313.23" stroke="black" stroke-width="0.5" />
    <path d="M253.92 313.12V313.01" stroke="black" stroke-width="0.5" />
    <path d="M253.92 312.9V312.79" stroke="black" stroke-width="0.5" />
    <path d="M253.92 312.68V312.57" stroke="black" stroke-width="0.5" />
    <path d="M253.92 312.46V312.35" stroke="black" stroke-width="0.5" />
    <path d="M253.92 312.24V312.13" stroke="black" stroke-width="0.5" />
    <path d="M253.92 312.02V311.91" stroke="black" stroke-width="0.5" />
    <path d="M253.92 311.8V311.69" stroke="black" stroke-width="0.5" />
    <path d="M253.92 311.58V311.47" stroke="black" stroke-width="0.5" />
    <path d="M253.92 311.36V311.25" stroke="black" stroke-width="0.5" />
    <path d="M253.92 311.14V311.03" stroke="black" stroke-width="0.5" />
    <path d="M253.92 310.92V310.81" stroke="black" stroke-width="0.5" />
    <path d="M253.92 310.7V310.59" stroke="black" stroke-width="0.5" />
    <path d="M253.92 310.48V310.37" stroke="black" stroke-width="0.5" />
    <path d="M253.92 310.26V310.2" stroke="black" stroke-width="0.5" />
    <path d="M228.67 352.43H228.82" stroke="black" stroke-width="0.5" />
    <path d="M228.93 352.43H229.04" stroke="black" stroke-width="0.5" />
    <path d="M229.15 352.43H229.26" stroke="black" stroke-width="0.5" />
    <path d="M229.37 352.43H229.48" stroke="black" stroke-width="0.5" />
    <path d="M229.59 352.43H229.69" stroke="black" stroke-width="0.5" />
    <path d="M229.8 352.43H229.91" stroke="black" stroke-width="0.5" />
    <path d="M230.02 352.43H230.13" stroke="black" stroke-width="0.5" />
    <path d="M230.24 352.43H230.35" stroke="black" stroke-width="0.5" />
    <path d="M230.46 352.43H230.57" stroke="black" stroke-width="0.5" />
    <path d="M230.68 352.43H230.79" stroke="black" stroke-width="0.5" />
    <path d="M230.9 352.43H231.01" stroke="black" stroke-width="0.5" />
    <path d="M231.12 352.43H231.23" stroke="black" stroke-width="0.5" />
    <path d="M231.34 352.43H231.45" stroke="black" stroke-width="0.5" />
    <path d="M231.56 352.43H231.67" stroke="black" stroke-width="0.5" />
    <path d="M231.78 352.43H231.89" stroke="black" stroke-width="0.5" />
    <path d="M232 352.43H232.11" stroke="black" stroke-width="0.5" />
    <path d="M232.22 352.43H232.33" stroke="black" stroke-width="0.5" />
    <path d="M232.44 352.43H232.55" stroke="black" stroke-width="0.5" />
    <path d="M232.66 352.43H232.77" stroke="black" stroke-width="0.5" />
    <path d="M232.88 352.43H232.99" stroke="black" stroke-width="0.5" />
    <path d="M233.1 352.43H233.21" stroke="black" stroke-width="0.5" />
    <path d="M233.32 352.43H233.43" stroke="black" stroke-width="0.5" />
    <path d="M233.54 352.43H233.65" stroke="black" stroke-width="0.5" />
    <path d="M233.76 352.43H233.87" stroke="black" stroke-width="0.5" />
    <path d="M233.98 352.43H234.09" stroke="black" stroke-width="0.5" />
    <path d="M234.2 352.43H234.31" stroke="black" stroke-width="0.5" />
    <path d="M234.42 352.43H234.53" stroke="black" stroke-width="0.5" />
    <path d="M234.64 352.43H234.75" stroke="black" stroke-width="0.5" />
    <path d="M234.86 352.43H234.97" stroke="black" stroke-width="0.5" />
    <path d="M235.08 352.43H235.19" stroke="black" stroke-width="0.5" />
    <path d="M235.3 352.43H235.41" stroke="black" stroke-width="0.5" />
    <path d="M235.52 352.43H235.63" stroke="black" stroke-width="0.5" />
    <path d="M235.74 352.43H235.85" stroke="black" stroke-width="0.5" />
    <path d="M235.96 352.43H236.07" stroke="black" stroke-width="0.5" />
    <path d="M236.18 352.43H236.29" stroke="black" stroke-width="0.5" />
    <path d="M236.4 352.43H236.51" stroke="black" stroke-width="0.5" />
    <path d="M236.62 352.43H236.73" stroke="black" stroke-width="0.5" />
    <path d="M236.84 352.43H236.95" stroke="black" stroke-width="0.5" />
    <path d="M237.06 352.43H237.17" stroke="black" stroke-width="0.5" />
    <path d="M237.28 352.43H237.39" stroke="black" stroke-width="0.5" />
    <path d="M237.5 352.43H237.61" stroke="black" stroke-width="0.5" />
    <path d="M237.72 352.43H237.83" stroke="black" stroke-width="0.5" />
    <path d="M237.94 352.43H238.05" stroke="black" stroke-width="0.5" />
    <path d="M238.16 352.43H238.27" stroke="black" stroke-width="0.5" />
    <path d="M238.38 352.43H238.49" stroke="black" stroke-width="0.5" />
    <path d="M238.6 352.43H238.71" stroke="black" stroke-width="0.5" />
    <path d="M238.82 352.43H238.93" stroke="black" stroke-width="0.5" />
    <path d="M239.04 352.43H239.15" stroke="black" stroke-width="0.5" />
    <path d="M239.26 352.43H239.37" stroke="black" stroke-width="0.5" />
    <path d="M239.48 352.43H239.59" stroke="black" stroke-width="0.5" />
    <path d="M239.7 352.43H239.81" stroke="black" stroke-width="0.5" />
    <path d="M239.92 352.43H240.03" stroke="black" stroke-width="0.5" />
    <path d="M240.14 352.43H240.25" stroke="black" stroke-width="0.5" />
    <path d="M240.36 352.43H240.47" stroke="black" stroke-width="0.5" />
    <path d="M240.58 352.43H240.69" stroke="black" stroke-width="0.5" />
    <path d="M240.8 352.43H240.91" stroke="black" stroke-width="0.5" />
    <path d="M241.02 352.43H241.13" stroke="black" stroke-width="0.5" />
    <path d="M241.24 352.43H241.35" stroke="black" stroke-width="0.5" />
    <path d="M241.46 352.43H241.57" stroke="black" stroke-width="0.5" />
    <path d="M241.68 352.43H241.79" stroke="black" stroke-width="0.5" />
    <path d="M241.9 352.43H242.01" stroke="black" stroke-width="0.5" />
    <path d="M242.12 352.43H242.23" stroke="black" stroke-width="0.5" />
    <path d="M242.34 352.43H242.45" stroke="black" stroke-width="0.5" />
    <path d="M242.56 352.43H242.67" stroke="black" stroke-width="0.5" />
    <path d="M242.78 352.43H242.89" stroke="black" stroke-width="0.5" />
    <path d="M243 352.43H243.11" stroke="black" stroke-width="0.5" />
    <path d="M243.22 352.43H243.33" stroke="black" stroke-width="0.5" />
    <path d="M243.44 352.43H243.55" stroke="black" stroke-width="0.5" />
    <path d="M243.66 352.43H243.77" stroke="black" stroke-width="0.5" />
    <path d="M243.88 352.43H243.99" stroke="black" stroke-width="0.5" />
    <path d="M244.1 352.43H244.21" stroke="black" stroke-width="0.5" />
    <path d="M244.32 352.43H244.43" stroke="black" stroke-width="0.5" />
    <path d="M244.54 352.43H244.65" stroke="black" stroke-width="0.5" />
    <path d="M244.76 352.43H244.87" stroke="black" stroke-width="0.5" />
    <path d="M244.98 352.43H245.09" stroke="black" stroke-width="0.5" />
    <path d="M245.2 352.43H245.31" stroke="black" stroke-width="0.5" />
    <path d="M245.42 352.43H245.53" stroke="black" stroke-width="0.5" />
    <path d="M245.64 352.43H245.75" stroke="black" stroke-width="0.5" />
    <path d="M245.86 352.43H245.97" stroke="black" stroke-width="0.5" />
    <path d="M246.08 352.43H246.19" stroke="black" stroke-width="0.5" />
    <path d="M246.3 352.43H246.41" stroke="black" stroke-width="0.5" />
    <path d="M246.52 352.43H246.63" stroke="black" stroke-width="0.5" />
    <path d="M246.74 352.43H246.85" stroke="black" stroke-width="0.5" />
    <path d="M246.96 352.43H247.07" stroke="black" stroke-width="0.5" />
    <path d="M247.18 352.43H247.29" stroke="black" stroke-width="0.5" />
    <path d="M247.4 352.43H247.51" stroke="black" stroke-width="0.5" />
    <path d="M247.62 352.43H247.73" stroke="black" stroke-width="0.5" />
    <path d="M247.84 352.43H247.95" stroke="black" stroke-width="0.5" />
    <path d="M248.06 352.43H248.17" stroke="black" stroke-width="0.5" />
    <path d="M248.28 352.43H248.39" stroke="black" stroke-width="0.5" />
    <path d="M248.5 352.43H248.61" stroke="black" stroke-width="0.5" />
    <path d="M248.72 352.43H248.83" stroke="black" stroke-width="0.5" />
    <path d="M248.94 352.43H249.05" stroke="black" stroke-width="0.5" />
    <path d="M249.16 352.43H249.27" stroke="black" stroke-width="0.5" />
    <path d="M249.38 352.43H249.49" stroke="black" stroke-width="0.5" />
    <path d="M249.6 352.43H249.71" stroke="black" stroke-width="0.5" />
    <path d="M249.82 352.43H249.93" stroke="black" stroke-width="0.5" />
    <path d="M250.04 352.43H250.15" stroke="black" stroke-width="0.5" />
    <path d="M250.26 352.43H250.37" stroke="black" stroke-width="0.5" />
    <path d="M250.48 352.43H250.59" stroke="black" stroke-width="0.5" />
    <path d="M250.7 352.43H250.81" stroke="black" stroke-width="0.5" />
    <path d="M250.92 352.43H251.03" stroke="black" stroke-width="0.5" />
    <path d="M251.14 352.43H251.25" stroke="black" stroke-width="0.5" />
    <path d="M251.36 352.43H251.47" stroke="black" stroke-width="0.5" />
    <path d="M251.58 352.43H251.69" stroke="black" stroke-width="0.5" />
    <path d="M251.8 352.43H251.91" stroke="black" stroke-width="0.5" />
    <path d="M252.02 352.43H252.13" stroke="black" stroke-width="0.5" />
    <path d="M252.24 352.43H252.35" stroke="black" stroke-width="0.5" />
    <path d="M252.46 352.43H252.57" stroke="black" stroke-width="0.5" />
    <path d="M252.68 352.43H252.79" stroke="black" stroke-width="0.5" />
    <path d="M252.9 352.43H253.01" stroke="black" stroke-width="0.5" />
    <path d="M253.12 352.43H253.23" stroke="black" stroke-width="0.5" />
    <path d="M253.34 352.43H253.45" stroke="black" stroke-width="0.5" />
    <path d="M253.56 352.43H253.67" stroke="black" stroke-width="0.5" />
    <path d="M253.78 352.43H253.92" stroke="black" stroke-width="0.5" />
    <path d="M236.77 313.72H240.62" stroke="black" stroke-width="0.5" />
    <path
      d="M243.25 311.96C243.25 312.135 243.32 312.303 243.443 312.427C243.567 312.55 243.735 312.62 243.91 312.62C244.085 312.62 244.253 312.55 244.377 312.427C244.5 312.303 244.57 312.135 244.57 311.96"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M244.57 311.96C244.57 311.785 244.5 311.617 244.377 311.493C244.253 311.37 244.085 311.3 243.91 311.3C243.735 311.3 243.567 311.37 243.443 311.493C243.32 311.617 243.25 311.785 243.25 311.96"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M239.08 311.96C239.08 311.785 239.01 311.617 238.887 311.493C238.763 311.37 238.595 311.3 238.42 311.3C238.245 311.3 238.077 311.37 237.953 311.493C237.83 311.617 237.76 311.785 237.76 311.96"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M237.76 311.96C237.76 312.135 237.83 312.303 237.953 312.427C238.077 312.55 238.245 312.62 238.42 312.62C238.595 312.62 238.763 312.55 238.887 312.427C239.01 312.303 239.08 312.135 239.08 311.96"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M249.08 310.2V324.28" stroke="black" stroke-width="0.5" />
    <path
      d="M248.2 325.16C248.433 325.16 248.657 325.067 248.822 324.902C248.987 324.737 249.08 324.513 249.08 324.28"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M248.2 325.16H234.13" stroke="black" stroke-width="0.5" />
    <path d="M233.25 324.28V310.2" stroke="black" stroke-width="0.5" />
    <path
      d="M233.25 324.28C233.25 324.513 233.343 324.737 233.508 324.902C233.673 325.067 233.897 325.16 234.13 325.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M241.71 316.08V311.48" stroke="black" stroke-width="0.5" />
    <path d="M241.71 311.48H240.62" stroke="black" stroke-width="0.5" />
    <path d="M240.62 311.48V316.08" stroke="black" stroke-width="0.5" />
    <path d="M240.62 316.08H241.71" stroke="black" stroke-width="0.5" />
    <path
      d="M246.45 314.6C246.45 314.367 246.357 314.143 246.192 313.978C246.027 313.813 245.803 313.72 245.57 313.72"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M241.71 313.72H245.57" stroke="black" stroke-width="0.5" />
    <path
      d="M236.77 313.72C236.537 313.72 236.313 313.813 236.148 313.978C235.983 314.143 235.89 314.367 235.89 314.6"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M246.45 314.6V321.64" stroke="black" stroke-width="0.5" />
    <path
      d="M245.57 322.52C245.803 322.52 246.027 322.427 246.192 322.262C246.357 322.097 246.45 321.873 246.45 321.64"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M245.57 322.52H236.77" stroke="black" stroke-width="0.5" />
    <path
      d="M235.89 321.64C235.89 321.873 235.983 322.097 236.148 322.262C236.313 322.427 236.537 322.52 236.77 322.52"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M235.89 321.64V314.6" stroke="black" stroke-width="0.5" />
    <path
      d="M247.76 324.72C247.993 324.72 248.217 324.627 248.382 324.462C248.547 324.297 248.64 324.073 248.64 323.84"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M233.69 323.84C233.69 324.073 233.783 324.297 233.948 324.462C234.113 324.627 234.337 324.72 234.57 324.72"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M248.64 311.08V323.84" stroke="black" stroke-width="0.5" />
    <path d="M247.76 324.72H234.57" stroke="black" stroke-width="0.5" />
    <path d="M233.69 323.84V311.08" stroke="black" stroke-width="0.5" />
    <path d="M233.69 311.08H248.64" stroke="black" stroke-width="0.5" />
    <path d="M228.67 310.2H253.87" stroke="black" stroke-width="0.5" />
    <path d="M545.35 537.6H524.01" stroke="black" stroke-width="0.5" />
    <path d="M524.01 496.04V538.26" stroke="black" stroke-width="0.5" />
    <path d="M523.35 496.04V538.26" stroke="black" stroke-width="0.5" />
    <path d="M523.35 538.26H545.35" stroke="black" stroke-width="0.5" />
    <path d="M545.35 538.26V496.04" stroke="black" stroke-width="0.5" />
    <path d="M545.35 496.04H523.35" stroke="black" stroke-width="0.5" />
    <path d="M306.08 275.37V274.6" stroke="black" stroke-width="0.5" />
    <path d="M306.08 274.6V275.37" stroke="black" stroke-width="0.5" />
    <path d="M306.08 274.86V275" stroke="black" stroke-width="0.5" />
    <path d="M306.08 274.86V275" stroke="black" stroke-width="0.5" />
    <path d="M306.08 274.86V275" stroke="black" stroke-width="0.5" />
    <path d="M306.08 274.86V275" stroke="black" stroke-width="0.5" />
    <path d="M306.08 283.01V283.78" stroke="black" stroke-width="0.5" />
    <path d="M306.08 283.78V283.01" stroke="black" stroke-width="0.5" />
    <path d="M306.08 283.38V283.53" stroke="black" stroke-width="0.5" />
    <path d="M306.08 283.38V283.53" stroke="black" stroke-width="0.5" />
    <path d="M306.08 283.38V283.53" stroke="black" stroke-width="0.5" />
    <path d="M306.08 283.38V283.53" stroke="black" stroke-width="0.5" />
    <path d="M306.08 274.49V274.6" stroke="black" stroke-width="0.5" />
    <path d="M306.08 274.49V274.6" stroke="black" stroke-width="0.5" />
    <path d="M306.08 283.89V283.78" stroke="black" stroke-width="0.5" />
    <path d="M306.08 283.89V283.78" stroke="black" stroke-width="0.5" />
    <path d="M306.08 279.63V278.75" stroke="black" stroke-width="0.5" />
    <path d="M306.08 279.63V278.75" stroke="black" stroke-width="0.5" />
    <path d="M306.08 272.16H302.69" stroke="black" stroke-width="0.5" />
    <path d="M302.69 286.23H306.08" stroke="black" stroke-width="0.5" />
    <path d="M306.08 286.23V272.16" stroke="black" stroke-width="0.5" />
    <path d="M306.08 272.16V286.23" stroke="black" stroke-width="0.5" />
    <path d="M306.08 286.23H302.69" stroke="black" stroke-width="0.5" />
    <path d="M302.69 272.16H306.08" stroke="black" stroke-width="0.5" />
    <path d="M228.67 294.04H230.36" stroke="black" stroke-width="0.5" />
    <path d="M230.36 294.04H230.37" stroke="black" stroke-width="0.5" />
    <path d="M230.37 294.04H230.39" stroke="black" stroke-width="0.5" />
    <path d="M230.39 294.04H230.41" stroke="black" stroke-width="0.5" />
    <path d="M230.41 294.04L230.42 294.03" stroke="black" stroke-width="0.5" />
    <path d="M230.42 294.03H230.44" stroke="black" stroke-width="0.5" />
    <path d="M230.44 294.03H230.45" stroke="black" stroke-width="0.5" />
    <path d="M230.45 294.03L230.47 294.02" stroke="black" stroke-width="0.5" />
    <path d="M230.47 294.02H230.48" stroke="black" stroke-width="0.5" />
    <path d="M230.48 294.02L230.49 294.01" stroke="black" stroke-width="0.5" />
    <path d="M230.49 294.01H230.51" stroke="black" stroke-width="0.5" />
    <path d="M230.51 294.01L230.52 294" stroke="black" stroke-width="0.5" />
    <path d="M230.52 294L230.54 293.99" stroke="black" stroke-width="0.5" />
    <path d="M230.54 293.99H230.55" stroke="black" stroke-width="0.5" />
    <path d="M230.55 293.99L230.56 293.98" stroke="black" stroke-width="0.5" />
    <path d="M230.56 293.98L230.58 293.97" stroke="black" stroke-width="0.5" />
    <path d="M230.58 293.97L230.59 293.96" stroke="black" stroke-width="0.5" />
    <path d="M230.59 293.96L230.6 293.95" stroke="black" stroke-width="0.5" />
    <path d="M230.6 293.95L230.61 293.94" stroke="black" stroke-width="0.5" />
    <path d="M230.61 293.94L230.62 293.93" stroke="black" stroke-width="0.5" />
    <path d="M230.62 293.93L230.64 293.92" stroke="black" stroke-width="0.5" />
    <path d="M230.64 293.92L230.65 293.91" stroke="black" stroke-width="0.5" />
    <path d="M230.65 293.91L230.66 293.9" stroke="black" stroke-width="0.5" />
    <path d="M230.66 293.9L230.67 293.89" stroke="black" stroke-width="0.5" />
    <path d="M230.67 293.89V293.87" stroke="black" stroke-width="0.5" />
    <path d="M230.67 293.87L230.68 293.86" stroke="black" stroke-width="0.5" />
    <path d="M230.68 293.86L230.69 293.85" stroke="black" stroke-width="0.5" />
    <path d="M230.69 293.85L230.7 293.83" stroke="black" stroke-width="0.5" />
    <path d="M230.7 293.83L230.71 293.82" stroke="black" stroke-width="0.5" />
    <path d="M230.71 293.82V293.81" stroke="black" stroke-width="0.5" />
    <path d="M230.71 293.81L230.72 293.79" stroke="black" stroke-width="0.5" />
    <path d="M230.72 293.79V293.78" stroke="black" stroke-width="0.5" />
    <path d="M230.72 293.78L230.73 293.76" stroke="black" stroke-width="0.5" />
    <path d="M230.73 293.76V293.75" stroke="black" stroke-width="0.5" />
    <path d="M230.73 293.75L230.74 293.73" stroke="black" stroke-width="0.5" />
    <path d="M230.74 293.73V293.72" stroke="black" stroke-width="0.5" />
    <path d="M230.74 293.72V293.71" stroke="black" stroke-width="0.5" />
    <path d="M230.74 293.71L230.83 292.94" stroke="black" stroke-width="0.5" />
    <path d="M230.83 292.94L230.89 292.18" stroke="black" stroke-width="0.5" />
    <path d="M230.89 292.18L230.92 291.41" stroke="black" stroke-width="0.5" />
    <path d="M230.92 291.41V290.65" stroke="black" stroke-width="0.5" />
    <path d="M230.92 290.65L230.89 289.88" stroke="black" stroke-width="0.5" />
    <path d="M230.89 289.88L230.82 289.12" stroke="black" stroke-width="0.5" />
    <path d="M230.82 289.12L230.74 288.43" stroke="black" stroke-width="0.5" />
    <path d="M230.74 288.43V288.42" stroke="black" stroke-width="0.5" />
    <path d="M230.74 288.42L230.73 288.4" stroke="black" stroke-width="0.5" />
    <path d="M230.73 288.4V288.39" stroke="black" stroke-width="0.5" />
    <path d="M230.73 288.39V288.37" stroke="black" stroke-width="0.5" />
    <path d="M230.73 288.37L230.72 288.36" stroke="black" stroke-width="0.5" />
    <path d="M230.72 288.36V288.34" stroke="black" stroke-width="0.5" />
    <path d="M230.72 288.34L230.71 288.33" stroke="black" stroke-width="0.5" />
    <path d="M230.71 288.33L230.7 288.32" stroke="black" stroke-width="0.5" />
    <path d="M230.7 288.32V288.3" stroke="black" stroke-width="0.5" />
    <path d="M230.7 288.3L230.69 288.29" stroke="black" stroke-width="0.5" />
    <path d="M230.69 288.29L230.68 288.27" stroke="black" stroke-width="0.5" />
    <path d="M230.68 288.27L230.67 288.26" stroke="black" stroke-width="0.5" />
    <path d="M230.67 288.26L230.66 288.25" stroke="black" stroke-width="0.5" />
    <path d="M230.66 288.25L230.65 288.24" stroke="black" stroke-width="0.5" />
    <path d="M230.65 288.24L230.64 288.23" stroke="black" stroke-width="0.5" />
    <path d="M230.64 288.23L230.63 288.22" stroke="black" stroke-width="0.5" />
    <path d="M230.63 288.22L230.62 288.2" stroke="black" stroke-width="0.5" />
    <path d="M230.62 288.2L230.61 288.19" stroke="black" stroke-width="0.5" />
    <path d="M230.61 288.19L230.6 288.18" stroke="black" stroke-width="0.5" />
    <path d="M230.6 288.18L230.59 288.17" stroke="black" stroke-width="0.5" />
    <path d="M230.59 288.17H230.57" stroke="black" stroke-width="0.5" />
    <path d="M230.57 288.17L230.56 288.16" stroke="black" stroke-width="0.5" />
    <path d="M230.56 288.16L230.55 288.15" stroke="black" stroke-width="0.5" />
    <path d="M230.55 288.15L230.53 288.14" stroke="black" stroke-width="0.5" />
    <path d="M230.53 288.14H230.52" stroke="black" stroke-width="0.5" />
    <path d="M230.52 288.14L230.51 288.13" stroke="black" stroke-width="0.5" />
    <path d="M230.51 288.13L230.49 288.12" stroke="black" stroke-width="0.5" />
    <path d="M230.49 288.12H230.48" stroke="black" stroke-width="0.5" />
    <path d="M230.48 288.12L230.46 288.11" stroke="black" stroke-width="0.5" />
    <path d="M230.46 288.11H230.45" stroke="black" stroke-width="0.5" />
    <path d="M230.45 288.11H230.43" stroke="black" stroke-width="0.5" />
    <path d="M230.43 288.11L230.42 288.1" stroke="black" stroke-width="0.5" />
    <path d="M230.42 288.1H230.4" stroke="black" stroke-width="0.5" />
    <path d="M230.4 288.1H230.39" stroke="black" stroke-width="0.5" />
    <path d="M230.39 288.1H230.37" stroke="black" stroke-width="0.5" />
    <path d="M230.37 288.1H230.36" stroke="black" stroke-width="0.5" />
    <path d="M230.36 288.1H228.67" stroke="black" stroke-width="0.5" />
    <path d="M228.67 293.81V294.03" stroke="black" stroke-width="0.5" />
    <path d="M228.67 293.71V293.79" stroke="black" stroke-width="0.5" />
    <path d="M228.67 293.71V288.43" stroke="black" stroke-width="0.5" />
    <path d="M228.67 288.11V288.43" stroke="black" stroke-width="0.5" />
    <path d="M228.67 288.11V288.43" stroke="black" stroke-width="0.5" />
    <path d="M228.67 288.43V293.71" stroke="black" stroke-width="0.5" />
    <path d="M228.67 293.71V293.79" stroke="black" stroke-width="0.5" />
    <path d="M228.67 293.81V294.03" stroke="black" stroke-width="0.5" />
    <path d="M231.09 290.74H230.97" stroke="black" stroke-width="0.5" />
    <path d="M230.97 291.4H231.09" stroke="black" stroke-width="0.5" />
    <path d="M230.97 291.4H231.09" stroke="black" stroke-width="0.5" />
    <path d="M230.97 290.74H231.09" stroke="black" stroke-width="0.5" />
    <path d="M230.97 290.63V291.51" stroke="black" stroke-width="0.5" />
    <path d="M230.97 291.51V290.63" stroke="black" stroke-width="0.5" />
    <path d="M230.97 291.4V290.74" stroke="black" stroke-width="0.5" />
    <path d="M230.97 290.74V291.4" stroke="black" stroke-width="0.5" />
    <path d="M230.97 290.63H230.89" stroke="black" stroke-width="0.5" />
    <path d="M230.89 290.63H230.97" stroke="black" stroke-width="0.5" />
    <path d="M230.89 291.51H230.97" stroke="black" stroke-width="0.5" />
    <path d="M230.89 291.51H230.97" stroke="black" stroke-width="0.5" />
    <path d="M231.58 290.63V291.51" stroke="black" stroke-width="0.5" />
    <path d="M231.58 291.51V290.63" stroke="black" stroke-width="0.5" />
    <path d="M231.09 290.63V290.74" stroke="black" stroke-width="0.5" />
    <path d="M231.09 291.4V291.51" stroke="black" stroke-width="0.5" />
    <path d="M231.09 291.51V290.63" stroke="black" stroke-width="0.5" />
    <path d="M231.58 290.63H231.09" stroke="black" stroke-width="0.5" />
    <path d="M231.09 291.51H231.58" stroke="black" stroke-width="0.5" />
    <path d="M231.09 291.51H231.58" stroke="black" stroke-width="0.5" />
    <path d="M231.09 290.63H231.58" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.43V288.42" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.42V288.4" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.4V288.39" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.39V288.38" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.38V288.36" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.36V288.35" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.35V288.34" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.34V288.33" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.33V288.31" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.31V288.3" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.3V288.29" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.29V288.28" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.28V288.27" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.27V288.25" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.25V288.24" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.24V288.23" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.23V288.22" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.22V288.21" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.21V288.2" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.2V288.19" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.19V288.18" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.18V288.17" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.17V288.16" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.16V288.15" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.15V288.14" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.14V288.13" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.13V288.12" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.12V288.11" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.11V288.1" stroke="black" stroke-width="0.5" />
    <path d="M229.22 287.99H228.67" stroke="black" stroke-width="0.5" />
    <path d="M229.32 288.05V288.06" stroke="black" stroke-width="0.5" />
    <path d="M229.32 288.06V288.07" stroke="black" stroke-width="0.5" />
    <path d="M229.32 288.07L229.33 288.08" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.08V288.09" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.09V288.1" stroke="black" stroke-width="0.5" />
    <path d="M229.33 294.04V294.05" stroke="black" stroke-width="0.5" />
    <path d="M229.33 294.05V294.06" stroke="black" stroke-width="0.5" />
    <path d="M229.33 294.06L229.32 294.07" stroke="black" stroke-width="0.5" />
    <path d="M229.32 294.07V294.08" stroke="black" stroke-width="0.5" />
    <path d="M229.33 294.04V294.03" stroke="black" stroke-width="0.5" />
    <path d="M229.33 294.03V294.02" stroke="black" stroke-width="0.5" />
    <path d="M229.33 294.02V294.01" stroke="black" stroke-width="0.5" />
    <path d="M229.33 294.01V294" stroke="black" stroke-width="0.5" />
    <path d="M229.33 294V293.99" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.99V293.98" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.98V293.97" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.97V293.96" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.96V293.95" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.95V293.94" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.94V293.93" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.93V293.92" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.92V293.91" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.91V293.9" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.9V293.89" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.89V293.88" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.88V293.86" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.86V293.85" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.85V293.84" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.84V293.83" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.83V293.82" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.82V293.8" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.8V293.79" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.79V293.78" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.78V293.76" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.76V293.75" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.75V293.74" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.74V293.72" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.72V293.71" stroke="black" stroke-width="0.5" />
    <path d="M228.67 294.15H229.22" stroke="black" stroke-width="0.5" />
    <path d="M229.33 293.71V288.43" stroke="black" stroke-width="0.5" />
    <path d="M228.67 288.1V288.43" stroke="black" stroke-width="0.5" />
    <path d="M228.67 288.1V288.43" stroke="black" stroke-width="0.5" />
    <path d="M228.67 288.43V293.71" stroke="black" stroke-width="0.5" />
    <path d="M228.67 293.71V294.04" stroke="black" stroke-width="0.5" />
    <path d="M228.67 293.71V288.43" stroke="black" stroke-width="0.5" />
    <path d="M228.67 294.15V293.71" stroke="black" stroke-width="0.5" />
    <path d="M228.67 293.71V294.15" stroke="black" stroke-width="0.5" />
    <path d="M228.67 293.71V288.43" stroke="black" stroke-width="0.5" />
    <path d="M228.67 288.43V287.99" stroke="black" stroke-width="0.5" />
    <path d="M228.67 288.43V287.99" stroke="black" stroke-width="0.5" />
    <path d="M228.67 294.04V293.71" stroke="black" stroke-width="0.5" />
    <path d="M228.67 288.43V293.71" stroke="black" stroke-width="0.5" />
    <path d="M229.33 288.43V293.71" stroke="black" stroke-width="0.5" />
    <path d="M306.08 338.65V339.42" stroke="black" stroke-width="0.5" />
    <path d="M306.08 339.42V338.65" stroke="black" stroke-width="0.5" />
    <path d="M306.08 339.02V339.16" stroke="black" stroke-width="0.5" />
    <path d="M306.08 339.02V339.16" stroke="black" stroke-width="0.5" />
    <path d="M306.08 339.02V339.16" stroke="black" stroke-width="0.5" />
    <path d="M306.08 339.02V339.16" stroke="black" stroke-width="0.5" />
    <path d="M306.08 331.01V330.24" stroke="black" stroke-width="0.5" />
    <path d="M306.08 330.24V331.01" stroke="black" stroke-width="0.5" />
    <path d="M306.08 330.5V330.64" stroke="black" stroke-width="0.5" />
    <path d="M306.08 330.5V330.64" stroke="black" stroke-width="0.5" />
    <path d="M306.08 330.5V330.64" stroke="black" stroke-width="0.5" />
    <path d="M306.08 330.5V330.64" stroke="black" stroke-width="0.5" />
    <path d="M306.08 339.42V339.53" stroke="black" stroke-width="0.5" />
    <path d="M306.08 339.42V339.53" stroke="black" stroke-width="0.5" />
    <path d="M306.08 330.24V330.13" stroke="black" stroke-width="0.5" />
    <path d="M306.08 330.24V330.13" stroke="black" stroke-width="0.5" />
    <path d="M306.08 335.27V334.39" stroke="black" stroke-width="0.5" />
    <path d="M306.08 335.27V334.39" stroke="black" stroke-width="0.5" />
    <path d="M306.08 341.87V327.79" stroke="black" stroke-width="0.5" />
    <path d="M306.08 327.79H302.69" stroke="black" stroke-width="0.5" />
    <path d="M302.69 341.87H306.08" stroke="black" stroke-width="0.5" />
    <path d="M306.08 341.87H302.69" stroke="black" stroke-width="0.5" />
    <path d="M302.69 327.79H306.08" stroke="black" stroke-width="0.5" />
    <path d="M306.08 327.79V341.87" stroke="black" stroke-width="0.5" />
    <path d="M230.36 319.99H228.67" stroke="black" stroke-width="0.5" />
    <path d="M230.74 320.32V320.3" stroke="black" stroke-width="0.5" />
    <path d="M230.74 320.3L230.73 320.29" stroke="black" stroke-width="0.5" />
    <path d="M230.73 320.29V320.27" stroke="black" stroke-width="0.5" />
    <path d="M230.73 320.27V320.26" stroke="black" stroke-width="0.5" />
    <path d="M230.73 320.26L230.72 320.25" stroke="black" stroke-width="0.5" />
    <path d="M230.72 320.25V320.23" stroke="black" stroke-width="0.5" />
    <path d="M230.72 320.23L230.71 320.22" stroke="black" stroke-width="0.5" />
    <path d="M230.71 320.22L230.7 320.2" stroke="black" stroke-width="0.5" />
    <path d="M230.7 320.2V320.19" stroke="black" stroke-width="0.5" />
    <path d="M230.7 320.19L230.69 320.18" stroke="black" stroke-width="0.5" />
    <path d="M230.69 320.18L230.68 320.16" stroke="black" stroke-width="0.5" />
    <path d="M230.68 320.16L230.67 320.15" stroke="black" stroke-width="0.5" />
    <path d="M230.67 320.15L230.66 320.14" stroke="black" stroke-width="0.5" />
    <path d="M230.66 320.14L230.65 320.13" stroke="black" stroke-width="0.5" />
    <path d="M230.65 320.13L230.64 320.11" stroke="black" stroke-width="0.5" />
    <path d="M230.64 320.11L230.63 320.1" stroke="black" stroke-width="0.5" />
    <path d="M230.63 320.1L230.62 320.09" stroke="black" stroke-width="0.5" />
    <path d="M230.62 320.09L230.61 320.08" stroke="black" stroke-width="0.5" />
    <path d="M230.61 320.08L230.6 320.07" stroke="black" stroke-width="0.5" />
    <path d="M230.6 320.07L230.59 320.06" stroke="black" stroke-width="0.5" />
    <path d="M230.59 320.06L230.57 320.05" stroke="black" stroke-width="0.5" />
    <path d="M230.57 320.05H230.56" stroke="black" stroke-width="0.5" />
    <path d="M230.56 320.05L230.55 320.04" stroke="black" stroke-width="0.5" />
    <path d="M230.55 320.04L230.53 320.03" stroke="black" stroke-width="0.5" />
    <path d="M230.53 320.03L230.52 320.02" stroke="black" stroke-width="0.5" />
    <path d="M230.52 320.02H230.51" stroke="black" stroke-width="0.5" />
    <path d="M230.51 320.02L230.49 320.01" stroke="black" stroke-width="0.5" />
    <path d="M230.49 320.01H230.48" stroke="black" stroke-width="0.5" />
    <path d="M230.48 320.01L230.46 320" stroke="black" stroke-width="0.5" />
    <path d="M230.46 320H230.45" stroke="black" stroke-width="0.5" />
    <path d="M230.45 320H230.43" stroke="black" stroke-width="0.5" />
    <path d="M230.43 320L230.42 319.99" stroke="black" stroke-width="0.5" />
    <path d="M230.42 319.99H230.4" stroke="black" stroke-width="0.5" />
    <path d="M230.4 319.99H230.39" stroke="black" stroke-width="0.5" />
    <path d="M230.39 319.99H230.37" stroke="black" stroke-width="0.5" />
    <path d="M230.37 319.99H230.36" stroke="black" stroke-width="0.5" />
    <path d="M230.74 325.59L230.83 324.83" stroke="black" stroke-width="0.5" />
    <path d="M230.83 324.83L230.89 324.07" stroke="black" stroke-width="0.5" />
    <path d="M230.89 324.07L230.92 323.3" stroke="black" stroke-width="0.5" />
    <path d="M230.92 323.3V322.54" stroke="black" stroke-width="0.5" />
    <path d="M230.92 322.54L230.89 321.77" stroke="black" stroke-width="0.5" />
    <path d="M230.89 321.77L230.82 321" stroke="black" stroke-width="0.5" />
    <path d="M230.82 321L230.74 320.32" stroke="black" stroke-width="0.5" />
    <path d="M230.36 325.93H230.37" stroke="black" stroke-width="0.5" />
    <path d="M230.37 325.93L230.39 325.92" stroke="black" stroke-width="0.5" />
    <path d="M230.39 325.92H230.41" stroke="black" stroke-width="0.5" />
    <path d="M230.41 325.92H230.42" stroke="black" stroke-width="0.5" />
    <path d="M230.42 325.92H230.44" stroke="black" stroke-width="0.5" />
    <path d="M230.44 325.92L230.45 325.91" stroke="black" stroke-width="0.5" />
    <path d="M230.45 325.91H230.47" stroke="black" stroke-width="0.5" />
    <path d="M230.47 325.91H230.48" stroke="black" stroke-width="0.5" />
    <path d="M230.48 325.91L230.49 325.9" stroke="black" stroke-width="0.5" />
    <path d="M230.49 325.9H230.51" stroke="black" stroke-width="0.5" />
    <path d="M230.51 325.9L230.52 325.89" stroke="black" stroke-width="0.5" />
    <path d="M230.52 325.89L230.54 325.88" stroke="black" stroke-width="0.5" />
    <path d="M230.54 325.88L230.55 325.87" stroke="black" stroke-width="0.5" />
    <path d="M230.55 325.87H230.56" stroke="black" stroke-width="0.5" />
    <path d="M230.56 325.87L230.58 325.86" stroke="black" stroke-width="0.5" />
    <path d="M230.58 325.86L230.59 325.85" stroke="black" stroke-width="0.5" />
    <path d="M230.59 325.85L230.6 325.84" stroke="black" stroke-width="0.5" />
    <path d="M230.6 325.84L230.61 325.83" stroke="black" stroke-width="0.5" />
    <path d="M230.61 325.83L230.62 325.82" stroke="black" stroke-width="0.5" />
    <path d="M230.62 325.82L230.64 325.81" stroke="black" stroke-width="0.5" />
    <path d="M230.64 325.81L230.65 325.8" stroke="black" stroke-width="0.5" />
    <path d="M230.65 325.8L230.66 325.79" stroke="black" stroke-width="0.5" />
    <path d="M230.66 325.79L230.67 325.77" stroke="black" stroke-width="0.5" />
    <path d="M230.67 325.77V325.76" stroke="black" stroke-width="0.5" />
    <path d="M230.67 325.76L230.68 325.75" stroke="black" stroke-width="0.5" />
    <path d="M230.68 325.75L230.69 325.74" stroke="black" stroke-width="0.5" />
    <path d="M230.69 325.74L230.7 325.72" stroke="black" stroke-width="0.5" />
    <path d="M230.7 325.72L230.71 325.71" stroke="black" stroke-width="0.5" />
    <path d="M230.71 325.71V325.69" stroke="black" stroke-width="0.5" />
    <path d="M230.71 325.69L230.72 325.68" stroke="black" stroke-width="0.5" />
    <path d="M230.72 325.68V325.67" stroke="black" stroke-width="0.5" />
    <path d="M230.72 325.67L230.73 325.65" stroke="black" stroke-width="0.5" />
    <path d="M230.73 325.65V325.64" stroke="black" stroke-width="0.5" />
    <path d="M230.73 325.64L230.74 325.62" stroke="black" stroke-width="0.5" />
    <path d="M230.74 325.62V325.61" stroke="black" stroke-width="0.5" />
    <path d="M230.74 325.61V325.59" stroke="black" stroke-width="0.5" />
    <path d="M228.67 325.93H230.36" stroke="black" stroke-width="0.5" />
    <path d="M228.67 320V320.32" stroke="black" stroke-width="0.5" />
    <path d="M228.67 320.32V325.6" stroke="black" stroke-width="0.5" />
    <path d="M228.67 325.6V325.91" stroke="black" stroke-width="0.5" />
    <path d="M228.67 325.6V325.91" stroke="black" stroke-width="0.5" />
    <path d="M228.67 325.6V320.32" stroke="black" stroke-width="0.5" />
    <path d="M228.67 320V320.32" stroke="black" stroke-width="0.5" />
    <path d="M230.97 323.29H231.09" stroke="black" stroke-width="0.5" />
    <path d="M231.09 322.63H230.97" stroke="black" stroke-width="0.5" />
    <path d="M231.09 322.63H230.97" stroke="black" stroke-width="0.5" />
    <path d="M231.09 323.29H230.97" stroke="black" stroke-width="0.5" />
    <path d="M230.97 323.4V322.52" stroke="black" stroke-width="0.5" />
    <path d="M230.97 322.52V323.4" stroke="black" stroke-width="0.5" />
    <path d="M230.97 322.63V323.29" stroke="black" stroke-width="0.5" />
    <path d="M230.97 323.29V322.63" stroke="black" stroke-width="0.5" />
    <path d="M230.89 323.4H230.97" stroke="black" stroke-width="0.5" />
    <path d="M230.97 323.4H230.89" stroke="black" stroke-width="0.5" />
    <path d="M230.97 322.52H230.89" stroke="black" stroke-width="0.5" />
    <path d="M230.97 322.52H230.89" stroke="black" stroke-width="0.5" />
    <path d="M231.58 323.4V322.52" stroke="black" stroke-width="0.5" />
    <path d="M231.58 322.52V323.4" stroke="black" stroke-width="0.5" />
    <path d="M231.09 323.4V322.52" stroke="black" stroke-width="0.5" />
    <path d="M231.09 322.52V322.63" stroke="black" stroke-width="0.5" />
    <path d="M231.09 323.29V323.4" stroke="black" stroke-width="0.5" />
    <path d="M231.09 323.4H231.58" stroke="black" stroke-width="0.5" />
    <path d="M231.58 322.52H231.09" stroke="black" stroke-width="0.5" />
    <path d="M231.58 322.52H231.09" stroke="black" stroke-width="0.5" />
    <path d="M231.58 323.4H231.09" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.93V325.92" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.92V325.91" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.91V325.9" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.9V325.89" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.89V325.88" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.88V325.87" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.87V325.86" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.86V325.85" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.85V325.84" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.84V325.83" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.83V325.82" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.82V325.81" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.81V325.8" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.8V325.79" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.79V325.77" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.77V325.76" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.76V325.75" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.75V325.74" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.74V325.73" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.73V325.72" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.72V325.7" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.7V325.69" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.69V325.68" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.68V325.66" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.66V325.65" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.65V325.64" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.64V325.63" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.63V325.61" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.61V325.6" stroke="black" stroke-width="0.5" />
    <path d="M228.67 326.04H229.22" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.93V325.94" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.94V325.95" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.95L229.32 325.96" stroke="black" stroke-width="0.5" />
    <path d="M229.32 325.96V325.97" stroke="black" stroke-width="0.5" />
    <path d="M229.32 325.97V325.98" stroke="black" stroke-width="0.5" />
    <path d="M229.32 319.94V319.95" stroke="black" stroke-width="0.5" />
    <path d="M229.32 319.95V319.96" stroke="black" stroke-width="0.5" />
    <path d="M229.32 319.96H229.33" stroke="black" stroke-width="0.5" />
    <path d="M229.33 319.96V319.97" stroke="black" stroke-width="0.5" />
    <path d="M229.33 319.97V319.98" stroke="black" stroke-width="0.5" />
    <path d="M229.33 319.98V319.99" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.32V320.3" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.3V320.29" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.29V320.28" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.28V320.27" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.27V320.25" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.25V320.24" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.24V320.23" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.23V320.21" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.21V320.2" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.2V320.19" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.19V320.18" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.18V320.17" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.17V320.15" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.15V320.14" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.14V320.13" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.13V320.12" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.12V320.11" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.11V320.1" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.1V320.09" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.09V320.08" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.08V320.07" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.07V320.06" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.06V320.05" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.05V320.04" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.04V320.03" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.03V320.02" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.02V320.01" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.01V320" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320V319.99" stroke="black" stroke-width="0.5" />
    <path d="M229.22 319.88H228.67" stroke="black" stroke-width="0.5" />
    <path d="M229.33 325.6V320.32" stroke="black" stroke-width="0.5" />
    <path d="M228.67 325.6V325.93" stroke="black" stroke-width="0.5" />
    <path d="M228.67 325.6V325.93" stroke="black" stroke-width="0.5" />
    <path d="M228.67 320.32V325.6" stroke="black" stroke-width="0.5" />
    <path d="M228.67 319.99V320.32" stroke="black" stroke-width="0.5" />
    <path d="M228.67 326.04V325.6" stroke="black" stroke-width="0.5" />
    <path d="M228.67 326.04V325.6" stroke="black" stroke-width="0.5" />
    <path d="M228.67 325.6V320.32" stroke="black" stroke-width="0.5" />
    <path d="M228.67 319.88V320.32" stroke="black" stroke-width="0.5" />
    <path d="M228.67 320.32V319.88" stroke="black" stroke-width="0.5" />
    <path d="M228.67 325.6V320.32" stroke="black" stroke-width="0.5" />
    <path d="M228.67 320.32V319.99" stroke="black" stroke-width="0.5" />
    <path d="M228.67 320.32V325.6" stroke="black" stroke-width="0.5" />
    <path d="M229.33 320.32V325.6" stroke="black" stroke-width="0.5" />
    <path d="M166.65 259.49V277.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 259.49H125.09" stroke="black" stroke-width="0.5" />
    <path d="M167.31 258.83H125.09" stroke="black" stroke-width="0.5" />
    <path d="M125.09 258.83V277.31" stroke="black" stroke-width="0.5" />
    <path d="M125.09 277.31H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 277.31V258.83" stroke="black" stroke-width="0.5" />
    <path d="M166.65 164.73V182.55" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.73H125.09" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.07H125.09" stroke="black" stroke-width="0.5" />
    <path d="M125.09 164.07V182.55" stroke="black" stroke-width="0.5" />
    <path d="M125.09 182.55H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 182.55V164.07" stroke="black" stroke-width="0.5" />
    <path d="M347.42 228.29V209.92" stroke="black" stroke-width="0.5" />
    <path d="M347.42 209.92H228.67" stroke="black" stroke-width="0.5" />
    <path d="M228.67 209.92V228.39" stroke="black" stroke-width="0.5" />
    <path d="M504.22 209.92H366.12" stroke="black" stroke-width="0.5" />
    <path d="M366.12 209.92V228.29" stroke="black" stroke-width="0.5" />
    <path d="M705.27 5.48999H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 5.48999V23.97" stroke="black" stroke-width="0.5" />
    <path d="M565.58 23.97H705.27" stroke="black" stroke-width="0.5" />
    <path d="M705.27 23.97V5.48999" stroke="black" stroke-width="0.5" />
    <path d="M533.91 538.26H533.81" stroke="black" stroke-width="0.5" />
    <path d="M533.76 538.26H533.68" stroke="black" stroke-width="0.5" />
    <path d="M533.62 538.26H533.54" stroke="black" stroke-width="0.5" />
    <path d="M533.48 538.26H533.4" stroke="black" stroke-width="0.5" />
    <path d="M533.35 538.26H533.26" stroke="black" stroke-width="0.5" />
    <path d="M533.21 538.26H533.13" stroke="black" stroke-width="0.5" />
    <path d="M533.07 538.26H532.99" stroke="black" stroke-width="0.5" />
    <path d="M532.93 538.26H532.85" stroke="black" stroke-width="0.5" />
    <path d="M532.8 538.26H532.71" stroke="black" stroke-width="0.5" />
    <path d="M532.66 538.26H532.58" stroke="black" stroke-width="0.5" />
    <path d="M532.52 538.26H532.44" stroke="black" stroke-width="0.5" />
    <path d="M532.38 538.26H532.3" stroke="black" stroke-width="0.5" />
    <path d="M532.25 538.26H532.16" stroke="black" stroke-width="0.5" />
    <path d="M532.11 538.26H532.03" stroke="black" stroke-width="0.5" />
    <path d="M531.97 538.26H531.89" stroke="black" stroke-width="0.5" />
    <path d="M531.83 538.26H531.75" stroke="black" stroke-width="0.5" />
    <path d="M531.7 538.26H531.62" stroke="black" stroke-width="0.5" />
    <path d="M531.56 538.26H531.48" stroke="black" stroke-width="0.5" />
    <path d="M531.42 538.26H531.34" stroke="black" stroke-width="0.5" />
    <path d="M531.29 538.26H531.2" stroke="black" stroke-width="0.5" />
    <path d="M531.15 538.26H531.07" stroke="black" stroke-width="0.5" />
    <path d="M531.01 538.26H530.93" stroke="black" stroke-width="0.5" />
    <path d="M530.87 538.26H530.79" stroke="black" stroke-width="0.5" />
    <path d="M530.74 538.26H530.65" stroke="black" stroke-width="0.5" />
    <path d="M530.6 538.26H530.52" stroke="black" stroke-width="0.5" />
    <path d="M530.46 538.26H530.38" stroke="black" stroke-width="0.5" />
    <path d="M530.32 538.26H530.24" stroke="black" stroke-width="0.5" />
    <path d="M530.19 538.26H530.1" stroke="black" stroke-width="0.5" />
    <path d="M530.05 538.26H529.97" stroke="black" stroke-width="0.5" />
    <path d="M529.91 538.26H529.83" stroke="black" stroke-width="0.5" />
    <path d="M529.77 538.26H529.69" stroke="black" stroke-width="0.5" />
    <path d="M529.64 538.26H529.55" stroke="black" stroke-width="0.5" />
    <path d="M529.5 538.26H529.42" stroke="black" stroke-width="0.5" />
    <path d="M529.36 538.26H529.28" stroke="black" stroke-width="0.5" />
    <path d="M529.22 538.26H529.14" stroke="black" stroke-width="0.5" />
    <path d="M529.09 538.26H529" stroke="black" stroke-width="0.5" />
    <path d="M528.95 538.26H528.87" stroke="black" stroke-width="0.5" />
    <path d="M528.81 538.26H528.73" stroke="black" stroke-width="0.5" />
    <path d="M528.67 538.26H528.59" stroke="black" stroke-width="0.5" />
    <path d="M528.54 538.26H528.45" stroke="black" stroke-width="0.5" />
    <path d="M528.4 538.26H528.32" stroke="black" stroke-width="0.5" />
    <path d="M528.26 538.26H528.18" stroke="black" stroke-width="0.5" />
    <path d="M528.12 538.26H528.04" stroke="black" stroke-width="0.5" />
    <path d="M527.99 538.26H527.9" stroke="black" stroke-width="0.5" />
    <path d="M527.85 538.26H527.77" stroke="black" stroke-width="0.5" />
    <path d="M527.71 538.26H527.63" stroke="black" stroke-width="0.5" />
    <path d="M527.57 538.26H527.49" stroke="black" stroke-width="0.5" />
    <path d="M527.44 538.26H527.35" stroke="black" stroke-width="0.5" />
    <path d="M527.3 538.26H527.22" stroke="black" stroke-width="0.5" />
    <path d="M527.16 538.26H527.08" stroke="black" stroke-width="0.5" />
    <path d="M527.02 538.26H526.94" stroke="black" stroke-width="0.5" />
    <path d="M526.89 538.26H526.8" stroke="black" stroke-width="0.5" />
    <path d="M526.75 538.26H526.67" stroke="black" stroke-width="0.5" />
    <path d="M526.61 538.26H526.53" stroke="black" stroke-width="0.5" />
    <path d="M526.47 538.26H526.39" stroke="black" stroke-width="0.5" />
    <path d="M526.34 538.26H526.25" stroke="black" stroke-width="0.5" />
    <path d="M526.2 538.26H526.12" stroke="black" stroke-width="0.5" />
    <path d="M526.06 538.26H525.98" stroke="black" stroke-width="0.5" />
    <path d="M525.92 538.26H525.84" stroke="black" stroke-width="0.5" />
    <path d="M525.79 538.26H525.7" stroke="black" stroke-width="0.5" />
    <path d="M525.65 538.26H525.57" stroke="black" stroke-width="0.5" />
    <path d="M525.51 538.26H525.43" stroke="black" stroke-width="0.5" />
    <path d="M525.37 538.26H525.29" stroke="black" stroke-width="0.5" />
    <path d="M525.24 538.26H525.16" stroke="black" stroke-width="0.5" />
    <path d="M525.1 538.26H525.02" stroke="black" stroke-width="0.5" />
    <path d="M524.96 538.26H524.88" stroke="black" stroke-width="0.5" />
    <path d="M524.83 538.26H524.74" stroke="black" stroke-width="0.5" />
    <path d="M524.69 538.26H524.61" stroke="black" stroke-width="0.5" />
    <path d="M524.55 538.26H524.47" stroke="black" stroke-width="0.5" />
    <path d="M524.41 538.26H524.33" stroke="black" stroke-width="0.5" />
    <path d="M524.28 538.26H524.19" stroke="black" stroke-width="0.5" />
    <path d="M524.14 538.26H524.06" stroke="black" stroke-width="0.5" />
    <path d="M524 538.26H523.92" stroke="black" stroke-width="0.5" />
    <path d="M523.86 538.26H523.78" stroke="black" stroke-width="0.5" />
    <path d="M523.73 538.26H523.64" stroke="black" stroke-width="0.5" />
    <path d="M523.59 538.26H523.51" stroke="black" stroke-width="0.5" />
    <path d="M523.45 538.26H523.35" stroke="black" stroke-width="0.5" />
    <path d="M523.35 538.26V538.2" stroke="black" stroke-width="0.5" />
    <path d="M523.35 538.15V538.07" stroke="black" stroke-width="0.5" />
    <path d="M523.35 538.01V537.93" stroke="black" stroke-width="0.5" />
    <path d="M523.35 537.87V537.79" stroke="black" stroke-width="0.5" />
    <path d="M523.35 537.74V537.65" stroke="black" stroke-width="0.5" />
    <path d="M523.35 537.6V537.52" stroke="black" stroke-width="0.5" />
    <path d="M523.35 537.46V537.38" stroke="black" stroke-width="0.5" />
    <path d="M523.35 537.32V537.24" stroke="black" stroke-width="0.5" />
    <path d="M523.35 537.19V537.1" stroke="black" stroke-width="0.5" />
    <path d="M523.35 537.05V536.97" stroke="black" stroke-width="0.5" />
    <path d="M523.35 536.91V536.83" stroke="black" stroke-width="0.5" />
    <path d="M523.35 536.77V536.69" stroke="black" stroke-width="0.5" />
    <path d="M523.35 536.64V536.55" stroke="black" stroke-width="0.5" />
    <path d="M523.35 536.5V536.42" stroke="black" stroke-width="0.5" />
    <path d="M523.35 536.36V536.28" stroke="black" stroke-width="0.5" />
    <path d="M523.35 536.22V536.14" stroke="black" stroke-width="0.5" />
    <path d="M523.35 536.09V536" stroke="black" stroke-width="0.5" />
    <path d="M523.35 535.95V535.87" stroke="black" stroke-width="0.5" />
    <path d="M523.35 535.81V535.73" stroke="black" stroke-width="0.5" />
    <path d="M523.35 535.67V535.59" stroke="black" stroke-width="0.5" />
    <path d="M523.35 535.54V535.46" stroke="black" stroke-width="0.5" />
    <path d="M523.35 535.4V535.32" stroke="black" stroke-width="0.5" />
    <path d="M523.35 535.26V535.18" stroke="black" stroke-width="0.5" />
    <path d="M523.35 535.13V535.04" stroke="black" stroke-width="0.5" />
    <path d="M523.35 534.99V534.91" stroke="black" stroke-width="0.5" />
    <path d="M523.35 534.85V534.77" stroke="black" stroke-width="0.5" />
    <path d="M523.35 534.71V534.63" stroke="black" stroke-width="0.5" />
    <path d="M523.35 534.58V534.49" stroke="black" stroke-width="0.5" />
    <path d="M523.35 534.44V534.36" stroke="black" stroke-width="0.5" />
    <path d="M523.35 534.3V534.22" stroke="black" stroke-width="0.5" />
    <path d="M523.35 534.16V534.08" stroke="black" stroke-width="0.5" />
    <path d="M523.35 534.03V533.94" stroke="black" stroke-width="0.5" />
    <path d="M523.35 533.89V533.81" stroke="black" stroke-width="0.5" />
    <path d="M523.35 533.75V533.67" stroke="black" stroke-width="0.5" />
    <path d="M523.35 533.61V533.53" stroke="black" stroke-width="0.5" />
    <path d="M523.35 533.48V533.39" stroke="black" stroke-width="0.5" />
    <path d="M523.35 533.34V533.26" stroke="black" stroke-width="0.5" />
    <path d="M523.35 533.2V533.12" stroke="black" stroke-width="0.5" />
    <path d="M523.35 533.06V532.98" stroke="black" stroke-width="0.5" />
    <path d="M523.35 532.93V532.84" stroke="black" stroke-width="0.5" />
    <path d="M523.35 532.79V532.71" stroke="black" stroke-width="0.5" />
    <path d="M523.35 532.65V532.57" stroke="black" stroke-width="0.5" />
    <path d="M523.35 532.51V532.43" stroke="black" stroke-width="0.5" />
    <path d="M523.35 532.38V532.29" stroke="black" stroke-width="0.5" />
    <path d="M523.35 532.24V532.16" stroke="black" stroke-width="0.5" />
    <path d="M523.35 532.1V532.02" stroke="black" stroke-width="0.5" />
    <path d="M523.35 531.96V531.88" stroke="black" stroke-width="0.5" />
    <path d="M523.35 531.83V531.74" stroke="black" stroke-width="0.5" />
    <path d="M523.35 531.69V531.61" stroke="black" stroke-width="0.5" />
    <path d="M523.35 531.55V531.47" stroke="black" stroke-width="0.5" />
    <path d="M523.35 531.41V531.33" stroke="black" stroke-width="0.5" />
    <path d="M523.35 531.28V531.19" stroke="black" stroke-width="0.5" />
    <path d="M523.35 531.14V531.06" stroke="black" stroke-width="0.5" />
    <path d="M523.35 531V530.92" stroke="black" stroke-width="0.5" />
    <path d="M523.35 530.86V530.78" stroke="black" stroke-width="0.5" />
    <path d="M523.35 530.73V530.64" stroke="black" stroke-width="0.5" />
    <path d="M523.35 530.59V530.51" stroke="black" stroke-width="0.5" />
    <path d="M523.35 530.45V530.37" stroke="black" stroke-width="0.5" />
    <path d="M523.35 530.31V530.23" stroke="black" stroke-width="0.5" />
    <path d="M523.35 530.18V530.09" stroke="black" stroke-width="0.5" />
    <path d="M523.35 530.04V529.96" stroke="black" stroke-width="0.5" />
    <path d="M523.35 529.9V529.82" stroke="black" stroke-width="0.5" />
    <path d="M523.35 529.76V529.68" stroke="black" stroke-width="0.5" />
    <path d="M523.35 529.63V529.54" stroke="black" stroke-width="0.5" />
    <path d="M523.35 529.49V529.41" stroke="black" stroke-width="0.5" />
    <path d="M523.35 529.35V529.27" stroke="black" stroke-width="0.5" />
    <path d="M523.35 529.21V529.13" stroke="black" stroke-width="0.5" />
    <path d="M523.35 529.08V529" stroke="black" stroke-width="0.5" />
    <path d="M523.35 528.94V528.86" stroke="black" stroke-width="0.5" />
    <path d="M523.35 528.8V528.72" stroke="black" stroke-width="0.5" />
    <path d="M523.35 528.67V528.58" stroke="black" stroke-width="0.5" />
    <path d="M523.35 528.53V528.45" stroke="black" stroke-width="0.5" />
    <path d="M523.35 528.39V528.31" stroke="black" stroke-width="0.5" />
    <path d="M523.35 528.25V528.17" stroke="black" stroke-width="0.5" />
    <path d="M523.35 528.12V528.03" stroke="black" stroke-width="0.5" />
    <path d="M523.35 527.98V527.9" stroke="black" stroke-width="0.5" />
    <path d="M523.35 527.84V527.76" stroke="black" stroke-width="0.5" />
    <path d="M523.35 527.7V527.62" stroke="black" stroke-width="0.5" />
    <path d="M523.35 527.57V527.48" stroke="black" stroke-width="0.5" />
    <path d="M523.35 527.43V527.35" stroke="black" stroke-width="0.5" />
    <path d="M523.35 527.29V527.21" stroke="black" stroke-width="0.5" />
    <path d="M523.35 527.15V527.07" stroke="black" stroke-width="0.5" />
    <path d="M523.35 527.02V526.93" stroke="black" stroke-width="0.5" />
    <path d="M523.35 526.88V526.8" stroke="black" stroke-width="0.5" />
    <path d="M523.35 526.74V526.66" stroke="black" stroke-width="0.5" />
    <path d="M523.35 526.6V526.52" stroke="black" stroke-width="0.5" />
    <path d="M523.35 526.47V526.38" stroke="black" stroke-width="0.5" />
    <path d="M523.35 526.33V526.25" stroke="black" stroke-width="0.5" />
    <path d="M523.35 526.19V526.11" stroke="black" stroke-width="0.5" />
    <path d="M523.35 526.05V525.97" stroke="black" stroke-width="0.5" />
    <path d="M523.35 525.92V525.83" stroke="black" stroke-width="0.5" />
    <path d="M523.35 525.78V525.7" stroke="black" stroke-width="0.5" />
    <path d="M523.35 525.64V525.56" stroke="black" stroke-width="0.5" />
    <path d="M523.35 525.5V525.42" stroke="black" stroke-width="0.5" />
    <path d="M523.35 525.37V525.28" stroke="black" stroke-width="0.5" />
    <path d="M523.35 525.23V525.15" stroke="black" stroke-width="0.5" />
    <path d="M523.35 525.09V525.01" stroke="black" stroke-width="0.5" />
    <path d="M523.35 524.95V524.87" stroke="black" stroke-width="0.5" />
    <path d="M523.35 524.82V524.73" stroke="black" stroke-width="0.5" />
    <path d="M523.35 524.68V524.6" stroke="black" stroke-width="0.5" />
    <path d="M523.35 524.54V524.46" stroke="black" stroke-width="0.5" />
    <path d="M523.35 524.4V524.32" stroke="black" stroke-width="0.5" />
    <path d="M523.35 524.27V524.18" stroke="black" stroke-width="0.5" />
    <path d="M523.35 524.13V524.05" stroke="black" stroke-width="0.5" />
    <path d="M523.35 523.99V523.91" stroke="black" stroke-width="0.5" />
    <path d="M523.35 523.85V523.77" stroke="black" stroke-width="0.5" />
    <path d="M523.35 523.72V523.63" stroke="black" stroke-width="0.5" />
    <path d="M523.35 523.58V523.5" stroke="black" stroke-width="0.5" />
    <path d="M523.35 523.44V523.36" stroke="black" stroke-width="0.5" />
    <path d="M523.35 523.3V523.22" stroke="black" stroke-width="0.5" />
    <path d="M523.35 523.17V523.08" stroke="black" stroke-width="0.5" />
    <path d="M523.35 523.03V522.95" stroke="black" stroke-width="0.5" />
    <path d="M523.35 522.89V522.81" stroke="black" stroke-width="0.5" />
    <path d="M523.35 522.75V522.67" stroke="black" stroke-width="0.5" />
    <path d="M523.35 522.62V522.54" stroke="black" stroke-width="0.5" />
    <path d="M523.35 522.48V522.4" stroke="black" stroke-width="0.5" />
    <path d="M523.35 522.34V522.26" stroke="black" stroke-width="0.5" />
    <path d="M523.35 522.21V522.12" stroke="black" stroke-width="0.5" />
    <path d="M523.35 522.07V521.99" stroke="black" stroke-width="0.5" />
    <path d="M523.35 521.93V521.85" stroke="black" stroke-width="0.5" />
    <path d="M523.35 521.79V521.71" stroke="black" stroke-width="0.5" />
    <path d="M523.35 521.66V521.57" stroke="black" stroke-width="0.5" />
    <path d="M523.35 521.52V521.44" stroke="black" stroke-width="0.5" />
    <path d="M523.35 521.38V521.3" stroke="black" stroke-width="0.5" />
    <path d="M523.35 521.24V521.16" stroke="black" stroke-width="0.5" />
    <path d="M523.35 521.11V521.02" stroke="black" stroke-width="0.5" />
    <path d="M523.35 520.97V520.89" stroke="black" stroke-width="0.5" />
    <path d="M523.35 520.83V520.75" stroke="black" stroke-width="0.5" />
    <path d="M523.35 520.69V520.61" stroke="black" stroke-width="0.5" />
    <path d="M523.35 520.56V520.47" stroke="black" stroke-width="0.5" />
    <path d="M523.35 520.42V520.34" stroke="black" stroke-width="0.5" />
    <path d="M523.35 520.28V520.2" stroke="black" stroke-width="0.5" />
    <path d="M523.35 520.14V520.06" stroke="black" stroke-width="0.5" />
    <path d="M523.35 520.01V519.92" stroke="black" stroke-width="0.5" />
    <path d="M523.35 519.87V519.79" stroke="black" stroke-width="0.5" />
    <path d="M523.35 519.73V519.65" stroke="black" stroke-width="0.5" />
    <path d="M523.35 519.59V519.51" stroke="black" stroke-width="0.5" />
    <path d="M523.35 519.46V519.37" stroke="black" stroke-width="0.5" />
    <path d="M523.35 519.32V519.24" stroke="black" stroke-width="0.5" />
    <path d="M523.35 519.18V519.1" stroke="black" stroke-width="0.5" />
    <path d="M523.35 519.04V518.96" stroke="black" stroke-width="0.5" />
    <path d="M523.35 518.91V518.82" stroke="black" stroke-width="0.5" />
    <path d="M523.35 518.77V518.69" stroke="black" stroke-width="0.5" />
    <path d="M523.35 518.63V518.55" stroke="black" stroke-width="0.5" />
    <path d="M523.35 518.49V518.41" stroke="black" stroke-width="0.5" />
    <path d="M523.35 518.36V518.27" stroke="black" stroke-width="0.5" />
    <path d="M523.35 518.22V518.14" stroke="black" stroke-width="0.5" />
    <path d="M523.35 518.08V518" stroke="black" stroke-width="0.5" />
    <path d="M523.35 517.94V517.86" stroke="black" stroke-width="0.5" />
    <path d="M523.35 517.81V517.72" stroke="black" stroke-width="0.5" />
    <path d="M523.35 517.67V517.59" stroke="black" stroke-width="0.5" />
    <path d="M523.35 517.53V517.45" stroke="black" stroke-width="0.5" />
    <path d="M523.35 517.39V517.31" stroke="black" stroke-width="0.5" />
    <path d="M523.35 517.26V517.17" stroke="black" stroke-width="0.5" />
    <path d="M523.35 517.12V517.04" stroke="black" stroke-width="0.5" />
    <path d="M523.35 516.98V516.9" stroke="black" stroke-width="0.5" />
    <path d="M523.35 516.84V516.76" stroke="black" stroke-width="0.5" />
    <path d="M523.35 516.71V516.62" stroke="black" stroke-width="0.5" />
    <path d="M523.35 516.57V516.49" stroke="black" stroke-width="0.5" />
    <path d="M523.35 516.43V516.35" stroke="black" stroke-width="0.5" />
    <path d="M523.35 516.29V516.21" stroke="black" stroke-width="0.5" />
    <path d="M523.35 516.16V516.08" stroke="black" stroke-width="0.5" />
    <path d="M523.35 516.02V515.94" stroke="black" stroke-width="0.5" />
    <path d="M523.35 515.88V515.8" stroke="black" stroke-width="0.5" />
    <path d="M523.35 515.75V515.66" stroke="black" stroke-width="0.5" />
    <path d="M523.35 515.61V515.53" stroke="black" stroke-width="0.5" />
    <path d="M523.35 515.47V515.39" stroke="black" stroke-width="0.5" />
    <path d="M523.35 515.33V515.25" stroke="black" stroke-width="0.5" />
    <path d="M523.35 515.2V515.11" stroke="black" stroke-width="0.5" />
    <path d="M523.35 515.06V514.98" stroke="black" stroke-width="0.5" />
    <path d="M523.35 514.92V514.84" stroke="black" stroke-width="0.5" />
    <path d="M523.35 514.78V514.7" stroke="black" stroke-width="0.5" />
    <path d="M523.35 514.65V514.56" stroke="black" stroke-width="0.5" />
    <path d="M523.35 514.51V514.43" stroke="black" stroke-width="0.5" />
    <path d="M523.35 514.37V514.29" stroke="black" stroke-width="0.5" />
    <path d="M523.35 514.23V514.15" stroke="black" stroke-width="0.5" />
    <path d="M523.35 514.1V514.01" stroke="black" stroke-width="0.5" />
    <path d="M523.35 513.96V513.88" stroke="black" stroke-width="0.5" />
    <path d="M523.35 513.82V513.74" stroke="black" stroke-width="0.5" />
    <path d="M523.35 513.68V513.6" stroke="black" stroke-width="0.5" />
    <path d="M523.35 513.55V513.46" stroke="black" stroke-width="0.5" />
    <path d="M523.35 513.41V513.33" stroke="black" stroke-width="0.5" />
    <path d="M523.35 513.27V513.19" stroke="black" stroke-width="0.5" />
    <path d="M523.35 513.13V513.05" stroke="black" stroke-width="0.5" />
    <path d="M523.35 513V512.91" stroke="black" stroke-width="0.5" />
    <path d="M523.35 512.86V512.78" stroke="black" stroke-width="0.5" />
    <path d="M523.35 512.72V512.64" stroke="black" stroke-width="0.5" />
    <path d="M523.35 512.58V512.5" stroke="black" stroke-width="0.5" />
    <path d="M523.35 512.45V512.36" stroke="black" stroke-width="0.5" />
    <path d="M523.35 512.31V512.23" stroke="black" stroke-width="0.5" />
    <path d="M523.35 512.17V512.09" stroke="black" stroke-width="0.5" />
    <path d="M523.35 512.03V511.95" stroke="black" stroke-width="0.5" />
    <path d="M523.35 511.9V511.81" stroke="black" stroke-width="0.5" />
    <path d="M523.35 511.76V511.68" stroke="black" stroke-width="0.5" />
    <path d="M523.35 511.62V511.54" stroke="black" stroke-width="0.5" />
    <path d="M523.35 511.48V511.4" stroke="black" stroke-width="0.5" />
    <path d="M523.35 511.35V511.26" stroke="black" stroke-width="0.5" />
    <path d="M523.35 511.21V511.13" stroke="black" stroke-width="0.5" />
    <path d="M523.35 511.07V510.99" stroke="black" stroke-width="0.5" />
    <path d="M523.35 510.93V510.85" stroke="black" stroke-width="0.5" />
    <path d="M523.35 510.8V510.71" stroke="black" stroke-width="0.5" />
    <path d="M523.35 510.66V510.58" stroke="black" stroke-width="0.5" />
    <path d="M523.35 510.52V510.44" stroke="black" stroke-width="0.5" />
    <path d="M523.35 510.38V510.3" stroke="black" stroke-width="0.5" />
    <path d="M523.35 510.25V510.16" stroke="black" stroke-width="0.5" />
    <path d="M523.35 510.11V510.03" stroke="black" stroke-width="0.5" />
    <path d="M523.35 509.97V509.89" stroke="black" stroke-width="0.5" />
    <path d="M523.35 509.83V509.75" stroke="black" stroke-width="0.5" />
    <path d="M523.35 509.7V509.62" stroke="black" stroke-width="0.5" />
    <path d="M523.35 509.56V509.48" stroke="black" stroke-width="0.5" />
    <path d="M523.35 509.42V509.34" stroke="black" stroke-width="0.5" />
    <path d="M523.35 509.29V509.2" stroke="black" stroke-width="0.5" />
    <path d="M523.35 509.15V509.07" stroke="black" stroke-width="0.5" />
    <path d="M523.35 509.01V508.93" stroke="black" stroke-width="0.5" />
    <path d="M523.35 508.87V508.79" stroke="black" stroke-width="0.5" />
    <path d="M523.35 508.74V508.65" stroke="black" stroke-width="0.5" />
    <path d="M523.35 508.6V508.52" stroke="black" stroke-width="0.5" />
    <path d="M523.35 508.46V508.38" stroke="black" stroke-width="0.5" />
    <path d="M523.35 508.32V508.24" stroke="black" stroke-width="0.5" />
    <path d="M523.35 508.19V508.1" stroke="black" stroke-width="0.5" />
    <path d="M523.35 508.05V507.97" stroke="black" stroke-width="0.5" />
    <path d="M523.35 507.91V507.83" stroke="black" stroke-width="0.5" />
    <path d="M523.35 507.77V507.69" stroke="black" stroke-width="0.5" />
    <path d="M523.35 507.64V507.55" stroke="black" stroke-width="0.5" />
    <path d="M523.35 507.5V507.42" stroke="black" stroke-width="0.5" />
    <path d="M523.35 507.36V507.28" stroke="black" stroke-width="0.5" />
    <path d="M523.35 507.22V507.14" stroke="black" stroke-width="0.5" />
    <path d="M523.35 507.09V507" stroke="black" stroke-width="0.5" />
    <path d="M523.35 506.95V506.87" stroke="black" stroke-width="0.5" />
    <path d="M523.35 506.81V506.73" stroke="black" stroke-width="0.5" />
    <path d="M523.35 506.67V506.59" stroke="black" stroke-width="0.5" />
    <path d="M523.35 506.54V506.45" stroke="black" stroke-width="0.5" />
    <path d="M523.35 506.4V506.32" stroke="black" stroke-width="0.5" />
    <path d="M523.35 506.26V506.18" stroke="black" stroke-width="0.5" />
    <path d="M523.35 506.12V506.04" stroke="black" stroke-width="0.5" />
    <path d="M523.35 505.99V505.9" stroke="black" stroke-width="0.5" />
    <path d="M523.35 505.85V505.77" stroke="black" stroke-width="0.5" />
    <path d="M523.35 505.71V505.63" stroke="black" stroke-width="0.5" />
    <path d="M523.35 505.57V505.49" stroke="black" stroke-width="0.5" />
    <path d="M523.35 505.44V505.35" stroke="black" stroke-width="0.5" />
    <path d="M523.35 505.3V505.22" stroke="black" stroke-width="0.5" />
    <path d="M523.35 505.16V505.08" stroke="black" stroke-width="0.5" />
    <path d="M523.35 505.02V504.94" stroke="black" stroke-width="0.5" />
    <path d="M523.35 504.89V504.8" stroke="black" stroke-width="0.5" />
    <path d="M523.35 504.75V504.67" stroke="black" stroke-width="0.5" />
    <path d="M523.35 504.61V504.53" stroke="black" stroke-width="0.5" />
    <path d="M523.35 504.47V504.39" stroke="black" stroke-width="0.5" />
    <path d="M523.35 504.34V504.25" stroke="black" stroke-width="0.5" />
    <path d="M523.35 504.2V504.12" stroke="black" stroke-width="0.5" />
    <path d="M523.35 504.06V503.98" stroke="black" stroke-width="0.5" />
    <path d="M523.35 503.92V503.84" stroke="black" stroke-width="0.5" />
    <path d="M523.35 503.79V503.7" stroke="black" stroke-width="0.5" />
    <path d="M523.35 503.65V503.57" stroke="black" stroke-width="0.5" />
    <path d="M523.35 503.51V503.43" stroke="black" stroke-width="0.5" />
    <path d="M523.35 503.37V503.29" stroke="black" stroke-width="0.5" />
    <path d="M523.35 503.24V503.16" stroke="black" stroke-width="0.5" />
    <path d="M523.35 503.1V503.02" stroke="black" stroke-width="0.5" />
    <path d="M523.35 502.96V502.88" stroke="black" stroke-width="0.5" />
    <path d="M523.35 502.83V502.74" stroke="black" stroke-width="0.5" />
    <path d="M523.35 502.69V502.61" stroke="black" stroke-width="0.5" />
    <path d="M523.35 502.55V502.47" stroke="black" stroke-width="0.5" />
    <path d="M523.35 502.41V502.33" stroke="black" stroke-width="0.5" />
    <path d="M523.35 502.28V502.19" stroke="black" stroke-width="0.5" />
    <path d="M523.35 502.14V502.06" stroke="black" stroke-width="0.5" />
    <path d="M523.35 502V501.92" stroke="black" stroke-width="0.5" />
    <path d="M523.35 501.86V501.78" stroke="black" stroke-width="0.5" />
    <path d="M523.35 501.73V501.64" stroke="black" stroke-width="0.5" />
    <path d="M523.35 501.59V501.51" stroke="black" stroke-width="0.5" />
    <path d="M523.35 501.45V501.37" stroke="black" stroke-width="0.5" />
    <path d="M523.35 501.31V501.23" stroke="black" stroke-width="0.5" />
    <path d="M523.35 501.18V501.09" stroke="black" stroke-width="0.5" />
    <path d="M523.35 501.04V500.96" stroke="black" stroke-width="0.5" />
    <path d="M523.35 500.9V500.82" stroke="black" stroke-width="0.5" />
    <path d="M523.35 500.76V500.68" stroke="black" stroke-width="0.5" />
    <path d="M523.35 500.63V500.54" stroke="black" stroke-width="0.5" />
    <path d="M523.35 500.49V500.41" stroke="black" stroke-width="0.5" />
    <path d="M523.35 500.35V500.27" stroke="black" stroke-width="0.5" />
    <path d="M523.35 500.21V500.13" stroke="black" stroke-width="0.5" />
    <path d="M523.35 500.08V499.99" stroke="black" stroke-width="0.5" />
    <path d="M523.35 499.94V499.86" stroke="black" stroke-width="0.5" />
    <path d="M523.35 499.8V499.72" stroke="black" stroke-width="0.5" />
    <path d="M523.35 499.66V499.58" stroke="black" stroke-width="0.5" />
    <path d="M523.35 499.53V499.44" stroke="black" stroke-width="0.5" />
    <path d="M523.35 499.39V499.31" stroke="black" stroke-width="0.5" />
    <path d="M523.35 499.25V499.17" stroke="black" stroke-width="0.5" />
    <path d="M523.35 499.11V499.03" stroke="black" stroke-width="0.5" />
    <path d="M523.35 498.98V498.89" stroke="black" stroke-width="0.5" />
    <path d="M523.35 498.84V498.76" stroke="black" stroke-width="0.5" />
    <path d="M523.35 498.7V498.62" stroke="black" stroke-width="0.5" />
    <path d="M523.35 498.56V498.48" stroke="black" stroke-width="0.5" />
    <path d="M523.35 498.43V498.34" stroke="black" stroke-width="0.5" />
    <path d="M523.35 498.29V498.21" stroke="black" stroke-width="0.5" />
    <path d="M523.35 498.15V498.07" stroke="black" stroke-width="0.5" />
    <path d="M523.35 498.01V497.93" stroke="black" stroke-width="0.5" />
    <path d="M523.35 497.88V497.79" stroke="black" stroke-width="0.5" />
    <path d="M523.35 497.74V497.66" stroke="black" stroke-width="0.5" />
    <path d="M523.35 497.6V497.52" stroke="black" stroke-width="0.5" />
    <path d="M523.35 497.46V497.38" stroke="black" stroke-width="0.5" />
    <path d="M523.35 497.33V497.24" stroke="black" stroke-width="0.5" />
    <path d="M523.35 497.19V497.11" stroke="black" stroke-width="0.5" />
    <path d="M523.35 497.05V496.97" stroke="black" stroke-width="0.5" />
    <path d="M523.35 496.91V496.83" stroke="black" stroke-width="0.5" />
    <path d="M523.35 496.78V496.7" stroke="black" stroke-width="0.5" />
    <path d="M523.35 496.64V496.56" stroke="black" stroke-width="0.5" />
    <path d="M523.35 496.5V496.42" stroke="black" stroke-width="0.5" />
    <path d="M523.35 496.37V496.28" stroke="black" stroke-width="0.5" />
    <path d="M523.35 496.23V496.15" stroke="black" stroke-width="0.5" />
    <path d="M523.35 496.09V496.04" stroke="black" stroke-width="0.5" />
    <path d="M523.35 496.04H523.45" stroke="black" stroke-width="0.5" />
    <path d="M523.51 496.04H523.59" stroke="black" stroke-width="0.5" />
    <path d="M523.64 496.04H523.73" stroke="black" stroke-width="0.5" />
    <path d="M523.78 496.04H523.86" stroke="black" stroke-width="0.5" />
    <path d="M523.92 496.04H524" stroke="black" stroke-width="0.5" />
    <path d="M524.06 496.04H524.14" stroke="black" stroke-width="0.5" />
    <path d="M524.19 496.04H524.28" stroke="black" stroke-width="0.5" />
    <path d="M524.33 496.04H524.41" stroke="black" stroke-width="0.5" />
    <path d="M524.47 496.04H524.55" stroke="black" stroke-width="0.5" />
    <path d="M524.61 496.04H524.69" stroke="black" stroke-width="0.5" />
    <path d="M524.74 496.04H524.83" stroke="black" stroke-width="0.5" />
    <path d="M524.88 496.04H524.96" stroke="black" stroke-width="0.5" />
    <path d="M525.02 496.04H525.1" stroke="black" stroke-width="0.5" />
    <path d="M525.16 496.04H525.24" stroke="black" stroke-width="0.5" />
    <path d="M525.29 496.04H525.37" stroke="black" stroke-width="0.5" />
    <path d="M525.43 496.04H525.51" stroke="black" stroke-width="0.5" />
    <path d="M525.57 496.04H525.65" stroke="black" stroke-width="0.5" />
    <path d="M525.7 496.04H525.79" stroke="black" stroke-width="0.5" />
    <path d="M525.84 496.04H525.92" stroke="black" stroke-width="0.5" />
    <path d="M525.98 496.04H526.06" stroke="black" stroke-width="0.5" />
    <path d="M526.12 496.04H526.2" stroke="black" stroke-width="0.5" />
    <path d="M526.25 496.04H526.34" stroke="black" stroke-width="0.5" />
    <path d="M526.39 496.04H526.47" stroke="black" stroke-width="0.5" />
    <path d="M526.53 496.04H526.61" stroke="black" stroke-width="0.5" />
    <path d="M526.67 496.04H526.75" stroke="black" stroke-width="0.5" />
    <path d="M526.8 496.04H526.89" stroke="black" stroke-width="0.5" />
    <path d="M526.94 496.04H527.02" stroke="black" stroke-width="0.5" />
    <path d="M527.08 496.04H527.16" stroke="black" stroke-width="0.5" />
    <path d="M527.22 496.04H527.3" stroke="black" stroke-width="0.5" />
    <path d="M527.35 496.04H527.44" stroke="black" stroke-width="0.5" />
    <path d="M527.49 496.04H527.57" stroke="black" stroke-width="0.5" />
    <path d="M527.63 496.04H527.71" stroke="black" stroke-width="0.5" />
    <path d="M527.77 496.04H527.85" stroke="black" stroke-width="0.5" />
    <path d="M527.9 496.04H527.99" stroke="black" stroke-width="0.5" />
    <path d="M528.04 496.04H528.12" stroke="black" stroke-width="0.5" />
    <path d="M528.18 496.04H528.26" stroke="black" stroke-width="0.5" />
    <path d="M528.32 496.04H528.4" stroke="black" stroke-width="0.5" />
    <path d="M528.45 496.04H528.54" stroke="black" stroke-width="0.5" />
    <path d="M528.59 496.04H528.67" stroke="black" stroke-width="0.5" />
    <path d="M528.73 496.04H528.81" stroke="black" stroke-width="0.5" />
    <path d="M528.87 496.04H528.95" stroke="black" stroke-width="0.5" />
    <path d="M529 496.04H529.09" stroke="black" stroke-width="0.5" />
    <path d="M529.14 496.04H529.22" stroke="black" stroke-width="0.5" />
    <path d="M529.28 496.04H529.36" stroke="black" stroke-width="0.5" />
    <path d="M529.42 496.04H529.5" stroke="black" stroke-width="0.5" />
    <path d="M529.55 496.04H529.64" stroke="black" stroke-width="0.5" />
    <path d="M529.69 496.04H529.77" stroke="black" stroke-width="0.5" />
    <path d="M529.83 496.04H529.91" stroke="black" stroke-width="0.5" />
    <path d="M529.97 496.04H530.05" stroke="black" stroke-width="0.5" />
    <path d="M530.1 496.04H530.19" stroke="black" stroke-width="0.5" />
    <path d="M530.24 496.04H530.32" stroke="black" stroke-width="0.5" />
    <path d="M530.38 496.04H530.46" stroke="black" stroke-width="0.5" />
    <path d="M530.52 496.04H530.6" stroke="black" stroke-width="0.5" />
    <path d="M530.65 496.04H530.74" stroke="black" stroke-width="0.5" />
    <path d="M530.79 496.04H530.87" stroke="black" stroke-width="0.5" />
    <path d="M530.93 496.04H531.01" stroke="black" stroke-width="0.5" />
    <path d="M531.07 496.04H531.15" stroke="black" stroke-width="0.5" />
    <path d="M531.2 496.04H531.29" stroke="black" stroke-width="0.5" />
    <path d="M531.34 496.04H531.42" stroke="black" stroke-width="0.5" />
    <path d="M531.48 496.04H531.56" stroke="black" stroke-width="0.5" />
    <path d="M531.62 496.04H531.7" stroke="black" stroke-width="0.5" />
    <path d="M531.75 496.04H531.83" stroke="black" stroke-width="0.5" />
    <path d="M531.89 496.04H531.97" stroke="black" stroke-width="0.5" />
    <path d="M532.03 496.04H532.11" stroke="black" stroke-width="0.5" />
    <path d="M532.16 496.04H532.25" stroke="black" stroke-width="0.5" />
    <path d="M532.3 496.04H532.38" stroke="black" stroke-width="0.5" />
    <path d="M532.44 496.04H532.52" stroke="black" stroke-width="0.5" />
    <path d="M532.58 496.04H532.66" stroke="black" stroke-width="0.5" />
    <path d="M532.71 496.04H532.8" stroke="black" stroke-width="0.5" />
    <path d="M532.85 496.04H532.93" stroke="black" stroke-width="0.5" />
    <path d="M532.99 496.04H533.07" stroke="black" stroke-width="0.5" />
    <path d="M533.13 496.04H533.21" stroke="black" stroke-width="0.5" />
    <path d="M533.26 496.04H533.35" stroke="black" stroke-width="0.5" />
    <path d="M533.4 496.04H533.48" stroke="black" stroke-width="0.5" />
    <path d="M533.54 496.04H533.62" stroke="black" stroke-width="0.5" />
    <path d="M533.68 496.04H533.76" stroke="black" stroke-width="0.5" />
    <path d="M533.81 496.04H533.91" stroke="black" stroke-width="0.5" />
    <path d="M533.91 496.04V496.09" stroke="black" stroke-width="0.5" />
    <path d="M533.91 496.15V496.23" stroke="black" stroke-width="0.5" />
    <path d="M533.91 496.28V496.37" stroke="black" stroke-width="0.5" />
    <path d="M533.91 496.42V496.5" stroke="black" stroke-width="0.5" />
    <path d="M533.91 496.56V496.64" stroke="black" stroke-width="0.5" />
    <path d="M533.91 496.7V496.78" stroke="black" stroke-width="0.5" />
    <path d="M533.91 496.83V496.91" stroke="black" stroke-width="0.5" />
    <path d="M533.91 496.97V497.05" stroke="black" stroke-width="0.5" />
    <path d="M533.91 497.11V497.19" stroke="black" stroke-width="0.5" />
    <path d="M533.91 497.24V497.33" stroke="black" stroke-width="0.5" />
    <path d="M533.91 497.38V497.46" stroke="black" stroke-width="0.5" />
    <path d="M533.91 497.52V497.6" stroke="black" stroke-width="0.5" />
    <path d="M533.91 497.66V497.74" stroke="black" stroke-width="0.5" />
    <path d="M533.91 497.79V497.88" stroke="black" stroke-width="0.5" />
    <path d="M533.91 497.93V498.01" stroke="black" stroke-width="0.5" />
    <path d="M533.91 498.07V498.15" stroke="black" stroke-width="0.5" />
    <path d="M533.91 498.21V498.29" stroke="black" stroke-width="0.5" />
    <path d="M533.91 498.34V498.43" stroke="black" stroke-width="0.5" />
    <path d="M533.91 498.48V498.56" stroke="black" stroke-width="0.5" />
    <path d="M533.91 498.62V498.7" stroke="black" stroke-width="0.5" />
    <path d="M533.91 498.76V498.84" stroke="black" stroke-width="0.5" />
    <path d="M533.91 498.89V498.98" stroke="black" stroke-width="0.5" />
    <path d="M533.91 499.03V499.11" stroke="black" stroke-width="0.5" />
    <path d="M533.91 499.17V499.25" stroke="black" stroke-width="0.5" />
    <path d="M533.91 499.31V499.39" stroke="black" stroke-width="0.5" />
    <path d="M533.91 499.44V499.53" stroke="black" stroke-width="0.5" />
    <path d="M533.91 499.58V499.66" stroke="black" stroke-width="0.5" />
    <path d="M533.91 499.72V499.8" stroke="black" stroke-width="0.5" />
    <path d="M533.91 499.86V499.94" stroke="black" stroke-width="0.5" />
    <path d="M533.91 499.99V500.08" stroke="black" stroke-width="0.5" />
    <path d="M533.91 500.13V500.21" stroke="black" stroke-width="0.5" />
    <path d="M533.91 500.27V500.35" stroke="black" stroke-width="0.5" />
    <path d="M533.91 500.41V500.49" stroke="black" stroke-width="0.5" />
    <path d="M533.91 500.54V500.63" stroke="black" stroke-width="0.5" />
    <path d="M533.91 500.68V500.76" stroke="black" stroke-width="0.5" />
    <path d="M533.91 500.82V500.9" stroke="black" stroke-width="0.5" />
    <path d="M533.91 500.96V501.04" stroke="black" stroke-width="0.5" />
    <path d="M533.91 501.09V501.18" stroke="black" stroke-width="0.5" />
    <path d="M533.91 501.23V501.31" stroke="black" stroke-width="0.5" />
    <path d="M533.91 501.37V501.45" stroke="black" stroke-width="0.5" />
    <path d="M533.91 501.51V501.59" stroke="black" stroke-width="0.5" />
    <path d="M533.91 501.64V501.73" stroke="black" stroke-width="0.5" />
    <path d="M533.91 501.78V501.86" stroke="black" stroke-width="0.5" />
    <path d="M533.91 501.92V502" stroke="black" stroke-width="0.5" />
    <path d="M533.91 502.06V502.14" stroke="black" stroke-width="0.5" />
    <path d="M533.91 502.19V502.28" stroke="black" stroke-width="0.5" />
    <path d="M533.91 502.33V502.41" stroke="black" stroke-width="0.5" />
    <path d="M533.91 502.47V502.55" stroke="black" stroke-width="0.5" />
    <path d="M533.91 502.61V502.69" stroke="black" stroke-width="0.5" />
    <path d="M533.91 502.74V502.83" stroke="black" stroke-width="0.5" />
    <path d="M533.91 502.88V502.96" stroke="black" stroke-width="0.5" />
    <path d="M533.91 503.02V503.1" stroke="black" stroke-width="0.5" />
    <path d="M533.91 503.16V503.24" stroke="black" stroke-width="0.5" />
    <path d="M533.91 503.29V503.37" stroke="black" stroke-width="0.5" />
    <path d="M533.91 503.43V503.51" stroke="black" stroke-width="0.5" />
    <path d="M533.91 503.57V503.65" stroke="black" stroke-width="0.5" />
    <path d="M533.91 503.7V503.79" stroke="black" stroke-width="0.5" />
    <path d="M533.91 503.84V503.92" stroke="black" stroke-width="0.5" />
    <path d="M533.91 503.98V504.06" stroke="black" stroke-width="0.5" />
    <path d="M533.91 504.12V504.2" stroke="black" stroke-width="0.5" />
    <path d="M533.91 504.25V504.34" stroke="black" stroke-width="0.5" />
    <path d="M533.91 504.39V504.47" stroke="black" stroke-width="0.5" />
    <path d="M533.91 504.53V504.61" stroke="black" stroke-width="0.5" />
    <path d="M533.91 504.67V504.75" stroke="black" stroke-width="0.5" />
    <path d="M533.91 504.8V504.89" stroke="black" stroke-width="0.5" />
    <path d="M533.91 504.94V505.02" stroke="black" stroke-width="0.5" />
    <path d="M533.91 505.08V505.16" stroke="black" stroke-width="0.5" />
    <path d="M533.91 505.22V505.3" stroke="black" stroke-width="0.5" />
    <path d="M533.91 505.35V505.44" stroke="black" stroke-width="0.5" />
    <path d="M533.91 505.49V505.57" stroke="black" stroke-width="0.5" />
    <path d="M533.91 505.63V505.71" stroke="black" stroke-width="0.5" />
    <path d="M533.91 505.77V505.85" stroke="black" stroke-width="0.5" />
    <path d="M533.91 505.9V505.99" stroke="black" stroke-width="0.5" />
    <path d="M533.91 506.04V506.12" stroke="black" stroke-width="0.5" />
    <path d="M533.91 506.18V506.26" stroke="black" stroke-width="0.5" />
    <path d="M533.91 506.32V506.4" stroke="black" stroke-width="0.5" />
    <path d="M533.91 506.45V506.54" stroke="black" stroke-width="0.5" />
    <path d="M533.91 506.59V506.67" stroke="black" stroke-width="0.5" />
    <path d="M533.91 506.73V506.81" stroke="black" stroke-width="0.5" />
    <path d="M533.91 506.87V506.95" stroke="black" stroke-width="0.5" />
    <path d="M533.91 507V507.09" stroke="black" stroke-width="0.5" />
    <path d="M533.91 507.14V507.22" stroke="black" stroke-width="0.5" />
    <path d="M533.91 507.28V507.36" stroke="black" stroke-width="0.5" />
    <path d="M533.91 507.42V507.5" stroke="black" stroke-width="0.5" />
    <path d="M533.91 507.55V507.64" stroke="black" stroke-width="0.5" />
    <path d="M533.91 507.69V507.77" stroke="black" stroke-width="0.5" />
    <path d="M533.91 507.83V507.91" stroke="black" stroke-width="0.5" />
    <path d="M533.91 507.97V508.05" stroke="black" stroke-width="0.5" />
    <path d="M533.91 508.1V508.19" stroke="black" stroke-width="0.5" />
    <path d="M533.91 508.24V508.32" stroke="black" stroke-width="0.5" />
    <path d="M533.91 508.38V508.46" stroke="black" stroke-width="0.5" />
    <path d="M533.91 508.52V508.6" stroke="black" stroke-width="0.5" />
    <path d="M533.91 508.65V508.74" stroke="black" stroke-width="0.5" />
    <path d="M533.91 508.79V508.87" stroke="black" stroke-width="0.5" />
    <path d="M533.91 508.93V509.01" stroke="black" stroke-width="0.5" />
    <path d="M533.91 509.07V509.15" stroke="black" stroke-width="0.5" />
    <path d="M533.91 509.2V509.29" stroke="black" stroke-width="0.5" />
    <path d="M533.91 509.34V509.42" stroke="black" stroke-width="0.5" />
    <path d="M533.91 509.48V509.56" stroke="black" stroke-width="0.5" />
    <path d="M533.91 509.62V509.7" stroke="black" stroke-width="0.5" />
    <path d="M533.91 509.75V509.83" stroke="black" stroke-width="0.5" />
    <path d="M533.91 509.89V509.97" stroke="black" stroke-width="0.5" />
    <path d="M533.91 510.03V510.11" stroke="black" stroke-width="0.5" />
    <path d="M533.91 510.16V510.25" stroke="black" stroke-width="0.5" />
    <path d="M533.91 510.3V510.38" stroke="black" stroke-width="0.5" />
    <path d="M533.91 510.44V510.52" stroke="black" stroke-width="0.5" />
    <path d="M533.91 510.58V510.66" stroke="black" stroke-width="0.5" />
    <path d="M533.91 510.71V510.8" stroke="black" stroke-width="0.5" />
    <path d="M533.91 510.85V510.93" stroke="black" stroke-width="0.5" />
    <path d="M533.91 510.99V511.07" stroke="black" stroke-width="0.5" />
    <path d="M533.91 511.13V511.21" stroke="black" stroke-width="0.5" />
    <path d="M533.91 511.26V511.35" stroke="black" stroke-width="0.5" />
    <path d="M533.91 511.4V511.48" stroke="black" stroke-width="0.5" />
    <path d="M533.91 511.54V511.62" stroke="black" stroke-width="0.5" />
    <path d="M533.91 511.68V511.76" stroke="black" stroke-width="0.5" />
    <path d="M533.91 511.81V511.9" stroke="black" stroke-width="0.5" />
    <path d="M533.91 511.95V512.03" stroke="black" stroke-width="0.5" />
    <path d="M533.91 512.09V512.17" stroke="black" stroke-width="0.5" />
    <path d="M533.91 512.23V512.31" stroke="black" stroke-width="0.5" />
    <path d="M533.91 512.36V512.45" stroke="black" stroke-width="0.5" />
    <path d="M533.91 512.5V512.58" stroke="black" stroke-width="0.5" />
    <path d="M533.91 512.64V512.72" stroke="black" stroke-width="0.5" />
    <path d="M533.91 512.78V512.86" stroke="black" stroke-width="0.5" />
    <path d="M533.91 512.91V513" stroke="black" stroke-width="0.5" />
    <path d="M533.91 513.05V513.13" stroke="black" stroke-width="0.5" />
    <path d="M533.91 513.19V513.27" stroke="black" stroke-width="0.5" />
    <path d="M533.91 513.33V513.41" stroke="black" stroke-width="0.5" />
    <path d="M533.91 513.46V513.55" stroke="black" stroke-width="0.5" />
    <path d="M533.91 513.6V513.68" stroke="black" stroke-width="0.5" />
    <path d="M533.91 513.74V513.82" stroke="black" stroke-width="0.5" />
    <path d="M533.91 513.88V513.96" stroke="black" stroke-width="0.5" />
    <path d="M533.91 514.01V514.1" stroke="black" stroke-width="0.5" />
    <path d="M533.91 514.15V514.23" stroke="black" stroke-width="0.5" />
    <path d="M533.91 514.29V514.37" stroke="black" stroke-width="0.5" />
    <path d="M533.91 514.43V514.51" stroke="black" stroke-width="0.5" />
    <path d="M533.91 514.56V514.65" stroke="black" stroke-width="0.5" />
    <path d="M533.91 514.7V514.78" stroke="black" stroke-width="0.5" />
    <path d="M533.91 514.84V514.92" stroke="black" stroke-width="0.5" />
    <path d="M533.91 514.98V515.06" stroke="black" stroke-width="0.5" />
    <path d="M533.91 515.11V515.2" stroke="black" stroke-width="0.5" />
    <path d="M533.91 515.25V515.33" stroke="black" stroke-width="0.5" />
    <path d="M533.91 515.39V515.47" stroke="black" stroke-width="0.5" />
    <path d="M533.91 515.53V515.61" stroke="black" stroke-width="0.5" />
    <path d="M533.91 515.66V515.75" stroke="black" stroke-width="0.5" />
    <path d="M533.91 515.8V515.88" stroke="black" stroke-width="0.5" />
    <path d="M533.91 515.94V516.02" stroke="black" stroke-width="0.5" />
    <path d="M533.91 516.08V516.16" stroke="black" stroke-width="0.5" />
    <path d="M533.91 516.21V516.29" stroke="black" stroke-width="0.5" />
    <path d="M533.91 516.35V516.43" stroke="black" stroke-width="0.5" />
    <path d="M533.91 516.49V516.57" stroke="black" stroke-width="0.5" />
    <path d="M533.91 516.62V516.71" stroke="black" stroke-width="0.5" />
    <path d="M533.91 516.76V516.84" stroke="black" stroke-width="0.5" />
    <path d="M533.91 516.9V516.98" stroke="black" stroke-width="0.5" />
    <path d="M533.91 517.04V517.12" stroke="black" stroke-width="0.5" />
    <path d="M533.91 517.17V517.26" stroke="black" stroke-width="0.5" />
    <path d="M533.91 517.31V517.39" stroke="black" stroke-width="0.5" />
    <path d="M533.91 517.45V517.53" stroke="black" stroke-width="0.5" />
    <path d="M533.91 517.59V517.67" stroke="black" stroke-width="0.5" />
    <path d="M533.91 517.72V517.81" stroke="black" stroke-width="0.5" />
    <path d="M533.91 517.86V517.94" stroke="black" stroke-width="0.5" />
    <path d="M533.91 518V518.08" stroke="black" stroke-width="0.5" />
    <path d="M533.91 518.14V518.22" stroke="black" stroke-width="0.5" />
    <path d="M533.91 518.27V518.36" stroke="black" stroke-width="0.5" />
    <path d="M533.91 518.41V518.49" stroke="black" stroke-width="0.5" />
    <path d="M533.91 518.55V518.63" stroke="black" stroke-width="0.5" />
    <path d="M533.91 518.69V518.77" stroke="black" stroke-width="0.5" />
    <path d="M533.91 518.82V518.91" stroke="black" stroke-width="0.5" />
    <path d="M533.91 518.96V519.04" stroke="black" stroke-width="0.5" />
    <path d="M533.91 519.1V519.18" stroke="black" stroke-width="0.5" />
    <path d="M533.91 519.24V519.32" stroke="black" stroke-width="0.5" />
    <path d="M533.91 519.37V519.46" stroke="black" stroke-width="0.5" />
    <path d="M533.91 519.51V519.59" stroke="black" stroke-width="0.5" />
    <path d="M533.91 519.65V519.73" stroke="black" stroke-width="0.5" />
    <path d="M533.91 519.79V519.87" stroke="black" stroke-width="0.5" />
    <path d="M533.91 519.92V520.01" stroke="black" stroke-width="0.5" />
    <path d="M533.91 520.06V520.14" stroke="black" stroke-width="0.5" />
    <path d="M533.91 520.2V520.28" stroke="black" stroke-width="0.5" />
    <path d="M533.91 520.34V520.42" stroke="black" stroke-width="0.5" />
    <path d="M533.91 520.47V520.56" stroke="black" stroke-width="0.5" />
    <path d="M533.91 520.61V520.69" stroke="black" stroke-width="0.5" />
    <path d="M533.91 520.75V520.83" stroke="black" stroke-width="0.5" />
    <path d="M533.91 520.89V520.97" stroke="black" stroke-width="0.5" />
    <path d="M533.91 521.02V521.11" stroke="black" stroke-width="0.5" />
    <path d="M533.91 521.16V521.24" stroke="black" stroke-width="0.5" />
    <path d="M533.91 521.3V521.38" stroke="black" stroke-width="0.5" />
    <path d="M533.91 521.44V521.52" stroke="black" stroke-width="0.5" />
    <path d="M533.91 521.57V521.66" stroke="black" stroke-width="0.5" />
    <path d="M533.91 521.71V521.79" stroke="black" stroke-width="0.5" />
    <path d="M533.91 521.85V521.93" stroke="black" stroke-width="0.5" />
    <path d="M533.91 521.99V522.07" stroke="black" stroke-width="0.5" />
    <path d="M533.91 522.12V522.21" stroke="black" stroke-width="0.5" />
    <path d="M533.91 522.26V522.34" stroke="black" stroke-width="0.5" />
    <path d="M533.91 522.4V522.48" stroke="black" stroke-width="0.5" />
    <path d="M533.91 522.54V522.62" stroke="black" stroke-width="0.5" />
    <path d="M533.91 522.67V522.75" stroke="black" stroke-width="0.5" />
    <path d="M533.91 522.81V522.89" stroke="black" stroke-width="0.5" />
    <path d="M533.91 522.95V523.03" stroke="black" stroke-width="0.5" />
    <path d="M533.91 523.08V523.17" stroke="black" stroke-width="0.5" />
    <path d="M533.91 523.22V523.3" stroke="black" stroke-width="0.5" />
    <path d="M533.91 523.36V523.44" stroke="black" stroke-width="0.5" />
    <path d="M533.91 523.5V523.58" stroke="black" stroke-width="0.5" />
    <path d="M533.91 523.63V523.72" stroke="black" stroke-width="0.5" />
    <path d="M533.91 523.77V523.85" stroke="black" stroke-width="0.5" />
    <path d="M533.91 523.91V523.99" stroke="black" stroke-width="0.5" />
    <path d="M533.91 524.05V524.13" stroke="black" stroke-width="0.5" />
    <path d="M533.91 524.18V524.27" stroke="black" stroke-width="0.5" />
    <path d="M533.91 524.32V524.4" stroke="black" stroke-width="0.5" />
    <path d="M533.91 524.46V524.54" stroke="black" stroke-width="0.5" />
    <path d="M533.91 524.6V524.68" stroke="black" stroke-width="0.5" />
    <path d="M533.91 524.73V524.82" stroke="black" stroke-width="0.5" />
    <path d="M533.91 524.87V524.95" stroke="black" stroke-width="0.5" />
    <path d="M533.91 525.01V525.09" stroke="black" stroke-width="0.5" />
    <path d="M533.91 525.15V525.23" stroke="black" stroke-width="0.5" />
    <path d="M533.91 525.28V525.37" stroke="black" stroke-width="0.5" />
    <path d="M533.91 525.42V525.5" stroke="black" stroke-width="0.5" />
    <path d="M533.91 525.56V525.64" stroke="black" stroke-width="0.5" />
    <path d="M533.91 525.7V525.78" stroke="black" stroke-width="0.5" />
    <path d="M533.91 525.83V525.92" stroke="black" stroke-width="0.5" />
    <path d="M533.91 525.97V526.05" stroke="black" stroke-width="0.5" />
    <path d="M533.91 526.11V526.19" stroke="black" stroke-width="0.5" />
    <path d="M533.91 526.25V526.33" stroke="black" stroke-width="0.5" />
    <path d="M533.91 526.38V526.47" stroke="black" stroke-width="0.5" />
    <path d="M533.91 526.52V526.6" stroke="black" stroke-width="0.5" />
    <path d="M533.91 526.66V526.74" stroke="black" stroke-width="0.5" />
    <path d="M533.91 526.8V526.88" stroke="black" stroke-width="0.5" />
    <path d="M533.91 526.93V527.02" stroke="black" stroke-width="0.5" />
    <path d="M533.91 527.07V527.15" stroke="black" stroke-width="0.5" />
    <path d="M533.91 527.21V527.29" stroke="black" stroke-width="0.5" />
    <path d="M533.91 527.35V527.43" stroke="black" stroke-width="0.5" />
    <path d="M533.91 527.48V527.57" stroke="black" stroke-width="0.5" />
    <path d="M533.91 527.62V527.7" stroke="black" stroke-width="0.5" />
    <path d="M533.91 527.76V527.84" stroke="black" stroke-width="0.5" />
    <path d="M533.91 527.9V527.98" stroke="black" stroke-width="0.5" />
    <path d="M533.91 528.03V528.12" stroke="black" stroke-width="0.5" />
    <path d="M533.91 528.17V528.25" stroke="black" stroke-width="0.5" />
    <path d="M533.91 528.31V528.39" stroke="black" stroke-width="0.5" />
    <path d="M533.91 528.45V528.53" stroke="black" stroke-width="0.5" />
    <path d="M533.91 528.58V528.67" stroke="black" stroke-width="0.5" />
    <path d="M533.91 528.72V528.8" stroke="black" stroke-width="0.5" />
    <path d="M533.91 528.86V528.94" stroke="black" stroke-width="0.5" />
    <path d="M533.91 529V529.08" stroke="black" stroke-width="0.5" />
    <path d="M533.91 529.13V529.21" stroke="black" stroke-width="0.5" />
    <path d="M533.91 529.27V529.35" stroke="black" stroke-width="0.5" />
    <path d="M533.91 529.41V529.49" stroke="black" stroke-width="0.5" />
    <path d="M533.91 529.54V529.63" stroke="black" stroke-width="0.5" />
    <path d="M533.91 529.68V529.76" stroke="black" stroke-width="0.5" />
    <path d="M533.91 529.82V529.9" stroke="black" stroke-width="0.5" />
    <path d="M533.91 529.96V530.04" stroke="black" stroke-width="0.5" />
    <path d="M533.91 530.09V530.18" stroke="black" stroke-width="0.5" />
    <path d="M533.91 530.23V530.31" stroke="black" stroke-width="0.5" />
    <path d="M533.91 530.37V530.45" stroke="black" stroke-width="0.5" />
    <path d="M533.91 530.51V530.59" stroke="black" stroke-width="0.5" />
    <path d="M533.91 530.64V530.73" stroke="black" stroke-width="0.5" />
    <path d="M533.91 530.78V530.86" stroke="black" stroke-width="0.5" />
    <path d="M533.91 530.92V531" stroke="black" stroke-width="0.5" />
    <path d="M533.91 531.06V531.14" stroke="black" stroke-width="0.5" />
    <path d="M533.91 531.19V531.28" stroke="black" stroke-width="0.5" />
    <path d="M533.91 531.33V531.41" stroke="black" stroke-width="0.5" />
    <path d="M533.91 531.47V531.55" stroke="black" stroke-width="0.5" />
    <path d="M533.91 531.61V531.69" stroke="black" stroke-width="0.5" />
    <path d="M533.91 531.74V531.83" stroke="black" stroke-width="0.5" />
    <path d="M533.91 531.88V531.96" stroke="black" stroke-width="0.5" />
    <path d="M533.91 532.02V532.1" stroke="black" stroke-width="0.5" />
    <path d="M533.91 532.16V532.24" stroke="black" stroke-width="0.5" />
    <path d="M533.91 532.29V532.38" stroke="black" stroke-width="0.5" />
    <path d="M533.91 532.43V532.51" stroke="black" stroke-width="0.5" />
    <path d="M533.91 532.57V532.65" stroke="black" stroke-width="0.5" />
    <path d="M533.91 532.71V532.79" stroke="black" stroke-width="0.5" />
    <path d="M533.91 532.84V532.93" stroke="black" stroke-width="0.5" />
    <path d="M533.91 532.98V533.06" stroke="black" stroke-width="0.5" />
    <path d="M533.91 533.12V533.2" stroke="black" stroke-width="0.5" />
    <path d="M533.91 533.26V533.34" stroke="black" stroke-width="0.5" />
    <path d="M533.91 533.39V533.48" stroke="black" stroke-width="0.5" />
    <path d="M533.91 533.53V533.61" stroke="black" stroke-width="0.5" />
    <path d="M533.91 533.67V533.75" stroke="black" stroke-width="0.5" />
    <path d="M533.91 533.81V533.89" stroke="black" stroke-width="0.5" />
    <path d="M533.91 533.94V534.03" stroke="black" stroke-width="0.5" />
    <path d="M533.91 534.08V534.16" stroke="black" stroke-width="0.5" />
    <path d="M533.91 534.22V534.3" stroke="black" stroke-width="0.5" />
    <path d="M533.91 534.36V534.44" stroke="black" stroke-width="0.5" />
    <path d="M533.91 534.49V534.58" stroke="black" stroke-width="0.5" />
    <path d="M533.91 534.63V534.71" stroke="black" stroke-width="0.5" />
    <path d="M533.91 534.77V534.85" stroke="black" stroke-width="0.5" />
    <path d="M533.91 534.91V534.99" stroke="black" stroke-width="0.5" />
    <path d="M533.91 535.04V535.13" stroke="black" stroke-width="0.5" />
    <path d="M533.91 535.18V535.26" stroke="black" stroke-width="0.5" />
    <path d="M533.91 535.32V535.4" stroke="black" stroke-width="0.5" />
    <path d="M533.91 535.46V535.54" stroke="black" stroke-width="0.5" />
    <path d="M533.91 535.59V535.67" stroke="black" stroke-width="0.5" />
    <path d="M533.91 535.73V535.81" stroke="black" stroke-width="0.5" />
    <path d="M533.91 535.87V535.95" stroke="black" stroke-width="0.5" />
    <path d="M533.91 536V536.09" stroke="black" stroke-width="0.5" />
    <path d="M533.91 536.14V536.22" stroke="black" stroke-width="0.5" />
    <path d="M533.91 536.28V536.36" stroke="black" stroke-width="0.5" />
    <path d="M533.91 536.42V536.5" stroke="black" stroke-width="0.5" />
    <path d="M533.91 536.55V536.64" stroke="black" stroke-width="0.5" />
    <path d="M533.91 536.69V536.77" stroke="black" stroke-width="0.5" />
    <path d="M533.91 536.83V536.91" stroke="black" stroke-width="0.5" />
    <path d="M533.91 536.97V537.05" stroke="black" stroke-width="0.5" />
    <path d="M533.91 537.1V537.19" stroke="black" stroke-width="0.5" />
    <path d="M533.91 537.24V537.32" stroke="black" stroke-width="0.5" />
    <path d="M533.91 537.38V537.46" stroke="black" stroke-width="0.5" />
    <path d="M533.91 537.52V537.6" stroke="black" stroke-width="0.5" />
    <path d="M533.91 537.65V537.74" stroke="black" stroke-width="0.5" />
    <path d="M533.91 537.79V537.87" stroke="black" stroke-width="0.5" />
    <path d="M533.91 537.93V538.01" stroke="black" stroke-width="0.5" />
    <path d="M533.91 538.07V538.15" stroke="black" stroke-width="0.5" />
    <path d="M533.91 538.2V538.26" stroke="black" stroke-width="0.5" />
    <path d="M527.42 507.69V523.52" stroke="black" stroke-width="0.5" />
    <path d="M527.42 523.52H543.26" stroke="black" stroke-width="0.5" />
    <path d="M543.26 523.52V507.69" stroke="black" stroke-width="0.5" />
    <path d="M543.26 507.69H527.42" stroke="black" stroke-width="0.5" />
    <path
      d="M529.4 512.97C529.633 512.97 529.857 512.877 530.022 512.712C530.187 512.547 530.28 512.323 530.28 512.09C530.28 511.857 530.187 511.633 530.022 511.468C529.857 511.303 529.633 511.21 529.4 511.21"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M529.4 511.21C529.167 511.21 528.943 511.303 528.778 511.468C528.613 511.633 528.52 511.857 528.52 512.09C528.52 512.323 528.613 512.547 528.778 512.712C528.943 512.877 529.167 512.97 529.4 512.97"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M529.4 520.01C529.633 520.01 529.857 519.917 530.022 519.752C530.187 519.587 530.28 519.363 530.28 519.13C530.28 518.897 530.187 518.673 530.022 518.508C529.857 518.343 529.633 518.25 529.4 518.25"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M529.4 518.25C529.167 518.25 528.943 518.343 528.778 518.508C528.613 518.673 528.52 518.897 528.52 519.13C528.52 519.363 528.613 519.587 528.778 519.752C528.943 519.917 529.167 520.01 529.4 520.01"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M531.38 516.05V520.45" stroke="black" stroke-width="0.5" />
    <path
      d="M533.58 516.05C533.697 516.05 533.809 516.004 533.891 515.921C533.974 515.839 534.02 515.727 534.02 515.61C534.02 515.493 533.974 515.381 533.891 515.299C533.809 515.216 533.697 515.17 533.58 515.17"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M529.4 515.17H533.58" stroke="black" stroke-width="0.5" />
    <path d="M529.4 516.05H533.58" stroke="black" stroke-width="0.5" />
    <path
      d="M529.4 515.17C529.342 515.17 529.285 515.181 529.232 515.203C529.178 515.226 529.13 515.258 529.089 515.299C529.048 515.34 529.016 515.388 528.993 515.442C528.971 515.495 528.96 515.552 528.96 515.61C528.96 515.668 528.971 515.725 528.993 515.778C529.016 515.832 529.048 515.88 529.089 515.921C529.13 515.962 529.178 515.994 529.232 516.016C529.285 516.039 529.342 516.05 529.4 516.05"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M531.38 520.45C531.38 520.681 531.425 520.91 531.514 521.124C531.602 521.337 531.732 521.531 531.895 521.695C532.059 521.858 532.253 521.988 532.466 522.076C532.68 522.165 532.909 522.21 533.14 522.21"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M540.18 522.21C540.411 522.21 540.64 522.165 540.854 522.076C541.067 521.988 541.261 521.858 541.425 521.695C541.588 521.531 541.718 521.337 541.806 521.124C541.895 520.91 541.94 520.681 541.94 520.45"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M541.94 510.77C541.94 510.539 541.895 510.31 541.806 510.096C541.718 509.883 541.588 509.689 541.425 509.525C541.261 509.362 541.067 509.232 540.854 509.144C540.64 509.055 540.411 509.01 540.18 509.01"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M533.14 509.01C532.909 509.01 532.68 509.055 532.466 509.144C532.253 509.232 532.059 509.362 531.895 509.525C531.732 509.689 531.602 509.883 531.514 510.096C531.425 510.31 531.38 510.539 531.38 510.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M531.38 510.77V515.17" stroke="black" stroke-width="0.5" />
    <path d="M540.18 509.01H533.14" stroke="black" stroke-width="0.5" />
    <path d="M541.94 520.45V510.77" stroke="black" stroke-width="0.5" />
    <path d="M533.14 522.21H540.18" stroke="black" stroke-width="0.5" />
    <path
      d="M535.42 516.49C535.653 516.49 535.877 516.397 536.042 516.232C536.207 516.067 536.3 515.843 536.3 515.61C536.3 515.377 536.207 515.153 536.042 514.988C535.877 514.823 535.653 514.73 535.42 514.73"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M535.42 514.73C535.187 514.73 534.963 514.823 534.798 514.988C534.633 515.153 534.54 515.377 534.54 515.61C534.54 515.843 534.633 516.067 534.798 516.232C534.963 516.397 535.187 516.49 535.42 516.49"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M314.77 378.16H313.91" stroke="black" stroke-width="0.5" />
    <path d="M313.91 382.45H314.77" stroke="black" stroke-width="0.5" />
    <path d="M314.77 382.45V381.9" stroke="black" stroke-width="0.5" />
    <path d="M314.77 381.9V378.71" stroke="black" stroke-width="0.5" />
    <path d="M314.77 378.71V378.16" stroke="black" stroke-width="0.5" />
    <path d="M504.22 268.95V272.27" stroke="black" stroke-width="0.5" />
    <path d="M508.51 272.27V268.95" stroke="black" stroke-width="0.5" />
    <path d="M508.51 268.95H507.96" stroke="black" stroke-width="0.5" />
    <path d="M507.96 268.95H504.77" stroke="black" stroke-width="0.5" />
    <path d="M504.77 268.95H504.22" stroke="black" stroke-width="0.5" />
    <path d="M315.31 159.79H293.32" stroke="black" stroke-width="0.5" />
    <path d="M293.32 159.79V132.41" stroke="black" stroke-width="0.5" />
    <path d="M293.32 128.12V95.36" stroke="black" stroke-width="0.5" />
    <path d="M287.06 36.85V95.36" stroke="black" stroke-width="0.5" />
    <path d="M287.06 95.36H297.61" stroke="black" stroke-width="0.5" />
    <path d="M297.61 95.36V36.85" stroke="black" stroke-width="0.5" />
    <path d="M297.61 36.85H287.06" stroke="black" stroke-width="0.5" />
    <path d="M504.22 209.82V228.29" stroke="black" stroke-width="0.5" />
    <path d="M504.22 232.58V237.28" stroke="black" stroke-width="0.5" />
    <path d="M508.51 237.28V209.82" stroke="black" stroke-width="0.5" />
    <path d="M508.51 209.82H504.22" stroke="black" stroke-width="0.5" />
    <path d="M504.22 272.27V274.8" stroke="black" stroke-width="0.5" />
    <path d="M504.22 274.8H508.51" stroke="black" stroke-width="0.5" />
    <path d="M508.51 274.8V272.27" stroke="black" stroke-width="0.5" />
    <path d="M504.22 306.47V311.75" stroke="black" stroke-width="0.5" />
    <path d="M504.22 311.75H508.51" stroke="black" stroke-width="0.5" />
    <path d="M508.51 311.75V306.47" stroke="black" stroke-width="0.5" />
    <path d="M508.51 306.47H504.22" stroke="black" stroke-width="0.5" />
    <path d="M504.22 377.17V378.16" stroke="black" stroke-width="0.5" />
    <path d="M508.51 378.16V377.17" stroke="black" stroke-width="0.5" />
    <path d="M508.51 377.17H504.22" stroke="black" stroke-width="0.5" />
    <path d="M504.22 237.28H504.77" stroke="black" stroke-width="0.5" />
    <path d="M504.77 237.28H507.96" stroke="black" stroke-width="0.5" />
    <path d="M507.96 237.28H508.51" stroke="black" stroke-width="0.5" />
    <path d="M504.22 377.17V311.75" stroke="black" stroke-width="0.5" />
    <path d="M504.22 377.17H508.51" stroke="black" stroke-width="0.5" />
    <path d="M508.51 311.75H504.22" stroke="black" stroke-width="0.5" />
    <path d="M508.51 311.75V377.17" stroke="black" stroke-width="0.5" />
    <path d="M377.39 79.32L377.22 79.03" stroke="black" stroke-width="0.5" />
    <path d="M377.37 28.62L377.22 28.36" stroke="black" stroke-width="0.5" />
    <path d="M377.29 158.78L377.22 158.89" stroke="black" stroke-width="0.5" />
    <path d="M377.32 57.38L377.22 57.55" stroke="black" stroke-width="0.5" />
    <path d="M374.03 39.73L374 39.67" stroke="black" stroke-width="0.5" />
    <path d="M374.03 127.55L373.97 127.45" stroke="black" stroke-width="0.5" />
    <path d="M374.03 76.88L373.96 76.75" stroke="black" stroke-width="0.5" />
    <path d="M374.03 134.01L373.98 134.1" stroke="black" stroke-width="0.5" />
    <path d="M377.77 5.19H377.22" stroke="black" stroke-width="0.5" />
    <path d="M377.22 5.19V159.79" stroke="black" stroke-width="0.5" />
    <path d="M374.03 5.19H373.48" stroke="black" stroke-width="0.5" />
    <path d="M377.22 5.19002V4.64001" stroke="black" stroke-width="0.5" />
    <path d="M374.03 5.19002V4.64001" stroke="black" stroke-width="0.5" />
    <path d="M374.03 5.19V159.79" stroke="black" stroke-width="0.5" />
    <path d="M374.03 159.79V160.34" stroke="black" stroke-width="0.5" />
    <path d="M377.22 159.79V160.34" stroke="black" stroke-width="0.5" />
    <path d="M694.56 215.62H694.89" stroke="black" stroke-width="0.5" />
    <path d="M705.44 215.62V214.3" stroke="black" stroke-width="0.5" />
    <path d="M705.44 214.3H694.56" stroke="black" stroke-width="0.5" />
    <path d="M694.56 217.82V215.62" stroke="black" stroke-width="0.5" />
    <path d="M694.56 214.3H693.24" stroke="black" stroke-width="0.5" />
    <path d="M693.24 214.3V217.82" stroke="black" stroke-width="0.5" />
    <path d="M35.36 527.04V523.3" stroke="black" stroke-width="0.5" />
    <path d="M35.36 523.3H31.07" stroke="black" stroke-width="0.5" />
    <path d="M31.07 523.3H21.73" stroke="black" stroke-width="0.5" />
    <path d="M21.73 527.59H31.07" stroke="black" stroke-width="0.5" />
    <path d="M31.07 527.59V527.04" stroke="black" stroke-width="0.5" />
    <path d="M21.73 218.81H26.46" stroke="black" stroke-width="0.5" />
    <path d="M29.21 218.81V216.06" stroke="black" stroke-width="0.5" />
    <path d="M29.21 216.06H21.73" stroke="black" stroke-width="0.5" />
    <path d="M26.46 218.81V232" stroke="black" stroke-width="0.5" />
    <path d="M29.21 232V218.81" stroke="black" stroke-width="0.5" />
    <path d="M26.46 232H21.73" stroke="black" stroke-width="0.5" />
    <path d="M21.73 234.75H29.21" stroke="black" stroke-width="0.5" />
    <path d="M29.21 234.75V232" stroke="black" stroke-width="0.5" />
    <path d="M375.68 537.49H387.01" stroke="black" stroke-width="0.5" />
    <path d="M389.76 537.49V534.74" stroke="black" stroke-width="0.5" />
    <path d="M389.76 534.74H375.68" stroke="black" stroke-width="0.5" />
    <path d="M387.01 537.49V538.26" stroke="black" stroke-width="0.5" />
    <path d="M389.76 538.26V537.49" stroke="black" stroke-width="0.5" />
    <path d="M669.27 538.26V532.65" stroke="black" stroke-width="0.5" />
    <path d="M669.27 529.9H666.52" stroke="black" stroke-width="0.5" />
    <path d="M666.52 529.9V538.26" stroke="black" stroke-width="0.5" />
    <path d="M669.27 532.65H683.34" stroke="black" stroke-width="0.5" />
    <path d="M683.34 529.9H669.27" stroke="black" stroke-width="0.5" />
    <path d="M683.34 532.65V538.26" stroke="black" stroke-width="0.5" />
    <path d="M686.09 538.26V532.65" stroke="black" stroke-width="0.5" />
    <path d="M686.09 529.9H683.34" stroke="black" stroke-width="0.5" />
    <path d="M686.09 532.65V530.45" stroke="black" stroke-width="0.5" />
    <path d="M233.07 378.16L255.46 355.77" stroke="black" stroke-width="0.5" />
    <path d="M234.16 379.25L233.07 378.16" stroke="black" stroke-width="0.5" />
    <path d="M256.55 356.86L234.16 379.25" stroke="black" stroke-width="0.5" />
    <path d="M255.46 355.77L256.55 356.86" stroke="black" stroke-width="0.5" />
    <path
      d="M264.73 378.16C264.731 369.763 261.396 361.709 255.46 355.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M469.7 217.73V217.83" stroke="black" stroke-width="0.5" />
    <path d="M469.7 217.88V217.97" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.02V218.1" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.16V218.24" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.3V218.38" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.43V218.52" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.57V218.65" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.71V218.79" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.85V218.93" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.98V219.07" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.12V219.2" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.26V219.34" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.4V219.48" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.53V219.62" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.67V219.75" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.81V219.89" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.95V220.03" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.08V220.17" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.22V220.3" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.36V220.44" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.5V220.58" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.63V220.72" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.77V220.85" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.91V220.99" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.05V221.13" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.18V221.27" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.32V221.4" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.46V221.54" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.6V221.68" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.73V221.82" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.87V221.95" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.01V222.09" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.14V222.23" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.28V222.36" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.42V222.5" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.56V222.64" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.69V222.78" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.83V222.91" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.97V223.05" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.11V223.19" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.24V223.33" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.38V223.46" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.52V223.6" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.66V223.74" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.79V223.88" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.93V224.01" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.07V224.15" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.21V224.29" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.34V224.43" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.48V224.56" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.62V224.7" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.76V224.84" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.89V224.98" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.03V225.11" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.17V225.25" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.31V225.39" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.44V225.53" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.58V225.66" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.72V225.8" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.86V225.94" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.99V226.08" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.13V226.21" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.27V226.35" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.41V226.49" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.54V226.63" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.68V226.76" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.82V226.9" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.96V227.04" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.09V227.18" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.23V227.31" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.37V227.45" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.51V227.59" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.64V227.73" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.78V227.86" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.92V228" stroke="black" stroke-width="0.5" />
    <path d="M469.7 228.06V228.14" stroke="black" stroke-width="0.5" />
    <path d="M469.7 228.19V228.29" stroke="black" stroke-width="0.5" />
    <path d="M469.7 228.29H469.75" stroke="black" stroke-width="0.5" />
    <path d="M469.81 228.29H469.89" stroke="black" stroke-width="0.5" />
    <path d="M469.94 228.29H470.03" stroke="black" stroke-width="0.5" />
    <path d="M470.08 228.29H470.16" stroke="black" stroke-width="0.5" />
    <path d="M470.22 228.29H470.3" stroke="black" stroke-width="0.5" />
    <path d="M470.36 228.29H470.44" stroke="black" stroke-width="0.5" />
    <path d="M470.49 228.29H470.57" stroke="black" stroke-width="0.5" />
    <path d="M470.63 228.29H470.71" stroke="black" stroke-width="0.5" />
    <path d="M470.77 228.29H470.85" stroke="black" stroke-width="0.5" />
    <path d="M470.9 228.29H470.99" stroke="black" stroke-width="0.5" />
    <path d="M471.04 228.29H471.12" stroke="black" stroke-width="0.5" />
    <path d="M471.18 228.29H471.26" stroke="black" stroke-width="0.5" />
    <path d="M471.32 228.29H471.4" stroke="black" stroke-width="0.5" />
    <path d="M471.45 228.29H471.54" stroke="black" stroke-width="0.5" />
    <path d="M471.59 228.29H471.67" stroke="black" stroke-width="0.5" />
    <path d="M471.73 228.29H471.81" stroke="black" stroke-width="0.5" />
    <path d="M471.87 228.29H471.95" stroke="black" stroke-width="0.5" />
    <path d="M472 228.29H472.09" stroke="black" stroke-width="0.5" />
    <path d="M472.14 228.29H472.22" stroke="black" stroke-width="0.5" />
    <path d="M472.28 228.29H472.36" stroke="black" stroke-width="0.5" />
    <path d="M472.42 228.29H472.5" stroke="black" stroke-width="0.5" />
    <path d="M472.55 228.29H472.64" stroke="black" stroke-width="0.5" />
    <path d="M472.69 228.29H472.77" stroke="black" stroke-width="0.5" />
    <path d="M472.83 228.29H472.91" stroke="black" stroke-width="0.5" />
    <path d="M472.97 228.29H473.05" stroke="black" stroke-width="0.5" />
    <path d="M473.1 228.29H473.19" stroke="black" stroke-width="0.5" />
    <path d="M473.24 228.29H473.32" stroke="black" stroke-width="0.5" />
    <path d="M473.38 228.29H473.46" stroke="black" stroke-width="0.5" />
    <path d="M473.52 228.29H473.6" stroke="black" stroke-width="0.5" />
    <path d="M473.65 228.29H473.74" stroke="black" stroke-width="0.5" />
    <path d="M473.79 228.29H473.87" stroke="black" stroke-width="0.5" />
    <path d="M473.93 228.29H474.01" stroke="black" stroke-width="0.5" />
    <path d="M474.07 228.29H474.15" stroke="black" stroke-width="0.5" />
    <path d="M474.2 228.29H474.29" stroke="black" stroke-width="0.5" />
    <path d="M474.34 228.29H474.42" stroke="black" stroke-width="0.5" />
    <path d="M474.48 228.29H474.56" stroke="black" stroke-width="0.5" />
    <path d="M474.62 228.29H474.7" stroke="black" stroke-width="0.5" />
    <path d="M474.75 228.29H474.84" stroke="black" stroke-width="0.5" />
    <path d="M474.89 228.29H474.97" stroke="black" stroke-width="0.5" />
    <path d="M475.03 228.29H475.11" stroke="black" stroke-width="0.5" />
    <path d="M475.17 228.29H475.25" stroke="black" stroke-width="0.5" />
    <path d="M475.3 228.29H475.39" stroke="black" stroke-width="0.5" />
    <path d="M475.44 228.29H475.52" stroke="black" stroke-width="0.5" />
    <path d="M475.58 228.29H475.66" stroke="black" stroke-width="0.5" />
    <path d="M475.72 228.29H475.8" stroke="black" stroke-width="0.5" />
    <path d="M475.85 228.29H475.94" stroke="black" stroke-width="0.5" />
    <path d="M475.99 228.29H476.07" stroke="black" stroke-width="0.5" />
    <path d="M476.13 228.29H476.21" stroke="black" stroke-width="0.5" />
    <path d="M476.27 228.29H476.35" stroke="black" stroke-width="0.5" />
    <path d="M476.4 228.29H476.49" stroke="black" stroke-width="0.5" />
    <path d="M476.54 228.29H476.62" stroke="black" stroke-width="0.5" />
    <path d="M476.68 228.29H476.76" stroke="black" stroke-width="0.5" />
    <path d="M476.82 228.29H476.9" stroke="black" stroke-width="0.5" />
    <path d="M476.95 228.29H477.03" stroke="black" stroke-width="0.5" />
    <path d="M477.09 228.29H477.17" stroke="black" stroke-width="0.5" />
    <path d="M477.23 228.29H477.31" stroke="black" stroke-width="0.5" />
    <path d="M477.36 228.29H477.45" stroke="black" stroke-width="0.5" />
    <path d="M477.5 228.29H477.58" stroke="black" stroke-width="0.5" />
    <path d="M477.64 228.29H477.72" stroke="black" stroke-width="0.5" />
    <path d="M477.78 228.29H477.86" stroke="black" stroke-width="0.5" />
    <path d="M477.91 228.29H478" stroke="black" stroke-width="0.5" />
    <path d="M478.05 228.29H478.13" stroke="black" stroke-width="0.5" />
    <path d="M478.19 228.29H478.27" stroke="black" stroke-width="0.5" />
    <path d="M478.33 228.29H478.41" stroke="black" stroke-width="0.5" />
    <path d="M478.46 228.29H478.55" stroke="black" stroke-width="0.5" />
    <path d="M478.6 228.29H478.68" stroke="black" stroke-width="0.5" />
    <path d="M478.74 228.29H478.82" stroke="black" stroke-width="0.5" />
    <path d="M478.88 228.29H478.96" stroke="black" stroke-width="0.5" />
    <path d="M479.01 228.29H479.1" stroke="black" stroke-width="0.5" />
    <path d="M479.15 228.29H479.23" stroke="black" stroke-width="0.5" />
    <path d="M479.29 228.29H479.37" stroke="black" stroke-width="0.5" />
    <path d="M479.43 228.29H479.51" stroke="black" stroke-width="0.5" />
    <path d="M479.56 228.29H479.65" stroke="black" stroke-width="0.5" />
    <path d="M479.7 228.29H479.78" stroke="black" stroke-width="0.5" />
    <path d="M479.84 228.29H479.92" stroke="black" stroke-width="0.5" />
    <path d="M479.98 228.29H480.06" stroke="black" stroke-width="0.5" />
    <path d="M480.11 228.29H480.2" stroke="black" stroke-width="0.5" />
    <path d="M480.25 228.29H480.33" stroke="black" stroke-width="0.5" />
    <path d="M480.39 228.29H480.47" stroke="black" stroke-width="0.5" />
    <path d="M480.53 228.29H480.61" stroke="black" stroke-width="0.5" />
    <path d="M480.66 228.29H480.75" stroke="black" stroke-width="0.5" />
    <path d="M480.8 228.29H480.88" stroke="black" stroke-width="0.5" />
    <path d="M480.94 228.29H481.02" stroke="black" stroke-width="0.5" />
    <path d="M481.08 228.29H481.16" stroke="black" stroke-width="0.5" />
    <path d="M481.21 228.29H481.3" stroke="black" stroke-width="0.5" />
    <path d="M481.35 228.29H481.43" stroke="black" stroke-width="0.5" />
    <path d="M481.49 228.29H481.57" stroke="black" stroke-width="0.5" />
    <path d="M481.63 228.29H481.71" stroke="black" stroke-width="0.5" />
    <path d="M481.76 228.29H481.85" stroke="black" stroke-width="0.5" />
    <path d="M481.9 228.29H481.98" stroke="black" stroke-width="0.5" />
    <path d="M482.04 228.29H482.12" stroke="black" stroke-width="0.5" />
    <path d="M482.18 228.29H482.26" stroke="black" stroke-width="0.5" />
    <path d="M482.31 228.29H482.4" stroke="black" stroke-width="0.5" />
    <path d="M482.45 228.29H482.53" stroke="black" stroke-width="0.5" />
    <path d="M482.59 228.29H482.67" stroke="black" stroke-width="0.5" />
    <path d="M482.73 228.29H482.81" stroke="black" stroke-width="0.5" />
    <path d="M482.86 228.29H482.95" stroke="black" stroke-width="0.5" />
    <path d="M483 228.29H483.08" stroke="black" stroke-width="0.5" />
    <path d="M483.14 228.29H483.22" stroke="black" stroke-width="0.5" />
    <path d="M483.28 228.29H483.36" stroke="black" stroke-width="0.5" />
    <path d="M483.41 228.29H483.49" stroke="black" stroke-width="0.5" />
    <path d="M483.55 228.29H483.63" stroke="black" stroke-width="0.5" />
    <path d="M483.69 228.29H483.77" stroke="black" stroke-width="0.5" />
    <path d="M483.82 228.29H483.91" stroke="black" stroke-width="0.5" />
    <path d="M483.96 228.29H484.04" stroke="black" stroke-width="0.5" />
    <path d="M484.1 228.29H484.18" stroke="black" stroke-width="0.5" />
    <path d="M484.24 228.29H484.32" stroke="black" stroke-width="0.5" />
    <path d="M484.37 228.29H484.46" stroke="black" stroke-width="0.5" />
    <path d="M484.51 228.29H484.59" stroke="black" stroke-width="0.5" />
    <path d="M484.65 228.29H484.73" stroke="black" stroke-width="0.5" />
    <path d="M484.79 228.29H484.87" stroke="black" stroke-width="0.5" />
    <path d="M484.92 228.29H485.01" stroke="black" stroke-width="0.5" />
    <path d="M485.06 228.29H485.14" stroke="black" stroke-width="0.5" />
    <path d="M485.2 228.29H485.28" stroke="black" stroke-width="0.5" />
    <path d="M485.34 228.29H485.42" stroke="black" stroke-width="0.5" />
    <path d="M485.47 228.29H485.56" stroke="black" stroke-width="0.5" />
    <path d="M485.61 228.29H485.69" stroke="black" stroke-width="0.5" />
    <path d="M485.75 228.29H485.83" stroke="black" stroke-width="0.5" />
    <path d="M485.89 228.29H485.97" stroke="black" stroke-width="0.5" />
    <path d="M486.02 228.29H486.11" stroke="black" stroke-width="0.5" />
    <path d="M486.16 228.29H486.24" stroke="black" stroke-width="0.5" />
    <path d="M486.3 228.29H486.38" stroke="black" stroke-width="0.5" />
    <path d="M486.44 228.29H486.52" stroke="black" stroke-width="0.5" />
    <path d="M486.57 228.29H486.66" stroke="black" stroke-width="0.5" />
    <path d="M486.71 228.29H486.79" stroke="black" stroke-width="0.5" />
    <path d="M486.85 228.29H486.93" stroke="black" stroke-width="0.5" />
    <path d="M486.99 228.29H487.07" stroke="black" stroke-width="0.5" />
    <path d="M487.12 228.29H487.21" stroke="black" stroke-width="0.5" />
    <path d="M487.26 228.29H487.34" stroke="black" stroke-width="0.5" />
    <path d="M487.4 228.29H487.48" stroke="black" stroke-width="0.5" />
    <path d="M487.54 228.29H487.62" stroke="black" stroke-width="0.5" />
    <path d="M487.67 228.29H487.76" stroke="black" stroke-width="0.5" />
    <path d="M487.81 228.29H487.89" stroke="black" stroke-width="0.5" />
    <path d="M487.95 228.29H488.03" stroke="black" stroke-width="0.5" />
    <path d="M488.09 228.29H488.17" stroke="black" stroke-width="0.5" />
    <path d="M488.22 228.29H488.31" stroke="black" stroke-width="0.5" />
    <path d="M488.36 228.29H488.44" stroke="black" stroke-width="0.5" />
    <path d="M488.5 228.29H488.58" stroke="black" stroke-width="0.5" />
    <path d="M488.64 228.29H488.72" stroke="black" stroke-width="0.5" />
    <path d="M488.77 228.29H488.86" stroke="black" stroke-width="0.5" />
    <path d="M488.91 228.29H488.99" stroke="black" stroke-width="0.5" />
    <path d="M489.05 228.29H489.13" stroke="black" stroke-width="0.5" />
    <path d="M489.19 228.29H489.27" stroke="black" stroke-width="0.5" />
    <path d="M489.32 228.29H489.41" stroke="black" stroke-width="0.5" />
    <path d="M489.46 228.29H489.54" stroke="black" stroke-width="0.5" />
    <path d="M489.6 228.29H489.68" stroke="black" stroke-width="0.5" />
    <path d="M489.74 228.29H489.82" stroke="black" stroke-width="0.5" />
    <path d="M489.87 228.29H489.95" stroke="black" stroke-width="0.5" />
    <path d="M490.01 228.29H490.09" stroke="black" stroke-width="0.5" />
    <path d="M490.15 228.29H490.23" stroke="black" stroke-width="0.5" />
    <path d="M490.28 228.29H490.37" stroke="black" stroke-width="0.5" />
    <path d="M490.42 228.29H490.5" stroke="black" stroke-width="0.5" />
    <path d="M490.56 228.29H490.64" stroke="black" stroke-width="0.5" />
    <path d="M490.7 228.29H490.78" stroke="black" stroke-width="0.5" />
    <path d="M490.83 228.29H490.92" stroke="black" stroke-width="0.5" />
    <path d="M490.97 228.29H491.05" stroke="black" stroke-width="0.5" />
    <path d="M491.11 228.29H491.19" stroke="black" stroke-width="0.5" />
    <path d="M491.25 228.29H491.33" stroke="black" stroke-width="0.5" />
    <path d="M491.38 228.29H491.47" stroke="black" stroke-width="0.5" />
    <path d="M491.52 228.29H491.6" stroke="black" stroke-width="0.5" />
    <path d="M491.66 228.29H491.74" stroke="black" stroke-width="0.5" />
    <path d="M491.8 228.29H491.88" stroke="black" stroke-width="0.5" />
    <path d="M491.93 228.29H492.02" stroke="black" stroke-width="0.5" />
    <path d="M492.07 228.29H492.15" stroke="black" stroke-width="0.5" />
    <path d="M492.21 228.29H492.29" stroke="black" stroke-width="0.5" />
    <path d="M492.35 228.29H492.43" stroke="black" stroke-width="0.5" />
    <path d="M492.48 228.29H492.57" stroke="black" stroke-width="0.5" />
    <path d="M492.62 228.29H492.7" stroke="black" stroke-width="0.5" />
    <path d="M492.76 228.29H492.84" stroke="black" stroke-width="0.5" />
    <path d="M492.9 228.29H492.98" stroke="black" stroke-width="0.5" />
    <path d="M493.03 228.29H493.12" stroke="black" stroke-width="0.5" />
    <path d="M493.17 228.29H493.25" stroke="black" stroke-width="0.5" />
    <path d="M493.31 228.29H493.39" stroke="black" stroke-width="0.5" />
    <path d="M493.45 228.29H493.53" stroke="black" stroke-width="0.5" />
    <path d="M493.58 228.29H493.67" stroke="black" stroke-width="0.5" />
    <path d="M493.72 228.29H493.8" stroke="black" stroke-width="0.5" />
    <path d="M493.86 228.29H493.94" stroke="black" stroke-width="0.5" />
    <path d="M494 228.29H494.08" stroke="black" stroke-width="0.5" />
    <path d="M494.13 228.29H494.22" stroke="black" stroke-width="0.5" />
    <path d="M494.27 228.29H494.35" stroke="black" stroke-width="0.5" />
    <path d="M494.41 228.29H494.49" stroke="black" stroke-width="0.5" />
    <path d="M494.55 228.29H494.63" stroke="black" stroke-width="0.5" />
    <path d="M494.68 228.29H494.77" stroke="black" stroke-width="0.5" />
    <path d="M494.82 228.29H494.9" stroke="black" stroke-width="0.5" />
    <path d="M494.96 228.29H495.04" stroke="black" stroke-width="0.5" />
    <path d="M495.1 228.29H495.18" stroke="black" stroke-width="0.5" />
    <path d="M495.23 228.29H495.32" stroke="black" stroke-width="0.5" />
    <path d="M495.37 228.29H495.45" stroke="black" stroke-width="0.5" />
    <path d="M495.51 228.29H495.59" stroke="black" stroke-width="0.5" />
    <path d="M495.65 228.29H495.73" stroke="black" stroke-width="0.5" />
    <path d="M495.78 228.29H495.87" stroke="black" stroke-width="0.5" />
    <path d="M495.92 228.29H496" stroke="black" stroke-width="0.5" />
    <path d="M496.06 228.29H496.14" stroke="black" stroke-width="0.5" />
    <path d="M496.2 228.29H496.28" stroke="black" stroke-width="0.5" />
    <path d="M496.33 228.29H496.41" stroke="black" stroke-width="0.5" />
    <path d="M496.47 228.29H496.55" stroke="black" stroke-width="0.5" />
    <path d="M496.61 228.29H496.69" stroke="black" stroke-width="0.5" />
    <path d="M496.74 228.29H496.83" stroke="black" stroke-width="0.5" />
    <path d="M496.88 228.29H496.96" stroke="black" stroke-width="0.5" />
    <path d="M497.02 228.29H497.1" stroke="black" stroke-width="0.5" />
    <path d="M497.16 228.29H497.24" stroke="black" stroke-width="0.5" />
    <path d="M497.29 228.29H497.38" stroke="black" stroke-width="0.5" />
    <path d="M497.43 228.29H497.51" stroke="black" stroke-width="0.5" />
    <path d="M497.57 228.29H497.65" stroke="black" stroke-width="0.5" />
    <path d="M497.71 228.29H497.79" stroke="black" stroke-width="0.5" />
    <path d="M497.84 228.29H497.93" stroke="black" stroke-width="0.5" />
    <path d="M497.98 228.29H498.06" stroke="black" stroke-width="0.5" />
    <path d="M498.12 228.29H498.2" stroke="black" stroke-width="0.5" />
    <path d="M498.26 228.29H498.34" stroke="black" stroke-width="0.5" />
    <path d="M498.39 228.29H498.48" stroke="black" stroke-width="0.5" />
    <path d="M498.53 228.29H498.61" stroke="black" stroke-width="0.5" />
    <path d="M498.67 228.29H498.75" stroke="black" stroke-width="0.5" />
    <path d="M498.81 228.29H498.89" stroke="black" stroke-width="0.5" />
    <path d="M498.94 228.29H499.03" stroke="black" stroke-width="0.5" />
    <path d="M499.08 228.29H499.16" stroke="black" stroke-width="0.5" />
    <path d="M499.22 228.29H499.3" stroke="black" stroke-width="0.5" />
    <path d="M499.36 228.29H499.44" stroke="black" stroke-width="0.5" />
    <path d="M499.49 228.29H499.58" stroke="black" stroke-width="0.5" />
    <path d="M499.63 228.29H499.71" stroke="black" stroke-width="0.5" />
    <path d="M499.77 228.29H499.85" stroke="black" stroke-width="0.5" />
    <path d="M499.91 228.29H499.99" stroke="black" stroke-width="0.5" />
    <path d="M500.04 228.29H500.13" stroke="black" stroke-width="0.5" />
    <path d="M500.18 228.29H500.26" stroke="black" stroke-width="0.5" />
    <path d="M500.32 228.29H500.4" stroke="black" stroke-width="0.5" />
    <path d="M500.46 228.29H500.54" stroke="black" stroke-width="0.5" />
    <path d="M500.59 228.29H500.68" stroke="black" stroke-width="0.5" />
    <path d="M500.73 228.29H500.81" stroke="black" stroke-width="0.5" />
    <path d="M500.87 228.29H500.95" stroke="black" stroke-width="0.5" />
    <path d="M501.01 228.29H501.09" stroke="black" stroke-width="0.5" />
    <path d="M501.14 228.29H501.23" stroke="black" stroke-width="0.5" />
    <path d="M501.28 228.29H501.36" stroke="black" stroke-width="0.5" />
    <path d="M501.42 228.29H501.5" stroke="black" stroke-width="0.5" />
    <path d="M501.56 228.29H501.64" stroke="black" stroke-width="0.5" />
    <path d="M501.69 228.29H501.78" stroke="black" stroke-width="0.5" />
    <path d="M501.83 228.29H501.91" stroke="black" stroke-width="0.5" />
    <path d="M501.97 228.29H502.05" stroke="black" stroke-width="0.5" />
    <path d="M502.11 228.29H502.19" stroke="black" stroke-width="0.5" />
    <path d="M502.24 228.29H502.33" stroke="black" stroke-width="0.5" />
    <path d="M502.38 228.29H502.46" stroke="black" stroke-width="0.5" />
    <path d="M502.52 228.29H502.6" stroke="black" stroke-width="0.5" />
    <path d="M502.66 228.29H502.74" stroke="black" stroke-width="0.5" />
    <path d="M502.79 228.29H502.87" stroke="black" stroke-width="0.5" />
    <path d="M502.93 228.29H503.01" stroke="black" stroke-width="0.5" />
    <path d="M503.07 228.29H503.15" stroke="black" stroke-width="0.5" />
    <path d="M503.2 228.29H503.29" stroke="black" stroke-width="0.5" />
    <path d="M503.34 228.29H503.42" stroke="black" stroke-width="0.5" />
    <path d="M503.48 228.29H503.56" stroke="black" stroke-width="0.5" />
    <path d="M503.62 228.29H503.7" stroke="black" stroke-width="0.5" />
    <path d="M503.75 228.29H503.84" stroke="black" stroke-width="0.5" />
    <path d="M503.89 228.29H503.97" stroke="black" stroke-width="0.5" />
    <path d="M504.03 228.29H504.11" stroke="black" stroke-width="0.5" />
    <path d="M504.17 228.29H504.22" stroke="black" stroke-width="0.5" />
    <path d="M504.22 228.29V228.19" stroke="black" stroke-width="0.5" />
    <path d="M504.22 228.14V228.06" stroke="black" stroke-width="0.5" />
    <path d="M504.22 228V227.92" stroke="black" stroke-width="0.5" />
    <path d="M504.22 227.86V227.78" stroke="black" stroke-width="0.5" />
    <path d="M504.22 227.73V227.64" stroke="black" stroke-width="0.5" />
    <path d="M504.22 227.59V227.51" stroke="black" stroke-width="0.5" />
    <path d="M504.22 227.45V227.37" stroke="black" stroke-width="0.5" />
    <path d="M504.22 227.31V227.23" stroke="black" stroke-width="0.5" />
    <path d="M504.22 227.18V227.09" stroke="black" stroke-width="0.5" />
    <path d="M504.22 227.04V226.96" stroke="black" stroke-width="0.5" />
    <path d="M504.22 226.9V226.82" stroke="black" stroke-width="0.5" />
    <path d="M504.22 226.76V226.68" stroke="black" stroke-width="0.5" />
    <path d="M504.22 226.63V226.54" stroke="black" stroke-width="0.5" />
    <path d="M504.22 226.49V226.41" stroke="black" stroke-width="0.5" />
    <path d="M504.22 226.35V226.27" stroke="black" stroke-width="0.5" />
    <path d="M504.22 226.21V226.13" stroke="black" stroke-width="0.5" />
    <path d="M504.22 226.08V225.99" stroke="black" stroke-width="0.5" />
    <path d="M504.22 225.94V225.86" stroke="black" stroke-width="0.5" />
    <path d="M504.22 225.8V225.72" stroke="black" stroke-width="0.5" />
    <path d="M504.22 225.66V225.58" stroke="black" stroke-width="0.5" />
    <path d="M504.22 225.53V225.44" stroke="black" stroke-width="0.5" />
    <path d="M504.22 225.39V225.31" stroke="black" stroke-width="0.5" />
    <path d="M504.22 225.25V225.17" stroke="black" stroke-width="0.5" />
    <path d="M504.22 225.11V225.03" stroke="black" stroke-width="0.5" />
    <path d="M504.22 224.98V224.89" stroke="black" stroke-width="0.5" />
    <path d="M504.22 224.84V224.76" stroke="black" stroke-width="0.5" />
    <path d="M504.22 224.7V224.62" stroke="black" stroke-width="0.5" />
    <path d="M504.22 224.56V224.48" stroke="black" stroke-width="0.5" />
    <path d="M504.22 224.43V224.34" stroke="black" stroke-width="0.5" />
    <path d="M504.22 224.29V224.21" stroke="black" stroke-width="0.5" />
    <path d="M504.22 224.15V224.07" stroke="black" stroke-width="0.5" />
    <path d="M504.22 224.01V223.93" stroke="black" stroke-width="0.5" />
    <path d="M504.22 223.88V223.79" stroke="black" stroke-width="0.5" />
    <path d="M504.22 223.74V223.66" stroke="black" stroke-width="0.5" />
    <path d="M504.22 223.6V223.52" stroke="black" stroke-width="0.5" />
    <path d="M504.22 223.46V223.38" stroke="black" stroke-width="0.5" />
    <path d="M504.22 223.33V223.24" stroke="black" stroke-width="0.5" />
    <path d="M504.22 223.19V223.11" stroke="black" stroke-width="0.5" />
    <path d="M504.22 223.05V222.97" stroke="black" stroke-width="0.5" />
    <path d="M504.22 222.91V222.83" stroke="black" stroke-width="0.5" />
    <path d="M504.22 222.78V222.69" stroke="black" stroke-width="0.5" />
    <path d="M504.22 222.64V222.56" stroke="black" stroke-width="0.5" />
    <path d="M504.22 222.5V222.42" stroke="black" stroke-width="0.5" />
    <path d="M504.22 222.36V222.28" stroke="black" stroke-width="0.5" />
    <path d="M504.22 222.23V222.14" stroke="black" stroke-width="0.5" />
    <path d="M504.22 222.09V222.01" stroke="black" stroke-width="0.5" />
    <path d="M504.22 221.95V221.87" stroke="black" stroke-width="0.5" />
    <path d="M504.22 221.82V221.73" stroke="black" stroke-width="0.5" />
    <path d="M504.22 221.68V221.6" stroke="black" stroke-width="0.5" />
    <path d="M504.22 221.54V221.46" stroke="black" stroke-width="0.5" />
    <path d="M504.22 221.4V221.32" stroke="black" stroke-width="0.5" />
    <path d="M504.22 221.27V221.18" stroke="black" stroke-width="0.5" />
    <path d="M504.22 221.13V221.05" stroke="black" stroke-width="0.5" />
    <path d="M504.22 220.99V220.91" stroke="black" stroke-width="0.5" />
    <path d="M504.22 220.85V220.77" stroke="black" stroke-width="0.5" />
    <path d="M504.22 220.72V220.63" stroke="black" stroke-width="0.5" />
    <path d="M504.22 220.58V220.5" stroke="black" stroke-width="0.5" />
    <path d="M504.22 220.44V220.36" stroke="black" stroke-width="0.5" />
    <path d="M504.22 220.3V220.22" stroke="black" stroke-width="0.5" />
    <path d="M504.22 220.17V220.08" stroke="black" stroke-width="0.5" />
    <path d="M504.22 220.03V219.95" stroke="black" stroke-width="0.5" />
    <path d="M504.22 219.89V219.81" stroke="black" stroke-width="0.5" />
    <path d="M504.22 219.75V219.67" stroke="black" stroke-width="0.5" />
    <path d="M504.22 219.62V219.53" stroke="black" stroke-width="0.5" />
    <path d="M504.22 219.48V219.4" stroke="black" stroke-width="0.5" />
    <path d="M504.22 219.34V219.26" stroke="black" stroke-width="0.5" />
    <path d="M504.22 219.2V219.12" stroke="black" stroke-width="0.5" />
    <path d="M504.22 219.07V218.98" stroke="black" stroke-width="0.5" />
    <path d="M504.22 218.93V218.85" stroke="black" stroke-width="0.5" />
    <path d="M504.22 218.79V218.71" stroke="black" stroke-width="0.5" />
    <path d="M504.22 218.65V218.57" stroke="black" stroke-width="0.5" />
    <path d="M504.22 218.52V218.43" stroke="black" stroke-width="0.5" />
    <path d="M504.22 218.38V218.3" stroke="black" stroke-width="0.5" />
    <path d="M504.22 218.24V218.16" stroke="black" stroke-width="0.5" />
    <path d="M504.22 218.1V218.02" stroke="black" stroke-width="0.5" />
    <path d="M504.22 217.97V217.88" stroke="black" stroke-width="0.5" />
    <path d="M504.22 217.83V217.73" stroke="black" stroke-width="0.5" />
    <path d="M504.22 217.73H504.17" stroke="black" stroke-width="0.5" />
    <path d="M504.11 217.73H504.03" stroke="black" stroke-width="0.5" />
    <path d="M503.97 217.73H503.89" stroke="black" stroke-width="0.5" />
    <path d="M503.84 217.73H503.75" stroke="black" stroke-width="0.5" />
    <path d="M503.7 217.73H503.62" stroke="black" stroke-width="0.5" />
    <path d="M503.56 217.73H503.48" stroke="black" stroke-width="0.5" />
    <path d="M503.42 217.73H503.34" stroke="black" stroke-width="0.5" />
    <path d="M503.29 217.73H503.2" stroke="black" stroke-width="0.5" />
    <path d="M503.15 217.73H503.07" stroke="black" stroke-width="0.5" />
    <path d="M503.01 217.73H502.93" stroke="black" stroke-width="0.5" />
    <path d="M502.87 217.73H502.79" stroke="black" stroke-width="0.5" />
    <path d="M502.74 217.73H502.66" stroke="black" stroke-width="0.5" />
    <path d="M502.6 217.73H502.52" stroke="black" stroke-width="0.5" />
    <path d="M502.46 217.73H502.38" stroke="black" stroke-width="0.5" />
    <path d="M502.33 217.73H502.24" stroke="black" stroke-width="0.5" />
    <path d="M502.19 217.73H502.11" stroke="black" stroke-width="0.5" />
    <path d="M502.05 217.73H501.97" stroke="black" stroke-width="0.5" />
    <path d="M501.91 217.73H501.83" stroke="black" stroke-width="0.5" />
    <path d="M501.78 217.73H501.69" stroke="black" stroke-width="0.5" />
    <path d="M501.64 217.73H501.56" stroke="black" stroke-width="0.5" />
    <path d="M501.5 217.73H501.42" stroke="black" stroke-width="0.5" />
    <path d="M501.36 217.73H501.28" stroke="black" stroke-width="0.5" />
    <path d="M501.23 217.73H501.14" stroke="black" stroke-width="0.5" />
    <path d="M501.09 217.73H501.01" stroke="black" stroke-width="0.5" />
    <path d="M500.95 217.73H500.87" stroke="black" stroke-width="0.5" />
    <path d="M500.81 217.73H500.73" stroke="black" stroke-width="0.5" />
    <path d="M500.68 217.73H500.59" stroke="black" stroke-width="0.5" />
    <path d="M500.54 217.73H500.46" stroke="black" stroke-width="0.5" />
    <path d="M500.4 217.73H500.32" stroke="black" stroke-width="0.5" />
    <path d="M500.26 217.73H500.18" stroke="black" stroke-width="0.5" />
    <path d="M500.13 217.73H500.04" stroke="black" stroke-width="0.5" />
    <path d="M499.99 217.73H499.91" stroke="black" stroke-width="0.5" />
    <path d="M499.85 217.73H499.77" stroke="black" stroke-width="0.5" />
    <path d="M499.71 217.73H499.63" stroke="black" stroke-width="0.5" />
    <path d="M499.58 217.73H499.49" stroke="black" stroke-width="0.5" />
    <path d="M499.44 217.73H499.36" stroke="black" stroke-width="0.5" />
    <path d="M499.3 217.73H499.22" stroke="black" stroke-width="0.5" />
    <path d="M499.16 217.73H499.08" stroke="black" stroke-width="0.5" />
    <path d="M499.03 217.73H498.94" stroke="black" stroke-width="0.5" />
    <path d="M498.89 217.73H498.81" stroke="black" stroke-width="0.5" />
    <path d="M498.75 217.73H498.67" stroke="black" stroke-width="0.5" />
    <path d="M498.61 217.73H498.53" stroke="black" stroke-width="0.5" />
    <path d="M498.48 217.73H498.39" stroke="black" stroke-width="0.5" />
    <path d="M498.34 217.73H498.26" stroke="black" stroke-width="0.5" />
    <path d="M498.2 217.73H498.12" stroke="black" stroke-width="0.5" />
    <path d="M498.06 217.73H497.98" stroke="black" stroke-width="0.5" />
    <path d="M497.93 217.73H497.84" stroke="black" stroke-width="0.5" />
    <path d="M497.79 217.73H497.71" stroke="black" stroke-width="0.5" />
    <path d="M497.65 217.73H497.57" stroke="black" stroke-width="0.5" />
    <path d="M497.51 217.73H497.43" stroke="black" stroke-width="0.5" />
    <path d="M497.38 217.73H497.29" stroke="black" stroke-width="0.5" />
    <path d="M497.24 217.73H497.16" stroke="black" stroke-width="0.5" />
    <path d="M497.1 217.73H497.02" stroke="black" stroke-width="0.5" />
    <path d="M496.96 217.73H496.88" stroke="black" stroke-width="0.5" />
    <path d="M496.83 217.73H496.74" stroke="black" stroke-width="0.5" />
    <path d="M496.69 217.73H496.61" stroke="black" stroke-width="0.5" />
    <path d="M496.55 217.73H496.47" stroke="black" stroke-width="0.5" />
    <path d="M496.41 217.73H496.33" stroke="black" stroke-width="0.5" />
    <path d="M496.28 217.73H496.2" stroke="black" stroke-width="0.5" />
    <path d="M496.14 217.73H496.06" stroke="black" stroke-width="0.5" />
    <path d="M496 217.73H495.92" stroke="black" stroke-width="0.5" />
    <path d="M495.87 217.73H495.78" stroke="black" stroke-width="0.5" />
    <path d="M495.73 217.73H495.65" stroke="black" stroke-width="0.5" />
    <path d="M495.59 217.73H495.51" stroke="black" stroke-width="0.5" />
    <path d="M495.45 217.73H495.37" stroke="black" stroke-width="0.5" />
    <path d="M495.32 217.73H495.23" stroke="black" stroke-width="0.5" />
    <path d="M495.18 217.73H495.1" stroke="black" stroke-width="0.5" />
    <path d="M495.04 217.73H494.96" stroke="black" stroke-width="0.5" />
    <path d="M494.9 217.73H494.82" stroke="black" stroke-width="0.5" />
    <path d="M494.77 217.73H494.68" stroke="black" stroke-width="0.5" />
    <path d="M494.63 217.73H494.55" stroke="black" stroke-width="0.5" />
    <path d="M494.49 217.73H494.41" stroke="black" stroke-width="0.5" />
    <path d="M494.35 217.73H494.27" stroke="black" stroke-width="0.5" />
    <path d="M494.22 217.73H494.13" stroke="black" stroke-width="0.5" />
    <path d="M494.08 217.73H494" stroke="black" stroke-width="0.5" />
    <path d="M493.94 217.73H493.86" stroke="black" stroke-width="0.5" />
    <path d="M493.8 217.73H493.72" stroke="black" stroke-width="0.5" />
    <path d="M493.67 217.73H493.58" stroke="black" stroke-width="0.5" />
    <path d="M493.53 217.73H493.45" stroke="black" stroke-width="0.5" />
    <path d="M493.39 217.73H493.31" stroke="black" stroke-width="0.5" />
    <path d="M493.25 217.73H493.17" stroke="black" stroke-width="0.5" />
    <path d="M493.12 217.73H493.03" stroke="black" stroke-width="0.5" />
    <path d="M492.98 217.73H492.9" stroke="black" stroke-width="0.5" />
    <path d="M492.84 217.73H492.76" stroke="black" stroke-width="0.5" />
    <path d="M492.7 217.73H492.62" stroke="black" stroke-width="0.5" />
    <path d="M492.57 217.73H492.48" stroke="black" stroke-width="0.5" />
    <path d="M492.43 217.73H492.35" stroke="black" stroke-width="0.5" />
    <path d="M492.29 217.73H492.21" stroke="black" stroke-width="0.5" />
    <path d="M492.15 217.73H492.07" stroke="black" stroke-width="0.5" />
    <path d="M492.02 217.73H491.93" stroke="black" stroke-width="0.5" />
    <path d="M491.88 217.73H491.8" stroke="black" stroke-width="0.5" />
    <path d="M491.74 217.73H491.66" stroke="black" stroke-width="0.5" />
    <path d="M491.6 217.73H491.52" stroke="black" stroke-width="0.5" />
    <path d="M491.47 217.73H491.38" stroke="black" stroke-width="0.5" />
    <path d="M491.33 217.73H491.25" stroke="black" stroke-width="0.5" />
    <path d="M491.19 217.73H491.11" stroke="black" stroke-width="0.5" />
    <path d="M491.05 217.73H490.97" stroke="black" stroke-width="0.5" />
    <path d="M490.92 217.73H490.83" stroke="black" stroke-width="0.5" />
    <path d="M490.78 217.73H490.7" stroke="black" stroke-width="0.5" />
    <path d="M490.64 217.73H490.56" stroke="black" stroke-width="0.5" />
    <path d="M490.5 217.73H490.42" stroke="black" stroke-width="0.5" />
    <path d="M490.37 217.73H490.28" stroke="black" stroke-width="0.5" />
    <path d="M490.23 217.73H490.15" stroke="black" stroke-width="0.5" />
    <path d="M490.09 217.73H490.01" stroke="black" stroke-width="0.5" />
    <path d="M489.95 217.73H489.87" stroke="black" stroke-width="0.5" />
    <path d="M489.82 217.73H489.74" stroke="black" stroke-width="0.5" />
    <path d="M489.68 217.73H489.6" stroke="black" stroke-width="0.5" />
    <path d="M489.54 217.73H489.46" stroke="black" stroke-width="0.5" />
    <path d="M489.41 217.73H489.32" stroke="black" stroke-width="0.5" />
    <path d="M489.27 217.73H489.19" stroke="black" stroke-width="0.5" />
    <path d="M489.13 217.73H489.05" stroke="black" stroke-width="0.5" />
    <path d="M488.99 217.73H488.91" stroke="black" stroke-width="0.5" />
    <path d="M488.86 217.73H488.77" stroke="black" stroke-width="0.5" />
    <path d="M488.72 217.73H488.64" stroke="black" stroke-width="0.5" />
    <path d="M488.58 217.73H488.5" stroke="black" stroke-width="0.5" />
    <path d="M488.44 217.73H488.36" stroke="black" stroke-width="0.5" />
    <path d="M488.31 217.73H488.22" stroke="black" stroke-width="0.5" />
    <path d="M488.17 217.73H488.09" stroke="black" stroke-width="0.5" />
    <path d="M488.03 217.73H487.95" stroke="black" stroke-width="0.5" />
    <path d="M487.89 217.73H487.81" stroke="black" stroke-width="0.5" />
    <path d="M487.76 217.73H487.67" stroke="black" stroke-width="0.5" />
    <path d="M487.62 217.73H487.54" stroke="black" stroke-width="0.5" />
    <path d="M487.48 217.73H487.4" stroke="black" stroke-width="0.5" />
    <path d="M487.34 217.73H487.26" stroke="black" stroke-width="0.5" />
    <path d="M487.21 217.73H487.12" stroke="black" stroke-width="0.5" />
    <path d="M487.07 217.73H486.99" stroke="black" stroke-width="0.5" />
    <path d="M486.93 217.73H486.85" stroke="black" stroke-width="0.5" />
    <path d="M486.79 217.73H486.71" stroke="black" stroke-width="0.5" />
    <path d="M486.66 217.73H486.57" stroke="black" stroke-width="0.5" />
    <path d="M486.52 217.73H486.44" stroke="black" stroke-width="0.5" />
    <path d="M486.38 217.73H486.3" stroke="black" stroke-width="0.5" />
    <path d="M486.24 217.73H486.16" stroke="black" stroke-width="0.5" />
    <path d="M486.11 217.73H486.02" stroke="black" stroke-width="0.5" />
    <path d="M485.97 217.73H485.89" stroke="black" stroke-width="0.5" />
    <path d="M485.83 217.73H485.75" stroke="black" stroke-width="0.5" />
    <path d="M485.69 217.73H485.61" stroke="black" stroke-width="0.5" />
    <path d="M485.56 217.73H485.47" stroke="black" stroke-width="0.5" />
    <path d="M485.42 217.73H485.34" stroke="black" stroke-width="0.5" />
    <path d="M485.28 217.73H485.2" stroke="black" stroke-width="0.5" />
    <path d="M485.14 217.73H485.06" stroke="black" stroke-width="0.5" />
    <path d="M485.01 217.73H484.92" stroke="black" stroke-width="0.5" />
    <path d="M484.87 217.73H484.79" stroke="black" stroke-width="0.5" />
    <path d="M484.73 217.73H484.65" stroke="black" stroke-width="0.5" />
    <path d="M484.59 217.73H484.51" stroke="black" stroke-width="0.5" />
    <path d="M484.46 217.73H484.37" stroke="black" stroke-width="0.5" />
    <path d="M484.32 217.73H484.24" stroke="black" stroke-width="0.5" />
    <path d="M484.18 217.73H484.1" stroke="black" stroke-width="0.5" />
    <path d="M484.04 217.73H483.96" stroke="black" stroke-width="0.5" />
    <path d="M483.91 217.73H483.82" stroke="black" stroke-width="0.5" />
    <path d="M483.77 217.73H483.69" stroke="black" stroke-width="0.5" />
    <path d="M483.63 217.73H483.55" stroke="black" stroke-width="0.5" />
    <path d="M483.49 217.73H483.41" stroke="black" stroke-width="0.5" />
    <path d="M483.36 217.73H483.28" stroke="black" stroke-width="0.5" />
    <path d="M483.22 217.73H483.14" stroke="black" stroke-width="0.5" />
    <path d="M483.08 217.73H483" stroke="black" stroke-width="0.5" />
    <path d="M482.95 217.73H482.86" stroke="black" stroke-width="0.5" />
    <path d="M482.81 217.73H482.73" stroke="black" stroke-width="0.5" />
    <path d="M482.67 217.73H482.59" stroke="black" stroke-width="0.5" />
    <path d="M482.53 217.73H482.45" stroke="black" stroke-width="0.5" />
    <path d="M482.4 217.73H482.31" stroke="black" stroke-width="0.5" />
    <path d="M482.26 217.73H482.18" stroke="black" stroke-width="0.5" />
    <path d="M482.12 217.73H482.04" stroke="black" stroke-width="0.5" />
    <path d="M481.98 217.73H481.9" stroke="black" stroke-width="0.5" />
    <path d="M481.85 217.73H481.76" stroke="black" stroke-width="0.5" />
    <path d="M481.71 217.73H481.63" stroke="black" stroke-width="0.5" />
    <path d="M481.57 217.73H481.49" stroke="black" stroke-width="0.5" />
    <path d="M481.43 217.73H481.35" stroke="black" stroke-width="0.5" />
    <path d="M481.3 217.73H481.21" stroke="black" stroke-width="0.5" />
    <path d="M481.16 217.73H481.08" stroke="black" stroke-width="0.5" />
    <path d="M481.02 217.73H480.94" stroke="black" stroke-width="0.5" />
    <path d="M480.88 217.73H480.8" stroke="black" stroke-width="0.5" />
    <path d="M480.75 217.73H480.66" stroke="black" stroke-width="0.5" />
    <path d="M480.61 217.73H480.53" stroke="black" stroke-width="0.5" />
    <path d="M480.47 217.73H480.39" stroke="black" stroke-width="0.5" />
    <path d="M480.33 217.73H480.25" stroke="black" stroke-width="0.5" />
    <path d="M480.2 217.73H480.11" stroke="black" stroke-width="0.5" />
    <path d="M480.06 217.73H479.98" stroke="black" stroke-width="0.5" />
    <path d="M479.92 217.73H479.84" stroke="black" stroke-width="0.5" />
    <path d="M479.78 217.73H479.7" stroke="black" stroke-width="0.5" />
    <path d="M479.65 217.73H479.56" stroke="black" stroke-width="0.5" />
    <path d="M479.51 217.73H479.43" stroke="black" stroke-width="0.5" />
    <path d="M479.37 217.73H479.29" stroke="black" stroke-width="0.5" />
    <path d="M479.23 217.73H479.15" stroke="black" stroke-width="0.5" />
    <path d="M479.1 217.73H479.01" stroke="black" stroke-width="0.5" />
    <path d="M478.96 217.73H478.88" stroke="black" stroke-width="0.5" />
    <path d="M478.82 217.73H478.74" stroke="black" stroke-width="0.5" />
    <path d="M478.68 217.73H478.6" stroke="black" stroke-width="0.5" />
    <path d="M478.55 217.73H478.46" stroke="black" stroke-width="0.5" />
    <path d="M478.41 217.73H478.33" stroke="black" stroke-width="0.5" />
    <path d="M478.27 217.73H478.19" stroke="black" stroke-width="0.5" />
    <path d="M478.13 217.73H478.05" stroke="black" stroke-width="0.5" />
    <path d="M478 217.73H477.91" stroke="black" stroke-width="0.5" />
    <path d="M477.86 217.73H477.78" stroke="black" stroke-width="0.5" />
    <path d="M477.72 217.73H477.64" stroke="black" stroke-width="0.5" />
    <path d="M477.58 217.73H477.5" stroke="black" stroke-width="0.5" />
    <path d="M477.45 217.73H477.36" stroke="black" stroke-width="0.5" />
    <path d="M477.31 217.73H477.23" stroke="black" stroke-width="0.5" />
    <path d="M477.17 217.73H477.09" stroke="black" stroke-width="0.5" />
    <path d="M477.03 217.73H476.95" stroke="black" stroke-width="0.5" />
    <path d="M476.9 217.73H476.82" stroke="black" stroke-width="0.5" />
    <path d="M476.76 217.73H476.68" stroke="black" stroke-width="0.5" />
    <path d="M476.62 217.73H476.54" stroke="black" stroke-width="0.5" />
    <path d="M476.49 217.73H476.4" stroke="black" stroke-width="0.5" />
    <path d="M476.35 217.73H476.27" stroke="black" stroke-width="0.5" />
    <path d="M476.21 217.73H476.13" stroke="black" stroke-width="0.5" />
    <path d="M476.07 217.73H475.99" stroke="black" stroke-width="0.5" />
    <path d="M475.94 217.73H475.85" stroke="black" stroke-width="0.5" />
    <path d="M475.8 217.73H475.72" stroke="black" stroke-width="0.5" />
    <path d="M475.66 217.73H475.58" stroke="black" stroke-width="0.5" />
    <path d="M475.52 217.73H475.44" stroke="black" stroke-width="0.5" />
    <path d="M475.39 217.73H475.3" stroke="black" stroke-width="0.5" />
    <path d="M475.25 217.73H475.17" stroke="black" stroke-width="0.5" />
    <path d="M475.11 217.73H475.03" stroke="black" stroke-width="0.5" />
    <path d="M474.97 217.73H474.89" stroke="black" stroke-width="0.5" />
    <path d="M474.84 217.73H474.75" stroke="black" stroke-width="0.5" />
    <path d="M474.7 217.73H474.62" stroke="black" stroke-width="0.5" />
    <path d="M474.56 217.73H474.48" stroke="black" stroke-width="0.5" />
    <path d="M474.42 217.73H474.34" stroke="black" stroke-width="0.5" />
    <path d="M474.29 217.73H474.2" stroke="black" stroke-width="0.5" />
    <path d="M474.15 217.73H474.07" stroke="black" stroke-width="0.5" />
    <path d="M474.01 217.73H473.93" stroke="black" stroke-width="0.5" />
    <path d="M473.87 217.73H473.79" stroke="black" stroke-width="0.5" />
    <path d="M473.74 217.73H473.65" stroke="black" stroke-width="0.5" />
    <path d="M473.6 217.73H473.52" stroke="black" stroke-width="0.5" />
    <path d="M473.46 217.73H473.38" stroke="black" stroke-width="0.5" />
    <path d="M473.32 217.73H473.24" stroke="black" stroke-width="0.5" />
    <path d="M473.19 217.73H473.1" stroke="black" stroke-width="0.5" />
    <path d="M473.05 217.73H472.97" stroke="black" stroke-width="0.5" />
    <path d="M472.91 217.73H472.83" stroke="black" stroke-width="0.5" />
    <path d="M472.77 217.73H472.69" stroke="black" stroke-width="0.5" />
    <path d="M472.64 217.73H472.55" stroke="black" stroke-width="0.5" />
    <path d="M472.5 217.73H472.42" stroke="black" stroke-width="0.5" />
    <path d="M472.36 217.73H472.28" stroke="black" stroke-width="0.5" />
    <path d="M472.22 217.73H472.14" stroke="black" stroke-width="0.5" />
    <path d="M472.09 217.73H472" stroke="black" stroke-width="0.5" />
    <path d="M471.95 217.73H471.87" stroke="black" stroke-width="0.5" />
    <path d="M471.81 217.73H471.73" stroke="black" stroke-width="0.5" />
    <path d="M471.67 217.73H471.59" stroke="black" stroke-width="0.5" />
    <path d="M471.54 217.73H471.45" stroke="black" stroke-width="0.5" />
    <path d="M471.4 217.73H471.32" stroke="black" stroke-width="0.5" />
    <path d="M471.26 217.73H471.18" stroke="black" stroke-width="0.5" />
    <path d="M471.12 217.73H471.04" stroke="black" stroke-width="0.5" />
    <path d="M470.99 217.73H470.9" stroke="black" stroke-width="0.5" />
    <path d="M470.85 217.73H470.77" stroke="black" stroke-width="0.5" />
    <path d="M470.71 217.73H470.63" stroke="black" stroke-width="0.5" />
    <path d="M470.57 217.73H470.49" stroke="black" stroke-width="0.5" />
    <path d="M470.44 217.73H470.36" stroke="black" stroke-width="0.5" />
    <path d="M470.3 217.73H470.22" stroke="black" stroke-width="0.5" />
    <path d="M470.16 217.73H470.08" stroke="black" stroke-width="0.5" />
    <path d="M470.03 217.73H469.94" stroke="black" stroke-width="0.5" />
    <path d="M469.89 217.73H469.81" stroke="black" stroke-width="0.5" />
    <path d="M469.75 217.73H469.7" stroke="black" stroke-width="0.5" />
    <path d="M435.17 217.73V217.83" stroke="black" stroke-width="0.5" />
    <path d="M435.17 217.88V217.97" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.02V218.1" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.16V218.24" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.3V218.38" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.43V218.52" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.57V218.65" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.71V218.79" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.85V218.93" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.98V219.07" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.12V219.2" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.26V219.34" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.4V219.48" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.53V219.62" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.67V219.75" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.81V219.89" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.95V220.03" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.08V220.17" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.22V220.3" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.36V220.44" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.5V220.58" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.63V220.72" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.77V220.85" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.91V220.99" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.05V221.13" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.18V221.27" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.32V221.4" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.46V221.54" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.6V221.68" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.73V221.82" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.87V221.95" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.01V222.09" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.14V222.23" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.28V222.36" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.42V222.5" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.56V222.64" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.69V222.78" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.83V222.91" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.97V223.05" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.11V223.19" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.24V223.33" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.38V223.46" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.52V223.6" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.66V223.74" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.79V223.88" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.93V224.01" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.07V224.15" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.21V224.29" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.34V224.43" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.48V224.56" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.62V224.7" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.76V224.84" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.89V224.98" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.03V225.11" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.17V225.25" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.31V225.39" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.44V225.53" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.58V225.66" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.72V225.8" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.86V225.94" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.99V226.08" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.13V226.21" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.27V226.35" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.41V226.49" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.54V226.63" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.68V226.76" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.82V226.9" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.96V227.04" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.09V227.18" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.23V227.31" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.37V227.45" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.51V227.59" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.64V227.73" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.78V227.86" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.92V228" stroke="black" stroke-width="0.5" />
    <path d="M435.17 228.06V228.14" stroke="black" stroke-width="0.5" />
    <path d="M435.17 228.19V228.29" stroke="black" stroke-width="0.5" />
    <path d="M435.17 228.29H435.22" stroke="black" stroke-width="0.5" />
    <path d="M435.28 228.29H435.36" stroke="black" stroke-width="0.5" />
    <path d="M435.42 228.29H435.5" stroke="black" stroke-width="0.5" />
    <path d="M435.55 228.29H435.64" stroke="black" stroke-width="0.5" />
    <path d="M435.69 228.29H435.77" stroke="black" stroke-width="0.5" />
    <path d="M435.83 228.29H435.91" stroke="black" stroke-width="0.5" />
    <path d="M435.97 228.29H436.05" stroke="black" stroke-width="0.5" />
    <path d="M436.1 228.29H436.19" stroke="black" stroke-width="0.5" />
    <path d="M436.24 228.29H436.32" stroke="black" stroke-width="0.5" />
    <path d="M436.38 228.29H436.46" stroke="black" stroke-width="0.5" />
    <path d="M436.52 228.29H436.6" stroke="black" stroke-width="0.5" />
    <path d="M436.65 228.29H436.74" stroke="black" stroke-width="0.5" />
    <path d="M436.79 228.29H436.87" stroke="black" stroke-width="0.5" />
    <path d="M436.93 228.29H437.01" stroke="black" stroke-width="0.5" />
    <path d="M437.07 228.29H437.15" stroke="black" stroke-width="0.5" />
    <path d="M437.2 228.29H437.29" stroke="black" stroke-width="0.5" />
    <path d="M437.34 228.29H437.42" stroke="black" stroke-width="0.5" />
    <path d="M437.48 228.29H437.56" stroke="black" stroke-width="0.5" />
    <path d="M437.62 228.29H437.7" stroke="black" stroke-width="0.5" />
    <path d="M437.75 228.29H437.84" stroke="black" stroke-width="0.5" />
    <path d="M437.89 228.29H437.97" stroke="black" stroke-width="0.5" />
    <path d="M438.03 228.29H438.11" stroke="black" stroke-width="0.5" />
    <path d="M438.16 228.29H438.25" stroke="black" stroke-width="0.5" />
    <path d="M438.3 228.29H438.38" stroke="black" stroke-width="0.5" />
    <path d="M438.44 228.29H438.52" stroke="black" stroke-width="0.5" />
    <path d="M438.58 228.29H438.66" stroke="black" stroke-width="0.5" />
    <path d="M438.71 228.29H438.8" stroke="black" stroke-width="0.5" />
    <path d="M438.85 228.29H438.93" stroke="black" stroke-width="0.5" />
    <path d="M438.99 228.29H439.07" stroke="black" stroke-width="0.5" />
    <path d="M439.13 228.29H439.21" stroke="black" stroke-width="0.5" />
    <path d="M439.26 228.29H439.35" stroke="black" stroke-width="0.5" />
    <path d="M439.4 228.29H439.48" stroke="black" stroke-width="0.5" />
    <path d="M439.54 228.29H439.62" stroke="black" stroke-width="0.5" />
    <path d="M439.68 228.29H439.76" stroke="black" stroke-width="0.5" />
    <path d="M439.81 228.29H439.9" stroke="black" stroke-width="0.5" />
    <path d="M439.95 228.29H440.03" stroke="black" stroke-width="0.5" />
    <path d="M440.09 228.29H440.17" stroke="black" stroke-width="0.5" />
    <path d="M440.23 228.29H440.31" stroke="black" stroke-width="0.5" />
    <path d="M440.36 228.29H440.45" stroke="black" stroke-width="0.5" />
    <path d="M440.5 228.29H440.58" stroke="black" stroke-width="0.5" />
    <path d="M440.64 228.29H440.72" stroke="black" stroke-width="0.5" />
    <path d="M440.78 228.29H440.86" stroke="black" stroke-width="0.5" />
    <path d="M440.91 228.29H441" stroke="black" stroke-width="0.5" />
    <path d="M441.05 228.29H441.13" stroke="black" stroke-width="0.5" />
    <path d="M441.19 228.29H441.27" stroke="black" stroke-width="0.5" />
    <path d="M441.33 228.29H441.41" stroke="black" stroke-width="0.5" />
    <path d="M441.46 228.29H441.55" stroke="black" stroke-width="0.5" />
    <path d="M441.6 228.29H441.68" stroke="black" stroke-width="0.5" />
    <path d="M441.74 228.29H441.82" stroke="black" stroke-width="0.5" />
    <path d="M441.88 228.29H441.96" stroke="black" stroke-width="0.5" />
    <path d="M442.01 228.29H442.1" stroke="black" stroke-width="0.5" />
    <path d="M442.15 228.29H442.23" stroke="black" stroke-width="0.5" />
    <path d="M442.29 228.29H442.37" stroke="black" stroke-width="0.5" />
    <path d="M442.43 228.29H442.51" stroke="black" stroke-width="0.5" />
    <path d="M442.56 228.29H442.65" stroke="black" stroke-width="0.5" />
    <path d="M442.7 228.29H442.78" stroke="black" stroke-width="0.5" />
    <path d="M442.84 228.29H442.92" stroke="black" stroke-width="0.5" />
    <path d="M442.98 228.29H443.06" stroke="black" stroke-width="0.5" />
    <path d="M443.11 228.29H443.2" stroke="black" stroke-width="0.5" />
    <path d="M443.25 228.29H443.33" stroke="black" stroke-width="0.5" />
    <path d="M443.39 228.29H443.47" stroke="black" stroke-width="0.5" />
    <path d="M443.53 228.29H443.61" stroke="black" stroke-width="0.5" />
    <path d="M443.66 228.29H443.75" stroke="black" stroke-width="0.5" />
    <path d="M443.8 228.29H443.88" stroke="black" stroke-width="0.5" />
    <path d="M443.94 228.29H444.02" stroke="black" stroke-width="0.5" />
    <path d="M444.08 228.29H444.16" stroke="black" stroke-width="0.5" />
    <path d="M444.21 228.29H444.3" stroke="black" stroke-width="0.5" />
    <path d="M444.35 228.29H444.43" stroke="black" stroke-width="0.5" />
    <path d="M444.49 228.29H444.57" stroke="black" stroke-width="0.5" />
    <path d="M444.62 228.29H444.71" stroke="black" stroke-width="0.5" />
    <path d="M444.76 228.29H444.84" stroke="black" stroke-width="0.5" />
    <path d="M444.9 228.29H444.98" stroke="black" stroke-width="0.5" />
    <path d="M445.04 228.29H445.12" stroke="black" stroke-width="0.5" />
    <path d="M445.17 228.29H445.26" stroke="black" stroke-width="0.5" />
    <path d="M445.31 228.29H445.39" stroke="black" stroke-width="0.5" />
    <path d="M445.45 228.29H445.53" stroke="black" stroke-width="0.5" />
    <path d="M445.59 228.29H445.67" stroke="black" stroke-width="0.5" />
    <path d="M445.72 228.29H445.81" stroke="black" stroke-width="0.5" />
    <path d="M445.86 228.29H445.94" stroke="black" stroke-width="0.5" />
    <path d="M446 228.29H446.08" stroke="black" stroke-width="0.5" />
    <path d="M446.14 228.29H446.22" stroke="black" stroke-width="0.5" />
    <path d="M446.27 228.29H446.36" stroke="black" stroke-width="0.5" />
    <path d="M446.41 228.29H446.49" stroke="black" stroke-width="0.5" />
    <path d="M446.55 228.29H446.63" stroke="black" stroke-width="0.5" />
    <path d="M446.69 228.29H446.77" stroke="black" stroke-width="0.5" />
    <path d="M446.82 228.29H446.91" stroke="black" stroke-width="0.5" />
    <path d="M446.96 228.29H447.04" stroke="black" stroke-width="0.5" />
    <path d="M447.1 228.29H447.18" stroke="black" stroke-width="0.5" />
    <path d="M447.24 228.29H447.32" stroke="black" stroke-width="0.5" />
    <path d="M447.37 228.29H447.46" stroke="black" stroke-width="0.5" />
    <path d="M447.51 228.29H447.59" stroke="black" stroke-width="0.5" />
    <path d="M447.65 228.29H447.73" stroke="black" stroke-width="0.5" />
    <path d="M447.79 228.29H447.87" stroke="black" stroke-width="0.5" />
    <path d="M447.92 228.29H448.01" stroke="black" stroke-width="0.5" />
    <path d="M448.06 228.29H448.14" stroke="black" stroke-width="0.5" />
    <path d="M448.2 228.29H448.28" stroke="black" stroke-width="0.5" />
    <path d="M448.34 228.29H448.42" stroke="black" stroke-width="0.5" />
    <path d="M448.47 228.29H448.56" stroke="black" stroke-width="0.5" />
    <path d="M448.61 228.29H448.69" stroke="black" stroke-width="0.5" />
    <path d="M448.75 228.29H448.83" stroke="black" stroke-width="0.5" />
    <path d="M448.89 228.29H448.97" stroke="black" stroke-width="0.5" />
    <path d="M449.02 228.29H449.11" stroke="black" stroke-width="0.5" />
    <path d="M449.16 228.29H449.24" stroke="black" stroke-width="0.5" />
    <path d="M449.3 228.29H449.38" stroke="black" stroke-width="0.5" />
    <path d="M449.44 228.29H449.52" stroke="black" stroke-width="0.5" />
    <path d="M449.57 228.29H449.66" stroke="black" stroke-width="0.5" />
    <path d="M449.71 228.29H449.79" stroke="black" stroke-width="0.5" />
    <path d="M449.85 228.29H449.93" stroke="black" stroke-width="0.5" />
    <path d="M449.99 228.29H450.07" stroke="black" stroke-width="0.5" />
    <path d="M450.12 228.29H450.21" stroke="black" stroke-width="0.5" />
    <path d="M450.26 228.29H450.34" stroke="black" stroke-width="0.5" />
    <path d="M450.4 228.29H450.48" stroke="black" stroke-width="0.5" />
    <path d="M450.54 228.29H450.62" stroke="black" stroke-width="0.5" />
    <path d="M450.67 228.29H450.76" stroke="black" stroke-width="0.5" />
    <path d="M450.81 228.29H450.89" stroke="black" stroke-width="0.5" />
    <path d="M450.95 228.29H451.03" stroke="black" stroke-width="0.5" />
    <path d="M451.08 228.29H451.17" stroke="black" stroke-width="0.5" />
    <path d="M451.22 228.29H451.3" stroke="black" stroke-width="0.5" />
    <path d="M451.36 228.29H451.44" stroke="black" stroke-width="0.5" />
    <path d="M451.5 228.29H451.58" stroke="black" stroke-width="0.5" />
    <path d="M451.63 228.29H451.72" stroke="black" stroke-width="0.5" />
    <path d="M451.77 228.29H451.85" stroke="black" stroke-width="0.5" />
    <path d="M451.91 228.29H451.99" stroke="black" stroke-width="0.5" />
    <path d="M452.05 228.29H452.13" stroke="black" stroke-width="0.5" />
    <path d="M452.18 228.29H452.27" stroke="black" stroke-width="0.5" />
    <path d="M452.32 228.29H452.4" stroke="black" stroke-width="0.5" />
    <path d="M452.46 228.29H452.54" stroke="black" stroke-width="0.5" />
    <path d="M452.6 228.29H452.68" stroke="black" stroke-width="0.5" />
    <path d="M452.73 228.29H452.82" stroke="black" stroke-width="0.5" />
    <path d="M452.87 228.29H452.95" stroke="black" stroke-width="0.5" />
    <path d="M453.01 228.29H453.09" stroke="black" stroke-width="0.5" />
    <path d="M453.15 228.29H453.23" stroke="black" stroke-width="0.5" />
    <path d="M453.28 228.29H453.37" stroke="black" stroke-width="0.5" />
    <path d="M453.42 228.29H453.5" stroke="black" stroke-width="0.5" />
    <path d="M453.56 228.29H453.64" stroke="black" stroke-width="0.5" />
    <path d="M453.7 228.29H453.78" stroke="black" stroke-width="0.5" />
    <path d="M453.83 228.29H453.92" stroke="black" stroke-width="0.5" />
    <path d="M453.97 228.29H454.05" stroke="black" stroke-width="0.5" />
    <path d="M454.11 228.29H454.19" stroke="black" stroke-width="0.5" />
    <path d="M454.25 228.29H454.33" stroke="black" stroke-width="0.5" />
    <path d="M454.38 228.29H454.47" stroke="black" stroke-width="0.5" />
    <path d="M454.52 228.29H454.6" stroke="black" stroke-width="0.5" />
    <path d="M454.66 228.29H454.74" stroke="black" stroke-width="0.5" />
    <path d="M454.8 228.29H454.88" stroke="black" stroke-width="0.5" />
    <path d="M454.93 228.29H455.02" stroke="black" stroke-width="0.5" />
    <path d="M455.07 228.29H455.15" stroke="black" stroke-width="0.5" />
    <path d="M455.21 228.29H455.29" stroke="black" stroke-width="0.5" />
    <path d="M455.35 228.29H455.43" stroke="black" stroke-width="0.5" />
    <path d="M455.48 228.29H455.57" stroke="black" stroke-width="0.5" />
    <path d="M455.62 228.29H455.7" stroke="black" stroke-width="0.5" />
    <path d="M455.76 228.29H455.84" stroke="black" stroke-width="0.5" />
    <path d="M455.9 228.29H455.98" stroke="black" stroke-width="0.5" />
    <path d="M456.03 228.29H456.12" stroke="black" stroke-width="0.5" />
    <path d="M456.17 228.29H456.25" stroke="black" stroke-width="0.5" />
    <path d="M456.31 228.29H456.39" stroke="black" stroke-width="0.5" />
    <path d="M456.45 228.29H456.53" stroke="black" stroke-width="0.5" />
    <path d="M456.58 228.29H456.67" stroke="black" stroke-width="0.5" />
    <path d="M456.72 228.29H456.8" stroke="black" stroke-width="0.5" />
    <path d="M456.86 228.29H456.94" stroke="black" stroke-width="0.5" />
    <path d="M457 228.29H457.08" stroke="black" stroke-width="0.5" />
    <path d="M457.13 228.29H457.22" stroke="black" stroke-width="0.5" />
    <path d="M457.27 228.29H457.35" stroke="black" stroke-width="0.5" />
    <path d="M457.41 228.29H457.49" stroke="black" stroke-width="0.5" />
    <path d="M457.54 228.29H457.63" stroke="black" stroke-width="0.5" />
    <path d="M457.68 228.29H457.76" stroke="black" stroke-width="0.5" />
    <path d="M457.82 228.29H457.9" stroke="black" stroke-width="0.5" />
    <path d="M457.96 228.29H458.04" stroke="black" stroke-width="0.5" />
    <path d="M458.09 228.29H458.18" stroke="black" stroke-width="0.5" />
    <path d="M458.23 228.29H458.31" stroke="black" stroke-width="0.5" />
    <path d="M458.37 228.29H458.45" stroke="black" stroke-width="0.5" />
    <path d="M458.51 228.29H458.59" stroke="black" stroke-width="0.5" />
    <path d="M458.64 228.29H458.73" stroke="black" stroke-width="0.5" />
    <path d="M458.78 228.29H458.86" stroke="black" stroke-width="0.5" />
    <path d="M458.92 228.29H459" stroke="black" stroke-width="0.5" />
    <path d="M459.06 228.29H459.14" stroke="black" stroke-width="0.5" />
    <path d="M459.19 228.29H459.28" stroke="black" stroke-width="0.5" />
    <path d="M459.33 228.29H459.41" stroke="black" stroke-width="0.5" />
    <path d="M459.47 228.29H459.55" stroke="black" stroke-width="0.5" />
    <path d="M459.61 228.29H459.69" stroke="black" stroke-width="0.5" />
    <path d="M459.74 228.29H459.83" stroke="black" stroke-width="0.5" />
    <path d="M459.88 228.29H459.96" stroke="black" stroke-width="0.5" />
    <path d="M460.02 228.29H460.1" stroke="black" stroke-width="0.5" />
    <path d="M460.16 228.29H460.24" stroke="black" stroke-width="0.5" />
    <path d="M460.29 228.29H460.38" stroke="black" stroke-width="0.5" />
    <path d="M460.43 228.29H460.51" stroke="black" stroke-width="0.5" />
    <path d="M460.57 228.29H460.65" stroke="black" stroke-width="0.5" />
    <path d="M460.71 228.29H460.79" stroke="black" stroke-width="0.5" />
    <path d="M460.84 228.29H460.93" stroke="black" stroke-width="0.5" />
    <path d="M460.98 228.29H461.06" stroke="black" stroke-width="0.5" />
    <path d="M461.12 228.29H461.2" stroke="black" stroke-width="0.5" />
    <path d="M461.26 228.29H461.34" stroke="black" stroke-width="0.5" />
    <path d="M461.39 228.29H461.48" stroke="black" stroke-width="0.5" />
    <path d="M461.53 228.29H461.61" stroke="black" stroke-width="0.5" />
    <path d="M461.67 228.29H461.75" stroke="black" stroke-width="0.5" />
    <path d="M461.81 228.29H461.89" stroke="black" stroke-width="0.5" />
    <path d="M461.94 228.29H462.03" stroke="black" stroke-width="0.5" />
    <path d="M462.08 228.29H462.16" stroke="black" stroke-width="0.5" />
    <path d="M462.22 228.29H462.3" stroke="black" stroke-width="0.5" />
    <path d="M462.36 228.29H462.44" stroke="black" stroke-width="0.5" />
    <path d="M462.49 228.29H462.58" stroke="black" stroke-width="0.5" />
    <path d="M462.63 228.29H462.71" stroke="black" stroke-width="0.5" />
    <path d="M462.77 228.29H462.85" stroke="black" stroke-width="0.5" />
    <path d="M462.91 228.29H462.99" stroke="black" stroke-width="0.5" />
    <path d="M463.04 228.29H463.13" stroke="black" stroke-width="0.5" />
    <path d="M463.18 228.29H463.26" stroke="black" stroke-width="0.5" />
    <path d="M463.32 228.29H463.4" stroke="black" stroke-width="0.5" />
    <path d="M463.46 228.29H463.54" stroke="black" stroke-width="0.5" />
    <path d="M463.59 228.29H463.68" stroke="black" stroke-width="0.5" />
    <path d="M463.73 228.29H463.81" stroke="black" stroke-width="0.5" />
    <path d="M463.87 228.29H463.95" stroke="black" stroke-width="0.5" />
    <path d="M464 228.29H464.09" stroke="black" stroke-width="0.5" />
    <path d="M464.14 228.29H464.22" stroke="black" stroke-width="0.5" />
    <path d="M464.28 228.29H464.36" stroke="black" stroke-width="0.5" />
    <path d="M464.42 228.29H464.5" stroke="black" stroke-width="0.5" />
    <path d="M464.55 228.29H464.64" stroke="black" stroke-width="0.5" />
    <path d="M464.69 228.29H464.77" stroke="black" stroke-width="0.5" />
    <path d="M464.83 228.29H464.91" stroke="black" stroke-width="0.5" />
    <path d="M464.97 228.29H465.05" stroke="black" stroke-width="0.5" />
    <path d="M465.1 228.29H465.19" stroke="black" stroke-width="0.5" />
    <path d="M465.24 228.29H465.32" stroke="black" stroke-width="0.5" />
    <path d="M465.38 228.29H465.46" stroke="black" stroke-width="0.5" />
    <path d="M465.52 228.29H465.6" stroke="black" stroke-width="0.5" />
    <path d="M465.65 228.29H465.74" stroke="black" stroke-width="0.5" />
    <path d="M465.79 228.29H465.87" stroke="black" stroke-width="0.5" />
    <path d="M465.93 228.29H466.01" stroke="black" stroke-width="0.5" />
    <path d="M466.07 228.29H466.15" stroke="black" stroke-width="0.5" />
    <path d="M466.2 228.29H466.29" stroke="black" stroke-width="0.5" />
    <path d="M466.34 228.29H466.42" stroke="black" stroke-width="0.5" />
    <path d="M466.48 228.29H466.56" stroke="black" stroke-width="0.5" />
    <path d="M466.62 228.29H466.7" stroke="black" stroke-width="0.5" />
    <path d="M466.75 228.29H466.84" stroke="black" stroke-width="0.5" />
    <path d="M466.89 228.29H466.97" stroke="black" stroke-width="0.5" />
    <path d="M467.03 228.29H467.11" stroke="black" stroke-width="0.5" />
    <path d="M467.17 228.29H467.25" stroke="black" stroke-width="0.5" />
    <path d="M467.3 228.29H467.39" stroke="black" stroke-width="0.5" />
    <path d="M467.44 228.29H467.52" stroke="black" stroke-width="0.5" />
    <path d="M467.58 228.29H467.66" stroke="black" stroke-width="0.5" />
    <path d="M467.72 228.29H467.8" stroke="black" stroke-width="0.5" />
    <path d="M467.85 228.29H467.94" stroke="black" stroke-width="0.5" />
    <path d="M467.99 228.29H468.07" stroke="black" stroke-width="0.5" />
    <path d="M468.13 228.29H468.21" stroke="black" stroke-width="0.5" />
    <path d="M468.27 228.29H468.35" stroke="black" stroke-width="0.5" />
    <path d="M468.4 228.29H468.49" stroke="black" stroke-width="0.5" />
    <path d="M468.54 228.29H468.62" stroke="black" stroke-width="0.5" />
    <path d="M468.68 228.29H468.76" stroke="black" stroke-width="0.5" />
    <path d="M468.82 228.29H468.9" stroke="black" stroke-width="0.5" />
    <path d="M468.95 228.29H469.04" stroke="black" stroke-width="0.5" />
    <path d="M469.09 228.29H469.17" stroke="black" stroke-width="0.5" />
    <path d="M469.23 228.29H469.31" stroke="black" stroke-width="0.5" />
    <path d="M469.37 228.29H469.45" stroke="black" stroke-width="0.5" />
    <path d="M469.5 228.29H469.59" stroke="black" stroke-width="0.5" />
    <path d="M469.64 228.29H469.7" stroke="black" stroke-width="0.5" />
    <path d="M469.7 228.29V228.19" stroke="black" stroke-width="0.5" />
    <path d="M469.7 228.14V228.06" stroke="black" stroke-width="0.5" />
    <path d="M469.7 228V227.92" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.86V227.78" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.73V227.64" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.59V227.51" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.45V227.37" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.31V227.23" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.18V227.09" stroke="black" stroke-width="0.5" />
    <path d="M469.7 227.04V226.96" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.9V226.82" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.76V226.68" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.63V226.54" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.49V226.41" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.35V226.27" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.21V226.13" stroke="black" stroke-width="0.5" />
    <path d="M469.7 226.08V225.99" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.94V225.86" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.8V225.72" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.66V225.58" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.53V225.44" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.39V225.31" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.25V225.17" stroke="black" stroke-width="0.5" />
    <path d="M469.7 225.11V225.03" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.98V224.89" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.84V224.76" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.7V224.62" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.56V224.48" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.43V224.34" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.29V224.21" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.15V224.07" stroke="black" stroke-width="0.5" />
    <path d="M469.7 224.01V223.93" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.88V223.79" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.74V223.66" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.6V223.52" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.46V223.38" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.33V223.24" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.19V223.11" stroke="black" stroke-width="0.5" />
    <path d="M469.7 223.05V222.97" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.91V222.83" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.78V222.69" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.64V222.56" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.5V222.42" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.36V222.28" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.23V222.14" stroke="black" stroke-width="0.5" />
    <path d="M469.7 222.09V222.01" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.95V221.87" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.82V221.73" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.68V221.6" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.54V221.46" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.4V221.32" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.27V221.18" stroke="black" stroke-width="0.5" />
    <path d="M469.7 221.13V221.05" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.99V220.91" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.85V220.77" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.72V220.63" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.58V220.5" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.44V220.36" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.3V220.22" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.17V220.08" stroke="black" stroke-width="0.5" />
    <path d="M469.7 220.03V219.95" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.89V219.81" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.75V219.67" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.62V219.53" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.48V219.4" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.34V219.26" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.2V219.12" stroke="black" stroke-width="0.5" />
    <path d="M469.7 219.07V218.98" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.93V218.85" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.79V218.71" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.65V218.57" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.52V218.43" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.38V218.3" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.24V218.16" stroke="black" stroke-width="0.5" />
    <path d="M469.7 218.1V218.02" stroke="black" stroke-width="0.5" />
    <path d="M469.7 217.97V217.88" stroke="black" stroke-width="0.5" />
    <path d="M469.7 217.83V217.73" stroke="black" stroke-width="0.5" />
    <path d="M469.7 217.73H469.64" stroke="black" stroke-width="0.5" />
    <path d="M469.59 217.73H469.5" stroke="black" stroke-width="0.5" />
    <path d="M469.45 217.73H469.37" stroke="black" stroke-width="0.5" />
    <path d="M469.31 217.73H469.23" stroke="black" stroke-width="0.5" />
    <path d="M469.17 217.73H469.09" stroke="black" stroke-width="0.5" />
    <path d="M469.04 217.73H468.95" stroke="black" stroke-width="0.5" />
    <path d="M468.9 217.73H468.82" stroke="black" stroke-width="0.5" />
    <path d="M468.76 217.73H468.68" stroke="black" stroke-width="0.5" />
    <path d="M468.62 217.73H468.54" stroke="black" stroke-width="0.5" />
    <path d="M468.49 217.73H468.4" stroke="black" stroke-width="0.5" />
    <path d="M468.35 217.73H468.27" stroke="black" stroke-width="0.5" />
    <path d="M468.21 217.73H468.13" stroke="black" stroke-width="0.5" />
    <path d="M468.07 217.73H467.99" stroke="black" stroke-width="0.5" />
    <path d="M467.94 217.73H467.85" stroke="black" stroke-width="0.5" />
    <path d="M467.8 217.73H467.72" stroke="black" stroke-width="0.5" />
    <path d="M467.66 217.73H467.58" stroke="black" stroke-width="0.5" />
    <path d="M467.52 217.73H467.44" stroke="black" stroke-width="0.5" />
    <path d="M467.39 217.73H467.3" stroke="black" stroke-width="0.5" />
    <path d="M467.25 217.73H467.17" stroke="black" stroke-width="0.5" />
    <path d="M467.11 217.73H467.03" stroke="black" stroke-width="0.5" />
    <path d="M466.97 217.73H466.89" stroke="black" stroke-width="0.5" />
    <path d="M466.84 217.73H466.75" stroke="black" stroke-width="0.5" />
    <path d="M466.7 217.73H466.62" stroke="black" stroke-width="0.5" />
    <path d="M466.56 217.73H466.48" stroke="black" stroke-width="0.5" />
    <path d="M466.42 217.73H466.34" stroke="black" stroke-width="0.5" />
    <path d="M466.29 217.73H466.2" stroke="black" stroke-width="0.5" />
    <path d="M466.15 217.73H466.07" stroke="black" stroke-width="0.5" />
    <path d="M466.01 217.73H465.93" stroke="black" stroke-width="0.5" />
    <path d="M465.87 217.73H465.79" stroke="black" stroke-width="0.5" />
    <path d="M465.74 217.73H465.65" stroke="black" stroke-width="0.5" />
    <path d="M465.6 217.73H465.52" stroke="black" stroke-width="0.5" />
    <path d="M465.46 217.73H465.38" stroke="black" stroke-width="0.5" />
    <path d="M465.32 217.73H465.24" stroke="black" stroke-width="0.5" />
    <path d="M465.19 217.73H465.1" stroke="black" stroke-width="0.5" />
    <path d="M465.05 217.73H464.97" stroke="black" stroke-width="0.5" />
    <path d="M464.91 217.73H464.83" stroke="black" stroke-width="0.5" />
    <path d="M464.77 217.73H464.69" stroke="black" stroke-width="0.5" />
    <path d="M464.64 217.73H464.55" stroke="black" stroke-width="0.5" />
    <path d="M464.5 217.73H464.42" stroke="black" stroke-width="0.5" />
    <path d="M464.36 217.73H464.28" stroke="black" stroke-width="0.5" />
    <path d="M464.22 217.73H464.14" stroke="black" stroke-width="0.5" />
    <path d="M464.09 217.73H464" stroke="black" stroke-width="0.5" />
    <path d="M463.95 217.73H463.87" stroke="black" stroke-width="0.5" />
    <path d="M463.81 217.73H463.73" stroke="black" stroke-width="0.5" />
    <path d="M463.68 217.73H463.59" stroke="black" stroke-width="0.5" />
    <path d="M463.54 217.73H463.46" stroke="black" stroke-width="0.5" />
    <path d="M463.4 217.73H463.32" stroke="black" stroke-width="0.5" />
    <path d="M463.26 217.73H463.18" stroke="black" stroke-width="0.5" />
    <path d="M463.13 217.73H463.04" stroke="black" stroke-width="0.5" />
    <path d="M462.99 217.73H462.91" stroke="black" stroke-width="0.5" />
    <path d="M462.85 217.73H462.77" stroke="black" stroke-width="0.5" />
    <path d="M462.71 217.73H462.63" stroke="black" stroke-width="0.5" />
    <path d="M462.58 217.73H462.49" stroke="black" stroke-width="0.5" />
    <path d="M462.44 217.73H462.36" stroke="black" stroke-width="0.5" />
    <path d="M462.3 217.73H462.22" stroke="black" stroke-width="0.5" />
    <path d="M462.16 217.73H462.08" stroke="black" stroke-width="0.5" />
    <path d="M462.03 217.73H461.94" stroke="black" stroke-width="0.5" />
    <path d="M461.89 217.73H461.81" stroke="black" stroke-width="0.5" />
    <path d="M461.75 217.73H461.67" stroke="black" stroke-width="0.5" />
    <path d="M461.61 217.73H461.53" stroke="black" stroke-width="0.5" />
    <path d="M461.48 217.73H461.39" stroke="black" stroke-width="0.5" />
    <path d="M461.34 217.73H461.26" stroke="black" stroke-width="0.5" />
    <path d="M461.2 217.73H461.12" stroke="black" stroke-width="0.5" />
    <path d="M461.06 217.73H460.98" stroke="black" stroke-width="0.5" />
    <path d="M460.93 217.73H460.84" stroke="black" stroke-width="0.5" />
    <path d="M460.79 217.73H460.71" stroke="black" stroke-width="0.5" />
    <path d="M460.65 217.73H460.57" stroke="black" stroke-width="0.5" />
    <path d="M460.51 217.73H460.43" stroke="black" stroke-width="0.5" />
    <path d="M460.38 217.73H460.29" stroke="black" stroke-width="0.5" />
    <path d="M460.24 217.73H460.16" stroke="black" stroke-width="0.5" />
    <path d="M460.1 217.73H460.02" stroke="black" stroke-width="0.5" />
    <path d="M459.96 217.73H459.88" stroke="black" stroke-width="0.5" />
    <path d="M459.83 217.73H459.74" stroke="black" stroke-width="0.5" />
    <path d="M459.69 217.73H459.61" stroke="black" stroke-width="0.5" />
    <path d="M459.55 217.73H459.47" stroke="black" stroke-width="0.5" />
    <path d="M459.41 217.73H459.33" stroke="black" stroke-width="0.5" />
    <path d="M459.28 217.73H459.19" stroke="black" stroke-width="0.5" />
    <path d="M459.14 217.73H459.06" stroke="black" stroke-width="0.5" />
    <path d="M459 217.73H458.92" stroke="black" stroke-width="0.5" />
    <path d="M458.86 217.73H458.78" stroke="black" stroke-width="0.5" />
    <path d="M458.73 217.73H458.64" stroke="black" stroke-width="0.5" />
    <path d="M458.59 217.73H458.51" stroke="black" stroke-width="0.5" />
    <path d="M458.45 217.73H458.37" stroke="black" stroke-width="0.5" />
    <path d="M458.31 217.73H458.23" stroke="black" stroke-width="0.5" />
    <path d="M458.18 217.73H458.09" stroke="black" stroke-width="0.5" />
    <path d="M458.04 217.73H457.96" stroke="black" stroke-width="0.5" />
    <path d="M457.9 217.73H457.82" stroke="black" stroke-width="0.5" />
    <path d="M457.76 217.73H457.68" stroke="black" stroke-width="0.5" />
    <path d="M457.63 217.73H457.54" stroke="black" stroke-width="0.5" />
    <path d="M457.49 217.73H457.41" stroke="black" stroke-width="0.5" />
    <path d="M457.35 217.73H457.27" stroke="black" stroke-width="0.5" />
    <path d="M457.22 217.73H457.13" stroke="black" stroke-width="0.5" />
    <path d="M457.08 217.73H457" stroke="black" stroke-width="0.5" />
    <path d="M456.94 217.73H456.86" stroke="black" stroke-width="0.5" />
    <path d="M456.8 217.73H456.72" stroke="black" stroke-width="0.5" />
    <path d="M456.67 217.73H456.58" stroke="black" stroke-width="0.5" />
    <path d="M456.53 217.73H456.45" stroke="black" stroke-width="0.5" />
    <path d="M456.39 217.73H456.31" stroke="black" stroke-width="0.5" />
    <path d="M456.25 217.73H456.17" stroke="black" stroke-width="0.5" />
    <path d="M456.12 217.73H456.03" stroke="black" stroke-width="0.5" />
    <path d="M455.98 217.73H455.9" stroke="black" stroke-width="0.5" />
    <path d="M455.84 217.73H455.76" stroke="black" stroke-width="0.5" />
    <path d="M455.7 217.73H455.62" stroke="black" stroke-width="0.5" />
    <path d="M455.57 217.73H455.48" stroke="black" stroke-width="0.5" />
    <path d="M455.43 217.73H455.35" stroke="black" stroke-width="0.5" />
    <path d="M455.29 217.73H455.21" stroke="black" stroke-width="0.5" />
    <path d="M455.15 217.73H455.07" stroke="black" stroke-width="0.5" />
    <path d="M455.02 217.73H454.93" stroke="black" stroke-width="0.5" />
    <path d="M454.88 217.73H454.8" stroke="black" stroke-width="0.5" />
    <path d="M454.74 217.73H454.66" stroke="black" stroke-width="0.5" />
    <path d="M454.6 217.73H454.52" stroke="black" stroke-width="0.5" />
    <path d="M454.47 217.73H454.38" stroke="black" stroke-width="0.5" />
    <path d="M454.33 217.73H454.25" stroke="black" stroke-width="0.5" />
    <path d="M454.19 217.73H454.11" stroke="black" stroke-width="0.5" />
    <path d="M454.05 217.73H453.97" stroke="black" stroke-width="0.5" />
    <path d="M453.92 217.73H453.83" stroke="black" stroke-width="0.5" />
    <path d="M453.78 217.73H453.7" stroke="black" stroke-width="0.5" />
    <path d="M453.64 217.73H453.56" stroke="black" stroke-width="0.5" />
    <path d="M453.5 217.73H453.42" stroke="black" stroke-width="0.5" />
    <path d="M453.37 217.73H453.28" stroke="black" stroke-width="0.5" />
    <path d="M453.23 217.73H453.15" stroke="black" stroke-width="0.5" />
    <path d="M453.09 217.73H453.01" stroke="black" stroke-width="0.5" />
    <path d="M452.95 217.73H452.87" stroke="black" stroke-width="0.5" />
    <path d="M452.82 217.73H452.73" stroke="black" stroke-width="0.5" />
    <path d="M452.68 217.73H452.6" stroke="black" stroke-width="0.5" />
    <path d="M452.54 217.73H452.46" stroke="black" stroke-width="0.5" />
    <path d="M452.4 217.73H452.32" stroke="black" stroke-width="0.5" />
    <path d="M452.27 217.73H452.18" stroke="black" stroke-width="0.5" />
    <path d="M452.13 217.73H452.05" stroke="black" stroke-width="0.5" />
    <path d="M451.99 217.73H451.91" stroke="black" stroke-width="0.5" />
    <path d="M451.85 217.73H451.77" stroke="black" stroke-width="0.5" />
    <path d="M451.72 217.73H451.63" stroke="black" stroke-width="0.5" />
    <path d="M451.58 217.73H451.5" stroke="black" stroke-width="0.5" />
    <path d="M451.44 217.73H451.36" stroke="black" stroke-width="0.5" />
    <path d="M451.3 217.73H451.22" stroke="black" stroke-width="0.5" />
    <path d="M451.17 217.73H451.08" stroke="black" stroke-width="0.5" />
    <path d="M451.03 217.73H450.95" stroke="black" stroke-width="0.5" />
    <path d="M450.89 217.73H450.81" stroke="black" stroke-width="0.5" />
    <path d="M450.76 217.73H450.67" stroke="black" stroke-width="0.5" />
    <path d="M450.62 217.73H450.54" stroke="black" stroke-width="0.5" />
    <path d="M450.48 217.73H450.4" stroke="black" stroke-width="0.5" />
    <path d="M450.34 217.73H450.26" stroke="black" stroke-width="0.5" />
    <path d="M450.21 217.73H450.12" stroke="black" stroke-width="0.5" />
    <path d="M450.07 217.73H449.99" stroke="black" stroke-width="0.5" />
    <path d="M449.93 217.73H449.85" stroke="black" stroke-width="0.5" />
    <path d="M449.79 217.73H449.71" stroke="black" stroke-width="0.5" />
    <path d="M449.66 217.73H449.57" stroke="black" stroke-width="0.5" />
    <path d="M449.52 217.73H449.44" stroke="black" stroke-width="0.5" />
    <path d="M449.38 217.73H449.3" stroke="black" stroke-width="0.5" />
    <path d="M449.24 217.73H449.16" stroke="black" stroke-width="0.5" />
    <path d="M449.11 217.73H449.02" stroke="black" stroke-width="0.5" />
    <path d="M448.97 217.73H448.89" stroke="black" stroke-width="0.5" />
    <path d="M448.83 217.73H448.75" stroke="black" stroke-width="0.5" />
    <path d="M448.69 217.73H448.61" stroke="black" stroke-width="0.5" />
    <path d="M448.56 217.73H448.47" stroke="black" stroke-width="0.5" />
    <path d="M448.42 217.73H448.34" stroke="black" stroke-width="0.5" />
    <path d="M448.28 217.73H448.2" stroke="black" stroke-width="0.5" />
    <path d="M448.14 217.73H448.06" stroke="black" stroke-width="0.5" />
    <path d="M448.01 217.73H447.92" stroke="black" stroke-width="0.5" />
    <path d="M447.87 217.73H447.79" stroke="black" stroke-width="0.5" />
    <path d="M447.73 217.73H447.65" stroke="black" stroke-width="0.5" />
    <path d="M447.59 217.73H447.51" stroke="black" stroke-width="0.5" />
    <path d="M447.46 217.73H447.37" stroke="black" stroke-width="0.5" />
    <path d="M447.32 217.73H447.24" stroke="black" stroke-width="0.5" />
    <path d="M447.18 217.73H447.1" stroke="black" stroke-width="0.5" />
    <path d="M447.04 217.73H446.96" stroke="black" stroke-width="0.5" />
    <path d="M446.91 217.73H446.82" stroke="black" stroke-width="0.5" />
    <path d="M446.77 217.73H446.69" stroke="black" stroke-width="0.5" />
    <path d="M446.63 217.73H446.55" stroke="black" stroke-width="0.5" />
    <path d="M446.49 217.73H446.41" stroke="black" stroke-width="0.5" />
    <path d="M446.36 217.73H446.27" stroke="black" stroke-width="0.5" />
    <path d="M446.22 217.73H446.14" stroke="black" stroke-width="0.5" />
    <path d="M446.08 217.73H446" stroke="black" stroke-width="0.5" />
    <path d="M445.94 217.73H445.86" stroke="black" stroke-width="0.5" />
    <path d="M445.81 217.73H445.72" stroke="black" stroke-width="0.5" />
    <path d="M445.67 217.73H445.59" stroke="black" stroke-width="0.5" />
    <path d="M445.53 217.73H445.45" stroke="black" stroke-width="0.5" />
    <path d="M445.39 217.73H445.31" stroke="black" stroke-width="0.5" />
    <path d="M445.26 217.73H445.17" stroke="black" stroke-width="0.5" />
    <path d="M445.12 217.73H445.04" stroke="black" stroke-width="0.5" />
    <path d="M444.98 217.73H444.9" stroke="black" stroke-width="0.5" />
    <path d="M444.84 217.73H444.76" stroke="black" stroke-width="0.5" />
    <path d="M444.71 217.73H444.62" stroke="black" stroke-width="0.5" />
    <path d="M444.57 217.73H444.49" stroke="black" stroke-width="0.5" />
    <path d="M444.43 217.73H444.35" stroke="black" stroke-width="0.5" />
    <path d="M444.3 217.73H444.21" stroke="black" stroke-width="0.5" />
    <path d="M444.16 217.73H444.08" stroke="black" stroke-width="0.5" />
    <path d="M444.02 217.73H443.94" stroke="black" stroke-width="0.5" />
    <path d="M443.88 217.73H443.8" stroke="black" stroke-width="0.5" />
    <path d="M443.75 217.73H443.66" stroke="black" stroke-width="0.5" />
    <path d="M443.61 217.73H443.53" stroke="black" stroke-width="0.5" />
    <path d="M443.47 217.73H443.39" stroke="black" stroke-width="0.5" />
    <path d="M443.33 217.73H443.25" stroke="black" stroke-width="0.5" />
    <path d="M443.2 217.73H443.11" stroke="black" stroke-width="0.5" />
    <path d="M443.06 217.73H442.98" stroke="black" stroke-width="0.5" />
    <path d="M442.92 217.73H442.84" stroke="black" stroke-width="0.5" />
    <path d="M442.78 217.73H442.7" stroke="black" stroke-width="0.5" />
    <path d="M442.65 217.73H442.56" stroke="black" stroke-width="0.5" />
    <path d="M442.51 217.73H442.43" stroke="black" stroke-width="0.5" />
    <path d="M442.37 217.73H442.29" stroke="black" stroke-width="0.5" />
    <path d="M442.23 217.73H442.15" stroke="black" stroke-width="0.5" />
    <path d="M442.1 217.73H442.01" stroke="black" stroke-width="0.5" />
    <path d="M441.96 217.73H441.88" stroke="black" stroke-width="0.5" />
    <path d="M441.82 217.73H441.74" stroke="black" stroke-width="0.5" />
    <path d="M441.68 217.73H441.6" stroke="black" stroke-width="0.5" />
    <path d="M441.55 217.73H441.46" stroke="black" stroke-width="0.5" />
    <path d="M441.41 217.73H441.33" stroke="black" stroke-width="0.5" />
    <path d="M441.27 217.73H441.19" stroke="black" stroke-width="0.5" />
    <path d="M441.13 217.73H441.05" stroke="black" stroke-width="0.5" />
    <path d="M441 217.73H440.91" stroke="black" stroke-width="0.5" />
    <path d="M440.86 217.73H440.78" stroke="black" stroke-width="0.5" />
    <path d="M440.72 217.73H440.64" stroke="black" stroke-width="0.5" />
    <path d="M440.58 217.73H440.5" stroke="black" stroke-width="0.5" />
    <path d="M440.45 217.73H440.36" stroke="black" stroke-width="0.5" />
    <path d="M440.31 217.73H440.23" stroke="black" stroke-width="0.5" />
    <path d="M440.17 217.73H440.09" stroke="black" stroke-width="0.5" />
    <path d="M440.03 217.73H439.95" stroke="black" stroke-width="0.5" />
    <path d="M439.9 217.73H439.81" stroke="black" stroke-width="0.5" />
    <path d="M439.76 217.73H439.68" stroke="black" stroke-width="0.5" />
    <path d="M439.62 217.73H439.54" stroke="black" stroke-width="0.5" />
    <path d="M439.48 217.73H439.4" stroke="black" stroke-width="0.5" />
    <path d="M439.35 217.73H439.26" stroke="black" stroke-width="0.5" />
    <path d="M439.21 217.73H439.13" stroke="black" stroke-width="0.5" />
    <path d="M439.07 217.73H438.99" stroke="black" stroke-width="0.5" />
    <path d="M438.93 217.73H438.85" stroke="black" stroke-width="0.5" />
    <path d="M438.8 217.73H438.71" stroke="black" stroke-width="0.5" />
    <path d="M438.66 217.73H438.58" stroke="black" stroke-width="0.5" />
    <path d="M438.52 217.73H438.44" stroke="black" stroke-width="0.5" />
    <path d="M438.38 217.73H438.3" stroke="black" stroke-width="0.5" />
    <path d="M438.25 217.73H438.16" stroke="black" stroke-width="0.5" />
    <path d="M438.11 217.73H438.03" stroke="black" stroke-width="0.5" />
    <path d="M437.97 217.73H437.89" stroke="black" stroke-width="0.5" />
    <path d="M437.84 217.73H437.75" stroke="black" stroke-width="0.5" />
    <path d="M437.7 217.73H437.62" stroke="black" stroke-width="0.5" />
    <path d="M437.56 217.73H437.48" stroke="black" stroke-width="0.5" />
    <path d="M437.42 217.73H437.34" stroke="black" stroke-width="0.5" />
    <path d="M437.29 217.73H437.2" stroke="black" stroke-width="0.5" />
    <path d="M437.15 217.73H437.07" stroke="black" stroke-width="0.5" />
    <path d="M437.01 217.73H436.93" stroke="black" stroke-width="0.5" />
    <path d="M436.87 217.73H436.79" stroke="black" stroke-width="0.5" />
    <path d="M436.74 217.73H436.65" stroke="black" stroke-width="0.5" />
    <path d="M436.6 217.73H436.52" stroke="black" stroke-width="0.5" />
    <path d="M436.46 217.73H436.38" stroke="black" stroke-width="0.5" />
    <path d="M436.32 217.73H436.24" stroke="black" stroke-width="0.5" />
    <path d="M436.19 217.73H436.1" stroke="black" stroke-width="0.5" />
    <path d="M436.05 217.73H435.97" stroke="black" stroke-width="0.5" />
    <path d="M435.91 217.73H435.83" stroke="black" stroke-width="0.5" />
    <path d="M435.77 217.73H435.69" stroke="black" stroke-width="0.5" />
    <path d="M435.64 217.73H435.55" stroke="black" stroke-width="0.5" />
    <path d="M435.5 217.73H435.42" stroke="black" stroke-width="0.5" />
    <path d="M435.36 217.73H435.28" stroke="black" stroke-width="0.5" />
    <path d="M435.22 217.73H435.17" stroke="black" stroke-width="0.5" />
    <path d="M400.64 217.73V217.83" stroke="black" stroke-width="0.5" />
    <path d="M400.64 217.88V217.97" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.02V218.1" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.16V218.24" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.3V218.38" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.43V218.52" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.57V218.65" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.71V218.79" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.85V218.93" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.98V219.07" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.12V219.2" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.26V219.34" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.4V219.48" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.53V219.62" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.67V219.75" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.81V219.89" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.95V220.03" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.08V220.17" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.22V220.3" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.36V220.44" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.5V220.58" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.63V220.72" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.77V220.85" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.91V220.99" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.05V221.13" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.18V221.27" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.32V221.4" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.46V221.54" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.6V221.68" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.73V221.82" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.87V221.95" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.01V222.09" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.14V222.23" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.28V222.36" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.42V222.5" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.56V222.64" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.69V222.78" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.83V222.91" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.97V223.05" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.11V223.19" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.24V223.33" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.38V223.46" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.52V223.6" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.66V223.74" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.79V223.88" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.93V224.01" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.07V224.15" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.21V224.29" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.34V224.43" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.48V224.56" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.62V224.7" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.76V224.84" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.89V224.98" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.03V225.11" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.17V225.25" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.31V225.39" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.44V225.53" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.58V225.66" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.72V225.8" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.86V225.94" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.99V226.08" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.13V226.21" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.27V226.35" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.41V226.49" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.54V226.63" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.68V226.76" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.82V226.9" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.96V227.04" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.09V227.18" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.23V227.31" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.37V227.45" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.51V227.59" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.64V227.73" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.78V227.86" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.92V228" stroke="black" stroke-width="0.5" />
    <path d="M400.64 228.06V228.14" stroke="black" stroke-width="0.5" />
    <path d="M400.64 228.19V228.29" stroke="black" stroke-width="0.5" />
    <path d="M400.64 228.29H400.7" stroke="black" stroke-width="0.5" />
    <path d="M400.75 228.29H400.83" stroke="black" stroke-width="0.5" />
    <path d="M400.89 228.29H400.97" stroke="black" stroke-width="0.5" />
    <path d="M401.03 228.29H401.11" stroke="black" stroke-width="0.5" />
    <path d="M401.16 228.29H401.25" stroke="black" stroke-width="0.5" />
    <path d="M401.3 228.29H401.38" stroke="black" stroke-width="0.5" />
    <path d="M401.44 228.29H401.52" stroke="black" stroke-width="0.5" />
    <path d="M401.58 228.29H401.66" stroke="black" stroke-width="0.5" />
    <path d="M401.71 228.29H401.8" stroke="black" stroke-width="0.5" />
    <path d="M401.85 228.29H401.93" stroke="black" stroke-width="0.5" />
    <path d="M401.99 228.29H402.07" stroke="black" stroke-width="0.5" />
    <path d="M402.13 228.29H402.21" stroke="black" stroke-width="0.5" />
    <path d="M402.26 228.29H402.35" stroke="black" stroke-width="0.5" />
    <path d="M402.4 228.29H402.48" stroke="black" stroke-width="0.5" />
    <path d="M402.54 228.29H402.62" stroke="black" stroke-width="0.5" />
    <path d="M402.68 228.29H402.76" stroke="black" stroke-width="0.5" />
    <path d="M402.81 228.29H402.9" stroke="black" stroke-width="0.5" />
    <path d="M402.95 228.29H403.03" stroke="black" stroke-width="0.5" />
    <path d="M403.09 228.29H403.17" stroke="black" stroke-width="0.5" />
    <path d="M403.23 228.29H403.31" stroke="black" stroke-width="0.5" />
    <path d="M403.36 228.29H403.45" stroke="black" stroke-width="0.5" />
    <path d="M403.5 228.29H403.58" stroke="black" stroke-width="0.5" />
    <path d="M403.64 228.29H403.72" stroke="black" stroke-width="0.5" />
    <path d="M403.78 228.29H403.86" stroke="black" stroke-width="0.5" />
    <path d="M403.91 228.29H404" stroke="black" stroke-width="0.5" />
    <path d="M404.05 228.29H404.13" stroke="black" stroke-width="0.5" />
    <path d="M404.19 228.29H404.27" stroke="black" stroke-width="0.5" />
    <path d="M404.33 228.29H404.41" stroke="black" stroke-width="0.5" />
    <path d="M404.46 228.29H404.55" stroke="black" stroke-width="0.5" />
    <path d="M404.6 228.29H404.68" stroke="black" stroke-width="0.5" />
    <path d="M404.74 228.29H404.82" stroke="black" stroke-width="0.5" />
    <path d="M404.88 228.29H404.96" stroke="black" stroke-width="0.5" />
    <path d="M405.01 228.29H405.1" stroke="black" stroke-width="0.5" />
    <path d="M405.15 228.29H405.23" stroke="black" stroke-width="0.5" />
    <path d="M405.29 228.29H405.37" stroke="black" stroke-width="0.5" />
    <path d="M405.43 228.29H405.51" stroke="black" stroke-width="0.5" />
    <path d="M405.56 228.29H405.65" stroke="black" stroke-width="0.5" />
    <path d="M405.7 228.29H405.78" stroke="black" stroke-width="0.5" />
    <path d="M405.84 228.29H405.92" stroke="black" stroke-width="0.5" />
    <path d="M405.97 228.29H406.06" stroke="black" stroke-width="0.5" />
    <path d="M406.11 228.29H406.19" stroke="black" stroke-width="0.5" />
    <path d="M406.25 228.29H406.33" stroke="black" stroke-width="0.5" />
    <path d="M406.39 228.29H406.47" stroke="black" stroke-width="0.5" />
    <path d="M406.52 228.29H406.61" stroke="black" stroke-width="0.5" />
    <path d="M406.66 228.29H406.74" stroke="black" stroke-width="0.5" />
    <path d="M406.8 228.29H406.88" stroke="black" stroke-width="0.5" />
    <path d="M406.94 228.29H407.02" stroke="black" stroke-width="0.5" />
    <path d="M407.07 228.29H407.16" stroke="black" stroke-width="0.5" />
    <path d="M407.21 228.29H407.29" stroke="black" stroke-width="0.5" />
    <path d="M407.35 228.29H407.43" stroke="black" stroke-width="0.5" />
    <path d="M407.49 228.29H407.57" stroke="black" stroke-width="0.5" />
    <path d="M407.62 228.29H407.71" stroke="black" stroke-width="0.5" />
    <path d="M407.76 228.29H407.84" stroke="black" stroke-width="0.5" />
    <path d="M407.9 228.29H407.98" stroke="black" stroke-width="0.5" />
    <path d="M408.04 228.29H408.12" stroke="black" stroke-width="0.5" />
    <path d="M408.17 228.29H408.26" stroke="black" stroke-width="0.5" />
    <path d="M408.31 228.29H408.39" stroke="black" stroke-width="0.5" />
    <path d="M408.45 228.29H408.53" stroke="black" stroke-width="0.5" />
    <path d="M408.59 228.29H408.67" stroke="black" stroke-width="0.5" />
    <path d="M408.72 228.29H408.81" stroke="black" stroke-width="0.5" />
    <path d="M408.86 228.29H408.94" stroke="black" stroke-width="0.5" />
    <path d="M409 228.29H409.08" stroke="black" stroke-width="0.5" />
    <path d="M409.14 228.29H409.22" stroke="black" stroke-width="0.5" />
    <path d="M409.27 228.29H409.36" stroke="black" stroke-width="0.5" />
    <path d="M409.41 228.29H409.49" stroke="black" stroke-width="0.5" />
    <path d="M409.55 228.29H409.63" stroke="black" stroke-width="0.5" />
    <path d="M409.69 228.29H409.77" stroke="black" stroke-width="0.5" />
    <path d="M409.82 228.29H409.91" stroke="black" stroke-width="0.5" />
    <path d="M409.96 228.29H410.04" stroke="black" stroke-width="0.5" />
    <path d="M410.1 228.29H410.18" stroke="black" stroke-width="0.5" />
    <path d="M410.24 228.29H410.32" stroke="black" stroke-width="0.5" />
    <path d="M410.37 228.29H410.46" stroke="black" stroke-width="0.5" />
    <path d="M410.51 228.29H410.59" stroke="black" stroke-width="0.5" />
    <path d="M410.65 228.29H410.73" stroke="black" stroke-width="0.5" />
    <path d="M410.79 228.29H410.87" stroke="black" stroke-width="0.5" />
    <path d="M410.92 228.29H411.01" stroke="black" stroke-width="0.5" />
    <path d="M411.06 228.29H411.14" stroke="black" stroke-width="0.5" />
    <path d="M411.2 228.29H411.28" stroke="black" stroke-width="0.5" />
    <path d="M411.34 228.29H411.42" stroke="black" stroke-width="0.5" />
    <path d="M411.47 228.29H411.56" stroke="black" stroke-width="0.5" />
    <path d="M411.61 228.29H411.69" stroke="black" stroke-width="0.5" />
    <path d="M411.75 228.29H411.83" stroke="black" stroke-width="0.5" />
    <path d="M411.89 228.29H411.97" stroke="black" stroke-width="0.5" />
    <path d="M412.02 228.29H412.11" stroke="black" stroke-width="0.5" />
    <path d="M412.16 228.29H412.24" stroke="black" stroke-width="0.5" />
    <path d="M412.3 228.29H412.38" stroke="black" stroke-width="0.5" />
    <path d="M412.43 228.29H412.52" stroke="black" stroke-width="0.5" />
    <path d="M412.57 228.29H412.65" stroke="black" stroke-width="0.5" />
    <path d="M412.71 228.29H412.79" stroke="black" stroke-width="0.5" />
    <path d="M412.85 228.29H412.93" stroke="black" stroke-width="0.5" />
    <path d="M412.98 228.29H413.07" stroke="black" stroke-width="0.5" />
    <path d="M413.12 228.29H413.2" stroke="black" stroke-width="0.5" />
    <path d="M413.26 228.29H413.34" stroke="black" stroke-width="0.5" />
    <path d="M413.4 228.29H413.48" stroke="black" stroke-width="0.5" />
    <path d="M413.53 228.29H413.62" stroke="black" stroke-width="0.5" />
    <path d="M413.67 228.29H413.75" stroke="black" stroke-width="0.5" />
    <path d="M413.81 228.29H413.89" stroke="black" stroke-width="0.5" />
    <path d="M413.95 228.29H414.03" stroke="black" stroke-width="0.5" />
    <path d="M414.08 228.29H414.17" stroke="black" stroke-width="0.5" />
    <path d="M414.22 228.29H414.3" stroke="black" stroke-width="0.5" />
    <path d="M414.36 228.29H414.44" stroke="black" stroke-width="0.5" />
    <path d="M414.5 228.29H414.58" stroke="black" stroke-width="0.5" />
    <path d="M414.63 228.29H414.72" stroke="black" stroke-width="0.5" />
    <path d="M414.77 228.29H414.85" stroke="black" stroke-width="0.5" />
    <path d="M414.91 228.29H414.99" stroke="black" stroke-width="0.5" />
    <path d="M415.05 228.29H415.13" stroke="black" stroke-width="0.5" />
    <path d="M415.18 228.29H415.27" stroke="black" stroke-width="0.5" />
    <path d="M415.32 228.29H415.4" stroke="black" stroke-width="0.5" />
    <path d="M415.46 228.29H415.54" stroke="black" stroke-width="0.5" />
    <path d="M415.6 228.29H415.68" stroke="black" stroke-width="0.5" />
    <path d="M415.73 228.29H415.82" stroke="black" stroke-width="0.5" />
    <path d="M415.87 228.29H415.95" stroke="black" stroke-width="0.5" />
    <path d="M416.01 228.29H416.09" stroke="black" stroke-width="0.5" />
    <path d="M416.15 228.29H416.23" stroke="black" stroke-width="0.5" />
    <path d="M416.28 228.29H416.37" stroke="black" stroke-width="0.5" />
    <path d="M416.42 228.29H416.5" stroke="black" stroke-width="0.5" />
    <path d="M416.56 228.29H416.64" stroke="black" stroke-width="0.5" />
    <path d="M416.7 228.29H416.78" stroke="black" stroke-width="0.5" />
    <path d="M416.83 228.29H416.92" stroke="black" stroke-width="0.5" />
    <path d="M416.97 228.29H417.05" stroke="black" stroke-width="0.5" />
    <path d="M417.11 228.29H417.19" stroke="black" stroke-width="0.5" />
    <path d="M417.25 228.29H417.33" stroke="black" stroke-width="0.5" />
    <path d="M417.38 228.29H417.47" stroke="black" stroke-width="0.5" />
    <path d="M417.52 228.29H417.6" stroke="black" stroke-width="0.5" />
    <path d="M417.66 228.29H417.74" stroke="black" stroke-width="0.5" />
    <path d="M417.8 228.29H417.88" stroke="black" stroke-width="0.5" />
    <path d="M417.93 228.29H418.02" stroke="black" stroke-width="0.5" />
    <path d="M418.07 228.29H418.15" stroke="black" stroke-width="0.5" />
    <path d="M418.21 228.29H418.29" stroke="black" stroke-width="0.5" />
    <path d="M418.35 228.29H418.43" stroke="black" stroke-width="0.5" />
    <path d="M418.48 228.29H418.57" stroke="black" stroke-width="0.5" />
    <path d="M418.62 228.29H418.7" stroke="black" stroke-width="0.5" />
    <path d="M418.76 228.29H418.84" stroke="black" stroke-width="0.5" />
    <path d="M418.89 228.29H418.98" stroke="black" stroke-width="0.5" />
    <path d="M419.03 228.29H419.11" stroke="black" stroke-width="0.5" />
    <path d="M419.17 228.29H419.25" stroke="black" stroke-width="0.5" />
    <path d="M419.31 228.29H419.39" stroke="black" stroke-width="0.5" />
    <path d="M419.44 228.29H419.53" stroke="black" stroke-width="0.5" />
    <path d="M419.58 228.29H419.66" stroke="black" stroke-width="0.5" />
    <path d="M419.72 228.29H419.8" stroke="black" stroke-width="0.5" />
    <path d="M419.86 228.29H419.94" stroke="black" stroke-width="0.5" />
    <path d="M419.99 228.29H420.08" stroke="black" stroke-width="0.5" />
    <path d="M420.13 228.29H420.21" stroke="black" stroke-width="0.5" />
    <path d="M420.27 228.29H420.35" stroke="black" stroke-width="0.5" />
    <path d="M420.41 228.29H420.49" stroke="black" stroke-width="0.5" />
    <path d="M420.54 228.29H420.63" stroke="black" stroke-width="0.5" />
    <path d="M420.68 228.29H420.76" stroke="black" stroke-width="0.5" />
    <path d="M420.82 228.29H420.9" stroke="black" stroke-width="0.5" />
    <path d="M420.96 228.29H421.04" stroke="black" stroke-width="0.5" />
    <path d="M421.09 228.29H421.18" stroke="black" stroke-width="0.5" />
    <path d="M421.23 228.29H421.31" stroke="black" stroke-width="0.5" />
    <path d="M421.37 228.29H421.45" stroke="black" stroke-width="0.5" />
    <path d="M421.51 228.29H421.59" stroke="black" stroke-width="0.5" />
    <path d="M421.64 228.29H421.73" stroke="black" stroke-width="0.5" />
    <path d="M421.78 228.29H421.86" stroke="black" stroke-width="0.5" />
    <path d="M421.92 228.29H422" stroke="black" stroke-width="0.5" />
    <path d="M422.06 228.29H422.14" stroke="black" stroke-width="0.5" />
    <path d="M422.19 228.29H422.28" stroke="black" stroke-width="0.5" />
    <path d="M422.33 228.29H422.41" stroke="black" stroke-width="0.5" />
    <path d="M422.47 228.29H422.55" stroke="black" stroke-width="0.5" />
    <path d="M422.61 228.29H422.69" stroke="black" stroke-width="0.5" />
    <path d="M422.74 228.29H422.83" stroke="black" stroke-width="0.5" />
    <path d="M422.88 228.29H422.96" stroke="black" stroke-width="0.5" />
    <path d="M423.02 228.29H423.1" stroke="black" stroke-width="0.5" />
    <path d="M423.16 228.29H423.24" stroke="black" stroke-width="0.5" />
    <path d="M423.29 228.29H423.38" stroke="black" stroke-width="0.5" />
    <path d="M423.43 228.29H423.51" stroke="black" stroke-width="0.5" />
    <path d="M423.57 228.29H423.65" stroke="black" stroke-width="0.5" />
    <path d="M423.71 228.29H423.79" stroke="black" stroke-width="0.5" />
    <path d="M423.84 228.29H423.93" stroke="black" stroke-width="0.5" />
    <path d="M423.98 228.29H424.06" stroke="black" stroke-width="0.5" />
    <path d="M424.12 228.29H424.2" stroke="black" stroke-width="0.5" />
    <path d="M424.26 228.29H424.34" stroke="black" stroke-width="0.5" />
    <path d="M424.39 228.29H424.48" stroke="black" stroke-width="0.5" />
    <path d="M424.53 228.29H424.61" stroke="black" stroke-width="0.5" />
    <path d="M424.67 228.29H424.75" stroke="black" stroke-width="0.5" />
    <path d="M424.81 228.29H424.89" stroke="black" stroke-width="0.5" />
    <path d="M424.94 228.29H425.03" stroke="black" stroke-width="0.5" />
    <path d="M425.08 228.29H425.16" stroke="black" stroke-width="0.5" />
    <path d="M425.22 228.29H425.3" stroke="black" stroke-width="0.5" />
    <path d="M425.35 228.29H425.44" stroke="black" stroke-width="0.5" />
    <path d="M425.49 228.29H425.57" stroke="black" stroke-width="0.5" />
    <path d="M425.63 228.29H425.71" stroke="black" stroke-width="0.5" />
    <path d="M425.77 228.29H425.85" stroke="black" stroke-width="0.5" />
    <path d="M425.9 228.29H425.99" stroke="black" stroke-width="0.5" />
    <path d="M426.04 228.29H426.12" stroke="black" stroke-width="0.5" />
    <path d="M426.18 228.29H426.26" stroke="black" stroke-width="0.5" />
    <path d="M426.32 228.29H426.4" stroke="black" stroke-width="0.5" />
    <path d="M426.45 228.29H426.54" stroke="black" stroke-width="0.5" />
    <path d="M426.59 228.29H426.67" stroke="black" stroke-width="0.5" />
    <path d="M426.73 228.29H426.81" stroke="black" stroke-width="0.5" />
    <path d="M426.87 228.29H426.95" stroke="black" stroke-width="0.5" />
    <path d="M427 228.29H427.09" stroke="black" stroke-width="0.5" />
    <path d="M427.14 228.29H427.22" stroke="black" stroke-width="0.5" />
    <path d="M427.28 228.29H427.36" stroke="black" stroke-width="0.5" />
    <path d="M427.42 228.29H427.5" stroke="black" stroke-width="0.5" />
    <path d="M427.55 228.29H427.64" stroke="black" stroke-width="0.5" />
    <path d="M427.69 228.29H427.77" stroke="black" stroke-width="0.5" />
    <path d="M427.83 228.29H427.91" stroke="black" stroke-width="0.5" />
    <path d="M427.97 228.29H428.05" stroke="black" stroke-width="0.5" />
    <path d="M428.1 228.29H428.19" stroke="black" stroke-width="0.5" />
    <path d="M428.24 228.29H428.32" stroke="black" stroke-width="0.5" />
    <path d="M428.38 228.29H428.46" stroke="black" stroke-width="0.5" />
    <path d="M428.52 228.29H428.6" stroke="black" stroke-width="0.5" />
    <path d="M428.65 228.29H428.74" stroke="black" stroke-width="0.5" />
    <path d="M428.79 228.29H428.87" stroke="black" stroke-width="0.5" />
    <path d="M428.93 228.29H429.01" stroke="black" stroke-width="0.5" />
    <path d="M429.07 228.29H429.15" stroke="black" stroke-width="0.5" />
    <path d="M429.2 228.29H429.29" stroke="black" stroke-width="0.5" />
    <path d="M429.34 228.29H429.42" stroke="black" stroke-width="0.5" />
    <path d="M429.48 228.29H429.56" stroke="black" stroke-width="0.5" />
    <path d="M429.62 228.29H429.7" stroke="black" stroke-width="0.5" />
    <path d="M429.75 228.29H429.84" stroke="black" stroke-width="0.5" />
    <path d="M429.89 228.29H429.97" stroke="black" stroke-width="0.5" />
    <path d="M430.03 228.29H430.11" stroke="black" stroke-width="0.5" />
    <path d="M430.17 228.29H430.25" stroke="black" stroke-width="0.5" />
    <path d="M430.3 228.29H430.39" stroke="black" stroke-width="0.5" />
    <path d="M430.44 228.29H430.52" stroke="black" stroke-width="0.5" />
    <path d="M430.58 228.29H430.66" stroke="black" stroke-width="0.5" />
    <path d="M430.72 228.29H430.8" stroke="black" stroke-width="0.5" />
    <path d="M430.85 228.29H430.94" stroke="black" stroke-width="0.5" />
    <path d="M430.99 228.29H431.07" stroke="black" stroke-width="0.5" />
    <path d="M431.13 228.29H431.21" stroke="black" stroke-width="0.5" />
    <path d="M431.27 228.29H431.35" stroke="black" stroke-width="0.5" />
    <path d="M431.4 228.29H431.49" stroke="black" stroke-width="0.5" />
    <path d="M431.54 228.29H431.62" stroke="black" stroke-width="0.5" />
    <path d="M431.68 228.29H431.76" stroke="black" stroke-width="0.5" />
    <path d="M431.81 228.29H431.9" stroke="black" stroke-width="0.5" />
    <path d="M431.95 228.29H432.03" stroke="black" stroke-width="0.5" />
    <path d="M432.09 228.29H432.17" stroke="black" stroke-width="0.5" />
    <path d="M432.23 228.29H432.31" stroke="black" stroke-width="0.5" />
    <path d="M432.36 228.29H432.45" stroke="black" stroke-width="0.5" />
    <path d="M432.5 228.29H432.58" stroke="black" stroke-width="0.5" />
    <path d="M432.64 228.29H432.72" stroke="black" stroke-width="0.5" />
    <path d="M432.78 228.29H432.86" stroke="black" stroke-width="0.5" />
    <path d="M432.91 228.29H433" stroke="black" stroke-width="0.5" />
    <path d="M433.05 228.29H433.13" stroke="black" stroke-width="0.5" />
    <path d="M433.19 228.29H433.27" stroke="black" stroke-width="0.5" />
    <path d="M433.33 228.29H433.41" stroke="black" stroke-width="0.5" />
    <path d="M433.46 228.29H433.55" stroke="black" stroke-width="0.5" />
    <path d="M433.6 228.29H433.68" stroke="black" stroke-width="0.5" />
    <path d="M433.74 228.29H433.82" stroke="black" stroke-width="0.5" />
    <path d="M433.88 228.29H433.96" stroke="black" stroke-width="0.5" />
    <path d="M434.01 228.29H434.1" stroke="black" stroke-width="0.5" />
    <path d="M434.15 228.29H434.23" stroke="black" stroke-width="0.5" />
    <path d="M434.29 228.29H434.37" stroke="black" stroke-width="0.5" />
    <path d="M434.43 228.29H434.51" stroke="black" stroke-width="0.5" />
    <path d="M434.56 228.29H434.65" stroke="black" stroke-width="0.5" />
    <path d="M434.7 228.29H434.78" stroke="black" stroke-width="0.5" />
    <path d="M434.84 228.29H434.92" stroke="black" stroke-width="0.5" />
    <path d="M434.98 228.29H435.06" stroke="black" stroke-width="0.5" />
    <path d="M435.11 228.29H435.17" stroke="black" stroke-width="0.5" />
    <path d="M435.17 228.29V228.19" stroke="black" stroke-width="0.5" />
    <path d="M435.17 228.14V228.06" stroke="black" stroke-width="0.5" />
    <path d="M435.17 228V227.92" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.86V227.78" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.73V227.64" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.59V227.51" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.45V227.37" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.31V227.23" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.18V227.09" stroke="black" stroke-width="0.5" />
    <path d="M435.17 227.04V226.96" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.9V226.82" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.76V226.68" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.63V226.54" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.49V226.41" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.35V226.27" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.21V226.13" stroke="black" stroke-width="0.5" />
    <path d="M435.17 226.08V225.99" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.94V225.86" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.8V225.72" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.66V225.58" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.53V225.44" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.39V225.31" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.25V225.17" stroke="black" stroke-width="0.5" />
    <path d="M435.17 225.11V225.03" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.98V224.89" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.84V224.76" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.7V224.62" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.56V224.48" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.43V224.34" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.29V224.21" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.15V224.07" stroke="black" stroke-width="0.5" />
    <path d="M435.17 224.01V223.93" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.88V223.79" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.74V223.66" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.6V223.52" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.46V223.38" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.33V223.24" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.19V223.11" stroke="black" stroke-width="0.5" />
    <path d="M435.17 223.05V222.97" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.91V222.83" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.78V222.69" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.64V222.56" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.5V222.42" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.36V222.28" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.23V222.14" stroke="black" stroke-width="0.5" />
    <path d="M435.17 222.09V222.01" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.95V221.87" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.82V221.73" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.68V221.6" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.54V221.46" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.4V221.32" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.27V221.18" stroke="black" stroke-width="0.5" />
    <path d="M435.17 221.13V221.05" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.99V220.91" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.85V220.77" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.72V220.63" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.58V220.5" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.44V220.36" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.3V220.22" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.17V220.08" stroke="black" stroke-width="0.5" />
    <path d="M435.17 220.03V219.95" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.89V219.81" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.75V219.67" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.62V219.53" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.48V219.4" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.34V219.26" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.2V219.12" stroke="black" stroke-width="0.5" />
    <path d="M435.17 219.07V218.98" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.93V218.85" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.79V218.71" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.65V218.57" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.52V218.43" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.38V218.3" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.24V218.16" stroke="black" stroke-width="0.5" />
    <path d="M435.17 218.1V218.02" stroke="black" stroke-width="0.5" />
    <path d="M435.17 217.97V217.88" stroke="black" stroke-width="0.5" />
    <path d="M435.17 217.83V217.73" stroke="black" stroke-width="0.5" />
    <path d="M435.17 217.73H435.11" stroke="black" stroke-width="0.5" />
    <path d="M435.06 217.73H434.98" stroke="black" stroke-width="0.5" />
    <path d="M434.92 217.73H434.84" stroke="black" stroke-width="0.5" />
    <path d="M434.78 217.73H434.7" stroke="black" stroke-width="0.5" />
    <path d="M434.65 217.73H434.56" stroke="black" stroke-width="0.5" />
    <path d="M434.51 217.73H434.43" stroke="black" stroke-width="0.5" />
    <path d="M434.37 217.73H434.29" stroke="black" stroke-width="0.5" />
    <path d="M434.23 217.73H434.15" stroke="black" stroke-width="0.5" />
    <path d="M434.1 217.73H434.01" stroke="black" stroke-width="0.5" />
    <path d="M433.96 217.73H433.88" stroke="black" stroke-width="0.5" />
    <path d="M433.82 217.73H433.74" stroke="black" stroke-width="0.5" />
    <path d="M433.68 217.73H433.6" stroke="black" stroke-width="0.5" />
    <path d="M433.55 217.73H433.46" stroke="black" stroke-width="0.5" />
    <path d="M433.41 217.73H433.33" stroke="black" stroke-width="0.5" />
    <path d="M433.27 217.73H433.19" stroke="black" stroke-width="0.5" />
    <path d="M433.13 217.73H433.05" stroke="black" stroke-width="0.5" />
    <path d="M433 217.73H432.91" stroke="black" stroke-width="0.5" />
    <path d="M432.86 217.73H432.78" stroke="black" stroke-width="0.5" />
    <path d="M432.72 217.73H432.64" stroke="black" stroke-width="0.5" />
    <path d="M432.58 217.73H432.5" stroke="black" stroke-width="0.5" />
    <path d="M432.45 217.73H432.36" stroke="black" stroke-width="0.5" />
    <path d="M432.31 217.73H432.23" stroke="black" stroke-width="0.5" />
    <path d="M432.17 217.73H432.09" stroke="black" stroke-width="0.5" />
    <path d="M432.03 217.73H431.95" stroke="black" stroke-width="0.5" />
    <path d="M431.9 217.73H431.81" stroke="black" stroke-width="0.5" />
    <path d="M431.76 217.73H431.68" stroke="black" stroke-width="0.5" />
    <path d="M431.62 217.73H431.54" stroke="black" stroke-width="0.5" />
    <path d="M431.49 217.73H431.4" stroke="black" stroke-width="0.5" />
    <path d="M431.35 217.73H431.27" stroke="black" stroke-width="0.5" />
    <path d="M431.21 217.73H431.13" stroke="black" stroke-width="0.5" />
    <path d="M431.07 217.73H430.99" stroke="black" stroke-width="0.5" />
    <path d="M430.94 217.73H430.85" stroke="black" stroke-width="0.5" />
    <path d="M430.8 217.73H430.72" stroke="black" stroke-width="0.5" />
    <path d="M430.66 217.73H430.58" stroke="black" stroke-width="0.5" />
    <path d="M430.52 217.73H430.44" stroke="black" stroke-width="0.5" />
    <path d="M430.39 217.73H430.3" stroke="black" stroke-width="0.5" />
    <path d="M430.25 217.73H430.17" stroke="black" stroke-width="0.5" />
    <path d="M430.11 217.73H430.03" stroke="black" stroke-width="0.5" />
    <path d="M429.97 217.73H429.89" stroke="black" stroke-width="0.5" />
    <path d="M429.84 217.73H429.75" stroke="black" stroke-width="0.5" />
    <path d="M429.7 217.73H429.62" stroke="black" stroke-width="0.5" />
    <path d="M429.56 217.73H429.48" stroke="black" stroke-width="0.5" />
    <path d="M429.42 217.73H429.34" stroke="black" stroke-width="0.5" />
    <path d="M429.29 217.73H429.2" stroke="black" stroke-width="0.5" />
    <path d="M429.15 217.73H429.07" stroke="black" stroke-width="0.5" />
    <path d="M429.01 217.73H428.93" stroke="black" stroke-width="0.5" />
    <path d="M428.87 217.73H428.79" stroke="black" stroke-width="0.5" />
    <path d="M428.74 217.73H428.65" stroke="black" stroke-width="0.5" />
    <path d="M428.6 217.73H428.52" stroke="black" stroke-width="0.5" />
    <path d="M428.46 217.73H428.38" stroke="black" stroke-width="0.5" />
    <path d="M428.32 217.73H428.24" stroke="black" stroke-width="0.5" />
    <path d="M428.19 217.73H428.1" stroke="black" stroke-width="0.5" />
    <path d="M428.05 217.73H427.97" stroke="black" stroke-width="0.5" />
    <path d="M427.91 217.73H427.83" stroke="black" stroke-width="0.5" />
    <path d="M427.77 217.73H427.69" stroke="black" stroke-width="0.5" />
    <path d="M427.64 217.73H427.55" stroke="black" stroke-width="0.5" />
    <path d="M427.5 217.73H427.42" stroke="black" stroke-width="0.5" />
    <path d="M427.36 217.73H427.28" stroke="black" stroke-width="0.5" />
    <path d="M427.22 217.73H427.14" stroke="black" stroke-width="0.5" />
    <path d="M427.09 217.73H427" stroke="black" stroke-width="0.5" />
    <path d="M426.95 217.73H426.87" stroke="black" stroke-width="0.5" />
    <path d="M426.81 217.73H426.73" stroke="black" stroke-width="0.5" />
    <path d="M426.67 217.73H426.59" stroke="black" stroke-width="0.5" />
    <path d="M426.54 217.73H426.45" stroke="black" stroke-width="0.5" />
    <path d="M426.4 217.73H426.32" stroke="black" stroke-width="0.5" />
    <path d="M426.26 217.73H426.18" stroke="black" stroke-width="0.5" />
    <path d="M426.12 217.73H426.04" stroke="black" stroke-width="0.5" />
    <path d="M425.99 217.73H425.9" stroke="black" stroke-width="0.5" />
    <path d="M425.85 217.73H425.77" stroke="black" stroke-width="0.5" />
    <path d="M425.71 217.73H425.63" stroke="black" stroke-width="0.5" />
    <path d="M425.57 217.73H425.49" stroke="black" stroke-width="0.5" />
    <path d="M425.44 217.73H425.35" stroke="black" stroke-width="0.5" />
    <path d="M425.3 217.73H425.22" stroke="black" stroke-width="0.5" />
    <path d="M425.16 217.73H425.08" stroke="black" stroke-width="0.5" />
    <path d="M425.03 217.73H424.94" stroke="black" stroke-width="0.5" />
    <path d="M424.89 217.73H424.81" stroke="black" stroke-width="0.5" />
    <path d="M424.75 217.73H424.67" stroke="black" stroke-width="0.5" />
    <path d="M424.61 217.73H424.53" stroke="black" stroke-width="0.5" />
    <path d="M424.48 217.73H424.39" stroke="black" stroke-width="0.5" />
    <path d="M424.34 217.73H424.26" stroke="black" stroke-width="0.5" />
    <path d="M424.2 217.73H424.12" stroke="black" stroke-width="0.5" />
    <path d="M424.06 217.73H423.98" stroke="black" stroke-width="0.5" />
    <path d="M423.93 217.73H423.84" stroke="black" stroke-width="0.5" />
    <path d="M423.79 217.73H423.71" stroke="black" stroke-width="0.5" />
    <path d="M423.65 217.73H423.57" stroke="black" stroke-width="0.5" />
    <path d="M423.51 217.73H423.43" stroke="black" stroke-width="0.5" />
    <path d="M423.38 217.73H423.29" stroke="black" stroke-width="0.5" />
    <path d="M423.24 217.73H423.16" stroke="black" stroke-width="0.5" />
    <path d="M423.1 217.73H423.02" stroke="black" stroke-width="0.5" />
    <path d="M422.96 217.73H422.88" stroke="black" stroke-width="0.5" />
    <path d="M422.83 217.73H422.74" stroke="black" stroke-width="0.5" />
    <path d="M422.69 217.73H422.61" stroke="black" stroke-width="0.5" />
    <path d="M422.55 217.73H422.47" stroke="black" stroke-width="0.5" />
    <path d="M422.41 217.73H422.33" stroke="black" stroke-width="0.5" />
    <path d="M422.28 217.73H422.19" stroke="black" stroke-width="0.5" />
    <path d="M422.14 217.73H422.06" stroke="black" stroke-width="0.5" />
    <path d="M422 217.73H421.92" stroke="black" stroke-width="0.5" />
    <path d="M421.86 217.73H421.78" stroke="black" stroke-width="0.5" />
    <path d="M421.73 217.73H421.64" stroke="black" stroke-width="0.5" />
    <path d="M421.59 217.73H421.51" stroke="black" stroke-width="0.5" />
    <path d="M421.45 217.73H421.37" stroke="black" stroke-width="0.5" />
    <path d="M421.31 217.73H421.23" stroke="black" stroke-width="0.5" />
    <path d="M421.18 217.73H421.09" stroke="black" stroke-width="0.5" />
    <path d="M421.04 217.73H420.96" stroke="black" stroke-width="0.5" />
    <path d="M420.9 217.73H420.82" stroke="black" stroke-width="0.5" />
    <path d="M420.76 217.73H420.68" stroke="black" stroke-width="0.5" />
    <path d="M420.63 217.73H420.54" stroke="black" stroke-width="0.5" />
    <path d="M420.49 217.73H420.41" stroke="black" stroke-width="0.5" />
    <path d="M420.35 217.73H420.27" stroke="black" stroke-width="0.5" />
    <path d="M420.21 217.73H420.13" stroke="black" stroke-width="0.5" />
    <path d="M420.08 217.73H419.99" stroke="black" stroke-width="0.5" />
    <path d="M419.94 217.73H419.86" stroke="black" stroke-width="0.5" />
    <path d="M419.8 217.73H419.72" stroke="black" stroke-width="0.5" />
    <path d="M419.66 217.73H419.58" stroke="black" stroke-width="0.5" />
    <path d="M419.53 217.73H419.44" stroke="black" stroke-width="0.5" />
    <path d="M419.39 217.73H419.31" stroke="black" stroke-width="0.5" />
    <path d="M419.25 217.73H419.17" stroke="black" stroke-width="0.5" />
    <path d="M419.11 217.73H419.03" stroke="black" stroke-width="0.5" />
    <path d="M418.98 217.73H418.89" stroke="black" stroke-width="0.5" />
    <path d="M418.84 217.73H418.76" stroke="black" stroke-width="0.5" />
    <path d="M418.7 217.73H418.62" stroke="black" stroke-width="0.5" />
    <path d="M418.57 217.73H418.48" stroke="black" stroke-width="0.5" />
    <path d="M418.43 217.73H418.35" stroke="black" stroke-width="0.5" />
    <path d="M418.29 217.73H418.21" stroke="black" stroke-width="0.5" />
    <path d="M418.15 217.73H418.07" stroke="black" stroke-width="0.5" />
    <path d="M418.02 217.73H417.93" stroke="black" stroke-width="0.5" />
    <path d="M417.88 217.73H417.8" stroke="black" stroke-width="0.5" />
    <path d="M417.74 217.73H417.66" stroke="black" stroke-width="0.5" />
    <path d="M417.6 217.73H417.52" stroke="black" stroke-width="0.5" />
    <path d="M417.47 217.73H417.38" stroke="black" stroke-width="0.5" />
    <path d="M417.33 217.73H417.25" stroke="black" stroke-width="0.5" />
    <path d="M417.19 217.73H417.11" stroke="black" stroke-width="0.5" />
    <path d="M417.05 217.73H416.97" stroke="black" stroke-width="0.5" />
    <path d="M416.92 217.73H416.83" stroke="black" stroke-width="0.5" />
    <path d="M416.78 217.73H416.7" stroke="black" stroke-width="0.5" />
    <path d="M416.64 217.73H416.56" stroke="black" stroke-width="0.5" />
    <path d="M416.5 217.73H416.42" stroke="black" stroke-width="0.5" />
    <path d="M416.37 217.73H416.28" stroke="black" stroke-width="0.5" />
    <path d="M416.23 217.73H416.15" stroke="black" stroke-width="0.5" />
    <path d="M416.09 217.73H416.01" stroke="black" stroke-width="0.5" />
    <path d="M415.95 217.73H415.87" stroke="black" stroke-width="0.5" />
    <path d="M415.82 217.73H415.73" stroke="black" stroke-width="0.5" />
    <path d="M415.68 217.73H415.6" stroke="black" stroke-width="0.5" />
    <path d="M415.54 217.73H415.46" stroke="black" stroke-width="0.5" />
    <path d="M415.4 217.73H415.32" stroke="black" stroke-width="0.5" />
    <path d="M415.27 217.73H415.18" stroke="black" stroke-width="0.5" />
    <path d="M415.13 217.73H415.05" stroke="black" stroke-width="0.5" />
    <path d="M414.99 217.73H414.91" stroke="black" stroke-width="0.5" />
    <path d="M414.85 217.73H414.77" stroke="black" stroke-width="0.5" />
    <path d="M414.72 217.73H414.63" stroke="black" stroke-width="0.5" />
    <path d="M414.58 217.73H414.5" stroke="black" stroke-width="0.5" />
    <path d="M414.44 217.73H414.36" stroke="black" stroke-width="0.5" />
    <path d="M414.3 217.73H414.22" stroke="black" stroke-width="0.5" />
    <path d="M414.17 217.73H414.08" stroke="black" stroke-width="0.5" />
    <path d="M414.03 217.73H413.95" stroke="black" stroke-width="0.5" />
    <path d="M413.89 217.73H413.81" stroke="black" stroke-width="0.5" />
    <path d="M413.75 217.73H413.67" stroke="black" stroke-width="0.5" />
    <path d="M413.62 217.73H413.53" stroke="black" stroke-width="0.5" />
    <path d="M413.48 217.73H413.4" stroke="black" stroke-width="0.5" />
    <path d="M413.34 217.73H413.26" stroke="black" stroke-width="0.5" />
    <path d="M413.2 217.73H413.12" stroke="black" stroke-width="0.5" />
    <path d="M413.07 217.73H412.98" stroke="black" stroke-width="0.5" />
    <path d="M412.93 217.73H412.85" stroke="black" stroke-width="0.5" />
    <path d="M412.79 217.73H412.71" stroke="black" stroke-width="0.5" />
    <path d="M412.65 217.73H412.57" stroke="black" stroke-width="0.5" />
    <path d="M412.52 217.73H412.43" stroke="black" stroke-width="0.5" />
    <path d="M412.38 217.73H412.3" stroke="black" stroke-width="0.5" />
    <path d="M412.24 217.73H412.16" stroke="black" stroke-width="0.5" />
    <path d="M412.11 217.73H412.02" stroke="black" stroke-width="0.5" />
    <path d="M411.97 217.73H411.89" stroke="black" stroke-width="0.5" />
    <path d="M411.83 217.73H411.75" stroke="black" stroke-width="0.5" />
    <path d="M411.69 217.73H411.61" stroke="black" stroke-width="0.5" />
    <path d="M411.56 217.73H411.47" stroke="black" stroke-width="0.5" />
    <path d="M411.42 217.73H411.34" stroke="black" stroke-width="0.5" />
    <path d="M411.28 217.73H411.2" stroke="black" stroke-width="0.5" />
    <path d="M411.14 217.73H411.06" stroke="black" stroke-width="0.5" />
    <path d="M411.01 217.73H410.92" stroke="black" stroke-width="0.5" />
    <path d="M410.87 217.73H410.79" stroke="black" stroke-width="0.5" />
    <path d="M410.73 217.73H410.65" stroke="black" stroke-width="0.5" />
    <path d="M410.59 217.73H410.51" stroke="black" stroke-width="0.5" />
    <path d="M410.46 217.73H410.37" stroke="black" stroke-width="0.5" />
    <path d="M410.32 217.73H410.24" stroke="black" stroke-width="0.5" />
    <path d="M410.18 217.73H410.1" stroke="black" stroke-width="0.5" />
    <path d="M410.04 217.73H409.96" stroke="black" stroke-width="0.5" />
    <path d="M409.91 217.73H409.82" stroke="black" stroke-width="0.5" />
    <path d="M409.77 217.73H409.69" stroke="black" stroke-width="0.5" />
    <path d="M409.63 217.73H409.55" stroke="black" stroke-width="0.5" />
    <path d="M409.49 217.73H409.41" stroke="black" stroke-width="0.5" />
    <path d="M409.36 217.73H409.27" stroke="black" stroke-width="0.5" />
    <path d="M409.22 217.73H409.14" stroke="black" stroke-width="0.5" />
    <path d="M409.08 217.73H409" stroke="black" stroke-width="0.5" />
    <path d="M408.94 217.73H408.86" stroke="black" stroke-width="0.5" />
    <path d="M408.81 217.73H408.72" stroke="black" stroke-width="0.5" />
    <path d="M408.67 217.73H408.59" stroke="black" stroke-width="0.5" />
    <path d="M408.53 217.73H408.45" stroke="black" stroke-width="0.5" />
    <path d="M408.39 217.73H408.31" stroke="black" stroke-width="0.5" />
    <path d="M408.26 217.73H408.17" stroke="black" stroke-width="0.5" />
    <path d="M408.12 217.73H408.04" stroke="black" stroke-width="0.5" />
    <path d="M407.98 217.73H407.9" stroke="black" stroke-width="0.5" />
    <path d="M407.84 217.73H407.76" stroke="black" stroke-width="0.5" />
    <path d="M407.71 217.73H407.62" stroke="black" stroke-width="0.5" />
    <path d="M407.57 217.73H407.49" stroke="black" stroke-width="0.5" />
    <path d="M407.43 217.73H407.35" stroke="black" stroke-width="0.5" />
    <path d="M407.29 217.73H407.21" stroke="black" stroke-width="0.5" />
    <path d="M407.16 217.73H407.07" stroke="black" stroke-width="0.5" />
    <path d="M407.02 217.73H406.94" stroke="black" stroke-width="0.5" />
    <path d="M406.88 217.73H406.8" stroke="black" stroke-width="0.5" />
    <path d="M406.74 217.73H406.66" stroke="black" stroke-width="0.5" />
    <path d="M406.61 217.73H406.52" stroke="black" stroke-width="0.5" />
    <path d="M406.47 217.73H406.39" stroke="black" stroke-width="0.5" />
    <path d="M406.33 217.73H406.25" stroke="black" stroke-width="0.5" />
    <path d="M406.19 217.73H406.11" stroke="black" stroke-width="0.5" />
    <path d="M406.06 217.73H405.97" stroke="black" stroke-width="0.5" />
    <path d="M405.92 217.73H405.84" stroke="black" stroke-width="0.5" />
    <path d="M405.78 217.73H405.7" stroke="black" stroke-width="0.5" />
    <path d="M405.65 217.73H405.56" stroke="black" stroke-width="0.5" />
    <path d="M405.51 217.73H405.43" stroke="black" stroke-width="0.5" />
    <path d="M405.37 217.73H405.29" stroke="black" stroke-width="0.5" />
    <path d="M405.23 217.73H405.15" stroke="black" stroke-width="0.5" />
    <path d="M405.1 217.73H405.01" stroke="black" stroke-width="0.5" />
    <path d="M404.96 217.73H404.88" stroke="black" stroke-width="0.5" />
    <path d="M404.82 217.73H404.74" stroke="black" stroke-width="0.5" />
    <path d="M404.68 217.73H404.6" stroke="black" stroke-width="0.5" />
    <path d="M404.55 217.73H404.46" stroke="black" stroke-width="0.5" />
    <path d="M404.41 217.73H404.33" stroke="black" stroke-width="0.5" />
    <path d="M404.27 217.73H404.19" stroke="black" stroke-width="0.5" />
    <path d="M404.13 217.73H404.05" stroke="black" stroke-width="0.5" />
    <path d="M404 217.73H403.91" stroke="black" stroke-width="0.5" />
    <path d="M403.86 217.73H403.78" stroke="black" stroke-width="0.5" />
    <path d="M403.72 217.73H403.64" stroke="black" stroke-width="0.5" />
    <path d="M403.58 217.73H403.5" stroke="black" stroke-width="0.5" />
    <path d="M403.45 217.73H403.36" stroke="black" stroke-width="0.5" />
    <path d="M403.31 217.73H403.23" stroke="black" stroke-width="0.5" />
    <path d="M403.17 217.73H403.09" stroke="black" stroke-width="0.5" />
    <path d="M403.03 217.73H402.95" stroke="black" stroke-width="0.5" />
    <path d="M402.9 217.73H402.81" stroke="black" stroke-width="0.5" />
    <path d="M402.76 217.73H402.68" stroke="black" stroke-width="0.5" />
    <path d="M402.62 217.73H402.54" stroke="black" stroke-width="0.5" />
    <path d="M402.48 217.73H402.4" stroke="black" stroke-width="0.5" />
    <path d="M402.35 217.73H402.26" stroke="black" stroke-width="0.5" />
    <path d="M402.21 217.73H402.13" stroke="black" stroke-width="0.5" />
    <path d="M402.07 217.73H401.99" stroke="black" stroke-width="0.5" />
    <path d="M401.93 217.73H401.85" stroke="black" stroke-width="0.5" />
    <path d="M401.8 217.73H401.71" stroke="black" stroke-width="0.5" />
    <path d="M401.66 217.73H401.58" stroke="black" stroke-width="0.5" />
    <path d="M401.52 217.73H401.44" stroke="black" stroke-width="0.5" />
    <path d="M401.38 217.73H401.3" stroke="black" stroke-width="0.5" />
    <path d="M401.25 217.73H401.16" stroke="black" stroke-width="0.5" />
    <path d="M401.11 217.73H401.03" stroke="black" stroke-width="0.5" />
    <path d="M400.97 217.73H400.89" stroke="black" stroke-width="0.5" />
    <path d="M400.83 217.73H400.75" stroke="black" stroke-width="0.5" />
    <path d="M400.7 217.73H400.64" stroke="black" stroke-width="0.5" />
    <path d="M366.12 217.73V217.83" stroke="black" stroke-width="0.5" />
    <path d="M366.12 217.88V217.97" stroke="black" stroke-width="0.5" />
    <path d="M366.12 218.02V218.1" stroke="black" stroke-width="0.5" />
    <path d="M366.12 218.16V218.24" stroke="black" stroke-width="0.5" />
    <path d="M366.12 218.3V218.38" stroke="black" stroke-width="0.5" />
    <path d="M366.12 218.43V218.52" stroke="black" stroke-width="0.5" />
    <path d="M366.12 218.57V218.65" stroke="black" stroke-width="0.5" />
    <path d="M366.12 218.71V218.79" stroke="black" stroke-width="0.5" />
    <path d="M366.12 218.85V218.93" stroke="black" stroke-width="0.5" />
    <path d="M366.12 218.98V219.07" stroke="black" stroke-width="0.5" />
    <path d="M366.12 219.12V219.2" stroke="black" stroke-width="0.5" />
    <path d="M366.12 219.26V219.34" stroke="black" stroke-width="0.5" />
    <path d="M366.12 219.4V219.48" stroke="black" stroke-width="0.5" />
    <path d="M366.12 219.53V219.62" stroke="black" stroke-width="0.5" />
    <path d="M366.12 219.67V219.75" stroke="black" stroke-width="0.5" />
    <path d="M366.12 219.81V219.89" stroke="black" stroke-width="0.5" />
    <path d="M366.12 219.95V220.03" stroke="black" stroke-width="0.5" />
    <path d="M366.12 220.08V220.17" stroke="black" stroke-width="0.5" />
    <path d="M366.12 220.22V220.3" stroke="black" stroke-width="0.5" />
    <path d="M366.12 220.36V220.44" stroke="black" stroke-width="0.5" />
    <path d="M366.12 220.5V220.58" stroke="black" stroke-width="0.5" />
    <path d="M366.12 220.63V220.72" stroke="black" stroke-width="0.5" />
    <path d="M366.12 220.77V220.85" stroke="black" stroke-width="0.5" />
    <path d="M366.12 220.91V220.99" stroke="black" stroke-width="0.5" />
    <path d="M366.12 221.05V221.13" stroke="black" stroke-width="0.5" />
    <path d="M366.12 221.18V221.27" stroke="black" stroke-width="0.5" />
    <path d="M366.12 221.32V221.4" stroke="black" stroke-width="0.5" />
    <path d="M366.12 221.46V221.54" stroke="black" stroke-width="0.5" />
    <path d="M366.12 221.6V221.68" stroke="black" stroke-width="0.5" />
    <path d="M366.12 221.73V221.82" stroke="black" stroke-width="0.5" />
    <path d="M366.12 221.87V221.95" stroke="black" stroke-width="0.5" />
    <path d="M366.12 222.01V222.09" stroke="black" stroke-width="0.5" />
    <path d="M366.12 222.14V222.23" stroke="black" stroke-width="0.5" />
    <path d="M366.12 222.28V222.36" stroke="black" stroke-width="0.5" />
    <path d="M366.12 222.42V222.5" stroke="black" stroke-width="0.5" />
    <path d="M366.12 222.56V222.64" stroke="black" stroke-width="0.5" />
    <path d="M366.12 222.69V222.78" stroke="black" stroke-width="0.5" />
    <path d="M366.12 222.83V222.91" stroke="black" stroke-width="0.5" />
    <path d="M366.12 222.97V223.05" stroke="black" stroke-width="0.5" />
    <path d="M366.12 223.11V223.19" stroke="black" stroke-width="0.5" />
    <path d="M366.12 223.24V223.33" stroke="black" stroke-width="0.5" />
    <path d="M366.12 223.38V223.46" stroke="black" stroke-width="0.5" />
    <path d="M366.12 223.52V223.6" stroke="black" stroke-width="0.5" />
    <path d="M366.12 223.66V223.74" stroke="black" stroke-width="0.5" />
    <path d="M366.12 223.79V223.88" stroke="black" stroke-width="0.5" />
    <path d="M366.12 223.93V224.01" stroke="black" stroke-width="0.5" />
    <path d="M366.12 224.07V224.15" stroke="black" stroke-width="0.5" />
    <path d="M366.12 224.21V224.29" stroke="black" stroke-width="0.5" />
    <path d="M366.12 224.34V224.43" stroke="black" stroke-width="0.5" />
    <path d="M366.12 224.48V224.56" stroke="black" stroke-width="0.5" />
    <path d="M366.12 224.62V224.7" stroke="black" stroke-width="0.5" />
    <path d="M366.12 224.76V224.84" stroke="black" stroke-width="0.5" />
    <path d="M366.12 224.89V224.98" stroke="black" stroke-width="0.5" />
    <path d="M366.12 225.03V225.11" stroke="black" stroke-width="0.5" />
    <path d="M366.12 225.17V225.25" stroke="black" stroke-width="0.5" />
    <path d="M366.12 225.31V225.39" stroke="black" stroke-width="0.5" />
    <path d="M366.12 225.44V225.53" stroke="black" stroke-width="0.5" />
    <path d="M366.12 225.58V225.66" stroke="black" stroke-width="0.5" />
    <path d="M366.12 225.72V225.8" stroke="black" stroke-width="0.5" />
    <path d="M366.12 225.86V225.94" stroke="black" stroke-width="0.5" />
    <path d="M366.12 225.99V226.08" stroke="black" stroke-width="0.5" />
    <path d="M366.12 226.13V226.21" stroke="black" stroke-width="0.5" />
    <path d="M366.12 226.27V226.35" stroke="black" stroke-width="0.5" />
    <path d="M366.12 226.41V226.49" stroke="black" stroke-width="0.5" />
    <path d="M366.12 226.54V226.63" stroke="black" stroke-width="0.5" />
    <path d="M366.12 226.68V226.76" stroke="black" stroke-width="0.5" />
    <path d="M366.12 226.82V226.9" stroke="black" stroke-width="0.5" />
    <path d="M366.12 226.96V227.04" stroke="black" stroke-width="0.5" />
    <path d="M366.12 227.09V227.18" stroke="black" stroke-width="0.5" />
    <path d="M366.12 227.23V227.31" stroke="black" stroke-width="0.5" />
    <path d="M366.12 227.37V227.45" stroke="black" stroke-width="0.5" />
    <path d="M366.12 227.51V227.59" stroke="black" stroke-width="0.5" />
    <path d="M366.12 227.64V227.73" stroke="black" stroke-width="0.5" />
    <path d="M366.12 227.78V227.86" stroke="black" stroke-width="0.5" />
    <path d="M366.12 227.92V228" stroke="black" stroke-width="0.5" />
    <path d="M366.12 228.06V228.14" stroke="black" stroke-width="0.5" />
    <path d="M366.12 228.19V228.29" stroke="black" stroke-width="0.5" />
    <path d="M366.12 228.29H366.17" stroke="black" stroke-width="0.5" />
    <path d="M366.23 228.29H366.31" stroke="black" stroke-width="0.5" />
    <path d="M366.36 228.29H366.45" stroke="black" stroke-width="0.5" />
    <path d="M366.5 228.29H366.58" stroke="black" stroke-width="0.5" />
    <path d="M366.64 228.29H366.72" stroke="black" stroke-width="0.5" />
    <path d="M366.78 228.29H366.86" stroke="black" stroke-width="0.5" />
    <path d="M366.91 228.29H367" stroke="black" stroke-width="0.5" />
    <path d="M367.05 228.29H367.13" stroke="black" stroke-width="0.5" />
    <path d="M367.19 228.29H367.27" stroke="black" stroke-width="0.5" />
    <path d="M367.32 228.29H367.41" stroke="black" stroke-width="0.5" />
    <path d="M367.46 228.29H367.54" stroke="black" stroke-width="0.5" />
    <path d="M367.6 228.29H367.68" stroke="black" stroke-width="0.5" />
    <path d="M367.74 228.29H367.82" stroke="black" stroke-width="0.5" />
    <path d="M367.87 228.29H367.96" stroke="black" stroke-width="0.5" />
    <path d="M368.01 228.29H368.09" stroke="black" stroke-width="0.5" />
    <path d="M368.15 228.29H368.23" stroke="black" stroke-width="0.5" />
    <path d="M368.29 228.29H368.37" stroke="black" stroke-width="0.5" />
    <path d="M368.42 228.29H368.51" stroke="black" stroke-width="0.5" />
    <path d="M368.56 228.29H368.64" stroke="black" stroke-width="0.5" />
    <path d="M368.7 228.29H368.78" stroke="black" stroke-width="0.5" />
    <path d="M368.84 228.29H368.92" stroke="black" stroke-width="0.5" />
    <path d="M368.97 228.29H369.06" stroke="black" stroke-width="0.5" />
    <path d="M369.11 228.29H369.19" stroke="black" stroke-width="0.5" />
    <path d="M369.25 228.29H369.33" stroke="black" stroke-width="0.5" />
    <path d="M369.39 228.29H369.47" stroke="black" stroke-width="0.5" />
    <path d="M369.52 228.29H369.61" stroke="black" stroke-width="0.5" />
    <path d="M369.66 228.29H369.74" stroke="black" stroke-width="0.5" />
    <path d="M369.8 228.29H369.88" stroke="black" stroke-width="0.5" />
    <path d="M369.94 228.29H370.02" stroke="black" stroke-width="0.5" />
    <path d="M370.07 228.29H370.16" stroke="black" stroke-width="0.5" />
    <path d="M370.21 228.29H370.29" stroke="black" stroke-width="0.5" />
    <path d="M370.35 228.29H370.43" stroke="black" stroke-width="0.5" />
    <path d="M370.49 228.29H370.57" stroke="black" stroke-width="0.5" />
    <path d="M370.62 228.29H370.71" stroke="black" stroke-width="0.5" />
    <path d="M370.76 228.29H370.84" stroke="black" stroke-width="0.5" />
    <path d="M370.9 228.29H370.98" stroke="black" stroke-width="0.5" />
    <path d="M371.04 228.29H371.12" stroke="black" stroke-width="0.5" />
    <path d="M371.17 228.29H371.26" stroke="black" stroke-width="0.5" />
    <path d="M371.31 228.29H371.39" stroke="black" stroke-width="0.5" />
    <path d="M371.45 228.29H371.53" stroke="black" stroke-width="0.5" />
    <path d="M371.59 228.29H371.67" stroke="black" stroke-width="0.5" />
    <path d="M371.72 228.29H371.81" stroke="black" stroke-width="0.5" />
    <path d="M371.86 228.29H371.94" stroke="black" stroke-width="0.5" />
    <path d="M372 228.29H372.08" stroke="black" stroke-width="0.5" />
    <path d="M372.14 228.29H372.22" stroke="black" stroke-width="0.5" />
    <path d="M372.27 228.29H372.36" stroke="black" stroke-width="0.5" />
    <path d="M372.41 228.29H372.49" stroke="black" stroke-width="0.5" />
    <path d="M372.55 228.29H372.63" stroke="black" stroke-width="0.5" />
    <path d="M372.69 228.29H372.77" stroke="black" stroke-width="0.5" />
    <path d="M372.82 228.29H372.91" stroke="black" stroke-width="0.5" />
    <path d="M372.96 228.29H373.04" stroke="black" stroke-width="0.5" />
    <path d="M373.1 228.29H373.18" stroke="black" stroke-width="0.5" />
    <path d="M373.24 228.29H373.32" stroke="black" stroke-width="0.5" />
    <path d="M373.37 228.29H373.46" stroke="black" stroke-width="0.5" />
    <path d="M373.51 228.29H373.59" stroke="black" stroke-width="0.5" />
    <path d="M373.65 228.29H373.73" stroke="black" stroke-width="0.5" />
    <path d="M373.78 228.29H373.87" stroke="black" stroke-width="0.5" />
    <path d="M373.92 228.29H374" stroke="black" stroke-width="0.5" />
    <path d="M374.06 228.29H374.14" stroke="black" stroke-width="0.5" />
    <path d="M374.2 228.29H374.28" stroke="black" stroke-width="0.5" />
    <path d="M374.33 228.29H374.42" stroke="black" stroke-width="0.5" />
    <path d="M374.47 228.29H374.55" stroke="black" stroke-width="0.5" />
    <path d="M374.61 228.29H374.69" stroke="black" stroke-width="0.5" />
    <path d="M374.75 228.29H374.83" stroke="black" stroke-width="0.5" />
    <path d="M374.88 228.29H374.97" stroke="black" stroke-width="0.5" />
    <path d="M375.02 228.29H375.1" stroke="black" stroke-width="0.5" />
    <path d="M375.16 228.29H375.24" stroke="black" stroke-width="0.5" />
    <path d="M375.3 228.29H375.38" stroke="black" stroke-width="0.5" />
    <path d="M375.43 228.29H375.52" stroke="black" stroke-width="0.5" />
    <path d="M375.57 228.29H375.65" stroke="black" stroke-width="0.5" />
    <path d="M375.71 228.29H375.79" stroke="black" stroke-width="0.5" />
    <path d="M375.85 228.29H375.93" stroke="black" stroke-width="0.5" />
    <path d="M375.98 228.29H376.07" stroke="black" stroke-width="0.5" />
    <path d="M376.12 228.29H376.2" stroke="black" stroke-width="0.5" />
    <path d="M376.26 228.29H376.34" stroke="black" stroke-width="0.5" />
    <path d="M376.4 228.29H376.48" stroke="black" stroke-width="0.5" />
    <path d="M376.53 228.29H376.62" stroke="black" stroke-width="0.5" />
    <path d="M376.67 228.29H376.75" stroke="black" stroke-width="0.5" />
    <path d="M376.81 228.29H376.89" stroke="black" stroke-width="0.5" />
    <path d="M376.95 228.29H377.03" stroke="black" stroke-width="0.5" />
    <path d="M377.08 228.29H377.17" stroke="black" stroke-width="0.5" />
    <path d="M377.22 228.29H377.3" stroke="black" stroke-width="0.5" />
    <path d="M377.36 228.29H377.44" stroke="black" stroke-width="0.5" />
    <path d="M377.5 228.29H377.58" stroke="black" stroke-width="0.5" />
    <path d="M377.63 228.29H377.72" stroke="black" stroke-width="0.5" />
    <path d="M377.77 228.29H377.85" stroke="black" stroke-width="0.5" />
    <path d="M377.91 228.29H377.99" stroke="black" stroke-width="0.5" />
    <path d="M378.05 228.29H378.13" stroke="black" stroke-width="0.5" />
    <path d="M378.18 228.29H378.27" stroke="black" stroke-width="0.5" />
    <path d="M378.32 228.29H378.4" stroke="black" stroke-width="0.5" />
    <path d="M378.46 228.29H378.54" stroke="black" stroke-width="0.5" />
    <path d="M378.6 228.29H378.68" stroke="black" stroke-width="0.5" />
    <path d="M378.73 228.29H378.82" stroke="black" stroke-width="0.5" />
    <path d="M378.87 228.29H378.95" stroke="black" stroke-width="0.5" />
    <path d="M379.01 228.29H379.09" stroke="black" stroke-width="0.5" />
    <path d="M379.15 228.29H379.23" stroke="black" stroke-width="0.5" />
    <path d="M379.28 228.29H379.37" stroke="black" stroke-width="0.5" />
    <path d="M379.42 228.29H379.5" stroke="black" stroke-width="0.5" />
    <path d="M379.56 228.29H379.64" stroke="black" stroke-width="0.5" />
    <path d="M379.7 228.29H379.78" stroke="black" stroke-width="0.5" />
    <path d="M379.83 228.29H379.92" stroke="black" stroke-width="0.5" />
    <path d="M379.97 228.29H380.05" stroke="black" stroke-width="0.5" />
    <path d="M380.11 228.29H380.19" stroke="black" stroke-width="0.5" />
    <path d="M380.24 228.29H380.33" stroke="black" stroke-width="0.5" />
    <path d="M380.38 228.29H380.46" stroke="black" stroke-width="0.5" />
    <path d="M380.52 228.29H380.6" stroke="black" stroke-width="0.5" />
    <path d="M380.66 228.29H380.74" stroke="black" stroke-width="0.5" />
    <path d="M380.79 228.29H380.88" stroke="black" stroke-width="0.5" />
    <path d="M380.93 228.29H381.01" stroke="black" stroke-width="0.5" />
    <path d="M381.07 228.29H381.15" stroke="black" stroke-width="0.5" />
    <path d="M381.21 228.29H381.29" stroke="black" stroke-width="0.5" />
    <path d="M381.34 228.29H381.43" stroke="black" stroke-width="0.5" />
    <path d="M381.48 228.29H381.56" stroke="black" stroke-width="0.5" />
    <path d="M381.62 228.29H381.7" stroke="black" stroke-width="0.5" />
    <path d="M381.76 228.29H381.84" stroke="black" stroke-width="0.5" />
    <path d="M381.89 228.29H381.98" stroke="black" stroke-width="0.5" />
    <path d="M382.03 228.29H382.11" stroke="black" stroke-width="0.5" />
    <path d="M382.17 228.29H382.25" stroke="black" stroke-width="0.5" />
    <path d="M382.31 228.29H382.39" stroke="black" stroke-width="0.5" />
    <path d="M382.44 228.29H382.53" stroke="black" stroke-width="0.5" />
    <path d="M382.58 228.29H382.66" stroke="black" stroke-width="0.5" />
    <path d="M382.72 228.29H382.8" stroke="black" stroke-width="0.5" />
    <path d="M382.86 228.29H382.94" stroke="black" stroke-width="0.5" />
    <path d="M382.99 228.29H383.08" stroke="black" stroke-width="0.5" />
    <path d="M383.13 228.29H383.21" stroke="black" stroke-width="0.5" />
    <path d="M383.27 228.29H383.35" stroke="black" stroke-width="0.5" />
    <path d="M383.41 228.29H383.49" stroke="black" stroke-width="0.5" />
    <path d="M383.54 228.29H383.63" stroke="black" stroke-width="0.5" />
    <path d="M383.68 228.29H383.76" stroke="black" stroke-width="0.5" />
    <path d="M383.82 228.29H383.9" stroke="black" stroke-width="0.5" />
    <path d="M383.96 228.29H384.04" stroke="black" stroke-width="0.5" />
    <path d="M384.09 228.29H384.18" stroke="black" stroke-width="0.5" />
    <path d="M384.23 228.29H384.31" stroke="black" stroke-width="0.5" />
    <path d="M384.37 228.29H384.45" stroke="black" stroke-width="0.5" />
    <path d="M384.51 228.29H384.59" stroke="black" stroke-width="0.5" />
    <path d="M384.64 228.29H384.73" stroke="black" stroke-width="0.5" />
    <path d="M384.78 228.29H384.86" stroke="black" stroke-width="0.5" />
    <path d="M384.92 228.29H385" stroke="black" stroke-width="0.5" />
    <path d="M385.06 228.29H385.14" stroke="black" stroke-width="0.5" />
    <path d="M385.19 228.29H385.28" stroke="black" stroke-width="0.5" />
    <path d="M385.33 228.29H385.41" stroke="black" stroke-width="0.5" />
    <path d="M385.47 228.29H385.55" stroke="black" stroke-width="0.5" />
    <path d="M385.61 228.29H385.69" stroke="black" stroke-width="0.5" />
    <path d="M385.74 228.29H385.83" stroke="black" stroke-width="0.5" />
    <path d="M385.88 228.29H385.96" stroke="black" stroke-width="0.5" />
    <path d="M386.02 228.29H386.1" stroke="black" stroke-width="0.5" />
    <path d="M386.16 228.29H386.24" stroke="black" stroke-width="0.5" />
    <path d="M386.29 228.29H386.38" stroke="black" stroke-width="0.5" />
    <path d="M386.43 228.29H386.51" stroke="black" stroke-width="0.5" />
    <path d="M386.57 228.29H386.65" stroke="black" stroke-width="0.5" />
    <path d="M386.7 228.29H386.79" stroke="black" stroke-width="0.5" />
    <path d="M386.84 228.29H386.92" stroke="black" stroke-width="0.5" />
    <path d="M386.98 228.29H387.06" stroke="black" stroke-width="0.5" />
    <path d="M387.12 228.29H387.2" stroke="black" stroke-width="0.5" />
    <path d="M387.25 228.29H387.34" stroke="black" stroke-width="0.5" />
    <path d="M387.39 228.29H387.47" stroke="black" stroke-width="0.5" />
    <path d="M387.53 228.29H387.61" stroke="black" stroke-width="0.5" />
    <path d="M387.67 228.29H387.75" stroke="black" stroke-width="0.5" />
    <path d="M387.8 228.29H387.89" stroke="black" stroke-width="0.5" />
    <path d="M387.94 228.29H388.02" stroke="black" stroke-width="0.5" />
    <path d="M388.08 228.29H388.16" stroke="black" stroke-width="0.5" />
    <path d="M388.22 228.29H388.3" stroke="black" stroke-width="0.5" />
    <path d="M388.35 228.29H388.44" stroke="black" stroke-width="0.5" />
    <path d="M388.49 228.29H388.57" stroke="black" stroke-width="0.5" />
    <path d="M388.63 228.29H388.71" stroke="black" stroke-width="0.5" />
    <path d="M388.77 228.29H388.85" stroke="black" stroke-width="0.5" />
    <path d="M388.9 228.29H388.99" stroke="black" stroke-width="0.5" />
    <path d="M389.04 228.29H389.12" stroke="black" stroke-width="0.5" />
    <path d="M389.18 228.29H389.26" stroke="black" stroke-width="0.5" />
    <path d="M389.32 228.29H389.4" stroke="black" stroke-width="0.5" />
    <path d="M389.45 228.29H389.54" stroke="black" stroke-width="0.5" />
    <path d="M389.59 228.29H389.67" stroke="black" stroke-width="0.5" />
    <path d="M389.73 228.29H389.81" stroke="black" stroke-width="0.5" />
    <path d="M389.87 228.29H389.95" stroke="black" stroke-width="0.5" />
    <path d="M390 228.29H390.09" stroke="black" stroke-width="0.5" />
    <path d="M390.14 228.29H390.22" stroke="black" stroke-width="0.5" />
    <path d="M390.28 228.29H390.36" stroke="black" stroke-width="0.5" />
    <path d="M390.42 228.29H390.5" stroke="black" stroke-width="0.5" />
    <path d="M390.55 228.29H390.64" stroke="black" stroke-width="0.5" />
    <path d="M390.69 228.29H390.77" stroke="black" stroke-width="0.5" />
    <path d="M390.83 228.29H390.91" stroke="black" stroke-width="0.5" />
    <path d="M390.97 228.29H391.05" stroke="black" stroke-width="0.5" />
    <path d="M391.1 228.29H391.19" stroke="black" stroke-width="0.5" />
    <path d="M391.24 228.29H391.32" stroke="black" stroke-width="0.5" />
    <path d="M391.38 228.29H391.46" stroke="black" stroke-width="0.5" />
    <path d="M391.52 228.29H391.6" stroke="black" stroke-width="0.5" />
    <path d="M391.65 228.29H391.74" stroke="black" stroke-width="0.5" />
    <path d="M391.79 228.29H391.87" stroke="black" stroke-width="0.5" />
    <path d="M391.93 228.29H392.01" stroke="black" stroke-width="0.5" />
    <path d="M392.07 228.29H392.15" stroke="black" stroke-width="0.5" />
    <path d="M392.2 228.29H392.29" stroke="black" stroke-width="0.5" />
    <path d="M392.34 228.29H392.42" stroke="black" stroke-width="0.5" />
    <path d="M392.48 228.29H392.56" stroke="black" stroke-width="0.5" />
    <path d="M392.62 228.29H392.7" stroke="black" stroke-width="0.5" />
    <path d="M392.75 228.29H392.84" stroke="black" stroke-width="0.5" />
    <path d="M392.89 228.29H392.97" stroke="black" stroke-width="0.5" />
    <path d="M393.03 228.29H393.11" stroke="black" stroke-width="0.5" />
    <path d="M393.16 228.29H393.25" stroke="black" stroke-width="0.5" />
    <path d="M393.3 228.29H393.38" stroke="black" stroke-width="0.5" />
    <path d="M393.44 228.29H393.52" stroke="black" stroke-width="0.5" />
    <path d="M393.58 228.29H393.66" stroke="black" stroke-width="0.5" />
    <path d="M393.71 228.29H393.8" stroke="black" stroke-width="0.5" />
    <path d="M393.85 228.29H393.93" stroke="black" stroke-width="0.5" />
    <path d="M393.99 228.29H394.07" stroke="black" stroke-width="0.5" />
    <path d="M394.13 228.29H394.21" stroke="black" stroke-width="0.5" />
    <path d="M394.26 228.29H394.35" stroke="black" stroke-width="0.5" />
    <path d="M394.4 228.29H394.48" stroke="black" stroke-width="0.5" />
    <path d="M394.54 228.29H394.62" stroke="black" stroke-width="0.5" />
    <path d="M394.68 228.29H394.76" stroke="black" stroke-width="0.5" />
    <path d="M394.81 228.29H394.9" stroke="black" stroke-width="0.5" />
    <path d="M394.95 228.29H395.03" stroke="black" stroke-width="0.5" />
    <path d="M395.09 228.29H395.17" stroke="black" stroke-width="0.5" />
    <path d="M395.23 228.29H395.31" stroke="black" stroke-width="0.5" />
    <path d="M395.36 228.29H395.45" stroke="black" stroke-width="0.5" />
    <path d="M395.5 228.29H395.58" stroke="black" stroke-width="0.5" />
    <path d="M395.64 228.29H395.72" stroke="black" stroke-width="0.5" />
    <path d="M395.78 228.29H395.86" stroke="black" stroke-width="0.5" />
    <path d="M395.91 228.29H396" stroke="black" stroke-width="0.5" />
    <path d="M396.05 228.29H396.13" stroke="black" stroke-width="0.5" />
    <path d="M396.19 228.29H396.27" stroke="black" stroke-width="0.5" />
    <path d="M396.33 228.29H396.41" stroke="black" stroke-width="0.5" />
    <path d="M396.46 228.29H396.55" stroke="black" stroke-width="0.5" />
    <path d="M396.6 228.29H396.68" stroke="black" stroke-width="0.5" />
    <path d="M396.74 228.29H396.82" stroke="black" stroke-width="0.5" />
    <path d="M396.88 228.29H396.96" stroke="black" stroke-width="0.5" />
    <path d="M397.01 228.29H397.1" stroke="black" stroke-width="0.5" />
    <path d="M397.15 228.29H397.23" stroke="black" stroke-width="0.5" />
    <path d="M397.29 228.29H397.37" stroke="black" stroke-width="0.5" />
    <path d="M397.43 228.29H397.51" stroke="black" stroke-width="0.5" />
    <path d="M397.56 228.29H397.65" stroke="black" stroke-width="0.5" />
    <path d="M397.7 228.29H397.78" stroke="black" stroke-width="0.5" />
    <path d="M397.84 228.29H397.92" stroke="black" stroke-width="0.5" />
    <path d="M397.98 228.29H398.06" stroke="black" stroke-width="0.5" />
    <path d="M398.11 228.29H398.2" stroke="black" stroke-width="0.5" />
    <path d="M398.25 228.29H398.33" stroke="black" stroke-width="0.5" />
    <path d="M398.39 228.29H398.47" stroke="black" stroke-width="0.5" />
    <path d="M398.53 228.29H398.61" stroke="black" stroke-width="0.5" />
    <path d="M398.66 228.29H398.75" stroke="black" stroke-width="0.5" />
    <path d="M398.8 228.29H398.88" stroke="black" stroke-width="0.5" />
    <path d="M398.94 228.29H399.02" stroke="black" stroke-width="0.5" />
    <path d="M399.08 228.29H399.16" stroke="black" stroke-width="0.5" />
    <path d="M399.21 228.29H399.3" stroke="black" stroke-width="0.5" />
    <path d="M399.35 228.29H399.43" stroke="black" stroke-width="0.5" />
    <path d="M399.49 228.29H399.57" stroke="black" stroke-width="0.5" />
    <path d="M399.62 228.29H399.71" stroke="black" stroke-width="0.5" />
    <path d="M399.76 228.29H399.84" stroke="black" stroke-width="0.5" />
    <path d="M399.9 228.29H399.98" stroke="black" stroke-width="0.5" />
    <path d="M400.04 228.29H400.12" stroke="black" stroke-width="0.5" />
    <path d="M400.17 228.29H400.26" stroke="black" stroke-width="0.5" />
    <path d="M400.31 228.29H400.39" stroke="black" stroke-width="0.5" />
    <path d="M400.45 228.29H400.53" stroke="black" stroke-width="0.5" />
    <path d="M400.59 228.29H400.64" stroke="black" stroke-width="0.5" />
    <path d="M400.64 228.29V228.19" stroke="black" stroke-width="0.5" />
    <path d="M400.64 228.14V228.06" stroke="black" stroke-width="0.5" />
    <path d="M400.64 228V227.92" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.86V227.78" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.73V227.64" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.59V227.51" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.45V227.37" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.31V227.23" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.18V227.09" stroke="black" stroke-width="0.5" />
    <path d="M400.64 227.04V226.96" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.9V226.82" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.76V226.68" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.63V226.54" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.49V226.41" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.35V226.27" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.21V226.13" stroke="black" stroke-width="0.5" />
    <path d="M400.64 226.08V225.99" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.94V225.86" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.8V225.72" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.66V225.58" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.53V225.44" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.39V225.31" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.25V225.17" stroke="black" stroke-width="0.5" />
    <path d="M400.64 225.11V225.03" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.98V224.89" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.84V224.76" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.7V224.62" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.56V224.48" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.43V224.34" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.29V224.21" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.15V224.07" stroke="black" stroke-width="0.5" />
    <path d="M400.64 224.01V223.93" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.88V223.79" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.74V223.66" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.6V223.52" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.46V223.38" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.33V223.24" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.19V223.11" stroke="black" stroke-width="0.5" />
    <path d="M400.64 223.05V222.97" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.91V222.83" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.78V222.69" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.64V222.56" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.5V222.42" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.36V222.28" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.23V222.14" stroke="black" stroke-width="0.5" />
    <path d="M400.64 222.09V222.01" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.95V221.87" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.82V221.73" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.68V221.6" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.54V221.46" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.4V221.32" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.27V221.18" stroke="black" stroke-width="0.5" />
    <path d="M400.64 221.13V221.05" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.99V220.91" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.85V220.77" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.72V220.63" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.58V220.5" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.44V220.36" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.3V220.22" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.17V220.08" stroke="black" stroke-width="0.5" />
    <path d="M400.64 220.03V219.95" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.89V219.81" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.75V219.67" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.62V219.53" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.48V219.4" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.34V219.26" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.2V219.12" stroke="black" stroke-width="0.5" />
    <path d="M400.64 219.07V218.98" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.93V218.85" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.79V218.71" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.65V218.57" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.52V218.43" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.38V218.3" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.24V218.16" stroke="black" stroke-width="0.5" />
    <path d="M400.64 218.1V218.02" stroke="black" stroke-width="0.5" />
    <path d="M400.64 217.97V217.88" stroke="black" stroke-width="0.5" />
    <path d="M400.64 217.83V217.73" stroke="black" stroke-width="0.5" />
    <path d="M400.64 217.73H400.59" stroke="black" stroke-width="0.5" />
    <path d="M400.53 217.73H400.45" stroke="black" stroke-width="0.5" />
    <path d="M400.39 217.73H400.31" stroke="black" stroke-width="0.5" />
    <path d="M400.26 217.73H400.17" stroke="black" stroke-width="0.5" />
    <path d="M400.12 217.73H400.04" stroke="black" stroke-width="0.5" />
    <path d="M399.98 217.73H399.9" stroke="black" stroke-width="0.5" />
    <path d="M399.84 217.73H399.76" stroke="black" stroke-width="0.5" />
    <path d="M399.71 217.73H399.62" stroke="black" stroke-width="0.5" />
    <path d="M399.57 217.73H399.49" stroke="black" stroke-width="0.5" />
    <path d="M399.43 217.73H399.35" stroke="black" stroke-width="0.5" />
    <path d="M399.3 217.73H399.21" stroke="black" stroke-width="0.5" />
    <path d="M399.16 217.73H399.08" stroke="black" stroke-width="0.5" />
    <path d="M399.02 217.73H398.94" stroke="black" stroke-width="0.5" />
    <path d="M398.88 217.73H398.8" stroke="black" stroke-width="0.5" />
    <path d="M398.75 217.73H398.66" stroke="black" stroke-width="0.5" />
    <path d="M398.61 217.73H398.53" stroke="black" stroke-width="0.5" />
    <path d="M398.47 217.73H398.39" stroke="black" stroke-width="0.5" />
    <path d="M398.33 217.73H398.25" stroke="black" stroke-width="0.5" />
    <path d="M398.2 217.73H398.11" stroke="black" stroke-width="0.5" />
    <path d="M398.06 217.73H397.98" stroke="black" stroke-width="0.5" />
    <path d="M397.92 217.73H397.84" stroke="black" stroke-width="0.5" />
    <path d="M397.78 217.73H397.7" stroke="black" stroke-width="0.5" />
    <path d="M397.65 217.73H397.56" stroke="black" stroke-width="0.5" />
    <path d="M397.51 217.73H397.43" stroke="black" stroke-width="0.5" />
    <path d="M397.37 217.73H397.29" stroke="black" stroke-width="0.5" />
    <path d="M397.23 217.73H397.15" stroke="black" stroke-width="0.5" />
    <path d="M397.1 217.73H397.01" stroke="black" stroke-width="0.5" />
    <path d="M396.96 217.73H396.88" stroke="black" stroke-width="0.5" />
    <path d="M396.82 217.73H396.74" stroke="black" stroke-width="0.5" />
    <path d="M396.68 217.73H396.6" stroke="black" stroke-width="0.5" />
    <path d="M396.55 217.73H396.46" stroke="black" stroke-width="0.5" />
    <path d="M396.41 217.73H396.33" stroke="black" stroke-width="0.5" />
    <path d="M396.27 217.73H396.19" stroke="black" stroke-width="0.5" />
    <path d="M396.13 217.73H396.05" stroke="black" stroke-width="0.5" />
    <path d="M396 217.73H395.91" stroke="black" stroke-width="0.5" />
    <path d="M395.86 217.73H395.78" stroke="black" stroke-width="0.5" />
    <path d="M395.72 217.73H395.64" stroke="black" stroke-width="0.5" />
    <path d="M395.58 217.73H395.5" stroke="black" stroke-width="0.5" />
    <path d="M395.45 217.73H395.36" stroke="black" stroke-width="0.5" />
    <path d="M395.31 217.73H395.23" stroke="black" stroke-width="0.5" />
    <path d="M395.17 217.73H395.09" stroke="black" stroke-width="0.5" />
    <path d="M395.03 217.73H394.95" stroke="black" stroke-width="0.5" />
    <path d="M394.9 217.73H394.81" stroke="black" stroke-width="0.5" />
    <path d="M394.76 217.73H394.68" stroke="black" stroke-width="0.5" />
    <path d="M394.62 217.73H394.54" stroke="black" stroke-width="0.5" />
    <path d="M394.48 217.73H394.4" stroke="black" stroke-width="0.5" />
    <path d="M394.35 217.73H394.26" stroke="black" stroke-width="0.5" />
    <path d="M394.21 217.73H394.13" stroke="black" stroke-width="0.5" />
    <path d="M394.07 217.73H393.99" stroke="black" stroke-width="0.5" />
    <path d="M393.93 217.73H393.85" stroke="black" stroke-width="0.5" />
    <path d="M393.8 217.73H393.71" stroke="black" stroke-width="0.5" />
    <path d="M393.66 217.73H393.58" stroke="black" stroke-width="0.5" />
    <path d="M393.52 217.73H393.44" stroke="black" stroke-width="0.5" />
    <path d="M393.38 217.73H393.3" stroke="black" stroke-width="0.5" />
    <path d="M393.25 217.73H393.16" stroke="black" stroke-width="0.5" />
    <path d="M393.11 217.73H393.03" stroke="black" stroke-width="0.5" />
    <path d="M392.97 217.73H392.89" stroke="black" stroke-width="0.5" />
    <path d="M392.84 217.73H392.75" stroke="black" stroke-width="0.5" />
    <path d="M392.7 217.73H392.62" stroke="black" stroke-width="0.5" />
    <path d="M392.56 217.73H392.48" stroke="black" stroke-width="0.5" />
    <path d="M392.42 217.73H392.34" stroke="black" stroke-width="0.5" />
    <path d="M392.29 217.73H392.2" stroke="black" stroke-width="0.5" />
    <path d="M392.15 217.73H392.07" stroke="black" stroke-width="0.5" />
    <path d="M392.01 217.73H391.93" stroke="black" stroke-width="0.5" />
    <path d="M391.87 217.73H391.79" stroke="black" stroke-width="0.5" />
    <path d="M391.74 217.73H391.65" stroke="black" stroke-width="0.5" />
    <path d="M391.6 217.73H391.52" stroke="black" stroke-width="0.5" />
    <path d="M391.46 217.73H391.38" stroke="black" stroke-width="0.5" />
    <path d="M391.32 217.73H391.24" stroke="black" stroke-width="0.5" />
    <path d="M391.19 217.73H391.1" stroke="black" stroke-width="0.5" />
    <path d="M391.05 217.73H390.97" stroke="black" stroke-width="0.5" />
    <path d="M390.91 217.73H390.83" stroke="black" stroke-width="0.5" />
    <path d="M390.77 217.73H390.69" stroke="black" stroke-width="0.5" />
    <path d="M390.64 217.73H390.55" stroke="black" stroke-width="0.5" />
    <path d="M390.5 217.73H390.42" stroke="black" stroke-width="0.5" />
    <path d="M390.36 217.73H390.28" stroke="black" stroke-width="0.5" />
    <path d="M390.22 217.73H390.14" stroke="black" stroke-width="0.5" />
    <path d="M390.09 217.73H390" stroke="black" stroke-width="0.5" />
    <path d="M389.95 217.73H389.87" stroke="black" stroke-width="0.5" />
    <path d="M389.81 217.73H389.73" stroke="black" stroke-width="0.5" />
    <path d="M389.67 217.73H389.59" stroke="black" stroke-width="0.5" />
    <path d="M389.54 217.73H389.45" stroke="black" stroke-width="0.5" />
    <path d="M389.4 217.73H389.32" stroke="black" stroke-width="0.5" />
    <path d="M389.26 217.73H389.18" stroke="black" stroke-width="0.5" />
    <path d="M389.12 217.73H389.04" stroke="black" stroke-width="0.5" />
    <path d="M388.99 217.73H388.9" stroke="black" stroke-width="0.5" />
    <path d="M388.85 217.73H388.77" stroke="black" stroke-width="0.5" />
    <path d="M388.71 217.73H388.63" stroke="black" stroke-width="0.5" />
    <path d="M388.57 217.73H388.49" stroke="black" stroke-width="0.5" />
    <path d="M388.44 217.73H388.35" stroke="black" stroke-width="0.5" />
    <path d="M388.3 217.73H388.22" stroke="black" stroke-width="0.5" />
    <path d="M388.16 217.73H388.08" stroke="black" stroke-width="0.5" />
    <path d="M388.02 217.73H387.94" stroke="black" stroke-width="0.5" />
    <path d="M387.89 217.73H387.8" stroke="black" stroke-width="0.5" />
    <path d="M387.75 217.73H387.67" stroke="black" stroke-width="0.5" />
    <path d="M387.61 217.73H387.53" stroke="black" stroke-width="0.5" />
    <path d="M387.47 217.73H387.39" stroke="black" stroke-width="0.5" />
    <path d="M387.34 217.73H387.25" stroke="black" stroke-width="0.5" />
    <path d="M387.2 217.73H387.12" stroke="black" stroke-width="0.5" />
    <path d="M387.06 217.73H386.98" stroke="black" stroke-width="0.5" />
    <path d="M386.92 217.73H386.84" stroke="black" stroke-width="0.5" />
    <path d="M386.79 217.73H386.7" stroke="black" stroke-width="0.5" />
    <path d="M386.65 217.73H386.57" stroke="black" stroke-width="0.5" />
    <path d="M386.51 217.73H386.43" stroke="black" stroke-width="0.5" />
    <path d="M386.38 217.73H386.29" stroke="black" stroke-width="0.5" />
    <path d="M386.24 217.73H386.16" stroke="black" stroke-width="0.5" />
    <path d="M386.1 217.73H386.02" stroke="black" stroke-width="0.5" />
    <path d="M385.96 217.73H385.88" stroke="black" stroke-width="0.5" />
    <path d="M385.83 217.73H385.74" stroke="black" stroke-width="0.5" />
    <path d="M385.69 217.73H385.61" stroke="black" stroke-width="0.5" />
    <path d="M385.55 217.73H385.47" stroke="black" stroke-width="0.5" />
    <path d="M385.41 217.73H385.33" stroke="black" stroke-width="0.5" />
    <path d="M385.28 217.73H385.19" stroke="black" stroke-width="0.5" />
    <path d="M385.14 217.73H385.06" stroke="black" stroke-width="0.5" />
    <path d="M385 217.73H384.92" stroke="black" stroke-width="0.5" />
    <path d="M384.86 217.73H384.78" stroke="black" stroke-width="0.5" />
    <path d="M384.73 217.73H384.64" stroke="black" stroke-width="0.5" />
    <path d="M384.59 217.73H384.51" stroke="black" stroke-width="0.5" />
    <path d="M384.45 217.73H384.37" stroke="black" stroke-width="0.5" />
    <path d="M384.31 217.73H384.23" stroke="black" stroke-width="0.5" />
    <path d="M384.18 217.73H384.09" stroke="black" stroke-width="0.5" />
    <path d="M384.04 217.73H383.96" stroke="black" stroke-width="0.5" />
    <path d="M383.9 217.73H383.82" stroke="black" stroke-width="0.5" />
    <path d="M383.76 217.73H383.68" stroke="black" stroke-width="0.5" />
    <path d="M383.63 217.73H383.54" stroke="black" stroke-width="0.5" />
    <path d="M383.49 217.73H383.41" stroke="black" stroke-width="0.5" />
    <path d="M383.35 217.73H383.27" stroke="black" stroke-width="0.5" />
    <path d="M383.21 217.73H383.13" stroke="black" stroke-width="0.5" />
    <path d="M383.08 217.73H382.99" stroke="black" stroke-width="0.5" />
    <path d="M382.94 217.73H382.86" stroke="black" stroke-width="0.5" />
    <path d="M382.8 217.73H382.72" stroke="black" stroke-width="0.5" />
    <path d="M382.66 217.73H382.58" stroke="black" stroke-width="0.5" />
    <path d="M382.53 217.73H382.44" stroke="black" stroke-width="0.5" />
    <path d="M382.39 217.73H382.31" stroke="black" stroke-width="0.5" />
    <path d="M382.25 217.73H382.17" stroke="black" stroke-width="0.5" />
    <path d="M382.11 217.73H382.03" stroke="black" stroke-width="0.5" />
    <path d="M381.98 217.73H381.89" stroke="black" stroke-width="0.5" />
    <path d="M381.84 217.73H381.76" stroke="black" stroke-width="0.5" />
    <path d="M381.7 217.73H381.62" stroke="black" stroke-width="0.5" />
    <path d="M381.56 217.73H381.48" stroke="black" stroke-width="0.5" />
    <path d="M381.43 217.73H381.34" stroke="black" stroke-width="0.5" />
    <path d="M381.29 217.73H381.21" stroke="black" stroke-width="0.5" />
    <path d="M381.15 217.73H381.07" stroke="black" stroke-width="0.5" />
    <path d="M381.01 217.73H380.93" stroke="black" stroke-width="0.5" />
    <path d="M380.88 217.73H380.79" stroke="black" stroke-width="0.5" />
    <path d="M380.74 217.73H380.66" stroke="black" stroke-width="0.5" />
    <path d="M380.6 217.73H380.52" stroke="black" stroke-width="0.5" />
    <path d="M380.46 217.73H380.38" stroke="black" stroke-width="0.5" />
    <path d="M380.33 217.73H380.24" stroke="black" stroke-width="0.5" />
    <path d="M380.19 217.73H380.11" stroke="black" stroke-width="0.5" />
    <path d="M380.05 217.73H379.97" stroke="black" stroke-width="0.5" />
    <path d="M379.92 217.73H379.83" stroke="black" stroke-width="0.5" />
    <path d="M379.78 217.73H379.7" stroke="black" stroke-width="0.5" />
    <path d="M379.64 217.73H379.56" stroke="black" stroke-width="0.5" />
    <path d="M379.5 217.73H379.42" stroke="black" stroke-width="0.5" />
    <path d="M379.37 217.73H379.28" stroke="black" stroke-width="0.5" />
    <path d="M379.23 217.73H379.15" stroke="black" stroke-width="0.5" />
    <path d="M379.09 217.73H379.01" stroke="black" stroke-width="0.5" />
    <path d="M378.95 217.73H378.87" stroke="black" stroke-width="0.5" />
    <path d="M378.82 217.73H378.73" stroke="black" stroke-width="0.5" />
    <path d="M378.68 217.73H378.6" stroke="black" stroke-width="0.5" />
    <path d="M378.54 217.73H378.46" stroke="black" stroke-width="0.5" />
    <path d="M378.4 217.73H378.32" stroke="black" stroke-width="0.5" />
    <path d="M378.27 217.73H378.18" stroke="black" stroke-width="0.5" />
    <path d="M378.13 217.73H378.05" stroke="black" stroke-width="0.5" />
    <path d="M377.99 217.73H377.91" stroke="black" stroke-width="0.5" />
    <path d="M377.85 217.73H377.77" stroke="black" stroke-width="0.5" />
    <path d="M377.72 217.73H377.63" stroke="black" stroke-width="0.5" />
    <path d="M377.58 217.73H377.5" stroke="black" stroke-width="0.5" />
    <path d="M377.44 217.73H377.36" stroke="black" stroke-width="0.5" />
    <path d="M377.3 217.73H377.22" stroke="black" stroke-width="0.5" />
    <path d="M377.17 217.73H377.08" stroke="black" stroke-width="0.5" />
    <path d="M377.03 217.73H376.95" stroke="black" stroke-width="0.5" />
    <path d="M376.89 217.73H376.81" stroke="black" stroke-width="0.5" />
    <path d="M376.75 217.73H376.67" stroke="black" stroke-width="0.5" />
    <path d="M376.62 217.73H376.53" stroke="black" stroke-width="0.5" />
    <path d="M376.48 217.73H376.4" stroke="black" stroke-width="0.5" />
    <path d="M376.34 217.73H376.26" stroke="black" stroke-width="0.5" />
    <path d="M376.2 217.73H376.12" stroke="black" stroke-width="0.5" />
    <path d="M376.07 217.73H375.98" stroke="black" stroke-width="0.5" />
    <path d="M375.93 217.73H375.85" stroke="black" stroke-width="0.5" />
    <path d="M375.79 217.73H375.71" stroke="black" stroke-width="0.5" />
    <path d="M375.65 217.73H375.57" stroke="black" stroke-width="0.5" />
    <path d="M375.52 217.73H375.43" stroke="black" stroke-width="0.5" />
    <path d="M375.38 217.73H375.3" stroke="black" stroke-width="0.5" />
    <path d="M375.24 217.73H375.16" stroke="black" stroke-width="0.5" />
    <path d="M375.1 217.73H375.02" stroke="black" stroke-width="0.5" />
    <path d="M374.97 217.73H374.88" stroke="black" stroke-width="0.5" />
    <path d="M374.83 217.73H374.75" stroke="black" stroke-width="0.5" />
    <path d="M374.69 217.73H374.61" stroke="black" stroke-width="0.5" />
    <path d="M374.55 217.73H374.47" stroke="black" stroke-width="0.5" />
    <path d="M374.42 217.73H374.33" stroke="black" stroke-width="0.5" />
    <path d="M374.28 217.73H374.2" stroke="black" stroke-width="0.5" />
    <path d="M374.14 217.73H374.06" stroke="black" stroke-width="0.5" />
    <path d="M374 217.73H373.92" stroke="black" stroke-width="0.5" />
    <path d="M373.87 217.73H373.78" stroke="black" stroke-width="0.5" />
    <path d="M373.73 217.73H373.65" stroke="black" stroke-width="0.5" />
    <path d="M373.59 217.73H373.51" stroke="black" stroke-width="0.5" />
    <path d="M373.46 217.73H373.37" stroke="black" stroke-width="0.5" />
    <path d="M373.32 217.73H373.24" stroke="black" stroke-width="0.5" />
    <path d="M373.18 217.73H373.1" stroke="black" stroke-width="0.5" />
    <path d="M373.04 217.73H372.96" stroke="black" stroke-width="0.5" />
    <path d="M372.91 217.73H372.82" stroke="black" stroke-width="0.5" />
    <path d="M372.77 217.73H372.69" stroke="black" stroke-width="0.5" />
    <path d="M372.63 217.73H372.55" stroke="black" stroke-width="0.5" />
    <path d="M372.49 217.73H372.41" stroke="black" stroke-width="0.5" />
    <path d="M372.36 217.73H372.27" stroke="black" stroke-width="0.5" />
    <path d="M372.22 217.73H372.14" stroke="black" stroke-width="0.5" />
    <path d="M372.08 217.73H372" stroke="black" stroke-width="0.5" />
    <path d="M371.94 217.73H371.86" stroke="black" stroke-width="0.5" />
    <path d="M371.81 217.73H371.72" stroke="black" stroke-width="0.5" />
    <path d="M371.67 217.73H371.59" stroke="black" stroke-width="0.5" />
    <path d="M371.53 217.73H371.45" stroke="black" stroke-width="0.5" />
    <path d="M371.39 217.73H371.31" stroke="black" stroke-width="0.5" />
    <path d="M371.26 217.73H371.17" stroke="black" stroke-width="0.5" />
    <path d="M371.12 217.73H371.04" stroke="black" stroke-width="0.5" />
    <path d="M370.98 217.73H370.9" stroke="black" stroke-width="0.5" />
    <path d="M370.84 217.73H370.76" stroke="black" stroke-width="0.5" />
    <path d="M370.71 217.73H370.62" stroke="black" stroke-width="0.5" />
    <path d="M370.57 217.73H370.49" stroke="black" stroke-width="0.5" />
    <path d="M370.43 217.73H370.35" stroke="black" stroke-width="0.5" />
    <path d="M370.29 217.73H370.21" stroke="black" stroke-width="0.5" />
    <path d="M370.16 217.73H370.07" stroke="black" stroke-width="0.5" />
    <path d="M370.02 217.73H369.94" stroke="black" stroke-width="0.5" />
    <path d="M369.88 217.73H369.8" stroke="black" stroke-width="0.5" />
    <path d="M369.74 217.73H369.66" stroke="black" stroke-width="0.5" />
    <path d="M369.61 217.73H369.52" stroke="black" stroke-width="0.5" />
    <path d="M369.47 217.73H369.39" stroke="black" stroke-width="0.5" />
    <path d="M369.33 217.73H369.25" stroke="black" stroke-width="0.5" />
    <path d="M369.19 217.73H369.11" stroke="black" stroke-width="0.5" />
    <path d="M369.06 217.73H368.97" stroke="black" stroke-width="0.5" />
    <path d="M368.92 217.73H368.84" stroke="black" stroke-width="0.5" />
    <path d="M368.78 217.73H368.7" stroke="black" stroke-width="0.5" />
    <path d="M368.64 217.73H368.56" stroke="black" stroke-width="0.5" />
    <path d="M368.51 217.73H368.42" stroke="black" stroke-width="0.5" />
    <path d="M368.37 217.73H368.29" stroke="black" stroke-width="0.5" />
    <path d="M368.23 217.73H368.15" stroke="black" stroke-width="0.5" />
    <path d="M368.09 217.73H368.01" stroke="black" stroke-width="0.5" />
    <path d="M367.96 217.73H367.87" stroke="black" stroke-width="0.5" />
    <path d="M367.82 217.73H367.74" stroke="black" stroke-width="0.5" />
    <path d="M367.68 217.73H367.6" stroke="black" stroke-width="0.5" />
    <path d="M367.54 217.73H367.46" stroke="black" stroke-width="0.5" />
    <path d="M367.41 217.73H367.32" stroke="black" stroke-width="0.5" />
    <path d="M367.27 217.73H367.19" stroke="black" stroke-width="0.5" />
    <path d="M367.13 217.73H367.05" stroke="black" stroke-width="0.5" />
    <path d="M367 217.73H366.91" stroke="black" stroke-width="0.5" />
    <path d="M366.86 217.73H366.78" stroke="black" stroke-width="0.5" />
    <path d="M366.72 217.73H366.64" stroke="black" stroke-width="0.5" />
    <path d="M366.58 217.73H366.5" stroke="black" stroke-width="0.5" />
    <path d="M366.45 217.73H366.36" stroke="black" stroke-width="0.5" />
    <path d="M366.31 217.73H366.23" stroke="black" stroke-width="0.5" />
    <path d="M366.17 217.73H366.12" stroke="black" stroke-width="0.5" />
    <path d="M510.95 36.89H512.71" stroke="black" stroke-width="0.5" />
    <path d="M512.71 36.89V33.37" stroke="black" stroke-width="0.5" />
    <path d="M512.71 33.37H510.95" stroke="black" stroke-width="0.5" />
    <path d="M510.95 33.37V36.89" stroke="black" stroke-width="0.5" />
    <path d="M559.53 36.89H561.29" stroke="black" stroke-width="0.5" />
    <path d="M561.29 36.89V33.37" stroke="black" stroke-width="0.5" />
    <path d="M561.29 33.37H559.53" stroke="black" stroke-width="0.5" />
    <path d="M559.53 33.37V36.89" stroke="black" stroke-width="0.5" />
    <path d="M512.71 36.89V33.37" stroke="black" stroke-width="0.5" />
    <path d="M512.71 33.37V36.89" stroke="black" stroke-width="0.5" />
    <path d="M524.15 36.89V33.37" stroke="black" stroke-width="0.5" />
    <path d="M524.15 33.37V36.89" stroke="black" stroke-width="0.5" />
    <path d="M512.71 36.89H524.15" stroke="black" stroke-width="0.5" />
    <path d="M524.15 36.89H512.71" stroke="black" stroke-width="0.5" />
    <path d="M524.15 33.37H512.71" stroke="black" stroke-width="0.5" />
    <path d="M524.15 33.37H512.71" stroke="black" stroke-width="0.5" />
    <path d="M524.15 36.89H525.91" stroke="black" stroke-width="0.5" />
    <path d="M525.91 36.89V33.37" stroke="black" stroke-width="0.5" />
    <path d="M525.91 33.37H524.15" stroke="black" stroke-width="0.5" />
    <path d="M524.15 33.37V36.89" stroke="black" stroke-width="0.5" />
    <path d="M297.61 36.85V95.36" stroke="black" stroke-width="0.5" />
    <path d="M297.61 95.36V128.12" stroke="black" stroke-width="0.5" />
    <path d="M293.32 128.12V95.36" stroke="black" stroke-width="0.5" />
    <path d="M293.32 95.36V36.85" stroke="black" stroke-width="0.5" />
    <path d="M293.32 95.36V128.12" stroke="black" stroke-width="0.5" />
    <path d="M297.61 95.36H293.32" stroke="black" stroke-width="0.5" />
    <path d="M297.61 128.12H293.32" stroke="black" stroke-width="0.5" />
    <path d="M293.32 36.85H297.61" stroke="black" stroke-width="0.5" />
    <path d="M293.32 95.36H297.61" stroke="black" stroke-width="0.5" />
    <path d="M164.23 36.91H165.99" stroke="black" stroke-width="0.5" />
    <path d="M165.99 36.91V33.39" stroke="black" stroke-width="0.5" />
    <path d="M165.99 33.39H164.23" stroke="black" stroke-width="0.5" />
    <path d="M164.23 33.39V36.91" stroke="black" stroke-width="0.5" />
    <path d="M209.98 36.91H211.74" stroke="black" stroke-width="0.5" />
    <path d="M211.74 36.91V33.39" stroke="black" stroke-width="0.5" />
    <path d="M211.74 33.39H209.98" stroke="black" stroke-width="0.5" />
    <path d="M209.98 33.39V36.91" stroke="black" stroke-width="0.5" />
    <path d="M165.99 36.91V33.39" stroke="black" stroke-width="0.5" />
    <path d="M165.99 33.39V36.91" stroke="black" stroke-width="0.5" />
    <path d="M176.55 36.91V33.39" stroke="black" stroke-width="0.5" />
    <path d="M176.55 33.39V36.91" stroke="black" stroke-width="0.5" />
    <path d="M165.99 36.91H176.55" stroke="black" stroke-width="0.5" />
    <path d="M176.55 36.91H165.99" stroke="black" stroke-width="0.5" />
    <path d="M176.55 33.39H165.99" stroke="black" stroke-width="0.5" />
    <path d="M176.55 33.39H165.99" stroke="black" stroke-width="0.5" />
    <path d="M176.55 36.91H178.31" stroke="black" stroke-width="0.5" />
    <path d="M178.31 36.91V33.39" stroke="black" stroke-width="0.5" />
    <path d="M178.31 33.39H176.55" stroke="black" stroke-width="0.5" />
    <path d="M176.55 33.39V36.91" stroke="black" stroke-width="0.5" />
    <path d="M306.08 378.16H264.73" stroke="black" stroke-width="0.5" />
    <path d="M264.73 378.16V382.45" stroke="black" stroke-width="0.5" />
    <path d="M264.73 382.45H306.08" stroke="black" stroke-width="0.5" />
    <path d="M233.07 378.16H228.67" stroke="black" stroke-width="0.5" />
    <path d="M224.38 378.16V382.45" stroke="black" stroke-width="0.5" />
    <path d="M224.38 382.45H233.07" stroke="black" stroke-width="0.5" />
    <path d="M233.07 382.45V378.16" stroke="black" stroke-width="0.5" />
    <path d="M345 380.86H345.4" stroke="black" stroke-width="0.5" />
    <path d="M322.16 382.26L321.96 381.9" stroke="black" stroke-width="0.5" />
    <path d="M332.32 378.54L332.22 378.71" stroke="black" stroke-width="0.5" />
    <path d="M345 381.9V380.31" stroke="black" stroke-width="0.5" />
    <path d="M314.77 381.9H345" stroke="black" stroke-width="0.5" />
    <path d="M345 378.71H314.77" stroke="black" stroke-width="0.5" />
    <path d="M345 380.31V378.71" stroke="black" stroke-width="0.5" />
    <path d="M504.77 240.6V237.28" stroke="black" stroke-width="0.5" />
    <path d="M507.96 240.6V237.28" stroke="black" stroke-width="0.5" />
    <path d="M504.42 268.95L504.27 268.69" stroke="black" stroke-width="0.5" />
    <path d="M504.77 262.8L504.67 262.61" stroke="black" stroke-width="0.5" />
    <path d="M504.77 268.95V240.6" stroke="black" stroke-width="0.5" />
    <path d="M507.96 268.95V240.6" stroke="black" stroke-width="0.5" />
    <path d="M220.81 441.28V442.82" stroke="black" stroke-width="0.5" />
    <path d="M220.81 442.82H220.86" stroke="black" stroke-width="0.5" />
    <path d="M220.86 442.82V441.28" stroke="black" stroke-width="0.5" />
    <path d="M220.86 441.28H220.81" stroke="black" stroke-width="0.5" />
    <path d="M220.81 455.02V456.56" stroke="black" stroke-width="0.5" />
    <path d="M220.81 456.56H220.86" stroke="black" stroke-width="0.5" />
    <path d="M220.86 456.56V455.02" stroke="black" stroke-width="0.5" />
    <path d="M220.86 455.02H220.81" stroke="black" stroke-width="0.5" />
    <path d="M220.81 455.02V442.82" stroke="black" stroke-width="0.5" />
    <path d="M220.86 455.02H220.81" stroke="black" stroke-width="0.5" />
    <path d="M220.86 442.82H220.81" stroke="black" stroke-width="0.5" />
    <path d="M220.86 442.82V455.02" stroke="black" stroke-width="0.5" />
    <path d="M220.81 442.82V444.36" stroke="black" stroke-width="0.5" />
    <path d="M220.81 444.36H220.86" stroke="black" stroke-width="0.5" />
    <path d="M220.86 444.36V442.82" stroke="black" stroke-width="0.5" />
    <path d="M220.86 442.82H220.81" stroke="black" stroke-width="0.5" />
    <path d="M220.81 453.48V455.02" stroke="black" stroke-width="0.5" />
    <path d="M220.81 455.02H220.86" stroke="black" stroke-width="0.5" />
    <path d="M220.86 455.02V453.48" stroke="black" stroke-width="0.5" />
    <path d="M220.86 453.48H220.81" stroke="black" stroke-width="0.5" />
    <path d="M220.81 453.48V444.36" stroke="black" stroke-width="0.5" />
    <path d="M220.81 444.36H220.86" stroke="black" stroke-width="0.5" />
    <path d="M220.86 453.48H220.81" stroke="black" stroke-width="0.5" />
    <path d="M220.86 444.36V453.48" stroke="black" stroke-width="0.5" />
    <path
      d="M220.81 444.4C220.087 444.911 219.496 445.588 219.089 446.375C218.682 447.161 218.469 448.034 218.469 448.92C218.469 449.806 218.682 450.679 219.089 451.465C219.496 452.252 220.087 452.929 220.81 453.44"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M220.81 453.44V453.29" stroke="black" stroke-width="0.5" />
    <path
      d="M220.81 444.54C220.121 445.043 219.561 445.701 219.175 446.462C218.789 447.222 218.588 448.062 218.588 448.915C218.588 449.768 218.789 450.608 219.175 451.368C219.561 452.129 220.121 452.787 220.81 453.29"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M220.81 444.54V444.4" stroke="black" stroke-width="0.5" />
    <path d="M220.86 442.31V442.82" stroke="black" stroke-width="0.5" />
    <path d="M220.86 442.82H224.38" stroke="black" stroke-width="0.5" />
    <path d="M224.38 442.82V442.31" stroke="black" stroke-width="0.5" />
    <path d="M224.38 442.31H220.86" stroke="black" stroke-width="0.5" />
    <path d="M220.86 455.02V455.51" stroke="black" stroke-width="0.5" />
    <path d="M220.86 455.51H224.38" stroke="black" stroke-width="0.5" />
    <path d="M224.38 455.51V455.02" stroke="black" stroke-width="0.5" />
    <path d="M224.38 455.02H220.86" stroke="black" stroke-width="0.5" />
    <path d="M220.86 455.02V442.82" stroke="black" stroke-width="0.5" />
    <path d="M694.23 498.23V439.52" stroke="black" stroke-width="0.5" />
    <path d="M694.89 498.23V437.98" stroke="black" stroke-width="0.5" />
    <path d="M672.9 439.52V498.23" stroke="black" stroke-width="0.5" />
    <path d="M672.9 498.23H694.89" stroke="black" stroke-width="0.5" />
    <path d="M694.25 530.19L694.09 529.9" stroke="black" stroke-width="0.5" />
    <path d="M686.09 532.65H694.89" stroke="black" stroke-width="0.5" />
    <path d="M694.89 529.9H686.09" stroke="black" stroke-width="0.5" />
    <path d="M694.89 532.65V530.45" stroke="black" stroke-width="0.5" />
    <path d="M694.89 530.45H686.09" stroke="black" stroke-width="0.5" />
    <path d="M694.89 530.45V529.9" stroke="black" stroke-width="0.5" />
    <path d="M466.86 164.18V112.83" stroke="black" stroke-width="0.5" />
    <path d="M466.86 108.55H462.57" stroke="black" stroke-width="0.5" />
    <path d="M462.57 108.55V159.79" stroke="black" stroke-width="0.5" />
    <path d="M462.57 164.07V164.18" stroke="black" stroke-width="0.5" />
    <path d="M462.57 164.18H466.86" stroke="black" stroke-width="0.5" />
    <path d="M462.57 159.79V160.34" stroke="black" stroke-width="0.5" />
    <path d="M462.57 160.34V163.52" stroke="black" stroke-width="0.5" />
    <path d="M462.57 163.52V164.07" stroke="black" stroke-width="0.5" />
    <path d="M433.9 163.7L433.8 163.52" stroke="black" stroke-width="0.5" />
    <path d="M320.8 160.34L320.69 160.15" stroke="black" stroke-width="0.5" />
    <path d="M408.56 160.34L408.47 160.18" stroke="black" stroke-width="0.5" />
    <path d="M314.9 160.24L314.63 159.79" stroke="black" stroke-width="0.5" />
    <path d="M305.05 160.07L304.88 159.79" stroke="black" stroke-width="0.5" />
    <path d="M330.55 160.34L330.54 160.32" stroke="black" stroke-width="0.5" />
    <path d="M325.75 160.22L325.68 160.34" stroke="black" stroke-width="0.5" />
    <path d="M308.36 159.93L308.13 160.34" stroke="black" stroke-width="0.5" />
    <path d="M462.52 159.79L462.48 159.85" stroke="black" stroke-width="0.5" />
    <path d="M390.06 160.3L390.04 160.34" stroke="black" stroke-width="0.5" />
    <path d="M321.98 159.99L321.78 160.34" stroke="black" stroke-width="0.5" />
    <path d="M413.52 160.2L413.44 160.34" stroke="black" stroke-width="0.5" />
    <path d="M310.4 159.79H310.39" stroke="black" stroke-width="0.5" />
    <path d="M462.57 164.07V163.52" stroke="black" stroke-width="0.5" />
    <path d="M299.48 163.52V164.07" stroke="black" stroke-width="0.5" />
    <path d="M369.63 161.93V163.52" stroke="black" stroke-width="0.5" />
    <path d="M369.63 163.52H462.57" stroke="black" stroke-width="0.5" />
    <path d="M462.57 163.52H463.12" stroke="black" stroke-width="0.5" />
    <path d="M462.57 160.34H463.12" stroke="black" stroke-width="0.5" />
    <path d="M462.57 160.34H377.22" stroke="black" stroke-width="0.5" />
    <path d="M462.57 160.34V159.79" stroke="black" stroke-width="0.5" />
    <path d="M299.48 159.79V160.34" stroke="black" stroke-width="0.5" />
    <path d="M336.87 160.34H299.48" stroke="black" stroke-width="0.5" />
    <path d="M369.63 160.34V161.93" stroke="black" stroke-width="0.5" />
    <path d="M374.03 160.34H369.63" stroke="black" stroke-width="0.5" />
    <path d="M639.69 440.62H596.59" stroke="black" stroke-width="0.5" />
    <path d="M596.59 440.62H639.69" stroke="black" stroke-width="0.5" />
    <path d="M638.81 440.62H597.47" stroke="black" stroke-width="0.5" />
    <path d="M597.47 440.62H638.81" stroke="black" stroke-width="0.5" />
    <path d="M639.69 439.52H596.59" stroke="black" stroke-width="0.5" />
    <path d="M596.59 439.52H639.69" stroke="black" stroke-width="0.5" />
    <path d="M639.69 440.62V439.52" stroke="black" stroke-width="0.5" />
    <path d="M596.59 439.52V440.62" stroke="black" stroke-width="0.5" />
    <path d="M596.59 439.52V440.62" stroke="black" stroke-width="0.5" />
    <path d="M639.69 439.52V440.62" stroke="black" stroke-width="0.5" />
    <path d="M694.01 498.23H693.95" stroke="black" stroke-width="0.5" />
    <path d="M693.9 498.23H693.79" stroke="black" stroke-width="0.5" />
    <path d="M693.73 498.23H693.62" stroke="black" stroke-width="0.5" />
    <path d="M693.57 498.23H693.46" stroke="black" stroke-width="0.5" />
    <path d="M693.4 498.23H693.29" stroke="black" stroke-width="0.5" />
    <path d="M693.24 498.23H693.13" stroke="black" stroke-width="0.5" />
    <path d="M693.07 498.23H692.96" stroke="black" stroke-width="0.5" />
    <path d="M692.91 498.23H692.8" stroke="black" stroke-width="0.5" />
    <path d="M692.74 498.23H692.63" stroke="black" stroke-width="0.5" />
    <path d="M692.58 498.23H692.47" stroke="black" stroke-width="0.5" />
    <path d="M692.41 498.23H692.3" stroke="black" stroke-width="0.5" />
    <path d="M692.25 498.23H692.14" stroke="black" stroke-width="0.5" />
    <path d="M692.08 498.23H691.97" stroke="black" stroke-width="0.5" />
    <path d="M691.92 498.23H691.81" stroke="black" stroke-width="0.5" />
    <path d="M691.75 498.23H691.64" stroke="black" stroke-width="0.5" />
    <path d="M691.59 498.23H691.48" stroke="black" stroke-width="0.5" />
    <path d="M691.42 498.23H691.31" stroke="black" stroke-width="0.5" />
    <path d="M691.26 498.23H691.15" stroke="black" stroke-width="0.5" />
    <path d="M691.09 498.23H690.98" stroke="black" stroke-width="0.5" />
    <path d="M690.93 498.23H690.82" stroke="black" stroke-width="0.5" />
    <path d="M690.76 498.23H690.65" stroke="black" stroke-width="0.5" />
    <path d="M690.6 498.23H690.49" stroke="black" stroke-width="0.5" />
    <path d="M690.43 498.23H690.32" stroke="black" stroke-width="0.5" />
    <path d="M690.27 498.23H690.16" stroke="black" stroke-width="0.5" />
    <path d="M690.11 498.23H690" stroke="black" stroke-width="0.5" />
    <path d="M689.94 498.23H689.83" stroke="black" stroke-width="0.5" />
    <path d="M689.78 498.23H689.67" stroke="black" stroke-width="0.5" />
    <path d="M689.61 498.23H689.5" stroke="black" stroke-width="0.5" />
    <path d="M689.45 498.23H689.34" stroke="black" stroke-width="0.5" />
    <path d="M689.28 498.23H689.17" stroke="black" stroke-width="0.5" />
    <path d="M689.12 498.23H689.01" stroke="black" stroke-width="0.5" />
    <path d="M688.95 498.23H688.84" stroke="black" stroke-width="0.5" />
    <path d="M688.79 498.23H688.68" stroke="black" stroke-width="0.5" />
    <path d="M688.62 498.23H688.51" stroke="black" stroke-width="0.5" />
    <path d="M688.46 498.23H688.35" stroke="black" stroke-width="0.5" />
    <path d="M688.29 498.23H688.18" stroke="black" stroke-width="0.5" />
    <path d="M688.13 498.23H688.02" stroke="black" stroke-width="0.5" />
    <path d="M687.96 498.23H687.85" stroke="black" stroke-width="0.5" />
    <path d="M687.8 498.23H687.69" stroke="black" stroke-width="0.5" />
    <path d="M687.63 498.23H687.52" stroke="black" stroke-width="0.5" />
    <path d="M687.47 498.23H687.36" stroke="black" stroke-width="0.5" />
    <path d="M687.3 498.23H687.19" stroke="black" stroke-width="0.5" />
    <path d="M687.14 498.23H687.03" stroke="black" stroke-width="0.5" />
    <path d="M686.97 498.23H686.86" stroke="black" stroke-width="0.5" />
    <path d="M686.81 498.23H686.7" stroke="black" stroke-width="0.5" />
    <path d="M686.64 498.23H686.53" stroke="black" stroke-width="0.5" />
    <path d="M686.48 498.23H686.37" stroke="black" stroke-width="0.5" />
    <path d="M686.31 498.23H686.2" stroke="black" stroke-width="0.5" />
    <path d="M686.15 498.23H686.04" stroke="black" stroke-width="0.5" />
    <path d="M685.98 498.23H685.87" stroke="black" stroke-width="0.5" />
    <path d="M685.82 498.23H685.71" stroke="black" stroke-width="0.5" />
    <path d="M685.65 498.23H685.54" stroke="black" stroke-width="0.5" />
    <path d="M685.49 498.23H685.38" stroke="black" stroke-width="0.5" />
    <path d="M685.32 498.23H685.21" stroke="black" stroke-width="0.5" />
    <path d="M685.16 498.23H685.05" stroke="black" stroke-width="0.5" />
    <path d="M684.99 498.23H684.88" stroke="black" stroke-width="0.5" />
    <path d="M684.83 498.23H684.72" stroke="black" stroke-width="0.5" />
    <path d="M684.66 498.23H684.55" stroke="black" stroke-width="0.5" />
    <path d="M684.5 498.23H684.39" stroke="black" stroke-width="0.5" />
    <path d="M684.33 498.23H684.22" stroke="black" stroke-width="0.5" />
    <path d="M684.17 498.23H684.06" stroke="black" stroke-width="0.5" />
    <path d="M684 498.23H683.89" stroke="black" stroke-width="0.5" />
    <path d="M683.84 498.23H683.73" stroke="black" stroke-width="0.5" />
    <path d="M683.67 498.23H683.56" stroke="black" stroke-width="0.5" />
    <path d="M683.51 498.23H683.4" stroke="black" stroke-width="0.5" />
    <path d="M683.34 498.23H683.23" stroke="black" stroke-width="0.5" />
    <path d="M683.18 498.23H683.07" stroke="black" stroke-width="0.5" />
    <path d="M683.01 498.23H682.9" stroke="black" stroke-width="0.5" />
    <path d="M682.85 498.23H682.74" stroke="black" stroke-width="0.5" />
    <path d="M682.68 498.23H682.57" stroke="black" stroke-width="0.5" />
    <path d="M682.52 498.23H682.41" stroke="black" stroke-width="0.5" />
    <path d="M682.35 498.23H682.24" stroke="black" stroke-width="0.5" />
    <path d="M682.19 498.23H682.08" stroke="black" stroke-width="0.5" />
    <path d="M682.02 498.23H681.91" stroke="black" stroke-width="0.5" />
    <path d="M681.86 498.23H681.75" stroke="black" stroke-width="0.5" />
    <path d="M681.69 498.23H681.58" stroke="black" stroke-width="0.5" />
    <path d="M681.53 498.23H681.42" stroke="black" stroke-width="0.5" />
    <path d="M681.36 498.23H681.25" stroke="black" stroke-width="0.5" />
    <path d="M681.2 498.23H681.09" stroke="black" stroke-width="0.5" />
    <path d="M681.03 498.23H680.92" stroke="black" stroke-width="0.5" />
    <path d="M680.87 498.23H680.76" stroke="black" stroke-width="0.5" />
    <path d="M680.7 498.23H680.59" stroke="black" stroke-width="0.5" />
    <path d="M680.54 498.23H680.43" stroke="black" stroke-width="0.5" />
    <path d="M680.37 498.23H680.26" stroke="black" stroke-width="0.5" />
    <path d="M680.21 498.23H680.1" stroke="black" stroke-width="0.5" />
    <path d="M680.04 498.23H679.93" stroke="black" stroke-width="0.5" />
    <path d="M679.88 498.23H679.77" stroke="black" stroke-width="0.5" />
    <path d="M679.71 498.23H679.6" stroke="black" stroke-width="0.5" />
    <path d="M679.55 498.23H679.44" stroke="black" stroke-width="0.5" />
    <path d="M679.38 498.23H679.27" stroke="black" stroke-width="0.5" />
    <path d="M679.22 498.23H679.11" stroke="black" stroke-width="0.5" />
    <path d="M679.05 498.23H678.94" stroke="black" stroke-width="0.5" />
    <path d="M678.89 498.23H678.78" stroke="black" stroke-width="0.5" />
    <path d="M678.72 498.23H678.61" stroke="black" stroke-width="0.5" />
    <path d="M678.56 498.23H678.45" stroke="black" stroke-width="0.5" />
    <path d="M678.39 498.23H678.28" stroke="black" stroke-width="0.5" />
    <path d="M678.23 498.23H678.12" stroke="black" stroke-width="0.5" />
    <path d="M678.06 498.23H677.95" stroke="black" stroke-width="0.5" />
    <path d="M677.9 498.23H677.79" stroke="black" stroke-width="0.5" />
    <path d="M677.73 498.23H677.62" stroke="black" stroke-width="0.5" />
    <path d="M677.57 498.23H677.46" stroke="black" stroke-width="0.5" />
    <path d="M677.4 498.23H677.29" stroke="black" stroke-width="0.5" />
    <path d="M677.24 498.23H677.13" stroke="black" stroke-width="0.5" />
    <path d="M677.08 498.23H676.97" stroke="black" stroke-width="0.5" />
    <path d="M676.91 498.23H676.8" stroke="black" stroke-width="0.5" />
    <path d="M676.75 498.23H676.64" stroke="black" stroke-width="0.5" />
    <path d="M676.58 498.23H676.47" stroke="black" stroke-width="0.5" />
    <path d="M676.42 498.23H676.31" stroke="black" stroke-width="0.5" />
    <path d="M676.25 498.23H676.14" stroke="black" stroke-width="0.5" />
    <path d="M676.09 498.23H675.98" stroke="black" stroke-width="0.5" />
    <path d="M675.92 498.23H675.81" stroke="black" stroke-width="0.5" />
    <path d="M675.76 498.23H675.65" stroke="black" stroke-width="0.5" />
    <path d="M675.59 498.23H675.48" stroke="black" stroke-width="0.5" />
    <path d="M675.43 498.23H675.32" stroke="black" stroke-width="0.5" />
    <path d="M675.26 498.23H675.15" stroke="black" stroke-width="0.5" />
    <path d="M675.1 498.23H674.99" stroke="black" stroke-width="0.5" />
    <path d="M674.93 498.23H674.82" stroke="black" stroke-width="0.5" />
    <path d="M674.77 498.23H674.66" stroke="black" stroke-width="0.5" />
    <path d="M674.6 498.23H674.49" stroke="black" stroke-width="0.5" />
    <path d="M674.44 498.23H674.33" stroke="black" stroke-width="0.5" />
    <path d="M674.27 498.23H674.16" stroke="black" stroke-width="0.5" />
    <path d="M674.11 498.23H674" stroke="black" stroke-width="0.5" />
    <path d="M673.94 498.23H673.83" stroke="black" stroke-width="0.5" />
    <path d="M673.78 498.23H673.67" stroke="black" stroke-width="0.5" />
    <path d="M673.61 498.23H673.5" stroke="black" stroke-width="0.5" />
    <path d="M673.45 498.23H673.34" stroke="black" stroke-width="0.5" />
    <path d="M673.28 498.23H673.17" stroke="black" stroke-width="0.5" />
    <path d="M673.12 498.23H673.01" stroke="black" stroke-width="0.5" />
    <path d="M672.95 498.23H672.9" stroke="black" stroke-width="0.5" />
    <path d="M672.9 498.23V498.18" stroke="black" stroke-width="0.5" />
    <path d="M672.9 498.12V498.01" stroke="black" stroke-width="0.5" />
    <path d="M672.9 497.96V497.85" stroke="black" stroke-width="0.5" />
    <path d="M672.9 497.79V497.68" stroke="black" stroke-width="0.5" />
    <path d="M672.9 497.63V497.52" stroke="black" stroke-width="0.5" />
    <path d="M672.9 497.46V497.35" stroke="black" stroke-width="0.5" />
    <path d="M672.9 497.3V497.19" stroke="black" stroke-width="0.5" />
    <path d="M672.9 497.13V497.02" stroke="black" stroke-width="0.5" />
    <path d="M672.9 496.97V496.86" stroke="black" stroke-width="0.5" />
    <path d="M672.9 496.8V496.7" stroke="black" stroke-width="0.5" />
    <path d="M672.9 496.64V496.53" stroke="black" stroke-width="0.5" />
    <path d="M672.9 496.48V496.37" stroke="black" stroke-width="0.5" />
    <path d="M672.9 496.31V496.2" stroke="black" stroke-width="0.5" />
    <path d="M672.9 496.15V496.04" stroke="black" stroke-width="0.5" />
    <path d="M672.9 495.98V495.87" stroke="black" stroke-width="0.5" />
    <path d="M672.9 495.82V495.71" stroke="black" stroke-width="0.5" />
    <path d="M672.9 495.65V495.54" stroke="black" stroke-width="0.5" />
    <path d="M672.9 495.49V495.38" stroke="black" stroke-width="0.5" />
    <path d="M672.9 495.32V495.21" stroke="black" stroke-width="0.5" />
    <path d="M672.9 495.16V495.05" stroke="black" stroke-width="0.5" />
    <path d="M672.9 494.99V494.88" stroke="black" stroke-width="0.5" />
    <path d="M672.9 494.83V494.72" stroke="black" stroke-width="0.5" />
    <path d="M672.9 494.66V494.55" stroke="black" stroke-width="0.5" />
    <path d="M672.9 494.5V494.39" stroke="black" stroke-width="0.5" />
    <path d="M672.9 494.33V494.22" stroke="black" stroke-width="0.5" />
    <path d="M672.9 494.17V494.06" stroke="black" stroke-width="0.5" />
    <path d="M672.9 494V493.89" stroke="black" stroke-width="0.5" />
    <path d="M672.9 493.84V493.73" stroke="black" stroke-width="0.5" />
    <path d="M672.9 493.67V493.56" stroke="black" stroke-width="0.5" />
    <path d="M672.9 493.51V493.4" stroke="black" stroke-width="0.5" />
    <path d="M672.9 493.34V493.23" stroke="black" stroke-width="0.5" />
    <path d="M672.9 493.18V493.07" stroke="black" stroke-width="0.5" />
    <path d="M672.9 493.01V492.9" stroke="black" stroke-width="0.5" />
    <path d="M672.9 492.85V492.74" stroke="black" stroke-width="0.5" />
    <path d="M672.9 492.68V492.57" stroke="black" stroke-width="0.5" />
    <path d="M672.9 492.52V492.41" stroke="black" stroke-width="0.5" />
    <path d="M672.9 492.35V492.24" stroke="black" stroke-width="0.5" />
    <path d="M672.9 492.19V492.08" stroke="black" stroke-width="0.5" />
    <path d="M672.9 492.02V491.91" stroke="black" stroke-width="0.5" />
    <path d="M672.9 491.86V491.75" stroke="black" stroke-width="0.5" />
    <path d="M672.9 491.69V491.58" stroke="black" stroke-width="0.5" />
    <path d="M672.9 491.53V491.42" stroke="black" stroke-width="0.5" />
    <path d="M672.9 491.36V491.25" stroke="black" stroke-width="0.5" />
    <path d="M672.9 491.2V491.09" stroke="black" stroke-width="0.5" />
    <path d="M672.9 491.03V490.92" stroke="black" stroke-width="0.5" />
    <path d="M672.9 490.87V490.76" stroke="black" stroke-width="0.5" />
    <path d="M672.9 490.7V490.59" stroke="black" stroke-width="0.5" />
    <path d="M672.9 490.54V490.43" stroke="black" stroke-width="0.5" />
    <path d="M672.9 490.37V490.26" stroke="black" stroke-width="0.5" />
    <path d="M672.9 490.21V490.1" stroke="black" stroke-width="0.5" />
    <path d="M672.9 490.04V489.93" stroke="black" stroke-width="0.5" />
    <path d="M672.9 489.88V489.77" stroke="black" stroke-width="0.5" />
    <path d="M672.9 489.71V489.6" stroke="black" stroke-width="0.5" />
    <path d="M672.9 489.55V489.44" stroke="black" stroke-width="0.5" />
    <path d="M672.9 489.38V489.27" stroke="black" stroke-width="0.5" />
    <path d="M672.9 489.22V489.11" stroke="black" stroke-width="0.5" />
    <path d="M672.9 489.05V488.94" stroke="black" stroke-width="0.5" />
    <path d="M672.9 488.89V488.78" stroke="black" stroke-width="0.5" />
    <path d="M672.9 488.72V488.61" stroke="black" stroke-width="0.5" />
    <path d="M672.9 488.56V488.45" stroke="black" stroke-width="0.5" />
    <path d="M672.9 488.39V488.28" stroke="black" stroke-width="0.5" />
    <path d="M672.9 488.23V488.12" stroke="black" stroke-width="0.5" />
    <path d="M672.9 488.06V487.95" stroke="black" stroke-width="0.5" />
    <path d="M672.9 487.9V487.79" stroke="black" stroke-width="0.5" />
    <path d="M672.9 487.73V487.62" stroke="black" stroke-width="0.5" />
    <path d="M672.9 487.57V487.46" stroke="black" stroke-width="0.5" />
    <path d="M672.9 487.4V487.29" stroke="black" stroke-width="0.5" />
    <path d="M672.9 487.24V487.13" stroke="black" stroke-width="0.5" />
    <path d="M672.9 487.07V486.96" stroke="black" stroke-width="0.5" />
    <path d="M672.9 486.91V486.8" stroke="black" stroke-width="0.5" />
    <path d="M672.9 486.74V486.63" stroke="black" stroke-width="0.5" />
    <path d="M672.9 486.58V486.47" stroke="black" stroke-width="0.5" />
    <path d="M672.9 486.41V486.3" stroke="black" stroke-width="0.5" />
    <path d="M672.9 486.25V486.14" stroke="black" stroke-width="0.5" />
    <path d="M672.9 486.08V485.97" stroke="black" stroke-width="0.5" />
    <path d="M672.9 485.92V485.81" stroke="black" stroke-width="0.5" />
    <path d="M672.9 485.75V485.64" stroke="black" stroke-width="0.5" />
    <path d="M672.9 485.59V485.48" stroke="black" stroke-width="0.5" />
    <path d="M672.9 485.42V485.31" stroke="black" stroke-width="0.5" />
    <path d="M672.9 485.26V485.15" stroke="black" stroke-width="0.5" />
    <path d="M672.9 485.09V484.98" stroke="black" stroke-width="0.5" />
    <path d="M672.9 484.93V484.82" stroke="black" stroke-width="0.5" />
    <path d="M672.9 484.76V484.65" stroke="black" stroke-width="0.5" />
    <path d="M672.9 484.6V484.49" stroke="black" stroke-width="0.5" />
    <path d="M672.9 484.43V484.32" stroke="black" stroke-width="0.5" />
    <path d="M672.9 484.27V484.16" stroke="black" stroke-width="0.5" />
    <path d="M672.9 484.1V483.99" stroke="black" stroke-width="0.5" />
    <path d="M672.9 483.94V483.83" stroke="black" stroke-width="0.5" />
    <path d="M672.9 483.78V483.67" stroke="black" stroke-width="0.5" />
    <path d="M672.9 483.61V483.56" stroke="black" stroke-width="0.5" />
    <path d="M672.9 468.88V468.93" stroke="black" stroke-width="0.5" />
    <path d="M672.9 468.99V469.1" stroke="black" stroke-width="0.5" />
    <path d="M672.9 469.15V469.26" stroke="black" stroke-width="0.5" />
    <path d="M672.9 469.32V469.43" stroke="black" stroke-width="0.5" />
    <path d="M672.9 469.48V469.59" stroke="black" stroke-width="0.5" />
    <path d="M672.9 469.65V469.76" stroke="black" stroke-width="0.5" />
    <path d="M672.9 469.81V469.92" stroke="black" stroke-width="0.5" />
    <path d="M672.9 469.98V470.09" stroke="black" stroke-width="0.5" />
    <path d="M672.9 470.14V470.25" stroke="black" stroke-width="0.5" />
    <path d="M672.9 470.31V470.42" stroke="black" stroke-width="0.5" />
    <path d="M672.9 470.47V470.58" stroke="black" stroke-width="0.5" />
    <path d="M672.9 470.64V470.75" stroke="black" stroke-width="0.5" />
    <path d="M672.9 470.8V470.91" stroke="black" stroke-width="0.5" />
    <path d="M672.9 470.96V471.07" stroke="black" stroke-width="0.5" />
    <path d="M672.9 471.13V471.24" stroke="black" stroke-width="0.5" />
    <path d="M672.9 471.29V471.4" stroke="black" stroke-width="0.5" />
    <path d="M672.9 471.46V471.57" stroke="black" stroke-width="0.5" />
    <path d="M672.9 471.62V471.73" stroke="black" stroke-width="0.5" />
    <path d="M672.9 471.79V471.9" stroke="black" stroke-width="0.5" />
    <path d="M672.9 471.95V472.06" stroke="black" stroke-width="0.5" />
    <path d="M672.9 472.12V472.23" stroke="black" stroke-width="0.5" />
    <path d="M672.9 472.28V472.39" stroke="black" stroke-width="0.5" />
    <path d="M672.9 472.45V472.56" stroke="black" stroke-width="0.5" />
    <path d="M672.9 472.61V472.72" stroke="black" stroke-width="0.5" />
    <path d="M672.9 472.78V472.89" stroke="black" stroke-width="0.5" />
    <path d="M672.9 472.94V473.05" stroke="black" stroke-width="0.5" />
    <path d="M672.9 473.11V473.22" stroke="black" stroke-width="0.5" />
    <path d="M672.9 473.27V473.38" stroke="black" stroke-width="0.5" />
    <path d="M672.9 473.44V473.55" stroke="black" stroke-width="0.5" />
    <path d="M672.9 473.6V473.71" stroke="black" stroke-width="0.5" />
    <path d="M672.9 473.77V473.88" stroke="black" stroke-width="0.5" />
    <path d="M672.9 473.93V474.04" stroke="black" stroke-width="0.5" />
    <path d="M672.9 474.1V474.21" stroke="black" stroke-width="0.5" />
    <path d="M672.9 474.26V474.37" stroke="black" stroke-width="0.5" />
    <path d="M672.9 474.43V474.54" stroke="black" stroke-width="0.5" />
    <path d="M672.9 474.59V474.7" stroke="black" stroke-width="0.5" />
    <path d="M672.9 474.76V474.87" stroke="black" stroke-width="0.5" />
    <path d="M672.9 474.92V475.03" stroke="black" stroke-width="0.5" />
    <path d="M672.9 475.09V475.2" stroke="black" stroke-width="0.5" />
    <path d="M672.9 475.25V475.36" stroke="black" stroke-width="0.5" />
    <path d="M672.9 475.42V475.53" stroke="black" stroke-width="0.5" />
    <path d="M672.9 475.58V475.69" stroke="black" stroke-width="0.5" />
    <path d="M672.9 475.75V475.86" stroke="black" stroke-width="0.5" />
    <path d="M672.9 475.91V476.02" stroke="black" stroke-width="0.5" />
    <path d="M672.9 476.08V476.19" stroke="black" stroke-width="0.5" />
    <path d="M672.9 476.24V476.35" stroke="black" stroke-width="0.5" />
    <path d="M672.9 476.41V476.52" stroke="black" stroke-width="0.5" />
    <path d="M672.9 476.57V476.68" stroke="black" stroke-width="0.5" />
    <path d="M672.9 476.74V476.85" stroke="black" stroke-width="0.5" />
    <path d="M672.9 476.9V477.01" stroke="black" stroke-width="0.5" />
    <path d="M672.9 477.07V477.18" stroke="black" stroke-width="0.5" />
    <path d="M672.9 477.23V477.34" stroke="black" stroke-width="0.5" />
    <path d="M672.9 477.4V477.51" stroke="black" stroke-width="0.5" />
    <path d="M672.9 477.56V477.67" stroke="black" stroke-width="0.5" />
    <path d="M672.9 477.73V477.84" stroke="black" stroke-width="0.5" />
    <path d="M672.9 477.89V478" stroke="black" stroke-width="0.5" />
    <path d="M672.9 478.06V478.17" stroke="black" stroke-width="0.5" />
    <path d="M672.9 478.22V478.33" stroke="black" stroke-width="0.5" />
    <path d="M672.9 478.39V478.5" stroke="black" stroke-width="0.5" />
    <path d="M672.9 478.55V478.66" stroke="black" stroke-width="0.5" />
    <path d="M672.9 478.72V478.83" stroke="black" stroke-width="0.5" />
    <path d="M672.9 478.88V478.99" stroke="black" stroke-width="0.5" />
    <path d="M672.9 479.05V479.16" stroke="black" stroke-width="0.5" />
    <path d="M672.9 479.21V479.32" stroke="black" stroke-width="0.5" />
    <path d="M672.9 479.38V479.49" stroke="black" stroke-width="0.5" />
    <path d="M672.9 479.54V479.65" stroke="black" stroke-width="0.5" />
    <path d="M672.9 479.71V479.82" stroke="black" stroke-width="0.5" />
    <path d="M672.9 479.87V479.98" stroke="black" stroke-width="0.5" />
    <path d="M672.9 480.04V480.15" stroke="black" stroke-width="0.5" />
    <path d="M672.9 480.2V480.31" stroke="black" stroke-width="0.5" />
    <path d="M672.9 480.37V480.48" stroke="black" stroke-width="0.5" />
    <path d="M672.9 480.53V480.64" stroke="black" stroke-width="0.5" />
    <path d="M672.9 480.7V480.81" stroke="black" stroke-width="0.5" />
    <path d="M672.9 480.86V480.97" stroke="black" stroke-width="0.5" />
    <path d="M672.9 481.03V481.14" stroke="black" stroke-width="0.5" />
    <path d="M672.9 481.19V481.3" stroke="black" stroke-width="0.5" />
    <path d="M672.9 481.36V481.47" stroke="black" stroke-width="0.5" />
    <path d="M672.9 481.52V481.63" stroke="black" stroke-width="0.5" />
    <path d="M672.9 481.69V481.8" stroke="black" stroke-width="0.5" />
    <path d="M672.9 481.85V481.96" stroke="black" stroke-width="0.5" />
    <path d="M672.9 482.02V482.13" stroke="black" stroke-width="0.5" />
    <path d="M672.9 482.18V482.29" stroke="black" stroke-width="0.5" />
    <path d="M672.9 482.35V482.46" stroke="black" stroke-width="0.5" />
    <path d="M672.9 482.51V482.62" stroke="black" stroke-width="0.5" />
    <path d="M672.9 482.68V482.79" stroke="black" stroke-width="0.5" />
    <path d="M672.9 482.84V482.95" stroke="black" stroke-width="0.5" />
    <path d="M672.9 483.01V483.12" stroke="black" stroke-width="0.5" />
    <path d="M672.9 483.17V483.28" stroke="black" stroke-width="0.5" />
    <path d="M672.9 483.34V483.45" stroke="black" stroke-width="0.5" />
    <path d="M672.9 483.5V483.56" stroke="black" stroke-width="0.5" />
    <path d="M672.9 468.88V468.82" stroke="black" stroke-width="0.5" />
    <path d="M672.9 468.77V468.66" stroke="black" stroke-width="0.5" />
    <path d="M672.9 468.6V468.49" stroke="black" stroke-width="0.5" />
    <path d="M672.9 468.44V468.33" stroke="black" stroke-width="0.5" />
    <path d="M672.9 468.27V468.16" stroke="black" stroke-width="0.5" />
    <path d="M672.9 468.11V468" stroke="black" stroke-width="0.5" />
    <path d="M672.9 467.94V467.83" stroke="black" stroke-width="0.5" />
    <path d="M672.9 467.78V467.67" stroke="black" stroke-width="0.5" />
    <path d="M672.9 467.61V467.5" stroke="black" stroke-width="0.5" />
    <path d="M672.9 467.45V467.34" stroke="black" stroke-width="0.5" />
    <path d="M672.9 467.28V467.17" stroke="black" stroke-width="0.5" />
    <path d="M672.9 467.12V467.01" stroke="black" stroke-width="0.5" />
    <path d="M672.9 466.95V466.84" stroke="black" stroke-width="0.5" />
    <path d="M672.9 466.79V466.68" stroke="black" stroke-width="0.5" />
    <path d="M672.9 466.62V466.51" stroke="black" stroke-width="0.5" />
    <path d="M672.9 466.46V466.35" stroke="black" stroke-width="0.5" />
    <path d="M672.9 466.29V466.18" stroke="black" stroke-width="0.5" />
    <path d="M672.9 466.13V466.02" stroke="black" stroke-width="0.5" />
    <path d="M672.9 465.96V465.85" stroke="black" stroke-width="0.5" />
    <path d="M672.9 465.8V465.69" stroke="black" stroke-width="0.5" />
    <path d="M672.9 465.63V465.52" stroke="black" stroke-width="0.5" />
    <path d="M672.9 465.47V465.36" stroke="black" stroke-width="0.5" />
    <path d="M672.9 465.3V465.19" stroke="black" stroke-width="0.5" />
    <path d="M672.9 465.14V465.03" stroke="black" stroke-width="0.5" />
    <path d="M672.9 464.97V464.86" stroke="black" stroke-width="0.5" />
    <path d="M672.9 464.81V464.7" stroke="black" stroke-width="0.5" />
    <path d="M672.9 464.64V464.53" stroke="black" stroke-width="0.5" />
    <path d="M672.9 464.48V464.37" stroke="black" stroke-width="0.5" />
    <path d="M672.9 464.31V464.2" stroke="black" stroke-width="0.5" />
    <path d="M672.9 464.15V464.04" stroke="black" stroke-width="0.5" />
    <path d="M672.9 463.98V463.87" stroke="black" stroke-width="0.5" />
    <path d="M672.9 463.82V463.71" stroke="black" stroke-width="0.5" />
    <path d="M672.9 463.65V463.54" stroke="black" stroke-width="0.5" />
    <path d="M672.9 463.49V463.38" stroke="black" stroke-width="0.5" />
    <path d="M672.9 463.32V463.21" stroke="black" stroke-width="0.5" />
    <path d="M672.9 463.16V463.05" stroke="black" stroke-width="0.5" />
    <path d="M672.9 462.99V462.88" stroke="black" stroke-width="0.5" />
    <path d="M672.9 462.83V462.72" stroke="black" stroke-width="0.5" />
    <path d="M672.9 462.66V462.55" stroke="black" stroke-width="0.5" />
    <path d="M672.9 462.5V462.39" stroke="black" stroke-width="0.5" />
    <path d="M672.9 462.33V462.22" stroke="black" stroke-width="0.5" />
    <path d="M672.9 462.17V462.06" stroke="black" stroke-width="0.5" />
    <path d="M672.9 462V461.89" stroke="black" stroke-width="0.5" />
    <path d="M672.9 461.84V461.73" stroke="black" stroke-width="0.5" />
    <path d="M672.9 461.67V461.56" stroke="black" stroke-width="0.5" />
    <path d="M672.9 461.51V461.4" stroke="black" stroke-width="0.5" />
    <path d="M672.9 461.34V461.23" stroke="black" stroke-width="0.5" />
    <path d="M672.9 461.18V461.07" stroke="black" stroke-width="0.5" />
    <path d="M672.9 461.01V460.9" stroke="black" stroke-width="0.5" />
    <path d="M672.9 460.85V460.74" stroke="black" stroke-width="0.5" />
    <path d="M672.9 460.68V460.57" stroke="black" stroke-width="0.5" />
    <path d="M672.9 460.52V460.41" stroke="black" stroke-width="0.5" />
    <path d="M672.9 460.35V460.24" stroke="black" stroke-width="0.5" />
    <path d="M672.9 460.19V460.08" stroke="black" stroke-width="0.5" />
    <path d="M672.9 460.02V459.91" stroke="black" stroke-width="0.5" />
    <path d="M672.9 459.86V459.75" stroke="black" stroke-width="0.5" />
    <path d="M672.9 459.69V459.58" stroke="black" stroke-width="0.5" />
    <path d="M672.9 459.53V459.42" stroke="black" stroke-width="0.5" />
    <path d="M672.9 459.36V459.25" stroke="black" stroke-width="0.5" />
    <path d="M672.9 459.2V459.09" stroke="black" stroke-width="0.5" />
    <path d="M672.9 459.03V458.92" stroke="black" stroke-width="0.5" />
    <path d="M672.9 458.87V458.76" stroke="black" stroke-width="0.5" />
    <path d="M672.9 458.7V458.59" stroke="black" stroke-width="0.5" />
    <path d="M672.9 458.54V458.43" stroke="black" stroke-width="0.5" />
    <path d="M672.9 458.37V458.26" stroke="black" stroke-width="0.5" />
    <path d="M672.9 458.21V458.1" stroke="black" stroke-width="0.5" />
    <path d="M672.9 458.04V457.94" stroke="black" stroke-width="0.5" />
    <path d="M672.9 457.88V457.77" stroke="black" stroke-width="0.5" />
    <path d="M672.9 457.72V457.61" stroke="black" stroke-width="0.5" />
    <path d="M672.9 457.55V457.44" stroke="black" stroke-width="0.5" />
    <path d="M672.9 457.39V457.28" stroke="black" stroke-width="0.5" />
    <path d="M672.9 457.22V457.11" stroke="black" stroke-width="0.5" />
    <path d="M672.9 457.06V456.95" stroke="black" stroke-width="0.5" />
    <path d="M672.9 456.89V456.78" stroke="black" stroke-width="0.5" />
    <path d="M672.9 456.73V456.62" stroke="black" stroke-width="0.5" />
    <path d="M672.9 456.56V456.45" stroke="black" stroke-width="0.5" />
    <path d="M672.9 456.4V456.29" stroke="black" stroke-width="0.5" />
    <path d="M672.9 456.23V456.12" stroke="black" stroke-width="0.5" />
    <path d="M672.9 456.07V455.96" stroke="black" stroke-width="0.5" />
    <path d="M672.9 455.9V455.79" stroke="black" stroke-width="0.5" />
    <path d="M672.9 455.74V455.63" stroke="black" stroke-width="0.5" />
    <path d="M672.9 455.57V455.46" stroke="black" stroke-width="0.5" />
    <path d="M672.9 455.41V455.3" stroke="black" stroke-width="0.5" />
    <path d="M672.9 455.24V455.13" stroke="black" stroke-width="0.5" />
    <path d="M672.9 455.08V454.97" stroke="black" stroke-width="0.5" />
    <path d="M672.9 454.91V454.8" stroke="black" stroke-width="0.5" />
    <path d="M672.9 454.75V454.64" stroke="black" stroke-width="0.5" />
    <path d="M672.9 454.58V454.47" stroke="black" stroke-width="0.5" />
    <path d="M672.9 454.42V454.31" stroke="black" stroke-width="0.5" />
    <path d="M672.9 454.25V454.2" stroke="black" stroke-width="0.5" />
    <path d="M672.9 439.52V439.57" stroke="black" stroke-width="0.5" />
    <path d="M672.9 439.63V439.74" stroke="black" stroke-width="0.5" />
    <path d="M672.9 439.79V439.9" stroke="black" stroke-width="0.5" />
    <path d="M672.9 439.96V440.07" stroke="black" stroke-width="0.5" />
    <path d="M672.9 440.12V440.23" stroke="black" stroke-width="0.5" />
    <path d="M672.9 440.29V440.4" stroke="black" stroke-width="0.5" />
    <path d="M672.9 440.45V440.56" stroke="black" stroke-width="0.5" />
    <path d="M672.9 440.62V440.73" stroke="black" stroke-width="0.5" />
    <path d="M672.9 440.78V440.89" stroke="black" stroke-width="0.5" />
    <path d="M672.9 440.95V441.06" stroke="black" stroke-width="0.5" />
    <path d="M672.9 441.11V441.22" stroke="black" stroke-width="0.5" />
    <path d="M672.9 441.28V441.39" stroke="black" stroke-width="0.5" />
    <path d="M672.9 441.44V441.55" stroke="black" stroke-width="0.5" />
    <path d="M672.9 441.61V441.72" stroke="black" stroke-width="0.5" />
    <path d="M672.9 441.77V441.88" stroke="black" stroke-width="0.5" />
    <path d="M672.9 441.94V442.05" stroke="black" stroke-width="0.5" />
    <path d="M672.9 442.1V442.21" stroke="black" stroke-width="0.5" />
    <path d="M672.9 442.27V442.38" stroke="black" stroke-width="0.5" />
    <path d="M672.9 442.43V442.54" stroke="black" stroke-width="0.5" />
    <path d="M672.9 442.6V442.71" stroke="black" stroke-width="0.5" />
    <path d="M672.9 442.76V442.87" stroke="black" stroke-width="0.5" />
    <path d="M672.9 442.93V443.04" stroke="black" stroke-width="0.5" />
    <path d="M672.9 443.09V443.2" stroke="black" stroke-width="0.5" />
    <path d="M672.9 443.26V443.37" stroke="black" stroke-width="0.5" />
    <path d="M672.9 443.42V443.53" stroke="black" stroke-width="0.5" />
    <path d="M672.9 443.59V443.7" stroke="black" stroke-width="0.5" />
    <path d="M672.9 443.75V443.86" stroke="black" stroke-width="0.5" />
    <path d="M672.9 443.92V444.03" stroke="black" stroke-width="0.5" />
    <path d="M672.9 444.08V444.19" stroke="black" stroke-width="0.5" />
    <path d="M672.9 444.25V444.36" stroke="black" stroke-width="0.5" />
    <path d="M672.9 444.41V444.52" stroke="black" stroke-width="0.5" />
    <path d="M672.9 444.58V444.69" stroke="black" stroke-width="0.5" />
    <path d="M672.9 444.74V444.85" stroke="black" stroke-width="0.5" />
    <path d="M672.9 444.91V445.02" stroke="black" stroke-width="0.5" />
    <path d="M672.9 445.07V445.18" stroke="black" stroke-width="0.5" />
    <path d="M672.9 445.23V445.34" stroke="black" stroke-width="0.5" />
    <path d="M672.9 445.4V445.51" stroke="black" stroke-width="0.5" />
    <path d="M672.9 445.56V445.67" stroke="black" stroke-width="0.5" />
    <path d="M672.9 445.73V445.84" stroke="black" stroke-width="0.5" />
    <path d="M672.9 445.89V446" stroke="black" stroke-width="0.5" />
    <path d="M672.9 446.06V446.17" stroke="black" stroke-width="0.5" />
    <path d="M672.9 446.22V446.33" stroke="black" stroke-width="0.5" />
    <path d="M672.9 446.39V446.5" stroke="black" stroke-width="0.5" />
    <path d="M672.9 446.55V446.66" stroke="black" stroke-width="0.5" />
    <path d="M672.9 446.72V446.83" stroke="black" stroke-width="0.5" />
    <path d="M672.9 446.88V446.99" stroke="black" stroke-width="0.5" />
    <path d="M672.9 447.05V447.16" stroke="black" stroke-width="0.5" />
    <path d="M672.9 447.21V447.32" stroke="black" stroke-width="0.5" />
    <path d="M672.9 447.38V447.49" stroke="black" stroke-width="0.5" />
    <path d="M672.9 447.54V447.65" stroke="black" stroke-width="0.5" />
    <path d="M672.9 447.71V447.82" stroke="black" stroke-width="0.5" />
    <path d="M672.9 447.87V447.98" stroke="black" stroke-width="0.5" />
    <path d="M672.9 448.04V448.15" stroke="black" stroke-width="0.5" />
    <path d="M672.9 448.2V448.31" stroke="black" stroke-width="0.5" />
    <path d="M672.9 448.37V448.48" stroke="black" stroke-width="0.5" />
    <path d="M672.9 448.53V448.64" stroke="black" stroke-width="0.5" />
    <path d="M672.9 448.7V448.81" stroke="black" stroke-width="0.5" />
    <path d="M672.9 448.86V448.97" stroke="black" stroke-width="0.5" />
    <path d="M672.9 449.03V449.14" stroke="black" stroke-width="0.5" />
    <path d="M672.9 449.19V449.3" stroke="black" stroke-width="0.5" />
    <path d="M672.9 449.36V449.47" stroke="black" stroke-width="0.5" />
    <path d="M672.9 449.52V449.63" stroke="black" stroke-width="0.5" />
    <path d="M672.9 449.69V449.8" stroke="black" stroke-width="0.5" />
    <path d="M672.9 449.85V449.96" stroke="black" stroke-width="0.5" />
    <path d="M672.9 450.02V450.13" stroke="black" stroke-width="0.5" />
    <path d="M672.9 450.18V450.29" stroke="black" stroke-width="0.5" />
    <path d="M672.9 450.35V450.46" stroke="black" stroke-width="0.5" />
    <path d="M672.9 450.51V450.62" stroke="black" stroke-width="0.5" />
    <path d="M672.9 450.68V450.79" stroke="black" stroke-width="0.5" />
    <path d="M672.9 450.84V450.95" stroke="black" stroke-width="0.5" />
    <path d="M672.9 451.01V451.12" stroke="black" stroke-width="0.5" />
    <path d="M672.9 451.17V451.28" stroke="black" stroke-width="0.5" />
    <path d="M672.9 451.34V451.45" stroke="black" stroke-width="0.5" />
    <path d="M672.9 451.5V451.61" stroke="black" stroke-width="0.5" />
    <path d="M672.9 451.67V451.78" stroke="black" stroke-width="0.5" />
    <path d="M672.9 451.83V451.94" stroke="black" stroke-width="0.5" />
    <path d="M672.9 452V452.11" stroke="black" stroke-width="0.5" />
    <path d="M672.9 452.16V452.27" stroke="black" stroke-width="0.5" />
    <path d="M672.9 452.33V452.44" stroke="black" stroke-width="0.5" />
    <path d="M672.9 452.49V452.6" stroke="black" stroke-width="0.5" />
    <path d="M672.9 452.66V452.77" stroke="black" stroke-width="0.5" />
    <path d="M672.9 452.82V452.93" stroke="black" stroke-width="0.5" />
    <path d="M672.9 452.99V453.1" stroke="black" stroke-width="0.5" />
    <path d="M672.9 453.15V453.26" stroke="black" stroke-width="0.5" />
    <path d="M672.9 453.32V453.43" stroke="black" stroke-width="0.5" />
    <path d="M672.9 453.48V453.59" stroke="black" stroke-width="0.5" />
    <path d="M672.9 453.65V453.76" stroke="black" stroke-width="0.5" />
    <path d="M672.9 453.81V453.92" stroke="black" stroke-width="0.5" />
    <path d="M672.9 453.98V454.09" stroke="black" stroke-width="0.5" />
    <path d="M672.9 454.14V454.2" stroke="black" stroke-width="0.5" />
    <path d="M693.13 528.36V499.77" stroke="black" stroke-width="0.5" />
    <path d="M693.13 499.77H665.2" stroke="black" stroke-width="0.5" />
    <path d="M665.2 499.77V528.36" stroke="black" stroke-width="0.5" />
    <path d="M665.2 528.36H693.13" stroke="black" stroke-width="0.5" />
    <path d="M691.48 493.78H675.87" stroke="black" stroke-width="0.5" />
    <path d="M691.48 495.32V475.64" stroke="black" stroke-width="0.5" />
    <path d="M691.48 475.64H674.99" stroke="black" stroke-width="0.5" />
    <path d="M674.99 475.64V497.3" stroke="black" stroke-width="0.5" />
    <path d="M674.99 497.3H691.48" stroke="black" stroke-width="0.5" />
    <path d="M691.48 497.3V495.54" stroke="black" stroke-width="0.5" />
    <path d="M691.48 495.54H691.7" stroke="black" stroke-width="0.5" />
    <path d="M691.7 495.54V495.32" stroke="black" stroke-width="0.5" />
    <path d="M691.7 495.32H691.48" stroke="black" stroke-width="0.5" />
    <path d="M691.48 475.64H674.99" stroke="black" stroke-width="0.5" />
    <path d="M506.75 65.42V49.59" stroke="black" stroke-width="0.5" />
    <path d="M510.71 49.59V65.42" stroke="black" stroke-width="0.5" />
    <path d="M510.71 65.42V49.59" stroke="black" stroke-width="0.5" />
    <path d="M506.75 49.59H510.71" stroke="black" stroke-width="0.5" />
    <path d="M510.71 49.59H510.27" stroke="black" stroke-width="0.5" />
    <path d="M510.71 65.42H510.27" stroke="black" stroke-width="0.5" />
    <path d="M510.71 65.42H506.75" stroke="black" stroke-width="0.5" />
    <path d="M510.71 63.66V51.34" stroke="black" stroke-width="0.5" />
    <path d="M510.71 51.34V63.66" stroke="black" stroke-width="0.5" />
    <path d="M541.94 44.31V44.33" stroke="black" stroke-width="0.5" />
    <path d="M541.94 44.39V44.44" stroke="black" stroke-width="0.5" />
    <path d="M541.94 44.5V44.55" stroke="black" stroke-width="0.5" />
    <path d="M541.94 44.61V44.66" stroke="black" stroke-width="0.5" />
    <path d="M541.94 44.72V44.77" stroke="black" stroke-width="0.5" />
    <path d="M541.94 44.83V44.88" stroke="black" stroke-width="0.5" />
    <path d="M541.94 44.94V44.99" stroke="black" stroke-width="0.5" />
    <path d="M541.94 45.05V45.1" stroke="black" stroke-width="0.5" />
    <path d="M541.94 45.16V45.21" stroke="black" stroke-width="0.5" />
    <path d="M541.94 45.27V45.32" stroke="black" stroke-width="0.5" />
    <path d="M541.94 45.38V45.43" stroke="black" stroke-width="0.5" />
    <path d="M541.94 45.49V45.54" stroke="black" stroke-width="0.5" />
    <path d="M541.94 45.6V45.65" stroke="black" stroke-width="0.5" />
    <path d="M541.94 45.71V45.76" stroke="black" stroke-width="0.5" />
    <path d="M541.94 45.82V45.87" stroke="black" stroke-width="0.5" />
    <path d="M541.94 45.93V45.98" stroke="black" stroke-width="0.5" />
    <path d="M541.94 46.04V46.09" stroke="black" stroke-width="0.5" />
    <path d="M541.94 46.15V46.2" stroke="black" stroke-width="0.5" />
    <path d="M541.94 46.26V46.31" stroke="black" stroke-width="0.5" />
    <path d="M541.94 46.37V46.42" stroke="black" stroke-width="0.5" />
    <path d="M541.94 46.48V46.53" stroke="black" stroke-width="0.5" />
    <path d="M541.94 46.59V46.64" stroke="black" stroke-width="0.5" />
    <path d="M541.94 46.7V46.75" stroke="black" stroke-width="0.5" />
    <path d="M541.94 46.81V46.86" stroke="black" stroke-width="0.5" />
    <path d="M541.94 46.92V46.97" stroke="black" stroke-width="0.5" />
    <path d="M541.94 47.03V47.08" stroke="black" stroke-width="0.5" />
    <path d="M541.94 47.14V47.19" stroke="black" stroke-width="0.5" />
    <path d="M541.94 47.25V47.3" stroke="black" stroke-width="0.5" />
    <path d="M541.94 47.36V47.41" stroke="black" stroke-width="0.5" />
    <path d="M541.94 47.47V47.52" stroke="black" stroke-width="0.5" />
    <path d="M541.94 47.58V47.63" stroke="black" stroke-width="0.5" />
    <path d="M541.94 47.69V47.74" stroke="black" stroke-width="0.5" />
    <path d="M541.94 47.8V47.85" stroke="black" stroke-width="0.5" />
    <path d="M541.94 47.91V47.96" stroke="black" stroke-width="0.5" />
    <path d="M541.94 48.02V48.07" stroke="black" stroke-width="0.5" />
    <path d="M541.94 48.13V48.18" stroke="black" stroke-width="0.5" />
    <path d="M541.94 48.24V48.29" stroke="black" stroke-width="0.5" />
    <path d="M541.94 48.35V48.4" stroke="black" stroke-width="0.5" />
    <path d="M541.94 48.46V48.51" stroke="black" stroke-width="0.5" />
    <path d="M541.94 48.57V48.62" stroke="black" stroke-width="0.5" />
    <path d="M541.94 48.68V48.73" stroke="black" stroke-width="0.5" />
    <path d="M541.94 48.79V48.84" stroke="black" stroke-width="0.5" />
    <path d="M541.94 48.9V48.95" stroke="black" stroke-width="0.5" />
    <path d="M541.94 49.01V49.06" stroke="black" stroke-width="0.5" />
    <path d="M541.94 49.12V49.17" stroke="black" stroke-width="0.5" />
    <path d="M541.94 49.23V49.28" stroke="black" stroke-width="0.5" />
    <path d="M541.94 49.34V49.39" stroke="black" stroke-width="0.5" />
    <path d="M541.94 49.45V49.5" stroke="black" stroke-width="0.5" />
    <path d="M541.94 49.56V49.61" stroke="black" stroke-width="0.5" />
    <path d="M541.94 49.67V49.72" stroke="black" stroke-width="0.5" />
    <path d="M541.94 49.78V49.83" stroke="black" stroke-width="0.5" />
    <path d="M541.94 49.89V49.94" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50V50.05" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50.11V50.16" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50.22V50.27" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50.33V50.38" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50.44V50.49" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50.55V50.6" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50.66V50.71" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50.77V50.82" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50.88V50.93" stroke="black" stroke-width="0.5" />
    <path d="M541.94 50.99V51.04" stroke="black" stroke-width="0.5" />
    <path d="M541.94 51.1V51.15" stroke="black" stroke-width="0.5" />
    <path d="M541.94 51.21V51.26" stroke="black" stroke-width="0.5" />
    <path d="M541.94 51.32V51.37" stroke="black" stroke-width="0.5" />
    <path d="M541.94 51.43V51.48" stroke="black" stroke-width="0.5" />
    <path d="M541.94 51.54V51.59" stroke="black" stroke-width="0.5" />
    <path d="M541.94 51.65V51.7" stroke="black" stroke-width="0.5" />
    <path d="M541.94 51.76V51.81" stroke="black" stroke-width="0.5" />
    <path d="M541.94 51.87V51.92" stroke="black" stroke-width="0.5" />
    <path d="M541.94 51.98V52.03" stroke="black" stroke-width="0.5" />
    <path d="M541.94 52.09V52.14" stroke="black" stroke-width="0.5" />
    <path d="M541.94 52.2V52.25" stroke="black" stroke-width="0.5" />
    <path d="M541.94 52.31V52.36" stroke="black" stroke-width="0.5" />
    <path d="M541.94 52.42V52.47" stroke="black" stroke-width="0.5" />
    <path d="M541.94 52.53V52.58" stroke="black" stroke-width="0.5" />
    <path d="M541.94 52.64V52.69" stroke="black" stroke-width="0.5" />
    <path d="M541.94 52.75V52.8" stroke="black" stroke-width="0.5" />
    <path d="M541.94 52.86V52.91" stroke="black" stroke-width="0.5" />
    <path d="M541.94 52.97V53.02" stroke="black" stroke-width="0.5" />
    <path d="M541.94 53.08V53.13" stroke="black" stroke-width="0.5" />
    <path d="M541.94 53.19V53.24" stroke="black" stroke-width="0.5" />
    <path d="M541.94 53.3V53.35" stroke="black" stroke-width="0.5" />
    <path d="M541.94 53.41V53.46" stroke="black" stroke-width="0.5" />
    <path d="M541.94 53.52V53.57" stroke="black" stroke-width="0.5" />
    <path d="M541.94 53.63V53.68" stroke="black" stroke-width="0.5" />
    <path d="M541.94 53.74V53.79" stroke="black" stroke-width="0.5" />
    <path d="M541.94 53.85V53.9" stroke="black" stroke-width="0.5" />
    <path d="M541.94 53.96V54.01" stroke="black" stroke-width="0.5" />
    <path d="M541.94 54.07V54.12" stroke="black" stroke-width="0.5" />
    <path d="M541.94 54.18V54.23" stroke="black" stroke-width="0.5" />
    <path d="M541.94 54.29V54.34" stroke="black" stroke-width="0.5" />
    <path d="M541.94 54.4V54.45" stroke="black" stroke-width="0.5" />
    <path d="M541.94 54.51V54.56" stroke="black" stroke-width="0.5" />
    <path d="M541.94 54.62V54.67" stroke="black" stroke-width="0.5" />
    <path d="M541.94 54.73V54.78" stroke="black" stroke-width="0.5" />
    <path d="M541.94 54.84V54.89" stroke="black" stroke-width="0.5" />
    <path d="M541.94 54.95V55" stroke="black" stroke-width="0.5" />
    <path d="M541.94 55.06V55.11" stroke="black" stroke-width="0.5" />
    <path d="M541.94 55.17V55.22" stroke="black" stroke-width="0.5" />
    <path d="M541.94 55.28V55.33" stroke="black" stroke-width="0.5" />
    <path d="M541.94 55.39V55.44" stroke="black" stroke-width="0.5" />
    <path d="M541.94 55.5V55.55" stroke="black" stroke-width="0.5" />
    <path d="M541.94 55.61V55.66" stroke="black" stroke-width="0.5" />
    <path d="M541.94 55.72V55.77" stroke="black" stroke-width="0.5" />
    <path d="M541.94 55.83V55.88" stroke="black" stroke-width="0.5" />
    <path d="M541.94 55.94V55.99" stroke="black" stroke-width="0.5" />
    <path d="M541.94 56.05V56.1" stroke="black" stroke-width="0.5" />
    <path d="M541.94 56.16V56.21" stroke="black" stroke-width="0.5" />
    <path d="M541.94 56.27V56.32" stroke="black" stroke-width="0.5" />
    <path d="M541.94 56.38V56.43" stroke="black" stroke-width="0.5" />
    <path d="M541.94 56.49V56.54" stroke="black" stroke-width="0.5" />
    <path d="M541.94 56.6V56.65" stroke="black" stroke-width="0.5" />
    <path d="M541.94 56.7V56.76" stroke="black" stroke-width="0.5" />
    <path d="M541.94 56.81V56.87" stroke="black" stroke-width="0.5" />
    <path d="M541.94 56.92V56.98" stroke="black" stroke-width="0.5" />
    <path d="M541.94 57.03V57.09" stroke="black" stroke-width="0.5" />
    <path d="M541.94 57.14V57.2" stroke="black" stroke-width="0.5" />
    <path d="M541.94 57.25V57.31" stroke="black" stroke-width="0.5" />
    <path d="M541.94 57.36V57.42" stroke="black" stroke-width="0.5" />
    <path d="M541.94 57.47V57.53" stroke="black" stroke-width="0.5" />
    <path d="M541.94 57.58V57.64" stroke="black" stroke-width="0.5" />
    <path d="M541.94 57.69V57.75" stroke="black" stroke-width="0.5" />
    <path d="M541.94 57.8V57.86" stroke="black" stroke-width="0.5" />
    <path d="M541.94 57.91V57.97" stroke="black" stroke-width="0.5" />
    <path d="M541.94 58.02V58.08" stroke="black" stroke-width="0.5" />
    <path d="M541.94 58.13V58.19" stroke="black" stroke-width="0.5" />
    <path d="M541.94 58.24V58.3" stroke="black" stroke-width="0.5" />
    <path d="M541.94 58.35V58.41" stroke="black" stroke-width="0.5" />
    <path d="M541.94 58.46V58.52" stroke="black" stroke-width="0.5" />
    <path d="M541.94 58.57V58.63" stroke="black" stroke-width="0.5" />
    <path d="M541.94 58.68V58.74" stroke="black" stroke-width="0.5" />
    <path d="M541.94 58.79V58.85" stroke="black" stroke-width="0.5" />
    <path d="M541.94 58.9V58.96" stroke="black" stroke-width="0.5" />
    <path d="M541.94 59.01V59.07" stroke="black" stroke-width="0.5" />
    <path d="M541.94 59.12V59.18" stroke="black" stroke-width="0.5" />
    <path d="M541.94 59.23V59.29" stroke="black" stroke-width="0.5" />
    <path d="M541.94 59.34V59.4" stroke="black" stroke-width="0.5" />
    <path d="M541.94 59.45V59.51" stroke="black" stroke-width="0.5" />
    <path d="M541.94 59.56V59.62" stroke="black" stroke-width="0.5" />
    <path d="M541.94 59.67V59.73" stroke="black" stroke-width="0.5" />
    <path d="M541.94 59.78V59.84" stroke="black" stroke-width="0.5" />
    <path d="M541.94 59.89V59.95" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60V60.06" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60.11V60.17" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60.22V60.28" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60.33V60.39" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60.44V60.5" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60.55V60.61" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60.66V60.72" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60.77V60.83" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60.88V60.94" stroke="black" stroke-width="0.5" />
    <path d="M541.94 60.99V61.05" stroke="black" stroke-width="0.5" />
    <path d="M541.94 61.1V61.16" stroke="black" stroke-width="0.5" />
    <path d="M541.94 61.21V61.27" stroke="black" stroke-width="0.5" />
    <path d="M541.94 61.32V61.38" stroke="black" stroke-width="0.5" />
    <path d="M541.94 61.43V61.49" stroke="black" stroke-width="0.5" />
    <path d="M541.94 61.54V61.6" stroke="black" stroke-width="0.5" />
    <path d="M541.94 61.65V61.71" stroke="black" stroke-width="0.5" />
    <path d="M541.94 61.76V61.82" stroke="black" stroke-width="0.5" />
    <path d="M541.94 61.87V61.93" stroke="black" stroke-width="0.5" />
    <path d="M541.94 61.98V62.04" stroke="black" stroke-width="0.5" />
    <path d="M541.94 62.09V62.15" stroke="black" stroke-width="0.5" />
    <path d="M541.94 62.2V62.26" stroke="black" stroke-width="0.5" />
    <path d="M541.94 62.31V62.37" stroke="black" stroke-width="0.5" />
    <path d="M541.94 62.42V62.48" stroke="black" stroke-width="0.5" />
    <path d="M541.94 62.53V62.59" stroke="black" stroke-width="0.5" />
    <path d="M541.94 62.64V62.7" stroke="black" stroke-width="0.5" />
    <path d="M541.94 62.75V62.81" stroke="black" stroke-width="0.5" />
    <path d="M541.94 62.86V62.92" stroke="black" stroke-width="0.5" />
    <path d="M541.94 62.97V63.03" stroke="black" stroke-width="0.5" />
    <path d="M541.94 63.08V63.14" stroke="black" stroke-width="0.5" />
    <path d="M541.94 63.19V63.25" stroke="black" stroke-width="0.5" />
    <path d="M541.94 63.3V63.36" stroke="black" stroke-width="0.5" />
    <path d="M541.94 63.41V63.47" stroke="black" stroke-width="0.5" />
    <path d="M541.94 63.52V63.58" stroke="black" stroke-width="0.5" />
    <path d="M541.94 63.63V63.69" stroke="black" stroke-width="0.5" />
    <path d="M541.94 63.74V63.8" stroke="black" stroke-width="0.5" />
    <path d="M541.94 63.85V63.91" stroke="black" stroke-width="0.5" />
    <path d="M541.94 63.96V64.02" stroke="black" stroke-width="0.5" />
    <path d="M541.94 64.07V64.13" stroke="black" stroke-width="0.5" />
    <path d="M541.94 64.18V64.24" stroke="black" stroke-width="0.5" />
    <path d="M541.94 64.29V64.35" stroke="black" stroke-width="0.5" />
    <path d="M541.94 64.4V64.46" stroke="black" stroke-width="0.5" />
    <path d="M541.94 64.51V64.57" stroke="black" stroke-width="0.5" />
    <path d="M541.94 64.62V64.68" stroke="black" stroke-width="0.5" />
    <path d="M541.94 64.73V64.79" stroke="black" stroke-width="0.5" />
    <path d="M541.94 64.84V64.9" stroke="black" stroke-width="0.5" />
    <path d="M541.94 64.95V65.01" stroke="black" stroke-width="0.5" />
    <path d="M541.94 65.06V65.12" stroke="black" stroke-width="0.5" />
    <path d="M541.94 65.17V65.23" stroke="black" stroke-width="0.5" />
    <path d="M541.94 65.28V65.34" stroke="black" stroke-width="0.5" />
    <path d="M541.94 65.39V65.45" stroke="black" stroke-width="0.5" />
    <path d="M541.94 65.5V65.56" stroke="black" stroke-width="0.5" />
    <path d="M541.94 65.61V65.67" stroke="black" stroke-width="0.5" />
    <path d="M541.94 65.72V65.78" stroke="black" stroke-width="0.5" />
    <path d="M541.94 65.83V65.89" stroke="black" stroke-width="0.5" />
    <path d="M541.94 65.94V66" stroke="black" stroke-width="0.5" />
    <path d="M541.94 66.05V66.11" stroke="black" stroke-width="0.5" />
    <path d="M541.94 66.16V66.22" stroke="black" stroke-width="0.5" />
    <path d="M541.94 66.27V66.33" stroke="black" stroke-width="0.5" />
    <path d="M541.94 66.38V66.44" stroke="black" stroke-width="0.5" />
    <path d="M541.94 66.49V66.55" stroke="black" stroke-width="0.5" />
    <path d="M541.94 66.6V66.66" stroke="black" stroke-width="0.5" />
    <path d="M541.94 66.71V66.77" stroke="black" stroke-width="0.5" />
    <path d="M541.94 66.82V66.88" stroke="black" stroke-width="0.5" />
    <path d="M541.94 66.93V66.99" stroke="black" stroke-width="0.5" />
    <path d="M541.94 67.04V67.1" stroke="black" stroke-width="0.5" />
    <path d="M541.94 67.15V67.21" stroke="black" stroke-width="0.5" />
    <path d="M541.94 67.26V67.32" stroke="black" stroke-width="0.5" />
    <path d="M541.94 67.37V67.43" stroke="black" stroke-width="0.5" />
    <path d="M541.94 67.48V67.54" stroke="black" stroke-width="0.5" />
    <path d="M541.94 67.59V67.65" stroke="black" stroke-width="0.5" />
    <path d="M541.94 67.7V67.76" stroke="black" stroke-width="0.5" />
    <path d="M541.94 67.81V67.87" stroke="black" stroke-width="0.5" />
    <path d="M541.94 67.92V67.98" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.03V68.09" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.14V68.2" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.25V68.31" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.36V68.42" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.47V68.53" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.58V68.64" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.69V68.75" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.8V68.86" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.91V68.97" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.02V69.08" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.13V69.19" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.24V69.3" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.35V69.41" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.46V69.52" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.57V69.62" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.68V69.73" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.79V69.84" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.9V69.95" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.01V70.06" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.12V70.17" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.23V70.28" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.34V70.39" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.45V70.5" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.56V70.61" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.67V70.7" stroke="black" stroke-width="0.5" />
    <path d="M506.75 49.59V65.42" stroke="black" stroke-width="0.5" />
    <path d="M506.75 65.42H510.27" stroke="black" stroke-width="0.5" />
    <path d="M510.27 65.42V49.59" stroke="black" stroke-width="0.5" />
    <path d="M510.27 49.59H506.75" stroke="black" stroke-width="0.5" />
    <path d="M541.94 44.31H541.91" stroke="black" stroke-width="0.5" />
    <path d="M541.85 44.31H541.8" stroke="black" stroke-width="0.5" />
    <path d="M541.74 44.31H541.69" stroke="black" stroke-width="0.5" />
    <path d="M541.63 44.31H541.58" stroke="black" stroke-width="0.5" />
    <path d="M541.52 44.31H541.47" stroke="black" stroke-width="0.5" />
    <path d="M541.42 44.31H541.36" stroke="black" stroke-width="0.5" />
    <path d="M541.31 44.31H541.25" stroke="black" stroke-width="0.5" />
    <path d="M541.2 44.31H541.14" stroke="black" stroke-width="0.5" />
    <path d="M541.09 44.31H541.03" stroke="black" stroke-width="0.5" />
    <path d="M540.98 44.31H540.92" stroke="black" stroke-width="0.5" />
    <path d="M540.87 44.31H540.81" stroke="black" stroke-width="0.5" />
    <path d="M540.76 44.31H540.7" stroke="black" stroke-width="0.5" />
    <path d="M540.65 44.31H540.59" stroke="black" stroke-width="0.5" />
    <path d="M540.54 44.31H540.48" stroke="black" stroke-width="0.5" />
    <path d="M540.43 44.31H540.37" stroke="black" stroke-width="0.5" />
    <path d="M540.32 44.31H540.26" stroke="black" stroke-width="0.5" />
    <path d="M540.21 44.31H540.15" stroke="black" stroke-width="0.5" />
    <path d="M540.1 44.31H540.04" stroke="black" stroke-width="0.5" />
    <path d="M539.99 44.31H539.93" stroke="black" stroke-width="0.5" />
    <path d="M539.88 44.31H539.82" stroke="black" stroke-width="0.5" />
    <path d="M539.77 44.31H539.71" stroke="black" stroke-width="0.5" />
    <path d="M539.66 44.31H539.6" stroke="black" stroke-width="0.5" />
    <path d="M539.55 44.31H539.49" stroke="black" stroke-width="0.5" />
    <path d="M539.44 44.31H539.38" stroke="black" stroke-width="0.5" />
    <path d="M539.33 44.31H539.27" stroke="black" stroke-width="0.5" />
    <path d="M539.22 44.31H539.16" stroke="black" stroke-width="0.5" />
    <path d="M539.11 44.31H539.05" stroke="black" stroke-width="0.5" />
    <path d="M539 44.31H538.94" stroke="black" stroke-width="0.5" />
    <path d="M538.89 44.31H538.83" stroke="black" stroke-width="0.5" />
    <path d="M538.78 44.31H538.72" stroke="black" stroke-width="0.5" />
    <path d="M538.67 44.31H538.61" stroke="black" stroke-width="0.5" />
    <path d="M538.56 44.31H538.5" stroke="black" stroke-width="0.5" />
    <path d="M538.45 44.31H538.39" stroke="black" stroke-width="0.5" />
    <path d="M538.34 44.31H538.28" stroke="black" stroke-width="0.5" />
    <path d="M538.23 44.31H538.17" stroke="black" stroke-width="0.5" />
    <path d="M538.12 44.31H538.06" stroke="black" stroke-width="0.5" />
    <path d="M538.01 44.31H537.95" stroke="black" stroke-width="0.5" />
    <path d="M537.9 44.31H537.84" stroke="black" stroke-width="0.5" />
    <path d="M537.79 44.31H537.73" stroke="black" stroke-width="0.5" />
    <path d="M537.68 44.31H537.62" stroke="black" stroke-width="0.5" />
    <path d="M537.57 44.31H537.51" stroke="black" stroke-width="0.5" />
    <path d="M537.46 44.31H537.4" stroke="black" stroke-width="0.5" />
    <path d="M537.35 44.31H537.29" stroke="black" stroke-width="0.5" />
    <path d="M537.24 44.31H537.18" stroke="black" stroke-width="0.5" />
    <path d="M537.13 44.31H537.07" stroke="black" stroke-width="0.5" />
    <path d="M537.02 44.31H536.96" stroke="black" stroke-width="0.5" />
    <path d="M536.91 44.31H536.85" stroke="black" stroke-width="0.5" />
    <path d="M536.8 44.31H536.74" stroke="black" stroke-width="0.5" />
    <path d="M536.69 44.31H536.63" stroke="black" stroke-width="0.5" />
    <path d="M536.58 44.31H536.52" stroke="black" stroke-width="0.5" />
    <path d="M536.47 44.31H536.41" stroke="black" stroke-width="0.5" />
    <path d="M536.36 44.31H536.3" stroke="black" stroke-width="0.5" />
    <path d="M536.25 44.31H536.19" stroke="black" stroke-width="0.5" />
    <path d="M536.14 44.31H536.08" stroke="black" stroke-width="0.5" />
    <path d="M536.03 44.31H535.97" stroke="black" stroke-width="0.5" />
    <path d="M535.92 44.31H535.86" stroke="black" stroke-width="0.5" />
    <path d="M535.81 44.31H535.75" stroke="black" stroke-width="0.5" />
    <path d="M535.7 44.31H535.64" stroke="black" stroke-width="0.5" />
    <path d="M535.59 44.31H535.53" stroke="black" stroke-width="0.5" />
    <path d="M535.48 44.31H535.42" stroke="black" stroke-width="0.5" />
    <path d="M535.37 44.31H535.31" stroke="black" stroke-width="0.5" />
    <path d="M535.26 44.31H535.2" stroke="black" stroke-width="0.5" />
    <path d="M535.15 44.31H535.09" stroke="black" stroke-width="0.5" />
    <path d="M535.04 44.31H534.98" stroke="black" stroke-width="0.5" />
    <path d="M534.93 44.31H534.87" stroke="black" stroke-width="0.5" />
    <path d="M534.82 44.31H534.76" stroke="black" stroke-width="0.5" />
    <path d="M534.71 44.31H534.65" stroke="black" stroke-width="0.5" />
    <path d="M534.6 44.31H534.54" stroke="black" stroke-width="0.5" />
    <path d="M534.49 44.31H534.43" stroke="black" stroke-width="0.5" />
    <path d="M534.38 44.31H534.32" stroke="black" stroke-width="0.5" />
    <path d="M534.27 44.31H534.21" stroke="black" stroke-width="0.5" />
    <path d="M534.16 44.31H534.1" stroke="black" stroke-width="0.5" />
    <path d="M534.05 44.31H533.99" stroke="black" stroke-width="0.5" />
    <path d="M533.94 44.31H533.88" stroke="black" stroke-width="0.5" />
    <path d="M533.83 44.31H533.77" stroke="black" stroke-width="0.5" />
    <path d="M533.72 44.31H533.66" stroke="black" stroke-width="0.5" />
    <path d="M533.61 44.31H533.55" stroke="black" stroke-width="0.5" />
    <path d="M533.5 44.31H533.44" stroke="black" stroke-width="0.5" />
    <path d="M533.39 44.31H533.33" stroke="black" stroke-width="0.5" />
    <path d="M533.28 44.31H533.22" stroke="black" stroke-width="0.5" />
    <path d="M533.17 44.31H533.11" stroke="black" stroke-width="0.5" />
    <path d="M533.06 44.31H533" stroke="black" stroke-width="0.5" />
    <path d="M532.95 44.31H532.89" stroke="black" stroke-width="0.5" />
    <path d="M532.84 44.31H532.78" stroke="black" stroke-width="0.5" />
    <path d="M532.73 44.31H532.67" stroke="black" stroke-width="0.5" />
    <path d="M532.62 44.31H532.56" stroke="black" stroke-width="0.5" />
    <path d="M532.51 44.31H532.45" stroke="black" stroke-width="0.5" />
    <path d="M532.4 44.31H532.34" stroke="black" stroke-width="0.5" />
    <path d="M532.29 44.31H532.23" stroke="black" stroke-width="0.5" />
    <path d="M532.18 44.31H532.12" stroke="black" stroke-width="0.5" />
    <path d="M532.07 44.31H532.01" stroke="black" stroke-width="0.5" />
    <path d="M531.96 44.31H531.9" stroke="black" stroke-width="0.5" />
    <path d="M531.85 44.31H531.79" stroke="black" stroke-width="0.5" />
    <path d="M531.74 44.31H531.68" stroke="black" stroke-width="0.5" />
    <path d="M531.63 44.31H531.57" stroke="black" stroke-width="0.5" />
    <path d="M531.52 44.31H531.46" stroke="black" stroke-width="0.5" />
    <path d="M531.41 44.31H531.35" stroke="black" stroke-width="0.5" />
    <path d="M531.3 44.31H531.24" stroke="black" stroke-width="0.5" />
    <path d="M531.19 44.31H531.13" stroke="black" stroke-width="0.5" />
    <path d="M531.08 44.31H531.02" stroke="black" stroke-width="0.5" />
    <path d="M530.97 44.31H530.91" stroke="black" stroke-width="0.5" />
    <path d="M530.86 44.31H530.8" stroke="black" stroke-width="0.5" />
    <path d="M530.75 44.31H530.69" stroke="black" stroke-width="0.5" />
    <path d="M530.64 44.31H530.58" stroke="black" stroke-width="0.5" />
    <path d="M530.53 44.31H530.47" stroke="black" stroke-width="0.5" />
    <path d="M530.42 44.31H530.36" stroke="black" stroke-width="0.5" />
    <path d="M530.31 44.31H530.25" stroke="black" stroke-width="0.5" />
    <path d="M530.2 44.31H530.14" stroke="black" stroke-width="0.5" />
    <path d="M530.09 44.31H530.03" stroke="black" stroke-width="0.5" />
    <path d="M529.98 44.31H529.92" stroke="black" stroke-width="0.5" />
    <path d="M529.87 44.31H529.81" stroke="black" stroke-width="0.5" />
    <path d="M529.76 44.31H529.7" stroke="black" stroke-width="0.5" />
    <path d="M529.65 44.31H529.59" stroke="black" stroke-width="0.5" />
    <path d="M529.54 44.31H529.48" stroke="black" stroke-width="0.5" />
    <path d="M529.43 44.31H529.37" stroke="black" stroke-width="0.5" />
    <path d="M529.32 44.31H529.26" stroke="black" stroke-width="0.5" />
    <path d="M529.21 44.31H529.15" stroke="black" stroke-width="0.5" />
    <path d="M529.1 44.31H529.04" stroke="black" stroke-width="0.5" />
    <path d="M528.99 44.31H528.93" stroke="black" stroke-width="0.5" />
    <path d="M528.88 44.31H528.82" stroke="black" stroke-width="0.5" />
    <path d="M528.77 44.31H528.71" stroke="black" stroke-width="0.5" />
    <path d="M528.66 44.31H528.6" stroke="black" stroke-width="0.5" />
    <path d="M528.55 44.31H528.5" stroke="black" stroke-width="0.5" />
    <path d="M528.44 44.31H528.39" stroke="black" stroke-width="0.5" />
    <path d="M528.33 44.31H528.28" stroke="black" stroke-width="0.5" />
    <path d="M528.22 44.31H528.17" stroke="black" stroke-width="0.5" />
    <path d="M528.11 44.31H528.06" stroke="black" stroke-width="0.5" />
    <path d="M528 44.31H527.95" stroke="black" stroke-width="0.5" />
    <path d="M527.89 44.31H527.84" stroke="black" stroke-width="0.5" />
    <path d="M527.78 44.31H527.73" stroke="black" stroke-width="0.5" />
    <path d="M527.67 44.31H527.62" stroke="black" stroke-width="0.5" />
    <path d="M527.56 44.31H527.51" stroke="black" stroke-width="0.5" />
    <path d="M527.45 44.31H527.4" stroke="black" stroke-width="0.5" />
    <path d="M527.34 44.31H527.29" stroke="black" stroke-width="0.5" />
    <path d="M527.23 44.31H527.18" stroke="black" stroke-width="0.5" />
    <path d="M527.12 44.31H527.07" stroke="black" stroke-width="0.5" />
    <path d="M527.01 44.31H526.96" stroke="black" stroke-width="0.5" />
    <path d="M526.9 44.31H526.85" stroke="black" stroke-width="0.5" />
    <path d="M526.79 44.31H526.74" stroke="black" stroke-width="0.5" />
    <path d="M526.68 44.31H526.63" stroke="black" stroke-width="0.5" />
    <path d="M526.57 44.31H526.52" stroke="black" stroke-width="0.5" />
    <path d="M526.46 44.31H526.41" stroke="black" stroke-width="0.5" />
    <path d="M526.35 44.31H526.3" stroke="black" stroke-width="0.5" />
    <path d="M526.24 44.31H526.19" stroke="black" stroke-width="0.5" />
    <path d="M526.13 44.31H526.08" stroke="black" stroke-width="0.5" />
    <path d="M526.02 44.31H525.97" stroke="black" stroke-width="0.5" />
    <path d="M525.91 44.31H525.86" stroke="black" stroke-width="0.5" />
    <path d="M525.8 44.31H525.75" stroke="black" stroke-width="0.5" />
    <path d="M525.69 44.31H525.64" stroke="black" stroke-width="0.5" />
    <path d="M525.58 44.31H525.53" stroke="black" stroke-width="0.5" />
    <path d="M525.47 44.31H525.42" stroke="black" stroke-width="0.5" />
    <path d="M525.36 44.31H525.31" stroke="black" stroke-width="0.5" />
    <path d="M525.25 44.31H525.2" stroke="black" stroke-width="0.5" />
    <path d="M525.14 44.31H525.09" stroke="black" stroke-width="0.5" />
    <path d="M525.03 44.31H524.98" stroke="black" stroke-width="0.5" />
    <path d="M524.92 44.31H524.87" stroke="black" stroke-width="0.5" />
    <path d="M524.81 44.31H524.76" stroke="black" stroke-width="0.5" />
    <path d="M524.7 44.31H524.65" stroke="black" stroke-width="0.5" />
    <path d="M524.59 44.31H524.54" stroke="black" stroke-width="0.5" />
    <path d="M524.48 44.31H524.43" stroke="black" stroke-width="0.5" />
    <path d="M524.37 44.31H524.32" stroke="black" stroke-width="0.5" />
    <path d="M524.26 44.31H524.21" stroke="black" stroke-width="0.5" />
    <path d="M524.15 44.31H524.1" stroke="black" stroke-width="0.5" />
    <path d="M524.04 44.31H523.99" stroke="black" stroke-width="0.5" />
    <path d="M523.93 44.31H523.88" stroke="black" stroke-width="0.5" />
    <path d="M523.82 44.31H523.77" stroke="black" stroke-width="0.5" />
    <path d="M523.71 44.31H523.66" stroke="black" stroke-width="0.5" />
    <path d="M523.6 44.31H523.55" stroke="black" stroke-width="0.5" />
    <path d="M523.49 44.31H523.44" stroke="black" stroke-width="0.5" />
    <path d="M523.38 44.31H523.33" stroke="black" stroke-width="0.5" />
    <path d="M523.27 44.31H523.22" stroke="black" stroke-width="0.5" />
    <path d="M523.16 44.31H523.11" stroke="black" stroke-width="0.5" />
    <path d="M523.05 44.31H523" stroke="black" stroke-width="0.5" />
    <path d="M522.94 44.31H522.89" stroke="black" stroke-width="0.5" />
    <path d="M522.83 44.31H522.78" stroke="black" stroke-width="0.5" />
    <path d="M522.72 44.31H522.67" stroke="black" stroke-width="0.5" />
    <path d="M522.61 44.31H522.56" stroke="black" stroke-width="0.5" />
    <path d="M522.5 44.31H522.45" stroke="black" stroke-width="0.5" />
    <path d="M522.39 44.31H522.34" stroke="black" stroke-width="0.5" />
    <path d="M522.28 44.31H522.23" stroke="black" stroke-width="0.5" />
    <path d="M522.17 44.31H522.12" stroke="black" stroke-width="0.5" />
    <path d="M522.06 44.31H522.01" stroke="black" stroke-width="0.5" />
    <path d="M521.95 44.31H521.9" stroke="black" stroke-width="0.5" />
    <path d="M521.84 44.31H521.79" stroke="black" stroke-width="0.5" />
    <path d="M521.73 44.31H521.68" stroke="black" stroke-width="0.5" />
    <path d="M521.62 44.31H521.57" stroke="black" stroke-width="0.5" />
    <path d="M521.51 44.31H521.46" stroke="black" stroke-width="0.5" />
    <path d="M521.4 44.31H521.35" stroke="black" stroke-width="0.5" />
    <path d="M521.29 44.31H521.24" stroke="black" stroke-width="0.5" />
    <path d="M521.18 44.31H521.13" stroke="black" stroke-width="0.5" />
    <path d="M521.07 44.31H521.02" stroke="black" stroke-width="0.5" />
    <path d="M520.96 44.31H520.91" stroke="black" stroke-width="0.5" />
    <path d="M520.85 44.31H520.8" stroke="black" stroke-width="0.5" />
    <path d="M520.74 44.31H520.69" stroke="black" stroke-width="0.5" />
    <path d="M520.63 44.31H520.58" stroke="black" stroke-width="0.5" />
    <path d="M520.52 44.31H520.47" stroke="black" stroke-width="0.5" />
    <path d="M520.41 44.31H520.36" stroke="black" stroke-width="0.5" />
    <path d="M520.3 44.31H520.25" stroke="black" stroke-width="0.5" />
    <path d="M520.19 44.31H520.14" stroke="black" stroke-width="0.5" />
    <path d="M520.08 44.31H520.03" stroke="black" stroke-width="0.5" />
    <path d="M519.97 44.31H519.92" stroke="black" stroke-width="0.5" />
    <path d="M519.86 44.31H519.81" stroke="black" stroke-width="0.5" />
    <path d="M519.75 44.31H519.7" stroke="black" stroke-width="0.5" />
    <path d="M519.64 44.31H519.59" stroke="black" stroke-width="0.5" />
    <path d="M519.53 44.31H519.48" stroke="black" stroke-width="0.5" />
    <path d="M519.42 44.31H519.37" stroke="black" stroke-width="0.5" />
    <path d="M519.31 44.31H519.26" stroke="black" stroke-width="0.5" />
    <path d="M519.2 44.31H519.15" stroke="black" stroke-width="0.5" />
    <path d="M519.09 44.31H519.04" stroke="black" stroke-width="0.5" />
    <path d="M518.98 44.31H518.93" stroke="black" stroke-width="0.5" />
    <path d="M518.87 44.31H518.82" stroke="black" stroke-width="0.5" />
    <path d="M518.76 44.31H518.71" stroke="black" stroke-width="0.5" />
    <path d="M518.65 44.31H518.6" stroke="black" stroke-width="0.5" />
    <path d="M518.54 44.31H518.49" stroke="black" stroke-width="0.5" />
    <path d="M518.43 44.31H518.38" stroke="black" stroke-width="0.5" />
    <path d="M518.32 44.31H518.27" stroke="black" stroke-width="0.5" />
    <path d="M518.21 44.31H518.16" stroke="black" stroke-width="0.5" />
    <path d="M518.1 44.31H518.05" stroke="black" stroke-width="0.5" />
    <path d="M517.99 44.31H517.94" stroke="black" stroke-width="0.5" />
    <path d="M517.88 44.31H517.83" stroke="black" stroke-width="0.5" />
    <path d="M517.77 44.31H517.72" stroke="black" stroke-width="0.5" />
    <path d="M517.66 44.31H517.61" stroke="black" stroke-width="0.5" />
    <path d="M517.55 44.31H517.5" stroke="black" stroke-width="0.5" />
    <path d="M517.44 44.31H517.39" stroke="black" stroke-width="0.5" />
    <path d="M517.33 44.31H517.28" stroke="black" stroke-width="0.5" />
    <path d="M517.22 44.31H517.17" stroke="black" stroke-width="0.5" />
    <path d="M517.11 44.31H517.06" stroke="black" stroke-width="0.5" />
    <path d="M517 44.31H516.95" stroke="black" stroke-width="0.5" />
    <path d="M516.89 44.31H516.84" stroke="black" stroke-width="0.5" />
    <path d="M516.78 44.31H516.73" stroke="black" stroke-width="0.5" />
    <path d="M516.67 44.31H516.62" stroke="black" stroke-width="0.5" />
    <path d="M516.56 44.31H516.51" stroke="black" stroke-width="0.5" />
    <path d="M516.45 44.31H516.4" stroke="black" stroke-width="0.5" />
    <path d="M516.34 44.31H516.29" stroke="black" stroke-width="0.5" />
    <path d="M516.23 44.31H516.18" stroke="black" stroke-width="0.5" />
    <path d="M516.12 44.31H516.07" stroke="black" stroke-width="0.5" />
    <path d="M516.01 44.31H515.96" stroke="black" stroke-width="0.5" />
    <path d="M515.9 44.31H515.85" stroke="black" stroke-width="0.5" />
    <path d="M515.79 44.31H515.74" stroke="black" stroke-width="0.5" />
    <path d="M515.68 44.31H515.63" stroke="black" stroke-width="0.5" />
    <path d="M515.58 44.31H515.52" stroke="black" stroke-width="0.5" />
    <path d="M515.47 44.31H515.41" stroke="black" stroke-width="0.5" />
    <path d="M515.36 44.31H515.3" stroke="black" stroke-width="0.5" />
    <path d="M515.25 44.31H515.19" stroke="black" stroke-width="0.5" />
    <path d="M515.14 44.31H515.08" stroke="black" stroke-width="0.5" />
    <path d="M515.03 44.31H514.97" stroke="black" stroke-width="0.5" />
    <path d="M514.92 44.31H514.86" stroke="black" stroke-width="0.5" />
    <path d="M514.81 44.31H514.75" stroke="black" stroke-width="0.5" />
    <path d="M514.7 44.31H514.64" stroke="black" stroke-width="0.5" />
    <path d="M514.59 44.31H514.53" stroke="black" stroke-width="0.5" />
    <path d="M514.48 44.31H514.42" stroke="black" stroke-width="0.5" />
    <path d="M514.37 44.31H514.31" stroke="black" stroke-width="0.5" />
    <path d="M514.26 44.31H514.2" stroke="black" stroke-width="0.5" />
    <path d="M514.15 44.31H514.09" stroke="black" stroke-width="0.5" />
    <path d="M514.04 44.31H513.98" stroke="black" stroke-width="0.5" />
    <path d="M513.93 44.31H513.87" stroke="black" stroke-width="0.5" />
    <path d="M513.82 44.31H513.76" stroke="black" stroke-width="0.5" />
    <path d="M513.71 44.31H513.65" stroke="black" stroke-width="0.5" />
    <path d="M513.6 44.31H513.54" stroke="black" stroke-width="0.5" />
    <path d="M513.49 44.31H513.43" stroke="black" stroke-width="0.5" />
    <path d="M513.38 44.31H513.32" stroke="black" stroke-width="0.5" />
    <path d="M513.27 44.31H513.21" stroke="black" stroke-width="0.5" />
    <path d="M513.16 44.31H513.1" stroke="black" stroke-width="0.5" />
    <path d="M513.05 44.31H512.99" stroke="black" stroke-width="0.5" />
    <path d="M512.94 44.31H512.88" stroke="black" stroke-width="0.5" />
    <path d="M512.83 44.31H512.77" stroke="black" stroke-width="0.5" />
    <path d="M512.72 44.31H512.66" stroke="black" stroke-width="0.5" />
    <path d="M512.61 44.31H512.55" stroke="black" stroke-width="0.5" />
    <path d="M512.5 44.31H512.44" stroke="black" stroke-width="0.5" />
    <path d="M512.39 44.31H512.33" stroke="black" stroke-width="0.5" />
    <path d="M512.28 44.31H512.22" stroke="black" stroke-width="0.5" />
    <path d="M512.17 44.31H512.11" stroke="black" stroke-width="0.5" />
    <path d="M512.06 44.31H512" stroke="black" stroke-width="0.5" />
    <path d="M511.95 44.31H511.89" stroke="black" stroke-width="0.5" />
    <path d="M511.84 44.31H511.78" stroke="black" stroke-width="0.5" />
    <path d="M511.73 44.31H511.67" stroke="black" stroke-width="0.5" />
    <path d="M511.62 44.31H511.56" stroke="black" stroke-width="0.5" />
    <path d="M511.51 44.31H511.45" stroke="black" stroke-width="0.5" />
    <path d="M511.4 44.31H511.34" stroke="black" stroke-width="0.5" />
    <path d="M511.29 44.31H511.23" stroke="black" stroke-width="0.5" />
    <path d="M511.18 44.31H511.12" stroke="black" stroke-width="0.5" />
    <path d="M511.07 44.31H511.01" stroke="black" stroke-width="0.5" />
    <path d="M510.96 44.31H510.9" stroke="black" stroke-width="0.5" />
    <path d="M510.85 44.31H510.79" stroke="black" stroke-width="0.5" />
    <path d="M510.74 44.31H510.68" stroke="black" stroke-width="0.5" />
    <path d="M510.63 44.31H510.57" stroke="black" stroke-width="0.5" />
    <path d="M510.52 44.31H510.46" stroke="black" stroke-width="0.5" />
    <path d="M510.41 44.31H510.35" stroke="black" stroke-width="0.5" />
    <path d="M510.3 44.31H510.27" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.7H541.91" stroke="black" stroke-width="0.5" />
    <path d="M541.85 70.7H541.8" stroke="black" stroke-width="0.5" />
    <path d="M541.74 70.7H541.69" stroke="black" stroke-width="0.5" />
    <path d="M541.63 70.7H541.58" stroke="black" stroke-width="0.5" />
    <path d="M541.52 70.7H541.47" stroke="black" stroke-width="0.5" />
    <path d="M541.42 70.7H541.36" stroke="black" stroke-width="0.5" />
    <path d="M541.31 70.7H541.25" stroke="black" stroke-width="0.5" />
    <path d="M541.2 70.7H541.14" stroke="black" stroke-width="0.5" />
    <path d="M541.09 70.7H541.03" stroke="black" stroke-width="0.5" />
    <path d="M540.98 70.7H540.92" stroke="black" stroke-width="0.5" />
    <path d="M540.87 70.7H540.81" stroke="black" stroke-width="0.5" />
    <path d="M540.76 70.7H540.7" stroke="black" stroke-width="0.5" />
    <path d="M540.65 70.7H540.59" stroke="black" stroke-width="0.5" />
    <path d="M540.54 70.7H540.48" stroke="black" stroke-width="0.5" />
    <path d="M540.43 70.7H540.37" stroke="black" stroke-width="0.5" />
    <path d="M540.32 70.7H540.26" stroke="black" stroke-width="0.5" />
    <path d="M540.21 70.7H540.15" stroke="black" stroke-width="0.5" />
    <path d="M540.1 70.7H540.04" stroke="black" stroke-width="0.5" />
    <path d="M539.99 70.7H539.93" stroke="black" stroke-width="0.5" />
    <path d="M539.88 70.7H539.82" stroke="black" stroke-width="0.5" />
    <path d="M539.77 70.7H539.71" stroke="black" stroke-width="0.5" />
    <path d="M539.66 70.7H539.6" stroke="black" stroke-width="0.5" />
    <path d="M539.55 70.7H539.49" stroke="black" stroke-width="0.5" />
    <path d="M539.44 70.7H539.38" stroke="black" stroke-width="0.5" />
    <path d="M539.33 70.7H539.27" stroke="black" stroke-width="0.5" />
    <path d="M539.22 70.7H539.16" stroke="black" stroke-width="0.5" />
    <path d="M539.11 70.7H539.05" stroke="black" stroke-width="0.5" />
    <path d="M539 70.7H538.94" stroke="black" stroke-width="0.5" />
    <path d="M538.89 70.7H538.83" stroke="black" stroke-width="0.5" />
    <path d="M538.78 70.7H538.72" stroke="black" stroke-width="0.5" />
    <path d="M538.67 70.7H538.61" stroke="black" stroke-width="0.5" />
    <path d="M538.56 70.7H538.5" stroke="black" stroke-width="0.5" />
    <path d="M538.45 70.7H538.39" stroke="black" stroke-width="0.5" />
    <path d="M538.34 70.7H538.28" stroke="black" stroke-width="0.5" />
    <path d="M538.23 70.7H538.17" stroke="black" stroke-width="0.5" />
    <path d="M538.12 70.7H538.06" stroke="black" stroke-width="0.5" />
    <path d="M538.01 70.7H537.95" stroke="black" stroke-width="0.5" />
    <path d="M537.9 70.7H537.84" stroke="black" stroke-width="0.5" />
    <path d="M537.79 70.7H537.73" stroke="black" stroke-width="0.5" />
    <path d="M537.68 70.7H537.62" stroke="black" stroke-width="0.5" />
    <path d="M537.57 70.7H537.51" stroke="black" stroke-width="0.5" />
    <path d="M537.46 70.7H537.4" stroke="black" stroke-width="0.5" />
    <path d="M537.35 70.7H537.29" stroke="black" stroke-width="0.5" />
    <path d="M537.24 70.7H537.18" stroke="black" stroke-width="0.5" />
    <path d="M537.13 70.7H537.07" stroke="black" stroke-width="0.5" />
    <path d="M537.02 70.7H536.96" stroke="black" stroke-width="0.5" />
    <path d="M536.91 70.7H536.85" stroke="black" stroke-width="0.5" />
    <path d="M536.8 70.7H536.74" stroke="black" stroke-width="0.5" />
    <path d="M536.69 70.7H536.63" stroke="black" stroke-width="0.5" />
    <path d="M536.58 70.7H536.52" stroke="black" stroke-width="0.5" />
    <path d="M536.47 70.7H536.41" stroke="black" stroke-width="0.5" />
    <path d="M536.36 70.7H536.3" stroke="black" stroke-width="0.5" />
    <path d="M536.25 70.7H536.19" stroke="black" stroke-width="0.5" />
    <path d="M536.14 70.7H536.08" stroke="black" stroke-width="0.5" />
    <path d="M536.03 70.7H535.97" stroke="black" stroke-width="0.5" />
    <path d="M535.92 70.7H535.86" stroke="black" stroke-width="0.5" />
    <path d="M535.81 70.7H535.75" stroke="black" stroke-width="0.5" />
    <path d="M535.7 70.7H535.64" stroke="black" stroke-width="0.5" />
    <path d="M535.59 70.7H535.53" stroke="black" stroke-width="0.5" />
    <path d="M535.48 70.7H535.42" stroke="black" stroke-width="0.5" />
    <path d="M535.37 70.7H535.31" stroke="black" stroke-width="0.5" />
    <path d="M535.26 70.7H535.2" stroke="black" stroke-width="0.5" />
    <path d="M535.15 70.7H535.09" stroke="black" stroke-width="0.5" />
    <path d="M535.04 70.7H534.98" stroke="black" stroke-width="0.5" />
    <path d="M534.93 70.7H534.87" stroke="black" stroke-width="0.5" />
    <path d="M534.82 70.7H534.76" stroke="black" stroke-width="0.5" />
    <path d="M534.71 70.7H534.65" stroke="black" stroke-width="0.5" />
    <path d="M534.6 70.7H534.54" stroke="black" stroke-width="0.5" />
    <path d="M534.49 70.7H534.43" stroke="black" stroke-width="0.5" />
    <path d="M534.38 70.7H534.32" stroke="black" stroke-width="0.5" />
    <path d="M534.27 70.7H534.21" stroke="black" stroke-width="0.5" />
    <path d="M534.16 70.7H534.1" stroke="black" stroke-width="0.5" />
    <path d="M534.05 70.7H533.99" stroke="black" stroke-width="0.5" />
    <path d="M533.94 70.7H533.88" stroke="black" stroke-width="0.5" />
    <path d="M533.83 70.7H533.77" stroke="black" stroke-width="0.5" />
    <path d="M533.72 70.7H533.66" stroke="black" stroke-width="0.5" />
    <path d="M533.61 70.7H533.55" stroke="black" stroke-width="0.5" />
    <path d="M533.5 70.7H533.44" stroke="black" stroke-width="0.5" />
    <path d="M533.39 70.7H533.33" stroke="black" stroke-width="0.5" />
    <path d="M533.28 70.7H533.22" stroke="black" stroke-width="0.5" />
    <path d="M533.17 70.7H533.11" stroke="black" stroke-width="0.5" />
    <path d="M533.06 70.7H533" stroke="black" stroke-width="0.5" />
    <path d="M532.95 70.7H532.89" stroke="black" stroke-width="0.5" />
    <path d="M532.84 70.7H532.78" stroke="black" stroke-width="0.5" />
    <path d="M532.73 70.7H532.67" stroke="black" stroke-width="0.5" />
    <path d="M532.62 70.7H532.56" stroke="black" stroke-width="0.5" />
    <path d="M532.51 70.7H532.45" stroke="black" stroke-width="0.5" />
    <path d="M532.4 70.7H532.34" stroke="black" stroke-width="0.5" />
    <path d="M532.29 70.7H532.23" stroke="black" stroke-width="0.5" />
    <path d="M532.18 70.7H532.12" stroke="black" stroke-width="0.5" />
    <path d="M532.07 70.7H532.01" stroke="black" stroke-width="0.5" />
    <path d="M531.96 70.7H531.9" stroke="black" stroke-width="0.5" />
    <path d="M531.85 70.7H531.79" stroke="black" stroke-width="0.5" />
    <path d="M531.74 70.7H531.68" stroke="black" stroke-width="0.5" />
    <path d="M531.63 70.7H531.57" stroke="black" stroke-width="0.5" />
    <path d="M531.52 70.7H531.46" stroke="black" stroke-width="0.5" />
    <path d="M531.41 70.7H531.35" stroke="black" stroke-width="0.5" />
    <path d="M531.3 70.7H531.24" stroke="black" stroke-width="0.5" />
    <path d="M531.19 70.7H531.13" stroke="black" stroke-width="0.5" />
    <path d="M531.08 70.7H531.02" stroke="black" stroke-width="0.5" />
    <path d="M530.97 70.7H530.91" stroke="black" stroke-width="0.5" />
    <path d="M530.86 70.7H530.8" stroke="black" stroke-width="0.5" />
    <path d="M530.75 70.7H530.69" stroke="black" stroke-width="0.5" />
    <path d="M530.64 70.7H530.58" stroke="black" stroke-width="0.5" />
    <path d="M530.53 70.7H530.47" stroke="black" stroke-width="0.5" />
    <path d="M530.42 70.7H530.36" stroke="black" stroke-width="0.5" />
    <path d="M530.31 70.7H530.25" stroke="black" stroke-width="0.5" />
    <path d="M530.2 70.7H530.14" stroke="black" stroke-width="0.5" />
    <path d="M530.09 70.7H530.03" stroke="black" stroke-width="0.5" />
    <path d="M529.98 70.7H529.92" stroke="black" stroke-width="0.5" />
    <path d="M529.87 70.7H529.81" stroke="black" stroke-width="0.5" />
    <path d="M529.76 70.7H529.7" stroke="black" stroke-width="0.5" />
    <path d="M529.65 70.7H529.59" stroke="black" stroke-width="0.5" />
    <path d="M529.54 70.7H529.48" stroke="black" stroke-width="0.5" />
    <path d="M529.43 70.7H529.37" stroke="black" stroke-width="0.5" />
    <path d="M529.32 70.7H529.26" stroke="black" stroke-width="0.5" />
    <path d="M529.21 70.7H529.15" stroke="black" stroke-width="0.5" />
    <path d="M529.1 70.7H529.04" stroke="black" stroke-width="0.5" />
    <path d="M528.99 70.7H528.93" stroke="black" stroke-width="0.5" />
    <path d="M528.88 70.7H528.82" stroke="black" stroke-width="0.5" />
    <path d="M528.77 70.7H528.71" stroke="black" stroke-width="0.5" />
    <path d="M528.66 70.7H528.6" stroke="black" stroke-width="0.5" />
    <path d="M528.55 70.7H528.5" stroke="black" stroke-width="0.5" />
    <path d="M528.44 70.7H528.39" stroke="black" stroke-width="0.5" />
    <path d="M528.33 70.7H528.28" stroke="black" stroke-width="0.5" />
    <path d="M528.22 70.7H528.17" stroke="black" stroke-width="0.5" />
    <path d="M528.11 70.7H528.06" stroke="black" stroke-width="0.5" />
    <path d="M528 70.7H527.95" stroke="black" stroke-width="0.5" />
    <path d="M527.89 70.7H527.84" stroke="black" stroke-width="0.5" />
    <path d="M527.78 70.7H527.73" stroke="black" stroke-width="0.5" />
    <path d="M527.67 70.7H527.62" stroke="black" stroke-width="0.5" />
    <path d="M527.56 70.7H527.51" stroke="black" stroke-width="0.5" />
    <path d="M527.45 70.7H527.4" stroke="black" stroke-width="0.5" />
    <path d="M527.34 70.7H527.29" stroke="black" stroke-width="0.5" />
    <path d="M527.23 70.7H527.18" stroke="black" stroke-width="0.5" />
    <path d="M527.12 70.7H527.07" stroke="black" stroke-width="0.5" />
    <path d="M527.01 70.7H526.96" stroke="black" stroke-width="0.5" />
    <path d="M526.9 70.7H526.85" stroke="black" stroke-width="0.5" />
    <path d="M526.79 70.7H526.74" stroke="black" stroke-width="0.5" />
    <path d="M526.68 70.7H526.63" stroke="black" stroke-width="0.5" />
    <path d="M526.57 70.7H526.52" stroke="black" stroke-width="0.5" />
    <path d="M526.46 70.7H526.41" stroke="black" stroke-width="0.5" />
    <path d="M526.35 70.7H526.3" stroke="black" stroke-width="0.5" />
    <path d="M526.24 70.7H526.19" stroke="black" stroke-width="0.5" />
    <path d="M526.13 70.7H526.08" stroke="black" stroke-width="0.5" />
    <path d="M526.02 70.7H525.97" stroke="black" stroke-width="0.5" />
    <path d="M525.91 70.7H525.86" stroke="black" stroke-width="0.5" />
    <path d="M525.8 70.7H525.75" stroke="black" stroke-width="0.5" />
    <path d="M525.69 70.7H525.64" stroke="black" stroke-width="0.5" />
    <path d="M525.58 70.7H525.53" stroke="black" stroke-width="0.5" />
    <path d="M525.47 70.7H525.42" stroke="black" stroke-width="0.5" />
    <path d="M525.36 70.7H525.31" stroke="black" stroke-width="0.5" />
    <path d="M525.25 70.7H525.2" stroke="black" stroke-width="0.5" />
    <path d="M525.14 70.7H525.09" stroke="black" stroke-width="0.5" />
    <path d="M525.03 70.7H524.98" stroke="black" stroke-width="0.5" />
    <path d="M524.92 70.7H524.87" stroke="black" stroke-width="0.5" />
    <path d="M524.81 70.7H524.76" stroke="black" stroke-width="0.5" />
    <path d="M524.7 70.7H524.65" stroke="black" stroke-width="0.5" />
    <path d="M524.59 70.7H524.54" stroke="black" stroke-width="0.5" />
    <path d="M524.48 70.7H524.43" stroke="black" stroke-width="0.5" />
    <path d="M524.37 70.7H524.32" stroke="black" stroke-width="0.5" />
    <path d="M524.26 70.7H524.21" stroke="black" stroke-width="0.5" />
    <path d="M524.15 70.7H524.1" stroke="black" stroke-width="0.5" />
    <path d="M524.04 70.7H523.99" stroke="black" stroke-width="0.5" />
    <path d="M523.93 70.7H523.88" stroke="black" stroke-width="0.5" />
    <path d="M523.82 70.7H523.77" stroke="black" stroke-width="0.5" />
    <path d="M523.71 70.7H523.66" stroke="black" stroke-width="0.5" />
    <path d="M523.6 70.7H523.55" stroke="black" stroke-width="0.5" />
    <path d="M523.49 70.7H523.44" stroke="black" stroke-width="0.5" />
    <path d="M523.38 70.7H523.33" stroke="black" stroke-width="0.5" />
    <path d="M523.27 70.7H523.22" stroke="black" stroke-width="0.5" />
    <path d="M523.16 70.7H523.11" stroke="black" stroke-width="0.5" />
    <path d="M523.05 70.7H523" stroke="black" stroke-width="0.5" />
    <path d="M522.94 70.7H522.89" stroke="black" stroke-width="0.5" />
    <path d="M522.83 70.7H522.78" stroke="black" stroke-width="0.5" />
    <path d="M522.72 70.7H522.67" stroke="black" stroke-width="0.5" />
    <path d="M522.61 70.7H522.56" stroke="black" stroke-width="0.5" />
    <path d="M522.5 70.7H522.45" stroke="black" stroke-width="0.5" />
    <path d="M522.39 70.7H522.34" stroke="black" stroke-width="0.5" />
    <path d="M522.28 70.7H522.23" stroke="black" stroke-width="0.5" />
    <path d="M522.17 70.7H522.12" stroke="black" stroke-width="0.5" />
    <path d="M522.06 70.7H522.01" stroke="black" stroke-width="0.5" />
    <path d="M521.95 70.7H521.9" stroke="black" stroke-width="0.5" />
    <path d="M521.84 70.7H521.79" stroke="black" stroke-width="0.5" />
    <path d="M521.73 70.7H521.68" stroke="black" stroke-width="0.5" />
    <path d="M521.62 70.7H521.57" stroke="black" stroke-width="0.5" />
    <path d="M521.51 70.7H521.46" stroke="black" stroke-width="0.5" />
    <path d="M521.4 70.7H521.35" stroke="black" stroke-width="0.5" />
    <path d="M521.29 70.7H521.24" stroke="black" stroke-width="0.5" />
    <path d="M521.18 70.7H521.13" stroke="black" stroke-width="0.5" />
    <path d="M521.07 70.7H521.02" stroke="black" stroke-width="0.5" />
    <path d="M520.96 70.7H520.91" stroke="black" stroke-width="0.5" />
    <path d="M520.85 70.7H520.8" stroke="black" stroke-width="0.5" />
    <path d="M520.74 70.7H520.69" stroke="black" stroke-width="0.5" />
    <path d="M520.63 70.7H520.58" stroke="black" stroke-width="0.5" />
    <path d="M520.52 70.7H520.47" stroke="black" stroke-width="0.5" />
    <path d="M520.41 70.7H520.36" stroke="black" stroke-width="0.5" />
    <path d="M520.3 70.7H520.25" stroke="black" stroke-width="0.5" />
    <path d="M520.19 70.7H520.14" stroke="black" stroke-width="0.5" />
    <path d="M520.08 70.7H520.03" stroke="black" stroke-width="0.5" />
    <path d="M519.97 70.7H519.92" stroke="black" stroke-width="0.5" />
    <path d="M519.86 70.7H519.81" stroke="black" stroke-width="0.5" />
    <path d="M519.75 70.7H519.7" stroke="black" stroke-width="0.5" />
    <path d="M519.64 70.7H519.59" stroke="black" stroke-width="0.5" />
    <path d="M519.53 70.7H519.48" stroke="black" stroke-width="0.5" />
    <path d="M519.42 70.7H519.37" stroke="black" stroke-width="0.5" />
    <path d="M519.31 70.7H519.26" stroke="black" stroke-width="0.5" />
    <path d="M519.2 70.7H519.15" stroke="black" stroke-width="0.5" />
    <path d="M519.09 70.7H519.04" stroke="black" stroke-width="0.5" />
    <path d="M518.98 70.7H518.93" stroke="black" stroke-width="0.5" />
    <path d="M518.87 70.7H518.82" stroke="black" stroke-width="0.5" />
    <path d="M518.76 70.7H518.71" stroke="black" stroke-width="0.5" />
    <path d="M518.65 70.7H518.6" stroke="black" stroke-width="0.5" />
    <path d="M518.54 70.7H518.49" stroke="black" stroke-width="0.5" />
    <path d="M518.43 70.7H518.38" stroke="black" stroke-width="0.5" />
    <path d="M518.32 70.7H518.27" stroke="black" stroke-width="0.5" />
    <path d="M518.21 70.7H518.16" stroke="black" stroke-width="0.5" />
    <path d="M518.1 70.7H518.05" stroke="black" stroke-width="0.5" />
    <path d="M517.99 70.7H517.94" stroke="black" stroke-width="0.5" />
    <path d="M517.88 70.7H517.83" stroke="black" stroke-width="0.5" />
    <path d="M517.77 70.7H517.72" stroke="black" stroke-width="0.5" />
    <path d="M517.66 70.7H517.61" stroke="black" stroke-width="0.5" />
    <path d="M517.55 70.7H517.5" stroke="black" stroke-width="0.5" />
    <path d="M517.44 70.7H517.39" stroke="black" stroke-width="0.5" />
    <path d="M517.33 70.7H517.28" stroke="black" stroke-width="0.5" />
    <path d="M517.22 70.7H517.17" stroke="black" stroke-width="0.5" />
    <path d="M517.11 70.7H517.06" stroke="black" stroke-width="0.5" />
    <path d="M517 70.7H516.95" stroke="black" stroke-width="0.5" />
    <path d="M516.89 70.7H516.84" stroke="black" stroke-width="0.5" />
    <path d="M516.78 70.7H516.73" stroke="black" stroke-width="0.5" />
    <path d="M516.67 70.7H516.62" stroke="black" stroke-width="0.5" />
    <path d="M516.56 70.7H516.51" stroke="black" stroke-width="0.5" />
    <path d="M516.45 70.7H516.4" stroke="black" stroke-width="0.5" />
    <path d="M516.34 70.7H516.29" stroke="black" stroke-width="0.5" />
    <path d="M516.23 70.7H516.18" stroke="black" stroke-width="0.5" />
    <path d="M516.12 70.7H516.07" stroke="black" stroke-width="0.5" />
    <path d="M516.01 70.7H515.96" stroke="black" stroke-width="0.5" />
    <path d="M515.9 70.7H515.85" stroke="black" stroke-width="0.5" />
    <path d="M515.79 70.7H515.74" stroke="black" stroke-width="0.5" />
    <path d="M515.68 70.7H515.63" stroke="black" stroke-width="0.5" />
    <path d="M515.58 70.7H515.52" stroke="black" stroke-width="0.5" />
    <path d="M515.47 70.7H515.41" stroke="black" stroke-width="0.5" />
    <path d="M515.36 70.7H515.3" stroke="black" stroke-width="0.5" />
    <path d="M515.25 70.7H515.19" stroke="black" stroke-width="0.5" />
    <path d="M515.14 70.7H515.08" stroke="black" stroke-width="0.5" />
    <path d="M515.03 70.7H514.97" stroke="black" stroke-width="0.5" />
    <path d="M514.92 70.7H514.86" stroke="black" stroke-width="0.5" />
    <path d="M514.81 70.7H514.75" stroke="black" stroke-width="0.5" />
    <path d="M514.7 70.7H514.64" stroke="black" stroke-width="0.5" />
    <path d="M514.59 70.7H514.53" stroke="black" stroke-width="0.5" />
    <path d="M514.48 70.7H514.42" stroke="black" stroke-width="0.5" />
    <path d="M514.37 70.7H514.31" stroke="black" stroke-width="0.5" />
    <path d="M514.26 70.7H514.2" stroke="black" stroke-width="0.5" />
    <path d="M514.15 70.7H514.09" stroke="black" stroke-width="0.5" />
    <path d="M514.04 70.7H513.98" stroke="black" stroke-width="0.5" />
    <path d="M513.93 70.7H513.87" stroke="black" stroke-width="0.5" />
    <path d="M513.82 70.7H513.76" stroke="black" stroke-width="0.5" />
    <path d="M513.71 70.7H513.65" stroke="black" stroke-width="0.5" />
    <path d="M513.6 70.7H513.54" stroke="black" stroke-width="0.5" />
    <path d="M513.49 70.7H513.43" stroke="black" stroke-width="0.5" />
    <path d="M513.38 70.7H513.32" stroke="black" stroke-width="0.5" />
    <path d="M513.27 70.7H513.21" stroke="black" stroke-width="0.5" />
    <path d="M513.16 70.7H513.1" stroke="black" stroke-width="0.5" />
    <path d="M513.05 70.7H512.99" stroke="black" stroke-width="0.5" />
    <path d="M512.94 70.7H512.88" stroke="black" stroke-width="0.5" />
    <path d="M512.83 70.7H512.77" stroke="black" stroke-width="0.5" />
    <path d="M512.72 70.7H512.66" stroke="black" stroke-width="0.5" />
    <path d="M512.61 70.7H512.55" stroke="black" stroke-width="0.5" />
    <path d="M512.5 70.7H512.44" stroke="black" stroke-width="0.5" />
    <path d="M512.39 70.7H512.33" stroke="black" stroke-width="0.5" />
    <path d="M512.28 70.7H512.22" stroke="black" stroke-width="0.5" />
    <path d="M512.17 70.7H512.11" stroke="black" stroke-width="0.5" />
    <path d="M512.06 70.7H512" stroke="black" stroke-width="0.5" />
    <path d="M511.95 70.7H511.89" stroke="black" stroke-width="0.5" />
    <path d="M511.84 70.7H511.78" stroke="black" stroke-width="0.5" />
    <path d="M511.73 70.7H511.67" stroke="black" stroke-width="0.5" />
    <path d="M511.62 70.7H511.56" stroke="black" stroke-width="0.5" />
    <path d="M511.51 70.7H511.45" stroke="black" stroke-width="0.5" />
    <path d="M511.4 70.7H511.34" stroke="black" stroke-width="0.5" />
    <path d="M511.29 70.7H511.23" stroke="black" stroke-width="0.5" />
    <path d="M511.18 70.7H511.12" stroke="black" stroke-width="0.5" />
    <path d="M511.07 70.7H511.01" stroke="black" stroke-width="0.5" />
    <path d="M510.96 70.7H510.9" stroke="black" stroke-width="0.5" />
    <path d="M510.85 70.7H510.79" stroke="black" stroke-width="0.5" />
    <path d="M510.74 70.7H510.68" stroke="black" stroke-width="0.5" />
    <path d="M510.63 70.7H510.57" stroke="black" stroke-width="0.5" />
    <path d="M510.52 70.7H510.46" stroke="black" stroke-width="0.5" />
    <path d="M510.41 70.7H510.35" stroke="black" stroke-width="0.5" />
    <path d="M510.3 70.7H510.27" stroke="black" stroke-width="0.5" />
    <path d="M158.08 165.39H142.24" stroke="black" stroke-width="0.5" />
    <path d="M142.24 165.39V181.23" stroke="black" stroke-width="0.5" />
    <path d="M142.24 181.23H158.08" stroke="black" stroke-width="0.5" />
    <path d="M158.08 181.23V165.39" stroke="black" stroke-width="0.5" />
    <path
      d="M152.8 167.37C152.8 167.603 152.893 167.827 153.058 167.992C153.223 168.157 153.447 168.25 153.68 168.25C153.913 168.25 154.137 168.157 154.302 167.992C154.467 167.827 154.56 167.603 154.56 167.37"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M154.56 167.37C154.56 167.137 154.467 166.913 154.302 166.748C154.137 166.583 153.913 166.49 153.68 166.49C153.447 166.49 153.223 166.583 153.058 166.748C152.893 166.913 152.8 167.137 152.8 167.37"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M145.76 167.37C145.76 167.603 145.853 167.827 146.018 167.992C146.183 168.157 146.407 168.25 146.64 168.25C146.873 168.25 147.097 168.157 147.262 167.992C147.427 167.827 147.52 167.603 147.52 167.37"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M147.52 167.37C147.52 167.137 147.427 166.913 147.262 166.748C147.097 166.583 146.873 166.49 146.64 166.49C146.407 166.49 146.183 166.583 146.018 166.748C145.853 166.913 145.76 167.137 145.76 167.37"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M149.72 169.35H145.32" stroke="black" stroke-width="0.5" />
    <path
      d="M149.72 171.55C149.72 171.667 149.766 171.779 149.849 171.861C149.931 171.944 150.043 171.99 150.16 171.99C150.277 171.99 150.389 171.944 150.471 171.861C150.554 171.779 150.6 171.667 150.6 171.55"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M150.6 167.37V171.55" stroke="black" stroke-width="0.5" />
    <path d="M149.72 167.37V171.55" stroke="black" stroke-width="0.5" />
    <path
      d="M150.6 167.37C150.6 167.253 150.554 167.141 150.471 167.059C150.389 166.976 150.277 166.93 150.16 166.93C150.043 166.93 149.931 166.976 149.849 167.059C149.766 167.141 149.72 167.253 149.72 167.37"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M145.32 169.35C145.089 169.35 144.86 169.395 144.646 169.484C144.433 169.572 144.239 169.702 144.075 169.865C143.912 170.029 143.782 170.223 143.694 170.436C143.605 170.65 143.56 170.879 143.56 171.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M143.56 178.15C143.56 178.381 143.605 178.61 143.694 178.824C143.782 179.037 143.912 179.231 144.075 179.395C144.239 179.558 144.433 179.688 144.646 179.776C144.86 179.865 145.089 179.91 145.32 179.91"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M155 179.91C155.231 179.91 155.46 179.865 155.674 179.776C155.887 179.688 156.081 179.558 156.245 179.395C156.408 179.231 156.538 179.037 156.626 178.824C156.715 178.61 156.76 178.381 156.76 178.15"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M156.76 171.11C156.76 170.879 156.715 170.65 156.626 170.436C156.538 170.223 156.408 170.029 156.245 169.865C156.081 169.702 155.887 169.572 155.674 169.484C155.46 169.395 155.231 169.35 155 169.35"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M155 169.35H150.6" stroke="black" stroke-width="0.5" />
    <path d="M156.76 178.15V171.11" stroke="black" stroke-width="0.5" />
    <path d="M145.32 179.91H155" stroke="black" stroke-width="0.5" />
    <path d="M143.56 171.11V178.15" stroke="black" stroke-width="0.5" />
    <path
      d="M149.28 173.39C149.28 173.623 149.373 173.847 149.538 174.012C149.703 174.177 149.927 174.27 150.16 174.27C150.393 174.27 150.617 174.177 150.782 174.012C150.947 173.847 151.04 173.623 151.04 173.39"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M151.04 173.39C151.04 173.157 150.947 172.933 150.782 172.768C150.617 172.603 150.393 172.51 150.16 172.51C149.927 172.51 149.703 172.603 149.538 172.768C149.373 172.933 149.28 173.157 149.28 173.39"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M158.08 260.06H142.24" stroke="black" stroke-width="0.5" />
    <path d="M142.24 260.06V275.89" stroke="black" stroke-width="0.5" />
    <path d="M142.24 275.89H158.08" stroke="black" stroke-width="0.5" />
    <path d="M158.08 275.89V260.06" stroke="black" stroke-width="0.5" />
    <path
      d="M152.8 262.04C152.8 262.273 152.893 262.497 153.058 262.662C153.223 262.827 153.447 262.92 153.68 262.92C153.913 262.92 154.137 262.827 154.302 262.662C154.467 262.497 154.56 262.273 154.56 262.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M154.56 262.04C154.56 261.807 154.467 261.583 154.302 261.418C154.137 261.253 153.913 261.16 153.68 261.16C153.447 261.16 153.223 261.253 153.058 261.418C152.893 261.583 152.8 261.807 152.8 262.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M145.76 262.04C145.76 262.273 145.853 262.497 146.018 262.662C146.183 262.827 146.407 262.92 146.64 262.92C146.873 262.92 147.097 262.827 147.262 262.662C147.427 262.497 147.52 262.273 147.52 262.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M147.52 262.04C147.52 261.807 147.427 261.583 147.262 261.418C147.097 261.253 146.873 261.16 146.64 261.16C146.407 261.16 146.183 261.253 146.018 261.418C145.853 261.583 145.76 261.807 145.76 262.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M149.72 264.02H145.32" stroke="black" stroke-width="0.5" />
    <path
      d="M149.72 266.22C149.72 266.337 149.766 266.449 149.849 266.531C149.931 266.614 150.043 266.66 150.16 266.66C150.277 266.66 150.389 266.614 150.471 266.531C150.554 266.449 150.6 266.337 150.6 266.22"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M150.6 262.04V266.22" stroke="black" stroke-width="0.5" />
    <path d="M149.72 262.04V266.22" stroke="black" stroke-width="0.5" />
    <path
      d="M150.6 262.04C150.6 261.923 150.554 261.811 150.471 261.729C150.389 261.646 150.277 261.6 150.16 261.6C150.043 261.6 149.931 261.646 149.849 261.729C149.766 261.811 149.72 261.923 149.72 262.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M145.32 264.02C145.089 264.02 144.86 264.065 144.646 264.154C144.433 264.242 144.239 264.372 144.075 264.535C143.912 264.699 143.782 264.893 143.694 265.106C143.605 265.32 143.56 265.549 143.56 265.78"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M143.56 272.82C143.56 273.051 143.605 273.28 143.694 273.494C143.782 273.707 143.912 273.901 144.075 274.065C144.239 274.228 144.433 274.358 144.646 274.446C144.86 274.535 145.089 274.58 145.32 274.58"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M155 274.58C155.231 274.58 155.46 274.535 155.674 274.446C155.887 274.358 156.081 274.228 156.245 274.065C156.408 273.901 156.538 273.707 156.626 273.494C156.715 273.28 156.76 273.051 156.76 272.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M156.76 265.78C156.76 265.549 156.715 265.32 156.626 265.106C156.538 264.893 156.408 264.699 156.245 264.535C156.081 264.372 155.887 264.242 155.674 264.154C155.46 264.065 155.231 264.02 155 264.02"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M155 264.02H150.6" stroke="black" stroke-width="0.5" />
    <path d="M156.76 272.82V265.78" stroke="black" stroke-width="0.5" />
    <path d="M145.32 274.58H155" stroke="black" stroke-width="0.5" />
    <path d="M143.56 265.78V272.82" stroke="black" stroke-width="0.5" />
    <path
      d="M149.28 268.06C149.28 268.293 149.373 268.517 149.538 268.682C149.703 268.847 149.927 268.94 150.16 268.94C150.393 268.94 150.617 268.847 150.782 268.682C150.947 268.517 151.04 268.293 151.04 268.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M151.04 268.06C151.04 267.827 150.947 267.603 150.782 267.438C150.617 267.273 150.393 267.18 150.16 267.18C149.927 267.18 149.703 267.273 149.538 267.438C149.373 267.603 149.28 267.827 149.28 268.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M369.08 128.12H367.54" stroke="black" stroke-width="0.5" />
    <path d="M367.54 128.12V159.79" stroke="black" stroke-width="0.5" />
    <path d="M367.54 159.79H369.08" stroke="black" stroke-width="0.5" />
    <path d="M369.08 159.79V128.12" stroke="black" stroke-width="0.5" />
    <path
      d="M369.08 128.12C360.682 128.122 352.629 131.46 346.692 137.399C340.755 143.338 337.419 151.392 337.42 159.79"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M306.08 378.16H264.73" stroke="black" stroke-width="0.5" />
    <path d="M233.07 378.16H228.67" stroke="black" stroke-width="0.5" />
    <path d="M228.67 378.16V271.39" stroke="black" stroke-width="0.5" />
    <path d="M228.67 271.39H226.52" stroke="black" stroke-width="0.5" />
    <path d="M226.52 271.39V239.72" stroke="black" stroke-width="0.5" />
    <path d="M226.52 239.72H228.67" stroke="black" stroke-width="0.5" />
    <path d="M228.67 239.72V232.58" stroke="black" stroke-width="0.5" />
    <path d="M228.67 232.58H306.08" stroke="black" stroke-width="0.5" />
    <path d="M306.08 232.58V378.16" stroke="black" stroke-width="0.5" />
    <path d="M306.08 378.16V232.58" stroke="black" stroke-width="0.5" />
    <path d="M306.08 232.58H228.67" stroke="black" stroke-width="0.5" />
    <path d="M228.67 232.58V239.72" stroke="black" stroke-width="0.5" />
    <path d="M228.67 239.72H226.52" stroke="black" stroke-width="0.5" />
    <path d="M226.52 239.72V271.39" stroke="black" stroke-width="0.5" />
    <path d="M226.52 271.39H228.67" stroke="black" stroke-width="0.5" />
    <path d="M228.67 271.39V378.16" stroke="black" stroke-width="0.5" />
    <path d="M228.67 378.16H233.07" stroke="black" stroke-width="0.5" />
    <path d="M233.07 378.16V380.31" stroke="black" stroke-width="0.5" />
    <path d="M233.07 380.31H264.73" stroke="black" stroke-width="0.5" />
    <path d="M264.73 380.31V378.16" stroke="black" stroke-width="0.5" />
    <path d="M264.73 378.16H306.08" stroke="black" stroke-width="0.5" />
    <path d="M21.73 523.3V465.33" stroke="black" stroke-width="0.5" />
    <path d="M21.73 446.86V5.57001" stroke="black" stroke-width="0.5" />
    <path d="M152.87 37.29H164.23" stroke="black" stroke-width="0.5" />
    <path d="M164.23 37.29V36.91" stroke="black" stroke-width="0.5" />
    <path d="M164.23 36.91H178.31" stroke="black" stroke-width="0.5" />
    <path d="M178.31 36.91V35.15" stroke="black" stroke-width="0.5" />
    <path d="M178.31 35.15H209.98" stroke="black" stroke-width="0.5" />
    <path d="M209.98 35.15V36.91" stroke="black" stroke-width="0.5" />
    <path d="M209.98 36.91H211.74" stroke="black" stroke-width="0.5" />
    <path d="M211.74 36.91V37.29" stroke="black" stroke-width="0.5" />
    <path d="M211.74 37.29H219.65" stroke="black" stroke-width="0.5" />
    <path d="M219.65 37.29V5.19" stroke="black" stroke-width="0.5" />
    <path d="M219.65 5.19H374.03" stroke="black" stroke-width="0.5" />
    <path d="M377.22 5.19H505.98" stroke="black" stroke-width="0.5" />
    <path d="M505.98 5.19V36.89" stroke="black" stroke-width="0.5" />
    <path d="M510.95 36.89H525.91" stroke="black" stroke-width="0.5" />
    <path d="M525.91 36.89V35.13" stroke="black" stroke-width="0.5" />
    <path d="M525.91 35.13H559.53" stroke="black" stroke-width="0.5" />
    <path d="M559.53 35.13V36.89" stroke="black" stroke-width="0.5" />
    <path d="M559.53 36.89H561.29" stroke="black" stroke-width="0.5" />
    <path d="M565.58 36.89V5.48999" stroke="black" stroke-width="0.5" />
    <path d="M565.58 5.48999H705.44" stroke="black" stroke-width="0.5" />
    <path d="M705.44 5.48999V214.3" stroke="black" stroke-width="0.5" />
    <path d="M705.44 214.3H694.89" stroke="black" stroke-width="0.5" />
    <path d="M694.89 215.62V538.26" stroke="black" stroke-width="0.5" />
    <path d="M694.89 538.26H41.52" stroke="black" stroke-width="0.5" />
    <path d="M41.52 538.26V527.04" stroke="black" stroke-width="0.5" />
    <path d="M41.52 527.04H35.36" stroke="black" stroke-width="0.5" />
    <path d="M35.36 527.04V523.3" stroke="black" stroke-width="0.5" />
    <path d="M35.36 523.3H21.73" stroke="black" stroke-width="0.5" />
    <path d="M224.38 228.29V378.16" stroke="black" stroke-width="0.5" />
    <path d="M224.38 378.16H233.07" stroke="black" stroke-width="0.5" />
    <path d="M264.73 378.16H306.08" stroke="black" stroke-width="0.5" />
    <path d="M306.08 378.16V232.58" stroke="black" stroke-width="0.5" />
    <path d="M21.73 523.3H35.36" stroke="black" stroke-width="0.5" />
    <path d="M35.36 523.3V527.04" stroke="black" stroke-width="0.5" />
    <path d="M35.36 527.04H41.52" stroke="black" stroke-width="0.5" />
    <path d="M41.52 527.04V538.26" stroke="black" stroke-width="0.5" />
    <path d="M41.52 538.26H694.89" stroke="black" stroke-width="0.5" />
    <path d="M694.89 538.26V215.62" stroke="black" stroke-width="0.5" />
    <path d="M694.89 214.3H705.44" stroke="black" stroke-width="0.5" />
    <path d="M705.44 214.3V5.48999" stroke="black" stroke-width="0.5" />
    <path d="M705.44 5.48999H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 5.48999V36.89" stroke="black" stroke-width="0.5" />
    <path d="M561.29 36.89H559.53" stroke="black" stroke-width="0.5" />
    <path d="M559.53 36.89V35.13" stroke="black" stroke-width="0.5" />
    <path d="M559.53 35.13H525.91" stroke="black" stroke-width="0.5" />
    <path d="M525.91 35.13V36.89" stroke="black" stroke-width="0.5" />
    <path d="M525.91 36.89H510.95" stroke="black" stroke-width="0.5" />
    <path d="M505.98 36.89V5.19" stroke="black" stroke-width="0.5" />
    <path d="M505.98 5.19H377.22" stroke="black" stroke-width="0.5" />
    <path d="M374.03 5.19H219.65" stroke="black" stroke-width="0.5" />
    <path d="M219.65 5.19V37.29" stroke="black" stroke-width="0.5" />
    <path d="M219.65 37.29H211.74" stroke="black" stroke-width="0.5" />
    <path d="M211.74 37.29V36.91" stroke="black" stroke-width="0.5" />
    <path d="M211.74 36.91H209.98" stroke="black" stroke-width="0.5" />
    <path d="M209.98 36.91V35.15" stroke="black" stroke-width="0.5" />
    <path d="M209.98 35.15H178.31" stroke="black" stroke-width="0.5" />
    <path d="M178.31 35.15V36.91" stroke="black" stroke-width="0.5" />
    <path d="M178.31 36.91H164.23" stroke="black" stroke-width="0.5" />
    <path d="M164.23 36.91V37.29" stroke="black" stroke-width="0.5" />
    <path d="M164.23 37.29H152.87" stroke="black" stroke-width="0.5" />
    <path d="M21.73 5.57001V446.86" stroke="black" stroke-width="0.5" />
    <path d="M21.73 465.33V523.3" stroke="black" stroke-width="0.5" />
    <path d="M306.08 232.58V378.16" stroke="black" stroke-width="0.5" />
    <path d="M306.08 378.16H235.24" stroke="black" stroke-width="0.5" />
    <path d="M233.07 378.16H224.38" stroke="black" stroke-width="0.5" />
    <path d="M224.38 378.16V228.29" stroke="black" stroke-width="0.5" />
    <path d="M47.24 287.42H51.42" stroke="black" stroke-width="0.5" />
    <path
      d="M51.42 287.42C51.5367 287.42 51.6486 287.374 51.7311 287.291C51.8136 287.209 51.86 287.097 51.86 286.98C51.86 286.863 51.8136 286.751 51.7311 286.669C51.6486 286.586 51.5367 286.54 51.42 286.54"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M51.42 286.54H87.03" stroke="black" stroke-width="0.5" />
    <path
      d="M87.03 286.54C86.9133 286.54 86.8014 286.586 86.7188 286.669C86.6363 286.751 86.59 286.863 86.59 286.98C86.59 287.097 86.6363 287.209 86.7188 287.291C86.8014 287.374 86.9133 287.42 87.03 287.42"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M87.03 287.42H91.22" stroke="black" stroke-width="0.5" />
    <path d="M91.22 287.42V260.59" stroke="black" stroke-width="0.5" />
    <path d="M91.22 260.59H47.24" stroke="black" stroke-width="0.5" />
    <path d="M47.24 260.59V287.42" stroke="black" stroke-width="0.5" />
    <path d="M52.52 261.47V286.54" stroke="black" stroke-width="0.5" />
    <path d="M85.94 265.87H52.52" stroke="black" stroke-width="0.5" />
    <path d="M85.94 286.54V261.47" stroke="black" stroke-width="0.5" />
    <path d="M48.12 286.54V261.47" stroke="black" stroke-width="0.5" />
    <path d="M48.12 261.47H90.34" stroke="black" stroke-width="0.5" />
    <path d="M90.34 261.47V286.54" stroke="black" stroke-width="0.5" />
    <path d="M51.42 286.54H48.12" stroke="black" stroke-width="0.5" />
    <path d="M87.03 286.54H90.34" stroke="black" stroke-width="0.5" />
    <path d="M107.28 259.71H97.6" stroke="black" stroke-width="0.5" />
    <path d="M97.6 259.71V277.31" stroke="black" stroke-width="0.5" />
    <path d="M97.6 277.31H107.28" stroke="black" stroke-width="0.5" />
    <path d="M107.28 277.31V259.71" stroke="black" stroke-width="0.5" />
    <path d="M107.28 259.71V277.31" stroke="black" stroke-width="0.5" />
    <path d="M107.28 277.31H97.6" stroke="black" stroke-width="0.5" />
    <path d="M97.6 277.31V259.71" stroke="black" stroke-width="0.5" />
    <path d="M97.6 259.71H107.28" stroke="black" stroke-width="0.5" />
    <path d="M41.52 262.35H27.45" stroke="black" stroke-width="0.5" />
    <path d="M27.45 262.35V283.46" stroke="black" stroke-width="0.5" />
    <path d="M27.45 283.46H41.52" stroke="black" stroke-width="0.5" />
    <path d="M41.52 283.46V262.35" stroke="black" stroke-width="0.5" />
    <path d="M41.52 262.35V283.46" stroke="black" stroke-width="0.5" />
    <path d="M41.52 283.46H27.45" stroke="black" stroke-width="0.5" />
    <path d="M27.45 283.46V262.35" stroke="black" stroke-width="0.5" />
    <path d="M27.45 262.35H41.52" stroke="black" stroke-width="0.5" />
    <path d="M132.13 334.39H112.77" stroke="black" stroke-width="0.5" />
    <path d="M112.77 334.39V347.59" stroke="black" stroke-width="0.5" />
    <path d="M112.77 347.59H132.13" stroke="black" stroke-width="0.5" />
    <path d="M132.13 347.59V334.39" stroke="black" stroke-width="0.5" />
    <path d="M132.13 334.39V347.59" stroke="black" stroke-width="0.5" />
    <path d="M132.13 347.59H112.77" stroke="black" stroke-width="0.5" />
    <path d="M112.77 347.59V334.39" stroke="black" stroke-width="0.5" />
    <path d="M112.77 334.39H132.13" stroke="black" stroke-width="0.5" />
    <path d="M221.74 385.09V380.03" stroke="black" stroke-width="0.5" />
    <path d="M226.8 385.09H221.74" stroke="black" stroke-width="0.5" />
    <path d="M47.9 440.81V413.98" stroke="black" stroke-width="0.5" />
    <path d="M91.88 440.81H47.9" stroke="black" stroke-width="0.5" />
    <path d="M91.88 413.98V440.81" stroke="black" stroke-width="0.5" />
    <path d="M87.69 413.98H91.88" stroke="black" stroke-width="0.5" />
    <path
      d="M87.69 413.98C87.5733 413.98 87.4614 414.026 87.3789 414.109C87.2964 414.191 87.25 414.303 87.25 414.42C87.25 414.537 87.2964 414.649 87.3789 414.731C87.4614 414.814 87.5733 414.86 87.69 414.86"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M52.08 414.86H87.69" stroke="black" stroke-width="0.5" />
    <path
      d="M52.08 414.86C52.1967 414.86 52.3086 414.814 52.3911 414.731C52.4737 414.649 52.52 414.537 52.52 414.42C52.52 414.303 52.4737 414.191 52.3911 414.109C52.3086 414.026 52.1967 413.98 52.08 413.98"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M47.9 413.98H52.08" stroke="black" stroke-width="0.5" />
    <path d="M53.18 439.93V414.86" stroke="black" stroke-width="0.5" />
    <path d="M86.6 435.54H53.18" stroke="black" stroke-width="0.5" />
    <path d="M86.6 414.86V439.93" stroke="black" stroke-width="0.5" />
    <path d="M48.78 414.86V439.93" stroke="black" stroke-width="0.5" />
    <path d="M48.78 439.93H91" stroke="black" stroke-width="0.5" />
    <path d="M91 439.93V414.86" stroke="black" stroke-width="0.5" />
    <path d="M52.08 414.86H48.78" stroke="black" stroke-width="0.5" />
    <path d="M87.69 414.86H91" stroke="black" stroke-width="0.5" />
    <path d="M109.03 440.81V423.22" stroke="black" stroke-width="0.5" />
    <path d="M109.03 423.22H99.36" stroke="black" stroke-width="0.5" />
    <path d="M99.36 423.22V440.81" stroke="black" stroke-width="0.5" />
    <path d="M99.36 440.81H109.03" stroke="black" stroke-width="0.5" />
    <path d="M109.03 440.81H99.36" stroke="black" stroke-width="0.5" />
    <path d="M99.36 440.81V423.22" stroke="black" stroke-width="0.5" />
    <path d="M99.36 423.22H109.03" stroke="black" stroke-width="0.5" />
    <path d="M109.03 423.22V440.81" stroke="black" stroke-width="0.5" />
    <path d="M43.06 439.05V417.94" stroke="black" stroke-width="0.5" />
    <path d="M43.06 417.94H28.99" stroke="black" stroke-width="0.5" />
    <path d="M28.99 417.94V439.05" stroke="black" stroke-width="0.5" />
    <path d="M28.99 439.05H43.06" stroke="black" stroke-width="0.5" />
    <path d="M43.06 439.05H28.99" stroke="black" stroke-width="0.5" />
    <path d="M28.99 439.05V417.94" stroke="black" stroke-width="0.5" />
    <path d="M28.99 417.94H43.06" stroke="black" stroke-width="0.5" />
    <path d="M43.06 417.94V439.05" stroke="black" stroke-width="0.5" />
    <path d="M132.13 367.02V353.82" stroke="black" stroke-width="0.5" />
    <path d="M132.13 353.82H112.77" stroke="black" stroke-width="0.5" />
    <path d="M112.77 353.82V367.02" stroke="black" stroke-width="0.5" />
    <path d="M112.77 367.02H132.13" stroke="black" stroke-width="0.5" />
    <path d="M132.13 367.02H112.77" stroke="black" stroke-width="0.5" />
    <path d="M112.77 367.02V353.82" stroke="black" stroke-width="0.5" />
    <path d="M112.77 353.82H132.13" stroke="black" stroke-width="0.5" />
    <path d="M132.13 353.82V367.02" stroke="black" stroke-width="0.5" />
    <path d="M675.21 406.95H671.03" stroke="black" stroke-width="0.5" />
    <path
      d="M671.03 406.95C670.913 406.95 670.801 406.996 670.719 407.079C670.636 407.161 670.59 407.273 670.59 407.39C670.59 407.507 670.636 407.619 670.719 407.701C670.801 407.784 670.913 407.83 671.03 407.83"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M671.03 407.83H635.41" stroke="black" stroke-width="0.5" />
    <path
      d="M635.41 407.83C635.527 407.83 635.639 407.784 635.721 407.701C635.804 407.619 635.85 407.507 635.85 407.39C635.85 407.273 635.804 407.161 635.721 407.079C635.639 406.996 635.527 406.95 635.41 406.95"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M635.41 406.95H631.22" stroke="black" stroke-width="0.5" />
    <path d="M631.22 406.95V433.78" stroke="black" stroke-width="0.5" />
    <path d="M631.22 433.78H675.21" stroke="black" stroke-width="0.5" />
    <path d="M675.21 433.78V406.95" stroke="black" stroke-width="0.5" />
    <path d="M669.93 432.9V407.83" stroke="black" stroke-width="0.5" />
    <path d="M636.5 428.5H669.93" stroke="black" stroke-width="0.5" />
    <path d="M636.5 407.83V432.9" stroke="black" stroke-width="0.5" />
    <path d="M674.33 407.83V432.9" stroke="black" stroke-width="0.5" />
    <path d="M674.33 432.9H632.1" stroke="black" stroke-width="0.5" />
    <path d="M632.1 432.9V407.83" stroke="black" stroke-width="0.5" />
    <path d="M671.03 407.83H674.33" stroke="black" stroke-width="0.5" />
    <path d="M635.41 407.83H632.1" stroke="black" stroke-width="0.5" />
    <path d="M618.47 434.66H628.14" stroke="black" stroke-width="0.5" />
    <path d="M628.14 434.66V417.06" stroke="black" stroke-width="0.5" />
    <path d="M628.14 417.06H618.47" stroke="black" stroke-width="0.5" />
    <path d="M618.47 417.06V434.66" stroke="black" stroke-width="0.5" />
    <path d="M618.47 434.66V417.06" stroke="black" stroke-width="0.5" />
    <path d="M618.47 417.06H628.14" stroke="black" stroke-width="0.5" />
    <path d="M628.14 417.06V434.66" stroke="black" stroke-width="0.5" />
    <path d="M628.14 434.66H618.47" stroke="black" stroke-width="0.5" />
    <path d="M678.28 432.02H692.36" stroke="black" stroke-width="0.5" />
    <path d="M692.36 432.02V410.91" stroke="black" stroke-width="0.5" />
    <path d="M692.36 410.91H678.28" stroke="black" stroke-width="0.5" />
    <path d="M678.28 410.91V432.02" stroke="black" stroke-width="0.5" />
    <path d="M678.28 432.02V410.91" stroke="black" stroke-width="0.5" />
    <path d="M678.28 410.91H692.36" stroke="black" stroke-width="0.5" />
    <path d="M692.36 410.91V432.02" stroke="black" stroke-width="0.5" />
    <path d="M692.36 432.02H678.28" stroke="black" stroke-width="0.5" />
    <path d="M598.24 345.9H617.59" stroke="black" stroke-width="0.5" />
    <path d="M617.59 345.9V332.71" stroke="black" stroke-width="0.5" />
    <path d="M617.59 332.71H598.24" stroke="black" stroke-width="0.5" />
    <path d="M598.24 332.71V345.9" stroke="black" stroke-width="0.5" />
    <path d="M598.24 345.9V332.71" stroke="black" stroke-width="0.5" />
    <path d="M598.24 332.71H617.59" stroke="black" stroke-width="0.5" />
    <path d="M617.59 332.71V345.9" stroke="black" stroke-width="0.5" />
    <path d="M617.59 345.9H598.24" stroke="black" stroke-width="0.5" />
    <path d="M673.89 224.31V251.14" stroke="black" stroke-width="0.5" />
    <path d="M629.9 224.31H673.89" stroke="black" stroke-width="0.5" />
    <path d="M629.9 251.14V224.31" stroke="black" stroke-width="0.5" />
    <path d="M634.09 251.14H629.9" stroke="black" stroke-width="0.5" />
    <path
      d="M634.09 251.14C634.207 251.14 634.319 251.094 634.401 251.011C634.484 250.929 634.53 250.817 634.53 250.7C634.53 250.583 634.484 250.471 634.401 250.389C634.319 250.306 634.207 250.26 634.09 250.26"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M669.71 250.26H634.09" stroke="black" stroke-width="0.5" />
    <path
      d="M669.71 250.26C669.593 250.26 669.481 250.306 669.399 250.389C669.316 250.471 669.27 250.583 669.27 250.7C669.27 250.817 669.316 250.929 669.399 251.011C669.481 251.094 669.593 251.14 669.71 251.14"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M673.89 251.14H669.71" stroke="black" stroke-width="0.5" />
    <path d="M668.61 225.19V250.26" stroke="black" stroke-width="0.5" />
    <path d="M635.18 229.59H668.61" stroke="black" stroke-width="0.5" />
    <path d="M635.18 250.26V225.19" stroke="black" stroke-width="0.5" />
    <path d="M673.01 250.26V225.19" stroke="black" stroke-width="0.5" />
    <path d="M673.01 225.19H630.78" stroke="black" stroke-width="0.5" />
    <path d="M630.78 225.19V250.26" stroke="black" stroke-width="0.5" />
    <path d="M669.71 250.26H673.01" stroke="black" stroke-width="0.5" />
    <path d="M634.09 250.26H630.78" stroke="black" stroke-width="0.5" />
    <path d="M617.15 223.65V241.24" stroke="black" stroke-width="0.5" />
    <path d="M617.15 241.24H626.82" stroke="black" stroke-width="0.5" />
    <path d="M626.82 241.24V223.65" stroke="black" stroke-width="0.5" />
    <path d="M626.82 223.65H617.15" stroke="black" stroke-width="0.5" />
    <path d="M617.15 223.65H626.82" stroke="black" stroke-width="0.5" />
    <path d="M626.82 223.65V241.24" stroke="black" stroke-width="0.5" />
    <path d="M626.82 241.24H617.15" stroke="black" stroke-width="0.5" />
    <path d="M617.15 241.24V223.65" stroke="black" stroke-width="0.5" />
    <path d="M676.64 228.84V249.95" stroke="black" stroke-width="0.5" />
    <path d="M676.64 249.95H690.71" stroke="black" stroke-width="0.5" />
    <path d="M690.71 249.95V228.84" stroke="black" stroke-width="0.5" />
    <path d="M690.71 228.84H676.64" stroke="black" stroke-width="0.5" />
    <path d="M676.64 228.84H690.71" stroke="black" stroke-width="0.5" />
    <path d="M690.71 228.84V249.95" stroke="black" stroke-width="0.5" />
    <path d="M690.71 249.95H676.64" stroke="black" stroke-width="0.5" />
    <path d="M676.64 249.95V228.84" stroke="black" stroke-width="0.5" />
    <path d="M598.24 312.18V325.38" stroke="black" stroke-width="0.5" />
    <path d="M598.24 325.38H617.59" stroke="black" stroke-width="0.5" />
    <path d="M617.59 325.38V312.18" stroke="black" stroke-width="0.5" />
    <path d="M617.59 312.18H598.24" stroke="black" stroke-width="0.5" />
    <path d="M598.24 312.18H617.59" stroke="black" stroke-width="0.5" />
    <path d="M617.59 312.18V325.38" stroke="black" stroke-width="0.5" />
    <path d="M617.59 325.38H598.24" stroke="black" stroke-width="0.5" />
    <path d="M598.24 325.38V312.18" stroke="black" stroke-width="0.5" />
    <path d="M681.8 115.23V142.06" stroke="black" stroke-width="0.5" />
    <path d="M637.82 115.23H681.8" stroke="black" stroke-width="0.5" />
    <path d="M637.82 142.06V115.23" stroke="black" stroke-width="0.5" />
    <path d="M642.01 142.06H637.82" stroke="black" stroke-width="0.5" />
    <path
      d="M642.01 142.06C642.127 142.06 642.239 142.014 642.321 141.931C642.404 141.849 642.45 141.737 642.45 141.62C642.45 141.503 642.404 141.391 642.321 141.309C642.239 141.226 642.127 141.18 642.01 141.18"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M677.62 141.18H642.01" stroke="black" stroke-width="0.5" />
    <path
      d="M677.62 141.18C677.503 141.18 677.391 141.226 677.309 141.309C677.226 141.391 677.18 141.503 677.18 141.62C677.18 141.737 677.226 141.849 677.309 141.931C677.391 142.014 677.503 142.06 677.62 142.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M681.8 142.06H677.62" stroke="black" stroke-width="0.5" />
    <path d="M676.53 116.11V141.18" stroke="black" stroke-width="0.5" />
    <path d="M643.1 120.51H676.53" stroke="black" stroke-width="0.5" />
    <path d="M643.1 141.18V116.11" stroke="black" stroke-width="0.5" />
    <path d="M680.92 141.18V116.11" stroke="black" stroke-width="0.5" />
    <path d="M680.92 116.11H638.7" stroke="black" stroke-width="0.5" />
    <path d="M638.7 116.11V141.18" stroke="black" stroke-width="0.5" />
    <path d="M677.62 141.18H680.92" stroke="black" stroke-width="0.5" />
    <path d="M642.01 141.18H638.7" stroke="black" stroke-width="0.5" />
    <path d="M621.99 115.23V132.82" stroke="black" stroke-width="0.5" />
    <path d="M621.99 132.82H631.66" stroke="black" stroke-width="0.5" />
    <path d="M631.66 132.82V115.23" stroke="black" stroke-width="0.5" />
    <path d="M631.66 115.23H621.99" stroke="black" stroke-width="0.5" />
    <path d="M621.99 115.23H631.66" stroke="black" stroke-width="0.5" />
    <path d="M631.66 115.23V132.82" stroke="black" stroke-width="0.5" />
    <path d="M631.66 132.82H621.99" stroke="black" stroke-width="0.5" />
    <path d="M621.99 132.82V115.23" stroke="black" stroke-width="0.5" />
    <path d="M686.64 116.99V138.1" stroke="black" stroke-width="0.5" />
    <path d="M686.64 138.1H700.72" stroke="black" stroke-width="0.5" />
    <path d="M700.72 138.1V116.99" stroke="black" stroke-width="0.5" />
    <path d="M700.72 116.99H686.64" stroke="black" stroke-width="0.5" />
    <path d="M686.64 116.99H700.72" stroke="black" stroke-width="0.5" />
    <path d="M700.72 116.99V138.1" stroke="black" stroke-width="0.5" />
    <path d="M700.72 138.1H686.64" stroke="black" stroke-width="0.5" />
    <path d="M686.64 138.1V116.99" stroke="black" stroke-width="0.5" />
    <path d="M598.24 203.1V216.3" stroke="black" stroke-width="0.5" />
    <path d="M598.24 216.3H617.59" stroke="black" stroke-width="0.5" />
    <path d="M617.59 216.3V203.1" stroke="black" stroke-width="0.5" />
    <path d="M617.59 203.1H598.24" stroke="black" stroke-width="0.5" />
    <path d="M598.24 203.1H617.59" stroke="black" stroke-width="0.5" />
    <path d="M617.59 203.1V216.3" stroke="black" stroke-width="0.5" />
    <path d="M617.59 216.3H598.24" stroke="black" stroke-width="0.5" />
    <path d="M598.24 216.3V203.1" stroke="black" stroke-width="0.5" />
    <path d="M376.78 464.92V508.02" stroke="black" stroke-width="0.5" />
    <path d="M376.78 508.02V464.92" stroke="black" stroke-width="0.5" />
    <path d="M376.78 465.8V507.14" stroke="black" stroke-width="0.5" />
    <path d="M376.78 507.14V465.8" stroke="black" stroke-width="0.5" />
    <path d="M375.68 464.92V508.02" stroke="black" stroke-width="0.5" />
    <path d="M375.68 508.02V464.92" stroke="black" stroke-width="0.5" />
    <path d="M376.78 464.92H375.68" stroke="black" stroke-width="0.5" />
    <path d="M375.68 508.02H376.78" stroke="black" stroke-width="0.5" />
    <path d="M375.68 508.02H376.78" stroke="black" stroke-width="0.5" />
    <path d="M375.68 464.92H376.78" stroke="black" stroke-width="0.5" />
    <path d="M370.29 508.02V464.92" stroke="black" stroke-width="0.5" />
    <path d="M370.29 464.92V508.02" stroke="black" stroke-width="0.5" />
    <path d="M370.29 507.14V465.8" stroke="black" stroke-width="0.5" />
    <path d="M370.29 465.8V507.14" stroke="black" stroke-width="0.5" />
    <path d="M371.39 508.02V464.92" stroke="black" stroke-width="0.5" />
    <path d="M371.39 464.92V508.02" stroke="black" stroke-width="0.5" />
    <path d="M370.29 508.02H371.39" stroke="black" stroke-width="0.5" />
    <path d="M371.39 464.92H370.29" stroke="black" stroke-width="0.5" />
    <path d="M371.39 464.92H370.29" stroke="black" stroke-width="0.5" />
    <path d="M371.39 508.02H370.29" stroke="black" stroke-width="0.5" />
    <path d="M130.37 442.57H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 442.57V420.58" stroke="black" stroke-width="0.5" />
    <path d="M167.31 420.58H130.37" stroke="black" stroke-width="0.5" />
    <path d="M130.37 420.58V442.57" stroke="black" stroke-width="0.5" />
    <path d="M130.37 441.91H167.31" stroke="black" stroke-width="0.5" />
    <path d="M166.65 420.58V441.91" stroke="black" stroke-width="0.5" />
    <path d="M131.03 441.91V420.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 435.23H602.52" stroke="black" stroke-width="0.5" />
    <path d="M602.52 435.23V413.24" stroke="black" stroke-width="0.5" />
    <path d="M602.52 413.24H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 413.24V435.23" stroke="black" stroke-width="0.5" />
    <path d="M565.58 434.57H602.52" stroke="black" stroke-width="0.5" />
    <path d="M601.86 413.24V434.57" stroke="black" stroke-width="0.5" />
    <path d="M566.24 434.57V413.24" stroke="black" stroke-width="0.5" />
    <path d="M602.52 222.11H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 222.11V244.1" stroke="black" stroke-width="0.5" />
    <path d="M565.58 244.1H602.52" stroke="black" stroke-width="0.5" />
    <path d="M602.52 244.1V222.11" stroke="black" stroke-width="0.5" />
    <path d="M602.52 222.77H565.58" stroke="black" stroke-width="0.5" />
    <path d="M566.24 244.1V222.77" stroke="black" stroke-width="0.5" />
    <path d="M601.86 222.77V244.1" stroke="black" stroke-width="0.5" />
    <path d="M602.52 113.14H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 113.14V135.13" stroke="black" stroke-width="0.5" />
    <path d="M565.58 135.13H602.52" stroke="black" stroke-width="0.5" />
    <path d="M602.52 135.13V113.14" stroke="black" stroke-width="0.5" />
    <path d="M602.52 113.8H565.58" stroke="black" stroke-width="0.5" />
    <path d="M566.24 135.13V113.8" stroke="black" stroke-width="0.5" />
    <path d="M601.86 113.8V135.13" stroke="black" stroke-width="0.5" />
    <path
      d="M257.59 19.57C257.59 18.8766 257.453 18.19 257.188 17.5494C256.923 16.9088 256.534 16.3267 256.044 15.8365C255.553 15.3462 254.971 14.9572 254.331 14.6919C253.69 14.4265 253.003 14.29 252.31 14.29C251.617 14.29 250.93 14.4265 250.289 14.6919C249.649 14.9572 249.067 15.3462 248.576 15.8365C248.086 16.3267 247.697 16.9088 247.432 17.5494C247.167 18.19 247.03 18.8766 247.03 19.57"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M247.03 19.57C247.03 20.9704 247.586 22.3133 248.576 23.3035C249.567 24.2937 250.91 24.85 252.31 24.85C253.71 24.85 255.053 24.2937 256.044 23.3035C257.034 22.3133 257.59 20.9704 257.59 19.57"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M217.34 538.26V537.6" stroke="black" stroke-width="0.5" />
    <path d="M217.34 537.6H175.12" stroke="black" stroke-width="0.5" />
    <path d="M175.12 537.6V538.26" stroke="black" stroke-width="0.5" />
    <path d="M175.12 538.26H217.34" stroke="black" stroke-width="0.5" />
    <path d="M151.48 441.69H151.42" stroke="black" stroke-width="0.5" />
    <path d="M151.37 441.69H151.26" stroke="black" stroke-width="0.5" />
    <path d="M151.2 441.69H151.09" stroke="black" stroke-width="0.5" />
    <path d="M151.04 441.69H150.93" stroke="black" stroke-width="0.5" />
    <path d="M150.87 441.69H150.76" stroke="black" stroke-width="0.5" />
    <path d="M150.71 441.69H150.6" stroke="black" stroke-width="0.5" />
    <path d="M150.54 441.69H150.43" stroke="black" stroke-width="0.5" />
    <path d="M150.38 441.69H150.27" stroke="black" stroke-width="0.5" />
    <path d="M150.21 441.69H150.1" stroke="black" stroke-width="0.5" />
    <path d="M150.05 441.69H149.94" stroke="black" stroke-width="0.5" />
    <path d="M149.88 441.69H149.77" stroke="black" stroke-width="0.5" />
    <path d="M149.72 441.69H149.61" stroke="black" stroke-width="0.5" />
    <path d="M149.55 441.69H149.44" stroke="black" stroke-width="0.5" />
    <path d="M149.39 441.69H149.28" stroke="black" stroke-width="0.5" />
    <path d="M149.22 441.69H149.11" stroke="black" stroke-width="0.5" />
    <path d="M149.06 441.69H148.95" stroke="black" stroke-width="0.5" />
    <path d="M148.89 441.69H148.78" stroke="black" stroke-width="0.5" />
    <path d="M148.73 441.69H148.62" stroke="black" stroke-width="0.5" />
    <path d="M148.56 441.69H148.45" stroke="black" stroke-width="0.5" />
    <path d="M148.4 441.69H148.29" stroke="black" stroke-width="0.5" />
    <path d="M148.23 441.69H148.12" stroke="black" stroke-width="0.5" />
    <path d="M148.07 441.69H147.96" stroke="black" stroke-width="0.5" />
    <path d="M147.9 441.69H147.79" stroke="black" stroke-width="0.5" />
    <path d="M147.74 441.69H147.63" stroke="black" stroke-width="0.5" />
    <path d="M147.57 441.69H147.46" stroke="black" stroke-width="0.5" />
    <path d="M147.41 441.69H147.3" stroke="black" stroke-width="0.5" />
    <path d="M147.25 441.69H147.14" stroke="black" stroke-width="0.5" />
    <path d="M147.08 441.69H146.97" stroke="black" stroke-width="0.5" />
    <path d="M146.92 441.69H146.81" stroke="black" stroke-width="0.5" />
    <path d="M146.75 441.69H146.64" stroke="black" stroke-width="0.5" />
    <path d="M146.59 441.69H146.48" stroke="black" stroke-width="0.5" />
    <path d="M146.42 441.69H146.31" stroke="black" stroke-width="0.5" />
    <path d="M146.26 441.69H146.15" stroke="black" stroke-width="0.5" />
    <path d="M146.09 441.69H145.98" stroke="black" stroke-width="0.5" />
    <path d="M145.93 441.69H145.82" stroke="black" stroke-width="0.5" />
    <path d="M145.76 441.69H145.65" stroke="black" stroke-width="0.5" />
    <path d="M145.6 441.69H145.49" stroke="black" stroke-width="0.5" />
    <path d="M145.43 441.69H145.32" stroke="black" stroke-width="0.5" />
    <path d="M145.27 441.69H145.16" stroke="black" stroke-width="0.5" />
    <path d="M145.1 441.69H144.99" stroke="black" stroke-width="0.5" />
    <path d="M144.94 441.69H144.83" stroke="black" stroke-width="0.5" />
    <path d="M144.77 441.69H144.66" stroke="black" stroke-width="0.5" />
    <path d="M144.61 441.69H144.5" stroke="black" stroke-width="0.5" />
    <path d="M144.44 441.69H144.33" stroke="black" stroke-width="0.5" />
    <path d="M144.28 441.69H144.17" stroke="black" stroke-width="0.5" />
    <path d="M144.11 441.69H144" stroke="black" stroke-width="0.5" />
    <path d="M143.95 441.69H143.84" stroke="black" stroke-width="0.5" />
    <path d="M143.78 441.69H143.67" stroke="black" stroke-width="0.5" />
    <path d="M143.62 441.69H143.51" stroke="black" stroke-width="0.5" />
    <path d="M143.45 441.69H143.34" stroke="black" stroke-width="0.5" />
    <path d="M143.29 441.69H143.18" stroke="black" stroke-width="0.5" />
    <path d="M143.12 441.69H143.01" stroke="black" stroke-width="0.5" />
    <path d="M142.96 441.69H142.85" stroke="black" stroke-width="0.5" />
    <path d="M142.79 441.69H142.68" stroke="black" stroke-width="0.5" />
    <path d="M142.63 441.69H142.52" stroke="black" stroke-width="0.5" />
    <path d="M142.46 441.69H142.35" stroke="black" stroke-width="0.5" />
    <path d="M142.3 441.69H142.19" stroke="black" stroke-width="0.5" />
    <path d="M142.13 441.69H142.02" stroke="black" stroke-width="0.5" />
    <path d="M141.97 441.69H141.86" stroke="black" stroke-width="0.5" />
    <path d="M141.8 441.69H141.69" stroke="black" stroke-width="0.5" />
    <path d="M141.64 441.69H141.53" stroke="black" stroke-width="0.5" />
    <path d="M141.47 441.69H141.36" stroke="black" stroke-width="0.5" />
    <path d="M141.31 441.69H141.2" stroke="black" stroke-width="0.5" />
    <path d="M141.14 441.69H141.03" stroke="black" stroke-width="0.5" />
    <path d="M140.98 441.69H140.87" stroke="black" stroke-width="0.5" />
    <path d="M140.81 441.69H140.7" stroke="black" stroke-width="0.5" />
    <path d="M140.65 441.69H140.54" stroke="black" stroke-width="0.5" />
    <path d="M140.48 441.69H140.37" stroke="black" stroke-width="0.5" />
    <path d="M140.32 441.69H140.21" stroke="black" stroke-width="0.5" />
    <path d="M140.15 441.69H140.04" stroke="black" stroke-width="0.5" />
    <path d="M139.99 441.69H139.88" stroke="black" stroke-width="0.5" />
    <path d="M139.82 441.69H139.71" stroke="black" stroke-width="0.5" />
    <path d="M139.66 441.69H139.55" stroke="black" stroke-width="0.5" />
    <path d="M139.49 441.69H139.38" stroke="black" stroke-width="0.5" />
    <path d="M139.33 441.69H139.22" stroke="black" stroke-width="0.5" />
    <path d="M139.16 441.69H139.05" stroke="black" stroke-width="0.5" />
    <path d="M139 441.69H138.89" stroke="black" stroke-width="0.5" />
    <path d="M138.83 441.69H138.72" stroke="black" stroke-width="0.5" />
    <path d="M138.67 441.69H138.56" stroke="black" stroke-width="0.5" />
    <path d="M138.5 441.69H138.39" stroke="black" stroke-width="0.5" />
    <path d="M138.34 441.69H138.23" stroke="black" stroke-width="0.5" />
    <path d="M138.17 441.69H138.06" stroke="black" stroke-width="0.5" />
    <path d="M138.01 441.69H137.9" stroke="black" stroke-width="0.5" />
    <path d="M137.84 441.69H137.73" stroke="black" stroke-width="0.5" />
    <path d="M137.68 441.69H137.57" stroke="black" stroke-width="0.5" />
    <path d="M137.51 441.69H137.4" stroke="black" stroke-width="0.5" />
    <path d="M137.35 441.69H137.24" stroke="black" stroke-width="0.5" />
    <path d="M137.18 441.69H137.07" stroke="black" stroke-width="0.5" />
    <path d="M137.02 441.69H136.91" stroke="black" stroke-width="0.5" />
    <path d="M136.85 441.69H136.74" stroke="black" stroke-width="0.5" />
    <path d="M136.69 441.69H136.58" stroke="black" stroke-width="0.5" />
    <path d="M136.52 441.69H136.41" stroke="black" stroke-width="0.5" />
    <path d="M136.36 441.69H136.25" stroke="black" stroke-width="0.5" />
    <path d="M136.19 441.69H136.08" stroke="black" stroke-width="0.5" />
    <path d="M136.03 441.69H135.92" stroke="black" stroke-width="0.5" />
    <path d="M135.86 441.69H135.75" stroke="black" stroke-width="0.5" />
    <path d="M135.7 441.69H135.59" stroke="black" stroke-width="0.5" />
    <path d="M135.53 441.69H135.42" stroke="black" stroke-width="0.5" />
    <path d="M135.37 441.69H135.26" stroke="black" stroke-width="0.5" />
    <path d="M135.2 441.69H135.09" stroke="black" stroke-width="0.5" />
    <path d="M135.04 441.69H134.93" stroke="black" stroke-width="0.5" />
    <path d="M134.87 441.69H134.76" stroke="black" stroke-width="0.5" />
    <path d="M134.71 441.69H134.6" stroke="black" stroke-width="0.5" />
    <path d="M134.54 441.69H134.43" stroke="black" stroke-width="0.5" />
    <path d="M134.38 441.69H134.27" stroke="black" stroke-width="0.5" />
    <path d="M134.22 441.69H134.11" stroke="black" stroke-width="0.5" />
    <path d="M134.05 441.69H133.94" stroke="black" stroke-width="0.5" />
    <path d="M133.89 441.69H133.78" stroke="black" stroke-width="0.5" />
    <path d="M133.72 441.69H133.61" stroke="black" stroke-width="0.5" />
    <path d="M133.56 441.69H133.45" stroke="black" stroke-width="0.5" />
    <path d="M133.39 441.69H133.28" stroke="black" stroke-width="0.5" />
    <path d="M133.23 441.69H133.12" stroke="black" stroke-width="0.5" />
    <path d="M133.06 441.69H132.95" stroke="black" stroke-width="0.5" />
    <path d="M132.9 441.69H132.79" stroke="black" stroke-width="0.5" />
    <path d="M132.73 441.69H132.62" stroke="black" stroke-width="0.5" />
    <path d="M132.57 441.69H132.46" stroke="black" stroke-width="0.5" />
    <path d="M132.4 441.69H132.29" stroke="black" stroke-width="0.5" />
    <path d="M132.24 441.69H132.13" stroke="black" stroke-width="0.5" />
    <path d="M132.07 441.69H131.96" stroke="black" stroke-width="0.5" />
    <path d="M131.91 441.69H131.8" stroke="black" stroke-width="0.5" />
    <path d="M131.74 441.69H131.63" stroke="black" stroke-width="0.5" />
    <path d="M131.58 441.69H131.47" stroke="black" stroke-width="0.5" />
    <path d="M131.41 441.69H131.3" stroke="black" stroke-width="0.5" />
    <path d="M131.25 441.69H131.14" stroke="black" stroke-width="0.5" />
    <path d="M131.08 441.69H130.97" stroke="black" stroke-width="0.5" />
    <path d="M130.92 441.69H130.81" stroke="black" stroke-width="0.5" />
    <path d="M130.75 441.69H130.64" stroke="black" stroke-width="0.5" />
    <path d="M130.59 441.69H130.48" stroke="black" stroke-width="0.5" />
    <path d="M130.42 441.69H130.37" stroke="black" stroke-width="0.5" />
    <path d="M130.37 420.58V420.64" stroke="black" stroke-width="0.5" />
    <path d="M130.37 420.69V420.8" stroke="black" stroke-width="0.5" />
    <path d="M130.37 420.86V420.97" stroke="black" stroke-width="0.5" />
    <path d="M130.37 421.02V421.13" stroke="black" stroke-width="0.5" />
    <path d="M130.37 421.19V421.3" stroke="black" stroke-width="0.5" />
    <path d="M130.37 421.35V421.46" stroke="black" stroke-width="0.5" />
    <path d="M130.37 421.52V421.63" stroke="black" stroke-width="0.5" />
    <path d="M130.37 421.68V421.79" stroke="black" stroke-width="0.5" />
    <path d="M130.37 421.85V421.96" stroke="black" stroke-width="0.5" />
    <path d="M130.37 422.01V422.12" stroke="black" stroke-width="0.5" />
    <path d="M130.37 422.18V422.29" stroke="black" stroke-width="0.5" />
    <path d="M130.37 422.34V422.45" stroke="black" stroke-width="0.5" />
    <path d="M130.37 422.51V422.62" stroke="black" stroke-width="0.5" />
    <path d="M130.37 422.67V422.78" stroke="black" stroke-width="0.5" />
    <path d="M130.37 422.84V422.95" stroke="black" stroke-width="0.5" />
    <path d="M130.37 423V423.11" stroke="black" stroke-width="0.5" />
    <path d="M130.37 423.17V423.28" stroke="black" stroke-width="0.5" />
    <path d="M130.37 423.33V423.44" stroke="black" stroke-width="0.5" />
    <path d="M130.37 423.5V423.61" stroke="black" stroke-width="0.5" />
    <path d="M130.37 423.66V423.77" stroke="black" stroke-width="0.5" />
    <path d="M130.37 423.83V423.94" stroke="black" stroke-width="0.5" />
    <path d="M130.37 423.99V424.1" stroke="black" stroke-width="0.5" />
    <path d="M130.37 424.16V424.27" stroke="black" stroke-width="0.5" />
    <path d="M130.37 424.32V424.43" stroke="black" stroke-width="0.5" />
    <path d="M130.37 424.49V424.6" stroke="black" stroke-width="0.5" />
    <path d="M130.37 424.65V424.76" stroke="black" stroke-width="0.5" />
    <path d="M130.37 424.82V424.93" stroke="black" stroke-width="0.5" />
    <path d="M130.37 424.98V425.09" stroke="black" stroke-width="0.5" />
    <path d="M130.37 425.14V425.25" stroke="black" stroke-width="0.5" />
    <path d="M130.37 425.31V425.42" stroke="black" stroke-width="0.5" />
    <path d="M130.37 425.47V425.58" stroke="black" stroke-width="0.5" />
    <path d="M130.37 425.64V425.75" stroke="black" stroke-width="0.5" />
    <path d="M130.37 425.8V425.91" stroke="black" stroke-width="0.5" />
    <path d="M130.37 425.97V426.08" stroke="black" stroke-width="0.5" />
    <path d="M130.37 426.13V426.24" stroke="black" stroke-width="0.5" />
    <path d="M130.37 426.3V426.41" stroke="black" stroke-width="0.5" />
    <path d="M130.37 426.46V426.57" stroke="black" stroke-width="0.5" />
    <path d="M130.37 426.63V426.74" stroke="black" stroke-width="0.5" />
    <path d="M130.37 426.79V426.9" stroke="black" stroke-width="0.5" />
    <path d="M130.37 426.96V427.07" stroke="black" stroke-width="0.5" />
    <path d="M130.37 427.12V427.23" stroke="black" stroke-width="0.5" />
    <path d="M130.37 427.29V427.4" stroke="black" stroke-width="0.5" />
    <path d="M130.37 427.45V427.56" stroke="black" stroke-width="0.5" />
    <path d="M130.37 427.62V427.73" stroke="black" stroke-width="0.5" />
    <path d="M130.37 427.78V427.89" stroke="black" stroke-width="0.5" />
    <path d="M130.37 427.95V428.06" stroke="black" stroke-width="0.5" />
    <path d="M130.37 428.11V428.22" stroke="black" stroke-width="0.5" />
    <path d="M130.37 428.28V428.39" stroke="black" stroke-width="0.5" />
    <path d="M130.37 428.44V428.55" stroke="black" stroke-width="0.5" />
    <path d="M130.37 428.61V428.72" stroke="black" stroke-width="0.5" />
    <path d="M130.37 428.77V428.88" stroke="black" stroke-width="0.5" />
    <path d="M130.37 428.94V429.05" stroke="black" stroke-width="0.5" />
    <path d="M130.37 429.1V429.21" stroke="black" stroke-width="0.5" />
    <path d="M130.37 429.27V429.38" stroke="black" stroke-width="0.5" />
    <path d="M130.37 429.43V429.54" stroke="black" stroke-width="0.5" />
    <path d="M130.37 429.6V429.71" stroke="black" stroke-width="0.5" />
    <path d="M130.37 429.76V429.87" stroke="black" stroke-width="0.5" />
    <path d="M130.37 429.93V430.04" stroke="black" stroke-width="0.5" />
    <path d="M130.37 430.09V430.2" stroke="black" stroke-width="0.5" />
    <path d="M130.37 430.26V430.37" stroke="black" stroke-width="0.5" />
    <path d="M130.37 430.42V430.53" stroke="black" stroke-width="0.5" />
    <path d="M130.37 430.59V430.7" stroke="black" stroke-width="0.5" />
    <path d="M130.37 430.75V430.86" stroke="black" stroke-width="0.5" />
    <path d="M130.37 430.92V431.03" stroke="black" stroke-width="0.5" />
    <path d="M130.37 431.08V431.19" stroke="black" stroke-width="0.5" />
    <path d="M130.37 431.25V431.36" stroke="black" stroke-width="0.5" />
    <path d="M130.37 431.41V431.52" stroke="black" stroke-width="0.5" />
    <path d="M130.37 431.58V431.69" stroke="black" stroke-width="0.5" />
    <path d="M130.37 431.74V431.85" stroke="black" stroke-width="0.5" />
    <path d="M130.37 431.91V432.02" stroke="black" stroke-width="0.5" />
    <path d="M130.37 432.07V432.18" stroke="black" stroke-width="0.5" />
    <path d="M130.37 432.24V432.35" stroke="black" stroke-width="0.5" />
    <path d="M130.37 432.4V432.51" stroke="black" stroke-width="0.5" />
    <path d="M130.37 432.57V432.68" stroke="black" stroke-width="0.5" />
    <path d="M130.37 432.73V432.84" stroke="black" stroke-width="0.5" />
    <path d="M130.37 432.9V433.01" stroke="black" stroke-width="0.5" />
    <path d="M130.37 433.06V433.17" stroke="black" stroke-width="0.5" />
    <path d="M130.37 433.23V433.34" stroke="black" stroke-width="0.5" />
    <path d="M130.37 433.39V433.5" stroke="black" stroke-width="0.5" />
    <path d="M130.37 433.56V433.67" stroke="black" stroke-width="0.5" />
    <path d="M130.37 433.72V433.83" stroke="black" stroke-width="0.5" />
    <path d="M130.37 433.89V434" stroke="black" stroke-width="0.5" />
    <path d="M130.37 434.05V434.16" stroke="black" stroke-width="0.5" />
    <path d="M130.37 434.22V434.33" stroke="black" stroke-width="0.5" />
    <path d="M130.37 434.38V434.49" stroke="black" stroke-width="0.5" />
    <path d="M130.37 434.55V434.66" stroke="black" stroke-width="0.5" />
    <path d="M130.37 434.71V434.82" stroke="black" stroke-width="0.5" />
    <path d="M130.37 434.88V434.99" stroke="black" stroke-width="0.5" />
    <path d="M130.37 435.04V435.15" stroke="black" stroke-width="0.5" />
    <path d="M130.37 435.21V435.32" stroke="black" stroke-width="0.5" />
    <path d="M130.37 435.37V435.48" stroke="black" stroke-width="0.5" />
    <path d="M130.37 435.54V435.65" stroke="black" stroke-width="0.5" />
    <path d="M130.37 435.7V435.81" stroke="black" stroke-width="0.5" />
    <path d="M130.37 435.87V435.98" stroke="black" stroke-width="0.5" />
    <path d="M130.37 436.03V436.14" stroke="black" stroke-width="0.5" />
    <path d="M130.37 436.2V436.31" stroke="black" stroke-width="0.5" />
    <path d="M130.37 436.36V436.47" stroke="black" stroke-width="0.5" />
    <path d="M130.37 436.53V436.64" stroke="black" stroke-width="0.5" />
    <path d="M130.37 436.69V436.8" stroke="black" stroke-width="0.5" />
    <path d="M130.37 436.86V436.97" stroke="black" stroke-width="0.5" />
    <path d="M130.37 437.02V437.13" stroke="black" stroke-width="0.5" />
    <path d="M130.37 437.19V437.3" stroke="black" stroke-width="0.5" />
    <path d="M130.37 437.35V437.46" stroke="black" stroke-width="0.5" />
    <path d="M130.37 437.52V437.63" stroke="black" stroke-width="0.5" />
    <path d="M130.37 437.68V437.79" stroke="black" stroke-width="0.5" />
    <path d="M130.37 437.85V437.96" stroke="black" stroke-width="0.5" />
    <path d="M130.37 438.01V438.12" stroke="black" stroke-width="0.5" />
    <path d="M130.37 438.17V438.28" stroke="black" stroke-width="0.5" />
    <path d="M130.37 438.34V438.45" stroke="black" stroke-width="0.5" />
    <path d="M130.37 438.5V438.61" stroke="black" stroke-width="0.5" />
    <path d="M130.37 438.67V438.78" stroke="black" stroke-width="0.5" />
    <path d="M130.37 438.83V438.94" stroke="black" stroke-width="0.5" />
    <path d="M130.37 439V439.11" stroke="black" stroke-width="0.5" />
    <path d="M130.37 439.16V439.27" stroke="black" stroke-width="0.5" />
    <path d="M130.37 439.33V439.44" stroke="black" stroke-width="0.5" />
    <path d="M130.37 439.49V439.6" stroke="black" stroke-width="0.5" />
    <path d="M130.37 439.66V439.77" stroke="black" stroke-width="0.5" />
    <path d="M130.37 439.82V439.93" stroke="black" stroke-width="0.5" />
    <path d="M130.37 439.99V440.1" stroke="black" stroke-width="0.5" />
    <path d="M130.37 440.15V440.26" stroke="black" stroke-width="0.5" />
    <path d="M130.37 440.32V440.43" stroke="black" stroke-width="0.5" />
    <path d="M130.37 440.48V440.59" stroke="black" stroke-width="0.5" />
    <path d="M130.37 440.65V440.76" stroke="black" stroke-width="0.5" />
    <path d="M130.37 440.81V440.92" stroke="black" stroke-width="0.5" />
    <path d="M130.37 440.98V441.09" stroke="black" stroke-width="0.5" />
    <path d="M130.37 441.14V441.25" stroke="black" stroke-width="0.5" />
    <path d="M130.37 441.31V441.42" stroke="black" stroke-width="0.5" />
    <path d="M130.37 441.47V441.58" stroke="black" stroke-width="0.5" />
    <path d="M130.37 441.64V441.69" stroke="black" stroke-width="0.5" />
    <path d="M151.48 420.58H151.42" stroke="black" stroke-width="0.5" />
    <path d="M151.37 420.58H151.26" stroke="black" stroke-width="0.5" />
    <path d="M151.2 420.58H151.09" stroke="black" stroke-width="0.5" />
    <path d="M151.04 420.58H150.93" stroke="black" stroke-width="0.5" />
    <path d="M150.87 420.58H150.76" stroke="black" stroke-width="0.5" />
    <path d="M150.71 420.58H150.6" stroke="black" stroke-width="0.5" />
    <path d="M150.54 420.58H150.43" stroke="black" stroke-width="0.5" />
    <path d="M150.38 420.58H150.27" stroke="black" stroke-width="0.5" />
    <path d="M150.21 420.58H150.1" stroke="black" stroke-width="0.5" />
    <path d="M150.05 420.58H149.94" stroke="black" stroke-width="0.5" />
    <path d="M149.88 420.58H149.77" stroke="black" stroke-width="0.5" />
    <path d="M149.72 420.58H149.61" stroke="black" stroke-width="0.5" />
    <path d="M149.55 420.58H149.44" stroke="black" stroke-width="0.5" />
    <path d="M149.39 420.58H149.28" stroke="black" stroke-width="0.5" />
    <path d="M149.22 420.58H149.11" stroke="black" stroke-width="0.5" />
    <path d="M149.06 420.58H148.95" stroke="black" stroke-width="0.5" />
    <path d="M148.89 420.58H148.78" stroke="black" stroke-width="0.5" />
    <path d="M148.73 420.58H148.62" stroke="black" stroke-width="0.5" />
    <path d="M148.56 420.58H148.45" stroke="black" stroke-width="0.5" />
    <path d="M148.4 420.58H148.29" stroke="black" stroke-width="0.5" />
    <path d="M148.23 420.58H148.12" stroke="black" stroke-width="0.5" />
    <path d="M148.07 420.58H147.96" stroke="black" stroke-width="0.5" />
    <path d="M147.9 420.58H147.79" stroke="black" stroke-width="0.5" />
    <path d="M147.74 420.58H147.63" stroke="black" stroke-width="0.5" />
    <path d="M147.57 420.58H147.46" stroke="black" stroke-width="0.5" />
    <path d="M147.41 420.58H147.3" stroke="black" stroke-width="0.5" />
    <path d="M147.25 420.58H147.14" stroke="black" stroke-width="0.5" />
    <path d="M147.08 420.58H146.97" stroke="black" stroke-width="0.5" />
    <path d="M146.92 420.58H146.81" stroke="black" stroke-width="0.5" />
    <path d="M146.75 420.58H146.64" stroke="black" stroke-width="0.5" />
    <path d="M146.59 420.58H146.48" stroke="black" stroke-width="0.5" />
    <path d="M146.42 420.58H146.31" stroke="black" stroke-width="0.5" />
    <path d="M146.26 420.58H146.15" stroke="black" stroke-width="0.5" />
    <path d="M146.09 420.58H145.98" stroke="black" stroke-width="0.5" />
    <path d="M145.93 420.58H145.82" stroke="black" stroke-width="0.5" />
    <path d="M145.76 420.58H145.65" stroke="black" stroke-width="0.5" />
    <path d="M145.6 420.58H145.49" stroke="black" stroke-width="0.5" />
    <path d="M145.43 420.58H145.32" stroke="black" stroke-width="0.5" />
    <path d="M145.27 420.58H145.16" stroke="black" stroke-width="0.5" />
    <path d="M145.1 420.58H144.99" stroke="black" stroke-width="0.5" />
    <path d="M144.94 420.58H144.83" stroke="black" stroke-width="0.5" />
    <path d="M144.77 420.58H144.66" stroke="black" stroke-width="0.5" />
    <path d="M144.61 420.58H144.5" stroke="black" stroke-width="0.5" />
    <path d="M144.44 420.58H144.33" stroke="black" stroke-width="0.5" />
    <path d="M144.28 420.58H144.17" stroke="black" stroke-width="0.5" />
    <path d="M144.11 420.58H144" stroke="black" stroke-width="0.5" />
    <path d="M143.95 420.58H143.84" stroke="black" stroke-width="0.5" />
    <path d="M143.78 420.58H143.67" stroke="black" stroke-width="0.5" />
    <path d="M143.62 420.58H143.51" stroke="black" stroke-width="0.5" />
    <path d="M143.45 420.58H143.34" stroke="black" stroke-width="0.5" />
    <path d="M143.29 420.58H143.18" stroke="black" stroke-width="0.5" />
    <path d="M143.12 420.58H143.01" stroke="black" stroke-width="0.5" />
    <path d="M142.96 420.58H142.85" stroke="black" stroke-width="0.5" />
    <path d="M142.79 420.58H142.68" stroke="black" stroke-width="0.5" />
    <path d="M142.63 420.58H142.52" stroke="black" stroke-width="0.5" />
    <path d="M142.46 420.58H142.35" stroke="black" stroke-width="0.5" />
    <path d="M142.3 420.58H142.19" stroke="black" stroke-width="0.5" />
    <path d="M142.13 420.58H142.02" stroke="black" stroke-width="0.5" />
    <path d="M141.97 420.58H141.86" stroke="black" stroke-width="0.5" />
    <path d="M141.8 420.58H141.69" stroke="black" stroke-width="0.5" />
    <path d="M141.64 420.58H141.53" stroke="black" stroke-width="0.5" />
    <path d="M141.47 420.58H141.36" stroke="black" stroke-width="0.5" />
    <path d="M141.31 420.58H141.2" stroke="black" stroke-width="0.5" />
    <path d="M141.14 420.58H141.03" stroke="black" stroke-width="0.5" />
    <path d="M140.98 420.58H140.87" stroke="black" stroke-width="0.5" />
    <path d="M140.81 420.58H140.7" stroke="black" stroke-width="0.5" />
    <path d="M140.65 420.58H140.54" stroke="black" stroke-width="0.5" />
    <path d="M140.48 420.58H140.37" stroke="black" stroke-width="0.5" />
    <path d="M140.32 420.58H140.21" stroke="black" stroke-width="0.5" />
    <path d="M140.15 420.58H140.04" stroke="black" stroke-width="0.5" />
    <path d="M139.99 420.58H139.88" stroke="black" stroke-width="0.5" />
    <path d="M139.82 420.58H139.71" stroke="black" stroke-width="0.5" />
    <path d="M139.66 420.58H139.55" stroke="black" stroke-width="0.5" />
    <path d="M139.49 420.58H139.38" stroke="black" stroke-width="0.5" />
    <path d="M139.33 420.58H139.22" stroke="black" stroke-width="0.5" />
    <path d="M139.16 420.58H139.05" stroke="black" stroke-width="0.5" />
    <path d="M139 420.58H138.89" stroke="black" stroke-width="0.5" />
    <path d="M138.83 420.58H138.72" stroke="black" stroke-width="0.5" />
    <path d="M138.67 420.58H138.56" stroke="black" stroke-width="0.5" />
    <path d="M138.5 420.58H138.39" stroke="black" stroke-width="0.5" />
    <path d="M138.34 420.58H138.23" stroke="black" stroke-width="0.5" />
    <path d="M138.17 420.58H138.06" stroke="black" stroke-width="0.5" />
    <path d="M138.01 420.58H137.9" stroke="black" stroke-width="0.5" />
    <path d="M137.84 420.58H137.73" stroke="black" stroke-width="0.5" />
    <path d="M137.68 420.58H137.57" stroke="black" stroke-width="0.5" />
    <path d="M137.51 420.58H137.4" stroke="black" stroke-width="0.5" />
    <path d="M137.35 420.58H137.24" stroke="black" stroke-width="0.5" />
    <path d="M137.18 420.58H137.07" stroke="black" stroke-width="0.5" />
    <path d="M137.02 420.58H136.91" stroke="black" stroke-width="0.5" />
    <path d="M136.85 420.58H136.74" stroke="black" stroke-width="0.5" />
    <path d="M136.69 420.58H136.58" stroke="black" stroke-width="0.5" />
    <path d="M136.52 420.58H136.41" stroke="black" stroke-width="0.5" />
    <path d="M136.36 420.58H136.25" stroke="black" stroke-width="0.5" />
    <path d="M136.19 420.58H136.08" stroke="black" stroke-width="0.5" />
    <path d="M136.03 420.58H135.92" stroke="black" stroke-width="0.5" />
    <path d="M135.86 420.58H135.75" stroke="black" stroke-width="0.5" />
    <path d="M135.7 420.58H135.59" stroke="black" stroke-width="0.5" />
    <path d="M135.53 420.58H135.42" stroke="black" stroke-width="0.5" />
    <path d="M135.37 420.58H135.26" stroke="black" stroke-width="0.5" />
    <path d="M135.2 420.58H135.09" stroke="black" stroke-width="0.5" />
    <path d="M135.04 420.58H134.93" stroke="black" stroke-width="0.5" />
    <path d="M134.87 420.58H134.76" stroke="black" stroke-width="0.5" />
    <path d="M134.71 420.58H134.6" stroke="black" stroke-width="0.5" />
    <path d="M134.54 420.58H134.43" stroke="black" stroke-width="0.5" />
    <path d="M134.38 420.58H134.27" stroke="black" stroke-width="0.5" />
    <path d="M134.22 420.58H134.11" stroke="black" stroke-width="0.5" />
    <path d="M134.05 420.58H133.94" stroke="black" stroke-width="0.5" />
    <path d="M133.89 420.58H133.78" stroke="black" stroke-width="0.5" />
    <path d="M133.72 420.58H133.61" stroke="black" stroke-width="0.5" />
    <path d="M133.56 420.58H133.45" stroke="black" stroke-width="0.5" />
    <path d="M133.39 420.58H133.28" stroke="black" stroke-width="0.5" />
    <path d="M133.23 420.58H133.12" stroke="black" stroke-width="0.5" />
    <path d="M133.06 420.58H132.95" stroke="black" stroke-width="0.5" />
    <path d="M132.9 420.58H132.79" stroke="black" stroke-width="0.5" />
    <path d="M132.73 420.58H132.62" stroke="black" stroke-width="0.5" />
    <path d="M132.57 420.58H132.46" stroke="black" stroke-width="0.5" />
    <path d="M132.4 420.58H132.29" stroke="black" stroke-width="0.5" />
    <path d="M132.24 420.58H132.13" stroke="black" stroke-width="0.5" />
    <path d="M132.07 420.58H131.96" stroke="black" stroke-width="0.5" />
    <path d="M131.91 420.58H131.8" stroke="black" stroke-width="0.5" />
    <path d="M131.74 420.58H131.63" stroke="black" stroke-width="0.5" />
    <path d="M131.58 420.58H131.47" stroke="black" stroke-width="0.5" />
    <path d="M131.41 420.58H131.3" stroke="black" stroke-width="0.5" />
    <path d="M131.25 420.58H131.14" stroke="black" stroke-width="0.5" />
    <path d="M131.08 420.58H130.97" stroke="black" stroke-width="0.5" />
    <path d="M130.92 420.58H130.81" stroke="black" stroke-width="0.5" />
    <path d="M130.75 420.58H130.64" stroke="black" stroke-width="0.5" />
    <path d="M130.59 420.58H130.48" stroke="black" stroke-width="0.5" />
    <path d="M130.42 420.58H130.37" stroke="black" stroke-width="0.5" />
    <path d="M586.69 434.35H586.64" stroke="black" stroke-width="0.5" />
    <path d="M586.58 434.35H586.47" stroke="black" stroke-width="0.5" />
    <path d="M586.42 434.35H586.31" stroke="black" stroke-width="0.5" />
    <path d="M586.25 434.35H586.14" stroke="black" stroke-width="0.5" />
    <path d="M586.09 434.35H585.98" stroke="black" stroke-width="0.5" />
    <path d="M585.92 434.35H585.81" stroke="black" stroke-width="0.5" />
    <path d="M585.76 434.35H585.65" stroke="black" stroke-width="0.5" />
    <path d="M585.59 434.35H585.48" stroke="black" stroke-width="0.5" />
    <path d="M585.43 434.35H585.32" stroke="black" stroke-width="0.5" />
    <path d="M585.26 434.35H585.15" stroke="black" stroke-width="0.5" />
    <path d="M585.1 434.35H584.99" stroke="black" stroke-width="0.5" />
    <path d="M584.93 434.35H584.82" stroke="black" stroke-width="0.5" />
    <path d="M584.77 434.35H584.66" stroke="black" stroke-width="0.5" />
    <path d="M584.6 434.35H584.49" stroke="black" stroke-width="0.5" />
    <path d="M584.44 434.35H584.33" stroke="black" stroke-width="0.5" />
    <path d="M584.27 434.35H584.16" stroke="black" stroke-width="0.5" />
    <path d="M584.11 434.35H584" stroke="black" stroke-width="0.5" />
    <path d="M583.94 434.35H583.83" stroke="black" stroke-width="0.5" />
    <path d="M583.78 434.35H583.67" stroke="black" stroke-width="0.5" />
    <path d="M583.61 434.35H583.5" stroke="black" stroke-width="0.5" />
    <path d="M583.45 434.35H583.34" stroke="black" stroke-width="0.5" />
    <path d="M583.28 434.35H583.17" stroke="black" stroke-width="0.5" />
    <path d="M583.12 434.35H583.01" stroke="black" stroke-width="0.5" />
    <path d="M582.95 434.35H582.84" stroke="black" stroke-width="0.5" />
    <path d="M582.79 434.35H582.68" stroke="black" stroke-width="0.5" />
    <path d="M582.62 434.35H582.51" stroke="black" stroke-width="0.5" />
    <path d="M582.46 434.35H582.35" stroke="black" stroke-width="0.5" />
    <path d="M582.29 434.35H582.18" stroke="black" stroke-width="0.5" />
    <path d="M582.13 434.35H582.02" stroke="black" stroke-width="0.5" />
    <path d="M581.96 434.35H581.85" stroke="black" stroke-width="0.5" />
    <path d="M581.8 434.35H581.69" stroke="black" stroke-width="0.5" />
    <path d="M581.63 434.35H581.52" stroke="black" stroke-width="0.5" />
    <path d="M581.47 434.35H581.36" stroke="black" stroke-width="0.5" />
    <path d="M581.3 434.35H581.19" stroke="black" stroke-width="0.5" />
    <path d="M581.14 434.35H581.03" stroke="black" stroke-width="0.5" />
    <path d="M580.97 434.35H580.86" stroke="black" stroke-width="0.5" />
    <path d="M580.81 434.35H580.7" stroke="black" stroke-width="0.5" />
    <path d="M580.64 434.35H580.53" stroke="black" stroke-width="0.5" />
    <path d="M580.48 434.35H580.37" stroke="black" stroke-width="0.5" />
    <path d="M580.31 434.35H580.2" stroke="black" stroke-width="0.5" />
    <path d="M580.15 434.35H580.04" stroke="black" stroke-width="0.5" />
    <path d="M579.98 434.35H579.87" stroke="black" stroke-width="0.5" />
    <path d="M579.82 434.35H579.71" stroke="black" stroke-width="0.5" />
    <path d="M579.65 434.35H579.54" stroke="black" stroke-width="0.5" />
    <path d="M579.49 434.35H579.38" stroke="black" stroke-width="0.5" />
    <path d="M579.32 434.35H579.21" stroke="black" stroke-width="0.5" />
    <path d="M579.16 434.35H579.05" stroke="black" stroke-width="0.5" />
    <path d="M578.99 434.35H578.88" stroke="black" stroke-width="0.5" />
    <path d="M578.83 434.35H578.72" stroke="black" stroke-width="0.5" />
    <path d="M578.66 434.35H578.55" stroke="black" stroke-width="0.5" />
    <path d="M578.5 434.35H578.39" stroke="black" stroke-width="0.5" />
    <path d="M578.33 434.35H578.22" stroke="black" stroke-width="0.5" />
    <path d="M578.17 434.35H578.06" stroke="black" stroke-width="0.5" />
    <path d="M578 434.35H577.89" stroke="black" stroke-width="0.5" />
    <path d="M577.84 434.35H577.73" stroke="black" stroke-width="0.5" />
    <path d="M577.67 434.35H577.56" stroke="black" stroke-width="0.5" />
    <path d="M577.51 434.35H577.4" stroke="black" stroke-width="0.5" />
    <path d="M577.34 434.35H577.23" stroke="black" stroke-width="0.5" />
    <path d="M577.18 434.35H577.07" stroke="black" stroke-width="0.5" />
    <path d="M577.01 434.35H576.9" stroke="black" stroke-width="0.5" />
    <path d="M576.85 434.35H576.74" stroke="black" stroke-width="0.5" />
    <path d="M576.68 434.35H576.57" stroke="black" stroke-width="0.5" />
    <path d="M576.52 434.35H576.41" stroke="black" stroke-width="0.5" />
    <path d="M576.35 434.35H576.24" stroke="black" stroke-width="0.5" />
    <path d="M576.19 434.35H576.08" stroke="black" stroke-width="0.5" />
    <path d="M576.02 434.35H575.91" stroke="black" stroke-width="0.5" />
    <path d="M575.86 434.35H575.75" stroke="black" stroke-width="0.5" />
    <path d="M575.69 434.35H575.58" stroke="black" stroke-width="0.5" />
    <path d="M575.53 434.35H575.42" stroke="black" stroke-width="0.5" />
    <path d="M575.36 434.35H575.25" stroke="black" stroke-width="0.5" />
    <path d="M575.2 434.35H575.09" stroke="black" stroke-width="0.5" />
    <path d="M575.03 434.35H574.92" stroke="black" stroke-width="0.5" />
    <path d="M574.87 434.35H574.76" stroke="black" stroke-width="0.5" />
    <path d="M574.7 434.35H574.59" stroke="black" stroke-width="0.5" />
    <path d="M574.54 434.35H574.43" stroke="black" stroke-width="0.5" />
    <path d="M574.37 434.35H574.26" stroke="black" stroke-width="0.5" />
    <path d="M574.21 434.35H574.1" stroke="black" stroke-width="0.5" />
    <path d="M574.04 434.35H573.93" stroke="black" stroke-width="0.5" />
    <path d="M573.88 434.35H573.77" stroke="black" stroke-width="0.5" />
    <path d="M573.72 434.35H573.61" stroke="black" stroke-width="0.5" />
    <path d="M573.55 434.35H573.44" stroke="black" stroke-width="0.5" />
    <path d="M573.39 434.35H573.28" stroke="black" stroke-width="0.5" />
    <path d="M573.22 434.35H573.11" stroke="black" stroke-width="0.5" />
    <path d="M573.06 434.35H572.95" stroke="black" stroke-width="0.5" />
    <path d="M572.89 434.35H572.78" stroke="black" stroke-width="0.5" />
    <path d="M572.73 434.35H572.62" stroke="black" stroke-width="0.5" />
    <path d="M572.56 434.35H572.45" stroke="black" stroke-width="0.5" />
    <path d="M572.4 434.35H572.29" stroke="black" stroke-width="0.5" />
    <path d="M572.23 434.35H572.12" stroke="black" stroke-width="0.5" />
    <path d="M572.07 434.35H571.96" stroke="black" stroke-width="0.5" />
    <path d="M571.9 434.35H571.79" stroke="black" stroke-width="0.5" />
    <path d="M571.74 434.35H571.63" stroke="black" stroke-width="0.5" />
    <path d="M571.57 434.35H571.46" stroke="black" stroke-width="0.5" />
    <path d="M571.41 434.35H571.3" stroke="black" stroke-width="0.5" />
    <path d="M571.24 434.35H571.13" stroke="black" stroke-width="0.5" />
    <path d="M571.08 434.35H570.97" stroke="black" stroke-width="0.5" />
    <path d="M570.91 434.35H570.8" stroke="black" stroke-width="0.5" />
    <path d="M570.75 434.35H570.64" stroke="black" stroke-width="0.5" />
    <path d="M570.58 434.35H570.47" stroke="black" stroke-width="0.5" />
    <path d="M570.42 434.35H570.31" stroke="black" stroke-width="0.5" />
    <path d="M570.25 434.35H570.14" stroke="black" stroke-width="0.5" />
    <path d="M570.09 434.35H569.98" stroke="black" stroke-width="0.5" />
    <path d="M569.92 434.35H569.81" stroke="black" stroke-width="0.5" />
    <path d="M569.76 434.35H569.65" stroke="black" stroke-width="0.5" />
    <path d="M569.59 434.35H569.48" stroke="black" stroke-width="0.5" />
    <path d="M569.43 434.35H569.32" stroke="black" stroke-width="0.5" />
    <path d="M569.26 434.35H569.15" stroke="black" stroke-width="0.5" />
    <path d="M569.1 434.35H568.99" stroke="black" stroke-width="0.5" />
    <path d="M568.93 434.35H568.82" stroke="black" stroke-width="0.5" />
    <path d="M568.77 434.35H568.66" stroke="black" stroke-width="0.5" />
    <path d="M568.6 434.35H568.49" stroke="black" stroke-width="0.5" />
    <path d="M568.44 434.35H568.33" stroke="black" stroke-width="0.5" />
    <path d="M568.27 434.35H568.16" stroke="black" stroke-width="0.5" />
    <path d="M568.11 434.35H568" stroke="black" stroke-width="0.5" />
    <path d="M567.94 434.35H567.83" stroke="black" stroke-width="0.5" />
    <path d="M567.78 434.35H567.67" stroke="black" stroke-width="0.5" />
    <path d="M567.61 434.35H567.5" stroke="black" stroke-width="0.5" />
    <path d="M567.45 434.35H567.34" stroke="black" stroke-width="0.5" />
    <path d="M567.28 434.35H567.17" stroke="black" stroke-width="0.5" />
    <path d="M567.12 434.35H567.01" stroke="black" stroke-width="0.5" />
    <path d="M566.95 434.35H566.84" stroke="black" stroke-width="0.5" />
    <path d="M566.79 434.35H566.68" stroke="black" stroke-width="0.5" />
    <path d="M566.62 434.35H566.51" stroke="black" stroke-width="0.5" />
    <path d="M566.46 434.35H566.35" stroke="black" stroke-width="0.5" />
    <path d="M566.29 434.35H566.18" stroke="black" stroke-width="0.5" />
    <path d="M566.13 434.35H566.02" stroke="black" stroke-width="0.5" />
    <path d="M565.96 434.35H565.85" stroke="black" stroke-width="0.5" />
    <path d="M565.8 434.35H565.69" stroke="black" stroke-width="0.5" />
    <path d="M565.63 434.35H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 413.24V413.29" stroke="black" stroke-width="0.5" />
    <path d="M565.58 413.35V413.46" stroke="black" stroke-width="0.5" />
    <path d="M565.58 413.51V413.62" stroke="black" stroke-width="0.5" />
    <path d="M565.58 413.68V413.79" stroke="black" stroke-width="0.5" />
    <path d="M565.58 413.84V413.95" stroke="black" stroke-width="0.5" />
    <path d="M565.58 414.01V414.12" stroke="black" stroke-width="0.5" />
    <path d="M565.58 414.17V414.28" stroke="black" stroke-width="0.5" />
    <path d="M565.58 414.34V414.45" stroke="black" stroke-width="0.5" />
    <path d="M565.58 414.5V414.61" stroke="black" stroke-width="0.5" />
    <path d="M565.58 414.67V414.78" stroke="black" stroke-width="0.5" />
    <path d="M565.58 414.83V414.94" stroke="black" stroke-width="0.5" />
    <path d="M565.58 415V415.11" stroke="black" stroke-width="0.5" />
    <path d="M565.58 415.16V415.27" stroke="black" stroke-width="0.5" />
    <path d="M565.58 415.33V415.44" stroke="black" stroke-width="0.5" />
    <path d="M565.58 415.49V415.6" stroke="black" stroke-width="0.5" />
    <path d="M565.58 415.66V415.77" stroke="black" stroke-width="0.5" />
    <path d="M565.58 415.82V415.93" stroke="black" stroke-width="0.5" />
    <path d="M565.58 415.99V416.1" stroke="black" stroke-width="0.5" />
    <path d="M565.58 416.15V416.26" stroke="black" stroke-width="0.5" />
    <path d="M565.58 416.32V416.43" stroke="black" stroke-width="0.5" />
    <path d="M565.58 416.48V416.59" stroke="black" stroke-width="0.5" />
    <path d="M565.58 416.65V416.76" stroke="black" stroke-width="0.5" />
    <path d="M565.58 416.81V416.92" stroke="black" stroke-width="0.5" />
    <path d="M565.58 416.98V417.09" stroke="black" stroke-width="0.5" />
    <path d="M565.58 417.14V417.25" stroke="black" stroke-width="0.5" />
    <path d="M565.58 417.31V417.42" stroke="black" stroke-width="0.5" />
    <path d="M565.58 417.47V417.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 417.64V417.75" stroke="black" stroke-width="0.5" />
    <path d="M565.58 417.8V417.91" stroke="black" stroke-width="0.5" />
    <path d="M565.58 417.97V418.08" stroke="black" stroke-width="0.5" />
    <path d="M565.58 418.13V418.24" stroke="black" stroke-width="0.5" />
    <path d="M565.58 418.3V418.41" stroke="black" stroke-width="0.5" />
    <path d="M565.58 418.46V418.57" stroke="black" stroke-width="0.5" />
    <path d="M565.58 418.63V418.74" stroke="black" stroke-width="0.5" />
    <path d="M565.58 418.79V418.9" stroke="black" stroke-width="0.5" />
    <path d="M565.58 418.96V419.07" stroke="black" stroke-width="0.5" />
    <path d="M565.58 419.12V419.23" stroke="black" stroke-width="0.5" />
    <path d="M565.58 419.28V419.39" stroke="black" stroke-width="0.5" />
    <path d="M565.58 419.45V419.56" stroke="black" stroke-width="0.5" />
    <path d="M565.58 419.61V419.72" stroke="black" stroke-width="0.5" />
    <path d="M565.58 419.78V419.89" stroke="black" stroke-width="0.5" />
    <path d="M565.58 419.94V420.05" stroke="black" stroke-width="0.5" />
    <path d="M565.58 420.11V420.22" stroke="black" stroke-width="0.5" />
    <path d="M565.58 420.27V420.38" stroke="black" stroke-width="0.5" />
    <path d="M565.58 420.44V420.55" stroke="black" stroke-width="0.5" />
    <path d="M565.58 420.6V420.71" stroke="black" stroke-width="0.5" />
    <path d="M565.58 420.77V420.88" stroke="black" stroke-width="0.5" />
    <path d="M565.58 420.93V421.04" stroke="black" stroke-width="0.5" />
    <path d="M565.58 421.1V421.21" stroke="black" stroke-width="0.5" />
    <path d="M565.58 421.26V421.37" stroke="black" stroke-width="0.5" />
    <path d="M565.58 421.43V421.54" stroke="black" stroke-width="0.5" />
    <path d="M565.58 421.59V421.7" stroke="black" stroke-width="0.5" />
    <path d="M565.58 421.76V421.87" stroke="black" stroke-width="0.5" />
    <path d="M565.58 421.92V422.03" stroke="black" stroke-width="0.5" />
    <path d="M565.58 422.09V422.2" stroke="black" stroke-width="0.5" />
    <path d="M565.58 422.25V422.36" stroke="black" stroke-width="0.5" />
    <path d="M565.58 422.42V422.53" stroke="black" stroke-width="0.5" />
    <path d="M565.58 422.58V422.69" stroke="black" stroke-width="0.5" />
    <path d="M565.58 422.75V422.86" stroke="black" stroke-width="0.5" />
    <path d="M565.58 422.91V423.02" stroke="black" stroke-width="0.5" />
    <path d="M565.58 423.08V423.19" stroke="black" stroke-width="0.5" />
    <path d="M565.58 423.24V423.35" stroke="black" stroke-width="0.5" />
    <path d="M565.58 423.41V423.52" stroke="black" stroke-width="0.5" />
    <path d="M565.58 423.57V423.68" stroke="black" stroke-width="0.5" />
    <path d="M565.58 423.74V423.85" stroke="black" stroke-width="0.5" />
    <path d="M565.58 423.9V424.01" stroke="black" stroke-width="0.5" />
    <path d="M565.58 424.07V424.18" stroke="black" stroke-width="0.5" />
    <path d="M565.58 424.23V424.34" stroke="black" stroke-width="0.5" />
    <path d="M565.58 424.4V424.51" stroke="black" stroke-width="0.5" />
    <path d="M565.58 424.56V424.67" stroke="black" stroke-width="0.5" />
    <path d="M565.58 424.73V424.84" stroke="black" stroke-width="0.5" />
    <path d="M565.58 424.89V425" stroke="black" stroke-width="0.5" />
    <path d="M565.58 425.06V425.17" stroke="black" stroke-width="0.5" />
    <path d="M565.58 425.22V425.33" stroke="black" stroke-width="0.5" />
    <path d="M565.58 425.39V425.5" stroke="black" stroke-width="0.5" />
    <path d="M565.58 425.55V425.66" stroke="black" stroke-width="0.5" />
    <path d="M565.58 425.72V425.83" stroke="black" stroke-width="0.5" />
    <path d="M565.58 425.88V425.99" stroke="black" stroke-width="0.5" />
    <path d="M565.58 426.05V426.16" stroke="black" stroke-width="0.5" />
    <path d="M565.58 426.21V426.32" stroke="black" stroke-width="0.5" />
    <path d="M565.58 426.38V426.49" stroke="black" stroke-width="0.5" />
    <path d="M565.58 426.54V426.65" stroke="black" stroke-width="0.5" />
    <path d="M565.58 426.71V426.82" stroke="black" stroke-width="0.5" />
    <path d="M565.58 426.87V426.98" stroke="black" stroke-width="0.5" />
    <path d="M565.58 427.04V427.15" stroke="black" stroke-width="0.5" />
    <path d="M565.58 427.2V427.31" stroke="black" stroke-width="0.5" />
    <path d="M565.58 427.37V427.48" stroke="black" stroke-width="0.5" />
    <path d="M565.58 427.53V427.64" stroke="black" stroke-width="0.5" />
    <path d="M565.58 427.7V427.81" stroke="black" stroke-width="0.5" />
    <path d="M565.58 427.86V427.97" stroke="black" stroke-width="0.5" />
    <path d="M565.58 428.03V428.14" stroke="black" stroke-width="0.5" />
    <path d="M565.58 428.19V428.3" stroke="black" stroke-width="0.5" />
    <path d="M565.58 428.36V428.47" stroke="black" stroke-width="0.5" />
    <path d="M565.58 428.52V428.63" stroke="black" stroke-width="0.5" />
    <path d="M565.58 428.69V428.8" stroke="black" stroke-width="0.5" />
    <path d="M565.58 428.85V428.96" stroke="black" stroke-width="0.5" />
    <path d="M565.58 429.02V429.13" stroke="black" stroke-width="0.5" />
    <path d="M565.58 429.18V429.29" stroke="black" stroke-width="0.5" />
    <path d="M565.58 429.35V429.46" stroke="black" stroke-width="0.5" />
    <path d="M565.58 429.51V429.62" stroke="black" stroke-width="0.5" />
    <path d="M565.58 429.68V429.79" stroke="black" stroke-width="0.5" />
    <path d="M565.58 429.84V429.95" stroke="black" stroke-width="0.5" />
    <path d="M565.58 430.01V430.12" stroke="black" stroke-width="0.5" />
    <path d="M565.58 430.17V430.28" stroke="black" stroke-width="0.5" />
    <path d="M565.58 430.34V430.45" stroke="black" stroke-width="0.5" />
    <path d="M565.58 430.5V430.61" stroke="black" stroke-width="0.5" />
    <path d="M565.58 430.67V430.78" stroke="black" stroke-width="0.5" />
    <path d="M565.58 430.83V430.94" stroke="black" stroke-width="0.5" />
    <path d="M565.58 431V431.11" stroke="black" stroke-width="0.5" />
    <path d="M565.58 431.16V431.27" stroke="black" stroke-width="0.5" />
    <path d="M565.58 431.33V431.44" stroke="black" stroke-width="0.5" />
    <path d="M565.58 431.49V431.6" stroke="black" stroke-width="0.5" />
    <path d="M565.58 431.66V431.77" stroke="black" stroke-width="0.5" />
    <path d="M565.58 431.82V431.93" stroke="black" stroke-width="0.5" />
    <path d="M565.58 431.99V432.1" stroke="black" stroke-width="0.5" />
    <path d="M565.58 432.15V432.26" stroke="black" stroke-width="0.5" />
    <path d="M565.58 432.31V432.42" stroke="black" stroke-width="0.5" />
    <path d="M565.58 432.48V432.59" stroke="black" stroke-width="0.5" />
    <path d="M565.58 432.64V432.75" stroke="black" stroke-width="0.5" />
    <path d="M565.58 432.81V432.92" stroke="black" stroke-width="0.5" />
    <path d="M565.58 432.97V433.08" stroke="black" stroke-width="0.5" />
    <path d="M565.58 433.14V433.25" stroke="black" stroke-width="0.5" />
    <path d="M565.58 433.3V433.41" stroke="black" stroke-width="0.5" />
    <path d="M565.58 433.47V433.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 433.63V433.74" stroke="black" stroke-width="0.5" />
    <path d="M565.58 433.8V433.91" stroke="black" stroke-width="0.5" />
    <path d="M565.58 433.96V434.07" stroke="black" stroke-width="0.5" />
    <path d="M565.58 434.13V434.24" stroke="black" stroke-width="0.5" />
    <path d="M565.58 434.29V434.35" stroke="black" stroke-width="0.5" />
    <path d="M586.69 413.24H586.64" stroke="black" stroke-width="0.5" />
    <path d="M586.58 413.24H586.47" stroke="black" stroke-width="0.5" />
    <path d="M586.42 413.24H586.31" stroke="black" stroke-width="0.5" />
    <path d="M586.25 413.24H586.14" stroke="black" stroke-width="0.5" />
    <path d="M586.09 413.24H585.98" stroke="black" stroke-width="0.5" />
    <path d="M585.92 413.24H585.81" stroke="black" stroke-width="0.5" />
    <path d="M585.76 413.24H585.65" stroke="black" stroke-width="0.5" />
    <path d="M585.59 413.24H585.48" stroke="black" stroke-width="0.5" />
    <path d="M585.43 413.24H585.32" stroke="black" stroke-width="0.5" />
    <path d="M585.26 413.24H585.15" stroke="black" stroke-width="0.5" />
    <path d="M585.1 413.24H584.99" stroke="black" stroke-width="0.5" />
    <path d="M584.93 413.24H584.82" stroke="black" stroke-width="0.5" />
    <path d="M584.77 413.24H584.66" stroke="black" stroke-width="0.5" />
    <path d="M584.6 413.24H584.49" stroke="black" stroke-width="0.5" />
    <path d="M584.44 413.24H584.33" stroke="black" stroke-width="0.5" />
    <path d="M584.27 413.24H584.16" stroke="black" stroke-width="0.5" />
    <path d="M584.11 413.24H584" stroke="black" stroke-width="0.5" />
    <path d="M583.94 413.24H583.83" stroke="black" stroke-width="0.5" />
    <path d="M583.78 413.24H583.67" stroke="black" stroke-width="0.5" />
    <path d="M583.61 413.24H583.5" stroke="black" stroke-width="0.5" />
    <path d="M583.45 413.24H583.34" stroke="black" stroke-width="0.5" />
    <path d="M583.28 413.24H583.17" stroke="black" stroke-width="0.5" />
    <path d="M583.12 413.24H583.01" stroke="black" stroke-width="0.5" />
    <path d="M582.95 413.24H582.84" stroke="black" stroke-width="0.5" />
    <path d="M582.79 413.24H582.68" stroke="black" stroke-width="0.5" />
    <path d="M582.62 413.24H582.51" stroke="black" stroke-width="0.5" />
    <path d="M582.46 413.24H582.35" stroke="black" stroke-width="0.5" />
    <path d="M582.29 413.24H582.18" stroke="black" stroke-width="0.5" />
    <path d="M582.13 413.24H582.02" stroke="black" stroke-width="0.5" />
    <path d="M581.96 413.24H581.85" stroke="black" stroke-width="0.5" />
    <path d="M581.8 413.24H581.69" stroke="black" stroke-width="0.5" />
    <path d="M581.63 413.24H581.52" stroke="black" stroke-width="0.5" />
    <path d="M581.47 413.24H581.36" stroke="black" stroke-width="0.5" />
    <path d="M581.3 413.24H581.19" stroke="black" stroke-width="0.5" />
    <path d="M581.14 413.24H581.03" stroke="black" stroke-width="0.5" />
    <path d="M580.97 413.24H580.86" stroke="black" stroke-width="0.5" />
    <path d="M580.81 413.24H580.7" stroke="black" stroke-width="0.5" />
    <path d="M580.64 413.24H580.53" stroke="black" stroke-width="0.5" />
    <path d="M580.48 413.24H580.37" stroke="black" stroke-width="0.5" />
    <path d="M580.31 413.24H580.2" stroke="black" stroke-width="0.5" />
    <path d="M580.15 413.24H580.04" stroke="black" stroke-width="0.5" />
    <path d="M579.98 413.24H579.87" stroke="black" stroke-width="0.5" />
    <path d="M579.82 413.24H579.71" stroke="black" stroke-width="0.5" />
    <path d="M579.65 413.24H579.54" stroke="black" stroke-width="0.5" />
    <path d="M579.49 413.24H579.38" stroke="black" stroke-width="0.5" />
    <path d="M579.32 413.24H579.21" stroke="black" stroke-width="0.5" />
    <path d="M579.16 413.24H579.05" stroke="black" stroke-width="0.5" />
    <path d="M578.99 413.24H578.88" stroke="black" stroke-width="0.5" />
    <path d="M578.83 413.24H578.72" stroke="black" stroke-width="0.5" />
    <path d="M578.66 413.24H578.55" stroke="black" stroke-width="0.5" />
    <path d="M578.5 413.24H578.39" stroke="black" stroke-width="0.5" />
    <path d="M578.33 413.24H578.22" stroke="black" stroke-width="0.5" />
    <path d="M578.17 413.24H578.06" stroke="black" stroke-width="0.5" />
    <path d="M578 413.24H577.89" stroke="black" stroke-width="0.5" />
    <path d="M577.84 413.24H577.73" stroke="black" stroke-width="0.5" />
    <path d="M577.67 413.24H577.56" stroke="black" stroke-width="0.5" />
    <path d="M577.51 413.24H577.4" stroke="black" stroke-width="0.5" />
    <path d="M577.34 413.24H577.23" stroke="black" stroke-width="0.5" />
    <path d="M577.18 413.24H577.07" stroke="black" stroke-width="0.5" />
    <path d="M577.01 413.24H576.9" stroke="black" stroke-width="0.5" />
    <path d="M576.85 413.24H576.74" stroke="black" stroke-width="0.5" />
    <path d="M576.68 413.24H576.57" stroke="black" stroke-width="0.5" />
    <path d="M576.52 413.24H576.41" stroke="black" stroke-width="0.5" />
    <path d="M576.35 413.24H576.24" stroke="black" stroke-width="0.5" />
    <path d="M576.19 413.24H576.08" stroke="black" stroke-width="0.5" />
    <path d="M576.02 413.24H575.91" stroke="black" stroke-width="0.5" />
    <path d="M575.86 413.24H575.75" stroke="black" stroke-width="0.5" />
    <path d="M575.69 413.24H575.58" stroke="black" stroke-width="0.5" />
    <path d="M575.53 413.24H575.42" stroke="black" stroke-width="0.5" />
    <path d="M575.36 413.24H575.25" stroke="black" stroke-width="0.5" />
    <path d="M575.2 413.24H575.09" stroke="black" stroke-width="0.5" />
    <path d="M575.03 413.24H574.92" stroke="black" stroke-width="0.5" />
    <path d="M574.87 413.24H574.76" stroke="black" stroke-width="0.5" />
    <path d="M574.7 413.24H574.59" stroke="black" stroke-width="0.5" />
    <path d="M574.54 413.24H574.43" stroke="black" stroke-width="0.5" />
    <path d="M574.37 413.24H574.26" stroke="black" stroke-width="0.5" />
    <path d="M574.21 413.24H574.1" stroke="black" stroke-width="0.5" />
    <path d="M574.04 413.24H573.93" stroke="black" stroke-width="0.5" />
    <path d="M573.88 413.24H573.77" stroke="black" stroke-width="0.5" />
    <path d="M573.72 413.24H573.61" stroke="black" stroke-width="0.5" />
    <path d="M573.55 413.24H573.44" stroke="black" stroke-width="0.5" />
    <path d="M573.39 413.24H573.28" stroke="black" stroke-width="0.5" />
    <path d="M573.22 413.24H573.11" stroke="black" stroke-width="0.5" />
    <path d="M573.06 413.24H572.95" stroke="black" stroke-width="0.5" />
    <path d="M572.89 413.24H572.78" stroke="black" stroke-width="0.5" />
    <path d="M572.73 413.24H572.62" stroke="black" stroke-width="0.5" />
    <path d="M572.56 413.24H572.45" stroke="black" stroke-width="0.5" />
    <path d="M572.4 413.24H572.29" stroke="black" stroke-width="0.5" />
    <path d="M572.23 413.24H572.12" stroke="black" stroke-width="0.5" />
    <path d="M572.07 413.24H571.96" stroke="black" stroke-width="0.5" />
    <path d="M571.9 413.24H571.79" stroke="black" stroke-width="0.5" />
    <path d="M571.74 413.24H571.63" stroke="black" stroke-width="0.5" />
    <path d="M571.57 413.24H571.46" stroke="black" stroke-width="0.5" />
    <path d="M571.41 413.24H571.3" stroke="black" stroke-width="0.5" />
    <path d="M571.24 413.24H571.13" stroke="black" stroke-width="0.5" />
    <path d="M571.08 413.24H570.97" stroke="black" stroke-width="0.5" />
    <path d="M570.91 413.24H570.8" stroke="black" stroke-width="0.5" />
    <path d="M570.75 413.24H570.64" stroke="black" stroke-width="0.5" />
    <path d="M570.58 413.24H570.47" stroke="black" stroke-width="0.5" />
    <path d="M570.42 413.24H570.31" stroke="black" stroke-width="0.5" />
    <path d="M570.25 413.24H570.14" stroke="black" stroke-width="0.5" />
    <path d="M570.09 413.24H569.98" stroke="black" stroke-width="0.5" />
    <path d="M569.92 413.24H569.81" stroke="black" stroke-width="0.5" />
    <path d="M569.76 413.24H569.65" stroke="black" stroke-width="0.5" />
    <path d="M569.59 413.24H569.48" stroke="black" stroke-width="0.5" />
    <path d="M569.43 413.24H569.32" stroke="black" stroke-width="0.5" />
    <path d="M569.26 413.24H569.15" stroke="black" stroke-width="0.5" />
    <path d="M569.1 413.24H568.99" stroke="black" stroke-width="0.5" />
    <path d="M568.93 413.24H568.82" stroke="black" stroke-width="0.5" />
    <path d="M568.77 413.24H568.66" stroke="black" stroke-width="0.5" />
    <path d="M568.6 413.24H568.49" stroke="black" stroke-width="0.5" />
    <path d="M568.44 413.24H568.33" stroke="black" stroke-width="0.5" />
    <path d="M568.27 413.24H568.16" stroke="black" stroke-width="0.5" />
    <path d="M568.11 413.24H568" stroke="black" stroke-width="0.5" />
    <path d="M567.94 413.24H567.83" stroke="black" stroke-width="0.5" />
    <path d="M567.78 413.24H567.67" stroke="black" stroke-width="0.5" />
    <path d="M567.61 413.24H567.5" stroke="black" stroke-width="0.5" />
    <path d="M567.45 413.24H567.34" stroke="black" stroke-width="0.5" />
    <path d="M567.28 413.24H567.17" stroke="black" stroke-width="0.5" />
    <path d="M567.12 413.24H567.01" stroke="black" stroke-width="0.5" />
    <path d="M566.95 413.24H566.84" stroke="black" stroke-width="0.5" />
    <path d="M566.79 413.24H566.68" stroke="black" stroke-width="0.5" />
    <path d="M566.62 413.24H566.51" stroke="black" stroke-width="0.5" />
    <path d="M566.46 413.24H566.35" stroke="black" stroke-width="0.5" />
    <path d="M566.29 413.24H566.18" stroke="black" stroke-width="0.5" />
    <path d="M566.13 413.24H566.02" stroke="black" stroke-width="0.5" />
    <path d="M565.96 413.24H565.85" stroke="black" stroke-width="0.5" />
    <path d="M565.8 413.24H565.69" stroke="black" stroke-width="0.5" />
    <path d="M565.63 413.24H565.58" stroke="black" stroke-width="0.5" />
    <path d="M581.41 222.99H581.47" stroke="black" stroke-width="0.5" />
    <path d="M581.52 222.99H581.63" stroke="black" stroke-width="0.5" />
    <path d="M581.69 222.99H581.8" stroke="black" stroke-width="0.5" />
    <path d="M581.85 222.99H581.96" stroke="black" stroke-width="0.5" />
    <path d="M582.02 222.99H582.13" stroke="black" stroke-width="0.5" />
    <path d="M582.18 222.99H582.29" stroke="black" stroke-width="0.5" />
    <path d="M582.35 222.99H582.46" stroke="black" stroke-width="0.5" />
    <path d="M582.51 222.99H582.62" stroke="black" stroke-width="0.5" />
    <path d="M582.68 222.99H582.79" stroke="black" stroke-width="0.5" />
    <path d="M582.84 222.99H582.95" stroke="black" stroke-width="0.5" />
    <path d="M583.01 222.99H583.12" stroke="black" stroke-width="0.5" />
    <path d="M583.17 222.99H583.28" stroke="black" stroke-width="0.5" />
    <path d="M583.34 222.99H583.45" stroke="black" stroke-width="0.5" />
    <path d="M583.5 222.99H583.61" stroke="black" stroke-width="0.5" />
    <path d="M583.67 222.99H583.78" stroke="black" stroke-width="0.5" />
    <path d="M583.83 222.99H583.94" stroke="black" stroke-width="0.5" />
    <path d="M584 222.99H584.11" stroke="black" stroke-width="0.5" />
    <path d="M584.16 222.99H584.27" stroke="black" stroke-width="0.5" />
    <path d="M584.33 222.99H584.44" stroke="black" stroke-width="0.5" />
    <path d="M584.49 222.99H584.6" stroke="black" stroke-width="0.5" />
    <path d="M584.66 222.99H584.77" stroke="black" stroke-width="0.5" />
    <path d="M584.82 222.99H584.93" stroke="black" stroke-width="0.5" />
    <path d="M584.99 222.99H585.1" stroke="black" stroke-width="0.5" />
    <path d="M585.15 222.99H585.26" stroke="black" stroke-width="0.5" />
    <path d="M585.32 222.99H585.43" stroke="black" stroke-width="0.5" />
    <path d="M585.48 222.99H585.59" stroke="black" stroke-width="0.5" />
    <path d="M585.65 222.99H585.76" stroke="black" stroke-width="0.5" />
    <path d="M585.81 222.99H585.92" stroke="black" stroke-width="0.5" />
    <path d="M585.98 222.99H586.09" stroke="black" stroke-width="0.5" />
    <path d="M586.14 222.99H586.25" stroke="black" stroke-width="0.5" />
    <path d="M586.31 222.99H586.42" stroke="black" stroke-width="0.5" />
    <path d="M586.47 222.99H586.58" stroke="black" stroke-width="0.5" />
    <path d="M586.64 222.99H586.75" stroke="black" stroke-width="0.5" />
    <path d="M586.8 222.99H586.91" stroke="black" stroke-width="0.5" />
    <path d="M586.96 222.99H587.07" stroke="black" stroke-width="0.5" />
    <path d="M587.13 222.99H587.24" stroke="black" stroke-width="0.5" />
    <path d="M587.29 222.99H587.4" stroke="black" stroke-width="0.5" />
    <path d="M587.46 222.99H587.57" stroke="black" stroke-width="0.5" />
    <path d="M587.62 222.99H587.73" stroke="black" stroke-width="0.5" />
    <path d="M587.79 222.99H587.9" stroke="black" stroke-width="0.5" />
    <path d="M587.95 222.99H588.06" stroke="black" stroke-width="0.5" />
    <path d="M588.12 222.99H588.23" stroke="black" stroke-width="0.5" />
    <path d="M588.28 222.99H588.39" stroke="black" stroke-width="0.5" />
    <path d="M588.45 222.99H588.56" stroke="black" stroke-width="0.5" />
    <path d="M588.61 222.99H588.72" stroke="black" stroke-width="0.5" />
    <path d="M588.78 222.99H588.89" stroke="black" stroke-width="0.5" />
    <path d="M588.94 222.99H589.05" stroke="black" stroke-width="0.5" />
    <path d="M589.11 222.99H589.22" stroke="black" stroke-width="0.5" />
    <path d="M589.27 222.99H589.38" stroke="black" stroke-width="0.5" />
    <path d="M589.44 222.99H589.55" stroke="black" stroke-width="0.5" />
    <path d="M589.6 222.99H589.71" stroke="black" stroke-width="0.5" />
    <path d="M589.77 222.99H589.88" stroke="black" stroke-width="0.5" />
    <path d="M589.93 222.99H590.04" stroke="black" stroke-width="0.5" />
    <path d="M590.1 222.99H590.21" stroke="black" stroke-width="0.5" />
    <path d="M590.26 222.99H590.37" stroke="black" stroke-width="0.5" />
    <path d="M590.43 222.99H590.54" stroke="black" stroke-width="0.5" />
    <path d="M590.59 222.99H590.7" stroke="black" stroke-width="0.5" />
    <path d="M590.76 222.99H590.87" stroke="black" stroke-width="0.5" />
    <path d="M590.92 222.99H591.03" stroke="black" stroke-width="0.5" />
    <path d="M591.09 222.99H591.2" stroke="black" stroke-width="0.5" />
    <path d="M591.25 222.99H591.36" stroke="black" stroke-width="0.5" />
    <path d="M591.42 222.99H591.53" stroke="black" stroke-width="0.5" />
    <path d="M591.58 222.99H591.69" stroke="black" stroke-width="0.5" />
    <path d="M591.75 222.99H591.86" stroke="black" stroke-width="0.5" />
    <path d="M591.91 222.99H592.02" stroke="black" stroke-width="0.5" />
    <path d="M592.08 222.99H592.19" stroke="black" stroke-width="0.5" />
    <path d="M592.24 222.99H592.35" stroke="black" stroke-width="0.5" />
    <path d="M592.41 222.99H592.52" stroke="black" stroke-width="0.5" />
    <path d="M592.57 222.99H592.68" stroke="black" stroke-width="0.5" />
    <path d="M592.74 222.99H592.85" stroke="black" stroke-width="0.5" />
    <path d="M592.9 222.99H593.01" stroke="black" stroke-width="0.5" />
    <path d="M593.07 222.99H593.18" stroke="black" stroke-width="0.5" />
    <path d="M593.23 222.99H593.34" stroke="black" stroke-width="0.5" />
    <path d="M593.4 222.99H593.51" stroke="black" stroke-width="0.5" />
    <path d="M593.56 222.99H593.67" stroke="black" stroke-width="0.5" />
    <path d="M593.73 222.99H593.84" stroke="black" stroke-width="0.5" />
    <path d="M593.89 222.99H594" stroke="black" stroke-width="0.5" />
    <path d="M594.06 222.99H594.17" stroke="black" stroke-width="0.5" />
    <path d="M594.22 222.99H594.33" stroke="black" stroke-width="0.5" />
    <path d="M594.39 222.99H594.5" stroke="black" stroke-width="0.5" />
    <path d="M594.55 222.99H594.66" stroke="black" stroke-width="0.5" />
    <path d="M594.72 222.99H594.83" stroke="black" stroke-width="0.5" />
    <path d="M594.88 222.99H594.99" stroke="black" stroke-width="0.5" />
    <path d="M595.05 222.99H595.16" stroke="black" stroke-width="0.5" />
    <path d="M595.21 222.99H595.32" stroke="black" stroke-width="0.5" />
    <path d="M595.38 222.99H595.49" stroke="black" stroke-width="0.5" />
    <path d="M595.54 222.99H595.65" stroke="black" stroke-width="0.5" />
    <path d="M595.71 222.99H595.82" stroke="black" stroke-width="0.5" />
    <path d="M595.87 222.99H595.98" stroke="black" stroke-width="0.5" />
    <path d="M596.04 222.99H596.15" stroke="black" stroke-width="0.5" />
    <path d="M596.2 222.99H596.31" stroke="black" stroke-width="0.5" />
    <path d="M596.37 222.99H596.48" stroke="black" stroke-width="0.5" />
    <path d="M596.53 222.99H596.64" stroke="black" stroke-width="0.5" />
    <path d="M596.7 222.99H596.81" stroke="black" stroke-width="0.5" />
    <path d="M596.86 222.99H596.97" stroke="black" stroke-width="0.5" />
    <path d="M597.03 222.99H597.14" stroke="black" stroke-width="0.5" />
    <path d="M597.19 222.99H597.3" stroke="black" stroke-width="0.5" />
    <path d="M597.36 222.99H597.47" stroke="black" stroke-width="0.5" />
    <path d="M597.52 222.99H597.63" stroke="black" stroke-width="0.5" />
    <path d="M597.69 222.99H597.8" stroke="black" stroke-width="0.5" />
    <path d="M597.85 222.99H597.96" stroke="black" stroke-width="0.5" />
    <path d="M598.02 222.99H598.13" stroke="black" stroke-width="0.5" />
    <path d="M598.18 222.99H598.29" stroke="black" stroke-width="0.5" />
    <path d="M598.35 222.99H598.46" stroke="black" stroke-width="0.5" />
    <path d="M598.51 222.99H598.62" stroke="black" stroke-width="0.5" />
    <path d="M598.68 222.99H598.79" stroke="black" stroke-width="0.5" />
    <path d="M598.84 222.99H598.95" stroke="black" stroke-width="0.5" />
    <path d="M599.01 222.99H599.12" stroke="black" stroke-width="0.5" />
    <path d="M599.17 222.99H599.28" stroke="black" stroke-width="0.5" />
    <path d="M599.34 222.99H599.45" stroke="black" stroke-width="0.5" />
    <path d="M599.5 222.99H599.61" stroke="black" stroke-width="0.5" />
    <path d="M599.67 222.99H599.77" stroke="black" stroke-width="0.5" />
    <path d="M599.83 222.99H599.94" stroke="black" stroke-width="0.5" />
    <path d="M599.99 222.99H600.1" stroke="black" stroke-width="0.5" />
    <path d="M600.16 222.99H600.27" stroke="black" stroke-width="0.5" />
    <path d="M600.32 222.99H600.43" stroke="black" stroke-width="0.5" />
    <path d="M600.49 222.99H600.6" stroke="black" stroke-width="0.5" />
    <path d="M600.65 222.99H600.76" stroke="black" stroke-width="0.5" />
    <path d="M600.82 222.99H600.93" stroke="black" stroke-width="0.5" />
    <path d="M600.98 222.99H601.09" stroke="black" stroke-width="0.5" />
    <path d="M601.15 222.99H601.26" stroke="black" stroke-width="0.5" />
    <path d="M601.31 222.99H601.42" stroke="black" stroke-width="0.5" />
    <path d="M601.48 222.99H601.59" stroke="black" stroke-width="0.5" />
    <path d="M601.64 222.99H601.75" stroke="black" stroke-width="0.5" />
    <path d="M601.81 222.99H601.92" stroke="black" stroke-width="0.5" />
    <path d="M601.97 222.99H602.08" stroke="black" stroke-width="0.5" />
    <path d="M602.14 222.99H602.25" stroke="black" stroke-width="0.5" />
    <path d="M602.3 222.99H602.41" stroke="black" stroke-width="0.5" />
    <path d="M602.47 222.99H602.52" stroke="black" stroke-width="0.5" />
    <path d="M602.52 244.1V244.05" stroke="black" stroke-width="0.5" />
    <path d="M602.52 243.99V243.88" stroke="black" stroke-width="0.5" />
    <path d="M602.52 243.83V243.72" stroke="black" stroke-width="0.5" />
    <path d="M602.52 243.66V243.55" stroke="black" stroke-width="0.5" />
    <path d="M602.52 243.5V243.39" stroke="black" stroke-width="0.5" />
    <path d="M602.52 243.33V243.22" stroke="black" stroke-width="0.5" />
    <path d="M602.52 243.17V243.06" stroke="black" stroke-width="0.5" />
    <path d="M602.52 243V242.89" stroke="black" stroke-width="0.5" />
    <path d="M602.52 242.84V242.73" stroke="black" stroke-width="0.5" />
    <path d="M602.52 242.67V242.56" stroke="black" stroke-width="0.5" />
    <path d="M602.52 242.51V242.4" stroke="black" stroke-width="0.5" />
    <path d="M602.52 242.34V242.23" stroke="black" stroke-width="0.5" />
    <path d="M602.52 242.18V242.07" stroke="black" stroke-width="0.5" />
    <path d="M602.52 242.01V241.9" stroke="black" stroke-width="0.5" />
    <path d="M602.52 241.85V241.74" stroke="black" stroke-width="0.5" />
    <path d="M602.52 241.68V241.57" stroke="black" stroke-width="0.5" />
    <path d="M602.52 241.52V241.41" stroke="black" stroke-width="0.5" />
    <path d="M602.52 241.35V241.24" stroke="black" stroke-width="0.5" />
    <path d="M602.52 241.19V241.08" stroke="black" stroke-width="0.5" />
    <path d="M602.52 241.02V240.91" stroke="black" stroke-width="0.5" />
    <path d="M602.52 240.86V240.75" stroke="black" stroke-width="0.5" />
    <path d="M602.52 240.69V240.58" stroke="black" stroke-width="0.5" />
    <path d="M602.52 240.53V240.42" stroke="black" stroke-width="0.5" />
    <path d="M602.52 240.36V240.25" stroke="black" stroke-width="0.5" />
    <path d="M602.52 240.2V240.09" stroke="black" stroke-width="0.5" />
    <path d="M602.52 240.03V239.92" stroke="black" stroke-width="0.5" />
    <path d="M602.52 239.87V239.76" stroke="black" stroke-width="0.5" />
    <path d="M602.52 239.7V239.59" stroke="black" stroke-width="0.5" />
    <path d="M602.52 239.54V239.43" stroke="black" stroke-width="0.5" />
    <path d="M602.52 239.37V239.26" stroke="black" stroke-width="0.5" />
    <path d="M602.52 239.21V239.1" stroke="black" stroke-width="0.5" />
    <path d="M602.52 239.04V238.93" stroke="black" stroke-width="0.5" />
    <path d="M602.52 238.88V238.77" stroke="black" stroke-width="0.5" />
    <path d="M602.52 238.71V238.6" stroke="black" stroke-width="0.5" />
    <path d="M602.52 238.55V238.44" stroke="black" stroke-width="0.5" />
    <path d="M602.52 238.38V238.27" stroke="black" stroke-width="0.5" />
    <path d="M602.52 238.22V238.11" stroke="black" stroke-width="0.5" />
    <path d="M602.52 238.05V237.94" stroke="black" stroke-width="0.5" />
    <path d="M602.52 237.89V237.78" stroke="black" stroke-width="0.5" />
    <path d="M602.52 237.72V237.61" stroke="black" stroke-width="0.5" />
    <path d="M602.52 237.56V237.45" stroke="black" stroke-width="0.5" />
    <path d="M602.52 237.39V237.28" stroke="black" stroke-width="0.5" />
    <path d="M602.52 237.23V237.12" stroke="black" stroke-width="0.5" />
    <path d="M602.52 237.06V236.95" stroke="black" stroke-width="0.5" />
    <path d="M602.52 236.9V236.79" stroke="black" stroke-width="0.5" />
    <path d="M602.52 236.73V236.62" stroke="black" stroke-width="0.5" />
    <path d="M602.52 236.57V236.46" stroke="black" stroke-width="0.5" />
    <path d="M602.52 236.4V236.29" stroke="black" stroke-width="0.5" />
    <path d="M602.52 236.24V236.13" stroke="black" stroke-width="0.5" />
    <path d="M602.52 236.07V235.96" stroke="black" stroke-width="0.5" />
    <path d="M602.52 235.91V235.8" stroke="black" stroke-width="0.5" />
    <path d="M602.52 235.74V235.63" stroke="black" stroke-width="0.5" />
    <path d="M602.52 235.58V235.47" stroke="black" stroke-width="0.5" />
    <path d="M602.52 235.41V235.3" stroke="black" stroke-width="0.5" />
    <path d="M602.52 235.25V235.14" stroke="black" stroke-width="0.5" />
    <path d="M602.52 235.08V234.97" stroke="black" stroke-width="0.5" />
    <path d="M602.52 234.92V234.81" stroke="black" stroke-width="0.5" />
    <path d="M602.52 234.75V234.64" stroke="black" stroke-width="0.5" />
    <path d="M602.52 234.59V234.48" stroke="black" stroke-width="0.5" />
    <path d="M602.52 234.42V234.31" stroke="black" stroke-width="0.5" />
    <path d="M602.52 234.26V234.15" stroke="black" stroke-width="0.5" />
    <path d="M602.52 234.09V233.98" stroke="black" stroke-width="0.5" />
    <path d="M602.52 233.93V233.82" stroke="black" stroke-width="0.5" />
    <path d="M602.52 233.76V233.65" stroke="black" stroke-width="0.5" />
    <path d="M602.52 233.6V233.49" stroke="black" stroke-width="0.5" />
    <path d="M602.52 233.43V233.32" stroke="black" stroke-width="0.5" />
    <path d="M602.52 233.27V233.16" stroke="black" stroke-width="0.5" />
    <path d="M602.52 233.1V232.99" stroke="black" stroke-width="0.5" />
    <path d="M602.52 232.94V232.83" stroke="black" stroke-width="0.5" />
    <path d="M602.52 232.77V232.66" stroke="black" stroke-width="0.5" />
    <path d="M602.52 232.61V232.5" stroke="black" stroke-width="0.5" />
    <path d="M602.52 232.44V232.33" stroke="black" stroke-width="0.5" />
    <path d="M602.52 232.28V232.17" stroke="black" stroke-width="0.5" />
    <path d="M602.52 232.11V232" stroke="black" stroke-width="0.5" />
    <path d="M602.52 231.95V231.84" stroke="black" stroke-width="0.5" />
    <path d="M602.52 231.78V231.67" stroke="black" stroke-width="0.5" />
    <path d="M602.52 231.62V231.51" stroke="black" stroke-width="0.5" />
    <path d="M602.52 231.45V231.34" stroke="black" stroke-width="0.5" />
    <path d="M602.52 231.29V231.18" stroke="black" stroke-width="0.5" />
    <path d="M602.52 231.13V231.02" stroke="black" stroke-width="0.5" />
    <path d="M602.52 230.96V230.85" stroke="black" stroke-width="0.5" />
    <path d="M602.52 230.8V230.69" stroke="black" stroke-width="0.5" />
    <path d="M602.52 230.63V230.52" stroke="black" stroke-width="0.5" />
    <path d="M602.52 230.47V230.36" stroke="black" stroke-width="0.5" />
    <path d="M602.52 230.3V230.19" stroke="black" stroke-width="0.5" />
    <path d="M602.52 230.14V230.03" stroke="black" stroke-width="0.5" />
    <path d="M602.52 229.97V229.86" stroke="black" stroke-width="0.5" />
    <path d="M602.52 229.81V229.7" stroke="black" stroke-width="0.5" />
    <path d="M602.52 229.64V229.53" stroke="black" stroke-width="0.5" />
    <path d="M602.52 229.48V229.37" stroke="black" stroke-width="0.5" />
    <path d="M602.52 229.31V229.2" stroke="black" stroke-width="0.5" />
    <path d="M602.52 229.15V229.04" stroke="black" stroke-width="0.5" />
    <path d="M602.52 228.98V228.87" stroke="black" stroke-width="0.5" />
    <path d="M602.52 228.82V228.71" stroke="black" stroke-width="0.5" />
    <path d="M602.52 228.65V228.54" stroke="black" stroke-width="0.5" />
    <path d="M602.52 228.49V228.38" stroke="black" stroke-width="0.5" />
    <path d="M602.52 228.32V228.21" stroke="black" stroke-width="0.5" />
    <path d="M602.52 228.16V228.05" stroke="black" stroke-width="0.5" />
    <path d="M602.52 227.99V227.88" stroke="black" stroke-width="0.5" />
    <path d="M602.52 227.83V227.72" stroke="black" stroke-width="0.5" />
    <path d="M602.52 227.66V227.55" stroke="black" stroke-width="0.5" />
    <path d="M602.52 227.5V227.39" stroke="black" stroke-width="0.5" />
    <path d="M602.52 227.33V227.22" stroke="black" stroke-width="0.5" />
    <path d="M602.52 227.17V227.06" stroke="black" stroke-width="0.5" />
    <path d="M602.52 227V226.89" stroke="black" stroke-width="0.5" />
    <path d="M602.52 226.84V226.73" stroke="black" stroke-width="0.5" />
    <path d="M602.52 226.67V226.56" stroke="black" stroke-width="0.5" />
    <path d="M602.52 226.51V226.4" stroke="black" stroke-width="0.5" />
    <path d="M602.52 226.34V226.23" stroke="black" stroke-width="0.5" />
    <path d="M602.52 226.18V226.07" stroke="black" stroke-width="0.5" />
    <path d="M602.52 226.01V225.9" stroke="black" stroke-width="0.5" />
    <path d="M602.52 225.85V225.74" stroke="black" stroke-width="0.5" />
    <path d="M602.52 225.68V225.57" stroke="black" stroke-width="0.5" />
    <path d="M602.52 225.52V225.41" stroke="black" stroke-width="0.5" />
    <path d="M602.52 225.35V225.24" stroke="black" stroke-width="0.5" />
    <path d="M602.52 225.19V225.08" stroke="black" stroke-width="0.5" />
    <path d="M602.52 225.02V224.91" stroke="black" stroke-width="0.5" />
    <path d="M602.52 224.86V224.75" stroke="black" stroke-width="0.5" />
    <path d="M602.52 224.69V224.58" stroke="black" stroke-width="0.5" />
    <path d="M602.52 224.53V224.42" stroke="black" stroke-width="0.5" />
    <path d="M602.52 224.36V224.25" stroke="black" stroke-width="0.5" />
    <path d="M602.52 224.2V224.09" stroke="black" stroke-width="0.5" />
    <path d="M602.52 224.03V223.92" stroke="black" stroke-width="0.5" />
    <path d="M602.52 223.87V223.76" stroke="black" stroke-width="0.5" />
    <path d="M602.52 223.7V223.59" stroke="black" stroke-width="0.5" />
    <path d="M602.52 223.54V223.43" stroke="black" stroke-width="0.5" />
    <path d="M602.52 223.37V223.26" stroke="black" stroke-width="0.5" />
    <path d="M602.52 223.21V223.1" stroke="black" stroke-width="0.5" />
    <path d="M602.52 223.04V222.99" stroke="black" stroke-width="0.5" />
    <path d="M581.41 244.1H581.47" stroke="black" stroke-width="0.5" />
    <path d="M581.52 244.1H581.63" stroke="black" stroke-width="0.5" />
    <path d="M581.69 244.1H581.8" stroke="black" stroke-width="0.5" />
    <path d="M581.85 244.1H581.96" stroke="black" stroke-width="0.5" />
    <path d="M582.02 244.1H582.13" stroke="black" stroke-width="0.5" />
    <path d="M582.18 244.1H582.29" stroke="black" stroke-width="0.5" />
    <path d="M582.35 244.1H582.46" stroke="black" stroke-width="0.5" />
    <path d="M582.51 244.1H582.62" stroke="black" stroke-width="0.5" />
    <path d="M582.68 244.1H582.79" stroke="black" stroke-width="0.5" />
    <path d="M582.84 244.1H582.95" stroke="black" stroke-width="0.5" />
    <path d="M583.01 244.1H583.12" stroke="black" stroke-width="0.5" />
    <path d="M583.17 244.1H583.28" stroke="black" stroke-width="0.5" />
    <path d="M583.34 244.1H583.45" stroke="black" stroke-width="0.5" />
    <path d="M583.5 244.1H583.61" stroke="black" stroke-width="0.5" />
    <path d="M583.67 244.1H583.78" stroke="black" stroke-width="0.5" />
    <path d="M583.83 244.1H583.94" stroke="black" stroke-width="0.5" />
    <path d="M584 244.1H584.11" stroke="black" stroke-width="0.5" />
    <path d="M584.16 244.1H584.27" stroke="black" stroke-width="0.5" />
    <path d="M584.33 244.1H584.44" stroke="black" stroke-width="0.5" />
    <path d="M584.49 244.1H584.6" stroke="black" stroke-width="0.5" />
    <path d="M584.66 244.1H584.77" stroke="black" stroke-width="0.5" />
    <path d="M584.82 244.1H584.93" stroke="black" stroke-width="0.5" />
    <path d="M584.99 244.1H585.1" stroke="black" stroke-width="0.5" />
    <path d="M585.15 244.1H585.26" stroke="black" stroke-width="0.5" />
    <path d="M585.32 244.1H585.43" stroke="black" stroke-width="0.5" />
    <path d="M585.48 244.1H585.59" stroke="black" stroke-width="0.5" />
    <path d="M585.65 244.1H585.76" stroke="black" stroke-width="0.5" />
    <path d="M585.81 244.1H585.92" stroke="black" stroke-width="0.5" />
    <path d="M585.98 244.1H586.09" stroke="black" stroke-width="0.5" />
    <path d="M586.14 244.1H586.25" stroke="black" stroke-width="0.5" />
    <path d="M586.31 244.1H586.42" stroke="black" stroke-width="0.5" />
    <path d="M586.47 244.1H586.58" stroke="black" stroke-width="0.5" />
    <path d="M586.64 244.1H586.75" stroke="black" stroke-width="0.5" />
    <path d="M586.8 244.1H586.91" stroke="black" stroke-width="0.5" />
    <path d="M586.96 244.1H587.07" stroke="black" stroke-width="0.5" />
    <path d="M587.13 244.1H587.24" stroke="black" stroke-width="0.5" />
    <path d="M587.29 244.1H587.4" stroke="black" stroke-width="0.5" />
    <path d="M587.46 244.1H587.57" stroke="black" stroke-width="0.5" />
    <path d="M587.62 244.1H587.73" stroke="black" stroke-width="0.5" />
    <path d="M587.79 244.1H587.9" stroke="black" stroke-width="0.5" />
    <path d="M587.95 244.1H588.06" stroke="black" stroke-width="0.5" />
    <path d="M588.12 244.1H588.23" stroke="black" stroke-width="0.5" />
    <path d="M588.28 244.1H588.39" stroke="black" stroke-width="0.5" />
    <path d="M588.45 244.1H588.56" stroke="black" stroke-width="0.5" />
    <path d="M588.61 244.1H588.72" stroke="black" stroke-width="0.5" />
    <path d="M588.78 244.1H588.89" stroke="black" stroke-width="0.5" />
    <path d="M588.94 244.1H589.05" stroke="black" stroke-width="0.5" />
    <path d="M589.11 244.1H589.22" stroke="black" stroke-width="0.5" />
    <path d="M589.27 244.1H589.38" stroke="black" stroke-width="0.5" />
    <path d="M589.44 244.1H589.55" stroke="black" stroke-width="0.5" />
    <path d="M589.6 244.1H589.71" stroke="black" stroke-width="0.5" />
    <path d="M589.77 244.1H589.88" stroke="black" stroke-width="0.5" />
    <path d="M589.93 244.1H590.04" stroke="black" stroke-width="0.5" />
    <path d="M590.1 244.1H590.21" stroke="black" stroke-width="0.5" />
    <path d="M590.26 244.1H590.37" stroke="black" stroke-width="0.5" />
    <path d="M590.43 244.1H590.54" stroke="black" stroke-width="0.5" />
    <path d="M590.59 244.1H590.7" stroke="black" stroke-width="0.5" />
    <path d="M590.76 244.1H590.87" stroke="black" stroke-width="0.5" />
    <path d="M590.92 244.1H591.03" stroke="black" stroke-width="0.5" />
    <path d="M591.09 244.1H591.2" stroke="black" stroke-width="0.5" />
    <path d="M591.25 244.1H591.36" stroke="black" stroke-width="0.5" />
    <path d="M591.42 244.1H591.53" stroke="black" stroke-width="0.5" />
    <path d="M591.58 244.1H591.69" stroke="black" stroke-width="0.5" />
    <path d="M591.75 244.1H591.86" stroke="black" stroke-width="0.5" />
    <path d="M591.91 244.1H592.02" stroke="black" stroke-width="0.5" />
    <path d="M592.08 244.1H592.19" stroke="black" stroke-width="0.5" />
    <path d="M592.24 244.1H592.35" stroke="black" stroke-width="0.5" />
    <path d="M592.41 244.1H592.52" stroke="black" stroke-width="0.5" />
    <path d="M592.57 244.1H592.68" stroke="black" stroke-width="0.5" />
    <path d="M592.74 244.1H592.85" stroke="black" stroke-width="0.5" />
    <path d="M592.9 244.1H593.01" stroke="black" stroke-width="0.5" />
    <path d="M593.07 244.1H593.18" stroke="black" stroke-width="0.5" />
    <path d="M593.23 244.1H593.34" stroke="black" stroke-width="0.5" />
    <path d="M593.4 244.1H593.51" stroke="black" stroke-width="0.5" />
    <path d="M593.56 244.1H593.67" stroke="black" stroke-width="0.5" />
    <path d="M593.73 244.1H593.84" stroke="black" stroke-width="0.5" />
    <path d="M593.89 244.1H594" stroke="black" stroke-width="0.5" />
    <path d="M594.06 244.1H594.17" stroke="black" stroke-width="0.5" />
    <path d="M594.22 244.1H594.33" stroke="black" stroke-width="0.5" />
    <path d="M594.39 244.1H594.5" stroke="black" stroke-width="0.5" />
    <path d="M594.55 244.1H594.66" stroke="black" stroke-width="0.5" />
    <path d="M594.72 244.1H594.83" stroke="black" stroke-width="0.5" />
    <path d="M594.88 244.1H594.99" stroke="black" stroke-width="0.5" />
    <path d="M595.05 244.1H595.16" stroke="black" stroke-width="0.5" />
    <path d="M595.21 244.1H595.32" stroke="black" stroke-width="0.5" />
    <path d="M595.38 244.1H595.49" stroke="black" stroke-width="0.5" />
    <path d="M595.54 244.1H595.65" stroke="black" stroke-width="0.5" />
    <path d="M595.71 244.1H595.82" stroke="black" stroke-width="0.5" />
    <path d="M595.87 244.1H595.98" stroke="black" stroke-width="0.5" />
    <path d="M596.04 244.1H596.15" stroke="black" stroke-width="0.5" />
    <path d="M596.2 244.1H596.31" stroke="black" stroke-width="0.5" />
    <path d="M596.37 244.1H596.48" stroke="black" stroke-width="0.5" />
    <path d="M596.53 244.1H596.64" stroke="black" stroke-width="0.5" />
    <path d="M596.7 244.1H596.81" stroke="black" stroke-width="0.5" />
    <path d="M596.86 244.1H596.97" stroke="black" stroke-width="0.5" />
    <path d="M597.03 244.1H597.14" stroke="black" stroke-width="0.5" />
    <path d="M597.19 244.1H597.3" stroke="black" stroke-width="0.5" />
    <path d="M597.36 244.1H597.47" stroke="black" stroke-width="0.5" />
    <path d="M597.52 244.1H597.63" stroke="black" stroke-width="0.5" />
    <path d="M597.69 244.1H597.8" stroke="black" stroke-width="0.5" />
    <path d="M597.85 244.1H597.96" stroke="black" stroke-width="0.5" />
    <path d="M598.02 244.1H598.13" stroke="black" stroke-width="0.5" />
    <path d="M598.18 244.1H598.29" stroke="black" stroke-width="0.5" />
    <path d="M598.35 244.1H598.46" stroke="black" stroke-width="0.5" />
    <path d="M598.51 244.1H598.62" stroke="black" stroke-width="0.5" />
    <path d="M598.68 244.1H598.79" stroke="black" stroke-width="0.5" />
    <path d="M598.84 244.1H598.95" stroke="black" stroke-width="0.5" />
    <path d="M599.01 244.1H599.12" stroke="black" stroke-width="0.5" />
    <path d="M599.17 244.1H599.28" stroke="black" stroke-width="0.5" />
    <path d="M599.34 244.1H599.45" stroke="black" stroke-width="0.5" />
    <path d="M599.5 244.1H599.61" stroke="black" stroke-width="0.5" />
    <path d="M599.67 244.1H599.77" stroke="black" stroke-width="0.5" />
    <path d="M599.83 244.1H599.94" stroke="black" stroke-width="0.5" />
    <path d="M599.99 244.1H600.1" stroke="black" stroke-width="0.5" />
    <path d="M600.16 244.1H600.27" stroke="black" stroke-width="0.5" />
    <path d="M600.32 244.1H600.43" stroke="black" stroke-width="0.5" />
    <path d="M600.49 244.1H600.6" stroke="black" stroke-width="0.5" />
    <path d="M600.65 244.1H600.76" stroke="black" stroke-width="0.5" />
    <path d="M600.82 244.1H600.93" stroke="black" stroke-width="0.5" />
    <path d="M600.98 244.1H601.09" stroke="black" stroke-width="0.5" />
    <path d="M601.15 244.1H601.26" stroke="black" stroke-width="0.5" />
    <path d="M601.31 244.1H601.42" stroke="black" stroke-width="0.5" />
    <path d="M601.48 244.1H601.59" stroke="black" stroke-width="0.5" />
    <path d="M601.64 244.1H601.75" stroke="black" stroke-width="0.5" />
    <path d="M601.81 244.1H601.92" stroke="black" stroke-width="0.5" />
    <path d="M601.97 244.1H602.08" stroke="black" stroke-width="0.5" />
    <path d="M602.14 244.1H602.25" stroke="black" stroke-width="0.5" />
    <path d="M602.3 244.1H602.41" stroke="black" stroke-width="0.5" />
    <path d="M602.47 244.1H602.52" stroke="black" stroke-width="0.5" />
    <path d="M581.41 114.02H581.47" stroke="black" stroke-width="0.5" />
    <path d="M581.52 114.02H581.63" stroke="black" stroke-width="0.5" />
    <path d="M581.69 114.02H581.8" stroke="black" stroke-width="0.5" />
    <path d="M581.85 114.02H581.96" stroke="black" stroke-width="0.5" />
    <path d="M582.02 114.02H582.13" stroke="black" stroke-width="0.5" />
    <path d="M582.18 114.02H582.29" stroke="black" stroke-width="0.5" />
    <path d="M582.35 114.02H582.46" stroke="black" stroke-width="0.5" />
    <path d="M582.51 114.02H582.62" stroke="black" stroke-width="0.5" />
    <path d="M582.68 114.02H582.79" stroke="black" stroke-width="0.5" />
    <path d="M582.84 114.02H582.95" stroke="black" stroke-width="0.5" />
    <path d="M583.01 114.02H583.12" stroke="black" stroke-width="0.5" />
    <path d="M583.17 114.02H583.28" stroke="black" stroke-width="0.5" />
    <path d="M583.34 114.02H583.45" stroke="black" stroke-width="0.5" />
    <path d="M583.5 114.02H583.61" stroke="black" stroke-width="0.5" />
    <path d="M583.67 114.02H583.78" stroke="black" stroke-width="0.5" />
    <path d="M583.83 114.02H583.94" stroke="black" stroke-width="0.5" />
    <path d="M584 114.02H584.11" stroke="black" stroke-width="0.5" />
    <path d="M584.16 114.02H584.27" stroke="black" stroke-width="0.5" />
    <path d="M584.33 114.02H584.44" stroke="black" stroke-width="0.5" />
    <path d="M584.49 114.02H584.6" stroke="black" stroke-width="0.5" />
    <path d="M584.66 114.02H584.77" stroke="black" stroke-width="0.5" />
    <path d="M584.82 114.02H584.93" stroke="black" stroke-width="0.5" />
    <path d="M584.99 114.02H585.1" stroke="black" stroke-width="0.5" />
    <path d="M585.15 114.02H585.26" stroke="black" stroke-width="0.5" />
    <path d="M585.32 114.02H585.43" stroke="black" stroke-width="0.5" />
    <path d="M585.48 114.02H585.59" stroke="black" stroke-width="0.5" />
    <path d="M585.65 114.02H585.76" stroke="black" stroke-width="0.5" />
    <path d="M585.81 114.02H585.92" stroke="black" stroke-width="0.5" />
    <path d="M585.98 114.02H586.09" stroke="black" stroke-width="0.5" />
    <path d="M586.14 114.02H586.25" stroke="black" stroke-width="0.5" />
    <path d="M586.31 114.02H586.42" stroke="black" stroke-width="0.5" />
    <path d="M586.47 114.02H586.58" stroke="black" stroke-width="0.5" />
    <path d="M586.64 114.02H586.75" stroke="black" stroke-width="0.5" />
    <path d="M586.8 114.02H586.91" stroke="black" stroke-width="0.5" />
    <path d="M586.96 114.02H587.07" stroke="black" stroke-width="0.5" />
    <path d="M587.13 114.02H587.24" stroke="black" stroke-width="0.5" />
    <path d="M587.29 114.02H587.4" stroke="black" stroke-width="0.5" />
    <path d="M587.46 114.02H587.57" stroke="black" stroke-width="0.5" />
    <path d="M587.62 114.02H587.73" stroke="black" stroke-width="0.5" />
    <path d="M587.79 114.02H587.9" stroke="black" stroke-width="0.5" />
    <path d="M587.95 114.02H588.06" stroke="black" stroke-width="0.5" />
    <path d="M588.12 114.02H588.23" stroke="black" stroke-width="0.5" />
    <path d="M588.28 114.02H588.39" stroke="black" stroke-width="0.5" />
    <path d="M588.45 114.02H588.56" stroke="black" stroke-width="0.5" />
    <path d="M588.61 114.02H588.72" stroke="black" stroke-width="0.5" />
    <path d="M588.78 114.02H588.89" stroke="black" stroke-width="0.5" />
    <path d="M588.94 114.02H589.05" stroke="black" stroke-width="0.5" />
    <path d="M589.11 114.02H589.22" stroke="black" stroke-width="0.5" />
    <path d="M589.27 114.02H589.38" stroke="black" stroke-width="0.5" />
    <path d="M589.44 114.02H589.55" stroke="black" stroke-width="0.5" />
    <path d="M589.6 114.02H589.71" stroke="black" stroke-width="0.5" />
    <path d="M589.77 114.02H589.88" stroke="black" stroke-width="0.5" />
    <path d="M589.93 114.02H590.04" stroke="black" stroke-width="0.5" />
    <path d="M590.1 114.02H590.21" stroke="black" stroke-width="0.5" />
    <path d="M590.26 114.02H590.37" stroke="black" stroke-width="0.5" />
    <path d="M590.43 114.02H590.54" stroke="black" stroke-width="0.5" />
    <path d="M590.59 114.02H590.7" stroke="black" stroke-width="0.5" />
    <path d="M590.76 114.02H590.87" stroke="black" stroke-width="0.5" />
    <path d="M590.92 114.02H591.03" stroke="black" stroke-width="0.5" />
    <path d="M591.09 114.02H591.2" stroke="black" stroke-width="0.5" />
    <path d="M591.25 114.02H591.36" stroke="black" stroke-width="0.5" />
    <path d="M591.42 114.02H591.53" stroke="black" stroke-width="0.5" />
    <path d="M591.58 114.02H591.69" stroke="black" stroke-width="0.5" />
    <path d="M591.75 114.02H591.86" stroke="black" stroke-width="0.5" />
    <path d="M591.91 114.02H592.02" stroke="black" stroke-width="0.5" />
    <path d="M592.08 114.02H592.19" stroke="black" stroke-width="0.5" />
    <path d="M592.24 114.02H592.35" stroke="black" stroke-width="0.5" />
    <path d="M592.41 114.02H592.52" stroke="black" stroke-width="0.5" />
    <path d="M592.57 114.02H592.68" stroke="black" stroke-width="0.5" />
    <path d="M592.74 114.02H592.85" stroke="black" stroke-width="0.5" />
    <path d="M592.9 114.02H593.01" stroke="black" stroke-width="0.5" />
    <path d="M593.07 114.02H593.18" stroke="black" stroke-width="0.5" />
    <path d="M593.23 114.02H593.34" stroke="black" stroke-width="0.5" />
    <path d="M593.4 114.02H593.51" stroke="black" stroke-width="0.5" />
    <path d="M593.56 114.02H593.67" stroke="black" stroke-width="0.5" />
    <path d="M593.73 114.02H593.84" stroke="black" stroke-width="0.5" />
    <path d="M593.89 114.02H594" stroke="black" stroke-width="0.5" />
    <path d="M594.06 114.02H594.17" stroke="black" stroke-width="0.5" />
    <path d="M594.22 114.02H594.33" stroke="black" stroke-width="0.5" />
    <path d="M594.39 114.02H594.5" stroke="black" stroke-width="0.5" />
    <path d="M594.55 114.02H594.66" stroke="black" stroke-width="0.5" />
    <path d="M594.72 114.02H594.83" stroke="black" stroke-width="0.5" />
    <path d="M594.88 114.02H594.99" stroke="black" stroke-width="0.5" />
    <path d="M595.05 114.02H595.16" stroke="black" stroke-width="0.5" />
    <path d="M595.21 114.02H595.32" stroke="black" stroke-width="0.5" />
    <path d="M595.38 114.02H595.49" stroke="black" stroke-width="0.5" />
    <path d="M595.54 114.02H595.65" stroke="black" stroke-width="0.5" />
    <path d="M595.71 114.02H595.82" stroke="black" stroke-width="0.5" />
    <path d="M595.87 114.02H595.98" stroke="black" stroke-width="0.5" />
    <path d="M596.04 114.02H596.15" stroke="black" stroke-width="0.5" />
    <path d="M596.2 114.02H596.31" stroke="black" stroke-width="0.5" />
    <path d="M596.37 114.02H596.48" stroke="black" stroke-width="0.5" />
    <path d="M596.53 114.02H596.64" stroke="black" stroke-width="0.5" />
    <path d="M596.7 114.02H596.81" stroke="black" stroke-width="0.5" />
    <path d="M596.86 114.02H596.97" stroke="black" stroke-width="0.5" />
    <path d="M597.03 114.02H597.14" stroke="black" stroke-width="0.5" />
    <path d="M597.19 114.02H597.3" stroke="black" stroke-width="0.5" />
    <path d="M597.36 114.02H597.47" stroke="black" stroke-width="0.5" />
    <path d="M597.52 114.02H597.63" stroke="black" stroke-width="0.5" />
    <path d="M597.69 114.02H597.8" stroke="black" stroke-width="0.5" />
    <path d="M597.85 114.02H597.96" stroke="black" stroke-width="0.5" />
    <path d="M598.02 114.02H598.13" stroke="black" stroke-width="0.5" />
    <path d="M598.18 114.02H598.29" stroke="black" stroke-width="0.5" />
    <path d="M598.35 114.02H598.46" stroke="black" stroke-width="0.5" />
    <path d="M598.51 114.02H598.62" stroke="black" stroke-width="0.5" />
    <path d="M598.68 114.02H598.79" stroke="black" stroke-width="0.5" />
    <path d="M598.84 114.02H598.95" stroke="black" stroke-width="0.5" />
    <path d="M599.01 114.02H599.12" stroke="black" stroke-width="0.5" />
    <path d="M599.17 114.02H599.28" stroke="black" stroke-width="0.5" />
    <path d="M599.34 114.02H599.45" stroke="black" stroke-width="0.5" />
    <path d="M599.5 114.02H599.61" stroke="black" stroke-width="0.5" />
    <path d="M599.67 114.02H599.77" stroke="black" stroke-width="0.5" />
    <path d="M599.83 114.02H599.94" stroke="black" stroke-width="0.5" />
    <path d="M599.99 114.02H600.1" stroke="black" stroke-width="0.5" />
    <path d="M600.16 114.02H600.27" stroke="black" stroke-width="0.5" />
    <path d="M600.32 114.02H600.43" stroke="black" stroke-width="0.5" />
    <path d="M600.49 114.02H600.6" stroke="black" stroke-width="0.5" />
    <path d="M600.65 114.02H600.76" stroke="black" stroke-width="0.5" />
    <path d="M600.82 114.02H600.93" stroke="black" stroke-width="0.5" />
    <path d="M600.98 114.02H601.09" stroke="black" stroke-width="0.5" />
    <path d="M601.15 114.02H601.26" stroke="black" stroke-width="0.5" />
    <path d="M601.31 114.02H601.42" stroke="black" stroke-width="0.5" />
    <path d="M601.48 114.02H601.59" stroke="black" stroke-width="0.5" />
    <path d="M601.64 114.02H601.75" stroke="black" stroke-width="0.5" />
    <path d="M601.81 114.02H601.92" stroke="black" stroke-width="0.5" />
    <path d="M601.97 114.02H602.08" stroke="black" stroke-width="0.5" />
    <path d="M602.14 114.02H602.25" stroke="black" stroke-width="0.5" />
    <path d="M602.3 114.02H602.41" stroke="black" stroke-width="0.5" />
    <path d="M602.47 114.02H602.52" stroke="black" stroke-width="0.5" />
    <path d="M602.52 135.13V135.08" stroke="black" stroke-width="0.5" />
    <path d="M602.52 135.02V134.91" stroke="black" stroke-width="0.5" />
    <path d="M602.52 134.86V134.75" stroke="black" stroke-width="0.5" />
    <path d="M602.52 134.69V134.58" stroke="black" stroke-width="0.5" />
    <path d="M602.52 134.53V134.42" stroke="black" stroke-width="0.5" />
    <path d="M602.52 134.36V134.25" stroke="black" stroke-width="0.5" />
    <path d="M602.52 134.2V134.09" stroke="black" stroke-width="0.5" />
    <path d="M602.52 134.03V133.92" stroke="black" stroke-width="0.5" />
    <path d="M602.52 133.87V133.76" stroke="black" stroke-width="0.5" />
    <path d="M602.52 133.7V133.59" stroke="black" stroke-width="0.5" />
    <path d="M602.52 133.54V133.43" stroke="black" stroke-width="0.5" />
    <path d="M602.52 133.37V133.26" stroke="black" stroke-width="0.5" />
    <path d="M602.52 133.21V133.1" stroke="black" stroke-width="0.5" />
    <path d="M602.52 133.04V132.93" stroke="black" stroke-width="0.5" />
    <path d="M602.52 132.88V132.77" stroke="black" stroke-width="0.5" />
    <path d="M602.52 132.71V132.6" stroke="black" stroke-width="0.5" />
    <path d="M602.52 132.55V132.44" stroke="black" stroke-width="0.5" />
    <path d="M602.52 132.38V132.27" stroke="black" stroke-width="0.5" />
    <path d="M602.52 132.22V132.11" stroke="black" stroke-width="0.5" />
    <path d="M602.52 132.05V131.94" stroke="black" stroke-width="0.5" />
    <path d="M602.52 131.89V131.78" stroke="black" stroke-width="0.5" />
    <path d="M602.52 131.72V131.61" stroke="black" stroke-width="0.5" />
    <path d="M602.52 131.56V131.45" stroke="black" stroke-width="0.5" />
    <path d="M602.52 131.39V131.28" stroke="black" stroke-width="0.5" />
    <path d="M602.52 131.23V131.12" stroke="black" stroke-width="0.5" />
    <path d="M602.52 131.06V130.95" stroke="black" stroke-width="0.5" />
    <path d="M602.52 130.9V130.79" stroke="black" stroke-width="0.5" />
    <path d="M602.52 130.73V130.62" stroke="black" stroke-width="0.5" />
    <path d="M602.52 130.57V130.46" stroke="black" stroke-width="0.5" />
    <path d="M602.52 130.4V130.29" stroke="black" stroke-width="0.5" />
    <path d="M602.52 130.24V130.13" stroke="black" stroke-width="0.5" />
    <path d="M602.52 130.07V129.96" stroke="black" stroke-width="0.5" />
    <path d="M602.52 129.91V129.8" stroke="black" stroke-width="0.5" />
    <path d="M602.52 129.74V129.63" stroke="black" stroke-width="0.5" />
    <path d="M602.52 129.58V129.47" stroke="black" stroke-width="0.5" />
    <path d="M602.52 129.41V129.3" stroke="black" stroke-width="0.5" />
    <path d="M602.52 129.25V129.14" stroke="black" stroke-width="0.5" />
    <path d="M602.52 129.08V128.97" stroke="black" stroke-width="0.5" />
    <path d="M602.52 128.92V128.81" stroke="black" stroke-width="0.5" />
    <path d="M602.52 128.75V128.64" stroke="black" stroke-width="0.5" />
    <path d="M602.52 128.59V128.48" stroke="black" stroke-width="0.5" />
    <path d="M602.52 128.42V128.31" stroke="black" stroke-width="0.5" />
    <path d="M602.52 128.26V128.15" stroke="black" stroke-width="0.5" />
    <path d="M602.52 128.09V127.98" stroke="black" stroke-width="0.5" />
    <path d="M602.52 127.93V127.82" stroke="black" stroke-width="0.5" />
    <path d="M602.52 127.77V127.66" stroke="black" stroke-width="0.5" />
    <path d="M602.52 127.6V127.49" stroke="black" stroke-width="0.5" />
    <path d="M602.52 127.44V127.33" stroke="black" stroke-width="0.5" />
    <path d="M602.52 127.27V127.16" stroke="black" stroke-width="0.5" />
    <path d="M602.52 127.11V127" stroke="black" stroke-width="0.5" />
    <path d="M602.52 126.94V126.83" stroke="black" stroke-width="0.5" />
    <path d="M602.52 126.78V126.67" stroke="black" stroke-width="0.5" />
    <path d="M602.52 126.61V126.5" stroke="black" stroke-width="0.5" />
    <path d="M602.52 126.45V126.34" stroke="black" stroke-width="0.5" />
    <path d="M602.52 126.28V126.17" stroke="black" stroke-width="0.5" />
    <path d="M602.52 126.12V126.01" stroke="black" stroke-width="0.5" />
    <path d="M602.52 125.95V125.84" stroke="black" stroke-width="0.5" />
    <path d="M602.52 125.79V125.68" stroke="black" stroke-width="0.5" />
    <path d="M602.52 125.62V125.51" stroke="black" stroke-width="0.5" />
    <path d="M602.52 125.46V125.35" stroke="black" stroke-width="0.5" />
    <path d="M602.52 125.29V125.18" stroke="black" stroke-width="0.5" />
    <path d="M602.52 125.13V125.02" stroke="black" stroke-width="0.5" />
    <path d="M602.52 124.96V124.85" stroke="black" stroke-width="0.5" />
    <path d="M602.52 124.8V124.69" stroke="black" stroke-width="0.5" />
    <path d="M602.52 124.63V124.52" stroke="black" stroke-width="0.5" />
    <path d="M602.52 124.47V124.36" stroke="black" stroke-width="0.5" />
    <path d="M602.52 124.3V124.19" stroke="black" stroke-width="0.5" />
    <path d="M602.52 124.14V124.03" stroke="black" stroke-width="0.5" />
    <path d="M602.52 123.97V123.86" stroke="black" stroke-width="0.5" />
    <path d="M602.52 123.81V123.7" stroke="black" stroke-width="0.5" />
    <path d="M602.52 123.64V123.53" stroke="black" stroke-width="0.5" />
    <path d="M602.52 123.48V123.37" stroke="black" stroke-width="0.5" />
    <path d="M602.52 123.31V123.2" stroke="black" stroke-width="0.5" />
    <path d="M602.52 123.15V123.04" stroke="black" stroke-width="0.5" />
    <path d="M602.52 122.98V122.87" stroke="black" stroke-width="0.5" />
    <path d="M602.52 122.82V122.71" stroke="black" stroke-width="0.5" />
    <path d="M602.52 122.65V122.54" stroke="black" stroke-width="0.5" />
    <path d="M602.52 122.49V122.38" stroke="black" stroke-width="0.5" />
    <path d="M602.52 122.32V122.21" stroke="black" stroke-width="0.5" />
    <path d="M602.52 122.16V122.05" stroke="black" stroke-width="0.5" />
    <path d="M602.52 121.99V121.88" stroke="black" stroke-width="0.5" />
    <path d="M602.52 121.83V121.72" stroke="black" stroke-width="0.5" />
    <path d="M602.52 121.66V121.55" stroke="black" stroke-width="0.5" />
    <path d="M602.52 121.5V121.39" stroke="black" stroke-width="0.5" />
    <path d="M602.52 121.33V121.22" stroke="black" stroke-width="0.5" />
    <path d="M602.52 121.17V121.06" stroke="black" stroke-width="0.5" />
    <path d="M602.52 121V120.89" stroke="black" stroke-width="0.5" />
    <path d="M602.52 120.84V120.73" stroke="black" stroke-width="0.5" />
    <path d="M602.52 120.67V120.56" stroke="black" stroke-width="0.5" />
    <path d="M602.52 120.51V120.4" stroke="black" stroke-width="0.5" />
    <path d="M602.52 120.34V120.23" stroke="black" stroke-width="0.5" />
    <path d="M602.52 120.18V120.07" stroke="black" stroke-width="0.5" />
    <path d="M602.52 120.01V119.9" stroke="black" stroke-width="0.5" />
    <path d="M602.52 119.85V119.74" stroke="black" stroke-width="0.5" />
    <path d="M602.52 119.68V119.57" stroke="black" stroke-width="0.5" />
    <path d="M602.52 119.52V119.41" stroke="black" stroke-width="0.5" />
    <path d="M602.52 119.35V119.24" stroke="black" stroke-width="0.5" />
    <path d="M602.52 119.19V119.08" stroke="black" stroke-width="0.5" />
    <path d="M602.52 119.02V118.91" stroke="black" stroke-width="0.5" />
    <path d="M602.52 118.86V118.75" stroke="black" stroke-width="0.5" />
    <path d="M602.52 118.69V118.58" stroke="black" stroke-width="0.5" />
    <path d="M602.52 118.53V118.42" stroke="black" stroke-width="0.5" />
    <path d="M602.52 118.36V118.25" stroke="black" stroke-width="0.5" />
    <path d="M602.52 118.2V118.09" stroke="black" stroke-width="0.5" />
    <path d="M602.52 118.03V117.92" stroke="black" stroke-width="0.5" />
    <path d="M602.52 117.87V117.76" stroke="black" stroke-width="0.5" />
    <path d="M602.52 117.7V117.59" stroke="black" stroke-width="0.5" />
    <path d="M602.52 117.54V117.43" stroke="black" stroke-width="0.5" />
    <path d="M602.52 117.37V117.26" stroke="black" stroke-width="0.5" />
    <path d="M602.52 117.21V117.1" stroke="black" stroke-width="0.5" />
    <path d="M602.52 117.04V116.93" stroke="black" stroke-width="0.5" />
    <path d="M602.52 116.88V116.77" stroke="black" stroke-width="0.5" />
    <path d="M602.52 116.71V116.6" stroke="black" stroke-width="0.5" />
    <path d="M602.52 116.55V116.44" stroke="black" stroke-width="0.5" />
    <path d="M602.52 116.38V116.27" stroke="black" stroke-width="0.5" />
    <path d="M602.52 116.22V116.11" stroke="black" stroke-width="0.5" />
    <path d="M602.52 116.05V115.94" stroke="black" stroke-width="0.5" />
    <path d="M602.52 115.89V115.78" stroke="black" stroke-width="0.5" />
    <path d="M602.52 115.72V115.61" stroke="black" stroke-width="0.5" />
    <path d="M602.52 115.56V115.45" stroke="black" stroke-width="0.5" />
    <path d="M602.52 115.39V115.28" stroke="black" stroke-width="0.5" />
    <path d="M602.52 115.23V115.12" stroke="black" stroke-width="0.5" />
    <path d="M602.52 115.06V114.95" stroke="black" stroke-width="0.5" />
    <path d="M602.52 114.9V114.79" stroke="black" stroke-width="0.5" />
    <path d="M602.52 114.74V114.63" stroke="black" stroke-width="0.5" />
    <path d="M602.52 114.57V114.46" stroke="black" stroke-width="0.5" />
    <path d="M602.52 114.41V114.3" stroke="black" stroke-width="0.5" />
    <path d="M602.52 114.24V114.13" stroke="black" stroke-width="0.5" />
    <path d="M602.52 114.08V114.02" stroke="black" stroke-width="0.5" />
    <path d="M581.41 135.13H581.47" stroke="black" stroke-width="0.5" />
    <path d="M581.52 135.13H581.63" stroke="black" stroke-width="0.5" />
    <path d="M581.69 135.13H581.8" stroke="black" stroke-width="0.5" />
    <path d="M581.85 135.13H581.96" stroke="black" stroke-width="0.5" />
    <path d="M582.02 135.13H582.13" stroke="black" stroke-width="0.5" />
    <path d="M582.18 135.13H582.29" stroke="black" stroke-width="0.5" />
    <path d="M582.35 135.13H582.46" stroke="black" stroke-width="0.5" />
    <path d="M582.51 135.13H582.62" stroke="black" stroke-width="0.5" />
    <path d="M582.68 135.13H582.79" stroke="black" stroke-width="0.5" />
    <path d="M582.84 135.13H582.95" stroke="black" stroke-width="0.5" />
    <path d="M583.01 135.13H583.12" stroke="black" stroke-width="0.5" />
    <path d="M583.17 135.13H583.28" stroke="black" stroke-width="0.5" />
    <path d="M583.34 135.13H583.45" stroke="black" stroke-width="0.5" />
    <path d="M583.5 135.13H583.61" stroke="black" stroke-width="0.5" />
    <path d="M583.67 135.13H583.78" stroke="black" stroke-width="0.5" />
    <path d="M583.83 135.13H583.94" stroke="black" stroke-width="0.5" />
    <path d="M584 135.13H584.11" stroke="black" stroke-width="0.5" />
    <path d="M584.16 135.13H584.27" stroke="black" stroke-width="0.5" />
    <path d="M584.33 135.13H584.44" stroke="black" stroke-width="0.5" />
    <path d="M584.49 135.13H584.6" stroke="black" stroke-width="0.5" />
    <path d="M584.66 135.13H584.77" stroke="black" stroke-width="0.5" />
    <path d="M584.82 135.13H584.93" stroke="black" stroke-width="0.5" />
    <path d="M584.99 135.13H585.1" stroke="black" stroke-width="0.5" />
    <path d="M585.15 135.13H585.26" stroke="black" stroke-width="0.5" />
    <path d="M585.32 135.13H585.43" stroke="black" stroke-width="0.5" />
    <path d="M585.48 135.13H585.59" stroke="black" stroke-width="0.5" />
    <path d="M585.65 135.13H585.76" stroke="black" stroke-width="0.5" />
    <path d="M585.81 135.13H585.92" stroke="black" stroke-width="0.5" />
    <path d="M585.98 135.13H586.09" stroke="black" stroke-width="0.5" />
    <path d="M586.14 135.13H586.25" stroke="black" stroke-width="0.5" />
    <path d="M586.31 135.13H586.42" stroke="black" stroke-width="0.5" />
    <path d="M586.47 135.13H586.58" stroke="black" stroke-width="0.5" />
    <path d="M586.64 135.13H586.75" stroke="black" stroke-width="0.5" />
    <path d="M586.8 135.13H586.91" stroke="black" stroke-width="0.5" />
    <path d="M586.96 135.13H587.07" stroke="black" stroke-width="0.5" />
    <path d="M587.13 135.13H587.24" stroke="black" stroke-width="0.5" />
    <path d="M587.29 135.13H587.4" stroke="black" stroke-width="0.5" />
    <path d="M587.46 135.13H587.57" stroke="black" stroke-width="0.5" />
    <path d="M587.62 135.13H587.73" stroke="black" stroke-width="0.5" />
    <path d="M587.79 135.13H587.9" stroke="black" stroke-width="0.5" />
    <path d="M587.95 135.13H588.06" stroke="black" stroke-width="0.5" />
    <path d="M588.12 135.13H588.23" stroke="black" stroke-width="0.5" />
    <path d="M588.28 135.13H588.39" stroke="black" stroke-width="0.5" />
    <path d="M588.45 135.13H588.56" stroke="black" stroke-width="0.5" />
    <path d="M588.61 135.13H588.72" stroke="black" stroke-width="0.5" />
    <path d="M588.78 135.13H588.89" stroke="black" stroke-width="0.5" />
    <path d="M588.94 135.13H589.05" stroke="black" stroke-width="0.5" />
    <path d="M589.11 135.13H589.22" stroke="black" stroke-width="0.5" />
    <path d="M589.27 135.13H589.38" stroke="black" stroke-width="0.5" />
    <path d="M589.44 135.13H589.55" stroke="black" stroke-width="0.5" />
    <path d="M589.6 135.13H589.71" stroke="black" stroke-width="0.5" />
    <path d="M589.77 135.13H589.88" stroke="black" stroke-width="0.5" />
    <path d="M589.93 135.13H590.04" stroke="black" stroke-width="0.5" />
    <path d="M590.1 135.13H590.21" stroke="black" stroke-width="0.5" />
    <path d="M590.26 135.13H590.37" stroke="black" stroke-width="0.5" />
    <path d="M590.43 135.13H590.54" stroke="black" stroke-width="0.5" />
    <path d="M590.59 135.13H590.7" stroke="black" stroke-width="0.5" />
    <path d="M590.76 135.13H590.87" stroke="black" stroke-width="0.5" />
    <path d="M590.92 135.13H591.03" stroke="black" stroke-width="0.5" />
    <path d="M591.09 135.13H591.2" stroke="black" stroke-width="0.5" />
    <path d="M591.25 135.13H591.36" stroke="black" stroke-width="0.5" />
    <path d="M591.42 135.13H591.53" stroke="black" stroke-width="0.5" />
    <path d="M591.58 135.13H591.69" stroke="black" stroke-width="0.5" />
    <path d="M591.75 135.13H591.86" stroke="black" stroke-width="0.5" />
    <path d="M591.91 135.13H592.02" stroke="black" stroke-width="0.5" />
    <path d="M592.08 135.13H592.19" stroke="black" stroke-width="0.5" />
    <path d="M592.24 135.13H592.35" stroke="black" stroke-width="0.5" />
    <path d="M592.41 135.13H592.52" stroke="black" stroke-width="0.5" />
    <path d="M592.57 135.13H592.68" stroke="black" stroke-width="0.5" />
    <path d="M592.74 135.13H592.85" stroke="black" stroke-width="0.5" />
    <path d="M592.9 135.13H593.01" stroke="black" stroke-width="0.5" />
    <path d="M593.07 135.13H593.18" stroke="black" stroke-width="0.5" />
    <path d="M593.23 135.13H593.34" stroke="black" stroke-width="0.5" />
    <path d="M593.4 135.13H593.51" stroke="black" stroke-width="0.5" />
    <path d="M593.56 135.13H593.67" stroke="black" stroke-width="0.5" />
    <path d="M593.73 135.13H593.84" stroke="black" stroke-width="0.5" />
    <path d="M593.89 135.13H594" stroke="black" stroke-width="0.5" />
    <path d="M594.06 135.13H594.17" stroke="black" stroke-width="0.5" />
    <path d="M594.22 135.13H594.33" stroke="black" stroke-width="0.5" />
    <path d="M594.39 135.13H594.5" stroke="black" stroke-width="0.5" />
    <path d="M594.55 135.13H594.66" stroke="black" stroke-width="0.5" />
    <path d="M594.72 135.13H594.83" stroke="black" stroke-width="0.5" />
    <path d="M594.88 135.13H594.99" stroke="black" stroke-width="0.5" />
    <path d="M595.05 135.13H595.16" stroke="black" stroke-width="0.5" />
    <path d="M595.21 135.13H595.32" stroke="black" stroke-width="0.5" />
    <path d="M595.38 135.13H595.49" stroke="black" stroke-width="0.5" />
    <path d="M595.54 135.13H595.65" stroke="black" stroke-width="0.5" />
    <path d="M595.71 135.13H595.82" stroke="black" stroke-width="0.5" />
    <path d="M595.87 135.13H595.98" stroke="black" stroke-width="0.5" />
    <path d="M596.04 135.13H596.15" stroke="black" stroke-width="0.5" />
    <path d="M596.2 135.13H596.31" stroke="black" stroke-width="0.5" />
    <path d="M596.37 135.13H596.48" stroke="black" stroke-width="0.5" />
    <path d="M596.53 135.13H596.64" stroke="black" stroke-width="0.5" />
    <path d="M596.7 135.13H596.81" stroke="black" stroke-width="0.5" />
    <path d="M596.86 135.13H596.97" stroke="black" stroke-width="0.5" />
    <path d="M597.03 135.13H597.14" stroke="black" stroke-width="0.5" />
    <path d="M597.19 135.13H597.3" stroke="black" stroke-width="0.5" />
    <path d="M597.36 135.13H597.47" stroke="black" stroke-width="0.5" />
    <path d="M597.52 135.13H597.63" stroke="black" stroke-width="0.5" />
    <path d="M597.69 135.13H597.8" stroke="black" stroke-width="0.5" />
    <path d="M597.85 135.13H597.96" stroke="black" stroke-width="0.5" />
    <path d="M598.02 135.13H598.13" stroke="black" stroke-width="0.5" />
    <path d="M598.18 135.13H598.29" stroke="black" stroke-width="0.5" />
    <path d="M598.35 135.13H598.46" stroke="black" stroke-width="0.5" />
    <path d="M598.51 135.13H598.62" stroke="black" stroke-width="0.5" />
    <path d="M598.68 135.13H598.79" stroke="black" stroke-width="0.5" />
    <path d="M598.84 135.13H598.95" stroke="black" stroke-width="0.5" />
    <path d="M599.01 135.13H599.12" stroke="black" stroke-width="0.5" />
    <path d="M599.17 135.13H599.28" stroke="black" stroke-width="0.5" />
    <path d="M599.34 135.13H599.45" stroke="black" stroke-width="0.5" />
    <path d="M599.5 135.13H599.61" stroke="black" stroke-width="0.5" />
    <path d="M599.67 135.13H599.77" stroke="black" stroke-width="0.5" />
    <path d="M599.83 135.13H599.94" stroke="black" stroke-width="0.5" />
    <path d="M599.99 135.13H600.1" stroke="black" stroke-width="0.5" />
    <path d="M600.16 135.13H600.27" stroke="black" stroke-width="0.5" />
    <path d="M600.32 135.13H600.43" stroke="black" stroke-width="0.5" />
    <path d="M600.49 135.13H600.6" stroke="black" stroke-width="0.5" />
    <path d="M600.65 135.13H600.76" stroke="black" stroke-width="0.5" />
    <path d="M600.82 135.13H600.93" stroke="black" stroke-width="0.5" />
    <path d="M600.98 135.13H601.09" stroke="black" stroke-width="0.5" />
    <path d="M601.15 135.13H601.26" stroke="black" stroke-width="0.5" />
    <path d="M601.31 135.13H601.42" stroke="black" stroke-width="0.5" />
    <path d="M601.48 135.13H601.59" stroke="black" stroke-width="0.5" />
    <path d="M601.64 135.13H601.75" stroke="black" stroke-width="0.5" />
    <path d="M601.81 135.13H601.92" stroke="black" stroke-width="0.5" />
    <path d="M601.97 135.13H602.08" stroke="black" stroke-width="0.5" />
    <path d="M602.14 135.13H602.25" stroke="black" stroke-width="0.5" />
    <path d="M602.3 135.13H602.41" stroke="black" stroke-width="0.5" />
    <path d="M602.47 135.13H602.52" stroke="black" stroke-width="0.5" />
    <path d="M167.31 420.58V420.64" stroke="black" stroke-width="0.5" />
    <path d="M167.31 420.69V420.8" stroke="black" stroke-width="0.5" />
    <path d="M167.31 420.86V420.97" stroke="black" stroke-width="0.5" />
    <path d="M167.31 421.02V421.13" stroke="black" stroke-width="0.5" />
    <path d="M167.31 421.19V421.3" stroke="black" stroke-width="0.5" />
    <path d="M167.31 421.35V421.46" stroke="black" stroke-width="0.5" />
    <path d="M167.31 421.52V421.63" stroke="black" stroke-width="0.5" />
    <path d="M167.31 421.68V421.79" stroke="black" stroke-width="0.5" />
    <path d="M167.31 421.85V421.96" stroke="black" stroke-width="0.5" />
    <path d="M167.31 422.01V422.12" stroke="black" stroke-width="0.5" />
    <path d="M167.31 422.18V422.29" stroke="black" stroke-width="0.5" />
    <path d="M167.31 422.34V422.45" stroke="black" stroke-width="0.5" />
    <path d="M167.31 422.51V422.62" stroke="black" stroke-width="0.5" />
    <path d="M167.31 422.67V422.78" stroke="black" stroke-width="0.5" />
    <path d="M167.31 422.84V422.95" stroke="black" stroke-width="0.5" />
    <path d="M167.31 423V423.11" stroke="black" stroke-width="0.5" />
    <path d="M167.31 423.17V423.28" stroke="black" stroke-width="0.5" />
    <path d="M167.31 423.33V423.44" stroke="black" stroke-width="0.5" />
    <path d="M167.31 423.5V423.61" stroke="black" stroke-width="0.5" />
    <path d="M167.31 423.66V423.77" stroke="black" stroke-width="0.5" />
    <path d="M167.31 423.83V423.94" stroke="black" stroke-width="0.5" />
    <path d="M167.31 423.99V424.1" stroke="black" stroke-width="0.5" />
    <path d="M167.31 424.16V424.27" stroke="black" stroke-width="0.5" />
    <path d="M167.31 424.32V424.43" stroke="black" stroke-width="0.5" />
    <path d="M167.31 424.49V424.6" stroke="black" stroke-width="0.5" />
    <path d="M167.31 424.65V424.76" stroke="black" stroke-width="0.5" />
    <path d="M167.31 424.82V424.93" stroke="black" stroke-width="0.5" />
    <path d="M167.31 424.98V425.09" stroke="black" stroke-width="0.5" />
    <path d="M167.31 425.14V425.25" stroke="black" stroke-width="0.5" />
    <path d="M167.31 425.31V425.42" stroke="black" stroke-width="0.5" />
    <path d="M167.31 425.47V425.58" stroke="black" stroke-width="0.5" />
    <path d="M167.31 425.64V425.75" stroke="black" stroke-width="0.5" />
    <path d="M167.31 425.8V425.91" stroke="black" stroke-width="0.5" />
    <path d="M167.31 425.97V426.08" stroke="black" stroke-width="0.5" />
    <path d="M167.31 426.13V426.24" stroke="black" stroke-width="0.5" />
    <path d="M167.31 426.3V426.41" stroke="black" stroke-width="0.5" />
    <path d="M167.31 426.46V426.57" stroke="black" stroke-width="0.5" />
    <path d="M167.31 426.63V426.74" stroke="black" stroke-width="0.5" />
    <path d="M167.31 426.79V426.9" stroke="black" stroke-width="0.5" />
    <path d="M167.31 426.96V427.07" stroke="black" stroke-width="0.5" />
    <path d="M167.31 427.12V427.23" stroke="black" stroke-width="0.5" />
    <path d="M167.31 427.29V427.4" stroke="black" stroke-width="0.5" />
    <path d="M167.31 427.45V427.56" stroke="black" stroke-width="0.5" />
    <path d="M167.31 427.62V427.73" stroke="black" stroke-width="0.5" />
    <path d="M167.31 427.78V427.89" stroke="black" stroke-width="0.5" />
    <path d="M167.31 427.95V428.06" stroke="black" stroke-width="0.5" />
    <path d="M167.31 428.11V428.22" stroke="black" stroke-width="0.5" />
    <path d="M167.31 428.28V428.39" stroke="black" stroke-width="0.5" />
    <path d="M167.31 428.44V428.55" stroke="black" stroke-width="0.5" />
    <path d="M167.31 428.61V428.72" stroke="black" stroke-width="0.5" />
    <path d="M167.31 428.77V428.88" stroke="black" stroke-width="0.5" />
    <path d="M167.31 428.94V429.05" stroke="black" stroke-width="0.5" />
    <path d="M167.31 429.1V429.21" stroke="black" stroke-width="0.5" />
    <path d="M167.31 429.27V429.38" stroke="black" stroke-width="0.5" />
    <path d="M167.31 429.43V429.54" stroke="black" stroke-width="0.5" />
    <path d="M167.31 429.6V429.71" stroke="black" stroke-width="0.5" />
    <path d="M167.31 429.76V429.87" stroke="black" stroke-width="0.5" />
    <path d="M167.31 429.93V430.04" stroke="black" stroke-width="0.5" />
    <path d="M167.31 430.09V430.2" stroke="black" stroke-width="0.5" />
    <path d="M167.31 430.26V430.37" stroke="black" stroke-width="0.5" />
    <path d="M167.31 430.42V430.53" stroke="black" stroke-width="0.5" />
    <path d="M167.31 430.59V430.7" stroke="black" stroke-width="0.5" />
    <path d="M167.31 430.75V430.86" stroke="black" stroke-width="0.5" />
    <path d="M167.31 430.92V431.03" stroke="black" stroke-width="0.5" />
    <path d="M167.31 431.08V431.19" stroke="black" stroke-width="0.5" />
    <path d="M167.31 431.25V431.36" stroke="black" stroke-width="0.5" />
    <path d="M167.31 431.41V431.52" stroke="black" stroke-width="0.5" />
    <path d="M167.31 431.58V431.69" stroke="black" stroke-width="0.5" />
    <path d="M167.31 431.74V431.85" stroke="black" stroke-width="0.5" />
    <path d="M167.31 431.91V432.02" stroke="black" stroke-width="0.5" />
    <path d="M167.31 432.07V432.18" stroke="black" stroke-width="0.5" />
    <path d="M167.31 432.24V432.35" stroke="black" stroke-width="0.5" />
    <path d="M167.31 432.4V432.51" stroke="black" stroke-width="0.5" />
    <path d="M167.31 432.57V432.68" stroke="black" stroke-width="0.5" />
    <path d="M167.31 432.73V432.84" stroke="black" stroke-width="0.5" />
    <path d="M167.31 432.9V433.01" stroke="black" stroke-width="0.5" />
    <path d="M167.31 433.06V433.17" stroke="black" stroke-width="0.5" />
    <path d="M167.31 433.23V433.34" stroke="black" stroke-width="0.5" />
    <path d="M167.31 433.39V433.5" stroke="black" stroke-width="0.5" />
    <path d="M167.31 433.56V433.67" stroke="black" stroke-width="0.5" />
    <path d="M167.31 433.72V433.83" stroke="black" stroke-width="0.5" />
    <path d="M167.31 433.89V434" stroke="black" stroke-width="0.5" />
    <path d="M167.31 434.05V434.16" stroke="black" stroke-width="0.5" />
    <path d="M167.31 434.22V434.33" stroke="black" stroke-width="0.5" />
    <path d="M167.31 434.38V434.49" stroke="black" stroke-width="0.5" />
    <path d="M167.31 434.55V434.66" stroke="black" stroke-width="0.5" />
    <path d="M167.31 434.71V434.82" stroke="black" stroke-width="0.5" />
    <path d="M167.31 434.88V434.99" stroke="black" stroke-width="0.5" />
    <path d="M167.31 435.04V435.15" stroke="black" stroke-width="0.5" />
    <path d="M167.31 435.21V435.32" stroke="black" stroke-width="0.5" />
    <path d="M167.31 435.37V435.48" stroke="black" stroke-width="0.5" />
    <path d="M167.31 435.54V435.65" stroke="black" stroke-width="0.5" />
    <path d="M167.31 435.7V435.81" stroke="black" stroke-width="0.5" />
    <path d="M167.31 435.87V435.98" stroke="black" stroke-width="0.5" />
    <path d="M167.31 436.03V436.14" stroke="black" stroke-width="0.5" />
    <path d="M167.31 436.2V436.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 436.36V436.47" stroke="black" stroke-width="0.5" />
    <path d="M167.31 436.53V436.64" stroke="black" stroke-width="0.5" />
    <path d="M167.31 436.69V436.8" stroke="black" stroke-width="0.5" />
    <path d="M167.31 436.86V436.97" stroke="black" stroke-width="0.5" />
    <path d="M167.31 437.02V437.13" stroke="black" stroke-width="0.5" />
    <path d="M167.31 437.19V437.3" stroke="black" stroke-width="0.5" />
    <path d="M167.31 437.35V437.46" stroke="black" stroke-width="0.5" />
    <path d="M167.31 437.52V437.63" stroke="black" stroke-width="0.5" />
    <path d="M167.31 437.68V437.79" stroke="black" stroke-width="0.5" />
    <path d="M167.31 437.85V437.96" stroke="black" stroke-width="0.5" />
    <path d="M167.31 438.01V438.12" stroke="black" stroke-width="0.5" />
    <path d="M167.31 438.17V438.28" stroke="black" stroke-width="0.5" />
    <path d="M167.31 438.34V438.45" stroke="black" stroke-width="0.5" />
    <path d="M167.31 438.5V438.61" stroke="black" stroke-width="0.5" />
    <path d="M167.31 438.67V438.78" stroke="black" stroke-width="0.5" />
    <path d="M167.31 438.83V438.94" stroke="black" stroke-width="0.5" />
    <path d="M167.31 439V439.11" stroke="black" stroke-width="0.5" />
    <path d="M167.31 439.16V439.27" stroke="black" stroke-width="0.5" />
    <path d="M167.31 439.33V439.44" stroke="black" stroke-width="0.5" />
    <path d="M167.31 439.49V439.6" stroke="black" stroke-width="0.5" />
    <path d="M167.31 439.66V439.77" stroke="black" stroke-width="0.5" />
    <path d="M167.31 439.82V439.93" stroke="black" stroke-width="0.5" />
    <path d="M167.31 439.99V440.1" stroke="black" stroke-width="0.5" />
    <path d="M167.31 440.15V440.26" stroke="black" stroke-width="0.5" />
    <path d="M167.31 440.32V440.43" stroke="black" stroke-width="0.5" />
    <path d="M167.31 440.48V440.59" stroke="black" stroke-width="0.5" />
    <path d="M167.31 440.65V440.76" stroke="black" stroke-width="0.5" />
    <path d="M167.31 440.81V440.92" stroke="black" stroke-width="0.5" />
    <path d="M167.31 440.98V441.09" stroke="black" stroke-width="0.5" />
    <path d="M167.31 441.14V441.25" stroke="black" stroke-width="0.5" />
    <path d="M167.31 441.31V441.42" stroke="black" stroke-width="0.5" />
    <path d="M167.31 441.47V441.58" stroke="black" stroke-width="0.5" />
    <path d="M167.31 441.64V441.69" stroke="black" stroke-width="0.5" />
    <path d="M151.48 420.58H151.53" stroke="black" stroke-width="0.5" />
    <path d="M151.59 420.58H151.7" stroke="black" stroke-width="0.5" />
    <path d="M151.75 420.58H151.86" stroke="black" stroke-width="0.5" />
    <path d="M151.92 420.58H152.03" stroke="black" stroke-width="0.5" />
    <path d="M152.08 420.58H152.19" stroke="black" stroke-width="0.5" />
    <path d="M152.25 420.58H152.36" stroke="black" stroke-width="0.5" />
    <path d="M152.41 420.58H152.52" stroke="black" stroke-width="0.5" />
    <path d="M152.58 420.58H152.69" stroke="black" stroke-width="0.5" />
    <path d="M152.74 420.58H152.85" stroke="black" stroke-width="0.5" />
    <path d="M152.91 420.58H153.02" stroke="black" stroke-width="0.5" />
    <path d="M153.07 420.58H153.18" stroke="black" stroke-width="0.5" />
    <path d="M153.24 420.58H153.35" stroke="black" stroke-width="0.5" />
    <path d="M153.4 420.58H153.51" stroke="black" stroke-width="0.5" />
    <path d="M153.57 420.58H153.68" stroke="black" stroke-width="0.5" />
    <path d="M153.73 420.58H153.84" stroke="black" stroke-width="0.5" />
    <path d="M153.9 420.58H154.01" stroke="black" stroke-width="0.5" />
    <path d="M154.06 420.58H154.17" stroke="black" stroke-width="0.5" />
    <path d="M154.23 420.58H154.34" stroke="black" stroke-width="0.5" />
    <path d="M154.39 420.58H154.5" stroke="black" stroke-width="0.5" />
    <path d="M154.56 420.58H154.67" stroke="black" stroke-width="0.5" />
    <path d="M154.72 420.58H154.83" stroke="black" stroke-width="0.5" />
    <path d="M154.89 420.58H155" stroke="black" stroke-width="0.5" />
    <path d="M155.05 420.58H155.16" stroke="black" stroke-width="0.5" />
    <path d="M155.22 420.58H155.33" stroke="black" stroke-width="0.5" />
    <path d="M155.38 420.58H155.49" stroke="black" stroke-width="0.5" />
    <path d="M155.55 420.58H155.66" stroke="black" stroke-width="0.5" />
    <path d="M155.71 420.58H155.82" stroke="black" stroke-width="0.5" />
    <path d="M155.88 420.58H155.99" stroke="black" stroke-width="0.5" />
    <path d="M156.04 420.58H156.15" stroke="black" stroke-width="0.5" />
    <path d="M156.21 420.58H156.32" stroke="black" stroke-width="0.5" />
    <path d="M156.37 420.58H156.48" stroke="black" stroke-width="0.5" />
    <path d="M156.54 420.58H156.65" stroke="black" stroke-width="0.5" />
    <path d="M156.7 420.58H156.81" stroke="black" stroke-width="0.5" />
    <path d="M156.87 420.58H156.98" stroke="black" stroke-width="0.5" />
    <path d="M157.03 420.58H157.14" stroke="black" stroke-width="0.5" />
    <path d="M157.2 420.58H157.31" stroke="black" stroke-width="0.5" />
    <path d="M157.36 420.58H157.47" stroke="black" stroke-width="0.5" />
    <path d="M157.53 420.58H157.64" stroke="black" stroke-width="0.5" />
    <path d="M157.69 420.58H157.8" stroke="black" stroke-width="0.5" />
    <path d="M157.86 420.58H157.97" stroke="black" stroke-width="0.5" />
    <path d="M158.02 420.58H158.13" stroke="black" stroke-width="0.5" />
    <path d="M158.19 420.58H158.3" stroke="black" stroke-width="0.5" />
    <path d="M158.35 420.58H158.46" stroke="black" stroke-width="0.5" />
    <path d="M158.52 420.58H158.63" stroke="black" stroke-width="0.5" />
    <path d="M158.68 420.58H158.79" stroke="black" stroke-width="0.5" />
    <path d="M158.85 420.58H158.96" stroke="black" stroke-width="0.5" />
    <path d="M159.01 420.58H159.12" stroke="black" stroke-width="0.5" />
    <path d="M159.18 420.58H159.29" stroke="black" stroke-width="0.5" />
    <path d="M159.34 420.58H159.45" stroke="black" stroke-width="0.5" />
    <path d="M159.51 420.58H159.62" stroke="black" stroke-width="0.5" />
    <path d="M159.67 420.58H159.78" stroke="black" stroke-width="0.5" />
    <path d="M159.84 420.58H159.95" stroke="black" stroke-width="0.5" />
    <path d="M160 420.58H160.11" stroke="black" stroke-width="0.5" />
    <path d="M160.17 420.58H160.27" stroke="black" stroke-width="0.5" />
    <path d="M160.33 420.58H160.44" stroke="black" stroke-width="0.5" />
    <path d="M160.49 420.58H160.6" stroke="black" stroke-width="0.5" />
    <path d="M160.66 420.58H160.77" stroke="black" stroke-width="0.5" />
    <path d="M160.82 420.58H160.93" stroke="black" stroke-width="0.5" />
    <path d="M160.99 420.58H161.1" stroke="black" stroke-width="0.5" />
    <path d="M161.15 420.58H161.26" stroke="black" stroke-width="0.5" />
    <path d="M161.32 420.58H161.43" stroke="black" stroke-width="0.5" />
    <path d="M161.48 420.58H161.59" stroke="black" stroke-width="0.5" />
    <path d="M161.65 420.58H161.76" stroke="black" stroke-width="0.5" />
    <path d="M161.81 420.58H161.92" stroke="black" stroke-width="0.5" />
    <path d="M161.98 420.58H162.09" stroke="black" stroke-width="0.5" />
    <path d="M162.14 420.58H162.25" stroke="black" stroke-width="0.5" />
    <path d="M162.31 420.58H162.42" stroke="black" stroke-width="0.5" />
    <path d="M162.47 420.58H162.58" stroke="black" stroke-width="0.5" />
    <path d="M162.64 420.58H162.75" stroke="black" stroke-width="0.5" />
    <path d="M162.8 420.58H162.91" stroke="black" stroke-width="0.5" />
    <path d="M162.97 420.58H163.08" stroke="black" stroke-width="0.5" />
    <path d="M163.13 420.58H163.24" stroke="black" stroke-width="0.5" />
    <path d="M163.3 420.58H163.41" stroke="black" stroke-width="0.5" />
    <path d="M163.46 420.58H163.57" stroke="black" stroke-width="0.5" />
    <path d="M163.63 420.58H163.74" stroke="black" stroke-width="0.5" />
    <path d="M163.79 420.58H163.9" stroke="black" stroke-width="0.5" />
    <path d="M163.96 420.58H164.07" stroke="black" stroke-width="0.5" />
    <path d="M164.12 420.58H164.23" stroke="black" stroke-width="0.5" />
    <path d="M164.29 420.58H164.4" stroke="black" stroke-width="0.5" />
    <path d="M164.45 420.58H164.56" stroke="black" stroke-width="0.5" />
    <path d="M164.62 420.58H164.73" stroke="black" stroke-width="0.5" />
    <path d="M164.78 420.58H164.89" stroke="black" stroke-width="0.5" />
    <path d="M164.95 420.58H165.06" stroke="black" stroke-width="0.5" />
    <path d="M165.11 420.58H165.22" stroke="black" stroke-width="0.5" />
    <path d="M165.28 420.58H165.39" stroke="black" stroke-width="0.5" />
    <path d="M165.44 420.58H165.55" stroke="black" stroke-width="0.5" />
    <path d="M165.61 420.58H165.72" stroke="black" stroke-width="0.5" />
    <path d="M165.77 420.58H165.88" stroke="black" stroke-width="0.5" />
    <path d="M165.94 420.58H166.05" stroke="black" stroke-width="0.5" />
    <path d="M166.1 420.58H166.21" stroke="black" stroke-width="0.5" />
    <path d="M166.27 420.58H166.38" stroke="black" stroke-width="0.5" />
    <path d="M166.43 420.58H166.54" stroke="black" stroke-width="0.5" />
    <path d="M166.6 420.58H166.71" stroke="black" stroke-width="0.5" />
    <path d="M166.76 420.58H166.87" stroke="black" stroke-width="0.5" />
    <path d="M166.93 420.58H167.04" stroke="black" stroke-width="0.5" />
    <path d="M167.09 420.58H167.2" stroke="black" stroke-width="0.5" />
    <path d="M167.26 420.58H167.31" stroke="black" stroke-width="0.5" />
    <path d="M602.52 413.24V413.29" stroke="black" stroke-width="0.5" />
    <path d="M602.52 413.35V413.46" stroke="black" stroke-width="0.5" />
    <path d="M602.52 413.51V413.62" stroke="black" stroke-width="0.5" />
    <path d="M602.52 413.68V413.79" stroke="black" stroke-width="0.5" />
    <path d="M602.52 413.84V413.95" stroke="black" stroke-width="0.5" />
    <path d="M602.52 414.01V414.12" stroke="black" stroke-width="0.5" />
    <path d="M602.52 414.17V414.28" stroke="black" stroke-width="0.5" />
    <path d="M602.52 414.34V414.45" stroke="black" stroke-width="0.5" />
    <path d="M602.52 414.5V414.61" stroke="black" stroke-width="0.5" />
    <path d="M602.52 414.67V414.78" stroke="black" stroke-width="0.5" />
    <path d="M602.52 414.83V414.94" stroke="black" stroke-width="0.5" />
    <path d="M602.52 415V415.11" stroke="black" stroke-width="0.5" />
    <path d="M602.52 415.16V415.27" stroke="black" stroke-width="0.5" />
    <path d="M602.52 415.33V415.44" stroke="black" stroke-width="0.5" />
    <path d="M602.52 415.49V415.6" stroke="black" stroke-width="0.5" />
    <path d="M602.52 415.66V415.77" stroke="black" stroke-width="0.5" />
    <path d="M602.52 415.82V415.93" stroke="black" stroke-width="0.5" />
    <path d="M602.52 415.99V416.1" stroke="black" stroke-width="0.5" />
    <path d="M602.52 416.15V416.26" stroke="black" stroke-width="0.5" />
    <path d="M602.52 416.32V416.43" stroke="black" stroke-width="0.5" />
    <path d="M602.52 416.48V416.59" stroke="black" stroke-width="0.5" />
    <path d="M602.52 416.65V416.76" stroke="black" stroke-width="0.5" />
    <path d="M602.52 416.81V416.92" stroke="black" stroke-width="0.5" />
    <path d="M602.52 416.98V417.09" stroke="black" stroke-width="0.5" />
    <path d="M602.52 417.14V417.25" stroke="black" stroke-width="0.5" />
    <path d="M602.52 417.31V417.42" stroke="black" stroke-width="0.5" />
    <path d="M602.52 417.47V417.58" stroke="black" stroke-width="0.5" />
    <path d="M602.52 417.64V417.75" stroke="black" stroke-width="0.5" />
    <path d="M602.52 417.8V417.91" stroke="black" stroke-width="0.5" />
    <path d="M602.52 417.97V418.08" stroke="black" stroke-width="0.5" />
    <path d="M602.52 418.13V418.24" stroke="black" stroke-width="0.5" />
    <path d="M602.52 418.3V418.41" stroke="black" stroke-width="0.5" />
    <path d="M602.52 418.46V418.57" stroke="black" stroke-width="0.5" />
    <path d="M602.52 418.63V418.74" stroke="black" stroke-width="0.5" />
    <path d="M602.52 418.79V418.9" stroke="black" stroke-width="0.5" />
    <path d="M602.52 418.96V419.07" stroke="black" stroke-width="0.5" />
    <path d="M602.52 419.12V419.23" stroke="black" stroke-width="0.5" />
    <path d="M602.52 419.28V419.39" stroke="black" stroke-width="0.5" />
    <path d="M602.52 419.45V419.56" stroke="black" stroke-width="0.5" />
    <path d="M602.52 419.61V419.72" stroke="black" stroke-width="0.5" />
    <path d="M602.52 419.78V419.89" stroke="black" stroke-width="0.5" />
    <path d="M602.52 419.94V420.05" stroke="black" stroke-width="0.5" />
    <path d="M602.52 420.11V420.22" stroke="black" stroke-width="0.5" />
    <path d="M602.52 420.27V420.38" stroke="black" stroke-width="0.5" />
    <path d="M602.52 420.44V420.55" stroke="black" stroke-width="0.5" />
    <path d="M602.52 420.6V420.71" stroke="black" stroke-width="0.5" />
    <path d="M602.52 420.77V420.88" stroke="black" stroke-width="0.5" />
    <path d="M602.52 420.93V421.04" stroke="black" stroke-width="0.5" />
    <path d="M602.52 421.1V421.21" stroke="black" stroke-width="0.5" />
    <path d="M602.52 421.26V421.37" stroke="black" stroke-width="0.5" />
    <path d="M602.52 421.43V421.54" stroke="black" stroke-width="0.5" />
    <path d="M602.52 421.59V421.7" stroke="black" stroke-width="0.5" />
    <path d="M602.52 421.76V421.87" stroke="black" stroke-width="0.5" />
    <path d="M602.52 421.92V422.03" stroke="black" stroke-width="0.5" />
    <path d="M602.52 422.09V422.2" stroke="black" stroke-width="0.5" />
    <path d="M602.52 422.25V422.36" stroke="black" stroke-width="0.5" />
    <path d="M602.52 422.42V422.53" stroke="black" stroke-width="0.5" />
    <path d="M602.52 422.58V422.69" stroke="black" stroke-width="0.5" />
    <path d="M602.52 422.75V422.86" stroke="black" stroke-width="0.5" />
    <path d="M602.52 422.91V423.02" stroke="black" stroke-width="0.5" />
    <path d="M602.52 423.08V423.19" stroke="black" stroke-width="0.5" />
    <path d="M602.52 423.24V423.35" stroke="black" stroke-width="0.5" />
    <path d="M602.52 423.41V423.52" stroke="black" stroke-width="0.5" />
    <path d="M602.52 423.57V423.68" stroke="black" stroke-width="0.5" />
    <path d="M602.52 423.74V423.85" stroke="black" stroke-width="0.5" />
    <path d="M602.52 423.9V424.01" stroke="black" stroke-width="0.5" />
    <path d="M602.52 424.07V424.18" stroke="black" stroke-width="0.5" />
    <path d="M602.52 424.23V424.34" stroke="black" stroke-width="0.5" />
    <path d="M602.52 424.4V424.51" stroke="black" stroke-width="0.5" />
    <path d="M602.52 424.56V424.67" stroke="black" stroke-width="0.5" />
    <path d="M602.52 424.73V424.84" stroke="black" stroke-width="0.5" />
    <path d="M602.52 424.89V425" stroke="black" stroke-width="0.5" />
    <path d="M602.52 425.06V425.17" stroke="black" stroke-width="0.5" />
    <path d="M602.52 425.22V425.33" stroke="black" stroke-width="0.5" />
    <path d="M602.52 425.39V425.5" stroke="black" stroke-width="0.5" />
    <path d="M602.52 425.55V425.66" stroke="black" stroke-width="0.5" />
    <path d="M602.52 425.72V425.83" stroke="black" stroke-width="0.5" />
    <path d="M602.52 425.88V425.99" stroke="black" stroke-width="0.5" />
    <path d="M602.52 426.05V426.16" stroke="black" stroke-width="0.5" />
    <path d="M602.52 426.21V426.32" stroke="black" stroke-width="0.5" />
    <path d="M602.52 426.38V426.49" stroke="black" stroke-width="0.5" />
    <path d="M602.52 426.54V426.65" stroke="black" stroke-width="0.5" />
    <path d="M602.52 426.71V426.82" stroke="black" stroke-width="0.5" />
    <path d="M602.52 426.87V426.98" stroke="black" stroke-width="0.5" />
    <path d="M602.52 427.04V427.15" stroke="black" stroke-width="0.5" />
    <path d="M602.52 427.2V427.31" stroke="black" stroke-width="0.5" />
    <path d="M602.52 427.37V427.48" stroke="black" stroke-width="0.5" />
    <path d="M602.52 427.53V427.64" stroke="black" stroke-width="0.5" />
    <path d="M602.52 427.7V427.81" stroke="black" stroke-width="0.5" />
    <path d="M602.52 427.86V427.97" stroke="black" stroke-width="0.5" />
    <path d="M602.52 428.03V428.14" stroke="black" stroke-width="0.5" />
    <path d="M602.52 428.19V428.3" stroke="black" stroke-width="0.5" />
    <path d="M602.52 428.36V428.47" stroke="black" stroke-width="0.5" />
    <path d="M602.52 428.52V428.63" stroke="black" stroke-width="0.5" />
    <path d="M602.52 428.69V428.8" stroke="black" stroke-width="0.5" />
    <path d="M602.52 428.85V428.96" stroke="black" stroke-width="0.5" />
    <path d="M602.52 429.02V429.13" stroke="black" stroke-width="0.5" />
    <path d="M602.52 429.18V429.29" stroke="black" stroke-width="0.5" />
    <path d="M602.52 429.35V429.46" stroke="black" stroke-width="0.5" />
    <path d="M602.52 429.51V429.62" stroke="black" stroke-width="0.5" />
    <path d="M602.52 429.68V429.79" stroke="black" stroke-width="0.5" />
    <path d="M602.52 429.84V429.95" stroke="black" stroke-width="0.5" />
    <path d="M602.52 430.01V430.12" stroke="black" stroke-width="0.5" />
    <path d="M602.52 430.17V430.28" stroke="black" stroke-width="0.5" />
    <path d="M602.52 430.34V430.45" stroke="black" stroke-width="0.5" />
    <path d="M602.52 430.5V430.61" stroke="black" stroke-width="0.5" />
    <path d="M602.52 430.67V430.78" stroke="black" stroke-width="0.5" />
    <path d="M602.52 430.83V430.94" stroke="black" stroke-width="0.5" />
    <path d="M602.52 431V431.11" stroke="black" stroke-width="0.5" />
    <path d="M602.52 431.16V431.27" stroke="black" stroke-width="0.5" />
    <path d="M602.52 431.33V431.44" stroke="black" stroke-width="0.5" />
    <path d="M602.52 431.49V431.6" stroke="black" stroke-width="0.5" />
    <path d="M602.52 431.66V431.77" stroke="black" stroke-width="0.5" />
    <path d="M602.52 431.82V431.93" stroke="black" stroke-width="0.5" />
    <path d="M602.52 431.99V432.1" stroke="black" stroke-width="0.5" />
    <path d="M602.52 432.15V432.26" stroke="black" stroke-width="0.5" />
    <path d="M602.52 432.31V432.42" stroke="black" stroke-width="0.5" />
    <path d="M602.52 432.48V432.59" stroke="black" stroke-width="0.5" />
    <path d="M602.52 432.64V432.75" stroke="black" stroke-width="0.5" />
    <path d="M602.52 432.81V432.92" stroke="black" stroke-width="0.5" />
    <path d="M602.52 432.97V433.08" stroke="black" stroke-width="0.5" />
    <path d="M602.52 433.14V433.25" stroke="black" stroke-width="0.5" />
    <path d="M602.52 433.3V433.41" stroke="black" stroke-width="0.5" />
    <path d="M602.52 433.47V433.58" stroke="black" stroke-width="0.5" />
    <path d="M602.52 433.63V433.74" stroke="black" stroke-width="0.5" />
    <path d="M602.52 433.8V433.91" stroke="black" stroke-width="0.5" />
    <path d="M602.52 433.96V434.07" stroke="black" stroke-width="0.5" />
    <path d="M602.52 434.13V434.24" stroke="black" stroke-width="0.5" />
    <path d="M602.52 434.29V434.35" stroke="black" stroke-width="0.5" />
    <path d="M586.69 413.24H586.75" stroke="black" stroke-width="0.5" />
    <path d="M586.8 413.24H586.91" stroke="black" stroke-width="0.5" />
    <path d="M586.96 413.24H587.07" stroke="black" stroke-width="0.5" />
    <path d="M587.13 413.24H587.24" stroke="black" stroke-width="0.5" />
    <path d="M587.29 413.24H587.4" stroke="black" stroke-width="0.5" />
    <path d="M587.46 413.24H587.57" stroke="black" stroke-width="0.5" />
    <path d="M587.62 413.24H587.73" stroke="black" stroke-width="0.5" />
    <path d="M587.79 413.24H587.9" stroke="black" stroke-width="0.5" />
    <path d="M587.95 413.24H588.06" stroke="black" stroke-width="0.5" />
    <path d="M588.12 413.24H588.23" stroke="black" stroke-width="0.5" />
    <path d="M588.28 413.24H588.39" stroke="black" stroke-width="0.5" />
    <path d="M588.45 413.24H588.56" stroke="black" stroke-width="0.5" />
    <path d="M588.61 413.24H588.72" stroke="black" stroke-width="0.5" />
    <path d="M588.78 413.24H588.89" stroke="black" stroke-width="0.5" />
    <path d="M588.94 413.24H589.05" stroke="black" stroke-width="0.5" />
    <path d="M589.11 413.24H589.22" stroke="black" stroke-width="0.5" />
    <path d="M589.27 413.24H589.38" stroke="black" stroke-width="0.5" />
    <path d="M589.44 413.24H589.55" stroke="black" stroke-width="0.5" />
    <path d="M589.6 413.24H589.71" stroke="black" stroke-width="0.5" />
    <path d="M589.77 413.24H589.88" stroke="black" stroke-width="0.5" />
    <path d="M589.93 413.24H590.04" stroke="black" stroke-width="0.5" />
    <path d="M590.1 413.24H590.21" stroke="black" stroke-width="0.5" />
    <path d="M590.26 413.24H590.37" stroke="black" stroke-width="0.5" />
    <path d="M590.43 413.24H590.54" stroke="black" stroke-width="0.5" />
    <path d="M590.59 413.24H590.7" stroke="black" stroke-width="0.5" />
    <path d="M590.76 413.24H590.87" stroke="black" stroke-width="0.5" />
    <path d="M590.92 413.24H591.03" stroke="black" stroke-width="0.5" />
    <path d="M591.09 413.24H591.2" stroke="black" stroke-width="0.5" />
    <path d="M591.25 413.24H591.36" stroke="black" stroke-width="0.5" />
    <path d="M591.42 413.24H591.53" stroke="black" stroke-width="0.5" />
    <path d="M591.58 413.24H591.69" stroke="black" stroke-width="0.5" />
    <path d="M591.75 413.24H591.86" stroke="black" stroke-width="0.5" />
    <path d="M591.91 413.24H592.02" stroke="black" stroke-width="0.5" />
    <path d="M592.08 413.24H592.19" stroke="black" stroke-width="0.5" />
    <path d="M592.24 413.24H592.35" stroke="black" stroke-width="0.5" />
    <path d="M592.41 413.24H592.52" stroke="black" stroke-width="0.5" />
    <path d="M592.57 413.24H592.68" stroke="black" stroke-width="0.5" />
    <path d="M592.74 413.24H592.85" stroke="black" stroke-width="0.5" />
    <path d="M592.9 413.24H593.01" stroke="black" stroke-width="0.5" />
    <path d="M593.07 413.24H593.18" stroke="black" stroke-width="0.5" />
    <path d="M593.23 413.24H593.34" stroke="black" stroke-width="0.5" />
    <path d="M593.4 413.24H593.51" stroke="black" stroke-width="0.5" />
    <path d="M593.56 413.24H593.67" stroke="black" stroke-width="0.5" />
    <path d="M593.73 413.24H593.84" stroke="black" stroke-width="0.5" />
    <path d="M593.89 413.24H594" stroke="black" stroke-width="0.5" />
    <path d="M594.06 413.24H594.17" stroke="black" stroke-width="0.5" />
    <path d="M594.22 413.24H594.33" stroke="black" stroke-width="0.5" />
    <path d="M594.39 413.24H594.5" stroke="black" stroke-width="0.5" />
    <path d="M594.55 413.24H594.66" stroke="black" stroke-width="0.5" />
    <path d="M594.72 413.24H594.83" stroke="black" stroke-width="0.5" />
    <path d="M594.88 413.24H594.99" stroke="black" stroke-width="0.5" />
    <path d="M595.05 413.24H595.16" stroke="black" stroke-width="0.5" />
    <path d="M595.21 413.24H595.32" stroke="black" stroke-width="0.5" />
    <path d="M595.38 413.24H595.49" stroke="black" stroke-width="0.5" />
    <path d="M595.54 413.24H595.65" stroke="black" stroke-width="0.5" />
    <path d="M595.71 413.24H595.82" stroke="black" stroke-width="0.5" />
    <path d="M595.87 413.24H595.98" stroke="black" stroke-width="0.5" />
    <path d="M596.04 413.24H596.15" stroke="black" stroke-width="0.5" />
    <path d="M596.2 413.24H596.31" stroke="black" stroke-width="0.5" />
    <path d="M596.37 413.24H596.48" stroke="black" stroke-width="0.5" />
    <path d="M596.53 413.24H596.64" stroke="black" stroke-width="0.5" />
    <path d="M596.7 413.24H596.81" stroke="black" stroke-width="0.5" />
    <path d="M596.86 413.24H596.97" stroke="black" stroke-width="0.5" />
    <path d="M597.03 413.24H597.14" stroke="black" stroke-width="0.5" />
    <path d="M597.19 413.24H597.3" stroke="black" stroke-width="0.5" />
    <path d="M597.36 413.24H597.47" stroke="black" stroke-width="0.5" />
    <path d="M597.52 413.24H597.63" stroke="black" stroke-width="0.5" />
    <path d="M597.69 413.24H597.8" stroke="black" stroke-width="0.5" />
    <path d="M597.85 413.24H597.96" stroke="black" stroke-width="0.5" />
    <path d="M598.02 413.24H598.13" stroke="black" stroke-width="0.5" />
    <path d="M598.18 413.24H598.29" stroke="black" stroke-width="0.5" />
    <path d="M598.35 413.24H598.46" stroke="black" stroke-width="0.5" />
    <path d="M598.51 413.24H598.62" stroke="black" stroke-width="0.5" />
    <path d="M598.68 413.24H598.79" stroke="black" stroke-width="0.5" />
    <path d="M598.84 413.24H598.95" stroke="black" stroke-width="0.5" />
    <path d="M599.01 413.24H599.12" stroke="black" stroke-width="0.5" />
    <path d="M599.17 413.24H599.28" stroke="black" stroke-width="0.5" />
    <path d="M599.34 413.24H599.45" stroke="black" stroke-width="0.5" />
    <path d="M599.5 413.24H599.61" stroke="black" stroke-width="0.5" />
    <path d="M599.67 413.24H599.77" stroke="black" stroke-width="0.5" />
    <path d="M599.83 413.24H599.94" stroke="black" stroke-width="0.5" />
    <path d="M599.99 413.24H600.1" stroke="black" stroke-width="0.5" />
    <path d="M600.16 413.24H600.27" stroke="black" stroke-width="0.5" />
    <path d="M600.32 413.24H600.43" stroke="black" stroke-width="0.5" />
    <path d="M600.49 413.24H600.6" stroke="black" stroke-width="0.5" />
    <path d="M600.65 413.24H600.76" stroke="black" stroke-width="0.5" />
    <path d="M600.82 413.24H600.93" stroke="black" stroke-width="0.5" />
    <path d="M600.98 413.24H601.09" stroke="black" stroke-width="0.5" />
    <path d="M601.15 413.24H601.26" stroke="black" stroke-width="0.5" />
    <path d="M601.31 413.24H601.42" stroke="black" stroke-width="0.5" />
    <path d="M601.48 413.24H601.59" stroke="black" stroke-width="0.5" />
    <path d="M601.64 413.24H601.75" stroke="black" stroke-width="0.5" />
    <path d="M601.81 413.24H601.92" stroke="black" stroke-width="0.5" />
    <path d="M601.97 413.24H602.08" stroke="black" stroke-width="0.5" />
    <path d="M602.14 413.24H602.25" stroke="black" stroke-width="0.5" />
    <path d="M602.3 413.24H602.41" stroke="black" stroke-width="0.5" />
    <path d="M602.47 413.24H602.52" stroke="black" stroke-width="0.5" />
    <path d="M565.58 244.1V244.05" stroke="black" stroke-width="0.5" />
    <path d="M565.58 243.99V243.88" stroke="black" stroke-width="0.5" />
    <path d="M565.58 243.83V243.72" stroke="black" stroke-width="0.5" />
    <path d="M565.58 243.66V243.55" stroke="black" stroke-width="0.5" />
    <path d="M565.58 243.5V243.39" stroke="black" stroke-width="0.5" />
    <path d="M565.58 243.33V243.22" stroke="black" stroke-width="0.5" />
    <path d="M565.58 243.17V243.06" stroke="black" stroke-width="0.5" />
    <path d="M565.58 243V242.89" stroke="black" stroke-width="0.5" />
    <path d="M565.58 242.84V242.73" stroke="black" stroke-width="0.5" />
    <path d="M565.58 242.67V242.56" stroke="black" stroke-width="0.5" />
    <path d="M565.58 242.51V242.4" stroke="black" stroke-width="0.5" />
    <path d="M565.58 242.34V242.23" stroke="black" stroke-width="0.5" />
    <path d="M565.58 242.18V242.07" stroke="black" stroke-width="0.5" />
    <path d="M565.58 242.01V241.9" stroke="black" stroke-width="0.5" />
    <path d="M565.58 241.85V241.74" stroke="black" stroke-width="0.5" />
    <path d="M565.58 241.68V241.57" stroke="black" stroke-width="0.5" />
    <path d="M565.58 241.52V241.41" stroke="black" stroke-width="0.5" />
    <path d="M565.58 241.35V241.24" stroke="black" stroke-width="0.5" />
    <path d="M565.58 241.19V241.08" stroke="black" stroke-width="0.5" />
    <path d="M565.58 241.02V240.91" stroke="black" stroke-width="0.5" />
    <path d="M565.58 240.86V240.75" stroke="black" stroke-width="0.5" />
    <path d="M565.58 240.69V240.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 240.53V240.42" stroke="black" stroke-width="0.5" />
    <path d="M565.58 240.36V240.25" stroke="black" stroke-width="0.5" />
    <path d="M565.58 240.2V240.09" stroke="black" stroke-width="0.5" />
    <path d="M565.58 240.03V239.92" stroke="black" stroke-width="0.5" />
    <path d="M565.58 239.87V239.76" stroke="black" stroke-width="0.5" />
    <path d="M565.58 239.7V239.59" stroke="black" stroke-width="0.5" />
    <path d="M565.58 239.54V239.43" stroke="black" stroke-width="0.5" />
    <path d="M565.58 239.37V239.26" stroke="black" stroke-width="0.5" />
    <path d="M565.58 239.21V239.1" stroke="black" stroke-width="0.5" />
    <path d="M565.58 239.04V238.93" stroke="black" stroke-width="0.5" />
    <path d="M565.58 238.88V238.77" stroke="black" stroke-width="0.5" />
    <path d="M565.58 238.71V238.6" stroke="black" stroke-width="0.5" />
    <path d="M565.58 238.55V238.44" stroke="black" stroke-width="0.5" />
    <path d="M565.58 238.38V238.27" stroke="black" stroke-width="0.5" />
    <path d="M565.58 238.22V238.11" stroke="black" stroke-width="0.5" />
    <path d="M565.58 238.05V237.94" stroke="black" stroke-width="0.5" />
    <path d="M565.58 237.89V237.78" stroke="black" stroke-width="0.5" />
    <path d="M565.58 237.72V237.61" stroke="black" stroke-width="0.5" />
    <path d="M565.58 237.56V237.45" stroke="black" stroke-width="0.5" />
    <path d="M565.58 237.39V237.28" stroke="black" stroke-width="0.5" />
    <path d="M565.58 237.23V237.12" stroke="black" stroke-width="0.5" />
    <path d="M565.58 237.06V236.95" stroke="black" stroke-width="0.5" />
    <path d="M565.58 236.9V236.79" stroke="black" stroke-width="0.5" />
    <path d="M565.58 236.73V236.62" stroke="black" stroke-width="0.5" />
    <path d="M565.58 236.57V236.46" stroke="black" stroke-width="0.5" />
    <path d="M565.58 236.4V236.29" stroke="black" stroke-width="0.5" />
    <path d="M565.58 236.24V236.13" stroke="black" stroke-width="0.5" />
    <path d="M565.58 236.07V235.96" stroke="black" stroke-width="0.5" />
    <path d="M565.58 235.91V235.8" stroke="black" stroke-width="0.5" />
    <path d="M565.58 235.74V235.63" stroke="black" stroke-width="0.5" />
    <path d="M565.58 235.58V235.47" stroke="black" stroke-width="0.5" />
    <path d="M565.58 235.41V235.3" stroke="black" stroke-width="0.5" />
    <path d="M565.58 235.25V235.14" stroke="black" stroke-width="0.5" />
    <path d="M565.58 235.08V234.97" stroke="black" stroke-width="0.5" />
    <path d="M565.58 234.92V234.81" stroke="black" stroke-width="0.5" />
    <path d="M565.58 234.75V234.64" stroke="black" stroke-width="0.5" />
    <path d="M565.58 234.59V234.48" stroke="black" stroke-width="0.5" />
    <path d="M565.58 234.42V234.31" stroke="black" stroke-width="0.5" />
    <path d="M565.58 234.26V234.15" stroke="black" stroke-width="0.5" />
    <path d="M565.58 234.09V233.98" stroke="black" stroke-width="0.5" />
    <path d="M565.58 233.93V233.82" stroke="black" stroke-width="0.5" />
    <path d="M565.58 233.76V233.65" stroke="black" stroke-width="0.5" />
    <path d="M565.58 233.6V233.49" stroke="black" stroke-width="0.5" />
    <path d="M565.58 233.43V233.32" stroke="black" stroke-width="0.5" />
    <path d="M565.58 233.27V233.16" stroke="black" stroke-width="0.5" />
    <path d="M565.58 233.1V232.99" stroke="black" stroke-width="0.5" />
    <path d="M565.58 232.94V232.83" stroke="black" stroke-width="0.5" />
    <path d="M565.58 232.77V232.66" stroke="black" stroke-width="0.5" />
    <path d="M565.58 232.61V232.5" stroke="black" stroke-width="0.5" />
    <path d="M565.58 232.44V232.33" stroke="black" stroke-width="0.5" />
    <path d="M565.58 232.28V232.17" stroke="black" stroke-width="0.5" />
    <path d="M565.58 232.11V232" stroke="black" stroke-width="0.5" />
    <path d="M565.58 231.95V231.84" stroke="black" stroke-width="0.5" />
    <path d="M565.58 231.78V231.67" stroke="black" stroke-width="0.5" />
    <path d="M565.58 231.62V231.51" stroke="black" stroke-width="0.5" />
    <path d="M565.58 231.45V231.34" stroke="black" stroke-width="0.5" />
    <path d="M565.58 231.29V231.18" stroke="black" stroke-width="0.5" />
    <path d="M565.58 231.13V231.02" stroke="black" stroke-width="0.5" />
    <path d="M565.58 230.96V230.85" stroke="black" stroke-width="0.5" />
    <path d="M565.58 230.8V230.69" stroke="black" stroke-width="0.5" />
    <path d="M565.58 230.63V230.52" stroke="black" stroke-width="0.5" />
    <path d="M565.58 230.47V230.36" stroke="black" stroke-width="0.5" />
    <path d="M565.58 230.3V230.19" stroke="black" stroke-width="0.5" />
    <path d="M565.58 230.14V230.03" stroke="black" stroke-width="0.5" />
    <path d="M565.58 229.97V229.86" stroke="black" stroke-width="0.5" />
    <path d="M565.58 229.81V229.7" stroke="black" stroke-width="0.5" />
    <path d="M565.58 229.64V229.53" stroke="black" stroke-width="0.5" />
    <path d="M565.58 229.48V229.37" stroke="black" stroke-width="0.5" />
    <path d="M565.58 229.31V229.2" stroke="black" stroke-width="0.5" />
    <path d="M565.58 229.15V229.04" stroke="black" stroke-width="0.5" />
    <path d="M565.58 228.98V228.87" stroke="black" stroke-width="0.5" />
    <path d="M565.58 228.82V228.71" stroke="black" stroke-width="0.5" />
    <path d="M565.58 228.65V228.54" stroke="black" stroke-width="0.5" />
    <path d="M565.58 228.49V228.38" stroke="black" stroke-width="0.5" />
    <path d="M565.58 228.32V228.21" stroke="black" stroke-width="0.5" />
    <path d="M565.58 228.16V228.05" stroke="black" stroke-width="0.5" />
    <path d="M565.58 227.99V227.88" stroke="black" stroke-width="0.5" />
    <path d="M565.58 227.83V227.72" stroke="black" stroke-width="0.5" />
    <path d="M565.58 227.66V227.55" stroke="black" stroke-width="0.5" />
    <path d="M565.58 227.5V227.39" stroke="black" stroke-width="0.5" />
    <path d="M565.58 227.33V227.22" stroke="black" stroke-width="0.5" />
    <path d="M565.58 227.17V227.06" stroke="black" stroke-width="0.5" />
    <path d="M565.58 227V226.89" stroke="black" stroke-width="0.5" />
    <path d="M565.58 226.84V226.73" stroke="black" stroke-width="0.5" />
    <path d="M565.58 226.67V226.56" stroke="black" stroke-width="0.5" />
    <path d="M565.58 226.51V226.4" stroke="black" stroke-width="0.5" />
    <path d="M565.58 226.34V226.23" stroke="black" stroke-width="0.5" />
    <path d="M565.58 226.18V226.07" stroke="black" stroke-width="0.5" />
    <path d="M565.58 226.01V225.9" stroke="black" stroke-width="0.5" />
    <path d="M565.58 225.85V225.74" stroke="black" stroke-width="0.5" />
    <path d="M565.58 225.68V225.57" stroke="black" stroke-width="0.5" />
    <path d="M565.58 225.52V225.41" stroke="black" stroke-width="0.5" />
    <path d="M565.58 225.35V225.24" stroke="black" stroke-width="0.5" />
    <path d="M565.58 225.19V225.08" stroke="black" stroke-width="0.5" />
    <path d="M565.58 225.02V224.91" stroke="black" stroke-width="0.5" />
    <path d="M565.58 224.86V224.75" stroke="black" stroke-width="0.5" />
    <path d="M565.58 224.69V224.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 224.53V224.42" stroke="black" stroke-width="0.5" />
    <path d="M565.58 224.36V224.25" stroke="black" stroke-width="0.5" />
    <path d="M565.58 224.2V224.09" stroke="black" stroke-width="0.5" />
    <path d="M565.58 224.03V223.92" stroke="black" stroke-width="0.5" />
    <path d="M565.58 223.87V223.76" stroke="black" stroke-width="0.5" />
    <path d="M565.58 223.7V223.59" stroke="black" stroke-width="0.5" />
    <path d="M565.58 223.54V223.43" stroke="black" stroke-width="0.5" />
    <path d="M565.58 223.37V223.26" stroke="black" stroke-width="0.5" />
    <path d="M565.58 223.21V223.1" stroke="black" stroke-width="0.5" />
    <path d="M565.58 223.04V222.99" stroke="black" stroke-width="0.5" />
    <path d="M581.41 244.1H581.36" stroke="black" stroke-width="0.5" />
    <path d="M581.3 244.1H581.19" stroke="black" stroke-width="0.5" />
    <path d="M581.14 244.1H581.03" stroke="black" stroke-width="0.5" />
    <path d="M580.97 244.1H580.86" stroke="black" stroke-width="0.5" />
    <path d="M580.81 244.1H580.7" stroke="black" stroke-width="0.5" />
    <path d="M580.64 244.1H580.53" stroke="black" stroke-width="0.5" />
    <path d="M580.48 244.1H580.37" stroke="black" stroke-width="0.5" />
    <path d="M580.31 244.1H580.2" stroke="black" stroke-width="0.5" />
    <path d="M580.15 244.1H580.04" stroke="black" stroke-width="0.5" />
    <path d="M579.98 244.1H579.87" stroke="black" stroke-width="0.5" />
    <path d="M579.82 244.1H579.71" stroke="black" stroke-width="0.5" />
    <path d="M579.65 244.1H579.54" stroke="black" stroke-width="0.5" />
    <path d="M579.49 244.1H579.38" stroke="black" stroke-width="0.5" />
    <path d="M579.32 244.1H579.21" stroke="black" stroke-width="0.5" />
    <path d="M579.16 244.1H579.05" stroke="black" stroke-width="0.5" />
    <path d="M578.99 244.1H578.88" stroke="black" stroke-width="0.5" />
    <path d="M578.83 244.1H578.72" stroke="black" stroke-width="0.5" />
    <path d="M578.66 244.1H578.55" stroke="black" stroke-width="0.5" />
    <path d="M578.5 244.1H578.39" stroke="black" stroke-width="0.5" />
    <path d="M578.33 244.1H578.22" stroke="black" stroke-width="0.5" />
    <path d="M578.17 244.1H578.06" stroke="black" stroke-width="0.5" />
    <path d="M578 244.1H577.89" stroke="black" stroke-width="0.5" />
    <path d="M577.84 244.1H577.73" stroke="black" stroke-width="0.5" />
    <path d="M577.67 244.1H577.56" stroke="black" stroke-width="0.5" />
    <path d="M577.51 244.1H577.4" stroke="black" stroke-width="0.5" />
    <path d="M577.34 244.1H577.23" stroke="black" stroke-width="0.5" />
    <path d="M577.18 244.1H577.07" stroke="black" stroke-width="0.5" />
    <path d="M577.01 244.1H576.9" stroke="black" stroke-width="0.5" />
    <path d="M576.85 244.1H576.74" stroke="black" stroke-width="0.5" />
    <path d="M576.68 244.1H576.57" stroke="black" stroke-width="0.5" />
    <path d="M576.52 244.1H576.41" stroke="black" stroke-width="0.5" />
    <path d="M576.35 244.1H576.24" stroke="black" stroke-width="0.5" />
    <path d="M576.19 244.1H576.08" stroke="black" stroke-width="0.5" />
    <path d="M576.02 244.1H575.91" stroke="black" stroke-width="0.5" />
    <path d="M575.86 244.1H575.75" stroke="black" stroke-width="0.5" />
    <path d="M575.69 244.1H575.58" stroke="black" stroke-width="0.5" />
    <path d="M575.53 244.1H575.42" stroke="black" stroke-width="0.5" />
    <path d="M575.36 244.1H575.25" stroke="black" stroke-width="0.5" />
    <path d="M575.2 244.1H575.09" stroke="black" stroke-width="0.5" />
    <path d="M575.03 244.1H574.92" stroke="black" stroke-width="0.5" />
    <path d="M574.87 244.1H574.76" stroke="black" stroke-width="0.5" />
    <path d="M574.7 244.1H574.59" stroke="black" stroke-width="0.5" />
    <path d="M574.54 244.1H574.43" stroke="black" stroke-width="0.5" />
    <path d="M574.37 244.1H574.26" stroke="black" stroke-width="0.5" />
    <path d="M574.21 244.1H574.1" stroke="black" stroke-width="0.5" />
    <path d="M574.04 244.1H573.93" stroke="black" stroke-width="0.5" />
    <path d="M573.88 244.1H573.77" stroke="black" stroke-width="0.5" />
    <path d="M573.72 244.1H573.61" stroke="black" stroke-width="0.5" />
    <path d="M573.55 244.1H573.44" stroke="black" stroke-width="0.5" />
    <path d="M573.39 244.1H573.28" stroke="black" stroke-width="0.5" />
    <path d="M573.22 244.1H573.11" stroke="black" stroke-width="0.5" />
    <path d="M573.06 244.1H572.95" stroke="black" stroke-width="0.5" />
    <path d="M572.89 244.1H572.78" stroke="black" stroke-width="0.5" />
    <path d="M572.73 244.1H572.62" stroke="black" stroke-width="0.5" />
    <path d="M572.56 244.1H572.45" stroke="black" stroke-width="0.5" />
    <path d="M572.4 244.1H572.29" stroke="black" stroke-width="0.5" />
    <path d="M572.23 244.1H572.12" stroke="black" stroke-width="0.5" />
    <path d="M572.07 244.1H571.96" stroke="black" stroke-width="0.5" />
    <path d="M571.9 244.1H571.79" stroke="black" stroke-width="0.5" />
    <path d="M571.74 244.1H571.63" stroke="black" stroke-width="0.5" />
    <path d="M571.57 244.1H571.46" stroke="black" stroke-width="0.5" />
    <path d="M571.41 244.1H571.3" stroke="black" stroke-width="0.5" />
    <path d="M571.24 244.1H571.13" stroke="black" stroke-width="0.5" />
    <path d="M571.08 244.1H570.97" stroke="black" stroke-width="0.5" />
    <path d="M570.91 244.1H570.8" stroke="black" stroke-width="0.5" />
    <path d="M570.75 244.1H570.64" stroke="black" stroke-width="0.5" />
    <path d="M570.58 244.1H570.47" stroke="black" stroke-width="0.5" />
    <path d="M570.42 244.1H570.31" stroke="black" stroke-width="0.5" />
    <path d="M570.25 244.1H570.14" stroke="black" stroke-width="0.5" />
    <path d="M570.09 244.1H569.98" stroke="black" stroke-width="0.5" />
    <path d="M569.92 244.1H569.81" stroke="black" stroke-width="0.5" />
    <path d="M569.76 244.1H569.65" stroke="black" stroke-width="0.5" />
    <path d="M569.59 244.1H569.48" stroke="black" stroke-width="0.5" />
    <path d="M569.43 244.1H569.32" stroke="black" stroke-width="0.5" />
    <path d="M569.26 244.1H569.15" stroke="black" stroke-width="0.5" />
    <path d="M569.1 244.1H568.99" stroke="black" stroke-width="0.5" />
    <path d="M568.93 244.1H568.82" stroke="black" stroke-width="0.5" />
    <path d="M568.77 244.1H568.66" stroke="black" stroke-width="0.5" />
    <path d="M568.6 244.1H568.49" stroke="black" stroke-width="0.5" />
    <path d="M568.44 244.1H568.33" stroke="black" stroke-width="0.5" />
    <path d="M568.27 244.1H568.16" stroke="black" stroke-width="0.5" />
    <path d="M568.11 244.1H568" stroke="black" stroke-width="0.5" />
    <path d="M567.94 244.1H567.83" stroke="black" stroke-width="0.5" />
    <path d="M567.78 244.1H567.67" stroke="black" stroke-width="0.5" />
    <path d="M567.61 244.1H567.5" stroke="black" stroke-width="0.5" />
    <path d="M567.45 244.1H567.34" stroke="black" stroke-width="0.5" />
    <path d="M567.28 244.1H567.17" stroke="black" stroke-width="0.5" />
    <path d="M567.12 244.1H567.01" stroke="black" stroke-width="0.5" />
    <path d="M566.95 244.1H566.84" stroke="black" stroke-width="0.5" />
    <path d="M566.79 244.1H566.68" stroke="black" stroke-width="0.5" />
    <path d="M566.62 244.1H566.51" stroke="black" stroke-width="0.5" />
    <path d="M566.46 244.1H566.35" stroke="black" stroke-width="0.5" />
    <path d="M566.29 244.1H566.18" stroke="black" stroke-width="0.5" />
    <path d="M566.13 244.1H566.02" stroke="black" stroke-width="0.5" />
    <path d="M565.96 244.1H565.85" stroke="black" stroke-width="0.5" />
    <path d="M565.8 244.1H565.69" stroke="black" stroke-width="0.5" />
    <path d="M565.63 244.1H565.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 135.13V135.08" stroke="black" stroke-width="0.5" />
    <path d="M565.58 135.02V134.91" stroke="black" stroke-width="0.5" />
    <path d="M565.58 134.86V134.75" stroke="black" stroke-width="0.5" />
    <path d="M565.58 134.69V134.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 134.53V134.42" stroke="black" stroke-width="0.5" />
    <path d="M565.58 134.36V134.25" stroke="black" stroke-width="0.5" />
    <path d="M565.58 134.2V134.09" stroke="black" stroke-width="0.5" />
    <path d="M565.58 134.03V133.92" stroke="black" stroke-width="0.5" />
    <path d="M565.58 133.87V133.76" stroke="black" stroke-width="0.5" />
    <path d="M565.58 133.7V133.59" stroke="black" stroke-width="0.5" />
    <path d="M565.58 133.54V133.43" stroke="black" stroke-width="0.5" />
    <path d="M565.58 133.37V133.26" stroke="black" stroke-width="0.5" />
    <path d="M565.58 133.21V133.1" stroke="black" stroke-width="0.5" />
    <path d="M565.58 133.04V132.93" stroke="black" stroke-width="0.5" />
    <path d="M565.58 132.88V132.77" stroke="black" stroke-width="0.5" />
    <path d="M565.58 132.71V132.6" stroke="black" stroke-width="0.5" />
    <path d="M565.58 132.55V132.44" stroke="black" stroke-width="0.5" />
    <path d="M565.58 132.38V132.27" stroke="black" stroke-width="0.5" />
    <path d="M565.58 132.22V132.11" stroke="black" stroke-width="0.5" />
    <path d="M565.58 132.05V131.94" stroke="black" stroke-width="0.5" />
    <path d="M565.58 131.89V131.78" stroke="black" stroke-width="0.5" />
    <path d="M565.58 131.72V131.61" stroke="black" stroke-width="0.5" />
    <path d="M565.58 131.56V131.45" stroke="black" stroke-width="0.5" />
    <path d="M565.58 131.39V131.28" stroke="black" stroke-width="0.5" />
    <path d="M565.58 131.23V131.12" stroke="black" stroke-width="0.5" />
    <path d="M565.58 131.06V130.95" stroke="black" stroke-width="0.5" />
    <path d="M565.58 130.9V130.79" stroke="black" stroke-width="0.5" />
    <path d="M565.58 130.73V130.62" stroke="black" stroke-width="0.5" />
    <path d="M565.58 130.57V130.46" stroke="black" stroke-width="0.5" />
    <path d="M565.58 130.4V130.29" stroke="black" stroke-width="0.5" />
    <path d="M565.58 130.24V130.13" stroke="black" stroke-width="0.5" />
    <path d="M565.58 130.07V129.96" stroke="black" stroke-width="0.5" />
    <path d="M565.58 129.91V129.8" stroke="black" stroke-width="0.5" />
    <path d="M565.58 129.74V129.63" stroke="black" stroke-width="0.5" />
    <path d="M565.58 129.58V129.47" stroke="black" stroke-width="0.5" />
    <path d="M565.58 129.41V129.3" stroke="black" stroke-width="0.5" />
    <path d="M565.58 129.25V129.14" stroke="black" stroke-width="0.5" />
    <path d="M565.58 129.08V128.97" stroke="black" stroke-width="0.5" />
    <path d="M565.58 128.92V128.81" stroke="black" stroke-width="0.5" />
    <path d="M565.58 128.75V128.64" stroke="black" stroke-width="0.5" />
    <path d="M565.58 128.59V128.48" stroke="black" stroke-width="0.5" />
    <path d="M565.58 128.42V128.31" stroke="black" stroke-width="0.5" />
    <path d="M565.58 128.26V128.15" stroke="black" stroke-width="0.5" />
    <path d="M565.58 128.09V127.98" stroke="black" stroke-width="0.5" />
    <path d="M565.58 127.93V127.82" stroke="black" stroke-width="0.5" />
    <path d="M565.58 127.77V127.66" stroke="black" stroke-width="0.5" />
    <path d="M565.58 127.6V127.49" stroke="black" stroke-width="0.5" />
    <path d="M565.58 127.44V127.33" stroke="black" stroke-width="0.5" />
    <path d="M565.58 127.27V127.16" stroke="black" stroke-width="0.5" />
    <path d="M565.58 127.11V127" stroke="black" stroke-width="0.5" />
    <path d="M565.58 126.94V126.83" stroke="black" stroke-width="0.5" />
    <path d="M565.58 126.78V126.67" stroke="black" stroke-width="0.5" />
    <path d="M565.58 126.61V126.5" stroke="black" stroke-width="0.5" />
    <path d="M565.58 126.45V126.34" stroke="black" stroke-width="0.5" />
    <path d="M565.58 126.28V126.17" stroke="black" stroke-width="0.5" />
    <path d="M565.58 126.12V126.01" stroke="black" stroke-width="0.5" />
    <path d="M565.58 125.95V125.84" stroke="black" stroke-width="0.5" />
    <path d="M565.58 125.79V125.68" stroke="black" stroke-width="0.5" />
    <path d="M565.58 125.62V125.51" stroke="black" stroke-width="0.5" />
    <path d="M565.58 125.46V125.35" stroke="black" stroke-width="0.5" />
    <path d="M565.58 125.29V125.18" stroke="black" stroke-width="0.5" />
    <path d="M565.58 125.13V125.02" stroke="black" stroke-width="0.5" />
    <path d="M565.58 124.96V124.85" stroke="black" stroke-width="0.5" />
    <path d="M565.58 124.8V124.69" stroke="black" stroke-width="0.5" />
    <path d="M565.58 124.63V124.52" stroke="black" stroke-width="0.5" />
    <path d="M565.58 124.47V124.36" stroke="black" stroke-width="0.5" />
    <path d="M565.58 124.3V124.19" stroke="black" stroke-width="0.5" />
    <path d="M565.58 124.14V124.03" stroke="black" stroke-width="0.5" />
    <path d="M565.58 123.97V123.86" stroke="black" stroke-width="0.5" />
    <path d="M565.58 123.81V123.7" stroke="black" stroke-width="0.5" />
    <path d="M565.58 123.64V123.53" stroke="black" stroke-width="0.5" />
    <path d="M565.58 123.48V123.37" stroke="black" stroke-width="0.5" />
    <path d="M565.58 123.31V123.2" stroke="black" stroke-width="0.5" />
    <path d="M565.58 123.15V123.04" stroke="black" stroke-width="0.5" />
    <path d="M565.58 122.98V122.87" stroke="black" stroke-width="0.5" />
    <path d="M565.58 122.82V122.71" stroke="black" stroke-width="0.5" />
    <path d="M565.58 122.65V122.54" stroke="black" stroke-width="0.5" />
    <path d="M565.58 122.49V122.38" stroke="black" stroke-width="0.5" />
    <path d="M565.58 122.32V122.21" stroke="black" stroke-width="0.5" />
    <path d="M565.58 122.16V122.05" stroke="black" stroke-width="0.5" />
    <path d="M565.58 121.99V121.88" stroke="black" stroke-width="0.5" />
    <path d="M565.58 121.83V121.72" stroke="black" stroke-width="0.5" />
    <path d="M565.58 121.66V121.55" stroke="black" stroke-width="0.5" />
    <path d="M565.58 121.5V121.39" stroke="black" stroke-width="0.5" />
    <path d="M565.58 121.33V121.22" stroke="black" stroke-width="0.5" />
    <path d="M565.58 121.17V121.06" stroke="black" stroke-width="0.5" />
    <path d="M565.58 121V120.89" stroke="black" stroke-width="0.5" />
    <path d="M565.58 120.84V120.73" stroke="black" stroke-width="0.5" />
    <path d="M565.58 120.67V120.56" stroke="black" stroke-width="0.5" />
    <path d="M565.58 120.51V120.4" stroke="black" stroke-width="0.5" />
    <path d="M565.58 120.34V120.23" stroke="black" stroke-width="0.5" />
    <path d="M565.58 120.18V120.07" stroke="black" stroke-width="0.5" />
    <path d="M565.58 120.01V119.9" stroke="black" stroke-width="0.5" />
    <path d="M565.58 119.85V119.74" stroke="black" stroke-width="0.5" />
    <path d="M565.58 119.68V119.57" stroke="black" stroke-width="0.5" />
    <path d="M565.58 119.52V119.41" stroke="black" stroke-width="0.5" />
    <path d="M565.58 119.35V119.24" stroke="black" stroke-width="0.5" />
    <path d="M565.58 119.19V119.08" stroke="black" stroke-width="0.5" />
    <path d="M565.58 119.02V118.91" stroke="black" stroke-width="0.5" />
    <path d="M565.58 118.86V118.75" stroke="black" stroke-width="0.5" />
    <path d="M565.58 118.69V118.58" stroke="black" stroke-width="0.5" />
    <path d="M565.58 118.53V118.42" stroke="black" stroke-width="0.5" />
    <path d="M565.58 118.36V118.25" stroke="black" stroke-width="0.5" />
    <path d="M565.58 118.2V118.09" stroke="black" stroke-width="0.5" />
    <path d="M565.58 118.03V117.92" stroke="black" stroke-width="0.5" />
    <path d="M565.58 117.87V117.76" stroke="black" stroke-width="0.5" />
    <path d="M565.58 117.7V117.59" stroke="black" stroke-width="0.5" />
    <path d="M565.58 117.54V117.43" stroke="black" stroke-width="0.5" />
    <path d="M565.58 117.37V117.26" stroke="black" stroke-width="0.5" />
    <path d="M565.58 117.21V117.1" stroke="black" stroke-width="0.5" />
    <path d="M565.58 117.04V116.93" stroke="black" stroke-width="0.5" />
    <path d="M565.58 116.88V116.77" stroke="black" stroke-width="0.5" />
    <path d="M565.58 116.71V116.6" stroke="black" stroke-width="0.5" />
    <path d="M565.58 116.55V116.44" stroke="black" stroke-width="0.5" />
    <path d="M565.58 116.38V116.27" stroke="black" stroke-width="0.5" />
    <path d="M565.58 116.22V116.11" stroke="black" stroke-width="0.5" />
    <path d="M565.58 116.05V115.94" stroke="black" stroke-width="0.5" />
    <path d="M565.58 115.89V115.78" stroke="black" stroke-width="0.5" />
    <path d="M565.58 115.72V115.61" stroke="black" stroke-width="0.5" />
    <path d="M565.58 115.56V115.45" stroke="black" stroke-width="0.5" />
    <path d="M565.58 115.39V115.28" stroke="black" stroke-width="0.5" />
    <path d="M565.58 115.23V115.12" stroke="black" stroke-width="0.5" />
    <path d="M565.58 115.06V114.95" stroke="black" stroke-width="0.5" />
    <path d="M565.58 114.9V114.79" stroke="black" stroke-width="0.5" />
    <path d="M565.58 114.74V114.63" stroke="black" stroke-width="0.5" />
    <path d="M565.58 114.57V114.46" stroke="black" stroke-width="0.5" />
    <path d="M565.58 114.41V114.3" stroke="black" stroke-width="0.5" />
    <path d="M565.58 114.24V114.13" stroke="black" stroke-width="0.5" />
    <path d="M565.58 114.08V114.02" stroke="black" stroke-width="0.5" />
    <path d="M581.41 135.13H581.36" stroke="black" stroke-width="0.5" />
    <path d="M581.3 135.13H581.19" stroke="black" stroke-width="0.5" />
    <path d="M581.14 135.13H581.03" stroke="black" stroke-width="0.5" />
    <path d="M580.97 135.13H580.86" stroke="black" stroke-width="0.5" />
    <path d="M580.81 135.13H580.7" stroke="black" stroke-width="0.5" />
    <path d="M580.64 135.13H580.53" stroke="black" stroke-width="0.5" />
    <path d="M580.48 135.13H580.37" stroke="black" stroke-width="0.5" />
    <path d="M580.31 135.13H580.2" stroke="black" stroke-width="0.5" />
    <path d="M580.15 135.13H580.04" stroke="black" stroke-width="0.5" />
    <path d="M579.98 135.13H579.87" stroke="black" stroke-width="0.5" />
    <path d="M579.82 135.13H579.71" stroke="black" stroke-width="0.5" />
    <path d="M579.65 135.13H579.54" stroke="black" stroke-width="0.5" />
    <path d="M579.49 135.13H579.38" stroke="black" stroke-width="0.5" />
    <path d="M579.32 135.13H579.21" stroke="black" stroke-width="0.5" />
    <path d="M579.16 135.13H579.05" stroke="black" stroke-width="0.5" />
    <path d="M578.99 135.13H578.88" stroke="black" stroke-width="0.5" />
    <path d="M578.83 135.13H578.72" stroke="black" stroke-width="0.5" />
    <path d="M578.66 135.13H578.55" stroke="black" stroke-width="0.5" />
    <path d="M578.5 135.13H578.39" stroke="black" stroke-width="0.5" />
    <path d="M578.33 135.13H578.22" stroke="black" stroke-width="0.5" />
    <path d="M578.17 135.13H578.06" stroke="black" stroke-width="0.5" />
    <path d="M578 135.13H577.89" stroke="black" stroke-width="0.5" />
    <path d="M577.84 135.13H577.73" stroke="black" stroke-width="0.5" />
    <path d="M577.67 135.13H577.56" stroke="black" stroke-width="0.5" />
    <path d="M577.51 135.13H577.4" stroke="black" stroke-width="0.5" />
    <path d="M577.34 135.13H577.23" stroke="black" stroke-width="0.5" />
    <path d="M577.18 135.13H577.07" stroke="black" stroke-width="0.5" />
    <path d="M577.01 135.13H576.9" stroke="black" stroke-width="0.5" />
    <path d="M576.85 135.13H576.74" stroke="black" stroke-width="0.5" />
    <path d="M576.68 135.13H576.57" stroke="black" stroke-width="0.5" />
    <path d="M576.52 135.13H576.41" stroke="black" stroke-width="0.5" />
    <path d="M576.35 135.13H576.24" stroke="black" stroke-width="0.5" />
    <path d="M576.19 135.13H576.08" stroke="black" stroke-width="0.5" />
    <path d="M576.02 135.13H575.91" stroke="black" stroke-width="0.5" />
    <path d="M575.86 135.13H575.75" stroke="black" stroke-width="0.5" />
    <path d="M575.69 135.13H575.58" stroke="black" stroke-width="0.5" />
    <path d="M575.53 135.13H575.42" stroke="black" stroke-width="0.5" />
    <path d="M575.36 135.13H575.25" stroke="black" stroke-width="0.5" />
    <path d="M575.2 135.13H575.09" stroke="black" stroke-width="0.5" />
    <path d="M575.03 135.13H574.92" stroke="black" stroke-width="0.5" />
    <path d="M574.87 135.13H574.76" stroke="black" stroke-width="0.5" />
    <path d="M574.7 135.13H574.59" stroke="black" stroke-width="0.5" />
    <path d="M574.54 135.13H574.43" stroke="black" stroke-width="0.5" />
    <path d="M574.37 135.13H574.26" stroke="black" stroke-width="0.5" />
    <path d="M574.21 135.13H574.1" stroke="black" stroke-width="0.5" />
    <path d="M574.04 135.13H573.93" stroke="black" stroke-width="0.5" />
    <path d="M573.88 135.13H573.77" stroke="black" stroke-width="0.5" />
    <path d="M573.72 135.13H573.61" stroke="black" stroke-width="0.5" />
    <path d="M573.55 135.13H573.44" stroke="black" stroke-width="0.5" />
    <path d="M573.39 135.13H573.28" stroke="black" stroke-width="0.5" />
    <path d="M573.22 135.13H573.11" stroke="black" stroke-width="0.5" />
    <path d="M573.06 135.13H572.95" stroke="black" stroke-width="0.5" />
    <path d="M572.89 135.13H572.78" stroke="black" stroke-width="0.5" />
    <path d="M572.73 135.13H572.62" stroke="black" stroke-width="0.5" />
    <path d="M572.56 135.13H572.45" stroke="black" stroke-width="0.5" />
    <path d="M572.4 135.13H572.29" stroke="black" stroke-width="0.5" />
    <path d="M572.23 135.13H572.12" stroke="black" stroke-width="0.5" />
    <path d="M572.07 135.13H571.96" stroke="black" stroke-width="0.5" />
    <path d="M571.9 135.13H571.79" stroke="black" stroke-width="0.5" />
    <path d="M571.74 135.13H571.63" stroke="black" stroke-width="0.5" />
    <path d="M571.57 135.13H571.46" stroke="black" stroke-width="0.5" />
    <path d="M571.41 135.13H571.3" stroke="black" stroke-width="0.5" />
    <path d="M571.24 135.13H571.13" stroke="black" stroke-width="0.5" />
    <path d="M571.08 135.13H570.97" stroke="black" stroke-width="0.5" />
    <path d="M570.91 135.13H570.8" stroke="black" stroke-width="0.5" />
    <path d="M570.75 135.13H570.64" stroke="black" stroke-width="0.5" />
    <path d="M570.58 135.13H570.47" stroke="black" stroke-width="0.5" />
    <path d="M570.42 135.13H570.31" stroke="black" stroke-width="0.5" />
    <path d="M570.25 135.13H570.14" stroke="black" stroke-width="0.5" />
    <path d="M570.09 135.13H569.98" stroke="black" stroke-width="0.5" />
    <path d="M569.92 135.13H569.81" stroke="black" stroke-width="0.5" />
    <path d="M569.76 135.13H569.65" stroke="black" stroke-width="0.5" />
    <path d="M569.59 135.13H569.48" stroke="black" stroke-width="0.5" />
    <path d="M569.43 135.13H569.32" stroke="black" stroke-width="0.5" />
    <path d="M569.26 135.13H569.15" stroke="black" stroke-width="0.5" />
    <path d="M569.1 135.13H568.99" stroke="black" stroke-width="0.5" />
    <path d="M568.93 135.13H568.82" stroke="black" stroke-width="0.5" />
    <path d="M568.77 135.13H568.66" stroke="black" stroke-width="0.5" />
    <path d="M568.6 135.13H568.49" stroke="black" stroke-width="0.5" />
    <path d="M568.44 135.13H568.33" stroke="black" stroke-width="0.5" />
    <path d="M568.27 135.13H568.16" stroke="black" stroke-width="0.5" />
    <path d="M568.11 135.13H568" stroke="black" stroke-width="0.5" />
    <path d="M567.94 135.13H567.83" stroke="black" stroke-width="0.5" />
    <path d="M567.78 135.13H567.67" stroke="black" stroke-width="0.5" />
    <path d="M567.61 135.13H567.5" stroke="black" stroke-width="0.5" />
    <path d="M567.45 135.13H567.34" stroke="black" stroke-width="0.5" />
    <path d="M567.28 135.13H567.17" stroke="black" stroke-width="0.5" />
    <path d="M567.12 135.13H567.01" stroke="black" stroke-width="0.5" />
    <path d="M566.95 135.13H566.84" stroke="black" stroke-width="0.5" />
    <path d="M566.79 135.13H566.68" stroke="black" stroke-width="0.5" />
    <path d="M566.62 135.13H566.51" stroke="black" stroke-width="0.5" />
    <path d="M566.46 135.13H566.35" stroke="black" stroke-width="0.5" />
    <path d="M566.29 135.13H566.18" stroke="black" stroke-width="0.5" />
    <path d="M566.13 135.13H566.02" stroke="black" stroke-width="0.5" />
    <path d="M565.96 135.13H565.85" stroke="black" stroke-width="0.5" />
    <path d="M565.8 135.13H565.69" stroke="black" stroke-width="0.5" />
    <path d="M565.63 135.13H565.58" stroke="black" stroke-width="0.5" />
    <path d="M202.76 164.07H190" stroke="black" stroke-width="0.5" />
    <path d="M190 164.07V176.83" stroke="black" stroke-width="0.5" />
    <path d="M190 176.83H202.76" stroke="black" stroke-width="0.5" />
    <path d="M202.76 176.83V164.07" stroke="black" stroke-width="0.5" />
    <path d="M202.76 164.07V176.83" stroke="black" stroke-width="0.5" />
    <path d="M202.76 176.83H190" stroke="black" stroke-width="0.5" />
    <path d="M190 176.83V164.07" stroke="black" stroke-width="0.5" />
    <path d="M190 164.07H202.76" stroke="black" stroke-width="0.5" />
    <path d="M188.31 164.73V164.71" stroke="black" stroke-width="0.5" />
    <path d="M188.31 164.65V164.6" stroke="black" stroke-width="0.5" />
    <path d="M188.31 164.54V164.49" stroke="black" stroke-width="0.5" />
    <path d="M188.31 164.43V164.38" stroke="black" stroke-width="0.5" />
    <path d="M188.31 164.32V164.27" stroke="black" stroke-width="0.5" />
    <path d="M188.31 164.21V164.16" stroke="black" stroke-width="0.5" />
    <path d="M188.31 164.1V164.07" stroke="black" stroke-width="0.5" />
    <path d="M190.07 164.73H190.05" stroke="black" stroke-width="0.5" />
    <path d="M189.99 164.73H189.94" stroke="black" stroke-width="0.5" />
    <path d="M189.88 164.73H189.83" stroke="black" stroke-width="0.5" />
    <path d="M189.77 164.73H189.72" stroke="black" stroke-width="0.5" />
    <path d="M189.66 164.73H189.61" stroke="black" stroke-width="0.5" />
    <path d="M189.55 164.73H189.5" stroke="black" stroke-width="0.5" />
    <path d="M189.44 164.73H189.39" stroke="black" stroke-width="0.5" />
    <path d="M189.33 164.73H189.28" stroke="black" stroke-width="0.5" />
    <path d="M189.22 164.73H189.17" stroke="black" stroke-width="0.5" />
    <path d="M189.11 164.73H189.06" stroke="black" stroke-width="0.5" />
    <path d="M189 164.73H188.95" stroke="black" stroke-width="0.5" />
    <path d="M188.89 164.73H188.84" stroke="black" stroke-width="0.5" />
    <path d="M188.78 164.73H188.73" stroke="black" stroke-width="0.5" />
    <path d="M188.67 164.73H188.62" stroke="black" stroke-width="0.5" />
    <path d="M188.56 164.73H188.51" stroke="black" stroke-width="0.5" />
    <path d="M188.45 164.73H188.4" stroke="black" stroke-width="0.5" />
    <path d="M188.34 164.73H188.31" stroke="black" stroke-width="0.5" />
    <path d="M190.07 164.07V164.1" stroke="black" stroke-width="0.5" />
    <path d="M190.07 164.16V164.21" stroke="black" stroke-width="0.5" />
    <path d="M190.07 164.27V164.32" stroke="black" stroke-width="0.5" />
    <path d="M190.07 164.38V164.43" stroke="black" stroke-width="0.5" />
    <path d="M190.07 164.49V164.54" stroke="black" stroke-width="0.5" />
    <path d="M190.07 164.6V164.65" stroke="black" stroke-width="0.5" />
    <path d="M190.07 164.71V164.73" stroke="black" stroke-width="0.5" />
    <path d="M188.75 164.73L188.76 164.8" stroke="black" stroke-width="0.5" />
    <path d="M188.76 164.85L188.77 164.91" stroke="black" stroke-width="0.5" />
    <path d="M188.77 164.96L188.78 165.02" stroke="black" stroke-width="0.5" />
    <path d="M188.79 165.07L188.8 165.12" stroke="black" stroke-width="0.5" />
    <path d="M188.81 165.18L188.82 165.23" stroke="black" stroke-width="0.5" />
    <path d="M188.83 165.28L188.85 165.34" stroke="black" stroke-width="0.5" />
    <path d="M188.86 165.39L188.88 165.44" stroke="black" stroke-width="0.5" />
    <path d="M188.9 165.5L188.91 165.55" stroke="black" stroke-width="0.5" />
    <path d="M188.93 165.6L188.95 165.65" stroke="black" stroke-width="0.5" />
    <path d="M188.97 165.7L188.99 165.75" stroke="black" stroke-width="0.5" />
    <path d="M189.02 165.8L189.04 165.85" stroke="black" stroke-width="0.5" />
    <path d="M189.06 165.9L189.09 165.95" stroke="black" stroke-width="0.5" />
    <path d="M189.12 166L189.15 166.04" stroke="black" stroke-width="0.5" />
    <path d="M189.17 166.09L189.2 166.14" stroke="black" stroke-width="0.5" />
    <path d="M189.23 166.18L189.26 166.22" stroke="black" stroke-width="0.5" />
    <path d="M189.26 166.22V166.23" stroke="black" stroke-width="0.5" />
    <path d="M189.3 166.27L189.33 166.32" stroke="black" stroke-width="0.5" />
    <path d="M189.37 166.36L189.4 166.4" stroke="black" stroke-width="0.5" />
    <path d="M189.43 166.45L189.45 166.47" stroke="black" stroke-width="0.5" />
    <path d="M189.45 166.47L189.47 166.49" stroke="black" stroke-width="0.5" />
    <path d="M189.51 166.53L189.55 166.56" stroke="black" stroke-width="0.5" />
    <path d="M189.59 166.6L189.63 166.64" stroke="black" stroke-width="0.5" />
    <path d="M189.66 166.68L189.68 166.7" stroke="black" stroke-width="0.5" />
    <path d="M189.68 166.7L189.71 166.72" stroke="black" stroke-width="0.5" />
    <path d="M189.75 166.75L189.79 166.78" stroke="black" stroke-width="0.5" />
    <path d="M189.84 166.82L189.88 166.85" stroke="black" stroke-width="0.5" />
    <path d="M189.92 166.89H189.93" stroke="black" stroke-width="0.5" />
    <path d="M189.93 166.89L189.97 166.92" stroke="black" stroke-width="0.5" />
    <path d="M190.01 166.95L190.06 166.97" stroke="black" stroke-width="0.5" />
    <path d="M190.11 167L190.15 167.03" stroke="black" stroke-width="0.5" />
    <path d="M190.2 167.06L190.25 167.08" stroke="black" stroke-width="0.5" />
    <path d="M190.3 167.11L190.35 167.13" stroke="black" stroke-width="0.5" />
    <path d="M190.4 167.15L190.45 167.18" stroke="black" stroke-width="0.5" />
    <path d="M190.5 167.2L190.55 167.22" stroke="black" stroke-width="0.5" />
    <path d="M190.61 167.23L190.66 167.25" stroke="black" stroke-width="0.5" />
    <path d="M190.71 167.27L190.76 167.29" stroke="black" stroke-width="0.5" />
    <path d="M190.81 167.3L190.87 167.31" stroke="black" stroke-width="0.5" />
    <path d="M190.92 167.32L190.98 167.34" stroke="black" stroke-width="0.5" />
    <path d="M191.03 167.35L191.08 167.36" stroke="black" stroke-width="0.5" />
    <path d="M191.14 167.37H191.19" stroke="black" stroke-width="0.5" />
    <path d="M191.25 167.38H191.3" stroke="black" stroke-width="0.5" />
    <path d="M191.36 167.39H191.42" stroke="black" stroke-width="0.5" />
    <path d="M189.63 164.73L189.64 164.79" stroke="black" stroke-width="0.5" />
    <path d="M189.64 164.84V164.9" stroke="black" stroke-width="0.5" />
    <path d="M189.65 164.95V164.99" stroke="black" stroke-width="0.5" />
    <path d="M189.65 164.99L189.66 165.01" stroke="black" stroke-width="0.5" />
    <path d="M189.67 165.06L189.68 165.12" stroke="black" stroke-width="0.5" />
    <path d="M189.69 165.17L189.7 165.22" stroke="black" stroke-width="0.5" />
    <path d="M189.72 165.27L189.74 165.33" stroke="black" stroke-width="0.5" />
    <path d="M189.76 165.38L189.78 165.43" stroke="black" stroke-width="0.5" />
    <path d="M189.8 165.48L189.82 165.53" stroke="black" stroke-width="0.5" />
    <path d="M189.85 165.58L189.88 165.63" stroke="black" stroke-width="0.5" />
    <path d="M189.9 165.67L189.92 165.7" stroke="black" stroke-width="0.5" />
    <path d="M189.92 165.7L189.93 165.72" stroke="black" stroke-width="0.5" />
    <path d="M189.96 165.76L190 165.81" stroke="black" stroke-width="0.5" />
    <path d="M190.03 165.85L190.06 165.9" stroke="black" stroke-width="0.5" />
    <path d="M190.1 165.94L190.14 165.97" stroke="black" stroke-width="0.5" />
    <path d="M190.18 166.01L190.22 166.05" stroke="black" stroke-width="0.5" />
    <path d="M190.26 166.09L190.3 166.12" stroke="black" stroke-width="0.5" />
    <path d="M190.35 166.16L190.39 166.19" stroke="black" stroke-width="0.5" />
    <path d="M190.43 166.22L190.45 166.24" stroke="black" stroke-width="0.5" />
    <path d="M190.45 166.24L190.48 166.25" stroke="black" stroke-width="0.5" />
    <path d="M190.53 166.28L190.58 166.3" stroke="black" stroke-width="0.5" />
    <path d="M190.62 166.33L190.67 166.36" stroke="black" stroke-width="0.5" />
    <path d="M190.72 166.38L190.78 166.4" stroke="black" stroke-width="0.5" />
    <path d="M190.83 166.42L190.88 166.43" stroke="black" stroke-width="0.5" />
    <path d="M190.93 166.45L190.98 166.46" stroke="black" stroke-width="0.5" />
    <path d="M191.04 166.47L191.09 166.49" stroke="black" stroke-width="0.5" />
    <path d="M191.15 166.5H191.17" stroke="black" stroke-width="0.5" />
    <path d="M191.17 166.5L191.2 166.51" stroke="black" stroke-width="0.5" />
    <path d="M191.26 166.51H191.31" stroke="black" stroke-width="0.5" />
    <path d="M191.36 166.52H191.42" stroke="black" stroke-width="0.5" />
    <path d="M191.42 166.52H201.98" stroke="black" stroke-width="0.5" />
    <path d="M191.42 167.39H201.98" stroke="black" stroke-width="0.5" />
    <path d="M205.08 164.73V164.71" stroke="black" stroke-width="0.5" />
    <path d="M205.08 164.65V164.6" stroke="black" stroke-width="0.5" />
    <path d="M205.08 164.54V164.49" stroke="black" stroke-width="0.5" />
    <path d="M205.08 164.43V164.38" stroke="black" stroke-width="0.5" />
    <path d="M205.08 164.32V164.27" stroke="black" stroke-width="0.5" />
    <path d="M205.08 164.21V164.16" stroke="black" stroke-width="0.5" />
    <path d="M205.08 164.1V164.07" stroke="black" stroke-width="0.5" />
    <path d="M203.32 164.73H203.35" stroke="black" stroke-width="0.5" />
    <path d="M203.41 164.73H203.46" stroke="black" stroke-width="0.5" />
    <path d="M203.52 164.73H203.57" stroke="black" stroke-width="0.5" />
    <path d="M203.63 164.73H203.68" stroke="black" stroke-width="0.5" />
    <path d="M203.74 164.73H203.79" stroke="black" stroke-width="0.5" />
    <path d="M203.85 164.73H203.9" stroke="black" stroke-width="0.5" />
    <path d="M203.96 164.73H204.01" stroke="black" stroke-width="0.5" />
    <path d="M204.07 164.73H204.12" stroke="black" stroke-width="0.5" />
    <path d="M204.18 164.73H204.23" stroke="black" stroke-width="0.5" />
    <path d="M204.29 164.73H204.34" stroke="black" stroke-width="0.5" />
    <path d="M204.4 164.73H204.45" stroke="black" stroke-width="0.5" />
    <path d="M204.51 164.73H204.56" stroke="black" stroke-width="0.5" />
    <path d="M204.62 164.73H204.67" stroke="black" stroke-width="0.5" />
    <path d="M204.73 164.73H204.78" stroke="black" stroke-width="0.5" />
    <path d="M204.84 164.73H204.89" stroke="black" stroke-width="0.5" />
    <path d="M204.95 164.73H205" stroke="black" stroke-width="0.5" />
    <path d="M205.06 164.73H205.08" stroke="black" stroke-width="0.5" />
    <path d="M203.32 164.07V164.1" stroke="black" stroke-width="0.5" />
    <path d="M203.32 164.16V164.21" stroke="black" stroke-width="0.5" />
    <path d="M203.32 164.27V164.32" stroke="black" stroke-width="0.5" />
    <path d="M203.32 164.38V164.43" stroke="black" stroke-width="0.5" />
    <path d="M203.32 164.49V164.54" stroke="black" stroke-width="0.5" />
    <path d="M203.32 164.6V164.65" stroke="black" stroke-width="0.5" />
    <path d="M203.32 164.71V164.73" stroke="black" stroke-width="0.5" />
    <path d="M201.98 167.39H202.04" stroke="black" stroke-width="0.5" />
    <path d="M202.09 167.38H202.15" stroke="black" stroke-width="0.5" />
    <path d="M202.2 167.37H202.26" stroke="black" stroke-width="0.5" />
    <path d="M202.31 167.36L202.37 167.35" stroke="black" stroke-width="0.5" />
    <path d="M202.42 167.34L202.47 167.32" stroke="black" stroke-width="0.5" />
    <path d="M202.53 167.31L202.58 167.3" stroke="black" stroke-width="0.5" />
    <path d="M202.63 167.29L202.69 167.27" stroke="black" stroke-width="0.5" />
    <path d="M202.74 167.25L202.79 167.23" stroke="black" stroke-width="0.5" />
    <path d="M202.84 167.22L202.9 167.2" stroke="black" stroke-width="0.5" />
    <path d="M202.95 167.18L203 167.15" stroke="black" stroke-width="0.5" />
    <path d="M203.05 167.13L203.1 167.11" stroke="black" stroke-width="0.5" />
    <path d="M203.14 167.08L203.19 167.06" stroke="black" stroke-width="0.5" />
    <path d="M203.24 167.03L203.29 167" stroke="black" stroke-width="0.5" />
    <path d="M203.33 166.97L203.38 166.95" stroke="black" stroke-width="0.5" />
    <path d="M203.43 166.92L203.47 166.89" stroke="black" stroke-width="0.5" />
    <path d="M203.52 166.85L203.56 166.82" stroke="black" stroke-width="0.5" />
    <path d="M203.6 166.78L203.65 166.75" stroke="black" stroke-width="0.5" />
    <path d="M203.69 166.72L203.72 166.7" stroke="black" stroke-width="0.5" />
    <path d="M203.72 166.7L203.73 166.68" stroke="black" stroke-width="0.5" />
    <path d="M203.77 166.64L203.81 166.6" stroke="black" stroke-width="0.5" />
    <path d="M203.85 166.56L203.89 166.53" stroke="black" stroke-width="0.5" />
    <path d="M203.93 166.49L203.94 166.47" stroke="black" stroke-width="0.5" />
    <path d="M203.94 166.47L203.96 166.45" stroke="black" stroke-width="0.5" />
    <path d="M204 166.4L204.03 166.36" stroke="black" stroke-width="0.5" />
    <path d="M204.07 166.32L204.1 166.27" stroke="black" stroke-width="0.5" />
    <path d="M204.13 166.23L204.14 166.22" stroke="black" stroke-width="0.5" />
    <path d="M204.14 166.22L204.16 166.18" stroke="black" stroke-width="0.5" />
    <path d="M204.19 166.14L204.22 166.09" stroke="black" stroke-width="0.5" />
    <path d="M204.25 166.04L204.28 166" stroke="black" stroke-width="0.5" />
    <path d="M204.31 165.95L204.33 165.9" stroke="black" stroke-width="0.5" />
    <path d="M204.36 165.85L204.38 165.8" stroke="black" stroke-width="0.5" />
    <path d="M204.4 165.75L204.43 165.7" stroke="black" stroke-width="0.5" />
    <path d="M204.45 165.65L204.47 165.6" stroke="black" stroke-width="0.5" />
    <path d="M204.48 165.55L204.5 165.5" stroke="black" stroke-width="0.5" />
    <path d="M204.52 165.44L204.54 165.39" stroke="black" stroke-width="0.5" />
    <path d="M204.55 165.34L204.56 165.28" stroke="black" stroke-width="0.5" />
    <path d="M204.57 165.23L204.59 165.18" stroke="black" stroke-width="0.5" />
    <path d="M204.6 165.12L204.61 165.07" stroke="black" stroke-width="0.5" />
    <path d="M204.62 165.02V164.96" stroke="black" stroke-width="0.5" />
    <path d="M204.63 164.91V164.85" stroke="black" stroke-width="0.5" />
    <path d="M204.64 164.8V164.73" stroke="black" stroke-width="0.5" />
    <path d="M201.98 166.52H202.03" stroke="black" stroke-width="0.5" />
    <path d="M202.09 166.51H202.14" stroke="black" stroke-width="0.5" />
    <path d="M202.2 166.51L202.23 166.5" stroke="black" stroke-width="0.5" />
    <path d="M202.23 166.5H202.25" stroke="black" stroke-width="0.5" />
    <path d="M202.3 166.49L202.36 166.47" stroke="black" stroke-width="0.5" />
    <path d="M202.41 166.46L202.47 166.45" stroke="black" stroke-width="0.5" />
    <path d="M202.52 166.43L202.57 166.42" stroke="black" stroke-width="0.5" />
    <path d="M202.62 166.4L202.67 166.38" stroke="black" stroke-width="0.5" />
    <path d="M202.72 166.36L202.77 166.33" stroke="black" stroke-width="0.5" />
    <path d="M202.82 166.3L202.87 166.28" stroke="black" stroke-width="0.5" />
    <path d="M202.92 166.25L202.94 166.24" stroke="black" stroke-width="0.5" />
    <path d="M202.94 166.24L202.96 166.22" stroke="black" stroke-width="0.5" />
    <path d="M203.01 166.19L203.05 166.16" stroke="black" stroke-width="0.5" />
    <path d="M203.09 166.12L203.14 166.09" stroke="black" stroke-width="0.5" />
    <path d="M203.18 166.05L203.22 166.01" stroke="black" stroke-width="0.5" />
    <path d="M203.26 165.97L203.3 165.94" stroke="black" stroke-width="0.5" />
    <path d="M203.33 165.9L203.37 165.85" stroke="black" stroke-width="0.5" />
    <path d="M203.4 165.81L203.43 165.76" stroke="black" stroke-width="0.5" />
    <path d="M203.46 165.72L203.48 165.7" stroke="black" stroke-width="0.5" />
    <path d="M203.48 165.7L203.49 165.67" stroke="black" stroke-width="0.5" />
    <path d="M203.52 165.63L203.55 165.58" stroke="black" stroke-width="0.5" />
    <path d="M203.57 165.53L203.6 165.48" stroke="black" stroke-width="0.5" />
    <path d="M203.62 165.43L203.64 165.38" stroke="black" stroke-width="0.5" />
    <path d="M203.66 165.33L203.68 165.27" stroke="black" stroke-width="0.5" />
    <path d="M203.69 165.22L203.71 165.17" stroke="black" stroke-width="0.5" />
    <path d="M203.72 165.12L203.73 165.06" stroke="black" stroke-width="0.5" />
    <path d="M203.74 165.01L203.75 164.99" stroke="black" stroke-width="0.5" />
    <path d="M203.75 164.99V164.95" stroke="black" stroke-width="0.5" />
    <path d="M203.75 164.9L203.76 164.84" stroke="black" stroke-width="0.5" />
    <path d="M203.76 164.79V164.73" stroke="black" stroke-width="0.5" />
    <path d="M506.75 90.05V74.22" stroke="black" stroke-width="0.5" />
    <path d="M510.71 74.22V90.05" stroke="black" stroke-width="0.5" />
    <path d="M510.71 90.05V74.22" stroke="black" stroke-width="0.5" />
    <path d="M506.75 74.22H510.71" stroke="black" stroke-width="0.5" />
    <path d="M510.71 74.22H510.27" stroke="black" stroke-width="0.5" />
    <path d="M510.71 90.05H510.27" stroke="black" stroke-width="0.5" />
    <path d="M510.71 90.05H506.75" stroke="black" stroke-width="0.5" />
    <path d="M510.71 88.29V75.98" stroke="black" stroke-width="0.5" />
    <path d="M510.71 75.98V88.29" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.94V68.97" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.02V69.08" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.13V69.19" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.24V69.3" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.35V69.41" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.46V69.52" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.57V69.62" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.68V69.73" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.79V69.84" stroke="black" stroke-width="0.5" />
    <path d="M541.94 69.9V69.95" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.01V70.06" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.12V70.17" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.23V70.28" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.34V70.39" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.45V70.5" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.56V70.61" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.67V70.72" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.78V70.83" stroke="black" stroke-width="0.5" />
    <path d="M541.94 70.89V70.94" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71V71.05" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71.11V71.16" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71.22V71.27" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71.33V71.38" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71.44V71.49" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71.55V71.6" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71.66V71.71" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71.77V71.82" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71.88V71.93" stroke="black" stroke-width="0.5" />
    <path d="M541.94 71.99V72.04" stroke="black" stroke-width="0.5" />
    <path d="M541.94 72.1V72.15" stroke="black" stroke-width="0.5" />
    <path d="M541.94 72.21V72.26" stroke="black" stroke-width="0.5" />
    <path d="M541.94 72.32V72.37" stroke="black" stroke-width="0.5" />
    <path d="M541.94 72.43V72.48" stroke="black" stroke-width="0.5" />
    <path d="M541.94 72.54V72.59" stroke="black" stroke-width="0.5" />
    <path d="M541.94 72.65V72.7" stroke="black" stroke-width="0.5" />
    <path d="M541.94 72.76V72.81" stroke="black" stroke-width="0.5" />
    <path d="M541.94 72.87V72.92" stroke="black" stroke-width="0.5" />
    <path d="M541.94 72.98V73.03" stroke="black" stroke-width="0.5" />
    <path d="M541.94 73.09V73.14" stroke="black" stroke-width="0.5" />
    <path d="M541.94 73.2V73.25" stroke="black" stroke-width="0.5" />
    <path d="M541.94 73.31V73.36" stroke="black" stroke-width="0.5" />
    <path d="M541.94 73.42V73.47" stroke="black" stroke-width="0.5" />
    <path d="M541.94 73.53V73.58" stroke="black" stroke-width="0.5" />
    <path d="M541.94 73.64V73.69" stroke="black" stroke-width="0.5" />
    <path d="M541.94 73.75V73.8" stroke="black" stroke-width="0.5" />
    <path d="M541.94 73.86V73.91" stroke="black" stroke-width="0.5" />
    <path d="M541.94 73.97V74.02" stroke="black" stroke-width="0.5" />
    <path d="M541.94 74.08V74.13" stroke="black" stroke-width="0.5" />
    <path d="M541.94 74.19V74.24" stroke="black" stroke-width="0.5" />
    <path d="M541.94 74.3V74.35" stroke="black" stroke-width="0.5" />
    <path d="M541.94 74.41V74.46" stroke="black" stroke-width="0.5" />
    <path d="M541.94 74.52V74.57" stroke="black" stroke-width="0.5" />
    <path d="M541.94 74.63V74.68" stroke="black" stroke-width="0.5" />
    <path d="M541.94 74.74V74.79" stroke="black" stroke-width="0.5" />
    <path d="M541.94 74.85V74.9" stroke="black" stroke-width="0.5" />
    <path d="M541.94 74.96V75.01" stroke="black" stroke-width="0.5" />
    <path d="M541.94 75.07V75.12" stroke="black" stroke-width="0.5" />
    <path d="M541.94 75.18V75.23" stroke="black" stroke-width="0.5" />
    <path d="M541.94 75.29V75.34" stroke="black" stroke-width="0.5" />
    <path d="M541.94 75.4V75.45" stroke="black" stroke-width="0.5" />
    <path d="M541.94 75.51V75.56" stroke="black" stroke-width="0.5" />
    <path d="M541.94 75.62V75.67" stroke="black" stroke-width="0.5" />
    <path d="M541.94 75.73V75.78" stroke="black" stroke-width="0.5" />
    <path d="M541.94 75.84V75.89" stroke="black" stroke-width="0.5" />
    <path d="M541.94 75.95V76" stroke="black" stroke-width="0.5" />
    <path d="M541.94 76.06V76.11" stroke="black" stroke-width="0.5" />
    <path d="M541.94 76.17V76.22" stroke="black" stroke-width="0.5" />
    <path d="M541.94 76.28V76.33" stroke="black" stroke-width="0.5" />
    <path d="M541.94 76.39V76.44" stroke="black" stroke-width="0.5" />
    <path d="M541.94 76.5V76.55" stroke="black" stroke-width="0.5" />
    <path d="M541.94 76.61V76.66" stroke="black" stroke-width="0.5" />
    <path d="M541.94 76.72V76.77" stroke="black" stroke-width="0.5" />
    <path d="M541.94 76.83V76.88" stroke="black" stroke-width="0.5" />
    <path d="M541.94 76.94V76.99" stroke="black" stroke-width="0.5" />
    <path d="M541.94 77.05V77.1" stroke="black" stroke-width="0.5" />
    <path d="M541.94 77.16V77.21" stroke="black" stroke-width="0.5" />
    <path d="M541.94 77.27V77.32" stroke="black" stroke-width="0.5" />
    <path d="M541.94 77.38V77.43" stroke="black" stroke-width="0.5" />
    <path d="M541.94 77.49V77.54" stroke="black" stroke-width="0.5" />
    <path d="M541.94 77.6V77.65" stroke="black" stroke-width="0.5" />
    <path d="M541.94 77.71V77.76" stroke="black" stroke-width="0.5" />
    <path d="M541.94 77.82V77.87" stroke="black" stroke-width="0.5" />
    <path d="M541.94 77.93V77.98" stroke="black" stroke-width="0.5" />
    <path d="M541.94 78.04V78.09" stroke="black" stroke-width="0.5" />
    <path d="M541.94 78.15V78.2" stroke="black" stroke-width="0.5" />
    <path d="M541.94 78.26V78.31" stroke="black" stroke-width="0.5" />
    <path d="M541.94 78.37V78.42" stroke="black" stroke-width="0.5" />
    <path d="M541.94 78.48V78.53" stroke="black" stroke-width="0.5" />
    <path d="M541.94 78.59V78.64" stroke="black" stroke-width="0.5" />
    <path d="M541.94 78.7V78.75" stroke="black" stroke-width="0.5" />
    <path d="M541.94 78.81V78.86" stroke="black" stroke-width="0.5" />
    <path d="M541.94 78.92V78.97" stroke="black" stroke-width="0.5" />
    <path d="M541.94 79.03V79.08" stroke="black" stroke-width="0.5" />
    <path d="M541.94 79.14V79.19" stroke="black" stroke-width="0.5" />
    <path d="M541.94 79.25V79.3" stroke="black" stroke-width="0.5" />
    <path d="M541.94 79.36V79.41" stroke="black" stroke-width="0.5" />
    <path d="M541.94 79.47V79.52" stroke="black" stroke-width="0.5" />
    <path d="M541.94 79.58V79.63" stroke="black" stroke-width="0.5" />
    <path d="M541.94 79.69V79.74" stroke="black" stroke-width="0.5" />
    <path d="M541.94 79.8V79.85" stroke="black" stroke-width="0.5" />
    <path d="M541.94 79.91V79.96" stroke="black" stroke-width="0.5" />
    <path d="M541.94 80.02V80.07" stroke="black" stroke-width="0.5" />
    <path d="M541.94 80.13V80.18" stroke="black" stroke-width="0.5" />
    <path d="M541.94 80.24V80.29" stroke="black" stroke-width="0.5" />
    <path d="M541.94 80.35V80.4" stroke="black" stroke-width="0.5" />
    <path d="M541.94 80.46V80.51" stroke="black" stroke-width="0.5" />
    <path d="M541.94 80.57V80.62" stroke="black" stroke-width="0.5" />
    <path d="M541.94 80.68V80.73" stroke="black" stroke-width="0.5" />
    <path d="M541.94 80.79V80.84" stroke="black" stroke-width="0.5" />
    <path d="M541.94 80.9V80.95" stroke="black" stroke-width="0.5" />
    <path d="M541.94 81.01V81.06" stroke="black" stroke-width="0.5" />
    <path d="M541.94 81.12V81.17" stroke="black" stroke-width="0.5" />
    <path d="M541.94 81.23V81.28" stroke="black" stroke-width="0.5" />
    <path d="M541.94 81.34V81.39" stroke="black" stroke-width="0.5" />
    <path d="M541.94 81.45V81.5" stroke="black" stroke-width="0.5" />
    <path d="M541.94 81.56V81.61" stroke="black" stroke-width="0.5" />
    <path d="M541.94 81.67V81.72" stroke="black" stroke-width="0.5" />
    <path d="M541.94 81.78V81.83" stroke="black" stroke-width="0.5" />
    <path d="M541.94 81.89V81.94" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82V82.05" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82.11V82.16" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82.22V82.27" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82.33V82.38" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82.44V82.49" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82.54V82.6" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82.65V82.71" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82.76V82.82" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82.87V82.93" stroke="black" stroke-width="0.5" />
    <path d="M541.94 82.98V83.04" stroke="black" stroke-width="0.5" />
    <path d="M541.94 83.09V83.15" stroke="black" stroke-width="0.5" />
    <path d="M541.94 83.2V83.26" stroke="black" stroke-width="0.5" />
    <path d="M541.94 83.31V83.37" stroke="black" stroke-width="0.5" />
    <path d="M541.94 83.42V83.48" stroke="black" stroke-width="0.5" />
    <path d="M541.94 83.53V83.59" stroke="black" stroke-width="0.5" />
    <path d="M541.94 83.64V83.7" stroke="black" stroke-width="0.5" />
    <path d="M541.94 83.75V83.81" stroke="black" stroke-width="0.5" />
    <path d="M541.94 83.86V83.92" stroke="black" stroke-width="0.5" />
    <path d="M541.94 83.97V84.03" stroke="black" stroke-width="0.5" />
    <path d="M541.94 84.08V84.14" stroke="black" stroke-width="0.5" />
    <path d="M541.94 84.19V84.25" stroke="black" stroke-width="0.5" />
    <path d="M541.94 84.3V84.36" stroke="black" stroke-width="0.5" />
    <path d="M541.94 84.41V84.47" stroke="black" stroke-width="0.5" />
    <path d="M541.94 84.52V84.58" stroke="black" stroke-width="0.5" />
    <path d="M541.94 84.63V84.69" stroke="black" stroke-width="0.5" />
    <path d="M541.94 84.74V84.8" stroke="black" stroke-width="0.5" />
    <path d="M541.94 84.85V84.91" stroke="black" stroke-width="0.5" />
    <path d="M541.94 84.96V85.02" stroke="black" stroke-width="0.5" />
    <path d="M541.94 85.07V85.13" stroke="black" stroke-width="0.5" />
    <path d="M541.94 85.18V85.24" stroke="black" stroke-width="0.5" />
    <path d="M541.94 85.29V85.35" stroke="black" stroke-width="0.5" />
    <path d="M541.94 85.4V85.46" stroke="black" stroke-width="0.5" />
    <path d="M541.94 85.51V85.57" stroke="black" stroke-width="0.5" />
    <path d="M541.94 85.62V85.68" stroke="black" stroke-width="0.5" />
    <path d="M541.94 85.73V85.79" stroke="black" stroke-width="0.5" />
    <path d="M541.94 85.84V85.9" stroke="black" stroke-width="0.5" />
    <path d="M541.94 85.95V86.01" stroke="black" stroke-width="0.5" />
    <path d="M541.94 86.06V86.12" stroke="black" stroke-width="0.5" />
    <path d="M541.94 86.17V86.23" stroke="black" stroke-width="0.5" />
    <path d="M541.94 86.28V86.34" stroke="black" stroke-width="0.5" />
    <path d="M541.94 86.39V86.45" stroke="black" stroke-width="0.5" />
    <path d="M541.94 86.5V86.56" stroke="black" stroke-width="0.5" />
    <path d="M541.94 86.61V86.67" stroke="black" stroke-width="0.5" />
    <path d="M541.94 86.72V86.78" stroke="black" stroke-width="0.5" />
    <path d="M541.94 86.83V86.89" stroke="black" stroke-width="0.5" />
    <path d="M541.94 86.94V87" stroke="black" stroke-width="0.5" />
    <path d="M541.94 87.05V87.11" stroke="black" stroke-width="0.5" />
    <path d="M541.94 87.16V87.22" stroke="black" stroke-width="0.5" />
    <path d="M541.94 87.27V87.33" stroke="black" stroke-width="0.5" />
    <path d="M541.94 87.38V87.44" stroke="black" stroke-width="0.5" />
    <path d="M541.94 87.49V87.55" stroke="black" stroke-width="0.5" />
    <path d="M541.94 87.6V87.66" stroke="black" stroke-width="0.5" />
    <path d="M541.94 87.71V87.77" stroke="black" stroke-width="0.5" />
    <path d="M541.94 87.82V87.88" stroke="black" stroke-width="0.5" />
    <path d="M541.94 87.93V87.99" stroke="black" stroke-width="0.5" />
    <path d="M541.94 88.04V88.1" stroke="black" stroke-width="0.5" />
    <path d="M541.94 88.15V88.21" stroke="black" stroke-width="0.5" />
    <path d="M541.94 88.26V88.32" stroke="black" stroke-width="0.5" />
    <path d="M541.94 88.37V88.43" stroke="black" stroke-width="0.5" />
    <path d="M541.94 88.48V88.54" stroke="black" stroke-width="0.5" />
    <path d="M541.94 88.59V88.65" stroke="black" stroke-width="0.5" />
    <path d="M541.94 88.7V88.76" stroke="black" stroke-width="0.5" />
    <path d="M541.94 88.81V88.87" stroke="black" stroke-width="0.5" />
    <path d="M541.94 88.92V88.98" stroke="black" stroke-width="0.5" />
    <path d="M541.94 89.03V89.09" stroke="black" stroke-width="0.5" />
    <path d="M541.94 89.14V89.2" stroke="black" stroke-width="0.5" />
    <path d="M541.94 89.25V89.31" stroke="black" stroke-width="0.5" />
    <path d="M541.94 89.36V89.42" stroke="black" stroke-width="0.5" />
    <path d="M541.94 89.47V89.53" stroke="black" stroke-width="0.5" />
    <path d="M541.94 89.58V89.64" stroke="black" stroke-width="0.5" />
    <path d="M541.94 89.69V89.75" stroke="black" stroke-width="0.5" />
    <path d="M541.94 89.8V89.86" stroke="black" stroke-width="0.5" />
    <path d="M541.94 89.91V89.97" stroke="black" stroke-width="0.5" />
    <path d="M541.94 90.02V90.08" stroke="black" stroke-width="0.5" />
    <path d="M541.94 90.13V90.19" stroke="black" stroke-width="0.5" />
    <path d="M541.94 90.24V90.3" stroke="black" stroke-width="0.5" />
    <path d="M541.94 90.35V90.41" stroke="black" stroke-width="0.5" />
    <path d="M541.94 90.46V90.52" stroke="black" stroke-width="0.5" />
    <path d="M541.94 90.57V90.63" stroke="black" stroke-width="0.5" />
    <path d="M541.94 90.68V90.74" stroke="black" stroke-width="0.5" />
    <path d="M541.94 90.79V90.85" stroke="black" stroke-width="0.5" />
    <path d="M541.94 90.9V90.96" stroke="black" stroke-width="0.5" />
    <path d="M541.94 91.01V91.07" stroke="black" stroke-width="0.5" />
    <path d="M541.94 91.12V91.18" stroke="black" stroke-width="0.5" />
    <path d="M541.94 91.23V91.29" stroke="black" stroke-width="0.5" />
    <path d="M541.94 91.34V91.4" stroke="black" stroke-width="0.5" />
    <path d="M541.94 91.45V91.51" stroke="black" stroke-width="0.5" />
    <path d="M541.94 91.56V91.62" stroke="black" stroke-width="0.5" />
    <path d="M541.94 91.67V91.73" stroke="black" stroke-width="0.5" />
    <path d="M541.94 91.78V91.84" stroke="black" stroke-width="0.5" />
    <path d="M541.94 91.89V91.95" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92V92.06" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92.11V92.17" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92.22V92.28" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92.33V92.39" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92.44V92.5" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92.55V92.61" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92.66V92.72" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92.77V92.83" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92.88V92.94" stroke="black" stroke-width="0.5" />
    <path d="M541.94 92.99V93.05" stroke="black" stroke-width="0.5" />
    <path d="M541.94 93.1V93.16" stroke="black" stroke-width="0.5" />
    <path d="M541.94 93.21V93.27" stroke="black" stroke-width="0.5" />
    <path d="M541.94 93.32V93.38" stroke="black" stroke-width="0.5" />
    <path d="M541.94 93.43V93.49" stroke="black" stroke-width="0.5" />
    <path d="M541.94 93.54V93.6" stroke="black" stroke-width="0.5" />
    <path d="M541.94 93.65V93.71" stroke="black" stroke-width="0.5" />
    <path d="M541.94 93.76V93.82" stroke="black" stroke-width="0.5" />
    <path d="M541.94 93.87V93.93" stroke="black" stroke-width="0.5" />
    <path d="M541.94 93.98V94.04" stroke="black" stroke-width="0.5" />
    <path d="M541.94 94.09V94.15" stroke="black" stroke-width="0.5" />
    <path d="M541.94 94.2V94.26" stroke="black" stroke-width="0.5" />
    <path d="M541.94 94.31V94.37" stroke="black" stroke-width="0.5" />
    <path d="M541.94 94.42V94.48" stroke="black" stroke-width="0.5" />
    <path d="M541.94 94.53V94.59" stroke="black" stroke-width="0.5" />
    <path d="M541.94 94.64V94.7" stroke="black" stroke-width="0.5" />
    <path d="M541.94 94.75V94.81" stroke="black" stroke-width="0.5" />
    <path d="M541.94 94.86V94.92" stroke="black" stroke-width="0.5" />
    <path d="M541.94 94.97V95.03" stroke="black" stroke-width="0.5" />
    <path d="M541.94 95.08V95.14" stroke="black" stroke-width="0.5" />
    <path d="M541.94 95.19V95.25" stroke="black" stroke-width="0.5" />
    <path d="M541.94 95.3V95.33" stroke="black" stroke-width="0.5" />
    <path d="M506.75 74.22V90.05" stroke="black" stroke-width="0.5" />
    <path d="M506.75 90.05H510.27" stroke="black" stroke-width="0.5" />
    <path d="M510.27 90.05V74.22" stroke="black" stroke-width="0.5" />
    <path d="M510.27 74.22H506.75" stroke="black" stroke-width="0.5" />
    <path d="M541.94 68.94H541.91" stroke="black" stroke-width="0.5" />
    <path d="M541.85 68.94H541.8" stroke="black" stroke-width="0.5" />
    <path d="M541.74 68.94H541.69" stroke="black" stroke-width="0.5" />
    <path d="M541.63 68.94H541.58" stroke="black" stroke-width="0.5" />
    <path d="M541.52 68.94H541.47" stroke="black" stroke-width="0.5" />
    <path d="M541.42 68.94H541.36" stroke="black" stroke-width="0.5" />
    <path d="M541.31 68.94H541.25" stroke="black" stroke-width="0.5" />
    <path d="M541.2 68.94H541.14" stroke="black" stroke-width="0.5" />
    <path d="M541.09 68.94H541.03" stroke="black" stroke-width="0.5" />
    <path d="M540.98 68.94H540.92" stroke="black" stroke-width="0.5" />
    <path d="M540.87 68.94H540.81" stroke="black" stroke-width="0.5" />
    <path d="M540.76 68.94H540.7" stroke="black" stroke-width="0.5" />
    <path d="M540.65 68.94H540.59" stroke="black" stroke-width="0.5" />
    <path d="M540.54 68.94H540.48" stroke="black" stroke-width="0.5" />
    <path d="M540.43 68.94H540.37" stroke="black" stroke-width="0.5" />
    <path d="M540.32 68.94H540.26" stroke="black" stroke-width="0.5" />
    <path d="M540.21 68.94H540.15" stroke="black" stroke-width="0.5" />
    <path d="M540.1 68.94H540.04" stroke="black" stroke-width="0.5" />
    <path d="M539.99 68.94H539.93" stroke="black" stroke-width="0.5" />
    <path d="M539.88 68.94H539.82" stroke="black" stroke-width="0.5" />
    <path d="M539.77 68.94H539.71" stroke="black" stroke-width="0.5" />
    <path d="M539.66 68.94H539.6" stroke="black" stroke-width="0.5" />
    <path d="M539.55 68.94H539.49" stroke="black" stroke-width="0.5" />
    <path d="M539.44 68.94H539.38" stroke="black" stroke-width="0.5" />
    <path d="M539.33 68.94H539.27" stroke="black" stroke-width="0.5" />
    <path d="M539.22 68.94H539.16" stroke="black" stroke-width="0.5" />
    <path d="M539.11 68.94H539.05" stroke="black" stroke-width="0.5" />
    <path d="M539 68.94H538.94" stroke="black" stroke-width="0.5" />
    <path d="M538.89 68.94H538.83" stroke="black" stroke-width="0.5" />
    <path d="M538.78 68.94H538.72" stroke="black" stroke-width="0.5" />
    <path d="M538.67 68.94H538.61" stroke="black" stroke-width="0.5" />
    <path d="M538.56 68.94H538.5" stroke="black" stroke-width="0.5" />
    <path d="M538.45 68.94H538.39" stroke="black" stroke-width="0.5" />
    <path d="M538.34 68.94H538.28" stroke="black" stroke-width="0.5" />
    <path d="M538.23 68.94H538.17" stroke="black" stroke-width="0.5" />
    <path d="M538.12 68.94H538.06" stroke="black" stroke-width="0.5" />
    <path d="M538.01 68.94H537.95" stroke="black" stroke-width="0.5" />
    <path d="M537.9 68.94H537.84" stroke="black" stroke-width="0.5" />
    <path d="M537.79 68.94H537.73" stroke="black" stroke-width="0.5" />
    <path d="M537.68 68.94H537.62" stroke="black" stroke-width="0.5" />
    <path d="M537.57 68.94H537.51" stroke="black" stroke-width="0.5" />
    <path d="M537.46 68.94H537.4" stroke="black" stroke-width="0.5" />
    <path d="M537.35 68.94H537.29" stroke="black" stroke-width="0.5" />
    <path d="M537.24 68.94H537.18" stroke="black" stroke-width="0.5" />
    <path d="M537.13 68.94H537.07" stroke="black" stroke-width="0.5" />
    <path d="M537.02 68.94H536.96" stroke="black" stroke-width="0.5" />
    <path d="M536.91 68.94H536.85" stroke="black" stroke-width="0.5" />
    <path d="M536.8 68.94H536.74" stroke="black" stroke-width="0.5" />
    <path d="M536.69 68.94H536.63" stroke="black" stroke-width="0.5" />
    <path d="M536.58 68.94H536.52" stroke="black" stroke-width="0.5" />
    <path d="M536.47 68.94H536.41" stroke="black" stroke-width="0.5" />
    <path d="M536.36 68.94H536.3" stroke="black" stroke-width="0.5" />
    <path d="M536.25 68.94H536.19" stroke="black" stroke-width="0.5" />
    <path d="M536.14 68.94H536.08" stroke="black" stroke-width="0.5" />
    <path d="M536.03 68.94H535.97" stroke="black" stroke-width="0.5" />
    <path d="M535.92 68.94H535.86" stroke="black" stroke-width="0.5" />
    <path d="M535.81 68.94H535.75" stroke="black" stroke-width="0.5" />
    <path d="M535.7 68.94H535.64" stroke="black" stroke-width="0.5" />
    <path d="M535.59 68.94H535.53" stroke="black" stroke-width="0.5" />
    <path d="M535.48 68.94H535.42" stroke="black" stroke-width="0.5" />
    <path d="M535.37 68.94H535.31" stroke="black" stroke-width="0.5" />
    <path d="M535.26 68.94H535.2" stroke="black" stroke-width="0.5" />
    <path d="M535.15 68.94H535.09" stroke="black" stroke-width="0.5" />
    <path d="M535.04 68.94H534.98" stroke="black" stroke-width="0.5" />
    <path d="M534.93 68.94H534.87" stroke="black" stroke-width="0.5" />
    <path d="M534.82 68.94H534.76" stroke="black" stroke-width="0.5" />
    <path d="M534.71 68.94H534.65" stroke="black" stroke-width="0.5" />
    <path d="M534.6 68.94H534.54" stroke="black" stroke-width="0.5" />
    <path d="M534.49 68.94H534.43" stroke="black" stroke-width="0.5" />
    <path d="M534.38 68.94H534.32" stroke="black" stroke-width="0.5" />
    <path d="M534.27 68.94H534.21" stroke="black" stroke-width="0.5" />
    <path d="M534.16 68.94H534.1" stroke="black" stroke-width="0.5" />
    <path d="M534.05 68.94H533.99" stroke="black" stroke-width="0.5" />
    <path d="M533.94 68.94H533.88" stroke="black" stroke-width="0.5" />
    <path d="M533.83 68.94H533.77" stroke="black" stroke-width="0.5" />
    <path d="M533.72 68.94H533.66" stroke="black" stroke-width="0.5" />
    <path d="M533.61 68.94H533.55" stroke="black" stroke-width="0.5" />
    <path d="M533.5 68.94H533.44" stroke="black" stroke-width="0.5" />
    <path d="M533.39 68.94H533.33" stroke="black" stroke-width="0.5" />
    <path d="M533.28 68.94H533.22" stroke="black" stroke-width="0.5" />
    <path d="M533.17 68.94H533.11" stroke="black" stroke-width="0.5" />
    <path d="M533.06 68.94H533" stroke="black" stroke-width="0.5" />
    <path d="M532.95 68.94H532.89" stroke="black" stroke-width="0.5" />
    <path d="M532.84 68.94H532.78" stroke="black" stroke-width="0.5" />
    <path d="M532.73 68.94H532.67" stroke="black" stroke-width="0.5" />
    <path d="M532.62 68.94H532.56" stroke="black" stroke-width="0.5" />
    <path d="M532.51 68.94H532.45" stroke="black" stroke-width="0.5" />
    <path d="M532.4 68.94H532.34" stroke="black" stroke-width="0.5" />
    <path d="M532.29 68.94H532.23" stroke="black" stroke-width="0.5" />
    <path d="M532.18 68.94H532.12" stroke="black" stroke-width="0.5" />
    <path d="M532.07 68.94H532.01" stroke="black" stroke-width="0.5" />
    <path d="M531.96 68.94H531.9" stroke="black" stroke-width="0.5" />
    <path d="M531.85 68.94H531.79" stroke="black" stroke-width="0.5" />
    <path d="M531.74 68.94H531.68" stroke="black" stroke-width="0.5" />
    <path d="M531.63 68.94H531.57" stroke="black" stroke-width="0.5" />
    <path d="M531.52 68.94H531.46" stroke="black" stroke-width="0.5" />
    <path d="M531.41 68.94H531.35" stroke="black" stroke-width="0.5" />
    <path d="M531.3 68.94H531.24" stroke="black" stroke-width="0.5" />
    <path d="M531.19 68.94H531.13" stroke="black" stroke-width="0.5" />
    <path d="M531.08 68.94H531.02" stroke="black" stroke-width="0.5" />
    <path d="M530.97 68.94H530.91" stroke="black" stroke-width="0.5" />
    <path d="M530.86 68.94H530.8" stroke="black" stroke-width="0.5" />
    <path d="M530.75 68.94H530.69" stroke="black" stroke-width="0.5" />
    <path d="M530.64 68.94H530.58" stroke="black" stroke-width="0.5" />
    <path d="M530.53 68.94H530.47" stroke="black" stroke-width="0.5" />
    <path d="M530.42 68.94H530.36" stroke="black" stroke-width="0.5" />
    <path d="M530.31 68.94H530.25" stroke="black" stroke-width="0.5" />
    <path d="M530.2 68.94H530.14" stroke="black" stroke-width="0.5" />
    <path d="M530.09 68.94H530.03" stroke="black" stroke-width="0.5" />
    <path d="M529.98 68.94H529.92" stroke="black" stroke-width="0.5" />
    <path d="M529.87 68.94H529.81" stroke="black" stroke-width="0.5" />
    <path d="M529.76 68.94H529.7" stroke="black" stroke-width="0.5" />
    <path d="M529.65 68.94H529.59" stroke="black" stroke-width="0.5" />
    <path d="M529.54 68.94H529.48" stroke="black" stroke-width="0.5" />
    <path d="M529.43 68.94H529.37" stroke="black" stroke-width="0.5" />
    <path d="M529.32 68.94H529.26" stroke="black" stroke-width="0.5" />
    <path d="M529.21 68.94H529.15" stroke="black" stroke-width="0.5" />
    <path d="M529.1 68.94H529.04" stroke="black" stroke-width="0.5" />
    <path d="M528.99 68.94H528.93" stroke="black" stroke-width="0.5" />
    <path d="M528.88 68.94H528.82" stroke="black" stroke-width="0.5" />
    <path d="M528.77 68.94H528.71" stroke="black" stroke-width="0.5" />
    <path d="M528.66 68.94H528.6" stroke="black" stroke-width="0.5" />
    <path d="M528.55 68.94H528.5" stroke="black" stroke-width="0.5" />
    <path d="M528.44 68.94H528.39" stroke="black" stroke-width="0.5" />
    <path d="M528.33 68.94H528.28" stroke="black" stroke-width="0.5" />
    <path d="M528.22 68.94H528.17" stroke="black" stroke-width="0.5" />
    <path d="M528.11 68.94H528.06" stroke="black" stroke-width="0.5" />
    <path d="M528 68.94H527.95" stroke="black" stroke-width="0.5" />
    <path d="M527.89 68.94H527.84" stroke="black" stroke-width="0.5" />
    <path d="M527.78 68.94H527.73" stroke="black" stroke-width="0.5" />
    <path d="M527.67 68.94H527.62" stroke="black" stroke-width="0.5" />
    <path d="M527.56 68.94H527.51" stroke="black" stroke-width="0.5" />
    <path d="M527.45 68.94H527.4" stroke="black" stroke-width="0.5" />
    <path d="M527.34 68.94H527.29" stroke="black" stroke-width="0.5" />
    <path d="M527.23 68.94H527.18" stroke="black" stroke-width="0.5" />
    <path d="M527.12 68.94H527.07" stroke="black" stroke-width="0.5" />
    <path d="M527.01 68.94H526.96" stroke="black" stroke-width="0.5" />
    <path d="M526.9 68.94H526.85" stroke="black" stroke-width="0.5" />
    <path d="M526.79 68.94H526.74" stroke="black" stroke-width="0.5" />
    <path d="M526.68 68.94H526.63" stroke="black" stroke-width="0.5" />
    <path d="M526.57 68.94H526.52" stroke="black" stroke-width="0.5" />
    <path d="M526.46 68.94H526.41" stroke="black" stroke-width="0.5" />
    <path d="M526.35 68.94H526.3" stroke="black" stroke-width="0.5" />
    <path d="M526.24 68.94H526.19" stroke="black" stroke-width="0.5" />
    <path d="M526.13 68.94H526.08" stroke="black" stroke-width="0.5" />
    <path d="M526.02 68.94H525.97" stroke="black" stroke-width="0.5" />
    <path d="M525.91 68.94H525.86" stroke="black" stroke-width="0.5" />
    <path d="M525.8 68.94H525.75" stroke="black" stroke-width="0.5" />
    <path d="M525.69 68.94H525.64" stroke="black" stroke-width="0.5" />
    <path d="M525.58 68.94H525.53" stroke="black" stroke-width="0.5" />
    <path d="M525.47 68.94H525.42" stroke="black" stroke-width="0.5" />
    <path d="M525.36 68.94H525.31" stroke="black" stroke-width="0.5" />
    <path d="M525.25 68.94H525.2" stroke="black" stroke-width="0.5" />
    <path d="M525.14 68.94H525.09" stroke="black" stroke-width="0.5" />
    <path d="M525.03 68.94H524.98" stroke="black" stroke-width="0.5" />
    <path d="M524.92 68.94H524.87" stroke="black" stroke-width="0.5" />
    <path d="M524.81 68.94H524.76" stroke="black" stroke-width="0.5" />
    <path d="M524.7 68.94H524.65" stroke="black" stroke-width="0.5" />
    <path d="M524.59 68.94H524.54" stroke="black" stroke-width="0.5" />
    <path d="M524.48 68.94H524.43" stroke="black" stroke-width="0.5" />
    <path d="M524.37 68.94H524.32" stroke="black" stroke-width="0.5" />
    <path d="M524.26 68.94H524.21" stroke="black" stroke-width="0.5" />
    <path d="M524.15 68.94H524.1" stroke="black" stroke-width="0.5" />
    <path d="M524.04 68.94H523.99" stroke="black" stroke-width="0.5" />
    <path d="M523.93 68.94H523.88" stroke="black" stroke-width="0.5" />
    <path d="M523.82 68.94H523.77" stroke="black" stroke-width="0.5" />
    <path d="M523.71 68.94H523.66" stroke="black" stroke-width="0.5" />
    <path d="M523.6 68.94H523.55" stroke="black" stroke-width="0.5" />
    <path d="M523.49 68.94H523.44" stroke="black" stroke-width="0.5" />
    <path d="M523.38 68.94H523.33" stroke="black" stroke-width="0.5" />
    <path d="M523.27 68.94H523.22" stroke="black" stroke-width="0.5" />
    <path d="M523.16 68.94H523.11" stroke="black" stroke-width="0.5" />
    <path d="M523.05 68.94H523" stroke="black" stroke-width="0.5" />
    <path d="M522.94 68.94H522.89" stroke="black" stroke-width="0.5" />
    <path d="M522.83 68.94H522.78" stroke="black" stroke-width="0.5" />
    <path d="M522.72 68.94H522.67" stroke="black" stroke-width="0.5" />
    <path d="M522.61 68.94H522.56" stroke="black" stroke-width="0.5" />
    <path d="M522.5 68.94H522.45" stroke="black" stroke-width="0.5" />
    <path d="M522.39 68.94H522.34" stroke="black" stroke-width="0.5" />
    <path d="M522.28 68.94H522.23" stroke="black" stroke-width="0.5" />
    <path d="M522.17 68.94H522.12" stroke="black" stroke-width="0.5" />
    <path d="M522.06 68.94H522.01" stroke="black" stroke-width="0.5" />
    <path d="M521.95 68.94H521.9" stroke="black" stroke-width="0.5" />
    <path d="M521.84 68.94H521.79" stroke="black" stroke-width="0.5" />
    <path d="M521.73 68.94H521.68" stroke="black" stroke-width="0.5" />
    <path d="M521.62 68.94H521.57" stroke="black" stroke-width="0.5" />
    <path d="M521.51 68.94H521.46" stroke="black" stroke-width="0.5" />
    <path d="M521.4 68.94H521.35" stroke="black" stroke-width="0.5" />
    <path d="M521.29 68.94H521.24" stroke="black" stroke-width="0.5" />
    <path d="M521.18 68.94H521.13" stroke="black" stroke-width="0.5" />
    <path d="M521.07 68.94H521.02" stroke="black" stroke-width="0.5" />
    <path d="M520.96 68.94H520.91" stroke="black" stroke-width="0.5" />
    <path d="M520.85 68.94H520.8" stroke="black" stroke-width="0.5" />
    <path d="M520.74 68.94H520.69" stroke="black" stroke-width="0.5" />
    <path d="M520.63 68.94H520.58" stroke="black" stroke-width="0.5" />
    <path d="M520.52 68.94H520.47" stroke="black" stroke-width="0.5" />
    <path d="M520.41 68.94H520.36" stroke="black" stroke-width="0.5" />
    <path d="M520.3 68.94H520.25" stroke="black" stroke-width="0.5" />
    <path d="M520.19 68.94H520.14" stroke="black" stroke-width="0.5" />
    <path d="M520.08 68.94H520.03" stroke="black" stroke-width="0.5" />
    <path d="M519.97 68.94H519.92" stroke="black" stroke-width="0.5" />
    <path d="M519.86 68.94H519.81" stroke="black" stroke-width="0.5" />
    <path d="M519.75 68.94H519.7" stroke="black" stroke-width="0.5" />
    <path d="M519.64 68.94H519.59" stroke="black" stroke-width="0.5" />
    <path d="M519.53 68.94H519.48" stroke="black" stroke-width="0.5" />
    <path d="M519.42 68.94H519.37" stroke="black" stroke-width="0.5" />
    <path d="M519.31 68.94H519.26" stroke="black" stroke-width="0.5" />
    <path d="M519.2 68.94H519.15" stroke="black" stroke-width="0.5" />
    <path d="M519.09 68.94H519.04" stroke="black" stroke-width="0.5" />
    <path d="M518.98 68.94H518.93" stroke="black" stroke-width="0.5" />
    <path d="M518.87 68.94H518.82" stroke="black" stroke-width="0.5" />
    <path d="M518.76 68.94H518.71" stroke="black" stroke-width="0.5" />
    <path d="M518.65 68.94H518.6" stroke="black" stroke-width="0.5" />
    <path d="M518.54 68.94H518.49" stroke="black" stroke-width="0.5" />
    <path d="M518.43 68.94H518.38" stroke="black" stroke-width="0.5" />
    <path d="M518.32 68.94H518.27" stroke="black" stroke-width="0.5" />
    <path d="M518.21 68.94H518.16" stroke="black" stroke-width="0.5" />
    <path d="M518.1 68.94H518.05" stroke="black" stroke-width="0.5" />
    <path d="M517.99 68.94H517.94" stroke="black" stroke-width="0.5" />
    <path d="M517.88 68.94H517.83" stroke="black" stroke-width="0.5" />
    <path d="M517.77 68.94H517.72" stroke="black" stroke-width="0.5" />
    <path d="M517.66 68.94H517.61" stroke="black" stroke-width="0.5" />
    <path d="M517.55 68.94H517.5" stroke="black" stroke-width="0.5" />
    <path d="M517.44 68.94H517.39" stroke="black" stroke-width="0.5" />
    <path d="M517.33 68.94H517.28" stroke="black" stroke-width="0.5" />
    <path d="M517.22 68.94H517.17" stroke="black" stroke-width="0.5" />
    <path d="M517.11 68.94H517.06" stroke="black" stroke-width="0.5" />
    <path d="M517 68.94H516.95" stroke="black" stroke-width="0.5" />
    <path d="M516.89 68.94H516.84" stroke="black" stroke-width="0.5" />
    <path d="M516.78 68.94H516.73" stroke="black" stroke-width="0.5" />
    <path d="M516.67 68.94H516.62" stroke="black" stroke-width="0.5" />
    <path d="M516.56 68.94H516.51" stroke="black" stroke-width="0.5" />
    <path d="M516.45 68.94H516.4" stroke="black" stroke-width="0.5" />
    <path d="M516.34 68.94H516.29" stroke="black" stroke-width="0.5" />
    <path d="M516.23 68.94H516.18" stroke="black" stroke-width="0.5" />
    <path d="M516.12 68.94H516.07" stroke="black" stroke-width="0.5" />
    <path d="M516.01 68.94H515.96" stroke="black" stroke-width="0.5" />
    <path d="M515.9 68.94H515.85" stroke="black" stroke-width="0.5" />
    <path d="M515.79 68.94H515.74" stroke="black" stroke-width="0.5" />
    <path d="M515.68 68.94H515.63" stroke="black" stroke-width="0.5" />
    <path d="M515.58 68.94H515.52" stroke="black" stroke-width="0.5" />
    <path d="M515.47 68.94H515.41" stroke="black" stroke-width="0.5" />
    <path d="M515.36 68.94H515.3" stroke="black" stroke-width="0.5" />
    <path d="M515.25 68.94H515.19" stroke="black" stroke-width="0.5" />
    <path d="M515.14 68.94H515.08" stroke="black" stroke-width="0.5" />
    <path d="M515.03 68.94H514.97" stroke="black" stroke-width="0.5" />
    <path d="M514.92 68.94H514.86" stroke="black" stroke-width="0.5" />
    <path d="M514.81 68.94H514.75" stroke="black" stroke-width="0.5" />
    <path d="M514.7 68.94H514.64" stroke="black" stroke-width="0.5" />
    <path d="M514.59 68.94H514.53" stroke="black" stroke-width="0.5" />
    <path d="M514.48 68.94H514.42" stroke="black" stroke-width="0.5" />
    <path d="M514.37 68.94H514.31" stroke="black" stroke-width="0.5" />
    <path d="M514.26 68.94H514.2" stroke="black" stroke-width="0.5" />
    <path d="M514.15 68.94H514.09" stroke="black" stroke-width="0.5" />
    <path d="M514.04 68.94H513.98" stroke="black" stroke-width="0.5" />
    <path d="M513.93 68.94H513.87" stroke="black" stroke-width="0.5" />
    <path d="M513.82 68.94H513.76" stroke="black" stroke-width="0.5" />
    <path d="M513.71 68.94H513.65" stroke="black" stroke-width="0.5" />
    <path d="M513.6 68.94H513.54" stroke="black" stroke-width="0.5" />
    <path d="M513.49 68.94H513.43" stroke="black" stroke-width="0.5" />
    <path d="M513.38 68.94H513.32" stroke="black" stroke-width="0.5" />
    <path d="M513.27 68.94H513.21" stroke="black" stroke-width="0.5" />
    <path d="M513.16 68.94H513.1" stroke="black" stroke-width="0.5" />
    <path d="M513.05 68.94H512.99" stroke="black" stroke-width="0.5" />
    <path d="M512.94 68.94H512.88" stroke="black" stroke-width="0.5" />
    <path d="M512.83 68.94H512.77" stroke="black" stroke-width="0.5" />
    <path d="M512.72 68.94H512.66" stroke="black" stroke-width="0.5" />
    <path d="M512.61 68.94H512.55" stroke="black" stroke-width="0.5" />
    <path d="M512.5 68.94H512.44" stroke="black" stroke-width="0.5" />
    <path d="M512.39 68.94H512.33" stroke="black" stroke-width="0.5" />
    <path d="M512.28 68.94H512.22" stroke="black" stroke-width="0.5" />
    <path d="M512.17 68.94H512.11" stroke="black" stroke-width="0.5" />
    <path d="M512.06 68.94H512" stroke="black" stroke-width="0.5" />
    <path d="M511.95 68.94H511.89" stroke="black" stroke-width="0.5" />
    <path d="M511.84 68.94H511.78" stroke="black" stroke-width="0.5" />
    <path d="M511.73 68.94H511.67" stroke="black" stroke-width="0.5" />
    <path d="M511.62 68.94H511.56" stroke="black" stroke-width="0.5" />
    <path d="M511.51 68.94H511.45" stroke="black" stroke-width="0.5" />
    <path d="M511.4 68.94H511.34" stroke="black" stroke-width="0.5" />
    <path d="M511.29 68.94H511.23" stroke="black" stroke-width="0.5" />
    <path d="M511.18 68.94H511.12" stroke="black" stroke-width="0.5" />
    <path d="M511.07 68.94H511.01" stroke="black" stroke-width="0.5" />
    <path d="M510.96 68.94H510.9" stroke="black" stroke-width="0.5" />
    <path d="M510.85 68.94H510.79" stroke="black" stroke-width="0.5" />
    <path d="M510.74 68.94H510.68" stroke="black" stroke-width="0.5" />
    <path d="M510.63 68.94H510.57" stroke="black" stroke-width="0.5" />
    <path d="M510.52 68.94H510.46" stroke="black" stroke-width="0.5" />
    <path d="M510.41 68.94H510.35" stroke="black" stroke-width="0.5" />
    <path d="M510.3 68.94H510.27" stroke="black" stroke-width="0.5" />
    <path d="M541.94 95.33H541.91" stroke="black" stroke-width="0.5" />
    <path d="M541.85 95.33H541.8" stroke="black" stroke-width="0.5" />
    <path d="M541.74 95.33H541.69" stroke="black" stroke-width="0.5" />
    <path d="M541.63 95.33H541.58" stroke="black" stroke-width="0.5" />
    <path d="M541.52 95.33H541.47" stroke="black" stroke-width="0.5" />
    <path d="M541.42 95.33H541.36" stroke="black" stroke-width="0.5" />
    <path d="M541.31 95.33H541.25" stroke="black" stroke-width="0.5" />
    <path d="M541.2 95.33H541.14" stroke="black" stroke-width="0.5" />
    <path d="M541.09 95.33H541.03" stroke="black" stroke-width="0.5" />
    <path d="M540.98 95.33H540.92" stroke="black" stroke-width="0.5" />
    <path d="M540.87 95.33H540.81" stroke="black" stroke-width="0.5" />
    <path d="M540.76 95.33H540.7" stroke="black" stroke-width="0.5" />
    <path d="M540.65 95.33H540.59" stroke="black" stroke-width="0.5" />
    <path d="M540.54 95.33H540.48" stroke="black" stroke-width="0.5" />
    <path d="M540.43 95.33H540.37" stroke="black" stroke-width="0.5" />
    <path d="M540.32 95.33H540.26" stroke="black" stroke-width="0.5" />
    <path d="M540.21 95.33H540.15" stroke="black" stroke-width="0.5" />
    <path d="M540.1 95.33H540.04" stroke="black" stroke-width="0.5" />
    <path d="M539.99 95.33H539.93" stroke="black" stroke-width="0.5" />
    <path d="M539.88 95.33H539.82" stroke="black" stroke-width="0.5" />
    <path d="M539.77 95.33H539.71" stroke="black" stroke-width="0.5" />
    <path d="M539.66 95.33H539.6" stroke="black" stroke-width="0.5" />
    <path d="M539.55 95.33H539.49" stroke="black" stroke-width="0.5" />
    <path d="M539.44 95.33H539.38" stroke="black" stroke-width="0.5" />
    <path d="M539.33 95.33H539.27" stroke="black" stroke-width="0.5" />
    <path d="M539.22 95.33H539.16" stroke="black" stroke-width="0.5" />
    <path d="M539.11 95.33H539.05" stroke="black" stroke-width="0.5" />
    <path d="M539 95.33H538.94" stroke="black" stroke-width="0.5" />
    <path d="M538.89 95.33H538.83" stroke="black" stroke-width="0.5" />
    <path d="M538.78 95.33H538.72" stroke="black" stroke-width="0.5" />
    <path d="M538.67 95.33H538.61" stroke="black" stroke-width="0.5" />
    <path d="M538.56 95.33H538.5" stroke="black" stroke-width="0.5" />
    <path d="M538.45 95.33H538.39" stroke="black" stroke-width="0.5" />
    <path d="M538.34 95.33H538.28" stroke="black" stroke-width="0.5" />
    <path d="M538.23 95.33H538.17" stroke="black" stroke-width="0.5" />
    <path d="M538.12 95.33H538.06" stroke="black" stroke-width="0.5" />
    <path d="M538.01 95.33H537.95" stroke="black" stroke-width="0.5" />
    <path d="M537.9 95.33H537.84" stroke="black" stroke-width="0.5" />
    <path d="M537.79 95.33H537.73" stroke="black" stroke-width="0.5" />
    <path d="M537.68 95.33H537.62" stroke="black" stroke-width="0.5" />
    <path d="M537.57 95.33H537.51" stroke="black" stroke-width="0.5" />
    <path d="M537.46 95.33H537.4" stroke="black" stroke-width="0.5" />
    <path d="M537.35 95.33H537.29" stroke="black" stroke-width="0.5" />
    <path d="M537.24 95.33H537.18" stroke="black" stroke-width="0.5" />
    <path d="M537.13 95.33H537.07" stroke="black" stroke-width="0.5" />
    <path d="M537.02 95.33H536.96" stroke="black" stroke-width="0.5" />
    <path d="M536.91 95.33H536.85" stroke="black" stroke-width="0.5" />
    <path d="M536.8 95.33H536.74" stroke="black" stroke-width="0.5" />
    <path d="M536.69 95.33H536.63" stroke="black" stroke-width="0.5" />
    <path d="M536.58 95.33H536.52" stroke="black" stroke-width="0.5" />
    <path d="M536.47 95.33H536.41" stroke="black" stroke-width="0.5" />
    <path d="M536.36 95.33H536.3" stroke="black" stroke-width="0.5" />
    <path d="M536.25 95.33H536.19" stroke="black" stroke-width="0.5" />
    <path d="M536.14 95.33H536.08" stroke="black" stroke-width="0.5" />
    <path d="M536.03 95.33H535.97" stroke="black" stroke-width="0.5" />
    <path d="M535.92 95.33H535.86" stroke="black" stroke-width="0.5" />
    <path d="M535.81 95.33H535.75" stroke="black" stroke-width="0.5" />
    <path d="M535.7 95.33H535.64" stroke="black" stroke-width="0.5" />
    <path d="M535.59 95.33H535.53" stroke="black" stroke-width="0.5" />
    <path d="M535.48 95.33H535.42" stroke="black" stroke-width="0.5" />
    <path d="M535.37 95.33H535.31" stroke="black" stroke-width="0.5" />
    <path d="M535.26 95.33H535.2" stroke="black" stroke-width="0.5" />
    <path d="M535.15 95.33H535.09" stroke="black" stroke-width="0.5" />
    <path d="M535.04 95.33H534.98" stroke="black" stroke-width="0.5" />
    <path d="M534.93 95.33H534.87" stroke="black" stroke-width="0.5" />
    <path d="M534.82 95.33H534.76" stroke="black" stroke-width="0.5" />
    <path d="M534.71 95.33H534.65" stroke="black" stroke-width="0.5" />
    <path d="M534.6 95.33H534.54" stroke="black" stroke-width="0.5" />
    <path d="M534.49 95.33H534.43" stroke="black" stroke-width="0.5" />
    <path d="M534.38 95.33H534.32" stroke="black" stroke-width="0.5" />
    <path d="M534.27 95.33H534.21" stroke="black" stroke-width="0.5" />
    <path d="M534.16 95.33H534.1" stroke="black" stroke-width="0.5" />
    <path d="M534.05 95.33H533.99" stroke="black" stroke-width="0.5" />
    <path d="M533.94 95.33H533.88" stroke="black" stroke-width="0.5" />
    <path d="M533.83 95.33H533.77" stroke="black" stroke-width="0.5" />
    <path d="M533.72 95.33H533.66" stroke="black" stroke-width="0.5" />
    <path d="M533.61 95.33H533.55" stroke="black" stroke-width="0.5" />
    <path d="M533.5 95.33H533.44" stroke="black" stroke-width="0.5" />
    <path d="M533.39 95.33H533.33" stroke="black" stroke-width="0.5" />
    <path d="M533.28 95.33H533.22" stroke="black" stroke-width="0.5" />
    <path d="M533.17 95.33H533.11" stroke="black" stroke-width="0.5" />
    <path d="M533.06 95.33H533" stroke="black" stroke-width="0.5" />
    <path d="M532.95 95.33H532.89" stroke="black" stroke-width="0.5" />
    <path d="M532.84 95.33H532.78" stroke="black" stroke-width="0.5" />
    <path d="M532.73 95.33H532.67" stroke="black" stroke-width="0.5" />
    <path d="M532.62 95.33H532.56" stroke="black" stroke-width="0.5" />
    <path d="M532.51 95.33H532.45" stroke="black" stroke-width="0.5" />
    <path d="M532.4 95.33H532.34" stroke="black" stroke-width="0.5" />
    <path d="M532.29 95.33H532.23" stroke="black" stroke-width="0.5" />
    <path d="M532.18 95.33H532.12" stroke="black" stroke-width="0.5" />
    <path d="M532.07 95.33H532.01" stroke="black" stroke-width="0.5" />
    <path d="M531.96 95.33H531.9" stroke="black" stroke-width="0.5" />
    <path d="M531.85 95.33H531.79" stroke="black" stroke-width="0.5" />
    <path d="M531.74 95.33H531.68" stroke="black" stroke-width="0.5" />
    <path d="M531.63 95.33H531.57" stroke="black" stroke-width="0.5" />
    <path d="M531.52 95.33H531.46" stroke="black" stroke-width="0.5" />
    <path d="M531.41 95.33H531.35" stroke="black" stroke-width="0.5" />
    <path d="M531.3 95.33H531.24" stroke="black" stroke-width="0.5" />
    <path d="M531.19 95.33H531.13" stroke="black" stroke-width="0.5" />
    <path d="M531.08 95.33H531.02" stroke="black" stroke-width="0.5" />
    <path d="M530.97 95.33H530.91" stroke="black" stroke-width="0.5" />
    <path d="M530.86 95.33H530.8" stroke="black" stroke-width="0.5" />
    <path d="M530.75 95.33H530.69" stroke="black" stroke-width="0.5" />
    <path d="M530.64 95.33H530.58" stroke="black" stroke-width="0.5" />
    <path d="M530.53 95.33H530.47" stroke="black" stroke-width="0.5" />
    <path d="M530.42 95.33H530.36" stroke="black" stroke-width="0.5" />
    <path d="M530.31 95.33H530.25" stroke="black" stroke-width="0.5" />
    <path d="M530.2 95.33H530.14" stroke="black" stroke-width="0.5" />
    <path d="M530.09 95.33H530.03" stroke="black" stroke-width="0.5" />
    <path d="M529.98 95.33H529.92" stroke="black" stroke-width="0.5" />
    <path d="M529.87 95.33H529.81" stroke="black" stroke-width="0.5" />
    <path d="M529.76 95.33H529.7" stroke="black" stroke-width="0.5" />
    <path d="M529.65 95.33H529.59" stroke="black" stroke-width="0.5" />
    <path d="M529.54 95.33H529.48" stroke="black" stroke-width="0.5" />
    <path d="M529.43 95.33H529.37" stroke="black" stroke-width="0.5" />
    <path d="M529.32 95.33H529.26" stroke="black" stroke-width="0.5" />
    <path d="M529.21 95.33H529.15" stroke="black" stroke-width="0.5" />
    <path d="M529.1 95.33H529.04" stroke="black" stroke-width="0.5" />
    <path d="M528.99 95.33H528.93" stroke="black" stroke-width="0.5" />
    <path d="M528.88 95.33H528.82" stroke="black" stroke-width="0.5" />
    <path d="M528.77 95.33H528.71" stroke="black" stroke-width="0.5" />
    <path d="M528.66 95.33H528.6" stroke="black" stroke-width="0.5" />
    <path d="M528.55 95.33H528.5" stroke="black" stroke-width="0.5" />
    <path d="M528.44 95.33H528.39" stroke="black" stroke-width="0.5" />
    <path d="M528.33 95.33H528.28" stroke="black" stroke-width="0.5" />
    <path d="M528.22 95.33H528.17" stroke="black" stroke-width="0.5" />
    <path d="M528.11 95.33H528.06" stroke="black" stroke-width="0.5" />
    <path d="M528 95.33H527.95" stroke="black" stroke-width="0.5" />
    <path d="M527.89 95.33H527.84" stroke="black" stroke-width="0.5" />
    <path d="M527.78 95.33H527.73" stroke="black" stroke-width="0.5" />
    <path d="M527.67 95.33H527.62" stroke="black" stroke-width="0.5" />
    <path d="M527.56 95.33H527.51" stroke="black" stroke-width="0.5" />
    <path d="M527.45 95.33H527.4" stroke="black" stroke-width="0.5" />
    <path d="M527.34 95.33H527.29" stroke="black" stroke-width="0.5" />
    <path d="M527.23 95.33H527.18" stroke="black" stroke-width="0.5" />
    <path d="M527.12 95.33H527.07" stroke="black" stroke-width="0.5" />
    <path d="M527.01 95.33H526.96" stroke="black" stroke-width="0.5" />
    <path d="M526.9 95.33H526.85" stroke="black" stroke-width="0.5" />
    <path d="M526.79 95.33H526.74" stroke="black" stroke-width="0.5" />
    <path d="M526.68 95.33H526.63" stroke="black" stroke-width="0.5" />
    <path d="M526.57 95.33H526.52" stroke="black" stroke-width="0.5" />
    <path d="M526.46 95.33H526.41" stroke="black" stroke-width="0.5" />
    <path d="M526.35 95.33H526.3" stroke="black" stroke-width="0.5" />
    <path d="M526.24 95.33H526.19" stroke="black" stroke-width="0.5" />
    <path d="M526.13 95.33H526.08" stroke="black" stroke-width="0.5" />
    <path d="M526.02 95.33H525.97" stroke="black" stroke-width="0.5" />
    <path d="M525.91 95.33H525.86" stroke="black" stroke-width="0.5" />
    <path d="M525.8 95.33H525.75" stroke="black" stroke-width="0.5" />
    <path d="M525.69 95.33H525.64" stroke="black" stroke-width="0.5" />
    <path d="M525.58 95.33H525.53" stroke="black" stroke-width="0.5" />
    <path d="M525.47 95.33H525.42" stroke="black" stroke-width="0.5" />
    <path d="M525.36 95.33H525.31" stroke="black" stroke-width="0.5" />
    <path d="M525.25 95.33H525.2" stroke="black" stroke-width="0.5" />
    <path d="M525.14 95.33H525.09" stroke="black" stroke-width="0.5" />
    <path d="M525.03 95.33H524.98" stroke="black" stroke-width="0.5" />
    <path d="M524.92 95.33H524.87" stroke="black" stroke-width="0.5" />
    <path d="M524.81 95.33H524.76" stroke="black" stroke-width="0.5" />
    <path d="M524.7 95.33H524.65" stroke="black" stroke-width="0.5" />
    <path d="M524.59 95.33H524.54" stroke="black" stroke-width="0.5" />
    <path d="M524.48 95.33H524.43" stroke="black" stroke-width="0.5" />
    <path d="M524.37 95.33H524.32" stroke="black" stroke-width="0.5" />
    <path d="M524.26 95.33H524.21" stroke="black" stroke-width="0.5" />
    <path d="M524.15 95.33H524.1" stroke="black" stroke-width="0.5" />
    <path d="M524.04 95.33H523.99" stroke="black" stroke-width="0.5" />
    <path d="M523.93 95.33H523.88" stroke="black" stroke-width="0.5" />
    <path d="M523.82 95.33H523.77" stroke="black" stroke-width="0.5" />
    <path d="M523.71 95.33H523.66" stroke="black" stroke-width="0.5" />
    <path d="M523.6 95.33H523.55" stroke="black" stroke-width="0.5" />
    <path d="M523.49 95.33H523.44" stroke="black" stroke-width="0.5" />
    <path d="M523.38 95.33H523.33" stroke="black" stroke-width="0.5" />
    <path d="M523.27 95.33H523.22" stroke="black" stroke-width="0.5" />
    <path d="M523.16 95.33H523.11" stroke="black" stroke-width="0.5" />
    <path d="M523.05 95.33H523" stroke="black" stroke-width="0.5" />
    <path d="M522.94 95.33H522.89" stroke="black" stroke-width="0.5" />
    <path d="M522.83 95.33H522.78" stroke="black" stroke-width="0.5" />
    <path d="M522.72 95.33H522.67" stroke="black" stroke-width="0.5" />
    <path d="M522.61 95.33H522.56" stroke="black" stroke-width="0.5" />
    <path d="M522.5 95.33H522.45" stroke="black" stroke-width="0.5" />
    <path d="M522.39 95.33H522.34" stroke="black" stroke-width="0.5" />
    <path d="M522.28 95.33H522.23" stroke="black" stroke-width="0.5" />
    <path d="M522.17 95.33H522.12" stroke="black" stroke-width="0.5" />
    <path d="M522.06 95.33H522.01" stroke="black" stroke-width="0.5" />
    <path d="M521.95 95.33H521.9" stroke="black" stroke-width="0.5" />
    <path d="M521.84 95.33H521.79" stroke="black" stroke-width="0.5" />
    <path d="M521.73 95.33H521.68" stroke="black" stroke-width="0.5" />
    <path d="M521.62 95.33H521.57" stroke="black" stroke-width="0.5" />
    <path d="M521.51 95.33H521.46" stroke="black" stroke-width="0.5" />
    <path d="M521.4 95.33H521.35" stroke="black" stroke-width="0.5" />
    <path d="M521.29 95.33H521.24" stroke="black" stroke-width="0.5" />
    <path d="M521.18 95.33H521.13" stroke="black" stroke-width="0.5" />
    <path d="M521.07 95.33H521.02" stroke="black" stroke-width="0.5" />
    <path d="M520.96 95.33H520.91" stroke="black" stroke-width="0.5" />
    <path d="M520.85 95.33H520.8" stroke="black" stroke-width="0.5" />
    <path d="M520.74 95.33H520.69" stroke="black" stroke-width="0.5" />
    <path d="M520.63 95.33H520.58" stroke="black" stroke-width="0.5" />
    <path d="M520.52 95.33H520.47" stroke="black" stroke-width="0.5" />
    <path d="M520.41 95.33H520.36" stroke="black" stroke-width="0.5" />
    <path d="M520.3 95.33H520.25" stroke="black" stroke-width="0.5" />
    <path d="M520.19 95.33H520.14" stroke="black" stroke-width="0.5" />
    <path d="M520.08 95.33H520.03" stroke="black" stroke-width="0.5" />
    <path d="M519.97 95.33H519.92" stroke="black" stroke-width="0.5" />
    <path d="M519.86 95.33H519.81" stroke="black" stroke-width="0.5" />
    <path d="M519.75 95.33H519.7" stroke="black" stroke-width="0.5" />
    <path d="M519.64 95.33H519.59" stroke="black" stroke-width="0.5" />
    <path d="M519.53 95.33H519.48" stroke="black" stroke-width="0.5" />
    <path d="M519.42 95.33H519.37" stroke="black" stroke-width="0.5" />
    <path d="M519.31 95.33H519.26" stroke="black" stroke-width="0.5" />
    <path d="M519.2 95.33H519.15" stroke="black" stroke-width="0.5" />
    <path d="M519.09 95.33H519.04" stroke="black" stroke-width="0.5" />
    <path d="M518.98 95.33H518.93" stroke="black" stroke-width="0.5" />
    <path d="M518.87 95.33H518.82" stroke="black" stroke-width="0.5" />
    <path d="M518.76 95.33H518.71" stroke="black" stroke-width="0.5" />
    <path d="M518.65 95.33H518.6" stroke="black" stroke-width="0.5" />
    <path d="M518.54 95.33H518.49" stroke="black" stroke-width="0.5" />
    <path d="M518.43 95.33H518.38" stroke="black" stroke-width="0.5" />
    <path d="M518.32 95.33H518.27" stroke="black" stroke-width="0.5" />
    <path d="M518.21 95.33H518.16" stroke="black" stroke-width="0.5" />
    <path d="M518.1 95.33H518.05" stroke="black" stroke-width="0.5" />
    <path d="M517.99 95.33H517.94" stroke="black" stroke-width="0.5" />
    <path d="M517.88 95.33H517.83" stroke="black" stroke-width="0.5" />
    <path d="M517.77 95.33H517.72" stroke="black" stroke-width="0.5" />
    <path d="M517.66 95.33H517.61" stroke="black" stroke-width="0.5" />
    <path d="M517.55 95.33H517.5" stroke="black" stroke-width="0.5" />
    <path d="M517.44 95.33H517.39" stroke="black" stroke-width="0.5" />
    <path d="M517.33 95.33H517.28" stroke="black" stroke-width="0.5" />
    <path d="M517.22 95.33H517.17" stroke="black" stroke-width="0.5" />
    <path d="M517.11 95.33H517.06" stroke="black" stroke-width="0.5" />
    <path d="M517 95.33H516.95" stroke="black" stroke-width="0.5" />
    <path d="M516.89 95.33H516.84" stroke="black" stroke-width="0.5" />
    <path d="M516.78 95.33H516.73" stroke="black" stroke-width="0.5" />
    <path d="M516.67 95.33H516.62" stroke="black" stroke-width="0.5" />
    <path d="M516.56 95.33H516.51" stroke="black" stroke-width="0.5" />
    <path d="M516.45 95.33H516.4" stroke="black" stroke-width="0.5" />
    <path d="M516.34 95.33H516.29" stroke="black" stroke-width="0.5" />
    <path d="M516.23 95.33H516.18" stroke="black" stroke-width="0.5" />
    <path d="M516.12 95.33H516.07" stroke="black" stroke-width="0.5" />
    <path d="M516.01 95.33H515.96" stroke="black" stroke-width="0.5" />
    <path d="M515.9 95.33H515.85" stroke="black" stroke-width="0.5" />
    <path d="M515.79 95.33H515.74" stroke="black" stroke-width="0.5" />
    <path d="M515.68 95.33H515.63" stroke="black" stroke-width="0.5" />
    <path d="M515.58 95.33H515.52" stroke="black" stroke-width="0.5" />
    <path d="M515.47 95.33H515.41" stroke="black" stroke-width="0.5" />
    <path d="M515.36 95.33H515.3" stroke="black" stroke-width="0.5" />
    <path d="M515.25 95.33H515.19" stroke="black" stroke-width="0.5" />
    <path d="M515.14 95.33H515.08" stroke="black" stroke-width="0.5" />
    <path d="M515.03 95.33H514.97" stroke="black" stroke-width="0.5" />
    <path d="M514.92 95.33H514.86" stroke="black" stroke-width="0.5" />
    <path d="M514.81 95.33H514.75" stroke="black" stroke-width="0.5" />
    <path d="M514.7 95.33H514.64" stroke="black" stroke-width="0.5" />
    <path d="M514.59 95.33H514.53" stroke="black" stroke-width="0.5" />
    <path d="M514.48 95.33H514.42" stroke="black" stroke-width="0.5" />
    <path d="M514.37 95.33H514.31" stroke="black" stroke-width="0.5" />
    <path d="M514.26 95.33H514.2" stroke="black" stroke-width="0.5" />
    <path d="M514.15 95.33H514.09" stroke="black" stroke-width="0.5" />
    <path d="M514.04 95.33H513.98" stroke="black" stroke-width="0.5" />
    <path d="M513.93 95.33H513.87" stroke="black" stroke-width="0.5" />
    <path d="M513.82 95.33H513.76" stroke="black" stroke-width="0.5" />
    <path d="M513.71 95.33H513.65" stroke="black" stroke-width="0.5" />
    <path d="M513.6 95.33H513.54" stroke="black" stroke-width="0.5" />
    <path d="M513.49 95.33H513.43" stroke="black" stroke-width="0.5" />
    <path d="M513.38 95.33H513.32" stroke="black" stroke-width="0.5" />
    <path d="M513.27 95.33H513.21" stroke="black" stroke-width="0.5" />
    <path d="M513.16 95.33H513.1" stroke="black" stroke-width="0.5" />
    <path d="M513.05 95.33H512.99" stroke="black" stroke-width="0.5" />
    <path d="M512.94 95.33H512.88" stroke="black" stroke-width="0.5" />
    <path d="M512.83 95.33H512.77" stroke="black" stroke-width="0.5" />
    <path d="M512.72 95.33H512.66" stroke="black" stroke-width="0.5" />
    <path d="M512.61 95.33H512.55" stroke="black" stroke-width="0.5" />
    <path d="M512.5 95.33H512.44" stroke="black" stroke-width="0.5" />
    <path d="M512.39 95.33H512.33" stroke="black" stroke-width="0.5" />
    <path d="M512.28 95.33H512.22" stroke="black" stroke-width="0.5" />
    <path d="M512.17 95.33H512.11" stroke="black" stroke-width="0.5" />
    <path d="M512.06 95.33H512" stroke="black" stroke-width="0.5" />
    <path d="M511.95 95.33H511.89" stroke="black" stroke-width="0.5" />
    <path d="M511.84 95.33H511.78" stroke="black" stroke-width="0.5" />
    <path d="M511.73 95.33H511.67" stroke="black" stroke-width="0.5" />
    <path d="M511.62 95.33H511.56" stroke="black" stroke-width="0.5" />
    <path d="M511.51 95.33H511.45" stroke="black" stroke-width="0.5" />
    <path d="M511.4 95.33H511.34" stroke="black" stroke-width="0.5" />
    <path d="M511.29 95.33H511.23" stroke="black" stroke-width="0.5" />
    <path d="M511.18 95.33H511.12" stroke="black" stroke-width="0.5" />
    <path d="M511.07 95.33H511.01" stroke="black" stroke-width="0.5" />
    <path d="M510.96 95.33H510.9" stroke="black" stroke-width="0.5" />
    <path d="M510.85 95.33H510.79" stroke="black" stroke-width="0.5" />
    <path d="M510.74 95.33H510.68" stroke="black" stroke-width="0.5" />
    <path d="M510.63 95.33H510.57" stroke="black" stroke-width="0.5" />
    <path d="M510.52 95.33H510.46" stroke="black" stroke-width="0.5" />
    <path d="M510.41 95.33H510.35" stroke="black" stroke-width="0.5" />
    <path d="M510.3 95.33H510.27" stroke="black" stroke-width="0.5" />
    <path d="M21.73 465.33V447.52" stroke="black" stroke-width="0.5" />
    <path d="M112.55 447.52H21.73" stroke="black" stroke-width="0.5" />
    <path d="M112.55 446.86H21.73" stroke="black" stroke-width="0.5" />
    <path d="M21.73 465.33H112.55" stroke="black" stroke-width="0.5" />
    <path d="M112.55 465.33V446.86" stroke="black" stroke-width="0.5" />
    <path d="M125.09 174.63V174.53" stroke="black" stroke-width="0.5" />
    <path d="M125.09 174.48V174.4" stroke="black" stroke-width="0.5" />
    <path d="M125.09 174.34V174.26" stroke="black" stroke-width="0.5" />
    <path d="M125.09 174.2V174.12" stroke="black" stroke-width="0.5" />
    <path d="M125.09 174.07V173.98" stroke="black" stroke-width="0.5" />
    <path d="M125.09 173.93V173.85" stroke="black" stroke-width="0.5" />
    <path d="M125.09 173.79V173.71" stroke="black" stroke-width="0.5" />
    <path d="M125.09 173.65V173.57" stroke="black" stroke-width="0.5" />
    <path d="M125.09 173.52V173.43" stroke="black" stroke-width="0.5" />
    <path d="M125.09 173.38V173.3" stroke="black" stroke-width="0.5" />
    <path d="M125.09 173.24V173.16" stroke="black" stroke-width="0.5" />
    <path d="M125.09 173.1V173.02" stroke="black" stroke-width="0.5" />
    <path d="M125.09 172.97V172.88" stroke="black" stroke-width="0.5" />
    <path d="M125.09 172.83V172.75" stroke="black" stroke-width="0.5" />
    <path d="M125.09 172.69V172.61" stroke="black" stroke-width="0.5" />
    <path d="M125.09 172.55V172.47" stroke="black" stroke-width="0.5" />
    <path d="M125.09 172.42V172.33" stroke="black" stroke-width="0.5" />
    <path d="M125.09 172.28V172.2" stroke="black" stroke-width="0.5" />
    <path d="M125.09 172.14V172.06" stroke="black" stroke-width="0.5" />
    <path d="M125.09 172V171.92" stroke="black" stroke-width="0.5" />
    <path d="M125.09 171.87V171.78" stroke="black" stroke-width="0.5" />
    <path d="M125.09 171.73V171.65" stroke="black" stroke-width="0.5" />
    <path d="M125.09 171.59V171.51" stroke="black" stroke-width="0.5" />
    <path d="M125.09 171.45V171.37" stroke="black" stroke-width="0.5" />
    <path d="M125.09 171.32V171.23" stroke="black" stroke-width="0.5" />
    <path d="M125.09 171.18V171.1" stroke="black" stroke-width="0.5" />
    <path d="M125.09 171.04V170.96" stroke="black" stroke-width="0.5" />
    <path d="M125.09 170.9V170.82" stroke="black" stroke-width="0.5" />
    <path d="M125.09 170.77V170.68" stroke="black" stroke-width="0.5" />
    <path d="M125.09 170.63V170.55" stroke="black" stroke-width="0.5" />
    <path d="M125.09 170.49V170.41" stroke="black" stroke-width="0.5" />
    <path d="M125.09 170.36V170.27" stroke="black" stroke-width="0.5" />
    <path d="M125.09 170.22V170.14" stroke="black" stroke-width="0.5" />
    <path d="M125.09 170.08V170" stroke="black" stroke-width="0.5" />
    <path d="M125.09 169.94V169.86" stroke="black" stroke-width="0.5" />
    <path d="M125.09 169.81V169.72" stroke="black" stroke-width="0.5" />
    <path d="M125.09 169.67V169.59" stroke="black" stroke-width="0.5" />
    <path d="M125.09 169.53V169.45" stroke="black" stroke-width="0.5" />
    <path d="M125.09 169.39V169.31" stroke="black" stroke-width="0.5" />
    <path d="M125.09 169.26V169.17" stroke="black" stroke-width="0.5" />
    <path d="M125.09 169.12V169.04" stroke="black" stroke-width="0.5" />
    <path d="M125.09 168.98V168.9" stroke="black" stroke-width="0.5" />
    <path d="M125.09 168.84V168.76" stroke="black" stroke-width="0.5" />
    <path d="M125.09 168.71V168.62" stroke="black" stroke-width="0.5" />
    <path d="M125.09 168.57V168.49" stroke="black" stroke-width="0.5" />
    <path d="M125.09 168.43V168.35" stroke="black" stroke-width="0.5" />
    <path d="M125.09 168.29V168.21" stroke="black" stroke-width="0.5" />
    <path d="M125.09 168.16V168.07" stroke="black" stroke-width="0.5" />
    <path d="M125.09 168.02V167.94" stroke="black" stroke-width="0.5" />
    <path d="M125.09 167.88V167.8" stroke="black" stroke-width="0.5" />
    <path d="M125.09 167.74V167.66" stroke="black" stroke-width="0.5" />
    <path d="M125.09 167.61V167.52" stroke="black" stroke-width="0.5" />
    <path d="M125.09 167.47V167.39" stroke="black" stroke-width="0.5" />
    <path d="M125.09 167.33V167.25" stroke="black" stroke-width="0.5" />
    <path d="M125.09 167.19V167.11" stroke="black" stroke-width="0.5" />
    <path d="M125.09 167.06V166.97" stroke="black" stroke-width="0.5" />
    <path d="M125.09 166.92V166.84" stroke="black" stroke-width="0.5" />
    <path d="M125.09 166.78V166.7" stroke="black" stroke-width="0.5" />
    <path d="M125.09 166.64V166.56" stroke="black" stroke-width="0.5" />
    <path d="M125.09 166.51V166.42" stroke="black" stroke-width="0.5" />
    <path d="M125.09 166.37V166.29" stroke="black" stroke-width="0.5" />
    <path d="M125.09 166.23V166.15" stroke="black" stroke-width="0.5" />
    <path d="M125.09 166.09V166.01" stroke="black" stroke-width="0.5" />
    <path d="M125.09 165.96V165.87" stroke="black" stroke-width="0.5" />
    <path d="M125.09 165.82V165.74" stroke="black" stroke-width="0.5" />
    <path d="M125.09 165.68V165.6" stroke="black" stroke-width="0.5" />
    <path d="M125.09 165.54V165.46" stroke="black" stroke-width="0.5" />
    <path d="M125.09 165.41V165.32" stroke="black" stroke-width="0.5" />
    <path d="M125.09 165.27V165.19" stroke="black" stroke-width="0.5" />
    <path d="M125.09 165.13V165.05" stroke="black" stroke-width="0.5" />
    <path d="M125.09 164.99V164.91" stroke="black" stroke-width="0.5" />
    <path d="M125.09 164.86V164.77" stroke="black" stroke-width="0.5" />
    <path d="M125.09 164.72V164.64" stroke="black" stroke-width="0.5" />
    <path d="M125.09 164.58V164.5" stroke="black" stroke-width="0.5" />
    <path d="M125.09 164.44V164.36" stroke="black" stroke-width="0.5" />
    <path d="M125.09 164.31V164.22" stroke="black" stroke-width="0.5" />
    <path d="M125.09 164.17V164.07" stroke="black" stroke-width="0.5" />
    <path d="M125.09 164.07H125.14" stroke="black" stroke-width="0.5" />
    <path d="M125.2 164.07H125.28" stroke="black" stroke-width="0.5" />
    <path d="M125.34 164.07H125.42" stroke="black" stroke-width="0.5" />
    <path d="M125.47 164.07H125.56" stroke="black" stroke-width="0.5" />
    <path d="M125.61 164.07H125.69" stroke="black" stroke-width="0.5" />
    <path d="M125.75 164.07H125.83" stroke="black" stroke-width="0.5" />
    <path d="M125.89 164.07H125.97" stroke="black" stroke-width="0.5" />
    <path d="M126.02 164.07H126.11" stroke="black" stroke-width="0.5" />
    <path d="M126.16 164.07H126.24" stroke="black" stroke-width="0.5" />
    <path d="M126.3 164.07H126.38" stroke="black" stroke-width="0.5" />
    <path d="M126.44 164.07H126.52" stroke="black" stroke-width="0.5" />
    <path d="M126.57 164.07H126.66" stroke="black" stroke-width="0.5" />
    <path d="M126.71 164.07H126.79" stroke="black" stroke-width="0.5" />
    <path d="M126.85 164.07H126.93" stroke="black" stroke-width="0.5" />
    <path d="M126.99 164.07H127.07" stroke="black" stroke-width="0.5" />
    <path d="M127.12 164.07H127.21" stroke="black" stroke-width="0.5" />
    <path d="M127.26 164.07H127.34" stroke="black" stroke-width="0.5" />
    <path d="M127.4 164.07H127.48" stroke="black" stroke-width="0.5" />
    <path d="M127.54 164.07H127.62" stroke="black" stroke-width="0.5" />
    <path d="M127.67 164.07H127.76" stroke="black" stroke-width="0.5" />
    <path d="M127.81 164.07H127.89" stroke="black" stroke-width="0.5" />
    <path d="M127.95 164.07H128.03" stroke="black" stroke-width="0.5" />
    <path d="M128.08 164.07H128.17" stroke="black" stroke-width="0.5" />
    <path d="M128.22 164.07H128.3" stroke="black" stroke-width="0.5" />
    <path d="M128.36 164.07H128.44" stroke="black" stroke-width="0.5" />
    <path d="M128.5 164.07H128.58" stroke="black" stroke-width="0.5" />
    <path d="M128.63 164.07H128.72" stroke="black" stroke-width="0.5" />
    <path d="M128.77 164.07H128.85" stroke="black" stroke-width="0.5" />
    <path d="M128.91 164.07H128.99" stroke="black" stroke-width="0.5" />
    <path d="M129.05 164.07H129.13" stroke="black" stroke-width="0.5" />
    <path d="M129.18 164.07H129.27" stroke="black" stroke-width="0.5" />
    <path d="M129.32 164.07H129.4" stroke="black" stroke-width="0.5" />
    <path d="M129.46 164.07H129.54" stroke="black" stroke-width="0.5" />
    <path d="M129.6 164.07H129.68" stroke="black" stroke-width="0.5" />
    <path d="M129.73 164.07H129.82" stroke="black" stroke-width="0.5" />
    <path d="M129.87 164.07H129.95" stroke="black" stroke-width="0.5" />
    <path d="M130.01 164.07H130.09" stroke="black" stroke-width="0.5" />
    <path d="M130.15 164.07H130.23" stroke="black" stroke-width="0.5" />
    <path d="M130.28 164.07H130.37" stroke="black" stroke-width="0.5" />
    <path d="M130.42 164.07H130.5" stroke="black" stroke-width="0.5" />
    <path d="M130.56 164.07H130.64" stroke="black" stroke-width="0.5" />
    <path d="M130.7 164.07H130.78" stroke="black" stroke-width="0.5" />
    <path d="M130.83 164.07H130.92" stroke="black" stroke-width="0.5" />
    <path d="M130.97 164.07H131.05" stroke="black" stroke-width="0.5" />
    <path d="M131.11 164.07H131.19" stroke="black" stroke-width="0.5" />
    <path d="M131.25 164.07H131.33" stroke="black" stroke-width="0.5" />
    <path d="M131.38 164.07H131.47" stroke="black" stroke-width="0.5" />
    <path d="M131.52 164.07H131.6" stroke="black" stroke-width="0.5" />
    <path d="M131.66 164.07H131.74" stroke="black" stroke-width="0.5" />
    <path d="M131.8 164.07H131.88" stroke="black" stroke-width="0.5" />
    <path d="M131.93 164.07H132.02" stroke="black" stroke-width="0.5" />
    <path d="M132.07 164.07H132.15" stroke="black" stroke-width="0.5" />
    <path d="M132.21 164.07H132.29" stroke="black" stroke-width="0.5" />
    <path d="M132.35 164.07H132.43" stroke="black" stroke-width="0.5" />
    <path d="M132.48 164.07H132.57" stroke="black" stroke-width="0.5" />
    <path d="M132.62 164.07H132.7" stroke="black" stroke-width="0.5" />
    <path d="M132.76 164.07H132.84" stroke="black" stroke-width="0.5" />
    <path d="M132.9 164.07H132.98" stroke="black" stroke-width="0.5" />
    <path d="M133.03 164.07H133.12" stroke="black" stroke-width="0.5" />
    <path d="M133.17 164.07H133.25" stroke="black" stroke-width="0.5" />
    <path d="M133.31 164.07H133.39" stroke="black" stroke-width="0.5" />
    <path d="M133.45 164.07H133.53" stroke="black" stroke-width="0.5" />
    <path d="M133.58 164.07H133.67" stroke="black" stroke-width="0.5" />
    <path d="M133.72 164.07H133.8" stroke="black" stroke-width="0.5" />
    <path d="M133.86 164.07H133.94" stroke="black" stroke-width="0.5" />
    <path d="M134 164.07H134.08" stroke="black" stroke-width="0.5" />
    <path d="M134.13 164.07H134.22" stroke="black" stroke-width="0.5" />
    <path d="M134.27 164.07H134.35" stroke="black" stroke-width="0.5" />
    <path d="M134.41 164.07H134.49" stroke="black" stroke-width="0.5" />
    <path d="M134.54 164.07H134.63" stroke="black" stroke-width="0.5" />
    <path d="M134.68 164.07H134.76" stroke="black" stroke-width="0.5" />
    <path d="M134.82 164.07H134.9" stroke="black" stroke-width="0.5" />
    <path d="M134.96 164.07H135.04" stroke="black" stroke-width="0.5" />
    <path d="M135.09 164.07H135.18" stroke="black" stroke-width="0.5" />
    <path d="M135.23 164.07H135.31" stroke="black" stroke-width="0.5" />
    <path d="M135.37 164.07H135.45" stroke="black" stroke-width="0.5" />
    <path d="M135.51 164.07H135.59" stroke="black" stroke-width="0.5" />
    <path d="M135.64 164.07H135.73" stroke="black" stroke-width="0.5" />
    <path d="M135.78 164.07H135.86" stroke="black" stroke-width="0.5" />
    <path d="M135.92 164.07H136" stroke="black" stroke-width="0.5" />
    <path d="M136.06 164.07H136.14" stroke="black" stroke-width="0.5" />
    <path d="M136.19 164.07H136.28" stroke="black" stroke-width="0.5" />
    <path d="M136.33 164.07H136.41" stroke="black" stroke-width="0.5" />
    <path d="M136.47 164.07H136.55" stroke="black" stroke-width="0.5" />
    <path d="M136.61 164.07H136.69" stroke="black" stroke-width="0.5" />
    <path d="M136.74 164.07H136.83" stroke="black" stroke-width="0.5" />
    <path d="M136.88 164.07H136.96" stroke="black" stroke-width="0.5" />
    <path d="M137.02 164.07H137.1" stroke="black" stroke-width="0.5" />
    <path d="M137.16 164.07H137.24" stroke="black" stroke-width="0.5" />
    <path d="M137.29 164.07H137.38" stroke="black" stroke-width="0.5" />
    <path d="M137.43 164.07H137.51" stroke="black" stroke-width="0.5" />
    <path d="M137.57 164.07H137.65" stroke="black" stroke-width="0.5" />
    <path d="M137.71 164.07H137.79" stroke="black" stroke-width="0.5" />
    <path d="M137.84 164.07H137.93" stroke="black" stroke-width="0.5" />
    <path d="M137.98 164.07H138.06" stroke="black" stroke-width="0.5" />
    <path d="M138.12 164.07H138.2" stroke="black" stroke-width="0.5" />
    <path d="M138.26 164.07H138.34" stroke="black" stroke-width="0.5" />
    <path d="M138.39 164.07H138.48" stroke="black" stroke-width="0.5" />
    <path d="M138.53 164.07H138.61" stroke="black" stroke-width="0.5" />
    <path d="M138.67 164.07H138.75" stroke="black" stroke-width="0.5" />
    <path d="M138.81 164.07H138.89" stroke="black" stroke-width="0.5" />
    <path d="M138.94 164.07H139.03" stroke="black" stroke-width="0.5" />
    <path d="M139.08 164.07H139.16" stroke="black" stroke-width="0.5" />
    <path d="M139.22 164.07H139.3" stroke="black" stroke-width="0.5" />
    <path d="M139.36 164.07H139.44" stroke="black" stroke-width="0.5" />
    <path d="M139.49 164.07H139.58" stroke="black" stroke-width="0.5" />
    <path d="M139.63 164.07H139.71" stroke="black" stroke-width="0.5" />
    <path d="M139.77 164.07H139.85" stroke="black" stroke-width="0.5" />
    <path d="M139.91 164.07H139.99" stroke="black" stroke-width="0.5" />
    <path d="M140.04 164.07H140.13" stroke="black" stroke-width="0.5" />
    <path d="M140.18 164.07H140.26" stroke="black" stroke-width="0.5" />
    <path d="M140.32 164.07H140.4" stroke="black" stroke-width="0.5" />
    <path d="M140.46 164.07H140.54" stroke="black" stroke-width="0.5" />
    <path d="M140.59 164.07H140.68" stroke="black" stroke-width="0.5" />
    <path d="M140.73 164.07H140.81" stroke="black" stroke-width="0.5" />
    <path d="M140.87 164.07H140.95" stroke="black" stroke-width="0.5" />
    <path d="M141 164.07H141.09" stroke="black" stroke-width="0.5" />
    <path d="M141.14 164.07H141.22" stroke="black" stroke-width="0.5" />
    <path d="M141.28 164.07H141.36" stroke="black" stroke-width="0.5" />
    <path d="M141.42 164.07H141.5" stroke="black" stroke-width="0.5" />
    <path d="M141.55 164.07H141.64" stroke="black" stroke-width="0.5" />
    <path d="M141.69 164.07H141.77" stroke="black" stroke-width="0.5" />
    <path d="M141.83 164.07H141.91" stroke="black" stroke-width="0.5" />
    <path d="M141.97 164.07H142.05" stroke="black" stroke-width="0.5" />
    <path d="M142.1 164.07H142.19" stroke="black" stroke-width="0.5" />
    <path d="M142.24 164.07H142.32" stroke="black" stroke-width="0.5" />
    <path d="M142.38 164.07H142.46" stroke="black" stroke-width="0.5" />
    <path d="M142.52 164.07H142.6" stroke="black" stroke-width="0.5" />
    <path d="M142.65 164.07H142.74" stroke="black" stroke-width="0.5" />
    <path d="M142.79 164.07H142.87" stroke="black" stroke-width="0.5" />
    <path d="M142.93 164.07H143.01" stroke="black" stroke-width="0.5" />
    <path d="M143.07 164.07H143.15" stroke="black" stroke-width="0.5" />
    <path d="M143.2 164.07H143.29" stroke="black" stroke-width="0.5" />
    <path d="M143.34 164.07H143.42" stroke="black" stroke-width="0.5" />
    <path d="M143.48 164.07H143.56" stroke="black" stroke-width="0.5" />
    <path d="M143.62 164.07H143.7" stroke="black" stroke-width="0.5" />
    <path d="M143.75 164.07H143.84" stroke="black" stroke-width="0.5" />
    <path d="M143.89 164.07H143.97" stroke="black" stroke-width="0.5" />
    <path d="M144.03 164.07H144.11" stroke="black" stroke-width="0.5" />
    <path d="M144.17 164.07H144.25" stroke="black" stroke-width="0.5" />
    <path d="M144.3 164.07H144.39" stroke="black" stroke-width="0.5" />
    <path d="M144.44 164.07H144.52" stroke="black" stroke-width="0.5" />
    <path d="M144.58 164.07H144.66" stroke="black" stroke-width="0.5" />
    <path d="M144.72 164.07H144.8" stroke="black" stroke-width="0.5" />
    <path d="M144.85 164.07H144.94" stroke="black" stroke-width="0.5" />
    <path d="M144.99 164.07H145.07" stroke="black" stroke-width="0.5" />
    <path d="M145.13 164.07H145.21" stroke="black" stroke-width="0.5" />
    <path d="M145.27 164.07H145.35" stroke="black" stroke-width="0.5" />
    <path d="M145.4 164.07H145.49" stroke="black" stroke-width="0.5" />
    <path d="M145.54 164.07H145.62" stroke="black" stroke-width="0.5" />
    <path d="M145.68 164.07H145.76" stroke="black" stroke-width="0.5" />
    <path d="M145.82 164.07H145.9" stroke="black" stroke-width="0.5" />
    <path d="M145.95 164.07H146.04" stroke="black" stroke-width="0.5" />
    <path d="M146.09 164.07H146.17" stroke="black" stroke-width="0.5" />
    <path d="M146.23 164.07H146.31" stroke="black" stroke-width="0.5" />
    <path d="M146.37 164.07H146.45" stroke="black" stroke-width="0.5" />
    <path d="M146.5 164.07H146.59" stroke="black" stroke-width="0.5" />
    <path d="M146.64 164.07H146.72" stroke="black" stroke-width="0.5" />
    <path d="M146.78 164.07H146.86" stroke="black" stroke-width="0.5" />
    <path d="M146.92 164.07H147" stroke="black" stroke-width="0.5" />
    <path d="M147.05 164.07H147.14" stroke="black" stroke-width="0.5" />
    <path d="M147.19 164.07H147.27" stroke="black" stroke-width="0.5" />
    <path d="M147.33 164.07H147.41" stroke="black" stroke-width="0.5" />
    <path d="M147.46 164.07H147.55" stroke="black" stroke-width="0.5" />
    <path d="M147.6 164.07H147.68" stroke="black" stroke-width="0.5" />
    <path d="M147.74 164.07H147.82" stroke="black" stroke-width="0.5" />
    <path d="M147.88 164.07H147.96" stroke="black" stroke-width="0.5" />
    <path d="M148.01 164.07H148.1" stroke="black" stroke-width="0.5" />
    <path d="M148.15 164.07H148.23" stroke="black" stroke-width="0.5" />
    <path d="M148.29 164.07H148.37" stroke="black" stroke-width="0.5" />
    <path d="M148.43 164.07H148.51" stroke="black" stroke-width="0.5" />
    <path d="M148.56 164.07H148.65" stroke="black" stroke-width="0.5" />
    <path d="M148.7 164.07H148.78" stroke="black" stroke-width="0.5" />
    <path d="M148.84 164.07H148.92" stroke="black" stroke-width="0.5" />
    <path d="M148.98 164.07H149.06" stroke="black" stroke-width="0.5" />
    <path d="M149.11 164.07H149.2" stroke="black" stroke-width="0.5" />
    <path d="M149.25 164.07H149.33" stroke="black" stroke-width="0.5" />
    <path d="M149.39 164.07H149.47" stroke="black" stroke-width="0.5" />
    <path d="M149.53 164.07H149.61" stroke="black" stroke-width="0.5" />
    <path d="M149.66 164.07H149.75" stroke="black" stroke-width="0.5" />
    <path d="M149.8 164.07H149.88" stroke="black" stroke-width="0.5" />
    <path d="M149.94 164.07H150.02" stroke="black" stroke-width="0.5" />
    <path d="M150.08 164.07H150.16" stroke="black" stroke-width="0.5" />
    <path d="M150.21 164.07H150.3" stroke="black" stroke-width="0.5" />
    <path d="M150.35 164.07H150.43" stroke="black" stroke-width="0.5" />
    <path d="M150.49 164.07H150.57" stroke="black" stroke-width="0.5" />
    <path d="M150.63 164.07H150.71" stroke="black" stroke-width="0.5" />
    <path d="M150.76 164.07H150.85" stroke="black" stroke-width="0.5" />
    <path d="M150.9 164.07H150.98" stroke="black" stroke-width="0.5" />
    <path d="M151.04 164.07H151.12" stroke="black" stroke-width="0.5" />
    <path d="M151.18 164.07H151.26" stroke="black" stroke-width="0.5" />
    <path d="M151.31 164.07H151.4" stroke="black" stroke-width="0.5" />
    <path d="M151.45 164.07H151.53" stroke="black" stroke-width="0.5" />
    <path d="M151.59 164.07H151.67" stroke="black" stroke-width="0.5" />
    <path d="M151.73 164.07H151.81" stroke="black" stroke-width="0.5" />
    <path d="M151.86 164.07H151.95" stroke="black" stroke-width="0.5" />
    <path d="M152 164.07H152.08" stroke="black" stroke-width="0.5" />
    <path d="M152.14 164.07H152.22" stroke="black" stroke-width="0.5" />
    <path d="M152.28 164.07H152.36" stroke="black" stroke-width="0.5" />
    <path d="M152.41 164.07H152.5" stroke="black" stroke-width="0.5" />
    <path d="M152.55 164.07H152.63" stroke="black" stroke-width="0.5" />
    <path d="M152.69 164.07H152.77" stroke="black" stroke-width="0.5" />
    <path d="M152.83 164.07H152.91" stroke="black" stroke-width="0.5" />
    <path d="M152.96 164.07H153.05" stroke="black" stroke-width="0.5" />
    <path d="M153.1 164.07H153.18" stroke="black" stroke-width="0.5" />
    <path d="M153.24 164.07H153.32" stroke="black" stroke-width="0.5" />
    <path d="M153.38 164.07H153.46" stroke="black" stroke-width="0.5" />
    <path d="M153.51 164.07H153.6" stroke="black" stroke-width="0.5" />
    <path d="M153.65 164.07H153.73" stroke="black" stroke-width="0.5" />
    <path d="M153.79 164.07H153.87" stroke="black" stroke-width="0.5" />
    <path d="M153.92 164.07H154.01" stroke="black" stroke-width="0.5" />
    <path d="M154.06 164.07H154.14" stroke="black" stroke-width="0.5" />
    <path d="M154.2 164.07H154.28" stroke="black" stroke-width="0.5" />
    <path d="M154.34 164.07H154.42" stroke="black" stroke-width="0.5" />
    <path d="M154.47 164.07H154.56" stroke="black" stroke-width="0.5" />
    <path d="M154.61 164.07H154.69" stroke="black" stroke-width="0.5" />
    <path d="M154.75 164.07H154.83" stroke="black" stroke-width="0.5" />
    <path d="M154.89 164.07H154.97" stroke="black" stroke-width="0.5" />
    <path d="M155.02 164.07H155.11" stroke="black" stroke-width="0.5" />
    <path d="M155.16 164.07H155.24" stroke="black" stroke-width="0.5" />
    <path d="M155.3 164.07H155.38" stroke="black" stroke-width="0.5" />
    <path d="M155.44 164.07H155.52" stroke="black" stroke-width="0.5" />
    <path d="M155.57 164.07H155.66" stroke="black" stroke-width="0.5" />
    <path d="M155.71 164.07H155.79" stroke="black" stroke-width="0.5" />
    <path d="M155.85 164.07H155.93" stroke="black" stroke-width="0.5" />
    <path d="M155.99 164.07H156.07" stroke="black" stroke-width="0.5" />
    <path d="M156.12 164.07H156.21" stroke="black" stroke-width="0.5" />
    <path d="M156.26 164.07H156.34" stroke="black" stroke-width="0.5" />
    <path d="M156.4 164.07H156.48" stroke="black" stroke-width="0.5" />
    <path d="M156.54 164.07H156.62" stroke="black" stroke-width="0.5" />
    <path d="M156.67 164.07H156.76" stroke="black" stroke-width="0.5" />
    <path d="M156.81 164.07H156.89" stroke="black" stroke-width="0.5" />
    <path d="M156.95 164.07H157.03" stroke="black" stroke-width="0.5" />
    <path d="M157.09 164.07H157.17" stroke="black" stroke-width="0.5" />
    <path d="M157.22 164.07H157.31" stroke="black" stroke-width="0.5" />
    <path d="M157.36 164.07H157.44" stroke="black" stroke-width="0.5" />
    <path d="M157.5 164.07H157.58" stroke="black" stroke-width="0.5" />
    <path d="M157.64 164.07H157.72" stroke="black" stroke-width="0.5" />
    <path d="M157.77 164.07H157.86" stroke="black" stroke-width="0.5" />
    <path d="M157.91 164.07H157.99" stroke="black" stroke-width="0.5" />
    <path d="M158.05 164.07H158.13" stroke="black" stroke-width="0.5" />
    <path d="M158.19 164.07H158.27" stroke="black" stroke-width="0.5" />
    <path d="M158.32 164.07H158.41" stroke="black" stroke-width="0.5" />
    <path d="M158.46 164.07H158.54" stroke="black" stroke-width="0.5" />
    <path d="M158.6 164.07H158.68" stroke="black" stroke-width="0.5" />
    <path d="M158.74 164.07H158.82" stroke="black" stroke-width="0.5" />
    <path d="M158.87 164.07H158.96" stroke="black" stroke-width="0.5" />
    <path d="M159.01 164.07H159.09" stroke="black" stroke-width="0.5" />
    <path d="M159.15 164.07H159.23" stroke="black" stroke-width="0.5" />
    <path d="M159.29 164.07H159.37" stroke="black" stroke-width="0.5" />
    <path d="M159.42 164.07H159.51" stroke="black" stroke-width="0.5" />
    <path d="M159.56 164.07H159.64" stroke="black" stroke-width="0.5" />
    <path d="M159.7 164.07H159.78" stroke="black" stroke-width="0.5" />
    <path d="M159.84 164.07H159.92" stroke="black" stroke-width="0.5" />
    <path d="M159.97 164.07H160.06" stroke="black" stroke-width="0.5" />
    <path d="M160.11 164.07H160.19" stroke="black" stroke-width="0.5" />
    <path d="M160.25 164.07H160.33" stroke="black" stroke-width="0.5" />
    <path d="M160.38 164.07H160.47" stroke="black" stroke-width="0.5" />
    <path d="M160.52 164.07H160.6" stroke="black" stroke-width="0.5" />
    <path d="M160.66 164.07H160.74" stroke="black" stroke-width="0.5" />
    <path d="M160.8 164.07H160.88" stroke="black" stroke-width="0.5" />
    <path d="M160.93 164.07H161.02" stroke="black" stroke-width="0.5" />
    <path d="M161.07 164.07H161.15" stroke="black" stroke-width="0.5" />
    <path d="M161.21 164.07H161.29" stroke="black" stroke-width="0.5" />
    <path d="M161.35 164.07H161.43" stroke="black" stroke-width="0.5" />
    <path d="M161.48 164.07H161.57" stroke="black" stroke-width="0.5" />
    <path d="M161.62 164.07H161.7" stroke="black" stroke-width="0.5" />
    <path d="M161.76 164.07H161.84" stroke="black" stroke-width="0.5" />
    <path d="M161.9 164.07H161.98" stroke="black" stroke-width="0.5" />
    <path d="M162.03 164.07H162.12" stroke="black" stroke-width="0.5" />
    <path d="M162.17 164.07H162.25" stroke="black" stroke-width="0.5" />
    <path d="M162.31 164.07H162.39" stroke="black" stroke-width="0.5" />
    <path d="M162.45 164.07H162.53" stroke="black" stroke-width="0.5" />
    <path d="M162.58 164.07H162.67" stroke="black" stroke-width="0.5" />
    <path d="M162.72 164.07H162.8" stroke="black" stroke-width="0.5" />
    <path d="M162.86 164.07H162.94" stroke="black" stroke-width="0.5" />
    <path d="M163 164.07H163.08" stroke="black" stroke-width="0.5" />
    <path d="M163.13 164.07H163.22" stroke="black" stroke-width="0.5" />
    <path d="M163.27 164.07H163.35" stroke="black" stroke-width="0.5" />
    <path d="M163.41 164.07H163.49" stroke="black" stroke-width="0.5" />
    <path d="M163.55 164.07H163.63" stroke="black" stroke-width="0.5" />
    <path d="M163.68 164.07H163.77" stroke="black" stroke-width="0.5" />
    <path d="M163.82 164.07H163.9" stroke="black" stroke-width="0.5" />
    <path d="M163.96 164.07H164.04" stroke="black" stroke-width="0.5" />
    <path d="M164.1 164.07H164.18" stroke="black" stroke-width="0.5" />
    <path d="M164.23 164.07H164.32" stroke="black" stroke-width="0.5" />
    <path d="M164.37 164.07H164.45" stroke="black" stroke-width="0.5" />
    <path d="M164.51 164.07H164.59" stroke="black" stroke-width="0.5" />
    <path d="M164.65 164.07H164.73" stroke="black" stroke-width="0.5" />
    <path d="M164.78 164.07H164.87" stroke="black" stroke-width="0.5" />
    <path d="M164.92 164.07H165" stroke="black" stroke-width="0.5" />
    <path d="M165.06 164.07H165.14" stroke="black" stroke-width="0.5" />
    <path d="M165.2 164.07H165.28" stroke="black" stroke-width="0.5" />
    <path d="M165.33 164.07H165.42" stroke="black" stroke-width="0.5" />
    <path d="M165.47 164.07H165.55" stroke="black" stroke-width="0.5" />
    <path d="M165.61 164.07H165.69" stroke="black" stroke-width="0.5" />
    <path d="M165.75 164.07H165.83" stroke="black" stroke-width="0.5" />
    <path d="M165.88 164.07H165.97" stroke="black" stroke-width="0.5" />
    <path d="M166.02 164.07H166.1" stroke="black" stroke-width="0.5" />
    <path d="M166.16 164.07H166.24" stroke="black" stroke-width="0.5" />
    <path d="M166.3 164.07H166.38" stroke="black" stroke-width="0.5" />
    <path d="M166.43 164.07H166.52" stroke="black" stroke-width="0.5" />
    <path d="M166.57 164.07H166.65" stroke="black" stroke-width="0.5" />
    <path d="M166.71 164.07H166.79" stroke="black" stroke-width="0.5" />
    <path d="M166.84 164.07H166.93" stroke="black" stroke-width="0.5" />
    <path d="M166.98 164.07H167.06" stroke="black" stroke-width="0.5" />
    <path d="M167.12 164.07H167.2" stroke="black" stroke-width="0.5" />
    <path d="M167.26 164.07H167.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.07V164.17" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.22V164.31" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.36V164.44" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.5V164.58" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.64V164.72" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.77V164.86" stroke="black" stroke-width="0.5" />
    <path d="M167.31 164.91V164.99" stroke="black" stroke-width="0.5" />
    <path d="M167.31 165.05V165.13" stroke="black" stroke-width="0.5" />
    <path d="M167.31 165.19V165.27" stroke="black" stroke-width="0.5" />
    <path d="M167.31 165.32V165.41" stroke="black" stroke-width="0.5" />
    <path d="M167.31 165.46V165.54" stroke="black" stroke-width="0.5" />
    <path d="M167.31 165.6V165.68" stroke="black" stroke-width="0.5" />
    <path d="M167.31 165.74V165.82" stroke="black" stroke-width="0.5" />
    <path d="M167.31 165.87V165.96" stroke="black" stroke-width="0.5" />
    <path d="M167.31 166.01V166.09" stroke="black" stroke-width="0.5" />
    <path d="M167.31 166.15V166.23" stroke="black" stroke-width="0.5" />
    <path d="M167.31 166.29V166.37" stroke="black" stroke-width="0.5" />
    <path d="M167.31 166.42V166.51" stroke="black" stroke-width="0.5" />
    <path d="M167.31 166.56V166.64" stroke="black" stroke-width="0.5" />
    <path d="M167.31 166.7V166.78" stroke="black" stroke-width="0.5" />
    <path d="M167.31 166.84V166.92" stroke="black" stroke-width="0.5" />
    <path d="M167.31 166.97V167.06" stroke="black" stroke-width="0.5" />
    <path d="M167.31 167.11V167.19" stroke="black" stroke-width="0.5" />
    <path d="M167.31 167.25V167.33" stroke="black" stroke-width="0.5" />
    <path d="M167.31 167.39V167.47" stroke="black" stroke-width="0.5" />
    <path d="M167.31 167.52V167.61" stroke="black" stroke-width="0.5" />
    <path d="M167.31 167.66V167.74" stroke="black" stroke-width="0.5" />
    <path d="M167.31 167.8V167.88" stroke="black" stroke-width="0.5" />
    <path d="M167.31 167.94V168.02" stroke="black" stroke-width="0.5" />
    <path d="M167.31 168.07V168.16" stroke="black" stroke-width="0.5" />
    <path d="M167.31 168.21V168.29" stroke="black" stroke-width="0.5" />
    <path d="M167.31 168.35V168.43" stroke="black" stroke-width="0.5" />
    <path d="M167.31 168.49V168.57" stroke="black" stroke-width="0.5" />
    <path d="M167.31 168.62V168.71" stroke="black" stroke-width="0.5" />
    <path d="M167.31 168.76V168.84" stroke="black" stroke-width="0.5" />
    <path d="M167.31 168.9V168.98" stroke="black" stroke-width="0.5" />
    <path d="M167.31 169.04V169.12" stroke="black" stroke-width="0.5" />
    <path d="M167.31 169.17V169.26" stroke="black" stroke-width="0.5" />
    <path d="M167.31 169.31V169.39" stroke="black" stroke-width="0.5" />
    <path d="M167.31 169.45V169.53" stroke="black" stroke-width="0.5" />
    <path d="M167.31 169.59V169.67" stroke="black" stroke-width="0.5" />
    <path d="M167.31 169.72V169.81" stroke="black" stroke-width="0.5" />
    <path d="M167.31 169.86V169.94" stroke="black" stroke-width="0.5" />
    <path d="M167.31 170V170.08" stroke="black" stroke-width="0.5" />
    <path d="M167.31 170.14V170.22" stroke="black" stroke-width="0.5" />
    <path d="M167.31 170.27V170.36" stroke="black" stroke-width="0.5" />
    <path d="M167.31 170.41V170.49" stroke="black" stroke-width="0.5" />
    <path d="M167.31 170.55V170.63" stroke="black" stroke-width="0.5" />
    <path d="M167.31 170.68V170.77" stroke="black" stroke-width="0.5" />
    <path d="M167.31 170.82V170.9" stroke="black" stroke-width="0.5" />
    <path d="M167.31 170.96V171.04" stroke="black" stroke-width="0.5" />
    <path d="M167.31 171.1V171.18" stroke="black" stroke-width="0.5" />
    <path d="M167.31 171.23V171.32" stroke="black" stroke-width="0.5" />
    <path d="M167.31 171.37V171.45" stroke="black" stroke-width="0.5" />
    <path d="M167.31 171.51V171.59" stroke="black" stroke-width="0.5" />
    <path d="M167.31 171.65V171.73" stroke="black" stroke-width="0.5" />
    <path d="M167.31 171.78V171.87" stroke="black" stroke-width="0.5" />
    <path d="M167.31 171.92V172" stroke="black" stroke-width="0.5" />
    <path d="M167.31 172.06V172.14" stroke="black" stroke-width="0.5" />
    <path d="M167.31 172.2V172.28" stroke="black" stroke-width="0.5" />
    <path d="M167.31 172.33V172.42" stroke="black" stroke-width="0.5" />
    <path d="M167.31 172.47V172.55" stroke="black" stroke-width="0.5" />
    <path d="M167.31 172.61V172.69" stroke="black" stroke-width="0.5" />
    <path d="M167.31 172.75V172.83" stroke="black" stroke-width="0.5" />
    <path d="M167.31 172.88V172.97" stroke="black" stroke-width="0.5" />
    <path d="M167.31 173.02V173.1" stroke="black" stroke-width="0.5" />
    <path d="M167.31 173.16V173.24" stroke="black" stroke-width="0.5" />
    <path d="M167.31 173.3V173.38" stroke="black" stroke-width="0.5" />
    <path d="M167.31 173.43V173.52" stroke="black" stroke-width="0.5" />
    <path d="M167.31 173.57V173.65" stroke="black" stroke-width="0.5" />
    <path d="M167.31 173.71V173.79" stroke="black" stroke-width="0.5" />
    <path d="M167.31 173.85V173.93" stroke="black" stroke-width="0.5" />
    <path d="M167.31 173.98V174.07" stroke="black" stroke-width="0.5" />
    <path d="M167.31 174.12V174.2" stroke="black" stroke-width="0.5" />
    <path d="M167.31 174.26V174.34" stroke="black" stroke-width="0.5" />
    <path d="M167.31 174.4V174.48" stroke="black" stroke-width="0.5" />
    <path d="M167.31 174.53V174.63" stroke="black" stroke-width="0.5" />
    <path d="M167.31 174.63H167.26" stroke="black" stroke-width="0.5" />
    <path d="M167.2 174.63H167.12" stroke="black" stroke-width="0.5" />
    <path d="M167.06 174.63H166.98" stroke="black" stroke-width="0.5" />
    <path d="M166.93 174.63H166.84" stroke="black" stroke-width="0.5" />
    <path d="M166.79 174.63H166.71" stroke="black" stroke-width="0.5" />
    <path d="M166.65 174.63H166.57" stroke="black" stroke-width="0.5" />
    <path d="M166.52 174.63H166.43" stroke="black" stroke-width="0.5" />
    <path d="M166.38 174.63H166.3" stroke="black" stroke-width="0.5" />
    <path d="M166.24 174.63H166.16" stroke="black" stroke-width="0.5" />
    <path d="M166.1 174.63H166.02" stroke="black" stroke-width="0.5" />
    <path d="M165.97 174.63H165.88" stroke="black" stroke-width="0.5" />
    <path d="M165.83 174.63H165.75" stroke="black" stroke-width="0.5" />
    <path d="M165.69 174.63H165.61" stroke="black" stroke-width="0.5" />
    <path d="M165.55 174.63H165.47" stroke="black" stroke-width="0.5" />
    <path d="M165.42 174.63H165.33" stroke="black" stroke-width="0.5" />
    <path d="M165.28 174.63H165.2" stroke="black" stroke-width="0.5" />
    <path d="M165.14 174.63H165.06" stroke="black" stroke-width="0.5" />
    <path d="M165 174.63H164.92" stroke="black" stroke-width="0.5" />
    <path d="M164.87 174.63H164.78" stroke="black" stroke-width="0.5" />
    <path d="M164.73 174.63H164.65" stroke="black" stroke-width="0.5" />
    <path d="M164.59 174.63H164.51" stroke="black" stroke-width="0.5" />
    <path d="M164.45 174.63H164.37" stroke="black" stroke-width="0.5" />
    <path d="M164.32 174.63H164.23" stroke="black" stroke-width="0.5" />
    <path d="M164.18 174.63H164.1" stroke="black" stroke-width="0.5" />
    <path d="M164.04 174.63H163.96" stroke="black" stroke-width="0.5" />
    <path d="M163.9 174.63H163.82" stroke="black" stroke-width="0.5" />
    <path d="M163.77 174.63H163.68" stroke="black" stroke-width="0.5" />
    <path d="M163.63 174.63H163.55" stroke="black" stroke-width="0.5" />
    <path d="M163.49 174.63H163.41" stroke="black" stroke-width="0.5" />
    <path d="M163.35 174.63H163.27" stroke="black" stroke-width="0.5" />
    <path d="M163.22 174.63H163.13" stroke="black" stroke-width="0.5" />
    <path d="M163.08 174.63H163" stroke="black" stroke-width="0.5" />
    <path d="M162.94 174.63H162.86" stroke="black" stroke-width="0.5" />
    <path d="M162.8 174.63H162.72" stroke="black" stroke-width="0.5" />
    <path d="M162.67 174.63H162.58" stroke="black" stroke-width="0.5" />
    <path d="M162.53 174.63H162.45" stroke="black" stroke-width="0.5" />
    <path d="M162.39 174.63H162.31" stroke="black" stroke-width="0.5" />
    <path d="M162.25 174.63H162.17" stroke="black" stroke-width="0.5" />
    <path d="M162.12 174.63H162.03" stroke="black" stroke-width="0.5" />
    <path d="M161.98 174.63H161.9" stroke="black" stroke-width="0.5" />
    <path d="M161.84 174.63H161.76" stroke="black" stroke-width="0.5" />
    <path d="M161.7 174.63H161.62" stroke="black" stroke-width="0.5" />
    <path d="M161.57 174.63H161.48" stroke="black" stroke-width="0.5" />
    <path d="M161.43 174.63H161.35" stroke="black" stroke-width="0.5" />
    <path d="M161.29 174.63H161.21" stroke="black" stroke-width="0.5" />
    <path d="M161.15 174.63H161.07" stroke="black" stroke-width="0.5" />
    <path d="M161.02 174.63H160.93" stroke="black" stroke-width="0.5" />
    <path d="M160.88 174.63H160.8" stroke="black" stroke-width="0.5" />
    <path d="M160.74 174.63H160.66" stroke="black" stroke-width="0.5" />
    <path d="M160.6 174.63H160.52" stroke="black" stroke-width="0.5" />
    <path d="M160.47 174.63H160.38" stroke="black" stroke-width="0.5" />
    <path d="M160.33 174.63H160.25" stroke="black" stroke-width="0.5" />
    <path d="M160.19 174.63H160.11" stroke="black" stroke-width="0.5" />
    <path d="M160.06 174.63H159.97" stroke="black" stroke-width="0.5" />
    <path d="M159.92 174.63H159.84" stroke="black" stroke-width="0.5" />
    <path d="M159.78 174.63H159.7" stroke="black" stroke-width="0.5" />
    <path d="M159.64 174.63H159.56" stroke="black" stroke-width="0.5" />
    <path d="M159.51 174.63H159.42" stroke="black" stroke-width="0.5" />
    <path d="M159.37 174.63H159.29" stroke="black" stroke-width="0.5" />
    <path d="M159.23 174.63H159.15" stroke="black" stroke-width="0.5" />
    <path d="M159.09 174.63H159.01" stroke="black" stroke-width="0.5" />
    <path d="M158.96 174.63H158.87" stroke="black" stroke-width="0.5" />
    <path d="M158.82 174.63H158.74" stroke="black" stroke-width="0.5" />
    <path d="M158.68 174.63H158.6" stroke="black" stroke-width="0.5" />
    <path d="M158.54 174.63H158.46" stroke="black" stroke-width="0.5" />
    <path d="M158.41 174.63H158.32" stroke="black" stroke-width="0.5" />
    <path d="M158.27 174.63H158.19" stroke="black" stroke-width="0.5" />
    <path d="M158.13 174.63H158.05" stroke="black" stroke-width="0.5" />
    <path d="M157.99 174.63H157.91" stroke="black" stroke-width="0.5" />
    <path d="M157.86 174.63H157.77" stroke="black" stroke-width="0.5" />
    <path d="M157.72 174.63H157.64" stroke="black" stroke-width="0.5" />
    <path d="M157.58 174.63H157.5" stroke="black" stroke-width="0.5" />
    <path d="M157.44 174.63H157.36" stroke="black" stroke-width="0.5" />
    <path d="M157.31 174.63H157.22" stroke="black" stroke-width="0.5" />
    <path d="M157.17 174.63H157.09" stroke="black" stroke-width="0.5" />
    <path d="M157.03 174.63H156.95" stroke="black" stroke-width="0.5" />
    <path d="M156.89 174.63H156.81" stroke="black" stroke-width="0.5" />
    <path d="M156.76 174.63H156.67" stroke="black" stroke-width="0.5" />
    <path d="M156.62 174.63H156.54" stroke="black" stroke-width="0.5" />
    <path d="M156.48 174.63H156.4" stroke="black" stroke-width="0.5" />
    <path d="M156.34 174.63H156.26" stroke="black" stroke-width="0.5" />
    <path d="M156.21 174.63H156.12" stroke="black" stroke-width="0.5" />
    <path d="M156.07 174.63H155.99" stroke="black" stroke-width="0.5" />
    <path d="M155.93 174.63H155.85" stroke="black" stroke-width="0.5" />
    <path d="M155.79 174.63H155.71" stroke="black" stroke-width="0.5" />
    <path d="M155.66 174.63H155.57" stroke="black" stroke-width="0.5" />
    <path d="M155.52 174.63H155.44" stroke="black" stroke-width="0.5" />
    <path d="M155.38 174.63H155.3" stroke="black" stroke-width="0.5" />
    <path d="M155.24 174.63H155.16" stroke="black" stroke-width="0.5" />
    <path d="M155.11 174.63H155.02" stroke="black" stroke-width="0.5" />
    <path d="M154.97 174.63H154.89" stroke="black" stroke-width="0.5" />
    <path d="M154.83 174.63H154.75" stroke="black" stroke-width="0.5" />
    <path d="M154.69 174.63H154.61" stroke="black" stroke-width="0.5" />
    <path d="M154.56 174.63H154.47" stroke="black" stroke-width="0.5" />
    <path d="M154.42 174.63H154.34" stroke="black" stroke-width="0.5" />
    <path d="M154.28 174.63H154.2" stroke="black" stroke-width="0.5" />
    <path d="M154.14 174.63H154.06" stroke="black" stroke-width="0.5" />
    <path d="M154.01 174.63H153.92" stroke="black" stroke-width="0.5" />
    <path d="M153.87 174.63H153.79" stroke="black" stroke-width="0.5" />
    <path d="M153.73 174.63H153.65" stroke="black" stroke-width="0.5" />
    <path d="M153.6 174.63H153.51" stroke="black" stroke-width="0.5" />
    <path d="M153.46 174.63H153.38" stroke="black" stroke-width="0.5" />
    <path d="M153.32 174.63H153.24" stroke="black" stroke-width="0.5" />
    <path d="M153.18 174.63H153.1" stroke="black" stroke-width="0.5" />
    <path d="M153.05 174.63H152.96" stroke="black" stroke-width="0.5" />
    <path d="M152.91 174.63H152.83" stroke="black" stroke-width="0.5" />
    <path d="M152.77 174.63H152.69" stroke="black" stroke-width="0.5" />
    <path d="M152.63 174.63H152.55" stroke="black" stroke-width="0.5" />
    <path d="M152.5 174.63H152.41" stroke="black" stroke-width="0.5" />
    <path d="M152.36 174.63H152.28" stroke="black" stroke-width="0.5" />
    <path d="M152.22 174.63H152.14" stroke="black" stroke-width="0.5" />
    <path d="M152.08 174.63H152" stroke="black" stroke-width="0.5" />
    <path d="M151.95 174.63H151.86" stroke="black" stroke-width="0.5" />
    <path d="M151.81 174.63H151.73" stroke="black" stroke-width="0.5" />
    <path d="M151.67 174.63H151.59" stroke="black" stroke-width="0.5" />
    <path d="M151.53 174.63H151.45" stroke="black" stroke-width="0.5" />
    <path d="M151.4 174.63H151.31" stroke="black" stroke-width="0.5" />
    <path d="M151.26 174.63H151.18" stroke="black" stroke-width="0.5" />
    <path d="M151.12 174.63H151.04" stroke="black" stroke-width="0.5" />
    <path d="M150.98 174.63H150.9" stroke="black" stroke-width="0.5" />
    <path d="M150.85 174.63H150.76" stroke="black" stroke-width="0.5" />
    <path d="M150.71 174.63H150.63" stroke="black" stroke-width="0.5" />
    <path d="M150.57 174.63H150.49" stroke="black" stroke-width="0.5" />
    <path d="M150.43 174.63H150.35" stroke="black" stroke-width="0.5" />
    <path d="M150.3 174.63H150.21" stroke="black" stroke-width="0.5" />
    <path d="M150.16 174.63H150.08" stroke="black" stroke-width="0.5" />
    <path d="M150.02 174.63H149.94" stroke="black" stroke-width="0.5" />
    <path d="M149.88 174.63H149.8" stroke="black" stroke-width="0.5" />
    <path d="M149.75 174.63H149.66" stroke="black" stroke-width="0.5" />
    <path d="M149.61 174.63H149.53" stroke="black" stroke-width="0.5" />
    <path d="M149.47 174.63H149.39" stroke="black" stroke-width="0.5" />
    <path d="M149.33 174.63H149.25" stroke="black" stroke-width="0.5" />
    <path d="M149.2 174.63H149.11" stroke="black" stroke-width="0.5" />
    <path d="M149.06 174.63H148.98" stroke="black" stroke-width="0.5" />
    <path d="M148.92 174.63H148.84" stroke="black" stroke-width="0.5" />
    <path d="M148.78 174.63H148.7" stroke="black" stroke-width="0.5" />
    <path d="M148.65 174.63H148.56" stroke="black" stroke-width="0.5" />
    <path d="M148.51 174.63H148.43" stroke="black" stroke-width="0.5" />
    <path d="M148.37 174.63H148.29" stroke="black" stroke-width="0.5" />
    <path d="M148.23 174.63H148.15" stroke="black" stroke-width="0.5" />
    <path d="M148.1 174.63H148.01" stroke="black" stroke-width="0.5" />
    <path d="M147.96 174.63H147.88" stroke="black" stroke-width="0.5" />
    <path d="M147.82 174.63H147.74" stroke="black" stroke-width="0.5" />
    <path d="M147.68 174.63H147.6" stroke="black" stroke-width="0.5" />
    <path d="M147.55 174.63H147.46" stroke="black" stroke-width="0.5" />
    <path d="M147.41 174.63H147.33" stroke="black" stroke-width="0.5" />
    <path d="M147.27 174.63H147.19" stroke="black" stroke-width="0.5" />
    <path d="M147.14 174.63H147.05" stroke="black" stroke-width="0.5" />
    <path d="M147 174.63H146.92" stroke="black" stroke-width="0.5" />
    <path d="M146.86 174.63H146.78" stroke="black" stroke-width="0.5" />
    <path d="M146.72 174.63H146.64" stroke="black" stroke-width="0.5" />
    <path d="M146.59 174.63H146.5" stroke="black" stroke-width="0.5" />
    <path d="M146.45 174.63H146.37" stroke="black" stroke-width="0.5" />
    <path d="M146.31 174.63H146.23" stroke="black" stroke-width="0.5" />
    <path d="M146.17 174.63H146.09" stroke="black" stroke-width="0.5" />
    <path d="M146.04 174.63H145.95" stroke="black" stroke-width="0.5" />
    <path d="M145.9 174.63H145.82" stroke="black" stroke-width="0.5" />
    <path d="M145.76 174.63H145.68" stroke="black" stroke-width="0.5" />
    <path d="M145.62 174.63H145.54" stroke="black" stroke-width="0.5" />
    <path d="M145.49 174.63H145.4" stroke="black" stroke-width="0.5" />
    <path d="M145.35 174.63H145.27" stroke="black" stroke-width="0.5" />
    <path d="M145.21 174.63H145.13" stroke="black" stroke-width="0.5" />
    <path d="M145.07 174.63H144.99" stroke="black" stroke-width="0.5" />
    <path d="M144.94 174.63H144.85" stroke="black" stroke-width="0.5" />
    <path d="M144.8 174.63H144.72" stroke="black" stroke-width="0.5" />
    <path d="M144.66 174.63H144.58" stroke="black" stroke-width="0.5" />
    <path d="M144.52 174.63H144.44" stroke="black" stroke-width="0.5" />
    <path d="M144.39 174.63H144.3" stroke="black" stroke-width="0.5" />
    <path d="M144.25 174.63H144.17" stroke="black" stroke-width="0.5" />
    <path d="M144.11 174.63H144.03" stroke="black" stroke-width="0.5" />
    <path d="M143.97 174.63H143.89" stroke="black" stroke-width="0.5" />
    <path d="M143.84 174.63H143.75" stroke="black" stroke-width="0.5" />
    <path d="M143.7 174.63H143.62" stroke="black" stroke-width="0.5" />
    <path d="M143.56 174.63H143.48" stroke="black" stroke-width="0.5" />
    <path d="M143.42 174.63H143.34" stroke="black" stroke-width="0.5" />
    <path d="M143.29 174.63H143.2" stroke="black" stroke-width="0.5" />
    <path d="M143.15 174.63H143.07" stroke="black" stroke-width="0.5" />
    <path d="M143.01 174.63H142.93" stroke="black" stroke-width="0.5" />
    <path d="M142.87 174.63H142.79" stroke="black" stroke-width="0.5" />
    <path d="M142.74 174.63H142.65" stroke="black" stroke-width="0.5" />
    <path d="M142.6 174.63H142.52" stroke="black" stroke-width="0.5" />
    <path d="M142.46 174.63H142.38" stroke="black" stroke-width="0.5" />
    <path d="M142.32 174.63H142.24" stroke="black" stroke-width="0.5" />
    <path d="M142.19 174.63H142.1" stroke="black" stroke-width="0.5" />
    <path d="M142.05 174.63H141.97" stroke="black" stroke-width="0.5" />
    <path d="M141.91 174.63H141.83" stroke="black" stroke-width="0.5" />
    <path d="M141.77 174.63H141.69" stroke="black" stroke-width="0.5" />
    <path d="M141.64 174.63H141.55" stroke="black" stroke-width="0.5" />
    <path d="M141.5 174.63H141.42" stroke="black" stroke-width="0.5" />
    <path d="M141.36 174.63H141.28" stroke="black" stroke-width="0.5" />
    <path d="M141.22 174.63H141.14" stroke="black" stroke-width="0.5" />
    <path d="M141.09 174.63H141" stroke="black" stroke-width="0.5" />
    <path d="M140.95 174.63H140.87" stroke="black" stroke-width="0.5" />
    <path d="M140.81 174.63H140.73" stroke="black" stroke-width="0.5" />
    <path d="M140.68 174.63H140.59" stroke="black" stroke-width="0.5" />
    <path d="M140.54 174.63H140.46" stroke="black" stroke-width="0.5" />
    <path d="M140.4 174.63H140.32" stroke="black" stroke-width="0.5" />
    <path d="M140.26 174.63H140.18" stroke="black" stroke-width="0.5" />
    <path d="M140.13 174.63H140.04" stroke="black" stroke-width="0.5" />
    <path d="M139.99 174.63H139.91" stroke="black" stroke-width="0.5" />
    <path d="M139.85 174.63H139.77" stroke="black" stroke-width="0.5" />
    <path d="M139.71 174.63H139.63" stroke="black" stroke-width="0.5" />
    <path d="M139.58 174.63H139.49" stroke="black" stroke-width="0.5" />
    <path d="M139.44 174.63H139.36" stroke="black" stroke-width="0.5" />
    <path d="M139.3 174.63H139.22" stroke="black" stroke-width="0.5" />
    <path d="M139.16 174.63H139.08" stroke="black" stroke-width="0.5" />
    <path d="M139.03 174.63H138.94" stroke="black" stroke-width="0.5" />
    <path d="M138.89 174.63H138.81" stroke="black" stroke-width="0.5" />
    <path d="M138.75 174.63H138.67" stroke="black" stroke-width="0.5" />
    <path d="M138.61 174.63H138.53" stroke="black" stroke-width="0.5" />
    <path d="M138.48 174.63H138.39" stroke="black" stroke-width="0.5" />
    <path d="M138.34 174.63H138.26" stroke="black" stroke-width="0.5" />
    <path d="M138.2 174.63H138.12" stroke="black" stroke-width="0.5" />
    <path d="M138.06 174.63H137.98" stroke="black" stroke-width="0.5" />
    <path d="M137.93 174.63H137.84" stroke="black" stroke-width="0.5" />
    <path d="M137.79 174.63H137.71" stroke="black" stroke-width="0.5" />
    <path d="M137.65 174.63H137.57" stroke="black" stroke-width="0.5" />
    <path d="M137.51 174.63H137.43" stroke="black" stroke-width="0.5" />
    <path d="M137.38 174.63H137.29" stroke="black" stroke-width="0.5" />
    <path d="M137.24 174.63H137.16" stroke="black" stroke-width="0.5" />
    <path d="M137.1 174.63H137.02" stroke="black" stroke-width="0.5" />
    <path d="M136.96 174.63H136.88" stroke="black" stroke-width="0.5" />
    <path d="M136.83 174.63H136.74" stroke="black" stroke-width="0.5" />
    <path d="M136.69 174.63H136.61" stroke="black" stroke-width="0.5" />
    <path d="M136.55 174.63H136.47" stroke="black" stroke-width="0.5" />
    <path d="M136.41 174.63H136.33" stroke="black" stroke-width="0.5" />
    <path d="M136.28 174.63H136.19" stroke="black" stroke-width="0.5" />
    <path d="M136.14 174.63H136.06" stroke="black" stroke-width="0.5" />
    <path d="M136 174.63H135.92" stroke="black" stroke-width="0.5" />
    <path d="M135.86 174.63H135.78" stroke="black" stroke-width="0.5" />
    <path d="M135.73 174.63H135.64" stroke="black" stroke-width="0.5" />
    <path d="M135.59 174.63H135.51" stroke="black" stroke-width="0.5" />
    <path d="M135.45 174.63H135.37" stroke="black" stroke-width="0.5" />
    <path d="M135.31 174.63H135.23" stroke="black" stroke-width="0.5" />
    <path d="M135.18 174.63H135.09" stroke="black" stroke-width="0.5" />
    <path d="M135.04 174.63H134.96" stroke="black" stroke-width="0.5" />
    <path d="M134.9 174.63H134.82" stroke="black" stroke-width="0.5" />
    <path d="M134.76 174.63H134.68" stroke="black" stroke-width="0.5" />
    <path d="M134.63 174.63H134.54" stroke="black" stroke-width="0.5" />
    <path d="M134.49 174.63H134.41" stroke="black" stroke-width="0.5" />
    <path d="M134.35 174.63H134.27" stroke="black" stroke-width="0.5" />
    <path d="M134.22 174.63H134.13" stroke="black" stroke-width="0.5" />
    <path d="M134.08 174.63H134" stroke="black" stroke-width="0.5" />
    <path d="M133.94 174.63H133.86" stroke="black" stroke-width="0.5" />
    <path d="M133.8 174.63H133.72" stroke="black" stroke-width="0.5" />
    <path d="M133.67 174.63H133.58" stroke="black" stroke-width="0.5" />
    <path d="M133.53 174.63H133.45" stroke="black" stroke-width="0.5" />
    <path d="M133.39 174.63H133.31" stroke="black" stroke-width="0.5" />
    <path d="M133.25 174.63H133.17" stroke="black" stroke-width="0.5" />
    <path d="M133.12 174.63H133.03" stroke="black" stroke-width="0.5" />
    <path d="M132.98 174.63H132.9" stroke="black" stroke-width="0.5" />
    <path d="M132.84 174.63H132.76" stroke="black" stroke-width="0.5" />
    <path d="M132.7 174.63H132.62" stroke="black" stroke-width="0.5" />
    <path d="M132.57 174.63H132.48" stroke="black" stroke-width="0.5" />
    <path d="M132.43 174.63H132.35" stroke="black" stroke-width="0.5" />
    <path d="M132.29 174.63H132.21" stroke="black" stroke-width="0.5" />
    <path d="M132.15 174.63H132.07" stroke="black" stroke-width="0.5" />
    <path d="M132.02 174.63H131.93" stroke="black" stroke-width="0.5" />
    <path d="M131.88 174.63H131.8" stroke="black" stroke-width="0.5" />
    <path d="M131.74 174.63H131.66" stroke="black" stroke-width="0.5" />
    <path d="M131.6 174.63H131.52" stroke="black" stroke-width="0.5" />
    <path d="M131.47 174.63H131.38" stroke="black" stroke-width="0.5" />
    <path d="M131.33 174.63H131.25" stroke="black" stroke-width="0.5" />
    <path d="M131.19 174.63H131.11" stroke="black" stroke-width="0.5" />
    <path d="M131.05 174.63H130.97" stroke="black" stroke-width="0.5" />
    <path d="M130.92 174.63H130.83" stroke="black" stroke-width="0.5" />
    <path d="M130.78 174.63H130.7" stroke="black" stroke-width="0.5" />
    <path d="M130.64 174.63H130.56" stroke="black" stroke-width="0.5" />
    <path d="M130.5 174.63H130.42" stroke="black" stroke-width="0.5" />
    <path d="M130.37 174.63H130.28" stroke="black" stroke-width="0.5" />
    <path d="M130.23 174.63H130.15" stroke="black" stroke-width="0.5" />
    <path d="M130.09 174.63H130.01" stroke="black" stroke-width="0.5" />
    <path d="M129.95 174.63H129.87" stroke="black" stroke-width="0.5" />
    <path d="M129.82 174.63H129.73" stroke="black" stroke-width="0.5" />
    <path d="M129.68 174.63H129.6" stroke="black" stroke-width="0.5" />
    <path d="M129.54 174.63H129.46" stroke="black" stroke-width="0.5" />
    <path d="M129.4 174.63H129.32" stroke="black" stroke-width="0.5" />
    <path d="M129.27 174.63H129.18" stroke="black" stroke-width="0.5" />
    <path d="M129.13 174.63H129.05" stroke="black" stroke-width="0.5" />
    <path d="M128.99 174.63H128.91" stroke="black" stroke-width="0.5" />
    <path d="M128.85 174.63H128.77" stroke="black" stroke-width="0.5" />
    <path d="M128.72 174.63H128.63" stroke="black" stroke-width="0.5" />
    <path d="M128.58 174.63H128.5" stroke="black" stroke-width="0.5" />
    <path d="M128.44 174.63H128.36" stroke="black" stroke-width="0.5" />
    <path d="M128.3 174.63H128.22" stroke="black" stroke-width="0.5" />
    <path d="M128.17 174.63H128.08" stroke="black" stroke-width="0.5" />
    <path d="M128.03 174.63H127.95" stroke="black" stroke-width="0.5" />
    <path d="M127.89 174.63H127.81" stroke="black" stroke-width="0.5" />
    <path d="M127.76 174.63H127.67" stroke="black" stroke-width="0.5" />
    <path d="M127.62 174.63H127.54" stroke="black" stroke-width="0.5" />
    <path d="M127.48 174.63H127.4" stroke="black" stroke-width="0.5" />
    <path d="M127.34 174.63H127.26" stroke="black" stroke-width="0.5" />
    <path d="M127.21 174.63H127.12" stroke="black" stroke-width="0.5" />
    <path d="M127.07 174.63H126.99" stroke="black" stroke-width="0.5" />
    <path d="M126.93 174.63H126.85" stroke="black" stroke-width="0.5" />
    <path d="M126.79 174.63H126.71" stroke="black" stroke-width="0.5" />
    <path d="M126.66 174.63H126.57" stroke="black" stroke-width="0.5" />
    <path d="M126.52 174.63H126.44" stroke="black" stroke-width="0.5" />
    <path d="M126.38 174.63H126.3" stroke="black" stroke-width="0.5" />
    <path d="M126.24 174.63H126.16" stroke="black" stroke-width="0.5" />
    <path d="M126.11 174.63H126.02" stroke="black" stroke-width="0.5" />
    <path d="M125.97 174.63H125.89" stroke="black" stroke-width="0.5" />
    <path d="M125.83 174.63H125.75" stroke="black" stroke-width="0.5" />
    <path d="M125.69 174.63H125.61" stroke="black" stroke-width="0.5" />
    <path d="M125.56 174.63H125.47" stroke="black" stroke-width="0.5" />
    <path d="M125.42 174.63H125.34" stroke="black" stroke-width="0.5" />
    <path d="M125.28 174.63H125.2" stroke="black" stroke-width="0.5" />
    <path d="M125.14 174.63H125.09" stroke="black" stroke-width="0.5" />
    <path d="M505.15 236.69L505.11 236.72" stroke="black" stroke-width="0.5" />
    <path d="M505.11 236.72L505.09 236.76" stroke="black" stroke-width="0.5" />
    <path d="M505.09 236.76V236.84" stroke="black" stroke-width="0.5" />
    <path d="M505.09 236.84L505.08 236.91" stroke="black" stroke-width="0.5" />
    <path d="M505.08 236.91L504.87 242.4" stroke="black" stroke-width="0.5" />
    <path d="M504.87 242.4V242.51" stroke="black" stroke-width="0.5" />
    <path d="M504.87 242.51V246.38" stroke="black" stroke-width="0.5" />
    <path d="M504.87 246.38V246.51" stroke="black" stroke-width="0.5" />
    <path d="M504.87 246.51L505.08 261.78" stroke="black" stroke-width="0.5" />
    <path d="M505.08 261.78L505.09 261.85" stroke="black" stroke-width="0.5" />
    <path d="M505.09 261.85V261.93" stroke="black" stroke-width="0.5" />
    <path d="M505.09 261.93L505.11 261.97" stroke="black" stroke-width="0.5" />
    <path d="M505.11 261.97L505.15 262" stroke="black" stroke-width="0.5" />
    <path d="M505.15 262H507.02" stroke="black" stroke-width="0.5" />
    <path d="M507.02 262L507.06 261.97" stroke="black" stroke-width="0.5" />
    <path d="M507.06 261.97L507.07 261.93" stroke="black" stroke-width="0.5" />
    <path d="M507.07 261.93L507.08 261.85" stroke="black" stroke-width="0.5" />
    <path d="M507.08 261.85V261.78" stroke="black" stroke-width="0.5" />
    <path d="M507.08 261.78L507.3 246.51" stroke="black" stroke-width="0.5" />
    <path d="M507.3 246.51V246.38" stroke="black" stroke-width="0.5" />
    <path d="M507.3 246.38V242.51" stroke="black" stroke-width="0.5" />
    <path d="M507.3 242.51V242.4" stroke="black" stroke-width="0.5" />
    <path d="M507.3 242.4L507.08 236.91" stroke="black" stroke-width="0.5" />
    <path d="M507.08 236.91V236.84" stroke="black" stroke-width="0.5" />
    <path d="M507.08 236.84L507.07 236.76" stroke="black" stroke-width="0.5" />
    <path d="M507.07 236.76L507.06 236.72" stroke="black" stroke-width="0.5" />
    <path d="M507.06 236.72L507.02 236.69" stroke="black" stroke-width="0.5" />
    <path d="M507.02 236.69H505.15" stroke="black" stroke-width="0.5" />
    <path d="M505.14 242.74H507.04" stroke="black" stroke-width="0.5" />
    <path d="M507.04 242.74L507.09 242.77" stroke="black" stroke-width="0.5" />
    <path d="M507.09 242.77L507.12 242.82" stroke="black" stroke-width="0.5" />
    <path d="M507.12 242.82V246.13" stroke="black" stroke-width="0.5" />
    <path d="M507.12 246.13L507.09 246.17" stroke="black" stroke-width="0.5" />
    <path d="M507.09 246.17L507.03 246.2" stroke="black" stroke-width="0.5" />
    <path d="M507.03 246.2H505.14" stroke="black" stroke-width="0.5" />
    <path d="M505.14 246.2L505.07 246.17" stroke="black" stroke-width="0.5" />
    <path d="M505.07 246.17L505.04 246.12" stroke="black" stroke-width="0.5" />
    <path d="M505.04 246.12V242.83" stroke="black" stroke-width="0.5" />
    <path d="M505.04 242.83L505.07 242.77" stroke="black" stroke-width="0.5" />
    <path d="M505.07 242.77L505.14 242.74" stroke="black" stroke-width="0.5" />
    <path d="M505.14 242.74L505.07 242.77" stroke="black" stroke-width="0.5" />
    <path d="M505.07 242.77L505.04 242.83" stroke="black" stroke-width="0.5" />
    <path d="M505.04 242.83V246.12" stroke="black" stroke-width="0.5" />
    <path d="M505.04 246.12L505.07 246.17" stroke="black" stroke-width="0.5" />
    <path d="M505.07 246.17L505.14 246.2" stroke="black" stroke-width="0.5" />
    <path d="M505.14 246.2H507.03" stroke="black" stroke-width="0.5" />
    <path d="M507.03 246.2L507.09 246.17" stroke="black" stroke-width="0.5" />
    <path d="M507.09 246.17L507.11 246.13" stroke="black" stroke-width="0.5" />
    <path d="M507.11 246.13V242.82" stroke="black" stroke-width="0.5" />
    <path d="M507.11 242.82L507.09 242.77" stroke="black" stroke-width="0.5" />
    <path d="M507.09 242.77L507.04 242.74" stroke="black" stroke-width="0.5" />
    <path d="M507.04 242.74H505.14" stroke="black" stroke-width="0.5" />
    <path d="M505.3 242.92L505.25 242.95" stroke="black" stroke-width="0.5" />
    <path d="M505.25 242.95L505.22 243.01" stroke="black" stroke-width="0.5" />
    <path d="M505.22 243.01V245.94" stroke="black" stroke-width="0.5" />
    <path d="M505.22 245.94L505.25 245.99" stroke="black" stroke-width="0.5" />
    <path d="M505.25 245.99L505.3 246.02" stroke="black" stroke-width="0.5" />
    <path d="M505.3 246.02H506.86" stroke="black" stroke-width="0.5" />
    <path d="M506.86 246.02L506.91 245.99" stroke="black" stroke-width="0.5" />
    <path d="M506.91 245.99L506.93 245.94" stroke="black" stroke-width="0.5" />
    <path d="M506.93 245.94V243.01" stroke="black" stroke-width="0.5" />
    <path d="M506.93 243.01L506.89 242.94" stroke="black" stroke-width="0.5" />
    <path d="M506.89 242.94L506.82 242.92" stroke="black" stroke-width="0.5" />
    <path d="M506.82 242.92H505.3" stroke="black" stroke-width="0.5" />
    <path d="M507.29 238.26H505.58" stroke="black" stroke-width="0.5" />
    <path d="M505.58 238.26L505.33 238.34" stroke="black" stroke-width="0.5" />
    <path d="M505.33 250.6L505.58 250.68" stroke="black" stroke-width="0.5" />
    <path d="M505.58 250.68H507.29" stroke="black" stroke-width="0.5" />
    <path d="M507.29 250.68L507.44 250.62" stroke="black" stroke-width="0.5" />
    <path d="M507.44 250.62L507.51 250.46" stroke="black" stroke-width="0.5" />
    <path d="M507.51 250.46V238.48" stroke="black" stroke-width="0.5" />
    <path d="M507.51 238.48L507.44 238.32" stroke="black" stroke-width="0.5" />
    <path d="M507.44 238.32L507.29 238.26" stroke="black" stroke-width="0.5" />
    <path d="M505.14 242.81L505.11 242.83" stroke="black" stroke-width="0.5" />
    <path d="M505.11 242.83V246.12" stroke="black" stroke-width="0.5" />
    <path d="M505.11 246.12L505.14 246.13" stroke="black" stroke-width="0.5" />
    <path d="M505.14 246.13H507.02" stroke="black" stroke-width="0.5" />
    <path d="M507.02 246.13L507.05 246.12" stroke="black" stroke-width="0.5" />
    <path d="M507.05 246.12V242.82" stroke="black" stroke-width="0.5" />
    <path d="M507.05 242.82L507.02 242.81" stroke="black" stroke-width="0.5" />
    <path d="M507.02 242.81H505.14" stroke="black" stroke-width="0.5" />
    <path d="M506.08 260.2L505.65 260.32" stroke="black" stroke-width="0.5" />
    <path d="M505.65 260.32L505.33 260.64" stroke="black" stroke-width="0.5" />
    <path d="M505.33 260.64L505.21 261.07" stroke="black" stroke-width="0.5" />
    <path d="M505.21 261.07L505.33 261.51" stroke="black" stroke-width="0.5" />
    <path d="M505.33 261.51L505.65 261.83" stroke="black" stroke-width="0.5" />
    <path d="M505.65 261.83L506.08 261.94" stroke="black" stroke-width="0.5" />
    <path d="M506.08 261.94L506.52 261.83" stroke="black" stroke-width="0.5" />
    <path d="M506.52 261.83L506.84 261.51" stroke="black" stroke-width="0.5" />
    <path d="M506.84 261.51L506.95 261.07" stroke="black" stroke-width="0.5" />
    <path d="M506.95 261.07L506.84 260.64" stroke="black" stroke-width="0.5" />
    <path d="M506.84 260.64L506.52 260.32" stroke="black" stroke-width="0.5" />
    <path d="M506.52 260.32L506.08 260.2" stroke="black" stroke-width="0.5" />
    <path d="M506.08 236.75L505.65 236.86" stroke="black" stroke-width="0.5" />
    <path d="M505.65 236.86L505.33 237.18" stroke="black" stroke-width="0.5" />
    <path d="M505.33 237.18L505.21 237.62" stroke="black" stroke-width="0.5" />
    <path d="M505.21 237.62L505.33 238.05" stroke="black" stroke-width="0.5" />
    <path d="M505.33 238.05L505.65 238.37" stroke="black" stroke-width="0.5" />
    <path d="M505.65 238.37L506.08 238.48" stroke="black" stroke-width="0.5" />
    <path d="M506.08 238.48L506.52 238.37" stroke="black" stroke-width="0.5" />
    <path d="M506.52 238.37L506.84 238.05" stroke="black" stroke-width="0.5" />
    <path d="M506.84 238.05L506.95 237.62" stroke="black" stroke-width="0.5" />
    <path d="M506.95 237.62L506.84 237.18" stroke="black" stroke-width="0.5" />
    <path d="M506.84 237.18L506.52 236.86" stroke="black" stroke-width="0.5" />
    <path d="M506.52 236.86L506.08 236.75" stroke="black" stroke-width="0.5" />
    <path d="M506.64 261.51H506.54" stroke="black" stroke-width="0.5" />
    <path d="M506.54 261.51L506.59 261.57" stroke="black" stroke-width="0.5" />
    <path d="M506.59 261.57L506.64 261.51" stroke="black" stroke-width="0.5" />
    <path d="M505.76 261.51L505.71 261.57" stroke="black" stroke-width="0.5" />
    <path d="M505.71 261.57H505.81" stroke="black" stroke-width="0.5" />
    <path d="M505.81 261.57L505.76 261.51" stroke="black" stroke-width="0.5" />
    <path d="M506.19 261.42H506.17" stroke="black" stroke-width="0.5" />
    <path d="M506.17 261.42V261.66" stroke="black" stroke-width="0.5" />
    <path d="M506.17 261.66H506.19" stroke="black" stroke-width="0.5" />
    <path d="M506.19 261.66V261.42" stroke="black" stroke-width="0.5" />
    <path d="M506.11 259.15V259.99" stroke="black" stroke-width="0.5" />
    <path d="M506.11 259.99H506.01" stroke="black" stroke-width="0.5" />
    <path d="M506.01 259.99V260.06" stroke="black" stroke-width="0.5" />
    <path d="M506.01 260.06H505.92" stroke="black" stroke-width="0.5" />
    <path d="M505.92 260.06H505.82" stroke="black" stroke-width="0.5" />
    <path d="M505.82 260.06H505.77" stroke="black" stroke-width="0.5" />
    <path d="M505.77 260.06L505.64 260.07" stroke="black" stroke-width="0.5" />
    <path d="M505.64 260.07H505.58" stroke="black" stroke-width="0.5" />
    <path d="M505.58 260.07L505.5 260.09" stroke="black" stroke-width="0.5" />
    <path d="M505.5 260.09L505.43 260.13" stroke="black" stroke-width="0.5" />
    <path d="M505.43 260.13L505.37 260.19" stroke="black" stroke-width="0.5" />
    <path d="M505.37 260.19L505.34 260.23" stroke="black" stroke-width="0.5" />
    <path d="M505.34 260.23L505.33 260.26" stroke="black" stroke-width="0.5" />
    <path d="M505.33 260.26L505.29 260.35" stroke="black" stroke-width="0.5" />
    <path d="M505.29 260.35V260.8" stroke="black" stroke-width="0.5" />
    <path d="M505.29 260.8V260.98" stroke="black" stroke-width="0.5" />
    <path d="M505.29 260.98L505.27 260.99" stroke="black" stroke-width="0.5" />
    <path d="M505.27 260.99L505.25 261" stroke="black" stroke-width="0.5" />
    <path d="M505.25 261L505.23 261.02" stroke="black" stroke-width="0.5" />
    <path d="M505.23 261.02L505.22 261.03" stroke="black" stroke-width="0.5" />
    <path d="M505.22 261.03L505.2 261.04" stroke="black" stroke-width="0.5" />
    <path d="M505.2 261.04L505.18 261.07" stroke="black" stroke-width="0.5" />
    <path d="M505.18 261.07V261.5" stroke="black" stroke-width="0.5" />
    <path d="M505.18 261.5L505.19 261.66" stroke="black" stroke-width="0.5" />
    <path d="M505.19 261.66L505.21 261.7" stroke="black" stroke-width="0.5" />
    <path d="M505.21 261.7L505.22 261.72" stroke="black" stroke-width="0.5" />
    <path d="M505.22 261.72L505.23 261.74" stroke="black" stroke-width="0.5" />
    <path d="M505.23 261.74L505.25 261.76" stroke="black" stroke-width="0.5" />
    <path d="M505.25 261.76L505.28 261.78" stroke="black" stroke-width="0.5" />
    <path d="M505.28 261.78L505.3 261.79" stroke="black" stroke-width="0.5" />
    <path d="M505.3 261.79L505.34 261.81" stroke="black" stroke-width="0.5" />
    <path d="M505.34 261.81H507" stroke="black" stroke-width="0.5" />
    <path d="M507 261.81L507.05 261.79" stroke="black" stroke-width="0.5" />
    <path d="M507.05 261.79L507.09 261.77" stroke="black" stroke-width="0.5" />
    <path d="M507.09 261.77L507.1 261.76" stroke="black" stroke-width="0.5" />
    <path d="M507.1 261.76L507.13 261.72" stroke="black" stroke-width="0.5" />
    <path d="M507.13 261.72L507.14 261.71" stroke="black" stroke-width="0.5" />
    <path d="M507.14 261.71L507.15 261.69" stroke="black" stroke-width="0.5" />
    <path d="M507.15 261.69L507.16 261.66" stroke="black" stroke-width="0.5" />
    <path d="M507.16 261.66L507.17 261.52" stroke="black" stroke-width="0.5" />
    <path d="M507.17 261.52V261.09" stroke="black" stroke-width="0.5" />
    <path d="M507.17 261.09V261.07" stroke="black" stroke-width="0.5" />
    <path d="M507.17 261.07L507.15 261.04" stroke="black" stroke-width="0.5" />
    <path d="M507.15 261.04L507.13 261.03" stroke="black" stroke-width="0.5" />
    <path d="M507.13 261.03L507.11 261" stroke="black" stroke-width="0.5" />
    <path d="M507.11 261L507.09 260.99" stroke="black" stroke-width="0.5" />
    <path d="M507.09 260.99L507.07 260.98" stroke="black" stroke-width="0.5" />
    <path d="M507.07 260.98V260.8" stroke="black" stroke-width="0.5" />
    <path d="M507.07 260.8L507.06 260.35" stroke="black" stroke-width="0.5" />
    <path d="M507.06 260.35L507.03 260.26" stroke="black" stroke-width="0.5" />
    <path d="M507.03 260.26L507.01 260.23" stroke="black" stroke-width="0.5" />
    <path d="M507.01 260.23L507 260.21" stroke="black" stroke-width="0.5" />
    <path d="M507 260.21L506.93 260.14" stroke="black" stroke-width="0.5" />
    <path d="M506.93 260.14L506.92 260.13" stroke="black" stroke-width="0.5" />
    <path d="M506.92 260.13L506.86 260.09" stroke="black" stroke-width="0.5" />
    <path d="M506.86 260.09L506.8 260.07" stroke="black" stroke-width="0.5" />
    <path d="M506.8 260.07H506.78" stroke="black" stroke-width="0.5" />
    <path d="M506.78 260.07L506.74 260.06" stroke="black" stroke-width="0.5" />
    <path d="M506.74 260.06H506.58" stroke="black" stroke-width="0.5" />
    <path d="M506.58 260.06H506.54" stroke="black" stroke-width="0.5" />
    <path d="M506.54 260.06H506.43" stroke="black" stroke-width="0.5" />
    <path d="M506.43 260.06H506.35" stroke="black" stroke-width="0.5" />
    <path d="M506.35 260.06V259.99" stroke="black" stroke-width="0.5" />
    <path d="M506.35 259.99H506.24" stroke="black" stroke-width="0.5" />
    <path d="M506.24 259.99V259.15" stroke="black" stroke-width="0.5" />
    <path d="M506.24 259.15H506.11" stroke="black" stroke-width="0.5" />
    <path d="M505.37 261.26L505.32 261.27" stroke="black" stroke-width="0.5" />
    <path d="M505.32 261.27L505.28 261.31" stroke="black" stroke-width="0.5" />
    <path d="M505.28 261.31V261.34" stroke="black" stroke-width="0.5" />
    <path d="M505.28 261.34V261.63" stroke="black" stroke-width="0.5" />
    <path d="M505.28 261.63V261.69" stroke="black" stroke-width="0.5" />
    <path d="M505.28 261.69V261.71" stroke="black" stroke-width="0.5" />
    <path d="M505.28 261.71L505.3 261.75" stroke="black" stroke-width="0.5" />
    <path d="M505.3 261.75L505.35 261.77" stroke="black" stroke-width="0.5" />
    <path d="M505.35 261.77H507" stroke="black" stroke-width="0.5" />
    <path d="M507 261.77L507.06 261.74" stroke="black" stroke-width="0.5" />
    <path d="M507.06 261.74L507.08 261.71" stroke="black" stroke-width="0.5" />
    <path d="M507.08 261.71V261.39" stroke="black" stroke-width="0.5" />
    <path d="M507.08 261.39V261.33" stroke="black" stroke-width="0.5" />
    <path d="M507.08 261.33L507.06 261.29" stroke="black" stroke-width="0.5" />
    <path d="M507.06 261.29L507.01 261.26" stroke="black" stroke-width="0.5" />
    <path d="M507.01 261.26H505.37" stroke="black" stroke-width="0.5" />
    <path d="M507.94 236.67V262.18" stroke="black" stroke-width="0.5" />
    <path d="M507.02 370.73H505.12" stroke="black" stroke-width="0.5" />
    <path d="M505.12 370.73L505.07 370.7" stroke="black" stroke-width="0.5" />
    <path d="M505.07 370.7L505.04 370.65" stroke="black" stroke-width="0.5" />
    <path d="M505.04 370.65V367.35" stroke="black" stroke-width="0.5" />
    <path d="M505.04 367.35L505.07 367.3" stroke="black" stroke-width="0.5" />
    <path d="M505.07 367.3L505.13 367.27" stroke="black" stroke-width="0.5" />
    <path d="M505.13 367.27H507.02" stroke="black" stroke-width="0.5" />
    <path d="M507.02 367.27L507.09 367.3" stroke="black" stroke-width="0.5" />
    <path d="M507.09 367.3L507.11 367.36" stroke="black" stroke-width="0.5" />
    <path d="M507.11 367.36V370.65" stroke="black" stroke-width="0.5" />
    <path d="M507.11 370.65L507.08 370.71" stroke="black" stroke-width="0.5" />
    <path d="M507.08 370.71L507.02 370.73" stroke="black" stroke-width="0.5" />
    <path d="M507.02 370.73L507.08 370.71" stroke="black" stroke-width="0.5" />
    <path d="M507.08 370.71L507.11 370.65" stroke="black" stroke-width="0.5" />
    <path d="M507.11 370.65V367.36" stroke="black" stroke-width="0.5" />
    <path d="M507.11 367.36L507.09 367.3" stroke="black" stroke-width="0.5" />
    <path d="M507.09 367.3L507.02 367.27" stroke="black" stroke-width="0.5" />
    <path d="M507.02 367.27H505.13" stroke="black" stroke-width="0.5" />
    <path d="M505.13 367.27L505.07 367.3" stroke="black" stroke-width="0.5" />
    <path d="M505.07 367.3L505.04 367.35" stroke="black" stroke-width="0.5" />
    <path d="M505.04 367.35V370.65" stroke="black" stroke-width="0.5" />
    <path d="M505.04 370.65L505.07 370.7" stroke="black" stroke-width="0.5" />
    <path d="M505.07 370.7L505.12 370.73" stroke="black" stroke-width="0.5" />
    <path d="M505.12 370.73H507.02" stroke="black" stroke-width="0.5" />
    <path d="M506.86 370.55L506.9 370.52" stroke="black" stroke-width="0.5" />
    <path d="M506.9 370.52L506.93 370.47" stroke="black" stroke-width="0.5" />
    <path d="M506.93 370.47V367.53" stroke="black" stroke-width="0.5" />
    <path d="M506.93 367.53L506.9 367.48" stroke="black" stroke-width="0.5" />
    <path d="M506.9 367.48L506.85 367.45" stroke="black" stroke-width="0.5" />
    <path d="M506.85 367.45H505.3" stroke="black" stroke-width="0.5" />
    <path d="M505.3 367.45L505.25 367.48" stroke="black" stroke-width="0.5" />
    <path d="M505.25 367.48L505.22 367.54" stroke="black" stroke-width="0.5" />
    <path d="M505.22 367.54V370.47" stroke="black" stroke-width="0.5" />
    <path d="M505.22 370.47L505.27 370.53" stroke="black" stroke-width="0.5" />
    <path d="M505.27 370.53L505.34 370.55" stroke="black" stroke-width="0.5" />
    <path d="M505.34 370.55H506.86" stroke="black" stroke-width="0.5" />
    <path d="M507.28 375.21L507.43 375.16" stroke="black" stroke-width="0.5" />
    <path d="M507.43 375.16L507.51 375" stroke="black" stroke-width="0.5" />
    <path d="M507.51 375V363.01" stroke="black" stroke-width="0.5" />
    <path d="M507.51 363.01L507.43 362.85" stroke="black" stroke-width="0.5" />
    <path d="M507.43 362.85L507.28 362.79" stroke="black" stroke-width="0.5" />
    <path d="M507.28 362.79H505.57" stroke="black" stroke-width="0.5" />
    <path d="M505.57 362.79L505.33 362.88" stroke="black" stroke-width="0.5" />
    <path d="M505.33 375.13L505.57 375.21" stroke="black" stroke-width="0.5" />
    <path d="M505.57 375.21H507.28" stroke="black" stroke-width="0.5" />
    <path d="M506.08 353.27L506.51 353.15" stroke="black" stroke-width="0.5" />
    <path d="M506.51 353.15L506.83 352.84" stroke="black" stroke-width="0.5" />
    <path d="M506.83 352.84L506.95 352.4" stroke="black" stroke-width="0.5" />
    <path d="M506.95 352.4L506.83 351.97" stroke="black" stroke-width="0.5" />
    <path d="M506.83 351.97L506.51 351.65" stroke="black" stroke-width="0.5" />
    <path d="M506.51 351.65L506.08 351.53" stroke="black" stroke-width="0.5" />
    <path d="M506.08 351.53L505.64 351.65" stroke="black" stroke-width="0.5" />
    <path d="M505.64 351.65L505.32 351.97" stroke="black" stroke-width="0.5" />
    <path d="M505.32 351.97L505.21 352.4" stroke="black" stroke-width="0.5" />
    <path d="M505.21 352.4L505.32 352.84" stroke="black" stroke-width="0.5" />
    <path d="M505.32 352.84L505.64 353.15" stroke="black" stroke-width="0.5" />
    <path d="M505.64 353.15L506.08 353.27" stroke="black" stroke-width="0.5" />
    <path d="M506.08 376.73L506.51 376.61" stroke="black" stroke-width="0.5" />
    <path d="M506.51 376.61L506.83 376.29" stroke="black" stroke-width="0.5" />
    <path d="M506.83 376.29L506.95 375.86" stroke="black" stroke-width="0.5" />
    <path d="M506.95 375.86L506.83 375.42" stroke="black" stroke-width="0.5" />
    <path d="M506.83 375.42L506.51 375.11" stroke="black" stroke-width="0.5" />
    <path d="M506.51 375.11L506.08 374.99" stroke="black" stroke-width="0.5" />
    <path d="M506.08 374.99L505.64 375.11" stroke="black" stroke-width="0.5" />
    <path d="M505.64 375.11L505.32 375.42" stroke="black" stroke-width="0.5" />
    <path d="M505.32 375.42L505.21 375.86" stroke="black" stroke-width="0.5" />
    <path d="M505.21 375.86L505.32 376.29" stroke="black" stroke-width="0.5" />
    <path d="M505.32 376.29L505.64 376.61" stroke="black" stroke-width="0.5" />
    <path d="M505.64 376.61L506.08 376.73" stroke="black" stroke-width="0.5" />
    <path d="M507.01 376.79L507.05 376.75" stroke="black" stroke-width="0.5" />
    <path d="M507.05 376.75L507.07 376.71" stroke="black" stroke-width="0.5" />
    <path d="M507.07 376.71L507.08 376.63" stroke="black" stroke-width="0.5" />
    <path d="M507.08 376.63V376.56" stroke="black" stroke-width="0.5" />
    <path d="M507.08 376.56L507.29 371.08" stroke="black" stroke-width="0.5" />
    <path d="M507.29 371.08V370.96" stroke="black" stroke-width="0.5" />
    <path d="M507.29 370.96V367.1" stroke="black" stroke-width="0.5" />
    <path d="M507.29 367.1V366.96" stroke="black" stroke-width="0.5" />
    <path d="M507.29 366.96L507.08 351.7" stroke="black" stroke-width="0.5" />
    <path d="M507.08 351.7V351.63" stroke="black" stroke-width="0.5" />
    <path d="M507.08 351.63L507.07 351.55" stroke="black" stroke-width="0.5" />
    <path d="M507.07 351.55L507.05 351.51" stroke="black" stroke-width="0.5" />
    <path d="M507.05 351.51L507.01 351.47" stroke="black" stroke-width="0.5" />
    <path d="M507.01 351.47H505.15" stroke="black" stroke-width="0.5" />
    <path d="M505.15 351.47L505.1 351.51" stroke="black" stroke-width="0.5" />
    <path d="M505.1 351.51L505.09 351.55" stroke="black" stroke-width="0.5" />
    <path d="M505.09 351.55L505.08 351.63" stroke="black" stroke-width="0.5" />
    <path d="M505.08 351.63V351.7" stroke="black" stroke-width="0.5" />
    <path d="M505.08 351.7L504.86 366.96" stroke="black" stroke-width="0.5" />
    <path d="M504.86 366.96V367.1" stroke="black" stroke-width="0.5" />
    <path d="M504.86 367.1V370.96" stroke="black" stroke-width="0.5" />
    <path d="M504.86 370.96V371.08" stroke="black" stroke-width="0.5" />
    <path d="M504.86 371.08L505.08 376.56" stroke="black" stroke-width="0.5" />
    <path d="M505.08 376.56V376.63" stroke="black" stroke-width="0.5" />
    <path d="M505.08 376.63L505.09 376.71" stroke="black" stroke-width="0.5" />
    <path d="M505.09 376.71L505.1 376.75" stroke="black" stroke-width="0.5" />
    <path d="M505.1 376.75L505.15 376.79" stroke="black" stroke-width="0.5" />
    <path d="M505.15 376.79H507.01" stroke="black" stroke-width="0.5" />
    <path d="M507.02 370.67L507.04 370.65" stroke="black" stroke-width="0.5" />
    <path d="M507.04 370.65V367.36" stroke="black" stroke-width="0.5" />
    <path d="M507.04 367.36L507.02 367.34" stroke="black" stroke-width="0.5" />
    <path d="M507.02 367.34H505.14" stroke="black" stroke-width="0.5" />
    <path d="M505.14 367.34L505.11 367.36" stroke="black" stroke-width="0.5" />
    <path d="M505.11 367.36V370.65" stroke="black" stroke-width="0.5" />
    <path d="M505.11 370.65L505.14 370.67" stroke="black" stroke-width="0.5" />
    <path d="M505.14 370.67H507.02" stroke="black" stroke-width="0.5" />
    <path d="M507.84 351.29V376.8" stroke="black" stroke-width="0.5" />
    <path d="M295.17 75.88V76.76" stroke="black" stroke-width="0.5" />
    <path d="M295.17 76.76H296.93" stroke="black" stroke-width="0.5" />
    <path d="M296.93 76.76V75.88" stroke="black" stroke-width="0.5" />
    <path d="M296.93 75.88H295.17" stroke="black" stroke-width="0.5" />
    <path d="M295.17 85.54V84.67" stroke="black" stroke-width="0.5" />
    <path d="M295.17 84.67H296.93" stroke="black" stroke-width="0.5" />
    <path d="M296.93 84.67V85.54" stroke="black" stroke-width="0.5" />
    <path d="M296.93 85.54H295.17" stroke="black" stroke-width="0.5" />
    <path d="M294.73 85.98V75.43" stroke="black" stroke-width="0.5" />
    <path d="M504.6 260.95V261.59" stroke="black" stroke-width="0.5" />
    <path d="M504.6 261.59V241.8" stroke="black" stroke-width="0.5" />
    <path d="M294.32 48.64V38.08" stroke="black" stroke-width="0.5" />
    <path d="M294.7 86.04V75.48" stroke="black" stroke-width="0.5" />
    <path d="M276.6 439.75H308.16" stroke="black" stroke-width="0.5" />
    <path d="M308.16 439.75V457.23" stroke="black" stroke-width="0.5" />
    <path d="M308.16 457.23H276.6" stroke="black" stroke-width="0.5" />
    <path d="M276.6 457.23V439.75" stroke="black" stroke-width="0.5" />
    <path d="M276.6 439.75L279.68 441.45" stroke="black" stroke-width="0.5" />
    <path d="M281.99 442.73L284.3 444.01" stroke="black" stroke-width="0.5" />
    <path d="M286.61 445.29L288.92 446.57" stroke="black" stroke-width="0.5" />
    <path d="M291.23 447.85L293.53 449.13" stroke="black" stroke-width="0.5" />
    <path d="M295.84 450.41L298.15 451.69" stroke="black" stroke-width="0.5" />
    <path d="M300.46 452.96L302.77 454.24" stroke="black" stroke-width="0.5" />
    <path d="M305.08 455.52L308.16 457.23" stroke="black" stroke-width="0.5" />
    <path d="M435.79 440.03H467.35" stroke="black" stroke-width="0.5" />
    <path d="M467.35 440.03V457.52" stroke="black" stroke-width="0.5" />
    <path d="M467.35 457.52H435.79" stroke="black" stroke-width="0.5" />
    <path d="M435.79 457.52V440.03" stroke="black" stroke-width="0.5" />
    <path d="M435.79 440.03L438.87 441.74" stroke="black" stroke-width="0.5" />
    <path d="M441.18 443.02L443.49 444.3" stroke="black" stroke-width="0.5" />
    <path d="M445.8 445.58L448.11 446.86" stroke="black" stroke-width="0.5" />
    <path d="M450.41 448.14L452.72 449.41" stroke="black" stroke-width="0.5" />
    <path d="M455.03 450.69L457.34 451.97" stroke="black" stroke-width="0.5" />
    <path d="M459.65 453.25L461.96 454.53" stroke="black" stroke-width="0.5" />
    <path d="M464.27 455.81L467.35 457.52" stroke="black" stroke-width="0.5" />
    <path d="M294.78 38.48V39.36" stroke="black" stroke-width="0.5" />
    <path d="M294.78 39.36H296.54" stroke="black" stroke-width="0.5" />
    <path d="M296.54 39.36V38.48" stroke="black" stroke-width="0.5" />
    <path d="M296.54 38.48H294.78" stroke="black" stroke-width="0.5" />
    <path d="M294.78 48.14V47.26" stroke="black" stroke-width="0.5" />
    <path d="M294.78 47.26H296.54" stroke="black" stroke-width="0.5" />
    <path d="M296.54 47.26V48.14" stroke="black" stroke-width="0.5" />
    <path d="M296.54 48.14H294.78" stroke="black" stroke-width="0.5" />
    <path d="M294.34 48.58V38.03" stroke="black" stroke-width="0.5" />
    <path d="M306.62 455.03L306.08 455.35" stroke="black" stroke-width="0.5" />
    <path d="M306.08 455.35L306.09 455.98" stroke="black" stroke-width="0.5" />
    <path d="M306.09 455.98L306.64 456.29" stroke="black" stroke-width="0.5" />
    <path d="M306.64 456.29L307.17 455.97" stroke="black" stroke-width="0.5" />
    <path d="M307.17 455.97V455.34" stroke="black" stroke-width="0.5" />
    <path d="M307.17 455.34L306.62 455.03" stroke="black" stroke-width="0.5" />
    <path d="M278.18 455.03L277.64 455.34" stroke="black" stroke-width="0.5" />
    <path d="M277.64 455.34L277.63 455.97" stroke="black" stroke-width="0.5" />
    <path d="M277.63 455.97L278.17 456.29" stroke="black" stroke-width="0.5" />
    <path d="M278.17 456.29L278.71 455.98" stroke="black" stroke-width="0.5" />
    <path d="M278.71 455.98L278.72 455.35" stroke="black" stroke-width="0.5" />
    <path d="M278.72 455.35L278.18 455.03" stroke="black" stroke-width="0.5" />
    <path d="M306.64 440.72L306.09 441.03" stroke="black" stroke-width="0.5" />
    <path d="M306.09 441.03L306.08 441.65" stroke="black" stroke-width="0.5" />
    <path d="M306.08 441.65L306.62 441.97" stroke="black" stroke-width="0.5" />
    <path d="M306.62 441.97L307.17 441.66" stroke="black" stroke-width="0.5" />
    <path d="M307.17 441.66V441.04" stroke="black" stroke-width="0.5" />
    <path d="M307.17 441.04L306.64 440.72" stroke="black" stroke-width="0.5" />
    <path d="M278.17 440.72L277.63 441.04" stroke="black" stroke-width="0.5" />
    <path d="M277.63 441.04L277.64 441.66" stroke="black" stroke-width="0.5" />
    <path d="M277.64 441.66L278.18 441.97" stroke="black" stroke-width="0.5" />
    <path d="M278.18 441.97L278.72 441.65" stroke="black" stroke-width="0.5" />
    <path d="M278.72 441.65L278.71 441.03" stroke="black" stroke-width="0.5" />
    <path d="M278.71 441.03L278.17 440.72" stroke="black" stroke-width="0.5" />
    <path d="M289.3 457.23V457.99" stroke="black" stroke-width="0.5" />
    <path d="M289.3 457.99L295.45 458" stroke="black" stroke-width="0.5" />
    <path d="M295.45 458L295.46 457.23" stroke="black" stroke-width="0.5" />
    <path d="M295.46 457.23H289.3" stroke="black" stroke-width="0.5" />
    <path d="M276.57 456.56V457.23" stroke="black" stroke-width="0.5" />
    <path d="M276.57 457.23H308.19" stroke="black" stroke-width="0.5" />
    <path d="M308.19 457.23V456.56" stroke="black" stroke-width="0.5" />
    <path d="M308.19 456.56H276.57" stroke="black" stroke-width="0.5" />
    <path d="M276.6 439.75V456.45" stroke="black" stroke-width="0.5" />
    <path d="M276.6 456.45H277.27" stroke="black" stroke-width="0.5" />
    <path d="M277.27 456.45V456.56" stroke="black" stroke-width="0.5" />
    <path d="M277.27 456.56H307.49" stroke="black" stroke-width="0.5" />
    <path d="M307.49 456.56V456.45" stroke="black" stroke-width="0.5" />
    <path d="M307.49 456.45H308.16" stroke="black" stroke-width="0.5" />
    <path d="M308.16 456.45V439.75" stroke="black" stroke-width="0.5" />
    <path d="M308.16 439.75H276.6" stroke="black" stroke-width="0.5" />
    <path d="M276.6 439.75V457.23" stroke="black" stroke-width="0.5" />
    <path d="M276.6 457.23H308.16" stroke="black" stroke-width="0.5" />
    <path d="M308.16 457.23V439.75" stroke="black" stroke-width="0.5" />
    <path d="M308.16 439.75H276.6" stroke="black" stroke-width="0.5" />
    <path d="M465.81 455.32L465.27 455.64" stroke="black" stroke-width="0.5" />
    <path d="M465.27 455.64L465.28 456.26" stroke="black" stroke-width="0.5" />
    <path d="M465.28 456.26L465.82 456.57" stroke="black" stroke-width="0.5" />
    <path d="M465.82 456.57L466.36 456.25" stroke="black" stroke-width="0.5" />
    <path d="M466.36 456.25V455.63" stroke="black" stroke-width="0.5" />
    <path d="M466.36 455.63L465.81 455.32" stroke="black" stroke-width="0.5" />
    <path d="M437.37 455.32L436.82 455.63" stroke="black" stroke-width="0.5" />
    <path d="M436.82 455.63V456.25" stroke="black" stroke-width="0.5" />
    <path d="M436.82 456.25L437.36 456.57" stroke="black" stroke-width="0.5" />
    <path d="M437.36 456.57L437.9 456.26" stroke="black" stroke-width="0.5" />
    <path d="M437.9 456.26L437.91 455.64" stroke="black" stroke-width="0.5" />
    <path d="M437.91 455.64L437.37 455.32" stroke="black" stroke-width="0.5" />
    <path d="M465.82 441.01L465.28 441.31" stroke="black" stroke-width="0.5" />
    <path d="M465.28 441.31L465.27 441.94" stroke="black" stroke-width="0.5" />
    <path d="M465.27 441.94L465.81 442.26" stroke="black" stroke-width="0.5" />
    <path d="M465.81 442.26L466.36 441.95" stroke="black" stroke-width="0.5" />
    <path d="M466.36 441.95V441.32" stroke="black" stroke-width="0.5" />
    <path d="M466.36 441.32L465.82 441.01" stroke="black" stroke-width="0.5" />
    <path d="M437.36 441.01L436.82 441.32" stroke="black" stroke-width="0.5" />
    <path d="M436.82 441.32V441.95" stroke="black" stroke-width="0.5" />
    <path d="M436.82 441.95L437.37 442.26" stroke="black" stroke-width="0.5" />
    <path d="M437.37 442.26L437.91 441.94" stroke="black" stroke-width="0.5" />
    <path d="M437.91 441.94L437.9 441.31" stroke="black" stroke-width="0.5" />
    <path d="M437.9 441.31L437.36 441.01" stroke="black" stroke-width="0.5" />
    <path d="M448.49 457.52V458.28" stroke="black" stroke-width="0.5" />
    <path d="M448.49 458.28L454.64 458.29" stroke="black" stroke-width="0.5" />
    <path d="M454.64 458.29L454.65 457.52" stroke="black" stroke-width="0.5" />
    <path d="M454.65 457.52H448.49" stroke="black" stroke-width="0.5" />
    <path d="M435.76 456.85V457.52" stroke="black" stroke-width="0.5" />
    <path d="M435.76 457.52H467.37" stroke="black" stroke-width="0.5" />
    <path d="M467.37 457.52V456.85" stroke="black" stroke-width="0.5" />
    <path d="M467.37 456.85H435.76" stroke="black" stroke-width="0.5" />
    <path d="M435.79 440.03V456.74" stroke="black" stroke-width="0.5" />
    <path d="M435.79 456.74H436.46" stroke="black" stroke-width="0.5" />
    <path d="M436.46 456.74V456.85" stroke="black" stroke-width="0.5" />
    <path d="M436.46 456.85H466.68" stroke="black" stroke-width="0.5" />
    <path d="M466.68 456.85V456.74" stroke="black" stroke-width="0.5" />
    <path d="M466.68 456.74H467.35" stroke="black" stroke-width="0.5" />
    <path d="M467.35 456.74V440.03" stroke="black" stroke-width="0.5" />
    <path d="M467.35 440.03H435.79" stroke="black" stroke-width="0.5" />
    <path d="M435.79 440.03V457.52" stroke="black" stroke-width="0.5" />
    <path d="M435.79 457.52H467.35" stroke="black" stroke-width="0.5" />
    <path d="M467.35 457.52V440.03" stroke="black" stroke-width="0.5" />
    <path d="M467.35 440.03H435.79" stroke="black" stroke-width="0.5" />
    <path d="M328.56 324.02L328.88 324.57" stroke="black" stroke-width="0.5" />
    <path d="M328.88 324.57H329.52" stroke="black" stroke-width="0.5" />
    <path d="M329.52 324.57L329.84 324.02" stroke="black" stroke-width="0.5" />
    <path d="M329.84 324.02L329.52 323.47" stroke="black" stroke-width="0.5" />
    <path d="M329.52 323.47H328.88" stroke="black" stroke-width="0.5" />
    <path d="M328.88 323.47L328.56 324.02" stroke="black" stroke-width="0.5" />
    <path d="M328.56 346.78L328.88 347.33" stroke="black" stroke-width="0.5" />
    <path d="M328.88 347.33H329.52" stroke="black" stroke-width="0.5" />
    <path d="M329.52 347.33L329.84 346.78" stroke="black" stroke-width="0.5" />
    <path d="M329.84 346.78L329.52 346.23" stroke="black" stroke-width="0.5" />
    <path d="M329.52 346.23H328.88" stroke="black" stroke-width="0.5" />
    <path d="M328.88 346.23L328.56 346.78" stroke="black" stroke-width="0.5" />
    <path d="M312.3 346.78L312.62 347.33" stroke="black" stroke-width="0.5" />
    <path d="M312.62 347.33H313.26" stroke="black" stroke-width="0.5" />
    <path d="M313.26 347.33L313.57 346.78" stroke="black" stroke-width="0.5" />
    <path d="M313.57 346.78L313.26 346.23" stroke="black" stroke-width="0.5" />
    <path d="M313.26 346.23H312.62" stroke="black" stroke-width="0.5" />
    <path d="M312.62 346.23L312.3 346.78" stroke="black" stroke-width="0.5" />
    <path d="M312.3 324.02L312.62 324.57" stroke="black" stroke-width="0.5" />
    <path d="M312.62 324.57H313.26" stroke="black" stroke-width="0.5" />
    <path d="M313.26 324.57L313.57 324.02" stroke="black" stroke-width="0.5" />
    <path d="M313.57 324.02L313.26 323.47" stroke="black" stroke-width="0.5" />
    <path d="M313.26 323.47H312.62" stroke="black" stroke-width="0.5" />
    <path d="M312.62 323.47L312.3 324.02" stroke="black" stroke-width="0.5" />
    <path d="M331.3 337.03H332.07" stroke="black" stroke-width="0.5" />
    <path d="M332.07 337.03V336.7" stroke="black" stroke-width="0.5" />
    <path d="M332.07 336.7H331.3" stroke="black" stroke-width="0.5" />
    <path d="M331.3 336.7V337.03" stroke="black" stroke-width="0.5" />
    <path d="M331.3 334.1H332.07" stroke="black" stroke-width="0.5" />
    <path d="M332.07 334.1V333.77" stroke="black" stroke-width="0.5" />
    <path d="M332.07 333.77H331.3" stroke="black" stroke-width="0.5" />
    <path d="M331.3 333.77V334.1" stroke="black" stroke-width="0.5" />
    <path d="M330.63 335.35H331.3" stroke="black" stroke-width="0.5" />
    <path d="M331.3 335.35V322.32" stroke="black" stroke-width="0.5" />
    <path d="M331.3 322.32H330.63" stroke="black" stroke-width="0.5" />
    <path d="M330.63 322.32V335.35" stroke="black" stroke-width="0.5" />
    <path d="M311.18 348.54H330.52" stroke="black" stroke-width="0.5" />
    <path d="M330.52 348.54V322.26" stroke="black" stroke-width="0.5" />
    <path d="M330.52 322.26H311.18" stroke="black" stroke-width="0.5" />
    <path d="M311.18 322.26V322.93" stroke="black" stroke-width="0.5" />
    <path d="M311.18 322.93H311.24" stroke="black" stroke-width="0.5" />
    <path d="M311.24 322.93V347.87" stroke="black" stroke-width="0.5" />
    <path d="M311.24 347.87H311.18" stroke="black" stroke-width="0.5" />
    <path d="M311.18 347.87V348.54" stroke="black" stroke-width="0.5" />
    <path d="M330.63 348.49H331.3" stroke="black" stroke-width="0.5" />
    <path d="M331.3 348.49V335.46" stroke="black" stroke-width="0.5" />
    <path d="M331.3 335.46H330.63" stroke="black" stroke-width="0.5" />
    <path d="M330.63 335.46V348.49" stroke="black" stroke-width="0.5" />
    <path d="M328.56 350.3L328.88 350.86" stroke="black" stroke-width="0.5" />
    <path d="M328.88 350.86H329.52" stroke="black" stroke-width="0.5" />
    <path d="M329.52 350.86L329.84 350.3" stroke="black" stroke-width="0.5" />
    <path d="M329.84 350.3L329.52 349.75" stroke="black" stroke-width="0.5" />
    <path d="M329.52 349.75H328.88" stroke="black" stroke-width="0.5" />
    <path d="M328.88 349.75L328.56 350.3" stroke="black" stroke-width="0.5" />
    <path d="M328.56 373.07L328.88 373.62" stroke="black" stroke-width="0.5" />
    <path d="M328.88 373.62H329.52" stroke="black" stroke-width="0.5" />
    <path d="M329.52 373.62L329.84 373.07" stroke="black" stroke-width="0.5" />
    <path d="M329.84 373.07L329.52 372.51" stroke="black" stroke-width="0.5" />
    <path d="M329.52 372.51H328.88" stroke="black" stroke-width="0.5" />
    <path d="M328.88 372.51L328.56 373.07" stroke="black" stroke-width="0.5" />
    <path d="M312.3 373.07L312.62 373.62" stroke="black" stroke-width="0.5" />
    <path d="M312.62 373.62H313.26" stroke="black" stroke-width="0.5" />
    <path d="M313.26 373.62L313.57 373.07" stroke="black" stroke-width="0.5" />
    <path d="M313.57 373.07L313.26 372.51" stroke="black" stroke-width="0.5" />
    <path d="M313.26 372.51H312.62" stroke="black" stroke-width="0.5" />
    <path d="M312.62 372.51L312.3 373.07" stroke="black" stroke-width="0.5" />
    <path d="M312.3 350.3L312.62 350.86" stroke="black" stroke-width="0.5" />
    <path d="M312.62 350.86H313.26" stroke="black" stroke-width="0.5" />
    <path d="M313.26 350.86L313.57 350.3" stroke="black" stroke-width="0.5" />
    <path d="M313.57 350.3L313.26 349.75" stroke="black" stroke-width="0.5" />
    <path d="M313.26 349.75H312.62" stroke="black" stroke-width="0.5" />
    <path d="M312.62 349.75L312.3 350.3" stroke="black" stroke-width="0.5" />
    <path d="M331.3 363.31H332.07" stroke="black" stroke-width="0.5" />
    <path d="M332.07 363.31V362.98" stroke="black" stroke-width="0.5" />
    <path d="M332.07 362.98H331.3" stroke="black" stroke-width="0.5" />
    <path d="M331.3 362.98V363.31" stroke="black" stroke-width="0.5" />
    <path d="M331.3 360.39H332.07" stroke="black" stroke-width="0.5" />
    <path d="M332.07 360.39V360.06" stroke="black" stroke-width="0.5" />
    <path d="M332.07 360.06H331.3" stroke="black" stroke-width="0.5" />
    <path d="M331.3 360.06V360.39" stroke="black" stroke-width="0.5" />
    <path d="M330.63 361.63H331.3" stroke="black" stroke-width="0.5" />
    <path d="M331.3 361.63V348.6" stroke="black" stroke-width="0.5" />
    <path d="M331.3 348.6H330.63" stroke="black" stroke-width="0.5" />
    <path d="M330.63 348.6V361.63" stroke="black" stroke-width="0.5" />
    <path d="M311.18 374.82H330.52" stroke="black" stroke-width="0.5" />
    <path d="M330.52 374.82V348.54" stroke="black" stroke-width="0.5" />
    <path d="M330.52 348.54H311.18" stroke="black" stroke-width="0.5" />
    <path d="M311.18 348.54V349.21" stroke="black" stroke-width="0.5" />
    <path d="M311.18 349.21H311.24" stroke="black" stroke-width="0.5" />
    <path d="M311.24 349.21V374.16" stroke="black" stroke-width="0.5" />
    <path d="M311.24 374.16H311.18" stroke="black" stroke-width="0.5" />
    <path d="M311.18 374.16V374.82" stroke="black" stroke-width="0.5" />
    <path d="M330.63 374.77H331.3" stroke="black" stroke-width="0.5" />
    <path d="M331.3 374.77V361.74" stroke="black" stroke-width="0.5" />
    <path d="M331.3 361.74H330.63" stroke="black" stroke-width="0.5" />
    <path d="M330.63 361.74V374.77" stroke="black" stroke-width="0.5" />
    <path d="M331.35 374.98V322.2" stroke="black" stroke-width="0.5" />
    <path d="M331.35 322.2H311.12" stroke="black" stroke-width="0.5" />
    <path d="M311.12 322.2V374.98" stroke="black" stroke-width="0.5" />
    <path d="M311.12 374.98H331.35" stroke="black" stroke-width="0.5" />
    <path d="M45.5 159.75H53.42" stroke="black" stroke-width="0.5" />
    <path d="M45.5 159.75H53.42" stroke="black" stroke-width="0.5" />
    <path d="M131.27 159.75H139.19" stroke="black" stroke-width="0.5" />
    <path d="M131.27 159.75H139.19" stroke="black" stroke-width="0.5" />
    <path d="M217.03 159.75H224.96" stroke="black" stroke-width="0.5" />
    <path d="M217.03 159.75H224.96" stroke="black" stroke-width="0.5" />
    <path d="M504.98 237.45L504.92 236.86" stroke="black" stroke-width="0.5" />
    <path d="M504.92 236.86L504.76 236.7" stroke="black" stroke-width="0.5" />
    <path d="M507.88 235.72V235.06" stroke="black" stroke-width="0.5" />
    <path d="M504.66 376.78L504.87 376.57" stroke="black" stroke-width="0.5" />
    <path d="M504.87 376.57V376.02" stroke="black" stroke-width="0.5" />
    <path d="M502.58 377.65H501.91" stroke="black" stroke-width="0.5" />
    <path d="M501.91 377.65V377.76" stroke="black" stroke-width="0.5" />
    <path d="M501.91 377.76H502.68" stroke="black" stroke-width="0.5" />
    <path d="M507.79 378.41V378.08" stroke="black" stroke-width="0.5" />
    <path d="M507.79 378.08V377.76" stroke="black" stroke-width="0.5" />
    <path d="M462.16 378.41H507.79" stroke="black" stroke-width="0.5" />
    <path d="M411.81 378.41H457.44" stroke="black" stroke-width="0.5" />
  </svg>
</template>
