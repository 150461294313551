<template>
  <svg
    width="280"
    height="141"
    viewBox="0 0 280 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M217.49 25.07V55.69" stroke="black" stroke-width="0.5" />
    <path d="M217.49 55.69H212.13" stroke="black" stroke-width="0.5" />
    <path d="M212.13 55.69V25.07" stroke="black" stroke-width="0.5" />
    <path d="M212.13 25.07H217.49" stroke="black" stroke-width="0.5" />
    <path d="M46.61 23.11L46.42 23.57" stroke="black" stroke-width="0.5" />
    <path d="M46.42 23.57L45.96 23.76" stroke="black" stroke-width="0.5" />
    <path d="M45.96 23.76L45.5 23.57" stroke="black" stroke-width="0.5" />
    <path d="M45.5 23.57L45.31 23.11" stroke="black" stroke-width="0.5" />
    <path d="M45.31 23.11L45.5 22.65" stroke="black" stroke-width="0.5" />
    <path d="M45.5 22.65L45.96 22.46" stroke="black" stroke-width="0.5" />
    <path d="M45.96 22.46L46.42 22.65" stroke="black" stroke-width="0.5" />
    <path d="M46.42 22.65L46.61 23.11" stroke="black" stroke-width="0.5" />
    <path d="M44.3 23.11L44.11 23.57" stroke="black" stroke-width="0.5" />
    <path d="M44.11 23.57L43.65 23.76" stroke="black" stroke-width="0.5" />
    <path d="M43.65 23.76L43.19 23.57" stroke="black" stroke-width="0.5" />
    <path d="M43.19 23.57L43 23.11" stroke="black" stroke-width="0.5" />
    <path d="M43 23.11L43.19 22.65" stroke="black" stroke-width="0.5" />
    <path d="M43.19 22.65L43.65 22.46" stroke="black" stroke-width="0.5" />
    <path d="M43.65 22.46L44.11 22.65" stroke="black" stroke-width="0.5" />
    <path d="M44.11 22.65L44.3 23.11" stroke="black" stroke-width="0.5" />
    <path d="M217.58 129.09H217.91" stroke="black" stroke-width="0.5" />
    <path d="M218.25 129.09H218.91" stroke="black" stroke-width="0.5" />
    <path d="M219.24 129.09H219.91" stroke="black" stroke-width="0.5" />
    <path d="M220.24 129.09H220.91" stroke="black" stroke-width="0.5" />
    <path d="M221.24 129.09H221.9" stroke="black" stroke-width="0.5" />
    <path d="M222.24 129.09H222.9" stroke="black" stroke-width="0.5" />
    <path d="M223.23 129.09H223.9" stroke="black" stroke-width="0.5" />
    <path d="M224.23 129.09H224.9" stroke="black" stroke-width="0.5" />
    <path d="M225.23 129.09H225.89" stroke="black" stroke-width="0.5" />
    <path d="M226.23 129.09H226.89" stroke="black" stroke-width="0.5" />
    <path d="M227.22 129.09H227.89" stroke="black" stroke-width="0.5" />
    <path d="M228.22 129.09H228.89" stroke="black" stroke-width="0.5" />
    <path d="M229.22 129.09H229.88" stroke="black" stroke-width="0.5" />
    <path d="M230.22 129.09H230.88" stroke="black" stroke-width="0.5" />
    <path d="M231.21 129.09H231.88" stroke="black" stroke-width="0.5" />
    <path d="M232.21 129.09H232.87" stroke="black" stroke-width="0.5" />
    <path d="M233.21 129.09H233.54" stroke="black" stroke-width="0.5" />
    <path d="M276.53 25.07V21.88" stroke="black" stroke-width="0.5" />
    <path d="M279.45 21.88V19.74" stroke="black" stroke-width="0.5" />
    <path d="M276.53 21.88H279.45" stroke="black" stroke-width="0.5" />
    <path d="M48.37 130.42V121.65" stroke="black" stroke-width="0.5" />
    <path d="M47.04 120.32H49.7" stroke="black" stroke-width="0.5" />
    <path d="M31.62 121.65V120.32" stroke="black" stroke-width="0.5" />
    <path d="M36.94 121.65V120.32" stroke="black" stroke-width="0.5" />
    <path d="M48.37 131.75H54.76" stroke="black" stroke-width="0.5" />
    <path d="M49.97 129.09H53.16" stroke="black" stroke-width="0.5" />
    <path d="M53.16 129.09V131.75" stroke="black" stroke-width="0.5" />
    <path d="M53.16 131.75H49.97" stroke="black" stroke-width="0.5" />
    <path d="M49.97 131.75V129.09" stroke="black" stroke-width="0.5" />
    <path d="M50.5 131.22V129.63" stroke="black" stroke-width="0.5" />
    <path d="M52.63 131.22H50.5" stroke="black" stroke-width="0.5" />
    <path d="M94.88 129.09V113.14" stroke="black" stroke-width="0.5" />
    <path d="M58.57 129.09H94.88" stroke="black" stroke-width="0.5" />
    <path d="M40.89 57.02H233.58" stroke="black" stroke-width="0.5" />
    <path d="M57.24 129.09H53.16" stroke="black" stroke-width="0.5" />
    <path d="M21.76 129.09H2.98999" stroke="black" stroke-width="0.5" />
    <path d="M55.91 84.41H2.98999" stroke="black" stroke-width="0.5" />
    <path d="M57.24 83.08H2.98999" stroke="black" stroke-width="0.5" />
    <path d="M39.56 78.83H37.43" stroke="black" stroke-width="0.5" />
    <path d="M36.43 78.83H34.44" stroke="black" stroke-width="0.5" />
    <path d="M33.44 78.83H31.31" stroke="black" stroke-width="0.5" />
    <path d="M29.98 78.83H27.85" stroke="black" stroke-width="0.5" />
    <path d="M26.86 78.83H24.86" stroke="black" stroke-width="0.5" />
    <path d="M23.86 78.83H21.74" stroke="black" stroke-width="0.5" />
    <path d="M55.91 127.76H49.7" stroke="black" stroke-width="0.5" />
    <path d="M1.66003 129.09V121.6" stroke="black" stroke-width="0.5" />
    <path d="M1.66003 120.27V55.16" stroke="black" stroke-width="0.5" />
    <path d="M1.66003 53.83V25.07" stroke="black" stroke-width="0.5" />
    <path d="M2.98999 129.09V84.41" stroke="black" stroke-width="0.5" />
    <path d="M2.98999 83.08V57.02" stroke="black" stroke-width="0.5" />
    <path d="M2.98999 55.69V25.07" stroke="black" stroke-width="0.5" />
    <path d="M7.23999 65V63" stroke="black" stroke-width="0.5" />
    <path d="M7.23999 62.01V60.01" stroke="black" stroke-width="0.5" />
    <path d="M7.23999 59.01V57.02" stroke="black" stroke-width="0.5" />
    <path d="M20.41 83.08V74.35" stroke="black" stroke-width="0.5" />
    <path d="M21.74 83.08V73.24" stroke="black" stroke-width="0.5" />
    <path d="M1.5 25.07H11.37" stroke="black" stroke-width="0.5" />
    <path d="M11.37 25.07V22.94" stroke="black" stroke-width="0.5" />
    <path d="M11.37 22.94H40.62" stroke="black" stroke-width="0.5" />
    <path d="M40.62 22.94V25.07" stroke="black" stroke-width="0.5" />
    <path d="M56.31 25.07V16.68" stroke="black" stroke-width="0.5" />
    <path d="M40.62 25.07H56.31" stroke="black" stroke-width="0.5" />
    <path d="M215.49 100.64V102.76" stroke="black" stroke-width="0.5" />
    <path d="M56.31 16.68L60.03 15.38" stroke="black" stroke-width="0.5" />
    <path d="M217.62 115.53H215.49" stroke="black" stroke-width="0.5" />
    <path d="M60.03 15.38L59.47 13.5" stroke="black" stroke-width="0.5" />
    <path d="M59.47 13.5L70.11 10.36" stroke="black" stroke-width="0.5" />
    <path d="M70.11 10.36L70.67 12.24" stroke="black" stroke-width="0.5" />
    <path d="M70.67 12.24L75.53 11.08" stroke="black" stroke-width="0.5" />
    <path d="M75.53 11.08V14.27" stroke="black" stroke-width="0.5" />
    <path d="M75.53 14.27H85.1" stroke="black" stroke-width="0.5" />
    <path d="M85.1 14.27V9.27002" stroke="black" stroke-width="0.5" />
    <path d="M85.1 9.26999L89.76 8.62" stroke="black" stroke-width="0.5" />
    <path d="M89.76 8.62001V5.76001" stroke="black" stroke-width="0.5" />
    <path d="M89.76 5.76001H128.19" stroke="black" stroke-width="0.5" />
    <path d="M128.19 5.76001V8.58001" stroke="black" stroke-width="0.5" />
    <path
      d="M128.19 8.58002L132.84 9.27001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M128.19 5.35999L137.63 6.75999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M132.84 9.27002V14.27" stroke="black" stroke-width="0.5" />
    <path d="M134.17 7.58002V12.95" stroke="black" stroke-width="0.5" />
    <path d="M132.84 14.27H142.42" stroke="black" stroke-width="0.5" />
    <path d="M134.17 12.95H141.09" stroke="black" stroke-width="0.5" />
    <path d="M142.42 14.27V11.08" stroke="black" stroke-width="0.5" />
    <path d="M142.42 11.08L147.27 12.26" stroke="black" stroke-width="0.5" />
    <path d="M142.92 9.16003L147.77 10.33" stroke="black" stroke-width="0.5" />
    <path d="M143.23 7.87L148.12 9.05" stroke="black" stroke-width="0.5" />
    <path
      d="M143.34 7.34998L148.25 8.52998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M147.27 12.26L147.84 10.35" stroke="black" stroke-width="0.5" />
    <path d="M147.84 10.35L158.48 13.49" stroke="black" stroke-width="0.5" />
    <path d="M148.12 9.04999L158.95 12.24" stroke="black" stroke-width="0.5" />
    <path d="M148.25 8.53003L159.11 11.74" stroke="black" stroke-width="0.5" />
    <path d="M158.48 13.49L157.92 15.38" stroke="black" stroke-width="0.5" />
    <path d="M157.92 15.38L161.63 16.68" stroke="black" stroke-width="0.5" />
    <path d="M158.48 13.49L169.35 17.28" stroke="black" stroke-width="0.5" />
    <path d="M158.95 12.24L170.68 16.33" stroke="black" stroke-width="0.5" />
    <path d="M159.11 11.74L171.21 15.95" stroke="black" stroke-width="0.5" />
    <path d="M161.63 16.68V25.07" stroke="black" stroke-width="0.5" />
    <path d="M163.23 15.14V22.94" stroke="black" stroke-width="0.5" />
    <path d="M169.35 17.28V20.28" stroke="black" stroke-width="0.5" />
    <path d="M170.68 16.33V20.28" stroke="black" stroke-width="0.5" />
    <path d="M171.21 15.95V20.28" stroke="black" stroke-width="0.5" />
    <path d="M161.63 25.07H177.33" stroke="black" stroke-width="0.5" />
    <path d="M177.33 25.07V22.94" stroke="black" stroke-width="0.5" />
    <path d="M177.33 22.94H206.58" stroke="black" stroke-width="0.5" />
    <path d="M206.58 22.94V25.07" stroke="black" stroke-width="0.5" />
    <path d="M228.39 25.07V22.94" stroke="black" stroke-width="0.5" />
    <path d="M228.39 22.94H257.65" stroke="black" stroke-width="0.5" />
    <path d="M257.65 22.94V25.07" stroke="black" stroke-width="0.5" />
    <path d="M257.65 25.07H273.78" stroke="black" stroke-width="0.5" />
    <path d="M257.65 23.2H274.93" stroke="black" stroke-width="0.5" />
    <path d="M163.23 20.28H263.5" stroke="black" stroke-width="0.5" />
    <path d="M273.25 25.07V129.09" stroke="black" stroke-width="0.5" />
    <path d="M274.5 129.09H167.88" stroke="black" stroke-width="0.5" />
    <path d="M187.3 125.9H185.57" stroke="black" stroke-width="0.5" />
    <path d="M184.57 125.9H182.58" stroke="black" stroke-width="0.5" />
    <path d="M181.58 125.9H179.59" stroke="black" stroke-width="0.5" />
    <path d="M178.59 125.9H176.59" stroke="black" stroke-width="0.5" />
    <path d="M175.6 125.9H173.6" stroke="black" stroke-width="0.5" />
    <path d="M172.6 125.9H170.61" stroke="black" stroke-width="0.5" />
    <path d="M169.61 125.9H167.88" stroke="black" stroke-width="0.5" />
    <path d="M187.3 122.71H167.88" stroke="black" stroke-width="0.5" />
    <path d="M187.3 129.63H184.87" stroke="black" stroke-width="0.5" />
    <path d="M183.87 129.63H181.88" stroke="black" stroke-width="0.5" />
    <path d="M180.88 129.63H178.89" stroke="black" stroke-width="0.5" />
    <path d="M177.89 129.63H175.89" stroke="black" stroke-width="0.5" />
    <path d="M174.9 129.63H172.9" stroke="black" stroke-width="0.5" />
    <path d="M171.91 129.63H169.48" stroke="black" stroke-width="0.5" />
    <path d="M215.49 102.76H188.1" stroke="black" stroke-width="0.5" />
    <path d="M215.49 100.64H188.63" stroke="black" stroke-width="0.5" />
    <path d="M217.62 97.98H215.49" stroke="black" stroke-width="0.5" />
    <path d="M217.62 97.44H215.49" stroke="black" stroke-width="0.5" />
    <path d="M233.58 119.52H232.25" stroke="black" stroke-width="0.5" />
    <path d="M222.67 119.52H217.62" stroke="black" stroke-width="0.5" />
    <path d="M233.58 120.85H232.25" stroke="black" stroke-width="0.5" />
    <path d="M222.67 120.85H217.62" stroke="black" stroke-width="0.5" />
    <path d="M265.45 130.42H233.58" stroke="black" stroke-width="0.5" />
    <path d="M217.62 130.42H169.48" stroke="black" stroke-width="0.5" />
    <path d="M273.25 55.69H218.82" stroke="black" stroke-width="0.5" />
    <path d="M217.49 55.69H162.96" stroke="black" stroke-width="0.5" />
    <path d="M161.63 55.69H129.52" stroke="black" stroke-width="0.5" />
    <path d="M88.43 55.69H56.31" stroke="black" stroke-width="0.5" />
    <path d="M54.98 55.69H2.32001" stroke="black" stroke-width="0.5" />
    <path d="M128.19 42.12H89.76" stroke="black" stroke-width="0.5" />
    <path d="M116.49 41.59H114.52" stroke="black" stroke-width="0.5" />
    <path d="M113.53 41.59H111.53" stroke="black" stroke-width="0.5" />
    <path d="M110.53 41.59H108.54" stroke="black" stroke-width="0.5" />
    <path d="M107.54 41.59H105.55" stroke="black" stroke-width="0.5" />
    <path d="M104.55 41.59H102.59" stroke="black" stroke-width="0.5" />
    <path d="M128.19 40.79H89.76" stroke="black" stroke-width="0.5" />
    <path d="M273.25 57.02H234.91" stroke="black" stroke-width="0.5" />
    <path d="M166.82 129.09V111.54" stroke="black" stroke-width="0.5" />
    <path d="M165.49 129.09V112.87" stroke="black" stroke-width="0.5" />
    <path d="M166.82 111.54H125.73" stroke="black" stroke-width="0.5" />
    <path d="M158.97 112.07H156.98" stroke="black" stroke-width="0.5" />
    <path d="M155.98 112.07H153.99" stroke="black" stroke-width="0.5" />
    <path d="M152.99 112.07H150.99" stroke="black" stroke-width="0.5" />
    <path d="M150 112.07H148" stroke="black" stroke-width="0.5" />
    <path d="M147 112.07H145.01" stroke="black" stroke-width="0.5" />
    <path d="M144.01 112.07H142.02" stroke="black" stroke-width="0.5" />
    <path d="M141.02 112.07H139.03" stroke="black" stroke-width="0.5" />
    <path d="M138.03 112.07H136.03" stroke="black" stroke-width="0.5" />
    <path d="M135.04 112.07H133.04" stroke="black" stroke-width="0.5" />
    <path d="M132.04 112.07H130.05" stroke="black" stroke-width="0.5" />
    <path d="M129.05 112.07H127.06" stroke="black" stroke-width="0.5" />
    <path d="M158.97 105.16H127.06" stroke="black" stroke-width="0.5" />
    <path d="M165.49 112.87H127.06" stroke="black" stroke-width="0.5" />
    <path d="M125.73 111.54V130.42" stroke="black" stroke-width="0.5" />
    <path d="M127.06 112.87V129.09" stroke="black" stroke-width="0.5" />
    <path d="M125.73 126.43H96.21" stroke="black" stroke-width="0.5" />
    <path d="M125.73 120.58H96.21" stroke="black" stroke-width="0.5" />
    <path d="M125.73 119.25H96.21" stroke="black" stroke-width="0.5" />
    <path d="M126.26 119.79H124.36" stroke="black" stroke-width="0.5" />
    <path d="M123.37 119.79H121.37" stroke="black" stroke-width="0.5" />
    <path d="M120.38 119.79H118.38" stroke="black" stroke-width="0.5" />
    <path d="M117.38 119.79H115.39" stroke="black" stroke-width="0.5" />
    <path d="M114.39 119.79H112.4" stroke="black" stroke-width="0.5" />
    <path d="M111.4 119.79H109.4" stroke="black" stroke-width="0.5" />
    <path d="M108.41 119.79H106.41" stroke="black" stroke-width="0.5" />
    <path d="M105.41 119.79H103.42" stroke="black" stroke-width="0.5" />
    <path d="M102.42 119.79H100.43" stroke="black" stroke-width="0.5" />
    <path d="M99.43 119.79H97.54" stroke="black" stroke-width="0.5" />
    <path d="M96.21 130.42V111.81" stroke="black" stroke-width="0.5" />
    <path d="M30.54 120.32L21.76 129.09" stroke="black" stroke-width="0.5" />
    <path d="M97.54 119.25V110.48" stroke="black" stroke-width="0.5" />
    <path d="M96.21 111.81H57.24" stroke="black" stroke-width="0.5" />
    <path d="M95.54 112.47H93.68" stroke="black" stroke-width="0.5" />
    <path d="M92.68 112.47H90.69" stroke="black" stroke-width="0.5" />
    <path d="M89.69 112.47H87.7" stroke="black" stroke-width="0.5" />
    <path d="M86.7 112.47H84.7" stroke="black" stroke-width="0.5" />
    <path d="M83.71 112.47H81.71" stroke="black" stroke-width="0.5" />
    <path d="M80.71 112.47H78.72" stroke="black" stroke-width="0.5" />
    <path d="M77.72 112.47H75.73" stroke="black" stroke-width="0.5" />
    <path d="M74.73 112.47H72.74" stroke="black" stroke-width="0.5" />
    <path d="M71.74 112.47H69.74" stroke="black" stroke-width="0.5" />
    <path d="M68.75 112.47H66.75" stroke="black" stroke-width="0.5" />
    <path d="M65.75 112.47H63.76" stroke="black" stroke-width="0.5" />
    <path d="M62.76 112.47H60.77" stroke="black" stroke-width="0.5" />
    <path d="M59.77 112.47H57.91" stroke="black" stroke-width="0.5" />
    <path d="M94.88 113.14H58.57" stroke="black" stroke-width="0.5" />
    <path d="M97.54 110.48H93.54" stroke="black" stroke-width="0.5" />
    <path d="M92.21 110.48H57.24" stroke="black" stroke-width="0.5" />
    <path d="M233.58 72.71H57.24" stroke="black" stroke-width="0.5" />
    <path d="M69.7 74.57H68.46" stroke="black" stroke-width="0.5" />
    <path d="M67.46 74.57H65.47" stroke="black" stroke-width="0.5" />
    <path d="M64.47 74.57H62.47" stroke="black" stroke-width="0.5" />
    <path d="M61.48 74.57H59.48" stroke="black" stroke-width="0.5" />
    <path d="M58.48 74.57H57.24" stroke="black" stroke-width="0.5" />
    <path d="M187.3 74.04H160.17" stroke="black" stroke-width="0.5" />
    <path d="M158.84 74.04H127.06" stroke="black" stroke-width="0.5" />
    <path d="M125.73 74.04H93.54" stroke="black" stroke-width="0.5" />
    <path d="M125.73 94.78H93.54" stroke="black" stroke-width="0.5" />
    <path d="M215.49 74.84H188.63" stroke="black" stroke-width="0.5" />
    <path d="M125.73 96.11H93.54" stroke="black" stroke-width="0.5" />
    <path d="M57.91 112.47V114.63" stroke="black" stroke-width="0.5" />
    <path d="M57.91 115.63V117.62" stroke="black" stroke-width="0.5" />
    <path d="M57.91 118.62V120.62" stroke="black" stroke-width="0.5" />
    <path d="M57.91 121.61V123.61" stroke="black" stroke-width="0.5" />
    <path d="M57.91 124.61V126.6" stroke="black" stroke-width="0.5" />
    <path d="M57.91 127.6V129.76" stroke="black" stroke-width="0.5" />
    <path d="M57.24 111.81V129.09" stroke="black" stroke-width="0.5" />
    <path d="M58.57 113.14V129.09" stroke="black" stroke-width="0.5" />
    <path d="M57.24 72.71V110.48" stroke="black" stroke-width="0.5" />
    <path d="M55.91 84.41V127.76" stroke="black" stroke-width="0.5" />
    <path d="M233.58 57.02V130.42" stroke="black" stroke-width="0.5" />
    <path d="M177.19 74.04L177.38 75.91" stroke="black" stroke-width="0.5" />
    <path d="M177.38 75.91L177.92 77.7" stroke="black" stroke-width="0.5" />
    <path d="M177.92 77.7L178.81 79.36" stroke="black" stroke-width="0.5" />
    <path d="M178.81 79.36L180 80.81" stroke="black" stroke-width="0.5" />
    <path d="M180 80.81L181.45 82" stroke="black" stroke-width="0.5" />
    <path d="M181.45 82L183.1 82.89" stroke="black" stroke-width="0.5" />
    <path d="M183.1 82.89L184.9 83.43" stroke="black" stroke-width="0.5" />
    <path d="M184.9 83.43L186.77 83.61" stroke="black" stroke-width="0.5" />
    <path d="M186.77 74.04H186.3" stroke="black" stroke-width="0.5" />
    <path d="M186.3 74.04V83.6" stroke="black" stroke-width="0.5" />
    <path d="M186.77 83.61V74.04" stroke="black" stroke-width="0.5" />
    <path d="M217.62 72.71V119.52" stroke="black" stroke-width="0.5" />
    <path d="M215.49 74.84V100.64" stroke="black" stroke-width="0.5" />
    <path d="M217.62 120.85V130.42" stroke="black" stroke-width="0.5" />
    <path d="M215.49 102.76V129.09" stroke="black" stroke-width="0.5" />
    <path d="M166.69 74.04V111.54" stroke="black" stroke-width="0.5" />
    <path d="M187.3 72.71V129.09" stroke="black" stroke-width="0.5" />
    <path d="M160.3 74.04V111.54" stroke="black" stroke-width="0.5" />
    <path d="M186.77 72.71V74.04" stroke="black" stroke-width="0.5" />
    <path d="M158.97 74.04V111.54" stroke="black" stroke-width="0.5" />
    <path d="M54.98 25.07V55.69" stroke="black" stroke-width="0.5" />
    <path d="M177.19 72.71V74.04" stroke="black" stroke-width="0.5" />
    <path d="M176.66 72.71V74.04" stroke="black" stroke-width="0.5" />
    <path d="M188.63 74.84V100.64" stroke="black" stroke-width="0.5" />
    <path d="M188.1 74.04V129.09" stroke="black" stroke-width="0.5" />
    <path d="M232.25 119.52V120.85" stroke="black" stroke-width="0.5" />
    <path d="M188.63 102.76V129.09" stroke="black" stroke-width="0.5" />
    <path d="M232.78 119.52V120.85" stroke="black" stroke-width="0.5" />
    <path d="M222.67 119.52V120.85" stroke="black" stroke-width="0.5" />
    <path d="M222.14 119.52V120.85" stroke="black" stroke-width="0.5" />
    <path d="M234.91 93.85V95.45" stroke="black" stroke-width="0.5" />
    <path d="M234.91 57.02V92.52" stroke="black" stroke-width="0.5" />
    <path d="M55.38 72.71V83.08" stroke="black" stroke-width="0.5" />
    <path d="M85.1 14.27H88.43" stroke="black" stroke-width="0.5" />
    <path d="M1.66 53.83H0.559998" stroke="black" stroke-width="0.5" />
    <path d="M1.66 55.16H0.559998" stroke="black" stroke-width="0.5" />
    <path d="M273.78 25.07H276.53" stroke="black" stroke-width="0.5" />
    <path d="M11.5 20.28H54.98" stroke="black" stroke-width="0.5" />
    <path d="M274.93 23.2V19.74" stroke="black" stroke-width="0.5" />
    <path d="M263.5 17.09H279.45" stroke="black" stroke-width="0.5" />
    <path d="M47.58 121.65V120.32" stroke="black" stroke-width="0.5" />
    <path d="M47.04 121.65V120.32" stroke="black" stroke-width="0.5" />
    <path d="M54.76 131.75V130.42" stroke="black" stroke-width="0.5" />
    <path d="M48.37 131.75V130.42" stroke="black" stroke-width="0.5" />
    <path d="M37.47 121.65V120.32" stroke="black" stroke-width="0.5" />
    <path d="M31.09 121.65H37.47" stroke="black" stroke-width="0.5" />
    <path d="M31.09 121.65V120.32" stroke="black" stroke-width="0.5" />
    <path d="M47.04 121.65H48.37" stroke="black" stroke-width="0.5" />
    <path d="M49.7 127.76V120.32" stroke="black" stroke-width="0.5" />
    <path d="M229.99 55.69L229.8 53.82" stroke="black" stroke-width="0.5" />
    <path d="M229.8 53.82L229.26 52.02" stroke="black" stroke-width="0.5" />
    <path d="M229.26 52.02L228.37 50.37" stroke="black" stroke-width="0.5" />
    <path d="M228.37 50.37L227.18 48.92" stroke="black" stroke-width="0.5" />
    <path d="M227.18 48.92L225.73 47.73" stroke="black" stroke-width="0.5" />
    <path d="M225.73 47.73L224.08 46.84" stroke="black" stroke-width="0.5" />
    <path d="M224.08 46.84L222.28 46.3" stroke="black" stroke-width="0.5" />
    <path d="M222.28 46.3L220.41 46.11" stroke="black" stroke-width="0.5" />
    <path d="M220.41 55.69H220.88" stroke="black" stroke-width="0.5" />
    <path d="M220.88 55.69V46.13" stroke="black" stroke-width="0.5" />
    <path d="M220.41 46.11V55.69" stroke="black" stroke-width="0.5" />
    <path d="M37.47 120.32H30.54" stroke="black" stroke-width="0.5" />
    <path d="M37.47 121.65L37.65 123.51" stroke="black" stroke-width="0.5" />
    <path d="M37.65 123.51L38.2 125.31" stroke="black" stroke-width="0.5" />
    <path d="M38.2 125.31L39.08 126.97" stroke="black" stroke-width="0.5" />
    <path d="M39.08 126.97L40.27 128.42" stroke="black" stroke-width="0.5" />
    <path d="M40.27 128.42L41.72 129.61" stroke="black" stroke-width="0.5" />
    <path d="M41.72 129.61L43.38 130.49" stroke="black" stroke-width="0.5" />
    <path d="M43.38 130.49L45.18 131.04" stroke="black" stroke-width="0.5" />
    <path d="M45.18 131.04L47.04 131.22" stroke="black" stroke-width="0.5" />
    <path d="M47.04 121.65H46.58" stroke="black" stroke-width="0.5" />
    <path d="M46.58 121.65V131.21" stroke="black" stroke-width="0.5" />
    <path d="M47.04 131.22V121.65" stroke="black" stroke-width="0.5" />
    <path d="M52.63 129.63V131.22" stroke="black" stroke-width="0.5" />
    <path d="M31.09 121.65L22.31 130.42" stroke="black" stroke-width="0.5" />
    <path d="M50.5 129.63H52.63" stroke="black" stroke-width="0.5" />
    <path d="M54.76 130.42H96.21" stroke="black" stroke-width="0.5" />
    <path d="M19.35 71.41L20.24 70.3" stroke="black" stroke-width="0.5" />
    <path d="M20.24 70.3L20.86 69.01" stroke="black" stroke-width="0.5" />
    <path d="M20.86 69.01L21.18 67.61" stroke="black" stroke-width="0.5" />
    <path d="M21.18 67.61V66.18" stroke="black" stroke-width="0.5" />
    <path d="M21.18 66.18L20.86 64.79" stroke="black" stroke-width="0.5" />
    <path d="M20.86 64.79L20.24 63.5" stroke="black" stroke-width="0.5" />
    <path d="M20.24 63.5L19.35 62.39" stroke="black" stroke-width="0.5" />
    <path d="M14.84 66.9L15.05 67.12" stroke="black" stroke-width="0.5" />
    <path d="M15.05 67.12L19.56 62.61" stroke="black" stroke-width="0.5" />
    <path d="M19.35 62.39L14.84 66.9" stroke="black" stroke-width="0.5" />
    <path d="M150.33 130.42L150.51 132.29" stroke="black" stroke-width="0.5" />
    <path d="M150.51 132.29L151.06 134.09" stroke="black" stroke-width="0.5" />
    <path d="M151.06 134.09L151.94 135.74" stroke="black" stroke-width="0.5" />
    <path d="M151.94 135.74L153.13 137.19" stroke="black" stroke-width="0.5" />
    <path d="M153.13 137.19L154.58 138.38" stroke="black" stroke-width="0.5" />
    <path d="M154.58 138.38L156.24 139.27" stroke="black" stroke-width="0.5" />
    <path d="M156.24 139.27L158.04 139.81" stroke="black" stroke-width="0.5" />
    <path d="M158.04 139.81L159.9 140" stroke="black" stroke-width="0.5" />
    <path d="M159.9 130.42H159.44" stroke="black" stroke-width="0.5" />
    <path d="M159.44 130.42V139.99" stroke="black" stroke-width="0.5" />
    <path d="M159.9 140V130.42" stroke="black" stroke-width="0.5" />
    <path d="M38.49 71.91L38.33 70.49" stroke="black" stroke-width="0.5" />
    <path d="M38.33 70.49L37.86 69.14" stroke="black" stroke-width="0.5" />
    <path d="M37.86 69.14L37.1 67.93" stroke="black" stroke-width="0.5" />
    <path d="M37.1 67.93L36.09 66.92" stroke="black" stroke-width="0.5" />
    <path d="M36.09 66.92L34.88 66.16" stroke="black" stroke-width="0.5" />
    <path d="M34.88 66.16L33.53 65.69" stroke="black" stroke-width="0.5" />
    <path d="M33.53 65.69L32.11 65.53" stroke="black" stroke-width="0.5" />
    <path d="M32.11 71.91H32.42" stroke="black" stroke-width="0.5" />
    <path d="M32.42 71.91V65.54" stroke="black" stroke-width="0.5" />
    <path d="M32.11 65.53V71.91" stroke="black" stroke-width="0.5" />
    <path d="M31.62 120.98H36.94" stroke="black" stroke-width="0.5" />
    <path d="M57.91 129.76H59.77" stroke="black" stroke-width="0.5" />
    <path d="M60.77 129.76H62.76" stroke="black" stroke-width="0.5" />
    <path d="M63.76 129.76H65.75" stroke="black" stroke-width="0.5" />
    <path d="M66.75 129.76H68.75" stroke="black" stroke-width="0.5" />
    <path d="M69.74 129.76H71.74" stroke="black" stroke-width="0.5" />
    <path d="M72.74 129.76H74.73" stroke="black" stroke-width="0.5" />
    <path d="M75.73 129.76H77.72" stroke="black" stroke-width="0.5" />
    <path d="M78.72 129.76H80.71" stroke="black" stroke-width="0.5" />
    <path d="M81.71 129.76H83.71" stroke="black" stroke-width="0.5" />
    <path d="M84.7 129.76H86.7" stroke="black" stroke-width="0.5" />
    <path d="M87.7 129.76H89.69" stroke="black" stroke-width="0.5" />
    <path d="M90.69 129.76H92.68" stroke="black" stroke-width="0.5" />
    <path d="M93.68 129.76H95.54" stroke="black" stroke-width="0.5" />
    <path d="M53.16 129.09V127.76" stroke="black" stroke-width="0.5" />
    <path d="M49.97 129.09V127.76" stroke="black" stroke-width="0.5" />
    <path d="M217.62 87.87H215.49" stroke="black" stroke-width="0.5" />
    <path d="M217.62 105.42H215.49" stroke="black" stroke-width="0.5" />
    <path d="M217.62 116.06H215.49" stroke="black" stroke-width="0.5" />
    <path d="M217.62 87.34H215.49" stroke="black" stroke-width="0.5" />
    <path d="M217.62 105.96H215.49" stroke="black" stroke-width="0.5" />
    <path d="M233.58 91.46H234.91" stroke="black" stroke-width="0.5" />
    <path d="M190.22 100.64V102.76" stroke="black" stroke-width="0.5" />
    <path d="M222.67 119.52H232.25" stroke="black" stroke-width="0.5" />
    <path d="M234.91 92.52H273.25" stroke="black" stroke-width="0.5" />
    <path d="M234.91 93.85H273.25" stroke="black" stroke-width="0.5" />
    <path d="M233.58 90.93H234.91" stroke="black" stroke-width="0.5" />
    <path d="M233.58 94.92H234.91" stroke="black" stroke-width="0.5" />
    <path d="M233.58 95.45H234.91" stroke="black" stroke-width="0.5" />
    <path d="M233.58 105.02H234.91" stroke="black" stroke-width="0.5" />
    <path d="M233.58 105.56H234.91" stroke="black" stroke-width="0.5" />
    <path d="M234.91 105.02V129.09" stroke="black" stroke-width="0.5" />
    <path d="M273.25 127.76H265.45" stroke="black" stroke-width="0.5" />
    <path d="M54.98 22.94V15.06" stroke="black" stroke-width="0.5" />
    <path d="M265.45 131.75V130.42" stroke="black" stroke-width="0.5" />
    <path d="M271.83 131.75V129" stroke="black" stroke-width="0.5" />
    <path d="M265.45 131.75H271.83" stroke="black" stroke-width="0.5" />
    <path d="M267.04 129.09H270.23" stroke="black" stroke-width="0.5" />
    <path d="M270.23 129.09V131.75" stroke="black" stroke-width="0.5" />
    <path d="M270.23 131.75H267.04" stroke="black" stroke-width="0.5" />
    <path d="M267.04 131.75V129.09" stroke="black" stroke-width="0.5" />
    <path d="M267.57 129.63H269.7" stroke="black" stroke-width="0.5" />
    <path d="M269.7 129.63V131.22" stroke="black" stroke-width="0.5" />
    <path d="M269.7 131.22H267.57" stroke="black" stroke-width="0.5" />
    <path d="M267.04 129.09V127.76" stroke="black" stroke-width="0.5" />
    <path d="M267.57 131.22V129.63" stroke="black" stroke-width="0.5" />
    <path d="M169.48 131.75V130.42" stroke="black" stroke-width="0.5" />
    <path d="M165.49 127.76H164.69" stroke="black" stroke-width="0.5" />
    <path d="M265.45 129.09V127.76" stroke="black" stroke-width="0.5" />
    <path d="M270.23 129.09V127.76" stroke="black" stroke-width="0.5" />
    <path d="M169.48 127.76H166.82" stroke="black" stroke-width="0.5" />
    <path d="M165.22 129.63H167.35" stroke="black" stroke-width="0.5" />
    <path d="M169.48 129.09V127.76" stroke="black" stroke-width="0.5" />
    <path d="M163.1 131.75H169.48" stroke="black" stroke-width="0.5" />
    <path d="M167.35 129.63V131.22" stroke="black" stroke-width="0.5" />
    <path d="M163.1 131.75V130.42" stroke="black" stroke-width="0.5" />
    <path d="M164.69 129.09V127.76" stroke="black" stroke-width="0.5" />
    <path d="M167.35 131.22H165.22" stroke="black" stroke-width="0.5" />
    <path d="M166.82 129.09H167.88" stroke="black" stroke-width="0.5" />
    <path d="M167.88 129.09V131.75" stroke="black" stroke-width="0.5" />
    <path d="M167.88 131.75H164.69" stroke="black" stroke-width="0.5" />
    <path d="M164.69 131.75V129.09" stroke="black" stroke-width="0.5" />
    <path d="M164.69 129.09H165.49" stroke="black" stroke-width="0.5" />
    <path d="M167.88 129.09V127.76" stroke="black" stroke-width="0.5" />
    <path d="M165.22 131.22V129.63" stroke="black" stroke-width="0.5" />
    <path d="M163.1 130.42H159.9" stroke="black" stroke-width="0.5" />
    <path d="M135.03 105.16V111.54" stroke="black" stroke-width="0.5" />
    <path d="M127.06 129.09H150.33" stroke="black" stroke-width="0.5" />
    <path d="M159.9 129.09V130.42" stroke="black" stroke-width="0.5" />
    <path d="M164.69 129.09H159.9" stroke="black" stroke-width="0.5" />
    <path d="M127.06 74.04V111.54" stroke="black" stroke-width="0.5" />
    <path d="M125.73 130.42H150.33" stroke="black" stroke-width="0.5" />
    <path d="M150.33 129.09V130.42" stroke="black" stroke-width="0.5" />
    <path d="M93.54 74.04V94.78" stroke="black" stroke-width="0.5" />
    <path d="M92.21 72.71V110.48" stroke="black" stroke-width="0.5" />
    <path d="M125.73 72.71V94.78" stroke="black" stroke-width="0.5" />
    <path d="M126.26 96.11V98.48" stroke="black" stroke-width="0.5" />
    <path d="M126.26 99.47V101.47" stroke="black" stroke-width="0.5" />
    <path d="M126.26 102.46V104.46" stroke="black" stroke-width="0.5" />
    <path d="M126.26 105.46V107.45" stroke="black" stroke-width="0.5" />
    <path d="M126.26 108.45V110.44" stroke="black" stroke-width="0.5" />
    <path d="M126.26 111.44V113.44" stroke="black" stroke-width="0.5" />
    <path d="M126.26 114.43V116.43" stroke="black" stroke-width="0.5" />
    <path d="M126.26 117.42V119.79" stroke="black" stroke-width="0.5" />
    <path d="M138.49 74.04V72.71" stroke="black" stroke-width="0.5" />
    <path d="M125.73 96.11V111.54" stroke="black" stroke-width="0.5" />
    <path d="M54.17 84.41V83.08" stroke="black" stroke-width="0.5" />
    <path d="M137.96 74.04V72.71" stroke="black" stroke-width="0.5" />
    <path d="M93.54 96.11V111.81" stroke="black" stroke-width="0.5" />
    <path d="M31.31 83.08V73.24" stroke="black" stroke-width="0.5" />
    <path d="M128.39 74.04V72.71" stroke="black" stroke-width="0.5" />
    <path d="M127.85 74.04V72.71" stroke="black" stroke-width="0.5" />
    <path d="M44.59 84.41V83.08" stroke="black" stroke-width="0.5" />
    <path d="M39.56 83.08V57.02" stroke="black" stroke-width="0.5" />
    <path d="M76.73 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M39.56 73.24H38.49" stroke="black" stroke-width="0.5" />
    <path d="M22.8 73.24H21.74" stroke="black" stroke-width="0.5" />
    <path d="M44.06 84.41V83.08" stroke="black" stroke-width="0.5" />
    <path d="M29.98 83.08V73.24" stroke="black" stroke-width="0.5" />
    <path d="M54.7 84.41V83.08" stroke="black" stroke-width="0.5" />
    <path d="M40.89 83.08V57.02" stroke="black" stroke-width="0.5" />
    <path d="M22.8 71.91H19.85" stroke="black" stroke-width="0.5" />
    <path d="M39.56 71.91H38.49" stroke="black" stroke-width="0.5" />
    <path d="M78.05 116.66H85.73" stroke="black" stroke-width="0.5" />
    <path d="M32.11 73.24V71.91" stroke="black" stroke-width="0.5" />
    <path d="M29.98 73.24H29.18" stroke="black" stroke-width="0.5" />
    <path d="M32.11 73.24H31.31" stroke="black" stroke-width="0.5" />
    <path d="M32.11 71.91H29.18" stroke="black" stroke-width="0.5" />
    <path d="M87.36 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M31.58 73.24V71.91" stroke="black" stroke-width="0.5" />
    <path d="M29.72 73.24V71.91" stroke="black" stroke-width="0.5" />
    <path d="M88.43 8.79999V12.94" stroke="black" stroke-width="0.5" />
    <path d="M22.27 73.24V71.91" stroke="black" stroke-width="0.5" />
    <path d="M77.26 55.69L77.44 53.82" stroke="black" stroke-width="0.5" />
    <path d="M77.44 53.82L77.99 52.02" stroke="black" stroke-width="0.5" />
    <path d="M77.99 52.02L78.87 50.37" stroke="black" stroke-width="0.5" />
    <path d="M78.87 50.37L80.06 48.92" stroke="black" stroke-width="0.5" />
    <path d="M80.06 48.92L81.51 47.73" stroke="black" stroke-width="0.5" />
    <path d="M81.51 47.73L83.17 46.84" stroke="black" stroke-width="0.5" />
    <path d="M83.17 46.84L84.96 46.3" stroke="black" stroke-width="0.5" />
    <path d="M84.96 46.3L86.83 46.11" stroke="black" stroke-width="0.5" />
    <path d="M86.83 55.69H86.37" stroke="black" stroke-width="0.5" />
    <path d="M86.37 55.69V46.13" stroke="black" stroke-width="0.5" />
    <path d="M86.83 46.11V55.69" stroke="black" stroke-width="0.5" />
    <path d="M38.49 73.24V71.91" stroke="black" stroke-width="0.5" />
    <path d="M29.18 73.24V71.91" stroke="black" stroke-width="0.5" />
    <path d="M85.1 12.94H88.43" stroke="black" stroke-width="0.5" />
    <path d="M86.83 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M88.43 14.27V55.69" stroke="black" stroke-width="0.5" />
    <path d="M77.26 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M194.21 105.08L194.25 104.92" stroke="black" stroke-width="0.5" />
    <path d="M194.62 104.59H194.88" stroke="black" stroke-width="0.5" />
    <path d="M195.24 104.92L195.28 105.11" stroke="black" stroke-width="0.5" />
    <path d="M194.93 104.91L195.01 105.07" stroke="black" stroke-width="0.5" />
    <path d="M195.01 105.07L194.98 105.24" stroke="black" stroke-width="0.5" />
    <path d="M194.98 105.24L194.85 105.35" stroke="black" stroke-width="0.5" />
    <path d="M194.85 105.35L194.68 105.36" stroke="black" stroke-width="0.5" />
    <path d="M194.68 105.36L194.53 105.27" stroke="black" stroke-width="0.5" />
    <path d="M194.53 105.27L194.48 105.11" stroke="black" stroke-width="0.5" />
    <path d="M194.48 105.11L194.56 104.92" stroke="black" stroke-width="0.5" />
    <path d="M196.58 107.65L196.74 108.7" stroke="black" stroke-width="0.5" />
    <path d="M196.74 108.7L196.58 109.74" stroke="black" stroke-width="0.5" />
    <path d="M196.58 109.74L196.16 110.58" stroke="black" stroke-width="0.5" />
    <path d="M196.16 110.58L195.48 111.17" stroke="black" stroke-width="0.5" />
    <path d="M195.48 111.17L194.74 111.36" stroke="black" stroke-width="0.5" />
    <path d="M194.74 111.36L194.01 111.17" stroke="black" stroke-width="0.5" />
    <path d="M194.01 111.17L193.33 110.58" stroke="black" stroke-width="0.5" />
    <path d="M193.33 110.58L192.91 109.74" stroke="black" stroke-width="0.5" />
    <path d="M192.91 109.74L192.75 108.7" stroke="black" stroke-width="0.5" />
    <path d="M192.75 108.7L192.91 107.65" stroke="black" stroke-width="0.5" />
    <path d="M192.91 107.65L193.14 107.12" stroke="black" stroke-width="0.5" />
    <path d="M193.14 107.12L193.47 106.65" stroke="black" stroke-width="0.5" />
    <path d="M194.21 105.11L194 105.95" stroke="black" stroke-width="0.5" />
    <path d="M194 105.95L193.47 106.65" stroke="black" stroke-width="0.5" />
    <path d="M194.21 105.11L194.25 104.92" stroke="black" stroke-width="0.5" />
    <path d="M194.61 104.59L194.7 104.58" stroke="black" stroke-width="0.5" />
    <path d="M194.7 104.58H194.79" stroke="black" stroke-width="0.5" />
    <path d="M194.79 104.58L194.88 104.59" stroke="black" stroke-width="0.5" />
    <path d="M195.24 104.92L195.28 105.11" stroke="black" stroke-width="0.5" />
    <path d="M196.02 106.65L195.49 105.95" stroke="black" stroke-width="0.5" />
    <path d="M195.49 105.95L195.28 105.11" stroke="black" stroke-width="0.5" />
    <path d="M196.02 106.65L196.35 107.12" stroke="black" stroke-width="0.5" />
    <path d="M196.35 107.12L196.58 107.65" stroke="black" stroke-width="0.5" />
    <path d="M194.21 105.08L194.06 105.79" stroke="black" stroke-width="0.5" />
    <path d="M194.06 105.79L193.69 106.43" stroke="black" stroke-width="0.5" />
    <path d="M195.92 106.54L195.47 105.88" stroke="black" stroke-width="0.5" />
    <path d="M195.47 105.88L195.28 105.11" stroke="black" stroke-width="0.5" />
    <path d="M192.91 107.65L193.22 106.99" stroke="black" stroke-width="0.5" />
    <path d="M193.22 106.99L193.69 106.43" stroke="black" stroke-width="0.5" />
    <path d="M192.75 108.7L192.91 107.65" stroke="black" stroke-width="0.5" />
    <path d="M192.91 109.74L192.75 108.7" stroke="black" stroke-width="0.5" />
    <path d="M193.33 110.58L192.91 109.74" stroke="black" stroke-width="0.5" />
    <path d="M194.01 111.17L193.33 110.58" stroke="black" stroke-width="0.5" />
    <path d="M194.74 111.36L194.01 111.17" stroke="black" stroke-width="0.5" />
    <path d="M195.48 111.17L194.74 111.36" stroke="black" stroke-width="0.5" />
    <path d="M196.16 110.58L195.48 111.17" stroke="black" stroke-width="0.5" />
    <path d="M196.58 109.74L196.16 110.58" stroke="black" stroke-width="0.5" />
    <path d="M196.74 108.7L196.58 109.74" stroke="black" stroke-width="0.5" />
    <path d="M196.58 107.65L196.74 108.7" stroke="black" stroke-width="0.5" />
    <path d="M195.92 106.54L196.31 107.05" stroke="black" stroke-width="0.5" />
    <path d="M196.31 107.05L196.58 107.65" stroke="black" stroke-width="0.5" />
    <path d="M2.98999 66.33H12.38" stroke="black" stroke-width="0.5" />
    <path d="M22.8 73.24V71.91" stroke="black" stroke-width="0.5" />
    <path d="M2.98999 65H12.16" stroke="black" stroke-width="0.5" />
    <path d="M39.02 73.24V71.91" stroke="black" stroke-width="0.5" />
    <path d="M129.52 14.27V55.69" stroke="black" stroke-width="0.5" />
    <path d="M128.19 8.58002V40.79" stroke="black" stroke-width="0.5" />
    <path d="M20.41 74.35L18.41 72.35" stroke="black" stroke-width="0.5" />
    <path d="M128.19 42.12V57.02" stroke="black" stroke-width="0.5" />
    <path d="M12.16 64.57H13.49" stroke="black" stroke-width="0.5" />
    <path d="M117.02 40.79V42.12" stroke="black" stroke-width="0.5" />
    <path d="M132.84 12.94H129.52" stroke="black" stroke-width="0.5" />
    <path d="M13.49 64.04V65.56" stroke="black" stroke-width="0.5" />
    <path d="M39.56 57.02H13.49" stroke="black" stroke-width="0.5" />
    <path d="M12.16 57.02H2.32001" stroke="black" stroke-width="0.5" />
    <path d="M13.52 67.46L14.46 66.52" stroke="black" stroke-width="0.5" />
    <path d="M12.16 64.04V65" stroke="black" stroke-width="0.5" />
    <path d="M13.49 65.56L14.84 66.9" stroke="black" stroke-width="0.5" />
    <path d="M18.79 72.73L19.73 71.79" stroke="black" stroke-width="0.5" />
    <path d="M12.16 58.08H13.49" stroke="black" stroke-width="0.5" />
    <path d="M13.9 67.84L14.84 66.9" stroke="black" stroke-width="0.5" />
    <path d="M12.38 66.33L13.9 67.84" stroke="black" stroke-width="0.5" />
    <path d="M12.16 58.08V57.02" stroke="black" stroke-width="0.5" />
    <path d="M12.16 64.04H13.49" stroke="black" stroke-width="0.5" />
    <path d="M13.49 58.08V57.02" stroke="black" stroke-width="0.5" />
    <path d="M164.56 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M12.16 57.55H13.49" stroke="black" stroke-width="0.5" />
    <path d="M18.41 72.35L19.35 71.41" stroke="black" stroke-width="0.5" />
    <path d="M39.56 57.55H40.89" stroke="black" stroke-width="0.5" />
    <path d="M162.96 25.07V55.69" stroke="black" stroke-width="0.5" />
    <path d="M164.03 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M116.49 40.79V42.12" stroke="black" stroke-width="0.5" />
    <path d="M19.85 71.91L19.35 71.41" stroke="black" stroke-width="0.5" />
    <path d="M39.56 58.08H40.89" stroke="black" stroke-width="0.5" />
    <path d="M174.13 55.69L173.95 53.82" stroke="black" stroke-width="0.5" />
    <path d="M173.95 53.82L173.41 52.02" stroke="black" stroke-width="0.5" />
    <path d="M173.41 52.02L172.52 50.37" stroke="black" stroke-width="0.5" />
    <path d="M172.52 50.37L171.33 48.92" stroke="black" stroke-width="0.5" />
    <path d="M171.33 48.92L169.88 47.73" stroke="black" stroke-width="0.5" />
    <path d="M169.88 47.73L168.22 46.84" stroke="black" stroke-width="0.5" />
    <path d="M168.22 46.84L166.43 46.3" stroke="black" stroke-width="0.5" />
    <path d="M166.43 46.3L164.56 46.11" stroke="black" stroke-width="0.5" />
    <path d="M164.56 55.69H165.02" stroke="black" stroke-width="0.5" />
    <path d="M165.02 55.69V46.13" stroke="black" stroke-width="0.5" />
    <path d="M164.56 46.11V55.69" stroke="black" stroke-width="0.5" />
    <path d="M174.67 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M117.02 40.79L117.2 38.93" stroke="black" stroke-width="0.5" />
    <path d="M117.2 38.93L117.75 37.13" stroke="black" stroke-width="0.5" />
    <path d="M117.75 37.13L118.63 35.48" stroke="black" stroke-width="0.5" />
    <path d="M118.63 35.48L119.82 34.02" stroke="black" stroke-width="0.5" />
    <path d="M119.82 34.02L121.27 32.83" stroke="black" stroke-width="0.5" />
    <path d="M121.27 32.83L122.93 31.95" stroke="black" stroke-width="0.5" />
    <path d="M122.93 31.95L124.73 31.4" stroke="black" stroke-width="0.5" />
    <path d="M124.73 31.4L126.59 31.22" stroke="black" stroke-width="0.5" />
    <path d="M126.59 40.79H126.13" stroke="black" stroke-width="0.5" />
    <path d="M126.13 40.79V31.23" stroke="black" stroke-width="0.5" />
    <path d="M126.59 31.22V40.79" stroke="black" stroke-width="0.5" />
    <path d="M39.56 67.66H40.89" stroke="black" stroke-width="0.5" />
    <path d="M141.22 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M140.69 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M39.56 68.19H40.89" stroke="black" stroke-width="0.5" />
    <path d="M93.54 106.77H92.21" stroke="black" stroke-width="0.5" />
    <path d="M140.69 55.69L140.51 53.82" stroke="black" stroke-width="0.5" />
    <path d="M140.51 53.82L139.96 52.02" stroke="black" stroke-width="0.5" />
    <path d="M139.96 52.02L139.08 50.37" stroke="black" stroke-width="0.5" />
    <path d="M139.08 50.37L137.89 48.92" stroke="black" stroke-width="0.5" />
    <path d="M137.89 48.92L136.43 47.73" stroke="black" stroke-width="0.5" />
    <path d="M136.43 47.73L134.78 46.84" stroke="black" stroke-width="0.5" />
    <path d="M134.78 46.84L132.98 46.3" stroke="black" stroke-width="0.5" />
    <path d="M132.98 46.3L131.11 46.11" stroke="black" stroke-width="0.5" />
    <path d="M131.11 55.69H131.58" stroke="black" stroke-width="0.5" />
    <path d="M131.58 55.69V46.13" stroke="black" stroke-width="0.5" />
    <path d="M131.11 46.11V55.69" stroke="black" stroke-width="0.5" />
    <path d="M215.49 87.87L213.62 88.05" stroke="black" stroke-width="0.5" />
    <path d="M213.62 88.05L211.83 88.6" stroke="black" stroke-width="0.5" />
    <path d="M211.83 88.6L210.17 89.48" stroke="black" stroke-width="0.5" />
    <path d="M210.17 89.48L208.72 90.67" stroke="black" stroke-width="0.5" />
    <path d="M208.72 90.67L207.53 92.13" stroke="black" stroke-width="0.5" />
    <path d="M207.53 92.13L206.64 93.78" stroke="black" stroke-width="0.5" />
    <path d="M206.64 93.78L206.1 95.58" stroke="black" stroke-width="0.5" />
    <path d="M206.1 95.58L205.92 97.44" stroke="black" stroke-width="0.5" />
    <path d="M215.49 97.44V96.98" stroke="black" stroke-width="0.5" />
    <path d="M215.49 96.98H205.93" stroke="black" stroke-width="0.5" />
    <path d="M205.92 97.44H215.49" stroke="black" stroke-width="0.5" />
    <path d="M44.59 84.41L44.77 86.28" stroke="black" stroke-width="0.5" />
    <path d="M44.77 86.28L45.32 88.08" stroke="black" stroke-width="0.5" />
    <path d="M45.32 88.08L46.2 89.73" stroke="black" stroke-width="0.5" />
    <path d="M46.2 89.73L47.4 91.18" stroke="black" stroke-width="0.5" />
    <path d="M47.4 91.18L48.85 92.37" stroke="black" stroke-width="0.5" />
    <path d="M48.85 92.37L50.5 93.26" stroke="black" stroke-width="0.5" />
    <path d="M50.5 93.26L52.3 93.8" stroke="black" stroke-width="0.5" />
    <path d="M52.3 93.8L54.17 93.99" stroke="black" stroke-width="0.5" />
    <path d="M54.17 84.41H53.7" stroke="black" stroke-width="0.5" />
    <path d="M53.7 84.41V93.98" stroke="black" stroke-width="0.5" />
    <path d="M54.17 93.99V84.41" stroke="black" stroke-width="0.5" />
    <path d="M93.54 97.19H92.21" stroke="black" stroke-width="0.5" />
    <path d="M93.54 96.66H92.21" stroke="black" stroke-width="0.5" />
    <path d="M93.54 107.3H92.21" stroke="black" stroke-width="0.5" />
    <path d="M215.49 115.53L213.62 115.35" stroke="black" stroke-width="0.5" />
    <path d="M213.62 115.35L211.83 114.8" stroke="black" stroke-width="0.5" />
    <path d="M211.83 114.8L210.17 113.92" stroke="black" stroke-width="0.5" />
    <path d="M210.17 113.92L208.72 112.73" stroke="black" stroke-width="0.5" />
    <path d="M208.72 112.73L207.53 111.27" stroke="black" stroke-width="0.5" />
    <path d="M207.53 111.27L206.64 109.62" stroke="black" stroke-width="0.5" />
    <path d="M206.64 109.62L206.1 107.82" stroke="black" stroke-width="0.5" />
    <path d="M206.1 107.82L205.92 105.96" stroke="black" stroke-width="0.5" />
    <path d="M215.49 105.96V106.42" stroke="black" stroke-width="0.5" />
    <path d="M215.49 106.42H205.93" stroke="black" stroke-width="0.5" />
    <path d="M205.92 105.96H215.49" stroke="black" stroke-width="0.5" />
    <path d="M39.56 67.66L37.69 67.47" stroke="black" stroke-width="0.5" />
    <path d="M37.69 67.47L35.89 66.93" stroke="black" stroke-width="0.5" />
    <path d="M35.89 66.93L34.24 66.04" stroke="black" stroke-width="0.5" />
    <path d="M34.24 66.04L32.79 64.85" stroke="black" stroke-width="0.5" />
    <path d="M32.79 64.85L31.6 63.4" stroke="black" stroke-width="0.5" />
    <path d="M31.6 63.4L30.71 61.75" stroke="black" stroke-width="0.5" />
    <path d="M30.71 61.75L30.17 59.95" stroke="black" stroke-width="0.5" />
    <path d="M30.17 59.95L29.98 58.08" stroke="black" stroke-width="0.5" />
    <path d="M39.56 58.08V58.55" stroke="black" stroke-width="0.5" />
    <path d="M39.56 58.55H29.99" stroke="black" stroke-width="0.5" />
    <path d="M29.98 58.08H39.56" stroke="black" stroke-width="0.5" />
    <path d="M56.31 25.07V55.69" stroke="black" stroke-width="0.5" />
    <path d="M229.99 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M93.54 106.77L95.41 106.58" stroke="black" stroke-width="0.5" />
    <path d="M95.41 106.58L97.21 106.04" stroke="black" stroke-width="0.5" />
    <path d="M97.21 106.04L98.86 105.15" stroke="black" stroke-width="0.5" />
    <path d="M98.86 105.15L100.31 103.96" stroke="black" stroke-width="0.5" />
    <path d="M100.31 103.96L101.51 102.51" stroke="black" stroke-width="0.5" />
    <path d="M101.51 102.51L102.39 100.85" stroke="black" stroke-width="0.5" />
    <path d="M102.39 100.85L102.94 99.06" stroke="black" stroke-width="0.5" />
    <path d="M102.94 99.06L103.12 97.19" stroke="black" stroke-width="0.5" />
    <path d="M93.54 97.19V97.66" stroke="black" stroke-width="0.5" />
    <path d="M93.54 97.66H103.11" stroke="black" stroke-width="0.5" />
    <path d="M103.12 97.19H93.54" stroke="black" stroke-width="0.5" />
    <path d="M222.67 120.85L222.85 122.72" stroke="black" stroke-width="0.5" />
    <path d="M222.85 122.72L223.4 124.51" stroke="black" stroke-width="0.5" />
    <path d="M223.4 124.51L224.28 126.17" stroke="black" stroke-width="0.5" />
    <path d="M224.28 126.17L225.48 127.62" stroke="black" stroke-width="0.5" />
    <path d="M225.48 127.62L226.93 128.81" stroke="black" stroke-width="0.5" />
    <path d="M226.93 128.81L228.58 129.69" stroke="black" stroke-width="0.5" />
    <path d="M228.58 129.69L230.38 130.24" stroke="black" stroke-width="0.5" />
    <path d="M230.38 130.24L232.25 130.42" stroke="black" stroke-width="0.5" />
    <path d="M232.25 120.85H231.78" stroke="black" stroke-width="0.5" />
    <path d="M231.78 120.85V130.41" stroke="black" stroke-width="0.5" />
    <path d="M232.25 130.42V120.85" stroke="black" stroke-width="0.5" />
    <path d="M137.96 74.04L137.78 75.91" stroke="black" stroke-width="0.5" />
    <path d="M137.78 75.91L137.23 77.7" stroke="black" stroke-width="0.5" />
    <path d="M137.23 77.7L136.35 79.36" stroke="black" stroke-width="0.5" />
    <path d="M136.35 79.36L135.16 80.81" stroke="black" stroke-width="0.5" />
    <path d="M135.16 80.81L133.71 82" stroke="black" stroke-width="0.5" />
    <path d="M133.71 82L132.05 82.89" stroke="black" stroke-width="0.5" />
    <path d="M132.05 82.89L130.25 83.43" stroke="black" stroke-width="0.5" />
    <path d="M130.25 83.43L128.39 83.61" stroke="black" stroke-width="0.5" />
    <path d="M128.39 74.04H128.85" stroke="black" stroke-width="0.5" />
    <path d="M128.85 74.04V83.6" stroke="black" stroke-width="0.5" />
    <path d="M128.39 83.61V74.04" stroke="black" stroke-width="0.5" />
    <path d="M220.41 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M53.92 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M234.91 105.02L236.77 104.84" stroke="black" stroke-width="0.5" />
    <path d="M236.77 104.84L238.57 104.3" stroke="black" stroke-width="0.5" />
    <path d="M238.57 104.3L240.22 103.41" stroke="black" stroke-width="0.5" />
    <path d="M240.22 103.41L241.68 102.22" stroke="black" stroke-width="0.5" />
    <path d="M241.68 102.22L242.87 100.77" stroke="black" stroke-width="0.5" />
    <path d="M242.87 100.77L243.75 99.11" stroke="black" stroke-width="0.5" />
    <path d="M243.75 99.11L244.3 97.32" stroke="black" stroke-width="0.5" />
    <path d="M244.3 97.32L244.48 95.45" stroke="black" stroke-width="0.5" />
    <path d="M234.91 95.45V95.92" stroke="black" stroke-width="0.5" />
    <path d="M234.91 95.92H244.47" stroke="black" stroke-width="0.5" />
    <path d="M244.48 95.45H234.91" stroke="black" stroke-width="0.5" />
    <path d="M233.58 81.35H234.91" stroke="black" stroke-width="0.5" />
    <path d="M29.18 71.91L29.02 70.49" stroke="black" stroke-width="0.5" />
    <path d="M29.02 70.49L28.55 69.14" stroke="black" stroke-width="0.5" />
    <path d="M28.55 69.14L27.79 67.93" stroke="black" stroke-width="0.5" />
    <path d="M27.79 67.93L26.78 66.92" stroke="black" stroke-width="0.5" />
    <path d="M26.78 66.92L25.57 66.16" stroke="black" stroke-width="0.5" />
    <path d="M25.57 66.16L24.22 65.69" stroke="black" stroke-width="0.5" />
    <path d="M24.22 65.69L22.8 65.53" stroke="black" stroke-width="0.5" />
    <path d="M22.8 71.91H23.11" stroke="black" stroke-width="0.5" />
    <path d="M23.11 71.91V65.54" stroke="black" stroke-width="0.5" />
    <path d="M22.8 65.53V71.91" stroke="black" stroke-width="0.5" />
    <path d="M53.39 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M234.91 81.35L236.77 81.54" stroke="black" stroke-width="0.5" />
    <path d="M236.77 81.54L238.57 82.08" stroke="black" stroke-width="0.5" />
    <path d="M238.57 82.08L240.22 82.97" stroke="black" stroke-width="0.5" />
    <path d="M240.22 82.97L241.68 84.16" stroke="black" stroke-width="0.5" />
    <path d="M241.68 84.16L242.87 85.61" stroke="black" stroke-width="0.5" />
    <path d="M242.87 85.61L243.75 87.26" stroke="black" stroke-width="0.5" />
    <path d="M243.75 87.26L244.3 89.06" stroke="black" stroke-width="0.5" />
    <path d="M244.3 89.06L244.48 90.93" stroke="black" stroke-width="0.5" />
    <path d="M234.91 90.93V90.46" stroke="black" stroke-width="0.5" />
    <path d="M234.91 90.46H244.47" stroke="black" stroke-width="0.5" />
    <path d="M244.48 90.93H234.91" stroke="black" stroke-width="0.5" />
    <path d="M40.62 25.07H54.98" stroke="black" stroke-width="0.5" />
    <path d="M54.98 25.07V25.91" stroke="black" stroke-width="0.5" />
    <path d="M54.98 25.91H40.62" stroke="black" stroke-width="0.5" />
    <path d="M40.62 25.91V25.07" stroke="black" stroke-width="0.5" />
    <path d="M233.58 80.82H234.91" stroke="black" stroke-width="0.5" />
    <path d="M43.81 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M43.28 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M13.49 64.47L14.91 64.31" stroke="black" stroke-width="0.5" />
    <path d="M14.91 64.31L16.26 63.83" stroke="black" stroke-width="0.5" />
    <path d="M16.26 63.83L17.47 63.07" stroke="black" stroke-width="0.5" />
    <path d="M17.47 63.07L18.48 62.06" stroke="black" stroke-width="0.5" />
    <path d="M18.48 62.06L19.24 60.85" stroke="black" stroke-width="0.5" />
    <path d="M19.24 60.85L19.72 59.5" stroke="black" stroke-width="0.5" />
    <path d="M19.72 59.5L19.88 58.08" stroke="black" stroke-width="0.5" />
    <path d="M13.49 58.08V58.39" stroke="black" stroke-width="0.5" />
    <path d="M13.49 58.39H19.87" stroke="black" stroke-width="0.5" />
    <path d="M19.88 58.08H13.49" stroke="black" stroke-width="0.5" />
    <path d="M89.76 8.62V40.79" stroke="black" stroke-width="0.5" />
    <path d="M42.04 29.24V25.91" stroke="black" stroke-width="0.5" />
    <path d="M51.9 27.38V27.05" stroke="black" stroke-width="0.5" />
    <path d="M52.88 27.38H51.9" stroke="black" stroke-width="0.5" />
    <path d="M52.88 27.05V27.38" stroke="black" stroke-width="0.5" />
    <path d="M51.9 27.05H52.88" stroke="black" stroke-width="0.5" />
    <path d="M50.72 29.77L50.78 30.05" stroke="black" stroke-width="0.5" />
    <path d="M50.78 30.05L50.96 30.27" stroke="black" stroke-width="0.5" />
    <path d="M50.96 30.27L51.2 30.34" stroke="black" stroke-width="0.5" />
    <path d="M53.58 30.34L53.82 30.27" stroke="black" stroke-width="0.5" />
    <path d="M53.82 30.27L53.99 30.05" stroke="black" stroke-width="0.5" />
    <path d="M53.99 30.05L54.06 29.77" stroke="black" stroke-width="0.5" />
    <path d="M54.06 28.35L53.92 27.94" stroke="black" stroke-width="0.5" />
    <path d="M53.92 27.94L53.58 27.77" stroke="black" stroke-width="0.5" />
    <path d="M51.2 27.77L50.86 27.94" stroke="black" stroke-width="0.5" />
    <path d="M50.86 27.94L50.72 28.35" stroke="black" stroke-width="0.5" />
    <path d="M50.72 29.77V28.35" stroke="black" stroke-width="0.5" />
    <path d="M53.58 30.34H51.2" stroke="black" stroke-width="0.5" />
    <path d="M54.06 28.35V29.77" stroke="black" stroke-width="0.5" />
    <path d="M51.2 27.77H53.58" stroke="black" stroke-width="0.5" />
    <path d="M50.39 30.68V26.69" stroke="black" stroke-width="0.5" />
    <path d="M54.38 30.68H50.39" stroke="black" stroke-width="0.5" />
    <path d="M54.38 26.69V30.68" stroke="black" stroke-width="0.5" />
    <path d="M50.39 26.69H54.38" stroke="black" stroke-width="0.5" />
    <path d="M42.04 25.91H54.98" stroke="black" stroke-width="0.5" />
    <path d="M54.98 25.91V31.27" stroke="black" stroke-width="0.5" />
    <path d="M54.98 31.27H42.04" stroke="black" stroke-width="0.5" />
    <path d="M42.04 31.27V25.91" stroke="black" stroke-width="0.5" />
    <path d="M42.04 29.35H43.19" stroke="black" stroke-width="0.5" />
    <path d="M43.86 29.35H45.19" stroke="black" stroke-width="0.5" />
    <path d="M45.85 29.35H47.18" stroke="black" stroke-width="0.5" />
    <path d="M47.85 29.35H49.18" stroke="black" stroke-width="0.5" />
    <path d="M49.84 29.35H51.17" stroke="black" stroke-width="0.5" />
    <path d="M51.84 29.35H53.17" stroke="black" stroke-width="0.5" />
    <path d="M53.83 29.35H54.98" stroke="black" stroke-width="0.5" />
    <path d="M89.76 42.12V57.02" stroke="black" stroke-width="0.5" />
    <path d="M127.13 40.79V42.12" stroke="black" stroke-width="0.5" />
    <path d="M129.52 8.79999V12.94" stroke="black" stroke-width="0.5" />
    <path d="M126.59 40.79V42.12" stroke="black" stroke-width="0.5" />
    <path d="M174.13 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M132.84 14.27H129.52" stroke="black" stroke-width="0.5" />
    <path d="M131.11 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M161.63 25.07V55.69" stroke="black" stroke-width="0.5" />
    <path d="M130.58 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M219.88 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M230.52 55.69V57.02" stroke="black" stroke-width="0.5" />
    <path d="M162.33 35.06V33.05" stroke="black" stroke-width="0.5" />
    <path d="M162.33 33.05H160.94" stroke="black" stroke-width="0.5" />
    <path d="M160.94 33.05V39.9" stroke="black" stroke-width="0.5" />
    <path d="M160.94 39.9H162.33" stroke="black" stroke-width="0.5" />
    <path d="M162.33 39.9V37.89" stroke="black" stroke-width="0.5" />
    <path d="M162.33 37.89H161.99" stroke="black" stroke-width="0.5" />
    <path d="M161.99 37.89V37.66" stroke="black" stroke-width="0.5" />
    <path d="M161.99 37.66H162.33" stroke="black" stroke-width="0.5" />
    <path d="M162.33 37.66V35.29" stroke="black" stroke-width="0.5" />
    <path d="M162.33 35.29H161.99" stroke="black" stroke-width="0.5" />
    <path d="M161.99 35.29V35.06" stroke="black" stroke-width="0.5" />
    <path d="M161.99 35.06H162.33" stroke="black" stroke-width="0.5" />
    <path d="M40.62 22.14H39.65" stroke="black" stroke-width="0.5" />
    <path d="M39.65 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M39.65 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M39.65 24.86H40.62" stroke="black" stroke-width="0.5" />
    <path d="M40.62 24.86V22.14" stroke="black" stroke-width="0.5" />
    <path d="M11.37 24.86H12.34" stroke="black" stroke-width="0.5" />
    <path d="M12.34 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M12.34 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M12.34 22.14H11.37" stroke="black" stroke-width="0.5" />
    <path d="M11.37 22.14V24.86" stroke="black" stroke-width="0.5" />
    <path d="M34.86 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M34.86 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M34.86 24.86H35.83" stroke="black" stroke-width="0.5" />
    <path d="M35.83 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M35.83 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M35.83 22.14H34.86" stroke="black" stroke-width="0.5" />
    <path d="M39.65 24.86H35.83" stroke="black" stroke-width="0.5" />
    <path d="M34.86 24.86H12.34" stroke="black" stroke-width="0.5" />
    <path d="M34.86 22.14H12.34" stroke="black" stroke-width="0.5" />
    <path d="M39.65 22.14H35.83" stroke="black" stroke-width="0.5" />
    <path d="M12.34 22.72H39.65" stroke="black" stroke-width="0.5" />
    <path d="M39.65 23.2H12.34" stroke="black" stroke-width="0.5" />
    <path d="M39.65 23.2V22.72" stroke="black" stroke-width="0.5" />
    <path
      d="M12.34 23.2H39.65L12.34 22.72V23.2Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M39.65 23.2V22.72H12.34L39.65 23.2Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M12.34 23.2V22.72" stroke="black" stroke-width="0.5" />
    <path d="M25.51 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M25.51 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M25.51 24.86H26.48" stroke="black" stroke-width="0.5" />
    <path d="M26.48 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M26.48 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M26.48 22.14H25.51" stroke="black" stroke-width="0.5" />
    <path d="M17.13 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M17.13 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M17.13 24.86H16.15" stroke="black" stroke-width="0.5" />
    <path d="M16.15 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M16.15 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M16.15 22.14H17.13" stroke="black" stroke-width="0.5" />
    <path d="M166.05 74.94H167.3" stroke="black" stroke-width="0.5" />
    <path d="M167.97 74.94H169.3" stroke="black" stroke-width="0.5" />
    <path d="M169.96 74.94H171.21" stroke="black" stroke-width="0.5" />
    <path d="M187.3 94.09H185.58" stroke="black" stroke-width="0.5" />
    <path d="M188.63 111.24H189.96" stroke="black" stroke-width="0.5" />
    <path d="M54.98 15.06L59.47 13.5" stroke="black" stroke-width="0.5" />
    <path d="M187.3 94.88H188.63" stroke="black" stroke-width="0.5" />
    <path d="M206.23 119.45L206.22 119.27" stroke="black" stroke-width="0.5" />
    <path d="M206.22 119.27V119.09" stroke="black" stroke-width="0.5" />
    <path d="M206.22 119.09L206.21 118.91" stroke="black" stroke-width="0.5" />
    <path d="M206.21 118.91L206.2 118.78" stroke="black" stroke-width="0.5" />
    <path d="M206.16 118.45L206.15 118.38" stroke="black" stroke-width="0.5" />
    <path d="M206.15 118.38L206.13 118.2" stroke="black" stroke-width="0.5" />
    <path d="M206.13 118.2L206.1 118.03" stroke="black" stroke-width="0.5" />
    <path d="M206.1 118.03L206.06 117.85" stroke="black" stroke-width="0.5" />
    <path d="M206.06 117.85L206.05 117.79" stroke="black" stroke-width="0.5" />
    <path d="M205.97 117.46L205.94 117.33" stroke="black" stroke-width="0.5" />
    <path d="M205.94 117.33L205.89 117.16" stroke="black" stroke-width="0.5" />
    <path d="M205.89 117.16L205.84 116.99" stroke="black" stroke-width="0.5" />
    <path d="M205.84 116.99L205.78 116.82" stroke="black" stroke-width="0.5" />
    <path d="M205.67 116.51L205.66 116.49" stroke="black" stroke-width="0.5" />
    <path d="M205.66 116.49L205.59 116.32" stroke="black" stroke-width="0.5" />
    <path d="M205.59 116.32L205.52 116.16" stroke="black" stroke-width="0.5" />
    <path d="M205.52 116.16L205.44 116" stroke="black" stroke-width="0.5" />
    <path d="M205.44 116L205.39 115.9" stroke="black" stroke-width="0.5" />
    <path d="M205.24 115.6L205.19 115.52" stroke="black" stroke-width="0.5" />
    <path d="M205.19 115.52L205.1 115.37" stroke="black" stroke-width="0.5" />
    <path d="M205.1 115.37L205.01 115.21" stroke="black" stroke-width="0.5" />
    <path d="M205.01 115.21L204.91 115.06" stroke="black" stroke-width="0.5" />
    <path d="M204.91 115.06L204.89 115.03" stroke="black" stroke-width="0.5" />
    <path d="M204.7 114.76L204.61 114.63" stroke="black" stroke-width="0.5" />
    <path d="M204.61 114.63L204.5 114.49" stroke="black" stroke-width="0.5" />
    <path d="M204.5 114.49L204.38 114.35" stroke="black" stroke-width="0.5" />
    <path d="M204.38 114.35L204.29 114.23" stroke="black" stroke-width="0.5" />
    <path d="M204.06 113.98L204.03 113.95" stroke="black" stroke-width="0.5" />
    <path d="M204.03 113.95L203.9 113.82" stroke="black" stroke-width="0.5" />
    <path d="M203.9 113.82L203.78 113.69" stroke="black" stroke-width="0.5" />
    <path d="M203.78 113.69L203.65 113.57" stroke="black" stroke-width="0.5" />
    <path d="M203.65 113.57L203.59 113.52" stroke="black" stroke-width="0.5" />
    <path d="M203.33 113.3L203.24 113.22" stroke="black" stroke-width="0.5" />
    <path d="M203.24 113.22L203.1 113.11" stroke="black" stroke-width="0.5" />
    <path d="M203.1 113.11L202.96 113.01" stroke="black" stroke-width="0.5" />
    <path d="M202.96 113.01L202.81 112.9" stroke="black" stroke-width="0.5" />
    <path d="M202.81 112.9L202.8 112.89" stroke="black" stroke-width="0.5" />
    <path d="M202.52 112.71L202.36 112.61" stroke="black" stroke-width="0.5" />
    <path d="M202.36 112.61L202.21 112.52" stroke="black" stroke-width="0.5" />
    <path d="M202.21 112.52L202.05 112.43" stroke="black" stroke-width="0.5" />
    <path d="M202.05 112.43L201.94 112.38" stroke="black" stroke-width="0.5" />
    <path d="M201.64 112.23L201.58 112.19" stroke="black" stroke-width="0.5" />
    <path d="M201.58 112.19L201.41 112.12" stroke="black" stroke-width="0.5" />
    <path d="M201.41 112.12L201.25 112.05" stroke="black" stroke-width="0.5" />
    <path d="M201.25 112.05L201.08 111.99" stroke="black" stroke-width="0.5" />
    <path d="M201.08 111.99L201.03 111.97" stroke="black" stroke-width="0.5" />
    <path d="M200.71 111.86L200.58 111.81" stroke="black" stroke-width="0.5" />
    <path d="M200.58 111.81L200.41 111.76" stroke="black" stroke-width="0.5" />
    <path d="M200.41 111.76L200.23 111.72" stroke="black" stroke-width="0.5" />
    <path d="M200.23 111.72L200.07 111.68" stroke="black" stroke-width="0.5" />
    <path d="M199.74 111.61L199.71 111.6" stroke="black" stroke-width="0.5" />
    <path d="M199.71 111.6L199.53 111.57" stroke="black" stroke-width="0.5" />
    <path d="M199.53 111.57L199.36 111.54" stroke="black" stroke-width="0.5" />
    <path d="M199.36 111.54L199.18 111.52" stroke="black" stroke-width="0.5" />
    <path d="M199.18 111.52L199.08 111.51" stroke="black" stroke-width="0.5" />
    <path d="M198.75 111.48H198.65" stroke="black" stroke-width="0.5" />
    <path d="M198.65 111.48L198.47 111.47" stroke="black" stroke-width="0.5" />
    <path d="M198.47 111.47H198.29" stroke="black" stroke-width="0.5" />
    <path d="M198.29 111.47H198.11" stroke="black" stroke-width="0.5" />
    <path d="M198.11 111.47H198.08" stroke="black" stroke-width="0.5" />
    <path d="M197.75 111.48L197.58 111.5" stroke="black" stroke-width="0.5" />
    <path d="M197.58 111.5L197.4 111.51" stroke="black" stroke-width="0.5" />
    <path d="M197.4 111.51L197.22 111.53" stroke="black" stroke-width="0.5" />
    <path d="M197.22 111.53L197.08 111.55" stroke="black" stroke-width="0.5" />
    <path d="M196.75 111.61L196.7 111.62" stroke="black" stroke-width="0.5" />
    <path d="M196.7 111.62L196.52 111.66" stroke="black" stroke-width="0.5" />
    <path d="M196.52 111.66L196.35 111.7" stroke="black" stroke-width="0.5" />
    <path d="M196.35 111.7L196.17 111.74" stroke="black" stroke-width="0.5" />
    <path d="M196.17 111.74L196.1 111.76" stroke="black" stroke-width="0.5" />
    <path d="M195.78 111.86L195.66 111.9" stroke="black" stroke-width="0.5" />
    <path d="M195.66 111.9L195.49 111.96" stroke="black" stroke-width="0.5" />
    <path d="M195.49 111.96L195.33 112.02" stroke="black" stroke-width="0.5" />
    <path d="M195.33 112.02L195.16 112.09" stroke="black" stroke-width="0.5" />
    <path d="M195.16 112.09H195.15" stroke="black" stroke-width="0.5" />
    <path d="M194.85 112.23H194.84" stroke="black" stroke-width="0.5" />
    <path d="M194.84 112.23L194.68 112.31" stroke="black" stroke-width="0.5" />
    <path d="M194.68 112.31L194.52 112.39" stroke="black" stroke-width="0.5" />
    <path d="M194.52 112.39L194.36 112.48" stroke="black" stroke-width="0.5" />
    <path d="M194.36 112.48L194.26 112.54" stroke="black" stroke-width="0.5" />
    <path d="M193.97 112.71L193.9 112.75" stroke="black" stroke-width="0.5" />
    <path d="M193.9 112.75L193.75 112.85" stroke="black" stroke-width="0.5" />
    <path d="M193.75 112.85L193.61 112.95" stroke="black" stroke-width="0.5" />
    <path d="M193.61 112.95L193.46 113.06" stroke="black" stroke-width="0.5" />
    <path d="M193.46 113.06L193.42 113.09" stroke="black" stroke-width="0.5" />
    <path d="M193.16 113.3L193.05 113.39" stroke="black" stroke-width="0.5" />
    <path d="M193.05 113.39L192.91 113.51" stroke="black" stroke-width="0.5" />
    <path d="M192.91 113.51L192.78 113.63" stroke="black" stroke-width="0.5" />
    <path d="M192.78 113.63L192.66 113.75" stroke="black" stroke-width="0.5" />
    <path d="M192.43 113.98L192.4 114.01" stroke="black" stroke-width="0.5" />
    <path d="M192.4 114.01L192.28 114.14" stroke="black" stroke-width="0.5" />
    <path d="M192.28 114.14L192.17 114.28" stroke="black" stroke-width="0.5" />
    <path d="M192.17 114.28L192.05 114.42" stroke="black" stroke-width="0.5" />
    <path d="M192.05 114.42L191.99 114.49" stroke="black" stroke-width="0.5" />
    <path d="M191.79 114.76L191.73 114.84" stroke="black" stroke-width="0.5" />
    <path d="M191.73 114.84L191.63 114.99" stroke="black" stroke-width="0.5" />
    <path d="M191.63 114.99L191.53 115.14" stroke="black" stroke-width="0.5" />
    <path d="M191.53 115.14L191.44 115.29" stroke="black" stroke-width="0.5" />
    <path d="M191.44 115.29L191.42 115.31" stroke="black" stroke-width="0.5" />
    <path d="M191.25 115.6L191.17 115.76" stroke="black" stroke-width="0.5" />
    <path d="M191.17 115.76L191.09 115.92" stroke="black" stroke-width="0.5" />
    <path d="M191.09 115.92L191.01 116.08" stroke="black" stroke-width="0.5" />
    <path d="M191.01 116.08L190.96 116.2" stroke="black" stroke-width="0.5" />
    <path d="M190.83 116.51L190.8 116.57" stroke="black" stroke-width="0.5" />
    <path d="M190.8 116.57L190.74 116.74" stroke="black" stroke-width="0.5" />
    <path d="M190.74 116.74L190.68 116.9" stroke="black" stroke-width="0.5" />
    <path d="M190.68 116.9L190.63 117.07" stroke="black" stroke-width="0.5" />
    <path d="M190.63 117.07L190.61 117.14" stroke="black" stroke-width="0.5" />
    <path d="M190.52 117.46L190.49 117.59" stroke="black" stroke-width="0.5" />
    <path d="M190.49 117.59L190.45 117.76" stroke="black" stroke-width="0.5" />
    <path d="M190.45 117.76L190.41 117.94" stroke="black" stroke-width="0.5" />
    <path d="M190.41 117.94L190.38 118.11" stroke="black" stroke-width="0.5" />
    <path d="M190.38 118.11V118.12" stroke="black" stroke-width="0.5" />
    <path d="M190.33 118.45V118.47" stroke="black" stroke-width="0.5" />
    <path d="M190.33 118.47L190.31 118.64" stroke="black" stroke-width="0.5" />
    <path d="M190.31 118.64L190.29 118.82" stroke="black" stroke-width="0.5" />
    <path d="M190.29 118.82L190.28 119" stroke="black" stroke-width="0.5" />
    <path d="M190.28 119V119.11" stroke="black" stroke-width="0.5" />
    <path d="M190.27 119.45V119.54" stroke="black" stroke-width="0.5" />
    <path d="M190.27 119.54V119.71" stroke="black" stroke-width="0.5" />
    <path d="M190.27 119.71L190.28 119.89" stroke="black" stroke-width="0.5" />
    <path d="M190.28 119.89L190.29 120.07" stroke="black" stroke-width="0.5" />
    <path d="M190.29 120.07L190.3 120.11" stroke="black" stroke-width="0.5" />
    <path d="M190.33 120.45L190.35 120.6" stroke="black" stroke-width="0.5" />
    <path d="M190.35 120.6L190.38 120.78" stroke="black" stroke-width="0.5" />
    <path d="M190.38 120.78L190.41 120.95" stroke="black" stroke-width="0.5" />
    <path d="M190.41 120.95L190.44 121.1" stroke="black" stroke-width="0.5" />
    <path d="M190.52 121.43L190.53 121.48" stroke="black" stroke-width="0.5" />
    <path d="M190.53 121.48L190.58 121.65" stroke="black" stroke-width="0.5" />
    <path d="M190.58 121.65L190.63 121.82" stroke="black" stroke-width="0.5" />
    <path d="M190.63 121.82L190.68 121.99" stroke="black" stroke-width="0.5" />
    <path d="M190.68 121.99L190.71 122.07" stroke="black" stroke-width="0.5" />
    <path d="M190.83 122.38L190.87 122.49" stroke="black" stroke-width="0.5" />
    <path d="M190.87 122.49L190.94 122.65" stroke="black" stroke-width="0.5" />
    <path d="M190.94 122.65L191.01 122.82" stroke="black" stroke-width="0.5" />
    <path d="M191.01 122.82L191.09 122.98" stroke="black" stroke-width="0.5" />
    <path d="M191.09 122.98L191.1 122.99" stroke="black" stroke-width="0.5" />
    <path d="M191.25 123.29H191.26" stroke="black" stroke-width="0.5" />
    <path d="M191.26 123.29L191.34 123.45" stroke="black" stroke-width="0.5" />
    <path d="M191.34 123.45L191.44 123.6" stroke="black" stroke-width="0.5" />
    <path d="M191.44 123.6L191.53 123.75" stroke="black" stroke-width="0.5" />
    <path d="M191.53 123.75L191.6 123.86" stroke="black" stroke-width="0.5" />
    <path d="M191.79 124.14L191.83 124.19" stroke="black" stroke-width="0.5" />
    <path d="M191.83 124.19L191.94 124.34" stroke="black" stroke-width="0.5" />
    <path d="M191.94 124.34L192.05 124.48" stroke="black" stroke-width="0.5" />
    <path d="M192.05 124.48L192.17 124.61" stroke="black" stroke-width="0.5" />
    <path d="M192.17 124.61L192.21 124.66" stroke="black" stroke-width="0.5" />
    <path d="M192.43 124.91L192.53 125.01" stroke="black" stroke-width="0.5" />
    <path d="M192.53 125.01L192.65 125.13" stroke="black" stroke-width="0.5" />
    <path d="M192.65 125.13L192.78 125.26" stroke="black" stroke-width="0.5" />
    <path d="M192.78 125.26L192.91 125.38" stroke="black" stroke-width="0.5" />
    <path d="M193.16 125.59L193.18 125.61" stroke="black" stroke-width="0.5" />
    <path d="M193.18 125.61L193.32 125.72" stroke="black" stroke-width="0.5" />
    <path d="M193.32 125.72L193.46 125.83" stroke="black" stroke-width="0.5" />
    <path d="M193.46 125.83L193.61 125.94" stroke="black" stroke-width="0.5" />
    <path d="M193.61 125.94L193.69 126" stroke="black" stroke-width="0.5" />
    <path d="M193.97 126.18L194.05 126.23" stroke="black" stroke-width="0.5" />
    <path d="M194.05 126.23L194.21 126.33" stroke="black" stroke-width="0.5" />
    <path d="M194.21 126.33L194.36 126.41" stroke="black" stroke-width="0.5" />
    <path d="M194.36 126.41L194.52 126.5" stroke="black" stroke-width="0.5" />
    <path d="M194.52 126.5L194.55 126.52" stroke="black" stroke-width="0.5" />
    <path d="M194.85 126.67L195 126.73" stroke="black" stroke-width="0.5" />
    <path d="M195 126.73L195.16 126.8" stroke="black" stroke-width="0.5" />
    <path d="M195.16 126.8L195.33 126.87" stroke="black" stroke-width="0.5" />
    <path d="M195.33 126.87L195.47 126.92" stroke="black" stroke-width="0.5" />
    <path d="M195.78 127.03L195.83 127.05" stroke="black" stroke-width="0.5" />
    <path d="M195.83 127.05L196 127.1" stroke="black" stroke-width="0.5" />
    <path d="M196 127.1L196.17 127.15" stroke="black" stroke-width="0.5" />
    <path d="M196.17 127.15L196.35 127.2" stroke="black" stroke-width="0.5" />
    <path d="M196.35 127.2L196.43 127.21" stroke="black" stroke-width="0.5" />
    <path d="M196.75 127.28L196.87 127.31" stroke="black" stroke-width="0.5" />
    <path d="M196.87 127.31L197.05 127.33" stroke="black" stroke-width="0.5" />
    <path d="M197.05 127.33L197.22 127.36" stroke="black" stroke-width="0.5" />
    <path d="M197.22 127.36L197.4 127.38" stroke="black" stroke-width="0.5" />
    <path d="M197.4 127.38H197.41" stroke="black" stroke-width="0.5" />
    <path d="M197.75 127.41H197.76" stroke="black" stroke-width="0.5" />
    <path d="M197.76 127.41L197.93 127.42" stroke="black" stroke-width="0.5" />
    <path d="M197.93 127.42H198.11" stroke="black" stroke-width="0.5" />
    <path d="M198.11 127.42H198.29" stroke="black" stroke-width="0.5" />
    <path d="M198.29 127.42H198.41" stroke="black" stroke-width="0.5" />
    <path d="M198.75 127.41L198.83 127.4" stroke="black" stroke-width="0.5" />
    <path d="M198.83 127.4L199 127.39" stroke="black" stroke-width="0.5" />
    <path d="M199 127.39L199.18 127.37" stroke="black" stroke-width="0.5" />
    <path d="M199.18 127.37L199.36 127.35" stroke="black" stroke-width="0.5" />
    <path d="M199.36 127.35L199.41 127.34" stroke="black" stroke-width="0.5" />
    <path d="M199.74 127.28L199.89 127.25" stroke="black" stroke-width="0.5" />
    <path d="M199.89 127.25L200.06 127.22" stroke="black" stroke-width="0.5" />
    <path d="M200.06 127.22L200.23 127.17" stroke="black" stroke-width="0.5" />
    <path d="M200.23 127.17L200.39 127.13" stroke="black" stroke-width="0.5" />
    <path d="M200.71 127.03L200.75 127.02" stroke="black" stroke-width="0.5" />
    <path d="M200.75 127.02L200.92 126.97" stroke="black" stroke-width="0.5" />
    <path d="M200.92 126.97L201.08 126.9" stroke="black" stroke-width="0.5" />
    <path d="M201.08 126.9L201.25 126.84" stroke="black" stroke-width="0.5" />
    <path d="M201.25 126.84L201.34 126.8" stroke="black" stroke-width="0.5" />
    <path d="M201.64 126.66L201.74 126.62" stroke="black" stroke-width="0.5" />
    <path d="M201.74 126.62L201.9 126.54" stroke="black" stroke-width="0.5" />
    <path d="M201.9 126.54L202.05 126.46" stroke="black" stroke-width="0.5" />
    <path d="M202.05 126.46L202.21 126.37" stroke="black" stroke-width="0.5" />
    <path d="M202.21 126.37L202.24 126.36" stroke="black" stroke-width="0.5" />
    <path d="M202.52 126.18L202.67 126.09" stroke="black" stroke-width="0.5" />
    <path d="M202.67 126.09L202.81 125.99" stroke="black" stroke-width="0.5" />
    <path d="M202.81 125.99L202.96 125.89" stroke="black" stroke-width="0.5" />
    <path d="M202.96 125.89L203.07 125.8" stroke="black" stroke-width="0.5" />
    <path d="M203.33 125.59L203.38 125.55" stroke="black" stroke-width="0.5" />
    <path d="M203.38 125.55L203.51 125.44" stroke="black" stroke-width="0.5" />
    <path d="M203.51 125.44L203.65 125.32" stroke="black" stroke-width="0.5" />
    <path d="M203.65 125.32L203.78 125.2" stroke="black" stroke-width="0.5" />
    <path d="M203.78 125.2L203.83 125.15" stroke="black" stroke-width="0.5" />
    <path d="M204.06 124.91L204.15 124.81" stroke="black" stroke-width="0.5" />
    <path d="M204.15 124.81L204.27 124.68" stroke="black" stroke-width="0.5" />
    <path d="M204.27 124.68L204.38 124.54" stroke="black" stroke-width="0.5" />
    <path d="M204.38 124.54L204.5 124.41" stroke="black" stroke-width="0.5" />
    <path d="M204.5 124.41V124.4" stroke="black" stroke-width="0.5" />
    <path d="M204.7 124.14L204.71 124.12" stroke="black" stroke-width="0.5" />
    <path d="M204.71 124.12L204.82 123.98" stroke="black" stroke-width="0.5" />
    <path d="M204.82 123.98L204.91 123.83" stroke="black" stroke-width="0.5" />
    <path d="M204.91 123.83L205.01 123.68" stroke="black" stroke-width="0.5" />
    <path d="M205.01 123.68L205.07 123.58" stroke="black" stroke-width="0.5" />
    <path d="M205.24 123.29L205.28 123.21" stroke="black" stroke-width="0.5" />
    <path d="M205.28 123.21L205.36 123.06" stroke="black" stroke-width="0.5" />
    <path d="M205.36 123.06L205.44 122.9" stroke="black" stroke-width="0.5" />
    <path d="M205.44 122.9L205.52 122.73" stroke="black" stroke-width="0.5" />
    <path d="M205.52 122.73L205.54 122.69" stroke="black" stroke-width="0.5" />
    <path d="M205.67 122.38L205.72 122.24" stroke="black" stroke-width="0.5" />
    <path d="M205.72 122.24L205.78 122.07" stroke="black" stroke-width="0.5" />
    <path d="M205.78 122.07L205.84 121.9" stroke="black" stroke-width="0.5" />
    <path d="M205.84 121.9L205.89 121.75" stroke="black" stroke-width="0.5" />
    <path d="M205.97 121.43L205.99 121.39" stroke="black" stroke-width="0.5" />
    <path d="M205.99 121.39L206.03 121.22" stroke="black" stroke-width="0.5" />
    <path d="M206.03 121.22L206.06 121.04" stroke="black" stroke-width="0.5" />
    <path d="M206.06 121.04L206.1 120.87" stroke="black" stroke-width="0.5" />
    <path d="M206.1 120.87L206.11 120.78" stroke="black" stroke-width="0.5" />
    <path d="M206.16 120.45L206.18 120.34" stroke="black" stroke-width="0.5" />
    <path d="M206.18 120.34L206.19 120.16" stroke="black" stroke-width="0.5" />
    <path d="M206.19 120.16L206.21 119.98" stroke="black" stroke-width="0.5" />
    <path d="M206.21 119.98L206.22 119.8" stroke="black" stroke-width="0.5" />
    <path d="M206.22 119.8V119.78" stroke="black" stroke-width="0.5" />
    <path d="M206.23 84.61L206.22 84.43" stroke="black" stroke-width="0.5" />
    <path d="M206.22 84.43V84.25" stroke="black" stroke-width="0.5" />
    <path d="M206.22 84.25L206.21 84.07" stroke="black" stroke-width="0.5" />
    <path d="M206.21 84.07L206.2 83.94" stroke="black" stroke-width="0.5" />
    <path d="M206.16 83.61L206.15 83.54" stroke="black" stroke-width="0.5" />
    <path d="M206.15 83.54L206.13 83.36" stroke="black" stroke-width="0.5" />
    <path d="M206.13 83.36L206.1 83.19" stroke="black" stroke-width="0.5" />
    <path d="M206.1 83.19L206.06 83.01" stroke="black" stroke-width="0.5" />
    <path d="M206.06 83.01L206.05 82.95" stroke="black" stroke-width="0.5" />
    <path d="M205.97 82.62L205.94 82.49" stroke="black" stroke-width="0.5" />
    <path d="M205.94 82.49L205.89 82.32" stroke="black" stroke-width="0.5" />
    <path d="M205.89 82.32L205.84 82.15" stroke="black" stroke-width="0.5" />
    <path d="M205.84 82.15L205.78 81.98" stroke="black" stroke-width="0.5" />
    <path d="M205.67 81.67L205.66 81.65" stroke="black" stroke-width="0.5" />
    <path d="M205.66 81.65L205.59 81.48" stroke="black" stroke-width="0.5" />
    <path d="M205.59 81.48L205.52 81.32" stroke="black" stroke-width="0.5" />
    <path d="M205.52 81.32L205.44 81.16" stroke="black" stroke-width="0.5" />
    <path d="M205.44 81.16L205.39 81.06" stroke="black" stroke-width="0.5" />
    <path d="M205.24 80.76L205.19 80.68" stroke="black" stroke-width="0.5" />
    <path d="M205.19 80.68L205.1 80.53" stroke="black" stroke-width="0.5" />
    <path d="M205.1 80.53L205.01 80.37" stroke="black" stroke-width="0.5" />
    <path d="M205.01 80.37L204.91 80.22" stroke="black" stroke-width="0.5" />
    <path d="M204.91 80.22L204.89 80.19" stroke="black" stroke-width="0.5" />
    <path d="M204.7 79.92L204.61 79.79" stroke="black" stroke-width="0.5" />
    <path d="M204.61 79.79L204.5 79.65" stroke="black" stroke-width="0.5" />
    <path d="M204.5 79.65L204.38 79.51" stroke="black" stroke-width="0.5" />
    <path d="M204.38 79.51L204.29 79.39" stroke="black" stroke-width="0.5" />
    <path d="M204.06 79.14L204.03 79.11" stroke="black" stroke-width="0.5" />
    <path d="M204.03 79.11L203.9 78.98" stroke="black" stroke-width="0.5" />
    <path d="M203.9 78.98L203.78 78.85" stroke="black" stroke-width="0.5" />
    <path d="M203.78 78.85L203.65 78.73" stroke="black" stroke-width="0.5" />
    <path d="M203.65 78.73L203.59 78.68" stroke="black" stroke-width="0.5" />
    <path d="M203.33 78.46L203.24 78.38" stroke="black" stroke-width="0.5" />
    <path d="M203.24 78.38L203.1 78.27" stroke="black" stroke-width="0.5" />
    <path d="M203.1 78.27L202.96 78.17" stroke="black" stroke-width="0.5" />
    <path d="M202.96 78.17L202.81 78.06" stroke="black" stroke-width="0.5" />
    <path d="M202.81 78.06L202.8 78.05" stroke="black" stroke-width="0.5" />
    <path d="M202.52 77.87V77.86" stroke="black" stroke-width="0.5" />
    <path d="M202.52 77.86L202.36 77.77" stroke="black" stroke-width="0.5" />
    <path d="M202.36 77.77L202.21 77.68" stroke="black" stroke-width="0.5" />
    <path d="M202.21 77.68L202.05 77.59" stroke="black" stroke-width="0.5" />
    <path d="M202.05 77.59L201.94 77.53" stroke="black" stroke-width="0.5" />
    <path d="M201.64 77.39L201.58 77.35" stroke="black" stroke-width="0.5" />
    <path d="M201.58 77.35L201.41 77.28" stroke="black" stroke-width="0.5" />
    <path d="M201.41 77.28L201.25 77.21" stroke="black" stroke-width="0.5" />
    <path d="M201.25 77.21L201.08 77.15" stroke="black" stroke-width="0.5" />
    <path d="M201.08 77.15L201.03 77.13" stroke="black" stroke-width="0.5" />
    <path d="M200.71 77.02L200.58 76.97" stroke="black" stroke-width="0.5" />
    <path d="M200.58 76.97L200.41 76.92" stroke="black" stroke-width="0.5" />
    <path d="M200.41 76.92L200.23 76.88" stroke="black" stroke-width="0.5" />
    <path d="M200.23 76.88L200.07 76.84" stroke="black" stroke-width="0.5" />
    <path d="M199.74 76.77L199.71 76.76" stroke="black" stroke-width="0.5" />
    <path d="M199.71 76.76L199.53 76.73" stroke="black" stroke-width="0.5" />
    <path d="M199.53 76.73L199.36 76.7" stroke="black" stroke-width="0.5" />
    <path d="M199.36 76.7L199.18 76.68" stroke="black" stroke-width="0.5" />
    <path d="M199.18 76.68L199.08 76.67" stroke="black" stroke-width="0.5" />
    <path d="M198.75 76.64H198.65" stroke="black" stroke-width="0.5" />
    <path d="M198.65 76.64L198.47 76.63" stroke="black" stroke-width="0.5" />
    <path d="M198.47 76.63H198.29" stroke="black" stroke-width="0.5" />
    <path d="M198.29 76.63H198.11" stroke="black" stroke-width="0.5" />
    <path d="M198.11 76.63H198.08" stroke="black" stroke-width="0.5" />
    <path d="M197.75 76.64L197.58 76.65" stroke="black" stroke-width="0.5" />
    <path d="M197.58 76.65L197.4 76.67" stroke="black" stroke-width="0.5" />
    <path d="M197.4 76.67L197.22 76.69" stroke="black" stroke-width="0.5" />
    <path d="M197.22 76.69L197.08 76.71" stroke="black" stroke-width="0.5" />
    <path d="M196.75 76.77L196.7 76.78" stroke="black" stroke-width="0.5" />
    <path d="M196.7 76.78L196.52 76.82" stroke="black" stroke-width="0.5" />
    <path d="M196.52 76.82L196.35 76.86" stroke="black" stroke-width="0.5" />
    <path d="M196.35 76.86L196.17 76.9" stroke="black" stroke-width="0.5" />
    <path d="M196.17 76.9L196.1 76.92" stroke="black" stroke-width="0.5" />
    <path d="M195.78 77.02L195.66 77.06" stroke="black" stroke-width="0.5" />
    <path d="M195.66 77.06L195.49 77.12" stroke="black" stroke-width="0.5" />
    <path d="M195.49 77.12L195.33 77.18" stroke="black" stroke-width="0.5" />
    <path d="M195.33 77.18L195.16 77.25" stroke="black" stroke-width="0.5" />
    <path d="M195.16 77.25H195.15" stroke="black" stroke-width="0.5" />
    <path d="M194.85 77.39H194.84" stroke="black" stroke-width="0.5" />
    <path d="M194.84 77.39L194.68 77.47" stroke="black" stroke-width="0.5" />
    <path d="M194.68 77.47L194.52 77.55" stroke="black" stroke-width="0.5" />
    <path d="M194.52 77.55L194.36 77.64" stroke="black" stroke-width="0.5" />
    <path d="M194.36 77.64L194.26 77.7" stroke="black" stroke-width="0.5" />
    <path d="M193.97 77.87L193.9 77.91" stroke="black" stroke-width="0.5" />
    <path d="M193.9 77.91L193.75 78.01" stroke="black" stroke-width="0.5" />
    <path d="M193.75 78.01L193.61 78.11" stroke="black" stroke-width="0.5" />
    <path d="M193.61 78.11L193.46 78.22" stroke="black" stroke-width="0.5" />
    <path d="M193.46 78.22L193.42 78.25" stroke="black" stroke-width="0.5" />
    <path d="M193.16 78.46L193.05 78.55" stroke="black" stroke-width="0.5" />
    <path d="M193.05 78.55L192.91 78.67" stroke="black" stroke-width="0.5" />
    <path d="M192.91 78.67L192.78 78.79" stroke="black" stroke-width="0.5" />
    <path d="M192.78 78.79L192.66 78.9" stroke="black" stroke-width="0.5" />
    <path d="M192.43 79.14L192.4 79.17" stroke="black" stroke-width="0.5" />
    <path d="M192.4 79.17L192.28 79.3" stroke="black" stroke-width="0.5" />
    <path d="M192.28 79.3L192.17 79.44" stroke="black" stroke-width="0.5" />
    <path d="M192.17 79.44L192.05 79.58" stroke="black" stroke-width="0.5" />
    <path d="M192.05 79.58L191.99 79.65" stroke="black" stroke-width="0.5" />
    <path d="M191.79 79.92L191.73 80" stroke="black" stroke-width="0.5" />
    <path d="M191.73 80L191.63 80.15" stroke="black" stroke-width="0.5" />
    <path d="M191.63 80.15L191.53 80.3" stroke="black" stroke-width="0.5" />
    <path d="M191.53 80.3L191.44 80.45" stroke="black" stroke-width="0.5" />
    <path d="M191.44 80.45L191.42 80.47" stroke="black" stroke-width="0.5" />
    <path d="M191.25 80.76L191.17 80.92" stroke="black" stroke-width="0.5" />
    <path d="M191.17 80.92L191.09 81.07" stroke="black" stroke-width="0.5" />
    <path d="M191.09 81.07L191.01 81.24" stroke="black" stroke-width="0.5" />
    <path d="M191.01 81.24L190.96 81.36" stroke="black" stroke-width="0.5" />
    <path d="M190.83 81.67L190.8 81.73" stroke="black" stroke-width="0.5" />
    <path d="M190.8 81.73L190.74 81.9" stroke="black" stroke-width="0.5" />
    <path d="M190.74 81.9L190.68 82.06" stroke="black" stroke-width="0.5" />
    <path d="M190.68 82.06L190.63 82.23" stroke="black" stroke-width="0.5" />
    <path d="M190.63 82.23L190.61 82.3" stroke="black" stroke-width="0.5" />
    <path d="M190.52 82.62L190.49 82.75" stroke="black" stroke-width="0.5" />
    <path d="M190.49 82.75L190.45 82.92" stroke="black" stroke-width="0.5" />
    <path d="M190.45 82.92L190.41 83.1" stroke="black" stroke-width="0.5" />
    <path d="M190.41 83.1L190.38 83.27" stroke="black" stroke-width="0.5" />
    <path d="M190.33 83.61V83.63" stroke="black" stroke-width="0.5" />
    <path d="M190.33 83.63L190.31 83.8" stroke="black" stroke-width="0.5" />
    <path d="M190.31 83.8L190.29 83.98" stroke="black" stroke-width="0.5" />
    <path d="M190.29 83.98L190.28 84.16" stroke="black" stroke-width="0.5" />
    <path d="M190.28 84.16V84.27" stroke="black" stroke-width="0.5" />
    <path d="M190.27 84.61V84.69" stroke="black" stroke-width="0.5" />
    <path d="M190.27 84.69V84.87" stroke="black" stroke-width="0.5" />
    <path d="M190.27 84.87L190.28 85.05" stroke="black" stroke-width="0.5" />
    <path d="M190.28 85.05L190.29 85.23" stroke="black" stroke-width="0.5" />
    <path d="M190.29 85.23L190.3 85.27" stroke="black" stroke-width="0.5" />
    <path d="M190.33 85.61L190.35 85.76" stroke="black" stroke-width="0.5" />
    <path d="M190.35 85.76L190.38 85.94" stroke="black" stroke-width="0.5" />
    <path d="M190.38 85.94L190.41 86.11" stroke="black" stroke-width="0.5" />
    <path d="M190.41 86.11L190.44 86.26" stroke="black" stroke-width="0.5" />
    <path d="M190.52 86.59L190.53 86.63" stroke="black" stroke-width="0.5" />
    <path d="M190.53 86.63L190.58 86.81" stroke="black" stroke-width="0.5" />
    <path d="M190.58 86.81L190.63 86.98" stroke="black" stroke-width="0.5" />
    <path d="M190.63 86.98L190.68 87.15" stroke="black" stroke-width="0.5" />
    <path d="M190.68 87.15L190.71 87.23" stroke="black" stroke-width="0.5" />
    <path d="M190.83 87.54L190.87 87.65" stroke="black" stroke-width="0.5" />
    <path d="M190.87 87.65L190.94 87.81" stroke="black" stroke-width="0.5" />
    <path d="M190.94 87.81L191.01 87.98" stroke="black" stroke-width="0.5" />
    <path d="M191.01 87.98L191.09 88.14" stroke="black" stroke-width="0.5" />
    <path d="M191.09 88.14L191.1 88.15" stroke="black" stroke-width="0.5" />
    <path d="M191.25 88.45H191.26" stroke="black" stroke-width="0.5" />
    <path d="M191.26 88.45L191.34 88.61" stroke="black" stroke-width="0.5" />
    <path d="M191.34 88.61L191.44 88.76" stroke="black" stroke-width="0.5" />
    <path d="M191.44 88.76L191.53 88.91" stroke="black" stroke-width="0.5" />
    <path d="M191.53 88.91L191.6 89.02" stroke="black" stroke-width="0.5" />
    <path d="M191.79 89.29L191.83 89.35" stroke="black" stroke-width="0.5" />
    <path d="M191.83 89.35L191.94 89.5" stroke="black" stroke-width="0.5" />
    <path d="M191.94 89.5L192.05 89.63" stroke="black" stroke-width="0.5" />
    <path d="M192.05 89.63L192.17 89.77" stroke="black" stroke-width="0.5" />
    <path d="M192.17 89.77L192.21 89.82" stroke="black" stroke-width="0.5" />
    <path d="M192.43 90.07L192.53 90.17" stroke="black" stroke-width="0.5" />
    <path d="M192.53 90.17L192.65 90.29" stroke="black" stroke-width="0.5" />
    <path d="M192.65 90.29L192.78 90.42" stroke="black" stroke-width="0.5" />
    <path d="M192.78 90.42L192.91 90.53" stroke="black" stroke-width="0.5" />
    <path d="M193.16 90.75L193.18 90.77" stroke="black" stroke-width="0.5" />
    <path d="M193.18 90.77L193.32 90.88" stroke="black" stroke-width="0.5" />
    <path d="M193.32 90.88L193.46 90.99" stroke="black" stroke-width="0.5" />
    <path d="M193.46 90.99L193.61 91.1" stroke="black" stroke-width="0.5" />
    <path d="M193.61 91.1L193.69 91.16" stroke="black" stroke-width="0.5" />
    <path d="M193.97 91.34L194.05 91.39" stroke="black" stroke-width="0.5" />
    <path d="M194.05 91.39L194.21 91.49" stroke="black" stroke-width="0.5" />
    <path d="M194.21 91.49L194.36 91.57" stroke="black" stroke-width="0.5" />
    <path d="M194.36 91.57L194.52 91.66" stroke="black" stroke-width="0.5" />
    <path d="M194.52 91.66L194.55 91.68" stroke="black" stroke-width="0.5" />
    <path d="M194.85 91.82L195 91.89" stroke="black" stroke-width="0.5" />
    <path d="M195 91.89L195.16 91.96" stroke="black" stroke-width="0.5" />
    <path d="M195.16 91.96L195.33 92.03" stroke="black" stroke-width="0.5" />
    <path d="M195.33 92.03L195.47 92.08" stroke="black" stroke-width="0.5" />
    <path d="M195.78 92.19L195.83 92.21" stroke="black" stroke-width="0.5" />
    <path d="M195.83 92.21L196 92.26" stroke="black" stroke-width="0.5" />
    <path d="M196 92.26L196.17 92.31" stroke="black" stroke-width="0.5" />
    <path d="M196.17 92.31L196.35 92.35" stroke="black" stroke-width="0.5" />
    <path d="M196.35 92.35L196.43 92.37" stroke="black" stroke-width="0.5" />
    <path d="M196.75 92.44L196.87 92.46" stroke="black" stroke-width="0.5" />
    <path d="M196.87 92.46L197.05 92.49" stroke="black" stroke-width="0.5" />
    <path d="M197.05 92.49L197.22 92.52" stroke="black" stroke-width="0.5" />
    <path d="M197.22 92.52L197.4 92.54" stroke="black" stroke-width="0.5" />
    <path d="M197.4 92.54H197.41" stroke="black" stroke-width="0.5" />
    <path d="M197.75 92.57H197.76" stroke="black" stroke-width="0.5" />
    <path d="M197.76 92.57L197.93 92.58" stroke="black" stroke-width="0.5" />
    <path d="M197.93 92.58H198.11" stroke="black" stroke-width="0.5" />
    <path d="M198.11 92.58H198.29" stroke="black" stroke-width="0.5" />
    <path d="M198.29 92.58H198.41" stroke="black" stroke-width="0.5" />
    <path d="M198.75 92.57L198.83 92.56" stroke="black" stroke-width="0.5" />
    <path d="M198.83 92.56L199 92.55" stroke="black" stroke-width="0.5" />
    <path d="M199 92.55L199.18 92.53" stroke="black" stroke-width="0.5" />
    <path d="M199.18 92.53L199.36 92.51" stroke="black" stroke-width="0.5" />
    <path d="M199.36 92.51L199.41 92.5" stroke="black" stroke-width="0.5" />
    <path d="M199.74 92.44L199.89 92.41" stroke="black" stroke-width="0.5" />
    <path d="M199.89 92.41L200.06 92.38" stroke="black" stroke-width="0.5" />
    <path d="M200.06 92.38L200.23 92.33" stroke="black" stroke-width="0.5" />
    <path d="M200.23 92.33L200.39 92.29" stroke="black" stroke-width="0.5" />
    <path d="M200.71 92.19L200.75 92.18" stroke="black" stroke-width="0.5" />
    <path d="M200.75 92.18L200.92 92.12" stroke="black" stroke-width="0.5" />
    <path d="M200.92 92.12L201.08 92.06" stroke="black" stroke-width="0.5" />
    <path d="M201.08 92.06L201.25 92" stroke="black" stroke-width="0.5" />
    <path d="M201.25 92L201.34 91.96" stroke="black" stroke-width="0.5" />
    <path d="M201.64 91.82L201.74 91.78" stroke="black" stroke-width="0.5" />
    <path d="M201.74 91.78L201.9 91.7" stroke="black" stroke-width="0.5" />
    <path d="M201.9 91.7L202.05 91.62" stroke="black" stroke-width="0.5" />
    <path d="M202.05 91.62L202.21 91.53" stroke="black" stroke-width="0.5" />
    <path d="M202.21 91.53L202.24 91.52" stroke="black" stroke-width="0.5" />
    <path d="M202.52 91.34L202.67 91.25" stroke="black" stroke-width="0.5" />
    <path d="M202.67 91.25L202.81 91.15" stroke="black" stroke-width="0.5" />
    <path d="M202.81 91.15L202.96 91.04" stroke="black" stroke-width="0.5" />
    <path d="M202.96 91.04L203.07 90.96" stroke="black" stroke-width="0.5" />
    <path d="M203.33 90.75L203.38 90.71" stroke="black" stroke-width="0.5" />
    <path d="M203.38 90.71L203.51 90.6" stroke="black" stroke-width="0.5" />
    <path d="M203.51 90.6L203.65 90.48" stroke="black" stroke-width="0.5" />
    <path d="M203.65 90.48L203.78 90.36" stroke="black" stroke-width="0.5" />
    <path d="M203.78 90.36L203.83 90.31" stroke="black" stroke-width="0.5" />
    <path d="M204.06 90.07L204.15 89.97" stroke="black" stroke-width="0.5" />
    <path d="M204.15 89.97L204.27 89.84" stroke="black" stroke-width="0.5" />
    <path d="M204.27 89.84L204.38 89.7" stroke="black" stroke-width="0.5" />
    <path d="M204.38 89.7L204.5 89.57" stroke="black" stroke-width="0.5" />
    <path d="M204.5 89.57V89.56" stroke="black" stroke-width="0.5" />
    <path d="M204.7 89.3L204.71 89.28" stroke="black" stroke-width="0.5" />
    <path d="M204.71 89.28L204.82 89.14" stroke="black" stroke-width="0.5" />
    <path d="M204.82 89.14L204.91 88.99" stroke="black" stroke-width="0.5" />
    <path d="M204.91 88.99L205.01 88.84" stroke="black" stroke-width="0.5" />
    <path d="M205.01 88.84L205.07 88.74" stroke="black" stroke-width="0.5" />
    <path d="M205.24 88.45L205.28 88.37" stroke="black" stroke-width="0.5" />
    <path d="M205.28 88.37L205.36 88.22" stroke="black" stroke-width="0.5" />
    <path d="M205.36 88.22L205.44 88.06" stroke="black" stroke-width="0.5" />
    <path d="M205.44 88.06L205.52 87.89" stroke="black" stroke-width="0.5" />
    <path d="M205.52 87.89L205.54 87.85" stroke="black" stroke-width="0.5" />
    <path d="M205.67 87.54L205.72 87.4" stroke="black" stroke-width="0.5" />
    <path d="M205.72 87.4L205.78 87.23" stroke="black" stroke-width="0.5" />
    <path d="M205.78 87.23L205.84 87.06" stroke="black" stroke-width="0.5" />
    <path d="M205.84 87.06L205.89 86.91" stroke="black" stroke-width="0.5" />
    <path d="M205.97 86.59L205.99 86.55" stroke="black" stroke-width="0.5" />
    <path d="M205.99 86.55L206.03 86.37" stroke="black" stroke-width="0.5" />
    <path d="M206.03 86.37L206.06 86.2" stroke="black" stroke-width="0.5" />
    <path d="M206.06 86.2L206.1 86.03" stroke="black" stroke-width="0.5" />
    <path d="M206.1 86.03L206.11 85.94" stroke="black" stroke-width="0.5" />
    <path d="M206.16 85.61L206.18 85.5" stroke="black" stroke-width="0.5" />
    <path d="M206.18 85.5L206.19 85.32" stroke="black" stroke-width="0.5" />
    <path d="M206.19 85.32L206.21 85.14" stroke="black" stroke-width="0.5" />
    <path d="M206.21 85.14L206.22 84.96" stroke="black" stroke-width="0.5" />
    <path d="M206.22 84.96V84.94" stroke="black" stroke-width="0.5" />
    <path d="M215.49 104.09H189.96" stroke="black" stroke-width="0.5" />
    <path d="M189.96 104.09V129.09" stroke="black" stroke-width="0.5" />
    <path d="M215.49 99.31H189.96" stroke="black" stroke-width="0.5" />
    <path d="M189.96 99.31V74.31" stroke="black" stroke-width="0.5" />
    <path d="M15.02 100.97H22.26" stroke="black" stroke-width="0.5" />
    <path d="M22.26 100.97V112.17" stroke="black" stroke-width="0.5" />
    <path d="M22.26 112.17H15.02" stroke="black" stroke-width="0.5" />
    <path d="M15.02 112.17V100.97" stroke="black" stroke-width="0.5" />
    <path d="M152.3 109.69V110.04" stroke="black" stroke-width="0.5" />
    <path d="M153.32 109.69H152.3" stroke="black" stroke-width="0.5" />
    <path d="M153.32 110.04V109.69" stroke="black" stroke-width="0.5" />
    <path d="M152.3 110.04H153.32" stroke="black" stroke-width="0.5" />
    <path d="M150.11 106.1L150.17 105.8" stroke="black" stroke-width="0.5" />
    <path d="M150.17 105.8L150.35 105.58" stroke="black" stroke-width="0.5" />
    <path d="M150.35 105.58L150.6 105.5" stroke="black" stroke-width="0.5" />
    <path d="M155.01 105.5L155.26 105.58" stroke="black" stroke-width="0.5" />
    <path d="M155.26 105.58L155.44 105.8" stroke="black" stroke-width="0.5" />
    <path d="M155.44 105.8L155.51 106.1" stroke="black" stroke-width="0.5" />
    <path d="M155.51 108.69L155.37 109.11" stroke="black" stroke-width="0.5" />
    <path d="M155.37 109.11L155.01 109.28" stroke="black" stroke-width="0.5" />
    <path d="M150.6 109.28L150.25 109.11" stroke="black" stroke-width="0.5" />
    <path d="M150.25 109.11L150.11 108.69" stroke="black" stroke-width="0.5" />
    <path d="M150.11 106.1V108.69" stroke="black" stroke-width="0.5" />
    <path d="M155.01 105.5H150.6" stroke="black" stroke-width="0.5" />
    <path d="M155.51 108.69V106.1" stroke="black" stroke-width="0.5" />
    <path d="M150.6 109.28H155.01" stroke="black" stroke-width="0.5" />
    <path d="M149.77 105.16V110.41" stroke="black" stroke-width="0.5" />
    <path d="M155.85 105.16H149.77" stroke="black" stroke-width="0.5" />
    <path d="M155.85 110.41V105.16" stroke="black" stroke-width="0.5" />
    <path d="M149.77 110.41H155.85" stroke="black" stroke-width="0.5" />
    <path d="M210.99 75.62V75.25" stroke="black" stroke-width="0.5" />
    <path d="M209.89 75.62H210.99" stroke="black" stroke-width="0.5" />
    <path d="M209.89 75.25V75.62" stroke="black" stroke-width="0.5" />
    <path d="M210.99 75.25H209.89" stroke="black" stroke-width="0.5" />
    <path d="M213.38 79.53L213.31 79.86" stroke="black" stroke-width="0.5" />
    <path d="M213.31 79.86L213.11 80.1" stroke="black" stroke-width="0.5" />
    <path d="M213.11 80.1L212.84 80.18" stroke="black" stroke-width="0.5" />
    <path d="M208.04 80.18L207.77 80.1" stroke="black" stroke-width="0.5" />
    <path d="M207.77 80.1L207.57 79.86" stroke="black" stroke-width="0.5" />
    <path d="M207.57 79.86L207.5 79.53" stroke="black" stroke-width="0.5" />
    <path d="M207.5 76.72L207.65 76.26" stroke="black" stroke-width="0.5" />
    <path d="M207.65 76.26L208.04 76.07" stroke="black" stroke-width="0.5" />
    <path d="M212.84 76.07L213.23 76.26" stroke="black" stroke-width="0.5" />
    <path d="M213.23 76.26L213.38 76.72" stroke="black" stroke-width="0.5" />
    <path d="M213.38 79.53V76.72" stroke="black" stroke-width="0.5" />
    <path d="M208.04 80.18H212.84" stroke="black" stroke-width="0.5" />
    <path d="M207.5 76.72V79.53" stroke="black" stroke-width="0.5" />
    <path d="M212.84 76.07H208.04" stroke="black" stroke-width="0.5" />
    <path d="M213.75 80.56V74.84" stroke="black" stroke-width="0.5" />
    <path d="M207.13 80.56H213.75" stroke="black" stroke-width="0.5" />
    <path d="M207.13 74.84V80.56" stroke="black" stroke-width="0.5" />
    <path d="M213.75 74.84H207.13" stroke="black" stroke-width="0.5" />
    <path d="M206.33 75.69H214.26" stroke="black" stroke-width="0.5" />
    <path d="M214.26 75.69V87.95" stroke="black" stroke-width="0.5" />
    <path d="M214.26 87.95H206.33" stroke="black" stroke-width="0.5" />
    <path d="M206.33 87.95V75.69" stroke="black" stroke-width="0.5" />
    <path d="M215.49 81.28H214.26" stroke="black" stroke-width="0.5" />
    <path d="M214.26 81.28V84.23" stroke="black" stroke-width="0.5" />
    <path d="M214.26 84.23H215.49" stroke="black" stroke-width="0.5" />
    <path d="M215.49 84.23V81.28" stroke="black" stroke-width="0.5" />
    <path d="M210.99 128.31V128.69" stroke="black" stroke-width="0.5" />
    <path d="M209.89 128.31H210.99" stroke="black" stroke-width="0.5" />
    <path d="M209.89 128.69V128.31" stroke="black" stroke-width="0.5" />
    <path d="M210.99 128.69H209.89" stroke="black" stroke-width="0.5" />
    <path d="M213.38 124.4L213.31 124.07" stroke="black" stroke-width="0.5" />
    <path d="M213.31 124.07L213.11 123.84" stroke="black" stroke-width="0.5" />
    <path d="M213.11 123.84L212.84 123.75" stroke="black" stroke-width="0.5" />
    <path d="M208.04 123.75L207.77 123.84" stroke="black" stroke-width="0.5" />
    <path d="M207.77 123.84L207.57 124.07" stroke="black" stroke-width="0.5" />
    <path d="M207.57 124.07L207.5 124.4" stroke="black" stroke-width="0.5" />
    <path d="M207.5 127.21L207.65 127.67" stroke="black" stroke-width="0.5" />
    <path d="M207.65 127.67L208.04 127.86" stroke="black" stroke-width="0.5" />
    <path d="M212.84 127.87L213.23 127.68" stroke="black" stroke-width="0.5" />
    <path d="M213.23 127.68L213.38 127.22" stroke="black" stroke-width="0.5" />
    <path d="M213.38 124.4V127.22" stroke="black" stroke-width="0.5" />
    <path d="M208.04 123.75H212.84" stroke="black" stroke-width="0.5" />
    <path d="M207.5 127.21V124.4" stroke="black" stroke-width="0.5" />
    <path d="M212.84 127.87L208.04 127.86" stroke="black" stroke-width="0.5" />
    <path d="M213.75 123.37V129.09" stroke="black" stroke-width="0.5" />
    <path d="M207.13 123.37H213.75" stroke="black" stroke-width="0.5" />
    <path d="M207.13 129.09V123.37" stroke="black" stroke-width="0.5" />
    <path d="M213.75 129.09H207.13" stroke="black" stroke-width="0.5" />
    <path d="M206.33 128.24H214.26" stroke="black" stroke-width="0.5" />
    <path d="M214.26 128.24V115.98" stroke="black" stroke-width="0.5" />
    <path d="M214.26 115.98H206.33" stroke="black" stroke-width="0.5" />
    <path d="M206.33 115.98V128.24" stroke="black" stroke-width="0.5" />
    <path d="M215.49 122.62H214.26" stroke="black" stroke-width="0.5" />
    <path d="M214.26 122.62V119.66" stroke="black" stroke-width="0.5" />
    <path d="M214.26 119.66H215.49" stroke="black" stroke-width="0.5" />
    <path d="M215.49 119.66V122.62" stroke="black" stroke-width="0.5" />
    <path d="M135.03 108.35H136.03" stroke="black" stroke-width="0.5" />
    <path d="M137.03 108.35H139.03" stroke="black" stroke-width="0.5" />
    <path d="M140.02 108.35H142.02" stroke="black" stroke-width="0.5" />
    <path d="M143.01 108.35H145.01" stroke="black" stroke-width="0.5" />
    <path d="M146.01 108.35H148" stroke="black" stroke-width="0.5" />
    <path d="M149 108.35H150.99" stroke="black" stroke-width="0.5" />
    <path d="M151.99 108.35H153.99" stroke="black" stroke-width="0.5" />
    <path d="M154.98 108.35H156.98" stroke="black" stroke-width="0.5" />
    <path d="M157.97 108.35H158.97" stroke="black" stroke-width="0.5" />
    <path d="M163.49 111.54V109.75" stroke="black" stroke-width="0.5" />
    <path d="M163.49 108.75V106.75" stroke="black" stroke-width="0.5" />
    <path d="M163.49 105.76V103.76" stroke="black" stroke-width="0.5" />
    <path d="M163.49 102.76V100.77" stroke="black" stroke-width="0.5" />
    <path d="M163.49 99.77V97.78" stroke="black" stroke-width="0.5" />
    <path d="M163.49 96.78V94.78" stroke="black" stroke-width="0.5" />
    <path d="M163.49 93.79V91.79" stroke="black" stroke-width="0.5" />
    <path d="M163.49 90.8V88.8" stroke="black" stroke-width="0.5" />
    <path d="M163.49 87.8V85.81" stroke="black" stroke-width="0.5" />
    <path d="M163.49 84.81V82.82" stroke="black" stroke-width="0.5" />
    <path d="M163.49 81.82V79.82" stroke="black" stroke-width="0.5" />
    <path d="M163.49 78.83V76.83" stroke="black" stroke-width="0.5" />
    <path d="M163.49 75.84V74.04" stroke="black" stroke-width="0.5" />
    <path d="M160.86 74.9H166.05" stroke="black" stroke-width="0.5" />
    <path d="M166.05 74.9V81.27" stroke="black" stroke-width="0.5" />
    <path d="M166.05 81.27H160.86" stroke="black" stroke-width="0.5" />
    <path d="M160.86 81.27V74.9" stroke="black" stroke-width="0.5" />
    <path d="M97.54 119.25V119.79" stroke="black" stroke-width="0.5" />
    <path d="M127.06 111.54V112.07" stroke="black" stroke-width="0.5" />
    <path d="M158.97 111.54V112.07" stroke="black" stroke-width="0.5" />
    <path d="M125.73 96.11H126.26" stroke="black" stroke-width="0.5" />
    <path d="M95.54 112.47V114.63" stroke="black" stroke-width="0.5" />
    <path d="M95.54 115.63V117.62" stroke="black" stroke-width="0.5" />
    <path d="M95.54 118.62V120.62" stroke="black" stroke-width="0.5" />
    <path d="M95.54 121.61V123.61" stroke="black" stroke-width="0.5" />
    <path d="M95.54 124.61V126.6" stroke="black" stroke-width="0.5" />
    <path d="M95.54 127.6V129.76" stroke="black" stroke-width="0.5" />
    <path d="M62.42 114.64H73.15" stroke="black" stroke-width="0.5" />
    <path d="M73.15 114.64V127.59" stroke="black" stroke-width="0.5" />
    <path d="M73.15 127.59H62.42" stroke="black" stroke-width="0.5" />
    <path d="M62.42 127.59V114.64" stroke="black" stroke-width="0.5" />
    <path d="M76.32 114.64H87.47" stroke="black" stroke-width="0.5" />
    <path d="M87.47 114.64V127.59" stroke="black" stroke-width="0.5" />
    <path d="M87.47 127.59H76.32" stroke="black" stroke-width="0.5" />
    <path d="M76.32 127.59V114.64" stroke="black" stroke-width="0.5" />
    <path d="M89.67 116.41L91.33 121.12" stroke="black" stroke-width="0.5" />
    <path d="M89.67 116.41H92.99" stroke="black" stroke-width="0.5" />
    <path d="M92.99 116.41V125.82" stroke="black" stroke-width="0.5" />
    <path d="M92.99 125.82H89.67" stroke="black" stroke-width="0.5" />
    <path d="M89.67 125.82V116.41" stroke="black" stroke-width="0.5" />
    <path d="M92.99 116.41L89.67 125.82" stroke="black" stroke-width="0.5" />
    <path d="M90.6 119.07H92.05" stroke="black" stroke-width="0.5" />
    <path d="M90.02 117.41H92.64" stroke="black" stroke-width="0.5" />
    <path d="M58.57 113.14L59.83 113.69" stroke="black" stroke-width="0.5" />
    <path d="M60.75 114.09L62.57 114.89" stroke="black" stroke-width="0.5" />
    <path d="M63.49 115.3L65.31 116.1" stroke="black" stroke-width="0.5" />
    <path d="M66.22 116.5L68.05 117.3" stroke="black" stroke-width="0.5" />
    <path d="M68.96 117.7L70.79 118.51" stroke="black" stroke-width="0.5" />
    <path d="M71.7 118.91L73.53 119.71" stroke="black" stroke-width="0.5" />
    <path d="M74.44 120.11L76.27 120.91" stroke="black" stroke-width="0.5" />
    <path d="M77.18 121.32L79.01 122.12" stroke="black" stroke-width="0.5" />
    <path d="M79.92 122.52L81.75 123.32" stroke="black" stroke-width="0.5" />
    <path d="M82.66 123.72L84.49 124.53" stroke="black" stroke-width="0.5" />
    <path d="M85.4 124.93L87.23 125.73" stroke="black" stroke-width="0.5" />
    <path d="M88.14 126.13L89.96 126.93" stroke="black" stroke-width="0.5" />
    <path d="M90.88 127.34L92.7 128.14" stroke="black" stroke-width="0.5" />
    <path d="M93.62 128.54L94.88 129.09" stroke="black" stroke-width="0.5" />
    <path d="M94.88 113.14L93.62 113.69" stroke="black" stroke-width="0.5" />
    <path d="M92.7 114.09L90.88 114.89" stroke="black" stroke-width="0.5" />
    <path d="M89.96 115.3L88.14 116.1" stroke="black" stroke-width="0.5" />
    <path d="M87.23 116.5L85.4 117.3" stroke="black" stroke-width="0.5" />
    <path d="M84.49 117.7L82.66 118.51" stroke="black" stroke-width="0.5" />
    <path d="M81.75 118.91L79.92 119.71" stroke="black" stroke-width="0.5" />
    <path d="M79.01 120.11L77.18 120.91" stroke="black" stroke-width="0.5" />
    <path d="M76.27 121.32L74.44 122.12" stroke="black" stroke-width="0.5" />
    <path d="M73.53 122.52L71.7 123.32" stroke="black" stroke-width="0.5" />
    <path d="M70.79 123.72L68.96 124.53" stroke="black" stroke-width="0.5" />
    <path d="M68.05 124.93L66.22 125.73" stroke="black" stroke-width="0.5" />
    <path d="M65.31 126.13L63.49 126.93" stroke="black" stroke-width="0.5" />
    <path d="M62.57 127.34L60.75 128.14" stroke="black" stroke-width="0.5" />
    <path d="M59.83 128.54L58.57 129.09" stroke="black" stroke-width="0.5" />
    <path d="M87.47 114.64L76.32 127.59" stroke="black" stroke-width="0.5" />
    <path d="M62.42 127.59L73.15 114.64" stroke="black" stroke-width="0.5" />
    <path d="M76.32 114.64L87.47 127.59" stroke="black" stroke-width="0.5" />
    <path d="M90.73 6.33997H127.21" stroke="black" stroke-width="0.5" />
    <path d="M80.13 119.06H83.66" stroke="black" stroke-width="0.5" />
    <path d="M78.05 125.57H85.73" stroke="black" stroke-width="0.5" />
    <path d="M80.13 123.17H83.66" stroke="black" stroke-width="0.5" />
    <path d="M66.25 122.96H62.42" stroke="black" stroke-width="0.5" />
    <path d="M71.77 116.31H62.42" stroke="black" stroke-width="0.5" />
    <path d="M70 118.44H62.42" stroke="black" stroke-width="0.5" />
    <path d="M68.02 120.83H62.42" stroke="black" stroke-width="0.5" />
    <path d="M64.49 125.09H62.42" stroke="black" stroke-width="0.5" />
    <path d="M98.61 5.76001H99.59" stroke="black" stroke-width="0.5" />
    <path d="M191.29 104.29H190.76" stroke="black" stroke-width="0.5" />
    <path d="M190.16 105.42V105.96" stroke="black" stroke-width="0.5" />
    <path d="M190.16 117.52V118.06" stroke="black" stroke-width="0.5" />
    <path d="M200.86 104.29H200.33" stroke="black" stroke-width="0.5" />
    <path d="M190.73 105.74L190.74 105.75" stroke="black" stroke-width="0.5" />
    <path d="M190.74 105.75L190.75 105.77" stroke="black" stroke-width="0.5" />
    <path d="M190.75 105.77V105.78" stroke="black" stroke-width="0.5" />
    <path d="M190.75 105.78L190.76 105.8" stroke="black" stroke-width="0.5" />
    <path d="M190.76 105.8L190.77 105.81" stroke="black" stroke-width="0.5" />
    <path d="M190.77 105.81V105.83" stroke="black" stroke-width="0.5" />
    <path d="M190.77 105.83L190.78 105.85" stroke="black" stroke-width="0.5" />
    <path d="M190.78 105.85V105.86" stroke="black" stroke-width="0.5" />
    <path d="M190.78 105.86V105.88" stroke="black" stroke-width="0.5" />
    <path d="M190.78 105.88L190.79 105.9" stroke="black" stroke-width="0.5" />
    <path d="M190.79 105.9V105.92" stroke="black" stroke-width="0.5" />
    <path d="M190.79 105.92V105.93" stroke="black" stroke-width="0.5" />
    <path d="M190.79 105.93V105.95" stroke="black" stroke-width="0.5" />
    <path d="M190.79 105.95V105.96" stroke="black" stroke-width="0.5" />
    <path d="M190.57 105.58L190.59 105.59" stroke="black" stroke-width="0.5" />
    <path d="M190.59 105.59L190.6 105.6" stroke="black" stroke-width="0.5" />
    <path d="M190.6 105.6L190.61 105.61" stroke="black" stroke-width="0.5" />
    <path d="M190.61 105.61L190.63 105.62" stroke="black" stroke-width="0.5" />
    <path d="M190.63 105.62L190.64 105.63" stroke="black" stroke-width="0.5" />
    <path d="M190.64 105.63L190.65 105.64" stroke="black" stroke-width="0.5" />
    <path d="M190.65 105.64L190.67 105.65" stroke="black" stroke-width="0.5" />
    <path d="M190.67 105.65L190.68 105.67" stroke="black" stroke-width="0.5" />
    <path d="M190.68 105.67L190.69 105.68" stroke="black" stroke-width="0.5" />
    <path d="M190.69 105.68L190.7 105.69" stroke="black" stroke-width="0.5" />
    <path d="M190.7 105.69L190.71 105.71" stroke="black" stroke-width="0.5" />
    <path d="M190.71 105.71L190.72 105.72" stroke="black" stroke-width="0.5" />
    <path d="M190.72 105.72L190.73 105.74" stroke="black" stroke-width="0.5" />
    <path d="M190.36 105.52H190.37" stroke="black" stroke-width="0.5" />
    <path d="M190.37 105.52H190.39" stroke="black" stroke-width="0.5" />
    <path d="M190.39 105.52L190.41 105.53" stroke="black" stroke-width="0.5" />
    <path d="M190.41 105.53H190.43" stroke="black" stroke-width="0.5" />
    <path d="M190.43 105.53H190.44" stroke="black" stroke-width="0.5" />
    <path d="M190.44 105.53L190.46 105.54" stroke="black" stroke-width="0.5" />
    <path d="M190.46 105.54H190.48" stroke="black" stroke-width="0.5" />
    <path d="M190.48 105.54H190.49" stroke="black" stroke-width="0.5" />
    <path d="M190.49 105.54L190.51 105.55" stroke="black" stroke-width="0.5" />
    <path d="M190.51 105.55L190.52 105.56" stroke="black" stroke-width="0.5" />
    <path d="M190.52 105.56H190.54" stroke="black" stroke-width="0.5" />
    <path d="M190.54 105.56L190.56 105.57" stroke="black" stroke-width="0.5" />
    <path d="M190.56 105.57L190.57 105.58" stroke="black" stroke-width="0.5" />
    <path d="M190.43 105.88H190.42" stroke="black" stroke-width="0.5" />
    <path d="M190.42 105.88L190.41 105.87" stroke="black" stroke-width="0.5" />
    <path d="M190.41 105.87H190.4" stroke="black" stroke-width="0.5" />
    <path d="M190.4 105.87V105.86" stroke="black" stroke-width="0.5" />
    <path d="M190.4 105.86H190.39" stroke="black" stroke-width="0.5" />
    <path d="M190.39 105.86H190.38" stroke="black" stroke-width="0.5" />
    <path d="M190.38 105.86H190.37" stroke="black" stroke-width="0.5" />
    <path d="M190.37 105.86H190.36" stroke="black" stroke-width="0.5" />
    <path d="M190.46 105.96V105.95" stroke="black" stroke-width="0.5" />
    <path d="M190.46 105.95V105.94" stroke="black" stroke-width="0.5" />
    <path d="M190.46 105.94H190.45" stroke="black" stroke-width="0.5" />
    <path d="M190.45 105.94V105.93" stroke="black" stroke-width="0.5" />
    <path d="M190.45 105.93V105.92" stroke="black" stroke-width="0.5" />
    <path d="M190.45 105.92V105.91" stroke="black" stroke-width="0.5" />
    <path d="M190.45 105.91H190.44" stroke="black" stroke-width="0.5" />
    <path d="M190.44 105.91V105.9" stroke="black" stroke-width="0.5" />
    <path d="M190.44 105.9L190.43 105.89" stroke="black" stroke-width="0.5" />
    <path d="M190.43 105.89V105.88" stroke="black" stroke-width="0.5" />
    <path d="M190.57 117.9L190.56 117.91" stroke="black" stroke-width="0.5" />
    <path d="M190.56 117.91H190.54" stroke="black" stroke-width="0.5" />
    <path d="M190.54 117.91L190.53 117.92" stroke="black" stroke-width="0.5" />
    <path d="M190.53 117.92L190.51 117.93" stroke="black" stroke-width="0.5" />
    <path d="M190.51 117.93H190.5" stroke="black" stroke-width="0.5" />
    <path d="M190.5 117.93L190.48 117.94" stroke="black" stroke-width="0.5" />
    <path d="M190.48 117.94H190.46" stroke="black" stroke-width="0.5" />
    <path d="M190.46 117.94L190.45 117.95" stroke="black" stroke-width="0.5" />
    <path d="M190.45 117.95H190.43" stroke="black" stroke-width="0.5" />
    <path d="M190.43 117.95H190.41" stroke="black" stroke-width="0.5" />
    <path d="M190.41 117.95H190.4" stroke="black" stroke-width="0.5" />
    <path d="M190.4 117.95L190.38 117.96" stroke="black" stroke-width="0.5" />
    <path d="M190.38 117.96H190.36" stroke="black" stroke-width="0.5" />
    <path d="M190.73 117.74L190.72 117.75" stroke="black" stroke-width="0.5" />
    <path d="M190.72 117.75L190.71 117.77" stroke="black" stroke-width="0.5" />
    <path d="M190.71 117.77L190.7 117.78" stroke="black" stroke-width="0.5" />
    <path d="M190.7 117.78L190.69 117.8" stroke="black" stroke-width="0.5" />
    <path d="M190.69 117.8L190.68 117.81" stroke="black" stroke-width="0.5" />
    <path d="M190.68 117.81L190.67 117.82" stroke="black" stroke-width="0.5" />
    <path d="M190.67 117.82L190.66 117.83" stroke="black" stroke-width="0.5" />
    <path d="M190.66 117.83L190.65 117.85" stroke="black" stroke-width="0.5" />
    <path d="M190.65 117.85L190.63 117.86" stroke="black" stroke-width="0.5" />
    <path d="M190.63 117.86L190.62 117.87" stroke="black" stroke-width="0.5" />
    <path d="M190.62 117.87L190.6 117.88" stroke="black" stroke-width="0.5" />
    <path d="M190.6 117.88L190.59 117.89" stroke="black" stroke-width="0.5" />
    <path d="M190.59 117.89L190.58 117.9" stroke="black" stroke-width="0.5" />
    <path d="M190.58 117.9H190.57" stroke="black" stroke-width="0.5" />
    <path d="M190.79 117.52V117.54" stroke="black" stroke-width="0.5" />
    <path d="M190.79 117.54V117.56" stroke="black" stroke-width="0.5" />
    <path d="M190.79 117.56V117.58" stroke="black" stroke-width="0.5" />
    <path d="M190.79 117.58L190.78 117.59" stroke="black" stroke-width="0.5" />
    <path d="M190.78 117.59V117.61" stroke="black" stroke-width="0.5" />
    <path d="M190.78 117.61V117.63" stroke="black" stroke-width="0.5" />
    <path d="M190.78 117.63L190.77 117.64" stroke="black" stroke-width="0.5" />
    <path d="M190.77 117.64V117.66" stroke="black" stroke-width="0.5" />
    <path d="M190.77 117.66L190.76 117.68" stroke="black" stroke-width="0.5" />
    <path d="M190.76 117.68L190.75 117.69" stroke="black" stroke-width="0.5" />
    <path d="M190.75 117.69V117.71" stroke="black" stroke-width="0.5" />
    <path d="M190.75 117.71L190.74 117.72" stroke="black" stroke-width="0.5" />
    <path d="M190.74 117.72L190.73 117.74" stroke="black" stroke-width="0.5" />
    <path d="M190.43 117.6V117.59" stroke="black" stroke-width="0.5" />
    <path d="M190.43 117.59L190.44 117.58" stroke="black" stroke-width="0.5" />
    <path d="M190.44 117.58V117.57" stroke="black" stroke-width="0.5" />
    <path d="M190.44 117.57H190.45" stroke="black" stroke-width="0.5" />
    <path d="M190.45 117.57V117.56" stroke="black" stroke-width="0.5" />
    <path d="M190.45 117.56V117.55" stroke="black" stroke-width="0.5" />
    <path d="M190.45 117.55V117.54" stroke="black" stroke-width="0.5" />
    <path d="M190.45 117.54H190.46" stroke="black" stroke-width="0.5" />
    <path d="M190.46 117.54V117.53" stroke="black" stroke-width="0.5" />
    <path d="M190.46 117.53V117.52" stroke="black" stroke-width="0.5" />
    <path d="M190.36 117.62H190.37" stroke="black" stroke-width="0.5" />
    <path d="M190.37 117.62H190.38" stroke="black" stroke-width="0.5" />
    <path d="M190.38 117.62H190.39" stroke="black" stroke-width="0.5" />
    <path d="M190.39 117.62H190.4" stroke="black" stroke-width="0.5" />
    <path d="M190.4 117.62V117.61" stroke="black" stroke-width="0.5" />
    <path d="M190.4 117.61H190.41" stroke="black" stroke-width="0.5" />
    <path d="M190.41 117.61L190.42 117.6" stroke="black" stroke-width="0.5" />
    <path d="M190.42 117.6H190.43" stroke="black" stroke-width="0.5" />
    <path d="M190.91 104.71V104.7" stroke="black" stroke-width="0.5" />
    <path d="M190.91 104.7L190.9 104.68" stroke="black" stroke-width="0.5" />
    <path d="M190.9 104.68L190.89 104.66" stroke="black" stroke-width="0.5" />
    <path d="M190.89 104.66L190.88 104.65" stroke="black" stroke-width="0.5" />
    <path d="M190.88 104.65V104.63" stroke="black" stroke-width="0.5" />
    <path d="M190.88 104.63L190.87 104.62" stroke="black" stroke-width="0.5" />
    <path d="M190.87 104.62V104.6" stroke="black" stroke-width="0.5" />
    <path d="M190.87 104.6L190.86 104.58" stroke="black" stroke-width="0.5" />
    <path d="M190.86 104.58V104.57" stroke="black" stroke-width="0.5" />
    <path d="M190.86 104.57V104.55" stroke="black" stroke-width="0.5" />
    <path d="M190.86 104.55V104.53" stroke="black" stroke-width="0.5" />
    <path d="M190.86 104.53V104.51" stroke="black" stroke-width="0.5" />
    <path d="M190.86 104.51V104.5" stroke="black" stroke-width="0.5" />
    <path d="M190.86 104.5V104.49" stroke="black" stroke-width="0.5" />
    <path d="M191.07 104.87L191.06 104.86" stroke="black" stroke-width="0.5" />
    <path d="M191.06 104.86L191.04 104.85" stroke="black" stroke-width="0.5" />
    <path d="M191.04 104.85L191.03 104.84" stroke="black" stroke-width="0.5" />
    <path d="M191.03 104.84L191.02 104.83" stroke="black" stroke-width="0.5" />
    <path d="M191.02 104.83L191 104.82" stroke="black" stroke-width="0.5" />
    <path d="M191 104.82L190.99 104.81" stroke="black" stroke-width="0.5" />
    <path d="M190.99 104.81L190.98 104.79" stroke="black" stroke-width="0.5" />
    <path d="M190.98 104.79L190.97 104.78" stroke="black" stroke-width="0.5" />
    <path d="M190.97 104.78L190.95 104.77" stroke="black" stroke-width="0.5" />
    <path d="M190.95 104.77L190.94 104.75" stroke="black" stroke-width="0.5" />
    <path d="M190.94 104.75L190.93 104.74" stroke="black" stroke-width="0.5" />
    <path d="M190.93 104.74L190.92 104.73" stroke="black" stroke-width="0.5" />
    <path d="M190.92 104.73L190.91 104.71" stroke="black" stroke-width="0.5" />
    <path d="M191.29 104.92H191.27" stroke="black" stroke-width="0.5" />
    <path d="M191.27 104.92H191.25" stroke="black" stroke-width="0.5" />
    <path d="M191.25 104.92H191.24" stroke="black" stroke-width="0.5" />
    <path d="M191.24 104.92H191.22" stroke="black" stroke-width="0.5" />
    <path d="M191.22 104.92H191.2" stroke="black" stroke-width="0.5" />
    <path d="M191.2 104.92L191.18 104.91" stroke="black" stroke-width="0.5" />
    <path d="M191.18 104.91H191.17" stroke="black" stroke-width="0.5" />
    <path d="M191.17 104.91L191.15 104.9" stroke="black" stroke-width="0.5" />
    <path d="M191.15 104.9H191.14" stroke="black" stroke-width="0.5" />
    <path d="M191.14 104.9L191.12 104.89" stroke="black" stroke-width="0.5" />
    <path d="M191.12 104.89L191.1 104.88" stroke="black" stroke-width="0.5" />
    <path d="M191.1 104.88H191.09" stroke="black" stroke-width="0.5" />
    <path d="M191.09 104.88L191.07 104.87" stroke="black" stroke-width="0.5" />
    <path d="M191.22 104.56V104.57" stroke="black" stroke-width="0.5" />
    <path d="M191.22 104.57H191.23" stroke="black" stroke-width="0.5" />
    <path d="M191.23 104.57V104.58" stroke="black" stroke-width="0.5" />
    <path d="M191.23 104.58H191.24" stroke="black" stroke-width="0.5" />
    <path d="M191.24 104.58H191.25" stroke="black" stroke-width="0.5" />
    <path d="M191.25 104.58V104.59" stroke="black" stroke-width="0.5" />
    <path d="M191.25 104.59H191.26" stroke="black" stroke-width="0.5" />
    <path d="M191.26 104.59H191.27" stroke="black" stroke-width="0.5" />
    <path d="M191.27 104.59H191.28" stroke="black" stroke-width="0.5" />
    <path d="M191.28 104.59H191.29" stroke="black" stroke-width="0.5" />
    <path d="M191.19 104.49V104.5" stroke="black" stroke-width="0.5" />
    <path d="M191.19 104.5V104.51" stroke="black" stroke-width="0.5" />
    <path d="M191.19 104.51V104.52" stroke="black" stroke-width="0.5" />
    <path d="M191.19 104.52V104.53" stroke="black" stroke-width="0.5" />
    <path d="M191.19 104.53H191.2" stroke="black" stroke-width="0.5" />
    <path d="M191.2 104.53V104.54" stroke="black" stroke-width="0.5" />
    <path d="M191.2 104.54V104.55" stroke="black" stroke-width="0.5" />
    <path d="M191.2 104.55H191.21" stroke="black" stroke-width="0.5" />
    <path d="M191.21 104.55V104.56" stroke="black" stroke-width="0.5" />
    <path d="M191.21 104.56H191.22" stroke="black" stroke-width="0.5" />
    <path d="M200.55 104.87H200.53" stroke="black" stroke-width="0.5" />
    <path d="M200.53 104.87L200.52 104.88" stroke="black" stroke-width="0.5" />
    <path d="M200.52 104.88L200.5 104.89" stroke="black" stroke-width="0.5" />
    <path d="M200.5 104.89L200.49 104.9" stroke="black" stroke-width="0.5" />
    <path d="M200.49 104.9H200.47" stroke="black" stroke-width="0.5" />
    <path d="M200.47 104.9L200.45 104.91" stroke="black" stroke-width="0.5" />
    <path d="M200.45 104.91H200.44" stroke="black" stroke-width="0.5" />
    <path d="M200.44 104.91L200.42 104.92" stroke="black" stroke-width="0.5" />
    <path d="M200.42 104.92H200.4" stroke="black" stroke-width="0.5" />
    <path d="M200.4 104.92H200.39" stroke="black" stroke-width="0.5" />
    <path d="M200.39 104.92H200.37" stroke="black" stroke-width="0.5" />
    <path d="M200.37 104.92H200.35" stroke="black" stroke-width="0.5" />
    <path d="M200.35 104.92H200.33" stroke="black" stroke-width="0.5" />
    <path d="M200.7 104.71V104.72" stroke="black" stroke-width="0.5" />
    <path d="M200.7 104.72L200.69 104.74" stroke="black" stroke-width="0.5" />
    <path d="M200.69 104.74L200.68 104.75" stroke="black" stroke-width="0.5" />
    <path d="M200.68 104.75L200.67 104.76" stroke="black" stroke-width="0.5" />
    <path d="M200.67 104.76L200.66 104.78" stroke="black" stroke-width="0.5" />
    <path d="M200.66 104.78L200.64 104.79" stroke="black" stroke-width="0.5" />
    <path d="M200.64 104.79L200.63 104.8" stroke="black" stroke-width="0.5" />
    <path d="M200.63 104.8L200.62 104.81" stroke="black" stroke-width="0.5" />
    <path d="M200.62 104.81L200.61 104.83" stroke="black" stroke-width="0.5" />
    <path d="M200.61 104.83L200.59 104.84" stroke="black" stroke-width="0.5" />
    <path d="M200.59 104.84L200.58 104.85" stroke="black" stroke-width="0.5" />
    <path d="M200.58 104.85L200.56 104.86" stroke="black" stroke-width="0.5" />
    <path d="M200.56 104.86L200.55 104.87" stroke="black" stroke-width="0.5" />
    <path d="M200.76 104.49V104.51" stroke="black" stroke-width="0.5" />
    <path d="M200.76 104.51V104.53" stroke="black" stroke-width="0.5" />
    <path d="M200.76 104.53V104.54" stroke="black" stroke-width="0.5" />
    <path d="M200.76 104.54V104.56" stroke="black" stroke-width="0.5" />
    <path d="M200.76 104.56L200.75 104.58" stroke="black" stroke-width="0.5" />
    <path d="M200.75 104.58V104.6" stroke="black" stroke-width="0.5" />
    <path d="M200.75 104.6V104.61" stroke="black" stroke-width="0.5" />
    <path d="M200.75 104.61L200.74 104.63" stroke="black" stroke-width="0.5" />
    <path d="M200.74 104.63L200.73 104.64" stroke="black" stroke-width="0.5" />
    <path d="M200.73 104.64V104.66" stroke="black" stroke-width="0.5" />
    <path d="M200.73 104.66L200.72 104.68" stroke="black" stroke-width="0.5" />
    <path d="M200.72 104.68L200.71 104.69" stroke="black" stroke-width="0.5" />
    <path d="M200.71 104.69V104.71" stroke="black" stroke-width="0.5" />
    <path d="M200.71 104.71H200.7" stroke="black" stroke-width="0.5" />
    <path d="M200.4 104.56H200.41" stroke="black" stroke-width="0.5" />
    <path d="M200.41 104.56V104.55" stroke="black" stroke-width="0.5" />
    <path d="M200.41 104.55L200.42 104.54" stroke="black" stroke-width="0.5" />
    <path d="M200.42 104.54V104.53" stroke="black" stroke-width="0.5" />
    <path d="M200.42 104.53V104.52" stroke="black" stroke-width="0.5" />
    <path d="M200.42 104.52H200.43" stroke="black" stroke-width="0.5" />
    <path d="M200.43 104.52V104.51" stroke="black" stroke-width="0.5" />
    <path d="M200.43 104.51V104.5" stroke="black" stroke-width="0.5" />
    <path d="M200.43 104.5V104.49" stroke="black" stroke-width="0.5" />
    <path d="M200.33 104.59H200.34" stroke="black" stroke-width="0.5" />
    <path d="M200.34 104.59H200.35" stroke="black" stroke-width="0.5" />
    <path d="M200.35 104.59H200.36" stroke="black" stroke-width="0.5" />
    <path d="M200.36 104.59H200.37" stroke="black" stroke-width="0.5" />
    <path d="M200.37 104.59V104.58" stroke="black" stroke-width="0.5" />
    <path d="M200.37 104.58H200.38" stroke="black" stroke-width="0.5" />
    <path d="M200.38 104.58L200.39 104.57" stroke="black" stroke-width="0.5" />
    <path d="M200.39 104.57H200.4" stroke="black" stroke-width="0.5" />
    <path d="M200.4 104.57V104.56" stroke="black" stroke-width="0.5" />
    <path d="M190.36 105.52H190.16" stroke="black" stroke-width="0.5" />
    <path d="M190.16 105.86H190.36" stroke="black" stroke-width="0.5" />
    <path d="M190.79 117.52V105.96" stroke="black" stroke-width="0.5" />
    <path d="M190.46 105.96V117.52" stroke="black" stroke-width="0.5" />
    <path d="M190.16 117.96H190.36" stroke="black" stroke-width="0.5" />
    <path d="M190.36 117.62H190.16" stroke="black" stroke-width="0.5" />
    <path d="M191.29 104.29V104.09" stroke="black" stroke-width="0.5" />
    <path d="M190.76 104.09V104.29" stroke="black" stroke-width="0.5" />
    <path d="M191.19 104.49V104.29" stroke="black" stroke-width="0.5" />
    <path d="M190.86 104.29V104.49" stroke="black" stroke-width="0.5" />
    <path d="M200.33 104.59H191.29" stroke="black" stroke-width="0.5" />
    <path d="M191.29 104.92H200.33" stroke="black" stroke-width="0.5" />
    <path d="M200.43 104.29V104.49" stroke="black" stroke-width="0.5" />
    <path d="M200.76 104.49V104.29" stroke="black" stroke-width="0.5" />
    <path d="M190.16 105.42H189.96" stroke="black" stroke-width="0.5" />
    <path d="M189.96 105.96H190.16" stroke="black" stroke-width="0.5" />
    <path d="M190.16 117.52H189.96" stroke="black" stroke-width="0.5" />
    <path d="M189.96 118.06H190.16" stroke="black" stroke-width="0.5" />
    <path d="M200.86 104.29V104.09" stroke="black" stroke-width="0.5" />
    <path d="M200.33 104.09V104.29" stroke="black" stroke-width="0.5" />
    <path d="M169.48 129.09V129.63" stroke="black" stroke-width="0.5" />
    <path d="M191.29 99.11H190.76" stroke="black" stroke-width="0.5" />
    <path d="M190.16 97.98V97.44" stroke="black" stroke-width="0.5" />
    <path d="M190.16 85.88V85.34" stroke="black" stroke-width="0.5" />
    <path d="M200.86 99.11H200.33" stroke="black" stroke-width="0.5" />
    <path d="M190.73 97.66L190.74 97.65" stroke="black" stroke-width="0.5" />
    <path d="M190.74 97.65L190.75 97.63" stroke="black" stroke-width="0.5" />
    <path d="M190.75 97.63V97.62" stroke="black" stroke-width="0.5" />
    <path d="M190.75 97.62L190.76 97.6" stroke="black" stroke-width="0.5" />
    <path d="M190.76 97.6L190.77 97.58" stroke="black" stroke-width="0.5" />
    <path d="M190.77 97.58V97.57" stroke="black" stroke-width="0.5" />
    <path d="M190.77 97.57L190.78 97.55" stroke="black" stroke-width="0.5" />
    <path d="M190.78 97.55V97.53" stroke="black" stroke-width="0.5" />
    <path d="M190.78 97.53V97.52" stroke="black" stroke-width="0.5" />
    <path d="M190.78 97.52L190.79 97.5" stroke="black" stroke-width="0.5" />
    <path d="M190.79 97.5V97.48" stroke="black" stroke-width="0.5" />
    <path d="M190.79 97.48V97.47" stroke="black" stroke-width="0.5" />
    <path d="M190.79 97.47V97.45" stroke="black" stroke-width="0.5" />
    <path d="M190.79 97.45V97.44" stroke="black" stroke-width="0.5" />
    <path d="M190.57 97.82L190.59 97.81" stroke="black" stroke-width="0.5" />
    <path d="M190.59 97.81L190.6 97.8" stroke="black" stroke-width="0.5" />
    <path d="M190.6 97.8L190.61 97.79" stroke="black" stroke-width="0.5" />
    <path d="M190.61 97.79L190.63 97.78" stroke="black" stroke-width="0.5" />
    <path d="M190.63 97.78L190.64 97.77" stroke="black" stroke-width="0.5" />
    <path d="M190.64 97.77L190.65 97.76" stroke="black" stroke-width="0.5" />
    <path d="M190.65 97.76L190.67 97.75" stroke="black" stroke-width="0.5" />
    <path d="M190.67 97.75L190.68 97.73" stroke="black" stroke-width="0.5" />
    <path d="M190.68 97.73L190.69 97.72" stroke="black" stroke-width="0.5" />
    <path d="M190.69 97.72L190.7 97.71" stroke="black" stroke-width="0.5" />
    <path d="M190.7 97.71L190.71 97.69" stroke="black" stroke-width="0.5" />
    <path d="M190.71 97.69L190.72 97.68" stroke="black" stroke-width="0.5" />
    <path d="M190.72 97.68L190.73 97.66" stroke="black" stroke-width="0.5" />
    <path d="M190.36 97.88H190.37" stroke="black" stroke-width="0.5" />
    <path d="M190.37 97.88H190.39" stroke="black" stroke-width="0.5" />
    <path d="M190.39 97.88L190.41 97.87" stroke="black" stroke-width="0.5" />
    <path d="M190.41 97.87H190.43" stroke="black" stroke-width="0.5" />
    <path d="M190.43 97.87H190.44" stroke="black" stroke-width="0.5" />
    <path d="M190.44 97.87L190.46 97.86" stroke="black" stroke-width="0.5" />
    <path d="M190.46 97.86H190.48" stroke="black" stroke-width="0.5" />
    <path d="M190.48 97.86L190.49 97.85" stroke="black" stroke-width="0.5" />
    <path d="M190.49 97.85H190.51" stroke="black" stroke-width="0.5" />
    <path d="M190.51 97.85L190.52 97.84" stroke="black" stroke-width="0.5" />
    <path d="M190.52 97.84H190.54" stroke="black" stroke-width="0.5" />
    <path d="M190.54 97.84L190.56 97.83" stroke="black" stroke-width="0.5" />
    <path d="M190.56 97.83L190.57 97.82" stroke="black" stroke-width="0.5" />
    <path d="M190.43 97.51V97.52" stroke="black" stroke-width="0.5" />
    <path d="M190.43 97.52H190.42" stroke="black" stroke-width="0.5" />
    <path d="M190.42 97.52L190.41 97.53" stroke="black" stroke-width="0.5" />
    <path d="M190.41 97.53H190.4" stroke="black" stroke-width="0.5" />
    <path d="M190.4 97.53V97.54" stroke="black" stroke-width="0.5" />
    <path d="M190.4 97.54H190.39" stroke="black" stroke-width="0.5" />
    <path d="M190.39 97.54H190.38" stroke="black" stroke-width="0.5" />
    <path d="M190.38 97.54H190.37" stroke="black" stroke-width="0.5" />
    <path d="M190.37 97.54H190.36" stroke="black" stroke-width="0.5" />
    <path d="M190.46 97.44V97.45" stroke="black" stroke-width="0.5" />
    <path d="M190.46 97.45V97.46" stroke="black" stroke-width="0.5" />
    <path d="M190.46 97.46H190.45" stroke="black" stroke-width="0.5" />
    <path d="M190.45 97.46V97.47" stroke="black" stroke-width="0.5" />
    <path d="M190.45 97.47V97.48" stroke="black" stroke-width="0.5" />
    <path d="M190.45 97.48V97.49" stroke="black" stroke-width="0.5" />
    <path d="M190.45 97.49H190.44" stroke="black" stroke-width="0.5" />
    <path d="M190.44 97.49V97.5" stroke="black" stroke-width="0.5" />
    <path d="M190.44 97.5L190.43 97.51" stroke="black" stroke-width="0.5" />
    <path d="M190.57 85.5L190.56 85.49" stroke="black" stroke-width="0.5" />
    <path d="M190.56 85.49H190.54" stroke="black" stroke-width="0.5" />
    <path d="M190.54 85.49L190.53 85.48" stroke="black" stroke-width="0.5" />
    <path d="M190.53 85.48L190.51 85.47" stroke="black" stroke-width="0.5" />
    <path d="M190.51 85.47H190.5" stroke="black" stroke-width="0.5" />
    <path d="M190.5 85.47L190.48 85.46" stroke="black" stroke-width="0.5" />
    <path d="M190.48 85.46H190.46" stroke="black" stroke-width="0.5" />
    <path d="M190.46 85.46L190.45 85.45" stroke="black" stroke-width="0.5" />
    <path d="M190.45 85.45H190.43" stroke="black" stroke-width="0.5" />
    <path d="M190.43 85.45H190.41" stroke="black" stroke-width="0.5" />
    <path d="M190.41 85.45L190.4 85.44" stroke="black" stroke-width="0.5" />
    <path d="M190.4 85.44H190.38" stroke="black" stroke-width="0.5" />
    <path d="M190.38 85.44H190.36" stroke="black" stroke-width="0.5" />
    <path d="M190.73 85.66L190.72 85.65" stroke="black" stroke-width="0.5" />
    <path d="M190.72 85.65L190.71 85.63" stroke="black" stroke-width="0.5" />
    <path d="M190.71 85.63L190.7 85.62" stroke="black" stroke-width="0.5" />
    <path d="M190.7 85.62L190.69 85.6" stroke="black" stroke-width="0.5" />
    <path d="M190.69 85.6L190.68 85.59" stroke="black" stroke-width="0.5" />
    <path d="M190.68 85.59L190.67 85.58" stroke="black" stroke-width="0.5" />
    <path d="M190.67 85.58L190.66 85.57" stroke="black" stroke-width="0.5" />
    <path d="M190.66 85.57L190.65 85.55" stroke="black" stroke-width="0.5" />
    <path d="M190.65 85.55L190.63 85.54" stroke="black" stroke-width="0.5" />
    <path d="M190.63 85.54L190.62 85.53" stroke="black" stroke-width="0.5" />
    <path d="M190.62 85.53L190.6 85.52" stroke="black" stroke-width="0.5" />
    <path d="M190.6 85.52L190.59 85.51" stroke="black" stroke-width="0.5" />
    <path d="M190.59 85.51L190.58 85.5" stroke="black" stroke-width="0.5" />
    <path d="M190.58 85.5H190.57" stroke="black" stroke-width="0.5" />
    <path d="M190.79 85.88V85.86" stroke="black" stroke-width="0.5" />
    <path d="M190.79 85.86V85.84" stroke="black" stroke-width="0.5" />
    <path d="M190.79 85.84V85.82" stroke="black" stroke-width="0.5" />
    <path d="M190.79 85.82L190.78 85.81" stroke="black" stroke-width="0.5" />
    <path d="M190.78 85.81V85.79" stroke="black" stroke-width="0.5" />
    <path d="M190.78 85.79V85.77" stroke="black" stroke-width="0.5" />
    <path d="M190.78 85.77L190.77 85.76" stroke="black" stroke-width="0.5" />
    <path d="M190.77 85.76V85.74" stroke="black" stroke-width="0.5" />
    <path d="M190.77 85.74L190.76 85.72" stroke="black" stroke-width="0.5" />
    <path d="M190.76 85.72L190.75 85.71" stroke="black" stroke-width="0.5" />
    <path d="M190.75 85.71V85.69" stroke="black" stroke-width="0.5" />
    <path d="M190.75 85.69L190.74 85.68" stroke="black" stroke-width="0.5" />
    <path d="M190.74 85.68L190.73 85.66" stroke="black" stroke-width="0.5" />
    <path d="M190.43 85.8V85.81" stroke="black" stroke-width="0.5" />
    <path d="M190.43 85.81H190.44" stroke="black" stroke-width="0.5" />
    <path d="M190.44 85.81V85.82" stroke="black" stroke-width="0.5" />
    <path d="M190.44 85.82V85.83" stroke="black" stroke-width="0.5" />
    <path d="M190.44 85.83H190.45" stroke="black" stroke-width="0.5" />
    <path d="M190.45 85.83V85.84" stroke="black" stroke-width="0.5" />
    <path d="M190.45 85.84V85.85" stroke="black" stroke-width="0.5" />
    <path d="M190.45 85.85V85.86" stroke="black" stroke-width="0.5" />
    <path d="M190.45 85.86H190.46" stroke="black" stroke-width="0.5" />
    <path d="M190.46 85.86V85.87" stroke="black" stroke-width="0.5" />
    <path d="M190.46 85.87V85.88" stroke="black" stroke-width="0.5" />
    <path d="M190.36 85.78H190.37" stroke="black" stroke-width="0.5" />
    <path d="M190.37 85.78H190.38" stroke="black" stroke-width="0.5" />
    <path d="M190.38 85.78H190.39" stroke="black" stroke-width="0.5" />
    <path d="M190.39 85.78H190.4" stroke="black" stroke-width="0.5" />
    <path d="M190.4 85.78V85.79" stroke="black" stroke-width="0.5" />
    <path d="M190.4 85.79H190.41" stroke="black" stroke-width="0.5" />
    <path d="M190.41 85.79L190.42 85.8" stroke="black" stroke-width="0.5" />
    <path d="M190.42 85.8H190.43" stroke="black" stroke-width="0.5" />
    <path d="M190.91 98.69V98.7" stroke="black" stroke-width="0.5" />
    <path d="M190.91 98.7L190.9 98.72" stroke="black" stroke-width="0.5" />
    <path d="M190.9 98.72L190.89 98.73" stroke="black" stroke-width="0.5" />
    <path d="M190.89 98.73L190.88 98.75" stroke="black" stroke-width="0.5" />
    <path d="M190.88 98.75V98.77" stroke="black" stroke-width="0.5" />
    <path d="M190.88 98.77L190.87 98.78" stroke="black" stroke-width="0.5" />
    <path d="M190.87 98.78V98.8" stroke="black" stroke-width="0.5" />
    <path d="M190.87 98.8L190.86 98.82" stroke="black" stroke-width="0.5" />
    <path d="M190.86 98.82V98.83" stroke="black" stroke-width="0.5" />
    <path d="M190.86 98.83V98.85" stroke="black" stroke-width="0.5" />
    <path d="M190.86 98.85V98.87" stroke="black" stroke-width="0.5" />
    <path d="M190.86 98.87V98.89" stroke="black" stroke-width="0.5" />
    <path d="M190.86 98.89V98.9" stroke="black" stroke-width="0.5" />
    <path d="M190.86 98.9V98.91" stroke="black" stroke-width="0.5" />
    <path d="M191.07 98.53L191.06 98.54" stroke="black" stroke-width="0.5" />
    <path d="M191.06 98.54L191.04 98.55" stroke="black" stroke-width="0.5" />
    <path d="M191.04 98.55L191.03 98.56" stroke="black" stroke-width="0.5" />
    <path d="M191.03 98.56L191.02 98.57" stroke="black" stroke-width="0.5" />
    <path d="M191.02 98.57L191 98.58" stroke="black" stroke-width="0.5" />
    <path d="M191 98.58L190.99 98.59" stroke="black" stroke-width="0.5" />
    <path d="M190.99 98.59L190.98 98.61" stroke="black" stroke-width="0.5" />
    <path d="M190.98 98.61L190.97 98.62" stroke="black" stroke-width="0.5" />
    <path d="M190.97 98.62L190.95 98.63" stroke="black" stroke-width="0.5" />
    <path d="M190.95 98.63L190.94 98.65" stroke="black" stroke-width="0.5" />
    <path d="M190.94 98.65L190.93 98.66" stroke="black" stroke-width="0.5" />
    <path d="M190.93 98.66L190.92 98.67" stroke="black" stroke-width="0.5" />
    <path d="M190.92 98.67L190.91 98.69" stroke="black" stroke-width="0.5" />
    <path d="M191.29 98.48H191.27" stroke="black" stroke-width="0.5" />
    <path d="M191.27 98.48H191.25" stroke="black" stroke-width="0.5" />
    <path d="M191.25 98.48H191.24" stroke="black" stroke-width="0.5" />
    <path d="M191.24 98.48H191.22" stroke="black" stroke-width="0.5" />
    <path d="M191.22 98.48H191.2" stroke="black" stroke-width="0.5" />
    <path d="M191.2 98.48L191.18 98.49" stroke="black" stroke-width="0.5" />
    <path d="M191.18 98.49H191.17" stroke="black" stroke-width="0.5" />
    <path d="M191.17 98.49L191.15 98.5" stroke="black" stroke-width="0.5" />
    <path d="M191.15 98.5H191.14" stroke="black" stroke-width="0.5" />
    <path d="M191.14 98.5L191.12 98.51" stroke="black" stroke-width="0.5" />
    <path d="M191.12 98.51L191.1 98.52" stroke="black" stroke-width="0.5" />
    <path d="M191.1 98.52H191.09" stroke="black" stroke-width="0.5" />
    <path d="M191.09 98.52L191.07 98.53" stroke="black" stroke-width="0.5" />
    <path d="M191.22 98.84V98.83" stroke="black" stroke-width="0.5" />
    <path d="M191.22 98.83H191.23" stroke="black" stroke-width="0.5" />
    <path d="M191.23 98.83V98.82" stroke="black" stroke-width="0.5" />
    <path d="M191.23 98.82H191.24" stroke="black" stroke-width="0.5" />
    <path d="M191.24 98.82H191.25" stroke="black" stroke-width="0.5" />
    <path d="M191.25 98.82V98.81" stroke="black" stroke-width="0.5" />
    <path d="M191.25 98.81H191.26" stroke="black" stroke-width="0.5" />
    <path d="M191.26 98.81H191.27" stroke="black" stroke-width="0.5" />
    <path d="M191.27 98.81H191.28" stroke="black" stroke-width="0.5" />
    <path d="M191.28 98.81H191.29" stroke="black" stroke-width="0.5" />
    <path d="M191.19 98.91V98.9" stroke="black" stroke-width="0.5" />
    <path d="M191.19 98.9V98.89" stroke="black" stroke-width="0.5" />
    <path d="M191.19 98.89V98.88" stroke="black" stroke-width="0.5" />
    <path d="M191.19 98.88V98.87" stroke="black" stroke-width="0.5" />
    <path d="M191.19 98.87H191.2" stroke="black" stroke-width="0.5" />
    <path d="M191.2 98.87V98.86" stroke="black" stroke-width="0.5" />
    <path d="M191.2 98.86V98.85" stroke="black" stroke-width="0.5" />
    <path d="M191.2 98.85H191.21" stroke="black" stroke-width="0.5" />
    <path d="M191.21 98.85V98.84" stroke="black" stroke-width="0.5" />
    <path d="M191.21 98.84H191.22" stroke="black" stroke-width="0.5" />
    <path d="M200.55 98.53H200.53" stroke="black" stroke-width="0.5" />
    <path d="M200.53 98.53L200.52 98.52" stroke="black" stroke-width="0.5" />
    <path d="M200.52 98.52L200.5 98.51" stroke="black" stroke-width="0.5" />
    <path d="M200.5 98.51L200.49 98.5" stroke="black" stroke-width="0.5" />
    <path d="M200.49 98.5H200.47" stroke="black" stroke-width="0.5" />
    <path d="M200.47 98.5L200.45 98.49" stroke="black" stroke-width="0.5" />
    <path d="M200.45 98.49H200.44" stroke="black" stroke-width="0.5" />
    <path d="M200.44 98.49L200.42 98.48" stroke="black" stroke-width="0.5" />
    <path d="M200.42 98.48H200.4" stroke="black" stroke-width="0.5" />
    <path d="M200.4 98.48H200.39" stroke="black" stroke-width="0.5" />
    <path d="M200.39 98.48H200.37" stroke="black" stroke-width="0.5" />
    <path d="M200.37 98.48H200.35" stroke="black" stroke-width="0.5" />
    <path d="M200.35 98.48H200.33" stroke="black" stroke-width="0.5" />
    <path d="M200.7 98.69V98.68" stroke="black" stroke-width="0.5" />
    <path d="M200.7 98.68L200.69 98.66" stroke="black" stroke-width="0.5" />
    <path d="M200.69 98.66L200.68 98.65" stroke="black" stroke-width="0.5" />
    <path d="M200.68 98.65L200.67 98.64" stroke="black" stroke-width="0.5" />
    <path d="M200.67 98.64L200.66 98.62" stroke="black" stroke-width="0.5" />
    <path d="M200.66 98.62L200.64 98.61" stroke="black" stroke-width="0.5" />
    <path d="M200.64 98.61L200.63 98.6" stroke="black" stroke-width="0.5" />
    <path d="M200.63 98.6L200.62 98.59" stroke="black" stroke-width="0.5" />
    <path d="M200.62 98.59L200.61 98.57" stroke="black" stroke-width="0.5" />
    <path d="M200.61 98.57L200.59 98.56" stroke="black" stroke-width="0.5" />
    <path d="M200.59 98.56L200.58 98.55" stroke="black" stroke-width="0.5" />
    <path d="M200.58 98.55L200.56 98.54" stroke="black" stroke-width="0.5" />
    <path d="M200.56 98.54L200.55 98.53" stroke="black" stroke-width="0.5" />
    <path d="M200.76 98.91V98.89" stroke="black" stroke-width="0.5" />
    <path d="M200.76 98.89V98.87" stroke="black" stroke-width="0.5" />
    <path d="M200.76 98.87V98.86" stroke="black" stroke-width="0.5" />
    <path d="M200.76 98.86V98.84" stroke="black" stroke-width="0.5" />
    <path d="M200.76 98.84L200.75 98.82" stroke="black" stroke-width="0.5" />
    <path d="M200.75 98.82V98.8" stroke="black" stroke-width="0.5" />
    <path d="M200.75 98.8V98.79" stroke="black" stroke-width="0.5" />
    <path d="M200.75 98.79L200.74 98.77" stroke="black" stroke-width="0.5" />
    <path d="M200.74 98.77L200.73 98.75" stroke="black" stroke-width="0.5" />
    <path d="M200.73 98.75V98.74" stroke="black" stroke-width="0.5" />
    <path d="M200.73 98.74L200.72 98.72" stroke="black" stroke-width="0.5" />
    <path d="M200.72 98.72L200.71 98.71" stroke="black" stroke-width="0.5" />
    <path d="M200.71 98.71V98.69" stroke="black" stroke-width="0.5" />
    <path d="M200.71 98.69H200.7" stroke="black" stroke-width="0.5" />
    <path d="M200.4 98.84H200.41" stroke="black" stroke-width="0.5" />
    <path d="M200.41 98.84V98.85" stroke="black" stroke-width="0.5" />
    <path d="M200.41 98.85L200.42 98.86" stroke="black" stroke-width="0.5" />
    <path d="M200.42 98.86V98.87" stroke="black" stroke-width="0.5" />
    <path d="M200.42 98.87L200.43 98.88" stroke="black" stroke-width="0.5" />
    <path d="M200.43 98.88V98.89" stroke="black" stroke-width="0.5" />
    <path d="M200.43 98.89V98.9" stroke="black" stroke-width="0.5" />
    <path d="M200.43 98.9V98.91" stroke="black" stroke-width="0.5" />
    <path d="M200.33 98.81H200.34" stroke="black" stroke-width="0.5" />
    <path d="M200.34 98.81H200.35" stroke="black" stroke-width="0.5" />
    <path d="M200.35 98.81H200.36" stroke="black" stroke-width="0.5" />
    <path d="M200.36 98.81H200.37" stroke="black" stroke-width="0.5" />
    <path d="M200.37 98.81V98.82" stroke="black" stroke-width="0.5" />
    <path d="M200.37 98.82H200.38" stroke="black" stroke-width="0.5" />
    <path d="M200.38 98.82H200.39" stroke="black" stroke-width="0.5" />
    <path d="M200.39 98.82V98.83" stroke="black" stroke-width="0.5" />
    <path d="M200.39 98.83H200.4" stroke="black" stroke-width="0.5" />
    <path d="M200.4 98.83V98.84" stroke="black" stroke-width="0.5" />
    <path d="M190.36 97.88H190.16" stroke="black" stroke-width="0.5" />
    <path d="M190.16 97.54H190.36" stroke="black" stroke-width="0.5" />
    <path d="M190.79 85.88V97.44" stroke="black" stroke-width="0.5" />
    <path d="M190.46 97.44V85.88" stroke="black" stroke-width="0.5" />
    <path d="M190.16 85.44H190.36" stroke="black" stroke-width="0.5" />
    <path d="M190.36 85.78H190.16" stroke="black" stroke-width="0.5" />
    <path d="M191.29 99.11V99.31" stroke="black" stroke-width="0.5" />
    <path d="M190.76 99.31V99.11" stroke="black" stroke-width="0.5" />
    <path d="M191.19 98.91V99.11" stroke="black" stroke-width="0.5" />
    <path d="M190.86 99.11V98.91" stroke="black" stroke-width="0.5" />
    <path d="M200.33 98.81H191.29" stroke="black" stroke-width="0.5" />
    <path d="M191.29 98.48H200.33" stroke="black" stroke-width="0.5" />
    <path d="M200.43 99.11V98.91" stroke="black" stroke-width="0.5" />
    <path d="M200.76 98.91V99.11" stroke="black" stroke-width="0.5" />
    <path d="M190.16 97.98H189.96" stroke="black" stroke-width="0.5" />
    <path d="M189.96 97.44H190.16" stroke="black" stroke-width="0.5" />
    <path d="M190.16 85.88H189.96" stroke="black" stroke-width="0.5" />
    <path d="M189.96 85.34H190.16" stroke="black" stroke-width="0.5" />
    <path d="M200.86 99.11V99.31" stroke="black" stroke-width="0.5" />
    <path d="M200.33 99.31V99.11" stroke="black" stroke-width="0.5" />
    <path
      d="M194.21 98.32C194.215 98.3751 194.228 98.4291 194.25 98.48"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.62 98.81C194.705 98.8315 194.795 98.8315 194.88 98.81"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M195.24 98.48C195.265 98.4196 195.278 98.3552 195.28 98.29"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.93 98.48C194.962 98.4495 194.986 98.4116 194.999 98.3696C195.013 98.3277 195.016 98.2831 195.008 98.2397C195 98.1964 194.981 98.1557 194.953 98.1213C194.926 98.087 194.89 98.06 194.849 98.0429C194.809 98.0257 194.765 98.0189 194.721 98.0231C194.677 98.0273 194.635 98.0423 194.598 98.0668C194.561 98.0913 194.531 98.1245 194.511 98.1635C194.49 98.2024 194.48 98.2459 194.48 98.29"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.48 98.29C194.48 98.3254 194.487 98.3604 194.501 98.3931C194.515 98.4257 194.535 98.4552 194.56 98.48"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.58 95.75C196.686 95.4101 196.74 95.0561 196.74 94.7"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.74 94.7C196.74 94.3439 196.686 93.9899 196.58 93.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.58 93.65C196.488 93.3514 196.346 93.0707 196.16 92.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.16 92.82C195.978 92.5774 195.746 92.3763 195.48 92.23"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M195.48 92.23C195.254 92.1044 194.999 92.039 194.74 92.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.74 92.04C194.485 92.0407 194.233 92.1061 194.01 92.23"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.01 92.23C193.744 92.3763 193.512 92.5774 193.33 92.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M193.33 92.82C193.144 93.0707 193.002 93.3514 192.91 93.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.91 93.65C192.804 93.9899 192.75 94.3439 192.75 94.7"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.75 94.7C192.75 95.0561 192.804 95.4101 192.91 95.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.91 95.75C193.024 96.119 193.215 96.4598 193.47 96.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.21 98.29C194.195 97.997 194.122 97.7098 193.995 97.4454C193.868 97.1809 193.69 96.9445 193.47 96.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.21 98.29C194.212 98.3552 194.225 98.4197 194.25 98.48"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M194.61 98.81L194.7 98.82" stroke="black" stroke-width="0.5" />
    <path d="M194.7 98.82H194.79" stroke="black" stroke-width="0.5" />
    <path d="M194.79 98.82L194.88 98.81" stroke="black" stroke-width="0.5" />
    <path
      d="M195.24 98.48C195.265 98.4196 195.278 98.3552 195.28 98.29"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.02 96.75C195.8 96.9445 195.622 97.1809 195.495 97.4454C195.368 97.7098 195.295 97.997 195.28 98.29"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.02 96.75C196.275 96.4598 196.466 96.119 196.58 95.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.21 98.32C194.183 97.8265 194.001 97.354 193.69 96.97"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M195.92 96.86C195.541 97.2438 195.314 97.7517 195.28 98.29"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.91 95.75C193.056 96.2192 193.325 96.6405 193.69 96.97"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.75 94.7C192.75 95.0561 192.804 95.4101 192.91 95.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M192.91 93.65C192.804 93.9899 192.75 94.3439 192.75 94.7"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M193.33 92.82C193.144 93.0707 193.002 93.3514 192.91 93.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.01 92.23C193.744 92.3763 193.512 92.5774 193.33 92.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M194.74 92.04C194.485 92.0407 194.233 92.1061 194.01 92.23"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M195.48 92.23C195.254 92.1044 194.999 92.039 194.74 92.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.16 92.82C195.978 92.5774 195.746 92.3763 195.48 92.23"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.58 93.65C196.488 93.3514 196.346 93.0707 196.16 92.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.74 94.7C196.74 94.3439 196.686 93.9899 196.58 93.65"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M196.58 95.75C196.686 95.4101 196.74 95.0561 196.74 94.7"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M195.92 96.86C196.225 96.5478 196.451 96.1673 196.58 95.75"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M187.3 129.09V129.63" stroke="black" stroke-width="0.5" />
    <path d="M98.2 88.45H114.25" stroke="black" stroke-width="0.5" />
    <path d="M114.25 88.45V94.78" stroke="black" stroke-width="0.5" />
    <path d="M114.25 94.78H98.2" stroke="black" stroke-width="0.5" />
    <path d="M98.2 94.78V88.45" stroke="black" stroke-width="0.5" />
    <path d="M69.7 73.24V72.71" stroke="black" stroke-width="0.5" />
    <path d="M114.25 91.62H112.71" stroke="black" stroke-width="0.5" />
    <path d="M111.71 91.62H109.71" stroke="black" stroke-width="0.5" />
    <path d="M108.72 91.62H106.72" stroke="black" stroke-width="0.5" />
    <path d="M105.72 91.62H103.73" stroke="black" stroke-width="0.5" />
    <path d="M102.73 91.62H100.74" stroke="black" stroke-width="0.5" />
    <path d="M99.74 91.62H98.2" stroke="black" stroke-width="0.5" />
    <path d="M57.24 72.71H55.38" stroke="black" stroke-width="0.5" />
    <path d="M141.09 12.95V8.79999" stroke="black" stroke-width="0.5" />
    <path d="M69.7 73.24V76.68" stroke="black" stroke-width="0.5" />
    <path d="M69.7 76.68H58.8" stroke="black" stroke-width="0.5" />
    <path d="M58.8 76.68V83.08" stroke="black" stroke-width="0.5" />
    <path d="M57.24 83.08H58.8" stroke="black" stroke-width="0.5" />
    <path d="M39.56 83.08V83.61" stroke="black" stroke-width="0.5" />
    <path d="M54.17 90.75H57.24" stroke="black" stroke-width="0.5" />
    <path d="M54.17 84.41V110.48" stroke="black" stroke-width="0.5" />
    <path d="M57.24 110.48H54.17" stroke="black" stroke-width="0.5" />
    <path d="M7.75 126.4L7.73999 126.32" stroke="black" stroke-width="0.5" />
    <path d="M7.73999 126.32V126.24" stroke="black" stroke-width="0.5" />
    <path
      d="M7.73999 126.24L7.73001 126.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M7.73001 126.16L7.70999 126.09"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M7.70999 126.09L7.70001 126.01"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M7.70001 126.01L7.67001 125.93"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M7.67001 125.93L7.64999 125.86"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M7.64999 125.86L7.62 125.78" stroke="black" stroke-width="0.5" />
    <path d="M7.62 125.78L7.60999 125.75" stroke="black" stroke-width="0.5" />
    <path d="M7.44 125.46L7.42001 125.44" stroke="black" stroke-width="0.5" />
    <path d="M7.42001 125.44L7.37 125.38" stroke="black" stroke-width="0.5" />
    <path d="M7.37 125.38L7.32001 125.32" stroke="black" stroke-width="0.5" />
    <path
      d="M7.32001 125.32L7.26001 125.26"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M7.26001 125.26L7.20999 125.21"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M7.20999 125.21L7.14001 125.15"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M7.14001 125.15L7.07999 125.11"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M7.07999 125.11L7.01999 125.06"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M7.01999 125.06L6.95001 125.02"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M6.64001 124.88L6.57999 124.87"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M6.57999 124.87L6.5 124.85" stroke="black" stroke-width="0.5" />
    <path d="M6.5 124.85L6.42999 124.83" stroke="black" stroke-width="0.5" />
    <path
      d="M6.42999 124.83L6.35001 124.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M6.35001 124.82L6.26999 124.81"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M6.26999 124.81H6.19" stroke="black" stroke-width="0.5" />
    <path d="M6.19 124.81H6.10999" stroke="black" stroke-width="0.5" />
    <path d="M6.10999 124.81H6.03" stroke="black" stroke-width="0.5" />
    <path d="M6.03 124.81L5.98001 124.82" stroke="black" stroke-width="0.5" />
    <path d="M5.66 124.88L5.64001 124.89" stroke="black" stroke-width="0.5" />
    <path
      d="M5.64001 124.89L5.57001 124.92"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M5.57001 124.92L5.48999 124.95"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M5.48999 124.95L5.42001 124.98"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M5.42001 124.98L5.35001 125.02"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M5.35001 125.02L5.28 125.06" stroke="black" stroke-width="0.5" />
    <path d="M5.28 125.06L5.22 125.11" stroke="black" stroke-width="0.5" />
    <path d="M5.22 125.11L5.14999 125.15" stroke="black" stroke-width="0.5" />
    <path d="M5.14999 125.15L5.09 125.21" stroke="black" stroke-width="0.5" />
    <path d="M5.09 125.21L5.07999 125.22" stroke="black" stroke-width="0.5" />
    <path d="M4.85999 125.46L4.82999 125.5" stroke="black" stroke-width="0.5" />
    <path d="M4.82999 125.5L4.79001 125.57" stroke="black" stroke-width="0.5" />
    <path d="M4.79001 125.57L4.75 125.64" stroke="black" stroke-width="0.5" />
    <path d="M4.75 125.64L4.70999 125.71" stroke="black" stroke-width="0.5" />
    <path
      d="M4.70999 125.71L4.67999 125.78"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M4.67999 125.78L4.64999 125.86"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M4.64999 125.86L4.62 125.93" stroke="black" stroke-width="0.5" />
    <path d="M4.62 125.93L4.60001 126.01" stroke="black" stroke-width="0.5" />
    <path d="M4.60001 126.01L4.59 126.07" stroke="black" stroke-width="0.5" />
    <path d="M4.54999 126.4L4.56 126.48" stroke="black" stroke-width="0.5" />
    <path d="M4.56 126.48V126.56" stroke="black" stroke-width="0.5" />
    <path d="M4.56 126.56L4.57001 126.64" stroke="black" stroke-width="0.5" />
    <path d="M4.57001 126.64L4.59 126.72" stroke="black" stroke-width="0.5" />
    <path d="M4.59 126.72L4.60001 126.8" stroke="black" stroke-width="0.5" />
    <path d="M4.60001 126.8L4.62 126.87" stroke="black" stroke-width="0.5" />
    <path d="M4.62 126.87L4.64999 126.95" stroke="black" stroke-width="0.5" />
    <path
      d="M4.64999 126.95L4.67999 127.02"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M4.67999 127.02L4.69 127.05" stroke="black" stroke-width="0.5" />
    <path d="M4.85999 127.34L4.88 127.37" stroke="black" stroke-width="0.5" />
    <path d="M4.88 127.37L4.92999 127.43" stroke="black" stroke-width="0.5" />
    <path
      d="M4.92999 127.43L4.98001 127.49"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M4.98001 127.49L5.04001 127.54"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M5.04001 127.54L5.09 127.6" stroke="black" stroke-width="0.5" />
    <path d="M5.09 127.6L5.14999 127.65" stroke="black" stroke-width="0.5" />
    <path d="M5.14999 127.65L5.22 127.7" stroke="black" stroke-width="0.5" />
    <path d="M5.22 127.7L5.28 127.74" stroke="black" stroke-width="0.5" />
    <path d="M5.28 127.74L5.35001 127.78" stroke="black" stroke-width="0.5" />
    <path d="M5.66 127.92L5.72 127.94" stroke="black" stroke-width="0.5" />
    <path d="M5.72 127.94L5.79001 127.96" stroke="black" stroke-width="0.5" />
    <path d="M5.79001 127.96L5.87 127.97" stroke="black" stroke-width="0.5" />
    <path d="M5.87 127.97L5.95001 127.99" stroke="black" stroke-width="0.5" />
    <path d="M5.95001 127.99H6.03" stroke="black" stroke-width="0.5" />
    <path d="M6.03 127.99L6.10999 128" stroke="black" stroke-width="0.5" />
    <path d="M6.10999 128H6.19" stroke="black" stroke-width="0.5" />
    <path d="M6.19 128L6.26999 127.99" stroke="black" stroke-width="0.5" />
    <path d="M6.26999 127.99H6.32001" stroke="black" stroke-width="0.5" />
    <path d="M6.64001 127.92L6.66 127.91" stroke="black" stroke-width="0.5" />
    <path d="M6.66 127.91L6.73001 127.89" stroke="black" stroke-width="0.5" />
    <path d="M6.73001 127.89L6.81 127.86" stroke="black" stroke-width="0.5" />
    <path d="M6.81 127.86L6.88 127.82" stroke="black" stroke-width="0.5" />
    <path d="M6.88 127.82L6.95001 127.78" stroke="black" stroke-width="0.5" />
    <path
      d="M6.95001 127.78L7.01999 127.74"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M7.01999 127.74L7.07999 127.7" stroke="black" stroke-width="0.5" />
    <path d="M7.07999 127.7L7.14001 127.65" stroke="black" stroke-width="0.5" />
    <path d="M7.14001 127.65L7.20999 127.6" stroke="black" stroke-width="0.5" />
    <path d="M7.20999 127.6L7.22 127.59" stroke="black" stroke-width="0.5" />
    <path d="M7.44 127.34L7.47 127.3" stroke="black" stroke-width="0.5" />
    <path d="M7.47 127.3L7.51001 127.23" stroke="black" stroke-width="0.5" />
    <path
      d="M7.51001 127.23L7.54999 127.16"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M7.54999 127.16L7.59 127.09" stroke="black" stroke-width="0.5" />
    <path d="M7.59 127.09L7.62 127.02" stroke="black" stroke-width="0.5" />
    <path d="M7.62 127.02L7.64999 126.95" stroke="black" stroke-width="0.5" />
    <path
      d="M7.64999 126.95L7.67001 126.87"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M7.67001 126.87L7.70001 126.8" stroke="black" stroke-width="0.5" />
    <path d="M7.70001 126.8L7.70999 126.73" stroke="black" stroke-width="0.5" />
    <path d="M22.97 126.4V126.32" stroke="black" stroke-width="0.5" />
    <path d="M22.97 126.32V126.24" stroke="black" stroke-width="0.5" />
    <path d="M22.97 126.24L22.96 126.16" stroke="black" stroke-width="0.5" />
    <path d="M22.96 126.16L22.94 126.09" stroke="black" stroke-width="0.5" />
    <path d="M22.94 126.09L22.93 126.01" stroke="black" stroke-width="0.5" />
    <path d="M22.93 126.01L22.9 125.93" stroke="black" stroke-width="0.5" />
    <path d="M22.9 125.93L22.88 125.86" stroke="black" stroke-width="0.5" />
    <path d="M22.88 125.86L22.85 125.78" stroke="black" stroke-width="0.5" />
    <path d="M22.85 125.78L22.84 125.75" stroke="black" stroke-width="0.5" />
    <path d="M22.67 125.46L22.65 125.44" stroke="black" stroke-width="0.5" />
    <path d="M22.65 125.44L22.6 125.38" stroke="black" stroke-width="0.5" />
    <path d="M22.6 125.38L22.55 125.32" stroke="black" stroke-width="0.5" />
    <path d="M22.55 125.32L22.49 125.26" stroke="black" stroke-width="0.5" />
    <path d="M22.49 125.26L22.44 125.21" stroke="black" stroke-width="0.5" />
    <path d="M22.44 125.21L22.37 125.15" stroke="black" stroke-width="0.5" />
    <path d="M22.37 125.15L22.31 125.11" stroke="black" stroke-width="0.5" />
    <path d="M22.31 125.11L22.24 125.06" stroke="black" stroke-width="0.5" />
    <path d="M22.24 125.06L22.18 125.02" stroke="black" stroke-width="0.5" />
    <path d="M21.87 124.88L21.81 124.87" stroke="black" stroke-width="0.5" />
    <path d="M21.81 124.87L21.73 124.85" stroke="black" stroke-width="0.5" />
    <path d="M21.73 124.85L21.66 124.83" stroke="black" stroke-width="0.5" />
    <path d="M21.66 124.83L21.58 124.82" stroke="black" stroke-width="0.5" />
    <path d="M21.58 124.82L21.5 124.81" stroke="black" stroke-width="0.5" />
    <path d="M21.5 124.81H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 124.81H21.34" stroke="black" stroke-width="0.5" />
    <path d="M21.34 124.81H21.26" stroke="black" stroke-width="0.5" />
    <path d="M21.26 124.81L21.21 124.82" stroke="black" stroke-width="0.5" />
    <path d="M20.89 124.88L20.87 124.89" stroke="black" stroke-width="0.5" />
    <path d="M20.87 124.89L20.8 124.92" stroke="black" stroke-width="0.5" />
    <path d="M20.8 124.92L20.72 124.95" stroke="black" stroke-width="0.5" />
    <path d="M20.72 124.95L20.65 124.98" stroke="black" stroke-width="0.5" />
    <path d="M20.65 124.98L20.58 125.02" stroke="black" stroke-width="0.5" />
    <path d="M20.58 125.02L20.51 125.06" stroke="black" stroke-width="0.5" />
    <path d="M20.51 125.06L20.45 125.11" stroke="black" stroke-width="0.5" />
    <path d="M20.45 125.11L20.38 125.15" stroke="black" stroke-width="0.5" />
    <path d="M20.38 125.15L20.32 125.21" stroke="black" stroke-width="0.5" />
    <path d="M20.32 125.21L20.31 125.22" stroke="black" stroke-width="0.5" />
    <path d="M20.09 125.46L20.06 125.5" stroke="black" stroke-width="0.5" />
    <path d="M20.06 125.5L20.02 125.57" stroke="black" stroke-width="0.5" />
    <path d="M20.02 125.57L19.98 125.64" stroke="black" stroke-width="0.5" />
    <path d="M19.98 125.64L19.94 125.71" stroke="black" stroke-width="0.5" />
    <path d="M19.94 125.71L19.91 125.78" stroke="black" stroke-width="0.5" />
    <path d="M19.91 125.78L19.88 125.86" stroke="black" stroke-width="0.5" />
    <path d="M19.88 125.86L19.85 125.93" stroke="black" stroke-width="0.5" />
    <path d="M19.85 125.93L19.83 126.01" stroke="black" stroke-width="0.5" />
    <path d="M19.83 126.01L19.82 126.07" stroke="black" stroke-width="0.5" />
    <path d="M19.78 126.4L19.79 126.48" stroke="black" stroke-width="0.5" />
    <path d="M19.79 126.48V126.56" stroke="black" stroke-width="0.5" />
    <path d="M19.79 126.56L19.8 126.64" stroke="black" stroke-width="0.5" />
    <path d="M19.8 126.64L19.82 126.72" stroke="black" stroke-width="0.5" />
    <path d="M19.82 126.72L19.83 126.8" stroke="black" stroke-width="0.5" />
    <path d="M19.83 126.8L19.85 126.87" stroke="black" stroke-width="0.5" />
    <path d="M19.85 126.87L19.88 126.95" stroke="black" stroke-width="0.5" />
    <path d="M19.88 126.95L19.91 127.02" stroke="black" stroke-width="0.5" />
    <path d="M19.91 127.02L19.92 127.05" stroke="black" stroke-width="0.5" />
    <path d="M20.09 127.34L20.11 127.37" stroke="black" stroke-width="0.5" />
    <path d="M20.11 127.37L20.16 127.43" stroke="black" stroke-width="0.5" />
    <path d="M20.16 127.43L20.21 127.49" stroke="black" stroke-width="0.5" />
    <path d="M20.21 127.49L20.26 127.54" stroke="black" stroke-width="0.5" />
    <path d="M20.26 127.54L20.32 127.6" stroke="black" stroke-width="0.5" />
    <path d="M20.32 127.6L20.38 127.65" stroke="black" stroke-width="0.5" />
    <path d="M20.38 127.65L20.45 127.7" stroke="black" stroke-width="0.5" />
    <path d="M20.45 127.7L20.51 127.74" stroke="black" stroke-width="0.5" />
    <path d="M20.51 127.74L20.58 127.78" stroke="black" stroke-width="0.5" />
    <path d="M20.89 127.92L20.95 127.94" stroke="black" stroke-width="0.5" />
    <path d="M20.95 127.94L21.02 127.96" stroke="black" stroke-width="0.5" />
    <path d="M21.02 127.96L21.1 127.97" stroke="black" stroke-width="0.5" />
    <path d="M21.1 127.97L21.18 127.99" stroke="black" stroke-width="0.5" />
    <path d="M21.18 127.99H21.26" stroke="black" stroke-width="0.5" />
    <path d="M21.26 127.99L21.34 128" stroke="black" stroke-width="0.5" />
    <path d="M21.34 128H21.42" stroke="black" stroke-width="0.5" />
    <path d="M21.42 128L21.5 127.99" stroke="black" stroke-width="0.5" />
    <path d="M21.5 127.99H21.55" stroke="black" stroke-width="0.5" />
    <path d="M21.87 127.92L21.89 127.91" stroke="black" stroke-width="0.5" />
    <path d="M21.89 127.91L21.96 127.89" stroke="black" stroke-width="0.5" />
    <path d="M21.96 127.89L22.04 127.86" stroke="black" stroke-width="0.5" />
    <path d="M22.04 127.86L22.11 127.82" stroke="black" stroke-width="0.5" />
    <path d="M22.11 127.82L22.18 127.78" stroke="black" stroke-width="0.5" />
    <path d="M22.18 127.78L22.24 127.74" stroke="black" stroke-width="0.5" />
    <path d="M22.24 127.74L22.31 127.7" stroke="black" stroke-width="0.5" />
    <path d="M22.31 127.7L22.37 127.65" stroke="black" stroke-width="0.5" />
    <path d="M22.37 127.65L22.44 127.6" stroke="black" stroke-width="0.5" />
    <path d="M22.44 127.6L22.45 127.59" stroke="black" stroke-width="0.5" />
    <path d="M22.67 127.34L22.7 127.3" stroke="black" stroke-width="0.5" />
    <path d="M22.7 127.3L22.74 127.23" stroke="black" stroke-width="0.5" />
    <path d="M22.74 127.23L22.78 127.16" stroke="black" stroke-width="0.5" />
    <path d="M22.78 127.16L22.82 127.09" stroke="black" stroke-width="0.5" />
    <path d="M22.82 127.09L22.85 127.02" stroke="black" stroke-width="0.5" />
    <path d="M22.85 127.02L22.88 126.95" stroke="black" stroke-width="0.5" />
    <path d="M22.88 126.95L22.9 126.87" stroke="black" stroke-width="0.5" />
    <path d="M22.9 126.87L22.93 126.8" stroke="black" stroke-width="0.5" />
    <path d="M22.93 126.8L22.94 126.73" stroke="black" stroke-width="0.5" />
    <path d="M7.75 86.99L7.73999 86.91" stroke="black" stroke-width="0.5" />
    <path d="M7.73999 86.91V86.83" stroke="black" stroke-width="0.5" />
    <path d="M7.73999 86.83L7.73001 86.75" stroke="black" stroke-width="0.5" />
    <path d="M7.73001 86.75L7.70999 86.67" stroke="black" stroke-width="0.5" />
    <path d="M7.70999 86.67L7.70001 86.59" stroke="black" stroke-width="0.5" />
    <path d="M7.70001 86.59L7.67001 86.52" stroke="black" stroke-width="0.5" />
    <path d="M7.67001 86.52L7.64999 86.44" stroke="black" stroke-width="0.5" />
    <path d="M7.64999 86.44L7.62 86.37" stroke="black" stroke-width="0.5" />
    <path d="M7.62 86.37L7.60999 86.34" stroke="black" stroke-width="0.5" />
    <path d="M7.44 86.05L7.42001 86.02" stroke="black" stroke-width="0.5" />
    <path d="M7.42001 86.02L7.37 85.96" stroke="black" stroke-width="0.5" />
    <path d="M7.37 85.96L7.32001 85.9" stroke="black" stroke-width="0.5" />
    <path d="M7.32001 85.9L7.26001 85.84" stroke="black" stroke-width="0.5" />
    <path d="M7.26001 85.84L7.20999 85.79" stroke="black" stroke-width="0.5" />
    <path d="M7.20999 85.79L7.14001 85.74" stroke="black" stroke-width="0.5" />
    <path d="M7.14001 85.74L7.07999 85.69" stroke="black" stroke-width="0.5" />
    <path d="M7.07999 85.69L7.01999 85.65" stroke="black" stroke-width="0.5" />
    <path d="M7.01999 85.65L6.95001 85.6" stroke="black" stroke-width="0.5" />
    <path d="M6.64001 85.47L6.57999 85.45" stroke="black" stroke-width="0.5" />
    <path d="M6.57999 85.45L6.5 85.43" stroke="black" stroke-width="0.5" />
    <path d="M6.5 85.43L6.42999 85.42" stroke="black" stroke-width="0.5" />
    <path d="M6.42999 85.42L6.35001 85.4" stroke="black" stroke-width="0.5" />
    <path d="M6.35001 85.4H6.26999" stroke="black" stroke-width="0.5" />
    <path d="M6.26999 85.4L6.19 85.39" stroke="black" stroke-width="0.5" />
    <path d="M6.19 85.39H6.10999" stroke="black" stroke-width="0.5" />
    <path d="M6.10999 85.39L6.03 85.4" stroke="black" stroke-width="0.5" />
    <path d="M6.03 85.4H5.98001" stroke="black" stroke-width="0.5" />
    <path d="M5.66 85.47H5.64001" stroke="black" stroke-width="0.5" />
    <path d="M5.64001 85.47L5.57001 85.5" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 85.5L5.48999 85.53" stroke="black" stroke-width="0.5" />
    <path d="M5.48999 85.53L5.42001 85.57" stroke="black" stroke-width="0.5" />
    <path d="M5.42001 85.57L5.35001 85.6" stroke="black" stroke-width="0.5" />
    <path d="M5.35001 85.6L5.28 85.65" stroke="black" stroke-width="0.5" />
    <path d="M5.28 85.65L5.22 85.69" stroke="black" stroke-width="0.5" />
    <path d="M5.22 85.69L5.14999 85.74" stroke="black" stroke-width="0.5" />
    <path d="M5.14999 85.74L5.09 85.79" stroke="black" stroke-width="0.5" />
    <path d="M5.09 85.79L5.07999 85.8" stroke="black" stroke-width="0.5" />
    <path d="M4.85999 86.05L4.82999 86.09" stroke="black" stroke-width="0.5" />
    <path d="M4.82999 86.09L4.79001 86.15" stroke="black" stroke-width="0.5" />
    <path d="M4.79001 86.15L4.75 86.22" stroke="black" stroke-width="0.5" />
    <path d="M4.75 86.22L4.70999 86.29" stroke="black" stroke-width="0.5" />
    <path d="M4.70999 86.29L4.67999 86.37" stroke="black" stroke-width="0.5" />
    <path d="M4.67999 86.37L4.64999 86.44" stroke="black" stroke-width="0.5" />
    <path d="M4.64999 86.44L4.62 86.52" stroke="black" stroke-width="0.5" />
    <path d="M4.62 86.52L4.60001 86.59" stroke="black" stroke-width="0.5" />
    <path d="M4.60001 86.59L4.59 86.66" stroke="black" stroke-width="0.5" />
    <path d="M4.54999 86.99L4.56 87.07" stroke="black" stroke-width="0.5" />
    <path d="M4.56 87.07V87.15" stroke="black" stroke-width="0.5" />
    <path d="M4.56 87.15L4.57001 87.22" stroke="black" stroke-width="0.5" />
    <path d="M4.57001 87.22L4.59 87.3" stroke="black" stroke-width="0.5" />
    <path d="M4.59 87.3L4.60001 87.38" stroke="black" stroke-width="0.5" />
    <path d="M4.60001 87.38L4.62 87.46" stroke="black" stroke-width="0.5" />
    <path d="M4.62 87.46L4.64999 87.53" stroke="black" stroke-width="0.5" />
    <path d="M4.64999 87.53L4.67999 87.61" stroke="black" stroke-width="0.5" />
    <path d="M4.67999 87.61L4.69 87.64" stroke="black" stroke-width="0.5" />
    <path d="M4.85999 87.92L4.88 87.95" stroke="black" stroke-width="0.5" />
    <path d="M4.88 87.95L4.92999 88.01" stroke="black" stroke-width="0.5" />
    <path d="M4.92999 88.01L4.98001 88.07" stroke="black" stroke-width="0.5" />
    <path d="M4.98001 88.07L5.04001 88.13" stroke="black" stroke-width="0.5" />
    <path d="M5.04001 88.13L5.09 88.18" stroke="black" stroke-width="0.5" />
    <path d="M5.09 88.18L5.14999 88.23" stroke="black" stroke-width="0.5" />
    <path d="M5.14999 88.23L5.22 88.28" stroke="black" stroke-width="0.5" />
    <path d="M5.22 88.28L5.28 88.33" stroke="black" stroke-width="0.5" />
    <path d="M5.28 88.33L5.35001 88.37" stroke="black" stroke-width="0.5" />
    <path d="M5.66 88.5L5.72 88.52" stroke="black" stroke-width="0.5" />
    <path d="M5.72 88.52L5.79001 88.54" stroke="black" stroke-width="0.5" />
    <path d="M5.79001 88.54L5.87 88.56" stroke="black" stroke-width="0.5" />
    <path d="M5.87 88.56L5.95001 88.57" stroke="black" stroke-width="0.5" />
    <path d="M5.95001 88.57L6.03 88.58" stroke="black" stroke-width="0.5" />
    <path d="M6.03 88.58H6.10999" stroke="black" stroke-width="0.5" />
    <path d="M6.10999 88.58H6.19" stroke="black" stroke-width="0.5" />
    <path d="M6.19 88.58H6.26999" stroke="black" stroke-width="0.5" />
    <path d="M6.26999 88.58L6.32001 88.57" stroke="black" stroke-width="0.5" />
    <path d="M6.64001 88.5H6.66" stroke="black" stroke-width="0.5" />
    <path d="M6.66 88.5L6.73001 88.47" stroke="black" stroke-width="0.5" />
    <path d="M6.73001 88.47L6.81 88.44" stroke="black" stroke-width="0.5" />
    <path d="M6.81 88.44L6.88 88.41" stroke="black" stroke-width="0.5" />
    <path d="M6.88 88.41L6.95001 88.37" stroke="black" stroke-width="0.5" />
    <path d="M6.95001 88.37L7.01999 88.33" stroke="black" stroke-width="0.5" />
    <path d="M7.01999 88.33L7.07999 88.28" stroke="black" stroke-width="0.5" />
    <path d="M7.07999 88.28L7.14001 88.23" stroke="black" stroke-width="0.5" />
    <path d="M7.14001 88.23L7.20999 88.18" stroke="black" stroke-width="0.5" />
    <path d="M7.20999 88.18L7.22 88.17" stroke="black" stroke-width="0.5" />
    <path d="M7.44 87.92L7.47 87.89" stroke="black" stroke-width="0.5" />
    <path d="M7.47 87.89L7.51001 87.82" stroke="black" stroke-width="0.5" />
    <path d="M7.51001 87.82L7.54999 87.75" stroke="black" stroke-width="0.5" />
    <path d="M7.54999 87.75L7.59 87.68" stroke="black" stroke-width="0.5" />
    <path d="M7.59 87.68L7.62 87.61" stroke="black" stroke-width="0.5" />
    <path d="M7.62 87.61L7.64999 87.53" stroke="black" stroke-width="0.5" />
    <path d="M7.64999 87.53L7.67001 87.46" stroke="black" stroke-width="0.5" />
    <path d="M7.67001 87.46L7.70001 87.38" stroke="black" stroke-width="0.5" />
    <path d="M7.70001 87.38L7.70999 87.32" stroke="black" stroke-width="0.5" />
    <path d="M27.8 87.71V87.63" stroke="black" stroke-width="0.5" />
    <path d="M27.8 87.63L27.79 87.55" stroke="black" stroke-width="0.5" />
    <path d="M27.79 87.55L27.78 87.47" stroke="black" stroke-width="0.5" />
    <path d="M27.78 87.47L27.77 87.39" stroke="black" stroke-width="0.5" />
    <path d="M27.77 87.39L27.75 87.32" stroke="black" stroke-width="0.5" />
    <path d="M27.75 87.32L27.73 87.24" stroke="black" stroke-width="0.5" />
    <path d="M27.73 87.24L27.7 87.16" stroke="black" stroke-width="0.5" />
    <path d="M27.7 87.16L27.67 87.09" stroke="black" stroke-width="0.5" />
    <path d="M27.67 87.09L27.66 87.06" stroke="black" stroke-width="0.5" />
    <path d="M27.49 86.77L27.47 86.75" stroke="black" stroke-width="0.5" />
    <path d="M27.47 86.75L27.42 86.68" stroke="black" stroke-width="0.5" />
    <path d="M27.42 86.68L27.37 86.62" stroke="black" stroke-width="0.5" />
    <path d="M27.37 86.62L27.32 86.57" stroke="black" stroke-width="0.5" />
    <path d="M27.32 86.57L27.26 86.51" stroke="black" stroke-width="0.5" />
    <path d="M27.26 86.51L27.2 86.46" stroke="black" stroke-width="0.5" />
    <path d="M27.2 86.46L27.13 86.41" stroke="black" stroke-width="0.5" />
    <path d="M27.13 86.41L27.07 86.37" stroke="black" stroke-width="0.5" />
    <path d="M27.07 86.37L27 86.33" stroke="black" stroke-width="0.5" />
    <path d="M26.69 86.19L26.63 86.17" stroke="black" stroke-width="0.5" />
    <path d="M26.63 86.17L26.56 86.15" stroke="black" stroke-width="0.5" />
    <path d="M26.56 86.15L26.48 86.14" stroke="black" stroke-width="0.5" />
    <path d="M26.48 86.14L26.4 86.13" stroke="black" stroke-width="0.5" />
    <path d="M26.4 86.13L26.32 86.12" stroke="black" stroke-width="0.5" />
    <path d="M26.32 86.12L26.24 86.11" stroke="black" stroke-width="0.5" />
    <path d="M26.24 86.11H26.16" stroke="black" stroke-width="0.5" />
    <path d="M26.16 86.11L26.08 86.12" stroke="black" stroke-width="0.5" />
    <path d="M26.08 86.12H26.03" stroke="black" stroke-width="0.5" />
    <path d="M25.71 86.19L25.69 86.2" stroke="black" stroke-width="0.5" />
    <path d="M25.69 86.2L25.62 86.22" stroke="black" stroke-width="0.5" />
    <path d="M25.62 86.22L25.55 86.26" stroke="black" stroke-width="0.5" />
    <path d="M25.55 86.26L25.47 86.29" stroke="black" stroke-width="0.5" />
    <path d="M25.47 86.29L25.4 86.33" stroke="black" stroke-width="0.5" />
    <path d="M25.4 86.33L25.34 86.37" stroke="black" stroke-width="0.5" />
    <path d="M25.34 86.37L25.27 86.41" stroke="black" stroke-width="0.5" />
    <path d="M25.27 86.41L25.21 86.46" stroke="black" stroke-width="0.5" />
    <path d="M25.21 86.46L25.15 86.51" stroke="black" stroke-width="0.5" />
    <path d="M25.15 86.51L25.13 86.52" stroke="black" stroke-width="0.5" />
    <path d="M24.91 86.77L24.88 86.81" stroke="black" stroke-width="0.5" />
    <path d="M24.88 86.81L24.84 86.88" stroke="black" stroke-width="0.5" />
    <path d="M24.84 86.88L24.8 86.95" stroke="black" stroke-width="0.5" />
    <path d="M24.8 86.95L24.76 87.02" stroke="black" stroke-width="0.5" />
    <path d="M24.76 87.02L24.73 87.09" stroke="black" stroke-width="0.5" />
    <path d="M24.73 87.09L24.7 87.16" stroke="black" stroke-width="0.5" />
    <path d="M24.7 87.16L24.68 87.24" stroke="black" stroke-width="0.5" />
    <path d="M24.68 87.24L24.66 87.32" stroke="black" stroke-width="0.5" />
    <path d="M24.66 87.32L24.64 87.38" stroke="black" stroke-width="0.5" />
    <path d="M24.61 87.71V87.79" stroke="black" stroke-width="0.5" />
    <path d="M24.61 87.79V87.87" stroke="black" stroke-width="0.5" />
    <path d="M24.61 87.87L24.62 87.95" stroke="black" stroke-width="0.5" />
    <path d="M24.62 87.95L24.64 88.03" stroke="black" stroke-width="0.5" />
    <path d="M24.64 88.03L24.66 88.1" stroke="black" stroke-width="0.5" />
    <path d="M24.66 88.1L24.68 88.18" stroke="black" stroke-width="0.5" />
    <path d="M24.68 88.18L24.7 88.26" stroke="black" stroke-width="0.5" />
    <path d="M24.7 88.26L24.73 88.33" stroke="black" stroke-width="0.5" />
    <path d="M24.73 88.33L24.74 88.36" stroke="black" stroke-width="0.5" />
    <path d="M24.91 88.65L24.93 88.67" stroke="black" stroke-width="0.5" />
    <path d="M24.93 88.67L24.98 88.74" stroke="black" stroke-width="0.5" />
    <path d="M24.98 88.74L25.03 88.8" stroke="black" stroke-width="0.5" />
    <path d="M25.03 88.8L25.09 88.85" stroke="black" stroke-width="0.5" />
    <path d="M25.09 88.85L25.15 88.91" stroke="black" stroke-width="0.5" />
    <path d="M25.15 88.91L25.21 88.96" stroke="black" stroke-width="0.5" />
    <path d="M25.21 88.96L25.27 89.01" stroke="black" stroke-width="0.5" />
    <path d="M25.27 89.01L25.34 89.05" stroke="black" stroke-width="0.5" />
    <path d="M25.34 89.05L25.4 89.09" stroke="black" stroke-width="0.5" />
    <path d="M25.71 89.23L25.77 89.25" stroke="black" stroke-width="0.5" />
    <path d="M25.77 89.25L25.85 89.27" stroke="black" stroke-width="0.5" />
    <path d="M25.85 89.27L25.92 89.28" stroke="black" stroke-width="0.5" />
    <path d="M25.92 89.28L26 89.29" stroke="black" stroke-width="0.5" />
    <path d="M26 89.29L26.08 89.3" stroke="black" stroke-width="0.5" />
    <path d="M26.08 89.3H26.16" stroke="black" stroke-width="0.5" />
    <path d="M26.16 89.3H26.24" stroke="black" stroke-width="0.5" />
    <path d="M26.24 89.3H26.32" stroke="black" stroke-width="0.5" />
    <path d="M26.32 89.3H26.37" stroke="black" stroke-width="0.5" />
    <path d="M26.69 89.23L26.71 89.22" stroke="black" stroke-width="0.5" />
    <path d="M26.71 89.22L26.78 89.2" stroke="black" stroke-width="0.5" />
    <path d="M26.78 89.2L26.86 89.16" stroke="black" stroke-width="0.5" />
    <path d="M26.86 89.16L26.93 89.13" stroke="black" stroke-width="0.5" />
    <path d="M26.93 89.13L27 89.09" stroke="black" stroke-width="0.5" />
    <path d="M27 89.09L27.07 89.05" stroke="black" stroke-width="0.5" />
    <path d="M27.07 89.05L27.13 89.01" stroke="black" stroke-width="0.5" />
    <path d="M27.13 89.01L27.2 88.96" stroke="black" stroke-width="0.5" />
    <path d="M27.2 88.96L27.26 88.91" stroke="black" stroke-width="0.5" />
    <path d="M27.26 88.91L27.27 88.9" stroke="black" stroke-width="0.5" />
    <path d="M27.49 88.65L27.52 88.61" stroke="black" stroke-width="0.5" />
    <path d="M27.52 88.61L27.56 88.54" stroke="black" stroke-width="0.5" />
    <path d="M27.56 88.54L27.6 88.47" stroke="black" stroke-width="0.5" />
    <path d="M27.6 88.47L27.64 88.4" stroke="black" stroke-width="0.5" />
    <path d="M27.64 88.4L27.67 88.33" stroke="black" stroke-width="0.5" />
    <path d="M27.67 88.33L27.7 88.26" stroke="black" stroke-width="0.5" />
    <path d="M27.7 88.26L27.73 88.18" stroke="black" stroke-width="0.5" />
    <path d="M27.73 88.18L27.75 88.1" stroke="black" stroke-width="0.5" />
    <path d="M27.75 88.1L27.76 88.04" stroke="black" stroke-width="0.5" />
    <path d="M75.03 9.16003L70.11 10.36" stroke="black" stroke-width="0.5" />
    <path d="M83.77 7.58002V12.95" stroke="black" stroke-width="0.5" />
    <path d="M69.69 8.53003L58.84 11.74" stroke="black" stroke-width="0.5" />
    <path d="M58.84 11.74L46.74 15.95" stroke="black" stroke-width="0.5" />
    <path d="M88.43 8.79999V12.94" stroke="black" stroke-width="0.5" />
    <path d="M46.74 15.95V20.28" stroke="black" stroke-width="0.5" />
    <path d="M70.67 12.24L70.11 10.35" stroke="black" stroke-width="0.5" />
    <path d="M88.77 14.27V16.52" stroke="black" stroke-width="0.5" />
    <path d="M88.77 17.52V19.51" stroke="black" stroke-width="0.5" />
    <path d="M88.77 20.51V22.5" stroke="black" stroke-width="0.5" />
    <path d="M88.77 23.5V25.5" stroke="black" stroke-width="0.5" />
    <path d="M88.77 26.49V28.74" stroke="black" stroke-width="0.5" />
    <path d="M55.62 35.06V33.05" stroke="black" stroke-width="0.5" />
    <path d="M55.62 33.05H57" stroke="black" stroke-width="0.5" />
    <path d="M57 33.05V39.9" stroke="black" stroke-width="0.5" />
    <path d="M57 39.9H55.62" stroke="black" stroke-width="0.5" />
    <path d="M55.62 39.9V37.89" stroke="black" stroke-width="0.5" />
    <path d="M55.62 37.89H55.96" stroke="black" stroke-width="0.5" />
    <path d="M55.96 37.89V37.66" stroke="black" stroke-width="0.5" />
    <path d="M55.96 37.66H55.62" stroke="black" stroke-width="0.5" />
    <path d="M55.62 37.66V35.29" stroke="black" stroke-width="0.5" />
    <path d="M55.62 35.29H55.96" stroke="black" stroke-width="0.5" />
    <path d="M55.96 35.29V35.06" stroke="black" stroke-width="0.5" />
    <path d="M55.96 35.06H55.62" stroke="black" stroke-width="0.5" />
    <path d="M89.07 19.1V17.09" stroke="black" stroke-width="0.5" />
    <path d="M89.07 17.09H90.45" stroke="black" stroke-width="0.5" />
    <path d="M90.45 17.09V23.94" stroke="black" stroke-width="0.5" />
    <path d="M90.45 23.94H89.07" stroke="black" stroke-width="0.5" />
    <path d="M89.07 23.94V21.93" stroke="black" stroke-width="0.5" />
    <path d="M89.07 21.93H89.4" stroke="black" stroke-width="0.5" />
    <path d="M89.4 21.93V21.7" stroke="black" stroke-width="0.5" />
    <path d="M89.4 21.7H89.07" stroke="black" stroke-width="0.5" />
    <path d="M89.07 21.7V19.33" stroke="black" stroke-width="0.5" />
    <path d="M89.07 19.33H89.4" stroke="black" stroke-width="0.5" />
    <path d="M89.4 19.33V19.1" stroke="black" stroke-width="0.5" />
    <path d="M89.4 19.1H89.07" stroke="black" stroke-width="0.5" />
    <path d="M88.43 14.27H88.77" stroke="black" stroke-width="0.5" />
    <path d="M89.76 40.79V42.12" stroke="black" stroke-width="0.5" />
    <path d="M88.77 28.74H88.43" stroke="black" stroke-width="0.5" />
    <path d="M85.1 14.27H88.43" stroke="black" stroke-width="0.5" />
    <path d="M86.96 24.13H87.29" stroke="black" stroke-width="0.5" />
    <path d="M86.96 25.11V24.13" stroke="black" stroke-width="0.5" />
    <path d="M87.29 25.11H86.96" stroke="black" stroke-width="0.5" />
    <path d="M87.29 24.13V25.11" stroke="black" stroke-width="0.5" />
    <path d="M84.57 22.95L84.28 23.02" stroke="black" stroke-width="0.5" />
    <path d="M84.28 23.02L84.07 23.19" stroke="black" stroke-width="0.5" />
    <path d="M84.07 23.19L84 23.43" stroke="black" stroke-width="0.5" />
    <path d="M84 25.81L84.07 26.05" stroke="black" stroke-width="0.5" />
    <path d="M84.07 26.05L84.28 26.23" stroke="black" stroke-width="0.5" />
    <path d="M84.28 26.23L84.57 26.29" stroke="black" stroke-width="0.5" />
    <path d="M85.99 26.29L86.4 26.15" stroke="black" stroke-width="0.5" />
    <path d="M86.4 26.15L86.57 25.81" stroke="black" stroke-width="0.5" />
    <path d="M86.57 23.43L86.4 23.09" stroke="black" stroke-width="0.5" />
    <path d="M86.4 23.09L85.99 22.95" stroke="black" stroke-width="0.5" />
    <path d="M84.57 22.95H85.99" stroke="black" stroke-width="0.5" />
    <path d="M84 25.81V23.43" stroke="black" stroke-width="0.5" />
    <path d="M85.99 26.29H84.57" stroke="black" stroke-width="0.5" />
    <path d="M86.57 23.43V25.81" stroke="black" stroke-width="0.5" />
    <path d="M83.66 22.63H87.65" stroke="black" stroke-width="0.5" />
    <path d="M83.66 26.62V22.63" stroke="black" stroke-width="0.5" />
    <path d="M87.65 26.62H83.66" stroke="black" stroke-width="0.5" />
    <path d="M87.65 22.63V26.62" stroke="black" stroke-width="0.5" />
    <path d="M88.43 14.27V27.22" stroke="black" stroke-width="0.5" />
    <path d="M88.43 27.22H83.07" stroke="black" stroke-width="0.5" />
    <path d="M83.07 27.22V14.27" stroke="black" stroke-width="0.5" />
    <path d="M83.07 14.27H88.43" stroke="black" stroke-width="0.5" />
    <path d="M84.99 14.27V15.43" stroke="black" stroke-width="0.5" />
    <path d="M84.99 16.09V17.42" stroke="black" stroke-width="0.5" />
    <path d="M84.99 18.09V19.42" stroke="black" stroke-width="0.5" />
    <path d="M84.99 20.08V21.41" stroke="black" stroke-width="0.5" />
    <path d="M84.99 22.08V23.41" stroke="black" stroke-width="0.5" />
    <path d="M84.99 24.07V25.4" stroke="black" stroke-width="0.5" />
    <path d="M84.99 26.07V27.22" stroke="black" stroke-width="0.5" />
    <path d="M102.7 37.47V40.79" stroke="black" stroke-width="0.5" />
    <path d="M92.84 39.33V39.66" stroke="black" stroke-width="0.5" />
    <path d="M91.86 39.33H92.84" stroke="black" stroke-width="0.5" />
    <path d="M91.86 39.66V39.33" stroke="black" stroke-width="0.5" />
    <path d="M92.84 39.66H91.86" stroke="black" stroke-width="0.5" />
    <path d="M94.02 36.94L93.96 36.65" stroke="black" stroke-width="0.5" />
    <path d="M93.96 36.65L93.78 36.44" stroke="black" stroke-width="0.5" />
    <path d="M93.78 36.44L93.54 36.36" stroke="black" stroke-width="0.5" />
    <path d="M91.16 36.36L90.92 36.44" stroke="black" stroke-width="0.5" />
    <path d="M90.92 36.44L90.75 36.65" stroke="black" stroke-width="0.5" />
    <path d="M90.75 36.65L90.68 36.94" stroke="black" stroke-width="0.5" />
    <path d="M90.68 38.36L90.82 38.77" stroke="black" stroke-width="0.5" />
    <path d="M90.82 38.77L91.16 38.93" stroke="black" stroke-width="0.5" />
    <path d="M93.54 38.93L93.88 38.77" stroke="black" stroke-width="0.5" />
    <path d="M93.88 38.77L94.02 38.36" stroke="black" stroke-width="0.5" />
    <path d="M94.02 36.94V38.36" stroke="black" stroke-width="0.5" />
    <path d="M91.16 36.36H93.54" stroke="black" stroke-width="0.5" />
    <path d="M90.68 38.36V36.94" stroke="black" stroke-width="0.5" />
    <path d="M93.54 38.93H91.16" stroke="black" stroke-width="0.5" />
    <path d="M94.35 36.03V40.02" stroke="black" stroke-width="0.5" />
    <path d="M90.36 36.03H94.35" stroke="black" stroke-width="0.5" />
    <path d="M90.36 40.02V36.03" stroke="black" stroke-width="0.5" />
    <path d="M94.35 40.02H90.36" stroke="black" stroke-width="0.5" />
    <path d="M102.7 40.79H89.76" stroke="black" stroke-width="0.5" />
    <path d="M89.76 40.79V35.44" stroke="black" stroke-width="0.5" />
    <path d="M89.76 35.44H102.7" stroke="black" stroke-width="0.5" />
    <path d="M102.7 35.44V40.79" stroke="black" stroke-width="0.5" />
    <path d="M102.7 37.36H101.55" stroke="black" stroke-width="0.5" />
    <path d="M100.88 37.36H99.55" stroke="black" stroke-width="0.5" />
    <path d="M98.89 37.36H97.56" stroke="black" stroke-width="0.5" />
    <path d="M96.89 37.36H95.56" stroke="black" stroke-width="0.5" />
    <path d="M94.9 37.36H93.57" stroke="black" stroke-width="0.5" />
    <path d="M92.91 37.36H91.58" stroke="black" stroke-width="0.5" />
    <path d="M90.91 37.36H89.76" stroke="black" stroke-width="0.5" />
    <path d="M79.73 10.74L79.55 11.36" stroke="black" stroke-width="0.5" />
    <path d="M79.55 11.36L79.06 11.78" stroke="black" stroke-width="0.5" />
    <path d="M79.06 11.78L78.42 11.88" stroke="black" stroke-width="0.5" />
    <path d="M78.42 11.88L77.84 11.61" stroke="black" stroke-width="0.5" />
    <path d="M77.84 11.61L77.49 11.07" stroke="black" stroke-width="0.5" />
    <path d="M77.49 11.07V10.42" stroke="black" stroke-width="0.5" />
    <path d="M77.49 10.42L77.84 9.88" stroke="black" stroke-width="0.5" />
    <path d="M77.84 9.87998L78.42 9.60999" stroke="black" stroke-width="0.5" />
    <path d="M78.42 9.60999L79.06 9.69998" stroke="black" stroke-width="0.5" />
    <path d="M79.06 9.70001L79.55 10.12" stroke="black" stroke-width="0.5" />
    <path d="M79.55 10.12L79.73 10.74" stroke="black" stroke-width="0.5" />
    <path d="M80.32 6.76001L74.72 7.87001" stroke="black" stroke-width="0.5" />
    <path d="M89.76 41.16H91.19" stroke="black" stroke-width="0.5" />
    <path d="M92.18 41.16H94.18" stroke="black" stroke-width="0.5" />
    <path d="M95.18 41.16H97.17" stroke="black" stroke-width="0.5" />
    <path d="M98.17 41.16H100.16" stroke="black" stroke-width="0.5" />
    <path d="M101.16 41.16H102.59" stroke="black" stroke-width="0.5" />
    <path d="M102.59 41.16V41.85" stroke="black" stroke-width="0.5" />
    <path d="M102.59 41.85H101.16" stroke="black" stroke-width="0.5" />
    <path d="M100.16 41.85H98.17" stroke="black" stroke-width="0.5" />
    <path d="M97.17 41.85H95.18" stroke="black" stroke-width="0.5" />
    <path d="M94.18 41.85H92.18" stroke="black" stroke-width="0.5" />
    <path d="M91.19 41.85H89.76" stroke="black" stroke-width="0.5" />
    <path d="M89.76 41.85V41.16" stroke="black" stroke-width="0.5" />
    <path d="M93.54 74.04H92.21" stroke="black" stroke-width="0.5" />
    <path d="M132.84 14.27H129.52" stroke="black" stroke-width="0.5" />
    <path d="M130.99 24.13H130.65" stroke="black" stroke-width="0.5" />
    <path d="M130.99 25.11V24.13" stroke="black" stroke-width="0.5" />
    <path d="M130.65 25.11H130.99" stroke="black" stroke-width="0.5" />
    <path d="M130.65 24.13V25.11" stroke="black" stroke-width="0.5" />
    <path d="M133.38 22.95L133.66 23.02" stroke="black" stroke-width="0.5" />
    <path d="M133.66 23.02L133.87 23.19" stroke="black" stroke-width="0.5" />
    <path d="M133.87 23.19L133.95 23.43" stroke="black" stroke-width="0.5" />
    <path d="M133.95 25.81L133.87 26.05" stroke="black" stroke-width="0.5" />
    <path d="M133.87 26.05L133.66 26.23" stroke="black" stroke-width="0.5" />
    <path d="M133.66 26.23L133.38 26.29" stroke="black" stroke-width="0.5" />
    <path d="M131.95 26.29L131.55 26.15" stroke="black" stroke-width="0.5" />
    <path d="M131.55 26.15L131.38 25.81" stroke="black" stroke-width="0.5" />
    <path d="M131.38 23.43L131.55 23.09" stroke="black" stroke-width="0.5" />
    <path d="M131.55 23.09L131.95 22.95" stroke="black" stroke-width="0.5" />
    <path d="M133.38 22.95H131.95" stroke="black" stroke-width="0.5" />
    <path d="M133.95 25.81V23.43" stroke="black" stroke-width="0.5" />
    <path d="M131.95 26.29H133.38" stroke="black" stroke-width="0.5" />
    <path d="M131.38 23.43V25.81" stroke="black" stroke-width="0.5" />
    <path d="M134.28 22.63H130.29" stroke="black" stroke-width="0.5" />
    <path d="M134.28 26.62V22.63" stroke="black" stroke-width="0.5" />
    <path d="M130.29 26.62H134.28" stroke="black" stroke-width="0.5" />
    <path d="M130.29 22.63V26.62" stroke="black" stroke-width="0.5" />
    <path d="M129.52 14.27V27.22" stroke="black" stroke-width="0.5" />
    <path d="M129.52 27.22H134.88" stroke="black" stroke-width="0.5" />
    <path d="M134.88 27.22V14.27" stroke="black" stroke-width="0.5" />
    <path d="M134.88 14.27H129.52" stroke="black" stroke-width="0.5" />
    <path d="M132.95 14.27V15.43" stroke="black" stroke-width="0.5" />
    <path d="M132.95 16.09V17.42" stroke="black" stroke-width="0.5" />
    <path d="M132.95 18.09V19.42" stroke="black" stroke-width="0.5" />
    <path d="M132.95 20.08V21.41" stroke="black" stroke-width="0.5" />
    <path d="M132.95 22.08V23.41" stroke="black" stroke-width="0.5" />
    <path d="M132.95 24.07V25.4" stroke="black" stroke-width="0.5" />
    <path d="M132.95 26.07V27.22" stroke="black" stroke-width="0.5" />
    <path d="M129.52 14.27H128.97" stroke="black" stroke-width="0.5" />
    <path d="M128.97 14.27V16.43" stroke="black" stroke-width="0.5" />
    <path d="M128.97 17.42V19.42" stroke="black" stroke-width="0.5" />
    <path d="M128.97 20.42V22.41" stroke="black" stroke-width="0.5" />
    <path d="M128.97 23.41V25.4" stroke="black" stroke-width="0.5" />
    <path d="M128.97 26.4V28.55" stroke="black" stroke-width="0.5" />
    <path d="M128.97 28.55H129.52" stroke="black" stroke-width="0.5" />
    <path d="M80.63 8.04999L75.03 9.15999" stroke="black" stroke-width="0.5" />
    <path d="M58.03 55.69H62.06" stroke="black" stroke-width="0.5" />
    <path d="M62.06 55.69V57.03" stroke="black" stroke-width="0.5" />
    <path d="M62.06 57.03H58.03" stroke="black" stroke-width="0.5" />
    <path d="M58.03 57.03V55.69" stroke="black" stroke-width="0.5" />
    <path d="M93.54 94.78H92.21" stroke="black" stroke-width="0.5" />
    <path d="M80.32 6.76002V3.70001" stroke="black" stroke-width="0.5" />
    <path d="M57.38 57.03H62.61" stroke="black" stroke-width="0.5" />
    <path d="M62.61 57.03V57.98" stroke="black" stroke-width="0.5" />
    <path d="M62.61 57.98H57.38" stroke="black" stroke-width="0.5" />
    <path d="M57.38 57.98V57.03" stroke="black" stroke-width="0.5" />
    <path d="M93.54 74.57H92.21" stroke="black" stroke-width="0.5" />
    <path d="M69.6 10.51L69.22 9.22998" stroke="black" stroke-width="0.5" />
    <path d="M93.54 79.3H92.21" stroke="black" stroke-width="0.5" />
    <path d="M93.54 89.06H92.21" stroke="black" stroke-width="0.5" />
    <path d="M177.33 22.94H163.23" stroke="black" stroke-width="0.5" />
    <path d="M206.58 22.14H205.61" stroke="black" stroke-width="0.5" />
    <path d="M205.61 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M205.61 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M205.61 24.86H206.58" stroke="black" stroke-width="0.5" />
    <path d="M206.58 24.86V22.14" stroke="black" stroke-width="0.5" />
    <path d="M177.33 24.86H178.3" stroke="black" stroke-width="0.5" />
    <path d="M178.3 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M178.3 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M178.3 22.14H177.33" stroke="black" stroke-width="0.5" />
    <path d="M177.33 22.14V24.86" stroke="black" stroke-width="0.5" />
    <path d="M200.82 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M200.82 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M200.82 24.86H201.79" stroke="black" stroke-width="0.5" />
    <path d="M201.79 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M201.79 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M201.79 22.14H200.82" stroke="black" stroke-width="0.5" />
    <path d="M205.61 24.86H201.79" stroke="black" stroke-width="0.5" />
    <path d="M200.82 24.86H178.3" stroke="black" stroke-width="0.5" />
    <path d="M200.82 22.14H178.3" stroke="black" stroke-width="0.5" />
    <path d="M205.61 22.14H201.79" stroke="black" stroke-width="0.5" />
    <path d="M178.3 22.72H205.61" stroke="black" stroke-width="0.5" />
    <path d="M205.61 23.2H178.3" stroke="black" stroke-width="0.5" />
    <path d="M205.61 23.2V22.72" stroke="black" stroke-width="0.5" />
    <path
      d="M178.3 23.2H205.61L178.3 22.72V23.2Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M205.61 23.2V22.72H178.3L205.61 23.2Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M178.3 23.2V22.72" stroke="black" stroke-width="0.5" />
    <path d="M191.47 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M191.47 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M191.47 24.86H192.44" stroke="black" stroke-width="0.5" />
    <path d="M192.44 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M192.44 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M192.44 22.14H191.47" stroke="black" stroke-width="0.5" />
    <path d="M183.09 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M183.09 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M183.09 24.86H182.11" stroke="black" stroke-width="0.5" />
    <path d="M182.11 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M182.11 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M182.11 22.14H183.09" stroke="black" stroke-width="0.5" />
    <path d="M257.65 22.14H256.67" stroke="black" stroke-width="0.5" />
    <path d="M256.67 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M256.67 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M256.67 24.86H257.65" stroke="black" stroke-width="0.5" />
    <path d="M257.65 24.86V22.14" stroke="black" stroke-width="0.5" />
    <path d="M228.39 24.86H229.37" stroke="black" stroke-width="0.5" />
    <path d="M229.37 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M229.37 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M229.37 22.14H228.39" stroke="black" stroke-width="0.5" />
    <path d="M228.39 22.14V24.86" stroke="black" stroke-width="0.5" />
    <path d="M251.88 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M251.88 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M251.88 24.86H252.86" stroke="black" stroke-width="0.5" />
    <path d="M252.86 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M252.86 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M252.86 22.14H251.88" stroke="black" stroke-width="0.5" />
    <path d="M256.67 24.86H252.86" stroke="black" stroke-width="0.5" />
    <path d="M251.88 24.86H229.37" stroke="black" stroke-width="0.5" />
    <path d="M251.88 22.14H229.37" stroke="black" stroke-width="0.5" />
    <path d="M256.67 22.14H252.86" stroke="black" stroke-width="0.5" />
    <path d="M229.37 22.72H256.67" stroke="black" stroke-width="0.5" />
    <path d="M256.67 23.2H229.37" stroke="black" stroke-width="0.5" />
    <path d="M256.67 23.2V22.72" stroke="black" stroke-width="0.5" />
    <path
      d="M229.37 23.2H256.67L229.37 22.72V23.2Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M256.67 23.2V22.72H229.37L256.67 23.2Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M229.37 23.2V22.72" stroke="black" stroke-width="0.5" />
    <path d="M242.53 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M242.53 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M242.53 24.86H243.51" stroke="black" stroke-width="0.5" />
    <path d="M243.51 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M243.51 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M243.51 22.14H242.53" stroke="black" stroke-width="0.5" />
    <path d="M234.15 22.14V22.72" stroke="black" stroke-width="0.5" />
    <path d="M234.15 23.2V24.86" stroke="black" stroke-width="0.5" />
    <path d="M234.15 24.86H233.18" stroke="black" stroke-width="0.5" />
    <path d="M233.18 24.86V23.2" stroke="black" stroke-width="0.5" />
    <path d="M233.18 22.72V22.14" stroke="black" stroke-width="0.5" />
    <path d="M233.18 22.14H234.15" stroke="black" stroke-width="0.5" />
    <path d="M161.67 109.07H166.69" stroke="black" stroke-width="0.5" />
    <path d="M166.69 109.07V105.78" stroke="black" stroke-width="0.5" />
    <path d="M166.69 105.78H161.67" stroke="black" stroke-width="0.5" />
    <path d="M166.05 79.86L167.35 79.69" stroke="black" stroke-width="0.5" />
    <path d="M167.35 79.69L167.62 79.58" stroke="black" stroke-width="0.5" />
    <path d="M168.23 79.34L168.56 79.2" stroke="black" stroke-width="0.5" />
    <path d="M168.56 79.2L169.35 78.63" stroke="black" stroke-width="0.5" />
    <path d="M169.83 78.17L170.44 77.41" stroke="black" stroke-width="0.5" />
    <path d="M170.44 77.41L170.59 77.09" stroke="black" stroke-width="0.5" />
    <path d="M170.87 76.49L170.99 76.23" stroke="black" stroke-width="0.5" />
    <path d="M170.99 76.23L171.21 74.94" stroke="black" stroke-width="0.5" />
    <path d="M54.98 15.06L48.6 17.29" stroke="black" stroke-width="0.5" />
    <path d="M161.23 106.23V108.63" stroke="black" stroke-width="0.5" />
    <path d="M161.23 108.63L161.67 109.07" stroke="black" stroke-width="0.5" />
    <path d="M161.67 105.78L161.23 106.23" stroke="black" stroke-width="0.5" />
    <path d="M161.23 107.79H162.27" stroke="black" stroke-width="0.5" />
    <path d="M162.27 105.78V109.07" stroke="black" stroke-width="0.5" />
    <path d="M161.23 107.06H162.27" stroke="black" stroke-width="0.5" />
    <path d="M189.96 85.34H191.57" stroke="black" stroke-width="0.5" />
    <path d="M191.57 85.34V90.21" stroke="black" stroke-width="0.5" />
    <path d="M191.57 90.21H189.96" stroke="black" stroke-width="0.5" />
    <path d="M189.96 90.21V85.34" stroke="black" stroke-width="0.5" />
    <path d="M165.64 105.78V109.07" stroke="black" stroke-width="0.5" />
    <path d="M190.79 91.21H191.65" stroke="black" stroke-width="0.5" />
    <path d="M191.65 91.21V94.69" stroke="black" stroke-width="0.5" />
    <path d="M191.65 94.69H190.79" stroke="black" stroke-width="0.5" />
    <path d="M190.79 94.69V91.21" stroke="black" stroke-width="0.5" />
    <path d="M185.58 94.09L187.3 92.37" stroke="black" stroke-width="0.5" />
    <path d="M185.58 94.09L187.3 95.81" stroke="black" stroke-width="0.5" />
    <path d="M187.3 93.3H188.63" stroke="black" stroke-width="0.5" />
    <path d="M188.63 92.16H189.96" stroke="black" stroke-width="0.5" />
    <path d="M187.3 109.31H185.58" stroke="black" stroke-width="0.5" />
    <path d="M188.63 96.02H189.96" stroke="black" stroke-width="0.5" />
    <path d="M185.58 109.31L187.3 111.03" stroke="black" stroke-width="0.5" />
    <path d="M185.58 109.31L187.3 107.59" stroke="black" stroke-width="0.5" />
    <path d="M187.3 110.1H188.63" stroke="black" stroke-width="0.5" />
    <path d="M187.3 108.52H188.63" stroke="black" stroke-width="0.5" />
    <path d="M188.63 107.38H189.96" stroke="black" stroke-width="0.5" />
    <path d="M187.3 74.04H188.1" stroke="black" stroke-width="0.5" />
    <path d="M189.96 118.06H191.57" stroke="black" stroke-width="0.5" />
    <path d="M191.57 118.06V113.19" stroke="black" stroke-width="0.5" />
    <path d="M191.57 113.19H189.96" stroke="black" stroke-width="0.5" />
    <path d="M189.96 113.19V118.06" stroke="black" stroke-width="0.5" />
    <path d="M190.79 112.19H191.65" stroke="black" stroke-width="0.5" />
    <path d="M191.65 112.19V108.71" stroke="black" stroke-width="0.5" />
    <path d="M191.65 108.71H190.79" stroke="black" stroke-width="0.5" />
    <path d="M190.79 108.71V112.19" stroke="black" stroke-width="0.5" />
    <path d="M191.65 109.74H192.91" stroke="black" stroke-width="0.5" />
    <path d="M192.91 109.74L191.65 111.01" stroke="black" stroke-width="0.5" />
    <path d="M192.91 109.74L191.65 108.48" stroke="black" stroke-width="0.5" />
    <path d="M216.02 52.6H216.35" stroke="black" stroke-width="0.5" />
    <path d="M216.02 53.58V52.6" stroke="black" stroke-width="0.5" />
    <path d="M216.35 53.58H216.02" stroke="black" stroke-width="0.5" />
    <path d="M216.35 52.6V53.58" stroke="black" stroke-width="0.5" />
    <path d="M213.63 51.42L213.34 51.49" stroke="black" stroke-width="0.5" />
    <path d="M213.34 51.49L213.13 51.66" stroke="black" stroke-width="0.5" />
    <path d="M213.13 51.66L213.06 51.9" stroke="black" stroke-width="0.5" />
    <path d="M213.06 54.28L213.13 54.52" stroke="black" stroke-width="0.5" />
    <path d="M213.13 54.52L213.34 54.7" stroke="black" stroke-width="0.5" />
    <path d="M213.34 54.7L213.63 54.76" stroke="black" stroke-width="0.5" />
    <path d="M215.05 54.76L215.46 54.62" stroke="black" stroke-width="0.5" />
    <path d="M215.46 54.62L215.63 54.28" stroke="black" stroke-width="0.5" />
    <path d="M215.63 51.9L215.46 51.56" stroke="black" stroke-width="0.5" />
    <path d="M215.46 51.56L215.05 51.42" stroke="black" stroke-width="0.5" />
    <path d="M213.63 51.42H215.05" stroke="black" stroke-width="0.5" />
    <path d="M213.06 54.28V51.9" stroke="black" stroke-width="0.5" />
    <path d="M215.05 54.76H213.63" stroke="black" stroke-width="0.5" />
    <path d="M215.63 51.9V54.28" stroke="black" stroke-width="0.5" />
    <path d="M212.72 51.1H216.71" stroke="black" stroke-width="0.5" />
    <path d="M212.72 55.09V51.1" stroke="black" stroke-width="0.5" />
    <path d="M216.71 55.09H212.72" stroke="black" stroke-width="0.5" />
    <path d="M216.71 51.1V55.09" stroke="black" stroke-width="0.5" />
    <path d="M214.05 25.07V26.42" stroke="black" stroke-width="0.5" />
    <path d="M214.05 27.41V29.41" stroke="black" stroke-width="0.5" />
    <path d="M214.05 30.41V32.4" stroke="black" stroke-width="0.5" />
    <path d="M214.05 33.4V35.39" stroke="black" stroke-width="0.5" />
    <path d="M214.05 36.39V38.38" stroke="black" stroke-width="0.5" />
    <path d="M214.05 39.38V41.38" stroke="black" stroke-width="0.5" />
    <path d="M214.05 42.37V44.37" stroke="black" stroke-width="0.5" />
    <path d="M214.05 45.37V47.36" stroke="black" stroke-width="0.5" />
    <path d="M214.05 48.36V50.35" stroke="black" stroke-width="0.5" />
    <path d="M214.05 51.35V53.34" stroke="black" stroke-width="0.5" />
    <path d="M214.05 54.34V55.69" stroke="black" stroke-width="0.5" />
    <path d="M217.49 25.07V55.69" stroke="black" stroke-width="0.5" />
    <path d="M217.75 25.07V26.42" stroke="black" stroke-width="0.5" />
    <path d="M217.75 27.41V29.41" stroke="black" stroke-width="0.5" />
    <path d="M217.75 30.41V32.4" stroke="black" stroke-width="0.5" />
    <path d="M217.75 33.4V35.39" stroke="black" stroke-width="0.5" />
    <path d="M217.75 36.39V38.38" stroke="black" stroke-width="0.5" />
    <path d="M217.75 39.38V41.38" stroke="black" stroke-width="0.5" />
    <path d="M217.75 42.37V44.37" stroke="black" stroke-width="0.5" />
    <path d="M217.75 45.37V47.36" stroke="black" stroke-width="0.5" />
    <path d="M217.75 48.36V50.35" stroke="black" stroke-width="0.5" />
    <path d="M217.75 51.35V53.34" stroke="black" stroke-width="0.5" />
    <path d="M217.75 54.34V55.69" stroke="black" stroke-width="0.5" />
    <path d="M214.16 25.07H217.49" stroke="black" stroke-width="0.5" />
    <path d="M206.58 25.07H228.39" stroke="black" stroke-width="0.5" />
    <path d="M218.82 25.07V55.69" stroke="black" stroke-width="0.5" />
    <path d="M217.49 55.69H217.75" stroke="black" stroke-width="0.5" />
    <path d="M279.45 19.74V17.09" stroke="black" stroke-width="0.5" />
    <path d="M263.5 20.28V17.09" stroke="black" stroke-width="0.5" />
    <path d="M257.65 22.94V20.28" stroke="black" stroke-width="0.5" />
    <path d="M279.45 19.74H274.93" stroke="black" stroke-width="0.5" />
    <path d="M177.33 22.94V20.28" stroke="black" stroke-width="0.5" />
    <path d="M206.58 22.94V20.28" stroke="black" stroke-width="0.5" />
    <path d="M228.39 22.94V20.28" stroke="black" stroke-width="0.5" />
    <path d="M228.39 23.47H206.58" stroke="black" stroke-width="0.5" />
    <path d="M274.58 129.09V25.07" stroke="black" stroke-width="0.5" />
    <path d="M274.58 25.07H278.39" stroke="black" stroke-width="0.5" />
    <path d="M278.39 25.07V21.88" stroke="black" stroke-width="0.5" />
    <path d="M127.21 6.83002V8.62002" stroke="black" stroke-width="0.5" />
    <path d="M128.19 5.76001H127.21" stroke="black" stroke-width="0.5" />
    <path d="M127.21 5.76001V6.34" stroke="black" stroke-width="0.5" />
    <path d="M127.21 8.62H128.19" stroke="black" stroke-width="0.5" />
    <path d="M89.76 8.62H90.73" stroke="black" stroke-width="0.5" />
    <path d="M90.73 8.62002V6.83002" stroke="black" stroke-width="0.5" />
    <path d="M90.73 6.34V5.76001" stroke="black" stroke-width="0.5" />
    <path d="M90.73 5.76001H89.76" stroke="black" stroke-width="0.5" />
    <path d="M89.76 5.76001V8.62001" stroke="black" stroke-width="0.5" />
    <path d="M122.43 5.76001H90.73" stroke="black" stroke-width="0.5" />
    <path d="M127.21 5.76001H122.43" stroke="black" stroke-width="0.5" />
    <path d="M127.21 6.83002H90.73" stroke="black" stroke-width="0.5" />
    <path d="M127.21 6.82997V6.33997" stroke="black" stroke-width="0.5" />
    <path d="M90.73 6.82997V6.33997" stroke="black" stroke-width="0.5" />
    <path d="M99.59 5.76001V6.34" stroke="black" stroke-width="0.5" />
    <path d="M99.59 6.83002V8.62002" stroke="black" stroke-width="0.5" />
    <path d="M99.59 8.62H98.61" stroke="black" stroke-width="0.5" />
    <path d="M98.61 8.62002V6.83002" stroke="black" stroke-width="0.5" />
    <path d="M98.61 6.34V5.76001" stroke="black" stroke-width="0.5" />
    <path d="M118.36 5.76001V6.34" stroke="black" stroke-width="0.5" />
    <path d="M118.36 6.83002V8.62002" stroke="black" stroke-width="0.5" />
    <path d="M118.36 8.62H119.33" stroke="black" stroke-width="0.5" />
    <path d="M119.33 8.62002V6.83002" stroke="black" stroke-width="0.5" />
    <path d="M119.33 6.34V5.76001" stroke="black" stroke-width="0.5" />
    <path d="M119.33 5.76001H118.36" stroke="black" stroke-width="0.5" />
    <path d="M89.76 8.62H128.19" stroke="black" stroke-width="0.5" />
    <path d="M128.19 5.97003V0.660034" stroke="black" stroke-width="0.5" />
    <path
      d="M128.19 0.660034L134.37 1.58003"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.63 6.76001L143.23 7.87001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.63 6.21002L143.34 7.35002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M128.19 6.70001L137.32 8.05002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M137.32 8.04999L142.92 9.15999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M137.05 10.74L136.87 11.36" stroke="black" stroke-width="0.5" />
    <path d="M136.87 11.36L136.38 11.78" stroke="black" stroke-width="0.5" />
    <path d="M136.38 11.78L135.74 11.88" stroke="black" stroke-width="0.5" />
    <path d="M135.74 11.88L135.15 11.61" stroke="black" stroke-width="0.5" />
    <path d="M135.15 11.61L134.8 11.07" stroke="black" stroke-width="0.5" />
    <path d="M134.8 11.07V10.42" stroke="black" stroke-width="0.5" />
    <path d="M134.8 10.42L135.15 9.88" stroke="black" stroke-width="0.5" />
    <path
      d="M135.15 9.87998L135.74 9.60999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M135.74 9.60999L136.38 9.69998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M136.38 9.70001L136.87 10.12" stroke="black" stroke-width="0.5" />
    <path d="M136.87 10.12L137.05 10.74" stroke="black" stroke-width="0.5" />
    <path d="M140.5 10.74L140.32 11.36" stroke="black" stroke-width="0.5" />
    <path d="M140.32 11.36L139.84 11.78" stroke="black" stroke-width="0.5" />
    <path d="M139.84 11.78L139.2 11.88" stroke="black" stroke-width="0.5" />
    <path d="M139.2 11.88L138.61 11.61" stroke="black" stroke-width="0.5" />
    <path d="M138.61 11.61L138.26 11.07" stroke="black" stroke-width="0.5" />
    <path d="M138.26 11.07V10.42" stroke="black" stroke-width="0.5" />
    <path d="M138.26 10.42L138.61 9.88" stroke="black" stroke-width="0.5" />
    <path d="M138.61 9.87998L139.2 9.60999" stroke="black" stroke-width="0.5" />
    <path d="M139.2 9.60999L139.84 9.69998" stroke="black" stroke-width="0.5" />
    <path d="M139.84 9.70001L140.32 10.12" stroke="black" stroke-width="0.5" />
    <path d="M140.32 10.12L140.5 10.74" stroke="black" stroke-width="0.5" />
    <path d="M142.41 8.47998H144.43" stroke="black" stroke-width="0.5" />
    <path d="M144.43 8.47998V10.25" stroke="black" stroke-width="0.5" />
    <path d="M144.43 10.25H142.41" stroke="black" stroke-width="0.5" />
    <path d="M142.41 10.25V8.47998" stroke="black" stroke-width="0.5" />
    <path d="M147.84 10.35L148.37 8.57001" stroke="black" stroke-width="0.5" />
    <path d="M159 11.7L158.48 13.49" stroke="black" stroke-width="0.5" />
    <path d="M158.34 12.06L157.97 13.34" stroke="black" stroke-width="0.5" />
    <path d="M137.63 6.76002V3.70001" stroke="black" stroke-width="0.5" />
    <path d="M134.37 3.22002V1.58002" stroke="black" stroke-width="0.5" />
    <path
      d="M134.37 3.21997L137.63 3.69997"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M147.27 12.26L157.92 15.38" stroke="black" stroke-width="0.5" />
    <path d="M128.19 8.62001V5.76001" stroke="black" stroke-width="0.5" />
    <path d="M89.76 5.76001V8.58001" stroke="black" stroke-width="0.5" />
    <path d="M83.77 12.95H76.86" stroke="black" stroke-width="0.5" />
    <path d="M89.76 8.62L85.1 9.26999" stroke="black" stroke-width="0.5" />
    <path d="M89.76 5.35999L80.32 6.75999" stroke="black" stroke-width="0.5" />
    <path d="M76.86 12.95V8.79999" stroke="black" stroke-width="0.5" />
    <path d="M74.72 7.87L69.83 9.05" stroke="black" stroke-width="0.5" />
    <path d="M74.6 7.34998L69.69 8.52998" stroke="black" stroke-width="0.5" />
    <path d="M69.83 9.04999L59 12.24" stroke="black" stroke-width="0.5" />
    <path d="M59.47 13.49L60.03 15.38" stroke="black" stroke-width="0.5" />
    <path d="M59 12.24L47.27 16.33" stroke="black" stroke-width="0.5" />
    <path d="M48.6 17.29V20.28" stroke="black" stroke-width="0.5" />
    <path d="M47.27 16.33V20.28" stroke="black" stroke-width="0.5" />
    <path d="M89.76 5.97003V0.660034" stroke="black" stroke-width="0.5" />
    <path d="M85.1 12.94H88.43" stroke="black" stroke-width="0.5" />
    <path d="M89.76 0.660034L83.58 1.58003" stroke="black" stroke-width="0.5" />
    <path d="M80.32 6.21002L74.6 7.35002" stroke="black" stroke-width="0.5" />
    <path d="M89.76 6.70001L80.63 8.05002" stroke="black" stroke-width="0.5" />
    <path d="M75.54 8.47998H73.52" stroke="black" stroke-width="0.5" />
    <path d="M73.52 8.47998V10.25" stroke="black" stroke-width="0.5" />
    <path d="M73.52 10.25H75.54" stroke="black" stroke-width="0.5" />
    <path d="M75.54 10.25V8.47998" stroke="black" stroke-width="0.5" />
    <path d="M83.19 10.74L83.01 11.36" stroke="black" stroke-width="0.5" />
    <path d="M83.01 11.36L82.52 11.78" stroke="black" stroke-width="0.5" />
    <path d="M82.52 11.78L81.88 11.88" stroke="black" stroke-width="0.5" />
    <path d="M81.88 11.88L81.3 11.61" stroke="black" stroke-width="0.5" />
    <path d="M81.3 11.61L80.95 11.07" stroke="black" stroke-width="0.5" />
    <path d="M80.95 11.07V10.42" stroke="black" stroke-width="0.5" />
    <path d="M80.95 10.42L81.3 9.88" stroke="black" stroke-width="0.5" />
    <path d="M81.3 9.87998L81.88 9.60999" stroke="black" stroke-width="0.5" />
    <path d="M81.88 9.60999L82.52 9.69998" stroke="black" stroke-width="0.5" />
    <path d="M82.52 9.70001L83.01 10.12" stroke="black" stroke-width="0.5" />
    <path d="M83.01 10.12L83.19 10.74" stroke="black" stroke-width="0.5" />
    <path d="M70.11 10.35L69.58 8.57001" stroke="black" stroke-width="0.5" />
    <path d="M58.94 11.7L59.47 13.49" stroke="black" stroke-width="0.5" />
    <path d="M59.6 12.06L59.98 13.34" stroke="black" stroke-width="0.5" />
    <path d="M83.58 3.22002V1.58002" stroke="black" stroke-width="0.5" />
    <path d="M83.58 3.21997L80.32 3.69997" stroke="black" stroke-width="0.5" />
    <path d="M70.67 12.24L60.03 15.38" stroke="black" stroke-width="0.5" />
    <path d="M89.76 8.62001V5.76001" stroke="black" stroke-width="0.5" />
    <path d="M40.62 22.94V20.28" stroke="black" stroke-width="0.5" />
    <path d="M11.37 22.94V20.28" stroke="black" stroke-width="0.5" />
    <path d="M171.67 25.86H175.57" stroke="black" stroke-width="0.5" />
    <path d="M175.57 25.86V22.94" stroke="black" stroke-width="0.5" />
    <path d="M175.57 22.94H171.67" stroke="black" stroke-width="0.5" />
    <path d="M171.67 22.94V25.86" stroke="black" stroke-width="0.5" />
    <path d="M175.08 24.47L174.9 24.92" stroke="black" stroke-width="0.5" />
    <path d="M174.9 24.92L174.45 25.1" stroke="black" stroke-width="0.5" />
    <path d="M174.45 25.1L174.01 24.92" stroke="black" stroke-width="0.5" />
    <path d="M174.01 24.92L173.83 24.47" stroke="black" stroke-width="0.5" />
    <path d="M173.83 24.47L174.01 24.03" stroke="black" stroke-width="0.5" />
    <path d="M174.01 24.03L174.45 23.85" stroke="black" stroke-width="0.5" />
    <path d="M174.45 23.85L174.9 24.03" stroke="black" stroke-width="0.5" />
    <path d="M174.9 24.03L175.08 24.47" stroke="black" stroke-width="0.5" />
    <path d="M173.4 24.47L173.21 24.92" stroke="black" stroke-width="0.5" />
    <path d="M173.21 24.92L172.77 25.1" stroke="black" stroke-width="0.5" />
    <path d="M172.77 25.1L172.32 24.92" stroke="black" stroke-width="0.5" />
    <path d="M172.32 24.92L172.14 24.47" stroke="black" stroke-width="0.5" />
    <path d="M172.14 24.47L172.32 24.03" stroke="black" stroke-width="0.5" />
    <path d="M172.32 24.03L172.77 23.85" stroke="black" stroke-width="0.5" />
    <path d="M172.77 23.85L173.21 24.03" stroke="black" stroke-width="0.5" />
    <path d="M173.21 24.03L173.4 24.47" stroke="black" stroke-width="0.5" />
    <path d="M47.34 24.47L47.16 24.92" stroke="black" stroke-width="0.5" />
    <path d="M47.16 24.92L46.72 25.1" stroke="black" stroke-width="0.5" />
    <path d="M46.72 25.1L46.27 24.92" stroke="black" stroke-width="0.5" />
    <path d="M46.27 24.92L46.09 24.47" stroke="black" stroke-width="0.5" />
    <path d="M46.09 24.47L46.27 24.03" stroke="black" stroke-width="0.5" />
    <path d="M46.27 24.03L46.72 23.85" stroke="black" stroke-width="0.5" />
    <path d="M46.72 23.85L47.16 24.03" stroke="black" stroke-width="0.5" />
    <path d="M47.16 24.03L47.34 24.47" stroke="black" stroke-width="0.5" />
    <path d="M49.03 24.47L48.85 24.92" stroke="black" stroke-width="0.5" />
    <path d="M48.85 24.92L48.4 25.1" stroke="black" stroke-width="0.5" />
    <path d="M48.4 25.1L47.96 24.92" stroke="black" stroke-width="0.5" />
    <path d="M47.96 24.92L47.77 24.47" stroke="black" stroke-width="0.5" />
    <path d="M47.77 24.47L47.96 24.03" stroke="black" stroke-width="0.5" />
    <path d="M47.96 24.03L48.4 23.85" stroke="black" stroke-width="0.5" />
    <path d="M48.4 23.85L48.85 24.03" stroke="black" stroke-width="0.5" />
    <path d="M48.85 24.03L49.03 24.47" stroke="black" stroke-width="0.5" />
    <path d="M45.62 25.86H49.52" stroke="black" stroke-width="0.5" />
    <path d="M49.52 25.86V22.94" stroke="black" stroke-width="0.5" />
    <path d="M49.52 22.94H45.62" stroke="black" stroke-width="0.5" />
    <path d="M45.62 22.94V25.86" stroke="black" stroke-width="0.5" />
    <path d="M40.62 22.94H54.98" stroke="black" stroke-width="0.5" />
    <path d="M271.17 25.07V25.86" stroke="black" stroke-width="0.5" />
    <path d="M271.17 25.86H275.68" stroke="black" stroke-width="0.5" />
    <path d="M275.68 25.86V25.07" stroke="black" stroke-width="0.5" />
    <path d="M273.04 24.47L272.86 24.92" stroke="black" stroke-width="0.5" />
    <path d="M272.86 24.92L272.41 25.1" stroke="black" stroke-width="0.5" />
    <path d="M272.41 25.1L271.97 24.92" stroke="black" stroke-width="0.5" />
    <path d="M271.97 24.92L271.79 24.47" stroke="black" stroke-width="0.5" />
    <path d="M271.79 24.47L271.97 24.03" stroke="black" stroke-width="0.5" />
    <path d="M271.97 24.03L272.41 23.85" stroke="black" stroke-width="0.5" />
    <path d="M272.41 23.85L272.86 24.03" stroke="black" stroke-width="0.5" />
    <path d="M272.86 24.03L273.04 24.47" stroke="black" stroke-width="0.5" />
    <path d="M274.73 24.47L274.54 24.92" stroke="black" stroke-width="0.5" />
    <path d="M274.54 24.92L274.1 25.1" stroke="black" stroke-width="0.5" />
    <path d="M274.1 25.1L273.66 24.92" stroke="black" stroke-width="0.5" />
    <path d="M273.66 24.92L273.47 24.47" stroke="black" stroke-width="0.5" />
    <path d="M273.47 24.47L273.66 24.03" stroke="black" stroke-width="0.5" />
    <path d="M273.66 24.03L274.1 23.85" stroke="black" stroke-width="0.5" />
    <path d="M274.1 23.85L274.54 24.03" stroke="black" stroke-width="0.5" />
    <path d="M274.54 24.03L274.73 24.47" stroke="black" stroke-width="0.5" />
    <path d="M279.45 21.88V17.09" stroke="black" stroke-width="0.5" />
    <path
      d="M135.03 105.16C134.932 103.087 134.058 101.127 132.582 99.6688C131.105 98.2107 129.134 97.3615 127.06 97.29"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M127.06 97.29V105.16" stroke="black" stroke-width="0.5" />
    <path d="M274.58 90.43V97.04" stroke="black" stroke-width="0.5" />
    <path d="M274.58 90.43V94.13" stroke="black" stroke-width="0.5" />
    <path d="M1.66003 130.42V121.6" stroke="black" stroke-width="0.5" />
    <path d="M0.329956 120.27H1.65997" stroke="black" stroke-width="0.5" />
    <path d="M0.859985 121.6V120.27" stroke="black" stroke-width="0.5" />
    <path d="M0.329956 121.6V120.27" stroke="black" stroke-width="0.5" />
    <path d="M0.329956 121.6H1.65997" stroke="black" stroke-width="0.5" />
    <path d="M2.98999 127.71V120.27" stroke="black" stroke-width="0.5" />
    <path d="M1.66003 130.42H22.31" stroke="black" stroke-width="0.5" />
    <path d="M1.21997 53.68V55.27" stroke="black" stroke-width="0.5" />
    <path d="M1.22 55.27H0.559998" stroke="black" stroke-width="0.5" />
    <path d="M0.559998 55.27V54.79" stroke="black" stroke-width="0.5" />
    <path d="M0.559998 54.79H0.359985" stroke="black" stroke-width="0.5" />
    <path d="M0.359985 54.79V54.17" stroke="black" stroke-width="0.5" />
    <path d="M0.359985 54.17H0.559998" stroke="black" stroke-width="0.5" />
    <path d="M0.559998 54.17V53.72" stroke="black" stroke-width="0.5" />
    <path d="M0.559998 53.72V53.68" stroke="black" stroke-width="0.5" />
    <path d="M0.559998 53.68H1.22" stroke="black" stroke-width="0.5" />
  </svg>
</template>
