<template>
  <v-stepper vertical flat>
    <v-stepper-item
      v-for="(message, index) in replayMessages"
      :key="index"
      :step="index + 1"
      :rules="isErrorMessage(message) ? [() => false] : [() => true]"
      complete
      color="primary"
    >
      <p>{{ message.message }}</p>
    </v-stepper-item>
    <v-divider />
  </v-stepper>
</template>
<script setup lang="ts">
import { IMessage } from '@/Core.Service.Domain/Strategies/Strategies/types/replayStrategy.ts';

defineProps<{
  replayMessages: IMessage[];
}>();

const isErrorMessage = (message: IMessage) => {
  console.log(message);
  return message.messageType === 'Error';
};
</script>
<style lang="scss" scoped>
p {
  padding: 5px;
  text-align: left;
  font-size: 16px;
  color: $primary;
}
</style>
