<template>
  <v-card class="monitors-bar">
    <v-app-bar elevation="1" class="px-11">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        {{ computedLabel }}
      </v-app-bar-title>
    </v-app-bar>
  </v-card>
</template>
<script>
export default {
  name: 'TitleBar',
  computed: {
    computedLabel() {
      return this.$route.meta.label || this.label;
    },
  },

  props: {
    label: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.monitors-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 700px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
