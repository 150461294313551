<template>
  <v-chip
    :color="hotspot.color"
    :style="hotspot.chipStyle as CSSProperties"
    :prepend-icon="
      selectedVAVId === hotspot.controlId ? 'mdi-close-circle' : ''
    "
    density="comfortable"
    variant="elevated"
    :disabled="hotspot.disabled ? hotspot.disabled : false"
    @mouseenter="showTooltip(hotspot, suite)"
    @mouseleave="hideTooltip(suite)"
    @mousemove="updateTooltipPosition"
    @click="hotspot.hotSpotAction(hotspot.controlId!)"
  >
    <span class="text-subtitle-1 font-weight-bold">
      {{ hotspot.techInfo.device?.label || hotspot.name }}
    </span>
    <!-- Tooltip content -->
    <v-tooltip
      activator="parent"
      location="start"
      content-class="dialog-tooltip"
    >
      <DigitalTwinTooltip
        :tooltipInfo="vavDevice"
        :color="getToolTipColorByHotspotId(suite)"
      >
        <v-progress-circular
          v-if="isVAVLoading"
          color="#378CDA"
          indeterminate
        />
        <table v-else>
          <tbody>
            <tr
              v-for="(value, key) in renameVAVKeysFromRemapVAVObject(vavDevice!)"
              :key="key"
            >
              <td cols="6" class="text-primary text-subtitle-2 text-left pr-7">
                {{ splitCamelCase(capitalizeFirstLetter(`${key}`)) }}
              </td>
              <td
                cols="6"
                class="text-primary text-subtitle-2 text-left pl-7 font-weight-bold"
              >
                {{ setUnitByValue(key, value) }}
              </td>
            </tr>
          </tbody>
        </table>
      </DigitalTwinTooltip>
    </v-tooltip>
  </v-chip>
</template>

<script setup lang="ts">
import { ref, CSSProperties } from 'vue';
import { useStore } from 'vuex';

import { capitalizeFirstLetter } from '@/Core.Patterns/Adapter/index.ts';
import { splitCamelCase } from '@/Core.Patterns/Factory/index.ts';

import DigitalTwinTooltip from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DigitalTwinTooltip.vue';

import {
  IVAV,
  IHotspot,
} from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

defineProps<{
  suite: {
    controlsList?: IHotspot<Record<string, unknown>>[];
    activeTooltip: number | null;
  };
  hotspot: IHotspot<Record<string, unknown>>;
  selectedVAVId: number | undefined;
}>();

const setUnitByValue = (key: keyof IVAV, value: string | number) => {
  if (typeof value === 'string') {
    return value;
  }
  switch (key) {
    case 'heatingSignal':
      return `${value}%`;
    case 'coolingSignal':
      return `${value}%`;
    case 'needMoreAirflow':
      return `${value}%`;
    case 'openEstimatedDamperPosition':
      return `${value}%`;
    case 'desiredAirflow':
      return `${value} cfm`;
    case 'currentAirflow':
      return `${value} cfm`;
    case 'usageDateTime':
      return `${value} minutes`;
    case 'spaceTemperature':
      return `${value} °F`;
    case 'supplyAirTemperature':
      return `${value} °F`;
    case 'occupiedSetpoint':
      return `${value} °F`;
    case 'maxOccupiedSetpoint':
      return `${value} °F`;
    case 'minOccupiedSetpoint':
      return `${value} °F`;
    default:
      return value;
  }
};

const isVAVLoading = ref(false);
const vavDevice = ref<IVAV>();

const store = useStore();
const showTooltip = async (
  hotspot: IHotspot<Record<string, unknown>>,
  suite: { activeTooltip: number | null }
) => {
  try {
    vavDevice.value = {} as IVAV;
    isVAVLoading.value = true;
    suite.activeTooltip = hotspot.id;

    const { controlId } = hotspot;
    const options = {
      checkCache: false,
      controlId,
    };
    const device = await store.dispatch(
      'DigitalTwinStore/fetchDevice',
      options
    );

    vavDevice.value = device;
  } catch (error) {
    vavDevice.value = {} as IVAV;
    return Promise.reject(error);
  } finally {
    isVAVLoading.value = false;
  }
};

const hideTooltip = (suite: { activeTooltip: number | null }) => {
  suite.activeTooltip = null;
};

const tooltipPosition = ref({ top: '0', left: '0' });
const updateTooltipPosition = (event: MouseEvent) => {
  tooltipPosition.value = {
    top: `${event.clientY + 200}px`,
    left: `${event.clientX - 950}px`,
  };
};

const remapVAVObject = (vav: IVAV) => {
  const keysValuesToRemove = [
    'siteId',
    'siteName',
    'controlId',
    'controlName',
    'controlFriendlyName',
    'usageDateTime',
  ];

  const remappedVAV = { ...vav };
  keysValuesToRemove.forEach((key) => {
    delete remappedVAV[key as keyof IVAV];
  });
  return remappedVAV;
};

const renameVAVKeysFromRemapVAVObject = (vav: IVAV) => {
  const remappedKeys: { [key: string]: string } = {
    siteLocationName: 'location',
    timeSinceLastUpdate: 'lastUpdate',
  };

  const remappedVAV = remapVAVObject(vav);
  const renamedVAV = Object.keys(remappedVAV).reduce((acc, key) => {
    const newKey = remappedKeys[key] || key;
    return { ...acc, [newKey]: remappedVAV[key as keyof IVAV] };
  }, {} as IVAV);

  return renamedVAV;
};

const getToolTipColorByHotspotId = (suite: {
  controlsList?: IHotspot<Record<string, unknown>>[];
  activeTooltip: number | null;
}) => {
  if (suite.controlsList) {
    return suite.controlsList.find(
      (hotspot: { id: number }) => hotspot.id === suite.activeTooltip
    )?.color;
  }
};
</script>
