<template>
  <div>
    <v-btn
      v-if="isComponentMounted && !isCurrentUserVAVsListLoading"
      color="aliceBlue"
      density="default"
      variant="flat"
      :style="getButtonStyleLeft"
      class="toggle-button"
      @click="setLateraMenuOpen(!mini)"
      :icon="mini ? `mdi-chevron-right` : `mdi-chevron-left`"
    />
    <v-navigation-drawer
      v-if="!isCurrentUserVAVsListLoading"
      v-model="drawer"
      :rail="mini"
      permanent
      color="primary"
      :width="notMiniWidth"
      :rail-width="miniWidth"
      :transitionend="(drawerWidth = mini ? miniWidth : notMiniWidth)"
    >
      <v-expansion-panels
        class="border-opacity-0"
        variant="accordion"
        color="primary"
        v-model="openedPanel"
      >
        <v-expansion-panel
          v-for="(item, i) in activeItems"
          :key="i"
          @click="item.action ? item.action() : handleClick(item)"
          class="px-1"
          :hide-actions="!hasChildren(item) || mini"
          elevation="0"
          bg-color="primary"
        >
          <template v-slot:title>
            <div>
              <v-divider v-if="item.separator" class="my-5 border-opacity-0" />
              <v-tooltip location="end">
                <template v-slot:activator="{ props }">
                  <v-icon
                    :icon="item.icon"
                    color="white"
                    class="mr-3"
                    v-bind="mini ? props : null"
                  >
                  </v-icon>
                  <span class="text-white" v-if="renderMenuLabels">
                    {{
                      typeof item.title === 'function'
                        ? item.title()
                        : item.title
                    }}
                  </span>
                </template>
                <div class="custom-tooltip">
                  <span>
                    {{
                      typeof item.title === 'function'
                        ? item.title()
                        : item.title
                    }}
                  </span>
                  <div class="tooltip-arrow"></div>
                </div>
              </v-tooltip>
            </div>
          </template>
          <v-expansion-panel-text
            v-for="(child, index) in getActiveChildren(item)"
            :key="index"
            @click="handleClick(child)"
            :class="renderMenuLabels ? 'pl-5' : 'pl-1'"
          >
            <div class="child-container">
              <v-tooltip location="end">
                <template v-slot:activator="{ props }">
                  <v-icon
                    :icon="child.icon"
                    class="mr-2"
                    color="white"
                    size="x-small"
                    v-bind="mini ? props : null"
                  >
                  </v-icon>
                  <span
                    class="text-white text-subtitle-2"
                    v-if="renderMenuLabels"
                  >
                    {{ child.title }}
                  </span>
                </template>
                <div class="custom-tooltip">
                  <span>
                    {{ child.title }}
                  </span>
                  <div class="tooltip-arrow"></div>
                </div>
              </v-tooltip>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Avatar -->
      <template v-slot:prepend>
        <v-row class="user-info-area" @click="onClickParent = !onClickParent">
          <v-col>
            <v-avatar size="44" class="ml-5 mt-5">
              <v-img :src="userPicture" />
            </v-avatar>
          </v-col>
          <v-col v-if="showUserName">
            <v-list-item-title class="ml-n11 mt-8">
              {{ userName }}
            </v-list-item-title>
          </v-col>
        </v-row>
        <v-divider class="my-6" />
      </template>

      <!-- Contact Us -->
      <template v-slot:append>
        <v-list>
          <v-list-item link>
            <template v-if="mini" v-slot:prepend>
              <v-btn
                class="ml-3 mb-7"
                color="aliceBlue"
                size="x-small"
                icon="true"
              >
                <v-icon> mdi-email-outline </v-icon>
              </v-btn>
            </template>

            <v-menu transition="slide-x-transition">
              <template v-slot:activator>
                <v-list-item class="pl-7 mb-5" v-if="renderContactLabel">
                  <v-btn
                    class="rounded-2"
                    color="aliceBlue"
                    max-width="140px"
                    @click="handleContactUs"
                  >
                    <div>
                      <v-icon start color="primary">mdi-email-outline</v-icon>
                      <span class="font-weight-bold text-primary">
                        Contact Us
                      </span>
                    </div>
                  </v-btn>
                </v-list-item>
              </template>
            </v-menu>
          </v-list-item>
        </v-list>
        <v-card
          flat
          color="primary"
          class="d-flex justify-center pb-3"
          v-if="!smallBreakpoint"
        >
          <span class="text-caption text-white">
            {{ appVersion }}
          </span>
          <div v-show="false">
            {{ commitVersion }}
          </div>
        </v-card>
      </template>
      <AdminMenu
        :onClickParent="onClickParent"
        :style="mini ? 'margin-left: 0' : 'margin-left: -5px'"
        :adminData="{ userPicture, userName }"
        @update:onClickParent="onClickParent = $event"
      />
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, inject, Ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import AdminMenu from '@/Core.UI.Domain/Components/AdminMenu.vue';

import { useAbility } from '@/plugins/useAbility.ts';

const onClickParent = ref(false);
const drawer = ref(true);
const drawerWidth = ref(0);
const notMiniWidth = ref(229);
const openedPanel = ref<number | null>(null);
const buildConfig = inject('BUILD_TAG');

type ButtonsList = {
  title?: string | (() => string);
  icon: string;
  to: string | { name: string };
  active?: () => boolean;
  children?: ButtonsList[];
  separator?: boolean;
  action?: () => void;
};

const { listOfVavSitesForCurrentUser, updateUserAccess } = inject<{
  listOfVavSitesForCurrentUser: Ref<unknown[]>;
  updateUserAccess: () => Promise<void>;
}>('listOfVavSitesForCurrentUser', {
  listOfVavSitesForCurrentUser: ref([]),
  updateUserAccess: async () => {},
});

const router = useRouter();
const items = ref<ButtonsList[]>([
  {
    title: 'Portfolio',
    icon: 'mdi-folder',
    to: '',
    active: () => true,
    children: [
      {
        icon: 'mdi-domain',
        title: 'My Portfolio',
        to: {
          name: 'Portfolio',
        },
        active: () => true,
      },
      {
        icon: 'mdi-message-badge',
        title: 'Notifications',
        to: {
          name: 'AllNotifications',
        },
        active: () => true,
      },
    ],
  },
  {
    title: 'Controls',
    icon: 'mdi-tune-vertical-variant',
    children: [
      {
        icon: 'mdi-thermometer',
        title: 'Thermostats',
        to: { name: 'Thermostats' },
        active: () => true,
      },
      {
        icon: 'mdi-power-settings',
        title: 'Power Controls',
        to: { name: 'PowerControls' },
        active: () => true,
      },
      {
        icon: 'mdi-air-purifier',
        title: 'AHUs',
        to: { name: 'AHUsPortfolio' },
        active: () => true,
      },
      {
        icon: 'mdi-hvac',
        title: 'VAVs',
        to: { name: 'VAVsPortfolio' },
        active: () => canUserAccessVAVsList.value && !hasMenuAccessFail.value,
      },
    ],
    to: '',
    active: () => true,
  },
  {
    title: 'Reports',
    icon: 'mdi-file-chart-outline',
    to: '',
    active: () => true,
    children: [
      {
        title: 'My Reports',
        icon: 'mdi-file-chart-outline',
        to: { name: 'Reports' },
        active: () => canUserReadReports.value,
      },
      {
        title: 'Scheduled Reports',
        icon: 'mdi-invoice-text-clock-outline',
        to: { name: 'ScheduledReports' },
        active: () => canUserReadScheduledReports.value,
      },
    ],
  },
  {
    title: 'Strategies',
    to: '',
    active: () => canUserReadStrategies.value,
    icon: 'mdi-head-lightbulb-outline',
    children: [
      {
        icon: 'mdi-strategy',
        title: 'Site Strategies',
        to: { name: 'StrategiesCommon' },
        active: () => true,
      },
      {
        icon: 'mdi-lightbulb-multiple-outline',
        title: 'Strategy Templates',
        to: { name: 'StrategyTemplates' },
        active: () => canUserReadStrategyTemplates.value,
      },
    ],
  },
  {
    icon: 'mdi-cog-box',
    title: () => (isUserAdmin.value ? 'Admin Menu' : 'User Menu'),
    to: '',
    separator: true,
    active: () => true,
    action: () => {
      onClickParent.value = !onClickParent.value;
    },
  },
]);

// User access
const ability = useAbility();

const isUserAdmin = computed(() => {
  return ability.can('administer', 'control');
});
const canUserReadStrategies = computed(() => {
  return ability.can('read', 'strategies');
});
const canUserReadStrategyTemplates = computed(() => {
  return ability.can('read', 'strategy-templates');
});
const canUserReadReports = computed(() => {
  return ability.can('read', 'reports');
});
const canUserReadScheduledReports = computed(() => {
  return ability.can('read', 'scheduled-reports');
});
const canUserAccessVAVsList = computed(() => {
  return listOfVavSitesForCurrentUser.value.length > 0;
});
// End of User access

const store = useStore();
const isAuthenticated = computed(
  () => store.getters['session/isAuthenticated']
);
const userName = computed(() => store.getters['session/jwtName']);
const userPicture = computed(() => store.getters['session/jwtPicture']);
const mini = computed(() => store.getters['UIStore/getLateraMenuOpen']);
const smallBreakpoint = computed(
  () => store.getters['UIStore/getSmallBreakpoint']
);

// eslint-disable-next-line no-undef
const appVersion = computed(() => VITE_APP_VERSION);
const commitVersion = computed(() =>
  buildConfig == '' || buildConfig == '__BUILD_TAG__'
    ? 'Local version'
    : buildConfig
);

const getButtonStyleLeft = computed(() => `left: ${drawerWidth.value - 25}px`);
const miniWidth = computed(() => (smallBreakpoint.value ? 68 : 85));
const activeItems = computed(() =>
  items.value.filter((item) => item.active && item.active())
);

const getActiveChildren = (item: ButtonsList) => {
  return (
    item.children &&
    item.children.filter((child) => child.active && child.active())
  );
};

const isCurrentUserVAVsListLoading = ref(true);
const hasMenuAccessFail = ref(true);

onMounted(async () => {
  drawerWidth.value = miniWidth.value;
  isComponentMounted.value = true;
  if (smallBreakpoint.value) {
    setLateraMenuOpen(true);
  }
});

watch(
  () => isAuthenticated.value,
  async (val) => {
    if (val) {
      try {
        await updateUserAccess();
        hasMenuAccessFail.value = false;
      } catch (error) {
        hasMenuAccessFail.value = true;
        return Promise.reject(error);
      } finally {
        isCurrentUserVAVsListLoading.value = false;
      }
    }
  },
  { immediate: true }
);

watch(mini, (val) => {
  renderContactLabel.value = !val;
  if (val) {
    showUserName.value = false;
    renderMenuLabels.value = false;
    closeAllPanels();
  } else {
    openPanel(openedPanel.value);
    setTimeout(() => {
      showUserName.value = true;
    }, 120);
    setTimeout(() => {
      renderMenuLabels.value = true;
    }, 200);
  }
});

watch(smallBreakpoint, (val) => {
  store.dispatch('UIStore/setLateraMenuOpen', val);
});

const renderContactLabel = ref(true);

const setLateraMenuOpen = (value: boolean) => {
  store.dispatch('UIStore/setLateraMenuOpen', value);
};
const renderMenuLabels = ref(true);
const showUserName = ref(true);
const isComponentMounted = ref(false);

const closeAllPanels = () => {
  openedPanel.value = null;
};

const openPanel = (index: number | null) => {
  openedPanel.value = index;
};

const hasChildren = (item: ButtonsList) =>
  item.children && item.children.length;

const handleClick = (item: ButtonsList) => {
  if (item.to) {
    router.push(item.to);
  }
};

const handleContactUs = () => {
  router.push({ name: 'ContactForm' });
};
</script>

<style lang="scss" scoped>
.toggle-button {
  border-width: 10px;
  border-color: $primary;
  position: fixed;
  top: 65px;
  width: 44px;
  height: 44px;
  transition: left 0.1s ease-in;
  z-index: 9999;
}

.v-list-item {
  :deep(.v-list-item__overlay) {
    opacity: 0 !important;
  }
  :deep(.v-list-item__spacer) {
    width: 17px !important;
  }
}

.v-list-group__items .v-list-item {
  padding-left: 30px !important;
}

.child-container {
  cursor: pointer;
  width: 100%;
  height: 25px;
  margin: 0px;
  display: block;
  transform: scale(1);
  transition: transform 0.1s ease-out;
}

.child-container:hover {
  transform: scale(1.1);
  transition: transform 0.1s ease-in;
}

.user-info-area {
  cursor: pointer;
}

:deep(.v-overlay__content) {
  background: none !important;
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  background-color: rgba(46, 60, 84, 0.95);
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 17px;
}

/* Tooltip arrow */
.tooltip-arrow {
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid rgba(46, 60, 84, 0.95);
}
</style>
