import { IAHU } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

type AHUState = {
  editAHU: IAHU;
};

const getEditAHU = (state: AHUState) => {
  return state.editAHU;
};

export default {
  getEditAHU,
};
