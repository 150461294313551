import { markRaw } from 'vue';

import VAV263Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciE/VAV263Drawing.vue';
import VAV270Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciE/VAV270Drawing.vue';
import VAV271Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciE/VAV271Drawing.vue';
import VAV272Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciE/VAV272Drawing.vue';
import VAV273Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciE/VAV273Drawing.vue';
import VAV274Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciE/VAV274Drawing.vue';
import VAV275Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciE/VAV275Drawing.vue';
import VAV276Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_2nd_Floor/SuitesVAVs/Suite2fciE/VAV276Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

import { IHotspot } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList: IHotspot<Record<string, unknown>>[] = [
  {
    id: 0,
    name: 'VAV 2-72',
    controlId: 2297,
    component: markRaw(VAV272Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-72',
      },
    },
    style: {
      position: 'absolute',
      top: '6.1%',
      left: '32.6%',
      width: '35%',
      height: '35%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '20%',
      left: '47%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 2-76',
    controlId: 2301,
    component: markRaw(VAV276Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-76',
      },
    },
    style: {
      position: 'absolute',
      top: '6%',
      left: '16.7%',
      width: '64.5%',
      height: '64.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '50%',
      left: '47%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 2-63',
    controlId: 2234,
    component: markRaw(VAV263Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-63',
      },
    },
    style: {
      position: 'absolute',
      top: '51.15%',
      left: '4.6%',
      width: '49.3%',
      height: '49.3%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '75%',
      left: '25%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 2-70',
    controlId: 2295,
    component: markRaw(VAV270Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-70',
      },
    },
    style: {
      position: 'absolute',
      top: '63%',
      left: '34.9%',
      width: '30.6%',
      height: '30.6%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '75%',
      left: '47%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 4,
    name: 'VAV 2-73',
    controlId: 2298,
    component: markRaw(VAV273Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-73',
      },
    },
    style: {
      position: 'absolute',
      top: '39.4%',
      left: '58.65%',
      width: '50.6%',
      height: '50.6%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '75%',
      left: '80%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 5,
    name: 'VAV 2-74',
    controlId: 2299,
    component: markRaw(VAV274Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-74',
      },
    },
    style: {
      position: 'absolute',
      top: '6%',
      left: '56.3%',
      width: '47.5%',
      height: '47.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '20%',
      left: '77%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 6,
    name: 'VAV 2-75',
    controlId: 2300,
    component: markRaw(VAV275Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-75',
      },
    },
    style: {
      position: 'absolute',
      top: '5.8%',
      left: '67.05%',
      width: '64%',
      height: '64%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '20%',
      left: '95%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 7,
    name: 'VAV 2-71',
    controlId: 2296,
    component: markRaw(VAV271Hotspot),
    techInfo: {
      device: {
        label: 'VAV 2-71',
      },
    },
    style: {
      position: 'absolute',
      top: '35.1%',
      left: '79.2%',
      width: '55.1%',
      height: '55.1%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '59%',
      left: '104%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
