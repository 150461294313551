//Dashboard
//Site Slicer - vwSite - SiteName
//Monitor Name Slicer - vwSensorNames - MonitorFriendlyName
//Overtime
//Date Slicer - vwReportingDateTime - Date

import constants from '../constants';

let firstSiteName = '';

const setSlicers = async (reportParameters, visuals) => {
  await setSiteSlicerVisual(reportParameters, visuals);
  // await setMonitorNameSlicerVisual(reportParameters, visuals);
};

const setOvertimeSlicer = async (reportParameters, visuals) => {
  await setDateSlicerVisual(reportParameters, visuals);
};

const setSensorOverviewSlicer = async (reportParameters, visuals) => {
  try {
    const siteData = getSiteDataBySiteName(reportParameters);

    const sensorSlicerVisual = visuals.find((visual) => {
      return visual.type === 'slicer' && visual.title === 'Sensor Slicer';
    });
    // Next lines will console the table and column from the slicer visual.
    // const state = await sensorSlicerVisual.getSlicerState();
    // console.log('date state', state);
    const trimmed = siteData.SensorName.trim();
    const values = [trimmed];

    if (!siteData) {
      const sensorSlicerState = await sensorSlicerVisual.getSlicerState();

      await sensorSlicerVisual.setSlicerState(sensorSlicerState);
    } else {
      await sensorSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: constants.BASIC_FILTER,
            target: {
              table: 'vwSensorNames',
              column: 'MonitorFriendlyName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const setSiteSlicerVisual = async (reportParameters, visuals) => {
  try {
    const siteData = getSiteDataBySiteName(reportParameters);
    const { SiteName } = reportParameters;

    const siteSlicerVisual = visuals.filter((visual) => {
      return visual.type === 'slicer' && visual.title === 'Site Slicer';
    })[0];

    const values = siteData ? [siteData.SiteName] : [SiteName];

    if (!SiteName && !siteData) {
      const siteSlicerState = await siteSlicerVisual.getSlicerState();

      if (siteSlicerVisual) {
        await siteSlicerVisual.setSlicerState(siteSlicerState);
      }
    } else {
      await siteSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: constants.BASIC_FILTER,
            target: {
              table: 'vwSite',
              column: 'SiteName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const setDateSlicerVisual = async (reportParameters, visuals) => {
  try {
    const StartDate = new Date(reportParameters.StartDate);
    const EndDate = new Date(reportParameters.EndDate);

    const dateSlicerFilter = {
      $schema: constants.ADVANCED_FILTER,
      target: {
        table: 'vwReportingDateTime',
        column: 'DateTime',
      },
      logicalOperator: 'And',
      conditions: [
        {
          operator: 'GreaterThanOrEqual',
          value: StartDate.toISOString(),
        },
        {
          operator: 'LessThanOrEqual',
          value: EndDate.toISOString(),
        },
      ],
    };
    const dateSlicerVisual = visuals.filter((visual) => {
      return visual.type === 'slicer' && visual.title === 'Date Slicer';
    })[0];

    if (dateSlicerVisual) {
      await dateSlicerVisual.setSlicerState({
        filters: [dateSlicerFilter],
      });
    } else {
      await dateSlicerVisual.setSlicerState({
        filters: [dateSlicerFilter],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Retrieves the first site data based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @param {String} sensorName - The Sensor name.
 * @returns {Array} - The first site data.
 */
const getSiteDataBySiteName = (reportParameters, sensorName = null) => {
  const { SiteName, SiteUnitOfMeasureSensors } = reportParameters;
  if (SiteUnitOfMeasureSensors) {
    const parsedData = JSON.parse(SiteUnitOfMeasureSensors);

    const trimmedSiteName = firstSiteName.length
      ? firstSiteName
      : SiteName.trim().toLowerCase();

    const response = parsedData.find((data) => {
      if (sensorName) {
        return (
          data.SiteName.trim().toLowerCase() === trimmedSiteName &&
          data.SensorName.trim().toLowerCase() ===
            sensorName.trim().toLowerCase()
        );
      }
      return data.SiteName.trim().toLowerCase() === trimmedSiteName;
    });

    return !response ? parsedData[0] : response;
  }

  return null;
};

export default {
  setSlicers,
  setOvertimeSlicer,
  setSensorOverviewSlicer,
};
