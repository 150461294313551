import actions from '@/Core.Service.Domain/ViewSupport/Notifications/Store/actions.js';
import getters from '@/Core.Service.Domain/ViewSupport/Notifications/Store/getters.js';
import mutations from '@/Core.Service.Domain/ViewSupport/Notifications/Store/mutations.js';

const state = {
  notificationsListBySite: [],
  notificationsCounterBySite: [],
  notificationsSearchTerm: '',
  notificationsIsLoading: true,
  sitesList: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
