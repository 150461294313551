import { Module } from 'vuex';
import API from '@/Core.Service.Domain/Controls/DigitalTwin/API/index.ts';
import { IVAV } from '@/Core.Service.Domain/Controls/DigitalTwin/types/index';

interface DigitalTwinState {
  devices: IVAV[];
  componentsCachedList: string[];
}

let currentController: AbortController | null = null;
const signalDataMap = new WeakMap<AbortSignal, { controlId: number }>();

function setDataForSignal(signal: AbortSignal, data: { controlId: number }) {
  signalDataMap.set(signal, data);
}

function getDataForSignal(signal: AbortSignal) {
  return signalDataMap.get(signal);
}

export const digitalTwinModule: Module<DigitalTwinState, unknown> = {
  namespaced: true,
  state: () => ({
    devices: [],
    componentsCachedList: [],
  }),
  getters: {
    getDeviceById: (state) => (id: number) => {
      return state.devices.find((device) => device.controlId === id);
    },

    isComponentCached: (state) => (componentName: string) => {
      return state.componentsCachedList.includes(componentName);
    },
  },
  mutations: {
    SET_DEVICE(state, device: IVAV) {
      const existingIndex = state.devices.findIndex(
        (d) => d.controlId === device.controlId
      );
      if (existingIndex >= 0) {
        state.devices[existingIndex] = device;
      } else {
        state.devices.push(device);
      }
    },

    REMOVE_DEVICE_FROM_CACHE(state, controlId: number) {
      state.devices = state.devices.filter(
        (device) => device.controlId !== controlId
      );
    },
  },
  actions: {
    /**
     * Check if the device is cached already. If not, fetch it from the API,
     * save to store, and return it. Otherwise, return the cached device.
     * Also, abort the previous request if it's still pending but first, checks if the request is for the same device.
     */
    async fetchDevice(
      { commit, getters },
      options: { controlId: number; checkCache?: boolean }
    ) {
      const { controlId, checkCache = true } = options;

      if (
        currentController &&
        getDataForSignal(currentController.signal)?.controlId !== controlId
      ) {
        currentController.abort();
      }

      currentController = new AbortController();
      const { signal } = currentController;
      setDataForSignal(signal, { controlId });

      const cachedDevice = getters.getDeviceById(controlId);
      if (cachedDevice && checkCache) {
        return cachedDevice;
      }

      try {
        const device = await API.getVAVById(controlId, { signal });
        commit('SET_DEVICE', device);
        return device;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    removeDeviceFromCache({ commit }, controlId: number) {
      commit('REMOVE_DEVICE_FROM_CACHE', controlId);
    },

    addComponentToCachedList({ state }, componentName: string) {
      state.componentsCachedList.push(componentName);
    },

    clearComponentsCache({ state }) {
      state.componentsCachedList = [];
    },
  },
};
