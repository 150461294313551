<template>
  <v-container grid-list-xl style="max-width: 1540px">
    <v-progress-linear :active="loading" :indeterminate="loading" absolute />

    <form @submit.prevent="submit">
      <v-sheet elevation="2" class="pa-4">
        <v-row>
          <v-col>
            <h1>Model Detail</h1>
          </v-col>
        </v-row>
        <Form ref="obs2" v-slot="{ invalid }">
          <v-row>
            <v-col>
              <Field name="name" rules="required|duplicate_name">
                <template v-slot="{ errors }">
                  <v-text-field
                    :readonly="!$can('create', 'analytics')"
                    v-model="analyticModel.name"
                    label="Analysis Model Name"
                    name="name"
                    :error-messages="errors"
                    required
                    ref="modelNameInput"
                    variant="outlined"
                  />
                </template>
              </Field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto" md sm="12">
              <validated-select-field
                :items="resourceTypeOptions"
                :item-value="'value'"
                :item-title="'text'"
                :readonly="!$can('delete', 'analytics')"
                :value="resourceType"
                label="Resource Type"
                hint="What resource type do you want to analyze"
                persistent-hint
                @change-value="setEndUseOptions"
                ref="resourceTypeSelect"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto" md sm="12">
              <validated-select-field
                ref="analysisTypeSelect"
                :items="analysisTypes"
                :item-value="'id'"
                :item-title="'analysisTypeName'"
                :readonly="!$can('delete', 'analytics')"
                :value="analyticModel.analysisType.id"
                label="Select Analysis Type"
                hint="What type of analysis do you want to perform"
                persistent-hint
                @change-value="setAnalysisType"
              />
            </v-col>

            <v-col cols="auto" md="6" sm="12">
              <validated-select-field
                ref="endUseSelect"
                multiple
                chips
                :items="endUseOptions"
                :item-value="'value'"
                :item-title="'text'"
                :readonly="!$can('delete', 'analytics')"
                :value="selectedEndUses"
                label="Select End Use(s)"
                persistent-hint
                hint="Select end uses for analysis"
                @change-value="setEndUses"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto" md="6" sm="12">
              <validated-select-field
                ref="factorSelect"
                multiple
                chips
                :items="factorOptions"
                :item-value="'value'"
                :item-title="'text'"
                :value="selectedFactors"
                item-disabled="disable"
                label="Select Factor(s)"
                persistent-hint
                hint="Select factors for analysis"
                @change-value="setFactors"
              />
            </v-col>
            <v-col cols="auto" md="6" sm="12">
              <validated-select-field
                ref="nreSelect"
                multiple
                chips
                :items="nreOptions"
                :item-title="'name'"
                :item-value="'id'"
                :value="analyticModel.nonRoutineEvents"
                label="Select NRE(s)"
                persistent-hint
                hint="Select NRE(s) for analysis"
                return-object
                @input="handleSetNREs"
              />
            </v-col>
          </v-row>
          <v-layout wrap justify-space-between>
            <div>
              <v-list-subheader class="pl-0">
                Basis for Cooling Degree Days
              </v-list-subheader>
              <v-row>
                <v-slider
                  v-model="analyticModel.degreeDaysBaseCooling"
                  :min="40"
                  :max="120"
                  hide-details
                >
                  <template v-slot:append>
                    <Field
                      name="degree days base cooling"
                      rules="required|valid_degreeDays_range"
                    >
                      <template v-slot="{ errors }">
                        <v-text-field
                          v-model="analyticModel.degreeDaysBaseCooling"
                          name="degree days base cooling"
                          class="mt-0 pt-0"
                          hide-details
                          type="number"
                          style="width: 60px"
                          :error-messages="errors"
                          required
                        />
                      </template>
                    </Field>
                  </template>
                </v-slider>
              </v-row>
              <div class="text-red text-caption">
                <!-- {{ errors.first('degree days base cooling') }} -->
              </div>
            </div>

            <div>
              <v-list-subheader class="pl-0"
                >Basis for Heating Degree Days</v-list-subheader
              >
              <v-row>
                <v-slider
                  v-model="analyticModel.degreeDaysBaseHeating"
                  :min="40"
                  :max="120"
                  hide-details
                >
                  <template v-slot:append>
                    <Field
                      name="degree days base heating"
                      rules="required|valid_degreeDays_range"
                    >
                      <template v-slot="{ errors }">
                        <v-text-field
                          v-model="analyticModel.degreeDaysBaseHeating"
                          name="degree days base heating"
                          class="mt-0 pt-0"
                          hide-details
                          type="number"
                          style="width: 60px"
                          :error-messages="errors"
                          required
                        />
                      </template>
                    </Field>
                  </template>
                </v-slider>
              </v-row>
              <div class="text-red text-caption">
                <!-- {{ errors.first('degree days base heating') }} -->
              </div>
            </div>
          </v-layout>
          <start-end-date-picker
            name="date range"
            rules="required|valid_date_range"
            v-model="dateRange"
            @change="setDateRange"
            date-format="yyyy-MM-dd"
            ref="startEndDatePicker"
          ></start-end-date-picker>
          <v-row>
            <label class="pa-3">Comments</label>
            <v-textarea
              rows="2"
              clearable
              clear-icon="mdi-close-circle"
              variant="outlined"
              auto-grow
              v-model="analyticModel.comment"
              class="pr-3"
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-if="$can('create', 'analytics')"
                @click="validateModel"
                color="primary"
                class="mr-5"
                :disabled="invalid"
                >Validate</v-btn
              >
              <v-btn
                @click="downloadZip"
                title="download the raw data and results for this model in csv format"
                color="primary"
                class="mr-5"
                :disabled="invalid || !isValidModel"
                >Download Raw Data</v-btn
              >

              <v-btn
                v-if="$can('create', 'analytics')"
                type="submit"
                color="secondary"
                class="mr-5"
                :disabled="invalid || !isValidModel"
                >Save</v-btn
              >
              <v-btn
                v-if="$can('create', 'analytics')"
                @click="saveAs"
                color="accent"
                class="mr-5"
                :disabled="invalid || !isValidModel"
                >Save As</v-btn
              >
              <v-btn @click="handleCancelCrud">Cancel</v-btn>
            </v-col>
          </v-row>

          <v-row justify="center">
            <stepper-validate
              :invalid="invalid"
              :modelName="analyticModel.name"
              :factors="selectedFactors"
              :startDateTime="dateRange.startDateTime"
              :endDateTime="dateRange.endDateTime"
              :dateRange="dateRange"
              :nameValidationResult="nameValidationResult"
              :dataValidationResult="dataValidationResult"
              @fixmodel="fixModelErrors"
              ref="stepper"
            ></stepper-validate>
          </v-row>

          <v-row justify="center">
            <v-dialog v-model="showConfirm" width="500">
              <v-card>
                <v-card-title class="text-h5"
                  >Enter new name for model</v-card-title
                >
                <v-card-text>
                  <v-container>
                    <v-text-field v-model="saveAsName"></v-text-field>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="handleCancel"
                    variant="text"
                    colr="blue darken-1"
                    >Cancel</v-btn
                  >
                  <v-btn @click="handleOk" variant="text" color="blue-darken-1"
                    >Ok</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </Form>
      </v-sheet>
    </form>

    <span class="px-4 text-caption text-primary">
      {{ $appOldVersion }}
    </span>
  </v-container>
</template>
<style>
.v-input__icon.v-input__icon--clear {
  margin-top: 2px !important;
}
</style>
<script>
import { Field, Form, defineRule } from 'vee-validate';
import moment from 'moment';
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker';
import ValidatedSelectField from '@/components/Fields/ValidatedSelectField';
import StepperValidate from './StepperValidate.vue';
import api from '../../analytics_models/_api';

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: 'AnalyticsModelDetailsModule',
  components: {
    Field,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    'start-end-date-picker': StartEndDatePicker,
    'validated-select-field': ValidatedSelectField,
    'stepper-validate': StepperValidate,
  },

  data() {
    return {
      analyticModels: [],
      analyticModel: {
        id: null,
        analyticFactors: [],
        analyticType: {
          id: 1,
          analyticTypeName: 'MandV',
        },
        analysisType: {
          id: 0,
          analysisTypeName: 'Linear Regression',
        },
        degreeDaysBaseCooling: 65,
        degreeDaysBaseHeating: 65,
        startDateTime: null,
        endDateTime: null,
        nonRoutineEvents: [],
      },
      error: null,
      loading: true,

      name: 'Model Detail',

      dateRange: {},
      dataValidationResult: null,
      nameValidationResult: null,

      nameIndex: 1,
      energyIndex: 2,
      weatherIndex: 3,
      factorIndex: 4,

      isValidModel: false,
      selectedSite: null,
      selectedEndUses: [],
      selectedFormula: '',
      selectedAnalysisTypeId: null,
      resourceType: '',
      selectedFactors: [],

      endUses: [],
      endUseOptions: [],
      analysisTypes: [],
      nreOptions: [],
      formulaOptions: [
        { value: '1', text: 'Production Capacity' },
        { value: '2', text: 'Adjusted Occupancy' },
      ],
      factorOptions: [],
      resourceTypeOptions: [
        { value: 'Electricity', text: 'Electricity' },
        { value: 'Gas', text: 'Gas' },
        { value: 'Water', text: 'Water' },
        { value: 'Solar', text: 'Solar' },
      ],

      startMenu: false,
      endMenu: false,
      showChart: false,
      chartOptions: {
        credits: { enabled: false },
        chart: {
          type: 'line',
          zoomType: 'x',
          borderWidth: 1,
          borderColor: '#efefef',
        },
        title: {
          text: 'Model Predictions',
        },
        subtitle: {
          text: 'R2: N/A - NMBE: N/A - CV(RMSE): N/A',
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          labels: {
            rotation: -45,
            formatter: function () {
              if (!this.value) return '';

              return moment(new Date(this.value)).format('YYYY-M-D');
            },
          },
        },
        yAxis: {
          title: {
            text: 'kWh',
          },
        },
        tooltip: {
          pointFormat: '{point.y}',
          valueSuffix: 'kWh',
          valueDecimals: '4',
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [{}],
      },

      defaultOptions: {},
      showConfirm: false,
      saveAsName: null,
    };
  },

  async beforeCreate() {},

  async mounted() {
    await this.getModel();
  },

  async created() {
    await this.getFactors();
    await this.getAnalysisTypes();
    this.registerCustomValidators();

    // this.$validator.attach;
  },

  computed: {
    ...mapGetters('session', ['jwtName', 'jwtPicture']),
  },

  methods: {
    ...mapActions({}),

    getAvatar() {
      return this.jwtPicture;
    },

    setFormula() {},

    setDateRange(e) {
      this.analyticModel.startDateTime = e.startDateTime;
      this.analyticModel.endDateTime = e.endDateTime;
    },

    saveAs() {
      this.showConfirm = true;
      this.saveAsName = this.analyticModel.name + ' (copy)';
    },
    handleOk() {
      this.showConfirm = false;
      this.analyticModel.id = null;
      this.analyticModel.name = this.saveAsName;
      this.postModel();
    },
    handleCancel() {
      this.saveAsName = this.analyticModel.name;
      this.showConfirm = false;
    },

    async postModel() {
      let isValid = await this.$refs.obs2.validate();
      if (isValid) {
        await api
          .createAnalyticModel(this.selectedSite, this.analyticModel)
          .then(() => {
            this.$toast.show('Successfully saved as model', null, 'success');
            this.$router.push({ name: 'Models' });
          })
          .catch((error) => {
            return Promise.reject(error);
          })
          .finally(() => (this.loading = false));
      }
    },

    submit() {
      this.putModel();
    },

    async getModel() {
      await api
        .getAnalyticModel(this.$route.params.id)
        .then((response) => {
          this.analyticModel = response;
          this.selectedSite = this.analyticModel.siteId;

          if (this.analyticModel.endUses.length > 0) {
            const endUse = this.analyticModel.endUses[0];
            this.resourceType = endUse.resourceType;
          } else {
            this.resourceType = 'Electricity';
          }

          this.dateRange = {
            startDateTime: new Date(this.analyticModel.startDateTime)
              .toISOString()
              .substring(0, 10),
            endDateTime: new Date(this.analyticModel.endDateTime)
              .toISOString()
              .substring(0, 10),
          };
          this.selectedFactors = this.mapanalyticFactors(
            this.analyticModel.analyticFactors
          );
          //repopulate the end use lookup
          this.getEndUses();
          this.getNREs();
          //clear success styling after loading valid data
          requestAnimationFrame(() => {
            // this.$refs.obs2.reset();
          });

          this.isValidModel = this.checkValidModel();
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async putModel() {
      let isValid = await this.$refs.obs2.validate();

      if (isValid) {
        await api
          .updateAnalyticModel(this.selectedSite, this.analyticModel)
          .then(() => {
            this.$toast.show('Model updated', null, 'success');
            this.$router.push({ name: 'Models' });
          })
          .catch((error) => {
            return Promise.reject(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    //ENDUSES - START
    async getEndUses() {
      await api
        .getEndUses(this.selectedSite)
        .then((response) => {
          this.endUses = response;
          this.endUseOptions = this.mapEndUseLookup(
            this.endUses,
            this.resourceType
          );
          this.selectedEndUses = this.mapAnalyticsEndUses(
            this.analyticModel.endUses
          );
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => (this.loading = false));
    },

    setEndUseOptions(selectedResource) {
      this.endUseOptions = this.mapEndUseLookup(this.endUses, selectedResource);
    },

    mapEndUseLookup(endUses, resourceTypeName) {
      const makeOptionItems = (item) => {
        if (item.resourceType === resourceTypeName) {
          return {
            value: item.endUseId,
            text: item.endUseName,
            endUseUnitOfMeasureName: item.endUseUnitOfMeasureName,
            resourceType: item.resourceType,
          };
        }
      };

      const eul = endUses.map(makeOptionItems);
      return eul.filter((e) => {
        return e != undefined;
      });
    },

    setEndUses(item) {
      this.analyticModel.endUses = this.mapSelectedEndUses(item);
    },

    mapSelectedEndUses(endUses) {
      const makeEndUse = (selectedItem) => {
        const selectedEndUseOption = this.endUseOptions.find(
          (o) => o.value === selectedItem
        );

        return {
          endUseId: selectedItem,
          endUseName: selectedEndUseOption.text,
          resourceType: 'Electricity', //get this value from the resource type selection
          endUseUnitOfMeasureName: selectedEndUseOption.endUseUnitOfMeasureName,
          selected: true,
          siteId: this.selectedSite, //temporary
        };
      };

      const eu = endUses.map(makeEndUse);
      return eu;
    },

    mapAnalyticsEndUses(enduses) {
      const extractEndUse = (item) => {
        const endUseToExtract = this.endUseOptions.find(
          (o) => o.text === item.endUseName
        );
        return endUseToExtract.value;
      };

      const af = enduses.map(extractEndUse);
      return af;
    },
    //ENDUSES - END

    //TYPE - START
    async getAnalysisTypes() {
      await api
        .getAnalysisTypes()
        .then((response) => {
          this.analysisTypes = response;
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => (this.loading = false));
    },

    setAnalysisType(item) {
      if (this.analysisTypes.length > 0) {
        this.analyticModel.analysisType = {
          id: item,
          analysisTypeName: this.analysisTypes.find((o) => o.id === item).text,
        };
      }
    },
    //TYPE - END

    //FACTORS - START
    async getFactors() {
      api
        .getAnalyticFactors()
        .then((response) => {
          this.factorOptions = this.mapFactorLookup(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => (this.loading = false));
    },

    mapFactorLookup(endUses) {
      const makeOptionItems = (item) => {
        return {
          value: item.id,
          text: item.factorName,
          analyticFactorName: item.analyticFactorName,
        };
      };
      const fl = endUses.map(makeOptionItems);
      return fl;
    },

    setFactors(items) {
      this.analyticModel.analyticFactors = this.mapSelectedFactors(items);
    },

    mapSelectedFactors(factors) {
      const makeFactor = (selectedItem) => {
        const selectedFactorOption = this.factorOptions.find(
          (o) => o.value === selectedItem
        );
        return {
          id: selectedItem,
          analyticFactorName: selectedFactorOption.analyticFactorName,
          factorName: selectedFactorOption.text,
          selected: true,
        };
      };

      const sf = factors.map(makeFactor);
      return sf;
    },

    mapanalyticFactors(factors) {
      const extractFactor = (item) => {
        const factorToExtract = this.factorOptions.find(
          (o) => o.analyticFactorName == item.analyticFactorName
        );
        return factorToExtract?.value;
      };

      const af = factors.map(extractFactor);
      return af;
    },
    //FACTORS - END

    //NRES - START
    async getNREs() {
      api
        .getNREs(this.selectedSite)
        .then((response) => {
          this.nreOptions = response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    handleSetNREs(selectedNREs) {
      this.analyticModel.nonRoutineEvents = selectedNREs.map((x) => {
        return { ...x, selected: true };
      });
    },
    //NRES -END

    async registerCustomValidators() {
      defineRule('duplicate_name', (value, [field]) => {
        let existingNames = this.analyticModels.map((a) => a.name);
        let valid = true;
        existingNames = existingNames.filter((n) => {
          return n !== value;
        });

        valid = existingNames.indexOf(value) === -1;
        if (!valid) {
          return `The ${field} is already used by another model.  Enter a different name.`;
        }

        return valid;
      });

      defineRule('valid_degreeDays_range', (value, [field]) => {
        const withinRange = value > 40 && value < 120;
        if (!withinRange) {
          return `Value for ${field} must be greater than 40 and less than 120.`;
        }
        return withinRange;
      });

      defineRule('formula_or_factors', (value, [field]) => {
        const noFactors = this.selectedFactors.length === 0;
        if (!noFactors) {
          return `You have factors selected.  Clear factors before selecting the ${field} formula`;
        }
        return noFactors;
      });
    },

    mapChartSeriesData(predictions) {
      const makeSeriesDataPoint = (item, property) => {
        const unixDate = moment(item.date).valueOf();
        const value = item[property];
        return [unixDate, value];
      };
      const seriesData = [
        {
          name: 'Actual',
          data: predictions.map((p) => makeSeriesDataPoint(p, 'kWh')),
        },
        {
          name: 'Predicted',
          data: predictions.map((a) => makeSeriesDataPoint(a, 'prediction')),
        },
      ];
      return seriesData;
    },

    checkValidModel() {
      this.isValidModelName = this.analyticModel.name !== null;
      this.isValidSite = this.analyticModel.siteId !== null;
      this.isValidResourceType = this.resourceType !== null;
      this.isValidAnalysisType = this.analyticModel.analysisType !== null;
      this.isValidEndUse = this.analyticModel.endUses.length > 0;
      this.isValidFactor = true;
      this.isValidDateRange =
        this.analyticModel.startDateTime < this.analyticModel.endDateTime;

      const isValid =
        this.isValidModelName &&
        this.isValidSite &&
        this.isValidResourceType &&
        this.isValidAnalysisType &&
        this.isValidEndUse &&
        this.isValidFactor &&
        this.isValidDateRange;
      return isValid;
    },

    ///Validate Dialog
    async validateModel() {
      let isValid = await this.$refs.obs2.validate();

      if (!isValid) {
        return;
      }
      this.nameValidationResult = await api.validateAnalyticModelNameForUpdate(
        this.analyticModel.siteId,
        this.analyticModel.id,
        this.analyticModel.name
      );

      this.dataValidationResult = await api.validateAnalyticModelDataForRange(
        this.analyticModel.id,
        this.dateRange.startDateTime,
        this.dateRange.endDateTime
      );

      this.isValidFactor = this.selectedFactors.length > 0;
      this.isValidModel =
        this.dataValidationResult.validation &&
        this.nameValidationResult &&
        this.isValidFactor;
      this.$refs.stepper.validateModel();
    },

    fixModelErrors(validationItem) {
      let step = validationItem.item;
      let newDateRange = validationItem.newDateRange;
      switch (step) {
        case this.nameIndex:
          this.$nextTick().then(() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            this.$refs.modelNameInput.$refs.input.focus();
          });
          break;
        case this.factorIndex:
          this.$nextTick().then(() => {
            this.$refs.factorSelect.$refs.validateSelectField.focus();
          });
          break;
        case this.weatherIndex:
        case this.energyIndex:
          this.$nextTick().then(() => {
            this.dateRange = newDateRange;
            this.$refs.startEndDatePicker.$refs.startPicker.focus();
          });
          break;
        default:
          break;
      }

      this.isValidModel = false;
    },

    async downloadZip() {
      try {
        this.loading = true;
        const zipStream = await api.downLoadAnalyticModelZip(
          this.selectedSite,
          this.analyticModel
        );
        const fileURL = window.URL.createObjectURL(new Blob([zipStream]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.analyticModel.name + '.zip');
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        return Promise.reject(error);
      }
      this.loading = false;
    },

    handleCancelCrud() {
      this.$router.push({ name: 'Models' });
    },
  },
};
</script>
