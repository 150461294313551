<template>
  <svg
    width="219"
    height="133"
    viewBox="0 0 219 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="219" height="133" fill="white" />
    <path d="M40.33 113.98H38.96" stroke="black" stroke-width="0.5" />
    <path d="M24.38 22.27H23.85" stroke="black" stroke-width="0.5" />
    <path d="M23.85 22.86V23.48" stroke="black" stroke-width="0.5" />
    <path d="M23.85 23.48H24.38" stroke="black" stroke-width="0.5" />
    <path d="M24.38 23.48V22.27" stroke="black" stroke-width="0.5" />
    <path d="M-0.130005 23.48V22.59" stroke="black" stroke-width="0.5" />
    <path d="M0.429993 23.48V22.59" stroke="black" stroke-width="0.5" />
    <path d="M19 22.86V23.48" stroke="black" stroke-width="0.5" />
    <path d="M19 23.48H19.54" stroke="black" stroke-width="0.5" />
    <path d="M19.54 23.48V22.86" stroke="black" stroke-width="0.5" />
    <path d="M19.54 22.27H19" stroke="black" stroke-width="0.5" />
    <path d="M23.85 23.48H19.54" stroke="black" stroke-width="0.5" />
    <path d="M19 23.48H0.429993" stroke="black" stroke-width="0.5" />
    <path d="M19 22.27H0.429993" stroke="black" stroke-width="0.5" />
    <path d="M23.85 22.27H19.54" stroke="black" stroke-width="0.5" />
    <path d="M-4.44 22.59H23.85" stroke="black" stroke-width="0.5" />
    <path d="M23.85 22.86H-4.44" stroke="black" stroke-width="0.5" />
    <path d="M23.85 22.86V22.59" stroke="black" stroke-width="0.5" />
    <path
      d="M-4.44 22.86H23.85L-4.44 22.59V22.86Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M23.85 22.86V22.59H-4.44L23.85 22.86Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M-0.130005 22.27H0.429993" stroke="black" stroke-width="0.5" />
    <path d="M-0.130005 23.48H0.429993" stroke="black" stroke-width="0.5" />
    <path d="M190.9 22.27H190.37" stroke="black" stroke-width="0.5" />
    <path d="M190.37 22.86V23.48" stroke="black" stroke-width="0.5" />
    <path d="M190.37 23.48H190.9" stroke="black" stroke-width="0.5" />
    <path d="M190.9 23.48V22.27" stroke="black" stroke-width="0.5" />
    <path d="M161.55 23.48H162.08" stroke="black" stroke-width="0.5" />
    <path d="M162.08 23.48V22.86" stroke="black" stroke-width="0.5" />
    <path d="M166.39 23.48V22.59" stroke="black" stroke-width="0.5" />
    <path d="M166.95 23.48V22.59" stroke="black" stroke-width="0.5" />
    <path d="M162.08 22.27H161.55" stroke="black" stroke-width="0.5" />
    <path d="M161.55 22.27V23.48" stroke="black" stroke-width="0.5" />
    <path d="M185.53 22.86V23.48" stroke="black" stroke-width="0.5" />
    <path d="M185.53 23.48H186.06" stroke="black" stroke-width="0.5" />
    <path d="M186.06 23.48V22.86" stroke="black" stroke-width="0.5" />
    <path d="M186.06 22.27H185.53" stroke="black" stroke-width="0.5" />
    <path d="M190.37 23.48H186.06" stroke="black" stroke-width="0.5" />
    <path d="M185.53 23.48H166.95" stroke="black" stroke-width="0.5" />
    <path d="M166.39 23.48H162.08" stroke="black" stroke-width="0.5" />
    <path d="M185.53 22.27H166.95" stroke="black" stroke-width="0.5" />
    <path d="M166.39 22.27H162.08" stroke="black" stroke-width="0.5" />
    <path d="M190.37 22.27H186.06" stroke="black" stroke-width="0.5" />
    <path d="M162.08 22.59H190.37" stroke="black" stroke-width="0.5" />
    <path d="M190.37 22.86H162.08" stroke="black" stroke-width="0.5" />
    <path d="M190.37 22.86V22.59" stroke="black" stroke-width="0.5" />
    <path
      d="M162.08 22.86H190.37L162.08 22.59V22.86Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M190.37 22.86V22.59H162.08L190.37 22.86Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M162.08 22.86V22.59" stroke="black" stroke-width="0.5" />
    <path d="M166.39 22.27H166.95" stroke="black" stroke-width="0.5" />
    <path d="M166.39 23.48H166.95" stroke="black" stroke-width="0.5" />
    <path d="M212.79 23.48H213.32" stroke="black" stroke-width="0.5" />
    <path d="M213.32 23.48V22.86" stroke="black" stroke-width="0.5" />
    <path d="M217.63 23.48V22.59" stroke="black" stroke-width="0.5" />
    <path d="M218.19 23.48V22.59" stroke="black" stroke-width="0.5" />
    <path d="M213.32 22.27H212.79" stroke="black" stroke-width="0.5" />
    <path d="M212.79 22.27V23.48" stroke="black" stroke-width="0.5" />
    <path d="M236.76 23.48H218.19" stroke="black" stroke-width="0.5" />
    <path d="M217.63 23.48H213.32" stroke="black" stroke-width="0.5" />
    <path d="M236.76 22.27H218.19" stroke="black" stroke-width="0.5" />
    <path d="M217.63 22.27H213.32" stroke="black" stroke-width="0.5" />
    <path d="M213.32 22.59H241.61" stroke="black" stroke-width="0.5" />
    <path d="M241.61 22.86H213.32" stroke="black" stroke-width="0.5" />
    <path
      d="M213.32 22.86H241.61L213.32 22.59V22.86Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M241.61 22.86V22.59H213.32L241.61 22.86Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M213.32 22.86V22.59" stroke="black" stroke-width="0.5" />
    <path d="M217.63 22.27H218.19" stroke="black" stroke-width="0.5" />
    <path d="M217.63 23.48H218.19" stroke="black" stroke-width="0.5" />
    <path d="M-4.97 23.48H24.38" stroke="black" stroke-width="0.5" />
    <path d="M24.38 23.48V25.62" stroke="black" stroke-width="0.5" />
    <path d="M24.38 25.62H-4.97" stroke="black" stroke-width="0.5" />
    <path d="M0.839996 23.48V25.62" stroke="black" stroke-width="0.5" />
    <path d="M0.839996 24.55H3.48001" stroke="black" stroke-width="0.5" />
    <path d="M5.32999 24.55H8.14999" stroke="black" stroke-width="0.5" />
    <path d="M10.24 24.55H12.57" stroke="black" stroke-width="0.5" />
    <path d="M14.86 24.55H17.17" stroke="black" stroke-width="0.5" />
    <path d="M19.62 24.55H21.78" stroke="black" stroke-width="0.5" />
    <path d="M23.17 24.55H24.38" stroke="black" stroke-width="0.5" />
    <path d="M161.55 23.48H190.9" stroke="black" stroke-width="0.5" />
    <path d="M190.9 23.48V25.62" stroke="black" stroke-width="0.5" />
    <path d="M190.9 25.62H161.55" stroke="black" stroke-width="0.5" />
    <path d="M161.55 25.62V23.48" stroke="black" stroke-width="0.5" />
    <path d="M186.5 25.62V26.95" stroke="black" stroke-width="0.5" />
    <path d="M186.5 26.95H183.03" stroke="black" stroke-width="0.5" />
    <path d="M183.03 26.95V68.85" stroke="black" stroke-width="0.5" />
    <path d="M185.09 23.48V25.62" stroke="black" stroke-width="0.5" />
    <path d="M186.42 23.48V25.62" stroke="black" stroke-width="0.5" />
    <path d="M212.79 23.48H242.14" stroke="black" stroke-width="0.5" />
    <path d="M242.14 25.62H212.79" stroke="black" stroke-width="0.5" />
    <path d="M212.79 25.62V23.48" stroke="black" stroke-width="0.5" />
    <path d="M218.6 23.48V25.62" stroke="black" stroke-width="0.5" />
    <path d="M217.27 23.48V25.62" stroke="black" stroke-width="0.5" />
    <path d="M212.79 24.55H217.27" stroke="black" stroke-width="0.5" />
    <path d="M218.6 24.55H221.24" stroke="black" stroke-width="0.5" />
    <path d="M161.55 24.55H166.03" stroke="black" stroke-width="0.5" />
    <path d="M167.36 24.55H170" stroke="black" stroke-width="0.5" />
    <path d="M171.85 24.55H174.67" stroke="black" stroke-width="0.5" />
    <path d="M176.76 24.55H179.09" stroke="black" stroke-width="0.5" />
    <path d="M181.38 24.55H183.69" stroke="black" stroke-width="0.5" />
    <path d="M186.42 24.55H188.3" stroke="black" stroke-width="0.5" />
    <path d="M189.7 24.55H190.9" stroke="black" stroke-width="0.5" />
    <path d="M-73.72 23.48H38.43" stroke="black" stroke-width="0.5" />
    <path d="M147.51 23.48H259.49" stroke="black" stroke-width="0.5" />
    <path d="M-62.51 21.44H38.43" stroke="black" stroke-width="0.5" />
    <path d="M147.51 21.44H248.28" stroke="black" stroke-width="0.5" />
    <path d="M24.38 23.48V21.44" stroke="black" stroke-width="0.5" />
    <path d="M161.55 23.48V21.44" stroke="black" stroke-width="0.5" />
    <path d="M190.9 23.48V21.44" stroke="black" stroke-width="0.5" />
    <path d="M212.79 23.48V21.44" stroke="black" stroke-width="0.5" />
    <path d="M0.600006 64.55V25.62" stroke="black" stroke-width="0.5" />
    <path d="M1.92999 25.62V64.55" stroke="black" stroke-width="0.5" />
    <path d="M38.96 129.99V25.62" stroke="black" stroke-width="0.5" />
    <path d="M25.62 131.33V122.99" stroke="black" stroke-width="0.5" />
    <path d="M26.95 128.39V121.65" stroke="black" stroke-width="0.5" />
    <path d="M40.29 25.62V129.99" stroke="black" stroke-width="0.5" />
    <path d="M80.31 65.88V74.35" stroke="black" stroke-width="0.5" />
    <path d="M80.85 65.88V74.13" stroke="black" stroke-width="0.5" />
    <path d="M79.59 65.88V74.76" stroke="black" stroke-width="0.5" />
    <path d="M81.65 65.88V73.8" stroke="black" stroke-width="0.5" />
    <path d="M53.9 49.37V53.6" stroke="black" stroke-width="0.5" />
    <path d="M53.9 63.21V64.55" stroke="black" stroke-width="0.5" />
    <path d="M55.24 49.37V53.6" stroke="black" stroke-width="0.5" />
    <path d="M55.24 63.21V64.55" stroke="black" stroke-width="0.5" />
    <path d="M3.17001 65.88H-17.12" stroke="black" stroke-width="0.5" />
    <path d="M0.600006 64.55H-17.65" stroke="black" stroke-width="0.5" />
    <path d="M128.04 65.88H101.69" stroke="black" stroke-width="0.5" />
    <path d="M129.37 72.82H128.04" stroke="black" stroke-width="0.5" />
    <path d="M88.73 65.88H77.07" stroke="black" stroke-width="0.5" />
    <path d="M59.99 65.88H40.29" stroke="black" stroke-width="0.5" />
    <path d="M38.96 65.88H19.18" stroke="black" stroke-width="0.5" />
    <path d="M129.37 64.55H101.69" stroke="black" stroke-width="0.5" />
    <path d="M88.73 64.55H77.07" stroke="black" stroke-width="0.5" />
    <path d="M59.99 64.55H55.24" stroke="black" stroke-width="0.5" />
    <path d="M53.9 64.55H40.29" stroke="black" stroke-width="0.5" />
    <path d="M55.24 63.74H53.9" stroke="black" stroke-width="0.5" />
    <path d="M55.24 63.21H53.9" stroke="black" stroke-width="0.5" />
    <path d="M38.96 64.55H18.64" stroke="black" stroke-width="0.5" />
    <path d="M3.17001 64.55H1.92999" stroke="black" stroke-width="0.5" />
    <path d="M3.17001 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M2.63 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M19.18 65.88H12.77" stroke="black" stroke-width="0.5" />
    <path d="M19.18 64.55H12.77" stroke="black" stroke-width="0.5" />
    <path d="M12.77 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M13.31 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M18.64 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M19.18 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M18.64 65.21H13.31" stroke="black" stroke-width="0.5" />
    <path d="M3.79999 81.89V89.9" stroke="black" stroke-width="0.5" />
    <path d="M6.37 83.23H5.13" stroke="black" stroke-width="0.5" />
    <path d="M6.37 83.23V81.89" stroke="black" stroke-width="0.5" />
    <path d="M5.82999 83.23V81.89" stroke="black" stroke-width="0.5" />
    <path d="M15.97 83.23H38.96" stroke="black" stroke-width="0.5" />
    <path d="M15.97 81.89H38.96" stroke="black" stroke-width="0.5" />
    <path d="M-10.88 89.9H3.79999" stroke="black" stroke-width="0.5" />
    <path d="M-10.88 91.23H3.79999" stroke="black" stroke-width="0.5" />
    <path d="M3.79999 92.03H4.47" stroke="black" stroke-width="0.5" />
    <path d="M6.37 81.89H3.79999" stroke="black" stroke-width="0.5" />
    <path d="M15.97 83.23V81.89" stroke="black" stroke-width="0.5" />
    <path d="M16.51 83.23V81.89" stroke="black" stroke-width="0.5" />
    <path d="M3.79999 91.23H5.13" stroke="black" stroke-width="0.5" />
    <path d="M5.13 91.23V121.65" stroke="black" stroke-width="0.5" />
    <path d="M5.13 121.65H3.79999" stroke="black" stroke-width="0.5" />
    <path d="M3.79999 121.65V91.23" stroke="black" stroke-width="0.5" />
    <path d="M4.47 91.23V121.65" stroke="black" stroke-width="0.5" />
    <path d="M3.79999 100.83H4.47" stroke="black" stroke-width="0.5" />
    <path d="M3.79999 101.63H4.47" stroke="black" stroke-width="0.5" />
    <path d="M3.79999 111.15H4.47" stroke="black" stroke-width="0.5" />
    <path d="M3.79999 111.95H4.47" stroke="black" stroke-width="0.5" />
    <path d="M3.79999 120.85H4.47" stroke="black" stroke-width="0.5" />
    <path d="M5.13 91.23V83.23" stroke="black" stroke-width="0.5" />
    <path d="M5.13 121.65H14.74" stroke="black" stroke-width="0.5" />
    <path d="M24.35 121.65H26.95" stroke="black" stroke-width="0.5" />
    <path d="M4.64999 122.99H14.74" stroke="black" stroke-width="0.5" />
    <path d="M23.88 122.99H25.62" stroke="black" stroke-width="0.5" />
    <path
      d="M4.64999 122.99L-2.35999 129.99"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M3.79999 121.95L-4.23999 129.99"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M3.79999 121.65V121.95" stroke="black" stroke-width="0.5" />
    <path d="M14.74 122.99H8.34" stroke="black" stroke-width="0.5" />
    <path d="M14.74 121.65H8.34" stroke="black" stroke-width="0.5" />
    <path d="M8.34 121.65V122.99" stroke="black" stroke-width="0.5" />
    <path d="M8.87 121.65V122.99" stroke="black" stroke-width="0.5" />
    <path d="M14.21 121.65V122.99" stroke="black" stroke-width="0.5" />
    <path d="M14.74 121.65V122.99" stroke="black" stroke-width="0.5" />
    <path d="M14.21 122.32H8.87" stroke="black" stroke-width="0.5" />
    <path d="M24.35 122.98V121.65" stroke="black" stroke-width="0.5" />
    <path d="M24.88 122.98V121.65" stroke="black" stroke-width="0.5" />
    <path d="M26.95 128.39H38.96" stroke="black" stroke-width="0.5" />
    <path d="M30.46 21.44V16.78" stroke="black" stroke-width="0.5" />
    <path d="M30.99 21.44V17.33" stroke="black" stroke-width="0.5" />
    <path d="M32.59 21.44V18.46" stroke="black" stroke-width="0.5" />
    <path d="M155.48 21.44V16.78" stroke="black" stroke-width="0.5" />
    <path d="M154.94 21.44V17.33" stroke="black" stroke-width="0.5" />
    <path d="M153.34 21.44V18.46" stroke="black" stroke-width="0.5" />
    <path d="M38.43 23.48V16.14" stroke="black" stroke-width="0.5" />
    <path d="M147.51 23.48V16.14" stroke="black" stroke-width="0.5" />
    <path
      d="M92.97 4.17999C90.3121 4.181 87.6546 4.24768 85 4.37999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M73.78 5.33002C66.94 6.14866 60.1595 7.40456 53.48 9.09001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M42.79 12.18C38.6192 13.545 34.5057 15.0796 30.46 16.78"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M92.97 4.73001C90.3253 4.73833 87.6811 4.81168 85.04 4.95"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M73.83 5.92999C67.0328 6.76726 60.2959 8.0365 53.66 9.73"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M42.98 12.85C38.9257 14.1841 34.9258 15.6787 30.99 17.33"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M92.97 5.87C90.3613 5.90675 87.7539 6.00677 85.15 6.16999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M73.94 7.25C67.2411 8.13923 60.6047 9.44847 54.07 11.17"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M43.41 14.37C39.7556 15.6036 36.1466 16.9678 32.59 18.46"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M43.59 15.01L42.79 12.18" stroke="black" stroke-width="0.5" />
    <path d="M44.11 14.86L43.76 13.63" stroke="black" stroke-width="0.5" />
    <path d="M54.27 11.86L53.48 9.09" stroke="black" stroke-width="0.5" />
    <path d="M53.76 12.01L53.36 10.63" stroke="black" stroke-width="0.5" />
    <path d="M43.25 13.78L53.87 10.47" stroke="black" stroke-width="0.5" />
    <path d="M74 7.88001L73.78 5.33002" stroke="black" stroke-width="0.5" />
    <path d="M74.53 7.84L74.42 6.56" stroke="black" stroke-width="0.5" />
    <path d="M85.21 6.94001L85 4.38" stroke="black" stroke-width="0.5" />
    <path d="M84.68 6.98002L84.57 5.70001" stroke="black" stroke-width="0.5" />
    <path d="M73.89 6.61L85.1 5.66" stroke="black" stroke-width="0.5" />
    <path d="M64.61 6.69002L64.19 4.33002" stroke="black" stroke-width="0.5" />
    <path d="M64.19 4.32999L67.34 3.76999" stroke="black" stroke-width="0.5" />
    <path d="M67.34 3.77L67.06 2.19" stroke="black" stroke-width="0.5" />
    <path
      d="M92.97 0.169983C84.2966 0.233304 75.6384 0.908317 67.06 2.18999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M68.61 8.29001L68.42 8.98001" stroke="black" stroke-width="0.5" />
    <path d="M68.42 8.98001L67.92 9.48001" stroke="black" stroke-width="0.5" />
    <path d="M67.92 9.48001L67.23 9.67001" stroke="black" stroke-width="0.5" />
    <path d="M67.23 9.67001L66.54 9.48001" stroke="black" stroke-width="0.5" />
    <path d="M66.54 9.48001L66.03 8.98001" stroke="black" stroke-width="0.5" />
    <path d="M66.03 8.98001L65.84 8.29001" stroke="black" stroke-width="0.5" />
    <path d="M65.84 8.29001L66.03 7.60001" stroke="black" stroke-width="0.5" />
    <path d="M66.03 7.6L66.54 7.09" stroke="black" stroke-width="0.5" />
    <path d="M66.54 7.08999L67.23 6.89999" stroke="black" stroke-width="0.5" />
    <path d="M67.23 6.89999L67.92 7.08999" stroke="black" stroke-width="0.5" />
    <path d="M67.92 7.09L68.42 7.6" stroke="black" stroke-width="0.5" />
    <path d="M68.42 7.60001L68.61 8.29001" stroke="black" stroke-width="0.5" />
    <path d="M72.63 7.63L72.45 8.30001" stroke="black" stroke-width="0.5" />
    <path d="M72.45 8.29999L71.95 8.79999" stroke="black" stroke-width="0.5" />
    <path d="M71.95 8.79999L71.28 8.97999" stroke="black" stroke-width="0.5" />
    <path d="M71.28 8.97999L70.61 8.79999" stroke="black" stroke-width="0.5" />
    <path d="M70.61 8.79999L70.11 8.29999" stroke="black" stroke-width="0.5" />
    <path d="M70.11 8.30001L69.93 7.63" stroke="black" stroke-width="0.5" />
    <path d="M69.93 7.62999L70.11 6.95999" stroke="black" stroke-width="0.5" />
    <path d="M70.11 6.95999L70.61 6.45999" stroke="black" stroke-width="0.5" />
    <path d="M70.61 6.46L71.28 6.28" stroke="black" stroke-width="0.5" />
    <path d="M71.28 6.28L71.95 6.46" stroke="black" stroke-width="0.5" />
    <path d="M71.95 6.45999L72.45 6.95999" stroke="black" stroke-width="0.5" />
    <path d="M72.45 6.95999L72.63 7.62999" stroke="black" stroke-width="0.5" />
    <path d="M57.52 9.29001H59.17" stroke="black" stroke-width="0.5" />
    <path d="M59.17 9.29001V11.08" stroke="black" stroke-width="0.5" />
    <path d="M59.17 11.08H57.52" stroke="black" stroke-width="0.5" />
    <path d="M57.52 11.08V9.29001" stroke="black" stroke-width="0.5" />
    <path d="M43.44 17.24L42.98 15.64" stroke="black" stroke-width="0.5" />
    <path d="M55.6 13.76L43.44 17.24" stroke="black" stroke-width="0.5" />
    <path d="M55.6 13.76L55.11 12.04" stroke="black" stroke-width="0.5" />
    <path d="M43.95 16.22L45.12 15.88" stroke="black" stroke-width="0.5" />
    <path d="M45.78 15.69L49.42 14.63" stroke="black" stroke-width="0.5" />
    <path d="M50.52 14.31L51.69 13.97" stroke="black" stroke-width="0.5" />
    <path d="M52.74 13.66L53.83 13.35" stroke="black" stroke-width="0.5" />
    <path d="M74 7.88L74.19 10.17" stroke="black" stroke-width="0.5" />
    <path d="M85.91 7.51999L86.05 9.16" stroke="black" stroke-width="0.5" />
    <path d="M74.19 10.17L86.05 9.16" stroke="black" stroke-width="0.5" />
    <path d="M85.68 8.37L84.22 8.48999" stroke="black" stroke-width="0.5" />
    <path d="M83.2 8.57001L81.72 8.7" stroke="black" stroke-width="0.5" />
    <path d="M80.53 8.79999L79.07 8.91998" stroke="black" stroke-width="0.5" />
    <path d="M77.83 9.03L76.47 9.15" stroke="black" stroke-width="0.5" />
    <path d="M75.32 9.23999L74.12 9.33999" stroke="black" stroke-width="0.5" />
    <path
      d="M85 4.38C81.2473 4.52263 77.5033 4.83964 73.78 5.33001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M53.48 9.09C49.9011 10.0654 46.3374 11.0955 42.79 12.18"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M74.04 8.20001V48.03" stroke="black" stroke-width="0.5" />
    <path d="M72.7 8.69V48.03" stroke="black" stroke-width="0.5" />
    <path d="M40.29 48.03H56.94" stroke="black" stroke-width="0.5" />
    <path d="M40.29 49.37H53.9" stroke="black" stroke-width="0.5" />
    <path d="M55.24 49.37H56.94" stroke="black" stroke-width="0.5" />
    <path d="M71.35 49.37H93.47" stroke="black" stroke-width="0.5" />
    <path d="M56.94 49.37H61.75" stroke="black" stroke-width="0.5" />
    <path d="M56.94 48.03H61.75" stroke="black" stroke-width="0.5" />
    <path d="M61.75 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M61.21 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M57.48 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M56.94 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M57.48 48.7H61.21" stroke="black" stroke-width="0.5" />
    <path d="M71.35 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M71.89 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path
      d="M100.94 4.37999C98.2854 4.24768 95.6279 4.181 92.97 4.17999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M132.45 9.09001C125.771 7.40456 118.99 6.14866 112.15 5.33002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M155.48 16.78C151.431 15.0793 147.314 13.5447 143.14 12.18"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M100.89 4.95C98.2522 4.81185 95.6114 4.7385 92.97 4.73001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M132.27 9.73C125.634 8.0365 118.897 6.76726 112.1 5.92999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M154.94 17.33C151.004 15.6787 147.004 14.1841 142.95 12.85"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M100.78 6.16999C98.1795 6.00694 95.5754 5.90691 92.97 5.87"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M131.86 11.17C125.325 9.44847 118.689 8.13923 111.99 7.25"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M153.34 18.46C149.783 16.9678 146.174 15.6036 142.52 14.37"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M142.23 15.39L143.14 12.18" stroke="black" stroke-width="0.5" />
    <path d="M141.83 14.86L142.18 13.63" stroke="black" stroke-width="0.5" />
    <path d="M131.66 11.86L132.45 9.09" stroke="black" stroke-width="0.5" />
    <path d="M132.17 12.01L132.57 10.63" stroke="black" stroke-width="0.5" />
    <path d="M142.69 13.78L132.06 10.47" stroke="black" stroke-width="0.5" />
    <path
      d="M111.93 7.88001L112.15 5.33002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M111.4 7.84L111.51 6.56" stroke="black" stroke-width="0.5" />
    <path d="M100.72 6.94001L100.94 4.38" stroke="black" stroke-width="0.5" />
    <path
      d="M101.25 6.98002L101.36 5.70001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M112.04 6.61L100.83 5.66" stroke="black" stroke-width="0.5" />
    <path
      d="M121.32 6.69002L121.74 4.33002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M121.74 4.32999L118.59 3.76999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M118.59 3.77L118.87 2.19" stroke="black" stroke-width="0.5" />
    <path
      d="M118.87 2.18999C110.295 0.908785 101.64 0.233774 92.97 0.169983"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M120.09 8.29001L119.9 8.98001" stroke="black" stroke-width="0.5" />
    <path d="M119.9 8.98001L119.4 9.48001" stroke="black" stroke-width="0.5" />
    <path d="M119.4 9.48001L118.71 9.67001" stroke="black" stroke-width="0.5" />
    <path
      d="M118.71 9.67001L118.01 9.48001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M118.01 9.48001L117.51 8.98001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M117.51 8.98001L117.32 8.29001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M117.32 8.29001L117.51 7.60001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M117.51 7.6L118.01 7.09" stroke="black" stroke-width="0.5" />
    <path
      d="M118.01 7.08999L118.71 6.89999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M118.71 6.89999L119.4 7.08999" stroke="black" stroke-width="0.5" />
    <path d="M119.4 7.09L119.9 7.6" stroke="black" stroke-width="0.5" />
    <path d="M119.9 7.60001L120.09 8.29001" stroke="black" stroke-width="0.5" />
    <path d="M116 7.63L115.82 8.30001" stroke="black" stroke-width="0.5" />
    <path
      d="M115.82 8.29999L115.32 8.79999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M115.32 8.79999L114.65 8.97999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M114.65 8.97999L113.98 8.79999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M113.98 8.79999L113.48 8.29999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M113.48 8.30001L113.3 7.63" stroke="black" stroke-width="0.5" />
    <path d="M113.3 7.62999L113.48 6.95999" stroke="black" stroke-width="0.5" />
    <path
      d="M113.48 6.95999L113.98 6.45999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M113.98 6.46L114.65 6.28" stroke="black" stroke-width="0.5" />
    <path d="M114.65 6.28L115.32 6.46" stroke="black" stroke-width="0.5" />
    <path
      d="M115.32 6.45999L115.82 6.95999"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M115.82 6.95999L116 7.62999" stroke="black" stroke-width="0.5" />
    <path d="M128.41 9.29001H126.76" stroke="black" stroke-width="0.5" />
    <path d="M126.76 9.29001V11.08" stroke="black" stroke-width="0.5" />
    <path d="M126.76 11.08H128.41" stroke="black" stroke-width="0.5" />
    <path d="M128.41 11.08V9.29001" stroke="black" stroke-width="0.5" />
    <path d="M142.49 17.24L142.95 15.64" stroke="black" stroke-width="0.5" />
    <path d="M130.33 13.76L142.49 17.24" stroke="black" stroke-width="0.5" />
    <path d="M130.33 13.76L130.82 12.04" stroke="black" stroke-width="0.5" />
    <path d="M141.98 16.22L140.81 15.88" stroke="black" stroke-width="0.5" />
    <path d="M140.15 15.69L136.51 14.63" stroke="black" stroke-width="0.5" />
    <path d="M135.41 14.31L134.24 13.97" stroke="black" stroke-width="0.5" />
    <path d="M133.19 13.66L132.1 13.35" stroke="black" stroke-width="0.5" />
    <path d="M111.93 7.88L111.74 10.17" stroke="black" stroke-width="0.5" />
    <path d="M100.02 7.51999L99.88 9.16" stroke="black" stroke-width="0.5" />
    <path d="M111.74 10.17L99.88 9.16" stroke="black" stroke-width="0.5" />
    <path d="M100.26 8.37L101.71 8.48999" stroke="black" stroke-width="0.5" />
    <path d="M102.73 8.57001L104.21 8.7" stroke="black" stroke-width="0.5" />
    <path d="M105.4 8.79999L106.86 8.91998" stroke="black" stroke-width="0.5" />
    <path d="M108.1 9.03L109.47 9.15" stroke="black" stroke-width="0.5" />
    <path
      d="M110.61 9.23999L111.81 9.33999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M112.15 5.33001C108.43 4.83995 104.689 4.52294 100.94 4.38"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M143.14 12.18C139.593 11.0955 136.029 10.0654 132.45 9.09"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M111.9 8.20001V48.03" stroke="black" stroke-width="0.5" />
    <path d="M113.23 8.20001V48.03" stroke="black" stroke-width="0.5" />
    <path d="M110.55 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M111.08 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M93.47 48.03H74.04" stroke="black" stroke-width="0.5" />
    <path d="M110.55 48.03H111.9" stroke="black" stroke-width="0.5" />
    <path d="M110.55 49.37H127.24" stroke="black" stroke-width="0.5" />
    <path d="M113.23 48.03H127.24" stroke="black" stroke-width="0.5" />
    <path d="M145.67 25.62V48.03" stroke="black" stroke-width="0.5" />
    <path d="M145.67 49.37V70.18" stroke="black" stroke-width="0.5" />
    <path d="M147.01 25.62V68.85" stroke="black" stroke-width="0.5" />
    <path d="M144.32 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M144.86 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M144.32 48.03H145.67" stroke="black" stroke-width="0.5" />
    <path d="M144.32 49.37H145.67" stroke="black" stroke-width="0.5" />
    <path d="M55.24 53.6H53.9" stroke="black" stroke-width="0.5" />
    <path d="M55.24 53.07H53.9" stroke="black" stroke-width="0.5" />
    <path d="M77.61 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M77.07 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M181.7 25.62V68.85" stroke="black" stroke-width="0.5" />
    <path d="M147.01 68.85H148.24" stroke="black" stroke-width="0.5" />
    <path d="M81.65 73.8L96.41 88.56" stroke="black" stroke-width="0.5" />
    <path d="M80.31 74.35L83.6 77.64" stroke="black" stroke-width="0.5" />
    <path d="M80.85 74.13L96.08 89.36" stroke="black" stroke-width="0.5" />
    <path d="M79.59 74.76L95.53 90.7" stroke="black" stroke-width="0.5" />
    <path d="M91.15 85.18L95.86 89.9" stroke="black" stroke-width="0.5" />
    <path d="M88.73 65.88V64.55" stroke="black" stroke-width="0.5" />
    <path d="M101.69 65.88V64.55" stroke="black" stroke-width="0.5" />
    <path d="M129.37 64.55V72.82" stroke="black" stroke-width="0.5" />
    <path d="M128.04 65.88V72.82" stroke="black" stroke-width="0.5" />
    <path d="M96.41 88.56H128" stroke="black" stroke-width="0.5" />
    <path d="M129.34 88.56H145.65" stroke="black" stroke-width="0.5" />
    <path d="M95.86 89.9H145.65" stroke="black" stroke-width="0.5" />
    <path d="M96.08 89.36H128" stroke="black" stroke-width="0.5" />
    <path d="M95.53 90.7H128" stroke="black" stroke-width="0.5" />
    <path d="M80.85 66.53H81.65" stroke="black" stroke-width="0.5" />
    <path d="M83.41 78.58L84.92 77.07" stroke="black" stroke-width="0.5" />
    <path d="M83.6 77.64L84.54 76.69" stroke="black" stroke-width="0.5" />
    <path d="M83.6 76.88L84.16 76.31" stroke="black" stroke-width="0.5" />
    <path d="M90.2 85.37L91.71 83.86" stroke="black" stroke-width="0.5" />
    <path d="M91.15 85.18L92.09 84.24" stroke="black" stroke-width="0.5" />
    <path d="M91.9 85.18L92.47 84.62" stroke="black" stroke-width="0.5" />
    <path d="M81.65 73.8L81.08 74.36" stroke="black" stroke-width="0.5" />
    <path d="M81.65 73.8H80.85" stroke="black" stroke-width="0.5" />
    <path d="M96.41 88.56L96.56 89.36" stroke="black" stroke-width="0.5" />
    <path d="M96.41 88.56L95.85 89.13" stroke="black" stroke-width="0.5" />
    <path d="M128 90.7V82.16" stroke="black" stroke-width="0.5" />
    <path d="M128 82.16H129.34" stroke="black" stroke-width="0.5" />
    <path d="M129.34 82.16V88.56" stroke="black" stroke-width="0.5" />
    <path d="M127.28 89.36V88.56" stroke="black" stroke-width="0.5" />
    <path d="M111.69 88.56V89.36" stroke="black" stroke-width="0.5" />
    <path d="M112.42 88.56V89.36" stroke="black" stroke-width="0.5" />
    <path d="M67.47 65.88H59.99" stroke="black" stroke-width="0.5" />
    <path d="M67.47 64.55H59.99" stroke="black" stroke-width="0.5" />
    <path d="M59.99 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M60.53 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M66.93 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M67.47 64.55V65.88" stroke="black" stroke-width="0.5" />
    <path d="M66.93 65.21H60.53" stroke="black" stroke-width="0.5" />
    <path d="M40.29 81.12H53.4" stroke="black" stroke-width="0.5" />
    <path d="M40.29 82.46H53.4" stroke="black" stroke-width="0.5" />
    <path d="M40.29 97.14H53.4" stroke="black" stroke-width="0.5" />
    <path d="M40.29 98.47H53.4" stroke="black" stroke-width="0.5" />
    <path d="M148.24 68.85H147.01" stroke="black" stroke-width="0.5" />
    <path d="M148.24 70.18H145.67" stroke="black" stroke-width="0.5" />
    <path d="M148.24 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M147.7 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M61.41 82.46H53.4" stroke="black" stroke-width="0.5" />
    <path d="M61.41 81.12H53.4" stroke="black" stroke-width="0.5" />
    <path d="M53.4 81.12V82.46" stroke="black" stroke-width="0.5" />
    <path d="M53.93 81.12V82.46" stroke="black" stroke-width="0.5" />
    <path d="M60.87 81.12V82.46" stroke="black" stroke-width="0.5" />
    <path d="M61.41 81.12V82.46" stroke="black" stroke-width="0.5" />
    <path d="M60.87 81.79H53.93" stroke="black" stroke-width="0.5" />
    <path d="M61.41 98.47H53.4" stroke="black" stroke-width="0.5" />
    <path d="M61.41 97.14H53.4" stroke="black" stroke-width="0.5" />
    <path d="M53.4 97.14V98.47" stroke="black" stroke-width="0.5" />
    <path d="M53.93 97.14V98.47" stroke="black" stroke-width="0.5" />
    <path d="M60.87 97.14V98.47" stroke="black" stroke-width="0.5" />
    <path d="M61.41 97.14V98.47" stroke="black" stroke-width="0.5" />
    <path d="M60.87 97.8H53.93" stroke="black" stroke-width="0.5" />
    <path d="M100.94 49.37H93.47" stroke="black" stroke-width="0.5" />
    <path d="M100.94 48.03H93.47" stroke="black" stroke-width="0.5" />
    <path d="M93.47 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M94 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M100.41 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M100.94 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M100.41 48.7H94" stroke="black" stroke-width="0.5" />
    <path d="M134.72 49.37H127.24" stroke="black" stroke-width="0.5" />
    <path d="M134.72 48.03H127.24" stroke="black" stroke-width="0.5" />
    <path d="M127.24 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M127.78 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M134.18 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M134.72 48.03V49.37" stroke="black" stroke-width="0.5" />
    <path d="M134.18 48.7H127.78" stroke="black" stroke-width="0.5" />
    <path d="M165.32 70.18H157.84" stroke="black" stroke-width="0.5" />
    <path d="M165.32 68.85H157.84" stroke="black" stroke-width="0.5" />
    <path d="M157.84 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M158.38 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M164.78 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M165.32 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M164.78 69.52H158.38" stroke="black" stroke-width="0.5" />
    <path d="M184.26 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M183.73 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M201.34 70.18H193.87" stroke="black" stroke-width="0.5" />
    <path d="M201.34 68.85H193.87" stroke="black" stroke-width="0.5" />
    <path d="M193.87 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M194.41 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M200.81 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M201.34 68.85V70.18" stroke="black" stroke-width="0.5" />
    <path d="M200.81 69.52H194.41" stroke="black" stroke-width="0.5" />
    <path d="M165.32 68.85H181.7" stroke="black" stroke-width="0.5" />
    <path d="M165.32 70.18H184.26" stroke="black" stroke-width="0.5" />
    <path d="M183.03 68.85H184.26" stroke="black" stroke-width="0.5" />
    <path d="M201.34 70.18H209.35" stroke="black" stroke-width="0.5" />
    <path d="M201.34 68.85H209.35" stroke="black" stroke-width="0.5" />
    <path d="M145.65 82.08V88.56" stroke="black" stroke-width="0.5" />
    <path d="M145.65 89.9V91.22" stroke="black" stroke-width="0.5" />
    <path d="M145.65 100.83V112.38" stroke="black" stroke-width="0.5" />
    <path d="M146.98 82.08V86.21" stroke="black" stroke-width="0.5" />
    <path d="M146.98 87.54V91.22" stroke="black" stroke-width="0.5" />
    <path d="M146.98 100.83V112.38" stroke="black" stroke-width="0.5" />
    <path d="M145.65 82.08H146.98" stroke="black" stroke-width="0.5" />
    <path d="M146.98 87.54H157.66" stroke="black" stroke-width="0.5" />
    <path d="M146.98 86.21H157.66" stroke="black" stroke-width="0.5" />
    <path d="M157.66 86.21V87.54" stroke="black" stroke-width="0.5" />
    <path d="M157.13 86.21V87.54" stroke="black" stroke-width="0.5" />
    <path d="M145.65 90.69H146.98" stroke="black" stroke-width="0.5" />
    <path d="M145.65 91.22H146.98" stroke="black" stroke-width="0.5" />
    <path d="M145.65 100.83H146.98" stroke="black" stroke-width="0.5" />
    <path d="M145.65 101.37H146.98" stroke="black" stroke-width="0.5" />
    <path d="M174.74 86.21H167.27" stroke="black" stroke-width="0.5" />
    <path d="M174.74 87.54H167.27" stroke="black" stroke-width="0.5" />
    <path d="M167.27 87.54V86.21" stroke="black" stroke-width="0.5" />
    <path d="M167.8 87.54V86.21" stroke="black" stroke-width="0.5" />
    <path d="M174.2 87.54V86.21" stroke="black" stroke-width="0.5" />
    <path d="M174.74 87.54V86.21" stroke="black" stroke-width="0.5" />
    <path d="M174.2 86.88H167.8" stroke="black" stroke-width="0.5" />
    <path d="M174.74 86.21H185.54" stroke="black" stroke-width="0.5" />
    <path d="M174.74 87.54H183.01" stroke="black" stroke-width="0.5" />
    <path d="M184.35 87.54H185.54" stroke="black" stroke-width="0.5" />
    <path d="M183.01 87.54V129.99" stroke="black" stroke-width="0.5" />
    <path d="M184.35 87.54V129.99" stroke="black" stroke-width="0.5" />
    <path d="M185.01 87.54V86.21" stroke="black" stroke-width="0.5" />
    <path d="M185.54 87.54V86.21" stroke="black" stroke-width="0.5" />
    <path d="M195.15 87.54V86.21" stroke="black" stroke-width="0.5" />
    <path d="M195.68 87.54V86.21" stroke="black" stroke-width="0.5" />
    <path d="M195.15 87.54H209.35" stroke="black" stroke-width="0.5" />
    <path d="M195.15 86.21H209.35" stroke="black" stroke-width="0.5" />
    <path d="M217.44 90.76V129.99" stroke="black" stroke-width="0.5" />
    <path d="M212.1 90.76V90.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 90.81V90.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 90.86V90.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 90.91V90.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 90.96V91" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.01V91.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.06V91.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.11V91.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.16V91.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.21V91.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.26V91.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.31V91.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.36V91.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.41V91.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.46V91.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.51V91.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.56V91.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.61V91.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.66V91.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.71V91.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.77V91.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.82V91.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.87V91.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.92V91.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 91.97V92" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.02V92.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.07V92.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.12V92.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.17V92.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.22V92.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.27V92.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.32V92.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.37V92.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.42V92.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.47V92.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.52V92.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.57V92.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.62V92.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.67V92.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.72V92.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.77V92.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.82V92.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.87V92.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.92V92.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 92.97V93" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.02V93.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.07V93.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.12V93.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.17V93.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.22V93.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.27V93.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.32V93.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.37V93.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.42V93.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.47V93.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.52V93.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.57V93.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.62V93.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.67V93.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.72V93.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.77V93.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.82V93.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.87V93.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.92V93.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 93.97V94" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.02V94.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.07V94.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.12V94.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.17V94.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.22V94.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.27V94.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.32V94.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.37V94.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.42V94.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.47V94.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.52V94.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.57V94.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.62V94.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.67V94.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.72V94.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.77V94.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.82V94.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.87V94.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.92V94.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 94.97V95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.02V95.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.07V95.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.12V95.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.17V95.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.22V95.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.27V95.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.32V95.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.37V95.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.42V95.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.47V95.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.52V95.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.57V95.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.62V95.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.67V95.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.72V95.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.77V95.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.82V95.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.87V95.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.92V95.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 95.97V96" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.02V96.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.07V96.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.12V96.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.17V96.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.22V96.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.27V96.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.32V96.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.37V96.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.42V96.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.47V96.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.52V96.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.57V96.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.62V96.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.67V96.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.72V96.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.77V96.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.82V96.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.87V96.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.92V96.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 96.97V97" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.02V97.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.07V97.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.12V97.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.17V97.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.22V97.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.27V97.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.32V97.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.37V97.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.42V97.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.47V97.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.52V97.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.57V97.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.62V97.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.67V97.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.72V97.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.77V97.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.82V97.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.87V97.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.92V97.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 97.97V98" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.02V98.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.07V98.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.12V98.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.17V98.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.22V98.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.27V98.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.32V98.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.37V98.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.42V98.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.47V98.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.52V98.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.57V98.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.62V98.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.67V98.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.72V98.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.77V98.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.82V98.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.87V98.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.92V98.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 98.97V99" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.02V99.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.07V99.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.12V99.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.17V99.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.22V99.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.27V99.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.32V99.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.37V99.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.42V99.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.47V99.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.52V99.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.57V99.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.62V99.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.67V99.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.72V99.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.77V99.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.82V99.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.87V99.9" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.92V99.95" stroke="black" stroke-width="0.5" />
    <path d="M212.1 99.97V100" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.02V100.05" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.07V100.1" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.12V100.15" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.17V100.2" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.22V100.25" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.27V100.3" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.32V100.35" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.37V100.4" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.42V100.45" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.47V100.5" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.52V100.55" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.57V100.6" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.62V100.65" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.67V100.7" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.72V100.75" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.77V100.8" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.82V100.85" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.87V100.91" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.92V100.96" stroke="black" stroke-width="0.5" />
    <path d="M212.1 100.97V101.01" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.02V101.06" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.07V101.11" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.12V101.16" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.17V101.21" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.22V101.26" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.27V101.31" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.32V101.36" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.37V101.41" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.42V101.46" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.47V101.51" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.52V101.56" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.57V101.61" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.62V101.66" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.67V101.71" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.72V101.76" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.77V101.81" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.82V101.86" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.87V101.91" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.92V101.96" stroke="black" stroke-width="0.5" />
    <path d="M218.77 90.76V129.99" stroke="black" stroke-width="0.5" />
    <path d="M212.1 101.96H212.13" stroke="black" stroke-width="0.5" />
    <path d="M212.15 101.96H212.18" stroke="black" stroke-width="0.5" />
    <path d="M212.2 101.96H212.23" stroke="black" stroke-width="0.5" />
    <path d="M212.25 101.96H212.28" stroke="black" stroke-width="0.5" />
    <path d="M212.3 101.96H212.33" stroke="black" stroke-width="0.5" />
    <path d="M212.35 101.96H212.38" stroke="black" stroke-width="0.5" />
    <path d="M212.4 101.96H212.43" stroke="black" stroke-width="0.5" />
    <path d="M212.45 101.96H212.48" stroke="black" stroke-width="0.5" />
    <path d="M212.5 101.96H212.53" stroke="black" stroke-width="0.5" />
    <path d="M212.55 101.96H212.58" stroke="black" stroke-width="0.5" />
    <path d="M212.6 101.96H212.63" stroke="black" stroke-width="0.5" />
    <path d="M212.65 101.96H212.68" stroke="black" stroke-width="0.5" />
    <path d="M212.7 101.96H212.73" stroke="black" stroke-width="0.5" />
    <path d="M212.75 101.96H212.78" stroke="black" stroke-width="0.5" />
    <path d="M212.8 101.96H212.83" stroke="black" stroke-width="0.5" />
    <path d="M212.85 101.96H212.88" stroke="black" stroke-width="0.5" />
    <path d="M212.9 101.96H212.93" stroke="black" stroke-width="0.5" />
    <path d="M212.95 101.96H212.98" stroke="black" stroke-width="0.5" />
    <path d="M213 101.96H213.03" stroke="black" stroke-width="0.5" />
    <path d="M213.05 101.96H213.08" stroke="black" stroke-width="0.5" />
    <path d="M213.1 101.96H213.13" stroke="black" stroke-width="0.5" />
    <path d="M213.15 101.96H213.18" stroke="black" stroke-width="0.5" />
    <path d="M213.2 101.96H213.23" stroke="black" stroke-width="0.5" />
    <path d="M213.25 101.96H213.28" stroke="black" stroke-width="0.5" />
    <path d="M213.3 101.96H213.33" stroke="black" stroke-width="0.5" />
    <path d="M213.35 101.96H213.38" stroke="black" stroke-width="0.5" />
    <path d="M213.4 101.96H213.43" stroke="black" stroke-width="0.5" />
    <path d="M213.45 101.96H213.48" stroke="black" stroke-width="0.5" />
    <path d="M213.5 101.96H213.53" stroke="black" stroke-width="0.5" />
    <path d="M213.55 101.96H213.58" stroke="black" stroke-width="0.5" />
    <path d="M213.6 101.96H213.63" stroke="black" stroke-width="0.5" />
    <path d="M213.65 101.96H213.68" stroke="black" stroke-width="0.5" />
    <path d="M213.7 101.96H213.73" stroke="black" stroke-width="0.5" />
    <path d="M213.75 101.96H213.78" stroke="black" stroke-width="0.5" />
    <path d="M213.8 101.96H213.83" stroke="black" stroke-width="0.5" />
    <path d="M213.85 101.96H213.88" stroke="black" stroke-width="0.5" />
    <path d="M213.9 101.96H213.93" stroke="black" stroke-width="0.5" />
    <path d="M213.95 101.96H213.98" stroke="black" stroke-width="0.5" />
    <path d="M214 101.96H214.03" stroke="black" stroke-width="0.5" />
    <path d="M214.05 101.96H214.08" stroke="black" stroke-width="0.5" />
    <path d="M214.1 101.96H214.13" stroke="black" stroke-width="0.5" />
    <path d="M214.15 101.96H214.18" stroke="black" stroke-width="0.5" />
    <path d="M214.2 101.96H214.23" stroke="black" stroke-width="0.5" />
    <path d="M214.25 101.96H214.28" stroke="black" stroke-width="0.5" />
    <path d="M214.3 101.96H214.33" stroke="black" stroke-width="0.5" />
    <path d="M214.35 101.96H214.38" stroke="black" stroke-width="0.5" />
    <path d="M214.4 101.96H214.43" stroke="black" stroke-width="0.5" />
    <path d="M214.45 101.96H214.48" stroke="black" stroke-width="0.5" />
    <path d="M214.5 101.96H214.53" stroke="black" stroke-width="0.5" />
    <path d="M214.55 101.96H214.58" stroke="black" stroke-width="0.5" />
    <path d="M214.6 101.96H214.63" stroke="black" stroke-width="0.5" />
    <path d="M214.65 101.96H214.68" stroke="black" stroke-width="0.5" />
    <path d="M214.7 101.96H214.73" stroke="black" stroke-width="0.5" />
    <path d="M214.75 101.96H214.78" stroke="black" stroke-width="0.5" />
    <path d="M214.8 101.96H214.83" stroke="black" stroke-width="0.5" />
    <path d="M214.85 101.96H214.88" stroke="black" stroke-width="0.5" />
    <path d="M214.9 101.96H214.93" stroke="black" stroke-width="0.5" />
    <path d="M214.95 101.96H214.98" stroke="black" stroke-width="0.5" />
    <path d="M215 101.96H215.03" stroke="black" stroke-width="0.5" />
    <path d="M215.05 101.96H215.08" stroke="black" stroke-width="0.5" />
    <path d="M215.1 101.96H215.13" stroke="black" stroke-width="0.5" />
    <path d="M215.15 101.96H215.18" stroke="black" stroke-width="0.5" />
    <path d="M215.2 101.96H215.23" stroke="black" stroke-width="0.5" />
    <path d="M215.25 101.96H215.28" stroke="black" stroke-width="0.5" />
    <path d="M215.3 101.96H215.33" stroke="black" stroke-width="0.5" />
    <path d="M215.35 101.96H215.38" stroke="black" stroke-width="0.5" />
    <path d="M215.4 101.96H215.43" stroke="black" stroke-width="0.5" />
    <path d="M215.45 101.96H215.48" stroke="black" stroke-width="0.5" />
    <path d="M215.5 101.96H215.53" stroke="black" stroke-width="0.5" />
    <path d="M215.55 101.96H215.58" stroke="black" stroke-width="0.5" />
    <path d="M215.6 101.96H215.63" stroke="black" stroke-width="0.5" />
    <path d="M215.65 101.96H215.68" stroke="black" stroke-width="0.5" />
    <path d="M215.7 101.96H215.73" stroke="black" stroke-width="0.5" />
    <path d="M215.75 101.96H215.78" stroke="black" stroke-width="0.5" />
    <path d="M215.8 101.96H215.83" stroke="black" stroke-width="0.5" />
    <path d="M215.85 101.96H215.88" stroke="black" stroke-width="0.5" />
    <path d="M215.9 101.96H215.93" stroke="black" stroke-width="0.5" />
    <path d="M215.95 101.96H215.98" stroke="black" stroke-width="0.5" />
    <path d="M216 101.96H216.04" stroke="black" stroke-width="0.5" />
    <path d="M216.05 101.96H216.09" stroke="black" stroke-width="0.5" />
    <path d="M216.1 101.96H216.14" stroke="black" stroke-width="0.5" />
    <path d="M216.15 101.96H216.19" stroke="black" stroke-width="0.5" />
    <path d="M216.2 101.96H216.24" stroke="black" stroke-width="0.5" />
    <path d="M216.25 101.96H216.29" stroke="black" stroke-width="0.5" />
    <path d="M216.3 101.96H216.34" stroke="black" stroke-width="0.5" />
    <path d="M216.35 101.96H216.39" stroke="black" stroke-width="0.5" />
    <path d="M216.4 101.96H216.44" stroke="black" stroke-width="0.5" />
    <path d="M216.45 101.96H216.49" stroke="black" stroke-width="0.5" />
    <path d="M216.5 101.96H216.54" stroke="black" stroke-width="0.5" />
    <path d="M216.55 101.96H216.59" stroke="black" stroke-width="0.5" />
    <path d="M216.6 101.96H216.64" stroke="black" stroke-width="0.5" />
    <path d="M216.65 101.96H216.69" stroke="black" stroke-width="0.5" />
    <path d="M216.7 101.96H216.74" stroke="black" stroke-width="0.5" />
    <path d="M216.75 101.96H216.79" stroke="black" stroke-width="0.5" />
    <path d="M216.8 101.96H216.84" stroke="black" stroke-width="0.5" />
    <path d="M216.85 101.96H216.89" stroke="black" stroke-width="0.5" />
    <path d="M216.9 101.96H216.94" stroke="black" stroke-width="0.5" />
    <path d="M216.95 101.96H216.99" stroke="black" stroke-width="0.5" />
    <path d="M217 101.96H217.04" stroke="black" stroke-width="0.5" />
    <path d="M217.05 101.96H217.09" stroke="black" stroke-width="0.5" />
    <path d="M217.1 101.96H217.14" stroke="black" stroke-width="0.5" />
    <path d="M217.15 101.96H217.19" stroke="black" stroke-width="0.5" />
    <path d="M217.2 101.96H217.24" stroke="black" stroke-width="0.5" />
    <path d="M217.25 101.96H217.29" stroke="black" stroke-width="0.5" />
    <path d="M217.3 101.96H217.34" stroke="black" stroke-width="0.5" />
    <path d="M217.35 101.96H217.39" stroke="black" stroke-width="0.5" />
    <path d="M217.4 101.96H217.44" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.99V129.98" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.96V129.93" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.91V129.88" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.86V129.83" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.81V129.78" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.76V129.73" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.71V129.68" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.66V129.63" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.61V129.58" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.56V129.53" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.51V129.48" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.46V129.43" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.41V129.38" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.36V129.33" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.31V129.28" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.26V129.23" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.21V129.18" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.16V129.13" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.11V129.08" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.06V129.03" stroke="black" stroke-width="0.5" />
    <path d="M210.76 129.01V128.98" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.96V128.93" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.91V128.88" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.86V128.83" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.81V128.78" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.76V128.73" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.71V128.68" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.66V128.63" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.61V128.58" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.56V128.53" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.51V128.48" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.46V128.43" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.41V128.38" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.36V128.33" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.31V128.28" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.26V128.23" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.21V128.18" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.16V128.13" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.11V128.08" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.06V128.03" stroke="black" stroke-width="0.5" />
    <path d="M210.76 128.01V127.98" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.96V127.93" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.91V127.88" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.86V127.83" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.81V127.78" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.76V127.73" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.71V127.68" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.66V127.63" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.61V127.58" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.56V127.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.51V127.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.46V127.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.41V127.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.36V127.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.31V127.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.26V127.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.21V127.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.16V127.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.11V127.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.06V127.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 127.01V126.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.96V126.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.91V126.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.86V126.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.81V126.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.76V126.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.71V126.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.66V126.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.61V126.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.56V126.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.51V126.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.46V126.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.41V126.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.36V126.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.31V126.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.26V126.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.21V126.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.16V126.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.11V126.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.06V126.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 126.01V125.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.96V125.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.91V125.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.86V125.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.81V125.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.76V125.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.71V125.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.66V125.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.61V125.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.56V125.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.51V125.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.46V125.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.41V125.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.36V125.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.31V125.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.26V125.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.21V125.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.16V125.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.11V125.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.06V125.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 125.01V124.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.96V124.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.91V124.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.86V124.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.81V124.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.76V124.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.71V124.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.66V124.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.61V124.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.56V124.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.51V124.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.46V124.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.41V124.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.36V124.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.31V124.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.26V124.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.21V124.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.16V124.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.11V124.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.06V124.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 124.01V123.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.96V123.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.91V123.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.86V123.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.81V123.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.76V123.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.71V123.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.66V123.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.61V123.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.56V123.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.51V123.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.46V123.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.41V123.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.36V123.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.31V123.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.26V123.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.21V123.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.16V123.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.11V123.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.06V123.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 123.01V122.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.95V122.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.9V122.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.85V122.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.8V122.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.75V122.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.7V122.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.65V122.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.6V122.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.55V122.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.5V122.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.45V122.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.4V122.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.35V122.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.3V122.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.25V122.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.2V122.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.15V122.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.1V122.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122.05V122.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 122V121.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.95V121.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.9V121.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.85V121.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.8V121.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.75V121.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.7V121.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.65V121.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.6V121.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.55V121.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.5V121.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.45V121.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.4V121.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.35V121.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.3V121.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.25V121.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.2V121.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.15V121.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.1V121.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121.05V121.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 121V120.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.95V120.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.9V120.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.85V120.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.8V120.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.75V120.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.7V120.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.65V120.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.6V120.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.55V120.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.5V120.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.45V120.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.4V120.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.35V120.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.3V120.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.25V120.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.2V120.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.15V120.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.1V120.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120.05V120.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 120V119.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.95V119.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.9V119.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.85V119.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.8V119.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.75V119.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.7V119.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.65V119.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.6V119.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.55V119.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.5V119.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.45V119.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.4V119.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.35V119.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.3V119.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.25V119.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.2V119.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.15V119.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.1V119.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119.05V119.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 119V118.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.95V118.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.9V118.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.85V118.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.8V118.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.75V118.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.7V118.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.65V118.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.6V118.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.55V118.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.5V118.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.45V118.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.4V118.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.35V118.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.3V118.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.25V118.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.2V118.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.15V118.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.1V118.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118.05V118.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 118V117.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.95V117.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.9V117.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.85V117.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.8V117.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.75V117.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.7V117.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.65V117.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.6V117.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.55V117.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.5V117.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.45V117.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.4V117.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.35V117.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.3V117.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.25V117.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.2V117.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.15V117.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.1V117.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117.05V117.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 117V116.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.95V116.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.9V116.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.85V116.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.8V116.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.75V116.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.7V116.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.65V116.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.6V116.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.55V116.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.5V116.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.45V116.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.4V116.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.35V116.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.3V116.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.25V116.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.2V116.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.15V116.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.1V116.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116.05V116.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 116V115.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.95V115.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.9V115.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.85V115.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.8V115.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.75V115.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.7V115.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.65V115.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.6V115.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.55V115.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.5V115.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.45V115.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.4V115.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.35V115.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.3V115.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.25V115.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.2V115.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.15V115.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.1V115.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115.05V115.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 115V114.97" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.95V114.92" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.9V114.87" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.85V114.82" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.8V114.77" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.75V114.72" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.7V114.67" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.65V114.62" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.6V114.57" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.55V114.52" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.5V114.47" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.45V114.42" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.4V114.37" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.35V114.32" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.3V114.27" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.25V114.22" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.2V114.17" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.15V114.12" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.1V114.07" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114.05V114.02" stroke="black" stroke-width="0.5" />
    <path d="M210.76 114V113.98" stroke="black" stroke-width="0.5" />
    <path d="M210.76 113.98H210.79" stroke="black" stroke-width="0.5" />
    <path d="M210.81 113.98H210.84" stroke="black" stroke-width="0.5" />
    <path d="M210.86 113.98H210.89" stroke="black" stroke-width="0.5" />
    <path d="M210.91 113.98H210.94" stroke="black" stroke-width="0.5" />
    <path d="M210.96 113.98H210.99" stroke="black" stroke-width="0.5" />
    <path d="M211.01 113.98H211.04" stroke="black" stroke-width="0.5" />
    <path d="M211.06 113.98H211.09" stroke="black" stroke-width="0.5" />
    <path d="M211.11 113.98H211.14" stroke="black" stroke-width="0.5" />
    <path d="M211.16 113.98H211.19" stroke="black" stroke-width="0.5" />
    <path d="M211.21 113.98H211.24" stroke="black" stroke-width="0.5" />
    <path d="M211.26 113.98H211.29" stroke="black" stroke-width="0.5" />
    <path d="M211.31 113.98H211.34" stroke="black" stroke-width="0.5" />
    <path d="M211.36 113.98H211.39" stroke="black" stroke-width="0.5" />
    <path d="M211.41 113.98H211.44" stroke="black" stroke-width="0.5" />
    <path d="M211.46 113.98H211.49" stroke="black" stroke-width="0.5" />
    <path d="M211.51 113.98H211.54" stroke="black" stroke-width="0.5" />
    <path d="M211.56 113.98H211.59" stroke="black" stroke-width="0.5" />
    <path d="M211.61 113.98H211.64" stroke="black" stroke-width="0.5" />
    <path d="M211.66 113.98H211.69" stroke="black" stroke-width="0.5" />
    <path d="M211.71 113.98H211.74" stroke="black" stroke-width="0.5" />
    <path d="M211.76 113.98H211.79" stroke="black" stroke-width="0.5" />
    <path d="M211.81 113.98H211.84" stroke="black" stroke-width="0.5" />
    <path d="M211.86 113.98H211.89" stroke="black" stroke-width="0.5" />
    <path d="M211.91 113.98H211.94" stroke="black" stroke-width="0.5" />
    <path d="M211.96 113.98H211.99" stroke="black" stroke-width="0.5" />
    <path d="M212.01 113.98H212.04" stroke="black" stroke-width="0.5" />
    <path d="M212.06 113.98H212.09" stroke="black" stroke-width="0.5" />
    <path d="M212.11 113.98H212.14" stroke="black" stroke-width="0.5" />
    <path d="M212.16 113.98H212.19" stroke="black" stroke-width="0.5" />
    <path d="M212.21 113.98H212.24" stroke="black" stroke-width="0.5" />
    <path d="M212.26 113.98H212.29" stroke="black" stroke-width="0.5" />
    <path d="M212.31 113.98H212.34" stroke="black" stroke-width="0.5" />
    <path d="M212.36 113.98H212.39" stroke="black" stroke-width="0.5" />
    <path d="M212.41 113.98H212.44" stroke="black" stroke-width="0.5" />
    <path d="M212.46 113.98H212.49" stroke="black" stroke-width="0.5" />
    <path d="M212.51 113.98H212.54" stroke="black" stroke-width="0.5" />
    <path d="M212.56 113.98H212.59" stroke="black" stroke-width="0.5" />
    <path d="M212.61 113.98H212.64" stroke="black" stroke-width="0.5" />
    <path d="M212.66 113.98H212.69" stroke="black" stroke-width="0.5" />
    <path d="M212.71 113.98H212.74" stroke="black" stroke-width="0.5" />
    <path d="M212.76 113.98H212.79" stroke="black" stroke-width="0.5" />
    <path d="M212.81 113.98H212.84" stroke="black" stroke-width="0.5" />
    <path d="M212.86 113.98H212.89" stroke="black" stroke-width="0.5" />
    <path d="M212.91 113.98H212.94" stroke="black" stroke-width="0.5" />
    <path d="M212.96 113.98H212.99" stroke="black" stroke-width="0.5" />
    <path d="M213.01 113.98H213.04" stroke="black" stroke-width="0.5" />
    <path d="M213.06 113.98H213.09" stroke="black" stroke-width="0.5" />
    <path d="M213.11 113.98H213.14" stroke="black" stroke-width="0.5" />
    <path d="M213.16 113.98H213.19" stroke="black" stroke-width="0.5" />
    <path d="M213.21 113.98H213.24" stroke="black" stroke-width="0.5" />
    <path d="M213.26 113.98H213.29" stroke="black" stroke-width="0.5" />
    <path d="M213.31 113.98H213.34" stroke="black" stroke-width="0.5" />
    <path d="M213.36 113.98H213.39" stroke="black" stroke-width="0.5" />
    <path d="M213.41 113.98H213.44" stroke="black" stroke-width="0.5" />
    <path d="M213.46 113.98H213.49" stroke="black" stroke-width="0.5" />
    <path d="M213.51 113.98H213.54" stroke="black" stroke-width="0.5" />
    <path d="M213.56 113.98H213.59" stroke="black" stroke-width="0.5" />
    <path d="M213.61 113.98H213.64" stroke="black" stroke-width="0.5" />
    <path d="M213.66 113.98H213.69" stroke="black" stroke-width="0.5" />
    <path d="M213.71 113.98H213.74" stroke="black" stroke-width="0.5" />
    <path d="M213.76 113.98H213.79" stroke="black" stroke-width="0.5" />
    <path d="M213.81 113.98H213.84" stroke="black" stroke-width="0.5" />
    <path d="M213.86 113.98H213.89" stroke="black" stroke-width="0.5" />
    <path d="M213.91 113.98H213.94" stroke="black" stroke-width="0.5" />
    <path d="M213.96 113.98H213.99" stroke="black" stroke-width="0.5" />
    <path d="M214.01 113.98H214.04" stroke="black" stroke-width="0.5" />
    <path d="M214.06 113.98H214.09" stroke="black" stroke-width="0.5" />
    <path d="M214.11 113.98H214.14" stroke="black" stroke-width="0.5" />
    <path d="M214.16 113.98H214.19" stroke="black" stroke-width="0.5" />
    <path d="M214.21 113.98H214.24" stroke="black" stroke-width="0.5" />
    <path d="M214.26 113.98H214.29" stroke="black" stroke-width="0.5" />
    <path d="M214.31 113.98H214.34" stroke="black" stroke-width="0.5" />
    <path d="M214.36 113.98H214.39" stroke="black" stroke-width="0.5" />
    <path d="M214.41 113.98H214.44" stroke="black" stroke-width="0.5" />
    <path d="M214.46 113.98H214.49" stroke="black" stroke-width="0.5" />
    <path d="M214.51 113.98H214.54" stroke="black" stroke-width="0.5" />
    <path d="M214.56 113.98H214.59" stroke="black" stroke-width="0.5" />
    <path d="M214.61 113.98H214.64" stroke="black" stroke-width="0.5" />
    <path d="M214.66 113.98H214.69" stroke="black" stroke-width="0.5" />
    <path d="M214.71 113.98H214.74" stroke="black" stroke-width="0.5" />
    <path d="M214.76 113.98H214.79" stroke="black" stroke-width="0.5" />
    <path d="M214.81 113.98H214.84" stroke="black" stroke-width="0.5" />
    <path d="M214.86 113.98H214.89" stroke="black" stroke-width="0.5" />
    <path d="M214.91 113.98H214.94" stroke="black" stroke-width="0.5" />
    <path d="M214.96 113.98H214.99" stroke="black" stroke-width="0.5" />
    <path d="M215.01 113.98H215.04" stroke="black" stroke-width="0.5" />
    <path d="M215.06 113.98H215.09" stroke="black" stroke-width="0.5" />
    <path d="M215.11 113.98H215.14" stroke="black" stroke-width="0.5" />
    <path d="M215.16 113.98H215.19" stroke="black" stroke-width="0.5" />
    <path d="M215.21 113.98H215.24" stroke="black" stroke-width="0.5" />
    <path d="M215.26 113.98H215.29" stroke="black" stroke-width="0.5" />
    <path d="M215.31 113.98H215.34" stroke="black" stroke-width="0.5" />
    <path d="M215.36 113.98H215.39" stroke="black" stroke-width="0.5" />
    <path d="M215.41 113.98H215.44" stroke="black" stroke-width="0.5" />
    <path d="M215.46 113.98H215.49" stroke="black" stroke-width="0.5" />
    <path d="M215.51 113.98H215.54" stroke="black" stroke-width="0.5" />
    <path d="M215.56 113.98H215.59" stroke="black" stroke-width="0.5" />
    <path d="M215.61 113.98H215.64" stroke="black" stroke-width="0.5" />
    <path d="M215.66 113.98H215.69" stroke="black" stroke-width="0.5" />
    <path d="M215.71 113.98H215.74" stroke="black" stroke-width="0.5" />
    <path d="M215.76 113.98H215.79" stroke="black" stroke-width="0.5" />
    <path d="M215.81 113.98H215.84" stroke="black" stroke-width="0.5" />
    <path d="M215.86 113.98H215.89" stroke="black" stroke-width="0.5" />
    <path d="M215.91 113.98H215.94" stroke="black" stroke-width="0.5" />
    <path d="M215.96 113.98H215.99" stroke="black" stroke-width="0.5" />
    <path d="M216.01 113.98H216.04" stroke="black" stroke-width="0.5" />
    <path d="M216.06 113.98H216.09" stroke="black" stroke-width="0.5" />
    <path d="M216.11 113.98H216.14" stroke="black" stroke-width="0.5" />
    <path d="M216.16 113.98H216.19" stroke="black" stroke-width="0.5" />
    <path d="M216.21 113.98H216.24" stroke="black" stroke-width="0.5" />
    <path d="M216.26 113.98H216.29" stroke="black" stroke-width="0.5" />
    <path d="M216.31 113.98H216.34" stroke="black" stroke-width="0.5" />
    <path d="M216.36 113.98H216.39" stroke="black" stroke-width="0.5" />
    <path d="M216.41 113.98H216.44" stroke="black" stroke-width="0.5" />
    <path d="M216.46 113.98H216.49" stroke="black" stroke-width="0.5" />
    <path d="M216.51 113.98H216.54" stroke="black" stroke-width="0.5" />
    <path d="M216.56 113.98H216.59" stroke="black" stroke-width="0.5" />
    <path d="M216.61 113.98H216.64" stroke="black" stroke-width="0.5" />
    <path d="M216.66 113.98H216.69" stroke="black" stroke-width="0.5" />
    <path d="M216.71 113.98H216.74" stroke="black" stroke-width="0.5" />
    <path d="M216.76 113.98H216.79" stroke="black" stroke-width="0.5" />
    <path d="M216.81 113.98H216.84" stroke="black" stroke-width="0.5" />
    <path d="M216.86 113.98H216.89" stroke="black" stroke-width="0.5" />
    <path d="M216.91 113.98H216.94" stroke="black" stroke-width="0.5" />
    <path d="M216.96 113.98H216.99" stroke="black" stroke-width="0.5" />
    <path d="M217.01 113.98H217.04" stroke="black" stroke-width="0.5" />
    <path d="M217.06 113.98H217.09" stroke="black" stroke-width="0.5" />
    <path d="M217.11 113.98H217.14" stroke="black" stroke-width="0.5" />
    <path d="M217.16 113.98H217.19" stroke="black" stroke-width="0.5" />
    <path d="M217.21 113.98H217.24" stroke="black" stroke-width="0.5" />
    <path d="M217.26 113.98H217.29" stroke="black" stroke-width="0.5" />
    <path d="M217.31 113.98H217.34" stroke="black" stroke-width="0.5" />
    <path d="M217.36 113.98H217.39" stroke="black" stroke-width="0.5" />
    <path d="M217.41 113.98H217.44" stroke="black" stroke-width="0.5" />
    <path d="M215.48 118.98H215.93" stroke="black" stroke-width="0.5" />
    <path d="M215.48 117.67V118.98" stroke="black" stroke-width="0.5" />
    <path d="M215.93 117.67H215.48" stroke="black" stroke-width="0.5" />
    <path d="M215.93 118.98V117.67" stroke="black" stroke-width="0.5" />
    <path d="M212.27 120.57L211.89 120.48" stroke="black" stroke-width="0.5" />
    <path d="M211.89 120.48L211.61 120.25" stroke="black" stroke-width="0.5" />
    <path d="M211.61 120.25L211.5 119.93" stroke="black" stroke-width="0.5" />
    <path d="M211.5 116.73L211.61 116.41" stroke="black" stroke-width="0.5" />
    <path d="M211.61 116.41L211.89 116.17" stroke="black" stroke-width="0.5" />
    <path d="M211.89 116.17L212.27 116.09" stroke="black" stroke-width="0.5" />
    <path d="M214.18 116.09L214.57 116.17" stroke="black" stroke-width="0.5" />
    <path d="M214.57 116.17L214.85 116.41" stroke="black" stroke-width="0.5" />
    <path d="M214.85 116.41L214.95 116.73" stroke="black" stroke-width="0.5" />
    <path d="M214.95 119.93L214.85 120.25" stroke="black" stroke-width="0.5" />
    <path d="M214.85 120.25L214.57 120.48" stroke="black" stroke-width="0.5" />
    <path d="M214.57 120.48L214.18 120.57" stroke="black" stroke-width="0.5" />
    <path d="M212.27 120.57H214.18" stroke="black" stroke-width="0.5" />
    <path d="M211.5 116.73V119.93" stroke="black" stroke-width="0.5" />
    <path d="M214.18 116.09H212.27" stroke="black" stroke-width="0.5" />
    <path d="M214.95 119.93V116.73" stroke="black" stroke-width="0.5" />
    <path d="M211.06 121.01H216.41" stroke="black" stroke-width="0.5" />
    <path d="M211.06 115.65V121.01" stroke="black" stroke-width="0.5" />
    <path d="M216.41 115.65H211.06" stroke="black" stroke-width="0.5" />
    <path d="M216.41 121.01V115.65" stroke="black" stroke-width="0.5" />
    <path d="M210.69 89.43H244.04" stroke="black" stroke-width="0.5" />
    <path d="M209.35 90.76H259.2" stroke="black" stroke-width="0.5" />
    <path d="M25.62 131.33H32.05" stroke="black" stroke-width="0.5" />
    <path d="M38.45 131.33H80.16" stroke="black" stroke-width="0.5" />
    <path d="M109.78 131.33H147.31" stroke="black" stroke-width="0.5" />
    <path d="M153.71 131.33H249.99" stroke="black" stroke-width="0.5" />
    <path d="M32.05 132.66H38.45" stroke="black" stroke-width="0.5" />
    <path d="M32.05 131.33V132.66" stroke="black" stroke-width="0.5" />
    <path d="M38.45 131.33V132.66" stroke="black" stroke-width="0.5" />
    <path d="M147.31 132.66H153.71" stroke="black" stroke-width="0.5" />
    <path d="M147.31 131.33V132.66" stroke="black" stroke-width="0.5" />
    <path d="M153.71 131.33V132.66" stroke="black" stroke-width="0.5" />
    <path d="M42.39 130.26V113.98" stroke="black" stroke-width="0.5" />
    <path d="M41.06 129.99V112.65" stroke="black" stroke-width="0.5" />
    <path d="M46.66 128.66V115.58" stroke="black" stroke-width="0.5" />
    <path d="M78.82 113.98H42.39" stroke="black" stroke-width="0.5" />
    <path d="M71.75 115.58H60.54" stroke="black" stroke-width="0.5" />
    <path d="M69.92 117.72H62.37" stroke="black" stroke-width="0.5" />
    <path d="M68.09 119.85H64.2" stroke="black" stroke-width="0.5" />
    <path d="M57.34 115.58H46.66" stroke="black" stroke-width="0.5" />
    <path d="M56.03 117.18H46.66" stroke="black" stroke-width="0.5" />
    <path d="M54.29 119.32H46.66" stroke="black" stroke-width="0.5" />
    <path d="M52.55 121.45H46.66" stroke="black" stroke-width="0.5" />
    <path d="M50.8 123.59H46.66" stroke="black" stroke-width="0.5" />
    <path d="M49.06 125.72H46.66" stroke="black" stroke-width="0.5" />
    <path d="M78.82 130.26V113.98" stroke="black" stroke-width="0.5" />
    <path d="M42.39 130.26H78.82" stroke="black" stroke-width="0.5" />
    <path d="M46.66 128.66H57.34" stroke="black" stroke-width="0.5" />
    <path d="M60.54 128.66H71.75" stroke="black" stroke-width="0.5" />
    <path d="M41.73 113.06H43.26V113.57H41.73V113.06Z" fill="black" />
    <path d="M43.93 113.06H45.26V113.57H43.93V113.06Z" fill="black" />
    <path d="M45.93 113.06H47.27V113.57H45.93V113.06Z" fill="black" />
    <path d="M47.93 113.06H49.27V113.57H47.93V113.06Z" fill="black" />
    <path d="M49.93 113.06H51.27V113.57H49.93V113.06Z" fill="black" />
    <path d="M51.94 113.06H53.27V113.57H51.94V113.06Z" fill="black" />
    <path d="M53.94 113.06H55.27V113.57H53.94V113.06Z" fill="black" />
    <path d="M55.94 113.06H57.27V113.57H55.94V113.06Z" fill="black" />
    <path d="M57.94 113.06H59.27V113.57H57.94V113.06Z" fill="black" />
    <path d="M59.94 113.06H61.28V113.57H59.94V113.06Z" fill="black" />
    <path d="M61.94 113.06H63.28V113.57H61.94V113.06Z" fill="black" />
    <path d="M63.94 113.06H65.28V113.57H63.94V113.06Z" fill="black" />
    <path d="M65.95 113.06H67.28V113.57H65.95V113.06Z" fill="black" />
    <path d="M67.95 113.06H69.28V113.57H67.95V113.06Z" fill="black" />
    <path d="M69.95 113.06H71.28V113.57H69.95V113.06Z" fill="black" />
    <path d="M71.95 113.06H73.28V113.57H71.95V113.06Z" fill="black" />
    <path d="M73.95 113.06H75.29V113.57H73.95V113.06Z" fill="black" />
    <path d="M75.95 113.06H77.29V113.57H75.95V113.06Z" fill="black" />
    <path d="M77.95 113.06H79.49V113.57H77.95V113.06Z" fill="black" />
    <path d="M79.75 113.31V114.65H79.23V113.31H79.75Z" fill="black" />
    <path d="M79.75 115.32V116.65H79.23V115.32H79.75Z" fill="black" />
    <path d="M79.75 117.32V118.65H79.23V117.32H79.75Z" fill="black" />
    <path d="M79.75 119.32V120.65H79.23V119.32H79.75Z" fill="black" />
    <path d="M79.75 121.32V122.65H79.23V121.32H79.75Z" fill="black" />
    <path d="M79.75 123.32V124.66H79.23V123.32H79.75Z" fill="black" />
    <path d="M79.75 125.32V126.66H79.23V125.32H79.75Z" fill="black" />
    <path d="M79.75 127.32V128.66H79.23V127.32H79.75Z" fill="black" />
    <path d="M79.75 129.33V130.66H79.23V129.33H79.75Z" fill="black" />
    <path d="M79.49 130.92H77.95V130.4H79.49V130.92Z" fill="black" />
    <path d="M77.29 130.92H75.95V130.4H77.29V130.92Z" fill="black" />
    <path d="M75.29 130.92H73.95V130.4H75.29V130.92Z" fill="black" />
    <path d="M73.28 130.92H71.95V130.4H73.28V130.92Z" fill="black" />
    <path d="M71.28 130.92H69.95V130.4H71.28V130.92Z" fill="black" />
    <path d="M69.28 130.92H67.95V130.4H69.28V130.92Z" fill="black" />
    <path d="M67.28 130.92H65.95V130.4H67.28V130.92Z" fill="black" />
    <path d="M65.28 130.92H63.94V130.4H65.28V130.92Z" fill="black" />
    <path d="M63.28 130.92H61.94V130.4H63.28V130.92Z" fill="black" />
    <path d="M61.28 130.92H59.94V130.4H61.28V130.92Z" fill="black" />
    <path d="M59.27 130.92H57.94V130.4H59.27V130.92Z" fill="black" />
    <path d="M57.27 130.92H55.94V130.4H57.27V130.92Z" fill="black" />
    <path d="M55.27 130.92H53.94V130.4H55.27V130.92Z" fill="black" />
    <path d="M53.27 130.92H51.94V130.4H53.27V130.92Z" fill="black" />
    <path d="M51.27 130.92H49.93V130.4H51.27V130.92Z" fill="black" />
    <path d="M49.27 130.92H47.93V130.4H49.27V130.92Z" fill="black" />
    <path d="M47.27 130.92H45.93V130.4H47.27V130.92Z" fill="black" />
    <path d="M45.26 130.92H43.93V130.4H45.26V130.92Z" fill="black" />
    <path d="M43.26 130.92H41.73V130.4H43.26V130.92Z" fill="black" />
    <path d="M41.47 130.66V129.33H41.98V130.66H41.47Z" fill="black" />
    <path d="M41.47 128.66V127.32H41.98V128.66H41.47Z" fill="black" />
    <path d="M41.47 126.66V125.32H41.98V126.66H41.47Z" fill="black" />
    <path d="M41.47 124.66V123.32H41.98V124.66H41.47Z" fill="black" />
    <path d="M41.47 122.65V121.32H41.98V122.65H41.47Z" fill="black" />
    <path d="M41.47 120.65V119.32H41.98V120.65H41.47Z" fill="black" />
    <path d="M41.47 118.65V117.32H41.98V118.65H41.47Z" fill="black" />
    <path d="M41.47 116.65V115.32H41.98V116.65H41.47Z" fill="black" />
    <path d="M41.47 114.65V113.31H41.98V114.65H41.47Z" fill="black" />
    <path d="M42.39 130.26L44.24 129.44" stroke="black" stroke-width="0.5" />
    <path d="M45 129.1L46.52 128.42" stroke="black" stroke-width="0.5" />
    <path d="M47.28 128.08L48.81 127.39" stroke="black" stroke-width="0.5" />
    <path d="M49.57 127.05L51.09 126.37" stroke="black" stroke-width="0.5" />
    <path d="M51.85 126.03L53.38 125.35" stroke="black" stroke-width="0.5" />
    <path d="M54.14 125.01L55.66 124.33" stroke="black" stroke-width="0.5" />
    <path d="M56.42 123.99L57.94 123.31" stroke="black" stroke-width="0.5" />
    <path d="M58.7 122.97L60.23 122.29" stroke="black" stroke-width="0.5" />
    <path d="M60.99 121.95L62.51 121.27" stroke="black" stroke-width="0.5" />
    <path d="M63.27 120.93L64.8 120.25" stroke="black" stroke-width="0.5" />
    <path d="M65.56 119.91L67.08 119.23" stroke="black" stroke-width="0.5" />
    <path d="M67.84 118.89L69.36 118.21" stroke="black" stroke-width="0.5" />
    <path d="M70.13 117.87L71.65 117.19" stroke="black" stroke-width="0.5" />
    <path d="M72.41 116.85L73.93 116.17" stroke="black" stroke-width="0.5" />
    <path d="M74.69 115.83L76.22 115.15" stroke="black" stroke-width="0.5" />
    <path d="M76.98 114.81L78.82 113.98" stroke="black" stroke-width="0.5" />
    <path d="M42.39 113.98L44.24 114.81" stroke="black" stroke-width="0.5" />
    <path d="M45 115.15L46.52 115.83" stroke="black" stroke-width="0.5" />
    <path d="M47.28 116.17L48.81 116.85" stroke="black" stroke-width="0.5" />
    <path d="M49.57 117.19L51.09 117.87" stroke="black" stroke-width="0.5" />
    <path d="M51.85 118.21L53.38 118.89" stroke="black" stroke-width="0.5" />
    <path d="M54.14 119.23L55.66 119.91" stroke="black" stroke-width="0.5" />
    <path d="M56.42 120.25L57.94 120.93" stroke="black" stroke-width="0.5" />
    <path d="M58.7 121.27L60.23 121.95" stroke="black" stroke-width="0.5" />
    <path d="M60.99 122.29L62.51 122.97" stroke="black" stroke-width="0.5" />
    <path d="M63.27 123.31L64.8 123.99" stroke="black" stroke-width="0.5" />
    <path d="M65.56 124.33L67.08 125.01" stroke="black" stroke-width="0.5" />
    <path d="M67.84 125.35L69.36 126.03" stroke="black" stroke-width="0.5" />
    <path d="M70.13 126.37L71.65 127.05" stroke="black" stroke-width="0.5" />
    <path d="M72.41 127.39L73.93 128.08" stroke="black" stroke-width="0.5" />
    <path d="M74.69 128.42L76.22 129.1" stroke="black" stroke-width="0.5" />
    <path d="M76.98 129.44L78.82 130.26" stroke="black" stroke-width="0.5" />
    <path d="M57.34 128.66V115.58" stroke="black" stroke-width="0.5" />
    <path d="M60.54 128.66V115.58" stroke="black" stroke-width="0.5" />
    <path d="M71.75 128.66V115.58" stroke="black" stroke-width="0.5" />
    <path d="M60.54 128.66L71.75 115.58" stroke="black" stroke-width="0.5" />
    <path d="M60.54 115.58L71.75 128.66" stroke="black" stroke-width="0.5" />
    <path d="M46.66 128.66L57.34 115.58" stroke="black" stroke-width="0.5" />
    <path d="M67.97 124.26H64.32" stroke="black" stroke-width="0.5" />
    <path d="M69.8 126.39H62.49" stroke="black" stroke-width="0.5" />
    <path d="M73.76 117.27H76.57" stroke="black" stroke-width="0.5" />
    <path d="M76.57 117.27V126.79" stroke="black" stroke-width="0.5" />
    <path d="M76.57 126.79H73.76" stroke="black" stroke-width="0.5" />
    <path d="M73.76 126.79V117.27" stroke="black" stroke-width="0.5" />
    <path d="M73.76 126.79L76.57 117.27" stroke="black" stroke-width="0.5" />
    <path d="M73.76 117.27L75.17 122.03" stroke="black" stroke-width="0.5" />
    <path d="M74.57 120H75.77" stroke="black" stroke-width="0.5" />
    <path d="M74.02 118.17H76.31" stroke="black" stroke-width="0.5" />
    <path d="M80.16 121.72V131.33" stroke="black" stroke-width="0.5" />
    <path d="M80.16 121.72H85.31" stroke="black" stroke-width="0.5" />
    <path d="M104.52 121.72H109.78" stroke="black" stroke-width="0.5" />
    <path d="M41.06 112.65H80.16" stroke="black" stroke-width="0.5" />
    <path d="M80.16 112.65V120.39" stroke="black" stroke-width="0.5" />
    <path d="M80.16 120.39H109.78" stroke="black" stroke-width="0.5" />
    <path d="M109.78 120.39V112.38" stroke="black" stroke-width="0.5" />
    <path d="M109.78 112.38H148.87" stroke="black" stroke-width="0.5" />
    <path d="M148.87 112.38V128.66" stroke="black" stroke-width="0.5" />
    <path d="M147.54 113.71V129.99" stroke="black" stroke-width="0.5" />
    <path d="M153.71 129.99H183.01" stroke="black" stroke-width="0.5" />
    <path d="M184.35 129.99H217.44" stroke="black" stroke-width="0.5" />
    <path d="M218.77 129.99H249.99" stroke="black" stroke-width="0.5" />
    <path d="M41.06 129.99H25.62" stroke="black" stroke-width="0.5" />
    <path d="M84.78 120.39V121.72" stroke="black" stroke-width="0.5" />
    <path d="M85.31 120.39V121.72" stroke="black" stroke-width="0.5" />
    <path d="M104.52 121.72V120.39" stroke="black" stroke-width="0.5" />
    <path d="M105.06 121.72V120.39" stroke="black" stroke-width="0.5" />
    <path d="M109.78 120.39V131.33" stroke="black" stroke-width="0.5" />
    <path d="M111.11 129.99V113.71" stroke="black" stroke-width="0.5" />
    <path d="M119.72 128.39V115.32" stroke="black" stroke-width="0.5" />
    <path d="M147.54 113.71H111.11" stroke="black" stroke-width="0.5" />
    <path d="M142.97 117.45H135.42" stroke="black" stroke-width="0.5" />
    <path d="M141.14 119.59H137.25" stroke="black" stroke-width="0.5" />
    <path d="M130.39 115.32H119.72" stroke="black" stroke-width="0.5" />
    <path d="M129.08 116.92H119.72" stroke="black" stroke-width="0.5" />
    <path d="M127.34 119.05H119.72" stroke="black" stroke-width="0.5" />
    <path d="M125.6 121.19H119.72" stroke="black" stroke-width="0.5" />
    <path d="M123.85 123.32H119.72" stroke="black" stroke-width="0.5" />
    <path d="M122.11 125.46H119.72" stroke="black" stroke-width="0.5" />
    <path d="M111.11 129.99H147.54" stroke="black" stroke-width="0.5" />
    <path d="M119.72 128.39H130.39" stroke="black" stroke-width="0.5" />
    <path d="M133.59 128.39H144.8" stroke="black" stroke-width="0.5" />
    <path d="M110.44 112.79H111.98V113.31H110.44V112.79Z" fill="black" />
    <path d="M112.64 112.79H113.98V113.31H112.64V112.79Z" fill="black" />
    <path d="M114.65 112.79H115.98V113.31H114.65V112.79Z" fill="black" />
    <path d="M116.65 112.79H117.98V113.31H116.65V112.79Z" fill="black" />
    <path d="M118.65 112.79H119.98V113.31H118.65V112.79Z" fill="black" />
    <path d="M120.65 112.79H121.98V113.31H120.65V112.79Z" fill="black" />
    <path d="M122.65 112.79H123.99V113.31H122.65V112.79Z" fill="black" />
    <path d="M124.65 112.79H125.99V113.31H124.65V112.79Z" fill="black" />
    <path d="M126.65 112.79H127.99V113.31H126.65V112.79Z" fill="black" />
    <path d="M128.66 112.79H129.99V113.31H128.66V112.79Z" fill="black" />
    <path d="M130.66 112.79H131.99V113.31H130.66V112.79Z" fill="black" />
    <path d="M132.66 112.79H133.99V113.31H132.66V112.79Z" fill="black" />
    <path d="M134.66 112.79H135.99V113.31H134.66V112.79Z" fill="black" />
    <path d="M136.66 112.79H138V113.31H136.66V112.79Z" fill="black" />
    <path d="M138.66 112.79H140V113.31H138.66V112.79Z" fill="black" />
    <path d="M140.67 112.79H142V113.31H140.67V112.79Z" fill="black" />
    <path d="M142.67 112.79H144V113.31H142.67V112.79Z" fill="black" />
    <path d="M144.67 112.79H146V113.31H144.67V112.79Z" fill="black" />
    <path d="M146.67 112.79H148.2V113.31H146.67V112.79Z" fill="black" />
    <path d="M148.46 113.05V114.38H147.95V113.05H148.46Z" fill="black" />
    <path d="M148.46 115.05V116.38H147.95V115.05H148.46Z" fill="black" />
    <path d="M148.46 117.05V118.38H147.95V117.05H148.46Z" fill="black" />
    <path d="M148.46 119.05V120.39H147.95V119.05H148.46Z" fill="black" />
    <path d="M148.46 121.05V122.39H147.95V121.05H148.46Z" fill="black" />
    <path d="M148.46 123.06V124.39H147.95V123.06H148.46Z" fill="black" />
    <path d="M148.46 125.06V126.39H147.95V125.06H148.46Z" fill="black" />
    <path d="M148.46 127.06V128.39H147.95V127.06H148.46Z" fill="black" />
    <path d="M148.46 129.06V130.39H147.95V129.06H148.46Z" fill="black" />
    <path d="M148.2 130.65H146.67V130.14H148.2V130.65Z" fill="black" />
    <path d="M146 130.65H144.67V130.14H146V130.65Z" fill="black" />
    <path d="M144 130.65H142.67V130.14H144V130.65Z" fill="black" />
    <path d="M142 130.65H140.67V130.14H142V130.65Z" fill="black" />
    <path d="M140 130.65H138.66V130.14H140V130.65Z" fill="black" />
    <path d="M138 130.65H136.66V130.14H138V130.65Z" fill="black" />
    <path d="M135.99 130.65H134.66V130.14H135.99V130.65Z" fill="black" />
    <path d="M133.99 130.65H132.66V130.14H133.99V130.65Z" fill="black" />
    <path d="M131.99 130.65H130.66V130.14H131.99V130.65Z" fill="black" />
    <path d="M129.99 130.65H128.66V130.14H129.99V130.65Z" fill="black" />
    <path d="M127.99 130.65H126.65V130.14H127.99V130.65Z" fill="black" />
    <path d="M125.99 130.65H124.65V130.14H125.99V130.65Z" fill="black" />
    <path d="M123.99 130.65H122.65V130.14H123.99V130.65Z" fill="black" />
    <path d="M121.98 130.65H120.65V130.14H121.98V130.65Z" fill="black" />
    <path d="M119.98 130.65H118.65V130.14H119.98V130.65Z" fill="black" />
    <path d="M117.98 130.65H116.65V130.14H117.98V130.65Z" fill="black" />
    <path d="M115.98 130.65H114.65V130.14H115.98V130.65Z" fill="black" />
    <path d="M113.98 130.65H112.64V130.14H113.98V130.65Z" fill="black" />
    <path d="M111.98 130.65H110.44V130.14H111.98V130.65Z" fill="black" />
    <path d="M110.18 130.39V129.06H110.7V130.39H110.18Z" fill="black" />
    <path d="M110.18 128.39V127.06H110.7V128.39H110.18Z" fill="black" />
    <path d="M110.18 126.39V125.06H110.7V126.39H110.18Z" fill="black" />
    <path d="M110.18 124.39V123.06H110.7V124.39H110.18Z" fill="black" />
    <path d="M110.18 122.39V121.05H110.7V122.39H110.18Z" fill="black" />
    <path d="M110.18 120.39V119.05H110.7V120.39H110.18Z" fill="black" />
    <path d="M110.18 118.38V117.05H110.7V118.38H110.18Z" fill="black" />
    <path d="M110.18 116.38V115.05H110.7V116.38H110.18Z" fill="black" />
    <path d="M110.18 114.38V113.05H110.7V114.38H110.18Z" fill="black" />
    <path d="M111.11 129.99L112.95 129.17" stroke="black" stroke-width="0.5" />
    <path d="M113.72 128.83L115.24 128.15" stroke="black" stroke-width="0.5" />
    <path d="M116 127.81L117.52 127.13" stroke="black" stroke-width="0.5" />
    <path d="M118.28 126.79L119.81 126.11" stroke="black" stroke-width="0.5" />
    <path d="M120.57 125.77L122.09 125.09" stroke="black" stroke-width="0.5" />
    <path d="M122.85 124.75L124.37 124.07" stroke="black" stroke-width="0.5" />
    <path d="M125.14 123.73L126.66 123.04" stroke="black" stroke-width="0.5" />
    <path d="M127.42 122.7L128.94 122.02" stroke="black" stroke-width="0.5" />
    <path d="M129.7 121.68L131.23 121" stroke="black" stroke-width="0.5" />
    <path d="M131.99 120.66L133.51 119.98" stroke="black" stroke-width="0.5" />
    <path d="M134.27 119.64L135.8 118.96" stroke="black" stroke-width="0.5" />
    <path d="M136.56 118.62L138.08 117.94" stroke="black" stroke-width="0.5" />
    <path d="M138.84 117.6L140.36 116.92" stroke="black" stroke-width="0.5" />
    <path d="M141.12 116.58L142.65 115.9" stroke="black" stroke-width="0.5" />
    <path d="M143.41 115.56L144.93 114.88" stroke="black" stroke-width="0.5" />
    <path d="M145.69 114.54L147.54 113.71" stroke="black" stroke-width="0.5" />
    <path d="M111.11 113.71L112.95 114.54" stroke="black" stroke-width="0.5" />
    <path d="M113.72 114.88L115.24 115.56" stroke="black" stroke-width="0.5" />
    <path d="M116 115.9L117.52 116.58" stroke="black" stroke-width="0.5" />
    <path d="M118.28 116.92L119.81 117.6" stroke="black" stroke-width="0.5" />
    <path d="M120.57 117.94L122.09 118.62" stroke="black" stroke-width="0.5" />
    <path d="M122.85 118.96L124.37 119.64" stroke="black" stroke-width="0.5" />
    <path d="M125.14 119.98L126.66 120.66" stroke="black" stroke-width="0.5" />
    <path d="M127.42 121L128.94 121.68" stroke="black" stroke-width="0.5" />
    <path d="M129.7 122.02L131.23 122.7" stroke="black" stroke-width="0.5" />
    <path d="M131.99 123.04L133.51 123.73" stroke="black" stroke-width="0.5" />
    <path d="M134.27 124.07L135.8 124.75" stroke="black" stroke-width="0.5" />
    <path d="M136.56 125.09L138.08 125.77" stroke="black" stroke-width="0.5" />
    <path d="M138.84 126.11L140.36 126.79" stroke="black" stroke-width="0.5" />
    <path d="M141.12 127.13L142.65 127.81" stroke="black" stroke-width="0.5" />
    <path d="M143.41 128.15L144.93 128.83" stroke="black" stroke-width="0.5" />
    <path d="M145.69 129.17L147.54 129.99" stroke="black" stroke-width="0.5" />
    <path d="M130.39 128.39V115.32" stroke="black" stroke-width="0.5" />
    <path d="M133.59 128.39V115.32" stroke="black" stroke-width="0.5" />
    <path d="M144.8 128.39V115.32" stroke="black" stroke-width="0.5" />
    <path d="M133.59 128.39L144.8 115.32" stroke="black" stroke-width="0.5" />
    <path d="M133.59 115.32L144.8 128.39" stroke="black" stroke-width="0.5" />
    <path d="M119.72 128.39L130.39 115.32" stroke="black" stroke-width="0.5" />
    <path d="M141.03 123.99H137.37" stroke="black" stroke-width="0.5" />
    <path d="M142.86 126.12H135.54" stroke="black" stroke-width="0.5" />
    <path d="M116.17 117.01H113.36" stroke="black" stroke-width="0.5" />
    <path d="M113.36 117.01V126.52" stroke="black" stroke-width="0.5" />
    <path d="M113.36 126.52H116.17" stroke="black" stroke-width="0.5" />
    <path d="M116.17 126.52V117.01" stroke="black" stroke-width="0.5" />
    <path d="M114.77 121.76L113.36 117.01" stroke="black" stroke-width="0.5" />
    <path d="M115.37 119.73L114.17 119.74" stroke="black" stroke-width="0.5" />
    <path d="M115.91 117.9H113.62" stroke="black" stroke-width="0.5" />
    <path d="M133.59 115.32H144.8" stroke="black" stroke-width="0.5" />
    <path d="M113.36 126.52L116.17 117.01" stroke="black" stroke-width="0.5" />
    <path d="M24.38 25.62H39.64" stroke="black" stroke-width="0.5" />
    <path d="M40.29 25.62V16.57" stroke="black" stroke-width="0.5" />
    <path d="M40.29 16.57L43.7 15.39" stroke="black" stroke-width="0.5" />
    <path d="M43.7 15.39L43.59 15.01" stroke="black" stroke-width="0.5" />
    <path d="M43.59 15.01L54.27 11.86" stroke="black" stroke-width="0.5" />
    <path d="M54.27 11.86L54.38 12.23" stroke="black" stroke-width="0.5" />
    <path
      d="M74.04 8.51999C67.4138 9.33311 60.8469 10.5724 54.38 12.23"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M74.04 8.52001L73.98 7.89001" stroke="black" stroke-width="0.5" />
    <path d="M73.98 7.89L85.21 6.94" stroke="black" stroke-width="0.5" />
    <path d="M85.21 6.94V7.55" stroke="black" stroke-width="0.5" />
    <path d="M85.21 7.54999H100.72" stroke="black" stroke-width="0.5" />
    <path d="M100.72 7.55L100.77 6.92999" stroke="black" stroke-width="0.5" />
    <path
      d="M100.77 6.92999L111.94 7.88999"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M111.94 7.89001L111.86 8.51002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M111.86 8.51001L131.56 12.24" stroke="black" stroke-width="0.5" />
    <path d="M131.56 12.23L131.86 11.17" stroke="black" stroke-width="0.5" />
    <path d="M131.86 11.17L142.58 14.2" stroke="black" stroke-width="0.5" />
    <path d="M142.58 14.2L142.17 15.37" stroke="black" stroke-width="0.5" />
    <path d="M142.17 15.37L145.64 16.57" stroke="black" stroke-width="0.5" />
    <path d="M145.64 16.57L145.67 25.62" stroke="black" stroke-width="0.5" />
    <path d="M145.67 25.62H161.55" stroke="black" stroke-width="0.5" />
    <path d="M190.9 25.62H212.79" stroke="black" stroke-width="0.5" />
    <path d="M153.71 129.99V128.66" stroke="black" stroke-width="0.5" />
    <path d="M153.71 128.66H148.87" stroke="black" stroke-width="0.5" />
    <path d="M175.14 71.06H170.18" stroke="black" stroke-width="0.5" />
    <path d="M170.18 71.06V70.18" stroke="black" stroke-width="0.5" />
    <path d="M170.18 70.18H175.14" stroke="black" stroke-width="0.5" />
    <path d="M175.14 70.18V71.06" stroke="black" stroke-width="0.5" />
    <path d="M174.7 70.18H170.73" stroke="black" stroke-width="0.5" />
    <path d="M170.73 70.18V69.41" stroke="black" stroke-width="0.5" />
    <path d="M170.73 69.41H174.7" stroke="black" stroke-width="0.5" />
    <path d="M174.7 69.41V70.18" stroke="black" stroke-width="0.5" />
    <path d="M12.77 64.54L12.59 62.67" stroke="black" stroke-width="0.5" />
    <path d="M12.59 62.67L12.04 60.87" stroke="black" stroke-width="0.5" />
    <path d="M12.04 60.87L11.15 59.2" stroke="black" stroke-width="0.5" />
    <path d="M11.15 59.2L9.95999 57.75" stroke="black" stroke-width="0.5" />
    <path d="M9.95999 57.75L8.5 56.56" stroke="black" stroke-width="0.5" />
    <path d="M8.5 56.56L6.84 55.67" stroke="black" stroke-width="0.5" />
    <path d="M6.84 55.67L5.04001 55.12" stroke="black" stroke-width="0.5" />
    <path d="M5.04001 55.12L3.16 54.94" stroke="black" stroke-width="0.5" />
    <path d="M3.17001 64.55H3.63" stroke="black" stroke-width="0.5" />
    <path d="M3.63 64.55V54.95" stroke="black" stroke-width="0.5" />
    <path d="M3.16 54.94L3.17001 64.55" stroke="black" stroke-width="0.5" />
    <path d="M15.97 83.23L15.79 85.1" stroke="black" stroke-width="0.5" />
    <path d="M15.79 85.1L15.24 86.91" stroke="black" stroke-width="0.5" />
    <path d="M15.24 86.91L14.35 88.57" stroke="black" stroke-width="0.5" />
    <path d="M14.35 88.57L13.16 90.02" stroke="black" stroke-width="0.5" />
    <path d="M13.16 90.02L11.7 91.22" stroke="black" stroke-width="0.5" />
    <path d="M11.7 91.22L10.04 92.1" stroke="black" stroke-width="0.5" />
    <path d="M10.04 92.1L8.23999 92.65" stroke="black" stroke-width="0.5" />
    <path d="M8.23999 92.65L6.35999 92.83" stroke="black" stroke-width="0.5" />
    <path d="M6.37 83.23H6.82999" stroke="black" stroke-width="0.5" />
    <path d="M6.82999 83.23V92.82" stroke="black" stroke-width="0.5" />
    <path d="M6.35999 92.83L6.37 83.23" stroke="black" stroke-width="0.5" />
    <path d="M14.74 122.99L14.93 124.86" stroke="black" stroke-width="0.5" />
    <path d="M14.93 124.86L15.47 126.66" stroke="black" stroke-width="0.5" />
    <path d="M15.47 126.66L16.36 128.32" stroke="black" stroke-width="0.5" />
    <path d="M16.36 128.32L17.56 129.78" stroke="black" stroke-width="0.5" />
    <path d="M17.56 129.78L19.01 130.97" stroke="black" stroke-width="0.5" />
    <path d="M19.01 130.97L20.68 131.86" stroke="black" stroke-width="0.5" />
    <path d="M20.68 131.86L22.48 132.41" stroke="black" stroke-width="0.5" />
    <path d="M22.48 132.41L24.35 132.59" stroke="black" stroke-width="0.5" />
    <path d="M24.35 122.98H23.88" stroke="black" stroke-width="0.5" />
    <path d="M23.88 122.98L23.89 132.58" stroke="black" stroke-width="0.5" />
    <path d="M24.35 132.59V122.98" stroke="black" stroke-width="0.5" />
    <path d="M61.75 48.03L61.93 46.16" stroke="black" stroke-width="0.5" />
    <path d="M61.93 46.16L62.48 44.35" stroke="black" stroke-width="0.5" />
    <path d="M62.48 44.35L63.37 42.69" stroke="black" stroke-width="0.5" />
    <path d="M63.37 42.69L64.56 41.24" stroke="black" stroke-width="0.5" />
    <path d="M64.56 41.24L66.02 40.04" stroke="black" stroke-width="0.5" />
    <path d="M66.02 40.04L67.68 39.16" stroke="black" stroke-width="0.5" />
    <path d="M67.68 39.16L69.48 38.61" stroke="black" stroke-width="0.5" />
    <path d="M69.48 38.61L71.36 38.43" stroke="black" stroke-width="0.5" />
    <path d="M71.35 48.03H70.89" stroke="black" stroke-width="0.5" />
    <path d="M70.89 48.03V38.44" stroke="black" stroke-width="0.5" />
    <path d="M71.36 38.43L71.35 48.03" stroke="black" stroke-width="0.5" />
    <path d="M100.94 48.03L101.13 46.16" stroke="black" stroke-width="0.5" />
    <path d="M101.13 46.16L101.67 44.35" stroke="black" stroke-width="0.5" />
    <path d="M101.67 44.35L102.56 42.69" stroke="black" stroke-width="0.5" />
    <path d="M102.56 42.69L103.76 41.24" stroke="black" stroke-width="0.5" />
    <path d="M103.76 41.24L105.21 40.04" stroke="black" stroke-width="0.5" />
    <path d="M105.21 40.04L106.88 39.16" stroke="black" stroke-width="0.5" />
    <path d="M106.88 39.16L108.68 38.61" stroke="black" stroke-width="0.5" />
    <path d="M108.68 38.61L110.55 38.43" stroke="black" stroke-width="0.5" />
    <path d="M110.55 48.03H110.08" stroke="black" stroke-width="0.5" />
    <path d="M110.08 48.03L110.09 38.44" stroke="black" stroke-width="0.5" />
    <path d="M110.55 38.43V48.03" stroke="black" stroke-width="0.5" />
    <path d="M134.72 48.03L134.9 46.16" stroke="black" stroke-width="0.5" />
    <path d="M134.9 46.16L135.45 44.35" stroke="black" stroke-width="0.5" />
    <path d="M135.45 44.35L136.34 42.69" stroke="black" stroke-width="0.5" />
    <path d="M136.34 42.69L137.53 41.24" stroke="black" stroke-width="0.5" />
    <path d="M137.53 41.24L138.99 40.04" stroke="black" stroke-width="0.5" />
    <path d="M138.99 40.04L140.65 39.16" stroke="black" stroke-width="0.5" />
    <path d="M140.65 39.16L142.45 38.61" stroke="black" stroke-width="0.5" />
    <path d="M142.45 38.61L144.33 38.43" stroke="black" stroke-width="0.5" />
    <path d="M144.32 48.03H143.86" stroke="black" stroke-width="0.5" />
    <path d="M143.86 48.03V38.44" stroke="black" stroke-width="0.5" />
    <path d="M144.33 38.43L144.32 48.03" stroke="black" stroke-width="0.5" />
    <path d="M55.24 53.6L57.12 53.79" stroke="black" stroke-width="0.5" />
    <path d="M57.12 53.79L58.92 54.34" stroke="black" stroke-width="0.5" />
    <path d="M58.92 54.34L60.58 55.23" stroke="black" stroke-width="0.5" />
    <path d="M60.58 55.23L62.03 56.42" stroke="black" stroke-width="0.5" />
    <path d="M62.03 56.42L63.23 57.88" stroke="black" stroke-width="0.5" />
    <path d="M63.23 57.88L64.12 59.54" stroke="black" stroke-width="0.5" />
    <path d="M64.12 59.54L64.66 61.34" stroke="black" stroke-width="0.5" />
    <path d="M64.66 61.34L64.85 63.22" stroke="black" stroke-width="0.5" />
    <path d="M55.24 63.21V62.74" stroke="black" stroke-width="0.5" />
    <path d="M55.24 62.74L64.83 62.75" stroke="black" stroke-width="0.5" />
    <path d="M64.85 63.22L55.24 63.21" stroke="black" stroke-width="0.5" />
    <path d="M67.47 65.88L67.65 67.76" stroke="black" stroke-width="0.5" />
    <path d="M67.65 67.76L68.2 69.56" stroke="black" stroke-width="0.5" />
    <path d="M68.2 69.56L69.09 71.22" stroke="black" stroke-width="0.5" />
    <path d="M69.09 71.22L70.28 72.68" stroke="black" stroke-width="0.5" />
    <path d="M70.28 72.68L71.74 73.87" stroke="black" stroke-width="0.5" />
    <path d="M71.74 73.87L73.4 74.76" stroke="black" stroke-width="0.5" />
    <path d="M73.4 74.76L75.2 75.3" stroke="black" stroke-width="0.5" />
    <path d="M75.2 75.3L77.08 75.49" stroke="black" stroke-width="0.5" />
    <path d="M77.07 65.88H76.61" stroke="black" stroke-width="0.5" />
    <path d="M76.61 65.88V75.48" stroke="black" stroke-width="0.5" />
    <path d="M77.08 75.49L77.07 65.88" stroke="black" stroke-width="0.5" />
    <path d="M157.84 68.84L157.66 66.97" stroke="black" stroke-width="0.5" />
    <path d="M157.66 66.97L157.11 65.17" stroke="black" stroke-width="0.5" />
    <path d="M157.11 65.17L156.22 63.51" stroke="black" stroke-width="0.5" />
    <path d="M156.22 63.51L155.03 62.05" stroke="black" stroke-width="0.5" />
    <path d="M155.03 62.05L153.57 60.86" stroke="black" stroke-width="0.5" />
    <path d="M153.57 60.86L151.91 59.97" stroke="black" stroke-width="0.5" />
    <path d="M151.91 59.97L150.11 59.43" stroke="black" stroke-width="0.5" />
    <path d="M150.11 59.43L148.23 59.24" stroke="black" stroke-width="0.5" />
    <path d="M148.24 68.85H148.71" stroke="black" stroke-width="0.5" />
    <path d="M148.71 68.85L148.7 59.25" stroke="black" stroke-width="0.5" />
    <path d="M148.23 59.24L148.24 68.85" stroke="black" stroke-width="0.5" />
    <path d="M193.87 68.84L193.69 66.97" stroke="black" stroke-width="0.5" />
    <path d="M193.69 66.97L193.14 65.17" stroke="black" stroke-width="0.5" />
    <path d="M193.14 65.17L192.25 63.51" stroke="black" stroke-width="0.5" />
    <path d="M192.25 63.51L191.05 62.05" stroke="black" stroke-width="0.5" />
    <path d="M191.05 62.05L189.6 60.86" stroke="black" stroke-width="0.5" />
    <path d="M189.6 60.86L187.94 59.97" stroke="black" stroke-width="0.5" />
    <path d="M187.94 59.97L186.13 59.43" stroke="black" stroke-width="0.5" />
    <path d="M186.13 59.43L184.26 59.24" stroke="black" stroke-width="0.5" />
    <path d="M184.26 68.85H184.73" stroke="black" stroke-width="0.5" />
    <path d="M184.73 68.85V59.25" stroke="black" stroke-width="0.5" />
    <path d="M184.26 59.24V68.85" stroke="black" stroke-width="0.5" />
    <path d="M167.27 87.55L167.08 89.42" stroke="black" stroke-width="0.5" />
    <path d="M167.08 89.42L166.53 91.23" stroke="black" stroke-width="0.5" />
    <path d="M166.53 91.23L165.64 92.89" stroke="black" stroke-width="0.5" />
    <path d="M165.64 92.89L164.45 94.34" stroke="black" stroke-width="0.5" />
    <path d="M164.45 94.34L162.99 95.54" stroke="black" stroke-width="0.5" />
    <path d="M162.99 95.54L161.33 96.42" stroke="black" stroke-width="0.5" />
    <path d="M161.33 96.42L159.53 96.97" stroke="black" stroke-width="0.5" />
    <path d="M159.53 96.97L157.65 97.15" stroke="black" stroke-width="0.5" />
    <path d="M157.66 87.54H158.13" stroke="black" stroke-width="0.5" />
    <path d="M158.13 87.54L158.12 97.14" stroke="black" stroke-width="0.5" />
    <path d="M157.65 97.15L157.66 87.54" stroke="black" stroke-width="0.5" />
    <path d="M195.15 87.55L194.96 89.42" stroke="black" stroke-width="0.5" />
    <path d="M194.96 89.42L194.42 91.23" stroke="black" stroke-width="0.5" />
    <path d="M194.42 91.23L193.53 92.89" stroke="black" stroke-width="0.5" />
    <path d="M193.53 92.89L192.33 94.34" stroke="black" stroke-width="0.5" />
    <path d="M192.33 94.34L190.88 95.54" stroke="black" stroke-width="0.5" />
    <path d="M190.88 95.54L189.22 96.42" stroke="black" stroke-width="0.5" />
    <path d="M189.22 96.42L187.41 96.97" stroke="black" stroke-width="0.5" />
    <path d="M187.41 96.97L185.54 97.15" stroke="black" stroke-width="0.5" />
    <path d="M185.54 87.54H186.01" stroke="black" stroke-width="0.5" />
    <path d="M186.01 87.54V97.14" stroke="black" stroke-width="0.5" />
    <path d="M185.54 97.15V87.54" stroke="black" stroke-width="0.5" />
    <path d="M146.98 100.83L148.85 100.65" stroke="black" stroke-width="0.5" />
    <path d="M148.85 100.65L150.66 100.1" stroke="black" stroke-width="0.5" />
    <path d="M150.66 100.1L152.32 99.21" stroke="black" stroke-width="0.5" />
    <path d="M152.32 99.21L153.77 98.02" stroke="black" stroke-width="0.5" />
    <path d="M153.77 98.02L154.97 96.57" stroke="black" stroke-width="0.5" />
    <path d="M154.97 96.57L155.86 94.9" stroke="black" stroke-width="0.5" />
    <path d="M155.86 94.9L156.41 93.1" stroke="black" stroke-width="0.5" />
    <path d="M156.41 93.1L156.59 91.23" stroke="black" stroke-width="0.5" />
    <path d="M146.98 91.22V91.69" stroke="black" stroke-width="0.5" />
    <path d="M146.98 91.69L156.58 91.7" stroke="black" stroke-width="0.5" />
    <path d="M156.59 91.23L146.98 91.22" stroke="black" stroke-width="0.5" />
    <path d="M94.92 121.72L95.1 123.6" stroke="black" stroke-width="0.5" />
    <path d="M95.1 123.6L95.65 125.4" stroke="black" stroke-width="0.5" />
    <path d="M95.65 125.4L96.54 127.06" stroke="black" stroke-width="0.5" />
    <path d="M96.54 127.06L97.73 128.52" stroke="black" stroke-width="0.5" />
    <path d="M97.73 128.52L99.19 129.71" stroke="black" stroke-width="0.5" />
    <path d="M99.19 129.71L100.85 130.6" stroke="black" stroke-width="0.5" />
    <path d="M100.85 130.6L102.65 131.14" stroke="black" stroke-width="0.5" />
    <path d="M102.65 131.14L104.53 131.33" stroke="black" stroke-width="0.5" />
    <path d="M104.52 121.72H104.06" stroke="black" stroke-width="0.5" />
    <path d="M104.06 121.72V131.32" stroke="black" stroke-width="0.5" />
    <path d="M104.53 131.33L104.52 121.72" stroke="black" stroke-width="0.5" />
    <path d="M94.92 121.72L94.73 123.6" stroke="black" stroke-width="0.5" />
    <path d="M94.73 123.6L94.18 125.4" stroke="black" stroke-width="0.5" />
    <path d="M94.18 125.4L93.3 127.06" stroke="black" stroke-width="0.5" />
    <path d="M93.3 127.06L92.1 128.52" stroke="black" stroke-width="0.5" />
    <path d="M92.1 128.52L90.64 129.71" stroke="black" stroke-width="0.5" />
    <path d="M90.64 129.71L88.98 130.6" stroke="black" stroke-width="0.5" />
    <path d="M88.98 130.6L87.18 131.14" stroke="black" stroke-width="0.5" />
    <path d="M87.18 131.14L85.31 131.33" stroke="black" stroke-width="0.5" />
    <path d="M85.31 121.72H85.78" stroke="black" stroke-width="0.5" />
    <path d="M85.78 121.72L85.77 131.32" stroke="black" stroke-width="0.5" />
    <path d="M85.31 131.33V121.72" stroke="black" stroke-width="0.5" />
    <path d="M210.69 89.43V25.62" stroke="black" stroke-width="0.5" />
    <path d="M209.35 90.76V25.62" stroke="black" stroke-width="0.5" />
    <path d="M218.77 129.99H247.19" stroke="black" stroke-width="0.5" />
    <path d="M218.77 114.25H226.64" stroke="black" stroke-width="0.5" />
    <path d="M218.77 112.91H226.64" stroke="black" stroke-width="0.5" />
    <path d="M219.07 123.59L219.03 123.61" stroke="black" stroke-width="0.5" />
    <path d="M219.03 123.61L219 123.62" stroke="black" stroke-width="0.5" />
    <path d="M219 123.62L218.97 123.65" stroke="black" stroke-width="0.5" />
    <path d="M218.97 123.65L218.94 123.67" stroke="black" stroke-width="0.5" />
    <path d="M218.94 123.67L218.92 123.71" stroke="black" stroke-width="0.5" />
    <path d="M218.92 123.71L218.9 123.75" stroke="black" stroke-width="0.5" />
    <path d="M218.9 123.75L218.88 123.81" stroke="black" stroke-width="0.5" />
    <path d="M218.88 123.81L218.86 123.87" stroke="black" stroke-width="0.5" />
    <path d="M218.86 123.87L218.85 123.94" stroke="black" stroke-width="0.5" />
    <path d="M218.85 123.94L218.83 124.03" stroke="black" stroke-width="0.5" />
    <path d="M218.83 124.03L218.82 124.13" stroke="black" stroke-width="0.5" />
    <path d="M218.82 124.13L218.81 124.24" stroke="black" stroke-width="0.5" />
    <path d="M218.81 124.24V124.37" stroke="black" stroke-width="0.5" />
    <path d="M218.81 124.37L218.8 124.51" stroke="black" stroke-width="0.5" />
    <path d="M218.8 124.51L218.79 124.67" stroke="black" stroke-width="0.5" />
    <path d="M218.79 124.67V124.84" stroke="black" stroke-width="0.5" />
    <path d="M218.79 124.84L218.78 125.03" stroke="black" stroke-width="0.5" />
    <path d="M218.78 125.03V125.22" stroke="black" stroke-width="0.5" />
    <path d="M218.78 125.22L218.77 125.43" stroke="black" stroke-width="0.5" />
    <path d="M218.77 125.43V125.64" stroke="black" stroke-width="0.5" />
    <path d="M218.77 125.64V125.86" stroke="black" stroke-width="0.5" />
    <path d="M218.77 125.86V126.08" stroke="black" stroke-width="0.5" />
    <path d="M218.77 126.08V126.3" stroke="black" stroke-width="0.5" />
    <path d="M218.77 126.3V126.51" stroke="black" stroke-width="0.5" />
    <path d="M218.77 126.51V126.72" stroke="black" stroke-width="0.5" />
    <path d="M218.77 126.72L218.78 126.93" stroke="black" stroke-width="0.5" />
    <path d="M218.78 126.93V127.13" stroke="black" stroke-width="0.5" />
    <path d="M218.78 127.13L218.79 127.31" stroke="black" stroke-width="0.5" />
    <path d="M218.79 127.31V127.49" stroke="black" stroke-width="0.5" />
    <path d="M218.79 127.49L218.8 127.65" stroke="black" stroke-width="0.5" />
    <path d="M218.8 127.65L218.81 127.79" stroke="black" stroke-width="0.5" />
    <path d="M218.81 127.79L218.82 127.92" stroke="black" stroke-width="0.5" />
    <path d="M218.82 127.92L218.83 128.03" stroke="black" stroke-width="0.5" />
    <path d="M218.83 128.03L218.84 128.13" stroke="black" stroke-width="0.5" />
    <path d="M218.84 128.13L218.85 128.21" stroke="black" stroke-width="0.5" />
    <path d="M218.85 128.21L218.87 128.28" stroke="black" stroke-width="0.5" />
    <path d="M218.87 128.28L218.88 128.35" stroke="black" stroke-width="0.5" />
    <path d="M218.88 128.35L218.9 128.4" stroke="black" stroke-width="0.5" />
    <path d="M218.9 128.4L218.92 128.44" stroke="black" stroke-width="0.5" />
    <path d="M218.92 128.44L218.95 128.48" stroke="black" stroke-width="0.5" />
    <path d="M218.95 128.48L218.97 128.5" stroke="black" stroke-width="0.5" />
    <path d="M218.97 128.5L219 128.52" stroke="black" stroke-width="0.5" />
    <path d="M219 128.52L219.04 128.54" stroke="black" stroke-width="0.5" />
    <path d="M219.04 128.54L219.07 128.55" stroke="black" stroke-width="0.5" />
    <path d="M224.12 115.68H218.77" stroke="black" stroke-width="0.5" />
    <path d="M218.77 121.04H224.12" stroke="black" stroke-width="0.5" />
    <path d="M218.77 115.68V121.04" stroke="black" stroke-width="0.5" />
    <path d="M218.77 121.04H219.76" stroke="black" stroke-width="0.5" />
    <path d="M219.76 122.47H218.77" stroke="black" stroke-width="0.5" />
    <path d="M218.77 122.47V121.04" stroke="black" stroke-width="0.5" />
    <path d="M218.77 115.68H219.76" stroke="black" stroke-width="0.5" />
    <path d="M219.76 114.25H218.77" stroke="black" stroke-width="0.5" />
    <path d="M218.77 114.25V115.68" stroke="black" stroke-width="0.5" />
  </svg>
</template>
