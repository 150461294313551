import { markRaw, defineAsyncComponent } from 'vue';
import { store } from '@/store';

import LoadingComponent from '@/Core.Service.Domain/Controls/DigitalTwin/Components/ImageLoader.vue';

const FirstFloor = defineAsyncComponent({
  loader: () =>
    import(
      '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/6822-01.vue'
    ).then((module) => {
      store.dispatch('DigitalTwinStore/addComponentToCachedList', '6822-01');
      return module.default;
    }),

  loadingComponent: LoadingComponent,

  delay: 0,
});

// Suites
import Suite100 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesSchematics/6822-01-suite-100.vue';
import Suite109 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesSchematics/6822-01-suite-109.vue';
import Suite121 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesSchematics/6822-01-suite-121.vue';
import Suite103 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesSchematics/6822-01-suite-103.vue';
import Suite107 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesSchematics/6822-01-suite-107.vue';

// Hotspots
import Suite101Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesHotspots/suite100Drawing.vue';
import Suite109Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesHotspots/suite109Drawing.vue';
import Suite121Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesHotspots/suite121Drawing.vue';
import Suite103Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesHotspots/suite103Drawing.vue';
import Suite107Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesHotspots/suite107Drawing.vue';

// VAVs in the Common area
import VAV11 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/CommonArea/VAV11Drawing.vue';
import VAV12 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/CommonArea/VAV12Drawing.vue';
import VAV13 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/CommonArea/VAV13Drawing.vue';
import VAV14 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/CommonArea/VAV14Drawing.vue';
import VAV15 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/CommonArea/VAV15Drawing.vue';
import VAV16 from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/CommonArea/VAV16Drawing.vue';

// VAVs in suites
import Suite121ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_1st_Floor/suite121ControlsList';
import Suite100ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_1st_Floor/suite100ControlsList';
import Suite107ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_1st_Floor/suite107ControlsList';
import Suite109ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_1st_Floor/suite109ControlsList';
import Suite103ControlsList from '@/Core.Service.Domain/Controls/DigitalTwin/API/MGMOB_1st_Floor/suite103ControlsList';

import {
  dialog,
  childTab,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors';

import { IFloor } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import eventBus from '@/Core.Service.Domain/Shared/eventBus';

const handleAction = (tab: MouseEvent | KeyboardEvent | number) => {
  if (typeof tab === 'number') {
    dialog.value = true;
    childTab.value = tab;
    eventBus.$emit('update:selectedVAVId', 0);
  }
};

const handleVAVAction = async (
  controlId: MouseEvent | KeyboardEvent | number
) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = true;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const firstFloor: IFloor<Record<string, unknown>> = {
  id: 0,
  name: 'MGMOB - 1st Floor',
  ahuId: 2090,
  loadedName: '6822-01',
  component: markRaw(FirstFloor),
  showAHUButton: true,
  hotspotsList: [
    // Suite 121
    {
      id: 0,
      name: 'Suite 121',
      component: markRaw(Suite121Hotspot),
      techInfo: {
        location: 'Suite 121',
        icon: '',
        devices: [
          'VAV 1-1',
          'VAV 1-2',
          'VAV 1-3',
          'VAV 1-4',
          'VAV 1-5',
          'VAV 1-6',
          'VAV 1-7',
          'VAV 1-8',
          'VAV 1-9',
          'VAV 1-10',
          'VAV 1-11',
          'VAV 1-12',
          'VAV 1-13',
          'VAV 1-14',
          'VAV 1-15',
          'VAV 1-16',
          'VAV 1-17',
          'VAV 1-18',
          'VAV 1-19',
          'VAV 1-20',
          'VAV 1-21',
        ],
      },
      style: {
        position: 'absolute',
        top: '0.18%',
        left: '-23%',
        width: '99.1%',
        height: '99.1%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '0.2%',
        left: '-21.7%',
        width: '96.5%',
        height: '96.5%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '45%',
        left: '23%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 109
    {
      id: 1,
      name: 'Suite 109',
      component: markRaw(Suite109Hotspot),
      techInfo: {
        location: 'Suite 109',
        icon: '',
        devices: ['VAV 1-1', 'VAV 1-2'],
      },
      style: {
        position: 'absolute',
        top: '0.18%',
        right: '19.85%',
        width: '37.7%',
        height: '37.7%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '0.2%',
        right: '20.2%',
        width: '36.8%',
        height: '36.8%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '20%',
        right: '37%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 107
    {
      id: 2,
      name: 'Suite 107',
      component: markRaw(Suite107Hotspot),
      techInfo: {
        location: 'Suite 107',
        icon: '',
        devices: ['VAV 1-38', 'VAV 1-39', 'VAV 1-40'],
      },
      style: {
        position: 'absolute',
        top: '5.2%',
        right: '0.65%',
        width: '33.7%',
        height: '33.7%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '5.07%',
        right: '1.15%',
        width: '32.85%',
        height: '32.85%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        top: '20%',
        right: '15.5%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 103
    {
      id: 3,
      name: 'Suite 103',
      component: markRaw(Suite103Hotspot),
      techInfo: {
        location: 'Suite 103',
        icon: '',
        devices: ['VAV 1-59', 'VAV 1-60'],
      },
      style: {
        position: 'absolute',
        top: '44.3%',
        right: '-13.7%',
        width: '48.5%',
        height: '48.5%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        top: '42.5%',
        right: '-13.5%',
        width: '48%',
        height: '48%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        bottom: '30%',
        right: '8.5%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // Suite 100
    {
      id: 4,
      name: 'Suite 100',
      component: markRaw(Suite101Hotspot),
      techInfo: {
        location: 'Suite 100',
        icon: '',
        devices: ['VAV 1-11', 'VAV 1-12', 'VAV 1-13', 'VAV 1-14'],
      },
      style: {
        position: 'absolute',
        bottom: '0.63%',
        right: '4.5%',
        width: '55.2%',
        height: '55.2%',
        cursor: 'pointer',
      },
      miniMapStyle: {
        bottom: '3.2%',
        right: '4.8%',
        width: '54.3%',
        height: '54.3%',
      },
      hotSpotAction: (tab: MouseEvent | KeyboardEvent | number) => {
        handleAction(tab);
      },
      chipStyle: {
        position: 'absolute',
        bottom: '30%',
        right: '28.5%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 1-6
    {
      id: 5,
      name: 'VAV 1-6',
      controlId: 2136,
      isFloorVAV: true,
      component: markRaw(VAV16),
      techInfo: {
        location: 'VAV 1-6',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '1.65%',
        right: '31.65%',
        width: '36.75%',
        height: '36.75%',
        cursor: 'pointer',
      },
      hotSpotAction: async (controlId: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '21%',
        right: '48.3%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 1-5
    {
      id: 6,
      name: 'VAV 1-5',
      controlId: 2095,
      isFloorVAV: true,
      component: markRaw(VAV15),
      techInfo: {
        location: 'VAV 1-5',
        icon: '',
      },
      style: {
        position: 'absolute',
        bottom: '2.75%',
        right: '38.3%',
        width: '22.2%',
        height: '22.2%',
        cursor: 'pointer',
      },
      hotSpotAction: async (controlId: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        bottom: '15%',
        right: '48.3%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 1-2
    {
      id: 7,
      name: 'VAV 1-2',
      controlId: 2094,
      isFloorVAV: true,
      component: markRaw(VAV12),
      techInfo: {
        location: 'VAV 1-2',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '17.7%',
        right: '4.6%',
        width: '43.95%',
        height: '43.95%',
        cursor: 'pointer',
      },
      hotSpotAction: async (controlId: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '39.5%',
        right: '25%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 1-3
    {
      id: 8,
      name: 'VAV 1-3',
      controlId: 2135,
      isFloorVAV: true,
      component: markRaw(VAV13),
      techInfo: {
        location: 'VAV 1-3',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '47.4%',
        right: '35.5%',
        width: '28%',
        height: '28%',
        cursor: 'pointer',
      },
      hotSpotAction: async (controlId: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '58%',
        right: '48.3%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 1-1
    {
      id: 9,
      name: 'VAV 1-1',
      controlId: 2135,
      isFloorVAV: true,
      disabled: true,
      component: markRaw(VAV11),
      techInfo: {
        location: 'VAV 1-1',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '42.48%',
        right: '51.6%',
        width: '10.52%',
        height: '10.52%',
        cursor: 'pointer',
      },
      hotSpotAction: async (controlId: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '46.4%',
        right: '57.9%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
    // VAV 1-4
    {
      id: 10,
      name: 'VAV 1-4',
      controlId: 2135,
      isFloorVAV: true,
      disabled: true,
      component: markRaw(VAV14),
      techInfo: {
        location: 'VAV 1-4',
        icon: '',
      },
      style: {
        position: 'absolute',
        top: '35.8%',
        right: '51.42%',
        width: '10.65%',
        height: '10.65%',
        cursor: 'pointer',
      },
      hotSpotAction: async (controlId: MouseEvent | KeyboardEvent | number) => {
        handleVAVAction(controlId);
      },
      chipStyle: {
        position: 'absolute',
        top: '39.5%',
        right: '55%',
        cursor: 'pointer',
      },
      color: '#378CDA',
    },
  ],
  childrenSuites: [
    // Suite 121
    {
      id: 0,
      name: 'Suite 121',
      disabled: false,
      styles: {
        width: '70%',
        height: 'auto',
        transform: 'translate(10%, 0)',
      },
      component: markRaw(Suite121),
      controlsList: [...Suite121ControlsList],
      activeTooltip: null,
    },
    // Suite 109
    {
      id: 1,
      name: 'Suite 109',
      disabled: false,
      styles: {
        width: '70%',
        height: 'auto',
        transform: 'translate(10%, 0)',
      },
      component: markRaw(Suite109),
      controlsList: [...Suite109ControlsList],
      activeTooltip: null,
    },
    // Suite 107
    {
      id: 2,
      name: 'Suite 107',
      disabled: false,
      styles: {
        width: '70%',
        height: 'auto',
        transform: 'translate(10%, 20%)',
      },
      component: markRaw(Suite107),
      controlsList: [...Suite107ControlsList],
      activeTooltip: null,
    },
    // Suite 103
    {
      id: 3,
      name: 'Suite 103',
      disabled: false,
      styles: {
        width: '35%',
        height: 'auto',
        transform: 'translate(60%, 0)',
      },
      component: markRaw(Suite103),
      controlsList: [...Suite103ControlsList],
      activeTooltip: null,
    },
    // Suite 100
    {
      id: 4,
      name: 'Suite 100',
      disabled: false,
      styles: {
        width: '85%',
        height: 'auto',
        top: '0',
      },
      component: markRaw(Suite100),
      controlsList: [...Suite100ControlsList],
      activeTooltip: null,
    },
  ],
  activeTooltip: null,
};

export default firstFloor;
