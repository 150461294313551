<template>
  <Form ref="obs" v-slot="{ invalid }">
    <v-container grid-list-xl>
      <h1>Create Project</h1>
      <form @submit.prevent="submit">
        <v-sheet elevation="2" class="pa-4">
          <v-text-field
            :rules="[(v) => !!v || 'Name is required']"
            v-model="project.projectName"
            label="Name"
          ></v-text-field>
          <v-text-field
            :rules="[(v) => !!v || 'Description is required']"
            v-model="project.description"
            label="Description"
          ></v-text-field>
          <v-select
            :rules="[(v) => !!v || 'Model is required']"
            :items="analyticModels"
            item-value="id"
            item-title="modelName"
            v-model="selectedModel"
            label="Associated Model"
            hint="Select an analysis model for this project"
            persistent-hint
            @update:modelValue="setDefaultDateRange"
          ></v-select>
          <v-row>
            <v-col cols="auto" md="6" sm="12">
              <v-autocomplete
                :items="measureOptions"
                item-title="name"
                item-value="id"
                label="Select Measure(s)"
                multiple
                persistent-hint
                hint="You can start typing to search for Measure(s)"
                ref="measureSelect"
                return-object
                @update:modelValue="handleSetMeasures"
                variant="solo"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                :rules="[(v) => !!v || 'Start date is required']"
                v-model="dateRange.startDateTime"
                label="Start Date"
                variant="outlined"
                prepend-icon="mdi-calendar"
              >
                <v-dialog
                  ref="dialog"
                  v-model="startModal"
                  width="290px"
                  activator="parent"
                >
                  <v-card width="400" class="rounded-2">
                    <v-date-picker
                      class="rounded-2"
                      width="400"
                      color="primary"
                      :title="`${currentYear}`"
                      :display-value="dateRange.startDateTime"
                      @update:modelValue="handleStartDateChange"
                    >
                    </v-date-picker>
                    <v-card-actions>
                      <v-btn
                        variant="text"
                        color="primary"
                        @click="startModal = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :rules="[(v) => !!v || 'End date is required']"
                v-model="dateRange.endDateTime"
                label="End Date"
                variant="outlined"
                prepend-icon="mdi-calendar"
              >
                <v-dialog
                  ref="dialog"
                  v-model="endModal"
                  width="290px"
                  activator="parent"
                >
                  <v-card width="400" class="rounded-2">
                    <v-date-picker
                      class="rounded-2"
                      width="400"
                      color="primary"
                      :title="`${currentYear}`"
                      :display-value="dateRange.endDateTime"
                      @update:modelValue="handleEndDateChange"
                    >
                    </v-date-picker>
                    <v-card-actions>
                      <v-btn
                        variant="text"
                        color="primary"
                        @click="endModal = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <label class="pa-3">Comments</label>
            <v-textarea
              rows="2"
              clearable
              clear-icon="mdi-close-circle"
              variant="outlined"
              auto-grow
              v-model="project.comment"
              class="pr-3"
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="invalid">
                Save
              </v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </form>
      <span class="px-4 text-caption text-primary">
        {{ $appOldVersion }}
      </span>
    </v-container>
  </Form>
</template>
<script>
import { Form, defineRule } from 'vee-validate';
import api from '../../projects/_api';

export default {
  name: 'AnalyticsMandVProjectCreate',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
  },

  data() {
    return {
      currentMode: {},
      currentYear: new Date().getFullYear(),
      startModal: false,
      endModal: false,
      dateRange: {
        startDateTime: '',
        endDateTime: '',
      },
      project: {
        projectName: '',
        description: '',
        measures: [],
        comment: '',
        siteId: null,
        modelId: null,
        projectStartDateTime: '',
        projectEndDateTime: '',
      },
      analyticModels: [],
      measureOptions: [],
      siteId: null,
      selectedModel: {
        id: null,
        startDateTime: '',
        endDateTime: '',
      },
      loading: false,
    };
  },
  created() {
    this.siteId = this.$route.params.siteId;
    this.getModels();
    this.getMeasures();
    this.registerCustomValidators();
  },
  async mounted() {
    this.dateRange = {
      startDateTime: new Date().toISOString().substring(0, 10),
      endDateTime: new Date().toISOString().substring(0, 10),
    };
  },
  methods: {
    handleStartDateChange(date) {
      this.dateRange.startDateTime = date.toISOString().split('T')[0];
      this.startModal = false;
    },
    handleEndDateChange(date) {
      this.dateRange.endDateTime = date.toISOString().split('T')[0];
      this.endModal = false;
    },
    async getModels() {
      api
        .getAnalyticModels(this.siteId)
        .then((response) => {
          this.analyticModels = response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    mapModelLookup(models) {
      const makeOptionItems = (item) => {
        return {
          value: item.id,
          text: item.name,
        };
      };
      let ml = models.map(makeOptionItems);
      return ml;
    },
    setSelectedModel(id) {
      let cm = {};
      try {
        cm = this.analyticModels.find((m) => {
          return m.id === id;
        });
        if (cm) {
          cm.startDateTime = new Date(cm.startDateTime)
            .toISOString()
            .substring(0, 10);
          cm.endDateTime = new Date(cm.endDateTime)
            .toISOString()
            .substring(0, 10);
          this.selectedModel = cm;
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },

    //MEASURES - START
    async getMeasures() {
      api
        .getMeasures()
        .then((response) => {
          this.measureOptions = this.mapMeasures(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    mapMeasures(measures) {
      return measures.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },

    handleSetMeasures(selectedMeasures) {
      this.project.measures = selectedMeasures.map((x) => {
        return { ...x, selected: true };
      });
    },

    async submit() {
      this.project.projectStartDateTime = this.dateRange.startDateTime;
      this.project.projectEndDateTime = this.dateRange.endDateTime;
      this.project.siteId = this.siteId;
      this.project.modelId = this.selectedModel.id;
      this.create();
    },

    async create() {
      let valid = await this.$refs.obs.validate();
      if (valid) {
        await api.createAnalyticProject(this.project);

        const options = {
          title: 'Success',
          message: `Project created.`,
          status: 'success',
        };
        this.$toast.show(options);

        this.$router.push({ name: 'AnalyticsMandVProjectIndex' });
      }
    },

    registerCustomValidators() {
      defineRule('duplicate_name', (value, [field]) => {
        let valid = true;
        if (this.projects) {
          let existingNames = this.projects.map((a) => a.projectName);
          valid = existingNames.indexOf(value) === -1;
        }

        if (!valid) {
          return `${field} is already used by another project.  Enter a different name.`;
        }

        return valid;
      });
    },

    setDefaultDateRange(id) {
      if (this.loading !== true) {
        this.setSelectedModel(id);
      }
    },

    handleCancelCrud() {
      this.$router.push({ name: 'AnalyticsMandVProjectIndex' });
    },
  },
};
</script>
