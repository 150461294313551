<template>
  <v-card>
    <v-layout class="app">
      <LateralMenuVue v-if="!isAllowAnonymous" />
      <AppBarVue />
      <MessageCenterEntryPoint v-if="!isAllowAnonymous" />
      <v-main>
        <v-container fluid class="main-container">
          <router-view />
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>
<script setup lang="ts">
import LateralMenuVue from '@/Core.UI.Domain/Components/LateralMenu.vue';
import AppBarVue from '@/Core.UI.Domain/Components/AppBar.vue';

import MessageCenterEntryPoint from '@/Core.Service.Domain/MessageCenter/MessageCenter.Common/Components/MessageCenterEntryPoint.vue';

import APIVAV from '@/Core.Service.Domain/Controls/VAVs/API/index.ts';

import { watch, computed, provide, ref } from 'vue';
import { useRoute } from 'vue-router';

// This will provide information about the current user's VAVs access through the application
const listOfVavSitesForCurrentUser = ref([]);

const updateUserAccess = async () => {
  try {
    listOfVavSitesForCurrentUser.value =
      await APIVAV.listOfVavSitesForCurrentUser();
  } catch (error) {
    return Promise.reject(error);
  }
};

provide('listOfVavSitesForCurrentUser', {
  listOfVavSitesForCurrentUser,
  updateUserAccess,
});

const route = useRoute();

const isAllowAnonymous = computed(() => route.meta.allowAnonymous);

watch(
  () => route,
  () => {
    localStorage.removeItem('controlAnchorPosition');
  }
);
</script>
<style lang="scss">
.app {
  background-color: $aliceBlue !important;
}

.main-container {
  min-height: 100vh !important;
  width: 98%;
  box-sizing: border-box;
}
</style>
