<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-dialog v-model="showConfirm" :width="width" @keydown.esc="handleCancel">
    <v-card>
      <v-card-title class="text-h5 px-5">
        <span class="text-primary">
          {{ currentTitle }}
        </span></v-card-title
      >
      <v-card-text class="text-primary text-justify" v-html="currentMessage" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="rounded-pill"
          color="primary"
          variant="flat"
          @click="handleCancel"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
          >
            Cancel
          </div>
        </v-btn>
        <v-btn
          v-if="showConfirmButton"
          class="rounded-pill"
          color="warning"
          variant="flat"
          @click="handleOk"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold px-3"
          >
            Yes
          </div>
        </v-btn>
      </v-card-actions>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>
<style>
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
</style>
<script>
import eventBus from '@/Core.Service.Domain/Shared/eventBus.js';

export default {
  props: {
    title: { type: String, default: 'Please Confirm' },
    message: { type: String, default: 'Are you sure you want to do this?' },
    width: { type: String, default: '290' },
    showConfirmButton: { type: Boolean, default: true },
  },
  data() {
    return {
      showConfirm: false,
      currentTitle: this.title,
      currentMessage: this.message,
    };
  },
  watch: {
    show(newVal) {
      this.showConfirm = newVal;
    },
    title(newVal) {
      this.currentTitle = newVal;
    },
  },
  methods: {
    async confirm(message, title) {
      this.showConfirm = true;

      if (title) {
        this.currentTitle = title;
      }

      if (message) {
        this.currentMessage = message;
      }

      const promise = new Promise((resolve) => {
        eventBus.$on('ok', () => {
          this.showConfirm = false;
          return resolve(true);
        });
        eventBus.$on('cancel', () => {
          this.showConfirm = false;
          return resolve(false);
        });
      });

      return promise;
    },
    handleOk() {
      eventBus.$emit('ok');
      this.showConfirm = false;
    },
    handleCancel() {
      eventBus.$emit('cancel');
      this.showConfirm = false;
    },
  },
};
</script>
