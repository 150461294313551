import { ThermostatsRoute } from '@/Core.Service.Domain/Controls/Thermostats/Routes';
import { PowerControlsRoute } from '@/Core.Service.Domain/Controls/PowerControls/Routes';

import { DaikinThermostatsRoute } from '@/Core.Service.Domain/Controls/DaikinThermostats/Routes';

import { DigitalTwinRoute } from '@/Core.Service.Domain/Controls/DigitalTwin/Routes';

import {
  VAVsRoute,
  EditVAVRoute,
} from '@/Core.Service.Domain/Controls/VAVs/Routes/index.ts';

import {
  AHUsRoute,
  EditAHURoute,
} from '@/Core.Service.Domain/Controls/AHUs/Routes/index.ts';

import Edit from '@/modules/controls/_components/Edit';

import ControlsView from '@/Core.Service.Domain/Controls/Controls.Common/Views';

const routesList = [];

const ControlsRoute = {
  path: '/controls',
  name: 'Controls',
  children: [
    ThermostatsRoute,
    PowerControlsRoute,
    DaikinThermostatsRoute,
    DigitalTwinRoute,
    AHUsRoute,
    EditAHURoute,
    VAVsRoute,
    EditVAVRoute,
  ],
  component: ControlsView,
  meta: { permission: 'controls:read' },
};

const ControlsEditRoute = {
  path: '/controls/:type/:controlId',
  name: 'ControlsEdit',
  component: Edit,
};

routesList.push(ControlsRoute, ControlsEditRoute);

export default routesList;
