<template>
  <svg
    width="213"
    height="117"
    viewBox="0 0 213 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M205.97 3.91998L205.78 4.37998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M205.78 4.38L205.32 4.57001" stroke="black" stroke-width="0.5" />
    <path d="M205.32 4.57001L204.86 4.38" stroke="black" stroke-width="0.5" />
    <path
      d="M204.86 4.37998L204.67 3.91998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M204.67 3.92002L204.86 3.46002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M204.86 3.46002L205.32 3.27002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M205.32 3.27002L205.78 3.46002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M205.78 3.46002L205.97 3.92002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M203.67 3.91998L203.48 4.37998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M203.48 4.38L203.02 4.57001" stroke="black" stroke-width="0.5" />
    <path d="M203.02 4.57001L202.56 4.38" stroke="black" stroke-width="0.5" />
    <path
      d="M202.56 4.37998L202.36 3.91998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M202.36 3.92002L202.56 3.46002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M202.56 3.46002L203.02 3.27002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M203.02 3.27002L203.48 3.46002"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M203.48 3.46002L203.67 3.92002"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M49.46 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M58.76 61.19L58.6 59.77" stroke="black" stroke-width="0.5" />
    <path d="M58.6 59.77L58.13 58.42" stroke="black" stroke-width="0.5" />
    <path d="M58.13 58.42L57.37 57.21" stroke="black" stroke-width="0.5" />
    <path d="M57.37 57.21L56.36 56.2" stroke="black" stroke-width="0.5" />
    <path d="M56.36 56.2L55.15 55.45" stroke="black" stroke-width="0.5" />
    <path d="M55.15 55.45L53.8 54.97" stroke="black" stroke-width="0.5" />
    <path d="M53.8 54.97L52.38 54.81" stroke="black" stroke-width="0.5" />
    <path d="M52.38 61.2H52.69" stroke="black" stroke-width="0.5" />
    <path d="M52.69 61.2V54.82" stroke="black" stroke-width="0.5" />
    <path d="M52.38 54.81V61.2" stroke="black" stroke-width="0.5" />
    <path d="M42.54 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M78.71 87.56H80.04" stroke="black" stroke-width="0.5" />
    <path d="M7.03998 44.14H36.42" stroke="black" stroke-width="0.5" />
    <path d="M31.37 45.47H8.22998" stroke="black" stroke-width="0.5" />
    <path d="M8.23003 46.8H5.84003" stroke="black" stroke-width="0.5" />
    <path d="M32.7 52.42H31.37" stroke="black" stroke-width="0.5" />
    <path d="M31.37 73.17H20.2" stroke="black" stroke-width="0.5" />
    <path d="M59.83 74.5H21.53" stroke="black" stroke-width="0.5" />
    <path d="M61.16 75.56H59.83" stroke="black" stroke-width="0.5" />
    <path d="M61.16 76.09H59.83" stroke="black" stroke-width="0.5" />
    <path d="M61.16 85.67H59.83" stroke="black" stroke-width="0.5" />
    <path d="M61.16 86.2H59.83" stroke="black" stroke-width="0.5" />
    <path d="M59.83 73.17H51.58" stroke="black" stroke-width="0.5" />
    <path d="M59.83 68.91H51.58" stroke="black" stroke-width="0.5" />
    <path d="M50.25 73.17H42.28" stroke="black" stroke-width="0.5" />
    <path d="M50.25 68.91H42.28" stroke="black" stroke-width="0.5" />
    <path d="M40.95 73.17H32.7" stroke="black" stroke-width="0.5" />
    <path d="M40.95 68.91H32.7" stroke="black" stroke-width="0.5" />
    <path d="M59.83 62.53H58.76" stroke="black" stroke-width="0.5" />
    <path d="M52.38 62.53H51.58" stroke="black" stroke-width="0.5" />
    <path d="M50.25 62.53H49.46" stroke="black" stroke-width="0.5" />
    <path d="M43.07 62.53H42.28" stroke="black" stroke-width="0.5" />
    <path d="M40.95 62.53H40.15" stroke="black" stroke-width="0.5" />
    <path d="M33.76 62.53H32.7" stroke="black" stroke-width="0.5" />
    <path d="M61.16 61.2H58.76" stroke="black" stroke-width="0.5" />
    <path d="M33.76 61.2H31.37" stroke="black" stroke-width="0.5" />
    <path d="M20.2 87.26H5.37" stroke="black" stroke-width="0.5" />
    <path d="M21.53 88.59H3.70001" stroke="black" stroke-width="0.5" />
    <path d="M32.7 45.47H36.42" stroke="black" stroke-width="0.5" />
    <path d="M31.37 61.2V73.17" stroke="black" stroke-width="0.5" />
    <path d="M21.53 74.5V88.59" stroke="black" stroke-width="0.5" />
    <path d="M20.2 73.17V87.26" stroke="black" stroke-width="0.5" />
    <path d="M32.7 62.53V73.17" stroke="black" stroke-width="0.5" />
    <path d="M33.76 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M33.23 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M40.95 62.53V73.17" stroke="black" stroke-width="0.5" />
    <path d="M42.28 62.53V73.17" stroke="black" stroke-width="0.5" />
    <path d="M43.07 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M61.16 61.2V76.09" stroke="black" stroke-width="0.5" />
    <path d="M137.49 90.49H138.02" stroke="black" stroke-width="0.5" />
    <path d="M59.83 62.53V73.17" stroke="black" stroke-width="0.5" />
    <path d="M58.76 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M59.3 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M51.58 62.53V73.17" stroke="black" stroke-width="0.5" />
    <path d="M52.38 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M51.85 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M50.25 62.53V73.17" stroke="black" stroke-width="0.5" />
    <path d="M204.54 105.65H209.73" stroke="black" stroke-width="0.5" />
    <path d="M49.99 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M52.38 61.2H49.46" stroke="black" stroke-width="0.5" />
    <path d="M43.07 61.2H40.15" stroke="black" stroke-width="0.5" />
    <path d="M40.15 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M40.68 61.2V62.53" stroke="black" stroke-width="0.5" />
    <path d="M59.83 74.5V76.09" stroke="black" stroke-width="0.5" />
    <path d="M61.16 85.67V104.85" stroke="black" stroke-width="0.5" />
    <path d="M59.83 85.67V113.09" stroke="black" stroke-width="0.5" />
    <path d="M61.16 106.18H62.22" stroke="black" stroke-width="0.5" />
    <path d="M71.8 106.18H78.71" stroke="black" stroke-width="0.5" />
    <path d="M61.16 104.85H62.22" stroke="black" stroke-width="0.5" />
    <path d="M71.8 104.85H78.71" stroke="black" stroke-width="0.5" />
    <path d="M171.3 65.15H174.49" stroke="black" stroke-width="0.5" />
    <path d="M78.71 101.39H80.04" stroke="black" stroke-width="0.5" />
    <path d="M78.71 91.82H80.04" stroke="black" stroke-width="0.5" />
    <path d="M78.71 91.28H80.04" stroke="black" stroke-width="0.5" />
    <path d="M78.71 88.09H80.04" stroke="black" stroke-width="0.5" />
    <path d="M80.04 101.39L81.91 101.21" stroke="black" stroke-width="0.5" />
    <path d="M81.91 101.21L83.71 100.66" stroke="black" stroke-width="0.5" />
    <path d="M83.71 100.66L85.36 99.78" stroke="black" stroke-width="0.5" />
    <path d="M85.36 99.78L86.81 98.59" stroke="black" stroke-width="0.5" />
    <path d="M86.81 98.59L88 97.14" stroke="black" stroke-width="0.5" />
    <path d="M88 97.14L88.89 95.48" stroke="black" stroke-width="0.5" />
    <path d="M88.89 95.48L89.43 93.68" stroke="black" stroke-width="0.5" />
    <path d="M89.43 93.68L89.62 91.82" stroke="black" stroke-width="0.5" />
    <path d="M80.04 91.82V92.28" stroke="black" stroke-width="0.5" />
    <path d="M80.04 92.28H89.61" stroke="black" stroke-width="0.5" />
    <path d="M89.62 91.82H80.04" stroke="black" stroke-width="0.5" />
    <path d="M78.71 77.99H80.04" stroke="black" stroke-width="0.5" />
    <path d="M78.71 77.45H80.04" stroke="black" stroke-width="0.5" />
    <path d="M192.31 105.65L192.49 107.51" stroke="black" stroke-width="0.5" />
    <path d="M192.49 107.51L193.04 109.31" stroke="black" stroke-width="0.5" />
    <path d="M193.04 109.31L193.92 110.97" stroke="black" stroke-width="0.5" />
    <path d="M193.92 110.97L195.11 112.42" stroke="black" stroke-width="0.5" />
    <path d="M195.11 112.42L196.57 113.61" stroke="black" stroke-width="0.5" />
    <path d="M196.57 113.61L198.22 114.49" stroke="black" stroke-width="0.5" />
    <path d="M198.22 114.49L200.02 115.04" stroke="black" stroke-width="0.5" />
    <path d="M200.02 115.04L201.88 115.22" stroke="black" stroke-width="0.5" />
    <path d="M201.88 105.65H201.42" stroke="black" stroke-width="0.5" />
    <path d="M201.42 105.65V115.21" stroke="black" stroke-width="0.5" />
    <path d="M201.88 115.22V105.65" stroke="black" stroke-width="0.5" />
    <path d="M78.71 101.92H80.04" stroke="black" stroke-width="0.5" />
    <path d="M61.16 106.18V114.42" stroke="black" stroke-width="0.5" />
    <path d="M78.71 106.18V114.42" stroke="black" stroke-width="0.5" />
    <path d="M80.04 101.39V113.09" stroke="black" stroke-width="0.5" />
    <path d="M105.84 90.49V113.09" stroke="black" stroke-width="0.5" />
    <path d="M107.17 81.18V105.65" stroke="black" stroke-width="0.5" />
    <path d="M108.5 105.65V110.43" stroke="black" stroke-width="0.5" />
    <path d="M78.71 101.39V104.85" stroke="black" stroke-width="0.5" />
    <path d="M62.22 104.85V106.18" stroke="black" stroke-width="0.5" />
    <path d="M61.69 104.85V106.18" stroke="black" stroke-width="0.5" />
    <path d="M71.8 104.85V106.18" stroke="black" stroke-width="0.5" />
    <path d="M203.21 114.42H207.2" stroke="black" stroke-width="0.5" />
    <path d="M72.33 104.85V106.18" stroke="black" stroke-width="0.5" />
    <path d="M40.15 61.19L39.99 59.77" stroke="black" stroke-width="0.5" />
    <path d="M39.99 59.77L39.51 58.42" stroke="black" stroke-width="0.5" />
    <path d="M39.51 58.42L38.75 57.21" stroke="black" stroke-width="0.5" />
    <path d="M38.75 57.21L37.74 56.2" stroke="black" stroke-width="0.5" />
    <path d="M37.74 56.2L36.53 55.45" stroke="black" stroke-width="0.5" />
    <path d="M36.53 55.45L35.18 54.97" stroke="black" stroke-width="0.5" />
    <path d="M35.18 54.97L33.76 54.81" stroke="black" stroke-width="0.5" />
    <path d="M33.76 61.2H34.07" stroke="black" stroke-width="0.5" />
    <path d="M34.07 61.2V54.82" stroke="black" stroke-width="0.5" />
    <path d="M33.76 54.81V61.2" stroke="black" stroke-width="0.5" />
    <path d="M207.2 115.75V114.42" stroke="black" stroke-width="0.5" />
    <path d="M49.46 61.19L49.3 59.77" stroke="black" stroke-width="0.5" />
    <path d="M49.3 59.77L48.82 58.42" stroke="black" stroke-width="0.5" />
    <path d="M48.82 58.42L48.06 57.21" stroke="black" stroke-width="0.5" />
    <path d="M48.06 57.21L47.05 56.2" stroke="black" stroke-width="0.5" />
    <path d="M47.05 56.2L45.84 55.45" stroke="black" stroke-width="0.5" />
    <path d="M45.84 55.45L44.49 54.97" stroke="black" stroke-width="0.5" />
    <path d="M44.49 54.97L43.07 54.81" stroke="black" stroke-width="0.5" />
    <path d="M43.07 61.2H43.38" stroke="black" stroke-width="0.5" />
    <path d="M43.38 61.2V54.82" stroke="black" stroke-width="0.5" />
    <path d="M43.07 54.81V61.2" stroke="black" stroke-width="0.5" />
    <path d="M78.71 87.56V91.82" stroke="black" stroke-width="0.5" />
    <path d="M80.04 87.56V89.16" stroke="black" stroke-width="0.5" />
    <path d="M80.04 90.49V91.82" stroke="black" stroke-width="0.5" />
    <path d="M80.04 90.49H105.84" stroke="black" stroke-width="0.5" />
    <path d="M80.04 89.16H105.84" stroke="black" stroke-width="0.5" />
    <path d="M99.99 81.18H107.17" stroke="black" stroke-width="0.5" />
    <path d="M98.66 82.51H105.84" stroke="black" stroke-width="0.5" />
    <path d="M105.84 89.16V82.51" stroke="black" stroke-width="0.5" />
    <path d="M99.99 67.88V81.18" stroke="black" stroke-width="0.5" />
    <path d="M98.66 82.51V69.21" stroke="black" stroke-width="0.5" />
    <path d="M80.04 69.21H98.66" stroke="black" stroke-width="0.5" />
    <path d="M80.04 67.88H99.99" stroke="black" stroke-width="0.5" />
    <path d="M78.71 61.5H80.04" stroke="black" stroke-width="0.5" />
    <path d="M78.71 61.5V77.99" stroke="black" stroke-width="0.5" />
    <path d="M80.04 61.5V77.99" stroke="black" stroke-width="0.5" />
    <path d="M31.37 52.42V45.47" stroke="black" stroke-width="0.5" />
    <path d="M32.7 52.42V45.47" stroke="black" stroke-width="0.5" />
    <path d="M36.42 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M35.89 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M46 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M46.53 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M46 44.14H47.32" stroke="black" stroke-width="0.5" />
    <path d="M48.65 44.14H66.74" stroke="black" stroke-width="0.5" />
    <path d="M76.32 44.14H77.38" stroke="black" stroke-width="0.5" />
    <path d="M78.71 44.14H96.53" stroke="black" stroke-width="0.5" />
    <path d="M46 45.47H66.74" stroke="black" stroke-width="0.5" />
    <path d="M76.32 45.47H96.53" stroke="black" stroke-width="0.5" />
    <path d="M47.32 5.88V44.14" stroke="black" stroke-width="0.5" />
    <path d="M48.65 5.88V44.14" stroke="black" stroke-width="0.5" />
    <path d="M77.38 5.88V44.14" stroke="black" stroke-width="0.5" />
    <path d="M78.71 5.88V44.14" stroke="black" stroke-width="0.5" />
    <path d="M66.74 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M66.21 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M76.32 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M76.85 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M106.37 44.14H107.43" stroke="black" stroke-width="0.5" />
    <path d="M108.76 44.14H126.85" stroke="black" stroke-width="0.5" />
    <path d="M107.43 5.88V44.14" stroke="black" stroke-width="0.5" />
    <path d="M108.76 5.88V44.14" stroke="black" stroke-width="0.5" />
    <path d="M106.37 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M106.9 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M136.43 44.14H137.48" stroke="black" stroke-width="0.5" />
    <path d="M68.11 5.87999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M46.3 5.88H68.11" stroke="black" stroke-width="0.5" />
    <path d="M138.81 44.14H156.9" stroke="black" stroke-width="0.5" />
    <path d="M137.48 5.88V44.14" stroke="black" stroke-width="0.5" />
    <path d="M138.81 7.01001V44.14" stroke="black" stroke-width="0.5" />
    <path d="M136.43 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M136.96 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M166.48 44.14H167.54" stroke="black" stroke-width="0.5" />
    <path d="M167.54 5.88V44.14" stroke="black" stroke-width="0.5" />
    <path d="M168.87 5.88V44.14" stroke="black" stroke-width="0.5" />
    <path d="M166.48 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M167.01 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M96.53 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M96 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M126.85 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M126.32 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M106.37 45.47H126.85" stroke="black" stroke-width="0.5" />
    <path d="M136.43 45.47H156.9" stroke="black" stroke-width="0.5" />
    <path d="M156.9 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M156.37 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M169.92 44.14H168.87" stroke="black" stroke-width="0.5" />
    <path d="M169.92 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M169.39 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M179.5 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M180.03 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M166.48 45.47H169.92" stroke="black" stroke-width="0.5" />
    <path d="M179.5 44.14H209.73" stroke="black" stroke-width="0.5" />
    <path d="M179.5 45.47H185.66" stroke="black" stroke-width="0.5" />
    <path d="M187.26 45.47H209.73" stroke="black" stroke-width="0.5" />
    <path d="M185.66 46.54H187.26" stroke="black" stroke-width="0.5" />
    <path d="M185.66 46H187.26" stroke="black" stroke-width="0.5" />
    <path d="M185.66 56.11H187.26" stroke="black" stroke-width="0.5" />
    <path d="M185.66 56.64H187.26" stroke="black" stroke-width="0.5" />
    <path d="M187.26 71.01H209.73" stroke="black" stroke-width="0.5" />
    <path d="M168.54 64.36H175.02" stroke="black" stroke-width="0.5" />
    <path d="M184.6 64.36H185.66" stroke="black" stroke-width="0.5" />
    <path d="M168.27 65.95H175.02" stroke="black" stroke-width="0.5" />
    <path d="M184.6 65.95H185.66" stroke="black" stroke-width="0.5" />
    <path d="M185.66 74.2H209.73" stroke="black" stroke-width="0.5" />
    <path d="M36.42 44.14L36.61 42.28" stroke="black" stroke-width="0.5" />
    <path d="M36.61 42.28L37.15 40.48" stroke="black" stroke-width="0.5" />
    <path d="M37.15 40.48L38.04 38.82" stroke="black" stroke-width="0.5" />
    <path d="M38.04 38.82L39.23 37.37" stroke="black" stroke-width="0.5" />
    <path d="M39.23 37.37L40.68 36.18" stroke="black" stroke-width="0.5" />
    <path d="M40.68 36.18L42.33 35.3" stroke="black" stroke-width="0.5" />
    <path d="M42.33 35.3L44.13 34.75" stroke="black" stroke-width="0.5" />
    <path d="M44.13 34.75L46 34.57" stroke="black" stroke-width="0.5" />
    <path d="M46 44.14H45.53" stroke="black" stroke-width="0.5" />
    <path d="M45.53 44.14V34.58" stroke="black" stroke-width="0.5" />
    <path d="M46 34.57V44.14" stroke="black" stroke-width="0.5" />
    <path d="M66.74 44.14L66.93 42.28" stroke="black" stroke-width="0.5" />
    <path d="M66.93 42.28L67.47 40.48" stroke="black" stroke-width="0.5" />
    <path d="M67.47 40.48L68.36 38.82" stroke="black" stroke-width="0.5" />
    <path d="M68.36 38.82L69.55 37.37" stroke="black" stroke-width="0.5" />
    <path d="M69.55 37.37L71 36.18" stroke="black" stroke-width="0.5" />
    <path d="M71 36.18L72.65 35.3" stroke="black" stroke-width="0.5" />
    <path d="M72.65 35.3L74.45 34.75" stroke="black" stroke-width="0.5" />
    <path d="M74.45 34.75L76.32 34.57" stroke="black" stroke-width="0.5" />
    <path d="M76.32 44.14H75.85" stroke="black" stroke-width="0.5" />
    <path d="M75.85 44.14V34.58" stroke="black" stroke-width="0.5" />
    <path d="M76.32 34.57V44.14" stroke="black" stroke-width="0.5" />
    <path d="M96.8 44.14L96.98 42.28" stroke="black" stroke-width="0.5" />
    <path d="M96.98 42.28L97.53 40.48" stroke="black" stroke-width="0.5" />
    <path d="M97.53 40.48L98.41 38.82" stroke="black" stroke-width="0.5" />
    <path d="M98.41 38.82L99.6 37.37" stroke="black" stroke-width="0.5" />
    <path d="M99.6 37.37L101.05 36.18" stroke="black" stroke-width="0.5" />
    <path d="M101.05 36.18L102.71 35.3" stroke="black" stroke-width="0.5" />
    <path d="M102.71 35.3L104.5 34.75" stroke="black" stroke-width="0.5" />
    <path d="M104.5 34.75L106.37 34.57" stroke="black" stroke-width="0.5" />
    <path d="M106.37 44.14H105.91" stroke="black" stroke-width="0.5" />
    <path d="M105.91 44.14V34.58" stroke="black" stroke-width="0.5" />
    <path d="M106.37 34.57V44.14" stroke="black" stroke-width="0.5" />
    <path d="M126.85 44.14L127.04 42.28" stroke="black" stroke-width="0.5" />
    <path d="M127.04 42.28L127.58 40.48" stroke="black" stroke-width="0.5" />
    <path d="M127.58 40.48L128.46 38.82" stroke="black" stroke-width="0.5" />
    <path d="M128.46 38.82L129.66 37.37" stroke="black" stroke-width="0.5" />
    <path d="M129.66 37.37L131.11 36.18" stroke="black" stroke-width="0.5" />
    <path d="M131.11 36.18L132.76 35.3" stroke="black" stroke-width="0.5" />
    <path d="M132.76 35.3L134.56 34.75" stroke="black" stroke-width="0.5" />
    <path d="M134.56 34.75L136.43 34.57" stroke="black" stroke-width="0.5" />
    <path d="M136.43 44.14H135.96" stroke="black" stroke-width="0.5" />
    <path d="M135.96 44.14V34.58" stroke="black" stroke-width="0.5" />
    <path d="M136.43 34.57V44.14" stroke="black" stroke-width="0.5" />
    <path d="M156.9 44.14L157.09 42.28" stroke="black" stroke-width="0.5" />
    <path d="M157.09 42.28L157.63 40.48" stroke="black" stroke-width="0.5" />
    <path d="M157.63 40.48L158.52 38.82" stroke="black" stroke-width="0.5" />
    <path d="M158.52 38.82L159.71 37.37" stroke="black" stroke-width="0.5" />
    <path d="M159.71 37.37L161.16 36.18" stroke="black" stroke-width="0.5" />
    <path d="M161.16 36.18L162.82 35.3" stroke="black" stroke-width="0.5" />
    <path d="M162.82 35.3L164.61 34.75" stroke="black" stroke-width="0.5" />
    <path d="M164.61 34.75L166.48 34.57" stroke="black" stroke-width="0.5" />
    <path d="M166.48 44.14H166.01" stroke="black" stroke-width="0.5" />
    <path d="M166.01 44.14V34.58" stroke="black" stroke-width="0.5" />
    <path d="M166.48 34.57V44.14" stroke="black" stroke-width="0.5" />
    <path d="M179.5 44.14L179.31 42.28" stroke="black" stroke-width="0.5" />
    <path d="M179.31 42.28L178.77 40.48" stroke="black" stroke-width="0.5" />
    <path d="M178.77 40.48L177.88 38.82" stroke="black" stroke-width="0.5" />
    <path d="M177.88 38.82L176.69 37.37" stroke="black" stroke-width="0.5" />
    <path d="M176.69 37.37L175.24 36.18" stroke="black" stroke-width="0.5" />
    <path d="M175.24 36.18L173.59 35.3" stroke="black" stroke-width="0.5" />
    <path d="M173.59 35.3L171.79 34.75" stroke="black" stroke-width="0.5" />
    <path d="M171.79 34.75L169.92 34.57" stroke="black" stroke-width="0.5" />
    <path d="M169.92 44.14H170.39" stroke="black" stroke-width="0.5" />
    <path d="M170.39 44.14V34.58" stroke="black" stroke-width="0.5" />
    <path d="M169.92 34.57V44.14" stroke="black" stroke-width="0.5" />
    <path d="M187.26 46.54V45.47" stroke="black" stroke-width="0.5" />
    <path d="M185.66 46.54V45.47" stroke="black" stroke-width="0.5" />
    <path d="M187.26 56.11V71.01" stroke="black" stroke-width="0.5" />
    <path d="M185.66 56.11V64.36" stroke="black" stroke-width="0.5" />
    <path d="M185.66 65.95V74.2" stroke="black" stroke-width="0.5" />
    <path d="M175.02 64.36V65.95" stroke="black" stroke-width="0.5" />
    <path d="M174.49 64.36V65.95" stroke="black" stroke-width="0.5" />
    <path d="M184.6 64.36V65.95" stroke="black" stroke-width="0.5" />
    <path d="M185.13 64.36V65.95" stroke="black" stroke-width="0.5" />
    <path d="M136.16 81.18V113.09" stroke="black" stroke-width="0.5" />
    <path d="M137.49 81.18V89.16" stroke="black" stroke-width="0.5" />
    <path d="M137.49 90.49V113.09" stroke="black" stroke-width="0.5" />
    <path d="M138.02 89.16V90.49" stroke="black" stroke-width="0.5" />
    <path d="M136.16 81.18H137.49" stroke="black" stroke-width="0.5" />
    <path d="M167.54 90.49V113.09" stroke="black" stroke-width="0.5" />
    <path d="M157.17 89.16V90.49" stroke="black" stroke-width="0.5" />
    <path d="M168.87 89.16V113.09" stroke="black" stroke-width="0.5" />
    <path d="M157.17 89.16H168.87" stroke="black" stroke-width="0.5" />
    <path d="M157.17 90.49H167.54" stroke="black" stroke-width="0.5" />
    <path d="M137.49 89.16H138.02" stroke="black" stroke-width="0.5" />
    <path d="M202.42 105.65V104.32" stroke="black" stroke-width="0.5" />
    <path d="M201.88 105.65V104.32" stroke="black" stroke-width="0.5" />
    <path d="M203.21 114.42V105.65" stroke="black" stroke-width="0.5" />
    <path d="M204.54 113.09V105.65" stroke="black" stroke-width="0.5" />
    <path d="M201.88 104.32H209.73" stroke="black" stroke-width="0.5" />
    <path d="M201.88 105.65H203.21" stroke="black" stroke-width="0.5" />
    <path d="M192.31 105.65V104.32" stroke="black" stroke-width="0.5" />
    <path d="M191.78 105.65V104.32" stroke="black" stroke-width="0.5" />
    <path d="M186.46 105.65V104.32" stroke="black" stroke-width="0.5" />
    <path d="M185.93 105.65V104.32" stroke="black" stroke-width="0.5" />
    <path d="M185.93 105.65H192.31" stroke="black" stroke-width="0.5" />
    <path d="M192.31 104.32H185.38" stroke="black" stroke-width="0.5" />
    <path d="M185.93 105.65L177.15 114.42" stroke="black" stroke-width="0.5" />
    <path d="M185.38 104.32L176.6 113.09" stroke="black" stroke-width="0.5" />
    <path d="M168.27 65.95L163.45 70.78" stroke="black" stroke-width="0.5" />
    <path d="M168.54 64.36L162.78 70.11" stroke="black" stroke-width="0.5" />
    <path d="M171.3 64.36V65.95" stroke="black" stroke-width="0.5" />
    <path d="M170.77 64.36V65.95" stroke="black" stroke-width="0.5" />
    <path d="M162.78 70.11L163.45 70.78" stroke="black" stroke-width="0.5" />
    <path d="M71.8 106.18L71.61 108.05" stroke="black" stroke-width="0.5" />
    <path d="M71.61 108.05L71.07 109.84" stroke="black" stroke-width="0.5" />
    <path d="M71.07 109.84L70.18 111.5" stroke="black" stroke-width="0.5" />
    <path d="M70.18 111.5L68.99 112.95" stroke="black" stroke-width="0.5" />
    <path d="M68.99 112.95L67.54 114.14" stroke="black" stroke-width="0.5" />
    <path d="M67.54 114.14L65.89 115.02" stroke="black" stroke-width="0.5" />
    <path d="M65.89 115.02L64.09 115.57" stroke="black" stroke-width="0.5" />
    <path d="M64.09 115.57L62.22 115.75" stroke="black" stroke-width="0.5" />
    <path d="M62.22 106.18H62.69" stroke="black" stroke-width="0.5" />
    <path d="M62.69 106.18V115.74" stroke="black" stroke-width="0.5" />
    <path d="M62.22 115.75V106.18" stroke="black" stroke-width="0.5" />
    <path d="M80.04 77.99L81.91 78.17" stroke="black" stroke-width="0.5" />
    <path d="M81.91 78.17L83.71 78.72" stroke="black" stroke-width="0.5" />
    <path d="M83.71 78.72L85.36 79.6" stroke="black" stroke-width="0.5" />
    <path d="M85.36 79.6L86.81 80.79" stroke="black" stroke-width="0.5" />
    <path d="M86.81 80.79L88 82.24" stroke="black" stroke-width="0.5" />
    <path d="M88 82.24L88.89 83.9" stroke="black" stroke-width="0.5" />
    <path d="M88.89 83.9L89.43 85.69" stroke="black" stroke-width="0.5" />
    <path d="M89.43 85.69L89.62 87.56" stroke="black" stroke-width="0.5" />
    <path d="M80.04 87.56V87.1" stroke="black" stroke-width="0.5" />
    <path d="M80.04 87.1H89.61" stroke="black" stroke-width="0.5" />
    <path d="M89.62 87.56H80.04" stroke="black" stroke-width="0.5" />
    <path d="M59.83 85.67L57.96 85.48" stroke="black" stroke-width="0.5" />
    <path d="M57.96 85.48L56.16 84.94" stroke="black" stroke-width="0.5" />
    <path d="M56.16 84.94L54.51 84.05" stroke="black" stroke-width="0.5" />
    <path d="M54.51 84.05L53.06 82.86" stroke="black" stroke-width="0.5" />
    <path d="M53.06 82.86L51.87 81.41" stroke="black" stroke-width="0.5" />
    <path d="M51.87 81.41L50.98 79.76" stroke="black" stroke-width="0.5" />
    <path d="M50.98 79.76L50.44 77.96" stroke="black" stroke-width="0.5" />
    <path d="M50.44 77.96L50.25 76.09" stroke="black" stroke-width="0.5" />
    <path d="M59.83 76.09V76.56" stroke="black" stroke-width="0.5" />
    <path d="M59.83 76.56H50.27" stroke="black" stroke-width="0.5" />
    <path d="M50.25 76.09H59.83" stroke="black" stroke-width="0.5" />
    <path d="M175.02 65.95L175.21 67.82" stroke="black" stroke-width="0.5" />
    <path d="M175.21 67.82L175.75 69.62" stroke="black" stroke-width="0.5" />
    <path d="M175.75 69.62L176.64 71.27" stroke="black" stroke-width="0.5" />
    <path d="M176.64 71.27L177.83 72.72" stroke="black" stroke-width="0.5" />
    <path d="M177.83 72.72L179.28 73.91" stroke="black" stroke-width="0.5" />
    <path d="M179.28 73.91L180.93 74.8" stroke="black" stroke-width="0.5" />
    <path d="M180.93 74.8L182.73 75.34" stroke="black" stroke-width="0.5" />
    <path d="M182.73 75.34L184.6 75.53" stroke="black" stroke-width="0.5" />
    <path d="M184.6 65.95H184.13" stroke="black" stroke-width="0.5" />
    <path d="M184.13 65.95V75.52" stroke="black" stroke-width="0.5" />
    <path d="M184.6 75.53V65.95" stroke="black" stroke-width="0.5" />
    <path d="M187.26 56.11L189.12 55.93" stroke="black" stroke-width="0.5" />
    <path d="M189.12 55.93L190.92 55.38" stroke="black" stroke-width="0.5" />
    <path d="M190.92 55.38L192.58 54.5" stroke="black" stroke-width="0.5" />
    <path d="M192.58 54.5L194.03 53.31" stroke="black" stroke-width="0.5" />
    <path d="M194.03 53.31L195.22 51.86" stroke="black" stroke-width="0.5" />
    <path d="M195.22 51.86L196.1 50.2" stroke="black" stroke-width="0.5" />
    <path d="M196.1 50.2L196.65 48.4" stroke="black" stroke-width="0.5" />
    <path d="M196.65 48.4L196.83 46.54" stroke="black" stroke-width="0.5" />
    <path d="M187.26 46.54V47" stroke="black" stroke-width="0.5" />
    <path d="M187.26 47H196.82" stroke="black" stroke-width="0.5" />
    <path d="M196.83 46.54H187.26" stroke="black" stroke-width="0.5" />
    <path d="M9.33002 114.42H61.16" stroke="black" stroke-width="0.5" />
    <path d="M1.04999 116.55H9.32999" stroke="black" stroke-width="0.5" />
    <path d="M78.71 114.42H104.78" stroke="black" stroke-width="0.5" />
    <path d="M9.33002 114.42V116.55" stroke="black" stroke-width="0.5" />
    <path d="M207.2 115.75H212.235" stroke="black" stroke-width="0.5" />
    <path d="M208.8 113.09H211.99" stroke="black" stroke-width="0.5" />
    <path d="M211.99 113.09V115.75" stroke="black" stroke-width="0.5" />
    <path d="M211.99 115.75H208.8" stroke="black" stroke-width="0.5" />
    <path d="M208.8 115.75V113.09" stroke="black" stroke-width="0.5" />
    <path d="M209.33 113.63H211.46" stroke="black" stroke-width="0.5" />
    <path d="M211.46 113.63V115.22" stroke="black" stroke-width="0.5" />
    <path d="M211.46 115.22H209.33" stroke="black" stroke-width="0.5" />
    <path d="M209.33 115.22V113.63" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 8.35999H17.04" stroke="black" stroke-width="0.5" />
    <path d="M17.04 8.35999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M46.3 3.73999V5.87999" stroke="black" stroke-width="0.5" />
    <path d="M68.11 3.73999H97.36" stroke="black" stroke-width="0.5" />
    <path d="M97.36 3.73999V5.87999" stroke="black" stroke-width="0.5" />
    <path d="M97.36 5.88H119.17" stroke="black" stroke-width="0.5" />
    <path d="M119.17 5.87999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M119.17 3.73999H148.43" stroke="black" stroke-width="0.5" />
    <path d="M148.43 3.73999V5.87999" stroke="black" stroke-width="0.5" />
    <path d="M148.43 5.88H170.24" stroke="black" stroke-width="0.5" />
    <path d="M170.24 5.87999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M170.24 3.73999H199.49" stroke="black" stroke-width="0.5" />
    <path d="M199.49 3.73999V5.87999" stroke="black" stroke-width="0.5" />
    <path d="M199.49 5.88H202.25" stroke="black" stroke-width="0.5" />
    <path d="M202.25 5.88V11.2" stroke="black" stroke-width="0.5" />
    <path d="M202.25 9.07001H210.4" stroke="black" stroke-width="0.5" />
    <path d="M202.25 11.2H210.4" stroke="black" stroke-width="0.5" />
    <path d="M209.73 9.07001V44.14" stroke="black" stroke-width="0.5" />
    <path d="M209.73 45.47V71.01" stroke="black" stroke-width="0.5" />
    <path d="M209.73 74.2V104.32" stroke="black" stroke-width="0.5" />
    <path d="M208.8 113.09V111.76" stroke="black" stroke-width="0.5" />
    <path d="M211.99 113.09V111.76" stroke="black" stroke-width="0.5" />
    <path d="M210.4 113.09H204.54" stroke="black" stroke-width="0.5" />
    <path d="M176.6 113.09H168.87" stroke="black" stroke-width="0.5" />
    <path d="M167.54 113.09H137.49" stroke="black" stroke-width="0.5" />
    <path d="M136.16 113.09H80.04" stroke="black" stroke-width="0.5" />
    <path d="M59.83 113.09H8" stroke="black" stroke-width="0.5" />
    <path d="M8 113.09V115.72" stroke="black" stroke-width="0.5" />
    <path d="M7.99998 115.72H5.66998" stroke="black" stroke-width="0.5" />
    <path d="M5.66998 115.72V111.21" stroke="black" stroke-width="0.5" />
    <path d="M5.66998 111.21H5.83998" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 111.21V96.54" stroke="black" stroke-width="0.5" />
    <path d="M3.70001 111.21V96.54" stroke="black" stroke-width="0.5" />
    <path d="M5.83999 96.54H1.04999" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 96.54V87.26" stroke="black" stroke-width="0.5" />
    <path d="M3.70001 67.28V45.47" stroke="black" stroke-width="0.5" />
    <path d="M3.70001 45.47V16.22" stroke="black" stroke-width="0.5" />
    <path d="M3.70001 67.28H5.84001" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 67.28V45.47" stroke="black" stroke-width="0.5" />
    <path d="M8.22998 46.8V45.47" stroke="black" stroke-width="0.5" />
    <path d="M5.84001 45.47H3.70001" stroke="black" stroke-width="0.5" />
    <path d="M3.70001 16.22H5.84001" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 16.22V8.35999" stroke="black" stroke-width="0.5" />
    <path d="M212.235 111.76H207.2" stroke="black" stroke-width="0.5" />
    <path d="M210.4 9.07001H211.06" stroke="black" stroke-width="0.5" />
    <path d="M207.2 113.09V111.76" stroke="black" stroke-width="0.5" />
    <path d="M111.16 114.42H177.15" stroke="black" stroke-width="0.5" />
    <path d="M138.12 23.54V21.53" stroke="black" stroke-width="0.5" />
    <path d="M138.12 21.53H139.5" stroke="black" stroke-width="0.5" />
    <path d="M139.5 21.53V28.38" stroke="black" stroke-width="0.5" />
    <path d="M139.5 28.38H138.12" stroke="black" stroke-width="0.5" />
    <path d="M138.12 28.38V26.37" stroke="black" stroke-width="0.5" />
    <path d="M138.12 26.37H138.46" stroke="black" stroke-width="0.5" />
    <path d="M138.46 26.37V26.14" stroke="black" stroke-width="0.5" />
    <path d="M138.46 26.14H138.12" stroke="black" stroke-width="0.5" />
    <path d="M138.12 26.14V23.77" stroke="black" stroke-width="0.5" />
    <path d="M138.12 23.77H138.46" stroke="black" stroke-width="0.5" />
    <path d="M138.46 23.77V23.54" stroke="black" stroke-width="0.5" />
    <path d="M138.46 23.54H138.12" stroke="black" stroke-width="0.5" />
    <path d="M105.84 100.89H107.17" stroke="black" stroke-width="0.5" />
    <path d="M137.49 79.86H142.21" stroke="black" stroke-width="0.5" />
    <path d="M142.21 79.86V87.19" stroke="black" stroke-width="0.5" />
    <path d="M142.21 87.19H137.49" stroke="black" stroke-width="0.5" />
    <path d="M137.49 87.19V79.86" stroke="black" stroke-width="0.5" />
    <path d="M137.98 82.12H141.71" stroke="black" stroke-width="0.5" />
    <path d="M141.71 82.12V85.78" stroke="black" stroke-width="0.5" />
    <path d="M141.71 85.78H137.98" stroke="black" stroke-width="0.5" />
    <path d="M137.98 85.78V82.12" stroke="black" stroke-width="0.5" />
    <path d="M98.64 112.26H101.46" stroke="black" stroke-width="0.5" />
    <path d="M101.46 112.26V111.57" stroke="black" stroke-width="0.5" />
    <path d="M101.46 111.57H98.64" stroke="black" stroke-width="0.5" />
    <path d="M98.64 111.57V112.26" stroke="black" stroke-width="0.5" />
    <path d="M211.06 9.07001V39.6891" stroke="black" stroke-width="0.5" />
    <path d="M211.06 39.16V75" stroke="black" stroke-width="0.5" />
    <path d="M211.06 74V113.09" stroke="black" stroke-width="0.5" />
    <path d="M209.73 105.65V113.09" stroke="black" stroke-width="0.5" />
    <path d="M141.1 44.81H145.13" stroke="black" stroke-width="0.5" />
    <path d="M145.13 44.81V45.49" stroke="black" stroke-width="0.5" />
    <path d="M145.13 45.49H141.1" stroke="black" stroke-width="0.5" />
    <path d="M141.1 45.49V44.81" stroke="black" stroke-width="0.5" />
    <path d="M148.43 2.95001H147.45" stroke="black" stroke-width="0.5" />
    <path d="M147.45 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M147.45 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M147.45 5.66998H148.43" stroke="black" stroke-width="0.5" />
    <path d="M148.43 5.67001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M119.17 5.66998H120.15" stroke="black" stroke-width="0.5" />
    <path d="M120.15 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M120.15 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M120.15 2.95001H119.17" stroke="black" stroke-width="0.5" />
    <path d="M119.17 2.95001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M142.66 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M142.66 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M142.66 5.66998H143.64" stroke="black" stroke-width="0.5" />
    <path d="M143.64 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M143.64 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M143.64 2.95001H142.66" stroke="black" stroke-width="0.5" />
    <path d="M147.45 5.66998H143.64" stroke="black" stroke-width="0.5" />
    <path d="M142.66 5.66998H120.15" stroke="black" stroke-width="0.5" />
    <path d="M142.66 2.95001H120.15" stroke="black" stroke-width="0.5" />
    <path d="M147.45 2.95001H143.64" stroke="black" stroke-width="0.5" />
    <path d="M120.15 3.52002H147.45" stroke="black" stroke-width="0.5" />
    <path d="M147.45 4.01001H120.15" stroke="black" stroke-width="0.5" />
    <path d="M147.45 4.01003V3.52002" stroke="black" stroke-width="0.5" />
    <path
      d="M120.15 4.01003H147.45L120.15 3.52002V4.01003Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M147.45 4.01003V3.52002H120.15L147.45 4.01003Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M120.15 4.01003V3.52002" stroke="black" stroke-width="0.5" />
    <path d="M133.31 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M133.31 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M133.31 5.66998H134.29" stroke="black" stroke-width="0.5" />
    <path d="M134.29 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M134.29 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M134.29 2.95001H133.31" stroke="black" stroke-width="0.5" />
    <path d="M124.93 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M124.93 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M124.93 5.66998H123.96" stroke="black" stroke-width="0.5" />
    <path d="M123.96 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M123.96 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M123.96 2.95001H124.93" stroke="black" stroke-width="0.5" />
    <path d="M46.3 2.95001H45.32" stroke="black" stroke-width="0.5" />
    <path d="M45.32 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M45.32 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M45.32 5.66998H46.3" stroke="black" stroke-width="0.5" />
    <path d="M46.3 5.67001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M17.04 5.66998H18.02" stroke="black" stroke-width="0.5" />
    <path d="M18.02 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M18.02 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M18.02 2.95001H17.04" stroke="black" stroke-width="0.5" />
    <path d="M17.04 2.95001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M40.54 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M40.54 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M40.54 5.66998H41.51" stroke="black" stroke-width="0.5" />
    <path d="M41.51 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M41.51 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M41.51 2.95001H40.54" stroke="black" stroke-width="0.5" />
    <path d="M45.32 5.66998H41.51" stroke="black" stroke-width="0.5" />
    <path d="M40.54 5.66998H18.02" stroke="black" stroke-width="0.5" />
    <path d="M40.54 2.95001H18.02" stroke="black" stroke-width="0.5" />
    <path d="M45.32 2.95001H41.51" stroke="black" stroke-width="0.5" />
    <path d="M18.02 3.52002H45.32" stroke="black" stroke-width="0.5" />
    <path d="M45.32 4.01001H18.02" stroke="black" stroke-width="0.5" />
    <path d="M45.32 4.01003V3.52002" stroke="black" stroke-width="0.5" />
    <path
      d="M18.02 4.01003H45.32L18.02 3.52002V4.01003Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M45.32 4.01003V3.52002H18.02L45.32 4.01003Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M18.02 4.01003V3.52002" stroke="black" stroke-width="0.5" />
    <path d="M31.18 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M31.18 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M31.18 5.66998H32.16" stroke="black" stroke-width="0.5" />
    <path d="M32.16 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M32.16 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M32.16 2.95001H31.18" stroke="black" stroke-width="0.5" />
    <path d="M22.8 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M22.8 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M22.8 5.66998H21.83" stroke="black" stroke-width="0.5" />
    <path d="M21.83 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M21.83 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M21.83 2.95001H22.8" stroke="black" stroke-width="0.5" />
    <path d="M97.36 2.95001H96.39" stroke="black" stroke-width="0.5" />
    <path d="M96.39 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M96.39 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M96.39 5.66998H97.36" stroke="black" stroke-width="0.5" />
    <path d="M97.36 5.67001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M68.11 5.66998H69.08" stroke="black" stroke-width="0.5" />
    <path d="M69.08 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M69.08 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M69.08 2.95001H68.11" stroke="black" stroke-width="0.5" />
    <path d="M68.11 2.95001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M91.6 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M91.6 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M91.6 5.66998H92.58" stroke="black" stroke-width="0.5" />
    <path d="M92.58 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M92.58 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M92.58 2.95001H91.6" stroke="black" stroke-width="0.5" />
    <path d="M96.39 5.66998H92.58" stroke="black" stroke-width="0.5" />
    <path d="M91.6 5.66998H69.08" stroke="black" stroke-width="0.5" />
    <path d="M91.6 2.95001H69.08" stroke="black" stroke-width="0.5" />
    <path d="M96.39 2.95001H92.58" stroke="black" stroke-width="0.5" />
    <path d="M69.08 3.52002H96.39" stroke="black" stroke-width="0.5" />
    <path d="M96.39 4.01001H69.08" stroke="black" stroke-width="0.5" />
    <path d="M96.39 4.01003V3.52002" stroke="black" stroke-width="0.5" />
    <path
      d="M69.08 4.01003H96.39L69.08 3.52002V4.01003Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M96.39 4.01003V3.52002H69.08L96.39 4.01003Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M69.08 4.01003V3.52002" stroke="black" stroke-width="0.5" />
    <path d="M82.25 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M82.25 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M82.25 5.66998H83.22" stroke="black" stroke-width="0.5" />
    <path d="M83.22 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M83.22 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M83.22 2.95001H82.25" stroke="black" stroke-width="0.5" />
    <path d="M73.87 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M73.87 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M73.87 5.66998H72.89" stroke="black" stroke-width="0.5" />
    <path d="M72.89 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M72.89 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M72.89 2.95001H73.87" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 16.22V17.19" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 17.19H3.42998" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 17.19H5.56997" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 17.19V16.22" stroke="black" stroke-width="0.5" />
    <path d="M5.56998 16.22H2.84998" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 45.47V44.5" stroke="black" stroke-width="0.5" />
    <path d="M5.56997 44.5H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M3.42998 44.5H2.84998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 44.5V45.47" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 45.47H5.56998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 21.98H3.42998" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 21.98H5.56997" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 21.98V21" stroke="black" stroke-width="0.5" />
    <path d="M5.56997 21H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M3.42998 21H2.84998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 21V21.98" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 17.19V21" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 21.98V44.5" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 21.98V44.5" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 17.19V21" stroke="black" stroke-width="0.5" />
    <path d="M3.42999 44.5V17.19" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 17.19V44.5" stroke="black" stroke-width="0.5" />
    <path d="M3.90999 17.19H3.42999" stroke="black" stroke-width="0.5" />
    <path
      d="M3.90999 44.5V17.19L3.42999 44.5H3.90999Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M3.90999 17.19H3.42999V44.5L3.90999 17.19Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M3.90999 44.5H3.42999" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 31.33H3.42998" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 31.33H5.56997" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 31.33V30.36" stroke="black" stroke-width="0.5" />
    <path d="M5.56997 30.36H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M3.42998 30.36H2.84998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 30.36V31.33" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 39.71H3.42998" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 39.71H5.56997" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 39.71V40.69" stroke="black" stroke-width="0.5" />
    <path d="M5.56997 40.69H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M3.42998 40.69H2.84998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 40.69V39.71" stroke="black" stroke-width="0.5" />
    <path d="M7.03998 45.47V44.14" stroke="black" stroke-width="0.5" />
    <path d="M6.5 45.47V42.95" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 42.95H6.50001" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 45.47H7.04003" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 87.26V96.54" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 67.28V68.26" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 68.26V67.28" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 68.26H3.42998" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 68.26H5.56997" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 87.26V86.29" stroke="black" stroke-width="0.5" />
    <path d="M5.56997 86.29H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M3.42998 86.29H2.84998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 86.29V87.26" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 68.26V86.29" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 87.26H5.56998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 68.26V86.29" stroke="black" stroke-width="0.5" />
    <path d="M3.42999 86.29V68.26" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 68.26V86.29" stroke="black" stroke-width="0.5" />
    <path d="M3.90999 68.26H3.42999" stroke="black" stroke-width="0.5" />
    <path d="M3.90999 86.29H3.42999" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 73.12H3.42998" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 73.12H5.56997" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 73.12V72.15" stroke="black" stroke-width="0.5" />
    <path d="M5.56997 72.15H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M3.42998 72.15H2.84998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 72.15V73.12" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 81.5H3.42998" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 81.5H5.56997" stroke="black" stroke-width="0.5" />
    <path d="M5.57001 81.5V82.48" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 82.48V81.5" stroke="black" stroke-width="0.5" />
    <path d="M3.42998 82.48H2.84998" stroke="black" stroke-width="0.5" />
    <path d="M199.49 2.95001H198.52" stroke="black" stroke-width="0.5" />
    <path d="M198.52 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M198.52 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M198.52 5.66998H199.49" stroke="black" stroke-width="0.5" />
    <path d="M199.49 5.67001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M170.24 5.66998H171.21" stroke="black" stroke-width="0.5" />
    <path d="M171.21 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M171.21 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M171.21 2.95001H170.24" stroke="black" stroke-width="0.5" />
    <path d="M170.24 2.95001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M193.73 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M193.73 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M193.73 5.66998H194.7" stroke="black" stroke-width="0.5" />
    <path d="M194.7 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M194.7 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M194.7 2.95001H193.73" stroke="black" stroke-width="0.5" />
    <path d="M198.52 5.66998H194.7" stroke="black" stroke-width="0.5" />
    <path d="M193.73 5.66998H171.21" stroke="black" stroke-width="0.5" />
    <path d="M193.73 2.95001H171.21" stroke="black" stroke-width="0.5" />
    <path d="M198.52 2.95001H194.7" stroke="black" stroke-width="0.5" />
    <path d="M171.21 3.52002H198.52" stroke="black" stroke-width="0.5" />
    <path d="M198.52 4.01001H171.21" stroke="black" stroke-width="0.5" />
    <path d="M198.52 4.01003V3.52002" stroke="black" stroke-width="0.5" />
    <path
      d="M171.21 4.01003H198.52L171.21 3.52002V4.01003Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path
      d="M198.52 4.01003V3.52002H171.21L198.52 4.01003Z"
      fill="black"
      stroke="black"
      stroke-width="0.2"
    />
    <path d="M171.21 4.01003V3.52002" stroke="black" stroke-width="0.5" />
    <path d="M184.38 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M184.38 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M184.38 5.66998H185.35" stroke="black" stroke-width="0.5" />
    <path d="M185.35 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M185.35 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M185.35 2.95001H184.38" stroke="black" stroke-width="0.5" />
    <path d="M176 2.95001V3.52001" stroke="black" stroke-width="0.5" />
    <path d="M176 4.01001V5.67001" stroke="black" stroke-width="0.5" />
    <path d="M176 5.66998H175.02" stroke="black" stroke-width="0.5" />
    <path d="M175.02 5.67001V4.01001" stroke="black" stroke-width="0.5" />
    <path d="M175.02 3.52001V2.95001" stroke="black" stroke-width="0.5" />
    <path d="M175.02 2.95001H176" stroke="black" stroke-width="0.5" />
    <path d="M104.78 115.75V114.42" stroke="black" stroke-width="0.5" />
    <path d="M111.16 115.75V114.42" stroke="black" stroke-width="0.5" />
    <path d="M104.78 115.75H111.16" stroke="black" stroke-width="0.5" />
    <path d="M106.37 113.09H109.56" stroke="black" stroke-width="0.5" />
    <path d="M109.56 113.09V115.75" stroke="black" stroke-width="0.5" />
    <path d="M109.56 115.75H106.37" stroke="black" stroke-width="0.5" />
    <path d="M106.37 115.75V113.09" stroke="black" stroke-width="0.5" />
    <path d="M106.9 113.63H109.03" stroke="black" stroke-width="0.5" />
    <path d="M109.03 113.63V115.22" stroke="black" stroke-width="0.5" />
    <path d="M109.03 115.22H106.9" stroke="black" stroke-width="0.5" />
    <path d="M106.9 115.22V113.63" stroke="black" stroke-width="0.5" />
    <path d="M111.16 110.43H108.5" stroke="black" stroke-width="0.5" />
    <path d="M108.5 105.65H107.17" stroke="black" stroke-width="0.5" />
    <path d="M106.37 113.09V111.76" stroke="black" stroke-width="0.5" />
    <path d="M109.56 113.09V111.76" stroke="black" stroke-width="0.5" />
    <path d="M111.16 113.09V110.43" stroke="black" stroke-width="0.5" />
    <path d="M130.06 105.91H135.1" stroke="black" stroke-width="0.5" />
    <path d="M135.1 105.91V112.12" stroke="black" stroke-width="0.5" />
    <path d="M135.1 112.12H130.06" stroke="black" stroke-width="0.5" />
    <path d="M130.06 112.12V105.91" stroke="black" stroke-width="0.5" />
    <path d="M130.06 107.32L128.82 107.49" stroke="black" stroke-width="0.5" />
    <path d="M128.82 107.49L127.66 107.97" stroke="black" stroke-width="0.5" />
    <path d="M127.66 107.97L126.67 108.73" stroke="black" stroke-width="0.5" />
    <path d="M126.67 108.73L125.91 109.72" stroke="black" stroke-width="0.5" />
    <path d="M125.91 109.72L125.43 110.88" stroke="black" stroke-width="0.5" />
    <path d="M125.43 110.88L125.27 112.12" stroke="black" stroke-width="0.5" />
    <path d="M130.06 112.12V111.88" stroke="black" stroke-width="0.5" />
    <path d="M130.06 111.88H125.27" stroke="black" stroke-width="0.5" />
    <path d="M125.27 112.12H130.06" stroke="black" stroke-width="0.5" />
    <path d="M187.26 61.37H188.37" stroke="black" stroke-width="0.5" />
    <path d="M188.37 61.37V64.41" stroke="black" stroke-width="0.5" />
    <path d="M188.37 64.41H187.26" stroke="black" stroke-width="0.5" />
    <path d="M187.26 64.41V61.37" stroke="black" stroke-width="0.5" />
    <path d="M209.73 57.05H208.06" stroke="black" stroke-width="0.5" />
    <path d="M208.06 57.05V62.33" stroke="black" stroke-width="0.5" />
    <path d="M208.06 62.33H209.73" stroke="black" stroke-width="0.5" />
    <path d="M209.73 62.33V57.05" stroke="black" stroke-width="0.5" />
    <path d="M209.73 63.77H208.38" stroke="black" stroke-width="0.5" />
    <path d="M208.38 63.77V66.43" stroke="black" stroke-width="0.5" />
    <path d="M208.38 66.43H209.73" stroke="black" stroke-width="0.5" />
    <path d="M209.73 66.43V63.77" stroke="black" stroke-width="0.5" />
    <path d="M166.75 89.16V71.71" stroke="black" stroke-width="0.5" />
    <path d="M165.42 89.16V72.26" stroke="black" stroke-width="0.5" />
    <path d="M157.91 62.87L163.96 68.93" stroke="black" stroke-width="0.5" />
    <path d="M164.63 69.59L166.75 71.71" stroke="black" stroke-width="0.5" />
    <path d="M157.36 64.2L163.02 69.87" stroke="black" stroke-width="0.5" />
    <path d="M163.69 70.54L165.42 72.26" stroke="black" stroke-width="0.5" />
    <path d="M149.56 62.87V64.2" stroke="black" stroke-width="0.5" />
    <path d="M149.56 62.87H157.91" stroke="black" stroke-width="0.5" />
    <path d="M149.56 64.2H157.36" stroke="black" stroke-width="0.5" />
    <path d="M163.35 89.16H167.52" stroke="black" stroke-width="0.5" />
    <path d="M167.52 89.16V81.23" stroke="black" stroke-width="0.5" />
    <path d="M167.52 81.23H163.35" stroke="black" stroke-width="0.5" />
    <path d="M163.35 81.23V89.16" stroke="black" stroke-width="0.5" />
    <path d="M191.8 70.22V70.6" stroke="black" stroke-width="0.5" />
    <path d="M192.91 70.22H191.8" stroke="black" stroke-width="0.5" />
    <path d="M192.91 70.6V70.22" stroke="black" stroke-width="0.5" />
    <path d="M191.8 70.6H192.91" stroke="black" stroke-width="0.5" />
    <path d="M189.41 66.31L189.49 65.99" stroke="black" stroke-width="0.5" />
    <path d="M189.49 65.99L189.68 65.75" stroke="black" stroke-width="0.5" />
    <path d="M189.68 65.75L189.96 65.66" stroke="black" stroke-width="0.5" />
    <path d="M194.76 65.66L195.03 65.75" stroke="black" stroke-width="0.5" />
    <path d="M195.03 65.75L195.23 65.98" stroke="black" stroke-width="0.5" />
    <path d="M195.23 65.98L195.3 66.31" stroke="black" stroke-width="0.5" />
    <path d="M195.3 69.13L195.14 69.59" stroke="black" stroke-width="0.5" />
    <path d="M195.14 69.59L194.76 69.78" stroke="black" stroke-width="0.5" />
    <path d="M189.96 69.78L189.57 69.59" stroke="black" stroke-width="0.5" />
    <path d="M189.57 69.59L189.41 69.13" stroke="black" stroke-width="0.5" />
    <path d="M189.41 66.31V69.13" stroke="black" stroke-width="0.5" />
    <path d="M194.76 65.66H189.96" stroke="black" stroke-width="0.5" />
    <path d="M195.3 69.13V66.31" stroke="black" stroke-width="0.5" />
    <path d="M189.96 69.78H194.76" stroke="black" stroke-width="0.5" />
    <path d="M189.04 65.28V71.01" stroke="black" stroke-width="0.5" />
    <path d="M195.67 65.28H189.04" stroke="black" stroke-width="0.5" />
    <path d="M195.67 71V65.28" stroke="black" stroke-width="0.5" />
    <path d="M189.04 71.01L195.67 71" stroke="black" stroke-width="0.5" />
    <path d="M105.05 96.06H105.43" stroke="black" stroke-width="0.5" />
    <path d="M105.05 94.96V96.06" stroke="black" stroke-width="0.5" />
    <path d="M105.43 94.96H105.05" stroke="black" stroke-width="0.5" />
    <path d="M105.43 96.06V94.96" stroke="black" stroke-width="0.5" />
    <path d="M101.15 98.46L100.82 98.38" stroke="black" stroke-width="0.5" />
    <path d="M100.82 98.38L100.58 98.18" stroke="black" stroke-width="0.5" />
    <path d="M100.58 98.18L100.5 97.91" stroke="black" stroke-width="0.5" />
    <path d="M100.49 93.11L100.58 92.84" stroke="black" stroke-width="0.5" />
    <path d="M100.58 92.84L100.82 92.64" stroke="black" stroke-width="0.5" />
    <path d="M100.82 92.64L101.15 92.57" stroke="black" stroke-width="0.5" />
    <path d="M103.96 92.57L104.42 92.73" stroke="black" stroke-width="0.5" />
    <path d="M104.42 92.73L104.61 93.11" stroke="black" stroke-width="0.5" />
    <path d="M104.61 97.91L104.42 98.3" stroke="black" stroke-width="0.5" />
    <path d="M104.42 98.3L103.96 98.46" stroke="black" stroke-width="0.5" />
    <path d="M101.15 98.46H103.96" stroke="black" stroke-width="0.5" />
    <path d="M100.49 93.11L100.5 97.91" stroke="black" stroke-width="0.5" />
    <path d="M103.96 92.57H101.15" stroke="black" stroke-width="0.5" />
    <path d="M104.61 97.91V93.11" stroke="black" stroke-width="0.5" />
    <path d="M100.12 98.82H105.84" stroke="black" stroke-width="0.5" />
    <path d="M100.12 92.2V98.82" stroke="black" stroke-width="0.5" />
    <path d="M105.84 92.2H100.12" stroke="black" stroke-width="0.5" />
    <path d="M105.84 98.82V92.2" stroke="black" stroke-width="0.5" />
    <path d="M105.64 111.76V112.3" stroke="black" stroke-width="0.5" />
    <path d="M104.51 112.89H103.98" stroke="black" stroke-width="0.5" />
    <path d="M92.41 112.89H91.88" stroke="black" stroke-width="0.5" />
    <path d="M105.64 102.19V102.72" stroke="black" stroke-width="0.5" />
    <path d="M104.19 112.32L104.18 112.31" stroke="black" stroke-width="0.5" />
    <path d="M104.18 112.31H104.17" stroke="black" stroke-width="0.5" />
    <path d="M104.17 112.31L104.15 112.3" stroke="black" stroke-width="0.5" />
    <path d="M104.15 112.3L104.13 112.29" stroke="black" stroke-width="0.5" />
    <path d="M104.13 112.29H104.12" stroke="black" stroke-width="0.5" />
    <path d="M104.12 112.29L104.1 112.28" stroke="black" stroke-width="0.5" />
    <path d="M104.1 112.28H104.09" stroke="black" stroke-width="0.5" />
    <path d="M104.09 112.28L104.07 112.27" stroke="black" stroke-width="0.5" />
    <path d="M104.07 112.27H104.05" stroke="black" stroke-width="0.5" />
    <path d="M104.05 112.27H104.03" stroke="black" stroke-width="0.5" />
    <path d="M104.03 112.27L104.02 112.26" stroke="black" stroke-width="0.5" />
    <path d="M104.02 112.26H104" stroke="black" stroke-width="0.5" />
    <path d="M104 112.26H103.98" stroke="black" stroke-width="0.5" />
    <path d="M104.35 112.48L104.34 112.47" stroke="black" stroke-width="0.5" />
    <path d="M104.34 112.47V112.45" stroke="black" stroke-width="0.5" />
    <path d="M104.34 112.45L104.33 112.44" stroke="black" stroke-width="0.5" />
    <path d="M104.33 112.44L104.31 112.42" stroke="black" stroke-width="0.5" />
    <path d="M104.31 112.42L104.3 112.41" stroke="black" stroke-width="0.5" />
    <path d="M104.3 112.41L104.29 112.4" stroke="black" stroke-width="0.5" />
    <path d="M104.29 112.4L104.28 112.38" stroke="black" stroke-width="0.5" />
    <path d="M104.28 112.38L104.27 112.37" stroke="black" stroke-width="0.5" />
    <path d="M104.27 112.37L104.25 112.36" stroke="black" stroke-width="0.5" />
    <path d="M104.25 112.36L104.24 112.35" stroke="black" stroke-width="0.5" />
    <path d="M104.24 112.35L104.23 112.34" stroke="black" stroke-width="0.5" />
    <path d="M104.23 112.34L104.21 112.33" stroke="black" stroke-width="0.5" />
    <path d="M104.21 112.33L104.2 112.32" stroke="black" stroke-width="0.5" />
    <path d="M104.2 112.32H104.19" stroke="black" stroke-width="0.5" />
    <path d="M104.41 112.69V112.68" stroke="black" stroke-width="0.5" />
    <path d="M104.41 112.68V112.66" stroke="black" stroke-width="0.5" />
    <path d="M104.41 112.66V112.64" stroke="black" stroke-width="0.5" />
    <path d="M104.41 112.64V112.63" stroke="black" stroke-width="0.5" />
    <path d="M104.41 112.63L104.4 112.61" stroke="black" stroke-width="0.5" />
    <path d="M104.4 112.61V112.59" stroke="black" stroke-width="0.5" />
    <path d="M104.4 112.59L104.39 112.58" stroke="black" stroke-width="0.5" />
    <path d="M104.39 112.58V112.56" stroke="black" stroke-width="0.5" />
    <path d="M104.39 112.56L104.38 112.54" stroke="black" stroke-width="0.5" />
    <path d="M104.38 112.54V112.53" stroke="black" stroke-width="0.5" />
    <path d="M104.38 112.53L104.37 112.51" stroke="black" stroke-width="0.5" />
    <path d="M104.37 112.51L104.36 112.5" stroke="black" stroke-width="0.5" />
    <path d="M104.36 112.5L104.35 112.48" stroke="black" stroke-width="0.5" />
    <path d="M104.05 112.62V112.63" stroke="black" stroke-width="0.5" />
    <path d="M104.05 112.63H104.06" stroke="black" stroke-width="0.5" />
    <path d="M104.06 112.63V112.64" stroke="black" stroke-width="0.5" />
    <path d="M104.06 112.64L104.07 112.65" stroke="black" stroke-width="0.5" />
    <path d="M104.07 112.65V112.66" stroke="black" stroke-width="0.5" />
    <path d="M104.07 112.66V112.67" stroke="black" stroke-width="0.5" />
    <path d="M104.07 112.67H104.08" stroke="black" stroke-width="0.5" />
    <path d="M104.08 112.67V112.68" stroke="black" stroke-width="0.5" />
    <path d="M104.08 112.68V112.69" stroke="black" stroke-width="0.5" />
    <path d="M103.98 112.6H103.99" stroke="black" stroke-width="0.5" />
    <path d="M103.99 112.6H104" stroke="black" stroke-width="0.5" />
    <path d="M104 112.6H104.01" stroke="black" stroke-width="0.5" />
    <path d="M104.01 112.6H104.02" stroke="black" stroke-width="0.5" />
    <path d="M104.02 112.6V112.61" stroke="black" stroke-width="0.5" />
    <path d="M104.02 112.61H104.03" stroke="black" stroke-width="0.5" />
    <path d="M104.03 112.61H104.04" stroke="black" stroke-width="0.5" />
    <path d="M104.04 112.61V112.62" stroke="black" stroke-width="0.5" />
    <path d="M104.04 112.62H104.05" stroke="black" stroke-width="0.5" />
    <path d="M92.03 112.48V112.49" stroke="black" stroke-width="0.5" />
    <path d="M92.03 112.49L92.02 112.51" stroke="black" stroke-width="0.5" />
    <path d="M92.02 112.51L92.01 112.52" stroke="black" stroke-width="0.5" />
    <path d="M92.01 112.52V112.54" stroke="black" stroke-width="0.5" />
    <path d="M92.01 112.54L92 112.55" stroke="black" stroke-width="0.5" />
    <path d="M92 112.55V112.57" stroke="black" stroke-width="0.5" />
    <path d="M92 112.57L91.99 112.59" stroke="black" stroke-width="0.5" />
    <path d="M91.99 112.59V112.6" stroke="black" stroke-width="0.5" />
    <path d="M91.99 112.6L91.98 112.62" stroke="black" stroke-width="0.5" />
    <path d="M91.98 112.62V112.64" stroke="black" stroke-width="0.5" />
    <path d="M91.98 112.64V112.66" stroke="black" stroke-width="0.5" />
    <path d="M91.98 112.66V112.67" stroke="black" stroke-width="0.5" />
    <path d="M91.98 112.67V112.69" stroke="black" stroke-width="0.5" />
    <path d="M92.19 112.32L92.18 112.33" stroke="black" stroke-width="0.5" />
    <path d="M92.18 112.33L92.17 112.34" stroke="black" stroke-width="0.5" />
    <path d="M92.17 112.34L92.15 112.35" stroke="black" stroke-width="0.5" />
    <path d="M92.15 112.35L92.14 112.36" stroke="black" stroke-width="0.5" />
    <path d="M92.14 112.36L92.12 112.37" stroke="black" stroke-width="0.5" />
    <path d="M92.12 112.37L92.11 112.38" stroke="black" stroke-width="0.5" />
    <path d="M92.11 112.38L92.1 112.39" stroke="black" stroke-width="0.5" />
    <path d="M92.1 112.39L92.09 112.41" stroke="black" stroke-width="0.5" />
    <path d="M92.09 112.41L92.08 112.42" stroke="black" stroke-width="0.5" />
    <path d="M92.08 112.42L92.07 112.43" stroke="black" stroke-width="0.5" />
    <path d="M92.07 112.43L92.06 112.45" stroke="black" stroke-width="0.5" />
    <path d="M92.06 112.45L92.05 112.46" stroke="black" stroke-width="0.5" />
    <path d="M92.05 112.46L92.04 112.48" stroke="black" stroke-width="0.5" />
    <path d="M92.04 112.48H92.03" stroke="black" stroke-width="0.5" />
    <path d="M92.41 112.26H92.39" stroke="black" stroke-width="0.5" />
    <path d="M92.39 112.26H92.37" stroke="black" stroke-width="0.5" />
    <path d="M92.37 112.26L92.36 112.27" stroke="black" stroke-width="0.5" />
    <path d="M92.36 112.27H92.34" stroke="black" stroke-width="0.5" />
    <path d="M92.34 112.27H92.32" stroke="black" stroke-width="0.5" />
    <path d="M92.32 112.27L92.31 112.28" stroke="black" stroke-width="0.5" />
    <path d="M92.31 112.28H92.29" stroke="black" stroke-width="0.5" />
    <path d="M92.29 112.28H92.27" stroke="black" stroke-width="0.5" />
    <path d="M92.27 112.28L92.26 112.29" stroke="black" stroke-width="0.5" />
    <path d="M92.26 112.29L92.24 112.3" stroke="black" stroke-width="0.5" />
    <path d="M92.24 112.3H92.22" stroke="black" stroke-width="0.5" />
    <path d="M92.22 112.3L92.21 112.31" stroke="black" stroke-width="0.5" />
    <path d="M92.21 112.31L92.19 112.32" stroke="black" stroke-width="0.5" />
    <path d="M92.34 112.62H92.35" stroke="black" stroke-width="0.5" />
    <path d="M92.35 112.62V112.61" stroke="black" stroke-width="0.5" />
    <path d="M92.35 112.61H92.36" stroke="black" stroke-width="0.5" />
    <path d="M92.36 112.61H92.37" stroke="black" stroke-width="0.5" />
    <path d="M92.37 112.61V112.6" stroke="black" stroke-width="0.5" />
    <path d="M92.37 112.6H92.38" stroke="black" stroke-width="0.5" />
    <path d="M92.38 112.6H92.39" stroke="black" stroke-width="0.5" />
    <path d="M92.39 112.6H92.4" stroke="black" stroke-width="0.5" />
    <path d="M92.4 112.6H92.41" stroke="black" stroke-width="0.5" />
    <path d="M92.31 112.69V112.68" stroke="black" stroke-width="0.5" />
    <path d="M92.31 112.68V112.67" stroke="black" stroke-width="0.5" />
    <path d="M92.31 112.67V112.66" stroke="black" stroke-width="0.5" />
    <path d="M92.31 112.66H92.32" stroke="black" stroke-width="0.5" />
    <path d="M92.32 112.66V112.65" stroke="black" stroke-width="0.5" />
    <path d="M92.32 112.65V112.64" stroke="black" stroke-width="0.5" />
    <path d="M92.32 112.64H92.33" stroke="black" stroke-width="0.5" />
    <path d="M92.33 112.64V112.63" stroke="black" stroke-width="0.5" />
    <path d="M92.33 112.63H92.34" stroke="black" stroke-width="0.5" />
    <path d="M92.34 112.63V112.62" stroke="black" stroke-width="0.5" />
    <path d="M105.23 112.14L105.24 112.15" stroke="black" stroke-width="0.5" />
    <path d="M105.24 112.15H105.25" stroke="black" stroke-width="0.5" />
    <path d="M105.25 112.15L105.27 112.16" stroke="black" stroke-width="0.5" />
    <path d="M105.27 112.16L105.28 112.17" stroke="black" stroke-width="0.5" />
    <path d="M105.28 112.17H105.3" stroke="black" stroke-width="0.5" />
    <path d="M105.3 112.17L105.32 112.18" stroke="black" stroke-width="0.5" />
    <path d="M105.32 112.18H105.33" stroke="black" stroke-width="0.5" />
    <path d="M105.33 112.18L105.35 112.19" stroke="black" stroke-width="0.5" />
    <path d="M105.35 112.19H105.37" stroke="black" stroke-width="0.5" />
    <path d="M105.37 112.19H105.38" stroke="black" stroke-width="0.5" />
    <path d="M105.38 112.19H105.4" stroke="black" stroke-width="0.5" />
    <path d="M105.4 112.19L105.42 112.2" stroke="black" stroke-width="0.5" />
    <path d="M105.42 112.2H105.44" stroke="black" stroke-width="0.5" />
    <path d="M105.22 112.14H105.23" stroke="black" stroke-width="0.5" />
    <path d="M105.07 111.98L105.08 111.99" stroke="black" stroke-width="0.5" />
    <path d="M105.08 111.99V112.01" stroke="black" stroke-width="0.5" />
    <path d="M105.08 112.01L105.09 112.02" stroke="black" stroke-width="0.5" />
    <path d="M105.09 112.02L105.11 112.04" stroke="black" stroke-width="0.5" />
    <path d="M105.11 112.04L105.12 112.05" stroke="black" stroke-width="0.5" />
    <path d="M105.12 112.05L105.13 112.06" stroke="black" stroke-width="0.5" />
    <path d="M105.13 112.06L105.14 112.07" stroke="black" stroke-width="0.5" />
    <path d="M105.14 112.07L105.15 112.09" stroke="black" stroke-width="0.5" />
    <path d="M105.15 112.09L105.17 112.1" stroke="black" stroke-width="0.5" />
    <path d="M105.17 112.1L105.18 112.11" stroke="black" stroke-width="0.5" />
    <path d="M105.18 112.11L105.19 112.12" stroke="black" stroke-width="0.5" />
    <path d="M105.19 112.12L105.21 112.13" stroke="black" stroke-width="0.5" />
    <path d="M105.21 112.13L105.22 112.14" stroke="black" stroke-width="0.5" />
    <path d="M105.01 111.76V111.78" stroke="black" stroke-width="0.5" />
    <path d="M105.01 111.78V111.8" stroke="black" stroke-width="0.5" />
    <path d="M105.01 111.8V111.82" stroke="black" stroke-width="0.5" />
    <path d="M105.01 111.82V111.83" stroke="black" stroke-width="0.5" />
    <path d="M105.01 111.83L105.02 111.85" stroke="black" stroke-width="0.5" />
    <path d="M105.02 111.85V111.87" stroke="black" stroke-width="0.5" />
    <path d="M105.02 111.87L105.03 111.88" stroke="black" stroke-width="0.5" />
    <path d="M105.03 111.88V111.9" stroke="black" stroke-width="0.5" />
    <path d="M105.03 111.9L105.04 111.92" stroke="black" stroke-width="0.5" />
    <path d="M105.04 111.92V111.93" stroke="black" stroke-width="0.5" />
    <path d="M105.04 111.93L105.05 111.95" stroke="black" stroke-width="0.5" />
    <path d="M105.05 111.95L105.06 111.96" stroke="black" stroke-width="0.5" />
    <path d="M105.06 111.96L105.07 111.98" stroke="black" stroke-width="0.5" />
    <path d="M105.37 111.83H105.36" stroke="black" stroke-width="0.5" />
    <path d="M105.36 111.83V111.82" stroke="black" stroke-width="0.5" />
    <path d="M105.36 111.82V111.81" stroke="black" stroke-width="0.5" />
    <path d="M105.36 111.81H105.35" stroke="black" stroke-width="0.5" />
    <path d="M105.35 111.81V111.8" stroke="black" stroke-width="0.5" />
    <path d="M105.35 111.8V111.79" stroke="black" stroke-width="0.5" />
    <path d="M105.35 111.79H105.34" stroke="black" stroke-width="0.5" />
    <path d="M105.34 111.79V111.78" stroke="black" stroke-width="0.5" />
    <path d="M105.34 111.78V111.77" stroke="black" stroke-width="0.5" />
    <path d="M105.34 111.77V111.76" stroke="black" stroke-width="0.5" />
    <path d="M105.44 111.86H105.43" stroke="black" stroke-width="0.5" />
    <path d="M105.43 111.86H105.42" stroke="black" stroke-width="0.5" />
    <path d="M105.42 111.86H105.41" stroke="black" stroke-width="0.5" />
    <path d="M105.41 111.86H105.4" stroke="black" stroke-width="0.5" />
    <path d="M105.4 111.86V111.85" stroke="black" stroke-width="0.5" />
    <path d="M105.4 111.85H105.39" stroke="black" stroke-width="0.5" />
    <path d="M105.39 111.85H105.38" stroke="black" stroke-width="0.5" />
    <path d="M105.38 111.85V111.84" stroke="black" stroke-width="0.5" />
    <path d="M105.38 111.84H105.37" stroke="black" stroke-width="0.5" />
    <path d="M105.37 111.84V111.83" stroke="black" stroke-width="0.5" />
    <path d="M105.07 102.51L105.06 102.52" stroke="black" stroke-width="0.5" />
    <path d="M105.06 102.52L105.05 102.53" stroke="black" stroke-width="0.5" />
    <path d="M105.05 102.53L105.04 102.55" stroke="black" stroke-width="0.5" />
    <path d="M105.04 102.55V102.56" stroke="black" stroke-width="0.5" />
    <path d="M105.04 102.56L105.03 102.58" stroke="black" stroke-width="0.5" />
    <path d="M105.03 102.58V102.6" stroke="black" stroke-width="0.5" />
    <path d="M105.03 102.6L105.02 102.61" stroke="black" stroke-width="0.5" />
    <path d="M105.02 102.61V102.63" stroke="black" stroke-width="0.5" />
    <path d="M105.02 102.63V102.65" stroke="black" stroke-width="0.5" />
    <path d="M105.02 102.65L105.01 102.66" stroke="black" stroke-width="0.5" />
    <path d="M105.01 102.66V102.68" stroke="black" stroke-width="0.5" />
    <path d="M105.01 102.68V102.7" stroke="black" stroke-width="0.5" />
    <path d="M105.01 102.7V102.72" stroke="black" stroke-width="0.5" />
    <path d="M105.22 102.35L105.21 102.36" stroke="black" stroke-width="0.5" />
    <path d="M105.21 102.36H105.2" stroke="black" stroke-width="0.5" />
    <path d="M105.2 102.36L105.18 102.37" stroke="black" stroke-width="0.5" />
    <path d="M105.18 102.37L105.17 102.39" stroke="black" stroke-width="0.5" />
    <path d="M105.17 102.39L105.16 102.4" stroke="black" stroke-width="0.5" />
    <path d="M105.16 102.4L105.14 102.41" stroke="black" stroke-width="0.5" />
    <path d="M105.14 102.41L105.13 102.42" stroke="black" stroke-width="0.5" />
    <path d="M105.13 102.42L105.12 102.43" stroke="black" stroke-width="0.5" />
    <path d="M105.12 102.43L105.11 102.45" stroke="black" stroke-width="0.5" />
    <path d="M105.11 102.45L105.1 102.46" stroke="black" stroke-width="0.5" />
    <path d="M105.1 102.46L105.09 102.47" stroke="black" stroke-width="0.5" />
    <path d="M105.09 102.47L105.08 102.49" stroke="black" stroke-width="0.5" />
    <path d="M105.08 102.49L105.07 102.5" stroke="black" stroke-width="0.5" />
    <path d="M105.07 102.5V102.51" stroke="black" stroke-width="0.5" />
    <path d="M105.23 102.35H105.22" stroke="black" stroke-width="0.5" />
    <path d="M105.44 102.29H105.42" stroke="black" stroke-width="0.5" />
    <path d="M105.42 102.29H105.41" stroke="black" stroke-width="0.5" />
    <path d="M105.41 102.29H105.39" stroke="black" stroke-width="0.5" />
    <path d="M105.39 102.29H105.37" stroke="black" stroke-width="0.5" />
    <path d="M105.37 102.29L105.36 102.3" stroke="black" stroke-width="0.5" />
    <path d="M105.36 102.3H105.34" stroke="black" stroke-width="0.5" />
    <path d="M105.34 102.3L105.32 102.31" stroke="black" stroke-width="0.5" />
    <path d="M105.32 102.31H105.31" stroke="black" stroke-width="0.5" />
    <path d="M105.31 102.31L105.29 102.32" stroke="black" stroke-width="0.5" />
    <path d="M105.29 102.32H105.27" stroke="black" stroke-width="0.5" />
    <path d="M105.27 102.32L105.26 102.33" stroke="black" stroke-width="0.5" />
    <path d="M105.26 102.33L105.24 102.34" stroke="black" stroke-width="0.5" />
    <path d="M105.24 102.34L105.23 102.35" stroke="black" stroke-width="0.5" />
    <path d="M105.37 102.65L105.38 102.64" stroke="black" stroke-width="0.5" />
    <path d="M105.38 102.64H105.39" stroke="black" stroke-width="0.5" />
    <path d="M105.39 102.64V102.63" stroke="black" stroke-width="0.5" />
    <path d="M105.39 102.63H105.4" stroke="black" stroke-width="0.5" />
    <path d="M105.4 102.63H105.41" stroke="black" stroke-width="0.5" />
    <path d="M105.41 102.63L105.42 102.62" stroke="black" stroke-width="0.5" />
    <path d="M105.42 102.62H105.43" stroke="black" stroke-width="0.5" />
    <path d="M105.43 102.62H105.44" stroke="black" stroke-width="0.5" />
    <path d="M105.34 102.72V102.71" stroke="black" stroke-width="0.5" />
    <path d="M105.34 102.71V102.7" stroke="black" stroke-width="0.5" />
    <path d="M105.34 102.7L105.35 102.69" stroke="black" stroke-width="0.5" />
    <path d="M105.35 102.69V102.68" stroke="black" stroke-width="0.5" />
    <path d="M105.35 102.68V102.67" stroke="black" stroke-width="0.5" />
    <path d="M105.35 102.67H105.36" stroke="black" stroke-width="0.5" />
    <path d="M105.36 102.67V102.66" stroke="black" stroke-width="0.5" />
    <path d="M105.36 102.66H105.37" stroke="black" stroke-width="0.5" />
    <path d="M105.37 102.66V102.65" stroke="black" stroke-width="0.5" />
    <path d="M104.41 112.69V112.89" stroke="black" stroke-width="0.5" />
    <path d="M104.08 112.89V112.69" stroke="black" stroke-width="0.5" />
    <path d="M92.41 112.26H103.98" stroke="black" stroke-width="0.5" />
    <path d="M103.98 112.6H92.41" stroke="black" stroke-width="0.5" />
    <path d="M91.98 112.89V112.69" stroke="black" stroke-width="0.5" />
    <path d="M92.31 112.69V112.89" stroke="black" stroke-width="0.5" />
    <path d="M105.64 111.76H105.84" stroke="black" stroke-width="0.5" />
    <path d="M105.84 112.3H105.64" stroke="black" stroke-width="0.5" />
    <path d="M105.44 111.86H105.64" stroke="black" stroke-width="0.5" />
    <path d="M105.64 112.2H105.44" stroke="black" stroke-width="0.5" />
    <path d="M105.34 102.72V111.76" stroke="black" stroke-width="0.5" />
    <path d="M105.01 111.76V102.72" stroke="black" stroke-width="0.5" />
    <path d="M105.64 102.62H105.44" stroke="black" stroke-width="0.5" />
    <path d="M105.44 102.29H105.64" stroke="black" stroke-width="0.5" />
    <path d="M104.51 112.89V113.09" stroke="black" stroke-width="0.5" />
    <path d="M103.98 113.09V112.89" stroke="black" stroke-width="0.5" />
    <path d="M92.41 112.89V113.09" stroke="black" stroke-width="0.5" />
    <path d="M91.88 113.09V112.89" stroke="black" stroke-width="0.5" />
    <path d="M105.64 102.19H105.84" stroke="black" stroke-width="0.5" />
    <path d="M105.84 102.72H105.64" stroke="black" stroke-width="0.5" />
    <path
      d="M104.86 108.84C104.911 108.837 104.962 108.827 105.01 108.81"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M105.35 108.43C105.368 108.344 105.365 108.254 105.34 108.17"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M105.01 107.81C104.953 107.786 104.892 107.773 104.83 107.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M105.02 108.12C104.989 108.088 104.952 108.064 104.91 108.051C104.868 108.037 104.823 108.034 104.78 108.042C104.736 108.05 104.696 108.069 104.661 108.097C104.627 108.124 104.6 108.16 104.583 108.201C104.566 108.241 104.559 108.285 104.563 108.329C104.567 108.373 104.582 108.415 104.607 108.452C104.631 108.489 104.665 108.519 104.703 108.539C104.742 108.56 104.786 108.57 104.83 108.57"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.83 108.57C104.897 108.57 104.961 108.545 105.01 108.5"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.29 106.47C101.95 106.364 101.596 106.31 101.24 106.31"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M101.24 106.31C100.884 106.31 100.53 106.364 100.19 106.47"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M100.19 106.47C99.8908 106.565 99.61 106.71 99.36 106.9"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M99.36 106.9C99.1185 107.079 98.9175 107.308 98.77 107.57"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M98.77 107.57C98.6444 107.796 98.579 108.051 98.58 108.31"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M98.58 108.31C98.5807 108.565 98.6461 108.817 98.77 109.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M98.77 109.04C98.9163 109.306 99.1174 109.538 99.36 109.72"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M99.36 109.72C99.6107 109.906 99.8914 110.048 100.19 110.14"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M100.19 110.14C100.53 110.246 100.884 110.3 101.24 110.3"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M101.24 110.3C101.596 110.3 101.95 110.246 102.29 110.14"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.29 110.14C102.655 110.025 102.993 109.834 103.28 109.58"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.83 108.84C104.535 108.853 104.246 108.926 103.98 109.053C103.714 109.18 103.476 109.359 103.28 109.58"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.83 108.84C104.891 108.84 104.952 108.83 105.01 108.81"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M105.34 108.44L105.36 108.35" stroke="black" stroke-width="0.5" />
    <path d="M105.36 108.35V108.26" stroke="black" stroke-width="0.5" />
    <path d="M105.36 108.26L105.34 108.17" stroke="black" stroke-width="0.5" />
    <path
      d="M105.01 107.81C104.953 107.786 104.892 107.773 104.83 107.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M103.28 107.03C103.476 107.251 103.714 107.43 103.98 107.557C104.246 107.684 104.535 107.757 104.83 107.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M103.28 107.03C102.993 106.776 102.655 106.585 102.29 106.47"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M104.86 108.84C104.366 108.867 103.894 109.049 103.51 109.36"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M103.4 107.14C103.785 107.515 104.293 107.739 104.83 107.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.29 110.14C102.759 109.994 103.181 109.725 103.51 109.36"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M101.24 110.3C101.596 110.3 101.95 110.246 102.29 110.14"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M100.19 110.14C100.53 110.246 100.884 110.3 101.24 110.3"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M99.36 109.72C99.6107 109.906 99.8914 110.048 100.19 110.14"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M98.77 109.04C98.9163 109.306 99.1174 109.538 99.36 109.72"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M98.58 108.31C98.5807 108.565 98.6461 108.817 98.77 109.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M98.77 107.57C98.6444 107.796 98.579 108.051 98.58 108.31"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M99.36 106.9C99.1185 107.079 98.9175 107.308 98.77 107.57"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M100.19 106.47C99.8908 106.565 99.61 106.71 99.36 106.9"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M101.24 106.31C100.884 106.31 100.53 106.364 100.19 106.47"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.29 106.47C101.95 106.364 101.596 106.31 101.24 106.31"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M103.4 107.14C103.089 106.831 102.708 106.601 102.29 106.47"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M208.4 70.81H208.93" stroke="black" stroke-width="0.5" />
    <path d="M209.53 69.68V69.14" stroke="black" stroke-width="0.5" />
    <path d="M209.53 57.57V57.04" stroke="black" stroke-width="0.5" />
    <path d="M198.83 70.81H199.36" stroke="black" stroke-width="0.5" />
    <path d="M208.96 69.36L208.95 69.35" stroke="black" stroke-width="0.5" />
    <path d="M208.95 69.35L208.94 69.33" stroke="black" stroke-width="0.5" />
    <path d="M208.94 69.33V69.32" stroke="black" stroke-width="0.5" />
    <path d="M208.94 69.32L208.93 69.3" stroke="black" stroke-width="0.5" />
    <path d="M208.93 69.3L208.92 69.28" stroke="black" stroke-width="0.5" />
    <path d="M208.92 69.28V69.27" stroke="black" stroke-width="0.5" />
    <path d="M208.92 69.27L208.91 69.25" stroke="black" stroke-width="0.5" />
    <path d="M208.91 69.25V69.23" stroke="black" stroke-width="0.5" />
    <path d="M208.91 69.23V69.22" stroke="black" stroke-width="0.5" />
    <path d="M208.91 69.22L208.9 69.2" stroke="black" stroke-width="0.5" />
    <path d="M208.9 69.2V69.18" stroke="black" stroke-width="0.5" />
    <path d="M208.9 69.18V69.17" stroke="black" stroke-width="0.5" />
    <path d="M208.9 69.17V69.15" stroke="black" stroke-width="0.5" />
    <path d="M208.9 69.15V69.14" stroke="black" stroke-width="0.5" />
    <path d="M209.12 69.52L209.1 69.51" stroke="black" stroke-width="0.5" />
    <path d="M209.1 69.51L209.09 69.5" stroke="black" stroke-width="0.5" />
    <path d="M209.09 69.5L209.07 69.49" stroke="black" stroke-width="0.5" />
    <path d="M209.07 69.49L209.06 69.48" stroke="black" stroke-width="0.5" />
    <path d="M209.06 69.48L209.05 69.47" stroke="black" stroke-width="0.5" />
    <path d="M209.05 69.47L209.03 69.46" stroke="black" stroke-width="0.5" />
    <path d="M209.03 69.46L209.02 69.44" stroke="black" stroke-width="0.5" />
    <path d="M209.02 69.44L209.01 69.43" stroke="black" stroke-width="0.5" />
    <path d="M209.01 69.43L209 69.42" stroke="black" stroke-width="0.5" />
    <path d="M209 69.42L208.99 69.41" stroke="black" stroke-width="0.5" />
    <path d="M208.99 69.41L208.98 69.39" stroke="black" stroke-width="0.5" />
    <path d="M208.98 69.39L208.97 69.38" stroke="black" stroke-width="0.5" />
    <path d="M208.97 69.38L208.96 69.36" stroke="black" stroke-width="0.5" />
    <path d="M209.33 69.58H209.31" stroke="black" stroke-width="0.5" />
    <path d="M209.31 69.58L209.3 69.57" stroke="black" stroke-width="0.5" />
    <path d="M209.3 69.57H209.28" stroke="black" stroke-width="0.5" />
    <path d="M209.28 69.57H209.26" stroke="black" stroke-width="0.5" />
    <path d="M209.26 69.57H209.25" stroke="black" stroke-width="0.5" />
    <path d="M209.25 69.57L209.23 69.56" stroke="black" stroke-width="0.5" />
    <path d="M209.23 69.56H209.21" stroke="black" stroke-width="0.5" />
    <path d="M209.21 69.56L209.2 69.55" stroke="black" stroke-width="0.5" />
    <path d="M209.2 69.55H209.18" stroke="black" stroke-width="0.5" />
    <path d="M209.18 69.55L209.16 69.54" stroke="black" stroke-width="0.5" />
    <path d="M209.16 69.54L209.15 69.53" stroke="black" stroke-width="0.5" />
    <path d="M209.15 69.53H209.13" stroke="black" stroke-width="0.5" />
    <path d="M209.13 69.53L209.12 69.52" stroke="black" stroke-width="0.5" />
    <path d="M209.26 69.21V69.22" stroke="black" stroke-width="0.5" />
    <path d="M209.26 69.22H209.27" stroke="black" stroke-width="0.5" />
    <path d="M209.27 69.22V69.23" stroke="black" stroke-width="0.5" />
    <path d="M209.27 69.23H209.28" stroke="black" stroke-width="0.5" />
    <path d="M209.28 69.23H209.29" stroke="black" stroke-width="0.5" />
    <path d="M209.29 69.23V69.24" stroke="black" stroke-width="0.5" />
    <path d="M209.29 69.24H209.3" stroke="black" stroke-width="0.5" />
    <path d="M209.3 69.24H209.31" stroke="black" stroke-width="0.5" />
    <path d="M209.31 69.24H209.32" stroke="black" stroke-width="0.5" />
    <path d="M209.32 69.24H209.33" stroke="black" stroke-width="0.5" />
    <path d="M209.23 69.14V69.15" stroke="black" stroke-width="0.5" />
    <path d="M209.23 69.15V69.16" stroke="black" stroke-width="0.5" />
    <path d="M209.23 69.16L209.24 69.17" stroke="black" stroke-width="0.5" />
    <path d="M209.24 69.17V69.18" stroke="black" stroke-width="0.5" />
    <path d="M209.24 69.18V69.19" stroke="black" stroke-width="0.5" />
    <path d="M209.24 69.19H209.25" stroke="black" stroke-width="0.5" />
    <path d="M209.25 69.19V69.2" stroke="black" stroke-width="0.5" />
    <path d="M209.25 69.2V69.21" stroke="black" stroke-width="0.5" />
    <path d="M209.25 69.21H209.26" stroke="black" stroke-width="0.5" />
    <path d="M209.12 57.2L209.13 57.19" stroke="black" stroke-width="0.5" />
    <path d="M209.13 57.19H209.14" stroke="black" stroke-width="0.5" />
    <path d="M209.14 57.19L209.16 57.18" stroke="black" stroke-width="0.5" />
    <path d="M209.16 57.18L209.18 57.17" stroke="black" stroke-width="0.5" />
    <path d="M209.18 57.17H209.19" stroke="black" stroke-width="0.5" />
    <path d="M209.19 57.17L209.21 57.16" stroke="black" stroke-width="0.5" />
    <path d="M209.21 57.16H209.22" stroke="black" stroke-width="0.5" />
    <path d="M209.22 57.16L209.24 57.15" stroke="black" stroke-width="0.5" />
    <path d="M209.24 57.15H209.26" stroke="black" stroke-width="0.5" />
    <path d="M209.26 57.15H209.28" stroke="black" stroke-width="0.5" />
    <path d="M209.28 57.15L209.29 57.14" stroke="black" stroke-width="0.5" />
    <path d="M209.29 57.14H209.31" stroke="black" stroke-width="0.5" />
    <path d="M209.31 57.14H209.33" stroke="black" stroke-width="0.5" />
    <path d="M208.96 57.36L208.97 57.34" stroke="black" stroke-width="0.5" />
    <path d="M208.97 57.34L208.98 57.33" stroke="black" stroke-width="0.5" />
    <path d="M208.98 57.33L208.99 57.32" stroke="black" stroke-width="0.5" />
    <path d="M208.99 57.32L209 57.3" stroke="black" stroke-width="0.5" />
    <path d="M209 57.3L209.01 57.29" stroke="black" stroke-width="0.5" />
    <path d="M209.01 57.29L209.02 57.28" stroke="black" stroke-width="0.5" />
    <path d="M209.02 57.28L209.03 57.26" stroke="black" stroke-width="0.5" />
    <path d="M209.03 57.26L209.04 57.25" stroke="black" stroke-width="0.5" />
    <path d="M209.04 57.25L209.06 57.24" stroke="black" stroke-width="0.5" />
    <path d="M209.06 57.24L209.07 57.23" stroke="black" stroke-width="0.5" />
    <path d="M209.07 57.23L209.08 57.22" stroke="black" stroke-width="0.5" />
    <path d="M209.08 57.22L209.1 57.21" stroke="black" stroke-width="0.5" />
    <path d="M209.1 57.21L209.11 57.2" stroke="black" stroke-width="0.5" />
    <path d="M209.11 57.2H209.12" stroke="black" stroke-width="0.5" />
    <path d="M208.9 57.57V57.56" stroke="black" stroke-width="0.5" />
    <path d="M208.9 57.56V57.54" stroke="black" stroke-width="0.5" />
    <path d="M208.9 57.54V57.52" stroke="black" stroke-width="0.5" />
    <path d="M208.9 57.52L208.91 57.51" stroke="black" stroke-width="0.5" />
    <path d="M208.91 57.51V57.49" stroke="black" stroke-width="0.5" />
    <path d="M208.91 57.49V57.47" stroke="black" stroke-width="0.5" />
    <path d="M208.91 57.47L208.92 57.45" stroke="black" stroke-width="0.5" />
    <path d="M208.92 57.45V57.44" stroke="black" stroke-width="0.5" />
    <path d="M208.92 57.44L208.93 57.42" stroke="black" stroke-width="0.5" />
    <path d="M208.93 57.42V57.41" stroke="black" stroke-width="0.5" />
    <path d="M208.93 57.41L208.94 57.39" stroke="black" stroke-width="0.5" />
    <path d="M208.94 57.39L208.95 57.37" stroke="black" stroke-width="0.5" />
    <path d="M208.95 57.37L208.96 57.36" stroke="black" stroke-width="0.5" />
    <path d="M209.26 57.5V57.51" stroke="black" stroke-width="0.5" />
    <path d="M209.26 57.51H209.25" stroke="black" stroke-width="0.5" />
    <path d="M209.25 57.51V57.52" stroke="black" stroke-width="0.5" />
    <path d="M209.25 57.52L209.24 57.53" stroke="black" stroke-width="0.5" />
    <path d="M209.24 57.53V57.54" stroke="black" stroke-width="0.5" />
    <path d="M209.24 57.54V57.55" stroke="black" stroke-width="0.5" />
    <path d="M209.24 57.55H209.23" stroke="black" stroke-width="0.5" />
    <path d="M209.23 57.55V57.56" stroke="black" stroke-width="0.5" />
    <path d="M209.23 57.56V57.57" stroke="black" stroke-width="0.5" />
    <path d="M209.33 57.47H209.32" stroke="black" stroke-width="0.5" />
    <path d="M209.32 57.47V57.48" stroke="black" stroke-width="0.5" />
    <path d="M209.32 57.48H209.31" stroke="black" stroke-width="0.5" />
    <path d="M209.31 57.48H209.3" stroke="black" stroke-width="0.5" />
    <path d="M209.3 57.48H209.29" stroke="black" stroke-width="0.5" />
    <path d="M209.29 57.48V57.49" stroke="black" stroke-width="0.5" />
    <path d="M209.29 57.49H209.28" stroke="black" stroke-width="0.5" />
    <path d="M209.28 57.49H209.27" stroke="black" stroke-width="0.5" />
    <path d="M209.27 57.49V57.5" stroke="black" stroke-width="0.5" />
    <path d="M209.27 57.5H209.26" stroke="black" stroke-width="0.5" />
    <path d="M208.78 70.39V70.4" stroke="black" stroke-width="0.5" />
    <path d="M208.78 70.4L208.79 70.42" stroke="black" stroke-width="0.5" />
    <path d="M208.79 70.42L208.8 70.43" stroke="black" stroke-width="0.5" />
    <path d="M208.8 70.43V70.45" stroke="black" stroke-width="0.5" />
    <path d="M208.8 70.45L208.81 70.47" stroke="black" stroke-width="0.5" />
    <path d="M208.81 70.47L208.82 70.48" stroke="black" stroke-width="0.5" />
    <path d="M208.82 70.48V70.5" stroke="black" stroke-width="0.5" />
    <path d="M208.82 70.5V70.52" stroke="black" stroke-width="0.5" />
    <path d="M208.82 70.52L208.83 70.53" stroke="black" stroke-width="0.5" />
    <path d="M208.83 70.53V70.55" stroke="black" stroke-width="0.5" />
    <path d="M208.83 70.55V70.57" stroke="black" stroke-width="0.5" />
    <path d="M208.83 70.57V70.58" stroke="black" stroke-width="0.5" />
    <path d="M208.83 70.58V70.6" stroke="black" stroke-width="0.5" />
    <path d="M208.83 70.6V70.61" stroke="black" stroke-width="0.5" />
    <path d="M208.62 70.23L208.63 70.24" stroke="black" stroke-width="0.5" />
    <path d="M208.63 70.24L208.65 70.25" stroke="black" stroke-width="0.5" />
    <path d="M208.65 70.25L208.66 70.26" stroke="black" stroke-width="0.5" />
    <path d="M208.66 70.26L208.67 70.27" stroke="black" stroke-width="0.5" />
    <path d="M208.67 70.27L208.69 70.28" stroke="black" stroke-width="0.5" />
    <path d="M208.69 70.28L208.7 70.29" stroke="black" stroke-width="0.5" />
    <path d="M208.7 70.29L208.71 70.31" stroke="black" stroke-width="0.5" />
    <path d="M208.71 70.31L208.72 70.32" stroke="black" stroke-width="0.5" />
    <path d="M208.72 70.32L208.73 70.33" stroke="black" stroke-width="0.5" />
    <path d="M208.73 70.33L208.74 70.34" stroke="black" stroke-width="0.5" />
    <path d="M208.74 70.34L208.76 70.36" stroke="black" stroke-width="0.5" />
    <path d="M208.76 70.36V70.37" stroke="black" stroke-width="0.5" />
    <path d="M208.76 70.37L208.77 70.39" stroke="black" stroke-width="0.5" />
    <path d="M208.77 70.39H208.78" stroke="black" stroke-width="0.5" />
    <path d="M208.4 70.17H208.42" stroke="black" stroke-width="0.5" />
    <path d="M208.42 70.17L208.44 70.18" stroke="black" stroke-width="0.5" />
    <path d="M208.44 70.18H208.45" stroke="black" stroke-width="0.5" />
    <path d="M208.45 70.18H208.47" stroke="black" stroke-width="0.5" />
    <path d="M208.47 70.18H208.49" stroke="black" stroke-width="0.5" />
    <path d="M208.49 70.18L208.5 70.19" stroke="black" stroke-width="0.5" />
    <path d="M208.5 70.19H208.52" stroke="black" stroke-width="0.5" />
    <path d="M208.52 70.19L208.54 70.2" stroke="black" stroke-width="0.5" />
    <path d="M208.54 70.2H208.55" stroke="black" stroke-width="0.5" />
    <path d="M208.55 70.2L208.57 70.21" stroke="black" stroke-width="0.5" />
    <path d="M208.57 70.21L208.59 70.22" stroke="black" stroke-width="0.5" />
    <path d="M208.59 70.22H208.6" stroke="black" stroke-width="0.5" />
    <path d="M208.6 70.22L208.62 70.23" stroke="black" stroke-width="0.5" />
    <path d="M208.47 70.54V70.53" stroke="black" stroke-width="0.5" />
    <path d="M208.47 70.53H208.46" stroke="black" stroke-width="0.5" />
    <path d="M208.46 70.53V70.52" stroke="black" stroke-width="0.5" />
    <path d="M208.46 70.52H208.45" stroke="black" stroke-width="0.5" />
    <path d="M208.45 70.52H208.44" stroke="black" stroke-width="0.5" />
    <path d="M208.44 70.52V70.51" stroke="black" stroke-width="0.5" />
    <path d="M208.44 70.51H208.43" stroke="black" stroke-width="0.5" />
    <path d="M208.43 70.51H208.42" stroke="black" stroke-width="0.5" />
    <path d="M208.42 70.51H208.41" stroke="black" stroke-width="0.5" />
    <path d="M208.41 70.51H208.4" stroke="black" stroke-width="0.5" />
    <path d="M208.5 70.61V70.6" stroke="black" stroke-width="0.5" />
    <path d="M208.5 70.6V70.59" stroke="black" stroke-width="0.5" />
    <path d="M208.5 70.59V70.58" stroke="black" stroke-width="0.5" />
    <path d="M208.5 70.58L208.49 70.57" stroke="black" stroke-width="0.5" />
    <path d="M208.49 70.57V70.56" stroke="black" stroke-width="0.5" />
    <path d="M208.49 70.56V70.55" stroke="black" stroke-width="0.5" />
    <path d="M208.49 70.55H208.48" stroke="black" stroke-width="0.5" />
    <path d="M208.48 70.55V70.54" stroke="black" stroke-width="0.5" />
    <path d="M208.48 70.54H208.47" stroke="black" stroke-width="0.5" />
    <path d="M199.14 70.23H199.15" stroke="black" stroke-width="0.5" />
    <path d="M199.15 70.23L199.17 70.22" stroke="black" stroke-width="0.5" />
    <path d="M199.17 70.22L199.19 70.21" stroke="black" stroke-width="0.5" />
    <path d="M199.19 70.21L199.2 70.2" stroke="black" stroke-width="0.5" />
    <path d="M199.2 70.2H199.22" stroke="black" stroke-width="0.5" />
    <path d="M199.22 70.2L199.23 70.19" stroke="black" stroke-width="0.5" />
    <path d="M199.23 70.19H199.25" stroke="black" stroke-width="0.5" />
    <path d="M199.25 70.19L199.27 70.18" stroke="black" stroke-width="0.5" />
    <path d="M199.27 70.18H199.28" stroke="black" stroke-width="0.5" />
    <path d="M199.28 70.18H199.3" stroke="black" stroke-width="0.5" />
    <path d="M199.3 70.18H199.32" stroke="black" stroke-width="0.5" />
    <path d="M199.32 70.18L199.34 70.17" stroke="black" stroke-width="0.5" />
    <path d="M199.34 70.17H199.35" stroke="black" stroke-width="0.5" />
    <path d="M199.35 70.17H199.36" stroke="black" stroke-width="0.5" />
    <path d="M198.98 70.39L198.99 70.38" stroke="black" stroke-width="0.5" />
    <path d="M198.99 70.38L199 70.36" stroke="black" stroke-width="0.5" />
    <path d="M199 70.36L199.01 70.35" stroke="black" stroke-width="0.5" />
    <path d="M199.01 70.35L199.02 70.33" stroke="black" stroke-width="0.5" />
    <path d="M199.02 70.33L199.03 70.32" stroke="black" stroke-width="0.5" />
    <path d="M199.03 70.32L199.05 70.31" stroke="black" stroke-width="0.5" />
    <path d="M199.05 70.31L199.06 70.3" stroke="black" stroke-width="0.5" />
    <path d="M199.06 70.3L199.07 70.28" stroke="black" stroke-width="0.5" />
    <path d="M199.07 70.28L199.08 70.27" stroke="black" stroke-width="0.5" />
    <path d="M199.08 70.27L199.1 70.26" stroke="black" stroke-width="0.5" />
    <path d="M199.1 70.26L199.11 70.25" stroke="black" stroke-width="0.5" />
    <path d="M199.11 70.25L199.12 70.24" stroke="black" stroke-width="0.5" />
    <path d="M199.12 70.24L199.14 70.23" stroke="black" stroke-width="0.5" />
    <path d="M198.93 70.61V70.59" stroke="black" stroke-width="0.5" />
    <path d="M198.93 70.59V70.57" stroke="black" stroke-width="0.5" />
    <path d="M198.93 70.57V70.55" stroke="black" stroke-width="0.5" />
    <path d="M198.93 70.55V70.54" stroke="black" stroke-width="0.5" />
    <path d="M198.93 70.54V70.52" stroke="black" stroke-width="0.5" />
    <path d="M198.93 70.52L198.94 70.5" stroke="black" stroke-width="0.5" />
    <path d="M198.94 70.5V70.49" stroke="black" stroke-width="0.5" />
    <path d="M198.94 70.49L198.95 70.47" stroke="black" stroke-width="0.5" />
    <path d="M198.95 70.47V70.45" stroke="black" stroke-width="0.5" />
    <path d="M198.95 70.45L198.96 70.44" stroke="black" stroke-width="0.5" />
    <path d="M198.96 70.44L198.97 70.42" stroke="black" stroke-width="0.5" />
    <path d="M198.97 70.42V70.41" stroke="black" stroke-width="0.5" />
    <path d="M198.97 70.41L198.98 70.39" stroke="black" stroke-width="0.5" />
    <path d="M199.29 70.54H199.28" stroke="black" stroke-width="0.5" />
    <path d="M199.28 70.54V70.55" stroke="black" stroke-width="0.5" />
    <path d="M199.28 70.55H199.27" stroke="black" stroke-width="0.5" />
    <path d="M199.27 70.55V70.56" stroke="black" stroke-width="0.5" />
    <path d="M199.27 70.56V70.57" stroke="black" stroke-width="0.5" />
    <path d="M199.27 70.57H199.26" stroke="black" stroke-width="0.5" />
    <path d="M199.26 70.57V70.58" stroke="black" stroke-width="0.5" />
    <path d="M199.26 70.58V70.59" stroke="black" stroke-width="0.5" />
    <path d="M199.26 70.59V70.6" stroke="black" stroke-width="0.5" />
    <path d="M199.26 70.6V70.61" stroke="black" stroke-width="0.5" />
    <path d="M199.36 70.51H199.35" stroke="black" stroke-width="0.5" />
    <path d="M199.35 70.51H199.34" stroke="black" stroke-width="0.5" />
    <path d="M199.34 70.51H199.33" stroke="black" stroke-width="0.5" />
    <path d="M199.33 70.51H199.32" stroke="black" stroke-width="0.5" />
    <path d="M199.32 70.51V70.52" stroke="black" stroke-width="0.5" />
    <path d="M199.32 70.52H199.31" stroke="black" stroke-width="0.5" />
    <path d="M199.31 70.52H199.3" stroke="black" stroke-width="0.5" />
    <path d="M199.3 70.52V70.53" stroke="black" stroke-width="0.5" />
    <path d="M199.3 70.53H199.29" stroke="black" stroke-width="0.5" />
    <path d="M199.29 70.53V70.54" stroke="black" stroke-width="0.5" />
    <path d="M209.33 69.58H209.53" stroke="black" stroke-width="0.5" />
    <path d="M209.53 69.24H209.33" stroke="black" stroke-width="0.5" />
    <path d="M208.9 57.57V69.14" stroke="black" stroke-width="0.5" />
    <path d="M209.23 69.14V57.57" stroke="black" stroke-width="0.5" />
    <path d="M209.53 57.14H209.33" stroke="black" stroke-width="0.5" />
    <path d="M209.33 57.47H209.53" stroke="black" stroke-width="0.5" />
    <path d="M208.4 70.81V71.01" stroke="black" stroke-width="0.5" />
    <path d="M208.93 71.01V70.81" stroke="black" stroke-width="0.5" />
    <path d="M208.5 70.61V70.81" stroke="black" stroke-width="0.5" />
    <path d="M208.83 70.81V70.61" stroke="black" stroke-width="0.5" />
    <path d="M199.36 70.51H208.4" stroke="black" stroke-width="0.5" />
    <path d="M208.4 70.17H199.36" stroke="black" stroke-width="0.5" />
    <path d="M199.26 70.81V70.61" stroke="black" stroke-width="0.5" />
    <path d="M198.93 70.61V70.81" stroke="black" stroke-width="0.5" />
    <path d="M209.53 69.68H209.73" stroke="black" stroke-width="0.5" />
    <path d="M209.73 69.14H209.53" stroke="black" stroke-width="0.5" />
    <path d="M209.53 57.57H209.73" stroke="black" stroke-width="0.5" />
    <path d="M209.73 57.04H209.53" stroke="black" stroke-width="0.5" />
    <path d="M198.83 70.81V71.01" stroke="black" stroke-width="0.5" />
    <path d="M199.36 71.01V70.81" stroke="black" stroke-width="0.5" />
    <path d="M205.47 70.02L205.44 70.18" stroke="black" stroke-width="0.5" />
    <path d="M205.07 70.51H204.81" stroke="black" stroke-width="0.5" />
    <path d="M204.44 70.18L204.41 69.99" stroke="black" stroke-width="0.5" />
    <path d="M204.76 70.18L204.68 70.03" stroke="black" stroke-width="0.5" />
    <path d="M204.68 70.03L204.71 69.86" stroke="black" stroke-width="0.5" />
    <path d="M204.71 69.86L204.84 69.75" stroke="black" stroke-width="0.5" />
    <path d="M204.84 69.75L205.01 69.74" stroke="black" stroke-width="0.5" />
    <path d="M205.01 69.74L205.15 69.83" stroke="black" stroke-width="0.5" />
    <path d="M205.15 69.83L205.21 69.99" stroke="black" stroke-width="0.5" />
    <path d="M205.21 69.99L205.13 70.18" stroke="black" stroke-width="0.5" />
    <path d="M203.11 67.45L202.95 66.4" stroke="black" stroke-width="0.5" />
    <path d="M202.95 66.4L203.11 65.35" stroke="black" stroke-width="0.5" />
    <path d="M203.11 65.35L203.53 64.52" stroke="black" stroke-width="0.5" />
    <path d="M203.53 64.52L204.21 63.93" stroke="black" stroke-width="0.5" />
    <path d="M204.21 63.93L204.94 63.74" stroke="black" stroke-width="0.5" />
    <path d="M204.94 63.74L205.68 63.93" stroke="black" stroke-width="0.5" />
    <path d="M205.68 63.93L206.35 64.52" stroke="black" stroke-width="0.5" />
    <path d="M206.35 64.52L206.78 65.35" stroke="black" stroke-width="0.5" />
    <path d="M206.78 65.35L206.94 66.4" stroke="black" stroke-width="0.5" />
    <path d="M206.94 66.4L206.78 67.45" stroke="black" stroke-width="0.5" />
    <path d="M206.78 67.45L206.55 67.98" stroke="black" stroke-width="0.5" />
    <path d="M206.55 67.98L206.22 68.45" stroke="black" stroke-width="0.5" />
    <path d="M205.48 69.99L205.69 69.14" stroke="black" stroke-width="0.5" />
    <path d="M205.69 69.14L206.22 68.45" stroke="black" stroke-width="0.5" />
    <path d="M205.48 69.99L205.44 70.18" stroke="black" stroke-width="0.5" />
    <path d="M205.08 70.51L204.99 70.52" stroke="black" stroke-width="0.5" />
    <path d="M204.99 70.52H204.9" stroke="black" stroke-width="0.5" />
    <path d="M204.9 70.52L204.81 70.51" stroke="black" stroke-width="0.5" />
    <path d="M204.44 70.18L204.41 69.99" stroke="black" stroke-width="0.5" />
    <path d="M203.67 68.45L204.2 69.14" stroke="black" stroke-width="0.5" />
    <path d="M204.2 69.14L204.41 69.99" stroke="black" stroke-width="0.5" />
    <path d="M203.67 68.45L203.33 67.98" stroke="black" stroke-width="0.5" />
    <path d="M203.33 67.98L203.11 67.45" stroke="black" stroke-width="0.5" />
    <path d="M205.47 70.02L205.63 69.31" stroke="black" stroke-width="0.5" />
    <path d="M205.63 69.31L206 68.67" stroke="black" stroke-width="0.5" />
    <path d="M203.77 68.56L204.22 69.22" stroke="black" stroke-width="0.5" />
    <path d="M204.22 69.22L204.41 69.99" stroke="black" stroke-width="0.5" />
    <path d="M206.78 67.45L206.47 68.11" stroke="black" stroke-width="0.5" />
    <path d="M206.47 68.11L206 68.67" stroke="black" stroke-width="0.5" />
    <path d="M206.94 66.4L206.78 67.45" stroke="black" stroke-width="0.5" />
    <path d="M206.78 65.35L206.94 66.4" stroke="black" stroke-width="0.5" />
    <path d="M206.35 64.52L206.78 65.35" stroke="black" stroke-width="0.5" />
    <path d="M205.68 63.93L206.35 64.52" stroke="black" stroke-width="0.5" />
    <path d="M204.94 63.74L205.68 63.93" stroke="black" stroke-width="0.5" />
    <path d="M204.21 63.93L204.94 63.74" stroke="black" stroke-width="0.5" />
    <path d="M203.53 64.52L204.21 63.93" stroke="black" stroke-width="0.5" />
    <path d="M203.11 65.35L203.53 64.52" stroke="black" stroke-width="0.5" />
    <path d="M202.95 66.4L203.11 65.35" stroke="black" stroke-width="0.5" />
    <path d="M203.11 67.45L202.95 66.4" stroke="black" stroke-width="0.5" />
    <path d="M203.77 68.56L203.37 68.05" stroke="black" stroke-width="0.5" />
    <path d="M203.37 68.05L203.11 67.45" stroke="black" stroke-width="0.5" />
    <path d="M1.04999 1.08997H199.5" stroke="black" stroke-width="0.5" />
    <path d="M46.3 2.94997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M68.11 2.94997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M1.04999 1.08997V116.55" stroke="black" stroke-width="0.5" />
    <path d="M3.69999 16.22H1.04999" stroke="black" stroke-width="0.5" />
    <path d="M3.69999 45.47H1.04999" stroke="black" stroke-width="0.5" />
    <path d="M3.69999 67.28H1.04999" stroke="black" stroke-width="0.5" />
    <path d="M5.67001 111.21H3.70001" stroke="black" stroke-width="0.5" />
    <path d="M47.96 23.54V21.53" stroke="black" stroke-width="0.5" />
    <path d="M47.96 21.53H49.34" stroke="black" stroke-width="0.5" />
    <path d="M49.34 21.53V28.38" stroke="black" stroke-width="0.5" />
    <path d="M49.34 28.38H47.96" stroke="black" stroke-width="0.5" />
    <path d="M47.96 28.38V26.37" stroke="black" stroke-width="0.5" />
    <path d="M47.96 26.37H48.3" stroke="black" stroke-width="0.5" />
    <path d="M48.3 26.37V26.14" stroke="black" stroke-width="0.5" />
    <path d="M48.3 26.14H47.96" stroke="black" stroke-width="0.5" />
    <path d="M47.96 26.14V23.77" stroke="black" stroke-width="0.5" />
    <path d="M47.96 23.77H48.3" stroke="black" stroke-width="0.5" />
    <path d="M48.3 23.77V23.54" stroke="black" stroke-width="0.5" />
    <path d="M48.3 23.54H47.96" stroke="black" stroke-width="0.5" />
    <path d="M9.72997 84.06L9.71997 83.98" stroke="black" stroke-width="0.5" />
    <path d="M9.71997 83.98V83.9" stroke="black" stroke-width="0.5" />
    <path d="M9.72002 83.9L9.71002 83.82" stroke="black" stroke-width="0.5" />
    <path d="M9.71 83.82L9.69 83.74" stroke="black" stroke-width="0.5" />
    <path d="M9.68999 83.74L9.67999 83.66" stroke="black" stroke-width="0.5" />
    <path d="M9.67997 83.66L9.65997 83.59" stroke="black" stroke-width="0.5" />
    <path d="M9.66 83.59L9.63 83.51" stroke="black" stroke-width="0.5" />
    <path d="M9.62998 83.51L9.59998 83.44" stroke="black" stroke-width="0.5" />
    <path d="M9.60003 83.44L9.59003 83.41" stroke="black" stroke-width="0.5" />
    <path d="M9.42002 83.12L9.40002 83.09" stroke="black" stroke-width="0.5" />
    <path d="M9.39998 83.09L9.34998 83.03" stroke="black" stroke-width="0.5" />
    <path d="M9.34999 83.03L9.29999 82.97" stroke="black" stroke-width="0.5" />
    <path d="M9.29999 82.97L9.23999 82.91" stroke="black" stroke-width="0.5" />
    <path d="M9.24001 82.91L9.19 82.86" stroke="black" stroke-width="0.5" />
    <path d="M9.19 82.86L9.13 82.81" stroke="black" stroke-width="0.5" />
    <path d="M9.13 82.81L9.06 82.76" stroke="black" stroke-width="0.5" />
    <path d="M9.06 82.76L9 82.72" stroke="black" stroke-width="0.5" />
    <path d="M8.99999 82.72L8.92999 82.67" stroke="black" stroke-width="0.5" />
    <path d="M8.62 82.54L8.56 82.52" stroke="black" stroke-width="0.5" />
    <path d="M8.55999 82.52L8.48999 82.5" stroke="black" stroke-width="0.5" />
    <path d="M8.48997 82.5L8.40997 82.48" stroke="black" stroke-width="0.5" />
    <path d="M8.41002 82.48L8.33002 82.47" stroke="black" stroke-width="0.5" />
    <path d="M8.33 82.47L8.25 82.46" stroke="black" stroke-width="0.5" />
    <path d="M8.24998 82.46H8.16998" stroke="black" stroke-width="0.5" />
    <path d="M8.17002 82.46H8.09003" stroke="black" stroke-width="0.5" />
    <path d="M8.09001 82.46H8.01001" stroke="black" stroke-width="0.5" />
    <path d="M8.01002 82.46L7.96002 82.47" stroke="black" stroke-width="0.5" />
    <path d="M7.64 82.54H7.62" stroke="black" stroke-width="0.5" />
    <path d="M7.61999 82.54L7.54999 82.57" stroke="black" stroke-width="0.5" />
    <path d="M7.54997 82.57L7.46997 82.6" stroke="black" stroke-width="0.5" />
    <path d="M7.47002 82.6L7.40002 82.64" stroke="black" stroke-width="0.5" />
    <path d="M7.40002 82.64L7.33002 82.67" stroke="black" stroke-width="0.5" />
    <path d="M7.33001 82.67L7.26001 82.72" stroke="black" stroke-width="0.5" />
    <path d="M7.26001 82.72L7.20001 82.76" stroke="black" stroke-width="0.5" />
    <path d="M7.20002 82.76L7.14001 82.81" stroke="black" stroke-width="0.5" />
    <path d="M7.14001 82.81L7.07001 82.86" stroke="black" stroke-width="0.5" />
    <path d="M7.07 82.86L7.06 82.87" stroke="black" stroke-width="0.5" />
    <path d="M6.84 83.12L6.81 83.16" stroke="black" stroke-width="0.5" />
    <path d="M6.81002 83.16L6.77002 83.22" stroke="black" stroke-width="0.5" />
    <path d="M6.76998 83.22L6.72998 83.29" stroke="black" stroke-width="0.5" />
    <path d="M6.73 83.29L6.69 83.36" stroke="black" stroke-width="0.5" />
    <path d="M6.68997 83.36L6.65997 83.44" stroke="black" stroke-width="0.5" />
    <path d="M6.66 83.44L6.63 83.51" stroke="black" stroke-width="0.5" />
    <path d="M6.62999 83.51L6.60999 83.59" stroke="black" stroke-width="0.5" />
    <path d="M6.61002 83.59L6.58002 83.66" stroke="black" stroke-width="0.5" />
    <path d="M6.58001 83.66L6.57001 83.72" stroke="black" stroke-width="0.5" />
    <path d="M6.53003 84.06L6.54003 84.14" stroke="black" stroke-width="0.5" />
    <path d="M6.53998 84.14V84.21" stroke="black" stroke-width="0.5" />
    <path d="M6.53998 84.21L6.54998 84.29" stroke="black" stroke-width="0.5" />
    <path d="M6.54999 84.29L6.56999 84.37" stroke="black" stroke-width="0.5" />
    <path d="M6.57001 84.37L6.58001 84.45" stroke="black" stroke-width="0.5" />
    <path d="M6.58002 84.45L6.61002 84.53" stroke="black" stroke-width="0.5" />
    <path d="M6.60999 84.53L6.62999 84.6" stroke="black" stroke-width="0.5" />
    <path d="M6.63 84.6L6.66 84.68" stroke="black" stroke-width="0.5" />
    <path d="M6.65997 84.68L6.66997 84.7" stroke="black" stroke-width="0.5" />
    <path d="M6.84003 84.99L6.86003 85.02" stroke="black" stroke-width="0.5" />
    <path d="M6.85999 85.02L6.90998 85.08" stroke="black" stroke-width="0.5" />
    <path d="M6.90997 85.08L6.95997 85.14" stroke="black" stroke-width="0.5" />
    <path d="M6.96002 85.14L7.02002 85.2" stroke="black" stroke-width="0.5" />
    <path d="M7.02002 85.2L7.07002 85.25" stroke="black" stroke-width="0.5" />
    <path d="M7.07001 85.25L7.14001 85.3" stroke="black" stroke-width="0.5" />
    <path d="M7.14001 85.3L7.20002 85.35" stroke="black" stroke-width="0.5" />
    <path d="M7.20001 85.35L7.26001 85.4" stroke="black" stroke-width="0.5" />
    <path d="M7.26001 85.4L7.33001 85.44" stroke="black" stroke-width="0.5" />
    <path d="M7.64001 85.57L7.70002 85.59" stroke="black" stroke-width="0.5" />
    <path d="M7.70001 85.59L7.78001 85.61" stroke="black" stroke-width="0.5" />
    <path d="M7.78003 85.61L7.85003 85.63" stroke="black" stroke-width="0.5" />
    <path d="M7.84998 85.63L7.92998 85.64" stroke="black" stroke-width="0.5" />
    <path d="M7.92999 85.64L8.00999 85.65" stroke="black" stroke-width="0.5" />
    <path d="M8.01001 85.65H8.09001" stroke="black" stroke-width="0.5" />
    <path d="M8.09003 85.65H8.17002" stroke="black" stroke-width="0.5" />
    <path d="M8.16998 85.65H8.24998" stroke="black" stroke-width="0.5" />
    <path d="M8.25 85.65L8.3 85.64" stroke="black" stroke-width="0.5" />
    <path d="M8.62 85.57H8.64" stroke="black" stroke-width="0.5" />
    <path d="M8.64001 85.57L8.71001 85.54" stroke="black" stroke-width="0.5" />
    <path d="M8.71002 85.54L8.79002 85.51" stroke="black" stroke-width="0.5" />
    <path d="M8.78998 85.51L8.85998 85.48" stroke="black" stroke-width="0.5" />
    <path d="M8.85999 85.48L8.92999 85.44" stroke="black" stroke-width="0.5" />
    <path d="M8.92999 85.44L8.99999 85.4" stroke="black" stroke-width="0.5" />
    <path d="M9 85.4L9.06 85.35" stroke="black" stroke-width="0.5" />
    <path d="M9.06 85.35L9.13 85.3" stroke="black" stroke-width="0.5" />
    <path d="M9.13 85.3L9.19 85.25" stroke="black" stroke-width="0.5" />
    <path d="M9.19 85.25L9.2 85.24" stroke="black" stroke-width="0.5" />
    <path d="M9.41998 84.99L9.44999 84.95" stroke="black" stroke-width="0.5" />
    <path d="M9.45001 84.95L9.49001 84.89" stroke="black" stroke-width="0.5" />
    <path d="M9.48999 84.89L9.52999 84.82" stroke="black" stroke-width="0.5" />
    <path d="M9.53003 84.82L9.57003 84.75" stroke="black" stroke-width="0.5" />
    <path d="M9.57001 84.75L9.60001 84.68" stroke="black" stroke-width="0.5" />
    <path d="M9.59998 84.68L9.62998 84.6" stroke="black" stroke-width="0.5" />
    <path d="M9.63 84.6L9.66 84.53" stroke="black" stroke-width="0.5" />
    <path d="M9.65997 84.53L9.67997 84.45" stroke="black" stroke-width="0.5" />
    <path d="M9.67999 84.45L9.68999 84.39" stroke="black" stroke-width="0.5" />
    <path d="M96.48 90.49H99.27" stroke="black" stroke-width="0.5" />
    <path d="M99.27 90.49V91.42" stroke="black" stroke-width="0.5" />
    <path d="M99.27 91.42H96.48" stroke="black" stroke-width="0.5" />
    <path d="M96.48 91.42V90.49" stroke="black" stroke-width="0.5" />
    <path d="M108.07 23.54V21.53" stroke="black" stroke-width="0.5" />
    <path d="M108.07 21.53H109.45" stroke="black" stroke-width="0.5" />
    <path d="M109.45 21.53V28.38" stroke="black" stroke-width="0.5" />
    <path d="M109.45 28.38H108.07" stroke="black" stroke-width="0.5" />
    <path d="M108.07 28.38V26.37" stroke="black" stroke-width="0.5" />
    <path d="M108.07 26.37H108.4" stroke="black" stroke-width="0.5" />
    <path d="M108.4 26.37V26.14" stroke="black" stroke-width="0.5" />
    <path d="M108.4 26.14H108.07" stroke="black" stroke-width="0.5" />
    <path d="M108.07 26.14V23.77" stroke="black" stroke-width="0.5" />
    <path d="M108.07 23.77H108.4" stroke="black" stroke-width="0.5" />
    <path d="M108.4 23.77V23.54" stroke="black" stroke-width="0.5" />
    <path d="M108.4 23.54H108.07" stroke="black" stroke-width="0.5" />
    <path d="M78.01 23.54V21.53" stroke="black" stroke-width="0.5" />
    <path d="M78.01 21.53H79.4" stroke="black" stroke-width="0.5" />
    <path d="M79.4 21.53V28.38" stroke="black" stroke-width="0.5" />
    <path d="M79.4 28.38H78.01" stroke="black" stroke-width="0.5" />
    <path d="M78.01 28.38V26.37" stroke="black" stroke-width="0.5" />
    <path d="M78.01 26.37H78.35" stroke="black" stroke-width="0.5" />
    <path d="M78.35 26.37V26.14" stroke="black" stroke-width="0.5" />
    <path d="M78.35 26.14H78.01" stroke="black" stroke-width="0.5" />
    <path d="M78.01 26.14V23.77" stroke="black" stroke-width="0.5" />
    <path d="M78.01 23.77H78.35" stroke="black" stroke-width="0.5" />
    <path d="M78.35 23.77V23.54" stroke="black" stroke-width="0.5" />
    <path d="M78.35 23.54H78.01" stroke="black" stroke-width="0.5" />
    <path d="M107.17 106.73V110.94" stroke="black" stroke-width="0.5" />
    <path d="M157.17 89.82H147.6" stroke="black" stroke-width="0.5" />
    <path d="M147.6 89.82V90.49" stroke="black" stroke-width="0.5" />
    <path d="M147.6 90.49H157.17" stroke="black" stroke-width="0.5" />
    <path d="M157.17 90.49V89.82" stroke="black" stroke-width="0.5" />
    <path d="M105.84 97.66H107.17" stroke="black" stroke-width="0.5" />
    <path d="M138.02 89.16H147.6" stroke="black" stroke-width="0.5" />
    <path d="M147.6 89.16V89.82" stroke="black" stroke-width="0.5" />
    <path d="M147.6 89.82H138.02" stroke="black" stroke-width="0.5" />
    <path d="M138.02 89.82V89.16" stroke="black" stroke-width="0.5" />
    <path d="M138.03 87.19H141.67" stroke="black" stroke-width="0.5" />
    <path d="M141.67 87.19V89.16" stroke="black" stroke-width="0.5" />
    <path d="M141.67 89.16H138.03" stroke="black" stroke-width="0.5" />
    <path d="M138.03 89.16V87.19" stroke="black" stroke-width="0.5" />
    <path d="M147.6 90.49V89.16" stroke="black" stroke-width="0.5" />
    <path d="M138.03 79.86H141.67" stroke="black" stroke-width="0.5" />
    <path d="M141.67 79.86V77.89" stroke="black" stroke-width="0.5" />
    <path d="M141.67 77.89H138.03" stroke="black" stroke-width="0.5" />
    <path d="M138.03 77.89V79.86" stroke="black" stroke-width="0.5" />
    <path d="M91.88 112.89H97.39" stroke="black" stroke-width="0.5" />
    <path d="M97.39 112.89V111.35" stroke="black" stroke-width="0.5" />
    <path d="M97.39 111.35H91.88" stroke="black" stroke-width="0.5" />
    <path d="M91.88 111.35V112.89" stroke="black" stroke-width="0.5" />
    <path d="M140.45 45.49H145.68" stroke="black" stroke-width="0.5" />
    <path d="M145.68 45.49V46.44" stroke="black" stroke-width="0.5" />
    <path d="M145.68 46.44H140.45" stroke="black" stroke-width="0.5" />
    <path d="M140.45 46.44V45.49" stroke="black" stroke-width="0.5" />
    <path d="M199.49 3.73999V1.17999" stroke="black" stroke-width="0.5" />
    <path d="M203.85 3.73999V7.46999" stroke="black" stroke-width="0.5" />
    <path d="M199.49 3.73999H203.85" stroke="black" stroke-width="0.5" />
    <path d="M98.52 6.66998H107.43" stroke="black" stroke-width="0.5" />
    <path d="M107.43 6.66999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M107.43 3.73999H98.52" stroke="black" stroke-width="0.5" />
    <path d="M98.52 3.73999V6.66999" stroke="black" stroke-width="0.5" />
    <path d="M5.56997 82.48H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M3.70001 96.54V67.28" stroke="black" stroke-width="0.5" />
    <path d="M199.49 5.87999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M170.24 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M148.43 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M119.17 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M97.36 3.73997V1.08997" stroke="black" stroke-width="0.5" />
    <path d="M148.43 3.73999H170.24" stroke="black" stroke-width="0.5" />
    <path d="M97.36 3.73999H119.17" stroke="black" stroke-width="0.5" />
    <path d="M46.3 3.73999H68.11" stroke="black" stroke-width="0.5" />
    <path d="M17.04 3.73999H3.70001" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 6.66998H15.66" stroke="black" stroke-width="0.5" />
    <path d="M15.66 6.66999V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M15.66 3.73999H5.84003" stroke="black" stroke-width="0.5" />
    <path d="M5.84003 3.73999V6.66999" stroke="black" stroke-width="0.5" />
    <path d="M10.32 5.28L10.13 5.73" stroke="black" stroke-width="0.5" />
    <path d="M10.13 5.73001L9.69 5.91001" stroke="black" stroke-width="0.5" />
    <path
      d="M9.68999 5.91001L9.23999 5.73001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M9.24 5.73L9.06 5.28" stroke="black" stroke-width="0.5" />
    <path d="M9.06 5.27997L9.24 4.83997" stroke="black" stroke-width="0.5" />
    <path
      d="M9.23999 4.84003L9.68999 4.66003"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M9.69 4.66003L10.13 4.84003" stroke="black" stroke-width="0.5" />
    <path d="M10.13 4.83997L10.32 5.27997" stroke="black" stroke-width="0.5" />
    <path d="M12.63 5.28L12.45 5.73" stroke="black" stroke-width="0.5" />
    <path d="M12.45 5.73001L12 5.91001" stroke="black" stroke-width="0.5" />
    <path d="M12 5.91001L11.56 5.73001" stroke="black" stroke-width="0.5" />
    <path d="M11.56 5.73L11.38 5.28" stroke="black" stroke-width="0.5" />
    <path d="M11.38 5.27997L11.56 4.83997" stroke="black" stroke-width="0.5" />
    <path d="M11.56 4.84003L12 4.66003" stroke="black" stroke-width="0.5" />
    <path d="M12 4.66003L12.45 4.84003" stroke="black" stroke-width="0.5" />
    <path d="M12.45 4.83997L12.63 5.27997" stroke="black" stroke-width="0.5" />
    <path d="M100.87 5.28L100.69 5.73" stroke="black" stroke-width="0.5" />
    <path
      d="M100.69 5.73001L100.25 5.91001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M100.25 5.91001L99.8 5.73001" stroke="black" stroke-width="0.5" />
    <path d="M99.8 5.73L99.62 5.28" stroke="black" stroke-width="0.5" />
    <path d="M99.62 5.27997L99.8 4.83997" stroke="black" stroke-width="0.5" />
    <path d="M99.8 4.84003L100.25 4.66003" stroke="black" stroke-width="0.5" />
    <path
      d="M100.25 4.66003L100.69 4.84003"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M100.69 4.83997L100.87 5.27997"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M103.19 5.28L103.01 5.73" stroke="black" stroke-width="0.5" />
    <path
      d="M103.01 5.73001L102.56 5.91001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.56 5.91001L102.12 5.73001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M102.12 5.73L101.94 5.28" stroke="black" stroke-width="0.5" />
    <path
      d="M101.94 5.27997L102.12 4.83997"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.12 4.84003L102.56 4.66003"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M102.56 4.66003L103.01 4.84003"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M103.01 4.83997L103.19 5.27997"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M97.36 5.88V8.05001" stroke="black" stroke-width="0.5" />
    <path d="M97.36 8.04999H108.76" stroke="black" stroke-width="0.5" />
    <path d="M203.85 10.09H209.73" stroke="black" stroke-width="0.5" />
    <path d="M209.73 10.09V7.47" stroke="black" stroke-width="0.5" />
    <path d="M209.73 7.47H203.85" stroke="black" stroke-width="0.5" />
    <path d="M203.85 7.47V10.09" stroke="black" stroke-width="0.5" />
    <path d="M205.9 8.44L205.72 8.89" stroke="black" stroke-width="0.5" />
    <path
      d="M205.72 8.89001L205.27 9.07001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M205.27 9.07001L204.83 8.89001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M204.83 8.89L204.65 8.44" stroke="black" stroke-width="0.5" />
    <path d="M204.65 8.44L204.83 8" stroke="black" stroke-width="0.5" />
    <path d="M204.83 8L205.27 7.81" stroke="black" stroke-width="0.5" />
    <path d="M205.27 7.81L205.72 8" stroke="black" stroke-width="0.5" />
    <path d="M205.72 8L205.9 8.44" stroke="black" stroke-width="0.5" />
    <path d="M208.22 8.44L208.04 8.89" stroke="black" stroke-width="0.5" />
    <path
      d="M208.04 8.89001L207.59 9.07001"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M207.59 9.07001L207.15 8.89001"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M207.15 8.89L206.96 8.44" stroke="black" stroke-width="0.5" />
    <path d="M206.96 8.44L207.15 8" stroke="black" stroke-width="0.5" />
    <path d="M207.15 8L207.59 7.81" stroke="black" stroke-width="0.5" />
    <path d="M207.59 7.81L208.04 8" stroke="black" stroke-width="0.5" />
    <path d="M208.04 8L208.22 8.44" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 91.15H3.69998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 67.28V96.54" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 91.68H3.69998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 96.54H3.90998" stroke="black" stroke-width="0.5" />
    <path d="M2.84998 96.01H3.69998" stroke="black" stroke-width="0.5" />
    <path d="M3.70001 16.22V3.73999" stroke="black" stroke-width="0.5" />
    <path d="M5.56998 67.28H2.84998" stroke="black" stroke-width="0.5" />
    <path d="M143.64 5.66998V7.00999" stroke="black" stroke-width="0.5" />
    <path d="M143.64 7.01001H138.81" stroke="black" stroke-width="0.5" />
  </svg>
</template>
