<template>
  <svg
    width="373"
    height="193"
    viewBox="0 0 373 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.75 3.42999H34.07" stroke="black" stroke-width="0.5" />
    <path d="M52.75 3.42999H271.63" stroke="black" stroke-width="0.5" />
    <path d="M271.63 3.42999V27.63" stroke="black" stroke-width="0.5" />
    <path d="M271.63 27.63H320.99" stroke="black" stroke-width="0.5" />
    <path d="M320.99 27.63V91.73" stroke="black" stroke-width="0.5" />
    <path d="M320.99 91.73H356.256" stroke="black" stroke-width="0.5" />
    <path d="M351.76 186.01V183" stroke="black" stroke-width="0.5" />
    <path d="M351.76 183H344.67" stroke="black" stroke-width="0.5" />
    <path
      d="M332.9 179.3C336.749 180.758 340.679 181.993 344.67 183"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M332.9 179.3L332.76 179.64" stroke="black" stroke-width="0.5" />
    <path d="M332.76 179.64L322.12 174.94" stroke="black" stroke-width="0.5" />
    <path d="M322.12 174.94L322.26 174.61" stroke="black" stroke-width="0.5" />
    <path d="M318.48 161.71H302.62" stroke="black" stroke-width="0.5" />
    <path d="M302.62 161.71V163.85" stroke="black" stroke-width="0.5" />
    <path d="M302.62 163.85H273.37" stroke="black" stroke-width="0.5" />
    <path d="M251.56 161.71V163.85" stroke="black" stroke-width="0.5" />
    <path d="M222.3 163.85V161.71" stroke="black" stroke-width="0.5" />
    <path d="M222.3 161.71H203.25" stroke="black" stroke-width="0.5" />
    <path d="M171.24 167.04V164.9" stroke="black" stroke-width="0.5" />
    <path d="M149.43 164.9V167.04" stroke="black" stroke-width="0.5" />
    <path d="M149.43 167.04H120.17" stroke="black" stroke-width="0.5" />
    <path d="M6.84001 139.27H4.70001" stroke="black" stroke-width="0.5" />
    <path d="M273.37 163.85V161.71" stroke="black" stroke-width="0.5" />
    <path d="M322.26 174.61L318.48 172.59" stroke="black" stroke-width="0.5" />
    <path d="M318.48 172.59V161.71" stroke="black" stroke-width="0.5" />
    <path d="M251.56 163.85H222.3" stroke="black" stroke-width="0.5" />
    <path d="M18.04 167.03H4.71002" stroke="black" stroke-width="0.5" />
    <path d="M47.3 164.9V167.04" stroke="black" stroke-width="0.5" />
    <path d="M203.25 161.71V164.9" stroke="black" stroke-width="0.5" />
    <path d="M171.24 164.9H149.43" stroke="black" stroke-width="0.5" />
    <path d="M273.37 161.71H251.56" stroke="black" stroke-width="0.5" />
    <path d="M203.25 164.9H200.49" stroke="black" stroke-width="0.5" />
    <path d="M98.36 164.9V167.04" stroke="black" stroke-width="0.5" />
    <path d="M98.36 167.04H69.11" stroke="black" stroke-width="0.5" />
    <path d="M120.17 167.03H98.36" stroke="black" stroke-width="0.5" />
    <path d="M4.71002 110.02V72.44" stroke="black" stroke-width="0.5" />
    <path d="M200.49 167.04H171.24" stroke="black" stroke-width="0.5" />
    <path d="M316.88 163.84H302.62" stroke="black" stroke-width="0.5" />
    <path d="M4.70001 139.27V110.02" stroke="black" stroke-width="0.5" />
    <path d="M200.49 164.9V167.04" stroke="black" stroke-width="0.5" />
    <path d="M273.37 163.84H251.56" stroke="black" stroke-width="0.5" />
    <path d="M222.3 163.84H204.75" stroke="black" stroke-width="0.5" />
    <path d="M18.04 164.9H6.84003" stroke="black" stroke-width="0.5" />
    <path d="M120.17 167.04V164.9" stroke="black" stroke-width="0.5" />
    <path d="M47.3 167.04H18.04" stroke="black" stroke-width="0.5" />
    <path d="M204.75 167.03H200.49" stroke="black" stroke-width="0.5" />
    <path d="M69.11 167.04V164.9" stroke="black" stroke-width="0.5" />
    <path d="M120.17 164.9H98.36" stroke="black" stroke-width="0.5" />
    <path d="M171.24 167.03H149.43" stroke="black" stroke-width="0.5" />
    <path d="M5.08002 110.02H6.84002" stroke="black" stroke-width="0.5" />
    <path d="M69.11 164.9H47.3" stroke="black" stroke-width="0.5" />
    <path d="M3.75 8.47998H34.07" stroke="black" stroke-width="0.5" />
    <path d="M249.56 36.94H250.62" stroke="black" stroke-width="0.5" />
    <path d="M251.95 36.94H253.02" stroke="black" stroke-width="0.5" />
    <path d="M249.56 38.27H253.02" stroke="black" stroke-width="0.5" />
    <path d="M18.04 167.04V164.9" stroke="black" stroke-width="0.5" />
    <path d="M6.83998 72.44H6.66998" stroke="black" stroke-width="0.5" />
    <path d="M6.66998 72.44V66.93" stroke="black" stroke-width="0.5" />
    <path d="M4.70001 110.02H5.08001" stroke="black" stroke-width="0.5" />
    <path d="M4.71002 167.03V139.27" stroke="black" stroke-width="0.5" />
    <path d="M69.11 167.03H47.3" stroke="black" stroke-width="0.5" />
    <path d="M6.84003 110.02V72.44" stroke="black" stroke-width="0.5" />
    <path d="M6.84003 164.9V139.27" stroke="black" stroke-width="0.5" />
    <path d="M3.75 66.93V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M8.53003 55.73V8.47998" stroke="black" stroke-width="0.5" />
    <path d="M171.24 164.9V169.69" stroke="black" stroke-width="0.5" />
    <path d="M171.24 169.69H200.49" stroke="black" stroke-width="0.5" />
    <path d="M200.49 169.69V164.9" stroke="black" stroke-width="0.5" />
    <path d="M200.49 164.9H171.24" stroke="black" stroke-width="0.5" />
    <path d="M171.24 167.04H200.49" stroke="black" stroke-width="0.5" />
    <path d="M171.24 166.77H200.49" stroke="black" stroke-width="0.5" />
    <path d="M171.24 167.84H200.49" stroke="black" stroke-width="0.5" />
    <path d="M171.77 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M175.76 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M176.29 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M195.97 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M195.44 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M199.96 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M186.02 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M185.49 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M273.37 161.71V166.5" stroke="black" stroke-width="0.5" />
    <path d="M273.37 166.5H302.62" stroke="black" stroke-width="0.5" />
    <path d="M302.62 166.5V161.71" stroke="black" stroke-width="0.5" />
    <path d="M302.62 161.71H273.37" stroke="black" stroke-width="0.5" />
    <path d="M273.37 163.85H302.62" stroke="black" stroke-width="0.5" />
    <path d="M273.37 163.58H302.62" stroke="black" stroke-width="0.5" />
    <path d="M273.37 164.64H302.62" stroke="black" stroke-width="0.5" />
    <path d="M273.9 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M277.89 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M278.42 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M298.1 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M297.57 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M302.09 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M288.15 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M287.62 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M6.67 66.93H3.75" stroke="black" stroke-width="0.5" />
    <path d="M69.11 164.9V169.69" stroke="black" stroke-width="0.5" />
    <path d="M69.11 169.69H98.36" stroke="black" stroke-width="0.5" />
    <path d="M98.36 169.69V164.9" stroke="black" stroke-width="0.5" />
    <path d="M98.36 164.9H69.11" stroke="black" stroke-width="0.5" />
    <path d="M69.11 167.04H98.36" stroke="black" stroke-width="0.5" />
    <path d="M69.11 166.77H98.36" stroke="black" stroke-width="0.5" />
    <path d="M69.11 167.84H98.36" stroke="black" stroke-width="0.5" />
    <path d="M69.64 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M73.63 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M74.16 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M93.84 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M93.31 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M97.83 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M83.89 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M83.36 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M222.3 161.71V166.5" stroke="black" stroke-width="0.5" />
    <path d="M222.3 166.5H251.56" stroke="black" stroke-width="0.5" />
    <path d="M251.56 166.5V161.71" stroke="black" stroke-width="0.5" />
    <path d="M251.56 161.71H222.3" stroke="black" stroke-width="0.5" />
    <path d="M222.3 163.85H251.56" stroke="black" stroke-width="0.5" />
    <path d="M222.3 163.58H251.56" stroke="black" stroke-width="0.5" />
    <path d="M222.3 164.64H251.56" stroke="black" stroke-width="0.5" />
    <path d="M222.83 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M226.82 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M227.35 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M247.04 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M246.5 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M251.02 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M237.08 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M236.55 163.85V164.64" stroke="black" stroke-width="0.5" />
    <path d="M18.04 164.9V169.69" stroke="black" stroke-width="0.5" />
    <path d="M18.04 169.69H47.3" stroke="black" stroke-width="0.5" />
    <path d="M47.3 169.69V164.9" stroke="black" stroke-width="0.5" />
    <path d="M47.3 164.9H18.04" stroke="black" stroke-width="0.5" />
    <path d="M18.04 167.04H47.3" stroke="black" stroke-width="0.5" />
    <path d="M18.04 166.77H47.3" stroke="black" stroke-width="0.5" />
    <path d="M18.04 167.84H47.3" stroke="black" stroke-width="0.5" />
    <path d="M18.57 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M22.56 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M23.1 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M42.78 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M42.24 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M46.77 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M32.83 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M32.29 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M6.83999 110.02H2.04999" stroke="black" stroke-width="0.5" />
    <path d="M2.04999 110.02V139.27" stroke="black" stroke-width="0.5" />
    <path d="M2.04999 139.27H6.83999" stroke="black" stroke-width="0.5" />
    <path d="M6.84003 139.27V110.02" stroke="black" stroke-width="0.5" />
    <path d="M4.70001 110.02V139.27" stroke="black" stroke-width="0.5" />
    <path d="M4.96997 110.02V139.27" stroke="black" stroke-width="0.5" />
    <path d="M3.90997 110.02V139.27" stroke="black" stroke-width="0.5" />
    <path d="M4.69997 110.55H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M4.69997 114.54H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M4.69997 115.07H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M4.69997 134.75H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M4.69997 134.22H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M4.69997 138.74H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M4.69997 124.8H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M4.69997 124.27H3.90997" stroke="black" stroke-width="0.5" />
    <path d="M352.29 186.01V184.95" stroke="black" stroke-width="0.5" />
    <path d="M351.76 184.95H373" stroke="black" stroke-width="0.5" />
    <path d="M351.76 186.01H373" stroke="black" stroke-width="0.5" />
    <path d="M360.8 186.01V184.95" stroke="black" stroke-width="0.5" />
    <path d="M361.33 186.01V184.95" stroke="black" stroke-width="0.5" />
    <path d="M351.76 184.95V186.01" stroke="black" stroke-width="0.5" />
    <path d="M316.88 163.84V172.73" stroke="black" stroke-width="0.5" />
    <path d="M309.44 171.16L321.08 177.39" stroke="black" stroke-width="0.5" />
    <path d="M310.77 169.46L321.92 175.42" stroke="black" stroke-width="0.5" />
    <path d="M321.6 176.16L332.22 180.85" stroke="black" stroke-width="0.5" />
    <path d="M321.08 177.39L331.68 182.07" stroke="black" stroke-width="0.5" />
    <path
      d="M332.36 180.52C336.329 182.026 340.383 183.298 344.5 184.33"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M339.46 190.98H351.76" stroke="black" stroke-width="0.5" />
    <path d="M341.05 189.38H350.16" stroke="black" stroke-width="0.5" />
    <path d="M344.5 184.33H350.16" stroke="black" stroke-width="0.5" />
    <path d="M350.16 184.33V189.38" stroke="black" stroke-width="0.5" />
    <path d="M310.77 166.5V169.46" stroke="black" stroke-width="0.5" />
    <path d="M309.44 166.5V171.16" stroke="black" stroke-width="0.5" />
    <path d="M322.12 174.94L321.08 177.39" stroke="black" stroke-width="0.5" />
    <path d="M331.68 182.07L332.75 179.64" stroke="black" stroke-width="0.5" />
    <path d="M339.46 184.68V190.98" stroke="black" stroke-width="0.5" />
    <path d="M341.05 183.4V189.38" stroke="black" stroke-width="0.5" />
    <path d="M351.76 186.01V190.98" stroke="black" stroke-width="0.5" />
    <path d="M343.41 186.79L343.23 187.36" stroke="black" stroke-width="0.5" />
    <path d="M343.23 187.36L342.74 187.71" stroke="black" stroke-width="0.5" />
    <path d="M342.74 187.71H342.14" stroke="black" stroke-width="0.5" />
    <path d="M342.14 187.71L341.66 187.36" stroke="black" stroke-width="0.5" />
    <path d="M341.66 187.36L341.47 186.79" stroke="black" stroke-width="0.5" />
    <path d="M341.47 186.79L341.66 186.22" stroke="black" stroke-width="0.5" />
    <path d="M341.66 186.22L342.14 185.87" stroke="black" stroke-width="0.5" />
    <path d="M342.14 185.87H342.74" stroke="black" stroke-width="0.5" />
    <path d="M342.74 185.87L343.23 186.22" stroke="black" stroke-width="0.5" />
    <path d="M343.23 186.22L343.41 186.79" stroke="black" stroke-width="0.5" />
    <path d="M346.15 186.79L345.96 187.36" stroke="black" stroke-width="0.5" />
    <path d="M345.96 187.36L345.48 187.71" stroke="black" stroke-width="0.5" />
    <path d="M345.48 187.71H344.88" stroke="black" stroke-width="0.5" />
    <path d="M344.88 187.71L344.39 187.36" stroke="black" stroke-width="0.5" />
    <path d="M344.39 187.36L344.21 186.79" stroke="black" stroke-width="0.5" />
    <path d="M344.21 186.79L344.39 186.22" stroke="black" stroke-width="0.5" />
    <path d="M344.39 186.22L344.88 185.87" stroke="black" stroke-width="0.5" />
    <path d="M344.88 185.87H345.48" stroke="black" stroke-width="0.5" />
    <path d="M345.48 185.87L345.96 186.22" stroke="black" stroke-width="0.5" />
    <path d="M345.96 186.22L346.15 186.79" stroke="black" stroke-width="0.5" />
    <path
      d="M331.68 182.07C334.24 183.037 336.835 183.908 339.46 184.68"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M337.43 181.27H335.66" stroke="black" stroke-width="0.5" />
    <path d="M335.66 181.27V182.9" stroke="black" stroke-width="0.5" />
    <path d="M335.66 182.9H337.43" stroke="black" stroke-width="0.5" />
    <path d="M337.43 182.9V181.27" stroke="black" stroke-width="0.5" />
    <path d="M351.76 190.98V191.98" stroke="black" stroke-width="0.5" />
    <path d="M351.76 191.98H348.47" stroke="black" stroke-width="0.5" />
    <path d="M348.47 191.98V190.98" stroke="black" stroke-width="0.5" />
    <path d="M204.75 163.84V167.03" stroke="black" stroke-width="0.5" />
    <path d="M6.67002 72.44H4.71002" stroke="black" stroke-width="0.5" />
    <path d="M316.88 166.5H216.45" stroke="black" stroke-width="0.5" />
    <path d="M216.45 166.5V169.69" stroke="black" stroke-width="0.5" />
    <path d="M216.45 169.69H200.49" stroke="black" stroke-width="0.5" />
    <path d="M200.49 169.69H1.97998" stroke="black" stroke-width="0.5" />
    <path d="M1.97998 169.69V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M1.97998 2.10001H3.74998" stroke="black" stroke-width="0.5" />
    <path d="M3.75 2.09999V1.03998" stroke="black" stroke-width="0.5" />
    <path d="M3.75 1.03998H10.13" stroke="black" stroke-width="0.5" />
    <path d="M10.13 1.03998V2.09999" stroke="black" stroke-width="0.5" />
    <path d="M10.13 2.10001H22.43" stroke="black" stroke-width="0.5" />
    <path d="M27.62 2.10001H35.4" stroke="black" stroke-width="0.5" />
    <path d="M51.42 2.10001H105.88" stroke="black" stroke-width="0.5" />
    <path d="M105.88 2.09999V1.03998" stroke="black" stroke-width="0.5" />
    <path d="M105.88 1.03998H112.26" stroke="black" stroke-width="0.5" />
    <path d="M112.26 1.03998V2.09999" stroke="black" stroke-width="0.5" />
    <path d="M112.26 2.10001H208.01" stroke="black" stroke-width="0.5" />
    <path d="M208.01 2.09999V1.03998" stroke="black" stroke-width="0.5" />
    <path d="M208.01 1.03998H214.39" stroke="black" stroke-width="0.5" />
    <path d="M214.39 1.03998V2.09999" stroke="black" stroke-width="0.5" />
    <path d="M214.39 2.10001H222.92" stroke="black" stroke-width="0.5" />
    <path d="M228.11 2.10001H271.689" stroke="black" stroke-width="0.5" />
    <path d="M23.16 111.18H6.84003" stroke="black" stroke-width="0.5" />
    <path d="M320.99 91.73H328.44" stroke="black" stroke-width="0.5" />
    <path d="M320.99 82.15V91.73" stroke="black" stroke-width="0.5" />
    <path d="M266.47 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M256.36 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M318.48 161.71V129.8" stroke="black" stroke-width="0.5" />
    <path d="M319.81 173.3V143.97" stroke="black" stroke-width="0.5" />
    <path d="M319.81 141.64V134.95" stroke="black" stroke-width="0.5" />
    <path d="M318.48 129.8H302.26" stroke="black" stroke-width="0.5" />
    <path d="M319.81 128.47H302.26" stroke="black" stroke-width="0.5" />
    <path d="M290.29 160.38V129.8" stroke="black" stroke-width="0.5" />
    <path d="M289.76 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M289.22 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M291.62 161.71V129.8" stroke="black" stroke-width="0.5" />
    <path d="M292.15 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M292.68 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M302.26 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M292.68 128.47H289.22" stroke="black" stroke-width="0.5" />
    <path d="M292.68 129.8H291.62" stroke="black" stroke-width="0.5" />
    <path d="M290.29 129.8H289.22" stroke="black" stroke-width="0.5" />
    <path d="M263.16 161.71V129.8" stroke="black" stroke-width="0.5" />
    <path d="M261.83 161.71V129.8" stroke="black" stroke-width="0.5" />
    <path d="M263.16 129.8H279.65" stroke="black" stroke-width="0.5" />
    <path d="M279.65 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M234.7 160.38V129.8" stroke="black" stroke-width="0.5" />
    <path d="M233.37 161.71V129.8" stroke="black" stroke-width="0.5" />
    <path d="M235.23 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M235.77 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M245.34 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M245.87 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M235.77 128.47H232.04" stroke="black" stroke-width="0.5" />
    <path d="M235.77 129.8H234.7" stroke="black" stroke-width="0.5" />
    <path d="M261.83 129.8H245.34" stroke="black" stroke-width="0.5" />
    <path d="M245.34 128.47H249.24" stroke="black" stroke-width="0.5" />
    <path d="M254.43 128.47H279.65" stroke="black" stroke-width="0.5" />
    <path d="M233.37 129.8H216.88" stroke="black" stroke-width="0.5" />
    <path d="M234.44 128.47H216.88" stroke="black" stroke-width="0.5" />
    <path d="M204.91 161.71V129.8" stroke="black" stroke-width="0.5" />
    <path d="M204.12 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M203.58 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M206.24 161.71V129.8" stroke="black" stroke-width="0.5" />
    <path d="M206.78 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M207.31 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M216.88 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M217.41 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M207.31 128.47H203.58" stroke="black" stroke-width="0.5" />
    <path d="M207.31 129.8H206.24" stroke="black" stroke-width="0.5" />
    <path d="M204.91 129.8H203.58" stroke="black" stroke-width="0.5" />
    <path d="M176.99 166.77V129.8" stroke="black" stroke-width="0.5" />
    <path d="M175.66 166.77V129.8" stroke="black" stroke-width="0.5" />
    <path d="M176.99 129.8H194.01" stroke="black" stroke-width="0.5" />
    <path d="M194.01 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M174.33 128.47H194.01" stroke="black" stroke-width="0.5" />
    <path d="M175.66 129.8H174.33" stroke="black" stroke-width="0.5" />
    <path d="M174.33 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M164.75 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M164.22 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M174.86 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M164.75 128.47H143.74" stroke="black" stroke-width="0.5" />
    <path d="M164.75 129.8H156.24" stroke="black" stroke-width="0.5" />
    <path d="M154.91 129.8H143.74" stroke="black" stroke-width="0.5" />
    <path d="M156.24 164.9V129.8" stroke="black" stroke-width="0.5" />
    <path d="M154.91 164.9V129.8" stroke="black" stroke-width="0.5" />
    <path d="M143.74 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M35.3 164.9V129.8" stroke="black" stroke-width="0.5" />
    <path d="M33.97 163.57V129.8" stroke="black" stroke-width="0.5" />
    <path d="M33.17 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M32.64 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M23.06 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M22.53 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M51.76 128.47H32.64" stroke="black" stroke-width="0.5" />
    <path d="M23.06 128.47H4.96997" stroke="black" stroke-width="0.5" />
    <path d="M33.97 129.8H32.64" stroke="black" stroke-width="0.5" />
    <path d="M23.06 129.8H4.96997" stroke="black" stroke-width="0.5" />
    <path d="M64.02 164.9V129.8" stroke="black" stroke-width="0.5" />
    <path d="M62.69 164.9V129.8" stroke="black" stroke-width="0.5" />
    <path d="M35.3 129.8H51.79" stroke="black" stroke-width="0.5" />
    <path d="M61.36 129.8H62.69" stroke="black" stroke-width="0.5" />
    <path d="M61.89 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M61.36 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M51.79 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M51.25 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M92.48 163.57V129.8" stroke="black" stroke-width="0.5" />
    <path d="M91.15 164.9V129.8" stroke="black" stroke-width="0.5" />
    <path d="M90.35 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M89.82 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M108.95 128.47H89.82" stroke="black" stroke-width="0.5" />
    <path d="M91.15 129.8H89.82" stroke="black" stroke-width="0.5" />
    <path d="M92.48 129.8H108.97" stroke="black" stroke-width="0.5" />
    <path d="M108.97 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M120.94 164.9V128.47" stroke="black" stroke-width="0.5" />
    <path d="M121.47 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M119.61 164.9V129.8" stroke="black" stroke-width="0.5" />
    <path d="M118.81 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M118.28 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M121.47 128.47H118.28" stroke="black" stroke-width="0.5" />
    <path d="M119.61 129.8H118.28" stroke="black" stroke-width="0.5" />
    <path d="M120.94 129.8H121.47" stroke="black" stroke-width="0.5" />
    <path d="M80.49 128.47H61.36" stroke="black" stroke-width="0.5" />
    <path d="M64.02 129.8H80.51" stroke="black" stroke-width="0.5" />
    <path d="M80.51 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M154.91 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M121.47 129.8H132.27" stroke="black" stroke-width="0.5" />
    <path d="M132.27 129.8V129.13" stroke="black" stroke-width="0.5" />
    <path d="M132.27 129.13H121.47" stroke="black" stroke-width="0.5" />
    <path d="M121.47 129.13V129.8" stroke="black" stroke-width="0.5" />
    <path d="M128.27 128.47H139.08" stroke="black" stroke-width="0.5" />
    <path d="M139.08 128.47V129.13" stroke="black" stroke-width="0.5" />
    <path d="M139.08 129.13H128.27" stroke="black" stroke-width="0.5" />
    <path d="M128.27 129.13V128.47" stroke="black" stroke-width="0.5" />
    <path d="M120.94 130.91H127.14" stroke="black" stroke-width="0.5" />
    <path d="M127.14 130.91V164.9" stroke="black" stroke-width="0.5" />
    <path d="M127.14 164.9H120.94" stroke="black" stroke-width="0.5" />
    <path d="M124.04 130.91V131.62" stroke="black" stroke-width="0.5" />
    <path d="M124.04 132.28V133.61" stroke="black" stroke-width="0.5" />
    <path d="M124.04 134.28V135.61" stroke="black" stroke-width="0.5" />
    <path d="M124.04 136.27V137.6" stroke="black" stroke-width="0.5" />
    <path d="M124.04 138.27V139.6" stroke="black" stroke-width="0.5" />
    <path d="M124.04 140.26V141.59" stroke="black" stroke-width="0.5" />
    <path d="M124.04 142.26V143.59" stroke="black" stroke-width="0.5" />
    <path d="M124.04 144.25V145.58" stroke="black" stroke-width="0.5" />
    <path d="M124.04 146.25V147.58" stroke="black" stroke-width="0.5" />
    <path d="M124.04 148.24V149.57" stroke="black" stroke-width="0.5" />
    <path d="M124.04 150.23V151.56" stroke="black" stroke-width="0.5" />
    <path d="M124.04 152.23V153.56" stroke="black" stroke-width="0.5" />
    <path d="M124.04 154.22V155.55" stroke="black" stroke-width="0.5" />
    <path d="M124.04 156.22V157.55" stroke="black" stroke-width="0.5" />
    <path d="M124.04 158.21V159.54" stroke="black" stroke-width="0.5" />
    <path d="M124.04 160.21V161.54" stroke="black" stroke-width="0.5" />
    <path d="M124.04 162.2V163.53" stroke="black" stroke-width="0.5" />
    <path d="M124.04 164.2V164.9" stroke="black" stroke-width="0.5" />
    <path d="M120.94 135.7H121.71" stroke="black" stroke-width="0.5" />
    <path d="M122.37 135.7H123.7" stroke="black" stroke-width="0.5" />
    <path d="M124.37 135.7H125.7" stroke="black" stroke-width="0.5" />
    <path d="M126.36 135.7H127.14" stroke="black" stroke-width="0.5" />
    <path d="M120.94 148.05H121.71" stroke="black" stroke-width="0.5" />
    <path d="M122.37 148.05H123.7" stroke="black" stroke-width="0.5" />
    <path d="M124.37 148.05H125.7" stroke="black" stroke-width="0.5" />
    <path d="M126.36 148.05H127.14" stroke="black" stroke-width="0.5" />
    <path d="M120.94 152.84H121.71" stroke="black" stroke-width="0.5" />
    <path d="M122.37 152.84H123.7" stroke="black" stroke-width="0.5" />
    <path d="M124.37 152.84H125.7" stroke="black" stroke-width="0.5" />
    <path d="M126.36 152.84H127.14" stroke="black" stroke-width="0.5" />
    <path d="M154.91 130.91H148.71" stroke="black" stroke-width="0.5" />
    <path d="M148.71 130.91V164.9" stroke="black" stroke-width="0.5" />
    <path d="M151.81 130.91V131.62" stroke="black" stroke-width="0.5" />
    <path d="M151.81 132.28V133.61" stroke="black" stroke-width="0.5" />
    <path d="M151.81 134.28V135.61" stroke="black" stroke-width="0.5" />
    <path d="M151.81 136.27V137.6" stroke="black" stroke-width="0.5" />
    <path d="M151.81 138.27V139.6" stroke="black" stroke-width="0.5" />
    <path d="M151.81 140.26V141.59" stroke="black" stroke-width="0.5" />
    <path d="M151.81 142.26V143.59" stroke="black" stroke-width="0.5" />
    <path d="M151.81 144.25V145.58" stroke="black" stroke-width="0.5" />
    <path d="M151.81 146.25V147.58" stroke="black" stroke-width="0.5" />
    <path d="M151.81 148.24V149.57" stroke="black" stroke-width="0.5" />
    <path d="M151.81 150.23V151.56" stroke="black" stroke-width="0.5" />
    <path d="M151.81 152.23V153.56" stroke="black" stroke-width="0.5" />
    <path d="M151.81 154.22V155.55" stroke="black" stroke-width="0.5" />
    <path d="M151.81 156.22V157.55" stroke="black" stroke-width="0.5" />
    <path d="M151.81 158.21V159.54" stroke="black" stroke-width="0.5" />
    <path d="M151.81 160.21V161.54" stroke="black" stroke-width="0.5" />
    <path d="M151.81 162.2V163.53" stroke="black" stroke-width="0.5" />
    <path d="M151.81 164.2V164.9" stroke="black" stroke-width="0.5" />
    <path d="M154.91 135.7H154.14" stroke="black" stroke-width="0.5" />
    <path d="M153.48 135.7H152.15" stroke="black" stroke-width="0.5" />
    <path d="M151.48 135.7H150.15" stroke="black" stroke-width="0.5" />
    <path d="M149.49 135.7H148.71" stroke="black" stroke-width="0.5" />
    <path d="M154.91 148.05H154.14" stroke="black" stroke-width="0.5" />
    <path d="M153.48 148.05H152.15" stroke="black" stroke-width="0.5" />
    <path d="M151.48 148.05H150.15" stroke="black" stroke-width="0.5" />
    <path d="M149.49 148.05H148.71" stroke="black" stroke-width="0.5" />
    <path d="M154.91 152.84H154.14" stroke="black" stroke-width="0.5" />
    <path d="M153.48 152.84H152.15" stroke="black" stroke-width="0.5" />
    <path d="M151.48 152.84H150.15" stroke="black" stroke-width="0.5" />
    <path d="M149.49 152.84H148.71" stroke="black" stroke-width="0.5" />
    <path d="M156.24 129.8H161.38" stroke="black" stroke-width="0.5" />
    <path d="M161.38 129.8V164.9" stroke="black" stroke-width="0.5" />
    <path d="M161.38 164.9H156.24" stroke="black" stroke-width="0.5" />
    <path d="M156.24 164.9V129.8" stroke="black" stroke-width="0.5" />
    <path d="M250.62 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M251.95 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M222.16 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M223.49 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M252.48 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M253.02 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M250.09 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M249.56 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M262.59 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M263.12 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M262.59 36.94H284.56" stroke="black" stroke-width="0.5" />
    <path d="M262.59 38.27H284.56" stroke="black" stroke-width="0.5" />
    <path d="M239.98 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M239.45 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M239.98 36.94H223.49" stroke="black" stroke-width="0.5" />
    <path d="M222.16 36.94H205.67" stroke="black" stroke-width="0.5" />
    <path d="M239.98 38.27H205.67" stroke="black" stroke-width="0.5" />
    <path d="M193.71 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M195.04 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M192.64 36.94H193.71" stroke="black" stroke-width="0.5" />
    <path d="M195.04 36.94H196.1" stroke="black" stroke-width="0.5" />
    <path d="M192.64 38.27H196.1" stroke="black" stroke-width="0.5" />
    <path d="M195.57 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M196.1 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M193.17 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M192.64 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M183.07 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M182.54 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M205.67 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M284.56 27.63V36.94" stroke="black" stroke-width="0.5" />
    <path d="M284.56 38.27V96.2" stroke="black" stroke-width="0.5" />
    <path d="M286.68 72.85V74.21" stroke="black" stroke-width="0.5" />
    <path d="M286.68 74.87V76.2" stroke="black" stroke-width="0.5" />
    <path d="M286.68 76.87V78.2" stroke="black" stroke-width="0.5" />
    <path d="M286.68 78.86V80.19" stroke="black" stroke-width="0.5" />
    <path d="M286.68 80.86V82.19" stroke="black" stroke-width="0.5" />
    <path d="M286.68 82.85V84.18" stroke="black" stroke-width="0.5" />
    <path d="M286.68 84.85V86.18" stroke="black" stroke-width="0.5" />
    <path d="M286.68 86.84V88.17" stroke="black" stroke-width="0.5" />
    <path d="M286.68 88.84V90.17" stroke="black" stroke-width="0.5" />
    <path d="M286.68 90.83V92.16" stroke="black" stroke-width="0.5" />
    <path d="M286.68 92.83V94.16" stroke="black" stroke-width="0.5" />
    <path d="M286.68 94.82V96.15" stroke="black" stroke-width="0.5" />
    <path d="M286.68 96.82V98.15" stroke="black" stroke-width="0.5" />
    <path d="M286.68 98.81V100.14" stroke="black" stroke-width="0.5" />
    <path d="M286.68 100.8V102.17" stroke="black" stroke-width="0.5" />
    <path d="M288.01 72.85V73.93" stroke="black" stroke-width="0.5" />
    <path d="M288.01 74.6V75.93" stroke="black" stroke-width="0.5" />
    <path d="M288.01 76.59V77.92" stroke="black" stroke-width="0.5" />
    <path d="M288.01 78.59V79.92" stroke="black" stroke-width="0.5" />
    <path d="M288.01 80.58V81.91" stroke="black" stroke-width="0.5" />
    <path d="M288.01 82.58V83.91" stroke="black" stroke-width="0.5" />
    <path d="M288.01 84.57V85.9" stroke="black" stroke-width="0.5" />
    <path d="M288.01 86.57V87.9" stroke="black" stroke-width="0.5" />
    <path d="M288.01 88.56V89.89" stroke="black" stroke-width="0.5" />
    <path d="M288.01 90.56V91.89" stroke="black" stroke-width="0.5" />
    <path d="M288.01 92.55V93.88" stroke="black" stroke-width="0.5" />
    <path d="M288.01 94.55V95.88" stroke="black" stroke-width="0.5" />
    <path d="M288.01 96.54V97.87" stroke="black" stroke-width="0.5" />
    <path d="M288.01 98.53V99.86" stroke="black" stroke-width="0.5" />
    <path d="M288.01 100.53V101.62" stroke="black" stroke-width="0.5" />
    <path d="M285.89 27.63V50.24" stroke="black" stroke-width="0.5" />
    <path d="M285.89 51.57V71.52" stroke="black" stroke-width="0.5" />
    <path d="M285.89 51.57H297.59" stroke="black" stroke-width="0.5" />
    <path d="M309.29 51.57H320.99" stroke="black" stroke-width="0.5" />
    <path d="M285.89 50.24H297.59" stroke="black" stroke-width="0.5" />
    <path d="M309.29 50.24H320.99" stroke="black" stroke-width="0.5" />
    <path d="M284.56 72.85H299.18" stroke="black" stroke-width="0.5" />
    <path d="M285.89 71.52H299.18" stroke="black" stroke-width="0.5" />
    <path d="M299.18 71.52V72.85" stroke="black" stroke-width="0.5" />
    <path d="M297.59 50.24V51.57" stroke="black" stroke-width="0.5" />
    <path d="M309.29 50.24V51.57" stroke="black" stroke-width="0.5" />
    <path d="M309.29 50.24H314.08" stroke="black" stroke-width="0.5" />
    <path d="M314.08 50.24V51.57" stroke="black" stroke-width="0.5" />
    <path d="M314.08 51.57H309.29" stroke="black" stroke-width="0.5" />
    <path d="M309.29 51.57V50.24" stroke="black" stroke-width="0.5" />
    <path d="M309.82 50.91H313.55" stroke="black" stroke-width="0.5" />
    <path d="M313.55 50.24V51.57" stroke="black" stroke-width="0.5" />
    <path d="M309.82 51.57V50.24" stroke="black" stroke-width="0.5" />
    <path d="M297.59 50.24H292.8" stroke="black" stroke-width="0.5" />
    <path d="M292.8 50.24V51.57" stroke="black" stroke-width="0.5" />
    <path d="M292.8 51.57H297.59" stroke="black" stroke-width="0.5" />
    <path d="M297.59 51.57V50.24" stroke="black" stroke-width="0.5" />
    <path d="M297.06 50.91H293.33" stroke="black" stroke-width="0.5" />
    <path d="M293.33 50.24V51.57" stroke="black" stroke-width="0.5" />
    <path d="M297.06 51.57V50.24" stroke="black" stroke-width="0.5" />
    <path d="M299.18 71.52H294.4" stroke="black" stroke-width="0.5" />
    <path d="M294.4 71.52V72.85" stroke="black" stroke-width="0.5" />
    <path d="M294.4 72.85H299.18" stroke="black" stroke-width="0.5" />
    <path d="M299.18 72.85V71.52" stroke="black" stroke-width="0.5" />
    <path d="M298.65 72.18H294.93" stroke="black" stroke-width="0.5" />
    <path d="M294.93 71.52V72.85" stroke="black" stroke-width="0.5" />
    <path d="M298.65 72.85V71.52" stroke="black" stroke-width="0.5" />
    <path d="M302.26 128.47H307.04" stroke="black" stroke-width="0.5" />
    <path d="M307.04 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M307.04 129.8H302.26" stroke="black" stroke-width="0.5" />
    <path d="M302.26 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M302.79 129.13H306.51" stroke="black" stroke-width="0.5" />
    <path d="M306.51 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M302.79 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M279.65 129.8H274.86" stroke="black" stroke-width="0.5" />
    <path d="M274.86 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M274.86 128.47H279.65" stroke="black" stroke-width="0.5" />
    <path d="M279.65 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M279.12 129.13H275.39" stroke="black" stroke-width="0.5" />
    <path d="M275.39 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M279.12 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M194.01 129.8H189.22" stroke="black" stroke-width="0.5" />
    <path d="M189.22 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M189.22 128.47H194.01" stroke="black" stroke-width="0.5" />
    <path d="M194.01 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M193.48 129.13H189.75" stroke="black" stroke-width="0.5" />
    <path d="M189.75 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M193.48 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M143.74 128.47H154.91" stroke="black" stroke-width="0.5" />
    <path d="M154.91 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M154.91 129.8H143.74" stroke="black" stroke-width="0.5" />
    <path d="M143.74 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M144.27 129.13H154.38" stroke="black" stroke-width="0.5" />
    <path d="M154.38 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M144.27 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M108.95 129.8H104.16" stroke="black" stroke-width="0.5" />
    <path d="M104.16 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M104.16 128.47H108.95" stroke="black" stroke-width="0.5" />
    <path d="M108.95 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M108.41 129.13H104.69" stroke="black" stroke-width="0.5" />
    <path d="M104.69 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M108.41 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M80.51 129.8H75.72" stroke="black" stroke-width="0.5" />
    <path d="M75.72 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M75.72 128.47H80.51" stroke="black" stroke-width="0.5" />
    <path d="M80.51 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M79.98 129.13H76.25" stroke="black" stroke-width="0.5" />
    <path d="M76.25 129.8V128.47" stroke="black" stroke-width="0.5" />
    <path d="M79.98 128.47V129.8" stroke="black" stroke-width="0.5" />
    <path d="M33.2 164.9V166.77" stroke="black" stroke-width="0.5" />
    <path d="M31.87 163.57V166.77" stroke="black" stroke-width="0.5" />
    <path d="M33.2 164.9H35.3" stroke="black" stroke-width="0.5" />
    <path d="M31.87 163.57H33.97" stroke="black" stroke-width="0.5" />
    <path d="M288.51 161.71V163.58" stroke="black" stroke-width="0.5" />
    <path d="M287.18 160.38V163.58" stroke="black" stroke-width="0.5" />
    <path d="M288.51 161.71H291.62" stroke="black" stroke-width="0.5" />
    <path d="M287.18 160.38H290.29" stroke="black" stroke-width="0.5" />
    <path d="M236.41 161.71V163.58" stroke="black" stroke-width="0.5" />
    <path d="M237.74 160.38V163.58" stroke="black" stroke-width="0.5" />
    <path d="M236.41 161.71H233.3" stroke="black" stroke-width="0.5" />
    <path d="M237.74 160.38H234.7" stroke="black" stroke-width="0.5" />
    <path d="M92.76 164.9V166.77" stroke="black" stroke-width="0.5" />
    <path d="M94.09 163.57V166.77" stroke="black" stroke-width="0.5" />
    <path d="M92.76 164.9H91.15" stroke="black" stroke-width="0.5" />
    <path d="M94.09 163.57H92.48" stroke="black" stroke-width="0.5" />
    <path d="M205.67 36.94H210.46" stroke="black" stroke-width="0.5" />
    <path d="M210.46 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M210.46 38.27H205.67" stroke="black" stroke-width="0.5" />
    <path d="M205.67 38.27V36.94" stroke="black" stroke-width="0.5" />
    <path d="M206.21 37.61H209.93" stroke="black" stroke-width="0.5" />
    <path d="M209.93 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M206.21 38.27V36.94" stroke="black" stroke-width="0.5" />
    <path d="M166.58 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M165.25 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M164.18 36.94H165.25" stroke="black" stroke-width="0.5" />
    <path d="M164.72 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M164.18 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M164.18 38.27H183.07" stroke="black" stroke-width="0.5" />
    <path d="M166.58 36.94H183.07" stroke="black" stroke-width="0.5" />
    <path d="M154.61 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M154.08 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M138.12 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M136.79 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M135.73 36.94H136.79" stroke="black" stroke-width="0.5" />
    <path d="M136.26 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M135.73 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M135.73 38.27H154.61" stroke="black" stroke-width="0.5" />
    <path d="M138.12 36.94H154.61" stroke="black" stroke-width="0.5" />
    <path d="M126.15 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M125.62 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M109.66 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M108.33 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M96.63 38.27H126.15" stroke="black" stroke-width="0.5" />
    <path d="M108.33 36.94H91.84" stroke="black" stroke-width="0.5" />
    <path d="M91.84 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M91.84 36.94H96.63" stroke="black" stroke-width="0.5" />
    <path d="M96.63 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M96.63 38.27H91.84" stroke="black" stroke-width="0.5" />
    <path d="M91.84 38.27V36.94" stroke="black" stroke-width="0.5" />
    <path d="M92.37 37.61H96.1" stroke="black" stroke-width="0.5" />
    <path d="M96.1 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M92.37 38.27V36.94" stroke="black" stroke-width="0.5" />
    <path d="M109.66 36.94H126.15" stroke="black" stroke-width="0.5" />
    <path d="M79.87 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M81.2 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M78.81 36.94H79.87" stroke="black" stroke-width="0.5" />
    <path d="M81.2 36.94H82.27" stroke="black" stroke-width="0.5" />
    <path d="M78.81 38.27H82.27" stroke="black" stroke-width="0.5" />
    <path d="M81.74 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M82.27 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M79.34 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M78.81 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M69.24 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M68.7 36.94V38.27" stroke="black" stroke-width="0.5" />
    <path d="M51.42 38.27H57.57" stroke="black" stroke-width="0.5" />
    <path d="M61.6 38.27H69.24" stroke="black" stroke-width="0.5" />
    <path d="M52.75 36.94H69.24" stroke="black" stroke-width="0.5" />
    <path d="M51.42 2.10001V12.21" stroke="black" stroke-width="0.5" />
    <path d="M51.42 13.54V38.27" stroke="black" stroke-width="0.5" />
    <path d="M52.75 3.42999V36.94" stroke="black" stroke-width="0.5" />
    <path d="M35.4 112.51V26.5" stroke="black" stroke-width="0.5" />
    <path d="M35.4 16.93V13.54" stroke="black" stroke-width="0.5" />
    <path d="M35.4 12.21V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M34.07 111.18V26.5" stroke="black" stroke-width="0.5" />
    <path d="M34.07 16.93V8.47998" stroke="black" stroke-width="0.5" />
    <path d="M34.07 111.18H32.74" stroke="black" stroke-width="0.5" />
    <path d="M6.84003 112.51H23.16" stroke="black" stroke-width="0.5" />
    <path d="M32.74 112.51H35.4" stroke="black" stroke-width="0.5" />
    <path d="M33.27 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M32.74 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M23.16 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M22.63 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M234.02 111.18H256.9" stroke="black" stroke-width="0.5" />
    <path d="M266.47 111.18H267.8" stroke="black" stroke-width="0.5" />
    <path d="M269.13 112.51H266.47" stroke="black" stroke-width="0.5" />
    <path d="M256.9 112.51H232.69" stroke="black" stroke-width="0.5" />
    <path d="M34.07 76.07H9.96997" stroke="black" stroke-width="0.5" />
    <path d="M6.84003 77.4H34.07" stroke="black" stroke-width="0.5" />
    <path d="M269.13 54.26V112.51" stroke="black" stroke-width="0.5" />
    <path d="M267.8 55.59V82.72" stroke="black" stroke-width="0.5" />
    <path d="M267 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M267.8 84.05V111.18" stroke="black" stroke-width="0.5" />
    <path d="M256.9 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M234.02 55.59V82.72" stroke="black" stroke-width="0.5" />
    <path d="M234.02 84.05V111.18" stroke="black" stroke-width="0.5" />
    <path d="M232.69 54.26V112.51" stroke="black" stroke-width="0.5" />
    <path d="M232.69 54.26H235.09" stroke="black" stroke-width="0.5" />
    <path d="M244.66 54.26H269.13" stroke="black" stroke-width="0.5" />
    <path d="M267.8 55.59H244.66" stroke="black" stroke-width="0.5" />
    <path d="M235.09 55.59H234.02" stroke="black" stroke-width="0.5" />
    <path d="M234.55 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M235.09 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M244.66 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M245.19 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M267.8 84.05H234.02" stroke="black" stroke-width="0.5" />
    <path d="M267.8 82.72H234.02" stroke="black" stroke-width="0.5" />
    <path d="M193.06 111.18H192" stroke="black" stroke-width="0.5" />
    <path d="M182.43 111.18H159.29" stroke="black" stroke-width="0.5" />
    <path d="M157.96 111.18H156.89" stroke="black" stroke-width="0.5" />
    <path d="M156.89 112.51H182.43" stroke="black" stroke-width="0.5" />
    <path d="M192 112.51H194.39" stroke="black" stroke-width="0.5" />
    <path d="M157.96 55.59V82.72" stroke="black" stroke-width="0.5" />
    <path d="M157.96 84.05V111.18" stroke="black" stroke-width="0.5" />
    <path d="M159.29 55.59V82.72" stroke="black" stroke-width="0.5" />
    <path d="M159.29 84.05V111.18" stroke="black" stroke-width="0.5" />
    <path d="M193.06 55.59V71.55" stroke="black" stroke-width="0.5" />
    <path d="M194.39 54.26V72.88" stroke="black" stroke-width="0.5" />
    <path d="M219.93 62.97V83.32" stroke="black" stroke-width="0.5" />
    <path d="M218.73 62.24V63.48" stroke="black" stroke-width="0.5" />
    <path d="M218.73 64.15V65.48" stroke="black" stroke-width="0.5" />
    <path d="M218.73 66.14V67.47" stroke="black" stroke-width="0.5" />
    <path d="M218.73 68.14V69.47" stroke="black" stroke-width="0.5" />
    <path d="M218.73 70.13V71.46" stroke="black" stroke-width="0.5" />
    <path d="M218.73 72.13V73.46" stroke="black" stroke-width="0.5" />
    <path d="M218.73 74.12V75.45" stroke="black" stroke-width="0.5" />
    <path d="M218.73 76.12V77.45" stroke="black" stroke-width="0.5" />
    <path d="M218.73 78.11V79.44" stroke="black" stroke-width="0.5" />
    <path d="M218.73 80.11V81.44" stroke="black" stroke-width="0.5" />
    <path d="M218.73 82.1V83.34" stroke="black" stroke-width="0.5" />
    <path
      d="M219.93 62.97C219.931 62.8739 219.912 62.7786 219.876 62.6897C219.84 62.6008 219.786 62.52 219.718 62.452C219.65 62.3841 219.569 62.3303 219.48 62.2939C219.391 62.2575 219.296 62.2392 219.2 62.24"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M216.2 55.69V84.04" stroke="black" stroke-width="0.5" />
    <path d="M217.53 62.24V63.75" stroke="black" stroke-width="0.5" />
    <path d="M217.53 64.42V65.75" stroke="black" stroke-width="0.5" />
    <path d="M217.53 66.41V67.74" stroke="black" stroke-width="0.5" />
    <path d="M217.53 68.41V69.74" stroke="black" stroke-width="0.5" />
    <path d="M217.53 70.4V71.73" stroke="black" stroke-width="0.5" />
    <path d="M217.53 72.4V73.73" stroke="black" stroke-width="0.5" />
    <path d="M217.53 74.39V75.72" stroke="black" stroke-width="0.5" />
    <path d="M217.53 76.39V77.72" stroke="black" stroke-width="0.5" />
    <path d="M217.53 78.38V79.71" stroke="black" stroke-width="0.5" />
    <path d="M217.53 80.38V81.89" stroke="black" stroke-width="0.5" />
    <path
      d="M216.2 55.69C216.199 55.4994 216.123 55.3168 215.988 55.182C215.853 55.0472 215.671 54.9711 215.48 54.97"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M211.95 61.06V83.32" stroke="black" stroke-width="0.5" />
    <path d="M208.76 54.99V62.24" stroke="black" stroke-width="0.5" />
    <path
      d="M208.76 54.99C208.761 54.8939 208.742 54.7986 208.706 54.7097C208.67 54.6208 208.616 54.54 208.548 54.472C208.48 54.4041 208.399 54.3503 208.31 54.3139C208.221 54.2775 208.126 54.2592 208.03 54.26"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M193.06 95.22V111.18" stroke="black" stroke-width="0.5" />
    <path d="M194.39 93.89V112.51" stroke="black" stroke-width="0.5" />
    <path d="M194.39 54.26H192" stroke="black" stroke-width="0.5" />
    <path d="M182.43 54.26H156.89" stroke="black" stroke-width="0.5" />
    <path d="M159.29 55.59H182.43" stroke="black" stroke-width="0.5" />
    <path d="M192 55.59H193.06" stroke="black" stroke-width="0.5" />
    <path d="M192.53 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M192 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M182.43 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M181.89 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M111.41 84.58H134.55" stroke="black" stroke-width="0.5" />
    <path d="M135.88 84.05H157.96" stroke="black" stroke-width="0.5" />
    <path d="M159.29 84.05H189.87" stroke="black" stroke-width="0.5" />
    <path d="M111.41 82.19H134.55" stroke="black" stroke-width="0.5" />
    <path d="M135.88 82.72H157.96" stroke="black" stroke-width="0.5" />
    <path d="M142.27 76.34H157.96" stroke="black" stroke-width="0.5" />
    <path d="M159.29 82.72H189.87" stroke="black" stroke-width="0.5" />
    <path d="M157.43 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M156.89 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M192.53 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M192 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M182.43 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M181.89 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M189.87 84.05V95.22" stroke="black" stroke-width="0.5" />
    <path d="M191.2 72.88V93.89" stroke="black" stroke-width="0.5" />
    <path d="M189.87 95.22H193.06" stroke="black" stroke-width="0.5" />
    <path d="M191.2 93.89H194.39" stroke="black" stroke-width="0.5" />
    <path d="M189.87 71.55H193.06" stroke="black" stroke-width="0.5" />
    <path d="M191.2 72.88H194.39" stroke="black" stroke-width="0.5" />
    <path d="M189.87 82.72V71.55" stroke="black" stroke-width="0.5" />
    <path d="M134.55 55.59V82.19" stroke="black" stroke-width="0.5" />
    <path d="M134.55 84.58V111.18" stroke="black" stroke-width="0.5" />
    <path d="M135.88 55.59V82.72" stroke="black" stroke-width="0.5" />
    <path d="M142.27 63.59V76.34" stroke="black" stroke-width="0.5" />
    <path d="M147.32 54.26H133.49" stroke="black" stroke-width="0.5" />
    <path d="M135.88 55.59H147.32" stroke="black" stroke-width="0.5" />
    <path d="M157.43 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M156.89 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M147.32 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M146.79 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M156.89 55.59H157.96" stroke="black" stroke-width="0.5" />
    <path d="M147.32 112.51H134.55" stroke="black" stroke-width="0.5" />
    <path d="M135.88 111.18H147.32" stroke="black" stroke-width="0.5" />
    <path d="M157.43 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M156.89 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M147.32 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M146.79 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M135.88 84.05V111.18" stroke="black" stroke-width="0.5" />
    <path d="M156.89 111.18H157.96" stroke="black" stroke-width="0.5" />
    <path d="M134.02 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M133.49 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M110.08 55.59V66.97" stroke="black" stroke-width="0.5" />
    <path d="M110.08 73.06V82.72" stroke="black" stroke-width="0.5" />
    <path d="M110.08 84.05V111.18" stroke="black" stroke-width="0.5" />
    <path d="M111.41 55.59V82.19" stroke="black" stroke-width="0.5" />
    <path d="M123.91 54.26H87.48" stroke="black" stroke-width="0.5" />
    <path d="M111.41 55.59H123.91" stroke="black" stroke-width="0.5" />
    <path d="M134.02 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M133.49 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M123.91 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M123.38 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M123.91 112.51H87.48" stroke="black" stroke-width="0.5" />
    <path d="M111.41 111.18H123.91" stroke="black" stroke-width="0.5" />
    <path d="M133.49 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M134.02 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M123.91 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M123.38 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M111.41 84.58V111.18" stroke="black" stroke-width="0.5" />
    <path d="M134.55 83.39H111.41" stroke="black" stroke-width="0.5" />
    <path d="M194.39 54.26H208.03" stroke="black" stroke-width="0.5" />
    <path d="M194.39 55.46H195.26" stroke="black" stroke-width="0.5" />
    <path d="M195.92 55.46H197.25" stroke="black" stroke-width="0.5" />
    <path d="M197.92 55.46H199.25" stroke="black" stroke-width="0.5" />
    <path d="M199.91 55.46H201.24" stroke="black" stroke-width="0.5" />
    <path d="M201.91 55.46H203.24" stroke="black" stroke-width="0.5" />
    <path d="M203.9 55.46H205.23" stroke="black" stroke-width="0.5" />
    <path d="M205.9 55.46H207.23" stroke="black" stroke-width="0.5" />
    <path d="M207.89 55.46H208.76" stroke="black" stroke-width="0.5" />
    <path d="M194.39 56.66H195.26" stroke="black" stroke-width="0.5" />
    <path d="M195.92 56.66H197.25" stroke="black" stroke-width="0.5" />
    <path d="M197.92 56.66H199.25" stroke="black" stroke-width="0.5" />
    <path d="M199.91 56.66H201.24" stroke="black" stroke-width="0.5" />
    <path d="M201.91 56.66H203.24" stroke="black" stroke-width="0.5" />
    <path d="M203.9 56.66H205.23" stroke="black" stroke-width="0.5" />
    <path d="M205.9 56.66H207.23" stroke="black" stroke-width="0.5" />
    <path d="M207.89 56.66H208.76" stroke="black" stroke-width="0.5" />
    <path d="M194.39 57.99H208.76" stroke="black" stroke-width="0.5" />
    <path d="M194.39 62.24H219.2" stroke="black" stroke-width="0.5" />
    <path d="M211.95 61.61H216.2" stroke="black" stroke-width="0.5" />
    <path d="M210.09 54.97V61.06" stroke="black" stroke-width="0.5" />
    <path d="M208.76 54.97H215.48" stroke="black" stroke-width="0.5" />
    <path d="M210.09 61.06H216.2" stroke="black" stroke-width="0.5" />
    <path d="M211.78 56.11H214.12" stroke="black" stroke-width="0.5" />
    <path d="M214.12 56.11V58.92" stroke="black" stroke-width="0.5" />
    <path d="M214.12 58.92H211.78" stroke="black" stroke-width="0.5" />
    <path d="M211.78 58.92V56.11" stroke="black" stroke-width="0.5" />
    <path d="M211.64 54.97H214.32" stroke="black" stroke-width="0.5" />
    <path d="M214.32 54.97L214.59 55.04" stroke="black" stroke-width="0.5" />
    <path d="M214.59 55.04L214.79 55.24" stroke="black" stroke-width="0.5" />
    <path d="M214.79 55.24L214.86 55.51" stroke="black" stroke-width="0.5" />
    <path d="M214.86 55.51V59.97" stroke="black" stroke-width="0.5" />
    <path d="M214.86 59.97L214.79 60.24" stroke="black" stroke-width="0.5" />
    <path d="M214.79 60.24L214.59 60.44" stroke="black" stroke-width="0.5" />
    <path d="M214.59 60.44L214.32 60.51" stroke="black" stroke-width="0.5" />
    <path d="M214.32 60.51H211.64" stroke="black" stroke-width="0.5" />
    <path d="M211.64 60.51L211.37 60.44" stroke="black" stroke-width="0.5" />
    <path d="M211.37 60.44L211.17 60.24" stroke="black" stroke-width="0.5" />
    <path d="M211.17 60.24L211.1 59.97" stroke="black" stroke-width="0.5" />
    <path d="M211.1 59.97V55.51" stroke="black" stroke-width="0.5" />
    <path d="M211.1 55.51L211.17 55.24" stroke="black" stroke-width="0.5" />
    <path d="M211.17 55.24L211.37 55.04" stroke="black" stroke-width="0.5" />
    <path d="M211.37 55.04L211.64 54.97" stroke="black" stroke-width="0.5" />
    <path d="M211.76 59.53H212.43" stroke="black" stroke-width="0.5" />
    <path d="M212.43 59.53V60.11" stroke="black" stroke-width="0.5" />
    <path d="M212.43 60.11H211.76" stroke="black" stroke-width="0.5" />
    <path d="M211.76 60.11V59.53" stroke="black" stroke-width="0.5" />
    <path d="M214.14 59.53H213.47" stroke="black" stroke-width="0.5" />
    <path d="M213.47 59.53V60.11" stroke="black" stroke-width="0.5" />
    <path d="M213.47 60.11H214.14" stroke="black" stroke-width="0.5" />
    <path d="M214.14 60.11V59.53" stroke="black" stroke-width="0.5" />
    <path d="M208.76 59.44H210.09" stroke="black" stroke-width="0.5" />
    <path d="M212.67 84.04H219.2" stroke="black" stroke-width="0.5" />
    <path
      d="M211.95 83.32C211.951 83.5106 212.027 83.6931 212.162 83.8279C212.297 83.9627 212.479 84.039 212.67 84.04"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M219.2 84.04C219.295 84.0408 219.39 84.0228 219.478 83.987C219.566 83.9512 219.647 83.8984 219.714 83.8315C219.782 83.7646 219.836 83.685 219.873 83.5972C219.91 83.5095 219.929 83.4153 219.93 83.32"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M211.95 81.89H213.41" stroke="black" stroke-width="0.5" />
    <path d="M214.08 81.89H215.41" stroke="black" stroke-width="0.5" />
    <path d="M216.07 81.89H217.53" stroke="black" stroke-width="0.5" />
    <path d="M211.95 83.34H213.01" stroke="black" stroke-width="0.5" />
    <path d="M213.68 83.34H215.01" stroke="black" stroke-width="0.5" />
    <path d="M215.67 83.34H217" stroke="black" stroke-width="0.5" />
    <path d="M217.67 83.34H218.73" stroke="black" stroke-width="0.5" />
    <path d="M219.93 103.81V91.82" stroke="black" stroke-width="0.5" />
    <path d="M218.73 104.53V103.48" stroke="black" stroke-width="0.5" />
    <path d="M218.73 102.82V101.49" stroke="black" stroke-width="0.5" />
    <path d="M218.73 100.82V99.49" stroke="black" stroke-width="0.5" />
    <path d="M218.73 98.83V97.5" stroke="black" stroke-width="0.5" />
    <path d="M218.73 96.83V95.5" stroke="black" stroke-width="0.5" />
    <path d="M218.73 94.84V93.51" stroke="black" stroke-width="0.5" />
    <path d="M218.73 92.84V91.79" stroke="black" stroke-width="0.5" />
    <path d="M216.2 111.08V91.09" stroke="black" stroke-width="0.5" />
    <path d="M217.53 104.53V103.21" stroke="black" stroke-width="0.5" />
    <path d="M217.53 102.55V101.22" stroke="black" stroke-width="0.5" />
    <path d="M217.53 100.55V99.22" stroke="black" stroke-width="0.5" />
    <path d="M217.53 98.56V97.23" stroke="black" stroke-width="0.5" />
    <path d="M217.53 96.56V95.23" stroke="black" stroke-width="0.5" />
    <path d="M217.53 94.57V93.25" stroke="black" stroke-width="0.5" />
    <path d="M211.95 105.71V91.82" stroke="black" stroke-width="0.5" />
    <path d="M212.67 91.09H219.2" stroke="black" stroke-width="0.5" />
    <path
      d="M212.67 91.09C212.575 91.0905 212.481 91.1098 212.393 91.1468C212.305 91.1838 212.225 91.2378 212.158 91.3056C212.092 91.3734 212.039 91.4538 212.003 91.542C211.967 91.6303 211.949 91.7248 211.95 91.82"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M219.93 91.82C219.931 91.7239 219.912 91.6286 219.876 91.5397C219.84 91.4508 219.786 91.37 219.718 91.302C219.65 91.2341 219.569 91.1803 219.48 91.1439C219.391 91.1075 219.296 91.0892 219.2 91.09"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M211.95 93.25H213.41" stroke="black" stroke-width="0.5" />
    <path d="M214.08 93.25H215.41" stroke="black" stroke-width="0.5" />
    <path d="M216.07 93.25H217.53" stroke="black" stroke-width="0.5" />
    <path d="M211.95 91.79H213.01" stroke="black" stroke-width="0.5" />
    <path d="M213.68 91.79H215.01" stroke="black" stroke-width="0.5" />
    <path d="M215.67 91.79H217" stroke="black" stroke-width="0.5" />
    <path d="M217.67 91.79H218.73" stroke="black" stroke-width="0.5" />
    <path
      d="M219.2 104.53C219.295 104.531 219.39 104.513 219.478 104.477C219.566 104.441 219.647 104.388 219.714 104.322C219.782 104.255 219.836 104.175 219.873 104.087C219.91 103.999 219.929 103.905 219.93 103.81"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M215.48 111.8C215.671 111.799 215.853 111.723 215.988 111.588C216.123 111.453 216.199 111.271 216.2 111.08"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M208.76 111.78V104.53" stroke="black" stroke-width="0.5" />
    <path
      d="M208.03 112.51C208.126 112.511 208.221 112.492 208.31 112.456C208.399 112.42 208.48 112.366 208.548 112.298C208.616 112.23 208.67 112.149 208.706 112.06C208.742 111.971 208.761 111.876 208.76 111.78"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M194.39 112.51H192" stroke="black" stroke-width="0.5" />
    <path d="M192 111.18H193.06" stroke="black" stroke-width="0.5" />
    <path d="M192.53 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M192 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M194.39 112.51H208.03" stroke="black" stroke-width="0.5" />
    <path d="M194.39 111.31H195.26" stroke="black" stroke-width="0.5" />
    <path d="M195.92 111.31H197.25" stroke="black" stroke-width="0.5" />
    <path d="M197.92 111.31H199.25" stroke="black" stroke-width="0.5" />
    <path d="M199.91 111.31H201.24" stroke="black" stroke-width="0.5" />
    <path d="M201.91 111.31H203.24" stroke="black" stroke-width="0.5" />
    <path d="M203.9 111.31H205.23" stroke="black" stroke-width="0.5" />
    <path d="M205.9 111.31H207.23" stroke="black" stroke-width="0.5" />
    <path d="M207.89 111.31H208.76" stroke="black" stroke-width="0.5" />
    <path d="M194.39 110.12H195.26" stroke="black" stroke-width="0.5" />
    <path d="M195.92 110.12H197.25" stroke="black" stroke-width="0.5" />
    <path d="M197.92 110.12H199.25" stroke="black" stroke-width="0.5" />
    <path d="M199.91 110.12H201.24" stroke="black" stroke-width="0.5" />
    <path d="M201.91 110.12H203.24" stroke="black" stroke-width="0.5" />
    <path d="M203.9 110.12H205.23" stroke="black" stroke-width="0.5" />
    <path d="M205.9 110.12H207.23" stroke="black" stroke-width="0.5" />
    <path d="M207.89 110.12H208.76" stroke="black" stroke-width="0.5" />
    <path d="M194.39 108.79H208.76" stroke="black" stroke-width="0.5" />
    <path d="M194.39 104.53H219.2" stroke="black" stroke-width="0.5" />
    <path d="M210.09 111.8V105.71" stroke="black" stroke-width="0.5" />
    <path d="M208.76 111.8H215.48" stroke="black" stroke-width="0.5" />
    <path d="M210.09 105.71H216.2" stroke="black" stroke-width="0.5" />
    <path d="M211.78 110.67H214.12" stroke="black" stroke-width="0.5" />
    <path d="M214.12 110.67V107.85" stroke="black" stroke-width="0.5" />
    <path d="M214.12 107.85H211.78" stroke="black" stroke-width="0.5" />
    <path d="M211.78 107.85V110.67" stroke="black" stroke-width="0.5" />
    <path d="M211.64 111.8H214.32" stroke="black" stroke-width="0.5" />
    <path d="M214.32 111.8L214.59 111.73" stroke="black" stroke-width="0.5" />
    <path d="M214.59 111.73L214.79 111.53" stroke="black" stroke-width="0.5" />
    <path d="M214.79 111.53L214.86 111.26" stroke="black" stroke-width="0.5" />
    <path d="M214.86 111.26V106.81" stroke="black" stroke-width="0.5" />
    <path d="M214.86 106.81L214.79 106.54" stroke="black" stroke-width="0.5" />
    <path d="M214.79 106.54L214.59 106.34" stroke="black" stroke-width="0.5" />
    <path d="M214.59 106.34L214.32 106.26" stroke="black" stroke-width="0.5" />
    <path d="M214.32 106.26H211.64" stroke="black" stroke-width="0.5" />
    <path d="M211.64 106.26L211.37 106.34" stroke="black" stroke-width="0.5" />
    <path d="M211.37 106.34L211.17 106.54" stroke="black" stroke-width="0.5" />
    <path d="M211.17 106.54L211.1 106.81" stroke="black" stroke-width="0.5" />
    <path d="M211.1 106.81V111.26" stroke="black" stroke-width="0.5" />
    <path d="M211.1 111.26L211.17 111.53" stroke="black" stroke-width="0.5" />
    <path d="M211.17 111.53L211.37 111.73" stroke="black" stroke-width="0.5" />
    <path d="M211.37 111.73L211.64 111.8" stroke="black" stroke-width="0.5" />
    <path d="M211.76 107.24H212.43" stroke="black" stroke-width="0.5" />
    <path d="M212.43 107.24V106.66" stroke="black" stroke-width="0.5" />
    <path d="M212.43 106.66H211.76" stroke="black" stroke-width="0.5" />
    <path d="M211.76 106.66V107.24" stroke="black" stroke-width="0.5" />
    <path d="M214.14 107.24H213.47" stroke="black" stroke-width="0.5" />
    <path d="M213.47 107.24V106.66" stroke="black" stroke-width="0.5" />
    <path d="M213.47 106.66H214.14" stroke="black" stroke-width="0.5" />
    <path d="M214.14 106.66V107.24" stroke="black" stroke-width="0.5" />
    <path d="M208.76 107.33H210.09" stroke="black" stroke-width="0.5" />
    <path d="M211.95 105.1H216.2" stroke="black" stroke-width="0.5" />
    <path d="M199.28 104.53V105.99" stroke="black" stroke-width="0.5" />
    <path d="M199.28 106.66V107.99" stroke="black" stroke-width="0.5" />
    <path d="M199.28 108.65V110.12" stroke="black" stroke-width="0.5" />
    <path d="M211.95 99.9H213.41" stroke="black" stroke-width="0.5" />
    <path d="M214.08 99.9H215.41" stroke="black" stroke-width="0.5" />
    <path d="M216.07 99.9H217.53" stroke="black" stroke-width="0.5" />
    <path d="M199.28 62.24V60.78" stroke="black" stroke-width="0.5" />
    <path d="M199.28 60.11V58.78" stroke="black" stroke-width="0.5" />
    <path d="M199.28 58.12V56.66" stroke="black" stroke-width="0.5" />
    <path d="M208.59 67.02H214.25" stroke="black" stroke-width="0.5" />
    <path d="M214.25 67.02V73.11" stroke="black" stroke-width="0.5" />
    <path d="M214.25 73.11H208.59" stroke="black" stroke-width="0.5" />
    <path d="M208.59 73.11V67.02" stroke="black" stroke-width="0.5" />
    <path d="M208.42 68.46H214.25" stroke="black" stroke-width="0.5" />
    <path d="M208.42 71.67H214.25" stroke="black" stroke-width="0.5" />
    <path d="M212.83 68.46V71.67" stroke="black" stroke-width="0.5" />
    <path d="M210.01 68.46V71.67" stroke="black" stroke-width="0.5" />
    <path d="M210.01 70.06H212.83" stroke="black" stroke-width="0.5" />
    <path d="M134.55 95.92H135.88" stroke="black" stroke-width="0.5" />
    <path d="M76.84 111.18H77.9" stroke="black" stroke-width="0.5" />
    <path d="M77.9 112.51H75.51" stroke="black" stroke-width="0.5" />
    <path d="M76.84 55.59V71.55" stroke="black" stroke-width="0.5" />
    <path d="M75.51 54.26V72.88" stroke="black" stroke-width="0.5" />
    <path d="M47.85 62.97V76.44" stroke="black" stroke-width="0.5" />
    <path d="M49.05 62.24V63.04" stroke="black" stroke-width="0.5" />
    <path d="M49.05 63.7V65.03" stroke="black" stroke-width="0.5" />
    <path d="M49.05 65.7V67.03" stroke="black" stroke-width="0.5" />
    <path d="M49.05 67.69V69.02" stroke="black" stroke-width="0.5" />
    <path d="M49.05 69.69V71.02" stroke="black" stroke-width="0.5" />
    <path d="M49.05 71.68V73.01" stroke="black" stroke-width="0.5" />
    <path d="M49.05 73.68V75.01" stroke="black" stroke-width="0.5" />
    <path d="M49.05 75.67V76.47" stroke="black" stroke-width="0.5" />
    <path
      d="M48.57 62.24C48.4748 62.2405 48.3806 62.2598 48.2928 62.2968C48.205 62.3338 48.1254 62.3878 48.0585 62.4556C47.9916 62.5234 47.9388 62.6037 47.903 62.692C47.8672 62.7803 47.8492 62.8748 47.85 62.97"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M51.57 55.69V77.17" stroke="black" stroke-width="0.5" />
    <path d="M50.24 62.24V63.31" stroke="black" stroke-width="0.5" />
    <path d="M50.24 63.97V65.3" stroke="black" stroke-width="0.5" />
    <path d="M50.24 65.97V67.3" stroke="black" stroke-width="0.5" />
    <path d="M50.24 67.96V69.29" stroke="black" stroke-width="0.5" />
    <path d="M50.24 69.96V71.29" stroke="black" stroke-width="0.5" />
    <path d="M50.24 71.95V73.28" stroke="black" stroke-width="0.5" />
    <path d="M50.24 73.94V75.01" stroke="black" stroke-width="0.5" />
    <path
      d="M52.3 54.97C52.2048 54.9692 52.1103 54.9872 52.022 55.023C51.9338 55.0588 51.8534 55.1116 51.7856 55.1785C51.7178 55.2454 51.6638 55.325 51.6268 55.4128C51.5898 55.5005 51.5705 55.5947 51.57 55.69"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M55.83 61.06V76.44" stroke="black" stroke-width="0.5" />
    <path
      d="M55.1 77.17C55.1961 77.1708 55.2914 77.1525 55.3803 77.1161C55.4692 77.0797 55.55 77.0259 55.618 76.958C55.6859 76.89 55.7396 76.8092 55.776 76.7203C55.8124 76.6314 55.8308 76.5361 55.83 76.44"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M59.02 54.99V62.24" stroke="black" stroke-width="0.5" />
    <path
      d="M59.74 54.26C59.6447 54.2605 59.5505 54.2798 59.4628 54.3168C59.375 54.3538 59.2954 54.4078 59.2285 54.4756C59.1616 54.5434 59.1088 54.6237 59.073 54.712C59.0372 54.8003 59.0192 54.8947 59.02 54.99"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M76.84 95.22V111.18" stroke="black" stroke-width="0.5" />
    <path d="M75.51 93.89V112.51" stroke="black" stroke-width="0.5" />
    <path d="M75.51 54.26H77.9" stroke="black" stroke-width="0.5" />
    <path d="M77.9 55.59H76.84" stroke="black" stroke-width="0.5" />
    <path d="M77.37 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M77.9 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M87.48 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M88.01 55.59V54.26" stroke="black" stroke-width="0.5" />
    <path d="M110.08 84.05H80.03" stroke="black" stroke-width="0.5" />
    <path d="M110.08 82.72H80.03" stroke="black" stroke-width="0.5" />
    <path d="M77.37 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M77.9 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M87.48 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M88.01 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M80.03 84.05V95.22" stroke="black" stroke-width="0.5" />
    <path d="M78.7 72.88V93.89" stroke="black" stroke-width="0.5" />
    <path d="M80.03 95.22H76.84" stroke="black" stroke-width="0.5" />
    <path d="M78.7 93.89H75.51" stroke="black" stroke-width="0.5" />
    <path d="M80.03 71.55H76.84" stroke="black" stroke-width="0.5" />
    <path d="M78.7 72.88H75.51" stroke="black" stroke-width="0.5" />
    <path d="M80.03 82.72V71.55" stroke="black" stroke-width="0.5" />
    <path d="M75.51 54.26H59.74" stroke="black" stroke-width="0.5" />
    <path d="M75.51 55.46H74.58" stroke="black" stroke-width="0.5" />
    <path d="M73.91 55.46H72.58" stroke="black" stroke-width="0.5" />
    <path d="M71.92 55.46H70.59" stroke="black" stroke-width="0.5" />
    <path d="M69.92 55.46H68.59" stroke="black" stroke-width="0.5" />
    <path d="M67.93 55.46H66.6" stroke="black" stroke-width="0.5" />
    <path d="M65.94 55.46H64.61" stroke="black" stroke-width="0.5" />
    <path d="M63.94 55.46H62.61" stroke="black" stroke-width="0.5" />
    <path d="M61.95 55.46H60.62" stroke="black" stroke-width="0.5" />
    <path d="M59.95 55.46H59.02" stroke="black" stroke-width="0.5" />
    <path d="M75.51 56.66H74.58" stroke="black" stroke-width="0.5" />
    <path d="M73.91 56.66H72.58" stroke="black" stroke-width="0.5" />
    <path d="M71.92 56.66H70.59" stroke="black" stroke-width="0.5" />
    <path d="M69.92 56.66H68.59" stroke="black" stroke-width="0.5" />
    <path d="M67.93 56.66H66.6" stroke="black" stroke-width="0.5" />
    <path d="M65.94 56.66H64.61" stroke="black" stroke-width="0.5" />
    <path d="M63.94 56.66H62.61" stroke="black" stroke-width="0.5" />
    <path d="M61.95 56.66H60.62" stroke="black" stroke-width="0.5" />
    <path d="M59.95 56.66H59.02" stroke="black" stroke-width="0.5" />
    <path d="M75.51 57.99H59.02" stroke="black" stroke-width="0.5" />
    <path d="M75.51 62.24H48.57" stroke="black" stroke-width="0.5" />
    <path d="M55.83 61.61H51.57" stroke="black" stroke-width="0.5" />
    <path d="M57.69 54.97V61.06" stroke="black" stroke-width="0.5" />
    <path d="M59.02 54.97H52.3" stroke="black" stroke-width="0.5" />
    <path d="M57.69 61.06H51.57" stroke="black" stroke-width="0.5" />
    <path d="M56 56.11H53.65" stroke="black" stroke-width="0.5" />
    <path d="M53.65 56.11V58.92" stroke="black" stroke-width="0.5" />
    <path d="M53.65 58.92H56" stroke="black" stroke-width="0.5" />
    <path d="M56 58.92V56.11" stroke="black" stroke-width="0.5" />
    <path d="M56.14 54.97H53.46" stroke="black" stroke-width="0.5" />
    <path d="M53.46 54.97L53.19 55.04" stroke="black" stroke-width="0.5" />
    <path d="M53.19 55.04L52.99 55.24" stroke="black" stroke-width="0.5" />
    <path d="M52.99 55.24L52.92 55.51" stroke="black" stroke-width="0.5" />
    <path d="M52.92 55.51V59.97" stroke="black" stroke-width="0.5" />
    <path d="M52.92 59.97L52.99 60.24" stroke="black" stroke-width="0.5" />
    <path d="M52.99 60.24L53.19 60.44" stroke="black" stroke-width="0.5" />
    <path d="M53.19 60.44L53.46 60.51" stroke="black" stroke-width="0.5" />
    <path d="M53.46 60.51H56.14" stroke="black" stroke-width="0.5" />
    <path d="M56.14 60.51L56.41 60.44" stroke="black" stroke-width="0.5" />
    <path d="M56.41 60.44L56.61 60.24" stroke="black" stroke-width="0.5" />
    <path d="M56.61 60.24L56.68 59.97" stroke="black" stroke-width="0.5" />
    <path d="M56.68 59.97V55.51" stroke="black" stroke-width="0.5" />
    <path d="M56.68 55.51L56.61 55.24" stroke="black" stroke-width="0.5" />
    <path d="M56.61 55.24L56.41 55.04" stroke="black" stroke-width="0.5" />
    <path d="M56.41 55.04L56.14 54.97" stroke="black" stroke-width="0.5" />
    <path d="M56.01 59.53H55.35" stroke="black" stroke-width="0.5" />
    <path d="M55.35 59.53V60.11" stroke="black" stroke-width="0.5" />
    <path d="M55.35 60.11H56.01" stroke="black" stroke-width="0.5" />
    <path d="M56.01 60.11V59.53" stroke="black" stroke-width="0.5" />
    <path d="M53.64 59.53H54.31" stroke="black" stroke-width="0.5" />
    <path d="M54.31 59.53V60.11" stroke="black" stroke-width="0.5" />
    <path d="M54.31 60.11H53.64" stroke="black" stroke-width="0.5" />
    <path d="M53.64 60.11V59.53" stroke="black" stroke-width="0.5" />
    <path d="M59.02 59.44H57.69" stroke="black" stroke-width="0.5" />
    <path d="M55.1 77.17H48.57" stroke="black" stroke-width="0.5" />
    <path
      d="M47.85 76.44C47.8492 76.5352 47.8672 76.6297 47.903 76.718C47.9388 76.8063 47.9916 76.8866 48.0585 76.9544C48.1254 77.0222 48.205 77.0762 48.2928 77.1132C48.3806 77.1502 48.4748 77.1695 48.57 77.17"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M55.83 75.01H54.37" stroke="black" stroke-width="0.5" />
    <path d="M53.7 75.01H52.37" stroke="black" stroke-width="0.5" />
    <path d="M51.71 75.01H50.24" stroke="black" stroke-width="0.5" />
    <path d="M55.83 76.47H54.76" stroke="black" stroke-width="0.5" />
    <path d="M54.1 76.47H52.77" stroke="black" stroke-width="0.5" />
    <path d="M52.11 76.47H50.78" stroke="black" stroke-width="0.5" />
    <path d="M50.11 76.47H49.05" stroke="black" stroke-width="0.5" />
    <path d="M47.85 103.81V90.49" stroke="black" stroke-width="0.5" />
    <path d="M49.05 104.53V103.82" stroke="black" stroke-width="0.5" />
    <path d="M49.05 103.15V101.82" stroke="black" stroke-width="0.5" />
    <path d="M49.05 101.16V99.83" stroke="black" stroke-width="0.5" />
    <path d="M49.05 99.16V97.83" stroke="black" stroke-width="0.5" />
    <path d="M49.05 97.17V95.84" stroke="black" stroke-width="0.5" />
    <path d="M49.05 95.17V93.84" stroke="black" stroke-width="0.5" />
    <path d="M49.05 93.18V91.85" stroke="black" stroke-width="0.5" />
    <path d="M49.05 91.18V90.47" stroke="black" stroke-width="0.5" />
    <path d="M51.57 111.08V89.77" stroke="black" stroke-width="0.5" />
    <path d="M50.24 104.53V103.55" stroke="black" stroke-width="0.5" />
    <path d="M50.24 102.88V101.55" stroke="black" stroke-width="0.5" />
    <path d="M50.24 100.89V99.56" stroke="black" stroke-width="0.5" />
    <path d="M50.24 98.89V97.56" stroke="black" stroke-width="0.5" />
    <path d="M50.24 96.9V95.57" stroke="black" stroke-width="0.5" />
    <path d="M50.24 94.9V93.57" stroke="black" stroke-width="0.5" />
    <path d="M50.24 92.91V91.93" stroke="black" stroke-width="0.5" />
    <path d="M55.83 104.53V90.49" stroke="black" stroke-width="0.5" />
    <path
      d="M55.83 90.49C55.8295 90.3947 55.8101 90.3005 55.7731 90.2128C55.7362 90.125 55.6822 90.0454 55.6144 89.9785C55.5466 89.9116 55.4662 89.8588 55.378 89.823C55.2897 89.7872 55.1952 89.7692 55.1 89.77"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M55.1 89.77H48.57" stroke="black" stroke-width="0.5" />
    <path
      d="M48.57 89.77C48.3793 89.771 48.1968 89.8473 48.062 89.9821C47.9272 90.1169 47.851 90.2994 47.85 90.49"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M55.83 91.93H54.37" stroke="black" stroke-width="0.5" />
    <path d="M53.7 91.93H52.37" stroke="black" stroke-width="0.5" />
    <path d="M51.71 91.93H50.24" stroke="black" stroke-width="0.5" />
    <path d="M55.83 90.47H54.76" stroke="black" stroke-width="0.5" />
    <path d="M54.1 90.47H52.77" stroke="black" stroke-width="0.5" />
    <path d="M52.11 90.47H50.78" stroke="black" stroke-width="0.5" />
    <path d="M50.11 90.47H49.05" stroke="black" stroke-width="0.5" />
    <path
      d="M47.85 103.81C47.851 104.001 47.9272 104.183 48.062 104.318C48.1968 104.453 48.3793 104.529 48.57 104.53"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M51.57 111.08C51.5705 111.175 51.5898 111.269 51.6268 111.357C51.6638 111.445 51.7178 111.525 51.7856 111.591C51.8534 111.658 51.9338 111.711 52.022 111.747C52.1103 111.783 52.2048 111.801 52.3 111.8"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M59.02 111.78V104.53" stroke="black" stroke-width="0.5" />
    <path
      d="M59.02 111.78C59.0192 111.875 59.0372 111.97 59.073 112.058C59.1088 112.146 59.1616 112.227 59.2285 112.294C59.2954 112.362 59.375 112.416 59.4628 112.453C59.5505 112.49 59.6447 112.509 59.74 112.51"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M75.51 112.51H77.9" stroke="black" stroke-width="0.5" />
    <path d="M77.9 111.18H76.84" stroke="black" stroke-width="0.5" />
    <path d="M77.37 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M77.9 111.18V112.51" stroke="black" stroke-width="0.5" />
    <path d="M75.51 112.51H59.74" stroke="black" stroke-width="0.5" />
    <path d="M75.51 111.31H74.58" stroke="black" stroke-width="0.5" />
    <path d="M73.91 111.31H72.58" stroke="black" stroke-width="0.5" />
    <path d="M71.92 111.31H70.59" stroke="black" stroke-width="0.5" />
    <path d="M69.92 111.31H68.59" stroke="black" stroke-width="0.5" />
    <path d="M67.93 111.31H66.6" stroke="black" stroke-width="0.5" />
    <path d="M65.94 111.31H64.61" stroke="black" stroke-width="0.5" />
    <path d="M63.94 111.31H62.61" stroke="black" stroke-width="0.5" />
    <path d="M61.95 111.31H60.62" stroke="black" stroke-width="0.5" />
    <path d="M59.95 111.31H59.02" stroke="black" stroke-width="0.5" />
    <path d="M75.51 110.12H74.58" stroke="black" stroke-width="0.5" />
    <path d="M73.91 110.12H72.58" stroke="black" stroke-width="0.5" />
    <path d="M71.92 110.12H70.59" stroke="black" stroke-width="0.5" />
    <path d="M69.92 110.12H68.59" stroke="black" stroke-width="0.5" />
    <path d="M67.93 110.12H66.6" stroke="black" stroke-width="0.5" />
    <path d="M65.94 110.12H64.61" stroke="black" stroke-width="0.5" />
    <path d="M63.94 110.12H62.61" stroke="black" stroke-width="0.5" />
    <path d="M61.95 110.12H60.62" stroke="black" stroke-width="0.5" />
    <path d="M59.95 110.12H59.02" stroke="black" stroke-width="0.5" />
    <path d="M75.51 108.79H59.02" stroke="black" stroke-width="0.5" />
    <path d="M55.83 104.53H48.57" stroke="black" stroke-width="0.5" />
    <path d="M57.69 111.8V105.71" stroke="black" stroke-width="0.5" />
    <path d="M59.02 111.8H52.3" stroke="black" stroke-width="0.5" />
    <path d="M57.69 105.71H51.57" stroke="black" stroke-width="0.5" />
    <path d="M56 110.67H53.65" stroke="black" stroke-width="0.5" />
    <path d="M53.65 110.67V107.85" stroke="black" stroke-width="0.5" />
    <path d="M53.65 107.85H56" stroke="black" stroke-width="0.5" />
    <path d="M56 107.85V110.67" stroke="black" stroke-width="0.5" />
    <path d="M56.14 111.8H53.46" stroke="black" stroke-width="0.5" />
    <path d="M53.46 111.8L53.19 111.73" stroke="black" stroke-width="0.5" />
    <path d="M53.19 111.73L52.99 111.53" stroke="black" stroke-width="0.5" />
    <path d="M52.99 111.53L52.92 111.26" stroke="black" stroke-width="0.5" />
    <path d="M52.92 111.26V106.81" stroke="black" stroke-width="0.5" />
    <path d="M52.92 106.81L52.99 106.54" stroke="black" stroke-width="0.5" />
    <path d="M52.99 106.54L53.19 106.34" stroke="black" stroke-width="0.5" />
    <path d="M53.19 106.34L53.46 106.26" stroke="black" stroke-width="0.5" />
    <path d="M53.46 106.26H56.14" stroke="black" stroke-width="0.5" />
    <path d="M56.14 106.26L56.41 106.34" stroke="black" stroke-width="0.5" />
    <path d="M56.41 106.34L56.61 106.54" stroke="black" stroke-width="0.5" />
    <path d="M56.61 106.54L56.68 106.81" stroke="black" stroke-width="0.5" />
    <path d="M56.68 106.81V111.26" stroke="black" stroke-width="0.5" />
    <path d="M56.68 111.26L56.61 111.53" stroke="black" stroke-width="0.5" />
    <path d="M56.61 111.53L56.41 111.73" stroke="black" stroke-width="0.5" />
    <path d="M56.41 111.73L56.14 111.8" stroke="black" stroke-width="0.5" />
    <path d="M56.01 107.24H55.35" stroke="black" stroke-width="0.5" />
    <path d="M55.35 107.24V106.66" stroke="black" stroke-width="0.5" />
    <path d="M55.35 106.66H56.01" stroke="black" stroke-width="0.5" />
    <path d="M56.01 106.66V107.24" stroke="black" stroke-width="0.5" />
    <path d="M53.64 107.24H54.31" stroke="black" stroke-width="0.5" />
    <path d="M54.31 107.24V106.66" stroke="black" stroke-width="0.5" />
    <path d="M54.31 106.66H53.64" stroke="black" stroke-width="0.5" />
    <path d="M53.64 106.66V107.24" stroke="black" stroke-width="0.5" />
    <path d="M59.02 107.33H57.69" stroke="black" stroke-width="0.5" />
    <path d="M55.83 105.1H51.57" stroke="black" stroke-width="0.5" />
    <path d="M70.62 104.53V105.99" stroke="black" stroke-width="0.5" />
    <path d="M70.62 106.66V107.99" stroke="black" stroke-width="0.5" />
    <path d="M70.62 108.65V110.12" stroke="black" stroke-width="0.5" />
    <path d="M55.83 99.9H54.37" stroke="black" stroke-width="0.5" />
    <path d="M53.7 99.9H52.37" stroke="black" stroke-width="0.5" />
    <path d="M51.71 99.9H50.24" stroke="black" stroke-width="0.5" />
    <path d="M70.62 62.24V60.78" stroke="black" stroke-width="0.5" />
    <path d="M70.62 60.11V58.78" stroke="black" stroke-width="0.5" />
    <path d="M70.62 58.12V56.66" stroke="black" stroke-width="0.5" />
    <path d="M87.48 55.59H110.08" stroke="black" stroke-width="0.5" />
    <path d="M87.48 111.18H110.08" stroke="black" stroke-width="0.5" />
    <path d="M55.83 67.11H54.37" stroke="black" stroke-width="0.5" />
    <path d="M53.7 67.11H52.37" stroke="black" stroke-width="0.5" />
    <path d="M51.71 67.11H50.24" stroke="black" stroke-width="0.5" />
    <path d="M92.18 54.26V53.5" stroke="black" stroke-width="0.5" />
    <path d="M92.18 53.5H93.41" stroke="black" stroke-width="0.5" />
    <path d="M94.08 53.5H95.41" stroke="black" stroke-width="0.5" />
    <path d="M96.07 53.5H97.4" stroke="black" stroke-width="0.5" />
    <path d="M98.07 53.5H99.4" stroke="black" stroke-width="0.5" />
    <path d="M100.06 53.5H101.39" stroke="black" stroke-width="0.5" />
    <path d="M102.05 53.5H103.38" stroke="black" stroke-width="0.5" />
    <path d="M104.05 53.5H105.38" stroke="black" stroke-width="0.5" />
    <path d="M106.04 53.5H107.28" stroke="black" stroke-width="0.5" />
    <path d="M107.28 53.5V54.26" stroke="black" stroke-width="0.5" />
    <path d="M35.4 13.54H40.61" stroke="black" stroke-width="0.5" />
    <path d="M50.18 13.54H51.42" stroke="black" stroke-width="0.5" />
    <path d="M35.4 12.21H40.61" stroke="black" stroke-width="0.5" />
    <path d="M50.18 12.21H51.42" stroke="black" stroke-width="0.5" />
    <path d="M50.72 12.21V13.54" stroke="black" stroke-width="0.5" />
    <path d="M50.18 12.21V13.54" stroke="black" stroke-width="0.5" />
    <path d="M40.61 12.21V13.54" stroke="black" stroke-width="0.5" />
    <path d="M39.81 12.21V13.54" stroke="black" stroke-width="0.5" />
    <path d="M35.4 3.42999V2.10001" stroke="black" stroke-width="0.5" />
    <path d="M34.07 16.4H35.4" stroke="black" stroke-width="0.5" />
    <path d="M34.07 16.93H35.4" stroke="black" stroke-width="0.5" />
    <path d="M34.07 26.5H35.4" stroke="black" stroke-width="0.5" />
    <path d="M35.4 26.5V33.42" stroke="black" stroke-width="0.5" />
    <path d="M35.4 33.42H34.07" stroke="black" stroke-width="0.5" />
    <path d="M34.07 33.42V26.5" stroke="black" stroke-width="0.5" />
    <path d="M34.07 26.5H35.4" stroke="black" stroke-width="0.5" />
    <path d="M34.73 27.03V32.89" stroke="black" stroke-width="0.5" />
    <path d="M35.4 32.89H34.07" stroke="black" stroke-width="0.5" />
    <path d="M34.07 27.03H35.4" stroke="black" stroke-width="0.5" />
    <path d="M301.53 107.61L305.3 111.37" stroke="black" stroke-width="0.5" />
    <path d="M288.4 72.85V96.2" stroke="black" stroke-width="0.5" />
    <path d="M322.37 132.39L326.88 136.9" stroke="black" stroke-width="0.5" />
    <path d="M320.36 127.91L321.31 128.85" stroke="black" stroke-width="0.5" />
    <path d="M320.74 127.53L321.68 128.48" stroke="black" stroke-width="0.5" />
    <path d="M319.81 143.97L327.56 136.22" stroke="black" stroke-width="0.5" />
    <path d="M320.99 140.92L325.94 135.97" stroke="black" stroke-width="0.5" />
    <path d="M319.81 128.47L320.74 127.53" stroke="black" stroke-width="0.5" />
    <path d="M320.75 129.41L321.68 128.48" stroke="black" stroke-width="0.5" />
    <path d="M327.51 120.76L330.9 117.38" stroke="black" stroke-width="0.5" />
    <path d="M328.45 121.7L331.84 118.32" stroke="black" stroke-width="0.5" />
    <path d="M326.77 111.37L339.62 124.23" stroke="black" stroke-width="0.5" />
    <path d="M326.22 112.7L338.68 125.17" stroke="black" stroke-width="0.5" />
    <path d="M338.68 125.17L339.74 124.1" stroke="black" stroke-width="0.5" />
    <path d="M340.22 123.63L341.16 122.69" stroke="black" stroke-width="0.5" />
    <path d="M341.63 122.22L342.57 121.28" stroke="black" stroke-width="0.5" />
    <path d="M343.04 120.81L343.98 119.87" stroke="black" stroke-width="0.5" />
    <path d="M344.45 119.4L345.39 118.46" stroke="black" stroke-width="0.5" />
    <path d="M345.86 117.99L346.8 117.05" stroke="black" stroke-width="0.5" />
    <path d="M347.27 116.58L348.21 115.64" stroke="black" stroke-width="0.5" />
    <path d="M348.68 115.17L349.62 114.23" stroke="black" stroke-width="0.5" />
    <path d="M350.09 113.76L351.03 112.82" stroke="black" stroke-width="0.5" />
    <path d="M351.5 112.35L352.44 111.41" stroke="black" stroke-width="0.5" />
    <path d="M352.91 110.94L353.97 109.88" stroke="black" stroke-width="0.5" />
    <path d="M338.68 123.29L339.79 122.18" stroke="black" stroke-width="0.5" />
    <path d="M340.26 121.71L341.2 120.77" stroke="black" stroke-width="0.5" />
    <path d="M341.67 120.3L342.61 119.36" stroke="black" stroke-width="0.5" />
    <path d="M343.08 118.89L344.02 117.95" stroke="black" stroke-width="0.5" />
    <path d="M344.49 117.48L345.43 116.54" stroke="black" stroke-width="0.5" />
    <path d="M345.9 116.07L346.84 115.13" stroke="black" stroke-width="0.5" />
    <path d="M347.31 114.66L348.25 113.72" stroke="black" stroke-width="0.5" />
    <path d="M348.72 113.25L349.66 112.31" stroke="black" stroke-width="0.5" />
    <path d="M350.13 111.84L351.07 110.9" stroke="black" stroke-width="0.5" />
    <path d="M351.54 110.43L352.64 109.33" stroke="black" stroke-width="0.5" />
    <path d="M353.97 91.73V92.49" stroke="black" stroke-width="0.5" />
    <path d="M353.97 93.16V94.49" stroke="black" stroke-width="0.5" />
    <path d="M353.97 95.15V96.48" stroke="black" stroke-width="0.5" />
    <path d="M353.97 97.15V98.48" stroke="black" stroke-width="0.5" />
    <path d="M353.97 99.14V100.47" stroke="black" stroke-width="0.5" />
    <path d="M353.97 101.14V102.47" stroke="black" stroke-width="0.5" />
    <path d="M353.97 103.13V104.46" stroke="black" stroke-width="0.5" />
    <path d="M353.97 105.13V106.46" stroke="black" stroke-width="0.5" />
    <path d="M353.97 107.12V108.45" stroke="black" stroke-width="0.5" />
    <path d="M353.97 109.12V109.88" stroke="black" stroke-width="0.5" />
    <path d="M352.64 91.73V93.21" stroke="black" stroke-width="0.5" />
    <path d="M352.64 93.88V95.21" stroke="black" stroke-width="0.5" />
    <path d="M352.64 95.87V97.2" stroke="black" stroke-width="0.5" />
    <path d="M352.64 97.87V99.2" stroke="black" stroke-width="0.5" />
    <path d="M352.64 99.86V101.19" stroke="black" stroke-width="0.5" />
    <path d="M352.64 101.86V103.19" stroke="black" stroke-width="0.5" />
    <path d="M352.64 103.85V105.18" stroke="black" stroke-width="0.5" />
    <path d="M352.64 105.85V107.18" stroke="black" stroke-width="0.5" />
    <path d="M352.64 107.84V109.33" stroke="black" stroke-width="0.5" />
    <path d="M320.97 142.81L319.81 141.64" stroke="black" stroke-width="0.5" />
    <path d="M320.99 140.92H319.81" stroke="black" stroke-width="0.5" />
    <path d="M319.81 134.95L323.05 131.71" stroke="black" stroke-width="0.5" />
    <path d="M320.75 129.41L327.56 136.22" stroke="black" stroke-width="0.5" />
    <path d="M324.38 109.95V112.7" stroke="black" stroke-width="0.5" />
    <path d="M325.71 111.37H326.77" stroke="black" stroke-width="0.5" />
    <path d="M325.71 109.95V111.37" stroke="black" stroke-width="0.5" />
    <path d="M324.38 112.7H326.22" stroke="black" stroke-width="0.5" />
    <path d="M327.51 120.76L328.45 121.7" stroke="black" stroke-width="0.5" />
    <path d="M328.45 121.7L331.84 118.32" stroke="black" stroke-width="0.5" />
    <path d="M331.84 118.32L330.9 117.38" stroke="black" stroke-width="0.5" />
    <path d="M330.9 117.38L327.51 120.76" stroke="black" stroke-width="0.5" />
    <path d="M327.51 120.76L328.45 121.7" stroke="black" stroke-width="0.5" />
    <path d="M328.36 120.86L330.99 118.23" stroke="black" stroke-width="0.5" />
    <path d="M331.46 118.7L330.52 117.76" stroke="black" stroke-width="0.5" />
    <path d="M327.89 120.39L328.83 121.33" stroke="black" stroke-width="0.5" />
    <path d="M324.38 109.95H325.71" stroke="black" stroke-width="0.5" />
    <path d="M324.38 99.31H325.71" stroke="black" stroke-width="0.5" />
    <path d="M313.48 109.95V111.37" stroke="black" stroke-width="0.5" />
    <path d="M325.71 99.31V91.73" stroke="black" stroke-width="0.5" />
    <path d="M324.45 99.01V91.73" stroke="black" stroke-width="0.5" />
    <path d="M314.81 109.95V112.7" stroke="black" stroke-width="0.5" />
    <path d="M313.48 109.95H314.81" stroke="black" stroke-width="0.5" />
    <path d="M314.81 99.31V91.73" stroke="black" stroke-width="0.5" />
    <path d="M314.81 112.7H297.22" stroke="black" stroke-width="0.5" />
    <path d="M313.48 99.31V91.73" stroke="black" stroke-width="0.5" />
    <path d="M313.48 111.37H307.18" stroke="black" stroke-width="0.5" />
    <path d="M320.99 91.73H313.55" stroke="black" stroke-width="0.5" />
    <path d="M305.3 111.37H297.77" stroke="black" stroke-width="0.5" />
    <path d="M288.01 101.62L288.66 102.26" stroke="black" stroke-width="0.5" />
    <path d="M289.13 102.73L290.07 103.67" stroke="black" stroke-width="0.5" />
    <path d="M290.54 104.14L291.48 105.08" stroke="black" stroke-width="0.5" />
    <path d="M291.95 105.55L292.89 106.49" stroke="black" stroke-width="0.5" />
    <path d="M293.36 106.96L294.3 107.9" stroke="black" stroke-width="0.5" />
    <path d="M294.77 108.37L295.71 109.31" stroke="black" stroke-width="0.5" />
    <path d="M296.18 109.78L296.83 110.43" stroke="black" stroke-width="0.5" />
    <path d="M286.68 102.17L297.22 112.7" stroke="black" stroke-width="0.5" />
    <path d="M294.37 98.56L307.18 111.37" stroke="black" stroke-width="0.5" />
    <path d="M301.53 107.61L297.77 111.37" stroke="black" stroke-width="0.5" />
    <path d="M301.53 105.73L295.89 111.37" stroke="black" stroke-width="0.5" />
    <path d="M294.37 72.85V98.56" stroke="black" stroke-width="0.5" />
    <path d="M284.56 96.2H288.4" stroke="black" stroke-width="0.5" />
    <path d="M284.56 93.64H288.4" stroke="black" stroke-width="0.5" />
    <path d="M120.17 164.9V169.69" stroke="black" stroke-width="0.5" />
    <path d="M120.17 169.69H149.43" stroke="black" stroke-width="0.5" />
    <path d="M149.43 169.69V164.9" stroke="black" stroke-width="0.5" />
    <path d="M149.43 164.9H120.17" stroke="black" stroke-width="0.5" />
    <path d="M120.17 167.04H149.43" stroke="black" stroke-width="0.5" />
    <path d="M120.17 166.77H149.43" stroke="black" stroke-width="0.5" />
    <path d="M120.17 167.84H149.43" stroke="black" stroke-width="0.5" />
    <path d="M120.7 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M124.69 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M125.22 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M144.91 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M144.37 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M148.9 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M134.96 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M134.42 167.04V167.84" stroke="black" stroke-width="0.5" />
    <path d="M1.97998 2.10001H0.919983" stroke="black" stroke-width="0.5" />
    <path d="M1.97998 3.42999H0.919983" stroke="black" stroke-width="0.5" />
    <path d="M0.919983 2.10001V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M1.45001 2.10001V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M3.75011 66.93H2" stroke="black" stroke-width="0.5" />
    <path d="M1.78998 64.61L1.88998 64.39" stroke="black" stroke-width="0.5" />
    <path d="M1.89001 64.39L1.98001 64.17" stroke="black" stroke-width="0.5" />
    <path d="M1.58002 63.63V26.64" stroke="black" stroke-width="0.5" />
    <path
      d="M1.58002 63.63C1.58889 63.7462 1.63145 63.8573 1.7025 63.9496C1.77355 64.042 1.86999 64.1116 1.98002 64.15"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M1.97999 26.06C1.85598 26.0946 1.74802 26.1716 1.67493 26.2776C1.60184 26.3835 1.56824 26.5118 1.57999 26.64"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M1.97999 25.61C1.75621 25.6472 1.55327 25.7636 1.40822 25.938C1.26318 26.1124 1.18572 26.3332 1.18999 26.56"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M1.98001 25.86L1.89001 25.64" stroke="black" stroke-width="0.5" />
    <path d="M1.88998 25.64L1.78998 25.42" stroke="black" stroke-width="0.5" />
    <path d="M1.17999 63.66L1.18999 26.56" stroke="black" stroke-width="0.5" />
    <path
      d="M1.17999 63.66C1.19715 63.8767 1.28457 64.0818 1.42896 64.2443C1.57336 64.4067 1.76683 64.5176 1.97999 64.56"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M239.98 36.94L240.17 35.07" stroke="black" stroke-width="0.5" />
    <path d="M240.17 35.07L240.71 33.28" stroke="black" stroke-width="0.5" />
    <path d="M240.71 33.28L241.6 31.62" stroke="black" stroke-width="0.5" />
    <path d="M241.6 31.62L242.79 30.17" stroke="black" stroke-width="0.5" />
    <path d="M242.79 30.17L244.24 28.98" stroke="black" stroke-width="0.5" />
    <path d="M244.24 28.98L245.89 28.1" stroke="black" stroke-width="0.5" />
    <path d="M245.89 28.1L247.69 27.55" stroke="black" stroke-width="0.5" />
    <path d="M247.69 27.55L249.56 27.37" stroke="black" stroke-width="0.5" />
    <path d="M249.56 36.94H249.09" stroke="black" stroke-width="0.5" />
    <path d="M249.09 36.94V27.38" stroke="black" stroke-width="0.5" />
    <path d="M249.56 27.37V36.94" stroke="black" stroke-width="0.5" />
    <path d="M262.59 36.94L262.41 35.07" stroke="black" stroke-width="0.5" />
    <path d="M262.41 35.07L261.86 33.28" stroke="black" stroke-width="0.5" />
    <path d="M261.86 33.28L260.98 31.62" stroke="black" stroke-width="0.5" />
    <path d="M260.98 31.62L259.79 30.17" stroke="black" stroke-width="0.5" />
    <path d="M259.79 30.17L258.33 28.98" stroke="black" stroke-width="0.5" />
    <path d="M258.33 28.98L256.68 28.1" stroke="black" stroke-width="0.5" />
    <path d="M256.68 28.1L254.88 27.55" stroke="black" stroke-width="0.5" />
    <path d="M254.88 27.55L253.02 27.37" stroke="black" stroke-width="0.5" />
    <path d="M253.02 36.94H253.48" stroke="black" stroke-width="0.5" />
    <path d="M253.48 36.94V27.38" stroke="black" stroke-width="0.5" />
    <path d="M253.02 27.37V36.94" stroke="black" stroke-width="0.5" />
    <path d="M69.24 36.94L69.42 35.07" stroke="black" stroke-width="0.5" />
    <path d="M69.42 35.07L69.96 33.28" stroke="black" stroke-width="0.5" />
    <path d="M69.96 33.28L70.85 31.62" stroke="black" stroke-width="0.5" />
    <path d="M70.85 31.62L72.04 30.17" stroke="black" stroke-width="0.5" />
    <path d="M72.04 30.17L73.49 28.98" stroke="black" stroke-width="0.5" />
    <path d="M73.49 28.98L75.15 28.1" stroke="black" stroke-width="0.5" />
    <path d="M75.15 28.1L76.94 27.55" stroke="black" stroke-width="0.5" />
    <path d="M76.94 27.55L78.81 27.37" stroke="black" stroke-width="0.5" />
    <path d="M78.81 36.94H78.34" stroke="black" stroke-width="0.5" />
    <path d="M78.34 36.94V27.38" stroke="black" stroke-width="0.5" />
    <path d="M78.81 27.37V36.94" stroke="black" stroke-width="0.5" />
    <path d="M91.84 36.94L91.66 35.07" stroke="black" stroke-width="0.5" />
    <path d="M91.66 35.07L91.11 33.28" stroke="black" stroke-width="0.5" />
    <path d="M91.11 33.28L90.23 31.62" stroke="black" stroke-width="0.5" />
    <path d="M90.23 31.62L89.04 30.17" stroke="black" stroke-width="0.5" />
    <path d="M89.04 30.17L87.59 28.98" stroke="black" stroke-width="0.5" />
    <path d="M87.59 28.98L85.93 28.1" stroke="black" stroke-width="0.5" />
    <path d="M85.93 28.1L84.14 27.55" stroke="black" stroke-width="0.5" />
    <path d="M84.14 27.55L82.27 27.37" stroke="black" stroke-width="0.5" />
    <path d="M82.27 36.94H82.73" stroke="black" stroke-width="0.5" />
    <path d="M82.73 36.94V27.38" stroke="black" stroke-width="0.5" />
    <path d="M82.27 27.37V36.94" stroke="black" stroke-width="0.5" />
    <path d="M126.15 36.94L126.34 35.07" stroke="black" stroke-width="0.5" />
    <path d="M126.34 35.07L126.88 33.28" stroke="black" stroke-width="0.5" />
    <path d="M126.88 33.28L127.76 31.62" stroke="black" stroke-width="0.5" />
    <path d="M127.76 31.62L128.96 30.17" stroke="black" stroke-width="0.5" />
    <path d="M128.96 30.17L130.41 28.98" stroke="black" stroke-width="0.5" />
    <path d="M130.41 28.98L132.06 28.1" stroke="black" stroke-width="0.5" />
    <path d="M132.06 28.1L133.86 27.55" stroke="black" stroke-width="0.5" />
    <path d="M133.86 27.55L135.73 27.37" stroke="black" stroke-width="0.5" />
    <path d="M135.73 36.94H135.26" stroke="black" stroke-width="0.5" />
    <path d="M135.26 36.94V27.38" stroke="black" stroke-width="0.5" />
    <path d="M135.73 27.37V36.94" stroke="black" stroke-width="0.5" />
    <path d="M154.61 36.94L154.79 35.07" stroke="black" stroke-width="0.5" />
    <path d="M154.79 35.07L155.34 33.28" stroke="black" stroke-width="0.5" />
    <path d="M155.34 33.28L156.22 31.62" stroke="black" stroke-width="0.5" />
    <path d="M156.22 31.62L157.41 30.17" stroke="black" stroke-width="0.5" />
    <path d="M157.41 30.17L158.86 28.98" stroke="black" stroke-width="0.5" />
    <path d="M158.86 28.98L160.52 28.1" stroke="black" stroke-width="0.5" />
    <path d="M160.52 28.1L162.32 27.55" stroke="black" stroke-width="0.5" />
    <path d="M162.32 27.55L164.18 27.37" stroke="black" stroke-width="0.5" />
    <path d="M164.18 36.94H163.72" stroke="black" stroke-width="0.5" />
    <path d="M163.72 36.94V27.38" stroke="black" stroke-width="0.5" />
    <path d="M164.18 27.37V36.94" stroke="black" stroke-width="0.5" />
    <path d="M183.07 36.94L183.25 35.07" stroke="black" stroke-width="0.5" />
    <path d="M183.25 35.07L183.8 33.28" stroke="black" stroke-width="0.5" />
    <path d="M183.8 33.28L184.68 31.62" stroke="black" stroke-width="0.5" />
    <path d="M184.68 31.62L185.87 30.17" stroke="black" stroke-width="0.5" />
    <path d="M185.87 30.17L187.32 28.98" stroke="black" stroke-width="0.5" />
    <path d="M187.32 28.98L188.98 28.1" stroke="black" stroke-width="0.5" />
    <path d="M188.98 28.1L190.77 27.55" stroke="black" stroke-width="0.5" />
    <path d="M190.77 27.55L192.64 27.37" stroke="black" stroke-width="0.5" />
    <path d="M192.64 36.94H192.18" stroke="black" stroke-width="0.5" />
    <path d="M192.18 36.94V27.38" stroke="black" stroke-width="0.5" />
    <path d="M192.64 27.37V36.94" stroke="black" stroke-width="0.5" />
    <path d="M205.67 36.94L205.49 35.07" stroke="black" stroke-width="0.5" />
    <path d="M205.49 35.07L204.95 33.28" stroke="black" stroke-width="0.5" />
    <path d="M204.95 33.28L204.06 31.62" stroke="black" stroke-width="0.5" />
    <path d="M204.06 31.62L202.87 30.17" stroke="black" stroke-width="0.5" />
    <path d="M202.87 30.17L201.42 28.98" stroke="black" stroke-width="0.5" />
    <path d="M201.42 28.98L199.76 28.1" stroke="black" stroke-width="0.5" />
    <path d="M199.76 28.1L197.97 27.55" stroke="black" stroke-width="0.5" />
    <path d="M197.97 27.55L196.1 27.37" stroke="black" stroke-width="0.5" />
    <path d="M196.1 36.94H196.56" stroke="black" stroke-width="0.5" />
    <path d="M196.56 36.94V27.38" stroke="black" stroke-width="0.5" />
    <path d="M196.1 27.37V36.94" stroke="black" stroke-width="0.5" />
    <path d="M23.16 111.18L23.35 109.31" stroke="black" stroke-width="0.5" />
    <path d="M23.35 109.31L23.89 107.52" stroke="black" stroke-width="0.5" />
    <path d="M23.89 107.52L24.78 105.86" stroke="black" stroke-width="0.5" />
    <path d="M24.78 105.86L25.97 104.41" stroke="black" stroke-width="0.5" />
    <path d="M25.97 104.41L27.42 103.22" stroke="black" stroke-width="0.5" />
    <path d="M27.42 103.22L29.07 102.33" stroke="black" stroke-width="0.5" />
    <path d="M29.07 102.33L30.87 101.79" stroke="black" stroke-width="0.5" />
    <path d="M30.87 101.79L32.74 101.6" stroke="black" stroke-width="0.5" />
    <path d="M32.74 111.18H32.27" stroke="black" stroke-width="0.5" />
    <path d="M32.27 111.18V101.62" stroke="black" stroke-width="0.5" />
    <path d="M32.74 101.6V111.18" stroke="black" stroke-width="0.5" />
    <path d="M123.91 111.18L124.1 109.31" stroke="black" stroke-width="0.5" />
    <path d="M124.1 109.31L124.64 107.52" stroke="black" stroke-width="0.5" />
    <path d="M124.64 107.52L125.53 105.86" stroke="black" stroke-width="0.5" />
    <path d="M125.53 105.86L126.72 104.41" stroke="black" stroke-width="0.5" />
    <path d="M126.72 104.41L128.17 103.22" stroke="black" stroke-width="0.5" />
    <path d="M128.17 103.22L129.83 102.33" stroke="black" stroke-width="0.5" />
    <path d="M129.83 102.33L131.62 101.79" stroke="black" stroke-width="0.5" />
    <path d="M131.62 101.79L133.49 101.6" stroke="black" stroke-width="0.5" />
    <path d="M133.49 111.18H133.02" stroke="black" stroke-width="0.5" />
    <path d="M133.02 111.18V101.62" stroke="black" stroke-width="0.5" />
    <path d="M133.49 101.6V111.18" stroke="black" stroke-width="0.5" />
    <path d="M87.48 111.18L87.29 109.31" stroke="black" stroke-width="0.5" />
    <path d="M87.29 109.31L86.75 107.52" stroke="black" stroke-width="0.5" />
    <path d="M86.75 107.52L85.86 105.86" stroke="black" stroke-width="0.5" />
    <path d="M85.86 105.86L84.67 104.41" stroke="black" stroke-width="0.5" />
    <path d="M84.67 104.41L83.22 103.22" stroke="black" stroke-width="0.5" />
    <path d="M83.22 103.22L81.57 102.33" stroke="black" stroke-width="0.5" />
    <path d="M81.57 102.33L79.77 101.79" stroke="black" stroke-width="0.5" />
    <path d="M79.77 101.79L77.9 101.6" stroke="black" stroke-width="0.5" />
    <path d="M77.9 111.18H78.37" stroke="black" stroke-width="0.5" />
    <path d="M78.37 111.18V101.62" stroke="black" stroke-width="0.5" />
    <path d="M77.9 101.6V111.18" stroke="black" stroke-width="0.5" />
    <path d="M147.32 111.18L147.5 109.31" stroke="black" stroke-width="0.5" />
    <path d="M147.5 109.31L148.05 107.52" stroke="black" stroke-width="0.5" />
    <path d="M148.05 107.52L148.93 105.86" stroke="black" stroke-width="0.5" />
    <path d="M148.93 105.86L150.12 104.41" stroke="black" stroke-width="0.5" />
    <path d="M150.12 104.41L151.57 103.22" stroke="black" stroke-width="0.5" />
    <path d="M151.57 103.22L153.23 102.33" stroke="black" stroke-width="0.5" />
    <path d="M153.23 102.33L155.03 101.79" stroke="black" stroke-width="0.5" />
    <path d="M155.03 101.79L156.89 101.6" stroke="black" stroke-width="0.5" />
    <path d="M156.89 111.18H156.43" stroke="black" stroke-width="0.5" />
    <path d="M156.43 111.18V101.62" stroke="black" stroke-width="0.5" />
    <path d="M156.89 101.6V111.18" stroke="black" stroke-width="0.5" />
    <path d="M182.43 111.18L182.61 109.31" stroke="black" stroke-width="0.5" />
    <path d="M182.61 109.31L183.16 107.52" stroke="black" stroke-width="0.5" />
    <path d="M183.16 107.52L184.04 105.86" stroke="black" stroke-width="0.5" />
    <path d="M184.04 105.86L185.23 104.41" stroke="black" stroke-width="0.5" />
    <path d="M185.23 104.41L186.68 103.22" stroke="black" stroke-width="0.5" />
    <path d="M186.68 103.22L188.34 102.33" stroke="black" stroke-width="0.5" />
    <path d="M188.34 102.33L190.13 101.79" stroke="black" stroke-width="0.5" />
    <path d="M190.13 101.79L192 101.6" stroke="black" stroke-width="0.5" />
    <path d="M192 111.18H191.54" stroke="black" stroke-width="0.5" />
    <path d="M191.54 111.18V101.62" stroke="black" stroke-width="0.5" />
    <path d="M192 101.6V111.18" stroke="black" stroke-width="0.5" />
    <path d="M256.9 111.18L257.08 109.31" stroke="black" stroke-width="0.5" />
    <path d="M257.08 109.31L257.62 107.52" stroke="black" stroke-width="0.5" />
    <path d="M257.62 107.52L258.51 105.86" stroke="black" stroke-width="0.5" />
    <path d="M258.51 105.86L259.7 104.41" stroke="black" stroke-width="0.5" />
    <path d="M259.7 104.41L261.15 103.22" stroke="black" stroke-width="0.5" />
    <path d="M261.15 103.22L262.81 102.33" stroke="black" stroke-width="0.5" />
    <path d="M262.81 102.33L264.6 101.79" stroke="black" stroke-width="0.5" />
    <path d="M264.6 101.79L266.47 101.6" stroke="black" stroke-width="0.5" />
    <path d="M266.47 111.18H266" stroke="black" stroke-width="0.5" />
    <path d="M266 111.18V101.62" stroke="black" stroke-width="0.5" />
    <path d="M266.47 101.6V111.18" stroke="black" stroke-width="0.5" />
    <path d="M123.91 55.59L124.1 57.46" stroke="black" stroke-width="0.5" />
    <path d="M124.1 57.46L124.64 59.26" stroke="black" stroke-width="0.5" />
    <path d="M124.64 59.26L125.53 60.91" stroke="black" stroke-width="0.5" />
    <path d="M125.53 60.91L126.72 62.36" stroke="black" stroke-width="0.5" />
    <path d="M126.72 62.36L128.17 63.55" stroke="black" stroke-width="0.5" />
    <path d="M128.17 63.55L129.83 64.44" stroke="black" stroke-width="0.5" />
    <path d="M129.83 64.44L131.62 64.98" stroke="black" stroke-width="0.5" />
    <path d="M131.62 64.98L133.49 65.17" stroke="black" stroke-width="0.5" />
    <path d="M133.49 55.59H133.02" stroke="black" stroke-width="0.5" />
    <path d="M133.02 55.59V65.16" stroke="black" stroke-width="0.5" />
    <path d="M133.49 65.17V55.59" stroke="black" stroke-width="0.5" />
    <path d="M87.48 55.59L87.29 57.46" stroke="black" stroke-width="0.5" />
    <path d="M87.29 57.46L86.75 59.26" stroke="black" stroke-width="0.5" />
    <path d="M86.75 59.26L85.86 60.91" stroke="black" stroke-width="0.5" />
    <path d="M85.86 60.91L84.67 62.36" stroke="black" stroke-width="0.5" />
    <path d="M84.67 62.36L83.22 63.55" stroke="black" stroke-width="0.5" />
    <path d="M83.22 63.55L81.57 64.44" stroke="black" stroke-width="0.5" />
    <path d="M81.57 64.44L79.77 64.98" stroke="black" stroke-width="0.5" />
    <path d="M79.77 64.98L77.9 65.17" stroke="black" stroke-width="0.5" />
    <path d="M77.9 55.59H78.37" stroke="black" stroke-width="0.5" />
    <path d="M78.37 55.59V65.16" stroke="black" stroke-width="0.5" />
    <path d="M77.9 65.17V55.59" stroke="black" stroke-width="0.5" />
    <path d="M147.32 55.59L147.5 57.46" stroke="black" stroke-width="0.5" />
    <path d="M147.5 57.46L148.05 59.26" stroke="black" stroke-width="0.5" />
    <path d="M148.05 59.26L148.93 60.91" stroke="black" stroke-width="0.5" />
    <path d="M148.93 60.91L150.12 62.36" stroke="black" stroke-width="0.5" />
    <path d="M150.12 62.36L151.57 63.55" stroke="black" stroke-width="0.5" />
    <path d="M151.57 63.55L153.23 64.44" stroke="black" stroke-width="0.5" />
    <path d="M153.23 64.44L155.02 64.98" stroke="black" stroke-width="0.5" />
    <path d="M155.02 64.98L156.89 65.17" stroke="black" stroke-width="0.5" />
    <path d="M156.89 55.59H156.43" stroke="black" stroke-width="0.5" />
    <path d="M156.43 55.59V65.16" stroke="black" stroke-width="0.5" />
    <path d="M156.89 65.17V55.59" stroke="black" stroke-width="0.5" />
    <path d="M182.43 55.59L182.61 57.46" stroke="black" stroke-width="0.5" />
    <path d="M182.61 57.46L183.15 59.26" stroke="black" stroke-width="0.5" />
    <path d="M183.15 59.26L184.04 60.91" stroke="black" stroke-width="0.5" />
    <path d="M184.04 60.91L185.23 62.36" stroke="black" stroke-width="0.5" />
    <path d="M185.23 62.36L186.68 63.55" stroke="black" stroke-width="0.5" />
    <path d="M186.68 63.55L188.34 64.44" stroke="black" stroke-width="0.5" />
    <path d="M188.34 64.44L190.13 64.98" stroke="black" stroke-width="0.5" />
    <path d="M190.13 64.98L192 65.17" stroke="black" stroke-width="0.5" />
    <path d="M192 55.59H191.53" stroke="black" stroke-width="0.5" />
    <path d="M191.53 55.59V65.16" stroke="black" stroke-width="0.5" />
    <path d="M192 65.17V55.59" stroke="black" stroke-width="0.5" />
    <path d="M244.66 55.59L244.48 57.46" stroke="black" stroke-width="0.5" />
    <path d="M244.48 57.46L243.93 59.26" stroke="black" stroke-width="0.5" />
    <path d="M243.93 59.26L243.05 60.91" stroke="black" stroke-width="0.5" />
    <path d="M243.05 60.91L241.86 62.36" stroke="black" stroke-width="0.5" />
    <path d="M241.86 62.36L240.41 63.55" stroke="black" stroke-width="0.5" />
    <path d="M240.41 63.55L238.75 64.44" stroke="black" stroke-width="0.5" />
    <path d="M238.75 64.44L236.95 64.98" stroke="black" stroke-width="0.5" />
    <path d="M236.95 64.98L235.09 65.17" stroke="black" stroke-width="0.5" />
    <path d="M235.09 55.59H235.55" stroke="black" stroke-width="0.5" />
    <path d="M235.55 55.59V65.16" stroke="black" stroke-width="0.5" />
    <path d="M235.09 65.17V55.59" stroke="black" stroke-width="0.5" />
    <path d="M23.06 129.8L23.25 131.66" stroke="black" stroke-width="0.5" />
    <path d="M23.25 131.66L23.79 133.46" stroke="black" stroke-width="0.5" />
    <path d="M23.79 133.46L24.68 135.12" stroke="black" stroke-width="0.5" />
    <path d="M24.68 135.12L25.87 136.57" stroke="black" stroke-width="0.5" />
    <path d="M25.87 136.57L27.32 137.76" stroke="black" stroke-width="0.5" />
    <path d="M27.32 137.76L28.97 138.64" stroke="black" stroke-width="0.5" />
    <path d="M28.97 138.64L30.77 139.19" stroke="black" stroke-width="0.5" />
    <path d="M30.77 139.19L32.64 139.37" stroke="black" stroke-width="0.5" />
    <path d="M32.64 129.8H32.17" stroke="black" stroke-width="0.5" />
    <path d="M32.17 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M32.64 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M51.79 129.8L51.97 131.66" stroke="black" stroke-width="0.5" />
    <path d="M51.97 131.66L52.51 133.46" stroke="black" stroke-width="0.5" />
    <path d="M52.51 133.46L53.4 135.12" stroke="black" stroke-width="0.5" />
    <path d="M53.4 135.12L54.59 136.57" stroke="black" stroke-width="0.5" />
    <path d="M54.59 136.57L56.04 137.76" stroke="black" stroke-width="0.5" />
    <path d="M56.04 137.76L57.7 138.64" stroke="black" stroke-width="0.5" />
    <path d="M57.7 138.64L59.49 139.19" stroke="black" stroke-width="0.5" />
    <path d="M59.49 139.19L61.36 139.37" stroke="black" stroke-width="0.5" />
    <path d="M61.36 129.8H60.9" stroke="black" stroke-width="0.5" />
    <path d="M60.9 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M61.36 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M80.49 129.8L80.67 131.66" stroke="black" stroke-width="0.5" />
    <path d="M80.67 131.66L81.22 133.46" stroke="black" stroke-width="0.5" />
    <path d="M81.22 133.46L82.1 135.12" stroke="black" stroke-width="0.5" />
    <path d="M82.1 135.12L83.29 136.57" stroke="black" stroke-width="0.5" />
    <path d="M83.29 136.57L84.74 137.76" stroke="black" stroke-width="0.5" />
    <path d="M84.74 137.76L86.4 138.64" stroke="black" stroke-width="0.5" />
    <path d="M86.4 138.64L88.19 139.19" stroke="black" stroke-width="0.5" />
    <path d="M88.19 139.19L90.06 139.37" stroke="black" stroke-width="0.5" />
    <path d="M90.06 129.8H89.6" stroke="black" stroke-width="0.5" />
    <path d="M89.6 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M90.06 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M108.95 129.8L109.13 131.66" stroke="black" stroke-width="0.5" />
    <path d="M109.13 131.66L109.67 133.46" stroke="black" stroke-width="0.5" />
    <path d="M109.67 133.46L110.56 135.12" stroke="black" stroke-width="0.5" />
    <path d="M110.56 135.12L111.75 136.57" stroke="black" stroke-width="0.5" />
    <path d="M111.75 136.57L113.2 137.76" stroke="black" stroke-width="0.5" />
    <path d="M113.2 137.76L114.86 138.64" stroke="black" stroke-width="0.5" />
    <path d="M114.86 138.64L116.65 139.19" stroke="black" stroke-width="0.5" />
    <path d="M116.65 139.19L118.52 139.37" stroke="black" stroke-width="0.5" />
    <path d="M118.52 129.8H118.05" stroke="black" stroke-width="0.5" />
    <path d="M118.05 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M118.52 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M164.75 129.8L164.94 131.66" stroke="black" stroke-width="0.5" />
    <path d="M164.94 131.66L165.48 133.46" stroke="black" stroke-width="0.5" />
    <path d="M165.48 133.46L166.37 135.12" stroke="black" stroke-width="0.5" />
    <path d="M166.37 135.12L167.56 136.57" stroke="black" stroke-width="0.5" />
    <path d="M167.56 136.57L169.01 137.76" stroke="black" stroke-width="0.5" />
    <path d="M169.01 137.76L170.66 138.64" stroke="black" stroke-width="0.5" />
    <path d="M170.66 138.64L172.46 139.19" stroke="black" stroke-width="0.5" />
    <path d="M172.46 139.19L174.33 139.37" stroke="black" stroke-width="0.5" />
    <path d="M174.33 129.8H173.86" stroke="black" stroke-width="0.5" />
    <path d="M173.86 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M174.33 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M194.01 129.8L194.19 131.66" stroke="black" stroke-width="0.5" />
    <path d="M194.19 131.66L194.74 133.46" stroke="black" stroke-width="0.5" />
    <path d="M194.74 133.46L195.62 135.12" stroke="black" stroke-width="0.5" />
    <path d="M195.62 135.12L196.81 136.57" stroke="black" stroke-width="0.5" />
    <path d="M196.81 136.57L198.26 137.76" stroke="black" stroke-width="0.5" />
    <path d="M198.26 137.76L199.92 138.64" stroke="black" stroke-width="0.5" />
    <path d="M199.92 138.64L201.72 139.19" stroke="black" stroke-width="0.5" />
    <path d="M201.72 139.19L203.58 139.37" stroke="black" stroke-width="0.5" />
    <path d="M203.58 129.8H203.12" stroke="black" stroke-width="0.5" />
    <path d="M203.12 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M203.58 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M279.65 129.8L279.83 131.66" stroke="black" stroke-width="0.5" />
    <path d="M279.83 131.66L280.38 133.46" stroke="black" stroke-width="0.5" />
    <path d="M280.38 133.46L281.26 135.12" stroke="black" stroke-width="0.5" />
    <path d="M281.26 135.12L282.45 136.57" stroke="black" stroke-width="0.5" />
    <path d="M282.45 136.57L283.9 137.76" stroke="black" stroke-width="0.5" />
    <path d="M283.9 137.76L285.56 138.64" stroke="black" stroke-width="0.5" />
    <path d="M285.56 138.64L287.36 139.19" stroke="black" stroke-width="0.5" />
    <path d="M287.36 139.19L289.22 139.37" stroke="black" stroke-width="0.5" />
    <path d="M289.22 129.8H288.76" stroke="black" stroke-width="0.5" />
    <path d="M288.76 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M289.22 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M302.26 129.8L302.07 131.66" stroke="black" stroke-width="0.5" />
    <path d="M302.07 131.66L301.53 133.46" stroke="black" stroke-width="0.5" />
    <path d="M301.53 133.46L300.64 135.12" stroke="black" stroke-width="0.5" />
    <path d="M300.64 135.12L299.45 136.57" stroke="black" stroke-width="0.5" />
    <path d="M299.45 136.57L298 137.76" stroke="black" stroke-width="0.5" />
    <path d="M298 137.76L296.34 138.64" stroke="black" stroke-width="0.5" />
    <path d="M296.34 138.64L294.55 139.19" stroke="black" stroke-width="0.5" />
    <path d="M294.55 139.19L292.68 139.37" stroke="black" stroke-width="0.5" />
    <path d="M292.68 129.8H293.15" stroke="black" stroke-width="0.5" />
    <path d="M293.15 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M292.68 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M245.34 129.8L245.16 131.66" stroke="black" stroke-width="0.5" />
    <path d="M245.16 131.66L244.61 133.46" stroke="black" stroke-width="0.5" />
    <path d="M244.61 133.46L243.73 135.12" stroke="black" stroke-width="0.5" />
    <path d="M243.73 135.12L242.54 136.57" stroke="black" stroke-width="0.5" />
    <path d="M242.54 136.57L241.08 137.76" stroke="black" stroke-width="0.5" />
    <path d="M241.08 137.76L239.43 138.64" stroke="black" stroke-width="0.5" />
    <path d="M239.43 138.64L237.63 139.19" stroke="black" stroke-width="0.5" />
    <path d="M237.63 139.19L235.77 139.37" stroke="black" stroke-width="0.5" />
    <path d="M235.77 129.8H236.23" stroke="black" stroke-width="0.5" />
    <path d="M236.23 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M235.77 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M216.88 129.8L216.7 131.66" stroke="black" stroke-width="0.5" />
    <path d="M216.7 131.66L216.15 133.46" stroke="black" stroke-width="0.5" />
    <path d="M216.15 133.46L215.27 135.12" stroke="black" stroke-width="0.5" />
    <path d="M215.27 135.12L214.08 136.57" stroke="black" stroke-width="0.5" />
    <path d="M214.08 136.57L212.63 137.76" stroke="black" stroke-width="0.5" />
    <path d="M212.63 137.76L210.97 138.64" stroke="black" stroke-width="0.5" />
    <path d="M210.97 138.64L209.18 139.19" stroke="black" stroke-width="0.5" />
    <path d="M209.18 139.19L207.31 139.37" stroke="black" stroke-width="0.5" />
    <path d="M207.31 129.8H207.77" stroke="black" stroke-width="0.5" />
    <path d="M207.77 129.8V139.36" stroke="black" stroke-width="0.5" />
    <path d="M207.31 139.37V129.8" stroke="black" stroke-width="0.5" />
    <path d="M235.08 112.51V113.55" stroke="black" stroke-width="0.5" />
    <path d="M235.08 113.55H236.23" stroke="black" stroke-width="0.5" />
    <path d="M236.89 113.55H238.22" stroke="black" stroke-width="0.5" />
    <path d="M238.89 113.55H240.22" stroke="black" stroke-width="0.5" />
    <path d="M240.88 113.55H242.21" stroke="black" stroke-width="0.5" />
    <path d="M242.88 113.55H244.2" stroke="black" stroke-width="0.5" />
    <path d="M244.87 113.55H246.2" stroke="black" stroke-width="0.5" />
    <path d="M246.86 113.55H248.19" stroke="black" stroke-width="0.5" />
    <path d="M248.86 113.55H250" stroke="black" stroke-width="0.5" />
    <path d="M250 113.55V112.51" stroke="black" stroke-width="0.5" />
    <path d="M34.07 26.5L32.2 26.32" stroke="black" stroke-width="0.5" />
    <path d="M32.2 26.32L30.4 25.77" stroke="black" stroke-width="0.5" />
    <path d="M30.4 25.77L28.75 24.89" stroke="black" stroke-width="0.5" />
    <path d="M28.75 24.89L27.3 23.7" stroke="black" stroke-width="0.5" />
    <path d="M27.3 23.7L26.11 22.25" stroke="black" stroke-width="0.5" />
    <path d="M26.11 22.25L25.22 20.59" stroke="black" stroke-width="0.5" />
    <path d="M25.22 20.59L24.68 18.8" stroke="black" stroke-width="0.5" />
    <path d="M24.68 18.8L24.49 16.93" stroke="black" stroke-width="0.5" />
    <path d="M34.07 16.93V17.39" stroke="black" stroke-width="0.5" />
    <path d="M34.07 17.39H24.5" stroke="black" stroke-width="0.5" />
    <path d="M24.49 16.93H34.07" stroke="black" stroke-width="0.5" />
    <path d="M40.61 12.21L40.79 10.34" stroke="black" stroke-width="0.5" />
    <path d="M40.79 10.34L41.34 8.53998" stroke="black" stroke-width="0.5" />
    <path d="M41.34 8.54001L42.22 6.89001" stroke="black" stroke-width="0.5" />
    <path d="M42.22 6.89L43.41 5.44" stroke="black" stroke-width="0.5" />
    <path d="M43.41 5.44L44.86 4.25" stroke="black" stroke-width="0.5" />
    <path d="M44.86 4.24998L46.52 3.35999" stroke="black" stroke-width="0.5" />
    <path d="M46.52 3.36L48.32 2.82001" stroke="black" stroke-width="0.5" />
    <path d="M48.32 2.82001L50.18 2.63" stroke="black" stroke-width="0.5" />
    <path d="M50.18 12.21H49.72" stroke="black" stroke-width="0.5" />
    <path d="M49.72 12.21V2.64001" stroke="black" stroke-width="0.5" />
    <path d="M50.18 2.63V12.21" stroke="black" stroke-width="0.5" />
    <path d="M29.05 9.34V11.11" stroke="black" stroke-width="0.5" />
    <path d="M328.45 121.7L329.64 123.16" stroke="black" stroke-width="0.5" />
    <path d="M329.64 123.16L330.53 124.81" stroke="black" stroke-width="0.5" />
    <path d="M330.53 124.81L331.07 126.61" stroke="black" stroke-width="0.5" />
    <path d="M331.07 126.61L331.26 128.48" stroke="black" stroke-width="0.5" />
    <path d="M331.26 128.48L331.07 130.34" stroke="black" stroke-width="0.5" />
    <path d="M331.07 130.34L330.53 132.14" stroke="black" stroke-width="0.5" />
    <path d="M330.53 132.14L329.64 133.79" stroke="black" stroke-width="0.5" />
    <path d="M329.64 133.79L328.45 135.25" stroke="black" stroke-width="0.5" />
    <path d="M321.68 128.48L322.01 128.15" stroke="black" stroke-width="0.5" />
    <path d="M322.01 128.15L328.77 134.91" stroke="black" stroke-width="0.5" />
    <path d="M328.45 135.25L321.68 128.48" stroke="black" stroke-width="0.5" />
    <path d="M326.88 136.9L327.92 138.17" stroke="black" stroke-width="0.5" />
    <path d="M327.92 138.17L328.69 139.61" stroke="black" stroke-width="0.5" />
    <path d="M328.69 139.61L329.16 141.18" stroke="black" stroke-width="0.5" />
    <path d="M329.16 141.18L329.32 142.81" stroke="black" stroke-width="0.5" />
    <path d="M329.32 142.81L329.16 144.44" stroke="black" stroke-width="0.5" />
    <path d="M329.16 144.44L328.69 146" stroke="black" stroke-width="0.5" />
    <path d="M328.69 146L327.92 147.44" stroke="black" stroke-width="0.5" />
    <path d="M327.92 147.44L326.88 148.71" stroke="black" stroke-width="0.5" />
    <path d="M326.88 148.71L320.97 142.81" stroke="black" stroke-width="0.5" />
    <path d="M114.87 79.51L114.81 78.95" stroke="black" stroke-width="0.5" />
    <path d="M114.81 78.95L114.75 78.54" stroke="black" stroke-width="0.5" />
    <path d="M114.75 78.54L114.69 78.18" stroke="black" stroke-width="0.5" />
    <path d="M114.69 78.18L114.63 77.88" stroke="black" stroke-width="0.5" />
    <path d="M114.63 77.88L114.57 77.64" stroke="black" stroke-width="0.5" />
    <path d="M114.57 77.64L114.52 77.45" stroke="black" stroke-width="0.5" />
    <path d="M114.52 77.45L114.49 77.33" stroke="black" stroke-width="0.5" />
    <path d="M114.49 77.33L114.47 77.23" stroke="black" stroke-width="0.5" />
    <path d="M114.47 77.23L114.45 77.12" stroke="black" stroke-width="0.5" />
    <path d="M114.45 77.12L114.44 76.98" stroke="black" stroke-width="0.5" />
    <path d="M114.44 76.98L114.43 76.78" stroke="black" stroke-width="0.5" />
    <path d="M114.43 76.78V76.55" stroke="black" stroke-width="0.5" />
    <path d="M114.43 76.55L114.45 76.28" stroke="black" stroke-width="0.5" />
    <path d="M114.45 76.28L114.49 75.98" stroke="black" stroke-width="0.5" />
    <path d="M114.49 75.98L114.55 75.67" stroke="black" stroke-width="0.5" />
    <path d="M114.55 75.67L114.65 75.36" stroke="black" stroke-width="0.5" />
    <path d="M114.65 75.36L114.78 75.06" stroke="black" stroke-width="0.5" />
    <path d="M114.78 75.06L114.93 74.77" stroke="black" stroke-width="0.5" />
    <path d="M114.93 74.77L115.12 74.52" stroke="black" stroke-width="0.5" />
    <path d="M115.12 74.52L115.32 74.31" stroke="black" stroke-width="0.5" />
    <path d="M115.32 74.31L115.54 74.13" stroke="black" stroke-width="0.5" />
    <path d="M115.54 74.13L115.76 74" stroke="black" stroke-width="0.5" />
    <path d="M115.76 74L115.98 73.92" stroke="black" stroke-width="0.5" />
    <path d="M115.98 73.92L116.2 73.9" stroke="black" stroke-width="0.5" />
    <path d="M116.2 73.9L116.42 73.92" stroke="black" stroke-width="0.5" />
    <path d="M116.42 73.92L116.64 74" stroke="black" stroke-width="0.5" />
    <path d="M116.64 74L116.87 74.13" stroke="black" stroke-width="0.5" />
    <path d="M116.87 74.13L117.09 74.31" stroke="black" stroke-width="0.5" />
    <path d="M117.09 74.31L117.29 74.52" stroke="black" stroke-width="0.5" />
    <path d="M117.29 74.52L117.47 74.77" stroke="black" stroke-width="0.5" />
    <path d="M117.47 74.77L117.63 75.06" stroke="black" stroke-width="0.5" />
    <path d="M117.63 75.06L117.76 75.36" stroke="black" stroke-width="0.5" />
    <path d="M117.76 75.36L117.85 75.67" stroke="black" stroke-width="0.5" />
    <path d="M117.85 75.67L117.92 75.98" stroke="black" stroke-width="0.5" />
    <path d="M117.92 75.98L117.96 76.27" stroke="black" stroke-width="0.5" />
    <path d="M117.96 76.27L117.97 76.55" stroke="black" stroke-width="0.5" />
    <path d="M117.97 76.55V76.79" stroke="black" stroke-width="0.5" />
    <path d="M117.97 76.79V76.99" stroke="black" stroke-width="0.5" />
    <path d="M117.97 76.99L117.96 77.12" stroke="black" stroke-width="0.5" />
    <path d="M117.96 77.12L117.94 77.22" stroke="black" stroke-width="0.5" />
    <path d="M117.94 77.22L117.92 77.33" stroke="black" stroke-width="0.5" />
    <path d="M117.92 77.33L117.88 77.46" stroke="black" stroke-width="0.5" />
    <path d="M117.88 77.46L117.83 77.64" stroke="black" stroke-width="0.5" />
    <path d="M117.83 77.64L117.78 77.88" stroke="black" stroke-width="0.5" />
    <path d="M117.78 77.88L117.72 78.18" stroke="black" stroke-width="0.5" />
    <path d="M117.72 78.18L117.66 78.54" stroke="black" stroke-width="0.5" />
    <path d="M117.66 78.54L117.6 78.95" stroke="black" stroke-width="0.5" />
    <path d="M117.6 78.95L117.55 79.39" stroke="black" stroke-width="0.5" />
    <path d="M117.55 79.39V79.51" stroke="black" stroke-width="0.5" />
    <path d="M118.02 79.66L118.06 79.67" stroke="black" stroke-width="0.5" />
    <path d="M118.06 79.67L118.09 79.68" stroke="black" stroke-width="0.5" />
    <path d="M118.09 79.68L118.13 79.7" stroke="black" stroke-width="0.5" />
    <path d="M118.13 79.7L118.16 79.71" stroke="black" stroke-width="0.5" />
    <path d="M118.16 79.71L118.19 79.73" stroke="black" stroke-width="0.5" />
    <path d="M118.19 79.73L118.22 79.75" stroke="black" stroke-width="0.5" />
    <path d="M118.22 79.75L118.26 79.78" stroke="black" stroke-width="0.5" />
    <path d="M118.26 79.78L118.29 79.81" stroke="black" stroke-width="0.5" />
    <path d="M118.29 79.81L118.34 79.85" stroke="black" stroke-width="0.5" />
    <path d="M118.34 79.85L118.38 79.9" stroke="black" stroke-width="0.5" />
    <path d="M118.38 79.9L118.43 79.95" stroke="black" stroke-width="0.5" />
    <path d="M118.43 79.95L118.48 80" stroke="black" stroke-width="0.5" />
    <path d="M118.48 80L118.53 80.07" stroke="black" stroke-width="0.5" />
    <path d="M118.53 80.07L118.57 80.13" stroke="black" stroke-width="0.5" />
    <path d="M118.57 80.13L118.61 80.2" stroke="black" stroke-width="0.5" />
    <path d="M118.61 80.2L118.65 80.27" stroke="black" stroke-width="0.5" />
    <path d="M118.65 80.27L118.67 80.35" stroke="black" stroke-width="0.5" />
    <path d="M118.67 80.35L118.69 80.42" stroke="black" stroke-width="0.5" />
    <path d="M118.69 80.42L118.71 80.5" stroke="black" stroke-width="0.5" />
    <path d="M118.71 80.5L118.72 80.58" stroke="black" stroke-width="0.5" />
    <path d="M118.72 80.58V80.65" stroke="black" stroke-width="0.5" />
    <path d="M118.72 80.65V80.73" stroke="black" stroke-width="0.5" />
    <path d="M118.72 80.73V80.8" stroke="black" stroke-width="0.5" />
    <path d="M118.72 80.8V80.87" stroke="black" stroke-width="0.5" />
    <path d="M118.72 80.87V80.94" stroke="black" stroke-width="0.5" />
    <path d="M118.72 80.94V81.01" stroke="black" stroke-width="0.5" />
    <path d="M118.72 81.01V81.07" stroke="black" stroke-width="0.5" />
    <path d="M118.72 81.07L118.71 81.13" stroke="black" stroke-width="0.5" />
    <path d="M118.71 81.13V81.18" stroke="black" stroke-width="0.5" />
    <path d="M118.71 81.18L118.7 81.23" stroke="black" stroke-width="0.5" />
    <path d="M118.7 81.23V81.28" stroke="black" stroke-width="0.5" />
    <path d="M118.7 81.28L118.69 81.33" stroke="black" stroke-width="0.5" />
    <path d="M118.69 81.33V81.37" stroke="black" stroke-width="0.5" />
    <path d="M118.69 81.37L118.68 81.41" stroke="black" stroke-width="0.5" />
    <path d="M118.68 81.41L118.66 81.45" stroke="black" stroke-width="0.5" />
    <path d="M118.66 81.45L118.65 81.48" stroke="black" stroke-width="0.5" />
    <path d="M118.65 81.48L118.62 81.51" stroke="black" stroke-width="0.5" />
    <path d="M118.62 81.51L118.6 81.54" stroke="black" stroke-width="0.5" />
    <path d="M118.6 81.54L118.56 81.56" stroke="black" stroke-width="0.5" />
    <path d="M118.56 81.56L118.52 81.58" stroke="black" stroke-width="0.5" />
    <path d="M118.52 81.58L118.47 81.6" stroke="black" stroke-width="0.5" />
    <path d="M118.47 81.6L118.4 81.62" stroke="black" stroke-width="0.5" />
    <path d="M118.4 81.62L118.33 81.63" stroke="black" stroke-width="0.5" />
    <path d="M118.33 81.63L118.24 81.65" stroke="black" stroke-width="0.5" />
    <path d="M118.24 81.65L118.15 81.66" stroke="black" stroke-width="0.5" />
    <path d="M118.15 81.66L118.03 81.67" stroke="black" stroke-width="0.5" />
    <path d="M118.03 81.67H117.91" stroke="black" stroke-width="0.5" />
    <path d="M117.91 81.67L117.77 81.68" stroke="black" stroke-width="0.5" />
    <path d="M117.77 81.68L117.61 81.69" stroke="black" stroke-width="0.5" />
    <path d="M117.61 81.69H117.43" stroke="black" stroke-width="0.5" />
    <path d="M117.43 81.69L117.25 81.7" stroke="black" stroke-width="0.5" />
    <path d="M117.25 81.7H117.05" stroke="black" stroke-width="0.5" />
    <path d="M117.05 81.7H116.85" stroke="black" stroke-width="0.5" />
    <path d="M116.85 81.7L116.63 81.71" stroke="black" stroke-width="0.5" />
    <path d="M116.63 81.71H116.42" stroke="black" stroke-width="0.5" />
    <path d="M116.42 81.71H116.2" stroke="black" stroke-width="0.5" />
    <path d="M116.2 81.71H115.98" stroke="black" stroke-width="0.5" />
    <path d="M115.98 81.71H115.77" stroke="black" stroke-width="0.5" />
    <path d="M115.77 81.71L115.56 81.7" stroke="black" stroke-width="0.5" />
    <path d="M115.56 81.7H115.35" stroke="black" stroke-width="0.5" />
    <path d="M115.35 81.7H115.16" stroke="black" stroke-width="0.5" />
    <path d="M115.16 81.7L114.97 81.69" stroke="black" stroke-width="0.5" />
    <path d="M114.97 81.69H114.8" stroke="black" stroke-width="0.5" />
    <path d="M114.8 81.69L114.64 81.68" stroke="black" stroke-width="0.5" />
    <path d="M114.64 81.68L114.49 81.67" stroke="black" stroke-width="0.5" />
    <path d="M114.49 81.67L114.37 81.66" stroke="black" stroke-width="0.5" />
    <path d="M114.37 81.66L114.26 81.65" stroke="black" stroke-width="0.5" />
    <path d="M114.26 81.65L114.16 81.64" stroke="black" stroke-width="0.5" />
    <path d="M114.16 81.64L114.07 81.63" stroke="black" stroke-width="0.5" />
    <path d="M114.07 81.63L114 81.61" stroke="black" stroke-width="0.5" />
    <path d="M114 81.61L113.94 81.6" stroke="black" stroke-width="0.5" />
    <path d="M113.94 81.6L113.89 81.58" stroke="black" stroke-width="0.5" />
    <path d="M113.89 81.58L113.85 81.56" stroke="black" stroke-width="0.5" />
    <path d="M113.85 81.56L113.81 81.53" stroke="black" stroke-width="0.5" />
    <path d="M113.81 81.53L113.78 81.51" stroke="black" stroke-width="0.5" />
    <path d="M113.78 81.51L113.76 81.48" stroke="black" stroke-width="0.5" />
    <path d="M113.76 81.48L113.75 81.44" stroke="black" stroke-width="0.5" />
    <path d="M113.75 81.44L113.73 81.41" stroke="black" stroke-width="0.5" />
    <path d="M113.73 81.41L113.72 81.37" stroke="black" stroke-width="0.5" />
    <path d="M113.72 81.37V81.33" stroke="black" stroke-width="0.5" />
    <path d="M113.72 81.33L113.71 81.28" stroke="black" stroke-width="0.5" />
    <path d="M113.71 81.28L113.7 81.23" stroke="black" stroke-width="0.5" />
    <path d="M113.7 81.23V81.18" stroke="black" stroke-width="0.5" />
    <path d="M113.7 81.18V81.12" stroke="black" stroke-width="0.5" />
    <path d="M113.7 81.12L113.69 81.06" stroke="black" stroke-width="0.5" />
    <path d="M113.69 81.06V81" stroke="black" stroke-width="0.5" />
    <path d="M113.69 81V80.93" stroke="black" stroke-width="0.5" />
    <path d="M113.69 80.93V80.87" stroke="black" stroke-width="0.5" />
    <path d="M113.69 80.87V80.8" stroke="black" stroke-width="0.5" />
    <path d="M113.69 80.8V80.72" stroke="black" stroke-width="0.5" />
    <path d="M113.69 80.72V80.65" stroke="black" stroke-width="0.5" />
    <path d="M113.69 80.65L113.7 80.58" stroke="black" stroke-width="0.5" />
    <path d="M113.7 80.58L113.71 80.5" stroke="black" stroke-width="0.5" />
    <path d="M113.71 80.5L113.72 80.43" stroke="black" stroke-width="0.5" />
    <path d="M113.72 80.43L113.74 80.35" stroke="black" stroke-width="0.5" />
    <path d="M113.74 80.35L113.77 80.28" stroke="black" stroke-width="0.5" />
    <path d="M113.77 80.28L113.8 80.21" stroke="black" stroke-width="0.5" />
    <path d="M113.8 80.21L113.84 80.14" stroke="black" stroke-width="0.5" />
    <path d="M113.84 80.14L113.89 80.07" stroke="black" stroke-width="0.5" />
    <path d="M113.89 80.07L113.94 80.01" stroke="black" stroke-width="0.5" />
    <path d="M113.94 80.01L114 79.95" stroke="black" stroke-width="0.5" />
    <path d="M114 79.95L114.06 79.89" stroke="black" stroke-width="0.5" />
    <path d="M114.06 79.89L114.12 79.84" stroke="black" stroke-width="0.5" />
    <path d="M114.12 79.84L114.19 79.79" stroke="black" stroke-width="0.5" />
    <path d="M114.19 79.79L114.25 79.75" stroke="black" stroke-width="0.5" />
    <path d="M114.25 79.75L114.32 79.71" stroke="black" stroke-width="0.5" />
    <path d="M114.32 79.71L114.39 79.67" stroke="black" stroke-width="0.5" />
    <path d="M114.39 79.67L114.46 79.64" stroke="black" stroke-width="0.5" />
    <path d="M114.46 79.64L114.54 79.61" stroke="black" stroke-width="0.5" />
    <path d="M114.54 79.61L114.61 79.59" stroke="black" stroke-width="0.5" />
    <path d="M114.61 79.59L114.68 79.56" stroke="black" stroke-width="0.5" />
    <path d="M114.68 79.56L114.76 79.54" stroke="black" stroke-width="0.5" />
    <path d="M114.76 79.54L114.84 79.52" stroke="black" stroke-width="0.5" />
    <path d="M114.84 79.52L114.91 79.5" stroke="black" stroke-width="0.5" />
    <path d="M114.91 79.5L114.99 79.48" stroke="black" stroke-width="0.5" />
    <path d="M114.99 79.48L115.07 79.46" stroke="black" stroke-width="0.5" />
    <path d="M115.07 79.46L115.15 79.45" stroke="black" stroke-width="0.5" />
    <path d="M115.15 79.45L115.24 79.43" stroke="black" stroke-width="0.5" />
    <path d="M115.24 79.43L115.32 79.42" stroke="black" stroke-width="0.5" />
    <path d="M115.32 79.42L115.41 79.4" stroke="black" stroke-width="0.5" />
    <path d="M115.41 79.4L115.5 79.39" stroke="black" stroke-width="0.5" />
    <path d="M115.5 79.39L115.6 79.38" stroke="black" stroke-width="0.5" />
    <path d="M115.6 79.38L115.7 79.37" stroke="black" stroke-width="0.5" />
    <path d="M115.7 79.37L115.8 79.36" stroke="black" stroke-width="0.5" />
    <path d="M115.8 79.36H115.9" stroke="black" stroke-width="0.5" />
    <path d="M115.9 79.36L116 79.35" stroke="black" stroke-width="0.5" />
    <path d="M116 79.35H116.1" stroke="black" stroke-width="0.5" />
    <path d="M116.1 79.35H116.21" stroke="black" stroke-width="0.5" />
    <path d="M116.21 79.35H116.31" stroke="black" stroke-width="0.5" />
    <path d="M116.31 79.35H116.41" stroke="black" stroke-width="0.5" />
    <path d="M116.41 79.35H116.52" stroke="black" stroke-width="0.5" />
    <path d="M116.52 79.35L116.62 79.36" stroke="black" stroke-width="0.5" />
    <path d="M116.62 79.36H116.71" stroke="black" stroke-width="0.5" />
    <path d="M116.71 79.36L116.81 79.37" stroke="black" stroke-width="0.5" />
    <path d="M116.81 79.37L116.9 79.38" stroke="black" stroke-width="0.5" />
    <path d="M116.9 79.38L116.98 79.39" stroke="black" stroke-width="0.5" />
    <path d="M116.98 79.39L117.06 79.4" stroke="black" stroke-width="0.5" />
    <path d="M117.06 79.4L117.14 79.41" stroke="black" stroke-width="0.5" />
    <path d="M117.14 79.41L117.21 79.43" stroke="black" stroke-width="0.5" />
    <path d="M117.21 79.43L117.28 79.44" stroke="black" stroke-width="0.5" />
    <path d="M117.28 79.44L117.35 79.45" stroke="black" stroke-width="0.5" />
    <path d="M117.35 79.45L117.42 79.47" stroke="black" stroke-width="0.5" />
    <path d="M117.42 79.47L117.48 79.49" stroke="black" stroke-width="0.5" />
    <path d="M117.48 79.49L117.55 79.51" stroke="black" stroke-width="0.5" />
    <path d="M117.55 79.51L117.61 79.52" stroke="black" stroke-width="0.5" />
    <path d="M117.61 79.52L117.68 79.54" stroke="black" stroke-width="0.5" />
    <path d="M117.68 79.54L117.74 79.56" stroke="black" stroke-width="0.5" />
    <path d="M117.74 79.56L117.8 79.58" stroke="black" stroke-width="0.5" />
    <path d="M117.8 79.58L117.86 79.6" stroke="black" stroke-width="0.5" />
    <path d="M117.86 79.6L117.92 79.62" stroke="black" stroke-width="0.5" />
    <path d="M117.92 79.62L117.97 79.64" stroke="black" stroke-width="0.5" />
    <path d="M117.97 79.64L118.02 79.66" stroke="black" stroke-width="0.5" />
    <path d="M130.91 81.4V81.78" stroke="black" stroke-width="0.5" />
    <path d="M129.81 81.4H130.91" stroke="black" stroke-width="0.5" />
    <path d="M129.81 81.78V81.4" stroke="black" stroke-width="0.5" />
    <path d="M130.91 81.78H129.81" stroke="black" stroke-width="0.5" />
    <path d="M133.3 77.5L133.23 77.17" stroke="black" stroke-width="0.5" />
    <path d="M133.23 77.17L133.03 76.93" stroke="black" stroke-width="0.5" />
    <path d="M133.03 76.93L132.76 76.84" stroke="black" stroke-width="0.5" />
    <path d="M127.96 76.84L127.69 76.93" stroke="black" stroke-width="0.5" />
    <path d="M127.69 76.93L127.49 77.17" stroke="black" stroke-width="0.5" />
    <path d="M127.49 77.17L127.42 77.49" stroke="black" stroke-width="0.5" />
    <path d="M127.42 80.31L127.57 80.77" stroke="black" stroke-width="0.5" />
    <path d="M127.57 80.77L127.96 80.96" stroke="black" stroke-width="0.5" />
    <path d="M132.76 80.96L133.14 80.77" stroke="black" stroke-width="0.5" />
    <path d="M133.14 80.77L133.3 80.31" stroke="black" stroke-width="0.5" />
    <path d="M133.3 77.5V80.31" stroke="black" stroke-width="0.5" />
    <path d="M127.96 76.84H132.76" stroke="black" stroke-width="0.5" />
    <path d="M127.42 80.31V77.49" stroke="black" stroke-width="0.5" />
    <path d="M132.76 80.96H127.96" stroke="black" stroke-width="0.5" />
    <path d="M133.67 76.47V82.19" stroke="black" stroke-width="0.5" />
    <path d="M127.05 76.47H133.67" stroke="black" stroke-width="0.5" />
    <path d="M127.05 82.19V76.47" stroke="black" stroke-width="0.5" />
    <path d="M133.67 82.19H127.05" stroke="black" stroke-width="0.5" />
    <path d="M136.63 57.6H135.88" stroke="black" stroke-width="0.5" />
    <path d="M1.45001 2.77002H2.95001" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 2.77002V4.43001" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 5.42999V7.42" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 8.41998V10.41" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 11.41V13.41" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 14.4V16.4" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 17.39V19.39" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 20.39V22.38" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 23.38V25.37" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 26.37V28.37" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 29.36V31.36" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 32.35V34.35" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 35.35V37.34" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 38.34V40.33" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 41.33V43.33" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 44.32V46.32" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 47.32V49.31" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 50.31V52.3" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 53.3V55.29" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 56.29V58.29" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 59.28V61.28" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 62.28V64.27" stroke="black" stroke-width="0.5" />
    <path d="M2.95001 65.27V66.93" stroke="black" stroke-width="0.5" />
    <path d="M3.75 7.16H9.24" stroke="black" stroke-width="0.5" />
    <path d="M9.23999 3.78998H12.41" stroke="black" stroke-width="0.5" />
    <path d="M12.41 3.78998V8.11998" stroke="black" stroke-width="0.5" />
    <path d="M12.41 8.12H9.23999" stroke="black" stroke-width="0.5" />
    <path d="M9.23999 8.11998V3.78998" stroke="black" stroke-width="0.5" />
    <path
      d="M12.41 8.11998L9.23999 3.78998"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M9.23999 8.11998L12.41 3.78998"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M15.58 3.78998H12.41" stroke="black" stroke-width="0.5" />
    <path d="M12.41 3.78998V8.11998" stroke="black" stroke-width="0.5" />
    <path d="M12.41 8.12H15.58" stroke="black" stroke-width="0.5" />
    <path d="M15.58 8.11998V3.78998" stroke="black" stroke-width="0.5" />
    <path d="M12.41 8.11998L15.58 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M15.58 8.11998L12.41 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M21.92 3.78998H18.75" stroke="black" stroke-width="0.5" />
    <path d="M18.75 3.78998V8.11998" stroke="black" stroke-width="0.5" />
    <path d="M18.75 8.12H21.92" stroke="black" stroke-width="0.5" />
    <path d="M21.92 8.11998V3.78998" stroke="black" stroke-width="0.5" />
    <path d="M18.75 8.11998L21.92 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M21.92 8.11998L18.75 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M15.58 3.78998H18.75" stroke="black" stroke-width="0.5" />
    <path d="M18.75 3.78998V8.11998" stroke="black" stroke-width="0.5" />
    <path d="M18.75 8.12H15.58" stroke="black" stroke-width="0.5" />
    <path d="M15.58 8.11998V3.78998" stroke="black" stroke-width="0.5" />
    <path d="M18.75 8.11998L15.58 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M15.58 8.11998L18.75 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M28.26 3.78998H31.44" stroke="black" stroke-width="0.5" />
    <path d="M31.44 3.78998V8.11998" stroke="black" stroke-width="0.5" />
    <path d="M31.44 8.12H28.26" stroke="black" stroke-width="0.5" />
    <path d="M28.26 8.11998V3.78998" stroke="black" stroke-width="0.5" />
    <path d="M31.44 8.11998L28.26 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M28.26 8.11998L31.44 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M21.92 3.78998H25.09" stroke="black" stroke-width="0.5" />
    <path d="M25.09 3.78998V8.11998" stroke="black" stroke-width="0.5" />
    <path d="M25.09 8.12H21.92" stroke="black" stroke-width="0.5" />
    <path d="M21.92 8.11998V3.78998" stroke="black" stroke-width="0.5" />
    <path d="M25.09 8.11998L21.92 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M21.92 8.11998L25.09 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M28.26 3.78998H25.09" stroke="black" stroke-width="0.5" />
    <path d="M25.09 3.78998V8.11998" stroke="black" stroke-width="0.5" />
    <path d="M25.09 8.12H28.26" stroke="black" stroke-width="0.5" />
    <path d="M28.26 8.11998V3.78998" stroke="black" stroke-width="0.5" />
    <path d="M25.09 8.11998L28.26 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M28.26 8.11998L25.09 3.78998" stroke="black" stroke-width="0.5" />
    <path d="M31.44 6.97998H34.07" stroke="black" stroke-width="0.5" />
    <path d="M34.07 6.98V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M23.01 2.77002H22.43" stroke="black" stroke-width="0.5" />
    <path d="M22.43 2.77V1.63" stroke="black" stroke-width="0.5" />
    <path d="M22.43 1.63H27.62" stroke="black" stroke-width="0.5" />
    <path d="M27.62 1.63V2.77" stroke="black" stroke-width="0.5" />
    <path d="M27.62 2.77002H27.04" stroke="black" stroke-width="0.5" />
    <path d="M27.04 2.77002V3.43001" stroke="black" stroke-width="0.5" />
    <path d="M27.04 3.42999H23.01" stroke="black" stroke-width="0.5" />
    <path d="M23.01 3.43001V2.77002" stroke="black" stroke-width="0.5" />
    <path d="M223.49 2.77002H222.92" stroke="black" stroke-width="0.5" />
    <path d="M222.92 2.77V1.63" stroke="black" stroke-width="0.5" />
    <path d="M222.92 1.63H228.11" stroke="black" stroke-width="0.5" />
    <path d="M228.11 1.63V2.77" stroke="black" stroke-width="0.5" />
    <path d="M228.11 2.77002H227.53" stroke="black" stroke-width="0.5" />
    <path d="M227.53 2.77002V3.43001" stroke="black" stroke-width="0.5" />
    <path d="M227.53 3.42999H223.49" stroke="black" stroke-width="0.5" />
    <path d="M223.49 3.43001V2.77002" stroke="black" stroke-width="0.5" />
    <path d="M29.05 14.07V14.31" stroke="black" stroke-width="0.5" />
    <path d="M30.07 10.46H34.07" stroke="black" stroke-width="0.5" />
    <path d="M34.07 10.46V14.84" stroke="black" stroke-width="0.5" />
    <path d="M34.07 14.84H30.07" stroke="black" stroke-width="0.5" />
    <path d="M30.07 14.84V10.46" stroke="black" stroke-width="0.5" />
    <path d="M29.05 14.31H34.07" stroke="black" stroke-width="0.5" />
    <path d="M29.05 9.34H34.07" stroke="black" stroke-width="0.5" />
    <path
      d="M34.07 14.31C34.7032 13.6375 35.0558 12.7487 35.0558 11.825C35.0558 10.9013 34.7032 10.0125 34.07 9.34"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M29.05 11.11C28.8537 11.11 28.6655 11.1879 28.5267 11.3267C28.388 11.4655 28.31 11.6537 28.31 11.85C28.31 12.0463 28.388 12.2345 28.5267 12.3732C28.6655 12.512 28.8537 12.59 29.05 12.59"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M29.05 12.59C28.8537 12.59 28.6655 12.668 28.5267 12.8067C28.388 12.9455 28.31 13.1337 28.31 13.33C28.31 13.5263 28.388 13.7145 28.5267 13.8533C28.6655 13.992 28.8537 14.07 29.05 14.07"
      stroke="black"
      stroke-width="0.5"
    />
    <path d="M33.21 12.39L33.05 13.01" stroke="black" stroke-width="0.5" />
    <path d="M33.05 13.01L32.59 13.47" stroke="black" stroke-width="0.5" />
    <path d="M32.59 13.47L31.97 13.63" stroke="black" stroke-width="0.5" />
    <path d="M31.97 13.63L31.35 13.47" stroke="black" stroke-width="0.5" />
    <path d="M31.35 13.47L30.89 13.01" stroke="black" stroke-width="0.5" />
    <path d="M30.89 13.01L30.73 12.39" stroke="black" stroke-width="0.5" />
    <path d="M30.73 12.39L30.89 11.77" stroke="black" stroke-width="0.5" />
    <path d="M30.89 11.77L31.35 11.32" stroke="black" stroke-width="0.5" />
    <path d="M31.35 11.32L31.97 11.15" stroke="black" stroke-width="0.5" />
    <path d="M31.97 11.15L32.59 11.32" stroke="black" stroke-width="0.5" />
    <path d="M32.59 11.32L33.05 11.77" stroke="black" stroke-width="0.5" />
    <path d="M33.05 11.77L33.21 12.39" stroke="black" stroke-width="0.5" />
    <path d="M30.73 12.39H34.07" stroke="black" stroke-width="0.5" />
    <path d="M3.75 55.73H8.53" stroke="black" stroke-width="0.5" />
    <path d="M7.12 55.73V57.38" stroke="black" stroke-width="0.5" />
    <path d="M7.12 57.38H3.75" stroke="black" stroke-width="0.5" />
    <path d="M9.96997 76.07V70.06" stroke="black" stroke-width="0.5" />
    <path d="M6.66998 70.06H34.07" stroke="black" stroke-width="0.5" />
    <path d="M8.28003 68.42V71.66" stroke="black" stroke-width="0.5" />
    <path d="M9.96997 73.07H10.89" stroke="black" stroke-width="0.5" />
    <path d="M11.56 73.07H12.89" stroke="black" stroke-width="0.5" />
    <path d="M13.55 73.07H14.88" stroke="black" stroke-width="0.5" />
    <path d="M15.55 73.07H16.88" stroke="black" stroke-width="0.5" />
    <path d="M17.54 73.07H18.87" stroke="black" stroke-width="0.5" />
    <path d="M19.54 73.07H20.87" stroke="black" stroke-width="0.5" />
    <path d="M21.53 73.07H22.86" stroke="black" stroke-width="0.5" />
    <path d="M23.52 73.07H24.85" stroke="black" stroke-width="0.5" />
    <path d="M25.52 73.07H26.85" stroke="black" stroke-width="0.5" />
    <path d="M27.51 73.07H28.84" stroke="black" stroke-width="0.5" />
    <path d="M29.51 73.07H30.84" stroke="black" stroke-width="0.5" />
    <path d="M31.5 73.07H32.83" stroke="black" stroke-width="0.5" />
    <path d="M33.5 73.07H34.42" stroke="black" stroke-width="0.5" />
    <path d="M29.48 74.76V75.09" stroke="black" stroke-width="0.5" />
    <path d="M30.46 74.76H29.48" stroke="black" stroke-width="0.5" />
    <path d="M30.46 75.09V74.76" stroke="black" stroke-width="0.5" />
    <path d="M29.48 75.09H30.46" stroke="black" stroke-width="0.5" />
    <path d="M27.37 71.31L27.44 71.02" stroke="black" stroke-width="0.5" />
    <path d="M27.44 71.02L27.61 70.81" stroke="black" stroke-width="0.5" />
    <path d="M27.61 70.81L27.85 70.73" stroke="black" stroke-width="0.5" />
    <path d="M32.09 70.73L32.33 70.81" stroke="black" stroke-width="0.5" />
    <path d="M32.33 70.81L32.51 71.02" stroke="black" stroke-width="0.5" />
    <path d="M32.51 71.02L32.57 71.31" stroke="black" stroke-width="0.5" />
    <path d="M32.57 73.79L32.43 74.2" stroke="black" stroke-width="0.5" />
    <path d="M32.43 74.2L32.09 74.37" stroke="black" stroke-width="0.5" />
    <path d="M27.85 74.37L27.51 74.2" stroke="black" stroke-width="0.5" />
    <path d="M27.51 74.2L27.37 73.79" stroke="black" stroke-width="0.5" />
    <path d="M27.37 71.31V73.79" stroke="black" stroke-width="0.5" />
    <path d="M32.09 70.73H27.85" stroke="black" stroke-width="0.5" />
    <path d="M32.57 73.79V71.31" stroke="black" stroke-width="0.5" />
    <path d="M27.85 74.37H32.09" stroke="black" stroke-width="0.5" />
    <path d="M27.05 70.4V75.45" stroke="black" stroke-width="0.5" />
    <path d="M32.9 70.4H27.05" stroke="black" stroke-width="0.5" />
    <path d="M32.9 75.45V70.4" stroke="black" stroke-width="0.5" />
    <path d="M27.05 75.45H32.9" stroke="black" stroke-width="0.5" />
    <path d="M28.38 77.4V93.6" stroke="black" stroke-width="0.5" />
    <path d="M28.38 93.6H34.07" stroke="black" stroke-width="0.5" />
    <path d="M28.38 87.13H34.07" stroke="black" stroke-width="0.5" />
    <path d="M30.59 93.6V92.81" stroke="black" stroke-width="0.5" />
    <path d="M30.59 92.15V90.82" stroke="black" stroke-width="0.5" />
    <path d="M30.59 90.15V88.82" stroke="black" stroke-width="0.5" />
    <path d="M30.59 88.16V86.83" stroke="black" stroke-width="0.5" />
    <path d="M30.59 86.16V84.83" stroke="black" stroke-width="0.5" />
    <path d="M30.59 84.17V82.84" stroke="black" stroke-width="0.5" />
    <path d="M30.59 82.17V80.84" stroke="black" stroke-width="0.5" />
    <path d="M30.59 80.18V78.85" stroke="black" stroke-width="0.5" />
    <path d="M30.59 78.18V77.4" stroke="black" stroke-width="0.5" />
    <path d="M28.28 147.38V163.57" stroke="black" stroke-width="0.5" />
    <path d="M28.28 163.57H33.97" stroke="black" stroke-width="0.5" />
    <path d="M28.28 154.23H33.97" stroke="black" stroke-width="0.5" />
    <path d="M30.49 163.57V162.79" stroke="black" stroke-width="0.5" />
    <path d="M30.49 162.13V160.8" stroke="black" stroke-width="0.5" />
    <path d="M30.49 160.13V158.8" stroke="black" stroke-width="0.5" />
    <path d="M30.49 158.14V156.81" stroke="black" stroke-width="0.5" />
    <path d="M30.49 156.14V154.81" stroke="black" stroke-width="0.5" />
    <path d="M30.49 154.15V152.82" stroke="black" stroke-width="0.5" />
    <path d="M30.49 152.15V150.82" stroke="black" stroke-width="0.5" />
    <path d="M30.49 150.16V148.83" stroke="black" stroke-width="0.5" />
    <path d="M30.49 148.16V147.38" stroke="black" stroke-width="0.5" />
    <path d="M28.28 147.38H33.97" stroke="black" stroke-width="0.5" />
    <path d="M60.6 164.9H62.69" stroke="black" stroke-width="0.5" />
    <path d="M57 148.71V164.9" stroke="black" stroke-width="0.5" />
    <path d="M57 155.56H62.69" stroke="black" stroke-width="0.5" />
    <path d="M59.21 164.9V164.12" stroke="black" stroke-width="0.5" />
    <path d="M59.21 163.46V162.13" stroke="black" stroke-width="0.5" />
    <path d="M59.21 161.46V160.13" stroke="black" stroke-width="0.5" />
    <path d="M59.21 159.47V158.14" stroke="black" stroke-width="0.5" />
    <path d="M59.21 157.47V156.14" stroke="black" stroke-width="0.5" />
    <path d="M59.21 155.48V154.15" stroke="black" stroke-width="0.5" />
    <path d="M59.21 153.48V152.15" stroke="black" stroke-width="0.5" />
    <path d="M59.21 151.49V150.16" stroke="black" stroke-width="0.5" />
    <path d="M59.21 149.49V148.71" stroke="black" stroke-width="0.5" />
    <path d="M57 148.71H62.69" stroke="black" stroke-width="0.5" />
    <path d="M208.34 161.71H206.24" stroke="black" stroke-width="0.5" />
    <path d="M211.93 145.52V161.71" stroke="black" stroke-width="0.5" />
    <path d="M211.93 152.37H206.24" stroke="black" stroke-width="0.5" />
    <path d="M209.72 161.71V160.93" stroke="black" stroke-width="0.5" />
    <path d="M209.72 160.26V158.93" stroke="black" stroke-width="0.5" />
    <path d="M209.72 158.27V156.94" stroke="black" stroke-width="0.5" />
    <path d="M209.72 156.27V154.95" stroke="black" stroke-width="0.5" />
    <path d="M209.72 154.28V152.95" stroke="black" stroke-width="0.5" />
    <path d="M209.72 152.29V150.96" stroke="black" stroke-width="0.5" />
    <path d="M209.72 150.29V148.96" stroke="black" stroke-width="0.5" />
    <path d="M209.72 148.3V146.97" stroke="black" stroke-width="0.5" />
    <path d="M209.72 146.3V145.52" stroke="black" stroke-width="0.5" />
    <path d="M211.93 145.52H206.24" stroke="black" stroke-width="0.5" />
    <path d="M236.8 160.38H234.7" stroke="black" stroke-width="0.5" />
    <path d="M240.39 144.19V160.38" stroke="black" stroke-width="0.5" />
    <path d="M240.39 160.38H234.7" stroke="black" stroke-width="0.5" />
    <path d="M240.39 151.04H234.7" stroke="black" stroke-width="0.5" />
    <path d="M238.18 160.38V159.6" stroke="black" stroke-width="0.5" />
    <path d="M238.18 158.93V157.6" stroke="black" stroke-width="0.5" />
    <path d="M238.18 156.94V155.61" stroke="black" stroke-width="0.5" />
    <path d="M238.18 154.95V153.62" stroke="black" stroke-width="0.5" />
    <path d="M238.18 152.95V151.62" stroke="black" stroke-width="0.5" />
    <path d="M238.18 150.96V149.63" stroke="black" stroke-width="0.5" />
    <path d="M238.18 148.96V147.63" stroke="black" stroke-width="0.5" />
    <path d="M238.18 146.97V145.64" stroke="black" stroke-width="0.5" />
    <path d="M238.18 144.97V144.19" stroke="black" stroke-width="0.5" />
    <path d="M240.39 144.19H234.7" stroke="black" stroke-width="0.5" />
    <path d="M262.11 100.24V84.05" stroke="black" stroke-width="0.5" />
    <path d="M262.11 93.39H267.8" stroke="black" stroke-width="0.5" />
    <path d="M264.32 84.05V84.83" stroke="black" stroke-width="0.5" />
    <path d="M264.32 85.5V86.83" stroke="black" stroke-width="0.5" />
    <path d="M264.32 87.49V88.82" stroke="black" stroke-width="0.5" />
    <path d="M264.32 89.49V90.82" stroke="black" stroke-width="0.5" />
    <path d="M264.32 91.48V92.81" stroke="black" stroke-width="0.5" />
    <path d="M264.32 93.48V94.81" stroke="black" stroke-width="0.5" />
    <path d="M264.32 95.47V96.8" stroke="black" stroke-width="0.5" />
    <path d="M264.32 97.47V98.8" stroke="black" stroke-width="0.5" />
    <path d="M264.32 99.46V100.24" stroke="black" stroke-width="0.5" />
    <path d="M262.11 100.24H267.8" stroke="black" stroke-width="0.5" />
    <path d="M239.71 66.53V82.72" stroke="black" stroke-width="0.5" />
    <path d="M239.71 73.38H234.02" stroke="black" stroke-width="0.5" />
    <path d="M237.5 82.72V81.94" stroke="black" stroke-width="0.5" />
    <path d="M237.5 81.27V79.94" stroke="black" stroke-width="0.5" />
    <path d="M237.5 79.28V77.95" stroke="black" stroke-width="0.5" />
    <path d="M237.5 77.28V75.95" stroke="black" stroke-width="0.5" />
    <path d="M237.5 75.29V73.96" stroke="black" stroke-width="0.5" />
    <path d="M237.5 73.29V71.97" stroke="black" stroke-width="0.5" />
    <path d="M237.5 71.3V69.97" stroke="black" stroke-width="0.5" />
    <path d="M237.5 69.31V67.98" stroke="black" stroke-width="0.5" />
    <path d="M237.5 67.31V66.53" stroke="black" stroke-width="0.5" />
    <path d="M239.71 66.53H234.02" stroke="black" stroke-width="0.5" />
    <path d="M292.47 51.57V71.52" stroke="black" stroke-width="0.5" />
    <path d="M292.47 71.52H285.89" stroke="black" stroke-width="0.5" />
    <path d="M292.47 56.3H291.5" stroke="black" stroke-width="0.5" />
    <path d="M290.84 56.3H289.51" stroke="black" stroke-width="0.5" />
    <path d="M288.85 56.3H287.52" stroke="black" stroke-width="0.5" />
    <path d="M286.85 56.3H285.89" stroke="black" stroke-width="0.5" />
    <path d="M289.37 71.52V70.85" stroke="black" stroke-width="0.5" />
    <path d="M289.37 70.19V68.86" stroke="black" stroke-width="0.5" />
    <path d="M289.37 68.19V66.86" stroke="black" stroke-width="0.5" />
    <path d="M289.37 66.2V64.87" stroke="black" stroke-width="0.5" />
    <path d="M289.37 64.2V62.87" stroke="black" stroke-width="0.5" />
    <path d="M289.37 62.21V60.88" stroke="black" stroke-width="0.5" />
    <path d="M289.37 60.21V58.88" stroke="black" stroke-width="0.5" />
    <path d="M289.37 58.22V56.89" stroke="black" stroke-width="0.5" />
    <path d="M289.37 56.22V54.9" stroke="black" stroke-width="0.5" />
    <path d="M289.37 54.23V52.9" stroke="black" stroke-width="0.5" />
    <path d="M289.37 52.24V51.57" stroke="black" stroke-width="0.5" />
    <path d="M292.47 51.57H285.89" stroke="black" stroke-width="0.5" />
    <path d="M292.47 66.96H291.5" stroke="black" stroke-width="0.5" />
    <path d="M290.84 66.96H289.51" stroke="black" stroke-width="0.5" />
    <path d="M288.85 66.96H287.52" stroke="black" stroke-width="0.5" />
    <path d="M286.85 66.96H285.89" stroke="black" stroke-width="0.5" />
    <path d="M292.47 27.63V50.24" stroke="black" stroke-width="0.5" />
    <path d="M292.47 50.24H285.89" stroke="black" stroke-width="0.5" />
    <path d="M292.47 32.36H291.5" stroke="black" stroke-width="0.5" />
    <path d="M290.84 32.36H289.51" stroke="black" stroke-width="0.5" />
    <path d="M288.85 32.36H287.52" stroke="black" stroke-width="0.5" />
    <path d="M286.85 32.36H285.89" stroke="black" stroke-width="0.5" />
    <path d="M289.37 50.24V49.24" stroke="black" stroke-width="0.5" />
    <path d="M289.37 48.58V47.25" stroke="black" stroke-width="0.5" />
    <path d="M289.37 46.58V45.25" stroke="black" stroke-width="0.5" />
    <path d="M289.37 44.59V43.26" stroke="black" stroke-width="0.5" />
    <path d="M289.37 42.59V41.26" stroke="black" stroke-width="0.5" />
    <path d="M289.37 40.6V39.27" stroke="black" stroke-width="0.5" />
    <path d="M289.37 38.6V37.28" stroke="black" stroke-width="0.5" />
    <path d="M289.37 36.61V35.28" stroke="black" stroke-width="0.5" />
    <path d="M289.37 34.62V33.29" stroke="black" stroke-width="0.5" />
    <path d="M289.37 32.62V31.29" stroke="black" stroke-width="0.5" />
    <path d="M289.37 30.63V29.3" stroke="black" stroke-width="0.5" />
    <path d="M289.37 28.63V27.63" stroke="black" stroke-width="0.5" />
    <path d="M292.47 27.63H285.89" stroke="black" stroke-width="0.5" />
    <path d="M292.47 45.68H291.5" stroke="black" stroke-width="0.5" />
    <path d="M290.84 45.68H289.51" stroke="black" stroke-width="0.5" />
    <path d="M288.85 45.68H287.52" stroke="black" stroke-width="0.5" />
    <path d="M286.85 45.68H285.89" stroke="black" stroke-width="0.5" />
    <path d="M244.93 19.62V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M244.93 12.77H250.62" stroke="black" stroke-width="0.5" />
    <path d="M247.14 3.42999V4.21001" stroke="black" stroke-width="0.5" />
    <path d="M247.14 4.88V6.21001" stroke="black" stroke-width="0.5" />
    <path d="M247.14 6.87V8.2" stroke="black" stroke-width="0.5" />
    <path d="M247.14 8.87V10.2" stroke="black" stroke-width="0.5" />
    <path d="M247.14 10.86V12.19" stroke="black" stroke-width="0.5" />
    <path d="M247.14 12.86V14.19" stroke="black" stroke-width="0.5" />
    <path d="M247.14 14.85V16.18" stroke="black" stroke-width="0.5" />
    <path d="M247.14 16.85V18.18" stroke="black" stroke-width="0.5" />
    <path d="M247.14 18.84V19.62" stroke="black" stroke-width="0.5" />
    <path d="M244.93 19.62H250.62" stroke="black" stroke-width="0.5" />
    <path d="M188.02 19.62V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M188.02 12.77H193.71" stroke="black" stroke-width="0.5" />
    <path d="M190.22 3.42999V4.21001" stroke="black" stroke-width="0.5" />
    <path d="M190.22 4.88V6.21001" stroke="black" stroke-width="0.5" />
    <path d="M190.22 6.87V8.2" stroke="black" stroke-width="0.5" />
    <path d="M190.22 8.87V10.2" stroke="black" stroke-width="0.5" />
    <path d="M190.22 10.86V12.19" stroke="black" stroke-width="0.5" />
    <path d="M190.22 12.86V14.19" stroke="black" stroke-width="0.5" />
    <path d="M190.22 14.85V16.18" stroke="black" stroke-width="0.5" />
    <path d="M190.22 16.85V18.18" stroke="black" stroke-width="0.5" />
    <path d="M190.22 18.84V19.62" stroke="black" stroke-width="0.5" />
    <path d="M188.02 19.62H193.71" stroke="black" stroke-width="0.5" />
    <path d="M159.56 19.62V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M159.56 12.77H165.25" stroke="black" stroke-width="0.5" />
    <path d="M161.77 3.42999V4.21001" stroke="black" stroke-width="0.5" />
    <path d="M161.77 4.88V6.21001" stroke="black" stroke-width="0.5" />
    <path d="M161.77 6.87V8.2" stroke="black" stroke-width="0.5" />
    <path d="M161.77 8.87V10.2" stroke="black" stroke-width="0.5" />
    <path d="M161.77 10.86V12.19" stroke="black" stroke-width="0.5" />
    <path d="M161.77 12.86V14.19" stroke="black" stroke-width="0.5" />
    <path d="M161.77 14.85V16.18" stroke="black" stroke-width="0.5" />
    <path d="M161.77 16.85V18.18" stroke="black" stroke-width="0.5" />
    <path d="M161.77 18.84V19.62" stroke="black" stroke-width="0.5" />
    <path d="M159.56 19.62H165.25" stroke="black" stroke-width="0.5" />
    <path d="M131.1 19.62V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M131.1 12.77H136.79" stroke="black" stroke-width="0.5" />
    <path d="M133.31 3.42999V4.21001" stroke="black" stroke-width="0.5" />
    <path d="M133.31 4.88V6.21001" stroke="black" stroke-width="0.5" />
    <path d="M133.31 6.87V8.2" stroke="black" stroke-width="0.5" />
    <path d="M133.31 8.87V10.2" stroke="black" stroke-width="0.5" />
    <path d="M133.31 10.86V12.19" stroke="black" stroke-width="0.5" />
    <path d="M133.31 12.86V14.19" stroke="black" stroke-width="0.5" />
    <path d="M133.31 14.85V16.18" stroke="black" stroke-width="0.5" />
    <path d="M133.31 16.85V18.18" stroke="black" stroke-width="0.5" />
    <path d="M133.31 18.84V19.62" stroke="black" stroke-width="0.5" />
    <path d="M131.1 19.62H136.79" stroke="black" stroke-width="0.5" />
    <path d="M74.19 19.62V3.42999" stroke="black" stroke-width="0.5" />
    <path d="M74.19 12.77H79.87" stroke="black" stroke-width="0.5" />
    <path d="M76.39 3.42999V4.21001" stroke="black" stroke-width="0.5" />
    <path d="M76.39 4.88V6.21001" stroke="black" stroke-width="0.5" />
    <path d="M76.39 6.87V8.2" stroke="black" stroke-width="0.5" />
    <path d="M76.39 8.87V10.2" stroke="black" stroke-width="0.5" />
    <path d="M76.39 10.86V12.19" stroke="black" stroke-width="0.5" />
    <path d="M76.39 12.86V14.19" stroke="black" stroke-width="0.5" />
    <path d="M76.39 14.85V16.18" stroke="black" stroke-width="0.5" />
    <path d="M76.39 16.85V18.18" stroke="black" stroke-width="0.5" />
    <path d="M76.39 18.84V19.62" stroke="black" stroke-width="0.5" />
    <path d="M74.19 19.62H79.87" stroke="black" stroke-width="0.5" />
    <path d="M35.4 91.24H36.12" stroke="black" stroke-width="0.5" />
    <path d="M36.12 91.24V92.43" stroke="black" stroke-width="0.5" />
    <path d="M36.12 93.09V94.42" stroke="black" stroke-width="0.5" />
    <path d="M36.12 95.09V96.42" stroke="black" stroke-width="0.5" />
    <path d="M36.12 97.08V98.41" stroke="black" stroke-width="0.5" />
    <path d="M36.12 99.08V100.41" stroke="black" stroke-width="0.5" />
    <path d="M36.12 101.07V102.4" stroke="black" stroke-width="0.5" />
    <path d="M36.12 103.07V104.4" stroke="black" stroke-width="0.5" />
    <path d="M36.12 105.06V106.25" stroke="black" stroke-width="0.5" />
    <path d="M36.12 106.25H35.4" stroke="black" stroke-width="0.5" />
    <path d="M55.83 104.53H75.51" stroke="black" stroke-width="0.5" />
    <path d="M55.83 104.53V105.71" stroke="black" stroke-width="0.5" />
    <path d="M57.57 38.27H56.99" stroke="black" stroke-width="0.5" />
    <path d="M56.99 38.27V39.41" stroke="black" stroke-width="0.5" />
    <path d="M56.99 39.41H62.18" stroke="black" stroke-width="0.5" />
    <path d="M62.18 39.41V38.27" stroke="black" stroke-width="0.5" />
    <path d="M62.18 38.27H61.6" stroke="black" stroke-width="0.5" />
    <path d="M61.6 38.27V37.61" stroke="black" stroke-width="0.5" />
    <path d="M61.6 37.61H57.57" stroke="black" stroke-width="0.5" />
    <path d="M57.57 37.61V38.27" stroke="black" stroke-width="0.5" />
    <path d="M78.7 72.88H73.16" stroke="black" stroke-width="0.5" />
    <path d="M73.16 72.88V93.89" stroke="black" stroke-width="0.5" />
    <path d="M73.16 93.89H78.7" stroke="black" stroke-width="0.5" />
    <path d="M78.7 93.89V72.88" stroke="black" stroke-width="0.5" />
    <path d="M75.51 72.88V74.08" stroke="black" stroke-width="0.5" />
    <path d="M75.51 74.74V76.07" stroke="black" stroke-width="0.5" />
    <path d="M75.51 76.74V78.07" stroke="black" stroke-width="0.5" />
    <path d="M75.51 78.73V80.06" stroke="black" stroke-width="0.5" />
    <path d="M75.51 80.73V82.06" stroke="black" stroke-width="0.5" />
    <path d="M75.51 82.72V84.05" stroke="black" stroke-width="0.5" />
    <path d="M75.51 84.72V86.05" stroke="black" stroke-width="0.5" />
    <path d="M75.51 86.71V88.04" stroke="black" stroke-width="0.5" />
    <path d="M75.51 88.71V90.04" stroke="black" stroke-width="0.5" />
    <path d="M75.51 90.7V92.03" stroke="black" stroke-width="0.5" />
    <path d="M75.51 92.69V93.89" stroke="black" stroke-width="0.5" />
    <path d="M93.89 61.28H110.08" stroke="black" stroke-width="0.5" />
    <path d="M100.74 61.28V55.59" stroke="black" stroke-width="0.5" />
    <path d="M110.08 59.07H109.3" stroke="black" stroke-width="0.5" />
    <path d="M108.64 59.07H107.31" stroke="black" stroke-width="0.5" />
    <path d="M106.64 59.07H105.31" stroke="black" stroke-width="0.5" />
    <path d="M104.65 59.07H103.32" stroke="black" stroke-width="0.5" />
    <path d="M102.65 59.07H101.32" stroke="black" stroke-width="0.5" />
    <path d="M100.66 59.07H99.33" stroke="black" stroke-width="0.5" />
    <path d="M98.66 59.07H97.33" stroke="black" stroke-width="0.5" />
    <path d="M96.67 59.07H95.34" stroke="black" stroke-width="0.5" />
    <path d="M94.67 59.07H93.89" stroke="black" stroke-width="0.5" />
    <path d="M93.89 61.28V55.59" stroke="black" stroke-width="0.5" />
    <path d="M93.89 105.49H110.08" stroke="black" stroke-width="0.5" />
    <path d="M100.74 105.49V111.18" stroke="black" stroke-width="0.5" />
    <path d="M110.08 107.7H109.3" stroke="black" stroke-width="0.5" />
    <path d="M108.64 107.7H107.31" stroke="black" stroke-width="0.5" />
    <path d="M106.64 107.7H105.31" stroke="black" stroke-width="0.5" />
    <path d="M104.65 107.7H103.32" stroke="black" stroke-width="0.5" />
    <path d="M102.65 107.7H101.32" stroke="black" stroke-width="0.5" />
    <path d="M100.66 107.7H99.33" stroke="black" stroke-width="0.5" />
    <path d="M98.66 107.7H97.33" stroke="black" stroke-width="0.5" />
    <path d="M96.67 107.7H95.34" stroke="black" stroke-width="0.5" />
    <path d="M94.67 107.7H93.89" stroke="black" stroke-width="0.5" />
    <path d="M93.89 105.49V111.18" stroke="black" stroke-width="0.5" />
    <path d="M175.48 61.28H159.29" stroke="black" stroke-width="0.5" />
    <path d="M168.63 61.28V55.59" stroke="black" stroke-width="0.5" />
    <path d="M159.29 59.07H160.07" stroke="black" stroke-width="0.5" />
    <path d="M160.74 59.07H162.07" stroke="black" stroke-width="0.5" />
    <path d="M162.73 59.07H164.06" stroke="black" stroke-width="0.5" />
    <path d="M164.72 59.07H166.05" stroke="black" stroke-width="0.5" />
    <path d="M166.72 59.07H168.05" stroke="black" stroke-width="0.5" />
    <path d="M168.71 59.07H170.04" stroke="black" stroke-width="0.5" />
    <path d="M170.71 59.07H172.04" stroke="black" stroke-width="0.5" />
    <path d="M172.7 59.07H174.03" stroke="black" stroke-width="0.5" />
    <path d="M174.7 59.07H175.48" stroke="black" stroke-width="0.5" />
    <path d="M175.48 61.28V55.59" stroke="black" stroke-width="0.5" />
    <path d="M175.48 105.49H159.29" stroke="black" stroke-width="0.5" />
    <path d="M168.63 105.49V111.18" stroke="black" stroke-width="0.5" />
    <path d="M159.29 107.7H160.07" stroke="black" stroke-width="0.5" />
    <path d="M160.74 107.7H162.07" stroke="black" stroke-width="0.5" />
    <path d="M162.73 107.7H164.06" stroke="black" stroke-width="0.5" />
    <path d="M164.72 107.7H166.05" stroke="black" stroke-width="0.5" />
    <path d="M166.72 107.7H168.05" stroke="black" stroke-width="0.5" />
    <path d="M168.71 107.7H170.04" stroke="black" stroke-width="0.5" />
    <path d="M170.71 107.7H172.04" stroke="black" stroke-width="0.5" />
    <path d="M172.7 107.7H174.03" stroke="black" stroke-width="0.5" />
    <path d="M174.7 107.7H175.48" stroke="black" stroke-width="0.5" />
    <path d="M175.48 105.49V111.18" stroke="black" stroke-width="0.5" />
    <path d="M134.55 55.59H133.49" stroke="black" stroke-width="0.5" />
    <path d="M110.08 66.97H106.61" stroke="black" stroke-width="0.5" />
    <path d="M106.61 66.97V73.06" stroke="black" stroke-width="0.5" />
    <path d="M106.61 73.06H110.08" stroke="black" stroke-width="0.5" />
    <path d="M107.5 70.02H110.99" stroke="black" stroke-width="0.5" />
    <path d="M110.99 71.65V68.38" stroke="black" stroke-width="0.5" />
    <path d="M107.5 71.65V68.38" stroke="black" stroke-width="0.5" />
    <path d="M130.91 85.37V84.99" stroke="black" stroke-width="0.5" />
    <path d="M129.81 85.37H130.91" stroke="black" stroke-width="0.5" />
    <path d="M129.81 84.99V85.37" stroke="black" stroke-width="0.5" />
    <path d="M130.91 84.99H129.81" stroke="black" stroke-width="0.5" />
    <path d="M133.3 89.28L133.23 89.6" stroke="black" stroke-width="0.5" />
    <path d="M133.23 89.6L133.03 89.84" stroke="black" stroke-width="0.5" />
    <path d="M133.03 89.84L132.76 89.93" stroke="black" stroke-width="0.5" />
    <path d="M127.96 89.93L127.69 89.84" stroke="black" stroke-width="0.5" />
    <path d="M127.69 89.84L127.49 89.6" stroke="black" stroke-width="0.5" />
    <path d="M127.49 89.6L127.42 89.28" stroke="black" stroke-width="0.5" />
    <path d="M127.42 86.46L127.57 86" stroke="black" stroke-width="0.5" />
    <path d="M127.57 86L127.96 85.81" stroke="black" stroke-width="0.5" />
    <path d="M132.76 85.81L133.14 86" stroke="black" stroke-width="0.5" />
    <path d="M133.14 86L133.3 86.46" stroke="black" stroke-width="0.5" />
    <path d="M133.3 89.28V86.46" stroke="black" stroke-width="0.5" />
    <path d="M127.96 89.93H132.76" stroke="black" stroke-width="0.5" />
    <path d="M127.42 86.46V89.28" stroke="black" stroke-width="0.5" />
    <path d="M132.76 85.81H127.96" stroke="black" stroke-width="0.5" />
    <path d="M133.67 90.31V84.58" stroke="black" stroke-width="0.5" />
    <path d="M127.05 90.31H133.67" stroke="black" stroke-width="0.5" />
    <path d="M127.05 84.58V90.31" stroke="black" stroke-width="0.5" />
    <path d="M133.67 84.58H127.05" stroke="black" stroke-width="0.5" />
    <path d="M112.74 84.78H112.21" stroke="black" stroke-width="0.5" />
    <path d="M111.61 85.91V86.44" stroke="black" stroke-width="0.5" />
    <path d="M111.61 98.01V98.55" stroke="black" stroke-width="0.5" />
    <path d="M122.32 84.78H121.79" stroke="black" stroke-width="0.5" />
    <path d="M112.19 86.23V86.24" stroke="black" stroke-width="0.5" />
    <path d="M112.19 86.24L112.2 86.26" stroke="black" stroke-width="0.5" />
    <path d="M112.2 86.26L112.21 86.27" stroke="black" stroke-width="0.5" />
    <path d="M112.21 86.27L112.22 86.29" stroke="black" stroke-width="0.5" />
    <path d="M112.22 86.29V86.3" stroke="black" stroke-width="0.5" />
    <path d="M112.22 86.3L112.23 86.32" stroke="black" stroke-width="0.5" />
    <path d="M112.23 86.32V86.34" stroke="black" stroke-width="0.5" />
    <path d="M112.23 86.34L112.24 86.35" stroke="black" stroke-width="0.5" />
    <path d="M112.24 86.35V86.37" stroke="black" stroke-width="0.5" />
    <path d="M112.24 86.37V86.39" stroke="black" stroke-width="0.5" />
    <path d="M112.24 86.39V86.41" stroke="black" stroke-width="0.5" />
    <path d="M112.24 86.41L112.25 86.42" stroke="black" stroke-width="0.5" />
    <path d="M112.25 86.42V86.44" stroke="black" stroke-width="0.5" />
    <path d="M112.03 86.07L112.04 86.08" stroke="black" stroke-width="0.5" />
    <path d="M112.04 86.08L112.06 86.09" stroke="black" stroke-width="0.5" />
    <path d="M112.06 86.09L112.07 86.1" stroke="black" stroke-width="0.5" />
    <path d="M112.07 86.1L112.09 86.11" stroke="black" stroke-width="0.5" />
    <path d="M112.09 86.11L112.1 86.12" stroke="black" stroke-width="0.5" />
    <path d="M112.1 86.12L112.11 86.13" stroke="black" stroke-width="0.5" />
    <path d="M112.11 86.13L112.12 86.14" stroke="black" stroke-width="0.5" />
    <path d="M112.12 86.14L112.14 86.16" stroke="black" stroke-width="0.5" />
    <path d="M112.14 86.16L112.15 86.17" stroke="black" stroke-width="0.5" />
    <path d="M112.15 86.17L112.16 86.18" stroke="black" stroke-width="0.5" />
    <path d="M112.16 86.18L112.17 86.2" stroke="black" stroke-width="0.5" />
    <path d="M112.17 86.2L112.18 86.21" stroke="black" stroke-width="0.5" />
    <path d="M112.18 86.21L112.19 86.23" stroke="black" stroke-width="0.5" />
    <path d="M111.81 86.01H111.83" stroke="black" stroke-width="0.5" />
    <path d="M111.83 86.01H111.85" stroke="black" stroke-width="0.5" />
    <path d="M111.85 86.01L111.87 86.02" stroke="black" stroke-width="0.5" />
    <path d="M111.87 86.02H111.88" stroke="black" stroke-width="0.5" />
    <path d="M111.88 86.02H111.9" stroke="black" stroke-width="0.5" />
    <path d="M111.9 86.02L111.92 86.03" stroke="black" stroke-width="0.5" />
    <path d="M111.92 86.03H111.93" stroke="black" stroke-width="0.5" />
    <path d="M111.93 86.03H111.95" stroke="black" stroke-width="0.5" />
    <path d="M111.95 86.03L111.97 86.04" stroke="black" stroke-width="0.5" />
    <path d="M111.97 86.04L111.98 86.05" stroke="black" stroke-width="0.5" />
    <path d="M111.98 86.05H112" stroke="black" stroke-width="0.5" />
    <path d="M112 86.05L112.01 86.06" stroke="black" stroke-width="0.5" />
    <path d="M112.01 86.06L112.03 86.07" stroke="black" stroke-width="0.5" />
    <path d="M111.88 86.37H111.87" stroke="black" stroke-width="0.5" />
    <path d="M111.87 86.37V86.36" stroke="black" stroke-width="0.5" />
    <path d="M111.87 86.36H111.86" stroke="black" stroke-width="0.5" />
    <path d="M111.86 86.36L111.85 86.35" stroke="black" stroke-width="0.5" />
    <path d="M111.85 86.35H111.84" stroke="black" stroke-width="0.5" />
    <path d="M111.84 86.35H111.83" stroke="black" stroke-width="0.5" />
    <path d="M111.83 86.35H111.82" stroke="black" stroke-width="0.5" />
    <path d="M111.82 86.35H111.81" stroke="black" stroke-width="0.5" />
    <path d="M111.91 86.44V86.43" stroke="black" stroke-width="0.5" />
    <path d="M111.91 86.43V86.42" stroke="black" stroke-width="0.5" />
    <path d="M111.91 86.42V86.41" stroke="black" stroke-width="0.5" />
    <path d="M111.91 86.41L111.9 86.4" stroke="black" stroke-width="0.5" />
    <path d="M111.9 86.4V86.39" stroke="black" stroke-width="0.5" />
    <path d="M111.9 86.39H111.89" stroke="black" stroke-width="0.5" />
    <path d="M111.89 86.39V86.38" stroke="black" stroke-width="0.5" />
    <path d="M111.89 86.38L111.88 86.37" stroke="black" stroke-width="0.5" />
    <path d="M112.03 98.39L112.02 98.4" stroke="black" stroke-width="0.5" />
    <path d="M112.02 98.4H112" stroke="black" stroke-width="0.5" />
    <path d="M112 98.4L111.99 98.41" stroke="black" stroke-width="0.5" />
    <path d="M111.99 98.41L111.97 98.42" stroke="black" stroke-width="0.5" />
    <path d="M111.97 98.42H111.95" stroke="black" stroke-width="0.5" />
    <path d="M111.95 98.42L111.94 98.43" stroke="black" stroke-width="0.5" />
    <path d="M111.94 98.43H111.92" stroke="black" stroke-width="0.5" />
    <path d="M111.92 98.43L111.9 98.44" stroke="black" stroke-width="0.5" />
    <path d="M111.9 98.44H111.89" stroke="black" stroke-width="0.5" />
    <path d="M111.89 98.44H111.87" stroke="black" stroke-width="0.5" />
    <path d="M111.87 98.44H111.85" stroke="black" stroke-width="0.5" />
    <path d="M111.85 98.44L111.84 98.45" stroke="black" stroke-width="0.5" />
    <path d="M111.84 98.45H111.82" stroke="black" stroke-width="0.5" />
    <path d="M111.82 98.45H111.81" stroke="black" stroke-width="0.5" />
    <path d="M112.19 98.23L112.18 98.24" stroke="black" stroke-width="0.5" />
    <path d="M112.18 98.24L112.17 98.26" stroke="black" stroke-width="0.5" />
    <path d="M112.17 98.26L112.16 98.27" stroke="black" stroke-width="0.5" />
    <path d="M112.16 98.27L112.15 98.29" stroke="black" stroke-width="0.5" />
    <path d="M112.15 98.29L112.14 98.3" stroke="black" stroke-width="0.5" />
    <path d="M112.14 98.3L112.13 98.31" stroke="black" stroke-width="0.5" />
    <path d="M112.13 98.31L112.11 98.32" stroke="black" stroke-width="0.5" />
    <path d="M112.11 98.32L112.1 98.34" stroke="black" stroke-width="0.5" />
    <path d="M112.1 98.34L112.09 98.35" stroke="black" stroke-width="0.5" />
    <path d="M112.09 98.35L112.08 98.36" stroke="black" stroke-width="0.5" />
    <path d="M112.08 98.36L112.06 98.37" stroke="black" stroke-width="0.5" />
    <path d="M112.06 98.37L112.05 98.38" stroke="black" stroke-width="0.5" />
    <path d="M112.05 98.38L112.03 98.39" stroke="black" stroke-width="0.5" />
    <path d="M112.25 98.01V98.03" stroke="black" stroke-width="0.5" />
    <path d="M112.25 98.03L112.24 98.05" stroke="black" stroke-width="0.5" />
    <path d="M112.24 98.05V98.07" stroke="black" stroke-width="0.5" />
    <path d="M112.24 98.07V98.08" stroke="black" stroke-width="0.5" />
    <path d="M112.24 98.08V98.1" stroke="black" stroke-width="0.5" />
    <path d="M112.24 98.1L112.23 98.12" stroke="black" stroke-width="0.5" />
    <path d="M112.23 98.12V98.13" stroke="black" stroke-width="0.5" />
    <path d="M112.23 98.13L112.22 98.15" stroke="black" stroke-width="0.5" />
    <path d="M112.22 98.15V98.17" stroke="black" stroke-width="0.5" />
    <path d="M112.22 98.17L112.21 98.18" stroke="black" stroke-width="0.5" />
    <path d="M112.21 98.18L112.2 98.2" stroke="black" stroke-width="0.5" />
    <path d="M112.2 98.2V98.21" stroke="black" stroke-width="0.5" />
    <path d="M112.2 98.21L112.19 98.23" stroke="black" stroke-width="0.5" />
    <path d="M111.88 98.08H111.89" stroke="black" stroke-width="0.5" />
    <path d="M111.89 98.08V98.07" stroke="black" stroke-width="0.5" />
    <path d="M111.89 98.07H111.9" stroke="black" stroke-width="0.5" />
    <path d="M111.9 98.07V98.06" stroke="black" stroke-width="0.5" />
    <path d="M111.9 98.06L111.91 98.05" stroke="black" stroke-width="0.5" />
    <path d="M111.91 98.05V98.04" stroke="black" stroke-width="0.5" />
    <path d="M111.91 98.04V98.03" stroke="black" stroke-width="0.5" />
    <path d="M111.91 98.03V98.02" stroke="black" stroke-width="0.5" />
    <path d="M111.91 98.02V98.01" stroke="black" stroke-width="0.5" />
    <path d="M111.81 98.11H111.82" stroke="black" stroke-width="0.5" />
    <path d="M111.82 98.11H111.83" stroke="black" stroke-width="0.5" />
    <path d="M111.83 98.11H111.84" stroke="black" stroke-width="0.5" />
    <path d="M111.84 98.11H111.85" stroke="black" stroke-width="0.5" />
    <path d="M111.85 98.11L111.86 98.1" stroke="black" stroke-width="0.5" />
    <path d="M111.86 98.1H111.87" stroke="black" stroke-width="0.5" />
    <path d="M111.87 98.1V98.09" stroke="black" stroke-width="0.5" />
    <path d="M111.87 98.09H111.88" stroke="black" stroke-width="0.5" />
    <path d="M111.88 98.09V98.08" stroke="black" stroke-width="0.5" />
    <path d="M112.37 85.2L112.36 85.19" stroke="black" stroke-width="0.5" />
    <path d="M112.36 85.19V85.17" stroke="black" stroke-width="0.5" />
    <path d="M112.36 85.17L112.35 85.15" stroke="black" stroke-width="0.5" />
    <path d="M112.35 85.15L112.34 85.14" stroke="black" stroke-width="0.5" />
    <path d="M112.34 85.14V85.12" stroke="black" stroke-width="0.5" />
    <path d="M112.34 85.12L112.33 85.11" stroke="black" stroke-width="0.5" />
    <path d="M112.33 85.11V85.09" stroke="black" stroke-width="0.5" />
    <path d="M112.33 85.09L112.32 85.07" stroke="black" stroke-width="0.5" />
    <path d="M112.32 85.07V85.06" stroke="black" stroke-width="0.5" />
    <path d="M112.32 85.06V85.04" stroke="black" stroke-width="0.5" />
    <path d="M112.32 85.04L112.31 85.02" stroke="black" stroke-width="0.5" />
    <path d="M112.31 85.02V85" stroke="black" stroke-width="0.5" />
    <path d="M112.31 85V84.99" stroke="black" stroke-width="0.5" />
    <path d="M112.31 84.99V84.98" stroke="black" stroke-width="0.5" />
    <path d="M112.53 85.36L112.51 85.35" stroke="black" stroke-width="0.5" />
    <path d="M112.51 85.35L112.5 85.34" stroke="black" stroke-width="0.5" />
    <path d="M112.5 85.34L112.49 85.33" stroke="black" stroke-width="0.5" />
    <path d="M112.49 85.33L112.47 85.32" stroke="black" stroke-width="0.5" />
    <path d="M112.47 85.32L112.46 85.31" stroke="black" stroke-width="0.5" />
    <path d="M112.46 85.31L112.45 85.3" stroke="black" stroke-width="0.5" />
    <path d="M112.45 85.3L112.43 85.28" stroke="black" stroke-width="0.5" />
    <path d="M112.43 85.28L112.42 85.27" stroke="black" stroke-width="0.5" />
    <path d="M112.42 85.27L112.41 85.26" stroke="black" stroke-width="0.5" />
    <path d="M112.41 85.26L112.4 85.24" stroke="black" stroke-width="0.5" />
    <path d="M112.4 85.24L112.39 85.23" stroke="black" stroke-width="0.5" />
    <path d="M112.39 85.23L112.38 85.22" stroke="black" stroke-width="0.5" />
    <path d="M112.38 85.22L112.37 85.2" stroke="black" stroke-width="0.5" />
    <path d="M112.74 85.41H112.73" stroke="black" stroke-width="0.5" />
    <path d="M112.73 85.41H112.71" stroke="black" stroke-width="0.5" />
    <path d="M112.71 85.41H112.69" stroke="black" stroke-width="0.5" />
    <path d="M112.69 85.41H112.68" stroke="black" stroke-width="0.5" />
    <path d="M112.68 85.41H112.66" stroke="black" stroke-width="0.5" />
    <path d="M112.66 85.41L112.64 85.4" stroke="black" stroke-width="0.5" />
    <path d="M112.64 85.4H112.63" stroke="black" stroke-width="0.5" />
    <path d="M112.63 85.4L112.61 85.39" stroke="black" stroke-width="0.5" />
    <path d="M112.61 85.39H112.59" stroke="black" stroke-width="0.5" />
    <path d="M112.59 85.39L112.58 85.38" stroke="black" stroke-width="0.5" />
    <path d="M112.58 85.38L112.56 85.37" stroke="black" stroke-width="0.5" />
    <path d="M112.56 85.37H112.54" stroke="black" stroke-width="0.5" />
    <path d="M112.54 85.37L112.53 85.36" stroke="black" stroke-width="0.5" />
    <path d="M112.67 85.05L112.68 85.06" stroke="black" stroke-width="0.5" />
    <path d="M112.68 85.06H112.69" stroke="black" stroke-width="0.5" />
    <path d="M112.69 85.06V85.07" stroke="black" stroke-width="0.5" />
    <path d="M112.69 85.07H112.7" stroke="black" stroke-width="0.5" />
    <path d="M112.7 85.07H112.71" stroke="black" stroke-width="0.5" />
    <path d="M112.71 85.07V85.08" stroke="black" stroke-width="0.5" />
    <path d="M112.71 85.08H112.72" stroke="black" stroke-width="0.5" />
    <path d="M112.72 85.08H112.73" stroke="black" stroke-width="0.5" />
    <path d="M112.73 85.08H112.74" stroke="black" stroke-width="0.5" />
    <path d="M112.64 84.98V84.99" stroke="black" stroke-width="0.5" />
    <path d="M112.64 84.99H112.65" stroke="black" stroke-width="0.5" />
    <path d="M112.65 84.99V85" stroke="black" stroke-width="0.5" />
    <path d="M112.65 85V85.01" stroke="black" stroke-width="0.5" />
    <path d="M112.65 85.01V85.02" stroke="black" stroke-width="0.5" />
    <path d="M112.65 85.02L112.66 85.03" stroke="black" stroke-width="0.5" />
    <path d="M112.66 85.03V85.04" stroke="black" stroke-width="0.5" />
    <path d="M112.66 85.04H112.67" stroke="black" stroke-width="0.5" />
    <path d="M112.67 85.04V85.05" stroke="black" stroke-width="0.5" />
    <path d="M122 85.36H121.99" stroke="black" stroke-width="0.5" />
    <path d="M121.99 85.36L121.98 85.37" stroke="black" stroke-width="0.5" />
    <path d="M121.98 85.37L121.96 85.38" stroke="black" stroke-width="0.5" />
    <path d="M121.96 85.38H121.94" stroke="black" stroke-width="0.5" />
    <path d="M121.94 85.38L121.93 85.39" stroke="black" stroke-width="0.5" />
    <path d="M121.93 85.39L121.91 85.4" stroke="black" stroke-width="0.5" />
    <path d="M121.91 85.4H121.89" stroke="black" stroke-width="0.5" />
    <path d="M121.89 85.4H121.88" stroke="black" stroke-width="0.5" />
    <path d="M121.88 85.4L121.86 85.41" stroke="black" stroke-width="0.5" />
    <path d="M121.86 85.41H121.84" stroke="black" stroke-width="0.5" />
    <path d="M121.84 85.41H121.83" stroke="black" stroke-width="0.5" />
    <path d="M121.83 85.41H121.81" stroke="black" stroke-width="0.5" />
    <path d="M121.81 85.41H121.79" stroke="black" stroke-width="0.5" />
    <path d="M122.16 85.2L122.15 85.21" stroke="black" stroke-width="0.5" />
    <path d="M122.15 85.21L122.14 85.23" stroke="black" stroke-width="0.5" />
    <path d="M122.14 85.23L122.13 85.24" stroke="black" stroke-width="0.5" />
    <path d="M122.13 85.24L122.12 85.25" stroke="black" stroke-width="0.5" />
    <path d="M122.12 85.25L122.11 85.27" stroke="black" stroke-width="0.5" />
    <path d="M122.11 85.27L122.1 85.28" stroke="black" stroke-width="0.5" />
    <path d="M122.1 85.28L122.09 85.29" stroke="black" stroke-width="0.5" />
    <path d="M122.09 85.29L122.08 85.3" stroke="black" stroke-width="0.5" />
    <path d="M122.08 85.3L122.06 85.32" stroke="black" stroke-width="0.5" />
    <path d="M122.06 85.32L122.05 85.33" stroke="black" stroke-width="0.5" />
    <path d="M122.05 85.33L122.04 85.34" stroke="black" stroke-width="0.5" />
    <path d="M122.04 85.34L122.02 85.35" stroke="black" stroke-width="0.5" />
    <path d="M122.02 85.35H122.01" stroke="black" stroke-width="0.5" />
    <path d="M122.01 85.35L122 85.36" stroke="black" stroke-width="0.5" />
    <path d="M122.22 84.98V85" stroke="black" stroke-width="0.5" />
    <path d="M122.22 85V85.02" stroke="black" stroke-width="0.5" />
    <path d="M122.22 85.02V85.03" stroke="black" stroke-width="0.5" />
    <path d="M122.22 85.03L122.21 85.05" stroke="black" stroke-width="0.5" />
    <path d="M122.21 85.05V85.07" stroke="black" stroke-width="0.5" />
    <path d="M122.21 85.07V85.08" stroke="black" stroke-width="0.5" />
    <path d="M122.21 85.08L122.2 85.1" stroke="black" stroke-width="0.5" />
    <path d="M122.2 85.1V85.12" stroke="black" stroke-width="0.5" />
    <path d="M122.2 85.12L122.19 85.13" stroke="black" stroke-width="0.5" />
    <path d="M122.19 85.13V85.15" stroke="black" stroke-width="0.5" />
    <path d="M122.19 85.15L122.18 85.17" stroke="black" stroke-width="0.5" />
    <path d="M122.18 85.17L122.17 85.18" stroke="black" stroke-width="0.5" />
    <path d="M122.17 85.18L122.16 85.2" stroke="black" stroke-width="0.5" />
    <path d="M121.86 85.05L121.87 85.04" stroke="black" stroke-width="0.5" />
    <path d="M121.87 85.04V85.03" stroke="black" stroke-width="0.5" />
    <path d="M121.87 85.03H121.88" stroke="black" stroke-width="0.5" />
    <path d="M121.88 85.03V85.02" stroke="black" stroke-width="0.5" />
    <path d="M121.88 85.02V85.01" stroke="black" stroke-width="0.5" />
    <path d="M121.88 85.01V85" stroke="black" stroke-width="0.5" />
    <path d="M121.88 85H121.89" stroke="black" stroke-width="0.5" />
    <path d="M121.89 85V84.99" stroke="black" stroke-width="0.5" />
    <path d="M121.89 84.99V84.98" stroke="black" stroke-width="0.5" />
    <path d="M121.79 85.08H121.8" stroke="black" stroke-width="0.5" />
    <path d="M121.8 85.08H121.81" stroke="black" stroke-width="0.5" />
    <path d="M121.81 85.08H121.82" stroke="black" stroke-width="0.5" />
    <path d="M121.82 85.08L121.83 85.07" stroke="black" stroke-width="0.5" />
    <path d="M121.83 85.07H121.84" stroke="black" stroke-width="0.5" />
    <path d="M121.84 85.07V85.06" stroke="black" stroke-width="0.5" />
    <path d="M121.84 85.06H121.85" stroke="black" stroke-width="0.5" />
    <path d="M121.85 85.06L121.86 85.05" stroke="black" stroke-width="0.5" />
    <path d="M111.81 86.01H111.61" stroke="black" stroke-width="0.5" />
    <path d="M111.61 86.35H111.81" stroke="black" stroke-width="0.5" />
    <path d="M112.25 98.01V86.44" stroke="black" stroke-width="0.5" />
    <path d="M111.91 86.44V98.01" stroke="black" stroke-width="0.5" />
    <path d="M111.61 98.45H111.81" stroke="black" stroke-width="0.5" />
    <path d="M111.81 98.11H111.61" stroke="black" stroke-width="0.5" />
    <path d="M112.74 84.78V84.58" stroke="black" stroke-width="0.5" />
    <path d="M112.21 84.58V84.78" stroke="black" stroke-width="0.5" />
    <path d="M112.64 84.98V84.78" stroke="black" stroke-width="0.5" />
    <path d="M112.31 84.78V84.98" stroke="black" stroke-width="0.5" />
    <path d="M121.79 85.08H112.74" stroke="black" stroke-width="0.5" />
    <path d="M112.74 85.41H121.79" stroke="black" stroke-width="0.5" />
    <path d="M121.89 84.78V84.98" stroke="black" stroke-width="0.5" />
    <path d="M122.22 84.98V84.78" stroke="black" stroke-width="0.5" />
    <path d="M111.61 85.91H111.41" stroke="black" stroke-width="0.5" />
    <path d="M111.41 86.44H111.61" stroke="black" stroke-width="0.5" />
    <path d="M111.61 98.01H111.41" stroke="black" stroke-width="0.5" />
    <path d="M111.41 98.55H111.61" stroke="black" stroke-width="0.5" />
    <path d="M122.32 84.78V84.58" stroke="black" stroke-width="0.5" />
    <path d="M121.79 84.58V84.78" stroke="black" stroke-width="0.5" />
    <path d="M112.74 81.99H112.21" stroke="black" stroke-width="0.5" />
    <path d="M111.61 80.86V80.33" stroke="black" stroke-width="0.5" />
    <path d="M111.61 68.76V68.23" stroke="black" stroke-width="0.5" />
    <path d="M122.32 81.99H121.79" stroke="black" stroke-width="0.5" />
    <path d="M112.19 80.54V80.53" stroke="black" stroke-width="0.5" />
    <path d="M112.19 80.53L112.2 80.52" stroke="black" stroke-width="0.5" />
    <path d="M112.2 80.52L112.21 80.5" stroke="black" stroke-width="0.5" />
    <path d="M112.21 80.5L112.22 80.48" stroke="black" stroke-width="0.5" />
    <path d="M112.22 80.48V80.47" stroke="black" stroke-width="0.5" />
    <path d="M112.22 80.47L112.23 80.45" stroke="black" stroke-width="0.5" />
    <path d="M112.23 80.45V80.43" stroke="black" stroke-width="0.5" />
    <path d="M112.23 80.43L112.24 80.42" stroke="black" stroke-width="0.5" />
    <path d="M112.24 80.42V80.4" stroke="black" stroke-width="0.5" />
    <path d="M112.24 80.4V80.38" stroke="black" stroke-width="0.5" />
    <path d="M112.24 80.38V80.37" stroke="black" stroke-width="0.5" />
    <path d="M112.24 80.37L112.25 80.35" stroke="black" stroke-width="0.5" />
    <path d="M112.25 80.35V80.33" stroke="black" stroke-width="0.5" />
    <path d="M112.03 80.7L112.04 80.69" stroke="black" stroke-width="0.5" />
    <path d="M112.04 80.69L112.06 80.68" stroke="black" stroke-width="0.5" />
    <path d="M112.06 80.68L112.07 80.67" stroke="black" stroke-width="0.5" />
    <path d="M112.07 80.67L112.09 80.66" stroke="black" stroke-width="0.5" />
    <path d="M112.09 80.66L112.1 80.65" stroke="black" stroke-width="0.5" />
    <path d="M112.1 80.65L112.11 80.64" stroke="black" stroke-width="0.5" />
    <path d="M112.11 80.64L112.12 80.63" stroke="black" stroke-width="0.5" />
    <path d="M112.12 80.63L112.14 80.62" stroke="black" stroke-width="0.5" />
    <path d="M112.14 80.62L112.15 80.6" stroke="black" stroke-width="0.5" />
    <path d="M112.15 80.6L112.16 80.59" stroke="black" stroke-width="0.5" />
    <path d="M112.16 80.59L112.17 80.58" stroke="black" stroke-width="0.5" />
    <path d="M112.17 80.58L112.18 80.56" stroke="black" stroke-width="0.5" />
    <path d="M112.18 80.56L112.19 80.55" stroke="black" stroke-width="0.5" />
    <path d="M112.19 80.55V80.54" stroke="black" stroke-width="0.5" />
    <path d="M111.81 80.76H111.83" stroke="black" stroke-width="0.5" />
    <path d="M111.83 80.76H111.85" stroke="black" stroke-width="0.5" />
    <path d="M111.85 80.76H111.87" stroke="black" stroke-width="0.5" />
    <path d="M111.87 80.76L111.88 80.75" stroke="black" stroke-width="0.5" />
    <path d="M111.88 80.75H111.9" stroke="black" stroke-width="0.5" />
    <path d="M111.9 80.75H111.92" stroke="black" stroke-width="0.5" />
    <path d="M111.92 80.75L111.93 80.74" stroke="black" stroke-width="0.5" />
    <path d="M111.93 80.74H111.95" stroke="black" stroke-width="0.5" />
    <path d="M111.95 80.74L111.97 80.73" stroke="black" stroke-width="0.5" />
    <path d="M111.97 80.73H111.98" stroke="black" stroke-width="0.5" />
    <path d="M111.98 80.73L112 80.72" stroke="black" stroke-width="0.5" />
    <path d="M112 80.72L112.01 80.71" stroke="black" stroke-width="0.5" />
    <path d="M112.01 80.71L112.03 80.7" stroke="black" stroke-width="0.5" />
    <path d="M111.88 80.4L111.87 80.41" stroke="black" stroke-width="0.5" />
    <path d="M111.87 80.41H111.86" stroke="black" stroke-width="0.5" />
    <path d="M111.86 80.41V80.42" stroke="black" stroke-width="0.5" />
    <path d="M111.86 80.42H111.85" stroke="black" stroke-width="0.5" />
    <path d="M111.85 80.42H111.84" stroke="black" stroke-width="0.5" />
    <path d="M111.84 80.42L111.83 80.43" stroke="black" stroke-width="0.5" />
    <path d="M111.83 80.43H111.82" stroke="black" stroke-width="0.5" />
    <path d="M111.82 80.43H111.81" stroke="black" stroke-width="0.5" />
    <path d="M111.91 80.33V80.34" stroke="black" stroke-width="0.5" />
    <path d="M111.91 80.34V80.35" stroke="black" stroke-width="0.5" />
    <path d="M111.91 80.35V80.36" stroke="black" stroke-width="0.5" />
    <path d="M111.91 80.36V80.37" stroke="black" stroke-width="0.5" />
    <path d="M111.91 80.37H111.9" stroke="black" stroke-width="0.5" />
    <path d="M111.9 80.37V80.38" stroke="black" stroke-width="0.5" />
    <path d="M111.9 80.38L111.89 80.39" stroke="black" stroke-width="0.5" />
    <path d="M111.89 80.39V80.4" stroke="black" stroke-width="0.5" />
    <path d="M111.89 80.4H111.88" stroke="black" stroke-width="0.5" />
    <path d="M112.03 68.38H112.02" stroke="black" stroke-width="0.5" />
    <path d="M112.02 68.38L112 68.37" stroke="black" stroke-width="0.5" />
    <path d="M112 68.37L111.99 68.36" stroke="black" stroke-width="0.5" />
    <path d="M111.99 68.36H111.97" stroke="black" stroke-width="0.5" />
    <path d="M111.97 68.36L111.95 68.35" stroke="black" stroke-width="0.5" />
    <path d="M111.95 68.35L111.94 68.34" stroke="black" stroke-width="0.5" />
    <path d="M111.94 68.34H111.92" stroke="black" stroke-width="0.5" />
    <path d="M111.92 68.34H111.9" stroke="black" stroke-width="0.5" />
    <path d="M111.9 68.34L111.89 68.33" stroke="black" stroke-width="0.5" />
    <path d="M111.89 68.33H111.87" stroke="black" stroke-width="0.5" />
    <path d="M111.87 68.33H111.85" stroke="black" stroke-width="0.5" />
    <path d="M111.85 68.33H111.84" stroke="black" stroke-width="0.5" />
    <path d="M111.84 68.33H111.82" stroke="black" stroke-width="0.5" />
    <path d="M111.82 68.33H111.81" stroke="black" stroke-width="0.5" />
    <path d="M112.19 68.54L112.18 68.53" stroke="black" stroke-width="0.5" />
    <path d="M112.18 68.53L112.17 68.51" stroke="black" stroke-width="0.5" />
    <path d="M112.17 68.51L112.16 68.5" stroke="black" stroke-width="0.5" />
    <path d="M112.16 68.5L112.15 68.49" stroke="black" stroke-width="0.5" />
    <path d="M112.15 68.49L112.14 68.47" stroke="black" stroke-width="0.5" />
    <path d="M112.14 68.47L112.13 68.46" stroke="black" stroke-width="0.5" />
    <path d="M112.13 68.46L112.11 68.45" stroke="black" stroke-width="0.5" />
    <path d="M112.11 68.45L112.1 68.44" stroke="black" stroke-width="0.5" />
    <path d="M112.1 68.44L112.09 68.43" stroke="black" stroke-width="0.5" />
    <path d="M112.09 68.43L112.08 68.41" stroke="black" stroke-width="0.5" />
    <path d="M112.08 68.41L112.06 68.4" stroke="black" stroke-width="0.5" />
    <path d="M112.06 68.4L112.05 68.39" stroke="black" stroke-width="0.5" />
    <path d="M112.05 68.39H112.03" stroke="black" stroke-width="0.5" />
    <path d="M112.03 68.39V68.38" stroke="black" stroke-width="0.5" />
    <path d="M112.25 68.76V68.74" stroke="black" stroke-width="0.5" />
    <path d="M112.25 68.74L112.24 68.72" stroke="black" stroke-width="0.5" />
    <path d="M112.24 68.72V68.71" stroke="black" stroke-width="0.5" />
    <path d="M112.24 68.71V68.69" stroke="black" stroke-width="0.5" />
    <path d="M112.24 68.69V68.67" stroke="black" stroke-width="0.5" />
    <path d="M112.24 68.67L112.23 68.66" stroke="black" stroke-width="0.5" />
    <path d="M112.23 68.66V68.64" stroke="black" stroke-width="0.5" />
    <path d="M112.23 68.64L112.22 68.62" stroke="black" stroke-width="0.5" />
    <path d="M112.22 68.62V68.61" stroke="black" stroke-width="0.5" />
    <path d="M112.22 68.61L112.21 68.59" stroke="black" stroke-width="0.5" />
    <path d="M112.21 68.59L112.2 68.57" stroke="black" stroke-width="0.5" />
    <path d="M112.2 68.57V68.56" stroke="black" stroke-width="0.5" />
    <path d="M112.2 68.56L112.19 68.54" stroke="black" stroke-width="0.5" />
    <path d="M111.88 68.69H111.89" stroke="black" stroke-width="0.5" />
    <path d="M111.89 68.69V68.7" stroke="black" stroke-width="0.5" />
    <path d="M111.89 68.7H111.9" stroke="black" stroke-width="0.5" />
    <path d="M111.9 68.7V68.71" stroke="black" stroke-width="0.5" />
    <path d="M111.9 68.71L111.91 68.72" stroke="black" stroke-width="0.5" />
    <path d="M111.91 68.72V68.73" stroke="black" stroke-width="0.5" />
    <path d="M111.91 68.73V68.74" stroke="black" stroke-width="0.5" />
    <path d="M111.91 68.74V68.75" stroke="black" stroke-width="0.5" />
    <path d="M111.91 68.75V68.76" stroke="black" stroke-width="0.5" />
    <path d="M111.81 68.66H111.82" stroke="black" stroke-width="0.5" />
    <path d="M111.82 68.66H111.83" stroke="black" stroke-width="0.5" />
    <path d="M111.83 68.66H111.84" stroke="black" stroke-width="0.5" />
    <path d="M111.84 68.66L111.85 68.67" stroke="black" stroke-width="0.5" />
    <path d="M111.85 68.67H111.86" stroke="black" stroke-width="0.5" />
    <path d="M111.86 68.67H111.87" stroke="black" stroke-width="0.5" />
    <path d="M111.87 68.67V68.68" stroke="black" stroke-width="0.5" />
    <path d="M111.87 68.68H111.88" stroke="black" stroke-width="0.5" />
    <path d="M111.88 68.68V68.69" stroke="black" stroke-width="0.5" />
    <path d="M112.37 81.57L112.36 81.59" stroke="black" stroke-width="0.5" />
    <path d="M112.36 81.59V81.6" stroke="black" stroke-width="0.5" />
    <path d="M112.36 81.6L112.35 81.62" stroke="black" stroke-width="0.5" />
    <path d="M112.35 81.62L112.34 81.63" stroke="black" stroke-width="0.5" />
    <path d="M112.34 81.63V81.65" stroke="black" stroke-width="0.5" />
    <path d="M112.34 81.65L112.33 81.67" stroke="black" stroke-width="0.5" />
    <path d="M112.33 81.67V81.68" stroke="black" stroke-width="0.5" />
    <path d="M112.33 81.68L112.32 81.7" stroke="black" stroke-width="0.5" />
    <path d="M112.32 81.7V81.72" stroke="black" stroke-width="0.5" />
    <path d="M112.32 81.72V81.73" stroke="black" stroke-width="0.5" />
    <path d="M112.32 81.73L112.31 81.75" stroke="black" stroke-width="0.5" />
    <path d="M112.31 81.75V81.77" stroke="black" stroke-width="0.5" />
    <path d="M112.31 81.77V81.79" stroke="black" stroke-width="0.5" />
    <path d="M112.53 81.42H112.51" stroke="black" stroke-width="0.5" />
    <path d="M112.51 81.42L112.5 81.43" stroke="black" stroke-width="0.5" />
    <path d="M112.5 81.43L112.49 81.44" stroke="black" stroke-width="0.5" />
    <path d="M112.49 81.44L112.47 81.45" stroke="black" stroke-width="0.5" />
    <path d="M112.47 81.45L112.46 81.47" stroke="black" stroke-width="0.5" />
    <path d="M112.46 81.47L112.45 81.48" stroke="black" stroke-width="0.5" />
    <path d="M112.45 81.48L112.43 81.49" stroke="black" stroke-width="0.5" />
    <path d="M112.43 81.49L112.42 81.5" stroke="black" stroke-width="0.5" />
    <path d="M112.42 81.5L112.41 81.52" stroke="black" stroke-width="0.5" />
    <path d="M112.41 81.52L112.4 81.53" stroke="black" stroke-width="0.5" />
    <path d="M112.4 81.53L112.39 81.54" stroke="black" stroke-width="0.5" />
    <path d="M112.39 81.54L112.38 81.56" stroke="black" stroke-width="0.5" />
    <path d="M112.38 81.56L112.37 81.57" stroke="black" stroke-width="0.5" />
    <path d="M112.74 81.36H112.73" stroke="black" stroke-width="0.5" />
    <path d="M112.73 81.36H112.71" stroke="black" stroke-width="0.5" />
    <path d="M112.71 81.36H112.69" stroke="black" stroke-width="0.5" />
    <path d="M112.69 81.36H112.68" stroke="black" stroke-width="0.5" />
    <path d="M112.68 81.36L112.66 81.37" stroke="black" stroke-width="0.5" />
    <path d="M112.66 81.37H112.64" stroke="black" stroke-width="0.5" />
    <path d="M112.64 81.37L112.63 81.38" stroke="black" stroke-width="0.5" />
    <path d="M112.63 81.38H112.61" stroke="black" stroke-width="0.5" />
    <path d="M112.61 81.38L112.59 81.39" stroke="black" stroke-width="0.5" />
    <path d="M112.59 81.39H112.58" stroke="black" stroke-width="0.5" />
    <path d="M112.58 81.39L112.56 81.4" stroke="black" stroke-width="0.5" />
    <path d="M112.56 81.4L112.54 81.41" stroke="black" stroke-width="0.5" />
    <path d="M112.54 81.41L112.53 81.42" stroke="black" stroke-width="0.5" />
    <path d="M112.67 81.72H112.68" stroke="black" stroke-width="0.5" />
    <path d="M112.68 81.72V81.71" stroke="black" stroke-width="0.5" />
    <path d="M112.68 81.71H112.69" stroke="black" stroke-width="0.5" />
    <path d="M112.69 81.71V81.7" stroke="black" stroke-width="0.5" />
    <path d="M112.69 81.7H112.7" stroke="black" stroke-width="0.5" />
    <path d="M112.7 81.7H112.71" stroke="black" stroke-width="0.5" />
    <path d="M112.71 81.7L112.72 81.69" stroke="black" stroke-width="0.5" />
    <path d="M112.72 81.69H112.73" stroke="black" stroke-width="0.5" />
    <path d="M112.73 81.69H112.74" stroke="black" stroke-width="0.5" />
    <path d="M112.64 81.79L112.65 81.78" stroke="black" stroke-width="0.5" />
    <path d="M112.65 81.78V81.77" stroke="black" stroke-width="0.5" />
    <path d="M112.65 81.77V81.76" stroke="black" stroke-width="0.5" />
    <path d="M112.65 81.76V81.75" stroke="black" stroke-width="0.5" />
    <path d="M112.65 81.75L112.66 81.74" stroke="black" stroke-width="0.5" />
    <path d="M112.66 81.74V81.73" stroke="black" stroke-width="0.5" />
    <path d="M112.66 81.73H112.67" stroke="black" stroke-width="0.5" />
    <path d="M112.67 81.73V81.72" stroke="black" stroke-width="0.5" />
    <path d="M122 81.42L121.99 81.41" stroke="black" stroke-width="0.5" />
    <path d="M121.99 81.41L121.98 81.4" stroke="black" stroke-width="0.5" />
    <path d="M121.98 81.4L121.96 81.39" stroke="black" stroke-width="0.5" />
    <path d="M121.96 81.39H121.94" stroke="black" stroke-width="0.5" />
    <path d="M121.94 81.39L121.93 81.38" stroke="black" stroke-width="0.5" />
    <path d="M121.93 81.38H121.91" stroke="black" stroke-width="0.5" />
    <path d="M121.91 81.38L121.89 81.37" stroke="black" stroke-width="0.5" />
    <path d="M121.89 81.37H121.88" stroke="black" stroke-width="0.5" />
    <path d="M121.88 81.37L121.86 81.36" stroke="black" stroke-width="0.5" />
    <path d="M121.86 81.36H121.84" stroke="black" stroke-width="0.5" />
    <path d="M121.84 81.36H121.83" stroke="black" stroke-width="0.5" />
    <path d="M121.83 81.36H121.81" stroke="black" stroke-width="0.5" />
    <path d="M121.81 81.36H121.79" stroke="black" stroke-width="0.5" />
    <path d="M122.16 81.57L122.15 81.56" stroke="black" stroke-width="0.5" />
    <path d="M122.15 81.56L122.14 81.55" stroke="black" stroke-width="0.5" />
    <path d="M122.14 81.55L122.13 81.53" stroke="black" stroke-width="0.5" />
    <path d="M122.13 81.53L122.12 81.52" stroke="black" stroke-width="0.5" />
    <path d="M122.12 81.52L122.11 81.51" stroke="black" stroke-width="0.5" />
    <path d="M122.11 81.51L122.1 81.49" stroke="black" stroke-width="0.5" />
    <path d="M122.1 81.49L122.09 81.48" stroke="black" stroke-width="0.5" />
    <path d="M122.09 81.48L122.08 81.47" stroke="black" stroke-width="0.5" />
    <path d="M122.08 81.47L122.06 81.46" stroke="black" stroke-width="0.5" />
    <path d="M122.06 81.46L122.05 81.45" stroke="black" stroke-width="0.5" />
    <path d="M122.05 81.45L122.04 81.44" stroke="black" stroke-width="0.5" />
    <path d="M122.04 81.44L122.02 81.43" stroke="black" stroke-width="0.5" />
    <path d="M122.02 81.43L122.01 81.42" stroke="black" stroke-width="0.5" />
    <path d="M122.01 81.42H122" stroke="black" stroke-width="0.5" />
    <path d="M122.22 81.79V81.77" stroke="black" stroke-width="0.5" />
    <path d="M122.22 81.77V81.76" stroke="black" stroke-width="0.5" />
    <path d="M122.22 81.76V81.74" stroke="black" stroke-width="0.5" />
    <path d="M122.22 81.74L122.21 81.72" stroke="black" stroke-width="0.5" />
    <path d="M122.21 81.72V81.7" stroke="black" stroke-width="0.5" />
    <path d="M122.21 81.7V81.69" stroke="black" stroke-width="0.5" />
    <path d="M122.21 81.69L122.2 81.67" stroke="black" stroke-width="0.5" />
    <path d="M122.2 81.67V81.65" stroke="black" stroke-width="0.5" />
    <path d="M122.2 81.65L122.19 81.64" stroke="black" stroke-width="0.5" />
    <path d="M122.19 81.64V81.62" stroke="black" stroke-width="0.5" />
    <path d="M122.19 81.62L122.18 81.61" stroke="black" stroke-width="0.5" />
    <path d="M122.18 81.61L122.17 81.59" stroke="black" stroke-width="0.5" />
    <path d="M122.17 81.59L122.16 81.58" stroke="black" stroke-width="0.5" />
    <path d="M122.16 81.58V81.57" stroke="black" stroke-width="0.5" />
    <path d="M121.86 81.72V81.73" stroke="black" stroke-width="0.5" />
    <path d="M121.86 81.73H121.87" stroke="black" stroke-width="0.5" />
    <path d="M121.87 81.73V81.74" stroke="black" stroke-width="0.5" />
    <path d="M121.87 81.74H121.88" stroke="black" stroke-width="0.5" />
    <path d="M121.88 81.74V81.75" stroke="black" stroke-width="0.5" />
    <path d="M121.88 81.75V81.76" stroke="black" stroke-width="0.5" />
    <path d="M121.88 81.76V81.77" stroke="black" stroke-width="0.5" />
    <path d="M121.88 81.77H121.89" stroke="black" stroke-width="0.5" />
    <path d="M121.89 81.77V81.78" stroke="black" stroke-width="0.5" />
    <path d="M121.89 81.78V81.79" stroke="black" stroke-width="0.5" />
    <path d="M121.79 81.69H121.8" stroke="black" stroke-width="0.5" />
    <path d="M121.8 81.69H121.81" stroke="black" stroke-width="0.5" />
    <path d="M121.81 81.69L121.82 81.7" stroke="black" stroke-width="0.5" />
    <path d="M121.82 81.7H121.83" stroke="black" stroke-width="0.5" />
    <path d="M121.83 81.7H121.84" stroke="black" stroke-width="0.5" />
    <path d="M121.84 81.7V81.71" stroke="black" stroke-width="0.5" />
    <path d="M121.84 81.71H121.85" stroke="black" stroke-width="0.5" />
    <path d="M121.85 81.71V81.72" stroke="black" stroke-width="0.5" />
    <path d="M121.85 81.72H121.86" stroke="black" stroke-width="0.5" />
    <path d="M111.81 80.76H111.61" stroke="black" stroke-width="0.5" />
    <path d="M111.61 80.43H111.81" stroke="black" stroke-width="0.5" />
    <path d="M112.25 68.76V80.33" stroke="black" stroke-width="0.5" />
    <path d="M111.91 80.33V68.76" stroke="black" stroke-width="0.5" />
    <path d="M111.61 68.33H111.81" stroke="black" stroke-width="0.5" />
    <path d="M111.81 68.66H111.61" stroke="black" stroke-width="0.5" />
    <path d="M112.74 81.99V82.19" stroke="black" stroke-width="0.5" />
    <path d="M112.21 82.19V81.99" stroke="black" stroke-width="0.5" />
    <path d="M112.64 81.79V81.99" stroke="black" stroke-width="0.5" />
    <path d="M112.31 81.99V81.79" stroke="black" stroke-width="0.5" />
    <path d="M121.79 81.69H112.74" stroke="black" stroke-width="0.5" />
    <path d="M112.74 81.36H121.79" stroke="black" stroke-width="0.5" />
    <path d="M121.89 81.99V81.79" stroke="black" stroke-width="0.5" />
    <path d="M122.22 81.79V81.99" stroke="black" stroke-width="0.5" />
    <path d="M111.61 80.86H111.41" stroke="black" stroke-width="0.5" />
    <path d="M111.41 80.33H111.61" stroke="black" stroke-width="0.5" />
    <path d="M111.61 68.76H111.41" stroke="black" stroke-width="0.5" />
    <path d="M111.41 68.23H111.61" stroke="black" stroke-width="0.5" />
    <path d="M122.32 81.99V82.19" stroke="black" stroke-width="0.5" />
    <path d="M121.79 82.19V81.99" stroke="black" stroke-width="0.5" />
    <path d="M114.87 87.26L114.81 87.82" stroke="black" stroke-width="0.5" />
    <path d="M114.81 87.82L114.75 88.23" stroke="black" stroke-width="0.5" />
    <path d="M114.75 88.23L114.69 88.59" stroke="black" stroke-width="0.5" />
    <path d="M114.69 88.59L114.63 88.89" stroke="black" stroke-width="0.5" />
    <path d="M114.63 88.89L114.57 89.14" stroke="black" stroke-width="0.5" />
    <path d="M114.57 89.14L114.52 89.32" stroke="black" stroke-width="0.5" />
    <path d="M114.52 89.32L114.49 89.44" stroke="black" stroke-width="0.5" />
    <path d="M114.49 89.44L114.47 89.55" stroke="black" stroke-width="0.5" />
    <path d="M114.47 89.55L114.45 89.66" stroke="black" stroke-width="0.5" />
    <path d="M114.45 89.66L114.44 89.79" stroke="black" stroke-width="0.5" />
    <path d="M114.44 89.79L114.43 89.99" stroke="black" stroke-width="0.5" />
    <path d="M114.43 89.99V90.23" stroke="black" stroke-width="0.5" />
    <path d="M114.43 90.23L114.45 90.5" stroke="black" stroke-width="0.5" />
    <path d="M114.45 90.5L114.49 90.79" stroke="black" stroke-width="0.5" />
    <path d="M114.49 90.79L114.55 91.1" stroke="black" stroke-width="0.5" />
    <path d="M114.55 91.1L114.65 91.41" stroke="black" stroke-width="0.5" />
    <path d="M114.65 91.41L114.78 91.72" stroke="black" stroke-width="0.5" />
    <path d="M114.78 91.72L114.93 92" stroke="black" stroke-width="0.5" />
    <path d="M114.93 92L115.12 92.25" stroke="black" stroke-width="0.5" />
    <path d="M115.12 92.25L115.32 92.47" stroke="black" stroke-width="0.5" />
    <path d="M115.32 92.47L115.54 92.64" stroke="black" stroke-width="0.5" />
    <path d="M115.54 92.64L115.76 92.77" stroke="black" stroke-width="0.5" />
    <path d="M115.76 92.77L115.98 92.85" stroke="black" stroke-width="0.5" />
    <path d="M115.98 92.85L116.2 92.88" stroke="black" stroke-width="0.5" />
    <path d="M116.2 92.88L116.42 92.85" stroke="black" stroke-width="0.5" />
    <path d="M116.42 92.85L116.64 92.77" stroke="black" stroke-width="0.5" />
    <path d="M116.64 92.77L116.87 92.64" stroke="black" stroke-width="0.5" />
    <path d="M116.87 92.64L117.09 92.47" stroke="black" stroke-width="0.5" />
    <path d="M117.09 92.47L117.29 92.25" stroke="black" stroke-width="0.5" />
    <path d="M117.29 92.25L117.47 92" stroke="black" stroke-width="0.5" />
    <path d="M117.47 92L117.63 91.71" stroke="black" stroke-width="0.5" />
    <path d="M117.63 91.71L117.76 91.42" stroke="black" stroke-width="0.5" />
    <path d="M117.76 91.42L117.85 91.1" stroke="black" stroke-width="0.5" />
    <path d="M117.85 91.1L117.92 90.79" stroke="black" stroke-width="0.5" />
    <path d="M117.92 90.79L117.96 90.5" stroke="black" stroke-width="0.5" />
    <path d="M117.96 90.5L117.97 90.22" stroke="black" stroke-width="0.5" />
    <path d="M117.97 90.22V89.98" stroke="black" stroke-width="0.5" />
    <path d="M117.97 89.98V89.79" stroke="black" stroke-width="0.5" />
    <path d="M117.97 89.79L117.96 89.66" stroke="black" stroke-width="0.5" />
    <path d="M117.96 89.66L117.94 89.55" stroke="black" stroke-width="0.5" />
    <path d="M117.94 89.55L117.92 89.44" stroke="black" stroke-width="0.5" />
    <path d="M117.92 89.44L117.88 89.32" stroke="black" stroke-width="0.5" />
    <path d="M117.88 89.32L117.83 89.13" stroke="black" stroke-width="0.5" />
    <path d="M117.83 89.13L117.78 88.89" stroke="black" stroke-width="0.5" />
    <path d="M117.78 88.89L117.72 88.59" stroke="black" stroke-width="0.5" />
    <path d="M117.72 88.59L117.66 88.23" stroke="black" stroke-width="0.5" />
    <path d="M117.66 88.23L117.6 87.82" stroke="black" stroke-width="0.5" />
    <path d="M117.6 87.82L117.55 87.38" stroke="black" stroke-width="0.5" />
    <path d="M117.55 87.38V87.27" stroke="black" stroke-width="0.5" />
    <path d="M118.02 87.12L118.06 87.1" stroke="black" stroke-width="0.5" />
    <path d="M118.06 87.1L118.09 87.09" stroke="black" stroke-width="0.5" />
    <path d="M118.09 87.09L118.13 87.08" stroke="black" stroke-width="0.5" />
    <path d="M118.13 87.08L118.16 87.06" stroke="black" stroke-width="0.5" />
    <path d="M118.16 87.06L118.19 87.04" stroke="black" stroke-width="0.5" />
    <path d="M118.19 87.04L118.22 87.02" stroke="black" stroke-width="0.5" />
    <path d="M118.22 87.02L118.26 86.99" stroke="black" stroke-width="0.5" />
    <path d="M118.26 86.99L118.29 86.96" stroke="black" stroke-width="0.5" />
    <path d="M118.29 86.96L118.34 86.92" stroke="black" stroke-width="0.5" />
    <path d="M118.34 86.92L118.38 86.88" stroke="black" stroke-width="0.5" />
    <path d="M118.38 86.88L118.43 86.83" stroke="black" stroke-width="0.5" />
    <path d="M118.43 86.83L118.48 86.77" stroke="black" stroke-width="0.5" />
    <path d="M118.48 86.77L118.53 86.71" stroke="black" stroke-width="0.5" />
    <path d="M118.53 86.71L118.57 86.64" stroke="black" stroke-width="0.5" />
    <path d="M118.57 86.64L118.61 86.57" stroke="black" stroke-width="0.5" />
    <path d="M118.61 86.57L118.65 86.5" stroke="black" stroke-width="0.5" />
    <path d="M118.65 86.5L118.67 86.43" stroke="black" stroke-width="0.5" />
    <path d="M118.67 86.43L118.69 86.35" stroke="black" stroke-width="0.5" />
    <path d="M118.69 86.35L118.71 86.27" stroke="black" stroke-width="0.5" />
    <path d="M118.71 86.27L118.72 86.2" stroke="black" stroke-width="0.5" />
    <path d="M118.72 86.2V86.12" stroke="black" stroke-width="0.5" />
    <path d="M118.72 86.12V86.05" stroke="black" stroke-width="0.5" />
    <path d="M118.72 86.05V85.97" stroke="black" stroke-width="0.5" />
    <path d="M118.72 85.97V85.9" stroke="black" stroke-width="0.5" />
    <path d="M118.72 85.9V85.83" stroke="black" stroke-width="0.5" />
    <path d="M118.72 85.83V85.77" stroke="black" stroke-width="0.5" />
    <path d="M118.72 85.77V85.71" stroke="black" stroke-width="0.5" />
    <path d="M118.72 85.71L118.71 85.65" stroke="black" stroke-width="0.5" />
    <path d="M118.71 85.65V85.59" stroke="black" stroke-width="0.5" />
    <path d="M118.71 85.59L118.7 85.54" stroke="black" stroke-width="0.5" />
    <path d="M118.7 85.54V85.49" stroke="black" stroke-width="0.5" />
    <path d="M118.7 85.49L118.69 85.44" stroke="black" stroke-width="0.5" />
    <path d="M118.69 85.44V85.4" stroke="black" stroke-width="0.5" />
    <path d="M118.69 85.4L118.68 85.36" stroke="black" stroke-width="0.5" />
    <path d="M118.68 85.36L118.66 85.32" stroke="black" stroke-width="0.5" />
    <path d="M118.66 85.32L118.65 85.29" stroke="black" stroke-width="0.5" />
    <path d="M118.65 85.29L118.62 85.26" stroke="black" stroke-width="0.5" />
    <path d="M118.62 85.26L118.6 85.23" stroke="black" stroke-width="0.5" />
    <path d="M118.6 85.23L118.56 85.21" stroke="black" stroke-width="0.5" />
    <path d="M118.56 85.21L118.52 85.19" stroke="black" stroke-width="0.5" />
    <path d="M118.52 85.19L118.47 85.17" stroke="black" stroke-width="0.5" />
    <path d="M118.47 85.17L118.4 85.15" stroke="black" stroke-width="0.5" />
    <path d="M118.4 85.15L118.33 85.14" stroke="black" stroke-width="0.5" />
    <path d="M118.33 85.14L118.24 85.13" stroke="black" stroke-width="0.5" />
    <path d="M118.24 85.13L118.15 85.12" stroke="black" stroke-width="0.5" />
    <path d="M118.15 85.12L118.03 85.11" stroke="black" stroke-width="0.5" />
    <path d="M118.03 85.11L117.91 85.1" stroke="black" stroke-width="0.5" />
    <path d="M117.91 85.1L117.77 85.09" stroke="black" stroke-width="0.5" />
    <path d="M117.77 85.09H117.61" stroke="black" stroke-width="0.5" />
    <path d="M117.61 85.09L117.43 85.08" stroke="black" stroke-width="0.5" />
    <path d="M117.43 85.08H117.25" stroke="black" stroke-width="0.5" />
    <path d="M117.25 85.08L117.05 85.07" stroke="black" stroke-width="0.5" />
    <path d="M117.05 85.07H116.85" stroke="black" stroke-width="0.5" />
    <path d="M116.85 85.07H116.63" stroke="black" stroke-width="0.5" />
    <path d="M116.63 85.07L116.42 85.06" stroke="black" stroke-width="0.5" />
    <path d="M116.42 85.06H116.2" stroke="black" stroke-width="0.5" />
    <path d="M116.2 85.06H115.98" stroke="black" stroke-width="0.5" />
    <path d="M115.98 85.06L115.77 85.07" stroke="black" stroke-width="0.5" />
    <path d="M115.77 85.07H115.56" stroke="black" stroke-width="0.5" />
    <path d="M115.56 85.07H115.35" stroke="black" stroke-width="0.5" />
    <path d="M115.35 85.07L115.16 85.08" stroke="black" stroke-width="0.5" />
    <path d="M115.16 85.08H114.97" stroke="black" stroke-width="0.5" />
    <path d="M114.97 85.08L114.8 85.09" stroke="black" stroke-width="0.5" />
    <path d="M114.8 85.09H114.64" stroke="black" stroke-width="0.5" />
    <path d="M114.64 85.09L114.49 85.1" stroke="black" stroke-width="0.5" />
    <path d="M114.49 85.1L114.37 85.11" stroke="black" stroke-width="0.5" />
    <path d="M114.37 85.11L114.26 85.12" stroke="black" stroke-width="0.5" />
    <path d="M114.26 85.12L114.16 85.13" stroke="black" stroke-width="0.5" />
    <path d="M114.16 85.13L114.07 85.15" stroke="black" stroke-width="0.5" />
    <path d="M114.07 85.15L114 85.16" stroke="black" stroke-width="0.5" />
    <path d="M114 85.16L113.94 85.18" stroke="black" stroke-width="0.5" />
    <path d="M113.94 85.18L113.89 85.2" stroke="black" stroke-width="0.5" />
    <path d="M113.89 85.2L113.85 85.22" stroke="black" stroke-width="0.5" />
    <path d="M113.85 85.22L113.81 85.24" stroke="black" stroke-width="0.5" />
    <path d="M113.81 85.24L113.78 85.27" stroke="black" stroke-width="0.5" />
    <path d="M113.78 85.27L113.76 85.3" stroke="black" stroke-width="0.5" />
    <path d="M113.76 85.3L113.75 85.33" stroke="black" stroke-width="0.5" />
    <path d="M113.75 85.33L113.73 85.36" stroke="black" stroke-width="0.5" />
    <path d="M113.73 85.36L113.72 85.4" stroke="black" stroke-width="0.5" />
    <path d="M113.72 85.4V85.45" stroke="black" stroke-width="0.5" />
    <path d="M113.72 85.45L113.71 85.49" stroke="black" stroke-width="0.5" />
    <path d="M113.71 85.49L113.7 85.54" stroke="black" stroke-width="0.5" />
    <path d="M113.7 85.54V85.6" stroke="black" stroke-width="0.5" />
    <path d="M113.7 85.6V85.65" stroke="black" stroke-width="0.5" />
    <path d="M113.7 85.65L113.69 85.71" stroke="black" stroke-width="0.5" />
    <path d="M113.69 85.71V85.77" stroke="black" stroke-width="0.5" />
    <path d="M113.69 85.77V85.84" stroke="black" stroke-width="0.5" />
    <path d="M113.69 85.84V85.91" stroke="black" stroke-width="0.5" />
    <path d="M113.69 85.91V85.98" stroke="black" stroke-width="0.5" />
    <path d="M113.69 85.98V86.05" stroke="black" stroke-width="0.5" />
    <path d="M113.69 86.05V86.12" stroke="black" stroke-width="0.5" />
    <path d="M113.69 86.12L113.7 86.2" stroke="black" stroke-width="0.5" />
    <path d="M113.7 86.2L113.71 86.27" stroke="black" stroke-width="0.5" />
    <path d="M113.71 86.27L113.72 86.35" stroke="black" stroke-width="0.5" />
    <path d="M113.72 86.35L113.74 86.42" stroke="black" stroke-width="0.5" />
    <path d="M113.74 86.42L113.77 86.49" stroke="black" stroke-width="0.5" />
    <path d="M113.77 86.49L113.8 86.56" stroke="black" stroke-width="0.5" />
    <path d="M113.8 86.56L113.84 86.63" stroke="black" stroke-width="0.5" />
    <path d="M113.84 86.63L113.89 86.7" stroke="black" stroke-width="0.5" />
    <path d="M113.89 86.7L113.94 86.76" stroke="black" stroke-width="0.5" />
    <path d="M113.94 86.76L114 86.82" stroke="black" stroke-width="0.5" />
    <path d="M114 86.82L114.06 86.88" stroke="black" stroke-width="0.5" />
    <path d="M114.06 86.88L114.12 86.93" stroke="black" stroke-width="0.5" />
    <path d="M114.12 86.93L114.19 86.98" stroke="black" stroke-width="0.5" />
    <path d="M114.19 86.98L114.25 87.03" stroke="black" stroke-width="0.5" />
    <path d="M114.25 87.03L114.32 87.06" stroke="black" stroke-width="0.5" />
    <path d="M114.32 87.06L114.39 87.1" stroke="black" stroke-width="0.5" />
    <path d="M114.39 87.1L114.46 87.13" stroke="black" stroke-width="0.5" />
    <path d="M114.46 87.13L114.54 87.16" stroke="black" stroke-width="0.5" />
    <path d="M114.54 87.16L114.61 87.18" stroke="black" stroke-width="0.5" />
    <path d="M114.61 87.18L114.68 87.21" stroke="black" stroke-width="0.5" />
    <path d="M114.68 87.21L114.76 87.23" stroke="black" stroke-width="0.5" />
    <path d="M114.76 87.23L114.84 87.25" stroke="black" stroke-width="0.5" />
    <path d="M114.84 87.25L114.91 87.27" stroke="black" stroke-width="0.5" />
    <path d="M114.91 87.27L114.99 87.29" stroke="black" stroke-width="0.5" />
    <path d="M114.99 87.29L115.07 87.31" stroke="black" stroke-width="0.5" />
    <path d="M115.07 87.31L115.15 87.33" stroke="black" stroke-width="0.5" />
    <path d="M115.15 87.33L115.24 87.34" stroke="black" stroke-width="0.5" />
    <path d="M115.24 87.34L115.32 87.36" stroke="black" stroke-width="0.5" />
    <path d="M115.32 87.36L115.41 87.37" stroke="black" stroke-width="0.5" />
    <path d="M115.41 87.37L115.5 87.38" stroke="black" stroke-width="0.5" />
    <path d="M115.5 87.38L115.6 87.39" stroke="black" stroke-width="0.5" />
    <path d="M115.6 87.39L115.7 87.4" stroke="black" stroke-width="0.5" />
    <path d="M115.7 87.4L115.8 87.41" stroke="black" stroke-width="0.5" />
    <path d="M115.8 87.41L115.9 87.42" stroke="black" stroke-width="0.5" />
    <path d="M115.9 87.42H116" stroke="black" stroke-width="0.5" />
    <path d="M116 87.42H116.1" stroke="black" stroke-width="0.5" />
    <path d="M116.1 87.42H116.21" stroke="black" stroke-width="0.5" />
    <path d="M116.21 87.42H116.31" stroke="black" stroke-width="0.5" />
    <path d="M116.31 87.42H116.41" stroke="black" stroke-width="0.5" />
    <path d="M116.41 87.42H116.52" stroke="black" stroke-width="0.5" />
    <path d="M116.52 87.42L116.62 87.41" stroke="black" stroke-width="0.5" />
    <path d="M116.62 87.41H116.71" stroke="black" stroke-width="0.5" />
    <path d="M116.71 87.41L116.81 87.4" stroke="black" stroke-width="0.5" />
    <path d="M116.81 87.4L116.9 87.39" stroke="black" stroke-width="0.5" />
    <path d="M116.9 87.39L116.98 87.38" stroke="black" stroke-width="0.5" />
    <path d="M116.98 87.38L117.06 87.37" stroke="black" stroke-width="0.5" />
    <path d="M117.06 87.37L117.14 87.36" stroke="black" stroke-width="0.5" />
    <path d="M117.14 87.36L117.21 87.35" stroke="black" stroke-width="0.5" />
    <path d="M117.21 87.35L117.28 87.33" stroke="black" stroke-width="0.5" />
    <path d="M117.28 87.33L117.35 87.32" stroke="black" stroke-width="0.5" />
    <path d="M117.35 87.32L117.42 87.3" stroke="black" stroke-width="0.5" />
    <path d="M117.42 87.3L117.48 87.29" stroke="black" stroke-width="0.5" />
    <path d="M117.48 87.29L117.55 87.27" stroke="black" stroke-width="0.5" />
    <path d="M117.55 87.27L117.61 87.25" stroke="black" stroke-width="0.5" />
    <path d="M117.61 87.25L117.68 87.23" stroke="black" stroke-width="0.5" />
    <path d="M117.68 87.23L117.74 87.21" stroke="black" stroke-width="0.5" />
    <path d="M117.74 87.21L117.8 87.19" stroke="black" stroke-width="0.5" />
    <path d="M117.8 87.19L117.86 87.17" stroke="black" stroke-width="0.5" />
    <path d="M117.86 87.17L117.92 87.15" stroke="black" stroke-width="0.5" />
    <path d="M117.92 87.15L117.97 87.13" stroke="black" stroke-width="0.5" />
    <path d="M117.97 87.13L118.02 87.12" stroke="black" stroke-width="0.5" />
    <path d="M136.63 56.93H142.21" stroke="black" stroke-width="0.5" />
    <path d="M142.21 56.93V62.22" stroke="black" stroke-width="0.5" />
    <path d="M142.21 62.22H136.63" stroke="black" stroke-width="0.5" />
    <path d="M136.63 62.22V56.93" stroke="black" stroke-width="0.5" />
    <path d="M135.88 63.59H142.27" stroke="black" stroke-width="0.5" />
    <path d="M136.63 61.55H135.88" stroke="black" stroke-width="0.5" />
    <path d="M136.63 56.93L142.21 62.07" stroke="black" stroke-width="0.5" />
    <path d="M142.21 56.93L136.63 62.22" stroke="black" stroke-width="0.5" />
    <path d="M191.2 72.88H196.75" stroke="black" stroke-width="0.5" />
    <path d="M196.75 72.88V93.89" stroke="black" stroke-width="0.5" />
    <path d="M196.75 93.89H191.2" stroke="black" stroke-width="0.5" />
    <path d="M191.2 93.89V72.88" stroke="black" stroke-width="0.5" />
    <path d="M194.39 72.88V74.08" stroke="black" stroke-width="0.5" />
    <path d="M194.39 74.74V76.07" stroke="black" stroke-width="0.5" />
    <path d="M194.39 76.74V78.07" stroke="black" stroke-width="0.5" />
    <path d="M194.39 78.73V80.06" stroke="black" stroke-width="0.5" />
    <path d="M194.39 80.73V82.06" stroke="black" stroke-width="0.5" />
    <path d="M194.39 82.72V84.05" stroke="black" stroke-width="0.5" />
    <path d="M194.39 84.72V86.05" stroke="black" stroke-width="0.5" />
    <path d="M194.39 86.71V88.04" stroke="black" stroke-width="0.5" />
    <path d="M194.39 88.71V90.04" stroke="black" stroke-width="0.5" />
    <path d="M194.39 90.7V92.03" stroke="black" stroke-width="0.5" />
    <path d="M194.39 92.69V93.89" stroke="black" stroke-width="0.5" />
    <path d="M249.82 128.68H249.24" stroke="black" stroke-width="0.5" />
    <path d="M249.24 128.68V127.54" stroke="black" stroke-width="0.5" />
    <path d="M249.24 127.54H254.43" stroke="black" stroke-width="0.5" />
    <path d="M254.43 127.54V128.68" stroke="black" stroke-width="0.5" />
    <path d="M254.43 128.68H253.86" stroke="black" stroke-width="0.5" />
    <path d="M253.86 128.68V129.35" stroke="black" stroke-width="0.5" />
    <path d="M253.86 129.35H249.82" stroke="black" stroke-width="0.5" />
    <path d="M249.82 129.35V128.68" stroke="black" stroke-width="0.5" />
    <path d="M288.4 95.38H290.05" stroke="black" stroke-width="0.5" />
    <path d="M290.72 95.38H292.05" stroke="black" stroke-width="0.5" />
    <path d="M292.71 95.38H294.37" stroke="black" stroke-width="0.5" />
    <path d="M288.4 90.61H290.05" stroke="black" stroke-width="0.5" />
    <path d="M290.72 90.61H292.05" stroke="black" stroke-width="0.5" />
    <path d="M292.71 90.61H294.37" stroke="black" stroke-width="0.5" />
    <path d="M288.4 78.13H290.05" stroke="black" stroke-width="0.5" />
    <path d="M290.72 78.13H292.05" stroke="black" stroke-width="0.5" />
    <path d="M292.71 78.13H294.37" stroke="black" stroke-width="0.5" />
    <path d="M249.39 54.26V53.52" stroke="black" stroke-width="0.5" />
    <path d="M249.39 53.52H250.46" stroke="black" stroke-width="0.5" />
    <path d="M251.13 53.52H252.46" stroke="black" stroke-width="0.5" />
    <path d="M253.12 53.52H254.45" stroke="black" stroke-width="0.5" />
    <path d="M255.12 53.52H256.45" stroke="black" stroke-width="0.5" />
    <path d="M257.11 53.52H258.44" stroke="black" stroke-width="0.5" />
    <path d="M259.11 53.52H260.44" stroke="black" stroke-width="0.5" />
    <path d="M261.1 53.52H262.43" stroke="black" stroke-width="0.5" />
    <path d="M263.09 53.52H264.17" stroke="black" stroke-width="0.5" />
    <path d="M264.17 53.52V54.26" stroke="black" stroke-width="0.5" />
    <path d="M314.81 98.26H324.45" stroke="black" stroke-width="0.5" />
    <path d="M313.48 99.31V91.73" stroke="black" stroke-width="0.5" />
    <path d="M313.48 91.73H314.81" stroke="black" stroke-width="0.5" />
    <path d="M314.81 91.73V99.31" stroke="black" stroke-width="0.5" />
    <path d="M314.81 99.31H313.48" stroke="black" stroke-width="0.5" />
    <path d="M314.14 98.78V92.26" stroke="black" stroke-width="0.5" />
    <path d="M313.48 92.26H314.81" stroke="black" stroke-width="0.5" />
    <path d="M314.81 98.78H313.48" stroke="black" stroke-width="0.5" />
    <path d="M313.48 99.31H314.81" stroke="black" stroke-width="0.5" />
    <path d="M313.48 91.73V74.74" stroke="black" stroke-width="0.5" />
    <path d="M313.48 74.74H320.99" stroke="black" stroke-width="0.5" />
    <path d="M317.61 74.74L317.62 76.31" stroke="black" stroke-width="0.5" />
    <path d="M317.62 76.97V78.3" stroke="black" stroke-width="0.5" />
    <path d="M317.62 78.97L317.63 80.3" stroke="black" stroke-width="0.5" />
    <path d="M317.63 80.96V82.29" stroke="black" stroke-width="0.5" />
    <path d="M317.63 82.96V84.29" stroke="black" stroke-width="0.5" />
    <path d="M317.64 84.95V86.28" stroke="black" stroke-width="0.5" />
    <path d="M317.64 86.94V88.27" stroke="black" stroke-width="0.5" />
    <path d="M317.64 88.94L317.65 90.51" stroke="black" stroke-width="0.5" />
    <path d="M320.99 90.51H313.48" stroke="black" stroke-width="0.5" />
    <path d="M311.82 70.02H315.31" stroke="black" stroke-width="0.5" />
    <path d="M315.31 71.65V68.38" stroke="black" stroke-width="0.5" />
    <path d="M311.82 71.65V68.38" stroke="black" stroke-width="0.5" />
    <path d="M314.74 50.24V27.63" stroke="black" stroke-width="0.5" />
    <path d="M314.74 32.55H315.54" stroke="black" stroke-width="0.5" />
    <path d="M316.2 32.55H317.53" stroke="black" stroke-width="0.5" />
    <path d="M318.2 32.55H319.53" stroke="black" stroke-width="0.5" />
    <path d="M320.19 32.55H320.99" stroke="black" stroke-width="0.5" />
    <path d="M317.65 27.63V29.02" stroke="black" stroke-width="0.5" />
    <path d="M317.65 29.68V31.01" stroke="black" stroke-width="0.5" />
    <path d="M317.65 31.68V33.01" stroke="black" stroke-width="0.5" />
    <path d="M317.65 33.67V35" stroke="black" stroke-width="0.5" />
    <path d="M317.65 35.67V37" stroke="black" stroke-width="0.5" />
    <path d="M317.65 37.66V38.99" stroke="black" stroke-width="0.5" />
    <path d="M317.65 39.66V40.99" stroke="black" stroke-width="0.5" />
    <path d="M317.65 41.65V42.98" stroke="black" stroke-width="0.5" />
    <path d="M317.65 43.65V44.98" stroke="black" stroke-width="0.5" />
    <path d="M317.65 45.64V46.97" stroke="black" stroke-width="0.5" />
    <path d="M317.65 47.64V49.02" stroke="black" stroke-width="0.5" />
    <path d="M314.74 45.22H315.54" stroke="black" stroke-width="0.5" />
    <path d="M316.2 45.22H317.53" stroke="black" stroke-width="0.5" />
    <path d="M318.2 45.22H319.53" stroke="black" stroke-width="0.5" />
    <path d="M320.19 45.22H320.99" stroke="black" stroke-width="0.5" />
    <path d="M354.24 91.73H356.13" stroke="black" stroke-width="0.5" />
    <path d="M356.13 91.73V98.45" stroke="black" stroke-width="0.5" />
    <path d="M356.13 98.45H354.24" stroke="black" stroke-width="0.5" />
    <path d="M354.24 98.45V91.73" stroke="black" stroke-width="0.5" />
  </svg>
</template>
