import { markRaw } from 'vue';

import VAV159Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite103/VAV159Drawing.vue';
import VAV160Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_1st_Floor/SuitesVAVs/Suite103/VAV160Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

import { IHotspot } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList: IHotspot<Record<string, unknown>>[] = [
  {
    id: 0,
    name: 'VAV 1-59',
    controlId: 2172,
    component: markRaw(VAV159Hotspot),
    techInfo: {
      device: {
        label: 'VAV 1-59',
      },
    },
    style: {
      position: 'absolute',
      top: '6.16%',
      right: '16.6%',
      width: '90.7%',
      height: '90.7%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '12%',
      left: '35%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 1-60',
    controlId: 2173,
    component: markRaw(VAV160Hotspot),
    techInfo: {
      device: {
        label: 'VAV 1-60',
      },
    },
    style: {
      position: 'absolute',
      top: '21%',
      right: '20.2%',
      width: '75.7%',
      height: '75.7%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '55%',
      left: '40%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
