import { IVAV } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const apiVersion = 'v1';

const getVAVsBySiteId = async (siteId: string | number) => {
  const response = await window.axios.get(
    `${apiVersion}/VariableAirVolumeUnits/latest-readings?siteid=${siteId}`
  );

  const { data } = response.data;
  return data as IVAV[];
};

const setVAVSetpoint = async (
  vavId: number,
  setpoint: number
): Promise<void> => {
  try {
    await window.axios.put(
      `/${apiVersion}/VariableAirVolumeUnits/${vavId}/settings`,
      {
        changedSettings: {
          occupiedSetpoint: setpoint,
        },
      }
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

const getVAVSettingsMessages = async (vavId: number) => {
  try {
    const response = await window.axios.get(
      `${apiVersion}/variableairvolumeunits/${vavId}/history`
    );

    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const listOfVavSitesForCurrentUser = async () => {
  try {
    const response = await window.axios.get(
      `${apiVersion}/variableairvolumeunits/sites`
    );
    return response.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateVAVFriendlyName = async (vav: IVAV) => {
  const {
    controlId,
    controlFriendlyName: friendlyName,
    siteLocationId,
    equipmentId = null,
  } = vav;
  try {
    await window.axios.put(
      `${apiVersion}/VariableAirVolumeUnits/${controlId}`,
      {
        friendlyName,
        siteLocationId,
        equipmentId,
        isActive: true,
      }
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getVAVsBySiteId,
  setVAVSetpoint,
  getVAVSettingsMessages,
  listOfVavSitesForCurrentUser,
  updateVAVFriendlyName,
};
