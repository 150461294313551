<!-- eslint-disable vue/valid-v-for -->
<template>
  <v-sheet flat border class="pa-4 col-sm-12">
    <p class="text-subtitle-1 pt-3 text-primary mb-3">
      <span class="font-weight-bold">Power Control</span>
      - Input values at execution time
    </p>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="localInputData.ControlId"
          :items="inputList"
          :item-title="(item) => item.Name || ''"
          :item-value="(item) => item.Id"
          label="Control Name"
          variant="outlined"
          density="compact"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.CurrentDateTime"
          :rules="[isDate]"
          variant="outlined"
          density="compact"
          label="Current Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.ReadingDateTime"
          :rules="[isDate]"
          variant="outlined"
          density="compact"
          label="Reading Date/Time"
          type="datetime-local"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.DelayInMinutes"
          :rules="[isNumberGreaterThanZero]"
          variant="outlined"
          density="compact"
          label="Delay in Minutes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.PowerControlStatus"
          :rules="[(v) => /^(on|off)$/i.test(v)]"
          variant="outlined"
          density="compact"
          label="Power Control On/Off Status"
        />
      </v-col>
      <v-col sm="4">
        <v-text-field
          v-model="localInputData.StatusDurationInMinutes"
          :rules="[isNumberGreaterThanZero]"
          variant="outlined"
          density="compact"
          label="Power Control Status Duration in Minutes"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import {
  isNumberGreaterThanZero,
  hasValue,
  isDate,
} from '@/Core.Patterns/Factory/validationFactory.ts';

export default {
  name: 'PowerControlInput',

  data() {
    return {
      devices: [],
      localInputData: { ...this.InputData },
    };
  },

  computed: {
    inputList() {
      // see if we have a list of datasources of this very object in datasources
      const inputs = this.DataSources?.find(
        (ds) => ds.Key === '_selfList'
      )?.Value;
      if (inputs) return inputs;
      // if not, we'll create one based on InputData.ControlId/Name/FriendlyName values
      return [
        {
          Id: this.InputData.ControlId,
          DeviceName: this.InputData.ControlName,
          FriendlyName: this.InputData.ControlFriendlyName,
          Name: `${this.InputData.ControlFriendlyName} ( ${this.InputData.ControlName} )`,
        },
      ];
    },
  },

  props: {
    InputData: {
      type: Object,
      required: true,
    },
    DataSources: {
      type: Array,
      required: true,
    },
  },

  watch: {
    InputData: {
      handler(newVal) {
        this.localInputData = { ...newVal };
      },
      deep: true,
    },

    localInputData: {
      handler() {
        // this handles the case where a user changes an entry in the Controls v-select
        // since we only get the ControlId (v-model), we need to set the rest
        // console.debug(`watch.InputData - selected item: ${this.InputData.ControlId}, name=${this.InputData.ControlName}, friendly=${this.InputData.ControlFriendlyName}`);
        const selected = this.inputList.find(
          (i) => i.Id == this.localInputData.ControlId
        );
        if (selected) {
          this.localInputData.ControlName = selected.Name;
          this.localInputData.ControlFriendlyName = selected.FriendlyName;
        } else {
          this.localInputData.ControlName = null;
          this.localInputData.ControlFriendlyName = null;
        }
        console.debug(
          `watch.InputData - updated: ${this.localInputData.ControlId}, name=${this.localInputData.ControlName}, friendly=${this.localInputData.ControlFriendlyName}`
        );
        this.$emit('update:model', this.localInputData);
      },
      deep: true,
    },
  },

  methods: {
    isNumberGreaterThanZero,
    hasValue,
    isDate,
  },
};
</script>
