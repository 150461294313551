import { IVAV } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

interface IContext {
  commit: (mutation: string, payload: IVAV) => void;
}

const setVAVEdit = (context: IContext, vav: IVAV) => {
  context.commit('SET_VAV_EDIT', vav);
};

export default {
  setVAVEdit,
};
