<template>
  <v-container fluid>
    <v-form @submit.prevent="submit" validate-on="input">
      <v-card class="pa-4 rounded-2">
        <v-row>
          <v-col sm="6">
            <v-select
              item-value="inputKey"
              item-title="typeName"
              v-model="strategyTriggerByName"
              label="Strategy Trigger"
              return-object
              disabled
              variant="outlined"
              density="compact"
            ></v-select>
          </v-col>
          <v-col sm="6">
            <v-select
              :items="frequencies"
              item-value="id"
              item-title="text"
              :rules="[(v) => !!v || 'Execute frequency is required']"
              v-model="strategy.executionFrequency"
              label="Execution Frequency"
              hint="How often should the rule be executed?"
              persistent-hint
              variant="outlined"
              density="compact"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <v-text-field
              autofocus
              v-model="strategy.name"
              label="Strategy Name"
              :rules="[(v) => !!v || 'Strategy Name is required']"
              required
              variant="outlined"
              density="compact"
            />
          </v-col>
          <v-col sm="6">
            <v-text-field
              v-model="strategy.description"
              label="Strategy Description"
              :rules="[(v) => !!v || 'Strategy Description is required']"
              variant="outlined"
              density="compact"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-card border>
              <v-card-text id="divRuleEditor" ref="divRuleEditor"></v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card border>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-title>
                    <p class="text-h6 text-primary">
                      <v-icon color="primary">
                        mdi-beaker-alert-outline
                      </v-icon>
                      Click here to test your strategy with different input
                      values. This will not save nor affect the actual strategy.
                    </p>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-row>
                      <v-card
                        v-if="isComponentNameValid"
                        class="mx-3"
                        width="100%"
                        flat
                      >
                        <component
                          :is="inputName"
                          :InputData="device"
                          :DataSources="dataSources"
                          @update:model="handleDeviceData"
                        />
                      </v-card>
                      <v-card v-else class="mx-3" width="100%">
                        <v-card-text>
                          <span class="text-subtitle-1">
                            Unknown Input Name: {{ inputName }}
                          </span>
                        </v-card-text>
                      </v-card>
                    </v-row>

                    <v-row v-if="isComponentNameValid">
                      <v-col cols="12">
                        <span class="text-subtitle-1 text-primary">
                          Enter input values above, then click "Evaluate
                          Strategy" to simulate what would happen during a real
                          execution.
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn @click="evaluateStrategy" :loading="isLoading">
                          Evaluate Strategy
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              type="submit"
              color="primary"
              :disabled="ruleInvalid"
              class="mr-5"
            >
              Save
            </v-btn>
            <v-btn
              @click="saveAs"
              color="accent"
              class="mr-5"
              :disabled="ruleInvalid"
            >
              Save As
            </v-btn>
            <v-btn color="primary" @click="validateRule">Validate</v-btn>
            <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-container>
  <StepperOverlay
    :showOverlay="showOverlay"
    :replayMessages="messages"
    :isLoading="isLoading"
    :stepperTitle="`Evaluating the strategy`"
    @close="showOverlay = false"
  />
  <v-row justify="center">
    <v-dialog v-model="showConfirm" width="500">
      <v-card>
        <v-card-title class="text-h5">Enter new name for strategy</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field v-model="saveAsName" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="handleCancel" variant="text" colr="blue darken-1">
            Cancel
          </v-btn>
          <v-btn @click="handleOk" variant="text" color="blue-darken-1">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss">
div.ceRule {
  min-height: 20vh;
}
</style>

<script>
import api from './_api';
import API from '@/Core.Service.Domain/Strategies/Strategies/API';
import { Form } from 'vee-validate';

import StepperOverlay from '@/Core.Service.Domain/Strategies/Strategies/Components/StepperOverlay.vue';
import InputIaqVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/IndoorAirQuality.vue';
import InputFiveMinutesTotalEnergyWithWeatherVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/FiveMinutesTotalEnergyWithWeather.vue';
import InputPowerControlVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/PowerControl.vue';
import InputPricingDemandManagementVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/PricingDemandManagement.vue';
import InputSolarPredictionVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/SolarPrediction.vue';
import InputThermostatVue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/Thermostat.vue';
import InputDemandResponseV1Vue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/DemandResponseV1.vue';
import InputEnergyEndUsePowerV3Vue from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/EnergyEndUsePowerV3.vue';
import DeviceStatusV1 from '@/Core.Service.Domain/Strategies/Strategies/Components/InputTypeEditors/DeviceStatusV1.vue';

import { capitalizeKeys } from '@/Core.Patterns/Adapter';

export default {
  name: 'EditStrategyCard',
  components: {
    'validation-observer': Form,
    StepperOverlay,
    IndoorAirQuality: InputIaqVue,
    FiveMinutesTotalEnergyWithWeather:
      InputFiveMinutesTotalEnergyWithWeatherVue,
    PowerControl: InputPowerControlVue,
    PricingDemandManagement: InputPricingDemandManagementVue,
    SolarPrediction: InputSolarPredictionVue,
    Thermostat: InputThermostatVue,
    DemandResponseV1: InputDemandResponseV1Vue,
    EnergyEndUsePowerV3: InputEnergyEndUsePowerV3Vue,
    DeviceStatusV1,
  },

  computed: {
    inputName() {
      if (this.strategy.inputTypeName) {
        const typeParts = this.strategy.inputTypeName.split('.');
        return typeParts[typeParts.length - 1];
      }
      return 'Not yet selected';
    },

    isComponentNameValid() {
      const componentNames = [
        'IndoorAirQuality',
        'FiveMinutesTotalEnergyWithWeather',
        'PowerControl',
        'PricingDemandManagement',
        'SolarPrediction',
        'Thermostat',
        'DemandResponseV1',
        'DeviceStatusV1',
        'EnergyEndUsePowerV2',
      ];
      return componentNames.includes(this.inputName);
    },

    strategyTriggerByName() {
      return this.inputTypes.find(
        (inputType) => inputType.inputKey === this.strategy.inputTypeName
      )?.typeName;
    },
  },

  data() {
    return {
      ruleInvalid: true,
      siteId: null,
      inputTypes: [],
      ruleEditor: null,
      strategyName: '',
      strategyId: null,
      strategy: {},
      frequencies: [
        { id: 'EveryFiveMinutes', text: 'Every 5 minutes' },
        { id: 'EveryTenMinutes', text: 'Every 10 minutes' },
        { id: 'EveryFifteenMinutes', text: 'Every 15 minutes' },
        { id: 'EveryThirtyMinutes', text: 'Every 30 minutes' },
        { id: 'EveryHour', text: 'Every hour' },
        { id: 'EveryDay', text: 'Every day (at midnight)' },
        { id: 'OnDemand', text: 'On Demand / External Trigger' },
      ],
      selectedFrequency: null,
      strategyDescription: '',
      selectedInput: null,
      showConfirm: false,
      saveAsName: null,
      templates: [
        {
          id: 0,
          name: 'Please select a Strategy Trigger first',
          inputType: null,
        },
      ],
      selectedTemplate: null,
      device: {},
      deviceData: {},
      dataSources: [],
      showOverlay: false,
      isLoading: false,
      messages: [],
    };
  },

  async created() {
    if (window.$rule === undefined) {
      //load static codeeffects assets
      const codeEffectsScript = document.createElement('script');
      codeEffectsScript.setAttribute('src', '/js/codeeffects.editor.js');
      document.head.appendChild(codeEffectsScript);

      const cssLink1 = document.createElement('link');
      cssLink1.setAttribute('rel', 'stylesheet');
      cssLink1.setAttribute('href', '/js/codeeffects.common.e360.custom.css');
      document.head.appendChild(cssLink1);

      const cssLink2 = document.createElement('link');
      cssLink2.setAttribute('rel', 'stylesheet');
      cssLink2.setAttribute('href', '/js/codeeffects.gray.e360.custom.css');
      document.head.appendChild(cssLink2);
    }

    this.strategyId = this.$route.params.strategyId;
    this.siteId = this.$route.params.siteId;
    this.strategy = await api.getStrategyById(this.siteId, this.strategyId);
    this.inputTypes = await api.getInputTypes(this.siteId);
    this.editorSettings = await api.getEditorSettingsForStrategy(
      this.siteId,
      this.strategy.name
    );

    const inputs = await api.getInputList(
      this.siteId,
      this.strategy.inputTypeName
    );
    const dataSources = this.editorSettings.dataSources;
    if (inputs.length > 0) {
      dataSources.push({ Key: '_selfList', Value: inputs });
    }
    this.dataSources = capitalizeKeys(dataSources);

    // there's a window.$rule, so we'll assign it to a local variable to make things easier
    const $rule = window['$rule'];
    //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
    this.ruleEditor = $rule.Context.getControl('divRuleEditor');
    if (this.ruleEditor != null) this.ruleEditor.dispose();
    $rule.Context.clear();

    //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
    this.ruleEditor = $rule.init(this.editorSettings.editorData);
    this.ruleEditor.clear();
    this.ruleEditor.loadSettings(this.editorSettings.sourceData);
    this.ruleEditor.loadRule(this.editorSettings.ruleData);

    this.ruleInvalid = false;
  },

  methods: {
    async submit(event) {
      const { valid } = await event;
      if (!valid) {
        return Promise.reject({
          Error: 'Please fill in all required fields',
        });
      }

      const ruleContent = this.ruleEditor.extract();
      await api.updateStrategy(
        this.strategy.id,
        this.strategy.siteId,
        this.strategy.name,
        this.strategy.description,
        this.strategy.inputTypeName,
        this.strategy.executionFrequency,
        ruleContent
      );
      const options = {
        title: 'Success',
        message: 'Successfully updated your strategy.',
        status: 'success',
      };
      this.$toast.show(options);
      this.$router.push({ name: 'StrategiesList' });
    },

    saveAs() {
      this.showConfirm = true;
      this.saveAsName = this.strategy.name + ' (copy)';
    },
    handleOk() {
      this.showConfirm = false;
      this.strategy.id = null;
      this.strategy.name = this.saveAsName;
      this.postStrategy();
    },
    handleCancel() {
      this.saveAsName = this.strategy.name;
      this.showConfirm = false;
    },
    async postStrategy() {
      const ruleContent = this.ruleEditor.extract();
      await api.saveStrategy(
        this.strategy.siteId,
        this.strategy.name,
        this.strategy.description,
        this.strategy.inputTypeName,
        this.strategy.executionFrequency,
        ruleContent
      );
      const options = {
        title: 'Success',
        message: 'Successfully saved as strategy.',
        status: 'success',
      };
      this.$toast.show(options);
      this.$router.push({ name: 'StrategiesList' });
    },

    async validateRule() {
      const ruleContent = this.ruleEditor.extract();
      const validationResult = await api.validateRule(
        this.siteId,
        this.strategy.inputTypeName,
        this.strategy.name,
        this.strategy.description,
        this.strategy.executionFrequency,
        ruleContent
      );

      if (validationResult.empty) {
        const options = {
          title: 'Validation',
          message: 'Rule cannot be empty.',
          status: 'error',
        };
        this.$toast.show(options);
        this.ruleInvalid = true;
        return;
      }

      if (!validationResult.valid) {
        try {
          this.ruleEditor.loadInvalids(validationResult.clientInvalidData);
        } catch {
          const options = {
            title: 'Validation',
            message: `Rule is invalid: ${validationResult.clientInvalidData}`,
            status: 'error',
          };
          this.$toast.show(options);
        }
        this.ruleInvalid = true;
      } else {
        const options = {
          title: 'Success',
          message: 'Rule is valid and can be saved.',
          status: 'success',
        };
        this.$toast.show(options);
        this.ruleInvalid = false;
      }
    },

    async loadInput(input) {
      this.editorSettings = await api.getEditorSettingsForInput(
        this.siteId,
        input
      );

      const inputs = await api.getInputList(this.siteId, input.inputKey);
      const dataSources = this.editorSettings.dataSources;
      if (inputs.length > 0) {
        dataSources.push({ Key: '_selfList', Value: inputs });
      }
      this.dataSources = capitalizeKeys(dataSources);

      // there's a window.$rule, so we'll assign it to a local variable to make things easier
      let $rule = window['$rule'];
      //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
      this.ruleEditor = $rule.Context.getControl('divRuleEditor');
      if (this.ruleEditor != null) this.ruleEditor.dispose();
      $rule.Context.clear();

      //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
      this.ruleEditor = $rule.init(this.editorSettings.editorData);
      this.ruleEditor.clear();
      this.ruleEditor.loadSettings(this.editorSettings.sourceData);
      // this.ruleEditor.loadRule(this.editorSettings.ruleData);
      let $re = this.ruleEditor;
      // TODO: docs for extract claim to show if it's valid, but really don't
      //       Keep an eye on their answer to my August 27, 2021 07:54 PM questions
      //       https://codeeffects.com/Doc/Business-Rule-Ajax-Api
      //       Their demo tests the rule on save
      this.ruleEditor.onBlur(function () {
        console.log(`Editor changed: ${$re.isAltered()}`);
        // console.log("Editor extract:", $re.extract());
      });
    },

    async evaluateStrategy() {
      // const valid = await this.$refs.obs.validate();
      // if (!valid) {
      //   return;
      // }
      this.messages = [];
      try {
        const strategyId = this.strategyId;
        const ruleContent = this.ruleEditor.extract();

        this.isLoading = true;
        const response = await API.replayStrategy(strategyId, {
          ruleContent,
          inputType: this.strategy.inputTypeName,
          inputData: {
            ...this.deviceData,
            SiteId: this.siteId,
            StrategyId: this.strategyId,
            StrategyName: this.strategy.name,
          },
        });
        this.showOverlay = true;
        if (response.length === 0) {
          this.messages = [
            { message: 'No action taken -- likely no condition matched.' },
          ];
        } else {
          this.messages = response;
        }
      } catch (error) {
        console.error(`error evaluating strategy`, error);
        this.messages = [{ message: 'Error evaluating the strategy.' }];
      } finally {
        this.isLoading = false;
      }
    },

    handleCancelCrud() {
      this.$router.push({ name: 'StrategiesList' });
    },

    handleDeviceData(data) {
      this.deviceData = { ...data };
    },
  },
};
</script>
<style>
.ceMenu {
  z-index: 9999 !important;
}

.v-expansion-panel {
  border-top: none !important;
}
</style>
