import constants from '../constants';

const setSlicers = async function (
  reportParameters,
  visuals,
  loadFirst = true
) {
  if (loadFirst) {
    await setSiteSlicerVisual(reportParameters, visuals);
  }
  await setDateSlicerVisual(reportParameters, visuals);
  await setEndUseSlicerVisual(reportParameters, visuals);
};
const setSiteSlicerVisual = async function (reportParameters, visuals) {
  try {
    const siteData = getSiteDataBySiteName(reportParameters);
    const { SiteName } = reportParameters;
    const siteSlicerVisual = visuals.filter(function (visual) {
      return visual.type === 'slicer' && visual.title === 'Utility Slicer';
    })[0];

    const values = siteData ? [siteData.SiteName] : [SiteName];

    if (!SiteName && !siteData) {
      const siteSlicerState = await siteSlicerVisual.getSlicerState();

      if (siteSlicerVisual) {
        await siteSlicerVisual.setSlicerState(siteSlicerState);
      }
    } else {
      await siteSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: constants.BASIC_FILTER,
            target: {
              table: 'vwUtilityNames',
              column: 'UtilityName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const setDateSlicerVisual = async function (reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: constants.ADVANCED_FILTER,
    target: {
      table: 'vwUtilityFact',
      column: 'SiteDate',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };

  const regex = /Date\s+Slicer/gi;
  const dateSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && regex.test(visual.title);
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
  }
};

/**
 * Sets the End Use slicer visual based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @param {Array} visuals - The visuals array.
 * @param {String} uomName - The UOM name.
 * @param {Boolean} unsetFirstLoad - Whether to unset the first load.
 * @returns {Promise<void>}
 */
const setEndUseSlicerVisual = async (reportParameters, visuals) => {
  try {
    const siteData = getSiteDataBySiteName(reportParameters);

    const endUseSlicerVisual = visuals.find(
      (visual) => visual.type === 'slicer' && visual.title === 'End Use Slicer'
    );

    if (endUseSlicerVisual) {
      const values = siteData ? [siteData.EndUseName] : ['No Data to show'];

      await endUseSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: constants.BASIC_FILTER,
            target: {
              table: 'vwUtilityEndUseNames',
              column: 'EndUseName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

let utilityName = '';
let firstLoad = true;

/**
 * Retrieves the first site data based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @returns {Array} - The first site data.
 * @param {String} uomName - The UOM name.
 */
const getSiteDataBySiteName = (reportParameters) => {
  try {
    const { EndUseUtilities } = reportParameters;

    if (EndUseUtilities) {
      const parsedData = JSON.parse(EndUseUtilities);
      if (firstLoad) {
        utilityName = parsedData[0].UtilityName.trim().toLowerCase();
        firstLoad = false;
      } else {
        utilityName = reportParameters.SiteName.trim().toLowerCase();
      }

      const response = parsedData.find((data) => {
        return data.UtilityName.trim().toLowerCase() === utilityName;
      });

      return !response && firstLoad ? parsedData[0] : response;
    }

    return null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  setSlicers,
  setEndUseSlicerVisual,
};
