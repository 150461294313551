<template>
  <div>
    <StrategyTemplatesBar />
    <router-view class="mt-2" />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </div>
</template>

<script>
import StrategyTemplatesBar from '@/Core.Service.Domain/Strategies/StrategyTemplates/Components/StrategyTemplatesBar.vue';

import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

import { mapActions } from 'vuex';

export default {
  name: 'StrategyTemplatesPortfolio',

  components: {
    StrategyTemplatesBar,
    ProgressLinearLoaderVue,
  },

  computed: {
    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },

  async created() {
    try {
      await this.getStrategyTemplates();
    } catch (error) {
      return Promise.reject(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      getStrategyTemplates:
        'StrategiesStore/StrategyTemplatesStore/getStrategyTemplates',
    }),
  },
};
</script>
