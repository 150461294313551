const getUser = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/users/auth0/${auth0Id}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getAllUsers = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/users`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getOrphanAuth0Users = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/orphanusers`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const isAuth0UserOrphan = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/orphanusers/auth0/${auth0Id}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const deleteOrphanedAuth0User = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.delete(`/v1/orphanusers/delete/${auth0Id}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const createUser = async (formData) => {
  let result = null;
  try {
    result = await window.axios.post('/v1/users/auth0', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const inviteUser = async (formData) => {
  let result = null;
  try {
    result = await window.axios.post('/v1/users/invite', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const reInviteUser = async (auth0Id) => {
  let result = null;
  let user = { auth0Id: auth0Id };
  try {
    result = await window.axios.post('/v1/users/reinvite', user);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const createE360User = async (auth0Id) => {
  let result = null;
  try {
    const userFragment = auth0Id.startsWith('auth0|') ? 'e360' : 'sso';
    result = await window.axios.post(
      `/v1/users/${userFragment}/${auth0Id}`
    );
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const updateUser = async (formData) => {
  let result = null;
  try {
    result = await window.axios.put('/v1/users', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const deleteUser = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.delete(`/v1/users/${auth0Id}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getSites = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/sites/user/all`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getPortfolioForUser = async (auth0Id) => {
  try {
    let result = await window.axios.get(`/v1/sites/portfolio/${auth0Id}`);
    return result.data.data;
    //return result != undefined ? result.data.data : [];
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const getPowerBiReports = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/reports/powerbi/all`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};
const getReportsForUser = async (auth0Id) => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/reports/user/${auth0Id}`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getRoles = async () => {
  let result = null;
  try {
    result = await window.axios.get(`/v1/userroles/auth0/roles/all`);
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getRolesForUser = async (auth0Id) => {
  try {
    const result = await window.axios.get(
      `/v1/userroles/auth0/roles/user/${auth0Id}`
    );
    const { data } = result.data;
    return data;
  } catch (err) {
    return err;
  }
};

const updateUserRoles = async (auth0Id, roles) => {
  let result = null;
  try {
    result = await window.axios.post(
      `/v1/userroles/auth0/roles/user/update/${auth0Id}`,
      roles
    );
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const setDefaultUserRole = async (auth0Id) => {
  // auth0 users start with auth0;
  // SSO users start with various other strings based on connection type (okta|, oidc|, etc)
  if (!auth0Id.startsWith('auth0|')) return true;
  let result = null;
  try {
    result = await window.axios.post(
      `/v1/userroles/auth0/roles/user/default/${auth0Id}`
    );
    return result.data.Data || result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export default {
  getAllUsers,
  getOrphanAuth0Users,
  deleteUser,
  deleteOrphanedAuth0User,
  createUser,
  inviteUser,
  reInviteUser,
  getUser,
  updateUser,
  getSites,
  getPortfolioForUser,
  getPowerBiReports,
  getReportsForUser,
  getRoles,
  getRolesForUser,
  updateUserRoles,

  // NOTE:  these are called by store (imported and used by store).
  isAuth0UserOrphan,
  createE360User,
  setDefaultUserRole,
};
