<template>
  <Form ref="obs" v-slot="{ invalid }">
    <v-container grid-list-xl>
      <h1>Project Event Detail</h1>
      <form @submit.prevent="submit">
        <validation-summary :messages="server_errors" />
        <validated-text-field
          rules="required|duplicate_name"
          v-model="event.name"
          label="Name"
        ></validated-text-field>
        <v-row>
          <v-col>
            <validated-select-field
              rules="required"
              :items="siteOptions"
              :readonly="currentMode === mode.EDIT"
              v-model="event.siteId"
              label="Portfolio Site"
              hint="What site do you want to analyze"
              persistent-hint
              @change-value="setSite"
            ></validated-select-field>
          </v-col>
        </v-row>

        <validated-text-field
          rules="required"
          v-model="event.description"
          label="Description"
        ></validated-text-field>
        <validated-select-field
          rules="required"
          :items="typeOptions"
          v-model="event.type"
          label="Event Type"
          hint="select an event type"
          persistent-hint
        ></validated-select-field>
        <start-end-date-picker
          name="date range"
          v-model="dateRange"
          date-format="yyyy-MM-dd"
          rules="required|valid_date_GGrange"
        ></start-end-date-picker>
        <v-row v-if="$can('edit', 'analysis')">
          <v-col>
            <v-btn type="submit" color="primary" :disabled="invalid"
              >Save</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </v-container>
  </Form>
</template>

<script>
import ValidationSummary from '@/components/Dialogs/ValidationSummary';
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker';
import ValidatedTextField from '@/components/Fields/ValidatedTextField';
import ValidatedSelectField from '@/components/Fields/ValidatedSelectField';
import { Form, defineRule } from 'vee-validate';
import api from '../_api';
import errorSummary from '../../../_mixins/error_utilities';

export default {
  components: {
    'validation-summary': ValidationSummary,
    'start-end-date-picker': StartEndDatePicker,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    'validated-text-field': ValidatedTextField,
    'validated-select-field': ValidatedSelectField,
  },

  mixins: [errorSummary],

  data() {
    return {
      mode: {
        EDIT: 'edit',
        NEW: 'new',
      },
      server_errors: [],
      dateRange: {
        startDateTime: '2018-10-01',
        endDateTime: '2018-01-03',
      },
      project: {
        projectName: '',
        description: '',
        analyticsModel: {},
        startDateTime: '',
        endDateTime: '',
      },
      typeOptions: [
        {
          text: 'Project Implementation',
          value: 'Project',
        },
        {
          text: 'Non Routine Event',
          value: 'NRE',
        },
      ],
      event: {
        name: 'Fall Gathering',
        description: 'This is the description for the fall gathering',
        eventStart: '2018-10-01',
        eventEnd: '2018-10-03',
        type: 'NRE',
        siteId: 36,
      },
      projectName: '',
      modelOptions: [],
      analyticModels: [],

      currentMode: {},

      sites: [],
      siteOptions: [],
      selectedSite: '36',

      loading: false,
    };
  },

  created() {
    this.setCurrentMode();
    this.getProjects();
    this.setCurrentMode();
    this.registerCustomValidators();
    this.getUserSites();
  },

  mounted() {
    this.setCurrentMode();
    if (this.currentMode === this.mode.EDIT) {
      //this.getProject();
      this.dateRange = {
        startDateTime: this.event.eventStart,
        endDateTime: this.event.eventEnd,
      };
    } else {
      this.dateRange = {
        startDateTime: new Date().toISOString().substring(0, 10),
        endDateTime: new Date().toISOString().substring(0, 10),
      };
      this.clearErrors();
    }
  },

  methods: {
    clearErrors() {
      requestAnimationFrame(() => {
        this.$refs.obs.reset();
      });
    },

    async getUserSites() {
      api
        .getSites()
        .then((response) => {
          this.sites = response;
          this.siteOptions = this.mapSiteLookup(response);
          this.selectedSite = 36;
        })
        .catch((error) => {
          this.server_errors = this.errorSummary(error);
        });
    },

    async submit() {
      this.project.startDateTime = this.dateRange.startDateTime;
      this.project.endDateTime = this.dateRange.endDateTime;
      // switch (this.currentMode) {
      //   case this.mode.EDIT:
      //     this.update();
      //     break;

      //   default:
      //     this.create();
      //     break;
      // }
    },

    async create() {
      let valid = await this.$refs.obs.validate();

      if (valid) {
        this.axios
          .post('/v1/analyticmodel/sites/' + this.selectedSite, this.project)
          .then((response) => {
            //show success toast
            this.projectName = this.project.projectName;
            console.log(response);
            this.$toast.show('Project created.', null, 'success');
          })
          .catch((error) => {
            this.server_errors = this.errorSummary(error);
          });
      }
    },

    async update() {
      let valid = await this.$refs.obs.validate();

      if (valid) {
        this.server_errors = [];
        this.axios
          .put('/v1/analyticmodel/sites/' + this.selectedSite, this.project)
          .then((response) => {
            //show success toast
            this.projectName = this.project.projectName;
            console.log(response);
            this.$toast.show('Project updated.', null, 'success');
          })
          .catch((error) => {
            let errs = this.errorSummary(error);
            console.log(errs);
            this.server_errors = errs;
          });
      }
    },

    getProject() {
      this.loading = true;
      api
        .getAnalyticProject(this.$route.params.id)
        .then((response) => {
          this.project = this.mapProject(response);
          this.projectName = response.projectName;
          this.selectedSite = response.site.id;
          //this.getModels();
          this.dateRange = {
            startDateTime: this.project.startDateTime,
            endDateTime: this.project.endDateTime,
          };
          if (this.currentMode === this.mode.EDIT) {
            this.setCurrentModel(response.analyticsModel.id);
          }
        })
        .catch((error) => {
          this.server_errors = this.errorSummary(error);
        })
        .finally(() => (this.loading = false));
    },

    async getProjects() {
      try {
        let response = await api.getPortfolioAnalyticProjects();
        this.projects = response;
        this.clearErrors();
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    // mapModelLookup(models) {
    //   const makeOptionItems = item => {
    //     return {
    //       value: item.id,
    //       text: item.name
    //     };
    //   };
    //   let ml = models.map(makeOptionItems);
    //   return ml;
    // },

    mapSiteLookup(sites) {
      const makeOptionItems = (item) => {
        return {
          value: item.id,
          text: item.name,
        };
      };
      var sl = sites.map(makeOptionItems);
      return sl;
    },

    mapProject(proj) {
      return {
        id: proj.id,
        projectName: proj.projectName,
        description: proj.description,
        analyticsModelId: proj.analyticsModel.id,
        startDateTime: new Date(proj.startDateTime)
          .toISOString()
          .substring(0, 10),
        endDateTime: new Date(proj.endDateTime).toISOString().substring(0, 10),
      };
    },

    setCurrentMode() {
      this.currentMode =
        this.$route.params.id === 'new' ? this.mode.NEW : this.mode.EDIT;
    },

    setSite(site) {
      console.log('site: ' + site);
    },

    registerCustomValidators() {
      defineRule('duplicate_name', (value, [field]) => {
        let valid = true;
        if (this.projects) {
          let existingNames = this.projects.map((a) => a.projectName);
          //filter project name out of list if in edit
          if (this.currentMode === this.mode.EDIT) {
            existingNames = existingNames.filter((n) => {
              return n !== this.projectName;
            });
          }
          valid = existingNames.indexOf(value) === -1;
        }
        if (!valid) {
          return `${field} is already used by another project.  Enter a different name.`;
        }

        return valid;
      });
    },

    // setCurrentModel(id) {
    //   let cm = {};

    //   try {
    //     cm = this.analyticModels.find(m => {
    //       return m.id === id;
    //     });
    //   } catch (error) {
    //     return Promise.reject(error);
    //   }
    //   if (cm) {
    //     cm.startDateTime = new Date(cm.startDateTime)
    //       .toISOString()
    //       .substring(0, 10);
    //     cm.endDateTime = new Date(cm.endDateTime)
    //       .toISOString()
    //       .substring(0, 10);
    //     this.currentModel = cm;
    //   }
    // },

    // setDefaultDateRange(id) {
    //   if (this.loading !== true) {
    //     this.setCurrentModel(id);

    //     this.dateRange = {
    //       startDateTime: this.currentModel.startDateTime,
    //       endDateTime: this.currentModel.endDateTime
    //     };
    //   }
    // }
  },
};
</script>
