<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg1"
    viewBox="0 0 170 67"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="M0.5 45V13.5L3.5 12.5L2.5 9.5L13.5 6.5L14.5 9.5C21.8459 7.55152 26.1248 6.68915 34 5.5V2.5C38.1661 2.00437 40.5562 1.77043 45 1.5L45.5 4.5H60.5L61 1.5C65.5734 1.65477 67.9055 1.93879 72 2.5V5.5L91.5 9L92.5 6L103 9L102 12.5L105.5 13.5V22.5H141.5H169.5V66H154C153.5 60.5 150.098 56.6352 144.5 56.5V66H118C117.5 60 113.16 56.4459 108.5 56.5V66H107V45H104V35.5C98 35.5 95.7113 39.5441 94.5 45H70V35.5C64 36 61.6855 39.459 61 45H31V35.5C25.5 35.5 22.6641 39.1738 21.5 45H0.5Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
