<template>
  <v-container>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <h1>Project Savings Report -- {{ project.projectName }}</h1>
      </div>
      <div>
        <v-btn icon @click="showHelp">
          <v-icon style="font-size: 40px !important">info_outlined</v-icon>
        </v-btn>
      </div>
    </div>

    <v-card prominent class="bg-blue-grey-lighten-4 mt-5" style="height: 170px">
      <v-row>
        <v-col>
          <v-row class="pa-6 font-weight-bold">
            <span>Baseline Model:</span>
          </v-row>
          <v-row class="pa-6 font-weight-bold">
            <span>Reporting period:</span>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row class="pa-6 font-weight-bold">
            <router-link :to="`/analytics/models/${project.modelId}`">{{
              project.modelName
            }}</router-link>
          </v-row>
          <v-row class="pa-6">
            <start-end-datepicker
              name="date range"
              rules="valid_date_range"
              v-model="dateRange"
              date-format="yyyy-MM-dd"
              :debounce="5000"
              @change="runReportForRange"
            ></start-end-datepicker>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3" class="pa-6">
          <main-label label="Events:" :labelBold="false" :valueIndent="false">
            <v-select
              v-model="selectedProjectEvents"
              :items="projectEvents"
              item-title="nreNameAndYear"
              :chips="false"
              :multiple="true"
              @update:modelValue="handleSetProjectEvents"
              class="pt-1 pr-2"
              return-object
              variant="outlined"
            ></v-select>
          </main-label>
        </v-col>
        <v-col cols="3" class="pa-6">
          <main-label label="Measures:" :labelBold="false" :valueIndent="false">
            <v-select
              v-model="selectedMeasures"
              :items="project.measureYears"
              item-value="analyticProjectMeasureId"
              item-title="measureNameAndYear"
              :chips="false"
              :multiple="false"
              class="pt-1 pr-2"
              variant="outlined"
              @update:modelValue="handleSetMeasures"
              return-object
              clearable
            ></v-select>
          </main-label>
        </v-col>
      </v-row>
    </v-card>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
    ></v-progress-linear>
    <v-row>
      <v-col>
        <v-fade-transition>
          <v-overlay v-if="help_chart" absolute>
            <v-card max-width="60%" style="margin: auto; opacity: 0.7" light>
              <v-card-text>{{ help_chart_text }}</v-card-text>
              <v-card-actions>
                <v-btn @click="help_chart = false" size="small">Dismiss</v-btn>
              </v-card-actions>
            </v-card>
          </v-overlay>
        </v-fade-transition>
        <highcharts
          class="elevation-3"
          :options="chartOptions"
          ref="projectImpactChart"
          >help me</highcharts
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600" persistent>
        <v-card>
          <v-card-title class="text-h5">{{
            projectEventDetail.nreNameAndYear
          }}</v-card-title>
          <v-card-text>
            <b>Type: </b>{{ projectEventDetail.type || 'NRE' }}
          </v-card-text>
          <v-card-text v-if="projectEventDetail.comment"
            ><b>Comment: </b>{{ projectEventDetail.comment }}</v-card-text
          >
          <v-card-text><v-divider></v-divider></v-card-text>
          <v-card-text>
            <b>Start: </b>{{ projectEventDetail.startDateTime }}
          </v-card-text>
          <v-card-text>
            <b>End: </b>{{ projectEventDetail.endDateTime }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="projectEventDetail.type !== 'Project'"
              icon
              :to="`/analytics/nonroutineevent/${projectEventDetail.nreId}/edit`"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn color="green-darken-1" variant="text" @click="dialog = false"
              >Dismiss</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="coefficients" persistent>
        <v-card>
          <v-card-title class="text-h5">Model Coefficients</v-card-title>
          <v-container>
            <v-data-table :headers="headers" :items="savingsData.coefficients">
            </v-data-table>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green-darken-1"
              variant="text"
              @click="coefficients = false"
              >Dismiss</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-fade-transition>
            <v-overlay v-if="help_savings" absolute>
              <v-card
                light
                max-width="90%"
                style="margin: auto !important; opacity: 0.7"
              >
                <v-card-text>{{ help_savings_text }}</v-card-text>
                <v-card-actions>
                  <v-btn @click="help_savings = false" size="small"
                    >Dismiss</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-overlay>
          </v-fade-transition>

          <v-app-bar density="compact" flat>
            <v-toolbar-title>Project Savings</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-subtitle> Total Baseline kWh </v-card-subtitle>
              <v-card-text>{{
                formatNumber(savingsData?.statistics?.totalBaseline, '0,0')
              }}</v-card-text>
            </div>
            <div>
              <v-card-subtitle> Total Actual kWh </v-card-subtitle>
              <v-card-text>{{
                formatNumber(savingsData?.statistics?.totalActual, '0,0')
              }}</v-card-text>
            </div>
            <div>
              <v-card-subtitle> Total kWh Savings </v-card-subtitle>
              <v-card-text>{{
                formatNumber(savingsData?.statistics?.totalSavings, '0,0')
              }}</v-card-text>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-fade-transition>
            <v-overlay v-if="help_stats" absolute>
              <v-card
                light
                max-width="90%"
                style="margin: auto !important; opacity: 0.7"
              >
                <v-card-text>{{ help_stats_text }}</v-card-text>
                <v-card-actions>
                  <v-btn @click="help_stats = false" size="small"
                    >Dismiss</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-overlay>
          </v-fade-transition>

          <v-app-bar density="compact" flat>
            <v-toolbar-title>Model Statistics</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="coefficients = true">
              Show Coefficients
            </v-btn>
          </v-app-bar>

          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-subtitle> Baseline Start </v-card-subtitle>
              <v-card-text>
                {{ analyticModel?.startDateTime?.substring(0, 10) }}
              </v-card-text>
            </div>
            <div>
              <v-card-subtitle> Baseline End </v-card-subtitle>
              <v-card-text>
                {{ analyticModel?.endDateTime?.substring(0, 10) }}
              </v-card-text>
            </div>
            <div>
              <v-card-subtitle>
                R
                <sup>2</sup>
              </v-card-subtitle>
              <v-card-text>{{
                formatNumber(savingsData.statistics?.rSquared, '0.00')
              }}</v-card-text>
            </div>
            <div>
              <v-card-subtitle> NMBE </v-card-subtitle>
              <v-card-text>{{ savingsData.statistics?.nmbe }}</v-card-text>
            </div>
            <div>
              <v-card-subtitle> CV(RMSE) </v-card-subtitle>
              <v-card-text>{{
                formatNumber(savingsData.statistics?.cvrmse, '0.00')
              }}</v-card-text>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <span class="px-4 text-caption text-primary">
      {{ $appOldVersion }}
    </span>
  </v-container>
</template>
<style>
.container {
  max-width: 100% !important;
}
</style>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import api from '../../projects/_api';
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker';
import Label from '@/modules/controls/_components/Label';
import moment from 'moment';
export default {
  name: 'AnalyticsProjectImpactReportModule',
  components: {
    highcharts: Chart,
    'start-end-datepicker': StartEndDatePicker,
    'main-label': Label,
  },

  //mixins: [errorSummary],
  async mounted() {
    //this.projectEvents = [{}];
    await this.getProject();
    await this.getModel();
    await this.getSavings();
    this.chartOptions.yAxis[2].visible = false;
    this.refreshChartData();

    this.help_chart_text = this.getHelpText('chart');
    this.help_savings_text = this.getHelpText('savings');
    this.help_stats_text = this.getHelpText('stats');
    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
      },
    });
  },

  data() {
    return {
      dialog: false,
      coefficients: false,
      help_chart: false,
      help_savings: false,
      help_stats: false,
      help_chart_text: '',
      help_savings_text: '',
      help_stats_text: '',
      loading: true,
      project: {},
      selectedProjectEvents: [],
      selectedMeasures: [],
      analyticModel: {},
      predictionData: {
        statistics: {
          rSquared: '(pending)',
          nmbe: '(pending)',
          cvrmse: '(pending)',
        },
      },
      dateRange: {
        startDateTime: new Date().toISOString().substring(0, 10),
        endDateTime: new Date().toISOString().substring(0, 10),
      },
      baselineStart: '',
      baselineEnd: '',
      totalBaseline: '(pending)',
      totalActual: '(pending)',
      totalUnitSavings: '(pending)',
      headers: [
        { text: 'Factor', value: 'factor' },
        { text: 'Estimate', value: 'estimate' },
        { text: 'Standard Error', value: 'std_Error' },
        { text: 'T Value', value: 't_Value' },
        { text: 'P Value', value: 'p_Value' },
      ],
      xAxisCategories: [],
      chartOptions: {
        credits: { enabled: false },
        chart: {
          zoomType: 'xy',
          marginTop: 20,
          height: 450,
        },
        title: null,
        xAxis: [
          {
            categories: [],
            type: 'category',
            labels: {
              rotation: -45,
              formatter: function () {
                if (!this.value) return '';

                return this.value;
              },
            },
            alignTicks: true,
            crosshair: true,
            plotLines: [
              {
                color: '#FF0000',
                width: 2,
                value: null,
                label: {},
              },
              {
                color: '#FF0000',
                width: 2,
                value: null,
                label: {},
              },
            ],

            plotBands: [
              {
                from: null,
                to: null,
                color: 'rgba(255, 255, 0, 0.2)',
                width: 1,
                label: {
                  text: 'Implementation Period',
                  verticalAlign: 'top',
                  y: -5,
                },
              },
            ],
          },
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: '{value:,.0f} kWh',
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            title: {
              text: 'kWh',
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
          },
          {
            // Secondary yAxis
            title: {
              text: 'Savings kWh',
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            max: 35000, //you'll need to calc this dynamically
            labels: {
              format: '{value:,.0f} kWh',
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            opposite: true,
          },

          {
            // Tertiary yAxis
            title: {
              text: 'Savings Goal',
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            max: 5000, //you'll need to calc this dynamically
            labels: {
              format: '${value:,.2f}',
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            opposite: true,
          },
          {
            // Tertiary yAxis 2? what does this even mean?
            title: {
              text: 'Energy Adjustment',
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            max: 5000, //you'll need to calc this dynamically
            labels: {
              format: '${value:,.2f}',
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            opposite: false,
          },
        ],
        tooltip: {
          shared: true,
        },
        legend: {
          layout: 'vertical',
          align: 'left',
          x: -10,
          verticalAlign: 'bottom',
          y: 10,
          floating: true,
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || // theme
            'rgba(255,255,255,0.25)',
        },
        series: null,
      },
      energyPredictionSavings: [],

      projectEvents: [
        {
          id: 1,
          name: 'Leadership Conference',
          comment: 'This is the comment for the leadership conference',
          startDateTime: '2018-04-01T00:00:00+00:00',
          endDateTime: '2018-04-07T23:59:59+00:00',
          type: 'Non-Routine Event',
        },
        {
          id: 2,
          name: 'Fall Gathering',
          comment: 'This is the comment for the fall gathering',
          startDateTime: '2018-10-01T00:00:00+00:00',
          endDateTime: '2018-10-03T23:59:59+00:00',
          type: 'Non-Routine Event',
        },
      ],

      projectEventOptions: [],
      projectEventDetail: {},

      savingsData: {
        predictions: [
          {
            value: 189049.7768,
            date: '2018-12-01T00:00:00-08:00',
            prediction: 230954.6109,
            savings: 41904.8341,
          },
          {
            value: 221265.3339,
            date: '2019-01-01T00:00:00-08:00',
            prediction: 247212.365,
            savings: 25947.0311,
          },
          {
            value: 221265.3339,
            date: '2019-02-01T00:00:00-08:00',
            prediction: 247212.365,
            savings: 25947.0311,
          },
          {
            value: 221265.3339,
            date: '2019-03-01T00:00:00-08:00',
            prediction: 247212.365,
            savings: 25947.0311,
          },
          {
            value: 221265.3339,
            date: '2019-04-01T00:00:00-08:00',
            prediction: 247212.365,
            savings: 25947.0311,
          },
          {
            value: 221265.3339,
            date: '2019-05-01T00:00:00-08:00',
            prediction: 247212.365,
            savings: 25947.0311,
          },
          {
            value: 371978.2963,
            date: '2019-06-01T00:00:00-08:00',
            prediction: 300384.2388,
            savings: 0,
          },
          {
            value: 1649691.8216,
            date: '2019-07-01T00:00:00-08:00',
            prediction: 469842.5418,
            savings: 0,
          },
          {
            value: 1190756.1282,
            date: '2019-08-01T00:00:00-08:00',
            prediction: 308118.7208,
            savings: 0,
          },
          {
            value: 595062.8784,
            date: '2019-09-01T00:00:00-08:00',
            prediction: 414912.3857,
            savings: 0,
          },
          {
            value: 375880.5563,
            date: '2019-10-01T00:00:00-08:00',
            prediction: 324988.3773,
            savings: 0,
          },
          {
            value: 449364.8063,
            date: '2019-11-01T00:00:00-08:00',
            prediction: 269696.6633,
            savings: 0,
          },
        ],
        statistics: {
          modelStatistics: {
            rSquared: 0.69,
            nmbe: 2.0564e-15,
            cvrmse: 28.7515,
            id: 0,
            lastModifiedUserId: null,
            lastModifiedDateTime: '0001-01-01T00:00:00+00:00',
          },
          totalBaseline: 2564976.7034,
          totalActual: 5053212.988,
          totalSavings: -2488236.2846,
          modelStart: '2018-04-01T00:00:00+00:00',
          modelEnd: '2019-01-31T00:00:00+00:00',
        },
      },
      goalValuesData: [],
      energyAdjustmentValuesData: [],
    };
  },

  methods: {
    showHelp() {
      this.help_chart = true;
      this.help_savings = true;
      this.help_stats = true;
    },

    runReportForRange: async function () {
      await this.getSavings();
      this.refreshChartData();
    },

    async getProject() {
      try {
        let project = await api.getAnalyticProjectForImpactReport(
          this.$route.params.id
        );
        this.project = project;

        this.projectEvents = project.nonRoutineEventYears;
        this.projectEvents = this.updateProjectEvents(this.project); //concatenate project and nres
        this.selectedProjectEvents = [
          this.projectEvents.find(
            (x) => x.nreNameAndYear === `${project.projectName}`
          ),
        ];
        this.filterProjectEvents(this.selectedProjectEvents); //this is the default project implementation - does not include NRE
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async getModel() {
      try {
        let model = await api.getAnalyticModel(this.project.modelId);

        this.analyticModel = model;

        let today = moment();
        let lastYear = moment().add('-1, year');

        //today = moment(new Date("2019-04-02")); //this is for Wildan demo
        lastYear = moment(new Date('2016-01-02'));

        this.baselineStart = lastYear.format('YYYY-MM-DD'); //model.startDateTime.substring(0, 10);
        this.baselineEnd = today.format('YYYY-MM-DD'); //model.endDateTime.substring(0, 10);
        this.dateRange.startDateTime = this.baselineStart;
        this.dateRange.endDateTime = this.baselineEnd;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async getSavings() {
      this.loading = true;
      let data = {};
      try {
        data = await api.analyzeEnergySavings(
          this.project.siteId,
          this.analyticModel,
          this.dateRange.startDateTime,
          this.dateRange.endDateTime,
          this.project.projectEndDateTime.substring(0, 10)
        );

        this.savingsData = data;
        if (this.savingsData.statistics) {
          this.savingsData.statistics.totalSavings = Math.abs(
            this.savingsData.statistics.totalSavings
          );
          this.loading = false;
        }
      } finally {
        this.loading = false;
      }
    },

    async handleSetMeasures(selectedMeasure) {
      if (selectedMeasure === undefined) {
        this.selectedMeasures = [];
        this.goalValuesData = [];
        this.chartOptions.yAxis[2].visible = false;
        this.refreshChartData();
        return;
      }

      this.goalValuesData = await api.getMonthlyCostSavingsForImpactReport(
        selectedMeasure.analyticProjectMeasureId,
        selectedMeasure.year
      );

      if (
        this.goalValuesData.length === 1 &&
        this.goalValuesData[0].date === null
      ) {
        this.selectedMeasures = [];
        this.goalValuesData = [];
        this.chartOptions.yAxis[2].visible = false;
        this.refreshChartData();
        return;
      }

      let sortedValues = this.goalValuesData.map((x) => x);

      sortedValues = sortedValues
        .sort(function (a, b) {
          return a.value - b.value;
        })
        .reverse();

      let newMax = sortedValues[0].value * 1.3;

      this.chartOptions.yAxis[2].max = newMax;
      this.chartOptions.yAxis[2].visible = true;
      this.refreshChartData();
    },

    async handleSetProjectEvents() {
      this.filterProjectEvents(this.selectedProjectEvents);
    },

    mapChartSeriesData(predictions) {
      const makeSeriesDataPoint = (item, property) => {
        var unixDate = moment(item.date).format('YYYY-M');
        var value = item[property];
        return [unixDate, value];
      };

      const makeGoalSeriesDataPoint = (item) => {
        var unixDate = moment(item.date).format('YYYY-M');
        return [unixDate, item.value];
      };

      const getMax = (property) => {
        let unsortedValues = predictions.map((x) => x[property]);

        let sortedValues = unsortedValues
          .sort(function (a, b) {
            return a - b;
          })
          .reverse();
        return sortedValues[0];
      };

      this.chartOptions.yAxis[1].max = getMax('savings');

      var seriesData = [
        {
          name: 'Baseline',
          type: 'line',
          color: '#FB8C00',
          marker: {
            radius: 2,
          },
          tooltip: {
            valueSuffix: ' kWh',
            valueDecimals: 0,
          },
          data: predictions.map((p) => makeSeriesDataPoint(p, 'prediction')),
        },
        {
          name: 'Actual',
          type: 'line',
          color: '#0277BD',
          marker: {
            radius: 2,
          },
          tooltip: {
            valueSuffix: ' kWh',
            valueDecimals: 0,
          },
          data: predictions.map((a) => makeSeriesDataPoint(a, 'value')),
        },
        {
          name: 'Savings',
          type: 'column',
          pointWidth: 20,
          pointPlacement: 0,
          pointPadding: 0,
          color: '#81C784',

          zIndex: -9999,
          tooltip: {
            valueSuffix: ' kWh',
            valueDecimals: 0,
          },
          yAxis: 1,
          data: predictions.map((s) => makeSeriesDataPoint(s, 'savings')),
        },

        {
          name: 'Goal',
          type: 'column',
          pointPadding: 0.1,
          pointPlacement: -0.25,
          pointWidth: 10,
          color: 'rgba(219,179,178,.7)',
          zIndex: -9999,
          visible: true,
          tooltip: {
            valuePrefix: '$',
            valueDecimals: 2,
          },
          yAxis: 2,
          data: this.goalValuesData.map((s) => makeGoalSeriesDataPoint(s)),
        },
      ];
      return seriesData;
    },

    mapChartCategories(predictions) {
      let categories = predictions.map((c) => {
        return moment(c.date).format('YYYY-M');
        //return c.date;
      });

      let goalCategories = this.goalValuesData.map((c) => {
        return moment(c.date).format('YYYY-M');
      });

      let combined = categories.concat(goalCategories);
      combined = [...new Set([...categories, ...goalCategories])];

      return combined;
    },

    getPlotPositionUnits(date) {
      let endMoment = moment(
        this.xAxisCategories[this.xAxisCategories.length - 1],
        'YYYY-M'
      );
      let startMoment = moment(this.xAxisCategories[0], 'YYYY-M');
      let rangeMilliseconds = endMoment.valueOf() - startMoment.valueOf();
      let plotMilliseconds = moment(date).valueOf() - startMoment.valueOf();
      let percent = Math.max(
        0,
        Math.min(1, plotMilliseconds / rangeMilliseconds)
      );
      //multiply percent * length of category collection to get position
      let position = this.xAxisCategories.length * percent;
      position = position === this.xAxisCategories.length ? 0 : position; //hide the lines if outside the bounds of the categories count
      return position - 1; //0 based
    },

    refreshChartData() {
      this.xAxisCategories = this.mapChartCategories(
        this.savingsData.predictions
      );
      this.chartOptions.xAxis[0].categories = this.xAxisCategories;

      this.filterProjectEvents(this.selectedProjectEvents);

      this.chartOptions.series = this.mapChartSeriesData(
        this.savingsData.predictions
      );
    },

    refreshProjectEventLinesAndBands(projectEvents) {
      let plotLines = this.mapProjectEventsToPlotlines(projectEvents);
      let plotBands = this.mapProjectEventsToPlotBands(projectEvents);
      this.chartOptions.xAxis[0].plotLines = plotLines;
      this.chartOptions.xAxis[0].plotBands = plotBands;
    },

    updateProjectEvents(currentProject) {
      let projectEvent = {};
      let updatedEvents = this.projectEvents;
      projectEvent.id = currentProject.id;
      projectEvent.startDateTime = currentProject.projectStartDateTime;
      projectEvent.endDateTime = currentProject.projectEndDateTime;
      projectEvent.nreNameAndYear = `${currentProject.projectName}`;
      projectEvent.type = 'Project';
      projectEvent.comment = currentProject.description;
      projectEvent.year = currentProject.year;

      updatedEvents = updatedEvents.concat(projectEvent);

      //sort these events by date
      return updatedEvents;
    },

    mapProjectEventsToPlotlines(projectEvents) {
      let plotLines = projectEvents.map((c) => {
        let pairs = [
          {
            value: this.getPlotPositionUnits(
              c.startDateTime || c.projectStartDateTime
            ),
            color: c.type === 'Project' ? '#FF0000' : '#0277BD',
            width: c.type === 'Project' ? 2 : 1,
            label: {},
          },
        ];
        pairs = pairs.concat({
          value: this.getPlotPositionUnits(
            c.endDateTime || c.projectEndDateTime
          ),
          color: c.type === 'Project' ? '#FF0000' : '#0277BD',
          width: c.type === 'Project' ? 2 : 1,
          label: {},
        });
        return pairs;
      });
      let flattened = plotLines.flat();
      let sorted = flattened.sort((a, b) => a.value - b.value);
      return sorted;
    },

    mapProjectEventsToPlotBands(projectEvents) {
      let plotBands = projectEvents.map((c) => {
        return {
          from: this.getPlotPositionUnits(
            c.startDateTime //|| c.projectStartDateTime
          ),
          to: this.getPlotPositionUnits(c.endDateTime), //|| c.projectEndDateTime),
          color: 'rgba(255, 255, 0, 0.2)',
          width: 1,
          label: {
            text: c.type === 'Project' ? 'Project' : 'NRE',
            verticalAlign: 'top',
            y: -5,
          },
          events: {
            click: function () {
              this.projectEventDetail = c;
              //this.timer = setTimeout(function () {
              this.dialog = true;
              //}, 1000);
            },
            mouseout: function () {
              //clearTimeout(this.timer);
            },
            // click: function () {
            //   clearTimeout(this.timer);
            // }
          },
        };
      });
      return plotBands.sort((a, b) => a.from - b.from);
    },

    mapProjectEventOptions(projectEvents) {
      const makeOptionItems = (item) => {
        return {
          value: item.name,
          text: item.name + ' - ' + item.type,
        };
      };
      let ml = projectEvents.map(makeOptionItems);
      return ml;
    },

    filterProjectEvents(selectedItems) {
      const peToFilter = this.projectEvents;
      let filteredPE = [];

      filteredPE = peToFilter.filter((item) => {
        return selectedItems.includes(item);
      });
      this.refreshProjectEventLinesAndBands(filteredPE);
    },

    getHelpText(type) {
      let text = '';
      switch (type) {
        case 'chart':
          text =
            'The Main Impact Report Chart presents the verified energy savings of the project. The Start and End Date define the period shown in the chart. The orange profile is the baseline consumption, the blue profile is the actual consumption, and the green filled bars represent the energy savings. Any Pre-defined non-routine events (NREs) such as work shutdowns and storms can be selected from the drop-down menu in the upper right. Hover over the bands to see details of projects and non-routine events. Use the link at the upper left to navigate to the analysis model used to generate this predicted savings data.';
          break;
        case 'savings':
          text =
            'Baseline and actual energy consumption are summarized here along with the energy savings for the selected time period.';
          break;
        case 'stats':
          text =
            'Model data and fit are summarized here. The baseline means the start and end of the modeling period. R2 is a statistical parameter indicating how well the energy data correlates with the temperature and occupancy variables; R2 > 0.7 is desirable. NMBE and CV(RMSE) summarize the overall error of the predicted values versus the actual values.';
          break;

        default:
          break;
      }
      return text;
    },

    numberFilter(value, decimalPlaces) {
      if (typeof value !== 'number') {
        return value;
      }
      return value.toFixed(decimalPlaces);
    },

    formatNumber(value, format) {
      return this.$n(value, format);
    },
  },
};
</script>
