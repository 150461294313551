//Power Quality
//Site Slicer - vwSite - SiteName
//Date Slicer - vwReportingDatetime - Date
//End Use Slicer - vwEndUseNames - EndUseName

import cons from '../constants';

let firstSiteName = '';
let firstLoad = true;

const setSlicers = async function (reportParameters, visuals) {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setDateSlicerVisual(reportParameters, visuals);
  await setEndUseSlicerVisual(reportParameters, visuals);
};

const setSiteSlicerVisual = async function (reportParameters, visuals) {
  try {
    const siteData = getSiteDataBySiteName(reportParameters);
    const { SiteName } = reportParameters;
    const siteSlicerVisual = visuals.filter(function (visual) {
      return visual.type === 'slicer' && visual.title === 'Site Slicer';
    })[0];

    const values = siteData ? [siteData.SiteName] : [SiteName];

    if (!SiteName && !siteData) {
      const siteSlicerState = await siteSlicerVisual.getSlicerState();

      if (siteSlicerVisual) {
        await siteSlicerVisual.setSlicerState(siteSlicerState);
      }
    } else {
      await siteSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: cons.BASIC_FILTER,
            target: {
              table: 'vwSite',
              column: 'SiteName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const setDateSlicerVisual = async function (reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwReportingDateTime',
      column: 'Date',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };

  let dateSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Date Slicer';
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
  }
};

/**
 * Retrieves the first site data based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @returns {Array} - The first site data. 
 */
const getSiteDataBySiteName = (reportParameters) => {
  try {
    const { SiteName, SiteEndUses } = reportParameters;

    if (SiteEndUses) {
      const parsedData = JSON.parse(SiteEndUses);
      if (firstLoad) {
        firstSiteName = parsedData[0].SiteName.trim().toLowerCase();
      }
      const trimmedSiteName = firstSiteName.length
        ? firstSiteName
        : SiteName.trim().toLowerCase();

      const response = parsedData.find((data) => {      
        return data.SiteName.trim().toLowerCase() === trimmedSiteName;
      });

      if (firstLoad) {
        firstLoad = false;
      }

      return !response && firstLoad ? parsedData[0] : response;
    }

    return null;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Sets the End Use slicer visual based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @param {Array} visuals - The visuals array.
 * @param {String} uomName - The UOM name.
 * @param {Boolean} unsetFirstLoad - Whether to unset the first load.
 * @returns {Promise<void>}
 */
const setEndUseSlicerVisual = async (
  reportParameters,
  visuals,
  uomName = null,
  unsetFirstLoad = false
) => {
  try {
    if (unsetFirstLoad) {
      firstLoad = false;
      firstSiteName = '';
    }
    const siteData = getSiteDataBySiteName(reportParameters, uomName);
    const { SiteName } = reportParameters;

    const endUseSlicerVisual = visuals.find(
      (visual) => visual.type === 'slicer' && visual.title === 'End Use Slicer'
    );

    if (!endUseSlicerVisual) {
      return Promise.reject(new Error('End Use Slicer visual not found'));
    }

    const values = siteData ? [siteData.EndUseName] : ['No Data to show'];

    if (!SiteName) {
      try {
        const endUseSlicerState = await endUseSlicerVisual.getSlicerState();
        await endUseSlicerVisual.setSlicerState(endUseSlicerState);
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      await endUseSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: cons.BASIC_FILTER,
            target: {
              table: 'vwEndUseNames',
              column: 'EndUseName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  setSlicers,
};
