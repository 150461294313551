import { markRaw } from 'vue';

import VAV324Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite315/VAV324Drawing.vue';
import VAV325Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite315/VAV325Drawing.vue';
import VAV341Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite315/VAV341Drawing.vue';
import VAV342Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite315/VAV342Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

import { IHotspot } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList: IHotspot<Record<string, unknown>>[] = [
  {
    id: 0,
    name: 'VAV 3-41',
    controlId: 2355,
    component: markRaw(VAV341Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-41',
      },
    },
    style: {
      position: 'absolute',
      top: '8%',
      left: '18.5%',
      width: '56.8%',
      height: '56.8%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '22%',
      left: '43%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 3-24',
    controlId: 2343,
    disabled: true,
    component: markRaw(VAV324Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-24',
      },
    },
    style: {
      position: 'absolute',
      top: '8%',
      left: '69.2%',
      width: '36.5%',
      height: '36.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '22%',
      left: '83%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 3-25',
    controlId: 2344,
    disabled: true,
    component: markRaw(VAV325Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-25',
      },
    },
    style: {
      position: 'absolute',
      top: '44.5%',
      left: '60.2%',
      width: '55.8%',
      height: '55.8%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '65%',
      left: '83%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 3-42',
    controlId: 2356,
    component: markRaw(VAV342Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-42',
      },
    },
    style: {
      position: 'absolute',
      top: '41.2%',
      left: '17.2%',
      width: '59.3%',
      height: '59.3%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '65%',
      left: '43%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
