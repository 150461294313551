<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="!hasLenght && !isLoading">
        <BannerAlertVue :message="bannerMessage" />
      </v-col>
      <v-col>
        <AHUsListVue
          @update:AHUsList="(value: boolean) => (hasLenght = value)"
          @update:isLoading="(value: boolean) => (isLoading = value)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';

import AHUsListVue from '@/Core.Service.Domain/Controls/AHUs/Components/AHUsList.vue';

const hasLenght = ref(false);
const isLoading = ref(true);
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

const router = useRouter();
const bannerMessage = computed(
  () => `No ${router.currentRoute.value.meta.label} configured for this site`
);
</script>
