const apiVersion = 'v1';

const getThermostatLatestUsage = async (siteId: number) => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/Thermostats/latest-readings`,
      {
        params: { siteId },
      }
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteThermostat = async (thermostatId: number) => {
  try {
    await window.axios.delete(
      `/${apiVersion}/Thermostats/${thermostatId}`
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

const setThermostatData = async (thermostatData: any) => {
  try {
    const response = await window.axios.put(
      `/${apiVersion}/Thermostats/${thermostatData.thermostatId}/settings`,
      thermostatData
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetThermostatSettingsMessages = async (thermostatId: number) => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/Thermostats/${thermostatId}/history`
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getThermostatLatestUsage,
  deleteThermostat,
  setThermostatData,
  GetThermostatSettingsMessages,
};
