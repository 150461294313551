<template>
  <v-card class="analytics-bar">
    <v-app-bar elevation="1" class="px-11">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        Scheduled Reports
      </v-app-bar-title>
      <v-text-field
        v-model="searchTerm"
        class="mt-1"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        bg-color="white"
        variant="outlined"
        density="compact"
        :placeholder="placeholder"
        :clearable="true"
        @click:clear="handleSearchInput"
        @update:modelValue="handleSearchInput"
      />

      <template v-slot:append>
        <v-btn
          v-if="userCanUpdate || userCanCreate || userCanDelete"
          class="rounded-2 mr-3"
          color="primary"
          variant="flat"
          size="large"
          @click="handleCreateScheduledReport"
        >
          <div
            class="text-subtitle-2 text-white text-truncate font-weight-bold"
          >
            {{ $route.meta.buttonLabel }}
          </div>
        </v-btn>
      </template>
      <v-spacer />
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'NonRoutineEventBar',

  data() {
    return {
      searchTerm: '',
      toolBarHeight: 50,
      toolBarWidth: 1175,
      selectedSite: null,
    };
  },

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Report';
    },

    userCanCreate() {
      return this.$can('create', 'analytics');
    },
    userCanDelete() {
      return this.$can('delete', 'analytics');
    },
    userCanUpdate() {
      return this.$can('update', 'analytics');
    },
  },

  watch: {
    $route() {
      this.clearSearchTerm();
    },
  },

  methods: {
    ...mapMutations({
      setScheduledReportsSearchTerm:
        'ViewSupportStore/ReportsStore/REPORT_SEARCH_TERM',
    }),
    handleCreateScheduledReport() {
      this.$router.push({ name: 'CreateScheduledReport' });
    },

    handleSearchInput(value) {
      this.setScheduledReportsSearchTerm(value);
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setScheduledReportsSearchTerm('');
    },
  },
};
</script>
<style lang="scss" scoped>
.analytics-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 700px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
