//Thermostat Readings
//Site Slicer
//Date Slicer
//Thermostat Slicer
//C02 / Temp Slicer
//CO2 Exceptions
//Site Slicer
//Date Slicer
//Thermostat Slicer
import cons from '../constants';

const setSlicers = async (reportParameters, visuals) => {
  try {
    await setSiteSlicerVisual(reportParameters, visuals);
    await setDateSlicerVisual(reportParameters, visuals);
    await setThermostatSlicerVisual(reportParameters, visuals);
  } catch (error) {
    return Promise.reject(error);
  }
};

const setSiteSlicerVisual = async (reportParameters, visuals) => {
  try {
    const siteSlicerFilter = {
      $schema: cons.ADVANCED_FILTER,
      target: {
        table: 'vwSite',
        column: 'SiteName',
      },
      operator: 'In',
      values: [reportParameters.SiteName],
    };

    const siteSlicerVisual = visuals.filter((visual) => {
      return visual.type === 'slicer' && visual.title === 'Site Slicer';
    })[0];

    if (siteSlicerVisual) {
      // Set the slicer state which contains the slicer filters.
      await siteSlicerVisual.setSlicerState({
        filters: [siteSlicerFilter],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const setDateSlicerVisual = async (reportParameters, visuals) => {
  try {
    const dateSlicerFilter = {
      $schema: cons.ADVANCED_FILTER,
      target: {
        table: 'vwReportingDateTime',
        column: 'Date',
      },
      logicalOperator: 'And',
      conditions: [
        {
          operator: 'GreaterThanOrEqual',
          value: reportParameters.StartDate,
        },
        {
          operator: 'LessThanOrEqual',
          value: reportParameters.EndDate,
        },
      ],
    };

    const dateSlicerVisual = visuals.filter((visual) => {
      return visual.type === 'slicer' && visual.title === 'Date Slicer';
    })[0];

    if (dateSlicerVisual) {
      await dateSlicerVisual.setSlicerState({
        filters: [dateSlicerFilter],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const setThermostatSlicerVisual = async (reportParameters, visuals) => {
  try {
    const thermostatSlicerFilter = {
      $schema: cons.ADVANCED_FILTER,
      target: {
        table: 'vwThermostatUsageData',
        column: 'ControlFriendlyName',
      },
      operator: 'Top',
      itemCount: 1,
      orderBy: {
        table: 'vwThermostatUsageData',
        measure: 'ControlFriendlyName',
      },
      filterType: reportParameters.SensorName ? 1 : 5,
      values: [reportParameters.SensorName],
    };
    const thermostatSlicerVisual = visuals.filter((visual) => {
      return visual.type === 'slicer' && visual.title === 'Thermostat Slicer';
    })[0];

    if (thermostatSlicerVisual) {
      await thermostatSlicerVisual.setSlicerState({
        filters: [thermostatSlicerFilter],
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  setSlicers,
};
