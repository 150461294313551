import cons from '../constants';

const setSlicers = async function (reportParameters, visuals) {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setDateSlicerVisual(reportParameters, visuals);
};
const setSiteSlicerVisual = async function (reportParameters, visuals) {
  const siteSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwSite',
      column: 'SiteName',
    },
    operator: 'In',
    values: [reportParameters.SiteName],
  };
  const siteSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState({
      filters: [siteSlicerFilter],
    });
  }
};

const setDateSlicerVisual = async function (reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwReportingDateTime',
      column: 'Date',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };

  const regex = /(Electricity\s+)?Date\s+Slicer/gi;
  const dateSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && regex.test(visual.title);
  })[0];

  if (dateSlicerVisual) {
    try {
      await dateSlicerVisual.setSlicerState({
        filters: [dateSlicerFilter],
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default {
  setSlicers,
};
