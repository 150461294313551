<template>
  <div class="mt-n2 px-2">
    <v-data-table
      :headers="headers"
      :items="aginovaMonitorsList"
      :items-per-page="10"
      :search="searchTerm"
      :loading="!aginovaMonitorsList.length"
      :header-props="headerProps"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <!--Headers slot-->
      <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="column.class"
            @click="() => toggleSort(column)"
          >
            {{ column.title }}
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)" size="sm" />
            </template>
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <!--Friendly Name slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.friendlyName }}
            </span>
          </td>
          <!--Device Id slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.deviceId }}
            </span>
          </td>
          <!--Device Name slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.deviceName }}
            </span>
          </td>
          <!--Site Location slot-->
          <td>
            <span class="text-primary text-subtitle-1">
              {{ item.siteLocation.siteLocationName }}
            </span>
          </td>
          <!--Actions buttons slot-->
          <td>
            <ActionButtonsVue
              :buttonsList="actionButtons"
              :outlined="true"
              :disabled="!userCanUpdate || !userCanDelete"
              :item="item"
              class="ml-n2"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteAginovaMonitor
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :AginovaMonitorInfo="AginovaMonitorInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </div>
</template>

<script>
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import ConfirmDialogDeleteAginovaMonitor from '@/Core.Service.Domain/Monitors/Aginova/Components/ConfirmDialogDeleteAginovaMonitor';

export default {
  name: 'AginovaMonitorsList',

  components: {
    ActionButtonsVue,
    ConfirmDialogDeleteAginovaMonitor,
  },

  computed: {
    ...mapGetters({
      AginovaMonitorsList:
        'MonitorsStore/AginovaMonitorsStore/selectedSiteWithAginovaMonitors',
      selectedAginovaMonitorIsLoading:
        'MonitorsStore/AginovaMonitorsStore/selectedAginovaMonitorIsLoading',
      searchTerm:
        'MonitorsStore/AginovaMonitorsStore/getAginovaMonitorsSearchTerm',
    }),

    aginovaMonitorsList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems)
        : sortAlphabetically(this.AginovaMonitorsList);
    },

    userCanDelete() {
      return this.$can('delete', 'meters');
    },
    userCanUpdate() {
      return this.$can('update', 'meters');
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['deviceName', 'friendlyName'],
        this.AginovaMonitorsList
      );
    },
  },

  data(instance) {
    return {
      filteredItems: [],
      showConfirmDialog: false,
      AginovaMonitorInfo: {},
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          title: 'Friendly Name',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'friendlyName',
          sortable: true,
        },
        {
          title: 'Device Id',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'deviceId',
          sortable: true,
        },
        {
          title: 'Device Name',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'deviceName',
          sortable: true,
        },
        {
          title: 'Site Location',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'siteLocation.siteLocationName',
          sortable: true,
        },
        {
          title: 'Actions',
          align: 'start',
          class: 'text-primary text-subtitle-1 font-weight-bold',
          value: 'actions',
          sortable: false,
        },
      ],
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },
      actionButtons: [
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          loading: () => instance.selectedAginovaMonitorIsLoading,
          action: (item) => {
            if (!this.userCanUpdate || !this.userCanDelete) return;
            instance.$router.push({
              name: 'EditAginovaMonitor',
              params: {
                id: item.deviceId,
              },
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          action: (item) => {
            if (!instance.userCanUpdate || !instance.userCanDelete) return;
            instance.AginovaMonitorInfo = item;
            instance.showConfirmDialog = true;
          },
        },
      ],
    };
  },

  methods: {
    dateTimeToUSFormatFactory,
  },
};
</script>
