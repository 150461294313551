<template>
  <v-container fluid>
    <ReportsListBar />
    <ReportsList :reportsList="filteredReportsList" />
    <ProgressLinearLoaderVue :isLoading="isLoading" />
  </v-container>
</template>

<script>
import API from '@/Core.Service.Domain/ViewSupport/Reports/API';

import ReportsList from '@/Core.Service.Domain/ViewSupport/Reports/Components/ReportsList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

import ReportsListBar from '@/Core.Service.Domain/ViewSupport/Reports/Components/ReportsListBar.vue';

export default {
  name: 'ReportsView',

  components: { ReportsList, ProgressLinearLoaderVue, ReportsListBar },

  data() {
    return {
      reportsList: [],
      filteredReportsList: [],
      cardWidth: 370,
      cardHeight: 576,
      isLoading: true,
    };
  },

  async created() {
    try {
      this.reportsList = await API.getReports();
      this.filteredReportsList = this.reportsList.filter(
        (report) => report.isActive
      );
    } catch (error) {
      return Promise.reject(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
