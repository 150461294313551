import { createApp, App } from 'vue';
import vuetify from './vuetify';
import ToastAlert from '@/Core.UI.Domain/Components/ToastAlert.vue';
import { ToastOptions } from '@/Core.UI.Domain/types/Toast';

export interface ToastService {
  show: (options: ToastOptions) => void;
}

const createToastService = (): ToastService => ({
  show(options: ToastOptions) {
    const toastContainer = document.createElement('div');
    document.body.appendChild(toastContainer);

    const toastOptions: ToastOptions = {
      title: options.title,
      message: options.message,
      status: options.status || 'success',
      showToast: true,
    };

    const toastApp = createApp(ToastAlert, { toastOptions });
    toastApp.use(vuetify);
    toastApp.mount(toastContainer);

    setTimeout(() => {
      toastApp.unmount();
      document.body.removeChild(toastContainer);
    }, 15000);
  },
});

const ToastPlugin = {
  install(app: App) {
    const toast = createToastService();

    app.provide('toast', toast);

    // To keep backward compatibility
    app.config.globalProperties.$toast = toast;
  },
};

export default ToastPlugin;
