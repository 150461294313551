<template>
  <v-container fluid>
    <TitleBar :label="`Create Non-Routine Event`" />
    <v-form @submit.prevent="submit" validate-on="input">
      <v-sheet elevation="2" class="pa-4">
        <v-row>
          <v-col sm="6">
            <v-text-field
              v-model="nonRoutineEvent.name"
              label="Non-Routine Event Name"
              hint="Name for this Non-Routine Event"
              persistent-hint
              :rules="[(v) => !!v || 'Name is required']"
              variant="outlined"
              density="compact"
              color="primary"
            />
          </v-col>
          <v-col sm="6">
            <v-select
              :items="nonRoutineEventTypes"
              item-value="id"
              item-title="name"
              :rules="[(v) => !!v || 'Type is required']"
              v-model="nonRoutineEvent.nreTypeId"
              label="Non-Routine Event Type"
              hint="What type of Non-Routine Event is this?"
              variant="outlined"
              density="compact"
              color="primary"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <start-end-date-picker
              name="date range"
              :rules="[(v) => !!v || 'Date Range is required']"
              v-model="dateRange"
              @change="handleSetDateRange"
              date-format="yyyy-MM-dd"
              ref="startEndDatePicker"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-textarea
            rows="2"
            label="Comments"
            clearable
            clear-icon="mdi-close-circle"
            variant="outlined"
            auto-grow
            v-model="nonRoutineEvent.comment"
            class="pr-3 pl-3"
          />
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              type="submit"
              color="primary"
              :disabled="!isValidNonRoutineEvent"
            >
              Save
            </v-btn>
            <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-form>
  </v-container>
</template>

<script>
import StartEndDatePicker from '@/components/Fields/StartEndDatePicker';
import api from '../_api';

import TitleBar from '@/Core.Service.Domain/Monitors/Monitors.Common/Components/TitleBar.vue';

export default {
  name: 'NonRoutineEventCreate',
  components: {
    'start-end-date-picker': StartEndDatePicker,
    TitleBar,
  },

  data() {
    return {
      valid: true,
      siteId: null,
      nonRoutineEvent: {
        name: null,
        nonRoutineEventTypeId: null,
        startDateTime: null,
        endDateTime: null,
        comment: null,
      },
      nonRoutineEventTypes: [],
      selectedNonRoutineEventType: null,
      dateRange: {},
      isValidNonRoutineEvent: true,
      isLoading: false,
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    this.dateRange = {
      startDateTime: new Date().toISOString().substring(0, 10),
      endDateTime: new Date().toISOString().substring(0, 10),
    };
    try {
      await this.getLookups(this.siteId);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  methods: {
    async submit(event) {
      const { valid } = await event;
      if (!valid) {
        return Promise.reject({
          Error: 'Please fill in all required fields',
        });
      }
      this.nonRoutineEvent.siteId = this.siteId;

      this.isLoading = true;
      try {
        await api.createNonRoutineEvent(this.nonRoutineEvent);
        const options = {
          title: 'Success',
          message: 'Non-routine Event created',
          status: 'success',
        };
        this.$toast.show(options);
        this.$router.push({ name: 'NonRoutineEvents' });
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.isLoading = false;
      }
    },

    async getLookups() {
      this.nonRoutineEventTypes = await api.getNonRoutineEventTypes();
    },

    handleSetDateRange(dateRange) {
      this.nonRoutineEvent.startDateTime = dateRange.startDateTime;
      this.nonRoutineEvent.endDateTime = dateRange.endDateTime;

      this.dateRange = {
        startDateTime: dateRange.startDateTime,
        endDateTime: dateRange.endDateTime,
      };
    },

    handleCancelCrud() {
      this.$router.push({ name: 'NonRoutineEvents' });
    },
  },
};
</script>
