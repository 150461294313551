<template>
  <div v-if="show">
    <!-- <div class="overlay" @click="show = false" /> -->
    <v-alert
      v-if="toastOptions.message"
      class="toast-container"
      :class="toastOptions.status"
      :type="toastOptions.status"
      :title="toastOptions.title"
      :options="{}"
      :closable="true"
      @click:close="show = false"
    >
      <template #text>
        <span v-html="toastOptions.message" />
      </template>
    </v-alert>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue';
import { ToastOptions } from '@/Core.UI.Domain/types/Toast';

const show = ref(true);

const props = defineProps<{
  toastOptions: ToastOptions;
}>();

onMounted(() => {
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      show.value = false;
    }
  });
});

watch(
  () => props.toastOptions.showToast,
  (value) => {
    show.value = value ?? false;
  }
);
</script>

<style scoped lang="scss">
// .overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 999;
// }

.toast-container {
  z-index: 1000;
  position: fixed;
  max-width: 700px;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -5%);
}
</style>
