<!-- eslint-disable vuetify/no-deprecated-components -->
<template>
  <section>
    <Form ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="onSubmit">
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
        />
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col cols="6">
              <v-text-field
                :autofocus="true"
                :rules="[() => !!site.name || 'Name is required']"
                v-model="site.name"
                label="Name"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-col class="d-flex justify-start">
              <v-radio-group
                v-model="site.isExcludedFromAI"
                color="primary"
                inline
              >
                <template v-slot:label>
                  <span
                    >Is this site <strong>Excluded</strong> from AI
                    Analysis?</span
                  >
                </template>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-start">
              <v-radio-group
                v-model="site.hasDigitalTwin"
                color="primary"
                inline
              >
                <template v-slot:label>
                  <span>Does this site have a Digital Twin?</span>
                </template>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="v-input__slot">
                <label-component
                  label="Address Lookup"
                  :labelBold="false"
                  :valueIndent="false"
                >
                  <input
                    label="Lookup"
                    v-model="site.address"
                    ref="autocomplete"
                    type="text"
                    placeholder="type all or part of a place"
                    class="v-text-field search-location"
                  />
                </label-component>
              </div>
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="12" sm="2">
              <v-text-field v-model="site.streetNumber" label="Number" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="site.address"
                label="Address Lookup"
                hint="Type all or part of a place"
                variant="outlined"
                ref="autocomplete"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="site.country"
                :rules="[() => !!site.country || 'Country is required']"
                label="Country"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="4">
              <v-text-field
                v-model="site.city"
                :rules="[() => !!site.city || 'City is required']"
                label="City or District"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="site.state"
                :rules="[() => !!site.state || 'State is required']"
                label="Region, State, or Territory"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="site.postalCode"
                :rules="[() => !!site.postalCode || 'Postal Code is required']"
                label="Postal Code"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="3">
              <v-text-field
                v-model="site.latitude"
                :rules="[() => !!site.latitude || 'Latitude is required']"
                label="Latitude"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="site.longitude"
                :rules="[() => !!site.longitude || 'Longitude is required']"
                label="Longitude"
              />
            </v-col>

            <v-col cols="3">
              <v-select
                v-model="site.timeZoneId"
                :rules="[() => !!site.timeZoneId || 'Time Zone is required']"
                :items="timezones"
                :custom-filter="timezoneFilter"
                item-title="name"
                item-value="id"
                label="Time Zone"
              />
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                :items="weatherStations"
                item-title="name"
                item-value="id"
                v-model="site.weatherStationId"
                label="Weather Station"
                required
                variant="solo"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="3">
              <v-select
                v-model="site.utilityCompanyIds"
                :rules="[
                  () => !!site.utilityCompanyIds || 'Utility is required',
                ]"
                :items="utilityCompanies"
                :item-title="(item) => item.name || ''"
                :item-value="(item) => item.id"
                label="Utility"
                multiple
              />
            </v-col>

            <v-col cols="3">
              <v-select
                v-model="site.marketContextId"
                :items="marketContexts"
                item-title="name"
                item-value="id"
                label="Market Context"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.kwhCost"
                :rules="[() => !!site.kwhCost || 'Cost per kWh is required']"
                label="Cost per kWh ($)"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.thermCost"
                label="Cost per Therm ($)"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="site.waterCost" label="Cost per Gal ($)" />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.cO2Threshold"
                label="CO2 Threshold"
                type="number"
                min="800"
                max="1500"
                step="1"
              />
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col cols="4">
              <v-select
                :rules="[
                  () => !!site.siteTypeId || 'Building Type is required',
                ]"
                :items="siteTypes"
                item-title="name"
                item-value="id"
                v-model="site.siteTypeId"
                label="Building Type"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.squareFootage"
                :rules="[() => !!site.squareFootage || 'Sq. Ft. is required']"
                label="Sq. Ft."
                type="number"
                min="0"
                max="50000"
                step="500"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                v-model="site.floors"
                :rules="[() => !!site.floors || 'Floors is required']"
                label="Floors"
                type="number"
                min="0"
                max="100"
                step="1"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="site.occupants"
                :rules="[() => !!site.occupants || 'Occupants is required']"
                label="Occupants"
                type="number"
                min="0"
                max="10000"
                step="50"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="site.year"
                :rules="[() => !!site.year || 'Year Built is required']"
                :items="years"
                label="Year Built"
              />
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet elevation="2" class="mt-4 pa-4">
          <v-row>
            <v-col cols="8">
              <div class="text-h6">Opening Times</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <b>Weekday</b>
            </v-col>
            <v-col cols="1">
              <b>Is Open</b>
            </v-col>
            <v-col cols="1">
              <b>Is 24h</b>
            </v-col>
            <v-col cols="3">
              <b>Open Time</b>
            </v-col>
            <v-col cols="3">
              <b>Close Time</b>
            </v-col>
          </v-row>
          <v-row v-for="day in siteStartStopTimesNew" :key="day.dayOfWeek">
            <v-col cols="2">
              {{ day.dayOfWeekName }}
            </v-col>
            <v-col cols="1">
              <v-switch v-model="day.isOpen" color="success" />
            </v-col>
            <v-col cols="1" v-if="day.isOpen">
              <v-switch
                v-model="day.is24h"
                @update:model-value="handleIs24hChange(day)"
                color="success"
                :disabled="!day.isOpen"
              />
            </v-col>
            <v-col cols="3" v-if="day.isOpen">
              <v-select
                v-model="day.startStopTime.siteStartTime"
                :items="generateTimeOptions24h(false)"
                item-title="text"
                item-value="value"
                label="Open Time"
                :disabled="!day.isOpen || day.is24h"
              />
            </v-col>
            <v-col cols="3" v-if="day.isOpen">
              <v-select
                v-model="day.startStopTime.siteEndTime"
                :items="generateTimeOptions24h(true)"
                item-title="text"
                item-value="value"
                label="Close Time"
                :disabled="!day.isOpen || day.is24h"
              />
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet elevation="2" class="mt-4 pa-4">
          <v-row>
            <v-col cols="8">
              <div class="text-h6">Contacts (optional)</div>
            </v-col>
            <v-col cols="2">
              <v-btn color="primary" @click="addContact">Add Contact</v-btn>
            </v-col>
          </v-row>
          <v-row
            justify="space-between"
            v-for="(contact, index) in site.createSiteContactResourceModels"
            :key="contact.contactMethodId"
          >
            <v-col>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="contact.siteContactName"
                    :rules="[
                      () => !!contact.siteContactName || 'Name is required',
                    ]"
                    label="Contact Name"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="contact.siteContactTitle"
                    :rules="[
                      () => !!contact.siteContactTitle || 'Title is required',
                    ]"
                    label="Contact Title"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-if="loading === false"
                    :items="siteContactNotificationFrequencies"
                    v-model="contact.siteContactNotificationFrequencyId"
                    label="Contact Frequency"
                    item-title="name"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn color="primary" @click="removeContact(index)">
                    Remove Contact
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-for="(
                  contactMethod, methodIndex
                ) in contact.createSiteContactMethodResourceModels"
                :key="contactMethod.contactMethodId"
              >
                <v-col cols="4">
                  <v-select
                    :items="contactTypes"
                    v-model="contactMethod.contactMethodId"
                    label="Contact Type"
                    item-title="text"
                    item-value="value"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="contactMethod.contactMethodId !== 2"
                    v-model="contactMethod.siteContactMethodValue"
                    :rules="[
                      () =>
                        !!contactMethod.siteContactMethodValue ||
                        'Number is required',
                    ]"
                    label="Number"
                    masked
                  />

                  <v-text-field
                    v-if="contactMethod.contactMethodId === 2"
                    v-model="contactMethod.siteContactMethodValue"
                    :rules="[
                      () =>
                        !!contactMethod.siteContactMethodValue ||
                        'Email is required',
                      (email) =>
                        /.+@.+\..+/.test(email) || 'E-mail must be valid',
                    ]"
                    label="Email"
                  />
                </v-col>
                <v-col cols="2">
                  <v-switch
                    v-model="contactMethod.isPreferred"
                    :label="`Preferred`"
                    @update:modelValue="
                      togglePrefer(methodIndex, contactMethod, contact)
                    "
                  />
                </v-col>
                <v-col cols="2" v-if="methodIndex === 0">
                  <v-btn
                    color="primary"
                    @click="addContactMethod(contact)"
                    v-bind:disabled="
                      contact.createSiteContactMethodResourceModels.length >= 2
                    "
                  >
                    Add Method
                  </v-btn>
                </v-col>
                <v-col cols="2" v-if="methodIndex > 0">
                  <v-btn
                    color="primary"
                    @click="removeContactMethod(contact, methodIndex)"
                  >
                    Remove Method
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider class="mt-6" />
            </v-col>
          </v-row>
        </v-sheet>

        <v-sheet elevation="2" class="mt-4 pa-4">
          <v-row>
            <v-col cols="12" sm="8">
              <div class="text-h6">Site Image (optional)</div>
            </v-col>
            <v-col cols="12" sm="8">
              <v-file-input
                :rules="rules"
                small-chips
                label="Select Image For Site (.jpg, .jpeg, .png, .bmp)"
                accept=".jpg, .jpeg, .png, .bmp"
                prepend-icon="mdi-camera"
                v-model="siteImageFile"
                @update:modelValue="showFilePreview"
                @click:clear="resetImage"
                class="pr-4"
                variant="outlined"
                density="compact"
                color="primary"
              />
              <p class="text-caption font-weight-light">
                *Images are cropped at 345x200px. For best results use a 3:4
                aspect ratio. Image size limit is 50 kB.
              </p>
            </v-col>
          </v-row>
          <v-img
            v-if="imagePreviewUrl"
            :src="imagePreviewUrl"
            max-height="200"
            max-width="345"
          />
          <p
            class="text-caption font-weight-light"
            v-if="imageSize"
            v-bind:style="imageSize > 50 ? 'color: red' : 'color: black'"
          >
            Current image size: {{ imageSize }} kB
          </p>
          <v-row class="mt-5">
            <v-col>
              <v-btn
                :disabled="invalid"
                type="submit"
                color="primary"
                class="mr-5"
              >
                Save
              </v-btn>
              <v-btn :to="`/sites`">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-form>
    </Form>
  </section>
</template>

<style>
.search-location {
  display: block;
  width: 80vw;
  outline: none;
  text-align: left;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.42);
  border-width: 0 0 thin 0;
}
</style>
<script>
import Label from '@/modules/controls/_components/Label.vue';
import mapPlaceComponents from '@/modules/site/_resourceModels/mapAddress.js';
import { Loader } from 'google-maps';
import { Form, defineRule } from 'vee-validate';
import { mask } from 'vue-the-mask';
import api from '../_api';
import { createSiteContactMethodResourceModel } from '../_resourceModels/createSiteContactMethodResourceModel';
import { createSiteContactResourceModel } from '../_resourceModels/createSiteContactResourceModel';
import { createSiteResourceModel } from '../_resourceModels/createSiteResourceModel';
import { createSiteStartStopTimeResourceModel } from '../_resourceModels/createSiteStartStopTimeResourceModel';

export default {
  name: 'CreateSite',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    'label-component': Label,
  },

  directives: {
    mask,
  },

  data() {
    return {
      rules: [
        (value) =>
          !value || value.size < 3000000 || 'Image size must be less than 3MB.',
      ],
      loading: true,
      valid: true,
      server_errors: [],
      emailMethod: false,
      states: [],
      sites: [],
      weatherStations: [],
      timezones: [],
      utilityCompanies: [],
      marketContexts: [],
      siteTypes: [],
      contactTypes: [
        { value: 3, text: 'Text' },
        { value: 2, text: 'Email' },
      ],
      years: this.getYears(),
      google: null,
      autocompleted: null,
      site: new createSiteResourceModel(),
      siteName: null,
      dayOfWeek: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      siteImageFile: null,
      imagePreviewUrl: null,
      imageSize: null,
      siteStartStopTimesNew: [],
    };
  },

  mounted() {
    if (this.google === null) {
      const loader = new Loader('AIzaSyCda-Sl20-2xJ-k7nCtmRiOTXWRgfS6P60', {
        libraries: ['places'],
      });
      loader
        .load()
        .then((gData) => {
          const inputField = this.$refs.autocomplete.$el.querySelector('input');
          inputField.placeholder = '';
          this.google = { ...gData };
          this.autocompleted = new this.google.maps.places.Autocomplete(
            inputField,
            { types: ['geocode'] }
          );

          this.autocompleted.addListener('place_changed', () => {
            const place = this.autocompleted.getPlace();
            const addressDetail = mapPlaceComponents(place);

            this.site.latitude = addressDetail.lat;
            this.site.longitude = addressDetail.lng;
            this.site.address = addressDetail.address;
            this.site.city = addressDetail.city;
            this.site.country = addressDetail.country;
            this.site.state = addressDetail.state;
            this.site.postalCode = addressDetail.postalCode;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },

  async created() {
    this.getWeatherstations();
    this.getTimezones();
    this.getUtilityCompanies();
    this.getSiteTypes();
    this.getMarketContexts();
    this.getStates();
    this.getSiteContactFrequencies();
    this.getSites();
    this.registerCustomValidators();
    this.siteStartStopTimesNew = this.mapSiteStartStopTimeNew();
  },

  methods: {
    generateTimeOptions24h(addLastMinute) {
      const timeOptions = [];
      const periods = ['AM', 'PM'];
      const minutesInterval = 15;

      for (let periodIndex in periods) {
        for (let hour = 0; hour < 12; hour++) {
          let displayHour = hour === 0 ? 12 : hour;
          for (let minutes = 0; minutes < 60; minutes += minutesInterval) {
            // Convert to 24-hour format
            let hour24 =
              periodIndex == 1
                ? hour === 0
                  ? 12
                  : hour + 12
                : hour === 0
                ? 0
                : hour;
            let formatted24Hours = hour24.toString().padStart(2, '0');
            let formattedMinutes = minutes.toString().padStart(2, '0');
            let value = `${formatted24Hours}:${formattedMinutes}:00`;

            timeOptions.push({
              text: `${displayHour}:${formattedMinutes} ${periods[periodIndex]}`,
              value,
            });
          }
        }
      }

      if (addLastMinute) {
        timeOptions.push({ text: '11:59 PM (last minute)', value: '23:59:00' });
      }

      return timeOptions;
    },

    getStates() {
      this.states = api.getStates();
    },

    async getWeatherstations() {
      try {
        let response = await api.getWeatherStations();
        this.weatherStations = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getSites() {
      try {
        let response = await api.getSites();
        this.sites = response;
        this.loading = false;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
        this.loading = false;
      }
    },

    async getTimezones() {
      try {
        let response = await api.getTimezones();
        this.timezones = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getUtilityCompanies() {
      try {
        let response = await api.getUtilityCompanies();
        this.utilityCompanies = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getMarketContexts() {
      try {
        let response = await api.getMarketContexts();
        this.marketContexts = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getSiteTypes() {
      try {
        let response = await api.getSiteTypes();
        this.siteTypes = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getSiteContactFrequencies() {
      try {
        let response = await api.getSiteContactNotificationFrequencies();
        this.siteContactNotificationFrequencies = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    mapSiteStartStopTimeNew() {
      let mappedTimes = [];

      for (let i = 0; i < this.dayOfWeek.length; i++) {
        let newSssTime = this.newStartStopTime(i, this.dayOfWeek[i]);
        mappedTimes.push(newSssTime);
      }

      return mappedTimes;
    },

    newStartStopTime(index, dayOfWeekName) {
      return new createSiteStartStopTimeResourceModel(
        0,
        index,
        dayOfWeekName,
        false
      );
    },

    async onSubmit() {
      // Trim the SiteName before submission
      this.site.siteName = this.site.siteName ? this.site.siteName.trim() : '';

      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }

      try {
        this.loading = true;
        this.site.siteStartStopTime = this.mapUpdatedStartStopTime(
          this.siteStartStopTimesNew
        );

        const options = {
          title: 'Success',
          message: `Site was successfully created.`,
          status: 'success',
        };

        const result = await api.addSite(this.site);
        if (this.siteImageFile) {
          await api.updateSiteImage(result, this.siteImageFile);
        }

        this.$toast.show(options);
        this.$router.push({ name: 'SitesRoute' });
      } catch (error) {
        this.server_errors = this.errorSummary(error);
        return Promise.reject(error);
      } finally {
        this.loading = false;
      }
    },

    mapUpdatedStartStopTime(sSSTimes) {
      var openSSSTimes = sSSTimes.filter(
        (t) => t.isOpen === true && t.dayOfWeekName !== 'All'
      );
      return openSSSTimes;
    },

    getYears() {
      let thisYear = new Date().getFullYear(),
        yearList = [];
      let startYear = 1900;
      while (startYear <= thisYear) {
        yearList.push(thisYear--);
      }
      return yearList;
    },

    addContact() {
      let newContact = new createSiteContactResourceModel();
      this.site.createSiteContactResourceModels.push(newContact);
    },

    removeContact(index) {
      this.site.createSiteContactResourceModels.splice(index, 1);
    },

    addContactMethod(contact) {
      let newContactMethod = new createSiteContactMethodResourceModel(
        null,
        null,
        false
      );
      contact.createSiteContactMethodResourceModels.push(newContactMethod);
    },

    removeContactMethod(contact, index) {
      contact.createSiteContactMethodResourceModels.splice(index, 1);
    },

    togglePrefer(index, contactMethod, contact) {
      let value = contactMethod.isPreferred;

      if (
        value === false ||
        contact.createSiteContactMethodResourceModels.length === 1
      ) {
        return;
      } else {
        for (
          let i = 0;
          i < contact.createSiteContactMethodResourceModels.length;
          i++
        ) {
          if (i === index) {
            continue;
          } else {
            contact.createSiteContactMethodResourceModels[
              i
            ].isPreferred = false;
          }
        }
      }
    },

    showFilePreview(e) {
      if (e) {
        this.imageSize = e.size / 1024;
        this.imagePreviewUrl = URL.createObjectURL(e);
      }
    },

    resetImage() {
      this.imagePreviewUrl = null;
      this.imageSize = null;
    },

    errorSummary(error) {
      return error.response
        ? error.response.data.Errors
          ? error.response.data.Errors
          : error.response.data.errors
          ? error.response.data.errors
          : [error]
        : [error];
    },

    timezoneFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    registerCustomValidators() {
      defineRule('duplicate_name', (value, [field]) => {
        let valid = true;
        if (this.sites) {
          let existingNames = this.sites.map((a) => a.name);
          valid = existingNames.indexOf(value) === -1;
        }
        if (!valid) {
          return `${field} is already used by another site.  Enter a different name.`;
        }

        return true;
      });
    },

    handleIs24hChange(day) {
      if (day.is24h) {
        const startTimes = this.generateTimeOptions24h(false);
        const endTimes = this.generateTimeOptions24h(true);

        if (startTimes.length > 0) {
          day.startStopTime.siteStartTime = startTimes[0].value;
        }
        if (endTimes.length > 0) {
          day.startStopTime.siteEndTime = endTimes[endTimes.length - 1].value;
        }
      }
    },
  },
};
</script>
