import { IAHU } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const apiVersion = 'v1';

const getAHUsBySiteId = async (siteId: string | number) => {
  const response = await window.axios.get(
    `${apiVersion}/AirHandlerUnits/latest-readings?siteid=${siteId}`
  );

  const { data } = response.data;
  return data as IAHU[];
};

const setAHUSetpoint = async (
  ahuId: number,
  setpoints: {
    netSupplyAirCoolingSetpoint: number;
    minSupplyAirCoolingSetpoint: number;
  }
): Promise<void> => {
  try {
    await window.axios.put(`/${apiVersion}/AirHandlerUnits/${ahuId}/settings`, {
      changedSettings: {
        netSupplyAirCoolingSetpoint: setpoints.netSupplyAirCoolingSetpoint,
        minSupplyAirCoolingSetpoint: setpoints.minSupplyAirCoolingSetpoint,
      },
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getAHUsBySiteId,
  setAHUSetpoint,
};
