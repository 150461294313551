<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg1"
    viewBox="0 0 71 87"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="M0.5 41.5V1H21.5V19H70.5V86.5H24V68H0.5V41.5Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
