import cons from '../constants';

const setSlicers = async function (reportParameters, visuals) {
  try {
    await setSiteSlicerVisual(reportParameters, visuals);
  } catch (error) {
    return Promise.reject(error);
  }
},
setSiteSlicerVisual = async function (reportParameters, visuals) {
  const siteSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwSite',
      column: 'SiteName',
    },
    operator: 'In',
    values: [reportParameters.SiteName],
  };
  let siteSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState({
      filters: [siteSlicerFilter],
    });
  }
};

export default {
  setSlicers,
};
