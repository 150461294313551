<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 210 117"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      fill-opacity="0.3"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="M8 113V116.225H1V12.5V1H203.5H209.5V113H204.5V104.5H185.5L177 113H111H80V105H60V113H8Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="handleClick"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
defineProps<{
  color?: string;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};

const handleClick = (event: MouseEvent) => {
  emits('click', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
