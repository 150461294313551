<template>
  <v-card class="portfolio-bar">
    <v-app-bar elevation="1" class="px-11 pr-16">
      <v-app-bar-title class="text-primary text-h6 font-weight-bold">
        My Portfolio
      </v-app-bar-title>
      <v-text-field
        class="mt-1"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        bg-color="white"
        variant="outlined"
        density="compact"
        :placeholder="placeholder"
        :clearable="true"
        @click:clear="clearSearchInput"
        @update:modelValue="handleSearchInput"
      />
      <v-spacer />
    </v-app-bar>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

const placeholder = computed(() => {
  return 'Search for a Site';
});

const handleSearchInput = (value: string) => {
  if (!value || typeof value === 'object') {
    setSiteSearchTerm('');
  } else {
    setSiteSearchTerm(value);
  }
};

const clearSearchInput = () => {
  setSiteSearchTerm('');
};

const store = useStore();
const setSiteSearchTerm = (value: string) => {
  store.commit('ViewSupportStore/PortfolioStore/SITE_SEARCH_TERM', value);
};
</script>

<style lang="scss" scoped>
.portfolio-bar {
  z-index: 1;
}

.v-toolbar-title {
  max-width: 700px !important;
}

.v-input {
  max-width: 700px !important;
}

:deep(.v-field__input) {
  color: $primary !important;
}

:deep(.bg-white) {
  color: $primary !important;
}
</style>
