//Sensor Readings
//Site Slicer
//Date Slicer
//Sensor Slicer
//UOM Slicer

import * as powerbi from 'powerbi-client';
import slicer from '../slicerReports/sensorSlicer.js';


let visuals = null;
let config = null;

// Embed a Power BI report in the given HTML element with the given configurations
// Read more about how to embed a Power BI report in your application here: https://go.microsoft.com/fwlink/?linkid=2153590
const getEmbed = async function (container, configuration) {
  config = configuration;
  /*-----------------------------------------------------------------------------------+
  |    Don't change these values here: access token, embed URL and report ID.          | 
  |    To make changes to these values:                                                | 
  |    1. Save any other code changes to a text editor, as these will be lost.         |
  |    2. Select 'Start over' from the ribbon.                                         |
  |    3. Select a report or use an embed token.                                       |
  +-----------------------------------------------------------------------------------*/
  // Embed the report and display it within the div container.
  const pbi = new powerbi.service.Service(
    powerbi.factories.hpmFactory,
    powerbi.factories.wpmpFactory,
    powerbi.factories.routerFactory
  );

  const report = pbi.embed(container, configuration);

  // report.on will add an event handler
  report.on('loaded', async function () {
    try {
      const pages = await report.getPages();
      // Retrieve the active page.
      const [pageWithSlicer] = pages.filter((page) => {
        return page.isActive;
      });

      visuals = await pageWithSlicer.getVisuals();

      await slicer.setSlicers(configuration.reportParameters, visuals);
      report.off('loaded');
    } catch (errors) {
      return Promise.reject(errors);
    }
  });

  //report.off removes all event handlers for a specific event
  // clears the event handlers before starting the event listener
  report.off('dataSelected');

  // report.on start the event listener
  report.on('dataSelected', async function (event) {
    // store the events
    const data = event.detail;
    // set the variables for the slicers that need to be adjusted
    let slicerThatChanged = 'Site Slicer';
    let slicersToReset = ['UOM Slicer', 'Sensor Slicer'];

    let uomName = null;
    let unsetFirstLoad = false;

    // filter events for only slicers
    if (data.visual.type !== 'slicer') return;

    try {
      // persist new site through out tab changes
      if (
        data.visual.title === 'Site Slicer' &&
        data.dataPoints[0].identity[0].target.column === 'SiteName'
      ) {
        unsetFirstLoad = true;
        slicerThatChanged = 'Site Slicer';
        const newSite = data.dataPoints[0].identity[0].equals;
        report.config.reportParameters.SiteName = newSite;
      } else if (data.visual.title === 'UOM Slicer') {
        uomName = data.dataPoints[0].identity[0].equals;
        slicerThatChanged = 'UOM Slicer';
        slicersToReset = ['Sensor Slicer'];
      }
      // run function to reset slicers
      await resetSlicers(
        data.visual.name,
        slicerThatChanged,
        slicersToReset,
        report,
        uomName,
        unsetFirstLoad
      );
    } catch (errors) {
      return Promise.reject(errors);
    }
  });

  return report;
};

const resetSlicers = async function (
  changedSlicerName,
  slicerToListenToName,
  slicersToResetNames,
  report,
  uomName = null,
  unsetFirstLoad = false
) {
  // get list of all slicers on active page
  const pageSlicers = await getSlicersForActivePage(report);

  // get the slicer visual and compare with the visual that fired the data selected event
  const changingSlicer = pageSlicers.filter(
    (s) => s.title === slicerToListenToName
  )[0];
  if (changingSlicer.name !== changedSlicerName) return;

  // for each slicer to be reset reset them.
  const slicersToReset = pageSlicers.filter(
    (s) => slicersToResetNames.indexOf(s.title) !== -1
  );
  slicersToReset.forEach(async (s) => {
    if (s.title === 'UOM Slicer') {
      try {
        await slicer.setUOMSlicerVisual(
          config.reportParameters,
          visuals,
          unsetFirstLoad
        );
      } catch (errors) {
        return Promise.reject(errors);
      }
    } else if (s.title === 'Sensor Slicer') {
      try {
        await slicer.setSensorSlicerVisual(
          config.reportParameters,
          visuals,
          uomName,
          unsetFirstLoad
        );
      } catch (errors) {
        return Promise.reject(errors);
      }
    }
  });
};
// Select Run and select an element of a visualization.
// For example, a bar in a bar chart. You should see an entry in the Log window.
const getSlicersForActivePage = async function (report) {
  // Retrieve the page collection and get the visuals for the active page.
  try {
    const pages = await report.getPages();
    // Retrieve the active page.
    const pageWithSlicer = pages.filter(function (page) {
      return page.isActive;
    })[0];
    const visuals = await pageWithSlicer.getVisuals();
    // Retrieve the target visual.
    const slicers = visuals.filter(function (visual) {
      return visual.type === 'slicer';
    });
    // Get the slicer state
    return slicers;
  } catch (errors) {
    return Promise.reject(errors);
  }
};

export default {
  getEmbed,
};
