import { IAHU } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

type AHUState = {
  editAHU: IAHU;
};

const SET_AHU_EDIT = (state: AHUState, payload: IAHU) => {
  state.editAHU = payload;
};

export default {
  SET_AHU_EDIT,
};
