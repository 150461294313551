const apiVersion = 'v1';

import {
  IVAV,
  IAHU,
} from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const getVAVById = async (
  vavId: number,
  options: { signal?: AbortSignal } = {}
): Promise<IVAV> => {
  try {
    const { signal } = options;

    const response = await window.axios.get(
      `/${apiVersion}/VariableAirVolumeUnits/${vavId}/latest-readings`,
      { signal }
    );

    const { data } = response.data;
    return data as IVAV;
  } catch (err) {
    return Promise.reject(err);
  }
};

const setVAVSetpoint = async (
  vavId: number,
  setpoint: number
): Promise<void> => {
  try {
    await window.axios.put(
      `/${apiVersion}/VariableAirVolumeUnits/${vavId}/settings`,
      {
        changedSettings: {
          occupiedSetpoint: setpoint,
        },
      }
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAHUById = async (ahuId: number): Promise<IAHU> => {
  try {
    const response = await window.axios.get(
      `/${apiVersion}/AirHandlerUnits/${ahuId}/latest-readings`
    );

    const { data } = response.data;

    return data as IAHU;
  } catch (err) {
    return Promise.reject(err);
  }
};

const setAHUSetpoint = async (
  ahuId: number,
  setpoints: {
    netSupplyAirCoolingSetpoint: number;
    minSupplyAirCoolingSetpoint: number;
  }
): Promise<void> => {
  try {
    await window.axios.put(`/${apiVersion}/AirHandlerUnits/${ahuId}/settings`, {
      changedSettings: {
        netSupplyAirCoolingSetpoint: setpoints.netSupplyAirCoolingSetpoint,
        minSupplyAirCoolingSetpoint: setpoints.minSupplyAirCoolingSetpoint,
      },
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getVAVById,
  setVAVSetpoint,
  getAHUById,
  setAHUSetpoint,
};
