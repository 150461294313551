const apiVersion = 'v1';

const getNotificationsBySiteId = async (siteId) => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/notifications/${siteId}`
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllNotifications = async (...args) => {
  try {
    const [{ siteId, searchText, dateFrom, dateTo } = {}] = args;

    const payload = {
      siteIds: siteId ? [siteId] : [],
      searchText,
      dateFrom,
      dateTo,
    };
    const result = await window.axios.post(
      `/${apiVersion}/notifications`,
      payload
    );
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateNotification = async (notification) => {
  try {
    const result = await window.axios.put(
      `/${apiVersion}/notifications`,
      notification
    );
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPortfolioSites = async () => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/viewsupport/portfolio/sitedata`
    );
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const markAllNotificationsAsRead = async ({ siteId }) => {
  try {
    const result = await window.axios.put(
      `/${apiVersion}/notifications/${siteId}/mark-all-read`
    );
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getSitesList = async () => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/notifications/sites`
    );
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getNotificationsBySiteId,
  updateNotification,
  getAllNotifications,
  getPortfolioSites,
  markAllNotificationsAsRead,
  getSitesList
};
