<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg1"
    viewBox="0 0 79 81"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="fillOpacity"
      :fill="color"
      :stroke="color"
      stroke-width="0"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <path
        d="M4 25V1H78V23H77V59.5H33.5V70.5L27 74L19 77.5H18L7.5 80.5H1.5V74H11C10.5 68 6.46223 65.1378 1.5 64.5V51.5H0.5V42H2C1.5 37.5 2.5 32 7.5 29L4 25Z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  color?: string;
  isHovered?: boolean;
}>();

const emits = defineEmits(['mouseenter', 'mouseleave', 'mousemove', 'click']);

const fillOpacity = computed(() => {
  return props.isHovered ? 0.5 : 0.3;
});

const handleMouseEnter = (event: MouseEvent) => {
  emits('mouseenter', event);
};

const handleMouseMove = (event: MouseEvent) => {
  emits('mousemove', event);
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
