//Dashboard
//Site Slicer - vwSite - SiteName
//Monitor Name Slicer - vwSensorNames - MonitorFriendlyName

import { DateTime } from 'luxon';
import * as powerbi from 'powerbi-client';
import slicer from '../slicerReports/NewIAQSlicer.js';

const regex = /\bDate Slicer\b/gi;

// Embed a Power BI report in the given HTML element with the given configurations
// Read more about how to embed a Power BI report in your application here: https://go.microsoft.com/fwlink/?linkid=2153590
const getEmbed = async (container, configuration) => {
  /*-----------------------------------------------------------------------------------+
    |    Don't change these values here: access token, embed URL and report ID.          | 
    |    To make changes to these values:                                                | 
    |    1. Save any other code changes to a text editor, as these will be lost.         |
    |    2. Select 'Start over' from the ribbon.                                         |
    |    3. Select a report or use an embed token.                                       |
    +-----------------------------------------------------------------------------------*/
  // Embed the report and display it within the div container.
  const pbi = new powerbi.service.Service(
    powerbi.factories.hpmFactory,
    powerbi.factories.wpmpFactory,
    powerbi.factories.routerFactory
  );

  const report = pbi.embed(container, configuration);

  // report.on will add an event handler
  report.on('loaded', async function () {
    try {
      report.off('loaded');
    } catch (errors) {
      return Promise.reject(errors);
    }
  });

  //report.off removes all event handlers for a specific event
  // clears the event handlers before starting the event listener
  report.off('dataSelected');

  // report.on start the event listener
  report.on('dataSelected', async (event) => {
    // store the events
    const data = event.detail;
    // set the variables for the slicers that need to be adjusted
    let slicerThatChanged = 'Site Slicer';
    let slicersToReset = ['Monitor Name Slicer'];

    let sensorName = null;

    // filter events for only slicers
    if (data.visual.type != 'slicer') return;

    try {
      // persist new site through out tab changes
      if (
        data.visual.title === 'Site Slicer' &&
        data.dataPoints[0].identity[0].target.column == 'SiteName'
      ) {
        const newSite = data.dataPoints[0].identity[0].equals;
        report.config.reportParameters.SiteName = newSite;
        sensorName = data.dataPoints[0].identity[0].equals;
        slicerThatChanged = 'Site Slicer';
        slicersToReset = ['Monitor Name Slicer'];
      }
      // run function to reset slicers
      await resetSlicers(
        data.visual.name,
        slicerThatChanged,
        slicersToReset,
        report,
        sensorName
      );
    } catch (errors) {
      return Promise.reject(errors);
    }
  });

  report.on('rendered', async () => {
    try {
      const pages = await report.getPages();
      // Retrieve the active page.
      const [pageWithSlicer] = pages.filter((page) => {
        return page.isActive;
      });
      // Retrieve the visuals so we have access to page elements
      const visuals = await pageWithSlicer.getVisuals();
      // Retrieve the Date Slicer
      const [dateSlicerVisual] = visuals.filter((visual) => {
        return visual.type === 'slicer' && regex.test(visual.title);
      });
      // Retrieve current parameters of slicer
      if (!dateSlicerVisual) return;
      const state = await dateSlicerVisual.getSlicerState();
      // Format the date slicers so that they are in utc time
      const newStartDate = DateTime.fromISO(
        state.filters[0].conditions[0].value
      ).setZone('utc');
      const newEndDate = DateTime.fromISO(
        state.filters[0].conditions[1].value
      ).setZone('utc');
      // Save the new start/end dates as part of the report parameters
      configuration.reportParameters.StartDate = newStartDate;
      configuration.reportParameters.EndDate = newEndDate;
    } catch (errors) {
      return Promise.reject(errors);
    }
  });

  report.on('buttonClicked', async () => {
    try {
      const pages = await report.getPages();
      // Retrieve the active page.
      const [pageWithSlicer] = pages.filter((page) => {
        return page.isActive;
      });

      const visuals = await pageWithSlicer.getVisuals();
      // set the slicers again for the new active page
      if (
        pageWithSlicer.displayName === 'Sensor Overview' ||
        pageWithSlicer.displayName === 'Sensor Over Time'
      ) {
        await slicer.setSensorOverviewSlicer(
          report.config.reportParameters,
          visuals
        );
      } else if (pageWithSlicer.displayName === 'All Sensors') {
        await slicer.setOvertimeSlicer(report.config.reportParameters, visuals);
      } else {
        await slicer.setSlicers(configuration.reportParameters, visuals);
      }
    } catch (errors) {
      return Promise.reject(errors);
    }
  });

  report.on('pageChanged', async () => {
    try {
      const pages = await report.getPages();
      // Retrieve the active page.
      const [pageWithSlicer] = pages.filter((page) => {
        return page.isActive;
      });

      const visuals = await pageWithSlicer.getVisuals();
      // set the slicers again for the new active page
      if (
        pageWithSlicer.displayName === 'Over Time' ||
        pageWithSlicer.displayName === 'Sensor Over Time'
      ) {
        await slicer.setOvertimeSlicer(configuration.reportParameters, visuals);
      } else {
        await slicer.setSlicers(configuration.reportParameters, visuals);
      }
    } catch (errors) {
      return Promise.reject(errors);
    }
  });

  return report;
};

const resetSlicers = async (
  changedSlicerName,
  slicerToListenToName,
  slicersToResetNames,
  report
) => {
  // get list of all slicers on active page
  const pageSlicers = await getSlicersForActivePage(report);
  const pages = await report.getPages();
  // Retrieve the active page.
  const [pageWithSlicer] = pages.filter((page) => {
    return page.isActive;
  });

  const visuals = await pageWithSlicer.getVisuals();

  // get the slicer visual and compare with the visual that fired the data selected event
  const [changingSlicer] = pageSlicers.filter(
    (s) => s.title === slicerToListenToName
  );
  if (changingSlicer.name != changedSlicerName) return;

  // for each slicer to be reset reset them.
  const slicersToReset = pageSlicers.filter(
    (s) => slicersToResetNames.indexOf(s.title) != -1
  );

  slicersToReset.forEach(async (s) => {
    if (s.title === 'Monitor Name Slicer') {
      try {
        await slicer.setSensorOverviewSlicer(
          report.config.reportParameters,
          visuals
        );
      } catch (errors) {
        return Promise.reject(errors);
      }
    }
  });
};

// Select Run and select an element of a visualization.
// For example, a bar in a bar chart. You should see an entry in the Log window.
const getSlicersForActivePage = async (report) => {
  // Retrieve the page collection and get the visuals for the active page.
  try {
    const pages = await report.getPages();
    // Retrieve the active page.
    const [pageWithSlicer] = pages.filter((page) => {
      return page.isActive;
    });
    const visuals = await pageWithSlicer.getVisuals();
    // Retrieve the target visual.
    const slicers = visuals.filter((visual) => {
      return visual.type === 'slicer';
    });
    // Get the slicer state
    return slicers;
  } catch (errors) {
    return Promise.reject(errors);
  }
};

export default {
  getEmbed,
};
