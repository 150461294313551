import { IVAV } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

type VAVState = {
  editVAV: IVAV;
};

const SET_VAV_EDIT = (state: VAVState, payload: IVAV) => {
  state.editVAV = payload;
};

export default {
  SET_VAV_EDIT,
};
