import { markRaw } from 'vue';

import VAV343Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite312/VAV343Drawing.vue';
import VAV344Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite312/VAV344Drawing.vue';
import VAV345Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite312/VAV345Drawing.vue';
import VAV346Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite312/VAV346Drawing.vue';
import VAV347Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite312/VAV347Drawing.vue';
import VAV348Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite312/VAV348Drawing.vue';
import VAV349Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite312/VAV349Drawing.vue';
import VAV350Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite312/VAV350Drawing.vue';
import VAV351Hotspot from '@/Core.Service.Domain/Controls/DigitalTwin/Components/DrawingComponents/MGMOB_3rd_Floor/SuitesVAVs/Suite312/VAV351Drawing.vue';

import {
  selectedVAVId,
  vav,
  isClickFromCommonArea,
} from '@/Core.Service.Domain/Controls/DigitalTwin/API/floors.ts';

import { store } from '@/store';

import { IHotspot } from '@/Core.Service.Domain/Controls/Controls.Common/types/index.ts';

const handleAction = async (controlId: MouseEvent | KeyboardEvent | number) => {
  if (typeof controlId === 'number') {
    try {
      if (selectedVAVId.value === controlId) {
        selectedVAVId.value = 0;
        return;
      }

      const device = await store.dispatch('DigitalTwinStore/fetchDevice', {
        controlId,
      });
      vav.value = device;
      selectedVAVId.value = controlId;
      isClickFromCommonArea.value = false;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

const ControlsList: IHotspot<Record<string, unknown>>[] = [
  {
    id: 0,
    name: 'VAV 3-51',
    controlId: 2098,
    component: markRaw(VAV351Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-51',
      },
    },
    style: {
      position: 'absolute',
      top: '54.1%',
      left: '26.5%',
      width: '21.5%',
      height: '21.5%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '64%',
      left: '34%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 1,
    name: 'VAV 3-44',
    controlId: 2358,
    component: markRaw(VAV344Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-44',
      },
    },
    style: {
      position: 'absolute',
      top: '75%',
      left: '26.2%',
      width: '28.3%',
      height: '28.3%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '89%',
      left: '34%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 2,
    name: 'VAV 3-43',
    controlId: 2357,
    component: markRaw(VAV343Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-43',
      },
    },
    style: {
      position: 'absolute',
      top: '56.1%',
      left: '34.05%',
      width: '40.6%',
      height: '40.6%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '69%',
      left: '50%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 3,
    name: 'VAV 3-46',
    controlId: 2357,
    disabled: true,
    component: markRaw(VAV346Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-46',
      },
    },
    style: {
      position: 'absolute',
      top: '66.5%',
      left: '43.45%',
      width: '48.7%',
      height: '48.7%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '100%',
      left: '65%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 4,
    name: 'VAV 3-45',
    controlId: 2359,
    component: markRaw(VAV345Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-45',
      },
    },
    style: {
      position: 'absolute',
      top: '75%',
      left: '39.5%',
      width: '28%',
      height: '28%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '89%',
      left: '50.5%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 5,
    name: 'VAV 3-49',
    controlId: 2361,
    component: markRaw(VAV349Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-49',
      },
    },
    style: {
      position: 'absolute',
      top: '20.6%',
      left: '75.2%',
      width: '46.4%',
      height: '46.4%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '33%',
      left: '95.2%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 6,
    name: 'VAV 3-48',
    controlId: 2361,
    disabled: true,
    component: markRaw(VAV348Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-48',
      },
    },
    style: {
      position: 'absolute',
      top: '51.1%',
      left: '77.2%',
      width: '51.9%',
      height: '51.9%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '80%',
      left: '100%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 7,
    name: 'VAV 3-50',
    controlId: 2362,
    component: markRaw(VAV350Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-50',
      },
    },
    style: {
      position: 'absolute',
      top: '51.5%',
      left: '76.25%',
      width: '20.6%',
      height: '20.6%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '60%',
      left: '85%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
  {
    id: 8,
    name: 'VAV 3-47',
    controlId: 2360,
    component: markRaw(VAV347Hotspot),
    techInfo: {
      device: {
        label: 'VAV 3-47',
      },
    },
    style: {
      position: 'absolute',
      top: '72%',
      left: '65%',
      width: '41.8%',
      height: '41.8%',
      cursor: 'pointer',
    },
    hotSpotAction: (controlId: MouseEvent | KeyboardEvent | number) => {
      handleAction(controlId);
    },
    chipStyle: {
      position: 'absolute',
      top: '89%',
      left: '83%',
      cursor: 'pointer',
    },
    color: '#378CDA',
  },
];

export default ControlsList;
